import { Injectable } from '@angular/core';
//import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslationServiceService {

  constructor(
    //public http: Http
    public http: HttpClient
  ) { }
  
  getTranslated(targetStr:string){
    // const body = 
    // {"value": "AAA"}
    // {
    //   "requests": [
    //     {
    //       "features": [
    //         {
    //           "type": feature.value,
    //           "maxResults": 10
    //         }],
    //       "image": {
    //         "content": base64Image
    //       }
    //     }
    //   ]
    // }

    //console.log("GETTING TO-- "+targetStr)
    return this.http.get(
      "https://script.google.com/macros/s/AKfycbwTvc5QLAaRNBkOWqXG-AzI_TZDkaEH_WJ-57cahI-S7PvIwuE/exec?text="
      +targetStr+"&source=ja&target=zh-cn"
      );
    //return this.http.post('https://script.google.com/macros/s/AKfycbwTvc5QLAaRNBkOWqXG-AzI_TZDkaEH_WJ-57cahI-S7PvIwuE/exec', body);
    
  }
}
