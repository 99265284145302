import { Pipe, PipeTransform } from '@angular/core';
import * as marked from 'marked'; // markedをインポート

@Pipe({
  name: 'mdToHtml'
})
export class MdToHtmlPipe implements PipeTransform {

  public mdToHtml(md: string): any {
    marked.setOptions({
      sanitize: false,
      breaks : false,//true,//Trueでスペース無しで開業するが、HTMLタグ書くときに無駄な改行が入ってしまうので
    });
    //md = "```math\n"+md+"\n```" ;
    return marked(md);
  }
  
  transform(value: any, args?: any): any {
    return this.mdToHtml(value);
  }

  // static forRoot() {
  //   return {
  //       ngModule: MdToHtmlPipe,
  //       providers: [],
  //   };
  // }

}
