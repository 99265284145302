import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { NavParams, ModalController, AlertController, ToastController, PickerController, Platform, LoadingController, IonSlides, } from '@ionic/angular';

import { Router } from '@angular/router';
import { Mondai } from '../mondai/mondai';
import { DomSanitizer } from '@angular/platform-browser';
//import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FirestoreAccessorService } from '../firestore-accessor.service';
import { TodoService } from '../services/todo.service';
//import { Kansou } from '../models/Kansou';
//import * as firebase from 'firebase';
import { MdToHtmlPipe } from '../md-to-html.pipe';
//import { AppAvailability } from '@ionic-native/app-availability/ngx';
//import { Screenshot } from '@ionic-native/screenshot/ngx';
import { GakusyuRireki } from '../models/GakusyuRireki';
//import { MatTableDataSource,MatSort, MatPaginator, Sort  } from '@angular/material';
//import { SakuinData } from '../models/SakuinData';
import { MokujiData } from '../models/MokujiData';
import { IonInfiniteScroll, IonVirtualScroll } from '@ionic/angular';
import { MondaiPreviewPage } from '../mondai-preview/mondai-preview.page';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Kaitou } from '../models/Kaitou';
import { Chart } from 'chart.js';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { GoogleCloudVisionServiceService } from '../google-cloud-vision-service.service';
import { YougoData } from '../models/YougoData';
import { IonContent } from '@ionic/angular';
import { Share } from '@capacitor/share';

const sampleData = {
  labels: ["p0", "p1", "p2", "p3", "p4", "p5", "p6", "p7", "p8", "p9", "p10", "p11"],
  sample1: [1.9, 2.32, 1.52, 0.79, 1.37, 1.28, 1.92, 1.44, 2.58, -0.01, 0.71, 4.25],
  sample2: [7.01, -2.15, -7.29, 1.71, 0.72, -4.83, 2.75, 4.11, 3.08, -2.45, 3.05, -3.93],
  sample3: [-2.97, -2.15, -0.3, -0.65, -8.84, 0.28, 2.95, 2.79, 1.79, 2.87, 0.16, 0.31],
  timestamp: [new Date("2018/04/16 22:18"), new Date("2018/04/16 23:18"), new Date("2018/04/17 00:18"), new Date("2018/04/17 01:18"), new Date("2018/04/17 02:18"), new Date("2018/04/17 09:18"), new Date("2018/04/17 10:18"), new Date("2018/04/17 11:18"), new Date("2018/04/17 12:18"), new Date("2018/04/17 13:18"), new Date("2018/04/17 14:18"), new Date("2018/04/17 15:18")]
};

@Component({
  selector: "app-modal",
  templateUrl: "./modal.page.html",
  styleUrls: ["./modal.page.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ModalPage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonVirtualScroll) virtualScroll: IonVirtualScroll;
  @ViewChild(IonSlides) slides: IonSlides;

  context01: CanvasRenderingContext2D;
  context02: CanvasRenderingContext2D;
  context03: CanvasRenderingContext2D;
  context04: CanvasRenderingContext2D;
  context55: CanvasRenderingContext2D;
  //contextMyPhoto: CanvasRenderingContext2D;

  @ViewChild("mycanvas01") mycanvas01: ElementRef;
  @ViewChild("mycanvas02") mycanvas02: ElementRef;
  @ViewChild("mycanvas03") mycanvas03: ElementRef;
  @ViewChild("mycanvas04") mycanvas04: ElementRef; //@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild("mycanvas55") mycanvas55: ElementRef;
  //@ViewChild("myPhotoArea") myPhotoArea: ElementRef;
  //@ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('scrollArea') mainContent: IonContent;

  public kiyaku = {
    riyoukiyaku: `
  図でよくわかる過去問問題集サービス　
  <BR>
  利　用　規　約
  <BR>
  
  <BR>
  この規約は、お客様が、zu-de.com（以下「当社」）が提供する「図でよくわかる過去問問題集」サービス（以下「本サービス」）をご利用頂く際の取扱いにつき定めるものです。本規約に同意した上で本サービスをご利用ください。
  <BR>
  
  <BR>
  第１条（定義）
  <BR>
  　本規約上で使用する用語の定義は、次に掲げるとおりとします。
  <BR>
  （1）本サービス	当社が運営するサービス及び関連するサービス
  <BR>
  （2）本サイト	本サービスのコンテンツが掲載されたウェブサイト
  <BR>
  （3）本コンテンツ	本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称（投稿情報を含む）
  <BR>
  （4）利用者	本サービスを利用する全ての方
  <BR>
  （5）登録利用者	本サイトの利用者登録が完了した方
  <BR>
  （6）ＩＤ	本サービスの利用のために登録利用者が固有に持つ文字列
  <BR>
  （7）パスワード	ＩＤに対応して登録利用者が固有に設定する暗号
  <BR>
  （8）個人情報	住所、氏名、職業、電話番号等個人を特定することのできる情報の総称
  <BR>
  （9）登録情報	登録利用者が本サイトにて登録した情報の総称（投稿情報は除く）
  <BR>
  （10）知的財産	発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報
  <BR>
  （11）知的財産権	特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利
  <BR>
  
  <BR>
  第２条（本規約への同意）
  <BR>
  １　利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします。
  <BR>
  ２　利用者が、本サービスをスマートフォンその他の情報端末にダウンロードし、本規約への同意手続を行った時点で、利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。
  <BR>
  ３　利用者が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。
  <BR>
  ４　未成年者の利用者が、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことは出来ません。
  <BR>
  ５ 本規約の同意時に未成年であった利用者が成年に達した後に本サービスを利用した場合、当該利用者は本サービスに関する一切の法律行為を追認したものとみなされます。
  <BR>
  
  <BR>
  第３条（規約の変更）
  <BR>
  １　当社は、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。
  <BR>
  ２　当社は、本規約を改定するときは、その内容について当社所定の方法により利用者に通知します。
  <BR>
  ３　前本規約の改定の効力は、当社が前項により通知を行った時点から生じるものとします。
  <BR>
  ４　利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。
  <BR>
  
  <BR>
  第４条（会員の入会手続）
  <BR>
  １　本サービスへの入会を希望する方（以下「登録希望者」）は、本規約に同意した上で、所定の方法で入会の申込を行ってください。
  <BR>
  ２　入会の申込をした方は、弊社がその申込を承諾し、ＩＤ登録が完了した時点から登録ユーザとなります。
  <BR>
  ３　弊社は、登録ユーザ向けにメールで連絡事項の告知や広告その他の情報提供を行います。あらかじめご了承ください。
  <BR>
  ４　弊社は、登録希望者が次の各号のいずれか一つに該当する場合は、弊社の判断により入会申込を承諾しないことがあります。
  <BR>
  一　登録希望者が、弊社の定める方法によらず入会の申込を行った場合 
  <BR>
  二　登録希望者が、過去に本規約または弊社の定めるその他の利用規約等に違反したことを理由として退会処分を受けた者である場合
  <BR>
  三　登録希望者が、不正な手段をもって登録を行っていると弊社が判断した場合
  <BR>
  四　登録希望者が、本人以外の情報を登録している場合
  <BR>
  五　その他弊社が不適切と判断した場合
  <BR>
  
  <BR>
  第５条（アカウントの管理）
  <BR>
  １　利用者は、利用に際して登録した情報（以下、「登録情報」といいます。メールアドレスやID・パスワード等を含みます）について、自己の責任の下、任意に登録、管理するものとします。利用者は、これを第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならないものとします。
  <BR>
  ２　当社は、登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。 
  <BR>
  ３　利用者は、登録情報の不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。 
  <BR>
  ４　登録情報の管理は、利用者が自己の責任の下で行うものとし、登録情報が不正確または虚偽であったために利用者が被った一切の不利益および損害に関して、当社は責任を負わないものとします。 
  <BR>
  ５　登録情報が盗用されまたは第三者に利用されていることが判明した場合、利用者は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
  <BR>
  
  <BR>
  第６条（個人情報等の取り扱い） 
  <BR>
  個人情報及び利用者情報については、当社が別途定める「図でよくわかる過去問問題集サービスプライバシーポリシー」に則り、適正に取り扱うこととします。
  <BR>
  
  <BR>
  第７条（禁止行為）　
  <BR>
  本サービスの利用に際し、当社は、利用者に対し、次に掲げる行為を禁止します。当社において、利用者が禁止事項に違反したと認めた場合、利用者用の一時停止、退会処分その他当社が必要と判断した措置を取ることができます。 
  <BR>
  
  <BR>
  （１）当社または第三者の知的財産権を侵害する行為
  <BR>
  （２）当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為
  <BR>
  （３）当社または第三者の財産を侵害する行為、または侵害する恐れのある行為 
  <BR>
  （４）当社または第三者に経済的損害を与える行為
  <BR>
  （５）当社または第三者に対する脅迫的な行為
  <BR>
  （６）コンピューターウィルス、有害なプログラムを仕様またはそれを誘発する行為 
  <BR>
  （７）本サービス用インフラ設備に対して過度な負担となるストレスをかける行為 
  <BR>
  （８）当サイトのサーバーやシステム、セキュリティへの攻撃 
  <BR>
  （９）当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為
  <BR>
  （１０）一人の利用者が、複数の利用者IDを取得する行為
  <BR>
  （１１）上記の他、当社が不適切と判断する行為
  <BR>
  
  <BR>
  第８条（免責）
  <BR>
  １　当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。
  <BR>
  ２　当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。
  <BR>
  ３　当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。
  <BR>
  ４　当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
  <BR>
  ５　利用者は、AppStore、GooglePlay等のサービスストアの利用規約および運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。
  <BR>
  ６　当社は、本サービスを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償責任を負いません。
  <BR>
  ７　当社は、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。
  <BR>
  ８　第１項乃至前項の規定は、当社に故意または重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。
  <BR>
  ９　前項が適用される場合であっても、当社は、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。　
  <BR>
  １０　本サービスの利用に関し当社が損害賠償責任を負う場合、当該損害が発生した月に利用者から受領した利用額を限度として賠償責任を負うものとします。
  <BR>
  １１　利用者と他の利用者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。
  <BR>
  １２　利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。
  <BR>
  １３　利用者の行為により、第三者から当社が損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、利用者は、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。
  <BR>
  １４　利用者が本サービスの利用に関連して当社に損害を与えた場合、利用者の費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。
  <BR>
  
  <BR>
  第９条（広告の掲載について） 
  <BR>
  利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。 
  <BR>
  
  <BR>
  第１０条（権利譲渡の禁止）
  <BR>
  １　利用者は、予め当社の書面による承諾がない限り、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。
  <BR>
  ２　当社は、本サービスの全部または一部を当社の裁量により第三者に譲渡することができ、その場合、譲渡された権利の範囲内で利用者のアカウントを含む、本サービスに係る利用者の一切の権利が譲渡先に移転するものとします。
  <BR>
  
  <BR>
  第１１条（分離可能性）
  <BR>
  本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
  <BR>
  
  <BR>
  第１２条（当社への連絡方法）
  <BR>
  本サービスに関する利用者の当社へのご連絡・お問い合わせは、本サービスまたは当社が運営するwebサイト内の適宜の場所に設置するお問い合わせフォームからの送信または当社が別途指定する方法により行うものとします。
  <BR>
  
  <BR>
  第１３条（準拠法、管轄裁判所）
  <BR>
  １　本規約の有効性，解釈及び履行については，日本法に準拠し，日本法に従って解釈されるものとする。
  <BR>
  ２　当社と利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡
  <BR>
  
  <BR>
  ２０２０年４月１日　施行
  <BR>
  ２０２０年４月１日　改定
  <BR>
  
  <BR>
  
  `,
    plivacyPolicy: `アプリケーション・プライバシーポリシー\n\n
  2020/4/1 制定
  <BR>

  <BR>
  本アプリケーション・プライバシーポリシーは、zu-de.comがスマートフォン等に向けて提供する図でよくわかる過去問問題集アプリ（以下、『本アプリ』という）から送信される利用者情報とその取り扱いについて説明するものです。
  <BR>
  本アプリのご利用に際しましては、本アプリケーション・プライバシーポリシーの内容をあらかじめご確認いただき、ご了承のうえでご利用ください。
  <BR>

  <BR>

  <BR>
  <BR>1.本アプリ本体から送信される利用者情報、目的、送信先等について<BR>
  <BR>

  <BR>
  【送信される情報】
  <BR>
  <BR>・アプリ独自IDをマーケティング、サービス改善（利用者数、利用頻度などのユーザー動向の把握のため）のために、アプリからzu-de.comに送信します。
  <BR>
  <BR>・利用端末情報（機種名、OSバージョン）をマーケティング、サービス改善（利用者数、利用頻度などのユーザー動向の把握のため）のために、アプリからzu-de.comに送信します。
  <BR>

  <BR>

  <BR>

  <BR>
  【第三者提供の有無】
  <BR>
  <BR>当社に送信される情報は、zu-de.com以外の第三者に提供しておりません。
  <BR>

  <BR>

  <BR>

  <BR>
  【情報送信の停止手段・影響など】
  <BR>
  <BR>送信される情報を停止する手段はありません。やむを得ず送信停止をご希望される場合は、本アプリのご利用を中止してください。
  <BR>

  <BR>

  <BR>

  <BR>
  <BR>2. 情報収集モジュールから送信される利用者情報、目的、送信先等について<BR>
  <BR>
  本アプリでは、以下に記載の情報収集モジュールを利用しています。
  <BR>
  <BR>※情報収集モジュールとは、アプリの利用状況等の統計データ収集やアプリ内での広告表示等を行うために、アプリ本体の機能とは独立した、第三者が作成したプログラムのことです。
  <BR>

  <BR>

  <BR>
  Google Analytics
  <BR>

  <BR>

  <BR>
  【送信される情報】
  <BR>
  <BR>本アプリではGoogle Inc.のアクセス解析ツール「Google Analytics」を利用しており、以下の情報がお客様個人が特定されない統計データとして送信されます。
  <BR>
  <BR>・本アプリのご利用状況（本アプリ訪問回数、滞在時間、閲覧ページ数、画面遷移等）
  <BR>
  <BR>・ご利用端末の情報（端末の設定言語、地域、機種名、OS、ブラウザバージョン、プロバイダ等）
  <BR>

  <BR>

  <BR>

  <BR>
  【利用目的】
  <BR>
  <BR>送信された情報は、本アプリの機能評価、改善およびマーケティング分析のためにzu-de.comで利用します。
  <BR>

  <BR>

  <BR>

  <BR>
  【送信方法・送信先】
  <BR>
  <BR>Google Inc.へ本情報収集モジュールが自動送信します。
  <BR>

  <BR>

  <BR>

  <BR>
  【第三者提供の有無】
  <BR>
  <BR>Google Analyticsを通じて当社が取得した情報は、zu-de.com以外の第三者に提供しておりません。
  <BR>

  <BR>

  <BR>

  <BR>
  【情報収集モジュール提供会社のプライバシーポリシー】
  <BR>
  <BR>http://www.google.com/analytics/learn/privacy.html
  <BR>

  <BR>

  <BR>

  <BR>
  【情報送信の停止手段・影響など】
  <BR>
  <BR>情報送信の停止をご希望される場合、Google Inc.が提供するオプトアウト機能を用いて情報送信停止の操作をお客様ご自身で行ってください。
  <BR>
  (https://tools.google.com/dlpage/gaoptout?hl=ja)
  <BR>
  <BR>※情報送信を停止した場合でも、本アプリのサービス機能は何ら制約なくご利用いただけます。
  <BR>

  <BR>

  <BR>
  <BR>個人情報の収集および取り扱いについて<BR>
  <BR>

  <BR>
  本アプリおよび本アプリに付随するサービスにて収集するお客様の情報に個人情報が含まれる場合、実際に収集を行う場面（個人情報の入力・送信を行う画面等）で利用目的、第三者提供の有無、その他法令に定められた事項を都度提示し、お客様の明示的な同意をあらかじめ取得したうえで収集するものとします。
  <BR>
  <BR>※個人情報とは、個人に関する情報であって、それら情報に含まれる氏名、生年月日、その他の記述によって特定の個人が識別できる情報を指します。（他の情報と容易に照合することができ、それによって特定の個人を識別することが可能となる情報も含みます。）
  <BR>

  <BR>

  <BR>
  <BR>4. 利用者情報の取り扱いに関するお問い合わせ先<BR>
  <BR>
  [zu-de.com　苦情・相談窓口]:	info@open-drill.org
  <BR>

  <BR>
  <BR>本利用規約の変更について<BR>
  <BR>

  <BR>
  本アプリのバージョンアップに伴い、送信される利用者情報の項目、目的、送信先等が変更される場合があり、変更が生じた際には本アプリケーション・プライバシーポリシーを改定します。変更内容等については、本アプリの新バージョンに付随する最新の本アプリケーション・プライバシーポリシーをご参照ください。
  <BR>
  <BR>なお、変更内容によっては、必要に応じてお客様の同意確認を改めて取らせていただく場合があります。
  <BR>`,
  };
  public modalMode: string;
  public modalModeOption: string;
  public kamokuName = {};
  nendoName = {};
  mokujiDataSourceDic = {};
  //kaitouHistory = {};
  sippituMode = true;
  mokujiButtonDifficultyLabel = {
    0: "易<BR>問",
    1: "中<BR>級",
    2: "上<BR>級",
    3: "難<BR>問",
  };
  mokujiButtonColor = {
    0: "#11ff00",
    1: "#bbffdd",
    2: "#ffaa77",
    3: "#cc2222",
  };

  // public currentMokujiStatus = {
  //   //currentKamoku: 106,
  //   categoryModeMokuji: this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab],
  //   levelMokuji: this.firestoreAccessor.currentGuiStatus.level[this.firestoreAccessor.currentGuiStatus.currentTab],
  //   mondaiSyuIdMokuji: this.firestoreAccessor.currentGuiStatus.currentTab,

  // };
  public currentMokujiStatus: {
    //currentKamoku: 106,
    categoryModeMokuji: string;
    levelMokuji: string;
    //mondaiSyuIdMokuji: string
  } = {
    //currentKamoku: 106,
    //mondaiSyuIdMokuji: String(this.firestoreAccessor.currentGuiStatus.currentTab),
    categoryModeMokuji:
      "" +
      this.firestoreAccessor.currentGuiStatus.categoryMode[
        this.firestoreAccessor.currentGuiStatus.currentTab
      ],
    levelMokuji: String(
      this.firestoreAccessor.currentGuiStatus.level[
        this.firestoreAccessor.currentGuiStatus.currentTab
      ]
    ),
  };

  nameCategoryModeMokuji = {};
  //   true: "📂頻出単元順",
  //   false: "📆年度順",
  // };

  nameLevel = {};
  //   0: "🔰初級問題のみ表示",
  //   1: '🌱中級',
  //   2: '🍀上級',
  // };
  nameMondaiSyuId = {};

  pickerColumnsDef = [];
  //   {
  //     name: 'categoryMode',
  //     options: [
  //       {
  //         text: '📂頻出単元順',
  //         value: true
  //       },
  //       {
  //         text: '📆年度順',
  //         value: false
  //       }
  //     ]
  //   },
  //   {
  //     name: 'level',
  //     options: [
  //       {
  //         text: '🔰初級問題のみ表示',
  //         value: 0
  //       },
  //       {
  //         text: '🌱中級',
  //         value: 1
  //       },
  //       {
  //         text: '🍀上級',
  //         value: 2
  //       },
  //     ]
  //   },
  // ];

  public mokujiDataList: MokujiData[] = [];
  public closeButtonVisible = true;
  public tutorial_color;

  //dataList = [];
  public mokujiVirtualList: MokujiData[] = [];
  public windowSize = 10;

  public levelDiscription = `単元別の場合
  初級：ウォーミングアップ。基礎問題。
  中級：全体の３割
  上級：全体の６割（つまりこのレベルの問題が正解できれば合格ライン）
  特級：全問題を表示
年度別の場合
  初級：直近１年。得意な科目は、これプラス苦手補強が効率的。
  中級：直近３年。基本的に３年くらいはやっておくことがよく推奨される。
  上級：直近５年。苦手科目の力試しに。あるいは１科目受験など確実に合格する必要がある場合に。
  特級：全問題を表示

  `;
  //public titleString = '';
  public mappingKamokuIconName;
  //rirekiDataSource: MatTableDataSource<GakusyuRireki>;
  public colorScheme;
  colorSchemeMapping;
  // nendoNameShort;

  // あしあとのグラフ設定
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  yAxisLabel = "問題数";
  showYAxisLabel = false;
  //columnsToDisplayAshiatoHyou = ['saitenKekka', 'mondaiSyuId', 'dateTime', 'nendo', 'name', 'category'];

  // expandedElement: GakusyuRireki | null;
  // expandedElementMokujiData: MokujiData | null;
  visibleChartName: string = "";
  // kaitouMondaiJoin: GakusyuRireki[];
  // dateData: {
  //   "name": string;
  //   "series": {
  //     "value": number;
  //     "name": string;
  //   }[];
  // }[] = [];//積み上げグラフのデータ構造
  ashiato_yScaleMin: number = 0;
  colorScheme_timer = {
    domain: [
      "#ffce00", //経済
      "#f07841", //財務
      "#7044ff", //経営
      "#3880ff", //運営
      "#0cd1e8", //法務
      "#10dc60", //情報
      "#96988f", //中小
    ],
  };
  ashiatoRangeVisible: boolean = false;
  //faqItems;
  slideOpts = {
    spaceBetween: 0,
    autoHeight: "true",
    followFinger: true,
  };
  slideNumber: number = 0;
  slideEndBool: boolean = false;
  slideLength: number = 10;
  kamokuSelected: boolean = false;
  // policyVisible:boolean = false;
  // riyoukiyakuVisible:boolean = false;
  policyAndRiyoukiyakuVisible: boolean = false;
  chartSize: number[] = [400, 300];
  saitenKekkaJson = {};
  duration: number;
  saitenKekkaMessageString: string = "";
  acceptButtonVisible: boolean = false;
  modalClosed: boolean = false;
  gouhiStrArr = ["0", "0", "0", "0"];
  //gouhiJouhouTargetMondaiId = [201020111,202020111,203020111,204020111];
  tokutenArr = [0, 0, 0, 0];
  tokutenSelectArr = [
    100, 99, 98, 97, 96, 95, 94, 93, 92, 91, 90, 89, 88, 87, 86, 85, 84, 83, 82,
    81, 80, 79, 78, 77, 76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63,
    62, 61, 60, 59, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44,
    43, 42, 41, 40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25,
    24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5,
    4, 3, 2, 1, 0,
  ];
  gouhiStrTokutenKaijiNasiArr = ["合格", "A", "B", "C", "D"];

  result: any = "";
  ocrFullString = "";
  hitYougoZahyouData = [];
  myImage: string = null;
  myImageBase64Data = "";
  imgWidth = 0;
  imgHeight = 0;

  keyWord = "";
  currentYougoData: YougoData;
  searchedYougoDataArray: YougoData[] = [];
  ocrResultString: string = "";
  urlParamUrlOptionString: string = "";
  jireiNameDic = {
    0:'ALL',
    1:'I',
    2:'II',
    3:'III',
    4:'IV',
  }

  ////定数定義ここまで///////////////////////

  constructor(
    private navParams: NavParams,
    public modalController: ModalController,
    private router: Router,
    public domSanitizer: DomSanitizer,
    //public socialSharing: SocialSharing,
    public platform: Platform,
    public firestoreAccessor: FirestoreAccessorService,
    private todoService: TodoService,
    public alertController: AlertController,
    //private appAvailability: AppAvailability,
    public toastController: ToastController,
    //private screenshot: Screenshot,
    public pickerCtrl: PickerController,
    public loadingCtrl: LoadingController,
    private vision: GoogleCloudVisionServiceService
  ) {
    // this.currentMokujiStatus.categoryModeMokuji = this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab];
    // this.currentMokujiStatus.levelMokuji = this.firestoreAccessor.currentGuiStatus.level[this.firestoreAccessor.currentGuiStatus.currentTab];
    // this.currentMokujiStatus.mondaiSyuIdMokuji = this.firestoreAccessor.currentGuiStatus.currentTab;

    this.modalMode = this.navParams.get("modalMode");
    this.kamokuName = this.navParams.get("kamokuName");
    this.nendoName = this.navParams.get("nendoName");
    //this.nendoNameShort = this.navParams.get('nendoNameShort');
    //this.mokujiDataSourceDic = this.navParams.get('mokujiDataSourceDic');
    //this.kaitouHistory = this.navParams.get('kaitouHistory');
    this.sippituMode = this.navParams.get("sippituMode");
    this.firestoreAccessor = this.navParams.get("firestoreAccessor");
    this.todoService = this.navParams.get("todoService");
    this.urlParamUrlOptionString = this.navParams.get("urlParamUrlOptionString");
    if (this.modalMode == "mokuji") {
      //(this.firestoreAccessor){
      //this.reloadMokuji();
    }
    //this.setMokujiDataList();
    this.closeButtonVisible = this.navParams.get("closeButtonVisibler");

    //this.mokujiDataList = this.firestoreAccessor.getMokujiDataList(this.firestoreAccessor.currentGuiStatus.currentTab,this.currentMokujiStatus.categoryModeMokuji,this.currentMokujiStatus.levelMokuji );

    this.nameCategoryModeMokuji = this.navParams.get("nameCategoryModeMokuji");
    this.nameLevel = this.navParams.get("nameLevel");
    this.nameMondaiSyuId = this.navParams.get("nameMondaiSyuId");

    this.pickerColumnsDef = this.navParams.get("pickerColumnsDef");
    this.tutorial_color = this.navParams.get("tutorial_color");
    this.mappingKamokuIconName = this.navParams.get("mappingKamokuIconName");
    this.duration = Number(this.navParams.get("duration"));

    // for (let i = 0; i < this.windowSize; i++) {
    //   if (this.mokujiDataList[i] != null) {
    //     //配列末尾に到達していないときのみ、Virtual配列に追記
    //     this.mokujiVirtualList.push(this.mokujiDataList[i]);
    //   }
    // }

    // for (let i = 0; i < this.windowSize; i++) {
    //   if (this.firestoreAccessor.kaijiData[i] != null) {
    //     //配列末尾に到達していないときのみ、Virtual配列に追記
    //     this.firestoreAccessor.kaijiVirtualData.push(this.firestoreAccessor.kaijiData[i]);
    //     //console.log("this.firestoreAccessor.kaijiData[i]--",this.firestoreAccessor.kaijiData[i].tokutenJirei[0])
    //   }
    // }

    // if ( this.modalMode == 'mokuji'){
    //   this.titleString = 'もくじ';
    // } else if ( this.modalMode == 'ashiato'){
    //   this.titleString = '学習のあしあと';
    // }

    // this.targetMondai = this.navParams.get('targetMondai');
    // this.targetUrl = this.navParams.get('targetUrl');
    // this.mondaiIdArray = this.navParams.get('mondaiIdArray');
    // this.myName = this.navParams.get('myName');
    // this.snsButtonIcon = this.navParams.get('snsButtonIcon');
    // this.nendoName = this.navParams.get('nendoName');
    // this.kamokuName = this.navParams.get('kamokuName');

    // this.kansouTextareaString = "";
    // this.kamokuName = this.navParams.get('kamokuName');
    // this.mentionMessage = this.navParams.get('mentionMessage');

    // this.kansouSlideName = this.navParams.get('kansouSlideName');
    // this.sakuinKeyword = this.navParams.get('sakuinKeyword');

    // this.ouenBunshou = this.navParams.get('ouenBunshou');
    // this.snsBunshou = this.navParams.get('snsBunshou');
    // this.dateData = this.navParams.get('dateData');
    this.colorScheme = this.navParams.get("colorScheme");
    this.colorSchemeMapping = this.navParams.get("colorSchemeMapping");
    // this.rirekiDataSource = this.navParams.get('rirekiDataSource');
    //this.rirekiDataSource.paginator = this.paginator;

    //this.firestoreAccessor.reloadAshiatoData();

    // if (this.modalMode == 'faq'){
    //   //
    //   const searchbar = document.querySelector('ion-searchbar');
    //   this.faqItems = Array.from(document.querySelector('ion-list').children);

    //   searchbar.addEventListener('ionInput', this.handleInput);
    // }

    this.modalModeOption = this.navParams.get("modalModeOption");
    if (this.modalMode == "saitenKekka") {
      //正解不正解の結果などを変数に格納する
      //console.log('this.modalModeOption_'+this.modalModeOption);
      this.saitenKekkaJson = JSON.parse(this.modalModeOption);
      //kekkaJson = {'sentakuMode':operationName,'kekka':'','kekka_lastTimeKaitou':''};
      if (this.saitenKekkaJson["sentakuMode"] == "exclude") {
        //除外の場合のメッセージ
        if (this.saitenKekkaJson["kekka"] == "true") {
          this.saitenKekkaMessageString = "正解（除外で良い選択肢です）";
        } else {
          this.saitenKekkaMessageString =
            "不正解（除外した選択肢は正解でした）";
        }
      } else if (this.saitenKekkaJson["sentakuMode"] == "sentaku") {
        //選択の場合のメッセージ
        if (this.saitenKekkaJson["kekka"] == "true") {
          //正解の場合
          if (this.saitenKekkaJson["kekka_lastTimeKaitou"] == "") {
            //初回＋正解
            this.saitenKekkaMessageString = "正解（初解答）👌";
          } else if (this.saitenKekkaJson["kekka_lastTimeKaitou"] == "true") {
            //正解、正解
            this.saitenKekkaMessageString = "正解（連続正解）💪";
          } else if (this.saitenKekkaJson["kekka_lastTimeKaitou"] == "false") {
            //不正解、正解
            this.saitenKekkaMessageString = "正解（前回は不正解）👑";
          }
        } else if (this.saitenKekkaJson["kekka"] == "false") {
          //不正解の場合
          if (this.saitenKekkaJson["kekka_lastTimeKaitou"] == "") {
            //初回＋不正解
            this.saitenKekkaMessageString = "不正解（初解答）☔";
          } else if (this.saitenKekkaJson["kekka_lastTimeKaitou"] == "true") {
            //正解＋不正解
            this.saitenKekkaMessageString = "不正解（前回は正解）💣";
          } else if (this.saitenKekkaJson["kekka_lastTimeKaitou"] == "false") {
            //不正解＋不正解
            this.saitenKekkaMessageString = "不正解（前回も不正解）🔥";
          }
        }
      }
    }

    //durationが設定されていれば（0でない）、設定時間経過後にDismissする
    if (this.duration != 0) {
      this.delay(this.duration).then((any) => {
        if (!this.modalClosed) {
          //手動でModalが閉じられていなければ、閉じる
          this.modalController.dismiss();
        }
      });
    }

    if (this.modalMode == "gouhiJouhou") {
      //合否情報提供の場合は、合否情報の空データを作成する
      for (let i_gjt in this.firestoreAccessor.gouhiJouhouMaster) {
        let cMData = this.firestoreAccessor.getOneMondaiFromFile(
          this.firestoreAccessor.gouhiJouhouMaster[i_gjt].mondaiId
        );
        if (
          !this.firestoreAccessor.currentGuiStatus.gouhi_jouhou[
            this.firestoreAccessor.getMondaiIdSentou5(cMData.mondaiId)
          ]
        ) {
          this.firestoreAccessor.currentGuiStatus.gouhi_jouhou[
            this.firestoreAccessor.getMondaiIdSentou5(cMData.mondaiId)
          ] = {
            gouhiView: "",
            mondaiSyuId: cMData.mondaiSyuId,
            myName: this.firestoreAccessor.currentGuiStatus.myName,
            nendo: cMData.nendo,
            visible: "student",
            handleName: this.firestoreAccessor.currentGuiStatus.handleName, //"匿名",
            tokutenKoukai: true,
          };
        }
      }

      //合否情報提供の場合は、表示用の合否情報のデータをRESTで取得してデータ整形する
      this.firestoreAccessor.init_gouhi_jouhou();
    }
  }

  dismissModal(res?: any) {
    let responce = {};
    if (res) {
      responce = res;
    }
    this.modalController.dismiss(responce);
    //手動でModalを閉じた場合に、指定時間後のDismiss処理が走らないようフラグ建てる
    this.modalClosed = true;
  }

  ngOnInit() {}
  ngAfterViewInit() {
    if (this.modalMode == "faq" || this.modalMode == "welcome" || this.modalMode == "kaiji") {
      //twitterの対処///////////
      var script = document.createElement("script"); //scriptタグを作ります
      script.async = true;
      script.setAttribute("src", "https://platform.twitter.com/widgets.js");
      script.setAttribute("charset", "utf-8");
      //aタグ、scriptタグの順で設置します
      var div = document.getElementById("scriptTag-twitter"); //ボタンを置きたい場所の手前の要素を取得
      // div.parentNode.insertBefore(element,div.nextSibling);//ボタンを置きたい場所にaタグを追加
      div.parentNode.insertBefore(script, div.nextSibling); //scriptタグを追加してJSを実行し、aタグをボタンに変身させる
    }
  }
  ///ngOnInit() {
  ionViewWillEnter() {
    let chartColors = {
      red: "rgba(255, 200, 153, 0.5)",
      blue: "rgba(150, 150, 255, 0.5)",
    };
    if (this.modalMode == "ranking") {
      this.context01 = this.mycanvas01.nativeElement.getContext("2d");
      let mycanvas01 = new Chart(this.context01, {
        type: "scatter",
        data: {
          datasets: [
            {
              label: "schatter dots",
              borderColor: chartColors.red,
              //backgroundColor: Chart.'helpers'.color(chartColors.red).rgbString(),//capacitor3.0化の過程でエラーになるのでコメントアウト
              pointRadius: 10,

              data: [
                {
                  x: 20.3,
                  y: 13.43,
                },
                {
                  x: 17.9,
                  y: 4.2,
                },
                {
                  x: 10.9,
                  y: 15.2,
                },
              ],
            },
          ],
        },
        // ,
        // option:{
        //   title: {
        //     display: true,
        //     text: "Chart.js Scatter Chart"
        //   },
        // }
      });
      // let mycanvas01 = new Chart(this.context01, {
      //   type: 'line',
      //   data: {
      //     datasets: [{
      //       label: 'Data01',
      //       backgroundColor: 'rgba(255, 255, 153, 0.5)',
      //       borderColor: "rgba(255, 99, 132, 0)",
      //       pointRadius: 0,
      //       fill: true,
      //       data: [
      //         { x: 0, y: 2 },
      //         { x: 1, y: 1 },
      //         { x: 2, y: 2.5 },
      //         { x: 3, y: 5 },
      //         { x: 4, y: 3 },
      //         { x: 5, y: 4 },
      //         { x: 6, y: 9 },
      //         { x: 7, y: 7 },
      //         { x: 8, y: 12 },
      //       ],
      //     }, {
      //       label: 'Data02',
      //       backgroundColor: 'rgba(153, 255, 255, 0.5)',
      //       borderColor: "rgba(255, 99, 132, 0)",
      //       pointRadius: 0,
      //       fill: true,
      //       data: [
      //         { x: 0, y: 1 },
      //         { x: 1, y: 4 },
      //         { x: 2, y: 8 },
      //         { x: 3, y: 12 },
      //         { x: 4, y: 1 },
      //         { x: 5, y: 5 },
      //         { x: 6, y: 2 },
      //         { x: 7, y: 3 },
      //         { x: 8, y: 1 },
      //       ],
      //     }]
      //   },
      //   options: {
      //     responsive: true,
      //     tooltips: {
      //       mode: 'index',
      //       intersect: false,
      //     },
      //     title: {
      //       display: true,
      //       text: 'Angular & Chart.js'
      //     },
      //     scales: {
      //       xAxes: [{
      //         type: 'linear',
      //         position: 'bottom',
      //         scaleLabel: {
      //           labelString: 'X',
      //           display: true,
      //         }
      //       }],
      //       yAxes: [{
      //         type: 'linear',
      //         scaleLabel: {
      //           labelString: 'Y',
      //           display: true
      //         }
      //       }]
      //     }
      //   }
      // });

      this.context02 = this.mycanvas02.nativeElement.getContext("2d");
      //capacitor3対応に伴うChartjsコス員でエラーになるのコメントアウト
      // let mycanvas02 = new Chart(this.context02, {
      //   type: "bar",
      //   data: {
      //     datasets: [
      //       {
      //         label: "Bar Data",
      //         backgroundColor: "rgba(255, 255, 153, 0.5)",
      //         borderColor: "rgba(255, 99, 132, 0)",
      //         data: [5, 20, 30, 40],
      //       },
      //       {
      //         label: "Line Data",
      //         backgroundColor: "rgba(153, 255, 255, 0)",
      //         borderColor: "rgba(153, 255, 255, 1)",
      //         data: [45, 37, 39, 24],
      //         type: "line",
      //         pointRadius: 0,
      //         fill: false,
      //       },
      //     ],
      //     labels: ["January", "February", "March", "April"],
      //   },
      //   options: {
      //     responsive: true,
      //     title: {
      //       display: true,
      //       text: "Angular & Chart.js",
      //     },
      //     tooltips: {
      //       mode: "index",
      //       intersect: false,
      //     },
      //     scales: {
      //       yAxes: [
      //         {
      //           type: "linear",
      //           scaleLabel: {
      //             labelString: "Y",
      //             display: true,
      //           },
      //         },
      //       ],
      //     },
      //   },
      // });

      this.context03 = this.mycanvas03.nativeElement.getContext("2d");
      //capacitor3対応に伴うChartjsコス員でエラーになるのコメントアウト
      // let mycanvas03 = new Chart(this.context03, {
      //   type: "line",
      //   data: {
      //     labels: sampleData.labels,
      //     datasets: [
      //       {
      //         label: "sample1",
      //         data: sampleData.sample1,
      //         lineTension: 0,
      //         backgroundColor: "rgba(60, 160, 220, 0.3)",
      //         borderColor: "rgba(60, 160, 220, 0.8)",
      //       },
      //       {
      //         label: "sample2",
      //         data: sampleData.sample2,
      //         lineTension: 0,
      //         steppedLine: true,
      //         backgroundColor: "rgba(60, 190, 20, 0.3)",
      //         borderColor: "rgba(60, 190, 20, 0.8)",
      //       },
      //     ],
      //   },
      //   options: {
      //     title: {
      //       display: true,
      //       text: "Angular & Chart.js",
      //     },
      //     tooltips: {
      //       mode: "index",
      //       intersect: false,
      //     },
      //     scales: {
      //       yAxes: [
      //         {
      //           ticks: {
      //             beginAtZero: true,
      //           },
      //         },
      //       ],
      //     },
      //     legend: {
      //       display: true,
      //       position: "left",
      //       labels: {
      //         fontSize: 18,
      //         boxWidth: 10,
      //         filter: function (item, chart) {
      //           return !item.text.includes("2");
      //         },
      //       },
      //     },
      //   },
      // });

      this.context04 = this.mycanvas04.nativeElement.getContext("2d");
      let mycanvas04 = new Chart(this.context04, {
        type: "line",
        data: {
          datasets: [
            {
              label: "sample1",
              data: [
                {
                  t: new Date("2018/04/16 22:18"),
                  y: 100,
                },
                {
                  t: new Date("2018/04/16 23:18"),
                  y: 105,
                },
                {
                  t: new Date("2018/04/17 00:18"),
                  y: 56,
                },
                {
                  t: new Date("2018/04/17 01:18"),
                  y: 132,
                },
                {
                  t: new Date("2018/04/17 05:18"),
                  y: 154,
                },
                {
                  t: new Date("2018/04/17 06:58"),
                  y: 123,
                },
              ],
              //lineTension: 0,//capacitor3対応に伴うChartjsコス員でエラーになるのコメントアウト
              backgroundColor: "rgba(60, 160, 220, 0.3)",
              borderColor: "rgba(60, 160, 220, 0.8)",
            },
          ],
        },
        options: {
          // title: {
          //   display: true,
          //   text: "Angular & Chart.js",
          // },
          // tooltips: {
          //   mode: "index",
          //   intersect: false,
          // },
          // scales: {//lineTension: 0,//capacitor3対応に伴うChartjsコス員でエラーになるのでコメントアウト
          //   xAxes: [
          //     {
          //       type: "time",
          //       distribution: "linear",
          //       ticks: {
          //         source: "auto",
          //       },
          //     },
          //   ],
          //   yAxes: [
          //     {
          //       ticks: {
          //         beginAtZero: true,
          //       },
          //     },
          //   ],
          // },
        },
      });
    }
  }

  ionViewDidEnter() {
    //
    if (this.modalMode == "faq") {
      this.getSlideLength();
      this.slides.startAutoplay();
    } else if (this.modalMode == "welcome") {
      //this.slides.lockSwipes(true);
      this.slides.startAutoplay();
      this.getSlideLength();
      //console.log('auto play STart  ')
      // this.slides.lockSwipeToNext(true);
      // this.slides.lockSwipeToPrev(true);
    } else if (this.modalMode == "yougo") {
      if (this.urlParamUrlOptionString != ""){
        //用語のリンクからの場合、その用語で検索をかける
        this.keyWord = this.urlParamUrlOptionString.replace('yougo', '');
        this.keywordChanged();
      } else {
        this.takePicture();

      }
    }
    this.chartSize = [
      window.document.body.clientWidth * 0.8,
      window.document.documentElement.clientHeight * 0.5,
    ];
  }

  // closeModal(mondaiIdMoveTo?:number) {
  //   // if(!mondaiIdMoveTo){
  //   //   mondaiIdMoveTo="";
  //   // }
  //   this.modalController.dismiss(mondaiIdMoveTo);
  // }

  // setCurrentKamoku(kamokuId:number){
  //   this.currentMokujiStatus.currentKamoku = kamokuId
  //   //this.slides.slideNext();
  //   this.slides.slideTo(1);
  //   this.delay(500).then(any => {
  //     this.currentMokujiStatus.currentSlide = 1;
  //     this.updateSlides();
  //   });
  // }

  // async setCurrentMokujiCategory(kamokuNum: number, mokujiItem: MokujiData) {
  //   console.log("このもくじをカレントにセットします。" + mokujiItem);
  //   // this.firestoreAccessor.currentGuiStatus.currentMokuji[kamokuNum] = mokujiItem;
  //   // //最初の問題をカレントにセットする
  //   // this.jumpToMondai(mokujiItem.mondaiSyuId,mokujiItem.mokujiMondaiList[0]);
  //   // this.slides.slideNext();

  // }

  // jumpToMondai(targeKamokuId:number,targetMondaiId:number){
  //   console.log("この問題をカレントにセットします。" + targetMondaiId);
  //   this.firestoreAccessor.currentGuiStatus.selectedSentakushi[ targeKamokuId ] = '';//radiobutton初期化
  //   this.firestoreAccessor.currentGuiStatus.mondaiVisible[targeKamokuId] = true;
  //   this.firestoreAccessor.getOneMondaiFromCfs(targeKamokuId,targetMondaiId,this.sippituMode);
  //   // this.slides.slideNext();

  //   // this.delay(4000).then(any => {
  //   //   this.updateSlides();
  //   // });
  // }

  // close() {
  //   this.modalController.dismiss();
  // }

  // ionViewDidEnter() {
  //   //this.printMessage()
  //   let mondaiDoc = this.firestoreAccessor.snsPng[this.targetMondai.mondaiId];
  //   setTimeout(() => {
  //     let b64: string = mondaiDoc[this.slideName];
  //     console.log('AAA_' + b64);
  //     setTimeout(() => {
  //       this.writeKansou(b64);
  //       console.log('BB_' + b64);
  //       setTimeout(() => {
  //         console.log('CCC_' + b64);
  //       }, 1000)
  //     }, 200)
  //   }, 1000)
  //   //this.presentAlert();
  // }
  // async presentAlert() {
  //   const alertController = document.querySelector('ion-alert-controller');
  //   await alertController.componentOnReady();

  //   const alert = await alertController.create({
  //     header: 'Alert',
  //     subHeader: 'Subtitle',
  //     message: 'This is an alert message.',
  //     buttons: ['OK']
  //   });
  //   return await alert.present();
  // }

  // printMessage = async () => {

  //   const res1 = await this.promise('hello', 1000)

  //   const message1 = res1 + 'async'
  //   const res2 = await this.promise(message1, 500)

  //   const message2 = res2 + 'world'
  //   const res3 = await this.promise(message2, 2000)
  // }

  // promise = (message, msec) => new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     console.log(message);
  //     resolve(message);
  //   }, msec);
  // });

  // writeKansou(){//(base64Img:string) {
  //   if ( this.modalMode =='syuuseiRequest'){
  //     //修正リクエストのリクエスト内容と場所を追記する
  //     this.kansouTextareaString = this.request +"," + this.basyo + "," + this.kansouTextareaString;
  //     this.presentToastWithOptions('質問を送信しました');
  //     this.modalController.dismiss();
  //   } else if ( this.modalMode =='sns'){
  //     //this.showSnsShareDialog();//(base64Img);
  //   }
  //   // else if ( this.modalMode =='twitter'){
  //   //   this.kansouTextareaString = `#中小企業診断士試験 #smec`;
  //   //   this.shareTwitter(base64Img);
  //   // } else if ( this.modalMode =='facebook'){
  //   //   this.shareFacebook(base64Img);
  //   // } else if ( this.modalMode =='line'){
  //   //   this.shareLine(base64Img);
  //   // }

  //   // //書き込み日時文字列生成
  //   // let kansouDate = new Date();
  //   // kansouDate.setTime(kansouDate.getTime() );

  //   // const tmpKansou : Kansou = {
  //   //   timeStamp: kansouDate,
  //   //   sendTo: this.modalMode,
  //   //   kansou: this.kansouTextareaString,
  //   //   myName: this.myName,
  //   // };

  //   // //全パターン共通処理。感想をCFSに保存。
  //   // if (this.targetMondai){
  //   //   //mondaiが存在する場合は、引数に使う
  //   //   this.firestoreAccessor.insertKansou( this.targetMondai.mondaiId, tmpKansou );
  //   // } else {
  //   //   //mondaiがぞ存在しない場合（解説CARD以外から質問が来た場合）は、固定値を使用
  //   //   this.firestoreAccessor.insertKansou( 100000000, tmpKansou );
  //   // }

  // }

  // async presentAlert(snsName:string) {
  //   const alert = await this.alertController.create({
  //     header: `${snsName}がインストールされていません`,
  //     subHeader:`（パソコンや${snsName}がインストールされて いない スマートホンではこの機能をご利用いただけません）`,
  //     message: `${snsName}がインストールされたスマートホンでは、このページの画像をシェアできます。`,
  //     buttons: ['OK']
  //   });

  //   await alert.present();
  // }

  // showSnsShareDialog(){//base64Img: string) {
  //   //let base64ImgFull = 'data:image/png;base64,' + base64Img;
  //   this.modalController.dismiss();

  //   let subjectNameAndFileName =
  //     'From_open-drill_org_' + this.targetMondai.mondaiId;
  //   var self = this;
  //   this.screenshot.URI(80).then(res => {
  //     this.screenShotData = res.URI;
  //     this.screenShotstate = true;
  //     self.img.src = res.URI;
  //     self.img.onload = () => {

  //       try {
  //         let options = {
  //           message: this.snsBunshou,//mondaiGaiyouString + '#中小企業診断士試験 #smec',
  //           subject: subjectNameAndFileName,//mondaiGaiyouString,
  //           files: [res.URI],//,'data:image/png;base64,' + base64Img],//2ファイル添付はTWITTERが選択肢に出ないため除外
  //           url: this.targetUrl,
  //           chooserTitle: 'シェアするアプリを選択..',
  //         }
  //         this.socialSharing.shareWithOptions(options);
  //       }
  //       catch (err) {
  //         alert(err.message);
  //       }
  //     };
  //   });
  // }

  // async shareTwitter(base64Img:string) {

  //   this.socialSharing.canShareVia(
  //     this.apNameString
  //   ).then(() => {
  //     // Sharing via is possible
  //     this.modalController.dismiss();
  //     this.socialSharing.shareViaTwitter(this.kansouTextareaString,
  //       'data:image/png;base64,' + base64Img
  //       , this.targetUrl).then(() => {
  //         // Success
  //       }).catch((e) => {
  //         // Error!
  //       });
  //   }).catch(() => {
  //     this.presentAlert('twitter');
  //   });

  // }

  // async shareFacebook(base64Img: string) {
  //   this.socialSharing.canShareVia(
  //     this.apNameString
  //   ).then(() => {
  //     // Sharing via is possible
  //     this.modalController.dismiss();
  //     this.socialSharing.shareViaFacebook(this.targetMondai.mondaiBun,
  //               'data:image/png;base64,' + base64Img
  //               , this.targetUrl).then(() => {
  //                 // Success
  //               }).catch((e) => {
  //                 // Error!
  //               });
  //   }).catch(() => {
  //     this.presentAlert('Facebook');
  //   });

  // }

  // async shareLine(base64Img: string) {
  //   let shareScheme;
  //   if (this.platform.is('ios')) {
  //     shareScheme = 'jp.naver.line.Share';
  //   } else if (this.platform.is('android')) {
  //     shareScheme = 'jp.naver.line.android';
  //   }

  //   this.socialSharing.shareVia(shareScheme, 'title kanaa', '', '', this.targetUrl).then(() => {
  //     // Success
  //   }).catch((e) => {
  //     // Error!
  //     this.presentAlert('LINE');
  //   });

  // }

  // osasenaiCheck() {//リクエスト内容と場所をSelectしていれば、ボタンを押せるようにする
  //   if (this.request  && this.basyo  ){
  //     this.osasenai = false;
  //   }else{
  //     this.osasenai = true;
  //   }
  // }

  // save() {
  //   this.modalController.dismiss();
  // }

  generateSnsLinkUrlKaisetu(mondaiId: number) {
    let domainUrl: string = location.origin; //http・・・.comや8100まで
    if (domainUrl.indexOf("localhost") > -1) {
      //ドメインにlocalhostが含まれている場合は、zu-de.comに変更
      domainUrl = "https://app.zu-de.com";
    }

    let urlStringToReturn: string =
      domainUrl + "/m/" + mondaiId + "/false/s/t/false/noTango";
    return urlStringToReturn;
  }

  // ngAfterViewInit() {

  //   //twitterの対処///////////
  //   var script = document.createElement('script');//scriptタグを作ります
  //   script.async = true;
  //   script.setAttribute('src',"https://platform.twitter.com/widgets.js");
  //   script.setAttribute('charset','utf-8');
  //   //aタグ、scriptタグの順で設置します
  //   var div = document.getElementById("scriptTag-twitter");//ボタンを置きたい場所の手前の要素を取得
  //   // div.parentNode.insertBefore(element,div.nextSibling);//ボタンを置きたい場所にaタグを追加
  //   div.parentNode.insertBefore(script,div.nextSibling);//scriptタグを追加してJSを実行し、aタグをボタンに変身させる

  //   //FBのスクリプトタグ生成。
  //   var scriptFacebook = document.createElement('script');//scriptタグを作ります
  //   scriptFacebook.async = true;
  //   scriptFacebook.defer = true;
  //   scriptFacebook.setAttribute('charset','utf-8');
  //   scriptFacebook.setAttribute('src',"assets/facebook.js");
  //   scriptFacebook.setAttribute('crossorigin',"anonymous");
  //   var divFacebook = document.getElementById("scriptTag-facebook");//ボタンを置きたい場所の手前の要素を取得
  //   divFacebook.parentNode.insertBefore(scriptFacebook,divFacebook.nextSibling);//scriptタグを追加してJSを実行し、aタグをボタンに変身させる

  // }

  async delay(ms: number) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), ms)).then(() =>
      this.wait_delay_end()
    );
  }

  wait_delay_end() {} //WAITのための何もしないメソッド

  // closeMokujiAndReturnSelectedMondai(targeKamokuId: number, targetMondaiId: number, mokujiItem: MokujiData) {
  //   //this.displaySpinner(`お待ち下さい..`,1500);
  //   //もくじから開いた問題のプレビュー画面で、移動するボタン押下時に呼ばれるメソッド。カレントの問題ともくじをセットして、ボタン押下時のcategoryModeやlevelをcurrentGUIに保存して、トーストで変更したことを表示する
  //   this.displaySpinner(`お待ち下さい..`, 1500);
  //   this.delay(50).then(any => {
  //     let res = {};

  //     // this.firestoreAccessor.jumpToMondaiAndMokuji(targeKamokuId,targetMondaiId,categoryMode,mokujiItem);
  //     this.firestoreAccessor.currentGuiStatus.currentMokuji[targeKamokuId] = mokujiItem;
  //     if (this.currentMokujiStatus.categoryModeMokuji == 'true'){
  //       this.firestoreAccessor.currentGuiStatus.categoryMode[targeKamokuId] = true;
  //     } else {
  //       this.firestoreAccessor.currentGuiStatus.categoryMode[targeKamokuId] = false;
  //     }
  //     this.firestoreAccessor.currentGuiStatus.level[targeKamokuId] = Number(this.currentMokujiStatus.levelMokuji);

  //     // this.firestoreAccessor.getOneMondaiFromCfs(targeKamokuId,targetMondaiId,this.sippituMode);
  //     let toastMessage = this.nendoName[mokujiItem.mokujiCategory];
  //     let duration = 4000;
  //     if (Object.keys(this.firestoreAccessor.kaitouHistory).length < 8) {
  //       //初回解答前の、チュートリアル
  //       toastMessage =
  //         `🔰 それでは解答してみましょう！\n💙 で選択..`;
  //       duration = 8000;
  //     } else if (this.firestoreAccessor.currentGuiStatus.categoryMode[targeKamokuId]) {
  //       toastMessage =
  //         `『 ${mokujiItem.mokujiCategory} 』\nの問題を表示しました。頑張ってください！😃`;
  //     } else {
  //       toastMessage =
  //         `『 ${this.nendoName[mokujiItem.mokujiCategory]} 』\nの問題を表示しました。頑張ってください！😃`;
  //     }
  //     this.presentToastWithOptions(toastMessage, duration, 'bottom');

  //     res['mode'] = 'mokuji';
  //     res['targeKamokuId'] = targeKamokuId;
  //     res['targetMondaiId'] = targetMondaiId;
  //     res['mokujiItem'] = mokujiItem;

  //     this.modalController.dismiss(res);
  //   });
  // }

  // async openPicker() {
  //   const picker = await this.pickerCtrl.create({

  //     columns: this.pickerColumnsDef,
  //     buttons: [
  //       {
  //         text: '⬅️とじる',
  //         role: 'cancel'
  //       },
  //       // {
  //       //   text: '❔説明..',
  //       //   handler: (value) => {
  //       //     // this.presentToastWithOptions(
  //       //     //   `初級：ごく基本的な内容です
  //       //     //   中級：全体の３割
  //       //     //   上級：全体の６割（つまりこのレベルの問題が正解できれば合格ライン）
  //       //     //   特級：全問題を表示
  //       //     //   `, 180000, 'top', 'dark')
  //       //     alert(this.levelDiscription);
  //       //     //this.presentAlert('aaa');
  //       //   }
  //       // },
  //       {
  //         text: '🔄もくじ変更',
  //         handler: (value) => {
  //           //console.log(`Got Value ${value.categoryMode.value}`);
  //           this.displaySpinner(`もくじを変更しています..`, 1500);
  //           this.delay(500).then(any => {
  //             this.mokujiVirtualList = [];

  //             //もくじ状態の更新
  //             this.currentMokujiStatus.categoryModeMokuji = value.categoryMode.value;
  //             this.currentMokujiStatus.levelMokuji = value.level.value;
  //             this.currentMokujiStatus.mondaiSyuIdMokuji = value.mondaiSyuId.value;

  //             //もくじの再読み込み
  //             this.mokujiDataList = this.firestoreAccessor.getMokujiDataList(this.currentMokujiStatus.mondaiSyuIdMokuji, this.currentMokujiStatus.categoryModeMokuji, this.currentMokujiStatus.levelMokuji);
  //             for (let i = 0; i < this.windowSize; i++) { //Virtualの方のもくじのリストも再読み込み
  //               if (this.mokujiDataList[i] != null) {
  //                 //配列末尾に到達していないときのみ、Virtual配列に追記
  //                 this.mokujiVirtualList.push(this.mokujiDataList[i]);
  //               }
  //             }

  //           });
  //           // //レベルをここで変える
  //           // console.log("AAA_"+JSON.stringify(this.currentMokujiStatus));
  //         }
  //       }
  //     ]
  //   });

  //   //表示の初期状態設定
  //   // picker.columns[0].selectedIndex = 1;
  //   // if (this.currentMokujiStatus.categoryModeMokuji){
  //   //   picker.columns[0].selectedIndex = 0;
  //   // }
  //   // picker.columns[1].selectedIndex = this.currentMokujiStatus.level;
  //   await picker.present();
  // }

  async displaySpinner(message: string, milliSecounds?: number) {
    let milliSec: number = 100;
    if (milliSecounds) {
      milliSec = milliSecounds;
    }

    const loading = await this.loadingCtrl.create({
      //spinner: spinner,//"lines",
      message: message,
      duration: milliSec, //100
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }

  // setMokujiDataList(){
  //   let tmpMokujiData: MokujiData[] = [];
  //   if (this.currentMokujiStatus.categoryModeMokuji){
  //     //categoryModeの場合は、単語が設定されているもの（すなわちカテゴリーモードのもくじ）を取得
  //     tmpMokujiData = this.firestoreAccessor.mokujidataOnFile.filter(data => {
  //       return (data.mondaiSyuId == Number(this.firestoreAccessor.currentGuiStatus.currentTab)&&data.tangoId );
  //     });
  //     //カテゴリ出題の場合は、問題の数が多い順にソート
  //     tmpMokujiData.sort(function (a, b) {
  //       if (a.mokujiMondaiList.length > b.mokujiMondaiList.length) return -1;
  //       if (a.mokujiMondaiList.length < b.mokujiMondaiList.length) return 1;
  //       return 0;
  //     });
  //   } else {
  //     tmpMokujiData = this.firestoreAccessor.mokujidataOnFile.filter(data => {
  //       return (data.mondaiSyuId == Number(this.firestoreAccessor.currentGuiStatus.currentTab)&&!data.tangoId );
  //     });
  //     //年ごと出題の場合は、年の番号順にソート
  //     tmpMokujiData.sort(function (a, b) {
  //       if (a.mokujiCategory > b.mokujiCategory) return -1;
  //       if (a.mokujiCategory < b.mokujiCategory) return 1;
  //       return 0;
  //     });
  //   }

  //   //this.mokujiDataSourceDic[key][''+tmpCategoryMd[trueFalse]] = new MatTableDataSource(tmpMokujiData);
  //   this.mokujiDataList = tmpMokujiData;

  // }

  // async openPicker(numColumns = 1, numOptions = 5, columnOptions = this.defaultColumnOptions){
  //   const picker = await this.pickerController.create({
  //     columns: this.getColumns(numColumns, numOptions, columnOptions),
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel'
  //       },
  //       {
  //         text: 'Confirm',
  //         handler: (value) => {
  //           console.log(`Got Value ${value}`);
  //         }
  //       }
  //     ]
  //   });
  //   await picker.present();
  // }

  // getColumns(numColumns, numOptions, columnOptions) {
  //   let columns = [];
  //   for (let i = 0; i < numColumns; i++) {
  //     columns.push({
  //       name: `col-${i}`,
  //       options: this.getColumnOptions(i, numOptions, columnOptions)
  //     });
  //   }
  //   return columns;
  // }

  // getColumnOptions(columnIndex, numOptions, columnOptions) {
  //   let options = [];
  //   for (let i = 0; i < numOptions; i++) {
  //     options.push({
  //       text: columnOptions[columnIndex][i % numOptions],
  //       value: i
  //     })
  //   }
  //   return options;
  // }

  async presentToastWithOptions(
    toastMessage: string,
    seconds: number,
    position: string,
    color?: string
  ) {
    let durationNumber = 0;
    let clr = "primary";
    if (seconds != 0) {
      durationNumber = seconds;
    }
    if (color != null) {
      clr = color;
    }

    if (position == "bottom") {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: "✖️",
            role: "cancel",
          },
        ],
        duration: durationNumber,
        position: "bottom",
        color: clr,
        translucent: false,
      });
      toast.present();
    }
    if (position == "middle") {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: "✖️",
            role: "cancel",
          },
        ],
        duration: durationNumber,
        position: "middle",
        color: clr,
        translucent: false,
      });
      toast.present();
    }
    if (position == "top") {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: "✖️",
            role: "cancel",
          },
        ],
        duration: durationNumber,
        position: "top",
        color: clr,
        translucent: false,
      });
      toast.present();
    }
  }

  loadMokujiVirtualList(event) {
    setTimeout(() => {
      //console.log("before_"+JSON.stringify(this.mokujiVirtualList));
      let alreadyReadPosition: number = this.mokujiVirtualList.length;
      for (
        let i = alreadyReadPosition;
        i < alreadyReadPosition + this.windowSize;
        i++
      ) {
        //this.mokujiVirtualList.push("Item number "+(this.dataList.length+1));
        if (this.mokujiDataList[i] != null) {
          //配列末尾に到達していないときのみ、Virtual配列に追記
          this.mokujiVirtualList.push(this.mokujiDataList[i]);
        }
        //console.log("this.mokujiDataList[this.mokujiVirtualList.length+i]_"+this.mokujiDataList[this.mokujiVirtualList.length+i].mokujiCategory)
      }
      //console.log("after_"+JSON.stringify(this.mokujiVirtualList));
      //Hide Infinite List Loader on Complete
      event.target.complete();

      //Rerender Virtual Scroll List After Adding New Data
      this.virtualScroll.checkEnd();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      // if (this.mokujiVirtualList.length >= this.mokujiDataList.length) {
      //   //末尾になったらLoadをDisableしようとしたが、再検索のときにリセットできなかったのでコメントアウト
      //   event.target.disabled = true;
      // }
      //this.firestoreAccessor.sortKaijiData();//これゴミ行かも
    }, 500);
  }

  // loadKaijiVirtualData(event) {
  //   setTimeout(() => {
  //     let alreadyReadPosition: number = this.firestoreAccessor.kaijiVirtualData.length;
  //     for (
  //       let i = alreadyReadPosition;
  //       i < alreadyReadPosition + this.windowSize;
  //       i++
  //     ) {
  //       console.log("i_"+i);
  //       console.log("tokutenJirei[0]_"+this.firestoreAccessor.kaijiData[i].tokutenJirei[0]);
      
  //       if (this.firestoreAccessor.kaijiData[i] != null) {
  //         //配列末尾に到達していないときのみ、Virtual配列に追記
  //         this.firestoreAccessor.kaijiVirtualData.push(this.firestoreAccessor.kaijiData[i]);
  //       }
  //     }
      
  //     //Hide Infinite List Loader on Complete
  //     event.target.complete();

  //     //Rerender Virtual Scroll List After Adding New Data
  //     this.virtualScroll.checkEnd();

  //     // App logic to determine if all data is loaded
  //     // and disable the infinite scroll
  //     // if (this.mokujiVirtualList.length >= this.mokujiDataList.length) {
  //     //   //末尾になったらLoadをDisableしようとしたが、再検索のときにリセットできなかったのでコメントアウト
  //     //   event.target.disabled = true;
  //     // }
  //   }, 500);
  // }

  // async openMondaiPreview(cMData: Mondai, mokujiItem: MokujiData) {
  //   const modal = await this.modalController.create({
  //     component: MondaiPreviewPage,
  //     componentProps: {
  //       mondai: cMData,
  //       firestoreAccessor: this.firestoreAccessor,
  //       nendoName: this.nendoName,
  //     }
  //   });
  //   modal.onDidDismiss()
  //     .then((res) => {
  //       if (res.data != null) {
  //         //console.log(res.data['targeKamokuId'] + res.data['targetMondaiId']);
  //         this.closeMokujiAndReturnSelectedMondai(res.data['targeKamokuId'], res.data['targetMondaiId'], mokujiItem)
  //       }
  //     });
  //   await modal.present();
  // }

  showChart(ev: any) {
    //console.log('Segment changed', ev.detail.value);
    this.visibleChartName = ev.detail.value;
    this.chartSize = [
      this.platform.width() * 0.8,
      this.platform.height() * 0.6,
    ];
    if (ev.detail.value == "kaitou") {
      //this.firestoreAccessor.reloadAshiatoData();
      //console.log('dateData= '+JSON.stringify(this.firestoreAccessor.dateData));
    }
    if (ev.detail.value == "timer") {
      //this.firestoreAccessor.reloadAshiatoData();
      //console.log('rirekiDataSource= '+JSON.stringify(this.rirekiDataSource));
    }
  }

  // showChart(chartName:string){
  //   this.visibleChartName = chartName;
  //   this.chartSize = [this.platform.width() * 0.8,this.platform.height()*0.6]
  //   //this.platform.width() > 766

  //   //console.log('chartName= '+chartName);
  //   if (chartName == 'kaitou'){
  //     //this.firestoreAccessor.reloadAshiatoData();
  //     //console.log('dateData= '+JSON.stringify(this.firestoreAccessor.dateData));

  //   }
  //   if (chartName == 'timer'){
  //     //console.log('rirekiDataSource= '+JSON.stringify(this.rirekiDataSource));
  //   }
  // }

  ashiatoRangeChanged() {
    if (!this.ashiatoRangeVisible) {
      this.ashiatoRangeVisible = true;
    }
    if (
      this.firestoreAccessor.currentGuiStatus.dateRangeDual.lower ==
      this.firestoreAccessor.currentGuiStatus.dateRangeDual.upper
    ) {
      //Rangeの最小と最大が同じ値になるとエラーになるので補正する
      this.firestoreAccessor.currentGuiStatus.dateRangeDual.lower =
        this.firestoreAccessor.currentGuiStatus.dateRangeDual.upper - 3;
    }
    this.firestoreAccessor.reloadAshiatoData();

    // console.log('before_this.ashiato_yScaleMin= '+this.ashiato_yScaleMin)
    //this.ashiato_yScaleMin = this.firestoreAccessor.(this.firestoreAccessor.currentGuiStatus.ashiatoHyoujiKamoku,this.firestoreAccessor.currentGuiStatus.dateRange-1) - 1;
    //console.log('this.dateRangeDual= '+JSON.stringify(this.dateRangeDual))
  }

  ashiatoTargetKamokuChanged(mondaiSyuId: number) {
    this.firestoreAccessor.currentGuiStatus.ashiatoHyoujiKamoku = mondaiSyuId;
    //console.log('this.firestoreAccessor.currentGuiStatus.ashiatoHyoujiKamoku= '+this.firestoreAccessor.currentGuiStatus.ashiatoHyoujiKamoku);
    this.firestoreAccessor.reloadAshiatoData();

    //this.ashiato_yScaleMin = this.firestoreAccessor.(this.firestoreAccessor.currentGuiStatus.ashiatoHyoujiKamoku,this.firestoreAccessor.currentGuiStatus.dateRange);
  }

  slideNext() {
    this.slides.slideNext();
    this.slides.stopAutoplay();
  }
  slidePrev() {
    this.slides.slidePrev();
    this.slides.stopAutoplay();
  }
  async slideDidChange() {
    await this.slides.getActiveIndex().then((slideNum) => {
      this.slideNumber = slideNum;
      this.slides.length().then((len) => {
        //this.delay(3000).then(any => {
        this.slideLength = len;
        //});
        if (slideNum >= this.slideLength - 1) {
          this.slideEndBool = true;
        } else {
          this.slideEndBool = false;
        }
      });
    });
  }

  async getSlideLength() {
    await this.slides.length().then((len) => {
      //this.delay(3000).then(any => {
      this.slideLength = len;
      //});
    });
  }

  updateSlides() {
    this.delay(1000).then((any) => {
      this.slides.update();
    });
    this.slides.stopAutoplay();
  }

  // slideReachEnd(){
  //   //
  //   console.log('reach enddd');
  //   this.slideEndReached = true;
  // }

  acceptAndNext() {
    this.accept();
    this.slides.lockSwipes(false).then((data) => {
      this.slideNext();
    });
  }

  acceptAndDismiss() {
    this.accept();

    let res = {};
    res["mode"] = this.modalMode;

    this.modalController.dismiss(res);
  }

  closeWelcomeModal() {
    this.firestoreAccessor.currentGuiStatus.welcomeModalClosed = true;

    this.firestoreAccessor.writeOperationLog(
      this.firestoreAccessor.currentGuiStatus.currentTab,
      this.firestoreAccessor.currentGuiStatus.currentMondai[
        this.firestoreAccessor.currentGuiStatus.currentTab
      ].mondaiId,
      "closeWelcomeModal()",
      true
    );

    let res = {};
    res["mode"] = this.modalMode;

    this.modalController.dismiss(res);
  }

  accept() {
    //利用規約にacceptしたらフラグ立ててDB保存しウィンドウ閉じる
    this.firestoreAccessor.currentGuiStatus.privacy_policy_accepted = true;
    this.firestoreAccessor.backUpObjectToDB(
      "currentGuiStatus",
      this.firestoreAccessor.currentGuiStatus
    );
  }

  selectedFirstKamoku() {
    this.kamokuSelected = true;
  }

  changeRiyoukiyakuAndPolicyVisible() {
    //
    this.policyAndRiyoukiyakuVisible = !this.policyAndRiyoukiyakuVisible;
  }
  // changePolicyVisible(){
  //   //
  //   this.policyVisible = !this.policyVisible;
  // }

  // changeRiyoukiyakuVisible(){
  //   //
  //   this.riyoukiyakuVisible = !this.riyoukiyakuVisible;
  // }

  // reloadMokuji() {

  //   //GUIのSELECTから取得したCategorymodeが文字列になってしまうので、BOOLに変換する仮の変数を使う
  //   let tmpCategoryModeMokuji:boolean = false;
  //   if (this.currentMokujiStatus.categoryModeMokuji == 'true'){
  //     tmpCategoryModeMokuji = true;
  //   }
  //   let mondaiSyuIdMokuji_number: number = Number(this.currentMokujiStatus.mondaiSyuIdMokuji);
  //   let levelMokuji_number: number = Number(this.currentMokujiStatus.levelMokuji);

  //   this.displaySpinner(``, 1000);
  //   this.delay(500).then(any => {
  //     this.mokujiVirtualList = [];

  //     //もくじの再読み込み
  //     this.mokujiDataList = this.firestoreAccessor.getMokujiDataList(mondaiSyuIdMokuji_number, tmpCategoryModeMokuji, levelMokuji_number);
  //     for (let i = 0; i < this.windowSize; i++) { //Virtualの方のもくじのリストも再読み込み
  //       if (this.mokujiDataList[i] != null) {
  //         //配列末尾に到達していないときのみ、Virtual配列に追記
  //         this.mokujiVirtualList.push(this.mokujiDataList[i]);
  //       }
  //     }

  //     //this.mokujiDataList = this.firestoreAccessor.getMokujiDataList(this.firestoreAccessor.currentGuiStatus.currentTab,this.currentMokujiStatus.categoryModeMokuji,this.currentMokujiStatus.levelMokuji );
  //   });
  // }

  // welcomeKamokuSelected(kamokuId:number){
  //   //ようこそ画面で科目選択時のメソッド
  //   //currentTab設定
  //   this.firestoreAccessor.currentGuiStatus.currentTab = kamokuId;
  //   //mondaiList再読み込み
  //   this.firestoreAccessor.tutorialMondaiListReload();
  //   //MODAL閉じる
  //   this.modalController.dismiss();
  // }

  sentakuConfirmed() {
    //選択肢のMODALで選択肢を選んだ場合、選んだ選択肢と、excludeかsentakuかを返して閉じる

    let res = {};
    res["mode"] = this.modalMode;
    res["sentakushi_to_select"] = this.modalModeOption;

    this.modalController.dismiss(res);
  }

  kaitouZumiMondaiKaitouReloadConfirmed() {
    //解答済みの問題のカレントの解答をリロードする
    let res = {};
    res["mode"] = this.modalMode;
    res["reloadKaitou"] = true;

    //this.modalController.dismiss(res);
    this.dismissModal(res);
  }

  sendKansou() {
    //解答済みの問題のカレントの解答をリロードする
    let res = {};
    res["mode"] = this.modalMode;
    res["sendKansou"] = true;

    //this.modalController.dismiss(res);
    this.dismissModal(res);
  }

  showAcceptButton() {
    //Welcomeスライドの末尾になったら、同意ボタンのフッタを表示する
    this.acceptButtonVisible = true;
    this.slides.stopAutoplay();
  }

  gouhikaitou(gouhiKaitouMode: string, targetMondai: Mondai) {
    // let tokutenDic = {
    //   201: 0,
    //   202: 0,
    //   203: 0,
    //   204: 0,
    // }
    let kekkaStrArr: string[] = [];

    // let tokutenTable = {
    //   "合格":63.5,
    //   "不合格":56.5,
    //   "A":65.1,
    //   "B":55.1,
    //   "C":45.1,
    //   "D":35.1,
    // };

    let visibleTable = {
      開示済み: "gouhiKaitouZumi",
      ABCD: "gouhiKaitouZumi",
      合格: "gouhiKaitouZumi",
      不合格: "gouhiKaitouZumi",
      受験していない: "student",
      スキップ: "student",
    };

    //全事例共通のパターンの回答の場合、共通の値で埋める
    if (gouhiKaitouMode == "不合格") {
      for (let i_gar in this.gouhiStrArr) {
        this.gouhiStrArr[i_gar] = "不合格";
      }
    } else if (gouhiKaitouMode == "合格") {
      for (let i_gar in this.gouhiStrArr) {
        this.gouhiStrArr[i_gar] = "合格";
      }
    }

    if (gouhiKaitouMode == "開示済み") {
      //得点からABCDを逆算させる
      for (let i_tar in this.tokutenArr) {
        //
        if (this.tokutenArr[i_tar] < 40) {
          this.gouhiStrArr[i_tar] = "D";
        } else if (
          40 <= this.tokutenArr[i_tar] &&
          this.tokutenArr[i_tar] < 50
        ) {
          this.gouhiStrArr[i_tar] = "C";
        } else if (
          50 <= this.tokutenArr[i_tar] &&
          this.tokutenArr[i_tar] < 60
        ) {
          this.gouhiStrArr[i_tar] = "B";
        } else if (60 <= this.tokutenArr[i_tar]) {
          this.gouhiStrArr[i_tar] = "A";
        }
      }
    } else if (
      gouhiKaitouMode == "ABCD" ||
      gouhiKaitouMode == "合格" ||
      gouhiKaitouMode == "不合格"
    ) {
      //テーブルから仮の得点を入れる
      for (let i_gar in this.gouhiStrArr) {
        this.tokutenArr[i_gar] =
          this.firestoreAccessor.tokutenTable[this.gouhiStrArr[i_gar]];
      }
    }

    // if (this.gouhiStrArr.length == 1){
    //   //ABCDや得点開示でない場合は、事例２，３，４に同じ値を入れる
    //   this.gouhiStrArr[1] = kekkaStrArr[0];
    //   this.gouhiStrArr[2] = kekkaStrArr[0];
    //   this.gouhiStrArr[3] = kekkaStrArr[0];
    // } else if (
    //   this.gouhiStrArr[0] != "A" &&
    //   this.gouhiStrArr[0] != "B" &&
    //   this.gouhiStrArr[0] != "C" &&
    //   this.gouhiStrArr[0] != "D"){
    //   //得点開示の場合
    //   //GUIの解答を数値に変換する
    //   this.tokutenArr[0] = Number(this.gouhiStrArr[0]);
    //   //GUIの解答から、ABCDを導出する
    //   this.tokutenArr[0] = Number(this.gouhiStrArr[0]);
    // }

    this.firestoreAccessor.currentGuiStatus.gouhi_jouhou = [
      {
        mondaiSyuId: 201,
        nendo: targetMondai.nendo,
        gouhi: this.gouhiStrArr[0],
        myName: this.firestoreAccessor.currentGuiStatus.myName,
        tokuten: this.tokutenArr[0],
        //"tokuten": tokutenTable[this.gouhiStrArr[0]],
        visible: visibleTable[gouhiKaitouMode],
      },
      {
        mondaiSyuId: 202,
        nendo: targetMondai.nendo,
        gouhi: this.gouhiStrArr[1],
        myName: this.firestoreAccessor.currentGuiStatus.myName,
        tokuten: this.tokutenArr[1],
        //"tokuten": tokutenTable[this.gouhiStrArr[1]],
        visible: visibleTable[gouhiKaitouMode],
      },
      {
        mondaiSyuId: 203,
        nendo: targetMondai.nendo,
        gouhi: this.gouhiStrArr[2],
        myName: this.firestoreAccessor.currentGuiStatus.myName,
        tokuten: this.tokutenArr[2],
        //"tokuten": tokutenTable[this.gouhiStrArr[2]],
        visible: visibleTable[gouhiKaitouMode],
      },
      {
        mondaiSyuId: 204,
        nendo: targetMondai.nendo,
        gouhi: this.gouhiStrArr[3],
        myName: this.firestoreAccessor.currentGuiStatus.myName,
        tokuten: this.tokutenArr[3],
        //"tokuten": tokutenTable[this.gouhiStrArr[3]],
        visible: visibleTable[gouhiKaitouMode],
      },
    ];
    let res = {};
    res["mode"] = this.modalMode;

    this.modalController.dismiss(res);
  }

  setCurrentMondai(mondaiSyuId: number, mondaiId: number) {
    let cMData: Mondai = this.firestoreAccessor.getOneMondaiFromFile(mondaiId);
    this.firestoreAccessor.currentGuiStatus.currentMondai[mondaiSyuId] = cMData;
    this.firestoreAccessor.currentGuiStatus.currentTab = cMData.mondaiSyuId;
  }

  closeGouhiJouhou() {
    this.setCurrentMondai(201, 201020111); //最初の問題に戻しておく。事例４未実装なので
    this.modalController.dismiss();
  }

  
  async share() {
    const urlString = this.firestoreAccessor.getSharingUrlYougo(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId, this.currentYougoData.name)
    await Share.share({
      title: this.currentYougoData.name,
      text:  this.currentYougoData.kaisetu +`
      
      関連サイト：`+this.currentYougoData.urlKiji+`
      
      関連：動画`+this.currentYougoData.urlYoutube,
      url: urlString//'http://ionicacademy.com/'
    });
  }

  async takePicture() {

    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      // width: 720,
      // height: 1280,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      promptLabelHeader: "過去問を撮影してください",
    });
    this.myImage = image.webPath;

    //png画像をBase64文字列に変換する。
    this.myImageBase64Data = await this.base64FromPath(image.webPath);
    //await this.searchObject();

    // 変換後のBase64文字列に含まれる画像として不要な文字列を除去する。
    this.myImageBase64Data = this.myImageBase64Data.replace(
      "data:image/png;base64,",
      ""
    );
    this.myImageBase64Data = this.myImageBase64Data.replace(
      "data:image/jpeg;base64,",
      ""
    );
  }

  async base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setTimeout(() => {
            //console.log('hello')
            setTimeout(() => {
              //console.log('callback')
              this.doOCR();
            }, 0);
          }, 1000);
          
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  async doOCR() {
    const imageData = this.myImageBase64Data;
    this.ocrResultString = "処理中";
    this.vision.getLabels(imageData, "DOCUMENT_TEXT_DETECTION").subscribe(
      async (result) => {
        //this.presentToastWithOptions("📖　重要語を検索中です",3000,"top");
        //console.log("帰ってきた値＝" + JSON.stringify(result.json()));
        //this.result = JSON.parse(JSON.stringify(result.json()));
        this.result = JSON.parse(JSON.stringify(result));//capacitor3対応でエラーになるので、.json()を削除うまく動くかは未確認なのでOCR流してみないとわからない
        
        if (this.result.responses[0]){
          if ( this.result.responses[0].fullTextAnnotation?.text){
            //認識文字がある場合は resultに文字列入れる。ない場合は、空文字を入れる
            this.ocrFullString = this.result.responses[0].fullTextAnnotation.text;
            this.ocrFullString = this.ocrFullString.replace("\\n", "");
            this.ocrFullString = this.ocrFullString.replace(" ", "");
            this.searchedYougoDataArray = this.firestoreAccessor.getYougoDataArray(this.ocrFullString);
            console.log("帰ってきた値＝" + this.ocrFullString);
            if (this.searchedYougoDataArray[0]){
              //用語がヒットしていたら
              //カレントのYOUGOを先頭のものにする
              this.currentYougoData = this.searchedYougoDataArray[0];

              //ヒットしていた用語の矩形データを取得する
              this.hitYougoZahyouData = [];
              for (let i_sya in this.searchedYougoDataArray) {
                let okWordArr =
                  this.searchedYougoDataArray[i_sya].targetOkWords;
                for (let i_owa in okWordArr) {
                  for (let i_ta in this.result.responses[0].textAnnotations) {
                    if (
                      this.result.responses[0].textAnnotations[i_ta].description == okWordArr[i_owa]
                    ) {
                      //用語のOKWORDと、OCRのアノテ文字列が一致したら、矩形座標を取得する
                      this.hitYougoZahyouData.push(
                        this.result.responses[0].textAnnotations[i_ta]
                      );
                    }
                  }
                }
              }
              //描画する
              var canvas = document.getElementById('mycanvas55');
              //this.context55 = this.mycanvas55.nativeElement.getContext("2d");
              if (this.mycanvas55.nativeElement.getContext("2d")) {
                //this.mycanvas55.setAttribute('width',String(this.context55.canvas.clientWidth));
                //ヒットした文字の回数だけ矩形を描画
                  this.context55 = this.mycanvas55.nativeElement.getContext("2d");
                  //this.contextMyPhoto = this.myPhotoArea.nativeElement.getContext("2d");
                  this.imgWidth = this.platform.width()*0.93 ;
                  //Number(document.getElementById("myPhotoArea").style.width);
                  this.context55.canvas.width = 1200//this.context55.canvas.clientWidth
                  this.context55.canvas.height = 1200;//this.context55.canvas.clientWidth
                  // this.imgWidth = this.mycanvas55.nativeElement.offsetWidth;
                  // this.imgHeight = this.mycanvas55.nativeElement.offsetHeight;

                  //元画像とのサイズ縮小比率を取得
                  let sizeHiritu = this.imgWidth / this.result.responses[0]?.fullTextAnnotation.pages[0]?.width  ;
                  this.context55.fillStyle = 'rgba(200,10,50,0.3)';
                  // console.log("this.context55.canvas.clientWidth＝" + this.context55.canvas.clientWidth);
                  // console.log("this.context55.canvas.clientWidth＝" + this.context55.canvas.clientHeight);
                  // console.log("window.document.documentElement.clientWidth--" + window.document.documentElement.clientWidth);
                  // console.log("this.context55.canvas.clientWidth--" + this.context55.canvas.clientWidth);
                  // console.log("this.result.responses[0].fullTextAnnotation.pages[0].width--" + this.result.responses[0].fullTextAnnotation.pages[0].width);
                  
                  for (let i_hyz in this.hitYougoZahyouData) {
                    // console.log("hitYougoZahyouData[i_hyz].description＝" + JSON.stringify(this.hitYougoZahyouData[i_hyz].description));
                    // console.log("hitYougoZahyouData[i_hyz].boundingPoly.vertices[0]＝" + JSON.stringify(this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[0]));
                    // console.log("hitYougoZahyouData[i_hyz].boundingPoly.vertices[2]＝" + JSON.stringify(this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[2]));
                    this.context55.fillRect(
                      //172,152,200,90
                      //10,50,200,90
                      this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[0].x * sizeHiritu,
                      this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[0].y *sizeHiritu ,
                      //162,33,250,150
                      (this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[2].x - this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[0].x) * sizeHiritu,
                      (this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[2].y - this.hitYougoZahyouData[i_hyz].boundingPoly.vertices[0].y) * sizeHiritu,
                      // hitYougoZahyouData['boundingPoly']['vertices'][0].y,
                      // hitYougoZahyouData['boundingPoly']['vertices'][3].x,
                      // hitYougoZahyouData['boundingPoly']['vertices'][3].y,
                    );
                  }
                  
                  // this.context55.fillRect(
                  //   184,154,40,40
                  //   //10,50,200,90
                  // );
                  // ctx.clearRect(45, 45, 60, 60);
                  // ctx.strokeRect(50, 50, 50, 50);  
                }
            }

            //this.keyWord = this.result;
            //this.keywordChanged();
            if (this.searchedYougoDataArray.length > 0){
              this.ocrResultString = "OCR処理正常完了";
            } else {
              this.ocrResultString = "画像から診断士の用語を認識できませんでした。再撮影またはキーワード入力で検索してください";
            }
          } else {
            this.ocrResultString = "画像から診断士の用語を認識できませんでした。再撮影またはキーワード入力で検索してください";
          }
        } else {
          this.keyWord = "";
          this.ocrResultString = "画像から診断士の用語を認識できませんでした。再撮影またはキーワード入力で検索してください";
        }
      },
      (err) => {
        this.ocrResultString = "文字認識でエラーが発生しました。再撮影またはキーワード入力で検索してください";
        console.log("getLabelsで異常発生");
        console.log(err);
      }
    );
    // return new Promise((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.onerror = reject;
    //   reader.onload = () => {
    //     if (typeof reader.result === "string") {
    //       resolve(reader.result);
    //     } else {
    //       reject("method did not return a string");
    //     }
    //   };
    //   reader.readAsDataURL(blob);
    // });
    // const imageData = this.myImageBase64Data;
    // ocrResultString:string = "":
    // this.vision.getLabels(imageData, "TEXT_DETECTION").subscribe(
    //   async (result) => {
    //     // JavaScriptではオブジェクトのコピーをした時に参照をシャローコピーするため、
    //     // コピーに対して変更を加えた場合、元のAPIの結果に対しても意図しない変更を加えてしまう可能性がある。
    //     // それを避けるために、一旦文字列に変換してから再度JSONに変換することでディープコピーする。
    //     this.result = JSON.parse(JSON.stringify(result.json()));
    //     console.log("帰ってきた値＝" + JSON.stringify(result.json()));

    //     this.result = this.result.responses[0].fullTextAnnotation.text;
    //     this.result = this.result.replace("\\n", "");
    //     return 
    //   },
    //   (err) => {
    //     console.log("getLabelsで異常発生");
    //     console.log(err);
    //   }
    // );
  }

  // async searchObject() {
  //   const imageData = this.myImageBase64Data;
  //   this.vision.getLabels(imageData, "TEXT_DETECTION").subscribe(
  //     async (result) => {
  //       // JavaScriptではオブジェクトのコピーをした時に参照をシャローコピーするため、
  //       // コピーに対して変更を加えた場合、元のAPIの結果に対しても意図しない変更を加えてしまう可能性がある。
  //       // それを避けるために、一旦文字列に変換してから再度JSONに変換することでディープコピーする。
  //       this.result = JSON.parse(JSON.stringify(result.json()));
  //       console.log("帰ってきた値＝" + JSON.stringify(result.json()));

  //       this.result = this.result.responses[0].fullTextAnnotation.text;
  //       this.result = this.result.replace("\\n", "");
  //     },
  //     (err) => {
  //       console.log("getLabelsで異常発生");
  //       console.log(err);
  //     }
  //   );
  // }

  keywordChanged() {
    //検索キーワードの文字が変わったら、キーワードマスタを絞り込む
    let tmpKeyWord: string = this.keyWord;//ポインタが切れるように、別変数に移す
    //console.log("tmpKeyWord-",tmpKeyWord)
    //this.currentYougoData = this.firestoreAccessor.getYougoDataArray(tmpKeyWord, true)[0];
    this.searchedYougoDataArray = this.firestoreAccessor.getYougoDataArray(tmpKeyWord, true);
    if (this.searchedYougoDataArray[0]){//用語がヒットしていたら、カレントのYOUGOを先頭のものにする
      this.currentYougoData = this.searchedYougoDataArray[0];
    }
  }


  keywordSegmentChanged(targetYougoData: YougoData) {
    //メインのYougo（QuickViewでないほう）」が変わったときの関数
    //console.log('keywordName.name--', targetYougoData.name);
    this.currentYougoData = targetYougoData;
    //this.keyWord = targetYougoData.name;
    // //keywordのSegmentをタップで変更したら実行される
    // console.log('JSON--', JSON.stringify(ev),ev.detail.value);
  }

  
  kaijiSearchJoukenChanged() {
    //得点開示のウィンドウで、検索条件選んだら、メインコンポーネントに、Modal開き直すように支持する。Twitterのタグ表示のために開き直しが必要なため

    this.firestoreAccessor.getKaijiData();//検索条件と検索結果を更新
    let res = {};
    res["mode"] = this.modalMode;

    this.modalController.dismiss(res);
  }

  // showQyuckYougoView(targetYougoData:YougoData){
  //   this.firestoreAccessor.presentPopover(targetYougoData);
  //   //スクロール有効化
  //   //halfModal起動
    
  //   // let scroll = this.mainContent.getScrollElement();
  //   // scroll.style.overflowY = "disable" ? 'hidden' : 'scroll';
 
  //   // const elm = document.querySelector<HTMLElement>('scrollArea')!;
  //   // elm.style.overflowY = "disable" ? 'hidden' : 'scroll';
  //   //document.getElementById("scrollArea").style.overflowY = "scroll";
    
  //   //this.quickYougoView = targetYougoData;
  // }

  // selectSentakushiFromYougo(targeMondai:Mondai,selected: string){
  //   //YougoのModalの関連問題からでも、解答だけできるようにする（消去法とかはできない）
  //   //カレントの問題をセットする ＃setMondaiListの抜粋
  //   this.firestoreAccessor.currentGuiStatus.currentMondai[targeMondai.mondaiSyuId] = targeMondai;
  //   this.firestoreAccessor.loadLastKaitouHistory(targeMondai.mondaiSyuId, targeMondai.mondaiId,true);//解答の枠を作る

  //   //解答する。selectSentakushi→updateKaitouの抜粋
  //   //メモリ上のKaitouの、現在日付を更新
  //   let todayDate = new Date();
  //   todayDate.setTime(todayDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
  //   this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId].dateTime = todayDate;

  //   this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId].sentaku = selected;
    
  //   //fixSaitenKekkaの抜粋
  //   if (selected == targeMondai.seikai) {
  //     this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId].saitenKekka = true;
  //   } else {
  //     this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId].saitenKekka = false;
  //   }
  //   this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId].hint_visible = true;
  //   this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId].dateTime_sentakuFixedAt = this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId].dateTime;//採点結果確定時点の日時を保存
  //   setTimeout(() => {
  //     //console.log('hello')
  //     this.firestoreAccessor.reloadAshiatoData();
  //     setTimeout(() => {
  //       //console.log('callback')
  //       this.firestoreAccessor.updateCount_kaitou_and_timer();
  //     }, 1000)
  //   }, 1000)
    
  //   //正誤に応じた応援メッセージをTOAST表示。displaySaitenKekkaToast(targeMondai, selected, operationName)の抜粋
  //   let toastString = "不正解です"
  //   if (targeMondai.seikai == selected){
  //     toastString = "正解です";
  //   }
  //   this.presentToastWithOptions(toastString, 4000, 'middle', "medium");

  //   //persist_updated_kaitouの抜粋。採点結果や解答をDB保存するなど後処理
  //   this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus, this.firestoreAccessor.currentGuiStatus.privacy_policy_accepted);

  //   //Kaitouの末尾をメモリ上のKaitouで上書きして、KaitouをDBに保存する
  //   this.firestoreAccessor.kaitouHistory[targeMondai.mondaiId][this.firestoreAccessor.kaitouHistory[targeMondai.mondaiId].length - 1] = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentKaitou[targeMondai.mondaiId]));
  //   this.firestoreAccessor.backUpObjectToDB('kaitouHistory', this.firestoreAccessor.kaitouHistory, this.firestoreAccessor.currentGuiStatus.privacy_policy_accepted);

  //   //操作ログの書き込み（処理後）
  //   this.firestoreAccessor.writeOperationLog(targeMondai.mondaiSyuId, targeMondai.mondaiId, "selectSentakushiFromYougo" + 'updateKaitou_after', this.firestoreAccessor.currentGuiStatus.privacy_policy_accepted);
  // }


  // gouhiSelected(mondaiId :number){
  //   let targetGouhiJouhou = this.firestoreAccessor.currentGuiStatus.gouhi_jouhou[this.firestoreAccessor.getMondaiIdSentou5(mondaiId)];
  //   let targetGouhiView = targetGouhiJouhou.gouhiView;
  //   if ( targetGouhiView == ""){
  //     //合否の解答を入力してなければ、何もしない
  //   } else if (targetGouhiView == "合格" || targetGouhiView == "A"|| targetGouhiView == "B"|| targetGouhiView == "C"|| targetGouhiView == "D"){
  //     //合格ABCDなら、gouhiをそのまま格納し、tokutenは引いた値を入れる
  //     targetGouhiJouhou.gouhi = targetGouhiView;
  //     targetGouhiJouhou.tokuten = this.firestoreAccessor.tokutenTable[targetGouhiView];

  //     targetGouhiJouhou.visible = "gouhiKaitouZumi";
  //     //RESTに値をPOSTする
  //     this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
  //     //開示方法のモーダル表示する
  //   } else if (targetGouhiView == "受験していない"){
  //     //受験していないなら、gouhiViewそのまま格納し、値をデフォルト値に戻す
  //     targetGouhiJouhou.gouhi = targetGouhiView;
  //     targetGouhiJouhou.visible = "student";
  //     targetGouhiJouhou.tokuten = null;
  //     //RESTに値をPOSTする
  //     this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
  //   } else if ( -1 < targetGouhiView && targetGouhiView < 101){
  //     //数字なら、tokutenをそのまま格納し、gouhiに引いた文字列を入れる
  //     targetGouhiJouhou.tokuten = targetGouhiView
  //     if ( targetGouhiView < 40){
  //       targetGouhiJouhou.gouhi = "D";
  //     } else if (
  //       40 <= targetGouhiView && targetGouhiView < 50
  //       ){
  //         targetGouhiJouhou.gouhi = "C";
  //     } else if (
  //       50 <= targetGouhiView && targetGouhiView < 60
  //       ){
  //         targetGouhiJouhou.gouhi = "B";
  //     } else if (
  //       60 <= targetGouhiView
  //       ){
  //         targetGouhiJouhou.gouhi = "A";
  //     }

  //     targetGouhiJouhou.visible = "gouhiKaitouZumi";
  //     //RESTに値をPOSTする
  //     this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
  //   }

  //   this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);

  //   //console.log("this.firestoreAccessor.currentGuiStatus"+JSON.stringify(this.firestoreAccessor.currentGuiStatus));//("hi-------------")//
  // }
}