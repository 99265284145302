// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
    apiKey: "AIzaSyAAhawjxuKgn6cgiV6_qvLbdflQeOYegcg",
    authDomain: "kmzukai.firebaseapp.com",
    databaseURL: "https://kmzukai.firebaseio.com",
    projectId: "kmzukai",
    storageBucket: "kmzukai.appspot.com",
    messagingSenderId: "974116765741",
    cacheSizeBytes: -1//キャッシュを消さない設定（デフォルトだと40MB）
  },
  googleCloudVisionAPIKey: 'AIzaSyAgMlQ6cWxKm9oRc4yd0fPdr7XFrldJuTU',
  googleSpeechToTextAPIKey: 'AIzaSyAgMlQ6cWxKm9oRc4yd0fPdr7XFrldJuTU',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
