import { Component, OnInit } from '@angular/core';

import { NavParams, } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
//import { FirestoreAccessorService } from '../firestore-accessor.service';
import { YougoData } from '../models/YougoData';
import { Share } from '@capacitor/share';

@Component({
  selector: 'app-half-modal',
  templateUrl: './half-modal.page.html',
  styleUrls: ['./half-modal.page.scss'],
})
export class HalfModalPage implements OnInit {
  yougoHalfModalHonbunString:string = "";
  currentYougoData: YougoData;
  kamokuName;
  nendoName;

  constructor(
    private navParams: NavParams,
    public domSanitizer: DomSanitizer,
    //public firestoreAccessor: FirestoreAccessorService,
  ) {
    this.yougoHalfModalHonbunString = this.navParams.get("yougoHalfModalHonbunString");
    //this.firestoreAccessor = this.navParams.get("firestoreAccessor");
    this.currentYougoData = this.navParams.get("currentYougoData");
    this.kamokuName = this.navParams.get("kamokuName");
    this.nendoName = this.navParams.get("nendoName");
  }

  ngOnInit() {
  }
  
  async share(pageClass: any) {
    const urlString = pageClass.getSharingUrlYougo(pageClass.currentGuiStatus.currentMondai[pageClass.currentGuiStatus.currentTab].mondaiId, this.currentYougoData.name)
    await Share.share({
      title: this.currentYougoData.name,
      text:  this.currentYougoData.kaisetu +`
      
      関連サイト：`+this.currentYougoData.urlKiji+`
      
      関連：動画`+this.currentYougoData.urlYoutube,
      url: urlString//'http://ionicacademy.com/'
    });
    // const urlString = this.firestoreAccessor.getSharingUrlYougo(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId, this.currentYougoData.name)
    // await Share.share({
    //   title: this.currentYougoData.name,
    //   text:  this.currentYougoData.kaisetu +`
      
    //   関連サイト：`+this.currentYougoData.urlKiji+`
      
    //   関連：動画`+this.currentYougoData.urlYoutube,
    //   url: urlString//'http://ionicacademy.com/'
    // });
  }

  // getSharingUrlYougo(mondaiId:number,youdoName:string){
  //   let tmp_hint_visible:string = "false";//解説表示したことがない場合、項目自体がないのでデフォルト値を入れる
  //   if (this.currentGuiStatus.currentKaitou[mondaiId].hint_visible){
  //     tmp_hint_visible = 'true';
  //   }
  //   let urlStr:string = '';
  //   urlStr = "https://app.zu-de.com/m/" + mondaiId + "/" + tmp_hint_visible + "/" +this.currentGuiStatus.categoryMode[this.currentGuiStatus.currentTab] + "/yougo"+youdoName
  //   return urlStr;
  // }

}
