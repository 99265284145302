/* eslint-disable @typescript-eslint/type-annotation-spacing */
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { commonCfsClass } from './models/commonCfsClass';
//import { mondaiPngJson } from './models/mondaiPngJson';

import { Mondai } from './mondai/mondai';
import { CFSDataContainer } from './models/CFSDataContainer';
//import { MONDAIDATAAddedCategory } from './mondai/mondaidataAddedCategory';
import { MokujiData } from './models/MokujiData';
import { MOKUJIDATAMASTER } from './mondai/MOKUJIDATAMASTER';
import { ICHIRANDATAMASTER } from './mondai/ICHIRANDATAMASTER';
import { YOUGODATAFILLEDMONDAI } from './mondai/YOUGODATAFILLEDMONDAI';
import { Kaitou } from './models/Kaitou';
import { Kaiji } from './models/Kaiji';
import { Kaitou2ji } from './models/Kaitou2ji';
//import { Timer } from './models/Timer';
import * as marked from 'marked'; // markedをインポート
import { GakusyuRireki } from './models/GakusyuRireki';
import { Kansou } from './models/Kansou';

//import * as firebase from 'firebase/app'; 
import firebase from 'firebase/app';
//import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

//import { firebase, auth, firestore, storage } from './firebase.ts';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
// import 'firebase/storage';

import { GouhiJouhou } from './models/gouhiJouhou.model';
import { TodoService } from './services/todo.service';
import { KaitouBun } from './models/kaitoubun.model';
import { YougoData } from './models/YougoData';
import { ModalController , ToastController} from '@ionic/angular';
import { HalfModalPage } from './half-modal/half-modal.page';
import { PopoverController } from '@ionic/angular';

import { Like } from './models/Like';
import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class FirestoreAccessorService {

  commonCfsClass: Observable<commonCfsClass | null>;
  commonCfsClassDoc: AngularFirestoreDocument<commonCfsClass>;
  // mondaiPngJson: Observable<mondaiPngJson | null>;
  // mondaiPngJsonDoc: AngularFirestoreDocument<mondaiPngJson>;
  Mondai: Observable<Mondai | null>;
  mondaiJsonDoc: AngularFirestoreDocument<Mondai>;
  kansouDoc: AngularFirestoreDocument<Kansou>;
  item: Observable<Kansou>;

  mondaidataOnCfs = {};
  //mondaiPngOnCfs = {};
  // mondaiCacheDoc = {
  //   101:{},
  //   102:{},
  //   103:{},
  //   104:{},
  //   105:{},
  //   106:{},
  //   107:{}
  // };
  // mokujiCacheDoc = {
  //   101:{},
  //   102:{},
  //   103:{},
  //   104:{},
  //   105:{},
  //   106:{},
  //   107:{}
  // };
  mondaidataOnFile: Mondai[];
  mokujidataOnFile: MokujiData[] = MOKUJIDATAMASTER;
  ichiranDataOnFile = ICHIRANDATAMASTER;
  YougoDataFilledMondai = YOUGODATAFILLEDMONDAI;

  currentGuiStatus_defaultValue = {
    categoryMode: {
      101: false,
      102: false,
      103: false,
      104: false,
      105: false,
      106: false,
      107: false,
      201: false,
      202: false,
      203: false,
      204: false
    },
    mokujiSearchKeyword: {
      101: '',
      102: '',
      103: '',
      104: '',
      105: '',
      106: '',
      107: '',
      201: '',
      202: '',
      203: '',
      204: ''
    },
    mondaiFontSize: 100,
    myName: "",
    currentTab: 101,
    currentMondai: {
      101: null,
      102: null,
      103: null,
      104: null,
      105: null,
      106: null,
      107: null,
      201: null,
      202: null,
      203: null,
      204: null,
    },
    currentMondaiList: {
      101: [],
      102: [],
      103: [],
      104: [],
      105: [],
      106: [],
      107: [],
      201: [],
      202: [],
      203: [],
      204: []
    },
    currentMokuji: {
      101: null,
      102: null,
      103: null,
      104: null,
      105: null,
      106: null,
      107: null,
      201: null,
      202: null,
      203: null,
      204: null,
    },
    currentKaitou: {},
    level: {
      101: 2,
      102: 2,
      103: 2,
      104: 2,
      105: 2,
      106: 2,
      107: 2,
      201: 2,
      202: 2,
      203: 2,
      204: 2
    },
    privacy_policy_accepted: false,
    ashiatoHyoujiKamoku: 0,
    ashiatoHyoujiNendo: 0,
    ashiatoHyoujiKeyword: "すべて表示",
    //dateRange: 9,
    dateRangeDual:{"lower":-9,"upper":0},
    kaitouCountToday: {
      0: 0,
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0,
    },
    kaitouCountAll: {
      0: 0,
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0,
    },
    // timer_remain: {
    //   101: 0,
    //   102: 0,
    //   103: 0,
    //   104: 0,
    //   105: 0,
    //   106: 0,
    //   107: 0
    // },
    timerCountAll: {
      0: 0,
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0
    },
    timerCountToday: {
    },
    //agree: false,
    //currentMainTab: 'mokuji'
    currentMainSlidePosition: {
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0
    },
    hintFooterVisible: false,
    noKaisetuVisible:false,
    kaitouCountDate: new Date(),
    platformName: [""],
    currentKansouId: '',
    welcomeModalClosed: false,
    kaihouArraySelected: "",
    mondaiListKeyword: {
      101: "2021",
      102: "2021",
      103: "2021",
      104: "2021",
      105: "2021",
      106: "2021",
      107: "2021",
      201: "2021",
      202: "2021",
      203: "2021",
      204: "2021",
    },
    mondaiListKeywordDisplayStr: {
      101: "令和３年",
      102: "令和３年",
      103: "令和３年",
      104: "令和３年",
      105: "令和３年",
      106: "令和３年",
      107: "令和３年",
      201: "令和３年",
      202: "令和３年",
      203: "令和３年",
      204: "令和３年",
    },
    currentNendoRange: {
      101: 2021,
      102: 2021,
      103: 2021,
      104: 2021,
      105: 2021,
      106: 2021,
      107: 2021,
      201: 2021,
      202: 2021,
      203: 2021,
      204: 2021,
    },
    saitenKekka2ji_model: {},
    saitenKekka2ji_view: {},
    saitenKekka2jiChangedFlag: {},
    kaitoubun2jiChangedFlag: {},
    scatterValue: {},
    //gouhiJouhou: [],
    gouhi_jouhou: {},
    scatterTangoRanking:{},
    gaitouTouanSuuData:{},
    rontenSorted_goukei:[],
    rontenSorted_wariai:[],
    rankingData:{},
    junniRaderChartData:{},
    gouhiJouhouTableData:{},
    thanksListArray:[],
    handleName:"匿名",
    currentTokuten: {
      101: {},
      102: {},
      103: {},
      104: {},
      105: {},
      106: {},
      107: {},
    },
    highLightedSearchSaigenTouanKeyWord: {},
  };

  currentGuiStatus = {
    categoryMode: {
      101: false,
      102: false,
      103: false,
      104: false,
      105: false,
      106: false,
      107: false,
      201: false,
      202: false,
      203: false,
      204: false
    },
    mokujiSearchKeyword: {
      101: '',
      102: '',
      103: '',
      104: '',
      105: '',
      106: '',
      107: '',
      201: '',
      202: '',
      203: '',
      204: ''
    },
    mondaiFontSize: 100,
    myName: "",
    currentTab: 101,
    currentMondai: {
      101: null,
      102: null,
      103: null,
      104: null,
      105: null,
      106: null,
      107: null,
      201: null,
      202: null,
      203: null,
      204: null,
    },
    currentMondaiList: {
      101: [],
      102: [],
      103: [],
      104: [],
      105: [],
      106: [],
      107: [],
      201: [],
      202: [],
      203: [],
      204: []
    },
    currentMokuji: {
      101: null,
      102: null,
      103: null,
      104: null,
      105: null,
      106: null,
      107: null,
      201: null,
      202: null,
      203: null,
      204: null,
    },
    currentKaitou: {},
    level: {
      101: 2,
      102: 2,
      103: 2,
      104: 2,
      105: 2,
      106: 2,
      107: 2,
      201: 2,
      202: 2,
      203: 2,
      204: 2
    },
    privacy_policy_accepted: false,
    ashiatoHyoujiKamoku: 0,
    ashiatoHyoujiNendo: 0,
    ashiatoHyoujiKeyword: "すべて表示",
    //dateRange: 9,
    dateRangeDual:{"lower":-30,"upper":0},
    kaitouCountToday: {
      // 0: {true: 0,false:0},
      // 101: {true: 0,false:0},
      // 102: {true: 0,false:0},
      // 103: {true: 0,false:0},
      // 104: {true: 0,false:0},
      // 105: {true: 0,false:0},
      // 106: {true: 0,false:0},
      // 107: {true: 0,false:0},
      0: 0,
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0,
    },
    kaitouCountAll: {
      0: 0,
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0,
    },
    // timer_remain: {
    //   101: 0,
    //   102: 0,
    //   103: 0,
    //   104: 0,
    //   105: 0,
    //   106: 0,
    //   107: 0
    // },
    timerCountAll: {
      0: 0,
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0
    },
    timerCountToday: {
    },
    //agree: false,
    //currentMainTab: 'mokuji'
    currentMainSlidePosition: {
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
      107: 0,
      201: 0,
      202: 0,
      203: 0,
      204: 0
    },
    hintFooterVisible: false,
    noKaisetuVisible:false,
    kaitouCountDate: new Date(),
    platformName: [""],
    currentKansouId: '',
    welcomeModalClosed: false,
    kaihouArraySelected: "",
    mondaiListKeyword: {
      101: "2021",
      102: "2021",
      103: "2021",
      104: "2021",
      105: "2021",
      106: "2021",
      107: "2021",
      201: "2021",
      202: "2021",
      203: "2021",
      204: "2021",
    },
    mondaiListKeywordDisplayStr: {
      101: "令和３年",
      102: "令和３年",
      103: "令和３年",
      104: "令和３年",
      105: "令和３年",
      106: "令和３年",
      107: "令和３年",
      201: "令和３年",
      202: "令和３年",
      203: "令和３年",
      204: "令和３年",
    },
    currentNendoRange: {
      101: 2021,
      102: 2021,
      103: 2021,
      104: 2021,
      105: 2021,
      106: 2021,
      107: 2021,
      201: 2021,
      202: 2021,
      203: 2021,
      204: 2021,
    },
    saitenKekka2ji_model: {},
    saitenKekka2ji_view: {},
    saitenKekka2jiChangedFlag: {},
    kaitoubun2jiChangedFlag: {},
    scatterValue: {},
    //gouhiJouhou: [],
    gouhi_jouhou: {},
    scatterTangoRanking:{},
    gaitouTouanSuuData:{},
    rontenSorted_goukei:[],
    rontenSorted_wariai:[],
    rankingData:{},
    junniRaderChartData:{},
    gouhiJouhouTableData:{},
    thanksListArray:[],
    handleName:"匿名",
    currentTokuten: {
      101: {},
      102: {},
      103: {},
      104: {},
      105: {},
      106: {},
      107: {},
    },
    highLightedSearchSaigenTouanKeyWord: {},
  };
  
  kamokuName = {
    101: "経済",
    102: "財務",
    103: "経営",
    104: "運営",
    105: "法務",
    106: "情報",
    107: "中小",
    201: "事例I",
    202: "事例II",
    203: "事例III",
    204: "事例IV"
  };

  public currentMokujiStatus: {
    //currentKamoku: 106,
    //categoryModeMokuji: string,
    //mondaiSyuIdMokuji: string,
    categoryModeMokuji: {
      101: string,//boolean,
      102: string,//boolean,
      103: string,//boolean,
      104: string,//boolean,
      105: string,//boolean,
      106: string,//boolean,
      107: string,//boolean
      201: string,//boolean
      202: string,//boolean
      203: string,//boolean
      204: string,//boolean
    },
    levelMokuji: {
      101: number,
      102: number,
      103: number,
      104: number,
      105: number,
      106: number,
      107: number,
      201: number,
      202: number,
      203: number,
      204: number
    },
    mokujiSaitennKekkaIconVisible: boolean,
  } = {
      //currentKamoku: 106,
      //mondaiSyuIdMokuji: String(this.currentGuiStatus.currentTab),
      //categoryModeMokuji: "" + this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab],
      categoryModeMokuji: {
        101: String(this.currentGuiStatus.categoryMode[101]),
        102: String(this.currentGuiStatus.categoryMode[102]),
        103: String(this.currentGuiStatus.categoryMode[103]),
        104: String(this.currentGuiStatus.categoryMode[104]),
        105: String(this.currentGuiStatus.categoryMode[105]),
        106: String(this.currentGuiStatus.categoryMode[106]),
        107: String(this.currentGuiStatus.categoryMode[107]),
        201: String(this.currentGuiStatus.categoryMode[201]),
        202: String(this.currentGuiStatus.categoryMode[202]),
        203: String(this.currentGuiStatus.categoryMode[203]),
        204: String(this.currentGuiStatus.categoryMode[204]),
      },
      levelMokuji: {
        101: 2,
        102: 2,
        103: 2,
        104: 2,
        105: 2,
        106: 2,
        107: 2,
        201: 2,
        202: 2,
        203: 2,
        204: 2,
      },
      mokujiSaitennKekkaIconVisible: false,
    };

  public showDetail: boolean;

  init_loop_count_mondai = 0;
  init_loop_count_mokuji = 0;
  mondaidata_init_done_all = false;//起動時の初回読み込みが終わったかのフラグ
  mokuji_init_done_all = false;//起動時の初回読み込みが終わったかのフラグ

  kaitouHistory: { [mondaiId: number]: Kaitou[] } = {};
  kaitouHistory2ji: { [mondaiId: number]: Kaitou2ji[] } = {};
  //timerHistory: { [datetime: string]: string } = {};
  kaitouMondaiJoin: GakusyuRireki[];
  dateData: {
    "name": string;
    "series": {
      "value": number;
      "name": string;
    }[];
  }[] = [];//積み上げグラフのデータ構造
  dateData_timer: {
    "name": string;
    "series": {
      "value": number;
      "name": string;
    }[];
  }[] = [];//タイマーの積み上げグラフのデータ構造
  operationLog: any =[];

  todayDate:Date = new Date();
  daysFromStartingDate:number = 30;
  guideMessageHtml : {
    'iconClass':string;
    'message-upper':string;
    'message-lower':string;
  } = {
    'iconClass':'far fa-laugh-beam',
    'message-upper':'初期',
    'message-lower':'調子!',
  };
  tutorial_mode:boolean  = false;
  tutorial_state:string = '';
  kansouString:string = '';
  // tutorial_mondai_visible_max_number:number = 200;//コードで表示する問題数を制御しようとしたが、エラーになるので、ひとまず十分大きい数字にしておく。this.currentGuiStatus.currentMokuji[this.currentGuiStatus.currentTab].mokujiMondaiList.length
  nendoName = {
    // 2022: "令和4年度",
    2021: "令和3年度",
    2020: "令和2年度",
    2019: "令和元年度",
    2018: "平成30年度",
    2017: "平成29年度",
    2016: "平成28年度",
    2015: "平成27年度",
    2014: "平成26年度",
    2013: "平成25年度",
    2012: "平成24年度",
    2011: "平成23年度",
    2010: "平成22年度",
    2009: "平成21年度",
    2008: "平成20年度",
    2007: "平成19年度"
  };

  tokutenTable = {//得点開示していない場合のデフォルト値定義
    "合格":63.5,
    "不合格":56.5,
    "A":66.1,
    "B":55.1,
    "C":47.1,
    "D":34.1,
  };
  gouhiJouhouMaster = [
    {
      mondaiId:201020111,
      name: "令和２年 事例１",
    },
    {
      mondaiId:202020111,
      name: "令和２年 事例２",
    },
    {
      mondaiId:203020111,
      name: "令和２年 事例３",
    },
    {
      mondaiId:204020111,
      name: "令和２年 事例４",
    }
  ];
  kaitouBun: KaitouBun;
  scatterSettings = [
    {
      name: "合否不明",
      //color: "rgba(100,100,100,0.5)",
      symbol: "circle-open",
      visible: "student",
    },
    {
      name: "×BCD(サイズ小は不合格)　●A(サイズ小は合格)　※色は得点目安",//"合否判明。※得点の色は目安、サイズ大はA、×は不合格(サイズ大はBCD)",
      //color: "rgba(50,250,250,0.5)",
      //symbol: "circle",
      visible: "gouhiKaitouZumi",
    },
    {
      name: "予備校",
      //color: "rgba(100,130,100,0.5)",
      symbol: "star-triangle-down-open",//"diamond-open",
      visible: "school",
    },
    {
      name: "あなたの解答",
      //color: "rgba(100,100,200,0.5)",
      symbol: "star",//"star",//"hexagram",//
      visible: "mine",
    },
  ];
  
  scatterDataAll = {};
  barChartAccordionOpenTargetMondaiId: string = "default";
  gouhiJouhouAll = [];
  hyoukaStrArr:string[] = ["A","B","C","D"];
  jireiMondaiSyuIdArr:number[] = [201,202,203,204];
  
  jireiName = {
    201: "事例 Ⅰ",
    202: "事例 Ⅱ",
    203: "事例 Ⅲ",
    204: "事例 Ⅳ",
  };

  searchedMondaiList:Mondai[];
  saigenTouanTeikyouTaisyouNendo = 2021;
  saigenTouanTeikyouResult = {};
  saigenTouanList = {};//再現答案の本文のリストを、問題IDごとの辞書に格納するための変数
  saigenTouanList_view = [];//GUI表示用
  saigenTouanTangoArray = {};//再現答案に含まれる単語を格納するArray
  hitTangoDictionary = {};
  likeList = {};

  yobikouUrls = {
    "KEC":"http://www.kec.ne.jp/shindanshi/event/ans-2021second/",
    "TAC":"https://edit.tac-school.co.jp/edit01/kouza_chusho/sokuhou_2ji.html#Sokuhou",
    "大原":"https://www.o-hara.jp/course/shindanshi/cyu_answer_flash_02",
    "MMC":"https://www.mmc-web.net/form-kaitou21.html",
    "まとめシート":"https://www.youtube.com/watch?v=w91YBFAYWDM"
  }
  kaijiData:Kaiji[] = [];
  //kaijiVirtualData:Kaiji[] = [];
  kaijiDataView:Kaiji[] = [];
  hyoujiJunniSentakuShiArray = [];
  selectedHyoujiJunni = 1;
  kaijiSortJireiNum = 0;
  kaijiSortJireiNumSentakushiArray = [
    {
      "text":"合計点の順にソート",
      "value":0,
    },
    {
      "text":"事例I",
      "value":1,
    },
    {
      "text":"事例II",
      "value":2,
    },
    {
      "text":"事例III",
      "value":3,
    },
    {
      "text":"事例IV",
      "value":4,
    },
  ]
  //定数定義終わり//////////////////


  constructor(
    private afStore: AngularFirestore,
    public todoService: TodoService,
    private modalController: ModalController,
    public popoverController: PopoverController,
    public toastController: ToastController,
    public http: HttpClient,
  ) {
    //開示のデータ読み込み
    
    // var fs = require('fs-extra');
    // var csvParse = require('csv-parse/lib/sync');
    
    // const tangoCsvFile = fs.readFileSync('./spec/11_MondaiMaster_input/tangoMaster_v2.csv');
    // var tangoPDFcsvParsed = csvParse(tangoCsvFile, {
    //     delimiter: ',', 
    //     columns: true, 
    // });
    // //this.kaijiData = 
    // console.log("tangoPDFcsvParsed--",tangoPDFcsvParsed);
    
    // fetch('./models/tangoMaster_v2.csv')
    // .then(response => response.text())
    // .then(data => {
    //   // Do something with your data
    //   console.log(data);
    // });
    
    this.getKaijiData();
  }

  getKaijiData(){
    this.kaijiData = [];
    let targetJireiNum = this.kaijiSortJireiNum;
    //得点・答案マップの元データのARRを生成する
    this.http.get("assets/kaiji/kaijiMaster.csv", { responseType: 'text' }).subscribe(data => {
      let lines = data.split('\r\n');
      for (let line = 0; line < lines.length; line++) {
        // By tabs
        let tabs = lines[line].split('\t');
        this.kaijiData.push(
          { 
            //urlTweetKaiji: tabs[0],
            urlTweetSaigenTouan: [
              this.getSaigenTouanUrl(0, this.getTokutenRank(0,Number(tabs[5])), tabs[0]),//tabs[0],//得点開示のURL

              this.getSaigenTouanUrl(1, this.getTokutenRank(1,Number(tabs[1])), tabs[6]),//事例１再現答案のURL
              this.getSaigenTouanUrl(2, this.getTokutenRank(2,Number(tabs[2])), tabs[7]),//事例１再現答案のURL
              this.getSaigenTouanUrl(3, this.getTokutenRank(3,Number(tabs[3])), tabs[8]),//事例１再現答案のURL
              this.getSaigenTouanUrl(4, this.getTokutenRank(4,Number(tabs[4])), tabs[9]),//事例１再現答案のURL
              // tabs[6],//事例１再現答案のURL
              // tabs[7],//事例2再現答案のURL
              // tabs[8],//事例3再現答案のURL
              // tabs[9],//事例4再現答案のURL
            // urlTweetSaigenTouan_2: tabs[7],
            // urlTweetSaigenTouan_3: tabs[8],
            // urlTweetSaigenTouan_4: tabs[9],
            ],
            tokutenJirei: [
              Number(tabs[5]),
              Number(tabs[1]),
              Number(tabs[2]),
              Number(tabs[3]),
              Number(tabs[4])
            ],
            tokutenRank: [//優秀・合否を格納
              this.getTokutenRank(0,Number(tabs[5])),
              this.getTokutenRank(1,Number(tabs[1])),
              this.getTokutenRank(2,Number(tabs[2])),
              this.getTokutenRank(3,Number(tabs[3])),
              this.getTokutenRank(4,Number(tabs[4])),
            ],
            junni: [
              Number(tabs[10]),
              Number(tabs[11]),
              Number(tabs[12]),
              Number(tabs[13]),
              Number(tabs[14]),
            ],
            // tokutenJirei_1: Number(tabs[1]),
            // tokutenJirei_2: Number(tabs[2]),
            // tokutenJirei_3: Number(tabs[3]),
            // tokutenJirei_4: Number(tabs[4]),
            // urlTweetSaigenTouan_1: tabs[6],
            // urlTweetSaigenTouan_2: tabs[7],
            // urlTweetSaigenTouan_3: tabs[8],
            // urlTweetSaigenTouan_4: tabs[9],
          }
        )
        //console.log("kaijiData--",this.kaijiData);
      }
      //this.sortKaijiData();
      this.generateTokutenHyoujiSentakusi(targetJireiNum);
      this.searchKaijiData(targetJireiNum, this.selectedHyoujiJunni);
    })
  }

  generateTokutenHyoujiSentakusi(targetJireiNum:number){
    //表示対象の順位の選択肢のマスタデータを作る

    //対象事例の順位順にソート
    //ARRを５ごとに上からプラス５までなめて、ヒットしたらBreak

    this.hyoujiJunniSentakuShiArray = [];
    let maxSelectCount = Math.floor(this.kaijiData.length/5);
    //console.log("maxSelectCount--",maxSelectCount);
    for (let selectItem = 0; selectItem < maxSelectCount; selectItem++) {
      //console.log("対象順位 --",selectItem * 5 + 1);
      let copiedKaijiData:Kaiji[] = JSON.parse(JSON.stringify(this.kaijiData));
      //範囲内の順位の条件を満たすものを配列で取得
      //console.log("selectItem * 5--",selectItem * 5);
      let targetKaijiData:Kaiji[] = copiedKaijiData.filter(data => {
        return ( selectItem * 5 + 1 <= data.junni[targetJireiNum] && data.junni[targetJireiNum] < selectItem * 5  + 6 );
      });
      //console.log("対象者数 --",targetKaijiData.length);
      if (targetKaijiData.length != 0){
        //配列が０でない場合は、取得した配列をソートして最小の値を取得
        targetKaijiData = this.sortKaijiData(targetJireiNum, targetKaijiData);
        this.hyoujiJunniSentakuShiArray.push({
          "text": targetKaijiData[0].junni[targetJireiNum] + "位 "+targetKaijiData[0].tokutenJirei[targetJireiNum]+"点～",
          "value": selectItem * 5 + 1
        })
      }
    }
    
    // for (let i_kjd in this.kaijiData) {
    //   //
    // }
    
    // this.hyoujiJunniSentakuShiArray = [
    //   {
    //     "text":"1位 302点",
    //     "value":1
    //   },
    //   {
    //     "text":"6位 270点",
    //     "value":6
    //   },
    //   {
    //     "text":"10位 260点",
    //     "value":10
    //   },
    // ]
  }

  sortKaijiData(targetJireiNum:number, targetKaijiData: Kaiji[]){
    //得点開示のデータを特典でソートする関数
    //let jireiNum = 0;
    let sortedKaijiData = JSON.parse(JSON.stringify(targetKaijiData));
    
    //指定の事例でソート（０の場合は総得点）
    
    sortedKaijiData.sort(function (a, b) {
      //ターゲットの事例、総合点のソートの優先度で、ソート
      if (a.junni[targetJireiNum] < b.junni[targetJireiNum]) return -1;
      if (a.junni[targetJireiNum] > b.junni[targetJireiNum]) return 1;
      if (a.junni[0] < b.junni[0]) return -1;
      if (a.junni[0] > b.junni[0]) return 1;
      return 0;
      // if (a.junni[targetJireiNum] < b.junni[targetJireiNum]) return -1;
      // if (a.junni[targetJireiNum] > b.junni[targetJireiNum]) return 1;
      // return 0;
    });

    //this.kaijiData = sortedKaijiData;
    return sortedKaijiData;
  }

  searchKaijiData(jireiNum: number, searchStartJunni: number){
    //得点開示のデータを検索する関数
    //console.log("this.kaijiData--",this.kaijiData)
    let targetKaijiData:Kaiji[] = this.kaijiData.filter(data => {
      return ( searchStartJunni <= data.junni[jireiNum] && data.junni[jireiNum] < searchStartJunni + 5  );
    });
    
    this.kaijiData = this.sortKaijiData(jireiNum,targetKaijiData);
  }

  getSaigenTouanUrl(jireiNum: number, rankString: string, urlString: string) {
    //
    let urlStringToReturn = "";
    //console.log("rankString--",rankString)

    if (jireiNum ==0){
      //合計点の場合
      if (rankString != 'fugoukaku') {
        urlStringToReturn = urlString;
      }  
    } else {
      if (rankString == 'saiyuusyuu' && urlString != '再現答案なし') {
        urlStringToReturn = urlString;
      }  
    }
    return urlStringToReturn;
  }

  getTokutenRank(jireiNum: number, tokuten: number) {
    //優秀・合否を判定する
    let rankNum = 0;
    let rankToReturnString = "fugoukaku";
    let rankDic = {
      0: [0, 240, 256, 271],
      1: [0, 60, 69, 78],//1/2,1/4
      2: [0, 60, 64, 70],
      3: [0, 60, 64, 70],
      4: [0, 60, 66, 72],
    }
    let targetRankDic = rankDic[jireiNum];
    for (let i_trd in targetRankDic) {
      //
      if (Number(tokuten) >= targetRankDic[i_trd]) {
        //
        rankNum = Number(i_trd);
      }
    }
    if (rankNum == 1) {
      rankToReturnString = "goukaku";
    } else if (rankNum == 2) {
      rankToReturnString = "yuusyuu";
    } else if (rankNum == 3) {
      rankToReturnString = "saiyuusyuu";
    }
    return rankToReturnString;
  }

  setKansouDoc(){
    //最新KansouをストリームできるObservableの設定をする
    this.kansouDoc = this.afStore.doc<Kansou>(`user/${this.currentGuiStatus.myName}/kansou/${this.currentGuiStatus.currentKansouId}`);
    this.item = this.kansouDoc.valueChanges();
  }


  getAllRecord(keyName: string) {
    if (localStorage.getItem(keyName)) {
      return JSON.parse(localStorage.getItem(keyName));
    } else {
      return null;
    }
  }

  // async initMondaiDataCfs( mondaiIdArray: number[]) {
  //   for (var key in mondaiIdArray) {
  //     this.commonCfsClassDoc = this.afStore.doc<commonCfsClass>('mondaiMaster/'+mondaiIdArray[key]);
  //     let commonCfsClass = this.commonCfsClassDoc.valueChanges();
  //     this.mondaidataOnCfs[mondaiIdArray[key]] = commonCfsClass;
  //   }
  // }

  async initCfsDocuments() {
    //問題マスタの参照を初期化する
    let mondaiIdArray = this.mondaidataOnFile.map(a => a.mondaiId);

    for (var key in mondaiIdArray) {
      this.mondaiJsonDoc = this.afStore.doc<Mondai>('mondaiMaster/' + mondaiIdArray[key]);
      this.mondaidataOnCfs[mondaiIdArray[key]] = this.mondaiJsonDoc.valueChanges();
    }

    // //画像ファイルのある問題のID配列を取得
    // let targetMondai:Mondai[] = this.mondaidataOnFile.filter(mondai => {
    //   return ( mondai.imgFile_Q || mondai.imgFile_A );
    // });
    // let mondaiIdArrayHavingPng = targetMondai.map(a => a.mondaiId);

    // for (var key in mondaiIdArrayHavingPng) {
    //   this.mondaiPngJsonDoc = this.afStore.doc<mondaiPngJson>('mondaiPng/'+mondaiIdArrayHavingPng[key]);
    //   this.mondaiPngOnCfs[mondaiIdArrayHavingPng[key]] = this.mondaiPngJsonDoc.valueChanges();
    // }
  }

  //オブジェクトをJSON化してまるごと保存する。cfs書き込みのフラグとどういうラグが立っていた場合は、CFSにも保存する
  backUpObjectToDB(keyName: string, toBeInsertObject: any, cfsWriteBool?: boolean) {
    const toBeInsertStr = JSON.stringify(toBeInsertObject);
    localStorage.setItem(keyName, toBeInsertStr);

    //cloudFireStoreMyName = cloudFireStoreMyName || '';//引数なければデフォルトで空文字入れる
    //if (cfsWriteBool) {
    if (cfsWriteBool&&this.currentGuiStatus.privacy_policy_accepted) {
      const data: CFSDataContainer = {
        toBeInsertStr: toBeInsertStr,
        //myName: cloudFireStoreMyName,
        myName: this.currentGuiStatus.myName,
      };
      const cFSDoc: AngularFirestoreDocument<CFSDataContainer> =
        //this.afStore.doc('user/'+this.myName);//+'/test');//+keyName);
        this.afStore.doc(`user/${this.currentGuiStatus.myName}/keyName/${keyName}`);
      cFSDoc.set(data);
    }
  }

  // updateQa(keyName: string, toBeInsertObject: any) {
  //   const toBeInsertStr = JSON.stringify(toBeInsertObject);
  //   const data: CFSDataContainer = {
  //     toBeInsertStr: toBeInsertStr,
  //     myName: this.currentGuiStatus.myName,
  //   };

  //   const cFSDocQa: AngularFirestoreDocument<CFSDataContainer> =
  //     this.afStore.doc(`qa/${this.currentGuiStatus.myName}/keyName/${keyName}`);
  //   cFSDocQa.set(data);

  //   const cFSDocQaAll: AngularFirestoreDocument<CFSDataContainer> =
  //     this.afStore.doc(`qa/all/keyName/${keyName}`);
  //   cFSDocQaAll.set(data);
  // }

  getOneMondaiFromFile(mondaiId: number): Mondai {
    let tmpMondai = this.mondaidataOnFile;
    //該当の問題を抽出
    let targetMondai: Mondai[] = tmpMondai.filter(mondai => {
      return (mondai.mondaiId == mondaiId);
    });
    return targetMondai[0];
  }

  getMondaiArr(targetOkWords: string[]): Mondai[] {
    let tmpMondai = this.mondaidataOnFile;
    //該当の問題を抽出
    
    //let targetMondai: Mondai[] = [tmpMondai[0]]
    let returnMondai: Mondai[] = [];

    for (var i_tow in targetOkWords) {
      //
      let targetMondai: Mondai[] = tmpMondai.filter(mondai => {
        return (mondai.mondaiBun.indexOf(targetOkWords[i_tow]) !== -1);
      });
      returnMondai = returnMondai.concat(JSON.parse(JSON.stringify(targetMondai)));
      //console.log("returnMondai.length--",returnMondai.length)
      
    }

    //重複する問題を除去する
    const uniqueArray = returnMondai.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === returnMondai.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });
    
    return uniqueArray;
  }

  getMondaiArr_nijiFirstMondai(nendo: number): Mondai[] {
    let tmpMondai = this.mondaidataOnFile;
    //該当の問題を抽出
    
    //let targetMondai: Mondai[] = [tmpMondai[0]]
    let returnMondai: Mondai[] = tmpMondai.filter(mondai => {
      return (mondai.niji == true && mondai.nendo == nendo && String(mondai.mondaiId).endsWith('0111') == true);
    });
    
    return returnMondai;
  }

  
  getMondaiArr_saigenTouanTeikyou(): Mondai[] {
    let tmpMondai = this.mondaidataOnFile;
    //該当の問題を抽出
    
    //let targetMondai: Mondai[] = [tmpMondai[0]]
    let returnMondai: Mondai[] = tmpMondai.filter(mondai => {
      return (mondai.niji == true && mondai.nendo == this.saigenTouanTeikyouTaisyouNendo);
    });
    
    return returnMondai;
  }

  getMondaiIdArr(targetOkWords: string[]): number[] {
    //OkWordを含む問題のIDだけ返す関数
    let tmpMondaiArr:Mondai[] = this.getMondaiArr(targetOkWords);
    let returnMondaiIdArray: number[] = [];

    for (var i_tmd in tmpMondaiArr) {
      //IDだけ取得していく。ただし二次試験は含めない
      if (tmpMondaiArr[i_tmd].niji != true){
        returnMondaiIdArray.push(tmpMondaiArr[i_tmd].mondaiId);      
      }
    }
    return returnMondaiIdArray;
  }


  getYougoDataArray(keyWord: string, fuzzySearch?: boolean): YougoData[]{
    //引数の文字列の中に含まれる、YougoDataをArrayで返す
    let tmpYougoData:YougoData[] = this.YougoDataFilledMondai;
    let returnYougoArr:YougoData[] = [];
    //console.log("keyWord--",keyWord);
    // console.log("fuzzySearch-",fuzzySearch)
    // console.log("keyWord-",keyWord)
    for (var i_tyd in tmpYougoData) {
      if (fuzzySearch == true) {
        //console.log("returnYougoArr-",returnYougoArr)
        if (
          //keywordが、OkWordのどこか一分に含まれていたら、返す
          tmpYougoData[i_tyd].kaisetu.indexOf(keyWord) !== -1
        ) {
          returnYougoArr.push(tmpYougoData[i_tyd]);
        } else if (
          //keywordが、Nameのどこか一分に含まれていたら、返す
          tmpYougoData[i_tyd].name.indexOf(keyWord) !== -1
        ) {
          returnYougoArr.push(tmpYougoData[i_tyd]);
        } else {
          for (var i_tok in tmpYougoData[i_tyd].targetOkWords) {
            //Fuzzyの場合は、Kaisetuの一部か、OkWordの一部なら返す
            if (
              //keywordが、OkWordのどこか一分に含まれていたら、返す
              tmpYougoData[i_tyd].targetOkWords[i_tok].indexOf(keyWord) !== -1
            ) {
              returnYougoArr.push(tmpYougoData[i_tyd]);
              break; //OKワードごとに追加すると重複発生するのでブレーク
            }
          }
        }
      } else {
        //fuzzy出ない場合は、OkWordとの一致の場合のみ返す
        for (var i_tokw in tmpYougoData[i_tyd].targetOkWords) {
          //Fuzzyの場合は、Kaisetuの一部か、OkWordの一部なら返す
          //console.log("tmpYougoData[i_tyd].targetOkWords[i_tokw]-",tmpYougoData[i_tyd].targetOkWords[i_tokw])
          if (
            //keywordが、OkWordのどこか一分に含まれていたら、返す
            keyWord.indexOf(tmpYougoData[i_tyd].targetOkWords[i_tokw]) !== -1
          ) {
            returnYougoArr.push(tmpYougoData[i_tyd]);
            break; //OKワードごとに追加すると重複発生するのでブレーク
          }
        }
      }
    }     
    return returnYougoArr;
  }
  
  getKanrenYougoData(targetMondai:Mondai): YougoData[]{
    //指定したMONDAIが、出題歴に含まれるYougoDataを返す
    let tmpYougoData:YougoData[] = this.YougoDataFilledMondai;
    let returnYougoArr:YougoData[] = [];
    for (var i_ty in tmpYougoData) {
      for (var i_ma in tmpYougoData[i_ty].mondaiIdArray) {
        if ( tmpYougoData[i_ty].mondaiIdArray[i_ma] == targetMondai.mondaiId){
          //もし、引数と同一のMondaiIdが、YougoのmondaiArrayに含まれていたら、PUSHする
          returnYougoArr.push(tmpYougoData[i_ty]);
          // console.log("追加した-"+targetMondai.mondaiId);
          // console.log("追加した-"+tmpYougoData[i_ty].name);
        }
      }
    }
          
    return returnYougoArr;
  }

  getSearchedMondaiList(): Mondai[] {
    let tmpMondai = this.mondaidataOnFile;
    let targetNendo:number = 2021;
    let targetmondaiSyuId:number = 106;
    //該当の問題を抽出
    let targetMondai: Mondai[] = tmpMondai.filter(mondai => {
      return (mondai.nendo == targetNendo && mondai.mondaiSyuId == targetmondaiSyuId);
    });
    return targetMondai;
  }

  async showHalfModal(ev: any,targetYougoData:YougoData){
    //YougoのMDをHTMLに変換して、PopOver開く関数
    marked.setOptions({
      sanitize: false,
      breaks: false,//true,//Trueでスペース無しで開業するが、HTMLタグ書くときに無駄な改行が入ってしまうので
    });
    let yougoKaisetuString = marked(targetYougoData.kaisetu);
    //marked変換

    const modal = await this.modalController.create({
      component: HalfModalPage,
      cssClass: ['half-modal'],
      swipeToClose: true,
      backdropDismiss: true,
      componentProps: {
        yougoKaisetuString,
        //firestoreAccessor: this,
        currentYougoData: targetYougoData,
        kamokuName:this.kamokuName,
        nendoName:this.nendoName,
      }
    });
    await modal.present();  
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  // async showQuickYougo(ev: any,targetYougoData: YougoData) {
  //   //YougoのMDをHTMLに変換して、PopOver開く関数
  //   marked.setOptions({
  //     sanitize: false,
  //     breaks: false,//true,//Trueでスペース無しで開業するが、HTMLタグ書くときに無駄な改行が入ってしまうので
  //   });
  //   let yougoKaisetuString = marked(targetYougoData.kaisetu);
  //   //marked変換

  //   const popover = await this.popoverController.create({
  //     component: HalfModalPage,
  //     cssClass: 'my-custom-class',
  //     showBackdrop:true,
  //     event: ev,
  //     translucent: true,
  //     mode: "ios",
  //     componentProps: {
  //       yougoKaisetuString,
  //     }
  //   });
  //   return await popover.present();
  // }
  
  
  searchDocument(evt) {
    if (evt.detail){
      this.searchedMondaiList = [];//初期化
      //半角スペースで分割
      const searchTerm =  evt.detail.value.split(" ");//evt.detail.value;

      let tmpMondai = this.mondaidataOnFile;
      for (var i_td in tmpMondai) {

        for (var i_st in searchTerm) {
          if (tmpMondai[i_td].mondaiBun.search( searchTerm[i_st] ) > 0){
            //対象のMondaiに検索後の文字列が含まれていれば、結果のARRに追加する
            //targetMondai.push(tmpMondai[i_td]);
            let targetMondaiView: Mondai = JSON.parse(JSON.stringify(tmpMondai[i_td]));
            targetMondaiView.mondaiBun = this.hightLightKeyword(tmpMondai[i_td]["mondaiBun"], searchTerm);
            this.searchedMondaiList.push(targetMondaiView)
            break;
          }          
        }
      }

    }
  }


  hightLightKeyword(honbun: string, targetKeyWordArr: string[]): string {
    //もくじで、検索キーワードをハイライトする
    let highLightedString: string = honbun;
    //console.log("targetKeyWordArr",targetKeyWordArr)
    for (var i_tkw in targetKeyWordArr) {
      const beforeString = new RegExp(targetKeyWordArr[i_tkw], 'g');///targetKeyWordArr[i_tkw]/;
      let afterString = "<font color=#ff2233><span style='background-color:#ffffff'>\n" + targetKeyWordArr[i_tkw] + '</span></font>';

      highLightedString = highLightedString.replace(beforeString, afterString);
    }
    return highLightedString;
  }



  searchKaitouRireki(targetMondai:Mondai):Kaitou[]{
    //対象の問題の科目、年度の、解説表示中の問題の、回答履歴を取得する
    let searchedKaitou:Kaitou[] = [];
    let tmpMondai: Mondai[] = this.getMondai_NendoArr(targetMondai.nendo);
    if (this.kaitouHistory) {
      for (var mondaiForArray in tmpMondai) {
        if (this.kaitouHistory[tmpMondai[mondaiForArray].mondaiId] != null) {
          let targetMondaiId = tmpMondai[mondaiForArray].mondaiId;
          let tmpKaitou: Kaitou = this.kaitouHistory[targetMondaiId][this.kaitouHistory[targetMondaiId].length - 1];
          searchedKaitou.push(tmpKaitou);
        }
      }
    }
    return searchedKaitou;    
  }

  tokutenSyuukei(targetMondai:Mondai){
    //searchKaitouRirekiで得た解答から、得点／解答済み得点　を合算する
    let searchedKaitou:Kaitou[] = this.searchKaitouRireki(targetMondai);
    let tokutenDic = {
      "tokuten": 0,
      "tokutenMax": 0,
      "tokutenGoukei": 0,
      "tokutenGoukeiMax": 0,
    }
    // let tmpTokuten = 0;
    // let tmpTokutenGoukei = 0;
    for (var isk in searchedKaitou) {
      //console.log("searchedKaitou[isk]"+JSON.stringify(searchedKaitou[isk]))
      if (searchedKaitou[isk].saitenKekka == true|| searchedKaitou[isk].saitenKekka == false){
        //採点までしていたら、合計点に加算
        
        //配点情報を取得
        let targetHaiten = targetMondai.haiten;
        let targetHaitenMax = targetMondai.haiten;
        if (targetMondai.haitenMax){
          //得点の上限があれば（配点が公式発表前で不明確な問題）、上限取得
          targetHaitenMax = targetMondai.haitenMax;
        }

        tokutenDic["tokutenGoukei"] = tokutenDic["tokutenGoukei"]  + targetHaiten;
        tokutenDic["tokutenGoukeiMax"] = tokutenDic["tokutenGoukeiMax"]  + targetHaitenMax;
        if (searchedKaitou[isk].saitenKekka == true){
          //正解していたら、得点に加算
          tokutenDic["tokuten"] = tokutenDic["tokuten"]  + targetHaiten;
          tokutenDic["tokutenMax"] = tokutenDic["tokutenMax"]  + targetHaitenMax;
        }           
        
      }
    }
    //仮配点が１００点超えたら、１００点にしておく
    if ( tokutenDic["tokutenMax"] > 100){
      tokutenDic["tokutenMax"] = 100;
    }

    this.currentGuiStatus.currentTokuten[targetMondai.mondaiSyuId] = tokutenDic;
  }
  
  getMondai_NendoArr(nendo?:number): Mondai[] {
    let baseNendo:Number = 2021;
    if (nendo != null){
      baseNendo = nendo;
    } else {
      //引き数いない場合は、カレントの年度の問題のリスト取得
      let cMData: Mondai = this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab];
      baseNendo = cMData.nendo;
    }
    let tmpMondai = this.mondaidataOnFile;
    //該当の問題を抽出
    let targetMondai: Mondai[] = tmpMondai.filter(mondai => {
      return (mondai.nendo == baseNendo && mondai.mondaiSyuId == this.currentGuiStatus.currentTab);
    });
    return targetMondai;
  }

  // getOneMondaiFromCache( mondaiId : number ):Mondai{ 
  //   let tmpMondai = this.mondaidataOnCache;
  //   //該当の問題を抽出
  //   let targetMondai:Mondai[] = tmpMondai.filter(mondai => {
  //     return ( mondai.mondaiId==mondaiId );
  //   });
  //   return targetMondai[0];
  // }

  // //年度のすべての問題CARDを同時に表示するGUI版の関数。一旦コメントアウトする
  // setMondaiList(mondaiSyuId: number) {
  //   //指定された科目を、カレントの科目にする
  //   //問題ID、科目、年度、キーワードが合致する問題のArrayを取得し、カレントの問題のリストに入れる
  //   let tmpMondai: Mondai[] = JSON.parse(JSON.stringify(this.mondaidataOnFile));

  //   //科目絞り込み
  //   tmpMondai = tmpMondai.filter(mondai => {
  //     return (mondai.mondaiSyuId == mondaiSyuId);
  //   });

  //   let nendoInt: number = Number(this.currentGuiStatus.mondaiListKeyword[mondaiSyuId]);
  //   if (this.nendoName[nendoInt] != null) {
  //     //キーワードが対象の年度の文字列であれば、nendoの数値で絞り込む
  //     tmpMondai = tmpMondai.filter(mondai => {
  //       return (mondai.nendo == nendoInt);
  //     });
  //     this.currentGuiStatus.mondaiListKeywordDisplayStr[mondaiSyuId] = this.nendoName[nendoInt];
  //   } else if (this.currentGuiStatus.mondaiListKeyword[mondaiSyuId] == "") {
  //     //カテゴリのIDに含まれていればカテゴリの検索条件で絞り込み
  //   } else {
  //     //どの条件にも合致しない場合は、デフォでR2に絞る
  //     tmpMondai = tmpMondai.filter(mondai => {
  //       return (mondai.nendo == 2020);
  //     });
  //     this.currentGuiStatus.mondaiListKeywordDisplayStr[mondaiSyuId] = this.nendoName[2020];
  //   }

  //   let tmpMondaiNumArray:number[] = [];
  //   for (var mondaiForArray in tmpMondai){
  //     //
  //     tmpMondaiNumArray.push(tmpMondai[mondaiForArray].mondaiId);
  //   }
  //   console.log("tmpMondai"+tmpMondai)
  //   this.currentGuiStatus.currentMondaiList[mondaiSyuId] = tmpMondaiNumArray;

  //   // if ( mondaiId != 0){
  //   //   tmpMondai = tmpMondai.filter(mondai => {
  //   //     return (mondai.mondaiId == mondaiId);
  //   //   });
  //   // }

  //   //カレントの問題がカラなら、問題リストの先頭の問題をカレントにセット。
  //   if (this.currentGuiStatus.currentMondai[mondaiSyuId] == null) {
  //     //console.log("this.currentGuiStatus.currentMondaiList[Number(mondaiSyuId)][0]_" + JSON.stringify(this.currentGuiStatus.currentMondaiList[Number(mondaiSyuId)][0]) + "___" + mondaiSyuId);
  //     let targetMondaiId: number = this.currentGuiStatus.currentMondaiList[Number(mondaiSyuId)][0];
  //     this.currentGuiStatus.currentMondai[Number(mondaiSyuId)] = this.getOneMondaiFromFile(targetMondaiId);
  //   }

  //   //解答を格納する変数の初期化
  //   for (var targetMondai in this.currentGuiStatus.currentMondaiList[mondaiSyuId]) {
  //     let targetMondaiId = this.currentGuiStatus.currentMondaiList[mondaiSyuId][targetMondai];
  //     //console.log("SSS"+targetMondaiId);
  //     this.loadLastKaitouHistory(mondaiSyuId, targetMondaiId);
  //   }
  //   this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);

  // }
  
  setMondaiList(mondaiId: number) {
    //指定された科目を、カレントの科目にする
    //問題ID、科目、年度、キーワードが合致する問題のArrayを取得し、カレントの問題のリストに入れる
    // let tmpMondai: Mondai[] = JSON.parse(JSON.stringify(this.mondaidataOnFile));

    // //科目絞り込み
    // tmpMondai = tmpMondai.filter(mondai => {
    //   return (mondai.mondaiId == mondaiId);
    // });

    let targeMondai:Mondai = this.getOneMondaiFromFile(mondaiId);

    // let tmpMondaiNumArray:number[] = [];
    // for (var mondaiForArray in tmpMondai){
    //   //
    //   tmpMondaiNumArray.push(tmpMondai[mondaiForArray].mondaiId);
    // }

    //カレントの問題リスト　を更新
    this.currentGuiStatus.currentMondaiList[targeMondai.mondaiSyuId] = [targeMondai.mondaiId];
    //カレントの問題　を更新
    this.currentGuiStatus.currentMondai[targeMondai.mondaiSyuId] = targeMondai;
    //解答履歴を作成
    if (targeMondai.niji){
      //二次の場合は、関連問題も初期化する
      for (var arrPosition in targeMondai.daimonList){
        let targetMondaiId = targeMondai.daimonList[arrPosition];
        this.loadLastKaitouHistory(this.getOneMondaiFromFile(targetMondaiId).mondaiSyuId, targetMondaiId);
      }
    } else {
      this.loadLastKaitouHistory(targeMondai.mondaiSyuId, targeMondai.mondaiId);
    }

    this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);

  }



  async getOneMondaiFromCfs(kamokuNum: number, targetMondaiId: number, sippituMode?: boolean) {
    //同科目の他の問題に手を出して（選択肢選んだり消去法選んだり）なければ、初期値に戻す　＠　解説のvisibleと、ラジオボタンの状態
    // this.currentGuiStatus.mondaiVisible[kamokuNum] = true;
    // this.currentGuiStatus.selectedSentakushi[kamokuNum] = "";
    //this.currentGuiStatus.excludedSentakushi[kamokuNum] = {mondaiId:'',excludedArray:[]};
    let returnMondai: Mondai;
    if (sippituMode) {
      //執筆モードの場合のみ、ファイルから取得
      returnMondai = this.getOneMondaiFromFile(targetMondaiId);
      this.currentGuiStatus.currentMondai[kamokuNum] = returnMondai;
      //console.log('執筆時なのでファイルから取得_returnMondai.kaisetuBun_'+returnMondai.kaisetuBun);
      this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);
    } else {
      //執筆モードでない場合は、CFSから取得をTryしてNGならファイルから取得
      let data;
      this.afStore.collection('mondaiMaster').doc('' + targetMondaiId).ref.get()
        .then(doc => {
          if (!doc.exists) {
            //console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data());
            data = doc.data();
            returnMondai = JSON.parse(JSON.stringify(data));

            //一度DBに書き込み、読み出しすることで、参照を実態のデータに変換する
            this.backUpObjectToDB('' + targetMondaiId, returnMondai);
            returnMondai = JSON.parse(localStorage.getItem('' + targetMondaiId));

            this.currentGuiStatus.currentMondai[kamokuNum] = returnMondai;
            this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);
          }
        })
        .catch(err => {
          console.log('Error発生しましたAT getting document', err);
          returnMondai = this.getOneMondaiFromFile(targetMondaiId);
          this.currentGuiStatus.currentMondai[kamokuNum] = returnMondai;
          //console.log('returnMondai_'+JSON.stringify(returnMondai));
          //alert("この端末は現在オフラインの可能性があります。問題の一部が正常に表示されない場合は、ネットワーク接続を確認し、もくじを再度クリックして再読込してください。err= " + err);
          this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);
        });
    }
  }


  // jumpToMondaiAndMokuji(targeKamokuId,targetMondaiId,categoryMode,mokujiItem){
  //   //

  //   this.currentGuiStatus.categoryMode[targeKamokuId] = categoryMode;
  //   this.currentGuiStatus.currentMokuji[targeKamokuId] = mokujiItem;
  // }

  // insertKaitou(mondaiId: number, kaitou: Kaitou, myName: string) {
  //   // let rand = Math.floor( 1000 + Math.random() * 9000 ) ;
  //   // let kaitouId:String =  [
  //   //   this.dateFormat.format(new Date(), 'yyyyMMdd_hhmmss_') , rand
  //   // ].join('');
  //   // const kaitouDoc: AngularFirestoreDocument<Kaitou> = 
  //   //   this.afStore.doc(`mondaiStatistics/${mondaiId}/kaitou/${kaitouId}`);

  //   // const kaitouDoc: AngularFirestoreDocument<Kaitou> =
  //   //   this.afStore.doc(`mondaiStatistics/${mondaiId}/kaitou/${myName}`);

  //   let kaitouId: String = [mondaiId, myName].join('_');
  //   const kaitouDoc: AngularFirestoreDocument<Kaitou> =
  //     this.afStore.doc(`kaitouStatistics/${kaitouId}`);

  //   const data: Kaitou = kaitou;
  //   kaitouDoc.set(data);
  // }

  getOneMokujiData(mondaiId: number, categoryMode: boolean) {
    //ターゲットの問題IDを含むもくじのうち、最初のもくじを返す
    let tmpMokujiData: MokujiData[] = [];
    let mokujidataOnFileCopied = JSON.parse(JSON.stringify(this.mokujidataOnFile));
    if (categoryMode) {
      //カテゴリーモードの場合
      tmpMokujiData = mokujidataOnFileCopied.filter(data => {
        //該当の問題IDを含み、カテゴリ別の目次を返す
        return (data.mokujiMondaiList.indexOf(mondaiId) !== -1 && data.tangoId);
      });
    } else {
      //年度別の場合
      tmpMokujiData = mokujidataOnFileCopied.filter(data => {
        //該当の問題IDを含み、年度別の目次を返す
        return (data.mokujiMondaiList.indexOf(mondaiId) !== -1 && !data.tangoId);
      });
    }
    return tmpMokujiData[0]
  }

  getFuseikaiMondaiIdList(targetMondaiSyuId: number) {
    let fuseikaiMondaiIdList: number[] = [];
    for (var mondaiId in this.kaitouHistory) {
      //console.log("mondaiId_getFuseikaiMondaiIdList_" + mondaiId);
      if (this.getOneMondaiFromFile(Number(mondaiId)).mondaiSyuId == targetMondaiSyuId) {
        //console.log("mondaiId_" + mondaiId);
        let recentKaitou:Kaitou = this.getLastKaitouHistory_HavingSaitenKekka(Number(mondaiId) );
        if (recentKaitou != null ) {
          if (recentKaitou.saitenKekka == false ) {
            //不正解の問題IDを格納するArrayに追加
            fuseikaiMondaiIdList.push(Number(mondaiId));
          }
        }
      }
    }
    return fuseikaiMondaiIdList;
  }

  generateIchirandataNendoBetu() {
    //問題の一覧表データを生成する @ 年度別
    let mokujidataOnFileCopied = JSON.parse(JSON.stringify(this.mokujidataOnFile));
    let ichiranData = {};
    let tmpMokujiData: MokujiData[] = [];

    for (var tmpMondaiSyuId in this.kamokuName) {
      ichiranData[tmpMondaiSyuId] = {};
      //単元別の、目次作成
      tmpMokujiData = mokujidataOnFileCopied.filter(data => {
        return (data.mondaiSyuId == Number(tmpMondaiSyuId) && data.tangoId);
      });
      //問題の数が多い順にソート
      tmpMokujiData.sort(function (a, b) {
        if (a.mokujiMondaiList.length > b.mokujiMondaiList.length) return -1;
        if (a.mokujiMondaiList.length < b.mokujiMondaiList.length) return 1;
        return 0;
      });

      for (let targetMokujidata in tmpMokujiData) {
        let mondaiIdList = [];
        ichiranData[tmpMondaiSyuId][tmpMokujiData[targetMokujidata].mokujiCategory] = {};
        for (let mondaiId in tmpMokujiData[targetMokujidata].mokujiMondaiList) {
          for (let nendo in this.nendoName) {
            if (this.getOneMondaiFromFile(tmpMokujiData[targetMokujidata].mokujiMondaiList[mondaiId]).nendo == Number(nendo)) {
              //単元ごと、年度ごとのデータに格納
              mondaiIdList.push(tmpMokujiData[targetMokujidata].mokujiMondaiList[mondaiId]);
            }
            ichiranData[tmpMondaiSyuId][tmpMokujiData[targetMokujidata].mokujiCategory][nendo] = mondaiIdList;
          }
        }
      }
    }
    return ichiranData;
  }
   
  generateIchirandata() {
    //問題の一覧表データを生成する @ 年度別
    let mokujidataOnFileCopied = JSON.parse(JSON.stringify(this.mokujidataOnFile));
    let ichiranData = {};
    let tmpMokujiData: MokujiData[] = [];

    for (var tmpMondaiSyuId in this.kamokuName) {
      ichiranData[tmpMondaiSyuId] = {};
      //単元別の、目次作成
      tmpMokujiData = mokujidataOnFileCopied.filter(data => {
        return (data.mondaiSyuId == Number(tmpMondaiSyuId) && data.tangoId);
      });
      //問題の数が多い順にソート
      tmpMokujiData.sort(function (a, b) {
        if (a.mokujiMondaiList.length > b.mokujiMondaiList.length) return -1;
        if (a.mokujiMondaiList.length < b.mokujiMondaiList.length) return 1;
        return 0;
      });

      // for (let targetMokujidata in tmpMokujiData) {
      //   ichiranData[tmpMondaiSyuId][tmpMokujiData[targetMokujidata].mokujiCategory] = [];
      //   for (let mondaiId in tmpMokujiData[targetMokujidata].mokujiMondaiList) {
      //     ichiranData[tmpMondaiSyuId][tmpMokujiData[targetMokujidata].mokujiCategory].push(this.getOneMondaiFromFile(tmpMokujiData[targetMokujidata].mokujiMondaiList[mondaiId]));
      //   }
      // }

      for (let targetMokujidata in tmpMokujiData) {
        tmpMokujiData[targetMokujidata].mondaiArray = [];
        for (let mondaiId in tmpMokujiData[targetMokujidata].mokujiMondaiList) {
          tmpMokujiData[targetMokujidata].mondaiArray.push(this.getOneMondaiFromFile(tmpMokujiData[targetMokujidata].mokujiMondaiList[mondaiId]));
        }

        // ichiranData[tmpMondaiSyuId][tmpMokujiData[targetMokujidata].mokujiCategory] = [];
        // for (let mondaiId in tmpMokujiData[targetMokujidata].mokujiMondaiList) {
        //   ichiranData[tmpMondaiSyuId][tmpMokujiData[targetMokujidata].mokujiCategory].push(this.getOneMondaiFromFile(tmpMokujiData[targetMokujidata].mokujiMondaiList[mondaiId]));
        // }
        //console.log("tmpMokujiData[targetMokujidata].mondaiArray_"+tmpMokujiData[targetMokujidata].mondaiArray)
      }
      ichiranData[tmpMondaiSyuId] = tmpMokujiData;
    }
    return ichiranData;
  }
  
        

  getMokujiDataList(kamokuId: number, categoryMode: string, level: number, tangoId?:string) {
    // console.log("cate_"+categoryMode+"_lev_"+level);
    let returnMokujiData: MokujiData[] = [];
    let tmpMokujiData: MokujiData[] = [];
    let mokujidataOnFileCopied = JSON.parse(JSON.stringify(this.mokujidataOnFile));

    let kamokuIdArray: Number[] = [];

    //単発のMokujiDataを返して、関数終了する場合の分岐
    if(tangoId){
      //tangoIdの引数が指定されていれば、該当のMokujiDataを返して関数終了
      tmpMokujiData = mokujidataOnFileCopied.filter(data => {
        return (data.tangoId == tangoId);
      });
      returnMokujiData = JSON.parse(JSON.stringify(tmpMokujiData));
      //console.log("tmpMokujiData_"+JSON.stringify(tmpMokujiData));
      return returnMokujiData;
    } else if(categoryMode == 'onlyFuseikai'){
      //不正解の問題を目次で指定した場合の処理
      let fuseikaiMokujiData:MokujiData={
        mokujiCategory: '誤答問題',
        mokujiMondaiList: this.getFuseikaiMondaiIdList(kamokuId),
        mondaiSyuId:kamokuId,
      }
      returnMokujiData[0] = fuseikaiMokujiData;
      return returnMokujiData;
    }

    if (kamokuId != 0) {
      kamokuIdArray = [Number(kamokuId)];
    } else {
      kamokuIdArray = [101, 102, 103, 104, 105, 106, 107, 201, 202, 203, 204];
    }
    //ここで、解説有りの絞り込みの場合は、解説ないのも含んでいるmokujiMondaiListに対してやっている処理を、解説があるもののみ格納したmokujiMondaiList_HavingKaisetuについて処理させるようにする。＃もくじをベースに問題を割り付けていく処理のところで、正規表現でFlipを正規表現で引っ掛けている
    
    for (var i_kamoku in kamokuIdArray) {

      tmpMokujiData = mokujidataOnFileCopied;
      
      //年の、目次作成
      tmpMokujiData = tmpMokujiData.filter(data => {
        return (data.mondaiSyuId == Number(kamokuIdArray[i_kamoku]) && !data.tangoId);
      });
      //年の番号順にソート
      tmpMokujiData.sort(function (a, b) {
        if (a.mokujiCategory > b.mokujiCategory) return -1;
        if (a.mokujiCategory < b.mokujiCategory) return 1;
        return 0;
      });

      returnMokujiData = returnMokujiData.concat(JSON.parse(JSON.stringify(tmpMokujiData)));


      //単元別の、目次作成
      tmpMokujiData = mokujidataOnFileCopied.filter(data => {
        return (data.mondaiSyuId == Number(kamokuIdArray[i_kamoku]) && data.tangoId);
      });
      //問題の数が多い順にソート
      tmpMokujiData.sort(function (a, b) {
        if (a.mokujiMondaiList.length > b.mokujiMondaiList.length) return -1;
        if (a.mokujiMondaiList.length < b.mokujiMondaiList.length) return 1;
        return 0;
      });

      returnMokujiData = returnMokujiData.concat(JSON.parse(JSON.stringify(tmpMokujiData)));



      // //レベルに応じたもくじの絞り込み　＠　もくじの項目削減
      // if (level == 0) {
      //   tmpMokujiData = tmpMokujiData.slice(0, 2);
      // }


      // if (level != 2) {
      //   //解説なしも表示する場合を除いて、解説有りの問題だけ載ったListでmondaiListを上書きする
      //   tmpMokujiData = mokujidataOnFileCopied.filter(data => {
      //     return (data.mokujiMondaiList_HavingKaisetu.length != 0);
      //   });
      //   tmpMokujiData = JSON.parse(JSON.stringify(tmpMokujiData));
      //   for (var i_mkj in tmpMokujiData) {
      //     tmpMokujiData[i_mkj].mokujiMondaiList = tmpMokujiData[i_mkj].mokujiMondaiList_HavingKaisetu ;
      //   }
      // } else {
      //   tmpMokujiData = mokujidataOnFileCopied
      // }

      // if (categoryMode == 'true') {
      //   //categoryModeの場合は、単語が設定されているもの（すなわちカテゴリーモードのもくじ）を取得
      //   tmpMokujiData = tmpMokujiData.filter(data => {
      //     return (data.mondaiSyuId == Number(kamokuIdArray[i_kamoku]) && data.tangoId);
      //   });

      //   // //難易度に応じた絞り込みは、機能をコメントアウトしておく
      //   // let mondaiSuuThreshold = 1;
      //   // if (level == 0) {
      //   //   mondaiSuuThreshold = 0.2;
      //   // } else if (level == 1) {
      //   //   mondaiSuuThreshold = 0.3;
      //   // } else if (level == 2) {
      //   //   mondaiSuuThreshold = 0.6;
      //   // }
      //   // //レベルに応じたもくじの絞り込み　＠　問題数を減らす
      //   // for (var key in tmpMokujiData) {
      //   //   tmpMokujiData[key].mokujiMondaiList = tmpMokujiData[key].mokujiMondaiList.slice(0, Math.ceil(tmpMokujiData[key].mokujiMondaiList.length * mondaiSuuThreshold));
      //   // }

      //   // //カテゴリ出題の場合は、問題の数が多い順にソート
      //   // tmpMokujiData.sort(function (a, b) {
      //   //   if (a.mokujiMondaiList.length > b.mokujiMondaiList.length) return -1;
      //   //   if (a.mokujiMondaiList.length < b.mokujiMondaiList.length) return 1;
      //   //   return 0;
      //   // });

      //   //レベルに応じたもくじの絞り込み　＠　もくじの項目削減
      //   if (level == 0) {
      //     tmpMokujiData = tmpMokujiData.slice(0, 2);
      //   }
      // } else if (categoryMode == 'false') {
      //   tmpMokujiData = tmpMokujiData.filter(data => {
      //     return (data.mondaiSyuId == Number(kamokuIdArray[i_kamoku]) && !data.tangoId);
      //   });
      //   // //年ごと出題の場合は、年の番号順にソート
      //   // tmpMokujiData.sort(function (a, b) {
      //   //   if (a.mokujiCategory > b.mokujiCategory) return -1;
      //   //   if (a.mokujiCategory < b.mokujiCategory) return 1;
      //   //   return 0;
      //   // });

      //   //レベルに応じたもくじの絞り込み　＠　もくじの項目削減
      //   if (level == 0) {
      //     tmpMokujiData = tmpMokujiData.slice(0, 2);
      //   }
      // }

      // if (returnMokujiData == []){
      //   returnMokujiData = JSON.parse(JSON.stringify(tmpMokujiData));
      // } else {
      //   returnMokujiData = returnMokujiData.concat(tmpMokujiData);
      // }
    }
    
    // if (categoryMode == 'true') {
    //   //カテゴリ出題の場合は、問題の数が多い順にソート
    //   returnMokujiData.sort(function (a, b) {
    //     if (a.mokujiMondaiList.length > b.mokujiMondaiList.length) return -1;
    //     if (a.mokujiMondaiList.length < b.mokujiMondaiList.length) return 1;
    //     return 0;
    //   });
    //   return returnMokujiData;
    // } else if (categoryMode == 'false') {
    //   //年ごと出題の場合は、年の番号順にソート
    //   returnMokujiData.sort(function (a, b) {
    //     if (a.mokujiCategory > b.mokujiCategory) return -1;
    //     if (a.mokujiCategory < b.mokujiCategory) return 1;
    //     return 0;
    //   });
    // }

    return returnMokujiData;
  }


  createFlipOpenedDic(targeKamokuId: number, targetMondaiId: number) {
    //Flipのタグを検索して、リストにして返す。
    let returnDic = {};
    //問題文から<flip>・・</flip>の文字列をDicにして取り出す
    let matchedArray: string[] = this.getOneMondaiFromFile(targetMondaiId).mondaiBun.match(/<flip>[\s\S]*?<\/flip>/g);

    //console.log("matchedArray_"+matchedArray)

    if (matchedArray != []) {
      //
      for (var key in matchedArray) {
        //
        returnDic[matchedArray[key]] = false;
      }

    }
    // returnDic["<flip>プライベートIPアドレスの割当などのネットワーク接続情報を応答するプロトコルです。この記述はNATに関する記述なので、不適切</flip>"] = false;

    // returnDic["<flip>アクセスでいい</flip>"] = false;
    return returnDic;
  }

  loadLastKaitouHistory(targteKamokuId: number, targetMondaiId: number, forceNewKaitou?: boolean) {
    //最新の解答の更新が、1234日すぎるとか極端に古くなければメモリ上にKaitouに読み込む。そうでなければ新しいKaitouのインスタンス作成
    let tmpDate = new Date();
    tmpDate.setTime(tmpDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
    this.todayDate = tmpDate;
    const dateNow = this.todayDate;
    const kaitouData: Kaitou = {
      mondaiId: targetMondaiId,
      dateTime: dateNow,
      myName: this.currentGuiStatus.myName,
      excludedSentakushi: [],
      flipOpenedDic: this.createFlipOpenedDic(targteKamokuId, targetMondaiId),
      flipOpenedBeforeSelect: false,
      memo: '',
      kaihouVisibleStatus: '',
      hint_visible: false,
    };

    if (forceNewKaitou == true) {
      if (this.kaitouHistory[targetMondaiId] == null) {
        //その問題の初回解答時は配列作って追加
        let kaitouArray: Kaitou[] = [];
        kaitouArray.push(JSON.parse(JSON.stringify(kaitouData)));
        this.kaitouHistory[targetMondaiId] = kaitouArray;
      }
      //新しい解答を強制する場合（もう一度解くボタン押下時）は、解答履歴作成
      this.kaitouHistory[targetMondaiId].push(JSON.parse(JSON.stringify(kaitouData)));
    } else if (this.kaitouHistory) {
      if (this.kaitouHistory[targetMondaiId] != null) {
        let tmpKaitou: Kaitou = this.kaitouHistory[targetMondaiId][this.kaitouHistory[targetMondaiId].length - 1];

        const dateKaitou: Date = new Date(tmpKaitou.dateTime);
        const timeDelata: number = dateNow.getTime() - dateKaitou.getTime();
        if (timeDelata / 60 / 60 / 1000 > 24 * 1234 && tmpKaitou.sentaku) {
          //最新の解答の更新日時が、1234日間以上　かつ　採点済みの場合
          //新規の解答履歴作成し、解答履歴の末尾に追加  ＃ そうでない場合は後続処理で、そのまま最新の回答履歴をメモリに読み込まれる
          this.kaitouHistory[targetMondaiId].push(JSON.parse(JSON.stringify(kaitouData)));
        }

      } else {
        //その問題の初回解答時は配列作って追加
        let kaitouArray: Kaitou[] = [];
        kaitouArray.push(JSON.parse(JSON.stringify(kaitouData)));
        this.kaitouHistory[targetMondaiId] = kaitouArray;
      }


    } else {
      //全問題通じて初回の解答の場合、配列初期化
      this.kaitouHistory = {};
      this.kaitouHistory[targetMondaiId] = [JSON.parse(JSON.stringify(kaitouData))];

    }

    //項目追加した場合に項目不足でエラーにならないよう、デフォルト値を追加するための処理　＠　Flipのリストは、DBよりも最新の値を優先
    let newFlipOpenedDic = this.createFlipOpenedDic(targteKamokuId, targetMondaiId);
    Object.assign(
      newFlipOpenedDic,
      this.kaitouHistory[targetMondaiId][this.kaitouHistory[targetMondaiId].length - 1].flipOpenedDic
    );
    this.kaitouHistory[targetMondaiId][this.kaitouHistory[targetMondaiId].length - 1].flipOpenedDic = newFlipOpenedDic;

    Object.assign(//項目追加した場合に項目不足でエラーにならないよう、デフォルト値を追加するための処理　＠　DBの値が存在する場合は既存の値で上書き
      kaitouData,
      this.kaitouHistory[targetMondaiId][this.kaitouHistory[targetMondaiId].length - 1]
    );
    //最新の解答履歴を、カレントの解答履歴に読み込み
    this.currentGuiStatus.currentKaitou[targetMondaiId] = kaitouData;
    //console.log("JSON.stringify(this.currentGuiStatus.currentKaitou[targetMondaiId])_"+JSON.stringify(this.currentGuiStatus.currentKaitou[targetMondaiId]));

    this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);
    this.backUpObjectToDB('kaitouHistory', this.kaitouHistory);
  }

  getLastKaitouHistory_HavingSaitenKekka(targetMondaiId: number): Kaitou {
    let kaitouHistoryHavingSaitenKekka: Kaitou[];
    if (this.kaitouHistory[targetMondaiId]) {
      //解答履歴のうち、採点済みのもので最新のものを取得する
      kaitouHistoryHavingSaitenKekka = this.kaitouHistory[targetMondaiId].filter(data => {
        return (data.saitenKekka != null);
      });
      let kaitouToReturn: Kaitou = kaitouHistoryHavingSaitenKekka[kaitouHistoryHavingSaitenKekka.length - 1];

      return kaitouToReturn;
    } else {
      //解答が存在しなければnull返して終わり
      return null;
    }
  }

  getLastKaitouHistory(targetMondaiId: number): Kaitou {
    //採点結果がなくとも、履歴があるものを返す
    let kaitouHistoryHavingSaitenKekka: Kaitou[];
    if (this.kaitouHistory[targetMondaiId]) {
      //解答履歴のうち、採点済みのもので最新のものを取得する
      kaitouHistoryHavingSaitenKekka = this.kaitouHistory[targetMondaiId];
      // .filter(data => {
      //   return (data.saitenKekka != null);
      // });
      let kaitouToReturn: Kaitou = kaitouHistoryHavingSaitenKekka[kaitouHistoryHavingSaitenKekka.length - 1];

      return kaitouToReturn;
    } else {
      //解答が存在しなければnull返して終わり
      return null;
    }
  }

  getSaitenKekkaForHyouji(targetKaitou: Kaitou): string {
    //採点結果の表示を、ヒント表示の場合も考慮して返す関数。true,false,sankaku,flipOpenedBeforeSelectのいずれか。
    let result: string = '' + targetKaitou.saitenKekka;
    if (targetKaitou.flipOpenedBeforeSelect) {
      result = 'flipOpenedBeforeSelect';
    } else if (result == 'false') {
      if (targetKaitou.excludedSentakushi.length != 0 && targetKaitou.sentaku != 'exclude_failure') {
        //除外の選択肢がカラでなく、かつ、排他の誤りでなければ
        result = 'sankaku';
        // console.log("targetKaitou.mondaiId_"+targetKaitou.mondaiId)
        // console.log("targetKaitou.excludedSentakushi_"+targetKaitou.excludedSentakushi)
        // console.log("targetKaitou.sentaku_"+targetKaitou.sentaku)
      }
    }
    return result;
  }

  getSaitenKekkaIconHtmlTag(targetKaitou: Kaitou, mode: string): string {
    let rslt = this.getSaitenKekkaForHyouji(targetKaitou);
    let htmlTagStr: string = '';

    let classNameDic =
    {
      'mokuji': {
        // 'flipOpenedBeforeSelect': 
        //   `<span class="fa-layers fa-fw">
        //     <i class="fas fa-dot-circle" data-fa-transform="grow-4" style="color: rgba(255, 255, 255, 1.0);"></i>
        //     <i class="far fa-life-ring" data-fa-transform="grow-4 rotate-45" style="color: rgba(255, 0, 0, 1.0);"></i>
        //     <i class="far fa-circle"  data-fa-transform="grow-4" style="color: rgba(0, 0, 0, 1.0);"></i>
        //     <i class="fas fa-circle"  data-fa-transform="shrink-8" style="color: rgba(0, 0, 0, 1.0);"></i>
        //   </span>`,
        'flipOpenedBeforeSelect': 
          // `<span class="fa-layers fa-fw">
          //   <i class="far fa-life-ring" data-fa-transform="grow-4 rotate-45" style="color: rgba(255, 255, 255, 1.0);"></i>
          //   <i class="far fa-life-ring" data-fa-transform="grow-4 rotate-0" style="color: rgba(255, 100, 100, 1.0);"></i>
            
          // </span>`,
          `<span class="fa-layers fa-fw">
            <i class="fas fa-lightbulb fa-fw" data-fa-transform="grow-4 rotate-0" style="color: rgba(255, 100, 100, 1.0);"></i>
            
          </span>`,
        'true': '<i class="far fa-circle fa-fw" data-fa-transform="grow-1" style="color: rgba(50, 200, 50, 1.0);"></i>',
        'false': '<i class="fas fa-times fa-fw" data-fa-transform="grow-1" style="color: rgba(255, 100, 100, 1.0);"></i>',
        'sankaku':
          `<span class="fa-layers fa-fw">
             <i class="fas fa-chevron-up" data-fa-transform="down-1 grow-8"></i>
             <i class="far fa-window-minimize" data-fa-transform="up-2 grow-5"></i>
          </span>`,

      },
      'badge': {
        // 'flipOpenedBeforeSelect': 
        //   `<span class="fa-layers fa-fw">
        //     <i class="fas fa-dot-circle"  data-fa-transform="grow-4" style="color: rgba(255, 255, 255, 1.0);"></i>
        //     <i class="fas fa-life-ring"  data-fa-transform="grow-4 rotate-45" style="color: rgba(255, 0, 0, 1.0);"></i>
        //     <i class="far fa-circle"  data-fa-transform="grow-6" style="color: rgba(244, 244, 244, 1.0);"></i>
        //     <i class="fas fa-circle"  data-fa-transform="shrink-7" style="color: rgba(244, 244, 244, 1.0);"></i>
        //   </span>`,
        'flipOpenedBeforeSelect': 
          // `<span class="fa-layers fa-fw">
          //   <i class="fas fa-life-ring"  data-fa-transform="grow-4 rotate-45" style="color: rgba(255, 255, 255, 1.0);"></i>
          //   <i class="fas fa-life-ring"  data-fa-transform="grow-4 rotate-0" style="color: rgba(255, 100, 100, 1.0);"></i>
          // </span>`,
          `<i class="fas fa-lightbulb fa-fw"  data-fa-transform="grow-4 rotate-0" style="color: rgba(255, 100, 100, 1.0);"></i>
          </span>`,
        'true': '<i class="fas fa-dot-circle fa-fw" data-fa-transform="grow-1" style="color: rgba(50, 200, 50, 1.0);"></i>',
        'false': '<i class="fas fa-times fa-fw" data-fa-transform="grow-1" style="color: rgba(255, 100, 100, 1.0);"></i>',
        'sankaku':
          `<span class="fa-layers fa-fw">
             <i class="fas fa-chevron-up" data-fa-transform="down-1 grow-8"></i>
             <i class="far fa-window-minimize" data-fa-transform="up-2 grow-5"></i>
          </span>`,
      },
    }
    htmlTagStr = classNameDic[mode][rslt];
    // if (rslt == 'flipOpenedBeforeSelect'){
    //   htmlTagStr = '<i class="fas fa-lock-open fa-fw" data-fa-transform="grow-1"></i>'
    // } else if (rslt == 'false'){
    //   htmlTagStr = '<i class="fas fa-times" data-fa-transform="grow-1"></i>'
    // } else if (rslt == 'true'){
    //   htmlTagStr = '<i class="far fa-circle fa-fw" data-fa-transform="grow-1"></i>'
    // } else if (rslt == 'sankaku'){
    //       htmlTagStr = `<span class="fa-layers fa-fw">
    //       <i class="fas fa-chevron-up" data-fa-transform="down-1 grow-8 right-4"></i>
    //       <i class="far fa-window-minimize" data-fa-transform="up-2 grow-5 right-4"></i>
    //     </span>
    //   `//'<i class="fas fa-play" data-fa-transform="rotate-30 grow-1"></i>'
    // }
    return htmlTagStr;
  }

  getSentakuStatusIconClassName(sentakushiItem: string, kaitouItem: Kaitou) {
    //
    let className = '';
    // let returnDic = {
    //   'classNameStr':'',
    //   'iconStyle':'color:white',
    //   'textStyle':'color:black;font-weight:900;',
    // }
    //let classNameStr:string = 'fas fa-square-full';
    if (kaitouItem.excludedSentakushi.indexOf(sentakushiItem) !== -1) {
      //消去法に含まれていたらゴミ箱アイコン
      className = 'fas fa-trash';
      // returnDic = {
      //   'classNameStr':'fas fa-trash',
      //   'iconStyle':'color:white',
      //   'textStyle':'color:black;font-weight:900;',
      // }
    } else if (sentakushiItem == kaitouItem.sentaku) {
      className = 'fas fa-heart';
      // returnDic = {
      //   'classNameStr':'fas fa-heart',
      //   'iconStyle':'color:red',
      //   'textStyle':'color:black;font-weight:900;',
      // }
    }
    return className;
  }

  getMondaiBunPreview(targetString: string): string {
    //もくじで表示する問題文のプレビュのHTML文字列を返すメソッド
    let resultString: string = targetString;
    console.log("resultString_" + resultString);
    //hintタグをSTRIP
    resultString = resultString.replace(/<hint hidden='true'>/g, '');
    //marked変換
    marked.setOptions({
      sanitize: false,
      breaks: false,//true,//Trueでスペース無しで開業するが、HTMLタグ書くときに無駄な改行が入ってしまうので
    });
    resultString = marked(resultString);
    console.log("after_resultString_" + resultString);
    return resultString;
  }

  replaceImgUrl(){
    //アプリの場合は、（かブラウザでない場合）は、URLを書き換える
    // https://zu-de.com/assets/img/101001.png
    // assets/img/101001.png
  }

  mutateImgTag(mode:string,mondaiBun: string){
    //解くCardでは、別窓で開くようリンクにする。もくじの場合は普通に表示
    //let resultString:string = mondaiBun.replace(/<imgTag>[\s\S]*?<\/imgTag>/g, `$&`);
    let resultString:string = mondaiBun;
    const imgTagArray: string[] = mondaiBun.match(/<imgTag>[\s\S]*?<\/imgTag>/g);
    for (let i_its in imgTagArray) {
      if (Object.prototype.hasOwnProperty.call(imgTagArray, i_its)) {
        let replacingString: string = '';
        let pngFileName: string = imgTagArray[i_its]
          .replace(/<imgTag>/, '')
          .replace(/<\/imgTag>/, '');

        //解法の表示状態の文字列取得 ＃選択なし奈良カラ文字
        let tmp_kaihouVisibleStatus_str = '';
        if (
          this.currentGuiStatus.currentKaitou[
            this.currentGuiStatus.currentMondai[
              this.currentGuiStatus.currentTab
            ].mondaiId
          ].kaihouVisibleStatus != '' &&
          this.currentGuiStatus.currentKaitou[
            this.currentGuiStatus.currentMondai[
              this.currentGuiStatus.currentTab
            ].mondaiId
          ].kaihouVisibleStatus != null
        ) {
          tmp_kaihouVisibleStatus_str =
            '' +
            this.currentGuiStatus.currentKaitou[
              this.currentGuiStatus.currentMondai[
                this.currentGuiStatus.currentTab
              ].mondaiId
            ].kaihouVisibleStatus;
        }

        if (
          mode == 'toku' &&
          tmp_kaihouVisibleStatus_str.indexOf('kaihou') === 0
        ) {
          //解法が選択されていれば、kaihou1,2,3,,,のフォルダ参照
          replacingString =
            `<a href="assets/img/` +
            mode +
            `/` +
            tmp_kaihouVisibleStatus_str +
            '/' +
            pngFileName +
            `" target="_blank"><img src="assets/img/` +
            mode +
            `/` +
            tmp_kaihouVisibleStatus_str +
            `/` +
            pngFileName +
            `" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></a>`;

          // //環境差分リスクと、ファイル容量差分で判断難しいが、当面はWEBユーザ中心と考えて、まずは安全動作優先でコメントアウトする
          // if (fs.existsSync(`assets/img/` +mode+ `/`+tmp_kaihouVisibleStatus_str+'/'+pngFileName)) {
          //   // File exists in path
          // } else {
          //   // ファイルが存在しなければ、TOKUかkaisetuフォルダ参照
          //   replacingString =  `<a href="assets/img/`  +mode+ `/`+pngFileName+`" target="_blank"><img src="assets/img/`+'theory'+`/`+pngFileName+`" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></a>`;
          // }

          // var file = new File( [ new Blob() ], `assets/img/` +mode+ `/`+tmp_kaihouVisibleStatus_str+'/'+pngFileName, {
          //   type: "image/png" ,
          // } ) ;
          //   const reader = new FileReader();
          //   reader.readAsText(file);
          //   return new Promise((resolve, reject) => {
          //     reader.onload = () => {
          //       resolve(reader.result);
          //     };
          //     reader.onerror = () => {
          //       reject(reader.error);
          //     };
          //   });
        } else if (mode == 'mokuji') {
          //主にもくじの場合は、リンクなし、解説画像差し替えもなし
          replacingString =
            `<img src="assets/img/toku/` +
            pngFileName +
            `" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;">`;
        } else {
          //上記以外は、引数通りのフォルダ(tokuかkaisetu)参照
          replacingString =
            `<a href="assets/img/` +
            mode +
            `/` +
            pngFileName +
            `" target="_blank"><img src="assets/img/` +
            mode +
            `/` +
            pngFileName +
            `" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></a>`;
        }

        // if(mode == 'toku'){
        //   //解くスライドの場合は、ヒントの選択しだいで変わる。リンク文字と、リンク先URLは解説なしのフォルダにする。

        //   else if (this.currentGuiStatus.currentKaitou[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId].kaihouVisibleStatus == 'hint' ){
        //     replacingString =  `<a href="assets/img/` +'hint'+ `/`+pngFileName+`" target="_blank"><img src="assets/img/`+'hint'+`/`+pngFileName+`" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></a>`;
        //   }
        // } else if (mode == 'kaisetu'){
        //   //解くスライドの場合は、リンク文字と、リンク先URLは解説なしのフォルダにする。
        //   replacingString =  `<a href="assets/img/` +mode+ `/`+pngFileName+`" target="_blank"><img src="assets/img/`+mode+`/`+pngFileName+`" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></a>`;

        resultString = resultString.replace(
          imgTagArray[i_its],
          replacingString
        );

        //console.log("imgTagArray[i_its]_"+imgTagArray[i_its]);
      }
    }

    return resultString;
  }

  replaceFlipTag(mondaiId: number, mondaiBun: string): string {
    let replacedString: string = mondaiBun;
    let replacingToString: string = '';
    let openedFlipStyleStr = "color: #222222;background:#ffffbb;)";//;background:linear-gradient( rgba(255,255,255,0) 25%, #ffff88 75%, rgba(255,255,255,0) 0%);";//flipを開いたあとの文字表示STYLE

    if (mondaiId == 0 || !this.currentGuiStatus.currentKaitou[mondaiId]) {
      //タグテスト用。問題IDが０の場合は、変換文字列だけ返して処理終了
      replacingToString = "<flip style='"+openedFlipStyleStr+"'>";

      replacedString = replacedString.replace(/<flip>/g, replacingToString)

      return replacedString;
    } else {
    //open済みのflip以外は、maskする文字列に置換する
    for (var keyOfFlip in this.currentGuiStatus.currentKaitou[mondaiId].flipOpenedDic) {
        replacingToString = '';
        if (this.currentGuiStatus.currentKaitou[mondaiId].flipOpenedDic[keyOfFlip] != true) {
          //OpenedのDicに載っている（明らかに開いたFlip）を除き、マスクする文字列に置換する
          //複数問題で同じFlip文字列がある場合に、タップでそれらすべて開いてしまう問題の対策のため、Flipタグ間の文字列に、問題IDを追記した文字列を、開いたフリップの辞書のキー文字列にしている。
          replacingToString = `<BR><label for='`+ mondaiId + `$&' style='background: #ffffbb;'><i class='fas fa-question' style="color:#000000;width:100%"></i></label>`;

          // if ( this.tutorial_mode
          //   // && this.tutorial_state != 'firstFlipOpened'
          //   ){
          //   //tutorial_mode、かつ、開いたフリップ数が０　の場合は　指のアイコン表示
          //   replacingToString = replacingToString + `<i class="far fa-hand-point-left faa-horizontal animated" style="color:#cccccc"
          //   data-fa-transform="shink-3"></i>`
  
          // }
          
        } else {
          //開いたFlipの場合。
          replacingToString = " <span style='"+openedFlipStyleStr+"'>$&</span>";
          this.tutorial_state = 'firstFlipOpened';//チュートリアルを初回FlipOpen時にする
        }
        replacedString = replacedString.replace(keyOfFlip, replacingToString)
      }
    }
    //ここでログ書くとログ大量でクラッシュするthis.writeOperationLog(this.currentGuiStatus.currentTab , mondaiId ,'replaceFlipTag', this.currentGuiStatus.privacy_policy_accepted );
    return replacedString;
  }

  // kaihouSelected(event) {
  //   //IMG表示でなく、特定タグを表示に変換でもいいかも。画像差し替えはできないか。二次は画像差し替え、１時は文字非表示解除の、両方できるといいのかなあ。
  //   // console.log(event.detail.value);
  //   // console.log(JSON.stringify(event));
  //   this.currentGuiStatus.currentKaitou[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId].kaihouVisibleStatus = event.detail.value;
  // }

  showKaihou(targetString: string): string {
    //解法のタグを表示させる
    let resultString: string = targetString;

    let tmp_kaihouVisibleStatus_str = '';
    if (this.currentGuiStatus.currentKaitou[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId].kaihouVisibleStatus != '' && this.currentGuiStatus.currentKaitou[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId].kaihouVisibleStatus != null) {
      tmp_kaihouVisibleStatus_str = "" + this.currentGuiStatus.currentKaitou[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId].kaihouVisibleStatus;
    }

    if (tmp_kaihouVisibleStatus_str != "" && tmp_kaihouVisibleStatus_str.indexOf("kaihou") === 0) {
      let kaihou_tagName_space = new RegExp("<"+tmp_kaihouVisibleStatus_str+" hidden='true'>[\\s\\S]*?<\/"+tmp_kaihouVisibleStatus_str+">","g");//"/<"+tmp_kaihouVisibleStatus_str+" hidden='true'>[\s\S]*?<\/hint>/g";
      
      let kaihou_tagName_hidden = new RegExp("<"+tmp_kaihouVisibleStatus_str+" hidden='true'>","g");

      let kaihou_target_tagName =  new RegExp("<"+tmp_kaihouVisibleStatus_str+"Target>","g");//"/<"+tmp_kaihouVisibleStatus_str+"Target>/g";
      

      //ヒントを表示させる
      resultString = resultString.replace(kaihou_tagName_space, `&nbsp;&nbsp;$&`);
      
      resultString = resultString.replace(kaihou_tagName_hidden, "<"+tmp_kaihouVisibleStatus_str+" style='color:#18df50;font-size:100%;background-color: #ffffff;'>");
      
      //置換　＠　ヒント対象文字の強調の背景色変更
      resultString = resultString.replace(kaihou_target_tagName, "<" +tmp_kaihouVisibleStatus_str+"Target style='background:linear-gradient( rgba(255,255,255,1) 5%, rgba(16,220,96,0.5)  40%, rgba(16,220,96,0.5) 65%, rgba(255,255,255,1)  99%);'>");
    }
    //console.log("showKaihou--",resultString)
    return resultString;
  }

  showHint(targetString: string): string {
    //文字列置換。蛍光ペンの色を付けて、Hiddenの値をFalseに。
    //hintTarget を背景指定の文字列に変える
    let resultString: string = targetString;
    
    //置換　＠　ヒントの可視化
    //ヒントの前後に半角スペースを入れる（スペースないと何故かTableが非表示にならないため）
    resultString = resultString.replace(/<hint hidden='true'>[\s\S]*?<\/hint>/g, `&nbsp;&nbsp;$&`);

    //ヒントを表示させる
    resultString = resultString.replace(/<hint hidden='true'>/g, `
    <hint style='color:#3880ff;font-size:100%;background-color: white;'>
    `);

    // //ヒント表示時に、非表示にする＃主にヒントなしの画像を非表示にする用途。ヒントありのものに差し替えるために。
    // resultString = resultString.replace(/<noHintImg>[\s\S]*?<\/noHintImg>/g, ``);

    //置換　＠　ヒント対象文字の強調の背景色変更
    resultString = resultString.replace(/<hintTarget>/g, "<hintTarget style='background:linear-gradient( rgba(255,255,255,1) 25%, rgba(255,255,255,1)  70%, #ffbbdd 85%, rgba(255,255,255,1)  99%);'>");

    
    return resultString;
  }

  reloadAshiatoData() {
    this.kaitouHistory = this.getAllRecord('kaitouHistory');
    //kaithouHistoryを参照渡ししないため、不変型（メモリのアドレスを参照渡ししない）Stringに一旦変換してからコピーを生成
    let kaitouHistoryCopiedStr: string = JSON.stringify(this.kaitouHistory);
    let kaitouHistoryCopied: GakusyuRireki[] = JSON.parse(kaitouHistoryCopiedStr); // JSON文字列化したものを戻す 

    this.kaitouMondaiJoin = this.reloadKaitouMondaiJoin(kaitouHistoryCopied);

    //あしあとのデータ更新　＠　表
    //this.rirekiDataSource = new MatTableDataSource(this.kaitouMondaiJoin);
    //this.rirekiDataSource.paginator = this.paginator;//ページ送り設定の更新

    //あしあとのデータ更新　＠　グラフ
    this.getAshiatoChartData(this.kaitouMondaiJoin, kaitouHistoryCopied);

    //現在のGUIの状態を保存
    this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);
    //console.log('コンストラクタ開始からreloadAshiatoData終了までの秒数_' + (new Date().getTime() - this.dateForSeinou) / 1000);

    // this.delay(4000).then(any => {
    //   //解答後に更新されない事があるので（やや冗長だが念の為）ここでも更新させる
    //   this.updateKaitouCounts();
    // });
  }

  updateCount_kaitou_and_timer() {
    //解答数・Timerともに、Today分とALL分を格納する変数を更新する関数
    //kaitouは、この関数で一気に更新
    this.updateTodayKaitouCount();

    //全科目の合計値はここで計算前に一旦0クリア
    // this.currentGuiStatus.kaitouCountToday[0]['true'] = 0;
    // this.currentGuiStatus.kaitouCountToday[0]['false'] = 0;
    this.currentGuiStatus.timerCountToday[0] = 0;
    //this.currentGuiStatus.kaitouCountToday[0] = 0;

    // this.currentGuiStatus.kaitouCountAll[0]['true'] = 0;
    // this.currentGuiStatus.kaitouCountAll[0]['false'] = 0;
    this.currentGuiStatus.timerCountAll[0] = 0;
    this.currentGuiStatus.kaitouCountAll[0] = 0;

    for (var kamokuMei in this.kamokuName) {
      // //KaitouCountは、最新から、前日分を引き算
      // this.currentGuiStatus.kaitouCountToday[Number(kamokuMei)][true] =
      //   this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou-true', 0) -
      //   this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou-true', 2);

      // this.currentGuiStatus.kaitouCountToday[Number(kamokuMei)][false] =
      //   this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou-false', 0) -
      //   this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou-false', 2);

      //解答の合計は、最新のみ取得
      this.currentGuiStatus.kaitouCountAll[Number(kamokuMei)] = this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou', 0)

      // this.currentGuiStatus.kaitouCountAll[Number(kamokuMei)][false] = this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou-false', 0)

      //timerの合計は、最新のみ取得
      this.currentGuiStatus.timerCountAll[Number(kamokuMei)] = this.getCount_kaitou_and_timer(Number(kamokuMei), 'timer', 0);

      //this.currentGuiStatus.kaitouCountToday[kamokuMei] = this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou', 0) - this.getCount_kaitou_and_timer(Number(kamokuMei), 'kaitou', 2);//最終日の統計から、最終日前日の記録を引く
      // this.currentGuiStatus.kaitouCountAll[kamokuMei] = this.getCount_kaitou_and_timer(Number(kamokuMei), 0, 'kaitou');

      // this.currentGuiStatus.timerCountAll[kamokuMei] = this.getCount_kaitou_and_timer(Number(kamokuMei), 0, 'timer');

      // //全科目の、Todayの、合計値の計算
      // this.currentGuiStatus.kaitouCountToday[0]['true'] = this.currentGuiStatus.kaitouCountToday[0]['true'] + this.currentGuiStatus.kaitouCountToday[Number(kamokuMei)][true];
      // this.currentGuiStatus.kaitouCountToday[0]['false'] = this.currentGuiStatus.kaitouCountToday[0]['false'] + this.currentGuiStatus.kaitouCountToday[Number(kamokuMei)][false];

      this.currentGuiStatus.timerCountToday[0] = this.currentGuiStatus.timerCountToday[0] + this.currentGuiStatus.timerCountToday[Number(kamokuMei)];
      // tmp_kaitouZenKamokuCountToday_true = tmp_kaitouZenKamokuCountToday_true + this.currentGuiStatus.kaitouCountToday[Number(kamokuMei)][true];
      // tmp_kaitouZenKamokuCountToday_false = tmp_kaitouZenKamokuCountToday_false + this.currentGuiStatus.kaitouCountToday[Number(kamokuMei)][false];
      // tmp_timerZenKamokuCountToday = tmp_timerZenKamokuCountToday + this.currentGuiStatus.timerCountToday[kamokuMei];
      
      //全科目の、All期間の、合計値の計算
      this.currentGuiStatus.kaitouCountAll[0] = this.currentGuiStatus.kaitouCountAll[0] + this.currentGuiStatus.kaitouCountAll[Number(kamokuMei)];
      
      //this.currentGuiStatus.kaitouCountToday[0] = this.currentGuiStatus.kaitouCountToday[0] + this.currentGuiStatus.kaitouCountToday[kamokuMei];

      this.currentGuiStatus.timerCountAll[0] = this.currentGuiStatus.timerCountAll[0] + this.currentGuiStatus.timerCountAll[Number(kamokuMei)];

    }

    //総学習時間は、四捨五入しておく
    this.currentGuiStatus.timerCountAll[0] = Math.round(this.currentGuiStatus.timerCountAll[0] * 10) / 10;
    //全科目、Todayの、合計値の反映
    // this.currentGuiStatus.kaitouCountToday[0]['true'] = tmp_kaitouZenKamokuCountToday_true;
    // this.currentGuiStatus.kaitouCountToday[0]['false'] = tmp_kaitouZenKamokuCountToday_false;
    // this.currentGuiStatus.timerCountToday[0] = tmp_timerZenKamokuCountToday;

    // console.log("kaitouCountToday_" + JSON.stringify(this.currentGuiStatus.kaitouCountToday))
    // console.log("kaitouCountAll_" + JSON.stringify(this.currentGuiStatus.kaitouCountAll))

    //console.log("this.getCount_kaitou_and_timer(0, 'kaitou', 7)_" + JSON.stringify(this.getCount_kaitou_and_timer(0, 'kaitou', 7)))
    //console.log("this.dateData_" + JSON.stringify(this.dateData))
    //console.log("this.dateData_timer_" + JSON.stringify(this.dateData_timer))
    // console.log("timerCountAll_" + JSON.stringify(this.currentGuiStatus.timerCountAll))

  }

  reloadKaitouMondaiJoin(kaitouHistory) {
    let gakusyuRireki: GakusyuRireki[] = [];//解答と問題をJOINしたデータ
    for (var mondaiId in kaitouHistory) {
      let tmpMondai: Mondai = this.getOneMondaiFromFile(Number(mondaiId));
      let tmpKaitous: Kaitou[] = kaitouHistory[Number(mondaiId)];
      for (let tk in tmpKaitous) {
        let tmpGakusyuRireki: GakusyuRireki;

        tmpGakusyuRireki = tmpKaitous[tk]; //
        for (var key in tmpMondai) {
          tmpGakusyuRireki[key] = tmpMondai[key];
        }

        gakusyuRireki.push(tmpGakusyuRireki);
      }
    }

    //日付順にソート
    gakusyuRireki.sort(function (a, b) {
      if (a.dateTime > b.dateTime) return -1;
      if (a.dateTime < b.dateTime) return 1;
      return 0;
    });

    //GUIのSELECT内容に応じて、各種フィルタリング
    //科目フィルタ
    if (this.currentGuiStatus.ashiatoHyoujiKamoku != 0 && this.currentGuiStatus.ashiatoHyoujiKamoku != null) {
      //フィルタに一致するレコードのみ取得
      gakusyuRireki = gakusyuRireki.filter(data => {
        return (data.mondaiSyuId == this.currentGuiStatus.ashiatoHyoujiKamoku);
      });
    }
    //年度フィルタ
    if (this.currentGuiStatus.ashiatoHyoujiNendo != 0 && this.currentGuiStatus.ashiatoHyoujiNendo != null) {
      //フィルタに一致するレコードのみ取得
      gakusyuRireki = gakusyuRireki.filter(data => {
        return (data.nendo == this.currentGuiStatus.ashiatoHyoujiNendo);
      });
    }
    //キーワードフィルタ
    if (this.currentGuiStatus.ashiatoHyoujiKeyword != "すべて表示" &&
      this.currentGuiStatus.ashiatoHyoujiKeyword != null &&
      this.currentGuiStatus.ashiatoHyoujiKeyword != "") {
      //フィルタに一致するレコードのみ取得
      gakusyuRireki = gakusyuRireki.filter(data => {
        return (data.keyword.indexOf(this.currentGuiStatus.ashiatoHyoujiKeyword) >= 0);
      });
    }

    return gakusyuRireki;
  }

  getAshiatoChartData(gakusyuRireki: GakusyuRireki[], kaitouHistory) { //あしあとのグラフデータ作成
    // if (gakusyuRireki.length == 0) {
    //   //検索にヒットしない場合は、alert出して処理終了
    //   //console.log('no_data')
    //   alert('該当する解答履歴がありません');
    //   return;
    // }

    var startDate = new Date(
      gakusyuRireki[gakusyuRireki.length - 1]["dateTime"]
    );
    startDate.setTime(startDate.getTime() - 1000 * 60 * 60 * 9);// JSTに変換
    this.dateData =
      [{ "name": "経済○", "series": [{ "value": 0, "name": "" }] }, { "name": "経済×", "series": [{ "value": 0, "name": "" }] }, { "name": "財務○", "series": [{ "value": 0, "name": "" }] }, { "name": "財務×", "series": [{ "value": 0, "name": "" }] }, { "name": "経営○", "series": [{ "value": 0, "name": "" }] }, { "name": "経営×", "series": [{ "value": 0, "name": "" }] }, { "name": "運営○", "series": [{ "value": 0, "name": "" }] }, { "name": "運営×", "series": [{ "value": 0, "name": "" }] }, { "name": "法務○", "series": [{ "value": 0, "name": "" }] }, { "name": "法務×", "series": [{ "value": 0, "name": "" }] }, { "name": "情報○", "series": [{ "value": 0, "name": "" }] }, { "name": "情報×", "series": [{ "value": 0, "name": "" }] }, { "name": "中小○", "series": [{ "value": 0, "name": "" }] }, { "name": "中小×", "series": [{ "value": 0, "name": "" }] }];

      this.dateData_timer =
      [{ "name": "経済", "series": [{ "value": 0, "name": "" }] }, { "name": "財務", "series": [{ "value": 0, "name": "" }] }, { "name": "経営", "series": [{ "value": 0, "name": "" }] }, { "name": "運営", "series": [{ "value": 0, "name": "" }] }, { "name": "法務", "series": [{ "value": 0, "name": "" }] }, { "name": "情報", "series": [{ "value": 0, "name": "" }] }, { "name": "中小", "series": [{ "value": 0, "name": "" }] }];

    //初回解答日から今日までの日数を取得し、for文用のArrayを作る
    let todayDate = new Date();
    var diff = todayDate.getTime() - startDate.getTime();
    var dayToToday = Math.ceil(diff / (1000 * 60 * 60 * 24));
    const dayToTodayList = Array.from(new Array(dayToToday + 1)).map((v, i) => i);
    this.daysFromStartingDate = dayToTodayList.length;
    // console.log("this.currentGuiStatus.dateRangeDual_"+JSON.stringify(this.currentGuiStatus.dateRangeDual))
    
    // let startDateNumeber:number = this.currentGuiStatus.dateRangeDual.lower + dayToTodayList.length;
    // let endDateNumeber:number = this.currentGuiStatus.dateRangeDual.upper + dayToTodayList.length;
    // let filterDate_start:Date = new Date(todayDate.setDate(todayDate.getDate() + this.currentGuiStatus.dateRangeDual.lower));
    // let filterDate_end:Date = new Date(todayDate.setDate(todayDate.getDate() + this.currentGuiStatus.dateRangeDual.upper));
    // console.log("filterDate_start_"+filterDate_start)
    // console.log("filterDate_end_"+filterDate_end)
    for (var i = 0, l = dayToTodayList.length; i < l; i++) {
      //一日ごとに、問題ごとの「その時点」最新の解答履歴だけ取得

      //if (startDateNumeber < i && i < endDateNumeber){
      let targetDate = new Date(startDate);
      targetDate = new Date(targetDate.setDate(targetDate.getDate() + i));
      targetDate = new Date(//日付比較のため時分秒msを０に揃える。2018/12/08 00:00:00.000
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate(),
        0, 0, 0, 0
      )
      //console.log("targetDate_"+targetDate)
      let jitenSaisinGakusyuRireki: GakusyuRireki[] = [];//各問題の「その時点の」最新の解答の入れ物

      for (var mondaiId in kaitouHistory) {
        //ターゲットの問題のうち、解答済み、かつ、回答前にFlipOpenしてない　のレコードだけに絞り込み
        //let tmpKaitous: Kaitou[] = kaitouHistory[Number(mondaiId)];
        let tmpKaitous: Kaitou[] = kaitouHistory[Number(mondaiId)].filter(data => {
          return (data.saitenKekka != null && !data.flipOpenedBeforeSelect);
        });
        
        let lengthTk = tmpKaitous.length;
        for (let tk in tmpKaitous) {
          //回答履歴を最新のものから舐めて、最初にターゲットを下回る日時のレコードを取得
          let targetDateShifted = targetDate.getTime() + 1000 * 60 * 60 * (9 + 24);//日本時間と、「より前」的な時間を補正した値と比較
          //if(filterDate_start.getTime() < targetDateShifted && targetDateShifted  < filterDate_end.getTime()){//期間フィルタの範囲内の場合のみ、加算の処理に入る
          if (new Date(tmpKaitous[lengthTk - Number(tk) - 1].dateTime_sentakuFixedAt).getTime() < targetDateShifted) {
            let konoMondaiNoSaisinKaitou: GakusyuRireki = tmpKaitous[lengthTk - Number(tk) - 1];
            let tmpMondai: Mondai = this.getOneMondaiFromFile(konoMondaiNoSaisinKaitou[mondaiId]);
            for (var key in tmpMondai) {
              konoMondaiNoSaisinKaitou[key] = tmpMondai[key];
            }
            jitenSaisinGakusyuRireki.push(konoMondaiNoSaisinKaitou);
            break;
          }
        
        }
      }
      // if (i==l-1){
      //   //最新の回答履歴を保存する（統計バッチのためにCFSにUPするため）
      //   //console.log("DDD_"+JSON.stringify(jitenSaisinGakusyuRireki))
      //   this.saishuuGakusyuRireki = JSON.parse(JSON.stringify(jitenSaisinGakusyuRireki));
      // }

      //科目フィルタ
      if (this.currentGuiStatus.ashiatoHyoujiKamoku != 0 && this.currentGuiStatus.ashiatoHyoujiKamoku != null) {
        //フィルタに一致するレコードのみ取得
        jitenSaisinGakusyuRireki = jitenSaisinGakusyuRireki.filter(data => {
          return (data.mondaiSyuId == this.currentGuiStatus.ashiatoHyoujiKamoku);
        });
      }
      //年度フィルタ
      if (this.currentGuiStatus.ashiatoHyoujiNendo != 0 && this.currentGuiStatus.ashiatoHyoujiNendo != null) {
        //フィルタに一致するレコードのみ取得
        jitenSaisinGakusyuRireki = jitenSaisinGakusyuRireki.filter(data => {
          return (data.nendo == this.currentGuiStatus.ashiatoHyoujiNendo);
        });
      }
      //キーワードフィルタ
      if (this.currentGuiStatus.ashiatoHyoujiKeyword != "すべて表示" &&
        this.currentGuiStatus.ashiatoHyoujiKeyword != null &&
        this.currentGuiStatus.ashiatoHyoujiKeyword != "") {
        //フィルタに一致するレコードのみ取得
        jitenSaisinGakusyuRireki = jitenSaisinGakusyuRireki.filter(data => {
          return (data.keyword.indexOf(this.currentGuiStatus.ashiatoHyoujiKeyword) >= 0);
        });
      }

      //「その時点」の、問題数を、科目名、正誤ごとに合計する
      for (var kamokuMei in this.kamokuName) {
        let seigoArr = [true, false]
        for (var seigo in seigoArr) {
          //科目別に抽出
          let arrayForCount = jitenSaisinGakusyuRireki.filter(jitenData => {
            return (jitenData.mondaiSyuId == Number(kamokuMei));
          });

          arrayForCount = arrayForCount.filter(arrData => {
            return (arrData.saitenKekka == seigoArr[seigo]);
          });

          //Flip開いた場合は、バツ扱いするための補正値を算出
          let arrayForCount_flip = jitenSaisinGakusyuRireki.filter(jitenData => {
            return (jitenData.mondaiSyuId == Number(kamokuMei));
          });
          arrayForCount_flip = arrayForCount_flip.filter(arrData => {
            return (arrData.saitenKekka &&arrData.flipOpenedBeforeSelect);
          });
          let answerCount:number = 0;
          if (seigoArr[seigo]){
            //正解をカウントする際は、減算
            answerCount = arrayForCount.length - arrayForCount_flip.length;
          } else {
            //不正解をカウントする際は、加算
            answerCount = arrayForCount.length + arrayForCount_flip.length;
          }
          //（ここまで）Flip開いた場合は、バツ扱いするための補正値を算出

          //タイマーの合算処理。targetDateの数値を

          const tmpSeries = {
            "value": answerCount,//arrayForCount.length,
            //"name": String(targetDate)
            //"name": this.convertDateTimeAshiatoChart(targetDate)
            "name": this.convertDateTimeAshiatoChart(String(targetDate))

          }

          let targetDateData;
          if (seigoArr[seigo]) { //正解と不正解を合算する場合で、dateDataの格納先のキーを切り替える分岐
            targetDateData = this.dateData.filter(dData => {
              return (dData.name == this.kamokuName[kamokuMei] + "○");
            });
          } else {
            targetDateData = this.dateData.filter(dData => {
              return (dData.name == this.kamokuName[kamokuMei] + "×");
            });
          }
          targetDateData[0].series.push(tmpSeries);
        }

        //timerのDatedata格納処理
        let targetDateShifted_timer = new Date();
        targetDateShifted_timer.setTime(targetDate.getTime() + 1000 * 60 * 60 * 9);//日本時間と、「より前」的な時間を補正した値と比較
        let tmpTimerRecordKey: string = JSON.stringify(targetDateShifted_timer).substr(1, 24);//先頭末尾のダブルクォート不要なので取りさる
        
        let targetDateData_timer;
        targetDateData_timer = this.dateData_timer.filter(dData => {
          return (dData.name == this.kamokuName[kamokuMei]);
        });
        
        //デフォルト値取得。勉強していない日は、前日の値のコピーを取得する
        let tmp_timer_value = targetDateData_timer[0].series[targetDateData_timer[0].series.length - 1].value;
        if (
          //this.timerHistory[tmpTimerRecordKey]
          this.currentGuiStatus.timerCountToday[tmpTimerRecordKey]
        ) {
          // console.log("BBB_"+JSON.stringify(this.currentGuiStatus.timerCountToday[tmpTimerRecordKey]))
          // console.log("AAA_"+JSON.parse(this.currentGuiStatus.timerCountToday[tmpTimerRecordKey]))
          //hourに換算した値を足し込む
          tmp_timer_value = tmp_timer_value + Math.round(this.currentGuiStatus.timerCountToday[tmpTimerRecordKey][kamokuMei] / 60 / 60 * 100) / 100;;//JSON.parse(this.timerHistory[tmpTimerRecordKey])[kamokuMei];
        } 
        
        const tmpSeries_timer = {
          "value": tmp_timer_value,
          "name": this.convertDateTimeAshiatoChart(String(targetDate))
        }
        targetDateData_timer[0].series.push(tmpSeries_timer);
      }
      
    }
    
    //フィルタ　＠　期間　で絞る （選択期間のみ、Y軸変えずに「拡大」するバージョン

    //Datadataの絞り込み処理　＠　Kaitou
    for (var kamokuMei in this.kamokuName) {
      let seigoArr = [true, false]
      for (var seigo in seigoArr) {
        let targetDateData;
        if (seigoArr[seigo]) { //正解と不正解を合算する場合で、dateDataの格納先のキーを切り替える分岐
          targetDateData = this.dateData.filter(dData => {
            return (dData.name == this.kamokuName[kamokuMei] + "○");
          });
        } else {
          targetDateData = this.dateData.filter(dData => {
            return (dData.name == this.kamokuName[kamokuMei] + "×");
          });
        }

        //フィルタ期間以前の値を引き算して除外する
        if (targetDateData[0].series[targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.lower] ){
        let kamokuChartMinValue:number = targetDateData[0].series[targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.lower].value;
        //console.log('kamokuChartMinValue_'+kamokuChartMinValue)
        for (var tmpSrs in targetDateData[0].series) {
          //console.log('tmpSrs_bfr'+JSON.stringify(targetDateData[0].series[tmpSrs]))
          targetDateData[0].series[tmpSrs]['value'] = targetDateData[0].series[tmpSrs]['value'] - kamokuChartMinValue;
          //console.log('tmpSrs_aft'+JSON.stringify(targetDateData[0].series[tmpSrs]))
        }
      }
        // targetDateData[0].series.forEach(function( item ) {
        //   item.value = item.value - kamokuChartMinValue;
        // });
        //フィルタ期間のみを、配列から取得Sliceする
        targetDateData[0].series = targetDateData[0].series.slice(targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.lower, targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.upper);
        
      }

      //Datadataの絞り込み処理　＠　Timer
      let targetDateData_timer;
      targetDateData_timer = this.dateData_timer.filter(dData => {
        return (dData.name == this.kamokuName[kamokuMei]);
      });
      targetDateData_timer[0].series = targetDateData_timer[0].series.slice(targetDateData_timer[0].series.length + this.currentGuiStatus.dateRangeDual.lower, targetDateData_timer[0].series.length + this.currentGuiStatus.dateRangeDual.upper);
    }
    
    // //フィルタ　＠　期間　で絞る （選択期間のみ、Y軸変えずに「拡大」するバージョン
    // //if ( this.currentGuiStatus.dateRange != 0){
    // //全期間の場合は絞る処理をしない
    // for (var kamokuMei in this.kamokuName) {
    //   let seigoArr = [true, false]
    //   for (var seigo in seigoArr) {
    //     let targetDateData;
    //     if (seigoArr[seigo]) { //正解と不正解を合算する場合で、dateDataの格納先のキーを切り替える分岐
    //       targetDateData = this.dateData.filter(dData => {
    //         return (dData.name == this.kamokuName[kamokuMei] + "○");
    //       });
    //     } else {
    //       targetDateData = this.dateData.filter(dData => {
    //         return (dData.name == this.kamokuName[kamokuMei] + "×");
    //       });
    //     }

    //     targetDateData[0].series = targetDateData[0].series.slice(targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.lower, targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.upper);
    //   }

    //   let targetDateData_timer;
    //   targetDateData_timer = this.dateData_timer.filter(dData => {
    //     return (dData.name == this.kamokuName[kamokuMei]);
    //   });
    //   targetDateData_timer[0].series = targetDateData_timer[0].series.slice(targetDateData_timer[0].series.length + this.currentGuiStatus.dateRangeDual.lower, targetDateData_timer[0].series.length + this.currentGuiStatus.dateRangeDual.upper);
    // }
    //}
  }

  // getStackChartMax(mode: string) {
  //   //
  //   let maxValue: number = 100;
  //   for (var kamokuMei in this.kamokuName) {
  //     if (this.visibleChartName == 'ashiato') {
  //       let seigoArr = [true, false]
  //       for (var seigo in seigoArr) {
  //         let targetDateData;
  //         if (seigoArr[seigo]) { //正解と不正解を合算する場合で、dateDataの格納先のキーを切り替える分岐
  //           targetDateData = this.dateData.filter(dData => {
  //             return (dData.name == this.kamokuName[kamokuMei] + "○");
  //           });
  //         } else {
  //           targetDateData = this.dateData.filter(dData => {
  //             return (dData.name == this.kamokuName[kamokuMei] + "×");
  //           });
  //         }

  //         maxValue = maxValue + targetDateData[0].series[targetDateData[0].series.length - 1].value;

  //       }
  //     } else {
  //       //
  //       let targetDateData_timer;
  //       targetDateData_timer = this.dateData_timer.filter(dData => {
  //         return (dData.name == this.kamokuName[kamokuMei]);
  //       });
  //       maxValue = maxValue + targetDateData_timer[0].series[targetDateData_timer[0].series.length - 1].value
  //     }

  //   }
  // }

  // getFilteredDateData(dDataHikisuu:any){
  //   //積み上げグラフの元データを、表示用のもののみフィルタする
  //   //フィルタ　＠　期間　で絞る
  //   //if ( this.currentGuiStatus.dateRange != 0){
  //   //全期間の場合は絞る処理をしない
  //   for (var kamokuMei in this.kamokuName) {
  //     let seigoArr = [true, false]
  //     for (var seigo in seigoArr) {
  //       let targetDateData;
  //       if (seigoArr[seigo]) { //正解と不正解を合算する場合で、dateDataの格納先のキーを切り替える分岐
  //         targetDateData = dDataHikisuu.filter(dData => {
  //           return (dData.name == this.kamokuName[kamokuMei] + "○");
  //         });
  //       } else {
  //         targetDateData = dDataHikisuu.filter(dData => {
  //           return (dData.name == this.kamokuName[kamokuMei] + "×");
  //         });
  //       }
  //       //targetDateData[0].series = targetDateData[0].series.slice(targetDateData[0].series.length-this.currentGuiStatus.dateRange, targetDateData[0].series.length);

  //       targetDateData[0].series = targetDateData[0].series.slice(targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.lower, targetDateData[0].series.length + this.currentGuiStatus.dateRangeDual.upper);
  //     }
  //   }
  // }

  //あしあとのチャートの日付表示を変換する関数
  convertDateTimeAshiatoChart(dateTimeStr: string) {
    //日付の形式を変換　例：12/11(木)
    var WeekChars = ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"];
    var MonthChars = {
      "Jan": "1",
      "Feb": "2",
      "Mar": "3",
      "Apr": "4",
      "May": "5",
      "Jun": "6",
      "Jul": "7",
      "Aug": "8",
      "Sep": "9",
      "Oct": "10",
      "Nov": "11",
      "Dec": "12"
    };
    let stringToReturn: string =
      MonthChars[dateTimeStr.substr(4, 3)] + "/"
      + dateTimeStr.substr(8, 2)
      + WeekChars[new Date(dateTimeStr).getDay()]

    return stringToReturn;
  }

  getCount_kaitou_and_timer(mondaiSyuId: number,mode: string, daysBefore: number ): number {
    //指定日前時点（現時点は0）の、勉強時間累計値

    let recordCount: number = 0;
    //let seigoArr = [true, false]
    let kamokuIdArray: Number[] = [];
    if (mondaiSyuId != 0) {
      kamokuIdArray = [Number(mondaiSyuId)];
    } else {
      kamokuIdArray = [101, 102, 103, 104, 105, 106, 107, 201, 202, 203, 204];
    }

    let targetDateData;
    for (var i_kamoku in kamokuIdArray) {
      if (mode == 'kaitou') {
        targetDateData = this.dateData.filter(dData => {
          return (dData.name == this.kamokuName[Number(kamokuIdArray[i_kamoku])] + "○");
        });

        if (targetDateData[0]){//初回起動時にNullエラーが出る対処
          recordCount = recordCount + targetDateData[0].series[targetDateData[0].series.length - (daysBefore + 1)].value;
        }

        targetDateData = this.dateData.filter(dData => {
          return (dData.name == this.kamokuName[Number(kamokuIdArray[i_kamoku])] + "×");
        });

        if (targetDateData[0]){//初回起動時にNullエラーが出る対処
          recordCount = recordCount + targetDateData[0].series[targetDateData[0].series.length - (daysBefore + 1)].value;
        }
      } else if (mode == 'timer') {
        targetDateData = this.dateData_timer.filter(dData => {
          return (dData.name == this.kamokuName[Number(kamokuIdArray[i_kamoku])]);
        });
        if (targetDateData[0]){//初回起動時にNullエラーが出る対処
          recordCount = recordCount + targetDateData[0].series[targetDateData[0].series.length - (daysBefore + 1)].value;
        }
      }
      // if (mode == 'kaitou-true') {
      //   targetDateData = this.dateData.filter(dData => {
      //     return (dData.name == this.kamokuName[Number(kamokuIdArray[i_kamoku])] + "○");
      //   });

      //   recordCount = recordCount + targetDateData[0].series[targetDateData[0].series.length - (daysBefore + 1)].value;

      // } else if (mode == 'kaitou-false') {
      //   targetDateData = this.dateData.filter(dData => {
      //     return (dData.name == this.kamokuName[Number(kamokuIdArray[i_kamoku])] + "×");
      //   });
      //   recordCount = recordCount + targetDateData[0].series[targetDateData[0].series.length - (daysBefore + 1)].value;
      // } else 
    }
    return recordCount;
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => this.wait_delay_end());
  }

  wait_delay_end(){}//WAITのための何もしないメソッド

  async writeOperationLog(mondaiSyuId:number , mondaiId:number ,operationName:string, cFSWrite:boolean ) {
    //色々雑に作ってある。＃SaitenFixしたら、CFSに書いてLocalDBは0クリア。＃LocalDBは一時置き場のようなもの
    //ログを出力する

    let tmpDate = new Date();
    tmpDate.setTime(tmpDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
    this.todayDate = tmpDate;
    const dateNow = this.todayDate;

    const logData = {
      "mondaiSyuId": mondaiSyuId,
      "mondaiId": mondaiId,
      "operationName": operationName,
      //"date": this.convertDateTimeAshiatoChart(String(new Date()))
      "date": dateNow
    }

    this.operationLog.push(JSON.parse(JSON.stringify(logData)));

    //データ保存
    if (cFSWrite) {//CFSに書く場合
      //日時つけてCFSにUP
      let monthString:string = String(dateNow.getMonth() + 1);
      let dateString:string = String(dateNow.getDate());
      let hourString:string = String(new Date().getHours());
      let minuteString:string = String(dateNow.getMinutes());
      let secondString:string = String(dateNow.getSeconds());

      if (Number(monthString) < 10){ monthString = '0'+monthString; }
      if (Number(dateString) < 10){ dateString = '0'+dateString; }
      if (Number(hourString) < 10){ hourString = '0'+hourString; }
      if (Number(minuteString) < 10){ minuteString = '0'+minuteString; }
      if (Number(secondString) < 10){ secondString = '0'+secondString; }

      this.backUpObjectToDB(
        'operationLog' + "_" + dateNow.getFullYear() + monthString + dateString + hourString + minuteString + secondString,
        this.operationLog,
        true
      );

      //CFSに上げたら、Localのデータは容量削減のため消す
      localStorage.removeItem( 'operationLog' + "_" + dateNow.getFullYear() + monthString + dateString + hourString + minuteString + secondString);
      this.backUpObjectToDB(
        'operationLog',
        [],
        false
      );//容量削減のためのクリアここまで
      
    } else {//CFSに書かない場合は、ローカルに変数をそのまま保存する
      this.backUpObjectToDB(
        'operationLog',
        this.operationLog,
        false
      );
    }

    // let fillSeconds:number = 60 * 3;
    // this.timer_remain_fill(mondaiSyuId,fillSeconds);

    // //日付単位に作っているその日のタイマー合計値を上書きしLocalDB保存
    // this.timerHistory[JSON.stringify(dateNow).substr(1, 10)+"T00:00:00.000Z"] = JSON.stringify(this.currentGuiStatus.timerCountAll);
    // this.backUpObjectToDB('timerHistory', this.timerHistory);

    
    
  }

  // timer_remain_fill(mondaiSyuId: number) {
  //   let fillValue = 60 * 3;
  //   //（補填しようとしている値より現在値が少なければ）タイマーを補填
  //   if (this.timer_remain[mondaiSyuId] < fillValue) {
  //     this.timer_remain[mondaiSyuId] = fillValue;
  //   }
  // }

  // async timerCounter() {
  //   //１ずつ現在の累計時間に値を移動させるカウンター処理を繰り返す関数
  //   let tmpDate;
  //   for (var kamokuMei in this.kamokuName) {
  //     if (kamokuMei == String(this.currentGuiStatus.currentTab)) {
  //       if (this.timer_remain[this.currentGuiStatus.currentTab] > 0) {
  //         //アクティブチェック＠１秒。カレントの科目の残時間が０より大きいときだけ、以降の処理を実行
  //         //アクティブかチェック全クリアしたら、１ずつ現在の累計時間に値を移動させる
  //         tmpDate = new Date();
  //         tmpDate.setTime(tmpDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
  //         this.todayDate = tmpDate;
  //         const dateNow = this.todayDate;
  
  //         //毎秒チェック。タブが切り替わっていれば、Remainをすべて０にリセット
  //         //標準時間チェック。スライド位置が変わっていなければRemainを０リセット
  //         //カレントのタブの、remainが1以上なら、remainの値を１減らして、addに１追加
  //         this.timer_remain[this.currentGuiStatus.currentTab] = this.timer_remain[this.currentGuiStatus.currentTab] - 1 * 90;//1
  //         // this.currentGuiStatus.timerCountAll[this.currentGuiStatus.currentTab] = this.currentGuiStatus.timerCountAll[this.currentGuiStatus.currentTab] + 1;
  
  //         //実行日ぶんのTimerCounterの時間を１秒分加算する処理。
  //         if (!this.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"]) {
  //           //時間を入れる変数の入れ物がなければ作る
  //           this.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"] = {
  //             0: 0,
  //             101: 0,
  //             102: 0,
  //             103: 0,
  //             104: 0,
  //             105: 0,
  //             106: 0,
  //             107: 0
  //           };
  //         }
  //         this.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][this.currentGuiStatus.currentTab] = this.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][this.currentGuiStatus.currentTab] + 1 * 90;//1
  //         //全科目の合計時間も更新
  //         let tmpTimerAllCount = 0;
  //         for (var kamokuMei in this.kamokuName) {
  //           tmpTimerAllCount = tmpTimerAllCount + this.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][kamokuMei];
  //         }
  //         this.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][0] = tmpTimerAllCount;
  
  
  //       } else {
  //         if (true){
  //         //アクティブチェック＠規定時間。スクロールが前回と変わっていれば、残時間を補填する
  //         this.timer_remain[this.currentGuiStatus.currentTab] = 60*3
  //         //this.timer_remain_fill(this.currentGuiStatus.currentTab);
  //         }
  //       }
  //     } else {
  //       //Currentの科目以外のタイマーはリセットする
  //       this.timer_remain[kamokuMei] = 0;
  //     }
  //   }

  // }

  dateFormat = {
    _fmt: {
      "yyyy": function (date) { return date.getFullYear() + ''; },
      "MM": function (date) { return ('0' + (date.getMonth() + 1)).slice(-2); },
      "dd": function (date) { return ('0' + date.getDate()).slice(-2); },
      "hh": function (date) { return ('0' + date.getHours()).slice(-2); },
      "mm": function (date) { return ('0' + date.getMinutes()).slice(-2); },
      "ss": function (date) { return ('0' + date.getSeconds()).slice(-2); }
    },
    _priority: ["yyyy", "MM", "dd", "hh", "mm", "ss"],
    format: function (date, format) {
      return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format)
    }
  };

  getTodayDateStringified(){
    //今日日付のString版を取得
    let returnStr = '';
    returnStr = JSON.stringify(this.todayDate).substr(1, 10)+"T00:00:00.000Z";
    return returnStr;
  }

  getTimerCountAll(mondaiSyuId:number){
    //今日の解答問題数を返す関数
    return this.currentGuiStatus.timerCountAll[mondaiSyuId];
  }
  
  getKaitouCountToday(mondaiSyuId:number){
    //今日の解答問題数を返す関数
    // console.log('mondaiSyuId'+mondaiSyuId);
    // console.log('this.currentGuiStatus.kaitouCountToday[mondaiSyuId]'+this.currentGuiStatus.kaitouCountToday[mondaiSyuId]);
    let kaitouCountTodayToReturn:number = this.currentGuiStatus.kaitouCountToday[mondaiSyuId];
    return kaitouCountTodayToReturn;
  }

  getTimerCountToday(mondaiSyuId:number, mode:string){
    //LCDに表示する時分あるいは秒を返す
    let returnNumber:number = 0;
    let returnString:string = 'none';
    let timerObj = this.currentGuiStatus.timerCountToday[JSON.stringify(this.todayDate).substr(1, 10)+"T00:00:00.000Z"]
    if (mode == 'hh'){
      //
      returnNumber = Math.floor(timerObj[mondaiSyuId] /60/60);
      if (returnNumber < 10){
        returnString = '0' + returnNumber;
      } else {//10以上ならそのまま文字列にする
        returnString = String(returnNumber);
      }
      //if (timerObj[mondaiSyuId]);
    } else if (mode == 'mm'){
      returnNumber = timerObj[mondaiSyuId] % (60*60);//hourの数字をなくす
      returnNumber = Math.floor(returnNumber /60);
      if (returnNumber < 10){
        returnString = '0' + returnNumber;
      } else {//10以上ならそのまま文字列にする
        returnString = String(returnNumber);
      }
    } else if (mode == 'ss'){
      returnNumber = timerObj[mondaiSyuId] % (60);
      returnNumber = Math.floor(returnNumber );
      if (returnNumber < 10){
        returnString = '0' + returnNumber;
      } else {//10以上ならそのまま文字列にする
        returnString = String(returnNumber);
      }
    } else if (mode == 'm'){
      returnNumber = timerObj[mondaiSyuId];
      //console.log('m_returnNumber_'+returnNumber)
      returnNumber = Math.floor(returnNumber /60);
      returnString = String(returnNumber);
      //console.log('m_'+returnString)
    }
    return returnString;
  }

  tutorialMondaiListReload() {
    //チュートリアルで1問だけ読み込むときのメソッド
    //mondaiListの参照を切るために、DeepCopy
    this.currentGuiStatus.currentMokuji[this.currentGuiStatus.currentTab] = JSON.parse(JSON.stringify(this.currentGuiStatus.currentMokuji[this.currentGuiStatus.currentTab]));
    //配列の1番目の問題だけ絞り込み
    this.currentGuiStatus.currentMokuji[this.currentGuiStatus.currentTab].mokujiMondaiList = this.currentGuiStatus.currentMokuji[this.currentGuiStatus.currentTab].mokujiMondaiList.slice(0, 1);

  }

  updateTodayKaitouCount(){
    //
    let kaitouCountToReturn = {0:0,101:0,102:0,103:0,104:0,105:0,106:0,107:0,201:0,202:0,203:0,204:0};
    let tmpKaitouHistory = JSON.parse(JSON.stringify(this.kaitouHistory));
    // let tmpKaitous: Kaitou[] = tmpKaitouHistory[Number(mondaiId)].filter(data => {
    //   return (data.saitenKekka != null || !data.hint_visible);
    // });
    
    for (var mondaiId in tmpKaitouHistory) {
      //ターゲットの問題のうち、解答済みのレコードだけに絞り込み
      //let tmpKaitous: Kaitou[] = kaitouHistory[Number(mondaiId)];
      let tmpKaitous: Kaitou[] = tmpKaitouHistory[Number(mondaiId)].filter(data => {
        return (data.saitenKekka != null);
      });
      //this.todayDate.getFullYear();
      // this.testDate = tmpKaitouHistory[Number(106010250)].filter(data => {
      //   return (data.saitenKekka != null);
      // });
      //this.testDate = new Date(this.testDate[0].dateTime).getTime();
      let lengthTk = tmpKaitous.length;
      for (let tk in tmpKaitous) {
        //回答履歴を今日のものだけカウント
        let targetDateShifted = this.todayDate.getTime() + 1000 * 60 * 60 * (-9)//(9-24 );//日本時間と、補正した値と比較
        if (new Date(tmpKaitous[lengthTk - Number(tk) - 1].dateTime_sentakuFixedAt).getTime() < targetDateShifted) {
          
          break;
        } else {
          //console.log('mondaiId_'+mondaiId)
          kaitouCountToReturn[this.getOneMondaiFromFile(Number(mondaiId)).mondaiSyuId] = kaitouCountToReturn[this.getOneMondaiFromFile(Number(mondaiId)).mondaiSyuId] + 1;
          kaitouCountToReturn[0] = kaitouCountToReturn[0] + 1;
        }      
      }
    }
  }

  
  setDefaultSettings(mode:string){
    if (mode == 'currentGuiStatus'){
      this.currentGuiStatus = this.currentGuiStatus_defaultValue;
      this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);

      this.currentGuiStatus.timerCountToday = this.getAllRecord('timerCountTodayBackup');//勉強時間の元ネタデータは、ユーザにとって重要なデータなのでデータ初期化する場合でも復活させる

      //this.kaitouHistory = null;
      //もともと、m.component似合った際の処理だがFSに移動したのでコメントアウト。代わりにリロードする
      // for (var key in this.currentGuiStatus.currentMokuji) {
      //   //カレントもくじを、最初のもくじにリセットする。この中で回答履歴も初期値が入る  
      //   this.setCurrentMokujiCategory(Number(key), this.mokujiDataSourceDic[key][this.firestoreAccessor.currentGuiStatus.categoryMode[key]][0])
      // }
      //this.backUpObjectToDB('kaitouHistory', this.kaitouHistory);
      window.location.reload();
    } else if (mode =='kaitouHistory'){
      this.kaitouHistory = null;
      //
      // もともと、m.component似合った際の処理だがFSに移動したのでコメントアウト。代わりにリロードする
      //   for (var key in this.currentGuiStatus.currentMokuji) {
      //   //カレントもくじを、最初のもくじにリセットする。この中で回答履歴も初期値が入る  
      //   this.setCurrentMokujiCategory(Number(key), this.mokujiDataSourceDic[key][this.firestoreAccessor.currentGuiStatus.categoryMode[key]][0])
      // }
      this.backUpObjectToDB('kaitouHistory', this.kaitouHistory);
      window.location.reload();
    }
  }

  getKaitouCountDisplay(targetMondaiSyuId?: number) {
    let kaitouCountAllNum: number = 0;
    if (targetMondaiSyuId) {
      //科目指定がある場合は絞り込み
      for (var mondaiId in this.kaitouHistory) {
        if (this.getOneMondaiFromFile(Number(mondaiId)).mondaiSyuId == targetMondaiSyuId) {
          if (this.getLastKaitouHistory_HavingSaitenKekka(Number(mondaiId))) {//解答が存在する問題をカウント
            kaitouCountAllNum = kaitouCountAllNum + 1;
          }
        }
      }
    } else {
      //console.log('targetMondaiSyuId_' + targetMondaiSyuId)
      for (var mondaiId in this.kaitouHistory) {
        if (this.getLastKaitouHistory_HavingSaitenKekka(Number(mondaiId))) {//解答が存在する問題をカウント
          kaitouCountAllNum = kaitouCountAllNum + 1;
        }
      }
    }
    return kaitouCountAllNum;
  }

  // getDateDataSeriesForTopPage(){
  //   //topページで学習日を表示するための変数を返す。起動時等で値が取得できない場合は、スキップする
  //   if (this.dateData[0]){
  //     return this.dateData[0]["series"];
  //   } else {
  //     return null;
  //   }
  // }

  getStartDate(){
    //HOMEタブに勉強開始日を表示に使用する数値
    //最も古い解答日時を取得
    let returnJson = {};
    let kaitouHistoryCopiedStr: string = JSON.stringify(this.kaitouHistory);
    let kaitouHistoryCopied = JSON.parse(kaitouHistoryCopiedStr); // JSON文字列化したものを戻す 

    this.kaitouMondaiJoin = this.reloadKaitouMondaiJoin(kaitouHistoryCopied);
    let gakusyuRireki: GakusyuRireki[] = [];//解答と問題をJOINしたデータ
    for (var mondaiId in kaitouHistoryCopied) {
      let tmpMondai: Mondai = this.getOneMondaiFromFile(Number(mondaiId));
      let tmpKaitous: Kaitou[] = kaitouHistoryCopied[Number(mondaiId)];
      for (let tk in tmpKaitous) {
        let tmpGakusyuRireki: GakusyuRireki;

        tmpGakusyuRireki = tmpKaitous[tk]; //
        for (var key in tmpMondai) {
          tmpGakusyuRireki[key] = tmpMondai[key];
        }

        gakusyuRireki.push(tmpGakusyuRireki);
      }
    }

    //日付順にソート
    gakusyuRireki.sort(function (a, b) {
      if (a.dateTime > b.dateTime) return -1;
      if (a.dateTime < b.dateTime) return 1;
      return 0;
    });
    
    returnJson["startDate"] = 
    gakusyuRireki[ gakusyuRireki.length -1 ].dateTime.substr( 0, 4 ) + "/" 
    + gakusyuRireki[ gakusyuRireki.length -1 ].dateTime.substr( 5, 2 ) + "/" 
    + gakusyuRireki[ gakusyuRireki.length -1 ].dateTime.substr( 8, 2 );

    let firstDate = new Date(gakusyuRireki[ gakusyuRireki.length -1 ].dateTime.substr( 0, 19 ));

    let timeDelta = this.todayDate.getTime() - firstDate.getTime();
    returnJson["daysPassed"] = Math.floor(timeDelta / ( 1000 * 60 * 60 * 24 ) + 1);  ;

    return returnJson;
  }

  testDayRemain(){
    //試験当日までののこり日数を返す
    let testDate:Date = new Date("2021/07/11");
    let timeRemain:number =  testDate.getTime() - this.todayDate.getTime() ;
    
    return Math.floor(timeRemain / ( 1000 * 60 * 60 * 24 ) + 1);
  }


  insertKansou(){
    //Kansouのデータ構築。日時、MNなど
    let kansouDate = new Date();
    kansouDate.setTime(kansouDate.getTime());

    const data: Kansou = {
      timeStamp: firebase.firestore.FieldValue.serverTimestamp(),//kansouDate,
      timeStamp_display: this.dateFormat.format(kansouDate, 'yyyy/MM/dd hh:mm:ss'),
      sendTo: 'kansou',
      kansou: this.kansouString,
      myName: this.currentGuiStatus.myName,
      reply: "",
    };

    //同じ問題の感想を連続で書き換えた場合は同じドキュメントIDを使用し、レコードを追加せず更新させる
    let kansouId:string =  '';

    let rand = Math.floor( 1000 + Math.random() * 9000 ) ;
    kansouId =  [
      this.dateFormat.format(kansouDate, 'yyyyMMdd_hhmmss_') , rand
    ].join('');

    if(RegExp('test_').test(this.currentGuiStatus.myName)){
      //ローカル用の書き込みの場合は、識別できる名前にする
      kansouId = "test_"+kansouId;
    }

    //ドキュメント追加　＠　感想の全体閲覧用
    var kansouDoc: AngularFirestoreDocument<Kansou> = 
      this.afStore.doc(`kansou/${kansouId}`);
    kansouDoc.set(data);

    //ドキュメント追加　＠　ユーザごとのコレクション
    const kansouDocUser: AngularFirestoreDocument<Kansou> = 
      this.afStore.doc(`user/${this.currentGuiStatus.myName}/kansou/${kansouId}`);
    kansouDocUser.set(data);

    this.currentGuiStatus.currentKansouId = kansouId;
    this.setKansouDoc();//表示用のDocを更新
    this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus, false);//DBローカル保存

    // this.kansouDoc = this.afStore.doc<Kansou>(`user/${this.currentGuiStatus.myName}/kansou/${kansouId}`);
    // this.item = this.kansouDoc.valueChanges();
  }

  getSharingUrl(mondaiId:number){
    let tmp_hint_visible:string = "false";//解説表示したことがない場合、項目自体がないのでデフォルト値を入れる
    if (this.currentGuiStatus.currentKaitou[mondaiId].hint_visible){
      tmp_hint_visible = 'true';
    }
    let urlStr:string = '';
    urlStr = "https://app.zu-de.com/m/" + mondaiId + "/" + tmp_hint_visible + "/" +this.currentGuiStatus.categoryMode[this.currentGuiStatus.currentTab] + "/none"
    return urlStr;
  }

  getSharingUrlYougo(mondaiId:number,youdoName:string){
    let tmp_hint_visible:string = "false";//解説表示したことがない場合、項目自体がないのでデフォルト値を入れる
    if (this.currentGuiStatus.currentKaitou[mondaiId].hint_visible){
      tmp_hint_visible = 'true';
    }
    let urlStr:string = '';
    urlStr = "https://app.zu-de.com/m/" + mondaiId + "/" + tmp_hint_visible + "/" +this.currentGuiStatus.categoryMode[this.currentGuiStatus.currentTab] + "/yougo"+youdoName
    return urlStr;
  }

  getMondaiNameShorten(nameString:string){
    //
    let shortened:string = nameString;
    shortened = shortened.replace( '第', '' );
    shortened = shortened.replace( '問', '' );
    shortened = shortened.replace( '設問', '.' );
    
    // if (shortened == "1" || shortened == "2" || shortened == "3" || shortened == "4" || shortened == "5" || shortened == "6" || shortened == "7" || shortened == "8" || shortened == "9"){
    //   //1-9の場合は、スペース加えて幅揃える
    //   shortened = "　" + shortened;
    // }

    // if (shortened.search(/設問/i) == -1){
    //   //設問がない場合は、スペース加えて幅揃える
    //   if (shortened.search(/　/i) == -1 ){
    //     //1-9以外の場合は、スペース加えて幅揃える
    //     shortened = "　" + shortened;
    //   }
    // } else {
    //   shortened = shortened.replace( '設問', '.' );
    // }

    return shortened;
  }

  getScatterDataArrFromKaitouHistory2ji(targetMondaiId:number, targetVisibleStr:string, colNameToReturn?:string): any[] {
    //RESTからもらった2次の解答たちから、条件にあった解答を返す
    //カラム指定（Scatterで使う解答文や得点)のARRを取得するときは、引数を指定してこの関数を呼ぶ
    // console.log("targetMondaiId"+targetMondaiId+",colNameToReturn"+colNameToReturn);
    // console.log("this.kaitouHistory2ji[targetMondaiId]"+this.kaitouHistory2ji[targetMondaiId])
    let returnArr :any[] = [];
    let tmpKaitou2ji = JSON.parse(JSON.stringify(this.kaitouHistory2ji[targetMondaiId]));
    //該当する解答の列を抽出
    let targetKaitou2ji: Kaitou2ji[] = [];
    if (targetVisibleStr == "mine"){
      //自分の解答を取得するときは、検索条件がmyNameと特殊
      targetKaitou2ji = tmpKaitou2ji.filter(k2 => {
        return (k2.myName == this.currentGuiStatus.myName);
      });
    } else if (targetVisibleStr == "student" || targetVisibleStr == "gouhiKaitouZumi"){
      //受験生と合否は判明済みの解答を取得するときは、自分の解答を除外する
      targetKaitou2ji = tmpKaitou2ji.filter(k2 => {
        return (k2.visible == targetVisibleStr && k2.myName != this.currentGuiStatus.myName);
      });
    } else {
      targetKaitou2ji = tmpKaitou2ji.filter(k2 => {
        return (k2.visible == targetVisibleStr);
      });
    }

    if (colNameToReturn){
      //引数にカラムの指定あれば、ここで指定のカラムのARRに変換する
      for (var i_k2i in targetKaitou2ji) {
        //console.log("FFFFFFFFFFF"+targetKaitou2ji[i_k2i])
        let targetValue = targetKaitou2ji[i_k2i][colNameToReturn];
        if (colNameToReturn == "kaitou"){
          //解答分の場合のみ、20文字ごとに改行を入れる
          targetValue = this.stringDivider(targetValue, 20, "<BR>")
        }
        returnArr.push(targetValue);
      }
    } else {
      //引数指定なければ、解答のARRをかえす
      returnArr = targetKaitou2ji;
    }

    return returnArr;
  }

  
  generateRankingData(targetMondaiId:number ){
    //回答ランキングのデータを生成する関数
    let returnArr :any[] = [];
    let tmpKaitou2ji = JSON.parse(JSON.stringify(this.kaitouHistory2ji[targetMondaiId]));
    returnArr = tmpKaitou2ji.filter(k2 => {
      return (
        k2["distanceA"] != null && k2["visible"] != "hidden"//&& k2["trainable"] == "admin"
      );
    });
    
    returnArr.sort(function (a, b) {
      //距離、得点、のソートの優先度で、ソート
      if (a.distanceA > b.distanceA) return -1;
      if (a.distanceA < b.distanceA) return 1;
      if (a.tokuten > b.tokuten) return -1;
      if (a.tokuten < b.tokuten) return 1;
      return 0;
    });
    //console.log("aft-returnArrrrr--"+JSON.stringify(returnArr))

    this.currentGuiStatus.rankingData[targetMondaiId] = returnArr;
  }

  getRankingTopPangeHyouji(targetMondaiId:number){
    //類似度ランキングで使う、自分の順位（と全体の数）を取得する関数
    let junniNum = 0;
    let ninzuuNum = 0;
    if (this.currentGuiStatus.rankingData[targetMondaiId]){
      let targetRankingData = this.currentGuiStatus.rankingData[targetMondaiId];
      ninzuuNum = targetRankingData.length;
      for (let i_trd in targetRankingData) {
        if (targetRankingData[i_trd].myName == this.currentGuiStatus.myName){
          junniNum = Number(i_trd) + 1;
        }
      }
    }
    const returnDic = {
      junni: junniNum,
      ninzuu: ninzuuNum
    };
    return returnDic;
  }

  
  getHeikinJunni(targetMondaiId:number,targerHyouka:string){
    //対象の評価の平均順位を取得する
    let heikinJunni_sum = 0;
    let heikinJunni_count = 0;
    let targetRankingData = this.currentGuiStatus.rankingData[targetMondaiId];
    // console.log("-targetMondaiId-"+targetMondaiId)
    // console.log("-targetRankingData-"+targetRankingData.length)//★ここで未回答の問題は、何故か長さ0になるのが怪しい
    if (targetRankingData){

      for (let i_tda in targetRankingData) {
        //
        if (targetRankingData[i_tda].gouhi == targerHyouka){
          let tmpJunniNum:number = Number(i_tda);
          heikinJunni_sum = heikinJunni_sum + tmpJunniNum + 1;
          heikinJunni_count = heikinJunni_count + 1;
        }
      }
    }
    const returnNum:number = heikinJunni_sum / heikinJunni_count;
    return returnNum;
  }

  

  getKaketeruTouan(targetMondaiId:number, targetSaitenKijun:string, targetSeigoNum:number,targetGouhiStrArr:string[],){
    //
    let returnArr :any[] = [];
    let tmpKaitou2ji = JSON.parse(JSON.stringify(this.kaitouHistory2ji[targetMondaiId]));
    let targetKaitou2ji: Kaitou2ji[] = [];
    //console.log("tmpKaitou2ji--"+JSON.stringify(tmpKaitou2ji))
    
    if (targetGouhiStrArr.length != 0){
      let saitenkijunKaitou2jiArr: Kaitou2ji[] = [];
      for (let targetGouhiStr in targetGouhiStrArr){
    
        saitenkijunKaitou2jiArr = tmpKaitou2ji.filter(k2 => {
          return (
            k2[targetSaitenKijun] == targetSeigoNum && 
            k2.myName != this.currentGuiStatus.myName && 
            k2["gouhi"] == targetGouhiStrArr[targetGouhiStr]
            );
        });
        targetKaitou2ji = targetKaitou2ji.concat(saitenkijunKaitou2jiArr);
        
        // console.log("targetGouhiStr-- "+ targetGouhiStrArr)
        // console.log("targetKaitou2ji.length-- "+ targetKaitou2ji.length)
      
      }
    } else {
      //合否の引数が、指定されていない場合
      targetKaitou2ji = tmpKaitou2ji.filter(k2 => {
        return (
          k2[targetSaitenKijun] == targetSeigoNum && 
          k2.myName != this.currentGuiStatus.myName
          );
      });
    }

    for (var i_k2i in targetKaitou2ji) {
      let targetValue = targetKaitou2ji[i_k2i]["kaitou"];
      //<BR>を削除する
      let targetValueStr = String(targetValue).replace( '<BR>', '' );
      
      returnArr.push(targetValueStr);
    }

    return returnArr;
  }

  // countKaitouSuuBarCharData(targetMondaiId:number, targetGouhiStr:string, saitenKijunItemStr:string): number{
  //   //
  //   let returnArr :any[] = [];
  //   let tmpKaitou2ji = JSON.parse(JSON.stringify(this.kaitouHistory2ji[targetMondaiId]));

  //   let targetKaitou2ji: Kaitou2ji[] = tmpKaitou2ji.filter(k2 => {
  //       return (k2.visible == "gouhiKaitouZumi");
  //   })

  //   console.log("targetKaitou2ji--"+JSON.stringify(targetKaitou2ji));

  //   return targetKaitou2ji.length;
  // }

  stringDivider(str, width, spaceReplacer) {
    if (str.length>width) {
        let p=width
        for (;p>0 && str[p]!=' ';p--) {
          if (p>0) {
            let left = str.substring(0, p);
            let right = str.substring(p);
              //console.log('1111')
              return left + spaceReplacer + this.stringDivider(right, width, spaceReplacer);
          }
        }
        //console.log('str-'+str)
    }
    return str;
  }
  
  generateScatterSizeSymbolArr(mondaiId: number, visibleName: string, sizeOrSimbolMode: string) {
    let tmpTokutenArr = this.getScatterDataArrFromKaitouHistory2ji(mondaiId, visibleName, "tokuten");

    //得点の配列から、頭に勝手につくIDの数字を削除して、
    //得点にもとづいて、プロットのシンボル形状や、サイズの配列を生成する
    let returnArr = [];
    for (let i_sta in tmpTokutenArr) {
      let targetTokuten = tmpTokutenArr[i_sta];

      let targetValue = null;
      if (sizeOrSimbolMode == "size") {
        if (targetTokuten == 63.5 || targetTokuten == 56.5) {//合格香不合格の場合
          targetValue = 15;
        } else {//ABCDの場合
          targetValue = 22;
        }
      } else if (sizeOrSimbolMode == "symbol") {
        if (targetTokuten >= 60) {//合格かA場合
          targetValue = "circle";
        } else {//ABCDの場合
          targetValue = "x"
        }
      }
      returnArr[i_sta] = targetValue;
    }
    return returnArr;
  }

  isTokutenValueDefault(tokutenValue: number): boolean{
    //得点が、デフォルト知かどうか判定する
    let returnBool = true;
    let originnalValue:number = tokutenValue;
    //小数点の値がなければFALSE
    if (tokutenValue == Math.floor(tokutenValue)){
      returnBool = false;
    }
    return returnBool;
  }

  like(mondaiId: number, targetMyName: string){
    //再現答案に良いねを押したときの挙動。
    //落としてきたLikeデータから、更新対象のレコードをUPする。
    let touhyouStatusChangeTo = "true";
    this.likeList[mondaiId] = [];
    
    if (this.searchLikedStatusList(mondaiId, targetMyName, this.currentGuiStatus.myName).length != 0 ){
      //対象の問題の、対象の再現答案に、自分がLikeしたことがあれば、falseを送る
      touhyouStatusChangeTo = "false";
    }
    this.todoService.like(mondaiId, targetMyName, this.currentGuiStatus.myName)
    .then(
      data => 
      //更新後の最新版を受領
      this.likeList[mondaiId] = data[""+mondaiId]//JSON.parse('{"201030111": [{"targetMyName":"TAC","myName":"test_mn-20210822-203745-46254557"}], "201030211": [{"targetMyName":"KEC","myName":"mn-12345"}]}')[""+mondaiId]
    );
  }

  searchLikedStatusList(mondaiId: number, targetMyName: string, myName?: string){
    //対象の問題の、対象の再現答案の、対象のユーザが、Likeをした投票リストを取得する
    //let returnLikedStatusList = [];
    let tmpReturnLikedStatusList: Like[] = [];
    if (this.likeList[mondaiId]){
      //対象の問題のLikeリストが存在すらしない場合は、空のArrを返す
      tmpReturnLikedStatusList = JSON.parse(JSON.stringify(this.likeList[mondaiId]));
      //console.log("tmpReturnLikedStatusList--",tmpReturnLikedStatusList);
  
      if (myName){
        //myNameの指定があれば、それも絞り込み条件に含める
        tmpReturnLikedStatusList = tmpReturnLikedStatusList.filter(data => {
          return (data.targetMyName == targetMyName && data.myName == myName);
        });
      } else {
        tmpReturnLikedStatusList = tmpReturnLikedStatusList.filter(data => {
          return (data.targetMyName == targetMyName);
        });
      }
  
    } else {
      //対象の問題の変数が空ならば、枠を作る
      this.likeList[mondaiId] = [];
    }

    return tmpReturnLikedStatusList;
  }

  async saigenTouanTeikyou(mondaiId: number, kaitouBun: string, myName: string, defaultGazouGetBoolean: boolean, createNewDefaultGazouFlag: boolean){
    //再現答案をFlaskに投げる指示を送る
    // const body = JSON.stringify({
    //   "kaitouBun": kaitouBun,
    // });
    //this.resultBase64Data = 'assets/img/yellow2-after.jpg';//9/8デモ対応
    //console.log('saigenTouanTeikyou()--kaitouBun--',kaitouBun)
    this.todoService.create_resultSaigenTouanTeikyou(mondaiId, kaitouBun, myName, defaultGazouGetBoolean, createNewDefaultGazouFlag)
    .then(
      data => 
      //this.extracetSaigenTouanTeikyouRestResponse(mondaiId, JSON.parse(data))
      this.saigenTouanTeikyouResult[mondaiId] = JSON.parse(data)
    ).then(
      data => 
      this.extractSaigenTouanTeikyouRestResponse(mondaiId)
    ).then(
      data => 
      this.like(mondaiId,"")
    )
  }

  extractSaigenTouanTeikyouRestResponse(mondaiId:number){
    //再現答案提供して帰ってきたRESTを変数に格納する関数
    //this.saigenTouanTeikyouResult[mondaiId] = responseDataParsed;
    this.saigenTouanList[mondaiId] = JSON.parse(this.saigenTouanTeikyouResult[mondaiId][0].kaitouBunDf);
    this.saigenTouanList_view[mondaiId] = JSON.parse(this.saigenTouanTeikyouResult[mondaiId][0].kaitouBunDf);
    this.saigenTouanTangoArray[mondaiId] = JSON.parse(this.saigenTouanTeikyouResult[mondaiId][0].tangoArray);
    //console.log("this.saigenTouanList[mondaiId]--",this.saigenTouanList[mondaiId])
    //return 0;
  }

  searchSaigenTouanList($event, targetMondaiId: number){
    //対象の問題の、再現答案のリストを、ハイライト表示するタグに置換する
    
    let hitTangoArray = [];//初期化
    this.like(targetMondaiId,"");//初期化
    this.saigenTouanList_view[targetMondaiId] = JSON.parse(JSON.stringify(this.saigenTouanList[targetMondaiId]));
    let targetSearchString = $event.detail.value;
    
    // 自分の再現答案で、頻出単語に含まれるものを、抽出する
    for (let i_ta in this.saigenTouanTangoArray[targetMondaiId]) {
      let targetTango = this.saigenTouanTangoArray[targetMondaiId][i_ta].tangoString;
      
      if ( targetSearchString.match(targetTango)) {
        //他の人が書いた単語と、検索文字列でかぶる単語を抽出
        //console.log("hit!--", targetTango)
        hitTangoArray.push(targetTango);
      }
    }
    this.hitTangoDictionary[targetMondaiId] = hitTangoArray;

    //キーワード部分にHTMLタグで装飾をつける　＠自分の再現答案の
    this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord = this.markTango(targetSearchString, targetMondaiId, hitTangoArray);

    //キーワード部分にHTMLタグで装飾をつける　＠全員の再現答案
    for (let i_stl in this.saigenTouanList[ targetMondaiId ]){
      //GUIのVIEW用の、再現答案のリストを更新する
      let markedResult = this.markTango(
        this.saigenTouanList[ targetMondaiId ][i_stl]["kaitou"],
        targetMondaiId,
        hitTangoArray
      );
      markedResult = JSON.parse(JSON.stringify(markedResult));//deppCopy
      this.saigenTouanList_view[ targetMondaiId ][i_stl]["kaitou"] = markedResult.str;
      this.saigenTouanList_view[ targetMondaiId ][i_stl]["countSearchKyoutuu"] = markedResult.countSearchKyoutuu;
      //console.log("markedResult.countSearchKyoutuu--",markedResult.countSearchKyoutuu)
      //this.saigenTouanList_view_sorted[ targetMondaiId ][i_stl]["kaitou"] = this.sortSaigenTouanList_view():
    }
    this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord = this.markTango(targetSearchString, targetMondaiId, hitTangoArray)["str"];
    
    //全員の再現答案（表示用）を、自分の再現答案と被った単語の数で、並べ替える
    
    //得点順にソート
    this.saigenTouanList_view[ targetMondaiId ].sort(function (a, b) {
      if (a.countSearchKyoutuu < b.countSearchKyoutuu) return 1;
      if (a.countSearchKyoutuu > b.countSearchKyoutuu) return -1;
      return 0;
    });


    // //一通り頻出単語にマークを付け終わったので、まとめてHTMLタグに変換する（ここでやらずループ内で置換するとタグ内の数字なども置換してしまうため）
    // console.log("tmpHighlightMarkedSaigenTouan--", tmpHighlightMarkedSaigenTouan)
    // this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord = tmpHighlightMarkedSaigenTouan.replace( new RegExp(" 【 ","g") , "<span style='background-color:#ddffee;color:rgb(3, 3, 3);border : 0px solid rgb(24, 24, 24);border-radius: 0.5em 0.5em 0.5em 0.5em ;padding-top: 0.5em; padding-right: 0.5em; padding-bottom: 0.5em;padding-left: 0.5em;' align='center'>" ).replace( new RegExp(" 】 ","g") , "</span>");
    // console.log("this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord--", this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord)

    // this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord = tmpHighlightedHtml.replace( new RegExp("&lt;","g") , "<" ).replace( new RegExp("&gt;","g") , ">");//innerHtmlを本来のHTMLになるよう記号を置換
    // console.log("tmpHighlightedHtml--", tmpHighlightedHtml)
    // console.log("this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord--", this.currentGuiStatus.currentKaitou[targetMondaiId].highLightedSearchSaigenTouanKeyWord)

    //console.log("this.hitTangoDictionary--", this.hitTangoDictionary)
    // let html = "Lorem ipsum dolor span sit amet, consectetuer <span class='dolor'>dolor</span> adipiscing elit.";
    //alert( this.replaceWords(html, ["dolor"]) );
  
  }

  // ippatuReplace(){
  //   var str = "I have a cat, a dog, and a goat.";
  //   var mapObj = {
  //     cat:"dog",
  //     dog:"goat",
  //     goat:"cat"
  //   };
  //   let tmpRe = new RegExp("cat|dog|goat", 'gi');//
  //   /cat|dog|goat/gi
  //   str = str.replace(tmpRe, function(matched){//(/cat|dog|goat/gi, function(matched){
  //     return mapObj[matched];
  //   });
  //   console.log("str--",str)
  // }

  markTango(targetSearchString:string, targetMondaiId:number, hitTangoArray:string[]){
    let str = targetSearchString;//"<span style='font-size: "+ this.currentGuiStatus.mondaiFontSize + "%;'>" + targetSearchString + "</span>";//"I have a cat, a dog, and a goat.";
    let reString = "";
    let mapObj = {};
    let countSearchKyoutuu = 0;
    for (let i_ta in this.saigenTouanTangoArray[targetMondaiId]) {
      countSearchKyoutuu = 0;

      //自分が書いた単語を、書いていたかどうかカウントする処理
      for (let i_hta in hitTangoArray) {
        let targetTango = hitTangoArray[i_hta];
        if ( targetSearchString.match(targetTango)) {//自分が書いた単語が、検索文字（主に他の人の再現答案の文字列）に含まれる場合は、カウントを１ふやす
          countSearchKyoutuu = countSearchKyoutuu + 1;
        }
      }
      
      //再現答案のハイライト処理
      //置換対象の文字列RE用の文字列を作成
      let tmpTangoString= this.saigenTouanTangoArray[targetMondaiId][i_ta].tangoString;
      reString = reString + "|" + tmpTangoString;

      //置換後の文字列のDicを作成
      let badgeColorString = "rgba(240, 65, 65, 0.2)";
      //console.log("targetSearchString--",targetSearchString)
      if (hitTangoArray.includes(tmpTangoString)){
        //自分の再現答案に書かれていた単語の場合は、バッジの色を緑にする
        badgeColorString = "rgba(16, 220, 96, 0.5)";
        
      }
      
      mapObj[tmpTangoString] = "<span style='background:linear-gradient(transparent 0%,  " + badgeColorString + " 30%,  " + badgeColorString + " 70%, transparent 100%);font-size: 1em;'>"+tmpTangoString+"</span><span style='background-color:  " + badgeColorString + ";border : 0px solid " + badgeColorString + ";border-radius: 0em 2em 2em 2em ;vertical-align: sub;font-size: 1em;color: #ffffff;'>&nbsp;" + this.saigenTouanTangoArray[targetMondaiId][i_ta].count + "&nbsp;</span>";

    }
    reString = reString.slice(1);//置換対象の文字列RE用の文字列から、先頭のゴミの|を削除

    // var mapObj = {
    //   cat:"dog",
    //   dog:"goat",
    //   goat:"cat"
    // };
    let tmpRe = new RegExp(reString, 'gi');//
    str = str.replace(tmpRe, function(matched){//(/cat|dog|goat/gi, function(matched){
      return mapObj[matched];
    });
    // console.log("mapObj--",mapObj)
    // console.log("str--",str)
    const returnObj = {"str":str,"countSearchKyoutuu":countSearchKyoutuu}
    return returnObj;//str;
  }

  // markSaigenTouanHinsyutuTango(targetSearchString:string, targetMondaiId:number, hitTangoArray:string[]){
  //   //再現答案文字列に含まれる、頻出単語をハイライトして、出現回数をバッジ表示する、HTML文字列を返す
  //   let returnHtmlString = ">"+targetSearchString+"<";//最初はタグがないので、全部囲ませる
    
  //   //（１）頻出単語に【】をつける
  //   for (let i_ta in this.saigenTouanTangoArray[targetMondaiId]) {
  //     let targetTango = this.saigenTouanTangoArray[targetMondaiId][i_ta].tangoString;
  //     returnHtmlString = this.kakkoSaigenTouanHinsyutuTango(returnHtmlString, targetTango);
  //   }

  //   //（２）カッコをHTMLタグに置換する。
  //   //
  //   //returnHtmlString = this.addHinsyutuTangoKaisuuBadge();
    
  //   //（３）タグ内の文字列をスキップして置換できる関数を使って、書かれた回数を付記する。回数のデフォルト背景色は赤で、hitTangoArrayに入っていればかけている単語なので緑にする。
    
  //   return returnHtmlString;
  // }

  // kakkoSaigenTouanHinsyutuTango(targetSearchString: string, targetTango: string){
  //   //再現答案で、皆が書いている単語は大きく表示するHTMLタグをつける
  // //hightLightKeyword(honbun: string, targetKeyWordArr: string[]): string {
  //   let markedString: string = targetSearchString;//最初はタグがないので、全部囲ませる
  //   //console.log("targetKeyWordArr",targetKeyWordArr)
  //     //const beforeString = /^(targetTango)(>.*?<)$/;//new RegExp(/targetTango/, 'g');//対象の単語かつタグ外を検索//targetKeyWordArr[i_tkw]/;
  //     let regStr = "^("+targetTango+")(>.*?<)$";
  //     const beforeString = new RegExp(regStr, 'g');//
      
  //     // let afterString = "<font color=#aaffbb><span style='background-color:#feffff'>\n" + targetTango + '</span></font>';
  //     let afterString = " 【 " + targetTango + ' 】 ';

  //     markedString = markedString.replace(beforeString, afterString);
  //   return markedString;
  // }

  // highLightSaigenTouanHinsyutuTango(targetSearchString: string, targetTango: string, tangoWeight: number){
  //   //再現答案で、皆が書いている単語は大きく表示するHTMLタグをつける
  // //hightLightKeyword(honbun: string, targetKeyWordArr: string[]): string {
  //   let highLightedString: string = targetSearchString;
  //   //console.log("targetKeyWordArr",targetKeyWordArr)
  //     const beforeString = new RegExp(targetTango, 'g');///targetKeyWordArr[i_tkw]/;
  //     // let afterString = "<font color=#aaffbb><span style='background-color:#feffff'>\n" + targetTango + '</span></font>';
  //     let afterString = " <u><i>" + targetTango + '<i></u> ';

  //     highLightedString = highLightedString.replace(beforeString, afterString);
  //   return highLightedString;
  // }

  // //HTML TAG内の文字を置換から除外できる関数群だが、置換後のHTMLに、4などの置換対象の文字が含まれている場合にサイド置換されてだめだったのでコメントアウト
  // // Reusable generic function
  // traverseElement(targetString, el, regex, textReplacerFunc) {
  //   // script and style elements are left alone
  //   if (!/^(script|style)$/.test(el.tagName)) {
  //       var child = el.lastChild;
  //       while (child) {
  //           if (child.nodeType == 1) {
  //             this.traverseElement(targetString, child, regex, textReplacerFunc);
  //           } else if (child.nodeType == 3) {
  //               textReplacerFunc(targetString, child, regex);
  //           }
  //           child = child.previousSibling;
  //       }
  //   }
  // }

  // // This function does the replacing for every matched piece of text
  // // and can be customized to do what you like
  // textReplacerFunc(text, textNode, regex ) {
  //   textNode.data = textNode.data.replace(regex, "<4><4>");//" <font color=#25ffbb><span style='background-color:#fe44ff'>"+text+"</span></font> ");
  // }

  // // The main function
  // replaceWords(html, words, tangoWeight: number) {
  //   var container = document.createElement("div");
  //   container.innerHTML = html;

  //   // Replace the words one at a time to ensure each one gets matched
  //   for (var i = 0, len = words.length; i < len; ++i) {
  //     this.traverseElement(words[i], container, new RegExp(words[i], "g"), this.textReplacerFunc);
  //   }
  //   // let unEscapedStr = container.innerHTML.replace( "&lt;" , "<" ).replace( "&gt;" , ">");
  //   // console.log("container.innerHTML--", container.innerHTML)
  //   // console.log("container.outerHTML--", container.outerHTML)
  //   return container.innerHTML;//outerHTML;//unEscapedStr;//
  // }



  

  // async doRest_getDeafultBunsekiGazou(mondaiId: number, myName: string, createNewDefaultGazouFlag: boolean){
  //   //デフォルトの、WCDerdroの画像を取得する
  //   this.todoService.getDeafultBunsekiGazou(mondaiId,  myName, createNewDefaultGazouFlag)
  //   .then(
  //     data => 
  //     this.currentGuiStatus.saigenTouanTeikyouResult[mondaiId] = JSON.parse(data)
  //   )
  // }

  // getsaigenTouanTeikyouResult(mondaiId: number){
  //   //採点答案提供の結果をローカルに保存した場所から読み出す関数
    
  //   setTimeout(() => {
  //     //console.log('hello')
  //     if (!this.currentGuiStatus.saigenTouanTeikyouResult[mondaiId]){
  //       //データがまだない場合は、REST送る
  //       this.saigenTouanTeikyou(mondaiId, '', '');
  //     }
  //     setTimeout(() => {
  //       //console.log('callback')
  //       return this.currentGuiStatus.saigenTouanTeikyouResult[mondaiId];
  //     }, 1000)
  //   }, 1000)

  // }


  hikaku(cMData: Mondai, sentakushi_to_select: string, mode: string, ) {
    //再現答案を送信し、RESTから帰ってきたデータで、scatterを作図するメソッド
    
    setTimeout(() => {
      let targetMondaiId = cMData.mondaiId;
      //let targetMondaiSyuId = cMData.mondaiSyuId;
      //this.setCurrentMondai(targetMondaiSyuId, targetMondaiId);
      let kaitouStr: string = this.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku;

      //ゴミ削除
      if (kaitouStr == "" || kaitouStr == null) {
        kaitouStr = "空欄"
      }
      kaitouStr = kaitouStr.replace(/(\r\n|\n|\r)/gm, ""); //改行削除
      kaitouStr = this.zenkaku2hankaku(kaitouStr); //全角英数字の半角化

      let changedSaitenVec = "";
      if (this.currentGuiStatus.kaitoubun2jiChangedFlag == true) {
        changedSaitenVec = ""
      } else if (this.currentGuiStatus.saitenKekka2jiChangedFlag == true) {
        //採点結果を書き換えている場合のみ、補正したベクトルを送信する
        if (this.currentGuiStatus.saitenKekka2ji_model[targetMondaiId]) {
          //初回起動時はNullの場合があるので分岐
          changedSaitenVec = JSON.parse(JSON.stringify(this.currentGuiStatus.saitenKekka2ji_model[targetMondaiId]))
        }

        //changedSaitenVec = this.firestoreAccessor.convertSaitenKekka2ji(cMData.mondaiId,true) ;
      }


      this.kaitouBun = {
        "mondaiId": cMData.mondaiId,
        "nendo": cMData.nendo,
        "mondaiSyuId": cMData.mondaiSyuId,
        "kaitouBunStr": kaitouStr,//this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku
        "myName": this.currentGuiStatus.myName,
        "scatterSettings": this.scatterSettings,
        "changedSaitenVec": changedSaitenVec,
        "gouhi_jouhou": this.currentGuiStatus.gouhi_jouhou[this.getMondaiIdSentou5(this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId)]//this.firestoreAccessor.currentGuiStatus.gouhi_jouhou,
      }

      setTimeout(() => {
        //console.log('callback')
        //this.innerState = "" + targetMondaiId;

        this.todoService
          .create(this.kaitouBun)
          .then(
            data => this.scatterDataAll = data[0]
          )
          // .then(
          //   data =>
          //     console.log("1111--")
          // )
          .then(
            data => this.kaitouHistory2ji[cMData.mondaiId] = JSON.parse(this.scatterDataAll["kaitouDf"])
          )
          .then(
            data =>
              this.currentGuiStatus.saitenKekka2ji_model[cMData.mondaiId] = JSON.parse(this.scatterDataAll["saitenKekka2ji"])[0]//
          )
          .then(
            data =>
              this.currentGuiStatus.saitenKekka2ji_view[cMData.mondaiId] = JSON.parse(this.scatterDataAll["saitenKekka2ji"])[0]//
          )
          // .then(
          //   data =>
          //   this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[cMData.mondaiId] = this.firestoreAccessor.convertSaitenKekka2ji(cMData.mondaiId) //this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[targetMondaiId]//
          // )
          .then(
            data =>
              //console.log("AAAA--"+this.scatterDataAll["rankingDataArr"])
              this.generateRankingData(cMData.mondaiId)
          )
          .then(
            data =>
              this.generateScatterTangoRanking(1, this.scatterDataAll["pc1Df"])
          )
          .then(
            data =>
              this.generateScatterTangoRanking(2, this.scatterDataAll["pc2Df"])
          )
          .then(
            data =>
              this.currentGuiStatus.scatterValue[cMData.mondaiId] = [
                {//'合否不明'
                  x: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[0]["visible"],
                    "zahyouXarr"
                  ),
                  y: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[0]["visible"],
                    "zahyouYarr"
                  ),
                  text: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[0]["visible"],
                    "kaitou"
                  ),
                  type: 'scatter',
                  name: this.scatterSettings[0]["name"],
                  mode: 'markers',
                  textposition: 'bottom center',
                  marker: {
                    size: 10,
                    color: "rgba(10,15,20,0.5)",
                    symbol: this.scatterSettings[0]["symbol"],
                  },
                  hovertemplate: '%{text}',
                },
                {//合否ご提供有り
                  x: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[1]["visible"],
                    "zahyouXarr"
                  ),
                  y: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[1]["visible"],
                    "zahyouYarr"
                  ),
                  text: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[1]["visible"],
                    "kaitou"
                  ),
                  type: 'scatter',
                  name: this.scatterSettings[1]["name"],
                  mode: 'markers',
                  textposition: 'bottom center',
                  marker: {
                    colorscale: "Viridis",
                    showscale: true,
                    color: this.getScatterDataArrFromKaitouHistory2ji(
                      cMData.mondaiId,
                      this.scatterSettings[1]["visible"],
                      "tokuten"
                    ),
                    size: this.generateScatterSizeSymbolArr(
                      cMData.mondaiId,
                      this.scatterSettings[1]["visible"],
                      "size"
                    ),
                    symbol: this.generateScatterSizeSymbolArr(
                      cMData.mondaiId,
                      this.scatterSettings[1]["visible"],
                      "symbol"
                    ),
                  },
                  hovertemplate: '%{text}',
                },
                {//予備校
                  x: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[2]["visible"],
                    "zahyouXarr"
                  ),
                  y: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[2]["visible"],
                    "zahyouYarr"
                  ),
                  text: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[2]["visible"],
                    "kaitou"
                  ),
                  type: 'scatter',
                  name: this.scatterSettings[2]["name"],
                  mode: 'markers',
                  textposition: 'bottom center',
                  marker: {
                    size: 12,
                    color: "rgba(10,15,20,0.8)",
                    symbol: this.scatterSettings[2]["symbol"],
                  },
                  hovertemplate: "予備校公式サイトでご確認下さい",//'%{text}',
                },
                {//'あなたの解答',
                  x: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[3]["visible"],
                    "zahyouXarr"
                  ),
                  y: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[3]["visible"],
                    "zahyouYarr"
                  ),
                  text: this.getScatterDataArrFromKaitouHistory2ji(
                    cMData.mondaiId,
                    this.scatterSettings[3]["visible"],
                    "kaitou"
                  ),
                  type: 'scatter',
                  name: this.scatterSettings[3]["name"],
                  mode: 'markers',
                  textposition: 'bottom center',
                  marker: {
                    color: "rgba(250,70,70,0.8)",
                    size: 20,
                    symbol: this.scatterSettings[3]["symbol"],
                  },
                  hovertemplate: '%{text}',
                },
              ]
          )
          // .then(
          //   data => this.plotScatter(cMData)
          // )

          // .then(
          //   data =>
          //     this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId] = [{
          //       x: [20],
          //       y: [''],
          //       name: 'SF Zoo',
          //       type: 'bar',
          //       orientation: 'h'
          //     }, {
          //       x: [20],
          //       y: [''],
          //       name: 'SF Zoo',
          //       type: 'bar',
          //       orientation: 'h'
          //     },

          //     ]
          // )
          .then(
            data =>
              //解答欄の更新チェックフラグを戻す
              this.currentGuiStatus.kaitoubun2jiChangedFlag = false
          )
          .then(
            data =>
              //採点の更新チェックフラグを戻す
              this.currentGuiStatus.saitenKekka2jiChangedFlag = false
          )
          .then(
            data =>
              //kaitouHistory2jiをDBに保存する
              this.backUpObjectToDB('kaitouHistory2ji', this.kaitouHistory2ji, true)
          )
          .then(
            data =>
              this.createTouanSuuBarChartData(cMData)
            //this.plotTouanSuuBarChart(cMData)
          )
          .then(
            data =>
              this.createJunniRaderChartData(cMData)
          )
          // .then(
          //   data =>
          //     this.plotJunniRaderChart(cMData)
          // )
          .then(
            data =>
              this.createGoodBadData(cMData)
          )
          .then(
            data =>
              this.barChartAccordionOpenTargetMondaiId = "default"//再現答案書き換えた後にボタンオスと棒グラフが消えるので、いっそのことアコーディオン閉じることにした
          )
          .then(
            data =>
              //CurrentGUIをDBに保存する
              this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus, true)
          )



        //Kaitouの末尾をメモリ上のKaitouで上書きして、KaitouをDBに保存する
        this.kaitouHistory[cMData.mondaiId][this.kaitouHistory[cMData.mondaiId].length - 1] = JSON.parse(JSON.stringify(this.currentGuiStatus.currentKaitou[cMData.mondaiId]));
        this.backUpObjectToDB('kaitouHistory', this.kaitouHistory, true);
        //console.log("0011--")

        //操作ログの書き込み（処理後）
        this.writeOperationLog(cMData.mondaiSyuId, cMData.mondaiId, 'hikaku()', true);
        //console.log("0022--")
      }, 0)
    }, 0)
    
  }

  init_gouhi_jouhou(){
    //起動時に合否情報をまるごとRESTでとってくる関数
    this.todoService
    .create_gouhi_jouhou(null,this.currentGuiStatus.myName)//初期化時は、合否情報NULLでRESTにPOSTすると、合否情報の全レコードがRESTから帰ってくる。
    .then(
      data => this.gouhiJouhouAll = JSON.parse(data)
    )
    // .then(
    //   data =>
    //     console.log("this.gouhiJouhouAll--"+this.gouhiJouhouAll)
    // )
    .then(
      data => 
      this.createGouhiJouhouTableData()
    )
  }
  
  createGouhiJouhouTableData() {
    let cMData: Mondai = this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab];
    let targetNendo = cMData.nendo;
    if (!this.currentGuiStatus.gouhiJouhouTableData[targetNendo]) {
      //null埋め
      this.currentGuiStatus.gouhiJouhouTableData[targetNendo] = {};
    }
    
    //合否情報を表示用に加工して、CurretGuiに格納する関数
    for (var i_jma in this.jireiMondaiSyuIdArr) {
      let targetMondaiSyuId = this.jireiMondaiSyuIdArr[i_jma];
      if (!this.currentGuiStatus.gouhiJouhouTableData[targetNendo][targetMondaiSyuId]) {
        //null埋め
        this.currentGuiStatus.gouhiJouhouTableData[targetNendo][targetMondaiSyuId] = {};
      }
      for (var i_hsa in this.hyoukaStrArr) {
        let targetGouhi = this.hyoukaStrArr[i_hsa];
        if (!this.currentGuiStatus.gouhiJouhouTableData[targetNendo][targetMondaiSyuId]) {
          //null埋め
          this.currentGuiStatus.gouhiJouhouTableData[targetNendo][targetMondaiSyuId][targetGouhi] = {};
        }

        let tmpGouhiJouhou: GouhiJouhou[] = JSON.parse(JSON.stringify(this.gouhiJouhouAll));

        let targetGouhiJouhou: GouhiJouhou[] = tmpGouhiJouhou.filter(data => {
          return (data.nendo == targetNendo && data.mondaiSyuId == targetMondaiSyuId && data.gouhi == targetGouhi);
        });
        //console.log("targetGouhiJouhou"+targetGouhiJouhou)
        this.currentGuiStatus.gouhiJouhouTableData[targetNendo][targetMondaiSyuId][targetGouhi] = targetGouhiJouhou.length;
      }
    }

    //提供ありがとうございましたのデータもここで作成する
    this.currentGuiStatus.thanksListArray = [];
    //得点公開可能な名前のリストを作成する
    let tmpGouhiJouhou: GouhiJouhou[] = JSON.parse(JSON.stringify(this.gouhiJouhouAll));
    //let handleNameList: string[] = [];
    let myNameList: string[] = [];

    //公開OKかつ、対象年度レコードのみ取得
    let targetGouhiJouhou_forTokuten: GouhiJouhou[] = tmpGouhiJouhou.filter(data => {
      return (data.nendo == targetNendo && data.tokutenKoukai == true);
    });

    for (var i_tgjh in targetGouhiJouhou_forTokuten) {
      //名前取得
      //handleNameList.push(targetGouhiJouhou_forTokuten[i_tgjh].handleName)
      myNameList.push(targetGouhiJouhou_forTokuten[i_tgjh].myName)
    }
    let uniqueNameArray = myNameList.filter(function (item, pos) {
      return myNameList.indexOf(item) == pos;
    })

    for (var i_una in uniqueNameArray) {
      //名前のリスト順に、該当するレコードの得点を加算していく
      let oneHandleGouhiJouhou: GouhiJouhou[] = targetGouhiJouhou_forTokuten.filter(data => {
        return (data.myName == uniqueNameArray[i_una]);
      });
      let tmpTokuten: number = 0;
      for (var i_ogj in oneHandleGouhiJouhou){
        //得点を合算する
        //ABCDの場合は０点のままにする。端数で見分ける
        if (oneHandleGouhiJouhou[i_ogj].tokuten == Math.round(oneHandleGouhiJouhou[i_ogj].tokuten)){
          tmpTokuten = tmpTokuten + Math.round(oneHandleGouhiJouhou[i_ogj].tokuten);
        }else{
          tmpTokuten = 0;
        }
        //tmpTokuten = tmpTokuten + Math.round(oneHandleGouhiJouhou[i_ogj].tokuten);
      }

      //myNameをHandleに変換する
      let targetGouhiJouhou_forHandle: GouhiJouhou[] = tmpGouhiJouhou.filter(data => {
        return (data.myName == uniqueNameArray[i_una]);
      });
      let targetHandleName:string = targetGouhiJouhou_forHandle[0].handleName;

      let thanksItem = {
        handleName:targetHandleName,//uniqueNameArray[i_una],
        goukeiTokuten:tmpTokuten,
      }
      this.currentGuiStatus.thanksListArray.push(thanksItem);
    }
    //得点順にソート
    this.currentGuiStatus.thanksListArray.sort(function (a, b) {
      if (a.goukeiTokuten < b.goukeiTokuten) return 1;
      if (a.goukeiTokuten > b.goukeiTokuten) return -1;
      return 0;
    });


    this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);
  }

  
  tokutenFilledCheck():number {
    //GouhiJouhouのSelectを全部埋めているかチェックする
    //年度を取得し、年度内の全事例のGouhiJouhouを確認する
    //let returnBool: boolean = false;
    let returnNum:number = 0;
    let targetNendo = this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].nendo;

    let searchedGouhi = []

    for (var i_jma in this.jireiMondaiSyuIdArr) {
      let targetMondaiSyuId = this.jireiMondaiSyuIdArr[i_jma];

      let tmpGouhiJouhou: GouhiJouhou[] = JSON.parse(JSON.stringify(this.gouhiJouhouAll));

      let targetGouhiJouhou: GouhiJouhou[] = tmpGouhiJouhou.filter(data => {
        return (data.nendo == targetNendo && data.mondaiSyuId == targetMondaiSyuId && data.myName == this.currentGuiStatus.myName);
      });
      if (targetGouhiJouhou.length != 0){
        searchedGouhi.push(targetGouhiJouhou);
      }
    }
    returnNum = searchedGouhi.length;
    // if (searchedGouhi.length > 3) {
    //   //４事例とも存在すれば、Trueを返す
    //   returnBool = true;
    // }
    return returnNum;
  }

  handleNameChanged(){
    //画面のHN変わったら、ローカルのHNも全科目書き換えて、REST投げる
    for (let i_gjo in this.currentGuiStatus.gouhi_jouhou){
      this.currentGuiStatus.gouhi_jouhou[i_gjo].handleName = this.currentGuiStatus.handleName;
    }
    
    this.gouhiSelected(this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId);
  }

  gouhiSelected(mondaiId:number){
    //let targetGouhiJouhou = this.currentGuiStatus.gouhi_jouhou[this.getMondaiIdSentou5(this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId)];
    let targetGouhiJouhou = this.currentGuiStatus.gouhi_jouhou[this.getMondaiIdSentou5(mondaiId)];
    let targetGouhiView = targetGouhiJouhou.gouhiView;
    if (targetGouhiView == "合格" || targetGouhiView == "A"|| targetGouhiView == "B"|| targetGouhiView == "C"|| targetGouhiView == "D"){
      //合格ABCDなら、gouhiをそのまま格納し、tokutenは引いた値を入れる
      targetGouhiJouhou.gouhi = targetGouhiView;
      targetGouhiJouhou.tokuten = this.tokutenTable[targetGouhiView];
      
      targetGouhiJouhou.visible = "gouhiKaitouZumi";
      //RESTに値をPOSTする
      //this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
    } else if (targetGouhiView == "受験していない"){
      //受験していないなら、gouhiViewそのまま格納し、値をデフォルト値に戻す
      targetGouhiJouhou.gouhi = targetGouhiView;
      targetGouhiJouhou.visible = "student";
      targetGouhiJouhou.tokuten = null;
      // //RESTに値をPOSTする
      // /this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
    } else if ( -1 < targetGouhiView && targetGouhiView < 101){
      //数字なら、tokutenをそのまま格納し、gouhiに引いた文字列を入れる
      targetGouhiJouhou.tokuten = targetGouhiView
      if ( targetGouhiView < 40){
        targetGouhiJouhou.gouhi = "D";
      } else if (
        40 <= targetGouhiView && targetGouhiView < 50
        ){
          targetGouhiJouhou.gouhi = "C";
      } else if (
        50 <= targetGouhiView && targetGouhiView < 60
        ){
          targetGouhiJouhou.gouhi = "B";
      } else if (
        60 <= targetGouhiView
        ){
          targetGouhiJouhou.gouhi = "A";
      }
      
      targetGouhiJouhou.visible = "gouhiKaitouZumi";
      // //RESTに値をPOSTする
      // this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
    }

    //合否の解答を入力していない場合を除いて、RESTに合否情報をPOST
    if ( targetGouhiView == ""){
    } else {
      //RESTに値をPOSTする
      this.todoService.create_gouhi_jouhou(targetGouhiJouhou,this.currentGuiStatus.myName)
      .then(
        //合否情報を更新したので、表のデータも更新する
        data => this.gouhiJouhouAll = JSON.parse(data)
      )
      .then(
        data => 
        this.createGouhiJouhouTableData()
      );
    }
    
    this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);
    
    //console.log("this.firestoreAccessor.currentGuiStatus"+JSON.stringify(this.firestoreAccessor.currentGuiStatus));//("hi-------------")//
  }

  getMondaiIdSentou5(targetMondaiId : number){
    //問題IDの先頭5文字を返す
    return String(targetMondaiId).slice( 0, 5 );
  }

  zenkaku2hankaku(str:string):string {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }

  // lowerCase(string:String):String{
  //   return string.toLowerCase();
  // }

  kensakuKeywordSoroeru(targetString:string):String{
    //検索語、被検索語の記述を揃える
    //全角、半角　変換
    let returnString : String = this.zenkaku2hankaku(targetString);
    //大文字小文字変換
    returnString = returnString.toLowerCase();
    return returnString;
  }

  generateScatterTangoRanking(pcNum: number, pcDfString: string) {
    //散布図で、X軸とY軸に影響が大きい単語のランクを取得する
    let pcDf = JSON.parse(pcDfString);

    if( !this.currentGuiStatus.scatterTangoRanking[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId] ){
      //変数がカラなら枠を作る
      this.currentGuiStatus.scatterTangoRanking[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId] = [];
    }

    // Create items array
    var items = Object.keys(pcDf).map(function(key) {
      return [key, pcDf[key]];
    });

    // Sort the array based on the second element
    items.sort(function(first, second) {
      return second[1] - first[1];
    });

    let pcDfStart = JSON.parse(JSON.stringify(items.slice(0, 10)));
    let pcDfEnd = JSON.parse(JSON.stringify(items.slice(-10)));

    //console.log("DDD"+JSON.stringify(pcDfStart));

    if (pcNum == 1) {
      //右はし。上から5つ
      this.currentGuiStatus.scatterTangoRanking[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][0] = pcDfStart;

      //左はし。下から5つ
      this.currentGuiStatus.scatterTangoRanking[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][1] = pcDfEnd;

    } else if (pcNum == 2) {

      //上はし。上から5つ
      this.currentGuiStatus.scatterTangoRanking[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][2] = pcDfStart;

      //下はし。下から5つ
      this.currentGuiStatus.scatterTangoRanking[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][3] = pcDfEnd;

    }

  }

  createTouanSuuBarChartData(cMData: Mondai) {
    //
    
    //１．順序を作る
    this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId] = [];

    for (var saitenKijunItem in this.currentGuiStatus.saitenKekka2ji_model[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId]) {
      //tmpSaitenKijunArr.push(saitenKijunItem);
      //グラフデータ格納する
      let countGoukaku: number = this.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["合格", 'A']).length;
      let countFugoukaku: number = this.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["不合格", 'B', 'C', 'D']).length;

      //let countKaitenai: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["合格", 'A', 'B', 'C', 'D']).length;
      
      let countGoukaku_Kaketenai: number = this.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["合格", 'A']).length;
      let countFugoukaku_Kaketenai: number = this.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["不合格", 'B', 'C', 'D']).length;

      let countAll: number = countGoukaku + countFugoukaku + countGoukaku_Kaketenai + countFugoukaku_Kaketenai;

      let dicToAppend = {
        key: saitenKijunItem,
        countGoukaku: countGoukaku,
        countFugoukaku: countFugoukaku,
        //countKaitenai: countKaitenai,

        countGoukaku_Kaketenai: countGoukaku_Kaketenai,
        countFugoukaku_Kaketenai: countFugoukaku_Kaketenai,

        countAll: countAll,
      };

      this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].push(dicToAppend);
    }

    //ソートする
    this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].sort(function (a, b) {
      if (a.countGoukaku + a.countFugoukaku < b.countGoukaku + b.countFugoukaku) return 1;
      if (a.countGoukaku  + a.countFugoukaku > b.countGoukaku + b.countFugoukaku) return -1;
      if (a.countGoukaku < b.countGoukaku) return 1;
      if (a.countGoukaku > b.countGoukaku) return -1;
      return 0;
    });

    //２．順序通りグラフデータ作る
    for (var i_gtt in this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {
      //
      let targetGaitouTouanSuuData = this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_gtt];

      //console.log("this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]-- "+this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId])
      //console.log("targetGaitouTouanSuuData-- "+ JSON.stringify(targetGaitouTouanSuuData))

      targetGaitouTouanSuuData.barChartData = [
        {
          x: [targetGaitouTouanSuuData.countGoukaku / targetGaitouTouanSuuData.countAll * 100],//[20],
          y: [''],
          name: '合格A[%]',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: "#ffff88",//'rgba(64, 219, 82, 0.95)',
            line: {
              color: 'rgba(255, 255, 255, 1.0)',
              width: 1
            }
          },
          hoverlabel: { bgcolor: "#ffff88" },
          hovertemplate: '合格A<BR> %{x:,.0f}%<extra></extra>',
          // text: [countGoukaku],
          // font: {
          //   size: 14,
          //   color: 'rgba(245,246,249,1)'
          // },
        },
        {
          x: [targetGaitouTouanSuuData.countFugoukaku / targetGaitouTouanSuuData.countAll * 100],
          y: [''],
          name: '不合格BCD[%]',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: "#c7c7ff",//'rgba(219, 64,  82, 0.95)',
            line: {
              color: 'rgba(255, 255, 255, 1.0)',
              width: 1
            }
          },
          hoverlabel: { bgcolor: "#c7c7ff" },
          hovertemplate: '不合格<BR>BCD<BR> %{x:,.0f}%<extra></extra>',
        },
        {
          x: [(targetGaitouTouanSuuData.countGoukaku_Kaketenai ) / targetGaitouTouanSuuData.countAll * 100],
          y: [''],
          name: '合格A記述なし[%]',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: "#fcfcfc",//'rgba(40, 40,  40, 0.95)',
            line: {
              color: '#eeeeee',
              width: 1
            }
          },
          hoverlabel: { bgcolor: "#f3f3f3" },
          hovertemplate: '記述<BR>なし<BR>@合格A<BR> %{x:,.0f}%<extra></extra>',
        },
        {
          x: [(targetGaitouTouanSuuData.countFugoukaku_Kaketenai) / targetGaitouTouanSuuData.countAll * 100],
          y: [''],
          name: '不合格BCD記述なし[%]',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: "#f5f5f5",//'rgba(40, 40,  40, 0.95)',
            line: {
              color: '#eeeeee',
              width: 1
            }
          },
          hoverlabel: { bgcolor: "#f3f3f3" },
          hovertemplate: '記述<BR>なし<BR>@不合格<BR>BCD<BR> %{x:,.0f}%<extra></extra>',
        }
      ]
    }
    

  }

  createJunniRaderChartData(cMData: Mondai) {
    //CurrentGuiにRaderのチャートデータ作って格納

    //該当の問題の、設問の数だけ、順位とか名前を所得して配列に格納する
    let tmpR = [];
    let tmpTheta = [];

    let tmpR_A = [];
    let tmpR_B = [];

    // let i_mondaiId in this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].daimonList

    let saikaiNum = 0;//全問題で最下位の数字を保持する。未回答の問題の順位埋めに使うため
    for (let i_mondaiId in cMData.daimonList){
      let tmpMondaiId = cMData.daimonList[i_mondaiId];
      tmpR.unshift(this.getRankingTopPangeHyouji(tmpMondaiId).junni);
      tmpTheta.unshift(this.getOneMondaiFromFile(tmpMondaiId).name)
      if (saikaiNum < this.getRankingTopPangeHyouji(tmpMondaiId).ninzuu){
        //
        saikaiNum = this.getRankingTopPangeHyouji(tmpMondaiId).ninzuu
      }

      //A答案の平均順位を取得する
      tmpR_A.unshift(this.getHeikinJunni(tmpMondaiId,"A"));
      tmpR_B.unshift(this.getHeikinJunni(tmpMondaiId,"B"));
    }

    //未回答の問題は最下位の順位の数字に置換する
    for (let i_tr in tmpR){
      if (tmpR[i_tr] == 0){
        //
        tmpR[i_tr] =saikaiNum
      }
    }
      //tmpR = tmpR.map(function(x){ return x.replace(0,saikaiNum) });

    //Raderが一周するよう追加
    tmpR.unshift(this.getRankingTopPangeHyouji(cMData.daimonList[0]).junni);
    tmpTheta.unshift(this.getOneMondaiFromFile(cMData.daimonList[0]).name);
    tmpR_A.unshift(this.getHeikinJunni(cMData.daimonList[0],"A"));
    tmpR_B.unshift(this.getHeikinJunni(cMData.daimonList[0],"B"));

    this.currentGuiStatus.junniRaderChartData[this.getMondaiIdSentou5(cMData.mondaiId)] = 
    [
      {
        type: 'scatterpolar',
        r: tmpR,//[10, 11, 8, 4, 12, 10],
        theta: tmpTheta,//['A','B','C', 'D', 'E', 'A'],
        //fill: 'toself',
        name: 'あなたの解答',
        hovertemplate: '%{r:,r}位<extra></extra>',
        line: {color: '#000000'}
        },
        
        {
        type: 'scatterpolar',
        r: tmpR_A,//[2.2, 3.3, 3.3, 4.4, 5.5, 2.2],
        theta: tmpTheta,//['A','B','C', 'D', 'E', 'A'],
        //fill: 'toself',
        name: 'A答案平均',
        hovertemplate: '%{r:,.1f}位<extra></extra>',
        line: {color: '#ffce00'}
        },
        
        {
          type: 'scatterpolar',
          r: tmpR_B,//[2.2, 3.3, 3.3, 4.4, 5.5, 2.2],
          theta: tmpTheta,//['A','B','C', 'D', 'E', 'A'],
          //fill: 'toself',
          name: 'B答案平均',
          hovertemplate: '%{r:,.1f}位<extra></extra>',
          line: {color: '#0cd1e8'}
          }
    ]
    // {
    //   labels: ['Running', 'Swimming', 'Eating', 'Cycling'],
    //   datasets: [{
    //     data: [20, 10, 4, 2]
    //   }]
    // }
  }

  createGoodBadData(cMData: Mondai){
    
    // let wariaiArr = [];
    // let tmpGaitouTouanSuuData = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]));
    // tmpGaitouTouanSuuData.sort(function (a, b) {
    //   if (a.countGoukaku + a.countFugoukaku < b.countGoukaku + b.countFugoukaku) return 1;
    //   if (a.countGoukaku  + a.countFugoukaku > b.countGoukaku + b.countFugoukaku) return -1;
    //   if (a.countGoukaku < b.countGoukaku) return 1;
    //   if (a.countGoukaku > b.countGoukaku) return -1;
    //   return 0;
    // });

    // for (var i_ttt in this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {

    //     //順序の配列を作る
    //     let targetGaitouTouanSuuData = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_ttt];
    //     wariaiArr.push(targetGaitouTouanSuuData);
    // }


    for (var i_gtt in this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {
      //記述有無の０１を、解答率などの、良し悪しのhyouka情報に変換して値を格納する
      let hyoukaArr =  ["",""];
      let hyoukaStr = "";
      let kijunGoukei:boolean =true;//合計Aの数が、しきい値超えかどうか
      let kijunWariai:boolean =true;//合格Aのうち、答えている割合が、不合格BCDのそれより大きいか

      let targetGaitouTouanSuuData = this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_gtt];

      //しきい値判定　＠　解答数
      if(
        Number(i_gtt) > this.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].length * (1/2)
      ){
        //配列の後半にあったら、解答者少ない論点なので
        kijunGoukei = false;
      }

      
      // countGoukaku: countGoukaku,
      // countFugoukaku: countFugoukaku,
      // //countKaitenai: countKaitenai,

      // countGoukaku_Kaketenai: countGoukaku_Kaketenai,
      // countFugoukaku_Kaketenai: countFugoukaku_Kaketenai,

      //しきい値判定　＠　解答率

      let bunboGoukaku = targetGaitouTouanSuuData.countGoukaku + targetGaitouTouanSuuData.countGoukaku_Kaketenai;
      let bunboFugoukaku = targetGaitouTouanSuuData.countFugoukaku + targetGaitouTouanSuuData.countFugoukaku_Kaketenai;
        
      if (
        //合格者が書いている割合より、不合格者のほうが多ければFalse
        (targetGaitouTouanSuuData.countGoukaku / bunboGoukaku) 
        <
        (targetGaitouTouanSuuData.countFugoukaku / bunboFugoukaku)
      ){
        kijunWariai = false;
      }
      // console.log("targetGaitouTouanSuuData.key--"+targetGaitouTouanSuuData.key)
      // console.log("kijunGoukei- "+kijunGoukei)
      // console.log("kijunWariai- "+kijunWariai)

      
      // console.log("targetGaitouTouanSuuData.countGoukaku / bunboGoukaku- "+(targetGaitouTouanSuuData.countGoukaku / bunboGoukaku))
      // console.log("targetGaitouTouanSuuData.countFugoukaku / bunboFugoukaku- "+(targetGaitouTouanSuuData.countFugoukaku / bunboFugoukaku))
      // console.log("")

      if (kijunGoukei){
        if (kijunWariai){
          hyoukaArr = ["Bad","Good"];
          if (this.currentGuiStatus.saitenKekka2ji_model[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
            hyoukaStr = "Good";
          } else {
            hyoukaStr = "Bad";
          }
        }else {
          hyoukaArr = ["","Hmm"];
          if (this.currentGuiStatus.saitenKekka2ji_model[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
            hyoukaStr = "Hmm";
          } else {
            hyoukaStr = "";
          }
        }
      } else {
        if (kijunWariai){
          hyoukaArr = ["","Fine"];
          if (this.currentGuiStatus.saitenKekka2ji_model[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
            hyoukaStr = "Fine";
          } else {
            hyoukaStr = "";
          }
        }else {
          hyoukaArr = ["",""];
          if (this.currentGuiStatus.saitenKekka2ji_model[this.currentGuiStatus.currentMondai[this.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
            hyoukaStr = "";
          } else {
            hyoukaStr = "";
          }
        }
      }

      targetGaitouTouanSuuData.hyoukaArr = hyoukaArr;
      targetGaitouTouanSuuData.hyoukaStr = hyoukaStr;
    }
  }

  
  getBlogCardTag(urlKiji: string): string{
    //const urlKijiStr = currentYougoData.urlKiji[0];
    const tagStr = '<iframe class="hatenablogcard" style="width:100%;height:155px;max-width:680px;" title="'+ urlKiji + '" src="https://hatenablog-parts.com/embed?url=' + urlKiji + '" width="auto" height="auto" frameborder="0" scrolling="no"></iframe>';
    //console.log("currentYougoData-",tagStr)
    return tagStr;
  }


  getYoutubeTag(youtubeUrl: string, width?: number): string{
    let widthSize = 0.7;
    if (width){
      //サイズ指定ない場合はデフォルトのサイズのまま。ある場合は値を差し替え
      widthSize = width;
    }
    
    //動画のID取得
    //v=よりあとを取得
    let video_id = youtubeUrl.split("v=")[1];
    // //Vよりあとの＆より前だけ取得
    // let ampersandPosition = video_id.indexOf("&");
    // if (ampersandPosition != -1) {
    //   video_id = video_id.substring(0, ampersandPosition);
    // }
    if (video_id.indexOf("&t=")){
      //再生位置指定があるときのみ実施する置換
      //末尾のs削除
      // let ampersandPosition = video_id.indexOf("s");
      // if (ampersandPosition != -1) {
      //   video_id = video_id.substring(0, ampersandPosition);
      // }
      video_id = video_id.replace(/s$/, "")
      //再生位置指定の文字列を置換
      video_id = video_id.replace("&t=", "?start=")
    }

    
    //window.document.documentElement.clientHeight * 0.5
    

    const tagStr = '<iframe width="'+window.document.documentElement.clientWidth * widthSize+'" height="'+window.document.documentElement.clientWidth * (widthSize * 0.86)+'" src="https://www.youtube.com/embed/' + video_id + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    //console.log("tagYoutubeStr-",tagStr)

    return tagStr;
  }

  selectSentakushiFromYougo(targeMondai:Mondai,selected: string){
    //YougoのModalの関連問題からでも、解答だけできるようにする（消去法とかはできない）
    //カレントの問題をセットする ＃setMondaiListの抜粋
    this.currentGuiStatus.currentMondai[targeMondai.mondaiSyuId] = targeMondai;
    this.loadLastKaitouHistory(targeMondai.mondaiSyuId, targeMondai.mondaiId,true);//解答の枠を作る

    //解答する。selectSentakushi→updateKaitouの抜粋
    //メモリ上のKaitouの、現在日付を更新
    let todayDate = new Date();
    todayDate.setTime(todayDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
    this.currentGuiStatus.currentKaitou[targeMondai.mondaiId].dateTime = todayDate;

    this.currentGuiStatus.currentKaitou[targeMondai.mondaiId].sentaku = selected;
    
    //fixSaitenKekkaの抜粋
    if (selected == targeMondai.seikai) {
      this.currentGuiStatus.currentKaitou[targeMondai.mondaiId].saitenKekka = true;
    } else {
      this.currentGuiStatus.currentKaitou[targeMondai.mondaiId].saitenKekka = false;
    }
    this.currentGuiStatus.currentKaitou[targeMondai.mondaiId].hint_visible = true;
    this.currentGuiStatus.currentKaitou[targeMondai.mondaiId].dateTime_sentakuFixedAt = this.currentGuiStatus.currentKaitou[targeMondai.mondaiId].dateTime;//採点結果確定時点の日時を保存
    setTimeout(() => {
      //console.log('hello')
      this.reloadAshiatoData();
      setTimeout(() => {
        //console.log('callback')
        this.updateCount_kaitou_and_timer();
      }, 1000)
    }, 1000)
    
    //正誤に応じた応援メッセージをTOAST表示。displaySaitenKekkaToast(targeMondai, selected, operationName)の抜粋
    let toastString = "不正解です"
    if (targeMondai.seikai == selected){
      toastString = "正解です";
    }
    this.presentToastWithOptions(toastString, 4000, 'middle', "medium");

    //persist_updated_kaitouの抜粋。採点結果や解答をDB保存するなど後処理
    this.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus, this.currentGuiStatus.privacy_policy_accepted);

    //Kaitouの末尾をメモリ上のKaitouで上書きして、KaitouをDBに保存する
    this.kaitouHistory[targeMondai.mondaiId][this.kaitouHistory[targeMondai.mondaiId].length - 1] = JSON.parse(JSON.stringify(this.currentGuiStatus.currentKaitou[targeMondai.mondaiId]));
    this.backUpObjectToDB('kaitouHistory', this.kaitouHistory, this.currentGuiStatus.privacy_policy_accepted);

    //操作ログの書き込み（処理後）
    this.writeOperationLog(targeMondai.mondaiSyuId, targeMondai.mondaiId, "selectSentakushiFromYougo" + 'updateKaitou_after', this.currentGuiStatus.privacy_policy_accepted);
  }

  
  async presentToastWithOptions(
    toastMessage: string,
    seconds: number,
    position: string,
    color?: string
  ) {
    let durationNumber = 0;
    let clr = "primary";
    if (seconds != 0) {
      durationNumber = seconds;
    }
    if (color != null) {
      clr = color;
    }

    if (position == "bottom") {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: "✖️",
            role: "cancel",
          },
        ],
        duration: durationNumber,
        position: "bottom",
        color: clr,
        translucent: false,
      });
      toast.present();
    }
    if (position == "middle") {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: "✖️",
            role: "cancel",
          },
        ],
        duration: durationNumber,
        position: "middle",
        color: clr,
        translucent: false,
      });
      toast.present();
    }
    if (position == "top") {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: "✖️",
            role: "cancel",
          },
        ],
        duration: durationNumber,
        position: "top",
        color: clr,
        translucent: false,
      });
      toast.present();
    }
  }


  // convertSaitenKekka2ji(targetMondaiId: number, toRest?: boolean) {
  //   let returnSaitenKekka2ji = JSON.parse(JSON.stringify(this.currentGuiStatus.saitenKekka2ji[targetMondaiId]))
  //   for (var key in returnSaitenKekka2ji) {
  //     if (toRest) {
  //       //REST送付のための変換の場合は、Numから、Boolへ変換
  //       //console.log("this.currentGuiStatus.saitenKekka2ji[targetMondaiId][key]"+this.currentGuiStatus.saitenKekka2ji[targetMondaiId][key])
  //       if (returnSaitenKekka2ji[key] == true) {
  //         returnSaitenKekka2ji[key] = 1;
  //       } else if (returnSaitenKekka2ji[key] == false) {
  //         returnSaitenKekka2ji[key] = 0;
  //       }
  //     } else {
  //       if (returnSaitenKekka2ji[key] == 1) {
  //         returnSaitenKekka2ji[key] = true;
  //       } else if (returnSaitenKekka2ji[key] == 0) {
  //         returnSaitenKekka2ji[key] = false;
  //       }
  //     }
  //   }
  //   return returnSaitenKekka2ji;
  // }


  // getKansou(){
  //   // const kansouDocUser: AngularFirestoreDocument<Kansou> = 
  //   //   this.afStore.doc(`user/${this.currentGuiStatus.myName}/kansou/`);
  //   //   this.afStore.collection(`user/${this.currentGuiStatus.myName}/kansou`).orderBy('timeStamp').limit(3);


  //   // let kansouDoc = this.afStore.doc<Kansou>(`user/${this.currentGuiStatus.myName}/kansou/20200618_062157_5586`);
  //   // this.currentGuiStatus.kansouCollectionList = kansouDoc.valueChanges();

  //   // this.kansouDoc = this.afStore.doc<Kansou>(`user/${this.currentGuiStatus.myName}/kansou/20200618_062157_5506`);
  //   // this.item = this.kansouDoc.valueChanges();


  //     this.afStore.collection(`user/${this.currentGuiStatus.myName}/kansou`, ref => ref.orderBy('timeStamp').limit(1)).get().toPromise()
  //       .then(
  //         function (querySnapshot) {
  //           querySnapshot.forEach(function (doc) {
  //             console.log(`${doc.id} => ${doc.data().kansou}`);
      
  //           });
  //         }

  //       ).catch(function (error) {
  //         alert("送信できませんでした");
  //       });

  // }
}
