/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-trailing-spaces */
import { Injectable } from '@angular/core';

import {  HttpClient, HttpHeaders } from '@angular/common/http';

//import { Todo } from '../models/todo.model';
import { KaitouBun } from '../models/kaitoubun.model';
import { GouhiJouhou } from '../models/gouhiJouhou.model';
import { ScatterData } from '../models/scatterdata.model';
import { Mondai } from '../mondai/mondai';

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  //todo: Todo[] = [];
  private Url = `https://api.zu-de.com:50000/`//`https://api.zu-de.com:50000/test/`//
  private Url_gouhi_jouhou = `https://api.zu-de.com:50000/gouhi_jouhou/`;
  private Url_resultSaigenTouanTeikyou = `https://api.zu-de.com:50000/saigen_touan_teikyou/`;  
  private Url_like = `https://api.zu-de.com:50000/like/`;  
  //private Url_getDeafultBunsekiGazou = `https://api.zu-de.com:50000/default_bunseki_gazou/`;  
  

  private headers = new HttpHeaders({'Content-Type': 'application/json'});//new Headers({'Content-Type': 'application/json'});//new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    private http: HttpClient//HttpClient//Http//HttpClientModule
  ){}

  // 全てのtodoをGETする
  // getAllTodo(): Promise<Todo[]> {//:Observable<any>{//
  //   console.log("AAA")
  //   // return this.http
  //   //   .get(this.Url)
  //   //   .toPromise()
  //   //   .then(response => response.json() as Todo[])
  //   //   .catch(this.errorHandler)
  //   // return this.http
  //   //   .get(this.Url)
  //   //   .then(response => response.json() as Todo[])
  //   //   .catch(this.errorHandler)
  // }
  
  // getAllTodo(): Promise<Todo> {
  //   return this.http
  //     .get(this.Url)
  //     .toPromise()
  //     .then(
  //       (res) => {
  //         console.log("res_"+JSON.stringify(res));
  //         const response: any = res;
  //         console.log("response_"+JSON.stringify(response));
  //         return response;
  //       }
  //       //response => response.json() as Todo[]
  //       )
  //     .catch(this.errorHandler)
  // }

  private errorHandler(err) {
    console.log('Error occured__', err);
    return Promise.reject(err.message || err);
  }


  // 追加時の挙動
  create(kaitouBun: KaitouBun): Promise<ScatterData[]> {
    return this.http
      .post(this.Url, JSON.stringify(kaitouBun), { headers: this.headers })
      .toPromise()
      .then(
        
        (res) => {
          const response: any = res;
          //console.log("response_"+JSON.stringify(response));
          return response;
        }
        //res => res.json()
      )
      .catch(this.errorHandler);
  }

  create_gouhi_jouhou(gouhi_jouhou: GouhiJouhou,myName: string) {
    const paramsDic = {
      gouhi_jouhou:[gouhi_jouhou],
      myName:myName
    };
    return this.http
      .post(this.Url_gouhi_jouhou, JSON.stringify(paramsDic), { headers: this.headers })
      .toPromise()
      .then(
        
        (res) => {
          const response: any = res;
          //console.log("response_"+JSON.stringify(response));
          return response;
        }
        //res => res.json()
      )
      .catch(this.errorHandler);
  }

  
  like(mondaiId: number, targetMyName: string, myName: string) {
    const paramsDic = {
      mondaiId: ""+mondaiId,//python側の都合でSTRにキャストしておく
      targetMyName: targetMyName,
      myName: myName,
    };
    //console.log('paramsDic--',paramsDic)
    return this.http
      .post(this.Url_like, JSON.stringify(paramsDic), { headers: this.headers })
      .toPromise()
      .then(
        
        (res) => {
          const response: any = res;
          //console.log("response_like_"+JSON.stringify(response));
          return response;
        }
        //res => res.json()
      )
      .catch(this.errorHandler);
  }

  create_resultSaigenTouanTeikyou(mondaiId: number, kaitouBun: string, 
    myName: string, defaultGazouGetBoolean: boolean, createNewDefaultGazouFlag: boolean) {
    const paramsDic = {
      mondaiId:mondaiId,
      kaitouBun:kaitouBun,
      myName: myName,
      defaultGazouGetBoolean: defaultGazouGetBoolean,
      createNewDefaultGazouFlag: createNewDefaultGazouFlag,
    };
    //console.log('paramsDic--',paramsDic)
    return this.http
      .post(this.Url_resultSaigenTouanTeikyou, JSON.stringify(paramsDic), { headers: this.headers })
      .toPromise()
      .then(
        
        (res) => {
          const response: any = res;
          //console.log("response_"+JSON.stringify(response));
          return response;
        }
        //res => res.json()
      )
      .catch(this.errorHandler);
  }

  // getDeafultBunsekiGazou(mondaiId: number, myName: string, createNewDefaultGazouFlag: boolean) {
  //   const paramsDic = {
  //     mondaiId:mondaiId,
  //     myName: myName,
  //     createNewDefaultGazouFlag: createNewDefaultGazouFlag,
  //   };
  //   //console.log('paramsDic--',paramsDic)
  //   return this.http
  //     .post(this.Url_getDeafultBunsekiGazou, JSON.stringify(paramsDic), { headers: this.headers })
  //     .toPromise()
  //     .then(
        
  //       (res) => {
  //         const response: any = res;
  //         //console.log("response_"+JSON.stringify(response));
  //         return response;
  //       }
  //       //res => res.json()
  //     )
  //     .catch(this.errorHandler);
  // }

  
  // get_all_gouhi_jouhou() {
  //   return this.http
  //     .get(this.Url_gouhi_jouhou_all)
  //     .toPromise()
  //     .then(
        
  //       (res) => {
  //         const response: any = res;
  //         //console.log("response_"+JSON.stringify(response));
  //         return response;
  //       }
  //       //res => res.json()
  //     )
  //     .catch(this.errorHandler);
  // }

  // // 追加された最新のtodoを一件取得する
  // getNewTodo(): Promise<Todo> {
  //   return this.http
  //     .get(this.Url + "?limit=1")
  //     .toPromise()
  //     .then(
        
  //       (res) => {
  //         const response: any = res;
  //         console.log("response_"+JSON.stringify(response));
  //         return response;
  //       }
  //       //res => res.json().results
  //     )
  //     .catch(this.errorHandler)
  // }


  // // 削除時の挙動
  // delete(id: number): Promise<void> {
  //   const url = `${this.Url}${id}/`;
  //   return this.http
  //     .delete(url, {headers: this.headers})
  //     .toPromise()
  //     .then(() => null)
  //     .catch(this.errorHandler);
  // }


  // // 更新時の挙動
  // update(todo: Todo): Promise<Todo> {
  //   const url = `${this.Url}${todo.id}/`;
  //   return this.http
  //     .put(url, JSON.stringify(todo), {headers: this.headers})
  //     .toPromise()
  //     .then(
  //       (res) => {
  //         const response: any = res;
  //         console.log("response_"+JSON.stringify(response));
  //         return response;
  //       }
  //     )
  //     .catch(this.errorHandler);
  // }
}
