import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MComponent } from './m/m.component';
//import { LComponent } from './l/l.component';
import { ModalPage } from './modal/modal.page';
import { MondaiPreviewPage } from './mondai-preview/mondai-preview.page';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule} from '@angular/material/expansion';
import { NgxChartsModule } from '@swimlane/ngx-charts';

//import { AngularFireModule } from 'angularfire2';
//import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
//import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
//import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { MdToHtmlPipe } from './md-to-html.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';

//import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ModalPageModule } from './modal/modal.module';
import { MondaiPreviewPageModule } from './mondai-preview/mondai-preview.module';

//import { Screenshot } from '@ionic-native/screenshot/ngx';
//import { ClipboardModule } from '@angular/cdk/clipboard'
import { HttpClientModule, } from '@angular/common/http';

//import { Camera } from '@ionic-native/camera/ngx';
//import { HttpModule } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import {CommonModule } from '@angular/common';
import { HalfModalPage } from './half-modal/half-modal.page';
import { HalfModalPageModule } from './half-modal/half-modal.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent,MComponent,
    //LComponent, 
    MdToHtmlPipe, ModalPage, MondaiPreviewPage, HalfModalPage, ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule, 
    CommonModule,
    ModalPageModule,
    MondaiPreviewPageModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    NgxChartsModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFirestoreModule, 
    AngularFirestoreModule.enablePersistence({synchronizeTabs: true}), // 追加
    HttpClientModule,
    //HttpModule,
    //HttpClient,
    //ClipboardModule,
    HalfModalPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    //StatusBar,
    //SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: SETTINGS, useValue: {} },
    //Camera,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
