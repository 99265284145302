import { Injectable } from '@angular/core';
//import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleCloudVisionServiceService {

  constructor(
    //public http: Http
    public http: HttpClient
  ) { }
  getLabels(base64Image, feature) {
    const body = {
      "requests": [
        {
          "features": [
            {
              "type": feature,
              "maxResults": 10
            }],
          "image": {
            "content": base64Image
          }
        }
        // {
        //   "image":{
        //     "source":{
        //       "imageUri":
        //         "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
        //     }
        //   },
        //   "features":[
        //     {
        //       "type":"LOGO_DETECTION",
        //       "maxResults":1
        //     }
        //   ]
        // }
      ]
    }
    console.log("POSTING TO-- "+'https://vision.googleapis.com/v1/images:annotate?key=' + environment.googleCloudVisionAPIKey)
    return this.http.post('https://vision.googleapis.com/v1/images:annotate?key=' + environment.googleCloudVisionAPIKey, body);
  }
}
