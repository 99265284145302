import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MComponent } from './m/m.component';
//import { LComponent } from './l/l.component';
//import { HomePage } from '../pages/camera/HomePage.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  {path:"", redirectTo: '/m', pathMatch: 'full'},
  //{ path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {path:'m', component:MComponent},
  { path: '**', redirectTo: '/m' },
  { path: 'modal', loadChildren: './modal/modal.module#ModalPageModule' },
  { path: 'mondai-preview', loadChildren: './mondai-preview/mondai-preview.module#MondaiPreviewPageModule' },
  {
    path: 'showclass',
    loadChildren: () => import('./showclass/showclass.module').then( m => m.ShowclassPageModule)
  },
  {
    path: 'half-modal',
    loadChildren: () => import('./half-modal/half-modal.module').then( m => m.HalfModalPageModule)
  }
  //{ path: '**', redirectTo: '/m/106300020/true/f/m/false/noTango' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      //routes, { preloadingStrategy: PreloadAllModules }
      [
        //{ path: '', redirectTo: 'home', pathMatch: 'full' },
        {path:"", redirectTo: '/m', pathMatch: 'full'},
        {path:"", redirectTo: '/l', pathMatch: 'full'},
        { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
        {path:"m/:mondaiId/:hint_visible/:categoryMode/:urlOptionString", component:MComponent},
        {path:'m', component:MComponent},
        //{path:'l', component:LComponent},
        { path: '**', redirectTo: '/m' }
        //{ path: '**', redirectTo: '/m/106300020/true/f/m/false/noTango' }
      ]
      )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
