import { Mondai } from './mondai';
export const MONDAIDATA_r1: Mondai[] = [
        {
                "mondaiId": 101010010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、政府の債務残高（対GDP比）の国際比較である。  
図中のａ～ｃに該当する国の組み合わせとして、最も適切なものを下記の解答群から選べ。

<hint hidden='true'>**
冒頭問題の定番、統計データから該当する国・地域を問う問題です。  
このタイプの問題で共通する解法は  
「マクロな経済状況やイベント（リーマンショック等）の知識から、各国の差異を推定する」  
です。  
　
経済学は2日間の試験の最初なので、パターンを覚えて落ち着いて受験をスタートできます。  
画面下部の類似問題から2,3年分を解法を意識してトレーニングすることをおすすめします。　
**</hint>  

<hint hidden='true'>**
選択肢の３カ国のうち、政府の債務残高が非常に高い国は、日本です。
**  
</hint>
<imgTag>101010010_1.png</imgTag>  
<hint hidden='true'>**
政府の債務残高が２００８年以降に悪化した国は、<hintTarget>アメリカです。リーマンショックの２００８年以降（リーマン・ブラザースは２００８年９月に経営破綻）、アメリカ政府は、リーマン・ブラザースをはじめとしたアメリカ国内の銀行に大規模な資本注入等を行ったため、債務残高が悪化しました。</hintTarget>
**  
</hint>

出所：財務省『日本の財政関係資料』（平成30年10月）  

〔解答群〕  
ア　ａ：アメリカ　ｂ：イタリア　ｃ：日本  
イ　ａ：イタリア　ｂ：日本　ｃ：アメリカ  
ウ　ａ：日本　ｂ：アメリカ　ｃ：イタリア  
エ　ａ：日本　ｂ：イタリア　ｃ：アメリカ<hint hidden='true'>**
←⭕
**</hint>
`
        },
        {
                "mondaiId": 101010020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
国際経済に大きな影響を与える国の1つとして、中国の経済動向は重要である。  
下図は、中国の貿易相手国・地域のシェア（2017年）を示している。  
図中のａ～ｃに該当する国・地域の組み合わせとして、最も適切なものを下記の解答群から選べ。  

<hint hidden='true'>**
現在の中国の主要輸出品目が、<hintTarget>携帯電話やコンピュータの完成品（部品を輸入し、完成品を輸出している）</hintTarget>であること記憶していれば、選択肢を絞り込める問題でした。    
**</hint>

〔解答群〕  
ア  ａ：EU       ｂ：アメリカ  ｃ：日本  
イ  ａ：EU       ｂ：日本      ｃ：アメリカ  
ウ  ａ：アメリカ  ｂ：EU       ｃ：日本<hint hidden='true'>**
←⭕
**</hint>  
エ  ａ：アメリカ  ｂ：日本      ｃ：EU  

<hint hidden='true'>**
輸出入ともに中国と繋がりが強いのは、EUです。
**</hint>  
<imgTag>101010020_1.png</imgTag>  
<hint hidden='true'>**
輸入に比べて輸出の割合が低いのは、台湾・韓国・<hintTarget>日本です。つまり、携帯電話やコンピュータの部品の多くを自国で調達できるこの３カ国は、（中国から見た）「輸入元」にはなっても、「完成品の輸出先」にはなっていないと考えられます。</hintTarget>
**</hint>


`
        },
        {
                "mondaiId": 101010030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　国民経済計算は、総需要をいくつかの項目に区分している。これらの項目を見ることによって、より詳細に総需要の状況を把握することができる。  
　国民経済計算における総需要に関わる恒等式として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】国内総支出GDE、国内需要、民間需要、公的需要
<imgTag>101010030_1.png</imgTag>  
</div>
</hint>    

ア　<hintTarget>公的需要</hintTarget>　=　公的固定資本形成+公的在庫変動
<hint hidden='true'>**
＋<hintTarget>政府最終消費支出</hintTarget>
**</hint>

イ　<hintTarget>国内需要</hintTarget>　=　民間需要+公的需要<hintTarget>+財貨・サービスの輸入</hintTarget>
<hint hidden='true'>**  
財貨・サービスの輸入は<hintTarget>不要です</hintTarget>
**</hint>  

ウ　<hintTarget>総固定資本形成</hintTarget>　=　民間住宅+民間企業設備
<hint hidden='true'>**
＋<hintTarget>公的固定資本形成</hintTarget>**
</hint>  

エ　<hintTarget>民間需要</hintTarget>　=　民間最終消費支出+民間住宅+民間企業設備+民間在庫変動<hint hidden='true'>**
←⭕適切です。  
**</hint>    
`
        },
        {
                "mondaiId": 101010040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　消費がどのようにして決まるかを理解することは、経済政策の手段を検討する際にも、また、景気動向を予測する上でも重要である。一般に、消費の決定に所得が影響すると考えられているが、具体的な影響の仕方についてはいくつかの考え方がある。  

　消費の決定に関する記述として、最も適切なものはどれか。  

ア　<hintTarget>恒常所得仮説</hintTarget>では、一時金の支給によって所得が増加しても、消費は増加しない。  

<hint hidden='true'>**
⭕適切です。  
恒常所得仮説では、所得を  
所得　＝　恒常所得　＋　<hintTarget>変動所得（景気や一時金の支給など）</hintTarget>
としたときの、  
「<hintTarget>変動所得</hintTarget>」が増減しても、消費は変わらないと考えます。  
** </hint>

イ　絶対所得仮説による<hintTarget>ケインズ型消費関数</hintTarget>では、減税によって可処分所得が増加しても、</hintTarget>消費は増加しない。</hintTarget>  

<hint hidden='true'>**
❌増加します。  
ケインズの消費関数の公式  
C=<hintTarget>cY+b</hintTarget>  
より、減税による可処分所得増加は、今期の所得（Y）の増加に相当するため。
** </hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
ケインズの消費関数
<imgTag>101010040_1.png</imgTag>  

</div>
</hint>

ウ　<hintTarget>絶対所得仮説</hintTarget>によるケインズ型消費関数では、定期給与のベースアップによって所得が増加しても、<hintTarget>消費は増加しない。</hintTarget>  

<hint hidden='true'>**
❌増加します。<hintTarget>今期の所得（Y）が増加する</hintTarget>ため。
** </hint>

エ　ライフサイクル仮説では、定期昇給によって所得が増加しても、消費は<hintTarget>増加しない。</hintTarget>

<hint hidden='true'>**
❌ライフサイクル仮説は、<hintTarget>「消費は生涯所得に依存する」という仮説なので、定期昇給により生涯所得が増加する</hintTarget>ため、消費が増加すると考えます。
** </hint>

`
        },
        {
                "mondaiId": 101010051,
                "name": "第5問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、開放経済における生産物市場の均衡を表す45度線図である。直線ADは総需要線であり、総需要ADは以下によって表される。  
<imgTag>101010051_1.png</imgTag>  

<imgTag>101010051_2.gif</imgTag>  
<hint hidden='true'>**  
※グラフがアニメーションしない場合クリックしてください  
上図りの乗数効果のイメージについて、  
「乗数効果が波及する際のインパクト（図中の矢印に相当）は、限界消費性向や限界輸入性向の影響で徐々に<hintTarget>小さ</hintTarget>くなりながら※波及する。」  
※
 限界輸入性向は、需要を満たす際にすべて国内調達するわけではない。また、  
 限界消費性向は、増えた給料をすべて消費するわけではないため
という概念を理解していれば、効率的に正解にたどり着ける問題でした。  
数式を展開した解法は、以下の通りです、
<imgTag>101010051_3.png</imgTag>  
**</hint>


（設問1）  
　総需要線がAD0からAD1にシフトするときの乗数効果はEB／EAによって表される。<hintTarget>乗数効果を小さく</hintTarget>するものとして、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　限界消費性向の上昇  
ｂ　限界消費性向の低下<hint hidden='true'>** ⭕
** </hint>  
ｃ　限界輸入性向の上昇<hint hidden='true'>** ⭕
** </hint>  
ｄ　限界輸入性向の低下  

〔解答群〕  
ア　ａとｃ  
イ　ａとｄ  
ウ　ｂとｃ<hint hidden='true'>** ⭕
** </hint>  
エ　ｂとｄ
`
        },
        {
                "mondaiId": 101010052,
                "name": "第5問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、開放経済における生産物市場の均衡を表す45度線図である。直線ADは総需要線であり、総需要ADは以下によって表される。  
<imgTag>101010051_1.png</imgTag>  

<imgTag>101010052_2.png</imgTag>  

                
（設問2）  
　均衡GDPは45度線と総需要線の交点によって与えられる。均衡GDPの変化に関する記述として、最も適切なものはどれか。  

ア　減税は、総需要線の<hintTarget>傾きを急にする</hintTarget>ことを通じて、均衡GDPを増やす。  

<hint hidden='true'>**  
❌減税は、<hintTarget>切片の項のうち－ｃTの値を大きくする</hintTarget>ので、総需要線を上方に平行移動させることで、均衡GDPを増やします。  
** </hint>

イ　政府支出の拡大は、総需要線の上方への平行移動を通じて、均衡GDPを増やす。  

<hint hidden='true'>**  
⭕政府支出は、<hintTarget>切片の項のうちGの値を大きくする</hintTarget>ので、総需要線を上方に平行移動させることで、均衡GDPを増やします。  
** </hint>

ウ　輸出の減少は、総需要線の<hintTarget>傾きを緩やか</hintTarget>にすることを通じて、均衡GDPを減らす。  

<hint hidden='true'>**  
❌輸出の減少は、切片の項のうちGの値を小さくするので、総需要線を下方に平行移動させます。そのため、均衡GDP（交点EのX軸）を<hintTarget>減ら</hintTarget>します。  
** </hint>

エ　利子率の上昇は、総需要線の<hintTarget>上方</hintTarget>への平行移動を通じて、均衡GDPを増や す。  

<hint hidden='true'>**  
❌利子率の上昇は、切片の項のうち-brの値を小さくするので、総需要線を下方に平行移動させます。そのため、均衡GDP（交点EのX軸）を<hintTarget>減ら</hintTarget>します。  
** </hint>

`
        },
        {
                "mondaiId": 101010060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　日本経済は、日本銀行による金融政策から影響を受けている。貨幣に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

<hint hidden='true'>**
マネタリーベース、マネーストックに関する問題です。各用語の定義は、画面下部の類似問題を参照ください。  
** </hint>

ａ　中央銀行が<hintTarget>買いオペ</hintTarget>を実施すると、マネタリー・ベースが<hintTarget>増加</hintTarget>する。  

<hint hidden='true'>**
⭕適切です。  
マネタリーベースとは、「<hintTarget>日本銀行が世の中に直接的に供給するお金</hintTarget>」のことです。
** </hint>

ｂ　マネー・ストックＭ1は、現金通貨、預金通貨、<hintTarget>準通貨、譲渡性預金</hintTarget>の合計である。  

<hint hidden='true'>**
❌M1に準通貨、譲渡性預金は含まれません。この記述はM2に関する記述です。
** </hint>

ｃ　マネー・ストックをマネタリー・ベースで除した値は「<hintTarget>信用乗数</hintTarget>」と呼ばれる。  

<hint hidden='true'>**
⭕適切です。マネーストックの公式は  
マネーストック　＝　<hintTarget>信用乗数</hintTarget>　×　マネタリーベース  
です。
** </hint>

ｄ　<hintTarget>準備預金</hintTarget>が増えると、信用乗数は<hintTarget>大きく</hintTarget>なる。  

<hint hidden='true'>**
❌信用乗数の公式  
信用乗数　＝　（ｃ＋１）／（ｃ＋ｒ）  
　ｃ：<hintTarget>現金預金比率</hintTarget>  
　ｒ：<hintTarget>準備率</hintTarget>  
から、準備預金が増えるとｒが増えるので、信用乗数は小さくなります。
** </hint>


〔解答群〕  
アａとｃ<hint hidden='true'>**
←⭕
** </hint>  
イａとｄ  
ウｂとｃ  
エｂとｄ7`
        },
        {
                "mondaiId": 101010070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　為替レートの決定に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

<hint hidden='true'>**
金利平価説、購買力平価説に関する問題です。  
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】金利平価説にもとづく為替レートと、購買力平価説にもとづく為替レート  
<imgTag>101010070_1.png</imgTag>  

</div>
</hint>

ａ　金利平価説によると、日本の<hintTarget>利子率の上昇</hintTarget>は<hintTarget>円高</hintTarget>の要因になる。<hint hidden='true'>**
←⭕
**</hint>  

ｂ　金利平価説によると、日本の利子率の上昇は<hintTarget>円安</hintTarget>の要因になる。  

ｃ　購買力平価説によると、日本の<hintTarget>物価の上昇</hintTarget>は<hintTarget>円高</hintTarget>の要因になる。  

ｄ　購買力平価説によると、日本の物価の上昇は<hintTarget>円安</hintTarget>の要因になる。<hint hidden='true'>**
←⭕
**</hint>    


〔解答群〕  
ア　ａとｃ  
イ　ａとｄ<hint hidden='true'>**
←⭕
**</hint>    
ウ　ｂとｃ  
エ　ｂとｄ
`
        },
        {
                "mondaiId": 101010081,
                "name": "第8問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　総需要－総供給分析の枠組みで、財政・金融政策の効果と有効性を考える。  
　下記の設問に答えよ。  


（設問1）  
　「流動性のわな」の状況下にあるときのLM曲線は、下図のように水平になる。このときの総需要曲線に関する記述として、最も適切なものを下記の解答群から選べ。  

〔解答群〕  
ア　物価が下落しても、利子率が低下しないため、投資支出は不変である。したがって、総需要曲線は垂直になる。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

イ　物価が下落すると、利子率が<hintTarget>低下して</hintTarget>、投資支出が増加する。したがって、総需要曲線は右下がりになる。  

<hint hidden='true'>**
❌「流動性のわな」の状況下では、低下しません。  
低下しないために、選択肢アのような現象が見られるのが「流動性のわな」です。  
この選択肢の記述は、通常の状況下での、物価下落から総需要曲線が右下がりに描かれるまでの流れについて書いています。
**</hint>

ウ　物価が下落すると、利子率は低下しないが、投資支出が<hintTarget>増加する</hintTarget>。したがって、総需要曲線は<hintTarget>右下がり</hintTarget>になる。  

<hint hidden='true'>**
❌
**</hint>

エ　物価が下落すると、利子率は<hintTarget>低下する</hintTarget>が、投資支出は不変である。したがって、総需要曲線は垂直になる。  

<hint hidden='true'>**
❌
**</hint>



`
        },
        {
                "mondaiId": 101010082,
                "name": "第8問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `

　総需要－総供給分析の枠組みで、財政・金融政策の効果と有効性を考える。  
「流動性のわな」の状況下にあるときのLM曲線は、下図のように水平になる。

<imgTag>101010082_1.png</imgTag>  

（設問2）  

　「流動性のわな」の状況下における財政政策と金融政策の効果と有効性に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　政府支出を増加させても、クラウディング・アウトによって総需要は不変である。したがって、物価水準も実質GDPも当初の水準から変化しない。  

<hint hidden='true'>**
❌この記述は、IS-LM曲線が通常の（流動性のわなでない）場合に、政府支出を増加させた際の記述になります。
クラウディングアウトのイメージは下記になります。  
<imgTag>101010082_2.png</imgTag>  
**</hint>

ｂ　政府支出の増加は、総需要を拡大させる。その結果、物価水準が上昇し、実質GDPも増加する。  

<hint hidden='true'>**
⭕適切な記述です。流動性のわなの状況下でも、政府支出が増加するとIS曲線が右にシフトし、実質GDPが増加します。  
<imgTag>101010082_3.png</imgTag>  
**</hint> 

ｃ　名目貨幣供給の増加は、総需要を変化させない。したがって、物価水準も実質GDPも当初の水準から変化しない。  

<hint hidden='true'>**
⭕適切な記述です。流動性のわなの状況下では、名目貨幣供給が増加してLM曲線が右にシフトしても、実質GDPは変化しません。  
<imgTag>101010082_4.png</imgTag>  
**</hint> 

ｄ　名目貨幣供給の増加は、総需要を拡大させる。その結果、物価水準が上昇し、実質GDPも増加する。  

<hint hidden='true'>**
❌この記述は、IS-LM曲線が通常の（流動性のわなでない）場合に、名目貨幣供給を増加させた際の記述になります。  
<imgTag>101010082_5.png</imgTag>  
**</hint>


〔解答群〕  
ア　ａとｃ  
イ　ａとｄ  
ウ　ｂとｃ<hint hidden='true'>**
←⭕
**</hint>   
エ　ｂとｄ

`
        },
        {
                "mondaiId": 101010090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　自然失業率仮説に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　インフレと失業の間には、短期的にも<hintTarget>長期的</hintTarget>にも、トレード・オフの関係が成立する。  

<hint hidden='true'>**
❌自然失業率仮説によると、インフレと失業率の間のトレードオフは、長期的には、自然失業率で一定となるためトレードオフが成立しません（なお、短期的には成立します。詳細は下図参照）。  
**</hint>
<hint hidden='true'>**
　自然失業率仮説とは、インフレ率が上昇して名目賃金が上昇すると、<hintTarget>貨幣錯覚</hintTarget>により短期的には失業率が下がるが、やがて貨幣錯覚が解消されて、長期的な失業率は<hintTarget>自然失業率</hintTarget>に戻るという仮説です。**
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】自然失業率仮説による、短期／長期の失業率推移のイメージ
<imgTag>101010090_1.gif</imgTag>
</div>
</hint>

ｂ　自然失業率とは、非自発的失業率と自発的失業率の合計である。  

<hint hidden='true'>**
❌失業率の定義（新古典主義の）  
　＝循環的失業＋自然失業率  
　＝循環的失業＋摩擦的失業＋構造的失業  
なお、選択肢の記述は、ケインズによる失業率の定義です。  
失業率の定義（ケインズの）＝非自発的失業率＋自発的失業率
**</hint>

ｃ　循環的失業の拡大は、実際のインフレ率を<hintTarget>抑制する</hintTarget>。  

<hint hidden='true'>**
⭕適切な記述です。  
上記図の通り、循環的失業が拡大（失業率が増加）すると、短期フィリップス曲線にしたがって、（一時的に）インフレ率が低下します。
**</hint>  

ｄ　政府による総需要拡大策は、<hintTarget>長期的</hintTarget>には<hintTarget>インフレ</hintTarget>を加速させる。  

<hint hidden='true'>**
⭕適切な記述です。上記図のとおり、短期的には総需要拡大策によりインフレ率を上昇させて失業率が下がっても、長期的には、自然失業率失業率に戻るため、総需要拡大作を繰り返すことになり、インフレを加速させます。
**</hint>  


〔解答群〕  
アａとｂ  
イａとｄ  
ウｂとｃ  
エｃとｄ<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 101010100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　市場取引から発生する利益は、「経済余剰」といわれる。この経済余剰は、売り手にも買い手にも生じ、売り手の経済余剰は「生産者余剰」、買い手の経済余剰は「消費者余剰」と呼ばれる。  

　下図に基づき、需要曲線または供給曲線のシフトに伴う余剰の変化に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。なお、点Eが初期の均衡を示している。  

<imgTag>101010100_1.png</imgTag>  

<hint hidden='true'>**
余剰分析に関する問題です。
**</hint>

ａ　所得の増加によって需要曲線が右方シフトすると、生産者余剰は<hintTarget>減少</hintTarget>する。  

<hint hidden='true'>**
❌増加します。
**</hint>

ｂ　技術進歩によって供給曲線が右方シフトすると、消費者余剰は増加する。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ｃ　好みの変化によって需要曲線が左方シフトすると、生産者余剰は減少する。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ｄ　原材料費の上昇によって供給曲線が左方シフトすると、消費者余剰は<hintTarget>増加</hintTarget>する。  

<hint hidden='true'>**
❌減少します。
**</hint>


〔解答群〕  

ア　ａとｂ  
イ　ａとｄ  
ウ　ｂとｃ<hint hidden='true'>**
←⭕
**</hint>    
エ　ｃとｄ  
`
        },
        {
                "mondaiId": 101010110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、供給曲線の形状が特殊なケースを描いたものである。座席数に上限があ  
るチケットなどは、ある一定数を超えて販売することができないため、上限の水準  
において垂直になる。なお、需要曲線は右下がりであるとする。  
この図に関する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101010110_1.png</imgTag>  

<hint hidden='true'>**
余剰分析に関する問題です。
**</hint>

〔解答群〕  

ア　供給曲線が垂直になってからは、生産者余剰は<hintTarget>増加しない</hintTarget>。  

<hint hidden='true'>**
❌増加します。
**</hint>

イ　このイベントの主催者側がチケットの価格をP1に設定すると、超過<hintTarget>需要</hintTarget>が生じる。  

<hint hidden='true'>**
❌超過供給が発生します。
**</hint>

ウ　チケットがP3で販売されると、社会的余剰は均衡価格の場合よりも□GEFHの分だけ少ない。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ　チケットがQ1だけ供給されている場合、消費者は最大<hintTarget>P2</hintTarget>まで支払ってもよいと考えている。  

<hint hidden='true'>**
❌P1です。
**</hint>

`
        },
        {
                "mondaiId": 101010120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ａさんは、夕食時にビールと焼酎を飲むことにしている。Ａさんの効用水準を一定とした場合、ビールを1杯余分に飲むことと引き換えに減らしてもよいと考える焼酎の数量が、徐々に減ることを描いた無差別曲線として、最も適切なものはどれか。  

<hint hidden='true'>**
Aさんを投資家とみなして、リスク回避度を検討することで解答できる問題でした。
**</hint> 

<imgTag>101010120_1.svg</imgTag>  

`
        },
        {
                "mondaiId": 101010130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　ここでは、ある財の消費者が、学生とそれ以外の一般消費者に分けられるものとする。このとき企業は、学生とそれ以外の一般消費者に対して<hintTarget>異なる価格</hintTarget>をつけることができる。また、学生は価格に対して<hintTarget>弾力的</hintTarget>である。  



　市場のパターンと、企業の価格設定に関する記述として、最も適切なものはどれ  
か。 

ア　この財の市場は競争的であり、学生に対する価格を引き上げると、企業収入が増加する。  

イ　この財の市場は競争的であり、学生に対する価格を引き下げると、企業収入が増加する。  

ウ　この財の市場は不完全競争であり、学生に対する価格を引き上げると、企業収入が増加する。  

エ　この財の市場は<hintTarget>不完全競争</hintTarget>であり、学生に対する<hintTarget>価格を引き下げる</hintTarget>と、企業収入が増加する。<hint hidden='true'>**
←⭕  
なお、不完全競争の定義は下記です。  
・完全競争：　同等の材を供給する多数の供給者がいて、個々の供給者は価格支配力がない。  
・独占市場：　供給者は一社。価格を支配できる。  
・不完全競争：　完全競争と、独占の中間。  
また、問題文より、価格に対して弾力的であるため、価格を下げると企業収入が増加します。
**</hint>  

`
        },
        {
                "mondaiId": 101010140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　労働と生産水準の関係について考える。労働は、生産水準に応じてすぐに投入量を調整できる可変的インプットである。資本投入量が固定されているとき、生産物の産出量は労働投入量のみに依存し、下図のような総生産物曲線を描くことができる。  

この図に関する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101010140_1.png</imgTag>  

〔解答群〕  
ア　労働投入量を増加させるほど、総生産物は<hintTarget>増加</hintTarget>する。  

<hint hidden='true'>**
❌Bより右では、総生産物は減少しています。
**</hint>

イ　労働の<hintTarget>限界生産物</hintTarget>は、<hintTarget>原点Oから点Aの間で最小</hintTarget>を迎え、それ以降は<hintTarget>増加</hintTarget>する。  

<hint hidden='true'>**
❌限界生産物とは、単位量の労働投入量に対する産出量の増加分（つまり総生産物曲線の傾き）です。OA間の最小の傾きより、Bより右側などの傾きのほうが小さいため、最小はOA間ではありません。
**</hint>

ウ　労働の平均生産物と限界生産物は、点Aで一致する。

<hint hidden='true'>**
⭕適切です。平均生産物はOAの傾きでるため、点Aにおける限界生産物（傾き）は一致します。
**</hint>    

エ　労働の平均生産物は、点Aにおいて<hintTarget>最小</hintTarget>となり、点Bにおいて最大となる。  

<hint hidden='true'>**
❌労働の平均生産物は、Oと総生産物曲線の接戦の傾きであるため、点Aにおいて最大になります。
**</hint>

`
        },
        {
                "mondaiId": 101010151,
                "name": "第15問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、産出と費用の関係を描いたものである。労働と資本の両方を可変的インプットとして、生産要素の投入と生産物の産出との関係を描いたものが等産出量曲線である。また、等費用線は、一定の費用のもとで労働と資本をどのくらい投入することが可能かを表している。  

この図に基づいて、下記の設問に答えよ。  

<imgTag>101010151_1.png</imgTag>  


（設問1）  
　等費用線のシフトに関する記述として、最も適切なものはどれか。  

ア　資本のレンタル価格が上昇した場合、横軸の切片は不変のままで、縦軸の切片が下方に移動する。

<hint hidden='true'>**
⭕適切な記述です。
**</hint>    

イ　賃金率が下落した場合、縦軸の切片は不変のままで、横軸の切片が<hintTarget>左方</hintTarget>に移動する。  

<hint hidden='true'>**
❌右方です。
**</hint>

ウ　賃金率の上昇と同じ割合で資本のレンタル価格が下落すれば、等費用線の傾きは<hintTarget>変わらない</hintTarget>。  

<hint hidden='true'>**
❌変わります。
**</hint>

エ　費用が増加すると、等費用線が<hintTarget>左方</hintTarget>に平行移動する。  

<hint hidden='true'>**
❌投入できる資本と労働量が増えるので、右方です。
**</hint>

`
        },
        {
                "mondaiId": 101010152,
                "name": "第15問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、産出と費用の関係を描いたものである。労働と資本の両方を可変的インプットとして、生産要素の投入と生産物の産出との関係を描いたものが等産出量曲線である。また、等費用線は、一定の費用のもとで労働と資本をどのくらい投入することが可能かを表している。  

この図に基づいて、下記の設問に答えよ。  

<imgTag>101010152_1.png</imgTag>  

（設問2）  
等費用線が右方に平行移動した場合の記述として、最も適切なものはどれか。  

ア　新しい等費用線における費用最小化は、点Eと費用は<hintTarget>同じ</hintTarget>であるが、賃金率と資本のレンタル価格がともに高い水準で達成される。  

<hint hidden='true'>**
❌異なります。
**</hint>

イ　新しい等費用線における費用最小化は、点Eよりも産出量が高い水準で達成される。<hint hidden='true'>**
←⭕
**</hint>   

ウ　新しい等費用線における費用最小化は、点Eよりも産出量が<hintTarget>低い</hintTarget>水準で達成される。  

<hint hidden='true'>**
❌高い水準になります。
**</hint>

エ　新しい等費用線における費用最小化は、点Eよりも労働と資本がともに<hintTarget>少ない</hintTarget>水準で達成される。

<hint hidden='true'>**
❌高い水準になります。
**</hint>

`
        },
        {
                "mondaiId": 101010160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　短期の完全競争市場における、価格と最適生産の関係を考える。下図には、限界費用曲線MC、平均費用曲線AC、平均可変費用曲線AVCが描かれている。  
　この図に関する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101010160_1.png</imgTag>  

〔解答群〕  

ア　価格がP1とP2の間に与えられると、固定費用は<hintTarget>すべて</hintTarget>損失になる。  

<hint hidden='true'>**
❌固定費費用の一部が損失になります。
**</hint>

イ　価格がP1より低い場合、操業を停止することで損失を固定費用のみに抑えることができる。

<hint hidden='true'>**
⭕可変費用が発生しないため、固定費用のみに抑えられます。
**</hint>   

ウ　価格がP2より高い場合、<hintTarget>総費用が総収入</hintTarget>を上回る。  

<hint hidden='true'>**
❌総収入が総費用を上回るため、記述が逆です。
**</hint>

エ　平均固定費用は、生産量の増加に応じて<hintTarget>上昇</hintTarget>する。  

<hint hidden='true'>**
❌固定費を生産量で割り算するため、平均固定費用は下がります。
**</hint>

`
        },
        {
                "mondaiId": 101010170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　海洋資源などの共有資源に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　共有資源の消費に対する無償の許可は、共有資源の消費の効率化につながる。  
ｂ　共有資源の消費に対する<hintTarget>有償の許可</hintTarget>は、共有資源の消費の効率化につながる。<hint hidden='true'>**
←⭕共有資源には、「牧草地の悲劇」と呼ばれる有名な資源配分の失敗例があります。  
共有地に生えた牧草を、羊飼いが限界まで羊を増やしたことで食べ尽くしてしまうという話です。  
牧草地の悲劇を避ける方法の一つが、共有資源を有償化して適正な消費量に抑えるというものです。
**</hint>   
ｃ　共有資源は、消費に<hintTarget>競合性がある</hintTarget>が、<hintTarget>排他性のない</hintTarget>財として定義できる。<hint hidden='true'>**
←⭕
**</hint>   
ｄ　共有資源は、消費に排他性があるが、競合性のない財として定義できる。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】共有資源は、ｃの記述の通り消費に競合性があり、排他性がない財です。  
<img src="https://cdn-ak.f.st-hatena.com/images/fotolife/p/pro_hero/20200704/20200704230639.jpg"/>
<a href="https://pro-hero.hatenablog.com/entry/2020/07/06/090008" target="_blank">  
(外部リンクを開きます　プロヒーローになるためのブログ：『マンキュー経済学 ミクロ編［第3版］』を分かりやすく説明する。Part11)</a>  
</div>
</hint>  


〔解答群〕  
アａとｃ  
イａとｄ  
ウｂとｃ<hint hidden='true'>**
←⭕
**</hint>   
エｂとｄ
`
        },
        {
                "mondaiId": 101010181,
                "name": "第18問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、産業保護という観点から、輸入関税と生産補助金の効果を描いたものである。輸入関税をかける場合、この財の国内価格はPfからPdへと上昇する。また、生産補助金を交付する場合、この財の供給曲線はS0からS1へとシフトする。  

この図に基づいて、下記の設問に答えよ。  


<imgTag>101010181_1.png</imgTag>  

（設問1）  
輸入関税をかけた場合、また、生産補助金を交付した場合の需要量と供給量に  
関する記述として、最も適切なものはどれか。  

ア　輸入関税をかけた場合の需要量は<hintTarget>Q1</hintTarget>である。  

<hint hidden='true'>**
❌PdとADの交点であるQ3です。
**</hint>

イ　輸入関税をかけた場合の供給量は<hintTarget>Q2</hintTarget>である。  

<hint hidden='true'>**
❌Pdと需要曲線S0との交点Q1です。
**</hint>

ウ　生産補助金を交付した場合の需要量は<hintTarget>Q3</hintTarget>である。  

<hint hidden='true'>**
❌PfとS1の交点であるQ4です。
**</hint>

エ　生産補助金を交付した場合の供給量はQ4である。

<hint hidden='true'>**
←⭕
**</hint> 

`
        },
        {
                "mondaiId": 101010182,
                "name": "第18問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、産業保護という観点から、輸入関税と生産補助金の効果を描いたものである。輸入関税をかける場合、この財の国内価格はPfからPdへと上昇する。また、生産補助金を交付する場合、この財の供給曲線はS0からS1へとシフトする。  

この図に基づいて、下記の設問に答えよ。  

<imgTag>101010181_2.png</imgTag>  

（設問2）  
輸入関税と生産補助金による社会的余剰の比較に関する記述として、最も適切  
なものはどれか。  

ア　□BCJKの分だけ、生産補助金の方が輸入関税よりも損失が少なく、産業保護の政策としてより効果的である。  

イ　□FGKHの分だけ、輸入関税の方が生産補助金よりも損失が少なく、産業保護の政策としてより効果的である。  

ウ　△FIHと△GJKの分だけ、輸入関税の方が生産補助金よりも損失が少なく、産業保護の政策としてより効果的である。  

エ　△FIHの分だけ、生産補助金の方が輸入関税よりも損失が少なく、産業保護の政策としてより効果的である。<hint hidden='true'>**
←⭕
**</hint> 

`
        },
        {
                "mondaiId": 101010190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　情報の非対称性がもたらすモラルハザードに関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　雇用主が従業員の働き具合を監視できないために従業員がまじめに働かないとき、この職場にはモラルハザードが生じている。<hint hidden='true'>**
←⭕
**</hint>   

ｂ　失業給付を増加させることは、失業による従業員の所得低下のリスクを減らすことを通じて、モラルハザードを<hintTarget>減らす</hintTarget>効果を期待できる。  

<hint hidden='true'>**
❌失業による所得低下の懸念が減ると考えて、本来必要ない退職が発生する可能性が高まるため、モラルハザードを増やします。  
**</hint>   

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】情報の非対称性にもとづくモラルハザードと逆選択の比較  
<img src="https://www.sbbit.jp/bit/img/bit090508ieco01.jpg"/>
<a href="https://www.sbbit.jp/article/cont1/19214" target="_blank">  
(外部リンクを開きます　ビジネス+IT：「逆選択」と「モラル・ハザード」）</a>  
【代表例 @逆選択】中古車市場  
品質が良いが品質相応に高価な中古車よりも、品質が悪く値段に見合わない中古車のほうが、（単に安いという理由で）コストパフォーマンスが悪くともよく売れる  
【代表例 @モラルハザード】保険  
保険に入ったので、病気にかかっても保険が出ると考えて、不健康な生活習慣に変わる  
</div>
</hint>  

ｃ　食堂で調理の過程を客に見せることには、料理人が手を抜くリスクを減らすことを通じて、モラルハザードを減らす効果を期待できる。<hint hidden='true'>**
←⭕
**</hint>   

ｄ　退職金の上乗せによる早期退職の促進が優秀な従業員を先に退職させるとき、この職場にはモラルハザードが生じている。  

<hint hidden='true'>**
❌従業員の退職意志の有無を調べることにコストがかかる（取引開始前の情報の非対称性がある）ため、本来退職してほしくない従業員が退職するという、逆選択が生じています
**</hint>   

〔解答群〕  
ア　ａとｂ  
イ　ａとｃ<hint hidden='true'>**
←⭕
**</hint>   
ウ　ｂとｄ  
エ　ｃとｄ
`
        },
        {
                "mondaiId": 101010200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　規模に関する収穫一定を想定するとき、経済学では、この性質を満たすものとして以下のようなコブ=ダグラス型生産関数をしばしば利用する。この生産関数に関する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101010200_1.svg</imgTag>  

（Y：生産量、N：労働投入量、K：資本投入量、A：技術水準、0＜αa＜1、A＞0）  

〔解答群〕  
ア　1-αは、<hintTarget>労働分配率</hintTarget>を意味する。  

<hint hidden='true'>**
❌資本分配率です。
**</hint>

イ　資本投入量と労働投入量がいずれも2倍になると、生産量も2倍になる。

<hint hidden='true'>**
⭕
**</hint>   

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】資本投入量と労働投入量がいずれも2倍になった場合の計算例  
<img src="https://pbs.twimg.com/media/CUD4IX9UcAE2SLU?format=jpg&name=small"/>
<a href="https://twitter.com/shibukawatake/status/666805583438311424/photo/1" target="_blank">  
(外部リンクを開きます　渋川武彦@shibukawatake）</a>  
</div>
</hint>  

ウ　労働投入量が増加すると、労働と資本の代替の弾力性は<hintTarget>逓減</hintTarget>する。  

<hint hidden='true'>**
❌1のまま変わりません。
**</hint>

エ　<hintTarget>労働の成長率と資本の成長率の和</hintTarget>は、「全要素生産性」と呼ばれる。

<hint hidden='true'>**
❌全要素生産性は、式のAの部分です。Aは労働投入量と資本投入量に対して、技術水準の度合いに比例して生産量が増減することを表しています。
**</hint>

`
        },
        {
                "mondaiId": 101010210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 101,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　費用便益分析による公共事業の評価基準は、純便益の大きさである。  
　ある町では、市街地にある老朽化した公共施設のマネジメントとして、市街地から郊外へ移転した上で新築する方法と、その場所のままでリフォームする方法を検討している。なお、現在の公共施設は、市街地に所在することが理由で、近隣に道路渋滞を発生させている。この道路渋滞は、公共施設が市街地から郊外へ新築移転することにより解消される。  

　新築の場合とリフォームの場合の、それぞれの費用や便益などは、下表のとおりとする。ただし、利子率はゼロとする。このとき、費用便益分析によって2つの方法を検討した結果として、最も適切なものを下記の解答群から選べ。  

<imgTag>101010210_1.png</imgTag>  


〔解答群〕  
ア　現状のままでよい。  
イ　新築を選ぶのが望ましい。

<hint hidden='true'>**
⭕  
新築：　　　　1億円+4000万円-8000万円＝6000万円  
　　　　　　　新築することにより、渋滞解消による4000万円の便益が発生すると考えます   
リフォーム：　1億円－5000万円＝5000万円  
　　　　　　　リフォームしたせいで渋滞が発生するわけではないため、4000万円は便益の計算に含めません
**</hint>   

ウ　どちらを選んでも同じである。  
エ　リフォームを選ぶのが望ましい。  

`
        },
        {
                "mondaiId": 102010010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `  
　8月の商品Ａの取引は以下のとおりであった。8月の<hintTarget>商品売買益</hintTarget>として、最も適切なものを下記の解答群から選べ。なお、<hintTarget>先入先出法</hintTarget>を採用しているものとする。  


<TABLE border="1" align="right" style='background-color:#ffffff;color:#000000;' width="100%">
<TR>
<TD>日付</TD>
<TD>摘要</TD>
<TD>数量</TD>
<TD>単価</TD>
</TR>
<TR>
<TR>
<TD>8月1日</TD>
<TD>前月繰越</TD>
<TD>20個</TD>
<TD>300円</TD>
</TR>
<TD>2日</TD>
<TD>仕入</TD>
<TD>100個</TD>
<TD>350円</TD>
</TR>
<TR>
<TD>5日</TD>
<TD>仕入戻し</TD>
<TD>10個</TD>
<TD>350円</TD>
</TR>
<TR>
<TD>16日</TD>
<TD>売上</TD>
<TD>80個</TD>
<TD>600円</TD>
</TR>
<TR>
<TD>19日</TD>
<TD>売上戻り</TD>
<TD>10個</TD>
<TD>600円</TD>
</TR>
<TR>
<TD>31日</TD>
<TD>次月繰越</TD>
<TD>40個</TD>
<TD></TD>
</TR>
</TABLE>  
<BR><BR><BR><BR><BR><BR><BR><BR>
  
<hint hidden='true'>**
分記法に関する問題です。表中の取引のうち、商品売買益に関わる仕訳は下記です。
**</hint>


<hint hidden='true'>
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD colspan="6" align="left">
問題文で<hintTarget>先入先出法</hintTarget>が指定されているので、8/16に売り上げた商品80個の内訳は、  
　  
80個（8/16売上）＝　20個（前月繰越の単価300円の商品）＋60個（8/2に仕入れた単価350円の商品）  
　  
と考えて、  
　  
商品＝300円×20個＋350円×60個＝6,000＋21,000＝27,000円  
　  
となります。  
貸借の差額（この値と、売上80×600の差額）が、商品売買益になるため、8/16の仕訳は下記になります。
</TD>
</TR>

<TR>
<TD>　</TD>
<TD colspan="4" align="center" style='border-bottom: 1px solid gray;'>8月16日売上</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">売掛金</TD>
<TD align="right" style='border-right: 1px solid gray;'>48,000円</TD>
<TD align="left">商品</TD>
<TD align="right">27,000円</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left"></TD>
<TD align="right" style='border-right: 1px solid gray;'></TD>
<TD align="left"><hintTarget>商品売買益</hintTarget></TD>
<TD align="right">21,000円</TD>
<TD>　</TD>
</TR>
</TABLE>
</hint>  


<hint hidden='true'>
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD colspan="6" align="left">
8/19の売上戻り10個は、8/2に仕入れた単価350円の商品が対象になるため、8/19の仕訳は下記になります。
</TD>
</TR>

<TR>
<TD>　</TD>
<TD colspan="4" align="center" style='border-bottom: 1px solid gray;'>8月19日売上戻り</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">商品</TD>
<TD align="right" style='border-right: 1px solid gray;'>3,500円</TD>
<TD align="left">売掛金</TD>
<TD align="right">6,000円</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left"><hintTarget>商品売買益　</hintTarget></TD>
<TD align="right" style='border-right: 1px solid gray;'>2,500円</TD>
<TD align="left"></TD>
<TD align="right"></TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
</TR>
</TABLE>
</hint>  


<hint hidden='true'>**
上記8/16の仕分と、8/19の仕分に記載されている<hintTarget>商品売買益</hintTarget>を合算して  
商品売買益＝21,000－2,500＝18,500円  
となります。
**</hint>  



〔解答群〕  
ア　4,500円  
イ　10,500円  
ウ　18,500円<hint hidden='true'>**
←⭕
**</hint>    
エ　24,500円
  
`
        },
        {
                "mondaiId": 102010020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
<hint hidden='true'>**
圧縮記帳に関する問題です。
** </hint>
<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【解答に必要な知識】<a href="https://www.foresight.jp/boki/column/boki2-reduction-entry/" target="_blank">圧縮記帳とは？</a>  
機械や設備などを購入するための国からの補助金に、<hintTarget>税金がかからないようにする</hintTarget>ための処理。  
　中小企業診断士では令和元年が初出の論点ですが、無視してよい論点とまでは言えないと思われます（もともと簿記一級の論点でしたが、診断士と出題範囲が一部重複する簿記二級に平成29年度から追加された影響により、出題されたと考えられるため）。  
</div>
</hint>

　Ａ社は、20X1年4月1日に<hintTarget>補助金6，000千円を現金</hintTarget>で受け取った。
<hint hidden='true'>
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD>　</TD>
<TD colspan="4" align="center" style='border-bottom: 1px solid gray;'></TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">現金預金</TD>
<TD align="right" style='border-right: 1px solid gray;'>6,000</TD>
<TD align="left"><hintTarget>補助金</hintTarget></TD>
<TD align="right">6,000</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
</TR>
</TABLE>
</hint>
　続いて20X1年10月1日に、その<hintTarget>補助金6，000千円と自己資金16，000千円で機械装置22，000千円</hintTarget>を購入し、直ちに使用を開始した。その際、直接減額方式による圧縮記帳を行った。
<hint hidden='true'>
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD>　</TD>
<TD colspan="4" align="center" style='border-bottom: 1px solid gray;'></TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">機械装置</TD>
<TD align="right" style='border-right: 1px solid gray;'>22,000</TD>
<TD align="left">現金預金</TD>
<TD align="right">22,000</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">固定資産圧縮損</TD>
<TD align="right" style='border-right: 1px solid gray;'>6,000</TD>
<TD align="left">機械装置</TD>
<TD align="right">6,000</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
</TR>
</TABLE>
</hint>
また、20X2年3月31日（決算日）に、定額法（<hintTarget>耐用年数4年、残存価額ゼロ</hintTarget>）により減価償却を行った。  
<hint hidden='true'>**
減価償却費=<hintTarget>(22000-6000)÷4年×(6÷12)</hintTarget>=2000
**
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD>　</TD>
<TD colspan="4" align="center" style='border-bottom: 1px solid gray;'></TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">減価償却費</TD>
<TD align="right" style='border-right: 1px solid gray;'>2,000</TD>
<TD align="left">減価償却費累計額</TD>
<TD align="right">2,000</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
</TR>
</TABLE>
</hint>
  
　購入時の固定資産圧縮損と決算時の減価償却費の組み合わせとして、最も適切なものはどれか。  


ア　固定資産圧縮損：6，000千円　　減価償却費：2，000千円<hint hidden='true'>**
←⭕
** </hint>  
イ　固定資産圧縮損：6，000千円　　減価償却費：2，750千円  
ウ　固定資産圧縮損：22，000千円　　減価償却費：2，000千円  
エ　固定資産圧縮損：22，000千円　　減価償却費：2，750千円
`
        },
        {
                "mondaiId": 102010030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　連結会計に関する記述として、最も適切なものはどれか。  

ア　Ａ社によるＢ社の議決権の所有割合が<hintTarget>40％未満</hintTarget>であっても、Ｂ社の<hintTarget>財務および営業または事業の方針決定に対して重要な影響を与えることができる場合</hintTarget>には、Ｂ社は子会社と判定される。  

<hint hidden='true'>**  
❌上記下線部に加えて、４０％以上、かつ「A社と緊密な者等とあわせると議決権が50％超であること」も必要な条件です。  
** </hint>

イ　非支配株主持分は、連結貸借対照表の純資産の部に表示される。  

<hint hidden='true'>**  
⭕適切です。
** </hint>

ウ　持分法による投資利益（または損失）は、連結損益計算書の<hintTarget>特別利益（または特別損失）</hintTarget>の区分に表示される。  

<hint hidden='true'>**  
❌営業外収益または営業外費用です。  
** </hint>

エ　連結貸借対照表は、親会社、子会社および<hintTarget>関連会社</hintTarget>の個別貸借対照表を合算し、必要な調整を加えて作成される。

<hint hidden='true'>**  
❌関連会社は<hintTarget>持分法</hintTarget>を用います。  
** </hint>


`
        },
        {
                "mondaiId": 102010040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　決算日における<hintTarget>当座預金勘定の残高は960，000円</hintTarget>であったが、取引銀行から受け取った残高証明書の残高と一致していなかった。そこで、不一致の原因を調べたところ以下の事項が判明した。  

<hint hidden='true'>**  
銀行勘定調整に関する問題です。
** </hint>

・決算日に現金60，000円を当座預金口座へ預け入れたが、銀行の営業時間外のため、銀行側は翌日付の入金としていた。  

<hint hidden='true'>**  
銀行側で、60,000円を加算します。これを（３）とします。
** </hint>

・買掛金支払いのため振り出した小切手30，000円が、<hintTarget>先方に未渡し</hintTarget>であった。  

<hint hidden='true'>**
未渡しなので、帳簿上は、当座預金に戻します。（１）**
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD>　</TD>
<TD colspan="4" align="center" style='border-bottom: 1px solid gray;'></TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">当座預金</TD>
<TD align="right" style='border-right: 1px solid gray;'>30,000</TD>
<TD align="left">買掛金</TD>
<TD align="right">30,000</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
</TR>
</TABLE>
</hint>

・受取手形20，000円が取り立てられていたが、<hintTarget>通知が未達</hintTarget>であった。  

<hint hidden='true'>**
通知が未達の場合、当座預金への入金処理を行います（２）**
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD>　</TD>
<TD colspan="4" align="center" style='border-bottom: 1px solid gray;'></TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD align="left">当座預金</TD>
<TD align="right" style='border-right: 1px solid gray;'>20,000</TD>
<TD align="left">受取手形</TD>
<TD align="right">20,000</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
</TR>
</TABLE>
</hint>

<hint hidden='true'>**
したがって、当社の当座預金は  
960,000 + （１）＋（２）＝　1,010,000  
となます
**</hint>

　このとき、<hintTarget>銀行の残高証明書</hintTarget>に記載されていた残高として、最も適切なものはどれか。  

<hint hidden='true'>**
銀行の残高証明書残高は  
</hintTarget>  1,010,000　－　（３）</hintTarget>  ＝　950,000  
となります
**</hint>

ア　890，000円  

イ　950，000円<hint hidden='true'>**
←⭕適切です。  
**</hint>    

ウ　1，010，000円  

エ　1，070，000円
`
        },
        {
                "mondaiId": 102010050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　会社法上の計算書類に関する記述として、最も適切なものはどれか。  

ア　<hintTarget>会社法上の計算書類</hintTarget>には、株主資本等変動計算書は含まれない。  

<hint hidden='true'>**
❌会社法上の計算書類は、下記４点です。  
* （１）<hintTarget>BS 貸借対照表</hintTarget>  
* （２）<hintTarget>PL 損益計算書</hintTarget>  
* （３）株主資本等変動計算書  
* （４）個別注記表  
** </hint>

イ　計算書類の作成と報告に当たっては、会社法のほかに<hintTarget>財務諸表規則</hintTarget>（財務諸表等の用語、様式および作成方法に関する規則）に準拠しなければならない。  

<hint hidden='true'>**
❌会社計算規則です
** </hint>

ウ　<hintTarget>公開会社</hintTarget>は、計算書類に加えて連結計算書類を作成し、定時株主総会に報告することが求められている。  

<hint hidden='true'>**
❌<hintTarget>有価証券報告書提出会社である大会社</hintTarget>のみ。
** </hint>

エ　取締役会設置会社は、定時株主総会の招集の通知に際して、株主に計算書類を提供しなければならない。

<hint hidden='true'>**  
⭕適切です。  
** </hint>

`
        },
        {
                "mondaiId": 102010060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　棚卸資産の評価に関する記述として、最も適切なものはどれか。  

ア　棚卸資産の<hintTarget>期末評価</hintTarget>において、帳簿価額と比較すべき時価は<hintTarget>再調達原価</hintTarget>である。  

<hint hidden='true'>**
❌帳簿価額と比較すべき時価は、正味売却価額。
（再調達原価を用いて良いのは、特定の条件を満たした製造業における原材料など一部ケース）
** </hint>

イ　棚卸資産の評価方法として認められている方法のうちに<hintTarget>個別法は含まれない</hintTarget>。  

<hint hidden='true'>**
❌宝飾品などのような、<hintTarget>個別の棚卸資産を明確に判別</hintTarget>が可能な棚卸資産に限り、個別法も認められています。
** </hint>

ウ　棚卸資産の評価方法のうち売価還元法は、取扱品種の極めて多い小売業等の業種において適用される方法である。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

エ　簿価切り下げによる評価損は、<hintTarget>原則</hintTarget>として<hintTarget>営業外費用または特別損失</hintTarget>に計上する。

<hint hidden='true'>**
❌原則は<hintTarget>売上原価または製造原価</hintTarget>です。
** </hint>

`
        },
        {
                "mondaiId": 102010070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　負債の会計処理と開示に関する記述として、最も適切なものはどれか。  

ア　資産除去の義務を伴う有形固定資産を取得した場合、その資産の除去に要する支出額の割引価値を、資産除去債務として負債に計上する。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

イ　支払手形や買掛金は、決算日の翌日から1年以内に支払期限が到来するかどうかを基準として、流動負債と<hintTarget>固定負債</hintTarget>に区分される。  

<hint hidden='true'>**
❌固定負債に計上されません。すべて流動負債です。
**</hint>

ウ　主たる営業活動以外の取引から生じた未払額は、<hintTarget>未払費用</hintTarget>として負債に計上される。  

<hint hidden='true'>**
❌未払金です。いずれも何かの費用や資産購入があり、その対価としての債務が発生した場合に計上するものですが、未払費用は「一定の契約に従い、継続して役務の提供を受ける場合」とされ、実務上は例えば、<hintTarget>給与・賃借料・リース料・利息</hintTarget>などに限られます。
**</hint>

エ　将来における<hintTarget>大地震等</hintTarget>の天災に備えて、災害損失引当金を設定することができる。  

<hint hidden='true'>**
❌引当金は、工場の建物の修繕引当金など、  
・発生の可能性が高い  
・<hintTarget>金額</hintTarget>を合理的に見積もれる  
ものが対象なので、大地震は該当しません。
**</hint>
`
        },
        {
                "mondaiId": 102010080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　決算に当たり、期首に取得した備品<hintTarget>1，200千円</hintTarget>（耐用年数<hintTarget>4年</hintTarget>、残存価額ゼロ）について定額法で減価償却を行った。しかし、この備品の税法上の耐用年数は<hintTarget>6年</hintTarget>であった。このとき、計上される繰延税金資産または繰延税金負債の金額として、最も適切なものはどれか。なお、法人税等の実効税率は<hintTarget>30％</hintTarget>とする。また、期首における一時差異はないものとする。  

<hint hidden='true'>**
税効果会計に関する問題です。
税効果会計とは、会計上の利益に見合った税金費用が計上されるように、<hintTarget>企業</hintTarget>会計と、<hintTarget>税務</hintTarget>会計の違い（ずれ）を調整し、適切に期間配分する手続きです。  
** </hint>  

<hint hidden='true'>**
この問題での計算は下記になります。  
・減価償却費（会計上）  
　＝　1200 ÷ 4　＝　300  
・減価償却費（税法上）  
　＝　1200 ÷ 6　＝　200  
・会計上と税法上の減価償却費のずれ  
　＝　300 － 200　＝　100  
・繰延税金<u>資産</u>額（「会計上」の観点からは、税金を「払いすぎた」状態になるので、負債でなく資産になります。）  
　＝　100　×　実効税率　＝　100×0.3 =　30  
** </hint>

ア　繰延税金資産：30千円<hint hidden='true'>**
←⭕
** </hint>  
イ　繰延税金資産：70千円  
ウ　繰延税金負債：30千円  
エ　繰延税金負債：70千円
`
        },
        {
                "mondaiId": 102010090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　8月中の材料Ｓの取引に関する以下の資料に基づき、材料消費価格差異として最も適切なものを下記の解答群から選べ。なお、材料の<hintTarget>予定消費価格は510円／kg</hintTarget>であり、材料の実際消費額は<hintTarget>総平均法</hintTarget>を用いて計算している。  

【資料】  
8月1日　前月繰越　20kg　500円／kg  
　10日　仕入　30kg　600円／kg  
　25日　出庫　40kg（うち直接材料30kg　間接材料10kg）  
　31日　次月繰越　10kg  

<hint hidden='true'>**
計算は下記になります。
<imgTag>102010090_1.png</imgTag>
**</hint>    

<hint hidden='true'>**
また、上記計算から、予定より実際の原価が高くなった事がわかるため、<hintTarget>不利</hintTarget>差異になります。  
**</hint>    

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【必要知識】
<imgTag>102010090_2.png</imgTag>
&nbsp;
</div>
</hint>

〔解答群〕

ア　1，500円の不利差異  

イ　1，500円の有利差異  

ウ　2，000円の不利差異<hint hidden='true'>**
←⭕
**</hint>    

エ　2，000円の有利差異

`
        },
        {
                "mondaiId": 102010100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　当社では、製品の製造に当たり必要な部品Ｘを1か月に300個自製しているが、Ａ工業から当該部品を1個当たり19千円で販売したいという提案があった。自製の場合と購入の場合ではどちらがいくら有利であるか。次月の予算に関する以下の資料に基づき、最も適切なものを下記の解答群から選べ。  

【資料】  
1．部品Ｘの製造原価  

<TABLE border="1" align="left" width="100%">
<TR>
<TD>変動費</TD>
<TD>@15千円　300個</TD>
<TD>4,500千円</TD>
</TR>
<TR>
<TD>固定費</TD>
<TD></TD>
<TD>2,300千円</TD>
</TR>
<TR>
<TD>合計</TD>
<TD></TD>
<TD>6,800千円</TD>
</TR>
</TABLE>
  

2．固定費には部品Ｘの製造に必要な特殊機械の賃借料<hintTarget>900</hintTarget>千円が含まれているが、部品Ｘを<hintTarget>購入する場合には不要</hintTarget>となる。  

<hint hidden='true'>**
計算式は下記になります。  
自製の場合＝変動費＋固定費＋購入原価  
　　　　　＝4500＋2300＋0  
　　　　　＝6800  
購入の場合＝変動費＋固定費＋購入原価  
　　　　　＝0＋(2300－<hintTarget>900</hintTarget>)＋(300×19)  
　　　　　＝7100  
**</hint>  

〔解答群〕  

ア　購入の方が200千円有利  
イ　購入の方が1,100千円有利  
ウ　自製の方が300千円有利<hint hidden='true'>**
←⭕
**</hint>    
エ　自製の方が1,200千円有利
`
        },
        {
                "mondaiId": 102010111,
                "name": "第11問設問１",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　当社の貸借対照表および損益計算書は以下のとおりであった。下記の設問に答え  
よ。  

<imgTag>102010111_1.png</imgTag>  


（設問1）  
20X2年の<hintTarget>固定比率</hintTarget>の値として、最も適切なものはどれか。  
<hint hidden='true'>**
公式は下記です。  
固定比率　＝　<hintTarget>固定資産</hintTarget>÷<hintTarget>自己資本</hintTarget>×100  
**</hint>  

ア　54％  
イ　77％  
ウ　120％<hint hidden='true'>**
←⭕
**</hint>    
エ　216％
`
        },
        {
                "mondaiId": 102010112,
                "name": "第11問設問２",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　当社の貸借対照表および損益計算書は以下のとおりであった。下記の設問に答えよ。  

<imgTag>102010112_1.png</imgTag>  


（設問2）

20X1年から20X2年の総資本営業利益率の変化とその要因に関する記述とし  
て、最も適切なものはどれか。  

<hint hidden='true'>**
公式は下記です。  
総資本営業利益率　＝　営業利益÷<hintTarget>総資本</hintTarget>×100  
売上高営業利益率　＝　<hintTarget>営業利益</hintTarget>÷<hintTarget>売上高</hintTarget>×100  
総資本回転率　＝　<hintTarget>売上高</hintTarget>÷総資本×100  
**</hint>  

ア総資本営業利益率は上昇したが、その要因は売上高営業利益率の上昇である。  
イ総資本営業利益率は上昇したが、その要因は総資本回転率の上昇である。<hint hidden='true'>**
←⭕
**</hint>    
ウ総資本営業利益率は低下したが、その要因は売上高営業利益率の低下である。  
エ総資本営業利益率は低下したが、その要因は総資本回転率の低下である。
`
        },
        {
                "mondaiId": 102010120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　<hintTarget>有形固定資産を売却</hintTarget>することで得た資金の全額を、<hintTarget>長期借入金の返済</hintTarget>にあてたとする。他の条件を一定とすると、これによるキャッシュ・フロー計算書および財務比率への影響に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　財務活動によるキャッシュ・フローは減少する。  

<hint hidden='true'>**
⭕適切な記述です。問題文の長期借入金の返済は、下表の該当箇所に影響します。
**</hint>  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】キャッシュフロー計算書  
<TABLE border="5" bordercolor="#ffffff" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD style='background-color:#ddddff;'>科目</TD>
<TD style='background-color:#bbbbff;'>金額</TD>
</TR>

<TR style='background-color:#dddddd;'>
<TD>I 営業活動によるキャッシュフロー</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　税引前当期純利益</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　減価償却費</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　売上債権の増減額</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　棚卸資産の増減額</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　その他資産の増減額</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　仕入債務の増減額</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　その他負債の増減額</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　法人税等の支払額</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　計</TD>
<TD>　</TD>
</TR>

<TR style='background-color:#dddddd;'>
<TD>II 投資活動によるキャッシュフロー</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　投資有価証券の取得による支出</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　投資有価証券の売却による収入</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　有形固定資産の取得による支出</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　有形固定資産の売却による収入<hint hidden='true'>**
←記述c
**</hint>  </TD>
<TD>　</TD>
</TR>

<TR>
<TD>　その他固定資産</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　計</TD>
<TD>　</TD>
</TR>

<TR style='background-color:#dddddd;'>
<TD>III 財務活動によるキャッシュフロー</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　短期借入による収入</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　短期借入金の返済による支出</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　長期借入による収入</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　長期借入金の返済による支出<hint hidden='true'>**
←記述a
**</hint></TD>
<TD>　</TD>
</TR>

<TR>
<TD>　株式の発行による収入</TD>
<TD>　</TD>
</TR>

<TR>
<TD>　計</TD>
<TD>　</TD>
</TR>

<TR style='background-color:#dddddd;'>
<TD>IV 当期キャッシュフローの増減額</TD>
<TD>　</TD>
</TR>

<TR style='background-color:#dddddd;'>
<TD>V 現金及び元気同等物の期首残高</TD>
<TD>　</TD>
</TR>

<TR style='background-color:#dddddd;'>
<TD>VI 現金及び元気同等物の期末残高</TD>
<TD>　</TD>
</TR>


</TABLE>
</div>
</hint>


ｂ　自己資本比率は上昇する。  

<hint hidden='true'>**
⭕自己資本比率　＝　<hintTarget>自己資本　÷　総資本</hintTarget> × 100  
であり、本ケースでは総資本が減少するため、適切です。
**</hint>  

ｃ　投資活動によるキャッシュ・フローは減少する。  

<hint hidden='true'>**
❌増加します。問題文の有形固定資産の売却は、上の表の該当箇所に影響します。
**</hint>

ｄ　流動比率は<hintTarget>上昇</hintTarget>する。  

<hint hidden='true'>**
❌流動比率　＝　<hintTarget>流動資産　÷　流動負債</hintTarget>　×　100  
であり、本ケースでは、分母分子ともに変わりません。
**</hint>


〔解答群〕  
ア　ａとｂ<hint hidden='true'>**
←⭕
**</hint>  
イ　ａとｃ  
ウ　ａとｄ  
エ　ｂとｃ  
オ　ｃとｄ
`
        },
        {
                "mondaiId": 102010130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下の図は、横軸にリスク、縦軸にリターンを取ったリスク・リターン平面上に、資産Ａから資産Ｄのそれぞれのリスクとリターンをプロットしたものである。  
　このとき、図中にある<hintTarget>無差別曲線</hintTarget>を有する<hintTarget>投資家</hintTarget>が、保有する際に最も望ましいと考えられる資産として、最も適切なものを下記の解答群から選べ。  

<hint hidden='true'>**
無差別曲線が、いわば効用の<hintTarget>等高線</hintTarget>のつもりで解釈するとイメージしやすい問題です。  
この投資家にとって、例えば、図中のBとCは同等の効用とみなされるため、リスク回避的と考えられます。   
**</hint>  
<imgTag>102010130_1.png</imgTag>    


〔解答群〕  
ア　Ａ<hint hidden='true'>**
←⭕
**</hint>    
イ　Ｂ  
ウ　Ｃ  
エ　Ｄ  
`
        },
        {
                "mondaiId": 102010140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　オプションに関する記述として、<u><u>最も不適切なものはどれか</u></u>。  

ア　オプションの価格は、オプションを行使した際の価値、すなわち本質的価値と時間的価値から成り立っている。  

<hint hidden='true'>**
適切な記述です。  
本質的価格、時間的価格のイメージは下記になります。  **
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
（コールオプションの場合）本質的価値、時間的価値、アウトオブザマネー、アット・ザ・マネー、イン・ザ・マネー  
<imgTag>102010140_1.png</imgTag>  
</div>
</hint>

イ　オプションの時間的価値はアット・ザ・マネーのとき、最大となる。  

<hint hidden='true'>**
適切な記述です。アット・ザ・マネーとは、オプションの原資産の価格が、そのオプションの権利行使価格に等しい価格であることをいいます。
**</hint>

ウ　コールオプションにおいて、原資産価格が行使価格を上回っている状態を、イン・ザ・マネーと呼ぶ。  

<hint hidden='true'>**
適切な記述です。
**</hint>

エ　本質的価値がゼロであっても、時間的価値が正であれば、オプションを行使する価値が<hintTarget>ある</hintTarget>。

<hint hidden='true'>**
❌本質的価値＝原資産価値－権利行使価格  
なので、本質的価値が０の場合は、
例えば、コールオプションであれば、  
市場で買う価格　≦　オプションを講師することで”この値段で変えます”とされる価格  
なので、オプションを行使する価値がない。
**</hint>  

`
        },
        {
                "mondaiId": 102010150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　ポートフォリオに関する記述として、最も適切なものはどれか。  

ア　安全資産とはリスクのない資産であると定義される。<hint hidden='true'>**
←⭕
**</hint>    

イ　安全資産と有効フロンティア上の任意の点で新しいポートフォリオを作ることにした。このとき、新たなポートフォリオのリスクとリターンの組み合わせは曲線となる。  

ウ　安全資産と有効フロンティア上の任意の点で作られる最も望ましいリスク・リターンの組み合わせを証券市場線という。  

エ　危険資産のみから構成されるポートフォリオの集合のうち、リスク・リターンの面から望ましい組み合わせのみを選んだ曲線を投資機会集合という。

`
        },
        {
                "mondaiId": 102010160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ａ社は新社屋の完成に当たって、20年間の火災保険契約を保険会社と結ぶことにした。保険会社によって、⑴保険料300万円を一括して支払う「一括払」タイプ、⑵20回払いで、契約時に20万円、それ以降は年末に20万円ずつ支払う「分割払」タイプの2種類から選ぶことができる。契約時点は年初であり、支払額以外の契約条件は同一である。  

　この保険契約でＡ社が選ぶべき支払額のタイプおよびその現在価値の組み合わせとして、最も適切なものはどれか。なお、割引率は5％とし、そのときの年金現価係数は、19年の場合には12、20年の場合には12.5を用いること。  

ア　「一括払」：300万円  
イ　「分割払」：250万円  
ウ　「分割払」：260万円
<hint hidden='true'>**
←⭕
**</hint>   
エ　「分割払」：400万円

`
        },
        {
                "mondaiId": 102010170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章は、Ｘ、Ｙの2資産から構成されるポートフォリオのリターンとリスクの変化について、説明したものである。空欄Ａ～Ｄに入る語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

　以下の図は、Ｘ、Ｙの2資産から構成されるポートフォリオについて、投資比率をさまざまに変化させた場合のポートフォリオのリターンとリスクが描く軌跡を、2資産間のＡが異なる4つの値について求めたものである。  

　Ｘ、ＹのＡがＢのとき、ポートフォリオのリターンとリスクの軌跡は①に示されるように直線となる。ＡがＣなるにつれて、②、③のようにポートフォリオのリスクをより小さくすることが可能となる。  
　ＡがＤのとき、ポートフォリオのリスクをゼロにすることが可能となり、④のような軌跡を描く。  


<imgTag>102010170_1.png</imgTag>  

〔解答群〕  
ア　Ａ：相関係数　Ｂ：-1　　Ｃ：大きく　Ｄ：ゼロ  
イ　Ａ：相関係数　Ｂ：+1　　Ｃ：小さく　Ｄ：-1<hint hidden='true'>**
←⭕
**</hint>   
ウ　Ａ：ベータ値　Ｂ：ゼロ　Ｃ：大きく　Ｄ：+1  
エ　Ａ：ベータ値　Ｂ：+1　　Ｃ：小さく　Ｄ：-1  

`
        },
        {
                "mondaiId": 102010180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　金利に関する記述として、最も適切なものはどれか。  

ア　将来の利払い額が変動するリスクを考慮すると、固定金利での借り入れが常に有利である。  

イ　日本における短期金利の代表的なものとして、インターバンク市場で取引される公定歩合がある。  

ウ　名目金利とは、実質金利から物価上昇率（インフレ率）を控除した金利水準を指す。  

エ　歴史的に長期金利と短期金利では、長期金利の方が高い傾向にあるが、金利水準の低下局面では逆のケースも観察されている。<hint hidden='true'>**
←⭕
**</hint> 


`
        },
        {
                "mondaiId": 102010190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　自己資本利益率（ROE）は、次のように分解される。  

<imgTag>102010190_1.png</imgTag>   


この式に関する記述として、最も適切なものはどれか。  

ア  
<imgTag>102010190_2.png</imgTag>は、加重平均資本コスト（WACC）と解釈される。  


イ  
<imgTag>102010190_3.png</imgTag>が小さくなっても、ROEが低くなるとは限らない。<hint hidden='true'>**
←⭕
**</hint> 
  

ウ  
<imgTag>102010190_3.png</imgTag>は、株価収益率（PER）である。  


エ　
ROEが<imgTag>102010190_2.png</imgTag>を上回る場合には、株価は1株当たり自己資本簿価より小さくなる。

`
        },
        {
                "mondaiId": 102010200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　資金調達に関する記述として、最も適切なものはどれか。  

ア　企業が証券会社や証券市場を介して、投資家に株式や債券を購入してもらうことで資金を集める仕組みを間接金融と呼ぶ。  

イ　資金調達における目的は、売上高の極大化である。  

ウ　資産の証券化は、資金調達手段として分類されない。  

エ　利益の内部留保や減価償却による資金調達を内部金融と呼ぶ。<hint hidden='true'>**
←⭕
**</hint> 

`
        },
        {
                "mondaiId": 102010210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　負債と純資産の構成が2：1の企業がある。この企業の税引前負債資本コストが3％（税率は30％）、株主資本コストが12％であるときの加重平均資本コストとして、最も適切なものはどれか。  

ア3.8％  
イ5.4％<hint hidden='true'>**
←⭕
**</hint>    
ウ7.5％  
エ9.0％
`
        },
        {
                "mondaiId": 102010221,
                "name": "第22問設問１",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ａ社は、5，000万円の資金を必要とする新規事業を始めようとしている。この投資により毎期300万円の営業利益を確実に得ることができ、この営業利益はフリーキャッシュフローに等しいものとする。今、5，000万円の資金を調達するために、次の2つの相互排他的資金調達案が提案されている。  

　MM理論が成り立つものとして、下記の設問に答えよ。  

（第1案）5，000万円すべて株式発行により資金調達する。  
（第2案）2，500万円は株式発行により、残額は借り入れにより資金調達する。なお、利子率は5％である。  


（設問1）
第2案の自己資本利益率として、最も適切なものはどれか。ただし、法人税は存在しないものとする。  

ア6％  
イ7％<hint hidden='true'>**
←⭕
**</hint>    
ウ8％  
エ12％
`
        },
        {
                "mondaiId": 102010222,
                "name": "第22問設問２",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ａ社は、5，000万円の資金を必要とする新規事業を始めようとしている。この投資により毎期300万円の営業利益を確実に得ることができ、この営業利益はフリーキャッシュフローに等しいものとする。今、5，000万円の資金を調達するために、次の2つの相互排他的資金調達案が提案されている。  

　MM理論が成り立つものとして、下記の設問に答えよ。  

（第1案）5，000万円すべて株式発行により資金調達する。  
（第2案）2，500万円は株式発行により、残額は借り入れにより資金調達する。なお、利子率は5％である。  


（設問2）  
法人税が存在する場合、（第2案）の企業価値は（第1案）のそれと比べていくら  
差があるか、最も適切なものを選べ。ただし、法人税率は30％とする。  

ア（第2案）と（第1案）の企業価値は同じ。  
イ（第2案）の方が（第1案）より125万円低い。  
ウ（第2案）の方が（第1案）より125万円高い。  
エ（第2案）の方が（第1案）より750万円高い。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 102010230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 102,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　投資評価基準に関する記述として、最も適切なものはどれか。  

ア　会計的投資利益率法に使われる会計利益には減価償却費を計算に入れない。  
イ　回収期間法における回収期間とは、プロジェクトの経済命数のことである。  
ウ　正味現在価値はパーセントで表示される。  
エ　正味現在価値法と内部収益率法は共にDCF法であるが、同一の結論を導くとは限らない。<hint hidden='true'>**
←⭕
**</hint>    
`
        },
        {
                "mondaiId": 103010010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　多角化して複数の事業を営む企業の企業ドメインと事業ドメインの決定に関する記述として、最も適切なものはどれか。  


ア　<hintTarget>企業ドメイン</hintTarget>の決定は、<hintTarget>個々の事業の定義を足し合わせるのではなく</hintTarget>、外部の利害関係者との間のさまざまな相互作用の範囲を反映し、事業の定義を見直す契機となる。
 
<hint hidden='true'>**
⭕個々の事業の最適化ではなく、外部の利害関係者を含めた事業ポートフォリオの最適化といった全社最適について述べられている記述であるため、企業ドメインの説明として適切です。**</hint>

イ　<hintTarget>企業</hintTarget>ドメインの決定は、新規事業進出分野の中心となる<hintTarget>顧客セグメント</hintTarget>選択の判断に影響し、<hintTarget>競争戦略</hintTarget>策定の出発点として差別化の基本方針を提供する。  
  
<hint hidden='true'>**
❌競争戦略や競合との競争戦略といった、個々の事業ごとの最適化に影響するのは、<hintTarget>事業</hintTarget>ドメインです。
**</hint>
  
ウ　<hintTarget>事業</hintTarget>ドメインの決定は、<hintTarget>将来</hintTarget>手がける事業をどう定義するかの決定であり、日常のオペレーションに直接関連し、<hintTarget>全社戦略策定</hintTarget>の第一歩として競争戦略に結び付ける役割を果たす。  

<hint hidden='true'>**
❌「将来」に手掛ける事業や、全社戦略策定に関わるのは、<hintTarget>企業</hintTarget>ドメインです。  
事業ドメインは主に「現在」手掛ける事業を決定します。
**</hint>

エ　<hintTarget>事業</hintTarget>ドメインの決定は、<hintTarget>多角化</hintTarget>の広がりの程度を決め、部門横断的な活動や製品・事業分野との関連性とともに、<hintTarget>将来の企業のあるべき姿</hintTarget>や経営理念を包含している存続領域を示す。  

<hint hidden='true'>**
❌このような全社最適をはかるドメインは、<hintTarget>企業</hintTarget>ドメインです。
**</hint>

オ　<hintTarget>事業</hintTarget>ドメインの決定は、特定市場での競争戦略に影響を受け、将来の事業領域の範囲をどう定義するかについて、企業が自らの相互作用の対象として選択した<hintTarget>事業ポートフォリオ</hintTarget>の決定である。

<hint hidden='true'>**
❌事業ポートフォリオのような、企業の全体最適の戦略を決めるのは、企業ドメインです。
**</hint>


  
<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【必要知識】
企業ドメインと、事業ドメインの対比

<imgTag>103010010_2.png</imgTag>  


<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border:1px solid black;'>
企業ドメイン</TD>
<TD style='background-color:#ffffff;color:#3880ff;'>　 　</TD>
<TD style='background-color:#dddddd;color:#3880ff;border:1px solid black;'>事業ドメイン</TD>
</TR>

<TD style='background-color:#ffffff;color:#3880ff;border:1px solid black;'>
事業の組み合わせ（ポートフォリオ）</hintTarget>を最適化</TD>
<TD style='background-color:#ffffff;color:#3880ff;'>　何を最適化するか？　</TD>
<TD style='background-color:#ffffff;color:#3880ff;border:1px solid black;'>個々の事業を最適化</TD>
</TR>
<TR>
<TD style='background-color:#ffffff;color:#3880ff;border:1px solid black;'>全社戦略</TD>
<TD style='background-color:#ffffff;color:#3880ff;'>　どのような範囲の戦略を定義する？　</TD>
<TD style='background-color:#ffffff;color:#3880ff;border:1px solid black;'><hintTarget>競争</hintTarget>戦略</TD>
</TR>
</TABLE>  
&nbsp;
</div>
</hint>


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】エーベルの三次元事業定義モデル  
エーベルの三次元事業定義モデルとは、事業ドメイン（事業の領域）がどこであるかということを、下記の３つの次元に基づいてモデル化するものです。  
(1) to whom＝誰に？（顧客セグメント）  
(2) what＝何を？（顧客機能）  
(3) how＝<hintTarget>どのように？（技術）</hintTarget>
</div>
</hint>


`
        },
        {
                "mondaiId": 103010020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　プロダクト・ポートフォリオ・マネジメント（PPM）に関する記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】プロダクト・ポートフォリオ・マネジメント（PPM）  
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD align="right">高</TD>
<TD>　</TD>
<TD>　</TD>
<TD>　</TD>
</TR>
<TR>
<TD align="right"><span style="font-size:3em">📈</span><hintTarget>市場</hintTarget>成長率</TD>
<TD style='border: 1px solid gray;' align="center"><span style="font-size:3em">👶</span>問題児  
</TD>
<TD style='border: 1px solid gray;' align="center"><span style="font-size:3em">🌸</span>花形  
市場シェアを維持するために必要な再投資　<hintTarget>≒</hintTarget>　得られるキャッシュフロー
</TD>
<TD>　</TD>
</TR>
<TR>
<TD align="right">低</TD>
<TD style='border: 1px solid gray;' align="center"><span style="font-size:3em">🐕</span>負け犬</TD>
<TD style='border: 1px solid gray;' align="center">
<span style="font-size:3em">🌳</span>金のなる木  
市場シェアを維持するために必要な再投資　<hintTarget>＜</hintTarget>　得られるキャッシュフロー
</TD>
<TD>　</TD>
</TR>
<TR>
<TD>　</TD>
<TD>低</TD>
<TD>市場占有率</TD>
<TD>高</TD>
</TR>

</TABLE>
</div>
</hint>

ア　プロダクト・ポートフォリオ・マネジメントでは、<hintTarget>自社</hintTarget>の事業の成長率と相対的な市場シェアとを基準として事業を分類し、戦略事業単位が他の戦略事業単位と製品や市場について相互に関連した統合的な戦略を持つ。  
<hint hidden='true'>**
❌PPMの縦軸は、<hintTarget>市場の成長率です。</hintTarget>
**</hint>  

イ　プロダクト・ポートフォリオ・マネジメントでは、成長市場で市場シェアを維持するために必要な<hintTarget>再投資を大きく上回るキャッシュフローをもたらし</hintTarget>、資金の投入によって競争優位を維持する「<hintTarget>花形</hintTarget>」よりも、資金の流出を削減して競争優位を獲得できる「問題児」の選択が重要である。  
<hint hidden='true'>**
❌これは<hintTarget>金のなる木</hintTarget>の記述です。
**</hint>  

ウ　プロダクト・ポートフォリオ・マネジメントでは、「花形」は分野の将来性に大きな魅力があり、特定の事業に対する集中的な投資の主要な<hintTarget>資金供給源</hintTarget>としても重要であり、<hintTarget>「負け犬」からの撤退を支える</hintTarget>役割を果たす。  
<hint hidden='true'>**
❌資金供給源となるのは、下図の通り、金のなる木です。  
また、花形が負け犬からの撤退を支えるといった役割について、PPMで特に規定していません。**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/103003_1.png" target="_blank" >
<img src="assets/img/kaisetu/103003_1.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>  

エ　プロダクト・ポートフォリオ・マネジメントは、事業間のマーケティングや技術に関するシナジーが考慮されていないが、外部技術の導入によって規模の経済を達成することで優位性を構築する事業にも適用できる。  
<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

オ　プロダクト・ポートフォリオ・マネジメントは、全社的な資源配分の論理の1つとして位置づけられ、<hintTarget>成長率の鈍化した業界の「花形」</hintTarget>事業の大きな余剰資金と「負け犬」を売却して得た資金を<hintTarget>「金のなる木」に集中的に投入</hintTarget>して競争優位を維持する。  
<hint hidden='true'>**
❌成長率が鈍化し、余剰資金を得られる領域は、上図の通り、<hintTarget>花形の売却でなく、金のなる木です。</hintTarget>
**</hint>  
<hint hidden='true'>**
また、資金を投入すべきは、上図の通り、<hintTarget>金のなる木ではなく、花形と問題児です。</hintTarget>
**</hint>

`
        },
        {
                "mondaiId": 103010030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄Ａ～Ｄに入る用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】アンゾフの意思決定論  
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD align="center"></TD>
<TD align="center">不規則性、外部志向、創造的、影響大</TD>
</TR>
<TR>
<TD align="center"></TD>
<TD align="center">↑</TD>
</TR>

<TR>
<TD align="right">トップマネジメント</TD>
<TD style='border: 1px solid gray;' align="center">
戦略的意思決定：　企業外部の問題や環境変化に適応するための意思決定 
</TD>
</TR>

<TR>
<TD align="right">ミドルマネジメント</TD>
<TD style='border: 1px solid gray;' align="center">
<hintTarget>管理</hintTarget>的意思決定：　企業内部の経営資源調達・経営資源配分  
</TD>
</TR>

<TR>
<TD align="right">ロワーマネジメント</TD>
<TD style='border: 1px solid gray;' align="center">
<hintTarget>業務</hintTarget>的意思決定：　日々の業務を効率的に行うための意思決定
</TD>
</TR>

<TD align="center"></TD>
<TD align="center">↓</TD>
</TR>

<TR>
<TD align="center"></TD>
<TD align="center">反復的、内部志向、論理的、影響小</TD>
</TR>
<TR>


</TABLE>
</div>
</hint>

　アンゾフは、環境変化が激しく、企業が決定すべき選択肢の評価基準も与えられていない高度に不確実な状況を、（　Ａ　<hint hidden='true'>**
部分的無知
**</hint>）という概念で捉え、（　Ａ　<hint hidden='true'>**
部分的無知
**</hint>）の状況下において、企業が取り組むべき問題を確定させ、その問題解決の方向性を探求することを経営戦略論の固有の課題と示した。  

　その上で、企業が行っている意思決定を、（　Ｂ　<hint hidden='true'>**
業務
**</hint>）的意思決定、（　Ｃ　<hint hidden='true'>**
管理
**</hint>）的意思決定、そして（　Ｄ　<hint hidden='true'>**
戦略
**</hint>）的意思決定に分類した。（　Ｂ　<hint hidden='true'>**
業務
**</hint>）的意思決定は、現行の業務の収益性の最大化を目的とするもの、（　Ｃ　<hint hidden='true'>**
管理
**</hint>）的意思決定は、最大の業績が生み出せるように企業の資源を組織化するもの、（　Ｄ　<hint hidden='true'>**
戦略
**</hint>）的意思決定は、将来どのような業種に進出すべきかなどに関するものである。  

〔解答群〕  
ア　Ａ：非対称情報　Ｂ：業務　Ｃ：組織　Ｄ：戦略  
イ　Ａ：非対称情報　Ｂ：日常　Ｃ：管理　Ｄ：計画  
ウ　Ａ：非対称情報　Ｂ：日常　Ｃ：組織　Ｄ：長期  
エ　Ａ：部分的無知　Ｂ：業務　Ｃ：管理　Ｄ：戦略 <hint hidden='true'>**
←⭕適切です。  
**</hint>    
オ　Ａ：部分的無知　Ｂ：業務　Ｃ：戦略　Ｄ：長期
`
        },
        {
                "mondaiId": 103010040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ｇ．ハメルとＣ．Ｋ．プラハラードによるコア・コンピタンスに関する記述として、最も適切なものはどれか。  

ア　コア・コンピタンスは、企業内部で育成していくものであるため、コア・コンピタンスを構成するスキルや技術を使った製品やサービス間で競争が行われるものの、コア・コンピタンスの構成要素であるスキルや技術を獲得するプロセスで<hintTarget>企業間の競争が起きることはない。</hintTarget>  

<hint hidden='true'>**
❌不適切です。理由：<hintTarget>企業間の競争が発生するのは、コアコンピタンスによって生み出された製品やサービスはもちろん、コアコンピタンスの構成要素を獲得するプロセスにおいても、企業間の競争が発生し得ます。  
むしろコアコンピタンスの概念が重視するのは、優れた製品やサービスなどの目に見えるものだけでなく、それらを生み出す背景です。  
例えば、コアコンピタンスの有名な事例に、🚙ホンダのエンジン技術があります。  
低公害化・高性能化・小型化といった新製品の開発につながっています（完成車などの製品はコアコンピタンスの対象ではない）。</hintTarget>**
</hint>

イ　コア・コンピタンスは、<hintTarget>企業の未来</hintTarget>を切り拓くものであり、所有するスキルや技術が現在の製品やサービスの競争力を支えていることに加えて、そのスキルや技術は将来の新製品や新サービスの開発につながるようなものであることが必要である。  

<hint hidden='true'>**
⭕適切です。戦略分析にあたり、現時点に限定しない時間幅で強みを分析する点が、コアコンピタンスの特徴です。
**</hint>

ウ　コア・コンピタンスは、顧客が認知する価値を高めるスキルや技術の集合体であるから、その価値をもたらす個々のスキルや技術を<hintTarget>顧客も理解</hintTarget>していることが必要である。  

<hint hidden='true'>**
❌不適切です。理由：<hintTarget>ここのスキルや技術を顧客が理解していることは必要ではありません。むしろ理解されず潜在的であるほうが、模倣されるリスクを低減考えられます。</hintTarget>**
</hint>

エ　コア・コンピタンスは、他の競争優位の源泉となり得る生産設備や特許権のような会計用語上の「資産」ではないので、貸借対照表上に表れることはなく、コア・コンピタンスの価値が<hintTarget>減少することもない</hintTarget>。  

<hint hidden='true'>**
❌不適切です。理由：<hintTarget>のれんの勘定科目のように、コアコンピタンスがあらわされうるものもある。また、外部環境の大幅な変化などで、コアコンピタンスの価値が減少する可能性がないとは言えない。</hintTarget>**
</hint>

オ　コア・コンピタンスは、ユニークな競争能力であり、個々のスキルや技術を束ねたものであるから、<hintTarget>束ねられたスキル</hintTarget>や技術を独占的に所有していることに加えて、競合会社の模倣を避けるために<hintTarget>個々</hintTarget>のスキルや技術も独占的に所有していることが必要である。

<hint hidden='true'>**
❌不適切です。理由：<hintTarget>個々のスキルの独占までは、必要ではありません。”束ねられた”スキルを独占的に所有していて、模倣されにくいことは必要です。</hintTarget>**
</hint>

`
        },
        {
                "mondaiId": 103010050,
                "name": "第5問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　戦略的提携に関する記述として、最も適切なものはどれか。  

<hint hidden='true'>**  
👨🏻‍🤝‍👨🏿戦略的提携とは、<hintTarget>契約に基づく柔軟</hintTarget>な連携です。  
** </hint>

ア　戦略的提携では、大学や政府機関が参加することは<hintTarget>ない</hintTarget>が、同一の業種で競争関係にある企業間よりも異業種の企業間での提携が多く、<hintTarget>継続的</hintTarget>な関係の構築が図られる。  

<hint hidden='true'>**  
❌不適切です。大学や政府機関が参加することもあります。また、継続性はケースバイケースの契約次第です。</hintTarget>  
** </hint>

イ　戦略的提携は、共同開発や合弁事業設立のように、企業が独立性を維持して緩やかな結びつきを構築するが、資本参加や当該企業同士の<hintTarget>組織的な統合</hintTarget>を通じて経営資源の合体を図る。  

<hint hidden='true'>**  
❌不適切です。戦略的提携は、あくまで契約に基づく柔軟な連携であるため、組織的な統合を必要としません。  
** </hint>

ウ　戦略的提携は、提携による協力で得られる恩恵を最大限享受できる<hintTarget>組織的な統合</hintTarget>を図り、業界内の新しいセグメントや新たな業界への低コストでの参入と経営資源の補完を主な目的とする。  

<hint hidden='true'>**  
❌不適切です。戦略的提携は、あくまで契約に基づく柔軟な連携であるため、組織的な統合を必要としません。  
** </hint>

エ　戦略的提携は、当事者間での裏切りのリスクを内包するが、その回避のために、企業には互いの独立性を維持しつつも、<hintTarget>階層関係を構築して関係の固定化</hintTarget>を図ることが求められる。  

<hint hidden='true'>**  
❌不適切です。戦略的提携において、階層関係の固定化は必須ではありません。むしろ柔軟な提携の阻害となるとも考えられます。  
** </hint>

オ　戦略的提携は、<hintTarget>範囲の経済</hintTarget>を利用できる内部開発によるコストよりも、共同開発のような提携によるコストが小さい場合、内部開発に代わって選択される。

<hint hidden='true'>**  
⭕適切です。<hintTarget>別個に内部開発をするより、提携先と共同開発することでコストを下げられ</hintTarget>れば、範囲の経済を享受できます。  
** </hint>

`
        },
        {
                "mondaiId": 103010060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　「業界の構造分析」の枠組みに基づいて想定される、既存企業間での対抗度に関する予測として、最も適切なものはどれか。  

ア　業界の成長率が<hintTarget>高い</hintTarget>と、製品市場での競合が激化して、業界全体の潜在的な収益性は低くなる。  

<hint hidden='true'>**
❌不適切です。<hintTarget>低い</hintTarget>場合の記述です。  
** </hint>

イ　顧客側で生じるスイッチングコストが高い業界では、製品市場での競合が緩和されて、業界全体の潜在的な収益性は高くなる。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

ウ　<hintTarget>固定費が高い</hintTarget>業界では、製品市場での競合が緩和されて、業界全体の潜在的な<hintTarget>収益性は高く</hintTarget>なる。  

<hint hidden='true'>**
❌不適切です。  
高い固定費を賄わなければならない  
→<hintTarget>販売量を増やして原価を減らそうとする</hintTarget>  
→収益性低下　となります。  
** </hint>

エ　事業戦略の方向性という点で、<hintTarget>多様なバックグラウンド</hintTarget>を有する企業が事業を展開する業界では、製品市場での競合が緩和されて、業界全体の潜在的な<hintTarget>収益性は高く</hintTarget>なる。  

<hint hidden='true'>**
❌不適切です。  
多様なバックグラウンドの企業が革新的な改善を行う  
→<hintTarget>競争が激化</hintTarget>  
→収益性低下　となります。
** </hint>

オ　<hintTarget>退出障壁が高い</hintTarget>と、製品市場での競合が緩和されて、業界全体の潜在的な<hintTarget>収益性は高く</hintTarget>なる。

<hint hidden='true'>**
❌不適切です。  
退出障壁が高い  
→<hintTarget>競争激化したときに、収益圧迫しても撤退しない</hintTarget>  
→収益性低下　となります。
** </hint>

`
        },
        {
                "mondaiId": 103010070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　経験効果や規模の経済に関する記述として、最も適切なものはどれか。  

ア　経験効果に基づくコスト優位を享受するためには、競合企業を上回る市場シェアを<hintTarget>継続的</hintTarget>に獲得することが、有効な手段となり得る。  

<hint hidden='true'>**  
⭕適切です。継続することで、1個当たりの製造時間削減や、良品率改善などでコスト優位を享受できます。  
** </hint>

イ　<hintTarget>経験効果</hintTarget>は、ある<hintTarget>一時点</hintTarget>での規模の大きさから生じるコスト優位として定義されることから、経験効果が生じる基本的なメカニズムは、規模の経済と同じである。

<hint hidden='true'>**  
❌一時点の規模の大きさがコスト削減の源泉になるという考え方は、規模の経済です。経験効果は、継続的な経験がコスト削減の源泉と考えます。  
**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】規模の経済　※👈アニメーションしない場合クリックしてください  
<imgTag>103010070_1.gif</imgTag>  
</div>

</hint>

<hint hidden='true'>**  
経験効果とは、ある製品について、生産量が増えるに従って、１個あたりのコストが低減する効果のことです。  
習熟や工程の改善などだけでなく、生産設備の投資といった生産機能の向上によっても経験効果が発生します。  
例えば、もともと1ヶ月で100個生産していた製品を200個に増産する場合、製品１個あたりの家賃を、規模の経済と経済効果で比較する（それぞれの案の相対評価で）と下表のようになります。
**

<TABLE border="1" align="left" style='background-color:#ffddff;color:#cc0000;'>  
<TR>
<TD style='background-color:#ffffff;color:#3880ff;'>
</TD>
<TD style='background-color:#ffffff;color:#3880ff;' >規模の経済</TD>
<TD style='background-color:#ffffff;color:#3880ff;' >経験効果</TD>
</TR>

<TR>
<TD style='background-color:#ffffff;color:#3880ff;'>
案１  
１ヶ月で、２００個生産する場合
</TD>
<TD style='background-color:#ffffff;color:#3880ff;' >発生する  
理由：<hintTarget>１個あたりの家賃コストは半額になるから</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;' >（案２よりは）発生しない</TD>
</TR>
<TR>

<TD style='background-color:#ffffff;color:#3880ff;'>
案２  
１ヶ月ごとに、１００個ずつの生産を２ヶ月続ける場合
</TD>
<TD style='background-color:#ffffff;color:#3880ff;' >発生しない</TD>
<TD style='background-color:#ffffff;color:#3880ff;' >（案１よりは）発生する  
理由：<hintTarget>案１と比較すると、２ヶ月分の経験による効率化が期待できるから</hintTarget></TD>
</TR>
</TABLE>  
</hint>

ウ　生産工程を保有しないサービス業では、経験効果は競争優位の源泉に<hintTarget> ならない。</hintTarget> 

<hint hidden='true'>**
❌例えば、サービス業を営むある企業において、接客の応対品質が向上することで、単位時間あたりの対応顧客数が他社より圧倒的に優位になるといったケースが考えられます。
** </hint>

エ　中小企業では、企業規模が小さいことから、規模の経済に基づく競争優位を求めることは<hintTarget>できない。</hintTarget>  

<hint hidden='true'>**
❌例えば、企業規模が小さくとも、特定の部品などの生産を集中することで、規模の経済に基づく競争優位を求めることが可能です。
** </hint>

オ　同一企業が<hintTarget>複数の事業</hintTarget>を展開することから生じる「シナジー効果」は、<hintTarget>規模の経済</hintTarget>を構成する中心的な要素の1つである。

<hint hidden='true'>**  
❌複数の事業や製品展開により生じる、<u>範囲の経済</u>に関する記述です。  
**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】範囲の経済　※👈アニメーションしない場合クリックしてください  
<imgTag>103010070_2.gif</imgTag>  
</div>
</hint>
`
        },
        {
                "mondaiId": 103010081,
                "name": "第8問設問１",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　コンピュータのソフトウェアやコンテンツなどのデジタル化された情報財は、製品開発費などの固定費が占める比率が（　Ａ<hint hidden='true'>**高** </hint>　）く、製品1単位を追加的に生産するためにかかる費用が（　Ｂ<hint hidden='true'>**低** </hint>　）い傾向があるという特性を有している。  
<hint hidden='true'>**
情報財は、製品1単位を追加手に生産する��めにかかる費用が電気代だけだったり、データをDVDに格納する場合であっても、ハードウェアを製造する場合のコストより格段に安価です。そのため、変動費に比べて固定費が<u>高く</u>、限界費用が<u>低い</u>傾向があります
** </hint>  
　こうした情報財の特性は、製品市場での競争状況や、その状況に基づく競争戦略に影響を与える。特に重要なのは、複数の企業が同様の情報財を供給して、コモディティ化が生じる場合、たとえ当該市場が成長段階にあったとしても、企業間での競争が激化して、最終的には（　Ｃ<hint hidden='true'>**限界費用（製品1つを生産する際に追加でかかる費用）** </hint>　）の水準まで価格が低下してしまう点にある。  

　そのために、デジタル化された情報財では、その特性を勘案した競争戦略によって、コストリーダーシップや製品差別化を実現することで、コモディティ化に伴う熾烈な価格競争を回避すべきだとされる。例えば、パソコンのオペレーティング・システム（OS）の場合、支配的な地位を確立した企業は、ユーザー数の多さが当該製品の便益の増大につながる（　Ｄ　）などを背景として、持続的な競争優位を獲得してきた。  

（設問1）  
文中の空欄Ａ～Ｃに入る語句の組み合わせとして、最も適切なものはどれか。  

ア　Ａ：高　Ｂ：低　Ｃ：<hintTarget>機会費用</hintTarget><hint hidden='true'>**
←機会費用とは、ある行動を選択することで失われる、他の選択肢を選んでいたら得られたであろう利益のことです
** </hint>  
イ　Ａ：高　Ｂ：低　Ｃ：限界費用<hint hidden='true'>**
←⭕適切です
** </hint>  
ウ　Ａ：高　Ｂ：低　Ｃ：固定費  
エ　Ａ：低　Ｂ：高　Ｃ：機会費用  
オ　Ａ：低　Ｂ：高　Ｃ：限界費用

`
        },
        {
                "mondaiId": 103010082,
                "name": "第8問設問２",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　コンピュータのソフトウェアやコンテンツなどのデジタル化された情報財は、製品開発費などの固定費が占める比率が（　Ａ　）く、製品1単位を追加的に生産するためにかかる費用が（　Ｂ　）い傾向があるという特性を有している。  

　こうした情報財の特性は、製品市場での競争状況や、その状況に基づく競争戦略に影響を与える。特に重要なのは、複数の企業が同様の情報財を供給して、コモディティ化が生じる場合、たとえ当該市場が成長段階にあったとしても、企業間での競争が激化して、最終的には（　Ｃ　）の水準まで価格が低下してしまう点にある。  

　そのために、デジタル化された情報財では、その特性を勘案した競争戦略によって、コストリーダーシップや製品差別化を実現することで、コモディティ化に伴う熾烈な価格競争を回避すべきだとされる。例えば、パソコンのオペレーティング・システム（OS）の場合、支配的な地位を確立した企業は、ユーザー数の多さが当該製品の便益の増大につながる（　Ｄ<hint hidden='true'>**ネットワーク外部性** </hint>　）などを背景として、持続的な競争優位を獲得してきた。  
                
（設問2）  
文中の空欄Ｄに入る語句として、最も適切なものはどれか。  

ア　オープン・イノベーション  
<hint hidden='true'>**
❌内部の設計を社外に公開することで、多様な開発者によるイノベーションを誘発する手法。<hintTarget>PCのOSのLinux</hintTarget>などが有名です。
** </hint>  

イ　デジュール標準  
<hint hidden='true'>**
❌デジュール標準とは、ISOなどの公的標準です。同様の、標準の分類として有名なものに、<hintTarget>デファクト</hintTarget>標準（事実上の標準。PCのOSのWindowsなどが有名）があります。
** </hint>  

ウ　ネットワーク外部性  
<hint hidden='true'>**
⭕適切です。ユーザ数が多いOSを選択したほうが、操作に慣れたOSを使える機会が多いというネットワーク外部性が発生します。
** </hint>  
エ　リバース・イノベーション  
<hint hidden='true'>**
❌従来の製品開発が、先進国で開発→新興国にカスタマイズ　という順序であったのと逆（リバース）に、新興国で開発→先進国にも展開　という順序を取る手法。
** </hint>  
  
オ　リバース・エンジニアリング  
<hint hidden='true'>**
❌分析対象の製品の外部的な振る舞いから、内部構造を推定して同様の製品を開発する手法。
** </hint>  

`
        },
        {
                "mondaiId": 103010090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　新製品や新サービスを受け入れる市場が一様ではなく、いくつかの異なったグループによって構成されているとする考え方に、市場をマニア・マーケットと大衆マーケットとに分けて市場の顧客層の質的な違いに着目するキャズム（Chasm：市場の断層）の理論がある。  
　キャズムの理論に関する記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】キャズム理論
<imgTag>103010090_1.png</imgTag>  
</div>
</hint>

ア　キャズムの理論では、大衆マーケットにおける新製品や新サービスの急成長は、目利きの層（アーリー・アドプター）と流行を後追いする層（レイト・マジョリティー）に対し、<hintTarget>並行的</hintTarget>に受け入れられる必要がある。  

<hint hidden='true'>**
❌アーリーアドプターへの普及後に、レイトマジョリティーに受け入れられる事が多い。
** </hint>

イ　キャズムの理論では、大衆マーケットを構成する流行に敏感な層（<hintTarget>アーリー・マジョリティー</hintTarget>）にいかに受け入れられ、その需要を喚起するかが課題となる。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

ウ　キャズムの理論では、大衆マーケットを構成する流行を後追いする層（レイト・マジョリティー）には受け入れられても、無関心の層（ラガード）に受け入れられるかどうかが<hintTarget>問題</hintTarget>となる。  

<hint hidden='true'>**
❌レイトマジョリティーは、相対的に少人数で、かつ製品の完成度や流行に関わらず利用を拒む者もいるため、キャズムの理論で顧客開拓を考える際に問題とはされない。
** </hint>

エ　キャズムの理論では、まずマニア・マーケットを構成する新しいモノ好きの層（イノベーター）と<hintTarget>無関心の層（ラガード）</hintTarget>とに受け入れられることが必要である。  

<hint hidden='true'>**
❌まずイノベーターと<hintTarget>アーリーアドプター</hintTarget>に受け入れられて、次のアーリーマジョリティー獲得に向けた谷をいかに超えるかが課題とされる。
** </hint>

オ　キャズムの理論では、マニア・マーケットを構成する新しいモノ好きの層（イノベーター）に受け入れられ、いかに目利きの層（アーリー・アドプター）の反応を推測するかが問題となる。

<hint hidden='true'>**
❌イノベーターとアーリーアドプターに受け入れられ、アーリーマジョリティの反応を推測してキャズムを超えることが問題となる。
** </hint>

`
        },
        {
                "mondaiId": 103010100,
                "name": "第10問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　社内ベンチャーに関する記述として、最も適切なものはどれか。  

ア　社内ベンチャーは、新規事業に関する「<hintTarget>学習装置</hintTarget>」としての機能は<hintTarget>果たせない</hintTarget>が、新規事業の推進と運営に必要な情報的資源を獲得して蓄積し、新規事業に挑戦する心理的エネルギーを生み出す。  

<hint hidden='true'>**
❌開発内容に関する知識や、新ビジネス立ち上げに関するノウハウの学習装置として機能します。
** </hint>

イ　社内ベンチャーは、新規事業の推進と運営について、本業や既存事業からの適切な<hintTarget>支援</hintTarget>を得て、本業や既存事業の思考様式の<hintTarget>枠組みの中</hintTarget>で事業を推進するための組織である。  

<hint hidden='true'>**
❌本業からの支援は必要ではありません。また、既存の思考様式を適宜逸脱してイノベーションを起こすことを期待されます。
** </hint>

ウ　社内ベンチャーは、小さな独立企業のような運営を目的とするが、社内の他部門の支援を得るために自律性よりも社内の意思決定プロセスとの整合性を<hintTarget>重視</hintTarget>する。

<hint hidden='true'>**
❌むしろ（必要に応じて）既存プロセスとの整合性より自律性を優先することが求められます。  
** </hint>

エ　社内ベンチャーは、プロジェクトチームやタスクフォースとして編成されることは少ないが、その運営ではハンズオフ型のベンチャーキャピタルに比べ、親企業の関与の程度は<hintTarget>低い</hintTarget>。  

<hint hidden='true'>**
❌ベンチャk－キャピタルとは違って同じ組織内における活動であるため、親企業の関与の程度は高い。
** </hint>

オ　社内ベンチャーは、本業や既存事業の思考様式にとらわれない発想を生み出し、本業や既存事業と異なった事業への進出や根本的に異質な製品開発を目的として設置されることが多い。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

`
        },
        {
                "mondaiId": 103010110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　製品アーキテクチャは、製品を構成する個々の部品や要素の間のつなぎ方や製品としてのまとめ方であり、部品（要素）間の相互依存性の程度によって、インテグラル型とモジュラー型の2つに分類される。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
インテグラル型とモジュラー型の比較

<TABLE border="1"  width="100%">
<TR>
<TD></TD>
<TD>インテグラル型</TD>
<TD>モジュラー型</TD>
</TR>
<TR>
<TD>代表例</TD>
<TD>乗用車</TD>
<TD>デスクトップパソコン</TD>
</TR>
<TR>
<TD>部品（要素）間の相互依存性</TD>
<TD>高い  
乗り心地や安全のため、部品感のすり合わせが重要</TD>
<TD>低い  
部品間の接続の規格が存在し、各部品メーカーが規格に準拠して製品開発をする</TD>
</TR>
</TABLE>
</div>
</hint>

「ａ乗用車<hint hidden='true'>**
←インテグラル型です
** </hint>」、「ｂ大型旅客機<hint hidden='true'>**
←インテグラル型です
** </hint>」、「ｃデスクトップパソコン<hint hidden='true'>**
←モジュラー型です
** </hint>」、「ｄ業務用複合機（コピー機）<hint hidden='true'>**
←インテグラル型です。用紙を１枚ずつ送ったり、インクの滲み・目詰まり・温度の制御など高度な統合が必要になります。
** </hint>」の4つの領域において、現在の市場で主に取引されている製品を想定した場合、それぞれインテグラル型、モジュラー型のいずれに該当するか。下記の解答群から、最も適切なものの組み合わせを選べ。  

〔解答群〕  
ア　ａ：インテグラル型　ｂ：インテグラル型　ｃ：インテグラル型　ｄ：モジュラー型  
イ　ａ：インテグラル型　ｂ：インテグラル型　ｃ：モジュラー型　ｄ：インテグラル型<hint hidden='true'>**
⭕
** </hint>  
ウ　ａ：モジュラー型　ｂ：インテグラル型　ｃ：モジュラー型　ｄ：モジュラー型  
エ　ａ：モジュラー型　ｂ：モジュラー型　ｃ：インテグラル型　ｄ：インテグラル型  
オ　ａ：モジュラー型　ｂ：モジュラー型　ｃ：モジュラー型　ｄ：インテグラル型


`
        },
        {
                "mondaiId": 103010120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ｓ．Ｇ．ブランクが構築した「顧客開発」モデルは、顧客ニーズの把握が不十分、かつ顧客の特定化が困難な場合に、仮説の検証を素早く繰り返すことによって、学習を通して、新しいビジネスの成功率を高めようとするモデルであり、それを発展させたものが、Ｅ．リースによって提唱された「リーン・スタートアップ」モデルである。  

　「リーン・スタートアップ」に関する記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
  　
<a href="https://www.amazon.co.jp/dp/B00F3UTIQY/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1" target="_blank" >💡エリック・リース　リーンスタートアップ  
<img src="assets/img/kaisetu/103010120_1.png" alt="表紙"></a>

</div>
</hint>


ア　リーン・スタートアップでは、戦略を検証する実験によって、その実験段階の製品やサービスが失敗に終わった場合、ビジョンを実現するためには、それまでの開発コストが無駄になっても、戦略の方向転換（ピボット）が必要であるとしている。  

<hint hidden='true'>**
⭕適切です。
** </hint>

イ　リーン・スタートアップでは、不確実な状態で新しい製品やサービスを創り出すスタートアップのプロセスを、戦略を検証する実験の連続と捉えており、その実験回数を<hintTarget>あらかじめ制限</hintTarget>しておくことが、成功の伴と捉えている。  

<hint hidden='true'>**
❌実験の結果や必要な実験回数について、あらかじめ計画的に見積もることが困難なスタートアップのプロセスにおいて、仮説検証を繰り返すことを重視せよという考え方です。
** </hint>

ウ　リーン・スタートアップは、①顧客ニーズにかかる「仮説」を立てること、②顧客ニーズを満たすアイデアを「製品化」すること、③製品化したものを消費者に「提供」すること、④新たな顧客を次々に「開拓」することの4つのプロセスを<hintTarget>直線的に</hintTarget>進めていくものである。  

<hint hidden='true'>**
❌<hintTarget>構築→計測→学習（改善or方針転換）</hintTarget>を、繰り返します。
** </hint>

エ　リーン・スタートアップは、新規事業の製品やサービス、対象となる顧客、販売方法などが<hintTarget>詳細に記述</hintTarget>されたビジネス・プランを構築し、そのビジネス・プランに従って新規事業を進めていくプロセスである。  

<hint hidden='true'>**
❌市場の不確実性が高い状況において、詳細なプランを立案するのではなく、構築→計測→学習を繰り返すことを重視する考え方です。
** </hint>

`
        },
        {
                "mondaiId": 103010130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　情報処理モデルに従って組織構造をデザインする際には、情報処理の必要性が不確実性（uncertainty）の除去に関わるものなのか、多義性（equivocality）の除去に関わるものなのかによって、必要となるコミュニケーションメディアのリッチネスや調整メカニズムが異なる。  
　情報処理の必要性とコミュニケーションメディアのリッチネス、調整メカニズムに関する記述として、最も適切なものはどれか。  

ア　新たなアイデアを生み出すために部門間調整を行う際、<hintTarget>多義性の除去</hintTarget>が必要になるときには、コミュニケーションの冗長性を<hintTarget>排除</hintTarget>し、効率的な調整メカニズムを確保する必要がある。  

<hint hidden='true'>**
❌多義性（equivocality。あいまいさ）の除去は、冗長性を排除した効率的な調整ではなく、冗長で（ある程度）非効率な調整メカニズムを通じて解決すべきものです。
** </hint>

イ　環境の新しい意味や価値の変化を知るには、多義性<hintTarget>よりも</hintTarget>不確実性の除去が重要なので、アンケート調査のような手法が有効である。  

<hint hidden='true'>**
❌多義性が重要です。
** </hint>

ウ　環境の質的な変化は、組織部門間での多義性の除去の必要性を増加させるので、部門間でのフェイス・ツー・フェイスコミュニケーションなどのリッチなコミュニケーションメディアを利用した調整メカニズムが必要になる。  

<hint hidden='true'>**
⭕
** </hint>

エ　コミュニケーションメディアをリッチなものにするためには、迅速なフィードバック、<hintTarget>明確に定義された言語による報告書</hintTarget>、複数のチャネルの確保が必要である。  

<hint hidden='true'>**
❌フィードバックや複数チャネルは、情報流通の双方向性を高めメディアをエンリッチ（活性化させる）が、報告書はそのような効果はありません。
** </hint>

オ　不確実性は情報量の不足を意味するので、<hintTarget>リッチなコミュニケーションメディア</hintTarget>を活用する必要性があり、より多くの情報を収集・処理するために職能別専門化を追求した組織構造を設計することが望ましい。

<hint hidden='true'>**
❌リッチなコミュニケーションは、多義性が高い（情報が多く意見が様々）場合多義性を除去するのに有効ですが、情報量の不足時には適しません。
** </hint>


`
        },
        {
                "mondaiId": 103010140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　組織学習は、一般に低次学習と高次学習に分けて考えることができる。組織学習に関する記述として、最も適切なものはどれか。  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識１】マクレガーのXY理論  
<hintTarget>
<imgTag>103010140_1.png</imgTag>  
</hintTarget>
</div>

</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識２】低次学習・高次学習  
<imgTag>103010140_2.png</imgTag>
</div>
</hint>

ア　Ｄ．マグレガーのいう<hintTarget>Ｙ理論</hintTarget>に基づく管理手法を採用すると、<hintTarget>低次学習が促進</hintTarget>されるため、組織の業績は<hintTarget>悪化</hintTarget>する可能性が高まる。  

<hint hidden='true'>**
❌Y理論に基づく管理手法を採用すると、自己実現など高度な欲求を求めるため、<hintTarget>高次学習</hintTarget>が促進されると考えられます。
高次学習と低次学習は、あくまで学習・改善する<hintTarget>視点の高低</hintTarget>を指す用語であり、低次学習を行うことで業績が悪化するというものではありません。
** </hint>

イ　新たに組織に参加した組織メンバーは、組織の周縁にいるために、社会化のプロセスを通じて積極的に<hintTarget>高次学習</hintTarget>をさせる必要がある。  

<hint hidden='true'>**
❌組織の周辺にいる（組織の既存プロセスやそのようなプロセスとなっている背景を把握していない）ため、社会化のプロセス（その組織の一員としてなじませる）を通じた<hintTarget>低次学習</hintTarget>が望ましいと考えられます。
** </hint>

ウ　高次学習とは組織の上位階層で行われている学習であり、低次学習とは組織の下位階層で行われている学習である。  

<hint hidden='true'>**
❌組織の上位下位ではなく、視点の高低です。
** </hint>

エ　組織の行動とそれが環境に与える効果の因果関係が分かりにくい場合、迷信的学習といわれる低次学習が起こりやすい。  

<hint hidden='true'>**
⭕適切です。
** </hint>

オ　低次学習とは組織の成果にとって<hintTarget>悪い影響</hintTarget>を与える学習であり、高次学習とはより<hintTarget>高い成果</hintTarget>をあげるために不可欠であるため、組織メンバーに高次学習を<hintTarget>意識させる</hintTarget>ことが重要である。  

<hint hidden='true'>**
❌良し悪しではなく、視点の高低です。  
部分的な改善にとどまらないように、高次学習を意識させることは重要です。
** </hint>

`
        },
        {
                "mondaiId": 103010150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　コンフリクトは、意思決定の標準メカニズムの機能不全を意味する。組織における部門間コンフリクトの原因、それへの対応に関する記述として、最も適切なものはどれか。  


ア　組織内の<hintTarget>スラックが豊富</hintTarget>に存在すると、部門間の目標の独立性が<hintTarget>減少</hintTarget>し、部門間コンフリクトが発生しやすくなる。  

<hint hidden='true'>**
❌組織内のスラック(slack＝和訳：<hintTarget>ゆとり</hintTarget>)が豊富だと、部門間の目標の独立性は増加し、コンフリクトは軽減されます。
**</hint>

イ　組織内の部門間コンフリクトは、共同意思決定の必要性が高ければ高いほど、また予算など限られた資源への依存度が大きければ大きいほど、発生する可能性が高まる。  

<hint hidden='true'>**
⭕適切です。
** </hint>

ウ　命令の一元性が確保されていると、部門間の目標や知覚の<hintTarget>分化</hintTarget>が進むため、部門間コンフリクトが起きる可能性は低下する。  

<hint hidden='true'>**
❌共有化がすすみます。
**</hint>

エ　目標が共有されている部門間でコンフリクトが生じた場合、その基準を満たす解決策を探索するために、<hintTarget>政治的工作</hintTarget>やバーゲニングが使用される可能性が<hintTarget>高く</hintTarget>なる。

<hint hidden='true'>**
❌目標が共有されている部門間では、部門同士の局所最適に陥ることで目標の達成（全体最適）ができなくなるリスクのある、<hintTarget>政治的工作</hintTarget>のような手法が使用される可能性は低いと考えられます。  
バーゲニング（Bargaining）とは、<hintTarget>交換条件を探る</hintTarget>ことです。各組織が積極的にバーゲニングして、共有された目標を達成できるよう模索する可能性は、高いと考えられます。
**</hint>

`
        },
        {
                "mondaiId": 103010160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ｅ．ロックやＧ．レイサムらにより体系化された目標設定理論において指摘されている、組織メンバーの努力や成果を引き出す目標の特徴として、最も適切なものはどれか。  

ア　目標と<hintTarget>報酬（昇給や昇進など）</hintTarget>の間の関係が明示されていること。  

<hint hidden='true'>**
❌目標設定理論で特に推奨されている観点ではありません。  
また、報酬といった外発的動機づけをを推奨する理論ではありません。
**</hint>

イ　目標の達成困難度が<hintTarget>顕著</hintTarget>
に高いこと。  

<hint hidden='true'>**
❌より困難で、高い目標のほうが動機づけされるとされています。  
しかし、それだけでなく、組織メンバーに目標が受け入れられている必要があるとされているため、「顕著」というやや極端な記述を考慮すると、最も適切な選択肢とはいえません。
**</hint>

ウ　目標の達成度合いについての<hintTarget>フィードバック</hintTarget>
が得られること。 

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ　目標の内容が組織運営上合理的であること。  

<hint hidden='true'>**
❌目標設定理論で特に推奨されている観点ではありません。
**</hint>

オ　目標の内容が<hintTarget>抽象的</hintTarget>
であること。

<hint hidden='true'>**
❌<hintTarget>具体的</hintTarget>であることが望ましいとされています。
**</hint>


`
        },
        {
                "mondaiId": 103010170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　状況に即したリーダーシップに関する記述として、最も適切なものはどれか。  

ア　Ｆ．フィードラーの研究によると、組織が<hintTarget>未成熟で管理体制が厳しい</hintTarget>場合と、組織が成熟しており管理体制が緩やかな場合においては、<hintTarget>人間関係</hintTarget>志向型のリーダーシップ行動が集団の業績を高める。  

<hint hidden='true'>**
❌組織が未成熟で管理体制が厳しい場合は、<hintTarget>タスク志向</hintTarget>型のリーダーシップが適切とされています。
**</hint>

イ　SL（SituationalLeadership）理論によると、フォロワーの成熟度が高く、<hintTarget>自律的</hintTarget>な行動が可能な状態では、リーダーの<hintTarget>参加型</hintTarget>リーダーシップにより、フォロワーの行動が自然と集団目標に沿うようになる。  

<hint hidden='true'>**
❌成熟度が高く、自律している場合は、参加型リーダーシップによってフォロワーの自律性を促すよりさらに進んだ、委譲型のリーダーシップが適切です。
**</hint>

ウ　パス・ゴール理論によると、「困難な目標を設定し、部下に全力を尽くすよう求める」という達成志向型のリーダーシップは、タスクが構造化されていないときに、努力すれば高業績につながるというフォロワーの期待を高める。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ　リーダー・メンバー交換理論によると、リーダーとフォロワーの関係は、①他人的関係、②知人的関係、③成熟した関係、という順序で深まっていく。関係の深まりに応じて、敬意や信頼に根ざしたものになり、<hintTarget>取引的</hintTarget>・公式的な相互作用が失われていく。

<hint hidden='true'>**
❌他人的、知人的、成熟した関係で深まっていくにつれ、取引の質が、経緯や信頼に根ざしたものに<u>変化</u>するが、あくまで取引的な相互作用が<u>失われるわけではない</u>。
**</hint>


`
        },
        {
                "mondaiId": 103010180,
                "name": "第18問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　パーソナリティについてのモデルの1つに「ビッグファイブ」がある。ビッグファイブによると、個人のパーソナリティの多様性は、次の5つの特性の強度によって説明される。

⑴　外向性（extroversion：社交的、話好きなど）  
⑵　神経症傾向（neuroticism：心配性、傷つきやすいなど）  
⑶　開放性（openness：想像力が豊か、好奇心が強いなど）  
⑷　調和性（agreeableness：協力的、温和など）  
⑸　誠実性（conscientiousness：計画的、責任感が強いなど）  

　ビッグファイブに関する以下の文章の空欄Ａ～Ｃに入る用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ビッグファイブを構成する5つのパーソナリティ特性は、職務満足や職務の成果に影響する。（　Ａ　<hint hidden='true'>**
開放性
**</hint>  ）以外の4つの特性は、職務満足と有意な関係がある。例えば、「神経症傾向」が強い人ほど、職務満足が低くなる傾向にある。一方、全ての職務の成果と正の相関を持つのは、（　Ｂ　<hint hidden='true'>**
誠実性
**</hint>  ）である。管理職や営業職のように、良好な対人関係の構築や維持が重要な職務においては、（　Ｃ　<hint hidden='true'>**
外向性
**</hint>  ）が高い人ほど職務の成果が高くなる。  


〔解答群〕  
ア　Ａ：外向性　Ｂ：開放性　Ｃ：外向性  
イ　Ａ：外向性　Ｂ：開放性　Ｃ：調和性  
ウ　Ａ：外向性　Ｂ：誠実性　Ｃ：調和性  
エ　Ａ：開放性　Ｂ：開放性　Ｃ：調和性  
オ　Ａ：開放性　Ｂ：誠実性　Ｃ：外向性<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 103010190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　現代の企業は、規模の大小にかかわりなく、さまざまなステイクホルダーの社会的ネットワークの中に埋め込まれている。企業は利害の異なるこうしたステイクホルダーから正当性を獲得するために、ステイクホルダーと協調戦略を採る場合がある。

　以下のａ～ｄの行動について、こうした協調戦略に関する記述の正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　企業とステイクホルダーとの間の資源交換をめぐって協定を締結すること。  
ｂ　ステイクホルダーの代表を、企業の一員として政策決定機関に参加させること。  
ｃ　組織間の共通目標を達成するために、複数の組織が資源やリスクを共有しながら、共同事業を行うこと。  
ｄ　特定の目標を達成するために、複数の組織間で、公式の調整機関を設置すること。  

〔解答群〕  

ア　　ａ：正　ｂ：正　ｃ：正　ｄ：正<hint hidden='true'>**
←⭕
**</hint>    
イ　　ａ：正　ｂ：正　ｃ：正　ｄ：誤  
ウ　　ａ：誤　ｂ：正　ｃ：誤　ｄ：誤  
エ　　ａ：誤　ｂ：誤　ｃ：正　ｄ：正  
オ　　ａ：誤　ｂ：誤　ｃ：誤　ｄ：正
`
        },
        {
                "mondaiId": 103010201,
                "name": "第20問設問１",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　メーカーＡ社では、経営陣が「次世代の主力製品」と鳴り物入りで導入した製品Ｘについて、累積損失が膨らんだため、市場から撤退する決定がなされた。実は5年ほど前から、製品Ｘには深刻な問題があると気づいていた現場管理者が数人いた。生産上のトラブルが続き、そのコストを価格に転嫁すれば競争力を失うことに気づいていたのである。しかしこの情報が、経営陣に伝わるには時間がかかりすぎた。その原因を探求すると、以下のような状況であったことが分かった。  

　生産現場の管理者たちは、改善運動で成功してきた実績と有能感を持っていた。  
　当初は、改善運動で問題が処理できると考えていたが、マーケティング面の問題がより深刻であることが分かった。そこで彼らは、製品Ｘのプロジェクトマネジャー（以下、「ミドル」という）に問題の深刻さを伝える報告書を作成した。Ａ社では、こうした報告書には改善提案を付けることが当然視されていたため、時間をかけて詳細なデータを付けた。  

　しかしこの精緻な報告書は、製品Ｘの導入決定の際に、トップ主導で行った生産やマーケティングの調査を根底から覆すような内容を含んでいた。そこでミドルは、まず現場管理者たちに、その報告書に記載された<hintTarget>データが正しいのか詳しく調べる</hintTarget>よう指示した。報告書が正しそうだと分かると今度は、経営陣に悲観的な情報を小出しに流し始めた。経営陣からはいつも「<hintTarget>説明資料が長すぎ</hintTarget>る」と叱られていたので、<hintTarget>資料のデータを大幅に割愛し、問題の深刻さをオブラートに包み、現場では事態を十分掌握しているように</hintTarget>表現していた。そのため経営陣は製品Ｘについて、引き続き「次世代の主力製品」と熱い期待を語り続け、必要な財務的資源も保証していったのである。  

　現場の管理者たちは問題点を指摘したにもかかわらず、経営陣は製品Ｘへの期待を語り、ミドルからは再検討の要請がなされたため混乱した。そのうち彼らは、製品Ｘに悲観的な資料を作ることを控え、<hintTarget>責任はミドルにある</hintTarget>と考えるようになった。やがて、<hintTarget>納得したわけではなかった</hintTarget>が、あまり気に留めることもなくなった。


（設問1）  
　あなたがコンサルタントとしてＡ社の<hintTarget>問題を</hintTarget>分析するとしたら、Ａ社の組織メンバーが持つ行動モデルに当てはまるものはどれか。最も適切なものを選べ。  

ア　自分たちの考え方を頻繁に検証する。  

<hint hidden='true'>**
❌問題文のとおり、データが正しいのか詳しく調べる調べたりするなど、自分たちの考え方をたびたび検証しています。  
検証が特に頻繁すぎるということもなく適切と思われるので、問題点とは言えません。
**</hint>

イ　情報の妥当性を重視する。  

<hint hidden='true'>**
❌問題文中の「オブラート」、「掌握しているよう」の記述から、妥当性の軽視が見られます。
**</hint>

ウ　積極的にリスクを取ろうとする。  

<hint hidden='true'>**
❌ミドルと生産現場は、製品Xに関する問題を伝えるというリスクを取っていません。  
経営陣は、リスクを取る以前に製品Xが抱えるリスクが伝わっていません。
**</hint>

エ　全社的な観点から自己の責任を果たそうとする。  

<hint hidden='true'>**
❌ミドルは「説明資料が長すぎる」という自己評価を、生産現場は、責任はミドルにあるという責任転嫁をしており、いずれも全社最適というより局所最適です。
**</hint>

オ　問題の論理的な部分を重視し、感情的な部分は排除しようとする。

<hint hidden='true'>**
⭕若干判断が難しいが、（選択肢の中では）最も適切な記述です。  
論理だけで説明しようとして、行き詰まると「納得したわけではなかった」にもかかわらず感情を排除して指摘をやめてしまった生産現場の行動にも問題がなかったとは言えません。
**</hint>  

`
        },
        {
                "mondaiId": 103010202,
                "name": "第20問設問２",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
                
　次の文章を読んで、下記の設問に答えよ。  

　メーカーＡ社では、経営陣が「次世代の主力製品」と鳴り物入りで導入した製品Ｘについて、累積損失が膨らんだため、市場から撤退する決定がなされた。実は5年ほど前から、製品Ｘには深刻な問題があると気づいていた現場管理者が数人いた。生産上のトラブルが続き、そのコストを価格に転嫁すれば競争力を失うことに気づいていたのである。しかしこの情報が、経営陣に伝わるには時間がかかりすぎた。その原因を探求すると、以下のような状況であったことが分かった。  

　生産現場の管理者たちは、改善運動で成功してきた実績と有能感を持っていた。  
　当初は、<hintTarget>改善運動で問題が処理できると考えていたが、マーケティング面の問題がより深刻</hintTarget>であることが分かった。そこで彼らは、製品Ｘのプロジェクトマネジャー（以下、「ミドル」という）に問題の深刻さを伝える報告書を作成した。Ａ社では、こうした報告書には改善提案を付けることが当然視されていたため、時間をかけて詳細なデータを付けた。  

　しかしこの精緻な報告書は、製品Ｘの導入決定の際に、トップ主導で行った生産やマーケティングの調査を根底から覆すような内容を含んでいた。そこでミドルは、まず現場管理者たちに、その報告書に記載されたデータが正しいのか詳しく調べるよう指示した。報告書が正しそうだと分かると今度は、経営陣に<hintTarget>悲観的な情報を小出し</hintTarget>に流し始めた。経営陣からはいつも「説明資料が長すぎる」と叱られていたので、資料のデータを大幅に割愛し、<hintTarget>問題の深刻さをオブラートに包み</hintTarget>、現場では事態を十分掌握しているように表現していた。そのため経営陣は製品Ｘについて、引き続き「次世代の主力製品」と熱い期待を語り続け、必要な財務的資源も保証していったのである。  

　現場の管理者たちは問題点を指摘したにもかかわらず、経営陣は製品Ｘへの期待を語り、ミドルからは再検討の要請がなされたため混乱した。そのうち彼らは、製品Ｘに悲観的な資料を作ることを控え、責任はミドルにあると考えるようになった。やがて、納得したわけではなかったが、あまり気に留めることもなくなった。


（設問2）  
　あなたがコンサルタントとしてＡ社の組織を変革する際に、その方針や手段として、最も適切なものはどれか。  

ア　Off-JTのワークショップやセミナーを活用し、真実を明らかにしたからといって不利な立場に立たされることはない、という態度を経営者が率先して組織メンバーに身に付けさせる。

<hint hidden='true'>**
⭕適切な記述です。論理的な納得だけでは全社的な問題を解決できないと感じた社員が、意見を出しやすい土壌を作る必要があります。
**</hint>  
  
イ　与えられた目標について利得の可能性を最大化し、損失の可能性を最小化するよう、組織のメンバーを動機づける。  

<hint hidden='true'>**
❌改善活動では解決できないと判断し、マーケティングについて言及するといった行動ができているので、利得最大化を動機づけするアドバイスは必須でないと考えられます。
**</hint>

ウ　管理職には自らの役割を明確にさせ、それを強化するために、他者に指示を出したり、他者を傷つけることのないよう、<hintTarget>伝える情報の範囲</hintTarget>を自身でコントロールするよう訓練する。  

<hint hidden='true'>**
❌伝える情報を自身でコントロールできていないのではなく、情報を小出しにしてしまったなど、コントロールのやり方が問題です。
**</hint>

エ　組織のメンバーは個人の責任と業績に応じて適切に<hintTarget>報酬を得る</hintTarget>ことができる、という理念を定着させる。  

<hint hidden='true'>**
❌このケースは、特に報酬が原因で問題が発生したわけではありません。
**</hint>

オ　組織の和を重視し、組織メンバーや既存の制度を脅かすような言動は慎むよう訓練する。

<hint hidden='true'>**
❌今回のケースにおける悲観的な情報の小出しや「問題の深刻さをオブラートに包み」といった原因行動は、組織の和の軽視によるものではありません。
**</hint>

`
        },
        {
                "mondaiId": 103010210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　多くの日本企業で利用されてきた職能資格制度に関する記述として、最も適切なものはどれか。  

ア　社内等級ごとに求められる職務遂行能力の定義や、その定義に基づいた実際の能力評価は、<hintTarget>社外で普及</hintTarget>しているさまざまな職業資格の体系に準拠して行われる。  

<hint hidden='true'>**
❌社会の体型に準拠しているとまでは言えません。むしろ海外と比較して相対的に雇用の流動性が低いこともあり、社外の職業資格の体型に順序していないことが多いです。
**</hint>

イ　従業員の職務遂行能力の上昇が認められれば、たとえそれに見合う上位階層のポストや職務が社内で用意できなくても、社内等級は上げることができる。  

<hint hidden='true'>**
⭕適切な記述です。ポストや職務は、指揮命令系統の混乱を避けるために人数上限を設定するケースがあるが、社内等級は職務遂行能力に応じて上げることができます。
**</hint>  

ウ　職種ごとに職務遂行能力の定義が行われているため、従業員の職種をまたぐ異動、ひいてはゼネラリスト育成には適さない。  

<hint hidden='true'>**
❌職能資格制度は、社内の職務遂行におけるゼネラリスト的な能力を評価します。
**</hint>

エ　職務遂行能力は職務の経験年数に応じて上昇するため、職能資格制度は年功主義的な人事管理の基盤となる。  

<hint hidden='true'>**
❌職能資格制度は、社内の職務遂行におけるゼネラリスト育成に適しているため、結果的に職務の経験年数に応じて上昇するように運用されることは多い。
**</hint>

`
        },
        {
                "mondaiId": 103010220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　「働き方改革」の一環として改正された労働基準法の第39条に定められた年次有給休暇に関する記述として、最も適切なものはどれか。  

ア　使用者は、年次有給休暇を10労働日以上付与される労働者に、付与した基準日から1年以内に5日について、時季指定して年次有給休暇を取得させなければならないが、既に5日以上の年次有給休暇を請求・取得している労働者に対しては、時季指定をする必要はない。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

イ　使用者は、雇入れの日から起算して6か月間継続勤務し、全労働日の8割以上出勤した週所定労働日数が5日である労働者に10労働日の年次有給休暇を付与しなければならないが、<hintTarget>8割未満</hintTarget>である者に対してはその出勤日数に比例した日数の年次有給休暇を付与しなければならない。  

<hint hidden='true'>**
❌8割未満の者への付与に関するルールはありません。
**</hint>

ウ　使用者は、要件を満たした労働者に<hintTarget>年次有給休暇</hintTarget>を付与しなければならないが、労働基準法<hintTarget>第41条</hintTarget>に定められた監督若しくは管理の地位にある者又は機密の事務を取り扱う者は、この対象から<hintTarget>除かれる</hintTarget>。  

<hint hidden='true'>**
❌第４１条に定められた者も、年次有給休暇の規定から除かれません。  
第４１条に定められた者が除かれるのは、「労働時間、休憩及び休日」の規定です。  
第４１条の対象は、例えば、<hintTarget>農業・水産・畜産業・守衛</hintTarget>などのように、労働時間の規定が業務内容にそぐわない業種であるためです。  
<imgTag>103010220_1.png</imgTag>  
また、問題文の「監督若しくは管理の地位にある者※」のように、経営者と一体的な立場で人事権や経営にかかわり裁量権をもつ者も、第４１条の対象です。  
※管理監督者の定義は、<a href="https://www.pref.yamagata.jp/sr/roudou/qanda/qa03_01_02.html" target="_blank">ハンバーガーショップにおける平成２０年の（いわゆる「雇われ店長問題」として社会問題となった）判例で示されています。</a>  
**</hint>

エ　使用者は、労働者本人が時季を指定して年次有給休暇の取得を請求した場合、事業の正常な運営を<hintTarget>妨げる場合</hintTarget>であっても、これを変更することが<hintTarget>できない</hintTarget>。

<hint hidden='true'>**
❌事業の正常な運営を妨げる場合は、変更することができます。
**</hint>

`
        },
        {
                "mondaiId": 103010230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　労働安全衛生法に定められた長時間労働に対する医師による面接指導（問診その他の方法により心身の状況を把握し、これに応じて面接により必要な指導を行うこと）に関する記述として、<u><u>最も不適切なものはどれか</u></u>。  

　なお、本問中、事業者は中小事業主とし、労働基準法第41条に規定された管理監督者「適用除外者」及び同法第41条の2に規定されたいわゆる高度プロフェッショナル制度の「対象労働者」については考慮に入れないものとする。  


ア　医師による面接指導に係る事業者の義務は、産業医を選定する義務のない、常時50人未満の労働者を使用する事業場においても適用される。  

<hint hidden='true'>**
適切な記述です。
**</hint>

イ　事業者は、医師による面接指導の結果に基づき、当該労働者の疲労の蓄積の状況等の厚生労働省令に定められた事項及び医師の意見を記載した当該面接指導の結果の記録を作成して、これを所定期間保存しなければならない。  

<hint hidden='true'>**
適切な記述です。
**</hint>

ウ　事業者は、医師による面接指導を実施するため、タイムカードによる記録、パーソナルコンピュータ等の電子計算機の使用時間の記録等の客観的な方法その他の適切な方法により、労働者の労働時間の状況を把握しなければならない。  

<hint hidden='true'>**
適切な記述です。
**</hint>

エ　事業者は、その使用する労働者について、週40時間を超えて労働させた時間が1月当たり<hintTarget>45時間</hintTarget>を超え、かつ疲労の蓄積が認められる者であって、当該労働者が申し出た場合、医師による面接指導を行わなければならない。

<hint hidden='true'>**
❌45時間ではなく、80時間です。
**</hint>  

`
        },
        {
                "mondaiId": 103010240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　労働者の妊娠、出産、育児休業に関する記述として、最も適切なものはどれか。  

ア　あらかじめ就業規則に女性労働者が<hintTarget>妊娠したことを退職理由</hintTarget>として定め、かつ採用の際にその旨の労働契約を締結している場合は、当該事実の到来をもって自然退職となる。  

<hint hidden='true'>**
❌婚姻・妊娠・出産を退職理由とする定めは、男女雇用機会き当方で禁じられています。
**</hint>

イ　事業主が雇用する女性労働者に講じなければならない「職場における妊娠、出産等に関する言動に起因する問題に関する雇用管理上の措置」について、派遣先事業主は、派遣労働者に対して、そのような雇用管理上及び指揮命令上の措置を講じなければならない。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　妊娠・出産・育児休業等に関するハラスメントの防止措置のうち、育児休業制度の利用を阻害するものについては、当該育児休業制度を利用しようとする、又は利用している<hintTarget>女性</hintTarget>労働者にのみ適用される。  

<hint hidden='true'>**
❌男性にも適用されます。
**</hint>

エ　妊娠中及び出産後1年を経過しない女性労働者に対してなされた解雇は、無効とされる。ただし、当該解雇が妊娠又は出産に起因する症状により労務の提供ができないこと若しくはできなかったこと又は<hintTarget>労働能率が低下したこと</hintTarget>を理由とする解雇であることを事業主が<hintTarget>証明</hintTarget>したときは、この限りでない。

<hint hidden='true'>**
❌事業主が証明することで、解雇が無効とされない例外が適用できるケースは、<hintTarget>妊娠または出産した</hintTarget>ことを理由とした解雇ではないことを証明した場合です。
**</hint>

`
        },
        {
                "mondaiId": 103010250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　事業主が公の目的のために賦課徴収される公租公課のうち、法律で「国税徴収の例により徴収する」と規定されている労働保険（労災保険・雇用保険）、社会保険（健康保険、厚生年金保険、介護保険とする）の保険料の納付に関する記述として、最も適切なものはどれか。  

　なお、本問における社会保険料については健康保険の日雇特例被保険者に関するものを除くものとする。  

ア　事業主は、労働保険の継続事業における一般保険料については、その概算保険料（増加概算保険料、追加徴収、延納を除く）を、保険年度ごとに概算保険料申告書に添えて、その保険年度の<hintTarget>4月</hintTarget>1日（保険年度の中途に保険関係が成立したものについては、当該保険関係が成立した日）から40日以内に納付しなければならない。  

<hint hidden='true'>**
❌4月1日でなく、6月1日です。
**</hint>

イ　社会保険の被保険者（健康保険の任意継続被保険者を除く）及び被保険者を使用する事業主は、それぞれ保険料額の2分の1を負担しなければならないが、事業主は使用する被保険者負担分を報酬から控除することができなかったとしても、使用する被保険者及び自己の負担する保険料を納付する義務を負う。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　社会保険の被保険者の毎月の保険料は、<hintTarget>当月</hintTarget>末日までに納付しなければならない。ただし、健康保険の任意継続被保険者に関する保険料については、その月の10日（初めて納付すべき保険料については、保険者の指定日）までに納付しなければならない。  

<hint hidden='true'>**
❌当月末日でなく、翌月末日です。
**</hint>

エ　労働保険事務の処理を労働保険事務組合に委託している中小事業主（当該保険年度において10月1日以降に保険関係が成立したものを除く）は、当該事業主が申請することにより、その継続事業の概算保険料を、4月1日からの<hintTarget>四半期</hintTarget>ごとに<hintTarget>4回</hintTarget>に分けて納付することができる。

<hint hidden='true'>**
❌3ヶ月ごとに、4回に分けて納付することができます。
**</hint>

`
        },
        {
                "mondaiId": 103010260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　マーケティングにおける顧客との関係構築に関する記述として、最も適切なもの  
はどれか。  

ア　顧客が企業に対して持つロイヤルティには、再購買率で測定される行動的ロイヤルティと態度に関わる心理的ロイヤルティがある。これらのうち前者が高けれ ば後者も高いが、前者が低くても後者は高いこともある。  

イ　顧客との関係構築を重要視するマーケティングの考え方はBtoCのサービス・マーケティングにルーツがあるが、近年はBtoBマーケティングにも応用されるようになってきた。  

ウ　顧客を満足させるには、顧客の事前の期待値を上回るパフォーマンスを提供する必要があるが、次回購買時には前回のパフォーマンスのレベルが期待値になるため、さらに高いパフォーマンスを提供することが望ましい。<hint hidden='true'>**
←⭕
**</hint>    

エ　優良顧客を識別するための指標の1つである顧客生涯価値とは、顧客が今回または今期に購入した金額だけでなく、これまでに購入した全ての金額に等しい。

`
        },
        {
                "mondaiId": 103010270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　市場細分化に関する記述として、最も適切なものはどれか。  

ア　BtoBマーケティングで企業規模に基づき市場細分化を行った場合、各セグメント内の企業は企業規模以外の基準においても均一となる。  

イ　BtoBマーケティングではさまざまな変数に基づいた市場細分化が行われるが、突発的な注文が多い企業や小口の注文が多い企業などは対象セグメントとして望ましくない。  

ウ　BtoBマーケティングにおいては組織的な購買が行われることが多いが、購買担当者の個人的特性に基づく市場細分化が有効な場合がある。<hint hidden='true'>**
←⭕
**</hint>    

エ　市場細分化によって製品・サービスの種類が増えるため、企業のコストも増加せざるを得ない。

`
        },
        {
                "mondaiId": 103010280,
                "name": "第28問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　製品ライフサイクルの各段階に対応したマーケティングに関する記述として、最も適切なものはどれか。  

ア　成熟期に入ると市場はより多くの消費者に支えられるようになるため、技術的に、より複雑で高度な製品の人気が高まる。  

イ　導入期に他社に先駆けていち早く市場の主導権をとることが重要なので、投資を抑えつつ競合他社から明確に差別化された製品やサービスを導入期に投入することが望ましい。  

ウ　導入期の主要顧客は市場動向や他者の行動を見ながら製品・サービスの購入を決める追随型採用者なので、このような消費者が抱える問題を解決できる製品・サービスを投入することが望ましい。  

エ　導入期や成長期において市場の業界標準が成立する場合、これに準拠する、または対抗するなど、成立した業界標準に対応したマーケティングを実行することが望ましい。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 103010290,
                "name": "第29問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　BtoBマーケティングに関する記述として、最も適切なものはどれか。  

ア　BtoBマーケティングでは、BtoCマーケティングに比べて特定少数の顧客を対象とすることが多いため、ブランディングは不要である。  

イ　BtoBマーケティングでは、顧客第一主義に立脚し、専ら既存顧客の要望に応えることに集中するべきである。  

ウ　BtoBマーケティングでは、常に、購買に関する意思決定は当該購買に関する意思決定者の技術的専門知識に基づいて行われるため、このような購買者を想定したマーケティングが求められる。  

エ　BtoCマーケティングでは、極めて高い市場シェアを獲得し長期的に維持することは困難な場合が多いが、BtoBマーケティングでは複数の寡占企業と取引できる場合などに極めて高い市場シェアを獲得し維持することも可能である。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 103010301,
                "name": "第30問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　マスメディアとさまざまなプロモーショナル・メディアを組み合わせたコミュニケーションを前提としてきた伝統的なマーケティングから、近年急速に<u>①デジタル・マーケティング</u>へのシフトが進んでいる。このシフトは、<u>②消費者同士の情報交換</u>がソーシャルメディアなどを介して盛んに行われるようになっていることに対応した動きでもある。  


（設問1）  

文中の下線部①に関する記述として、最も適切なものはどれか。  

ア　企業やマーケターが顧客と接したり会話したりする「タッチポイント」は、店舗などの物理的空間だけに限定せず、オンライン上にもさまざまな形で設定される。<hint hidden='true'>**
←⭕
**</hint>    

イ　需給バランスや時期などに応じて価格を変動させるダイナミック・プライシングのような方法は、デジタル・マーケティングの時代になって初めて登場した価格設定方法である。  

ウ　デジタル・マーケティングにおいては、製品開発のための資金をオンライン上の多数の消費者から調達するクラウド・ソーシングの手法がしばしば用いられる。  

エ　デジタル財の特徴として、複製が容易である（複製可能性）、他者が使用すると直ちに価値が低下する（価値の毀損性）、オンラインで瞬時に転送できる（非空間性）の3つをあげることができる。  

`
        },
        {
                "mondaiId": 103010302,
                "name": "第30問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　マスメディアとさまざまなプロモーショナル・メディアを組み合わせたコミュニケーションを前提としてきた伝統的なマーケティングから、近年急速に<u>①デジタル・マーケティング</u>へのシフトが進んでいる。このシフトは、<u>②消費者同士の情報交換</u>がソーシャルメディアなどを介して盛んに行われるようになっていることに対応した動きでもある。  


（設問2）  
文中の下線部②に関する記述として、最も適切なものはどれか。  

ア クチコミには、経験しないと判断できない「経験属性」に関する情報が豊富に含まれている。<hint hidden='true'>**
←⭕
**</hint>    

イ クチコミの利便性を向上するために、クチコミを集約したランキングや星評価などが導入されたことにより、かえってクチコミの利便性が低下している。  

ウ 消費者同士がオンライン上で交換したクチコミ情報が蓄積される場所は、蓄
積される情報や場の運営に関して消費者が主導権を持っているという意味で「オウンドメディア」と呼ばれる。  

エ マーケターが、企業と無関係な消費者であるかのように振る舞って情報を受  
発信することは、当該企業にとっての長期的利益につながる。
`
        },
        {
                "mondaiId": 103010311,
                "name": "第31問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　原油や原材料価格の低下、あるいは革新的技術の普及は、製造ならびに製品提供にかかる変動費を減少させるため、販売価格の引き下げが検討されるが、価格を下げることが需要の拡大につながらないケースもある。企業は、<u>①需要の価格弾力性や交差弾力性</u>を確認したり、<u>②競合他社の動向や顧客の需要を分析、考慮したりして、価格を決定する</u>。  

（設問1）  
文中の下線部①に関する記述として、最も適切なものはどれか。  

ア　企業は、製品Ａの価格変化が製品Ｂの販売量にもたらす影響について、交差弾力性の値を算出し確認する。具体的には、製品Ａの価格の変化率を、製品Ｂの需要量の変化率で割った値を用いて判断する。  

イ　牛肉の価格の変化が豚肉や鶏肉の需要量に、またコーヒー豆の価格の変化がお茶や紅茶の需要量に影響することが予想される。これらのケースにおける交差弾力性は負の値になる。  

ウ　消費者が品質を判断しやすい製品の場合には、威光価格が有効に働くため、価格を下げることが需要の拡大につながるとは限らない。  

エ　利用者層や使用目的が異なるため、軽自動車の価格の変化は、高級スポーツカーの需要量には影響しないことが予想される。こうしたケースの交差弾力性の値は、ゼロに近い。<hint hidden='true'>**
←⭕
**</hint>    

`
        },
        {
                "mondaiId": 103010312,
                "name": "第31問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　原油や原材料価格の低下、あるいは革新的技術の普及は、製造ならびに製品提供にかかる変動費を減少させるため、販売価格の引き下げが検討されるが、価格を下げることが需要の拡大につながらないケースもある。企業は、<u>①需要の価格弾力性や交差弾力性</u>を確認したり、<u>②競合他社の動向や顧客の需要を分析、考慮したりして、価格を決定する</u>。  


（設問2）  
文中の下線部②に関する記述として、最も適切なものはどれか。  

ア　ウイスキー、ネクタイ、スーツなどの製品では、低価格の普及品から高価格の高級品までのバリエーションを提供することがある。このように、複数の価格帯で製品展開することを「プライス・ライニング戦略」と呼ぶ。<hint hidden='true'>**
←⭕
**</hint>    

イ　短期間で製品開発コストを回収することを目指して設定された高い価格を「スキミング価格」と呼ぶ。このような価格設定は、模倣されやすい新製品に最適である。  

ウ　発売当日にCDやDVDを入手することに強いこだわりを持ち、価格に敏感ではない熱狂的なファンがいる。新製品導入にあたり、こうした層に対して一時的に設定される高価格を「サブスクリプション価格」と呼ぶ。  

エ　若者にスノーレジャーを普及させるために、多くのスキー場は、往復交通費にウェアやスノーボードのレンタル料やリフト券を組み合わせた「キャプティブ価格」を設定し、アピールしている。
`
        },
        {
                "mondaiId": 103010321,
                "name": "第32問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　製品開発を効果的に行うために、多くの場合、企業担当者は<u>①製品開発プロセスを段階的に管理・実行</u>している。それぞれの段階において、<u>②調査や実験を行い</u>、<u>③それぞれの分析</u>結果に基づき意思決定を繰り返すことで、新製品の成功確率を高めるよ う努めている。  


（設問1）  
文中の下線部①に関する記述として、最も適切なものはどれか。  

ア　「アイデア・スクリーニング」において、新製品アイデアが多い場合でも取捨選択は十分に時間をかけて慎重に行うべきである。  

イ　「市場テスト」では、実験用仮設店舗を用いて消費者の反応を確認するよりも、実際の市場環境で十分な時間や予算を投入して製品やマーケティング施策をテストするべきである。  

ウ　開発中の製品および当該製品と競合する既存製品を対象に、消費者の「知覚マップ」を作成した場合、開発中の製品が空白領域に位置づけられたとしても、その製品に消費者ニーズや市場性があるとは限らない。<hint hidden='true'>**
←⭕
**</hint>    

エ　新製品アイデアのスクリーニングの次に、アイデアを具現化させるための試作品開発段階である「プロトタイピング」に移る。製品アイデアを具体的な製品属性に落とし込む作業であるため、通常、技術担当者に全権が委ねられる。  

オ　新製品開発に際して、市場規模を推定することは、製品開発の実現に投じる費用を誤って算定することにつながるため、不要である。  

`
        },
        {
                "mondaiId": 103010322,
                "name": "第32問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　製品開発を効果的に行うために、多くの場合、企業担当者は<u>①製品開発プロセスを段階的に管理・実行</u>している。それぞれの段階において、<u>②調査や実験を行い</u>、<u>③それぞれの分析</u>結果に基づき意思決定を繰り返すことで、新製品の成功確率を高めるよ う努めている。  


（設問2）  
文中の下線部②の調査や実験におけるデータ収集方法に関する記述として、最  
も適切なものはどれか。  

ア　観察法には、実験的条件下の調査対象者の行動を観察する方法や、調査者自らが体験しその体験自体を自己観察する方法が含まれる。<hint hidden='true'>**
←⭕
**</hint>    

イ　グループインタビューの司会者は、複数の参加者と均一な距離を保つことが求められる。共感を示したり、友好的関係を築こうとしたりしないほうがよい。  

ウ　デプスインタビューでは、考え方や価値観、行動スタイル、嗜好などを聞くことが可能である。また、グループインタビューと比較すると、他の参加者の影響を受けにくく、一人当たりの調査コスト（金銭および時間）は低い。  

エ　リード・ユーザー法は、例えば、市場の規模や競合に対する競争力を確認す  
るために、主として検証的調査で用いられる。
`
        },
        {
                "mondaiId": 103010323,
                "name": "第32問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　製品開発を効果的に行うために、多くの場合、企業担当者は<u>①製品開発プロセスを段階的に管理・実行</u>している。それぞれの段階において、<u>②調査や実験を行い</u>、<u>③それぞれの分析</u>結果に基づき意思決定を繰り返すことで、新製品の成功確率を高めるよ う努めている。  

（設問3）  
文中の下線部③の分析方法に関する記述として、最も適切なものはどれか。  

ア　2つの要素間の因果関係は、相関係数を算出することによって確認できる。  

イ　異なる性質を持つ対象が混在しているとき、クラスター分析を用いて、似ている対象から構成される相互に排他的なグループに分類することがある。<hint hidden='true'>**
←⭕
**</hint>    

ウ　順序尺度で測定された回答の集計では、一般的に、中央値と平均値が算出される。  

エ　例えば、特定店舗での消費金額に男女で差があるのかを確認したいときには、男女それぞれが消費する金額の平均値を求め、それらの平均値の間に統計的有意差があるといえるのかを、カイ二乗検定を用いて調べるとよい。
`
        },
        {
                "mondaiId": 103010331,
                "name": "第33問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　サービス財には、<u>①無形性、品質の変動性、不可分性、消滅性、需要の変動性といった特徴</u>がある。<u>②サービス組織やサービス提供者</u>は、これらの特徴を踏まえた対応が求められる。  

（設問1）  
文中の下線部①に関する記述として、最も適切なものはどれか。  

ア　「品質の変動性」の対応として、企業には慎重な従業員採用や教育の徹底が求められる。しかしながら、それに代わる機械の導入はサービスの均一化につながり、必然的に顧客の知覚品質は低下する。  

イ　「不可分性」といった特性により、サービス財の流通において、中間業者を活用することができない。そのため、サービス業のチャネルは、有形財と比較し て、短く単純なものになる。  

ウ　情報管理システムやAI技術を用いることで、需要に応じてフレキシブルに価格を変動させることが容易になった。「消滅性」「需要の変動性」の対応策として、サービス業者は、これらの仕組みを用いて思い切った値引きを行うべきである。  

エ　美容室のように人が顧客に提供するサービスは、「無形性」「不可分性」を有するため、在庫を持つことや生産場所から他の場所に移動させることが困難である。<hint hidden='true'>**
←⭕
**</hint>    

`
        },
        {
                "mondaiId": 103010332,
                "name": "第33問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　サービス財には、<u>①無形性、品質の変動性、不可分性、消滅性、需要の変動性といった特徴</u>がある。<u>②サービス組織やサービス提供者</u>は、これらの特徴を踏まえた対応が求められる。  

（設問2）  
文中の下線部②に関する記述として、最も適切なものはどれか。  

ア　「サービス・トライアングル」は、顧客・サービス提供者・企業の3者の関係性を図示している。中でも顧客とサービス提供者の関係をより密接なものにすることの重要性を説いている。  

イ　「サービス・プロフィット・チェーン」は、顧客満足が顧客ロイヤルティにつながることで事業の収益性が高まることに注目し、企業が顧客に目を向けることの重要性を強調している。  

ウ　「逆さまのピラミッド」の図では、マネジャーは現場スタッフを支援する立場にあり、両者の権限関係が製造業と逆転していることが示されている。  

エ　「真実の瞬間」とは、適切なサービスを、顧客が望むタイミングで提供することの重要性を示す概念である。  

オ　サービス品質の計測尺度である「サーブクォル（SERVQUAL）」では、サービス利用前と利用後の2時点で評価を計測し、それらの差を確認することが推奨されている。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 103010340,
                "name": "第34問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 103,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　消費者の情報処理や購買意思決定に影響をもたらす関与に関する記述として、最  
も適切なものはどれか。  

ア　関与とは製品カテゴリーに限定した消費者の関心度、重要度の程度のことである。  

イ　関与の水準は、消費者によって異なるが、当該消費者においては変動せず、安定的である。  

ウ　高関与な消費者に対して、商品の金銭的・社会的リスクや専門性を知覚させることで、企業は自社が行うマーケティング・コミュニケーション活動への反応を高めることができる。<hint hidden='true'>**
←⭕
**</hint>    

エ　低関与である場合、消費者は購買したり、利用したりする前に、製品に対する慎重な評価を行う。  
`
        },
        {
                "mondaiId": 104010010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
管理指標に関する記述として、最も適切なものはどれか。  

ア　稼働率とは、人または機械における<hintTarget>就業時間もしくは拘束時間</hintTarget>を、<hintTarget>有効稼働時間</hintTarget>で除したものである。  

<hint hidden='true'>**
❌稼働率の公式は、  
稼働率 ＝ 有効稼働時間　÷　<hintTarget>（就業時間もしくは拘束時間）  
なので、分母分子が逆です。</hintTarget>**</hint>

イ　<hintTarget>生産</hintTarget>リードタイムは、<hintTarget>顧客が注文してから</hintTarget>その製品を手にするまでの時間である。  

<hint hidden='true'>**
❌生産リードタイムではなく、<hintTarget>発注</hintTarget>リードタイムに関する記述です。生産リードタイムは、生産の着手時期から完了時期までの時間です。**</hint>

ウ　直行率とは、初工程から最終工程まで、手直しや手戻りなどがなく順調に通過した<hintTarget>品物の生産数量</hintTarget>を、<hintTarget>工程に投入した品物の数量</hintTarget>で除したものである。  

<hint hidden='true'>**
⭕
**</hint>

エ　歩留まりとは、投入された<hintTarget>主原材料の量</hintTarget>を、<hintTarget>産出された品物の量</hintTarget>で除したものである。  

<hint hidden='true'>**
❌歩留まりの公式は、  
歩留まり ＝ 産出された品物の量　÷　<hintTarget>主原材料の量  
なので、分母分子が逆です。</hintTarget>**</hint>

`
        },
        {
                "mondaiId": 104010020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　生産工程における<hintTarget>加工品の流れ</hintTarget>の違いによって区別される用語の組み合わせとして、最も適切なものはどれか。  
<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】生産形態の分類と対比
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD></TD>
<TD style='border: 1px solid gray;background-color:#ddddee;' style=''>次工程への指示</TD>
<TD style='border: 1px solid gray;'>押出型  
（別名  
プッシュ・システム  
プッシュ型）</TD>
<TD style='border: 1px solid gray;'>引取型  
（別名  
プル・システム  
プル型）</TD>
<TD>　</TD>
</TR>
<TR>
<TD></TD>
<TD style='border: 1px solid gray;background-color:#ddddee;'>●品種●量生産</TD>
<TD style='border: 1px solid gray;'>多品種少量生産</TD>
<TD style='border: 1px solid gray;'><hintTarget>少品種多量</hintTarget>生産</TD>
<TD>　</TD>
</TR>
<TR>
<TD></TD>
<TD style='border: 1px solid gray;background-color:#ddddee;'>生産機械の配置（<hintTarget>加工品の流れの違い</hintTarget>）</TD>
<TD style='border: 1px solid gray;'>ジョブショップ  
同じ機能・種類の機械を1箇所に集中配置する</TD>
<TD style='border: 1px solid gray;'><hintTarget>フロー</hintTarget>ショップ  
品種が限られているので、生産で使う機器を、品種ごとに工程の順に近接して配置する（理由：効率的だから）</TD>
<TD>　</TD>
</TR>
<TR>
<TD></TD>
<TD style='border: 1px solid gray;background-color:#ddddee;'>見込生産  or  受注生産</TD>
<TD style='border: 1px solid gray;'><hintTarget>見込</hintTarget>生産</TD>
<TD style='border: 1px solid gray;'><hintTarget>受注</hintTarget>生産</TD>
<TD>　</TD>
</TR>
<TR>
<TD></TD>
<TD style='border: 1px solid gray;background-color:#ddddee;'>イメージ</TD>
<TD style='border: 1px solid gray;'>🍣↻回転寿司</TD>
<TD style='border: 1px solid gray;'>🍣カウンターの寿司</TD>
<TD>　</TD>
</TR>

</TABLE>
</div>
</hint>

ア　押出型と引取型  
<hint hidden='true'>**
❌<hintTarget>生産開始の契機</hintTarget>の違いによる区別です。**
</hint>

イ　多品種少量生産と少品種多量生産  
<hint hidden='true'>**
❌<hintTarget>生産する品種の数と、生産数の比率</hintTarget>の違いによる区別です。**
</hint>

ウ　フローショップ型とジョブショップ型  
<hint hidden='true'>**
⭕
**</hint>

エ　見込生産と受注生産  
<hint hidden='true'>**
❌<hintTarget>生産と注文の前後関係  
見込生産　：　生産者が顧客の注文を見越して生産  
受注生産　：　顧客の注文を受けて生産者が生産  
</hintTarget>の違いによる区別です。
**</hint>
`
        },
        {
                "mondaiId": 104010030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
ある工場でＡ～Ｅの5台の機械間における運搬回数を分析した結果、次のフロムツウチャートが得られた。この表から読み取れる内容に関する記述として、最も適切なものを下記の解答群から選べ。  

<TABLE border="1" align="left" width="100%">
<TR>
<TD align="center" style=" background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');">
　To<BR>
<BR>
From　</TD>
<TD align="center">A</TD>
<TD align="center">B</TD>
<TD align="center">C</TD>
<TD align="center">D</TD>
<TD align="center">E</TD>
</TR>

<TR>
<TD align="center">A</TD>
<TD align="center" style=" background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');"></TD>
<TD align="center">12</TD>
<TD align="center">5</TD>
<TD align="center">25</TD>
<TD align="center"><hint hidden='true'>**
AからEへ移動ないので選択肢アは不適切
**</hint>
</TD>
</TR>

<TR>
<TD align="center">B</TD>
<TD align="center"></TD>
<TD align="center" style=" background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');"></TD>
<TD align="center">11</TD>
<TD align="center"></TD>
<TD align="center">4</TD>
</TR>

<TR>
<TD align="center">C</TD>
<TD align="center"></TD>
<TD align="center"></TD>
<TD align="center" style=" background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');"></TD>
<TD align="center">2</TD>
<TD align="center"> </TD>
</TR>

<TR>
<TD align="center">D</TD>
<TD align="center">11<hint hidden='true'>**
逆流
**</hint></TD>
<TD align="center"></TD>
<TD align="center"></TD>
<TD align="center" style=" background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');"></TD>
<TD align="center"> </TD>
</TR>

<TR>
<TD align="center">E</TD>
<TD align="center"></TD>
<TD align="center">27<hint hidden='true'>**
逆流
**</hint></TD>
<TD align="center"></TD>
<TD align="center"></TD>
<TD align="center" style=" background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');"></TD>
</TR>

</TABLE>  


〔解答群〕  
ア　機械Ａから他の全ての機械に品物が移動している。  
<hint hidden='true'>**
❌不適切です。
**</hint>

イ　逆流が<hintTarget>一カ所</hintTarget>発生している。  
<hint hidden='true'>**
❌不適切です。<hintTarget>2</hintTarget>箇所発生しています。
**</hint>

ウ　他の機械からの機械Ｂへの運搬回数は12である。  
<hint hidden='true'>**
❌不適切です。<hintTarget>12+27</hintTarget>＝39回発生しています。
**</hint>

エ　最も運搬頻度が高いのは機械Ａ・Ｄ間である。
<hint hidden='true'>**
⭕適切です。A→D25回＋D→A11回＝36回発生しています。
**</hint>
`
        },
        {
                "mondaiId": 104010040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　加工技術に関する記述として、最も適切なものはどれか。  

ア　<hintTarget>超音波加工</hintTarget>は、液体を加圧して微小穴から噴射し、工作物に衝突させることによって工作物を微小破砕させて、主として切断を行う加工法である。  

<hint hidden='true'>**
❌不適切です。理由：<hintTarget>ウォータージェット工法</hintTarget>に関する記述です。
**</hint>

イ　<hintTarget>電子ビーム加工</hintTarget>は、波長や位相がよくそろった光を、レンズとミラーで微小スポットに集束させ、このときに得られる高いエネルギー密度を利用して工作物の切断、溶接などを行う加工法である。  
<hint hidden='true'>**
❌不適切です。理由：<hintTarget>レーザー加工</hintTarget>に関する記述です。
**</hint>

ウ　<hintTarget>プラズマ加工</hintTarget>は、気体を極めて高温にさせ、気体原子を陽イオンと自由電子に解離しイオン化させ、この状態を利用して切断、穴あけ、溶接などを行う加工法である。  
<hint hidden='true'>**
⭕適切です。
**</hint>

エ　<hintTarget>レーザ加工</hintTarget>は、電子を高電圧によって加速し工作物に衝突させ、発生する熱エネルギーを利用して工作物を溶融させて除去する加工法である。  
<hint hidden='true'>**
❌不適切です。理由：<hintTarget>電子ビーム加工</hintTarget>に関する記述です。
**</hint>

`
        },
        {
                "mondaiId": 104010050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　要素作業ａ～ｇの先行関係が下図に示される製品を、単一ラインで生産する。生産計画量が<hintTarget>380個</hintTarget>、稼働予定時間が<hintTarget>40時間</hintTarget>のとき、実行可能なサイクルタイムと最小作業工程数の組み合わせとして、最も適切なものを下記の解答群から選べ。  

<hint hidden='true'>**  
まず、問題文の、生産計画と稼働予定時間を満たすための最長のサイクルタイムを求めます。  
サイクルタイム（製品が算出される時間間隔。次工程に進むサイクル）  
＝　稼働予定時間／生産計画量  
＝　40*60／380  
≒　6.3
となり、6.3以下のサイクルタイムを満たす選択肢はアかイになります。    
また、6分のサイクルタイムを充足する工程は下記の3つの工程となります。
** </hint>

<imgTag>104010050_1.png</imgTag>  
※○は要素作業、○の右上の数字は要素作業時間（分）を表す。  


〔解答群〕  
ア　サイクルタイム：　6分　　最小作業工程数：　3
<hint hidden='true'>**
←⭕適切です
** </hint>  
イ　サイクルタイム：　6分　　最小作業工程数：　4  

ウ　サイクルタイム：　9分　　最小作業工程数：　2  

エ　サイクルタイム：　9分　　最小作業工程数：　3
`
        },
        {
                "mondaiId": 104010060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　生産座席予約方式に関する記述として、最も適切なものはどれか。  

ア　外注に際して発注者が、外注先へ資材を支給する方式である。  

<hint hidden='true'>**
❌不適切です。<hintTarget>材料支給方式</hintTarget>に関する記述です。
** </hint>

イ　組立を対象としたラインや機械、工程、作業者へ、1つの組立品に必要な各種の部品を1セットとして、そのセット単位で部品をそろえて出庫および供給する方式である。  
 
<hint hidden='true'>**
❌不適切です。部品のピッキング作業を効率化する際に用いられる、<hintTarget>マーシャリング</hintTarget>に関する記述です。
** </hint>

ウ　受注時に、製造設備の使用日程・資材の使用予定などにオーダーを割り付けて生産する方式である。  

<hint hidden='true'>**  
⭕適切です。
** </hint>

エ　製造命令書を発行するときに、その製品に関する全ての加工と組立の指示書を同時に準備し、同一の製造番号をそれぞれに付けて管理を行う方式である。
 
<hint hidden='true'>**
❌不適切です。<hintTarget>製番管理方式</hintTarget>に関する記述です。
** </hint>

<hint hidden='true'>**
<imgTag>104010060_1.png</imgTag>  
** </hint>

`
        },
        {
                "mondaiId": 104010070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下図は、最終製品Ａの部品構成表であり、（　）内は<hintTarget>親1個に対して</hintTarget>必要な部品の個数である。製品Ａを2個生産するとき、必要部品数量に関する記述として、最も適切なものを下記の解答群から選べ。  

<hint hidden='true'>**
図中に製品Aを1個生産するために必要な個数を追記すると、下記になります。  
** </hint>
<imgTag>104010070_1.png</imgTag>  
<hint hidden='true'>**
したがって、Aを2個生産するために各部品の個数を計算すると、下記になります。
a: 6個  
b: 4個  
c: 24個  
d: 96個  
e: 64個  
f: 16個  
** </hint>


〔解答群〕  
ア　部品ｃは12個必要である。  
イ　部品ｄは36個必要である。  
ウ　部品ｅは64個必要である。<hint hidden='true'>**←⭕適切です。
** </hint>  
エ　部品ｆは60個必要である。
`
        },
        {
                "mondaiId": 104010080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
需要予測に関する記述として、最も適切なものはどれか。  

ア　過去の観測値から将来の需要量を予測するために移動平均法を利用した。  

<hint hidden='true'>**  
⭕適切です。例えば、移動平均法により、過去1週間の販売数の平均を日々算出します。すると、日々の偶発的な需要変動を取り除いた販売数の大まかなトレンド（増加傾向／減少傾向）がわかるため、需要予測に活用することができます。
** </hint>

イ　<hintTarget>過去の</hintTarget>観測値ほど重みを指数的に増加させるために指数平滑法を利用した。  

<hint hidden='true'>**
❌指数平滑法で重み付けするのは、<hintTarget>最近の観測値です。指数平滑法も移動平均法の一つですが、単純な移動平均では過去と最近で同じ重み付けをするのに対し、指数平滑法は最近の観測値に重み付けします。最近の観測値ほど将来の予測に影響が大きいと考えられる際などに、指数平滑法を用います。</hintTarget>
** </hint>

ウ　工場の新設に当たっての設備能力を決定するために<hintTarget>短期予測</hintTarget>を利用した。  

<hint hidden='true'>**
❌長期予測を用いるべきケースです。
** </hint>

エ　次週の生産計画を立案するために<hintTarget>長期予測</hintTarget>を利用した。  

<hint hidden='true'>**
❌短期予測を用いるべきケースです。
** </hint>

`
        },
        {
                "mondaiId": 104010090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　2工程のフローショップにおけるジョブの投入順序を考える。各ジョブ各工程の加工時間が下表のように与えられたとき、生産を開始して全てのジョブの加工を完了するまでの時間（メイクスパン）を最小にする順序として、最も適切なものを下記の解答群から選べ。  

<TABLE border="1" align="left" width="100%">
<TR>
<TD>ジョブ</TD>
<TD>J1</TD>
<TD>J2</TD>
<TD>J3</TD>
</TR>
<TR>
<TD>第1工程</TD>
<TD>3時間</TD>
<TD>5時間</TD>
<TD>1時間</TD>
</TR>
<TR>
<TD>第2工程</TD>
<TD>2時間</TD>
<TD>4時間</TD>
<TD>6時間</TD>
</TR>
</table>

<hint hidden='true'>**  
最小メイクスパンとなる順序は下記であるため、エが正解です。  
<imgTag>104010090_1.png</imgTag>  
** </hint>
  
〔解答群〕  
ア　J1　→　J2　→　J3  
イ　J1　→　J3　→　J2  
ウ　J2　→　J1　→　J3  
エ　J3　→　J2　→　J1<hint hidden='true'>**
←⭕
** </hint>  
`
        },
        {
                "mondaiId": 104010100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　<hintTarget>経済的発注量</hintTarget>Qを表す数式として、最も適切なものはどれか。ただし、dを1期当たりの推定所要量、cを1回当たりの発注費、hを1個1期当たりの保管費とする。  

<hint hidden='true'>**
経済的発注量（総費用が最小となる発注量）は、  
在庫費用＝発注費  
となる発注量であるため、図中の式を変形することで、正解にたどり着ける問題でした。  
<imgTag>104010100_5.png</imgTag>  
** </hint>  

ア　<imgTag>104010100_1.png</imgTag>  
イ　<imgTag>104010100_2.png</imgTag>  
ウ　<imgTag>104010100_3.png</imgTag>  
エ　<imgTag>104010100_4.png</imgTag><hint hidden='true'>**
⭕
** </hint>  

`
        },
        {
                "mondaiId": 104010110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　QC7つ道具に関する記述として、最も適切なものはどれか。  

ア　<hintTarget>管理図</hintTarget>は、<hintTarget>2つの対になった</hintTarget>データをXY軸上に表した図である。  

<hint hidden='true'>**
❌管理図でなく散布図に関する記述です。
** </hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】QC7７つ道具  
<TABLE border="5" bordercolor="#ffffff" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD style='background-color:#ffdddd;'>チェックシート</TD>
<TD><imgTag>104010110_2.png</imgTag></TD>
<TD style='background-color:#ddddff;'><hintTarget>確認事項</hintTarget>をまとめて、データ収集を効率化したり、整理する
</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>特性要因</hintTarget>図</TD>
<TD><imgTag>104010110_3.png</imgTag></TD>
<TD style='background-color:#ddddff;'>結果と原因の関係を分析する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>パレート</hintTarget>図</TD>
<TD><imgTag>104010110_4.png</imgTag></TD>
<TD style='background-color:#ddddff;'>不具合の原因を<hintTarget>発生頻度</hintTarget>順に並べて、原因調査の絞り込みなどに活用する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>ヒストグラム</hintTarget>
</TD>
<TD><imgTag>104010110_5.png</imgTag></TD>
<TD style='background-color:#ddddff;'>区分ごとのデータの分布を俯瞰する
</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>散布</hintTarget>図</TD>
<TD><imgTag>104010110_6.png</imgTag></TD>
<TD style='background-color:#ddddff;'>X,Y軸に2つの特性を配置して相関の有無を見る</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'>管理図</TD>
<TD><imgTag>104010110_7.png</imgTag></TD>
<TD style='background-color:#ddddff;'><hintTarget>許容範囲の上限加減の逸脱状況</hintTarget>の点検などに使用する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'>グラフ</TD>
<TD>　</TD>
<TD style='background-color:#ddddff;'>必要に応じて可視化して、実態把握することを推奨しています</TD>
</TR>

</TABLE>
</div>
</hint>

イ　特性要因図は、原因と結果の関係を魚の骨のように表した図である。<hint hidden='true'>**
⭕
** </hint>  

ウ　パレート図は、不適合の原因を発生件数の<hintTarget>昇順</hintTarget>に並べた図である。  

<hint hidden='true'>**
❌多くのケースで何が原因だったのかより判別しやすいように、降順になっています。
** </hint>

エ　ヒストグラムは、<hintTarget>時系列データを折れ線グラフ</hintTarget>で表した図である。  

<hint hidden='true'>**
❌ヒストグラムでなく管理図に関する記述です。
** </hint>


`
        },
        {
                "mondaiId": 104010120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　工場内の運搬に関する施策として、最も適切なものはどれか。  

ア　工程間の運搬頻度を考慮してレイアウトを見直した。  

<hint hidden='true'>**
⭕適切です。 運搬頻度の高い工程ほど近いレイアウトになるよう考慮するなど。
** </hint>

イ　工程間の距離情報<hintTarget>のみ</hintTarget>を用いて運搬手段を選択した。  

<hint hidden='true'>**
❌頻度・動線が交錯しないようにするなど、考慮すべきことは距離のみに限りません。
** </hint>

ウ　離れた工程間を時折運搬する手段として<hintTarget>ベルトコンベア</hintTarget>を採用した。  

<hint hidden='true'>**
❌フォークリフトが適するケースです。
** </hint>

エ　隣接した工程へ頻繁に物を運搬するために<hintTarget>フォークリフト</hintTarget>を導入した。  

<hint hidden='true'>**
❌ベルトコンベアが適するケースです。
** </hint>


`
        },
        {
                "mondaiId": 104010130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　工程分析に関する記述として、最も適切なものはどれか。  

ア　「加工」を表す工程分析記号は<hintTarget>◇</hintTarget>である。  

<hint hidden='true'>**
❌○です。
** </hint>

イ　「加工」を主として行いながら「運搬」することを表す複合記号が存在する。  

<hint hidden='true'>**
⭕適切です。<img src="assets/img/kaisetu/104010130_1.png" width="30em"/>　が該当の記号です。
** </hint>

ウ　「検査」には「<hintTarget>計量</hintTarget>検査」と「<hintTarget>計数</hintTarget>検査」の2種類がある。  

<hint hidden='true'>**
❌<hintTarget>数量</hintTarget>検査<hintTarget>と品質</hintTarget>検査です。
** </hint>

エ　「停滞」は「貯蔵」と「滞留」に分類されるが、相違点は停滞している<hintTarget>時間の長さ</hintTarget>である。  

<hint hidden='true'>**
❌<hintTarget>（貯蔵などの）計画的</hintTarget>な停滞か、<hintTarget>非計画的</hintTarget>な停滞かの違いです。
** </hint>


`
        },
        {
                "mondaiId": 104010141,
                "name": "第14問設問１",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　ある工程では1人の作業者が製品の箱詰めを行っている。この工程の標準時間を 算出するため、作業内容を以下のように作業1と作業2に分割して、時間観測を行うこととした。  

　作業1　　箱を組み立てる。  
　作業2　　製品を5個箱に詰め、テープで封をする。  

　作業者が「作業1→作業2」のサイクルを5回繰り返したときの各作業の終了時刻を、ストップウオッチ（単位DM、ただし1分=100DM）を使って観測した。その結果を観測用紙に記入したものが下表である。ただし、観測開始時点のストップウオッチの目盛りは<hintTarget>5DM</hintTarget>であった。  
　<hintTarget>作業1</hintTarget>に関する下記の設問に答えよ。  

<imgTag>104010141_1.png</imgTag>  

（設問1）  
観測時間の平均値（単位：秒）として、最も適切なものはどれか。ただし、観測時間の平均は算術平均を用いる。  

ア　6  <hint hidden='true'>**
←⭕(10　+　10　+　9　+　10　+　11)　÷　5　＝　10 [DM]  
10/100×60　＝　6[秒]  
**</hint>    
イ　10  
ウ　11  
エ　56
`
        },
        {
                "mondaiId": 104010142,
                "name": "第14問設問２",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　ある工程では1人の作業者が製品の箱詰めを行っている。この工程の標準時間を 算出するため、作業内容を以下のように作業1と作業2に分割して、時間観測を行うこととした。  

　作業1　　箱を組み立てる。  
　作業2　　製品を5個箱に詰め、テープで封をする。  

　作業者が「作業1→作業2」のサイクルを5回繰り返したときの各作業の終了時刻を、ストップウオッチ（単位DM、ただし1分=100DM）を使って観測した。その結果を観測用紙に記入したものが下表である。ただし、観測開始時点のストップウオッチの目盛りは5DMであった。  
　<hintTarget>作業1</hintTarget>に関する下記の設問に答えよ。  

<imgTag>104010142_1.png</imgTag>  

（設問2）  
　<hintTarget>レイティング係数が90</hintTarget>と観測され、余裕率を5％と設定したときの標準時間（単位：秒）として、最も適切なものはどれか。  

<hint hidden='true'>**
レイティング係数とは、観測者の作業時間を、標準的な作業者が継続的に達成できる作業時間に変換するための係数です。  
　例えばこの問題で用いられているレイティング係数９０なので、観測した作業者は標準的な作業者より、作業スピードが<hintTarget>１割程度遅</hintTarget>いと考えられます。  
**</hint>    

ア　5.1  
イ　5.7  <hint hidden='true'>**
←⭕  
正味時間　＝　観測時間の平均　×　<hintTarget>レイティング係数　÷　１００  
　　　　　＝　６×９０÷１００</hintTarget>  
　　　　　＝　５．４[秒]  
標準時間　＝　正味時間　×　<hintTarget>（１＋余裕率）</hintTarget>  
　　　　　＝　５．４　×　（１＋０．０５）  
　　　　　≒　５．７  
**</hint>    
ウ　6.4  
エ　7.1
`
        },
        {
                "mondaiId": 104010150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　ある工程における製品Ａの1個当たりの標準作業時間は0.3時間で、適合品率は90％である。この工程を担当する作業者は5人で、1人1日当たりの実働時間は6時間、稼働率は90％である。今期、残り10日間に適合品を900個生産しなければならないことが分かっている。  

　この場合にとるべき施策として、最も適切なものはどれか。  

ア　一部作業の外注化を行う。  

<hint hidden='true'>**
⭕適切な記述です。  
問題文の条件から、施策未実施の場合の、10日間の生産量を見積もります。  
施策未実施の場合の、10日間の生産量＝<hintTarget>6時間×90%÷0.3[時間/個]×5人×90%×10日</hintTarget>＝810個  
残り10日間で適合品900個を生産するために90個の不足を解決する施策として、外注は適切です。
**</hint>  

イ　次期の仕事を前倒しして行う。  

<hint hidden='true'>**
❌選択肢アの解説のとおり、<u>今期</u>の適合品の生産量不足への施策が必要です。  
**</hint>

ウ　終業時刻を早めて小集団活動を行う。  

<hint hidden='true'>**
❌選択肢アの解説のとおり、今期の適合品の生産量不足への施策が必要です。終業時刻を早めると生産量がもっと不足してしまいます。
**</hint>

エ　特別な施策は必要ない。

<hint hidden='true'>**
❌選択肢アの解説のとおり、今期の適合品の生産量不足への施策が必要です。
**</hint>

`
        },
        {
                "mondaiId": 104010160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　PTS（Predetermined-TimeStandard）法に関する記述として、最も適切なものはどれか。  

ア　<hintTarget>機械によってコントロールされる</hintTarget>時間および<hintTarget>躊躇（ちゅうちょ）や判断を必要とする作業時</hintTarget>間も含めて、ほとんどの作業時間を算出することができる。  

<hint hidden='true'>**
❌PTS法とは、人間の作業を、それを構成する基本動作にまで分解。基本動作の性質と条件に応じて、予め決められた基本となる時間力その作業時間を求める方法です。  
上記動作は、いずれも基本動作に含まれません。
**</hint>

イ　個人的判断によらない正確かつ公平な時間値を設定する方法である。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　人の行う全ての作業を、それを構成する<hintTarget>要素作業</hintTarget>に分解し、その要素作業の性質と条件に応じて、前もって定められた時間値を当てはめる手法である。  

<hint hidden='true'>**
❌PTS法であれば「基本動作」と記述すべき箇所を、要素作業と記述しています。  
・要素作業：　作業を分割したときの単位要素。ストップウォッチ法などで、時間計測する際の単位。例：部品を右から左へ移動させる  
・基本動作：　PTS法で作業時間を算出する際は基本動作単位で行う。例：手をのばす。
**</hint>

エ　標準時間を構成する<hintTarget>余裕時間</hintTarget>を算出する方法である。

<hint hidden='true'>**
❌標準時間　＝　作業標準時間　＋　準備標準時間  
　　　　　　＝（正味作業時間＋余裕時間）＋（準備正味時間＋準備余裕時間）  
です。PTS法は、標準時間の全体を算出します。  
標準時間の一部である余裕時間が算出できるものではありません。
**</hint>

`
        },
        {
                "mondaiId": 104010170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下のａ～ｅの記述は、職場管理における5Sの各内容を示している。5Sを実施する手順として、最も適切なものを下記の解答群から選べ。  

ａ　問題を問題であると認めることができ、それを自主的に解決できるように指導する。<hint hidden='true'>**
←しつけです
**</hint>      

ｂ　必要なものが決められた場所に置かれ、使える状態にする。<hint hidden='true'>**
←整頓です
**</hint>    

ｃ　���要なものと不必要なものを区分する。<hint hidden='true'>**
←整理です
**</hint>    

ｄ　隅々まで掃除を行い、職場のきれいさを保つことにより、問題点を顕在化させる。<hint hidden='true'>**
←清掃です
**</hint>      

ｅ　職場の汚れを取り除き、発生した問題がすぐ分かるようにする。<hint hidden='true'>**
←清潔です
**</hint>      

<hint hidden='true'>
５Sの定義は下記です。下図の①から順に取り組むことが推奨されています。
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】５S  
<imgTag>104010170_1.gif</imgTag>  
</div>
</hint>

〔解答群〕  
ア　ａ→ｂ→ｃ→ｄ→ｅ  
イ　ｂ→ｅ→ｄ→ｃ→ａ  
ウ　ｃ→ｂ→ｄ→ｅ→ａ<hint hidden='true'>**
←⭕
**</hint>    
エ　ｄ→ｂ→ｃ→ａ→ｅ

`
        },
        {
                "mondaiId": 104010180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　生産保全の観点から見た保全活動の実施に関する記述として、最も適切なものはどれか。  

ア　<hintTarget>偶発</hintTarget>故障期にある設備の保全体制として、部品の寿命が来る前に部品を交換し、故障の未然防止を図る必要があるため、予知保全体制を確立することが重要である。  

<hint hidden='true'>**
❌摩耗故障期に関する記述です。  
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】  
<imgTag>104010180_1.png</imgTag>
</div>
</hint>

イ　初期故障期にある設備では、設計ミスや潜在的な欠陥による故障が発生する可能性が高く、調整・修復を目的とした予防保全を実施する。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】  
<imgTag>104010180_2.png</imgTag>
</div>
</hint>

ウ　設備の故障率は使用開始直後に徐々に<hintTarget>増加</hintTarget>し、ある期間が過ぎると一定となり、その後劣化の進行とともに故障率は<hintTarget>減少</hintTarget>する。  

<hint hidden='true'>**
❌増加と減少が逆です。
**</hint>

エ　定期保全とは、従来の故障記録などから周期を決めて周期ごとに行う保全方式で、<hintTarget>初期</hintTarget>故障期にある設備に対して実施される。

<hint hidden='true'>**
❌初期故障期には、定期保全は適しません。そもそも定期保全の根拠となる故障記録が蓄積されていないことと、類似機器の故障記録が存在したとしても、初期故障の原因は設置や機器の個体差に起因するものも含まれるためです。
**</hint>

`
        },
        {
                "mondaiId": 104010190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　ある工程を自動化するためには、設備の改良に<hintTarget>1，000万円</hintTarget>を投資する必要がある。この自動化投資を<hintTarget>6年で回収</hintTarget>するために必要な最小の原価低減額（万円／年）として、最も適切なものを下記の解答群から選べ。計算利率を8％とし、必要ならば下表の換算係数を使うこと。  

表　計算利率8％の換算係数  
<TABLE border="1" bordercolor="#000000" width="100%" style='background-color:#ffffff;color:#111111;'>
<TR>
<TD>年</TD>
<TD>現価係数</TD>
<TD>資本回収係数</TD>
<TD>年金終価係数</TD>
</TR>
<TR>
<TD>1</TD>
<TD>0.93</TD>
<TD>1.08</TD>
<TD>1.00</TD>
</TR>
<TR>
<TD>2</TD>
<TD>0.86</TD>
<TD>0.56</TD>
<TD>2.08</TD>
</TR>
<TR>
<TD>3</TD>
<TD>0.79</TD>
<TD>0.39</TD>
<TD>3.25</TD>
</TR>
<TR>
<TD>4</TD>
<TD>0.74</TD>
<TD>0.30</TD>
<TD>4.51</TD>
</TR>
<TR>
<TD>5</TD>
<TD>0.68</TD>
<TD>0.25</TD>
<TD>5.87</TD>
</TR>
<TR>
<TD><hintTarget>6</hintTarget></TD>
<TD>0.63</TD>
<TD><hintTarget>0.22</hintTarget></TD>
<TD>7.34</TD>
</TR>
<TR>
<TD>7</TD>
<TD>0.58</TD>
<TD>0.19</TD>
<TD>8.92</TD>
</TR>
</TABLE>

<hint hidden='true'>**
原価低減額＝投資金額×<hintTarget>資本回収係数</hintTarget>
          ＝1000×0.22
          ＝220万円
**</hint>  


〔解答群〕  

ア　136  
イ　167  
ウ　190  
エ　220<hint hidden='true'>**
←⭕
**</hint>  

`
        },
        {
                "mondaiId": 104010200,
                "name": "第20問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　<hintTarget>TPM</hintTarget>に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　<hintTarget>製品の</hintTarget>ライフサイクル全体を対象とし、災害ロス・不良ロス・故障ロス等あらゆるロスを未然に防止するしくみを構築する。  

<hint hidden='true'>**
❌正式名称はTotalProductiveMaintenanceであり、<hintTarget>生産</hintTarget>のライフサイクル全体を対象とします。
**</hint>

ｂ　設備効率化を阻害している7大ロスを時間的ロスの面から検討し、設備の使用率の度合いを表した指標が設備総合効率である。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ｃ　経営トップから現場の作業員まで全員参加の重複小集団活動を行うことが特徴で、職制に<hintTarget>とらわれない</hintTarget>自主的なサークル活動である。  

<hint hidden='true'>**
❌職制（職場での職務の分担に関する制度。課、係、班）にとらわれないわけではありません。職制ごとの活動を通じて、上位層・下位層でフィードバックできる効果があります。
**</hint>

ｄ　ロスを発生させないために行う活動の1つが計画保全活動で、設備が停止した場合の損失影響度を複数の角度から設備評価基準に基づいて評価し、最適保全方式を決める。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  


〔解答群〕  

アａとｂ  
イａとｃ  
ウａとｄ  
エｂとｃ  
オｂとｄ<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　<hintTarget>動作経済の原則</hintTarget>に基づいて実施した改善に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

<hint hidden='true'>**
動作経済の原則は、作業を行うのに効率的な身体動作を  
（１）身体部位の使用についての原則  
（２）作業場所の配置についての原則  
（３）設備・工具の設計についての原則  
の3つに分類してまとめたものです。
**</hint>  


ａ　機械が停止したことを知らせる回転灯を設置した。  

<hint hidden='true'>**
❌身体動作の効率化ではありません（異常検知の速度や精度の改善）。
**</hint>

ｂ　径の異なる2つのナットを2種類のレンチで締めていたが、2種類の径に対応できるように工具を改良した。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ｃ　2つの部品を同時に挿入できるように保持具を導入した。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ｄ　プレス機の動作中に手が挟まれないようにセンサを取り付けた。  

<hint hidden='true'>**
❌身体動作の効率化ではありません（安全面の改善）。
**</hint>


〔解答群〕  

ア　ａとｂ  
イ　ａとｄ  
ウ　ｂとｃ<hint hidden='true'>**
←⭕
**</hint>    
エ　ｂとｄ  
オ　ｃとｄ
`
        },
        {
                "mondaiId": 104010220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　わが国のショッピングセンター（SC）の現況について、一般社団法人日本ショッピングセンター協会が公表している「全国のSC数・概況」（2018年末時点で営業中のSC）から確認できる記述として、最も適切なものはどれか。  
　なお、立地については、以下のように定義されている。  

中心地域：　人口15万人以上の都市で、商業機能が集積した中心市街地  
周辺地域：　上記中心地域以外の全ての地域  

ア　1SC当たりの平均テナント数は約<hintTarget>200</hintTarget>店である。  

<hint hidden='true'>**
❌50店です。
**</hint>

イ　2013年と2018年の業種別テナント数の割合を比較すると、物販店の割合は減少し、サービス店の割合は増加している。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　SCを立地別に分類した場合、周辺地域よりも中心地域のほうが<hintTarget>多い</hintTarget>。  

<hint hidden='true'>**
❌少ないです。自動車による来店を前提として、用地取得が容易であることから、郊外の周辺地域へのSC出店が多くなる傾向があります。（概ね1：6の比率）
**</hint>

エ　新規オープン1SC当たりの平均店舗面積は、2001年以降、年単位で<hintTarget>一貫</hintTarget>して増加している。  

<hint hidden='true'>**
❌増減を繰り返しており、一貫した傾向はありません。
**</hint>

オ　ディベロッパー業種・業態別SC数において、小売業で最も多いものは、<hintTarget>百貨店</hintTarget>である。

<hint hidden='true'>**
❌総合スーパーです。
**</hint>

`
        },
        {
                "mondaiId": 104010230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　都市計画法および建築基準法で定められている用途地域と建築物について、床面積が<hintTarget>2，000平方メートル</hintTarget>のスーパーマーケットを建築できる用途地域の組み合わせとして、最も適切なものはどれか。  

ア　<hintTarget>工業専用</hintTarget>地域と商業地域  

<hint hidden='true'>**
❌工業専用地域には物販、飲食店の建築ができません。
**</hint>

イ　第一種住居地域と商業地域  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　第一種住居地域と<hintTarget>第二種中高層住居専用</hintTarget>地域  

<hint hidden='true'>**
❌第二種中高層住居専用地域に建築できる店舗は、1500平方メートル以下です。
**</hint>

エ　<hintTarget>第二種中高層住居専用</hintTarget>地域と近隣商業地域  

<hint hidden='true'>**
❌選択肢ウと同じ。
**</hint>

オ　<hintTarget>第二種中高層住居専用</hintTarget>地域と準工業地域

<hint hidden='true'>**
❌選択肢ウと同じ。
**</hint>

`
        },
        {
                "mondaiId": 104010240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　都市再生特別措置法においては、市町村は、都市計画法に規定される区域について、都市再生基本方針に基づき、住宅および都市機能増進施設の立地の適正化を図るための計画（立地適正化計画）を作成することができることとされている。  
　下図は、国土交通省が平成28年に公表した『都市計画運用指針における立地適正化計画に係る概要』で説明されている立地適正化計画の区域について、その基本的な関係を表したものである。図中のＡ～Ｃに該当する語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

<imgTag>104010240_1.svg</imgTag>  

〔解答群〕  
ア　Ａ：市街化調整区域　Ｂ：居住誘導区域　　　　Ｃ：都市機能誘導区域  
イ　Ａ：市街化調整区域　Ｂ：線引き都市計画区域　Ｃ：非線引き都市計画区域  
ウ　Ａ：市街化調整区域　Ｂ：都市機能誘導区域　　Ｃ：居住誘導区域  
エ　Ａ：都市計画区域　　Ｂ：居住誘導区域　　　　Ｃ：都市機能誘導区域<hint hidden='true'>**
←⭕
**</hint>    
オ　Ａ：都市計画区域　　Ｂ：都市機能誘導区域　　Ｃ：居住誘導区域
`
        },
        {
                "mondaiId": 104010250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　小売店舗（一般住居と併用するものは除く）における防火管理に関する記述の正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　店舗に設置されている消火器具や火災報知設備などの機器点検は、6カ月に1回行わなければならない。<hint hidden='true'>**
⭕
**</hint>    

ｂ　店舗に設置されている配線の総合点検は、1年に1回行わなければならない。<hint hidden='true'>**
⭕
**</hint>    

ｃ　店舗は、機器点検・総合点検を行った結果を消防長または消防署長へ1年に1回報告しなければならない。<hint hidden='true'>**
❌
**</hint>  

ｄ　店舗は、特定防火対象物ではない。<hint hidden='true'>**
❌
**</hint>  

〔解答群〕  
ア　ａ：正　ｂ：正　ｃ：正　ｄ：誤<hint hidden='true'>**
←⭕
**</hint>    
イ　ａ：正　ｂ：正　ｃ：誤　ｄ：誤  
ウ　ａ：正　ｂ：誤　ｃ：正　ｄ：正  
エ　ａ：誤　ｂ：正　ｃ：誤　ｄ：誤  
オ　ａ：誤　ｂ：誤　ｃ：正　ｄ：正
`
        },
        {
                "mondaiId": 104010260,
                "name": "第26問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　食品リサイクル法、およびその基本方針（平成27年策定）に関する記述の正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　主務大臣は、再生利用等が基準に照らして著しく不十分であると認めるときは、食品廃棄物等多量発生事業者に対し、勧告、公表、および命令を行うことができる。<hint hidden='true'>**
❌
**</hint>  

ｂ食品リサイクル法の基本方針では、再生利用等を実施すべき量に関する目標を、資本金規模別に定めている。<hint hidden='true'>**
❌
**</hint>  

ｃ食品リサイクル法の基本方針では、食品廃棄物等の再生利用よりも発生抑制を優先的な取り組みとして位置付けている。<hint hidden='true'>**
⭕
**</hint>    

〔解答群〕  
ア　ａ：正　ｂ：誤　ｃ：正  
イ　ａ：正　ｂ：誤　ｃ：誤  
ウ　ａ：誤　ｂ：正　ｃ：正  
エ　ａ：誤　ｂ：正　ｃ：誤  
オ　ａ：誤　ｂ：誤　ｃ：正<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　商店街では、空き店舗の増加および常態化が全国的な課題である。そのため、空き店舗の実態を把握し、有効な空き店舗対策を講じることが期待されている。中小企業庁が調査し、平成29年に公表している『商店街空き店舗実態調査報告書』に関する記述として、最も適切なものはどれか。  

　なお、この報告書では、空き店舗とは、「従前は店舗であったものが、店舗として利用可能な状態でありながら利用の予定がない（所有者の利用の意志がない場合も含む）建物」と定義されている。  

ア　空き店舗が生じた原因では、「商店主の高齢化・後継者の不在」よりも、「大型店の進出、撤退の影響を受けたため」の方が回答の割合が高い。  

イ　空き店舗になってからの経過年数を、「1年未満」、「1年以上～3年未満」、「3年以上～5年未満」、「5年以上」に分類した場合、最も回答の割合が高いものは「5年以上」である。<hint hidden='true'>**
←⭕
**</hint>    

ウ　空き店舗の所有者の把握を、「所有者を把握している（連絡も取れる）」、「所有者を把握している（連絡は取れない）」、「所有者を把握していない」に分類した場合、最も回答の割合が高いものは「所有者を把握していない」である。  

エ　過去3年間に空き店舗が解体・撤去されたもののうち、その後の利用状況として最も回答の割合が高いものは、「新しい店舗」である。
`
        },
        {
                "mondaiId": 104010280,
                "name": "第28問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　店舗Ｘのある月の営業実績は下表のとおりである。この表から計算される相乗積に関する記述として、最も適切なものを下記の解答群から選べ。



<imgTag>104010280_1.svg</imgTag>  

〔解答群〕  
ア　カテゴリーＡ～Ｅの合計の販売金額が2倍になると、各カテゴリーの相乗積の合計も2倍になる。  

イ　カテゴリーＡの相乗積は50%である。  

ウ　カテゴリーＡの販売金額も粗利益率も変わらず、他のカテゴリーの販売金額が増加すると、カテゴリーＡの相乗積は減少する。<hint hidden='true'>**
←⭕
**</hint>    

エ　カテゴリーＢはカテゴリーＣよりも相乗積が大きい。  

オ　相乗積が最も大きいカテゴリーは、カテゴリーＥである。
`
        },
        {
                "mondaiId": 104010290,
                "name": "第29問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
酒類や医薬品などの販売の制度に関する記述として、最も適切なものはどれか。  

ア　健康食品は、顧客に対して薬剤師か登録販売者が対面販売しなければならない。  

イ　酒類売場には、酒類を販売する時間帯に酒類販売管理者が常駐しなければならない。  

ウ　酒類の陳列場所には「20歳以上の年齢であることを確認できない場合には酒類を販売しない」旨を表示しなければならないが、文字のサイズについては特に定めはない。  

エ　酒類販売管理者は同時に複数の酒類販売場の管理者になることができる。  

オ　要指導医薬品に指定されていない一般用医薬品は、所定の条件を満たせばインターネットで販売することができる。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010300,
                "name": "第30問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　商品の仕入方法のうち、委託仕入に関する記述の正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　中小企業と大企業の間で委託仕入を取引条件とした契約を締結することは禁止されている。<hint hidden='true'>**
❌
**</hint>  

ｂ　委託仕入の場合、小売店に納入された時点で当該商品の所有権が小売店に移転する。<hint hidden='true'>**
❌
**</hint>  

ｃ　委託仕入の場合、小売店は粗利益ではなく販売手数料を得ることになる。<hint hidden='true'>**
⭕
**</hint>    

ｄ　委託仕入をした商品の売れ行きが悪い場合、小売店は原則自由に値下げして販売することができる。<hint hidden='true'>**
❌
**</hint>  


〔解答群〕  

ア　ａ：正　ｂ：誤　ｃ：正　ｄ：誤  
イ　ａ：正　ｂ：誤　ｃ：誤　ｄ：正  
ウ　ａ：誤　ｂ：正　ｃ：誤　ｄ：正  
エ　ａ：誤　ｂ：正　ｃ：誤　ｄ：誤  
オ　ａ：誤　ｂ：誤　ｃ：正　ｄ：誤<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010310,
                "name": "第31問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　衣類を販売する売場づくりにおける、ビジュアルマーチャンダイジング（VMD）の考え方に基づくプレゼンテーション方法のうち、フェイスアウトに関する記述として、最も適切なものはどれか。  

ア　商品や演出小物を吊り下げて展示する手法である。  
イ　商品を畳んで棚に置く陳列手法である。  
ウ　商品をピンで壁やパネルに展示する手法である。  
エ　商品をマネキンに着せて展示する手法である。  
オ　ハンガーに掛けて商品の正面を見せる陳列手法である。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010320,
                "name": "第32問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　食品表示法に基づく、食品の栄養成分の量と熱量の表示に関する記述として、最も適切なものはどれか。ただし、本問においては、例外規定は考慮に入れないものとする。  

ア　一般用加工食品に含有量を表示することが義務付けられている栄養成分は、たんぱく質、脂質、炭水化物およびビタミンである。  

イ　業務用食品の場合、容器包装に加え、送り状や納品書にも栄養成分表示をする義務がある。  

ウ　生鮮食品の栄養成分を表示することは、認められていない。  

エ　店頭で表示されるPOPやポスターなど、食品の容器包装以外のものに栄養成  
分表示する場合には、食品表示基準が適用されない。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010330,
                "name": "第33問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　最寄品を主に取り扱う小売店舗における在庫管理に関する記述として、最も適切なものはどれか。  

ア　定期発注方式を採用した場合、安全在庫を計算する際に考慮する需要変動の期間は、調達期間と発注間隔の合計期間である。<hint hidden='true'>**
←⭕
**</hint>    

イ　定期発注方式を採用した場合、需要予測量から有効在庫を差し引いた値が発注量になる。  

ウ　定量発注方式を採用した場合、調達期間が長いほど発注点を低く設定した方が、欠品は発生しにくい。  

エ　定量発注方式を採用した場合の安全在庫は、調達期間が1日であれば、常に0になる。  

オ　発注点を下回ったときの有効在庫と補充点との差の数量を発注するように運用  
すれば、補充直後の在庫量は常に一定になる。
`
        },
        {
                "mondaiId": 104010340,
                "name": "第34問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　輸送手段の特徴に関する記述として、最も適切なものはどれか。  

ア　RORO（roll-onroll-off）船は、貨物を積載したトラックやトレーラーなどの車両をそのまま船内へ積み込んで輸送することが可能である。<hint hidden='true'>**
←⭕
**</hint>    

イ　鉄道輸送は、鉄道コンテナの大きさが平パレットの規格に合っていないので、一貫パレチゼーションを阻害する。  

ウ　トラック輸送から鉄道輸送へのモーダルシフトは、トラックと鉄道の複合一貫輸送を阻害する。  

エ　トラック輸送の長所は、鉄道や船舶での輸送に比べて、輸送トンキロ当たり二酸化炭素排出量が少ないことである。  

オ　路線便は、貨物を積み合わせて一車単位にまとまるときに利用するトラックの輸送サービスであり、発地から着地まで積み替えを行わずに直行輸送する。
`
        },
        {
                "mondaiId": 104010350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　トラックなど自動車による輸送形態に関する記述として、最も適切なものはどれ  
か。  

ア　貨客混載は、バスなど公共交通機関による実施に限られ、トラックによる実施は禁止されている。  

イ　共同輸送は、複数の荷主の商品をトラック1台に混載しているため、複数の荷主にとって納品先が一致している場合に限り行われる。  

ウ　ミルクランは、複数の荷主を回って商品を集めることである。<hint hidden='true'>**
←⭕
**</hint>    

エ　ラウンド輸送は、往路の車両の積載率を高める輸送形態であり、復路は空車になる。`
        },
        {
                "mondaiId": 104010360,
                "name": "第36問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　チェーン小売業の物流センターの機能に関する記述として、最も適切なものはど  
れか。  

ア　通過型物流センター内の作業工程数は、注文商品を事前に納品先別に仕分けした状態で納品するタイプの物流センターの方が、仕分けしていない状態で納品するタイプよりも多い。  

イ　店舗の荷受回数は、物流センターを経由しない場合に仕入先の数だけ荷受が発生したとすると、在庫型物流センターを経由する場合は仕入先の数よりも少ないが、通過型物流センターを経由する場合は仕入先の数と同じである。  

ウ　物流センターから店舗へのカテゴリー納品は、商品を売場に補充する作業の時間を短縮する。<hint hidden='true'>**
←⭕
**</hint>    

エ　物流センターでは、常温で管理できる商品しか取り扱うことができず、低温で管理する必要がある商品は取り扱うことができない。
`
        },
        {
                "mondaiId": 104010370,
                "name": "第37問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　物流センターの運営に関する記述として、最も適切なものはどれか。  

ア　ASNを用いた入荷検品は、商品の外箱に印字されたITFシンボルや、混載の場合に外箱に貼付されたSCMラベルを読み取った情報と、ASNとを照合することで完了する。<hint hidden='true'>**
←⭕
**</hint>    

イ　個装には、内容物を保護したり、複数の商品を1つにまとめて取り扱いやすくしたりする機能がある。  

ウ　固定ロケーション管理は、在庫量が減少しても、保管スペースを有効に活用できるため、保管効率が高い。  

エ　摘み取り方式ピッキングは、商品ごとのオーダー総数をまとめて取り出した後、オーダー別に仕分けることである。  

オ　トラックドライバーが集品先または納品先の荷主の倉庫内でフォークリフトを使用することは、法律で禁止されている。
`
        },
        {
                "mondaiId": 104010380,
                "name": "第38問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　QRコードやバーコードを用いた電子決済（以下「QR決済」という。）に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　商品を購入する際に、購入者のQR決済アプリケーションに表示された専用QRコードを、店舗側が店舗の読み取り機で読み取って、決済することが可能な方法である。  

ｂ　商品を購入する際に、店舗側では読み取り機を利用しなくても、紙に印刷された専用QRコードを購入者のQR決済アプリケーションで読み取って、決済することが可能な方法である。  

ｃ　QR決済アプリケーションが実行できるスマートフォンであっても、日本国内でQR決済を行うには日本製のスマートフォンである必要がある。  

ｄ　QR決済では、決済したら即座に引き落とされるプリペイド方式のものしか存在していない。  

〔解答群〕  
ア　ａとｂ<hint hidden='true'>**
←⭕
**</hint>    
イ　ａとｃ  
ウ　ａとｄ  
エ　ｂとｃ  
オ　ｂとｄ
`
        },
        {
                "mondaiId": 104010390,
                "name": "第39問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　小売業におけるFSP（FrequentShoppersProgram）に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　FSPデータから顧客セグメントを識別する分析方法として、RFM（Recency，requency，Monetary）分析がある。  

ｂ　FSPデータから優良顧客層を発見する分析方法として、マーケットバスケット分析が最適である。  

ｃ　FSPは、短期的な売上の増加を目指すより、長期的な視点での顧客のロイヤルティを高めることを目指す手段である。  

ｄ　FSPは、特売期間を限定せず、全ての顧客に各商品を年間通じて同じ低価格で販売する手段である。  

〔解答群〕  
ア　ａとｂ  
イ　ａとｃ<hint hidden='true'>**
←⭕
**</hint>    
ウ　ａとｄ  
エ　ｂとｃ  
オ　ｂとｄ
`
        },
        {
                "mondaiId": 104010400,
                "name": "第40問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　店舗間でPOSデータを比較分析する際の基本的な指標に、PI（Purchase Incidence）値がある。このPI値に関する以下の記述の空欄に入る語句として、最も適切なものを下記の解答群から選べ。  

PI値とは、販売点数または販売金額を（　　　）で除して調整した数値である。  

〔解答群〕  
ア　顧客カード発行枚数  
イ　在庫点数または在庫金額  
ウ　商圏内人口  
エ　優良顧客数  
オ　レシート枚数またはレジ通過人数<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010410,
                "name": "第41問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　GS1標準の商品識別コードの総称であるGTINに関する記述として、最も適切なものはどれか。  

ア　GTINには7つの種類が存在している。  

イ　GTIN-8は、表示スペースが限られている小さな商品にJANシンボルを表示するための商品識別コードである。<hint hidden='true'>**
←⭕
**</hint>    

ウ　GTIN-12は、中国・韓国で利用されている12桁の商品識別コードである。  

エ　GTIN-14は、インジケータ、GS1事業者コード、商品アイテムコード、単価コードの4つの要素で構成されている。
`
        },
        {
                "mondaiId": 104010420,
                "name": "第42問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　資金決済に関する法律（資金決済法）は、近年のICTの発達や利用者ニーズの多様化などの資金決済システムをめぐる環境の変化に対応するため、前払式支払手段、資金移動、資金清算、仮想通貨などについて規定している。  

　以下のａ～ｄの前払式支払手段のうち、資金決済法の適用が<u>除外されるもの、または該当しないもの</u>の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　全国百貨店共通商品券  
ｂ　収入印紙  
ｃ　全国共通おこめ券  
ｄ　美術館の入場券  

〔解答群〕  
アａとｂ  
イａとｃ  
ウａとｄ  
エｂとｃ  
オｂとｄ<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 104010430,
                "name": "第43問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 104,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下のようなID-POSデータがあるとき、データ項目中の顧客ID、年、性別、購買金額の項目の変数を「名義尺度」「順序尺度」「間隔尺度」「比例尺度」の4つの尺度水準のいずれかに分類したい。  

このとき、その組み合わせとして、最も適切なものを下記の解答群から選べ。  

【ID-POSデータ】  
<imgTag>104010430_1.svg</imgTag>  
（注）ただし年は西暦、時刻の1410は14時10分を表しているとする。  

〔解答群〕  
ア　顧客ID－名義尺度　年－間隔尺度　性別－名義尺度　購買金額－比例尺度<hint hidden='true'>**
←⭕
**</hint>    
イ　顧客ID－名義尺度　年－間隔尺度　性別－順序尺度　購買金額－比例尺度  
ウ　顧客ID－名義尺度　年－比例尺度　性別－名義尺度　購買金額－順序尺度  
エ　顧客ID－間隔尺度　年－比例尺度　性別－名義尺度　購買金額－順序尺度  
オ　顧客ID－間隔尺度　年－比例尺度　性別－順序尺度　購買金額－比例尺度  
`
        },
        {
                "mondaiId": 105010010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
合同会社、合名会社、合資会社の比較に関する記述として、最も適切なものはどれか。  

  
ア　合同会社、合名会社、合資会社のいずれの会社も、<hintTarget>会社成立後に新たに社員を加入</hintTarget>させることができる。  

<hint hidden='true'>**
⭕（定款を変更すれば）可能です。
**</hint>  

イ　合同会社、合名会社、合資会社のいずれの会社も、社員は<hintTarget>2名以上</hintTarget>でなければならない。  

<hint hidden='true'>**
❌合同会社、合名会社は<hintTarget>１名</hintTarget>いればよい。
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
持分会社（合同会社、合名会社、合資会社）と株式会社の比較表

<TABLE align="left" width="100%">
<TR>
<TD></TD>
<TD align='center' style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;' colspan="3">
持分会社
</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-top: 1px solid gray;border-left: 1px solid gray;'>
株式会社</TD>
</TR>

<TR>
<TD>
　</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合同会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合名会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合資会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>
</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者
</TD>
<TD align='center' colspan='3' style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >社員</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;'>株主</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
必要な出資者（社員または株主）の人数
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >１人以上</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上 </hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >２人以上
（有限責任社員と無限責任社員が各１名以上）</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >１人以上</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者の責任
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >間接有限責任</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >無限責任（全ての責任）</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>有限責任社員と無限責任社員がいる</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >間接有限責任（出資した範囲の責任）</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>

ウ　合同会社、合名会社、合資会社のいずれの会社も、定款の定めによっても、<hintTarget>一部の社員</hintTarget>のみを業務執行社員とすることはできない。  

<hint hidden='true'>**
❌業務執行社員については、いずれの持分会社も、原則は全員が業務執行役員であるが、定款の定めにより一部の社員を業務執行社員にできます。
**</hint>

エ　合同会社と合名会社の社員は<hintTarget>無限責任社員のみ</hintTarget>で構成されるが、合資会社の社員は無限責任社員と有限責任社員により構成される。

<hint hidden='true'>**
合同会社は、<hintTarget>全て</hintTarget>の社員が有限責任社員です。**</hint>

  `
        },
        {
                "mondaiId": 105010020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　会社法が定める株式会社の事業譲渡に関する記述として、最も適切なものはどれか。なお、反対株主の買取請求権に関する<hintTarget>会社法第469条第1項第1号</hintTarget>及び<hintTarget>第2号</hintTarget>については考慮しないものとする。  

<hint hidden='true'>**
事業の全部譲渡と同時に解散決議を行ったケース。
**</hint>  
<hint hidden='true'>**
譲渡により譲り渡す財産の譲渡価額が、譲渡会社の総資産額の1/5以上の、いわゆる簡易事業譲渡のケース。
**</hint>

ア　事業譲渡の対価は、金銭でなければならず、<hintTarget>譲受会社の株式を用いることはできない。</hintTarget>  

<hint hidden='true'>**
❌不適切です。譲渡会社の株式でも可能です。
**</hint>

イ　事業譲渡をする会社の株主が、事業譲渡に反対する場合、その反対株主には株式買取請求権が認められている。  

<hint hidden='true'>**
⭕適切です。ただし冒頭の文章で「考慮しない」とされた２つのケースでは認められません。
**</hint>

ウ　事業の全部を譲渡する場合には、譲渡会社の株主総会の特別決議によって承認を受ける必要があるが、<hintTarget>事業の一部</hintTarget>を譲渡する場合には、譲渡会社の株主総会の特別決議による承認が必要となることはない。  

<hint hidden='true'>**
❌不適切です。略式手続で譲受会社が特別支配会社に該当する場合は、必要です。また、冒頭の文章で「考慮しない」とされたももののうち、簡易事業譲渡のケースでも必要です。
**</hint>

エ　当該事業を構成する債務や契約上の地位を譲受人に移転する場合、<hintTarget>個別にその債権者や契約相手方の同意</hintTarget>を得る必要はない。

<hint hidden='true'>**
❌不適切です。債権者の利害に重大な影響を及ぼす場合があるため、同意が必要です。
**</hint>

`
        },
        {
                "mondaiId": 105010030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　会社法が定める会社の清算・特別清算に関する記述として、最も適切なものはどれか。  

ア　株主は、清算中の会社の残余財産が金銭以外の財産であるときは、当該会社に対し、当該残余財産に代えて金銭を交付することを請求することができる。  

<hint hidden='true'>**
⭕適切です。
** </hint>

イ　清算中の会社の機関設計は、清算開始前の機関設計が<hintTarget>維持される</hintTarget>ため、指名委員会等設置会社が清算手続に入った場合、指名委員会等の各委員会が設置される。  

<hint hidden='true'>**
❌維持されない場合があります。例えば、監査等委員会設置会社では、監査等委員が監査役に変更されます。
** </hint>

ウ　清算中の会社は裁判所の監督に属するため、清算人は、裁判所による<hintTarget>提出命令がなくても</hintTarget>、株主総会で承認を得た財産目録等を裁判所に提出しなければならない。  

<hint hidden='true'>**
❌会社法が適用される（平成18年5月1日以降に解散）場合は、会社法に提出の規定はありません。
** </hint>

エ　特別清算は、<hintTarget>株式会社だけではなく</hintTarget>、合同会社にも適用される。  

<hint hidden='true'>**
❌株式会社だけです。特別清算は、株式会社の解散の場合に、<hintTarget>債務超過や精算手続きの遂行に支障をきたす</hintTarget>恐れがある場合にのみ適用されます。
** </hint>

`
        },
        {
                "mondaiId": 105010040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ａは、Ｘ株式会社の代表取締役であったが、昨年（2018年）12月30日に死亡した。  

　Ａには配偶者Ｂ、嫡出子Ｃ、Ｄ、Ｅがいる（下図参照）。  

<imgTag>105010040_1.png</imgTag>  
<hint hidden='true'>**
法定相続分は図中赤字のとおりです。  
このケースでは、遺産の額をそのまま法定相続分に適用する前に、
特別受益、寄与分からみなし相続財産を算定します。  
なお、寄与分とは、被相続人の生前に介護などの貢献をしてきた相続人に、貢献に応じた相続金額を上乗せする制度です。
** </hint>

Ａの遺産の額は<hintTarget>1億4，000万円</hintTarget>であり、配偶者Ｂには特別受益として<hintTarget>400万円</hintTarget>の生前贈与、子Ｅには特別受益として<hintTarget>200万円</hintTarget>の生前贈与があり、子Ｄには<hintTarget>寄与分が500万円</hintTarget>あった。この場合の相続分（取得額）に関する記述として、最も適切なものはどれか。  

<hint hidden='true'>**
みなし相続財産＝1億4000万円＋400万円＋200万円－500万円＝1億4100万円  
みなし相続財産に法定相続分を適用し、寄与分と特別受益で増減し、相続金額を算出します。  
B　1億4100万円×1/2　－　400万円　＝　6650万円  
C　1億4100万円×1/6　＝　2350万円  
D　1億4100万円×1/6　＋　500万円　＝　2850万円  
E　1億4100万円×1/6　－　200万円　＝　2150万円
** </hint>

　なお、相続人の中で、相続欠格者、相続廃除者、相続放棄者はおらず、また、遺産分割協議は成立していない。 

ア　Ｂの相続分（取得額）は6，650万円となる。<hint hidden='true'>**←⭕適切です。
** </hint>    

イ　Ｃの相続分（取得額）は3，500万円となる。  

ウ　Ｄの相続分（取得額）は2，350万円となる。  

エ　Ｅの相続分（取得額）は2，550万円となる。

`
        },
        {
                "mondaiId": 105010050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　株式と社債の比較に関する記述として、最も適切なものはどれか。  

ア　株式：会社が解散して清算する場合、株主は、通常の債権者、社債権者等の債権者に劣後し、これら債権者の債務を弁済した後に残余財産があれば、  
その分配を受ける。  
　社債：会社が解散して清算する場合、社債権者は、通常の債権者に<hintTarget>常に優先し</hintTarget>、これら債権者の債務の弁済前に、弁済を受けることができる。  

<hint hidden='true'>**
❌基本的にほかの一般債権者と同様の地位です。
** </hint>

イ　株式：株券を発行する旨の定款の定めのある公開会社は、当該株式に係る株券を発行しなければならない。  
　社債：募集事項として社債券を発行する旨を定めている場合、会社は当該社債に係る社債券を発行しなければならない。  

<hint hidden='true'>**
⭕適切です。
** </hint>

ウ　株式：株式の対価として払込み又は給付された財産は、<hintTarget>全て資本金</hintTarget>の額に組み入れられる。  

<hint hidden='true'>**
❌<hintTarget>1/2</hintTarget>までの金額を上限に、資本金でなく<hintTarget>資本準備金</hintTarget>に組み入れることも可能です。
** </hint>

　社債：社債の対価として払い込まれた金銭は、全て<hintTarget>資本金</hintTarget>の額に組み入れられる。  

<hint hidden='true'>**
❌<hintTarget>負債</hintTarget>の額に組み入れられます。
** </hint>

エ　株式：株式引受人の募集は、有利発行ではない場合であっても、<hintTarget>公開会社</hintTarget>・非公開会社を問わず、<hintTarget>株主総会</hintTarget>の決議事項である。  

<hint hidden='true'>**
❌公開会社が募集株式について（有利発行ではない）通常の発行を行う場合は、<hintTarget>取締役会</hintTarget>の決議事項になります。
** </hint>

　社債：社債の引受人の募集は、公開会社・非公開会社を問わず、株主総会の決議事項ではない。


`
        },
        {
                "mondaiId": 105010061,
                "name": "第6問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ｘ株式会社（以下「Ｘ社」という。）は、取締役会及び監査役会を設置している会社（<hintTarget>公開会社ではなく</hintTarget>、かつ大会社ではない）である。  
　中小企業診断士であるあなたは、2019年1月に、今年（2019年）の株主総会のスケジュール等について、Ｘ社の株主総会担当者の甲氏から相談を受けた。以下の会話は、その相談の際のものである。この会話を読んで、下記の設問に答えよ。  

甲氏：「当社の事業年度は、4月1日から翌年3月31日までです。2019年は6月  
27日（木）に株主総会を開催したいと考えています。株主総会の招集通知は  
いつまでに発送すればよいですか。」  

あなた：「御社では、株主総会に出席しない株主に、書面による議決権の行使や、<hintTarget>電磁的方法</hintTarget>による議決権の行使を認める制度を設けていますか。」  

甲氏：「いいえ。<hintTarget>設けていません</hintTarget>。」  

あなた：「そうすると、御社は、<hintTarget>取締役会を設置している</hintTarget>会社ですが、公開会社ではありませんし、また、書面による議決権の行使や、電磁的方法による議決権の行使を認める制度を設けていないので、（　Ａ　）までに招集通知を発送する必要があります。」  

甲氏：「分かりました。ところで、今回の株主総会でも、昨年と同様、3年前まで当社の取締役であった乙氏が、「自分を取締役に選任しろ」という議案を株主提案として提出してくると聞いています。どのような点に注意した方  
がよいでしょうか。」  

あなた：「御社では、定款で株主提案に関する何らかの規定は設けていますか。」  

甲氏：「いいえ。定款では特に規定は設けていません。」  

あなた：「（　Ｂ　）」  

（設問1）  
会話の中の空欄Ａに入る記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
株主総会の招集手続きと、このケースの条件（赤字）は下記になります。  

💡【参考】株主総会の招集手続きのまとめ  
<imgTag>105010061_1.png</imgTag>  
</div>
</hint>

ア　株主総会の日の1週間前<hint hidden='true'>**
←⭕適切です。  
**</hint>  
イ　株主総会の日の2週間前  
ウ　原則として株主総会の日の1週間前ですが、定款で1週間を下回る期間を定めた場合にはその期間の前  
エ　原則として株主総会の日の2週間前ですが、定款で2週間を下回る期間を定めた場合にはその期間の前`
        },
        {
                "mondaiId": 105010062,
                "name": "第6問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ｘ株式会社（以下「Ｘ社」という。）は、取締役会及び監査役会を設置している会社（公開会社ではなく、かつ大会社ではない）である。  
　中小企業診断士であるあなたは、2019年1月に、今年（2019年）の株主総会のスケジュール等について、Ｘ社の株主総会担当者の甲氏から相談を受けた。以下の会話は、その相談の際のものである。この会話を��んで、下記の設問に答えよ。  

甲氏：「当社の事業年度は、4月1日から翌年3月31日までです。2019年は6月  
27日（木）に株主総会を開催したいと考えています。株主総会の招集通知は  
いつまでに発送すればよいですか。」  

あなた：「御社では、株主総会に出席しない株主に、書面による議決権の行使や、電磁的方法による議決権の行使を認める制度を設けていますか。」  

甲氏：「いいえ。設けていません。」  

あなた：「そうすると、御社は、<hintTarget>取締役会を設置</hintTarget>している会社ですが、<hintTarget>公開会社ではありません</hintTarget>し、また、書面による議決権の行使や、電磁的方法による議決権の行使を認める制度を設けていないので、（　Ａ　）までに招集通知を発送する必要があります。」  

甲氏：「分かりました。ところで、今回の株主総会でも、昨年と同様、3年前まで当社の取締役であった乙氏が、「自分を取締役に選任しろ」という議案を株主提案として提出してくると聞いています。どのような点に注意した方  
がよいでしょうか。」  

あなた：「御社では、定款で株主提案に関する何らかの規定は設けていますか。」  

甲氏：「いいえ。定款では特に規定は設けていません。」  

あなた：「（　Ｂ　）」  
                
（設問2）  
　会話の中の空欄Ｂに入る記述として、最も適切なものはどれか。  

ア　御社の場合、株主が、株主提案について、議案の要領を株主に通知することを求めるには、株主総会の日の<hintTarget>6週間前</hintTarget>までに請求することが必要です。このため、乙氏が株主提案をしてきた場合は、この要件を満たしているのかを確認してください。  

<hint hidden='true'>**
❌<hintTarget>８</hintTarget>週間前までです。
** </hint>

イ　御社の場合、株主が、株主提案について、議案の要領を株主に通知することを求めるには、総株主の議決権の<hintTarget>100分の3</hintTarget>以上の議決権又は300個以上の議決権を、<hintTarget>6か月前から</hintTarget>引き続き有していることが要件となります。このため、乙氏が株主提案をしてきた場合は、この要件を満たしているかを確認してください。  

<hint hidden='true'>**
❌取締役設置会社なので、100分の1以上が条件です。また、公開会社でないので、6ヶ月保有要件は不要です。
** </hint>

ウ　株主の提案する議案が、実質的に同一の議案につき株主総会において総株主の議決権の10分の1以上の賛成を得られなかった日から3年を経過していな  
い場合は、会社は、その株主提案を拒絶することができます。乙氏は、昨年の株主総会でも同様の株主提案をしてきたとのことですので、乙氏が株主提案をしてきた場合は、まず昨年の賛否の状況を確認してください。  

<hint hidden='true'>**
⭕適切です。
** </hint>

エ　株主の提案する議案が、<hintTarget>法令や定款に違反する</hintTarget>議案の場合であっても、株主提案は、株主の基本的な権利ですので、議案の要領を株主に通知する必要があります。このため、乙氏が株主提案をしてきた場合は、その提案が法令に違反するものであっても、必ず、議案の要領を株主に通知してください。

<hint hidden='true'>**
❌法令や定款に違反する場合は、拒絶することができます。
** </hint>


`
        },
        {
                "mondaiId": 105010070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下請代金支払遅延等防止法（以下「下請法」という。）に関する記述として、<u><u>最も不適切なものはどれか</u></u>。なお、親事業者、下請事業者の範囲を定める取引当事者の資本金の要件は考慮しないものとする。  

ア　<hintTarget>自社の</hintTarget>社内研修をコンサルティング会社に委託することは、下請法の対象となる<hintTarget>役務提供委託</hintTarget>に該当する。  

<hint hidden='true'>**
❌役務提供委託は、  
　顧客→自社→下請事業者  
といった、顧客から自社が請け負った役務を、下請事業者に<hintTarget>再委託</hintTarget>する場合が該当します。このケースは  
　自社→下請事業者  
であり、該当しません。
**</hint>  

イ　製造業者が、自社の工場で使用している工具の修理を自社で行っている場合に、その修理の一部を修理業者に委託することは、下請法の対象となる修理委託に該当する。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　大規模小売業者が、自社のプライベート・ブランド商品の製造を食品加工業者に委託することは、下請法の対象となる製造委託に該当する。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ　放送事業者が、放送するテレビ番組の制作を番組制作業者に委託することは下請法の対象となる情報成果物の作成委託に該当する。

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

`
        },
        {
                "mondaiId": 105010080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下表は、金融商品取引法に定める縦覧書類の公衆縦覧期間をまとめたものである。空欄Ａ～Ｃに入る数値の組み合わせとして、最も適切なものを下記の解答群から選べ。  


<TABLE border='1' align="left" width="100%">
<TR>
<TD>縦覧書類の名称</TD>
<TD>公衆縦覧期間</TD>
</TR>
<TR>
<TD>有価証券報告書</TD>
<TD>受理した日から（　Ａ　<hint hidden='true'>**
５
**</hint> ）年を経過する日まで</TD>
</TR>
<TR>
<TD>半期報告書</TD>
<TD>受理した日から（　Ｂ　<hint hidden='true'>**
３
**</hint> ）年を経過する日まで</TD>
</TR>
<TR>
<TD>内部統制報告書</TD>
<TD>受理した日から（　Ｃ　<hint hidden='true'>**
５
**</hint> ）年を経過する日まで</TD>
</TR>
</TABLE>


〔解答群〕  
ア　Ａ：5　Ｂ：3　Ｃ：3  
イ　Ａ：5　Ｂ：3　Ｃ：5<hint hidden='true'>**
←⭕
**</hint>    
ウ　Ａ：7　Ｂ：3　Ｃ：3  
エ　Ａ：7　Ｂ：5　Ｃ：5
`
        },
        {
                "mondaiId": 105010091,
                "name": "第9問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下の会話は、中小企業診断士であるあなたと、Ｘ株式会社の代表取締役a氏との間で行われたものである。この会話を読んで、下記の設問に答えよ。  

α氏：  
「今度、人気マンガ家のＹさんに、当社の企業キャラクターを創ってもらうことになりました。将来的には着ぐるみやアニメを作って活用する予定です。Ｙさんからその著作権の譲渡を受けるために、次の契約書を作ってみたのですがどうでしょうか。」  

＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊  

Ｙ（以下「甲」という。）とＸ株式会社（以下「乙」という。）とは、キャラクターの絵柄作成業務の委託に関し、以下のとおり契約を締結する。  

第1条（委託）  
　乙は、甲に対し、以下をテーマとするキャラクターの絵柄（以下「本著作物」という。）の作成を委託し、甲はこれを受託した。  
　テーマ：乙が広告に使用するマスコットキャラクター  
第2条（納入）  
(1)　甲は乙に対し、本著作物をJPEGデータの形式により、2019年10月末日までに納入する。  
(2)　乙は、前項の納入を受けた後速やかに納入物を検査し、納入物が契約内容に適合しない場合や乙の企画意図に合致しない場合はその旨甲に通知し、当該通知を受けた甲は速やかに乙の指示に従った対応をする。  
<u>第3条（著作権の帰属）</u>  
　<u>本著作物の著作権は、対価の完済により乙に移転する。</u>  
<u>第4条（著作者人格権の帰属）</u>  
　<u>本著作物の著作者人格権は、対価の完済により乙に移転する。</u>  
第5条（保証）  
　甲は、乙に対し、本著作物が第三者の著作権を侵害しないものであることを保証する。  
第6条（対価）  
　乙は甲に対し、本著作物の著作権譲渡の対価、その他本契約に基づく一切の対価として、金1，500，000円（消費税別途）を、2019年11月末日までに支払う。  
　本契約締結の証として、本契約書2通を作成し、甲乙記名押印の上、各自1通を保持する。  

2019年　　月　　日  
甲　Ｙ　　　　　　　　　　　　　印  
乙　Ｘ株式会社代表取締役　　α　印  

＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊  

あなた：  
「そうですね。まず第3条については（　Ａ　<hint hidden='true'>**
著作権法第27条と第28条の権利は特掲しないと、これらの権利は譲渡した者に留保されたと推定されますから
**</hint>  ）、検討が必要です。また、第4条については（　Ｂ　）。詳細は弁護士に確認した方がよいと思いますので、もしよろしければ、著作権に詳しい弁護士を紹介しますよ。」  

α氏：  
「著作権の契約はなかなか難しいですね。よろしくお願いします。」  


（設問1）  
　会話の中の空欄Ａに入る記述として、最も適切なものはどれか。  
　なお、著作権法の第21条、第27条及び第28条において規定される権利は次のとおりである。  
　第21条：複製権  
　第27条：<hintTarget>翻訳、翻案</hintTarget>等する権利  
　第28条：<hintTarget>二次的著作物</hintTarget>の利用に関する原著作者の権利  

ア　著作権は著作者の一身に専属し、譲渡することが<hintTarget>できません</hintTarget>から  

<hint hidden='true'>**
❌財産権なので、全部または一部を譲渡できます。
**</hint>

イ　著作権法第21条から第28条の権利は、そもそも対価を支払った者に<hintTarget>自動的に移転</hintTarget>しますから  

<hint hidden='true'>**
❌問題文の第21,27,28条のうち、<hintTarget>翻訳・翻案（第27条）、二次的著作物（第28条）</hintTarget>に関する権利は自動的に移転しません。契約に明記して当事者間の合意が必要です。
**</hint>

ウ　著作権法<hintTarget>第21条</hintTarget>から第28条の全ての権利を特掲しないと、特掲されなかった権利は譲渡した者に留保されたと推定されますから  

<hint hidden='true'>**
❌「特掲しないと、特掲されなかった権利は譲渡した者に留保されたと推定され」るのは、第27条、28条です。複製権（第21条）についての記載が不適切です。
**</hint>

エ　著作権法第27条と第28条の権利は特掲しないと、これらの権利は譲渡した者に留保されたと推定されますから

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

`
        },
        {
                "mondaiId": 105010092,
                "name": "第9問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下の会話は、中小企業診断士であるあなたと、Ｘ株式会社の代表取締役a氏との間で行われたものである。この会話を読んで、下記の設問に答えよ。  

α氏：  
「今度、人気マンガ家のＹさんに、当社の企業キャラクターを創ってもらうことになりました。将来的には着ぐるみやアニメを作って活用する予定です。Ｙさんからその著作権の譲渡を受けるために、次の契約書を作ってみたのですがどうでしょうか。」  

＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊  

Ｙ（以下「甲」という。）とＸ株式会社（以下「乙」という。）とは、キャラクターの絵柄作成業務の委託に関し、以下のとおり契約を締結する。  

第1条（委託）  
　乙は、甲に対し、以下をテーマとするキャラクターの絵柄（以下「本著作物」という。）の作成を委託し、甲はこれを受託した。  
　テーマ：乙が広告に使用するマスコットキャラクター  
第2条（納入）  
(1)　甲は乙に対し、本著作物をJPEGデータの形式により、2019年10月末日までに納入する。  
(2)　乙は、前項の納入を受けた後速やかに納入物を検査し、納入物が契約内容に適合しない場合や乙の企画意図に合致しない場合はその旨甲に通知し、当該通知を受けた甲は速やかに乙の指示に従った対応をする。  
<u>第3条（著作権の帰属）</u>  
　<u>本著作物の著作権は、対価の完済により乙に移転する。</u>  
<u>第4条（著作者人格権の帰属）</u>  
　<u>本著作物の著作者人格権は、対価の完済により乙に移転する。</u>  
第5条（保証）  
　甲は、乙に対し、本著作物が第三者の著作権を侵害しないものであることを保証する。  
第6条（対価）  
　乙は甲に対し、本著作物の著作権譲渡の対価、その他本契約に基づく一切の対価として、金1，500，000円（消費税別途）を、2019年11月末日までに支払う。  
　本契約締結の証として、本契約書2通を作成し、甲乙記名押印の上、各自1通を保持する。  

2019年　　月　　日  
甲　Ｙ　　　　　　　　　　　　　印  
乙　Ｘ株式会社代表取締役　　α　印  

＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊  

あなた：  
「そうですね。まず第3条については（　Ａ　）、検討が必要です。また、第4条については（　Ｂ　<hint hidden='true'>**
著作者人格権は移転できませんし、特約があっても移転についてはオーバーライドできない強行規定ですから、修正が必要です  
**</hint>  
）。詳細は弁護士に確認した方がよいと思いますので、もしよろしければ、著作権に詳しい弁護士を紹介しますよ。」  

α氏：  
「著作権の契約はなかなか難しいですね。よろしくお願いします。」  


（設問2）  
会話の中の空欄Ｂに入る記述として、最も適切なものはどれか。  

ア　著作者人格権は移転できますが、<hintTarget>職務著作</hintTarget>の場合に限られますから修正が必要です  

<hint hidden='true'>**
❌職務著作の場合は、法人等に著作者人格権が帰属しますが、選択肢イの解説の通り、移転できません。
**</hint>

イ　著作者人格権は移転<hintTarget>できます</hintTarget>が、著作者が<hintTarget>法人</hintTarget>である場合に限られますから修正が必要です  

<hint hidden='true'>**
❌自然人の場合と同様に移転できません。
**</hint>

ウ　著作者人格権は移転できませんが、<hintTarget>特約</hintTarget>があれば移転についてはオーバーライドすることができる任意規定ですから、このままでよいでしょう  

<hint hidden='true'>**
❌特約があっても移転できません。
**</hint>

エ　著作者人格権は移転できませんし、特約があっても移転についてはオーバーライドできない強行規定ですから、修正が必要です

<hint hidden='true'>**
⭕適切な記述です。著作人者格権とは下記３つです。  
（１）公表権：　無断で<hintTarget>著作物を公表されない</hintTarget>権利  
（２）氏名保持権：　<hintTarget>著作者名の表示を決定</hintTarget>する権利  
（３）<hintTarget>同一性</hintTarget>保持権：　無断で<hintTarget>改変</hintTarget>されない権利  
例えば、著作人格権を行使しない旨、契約書の修正を行うことが対策になります。
**</hint>  

`
        },
        {
                "mondaiId": 105010100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　物の形状を保護する意匠法、商標法、不正競争防止法に関する記述として、最も適切なものはどれか。  

ア　自動二輪車の形状が意匠登録された場合、その意匠権は同じ形状のチョコレートにも<hintTarget>及ぶ</hintTarget>
。  

<hint hidden='true'>**
❌意匠登録は、「類似物品の類似意匠」までが対象です。例えば類似意匠の子供用自転車などであれば該当する可能性がありますが、チョコレートは類似物品に該当しません。  
では、  
「意匠権で保護されないのであれば、例えば、名車と呼ばれるような🛵著名な自動二輪車のチョコレートやキャラクターグッズを、他社が勝手に販売できるか？」  
というと、<hintTarget>選択肢イの不正競争防止法やエの立体商標など</hintTarget>で保護します。
**</hint>

イ　自動二輪車の形状が不正競争防止法第2条第1項第1号の商品等表示混同惹起行為として保護されるには、それが当該メーカーの商品等表示として需要者の間に広く認識されている必要がある。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　自動二輪車の形状について意匠登録出願をした場合、所定期間内であれば立体商標の商標登録出願に<hintTarget>出願変更</hintTarget>することができる。  

<hint hidden='true'>**
❌出願変更できません。
**</hint>

エ　自動二輪車の形状は商品そのものの形状なので、<hintTarget>立体商標</hintTarget>として登録されることはない。

<hint hidden='true'>**
❌登録されます。ホンダスーパーカブが立体商標を登録しています。
<img src="https://www.honda.co.jp/content/site/www/news/pc/2020/2200417-supercub/_jcr_content/par_news-body/newscolumn_1863026810/par_news-col-1/newsimage.img.jpg/1586493985126.jpg" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;">  
他にはヤクルトの容器などがあります。
<img src="https://www.yakult.co.jp/products/images/item_7.jpg" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;">
**</hint>

`
        },
        {
                "mondaiId": 105010110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　著作権の保護期間に関する記述として、最も適切なものはどれか。なお、各記述の自然人の死亡年は、それぞれの著作物の公表年より遅いものとする。  

ア　2000年8月4日に公表された、映画の著作権の存続期間は、<hintTarget>2090年</hintTarget>12月31日までである。  

<hint hidden='true'>**
❌<hintTarget>2070</hintTarget>年12月31日までです。
**</hint>

イ　2000年8月4日に公表された、株式会社の従業員が<hintTarget>職務著作</hintTarget>として制作した同社マスコットキャラクターの著作権の存続期間は、<hintTarget>2070年</hintTarget>12月31日までである。  

<hint hidden='true'>**
⭕適切な記述です。職務著作の場合、<hintTarget>公表</hintTarget>から70年後の12月31日までです。
**</hint>  

ウ　2000年8月4日に<hintTarget>公表</hintTarget>された、写真家（自然人）に帰属する<hintTarget>写真</hintTarget>の著作権の存続期間は、<hintTarget>2050年</hintTarget>12月31日までである。  

<hint hidden='true'>**
❌自然人による写真の場合、著作者の<hintTarget>死後（公表後ではない）</hintTarget>70年であるため、2050年ではありません。
**</hint>

エ　2000年8月4日に<hintTarget>公表</hintTarget>された、マンガ家（<hintTarget>自然人</hintTarget>）のアシスタントが<hintTarget>職務著作</hintTarget>として描いた<hintTarget>絵</hintTarget>の著作権の存続期間は、2070年12月31日までである。

<hint hidden='true'>**
❌職務著作の著作権は、使用者である漫画家にあります。また、期間は公表から70年ではなく、著作者の死後から70年です。
**</hint>

`
        },
        {
                "mondaiId": 105010120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下の会話は、中小企業診断士であるあなたと、玩具メーカーのＸ株式会社の代表取締役甲氏との間で本年8月に行われたものである。会話の中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

あなた：「先月の業界誌で、御社の新製品が好評との記事を読みました。」  

甲氏：「はい、6月に大規模展示施設の展示会で発表したのですが、おかげさまで、クリスマス商戦に向けて引き合いがたくさん来ています。」  

あなた：「この製品、外観がとてもユニークですが、意匠登録出願はされましたか。」  

甲氏：「実をいうと、こんなに売れるとは思っていなかったので、意匠登録出願に費用をかけなかったんです。こんなに好評なら、模倣品対策のため、発表前に出願しておけばよかったです。」  

あなた：「（　Ａ　<hint hidden='true'>**
新規性喪失の例外
**</hint>  ）の規定を用いれば、意匠登録出願することができる場合がありますよ。」  

甲氏：「本当ですか。どのくらいの期間認められているのでしょう。」  

あなた：「今回の場合は、展示会に出品した日が起算日になると思いますが、その  
日から（　Ｂ　<hint hidden='true'>**
1年
**</hint>  ）間です。」  

甲氏：「よかった、まだ間に合いそうです。急いで特許事務所に相談してみます。」  


〔解答群〕  
ア　Ａ：国内優先権  Ｂ：6か月  
イ　Ａ：国内優先権  Ｂ：1年  
ウ　Ａ：新規性喪失の例外  Ｂ：6か月  
エ　Ａ：新規性喪失の例外  Ｂ：1年<hint hidden='true'>**
←⭕
**</hint>  

`
        },
        {
                "mondaiId": 105010130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　特許権に関する記述として、最も適切なものはどれか。  

ア　他人の特許権又は専用実施権を侵害しても、その侵害の行為について<hintTarget>過失</hintTarget>があったものと推定されない。  

<hint hidden='true'>**
❌過失があったと推定されます。
**</hint>

イ　特許権が共有に係るときは、各共有者は、契約で別段の定めをした場合を除き、他の共有者の<hintTarget>同意</hintTarget>を得なければ、特許発明の<hintTarget>実施</hintTarget>をすることができない。  

<hint hidden='true'>**
❌実施をすることは可能です。同意を要するのは、下記です。  
・第三者への特許権の譲渡・質権の設定
・専用実施権の設定
・第3者への通常実施権の許諾
**</hint>

ウ　特許権について専用実施権を設定した場合には、特許権者は専用実施権者が専有する範囲について業として特許発明の実施をすることができない。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ　特許権の存続期間は、<hintTarget>登録</hintTarget>の日から20年をもって終了する。

<hint hidden='true'>**
❌出願の日から20年です。
**</hint>

`
        },
        {
                "mondaiId": 105010140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下の会話は、中小企業診断士であるあなたと、県内で複数の和菓子店を展開する甲株式会社の代表取締役Ａ氏との間で行われたものである。会話の中の空欄に入る記述として、最も適切なものを下記の解答群から選べ。  

Ａ氏：「おととい、東京にある乙株式会社から警告書が送られてきて驚いていま  
す。」  

あなた：「どのような内容ですか。」  

Ａ氏：「うちで販売するどら焼きの名前が、昨年、乙株式会社が「菓子・パン」について登録した商標と類似するそうで、直ちに販売を中止しなさい、という内容です。どうしたらいいでしょう。」  

あなた：「確か、御社のどら焼きは昭和の時代から販売している名物商品ですよね。それであれば、先使用権を主張できるかもしれませんよ。」  

Ａ氏：「その先使用権とはどういうものですか。」  

あなた：「不正競争の目的でなく、（　　　）、継続してその商標の使用をする権利を有する、という商標法上の規定です。」  

Ａ氏：「ということは、うちのどら焼きの販売を中止する必要はないのですね。」  

あなた：「そうです。知的財産権に詳しい弁護士さんを紹介しますので、相談されてはいかがですか。」  

Ａ氏：「よろしくお願いします。」  

〔解答群〕  
ア　乙株式会社の商標登録出願前から、御社がどら焼きについて御社商標を使用し、または使用する準備をしているときは  

イ　乙株式会社の商標登録出願前から、御社がどら焼きについて御社商標を使用していた結果、乙株式会社の商標登録出願の際、現に御社商標が御社の業務に係るどら焼きを表示するものとして、需要者の間に広く認識されているときは<hint hidden='true'>**
←⭕
**</hint>    

ウ　乙株式会社の商標登録前から、御社がどら焼きについて御社商標を使用し、または使用する準備をしているときは  

エ　乙株式会社の商標登録前から、御社がどら焼きについて御社商標を使用していた結果、乙株式会社の商標登録の際、現に御社商標が御社の業務に係るどら焼きを表示するものとして、需要者の間に広く認識されているときは

`
        },
        {
                "mondaiId": 105010150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　産業財産権法に関する記述として、最も適切なものはどれか。  

ア　特許法には、特許異議申立制度が規定されている。<hint hidden='true'>**
←⭕
**</hint>    
イ　実用新案法には、審査請求制度が規定されている。  
ウ　意匠法には、出願公開制度が規定されている。  
エ　商標法には、新規性喪失の例外規定が規定されている。
`
        },
        {
                "mondaiId": 105010161,
                "name": "第16問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小企業診断士であるあなたと株式会社Ｘの代表取締役甲氏との間の以下の会話を読んで、下記の設問に答えよ。  

甲氏：「弊社は、現在、自社ブランド製品について、外国の販売業者と取引を開始しようと考えています。先方から届いた契約書案を検討しているのですが、以下の規定について教えてください。  

Title & Risk  
Risk of loss of the Products sold by Seller under this Agreement shall pass to Purchaser upon Purchaser’s acceptance of delivery of the same at the Designated Delivery Site，and title of the Products shall pass to Purchaser only upon full payment therefor.」  

あなた：「この規定は、危険負担と所有権の移転に関する条項です。このうち、（　Ａ　）については、（　Ｂ　）に移転するものと定められています。  
また、（　Ｃ　）に　ついては、（　Ｄ　）に移転するものと定められています。なお、（　Ｃ　）については、貿易取引条件の解釈の誤解や行き違いを回避する目的で、国際商業会議所が制定した<u>インコタームズ</u>という規則がありますので、それによることも考えられます。」  

（設問1）  
会話の中の空欄Ａ～Ｄに入る語句の組み合わせとして、最も適切なものはどれ  
か。  

ア　Ａ：危険負担　Ｂ：代金支払時　Ｃ：所有権　Ｄ：引渡時  
イ　Ａ：危険負担　Ｂ：引渡時　Ｃ：所有権　Ｄ：代金支払時  
ウ　Ａ：所有権　　Ｂ：代金支払時　Ｃ：危険負担　Ｄ：引渡時<hint hidden='true'>**
←⭕
**</hint>    
エ　Ａ：所有権　　Ｂ：引渡時　Ｃ：危険負担　Ｄ：代金支払時`
        },
        {
                "mondaiId": 105010162,
                "name": "第16問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小企業診断士であるあなたと株式会社Ｘの代表取締役甲氏との間の以下の会話を読んで、下記の設問に答えよ。  

甲氏：「弊社は、現在、自社ブランド製品について、外国の販売業者と取引を開始しようと考えています。先方から届いた契約書案を検討しているのですが、以下の規定について教えてください。  

Title & Risk  
Risk of loss of the Products sold by Seller under this Agreement shall pass to Purchaser upon Purchaser’s acceptance of delivery of the same at the Designated Delivery Site，and title of the Products shall pass to Purchaser only upon full payment therefor.」  

あなた：「この規定は、危険負担と所有権の移転に関する条項です。このうち、（　Ａ　）については、（　Ｂ　）に移転するものと定められています。  
また、（　Ｃ　）に　ついては、（　Ｄ　）に移転するものと定められています。なお、（　Ｃ　）については、貿易取引条件の解釈の誤解や行き違いを回避する目的で、国際商業会議所が制定した<u>インコタームズ</u>という規則がありますので、それによることも考えられます。」  

（設問2）  
　会話の中の下線部に関連して制定された「海上および内陸水路輸送のための規則」のうち、CIFの説明として、最も適切なものはどれか。  

ア　運賃込み  
イ　運賃保険料込み<hint hidden='true'>**
←⭕
**</hint>    
ウ　船側渡し  
エ　本船渡し
`
        },
        {
                "mondaiId": 105010170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　共有に関する記述として、最も適切なものはどれか。なお、別段の意思表示はないものとする。  

ア　共有不動産の所有権確認の訴えを提起するには、各共有者の持分の価格に従い、その過半数で決しなければならない。  

イ　共有不動産の不法占有者に引渡を請求する場合、各共有者がそれぞれ単独でできる。<hint hidden='true'>**
←⭕
**</hint>    

ウ　共有不動産を妨害する者に損害賠償を請求する場合、他の共有者の持分についてもすることができる。  

エ　共有不動産を目的とする賃貸借契約の解除をするには、他の共有者全員の同意を得なければならない。
`
        },
        {
                "mondaiId": 105010180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　担保物権のうち、その目的物の売却、賃貸、滅失又は損傷によって債務者が受けるべき金銭その他の物に対して、担保物権を<u>行使することができないもの</u>として、最も適切なものはどれか。  

ア　先取特権  
イ　質権  
ウ　抵当権  
エ　留置権<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 105010190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　民法に基づく保証に関する記述として、最も適切なものはどれか。なお、別段の意思表示はなく、商法は適用されないものとする。  

ア　主たる債務者の意思に反して保証をした者は、求償権を有しない。  

イ　数人の保証人がある場合には、それらの保証人が各別の行為により単純保証したときは、全員が当該債務全部の弁済義務を負う。  

ウ　保証人が主たる債務者の委託を受けて保証をした場合において、主たる債務者が弁済をしたことを保証人に通知することを怠ったため、保証人が善意で弁済をしたときは、その保証人は、自己の弁済を有効であったものとみなすことができる。<hint hidden='true'>**
←⭕
**</hint>    

エ　保証人は、主たる債務者の委託を受けないで保証をした場合において、債務が  
弁済期にあるときは、主たる債務者に対して、あらかじめ、求償権を行使するこ  
とができる。`
        },
        {
                "mondaiId": 105010200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　債権譲渡に関する記述として、最も適切なものはどれか。なお、別段の意思表示はないものとする。  

ア　ＡがＢに対する指名債権を二重譲渡した場合において、Ｃへの債権譲渡に係る通知の確定日付が2019年7月23日、Ｄへの債権譲渡に係る通知の確定日付が同月24日であり、債務者であるＢに当該通知が到達したのが、前者は同月26日、後者は同月25日であったときは、債務者Ｂは、Ｃに対して弁済をする必要がある。 

イ　指名債権の譲渡の対抗要件としての債務者の承諾は、譲渡人又は譲受人のどちらに対してしても、有効である。<hint hidden='true'>**
←⭕
**</hint>    

ウ　指名債権の譲渡の通知以前に、弁済期の到来している反対債権を有していた場合でも、譲渡の通知後においては相殺することができない。  

エ　指名債権の譲渡は、譲受人が譲渡人に代位して債務者に通知をすることによっても、債務者に対抗することができる。
`
        },
        {
                "mondaiId": 105010210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　遺言に関する記述として、最も適切なものはどれか。  

ア　遺言者が、遺言において、「この遺言を撤回しない」と意思表示しても、遺言者は、いつでも、遺言の方式に従って、その遺言を撤回することができる。<hint hidden='true'>**
←⭕
**</hint>    

イ　遺言は、20歳に達しなければできない。  

ウ　検認を経ないで、家庭裁判所外において開封された自筆証書遺言は、検認を経なかったことをもって無効となる。  

エ　自筆証書によって遺言をする場合、日付及び氏名を自署し、これに印を押せ  
ば、全文はパソコンで作成することができる。
`
        },
        {
                "mondaiId": 105010220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 105,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　下表は、マザーズにおける上場審査の形式要件及び上場審査の内容をまとめた表の一部を抜粋したものである。空欄Ａ～Ｄに入る数値及び語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

（形式要件）  
<imgTag>105010220_1.svg</imgTag>  

（上場審査の内容）  
<imgTag>105010220_2.svg</imgTag>  


〔解答群〕  
ア　Ａ：100　Ｂ：10　Ｃ：3　Ｄ：企業の成長可能性  
イ　Ａ：100　Ｂ：30　Ｃ：1　Ｄ：企業の存続性  
ウ　Ａ：200　Ｂ：10　Ｃ：1　Ｄ：事業計画の合理性<hint hidden='true'>**
←⭕
**</hint>    
エ　Ａ：200　Ｂ：30　Ｃ：3　Ｄ：企業の継続性および収益性  
`
        },
        {
                "mondaiId": 106010010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　各種の業務システムのデータ入力や情報検索などにタッチパネルを利用する場合がある。タッチパネルの選択に当たっては、その方式ごとの操作特性を考慮する必要がある。  
　タッチパネルの方式に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。

ａ　<hintTarget>赤外線方式</hintTarget></hintTarget>は、機器の画面の<hintTarget>複数点</hintTarget>を指先で同時に直接触れて操作することができる。  

<hint hidden='true'>**
⭕
<img src="https://www.eizo.co.jp/eizolibrary/other/itmedia02_08/tm_1009touch_07.gif"/>
（<a href="https://www.eizo.co.jp/eizolibrary/other/itmedia02_08/" target="_blank">  
EIZO株式会社：「タッチパネル」の基礎知識</a>）  
　  
赤外線方式は、画面の右上と左上からなど、複数の方向から画面に赤外線を照射して、指先で赤外線を遮られた際にできる影からタッチ位置を検出します。  
したがって、赤外線の影が複数存在することを検知することにより、マルチタッチが可能です。
** </hint>  

ｂ　<hintTarget>静電容量方式</hintTarget>は、機器の画面の<hintTarget>複数点</hintTarget>を指先で同時に直接触れて操作することはできない  

<hint hidden='true'>**
❌静電容量方式は、<hintTarget>スマートホン（なのでマルチタッチ可能）</hintTarget>で使用されていることから分かる通り、可能です。
** </hint>  

ｃ　<hintTarget>抵抗膜方式（感圧式）</hintTarget>は、<hintTarget>対応ペン以外</hintTarget>のペンでは操作できないが、指先で直接触れて機器の画面に表示されるアイコンやボタンを操作できる。  

<hint hidden='true'>**
❌抵抗膜方式では、画面上にある2枚の抵抗膜を指などで押すことで生じる電流の変化でタッチ位置を検出するため、対応ペンでなくとも、画面に圧力を加えることで操作できます。
<img src="https://www.eizo.co.jp/eizolibrary/other/itmedia02_08/tm_1009touch_03.gif"/>
（<a href="https://www.eizo.co.jp/eizolibrary/other/itmedia02_08/" target="_blank">  
EIZO株式会社：「タッチパネル」の基礎知識</a>）  
** </hint>

ｄ　<hintTarget>静電容量方式</hintTarget>は、<hintTarget>対応ペン以外</hintTarget>のペンでは操作できないが、指先で直接触れて機器の画面に表示されるアイコンやボタンを操作できる。  

<hint hidden='true'>**
⭕静電容量方式は、静電的な導電性があるもので画面に触れた際に生じる電流の変化でタッチ位置を検出するため、下記の画像のような対応ペン（ペン先が導電性のある素材で十分に太い）以外では操作できません。  
<imgTag>106010010_1.png</imgTag>  
** </hint>

〔解答群〕  
ア　ａとｂ  
イ　ａとｄ<hint hidden='true'>**
←⭕
**</hint>     
ウ　ｂとｃ  
エ　ｃとｄ

<hint hidden='true'>**
表：タッチパネルの方式比較まとめ
**
<TABLE border="1" align="left" style='background-color:#ffddff;color:#cc0000;' width="100%">
<TR>
<TD></TD>
<TD>**どのようにタッチ位置を検知している？**</TD>
<TD>**マルチタッチできる？**</TD>
<TD>**対応ペン以外のペンに対応している？**</TD>
<TD>**用例（どんな機器で使われている？）**</TD>
</TR>
<TR>
<TR>
<TD>赤外線方式</TD>
<TD>画面の右上と左上からなど、複数の方向から赤外線を照射して、指先で赤外線を遮られた際にできる影から</TD>
<TD><hintTarget>できる　</hintTarget></TD>
<TD>○</TD>
<TD>現在はあまりない。日光に弱い、小型化しにくいなど制約が多いため</TD>
</TR>
<TD>静電容量方式</TD>
<TD>指先（あるいは静電的な導電性がある物体）と導電膜の間の静電容量の変化</TD>
<TD><hintTarget>できる</hintTarget></TD>
<TD>☓使えるのは静電的な導電性がある専用ペンのみ</hintTarget></TD>
<TD><hintTarget>スマートホン </hintTarget></TD>
</TR>
<TR>
<TD>抵抗膜方式（感圧式）</TD>
<TD>画面上にある2枚の抵抗膜を指などで押すことで生じる電流</TD>
<TD>できないものが多い</TD>
<TD>○</TD>
<TD>旧型の銀行ATM、子供向け玩具などの小型かつ安価な機器</TD>
</TR>
</TABLE>  
</hint>

`
        },
        {
                "mondaiId": 106010020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　レーザ光を利用してデータの読み書きを行う記憶媒体にはさまざまな種類があり、それぞれの特徴を理解する必要がある。  
　記憶媒体に関する記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】ディスク型記憶媒体比較
<TABLE border="0" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD style='border: 1px solid gray;'> </TD>
<TD style='border: 1px solid gray;'>データの書き換え可否</TD>
</TR>
<TR>
<TD style='border: 1px solid gray;'>BD-ROM</TD>
<TD style='border: 1px solid gray;'>不可。ReadOnlyMemoryの略</TD>
</TR>
<TR>

<TR>
<TD style='border: 1px solid gray;'>BD-R</TD>
<TD style='border: 1px solid gray;'>追記が可能。書換不可</TD>
</TR>
<TR>

<TR>
<TD style='border: 1px solid gray;'>DVD-RW</TD>
<TD style='border: 1px solid gray;'>繰り返し書き換え可能（ただしファイル単位で書き換えは不可）</TD>
</TR>
<TR>

<TR>
<TD style='border: 1px solid gray;'>DVD-RAM</TD>
<TD style='border: 1px solid gray;'>繰り返し書き換え可能（ファイル単位で書き換えも可能）</TD>
</TR>
</TABLE>

</div>
</hint>

ア　BD-<hintTarget>ROM</hintTarget>は、データを未記録部分へ繰り返し<hintTarget>追記</hintTarget>することができる。  
<hint hidden='true'>**  
❌追記できません。ROMの正式名称は、<hintTarget>Ｒｅａｄ　Ｏｎｌｙ　Ｍｅｍｏｒｙ（読み出し専用記憶媒体）です</hintTarget>  
** </hint>

イ　BD-Rは、<hintTarget>読み出し専用</hintTarget>である。  

<hint hidden='true'>**  
❌追記が可能です。読み出し専用のBlueRayディスクの規格は、BD-ROMです。  
** </hint>

ウ　DVD-RAMは、データを<hintTarget>一度だけ</hintTarget>書き換えることができる。  
<hint hidden='true'>**
❌繰り返し書き換えできます。一度だけ書き換え可能なのはBD-Rです
** </hint>

エ　DVD-RWは、データを<hintTarget>繰り返し</hintTarget>書き換えることができる。  

<hint hidden='true'>**  
⭕適切です。  
DVD-RAMとの違いは、<hintTarget>  
繰り返し書き換えが、  
DVD-RW　　→　ディスク全体しかできない（ファイル単位は不可）  
DVD-RAM　→　ファイル単位でもできる  </hintTarget>
** </hint>

`
        },
        {
                "mondaiId": 106010030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Webアプリケーションを開発するに当たっては、さまざまな開発言語や仕組みが必要になる。  
　Webアプリケーションの開発に利用する言語や仕組みに関する記述として、最も適切なものはどれか。  

ア　<hintTarget>Ajax</hintTarget>は、WebブラウザのJavaScriptのHTTP通信機能を利用して、<hintTarget>対話型</hintTarget>のWebアプリケーションを構築する仕組みである。

<hint hidden='true'>**
⭕適切です。
Ajaxは、Asynchronous JavaScript＋XMLの略です。  
JavaScriptというプログラム言語とXMLを用いて、非同期的（Asynchronous）に通信する点が特徴的です。  
Ajaxでは、Ｗｅｂブラウザでアプリケーションの画面を表示して、その画面に対するユーザの操作に対応して部分的に表示内容を書き換える事ができるため、対話的な処理を実行できます。
** </hint>

イ　Cookieは、Webサーバに対するアクセスがどの端末からのものであるかを識別するために、Webサーバの指示によって<hintTarget>Webサーバに</hintTarget>ユーザ情報などを保存する仕組みである。

<hint hidden='true'>**
❌Ｃｏｏｋｉｅが保存される場所は<hintTarget>ブラウザ</hintTarget>です。  
参考：処理の流れ
ＷｅｂサーバはセッションＩＤと呼ばれる端末ごとに異なる値を払い出す  
→端末は、Ｃｏｏｋｉｅと呼ばれるブラウザ内の保存領域に、セッションＩＤをユーザ情報などとともに保存する  
→以後の通信はセッションIDを端末とサーバで授受することで、サーバは端末を識別する。
** </hint>

ウ　CSSは、<hintTarget>タグによってWebページの構造を記述するマーク付け言語</hintTarget>であり、<hintTarget>利用者独自のタグ</hintTarget>を使って文書の属性情報や論理構造を定義できる。

<hint hidden='true'>**
❌<hintTarget>ＨＴＭＬ</hintTarget>に関する記述です  
❌利用者独自のタグを使えるのは<hintTarget>XML</hintTarget>です
** </hint>

エ　Javaは、<hintTarget>Ｃ言語にクラスやインヘリタンスといったオブジェクト指向</hintTarget>の概念を取り入れた言語であり、Ｃ言語に対して上位互換性を持つ。  
<hint hidden='true'>**
❌<hintTarget>Ｃ＋＋</hintTarget>に関する記述です
**</hint>

`
        },
        {
                "mondaiId": 106010040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の表は、ユーロを円に換算するために表計算ソフトウェアによって作成されたものである。A2～C2のセルには円に換算したい「ユーロの金額（€1、€5、€10）」が入力されている。また、A3～A5のセルにはユーロ／円の「為替レート（¥125、¥126、¥127）」が入っている。ユーロの円への換算は、「為替レート」×「ユーロの金額」の式を用いることにした。  

　このとき、はじめにB3のセルに積の式を入力し、それを空欄のセルに複写して表を完成したい。B3のセルに入力した式として、最も適切なものを下記の解答群から選べ。  

　なお、セル番地指定における$記号は<hintTarget>絶対セル参照</hintTarget>を表すものとする。また、＊記号は積を求める演算子である。
<hint hidden='true'>** 
絶対セル参照では、＄の直後にある列を表す文字（あるいは行番号）は<hintTarget>複写（コピー＆ペースト）しても複写元の文字（あるいは番号）と変わらない値</hintTarget>
が複写されます。
** </hint>


<TABLE border="1" align="center" width="100%">
<TR>
<TD style=" background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');">
&nbsp;&nbsp;列<BR>
<BR>
行&nbsp;&nbsp;</TD>
<TD>A</TD>
<TD>B</TD>
<TD>C</TD>
</TR>
<TR>
<TD>1</TD>
<td colspan="3">ユーロを円に換算する表</td>
</TR>
<TD>2</TD>
<TD>€1</TD>
<TD>€5</TD>
<TD>€10</TD>
</TR>
</TR>
<TD>3</TD>
<TD>￥125</TD>
<TD>￥625</TD>
<TD><hintTarget>
</hintTarget>
<hint hidden='true'>** 
このセルに複写されるべき計算式は  
<hintTarget>Ａ３×Ｃ２（したがって￥１２５×€１０の計算結果が表示される）</hintTarget>  
です。  
計算結果はそれぞれ  
選択肢アは、<hintTarget>Ａ３×Ｂ２＝￥１２５×€５＝￥６２５</hintTarget>、  
選択肢イは、<hintTarget>Ａ３×Ｃ２＝￥１２５×€１０＝￥１２５０ （正解）</hintTarget>、  
選択肢ウは、<hintTarget>Ｂ３×Ｂ２＝￥６２５×€５＝￥３１２５</hintTarget>、  
選択肢エは、<hintTarget>Ｂ３×Ｃ２＝￥６２５×€１０＝￥６２５０</hintTarget>  
となります。
** </hint>
</TD>
</TR>
</TR>
<TD>4</TD>
<TD>￥126</TD>
<TD></TD>
<TD></TD>
</TR>
</TR>
<TD>5</TD>
<TD>￥127</TD>
<TD></TD>
<TD></TD>
</TR>
</TABLE>

〔解答群〕  
ア　=$A3＊$B2  
イ　=$A3＊B$2  
ウ　=A$3＊$B2  
エ　=A$3＊B$2
`
        },
        {
                "mondaiId": 106010050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Webサービス開発では、従来のシステム開発とは異なる手法を採用することで、開発の迅速化やコストを低減することができる。  
　そのような開発手法の1つである<hintTarget>マッシュアップ</hintTarget>に関する記述として、最も適切なものはどれか。  

ア　開発対象のシステムを<hintTarget>分割</hintTarget>し、短期間に計画・分析・プログラミング・テストなどを<hintTarget>繰り返し</hintTarget>て実施する方法である。

<hint hidden='true'>**
❌独立性の高いシステムごとに分割して繰り返し開発する、<hintTarget>スパイラルモデル</hintTarget>に関する記述です。
** </hint>

イ　既存ソフトウェアの<hintTarget>動作を解析</hintTarget>することで、プログラムの仕様やソースコードを<hintTarget>導き出す方法である。

<hint hidden='true'>** 
❌<hintTarget>リバースエンジニアリング</hintTarget>に関する記述です
** </hint>

ウ　<hintTarget>公開されている複数のWebサービスを組み合わせることで新しいサービスを提供する方法</hintTarget>である。

<hint hidden='true'>** 
⭕<hintTarget>マッシュアップに関する記述です</hintTarget>
** </hint>

エ　部品として開発されたプログラムを<hintTarget>組み合わせて</hintTarget>プログラムを開発する方法である。

<hint hidden='true'>** 
❌部品化されたプログラムを利用して効率的に開発をすすめる手法（一般的によく行われている手法で、特定の呼称がつくような特殊なものではない）に関する記述です。
** </hint>

`
        },
        {
                "mondaiId": 106010060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　給与計算や出荷数あるいは月次決算などの処理をコンピュータで、毎月バッチ処理する場合がある。  
　このような情報処理と同じ特徴を有する処理方式に関する記述として、最も適切なものはどれか。  
        
ア　金融機関などが入出金・送金などの処理を、<hintTarget>
一定期間や一定量ごとにまとめて実行</hintTarget>する処理方式。  

<hint hidden='true'>**
⭕バッチ処理です。
**</hint>
        
イ　入金と出金のように<hintTarget>複数処理を連結した処理単位にまとめて管理</hintTarget>することで、 入金処理だけが終了して出金処理は失敗したというような、一部の処理だけが終了している状態を回避することができる処理方式。  
  
<hint hidden='true'>**
❌銀行の振り込み処理などに利用される、<hintTarget>トランザクション</hintTarget>処理に関する記述です。この処理方式を用いることによって、選択肢の記述のように入金側口座と出金側口座に、残高があたかも二重計上されたような異常な状態になることを回避できます。
**</hint>

ウ　ポイントカードのポイント残高を精算直後に確認できるように、精算処理の<hintTarget>要求が発生したときに即座に処理を実行</hintTarget>し、その結果を返す処理方式。  

<hint hidden='true'>**
❌バッチ処理と対象的な処理方式である、<hintTarget>オンライン</hintTarget>処理に関する記述です。
**</hint>

エ　利用者とコンピュータがディスプレイなどを介して、あたかも<hintTarget>対話するように</hintTarget>処理を進める処理方式。  
<hint hidden='true'>**
❌<hintTarget>対話型</hintTarget>処理に関する記述でです。
**</hint>
                

`
        },
        {
                "mondaiId": 106010070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小企業においても、複数のコンピュータを用いてシステムを構築することが少なくない。  
　そのような場合のシステム構成に関する記述として、最も適切なものはどれか。  

ア　クライアントサーバシステムのクライアントで、データの処理や保管などの<hintTarget>多くの機能を担う</hintTarget>ように構成したシステムを<hintTarget>シン</hintTarget>クライアントシステムという。

<hint hidden='true'>** 
❌<hintTarget>ファット</hintTarget>クライアントに関する記述です
** </hint>

イ　システムを2系統用意し、常に同じ処理を行わせ、その結果を相互に<hintTarget>照合・比較</hintTarget>することで高い信頼性を実現できるようにしたシステムを<hintTarget>ミラーリング</hintTarget>システムという。

<hint hidden='true'>** 
❌<hintTarget>デュアル</hintTarget>システムに関する記述です。  
なお、ミラーリングシステムは、記憶領域の故障に対する耐性を向上する方式で、同じデータを別の記憶領域（ハードディスクなど）に保存します。  
デュアルシステムと異なる点は、<hintTarget>処理結果を照合</hintTarget>しない点です。
** </hint>

ウ　ネットワーク上で<hintTarget>対等な関係</hintTarget>にあるコンピュータを相互に直接接続し、データを送受信するように構成したシステムを<hintTarget>グリッドコンピューティング</hintTarget>システムという。

<hint hidden='true'>** 
❌対等ではありません。例えば、負荷の分散をするために、それぞれのコンピュータの処理能力などに応じて、処理の実行指示を行うコンピュータが存在します。
** </hint>

エ　複数のコンピュータを相互に接続し、あたかも1台の高性能なコンピュータのごとく利用できるように構成したシステムを<hintTarget>クラスタリングシステム</hintTarget>という。

<hint hidden='true'>** 
⭕適切です。  
類似技術のグリッドコンピューティングとの相違点は、<hintTarget>規模です。  
　グリッドコンピューティング　：　大規模  
　クラスタリングシステム　：　２，３台程度の小規模</hintTarget>
** </hint>
              

`
        },
        {
                "mondaiId": 106010080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小企業診断士のあなたは、あるメールを開封したところ、次のようなURLに接続するように指示が出てきた。  

https：//News.Fishing.jp/test  

このURLから分かることとして、最も適切なものはどれか。  

ア　<hintTarget>SSL</hintTarget>を用いて暗号化されたデータ通信であることが確認できる。  

<hint hidden='true'>**
⭕適切な記述です。  
URL中の、<hintTarget>https（SSLを用いない場合、httpになる）</hintTarget>という記述から、SSLを用いて暗号化されたデータ通信であることがわかります
**</hint>  

イ　<hintTarget>大文字と小文字を入れ替えた</hintTarget>偽サイトであることが確認できる。  

<hint hidden='true'>**
❌URL中のNewsの箇所（<hintTarget>ホスト</hintTarget>名）と、（Fishing.jpの箇所(<hintTarget>ドメイン</hintTarget>名）は、大文字と小文字を区別しないため、偽サイトではありません。  
なお、ファイル名（URL中のtestの箇所）は、大文字小文字を区別します。
**</hint>

ウ　参照先ホストのサーバが日本国内に<hintTarget>設置</hintTarget>されていることが確認できる。  

<hint hidden='true'>**
❌News.Fishing.jpのjpはjapanの略ですが、サーバの設置場所は日本とは限りません。  
あくまで、このドメイン名は日本に<hintTarget>住所がある組織・個人が登録をしてい</hintTarget>ることを表しています。
**</hint>

エ　<hintTarget>ホスト名</hintTarget>のWWWが省略されていることが確認できる。
       
<hint hidden='true'>**
❌URL中の「<hintTarget>News</hintTarget>」の箇所がホスト名にあたります
**</hint>

`
        },
        {
                "mondaiId": 106010090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　企業の情報システム開発においては多様なデータベース（DB）が用いられる。それらを適切に利用するためには、各DBの特徴を把握しておく必要がある。  

　DBに関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  
  
ａ　レコード間の相互関係をポインタを用いて記述した<hintTarget>木構造</hintTarget>で表現できるDBを階層型DBという。  
  
<hint hidden='true'>**
⭕階層型DBは、レコード間に親子関係のような１対多の関係を持ちます。
**</hint>  

ｂ　データを複数の表に整理して、<hintTarget>表と表の間</hintTarget>はそれぞれの表の中の<hintTarget>値を用いて関連付ける</hintTarget>DBを<hintTarget>ネットワーク型DB</hintTarget>という。  
  
<hint hidden='true'>**
❌これは<hintTarget>リレーショナルDB（関係データベース）</hintTarget>DBの説明です
**</hint>

ｃ　データを識別するための<hintTarget>キーとデータの値をペア</hintTarget>にして多様なデータを格納・管理するDBをキー・バリュー型DBという。  

<hint hidden='true'>**
⭕
**</hint>  

ｄ　レコード間の相互関係をリンクを用いて記述した木構造や<hintTarget>網構造も</hintTarget>表現できるDBを<hintTarget>関係データベース</hintTarget>という。  

<hint hidden='true'>**
❌これは<hintTarget>ネットワーク型</hintTarget>DBの説明です
**</hint>


〔解答群〕  
ア　ａとｂ  
イ　ａとｃ<hint hidden='true'>**
←⭕
**</hint>  
ウ　ｂとｄ  
エ　ｃとｄ

`
        },
        {
                "mondaiId": 106010100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `　
　中小企業においても、Webサイトを構築する場合など、静止画像データを利用することが多い。静止画像データの保存にはさまざまなファイル形式が利用されるので、それぞれの形式の特徴を理解する必要がある。  
　静止画像データのファイル形式に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　BMP形式は、<hintTarget>可逆圧縮方式</hintTarget>の画像フォーマットであり、256色（8ビット）<hintTarget>以下</hintTarget>で静止画像を保存できる。  

<hint hidden='true'>**
❌BMP形式はファイルサイズを圧縮しない形式です。  
BMP形式は<hintTarget>256色より高解像度の画像に対応しています</hintTarget>。
**</hint>

ｂ　JPEG形式は、<hintTarget>非可逆</hintTarget>圧縮方式の画像フォーマットであり、<hintTarget>フルカラー</hintTarget>で静止画像を保存できる。  

<hint hidden='true'>**
⭕JPEG形式は非可逆圧縮方式です。そのため、JPEG形式に圧縮すると、圧縮前の解像度の画像に戻すことができませんが、可逆圧縮方式より圧縮率が高くなります。    
フルカラーとは<hintTarget>コンピュータで色を表示する際にどの程度精細な色を表現できるかをあらわした用語で、フルカラーでは1677万7216色が表現可能</hintTarget>です
**</hint>  

ｃ　GIF形式は、<hintTarget>圧縮しない</hintTarget>画像フォーマットであり、<hintTarget>ドットの集まりとして静止画像を保存</hintTarget>できる。  

<hint hidden='true'>**
❌GIF形式は非可逆圧縮方式であるため、ファイルサイズを圧縮します。  
圧縮せず、ドットの集まりとして静止画像を保存する形式は、<hintTarget>BMP</hintTarget>形式です。
**</hint>

ｄ　PNG形式は、<hintTarget>可逆圧縮方式</hintTarget>の画像フォーマットであり、フルカラーで静止画像を保存できる。  

<hint hidden='true'>**
⭕この記述は適切です（可逆圧縮方式かつ、フルカラー対応）。
**</hint>  


〔解答群〕  
ア　ａとｂ  
イ　ａとｃ  
ウ　ｂとｄ<hint hidden='true'>**
←⭕
**</hint>    
エ　ｃとｄ

`
        },
        {
                "mondaiId": 106010110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `　
　パーソナルコンピュータ（PC）を会社内のLANに接続し、インターネットを利用して業務を行う場面が増え、インターネットの管理・運用に関する理解が必要になっている。  
　インターネットの管理・運用に関する記述として、最も適切なものはどれか。  

ア　<hintTarget>DHCP</hintTarget>は、会社内のプライベートIPアドレスを<hintTarget>グローバルIPアドレスに変換</hintTarget>し、インターネットへのアクセスを可能にする。  

<hint hidden='true'>**
❌DHCPは、パソコンやスマートホンなどの機器がネットワークに接続しようとした際に、<hintTarget>機器を識別するためのＩＰアドレスを割り当て</hintTarget>ます。  
この選択肢は<hintTarget>NATまたはNAPT</hintTarget>に関する記述です。  
**</hint>

イ　<hintTarget>MAC</hintTarget>アドレスは、<hintTarget>PCに割り振る識別番号</hintTarget>であり、<hintTarget>ネットワークのグループを示すネットワークアドレス部と、そのネットワークに属する個々のPCを識別するホストアドレス部に分かれる</hintTarget>。  

<hint hidden='true'>**
❌MACアドレスではなく、<hintTarget>IP</hintTarget>アドレスに関する記述です。  
MACアドレスは、基本的に全世界で重複する値を持つ機器は存在しない（意図的に書き換えた場合などを除く）、ネットワーク機器のハードウェアに書き込まれた識別番号です。
**</hint>

ウ　<hintTarget>NAT</hintTarget>は、LANに接続するPCに対してIPアドレスを始めとして、<hintTarget>ホスト名や経路情報、DNSサーバの情報</hintTarget>など、通信に必要な設定情報を自動的に<hintTarget>割り当てる</hintTarget>プロトコルである。  

<hint hidden='true'>**
❌この選択肢は<hintTarget>ＤＨＣＰ</hintTarget>に関する記述です。  
ＮＡＴは、選択肢アの記載の通り。
**</hint>

エ　ポート番号は、TCPやUDP通信において通信相手のアプリケーションを識別するために利用される番号であり、送信元ポート番号と宛先ポート番号の両方を指定する必要がある。

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

`
        },
        {
                "mondaiId": 106010120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `　
　中小企業においても、ネットワーク環境を理解することは重要である。ネットワーク機器と、それに対応する通信ネットワークにおけるOSI基本参照モデルの階層の組み合わせとして、最も適切なものを下記の解答群から選べ。  

【ネットワーク機器】  
ａ　LANケーブルのように、電気的な信号を送受信する装置  
<hint hidden='true'>**
①の物理層です。
**</hint>  

ｂ　リピーターハブのように、ケーブルを流れる電気信号を増幅してLANの総延長距離を伸長する装置  
<hint hidden='true'>**
②のデータリンク層です。
**</hint>  

ｃ　ルータのように、異なるLAN同士の中継役を担う装置  
<hint hidden='true'>**
③のネットワーク層です。
**</hint>  

【OSI基本参照モデルの階層の第1層～第5層】  
①第1層<hintTarget>（物理層）</hintTarget><hint hidden='true'>**
ネットワークの<hintTarget>接続に使用するケーブルのコネクタ形状やコネクタのピンなどの物理的なルールを</hintTarget>を規定しています
**</hint>  
②第2層<hintTarget>（データリンク層）</hintTarget><hint hidden='true'>**
ネットワークでデータを受け渡しする際に<hintTarget>直接接続している機器間の信号の強さや総延長距離</hintTarget>を規定しています
**</hint>  
③第3層<hintTarget>（ネットワーク層）</hintTarget><hint hidden='true'>**
ネットワークの<hintTarget>通信経路を選択して異なるLAN同士の中継をし</hintTarget>します
**</hint>  
④第4層<hintTarget>（トランスポート層）</hintTarget><hint hidden='true'>**
データが正しく相手まで<hintTarget>届いたか確認しながら分割して送信したり、受信側では正しい順番に並べ替えたりエラーを訂正</hintTarget>します
**</hint>  
⑤第5層<hintTarget>（セッション層）</hintTarget><hint hidden='true'>**
セッションと呼ばれる<hintTarget>通信が開始されてから一連の通信が終了するまでを１つの単位としたもの</hintTarget>を管理します
**</hint>

〔解答群〕  
ア　ａと①　ｂと②　ｃと③<hint hidden='true'>**
←⭕
**</hint>    
イ　ａと①　ｂと③　ｃと④  
ウ　ａと②　ｂと③　ｃと④  
エ　ａと②　ｂと④　ｃと⑤
`
        },
        {
                "mondaiId": 106010130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　ある中小企業では、情報システムの導入を検討している。最終的に、2つの情報システム（ＡとＢ）を比較検討することになり、それぞれのRASIS（<hintTarget>Reliability：信頼性</hintTarget><hint hidden='true'>**
信頼性は<hintTarget>ＭＴＢＦ</hintTarget>で評価されます
**</hint>、Availability：<hintTarget>可用性</hintTarget><hint hidden='true'>**
可用性をあらわす稼働率の公式は、<hintTarget>ＭＴＢＦ／（ＭＴＢＦ＋ＭＴＴＲ）</hintTarget>です
**</hint>、Serviceability：保守性、<hintTarget>Integrity：保全性</hintTarget><hint hidden='true'>**
保全性は<hintTarget>ＭＴＴＲ</hintTarget>で評価されます
**</hint>、Security：安全性）に注目することにした。  

　このとき、情報システムＡの平均故障間隔（MTBF）は480時間、平均修理時間（MTTR）は20時間であった。一方、情報システムＢの平均故障間隔は532時間、平均修理時間は28時間であった。  

　これら2つのシステムのRASISに関する記述として、最も適切なものはどれか。  

ア　<hintTarget>安全性</hintTarget>は、システムＡの方がシステムＢよりも優れている。  

<hint hidden='true'>**
❌この問題文に、安全性の優劣を判断できる記述は特にありません。
**</hint>

イ　<hintTarget>可用性</hintTarget>は、システムＡの方がシステムＢよりも優れている。

<hint hidden='true'>**
計算すると、  
システムＡは、<hintTarget>ＭＴＢＦ／（ＭＴＢＦ＋ＭＴＴＲ）＝４８０／（４８０＋２０）＝９６％</hintTarget>  
システムＢは、<hintTarget>ＭＴＢＦ／（ＭＴＢＦ＋ＭＴＴＲ）＝５３２／（５３２＋２８）＝９５％</hintTarget>  
したがって、<hintTarget>システムＡの信頼性＞システムＢの信頼性　となるので、不適切</hintTarget>な記述です
**</hint>

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　信頼性は、<hintTarget>システムＡの方がシステムＢよりも優れて</hintTarget><hint hidden='true'>**
信頼性を<hintTarget>ＭＴＢＦ　</hintTarget>
で比較するので、<hintTarget>　４８０時間　＜　システムＢ　５３２時間</hintTarget>であるため、<hintTarget>不適切　</hintTarget>な記述です
**</hint>いる。  

<hint hidden='true'>**
❌
**</hint>

エ　保全性は、<hintTarget>システムＡの方がシステムＢよりも優れて</hintTarget><hint hidden='true'>**
保全性を<hintTarget>ＭＴＴＲ　</hintTarget>
で比較すると、<hintTarget>システムＡ　２０時間　＜　システムＢ　２８時間</hintTarget>であるため、<hintTarget>不適切　</hintTarget>な記述です
**</hint>いる。

<hint hidden='true'>**
❌
**</hint>
                
                                                
`
        },
        {
                "mondaiId": 106010140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　e-ビジネスでは、新しいビジネスモデルを開発して特許を得ることによって利益を得ることがある。以下の記述のうち、<hintTarget>日本において特許として認められていない</hintTarget><hint hidden='true'>**
特許として認められているかどうか正確に調査するには、特許庁サイト等で調査する必要がありますが、この問題の解答にあたっては<hintTarget>ビジネスモデル特許（ITを利用したビジネス方法に関する特許）</hintTarget>に該当するか推定することで正解にたどり着くことが可能です。
**</hint>
ものはどれか。

ア　<hintTarget>航空券の購入希望者が条件を入力すると、複数の航空券販売業者が販売価格を提示し、購入希望者が1つを選択して予約する逆オークション</hintTarget><hint hidden='true'>**
この記述は<hintTarget>条件の入力された購入希望条件を、複数の販売業者から需給マッチをさせる仕組みにＩＴが活用されて</hintTarget>いることから、特許として認められると考え<hintTarget>られる</hintTarget>。
**</hint>のシステム。  

イ　<hintTarget>ネットで商品を注文して30分以内に配達できなければ割引する配達保証</hintTarget><hint hidden='true'>**
このサービスは<hintTarget>新規性が主に流通などの分野に限られ、ＩＴには該当しないと考えられる</hintTarget>ため、特許に該当<hintTarget>しない</hintTarget>。
**</hint>のサービス。

ウ　<hintTarget>ネットで商品を注文する際に、購入希望品目をショッピングカートに入れれば、個別商品ごとに決済しなくても最後にまとめて決済</hintTarget><hint hidden='true'>**
ＩＴを<hintTarget>活用した新規性のあるものである</hintTarget>ため、特許に該当<hintTarget>します</hintTarget>
**</hint>できるシステム。  

エ　<hintTarget>ネットで商品を注文する際に、住所や連絡先を一度入力しておけば2度目からはワンクリック</hintTarget><hint hidden='true'>**
ＩＴを<hintTarget>活用した新規性のあるものでない</hintTarget>ため、特許に該当<hintTarget>しません</hintTarget>
**</hint>で完了させることのできるシステム。
                

`
        },
        {
                "mondaiId": 106010150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `　
「ERP（Enterprise Resource Planning）システム」に関する記述として、最も適切なものはどれか。  

ア　基幹業務プロセスの実行を、統合業務パッケージを利用して、必要な機能を相互に関係付けながら支援する総合情報システムである。  

イ　基幹業務プロセスをクラウド上で処理する統合情報システムである。  

ウ　企業経営に必要な諸資源を統合的に管理するシステムである。  

エ　企業経営の持つ諸資源の戦略的な活用を計画するためのシステムである。
`
        },
        {
                "mondaiId": 106010160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `　
　経営の情報化において、意思決定者を支援するために、必要なデータの取得や分析などを行うシステムが求められることがある。  

　意思決定のためのデータ支援に関するａ～ｃの記述と①～⑤の用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  

【記述】  
ａ　企業のさまざまな活動を介して得られた大量のデータを目的別に整理・統合して蓄積し、意思決定支援などに利用するために、基幹業務用のデータベースとは別に作成するもの。  

ｂ　多様な形式で蓄積されている生データに対して、データ形式統一、欠損値補完、単位統一などの処理を行い、横断的な解析ができるように整えること。  

ｃ　スライシング、ダイシング、ドリルダウンなどのインタラクティブな操作によって多次元分析を行い、意思決定に利用できるようにすること。  

【用語】  
①　<hintTarget>OLAP</hintTarget><hint hidden='true'>**
これは<hintTarget>記述ｃにあたり</hintTarget>ます
**</hint>  

②　<hintTarget>データウェアハウス</hintTarget><hint hidden='true'>**
これは<hintTarget>記述ａにあたり</hintTarget>ます
**</hint>    

③　<hintTarget>データクレンジング</hintTarget><hint hidden='true'>**
これは<hintTarget>記述ｂにあたり</hintTarget>ます
**</hint>    

④　<hintTarget>データマイニング</hintTarget><hint hidden='true'>**
これは<hintTarget>企業が持つ大量のデータを分析し、有益なパターンや傾向を見出す（マイニング）することをさし</hintTarget>ます
**</hint>    

⑤　<hintTarget>データマッピング</hintTarget><hint hidden='true'>**
これは<hintTarget>データを関連付けることをさします。特に特定の技術を指す用語ではありません</hintTarget>。
**</hint>    


〔解答群〕  
ア　ａと②ｂと③ｃと①  
イ　ａと②ｂと⑤ｃと④  
ウ　ａと⑤ｂと③ｃと④  
エ　ａと⑤ｂと④ｃと①
                
`
        },
        {
                "mondaiId": 106010170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　システム開発の考え方やモデルは多様である。システム開発に関する記述として、最も適切なものはどれか。  

ア　DevOpsとは、<hintTarget>システムの開発とシステムの運用を同時並行的に行う</hintTarget><hint hidden='true'>**
Dev（開発）とOps（保守運用）が<hintTarget>協力して効率よく開発や運用</hintTarget>を行います。DevとOpsが同時並行である必要は<hintTarget>ありません</hintTarget>
**</hint>システム開発の考え方である。  

イ　ウォーターフォールモデルは、<hintTarget>開発工程を上流工程から下流工程へと順次移行</hintTarget><hint hidden='true'>**
ウォーターフォールモデルの記述として<hintTarget>適切な記述</hintTarget>です
**</hint>し、<hintTarget>後戻りはシステムの完成後にのみ</hintTarget><hint hidden='true'>**
工程の後戻りは<hintTarget>しません</hintTarget>。
**</hint>許される。  

ウ　スクラムは、開発チームの密接な連携を前提にする開発手法であるが、<hintTarget>物理的に同じ場所で作業をすることは必ずしも必要ではない。</hintTarget><hint hidden='true'>**
遠隔地にいるメンバー<hintTarget>でスクラムを行うことがあります</hintTarget>。ただし、メンバー間の密接な連携を<hintTarget>を前提としているため、ビデオ会議などのコミュニケーションツールを使用するなど工夫する</hintTarget>必要がある。
**</hint>  

エ　プロトタイピングモデルにおけるプロトタイプとは、<hintTarget>システム要件を確認した後に廃棄する試作品</hintTarget><hint hidden='true'>**
プロトタイプを見てシステム要件（どのようなシステムの仕様であるべきか）を確認した後に廃棄する<hintTarget>とは限らない</hintTarget>
**</hint>のことである。
`
        },
        {
                "mondaiId": 106010180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `　
　ある中小企業では、出退勤システムの実装を進めている。バーコードリーダーを用いて社員証の社員番号を読み取り、出退勤をサーバ上で管理するためのプログラムが作成され、テストの段階に入った。  

　テストに関する記述として、最も適切なものはどれか。  

ア　<hintTarget>結合テスト</hintTarget><hint hidden='true'>**
結合テストは<hintTarget>複数モジュール間のインタフェースが正常に機能しているか</hintTarget>を確認するテストです
**</hint>は、<hintTarget>出退勤システム全体の処理能力が十分であるか、高い負荷でも問題がないか、などの検証を行うために、実際に使う環境で行うテスト</hintTarget><hint hidden='true'>**
この記述は<hintTarget>総合テストに関する記述</hintTarget>です
**</hint>である。  

イ　<hintTarget>ブラックボックステスト</hintTarget><hint hidden='true'>**
ブラックボックステストは<hintTarget>内部構造を考えずに、設計書で決められた入力に対する出力が行われるか</hintTarget>をテストします
**</hint>は、<hintTarget>出退勤システムに修正を加えた場合に、想定外の影響が出ていないかを確認する</hintTarget><hint hidden='true'>**
この記述は<hintTarget>リグレッションテストのテスト内容</hintTarget>です
**</hint>ためのテストである。  

ウ　ホワイトボックステストは、<hintTarget>社員証の読み取りの際のチェックディジットの条件を網羅的にチェックするなど、内部構造を理解した上で行うテストである。</hintTarget><hint hidden='true'>**
ホワイトボックステストは<hintTarget>この記述のとおり内部構造にもとづいて、バグ発生可能性が高いと思われる処理パターンなど</hintTarget>をテストします
**</hint>

エ　リグレッションテストは、<hintTarget>社員証の読み取りやサーバ送信などの複数モジュール間のインタフェースが正常に機能しているかを確認する</hintTarget><hint hidden='true'>**
この記述は<hintTarget>結合テストに関する記述</hintTarget>です
**</hint>テストである。
                
`
        },
        {
                "mondaiId": 106010190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　情報ネットワーク社会を支えるセキュリティ技術の1つに暗号化技術がある。  
　暗号化方式に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　<hintTarget>共通鍵暗号方式</hintTarget><hint hidden='true'>**
共通鍵暗号方式の処理イメージ図は<hintTarget><img src="assets/img/106010190_1.png" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></hintTarget>です
**</hint>
は、暗号化と復号に共通鍵を用いる方式である。この方式では、送信者と受信者は<hintTarget>あらかじめ共通鍵を共有しておく必要がある。</hintTarget><hint hidden='true'>**
あらかじめ共有しておく必要はあるでしょうか？→<hintTarget>あります。同じ鍵を持っておかないと復号ができないからです。あらかじめ共有するコストが足かせとなって共通鍵の変更頻度が下がることで、鍵が盗まれて盗聴されるリスクが発生することが、共通鍵暗号方式のデメリットです</hintTarget>
**</hint>  

ｂ　<hintTarget>公開鍵暗号方式</hintTarget><hint hidden='true'>**
公開鍵暗号方式の処理イメージ図は<hintTarget><img src="assets/img/106010190_2.png" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></hintTarget>です
**</hint>では、送信者は送信データを受信者の公開鍵で暗号化し、それを受け取った受信者は、ペアとなる送信者の秘密鍵で復号する。  

ｃ　公開鍵暗号方式は、暗号化と復号に2個1組の異なる鍵を用いる方式である。この方式では、<hintTarget>データを送信する時には送信者の公開鍵</hintTarget><hint hidden='true'>**
送信者の公開鍵を使って暗号化するとどうなるでしょうか？→<hintTarget>復号化できるのは「送信」者の秘密鍵だけ。送信者の秘密鍵は送信者しか持っていないので、受信者が復号化できなくなってしまいます。なのでこの選択肢は不適切です。</hintTarget>
**</hint>を使う。  

ｄ　<hintTarget>セッション鍵方式</hintTarget><hint hidden='true'>**
処理イメージ図は<hintTarget><img src="assets/img/106010190_3.png" alt="ネットワーク接続を確認してください" style="height: auto;width: 30em;"></hintTarget>です
**</hint>は、共通鍵暗号方式の長所と公開鍵暗号方式の長所を組み合わせた方式である。  


〔解答群〕  
ア　ａとｂ  
イ　ａとｄ  
ウ　ｂとｃ  
エ　ｃとｄ
                
`
        },
        {
                "mondaiId": 106010200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　独立行政法人情報処理推進機構（IPA）は、「中小企業の情報セキュリティ対策ガイドライン（第3版）」を公表している。この中で経営者が実行すべき「重要7項目の取組」を挙げている。  
「重要7項目の取組」に該当しないものはどれか。  

<hintTarget></hintTarget><hint hidden='true'>**
💡解き方のヒント💡<hintTarget>「中小企業の情報セキュリティ対策ガイドライン」は、令和元年度以前に出題歴はありません。ただし、この問題は、最も不適切と思われる選択肢を選択すれば正解できる難度の問題なので、取りこぼさないようにしましょう</hintTarget>
**</hint>

ア　情報セキュリティ<hintTarget>対策のための予算や人材などを確保</hintTarget><hint hidden='true'>**
予算や人材確保が適切か？<hintTarget>適切です。ただし経営資源に制約のある中小企業にとって過度の負担とならないよう、事故発生時のリスクに見合った資源配分が求められます</hintTarget>
**</hint>する。  

イ　情報セキュリティに関する<hintTarget>組織全体の対応方針を定める。</hintTarget><hint hidden='true'>**
組織全体の対応方針が必要か？<hintTarget>セキュリティ事故の内容あるいは事業内容によっては全社的な被害となる可能性があるため、個々の組織や個人ではなく組織全体で対応方針を定める必要があります。</hintTarget>
**</hint>  

ウ　情報セキュリティは<hintTarget>専門的な問題なので、専門家に一任する。</hintTarget><hint hidden='true'>**
専門的な問題なので、一任すべきか？<hintTarget>予防策（特に技術的なもの）の検討など、専門的な知識を必要とする場合はあります。しかし、問題発生時の対処や、ビジネスインパクトの見積もりなど、専門家より精度の高い検討が可能と考えられる領域が存在します。そのため専門家に一任するという記述は不適切です。</hintTarget>
**</hint>  

エ　<hintTarget>必要と考えられる対策を検討させて実行を指示</hintTarget><hint hidden='true'>**
検討させて実行を指示すべきか？<hintTarget>適切な記述です。対策の実効性を高めるためにも、担当者自ら検討・実行指示することが望ましいです。</hintTarget>
**</hint>する。
`
        },
        {
                "mondaiId": 106010210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　Ｒ．Ｓ．キャプランとＤ．Ｐ．ノートンが開発した<hintTarget>バランスト・スコアカード（BSC）は、</hintTarget>
<hint hidden='true'>ＢＳＣをまとめると、下記の表になります。
<TABLE border="1" align="left" style='background-color:#ffddff;color:#cc0000;' width="100%">
<TR>
<TD></TD>
<TD>説明</TD>
<TD>例</TD>
</TR>
<TR>
<TD><hintTarget>学習と成長</hintTarget>の視点</TD>
<TD><hintTarget>組織と個人がどのように能力向上するか</hintTarget>の指標</TD>
<TD><hintTarget>手書きの見積書作成を、スマホからWeb経由で自社に依頼するシステムを作成、操作手順の習熟者を</hintTarget>増やす</TD>
</TR>
<TR>
<TD><hintTarget>業務プロセス</hintTarget>の視点</TD>
<TD><hintTarget>優れた業務プロセスを構築するため</hintTarget>の指標</TD>
<TD><hintTarget>手書きや見積書発行時間</hintTarget>の短縮</TD></TR>
<TR>
<TD><hintTarget>顧客</hintTarget>の視点</TD>
<TD><hintTarget>顧客に</hintTarget>対してどのように行動すべきかの指標</TD>
<TD><hintTarget>顧客の見積もり早期回答ニーズに対応</hintTarget></TD></TR>
<TR>
<TD><hintTarget>財務</hintTarget>の視点</TD>
<TD><hintTarget>財務的に成功するためにどのように行動すべきか</hintTarget>の指標</TD>
<TD><hintTarget>受注率ＵＰ</hintTarget></TD>
</TR>
</TABLE>  
</hint>情報通信技術（ICT）投資の評価手法の1つとして使われることがある。BSCでは4つの視点から評価するとされているが、この4つの視点に含まれないものはどれか。  


ア　<hintTarget>学習と成長の視点</hintTarget><hint hidden='true'>**
この記述は<hintTarget>適切</hintTarget>です
**</hint>  
イ　競合企業の視点</hintTarget><hint hidden='true'>**
この記述は<hintTarget>不適切</hintTarget>です
**</hint>  
ウ　業務プロセスの視点</hintTarget><hint hidden='true'>**
この記述は<hintTarget>適切　</hintTarget>です
**</hint>  
エ　顧客の視点</hintTarget><hint hidden='true'>**
この記述は<hintTarget>　適切</hintTarget>です
**</hint>  
オ　財務の視点</hintTarget><hint hidden='true'>**
この記述は<hintTarget>適切　　</hintTarget>です
**</hint>
                
`
        },
        {
                "mondaiId": 106010220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　情報通信ネットワークを介して、外部の事業者が提供するさまざまな種類のサービスを、中小企業も利用できるようになってきている。  
　そのようなサービスに関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　所有する高速回線や耐震設備などが整った施設を提供することで、顧客が用意するサーバなどの設置を可能にするサービスを<hintTarget>ハウジングサービスという。</hintTarget>
<hint hidden='true'>**表：ハウジングサービスとホスティングサービスの違いとは？**<TABLE border="1" align="left" style='background-color:#ffddff;color:#cc0000;' width="100%"><TR>
<TD>ハウジングサービス</TD>
<TD>顧客に<hintTarget>回線や耐震含む各種設備</hintTarget>を提供する</TD>
</TR>
<TD>ホスティングサービス</TD>
<TD>顧客に<hintTarget>（回線や設備に加えて）サーバ</hintTarget>を提供する</TD>
</TR>
</TABLE>
</hint>  

ｂ　所有するサーバの一部を顧客に貸し出し、顧客が自社のサーバとして利用するサービスをホスティングサービスという。  

ｃ　<hintTarget>電子メール、グループウェア、顧客管理システム、財務会計システムなどの機能をネットワーク経由で提供するサービス</hintTarget><hint hidden='true'>**
この記述は<hintTarget>ＳａａＳ（Ｓｏｆｔｗａｒｅ　Ａｓ　Ａ Ｓｅｒｖｉｃｅ）</hintTarget>です。
**</hint>を、ソーシャルネットワークサービスという。  

ｄ　<hintTarget>業務用のアプリケーションの機能をネットワーク経由で複数に提供するサービス</hintTarget><hint hidden='true'>**
この記述は<hintTarget>マイクロサービス</hintTarget>です
**</hint>をISPサービスという。  

〔解答群〕  
ア　ａとｂ  
イ　ａとｄ  
ウ　ｂとｃ  
エ　ｃとｄ
                
`
        },
        {
                "mondaiId": 106010230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　クラウドコンピューティングは、インターネットを通じて提供されるさまざまなサービスを利用してデータ処理を行う利用形態であり、広く利用されるようになってきている。  
クラウドコンピューティングのサービスはさまざまな形態で提供されるが、アプリケーション、ミドルウェア、OS、ハードウェアの全ての機能を提供し、複数の顧客が利用するサービスの名称として、最も適切なものはどれか。  

ア　IaaS（Infrastructure as a Service）

イ　MaaS（Mobility as a Service）  

ウ　PaaS（Platform as a Service）  

エ　SaaS（Software as a Service）  
  
<hint hidden='true'>**表：ＩＴ系インフラの貸与サービスの違いとは？まとめ**<TABLE border="1" align="left" style='background-color:#ffddff;color:#cc0000;' width="100%"><TR>
<TD>サービスの名前</TD>
<TD>顧客に貸与するもの</TD>
</TR>
<TR>
<TD><hintTarget>S</hintTarget>aaSここ以下まで</TD>
<TD><hintTarget>アプリケーション</hintTarget></TD>
</TR>
<TR>
<TD><hintTarget>P</hintTarget>aaSここ以下まで</TD>
<TD><hintTarget>ＯＳ、データベースなどミドルウェア</hintTarget></TD>
</TR>
<TR>
<TR>
<TD><hintTarget>I</hintTarget>aaSここ以下まで</TD>
<TD><hintTarget>仮想サーバ</hintTarget></TD>
</TR>
<TR>
<TR>
<TD><hintTarget>ホスティング</hintTarget>ここ以下まで</TD>
<TD><hintTarget>物理的なサーバ</hintTarget></TD>
</TR>
<TR>
<TD><hintTarget>ハウジング</hintTarget>ここ以下まで</TD>
<TD><hintTarget>データセンターの高速回線や耐震性のある設置場所</hintTarget></TD>
</TR>
</TABLE>  
なお、Mobility as a Serviceは、自動車の定額利用サービスのように、移動をサービスとして提供するサービス形態を指すので、この表のいずれにも該当しない。
</hint>    
                
`
        },
        {
                "mondaiId": 106010240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　以下の文章の空欄Ａ～Ｃに入る数値の組み合わせとして、最も適切なものを下記の解答群から選べ。 

　集積した数値データを分析する際に、データの値の大きさやばらつきの目安となるような特性値に注目することがある。  
　あるスーパーマーケットで10日間毎日、サンドイッチの売上個数を調査し、小さい順に並べたデータが以下に示されている。  


46　48　50　50　<hintTarget>51</hintTarget>　<hintTarget>51</hintTarget>　53　53　53　55
<hint hidden='true'>**メディアンの日本語名は？→<hintTarget>中央値</hintTarget>です。  
データの個数が偶数のときは？→<hintTarget>真ん中に近い値２つの平均</hintTarget>が中央値になります。
**</hint>

このとき、サンドイッチの1日当たり売上個数の<hintTarget>平均は（Ａ）</hintTarget><hint hidden='true'>**
平均＝<hintTarget>（４６＋４８＋５０＋５０＋５１＋５１＋５３＋５３＋５３＋５５）／１０＝５１</hintTarget>です
**</hint>、メディアンは（Ｂ）、<hintTarget>平均偏差は（Ｃ）である。</hintTarget><hint hidden='true'>**
平均偏差＝<hintTarget>｜５１－４６｜＋｜５１－４８｜＋｜５１－５０｜＋｜５１－５０｜＋｜５１－５１｜＋｜５１－５１｜＋｜５１－５３｜＋｜５１－５３｜＋｜５１－５３｜＋｜５１－５５｜）／１０＝２</hintTarget>です  
**</hint>

〔解答群〕  
ア　Ａ：51　Ｂ：51　Ｃ：0.0  
イ　Ａ：51　Ｂ：51　Ｃ：2.0  
ウ　Ａ：51　Ｂ：52　Ｃ：2.0  
エ　Ａ：52　Ｂ：53　Ｃ：0.0
                
`
        },
        {
                "mondaiId": 106010250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2019,
                "mondaiSyuId": 106,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　情報通信技術（ICT）においては、相互接続性や相互運用性を確保することが不可欠である。このため、さまざまな組織が規格の標準化を進めている。  
　<hintTarget>標準化を進める組織</hintTarget>に関する以下の文章の空欄Ａ～Ｄに入る語句の組み合わせとして、最も適切なものを下記の解答群から選べ。
<hint hidden='true'>**
表：標準化をすすめる組織まとめ**
<TABLE border="1" align="left" style='background-color:#ffddff;color:#cc0000;' width="100%">
<TR>
<TD></TD>
<TD>デジュールかデファクトか</TD>
<TD>説明</TD>
<TD>ＡＢＣＤ</TD>
</TR>
<TR>
<TR>
<TD>ＩＥＥＥ</TD>
<TD><hintTarget>デファクト</hintTarget></TD>
<TD><hintTarget>学会。ＬＡＮやイーサネットなどの通信方式や電子部品の研究や標準化</hintTarget>を図る</TD>
<TD><hintTarget>（Ｃ）</hintTarget></TD>
</TR>
<TR>
<TD>Ｗ３Ｃ</TD>
<TD><hintTarget>デファクト</hintTarget></TD>
<TD><hintTarget>非営利団体。インターネットのＷｅｂに関する技術の標準化</hintTarget>を図る</TD>
<TD><hintTarget>（Ｄ）</hintTarget></TD>
</TR>
<TD>ＩＳＯ</TD>
<TD><hintTarget>デジュール</hintTarget></TD>
<TD><hintTarget>国家規格団体が加盟する世界的規模の非政府組織であり、世界的な標準化およびその関連活動の発展開発</hintTarget>を図る</TD>
<TD><hintTarget>（Ａ）</hintTarget></TD>
</TR>
<TR>
<TD>ＩＴＵ</TD>
<TD><hintTarget>デジュール</hintTarget></TD>
<TD><hintTarget>国際連合の専門機関であり、情報通信分野の国際標準の策定</hintTarget>を図る</TD>
<TD><hintTarget>（Ｂ）</hintTarget></TD>
</TR>
</TABLE>
</hint>
  
（Ａ）は、世界160カ国以上の国家規格団体が加盟する世界的規模の非政府組織であり、世界的な標準化およびその関連活動の発展開発を図ることを目的としている。  

（Ｂ）は、国際連合の専門機関であり、情報通信分野の国際標準の策定を図り、発展途上国への技術協力の推進を行っている。  

（Ｃ）は、LANやイーサネットなどの通信方式や電子部品の研究や標準化を行う学会である。
        
（Ｄ）は、インターネットのWebに関する技術の標準化を進める非営利団体である。  

（Ａ）や（Ｂ）のような国際標準化機関が作成する標準をデジュール標準と呼び、（Ｃ）や（Ｄ）のような民間団体が作成する標準をデファクト標準と呼ぶ。  

〔解答群〕  
ア　Ａ：IEEE　Ｂ：ITU　Ｃ：ISO　　Ｄ：W3C  
イ　Ａ：ISO　　Ｂ：ITU　Ｃ：IEEE　Ｄ：W3C  
ウ　Ａ：ISO　　Ｂ：W3C　Ｃ：IEEE　Ｄ：ITU  
エ　Ａ：ITU　　Ｂ：ISO　Ｃ：W3C　Ｄ：IEEE
                
`
        },
        {
                "mondaiId": 107010011,
                "name": "第１問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
総務省「平成11年、13年、16年、18年事業所・企業統計調査」、「平成21年、  26年経済センサス－基礎調査」、総務省・経済産業省「平成24年経済センサス－活動調査」に基づき、1999年から2014年の期間について、わが国の企業数の推移を見た場合、減少基調で推移している。しかしながら<u>近年では、企業規模で<hintTarget>企業数の推移には違いも見られる</hintTarget></u>。 <hint hidden='true'>**
大規模と小規模の近年の増減傾向は？<hintTarget>いずれも減少傾向です。中規模の増減は判断が分かれるところですが、大小ともに減少傾向であることを記憶していれば、中規模についての判断無しで正解できる問題でした。</hintTarget>  
【図：企業規模別企業数の推移グラフ】  
<imgTag>107010011_1.png</imgTag>  
**</hint> 
　また、業種別企業数を1999年と2014年で比較した場合、建設業、小売業、製造業では、（　Ａ　） の企業数の減少が最も大きく、1999年の約108万者から2014年には約（　Ｂ　）割程度の企業数まで減少している。  
　なお、ここでは企業数は会社数と個人事業者数の合計とする。  

（設問1）  
　文中の下線部に関して、総務省「平成21年、26年経済センサス－基礎調査」に基づき、2009年と2014年について、企業規模別企業数を比較した場合の記述として、最も適切なものはどれか。  

　なお、ここでは中規模企業は小規模企業以外の中小企業とし、企業規模区分は中小企業基本法に準ずるものとする。  

ア　小規模企業数は減少、中規模企業数は減少、大企業数は増加している。  
イ　小規模企業数は減少、中規模企業数は増加、大企業数は減少している。  <hint hidden='true'>**
←⭕適切です。  
**</hint>  
ウ　小規模企業数は減少、中規模企業数は増加、大企業数は増加している。  
エ　小規模企業数は増加、中規模企業数は減少、大企業数は増加している。  
オ　小規模企業数は増加、中規模企業数は増加、大企業数は減少している。


`
        },
        {
                "mondaiId": 107010012,
                "name": "第１問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
総務省「平成11年、13年、16年、18年事業所・企業統計調査」、「平成21年、  26年経済センサス－基礎調査」、総務省・経済産業省「平成24年経済センサス－活動調査」に基づき、1999年から2014年の期間について、わが国の企業数の推移を見た場合、減少基調で推移している。しかしながら<u>近年では、企業規模で企業数の推移には違いも見られる</u>。  
　また、業種別企業数を1999年と2014年で比較した場合、建設業、小売業、製造業では、（　Ａ　<hint hidden='true'>**
小売業
**</hint>  ） の企業数の減少が最も大きく、1999年の約108万者から2014年には約（　Ｂ　<hint hidden='true'>**
６
**</hint>  ）割程度の企業数まで減少している。  
　なお、ここでは企業数は会社数と個人事業者数の合計とする。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】<a href="https://www.chusho.meti.go.jp/pamflet/hakusyo/H30/PDF/chusho/03Hakusyo_part1_chap2_web.pdf" target="_blank">中小企業白書　業種別企業数の推移</a>  
<imgTag>107010012_1.png</imgTag>

</div>
</hint>

（設問2）  
　文中の空欄ＡとＢに入る語句と数値の組み合わせとして、最も適切なものはどれか。  
ア　Ａ：建設業　Ｂ：3  
イ　Ａ：建設業　Ｂ：6  
ウ　Ａ：小売業　Ｂ：3  
エ　Ａ：小売業　Ｂ：6<hint hidden='true'>**←⭕適切です。** </hint>  
オ　Ａ：製造業　Ｂ：3
`
        },
        {
                "mondaiId": 107010021,
                "name": "第2問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
財務省「法人企業統計調査年報」に基づき、1983年度から2016年度の期間について、製造業の労働生産性（従業者一人当たりの付加価値額）を、企業規模別に見た場合、<u>①小規模企業の労働生産性は中規模企業と大企業を下回っている</u>。  

　労働生産性は、資本装備率（従業者一人当たりの有形固定資産）と資本生産性（有形固定資産当たりの付加価値額）に分解できるが、企業規模別に見た場合、小規模企業の資本装備率は中規模企業と大企業より（　Ａ　）、小規模企業の資本生産性は中規模企業と大企業より（　Ｂ　）。しかしながら、近年こうした<u>②規模間格差に変化が見られる</u>ようになっている。  

　なお、ここでは中規模企業は小規模企業以外の中小企業とし、企業規模区分は中小企業基本法に準ずるものとする。対象となる企業は全て法人であり、比較は中央値で行うものとする。  

（設問1）  
文中の下線部①に関して、1983年度と2016年度について、小規模企業と大企業、中規模企業との労働生産性の規模間格差を比較した場合の記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】<a href="https://www.chusho.meti.go.jp/pamflet/hakusyo/H30/PDF/shokibo/03sHakusyo_part1_chap3_web.pdf" target="_blank">小規模企業白書　企業規模別の労働生産性の推移</a>  
<imgTag>107010021_1.png</imgTag>

</div>
</hint>

ア　小規模企業と大企業、中規模企業との格差はともに拡大している。<hint hidden='true'>**
←⭕適切です。
**</hint>  

イ　小規模企業と大企業、中規模企業との格差はともに縮小している。  

ウ　小規模企業と大企業との格差は拡大、小規模企業と中規模企業との格差は縮小している。  

エ　小規模企業と大企業との格差は縮小、小規模企業と中規模企業との格差は拡大している。  
`
        },
        {
                "mondaiId": 107010022,
                "name": "第2問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
財務省「法人企業統計調査年報」に基づき、1983年度から2016年度の期間について、製造業の労働生産性（従業者一人当たりの付加価値額）を、企業規模別に見た場合、<u>①小規模企業の労働生産性は中規模企業と大企業を下回っている</u>。  

　労働生産性は、資本装備率（従業者一人当たりの有形固定資産）と資本生産性（有形固定資産当たりの付加価値額）に分解できるが、企業規模別に見た場合、小規模企業の資本装備率は中規模企業と大企業より（　Ａ<hint hidden='true'>**
低く
**</hint>  　）、小規模企業の資本生産性は中規模企業と大企業より（　Ｂ<hint hidden='true'>**
高い
**</hint>  　）。しかしながら、近年こうした<u>②規模間格差に変化が見られる</u>ようになっている。  

　なお、ここでは中規模企業は小規模企業以外の中小企業とし、企業規模区分は中小企業基本法に準ずるものとする。対象となる企業は全て法人であり、比較は中央値で行うものとする。  

（設問2）  
　文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】<a href="https://www.chusho.meti.go.jp/pamflet/hakusyo/H30/PDF/shokibo/03sHakusyo_part1_chap3_web.pdf" target="_blank">小規模企業白書　企業規模別の資本整備率の推移</a>  
<imgTag>107010022_1.png</imgTag>

</div>
</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】<a href="https://www.chusho.meti.go.jp/pamflet/hakusyo/H30/PDF/shokibo/03sHakusyo_part1_chap3_web.pdf" target="_blank">小規模企業白書　企業規模別の資本生産性の推移</a>  
<imgTag>107010022_2.png</imgTag>

</div>
</hint>



ア　Ａ：高く　Ｂ：高い  
イ　Ａ：高く　Ｂ：低い  
ウ　Ａ：低く　Ｂ：高い<hint hidden='true'>**
←⭕適切です。
**</hint>    
エ　Ａ：低く　Ｂ：低い
`
        },
        {
                "mondaiId": 107010023,
                "name": "第2問設問3",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
財務省「法人企業統計調査年報」に基づき、1983年度から2016年度の期間について、製造業の労働生産性（従業者一人当たりの付加価値額）を、企業規模別に見た場合、<u>①小規模企業の労働生産性は中規模企業と大企業を下回っている</u>。  

　労働生産性は、資本装備率（従業者一人当たりの有形固定資産）と資本生産性（有形固定資産当たりの付加価値額）に分解できるが、企業規模別に見た場合、小規模企業の資本装備率は中規模企業と大企業より（　Ａ　）、小規模企業の資本生産性は中規模企業と大企業より（　Ｂ　）。しかしながら、近年こうした<u>②規模間格差に変化が見られる</u>ようになっている。  

　なお、ここでは中規模企業は小規模企業以外の中小企業とし、企業規模区分は中小企業基本法に準ずるものとする。対象となる企業は全て法人であり、比較は中央値で行うものとする。  

（設問3）  
文中の下線部②に関して、財務省「法人企業統計調査年報」に基づき、企業規模別に資本装備率と資本生産性を、1983年度と2016年度について比較した場合の記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】<a href="https://www.chusho.meti.go.jp/pamflet/hakusyo/H30/PDF/shokibo/03sHakusyo_part1_chap3_web.pdf" target="_blank">小規模企業白書　企業規模別の資本整備率・資本生産性の推移</a>  
<imgTag>107010023_1.png</imgTag>

</div>
</hint>

ア　小規模企業の資本装備率は上昇し、中規模企業、大企業との規模間格差は縮小している。  

イ　小規模企業の資本装備率は低下し、中規模企業、大企業との規模間格差は拡大している。  

ウ　小規模企業の資本生産性は上昇し、中規模企業、大企業との規模間格差は縮小している。  

エ　小規模企業の資本生産性は低下し、中規模企業、大企業との規模間格差は拡大している。  

オ　小規模企業の<hintTarget>資本生産性は低下</hintTarget>し、中規模企業、大企業との規模間<hintTarget>格差は縮小</hintTarget>している。<hint hidden='true'>**
←⭕適切です。
**</hint>    
`
        },
        {
                "mondaiId": 107010031,
                "name": "第3問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　わが国の企業構造を常用雇用者数と設立年の視点から見る。  
　総務省「平成26年経済センサス－基礎調査」に基づき、常用雇用者数、設立年別に企業数の分布を見た場合（2014年）、常用雇用者数「0～5人以下」の企業が全体の約（　Ａ　）割を占め、「1984年以前」に設立された企業が全体の約（　Ｂ　）割を占めている。  

　また、総務省「平成21年、26年経済センサス－基礎調査」に基づき、2009年から2014年にかけての企業数の変化を見た場合、企業数は約40万者減少しているが、<u>「1984年以前」</u>の設立企業数が減少する一方で、2005年以降の設立企業数は増加している。  

　なお、ここでは企業数は会社数と個人事業者数の合計とする。常用雇用者とは、期間を定めずに雇用されている人もしくは1カ月を超える期間を定めて雇用されている人、または調査対象の前2カ月にそれぞれ18日以上雇用されている人をいう。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　情報雇用者階級別、設立年別企業数  
<imgTag>107010031_1.png</imgTag>

</div>
</hint>

（設問1）  
　文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  

ア　Ａ：4　Ｂ：6  
イ　Ａ：4　Ｂ：8  
ウ　Ａ：5　Ｂ：5  
エ　Ａ：8　Ｂ：4<hint hidden='true'>**
←⭕適切です。
**</hint>      
オ　Ａ：8　Ｂ：6
`
        },
        {
                "mondaiId": 107010032,
                "name": "第3問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

　わが国の企業構造を常用雇用者数と設立年の視点から見る。  
　総務省「平成26年経済センサス－基礎調査」に基づき、常用雇用者数、設立年別に企業数の分布を見た場合（2014年）、常用雇用者数「0～5人以下」の企業が全体の約（　Ａ　）割を占め、「1984年以前」に設立された企業が全体の約（　Ｂ　）割を占めている。  

　また、総務省「平成21年、26年経済センサス－基礎調査」に基づき、2009年から2014年にかけての企業数の変化を見た場合、企業数は約40万者減少しているが、<u>「1984年以前」</u>の設立企業数が減少する一方で、2005年以降の設立企業数は増加している。  

　なお、ここでは企業数は会社数と個人事業者数の合計とする。常用雇用者とは、期間を定めずに雇用されている人もしくは1カ月を超える期間を定めて雇用されている人、または調査対象の前2カ月にそれぞれ18日以上雇用されている人をいう。  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　情報雇用者階級別、設立年別企業数の変化  
<imgTag>107010032_1.png</imgTag>

</div>
</hint>

（設問2）  
文中の下線部に関して、総務省「平成21年、26年経済センサス－基礎調査」に基づき、常用雇用者規模別に、「1984年以前」の設立企業数の変化を見た場合の記述に関する正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　常用雇用者無しの企業数は<hintTarget>横ばい</hintTarget>である。<hint hidden='true'>**
←❌減少しています（▲182）
**</hint>  
ｂ　常用雇用者数1～5人の企業数は<hintTarget>減少</hintTarget>している。<hint hidden='true'>**
←⭕▲200
**</hint>    
ｃ　常用雇用者数300人以上の企業数は<hintTarget>増加</hintTarget>している。<hint hidden='true'>**
←❌減少しています（▲0.2～▲0.02）
**</hint>    

〔解答群〕  
ア　ａ：正　ｂ：正　ｃ：誤  
イ　ａ：正　ｂ：誤　ｃ：正  
ウ　ａ：正　ｂ：誤　ｃ：誤  
エ　ａ：誤　ｂ：正　ｃ：正  
オ　ａ：誤　ｂ：正　ｃ：誤<hint hidden='true'>**
←⭕
**</hint>      

`
        },
        {
                "mondaiId": 107010041,
                "name": "第4問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　総務省「平成26年経済センサス－基礎調査」に基づき、小規模企業について見る。  
個人事業者と法人別に見た場合、<hintTarget>個人事業者</hintTarget>数は小規模企業数全体の約（　Ａ　）割を占めている。  
　また、小規模企業を常用雇用者の有無別に見た場合、「<hintTarget>常用雇用者無し</hintTarget>」の企業数が、「<hintTarget>常用雇用者有り</hintTarget>」の企業数を（　Ｂ　）。  
　さらに、<u>業種別に小規模企業数を見た場合</u>、「常用雇用者無し」の企業数は、小売業、生活関連サービス業、宿泊業・飲食サービス業で多く、これらの業種では経営者とその家族で経営されている企業が多いと考えられる。  
　なお、ここでは企業規模区分は中小企業基本法に準ずるものとする。常用雇用者とは、期間を定めずに雇用されている人もしくは1カ月を超える期間を定めて雇用されている人、または調査対象の前2カ月にそれぞれ18日以上雇用されている人をいう。  

（設問1）  
　文中の空欄ＡとＢに入る数値と語句の組み合わせとして、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】小規模企業白書　小規模事業者数（個人法人別、常用雇用者有無別）  
<imgTag>107010041_1.png</imgTag>

</div>
</hint>

ア　Ａ：4　Ｂ：上回っている  
イ　Ａ：4　Ｂ：下回っている  
ウ　Ａ：6　Ｂ：上回っている  
エ　Ａ：6　Ｂ：下回っている<hint hidden='true'>**←⭕適切です。
** </hint>  
オ　Ａ：8　Ｂ：上回っている
`
        },
        {
                "mondaiId": 107010042,
                "name": "第4問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　総務省「平成26年経済センサス－基礎調査」に基づき、小規模企業について見る。  
個人事業者と法人別に見た場合、<hintTarget>個人事業者</hintTarget>数は小規模企業数全体の約（　Ａ　）割を占めている。  
　また、小規模企業を常用雇用者の有無別に見た場合、「<hintTarget>常用雇用者無し</hintTarget>」の企業数が、「<hintTarget>常用雇用者有り</hintTarget>」の企業数を（　Ｂ　）。  
　さらに、<u>業種別に小規模企業数を見た場合</u>、「常用雇用者無し」の企業数は、小売業、生活関連サービス業、宿泊業・飲食サービス業で多く、これらの業種では経営者とその家族で経営されている企業が多いと考えられる。  
　なお、ここでは企業規模区分は中小企業基本法に準ずるものとする。常用雇用者とは、期間を定めずに雇用されている人もしくは1カ月を超える期間を定めて雇用されている人、または調査対象の前2カ月にそれぞれ18日以上雇用されている人をいう。  

（設問2）  
　文中の下線部に関して、次のａ～ｃの業種別に企業数を見た場合、企業数が多いものから少ないものへと並べた組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ：建設業  
ｂ：小売業  
ｃ：宿泊業・飲食サービス業  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】小規模企業白書　小規模事業者の類型別に見た企業数（業種別）  
<imgTag>107010042_1.png</imgTag>

</div>
</hint>

〔解答群〕  
ア　ａ：建設業　－　ｂ：小売業　－　ｃ：宿泊業・飲食サービス業  
イ　ａ：建設業　－　ｃ：宿泊業・飲食サービス業　－　ｂ：小売業  
ウ　ｂ：小売業　－　ａ：建設業　－　ｃ：宿泊業・飲食サービス業  
エ　ｂ：小売業　－　ｃ：宿泊業・飲食サービス業　－　ａ：建設業<hint hidden='true'>**←⭕適切です。
** </hint>    
オ　ｃ：宿泊業・飲食サービス業　－　ａ：建設業　－　ｂ：小売業
`
        },
        {
                "mondaiId": 107010050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小企業庁「平成29年中小企業実態基本調査（平成28年度決算実績）」に基づき、次のａ～ｃの業種別に中小企業の付加価値比率（売上高に対する付加価値額の割合）を見た場合、付加価値比率が高いものから低いものへと並べた組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ：小売業  
ｂ：宿泊業・飲食サービス業  
ｃ：製造業  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　中小企業の経営指標  
<imgTag>107010050_1.png</imgTag>

</div>
</hint>

〔解答群〕  
ア　ａ：小売業　－　ｂ：宿泊業・飲食サービス業　－　ｃ：製造業  
イ　ａ：小売業　－　ｃ：製造業　－　ｂ：宿泊業・飲食サービス業  
ウ　ｂ：宿泊業・飲食サービス業　－　ａ：小売業　－　ｃ：製造業  
エ　ｂ：宿泊業・飲食サービス業　－　ｃ：製造業　－　ａ：小売業<hint hidden='true'>**
←⭕
** </hint>  
オ　ｃ：製造業　－　ａ：小売業　－　ｂ：宿泊業・飲食サービス業
`
        },
        {
                "mondaiId": 107010061,
                "name": "第6問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。

総務省「国勢調査」、国立社会保障・人口問題研究所「日本の将来推計人口」（平成29年推計、出生中位（死亡中位）推計）に基づき、わが国の<hintTarget>生産年齢人口（15～64歳）</hintTarget>を見た場合、<hintTarget>1995年の約8，700万人</hintTarget>をピークに減少に転じており、2015年には約（　Ａ　）万人まで減少してきている。この傾向は将来にわたって継続すると見込まれ、2060年には、2015年の約（　Ｂ　）割の水準まで減少すると推計されている。

　こうした中で、近年、企業における<u>人手不足も深刻化しつつある</u>。人手不足感が高まる中小企業にとっては、女性やシニア等の潜在的労働力のさらなる掘り起こしや、既存従業員の働き方の工夫を行うことが、一段と重要になってきている。


（設問1）  
　文中の空欄（　Ａ　）と（　Ｂ　）に入る数値の組み合わせとして、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　年齢別人口推計の推移  
<imgTag>107010061_1.png</imgTag>

</div>
</hint>

ア　Ａ：　7，700　　Ｂ：　4  

イ　Ａ：　7，700　　Ｂ：　6<hint hidden='true'>**
←⭕
** </hint>  

ウ　Ａ：　8，200　　Ｂ：　4  

エ　Ａ：　8，200　　Ｂ：　6
`
        },
        {
                "mondaiId": 107010062,
                "name": "第6問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。

総務省「国勢調査」、国立社会保障・人口問題研究所「日本の将来推計人口」（平成29年推計、出生中位（死亡中位）推計）に基づき、わが国の生産年齢人口（15～64歳）を見た場合、1995年の約8，700万人をピークに減少に転じており、2015年には約（　Ａ　）万人まで減少してきている。この傾向は将来にわたって継続すると見込まれ、2060年には、2015年の約（　Ｂ　）割の水準まで減少すると推計されている。

　こうした中で、近年、企業における<u>人手不足も深刻化しつつある</u>。人手不足感が高まる中小企業にとっては、女性やシニア等の潜在的労働力のさらなる掘り起こしや、既存従業員の働き方の工夫を行うことが、一段と重要になってきている。
                                
（設問2）  
文中の下線部に関して、厚生労働省「平成28年上半期雇用動向調査」に基づ  
き、<hintTarget>従業員規模</hintTarget>別、<hintTarget>製造業と非製造業別</hintTarget>に、人材の未充足率を見た場合の記述として、最も適切なものはどれか。  

　なお、ここでは未充足率は、未充足者数を常用労働者数で除して算出する。従業員規模は、「5～29人」「30～99人」「100～299人」「300～999人」「1，000人以上」で比較するものとする。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　年齢別人口推計の推移  
<imgTag>107010062_1.png</imgTag>

</div>
</hint>

ア　規模の大きい企業ほど高い傾向、製造業より非製造業が高い傾向にある。  
イ　規模の大きい企業ほど高い傾向、製造業より非製造業が低い傾向にある。  
ウ　規模の大きい企業ほど低い傾向、製造業より非製造業が高い傾向にある。<hint hidden='true'>**
←⭕
** </hint>  
エ　規模の大きい企業ほど低い傾向、製造業より非製造業が低い傾向にある。
`
        },
        {
                "mondaiId": 107010071,
                "name": "第7問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　中小企業が人手不足に対処していくためには、従業員の多能工化・兼任化や、アウトソーシングの活用といった取り組みに加え、人材育成・能力開発を通じて、従業員が生み出す付加価値を向上させていくことが必要となる。  

　企業による従業員の育成手段は、日常の業務に就きながら行われる教育訓練である「OJT」と、業務命令に基づき、通常の仕事を一時的に離れて行う教育訓練である「①<u>OFF-JT</u>」に大別できる。厚生労働省「平成28年度能力開発基本調査」に基づき、企業がOJTとOFF-JTのいずれを重視しているかについて見た場合、わが国では、企業規模を問わず、OJTを重視する傾向が強い。  

　一方で、②<u>人材育成・能力開発を行う際に感じている課題</u>について見た場合、企業規模によって違いが見受けられることには留意も必要である。  


（設問1）  
　文中の下線部①に関して、厚生労働省「平成28年度能力開発基本調査」に基づき、実施したOFF-JTの内容を次のａ～ｃで見た場合（複数回答）、回答企業割合が高いものから低いものへと並べた組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ：新たに中堅社員となった者を対象とする研修  
ｂ：新規採用者など初任層を対象とする研修  
ｃ：語学・国際化対応能力  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　企業規模別に見た、実施したOFF-JTの内容  
<imgTag>107010071_1.png</imgTag>

</div>
</hint>

〔解答群〕  
ア　ａ：新たに中堅社員となった者を対象とする研修　－　ｂ：新規採用者など初任層を対象とする研修　－　ｃ：語学・国際化対応能力  

イ　ａ：新たに中堅社員となった者を対象とする研修　－　ｃ：語学・国際化対応能力　－　ｂ：新規採用者など初任層を対象とする研修  

ウ　ｂ：新規採用者など初任層を対象とする研修　－　ａ：新たに中堅社員となった者を対象とする研修　－　ｃ：語学・国際化対応能力<hint hidden='true'>**
←⭕
**</hint>  

エ　ｂ：新規採用者など初任層を対象とする研修　－　ｃ：語学・国際化対応能力　－　ａ：新たに中堅社員となった者を対象とする研修  

オ　ｃ：語学・国際化対応能力　－　ａ：新たに中堅社員となった者を対象とする研修　－　ｂ：新規採用者など初任層を対象とする研修
`
        },
        {
                "mondaiId": 107010072,
                "name": "第7問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　中小企業が人手不足に対処していくためには、従業員の多能工化・兼任化や、アウトソーシングの活用といった取り組みに加え、人材育成・能力開発を通じて、従業員が生み出す付加価値を向上させていくことが必要となる。  

　企業による従業員の育成手段は、日常の業務に就きながら行われる教育訓練である「OJT」と、業務命令に基づき、通常の仕事を一時的に離れて行う教育訓練である「①<u>OFF-JT</u>」に大別できる。厚生労働省「平成28年度能力開発基本調査」に基づき、企業がOJTとOFF-JTのいずれを重視しているかについて見た場合、わが国では、企業規模を問わず、OJTを重視する傾向が強い。  

　一方で、②<u>人材育成・能力開発を行う際に感じている課題</u>について見た場合、企業規模によって違いが見受けられることには留意も必要である。  


（設問2）  
　文中の下線部②に関して、厚生労働省「平成28年度能力開発基本調査」に基づき、企業規模別に人材育成・能力開発を行う際に感じている課題について、教える側の人材不足（「指導を行う人材が不足している」）と教えられる側の人材不足（「鍛えがいのある人材が集まらない」）に大別して見た場合の記述として、最も適切なものはどれか。  
なお、ここでは企業規模を、従業員数「30～49人」「50～99人」「100～299人」「300～999人」で比較するものとする。  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　企業規模別に見た、実施したOFF-JTの内容  
<imgTag>107010072_1.png</imgTag>

</div>
</hint>

ア　教える側の人材不足は規模の大きな企業ほど回答割合が高く、教えられる側の人材不足は規模の小さな企業ほど回答割合が高い。<hint hidden='true'>**
←⭕
**</hint>  

イ　教える側の人材不足は規模の大きな企業ほど回答割合が高く、教えられる側の人材不足は規模の小さな企業ほど回答割合が低い。  

ウ　教える側の人材不足は規模の大きな企業ほど回答割合が低く、教えられる側の人材不足は規模の小さな企業ほど回答割合が高い。  

エ　教える側の人材不足は規模の大きな企業ほど回答割合が低く、教えられる側の人材不足は規模の小さな企業ほど回答割合が低い。

`
        },
        {
                "mondaiId": 107010080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小企業においても、新事業展開や商圏拡大等を目的として子会社・関連会社を設立する企業は少なくない。  

　経済産業省「企業活動基本調査」に基づき、国内の子会社・関連会社を設立により当該年度に1社以上増加させた中小企業数（「新設を行った企業数」）と、国内の子会社・関連会社を買収により当該年度に1社以上増加させた中小企業数（「買収を行った企業数」）を、おのおのについて2006年度と2015年度で比較した場合の記述として、最も適切なものはどれか。  

　なお、経済産業省「企業活動基本調査」は、従業員50人未満、資本金または出資金3，000万円未満の企業を対象としていない。  

ア「新設を行った企業数」、「買収を行った企業数」とも減少している。  

<hint hidden='true'>**
❌
**</hint>

イ「新設を行った企業数」、「買収を行った企業数」とも増加している。  

<hint hidden='true'>**
❌
**</hint>

ウ「新設を行った企業数」は減少、「買収を行った企業数」は増加している。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ「新設を行った企業数」は増加、「買収を行った企業数」は減少している。

<hint hidden='true'>**
❌
**</hint>

`
        },
        {
                "mondaiId": 107010091,
                "name": "第9問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　わが国の中小企業の設備投資は緩やかな増加傾向にある。内閣府・財務省「法人企業景気予測調査」に基づき、2007年度と2017年度について、中小企業の投資目的（投資目的別の設備投資スタンス）を比較した場合（複数回答）、「<hintTarget>維持更新</hintTarget>」とする回答割合は（　Ａ　<hint hidden='true'>**
増加
**</hint>  ）、「<hintTarget>生産（販売）能力の拡大</hintTarget>」とする回答割合は（　Ｂ　<hint hidden='true'>**
減少
**</hint>  ）している。  

　また、財務省「法人企業統計調査季報」に基づき、1990年度から2016年度の期間 について、<u>企業規模別設備年齢の推移</u>を見た場合、大企業と中小企業の設備年齢の差は拡大している。  

　なお、ここでは大企業は資本金10億円以上の企業、中小企業は資本金1千万円以上1億円未満の企業とする。  


（設問1）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書
<imgTag>107010091_1.png</imgTag>
</div>
</hint>

ア　Ａ：減少　Ｂ：減少  
イ　Ａ：減少　Ｂ：増加  
ウ　Ａ：増加　Ｂ：減少<hint hidden='true'>**
←⭕
**</hint>    
エ　Ａ：増加　Ｂ：増加
`
        },
        {
                "mondaiId": 107010092,
                "name": "第9問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
                
　次の文章を読んで、下記の設問に答えよ。  

　わが国の中小企業の設備投資は緩やかな増加傾向にある。内閣府・財務省「法人企業景気予測調査」に基づき、2007年度と2017年度について、中小企業の投資目的（投資目的別の設備投資スタンス）を比較した場合（複数回答）、「維持更新」とする回答割合は（　Ａ　）、「生産（販売）能力の拡大」とする回答割合は（　Ｂ　）している。  

　また、財務省「法人企業統計調査季報」に基づき、1990年度から2016年度の期間 について、<hintTarget><u>企業規模別設備年齢の推移</u></hintTarget>を見た場合、大企業と中小企業の設備年齢の差は拡大している。  

　なお、ここでは大企業は資本金10億円以上の企業、中小企業は資本金1千万円以上1億円未満の企業とする。  


（設問2）  
　文中の下線部に関して、財務省「法人企業統計調査季報」に基づき、1990年度から2016年度の期間について、企業規模別設備年齢の推移を見た場合の記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書
<imgTag>107010092_1.png</imgTag>
</div>
</hint>

ア　大企業、中小企業とも上昇傾向にある。<hint hidden='true'>**
←⭕
**</hint>     
イ　大企業、中小企業とも低下傾向にある。  
ウ　大企業は上昇傾向、中小企業は低下傾向にある。  
エ　大企業は低下傾向、中小企業は上昇傾向にある。
`
        },
        {
                "mondaiId": 107010101,
                "name": "第10問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　中小企業にとって、限られた経営資源の補完を図るために、企業間連携は有効な方策として活用されてきた。中小企業庁の委託により実施されたアンケート調査（「成長に向けた企業間連携等に関する調査（2017年11月）」）に基づき、中小企業の企業間連携の実施状況を見た場合、企業間連携を「実施したことがある」と回答した企業割合は約（　Ａ　<hint hidden='true'>**
３
**</hint>）割であり、取組内容としては「共同研究・開発」を挙げる企業割合が最も高い。また、企業間連携で最も重視する連携相手を見ると、（　Ｂ　<hint hidden='true'>**
同業種中小企業
**</hint>）を挙げる企業割合が最も高い。  

　他方で、中小企業が<u>企業間連携に取り組むに当たっては課題も少なくない</u>。支援者にはこうした課題を適切に把握して、解決に向けた助力が期待されている。  

　なお、ここでの「企業間連携」とは、共同研究・開発、販売提携等の業務提携や組合活動等、各社の単独の事業活動では得られない相乗効果を生み出す同業・異業種の複数事業者による共同活動を意味している。  


（設問1）  
　文中の空欄ＡとＢに入る数値と語句の組み合わせとして、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書
<imgTag>107010101_1.png</imgTag>
</div>
</hint>


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書
<imgTag>107010101_2.png</imgTag>
</div>
</hint>


ア　Ａ：　3　　Ｂ：　異業種中小企業  
イ　Ａ：　3　　Ｂ：　同業種大企業  
ウ　Ａ：　3　　Ｂ：　同業種中小企業<hint hidden='true'>**
←⭕
**</hint>  
エ　Ａ：　6　　Ｂ：　異業種中小企業  
オ　Ａ：　6　　Ｂ：　同業種中小企業
`
        },
        {
                "mondaiId": 107010102,
                "name": "第10問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
                
　次の文章を読んで、下記の設問に答えよ。  

　中小企業にとって、限られた経営資源の補完を図るために、企業間連携は有効な方策として活用されてきた。中小企業庁の委託により実施されたアンケート調査（「成長に向けた企業間連携等に関する調査（2017年11月）」）に基づき、中小企業の企業間連携の実施状況を見た場合、企業間連携を「実施したことがある」と回答した企業割合は約（　Ａ　）割であり、取組内容としては「共同研究・開発」を挙げる企業割合が最も高い。また、企業間連携で最も重視する連携相手を見ると、（　Ｂ　）を挙げる企業割合が最も高い。  

　他方で、中小企業が<u>企業間連携に取り組むに当たっては課題も少なくない</u>。支援者にはこうした課題を適切に把握して、解決に向けた助力が期待されている。  

　なお、ここでの「企業間連携」とは、共同研究・開発、販売提携等の業務提携や組合活動等、各社の単独の事業活動では得られない相乗効果を生み出す同業・異業種の複数事業者による共同活動を意味している。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書
<imgTag>107010102_1.png</imgTag>
</div>
</hint>

（設問2）  
　文中の下線部に関して、「成長に向けた企業間連携等に関する調査（2017年11月）」に基づき、実施状況別に企業間連携の課題を見た場合（複数回答）の記述として、最も適切なものはどれか。  

　なお、実施状況は、「実施したことがある（実施企業）」「実施したことはないが今後予定がある（予定企業）」「実施したことがなく今後も予定はない（予定なし企業）」で見るものとし、企業間連携の課題は、「自社の情報や技術・ノウハウ流出の懸念がある」「取組の効果が分からない」「社内で対応できる人材がいない」に大別して比較するものとする。  


ア　実施企業では「社内で対応できる人材がいない」、予定企業では「自社の情報や技術・ノウハウ流出の懸念がある」、予定なし企業では「取組の効果が分からない」の回答企業割合が最も高い。  

イ　実施企業では「社内で対応できる人材がいない」、予定企業では「取組の効果が分からない」、予定なし企業では「自社の情報や技術・ノウハウ流出の懸念がある」の回答企業割合が最も高い。  

ウ　実施企業では「自社の情報や技術・ノウハウ流出の懸念がある」、予定企業では「社内で対応できる人材がいない」、予定なし企業では「取組の効果が分からない」の回答企業割合が最も高い。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ　実施企業では「自社の情報や技術・ノウハウ流出の懸念がある」、予定企業では「取組の効果が分からない」、予定なし企業では「社内で対応できる人材がいない」の回答企業割合が最も高い。  

オ　実施企業では「取組の効果が分からない」、予定企業では「社内で対応できる人材がいない」、予定なし企業では「自社の情報や技術・ノウハウ流出の懸念がある」の回答企業割合が最も高い。

`
        },
        {
                "mondaiId": 107010110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　日本銀行「金融経済統計月報」他による中小企業庁の調べに基づき、<hintTarget>2012年から2017年</hintTarget>の期間について、金融機関別中小企業向け貸出残高の推移を見た場合の記述として、最も適切なものはどれか。  
　なお、中小企業向け貸出残高は、民間金融機関と政府系金融機関の貸出残高の合計。民間金融機関のうち、信用金庫における中小企業向け貸出残高は、個人、地方公共団体、海外円借款、国内店名義現地貸を除く貸出残高。信用組合における中小企業向け貸出残高は、個人、地方公共団体などを含む貸出残高である。政府系金融機関における中小企業向け貸出残高は、商工組合中央金庫、日本政策金融公庫（中小企業事業、国民生活事業）による貸出残高である。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書
<imgTag>107010110_1.png</imgTag>
</div>
</hint>

ア　民間金融機関の貸出残高、政府系金融機関の貸出残高ともに減少傾向である。  

イ　民間金融機関の貸出残高、政府系金融機関の貸出残高ともに増加傾向である。  

ウ　民間金融機関の貸出残高は減少傾向、政府系金融機関の貸出残高は増加傾向である。  

エ　民間金融機関の貸出残高は増加傾向、政府系金融機関の貸出残高は減少傾向である。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 107010120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　厚生労働省「「外国人雇用状況」の届出状況まとめ」によれば、外国人労働者を雇用する事業所の約8割が、従業員規模100人未満の事業所で占められており（2017年10月末）、中小企業においても外国人労働者が活用されていることがうかがえる。  
　同届出状況まとめに基づき、2008年から2017年の期間について（各年10月末現在）、外国人労働者数と外国人労働者を雇用する事業所数の推移を見た場合の記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書
<imgTag>107010120_1.png</imgTag>
</div>
</hint>

ア　外国人労働者数、外国人労働者を雇用する事業所数ともに増加している。<hint hidden='true'>**
←⭕
**</hint>    
イ　外国人労働者数は増加、外国人労働者を雇用する事業所数は減少している。  
ウ　外国人労働者数は増加、外国人労働者を雇用する事業所数は横ばいである。  
エ　外国人労働者数は横ばい、外国人労働者を雇用する事業所数は減少している。  
オ　外国人労働者数は横ばい、外国人労働者を雇用する事業所数は増加している。
`
        },
        {
                "mondaiId": 107010131,
                "name": "第13問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　<u>①中小企業基本法</u>は、中小企業施策について、基本理念・<u>②基本方針</u>等を定めるとともに、国及び地方公共団体の責務等を規定することにより、中小企業施策を総合的に推進し、国民経済の健全な発展及び国民生活の向上を図ることを目的としている。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>


（設問1）  
　
　文中の下線部①に基づく、中小企業者の範囲に関する記述の正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　従業員数120人の教育サービス業（資本金8千万円）は、中小企業に該当する。  

<hint hidden='true'>**
❌従業員数、資本金とも条件を満たしていません。
**</hint>

ｂ　従業員数150人の衣料品卸売業（資本金1億2千万円）は、中小企業に該当する。  

<hint hidden='true'>**
❌従業員数、資本金とも条件を満たしていません。
**</hint>

ｃ　従業員数200人の電子部品製造業（資本金5億円）は、中小企業に該当する。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

〔解答群〕  

ア　ａ：正　ｂ：正　ｃ：誤  
イ　ａ：正　ｂ：誤　ｃ：正  
ウ　ａ：誤　ｂ：正　ｃ：誤  
エ　ａ：誤　ｂ：誤　ｃ：正<hint hidden='true'>**
←⭕
**</hint>    
`
        },
        {
                "mondaiId": 107010132,
                "name": "第13問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
                
　次の文章を読んで、下記の設問に答えよ。  

　<u>①中小企業基本法</u>は、中小企業施策について、基本理念・<u>②基本方針</u>等を定めるとともに、国及び地方公共団体の責務等を規定することにより、中小企業施策を総合的に推進し、国民経済の健全な発展及び国民生活の向上を図ることを目的としている。  

（設問2）  
文中の下線部②は中小企業基本法の第5条に記されている。これに関して、<u>最も不適切なものはどれか</u>。  

ア　経営の革新及び創業の促進を図ること  
イ　経済的社会的環境の変化への適応の円滑化を図ること  
ウ　地域における多様な需要に応じた事業活動の活性化を図ること<hint hidden='true'>**
←⭕
**</hint>    
エ　中小企業の経営基盤の強化を図ること
`
        },
        {
                "mondaiId": 107010141,
                "name": "第14問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　経営革新支援事業は、中小企業者が、経営の向上を図るために経営革新計画の承認を受けると、<u>多様な支援</u>を受けることができるものである。  
　経営革新支援事業に関する下記の設問に答えよ。  

（設問1）  
<hintTarget>経営革新支援事業</hintTarget>に該当する中小企業者の取り組みとして、最も適切なものはどれか。  
<hint hidden='true'>**
経営革新支援事業は、下記いずれかに該当する必要があります。  
・新製品の開発または生産  
・新役務の開発または提供  
・商品の新たな生産または販売の方式の導入  
・役務の新たな提供の方式の導入  
・その他新たな事業活動  
**</hint>  

ア　役務の新たな提供方式の導入に関する取り組み<hint hidden='true'>**
←⭕  
**</hint>    
イ　自社の既存商品の改良に関する取り組み  
ウ　従業員の技能・技術の向上に資する取り組み  
エ　労働条件改善・向上のための取り組み

`
        },
        {
                "mondaiId": 107010142,
                "name": "第14問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　経営革新支援事業は、中小企業者が、経営の向上を図るために経営革新計画の承認を受けると、<u>多様な支援</u>を受けることができるものである。  
　経営革新支援事業に関する下記の設問に答えよ。  

（設問2）  
文中の下線部に関する具体的な支援内容として、<u>最も不適切なものはどれか</u>。  

ア　経営革新に取り組む人材への補助金<hint hidden='true'>**
←⭕
**</hint>   
イ　信用保証の特例  
ウ　政府系金融機関の特別利率による融資制度  
エ　販路開拓コーディネート事業

`
        },
        {
                "mondaiId": 107010151,
                "name": "第15問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　「ものづくり・商業・サービス生産性向上促進補助金」は、生産性向上に資する革新的サービス開発・試作品開発・生産プロセスの改善を行う中小企業・小規模事業者等の設備投資等を支援するものである。認定支援機関のバックアップを得た事業を行う中小企業・小規模事業者等であり、以下の⑴、⑵のいずれかの要件を満たすものが対象となる。  

⑴　（　　）で示された方法で行う革新的なサービスの創出・サービス提供プロセスの改善であり、<u>「付加価値額」及び「経常利益」の一定水準の向上を達成できる計画であること。</u>  

⑵「中小ものづくり高度化法」に基づく特定ものづくり基盤技術を活用した革新的な試作品開発・生産プロセスの改善であり、「付加価値額」及び「経常利益」の一定水準の向上を達成できる計画であること。  

（設問1）  
文中の空欄に入る語句として、最も適切なものはどれか。  

ア　経営力向上計画  
イ　産業競争力強化法  
ウ　中小サービス事業者の生産性向上のためのガイドライン<hint hidden='true'>**
←⭕
**</hint>    
エ　特定ものづくり基盤技術高度化指針
`
        },
        {
                "mondaiId": 107010152,
                "name": "第15問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　「ものづくり・商業・サービス生産性向上促進補助金」は、生産性向上に資する革新的サービス開発・試作品開発・生産プロセスの改善を行う中小企業・小規模事業者等の設備投資等を支援するものである。認定支援機関のバックアップを得た事業を行う中小企業・小規模事業者等であり、以下の⑴、⑵のいずれかの要件を満たすものが対象となる。  

⑴　（　　）で示された方法で行う革新的なサービスの創出・サービス提供プロセスの改善であり、<u>「付加価値額」及び「経常利益」の一定水準の向上を達成できる計画であること。</u>  

⑵「中小ものづくり高度化法」に基づく特定ものづくり基盤技術を活用した革新的な試作品開発・生産プロセスの改善であり、「付加価値額」及び「経常利益」の一定水準の向上を達成できる計画であること。  

（設問2）  
文中の下線部に関する記述として、最も適切なものはどれか。  
ア　1～3年で、「付加価値額」年率3％及び「経常利益」年率1％の向上  
イ　1～3年で、「付加価値額」年率5％及び「経常利益」年率3％の向上  
ウ　3～5年で、「付加価値額」年率3％及び「経常利益」年率1％の向上<hint hidden='true'>**
←⭕
**</hint>    
エ　3～5年で、「付加価値額」年率5％及び「経常利益」年率3％の向上
`
        },
        {
                "mondaiId": 107010161,
                "name": "第16問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　JAPANブランド育成支援事業は、地域中小企業の海外でのブランド確立を図るとともに、地域経済の活性化及び地域中小企業の振興に寄与することを目的としている。  
　この事業の対象や支援内容に関して、下記の設問に答えよ。  

（設問1）  
JAPANブランド育成支援事業の対象者として、<u><u>最も不適切なものはどれか</u></u>。  

ア事業協同組合  
イ商工会  
ウ商工組合  
エ単独の中小企業<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 107010162,
                "name": "第16問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
                
　JAPANブランド育成支援事業は、地域中小企業の海外でのブランド確立を図るとともに、地域経済の活性化及び地域中小企業の振興に寄与することを目的としている。  
　この事業の対象や支援内容に関して、下記の設問に答えよ。  

（設問2）  
JAPANブランド育成支援事業の支援内容として、最も適切なものはどれか。  

ア「戦略策定段階への支援」は、定額補助であり、500万円を上限とする。  

イ「戦略策定段階への支援」は、ブランド戦略策定のための市場調査等である。<hint hidden='true'>**
←⭕
**</hint>    

ウ「ブランド確立段階への支援」は、2分の1補助であり、1，000万円を上限とする。  

エ「ブランド確立段階への支援」は、海外のニーズ等に詳しい外部人材を活用し、ブランドのプロデュース活動を、最大5年間支援する。
`
        },
        {
                "mondaiId": 107010171,
                "name": "第17問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
　
　中小企業診断士のＡ氏は、小規模事業者のＢ氏から、「小規模事業者持続化補助  
金について教えてほしい」との相談を受けた。以下は、Ａ氏とＢ氏との会話の一部  
である。  

Ｂ氏：「小規模事業者持続化補助金について教えてください。」  

Ａ氏：「小規模事業者は、人口減少や高齢化などによる地域の需要の変化に対応していくことが欠かせません。小規模事業者持続化補助金は、小規模事業者のビジネスプランに基づく経営を推進するため、<u>①経営計画</u>を作成し、その経営計画に基づく<u>②取り組み</u>を支援するものです。」  

（設問1）  
文中の下線部①の経営計画に関する記述として、最も適切なものはどれか。  

ア　経営改善支援センターの助言等を受けて作成する。  
イ　商工会・商工会議所の助言等を受けて作成する。<hint hidden='true'>**
←⭕
**</hint>    
ウ　地域の金融機関の助言等を受けて作成する。  
エ　認定支援機関の助言等を受けて作成する。
`
        },
        {
                "mondaiId": 107010172,
                "name": "第17問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `                
　次の文章を読んで、下記の設問に答えよ。  
　
　中小企業診断士のＡ氏は、小規模事業者のＢ氏から、「小規模事業者持続化補助  
金について教えてほしい」との相談を受けた。以下は、Ａ氏とＢ氏との会話の一部  
である。  

Ｂ氏：「小規模事業者持続化補助金について教えてください。」  

Ａ氏：「小規模事業者は、人口減少や高齢化などによる地域の需要の変化に対応していくことが欠かせません。小規模事業者持続化補助金は、小規模事業者のビジネスプランに基づく経営を推進するため、<u>①経営計画</u>を作成し、その経営計画に基づく<u>②取り組み</u>を支援するものです。」  

（設問2）  
文中の下線部②に該当する取り組みとして、最も適切なものはどれか。  
ア雇用調整の取り組み  
イ事業承継の取り組み  
ウ販路開拓の取り組み<hint hidden='true'>**
←⭕
**</hint>    
エ連鎖倒産防止の取り組み  

`
        },
        {
                "mondaiId": 107010181,
                "name": "第18問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　中小企業診断士のＡ氏は、創業を計画しているＢ氏から、「創業資金を借り入れたいので、これに関する支援策を教えてほしい」との相談を受けた。Ｂ氏は、関西地方において食品小売業の創業を予定している。以下は、Ａ氏とＢ氏との会話の一部である。  

Ａ氏：「創業に当たって、雇用の計画はありますか。」  

Ｂ氏：「1名雇用する予定です。」  

Ａ氏：「それでは、日本政策金融公庫の新創業融資制度の利用を検討してはいかがでしょうか。」  

Ｂ氏：「その新創業融資制度の利用に当たっては、何か要件はありますか。」  

Ａ氏：「Ｂさんのビジネスプランについて、日本政策金融公庫が審査をします。ま た、現在お勤めの企業とは異なる業種の創業ですので、<u>①創業時における自己資金に関する要件</u>があります。」  

Ｂ氏：「ビジネスプランは、ほぼ完成しています。創業に備えて、ある程度の自己資金も準備しています。その新創業融資制度について、もう少し詳しく教えてくれますか。��  

Ａ氏：「では、<u>②貸付限度額などの支援内容</u>について説明しましょう。」  

（設問1）  
文中の下線部①に関する具体的な説明として、最も適切なものはどれか。  

ア　創業資金総額の2分の1以上の自己資金を確認できること  
イ　創業資金総額の3分の1以上の自己資金を確認できること  
ウ　創業資金総額の5分の1以上の自己資金を確認できること  
エ　創業資金総額の10分の1以上の自己資金を確認できること<hint hidden='true'>**
←⭕
**</hint>    

`
        },
        {
                "mondaiId": 107010182,
                "name": "第18問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　中小企業診断士のＡ氏は、創業を計画しているＢ氏から、「創業資金を借り入れたいので、これに関する支援策を教えてほしい」との相談を受けた。Ｂ氏は、関西地方において食品小売業の創業を予定している。以下は、Ａ氏とＢ氏との会話の一部である。  

Ａ氏：「創業に当たって、雇用の計画はありますか。」  

Ｂ氏：「1名雇用する予定です。」  

Ａ氏：「それでは、日本政策金融公庫の新創業融資制度の利用を検討してはいかがでしょうか。」  

Ｂ氏：「その新創業融資制度の利用に当たっては、何か要件はありますか。」  

Ａ氏：「Ｂさんのビジネスプランについて、日本政策金融公庫が審査をします。ま た、現在お勤めの企業とは異なる業種の創業ですので、<u>①創業時における自己資金に関する要件</u>があります。」  

Ｂ氏：「ビジネスプランは、ほぼ完成しています。創業に備えて、ある程度の自己資金も準備しています。その新創業融資制度について、もう少し詳しく教えてくれますか。」  

Ａ氏：「では、<u>②貸付限度額などの支援内容</u>について説明しましょう。」  

（設問2）  
文中の下線部②に関して、Ａ氏のＢ氏に対する説明として、最も適切なものはどれか。  

ア　原則として、保証人もしくは担保が必要です。貸付限度額は2，000万円。運転資金の限度額は1，000万円です。  

イ　原則として、保証人もしくは担保が必要です。貸付限度額は3，000万円。運転資金の限度額は1，500万円です。  

ウ　原則として、無担保・無保証人です。貸付限度額は2，000万円。運転資金の限度額は1，000万円です。  

エ　原則として、無担保・無保証人です。貸付限度額は3，000万円。運転資金の限度額は1，500万円です。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 107010190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小企業診断士のＡ氏は、食品製造業（従業員数15人）の経営者のＢ氏から「取引先企業の倒産による連鎖倒産を防止したい」と相談を受けた。そこで、Ａ氏はＢ氏に、「経営セーフティ共済」の愛称を持つ中小企業倒産防止共済制度を紹介することとした。  

この制度に関する、Ａ氏のＢ氏に対する説明として、最も適切なものはどれか。  

ア　共済金の貸付けに当たっては、担保が必要になる場合があります。  

イ　共済金の貸付けは無利子ですが、貸付けを受けた共済金の10分の1に相当する額が掛金総額から控除されます。<hint hidden='true'>**
←⭕
**</hint>    

ウ　対象となる方は、6カ月以上継続して事業を行っている小規模企業者です。  

エ　毎年の掛金の80％は損金に算入できます。
`
        },
        {
                "mondaiId": 107010201,
                "name": "第20問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　海外展開を図る中小企業のＡ社は、海外において自社が取得した産業財産権の侵害を受けている。そこで、現地で権利侵害を受けている状況を把握し、模倣品対策に取り組みたいと考えている。  
　経営者のＡ氏から相談を受けた中小企業診断士のＢ氏は、「模倣品対策支援事業」を紹介することとした。以下は、Ａ氏とＢ氏との会話の一部である。  

Ａ氏：「海外での模倣品対策に取り組みたいのですが、支援施策があれば、ぜひ教えてください。」  

Ｂ氏：「海外で産業財産権の侵害を受けている中小企業が、日本貿易振興機構（JETRO）を通じ、<u>①模倣品対策費用の一部について補助金</u>を受けることができます。」  

Ａ氏：「具体的には、どのようになっていますか。」  

Ｂ氏：「<u>②補助率と補助金には上限があります</u>。」  

（設問1）  
文中の下線部①の補助対象経費として、<u><u>最も不適切なものはどれか</u></u>。  

ア　海外知財訴訟費用保険の契約に関わる費用<hint hidden='true'>**
❌
**</hint>  
イ　現地の行政機関に取締り申請することに関わる費用  
ウ　模倣品業者への警告に関わる費用  
エ　模倣品の製造拠点や流通経路の実態把握に関わる費用  

`
        },
        {
                "mondaiId": 107010202,
                "name": "第20問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　海外展開を図る中小企業のＡ社は、海外において自社が取得した産業財産権の侵害を受けている。そこで、現地で権利侵害を受けている状況を把握し、模倣品対策に取り組みたいと考えている。  
　経営者のＡ氏から相談を受けた中小企業診断士のＢ氏は、「模倣品対策支援事業」を紹介することとした。以下は、Ａ氏とＢ氏との会話の一部である。  

Ａ氏：「海外での模倣品対策に取り組みたいのですが、支援施策があれば、ぜひ教えてください。」  

Ｂ氏：「海外で産業財産権の侵害を受けている中小企業が、日本貿易振興機構（JETRO）を通じ、<u>①模倣品対策費用の一部について補助金</u>を受けることができます。」  

Ａ氏：「具体的には、どのようになっていますか。」  

Ｂ氏：「<u>②補助率と補助金には上限があります</u>。」  

（設問2）  
文中の下線部②に関するＢ氏の説明として、最も適切なものはどれか。  
ア補助率は2分の1以内で、上限額は200万円です。  
イ補助率は2分の1以内で、上限額は400万円です。  
ウ補助率は3分の2以内で、上限額は200万円です。  
エ補助率は3分の2以内で、上限額は400万円です。<hint hidden='true'>**
←⭕
**</hint>  
`
        },
        {
                "mondaiId": 107010211,
                "name": "第21問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　伝統的工芸品産業の支援施策の1つとして、「伝統的工芸品産業支援補助金」があ る。「伝統的工芸品産業の振興に関する法律」（伝産法）に基づく<u>①各種計画</u>の認定を受けた者は、伝統的工芸品産業の振興に関わる<u>②さまざまな支援措置</u>を受けることがで  
きる。  
　なお、ここで伝統的工芸品とは、「伝産法」の規定に基づき、経済産業大臣が指定  
した工芸品のことをいう。  

（設問1）  
文中の下線部①に関する記述として、最も適切なものはどれか。  

ア　「活性化計画」とは、伝統的工芸品産業を支援しようとする者が従事者の後継者の確保及び育成、消費者との交流推進、その他伝統的工芸品の振興を支援する計画である。  

イ　「共同振興計画」とは、他の伝統的工芸品との産地間連携による産業活性化のための意欲的な計画である。  

ウ　「振興計画」とは、産地の特定製造協同組合等が産地全体の振興を図る計画である。<hint hidden='true'>**
⭕適切な記述です。
**</hint>    

エ　「連携活性化計画」とは、産地の特定製造協同組合等が販売組合や個別の販売  
事業者等とともに需要の開拓のために立てる計画である。  
`
        },
        {
                "mondaiId": 107010212,
                "name": "第21問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
                
　次の文章を読んで、下記の設問に答えよ。  

　伝統的工芸品産業の支援施策の1つとして、「伝統的工芸品産業支援補助金」があ る。「伝統的工芸品産業の振興に関する法律」（伝産法）に基づく<u>①各種計画</u>の認定を受けた者は、伝統的工芸品産業の振興に関わる<u>②さまざまな支援措置</u>を受けることがで  
きる。  
　なお、ここで伝統的工芸品とは、「伝産法」の規定に基づき、経済産業大臣が指定  
した工芸品のことをいう。  

（設問2）  
文中の下線部②に関して、計画の認定を受けた事業者は、事業に必要な経費の補助を受けることができる。この支援に該当する事業として、<u><u>最も不適切なものはどれか</u></u>。  

ア　後継者育成  
イ　雇用調整<hint hidden='true'>**
←❌
**</hint>    
ウ　需要開拓  
エ　人材育成・交流支援
`
        },
        {
                "mondaiId": 107010220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　中小製造業のＡ社は、ものづくり基盤技術の高度化に向けた研究開発を行いたいと考えている。中小企業診断士Ｂ氏は、「戦略的基盤技術高度化支援事業」を紹介することとした。  

　この事業に関するＢ氏の説明として、最も適切なものはどれか。  

ア　2者以上の共同体を組んだ取り組みが支援要件に含まれます。<hint hidden='true'>**
←⭕
**</hint>    
イ　5年にわたり基礎研究を支援してくれます。  
ウ　設備投資に関して固定資産税の特例を受けることができます。  
エ　中小企業支援法の認定を受けた企業が対象になります。
`
        },
        {
                "mondaiId": 107010230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　次の文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

　中小企業等の生産性を高めるための政策的な枠組みである「（　Ａ　）」が平成28年7月に施行された。この法律では、生産性向上策（営業活動、財務、人材育成、IT投資等）を業種ごとに「（　Ｂ　）」として策定している。平成31年3月までに製造業の他、卸・小売、外食・中食、旅館業、医療、介護、建設等20分野で策定済みである。  
　支援措置として、金融支援、中小企業等強化税制（即時償却等）、事業承継等に係  
る登録免許税・不動産取得税の特例、業法上の許認可の承継の特例等の法的支援、  
補助金との連動を行っている。  

〔解答群〕  
ア　Ａ：産業競争力強化法　　　Ｂ：産業振興指針  
イ　Ａ：産業競争力強化法　　　Ｂ：事業分野別指針  
ウ　Ａ：中小企業等経営強化法　Ｂ：産業振興指針  
エ　Ａ：中小企業等経営強化法　Ｂ：事業分野別指針<hint hidden='true'>**
←⭕
**</hint> 
`
        },
        {
                "mondaiId": 107010241,
                "name": "第24問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　「地域未来投資促進法による支援」は、地域未来投資促進法に基づき、事業者が、地域の特性を生かして、高い付加価値を創出し、（　Ａ　）の事業者に対する相当の経済的効果を及ぼす（　Ｂ　）を行う際、<u>さまざまな支援措置</u>を受けることができるものである。  

（設問1）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
ア　Ａ：川下　Ｂ：革新的試作品開発事業  
イ　Ａ：川下　Ｂ：地域経済牽引事業  
ウ　Ａ：地域　Ｂ：革新的試作品開発事業  
エ　Ａ：地域　Ｂ：地域経済牽引事業<hint hidden='true'>**
←⭕
**</hint> 
`
        },
        {
                "mondaiId": 107010242,
                "name": "第24問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2019,
                "mondaiSyuId": 107,
                //"imgFile_Q": false,
                //"imgFile_A": false,
                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                "category": [],
                "difficulty": 2,
                
                "mondaiBun": `
　「地域未来投資促進法による支援」は、地域未来投資促進法に基づき、事業者が、地域の特性を生かして、高い付加価値を創出し、（　Ａ　）の事業者に対する相当の経済的効果を及ぼす（　Ｂ　）を行う際、<u>さまざまな支援措置</u>を受けることができるものである。  

（設問2）  
文中の下線部に関する具体的な支援内容として、<u><u>最も不適切なものはどれか</u></u>。  

ア　研究開発から設備投資、販路開拓等まで一体的に支援  

イ　事業のために必要となる設備資金の80％について、無担保無保証かつ低利で融資<hint hidden='true'>**
←❌
**</hint>   

ウ　先進的な事業に必要な設備投資に対する減税措置  

エ　農地転用許可、市街化調整区域の開発許可等に係る配慮

`
        },
        {
                mondaiSyuId: 106,
                "nendo": 2018,
                "mondaiId": 106300010,
                name: "第1問",
                //"assets/img/blank.png",
                //"assets/img/01_06_H30/2-fs8.png",
                mondaiBun:
                        `
パーソナルコンピュータ（PC）の利用においては、業務内容に応じてハードディスクドライブ（HDD）などのさまざまな種類の周辺機器をPC 本体に接続することがある。周辺機器を接続するインタフェースに関する記述として、最も適切なものはどれか。  

ア　e-SATAは、PC本体の電源を切らずに外付けHDDの接続が可能なシリアルインタフェースである。

<hint hidden='true'>**
⭕適切な記述です。
**</hint>          
        
イ　SCSIは、外付けHDD、モデムやマウスの接続が可能な<hintTarget>シリアル</hintTarget>インタフェースである。  

<hint hidden='true'>**
❌SCSIはパラレルインタフェースです
**</hint>
        
ウ　USBは、PC本体の電源を切らずに外付けHDDの接続が可能な<hintTarget>パラレル</hintTarget>インタフェースである。  

<hint hidden='true'>**
❌USBは正式名称Universal Serial Busの名前の通り、シリアルインタフェースです
**</hint>        

**エ**　シリアルATAは、外付けHDD、<hintTarget>モデムやマウス</hintTarget>の接続が可能なインタフェースである。  

<hint hidden='true'>**
❌モデムやマウスの接続に使用するのは「シリアルATA」ではなく「シリアルインタフェース」です
**</hint>

`,
                sentakushi: ["ア", "イ", "ウ", "エ"],
                seikai: "ア",
                //"category": "f111_ハードウェア",
                //keyword: ["シリアル", "パラレル", "USB", 'SATAってPCのやつですか？', 'USBで電源ONで抜差し？'],
                difficulty: 1,
                haiten: 4
        },
        {
                mondaiSyuId: 106,
                "nendo": 2018,
                "mondaiId": 106300020,
                name: "第2問",
                //imgFile_A: true,
                mondaiBun:
                        `
PCではさまざまな種類のソフトウェアが利用されている。PCのソフトウェアに関する以下の①～④の記述と、それらに対応する用語の組み合わせとして、最も適切なものを下記の解答群から選べ。

①　PCの電源投入時に最初に実行され、PCと周辺機器との間の入出力を制御するソフトウェア。

<hint hidden='true'>**
BIOSに関する記述です。PCのソフトウェア構成は下図の下部のようになっており、内側（ハードウェア）から外側の順に実行されます。（ハードウェア起動→BIOS起動→OS(カーネル）の順に起動）  
<imgTag>106300020_1.png</imgTag>  
**</hint>  

②　出荷済みのソフトウェアの不具合を修正・更新するために該当部分だけを書き換えるソフトウェア。

<hint hidden='true'>**
パッチに関する記述です。例えば、PCやスマートホン等のOSには、不具合やセキュリティ上の問題を修正・更新するために、定期的にパッチをインターネット上からダウンロードする機能が備わっています。
**</hint>  

③　著作権は放棄されていないが、誰もが無償で入手・利用でき、ライセンスの範囲であれば、再配布や内容の改良をすることが許可されているソフトウェア。

<hint hidden='true'>**
フリーウェアに関する記述です。ファームウェアは選択肢アの図参照。
**</hint>  

④　単独では機能せず、Webブラウザなどのアプリケーションに組み込むことで、そのアプリケーションの特定の機能を拡張するソフトウェア。

<hint hidden='true'>**
ラグインソフトに関する記述です。例えば、Webブラウザにadobe acrobat readerのプラグインをインストールすると、Webブラウザでpdfを表示できます。
**</hint>  


〔解答群〕  
ア　①：BIOS ②：カーネル ③：ファームウェア ④：ミドルウェア

イ　①：BIOS ②：パッチ 　③：フリーウェア 　④：プラグインソフト

ウ　①：OS 　②：シェル 　③：ファームウェア ④：プラグインソフト

エ　①：OS 　②：パッチ 　③：フリーウェア 　④：ミドルウェア
`,
                sentakushi: ["ア", "イ", "ウ", "エ"],
                seikai: "イ",
                //"category": "f112_ソフトウェア",
                //keyword: ["BIOS", "OS", "ミドルウェア"],
                difficulty: 1,
                haiten: 4
        },

        {
                mondaiSyuId: 106,
                "nendo": 2018,
                "mondaiId": 106300030,
                name: "第3問",

                mondaiBun:
                        `
文字情報を電子化する際の文字コードには、いくつかの種類がある。文字コードの特徴に関する記述として、最も適切なものはどれか。  
  
ア　ASCIIコードは、アルファベット、数字、特殊文字、制御文字および<hintTarget>漢字</hintTarget>から構成される文字コードである。

<hint hidden='true'>**
❌ASCIIコードに漢字は含まれません
**</hint>

イ　EUCは、UNIX OS のために開発されたが、その後<hintTarget>拡張</hintTarget>されて日本語などにも対応できるようになった文字コードである。

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ　Shift-JISコードは、EUC を<hintTarget>拡張</hintTarget>して日本語にも利用できるようにした文字コードである。

<hint hidden='true'>**
❌JISコードを拡張したものです。
**</hint>

エ　UTF-8は、 <hintTarget>2バイト</hintTarget>の文字コードで、英数字と日本語だけではなく、世界の主要な言語で使われるほとんどの文字も表現できる。  

<hint hidden='true'>**
❌UTF-8は4バイトの文字コードです。
**</hint>

`,

                sentakushi: ["ア", "イ", "ウ", "エ"],
                seikai: "イ",
                //"category": "f113_OS",
                //keyword: [],
                difficulty: 2,
                haiten: 4
        },

        //雛形■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        //     {
        //         mondaiSyuId: 106,
        //         "nendo": 2018,
        //         mondaiIdd: 106300030,  //★手動で、d消して、１足して書き換え
        //         name: "第3問",  //★手動で１足して書き換え
        //         
        //         
        //         mondaiBun: 
        // `
        // ★問題文をPDFからコピペ  

        // **ア**　★選択肢記述をPDFからコピペ 

        // **イ**　★選択肢記述をPDFからコピペ 

        // **ウ**　★選択肢記述をPDFからコピペ 

        // **エ**　★選択肢記述をPDFからコピペ  

        // `,
        //         kaisetuBun:
        // `
        // // ★（１）問題文記述をmondaiBunからコピペ   
        // // 　　（２）正解選択肢にマーカ　SPANをコピペ
        // // 　＃（３）必要に応じて
        // // 　　・解説図　＃参考画像執筆用ファイル.pptxで作成して既存画像にコピペで張って作ると楽
        // // 　　・キーワード
        // // 　　　問題マスターにキーワード追加して、索引マスタに執筆
        // //　　　（汎用的な用語で、受験生が疑問に思いそうなところがあれば、既存のkeywordにない場合は）
        // //　　　　ただし詳しい情報はSAKUINにはなるべく書かない方向。sakuinDataはSNSシェアできないので
        // `,

        //         sentakushi: ["ア","イ","ウ","エ"],
        //         seikai: "イ", //★正解をPDFからコピペ
        //         //"category": "ソフトウェア",  //★手動で適切なカテゴリに書き換え
        //         //keyword: [], //★いいキーワードがあれば書き換え。SakuinMasterの方にも書く
        //         difficulty: 2,  //★普通レベルの２以外なら書き換え
        //         haiten: 4
        //     },
        //雛形■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

        {
                mondaiSyuId: 106,
                "nendo": 2018,
                "mondaiId": 106300040,
                name: "第4問",

                //imgFile_A: true,

                mondaiBun:
                        `
下表は、ある中小企業の“アルバイト”表と“店舗”表である。この 2 つの表に対して、次の SQL 文を実行した場合に得る結果として、最も適切なものを下記の解答群から選べ。  

SELECT 氏名,年間給与支給額,店長名  
FROM アルバイト,店舗  
WHERE アルバイト.店舗コード = 店舗.店舗コード  
　AND 店舗所在地 ='新宿'  
　AND 年間給与支給額 < 800000；
  
  
アルバイト  
<TABLE border="1" align="left" width="100%">
<TR>
<TD>氏名</TD>
<TD>住所</TD>
<TD>年間給与支給額</TD>
<TD>店舗コード</TD>
</TR>
<TR>
<TD>青田 太郎</TD>
<TD>新宿</TD>
<TD>800000</TD>
<TD>103</TD>
</TR>
<TR>
<TD>伊田 五郎</TD>
<TD>新宿</TD>
<TD>800000</TD>
<TD>102</TD>
</TR>
<TR>
<TD>東田 史郎</TD>
<TD>市ヶ谷</TD>
<TD>500000</TD>
<TD>103</TD>
</TR>
<TR>
<TD>生田 花子</TD>
<TD>市ヶ谷</TD>
<TD>700000</TD>
<TD>101</TD>
</TR>
<TR>
<TD>西田 哲郎</TD>
<TD>御茶ノ水</TD>
<TD>600000</TD>
<TD>103</TD>
</TR>
<TR>
<TD>北田 圭太</TD>
<TD>新宿</TD>
<TD>650000</TD>
<TD>102</TD>
</TR>
<TR>
<TD>南田 健太</TD>
<TD>新宿</TD>
<TD>600000</TD>
<TD>102</TD>
</TR>
</TABLE>

<BR><BR><BR><BR><BR><BR><BR><BR><BR>
  
店舗  
<TABLE border="1" align="left" width="100%">
<TR>
<TD>氏名</TD>
<TD>住所</TD>
<TD>店舗所在地</TD>
<TD>店舗コード</TD>
</TR>
<TR>
<TD>101</TD>
<TD>御茶ノ水店</TD>
<TD>御茶ノ水</TD>
<TD>高橋 翔太</TD>
</TR>
<TR>
<TD>102</TD>
<TD>市ヶ谷店</TD>
<TD>市ヶ谷</TD>
<TD>小林 慎吾</TD>
</TR>
<TR>
<TD>103</TD>
<TD>新宿店</TD>
<TD>新宿</TD>
<TD>中村 恵子</TD>
</TR>
</TABLE>

<BR><BR><BR><BR><BR>

[解答群]
  
ア  
<TABLE border="1" align="left" width="100%">
<TR>
<TD>氏名</TD>
<TD>年間給与支給額</TD>
<TD>店長名</TD>
</TR>
<TR>
<TD>東田 史郎</TD>
<TD>500000</TD>
<TD>中村 恵子</TD>
</TR>
<TR>
<TD>西田 哲郎</TD>
<TD>600000</TD>
<TD>中村 恵子</TD>
</TR>
</TABLE>
</span>

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

イ  
<TABLE border="1" align="left" width="100%">
<TR>
<TD>氏名</TD>
<TD>年間給与支給額</TD>
<TD>店長名</TD>
</TR>
<TR>
<TD>青田 太郎</TD>
<TD><hintTarget>800000</hintTarget></TD>
<TD>中村 恵子</TD>
</TR>
<TR>
<TD>東田 史郎</TD>
<TD>500000</TD>
<TD>中村 恵子</TD>
</TR>
<TR>
<TD>西田 哲郎</TD>
<TD>600000</TD>
<TD>中村 恵子</TD>
</TR>
</TABLE>

<hint hidden='true'>**
❌WHERE句の３行目の条件（ ８０００００「より」少ない）を満たしていません。
**</hint>

ウ  
<TABLE border="1" align="left" width="100%">
<TR>
<TD>氏名</TD>
<TD>年間給与支給額</TD>
<TD>店長名</TD>
</TR>
<TR>
<TD>青田 太郎</TD>
<TD><hintTarget>800000</hintTarget></TD>
<TD>中村 恵子</TD>
</TR>
<TR>
<TD>伊田 五郎</TD>
<TD><hintTarget>800000</hintTarget></TD>
<TD><hintTarget>小林 慎吾</hintTarget></TD>
</TR>
<TR>
<TD>北田 圭太</TD>
<TD>650000</TD>
<TD><hintTarget>小林 慎吾</hintTarget></TD>
</TR>
<TR>
<TD>南田 健太</TD>
<TD>600000</TD>
<TD><hintTarget>小林 慎吾</hintTarget></TD>
</TR>
</TABLE>

<hint hidden='true'>**
❌WHERE句の３行目の条件（ ８０００００「より」少ない）を満たしていません。  
また、店舗所在地（注：住所ではない）が市ヶ谷の店長名であり、新宿の店長名ではないため、WHERE句の２行目を満たしていません。
**</hint>

エ  
<TABLE border="1" align="left" width="100%">
<TR>
<TD>氏名</TD>
<TD>年間給与支給額</TD>
<TD>店長名</TD>
</TR>
<TR>
<TD>北田 圭太</TD>
<TD>650000</TD>
<TD><hintTarget>小林 慎吾</hintTarget></TD>
</TR>
<TR>
<TD>南田 健太</TD>
<TD>600000</TD>
<TD><hintTarget>小林 慎吾</hintTarget></TD>
</TR>
</TABLE>

<hint hidden='true'>**
❌店舗所在地（注：住所ではない）が市ヶ谷の店長名であり、新宿の店長名ではないため、WHERE句の２行目を満たしていません。
**</hint>

`,

                sentakushi: ["ア", "イ", "ウ", "エ"],
                seikai: "ア", //★正解をPDFからコピペ
                //"category": "f115_SQL",  //★手動で適切なカテゴリに書き換え
                //keyword: ["SQL", "select文"], //★いいキーワードがあれば書き換え。SakuinMasterの方にも書く
                difficulty: 3,  //★普通レベルの２以外なら書き換え
                haiten: 4
        },

        {
                mondaiSyuId: 106,
                "nendo": 2018,
                "mondaiId": 106300050,
                name: "第5問",

                mondaiBun:
                        `
近年、ソースコードが無償で公開されているソフトウェアを用いることで、中小企業においても Web サーバシステムの構築を安価に行えるようになっている。  
以下の記述の空欄Ａ～Ｄに入る用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  
  
・ソースコードが無償で公開されているソフトウェアのことを（ Ａ　<hint hidden='true'>**
OSS
**</hint>）という。このようなソフトウェアを用いることでコストの削減が期待できる。

・ドメイン名とIPアドレスの対応づけのためのシステムを（　B　<hint hidden='true'>**
DNS
**</hint>）というが、これには（ Ａ　<hint hidden='true'>**
OSS
**</hint>）である（ C <hint hidden='true'>**
BIND
**</hint>）が用いられることが多い。

・Webサーバ用ソフトウェアである（ D <hint hidden='true'>**
Apache
**</hint>  ）は（ Ａ　<hint hidden='true'>**
OSS
**</hint>）ある。  
  

[解答群]  
  
ア  　Ａ：OSS   Ｂ：DNS   Ｃ：BIND   Ｄ：Apache<hint hidden='true'>**
←⭕
**</hint>    
  
イ  　Ａ：OSS   Ｂ：NAT   Ｃ：BIND   Ｄ：<hintTarget>Postfix</hintTarget>

<hint hidden='true'>**
Postfixはメールサーバに使用されるOSSです
**</hint>
  
ウ  　Ａ：<hintTarget>PDS</hintTarget>   Ｂ：DNS   Ｃ：<hintTarget>Ubuntu</hintTarget>   Ｄ：Apache  

<hint hidden='true'>**
PDSは、パブリック・ドメイン・ソフトウェアです。PDSもOSSもソフトウェアをユーザがある程度自由に利用できるようにするための公開方式の一つですが、PDSはOSSと異なり、ソースコードの公開が条件ではありません。  
また、UbuntuはOSSのOSです。
**</hint>  
  
エ  　Ａ：PDS   Ｂ：NAT   Ｃ：Ubuntu   Ｄ：Postfix

`,

                sentakushi: ["ア", "イ", "ウ", "エ"],
                seikai: "ア", //★正解をPDFからコピペ
                //"category": "f114_ミドルウェア",  //★手動で適切なカテゴリに書き換え
                //keyword: ["OSS"], //★いいキーワードがあれば書き換え。SakuinMasterの方にも書く
                difficulty: 2,  //★普通レベルの２以外なら書き換え
                haiten: 4
        },
        {
                "mondaiId": 101300010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の2つの図は、2000年以降の日本経済について、左図は賃金と労働生産性  
の推移、右図は労働分配率と営業利益の推移を示している。図中のａ〜ｄに該当す  
るものの組み合わせとして、最も適切なものを下記の解答群から選べ。ただし、  
ａ、ｂ、ｃは2000年の水準を100とした指数である。  

<imgTag>101300010_1.png</imgTag> 

（注）労働生産性は、就業者数に総実労働時間を掛け合わせたマンアワーベース  
の名目GDP。賃金は、労働時間1時間当たりの名目雇用者報酬。  
出所：厚生労働省『平成29年版労働経済白書』  

〔解答群〕  
  
ア  ａ：賃金ｂ：労働生産性ｃ：営業利益ｄ：労働分配率  
  
イ  ａ：賃金ｂ：労働生産性ｃ：労働分配率ｄ：営業利益  
  
ウ  ａ：労働生産性ｂ：賃金ｃ：営業利益ｄ：労働分配率  
  
エ  ａ：労働生産性ｂ：賃金ｃ：労働分配率ｄ：営業利益  

`
        },
        {
                "mondaiId": 101300020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　下図は、1995年度以降の日本の総需要のうち、消費支出、投資支出、政府支出  
の変化（対前年度変化率）の推移を示している。図中のａ〜ｃに該当するものの組み  
合わせとして、最も適切なものを下記の解答群から選べ。  

<imgTag>101300020_1.png</imgTag> 
出所：内閣府ホームページ  

〔解答群〕  
  
ア  ａ：消費支出ｂ：政府支出ｃ：投資支出  
  
イ  ａ：消費支出ｂ：投資支出ｃ：政府支出  
  
ウ  ａ：政府支出ｂ：消費支出ｃ：投資支出  
  
エ  ａ：投資支出ｂ：消費支出ｃ：政府支出  

`
        },
        {
                "mondaiId": 101300030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
景気動向指数には、コンポジット・インデックス（CI）とディフュージョン・イ  
ンデックス（DI）がある。CIとDIによる景気判断に関する記述として、最も適切  
なものはどれか。  
  
ア  CI一致指数が上昇から低下に変わるとき、景気は谷にある。  
  
イ  CI一致指数が上昇しているとき、景気は拡張局面にある。  
  
ウ  DI一致指数が50％未満から50％超へ変わるとき、景気は山にある。  
  
エ  DI一致指数が50％を下回るとき、景気は拡張局面にある。`
        },
        {
                "mondaiId": 101300040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経済を時系列で捉えるときには、名目値と実質値の区別が大切である。これらの  
関係を理解するために、次のような設例を考える。この設例では、商品Ａと商品Ｂ  
の2つがあり、それぞれの価格と生産量は下表のようになる。基準年を2015年と  
するとき、この設例に関する記述として、最も適切なものを下記の解答群から選  
べ。  


<imgTag>101300040_1.png</imgTag> 

〔解答群〕  
  
ア  2017年の実質GDPは、1，980円である。  
  
イ  2017年の物価指数（パーシェ型）は、100になる。  
  
ウ  2017年の物価指数（ラスパイレス型）は、100になる。  
  
エ  2017年の名目GDPは、2，000円である。  
`
        },
        {
                "mondaiId": 101300050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　下記の財政に関わる支出の中で、GDPに含まれるものの組み合わせとして、最も適切なものを下記の解答群から選べ。  

<hint hidden='true'>**
GDPの定義のうち、支出面の定義に基づき正解にたどりつけます。
**
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/101001.png" target="_blank" >
<img src="assets/img/kaisetu/101001.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>  

ａ　移転支出  

ｂ　公的資本形成<hint hidden='true'>**
←⭕
** </hint>  

ｃ　財政投融資  

ｄ　政府最終消費支出<hint hidden='true'>**
←⭕
** </hint>  

〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ<hint hidden='true'>**
←⭕
** </hint>    

`
        },
        {
                "mondaiId": 101300060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マクロ経済循環では貯蓄と投資の均衡が恒等的に成り立つことが知られており、  
これは「貯蓄投資バランス」と呼ばれている。貯蓄投資バランスに関する記述とし  
て、最も適切なものはどれか。  
  
ア  経常収支が黒字で財政収支が均衡しているとき、民間部門は貯蓄超過である。  
  
イ  経常収支の黒字を民間部門の貯蓄超過が上回るとき、財政収支は黒字である。  
  
ウ  国内生産よりも国内需要が少ないとき、経常収支は赤字である。  
  
エ  国内の純貯蓄がプラスであるとき、海外の純資産は減少している。  
`
        },
        {
                "mondaiId": 101300071,
                "name": "第7問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は45度線図である。総需要はAD=C+I（ただし、ADは総需要、Cは消  
費、Iは投資）、消費はC=C0+cY（ただし、C0は基礎消費、cは限界消費性  
向、YはGDP）によって表されるものとする。  
この図に基づいて、下記の設問に答えよ。  


<imgTag>101300071_1.png</imgTag> 

（設問1）  
この図に関する記述として、最も適切なものはどれか。  
  
ア  GDPがY1であるとき、生産物市場にはGHだけの超過需要が生じている。  
  
イ  均衡GDPの大きさはY0であり、このときの総需要の大きさはOHである。  
  
ウ  図中で基礎消費の大きさはOGで表され、これは総需要の増加とともに大き  
くなる。  
  
エ  図中で限界消費性向の大きさは  FG／EFで表され、これは総需要の増加ととも  
に小さくなる。  
`
        },
        {
                "mondaiId": 101300072,
                "name": "第7問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は45度線図である。総需要はAD=C+I（ただし、ADは総需要、Cは消  
費、Iは投資）、消費はC=C0+cY（ただし、C0は基礎消費、cは限界消費性  
向、YはGDP）によって表されるものとする。  
この図に基づいて、下記の設問に答えよ。  


<imgTag>101300072_1.png</imgTag> 

（設問2）  
均衡GDPの変化に関する記述として、最も適切なものはどれか。  
  
ア  限界消費性向が大きくなると、均衡GDPも大きくなる。  
  
イ  限界貯蓄性向が大きくなると、均衡GDPも大きくなる。  
  
ウ  貯蓄意欲が高まると、均衡GDPも大きくなる。  
  
エ  独立投資が増加すると、均衡GDPは小さくなる。  
`
        },
        {
                "mondaiId": 101300081,
                "name": "第8問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、総需要曲線（AD）と総供給曲線（AS）を描いている。この図に基づいて、  
下記の設問に答えよ。  


<imgTag>101300081_1.png</imgTag> 

（設問1）  
総需要曲線（AD）と総供給曲線（AS）の傾きに関する記述として、最も適切なも  
のの組み合わせを下記の解答群から選べ。  

ａ　物価の上昇に伴う実質貨幣供給の減少は、実質利子率の上昇による実質投資  
支出の減少を通じて総需要を縮小させる。ここから、ADは右下がりになる。  

ｂ　物価の上昇に伴う実質貨幣供給の増加は、実質利子率の低下による実質投資  
支出の減少を通じて総需要を縮小させる。ここから、ADは右下がりになる。  

ｃ　物価の上昇に伴う実質賃金率の低下は、労働需要の増加による生産量の増加  
を通じて総供給を拡大させる。ここから、ASは右上がりになる。  

ｄ　物価の上昇に伴う実質賃金率の上昇は、労働需要の縮小による生産量の増加  
を通じて総供給を拡大させる。ここから、ASは右上がりになる。  

〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  

`
        },
        {
                "mondaiId": 101300082,
                "name": "第8問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、総需要曲線（AD）と総供給曲線（AS）を描いている。この図に基づいて、  
下記の設問に答えよ。                
                
<imgTag>101300082_1.png</imgTag> 

(設問2）  
総需要や総供給の変化が実質GDPに及ぼす影響に関する記述として、最も適  
切なものはどれか。  
  
ア  原材料価格の上昇は、ASの左方シフトを通じて実質GDPを縮小させる。  
  
イ  名目貨幣供給の増加は、ADの左方シフトを通じて実質GDPを縮小させる。  
  
ウ  名目賃金率の引き上げは、ADの右方シフトを通じて実質GDPを拡大させ  
る。  
  
エ  労働人口の増加は、ASの左方シフトを通じて実質GDPを拡大させる。  
`
        },
        {
                "mondaiId": 101300091,
                "name": "第9問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図において、IS曲線は生産物市場の均衡、LM曲線は貨幣市場の均衡、BP曲  
線は国際収支の均衡を表す。この経済は小国経済であり、資本移動は完全に自由で  
あるとする。  
この図に基づいて、下記の設問に答えよ。  


<imgTag>101300091_1.png</imgTag> 

（設問1）  
変動相場制の場合における政府支出増加の効果に関する記述として、最も適切  
なものの組み合わせを下記の解答群から選べ。  
ａ為替レートは増価する。  
ｂGDPは増加する。  
ｃ純輸出の減少が生じる。  
ｄ民間投資支出の減少が生じる。  

〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  

`
        },
        {
                "mondaiId": 101300092,
                "name": "第9問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図において、IS曲線は生産物市場の均衡、LM曲線は貨幣市場の均衡、BP曲  
線は国際収支の均衡を表す。この経済は小国経済であり、資本移動は完全に自由で  
あるとする。  
この図に基づいて、下記の設問に答えよ。  


<imgTag>101300092_1.png</imgTag> 

（設問2）  
変動相場制の場合における貨幣供給量増加の効果に関する記述として、最も適  
切なものの組み合わせを下記の解答群から選べ。  
ａ為替レートは増価する。  
ｂGDPは増加する。  
ｃ純輸出の増加が生じる。  
ｄ民間投資支出の増加が生じる。  

〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101300100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
生産者余剰について考える。いま、A〜Eの5つの企業から構成される社会を想  
定する。下図では、それぞれの企業が、生産を継続するために最低限回収しなけれ  
ばならないと考える金額（生産物1単位当たり）が示されている。  
この図に関する記述として、最も適切なものを下記の解答群から選べ。  


<imgTag>101300100_1.png</imgTag> 

〔解答群〕  
  
ア  市場価格が400円を上回れば、5つの企業すべてが生産を行う。  
  
イ  市場価格が600円の場合、3つの企業は生産を行わないので、社会全体の生  
産量は2単位である。  
  
ウ  市場価格が1，400円の場合、社会全体の生産者余剰は1，800円である。  
  
エ  市場価格が1，600円を上回ると、生産を行うのはEのみである。  

`
        },
        {
                "mondaiId": 101300110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、一部の中古品のように、財そのものがマイナスの効用を消費者にもたら  
し、費用をかけて処理されなければならない場合を描いている。  
この図に関する記述として、最も適切なものを下記の解答群から選べ。  


<imgTag>101300110_1.png</imgTag> 

〔解答群〕  
  
ア  価格が負の値をとる領域では、お金を支払って中古品を出す家計の行動を需  
要曲線Dが、お金を受け取り中古品回収を行う業者の行動を供給曲線Sが示  
している。  
  
イ  供給曲線S上で点Cから点Bの方への動きがあるとき、中古品の処理費用  
が高くなり、家計が中古品を出さなくなっていることがわかる。  
  
ウ  均衡が点Cである場合、この中古品は経済財となる。  
  
エ  この中古品のリサイクルに関する技術進歩があると、同じ処理費用で多くの  
中古品を処理できるので、需要曲線Dが右方シフトする。  

`
        },
        {
                "mondaiId": 101300120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図でDAとDBは、それぞれ商品Ａと商品Ｂの需要曲線である。このとき、商  
品Ａと商品Ｂの需要の価格弾力性に関する記述として、最も適切なものの組み合わ  
せを下記の解答群から選べ。  



<imgTag>101300120_1.png</imgTag> 


ａ価格がP0から下がると、商品Ａの需要の価格弾力性は大きくなる。  
ｂ価格がP0から上がると、商品Ｂの需要の価格弾力性は大きくなる。  
ｃ点Eでは、商品Ａの需要の価格弾力性は商品Ｂの需要の価格弾力性よりも大  
きい。  
ｄ点Eでは、商品Ａの需要の価格弾力性は商品Ｂの需要の価格弾力性よりも小  
さい。  

〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101300130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、独占企業が生産する財の需要曲線D、限界収入曲線MR、限界費用曲線  
MCを示している。この図に関する記述として、最も適切なものの組み合わせを下  
記の解答群から選べ。  


<imgTag>101300130_1.png</imgTag> 

ａ独占企業が利潤を最大にするとき、完全競争を想定した場合と比較して、消費  
者余剰は減少する。  
ｂ独占企業が利潤を最大にするとき、完全競争を想定した場合と比較して、総余  
剰は増加する。  
ｃ独占企業の利潤が最大になる生産量はQ1であり、そのときの価格はP1である。  
ｄ独占企業の利潤が最大になる生産量はQ1であり、そのときの価格はP2である。  


〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101300140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、労働市場の需要曲線と供給曲線を示している。Dは労働需要曲線、Sは  
労働供給曲線であり、均衡賃金率はW0である。労働市場における所得分配に関す  
る記述として、最も適切なものを下記の解答群から選べ。  


<imgTag>101300140_1.png</imgTag> 

〔解答群〕  
  
ア  最低賃金率をW1に設定すると、市場均衡と比較して、企業の余剰は増加す  
る。  
  
イ  市場均衡において企業が労働者に支払う賃金は□OBEN0である。  
  
ウ  市場均衡における労働者の余剰は、△AW0Eである。  
  
エ  労働供給が増加すると、当初の市場均衡と比較して、企業の余剰は増加す  
る。
`
        },
        {
                "mondaiId": 101300150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                "kaitouToukeiBase": [5, 3, 4, 2, 1],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費税の課税については、価格、取引量の変化や税収の金額に加えて、実際に税  
金を負担するのは誰かという問題も重要となる。下図では、供給の価格弾力性が無  
限大である場合を考える。ここで、生産物1単位当たりT円の課税を行うと、供  
給曲線S0は新しい供給曲線S1へとシフトする。また、需要曲線はDである。  
この図に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

<imgTag>101300150_1.png</imgTag> 


ａ消費税の課税により、市場価格はP0からP1に上昇し、取引量はQ0からQ1  
に減少する。  
ｂ消費税の課税を行うと、消費者余剰は△AEP0から、△EFGの分だけ減少する。  
ｃ消費税の課税を行うと、税負担の一部が生産者に転嫁される。  
ｄ消費税の課税により、政府に入る税収は、□P1FGP0である。  

〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｃ  
  
オ  ｃとｄ  

`
        },
        {
                "mondaiId": 101300160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
外部不経済について考える。いま、マンションの建設業者と周辺住民が、新しい  
マンションについて交渉を行う。ここでは、周辺住民が地域の環境資源の利用権を  
持っているとする。マンションの建設によって、地域環境の悪化という外部不経済  
が発生するので、マンションの建設業者は補償金を周辺住民に支払うことで問題を  
解決しようとする。  
下図には、需要曲線、私的限界費用曲線、社会的限界費用曲線が描かれている。  
この図に関する記述として、最も適切なものを下記の解答群から選べ。  


<imgTag>101300160_1.png</imgTag> 


〔解答群〕  
  
ア  資源配分が効率化する生産水準において、マンションの建設業者が補償金と  
して支払う総額は□BFHCである。  
  
イ  マンションの建設による外部不経済下の市場均衡において、外部費用は  
□BFHCで示される。  
  
ウ  マンションの価格と、マンションの建設による社会的限界費用は、生産量が  
Q0のもとで等しくなる。  
  
エ  マンションの建設による外部不経済が発生しているもとでの生産量はQ0に  
なり、総余剰は△AECで示される。  
`
        },
        {
                "mondaiId": 101300170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、所得と財の需要量の関係を表すエンゲル曲線が描かれている。これら  
の図に関する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101300170_1.png</imgTag> 


〔解答群〕  
  
ア  下級財に関するエンゲル曲線は右図である。  
  
イ  コメなどの食料品は、一般的に生活必需品であるので、右図のようなエンゲ  
ル曲線を描くことができる。  
  
ウ  左図のエンゲル曲線は、需要の増加の程度が、所得の増加の程度より小さく  
なることを示している。  
  
エ  左図は、需要の所得弾力性が1より小さい場合のエンゲル曲線である。  
  

`
        },
        {
                "mondaiId": 101300181,
                "name": "第18問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
生産においては、生産要素を効率的に投入することが重要である。下図では、等  
産出量曲線と等費用線を用いて、最適な生産要素の投入量を考える。  
この図に基づいて、下記の設問に答えよ。  

<imgTag>101300181_1.png</imgTag> 


（設問1）  
この図に関する記述として、最も適切なものはどれか。  
  
ア  点Aと点Bは、労働と資本の投入による費用は同じであるが、生産量が異  
なる。  
  
イ  点Dでは、労働と資本の投入による費用は点Bより少ないが、生産量は多  
くなっている。  
  
ウ  点Fでは、点Dよりも資本の投入が少なく、労働の投入が多いので、費用  
が少なくてすむ。  
  
エ  費用を一定とした場合、点Aでは、労働の投入を増加させ、資本の投入を  
減少させることによって、生産量が増加する余地がある。  

`
        },
        {
                "mondaiId": 101300182,
                "name": "第18問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
生産においては、生産要素を効率的に投入することが重要である。下図では、等  
産出量曲線と等費用線を用いて、最適な生産要素の投入量を考える。  
この図に基づいて、下記の設問に答えよ。  

<imgTag>101300182_1.png</imgTag> 

（設問2）  
この図においては、点Eで生産要素の最適投入が実現している。点Eに関す  
る記述として、最も適切なものはどれか。  
  
ア  点Eでは、点Bと同じ量を生産する場合の要素費用最小化が実現している。  
  
イ  点Eでは、労働と資本について要素価格1単位当たりの限界生産物が均等  
化している。  
  
ウ  点Eにおいては、点Aと技術的限界代替率が同じであるが、労働と資本の  
要素価格比率が異なっている。  
  
エ  点Eにおける技術的限界代替率は、点Dと比べると大きく、点Fと比べる  
と小さい。  
`
        },
        {
                "mondaiId": 101300190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、生産と費用の関係を描いたものである。ここでは、ある生産要素の投入  
は変化するが、他の生産要素の投入は変化しない、つまり、少なくとも1つは固定  
的インプットが存在する短期の費用関数を考える。  
この図に関する記述として、最も適切なものの組み合わせを下記の解答群から選  
べ。  


<imgTag>101300190_1.png</imgTag> 


ａ原点Oと点Bを結んだ線の傾きによって、平均費用が求まる。  
ｂ総費用曲線を見ると、点Dから点Aまでは費用逓増型、点Aから右は費用逓  
減型となっている。  
ｃ点Eでは、平均費用と限界費用が一致する。  
ｄ平均費用が最小になる生産量より、平均可変費用が最小になる生産量の方が少  
ない。  



〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｃとｄ  
`
        },
        {
                "mondaiId": 101300200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、自由貿易地域の理論を描いたものである。自国が農産物の市場を開放  
し、貿易を行っている。Ａ国から輸入される農産物の価格はPA、Ｂ国から輸入さ  
れる農産物の価格はPBとする。  
当初、自国は、価格の低いＡ国から農産物を輸入し、その農産物には関税がか  
かっていた。そのときの国内価格はPA'である。しかしながら、歴史的な背景か  
ら、自国はＢ国と自由貿易協定を締結した。その結果、Ｂ国からの農産物には関税  
がかからず、国内価格はPBになるが、域外のＡ国からの農産物には関税がかかる。  
この図に関する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101300200_1.png</imgTag> 


〔解答群〕  
  
ア  関税が賦課された価格PA'に比べて、自由貿易協定を締結した後の価格PB  
では、自国の消費者余剰は□HMNKだけ大きくなっている。  
  
イ  自国がＢ国から農産物を輸入するときの国内の生産者余剰は、Ａ国から農産  
物を輸入していたときの生産者余剰よりも□PAGNPBの分だけ小さくなる。  
  
ウ  自由貿易協定の締結によって、自国が失う関税収入は、□HIJKである。  
  
エ  自由貿易協定の締結による貿易創造効果は、△HLMと△KNRであり、貿  
易転換効果は、□HLRKである。  

`
        },
        {
                "mondaiId": 101300210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
寡占市場においては、ライバル店の動きを見ながら、価格を設定することが重要  
である。下表では寡占市場における価格競争のゲームについて考える。Ａ店とＢ店  
の戦略は、高価格と低価格であるとする。  
両者が異なる価格を設定する場合、低価格にした店は、すべての顧客を得て20  
の利潤を得ることができるが、高価格を提示した店は顧客を得ることができず、利  
潤は0となる。また、両者が低価格にする場合は、この価格で得られる市場全体の  
利潤20を半分ずつシェアする。さらに両者が高価格にする場合は、市場全体の利  
潤は32となり、各店はそれぞれ16の利潤を得る。カッコ内の左側がＡ店の利潤、  
右側がＢ店の利潤を示す。  
このゲームに関する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101300210_1.png</imgTag> 

〔解答群〕  
  
ア  このゲームからは、2つの店が価格競争を行うと互いにメリットがあること  
が分かる。  
  
イ  このゲームで、Ａ店とＢ店がともに低価格にする場合、どちらか一方の店が  
価格を高価格に変更すると、その店の利潤は減少する。  
  
ウ  このゲームでは、Ａ店とＢ店が異なる価格をつける2つの場合がナッシュ均  
衡である。  
  
エ  このゲームにおけるＡ店とＢ店の最適反応は、ともに高価格にする場合であ  
る。`
        },

        {
                "mondaiId": 106300060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　Web環境におけるソフトウェア開発においては、開発目的に応じて利用可能なさまざまなプログラミング言語などを組み合わせて実現していくことが必要になる。以下の①～④の記述と、それらに対応するプログラミング言語などの組み合わせとして、最も適切なものを下記の解答群から選べ。  

①HTMLファイルの中で記述され、動的なWebページを作成することができる。<hint hidden='true'>**
←JavaScriptに関する記述です  
** </hint>  

②データベースと連携したWebページを作成することができる。<hint hidden='true'>**
←PHPに関する記述です  
** </hint>  

③Webサーバと非同期通信を行うことで、Webページの一部分のみのデータ内容を動的に更新することができる技術である。<hint hidden='true'>**
←Ajaxに関する記述です  
** </hint>    

④Webページのフォントや文字の大きさ、行間、表示位置の指示など、表示方法に関する事項を定義するために利用する。<hint hidden='true'>**
←CSSに関する記述です  
** </hint>  
  

<hint hidden='true'>**
参考：それぞれの言語の特徴は以下のとおりです。  
・ JavaScript： HTMLファイルの中で記述され、動的なWebページを作成することができる。  
・ PHP：　データベースと連携したWebページを作成することができる。  
・ Ajax：　Asynchronous JavaScript＋XMLの略。JavaScriptというプログラム言語とXMLを用いて、Webサーバと非同期通信を行うことで、Webページの一部分のみのデータ内容を動的に更新することができる。  
・ CSS：　Webページのフォントや文字の大きさ、行間、表示位置の指示など、表示方法に関する事項を定義するために利用する。  
**</hint>

〔解答群〕  
  
ア  ①：Java　②：jQuery　③：Perl　④：CSS  
  
イ  ①：Java　②：PHP　③：Perl　④：XSL  
  
ウ  ①：JavaScript　②：jQuery　③：Ajax　④：XSL  
  
エ  ①：JavaScript　②：PHP　③：Ajax　④：CSS<hint hidden='true'>**
←⭕
** </hint>  

`
        },
        {
                "mondaiId": 106300070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
スマートフォンやタブレットなどの携帯端末は、外出先での業務用端末としても  
利用されている。その利用に際しては、安全かつ効率的な管理が求められている。  
この管理のための記述として、最も適切なものはどれか。  
  
ア  MDMとは、組織における携帯端末の運用を一元的に管理することである。  
  
イ  デジタル署名とは、利用者本人を認証するために利用者の身体的特徴や行動上  
の特徴を用いるものである。  
  
ウ  リモートロックとは、遠隔操作によって携帯端末のデータを消去することである。  
  
エ  リモートワイプとは、遠隔操作によって携帯端末の操作を制限することである。`
        },
        {
                "mondaiId": 106300080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある中小企業では、売上記録のリレーショナルデータベース化を検討している。  
次の表を第3正規形まで正規化を行った場合、いくつの表に分割されるか。最も  
適切なものを下記の解答群から選べ。  

<imgTag>106300080_1.png</imgTag>  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】正規化
正規化とは、リレーショナルデータベースにおいて、データの冗長・重複を排除し、データの不整合を起こりにくくするためにテーブル構造を最適化する事を言います。  
非正規形を、第1，第2，第3正規形まで最適化すると良いとされています。  
  
参考：非正規形の例   
<img src="https://ferret-one.akamaized.net/images/5c457be0979afc6384002888/original.png?public=true"/>
<a href="https://oss-db.jp/dojo/dojo_info_04" target="_blank">  
(外部リンクを開きます　OSS-DB：データベースの正規化)</a>  
</div>
</hint>  

〔解答群〕  
  
ア  3<hint hidden='true'>**
←⭕
**</hint>    
  
イ  4  
  
ウ  5  
  
エ  6  
`
        },
        {
                "mondaiId": 106300090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
通信ネットワーク上では多様なプロトコルが用いられており、代表的なプロトコ  
ルについて理解しておくことは、中小企業の情報ネットワーク化においても重要で  
ある。通信プロトコルに関する以下の①～④の記述と、それらに対応する用語の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  
①クライアントからサーバにメールを送信したり、サーバ間でメールを転送した  
りするために用いられる。  

②ネットワークに接続する機器にIPアドレスなどを自動的に割り当てるために  
用いられる。  
③ネットワークに接続されている機器の情報を収集し、監視や制御を行うために  
用いられる。  
④ネットワークに接続されている機器の内部時計を協定世界時に同期するために  
用いられる。  

〔解答群〕  
  
ア  ①：IMAP②：DHCP③：PPP④：NTP  
  
イ  ①：IMAP②：FTP③：SNMP④：SOAP  
  
ウ  ①：SMTP②：DHCP③：SNMP④：NTP  
  
エ  ①：SMTP②：FTP③：PPP④：SOAP  
`
        },
        {
                "mondaiId": 106300100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
社外から、機密情報を持つ社内ネットワーク内のDBサーバへ安全にアクセスす  
る仕組みに関する以下の文章の空欄Ａ～Ｄに入る語句の組み合わせとして、最も適  
切なものを下記の解答群から選べ。 

自宅や出張先から社内ネットワークに安全に接続するにはＡを利用す  
る方法がある。  

別のやり方として、Ｂによって社内ネットワークを内部セグメントと  
Ｃに分ける方法もある。この場合、機密情報を持つDBサーバは内部セ  
グメントに設置し、Ｃに設置するWebサーバを経由してアクセスす  
る。Ｂのパケットフィルタリングは、Ｄにおいて通信データに  
含まれる情報を検査し、フィルタリング設定にそぐわないパケットを遮断する。  

〔解答群〕  
  
ア  Ａ：VPN　Ｂ：SSH　Ｃ：LAN　Ｄ：ハブ  
  
イ  Ａ：VPN　Ｂ：ファイアウォール　Ｃ：DMZ　Ｄ：ルータ  
  
ウ  Ａ：イントラネット　Ｂ：SSH　Ｃ：LAN　Ｄ：ルータ  
  
エ  Ａ：イントラネット　Ｂ：ファイアウォール　Ｃ：DMZ　Ｄ：ハブ
`
        },
        {
                "mondaiId": 106300110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報システムの評価指標に関する記述として、最も適切なものはどれか。  
  
ア　MTBF  
　＿＿＿＿＿
　MTBF+MTTR  
の値が大きいほど、可用性が高いと言える。  
  
イ  MTBFの値が小さいほど、信頼性が高いと言える。  
  
ウ  MTTFの値が小さいほど、機器の寿命が長いと言える。  
  
エ  MTTRの値が大きいほど、保守性が高いと言える。  
`
        },
        {
                "mondaiId": 106300120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
QRコードは、中小企業でも商品の検品・棚卸、決済などの業務に利用できる。  
QRコードに関する記述として最も適切なものはどれか。  
  
ア  コードの一部に汚れや破損があっても元のデータを復元できる。  
  
イ  数字だけではなく英字やひらがなのデータを格納できるが、漢字のデータは格  
納できない。  
  
ウ  スマートフォンやタブレットなどの携帯端末で実行できるプログラムである。  
  
エ  無線通信を用いてデータを非接触で読み取ることができる。`
        },
        {
                "mondaiId": 106300130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
検索エンジンによる情報収集では、「フィルターバブル」と呼ばれる弊害も指摘さ  
れている。フィルターバブルに関する記述として、最も適切なものはどれか。  
  
ア  虚偽の情報から作られたニュースがまん延することで、利用者の正しい判断を  
阻害することが懸念されている。  
  
<hint hidden='true'>**
❌フェイクニュースに関する記述です。
**</hint>

イ  検索結果の記事に広告を自然に溶け込ませて提示するために、利用者の情報収  
集が妨げられることが懸念されている。  
  
<hint hidden='true'>**
❌ネイティブ広告と言われる、記事のように見せかけた広告です。  
類似の手法として、ステルスマーケティングという手法があり、こちらはSNSに有名人が商品の宣伝を通常の書き込みに見せかけて投稿し、口コミ効果による広告を行います。
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】ネイティブ広告の例  
<img src="https://store.boxil.jp/media/images/uploads/media_image/media_image/35821/large.jpg"/>
<a href="https://boxil.jp/mag/a927/" target="_blank">  
(外部リンクを開きます　BOXIL：5分でわかる「ネイティブ広告」)</a>  
</div>
</hint>  

ウ  不自然な外部リンクを増やすなどして検索結果の表示順序を意図的に操作でき  
るために、必要な情報にたどり着くことが困難になることが懸念されている。  
  
<hint hidden='true'>**
❌ブラックハットSEOに関する記述です。
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】ブラックハットSEOのイメージ  
<img src="https://ferret.akamaized.net/images/5ac6da5afafbd84fec000174/original.png?1522981465"/>
<a href="https://ferret-plus.com/curriculums/1924" target="_blank">  
(外部リンクを開きます　ferret：ブラックハットSEOとホワイトハットSEO)</a>  
</div>
</hint>  

エ  利用者の過去の検索履歴などに応じた情報を優先的に提示する傾向があるため  
に、利用者の目に触れる情報に偏りの生じることが懸念されている。  


<hint hidden='true'>**
⭕適切な記述です。
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】フィルターバブルのイメージ。GOOGLEやYAHOO NEWSが「YOU」の嗜好に合わせた青色の情報だけ見えるようにフィルターしている。  
<img src="https://miro.medium.com/max/1000/1*8b3CR37vzN8NQdJxXSP7Dw.jpeg"/>
<a href="http://www.kensetsu-plaza.com/kiji/post/29152" target="_blank">  
(外部リンクを開きます　taiki：What is the Filter Bubble? Privacyについて考える)</a>  
</div>
</hint>  


`
        },
        {
                "mondaiId": 106300140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ビッグデータの時代では、デジタルデータを介してヒトやモノを結ぶネットワー  
クが急激に拡大していく現象が見られる。ネットワークに関する記述として、最も  
適切なものはどれか。  
なお、ノードとはネットワークの結節点、あるノードの次数とはそのノードと他  
のノードを結ぶ線の数を意味する。  
  
ア  次数分布がべき乗則に従う、インターネットなどで見られるスケールフリー・  
ネットワークには、ハブと呼ばれる次数の大きなノードが存在する。  
  
イ  ブロックチェーンとは、Web上に仮想的な金融機関を置き、金融取引の履歴  
をWeb上のデータベースに一元管理するネットワークをいう。  
  
ウ  メトカーフの法則は、デジタルデータの爆発的な増大を背景に、ノードの増加  
と共に価値が指数関数的に増えていく状況を表している。  
  
エ  リンクポピュラリティは、ネットワーク分析で使う指標の1つで、あるノード  
を通る経路が多いほど大きくなる。  
`
        },
        {
                "mondaiId": 106300150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
売り手と買い手間の受発注処理などで、EDI（ElectronicDataInterchange）を利  
用することが少なくない。EDIの情報伝達規約に関する記述として、最も適切な  
ものはどれか。  
  
ア  シンタックスルールや標準メッセージのフォーマットに関する取り決めを行う。  
  
イ  通信暗号化や障害時の対応など、セキュリティ面に関する取り決めを行う。  
  
ウ  メッセージ送受信のタイミングなど、システム運用に関する取り決めを行う。  
  
エ  メッセージを送受信するための通信プロトコルに関する取り決めを行う。`
        },
        {
                "mondaiId": 106300160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
CMMI（CapabilityMaturityModelIntegration）を使って、ソフトウェア開発組  
織の成熟度を5段階のレベルで表現し、開発プロセスの改善に役立てることができ  
る。CMMIに関する記述として、最も適切なものはどれか。  
  
ア  CMMIで、各プロセスの特性が明確化されるとともに標準化が進み、「定義さ  
れた」状態になるのは第3レベルである。  
  
イ  CMMIの第2レベルは、各プロセスがいまだアドホックかつ場当たり的で「不  
完全な」状態として認識される。  
  
ウ  CMMIはISOが定めた国際規格で、要求事項に合致したシステムを構築する  
と、各レベルに応じた認証を取得することができる。  
  
エ  CMMIは、システム・ライフサイクル全体を構想、開発、運用、保守、廃棄  
のプロセスに分類し、それぞれのレベルを評価するモデルである。  
`
        },
        {
                "mondaiId": 106300170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A社は自社の業務システムを全面的に改訂しようとしている。候補に挙がって  
いるいくつかのITベンダーの中からシステム開発先を決定したい。Ａ社がITベ  
ンダーに出す文書に関する記述として、最も適切なものはどれか。  
  
ア  RFIとは、自社が利用可能な技術などをベンダーに伝え、システム開発を依頼  
する文書をいう。  
  
イ  RFIとは、システムが提供するサービスの品質保証やペナルティに関する契約  
内容を明らかにし、システム開発を依頼する文書をいう。  
  
ウ  RFPとは、システムの概要や主要な機能などに関する提案を依頼する文書を  
いう。  
  
エ  RFPとは、システムライフサイクル全体にわたる、システム開発および運用  
にかかるコスト見積もりを依頼する文書をいう。`
        },
        {
                "mondaiId": 106300180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ソフトウェア開発では、仕様の曖昧さなどが原因で工数オーバーとなるケースが  
散見される。開発規模の見積もりに関する記述として、最も適切なものはどれか。  
  
ア  CoBRA法では、開発工数は開発規模に比例することを仮定するとともに、さ  
まざまな変動要因によって工数増加が発生することを加味している。  
  
イ  LOC法では、画面や帳票の数をもとに開発規模を計算するため、仕様書が完  
成する前の要件定義段階での見積もりは難しい。  
  
ウ  標準タスク法は、ソフトウェアの構造をWBS（WorkBreakdownStructure）  
に分解し、WBSごとに工数を積み上げて開発規模を見積もる方法である。  
  
エ  ファンクション・ポイント法は、システムのファンクションごとにプログラ  
マーのスキルを数値化した重みを付けて、プログラム・ステップ数を算出する。  
`
        },
        {
                "mondaiId": 106300190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報システムを構築する上で、対象業務の最適化のみならず、企業全体にわたる  
業務とシステムの最適化を図ることが重要とされている。そのための手法として、エ  ンタープライズアーキテクチャ（EA）が提唱されている。  

EAのビジネスアーキテクチャに関する記述として、最も適切なものはどれか。  
  
ア  各業務において利用されるデータの内容やデータ間の関連性を体系化するもの  
で、その結果、E－R図などが作成される。  
  
イ  共通化・合理化などを行った実現すべき業務の姿を体系化するもので、その結  
果、機能構成図や業務フローなどが作成される。  
  
ウ  業務処理に最適な情報システムの形態を体系化するもので、その結果、情報シ  
ステム関連図などが作成される。  
  
エ  システムを構築する際に利用するもろもろの技術的構成要素を体系化するもの  
で、その結果、ネットワーク構成図などが作成される。  
`
        },
        {
                "mondaiId": 106300200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　下図は、ある中小企業Ａ社のプロジェクト管理に関する現行の業務の一部をUMLのクラス図として描いたものである。【属性に関する前提】と【凡例】を参考にしつつ、この図の解釈として最も適切なものを下記の解答群から選べ。  

<imgTag>106300200_1.png</imgTag>  

【属性に関する前提】  
・「担当可能な役割」の値は、「主任」あるいは「アシスタント」のいずれかである。  
・「主任の担当時間」の値は、各プロジェクトに投入する主任の担当時間数である。  
・「アシスタントの担当時間」の値は、各プロジェクトに各アシスタントが投入する担当時間数である。  

【凡例】  
<imgTag>106300200_2.png</imgTag>  


　長方形はクラスを表す。長方形の上段はクラス名、中段は属性名、下段は操作名を記述する。なお、属性名および操作名は省略できる。  
　クラス間を結ぶ線はクラス間の関連を表し、この線の中央に関連名を記述する。ただし、関連が属性を持つ場合には、関連を表す線から点線を引いて関連クラスを設ける。凡例では関連2が関連クラスに該当する。  
　また、関連を表す線の両方の終端近くには、それぞれの相手に対するクラス間の多重度の範囲を表す。ここで、多重度とは、一方のクラスの1つのオブジェクトに対して接続されている、他方のクラスのオブジェクトの個数を示すものである。  
　多重度の範囲は、下限をn、上限をmとする場合は「n..m」という形式で表す。例えば、接続が必ず1つの場合は「1」、接続が存在しないかあるいは1つの場合は「0..1」、接続がゼロ以上の場合は「0..*」などのように表す。  

〔解答群〕  
  
ア  各社員は、役割の兼務が認められ、あるプロジェクトでは主任を担当し、また別のプロジェクトではアシスタントを担当できる。  
  
イ  各プロジェクトでは、担当するアシスタントは1人以上である。  
  
ウ  各プロジェクトでは、担当する主任は必ず1人である。  
  
エ  各プロジェクトでは、担当する主任は1人であるか、あるいは担当するアシスタントは1人以上であるかのいずれかである。  
`
        },
        {
                "mondaiId": 106300210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業が外注によって情報システムを開発する場合、外注先に任せきりにする  
のではなく、情報システムのテストに留意するなど、当事者意識を持つ必要があ  
る。  
テストに関する記述として最も適切なものはどれか。  
  
ア  システム開発の最終段階で、発注者として、そのシステムが実際に運用できる  
か否かを、人間系も含めて行うテストをベータテストという。  
  
イ  ソースコードの開発・追加・修正を終えたソフトウェアが正常に機能する状態  
にあるかを確認する予備的なテストをアルファテストという。  
  
ウ  対象箇所や操作手順などを事前に定めず、実施者がテスト項目をランダムに選  
んで実行するテストをA/Bテストという。  
  
エ  プログラムを変更した際に、その変更によって予想外の影響が現れていないか  
どうか確認するテストを回帰テストという。`
        },
        {
                "mondaiId": 106300220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A社では、BAC（BudgetatCompletion：完成時総予算）が1，200万円の情報シ  
ステム開発プロジェクトが進行中である。昨日進捗を把握したところ、AC（Actual  
Cost：コスト実績値）が800万円、EV（EarnedValue：出来高実績値）が600万円  
となっていた。このままのコスト効率でプロジェクトが進んでいくと、完成した時  
にどれくらいのコストがかかると予想できるか。最も適切なものを選べ。  
  
ア  1，200万円  
  
イ  1，400万円  
  
ウ  1，600万円  
  
エ  1，800万円  
`
        },
        {
                "mondaiId": 106300230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年、機密情報への攻撃の手法が多様化している。機密情報を不正に入手する手  
法であるソーシャルエンジニアリングに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  シュレッダーで処理された紙片をつなぎ合わせて、パスワードを取得する。  
  
イ  パソコンの操作画面を盗み見して、パスワードを取得する。  
  
ウ  文字列の組み合わせを機械的かつ総当たり的に試すことで、パスワードを取得  
する。  
  
エ  ユーザになりすまして管理者に電話し、パスワードを取得する。`
        },
        {
                "mondaiId": 106300240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報システムに対するコンティンジェンシープランに関する記述として、最も適  
切なものはどれか。  
  
ア  災害などにより情報システムの運用が困難になることを想定して行う、情報シ  
ステム部門に対する教育・訓練計画である。  
  
イ  情報システムに障害が起きて損失が発生した後に、直ちに作成される、被害の  
調査と復旧のための計画である。  
  
ウ  情報システムに障害が発生しても業務を中断することなく処理を継続できるた  
めに行う、フォールトトレラント・システムの構築計画である。  
  
エ  情報システムに不測の事態が発生することを想定し、事前に対応策や手順など  
を定める緊急時対応計画である。  
`
        },
        {
                "mondaiId": 106300250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
Ｚ社は、新商品Ａ、Ｂ、Ｃのいずれかを開発しようとしている。市場調査を行っ  
た結果、市場の拡大・現状維持・縮小の3パターンで下記のような利益の予測（単  
位：億円）が得られた。この場合の意思決定に関する記述として、最も適切なもの  
を下記の解答群から選べ。  

　　　　拡大／現状維持／縮小  
商品Ａ　100／40／10  
商品Ｂ　90／80／40  
商品Ｃ　80／70／30  

〔解答群〕  
  
ア  マクシマックス原理、ラプラスの原理のいずれを用いても、商品Ａを開発す  
ることが最適となる。  
  
イ  マクシミン原理、マクシマックス原理のいずれを用いても、商品Ａを開発す  
ることが最適となる。  
  
ウ  マクシミン原理、マクシマックス原理のいずれを用いても、商品Ｂを開発す  
ることが最適となる。  
  
エ  マクシミン原理、ラプラスの原理のいずれを用いても、商品Ｂを開発するこ  
とが最適となる。`
        },

        // {
        //         mondaiSyuId: 106,
        //         "nendo": 2017,
        //         "mondaiId": 106290010,
        //         name: "第1問",
        //           //"assets/img/01_06_H30/6-fs8.png",
        //         imgFile_A: "assets/img/bg1.png",  //"assets/img/01_06_H30/7-fs8.png",
        //         mondaiBun: "文字情報を電子化する際の文字コードには、いくつかの種類がある。文字コードの特徴に関する記述として、最も適切なものはどれか。 ア　ASCII コードは、アルファベット、数字、特殊文字、制御文字および漢字から構成される文字コードである。 イ　EUCは、UNIX OSのために開発されたが、その後拡張されて日本語などにも対応できるようになった文字コードである。 ウ　Shift-JISコードは、EUCを拡張して日本語にも利用できるようにした文字コードである。 エ　UTF-8は、2バイトの文字コードで、英数字と日本語だけではなく、世界の主要な言語で使われるほとんどの文字も表現できる。 ",
        //         kaisetuBun:
        //                 `（解説文H２９-１未執筆）

        // `,
        //         sentakushi: ["ア", "イ", "ウ", "エ"],
        //         seikai: "イ",
        //         //"category": "f112_ソフトウェア",
        //         //keyword: ["ASCII", "文字コード", "EUC", '文字コード：パソコンであればどんなときに使う？'],
        //         difficulty: 3,
        //         haiten: 4,
        // },

        // {
        //         mondaiSyuId: 106,
        //         "nendo": 2017,
        //         "mondaiId": 106290020,
        //         name: "第2問",
        //           //"assets/img/01_06_H30/8-fs8.png",
        //         imgFile_A: "assets/img/bg3.png",  //"assets/img/01_06_H30/9-fs8.png",
        //         mondaiBun: "下表は、ある中小企業の“アルバイト”表と“店舗”表である。この 2 つの表に対して、次の SQL 文を実行した場合に得る結果として、最も適切なものを下記の解答群から選べ。 　SELECT 氏名,年間給与支給額,店長名 　FROM アルバイト,店舗 　WHERE アルバイト.店舗コード = 店舗.店舗コード 　　AND 店舗所在地 ='新宿' 　　AND 年間給与支給額 < 800000；  ",
        //         kaisetuBun:
        //                 `（解説文H２９-２未執筆）

        // `,
        //         sentakushi: ["ア", "イ", "ウ", "エ"],
        //         seikai: "ア",
        //         //"category": "f115_SQL",
        //         //keyword: ["SQL", "SELECT", "データベース"],
        //         difficulty: 2,
        //         haiten: 3,
        // },

        {
                "mondaiId": 102300010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
商品　120，000　円を売り上げ、代金のうち　30，000　円を現金で受け取り、残額を掛  
けとした。以下のように入金伝票を作成した場合、振替伝票はどのように記入すべ  
きか。最も適切なものを下記の解答群から選べ。なお、当社では　3　伝票制が用いら  
れているものとする。  

<imgTag>102300010_1.png</imgTag>  

〔解答群〕  
  
ア  　売掛金　　　90，000　　　売　上　　　90，000  
  
イ  　売掛金　120，000　　　売　上　120，000  
  
ウ  　現　金　　　30，000　　　売　上　120，000  
売掛金　　　90，000  
  
エ  　現　金　　　90，000　　　売　上　120，000  
売掛金　　　30，000
`
        },
        {
                "mondaiId": 102300020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
20X1　年　1　月　1　日に購入した建物（取得原価　800，000　千円、耐用年数　20　年、残存  
価額ゼロ）を　20X2　年　6　月　30　日に　725，000　千円で売却した。ただし、決算日は　12  
月　31　日（年　1　回）であり、定額法により減価償却している。売却にあたり計上され  
る固定資産売却損益の金額として、最も適切なものはどれか。  
  
ア  　固定資産売却益：　　5，000　千円  
  
イ  　固定資産売却損：15，000　千円  
  
ウ  　固定資産売却損：35，000　千円  
  
エ  　固定資産売却損：75，000　千円  
`
        },
        {
                "mondaiId": 102300030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は本店のほかに支店があり、本支店間の債権債務は支店勘定および本店勘定  
により処理している。当月は、本支店間で以下の資料に記載された取引が生じた。  
月末時点における本店の支店勘定の残高として、最も適切なものを下記の解答群か  
ら選べ。なお、月初の支店勘定および本店勘定の残高はゼロであり、月末における  
未達事項はないものとする。  

【資　料】  
⑴　本店は支店の広告宣伝費　30，000　円を現金で支払った。  
⑵　支店は本店の買掛金　70，000　円を現金で支払った。  
⑶　本店は支店の売掛金　15，000　円を現金で回収した。  
⑷　本店は原価　60，000　円の商品を支店に送付した。  

〔解答群〕  
  
ア  　貸方残高：　　45，000　円  
  
イ  　貸方残高：115，000　円  
  
ウ  　借方残高：　5，000　円  
  
エ  　借方残高：　　75，000　円  
`
        },
        {
                "mondaiId": 102300040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
Ａ社は、20X1　年　12　月　31　日にＢ社株式の　80　％を　85　百万円で取得した。取得時  
のＡ社およびＢ社の貸借対照表は以下のとおりである。なお、Ｂ社の諸資産および  
諸負債の簿価は、時価と一致している。取得時におけるのれんと非支配株主持分の  
金額の組み合わせとして、最も適切なものを下記の解答群から選べ。  


<imgTag>102300040_1.png</imgTag>  


〔解答群〕  
  
ア  　のれん：　　5　百万円　　非支配株主持分：　　8　百万円  
  
イ  　のれん：　　5　百万円　　非支配株主持分：16　百万円  
  
ウ  　のれん：21　百万円　　非支配株主持分：　　8　百万円  
  
エ  　のれん：21　百万円　　非支配株主持分：16　百万円  

`
        },
        {
                "mondaiId": 102300050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ソフトウェアの会計処理および開示に関する記述として、最も適切なものはどれ  
か。  
  
ア  　自社利用目的のソフトウェアのうち、将来の収益獲得または費用削減が確実で  
あるものについては、機械装置等に組み込まれたものを除き、その取得に要した  
費用を無形固定資産として計上する。  
  
イ  　市場販売を目的とするソフトウェアの製品マスターが完成するまでに要した制  
作費は、最初に製品化されたときに無形固定資産として計上する。  
  
ウ  　受注制作のソフトウェアは、その制作に要した費用を無形固定資産として計上  
する。  
  
エ  　無形固定資産として計上したソフトウェアは規則的な償却を行わず、価値の低  
下時に減損処理する。`
        },
        {
                "mondaiId": 102300060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ファイナンス・リース取引の借手側の会計処理および開示に関する記述として、  
最も適切なものはどれか。  
  
ア  　所有権移転ファイナンス・リース取引にかかるリース資産の減価償却費は、  
リース期間を耐用年数とし、残存価額をゼロとして算定する。  
  
イ  　リース債務は、貸借対照表日後　1　年以内に支払の期限が到来するものは流動負  
債に属するものとし、貸借対照表日後　1　年を超えて支払の期限が到来するものは  
固定負債に属するものとする。  
  
ウ  　リース資産およびリース債務の計上額は、リース契約締結時に合意されたリー  
ス料総額とする。  
  
エ  　リース資産は、貸借対照表日後　1　年以内にリース期間が満了するものは流動資  
産に、貸借対照表日後　1　年を超えてリース期間が満了するものは有形固定資産ま  
たは無形固定資産に含めて表示する。  

`
        },
        {
                "mondaiId": 102300070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は企業向けのセミナーや研修を中心とした業務を営んでいる。決算にあたり  
以下の一連の取引に対し計上される収益および費用の金額の組み合わせとして、最  
も適切なものを下記の解答群から選べ。  
　　4　月　20　日　　7　月開講予定のセミナー（全　10　回、50，000　円／回）の受講料総額  
500，000　円を現金で受け取った。  
　　5　月　30　日　　開講準備にあたり、全　10　回分のテキスト作成のため現金　250，000  
円を支出した。  
12　月　31　日（決算日）　全　10　回のセミナーのうち　6　回が終了していた。  

〔解答群〕  
  
ア  　収益：300，000　円　　費用：150，000　円  
  
イ  　収益：300，000　円　　費用：250，000　円  
  
ウ  　収益：500，000　円　　費用：150，000　円  
  
エ  　収益：500，000　円　　費用：250，000　円`
        },
        {
                "mondaiId": 102300080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
部門別個別原価計算に関する記述として、最も適切なものはどれか。  
  
ア  　部門費の第　1　次集計では、製造直接費は当該部門に賦課され、製造間接費は適  
当な配賦基準により関係する各部門に配賦される。  
  
イ  　部門費の第　1　次集計では、補助部門のうち工場全体の管理業務を行う工場管理  
部門（工場事務部門、労務部門など）には原価を集計しない。  
  
ウ  　部門費の第　2　次集計（補助部門費の配賦）において、直接配賦法を採用する場  
合、補助部門間の用役の授受は計算上無視される。  
  
エ  　部門費の第　2　次集計（補助部門費の配賦）において、補助部門費は、直接配賦法  
や相互配賦法等の方法で各製造指図書に配賦される。  
`
        },
        {
                "mondaiId": 102300090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は製造間接費の予定配賦を行っている。製造間接費予算については公式法変  
動予算を採用している。以下の資料に基づき、製造間接費配賦差異のうち、予算差  
異の金額として、最も適切なものを下記の解答群から選べ。  

【資　料】  
⑴　月間の製造間接費予算  
基準操業度　5，000　時間　固定費　150，000　千円　変動費率　20　千円／時間  
⑵　当月の実際操業度　4，000　時間  
⑶　当月の製造間接費実際発生額　245，000　千円  

〔解答群〕  
  
ア  　不利差異：15，000　千円  
  
イ  　不利差異：30，000　千円  
  
ウ  　有利差異：15，000　千円  
  
エ  　有利差異：30，000　千円  
`
        },
        {
                "mondaiId": 102300100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の資料は、20X1　年の実績と　20X2　年の予算の抜粋である。20X2　年における  
財務比率の変化に関する記述として、最も適切なものを下記の解答群から選べ。  

【資　料】  

<imgTag>102300100_1.png</imgTag>  


〔解答群〕  
  
ア  　付加価値率は上昇する。  
  
イ  　労働生産性は低下する。  
  
ウ  　労働装備率は上昇する。  
  
エ  　労働分配率は低下する。  
`
        },
        {
                "mondaiId": 102300111,
                "name": "第11問設問１",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
当社の当期の損益計算書は、以下のとおりであった。下記の設問に答えよ。  
　　損益計算書  
売上高　240，000　千円（販売価格　200　円　#　販売数量　1，200　千個）  
変動費　<u>96，000</u>　　　（　1　個当たり変動費　80　円　#　販売数量　1，200　千個）  

貢献利益　144，000　千円  
固定費　<u>104，000</u>　　　  

営業利益　<u>40，000</u>　千円  

（設問　1　）  
当社では、次期の目標営業利益を　55，000　千円に設定した。他の条件を一定とする  
と、目標営業利益を達成するために必要な売上高として、最も適切なものはどれか。  
  
ア  　255，000　千円  
  
イ  　265，000　千円  
  
ウ  　280，000　千円  
  
エ  　330，000　千円`
        },
        {
                "mondaiId": 102300112,
                "name": "第11問設問２",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　　損益計算書  
売上高　240，000　千円（販売価格　200　円　#　販売数量　1，200　千個）  
変動費　<u>96，000</u>　　　（　1　個当たり変動費　80　円　#　販売数量　1，200　千個）  

貢献利益　144，000　千円  
固定費　<u>104，000</u>　　　  

営業利益　<u>40，000</u>　千円  

（設問　2　）  
次期の利益計画において、固定費を　2，000　千円削減するとともに、販売価格を  
190　円に引き下げる案が検討されている。また、この案が実施されると、販売数  
量は　1，400　千個に増加することが予想される。次期の予想営業利益として、最も  
適切なものはどれか。なお、他の条件は一定であるものとする。  
  
ア  　52，000　千円  
  
イ  　57，600　千円  
  
ウ  　68，000　千円  
  
エ  　72，800　千円  
`
        },
        {
                "mondaiId": 102300120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
キャッシュ・フロー計算書に関する記述として、最も適切なものはどれか。  
  
ア  　財務活動によるキャッシュ・フローの区分には、資金調達に関する収入や支  
出、有価証券の取得や売却、および貸し付けに関する収入や支出が表示される。  
  
イ  　仕入債務の増加額は、営業活動によるキャッシュ・フローの区分（間接法）にお  
いて、△（マイナス）を付けて表示される。  
  
ウ  　法人税等の支払額は、財務活動によるキャッシュ・フローの区分で表示され  
る。  
  
エ  　利息および配当金の受取額については、営業活動によるキャッシュ・フローの  
区分で表示する方法と投資活動によるキャッシュ・フローの区分で表示する方法  
が認められている。  
`
        },
        {
                "mondaiId": 102300130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
Ａ社は、額面　100　万円の社債（償還までの期間が　3　年、クーポン・レート　3　％（年　  
1　回払い））を発行しようと考えている。現在、複利最終利回りは　2.0　％と予想され  
る。このとき、Ａ社の社債の価格はおよそいくらと考えられるか。最も適切なもの  
を下記の解答群から選べ。なお、複利現価係数と年金現価係数は以下のものを使用  
すること。  

<imgTag>102300130_1.png</imgTag>  


〔解答群〕  
  
ア  　　　98　万円  
  
イ  　100　万円  
  
ウ  　103　万円  
  
エ  　105　万円  
`
        },
        {
                "mondaiId": 102300140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
デリバティブに関する記述として、<u><u>最も不適切なものはどれか</u></u>。  
  
ア  　金利スワップ取引の代表的なものは、同一通貨における固定金利と変動金利を  
交換する取引である。  
  
イ  　先物取引では日々値洗いにより損益が計算され、証拠金に加減されている。  
  
ウ  　デリバティブ取引の代表的なものとして、先物取引、オプション取引、スワッ  
プ取引がある。  
  
エ  　わが国のデリバティブ取引において、想定元本ベースで今日最も多く取引され  
ているものは、通貨スワップである。`
        },
        {
                "mondaiId": 102300150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
コールオプションの価格に関する以下の文章の空欄①〜④に入る語句の組み合わ  
せとして、最も適切なものを下記の解答群から選べ。  
コールオプションの価格は、権利行使したときに得られる　①　価値とこれ  
に上乗せされる　②　価値の合計から構成されている。　①　価値は  
③　価格から　④　価格を控除することにより得られる。　③　価  
格　－　④　価格　≦　0　のときは　①　価値はゼロとなる。  

〔解答群〕  
  
ア  　①：時間的　　②：本質的　　③：権利行使　　④：原資産  
  
イ  　①：時間的　　②：本質的　　③：原資産　　　④：権利行使  
  
ウ  　①：本質的　　②：時間的　　③：権利行使　　④：原資産  
  
エ  　①：本質的　　②：時間的　　③：原資産　　　④：権利行使  
`
        },
        {
                "mondaiId": 102300160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
分散投資によるポートフォリオのリスク減少の様子を示した以下の図と、図中の  
①と②に当てはまる用語の組み合わせのうち、最も適切なものを下記の解答群から  
選べ。  

<imgTag>102300160_1.png</imgTag>  


〔解答群〕  
  
ア  　図Ａ　①：システマティック・リスク  
②：非システマティック・リスク  
  
イ  　図Ａ　①：非システマティック・リスク  
②：システマティック・リスク  
  
ウ  　図Ｂ　①：システマティック・リスク  
②：非システマティック・リスク  
  
エ  　図Ｂ　①：非システマティック・リスク  
②：システマティック・リスク  

`
        },
        {
                "mondaiId": 102300170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の図は、すべての投資家が共通して直面する危険資産のみから構成される危  
険資産ポートフォリオの集合を示したものである。この図を用いた説明となる以下  
の文章の空欄①と②に入る語句の組み合わせとして、最も適切なものを下記の解答  
群から選べ。  
危険資産のみから構成されるポートフォリオの集合のうち、リスク・リターンの  
面から望ましい組み合わせのみを選んだ曲線　AC　を　①　と呼ぶ。  
安全資産と曲線　AC　上の任意の点Ｄで新しいポートフォリオを作ることにした。  
このとき、新たなポートフォリオのリスク・リターンの組み合わせは安全資産と点  
Ｄを結ぶ直線で示される。安全資産と曲線　AC　上の任意の点で作られる最も望まし  
いリスク・リターンの組み合わせを　②　と呼ぶ。  

<imgTag>102300170_1.png</imgTag>  


〔解答群〕  
  
ア  　①：投資機会集合　　　　②：資本市場線  
  
イ  　①：投資機会集合　　　　②：証券市場線  
  
ウ  　①：有効フロンティア　　②：資本市場線  
  
エ  　①：有効フロンティア　　②：証券市場線  

`
        },
        {
                "mondaiId": 102300180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
資産Ａ、Ｂの収益率の期待値（リターン）と標準偏差（リスク）および相関係数が以  
下の表のように与えられているとき、資産Ａ、Ｂを組み込んだポートフォリオの収  
益率が　16　％になるためには、資産Ａへの投資比率を何％にしたらよいか。最も適  
切なものを下記の解答群から選べ。  

<imgTag>102300180_1.png</imgTag>  


〔解答群〕  
  
ア  　20　％  
  
イ  　30　％  
  
ウ  　40　％  
  
エ  　50　％  
`
        },
        {
                "mondaiId": 102300190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の一連の取引に対する　3　月　10　日時点の記述として、最も適切なものを下記  
の解答群から選べ。なお、当店では振当処理を採用しており、決算日は　3　月　31　日  
である。  

20X1　年　2　月　15　日（取引発生時）  
　商品　1　万ドルを仕入れ、代金は買掛金とした。  
　直物為替レートは、　1　ドル　100　円であった。  

20X1　年　3　月　10　日  
　上記の買掛金について為替予約をした。  
　直物為替レートは　1　ドル　103　円であった。  
　先物為替レートは　1　ドル　106　円であった。  

〔解答群〕  
  
ア  　買掛金は　6　万円減少する。  
  
イ  　為替差損は　6　万円である。  
  
ウ  　直先差額は　△3　万円である。  
  
エ  　直先差額は　△6　万円である。  
`
        },
        {
                "mondaiId": 102300200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
市場の効率性に関する記述として、<u><u>最も不適切なものはどれか</u></u>。  
  
ア  　ウィーク型仮説とは、現在の株価は、過去の株価、取引高などを織り込んでい  
る結果、過去のデータから、将来の株価の変動を予測することは不可能であると  
する仮説である。  
  
イ  　効率的市場仮説とは、情報が即座に価格に織り込まれることを通じて、市場で  
は効率的な価格形成が達成されているとする仮説である。  
  
ウ  　資本市場における取引上の効率性とは、手数料、税金、制度、法律などの面で  
取引を円滑に実施するための取引システム全般が機能しているかどうかを意味す  
る。  
  
エ  　セミストロング型仮説とは、市場の効率性は限定的であるので、ファンダメン  
タル分析を使って超過収益獲得の機会が存在することを示す仮説である。`
        },
        {
                "mondaiId": 102300211,
                "name": "第21問設問１",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の損益計算書について、下記の設問に答えよ。  

　　損益計算書  
営業利益　150　百万円  
支払利息　<u>50</u>  
税引前利益　100　百万円  
税金（40　％）　<u>40</u>  

税引後利益　<u>60</u>　百万円  


なお、当期の総資産は　1，500　百万円（　=　有利子負債　1，000　百万円　+　株主資本　500  
百万円）とする。  
また、当社では　ROA　を営業利益　÷　総資産と定義している。  

  
（設問　1　）  
営業利益は経営環境によって変動する。したがって、投下資本を一定とした場  
合、それに応じて　ROA　も変動する。ROA　が　15　％に上昇した場合、ROE　は何％  
になるか、最も適切なものを選べ。  
  
ア  　17　％  
  
イ  　21　％  
  
ウ  　35　％  
  
エ  　39　％`
        },
        {
                "mondaiId": 102300212,
                "name": "第21問設問２",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の損益計算書について、下記の設問に答えよ。  

　　損益計算書  
営業利益　150　百万円  
支払利息　<u>50</u>  
税引前利益　100　百万円  
税金（40　％）　<u>40</u>  

税引後利益　<u>60</u>　百万円  


なお、当期の総資産は　1，500　百万円（　=　有利子負債　1，000　百万円　+　株主資本　500  
百万円）とする。  
また、当社では　ROA　を営業利益　÷　総資産と定義している。  


（設問　2　）  
ROA　の変動に対して　ROE　の変動を大きくさせる要因として、最も適切なも  
のはどれか。  
  
ア  　安全余裕率  
  
イ  　売上高営業利益率  
  
ウ  　負債比率  
  
エ  　流動比率  
`
        },
        {
                "mondaiId": 102300221,
                "name": "第22問設問１",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
経済命数がいずれも　2　年で初期投資額Ｉが同一である　2　つの投資案ＡとＢがあ  
る。各投資案の各期のキャッシュフロー　R　1　、R　2　が以下のように予測されている。  
いずれも資本コストは　5　％であり、そのときの内部収益率ｒと正味現在価値　NPV  
が以下のように計算されている。  

<imgTag>102300221_1.png</imgTag>  



（設問　1　）  
投資案Ａと投資案Ｂのどちらを採択するかについて、内部収益率法と正味現在  
価値法では結論が異なっている。その理由として、最も適切なものはどれか。  
  
ア  　会計的投資利益率の相違  
  
イ  　回収期間法における回収期間の相違  
  
ウ  　再投資における収益率の相違  
  
エ  　割引キャッシュフロー法であるかないかの相違  
`
        },
        {
                "mondaiId": 102300222,
                "name": "第22問設問２",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
経済命数がいずれも　2　年で初期投資額Ｉが同一である　2　つの投資案ＡとＢがあ  
る。各投資案の各期のキャッシュフロー　R　1　、R　2　が以下のように予測されている。  
いずれも資本コストは　5　％であり、そのときの内部収益率ｒと正味現在価値　NPV  
が以下のように計算されている。  

<imgTag>102300221_1.png</imgTag>  


（設問　2　）  
投資案Ａと投資案Ｂのいずれを採択すべきか。①結論と②その根拠を示す計算  
方法の組み合わせとして、最も適切なものを選べ。  
  
ア  　①：投資案Ａ  
②：　差額投資案Ａ　-　Ｂを計算し、その内部収益率または正味現在価値を計算  
する。  
  
イ  　①：投資案Ａ  
②：　差額投資案Ａ　-　Ｂを計算し、その内部収益率を資本コストとして正味現  
在価値を計算する。  
  
ウ  　①：投資案Ｂ  
②：　差額投資案Ａ　-　Ｂを計算し、その内部収益率または正味現在価値を計算  
する。  
  
エ  　①：投資案Ｂ  
②：　差額投資案Ａ　-　Ｂを計算し、その内部収益率を資本コストとして正味現  
在価値を計算する。`
        },
        {
                "mondaiId": 103300010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業の多角化に関する記述として、最も適切なものはどれか。  
  
ア  　外的な成長誘引は、企業を新たな事業へと参入させる外部環境の条件である  
が、主要な既存事業の市場の需要低下という脅威は、新規事業への参入の誘引と  
なりうる。  
  
イ  　企業の多角化による効果には、特定の事業の組み合わせで発生する相補効果  
と、各製品市場分野での需要変動や資源制約に対応し、費用の低下に結びつく相  
乗効果がある。  
  
ウ  　企業の本業や既存事業の市場が成熟・衰退期に入って何らかの新規事業を進め  
る場合、非関連型の多角化は、本業や既存事業の技術が新規事業に適合すると判  
断した場合に行われる。  
  
エ  　事業拡大への誘引と障害は、企業の多角化の形態や将来の収益性の基盤にまで  
影響するが、非関連型の多角化では、既存事業の市場シェアが新規事業の市場  
シェアに大きく影響する。  
  
  
オ  　内的な成長誘引は、企業を多角化へと向かわせる企業内部の条件であり、既存  
事業の資源を最大限転用して相乗効果を期待したいという非関連型多角化に対す  
る希求から生じることが多い。  
`
        },
        {
                "mondaiId": 103300020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経営資源の　1　つとして区別される情報的経営資源に関する記述として、最も適切  
なものはどれか。  
  
ア  　企業活動における仕事の手順や顧客の特徴のように、日常の業務活動を通じた  
経験的な効果として蓄積される経営資源は、情報的経営資源には含まれない。  
  
イ  　企業活動における詳細なマニュアルや設計図は、熟練やノウハウなどの情報的  
経営資源と比較して模倣困難性は高くない。  
  
ウ  　企業にとって模倣困難性の低い情報的経営資源が競争にとって重要ならば、特  
許や商標のような手段で法的に模倣のコストを高める必要性は高くない。  
  
エ  　企業の特定の事業分野における活動で蓄積された情報的経営資源は、その事業  
に補完的な事業分野でしか利用できない。  
`
        },
        {
                "mondaiId": 103300030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の経営資源に基づく競争優位を考察する　VRIO　フレームワークにおける模  
倣困難性は、持続的競争優位を獲得するために必要な条件とされている。この模倣  
困難性に関する記述として、最も適切なものはどれか。  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】VRIOフレームワーク  
<TABLE border="5" bordercolor="#ffffff" width="100%" style='background-color:#ffffff;color:#333333;'>

<TR>
<TD style='background-color:#d0d0f0;'>
質問１  
</TD>
<TD style='background-color:#d0d0f0;'>
質問２  
</TD>
<TD style='background-color:#d0d0f0;'>
質問３  
</TD>
<TD style='background-color:#d0d0f0;'>
質問４  
</TD>
<TD style='background-color:#ffffff;'>
　
</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;'>
Value（経済的価値）はあるか？
</TD>
<TD style='background-color:#ddddff;'>
R<hintTarget>are（希少）</hintTarget>か？
</TD>
<TD style='background-color:#ddddff;'>
Inimitable<hintTarget>（imitate、真似）しにくい</hintTarget>か？
</TD>
<TD style='background-color:#ddddff;'>
O<hintTarget>rganization（活用できる組織）</hintTarget>か？
</TD>
<TD style='background-color:#ffffff;'>
　
</TD>
</TR>

<TR>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffeeee;'>
No
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
競争劣位
</TD>
</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
競争均衡
</TD>
</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
<hintTarget>一時的</hintTarget>な競争優位
</TD>

</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
持続的な競争優位
</TD>

</TR>


</TABLE>
</div>
</hint>


ア  　Ａ社が、模倣対象のＢ社が保有する経営資源やケイパビリティと、Ｂ社の競争  
優位の<hintTarget>関係を理解</hintTarget>しているか否かは、Ａ社がＢ社の模倣を行う時のコストに影響  
を与える要因にならない。  

<hint hidden='true'>**
❌Inimitable　＝　imitate（真似）しにくさ  
に影響を与える要因となります。
**</hint>
  
イ  　Ｃ社が、新規事業に必要不可欠な経営資源を、その将来における最大価値を下  
回るコストで入手した場合、競合会社Ｄ社が、Ｃ社より<hintTarget>相当に高いコスト</hintTarget>でも同  
様の経営資源を獲得できる限り、Ｃ社の経営資源に模倣困難性は<hintTarget>ない</hintTarget>。  

<hint hidden='true'>**
❌相当に高いコストは、imitate（真似）しにくさを高めるため、模倣困難性があるといえます。
**</hint>
  
ウ  　最先端の機械Ｅを使いこなすために熟練技能者同士の協力関係が必要であり、  
かつ、熟練技能者同士の協力関係の構築に相当な時間とコストを必要とする場  
合、最先端の機械Ｅを所有しているだけでは、模倣困難性による持続的競争優位  
の源泉にはならない。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  
  
エ  　相当な時間を要して獲得したＦ社のノウハウやネットワークが、優れた製品を  
生み出すための重要な要素で希少性もあり、また競合会社が短期間で獲得するに  
はコスト上の不利が働くとしても、Ｆ社の模倣困難性を持つ経営資源には<hintTarget>なりえ  
ない</hintTarget>。  

<hint hidden='true'>**
❌なりえます
**</hint>

`
        },
        {
                "mondaiId": 103300040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の事業再編と買収の戦略に関する記述として、最も適切なものはどれか。  
  
ア  　企業の<hintTarget>一部門</hintTarget>を買収するタイプの買収は、通常、<hintTarget>狭義</hintTarget>のレバレッジド・バイアウトと呼ばれ、もともとは経営資源の拡大を意図したが、マネジメント・バイアウトやエンプロイー・バイアウトとは<hintTarget>異なる範疇</hintTarget>の手法である。  

<hint hidden='true'>**
レバレッジド・バイアウト（LBO)は、企業全体あるいは一部門といった買収範囲に応じて狭義・広義などと分ける分類の定義はありません。  
また、LBOと、マネジメント・バイアウト（自社経営陣による経営権の買取）およびエンプロイーバイアウト（従業員による経営権の買取）について、異なる範疇に区分するような一般的な分類はありません。
**</hint>
  
<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】レバレッジド・バイアウトとは  
<img src="https://www.shikinguri.biz/blog/wp-content/uploads/2018/07/LBO-580x435-300x225.jpg"/>  
<a href="https://www.shikinguri.biz/blog/201807081418_51.html" target="_blank">  
(外部リンクを開きます　中小企業が買収資金をＬＢＯで調達できるのか？：インタープロス株式会社)</a>  
</div>
</hint>  

イ  　事業<hintTarget>規模</hintTarget>の縮小は、通常、<hintTarget>売却</hintTarget>、企業の一部門の分離独立である<hintTarget>スピンオフ</hintTarget>、  
企業の中核事業に関連しない<hintTarget>部門の廃止</hintTarget>などの手法を指し、事業ポートフォリオを変えて<hintTarget>短期的には負債</hintTarget>の削減につながる。  

<hint hidden='true'>**
❌事業規模の縮小で短期的に削減されるのは、コストです。  
また、売却、スピンオフ、中核事業に関連しない部門廃止は、必ずしも事業規模を削減せず、事業範囲の削減になると考えられます。
**</hint>
  
ウ  　事業範囲の縮小は、企業買収によって期待した価値を実現できない際の買収見  
直しに用いられ、通常、従業員数や事業部門数の削減を伴い、<hintTarget>事業規模の縮小と  
同様</hintTarget>に事業ポートフォリオを変えることになる。  

<hint hidden='true'>**
❌事業規模の縮小の場合は、事業ポートフォリオは必ずしも変わりません。
**</hint>

エ  　自社資産を担保に調達した資金によって、オーナーではない経営者が自社を買  
収するタイプの買収は広義のレバレッジド・バイアウトの一形態であり、通常、  
買収後には経営の自由裁量の確保や敵対的買収に対する防衛などのために株式を  
非公開とする。

<hint hidden='true'>**
⭕適切な記述です。
**</hint>   

オ  　プライベート・エクイテイ投資会社が、企業の資産の大部分を買い取って当該  
企業を<hintTarget>非上場化する</hintTarget>レバレッジド・バイアウトでは、通常、当該企業の業務を<hintTarget>維  
持</hintTarget>し、<hintTarget>資産</hintTarget>の売却は長期的な計画の下で行う。  

<hint hidden='true'>**
❌プライベート・エクイティ投資会社は、未公開株（つまり非上場）を取得し、経営に深く関与して企業価値を高めたあとに株式を売却します。  
したがって、基本的に
・非上場化はしない点  
・当該企業の業務を維持するのではなく改善する点  
・資産ではなく株式を売却する点  
が誤りです。
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】PEファンドの対象  
<img src="https://lh4.googleusercontent.com/Ztn0izl9DoAFa15gdXZslFcZImM-xHN_U1p5SGvP20tm4YAXd30_KiyTPon3maj4zukMUO00Zxu7bEqBkTCxJFdSgc9t3S8YavXzTFsdK_pbM60xomONYYjz1FUNtsdXJ50QaEsa"/>  
<a href="https://sogotcha.com/buyer-personal-pefund-002/" target="_blank">  
(外部リンクを開きます　【図解】PEファンドとは？バイアウトファンドとは？：SoGatcha)</a>  
</div>
</hint>  

<hint hidden='true'>**
ライリー＆コンバースの法則の公式より  
商圏分岐点Da＝15km/(1+√48万人/12万人)＝15/(1+2)=5km
**</hint>

`
        },
        {
                "mondaiId": 103300050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
マイケル・ポーターによる業界の構造分析に関する記述として、最も適切なもの  
はどれか。  
  
ア  　価値連鎖（バリューチェーン）を構成する設計、製造、販売、流通、支援サービ  
スなどの諸活動において規模の経済が働くかどうかは、その業界構造を決定する  
要因であり、多数乱戦（市場分散型）業界では、すべての諸活動において規模の経  
済性が欠如している。  
  
イ  　継続的に売り上げが減少している衰退業界においては、できるだけ早く投資を  
回収して撤退する戦略の他に、縮小した業界においてリーダーの地位を確保する  
ことも重要な戦略の　1　つである。  
  
ウ  　成熟業界においては、新製品開発の可能性が少なく、成長が鈍化するために、  
多くの企業は、プロセス革新や現行製品の改良に力を入れるようになり、企業間  
のシェア争いは緩やかになる。  
  
エ  　多数乱戦（市場分散型）業界は、ニーズが多様であること、人手によるサービス  
が中心であることが特徴なので、集約・統合戦略は、この業界には適さない戦略  
である。  
`
        },
        {
                "mondaiId": 103300060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
価値連鎖（バリューチェーン）のどれだけの活動を自社の中で行うかが、その企業  
の垂直統合度を決めると言われている。自社の中で行う活動の数が多いほど、垂直  
統合度が高く、その数が少ないほど垂直統合度が低いとした場合、ある部品メー  
カーＡ社が垂直統合度を高める理由として、最も適切なものはどれか。  
  
ア  　Ａ社の部品を使って完成品を製造している企業は多数存在しているが、いずれ  
の企業もＡ社の部品を仕入れることができないと、それぞれの完成品を製造でき  
ない。  
  
イ  　Ａ社の部品を作るために必要な原材料については、優良な販売先が多数存在し  
ており、それらの企業から品質の良い原材料を低コストで仕入れることが容易で  
ある。  
  
ウ  　Ａ社の部品を作るために必要な原材料を製造しているメーカーは、その原材料  
をＡ社以外に販売することはできない。  
  
エ  　Ａ社の部品を作るために必要な原材料を製造しているメーカーが少数であり、  
環境変化により、Ａ社はこれらの原材料の入手が困難となる。  
  
  
オ  　Ａ社は、Ａ社の部品を作るために必要な原材料を製造しているメーカーとの間  
で、将来起こりうるすべての事態を想定し、かつそれらの事態に対してＡ社が不  
利にならないようなすべての条件を網羅した契約を交わすことができる。  
`
        },
        {
                "mondaiId": 103300070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
部品の開発や生産をめぐる完成品メーカーと部品メーカーの取引関係は多様であ  
る。そのような取引関係に関する記述として、最も適切なものはどれか。  
  
ア  　委託図方式では、部品メーカーが部品の詳細設計を行うので、図面の所有権は  
部品メーカーに帰属し、部品の品質保証責任は完成品メーカーが負うことにな  
る。  
  
イ  　承認図方式では、発注側が準備した部品の詳細設計に基づいて製造できる能力  
やコストを評価して部品外注先が選ばれる。  
  
ウ  　承認図方式や委託図方式では、部品メーカーには製造能力ばかりでなく設計開  
発能力が要求される。  
  
エ  　貸与図方式では、発注側が提示した部品の基本的な要求仕様に対して、部品  
メーカーは部品の詳細設計を行い、部品を試作し性能評価をすることになる。  
  
  
オ  　デザインインでは、部品メーカーは当該部品の開発段階の参加と発注側作成の  
詳細設計に基づく生産能力が求められるが、設計の外注が発生しないのでコスト  
負担は軽減される。  
`
        },
        {
                "mondaiId": 103300080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業変革は、経営者にとって重要な戦略的課題である。企業におけるイノベー  
ションと変革に関する記述として、最も適切なものはどれか。  
  
ア  　企業内起業家制度は、組織内で自律した位置づけと経営資源を与えられるベン  
チャー・チームを活用することがあり、イノベーションを生み出す企業家精神、  
哲学、組織構造を内部に発展させようとする試みである。  
  
イ  　企業の戦略的な優位を達成するために、製品・サービス、戦略と組織構造、組  
織文化、技術の変革に取り組む必要があるが、これらの個々の変革は他と切り離  
して実行でき、各々の変革の結果は相互に独立的である。  
  
ウ  　製品イノベーションを戦略的に達成するには、水平的連携が必要となるが、水  
平的連携は、新製品にかかわる各々の部門が外部環境における関連する領域と卓  
越した連携を持つことである。  
  
エ  　製品イノベーションを戦略的に達成するには、バウンダリー・スパンニングが  
必要となるが、バウンダリー・スパンニングは、技術、マーケティング、生産の  
各担当者が、互いにアイデアや情報を共有することである。  
  
  
オ  　リエンジニアリングは、事業プロセスの急激な設計変更に対応し、プロセスよ  
りも職務を重視した部門の専門化の取り組みであり、組織文化、組織構造、情報  
技術に対して逐次的変化を引き起こすため、従業員が不安や怒りで反応する場合  
がある。  
`
        },
        {
                "mondaiId": 103300090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
技術のイノベーションは発生してから、いくつかの特徴的な変化のパターンをと  
りながら進化していく。イノベーションの進化に見られる特徴に関する記述とし  
て、<u><u>最も不適切なものはどれか</u></u>。  
  
ア  　技術システムが均衡状態にあることが、技術開発への努力を導く不可欠な力に  
なるので、技術間の依存関係や補完関係に注意することは重要である。  
  
イ  　技術進歩のパターンが経時的にＳ字型の曲線をたどることがあるのは、時間の  
経過とともに基礎となる知識が蓄積され、資源投入の方向性が　収斂するからで  
ある。  
  
ウ  　優れた技術が事業の成功に結びつかない理由として、ある技術システムとそれ  
を使用する社会との相互依存関係が、その後の技術発展の方向を制約するという  
経路依存性を挙げることができる。  
  
エ  　製品の要素部品の進歩や使い手のレベルアップが、予測された技術の限界を克  
服したり、新規技術による製品の登場を遅らせることもある。  
  
  
オ  　連続的なイノベーションが成功するのは、漸進的に積み上げられた技術進化の  
累積的効果が、技術の進歩や普及を促進するからである。  
`
        },
        {
                "mondaiId": 103300100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
製品開発期間の短縮を図るために、製品開発のプロセスに注目して、いくつかの  
手法を体系的に組み合わせることが行われている。そのような手法に関する記述と  
して、最も適切なものはどれか。  
  
ア  　オーバーラップの開発手法では、開発プロセスの上流タスクの完了前に下流タ  
スクを先行してスタートさせるので、事前に両タスクの内容を綿密に設計するこ  
とが必要である。  
  
イ  　オーバーラップの開発手法では、開発プロセスの上流タスクと下流タスクの相  
互信頼が強い場合に効果的であり、コミュニケーション頻度や相互の調整を著し  
く減少させることによって開発期間が短縮される。  
  
ウ  　開発前半に速いスピードで解決できる問題を集中させて、開発後半で発生しや  
すく、時間や費用のかかる設計変更などの反復回数を減らすことは、開発期間の  
短縮に効果的である。  
  
エ  　コンピューター支援エンジニアリング（CAE）が開発手法の根本的な変革とし  
て自動車開発で導入が進んでいるのは、コンピューター上でシミュレーションし  
ながら製品の完成度を評価できるので、実物試作が不要になるからである。  
  
  
オ  　フロントローディングでは、開発初期段階で開発に必要な経営資源の投入量が  
増加するので、開発後期での設計変更は不要になる。  
`
        },
        {
                "mondaiId": 103300110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
創業家とその一族によって所有、経営されるファミリービジネスの中小企業は多  
い。ファミリービジネスのシステムを、互いに重なり合う部分を持つ「オーナー  
シップ」「ビジネス」「ファミリー」の　3　つのサブシステムで表すスリー・サークル・  
モデルに関する記述として、<u><u>最も不適切なものはどれか</u></u>。  
  
ア  　スリー・サークル・モデルは、経営理念の核となる家訓の維持を重視するファ  
ミリービジネスに適用でき、ファミリービジネスの限界が何に起因するのかを知  
るなど、個々のファミリービジネスで異なる経営の問題解決に有用である。  
  
イ  　スリー・サークル・モデルは、直系血族の経営から従兄弟などを含む広い意味  
でのファミリービジネスへ変化していくようなファミリービジネスの時間による  
変化について、オーナーシップ、ビジネス、ファミリーの　3　次元から分類するモ  
デルへと展開できる。  
  
ウ  　スリー・サークル・モデルは、ファミリービジネスの　3　つのサブシステムに対  
する利害関係者の関わり方を表し、ファミリービジネスの中小企業に関わるすべ  
ての個人は、自らを　3　つのサブシステムの組み合わせからなるセクターのいずれ  
か　1　つに位置づけて問題解決に関わる。  
  
エ  　スリー・サークル・モデルは、ファミリービジネスの合理的経営のための戦略  
計画とファミリー固有のビジョンや目標との間の適合を図り、コンフリクト回避  
のためにファミリーメンバーの継続的関与と戦略を並行的に計画させるモデルで  
ある。  
  
  
オ  　スリー・サークル・モデルは、ファミリービジネスの中小企業に内在する複雑  
な相互作用の分析の助けとなり、企業内外の人間関係における対立、役割上の困  
難な問題を理解する際に、それらが何に起因するのかを知るのに役立つ。  
`
        },
        {
                "mondaiId": 103300120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
技術開発型ベンチャー企業が起業から事業展開で直面する障壁には、通常、以下  
の【Ａ欄】にあるダーウィンの海、デビルリバー（魔の川）、デスバレー（死の谷）と呼  
ばれるものがある。これらの障壁は【Ｂ欄】のように説明できるが、その回避には  
【Ｃ欄】に例示したような対応策が求められる。  
【Ａ欄】のａ〜ｃに示された障壁名、【Ｂ欄】の①〜③に示された障壁の内容、【Ｃ  
欄】のⅰ〜ⅲに示された対応策の組み合わせとして、最も適切なものを下記の解答  
群から選べ。  

【Ａ：障壁名】  
ａ　ダーウィンの海  
ｂ　デビルリバー  
ｃ　デスバレー  

【Ｂ：障壁の内容】  
①　応用研究と商品開発ないし事業化との間に存在する資金や人材の不足などとい  
う障壁  
②　開発商品を事業化して軌道に乗せる際、既存商品や他企業との激烈な競争に直  
面するという障壁  
③　技術シーズ志向の研究のような基礎研究からニーズ志向の応用（開発）研究に至  
る際の障壁  

【Ｃ：対応策】  
ⅰ　大手企業とのアライアンスやファブレス生産に取り組み、生産、販売、マーケ  
ティング、アフターサービスが一体となった体制などによって回避を試みる。  
ⅱ　基礎技術や高い要素技術を必要とする領域は大学に任せ、TLO　を活用して連  
携を積極的に行うことなどによって回避を試みる。  
13  
ⅲ　所有している特許権や意匠権などの知的所有権のうち、一部の専用実施権を第  
三者企業に付与することや、社内プロジェクトメンバーについての担当の入れ替  
え、メンバーの権限付与の見直しなどによって回避を試みる。  

〔解答群〕  
  
ア  　ａ－①－ⅱ　　ｂ－②－ⅲ　　ｃ－③－ⅰ  
  
イ  　ａ－②－ⅰ　　ｂ－③－ⅱ　　ｃ－①－ⅲ  
  
ウ  　ａ－②－ⅲ　　ｂ－①－ⅱ　　ｃ－③－ⅰ  
  
エ  　ａ－③－ⅱ　　ｂ－①－ⅰ　　ｃ－②－ⅲ  
    
オ  　ａ－③－ⅲ　　ｂ－②－ⅰ　　ｃ－①－ⅱ  
`
        },
        {
                "mondaiId": 103300130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
わが国の企業が東南アジアの新興国に進出する場合に考慮すべき戦略的な課題に  
関する記述として、最も適切なものはどれか。  
  
ア  　海外戦略の一環としてリバースイノベーションを展開するには、現地のニーズ  
に適合的な製品の開発能力が鍵になるので、研究開発機能の本国への統合が必要  
である。  
  
イ  　現地市場のボリュームゾーンで、売上を伸ばしている模倣部品を組み込んだ現  
地企業の廉価品に対抗するためには、自社の高性能部品を組み込んだ高価格な高  
機能製品を現地生産しなければならない。  
  
ウ  　電子製品や自動車などでは現地生産の進展にともなって系列を超えた域内取引  
が拡大しているので、日系サプライヤーにとっては現地での開発力や柔軟な生産  
対応力の強化が重要になる。  
  
エ  　東南アジアへの進出では海外直接投資による資産の所有が市場の成長への対応  
を鈍くするので、現地生産による内部化を避けてライセンシングによる生産委託  
を選択しなければならない。  
  
オ  　輸出代替型の東南アジア進出では、現地子会社で売れ筋の量産品の生産能力を  
高めることができれば、顧客密着を狙ったマスカスタマイゼーションを実現でき  
る。  
`
        },
        {
                "mondaiId": 103300140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業組織を取り巻く状況の変化に柔軟に対応するために、従来の部門組織や恒久  
的なグループ編成だけでなく、チームを採用することが効果的な場合がある。チー  
ムに関する記述として、最も適切なものはどれか。  
  
ア  　共通の目的を達成するためにバーチャルチームを形成して業務を遂行する際  
に、メンバー同士が直接顔を合わせた経験がある場合は、そうでない場合と比べ  
てタスク志向性が高くなり、社会的・感情的情報交換は少なくなる。  
  
イ  　遂行すべきタスクに必要なスキルや経験の多様性が低い場合は、個人よりチー  
ムの方が高い業績をあげる傾向にある。  
  
ウ  　タスクフォースは恒常的に設置されている機能横断型チームであり、初期段階  
ではメンバーが多様性や複雑性への対処の仕方を学ぶために時間がかかることが  
ある。  
  
エ  　チームで業務を遂行する場合は、一般に多くの時間と資源を必要とし、コンフ  
リクトが顕在化する傾向にある。  
  
オ  　チームメンバー間の信頼関係が確立されていると、メンバーは他者が自分を利  
用しようとしていると感じる傾向が低くなり、自分の弱点をさらけだすことも可  
能になるため、リスク志向性は低くなる。  
`
        },
        {
                "mondaiId": 103300150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
働き方や価値観の多様化とともに、外発的動機づけに加え、内発的な動機づけが  
いっそう重要になっている。内発的な動機づけに関わる代表的な論者による説明と  
して、<u><u>最も不適切なものはどれか</u></u>。  
  
ア  　Ａ．マズローの欲求段階説における自己実現欲求は、外発的に動機づけられる  
ものではなく、自分自身の理想を追い求め続けることを通じた内発的な動機づけ  
とも考えられる。  
  
イ  　Ｅ．メイヨーとＦ．レスリスバーガーのホーソン実験では、従業員が自分たちの  
作業条件を決定することによって内発的に動機づけられていたことを発見し、こ  
れをホーソン効果と呼んだ。  
  
ウ  　Ｍ．チクセントミハイは、特定の作業に没頭する中で、自身や環境を完全に支  
配できているという感覚が生まれることをフロー経験と呼び、そうした経験は他  
者からのフィードバックも必要とせず、給与などの報酬とも無関係であるとし  
た。  
  
エ  　Ｒ．Ｗ．ホワイトが提唱するコンピテンス（有能性）概念では、環境と相互作用す  
る有機体の能力自体が、「うまくいった」という内発的な動機づけの源泉となる。  
    
オ  　内発的動機づけを概念として広く知らしめたＥ．デシは、報酬のためにやらさ  
れているのではなく、自分の好きにやっているという自己決定が重要であるとし  
た。  
`
        },
        {
                "mondaiId": 103300160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
状況ごとに異なるリーダーシップを捉える条件適合理論の　1　つに、パス・ゴール理論がある。パス・ゴール理論が注目する状況要因には、タスク特性や公式の権限  
体系などリーダーが直接コントロールできない環境と、部下の経験や能力などの個  
人的特徴がある。  
パス・ゴール理論が明らかにしたリーダーシップに関する記述として、最も適切  
なものはどれか。  
  
ア  　構造化されたタスクに携わる従業員に対しては、指示型リーダーシップによる  
職務遂行が有効である。  
  
イ  　構造化されたルーチンワークに携わる部下に対しては、支援型リーダーシップ  
が高業績と高い満足度をもたらす。  
  
ウ  　行動の決定権が自分にはないと感じている従業員に対しては、参加型リーダーシップによって動機づけを行うことが有効である。  
  
エ  　職場内に深刻な価値コンフリクトが生じている場合には、参加型リーダーシップが従業員の高い満足度をもたらす。  
  
オ  　複雑なタスクに携わる高い能力を持つ従業員に対しては、より具体的な作業内  
容を与える指示型リーダーシップが高い満足度をもたらす。  
`
        },
        {
                "mondaiId": 103300170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
集団のリーダーには、メンバーが集団目標を自身の目標として達成しようとする  
ように働きかけることが求められるが、その手段としてメンバーを追従させるため  
のパワーが必要である。個人や集団を追従させるパワーの源泉に関する記述とし  
て、<u><u><u>最も不適切なものはどれか</u></u></u>。  
  
ア  　技術が高度化するにつれ、リーダーが専門的な知識やスキルを有している、あ  
るいは専門家からのサポートを得ていることが、メンバーを従わせる専門力  
（expert　power）となる。  
  
イ  　職位権限など、組織から公式に与えられた地位は、それ自体が人々を従わせる  
正当権力（legitimate　power）となる。  
  
ウ  　メンバーが自身と同じような資質や個性を備えたリーダーに同一化する同一視  
力（referent　power）が生まれる。  
  
エ  　リーダーがメンバーの昇給や昇進、その他の好意的な労働条件を与えることが  
できる権限を持っている場合、メリットを求めて指示に従う報酬力（reward　  
power）が生まれる。  
  
オ  　リーダーがメンバーに集団内での不利益を与える場合、恐怖心に裏付けられた  
強制力（coercive　power）が生まれる。  
`
        },
        {
                "mondaiId": 103300180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
変化が激しい環境に適応する組織にとって、組織学習を促進していくことは不可  
欠である。組織学習に関する記述として、最も適切なものはどれか。  
  
ア  　シングルループ学習とは、ある目的とそれを達成するための行為の因果関係に  
ついての知識を、一度見直すことを意味する。  
  
イ  　組織内の人々は役割が規定され、その成果によって評価されるために、環境の  
変化に対応した新しい知識を獲得しても、それを直ちに個人や組織の行動の変化  
に反映できないことがある。  
  
ウ  　高い成果をもたらした組織のルーティンは、繰り返し使用することによって、  
より高い成果を生み出すことにつながるため、慣性の高い組織の方が長期適応す  
る能力は高くなる。  
  
エ  　低次学習よりも高次学習を促進するためには、明確なコンテキストのもとで、  
ある行為の結果に関する大量の情報を処理し、その行為の有効性を評価する必要  
がある。  
  
オ  　部門間を緩やかな結合関係にすることによって、傍観者的学習の可能性が低下  
するため、組織は全体として環境の変化に適応しやすくなる。  
`
        },
        {
                "mondaiId": 103300190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経営者と従業員は、賃金を支払って従業員に職務を委託するプリンシパルと、賃  
金を受け取って委託された職務を遂行するエージェントの関係として考えることが  
できる。次のような業績インセンティブ制度を仮定する。  
Ｐ　=　Ａ　+　Ｂ　×　Ｘ  
ここで、Ｐは賃金、Ａは固定給、Ｂは歩合、Ｘはエージェントの売上や生産量な  
どの成果である。  
この業績インセンティブ制度に関する記述として、最も適切なものはどれか。  
  
ア  　Ａが　0　の場合、完全業績給を意味するので、すべてのリスクを従業員（エー  
ジェント）が負担することになるので、サボタージュが起こる可能性が高くなる。  
  
イ  　Ａの割合が小さいほど、一般に、組織階層の下位にいる従業員（エージェント）  
にとってハイリスク・ハイリターンになり、階層の上位で利益責任を負う管理職  
（エージェント）にとってインセンティブを高める制度となる。  
  
ウ  　Ｂが　0　の場合、経営者（プリンシパル）側がすべてのリスクを負担することにな  
るので、リスク回避的傾向の高い従業員（エージェント）は積極的に職務にコミッ  
トする傾向が高くなる。  
  
エ  　環境のリスクが小さく、経営者（プリンシパル）が従業員（エージェント）の行動  
のモニタリング能力が高い状況では、Ａの割合が小さく、Ｂの割合が大きい業績  
  
イ  ンセンティブ制度が望ましい。  
  
オ  　業績の測定が難しい職務に携わる従業員（エージェント）ほど、Ｂの割合が高い  
業績インセンティブ制度のもとでよく動機づけられる。  
`
        },
        {
                "mondaiId": 103300200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
イノベーションを起こすために必要な専門知識が社会に分散し、オープンイノ  
ベーションや企業間システムの重要性が高まるとともに、オープンイノベーション  
の解釈も広く多義的になってきている。  
チェスブローが提唱したオリジナルのオープンイノベーションや企業間システム  
に関する記述として、最も適切なものはどれか。  
  
ア  　オープンイノベーションは、基盤技術の開発などのコラボレーションというよ  
りも、事業化レベルのコラボレーションを促進するという特徴がある。  
  
イ  　オープンイノベーションを促進するためには、ネットワーク外部性がある製品  
を開発している企業同士が共通の規格を採用する必要がある。  
  
ウ  　オープンイノベーションを通じて、自社内で技術開発投資を行う必要がなくな  
るため、コストやリスクを負担することなく、新製品を開発できるメリットがあ  
る。  
  
エ  　自社内の非効率な業務のアウトソースを通じて、オープンイノベーションを低  
コストで行うことができるようになる。  
  
オ  　製品アーキテクチャーがモジュラー化するほど垂直統合が進むため、企業間の  
水平的連携システムを通じたオープンイノベーションが重要になる。  
`
        },
        {
                "mondaiId": 103300210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織の成長や変革に介入する経営コンサルタントにとって、企業組織のライフサイクルに応じた課題や特徴についての理解が必要になることがある。組織のライフサイクルを、起業者段階、共同体段階、公式化段階、精緻化段階に分けて考えると  
き、それぞれの段階に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  　持続的な成長を迎える共同体段階では、従業員は自身が共同体の一員であると  
強く感じるため、職務の割り当てが専門化され、階層化が進むとともに中間管理  
職への権限委譲が必要になってくる。  
  
イ  　精緻化段階では、官僚制のもたらす形式主義的な弊害を克服するために、場合  
によっては公式のシステムを単純化し、チームやタスクフォースを活用して小企  
業的な価値観や発想を維持するために組織全体に絶えず新しい挑戦や努力を推奨  
する必要が生じる。  
  
ウ  　創業者が創造力の高い技術志向の経営者の場合、起業者段階では従業員は非公  
式で非官僚主義的なコミュニケーションで管理されることが多い。初期の市場が  
成長し、それに伴い従業員が増加すると、財務管理などを含めた、組織全体を統  
率するリーダーシップを持った経営者が必要になる。  
  
エ  　組織の規模も大きくなり公式化段階になると、規則や手続き、管理システムの  
公式化が進み、戦略的意思決定や業務的意思決定をトップマネジメントに集権化  
する必要が生まれ、トップが各事業部門を直接コントロールするようになる。  
`
        },
        {
                "mondaiId": 103300220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
職務遂行に当たって個人が抱く価値に対するセルフ・イメージは、キャリア・アンカー（例えば、技術的・機能的コンピテンス、全般的な管理コンピテンス、自律  
と独立、保障と安定、企業家的創造性、純粋な挑戦、奉仕と社会貢献、ライフスタイ  ル）と呼ばれる。キャリア・アンカーに関する記述として、最も適切なものはど  
れか。  
  
ア  　キャリア・アンカーは、現状を否定する学習を阻害する。  
  
イ  　個人が抱くキャリア・アンカーは、職種や企業ごとに類似していく傾向が見ら  
れる。  
  
ウ  　しっくりこないという経験を通じて自らのキャリア・アンカーを反省し、転職  
や働き方の変化につながる。  
  
エ  　人々は、生来の価値であるキャリア・アンカーに従って、職業を意識的に選択  
する。  
  
オ  　矛盾するキャリア・アンカーは、仕事経験を通じて　1　つに絞り込まれていく。  
`
        },
        {
                "mondaiId": 103300230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働者の過労死につながりかねない働き方の問題は、長時間労働だけが原因とな  
るのではなく、職場のストレスが強く関連している。企業のストレス管理はスト  
レッサーそのものの解消だけではなく、ストレッサーを解消しようとする介入のプ  
ロセスが重要であると言われている。  
ストレス管理における介入のプロセスに関する記述として、最も適切なものはど  
れか。  
  
ア  　過去に大きな変化を克服した経験をしている従業員に対しては、ストレス管理  
における介入によってトラウマを呼び起こさないようにしなければならない。  
  
イ  　従業員がストレスを抱えていることを知られることで不利益を被らないよう、  
現場の管理者をストレス管理における介入プロセスに関わらせないようにする。  
  
ウ  　ストレス管理における介入が従業員にさらなるストレスにならないよう、従業  
員には介入が行われることを知らせないほうが良い。  
  
エ  　ストレス管理の対象となる従業員を、介入案の策定や実施のプロセスに積極的  
に関わらせ、自身のストレッサーを自覚させるようにする。  
`
        },
        {
                "mondaiId": 103300240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働契約の期間に関する記述として、最も適切なものはどれか。なお、一定の事  
業の完了に必要な期間を定める労働契約については考慮しないものとする。  
  
ア  　期間の定めのない労働契約を締結している労働者については、いかなる場合で  
も定年年齢まで解雇することはできない。  
  
イ  　期間の定めのない労働契約を除き、　1　年を超える労働契約は締結できない。  
  
ウ  　期間の定めのない労働契約を除き、満　60　歳以上の労働者との間に締結される  
労働契約の期間は、最長　5　年である。  
  
エ  　期間の定めのない労働契約を除き、薬剤師の資格を有し、調剤業務を行う者と  
の間に締結される労働契約の期間は、最長　3　年である。`
        },
        {
                "mondaiId": 103300250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働基準法に定める割増賃金に関する記述として、最も適切なものはどれか。  
  
ア  　管理監督者を深夜に労働させた場合、通常の労働時間の賃金の計算額の　2　割　5分以上の率で計算した割増賃金を支払わなければならない。  
  
イ  　契約社員を年俸制で雇用する場合、年俸額が通常の労働時間の賃金に相当する  
部分と時間外労働による割増賃金に相当する部分とに明確に区分されているケー  
スでは、時間外労働の時間にかかわらず、年俸の月割額とは別に割増賃金を支払  
う必要はない。  
  
ウ  　毎週日曜日と土曜日を休日とする完全週休　2　日制の企業の場合、日曜日と土曜  
日のどちらの休日労働についても割増賃金率を　3　割　5　分以上としなければ、労働  
基準法違反となる。  
  
エ  　割増賃金の算定基礎から除外される賃金は、①家族手当、②通勤手当、③別居  
手当、④子女教育手当、⑤住宅手当、⑥臨時に支払われた賃金、⑦　1　カ月を超え  
る期間ごとに支払われる賃金の　7　種類のみであり、実際に支払われている手当が  
この　7　種類に該当するかどうかは、その名称により判断することになる。  
`
        },
        {
                "mondaiId": 103300260,
                "name": "第26問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
就業規則の作成や届け出、周知等に関する記述として、最も適切なものはどれ  
か。  
  
ア  　常時　10　人以上の労働者を使用する事業場の使用者は、就業規則を作成した場  
合、もしくはすでにある就業規則を変更した場合、14　日以内に所轄の労働基準  
監督署長に届け出て、その承認を得なければならない。  
  
イ  　常時　10　人以上の労働者を使用する事業場の使用者は、その労働者のうち大半  
がパートタイマーであっても、就業規則を定めて所轄の労働基準監督署長に届け  
出なければならない。  
  
ウ  　使用者は、就業規則を作成した場合、常時事業場の見やすい場所に掲示する方  
法では足りず、全労働者に配布する方法によって周知させなければならない。  
  
エ  　使用者は、就業規則を作成した場合、もしくはすでにある就業規則を変更した  
場合、労働者の過半数で組織する労働組合がある場合はその労働組合、ない場合  
は労働者の過半数を代表する者の同意を得なければならない。  
`
        },
        {
                "mondaiId": 103300270,
                "name": "第27問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
懲戒に関する記述として、最も適切なものはどれか。なお、本問におけるいずれ  
の処分も、就業規則において明確に規定されている懲戒事由および処分内容に則し  
てなされるものであることとする。  
  
ア  　過去に懲戒の対象となった行為について、重ねて懲戒することができる。  
  
イ  　自己都合によって退職した直後に、解雇に相当する懲戒事由が発覚した元従業  
員に対し、懲戒解雇基準を準用して退職金を不支給とすることは、いかなる場合  
でも認められない。  
  
ウ  　就業規則で、労働者に対して減給の制裁を定める場合においては、その減給  
は、　1　回の額が平均賃金の　1　日分の額を超え、総額が　1　賃金支払期における賃金  
の総額の　20　分の　1　を超えてはならない。  
  
エ  　懲戒処分によって出勤停止を命じた従業員に対する賃金は、出勤停止期間が適  
切な範囲内のものである限り、その出勤停止期間に対応する分は支給しなくても  
よい。  
`
        },
        {
                "mondaiId": 103300280,
                "name": "第28問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業のマーケティング・チャネルに関する意思決定として、最も適切なものはど  
れか。  
  
ア  　Ａ氏は、自ら経営するメーカーが生産する　LED　デスクライトの大量生産のテ  
スト稼働が始まったことから、新たに直販の　EC　サイトを開設し、消費者の持ち  
込んだデザインを反映した完全オーダーメードのデスクライトの受注生産に乗り  
出した。  
  
イ  　希少な天然繊維を用いた原料を独占的に調達することができ、その素材を用い  
たシャツを最大年間　2，000　枚程度供給することのできるメーカーＢ社は、少数の  
取引相手に販売を集約する目標を設定し、先端ファッション雑誌に広告を出稿す  
るとともに、国内に　250　店舗を有する総合スーパーでの全店取り扱いを目指して  
バイヤーとの交渉に着手した。  
  
ウ  　携帯通信端末の修理に長年携わってきたＣ社は、大手端末メーカーと変わらな  
い品質の部品調達が可能になったため、格安　SIM　カードによる音声通話・デー  
タサービスを提供する通信事業者と提携し、業務用オリジナル端末と通信サービ  
スを組み合わせたパッケージ商品の提案を開始した。  
  
エ  　手作りの知育玩具の製造卸Ｄ社の商品Ｘは、テレビのビジネス番組で報道され  
たことがきっかけとなり、現在では受注から納品まで　1　年以上を要するほどの大  
人気ブランドになっている。そこで、同社は商品Ｘの普及モデルＹを開発し、海  
外の大規模メーカーへの仕様書発注による商品調達を行い、100　円ショップで商  
品Ｙを同一ブランド名で販売することを検討し始めた。  
`
        },
        {
                "mondaiId": 103300290,
                "name": "第29問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年の流通チャネルの潮流に関する記述として、最も適切なものはどれか。  
  
ア  　インターネット上の仮想ショッピング・モールでは、テナント店舗数が増加  
し、取扱商品の幅と奥行きが拡大すると、購入者数と流通総額（取扱高）が増加す  
る効果が見られるが、消費者の探索効率が高まらない限り、その効果には限界が  
ある。  
  
イ  　オムニ・チャネル・リテイリングとは、小売業者が複数の業態のチェーンスト  アを経営することを通じて性格の異なる消費者クラスターごとに別々のチャネル  
で対応するための考え方である。  
  
ウ  　電子商取引のプラットフォームのうち、「商人型プラットフォーム」と呼ばれる  
形態がとられる場合、プラットフォームのユーザー数の増加がサービスの利便性  
を高めるという意味でのネットワーク外部���が発生しにくい。  
  
エ  　電子商取引のプラットフォームのうち、「マーケットプレイス型プラット  
フォーム」と呼ばれる形態がとられる場合、プラットフォームを介した流通総額  
（取扱高）がその経営主体の会計上の売上高として計上される。  
`
        },
        {
                "mondaiId": 103300300,
                "name": "第30問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マーケティング計画に関する記述として、最も適切なものはどれか。  
  
ア  　POS　データを用いた最も基本的な分析手法の　1　つはアクセス解析である。こ  
れは、購買金額の規模によって顧客をいくつかのグループに分け、それぞれのグ  
ループの顧客による売上や利益への貢献度を測定するものである。  
  
イ  　首都圏在住の大学生をターゲットとする就職活動支援サービスの展開を計画す  
る企業が、ターゲットの潜在ニーズを把握するために標本調査を実施する場合、  
母集団の規模とその男女構成比が事前に把握できるため、その比率に応じた標本  
抽出を行うことができる。この種の標本抽出法を系統的抽出法という。  
  
ウ  　マーケティング計画の初期段階においては二次データが用いられる場合が多い  
が、二次データは内的データと外的データに分類される。小売業者にとっては、  
POS　データなどの販売データは外的データである。  
  
エ  　洋菓子メーカーＡ社は、SNS　のフォロワーを　100　万人以上もつ若手人気モデ  
ルと契約し、SNS　を用いて若者をターゲットにしたスイーツに対するブランド  
のプロモーションを強化している。その狙いは、早期に前期大衆（early　majority）  
への普及を図ることである。これをキャズムを超えるという。  
`
        },
        {
                "mondaiId": 103300310,
                "name": "第31問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
製品開発に関する記述として、最も適切なものはどれか。  
  
ア  　「製品アイデア」とは企業が市場に提供する可能性のある製品を指すが、「製品  
コンセプト」といった場合には、これを顧客の立場から捉え、その製品が誰に  
とって、どのような時に、どのような問題解決をするものであるかを表現したも  
のである。  
  
イ  　製品開発においては、顧客の潜在的な欲求や期待についての情報を様々なリ  
サーチ手法を用いて捕捉し、そうしたニーズに基づいた開発を行うことが革新的  
な製品アイデアを導くための定石である。  
  
ウ  　製品開発の出発点は、新製品のアイデアを創出する過程であるが、そこでは社  
内外双方での情報収集が行われる。そのうち、社内におけるアイデアの源泉は研  
究開発部門と経営トップの　2　者に集約化されている。  
  
エ  　製品ライフサイクルの成熟期に差し掛かった製品のマーケティングにおいて  
は、ユーザー数の拡大によって製品の売上向上を図る「市場の修正」と製品価格の  
値下げによる需要喚起を狙った「価格の修正」の　2　つの組み合わせによるリポジ  
ショニングを実施する必要がある。  
`
        },
        {
                "mondaiId": 103300321,
                "name": "第32問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
　
　Ｄ氏が所有・経営するギョーザレストランは、現在　20　店舗を<u>①チェーンストア・オペレーション</u>で独立運営している。ランチタイムもディナータイムも毎日盛況な状況が続いており、商圏の　1　人暮らしの顧客からのリクエストに応える形で持ち帰 りサービスも始めている。そのような背景から、<u>②Ｄ氏はさらなる顧客満足の向上を目指している</u>。  

（設問　1　）  
文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  　Ｄ氏のレストラン・チェーンの店舗は、立地特性に応じて若干の違いをもた  
せているが、基本的に同形であり、チェーン本部が相当程度の中央統制を行っ  
ている。  
  
イ  　Ｄ氏のレストラン・チェーン本部と契約し、加盟店としてこのレストランを  
経営したいと申し出る企業が目立っている。この種の契約型チェーンはコーポ  
レート・チェーンと呼ばれる。  
  
ウ  　Ｄ氏のレストランは同一の所有の下で経営されている。この種のチェーンは  
フランチャイズ・チェーンと呼ばれる。  
  
エ  　Ｄ氏はレストランをチェーンストア化するにあたって、業務マニュアルの作  
成やスタッフの研修を行わないことにした。サービスを工業化・標準化するこ  
とが不可能であることがその理由である。  

`
        },
        {
                "mondaiId": 103300322,
                "name": "第32問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
　
　Ｄ氏が所有・経営するギョーザレストランは、現在　20　店舗を<u>①チェーンストア・オペレーション</u>で独立運営している。ランチタイムもディナータイムも毎日盛況な状況が続いており、商圏の　1　人暮らしの顧客からのリクエストに応える形で持ち帰 りサービスも始めている。そのような背景から、<u>②Ｄ氏はさらなる顧客満足の向上を目指している</u>。  

（設問　2　）  
文中の下線部②に関する記述として、最も適切なものはどれか。  
  
ア  　Ｄ氏のレストラン・チェーンでは顧客の満足度を測定するために、この  
チェーンが提供するスマホアプリを活用して顧客へのアンケートを実施し、顧  
客のリクエストをすべて実現することを最優先している。  
  
イ  　Ｄ氏のレストラン・チェーンは、低成長の市場環境での厳しい競争に打ち勝  
つためにサービス・マーケティングの　7Ps　の充実に努めているが、そのうち  
の　1　つであるサービスが提供される“Physical　Evidence（フィジカル・エビデ  
ンス）”には、店舗のロゴやサービスマークも含まれる。  
  
ウ  　Ｄ氏はレストラン・チェーンの従業員の動機づけを行うために自社の行動規  
範を分かりやすくまとめた“CREDO（クレド）”と呼ばれるカードを全従業員に  
配布し、毎日の始業時にその内容を相互に確認しているが、これはとくに調  
理・接客技術の向上に直接的に有効である。  
  
エ  　Ｄ氏はレストラン・チェーンの店舗網拡大に先駆けて、大手のレストラン予  
約サイトと契約して、顧客が所定の日時にお得なコース料理を事前に予約する  
ことのできるバウチャーの販売を始めたが、これは直接流通の経路の拡張によ  
るサービス拡販の典型例である。  
`
        },
        {
                "mondaiId": 103300330,
                "name": "第33問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マーケティング概念に関する記述として、最も適切なものはどれか。  
  
ア  　近年では様々なソーシャルメディアが普及しており、とくに　SNS　を活用した  
顧客関係性の構築に基づくマーケティングのあり方は、ソーシャル・マーケティ  
ングと呼ばれている。  
  
イ  　ソサイエタル・マーケティング・コンセプト（societal　marketing　concept）で  
は、標的市場のニーズや欲求、利益を正しく判断し、消費者と社会の幸福を維  
持・向上させる方法をもって、顧客の要望に沿った満足を他社よりも効果的かつ  
効率的に提供することが営利企業の役割であるとしている。  
  
ウ  　マーケティングは営利企業の市場創造においてだけでなく、美術館や病院、  
NPO　などの非営利組織にも適用されているが、非営利組織のマーケティングに  
おいてはマーケティング・ミックスのうちの価格要素の持つ相対的重要性は低  
い。  
  
エ  　マーケティング・ミックスの　4　つのＰは買い手に影響を与えるために利用でき  
るマーケティング・ツールを売り手側から見たものであるが、これらを買い手側  
から見ると　4　つのＣとしてとらえることができる。4Ps　の　Place　に対応するもの  
は、Customer　cost、つまり顧客コストである。  
  
オ  　マーケティング・ミックスは企業が設定した標的市場においてそのマーケティ  
ング目標を実現するための一貫したツールとしてとらえられるが、そのうちの販  
売促進の修正は、他のマーケティング・ミックス要素の修正と比べて長期間を要  
するものである。  
`
        },
        {
                "mondaiId": 103300340,
                "name": "第34問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
価格に対する消費者の反応に関する記述として、最も適切なものはどれか。  
  
ア  　　2　つの価格帯を用意した場合と、それらにさらなる高価格帯を追加し　3　つの価  
格帯を用意した場合のいずれにおいても、金銭的コストが最小となる低価格帯の  
商品が選択されやすい。  
  
イ  　健康効果が期待される菓子について、一般的に価格が高いとされる健康食品と  
して購入者が認識する場合のほうが、嗜好品として認識する場合よりも高い価格  
帯で受容されやすい。  
  
ウ  　消費者は、切りの良い価格よりも若干低い価格に対して反応しやすい。これをイメージ・プライシングと呼ぶ。  
  
エ  　マンションを購入した人は、家具や家電品をあわせて購入することが多い。高  
額商品を購入した直後の消費者は、一般的に、支出に対して敏感になり、値頃感  
のある商品を求めやすいことが心理的財布という考えで示されている。  
`
        },
        {
                "mondaiId": 103300351,
                "name": "第35問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　プロモーションの役割は、<u>①広告、販売促進、人的販売、パブリックリレーションズ（PR）の　4　つの手段</u>を用いて、製品やサービスに関する情報を伝達し、魅力をアピールし、販売を促進することである。プロモーション効果を高めるためには、その目的や、対象となる<u>②製品・サービスの特性および知名、理解、好意・選好、購買、再購買といった購買プロセスの段階に応じて、　4　つのプロモーション手段を使い分けたり、適切に組み合わせたりする</u>ことが重要である。  

（設問　1　）  
文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  　2016　年度の日本の広告費に関する注目点は、製作費を含むインターネット  
広告費が、はじめて、テレビメディア広告費を上回ったことである。  
  
イ  　従業員と家族を対象にした運動会や部署旅行および従業員の家族を対象にし  
た職場見学会を実施する企業が出てきている。これらの活動は　PR　の一環と捉  
えることができる。  
  
ウ  　人的販売は、テレビ広告と比較して、到達する消費者　1　人当たりの情報伝達  
コストが小さい。  
  
エ  　パブリシティは、企業や製品に関する情報の公表を通じて、新聞や雑誌など  
のメディアに取り上げてもらうための広告活動の　1　つである。  
`
        },
        {
                "mondaiId": 103300352,
                "name": "第35問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　プロモーションの役割は、<u>①広告、販売促進、人的販売、パブリックリレーションズ（PR）の　4　つの手段</u>を用いて、製品やサービスに関する情報を伝達し、魅力をアピールし、販売を促進することである。プロモーション効果を高めるためには、その目的や、対象となる<u>②製品・サービスの特性および知名、理解、好意・選好、購買、再購買といった購買プロセスの段階に応じて、　4　つのプロモーション手段を使い分けたり、適切に組み合わせたりする</u>ことが重要である。  

（設問　2　）  
文中の下線部②に関する記述として、最も適切なものはどれか。  
  
ア  　　4　つのプロモーション手段の重要性は、対象が消費財か生産財かによって変  
わるが、いずれの場合も、広告が最も重要である。  
  
イ  　企業が広告を作成する狙いには、再購買時のブランド想起を促進したり、購  
買後に消費者が感じる認知不協和を減らしたりする効果を生み出すことが含ま  
れる。  
  
ウ  　製品やサービスに対する知名率や理解率が高いものの購買に至らない場合、  
買い手の当該製品やサービスに対する関与の高低にかかわらず、短期的なイン  
センティブを提供する販売促進が最も有効である。  
  
エ  　プッシュ戦略と比較して、プル戦略の場合、一般的に、人的販売が重要視さ  
れる。  
`
        },
        {
                "mondaiId": 103300361,
                "name": "第36問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

顧客リレーションシップのマネジメントにおいて、企業は、<u>①収益性の高い優良顧客を識別し、優れた顧客価値を提供することで関係性の構築、維持、強化に努め</u>、<u>②ブランド・ロイヤルティなどの成果を獲得することを目指している</u>。  

（設問　1　）  
文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  　初めて購入した顧客がリピート顧客、さらには得意客やサポーターになるよ  
うに、関係性にはレベルがある。自分のすばらしい経験を、顧客が他者に広め  
ているかどうかは、関係性レベルの高さを判断するための手段となる。  
  
イ  　パレートの法則をビジネス界に当てはめると、売上の　50　％が上位　50　％の優  
良顧客によって生み出される。  
  
ウ  　優良顧客の識別には、対象製品の購買においてクロスセルやアップセルが  
あったか否かは重視されない。  
  
エ  　優良顧客の識別のために用いられる　RFM　分析とは、どの程度値引きなしで  
購買しているか（Regular）、どの程度頻繁に購買しているか（Frequency）、ど  
の程度の金額を支払っているのか（Monetary）を分析することである。  

`
        },
        {
                "mondaiId": 103300362,
                "name": "第36問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

顧客リレーションシップのマネジメントにおいて、企業は、<u>①収益性の高い優良顧客を識別し、優れた顧客価値を提供することで関係性の構築、維持、強化に努め</u>、<u>②ブランド・ロイヤルティなどの成果を獲得することを目指している</u>。  

（設問　2　）  
文中の下線部②に関する記述として、最も適切なものはどれか。  
  
ア  　関係性が構築され、それがさらに維持、強化されることで、特定顧客におけ  
る同一製品カテゴリーの購買全体に対して自社製品が占める割合、つまり市場  
シェアの拡大が見込める。  
  
イ  　関係性が構築されると、同一製品を再購買する傾向である「行動的ロイヤル  
ティ」が高まる。それはブランド・コミットメントと言い換えられる。  
  
ウ  　消費者は、惰性、サンクコストや所有効果により、現在利用している製品や  
サービスを変更しない傾向がある。こうした傾向が、真のロイヤル顧客の識別  
を難しくする。  
  
エ  　製品やサービスの購入および利用に対してポイントを付与することにより再  
購入を促し、継続的な取引の維持を目指そうとする「ロイヤルティ・プログラ  
ム」は、多くの企業が採用可能な、経済効率の非常に高い施策とみなされてい  
る。  
`
        },
        {
                "mondaiId": 103300370,
                "name": "第37問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ブランドマネジメントに関する記述として、最も適切なものはどれか。  
  
ア  　これまでに製品を投入したことのないカテゴリーにおいて、すでに他のカテゴ  
リーで実績のあるブランド名を用いて新製品を投入することを「ブランド・リポジショニング戦略」と呼ぶ。  
  
イ  　ターゲットとする性別・年代、価格帯やイメージが異なる複数の製品ラインを  
展開する場合、メーカー名などを冠した統一的なブランドと個々のブランドを組  
み合わせた「ダブルチョップ戦略」が適切である。  
  
ウ  　ブランド間の知覚差異は大きいが製品自体やその購買への関与度は低い、とい  
う状況でのブランド展開においては、「マルチ・ブランド戦略」が採用されやす  
い。  
  
エ  　ブランドには、メーカー名がつけられる場合や独自の商品名がつけられる場合  
がある。前者をプライベート・ブランドと呼ぶ。
`
        },
        {
                "mondaiId": 103300380,
                "name": "第38問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ブランドカテゴライゼーションに関する記述として、最も適切なものはどれか。  
  
ア  　消費者の長期記憶の中で、ブランド情報は構造化され、保持されている。購買  
意思決定プロセスにおいて、消費者は、保持するすべてのブランド情報を均等に  
検討し、　1　つのブランドに絞り込む。  
  
イ  　「想起集合」とは、消費者が購入を真剣に検討する対象のことであり、「考慮集  
合」と呼ばれることもある。  
  
ウ  　「想起集合」に入るためには、当該製品カテゴリーの中で際立った異質性をもた  
せることが重要である。  
  
エ  　「保留集合」とは、判断に必要な情報が不足しているため、購買の意思決定が先  
送りされているブランドのことである。`
        },
        {
                "mondaiId": 104300010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産における管理目標（PQCDSME）に関する記述として、最も適切なものはど  
れか。  
  
ア  　<hintTarget>職場環境に関する評価</hintTarget>を行うために、検査によって不適合と判断された製品の  
数を検査対象の製品の総数で除して求められる不適合率を用いた。  
  
<hint hidden='true'>**
❌不適合率はQualityに関する評価を行うために使用されます。
**</hint>

イ  　職場の安全性を評価するために、延べ労働損失日数を延べ実労働時間数で除し  
1，000　を乗じて求められる強度率を用いた。  
  
<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ  　生産の効率性を評価するために、<hintTarget>労働量を生産量で除して</hintTarget>求められる労働生産  
性を用いた。  

<hint hidden='true'>**
❌生産性の公式は、  
生産性 ＝ 産出量（アウトプット）　÷　投入量（インプット）  
なので、分母分子が逆です。
**</hint>
  
エ  　納期に関する評価を行うために、動作可能時間を動作可能時間と動作不能時間  
の合計で除して求められる<hintTarget>可用率</hintTarget>を用いた。

<hint hidden='true'>**
❌可用率（アベイラビリティ、稼働率とも呼ぶ）の公式は、選択肢の記述のとおりですが、納期に関する評価ではなく、Productivityに関する評価に用いられます。
**</hint>

`
        },
        {
                "mondaiId": 104300020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
加工方法が多様で、需要が安定していない寿命の短い製品の多品種少量生産に関  
する記述として、最も適切なものはどれか。  
  
ア  　加工品の流れが一定ではないので、機能別レイアウトを導入した。  
  
イ  　需要の動向にあわせて頻繁に生産計画を変更することが必要なので、MRP　を  
導入した。  
  
ウ  　需要変動に対応するためには、生産量の変動で対応するより完成品在庫で対応  
することが効果的である。  
  
エ  　スループットタイムを短くし、コストダウンを図るために専用ラインを導入す  
ることが効果的である。  
`
        },
        {
                "mondaiId": 104300030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ある工場のレイアウト改善に関する次の文章の空欄ＡとＢに入る語句として、最  
も適切なものの組み合わせを下記の解答群から選べ。  
この工場では複数の設備を用いて製品の加工を行っており、各設備を製品ごとに  
直線に配置したレイアウトをとっている。最近、製品の種類が多様化してきたため  
加工方法が複雑になり、工程間の搬送の手間が増えてきたという問題点を抱えてい  
た。  
そこで、ものの流れに関する問題点の発見のために　PQ　分析を行った。その結果  
が下図の（　Ａ　）であったので、（　Ｂ　）を作成した。それに基づいて工程編  
成を見直し、設備のレイアウトをジョブショップ型レイアウトに変更した。  

<imgTag>104300030_1.png</imgTag>  

〔解答群〕  
  
ア  　Ａ：タイプⅠ　　Ｂ：多品種工程図表  
  
イ  　Ａ：タイプⅠ　　Ｂ：流れ線図  
  
ウ  　Ａ：タイプⅡ　　Ｂ：多品種工程図表  
  
エ  　Ａ：タイプⅡ　　Ｂ：流れ線図  

`
        },
        {
                "mondaiId": 104300040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ある職場では　3　種類の製品Ａ、Ｂ、Ｃを製造している。この職場の作業条件は以  
下に示すとおりである。  

＜作業条件＞  
・各製品は第　1　工程と第　2　工程で同じ順序で加工される。  
・各工程では一度加工が始まったら、その製品が完成するまで同じ製品を加工する。  
・工程間の運搬時間は　0　とする。  
・各製品の各工程における作業時間と納期は下表に示される。  

<imgTag>104300040_1.png</imgTag>  

また、第　1　工程において製品をＡ、Ｂ、Ｃの順に投入した場合のガントチャート  
は下図のように示され、総所要時間は　18　時間となる。  

<imgTag>104300040_2.png</imgTag>  


この職場に製品がＡ、Ｃ、Ｂの順で到着した場合の、第　1　工程における投入順序  
決定に関する記述として、最も適切なものはどれか。  
  
ア  　　3　つの製品を　SPT　順に投入すると、総所要時間は　15　時間である。  
  
イ  　　3　つの製品を到着順に投入すると、総所要時間は　14　時間である。  
  
ウ  　　3　つの製品を到着順に投入すると、納期遅れはなくなる。  
  
エ  　　3　つの製品を納期順に投入すると、納期遅れはなくなる。  
`
        },
        {
                "mondaiId": 104300050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
マシニングセンタに関する記述として、最も適切なものはどれか。  
  
ア  　工作物を回転させ、主としてバイトなどの静止工具を使用して、外丸削り、中  
ぐり、突切り、正面削り、ねじ切りなどの切削加工を行う工作機械。　  
  
イ  　異なる機能をもつ数台から数十台の工作機械を等間隔、かつ、直線状に配置  
し、それらを搬送装置で結合した工作機械群。  
  
ウ  　自動制御によるマニピュレーション機能または移動機能をもち、各種の作業を  
プログラムによって実行できる、産業に使用される機械。  
  
エ  　主として回転工具を使用し、フライス削り、中ぐり、穴あけおよびねじ立てを  
含む複数の切削加工ができ、かつ、加工プログラムに従って工具を自動交換でき  
る数値制御工作機械。　  
`
        },
        {
                "mondaiId": 104300060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表に示される作業Ａ〜Ｆで構成されるプロジェクトについて、PERT　を用い  
て日程管理をすることに関する記述として、最も適切なものを下記の解答群から選  
べ。  


<imgTag>104300060_1.png</imgTag>  


〔解答群〕  
  
ア  　このプロジェクトのアローダイアグラムを作成するためには、ダミーが　2　本  
必要である。  
  
イ  　このプロジェクトの所要日数は　8　日である。  
  
ウ  　このプロジェクトの所要日数を　1　日縮めるためには、作業Ｆを　1　日短縮すれ  
ばよい。  
  
エ  　作業Ｅを最も早く始められるのは　6　日後である。  
`
        },
        {
                "mondaiId": 104300070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
1　台の工作機械で　2　種類の製品Ａ、Ｂを加工している職場における基準日程計画  
について考える。計画作成上の前提条件は以下に示すとおりである。  

＜計画作成上の前提条件＞  
・製品Ａのロットサイズは　40　個で、加工時間は　0.5　時間/個である。  
・製品Ｂのロットサイズは　60　個で、加工時間は　1.0　時間/個である。  
・　1　期当たりの製造可能時間の上限は　60　時間である。  
・ロット分割はできない。  
・各製品の生産は　1　期しか前倒しができない。  


この条件の下で、　1　期から　6　期までの予測需要量と　1　期目の期首在庫量から、生  
産能力を考慮しない場合の製品Ａ、Ｂそれぞれの各期の生産量と必要生産時間を求  
めた。このときの期別の必要生産時間を下図に示す。

<imgTag>104300070_1.png</imgTag>  


各製品の生産が　1　期前倒しできることを考慮して、実行可能となる基準日程計画  
を作成した。このときの　1　期から　6　期までの製品Ｂの必要生産時間として、最も適  
切なものはどれか。  

  
ア    
１期　２期　３期　４期　５期　６期
６０　６０　０　　０　　０　　６０

イ    
１期　２期　３期　４期　５期　６期
６０　６０　０　　６０　０　　６０
  
ウ    
１期　２期　３期　４期　５期　６期
６０　２０　４０　０　　２０　４０
  
エ    
１期　２期　３期　４期　５期　６期
６０　２０　４０　０　　０　　６０

`
        },
        {
                "mondaiId": 104300080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
NC　工作機械　5　台を　2　人の作業者でオペレーションしている。ワークの着脱作業  
は作業者が行う。作業者によってワークが取りつけられプログラムが入力されれば  
自動的に加工が行われ、終了すると自動的に停止する。現在、この職場では作業者  
の稼働率が高く、機械が段取待ちで停止していることが多く発生している。  
この職場における改善活動に関する記述として、最も適切なものはどれか。  
  
ア  　各機械の稼働率を調べるため、管理図を作成した。  
  
イ  　機械の停止時間を短くするため、加工時間を短縮する加工方法の検討を行っ  
た。  
  
ウ  　作業者の持ち台数を検討するため、マン・マシンチャートを作成した。  
  
エ  　製品の平均スループットタイムを短くするため、MTM　法による分析を行っ  
た。  
`
        },
        {
                "mondaiId": 104300090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　品質改善に関する次の文章の空欄Ａ〜Ｃに入る　QC7つ道具として、最も適切なものの組み合わせを下記の解答群から選べ。  
　ある職場において不適合品が多発している。<hintTarget>重要項目を絞り込む</hintTarget>ため不適合の種類と発生数を調べ（　Ａ　<hint hidden='true'>**
パレート図
** </hint>）を作成した。その結果、重量に関する不適合が最も大きな割合を占めていることが分かった。そこで重量の（　Ｂ　<hint hidden='true'>**
ヒストグラム
** </hint>）を作成した。その結果、<hintTarget>重量のバラツキが大きい</hintTarget>ため、不適合が発生していることが分かった。この重量に影響を及ぼす要因について、過去の知見を特性要因図として整理し、加工速度に着目することとなった。そこで<hintTarget>加工速度と重量の関係</hintTarget>を調べるため（　Ｃ　<hint hidden='true'>**
散布図
** </hint>）を作成した。  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】QC7７つ道具  
<TABLE border="5" bordercolor="#ffffff" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD style='background-color:#ffdddd;'>チェックシート</TD>
<TD><imgTag>104010110_2.png</imgTag></TD>
<TD style='background-color:#ddddff;'><hintTarget>確認事項</hintTarget>をまとめて、データ収集を効率化したり、整理する
</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>特性要因</hintTarget>図</TD>
<TD><imgTag>104010110_3.png</imgTag></TD>
<TD style='background-color:#ddddff;'>結果と原因の関係を分析する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>パレート</hintTarget>図</TD>
<TD><imgTag>104010110_4.png</imgTag></TD>
<TD style='background-color:#ddddff;'>不具合の原因を<hintTarget>発生頻度</hintTarget>順に並べて、原因調査の絞り込みなどに活用する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>ヒストグラム</hintTarget>
</TD>
<TD><imgTag>104010110_5.png</imgTag></TD>
<TD style='background-color:#ddddff;'>区分ごとのデータの分布を俯瞰する
</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>散布</hintTarget>図</TD>
<TD><imgTag>104010110_6.png</imgTag></TD>
<TD style='background-color:#ddddff;'>X,Y軸に2つの特性を配置して相関の有無を見る</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'>管理図</TD>
<TD><imgTag>104010110_7.png</imgTag></TD>
<TD style='background-color:#ddddff;'><hintTarget>許容範囲の上限加減の逸脱状況</hintTarget>の点検などに使用する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'>グラフ</TD>
<TD>　</TD>
<TD style='background-color:#ddddff;'>必要に応じて可視化して、実態把握することを推奨しています</TD>
</TR>

</TABLE>
</div>
</hint>

〔解答群〕  
  
ア  　Ａ：パレート図　　　Ｂ：散布図　　　　　Ｃ：ヒストグラム  
  
イ  　Ａ：パレート図　　　Ｂ：ヒストグラム　　Ｃ：散布図<hint hidden='true'>**
←⭕
** </hint>    
  
ウ  　Ａ：ヒストグラム　　Ｂ：散布図　　　　　Ｃ：パレート図  
  
エ  　Ａ：ヒストグラム　　Ｂ：パレート図　　　Ｃ：散布図  

`
        },
        {
                "mondaiId": 104300100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある製品について行った製品工程分析の結果を下図に示す。この図から読み取る  
ことができる記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>104300100_1.png</imgTag>  

〔解答群〕  
  
ア  　作業者が　4　名いる。  
  
イ  　製品検査に抜取検査を採用している。  
  
ウ  　台車を自動搬送機にすることにより、運搬記号の数を減らすことができる。  
  
エ  　停滞を表す工程が　3　カ所ある。  

`
        },
        {
                "mondaiId": 104300110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
トヨタ生産方式の特徴を表す用語として、最も適切なものの組み合わせを下記の  
解答群から選べ。  

ａ　MRP  
ｂ　かんばん方式  
ｃ　セル生産方式  
ｄ　製番管理方式  
ｅ　あんどん方式  

〔解答群〕  
  
ア  　ａとｃ  
  
イ  　ａとｄ  
  
ウ  　ｂとｃ  
  
エ  　ｂとｅ    
  
オ  　ｄとｅ  
`
        },
        {
                "mondaiId": 104300120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある見込生産型工場における需要予測において、従来と比較して、過去の実績需  
要量の中でも現在に近いものほど次月の需要量に大きく影響することが分かってき  
た。予測精度を向上させる試みに関する記述として、最も適切なものの組み合わせ  
を下記の解答群から選べ。  
ａ　移動平均法においては、対象範囲を　3　カ月から　5　カ月に変更する。  
ｂ　移動平均法においては、対象範囲を　5　カ月から　3　カ月に変更する。  
ｃ　指数平滑法においては、平滑化定数を　0.3　から　0.5　に変更する。  
ｄ　指数平滑法においては、平滑化定数を　0.5　から　0.3　に変更する。  
〔解答群〕  
  
ア  　ａとｃ  
  
イ  　ａとｄ  
  
ウ  　ｂとｃ  
  
エ  　ｂとｄ`
        },
        {
                "mondaiId": 104300130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
資材の発注に関する記述として、最も適切なものはどれか。  
  
ア  　MRP　では、発注量と発注時期を生産計画と独立に決定できる。  
  
イ  　定期発注方式における発注量は、（発注間隔　+　調達期間）中の需要推定量　-　発注残　-　手持在庫量　-　安全在庫量で求められる。  
  
ウ  　発注間隔を長くすることにより、きめの細かい在庫管理ができ在庫量が減少す  
る。  
  
エ  　発注点は、調達期間中の払出量の大きさと不確実性を考慮して決定される。  
`
        },
        {
                "mondaiId": 104300140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
JIS　で定義される現品管理の活動として、<u>最も不適切なものはどれか</u>。  
  
ア  　受け入れ外注品の品質と数量の把握  
  
イ  　仕掛品の適正な保管位置や保管方法の設定  
  
ウ  　製品の適正な運搬荷姿や運搬方法の検討  
  
エ  　利用資材の発注方式の見直し`
        },
        {
                "mondaiId": 104300150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
新製品を組み立てるための標準時間を　PTS（Predetermined　Time　Standard）法  
を利用して算定することにした。標準時間を設定するための準備に関する記述とし  
て、最も適切なものの組み合わせを下記の解答群から選べ。  
ａ　PTS　法で算定された標準時間を組立作業を行う作業者の習熟度に応じて調整  
するために、作業者の組立職場での就業年数を調査した。  
ｂ　設備による加工時間を別途付与するために、設備で試加工を実施して加工時間  
を計測した。  
ｃ　標準時間を見積もるための基礎資料を整備するために、既存製品の組立作業に  
対して時間分析を実施した。  
ｄ　試作品を組み立てるための模擬ラインを敷設して、製品組立の標準作業を決定  
した。  

〔解答群〕  
  
ア  　ａとｂ  
  
イ  　ａとｄ  
  
ウ  　ｂとｃ  
  
エ  　ｂとｄ  
`
        },
        {
                "mondaiId": 104300161,
                "name": "第16問設問１",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

ある工場では、自動設備を利用して飲料の瓶詰を行っているが、瓶に詰められた  
内容量のバラツキを抑制する目的で新設備を試作した。この工場では、仮説検定を  
行うことで、試作機の性能向上を確かめたいと考えている。  

（設問　1　）  
現有設備を使用したときの内容量の標準偏差　δ0　が　1.1　ml　であることから、新  
設備を使ったときの内容量の標準偏差を　δ　としたもとで、以下のように帰無仮  
説　H0　を設定した。対立仮説　H1　として、最も適切なものを下記の解答群から選  
べ。  

<imgTag>104300161_1.png</imgTag>  

〔解答群〕  
  
ア  　　<imgTag>104300161_2.png</imgTag>  
  
イ  　　<imgTag>104300161_3.png</imgTag>  
  
ウ  　　<imgTag>104300161_4.png</imgTag>  
  
エ  　　<imgTag>104300161_5.png</imgTag>  

`
        },
        {
                "mondaiId": 104300162,
                "name": "第16問設問２",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

ある工場では、自動設備を利用して飲料の瓶詰を行っているが、瓶に詰められた  
内容量のバラツキを抑制する目的で新設備を試作した。この工場では、仮説検定を  
行うことで、試作機の性能向上を確かめたいと考えている。  

（設問　2　）  
仮説検定を行うために、新設備で瓶詰をした瓶の中から　n　=　8　本のサンプルを取り出して内容量を計測したところ、以下のデータが得られた。  


54.0　53.8　54.5　54.2　53.8　53.6　54.6　55.0　（ml）  

<imgTag>104300162_1.png</imgTag>  

分散の検定は、サンプルから計算される統計量が自由度　n　-　1　のカイ二乗分布に従うことを利用して行われる。この統計量として、最も適切なものはどれか。  


ア  　　<imgTag>104300162_2.png</imgTag>  
  
イ  　　<imgTag>104300162_3.png</imgTag>  
  
ウ  　　<imgTag>104300162_4.png</imgTag>  
  
エ  　　<imgTag>104300162_5.png</imgTag>  

`
        },
        {
                "mondaiId": 104300171,
                "name": "第17問設問１",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

ある製品についての毎期の需要が下表のように予測されている。製品の発注費が1　回当たり　4，000　円、保管費が　1　個　1　期当たり　20　円のもとで、発注費と保管費の総和である総費用を最小化する最適発注計画を考えたい。ただし、製品は、期首に発注し即時に納入される。従って、最適発注計画では、発注は期首在庫量が　0　である期に限られ、発注量はその後の需要量の何期分かになる。また、保管費は、当期  
に納入された製品の中で、翌期以降に持ち越した量にだけ発生するものとする。  

<imgTag>104300171_1.png</imgTag>  


（設問　1　）  
発注計画を各期首に発注された製品量の組によって表すとき、発注計画（260，0，0）の総費用として、最も適切なものはどれか。  
  
ア  　10，000　円  
  
イ  　10，400　円  
  
ウ  　11，600　円  
  
エ  　12，000　円  
`
        },
        {
                "mondaiId": 104300172,
                "name": "第17問設問２",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

ある製品についての毎期の需要が下表のように予測されている。製品の発注費が1　回当たり　4，000　円、保管費が　1　個　1　期当たり　20　円のもとで、発注費と保管費の総和である総費用を最小化する最適発注計画を考えたい。ただし、製品は、期首に発注し即時に納入される。従って、最適発注計画では、発注は期首在庫量が　0　である期に限られ、発注量はその後の需要量の何期分かになる。また、保管費は、当期  
に納入された製品の中で、翌期以降に持ち越した量にだけ発生するものとする。  

<imgTag>104300172_1.png</imgTag>  

（設問　2　）  
予測されている需要量のもとで最適発注を行ったときの総費用として、最も適  
切なものを下記の解答群から選べ。なお、下表は計算過程の一部を示したもので  
ある。  


<imgTag>104300172_2.png</imgTag>  


〔解答群〕  
  
ア  　　　7，400　円  
  
イ  　　　8，000　円  
  
ウ  　　　9，200　円  
  
エ  　10，000　円  
`
        },
        {
                "mondaiId": 104300180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある作業の出現率をワークサンプリング法を使って推定したい。出現率を信頼度95　％、相対誤差　a　で推定するために必要なサンプル数　n　は次式で与えられる。ここで、　p　は予備調査により予想された作業の出現率である。  

<imgTag>104300180_1.png</imgTag>  

このサンプル数　n　を絶対誤差　e　を用いて求める下記の計算式について、空欄に  
入る最も適切なものを下記の解答群から選べ。  

<imgTag>104300180_2.png</imgTag>  



〔解答群〕  
  
ア  　1　-　p  
  
イ  　　p  
  
ウ　　　　p　  
　　　<u>　　　　　</u>  
　　　1　－　p
  
エ  　　p　（1　-　p　）  
`
        },
        {
                "mondaiId": 104300190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
JIS　で定義される設備故障とその保全活動に関する記述として、最も適切なもの  
はどれか。  
  
ア  　機能停止型故障を抑制するために、事後保全を実施した。  
  
イ  　寿命特性曲線上での設備の初期故障を抑制するために、保全予防を実施した。  
  
ウ  　設備故障の状態は、「設備が規定の機能を失う状態」と「設備が規定の性能を満  
たせなくなる状態」の　2　つに分類される。  
  
エ  　設備の信頼性を表す故障強度率は、１－（故障停止時間の合計　÷　負荷時間の合計）によって計算さ  れる。`
        },
        {
                "mondaiId": 104300200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
生産現場で行われる改善に関する記述として、最も適切なものはどれか。  
  
ア  　あい路工程での出来高を向上させる目的で、その直前工程の処理能力を高め  
た。  
  
イ  　生産ラインの編成効率を高める目的で、生産ラインのＵ字化を検討した。  
  
ウ  　同一製品を継続生産する職場での進度管理の手間を省く目的で、製番管理を導  
入した。  
  
エ  　入社直後のパート従業員を短期間で組立職場に配置できるようにする目的で、  
1　人生産方式を導入した。  
`
        },
        {
                "mondaiId": 104300210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章は、いわゆる「まちづくり三法」のねらいに関する記述である。空欄Ａ〜  
Ｃに入る語句として、最も適切なものの組み合わせを下記の解答群から選べ。  

中心市街地活性化法は、都市中心部の衰退化現象に歯止めをかけるべく、都市中  
心部に対して政策的に資源を集中しようとするものであり、従来の（　Ａ　）政策  
の系譜の中での取り組みである。（　Ｂ　）ではゾーニング的手法によって商業施  
設の立地を計画的に誘導することが期待され、（　Ｃ　）では施設周辺の生活環境  
を保持する観点からチェックが行われる。  
〔解答群〕  
  
ア  　Ａ：競争　　Ｂ：大規模小売店舗立地法　　Ｃ：都市計画法  
  
イ  　Ａ：競争　　Ｂ：都市計画法　　　　　　　Ｃ：大規模小売店舗立地法  
  
ウ  　Ａ：振興　　Ｂ：大規模小売店舗立地法　　Ｃ：都市計画法  
  
エ  　Ａ：振興　　Ｂ：都市計画法　　　　　　　Ｃ：大規模小売店舗立地法  
`
        },
        {
                "mondaiId": 104300220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業庁『平成　27　年度商店街実態調査報告書』で用いられている商店街のタイ  
プに関する説明として、最も適切なものはどれか。  
  
ア  　近隣型商店街：最寄り品と買回り品の店舗が混在する商店街で、地域型商店街  
よりやや広い範囲であることから、徒歩、自転車、バス等で来街する商店街  
  
イ  　広域型商店街：百貨店・量販店を含む大型店があり、買回り品よりも最寄り品  
の店舗が多い商店街  
  
ウ  　地域型商店街：最寄り品中心の商店街で、徒歩または自転車等により買い物を  
行う商店街  
  
エ  　超広域型商店街：百貨店・量販店を含む大型店があり、有名専門店、高級専門  
店を中心に構成���れ、遠距離からも来街する商店街`
        },
        {
                "mondaiId": 104300230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商圏分析として、Ａ市およびＢ市がその中間に位置するＸ町から吸引する購買額  
の割合を、ライリーの法則に基づいて求めたい。その計算に必要な比率として、最  
も適切なものの組み合わせを下記の解答群から選べ。  
ａ　「Ａ市の人口」と「Ｂ市の人口」の比率  
ｂ　「Ａ市の面積」と「Ｂ市の面積」の比率  
ｃ　「Ａ市とＸ町の距離」と「Ｂ市とＸ町の距離」の比率  
ｄ　「Ａ市とＸ町の住民の総所得の差」と「Ｂ市とＸ町の住民の総所得の差」の比率  
〔解答群〕  
  
ア  　ａとｃ  
  
イ  　ａとｄ  
  
ウ  　ｂとｃ  
  
エ  　ｂとｄ  
`
        },
        {
                "mondaiId": 104300240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章は、照明の基礎知識に関する解説である。空欄Ａ〜Ｃに入る語句または  
数値として、最も適切なものの組み合わせを下記の解答群から選べ。  

照度とは自然光や人工照明で照らされた場所の明るさを意味する用語であり、一  
般的に（　Ａ　）の単位で表される。JIS　では維持照度の推奨値が示されている。  
例えば、商店（一般共通事項）の重要陳列部は　750（　Ａ　）であり、大型店（デ  
パートや量販店など）の重要陳列部は（　Ｂ　）（　Ａ　）である。  
照明された物の色の見え方を表す光源の性質を客観的に示すために、JIS　では（　Ｃ　）が用いられている。例えば、商店（一般共通事項）および大型店（デパー  
トや量販店など）の重要陳列部の推奨最小値は　80　である。  

〔解答群〕  
  
ア  　Ａ：ルクス　　Ｂ：　500　　Ｃ：平均光色評価数  
  
イ  　Ａ：ルクス　　Ｂ：2，000　　Ｃ：平均演色評価数  
  
ウ  　Ａ：ルクス　　Ｂ：2，000　　Ｃ：平均光色評価数  
  
エ  　Ａ：ワット　　Ｂ：　500　　Ｃ：平均演色評価数  
  
オ  　Ａ：ワット　　Ｂ：2，000　　Ｃ：平均演色評価数  
`
        },
        {
                "mondaiId": 104300250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
地域商店街活性化法および同法に基づく商店街活性化事業に関する記述として、  
<u>最も不適切なものはどれか</u>。  
  
ア  　商店街活性化事業の成果として、商店街への来訪者の増加に着目している。  
  
イ  　商店街活性化事業は、第　1　に地域住民の需要に応じて行う事業であること、第　  
2　に商店街活性化の効果が見込まれること、第　3　に他の商店街にとって参考とな  
り得る事業であること、以上の　3　点を満たす必要がある。  
  
ウ  　商店街活性化事業は、ハード事業のみによる振興を基本的な目的にしている。  
  
エ  　商店街は、地域コミュニティの担い手としての役割を発揮することを期待され  
ている。  
`
        },
        {
                "mondaiId": 104300260,
                "name": "第26問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、ある店舗における　1　カ月の営業実績をまとめたものである。  
人時生産性を改善するために、営業時間などを変えた販売計画を検討している。  
それぞれの販売計画に関する下記の解答群の記述のうち、現状の営業実績と比べて  
人時生産性を最も大きく改善できるものはどれか。  

〇年〇月　営業実績  
売上高　900　万円  
粗利益　270　万円  
粗利益率　　30　％　  
人件費　120　万円  
総労働時間　600　時間  
人件費単価　2，000　円/人時  

〔解答群〕  
  
ア  　営業時間を延長して売上高を　20　％増やす。ただし、総労働時間は　810　時間  
となり、粗利益率、人件費単価は変わらないものとする。  
  
イ  　人件費以外の販売経費を　10　％削減して営業利益を増加させる。ただし、総  
労働時間、粗利益率は変わらないものとする。  
  
ウ  　総労働時間を　30　時間減らして人件費を削減する。ただし、売上高、粗利益  
はそれぞれ　5　％減少し、人件費単価は変わらないものとする。  
  
エ  　値引きロスを減らして粗利益率を　33　％に改善する。ただし、売上高、人件  
費、総労働時間は変わらないものとする。  
`
        },
        {
                "mondaiId": 104300270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店の品揃えの方針に関する記述として、最も適切なものの組み合わせを下記  
の解答群から選べ。  
ａ　売れ筋商品を中心に品揃え商品数を絞り込むと、店全体の在庫回転率を高めや  
すい。  
ｂ　同じ商品カテゴリーの中で多数のメーカーの商品を品揃えすると、品揃えの総  
合化になる。  
ｃ　競合店にない独自商品を品揃えすれば、品揃え商品数を増やさなくても差別化  
ができる。  
ｄ　品揃えを専門化するためには、売れ筋商品に品揃え商品数を絞り込むことが重  
要である。  
〔解答群〕  
  
ア  　ａとｂ  
  
イ  　ａとｃ  
  
ウ  　ｂとｃ  
  
エ  　ｂとｄ  
  
  
オ  　ｃとｄ  
`
        },
        {
                "mondaiId": 104300281,
                "name": "第28問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
ある店舗で下表の商品を用いて、福袋を作って販売することを計画している。福  
袋は全部で　5　個作り、売価は　4，000　円とする。また、下表のすべての商品を使い切  
り、　1　つの福袋に同じ商品が入ることもある。なお、消費税は考慮しないものとす  
る。  

<imgTag>104300281_1.png</imgTag>  


（設問　1　）  
最初に売れた　1　つの福袋の粗利益率は　10　％であった。この福袋に入っていた  
商品の組み合わせとして、最も適切なものはどれか。  
  
ア  　商品Ａと商品Ｃがそれぞれ　1　個ずつ  
  
イ  　商品Ａが　1　個と商品Ｄが　2　個  
  
ウ  　商品Ｂが　1　個と商品Ｄが　2　個  
  
エ  　商品Ｂと商品Ｃと商品Ｄがそれぞれ　1　個ずつ  
  
オ  　商品Ｃが　2　個と商品Ｄが　1　個  
`
        },
        {
                "mondaiId": 104300282,
                "name": "第28問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
ある店舗で下表の商品を用いて、福袋を作って販売することを計画している。福  
袋は全部で　5　個作り、売価は　4，000　円とする。また、下表のすべての商品を使い切  
り、　1　つの福袋に同じ商品が入ることもある。なお、消費税は考慮しないものとす  
る。  

<imgTag>104300281_1.png</imgTag>  


（設問　2　）  
福袋の販売計画に関する次の文章の空欄Ａ〜Ｃに入る数値として、最も適切な  
ものの組み合わせを下記の解答群から選べ。  
今の販売計画では、　5　個の福袋を計画した売価ですべて売り切ったときの福袋  
販売全体の粗利益率は（　Ａ　）％である。粗利益率を　3　ポイント高めて（　Ｂ　）％とするためには、福袋の売価設定を（　Ｃ　）円とする必要があ  
る。  
〔解答群〕  
  
ア  　Ａ：17　　Ｂ：20　　Ｃ：4，120  
  
イ  　Ａ：17　　Ｂ：20　　Ｃ：4，150  
  
ウ  　Ａ：23　　Ｂ：26　　Ｃ：4，120  
  
エ  　Ａ：23　　Ｂ：26　　Ｃ：4，150  
`
        },
        {
                "mondaiId": 104300290,
                "name": "第29問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
売場づくりの考え方に関する記述として、最も適切なものはどれか。  
  
ア  　売上数量が異なる商品でも売場スペースを均等に配分することで、欠品を減ら  
し、商品ごとの商品回転率を均一化することができる。  
  
イ  　同じ商品グループを同じ棚段にホリゾンタル陳列すると、比較購買しやすい売  
場になる。  
  
ウ  　購買率の高いマグネット商品をレジ近くに配置することで、売場の回遊性を高  
めることができる。  
  
エ  　ゴールデンゾーンに商品を陳列する場合、それ以外の位置に陳列された商品よ  
り多フェイスにしなければ視認率は高まらない。  
  
オ  　棚割計画を立てる際、類似商品や代替性のある商品をまとめて配置することを  
フェイシングという。  
`
        },
        {
                "mondaiId": 104300300,
                "name": "第30問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
イ  ンストアプロモーションに関する次の文章の空欄Ａ〜Ｄに入る語句として、最  
も適切なものの組み合わせを下記の解答群から選べ。  

特売は、インストアプロモーションの中でも　Ａ　に売上を増加させるため  
に有効である。価格弾力性が　Ｂ　商品は、　Ｃ　商品と比べて同じ値引  
率での売上の増加幅が大きい。ただし、特売を長期間継続した場合は、消費者の  
Ｄ　が低下するため、特売を実施する際に注意が必要である。  
〔解答群〕  
  
ア  　Ａ：短期的　　Ｂ：小さい　　Ｃ：大きい　　Ｄ：外的参照価格  
  
イ  　Ａ：短期的　　Ｂ：大きい　　Ｃ：小さい　　Ｄ：内的参照価格  
  
ウ  　Ａ：短期的　　Ｂ：大きい　　Ｃ：小さい　　Ｄ：外的参照価格  
  
エ  　Ａ：長期的　　Ｂ：小さい　　Ｃ：大きい　　Ｄ：内的参照価格  
  
オ  　Ａ：長期的　　Ｂ：小さい　　Ｃ：大きい　　Ｄ：外的参照価格`
        },
        {
                "mondaiId": 104300310,
                "name": "第31問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店舗における在庫管理に関する記述として、最も適切なものはどれか。  
  
ア  　安全在庫を算出するときに用いる安全係数は、あらかじめ決められた一定の値  
であり、意図的に決める値ではない。  
  
イ  　欠品を少なくする方法は、フェイス数の増加や安全在庫の引き下げである。  
  
ウ  　サイクル在庫は、発注　1　回当たりの発注量を多くし発注頻度を引き下げると増  
加する。  
  
エ  　定量発注方式を採用しているときに過剰在庫を抑制する方法は、納品リードタイムをできるだけ長くすることである。    
  
オ  　適正な在庫量を表す理論在庫は、平均在庫と安全在庫の合計で算出する。  
`
        },
        {
                "mondaiId": 104300320,
                "name": "第32問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、配送元Ｐ、配送先Ａ、Ｂの各拠点間における　2　種類のトラックの配送方  
法を表している。矢印は始点から終点へとトラックが走行することを表し、数字は  
配送距離である。配送元Ｐから配送先Ａ、Ｂへと、配送方法（Ⅰ）は　2　運行、配送方  
法（Ⅱ）は　1　運行で配送した場合を表している。  
トラックの最適な配送ルートを選定する方法の　1　つにセービング法がある。セー  
ビング法の考え方に基づき、配送方法（Ⅰ）と（Ⅱ）を比較したときの配送距離の節約  
量（セービング）として、最も適切なものを下記の解答群から選べ。  

<imgTag>104300320_1.png</imgTag>  

〔解答群〕  
  
ア  　　　3  
  
イ  　　　6  
  
ウ  　10  
  
エ  　16  
  
オ  　20  

`
        },
        {
                "mondaiId": 104300330,
                "name": "第33問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流におけるユニットロードおよびその搬送機器に関する記述として、最も適切  
なものはどれか。  
  
ア  　一貫パレチゼーションとは、発地から着地までの間、保管用のパレットから輸  
送用のパレットへの積み替えを繰り返しながら、パレットに荷を積載し続けて物  
流を行うことである。  
  
イ  　平パレットは、主にプラスチックまたは鋼材で作られており、木製のパレット  
はほとんど使用されていない。  
  
ウ  　平パレットは、ワンウェイパレットとして利用されることが一般的である。  
  
エ  　ロールボックスパレットは、フォークリフトなどを用いずに人力だけでも荷役  
することができる。`
        },
        {
                "mondaiId": 104300340,
                "name": "第34問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
チェーン小売業の物流センターの機能に関する記述として、最も適切なものはど  
れか。  
  
ア  　在庫型物流センターを利用した取引における物流センターの在庫の所有権は、  
小売業の仕入条件が店頭渡しの場合でも小売業が持つことが一般的である。  
  
イ  　物流センターから店舗に対するカテゴリー納品は、ケース単位の商品で行わ  
れ、ケース単位未満のボール単位やピース単位では行われない。  
  
ウ  　物流センターに対して商品を店舗別に仕分けて納入することは、取引に利用す  
る物流センターの種類が在庫型か通過型かにかかわらず行われない。  
  
エ  　物流センターを利用した取引では、商品の所有権の移転経路が「製造業→卸売  
業→小売業」である場合でも、物流経路は卸売業を経由させないことが可能であ  
る。  
`
        },
        {
                "mondaiId": 104300350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
チェーン小売業の物流センターの運営に関する記述として、最も適切なものはど  
れか。  
  
ア  　　3　PL（Third　Party　Logistics）事業者は、倉庫や車両などの施設や設備を自ら  
所有しなければ、荷主に対してサービスを提供することができない。  
  
イ  　デジタルピッキングは、人の手を介さずにピッキング作業を自動化する装置で  
ある。  
  
ウ  　ピッキング作業は、ピッキングする商品品目数がオーダー数より多い場合は摘  
み取り方式で、少ない場合は種まき方式で行うのが一般的である。  
  
エ  　マテハン機器のうち、ソーターは保管用の機器であり、フローラックは仕分用  
の機器である。  
`
        },
        {
                "mondaiId": 104300360,
                "name": "第36問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商品Ａ〜Ｄの　1　年間における日別の売上金額について、　2　商品間の売上金額の相関係数を計算したところ、下記のようになった。これらの結果の解釈および相関係数の一般的な知識に関する記述として、最も適切なものを下記の解答群から選べ。  

組み合わせ　　　　　　　　　　　　　　　　（相関係数）  
商品Ａの売上金額　と　商品Ｂの売上金額　　（0.5）  
商品Ｂの売上金額　と　商品Ｃの売上金額　　（0.1）  
商品Ａの売上金額　と　商品Ｄの売上金額　　（－0.7）  

＊ここで相関係数とはピアソンの積率相関係数である。  

〔解答群〕  
  
ア  　売上金額の相関関係の強さを見ると、商品Ａと商品Ｂの関係より、商品Ａと  
商品Ｄの関係のほうが強い。  
  
イ  　商品Ａと商品Ｂの相関係数が　0.5　で、商品Ｂと商品Ｃの相関係数が　0.1　であ  
るため、表には計算されていないが、商品Ａと商品Ｃの相関係数は　0.4　である  
と言える。  
  
ウ  　商品Ａと商品Ｂの相関係数が　0.5　であるため、商品Ｂの平均売上金額は、商  
品Ａの平均売上金額の半分であると言える。  
  
エ  　相関係数は、　-　100　から　100　までの範囲の値として計算される。  
  
オ  　理論的に相関係数は　0　にはならない。  
`
        },
        {
                "mondaiId": 104300370,
                "name": "第37問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ソースマーキングとインストアマーキングに関する記述として、最も適切なもの  
はどれか。  
  
ア  　JAN　コードにおける日本の国番号は“49”のみである。  
  
イ  　JAN　コードには、大きく分けると拡張タイプ、標準タイプ、短縮タイプの　3  
種類が存在する。  
  
ウ  　JAN　コードの先頭　2　桁は国番号であり、当該製品の原産国を表している。  
  
エ  　インストアマーキングには、バーコードの中に価格情報が含まれる　PLU  
（Price　Look　Up）タイプがある。  
  
オ  　インストアマーキングの場合、先頭　2　桁のプリフィックスに“20”を利用するこ  
とは正しい利用方法である。  
`
        },
        {
                "mondaiId": 104300380,
                "name": "第38問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
食品衛生管理方法である　HACCP（Hazard　Analysis　and　Critical　Control　Point）  
に関する　12　手順が以下に示されている。空欄Ａ〜Ｃと記述群①〜③の組み合わせ  
として、最も適切なものを下記の解答群から選べ。  

＜HACCP　の　12　手順＞  
手順　1　HACCP　のチーム編成  
手順　2　製品説明書の作成  
手順　3　意図する用途及び対象となる消費者の確認  
手順　4　製造工程一覧図の作成  
手順　5　製造工程一覧図の現場確認  
手順　6　危害要因分析の実施  
手順　7　(  Ａ  <hint hidden='true'>**
①重要管理点（CCP）の決定
**</hint>)  
手順　8　(  Ｂ  <hint hidden='true'>**
②管理基準（CL）の設定
**</hint>)  
手順　9　モニタリング方法の設定  
手順　10　改善措置の設定  
手順　11　(  Ｃ  <hint hidden='true'>**
③検証方法の設定
**</hint>)  
手順　12　記録と保存方法の設定  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】HACCPのの１２手順  
<img src="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/images/haccptejun.png"/>
<a href="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/haccpgimuka.html" target="_blank">  
(外部リンクを開きます　千葉市：HACCP(ハサップ)に沿った衛生管理)</a>  
</div>
</hint>

＜空欄に入る記述群＞  
①重要管理点（CCP）の決定  
②管理基準（CL）の設定  
③検証方法の設定  


〔解答群〕  
  
ア  　Ａ：①　　Ｂ：②　　Ｃ：③<hint hidden='true'>**
←⭕
**</hint>    
  
イ  　Ａ：①　　Ｂ：③　　Ｃ：②  
  
ウ  　Ａ：②　　Ｂ：①　　Ｃ：③  
  
エ  　Ａ：②　　Ｂ：③　　Ｃ：①  
  
オ  　Ａ：③　　Ｂ：①　　Ｃ：②  
`
        },
        {
                "mondaiId": 104300391,
                "name": "第39問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マーケットバスケット分析は、頻繁に購入される商品の組み合わせ（相関ルール）  
を見つけ、併買を促すためのヒントを見つけ出すのに活用される方法の　1　つであ  
る。この相関ルールの評価に関する下記の設問に答えよ。  

（設問　1　）  
相関ルールを多角的な観点から評価するためには、複数の指標が用いられる。  
このうち、リフト値は重要な評価指標の　1　つであるが、他に　2　つの評価指標を挙  
げる場合、以下の①〜④のうち、最も適切なものの組み合わせを下記の解答群か  
ら選べ。  

①　コンバージョン率  
②　支持度（サポート）  
③　信頼度（コンフィデンス）  
④　正答率  


〔解答群〕  
  
ア  　①と③  
  
イ  　①と④  
  
ウ  　②と③  
  
エ  　②と④  
  
オ  　③と④  
`
        },
        {
                "mondaiId": 104300392,
                "name": "第39問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マーケットバスケット分析は、頻繁に購入される商品の組み合わせ（相関ルール）  
を見つけ、併買を促すためのヒントを見つけ出すのに活用される方法の　1　つであ  
る。この相関ルールの評価に関する下記の設問に答えよ。  

（設問　2　）  
商品Ｘと商品Ｙの相関ルールを評価するとき、商品Ｘの購買が、どの程度、商  
品Ｙの購買を増大させているかを示すリフト値を計算する式を次に示す。  

<imgTag>104300392_1.png</imgTag>  

以下の①〜④のうち、式の空欄ＡとＢに入る語句として、最も適切なものの組  
み合わせを下記の解答群から選べ。  

①　全顧客数  
②　商品Ｘを購入した顧客数  
③　商品Ｙを購入した顧客数  
④　商品ＸとＹを購入した顧客数  

〔解答群〕  
  
ア  　Ａ：①　　Ｂ：②  
  
イ  　Ａ：①　　Ｂ：④  
  
ウ  　Ａ：②　　Ｂ：②  
  
エ  　Ａ：②　　Ｂ：③    
  
オ  　Ａ：③　　Ｂ：④  
`
        },
        {
                "mondaiId": 104300400,
                "name": "第40問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本国政府においては、「電子行政オープンデータ戦略」（平成　24　年　7　月　4　日　IT　  
総合戦略本部決定）に基づき、各省庁のホームページ上で各種データの公開が進  
み、その利用についても関心が高まっている。ここで「オープンデータ」と言えるた  
めには、機械判読に適したデータ形式で、二次利用が可能な利用ルールで公開され  
たデータである必要がある。この機械判読の容易性と著作権等の扱いにより、オー  
プンデータは開放性の程度が　5　つの段階に分けられている。（平成　25　年度版　情報  
通信白書）  
以下の　3　つのデータ形式例を、開放性の程度が低いものから高いものへと並べる  
とき、最も適切なものを下記の解答群から選べ。  

【データ形式例】  
①　xls　や　doc  
②　PDF　や　JPG  
③　XML　や　CSV  

〔解答群〕  
  
ア  　①－②－③  
  
イ  　①－③－②  
  
ウ  　②－①－③  
  
エ  　②－③－①  
    
オ  　③－①－②`
        },
        {
                "mondaiId": 105300010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　合同会社は、当事者間で最適な利害状況を自由に設定することを可能とすることによって、事業の円滑な実施を図り、法規制による保護ではなく、利害関係者の自己責任による問題解決に委ねるという会社類型である。  
株式会社と合同会社を比較した記述として、最も適切なものはどれか。  
  
ア  　株式会社の株主は、会社債権者に対して間接有限責任しか負わないが、<hintTarget>合同会社</hintTarget>の社員は、会社債権者に対して直接<hintTarget>無限</hintTarget>責任を負う。  

<hint hidden='true'>**
❌下表の通り、合同会社は有限責任社員のみです。  
**</hint>

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
合同会社、合名会社、合資会社の比較表

<TABLE align="left" width="100%">
<TR>
<TD></TD>
<TD align='center' style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;' colspan="3">
持分会社
</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-top: 1px solid gray;border-left: 1px solid gray;'>
株式会社</TD>
</TR>

<TR>
<TD>
　</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合同会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合名会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合資会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>
</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者
</TD>
<TD align='center' colspan='3' style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >社員</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;'>株主</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
必要な出資者（社員または株主）の人数
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上 </hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>２人以上
（有限責任社員と無限責任社員が各１名以上）</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上  </hintTarget></TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者の責任
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>間接有限責任</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>無限責任（全ての責任）</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>有限責任社員と無限責任社員</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >間接有限責任（出資した範囲の責任）</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>

イ  　株式会社の場合には、資本金を増やさずに出資による資金調達を行うことはできないが、合同会社の場合には、資本金を増やさずに出資による資金調達を行うことができる。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  
  
ウ  　株式会社の場合にも、<hintTarget>合同会社</hintTarget>の場合にも、<hintTarget>純資産額が300万円以上でなければ</hintTarget>、配当を行うことができない。  

<hint hidden='true'>**
❌合同会社の場合は、可能（株式会社の場合は不可能）。
**</hint>
  
エ  　株式会社の場合にも、<hintTarget>合同会社</hintTarget>の場合にも、<hintTarget>貸借対照表を公告</hintTarget>しなければならない。  

<hint hidden='true'>**
❌合同会社の場合は、不要（株式会社の場合は必要）。
**</hint>

`
        },
        {
                "mondaiId": 105300020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表は、合併及び会社分割の各手続において、簡易手続及び略式手続の有無を整  
理したものである。空欄Ａ〜Ｄに入る記号の組み合わせとして、最も適切なものを  
下記の解答群から選べ。  

なお、該当する手続があるものについては「○」、ないものについては「╳」を記載  
することにしている。  

<imgTag>105300020_1.png</imgTag>  


〔解答群〕  
  
ア  　Ａ：○　　Ｂ：╳　　Ｃ：╳　　Ｄ：○  
  
イ  　Ａ：○　　Ｂ：╳　　Ｃ：╳　　Ｄ：╳  
  
ウ  　Ａ：╳　　Ｂ：○　　Ｃ：○　　Ｄ：○  
  
エ  　Ａ：╳　　Ｂ：○　　Ｃ：○　　Ｄ：╳  
`
        },
        {
                "mondaiId": 105300030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
Ａは、Ｘ株式会社（以下「Ｘ社」という。）が発行する普通株式　4　万株（以下「本件株  
式」という。）を保有する株主であった。その後、Ａは死亡し、Ｂ、Ｃ、Ｄの　3　名の  
みが相続人としてＡの財産を相続した。Ｂは、Ａの配偶者である。Ｃ及びＤは、Ａ  
の子である（下図参照）。  

<imgTag>105300030_1.png</imgTag>  


この場合、本件株式に係る権利行使及び通知に関する記述として、最も適切なも  
のはどれか。  
なお、遺言はなく、遺産分割協議も整っておらず、相続人はいずれも廃除されて  
いないものとし、寄与分及び特別受益についても考慮しないものとする。  
  

ア  　Ｂは、Ｃ及びＤが反対していても、自らを本件株式についての権利を行使する  
者として指定し、自らの氏名をＸ社に通知した上で、Ｘ社の同意を得た場合、株  
主総会において、本件株式について議決権を行使することができる。  
  
イ  　Ｃは、その指定に参加する機会をＤに与えた上で、Ｂの同意を得て、自らを本  
件株式についての権利を行使する者として指定し、自らの氏名をＸ社に通知した  
場合、本件株式について議決権を行使することができる。  
  
ウ  　Ｃは、自らを本件株式のうち　1　万株についての権利を行使する者として指定  
し、それをＢとＤに通知した上で、Ｘ社の同意を得た場合、Ｘ社の株主総会にお  
いて、その　1　万株について単独で議決権を行使することができる。  
  
エ  　Ｘ社は、Ｂ、Ｃ、Ｄのいずれからも通知又は催告を受領する者の通知を受けて  
いない場合において、株主総会を開催するときは、Ｂ、Ｃ、Ｄの　3　名全員に招集  
通知を発しなければならない。  

`
        },
        {
                "mondaiId": 105300040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
募集株式の払込金額（募集株式　1　株と引換えに払い込む金銭の額）が募集株式を引  
き受ける者にとって特に有利な金額になる第三者割当増資を株式会社が行うには、  
株主総会の特別決議が必要になる。  
現在のＸ株式会社（以下「Ｘ社」という。）の発行可能株式総数は　20　株であり、発行  
済株式総数は、10　株である。また、Ｘ社の企業価値は、100　億円である。したがっ  
て、　1　株当たりの企業価値は、10　億円である。  
現在、Ｘ社は、40　億円の投資を行うことによりＸ社の企業価値が　50　億円増加し  
て　150　億円になる新規プロジェクトを計画しており、この　40　億円を第三者割当て  
の方法による募集株式の発行により調達しようとしている。  
この場合において、募集株式の払込金額が募集株式を引き受ける者にとって特に  
有利な金額になる募集株式の数に関する記述として、最も適切なものはどれか。  
なお、Ｘ社には債権者がいないこと及び募集株式の全てを引き受けてくれる投資  
家が存在することを前提とする。  
  
ア  　募集株式の数を　2　株とする。  
  
イ  　募集株式の数を　4　株とする。  
  
ウ  　募集株式の数を　5　株とする。  
  
エ  　募集株式の数を　10　株とする。  
`
        },
        {
                "mondaiId": 105300050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株価の算定方法に関する記述として、最も適切なものはどれか。  
  
ア  　ゴードン・モデル方式では、比較の対象として適切な会社を選定することが難  
しい。  
  
イ  　時価純資産額方式では、支配株主が不当に剰余金の配当額を抑えていると、株  
価が不当に低く評価されてしまう。  
  
ウ  　実際配当還元方式では、保有する資産の価値は低いが稼ぐ力を高く有している  
会社の株価が不当に低く評価されてしまう。  
  
エ  　ディスカウント・キャッシュ・フロー法では、将来の業績を予測する必要があ  
るが、それを正確に予測するのが難しい。  
`
        },
        {
                "mondaiId": 105300060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたと、ベンチャー企業であるＸ株式会  
社の普通株主であり、かつ、代表取締役である、甲氏との間で行われたものであ  
る。会話の中の空欄に入る記述として、最も適切なものを下記の解答群から選べ。  

甲　氏：「今、あるベンチャーキャピタルが当社に対して優先株式による出資をし  
てくれるという話がありまして、その交渉をしています。ベンチャーキャ  
ピタル側からいろいろと条件を提示されているのですが、耳慣れない用語  
が多くて困っています。この条件は私にとって有利なのでしょうか。」  

あなた：「（　　　）。これは、甲さんにとって有利です。」  

甲　氏：「なるほど。具体的にどういうことですか。」  

あなた：「概要なら私から説明できますが、契約交渉は投資契約の実務に詳しい弁  
護士のアドバイスを受けた方がよいと思います。ちょうど知っている方が  
いますから、紹介しますよ。」  

甲　氏：「ぜひお願いします。」  


〔解答群〕  
  
ア  　株主間契約において、みなし清算条項を定めるという条件ですね  
  
イ  　投資契約において、優先株主にドラッグ・アロング・ライトを認めるという  
条件ですね  
  
ウ  　優先株式の配当方式を参加型にするという条件ですね  
  
エ  　優先株式の配当方式を非累積型にするという条件ですね  
`
        },
        {
                "mondaiId": 105300070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
資本の部の計数の増減に関する記述として、最も適切なものはどれか。  
  
ア  　資本金の額を減少させ、その減少させた金額と同じ金額だけその他資本剰余金  
の額を増やすためには、債権者異議手続を行う必要がある。  
  
イ  　資本金の額を減少させ、その減少させた金額と同じ金額だけ利益準備金の額を  
増やすためには、債権者異議手続を行う必要がある。  
  
ウ  　資本準備金の額を減少させ、その減少させた金額と同じ金額だけ資本金の額を  
増やすためには、債権者異議手続を行う必要がある。  
  
エ  　その他資本剰余金の額を減少させ、その減少させた金額と同じ金額だけ資本金  
の額を増やすためには、債権者異議手続を行う必要がある。`
        },
        {
                "mondaiId": 105300080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
意匠制度における「意匠の類似」に関する記述として、最も適切なものはどれか。  
  
ア  　出願前に頒布された刊行物に記載された意匠に類似する意匠は、登録を受ける  
ことができない。  
  
イ  　類似の意匠について同日に　2　以上の意匠登録出願があり、意匠登録出願人間で  
行われる協議が成立しなかった場合は、特許庁長官が行う公正な方法によるくじ  
により定めた一の意匠登録出願人のみが意匠登録を受けることができる。  
  
ウ  　意匠権者は、業として登録意匠の実施をする権利を専有するが、これに類似す  
る意匠についてはそれを実施する権利を専有しない。  
  
エ  　意匠登録を受けようとする関連意匠にのみ類似する意匠についても関連意匠と  
して意匠登録を受けることができる。  
`
        },
        {
                "mondaiId": 105300090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許発明の「実施」として、<u>最も不適切なものはどれか</u>。  
  
ア  　特許発明がレンズの生産方法であって、同特許発明の技術的範囲に属する方法  
を使用してレンズを製造する行為。  
  
イ  　特許発明がレンズの生産方法であって、同特許発明の技術的範囲に属する方法  
により生産されたレンズを販売する行為。  
  
ウ  　特許発明がレンズの生産方法ではなく、その製造装置であって、同特許発明の  
技術的範囲に属する製造装置により製造されたレンズを販売する行為。  
  
エ  　特許発明がレンズの研磨プログラムであって、同特許発明の技術的範囲に属す  
るプログラムを電気通信回線を通じて提供する行為。`
        },
        {
                "mondaiId": 105300100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
特許と実用新案に関する記述として、最も適切なものはどれか。  
  
ア  　権利侵害に基づく差止請求権を行使する場合、特許権は事前に相手方に警告を  
行わなければならないが、実用新案権はその際、さらに技術評価書を提示しなけ  
ればならない。  
  
イ  　他人の特許権又は実用新案権を侵害した者は、その侵害の行為について過失が  
あったものと推定する。  
  
ウ  　特許権の存続期間の起算日は出願日であるが、実用新案権の存続期間の起算日  
は登録日である。  
  
エ  　方法の発明は特許を受けることができるが、方法の考案は実用新案登録を受け  
ることができない。  
`
        },
        {
                "mondaiId": 105300110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
不正競争防止法に関する記述として、最も適切なものはどれか。  
  
ア  　真正品が外国で最初に販売された日から　3　年を経過すれば、不正競争法防止法  
第　2　条第　1　項第　3　号に規定する、いわゆるデッドコピー行為の規定は適用されな  
い。  
  
イ  　不正競争防止法第　2　条第　1　項第　1　号に規定する、いわゆる周知表示混同惹起行  
為において、商品の包装は「商品等表示」に含まれない。  
  
ウ  　不正競争防止法第　2　条第　1　項第　2　号に規定する、いわゆる著名表示冒用行為と  
認められるためには、他人の商品又は営業と混同を生じさせたか否かは問われな  
い。  
  
エ  　不正競争防止法第　2　条第　1　項第　4　号乃至第　9　号に規定される営業秘密となるに  
は、秘密管理性、独自性、有用性の　3　つの要件を満たすことが必要である。  
`
        },
        {
                "mondaiId": 105300120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、地元の民芸品を扱う事業協同組  
合Ｘの理事である甲氏との間で行われたものである。会話の中の空欄に入る語句と  
して、最も適切なものを下記の解答群から選べ。  

甲　氏：「うちの民芸品は全国的にも有名だと思うのですが、知的財産権で保護す  
ることができないでしょうか。」  

あなた：「そうですね。意匠や実用新案は新規性が要求されますから難しいでしょ  
う。でも、商標には立体商標という制度があります。実際、飛騨地方の  
『さるぼぼ』や太宰府天満宮の『うそ』が、『キーホルダー』を指定商品とした  
立体商標として商標登録を受けているんですよ。」  

甲　氏：「へぇ、立体の商標ですか。」  

あなた：「そうです。（　　）の立体商標は、『使用をされた結果需要者が何人か  
の業務に係る商品又は役務であることを認識することができるもの』なら  
ば、商標登録を受けることができますから、長年使用されている民芸品は  
立体商標の登録を比較的受け易いのです。」  

甲　氏：「なるほど。長年使っているからこそ登録を得られる商標があるのですね。」  

あなた：「地元の弁理士さんを紹介しますので、相談してみてはいかがでしょう。」  

甲　氏：「よろしくお願いします。」  


〔解答群〕  
  
ア  　その商品の形状等を普通に用いられる方法で表示する標章のみからなる商標  
  
イ  　その商品又は役務について慣用されている商標  
  
ウ  　その商品又は役務の普通名称を普通に用いられる方法で表示する標章のみか  
らなる商標  
  
エ  　他人の業務に係る商品又は役務と混同を生ずるおそれがある商標  
`
        },
        {
                "mondaiId": 105300130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
パブリシティ権に関する記述として、最も適切なものはどれか。  
  
ア  　パブリシティ権の侵害は、肖像等を無断で使用する行為が専ら肖像等の有する  
顧客吸引力の利用を目的とする場合に成立する。  
  
イ  　パブリシティ権の侵害は、商品等の差別化を図る目的で肖像等を商品等に付し  
た場合に認められ、肖像等を商品等の広告として使用する場合には認められな  
い。  
  
ウ  　パブリシティ権は、会社法に規定されている。  
  
エ  　パブリシティ権は、競馬の競走馬にも認められる。  
`
        },
        {
                "mondaiId": 105300140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、酒造会社Ｘ社の代表取締役甲氏  
との間で行われたものである。会話の中の空欄ＡとＢに入る語句の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  

甲　氏：「うちの醸造所で、フルーティーな味わいを目指した『本／PPONN』とい  
う銘柄を売り出すんですよ。日本での商標登録出願も、このとおり済ませ  
ました。」  
あなた：「漢字『本』とアルファベット『PPONN』を二段表記した商標ですね。」  
甲　氏：「インバウンドの効果もあって日本酒は外国でも人気です。海外販路も開  
拓したいと思っているので、外国でも商標登録出願を行おうと思っていま  
す。」  
あなた：「日本の商標を基礎に、多数の国を指定して日本の特許庁に一括して商標  
登録出願ができる、マドリッド協定による国際商標登録出願、いわゆるマ  
ドプロ出願という制度があるようですよ。」  
甲　氏：「そのマドプロ出願は、今すぐ使えるのでしょうか。」  
あなた：「マドプロ出願は、　Ａ　。」  
甲　氏：「なるほど。それと、商標に漢字が入ったままで大丈夫でしょうか。」  
あなた：「漢字が入っていても出願できます。　Ｂ　。」  
13  
〔解答群〕  
  
ア  　Ａ：日本の商標登録出願を基礎にできますから、もう利用できますよ  
　　Ｂ：そもそも、商標を変更することはできません  
  
イ  　Ａ：日本の商標登録出願を基礎にできますから、もう利用できますよ  
　　Ｂ：ただ、同一性のある範囲なら、商標を変更することができます  
  
ウ  　Ａ：日本の商標登録を基礎にしますから、出願中の現在は、まだ利用はでき  
ません  
　　Ｂ：そもそも、商標を変更することはできません  
  
エ  　Ａ：日本の商標登録を基礎にしますから、出願中の現在は、まだ利用はでき  
ません  
　　Ｂ：ただ、同一性のある範囲なら、商標を変更することができます  
`
        },
        {
                "mondaiId": 105300151,
                "name": "第15問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、Ｘ株式会社の代表取締役甲氏と  
の間で行われたものである。この会話を読んで、下記の設問に答えよ。  

甲　氏：「海外の　a　社との間で、秘密保持契約を締結することになり、　a　社からその  
案が送られてきました。そのうち次の規定は、『秘密情報』の定義を定めて  
いると思うのですが、注意すべき点や、追記・修正すべき点はありますで  
しょうか。」  

For　the　purpose　of　this　Agreement，　‘‘Confidential　Information’’　shall　  
mean　the　followings:  

（ⅰ）　　Any　information　that　is　disclosed　by　the　party　which　discloses　such　  
information　（‘‘Disclosing　Party’’）　to　the　party　which　receives　such　  
information　（‘‘Receiving　Party’’）　pursuant　to　this　Agreement，　which　  
is　included　in　materials　（including　but　not　limited　to　documents　or　  
other　tangible　entity　such　as　electronic　media　in　which　electrical　  
data　is　stored　and　e-mail）　clearly　indicated　as　being　confidential;　or  

（ⅱ）　　Any　information　that　is　designated　as　being　confidential　by　the　  
Disclosing　Party　to　the　Receiving　Party　orally;　  

（ⅲ）　　Provided　the　information　set　forth　in　the　preceding　item　ⅱ　shall　be　  
included　into　Confidential　Information　if　the　Disclosing　Party　notifies　  
in　writing　the　Receiving　Party，　within　ten　days　from　the　time　of　the　  
disclosure，　of　such　Confidential　Information　in　itself　and　that　such　  
information　is　Confidential　Information.  

Notwithstanding　　the　　precedings，　　Confidential　　Information　　shall　　not　  
include　any　information　which　falls　into　one　or　more　of　the　followings:  

（ⅰ）　　information　which　was　already　known　to　the　public　prior　to　the　time　  
of　the　disclosure　by　the　Disclosing　Party　to　the　Receiving　Party;  

（ⅱ）　　information　which　is　or　subsequently　becomes　known　to　the　public　  
other　than　through　the　fault　or　negligence　of　the　Receiving　Party　    
after　the　disclosure　by　the　Disclosing　Party　to　the　Receiving　Party;  

（ⅲ）　　information　which　was　obtained　by　the　third　party　other　than　the　  
Disclosing　　Party，　　which　　was　　disclosed　　to　　the　　Receiving　　Party　  
without　the　third　party’s　breach　of　any　obligation;  

（ⅳ）　　information　which　is　independently　developed　by　the　Receiving　  
Party;  


あなた：「まず、いかなる情報が『秘密情報』となるかという点については、（　Ａ  ）に注意する必要があります。  
また、『秘密情報』から除外されることの多い（　Ｂ　）が除外されてい  
ませんので、それを除外した方がよいと考えます。  
詳細は、弁護士に確認した方がよいと思いますので、必要であれば、私  
の知り合いの弁護士を紹介します。」  

甲　氏：「ありがとうございます。ぜひよろしくお願いします。」  

（設問　1　）  
会話の中の空欄Ａに入る記述として、最も適切なものはどれか。  
  
ア  　口頭で開示した情報は開示の際に秘密として指定しさえすれば秘密情報にな  
る点  
  
イ  　口頭で開示した情報は秘密情報とはならない点  
  
ウ  　書面で開示した情報は全て秘密情報になる点  
  
エ  　書面で開示した場合と口頭で開示した場合とで扱いが異なる点  
`
        },
        {
                "mondaiId": 105300152,
                "name": "第15問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、Ｘ株式会社の代表取締役甲氏と  
の間で行われたものである。この会話を読んで、下記の設問に答えよ。  

甲　氏：「海外の　a　社との間で、秘密保持契約を締結することになり、　a　社からその  
案が送られてきました。そのうち次の規定は、『秘密情報』の定義を定めて  
いると思うのですが、注意すべき点や、追記・修正すべき点はありますで  
しょうか。」  

For　the　purpose　of　this　Agreement，　‘‘Confidential　Information’’　shall　  
mean　the　followings:  

（ⅰ）　　Any　information　that　is　disclosed　by　the　party　which　discloses　such　  
information　（‘‘Disclosing　Party’’）　to　the　party　which　receives　such　  
information　（‘‘Receiving　Party’’）　pursuant　to　this　Agreement，　which　  
is　included���in　materials　（including　but　not　limited　to　documents　or　  
other　tangible　entity　such　as　electronic　media　in　which　electrical　  
data　is　stored　and　e-mail）　clearly　indicated　as　being　confidential;　or  

（ⅱ）　　Any　information　that　is　designated　as　being　confidential　by　the　  
Disclosing　Party　to　the　Receiving　Party　orally;　  

（ⅲ）　　Provided　the　information　set　forth　in　the　preceding　item　ⅱ　shall　be　  
included　into　Confidential　Information　if　the　Disclosing　Party　notifies　  
in　writing　the　Receiving　Party，　within　ten　days　from　the　time　of　the　  
disclosure，　of　such　Confidential　Information　in　itself　and　that　such　  
information　is　Confidential　Information.  

Notwithstanding　　the　　precedings，　　Confidential　　Information　　shall　　not　  
include　any　information　which　falls　into　one　or　more　of　the　followings:  

（ⅰ）　　information　which　was　already　known　to　the　public　prior　to　the　time　  
of　the　disclosure　by　the　Disclosing　Party　to　the　Receiving　Party;  

（ⅱ）　　information　which　is　or　subsequently　becomes　known　to　the　public　  
other　than　through　the　fault　or　negligence　of　the　Receiving　Party　    
after　the　disclosure　by　the　Disclosing　Party　to　the　Receiving　Party;  

（ⅲ）　　information　which　was　obtained　by　the　third　party　other　than　the　  
Disclosing　　Party，　　which　　was　　disclosed　　to　　the　　Receiving　　Party　  
without　the　third　party’s　breach　of　any　obligation;  

（ⅳ）　　information　which　is　independently　developed　by　the　Receiving　  
Party;  


あなた：「まず、いかなる情報が『秘密情報』となるかという点については、（　Ａ  ）に注意する必要があります。  
また、『秘密情報』から除外されることの多い（　Ｂ　）が除外されてい  
ませんので、それを除外した方がよいと考えます。  
詳細は、弁護士に確認した方がよいと思いますので、必要であれば、私  
の知り合いの弁護士を紹介します。」  

甲　氏：「ありがとうございます。ぜひよろしくお願いします。」  

（設問　2　）  
会話の中の空欄Ｂに入る記述として、最も適切なものはどれか。  
  
ア  　受領者が開示者以外の第三者から取得した情報で、当該第三者が何らの義務  
に違反することなく受領者に開示した情報  
  
イ  　受領者が開示者から開示を受けた後に、受領者の責に帰すべき事由によらず  
に公知となった情報  
  
ウ  　受領者が開示者から開示を受ける前に、既に公知となっていた情報  
  
エ  　受領者が開示者から開示を受ける前に、既に保有していた情報  
`
        },
        {
                "mondaiId": 105300160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の者のうち、時効を援用することができる者として、<u>最も不適切なものはどれ  
か</u>。  
  
ア  　主債務の消滅時効につき、保証人。  
  
イ  　土地の取得時効につき、当該土地の時効取得者から土地上の建物を賃借した  
者。  
  
ウ  　被相続人の占有による取得時効につき、単独相続人。  
  
エ  　被担保債権の消滅時効につき、抵当不動産の第三取得者。`
        },
        {
                "mondaiId": 105300170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
保証に関する記述として、最も適切なものはどれか。なお、別段の意思表示はな  
いものとする。  
  
ア  　主たる債務者が破産手続開始の決定を受けた場合、保証契約に基づく支払義務  
はなくなる。  
  
イ  　売買契約の売主の債務不履行によって生じる損害賠償義務は、当該売主のため  
の保証債務の担保する範囲に属する。  
  
ウ  　保証契約は、口頭でしても、その効力を生じる。  
  
エ  　連帯保証人が債権者から債務の履行を請求されたときは、連帯保証人は、まず  
主たる債務者に催告をすべき旨を請求することができる。  
`
        },
        {
                "mondaiId": 105300181,
                "name": "第18問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、Ｘ株式会社の代表取締役甲氏と  
の間で行われたものである。この会話を読んで、下記の設問に答えよ。  

甲　氏：「当社が労務管理のソフトウエアを開発している会社であることはご存知  
かと思いますが、新たに採用管理のソフトウエアの開発を検討しておりま  
す。他社にまねをされないよう、どうにかして保護することはできないで  
しょうか。」  

あなた：「ソフトウエアであれば、著作権により保護される可能性があります。ま  
た、特許権による保護もあり得ます。」  

甲　氏：「著作権とか特許権というのは聞いたことがあります。開発をするのは、  
当社のソフトウエア開発の部署の従業員なのですが、著作権や特許権は当  
社に帰属しますか。」  

あなた：「そうだとすると、いわゆる職務著作や職務発明に該当する可能性があり  
ます。その場合、著作権と特許権では、取り扱いが異なります。（　Ａ  ）を最初から貴社に帰属させるためには、あらかじめ契約、勤務規則その他  
の定めにおいて、その旨を定めなければなりません。(　Ｂ　)。」  

甲　氏：「なるほど。ソフトウエアの開発にあたっては、Ｙ株式会社との共同開発  
も視野に入れているのですが、その場合の権利の帰属はどうなりますか。」  

あなた：「Ｙ株式会社とその従業員との契約等によりますが、著作権も特許権も、  
貴社とＹ株式会社の共有になる可能性があります。」  

甲　氏：「その場合、当社は、当該ソフトウエアを、販売したり、作って販売する  
ことを第三者に許諾したり、または、自らの権利の持分を譲渡したりする  
ときに、Ｙ株式会社の承諾が必要になるのでしょうか。」  

あなた：「Ｙ株式会社との間で別段の定めをせず、著作権と特許権の双方で保護さ  
れることを前提とします。まず、貴社が作って販売することは承諾が(  Ｃ　)。次に、作って販売することを第三者に許諾することは承諾が(  Ｄ　)。従業員との契約、勤務規則などの資料を持って、弁護士に相  
談に行きましょう。」  


（設問　1　）  
会話の中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  　Ａ：著作権  
　　Ｂ：しかも、定めていない場合には、当該ソフトウエアを販売することもで  
きません  
  
イ  　Ａ：著作権  
　　Ｂ：ただし、定めていない場合でも、当該ソフトウエアを販売することはで  
きます  
  
ウ  　Ａ：特許権  
　　Ｂ：しかも、定めていない場合には、当該ソフトウエアを販売することもで  
きません  
  
エ  　Ａ：特許権  
　　Ｂ：ただし、定めていない場合でも、当該ソフトウエアを販売することはで  
きます`
        },
        {
                "mondaiId": 105300182,
                "name": "第18問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、Ｘ株式会社の代表取締役甲氏と  
の間で行われたものである。この会話を読んで、下記の設問に答えよ。  

甲　氏：「当社が労務管理のソフトウエアを開発している会社であることはご存知  
かと思いますが、新たに採用管理のソフトウエアの開発を検討しておりま  
す。他社にまねをされないよう、どうにかして保護することはできないで  
しょうか。」  

あなた：「ソフトウエアであれば、著作権により保護される可能性があります。ま  
た、特許権による保護もあり得ます。」  

甲　氏：「著作権とか特許権というのは聞いたことがあります。開発をするのは、  
当社のソフトウエア開発の部署の従業員なのですが、著作権や特許権は当  
社に帰属しますか。」  

あなた：「そうだとすると、いわゆる職務著作や職務発明に該当する可能性があり  
ます。その場合、著作権と特許権では、取り扱いが異なります。（　Ａ  ）を最初から貴社に帰属させるためには、あらかじめ契約、勤務規則その他  
の定めにおいて、その旨を定めなければなりません。(　Ｂ　)。」  

甲　氏：「なるほど。ソフトウエアの開発にあたっては、Ｙ株式会社との共同開発  
も視野に入れているのですが、その場合の権利の帰属はどうなりますか。」  

あなた：「Ｙ株式会社とその従業員との契約等によりますが、著作権も特許権も、  
貴社とＹ株式会社の共有になる可能性があります。」  

甲　氏：「その場合、当社は、当該ソフトウエアを、販売したり、作って販売する  
ことを第三者に許諾したり、または、自らの権利の持分を譲渡したりする  
ときに、Ｙ株式会社の承諾が必要になるのでしょうか。」  

あなた：「Ｙ株式会社との間で別段の定めをせず、著作権と特許権の双方で保護さ  
れることを前提とします。まず、貴社が作って販売することは承諾が(  Ｃ　)。次に、作って販売することを第三者に許諾することは承諾が(  Ｄ　)。従業員との契約、勤務規則などの資料を持って、弁護士に相  
談に行きましょう。」  

（設問　2　）  
会話の中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  　Ｃ：ない場合、できません　　Ｄ：ない場合、できません  
  
イ  　Ｃ：ない場合、できません　　Ｄ：なくても可能です  
  
ウ  　Ｃ：なくても可能です　　　　Ｄ：ない場合、できません  
  
エ  　Ｃ：なくても可能です　　　　Ｄ：なくても可能です  
`
        },
        {
                "mondaiId": 105300190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
相殺に関する記述として、最も適切なものはどれか。なお、別段の意思表示はな  
いものとする。  
  
ア  　時効によって消滅した自働債権がその消滅以前に相殺に適するようになってい  
たとしても、相殺の意思表示をしたのが時効消滅後である場合は、相殺すること  
はできない。  
  
イ  　相殺の意思表示は、双方の債務が互いに相殺に適するようになった時にさかの  
ぼってその効力を生ずる。  
  
ウ  　二人が互いに同種の目的を有する債務を負担する場合で、受働債権が弁済期に  
あれば、自働債権の弁済期が到来しなくても、相殺することができる。  
  
エ  　不法行為から生じた債権を自働債権として相殺することはできない。  
`
        },
        {
                "mondaiId": 105300200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
被相続人Ｘが死亡し、相続が生じた。ＥはＸの配偶者である。ＣはＢの後妻であ  
り、Ｘ、Ｆ、Ｈ及びＪはＢ及びＣの子である。ＡはＢの前妻であり、ＤはＡ及びＢ  
の子である。ＧはＦの配偶者であり、ＬはＦ及びＧの子である。ＭはＬの配偶者で  
あり、ＯはＬ及びＭの子である。ＩはＨの配偶者であり、ＮはＨ及びＩの子であ  
る。ＫはＪの配偶者であり、ＪとＫとの間には胎児Ｐがいる。Ｂ、Ｃ、Ｆ、Ｊ及び  
ＬはＸ死亡以前に死亡していた（下図参照）。  
ＨはＸの相続について相続放棄をしたが、それ以外の相続人は承認した。  
この場合、Ｘの相続財産について、それぞれの相続人が相続する割合として、最  
も適切なものはどれか。  
なお、遺言はなく、遺産分割協議も整っておらず、相続人はいずれも廃除されて  
いないものとし、寄与分及び特別受益についても考慮しないものとする。  
  
<imgTag>105300200_1.png</imgTag>  

ア  　Ｄが　20　分の　1　、Ｅが　4　分の　3　、Ｏが　10　分の　1　、Ｐが　10　分の　1　を相続する。  
  
イ  　Ｄが　20　分の　1　、Ｅが　4　分の　3　、Ｎが　10　分の　1　、Ｐが　10　分の　1　を相続する。  
  
ウ  　Ｄが　12　分の　1　、Ｅが　4　分の　3　、Ｐが　6　分の　1　を相続する。  
  
エ  　Ｄが　8　分の　1　、Ｅが　4　分の　3　、Ｐが　8　分の　1　を相続する。  

`
        },
        {
                "mondaiId": 105300210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、2016　年　7　月　14　日現在における、東京証券取引所に上場している企業が  
採用している機関設計の割合を、市場区分等別に集計したものである。空欄Ａ〜Ｃ  
に入る語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

<imgTag>105300210_1.png</imgTag>  

出典：株式会社東京証券取引所「東証上場会社コーポレートガバナンス白書　2017」  


〔解答群〕  
  
ア  　Ａ：JASDAQ　　Ｂ：JPX　日経　400　　Ｃ：市場第一部  
  
イ  　Ａ：JASDAQ　　Ｂ：市場第一部　　Ｃ：JPX　日経　400  
  
ウ  　Ａ：JPX　日経　400　　Ｂ：市場第一部　　Ｃ：JASDAQ  
  
エ  　Ａ：市場第一部　　Ｂ：JASDAQ　　Ｃ：JPX　日経　400  
`
        },
        {
                "mondaiId": 105300220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下記は、金融商品取引法に基づき作成が義務付けられる書類の名称とその内容に  
ついて説明したものである。空欄Ａ〜Ｄに入る語句の組み合わせとして、最も適切  
なものを下記の解答群から選べ。  

（名称）　内容の説明  
（Ａ）  新たに有価証券を発行する場合、又は、既発行の有価証券の売出しをする場合において、その取得の申込みの勧誘を行う相手方の人数及び発行（売出し）価額の総額等が一定の基準に該当するときに、発行者が内閣総理大臣に提出することが義務付けられる書類  

（Ｂ）　発行（売出し）価額の総額等が（　Ａ　）の提出が義務付けられる基準に満たない場合において、新たに有価証券を発行し、又は、既発行の有価証券の売出しをするときに、発行者が内閣総理大臣に提出することが義務付けられる書類  

（Ｃ）  有価証券の発行者が、事業年度ごとに、内閣総理大臣に提出することが義務付けられる、事業の内容に関する重要な事項を記載した書類  

（Ｄ）  有価証券の募集又は売出しに当たって、その取得の申込みを勧誘する際等に投資家に交付する文書  

〔解答群〕  
  
ア  　Ａ：有価証券通知書　　Ｂ：有価証券届出書  
　　Ｃ：目論見書　　　　　Ｄ：有価証券報告書  
  
イ  　Ａ：有価証券届出書　　Ｂ：有価証券通知書  
　　Ｃ：目論見書　　　　　Ｄ：有価証券報告書  
  
ウ  　Ａ：有価証券届出書　　Ｂ：有価証券通知書  
　　Ｃ：有価証券報告書　　Ｄ：目論見書  
  
エ  　Ａ：有価証券報告書　　Ｂ：有価証券届出書  
　　Ｃ：有価証券通知書　　Ｄ：目論見書  
`
        },
        {
                "mondaiId": 105300230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2018,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、Ｘ株式会社の代表取締役である  
甲氏との間で行われたものである。この会話の中の空欄に入る記述として、<u>最も不適切なものを下記の解答群から選べ。 </u> 

甲　氏：「販売促進のために、弊社の商品を紹介する電子メールを配信しようと考  
えています。」  

あなた：「広告宣伝を目的とした電子メールの送信には法規制があるのをご存知で  
すか。」  

甲　氏：「何という法律ですか。」  

あなた：「『特定電子メールの送信の適正化等に関する法律』と『特定商取引に関する  
法律』です。」  

甲　氏：「どのようなルールになっていますか。」  

あなた：「『特定電子メールの送信の適正化等に関する法律』では、（　　　）。詳  
しいことは、この分野に詳しい弁護士に相談するとよいと思います。」  

甲　氏：「分かりました。ありがとうございます。」  


〔解答群〕  
  
ア  　自己の電子メールアドレスをインターネットで公表している消費者には、あ  
らかじめ同意を得ておかなくても送信することができます  
  
イ  　電子メールに、受信拒否の通知ができる旨を表示しなければなりません  
  
ウ  　電子メールの送信について請求・承諾があったことを証する記録を保存しな  
ければなりません  
  
エ  　同意があっても、その後、受信拒否の通知があった場合には、送信すること  
はできません。ただし、広告宣伝以外の目的で受信者の意思に基づき送信され  
る電子メールに付随的に広告宣伝を記載することはできます`
        },
        {
                "mondaiId": 107300011,
                "name": "第１問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

財務省「法人企業統計調査季報」に基づき、2007　年から　2016　年の期間について、  
中小企業の業況を見た場合、売上高は　2013　年以降　Ａ　傾向、経常利益は  
2009　年以降　Ｂ　傾向にある。  
また、中小企業の設備投資の推移を見ると、足下ではやや　Ｃ　傾向にあ  
り、設備投資の水準は、リーマン・ショック前の　2007　年の水準を　Ｄ　。  
なお、ここでは中小企業は資本金　1　千万円以上　1　億円未満の企業とする。  

（設問　1　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：減少　　　Ｂ：減少  
  
イ  　Ａ：減少　　　Ｂ：増加  
  
ウ  　Ａ：増加　　　Ｂ：減少  
  
エ  　Ａ：横ばい　　Ｂ：減少  
  
オ  　Ａ：横ばい　　Ｂ：増加`
        },
        {
                "mondaiId": 107300012,
                "name": "第１問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

財務省「法人企業統計調査季報」に基づき、2007　年から　2016　年の期間について、  
中小企業の業況を見た場合、売上高は　2013　年以降　Ａ　傾向、経常利益は  
2009　年以降　Ｂ　傾向にある。  
また、中小企業の設備投資の推移を見ると、足下ではやや　Ｃ　傾向にあ  
り、設備投資の水準は、リーマン・ショック前の　2007　年の水準を　Ｄ　。  
なお、ここでは中小企業は資本金　1　千万円以上　1　億円未満の企業とする。  

（設問　2　）  
文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｃ：減少　　Ｄ：上回っている  
  
イ  　Ｃ：減少　　Ｄ：下回っている  
  
ウ  　Ｃ：増加　　Ｄ：上回っている  
  
エ  　Ｃ：増加　　Ｄ：下回っている  
`
        },
        {
                "mondaiId": 107300021,
                "name": "第2問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
総務省「平成　26　年経済センサス－基礎調査」に基づき、<u>企業規模別（民営、非一次産業）に、企業数と会社及び個人の従業者総数を見た場合（2014　年）</u>、中小企業の構成比は全企業数の　99.7　%、従業者総数の　70.1　％を占めている。  

　また、総務省「平成　21　年、平成　26　年経済センサス－基礎調査」、総務省・経済産業省「平成　24　年経済センサス－活動調査」に基づき、2009　年、2012　年、2014　年で比較すると、全企業数に占める中小企業の構成比（％）は（　Ａ　）傾向、従業者総数に占める中小企業の構成比（％）は（　Ｂ　）傾向となっている。  
　なお、企業規模区分は中小企業基本法に準ずるものとする。  

（設問　1　）  

　文中の下線部について、小規模企業と中規模企業の企業数と従業者総数を見た場合の記述として、最も適切なものはどれか。なお、ここでは中規模企業は小規模企業以外の中小企業とする。  
  
ア  　小規模企業の<hintTarget>企業数</hintTarget>と<hintTarget>従業者総数</hintTarget>は、ともに中規模企業と<hintTarget>ほぼ同数</hintTarget>である。  

<hint hidden='true'>**
❌企業数は多く、従業者数は少ない。
**</hint>

イ  　小規模企業の企業数と従業者総数は、<hintTarget>ともに</hintTarget>中規模企業より<hintTarget>多い</hintTarget>。  
  
<hint hidden='true'>**
❌企業数は多く、従業者数は少ない。
**</hint>

ウ  　小規模企業の企業数と従業者総数は、<hintTarget>ともに</hintTarget>中規模企業より<hintTarget>少ない</hintTarget>。  

<hint hidden='true'>**
❌企業数は多く、従業者数は少ない。
**</hint>
  
エ  　小規模企業の企業数は中規模企業より多く、従業者総数は中規模企業より少ない。<hint hidden='true'>**
←⭕  
**</hint>  
  
オ  　小規模企業の企業数は中規模企業より少なく、従業者総数は中規模企業より多い。

<hint hidden='true'>**
❌選択肢エのとおり。
**</hint>


`
        },
        {
                "mondaiId": 107300022,
                "name": "第2問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
　
　総務省「平成　26　年経済センサス－基礎調査」に基づき、<u>企業規模別（民営、非一次産業）に、企業数と会社及び個人の従業者総数を見た場合（2014　年）</u>、中小企業の構成比は全企業数の　99.7　%、従業者総数の　70.1　％を占めている。  

　また、総務省「平成　21　年、平成　26　年経済センサス－基礎調査」、総務省・経済産業省「平成　24　年経済センサス－活動調査」に基づき、<hintTarget>2009　年、2012　年、2014　年</hintTarget>で比較すると、全企業数に占める中小企業の構成比（％）は（　Ａ　<hint hidden='true'>**
横ばい
**</hint> ）傾向、従業者総数に占める中小企業の構成比（％）は（　Ｂ　<hint hidden='true'>**
増加
**</hint>）傾向となっている。  
　なお、企業規模区分は中小企業基本法に準ずるものとする。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　付属統計資料  
全企業数に占める中小企業の構成比は、99.7%で横ばい。
<imgTag>107300022_1.png</imgTag>
</div>
</hint>


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】中小企業白書　付属統計資料  
2009,2012,2014で比較すると、従業者数は増加傾向。
<imgTag>107300022_2.png</imgTag>
</div>
</hint>

（設問　2　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：減少　　　Ｂ：減少  
  
イ  　Ａ：減少　　　Ｂ：増加  
  
ウ  　Ａ：増加　　　Ｂ：減少  
  
エ  　Ａ：増加　　　Ｂ：増加  
    
オ  　Ａ：横ばい　　Ｂ：増加<hint hidden='true'>**
←⭕
**</hint>    

`
        },
        {
                "mondaiId": 107300031,
                "name": "第3問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

総務省「平成　21　年、平成　26　年経済センサス－基礎調査」に基づき、2009　年と  
2014　年について、企業規模別に従業者数の変化を見た場合、大企業は　Ａ　、　  
中規模企業は　Ｂ　、小規模企業は　Ｃ　している。  
同様に、企業規模別に、一者当たり従業者数の変化を見ると、大企業は　Ｄ　、　  
中規模企業は　Ｅ　、小規模企業は　Ｆ　している。  
なお、ここでは中規模企業は小規模企業以外の中小企業とし、企業規模区分は中  
小企業基本法に準ずるものとする。  

（設問　1　）  
文中の空欄Ａ～Ｃに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：減少　　Ｂ：減少　　Ｃ：減少  
  
イ  　Ａ：減少　　Ｂ：増加　　Ｃ：減少  
  
ウ  　Ａ：増加　　Ｂ：減少　　Ｃ：減少  
  
エ  　Ａ：増加　　Ｂ：減少　　Ｃ：増加    
  
オ  　Ａ：増加　　Ｂ：増加　　Ｃ：増加`
        },
        {
                "mondaiId": 107300032,
                "name": "第3問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

総務省「平成　21　年、平成　26　年経済センサス－基礎調査」に基づき、2009　年と  
2014　年について、企業規模別に従業者数の変化を見た場合、大企業は　Ａ　、　  
中規模企業は　Ｂ　、小規模企業は　Ｃ　している。  
同様に、企業規模別に、一者当たり従業者数の変化を見ると、大企業は　Ｄ　、　  
中規模企業は　Ｅ　、小規模企業は　Ｆ　している。  
なお、ここでは中規模企業は小規模企業以外の中小企業とし、企業規模区分は中  
小企業基本法に準ずるものとする。  

（設問　2　）  
文中の空欄Ｄ～Ｆに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｄ：減少　　Ｅ：減少　　Ｆ：増加  
  
イ  　Ｄ：減少　　Ｅ：増加　　Ｆ：減少  
  
ウ  　Ｄ：増加　　Ｅ：減少　　Ｆ：減少  
  
エ  　Ｄ：増加　　Ｅ：増加　　Ｆ：減少    
  
オ  　Ｄ：増加　　Ｅ：増加　　Ｆ：増加  
`
        },
        {
                "mondaiId": 107300040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
総務省・経済産業省「平成　24　年経済センサス－活動調査」に基づき、産業別規模  
別付加価値額（企業ベース、民営、非一次産業）を見た場合、建設業、小売業、宿泊  
業・飲食サービス業、情報通信業、製造業のうち、各産業の付加価値額の総額に占  
める中小企業の構成比が最も高いものはどれか。  
なお、企業規模区分は中小企業基本法に準ずるものとする。  
  
ア  　建設業  
  
イ  　小売業  
  
ウ  　宿泊業・飲食サービス業  
  
エ  　情報通信業  
  
オ  　製造業`
        },
        {
                "mondaiId": 107300050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業庁「平成　28　年中小企業実態基本調査（平成　27　年度決算実績）」に基づき、  
小売業、宿泊業・飲食サービス業、製造業の売上高経常利益率と自己資本比率を見  
た場合、それぞれについて中小企業の全業種平均より高いものとして、最も適切な  
ものはどれか。  
  
ア  　小売業の売上高経常利益率  
  
イ  　宿泊業・飲食サービス業の売上高経常利益率  
  
ウ  　製造業の売上高経常利益率  
  
エ  　小売業の自己資本比率  
  
オ  　宿泊業・飲食サービス業の自己資本比率  
`
        },
        {
                "mondaiId": 107300061,
                "name": "第6問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

わが国の<u>開業率と廃業率の推移</u>を、厚生労働省「雇用保険事業年報」に基づき見た  
場合、2010　年度から　2015　年度の期間、開業率が廃業率を上回っている。  

もっとも、開業・廃業の動向は業種によっても異なる。建設業、宿泊業・飲食  
サービス業、製造業の開業率と廃業率について全業種平均と比較すると（2015　年  
度）、　Ａ　は開業率・廃業率とも全業種平均より高い一方、　Ｂ　は開  
業率・廃業率とも全業種平均より低い。　Ｃ　では開業率は全業種平均より高  
く、廃業率は全業種平均とおおむね同水準となっている。  

なお、雇用保険事業年報による開業率は、当該年度に雇用関係が新規に成立した  
事業所数を前年度末の適用事業所数で除して算出したものである。同じく廃業率  
は、当該年度に雇用関係が消滅した事業所数を前年度末の適用事業所数で除して算  
出したものである。  

（設問　1　）  
文中の下線部について、わが国の開業率と廃業率を、厚生労働省「雇用保険事  
業年報」に基づき、2010　年度と　2015　年度について比較した場合の記述として、  
最も適切なものはどれか。  
  
ア  　開業率と廃業率は、ともに減少している。  
  
イ  　開業率と廃業率は、ともに増加している。  
  
ウ  　開業率は減少、廃業率は増加している。  
  
エ  　開業率は増加、廃業率は減少している。  
`
        },
        {
                "mondaiId": 107300062,
                "name": "第6問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

わが国の<u>開業率と廃業率の推移</u>を、厚生労働省「雇用保険事業年報」に基づき見た  
場合、2010　年度から　2015　年度の期間、開業率が廃業率を上回っている。  

もっとも、開業・廃業の動向は業種によっても異なる。建設業、宿泊業・飲食  
サービス業、製造業の開業率と廃業率について全業種平均と比較すると（2015　年  
度）、　Ａ　は開業率・廃業率とも全業種平均より高い一方、　Ｂ　は開  
業率・廃業率とも全業種平均より低い。　Ｃ　では開業率は全業種平均より高  
く、廃業率は全業種平均とおおむね同水準となっている。  

なお、雇用保険事業年報による開業率は、当該年度に雇用関係が新規に成立した  
事業所数を前年度末の適用事業所数で除して算出したものである。同じく廃業率  
は、当該年度に雇用関係が消滅した事業所数を前年度末の適用事業所数で除して算  
出したものである。  

（設問　2　）  
文中の空欄Ａ～Ｃに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：建設業　　　　　　　　　　Ｂ：宿泊業・飲食サービス業  
　　Ｃ：製造業  
  
イ  　Ａ：建設業　　　　　　　　　　Ｂ：製造業  
　　Ｃ：宿泊業・飲食サービス業  
  
ウ  　Ａ：宿泊業・飲食サービス業　　Ｂ：製造業  
　　Ｃ：建設業  
  
エ  　Ａ：宿泊業・飲食サービス業　　Ｂ：建設業  
　　Ｃ：製造業  
  
  
オ  　Ａ：製造業　　　　　　　　　　Ｂ：建設業  
　　Ｃ：宿泊業・飲食サービス業  
`
        },
        {
                "mondaiId": 107300071,
                "name": "第7問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

総務省「就業構造基本調査」に基づき、1979　年から　2012　年の期間について、起業  
希望者と起業家の男女別の構成の推移を見た場合、1997　年以降、起業希望者に占  
める女性の割合は　Ａ　傾向、起業家に占める女性の割合は　Ｂ　傾向に  
ある。  

同様に　1979　年から　2012　年の期間について、起業家に占める「60　歳以上」の割合  
の推移を見ると、男女共に　Ｃ　傾向にあるものの、女性に比べ男性の方が  
Ｄ　なっている。また、<u>起業家の業種構成を男女別に見ても</u>違いが見受けら  
れる。  

なお、ここでは「起業希望者」とは、有業者の転職希望者のうち「自分で事業を起  
こしたい」又は、無業者のうち「自分で事業を起こしたい」と回答した者をいう。「起  
業家」とは、過去　1　年間に職を変えた又は新たに職についた者のうち、現在は会社  
等の役員又は自営業主となっている者をいう。兼業・副業としての起業希望者と起  
業家は含まない。  

（設問　1　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：減少　　Ｂ：減少  
  
イ  　Ａ：減少　　Ｂ：増加  
  
ウ  　Ａ：増加　　Ｂ：減少  
  
エ  　Ａ：増加　　Ｂ：増加  
`
        },
        {
                "mondaiId": 107300072,
                "name": "第7問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

総務省「就業構造基本調査」に基づき、1979　年から　2012　年の期間について、起業  
希望者と起業家の男女別の構成の推移を見た場合、1997　年以降、起業希望者に占  
める女性の割合は　Ａ　傾向、起業家に占める女性の割合は　Ｂ　傾向に  
ある。  

同様に　1979　年から　2012　年の期間について、起業家に占める「60　歳以上」の割合  
の推移を見ると、男女共に　Ｃ　傾向にあるものの、女性に比べ男性の方が  
Ｄ　なっている。また、<u>起業家の業種構成を男女別に見ても</u>違いが見受けら  
れる。  

なお、ここでは「起業希望者」とは、有業者の転職希望者のうち「自分で事業を起  
こしたい」又は、無業者のうち「自分で事業を起こしたい」と回答した者をいう。「起  
業家」とは、過去　1　年間に職を変えた又は新たに職についた者のうち、現在は会社  
等の役員又は自営業主となっている者をいう。兼業・副業としての起業希望者と起  
業家は含まない。  

（設問　2　）  
文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｃ：減少　　Ｄ：高く  
  
イ  　Ｃ：減少　　Ｄ：低く  
  
ウ  　Ｃ：増加　　Ｄ：高く  
  
エ  　Ｃ：増加　　Ｄ：低く`
        },
        {
                "mondaiId": 107300073,
                "name": "第7問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

総務省「就業構造基本調査」に基づき、1979　年から　2012　年の期間について、起業  
希望者と起業家の男女別の構成の推移を見た場合、1997　年以降、起業希望者に占  
める女性の割合は　Ａ　傾向、起業家に占める女性の割合は　Ｂ　傾向に  
ある。  

同様に　1979　年から　2012　年の期間について、起業家に占める「60　歳以上」の割合  
の推移を見ると、男女共に　Ｃ　傾向にあるものの、女性に比べ男性の方が  
Ｄ　なっている。また、<u>起業家の業種構成を男女別に見ても</u>違いが見受けら  
れる。  

なお、ここでは「起業希望者」とは、有業者の転職希望者のうち「自分で事業を起  
こしたい」又は、無業者のうち「自分で事業を起こしたい」と回答した者をいう。「起  
業家」とは、過去　1　年間に職を変えた又は新たに職についた者のうち、現在は会社  
等の役員又は自営業主となっている者をいう。兼業・副業としての起業希望者と起  
業家は含まない。  

（設問　3　）  
文中の下線部について、総務省「就業構造基本調査」に基づき、1997　年から  
2012　年の期間について、男女別に起業家の業種構成を見た場合の記述として、  
最も適切なものはどれか。  
  
ア  　飲食サービス業の構成割合は、男性が女性より高い傾向にある。  
  
イ  　小売業の構成割合は、男性が女性より高い傾向にある。  
  
ウ  　製造業の構成割合は、女性が男性より高い傾向にある。  
  
エ  　女性の飲食サービス業の構成割合は、低下傾向にある。  
  
オ  　女性の小売業の構成割合は、増加傾向にある。  
`
        },
        {
                "mondaiId": 107300080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

起業後に円滑な成長を遂げていくためには、起業後の成長段階ごとに直面する課  
題を克服していく必要がある。中小企業庁「起業・創業の実態に関する調査（2016  
年　11　月、複数回答）」に基づき、各成長段階で直面する課題を見た場合、「資金調  
達」「質の高い人材の確保」「販路開拓・マーケティング」のうち、創業期では　Ａ　、　  
安定・拡大期では　Ｂ　が最も回答企業割合が高くなっている。  

なお、ここで、創業期は本業の製品・商品・サービスによる売上がない段階、安  
定・拡大期は売上が計上され、少なくとも一期は営業利益が黒字化した段階をい  
う。  

〔解答群〕  
  
ア  　Ａ：資金調達　　　　　　　　　　Ｂ：質の高い人材の確保  
  
イ  　Ａ：資金調達　　　　　　　　　　Ｂ：販路開拓・マーケティング  
  
ウ  　Ａ：質の高い人材の確保　　　　　Ｂ：資金調達  
  
エ  　Ａ：質の高い人材の確保　　　　　Ｂ：販路開拓・マーケティング  
  
オ  　Ａ：販路開拓・マーケティング　　Ｂ：質の高い人材の確保  
`
        },
        {
                "mondaiId": 107300090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

後継者がいないが事業を継続したい企業にとっては、事業の譲渡・売却・統合  
（M＆A）も重要な選択肢となる。中小企業庁「企業経営の継続に関するアンケート  
調査（2016　年　11　月）」に基づき、従業員規模別（20　人以下、21～50　人、51　人以上）  
に、事業を　M＆A　する場合に重視する条件を見た場合（複数回答）、「会社や事業の更  
なる発展」「従業員の雇用の維持・確保」「売却による金銭的収入」のうち、　Ａ  
を重視する回答企業割合が全ての従業員規模で最も高いものの、従業員規模が大き  
い企業ほど　Ｂ　を重視する割合が高くなっている。  
〔解答群〕  
  
ア  　Ａ：会社や事業の更なる発展　　　Ｂ：従業員の雇用の維持・確保  
  
イ  　Ａ：会社や事業の更なる発展　　　Ｂ：売却による金銭的収入  
  
ウ  　Ａ：従業員の雇用の維持・確保　　Ｂ：会社や事業の更なる発展  
  
エ  　Ａ：従業員の雇用の維持・確保　　Ｂ：売却による金銭的収入  
  
オ  　Ａ：売却による金銭的収入　　　　Ｂ：従業員の雇用の維持・確保  
`
        },
        {
                "mondaiId": 107300101,
                "name": "第10問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
　次の文章を読んで、下記の設問に答えよ。  

　製造業は、わが国の　GDP　の約（　Ａ　）割（2016　年）を占める重要な産業であり、<u>製造業の名目労働生産性は他産業と比べても比較的高くなっている</u>。　  
　他方で、国際競争の激化や産業構造の変化等に伴い、製造業を取り巻く環境は一段と厳しさを増している。総務省「平成　21　年、平成　26　年経済センサス－基礎調査」に基づき、2009　年と　2014　年について比較した場合、事業所数は（　Ｂ　）、従業者数は（　Ｃ　）している。これを企業規模別に見ると、中小企業の事業所数の減少率は大企業の減少率より（　Ｄ　）。  

　なお、ここでは従業者規模　300　人以上の企業を大企業、同じく　300　人未満の企業を中小企業とする。  
  

（設問　1　）  
文中の空欄Ａに入る数値として、最も適切なものはどれか。  
  
ア  　　2<hint hidden='true'>**
←⭕
**</hint>  
  
イ  　　4  
  
ウ  　　6  
  
エ  　　8  

`
        },
        {
                "mondaiId": 107300102,
                "name": "第10問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　製造業は、わが国の　GDP　の約（　Ａ　）割（2016　年）を占める重要な産業であり、<u>製造業の名目労働生産性は他産業と比べても比較的高くなっている</u>。　  
　他方で、国際競争の激化や産業構造の変化等に伴い、製造業を取り巻く環境は一段と厳しさを増している。総務省「平成　21　年、平成　26　年経済センサス－基礎調査」に基づき、2009　年と　2014　年について比較した場合、事業所数は（　Ｂ　）、従業者数は（　Ｃ　）している。これを企業規模別に見ると、中小企業の事業所数の減少率は大企業の減少率より（　Ｄ　）。  

　なお、ここでは従業者規模　300　人以上の企業を大企業、同じく　300　人未満の企業を中小企業とする。  
                

（設問　2　）  
　文中の下線部について、内閣府「2016　年度国民経済計算」に基づき、就業者一人当たり名目労働生産性（2016　年）を比較した場合、製造業を上回る産業として、最も適切なものはどれか。  

　なお、ここでは、就業者一人当たり名目労働生産性は産業別の名目GDPを就業者数で除して算出するものとする。  
  
ア  　卸売・小売業  
  
イ  　建設業  
  
ウ  　宿泊・飲食サービス業  
  
エ  　情報通信業<hint hidden='true'>**
←⭕統計の数値を記憶して正解することは難しい問題ですが、選択肢のうち、もっとも労働生産性が高い（かつ、問題文の下線部ので比較的高いとされる製造業より高い）と思われるものを選択することで、正解することが可能と考えられる問題でした。
**</hint>      

`
        },
        {
                "mondaiId": 107300103,
                "name": "第10問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　製造業は、わが国の　GDP　の約（　Ａ　）割（2016　年）を占める重要な産業であり、<u>製造業の名目労働生産性は他産業と比べても比較的高くなっている</u>。　  
　他方で、国際競争の激化や産業構造の変化等に伴い、製造業を取り巻く環境は一段と厳しさを増している。総務省「平成　21　年、平成　26　年経済センサス－基礎調査」に基づき、2009　年と　2014　年について比較した場合、事業所数は（　Ｂ　<hint hidden='true'>**
減少
**</hint>）、従業者数は（　Ｃ　<hint hidden='true'>**
減少
**</hint>）している。これを企業規模別に見ると、中小企業の事業所数の減少率は大企業の減少率より（　Ｄ　<hint hidden='true'>**
低い
**</hint>）。  

　なお、ここでは従業者規模　300　人以上の企業を大企業、同じく　300　人未満の企業を中小企業とする。  
                

（設問　3　）  
文中の空欄Ｂ～Ｄに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｂ：減少　　Ｃ：減少　　Ｄ：高い<hint hidden='true'>**
←⭕
**</hint>     
  
イ  　Ｂ：減少　　Ｃ：減少　　Ｄ：低い  
  
ウ  　Ｂ：減少　　Ｃ：増加　　Ｄ：高い  
  
エ  　Ｂ：増加　　Ｃ：減少　　Ｄ：低い  
  
オ  　Ｂ：増加　　Ｃ：増加　　Ｄ：高い  

`
        },
        {
                "mondaiId": 107300111,
                "name": "第11問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業の新事業展開の実施状況を、中小企業庁「中小企業の成長に向けた事業  
戦略等に関する調査（2016　年　11　月）」に基づき見た場合、「新市場開拓（新市場で既  
存製品・サービスを展開）」、「新製品開発（既存市場で新製品・サービスを開発・展  
開）」、「多角化（既存事業を継続しつつ新市場で新製品・サービスを展開）」、「事業  
転換（既存事業を縮小・廃止しつつ新市場で新製品・サービスを展開）」のうち、<u>「新製品開発」を実施していると回答する企業の割合が最も高い</u>。  

他方で、新事業展開を実施していない企業の抱える課題について見た場合（複数  
回答）、「必要な技術・ノウハウを持つ人材不足」「必要なコスト負担の大きさ」「適切  
な相談相手が見つからない」のうち、　Ａ　とする回答企業割合が最も高く、  
Ｂ　の回答企業割合が最も低い。  

（設問　1　）  
文中の下線部について、「新製品開発」以外の「新市場開拓」「多角化」「事業転換」  
の実施状況を見た場合、実施割合が高いものから低いものへと並べた組み合わせ  
として、最も適切なものはどれか。  
  
ア  　新市場開拓　－　事業転換　　－　多角化  
  
イ  　新市場開拓　－　多角化　　　－　事業転換  
  
ウ  　事業転換　　－　新市場開拓　－　多角化  
  
エ  　事業転換　　－　多角化　　　－　新市場開拓  
  
オ  　多角化　　　－　事業転換　　－　新市場開拓  
`
        },
        {
                "mondaiId": 107300112,
                "name": "第11問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業の新事業展開の実施状況を、中小企業庁「中小企業の成長に向けた事業  
戦略等に関する調査（2016　年　11　月）」に基づき見た場合、「新市場開拓（新市場で既  
存製品・サービスを展開）」、「新製品開発（既存市場で新製品・サービスを開発・展  
開）」、「多角化（既存事業を継続しつつ新市場で新製品・サービスを展開）」、「事業  
転換（既存事業を縮小・廃止しつつ新市場で新製品・サービスを展開）」のうち、<u>「新製品開発」を実施していると回答する企業の割合が最も高い</u>。  

他方で、新事業展開を実施していない企業の抱える課題について見た場合（複数  
回答）、「必要な技術・ノウハウを持つ人材不足」「必要なコスト負担の大きさ」「適切  
な相談相手が見つからない」のうち、　Ａ　とする回答企業割合が最も高く、  
Ｂ　の回答企業割合が最も低い。  

（設問　2　）  
文中の空欄　A　と　B　に入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：適切な相談相手が見つからない  
　　Ｂ：必要な技術・ノウハウを持つ人材不足  
  
イ  　Ａ：必要な技術・ノウハウを持つ人材不足  
　　Ｂ：適切な相談相手が見つからない  
  
ウ  　Ａ：必要な技術・ノウハウを持つ人材不足  
　　Ｂ：必要なコスト負担の大きさ  
  
エ  　Ａ：必要なコスト負担の大きさ  
　　Ｂ：必要な技術・ノウハウを持つ人材不足  
  
オ  　Ａ：必要なコスト負担の大きさ  
　　Ｂ：適切な相談相手が見つからない  
`
        },
        {
                "mondaiId": 107300120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄Ａ～Ｃに入る語句と数値の組み合わせとして、最も適切なものを  
下記の解答群から選べ。  

わが国の中小企業金融において、公的信用保証制度は大きな役割を果たしてい  
る。  

全国信用保証協会連合会の調べによれば、全国の保証協会の保証債務残高（2017  
年　3　月末）は約　24　兆円であり、近年　Ａ　傾向にある。また、2016　年度の保  
証承諾実績を見ると、保証金額別では　3，000　万円以下が全体の約　Ｂ　割、資  
金使途別では運転資金が全体の約　Ｃ　割である。  

〔解答群〕  
  
ア  　Ａ：減少　　　Ｂ：　6　　　Ｃ：　9　　  
  
イ  　Ａ：減少　　　Ｂ：　9　　　Ｃ：　6  
  
ウ  　Ａ：増加　　　Ｂ：　6　　　Ｃ：　9  
  
エ  　Ａ：増加　　　Ｂ：　9　　　Ｃ：　6  
    
オ  　Ａ：横ばい　　Ｂ：　5　　　Ｃ：　5  
`
        },
        {
                "mondaiId": 107300131,
                "name": "第13問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

「中小企業のものづくり基盤技術の高度化に関する法律」は、我が国製造業の  
Ａ　及び新たな事業の創出を図るため、中小企業が担うものづくり基盤技術  
の高度化に向けた研究開発及びその成果の利用を支援するための法律である。  

この法律では、「特定ものづくり基盤技術」について、　Ｂ　の最先端ニーズ  
を反映して行われるべき研究開発等の内容、人材育成・知的資産活用の在り方、取  
引慣行の改善等に関する指針を経済産業大臣が策定する。中小企業が、この指針に  
基づいて特定研究開発等計画を策定し、<u>経済産業大臣の認定を受けると、支援措置を受けることができる</u>。  


（設問　1　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：経営基盤の強化　　　Ｂ：川上産業  
  
イ  　Ａ：経営基盤の強化　　　Ｂ：川下産業  
  
ウ  　Ａ：国際競争力の強化　　Ｂ：川上産業  
  
エ  　Ａ：国際競争力の強化　　Ｂ：川下産業`
        },
        {
                "mondaiId": 107300132,
                "name": "第13問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

「中小企業のものづくり基盤技術の高度化に関する法律」は、我が国製造業の  
Ａ　及び新たな事業の創出を図るため、中小企業が担うものづくり基盤技術  
の高度化に向けた研究開発及びその成果の利用を支援するための法律である。  

この法律では、「特定ものづくり基盤技術」について、　Ｂ　の最先端ニーズ  
を反映して行われるべき研究開発等の内容、人材育成・知的資産活用の在り方、取  
引慣行の改善等に関する指針を経済産業大臣が策定する。中小企業が、この指針に  
基づいて特定研究開発等計画を策定し、<u>経済産業大臣の認定を受けると、支援措置を受けることができる</u>。  


（設問　2　）  
文中の下線部に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  　研究開発の助成  
  
イ  　中小企業投資育成株式会社法の特例  
  
ウ  　日本政策金融公庫による低金利融資  
  
エ  　法人税の免除  
`
        },
        {
                "mondaiId": 107300141,
                "name": "第14問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。 

　<u>中小企業基本法</u>は、中小企業施策について、基本理念・基本方針等を定めている。同法の基本理念では、中小企業を「多様な事業の分野において特色ある事業活動を行い、多様な就業の機会を提供し、個人がその能力を発揮しつつ事業を行う機会を提供することにより（　Ａ　）」と位置付けている。  

　また、小規模企業は、「（　Ｂ　）事業活動を行い、就業の機会を提供する」など、地域経済の安定・地域住民の生活の向上及び交流の促進に寄与するとともに、「（　Ｃ　）事業活動を行い、新たな産業を創出する」など、将来における我が国経済社会の発展に寄与する、という　2　つの重要な意義を有するとしている。  

（設問　1　）  
文中の下線部の中小企業基本法に基づく、中小企業者と小規模企業者の範囲に関する記述の正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　資本金　1　億円で従業員数が　50　人の食品卸売業者は中小企業者の範囲に含まれ、資本金　1　千万円で従業員数　5　人の食品卸売業者は小規模企業者の範囲に含  
まれる。  

ｂ　資本金　5　億円で従業員数が　200　人の食品製造業者は中小企業者の範囲に含まれ、資本金　1　千万円で従業員数　30　人の食品製造業者は小規模企業者の範囲に含まれる。　　 


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義表  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>

〔解答群〕  
  
ア  　ａ：正　　ｂ：正  
  
イ  　ａ：正　　ｂ：誤<hint hidden='true'>**
←⭕
**</hint>    
  
ウ  　ａ：誤　　ｂ：正  
  
エ  　ａ：誤　　ｂ：誤  
`
        },
        {
                "mondaiId": 107300142,
                "name": "第14問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
 次の文章を読んで、下記の設問に答えよ。  
　<u>中小企業基本法</u>は、中小企業施策について、基本理念・基本方針等を定めている。同法の基本理念では、中小企業を「多様な事業の分野において特色ある事業活動を行い、多様な就業の機会を提供し、個人がその能力を発揮しつつ事業を行う機会を提供することにより（　Ａ　）」と位置付けている。  

　また、小規模企業は、「（　Ｂ　）事業活動を行い、就業の機会を提供する」など、地域経済の安定・地域住民の生活の向上及び交流の促進に寄与するとともに、「（　Ｃ　）事業活動を行い、新たな産業を創出する」など、将来における我が国経済社会の発展に寄与する、という　2　つの重要な意義を有するとしている。  

（設問　1　）  
文中の下線部の中小企業基本法に基づく、中小企業者と小規模企業者の範囲に関する記述の正誤の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　資本金　1　億円で従業員数が　50　人の食品卸売業者は中小企業者の範囲に含まれ、資本金　1　千万円で従業員数　5　人の食品卸売業者は小規模企業者の範囲に含  
まれる。  

ｂ　資本金　5　億円で従業員数が　200　人の食品製造業者は中小企業者の範囲に含まれ、資本金　1　千万円で従業員数　30　人の食品製造業者は小規模企業者の範囲に含まれる。　　 


（設問　2　）  
文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  　国民経済の健全な発展に寄与している  
  
イ  　国民生活の向上に寄与している  
  
ウ  　我が国の経済の基盤を形成している<hint hidden='true'>**
←⭕
**</hint>    
  
エ  　我が国の経済の多様な需要に対応している`
        },
        {
                "mondaiId": 107300143,
                "name": "第14問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  
　<u>中小企業基本法</u>は、中小企業施策について、基本理念・基本方針等を定めている。同法の基本理念では、中小企業を「多様な事業の分野において特色ある事業活動を行い、多様な就業の機会を提供し、個人がその能力を発揮しつつ事業を行う機会を提供することにより（　Ａ　）」と位置付けている。  

　また、小規模企業は、「（　Ｂ　）事業活動を行い、就業の機会を提供する」など、地域経済の安定・地域住民の生活の向上及び交流の促進に寄与するとともに、「（　Ｃ　）事業活動を行い、新たな産業を創出する」など、将来における我が国経済社会の発展に寄与する、という　2　つの重要な意義を有するとしている。  

（設問　3　）  
文中の空欄ＢとＣに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｂ：創意工夫を生かした　　　Ｃ：環境の変化に応じた  
  
イ  　Ｂ：創意工夫を生かした　　　Ｃ：創造的な  
  
ウ  　Ｂ：地域の特色を生かした　　Ｃ：環境の変化に応じた  
  
エ  　Ｂ：地域の特色を生かした　　Ｃ：創造的な<hint hidden='true'>**
←⭕
**</hint>  

`
        },
        {
                "mondaiId": 107300151,
                "name": "第15問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のＸ氏は、食品小売業を営むＹ氏から、「親族や従業員など身近  
なところに後継者が見つからないため、社外に後継者を求めることを検討したい」  
との相談を受けた。そこで、Ｘ氏はＹ氏に、中小企業政策を活用した事業承継につ  
いて説明を行った。  

以下は、Ｘ氏とＹ氏との会話の一部である。  

Ｘ氏：「以上が事業承継の大まかなプロセスです。」  

Ｙ氏：「ありがとうございます。ところで、今の説明に出てきたデューデリジェン  
スとは、どのようなことなのでしょうか。」  

Ｘ氏：「説明が足りずにすみません。ここでデューデリジェンスとは　Ａ　」  

Ｙ氏：「分かりました。」  

Ｘ氏：「詳細は、各都道府県に設置されている　Ｂ　に相談をしてみてはいか  
がでしょうか。ここでは、事業承継に関わる幅広い相談を受け付けているほ  
か、　Ｃ　を行う後継者人材バンクなどを利用することができますよ。」  

Ｙ氏：「ぜひ、検討をしてみます。」  


（設問　1　）  
文中の空欄Ａに入る記述として、最も適切なものはどれか。  
  
ア  　M＆A　などの取引の際に行われる会社の価値の調査のことを言います。  
  
イ  　会社名が特定されないように概要を簡単に要約することを言います。  
  
ウ  　会社を　M＆A　によって外部の会社に譲渡することを言います。  
  
エ  　当事者以外の「意見」や「意見を求める行為」のことを言います。  
`
        },
        {
                "mondaiId": 107300152,
                "name": "第15問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のＸ氏は、食品小売業を営むＹ氏から、「親族や従業員など身近  
なところに後継者が見つからないため、社外に後継者を求めることを検討したい」  
との相談を受けた。そこで、Ｘ氏はＹ氏に、中小企業政策を活用した事業承継につ  
いて説明を行った。  

以下は、Ｘ氏とＹ氏との会話の一部である。  

Ｘ氏：「以上が事業承継の大まかなプロセスです。」  

Ｙ氏：「ありがとうございます。ところで、今の説明に出てきたデューデリジェン  
スとは、どのようなことなのでしょうか。」  

Ｘ氏：「説明が足りずにすみません。ここでデューデリジェンスとは　Ａ　」  

Ｙ氏：「分かりました。」  

Ｘ氏：「詳細は、各都道府県に設置されている　Ｂ　に相談をしてみてはいか  
がでしょうか。ここでは、事業承継に関わる幅広い相談を受け付けているほ  
か、　Ｃ　を行う後継者人材バンクなどを利用することができますよ。」  

Ｙ氏：「ぜひ、検討をしてみます。」  


（設問　2　）  
文中の空欄ＢとＣに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｂ：事業引継ぎ支援センター　　Ｃ：起業を志す学生の情報提供  
  
イ  　Ｂ：事業引継ぎ支援センター　　Ｃ：起業を志す個人とマッチング  
  
ウ  　Ｂ：中小企業再生支援協議会　　Ｃ：起業を志す学生の情報提供  
  
エ  　Ｂ：中小企業再生支援協議会　　Ｃ：起業を志す個人とマッチング  
`
        },
        {
                "mondaiId": 107300161,
                "name": "第16問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成　28　年　7　月に、「中小企業の新たな事業活動の促進に関する法律（中小企業新  
事業活動促進法）」を改正する　Ａ　が施行された。  

「中小企業新事業活動促進法」は、中小企業の新たな事業活動の促進を柱とした法  
律であるが、　Ａ　は「新たな事業活動」に加えて、これまで支援対象となって  
いなかった「　Ｂ　」を支援し、中小企業の生産性向上を図るために、様々な支  
援を規定している。  

Ａ　では、「<u>経営革新</u>」を「事業者が新事業活動を行うことにより、その“経営  
の相当程度の向上”を図ること」と定義している。ここで、“経営の相当程度の向上”  
とは、⑴「付加価値額」または「一人当たりの付加価値額」の伸び率、⑵「　Ｃ　」  
の伸び率の　2　つの指標が、　Ｄ　で、相当程度向上することをいう。  

（設問　1　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：中小企業経営革新法　　　Ｂ：新連携  
  
イ  　Ａ：中小企業経営革新法　　　Ｂ：本業の成長  
  
ウ  　Ａ：中小企業等経営強化法　　Ｂ：新連携  
  
エ  　Ａ：中小企業等経営強化法　　Ｂ：本業の成長  
`
        },
        {
                "mondaiId": 107300162,
                "name": "第16問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成　28　年　7　月に、「中小企業の新たな事業活動の促進に関する法律（中小企業新  
事業活動促進法）」を改正する　Ａ　が施行された。  

「中小企業新事業活動促進法」は、中小企業の新たな事業活動の促進を柱とした法  
律であるが、　Ａ　は「新たな事業活動」に加えて、これまで支援対象となって  
いなかった「　Ｂ　」を支援し、中小企業の生産性向上を図るために、様々な支  
援を規定している。  

Ａ　では、「<u>経営革新</u>」を「事業者が新事業活動を行うことにより、その“経営  
の相当程度の向上”を図ること」と定義している。ここで、“経営の相当程度の向上”  
とは、⑴「付加価値額」または「一人当たりの付加価値額」の伸び率、⑵「　Ｃ　」  
の伸び率の　2　つの指標が、　Ｄ　で、相当程度向上することをいう。  

（設問　2　）  
文中の下線部「経営革新」に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  　具体的な数値目標を含んだ経営革新計画の作成が要件となる。  
  
イ  　支援対象には、業種による制約条件がある。  
  
ウ  　都道府県等が、承認企業に対して進捗状況の調査を行う。  
  
エ  　任意グループや組合等の柔軟な連携体制での経営革新計画の実施が可能であ  
る。`
        },
        {
                "mondaiId": 107300163,
                "name": "第16問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成　28　年　7　月に、「中小企業の新たな事業活動の促進に関する法律（中小企業新  
事業活動促進法）」を改正する　Ａ　が施行された。  

「中小企業新事業活動促進法」は、中小企業の新たな事業活動の促進を柱とした法  
律であるが、　Ａ　は「新たな事業活動」に加えて、これまで支援対象となって  
いなかった「　Ｂ　」を支援し、中小企業の生産性向上を図るために、様々な支  
援を規定している。  

Ａ　では、「<u>経営革新</u>」を「事業者が新事業活動を行うことにより、その“経営  
の相当程度の向上”を図ること」と定義している。ここで、“経営の相当程度の向上”  
とは、⑴「付加価値額」または「一人当たりの付加価値額」の伸び率、⑵「　Ｃ　」  
の伸び率の　2　つの指標が、　Ｄ　で、相当程度向上することをいう。  

（設問　3　）  
文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｃ：営業利益　　Ｄ：　1　年～　3　年  
  
イ  　Ｃ：営業利益　　Ｄ：　3　年～　5　年  
  
ウ  　Ｃ：経常利益　　Ｄ：　1　年～　3　年  
  
エ  　Ｃ：経常利益　　Ｄ：　3　年～　5　年  
`
        },
        {
                "mondaiId": 107300171,
                "name": "第17問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のＸ氏は、衣料品小売業を営む小規模事業者のＹ氏から、「小規  
模事業者向けの融資制度を知りたい」との相談を受けた。Ｙ氏は、Ｚ商工会の経営  
指導員による経営指導を　3　年間受けており、Ｚ商工会地区で　5　年間事業を行ってい  
る。そこで、Ｘ氏はＹ氏に「小規模事業者経営改善資金融資制度」を紹介することに  
した。  

以下は、Ｘ氏とＹ氏との会話である。  

Ｘ氏：「小規模事業者経営改善資金融資制度は、小規模事業者の経営改善のための  
有利な融資制度です。」  

Ｙ氏：「私は、融資対象になりますでしょうか。」  

Ｘ氏：「対象となるには、商工会・商工会議所の経営指導員による経営指導を原則  
Ａ　受けていること、所得税、法人税、事業税、都道府県民税などの  
税金を原則として完納していること、原則として同一の商工会等の地区内で  
Ｂ　事業を行っていることなどの要件がありますが、Ｙさんは該当し  
ますね。」  

Ｙ氏：「具体的な支援内容について教えていただけますか。」  

Ｘ氏：「この融資制度では、低利で融資を受���ることができます。融資対象となる  
資金は、設備資金と運転資金で、貸付限度額は　Ｃ　です。担保・保証  
人は　Ｄ　。」  

Ｙ氏：「ありがとうございます。さっそく、利用を検討してみます。」  

  
（設問　1　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：　6　か月以上　　Ｂ：　1　年以上  
  
イ  　Ａ：　6　か月以上　　Ｂ：　2　年以上  
  
ウ  　Ａ：　1　年以上　　　Ｂ：　1　年以上  
  
エ  　Ａ：　1　年以上　　　Ｂ：　2　年以上`
        },
        {
                "mondaiId": 107300172,
                "name": "第17問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のＸ氏は、衣料品小売業を営む小規模事業者のＹ氏から、「小規  
模事業者向けの融資制度を知りたい」との相談を受けた。Ｙ氏は、Ｚ商工会の経営  
指導員による経営指導を　3　年間受けており、Ｚ商工会地区で　5　年間事業を行ってい  
る。そこで、Ｘ氏はＹ氏に「小規模事業者経営改善資金融資制度」を紹介することに  
した。  

以下は、Ｘ氏とＹ氏との会話である。  

Ｘ氏：「小規模事業者経営改善資金融資制度は、小規模事業者の経営改善のための  
有利な融資制度です。」  

Ｙ氏：「私は、融資対象になりますでしょうか。」  

Ｘ氏：「対象となるには、商工会・商工会議所の経営指導員による経営指導を原則  
Ａ　受けていること、所得税、法人税、事業税、都道府県民税などの  
税金を原則として完納していること、原則として同一の商工会等の地区内で  
Ｂ　事業を行っていることなどの要件がありますが、Ｙさんは該当し  
ますね。」  

Ｙ氏：「具体的な支援内容について教えていただけますか。」  

Ｘ氏：「この融資制度では、低利で融資を受���ることができます。融資対象となる  
資金は、設備資金と運転資金で、貸付限度額は　Ｃ　です。担保・保証  
人は　Ｄ　。」  

Ｙ氏：「ありがとうございます。さっそく、利用を検討してみます。」  

（設問　2　）  
文中の空欄ＣとＤに入る数値と語句の組み合わせとして、最も適切なものはど  
れか。  
  
ア  　Ｃ：1，000　万円　　Ｄ：不要です  
  
イ  　Ｃ：1，000　万円　　Ｄ：必要になることもあります  
  
ウ  　Ｃ：2，000　万円　　Ｄ：不要です  
  
エ  　Ｃ：2，000　万円　　Ｄ：必要になることもあります  
`
        },
        {
                "mondaiId": 107300181,
                "name": "第18問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の下請中小企業の支援に関する文章を読んで、下記の設問に答えよ。  

Ａ　は、　Ｂ　の規模によって「優越的地位」にあるかどうかを規定す  
るとともに、製造委託、修理委託、情報成果物作成委託及び役務提供委託の行為に  
ついて、下請取引を適正化し、下請事業者の利益を保護することを目的としてい  
る。この法律では、<u>親事業者には　4　つの義務</u>と　11　項目の禁止事項が課されている。  

（設問　1　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：下請代金支払遅延等防止法　　Ｂ：資本金  
  
イ  　Ａ：下請代金支払遅延等防止法　　Ｂ：従業者数  
  
ウ  　Ａ：下請中小企業振興法　　　　　Ｂ：資本金  
  
エ  　Ａ：下請中小企業振興法　　　　　Ｂ：従業者数`
        },
        {
                "mondaiId": 107300182,
                "name": "第18問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の下請中小企業の支援に関する文章を読んで、下記の設問に答えよ。  

Ａ　は、　Ｂ　の規模によって「優越的地位」にあるかどうかを規定す  
るとともに、製造委託、修理委託、情報成果物作成委託及び役務提供委託の行為に  
ついて、下請取引を適正化し、下請事業者の利益を保護することを目的としてい  
る。この法律では、<u>親事業者には　4　つの義務</u>と　11　項目の禁止事項が課されている。  

（設問　2　）  
文中の下線部に関する記述として、最も適切なものはどれか。  
  
ア  　支払期日までに代金を支払わなかった場合でも、下請事業者の了解を得てい  
れば、遅延利息を支払う必要がない。  
  
イ  　請求書を受領した日から　120　日以内に代金を支払う必要がある。  
  
ウ  　取引が完了した後も、取引の内容を記録し、　2　年間保存する必要がある。  
  
エ  　発注の際は、親事業者と下請事業者が対面して、発注内容を確認しなければ  
ならない。  
`
        },
        {
                "mondaiId": 107300191,
                "name": "第19問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

地域・まちなか商業活性化支援事業は、商店街等における「少子・高齢化」「地域  
交流」「新陳代謝」「構造改善」「外国人対応」「地域資源活用」の分野に係る取り組みを  
支援するものである。  

この事業における「中心市街地再興戦略事業」では、　Ａ　に基づき、  
Ｂ　が実施する、①　Ｃ　、②　Ｄ　、③先導的・実証的事業に対  
し、重点的に支援を行う。  

（設問　1　）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ａ：商店街活性化事業計画　　　　Ｂ：地方公共団体  
  
イ  　Ａ：商店街活性化事業計画　　　　Ｂ：民間事業者  
  
ウ  　Ａ：中心市街地活性化基本計画　　Ｂ：地方公共団体  
  
エ  　Ａ：中心市街地活性化基本計画　　Ｂ：民間事業者`
        },
        {
                "mondaiId": 107300192,
                "name": "第19問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

地域・まちなか商業活性化支援事業は、商店街等における「少子・高齢化」「地域  
交流」「新陳代謝」「構造改善」「外国人対応」「地域資源活用」の分野に係る取り組みを  
支援するものである。  

この事業における「中心市街地再興戦略事業」では、　Ａ　に基づき、  
Ｂ　が実施する、①　Ｃ　、②　Ｄ　、③先導的・実証的事業に対  
し、重点的に支援を行う。  

（設問　2　）  
文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｃ：新サービス開発事業　　Ｄ：専門人材活用支援事業  
  
イ  　Ｃ：新サービス開発事業　　Ｄ：販路開拓事業  
  
ウ  　Ｃ：調査事業　　　　　　　Ｄ：専門人材活用支援事業  
  
エ  　Ｃ：調査事業　　　　　　　Ｄ：販路開拓事業  
`
        },
        {
                "mondaiId": 107300200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
Ｘ氏（60　歳）は、これまでの企業での勤務経験を生かして、ベンチャー企業の創  
業を予定している。起業に当たっては、従業員の雇用を検討中である。Ｘ氏から起  
業の相談を受けた中小企業診断士のＹ氏は「生涯現役起業支援助成金」を紹介するこ  
とにした。  

以下は、Ｘ氏とＹ氏との会話である。会話の中の空欄ＡとＢに入る語句の組み合  
わせとして、最も適切なものを下記の解答群から選べ。  

Ｘ氏：「従業員の雇用に関する支援策について教えていただけますでしょうか。」  

Ｙ氏：「起業に伴い従業員を雇用する場合、経費を助成する制度があります。生涯  
現役起業支援助成金という制度です。」  

Ｘ氏：「私は、支援対象になりますでしょうか。」  

Ｙ氏：「対象となる方は、　Ａ　の事業主ですので、Ｘさんは該当します。  
Ｂ　を提出して認定を受けることが必要です。」  

Ｘ氏：「この制度で、会社設立費用も、助成対象になりますでしょうか。」  

Ｙ氏：「会社設立費用は助成対象になりません。」  

Ｘ氏：「助成内容について教えていただけますか。」  

Ｙ氏：「Ｘさんは　60　歳以上ですので、助成率は　3　分の　2　になります。助成上限は原  
則として　200　万円です。」  


〔解答群〕  
  
ア  　Ａ：40　歳以上　　Ｂ：雇用創出のための募集・採用や教育訓練に関する計画  
  
イ  　Ａ：40　歳以上　　Ｂ：生産性向上に資する経営計画  
  
ウ  　Ａ：60　歳以上　　Ｂ：雇用創出のための募集・採用や教育訓練に関する計画  
  
エ  　Ａ：60　歳以上　　Ｂ：生産性向上に資する経営計画  
`
        },
        {
                "mondaiId": 107300210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

「地域中核企業創出・支援事業」は、地域経済を牽引する地域中核企業へと成長で  
きる企業を発掘するとともに、地域中核企業候補とパートナー企業や大学等との連  
携体制の構築や、グローバルな展開も視野に入れた地域中核企業の事業化戦略の立  
案や販路開拓、事業化のための研究開発の取り組みを支援するものである。  

この事業の対象は、新分野や新事業等に取り組む地域の中小・中堅企業を支援す  
る団体等であり、この事業の支援内容には、　Ａ　と　Ｂ　がある。  

〔解答群〕  
  
ア  　Ａ：地域資源活用型支援　　Ｂ：新事業創出型支援  
  
イ  　Ａ：地域資源活用型支援　　Ｂ：ハンズオン型支援  
  
ウ  　Ａ：ネットワーク型支援　　Ｂ：新事業創出型支援  
  
エ  　Ａ：ネットワーク型支援　　Ｂ：ハンズオン型支援  
`
        },
        {
                "mondaiId": 107300221,
                "name": "第22問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

法人が支出した交際費等は、原則として、全額を損金の額に算入しないこととさ  
れているが、中小法人は、①　Ａ　円までの交際費等の全額損金算入、②接待  
飲食費の　50　％の損金算入の選択適用が認められている。  

ここで、中小法人とは、普通法人のうち、各事業年度終了時において、資本金又  
は出資金の額が　Ｂ　円以下の法人又は資本若しくは出資を有しない法人のこ  
とをいう。  

なお、資本金が　Ｂ　円以下　でも、大法人（資本金又は出資金の額が  
Ｃ　円以上の法人）、相互会社等の　100　％子会社は中小法人とはならない。  

（設問　1　）  
文中の空欄Ａに入る数値として、最も適切なものはどれか。  
  
ア  　　300　万  
  
イ  　　500　万  
  
ウ  　　800　万  
  
エ  　1，000　万`
        },
        {
                "mondaiId": 107300222,
                "name": "第22問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

法人が支出した交際費等は、原則として、全額を損金の額に算入しないこととさ  
れているが、中小法人は、①　Ａ　円までの交際費等の全額損金算入、②接待  
飲食費の　50　％の損金算入の選択適用が認められている。  

ここで、中小法人とは、普通法人のうち、各事業年度終了時において、資本金又  
は出資金の額が　Ｂ　円以下の法人又は資本若しくは出資を有しない法人のこ  
とをいう。  

なお、資本金が　Ｂ　円以下　でも、大法人（資本金又は出資金の額が  
Ｃ　円以上の法人）、相互会社等の　100　％子会社は中小法人とはならない。  

（設問　2　）  
文中の空欄ＢとＣに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  　Ｂ：5，000　万　　Ｃ：　3　億  
  
イ  　Ｂ：5，000　万　　Ｃ：　5　億  
  
ウ  　Ｂ：　1　億　　Ｃ：　3　億  
  
エ  　Ｂ：　1　億　　Ｃ：　5　億  
`
        },
        {
                "mondaiId": 107300230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2018,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業のＸ社は、「安全・確実・有利に退職金制度を確立したい」と考えている  
が、独力では退職金制度をもつことが困難な状況である。そこで、中小企業診断士  
のＹ氏は、Ｘ社の経営者に対して、「中小企業退職金共済制度」を紹介することにし  
た。  

以下は、Ｘ社の経営者とＹ氏との会話である。会話の中の空欄ＡとＢに入る語句  
の組み合わせとして、最も適切なものを下記の解答群から選べ。  

Ｘ社の経営者：「中小企業退職金共済制度とは、どのような制度なのですか。」  

Ｙ　　　　氏：「勤労者退職金共済機構と退職金共済契約を結び、掛金を納付する  
ことで、簡単に退職金制度を設けることができます。　Ａ  
で、新規加入時等には掛金の一部を　Ｂ　が助成します。」  

Ｘ社の経営者：「ぜひ、その共済制度の利用を検討してみたいと思います。」  

Ｙ　　　　氏：「詳しくは、勤労者退職金共済機構に問い合わせてみるといいです  
よ。」  
〔解答群〕  
  
ア  　Ａ：掛金の一部は非課税　　Ｂ：国  
  
イ  　Ａ：掛金の一部は非課税　　Ｂ：都道府県  
  
ウ  　Ａ：掛金は全額非課税　　　Ｂ：国  
  
エ  　Ａ：掛金は全額非課税　　　Ｂ：都道府県  
`
        },
        {
                "mondaiId": 101290010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本、アメリカ、EUの失業率の推移を示している。  
図中のａ~ｃに該当する国・地域の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

<imgTag>101290010_1.png</imgTag>  
出所：内閣府　世界経済の潮流（2016年Ⅰ）  

<hint hidden='true'>**
冒頭問題の定番、統計データから該当する国・地域を問う問題です。  
　
bは、2008年リーマンショックで失業率が急上昇したアメリカです。  
cは、選択肢の3地域の中で最も失業率が低いので、日本です。  
aは、失業率が投じて高く、リーマン・ショック後も<hintTarget>失業率が下がっていない</hintTarget>ので、EUです。
**</hint>  

（解答群） 
  
ア  ａ：EUｂ：アメリカｃ：日本  
  
イ  ａ：EUｂ：日本ｃ：アメリカ  
  
ウ  ａ：アメリカｂ：EUｃ：日本  
  
エ  ａ：アメリカｂ：日本ｃ：EU  
  
オ  ａ：日本ｂ：アメリカｃ：EU  
        
`
        },
        {
                "mondaiId": 101290020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、2000年以降の日本の経常収支の推移を示している。経常収支は、貿易・  
サービス収支、第１次所得収支、第２次所得収支から構成される。  
図中のａ~ｃのうち、貿易・サービス収支と第１次所得収支に該当するものの組  
み合わせとして、最も適切なものを下記の解答群から選べ。  


<imgTag>101290020_1.png</imgTag>  
出所：財務省ホームページ  

（解答群）  
  
ア  貿易・サービス収支：ａ　第１次所得収支：ｂ  
  
イ  貿易・サービス収支：ａ　第１次所得収支：ｃ  
  
ウ  貿易・サービス収支：ｂ　第１次所得収支：ａ  
  
エ  貿易・サービス収支：ｃ　第１次所得収支：ａ  
  
オ  貿易・サービス収支：ｃ　第１次所得収支：ｂ  
        
`
        },
        {
                "mondaiId": 101290030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　国内総生産（GDP）に含まれるものとして、最も適切なものの組み合わせを下記の解答群から選べ。  

<hint hidden='true'>**
GDPの定義は、<hintTarget>国内で⽣産された最終的な財・サービスの付加価値</hintTarget>の総額です。
**</hint>   

ａ　株価の上昇  

<hint hidden='true'>**
❌国内で⽣産された最終的な財・サービスの付加価値に該当しません。  
** </hint>

ｂ　警察や消防などの公共サービスの提供<hint hidden='true'>**
←⭕  
**</hint>  

ｃ　農家の自家消費<hint hidden='true'>**
←⭕市場で取引されたとみなして、付加価値とします。  
**</hint>  

ｄ　中古住宅の購入  

<hint hidden='true'>**
❌仲介業者による付加価値はGDPに該当しますが、所有権の移転だけでは、付加価値に該当しません。  
** </hint>


[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ<hint hidden='true'>**
←⭕  
**</hint>  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 101290041,
                "name": "第4問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
GDPは、国の経済の大きさを測る際に利用される代表的な尺度のひとつである。  
GDPを需要サイドから捉えたものは総需要と呼ばれる。以下の設問に答えよ。  

（設問１） 

総需要は、民間消費、民間投資、政府支出、純輸出から構成される。下図は、  
2000年度以降の日本の総需要の構成割合を表している。  

図中のａ~ｃに該当するものの組み合わせとして、最も適切なものを下記の解  
答群から選べ。  


<imgTag>101290041_1.png</imgTag>  
出所：内閣府ホームページ  


（解答群）  
  
ア  ａ：純輸出ｂ：政府支出ｃ：民間投資  
  
イ  ａ：政府支出ｂ：純輸出ｃ：民間投資  
  
ウ  ａ：政府支出ｂ：民間投資ｃ：純輸出  
  
エ  ａ：民間投資ｂ：純輸出ｃ：政府支出  
  
オ  ａ：民間投資ｂ：政府支出ｃ：純輸出  
        
`
        },
        {
                "mondaiId": 101290042,
                "name": "第4問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
GDPは、国の経済の大きさを測る際に利用される代表的な尺度のひとつである。  
GDPを需要サイドから捉えたものは総需要と呼ばれる。以下の設問に答えよ。  

（設問２）  
総需要の大きさは、均衡GDPの決定にとって重要である。総需要と均衡GDP  
の関係は、下図のような45度線図によって表すことができる。下図で、YFは完  
全雇用GDP、YEは現実のGDP、ADは総需要である。総需要線がAD0から  
AD1に上方シフトすることで完全雇用GDPを実現できる。  
このとき、乗数の大きさを表すものとして、最も適切なものを下記の解答群か  
ら選べ。  


<imgTag>101290042_1.png</imgTag>  


（解答群）  
  
ア    
　AF  
＿＿＿  
　AB  
  
イ    
　AF  
＿＿＿  
　AE  
  
ウ    
　AB  
＿＿＿  
　BF  
  
エ    
　AF  
＿＿＿  
　BF  
        
`
        },
        {
                "mondaiId": 101290050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
需給ギャップ（GDPギャップ）は景気や物価の動向を把握するための有効な指標  
であり、マクロ経済政策の判断において重要な役割を果たしている。日本では、内  
閣府や日本銀行などがこれを推計し、公表している。  
需給ギャップに関する記述として、最も適切なものはどれか。  
  
ア  オークンの法則によれば、需給ギャップがプラスのとき、雇用市場は過少雇用  
の状態にあると考えられる。  
  
イ  需給ギャップのプラスが拡大しているとき、物価はディスインフレーションの  
状態にあると考えられる。  
  
ウ  需給ギャップのマイナスが拡大しているとき、景気は後退していると考えられ  
る。  
  
エ  需給ギャップは、  
　潜在GDP　－　実際のGDP  
＿＿＿＿＿＿＿＿＿＿＿＿＿＿
　　　実際のGDP  
によって計算される。  
        
`
        },
        {
                "mondaiId": 101290060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
景気動向指数の個別系列は、先行系列、一致系列、遅行系列に分けられる。各系  
列の具体例の組み合わせとして、最も適切なものはどれか。  
  
ア  先行系列：消費者物価指数(生鮮食品を除く総合)  
一致系列：実質法人企業設備投資(全産業)  
遅行系列：法人税収入  
  
イ  先行系列：所定外労働時間指数(調査産業計)  
一致系列：耐久消費財出荷指数  
遅行系列：営業利益(全産業)  
  
ウ  先行系列：中小企業売上げ見通しDI  
一致系列：新規求人数(除学卒)  
遅行系列：新設住宅着工床面積  
  
エ  先行系列：東証株価指数  
一致系列：有効求人倍率(除学卒)  
遅行系列：完全失業率  
        
`
        },
        {
                "mondaiId": 101290070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　2016年9月、日本銀行は金融緩和強化のための新しい枠組みとして｢長短金利操作付き量的・質的金融緩和｣を導入した。この枠組みでは、｢消費者物価上昇率の実績値が安定的に2％を超えるまで、マネタリーベースの拡大方針を継続する｣こととされている。  
　マネタリーベースに関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

<hint hidden='true'>**
マネタリーベース、マネーストックに関する問題です。各用語の定義は、画面下部の類似問題を参照ください。  
** </hint>

ａ　<hintTarget>マネタリーベース</hintTarget>は、金融部門から経済全体に供給される通貨の総量である。  

<hint hidden='true'>**
❌<hintTarget>マネー・ストック</hintTarget>に関する記述です  
** </hint>

ｂ　マネタリーベースは、<hintTarget>日本銀行券発行高、貨幣流通高、日銀当座預金</hintTarget>の合計である。

<hint hidden='true'>**
⭕適切です。
** </hint>  

ｃ　日本銀行による買いオペレーションの実施は、マネタリーベースを<hintTarget>増加</hintTarget>させる。  

<hint hidden='true'>**
⭕適切です。
** </hint>  

ｄ　日本銀行によるドル買い・円売りの外国為替市場介入は、マネタリーベースを<hintTarget>減少</hintTarget>させる。  

<hint hidden='true'>**
❌増加します  
** </hint>


[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ<hint hidden='true'>**
←⭕
** </hint>    
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101290080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
投資の決定に関する記述として、最も適切なものの組み合わせを下記の解答群か  
ら選べ。  

ａケインズの投資理論によれば、利子率の低下は投資を増加させる。  
ｂ資本ストック調整原理によれば、投資の調整速度が大きいほど、投資が減少す  
る。  
ｃ投資の限界効率とは、投資収益の現在価値の合計を投資費用に等しくさせる収  
益率である。  
ｄトービンのqとは、企業の市場価値を資本の割引価値で除したものである。  

（解答群）  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101290091,
                "name": "第9問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
下図は、IS曲線とLM曲線を描いている。この図に基づいて、下記の設問に答  
えよ。  

<imgTag>101290091_1.png</imgTag> 


（設問１）  
IS曲線、LM曲線は、それぞれ生産物市場と貨幣市場を均衡させるGDPと利  
子率の関係を表している。下記の記述のうち、最も適切なものはどれか。  
  
ア  Ⅰの領域では、生産物市場が超過需要であり、貨幣市場が超過供給である。  
  
イ  Ⅱの領域では、生産物市場と貨幣市場がともに超過供給である。  
  
ウ  Ⅲの領域では、生産物市場と貨幣市場がともに超過需要である。  
  
エ  Ⅳの領域では、生産物市場が超過供給であり、貨幣市場が超過需要である。  
        
`
        },
        {
                "mondaiId": 101290092,
                "name": "第9問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
下図は、IS曲線とLM曲線を描いている。この図に基づいて、下記の設問に答  
えよ。  

<imgTag>101290091_1.png</imgTag> 


（設問２）  
公債の資産効果をIS-LM分析によって考察する。下記の記述のうち、最も適  
切なものはどれか。  
  
ア  資産効果は、家計の消費支出を刺激することで、IS曲線を左方にシフトさ  
せる。  
  
イ  資産効果は、必ずGDPを増加させる。  
  
ウ  資産効果は、必ず利子率を上昇させる。  
  
エ  資産効果は、貨幣需要を増加させることで、LM曲線を右方にシフトさせ  
る。  
        
`
        },
        {
                "mondaiId": 101290100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　価格と消費者余剰について考える。下図に関する記述として、最も適切なものを  
下記の解答群から選べ。  

<imgTag>101290100_1.png</imgTag> 


（解答群）  

<hint hidden='true'>**
余剰分析に関する問題です。
**</hint>

ア  価格がP0のとき、消費者がQ0を選択する場合の消費者余剰は、消費者の支払意思額よりも大きい。  
  
イ  価格がP1のとき、消費者がQ1を選択する場合の消費者余剰は、Q0を選択する場合の消費者余剰よりも大きい。  
  
ウ  価格がP2のとき、消費者がQ1を選択する場合の消費者余剰は、Q2を選択する場合の消費者余剰よりも大きい。  
  
エ  価格がのとき、実際の支払額はなので、消費者がQ0やQ1を選択しても、消費者余剰は得られない。  
        
`
        },
        {
                "mondaiId": 101290110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図によって間接税(従量税)の経済効果を考える。需要曲線をD、課税前の供  
給曲線をS、課税後の供給曲線をS′で表す。税は生産物1単位当たりtとし、納税  
義務者は生産者とする。下図では、税負担がすべて消費者に転嫁されている。  

この図に関する記述として、最も適切なものの組み合わせを下記の解答群から選  
べ。  

<imgTag>101290110_1.png</imgTag> 


ａ税負担がすべて消費者に転嫁されるとき、消費者の支払う税額は四角形  
PEE′P′で示される。  

ｂ税負担がすべて消費者に転嫁されるとき、生産者の受け取る価格は課税前に比  
べてtだけ低下する。  

ｃ税負担がすべて消費者に転嫁されるのは、需要の価格弾力性がゼロだからであ  
る。  

ｄ税負担がすべて消費者に転嫁されるのは、生産量の増加に伴って限界費用が増  
加するからである。  

（解答群）  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101290120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
無差別曲線は、一般に、原点に対して凸型をした右下がりの曲線である。しかし  
ながら、その形状は消費者の好みなどによって変わることがある。下図のような形  
状の無差別曲線に関する記述として、最も適切なものを下記の解答群から選べ。  


<imgTag>101290120_1.png</imgTag> 

（解答群）  
  
ア  Ａさんは、喫茶店でコーヒーを1杯飲むとき、必ず角砂糖を1個だけ入れ  
る。このとき、X財をコーヒー、Y財を角砂糖とした場合の無差別曲線は図  
のようになる。  
  
イ  Ｂさんは、発泡酒が大好きであるが、どのブランドでもよいと思っている。  
このとき、ある2つのブランドの発泡酒をX財、Y財とした場合の無差別曲  
線は図のようになる。  
  
ウ  Ｃさんは、ゴルフに興味があり、野球には興味がない。このとき、X財を  
ゴルフの観戦チケット、Y財を野球の観戦チケットとした場合の無差別曲線  
は図のようになる。  
  
エ  Ｄさんは、その日の気分により、お昼におにぎりとパンのどちらかを選んで  
購入する。このとき、X財をおにぎり、Y財をパンとした場合の無差別曲線  
は図のようになる。  
        
`
        },
        {
                "mondaiId": 101290130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
需要の価格弾力性は、価格の変化によって売上額に影響を及ぼす。需要の価格弾  
力性と価格戦略に関する記述として、最も適切なものの組み合わせを下記の解答群  
から選べ。  
ａ　需要の価格弾力性が１より小さい場合、企業が価格を引き下げる戦略をとる  
と、売上額は増加する。  

ｂ　需要の価格弾力性が１より小さい場合、企業が価格を引き上げる戦略をとる  
と、需要が減少して、売上額も減少する。  

ｃ　需要の価格弾力性が１に等しい場合、企業が価格を変化させる戦略をとって  
も、売上額は変化しない。  

ｄ　需要の価格弾力性が１より大きい場合、企業が価格を引き下げる戦略をとる  
と、売上額は増加する。  

（解答群）  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｄ  
  
エ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 101290140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、総費用曲線が描かれている。生産が行われないときの費用は点Aで  
示されている。この図に関する記述として、最も適切なものを下記の解答群から選  
べ。  


<imgTag>101290140_1.png</imgTag> 

（解答群）  
  
ア  AFを１とすると、BFが平均可変費用を表している。  
  
イ  原点と点Cを結ぶ直線の傾きが限界費用を表している。  
  
ウ  産出量Q0における可変費用はFGに等しい。  
  
エ  産出量Q1における固定費用は、Q0における固定費用にHIを加えたもので  
ある。  
  
オ  点Cにおける総費用曲線の接線の傾きが平均費用を表している。  
        
`
        },
        {
                "mondaiId": 101290150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、等費用線が描かれている。この等費用線に関する記述として、最も適  
切なものを下記の解答群から選べ。  


<imgTag>101290150_1.png</imgTag> 

（解答群）  
  
ア  資本のレンタル価格が上昇する場合、横軸上の切片Bは不変のままで、縦  
軸上の切片Aが上方に移動する。  
  
イ  縦軸上の切片Aは、資本の最大投入可能量を示している。  
  
ウ  賃金率が上昇する場合、横軸上の切片Bは不変のままで、縦軸上の切片A  
が下方に移動する。  
  
エ  費用が減少すると、等費用線は右方にシフトする。  
        
`
        },
        {
                "mondaiId": 101290160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年、保育や介護の現場における人手不足が社会問題となっている。この問題に  
対処するための方策として、これらに関わる職種の賃金の引き上げが検討されるこ  
とがある。そこで、賃金の引き上げと労働供給の関係を考察することにした。  
下図を参考にしながら、次の文中の空欄Ａ~Ｄに当てはまる語句として、最も適  
切なものの組み合わせを下記の解答群から選べ。  


<imgTag>101290160_1.png</imgTag> 


人手不足を解消するためには、現在働いていない人に新規に就労してもらうか、  
あるいは現在パート勤務などの短時間労働の人に今までよりも長い時間働いてもら  
うことが必要である。  
すでに働いている人が賃金の上昇によってもっと働くようになるかどうかは、代  
替効果と所得効果によって決まる。賃金の上昇は、Ａ効果によって労働供  
給を増やし、Ｂ効果によって労働供給を減らす。両者の関係は、通常、低  
い賃金水準ではＣ効果の方が大きく、高い賃金水準ではＤ効果の  
方が大きい。したがって、現在の賃金が低い水準であるならば、賃上げは、労働時  
間を増やして人手不足の解消に寄与する。逆に、もし現在の賃金が高い水準にある  
とすれば、賃上げは労働時間を減らすことになる。  
        
        
（解答群）  
  
ア  Ａ：所得Ｂ：代替Ｃ：所得Ｄ：代替  
  
イ  Ａ：所得Ｂ：代替Ｃ：代替Ｄ：所得  
  
ウ  Ａ：代替Ｂ：所得Ｃ：所得Ｄ：代替  
  
エ  Ａ：代替Ｂ：所得Ｃ：代替Ｄ：所得  
        
`
        },
        {
                "mondaiId": 101290170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本は諸外国に比べて労働時間が長いため、休日の過ごし方が重要である。ある  
共働きの夫婦について休日の過ごし方を考える。夫の趣味は水泳であり、妻の趣味  
はジョギングである。２人とも自分の好きなことに付き合って欲しいので、基本的  
には、別々の行動はとりたくない。下表の利得マトリックスは、夫婦の戦略i水泳  
とジョギングdとそれにより得られる利得を示したものである。カッコ内の左側が  
夫の利得、右側が妻の利得である。  

このゲームに関する記述として、最も適切なものの組み合わせを下記の解答群か  
ら選べ。  


<imgTag>101290170_1.png</imgTag> 


ａ　このゲームには、支配戦略がある。  

ｂ　夫と妻がともに水泳をするとき、夫と妻のどちらかが戦略を変えると、戦略を  
変えた方の利得が下がるので、(水泳，水泳)はナッシュ均衡である。  

ｃ　夫と妻がそれぞれ自分の趣味を選ぶとき、夫と妻のどちらかが戦略を変える  
と、戦略を変えた方の利得が下がるので、(水泳，ジョギング)はナッシュ均衡で  
ある。  

ｄ　夫の戦略としては、妻がジョギングがよいといえばジョギングに行くのがよ  
く、また、水泳がよいといえば水泳に行くのがよい。  

（解答群）  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101290180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商品には、新しく登場するものや、市場から姿を消すものがある。その過程を説  
明する下図に関する記述として、最も適切なものの組み合わせを下記の解答群から  
選べ。  


<imgTag>101290180_1.png</imgTag> 


ａ右図では、当初、この商品の限界費用がとても高いので市場が成立していな  
い。  

ｂ左図では、技術進歩によって供給曲線Sがシフトするにしたがって、この商  
品の市場が成立する。  

ｃ右図では、代替品の登場によって需要曲線Dがシフトすれば、この商品はや  
がて市場から姿を消す。  

ｄ左図では、市場への新たな生産者の参入で需要曲線Dがシフトすることによ  
り、市場が成立する。  

（解答群）  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 101290190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
公共財や私的財などの財の特徴として、最も適切なものはどれか。  
  
ア  海洋漁業における水産資源は、すべての漁業者が無償で等しく漁を行うことが  
できるという理由で、競合的な性格を持たない。  
  
イ  公共財の場合だけでなく、競合性と排除性を持つ私的財の場合にも、フリーライダーは出現する。  
  
ウ  公共財は、競合性と排除性を持たないので、等量消費が不可能になる。  
  
エ  有料のケーブルテレビは、その対価を支払わない消費者を排除できる排除性を  
持つが、対価を支払った消費者の間では競合性がない。  
        
`
        },
        {
                "mondaiId": 101290200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表に基づき、国際分業と比較優位について考える。製品Ｐ１個を生産するの  
に、Ａ国では５人の労働が必要であり、Ｂ国では30人の労働が必要である。また、  
製品Ｑ１個を生産するのに、Ａ国では５人の労働が必要であり、Ｂ国では60人の  
労働が必要である。  

このような状況に関する記述として、最も適切なものを下記の解答群から選べ。  

　　　　　　　　　　　　（Ａ国／Ｂ国）  
製品Ｐ１個当たりの労働量　（５人／30人）  
製品Ｑ１個当たりの労働量　（５人／60人）

（解答群）  
  
ア  Ａ国では、製品Ｑの労働生産性が相対的に高いので、製品Ｑの相対価格が高  
くなる。  
  
イ  Ａ国は製品Ｑに絶対優位があり、Ｂ国は製品Ｐに絶対優位がある。  
  
ウ  Ｂ国は、Ａ国に比べて、製品Ｐについては１／６、製品Ｑについては１／１２の生産性なので、製品Ｑに比較優位を持つ。  
  
エ  １人当たりで生産できる個数を同じ価値とすると、Ａ国では、製品Ｐ１個と製品Ｑ１個を交換でき、Ｂ国では製品Ｐ２個と製品Ｑ１個を交換することができる。  
        
`
        },
        {
                "mondaiId": 101290210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
昨今、WTOを中心とする多国間交渉はうまくいかず、FTAのような比較的少  
数の国の間の交渉が増加している。  

下図は、関税引き下げによって輸入品の価格がP0からP1に下落する場合を描い  
ている。この図に関する説明として、最も適切なものを下記の解答群から選べ。  


<imgTag>101290210_1.png</imgTag> 


（解答群）  
  
ア  関税引き下げ後、国内の生産者余剰は、引き下げ前より三角形FGHの分だ  
け減少する。  
  
イ  関税引き下げ後、消費者余剰は、関税引き下げ幅に輸入量CGを乗じた分  
だけ増加する。  
  
ウ  関税引き下げによる、国内の生産者から消費者への再分配効果は、四角形  
P0FGP1である。  
  
エ  関税引き下げによる貿易創造効果は、四角形BCGFの部分である。  
        
`
        },
        {
                "mondaiId": 101290220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
技術革新およびその奨励政策に関する理論的記述として、最も適切なものはどれ  
か。  
  
ア  新しいビジネスモデルのようなアイデアの限界費用がゼロであるとする。この  
とき、競争市場においては、効率性の観点から判断すると、社会に対して無償で  
提供されるのが望ましい。  
  
イ  企業の生産設備は、償却可能資産であり、経年減価する。したがって、競争市  
場においては、法人税で加速度償却を認めることに企業の設備投資を促す効果は  
ない。  
  
ウ  基礎研究は、社会に大きな利益をもたらすとき、外部経済効果を有することに  
なる。したがって、競争市場においては、基礎研究への政府による補助金は企業  
に基礎研究への取り組みを促す効果を期待できない。  
  
エ  研究開発費が固定費用であるとき、研究開発に多額の費用を要する企業は多額  
のサンクコストを抱えることになる。したがって、競争市場においては、政府が  
研究開発費に補助金を支給したとしても、新規企業の参入による研究開発の促進  
は期待できない。  
        
`
        },
        {
                "mondaiId": 101290230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
少子高齢化と日本の経済・財政との関係に関する記述として、最も適切なものは  
どれか。  
  
ア  60歳を超えると年間収入が減少する傾向があるため、世帯主の年齢が60歳以  
上の世帯の貯蓄額は世帯主が60歳未満の世帯の貯蓄額よりも低い水準にある。  
  
イ  生産性が一定のとき、少子化による労働人口の減少はGDPを減少させる。  
  
ウ  高齢化の進行は、社会保障による所得再分配効果を小さくする。  
  
エ  日本の年金財政は、積立方式であるため、人口構成の変化からの直接の影響を  
受けない。  
`
        },
        {
                "mondaiId": 102290010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の期末商品に関する資料に基づいて、棚卸減耗費と商品評価損の金額の組み合  
わせとして、最も適切なものを下記の解答群から選べ。  

【資料】  
帳簿棚卸数量　60個  
実地棚卸数量　50個  
原価　　　　　＠200円  
正味売却価額　＠190円  

<hint hidden='true'>**  
棚卸減耗費　＝　(60－50)×200　＝　2,000円  
商品評価損　＝　(200－190)×50　＝  500円  
　  
棚卸減耗損は、盗難・紛失などで、実際の数量が帳簿上の数量を下回る場合に、帳簿価額を切り下げるために使用します。  
商品評価損は、商品の陳腐化や経年劣化により価値が下がった場合に、帳簿価額を切り下げるために使用します。  
**</hint>  

（解答群）  
  
ア  棚卸減耗費：1，900円　商品評価損：500円  
  
イ  棚卸減耗費：1，900円　商品評価損：600円  
  
ウ  棚卸減耗費：2，000円　商品評価損：500円<hint hidden='true'>**
←⭕
**</hint>    
  
エ  棚卸減耗費：2，000円　商品評価損：600円`
        },
        {
                "mondaiId": 102290020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
20X2年１月１日に300，000千円を期間６カ月、年利５％で取引先Ｚ社に貸し付  
けた。20X2年６月30日に利息と元金を合わせて受け取る予定である。会計期間  
は20X2年３月31日までの１年間である。決算にあたり計上される未収利息の金  
額として、最も適切なものはどれか。  
  
ア  3，750千円  
  
イ  7，500千円  
  
ウ  15，000千円  
  
エ  30，000千円  
        
`
        },
        {
                "mondaiId": 102290030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料に基づき、会社法の規定に基づく剰余金の分配可能額として、最も適切  
なものを下記の解答群から選べ。なお、のれん、繰延資産および自己株式の金額は  
ゼロである。  

【資料】  
貸借対照表の一部　（単位：千円）  

純資産の部  
　資本金　500，000  
　資本準備金　40，000  
　その他資本剰余金　20，000  
　利益準備金　110，000  
　任意積立金　50，000  
　繰越利益剰余金　<u>180，000</u>  
　　　　　　　　　　　<u>900，000</u>  

（解答群）  
  
ア  180，000千円  
  
イ  230，000千円  
  
ウ  250，000千円  
  
エ  340，000千円  
        
`
        },
        {
                "mondaiId": 102290040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
20X1年度に工事契約を締結し、工事を開始した。20X3年度に工事が完成し、  
引渡しを行った。各期の工事収益は工事進行基準により計上している。また、決算  
日における工事進捗度は原価比例法により算出している。契約に基づく工事収益総  
額は240，000千円であり、当初の工事原価総額の見積額は180，000千円である。工  
事進行基準を適用した場合の20X1年度の工事収益として、最も適切なものを下記  
の解答群から選べ。  

【資料】  
　　　　　　　　　20X1年　／　20X2年　／　20X3年  
各期の工事原価　90，000千円　／　60，000千円　／　50，000千円  
次期から完成までの工事原価の見積額　90，000千円　／　50，000千円　／　―  

（解答群）  
  
ア  90，000千円  
  
イ  108，000千円  
  
ウ  120，000千円  
  
エ  180，000千円  
        
`
        },
        {
                "mondaiId": 102290050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業会計原則に関する記述として、最も適切なものはどれか。  
  
ア  会計処理の原則および手続きを毎期継続して適用し、みだりに変更してはなら  
ない。  
  
イ  株主総会提出のため、信用目的のため、租税目的のためなど種々の目的のため  
に異なる形式の財務諸表を作成してはならない。  
  
ウ  すべての費用および収益は、その支出および収入の時点において認識し、損益  
計算書に計上しなければならない。  
  
エ  予測される将来の危険に備えて、合理的な見積額を上回る費用を計上すること  
は、保守的な会計処理として認められる。`
        },
        {
                "mondaiId": 102290060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　税効果会計に関する記述として、最も適切なものはどれか。  
  
ア  <hintTarget>受取配当金のうち益金に算入されない</hintTarget>金額は、繰延税金負債を増加させる。  

<hint hidden='true'>**
❌益金に参入されない→税務に影響しない→税務上と会計上の認識時点のずれを調整する税効果会計（繰延税金負債の増加）の対象（一時差異）にあたりません。  
このケースは、永久差異であり、一時差異にあたりません。
** </hint>  

イ  <hintTarget>交際費のうち損金に算入されない</hintTarget>金額は、繰延税金資産を増加させる。  

<hint hidden='true'>**
❌選択肢アの理由と同様です。
** </hint>  

ウ  税法の損金算入限度額を超える貸倒引当金繰入額は、繰延税金資産を<hintTarget>減少</hintTarget>させる。  

<hint hidden='true'>**
❌増加させます。実際の税務上の減価償却費から算出した税金の額は、会計上から見るといわば「税金を払いすぎた」状態なので、繰延税金「資産」を「増加」させます。
** </hint>  

エ  税法の損金算入限度額を超える減価償却費は、繰延税金資産を<hintTarget>増加</hintTarget>させる。<hint hidden='true'>**
⭕実際の税務上の減価償却費から算出した税金の額は、会計上から見るといわば「税金を払いすぎた」状態なので、繰延税金「資産」を「増加」させます。
** </hint>  
        
`
        },
        {
                "mondaiId": 102290070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
固定資産の減損に関する記述として、最も適切なものはどれか。  
  
ア  減損処理を行った場合でも、収益性の回復が認められる場合には減損損失の戻  
入れを行う。  
  
イ  減損損失は、原則として特別損失とする。  
  
ウ  減損損失を認識するかどうかの判定は、個別の資産について行わなければなら  
ず、複数の資産からなる資産グループについて行ってはならない。  
  
エ  固定資産の回収可能価額とは、再調達原価である。  
        
`
        },
        {
                "mondaiId": 102290080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
単純総合原価計算を採用しているA工場の以下の資料に基づき、平均法により  
計算された月末仕掛品原価として、最も適切なものを下記の解答群から選べ。な  
お、材料は工程の始点ですべて投入されている。  

【資料】  
（１）当月の生産量  
　月初仕掛品　200個(加工進捗度50％）  
　当月投入　<u>800個</u>  
　合計　　　　1，000個  
　月末仕掛品　<u>400個</u>(加工進捗度50％）  
　当月完成品　<u>600個</u>  

（２）当月の原価  
　月初仕掛品直接材料費　200千円  
　月初仕掛品加工費　　　100千円  
　当月投入直接材料費　1，000千円  
　当月投入加工費　　　700千円  


（解答群）  
  
ア  500千円  
  
イ  680千円  
  
ウ  700千円  
  
エ  800千円  
        
`
        },
        {
                "mondaiId": 102290090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
標準原価計算を採用しているB工場の以下の資料に基づき、作業時間差異とし  
て、最も適切なものを下記の解答群から選べ。  

【資料】  
（１）原価標準(抜粋）  
　直接労務費300円／時間庵\u000e時間暗1，800円  

（２）当月の生産量  
　月初仕掛品　40個(加工進捗度50％）  
　当月投入　<u>120個</u>  
　合計　160個  
　月末仕掛品　<u>60個</u>(加工進捗度50％）  
　当月完成品　<u>100個</u>  

（３）当月の実際直接労務費  
実際賃率　　　　　310円／時間  
実際直接作業時間　700時間  

（解答群）  
  
ア  不利差異：12，000円  
  
イ  不利差異：12，400円  
  
ウ  有利差異：6，000円  
  
エ  有利差異：6，200円 
`
        },
        {
                "mondaiId": 102290100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
原価計算基準における製造原価に関する記述として、最も適切なものはどれか。  
  
ア  間接労務費には、直接工賃金は含まれない。  
  
イ  形態別分類において、経費とは材料費、労務費、減価償却費以外の原価要素を  
いう。  
  
ウ  原価の発生が特定の製品の生産との関連で直接的に把握されないものを間接費  
という。  
  
エ  直接材料費、直接労務費以外の原価要素を加工費という。  
        
`
        },
        {
                "mondaiId": 102290110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の資料に基づき計算された財務比率の値として、最も適切なものを下記の解答  
群から選べ。  

【資料】  
<imgTag>102290110_1.png</imgTag> 


（解答群）  
  
ア  インタレスト・カバレッジ・レシオは5.5倍である。  
  
イ  固定長期適合率は80％である。  
  
ウ  自己資本利益率は11.3％である。  
  
エ  総資本営業利益率は27.5％である。  
        
`
        },
        {
                "mondaiId": 102290120,
                "name": "第12問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
長期借入金により資金を調達し、その全額を設備投資(新規の生産設備の取得)に  
使用したとする。他の条件を一定とすると、これによる財務比率への影響に関する  
記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ固定比率は不変である。  
ｂ自己資本比率は悪化する。  
ｃ当座比率は悪化する。  
ｄ流動比率は不変である。  

（解答群）  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｃ  
  
オ  ｂとｄ`
        },
        {
                "mondaiId": 102290130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
キャッシュ・フロー計算書における営業活動によるキャッシュ・フローの区分  
(間接法)で増加要因として表示されるものはどれか。最も適切なものを選べ。  
  
ア  売上債権の増加  
  
イ  貸倒引当金の増加  
  
ウ  短期借入金の増加  
  
エ  有形固定資産の売却  
        
`
        },
        {
                "mondaiId": 102290140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄Ａ~Ｄに当てはまる語句の組み合わせとして、最も適切なものを  
下記の解答群から選べ。  

企業の資金調達方法には、大きく分けてＡとＢがある。代表的  
なＡとしてはＣがあり、ＢとしてはＤがあげら  
れる。  

（解答群）  
  
ア  Ａ：外部金融Ｂ：内部金融Ｃ：株式発行Ｄ：利益留保  
  
イ  Ａ：間接金融Ｂ：直接金融Ｃ：企業間信用Ｄ：社債発行  
  
ウ  Ａ：直接金融Ｂ：間接金融Ｃ：社債発行Ｄ：利益留保  
  
エ  Ａ：内部金融Ｂ：外部金融Ｃ：社債発行Ｄ：減価償却
`
        },
        {
                "mondaiId": 102290150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
当社は、来年度の期首に新設備を購入しようと検討中である。新設備の購入価額  
は100百万円であり、購入によって毎年（ただし、５年間）の現金支出費用が30百万円節約されると期待される。減価償却方法は、耐用年数 ５年、残存価額がゼロの  
定額法を採用する予定でいる。税率を40％とするとき、この投資案の各期の税引  
後キャッシュフローとして、最も適切なものはどれか。  
  
ア  12百万円  
  
イ  18百万円  
  
ウ  26百万円  
  
エ  34百万円  
        
`
        },
        {
                "mondaiId": 102290160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
来年度の当社の売上高は、好況の場合20億円、通常の場合15億円、不況の場合  
億円と予想されている。好況になる確率が20％、通常の場合が70％、不況とな  
る確率は10％と予想されているとき、当社の来年度の売上高の期待値として、最  
も適切なものはどれか。  
  
ア  13.8億円  
  
イ  14.0億円  
  
ウ  14.8億円  
  
エ  15.2億円`
        },
        {
                "mondaiId": 102290170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
借入金のあるなし以外は同一条件の２つの企業がある。このとき、税金が存在す  
る場合のモジリアーニとミラーの理論$MM理論’に関する記述として、最も適切  
なものはどれか。  
  
ア  節税効果による資本コストの上昇により、借入金のある企業の企業価値の方が  
高くなる。  
  
イ  節税効果による資本コストの上昇により、無借金企業の企業価値の方が高くな  
る。  
  
ウ  節税効果による資本コストの低下により、借入金のある企業の企業価値の方が  
高くなる。  
  
エ  節税効果による資本コストの低下により、無借金企業の企業価値の方が高くな  
る。  
        
`
        },
        {
                "mondaiId": 102290180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
当社の前期末の  
株当たり配当金は120円であり、今後毎年２％の定率成長が期待されている。資本コストを６％とすると、この株式の理論価格として、最も適切なものはどれか。  
  
ア  2，400円  
  
イ  3，000円  
  
ウ  3，060円  
  
エ  3，180円  
        
`
        },
        {
                "mondaiId": 102290190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A、Bの２つの株式から構成されるポートフォリオにおいて、相関係数をさまざ  
まに設定した場合のリターンとリスクを表した下図の①~④のうち、相関係数が－１であるケースとして、最も適切なものを下記の解答群から選べ。  

<imgTag>102290190_1.png</imgTag> 

（解答群）  
  
ア  ①  
  
イ  ②  
  
ウ  ③  
  
エ  ④  
        
`
        },
        {
                "mondaiId": 102290200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
CAPMが成立する市場において、マーケット・ポートフォリオの期待収益率が６％、安全利子率が１％のとき、当該資産の期待収益率が10％となるベータ値として、最も適切なものはどれか。  
  
ア  1.5  
  
イ  1.8  
  
ウ  2.0  
  
エ  3.0`
        },
        {
                "mondaiId": 102290210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
先渡取引(フォワード)と先物取引(フューチャー)に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  原則的に先物取引は取引所で、先渡取引は店頭$相対’で取引が行われる。  
  
イ  先物取引では、契約の履行を取引所が保証しているため、信用リスクは少ない  
といえる。  
  
ウ  先渡取引では、期日までに約定したものと反対の取引を行い、差金決済により  
清算される。  
  
エ  先渡取引では、原資産、取引条件などは取引の当事者間で任意に取り決める。  
        
`
        },
        {
                "mondaiId": 102290220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
流動性リスクに関する記述として、最も適切なものはどれか。  
  
ア  外国為替レートが変動することにより差損を被るリスク  
  
イ  借入金や債券の金利支払いや元本の返済が遅れたり、支払いが不能となるリス  
ク  
  
ウ  債券を売却するときに、その債券の市場価格が金利変動の影響により値上がり  
したり、値下がりするリスク  
  
エ  市場取引において需給がマッチしないために売買が成立しなかったり、資金繰  
りに失敗するリスク`
        },
        {
                "mondaiId": 102290230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
最適ポートフォリオの選択に関する次の文中の空欄Ａ~Ｃに当てはまる用語の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  

危険資産と安全資産が存在する市場では、どのような投資家であっても、選択さ  
れるポートフォリオはＡ上にある。これは、選択可能な危険資産ポートフ  
ォリオの組み合わせは無数に存在するが、選択される危険資産の組み合わせは、  
Ａと危険資産ポートフォリオのＢが接する点に限られることを意  
味している。  

Ｃに左右される部分は、この唯一選択される危険資産ポートフォリオと  
安全資産への投資比率の決定のみとなり、危険資産ポートフォリオ自体の選択は  
Ｃとは別に決定される。  

（解答群）  
  
ア  Ａ：資本市場線　Ｂ：有効フロンティア　Ｃ：投資家の効用  
  
イ  Ａ：証券市場線　Ｂ：無差別曲線　Ｃ：投資のリターン  
  
ウ  Ａ：無差別曲線　Ｂ：資本市場線　Ｃ：投資の効率性  
  
エ  Ａ：有効フロンティア　Ｂ：証券市場線　Ｃ：投資のリスク  
        
`
        },
        {
                "mondaiId": 102290240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
負債と純資産の構成が２：１の企業がある。この企業の税引前負債資本コストが ３％(税率は40％)、株主資本コストが12％であるときの加重平均資本コストとして、最も適切なものはどれか。  
  
ア  5.2％  
  
イ  5.8％  
  
ウ  6.0％  
  
エ  9.0％  
        
`
        },
        {
                "mondaiId": 102290250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
行使価格1，200円のプットオプションをプレミアム100円で購入した。満期時点  
におけるこのオプションの損益図として、最も適切なものはどれか。  
  
ア  <imgTag>102290250_1.png</imgTag>   
  
イ  <imgTag>102290250_2.png</imgTag>         
  
ウ  <imgTag>102290250_3.png</imgTag>  
  
エ  <imgTag>102290250_4.png</imgTag>   
`
        },
        {
                "mondaiId": 103290010,
                "name": "第1問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　多角化した企業のドメインと事業ポートフォリオの決定に関する記述として、最も適切なものはどれか。  
  
ア  多角化した企業の経営者にとって、事業ドメインの決定は、企業の基本的性格を決めてアイデンティティを確立するという問題である。  
  
イ  多角化した企業の経営者にとって、事業ドメインの決定は、現在の活動領域や製品分野との関連性を示し、将来の企業のあるべき姿や方向性を明示した展開領域を示す。  
  
ウ  多角化した事業間の関連性を考える経営者にとって、企業ドメインの決定は、多角化の広がりの程度と個別事業の競争力とを決める問題である。  
  
エ  多角化した事業間の関連性を考える経営者にとって、事業ドメインの決定は、全社戦略の策定と企業アイデンティティ確立のための指針として、外部の多様な利害関係者との間のさまざまな相互作用を規定する。  
  
オ  多角化を一層進めようとする経営者は、事業間の関連性パターンが集約型の場合、範囲の経済を重視した資源の有効利用を考える。  
        
`
        },
        {
                "mondaiId": 103290020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　プロダクト・ポートフォリオ・マネジメント（PPM）に関する記述として、最も適切なものはどれか。  
  
ア  衰退期に入った業界の｢<hintTarget>金のなる木</hintTarget>｣事業と｢負け犬｣事業は<hintTarget>可及的速やかに撤退</hintTarget>し、成長率の鈍化した業界の｢花形商品｣事業の再活性化に多くのキャッシュを投入することが重要である。  
  
<hint hidden='true'>**
❌金のなる木は、必要な再投資に対して得られるキャッシュフローが高く、撤退が適さないケースが多いと考えられます。  
また、PPMの分類結果は、「可及的速やか」とされるほどに撤退判断に直結する利用を目的としたものではないため、個別の事業の状況を総合的に勘案して判断すべきです。  
**</hint>    

イ  成長市場で競争優位の実現を期待できる｢問題児｣の選択と、競争優位性を期待できないが資金流出の小さい｢負け犬｣事業の中で市場成長率が低くとも高収益事業を選別することは重要である。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ  プロダクト・ポートフォリオ・マネジメントの考え方では、資金の流入は<hintTarget>自社事業の成長率と市場の成長</hintTarget>率、資金の流出は自社事業の競争上の地位（相対的な市場シェア）で決まる。  
  
<hint hidden='true'>**
❌PPMでは、資金の流入は、市場の占有率で決まります。  
資金の流出は、市場の成長率で決まります。**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/103003_1.png" target="_blank" >
<img src="assets/img/kaisetu/103003_1.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>    
  
エ  プロダクト・ポートフォリオ・マネジメントの考え方は、事業間の<hintTarget>マーケティングや技術に関するシナジー</hintTarget>を考慮して、複数事業に対して財務面を重視した資金の再配分のガイドラインとなる。  
    
<hint hidden='true'>**
❌PPMではマーケティングや技術のシナジーは特に考慮しないため、それらの観点を検討する場合は、PPMとは別の検討が必要です。
**</hint>    

オ  プロダクト・ポートフォリオ・マネジメントの考え方は、<hintTarget>自社技術開発、外部技術</hintTarget>の導入、<hintTarget>外部資金</hintTarget>の再配分により、<hintTarget>範囲</hintTarget>の経済を達成して競争優位性を構築する業界に適用できる。  
  
<hint hidden='true'>**
❌選択肢エのとおり、PPMは技術のシナジーの検討に使用するフレームワークではありません。資金の再配分については、<hintTarget>内部資金（自社内の他事業で得た資金）</hintTarget>の再配分の検討に用います（選択肢ウの図のとおり、金のなる木で得た資金を、花形や問題児に配分します）。  
また、内部資金の再配分により、<u>規模</u>の経済を達成して競争優位性を構築する業界に適用できます。
**</hint>    
        
`
        },
        {
                "mondaiId": 103290030,
                "name": "第3問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の経営資源に基づく競争優位性を考察するVRIOフレームワークに関する  
記述として、最も適切なものはどれか。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】VRIOフレームワーク  
<TABLE border="5" bordercolor="#ffffff" width="100%" style='background-color:#ffffff;color:#333333;'>

<TR>
<TD style='background-color:#d0d0f0;'>
質問１  
</TD>
<TD style='background-color:#d0d0f0;'>
質問２  
</TD>
<TD style='background-color:#d0d0f0;'>
質問３  
</TD>
<TD style='background-color:#d0d0f0;'>
質問４  
</TD>
<TD style='background-color:#ffffff;'>
　
</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;'>
Value（経済的価値）はあるか？
</TD>
<TD style='background-color:#ddddff;'>
R<hintTarget>are（希少）</hintTarget>か？
</TD>
<TD style='background-color:#ddddff;'>
Inimitable<hintTarget>（imitate、真似）しにくい</hintTarget>か？
</TD>
<TD style='background-color:#ddddff;'>
O<hintTarget>rganization（活用できる組織）</hintTarget>か？
</TD>
<TD style='background-color:#ffffff;'>
　
</TD>
</TR>

<TR>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffeeee;'>
No
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
競争劣位
</TD>
</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
競争均衡
</TD>
</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
<hintTarget>一時的</hintTarget>な競争優位
</TD>

</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
持続的な競争優位
</TD>

</TR>


</TABLE>
</div>
</hint>


  
ア  外部環境の機会を適切に捉え脅威を無力化する経営資源は、業界内において希  
少でない<hintTarget></hintTarget>ときに、企業の一時的な競争優位の源泉となる。  
  
<hint hidden='true'>**
❌希少である必要があります。
**</hint>

イ  希少で価値がある経営資源を保有する企業は、他の企業がその経営資源を別の  
経営資源で代替するコストが<hintTarget>小さい</hintTarget>場合、持続的な競争優位を確立する。  

<hint hidden='true'>**
❌代替するコストが大きい＝Inimitable　な場合、かつ、組織の方針や体制が整っている場合、持続的な競争優位を確立します。
**</hint>
  
ウ  組織内の事務作業を効率化する固有のノウハウは、業界内で希少で<hintTarget>ない</hintTarget>場合、  
企業の一時的な競争優位の源泉となる。  

<hint hidden='true'>**
❌希少でなければ、競争優位の源泉に（一時的さえ）なりません。
**</hint>
  
エ  独自に長い年月をかけて開発した価値ある経営資源を保有する企業は、その資  
源が業界内で<hintTarget>希少でない</hintTarget>とき、資源をいかす組織の方針や体制が<hintTarget>整わない</hintTarget>中でも<hintTarget>持続的</hintTarget>な競争優位を確立する。  

<hint hidden='true'>**
❌  
・希少であり  
・Inimitable<hintTarget>（imitate、真似）しにくい  
・組織の方針や体制が整っている  
の全てを満たす場合、持続的な競争優位を確立できます。
**</hint>
  
オ  予測が困難な環境変化が起きない場合は、希少で価値があり模倣が難しい経営  
資源は企業の持続的な競争優位の源泉となる。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  
        
`
        },
        {
                "mondaiId": 103290040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
日本企業は戦略的にM＆Aを活用するようになっているが、M＆Aよりも戦略  
的提携を選択する企業も多い。M＆Aには、契約成立前の準備段階と交渉段階、  
成立後の統合段階でのさまざまな留意点がある。  
日本企業のM＆Aと戦略的提携に関する記述として、最も適切なものはどれか。  
  
ア  M＆Aの準備段階では、当事者の持つ研究開発、生産、販売などの重複部分  
や競合関係の明確化が重要であり、統合段階でデューデリジェンス(due  
diligence)を開始して機能統合していく。  
  
イ  異業種のM＆Aには、基本的には、規模の経済と取引交渉力の増大というメ  
リットがあり、業績不振の立て直しはできないが、自社の必要としない資源まで  
も獲得してしまう恐れはない。  
  
ウ  企業の独立性を維持できる戦略的提携は、パートナーが提携関係を裏切る可能  
性を最小化しつつ、提携による協力から得られる恩恵を最大限享受することが主  
な目的であり、企業の評判に悪影響が起こる可能性は、戦略的提携における裏切  
りのインセンティブを抑制できない。  
  
エ  戦略的提携の目的が経済的な価値と希少性の追求にあっても、持続的な競争優  
位をもたらすとは限らないが、提携による業界内の新しいセグメントへの低コス  
トでの参入は企業間の強みを補完する試みとなりうる。  
  
オ  同業種のM＆Aには、基本的には、範囲の経済と習熟効果の実現というメリ  
ットがあり、組織文化の調整のコストは必要であるが、統合のコストはかからな  
い。  
        
`
        },
        {
                "mondaiId": 103290050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
日本企業には、社内分社化であるカンパニー制や持株会社を導入して戦略性を一  
層高めようとした企業が見られる。カンパニー制と持株会社に関する記述として、  
最も適切なものはどれか。  
  
ア  カンパニー制は、企業グループ内の個々の業態ごとに採用できるが、同一業界  
でのカンパニーごとの個別最適を許容すればカニバリゼーションの助長につなが  
りうる。  
  
イ  カンパニー制は、主要な事業の特定製品やブランドについての管理者をおき、  
その製品やブランドに関する戦略を策定し、販売活動を調整して統合する機能を  
持つ。  
  
ウ  カンパニー制は、通常、多角化戦略によって事業領域を拡大する際、不確実性  
の高い新事業を切り離して法人格を持つ別会社として制度的に独立させ、本業や  
既存事業におよぼすリスクを軽減する。  
  
エ  純粋持株会社は、株式の所有対象としている企業グループ全体の戦略策定と  
個々の事業の運営を統合して行えるメリットがあり、傘下の企業の経営戦略を標  
準化し、集中的に管理する制度である。  
  
オ  純粋持株会社は、通常、企業グループ全体の効率的な資源配分が可能となり、  
雇用形態や労働条件の設定を標準化する機能を持つ。  
        
`
        },
        {
                "mondaiId": 103290060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
  
オーナー社長が経営する企業の事業承継の方法としてMBO(management buy-out)がある。MBOに関する記述として、最も適切なものはどれか。  
  
ア  オーナー社長は、外部の投資ファンドに株式を売却して、役員を刷新して経営  
を引き継がせる。  
  
イ  オーナー社長は、勤務経験が長いベテランで役員ではない企画部長と営業課長  
に株式を売却して、経営を引き継がせる。  
  
ウ  オーナー社長は、社外の第三者に株式を売却して、役員ではない従業員に経営  
を引き継がせる。  
  
エ  財務担当役員と同僚の役員は、投資ファンドの支援を受けることなどを通じてオーナー社長から株式を買い取り経営を引き継ぐ。  
  
オ  役員ではない企画部長と営業課長は、金融機関から融資を受けてオーナー社長  
から株式を買い取り、役員と従業員を刷新して経営を引き継ぐ。  
        
`
        },
        {
                "mondaiId": 103290070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の競争戦略と持続的な競争優位に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  競争戦略の実行に不可欠な独自の経営資源を持ち、製品市場における規模の経  
済を実現できれば、代替製品の脅威は事業の収益性に影響を与えず競争優位の源  
泉となる。  
  
イ  経路依存性のある経営資源は、模倣を遅らせることで市場における競争者の脅  
威から先発者を保護する。  
  
ウ  顧客からの強い支持を受ける製品差別化は、競合他社との間の競争に勝ち抜く  
手段である以上に、他社との競争を可能な限り回避できる自社市場構築の手段と  
なる。  
  
エ  差別化した製品と標準的な製品の機能的な差が小さくなるほど、差別化した製  
品を選好する顧客の割合は低下するが、標準的な製品よりも高い価格を設定し、  
差別化した製品で高い収益性を確保しようとする場合、できるだけ多くの顧客を  
対象とすると戦略上の矛盾を生み出す。  
  
オ  スイッチング・コストの発生する状況では、買い手側は、現在使用する製品や  
サービスと他の代替的な製品・サービスと価格や機能が同じであったとしても、  
別のものとして見なす。  
        
`
        },
        {
                "mondaiId": 103290080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
規模の経済は、モノづくりをする企業にとって重要である。規模の経済を説明す  
る記述として、最も適切なものはどれか。  
  
ア  売り上げの増大をもたらすように複数の製品を組み合わせて生産するようにす  
る。  
  
イ  買い手にとって購入価値が高まれば販売数が増大するので、製品の普及度に注  
目してクリティカルマスを超えるようにマーケティング組織の規模を維持する。  
  
ウ  現有製品の特性を分析し直し、製品の構成要素の機能や性能を向上させて、新  
たな経済価値を付与した製品の生産を行う。  
  
エ  産出量の増大に伴って  
単位当たりの製品を産出する平均費用を低下させるべ  
く、一度に数多くのアウトプットを産出するようにする。  
  
オ  累積生産量を増やして単位当たりのコストを下げるようにする。  
        
`
        },
        {
                "mondaiId": 103290090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
成長をめざす中小企業にとって外部資金の獲得は欠かせない。中小企業への資金  
提供に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  中小企業へ投資する投資事業有限責任組合では、業務執行を伴わない組合員  
は、その出資額を限度として組合の債務を弁済する責任を負う。  
  
イ  中小企業へ投資する投資事業有限責任組合では、組合の業務を執行する者は有  
限責任組合員である。  
  
ウ  ベンチャーキャピタルは、株式を公開していない経営課題を抱える中小企業に  
対して、新株と引き換えに事業成長のための資金を潤沢に提供することを通じて  
中小企業の企業価値を高める。  
  
エ  ベンチャーキャピタルは、役員派遣や経営のモニタリングをすることによっ  
て、有望な中小企業に投資した資金を、新規株式公開やM＆Aを通じて回収す  
る可能性を高める。  
  
オ  ベンチャーキャピタルは、有望な中小企業に対して、本体や他のベンチャーキ  
ャピタルが運用するファンドを通じた投資と本体の自己資金を原資とした投資の  
スタイルで、中小企業の企業価値を高める。  
        
`
        },
        {
                "mondaiId": 103290100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業では、新製品開発や新規事業などのプロジェクトが円滑に進むように、さま  
ざまな方法を用いて進捗管理を行っている。そのような進捗管理に関する記述とし  
て、最も適切なものはどれか。  
  
ア  技術開発と市場開拓が並行して事業化が進行すれば、技術開発面の課題を早期  
に発見して、その解消活動が販売における課題解決に結びつくので、基礎研究成  
果を応用研究につなぐ際のダーウィンの海と呼ばれる課題の克服に有効である。  
  
イ  技術や市場が新規の製品の開発に取り組む場合、現場で培った経験や知識の活  
用が開発時間やコストを節約するキーポイントになる。  
  
ウ  新製品の事業化では、顧客や市場の評価を早期に把握して、その結果を開発活  
動にフィードバックして、場合によっては開発段階が後戻りすることを許容する  
方が新製品の迅速な立ち上げに有利に働く。  
  
エ  プロジェクトのある段階から次の段階への移行ごとにチェックポイントを設け  
るステージゲート管理では、移行可否の判断基準の設定や移行可否の権限が各段  
階に与えられないため、管理が甘くなって見込みの低いプロジェクトを温存する  
ことになりやすい。  
  
オ  プロジェクトの複数の段階の活動を同時に並行して行うと、開発の早い段階か  
らプロジェクト内で情報交換が進むが、情報の複雑性も高くなるので、開発期間  
が延びたり、開発コストが余計にかかりやすくなる。  
        
`
        },
        {
                "mondaiId": 103290110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
製品のイノベーションを起こすには、企業の内外の知識や情報を動員し、それを  
有効に活用することが重要である。イノベーションのタイプと知識の関係に関する  
記述として、最も適切なものはどれか。  
  
ア  アーキテクチャの構成要素の改善を積み重ねながら、製品を進化させるイノベ  
ーションでは、システムの複雑性に対処するための専門横断的に共有される知識  
が重要になる。  
  
イ  アーキテクチャの構成要素の組み合わせやつながり方を変えることによって生  
み出されるイノベーションでは、専門領域に固有な知識がイノベーションの機会  
を捉えるうえで重要な役割を担う。  
  
ウ  アーキテクチャの構成要素を見直して、ユーザーの価値の変化に適応した製品  
コンセプトを生み出すイノベーションでは、専門的な技術知識や経路依存的に蓄  
積される知識が有効になる。  
  
エ  アーキテクチャを変えることなく、構成要素のイノベーションを起こそうとす  
るモジュラー・イノベーションでは、その構成要素をめぐって培われた学習や経  
験などのノウハウ的な知識を用いることが有効である。  
  
オ  製品コンセプトを変えるようなラディカルなイノベーションでは、専門的な技  
術知識を持たないユーザーからの製品価値評価を用いずに、研究開発部門から生  
み出される専門知識を活用することが重要になる。  
        
`
        },
        {
                "mondaiId": 103290120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
自然災害や大事故などの突発的な不測の事態の発生に対応することは、企業にと  
って戦略的な経営課題であり、停滞のない企業活動の継続は企業の社会的責任の一  
環をなしている。そのような事態への対応に関する記述として、最も適切なものは  
どれか。  
  
ア  カフェテリア・プランは、多くの場合、ポイント制によって福利厚生メニュー  
を自主的に、また公平に選択できるようにしているので、突発的な災害などの支  
援に活用できるメニューは盛り込めない。  
  
イ  クライシス・マネジメントは、想定される危機的事象を予測し、事前にその発  
生抑止や防止策を検討して危機への対応を図ろうとするものである。  
  
ウ  コンティンジェンシー・プランでは、不測の事態や最悪の事態を想定して、そ  
の事態が与える業務間の影響を測るべく、事業インパクト分析を重視して危機対  
応の計画を策定するのが一般的な方法である。  
  
エ  事業継続計画0BCP3では、事業停止の影響度を評価分析して、業務の中断が許  
される許容期限を把握して業務の復旧優先順位を導くために事業インパクト分析  
の実施が行われる。  
  
オ  事業継続計画(BCP)は、災害時のロジスティクスの確保を重視した企業間ネッ  
トワークの構築を目指すものとして策定されている。  
        
`
        },
        {
                "mondaiId": 103290130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
世界的に展開する企業にとって、本国親会社と海外子会社との関係は重要とな  
る。グローバルな統合の必要性と現地市場への適応の必要性を軸にした多国籍企業  
の戦略に関する記述として、最も適切なものはどれか。  
  
ア  規模の経済が作用し、現地市場への適応の必要性が低い製品を提供する企業で  
は、通常、本国親会社のリーダーシップで、各国の子会社の能力を最大限に発揮  
させ現地向けの製品を開発して全体の効率性を高める。  
  
イ  グローバルな統合の必要性は低く、現地市場への適応の必要性は高い製品を提  
供する企業では、通常、全社方針のもと複数の国に共通する製品需要を吸い上げ  
て集中的に生産拠点と販売拠点を整備し製品を供給する戦略をとる。  
  
ウ  現地の習慣や文化への配慮の必要性は高く、グローバルな統合の必要性は低い  
製品を取り扱う企業では、通常、海外子会社が独自に製品開発やマーケティング  
に取り組み、現地の需要の変化に即座に対応する戦略がとられる。  
  
エ  製品開発の固定費が大きく、各国の認可と文化的理解の必要性が高い製品を取  
り扱う企業では、通常、全社方針のもと集中的に生産拠点と販売拠点を整備し製  
品を供給することで全体の生産性を高める。  
  
オ  製品開発の固定費が大きく、現地の習慣や文化への配慮の必要性が低い製品を  
取り扱う企業では、通常、国ごとに対応した製品開発、マーケティング、生産の  
戦略をとることで、現地のニーズにきめ細かく対応する。  
        
`
        },
        {
                "mondaiId": 103290140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織構造のデザインに関する記述として、最も適切なものはどれか。  
  
ア  異なったタスクを組み合わせて、顧客に提供するサービスとしてまとめる方法  
を、機能部門化という。  
  
イ  指揮命令系統は、組織のトップからロアーに至る権限の系統であるが、組織横  
断的なコミュニケーションを可能にする情報ネットワーク技術の発展によって、  
指揮命令系統は組織デザインの要素としては必須ではなくなっている。  
  
ウ  仕事を細かく分割された作業ルーティンとしてではなく、トータルなプロセス  
として任せるように割り当てることを、職務の専門化という。  
  
エ  職務の標準業務手続きの公式化が進むほど、職務の進め方に対する個人の自由  
裁量は小さくなる。  
  
オ  組織の頂点に意思決定を集中する度合いとして集権化と分権化が決められ、集  
権化するほど環境変化への対応力を高めることができ、分権化するほど迅速な組  
織的な行動が可能になる。  
        
`
        },
        {
                "mondaiId": 103290150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織における一連の活動プロセスを制御するコントロール・システムのデザイン  
に関する記述として、最も適切なものはどれか。  
  
ア  サプライヤーから原材料や設備を入手する際に、さまざまな性能やスペックを  
事前にテストするフィードフォワード・システムは、最終的なアウトプットの性  
能をあらかじめ保証できるため、一連の活動プロセスの上流にある活動の制御に  
有効である。  
  
イ  職務記述書や標準業務手続きなど、一連の活動プロセスの外部にある情報処理  
メカニズムによって活動を制御するオープンループ・システムは、成果の望まし  
さを評価するメカニズムを備えていないため、管理者は組織構造のプログラム化  
された側面を評価する必要がある。  
  
ウ  組織の外部環境にある基準によって組織における一連の活動プロセスの成果を  
評価するオープンループ・システムは、プロセス全体を制御するのに適してい  
る。  
  
エ  対象となる一連の活動プロセスのアウトプットからの情報を利用するフィード  
バック・システムは、アウトプットが出てから短時間で活動が修正可能なケース  
が相対的に多いため、上流にある活動の制御に有効である。  
  
オ  対象となる一連の活動プロセスのアウトプットからの情報を利用するフィード  
バック・システムは、提供される情報が正確に把握できるので、上流にある活動  
の制御に有効である。  
        
`
        },
        {
                "mondaiId": 103290160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
モチベーション理論に関する記述として、最も適切なものはどれか。  
  
ア  A.マズローの欲求段階説は、多様な欲求が同時に満たされることによって、  
個人のモチベーションが階層的に強まっていくことを提唱した。  
  
イ  D.マクレガーのX理論とY理論は、個人は肯定的側面と否定的側面の両面  
を併せ持つことを示し、状況に応じてモチベーションを刺激する組み合わせを変  
化させる必要性があることを提唱した。  
  
ウ  D.マクレランドの三欲求理論によれば、報酬や社会的な成功よりも個人的な  
達成感を強く求める人は、自分の能力を超えたチャレンジングな仕事を好み、他  
者と親和的な関係を結ぶリーダーになろうとする傾向を持つことを提唱した。  
  
エ  F.ハーズバーグの二要因理論では、従業員が不満足を知覚する衛生要因と、  
満足を知覚する動機づけ要因を独立した要因として捉え、必ずしも不満足を解消  
せずとも、モチベーションを高めることができることを提唱した。  
  
オ  V.ブルームの期待理論によれば、モチベーションは将来に対する合理的な計  
算として捉えられ、特定の努力によって実現される目標の期待値と、目標を実現  
することによって得られる報酬の期待値の総和として把握できることを提唱し  
た。  
        
`
        },
        {
                "mondaiId": 103290170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
個人が特定の組織に継続して参加する現象を説明する概念として、組織コミット  
メントが知られている。組織コミットメントが高まる理由として、<u>最も不適切なものはどれか</u>。  
  
ア  個人が目指す目標や価値観と組織のそれらとが一致しているため。  
  
イ  自分の専門分野や職業に対して強い心理的愛着を持っているため。  
  
ウ  組織のメンバーに対する好意を持ち、組織に参加すること自体に誇りを持って  
いるため。  
  
エ  長い時間の訓練を費やして身につけた技術や知識が他の組織に転用できないた  
め。  
  
オ  複数の企業を転々と移動するより、  
つの企業にとどまることが道徳的に正し  
いと考えるため。`
        },
        {
                "mondaiId": 103290180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
働き方の多様化とともに、個人にとっての働く意味や価値を問うキャリア概念の  
重要性が増している。D.スーパーらによるキャリアに関する命題として、<u>最も不
適切なものはどれか</u>。  
  
ア  キャリア発達とは、職業的自己概念を発達させていくプロセスである。  
  
イ  職業的自己概念は、私生活の満足やパーソナリティとは独立に構成される。  
  
ウ  職務満足は、職業的自己概念を適切に表現する場を見つける程度によって決ま  
ってくる。  
  
エ  人の職業に対する好みや能力は、時間や経験とともに変化し、職業的自己概念  
として社会的に学習されていく。  
  
オ  人はそれぞれ異なるパーソナリティを持っている一方で、職業に必要とされる  
能力とパーソナリティには独自のパターンがある。  
        
`
        },
        {
                "mondaiId": 103290190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織メンバーに共有された価値観や信念など、目に見えない基本的仮定に対処す  
るためには、具体的な組織文化の類型についての知識が必要となる。組織文化の特  
徴と管理者に求められるリーダーシップに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  安定性と予測可能性を重視するハイアラーキー文化では、信頼性の高い製品や  
サービスを提供するために、規則や手続きを遵守するリーダーシップが求められ  
る。  
  
イ  企業を親しい仲間達の集まりのようなものと見なすクラン文化では、自らの経  
営理念を組織内部に浸透させ、従業員に共有された強い価値観を作り出すため  
に、さまざまな社会化研修を行うなど教育者としての強いリーダーシップが求め  
られる。  
  
ウ  競争環境への対応を優先するマーケット文化では、規則や手続きなどの組織内  
プロセスよりも、市場シェアの向上などの結果を重視し、現実主義的なリーダー  
シップが求められる。  
  
エ  変化する環境下で直面する課題に即興的に対応するアドホクラシー文化では、イノベーションと創造性が重視され、リスクを進んで取っていこうとする企業家
的なリーダーシップが求められる。  
        
`
        },
        {
                "mondaiId": 103290200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織的知識創造プロセスとして、野中郁次郎が提唱するSECIモデルに関する記  
述として、最も適切なものはどれか。  
  
ア  SECIモデルにおける共同化ないし社会化(Socialization)とは、新入社員の研  
修活動を通じて組織文化に適応させることを意味し、組織メンバーとしての自覚  
を促すことによって社内での行動パターンを身につけさせることを促す。  
  
イ  SECIモデルにおける表出化(Externalization)とは、新製品のイメージなどが  
具体的な言葉によって新製品コンセプトとして表現されていくような、社会化を  
通じて獲得された暗黙知を形式知に転換するプロセスを意味する。  
  
ウ  SECIモデルにおける内面化(Internalization)とは、実践を繰り返すことを通  
じて、内面化された知識を他者にも伝えていくことを意味する。  
  
エ  SECIモデルにおける連結化(Combination)とは、形式知と暗黙知が組み合わ  
されることを通じて、すでに言語で表現されている既存の製品コンセプトが、新  
製品コンセプトへ転換されていくことを意味する。  
        
`
        },
        {
                "mondaiId": 103290210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織を取り巻く環境の変化が激しくなるにつれて、絶えざる組織変革が求められ  
る一方で、組織アイデンティティの重要性が認識されてきている。組織アイデンテ  
ィティに関する記述として、最も適切なものはどれか。  
  
ア  異なる利害関係者が関わる組織においては、コンフリクトなどが頻繁に発生す  
るため、組織アイデンティティは効果を発揮することができない。  
  
イ  組織アイデンティティは、業界内の自社の競争上のポジションなどを認識する  
ことを通じて確立されるもので、トップマネジメントが経営理念や組織文化に反  
映していく自社のイメージを意味する。  
  
ウ  組織アイデンティティは、組織の構成員による自己認識であるため、組織の外  
部からの影響を受けて変化する可能性が少なく、組織に強い一体感をもたらす効  
果がある。  
  
エ  組織アイデンティティは、他者から自社がどう見られているかを映し出すとと  
もに自社のイメージを他者に印象付け、組織文化に埋め込まれると同時に組織文  
化の理解を表したものとなる。  
  
オ  単一の組織アイデンティティは外部環境への適応に対する抵抗要因となるた  
め、複数の組織アイデンティティを持つことが、環境への過剰適応を生み出す可  
能性がある。  
        
`
        },
        {
                "mondaiId": 103290220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織の計画的変革にはさまざまな手法があるが、その多くの背後にはK.レヴィ  
ン0Lewin3らが主張した、解凍―変化―再凍結モデルがある。この計画的変革モデ  
ルに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  解凍の際には、新しいことを学ぶだけでなく、その人のパーソナリティや社会  
関係と一体化していることをやめることが含まれるため、変わろうというモチベ  
ーションを起こさせることが重要である。  
  
イ  解凍の際に変革に対して組織メンバーを動機づけるためには、自分たちの過去  
の失敗を認めることに対する不安や脅威を持たないよう、組織が危機に直面して  
いるという意識を持たせないよう配慮する必要がある。  
  
ウ  再凍結の過程では、新しい行動様式を身につけた人にとって重要な他者たちか  
ら、その行動や態度を認めてもらえるかどうかを試す機会を持たせる必要があ  
る。  
  
エ  再凍結の過程では、新しい役割や行動が、その人のアイデンティティにあって  
いるかどうか、パーソナリティと矛盾しないかどうかを確認する機会を持たせる  
必要がある。  
  
オ  変化の過程では、模範的な役割を演じるロールモデルや信頼のおける仲間たち  
との同一視と、そうした人々の立場から新しいことを学ぶことが必要である。  
        
`
        },
        {
                "mondaiId": 103290230,
                "name": "第23問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
契約社員やパートタイマー、派遣労働者、請負労働者など、正社員以外で組織に  
雇用される労働者は、広く非正社員と呼ばれてきたが、近年は定型的・補助的な職  
務にとどまらず、正社員と同じ責任を持って職務に従事する質的基幹化が起こって  
いる。質的基幹化に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  機密事項の漏洩が発生しやすくなっている。  
  
イ  職場の一体感が低下しやすくなっている。  
  
ウ  正社員との賃金格差に非正社員が不満を感じやすくなっている。  
  
エ  長期的な視点から見た正社員の育成が困難になっている。  
  
オ  非正社員は正社員に期待されている役割を担うことができるようになってい  
る。`
        },
        {
                "mondaiId": 103290240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働契約の締結に際しての労働基準法に基づく労働条件の明示義務に関する記述  
として、最も適切なものはどれか。  
  
ア  使用者は、期間の定めがあって満了後に更新する場合があるときは、｢労働契  
約を更新する場合の基準に関する事項｣を書面の交付によって明示しなければな  
らない。  
  
イ  使用者は、｢所定労働時間を超える労働の有無｣及び｢所定労働時間を超えて労  
働させる程度｣については、書面の交付によって明示しなければならない。  
  
ウ  使用者は、｢表彰に関する事項｣については、それに関する定めをする場合であ  
っても、そのことを明示する必要はない。  
  
エ  使用者は、労働者に適用される労働条件が規定されている部分を明らかにした  
就業規則を交付したとしても、当該事項の明示義務を果たしたことにはならな  
い。  
        
`
        },
        {
                "mondaiId": 103290250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
解雇に関する記述として、最も適切なものはどれか。  
  
ア  会社が定める試用期間中の労働者については、労働基準法第20条に定める解  
雇予告に関する規定は適用されることはない。  
  
イ  使用者は、労働者を解雇しようとする場合においては、少なくとも30日前の  
予告をしなければならないが、労働者側からする任意退職についても、就業規則  
その他に別段の定めがない場合には、少なくとも30日前の予告が必要である。  
  
ウ  日々雇い入れられる者については、その後引き続き使用されるに至った場合で  
も、労働基準法第20条に定める解雇予告に関する規定が適用されることはない。  
  
エ  労働者の責に帰すべき事由により、使用者が労働者を即時解雇する意思表示を  
し、当日所轄労働基準監督署長に解雇予告除外認定の申請をして翌日以降その認  
定を受けたときでも、その即時解雇の効力は、使用者が即時解雇の意思表示をし  
た日に発生する。`
        },
        {
                "mondaiId": 103290260,
                "name": "第26問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働基準法に基づく賃金の支払いに関する記述として、最も適切なものはどれ  
か。  
  
ア  使用者が賃金を労働者の銀行口座への振込みによって支払うためには、当該労  
働者の同意を得なければならない。  
  
イ  使用者は、年俸制で年俸額が600万円の労働者に対しては、毎月一定の期日を  
定めて月50万円ずつ賃金を支払わなければならない。  
  
ウ  賃金は、直接労働者に支払わなければならないが、未成年者の親権者または後  
見人は、その賃金を代わって受け取ることができる。  
  
エ  毎月の第4金曜日というような特定された曜日に定期賃金を支払うことを、就  
業規則で定めることができる。  
        
`
        },
        {
                "mondaiId": 103290270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働基準法に定める変形労働時間制および裁量労働制に関する記述として、最も  
適切なものはどれか。  
  
ア  使用者は、  1か月単位の変形労働時間制を採用した場合において、変形期間が  
開始した後に、労働基準監督署に届け出た労働日並びに始業及び終業の時刻と異  
なる日時に労働させた場合であっても、結果として、変形期間を平均して週40  
時間の範囲内で労働させていれば、残業代を支払う必要はない。  
  
イ  使用者は、  1年単位の変形労働時間制を採用した場合において、対象期間が開  
始した後に、労使協定で定めた労働日並びに始業及び終業の時刻と異なる日時に  
労働させた場合であっても、結果として、  1日10時間、  1週52時間の範囲内で  
労働させていれば、残業代を支払う必要はない。  
  
ウ  専門業務型裁量労働制については、適用される労働者の個別の同意を得ること  
は要件とされていないが、企画業務型裁量労働制については、適用される労働者  
の個別の同意を得なければならない。  
  
エ  フレックスタイム制は、始業及び終業の時刻の両方を労働者の決定に委ねるこ  
とを要件としておらず、始業時刻又は終業時刻の一方についてのみ労働者の決定  
に委ねるものも含まれる。  
        
`
        },
        {
                "mondaiId": 103290280,
                "name": "第28問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マーケティングにおける価格に関する記述として、最も適切なものはどれか。  
  
ア  価格には、品質のバロメーターとしての役割や、プレステージ性を顕示する機  
能も含まれている。後者に関しては威光価格の考え方があるが、これは消費者の  
価格感度を高め、需要の価格弾力性を低下させるうえで重要な手法である。  
  
イ  コモディティ化に陥っていた製品のブランドイメージをマーケティング努力に  
よって向上させることに成功すると、その製品の需要曲線は上方に移動し、数量  
プレミアム、価格プレミアム、またはその両方が発生する機会が生じる。  
  
ウ  高い市場占有率をもつ企業は、しばしば同種製品市場での競争相手となる他の  
上位企業と相談し、それぞれの製品の価格に関する意思決定を行うことがある。  
これによって市場における競争の公正性が担保され、非価格競争が促される。  
  
エ  マーケティング・コンセプトでは、顧客指向が最優先課題であるため、新製品  
の市場導入期においては利益指向を追求しないことが強調されている。  
        
`
        },
        {
                "mondaiId": 103290291,
                "name": "第29問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

高性能オーディオ製品を生産しているメーカーA社は、B地区にそれぞれ店舗  
を構える小売業のX社とY社に卸売を行っている。かつてはX社とY社の小売  
店舗はともに、丁寧な対面販売と手厚いサービスを重視していた。ここ数年の間、  
X社が従来の方向性を強化する一方、Y社は販売員を減らし、店舗への投資を削  
減して販管費を節約し、それを低価格に反映させるディスカウント戦略をとるよう  
になっていた。<u>①その結果、多くの消費者がX社の店舗で情報探索し、Y社の店舗で実際の商品の購買を行うという傾向が顕著化した</u>。  <u>②X社はY社の行動の影響による売上不振を打開するため、自らもY社と同様の戦略をとるようになっていった</u>。  

（設問!)    

文中の下線部①に示すような状況を表す語句として、最も適切なものはどれか。  
  
ア  クロスバイイング  
  
イ  ダンピング  
  
ウ  ブラウジング  
  
エ  フリーライディング  
        
`
        },
        {
                "mondaiId": 103290292,
                "name": "第29問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

高性能オーディオ製品を生産しているメーカーA社は、B地区にそれぞれ店舗  
を構える小売業のX社とY社に卸売を行っている。かつてはX社とY社の小売  
店舗はともに、丁寧な対面販売と手厚いサービスを重視していた。ここ数年の間、  
X社が従来の方向性を強化する一方、Y社は販売員を減らし、店舗への投資を削  
減して販管費を節約し、それを低価格に反映させるディスカウント戦略をとるよう  
になっていた。<u>①その結果、多くの消費者がX社の店舗で情報探索し、Y社の店舗で実際の商品の購買を行うという傾向が顕著化した</u>。  <u>②X社はY社の行動の影響による売上不振を打開するため、自らもY社と同様の戦略をとるようになっていった</u>。  

（設問２）  
文中の下線部②に示すX社およびY社の行動がメーカーA社にもたらすであ  
ろう結果として、最も適切なものはどれか。  
  
ア  A社はX社とY社に対して、自らの製品の小売段階における価格を設定  
し、それを厳守することを両社に約束させた。  
  
イ  X、Y両社による価格競争の結果、A社の製品は高い市場シェアを実現す  
るとともに、ブランドの強化を図ることができた。  
  
ウ  X社とY社が同じ商圏の中で価格競争を通じて顧客の奪い合いを行うよう  
になったため、A社は両社に対する卸売価格の引き上げに成功した。  
  
エ  X社とY社が価格競争を展開することによって、A社が長年をかけて築い  
てきたブランドイメージが毀損された。  
        
`
        },
        {
                "mondaiId": 103290301,
                "name": "第30問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

　マーケターがその活動の場として選択する市場は、<u>①ターゲット・マーケット・セグメント</u>あるいは対象市場、標的市場などと呼ばれる。どのような市場セグメントをターゲットとするかは、企業の戦略や資源・能力の多様性に関連している。また、<u>②ターゲットとする市場セグメントの選択パターンは、マーケターが対象とする製品と市場、あるいはそのいずれかの選択に依存する</u>。  

（設問１）  

文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  A社は、面や胴、小手、剣道着、はかまといった剣道用品を総合的に企  
画・生産するメーカーである。同社は、幼児・小学生、中高生、大学生・一般  
といった年齢を変数とした市場セグメントのそれぞれに適した製品群を生産し  
ている。これは、選択的専門化によるターゲティングの代表例である。  
  
イ  老舗の豆腐製造業者B社は４代にわたって、家族従業者だけで豆腐の生産  
に携わっている。豆腐の販売先は、大都市に立地する日本酒バー数店舗のみで  
ある。これは、製品専門化によるターゲティングの典型例である。  
  
ウ  タオルメーカーのＣ社は、同社のランドマーク商品である、手触りのよいハ  
ンドタオルシリーズのブランドによって、高級ホテルやレストラン、スポーツ  
ジム、贈答品専門店など幅広いターゲットに対する働きかけを行っている。こ  
れは市場専門化によるターゲティング・アプローチである。  
  
エ  ハンドメイドのスポーツ自転車を製造・小売するＤ社は、小さな製造小売事  
業所２店舗を通じて、ファッション性と堅牢度の高い製品を提供している。製  
品は洗練されたデザインを持つが、競技指向や機能性指向とは対照的な、ファ  
ッション性を求める市場セグメントがターゲットである。これは、集中による  
ターゲティングである。  
        
`
        },
        {
                "mondaiId": 103290302,
                "name": "第30問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

　マーケターがその活動の場として選択する市場は、<u>①ターゲット・マーケット・セグメント</u>あるいは対象市場、標的市場などと呼ばれる。どのような市場セグメントをターゲットとするかは、企業の戦略や資源・能力の多様性に関連している。また、<u>②ターゲットとする市場セグメントの選択パターンは、マーケターが対象とする製品と市場、あるいはそのいずれかの選択に依存する</u>。  

（設問２）  
文中の下線部②について、下表の空欄Ａ~Ｄに当てはまる語句の組み合わせと  
して、最も適切なものを下記の解答群から選べ。  

<imgTag>103290302_1.png</imgTag>  

（解答群）  
  
ア  Ａ：競争相手の顧客奪取  
　Ｂ：新製品で顧客深耕  
　Ｃ：顧客内シェアの向上  
　Ｄ：フルライン化による結合効果  
  
イ  Ａ：顧客層拡大  
　Ｂ：新製品で顧客深耕  
　Ｃ：顧客内シェアの向上  
　Ｄ：製品系列の縮小  
  
ウ  Ａ：顧客内シェアの向上  
　Ｂ：新製品で顧客深耕  
　Ｃ：既存製品の新用途開発  
　Ｄ：新製品で市場開拓  
  
エ  Ａ：新製品で顧客深耕  
　Ｂ：新・旧製品の相乗効果  
　Ｃ：顧客内シェアの向上  
　Ｄ：フルライン化による結合効果  
        
`
        },
        {
                "mondaiId": 103290311,
                "name": "第31問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

長年にわたり、羽毛布団の製造小売を行ってきたY社は、近年、拡大を続ける全国チェーンのインテリア専門店に羽毛布団の顧客を奪われてしまったため、新社長のP氏は羽毛を原材料とした新製品の開発を通じて、新たな顧客を創造するという構想を練り始めている。所有する生産設備もうまく活用する形での新製品開発に向け、P氏は中小企業診断士であるQ氏から基本的な<u>①製品開発</u>のプロセスについてアドバイスを受けている。  

その結果、いくつかの<u>②コンセプト</u>案がリストアップされた。ここから一年間を費やしてそれらからいくつかの製品を市場投入段階まで到達させることを念頭に置いて、P氏はそのための準備に取り組んでいる。P氏は、まず<u>③市場動向を把握し、競合となりうる製品・企業を特定するための作業</u>に着手している。  

（設問１）  

文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  近年では、有体の製品とそれらの使用価値を高めるための無体財を組み合わ  
せて提供し、顧客の囲い込みを図る、レッドオーシャン戦略と呼ばれるアプロ  
ーチが顕著になっている。  
  
イ  新製品を開発する際には、製品系列のラインの幅と奥行きの全体的な構成の  
バランスを保つ必要がある。そのための一連の分析をクロスセル分析という。  
  
ウ  幅が広く、奥行きも深い製品系列を有する消費財メーカーは、それを経営資  
源として活用し、流通業者から有利な取引条件を引き出せる可能性をもってい  
る。  
  
エ  マーケティング指向の立場をとる企業は、いわゆるシーズ(seeds)を出発点  
とした新製品・新サービスの開発は行わない。  
        
`
        },
        {
                "mondaiId": 103290312,
                "name": "第31問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

長年にわたり、羽毛布団の製造小売を行ってきたY社は、近年、拡大を続ける全国チェーンのインテリア専門店に羽毛布団の顧客を奪われてしまったため、新社長のP氏は羽毛を原材料とした新製品の開発を通じて、新たな顧客を創造するという構想を練り始めている。所有する生産設備もうまく活用する形での新製品開発に向け、P氏は中小企業診断士であるQ氏から基本的な<u>①製品開発</u>のプロセスについてアドバイスを受けている。  

その結果、いくつかの<u>②コンセプト</u>案がリストアップされた。ここから一年間を費やしてそれらからいくつかの製品を市場投入段階まで到達させることを念頭に置いて、P氏はそのための準備に取り組んでいる。P氏は、まず<u>③市場動向を把握し、競合となりうる製品・企業を特定するための作業</u>に着手している。  

（設問2)  
文中の下線部②に関する記述として、最も適切なものはどれか。  
  
ア  新製品のコンセプト開発においては、既存顧客や顕在顧客に対象を絞り、そ  
の中での市場セグメンテーションを行うことが重要である。  
  
イ  新製品のコンセプト策定に当たっては、組織内部の部門間での伝わりやすさ  
を第一とし、顧客を含む、企業の外部に対する分かりやすさは事後的に検討さ  
れる。  
  
ウ  製品コンセプトとは、ある製品が顧客に提供しうる価値を端的に示すもので  
ある。それによって、買い手が製品やサービスを購買したいと思うようにな  
る。  
  
エ  製品コンセプトの開発に先駆けて探索的調査が実施されることが多いが、観  
察法はその段階で有用でないとされている。`
        },
        {
                "mondaiId": 103290313,
                "name": "第31問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

長年にわたり、羽毛布団の製造小売を行ってきたY社は、近年、拡大を続ける全国チェーンのインテリア専門店に羽毛布団の顧客を奪われてしまったため、新社長のP氏は羽毛を原材料とした新製品の開発を通じて、新たな顧客を創造するという構想を練り始めている。所有する生産設備もうまく活用する形での新製品開発に向け、P氏は中小企業診断士であるQ氏から基本的な<u>①製品開発</u>のプロセスについてアドバイスを受けている。  

その結果、いくつかの<u>②コンセプト</u>案がリストアップされた。ここから一年間を費やしてそれらからいくつかの製品を市場投入段階まで到達させることを念頭に置いて、P氏はそのための準備に取り組んでいる。P氏は、まず<u>③市場動向を把握し、競合となりうる製品・企業を特定するための作業</u>に着手している。  

（設問３）  
文中の下線部③に関する記述として、最も適切なものはどれか。  
  
ア  PEST分析は、組織の外部環境を捉えるための方法である。これは、政治的  
環境、企業文化的環境、社会的環境、技術的環境という４つの側面から外部環  
境を把握することを支援する。  
  
イ  SWOT分析は、組織の内部環境の把握に限定した方法であるが、自社の強  
みと弱み、機会と脅威のそれぞれを構成する要素を整理するために有用であ  
る。  
  
ウ  相対的市場シェアとは、最大の競争相手の市場シェアで自社の市場シェアを  
割る(除する)ことで算出される数値である。この値が50％を超えていれば、  
自社はその市場のリーダー企業である。  
  
エ  有効市場とは、ある製品・サービスに対する十分な関心をもち、購買に必要  
な水準の収入を有しており、かつその製品・サービスにアクセスすることがで  
きる消費者の集合のことである。  
        
`
        },
        {
                "mondaiId": 103290320,
                "name": "第32問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章は、マーケティングにおける競争の次元について例を示したものであ  
る。文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものを下記の解  
答群から選べ。  
缶コーヒーの製品カテゴリーでは、毎年、多くの製品が投入されている。異なる  
メーカー間では、缶コーヒー製品をめぐり　Ａ　競争を繰り広げている。また  
缶コーヒーは、緑茶や紅茶、炭酸飲料とも顧客を奪いあっており、この競争は産業  
競争と呼ばれる場合がある。  
さらに、缶コーヒーは、コンビニエンスストアやファーストフードチェーンが提  
供する安価な淹  
い  
れたてコーヒーとも　Ｂ　競争をしている。また、給料日前に  
節約のため缶コーヒーを我慢して、新聞やスイーツを購入するといったケースがあ  
るように、缶コーヒーは消費支出をめぐり多くの製品と　Ｃ　競争をしてい  
る。  
（解答群）  
  
ア  Ａ：一般Ｂ：機能Ｃ：便益  
  
イ  Ａ：一般Ｂ：形態Ｃ：機能  
  
ウ  Ａ：形態Ｂ：機能Ｃ：一般  
  
エ  Ａ：ブランドＢ：一般Ｃ：便益  
  
オ  Ａ：ブランドＢ：形態Ｃ：一般  
        
`
        },
        {
                "mondaiId": 103290330,
                "name": "第33問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費者の購買意思決定に関する記述として、最も適切なものはどれか。  
  
ア  原材料や味に特徴がある多様なドレッシングが販売されている。アサエルの購  
買行動類型によれば、商品間の差を理解しやすく、低価格で特にこだわりもなく  
購入できる商品に対して、消費者は多くを検討することなく、慣習的な購買行動  
をとりやすい。  
  
イ  テレビを買い替える場合、過去の使用経験から特定ブランドに好ましい態度を  
有している消費者の多くは、他ブランドを詳しく検討することなく、当該ブラン  
ドを選ぶことがある。こうした決定方略は連結型と呼ばれる。  
  
ウ  特別な人に贈る宝石の購入は重要性の高い買い物であるが、一般の消費者は宝  
石の良し悪しを正確に判断できない。アサエルの購買行動類型によれば、こうし  
た場合に、一般の消費者は複雑な情報処理を伴う購買行動をとりやすい。  
  
エ  パソコンの購入に際して、消費者は最も重視する属性で高評価な候補製品を選  
び、その属性で候補製品が同評価であれば、次に重視する属性で選ぶ場合があ  
る。こうした決定方略は辞書編纂型と呼ばれる。  
  
オ  マンション購入に際して、消費者は価格、立地、間取り、環境や建設会社な  
ど、検討すべき属性を網羅的にあげ、候補物件において全属性を評価し、総合点  
が高い選択肢を選ぶことがある。こうした決定方略はEBA型と呼ばれる。  
        
`
        },
        {
                "mondaiId": 103290341,
                "name": "第34問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

今日の消費者は、マーケティング情報に対して知覚的過重負担に陥っている。コ  
ストに見合ったコミュニケーション効果を消費者から引き出すために、企業の担当  
者は、次の点に注意を払っている。  <u>①消費者が受け入れる情報や注意を向ける刺激</u>がどのようなものであるかを理解すること、プロモーションの構成要素を適切にミックスさせること、さらには②<u>新たなコミュニケーションツールの活用や、統合型マーケティングコミュニケーションを実現すること</u>である。  

（設問１）  

文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  消費者が説得的情報に接し、それらを処理するとき、無意識的な場合と比べ  
て意識的な場合に、情報はそのまま記憶に追加されやすい。  
  
イ  消費者は、一般的に、事前に有していた知識や態度と合致した情報を受け入  
れる傾向がある。  
  
ウ  ｢天気がよい｣といったポジティブな外部要因は消費者の好ましいムードを醸  
成するが、その結果、製品やブランドに関する説得的情報に向けられる消費者  
の注意は低下する。  
  
エ  人は接触回数が多い対象の評価や選択確率を高める。よって、マーケティン  
グ成果を高めるためには、消費者に製品や製品情報に接触したことを明確に意  
識させるよう工夫することが望ましい。  
        
`
        },
        {
                "mondaiId": 103290342,
                "name": "第34問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

今日の消費者は、マーケティング情報に対して知覚的過重負担に陥っている。コ  
ストに見合ったコミュニケーション効果を消費者から引き出すために、企業の担当  
者は、次の点に注意を払っている。  <u>①消費者が受け入れる情報や注意を向ける刺激</u>がどのようなものであるかを理解すること、プロモーションの構成要素を適切にミックスさせること、さらには②<u>新たなコミュニケーションツールの活用や、統合型マーケティングコミュニケーションを実現すること</u>である。  

（設問２）  
文中の下線部②に関する記述として、最も適切なものはどれか。  
  
ア  消費者生成型の映像コンテンツを広告コミュニケーションに活用すれば、一  
般的に、金銭的・時間的負担はかからない。  
  
イ  消費者は製品のデザインやパッケージなどから多くの情報を得て、ブランドイメージを形成する。統合型マーケティングコミュニケーションは、消費者と  
ブランドとのこうした多様な接点の統合的な管理を目指す。  
  
ウ  テレビ広告で｢詳細は翌日の新聞折り込みをご覧ください｣とメッセージを送  
る手法が増えているが、それぞれのコミュニケーションの影響力は割り引かれ  
る。  
  
エ  統合型マーケティングコミュニケーションにおいては、発信されるメッセー  
ジは、企業視点で綿密に練り上げられ、統合される。  
        
`
        },
        {
                "mondaiId": 103290350,
                "name": "第35問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
他者や他者集団が消費者行動に与える影響に関する記述として、最も適切なもの  
はどれか。  
  
ア  準拠集団とは、消費者の態度や行動の形成に影響を与える所属集団のことであ  
る。  
  
イ  消費者間ネットワークを用いて広くマーケティング情報を伝えたいと考えると  
き、消費者間の弱いつながりが重要な役割を果たす。  
  
ウ  他者の購買に影響を与えるオピニオンリーダーは、新製品をいち早く購入するイノベーターと呼ばれる人々であることが多い。  
  
エ  人の目に触れる場で使用される製品より、人の目に触れない場で使用される製  
品の方が、ブランド選択における他者集団の影響が大きい。`
        },
        {
                "mondaiId": 103290360,
                "name": "第36問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
製品やサービスに関する記述として、最も適切なものはどれか。  
  
ア  快適なドライブを楽しむことができた、子供の最高の表情を撮影できたといっ  
た消費体験を通じて、顧客は各製品に対する価値を見出す。このことを踏まえる  
と、製品やサービス自体には価値の一部しか埋め込まれていないと考えられる。  
  
イ  消費者は、製品が提供する便益やそれらがもたらす満足に消費の価値を見出  
す。ただし消費者が実際に手にするのは物的な製品自体である。そのため、企業  
は製品の提供価値よりデザイン、品質、特徴の検討を重視すべきである。  
  
ウ  ホテル業および鉄道業は、部屋あるいは列車(座席)といった有体財を顧客に提  
供しているため、これらはサービス業と言い難い。  
  
エ  マーケティング視点での製品開発におけるコスト設計では、製造コストと販売  
コストの低減がすべてにおいて優先課題となる。  
`
        },
        {
                "mondaiId": 104290010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産システムにおける評価尺度に関する記述として、最も適切なものはどれか。  
  
ア  <hintTarget>MTBF</hintTarget>は、故障した設備を運用可能状態へ修復するために必要な時間の平均  
値である。  

<hint hidden='true'>**
❌MTBF（Mean Time Between Failure）でなく、MTTR（Mean Time To Repair）に関する記述です。
**</hint>
  
イ  稼働率は、人または機械の利用可能時間を有効稼働時間で除した値である。  

<hint hidden='true'>**
❌稼働率の公式は、  
稼働率 ＝ 有効稼働時間　÷　<hintTarget>（就業時間もしくは拘束時間）  
なので、分母分子が逆です。</hintTarget>**</hint>
  
ウ  原材料生産性は、生産量を原材料の総使用量で除した値である。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  
  
エ  <hintTarget>スループット</hintTarget>は、製品を発注してから納入されるまでの時間である。

<hint hidden='true'>**
❌リードタイムに関する記述です。
**</hint>
`
        },
        {
                "mondaiId": 104290020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産システムにおけるICTの活用に関する記述として、最も適切なものはどれ  
か。  
  
ア  CAE(ComputerAidedEngineering)を導入することにより、製品開発過程の  
早い段階での事前検討が可能となり、開発期間の短縮が期待できる。  
  
イ  CAM(ComputerAidedManufacturing)を導入することにより、時々刻々変化  
する生産現場の状況をリアルタイムで把握することが可能となり、納期変更や設  
計変更などへの対応が容易になる。  
  
ウ  PDM(ProductDataManagement)を導入することにより、メーカーとサプライヤーが在庫データを共有することができ、実需に同期した精度の高い予測に基  
づく生産が可能になる。  
  
エ  POP(PointofProduction)を導入することにより、タイムバケットに対して計  
画が作成され、調達・製造すべき品目とその量、各オーダーの着手・完了時期の  
必然性を明確にすることが可能となる。  
        
`
        },
        {
                "mondaiId": 104290030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品開発・製品設計に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  下流工程での問題を可能な限り上流で防止し、短い設計納期を実現するため  
に、バリューエンジニアリングを取り入れることが有効である。  
  
イ  試作品製作は製品開発プロセスの中でも重要な位置を占めており、試作時の製  
作方法や加工条件から、量産時の工程編成における重要な情報を得ることが可能  
である。  
  
ウ  新製品の設計段階でデザインレビューを活用する際には、設計構造の矛盾や誤  
りを排除することに重点がおかれるため、設計の熟練者がレビューアとなること  
が有効である。  
  
エ  モジュール設計の考え方を取り入れると、生産工程の合理化・簡素化が期待で  
きるが、設計に問題が発生した場合にその影響が大きいというデメリットもあ  
る。`
        },
        {
                "mondaiId": 104290040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
モジュール生産方式に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  あらかじめ複数種類の部品を組み立てておき、注文を受けてからそれらの組み  
合わせによって多品種の最終製品を生産することが可能となる。  
  
イ  外部のサプライヤーに対してモジュール単位で発注を行えば、サプライヤーの  
数を絞ることが可能となるため、管理の負担を軽減することが期待できる。  
  
ウ  組立工程で扱う部品点数が削減され、組立工程が短くなり注文を受けてから納  
品するまでのリードタイム短縮が期待できる。  
  
エ  製造設備の使用日程・資材の使用予定などにオーダーを割り付け、顧客が要求  
する納期通りに生産する方式で、平準化生産など製造効率の良い生産が可能とな  
る。  
        
`
        },
        {
                "mondaiId": 104290050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工場レイアウトに関する次の文中の空欄A~Cに入る語句の組み合わせとして、  
最も適切なものを下記の解答群から選べ。  
工場レイアウトとはあらゆる施設におけるＡの配置問題である。  
代表的なレイアウト技法としてMutherによって開発されたSLPがある。SLP  
では、Ｂ・面積・調整のつの基本的重要項目が提起され、それらを段階  
的に精査することでレイアウト案が作成される。SLPは直感的でわかりやすい反  
面、主観的評価に依存しているという課題がある。  
他方、コンピュータを活用したレイアウトのためのヒューリスティック技法が開  
発されている。そこでは評価関数として、一般的にＣが用いられている。  
（解答群）  
  
ア  Ａ：機能Ｂ：アクティビティＣ：加重総移動距離  
  
イ  Ａ：機能Ｂ：相互関係Ｃ：加重総移動距離  
  
ウ  Ａ：設備Ｂ：アクティビティＣ：スループット  
  
エ  Ａ：設備Ｂ：相互関係Ｃ：スループット  
        
`
        },
        {
                "mondaiId": 104290060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表は、ある職場で製品Aに関する工程分析を行った結果から得られた各工程分析記号の出現回数を示している。この分析結果に関するａ~ｃの記述の正誤の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　加工の割合は約53.6％である。  
ｂ　数量検査の出現回数は１である。  
ｃ　滞留の出現回数は３である。  


<imgTag>104290060_1.png</imgTag>  


（解答群）  
  
ア  ａ：正ｂ：正ｃ：誤  
  
イ  ａ：正ｂ：誤ｃ：正  
  
ウ  ａ：誤ｂ：正ｃ：誤  
  
エ  ａ：誤ｂ：誤ｃ：正  
        
`
        },
        {
                "mondaiId": 104290070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表は、ある職場で加工に用いられている機械Ａについてワークサンプリングを  
行った結果を示している。主作業以外の作業を改善対象として抽出するため、対象  
となる作業のパレート分析を行った。80％を超えない範囲でできるだけ多くの作  
業を改善対象とするとき、その作業の数として、最も適切なものを下記の解答群か  
ら選べ。  

(作業)観測回数  
(機械加工)1，320  
加工部材の着脱作業)251  
(段取替え)205  
(着脱作業待ち)189  
(段取替え待ち)155  
(加工部材待ち)124  
(故障)76  

合計2，320  


（解答群）  
  
ア  3
  
イ  4  
  
ウ  5  
  
エ  6  
        
`
        },
        {
                "mondaiId": 104290080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
自動生産および生産情報管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  開発・製造・販売などの情報ネットワークを統合化するために、FAを導入す  
ることにした。  
  
イ  複数の工作機械や搬送機器などを集中制御するために、DNCを導入すること  
にした。  
  
ウ  部材加工や、工具管理、工場内搬送などを自動化したいので、FMSを導入す  
ることにした。  
  
エ  部材の取り付け・取り外し、工具の選択・交換などを行う作業者を削減したい  
ので、MCを導入することにした。`
        },
        {
                "mondaiId": 104290090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
かんばん方式に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  かんばんは、あらかじめ定められた工程間、職場間で循環的に用いられる。  
  
イ  かんばん方式を導入することにより、平準化生産が達成される。  
  
ウ  仕掛けかんばんには、品名、品番、工程名、生産指示量、完成品置場名などが  
記載される。  
  
エ  引取かんばんのかんばん枚数によって、工程間における部材の総保有数を調整  
することができる。  
        
`
        },
        {
                "mondaiId": 104290100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
標準時間に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  PTS法ではレイティングを行う必要はない。  
  
イ  内掛け法では、正味時間に対する余裕時間の割合で余裕率を考える。  
  
ウ  主体作業時間は、正味時間と余裕時間を合わせたものである。  
  
エ  人的余裕は、用達余裕と疲労余裕に分けられる。`
        },
        {
                "mondaiId": 104290110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
購買・外注管理に関する次の業務のうち、調達に関わる量産開始前の業務とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  在庫管理  
  
イ  調達先の選定  
  
ウ  デザイン・イン  
  
エ  内外製区分の決定`
        },
        {
                "mondaiId": 104290120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
在庫を評価するための尺度に関する記述として、最も適切なものはどれか。  
  
ア  在庫回転率は、標準在庫量を使用実績量で除したものである。  
  
イ  在庫月数は、月間使用量を平均在庫量で除したものである。  
  
ウ  品切れ率は、品切れ件数を受注件数で除したものである。  
  
エ  納期遵守率は、納期遅延件数を受注件数で除したものである。  
        
`
        },
        {
                "mondaiId": 104290130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
工場内でのマテリアルハンドリングに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  運搬活性示数は、置かれている物品を運び出すために必要となる取り扱いの手  
間の数を示している。  
  
イ  運搬管理の改善には、レイアウトの改善、運搬方法の改善、運搬制度の改善が  
ある。  
  
ウ  運搬工程分析では、モノの運搬活動を｢移動｣と｢取り扱い｣の2つの観点から分  
析する。  
  
エ  平均活性示数は、停滞工程の活性示数の合計を停滞工程数で除した値として求  
められる。  
        
`
        },
        {
                "mondaiId": 104290140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
標準時間を用いた作業改善のPDCAサイクルにおける各要素とその内容の組み  
合わせとして、最も適切なものを下記の解答群から選べ。  

＜各要素の内容＞  
①標準時間の順守を徹底するとともに、生産の合理化に向けて作業改善を行う。  
②実際の作業時間と標準時間の差異を確認し、その原因を追求する。  
③対象となる作業の標準作業を設定して、標準時間を算定する。  
④実際に作業を実施して、その作業時間を測定する。  

（解答群）  
  
ア  Ｐ：②Ｄ：①Ｃ：③Ａ：④  
  
イ  Ｐ：②Ｄ：④Ｃ：③Ａ：①  
  
ウ  Ｐ：③Ｄ：①Ｃ：④Ａ：②  
  
エ  Ｐ：③Ｄ：④Ｃ：②Ａ：①  
        
`
        },
        {
                "mondaiId": 104290150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の4つの手法で分析した結果から改善案を検討する際に、｢ECRSの原則｣が利  
用できる手法の数として、最も適切なものを下記の解答群から選べ。  

＜分析手法＞  
①ABC分析  
②連合作業分析  
③事務工程分析  
④流動数分析  

（解答群）  
  
ア  1  
  
イ  2  
  
ウ  3  
  
エ  4  
        
`
        },
        {
                "mondaiId": 104290160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある作業者が第1作業として穴あけ作業、第2作業として曲げ作業を行う金属加  
工工程において、時間分析とワークサンプリングを実施した。時間分析は正味時間  
を計測する目的で行われ、下表はその結果を示している。また、ワークサンプリン  
グは余裕率を算定する目的で行われ、延べ500回の計測の中で余裕に該当するサン  
プルが50個得られた。  

この工程で1個の部品を製造するための標準時間(分/個)として、最も適切なも  
のを下記の解答群から選べ。  

表時間分析の結果  

<imgTag>104290160_1.png</imgTag>

（解答群）  
  
ア  2.80  
  
イ  2.97  
  
ウ  3.00  
  
エ  3.08  
        
`
        },
        {
                "mondaiId": 104290170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
シューハート管理図に関する記述として、最も適切なものはどれか。  
  
ア  解析用管理図は、過去の生産実績から得られた標準値を利用して、工程を管理  
状態に保持する際に利用される。  
  
イ  管理用管理図は、既に集められた観測値によって、工程が統計的管理状態にあ  
るかどうかを評価する際に利用される。  
  
ウ  群の大きさが一定の状況下で、サンプルに生起した不適合数を用いて工程を評  
価する場合には、c管理図を利用する。  
  
エ  工程能力指数は、規定された公差を工程能力で除した値として求められ、その  
値が小さい程、工程能力が高いことを示している。  
        
`
        },
        {
                "mondaiId": 104290180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
基準サイクルタイムが2分/個に設定されている加工機械について、1，000時間  
の負荷時間内での設備データを収集したところ下記が得られた。この機械の設備総  
合効率の値として、最も適切なものを下記の解答群から選べ。  

[設備データの内容]値  
[稼働時間]800時間  
[加工数量(不適合品を含む)]18，000個  
[不適合品率]20％  

（解答群）  
  
ア  0.48  
  
イ  0.50  
  
ウ  0.52  
  
エ  0.54  
        
`
        },
        {
                "mondaiId": 104290191,
                "name": "第19問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
食材の加工・販売を行う食品会社において、ある食材の経済的発注量を検討することを考える。当日に納入された食材は、注文に応じて販売分だけを加工して客に提供される。食材は翌日以降に持ち越して販売することはできない。食材の仕入れ単価は80円/個、加工単価は40円/個、加工食材の販売単価は460円/個である。  

また、販売できずに売れ残った食材は、飼料会社によって20円/個で買い取られていく。  

以下の設問に答えよ。  

（設問1)  
この食品会社において、｢食材を需要量よりも1個多く発注したときの売れ残  
り損失｣と｢食材を需要量よりも1個少なく発注したときの品切れ損失｣の組み合  
わせとして、最も適切なものはどれか。ただし、品切れが発生したときの信用損  
失は考慮しないものとする。  
  
ア  売れ残り損失：60円　品切れ損失：340円  
  
イ  売れ残り損失：60円　品切れ損失：460円  
  
ウ  売れ残り損失：100円　品切れ損失：340円  
  
エ  売れ残り損失：100円　品切れ損失：460円  
        
`
        },
        {
                "mondaiId": 104290192,
                "name": "第19問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
食材の加工・販売を行う食品会社において、ある食材の経済的発注量を検討することを考える。当日に納入された食材は、注文に応じて販売分だけを加工して客に提供される。食材は翌日以降に持ち越して販売することはできない。食材の仕入れ単価は80円/個、加工単価は40円/個、加工食材の販売単価は460円/個である。  

また、販売できずに売れ残った食材は、飼料会社によって20円/個で買い取られていく。  

以下の設問に答えよ。  

（設問２)  
食材の過去100日の需要量の分布を調べたところ、表１のようなデータが得ら  
れた。表２は、この需要分布のもとで、食材の１日当たりの発注量を変化させたと  
きの平均損失額を計算したものである(一部は空欄になっているので注意するこ  
と)。表２を利用して１日当たりの平均損失額を最小化する発注量を求めること  
を考えるとき、最適発注量の値として最も適切なものを下記の解答群から選べ。  

表１　過去100日の需要量の分布  
<imgTag>104290192_1.png</imgTag>

表２　各発注量のもとでの平均損失額  
<imgTag>104290192_2.png</imgTag>


（解答群）  
  
ア  49個  
  
イ  50個  
  
ウ  51個  
  
エ  52個  
        
`
        },
        {
                "mondaiId": 104290200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
生産現場で行われる改善施策に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  機械設備の稼働状況を可視化するために、｢あんどん｣を設置した。  
  
イ  ｢シングル段取｣の実現を目指して、内段取の一部を外段取に変更した。  
  
ウ  品種変更に伴う段取り替えの回数を抑制するために、製品の流れを｢１個流し｣  
に変更した。  
  
エ  部品の組み付け忘れを防止するために、部品の供給棚に｢ポカヨケ｣の改善を施  
した。`
        },
        {
                "mondaiId": 104290210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
3Dプリンターに関する記述として、最も適切なものはどれか。  
  
ア  次元の形状データから、立体物の断面図情報を作り出して造形が行われる。  
  
イ  造形する際の積層ピッチを大きくすることにより、より高精細な造形が可能に  
なる。  
  
ウ  同一の製品を安価かつ短時間に大量生産することができる。  
  
エ  モンキレンチのウオームギアのように、複数個の部品が組み合わされた製品は  
造形できない。  
        
`
        },
        {
                "mondaiId": 104290220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業庁｢平成27年度商店街実態調査報告書｣から確認できる記述として、最  
も適切なものはどれか。  
  
ア  最近年間に商店主が退店\u0007廃業\u0013した理由として最も回答が多いものは｢大型  
店の進出｣である。  
  
イ  最近年間の商店街への来街者数の変化について、｢減った｣と回答した商店街  
の割合は平成24年度調査よりも増加している。  
  
ウ  商店街の最近の景況について、｢衰退している｣と回答した商店街の割合は平成  
24年度調査よりも増加している。  
  
エ  商店街の全体の平均店舗数は平成24年度調査よりも増加している。  
        
`
        },
        {
                "mondaiId": 104290230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
都市計画法に関する次の文中の空欄Ａ~Ｄに入る語句の組み合わせとして、最も  
適切なものを下記の解答群から選べ。  

都市計画区域は、自然的、社会的条件や人口、土地利用、交通量などの現況およ  
び推移を勘案して、一体の都市として総合的に整備、開発および保全する必要があ  
る区域であり、　Ａ　が指定するものである。都市計画区域において、無秩序  
な市街化を防止し計画的な市街化を図るために市街化区域と市街化調整区域との区  
分を定めることをＢという。  

Ｃとは都市計画法により、都市の環境保全や利便性の向上を目的とし  
て、ある地域における建物の用途に一定の制限を行う地域のことである。例えば、  
床面積が１万m2を超える店舗の出店が可能な地域は、原則として近隣商業地域、商業地域、　Ｄ　の地域である。  

（解答群）  
  
ア  Ａ：市町村　Ｂ：区域区分　Ｃ：用途制限地域　Ｄ：準工業地域  
  
イ  Ａ：市町村　Ｂ：区分設定　Ｃ：用途地域　Ｄ：工業地域  
  
ウ  Ａ：都道府県　Ｂ：区域区分　Ｃ：用途制限地域　Ｄ：準工業地域  
  
エ  Ａ：都道府県　Ｂ：区域区分　Ｃ：用途地域　Ｄ：準工業地域  
  
オ  Ａ：都道府県　Ｂ：区分設定　Ｃ：用途制限地域　Ｄ：工業地域  
        
`
        },
        {
                "mondaiId": 104290240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
1970年代、1980年代および1990年代における地域商業に関連する流通政策を説  
明する以下のａ~ｃの記述について、古いものから新しいものへと並べた順番とし  
て、最も適切なものを下記の解答群から選べ。  

ａ流通政策の中にまちづくりの視点を導入し、｢コミュニティ・マート構想｣の積  
極的な推進を打ち出した。  
ｂ地域商業近代化の必要性を指摘し、商業近代化地域計画の充実・強化を求め  
た。  
ｃ｢ハイマート2000構想｣が示され、それまでの商店街組織を対象とした政策か  
ら商業集積を対象とした政策へと踏み出した。  

（解答群）  
  
ア  ａ→ｂ→ｃ  
  
イ  ａ→ｃ→ｂ  
  
ウ  ｂ→ａ→ｃ  
  
エ  ｂ→ｃ→ａ  
  
オ  ｃ→ａ→ｂ  
        
`
        },
        {
                "mondaiId": 104290250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
わが国のショッピングセンター(SC)の現況について、一般社団法人日本ショッ  
ピングセンター協会が公表している｢全国のSC数・概況｣(2016年末時点で営業中  
のSC)から確認できる記述として、最も適切なものはどれか。  

なお、立地については、以下のように定義されている。  

中心地域：人口15万人以上の都市(東京23区を含む162都市)で、商業機能が集積した中心市街地  
周辺地域：上記中心地域以外の全ての地域  
  
ア  １SC当たりの平均店舗面積は約50，000m2である。  
  
イ  2016年にオープンしたSCの立地は、周辺地域よりも中心地域の方が多い。  
  
ウ  キーテナント別SC数では、１核SCの割合が最も高い。  
  
エ  新規オープン１SC当たりの平均テナント数は、2001年以降年単位で一貫して  
増加している。`
        },
        {
                "mondaiId": 104290260,
                "name": "第26問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
大規模小売店舗立地法に関する記述として、最も適切なものはどれか。  
  
ア  大規模小売店舗の設置者が配慮すべき基本的な事項の１つは、地域商業の需給  
調整である。  
  
イ  大規模小売店舗立地法が適用対象とする小売業には、飲食店が含まれる。  
  
ウ  大規模小売店舗立地法が適用対象とする小売店舗は、敷地面積が1，000m2を  
超えるものである。  
  
エ  大規模小売店舗立地法の施行に伴い、地域商業の活性化を図ることを目的とし  
て大規模小売店舗法の規制が緩和された。  
  
オ  都道府県は大規模小売店舗の設置者が正当な理由がなく勧告に従わない場合、  
その旨を公表することができる。  
        
`
        },
        {
                "mondaiId": 104290270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
店舗Ｘにおける商品カテゴリー別の売上高と粗利高を示した次の表を見て、この  
店舗における今後の販売計画を検討する際の考え方に関する記述として最も適切な  
ものを、下記の解答群から選べ。  
ただし、値引きや廃棄ロスを考慮せず、商品カテゴリーごとの粗利益率は変動し  
ないものとする。  

<imgTag>104290270_1.png</imgTag>


（解答群）  
  
ア  カテゴリーｂの売上高が表の数値の２倍になり、他のカテゴリーの売上高が  
変わらない場合、カテゴリーｂの相乗積はカテゴリーａより高くなる。  
  
イ  カテゴリーｃの売上高が表の数値の２倍になり、他のカテゴリーの売上高が  
変わらない場合、カテゴリーｃの相乗積は２倍になる。  
  
ウ  カテゴリーｅの売上高が表の数値の２倍になり、他のカテゴリーの売上高が  
変わらない場合、店舗全体の粗利益率は高まる。  
  
エ  すべてのカテゴリーの売上高が表の数値からそれぞれ10％ずつ増えた場合、  
相乗積がもっとも増加するのはカテゴリーｃである。  
  
オ  すべてのカテゴリーの売上高が表の数値からそれぞれ100万円ずつ増えた場  
合、カテゴリーそれぞれの相乗積は変わらない。  
        
`
        },
        {
                "mondaiId": 104290280,
                "name": "第28問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある売場において、商品を300万円で仕入れ、10日間ですべての商品を販売す  
ることを計画している。この売場で、２人の従業員が毎日それぞれ５時間ずつ労働  
し、売上高が500万円であった場合、この期間の人時生産性として、最も適切なも  
のはどれか。  
  
ア  1万円  
  
イ  2万円  
  
ウ  3万円  
  
エ  4万円  
  
オ  5万円`
        },
        {
                "mondaiId": 104290290,
                "name": "第29問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
セルフサービス店舗のフロアレイアウトにおけるワンウェイコントロールに関す  
る記述として、最も適切なものはどれか。  
  
ア  売れ筋商品を見やすい位置に陳列して、買上率を高めること。  
  
イ  買物客の売場回遊を促進して、商品との接点を増やすこと。  
  
ウ  商品の陳列スペースを最適化して、店員の商品補充頻度を減らすこと。  
  
エ  商品を買物しやすい順に配置して、買物客の店内動線長を最短にすること。  
  
オ  レジ前の売場に低価格商品を陳列して、衝動購買を促進すること。  
        
`
        },
        {
                "mondaiId": 104290300,
                "name": "第30問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
売場や商品を演出する色彩に関する次の文中の空欄ＡとＢに入る語句として、最  
も適切なものの組み合わせを下記の解答群から選べ。  
色には、見やすさに大きく影響するいくつかの性質がある。注意を向けている人  
に遠くからでも見つけやすく、周囲から際立って見えるような色や配色を  
｢Ａが高い｣という。また、見つけた対象物の形や細部が認めやすく、意味  
や情報が細かく判別できるような色や配色を｢Ｂが高い｣という。  

（解答群）  
  
ア  Ａ：視認性Ｂ：識別性  
  
イ  Ａ：視認性Ｂ：明視性  
  
ウ  Ａ：誘目性Ｂ：識別性  
  
エ  Ａ：誘目性Ｂ：明視性`
        },
        {
                "mondaiId": 104290310,
                "name": "第31問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売業の商品政策・価格政策に関する記述として、最も適切なものはどれか。  
  
ア  EDLP政策では、CRMを強化するなど店舗のサービス水準を高めることが必  
要である。  
  
イ  小売業が自ら企画し、外部に生産を委託したプライベート・ブランド商品を中  
心とした品揃えは、他店との差別化に有効であるが粗利益率を低下させる。  
  
ウ  小規模な店舗で狭い商圏の顧客を囲い込むためには、特定の商品カテゴリーで  
奥行きの深い品揃えを追求する。  
  
エ  ハイ・ロープライシング政策で、来店促進のために利益が出ないほど安く販売  
する目玉商品をロスリーダーという。  
        
`
        },
        {
                "mondaiId": 104290320,
                "name": "第32問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
不当景品類及び不当表示防止法(景品表示法)では一般消費者の利益を保護するた  
めに、店舗で販売促進を実施する際に遵守しなければならない事項が定められてい  
る。例えば、商品の購入者全員に景品類を提供することを総付景品といい、その限  
度額が定められている。この限度額に関する記述として、最も適切なものはどれ  
か。  
  
ア  取引価額が1，000円以上の場合、景品類の最高額は取引価額の10分の1であ  
る。  
  
イ  取引価額が1，000円未満の場合、景品類の最高額は200円である。  
  
ウ  取引価額が5，000円以上の場合、景品類の最高額は10万円である。  
  
エ  取引価額が5，000円未満の場合、景品類の最高額は200円である。  
  
オ  取引価額が5，000円未満の場合、景品類の最高額は取引価額の2％である。`
        },
        {
                "mondaiId": 104290330,
                "name": "第33問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店舗における在庫管理に関する記述として、最も適切なものはどれか。  
  
ア  経済的発注量とは、在庫量を最小にする1回当たりの発注量のことである。  
  
イ  サイクル在庫は、定量発注方式の場合に発生し、定期発注方式の場合には発生  
しない。  
  
ウ  定量発注方式を採用した場合、発注点は調達期間中の推定需要量と安全在庫の  
和として求められる。  
  
エ  補充点は、最大在庫量から調達期間中の推定需要量を差し引いた値である。  
  
オ  見越在庫とは、発注済みであるがまだ手元にない在庫のことである。  
        
`
        },
        {
                "mondaiId": 104290340,
                "name": "第34問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
需要予測に関する記述として、最も適切なものはどれか。  
  
ア  移動平均法は、過去の一定期間の実績値の平均に過去の変動要因を加えて予測  
する方法である。  
  
イ  季節変動とは、3か月を周期とする変動のことである。  
  
ウ  指数平滑法は、当期の実績値と当期の予測値を加重平均して次期の予測値を算  
出する方法である。  
  
エ  重回帰分析では、説明変数間の相関が高いほど良い数式(モデル)であると評価  
できる。`
        },
        {
                "mondaiId": 104290350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
国内の輸送手段に関する記述として、最も適切なものはどれか。  
  
ア  鉄道輸送では、パレットを利用することができず、一貫パレチゼーションを阻  
害する。  
  
イ  鉄道輸送は、常温での輸送であり、冷蔵・冷凍など温度管理が必要な荷物を輸  
送できない。  
  
ウ  トラック輸送からのモーダルシフトとは、貨物輸送を鉄道や内航海運などへ転  
換し、トラックと連携して複合一貫輸送を推進することである。  
  
エ  トラック輸送からのモーダルシフトは、単独荷主の貸切便で行われ、複数荷主  
の混載便では行われない。  
        
`
        },
        {
                "mondaiId": 104290360,
                "name": "第36問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流におけるユニットロードに関する記述として、最も適切なものはどれか。  
  
ア  折畳み容器とは、物品を収納しないときには折り畳んでその容積を縮小できる  
容器のことであり、ネスティング形容器に分類される。  
  
イ  通い容器とは、一定の企業または事業所などの間で繰り返し使用される輸送用  
容器のことである。  
  
ウ  パレチゼーションとは、物品をパレットに積み、パレット単位で物流を行うこ  
とであり、輸送量の増加を促す手段である。  
  
エ  物流クレートは、小売業各社、メーカー各社が自社専用のものを使用してお  
り、各社が共同利用できる標準化されたクレートは開発されていない。`
        },
        {
                "mondaiId": 104290370,
                "name": "第37問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
チェーン小売業の物流に関する記述として、最も適切なものはどれか。  
  
ア  TC(TransferCenter)は、入荷した商品を保管することを主な機能としてお  
り、店舗からの注文に応じて商品をピッキングして、仕分けし、出荷する。  
  
イ  多頻度小口配送は、車両積載効率を低下させ、店舗の荷受回数を減少させる。  
  
ウ  物流センターの運営は、商品の売買に関する取引関係がある仕入先の卸売業に  
は委託することができない。  
  
エ  物流センターを利用すると、仕入先からの納品に対する店舗の荷受作業を集約  
することができる。  
  
オ  流通加工は、物流センターでは行われず、各店舗で全て行われる。  
        
`
        },
        {
                "mondaiId": 104290380,
                "name": "第38問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センターの運営に関する記述として、最も適切なものはどれか。  
  
ア  PL(ThirdPartyLogistics)とは、荷主の物流業務を代行するサービスのこと  
であり、発荷主と着荷主との関係で第三者に当たることからこのように呼ばれ  
る。  
  
イ  ABC分析は、多くの在庫品目を取り扱うとき、在庫品目をその取扱数量の多  
い順や単価の高い順に並べて区分し、在庫管理の重点を決めるのに用いる。  
  
ウ  シングルピッキングは、注文伝票ごとにピッキングすることであり、一般的に  
種まき方法で行われる。  
  
エ  有効在庫とは、現時点で利用可能な手持在庫のことである。  
  
オ  ロケーション管理とは、どの商品が、どの保管場所にあるかを管理する保管方  
式のことであり、保管場所を特定の場所に決めておく方式と入庫の都度自由に決  
定する方式がある。`
        },
        {
                "mondaiId": 104290390,
                "name": "第39問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある売上の事象に対するＸとＹという2つの評価データがあるとき、この2つの  
評価データの相関係数に関する記述として、最も適切なものはどれか。ただし、Ｘ  
またはＹが、すべて同じ値の場合は除く。  
  
ア  相関係数が0.1であれば、サンプル数に関係なく5％の有意水準では有意にな  
らない。  
  
イ  相関係数は、－１００~１００の範囲の値として計算される。  
  
ウ  両者の評価が同じ方向に強く類似している場合、相関係数は必ず正の値にな  
る。  
  
エ  両者の評価に関連性がない場合、相関係数は必ず負の値になる。  
        
`
        },
        {
                "mondaiId": 104290401,
                "name": "第40問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

あるスーパーマーケットの、ある期間に購買のあった顧客1，000人分のID-POS  
データを用いて、顧客が当該期間内に購入する商品の組み合わせを分析した。その  
結果、商品Ａの購入者が200人、商品Ｂの購入者が250人、商品Ａと商品Ｂの両方  
の購入者が100人であった。  

（設問1）
｢商品Ａを購入した当該顧客の何パーセントが商品Ｂを購入するか｣という値  
を、商品Ｂのプロモーションを検討する材料として計算したい。このときこの値  
は、一般に何と呼ばれる値か、最も適切なものを選べ。  
  
ア  Jaccard係数  
  
イ  支持度(サポート)  
  
ウ  信頼度(コンフィデンス)  
  
エ  正答率  
  
オ  リフト値`
        },
        {
                "mondaiId": 104290402,
                "name": "第40問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

あるスーパーマーケットの、ある期間に購買のあった顧客1，000人分のID-POS  
データを用いて、顧客が当該期間内に購入する商品の組み合わせを分析した。その  
結果、商品Ａの購入者が200人、商品Ｂの購入者が250人、商品Ａと商品Ｂの両方  
の購入者が100人であった。  

（設問2）設問1の｢商品Ａを購入した当該顧客の何パーセントが商品Ｂを購入するか｣と  
いう値を実際に計算したとき、最も適切な値はどれか。  
  
ア  15％  
  
イ  20％  
  
ウ  25％  
  
エ  40％  
  
オ  50％  
        
`
        },
        {
                "mondaiId": 104290410,
                "name": "第41問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売業を取り巻く電子商取引の方式を整備・標準化し、製・配・販層一連での  
業務効率の向上を図るため、流通システム標準普及推進協議会が定めている｢流通  
ビジネスメッセージ標準運用ガイドライン(基本編)第1.3.3版(2014年10月)｣  
に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  GLNとは、企業間取引において企業や事務所などを識別するために、国際流  
通標準機関であるGS1が定めた、グローバルでユニークなコードのことである。  
  
イ  GLNは、企業コード＋ロケーションコード＋チェックデジットから構成され  
る。  
  
ウ  GTINとは、商品またはサービスを国際的に識別する番号であり、国際標準の  
商品識別コードの総称である。  
  
エ  GTINには、JANコード、UPCコードの2種類のみ存在する。  
  
オ  不定貫商品とは、発注上、単価庵個数で値段を算出できない商品で、実際の値  
段は、単価×重量で算出しなければならない。  
        
`
        },
        {
                "mondaiId": 104290420,
                "name": "第42問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
厚生労働省がHACCP導入のため平成27年10月に公表している手引書による  
HACCPに関する基本的な用語とその説明の組み合わせとして、最も適切なものを  
下記の解答群から選べ。  

【用語】  
１　PRP  

<hint hidden='true'>**
一般衛生管理（PRP）の決定
→　ａ　HACCPシステムを効果的に機能させるための前提となる食品取扱施設の衛生管理プログラム 
**</hint>    

２　SSOP  

<hint hidden='true'>**
衛生標準作業手順（SSOP）の決定
→　ｂ　衛生管理に関する手順のことで、その内容を｢いつ、どこで、だれが、何を、 どのようにするか｣がわかるように文書化したもの   
**</hint>    

３　CCP

<hint hidden='true'>**
重要管理点（CCP）の決定
→　ｃ　特に厳重に管理する必要があり、かつ、危害の発生を防止するために中の危害要因を予防もしくは除去、または、それを許容できるレベルに低減するために必須な段階
**</hint>    

４　CL  

<hint hidden='true'>**
管理基準（CL）の設定  
→　aからdに該当なし。  
**</hint>    

【用語の説明】
ａ　HACCPシステムを効果的に機能させるための前提となる食品取扱施設の衛生管理プログラム  

ｂ　衛生管理に関する手順のことで、その内容を｢いつ、どこで、だれが、何を、 どのようにするか｣がわかるように文書化したもの  

ｃ　特に厳重に管理する必要があり、かつ、危害の発生を防止するために中の危害要因を予防もしくは除去、または、それを許容できるレベルに低減するために必須な段階  

ｄ　HACCPプランに従って実施されているかどうか、HACCPプランに修正が必要かどうかを判定するために行う方法、手続き、試験検査  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】HACCPのの１２手順  
<img src="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/images/haccptejun.png"/>
<a href="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/haccpgimuka.html" target="_blank">  
(外部リンクを開きます　千葉市：HACCP(ハサップ)に沿った衛生管理)</a>  
</div>
</hint>

（解答群）  
  
ア  １とｂ  
  
イ  ２とａ  
  
ウ  ３とｃ<hint hidden='true'>**
←⭕
**</hint>    
  
エ  ４とｃ  
  
オ  ４とｄ  
        
`
        },
        {
                "mondaiId": 104290430,
                "name": "第43問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある企業が同じ商品を５つの異なるWEBサイトで、それぞれバナー広告A~E  
を掲載している。このとき、ある１か月で下表のような結果が得られたとする。こ  
こでクリック単価はユニークな顧客がクリックしたときに発生する金額、クリック  
顧客数は各広告をクリックしたユニークな顧客数、広告費は当該広告をクリックさ  
れたことによって生じた総広告費用、商品購買客数は当該広告によって商品購買に  
至ったユニークな顧客数とする。  

コンバージョンレート(CVR)によってバナー広告を評価するとき、最も効率的  
なバナー広告はどれか。下記の解答群から選べ。  

<imgTag>104290430_1.png</imgTag>

バナー広告  
クリック単価  
(円)  
クリック顧客数  
(人)  
広告費  
(円)  
商品購買客数  
(人)  
Ａ10060060，0007  
Ｂ5020010，0004  
Ｃ25010025，0001  
Ｄ20025050，0004  
Ｅ15015022，5004  
（解答群）  
  
ア  Ａ  
  
イ  Ｂ  
  
ウ  Ｃ  
  
エ  Ｄ  
  
オ  Ｅ  
`
        },
        {
                "mondaiId": 105290010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株式の併合又は株式の分割に関する記述として、最も適切なものはどれか。  
  
ア  株式の併合によって発行済株式総数は増加し、株式の分割によって発行済株式  
総数は減少する。  
  
イ  株式の併合又は株式の分割があっても、資本金額は変動しない。  
  
ウ  株式の併合を行う場合には取締役会の決議で足りるが、株式の分割を行う場合  
には株主総会の特別決議が必要である。  
  
エ  株式無償割当てにより、株式の分割の目的を達成することはできない。  
        
`
        },
        {
                "mondaiId": 105290020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたとＸ株式会社（以下｢Ｘ社｣という。）  
の代表取締役甲氏との間で行われたものである。甲氏は、Ｘ社の発行済株式の全て  
を保有している。会話の中の空欄Ａ~Ｃに入る記述の組み合わせとして、最も適切  
なものを下記の解答群から選べ。  

甲氏：｢会社分割の手続を利用して、当社のα事業を、Ｙ株式会社（以下｢Ｙ社｣  
という。）に売却しようと考えているのですが、債権者異議手続の対象とな  
る債権者の範囲を教えてください。まず、吸収分割によりα事業に係る  
権利義務をＹ社に直接承継させ、その対価としてＸ社がＹ社から現金を受  
け取る場合にはどうなりますか。｣  

あなた：｢売却ということで、Ｘ社は、分割後、α事業に対する支配権を手放すと  
いうことでしょうから、分割契約において、Ｙ社に承継させる債務に係る  
債権者は、もうＸ社に債務の履行を請求できないと定めることになります  
よね。そうすると、（　Ａ　）が債権者異議手続の対象になります。｣  

甲氏：｢では、新設分割によりα事業に係る権利義務を新たに設立したＺ株式会  
社（以下｢Ｚ社｣という。）に承継させた上で、Ｚ社の株式をＹ社に譲渡する  
場合にはどうなりますか。｣

あなた：｢Ｚ社の株式の譲渡の対価をＸ社が受け取りたい場合には、新設分割と同  
時にＺ社の株式をＸ社が保有する物的分割になります。また、分割計画に  
おいて、Ｚ社に承継させる債務に係る債権者は、やはり、もうＸ社に債務  
の履行を請求できないと定めることになりますよね。そうすると、  
（　Ｂ　）が債権者異議手続の対象になります。  
他方、Ｚ社の株式の譲渡の対価を甲さんが個人で受け取りたい場合に  
は、新設分割と同時にＺ社の株式を甲さん個人が保有する人的分割になる  
でしょう。その場合には、（　Ｃ　）が債権者異議手続の対象になりま  
す。  
事業の売却ということであれば、いろいろな専門家のアドバイスも必要  
になってくると思いますし、よい方を紹介しますから、一緒に相談に行っ  
てみませんか。｣  
        
        
（解答群）  
  
ア  Ａ：Ｙ社に承継させる債務に係る債権者と分割の効力発生日前からＹ社の債  
権者であった者  
Ｂ：Ｚ社に承継させる債務に係る債権者  
Ｃ：Ｚ社に承継させる債務に係る債権者だけでなく、Ｚ社に承継されない債  
務に係る債権者  
  
イ  Ａ：Ｙ社に承継させる債務に係る債権者と分割の効力発生日前からＹ社の債  
権者であった者  
Ｂ：Ｚ社に承継させる債務に係る債権者だけでなく、Ｚ社に承継されない債  
務に係る債権者  
Ｃ：Ｚ社に承継させる債務に係る債権者  
  
ウ  Ａ：Ｙ社に承継されない債務に係る債権者とＹ社に承継させる債務に係る債  
権者と分割の効力発生日前からＹ社の債権者であった者  
Ｂ：Ｚ社に承継させる債務に係る債権者  
Ｃ：Ｚ社に承継させる債務に係る債権者だけでなく、Ｚ社に承継されない債  
務に係る債権者  
  
エ  Ａ：Ｙ社に承継されない債務に係る債権者とＹ社に承継させる債務に係る債  
権者と分割の効力発生日前からＹ社の債権者であった者  
Ｂ：Ｚ社に承継させる債務に係る債権者だけでなく、Ｚ社に承継されない債  
務に係る債権者  
Ｃ：Ｚ社に承継させる債務に係る債権者  
        
`
        },
        {
                "mondaiId": 105290031,
                "name": "第3問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたと、上場を目指しているベンチャー  
企業であるＸ株式会社（以下｢Ｘ社｣という。）の代表取締役甲氏との間で行われたも  
のである。この会話を読んで、下記の設問に答えよ。なお、Ｘ社の定款には特段の  
定めがないものとする。  

甲氏：｢優秀な人材が会社に定着してくれなくて困っています。何かよい方法は  
ないですか。｣  

あなた：｢御社は上場を目指していましたよね。ストック・オプションを従業員に  
発行するのはどうでしょうか。｣  

甲氏：｢どういうことですか。｣  

あなた：｢会社法では、新株予約権と呼ばれているものなのですが、会社に対して  
行使することにより株式の交付を受けることができる権利のことをいいま  
す。｣  

甲氏：｢それをどう使うのですか。｣  

あなた：｢まず、言葉の意味について説明しますね。新株予約権の付与を受けた時  
点で付与を受けた者が会社に払う金額をq払込金額sといい、その後新株予  
約権を行使して株式の交付を受ける時点で新株予約権者が会社に払う金額  
をq行使価額sといいます。また、新株予約権者が新株予約権を行使できる  
期間をq行使期間sといい、新株予約権者が新株予約権を行使する際に満た  
していなければならない条件をq行使条件sといいます。｣  

甲氏：｢それで？｣  

あなた：｢そこで、例えば、新株予約権の内容を  

・（　Ａ　）を無償とすること  
・（　Ｂ　）について、現在のＸ社の株価と一致させるか、又は現在のＸ社の株価より（　Ｃ　）すること  
・行使期間を、新株予約権の付与を受けた日後２年経過した日以降とすること  
・新株予約権行使時までＸ社の役員又は従業員の地位を維持していることを行使条件とすること  

にすれば、御社の業績を今よりも向上させようという気持ちを従業員に持  
たせることができると思います。｣  

甲氏：｢なるほど、その仕組みなら少なくとも\u0003年間は定着して頑張ってくれそ  
うですね。従業員にストック・オプションを付与するに当たって注意しな  
ければならないことはありますか。｣  

あなた：｢（　Ｄ　）。専門家の協力を得ないまま、ストック・オプションを発行  
することは難しいと思います。詳しい方を紹介しますから、一緒に相談に  
行ってみませんか。｣  

甲氏：｢ぜひお願いします。｣  

（設問１）  
会話の中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  Ａ：行使価額　Ｂ：払込金額　Ｃ：高く  
  
イ  Ａ：行使価額　Ｂ：払込金額　Ｃ：安く  
  
ウ  Ａ：払込金額　Ｂ：行使価額　Ｃ：高く  
  
エ  Ａ：払込金額　Ｂ：行使価額　Ｃ：安く`
        },
        {
                "mondaiId": 105290032,
                "name": "第3問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `                
以下の会話は、中小企業診断士であるあなたと、上場を目指しているベンチャー  
企業であるＸ株式会社（以下｢Ｘ社｣という。）の代表取締役甲氏との間で行われたも  
のである。この会話を読んで、下記の設問に答えよ。なお、Ｘ社の定款には特段の  
定めがないものとする。  

甲氏：｢優秀な人材が会社に定着してくれなくて困っています。何かよい方法は  
ないですか。｣  

あなた：｢御社は上場を目指していましたよね。ストック・オプションを従業員に  
発行するのはどうでしょうか。｣  

甲氏：｢どういうことですか。｣  

あなた：｢会社法では、新株予約権と呼ばれているものなのですが、会社に対して  
行使することにより株式の交付を受けることができる権利のことをいいま  
す。｣  

甲氏：｢それをどう使うのですか。｣  

あなた：｢まず、言葉の意味について説明しますね。新株予約権の付与を受けた時  
点で付与を受けた者が会社に払う金額をq払込金額sといい、その後新株予  
約権を行使して株式の交付を受ける時点で新株予約権者が会社に払う金額  
をq行使価額sといいます。また、新株予約権者が新株予約権を行使できる  
期間をq行使期間sといい、新株予約権者が新株予約権を行使する際に満た  
していなければならない条件をq行使条件sといいます。｣  

甲氏：｢それで？｣  

あなた：｢そこで、例えば、新株予約権の内容を  

・（　Ａ　）を無償とすること  
・（　Ｂ　）について、現在のＸ社の株価と一致させるか、又は現在のＸ社の株価より（　Ｃ　）すること  
・行使期間を、新株予約権の付与を受けた日後２年経過した日以降とすること  
・新株予約権行使時までＸ社の役員又は従業員の地位を維持していることを行使条件とすること  

にすれば、御社の業績を今よりも向上させようという気持ちを従業員に持  
たせることができると思います。｣  

甲氏：｢なるほど、その仕組みなら少なくとも\u0003年間は定着して頑張ってくれそ  
うですね。従業員にストック・オプションを付与するに当たって注意しな  
ければならないことはありますか。｣  

あなた：｢（　Ｄ　）。専門家の協力を得ないまま、ストック・オプションを発行  
することは難しいと思います。詳しい方を紹介しますから、一緒に相談に  
行ってみませんか。｣  

甲氏：｢ぜひお願いします。｣  

（設問２）  
会話の中の空欄Ｄに入る記述として、最も適切なものはどれか。  
  
ア  株価が値下がりした場合のリスクを従業員に負わせることになります  
  
イ  株主総会において、議決権を行使することができる株主の半数以上であっ  
て、当該株主の議決権の３分の２以上に当たる多数をもって、募集事項を決定  
する必要があります  
  
ウ  従業員だけでなく、社外のコンサルタント等にもストック・オプションの取  
得を勧誘する場合には、有価証券届出書の提出が義務付けられることがありま  
す  
  
エ  租税特別措置法に定める要件を満たしていない場合、株式売却時に売却価格  
と行使価額の差額部分について譲渡所得として課税されてしまいます  
        
`
        },
        {
                "mondaiId": 105290041,
                "name": "第4問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたと、新株発行による資金調達を行お  
うとしているＸ株式会社の代表取締役甲氏との間で行われたものである。この会話  
を読んで、下記の設問に答えよ。  

甲氏：｢新株発行により3，000万円を調達しようと考えています。株式の発行に  
際して払い込まれた金額は、資本金か資本準備金かのどちらかに計上しな  
ければならないと聞きましたが、具体的にいくらにすればいいのでしょう  
か。｣  

あなた：｢会社法上の規定により、3，000万円のうち、少なくとも（　Ａ　）円は、  
資本金として計上しなければならないので、残りの（　Ｂ　）円について  
いくらを資本金にするのかが問題になります。資本金の額が許認可の要件  
となっている事業を行う場合などを除き、<u>一般的には資本金に計上する金  
額を少なくした方が有利なことが多いように思います</u>。｣  

甲氏：｢資本金を増やす特別な理由がないのであれば、資本金に計上する金額は  
少なくした方がいいみたいですね。今回は、（　Ｂ　）円を資本準備金の  
金額としておきます。｣  

（設問1）3  
会話の中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  Ａ：1　Ｂ：2，999万9，999  
  
イ  Ａ：300万　Ｂ：2，700万  
  
ウ  Ａ：1，000万　Ｂ：2，000万  
  
エ  Ａ：1，500万　Ｂ：1，500万  
        
`
        },
        {
                "mondaiId": 105290042,
                "name": "第4問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたと、新株発行による資金調達を行お  
うとしているＸ株式会社の代表取締役甲氏との間で行われたものである。この会話  
を読んで、下記の設問に答えよ。  

甲氏：｢新株発行により3，000万円を調達しようと考えています。株式の発行に  
際して払い込まれた金額は、資本金か資本準備金かのどちらかに計上しな  
ければならないと聞きましたが、具体的にいくらにすればいいのでしょう  
か。｣  

あなた：｢会社法上の規定により、3，000万円のうち、少なくとも（　Ａ　）円は、  
資本金として計上しなければならないので、残りの（　Ｂ　）円について  
いくらを資本金にするのかが問題になります。資本金の額が許認可の要件  
となっている事業を行う場合などを除き、<u>一般的には資本金に計上する金  
額を少なくした方が有利なことが多いように思います</u>。｣  

甲氏：｢資本金を増やす特別な理由がないのであれば、資本金に計上する金額は  
少なくした方がいいみたいですね。今回は、（　Ｂ　）円を資本準備金の  
金額としておきます。｣  

（設問２）  
会話の中の下線部に関連し、最も適切なものはどれか。  
  
ア  資本金の金額によって、監査役設置会社において会計監査人を設置しなけれ  
ばならないかどうかが影響を受けることはない。  
  
イ  資本金の金額によって、個人情報の保護に関する法律に定める個人情報取扱  
事業者に該当するかどうかが影響を受けることはない。  
  
ウ  資本金の金額によって、下請代金支払遅延等防止法によって保護される下請  
事業者に該当するかどうかが影響を受けることはない。  
  
エ  増える資本金の額が多くなっても、資本金の額の変更に係る登記に要する登  
録免許税の金額が増えることはない。`
        },
        {
                "mondaiId": 105290050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業における経営の承継の円滑化に関する法律に定められた遺留分に関する  
民法の特例に関する記述として、最も適切なものはどれか。  
  
ア  旧代表者の推定相続人が除外合意や固定合意の当事者となった場合において、  
当該推定相続人の代襲者には、除外合意や固定合意の効力は及ばない。  
  
イ  旧代表者の推定相続人でない者は、除外合意や固定合意の当事者となることは  
できない。  
  
ウ  旧代表者の推定相続人の中に除外合意や固定合意の当事者となっていない者が  
いても、これらの合意は有効に成立する。  
  
エ  除外合意や固定合意の当事者の代襲者が旧代表者の養子となった場合には、除  
外合意や固定合意は効力を失う。  
        
`
        },
        {
                "mondaiId": 105290061,
                "name": "第6問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたとＸ株式会社の代表取締役甲氏との  
間で行われたものである。この会話を読んで、下記の設問に答えよ。  

甲氏：｢ある会社が有している知的財産権、具体的には、特許権、特許を受ける  
権利、商標権、著作権の譲渡を受けたいと考えているのですが、分割移転  
は可能でしょうか。｣  
あなた：｢分割移転について、まず、特許権を請求項ごとに分割して移転すること  
は（　Ａ　）。次に、商標権を指定商品又は指定役務ごとに分割して移転  
することは（　Ｂ　）。｣  
甲氏：｢知的財産権を移転するためには、登録が必要だと聞いたことがあるので  
すが、その手続はどうすればいいのでしょうか。｣  
あなた：｢特許権、特許を受ける権利、商標権の移転登録は特許庁が、著作権の移  
転登録は文化庁が扱っています。もっとも、それらの中には、移転登録が  
効力発生要件となっているものと、対抗要件となっているものがあります  
ので、注意が必要です。具体的には、（　Ｃ　）については効力発生要件  
となっています。｣  

（設問１）  
会話の中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  Ａ：できます　Ｂ：できます  
  
イ  Ａ：できます　Ｂ：できません  
  
ウ  Ａ：できません　Ｂ：できます  
  
エ  Ａ：できません　Ｂ：できません  
        
`
        },
        {
                "mondaiId": 105290062,
                "name": "第6問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
甲氏：｢ある会社が有している知的財産権、具体的には、特許権、特許を受ける  
権利、商標権、著作権の譲渡を受けたいと考えているのですが、分割移転  
は可能でしょうか。｣  
あなた：｢分割移転について、まず、特許権を請求項ごとに分割して移転すること  
は（　Ａ　）。次に、商標権を指定商品又は指定役務ごとに分割して移転  
することは（　Ｂ　）。｣  
甲氏：｢知的財産権を移転するためには、登録が必要だと聞いたことがあるので  
すが、その手続はどうすればいいのでしょうか。｣  
あなた：｢特許権、特許を受ける権利、商標権の移転登録は特許庁が、著作権の移  
転登録は文化庁が扱っています。もっとも、それらの中には、移転登録が  
効力発生要件となっているものと、対抗要件となっているものがあります  
ので、注意が必要です。具体的には、（　Ｃ　）については効力発生要件  
となっています。｣  

（設問２）  
会話の中の空欄Ｃに入る語句として、最も適切なものはどれか。  
  
ア  特許権及び商標権  
  
イ  特許権、特許出願後の特許を受ける権利及び商標権  
  
ウ  特許権、特許出願前の特許を受ける権利及び商標権  
  
エ  特許権、特許出願前の特許を受ける権利、特許出願後の特許を受ける権利及  
び商標権`
        },
        {
                "mondaiId": 105290070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
産業財産権の存続期間に関する記述として、最も適切なものはどれか。  
  
ア  意匠権(関連意匠の意匠権を除く)の存続期間は、設定登録の日から20年であ  
る。  
  
イ  実用新案権の存続期間は、設定登録の日から15年である。  
  
ウ  商標権の存続期間は、設定登録の日から10年であり、以後、1年ごとに更新  
することが可能である。  
  
エ  特許権の存続期間は、設定登録の日から20年である。  
        
`
        },
        {
                "mondaiId": 105290080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
損害賠償請求を行う際に、請求者が侵害者の過失を立証しなくてもそれが推定さ  
れる行為又は権利として、最も適切なものはどれか。  
  
ア  営業秘密使用行為  
  
イ  実用新案権  
  
ウ  商標権  
  
エ  著作権`
        },
        {
                "mondaiId": 105290090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたとＸ株式会社の代表取締役甲氏との  
間で行われたものである。  
会話の中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

あなた：｢意匠制度には特殊な出願制度があります。下図は（　Ａ　）意匠制度と、  
（　Ｂ　）意匠制度について、全体の意匠出願中の利用率を示した特許庁  
の統計です。｣  
甲氏：｢それぞれ、どのような制度なのかな。｣  
あなた：｢（　Ａ　）意匠制度は図面の一部に破線を用いるなどして余分な限定を  
排除する制度で、（　Ｂ　）意匠制度は互いに類似する複数の意匠を重ね  
て登録することでより広い権利範囲を特定する制度です。いずれも、より  
強い意匠権を獲得できるメリットがあります。｣  
甲氏：｢（　Ａ　）意匠制度は利用率が40％にも達していて、人気があるようだ  
ね。次回、意匠登録出願をする場合は、検討してみよう。｣  
        
     
（　Ａ　）意匠、（　Ｂ　）意匠の出願件数及び出願件数割合の推移  
<imgTag>105290090_1.png</imgTag>  
特許行政年次報告書2016年度版図1-1-66から作成  


（解答群）  
  
ア  Ａ：秘密　Ｂ：関連  
  
イ  Ａ：秘密　Ｂ：組物の  
  
ウ  Ａ：部分　Ｂ：関連  
  
エ  Ａ：部分　Ｂ：組物の  
        
`
        },
        {
                "mondaiId": 105290100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたとＸ株式会社の代表取締役甲氏との  
間で行われたものである。  
会話の中の空欄に入る語句として、最も適切なものを下記の解答群から選べ。  

甲氏：｢立体商標というものがあると聞きました。うちの商品の容器の形状は他  
社とは違う独特の形をしていますから、登録を受けられると思うのです  
が。｣  
あなた：｢通常の商標は、識別性を有していれば登録される可能性があり、これは  

通常の立体商標も同じです。しかし、商標法はq商品の形状\u0005包装の形状を  
含む3を普通に用いられる方法で表示する標章のみからなる商標sについて  
は、原則として登録を認めないと規定しているので、商品の容器の形状自  
体を立体商標として登録するのはハードルが高いようです。｣  

甲氏：｢しかし、ある清涼飲料水や乳酸菌飲料の容器の形状は立体商標として登  
録されている、と新聞で読みましたよ。｣  

あなた：｢そのようですね。（　　　）と認められれば、例外的に登録が認められ  
るようです。おつきあいのある弁理士に相談してみたらどうでしょう。｣  

甲氏：｢なるほど。それでは、早速担当部署に対応を取らせましょう。｣  

（解答群）  
  
ア  容器の形状等が創作性を有し、需要者が何人かの業務に係る商品であると認  
識できるに至った  
  
イ  容器の形状等が創作性を有し、同業者が何人かの業務に係る商品であると認  
識できるに至った  
  
ウ  容器を使用した結果、需要者が何人かの業務に係る商品であると認識できる  
に至った  
  
エ  容器を使用した結果、同業者が何人かの業務に係る商品であると認識できる  
に至った  
        
`
        },
        {
                "mondaiId": 105290110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたとＸ株式会社の代表取締役甲氏との  
間で行われたものである。  
会話の中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

甲氏：｢漫画家の乙先生に依頼して、企業キャラクターを作ってもらう予定で  
す。｣  

あなた：｢著作権の処理は適切に行いましたか。｣  

甲氏：｢報酬を払うのですから、当然、著作権はうちのものでしょう。｣  
あなた：｢いいえ、乙先生はそれをライセンス料と思っているかもしれませんよ。  
キャラクターの絵柄について、その著作者である乙先生は、著作権法上  
（　Ａ　）権と（　Ｂ　）権の2つの権利を有しますから、それぞれの処  
理が必要になります。｣  

甲氏：｢知りませんでした。｣  

あなた：｢（　Ａ　）権は契約によって譲り受けることができます。一方、  
（　Ｂ　）権は譲り受けられませんから、異なった権利処理をする必要が  
あります。注意してください。｣  

甲氏：｢ありがとうございます。早速、顧問弁護士に相談するようにします。｣  

（解答群）  
  
ア  Ａ：著作Ｂ：著作者人格  
  
イ  Ａ：著作Ｂ：著作隣接  
  
ウ  Ａ：著作者人格Ｂ：著作  
  
エ  Ａ：著作者人格Ｂ：著作隣接  
        
`
        },
        {
                "mondaiId": 105290120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
自然人である小説の著作者が1970年6月1日に死亡していた場合、その著作権の保護期間の満了日として、最も適切なものはどれか。ただし、旧著作権法の適用及び戦時加算は考慮しないものとする。  
  
ア  2020年6月1日  
  
イ  2020年12月31日  
  
ウ  2021年6月1日  
  
エ  2021年12月31日`
        },
        {
                "mondaiId": 105290130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
不正競争に関する記述として、最も適切なものはどれか。  
  
ア  他人の商品等表示として需要者の間に広く認識されているものであっても、自  
己の氏名を使用する行為は不正競争になることはない。  
  
イ  他人の商品等表示として需要者の間に広く認識されているものであっても、需  
要者の間に広く認識される前からその商品等表示と同一の商品等表示を使用する  
者がその商品等表示を使用する行為は不正競争になることはない。  
  
ウ  日本国内において最初に販売された日から起算して三年を経過した商品につい  
て、その商品の形態を模倣した商品を譲渡する行為は不正競争になることはな  
い。  
  
エ  不正の手段により取得した技術上の秘密を使用する行為に対する差止請求権が  
時効によって消滅した後に当該使用行為に基づいて生じた物の譲渡行為は不正競  
争になることはない。  
        
`
        },
        {
                "mondaiId": 105290140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
行為能力に関する記述として、最も適切なものはどれか。  
  
ア  制限行為能力者が、自らが制限行為能力者であることを告げずに契約を締結し  
たことのみをもって、当該制限行為能力者は当該行為を取り消すことができなく  
なる。  
  
イ  被保佐人と契約をする場合には、その保佐人を代理人として締結しなければな  
らない。  
  
ウ  不動産業を営むことを許された未成年者が、その営業に関して不動産を売却す  
る場合は、法定代理人の同意を得る必要はない。  
  
エ  未成年者が債権者との間で当該未成年者の債務を免除する契約を締結するに  
は、法定代理人の同意を得なければならない。  
        
`
        },
        {
                "mondaiId": 105290151,
                "name": "第15問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたとＸ株式会社の代表取締役甲氏との  
間で行われたものである。この会話を読んで、下記の設問に答えよ。  

甲氏：｢α国に本社のあるβ会社との間で、当社で製造している機械を継続的に  
販売するために、英文の基本売買契約書を締結することになりました。相  
手方から次のような契約書案が届いたのですが、この条項は、何を定めて  
いるのでしょうか。｣  

If any ofthe following events occurs on either party，the other party may  
terminate this Agreement by giving a written notice thereof:  

(a)if either party fails to perform any provision of this Agreement or any  
other agreements between the parties，which failure remains  
uncorrected for more than eighty days after receipt of a written  
notice specifying the default;  

(b)ife ither party files a petition or has a petition filed against it by any  
person for bankruptcy or corporate reorganization;  

(c)if either party disposes of the whole or any substantial part of its  
undertaking or its assets;  

(d)if control of either party is acquired by any person or group not in  
control at the date of this Agreement.  

あなた：｢これは、解除条項を定めています。具体的には、（　Ａ　）場合に、契  
約を解除することができることになっています。｣  

甲氏：｢債務不履行に基づく解除については、どうなっていますか。｣  

あなた：｢（　Ｂ　）｣  
        
（設問１）  
会話の中の空欄Ａに入る語句として、<u>最も不適切なものはどれか</u>。  
  
ア  事業譲渡があった  
  
イ  支配権の移転があった  
  
ウ  破産の申立てがあった  
  
エ  罰金刑が科された`
        },
        {
                "mondaiId": 105290152,
                "name": "第15問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の会話は、中小企業診断士であるあなたとＸ株式会社の代表取締役甲氏との  
間で行われたものである。この会話を読んで、下記の設問に答えよ。  

甲氏：｢α国に本社のあるβ会社との間で、当社で製造している機械を継続的に  
販売するために、英文の基本売買契約書を締結することになりました。相  
手方から次のような契約書案が届いたのですが、この条項は、何を定めて  
いるのでしょうか。｣  

If any ofthe following events occurs on either party，the other party may  
terminate this Agreement by giving a written notice thereof:  

(a)if either party fails to perform any provision of this Agreement or any  
other agreements between the parties，which failure remains  
uncorrected for more than eighty days after receipt of a written  
notice specifying the default;  

(b)ife ither party files a petition or has a petition filed against it by any  
person for bankruptcy or corporate reorganization;  

(c)if either party disposes of the whole or any substantial part of its  
undertaking or its assets;  

(d)if control of either party is acquired by any person or group not in  
control at the date of this Agreement.  

あなた：｢これは、解除条項を定めています。具体的には、（　Ａ　）場合に、契  
約を解除することができることになっています。｣  

甲氏：｢債務不履行に基づく解除については、どうなっていますか。｣  

あなた：｢（　Ｂ　）｣  
        
（設問2)  
会話の中の空欄Ｂに入る記述として、最も適切なものはどれか。  
  
ア  β会社との間の他の契約にβ会社の債務不履行があった場合でも、Ｘ株式会  
社は本契約を解除できません。  
  
イ  β会社に債務不履行があった場合、β会社は本契約を解除できます。  
  
ウ  契約条項違反があった場合、本契約を即時に解除できます。  
  
エ  債務不履行の内容は書面で通知する必要があります。  
        
`
        },
        {
                "mondaiId": 105290160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
Ａ株式会社(以下｢Ａ社｣という。)とＢ株式会社(以下｢Ｂ社｣という。)との間の民事  
留置権又は商事留置権に関する記述として、最も適切なものはどれか。  
  
ア  Ａ社がＢ社に売却した機械αの代金が、弁済期の到来にもかかわらず支払わ  
れていない状況で、Ｂ社がＣに機械αを売却した場合、Ａ社が機械αを引き渡  
さず占有しているとしても、機械αは債務者であるＢ社の所有物ではなくなっ  
たことから、Ａ社は機械αについて留置権を主張することができない。  
  
イ  Ａ社がＢ社に売却した部品αの代金が、弁済期の到来にもかかわらず支払わ  
れていない場合に、Ａ社がＢ社に部品αを引き渡したとしても、Ａ社は部品α  
について留置権を主張することができる。  
  
ウ  Ａ社がＢ社に売却した不動産αの代金が、弁済期の到来にもかかわらず支払  
われていない場合でも、Ａ社がＢ社に不動産αの登記を移転してしまうと、Ａ  
社は不動産αについて留置権を第三者に対抗できない。  
  
エ  店舗で販売するために小売業者であるＢ社が卸売業者であるＡ社から購入した  
商品αの代金が、弁済期の到来にもかかわらず支払われていない場合に、Ａ社  
がＢ社から売買代金を受領し、引き渡すだけの状態にある商品βについて、Ａ  
社は留置権を主張することができる。  
        
`
        },
        {
                "mondaiId": 105290170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消滅時効に関する記述として、最も適切なものはどれか。  
  
ア  主債務者が時効の利益を放棄した場合でも、その保証人は時効を援用すること  
ができる。  
  
イ  時効の完成後に債務を承認したとしても、時効完成の事実を知らなかった場合  
には、時効を援用することができる。  
  
ウ  内容証明郵便による請求をすれば時効の完成が6か月猶予されることになり、  
当該6か月が経過する直前に再度内容証明郵便による請求をすれば、さらに時効  
の完成が6か月猶予される。  
  
エ  平成29年1月15日に機械を売却し、その代金の弁済期を平成29年2月28日  
とした場合、代金債権の時効は平成29年1月15日から進行する。`
        },
        {
                "mondaiId": 105290180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
製造物責任に関する記述として、最も適切なものはどれか。  
  
ア  魚の塩焼きは、製造物責任法に定める製造物に該当しない。  
  
イ  製造物にその製造業者と誤認させるような氏名の表示をしただけの者は、製造  
物責任法上の責任を負わない。  
  
ウ  被害者が損害又は賠償義務者を知らないまま、製造業者が製造物を引き渡した  
時から1年を経過したときは、当該製造業者に対する損害賠償請求はできなくな  
る。  
  
エ  不動産は、製造物責任法に定める製造物に該当しない。  
        
`
        },
        {
                "mondaiId": 105290190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費者契約法に関する記述として、最も適切なものはどれか。  
  
ア  個人事業主が、Ａ株式会社から、ミネラルウォーターを自宅で飲むために購入  
した場合、当該契約に消費者契約法は適用される。  
  
イ  事業者が消費者の代理人に対し、重要事項について事実と異なることを告げた  
ことにより、当該代理人が、告げられた内容が事実であるとの誤認をし、それに  
より契約を締結した場合において、当該代理人が事業者に該当するときは、消費  
者契約法は適用されない。  
  
ウ  住居の賃貸借契約において、事業者である賃貸人の重過失に起因する債務不履  
行により賃借人に生じた損害を賠償する責任の一部を免除する条項は有効であ  
る。  
  
エ  洗濯機の売買契約において、事業者である売主の軽過失に起因する債務不履行  
により消費者である買主に生じた損害を賠償する責任の全部を免除する条項は有  
効である。`
        },
        {
                "mondaiId": 105290200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
不当景品類及び不当表示防止法\u0005以下｢景品表示法｣という。3に基づいて課される  
課徴金に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  課徴金対象行為を行った事業者のうち、最初に課徴金対象行為に該当する事実  
を内閣総理大臣に報告(自己申告)した者に対しては、課徴金の納付は命じられな  
い。  
  
イ  課徴金対象行為をやめた日から5年を経過すれば、当該課徴金対象行為につい  
て課徴金の納付を命じられることはない。  
  
ウ  景品表示法に定める手続に従って返金措置を実施した場合において、その返金  
した金額が課徴金の額以上の場合には、課徴金納付命令を受けることはない。  
  
エ  結果として表示が優良誤認表示や有利誤認表示に該当していた場合でも、表示  
を行った期間を通じて、相当な注意を払った上で該当することを知らなかった者  
であれば、課徴金納付命令を受けることはない。  
        
`
        },
        {
                "mondaiId": 105290210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、日本取引所グループが上場市場の種類ごとに上場審査基準をまとめた表  
の一部を抜粋したものである。空欄Ａ~Ｃに入る数値の組み合わせとして、最も適  
切なものを下記の解答群から選べ。なお、表中の｢－｣とは、その項目に相当する基  
準が存在しないことを意味する。  

<imgTag>105290210_1.png</imgTag>  

（解答群）  
  
ア  Ａ：25　Ｂ：20　Ｃ：1  
  
イ  Ａ：25　Ｂ：20　Ｃ：3  
  
ウ  Ａ：30　Ｂ：25　Ｃ：1  
  
エ  Ａ：30　Ｂ：25　Ｃ：3  
`
        },
        {
                "mondaiId": 106290010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
パーソナルコンピュータ(PC)内部には、バスやインタフェースと呼ばれる伝送  
経路がある。その機能改善によりスループットの向上が期待できるので、PC の導  
入に当たっては、伝送経路の機能にも配慮すべきである。  
この伝送経路の仕組みに関する以下の文章の空欄Ａ~Ｄに当てはまる語句の組み  
合わせとして、最も適切なものを下記の解答群から選べ。  

データやプログラムは、PC 内部のマザーボードで発生する（　Ａ　）と同期  
を取りながら、バス上で伝送される。CPU と主記憶装置の間でそれらを伝送す  
るシステムバスは、（ 　Ｂ　 ）、データバス、コントロールバスから構成されて  
いる。  
PC の入出力バスと（　 Ｃ 　）や DVD 装置を接続し、それらをオペレーティ  
ングシステムの起動ディスクとして利用する場合に使用できる代表的なインタフ  
ェースは SATA である。  
PC のシステムバスに接続された（　 Ｄ 　）インタフェースは、これまで主に  
グラフィックスボードなどを装着するために利用されてきたが、このインタフェ  
ースに装着できる SSD を使用すると、データなどの読み書き速度や PC の起動  
速度が向上する。  
（解答群）  
  
ア   Ａ：クロック Ｂ：アドレスバス Ｃ：HDD Ｄ：PCI Express  
  
イ   Ａ：クロック Ｂ：パラレルバス Ｃ：SSD Ｄ：mSATA  
  
ウ   Ａ：パルス Ｂ：シリアルバス Ｃ：ブルーレイ Ｄ：NVMe  
  
エ   Ａ：パルス Ｂ：パラレルバス Ｃ：microSD Ｄ：IEEE 1394`
        },
        {
                "mondaiId": 106290020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務に PC を導入しようとするとき、処理速度を検討する必要がある。PC の処  
理速度は多くの要因によって変化し、その評価尺度もさまざまである。  
PC の処理速度や評価尺度に関する記述として、最も適切なものはどれか。  
  
ア   実際に使用するアプリケーションの処理内容を想定し、それらに特有な命令を  
組み合わせた命令ミックスを用いて性能評価することを MIPS と呼ぶ。  
  
イ   数値演算を行う場合、同じ数値を整数として演算する場合に比べ小数点付き数  
値として演算する方が処理が遅いのは、浮動小数点を用いる仕組みを使用してい  
るためである。  
  
ウ   整数演算の命令を実行させ、 秒間に実行できた命令数を表す指標が FLOPS  
で、この逆数が平均命令実行時間である。  
  
エ   単位時間当たりの命令実行数は CPU のクロック周波数の逆数で表される。こ  
の値が大きく、また CPI(Cycles Per Instruction)の値も大きいほど高速にプログ  
ラムが実行できる。`
        },
        {
                "mondaiId": 106290030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータの出力装置として使用するプリンタには印字方法の異なる製品があ  
り、業務の用途に応じて使い分ける必要がある。  
プリンタの特徴に関する記述として、最も適切なものはどれか。  
  
ア  インクジェットプリンタは、細いノズルから液体インクを用紙に噴出して印刷  
する。カラー印刷する場合は、黒色に加えて、シアン、マゼンタ、イエローのイ  
ンクの濃度を調節して印刷する。色の再現性能が高く、写真やポスターの印刷に  
向いている。  
  
イ  ドットインパクトプリンタはプリンタヘッドのピンにより活字のような印影を  
形作り、カーボンリボンの上から強打することでカーボンを用紙に転写して印刷  
する。複写伝票に印字できる唯一の仕組みであるが、文字以外の画像などの印刷  
はできない。  
  
ウ  熱転写プリンタは、インクリボンから熱で発色する染料を用紙に転写した後、  
熱を加えて発色させる。フルカラーの印刷はできないが、与える温度を変えるこ  
とで異なった発色が可能である。動作音が静かで、構造が簡素なため小型軽量化  
しやすくハンディターミナルなどにも用いられる。  
  
エ  レーザプリンタでカラー印刷可能な機器は、黒色に加えて、シアン、マゼン  
タ、ブルーのドラムを内蔵し、各色のトナーを紙面に転写し、最後に定着機でレ  
ーザ照射してトナーを固定する。印刷速度が高速で、事業所での文書や書類の印  
刷に利用される。  
        
`
        },
        {
                "mondaiId": 106290040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
スマートフォンやタブレットなどのモバイル端末では、文字などの入力を行う場  
合、種類の異なる入力画面がソフトウェアによって表示され、その画面をタッチす  
ることで入力を行う。  

この入力に関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、最も  
適切なものを下記の解答群から選べ。  

ひらがな、アルファベット、数字や記号などの入力画面がＡの形で表  
示される場合、全ての文字や記号を表示する枠数を確保できないので、1枠に複  
数の文字や記号を割り当てている。その1枠を複数回タッチして入力するのが  
Ｂ入力で、タッチ後に上下左右にスライドさせるのがＣ入力で  
ある。  
タブレットのように画面が大きく、PCのハードウェアキーボードと同じキー  
配列で入力を行いたい場合はＤ配列という設定を選べば良い。  

（解答群）  
  
ア  Ａ：101配列Ｂ：フリックＣ：トグルＤ：JIS  
  
イ  Ａ：チェックボックスＢ：フリックＣ：ジェスチャーＤ：106  
  
ウ  Ａ：テンキーＢ：ジェスチャーＣ：トグルＤ：Godan  
  
エ  Ａ：テンキーＢ：トグルＣ：フリックＤ：QWERTY  
        
`
        },
        {
                "mondaiId": 106290050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
オペレーティングシステム(OS)は、制御プログラム、言語プロセッサおよびユ  
ーティリティ(サービスプログラムとも呼ばれる)で構成される。  
OSの基本機能に関する記述として、最も適切なものはどれか。  
  
ア  言語プロセッサには、コンパイラ、インタプリタなどがある。コンパイラは、  
高水準言語で記述されたプログラムを機械語のオブジェクトプログラムに変換す  
る言語プロセッサである。  
  
イ  タスク管理とジョブ管理は、制御プログラムの基本機能である。タスク管理  
は、プログラムの実行単位を1つのタスクとして、その処理順序を監視・制御す  
ることであり、ジョブ管理は、タスクを細分化したジョブにCPUや主記憶など  
の資源をいかに割り付けるかを管理することである。  
  
ウ  デバイスドライバは、入出力装置などを操作・管理するプログラムであり、制  
御プログラムの中に組み込まれている。従って、新しいデバイスドライバが必要  
になった場合、OSの再インストールが必要となる。  
  
エ  ユーティリティは、制御プログラムおよび言語プロセッサを代替する機能を持  
ち、これによってOSは安定して稼働できるようになる。  
        
`
        },
        {
                "mondaiId": 106290060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務処理には表計算ソフトウェアがよく利用されるが、プログラムを作成するこ  
とによって、より効率的に業務を遂行できる場合がある。プログラム作成において  
変数を利用する際、データ型の定義が行われる。このデータ型の定義の仕方によ  
り、演算速度や演算誤差に影響を及ぼすことがある。  
このデータ型定義に関する記述として、最も適切なものはどれか。  
  
ア  小数点付きデータについて、適切なデータ型を定義することによって、演算誤  
差を取り除くことができる。  
  
イ  数値を格納する変数のデータ型を定義すれば、2進数による内部表現が区別さ  
れ、演算の精度や速度にも影響が出る。  
  
ウ  データ型を定義した変数を配列宣言して利用する場合、そのデータの格納領域  
は外部記憶装置に確保される。  
  
エ  変数のデータ型を定義すれば、データ型ごとに変数名索引リストが作成される  
ので、演算速度の向上に役立つ。  
        
`
        },
        {
                "mondaiId": 106290070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
Webコンテンツを多くのネット利用者に閲覧してもらうためには、検索サイト  
の仕組みを理解して利用することが重要である。  
それに関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切  
なものを下記の解答群から選べ。  

検索サイトは、インターネット上にあるWebサイト内の情報をＡと  
呼ばれる仕組みで収集し、検索用のデータベースに登録する。  

検索サイトに対して利用者からあるキーワードで検索要求が出された場合、検  
索サイトは、独自のＢによって求めた優先度をもとに、その上位から検  
索結果を表示している。  

Webサイト運営者は、Webコンテンツの内容が検索結果の上位に表示される  
ような施策を行う必要があり、Ｃ対策と呼ばれる。これにはブラックハ  
ット対策とＤ対策がある。  

（解答群）  
  
ア  Ａ：ガーベージＢ：アルゴリズムＣ：SERP　Ｄ：ホワイトハット  
  
イ  Ａ：クローラＢ：アルゴリズムＣ：SEO　Ｄ：ホワイトハット  
  
ウ  Ａ：クローラＢ：ハッシュＣ：KGI　Ｄ：ブルーハット  
  
エ  Ａ：スパイダーＢ：メトリクスＣ：SEM　Ｄ：グレーハット  
        
`
        },
        {
                "mondaiId": 106290080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
自社のWebサイトを近年の開発技術や新しい考え方を用いて魅力的にすること  
ができれば、さまざまな恩恵がもたらされる。  
それに関する記述として、最も適切なものはどれか。  
  
ア  AR(拡張現実)とは人工知能技術を指し、これをWebサイトに組み込むこと  
ができれば、顧客がWebサイトを通じて商品を購入する場合などの入力支援が  
可能となる。  
  
イ  IoTとはモノのインターネットと呼ばれ、今後、インターネットは全てこの方  
式に変更されるので、既存の自社のWebサイトを変更しなくても顧客が自社商  
品をどのように使っているかをリアルタイムに把握できるようになる。  
  
ウ  MCN(マルチチャンネルネットワーク)とは、自社のWebサイトを介して外部  
のWebサイトにアクセスできる仕組みを指し、自社のWebサイトにゲートウ  
ェイの機能を持たせることができる。  
  
エ  ウェアラブルデバイスとは身につけられるデバイスを指し、それを介して顧客  
の日々の生活、健康、スポーツなどに関わるデータを自社のWebサイトを経由  
してデータベースに蓄積できれば、顧客の行動分析をより緻密かつリアルタイム  
にできるようになる。  
        
`
        },
        {
                "mondaiId": 106290090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
業務処理のためには、多くの場合、データベース(DB)が利用される。DBをネ  
ットワーク環境下で利用する場合、さまざまな端末からトランザクション処理要求  
を受け付けるため、多くの負荷が集中することもある。このような状態の中での  
DBの効率的な運用や障害対策などのための仕組みが用意されている。  
そのような仕組みに関する記述として、最も適切なものはどれか。  
  
ア  DB運用中に表のデータ項目の追加・削除や新たな表追加が必要となり、DB  
の論理構造の再構築を行う場合は、SQL文のREBUILD命令において必要なパ  
ラメータを指示して実行する。  
  
イ  DBの更新処理を行う場合は、ロックと呼ばれる排他制御が行われる。このロ  
ックをかける範囲をロック粒度と呼び、ロック粒度が大きいと排他制御のための  
処理のオーバヘッドが大きくなる。  
  
ウ  DBの障害回復には、バックアップファイルを利用するロールフォワードとデ  
ータ更新状況を記録したものを利用するロールバックの仕組みがある。  
  
エ  クライアント端末からWebサーバを経由してDBサーバに対して更新作業を  
行う際、まずDBサーバに対して更新作業が可能かどうかを問い合わせることを2相のコミットメントと呼ぶ。  
        
`
        },
        {
                "mondaiId": 106290100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、ある日の東京、大阪、名古屋、九州の各支店の菓子ＡからＥの売上表で  
ある。  
この表に適用したSQL文とその結果を示したものの組み合わせとして、最も適  
切なものを下記の解答群から選べ。  

菓子売上  
<imgTag>106290100_1.png</imgTag>  

（解答群）  
  
ア  【SQL文】  
SELECT商品名FROM菓子売上  
WHERE東京支店>=3500and大阪支店>=3500or名古屋支店>=3500  
and九州支店>=3500  

　【結果】  
菓子Ｃ，菓子Ｄ，菓子Ｅ  
  
イ  
【SQL文】  
SELECT商品名FROM菓子売上  
WHERE東京支店>2500and大阪支店>2500and名古屋支店>=2500  
and九州支店>2500  

【結果】  
菓子Ｄ  
  
ウ  
【SQL文】  
SELECT商品名FROM菓子売上  
WHERE東京支店>3500and(大阪支店>3500or名古屋支店>3500)  
and九州支店>3500  

【結果】  
菓子Ａ，菓子Ｂ，菓子Ｅ  
  
エ  
【SQL文】  
SELECT商品名FROM菓子売上  
WHERE東京支店＋大阪支店＋名古屋支店＋九州支店>=14000  

【結果】  
菓子Ｄ  
        
`
        },
        {
                "mondaiId": 106290110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットを利用している事業所で、ネットワークに接続された端末や周辺  
機器の設置場所変更や増設を行おうとする場合、そのために使用するネットワーク  
機器の選定や各種設定作業が必要となる。  
このような場合のネットワーク管理に関する以下の文章の空欄Ａ~Ｄに入る語句  
の組み合わせとして、最も適切なものを下記の解答群から選べ。  

事業所においてインターネット接続を行い、グローバルIPアドレスをプライ  
ベートIPアドレスに変換して運用する場合は、IPアドレスとＡを用い  
て変換するＢ機能を持つルータを設置すればよい。  

PC設置場所の変更への対応やタブレットなどの利用を考慮する場合、それら  
の機器が自社のLANに接続された時のみ、空いているプライベートIPアドレ  
スを使用するＣ機能を利用するようにルータを設定することで、IPア  
ドレスの使用数の節約が図れる。  

事業所内の有線LANに接続する端末や周辺機器を増やしたい場合、  
Ｄを使用して通信相手を識別するスイッチングハブをカスケード接続す  
れば通信トラフィックを軽減できる。  

（解答群）  
  
ア  Ａ：MACアドレスＢ：NAPT  
Ｃ：DMZＤ：ルーティングテーブル  
  
イ  Ａ：サブネットマスクＢ：DNS  
Ｃ：DMZＤ：ポート番号  
  
ウ  Ａ：ポート番号Ｂ：NAPT  
Ｃ：DHCPＤ：MACアドレス  
  
エ  Ａ：ポート番号Ｂ：PPPoE  
Ｃ：IPルーティングＤ：ルーティングテーブル  
        
`
        },
        {
                "mondaiId": 106290120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
スマートフォンやタブレットなどは、ネットワークに接続して利用することを前  
提としている。  
こうした端末のネットワーク利用に関する記述として、最も適切なものはどれ  
か。  
  
ア  LTEとは、プラチナバンドを周波数帯域として使うモバイル通信規格を指す。  
  
イ  SIMフリー端末とは、SIMカードがなくても多様な通信ができる端末を指す。  
  
ウ  データローミングとは、端末利用者が、契約している移動体通信事業者と提携  
している他の移動体通信事業者の提供するサービスを利用できる機能を指す。  
  
エ  モバイルネットワークオペレータとは、ネットワーク接続に不慣れな利用者に  
対してサポートを行う事業者を指す。  
        
`
        },
        {
                "mondaiId": 106290130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
業務に利用するコンピュータシステムが、その機能や性能を安定して維持できる  
かどうかを評価する項目としてRASISが知られている。  
これらの項目に関連する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
コンピュータシステムの信頼性は、稼働時間に基づいたＡで評価する  
ことができ、この値が大きいほど信頼性は高い。  
コンピュータシステムの保守性は、修理時間に基づいたＢで評価する  
ことができ、この値が小さいほど保守が良好に行われている。  
障害が発生しないようにコンピュータシステムの点検や予防措置を講ずること  
はＣとＤを高める。また、システムを二重化することは、個々  
の機器のＣを変えることはできないがシステムのＤを高めるこ  
とはできる。  
（解答群）  
  
ア  Ａ：MTBF　Ｂ：MTTR  
Ｃ：信頼性Ｄ：可用性  
  
イ  Ａ：MTBF/(MTBF＋MTTR)　Ｂ：MTBF  
Ｃ：安全性Ｄ：可用性  
  
ウ  Ａ：MTBF/(MTBF＋MTTR)　Ｂ：MTTR  
Ｃ：信頼性Ｄ：保全性  
  
エ  Ａ：MTTR　Ｂ：MTBF/(MTBF＋MTTR)  
Ｃ：安全性Ｄ：保全性  
        
`
        },
        {
                "mondaiId": 106290140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ITの進化に伴い、大量かつ多様なデジタルデータを高速で処理し、分析結果を  
企業経営に生かせるようになってきた。そこには、日々の業務で発生するトランザ  
クションデータ、eメールや交流サイトでやりとりされるWeb上のデータ、デジ  
タル機器から発信されるIoT関連データなどが含まれる。  
これらのデジタルデータの処理に関する記述として、最も適切なものはどれか。  
  
ア  センサーの小型化と低価格化がIoTの普及を促進している。センサーには、  
地磁気を測定するジャイロセンサー、加速度を測定する電子コンパスなどさまざ  
まなものがあり、それらを組み合わせた新しいサービスが実現化されている。  
  
イ  大容量のデータ処理を高速化するため、ハードディスクの読み書きを避けてメ  
モリ上で処理を完結する技術がある。これをストリームデータ処理という。  
  
ウ  データベースに保管された大容量のデータを処理するために、サーバを増設し  
て負荷を分散化させる方法を複合イベント処理という。  
  
エ  日本五テキストの分析では、意味を持つ最小の言語単位にテキストを分け、品  
詞を判別することが必要になる。テキストのデータ分析に先立つこのような事前  
処理を形態素解析という。  
        
`
        },
        {
                "mondaiId": 106290150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業や社会で、インターネットを介して、さまざまな形でデジタルデータの利活  
用が進んでいる。  
それに関する記述として、最も適切なものはどれか。  
  
ア  M2Mとは、人同士がよりスムースにインターネットを介してつながることを  
意味する言葉であり、SNSの基本とされている。  
  
イ  インダストリー4.0とは、米国政府が2012年に発表した、情報技術を活用し  
生産性の向上やコストの削減を支援する取り組みを指す。  
  
ウ  オープンソースとは、インターネットの双方向性を活用するデータ利用のこと  
で、行政への市民参加を促進するための情報公開・意見収集の手段である。  
  
エ  行政データのオープンデータ化とは、行政組織で収集されてきたデータを広く  
社会に公開し民間で利活用できるようにすることを指す。`
        },
        {
                "mondaiId": 106290160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
データベースに蓄積されたデータを有効活用するためにデータウェアハウスの構  
築が求められている。  
データウェアハウスの構築、運用あるいはデータ分析手法などに関する記述とし  
て、最も適切なものはどれか。  
  
ア  BI(BusinessIntelligence)ツールとは、人工知能のアルゴリズムを開発するソ  
フトウェアをいう。  
  
イ  ETL(Extract/Transform/Load)とは、時系列処理のデータ変換を行うアルゴ  
リズムをいい、将来の販売動向のシミュレーションなどを行うことができる。  
  
ウ  大量かつ多様な形式のデータを処理するデータベースで、RDBとは異なるデ  
ータ構造を扱うものにNoSQLデータベースがある。  
  
エ  データマイニングとは、データの特性に応じてRDBのスキーマ定義を最適化  
することをいう。  
        
`
        },
        {
                "mondaiId": 106290170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ウォータフォール型システム開発方法論は、システム開発を行う上での基本プロ  
セスである。しかし、それには多くの課題があり、それらを克服することが、多様  
な開発方法論の提言の動機付けになってきた。ウォータフォール型システム開発方法論に関する記述として、最も適切なものは  
どれか。  
  
ア  ウォータフォール型システム開発方法論では、開発プロセスを｢要件定義｣、  
｢外部設計｣、｢内部設計｣、｢開発(プログラミング)｣、｢テスト｣、｢運用｣の順に行  
い、後戻りしないことが理想とされている。  
  
イ  ウォータフォール型システム開発方法論では、開発プロセスを｢要件定義｣、  
｢内部設計｣、｢外部設計｣、｢開発(プログラミング)｣、｢運用｣、｢テスト｣の順に行  
い、後戻りしないことが理想とされている。  
  
ウ  ウォータフォール型システム開発方法論に対して、スパイラルモデルでは一連  
のプロセスを何度も繰り返すことを許すが、その際には、まず全体の概要を構築  
し、それを徐々に具体化するプロセスが採用される。  
  
エ  プロトタイプモデルは、ウォータフォール型システム開発方法論における｢テ  
スト｣工程でのノウハウがなかなか蓄積できないとの課題に対応して提案された  
ものである。  
        
`
        },
        {
                "mondaiId": 106290180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ソフトウェア開発の見積もり手法には、大きく分けて、類推法、パラメトリック  
法、ボトムアップ法がある。  
それらの手法に関する記述として、最も適切なものはどれか。  
  
ア  LOC法は、プログラムのステップ数に基づいて見積もりを行う手法であり、  
パラメトリック法に分類される。  
  
イ  ファンクションポイントは、どの見積もり手法でも必要となる重要データであ  
る。  
  
ウ  ボトムアップ法は、要件定義の段階で見積もる手法であり、以降の段階ではよ  
り詳細なパラメトリック法が用いられる。  
  
エ  類推法は、過去の類似システムと比較して見積もる手法で、標準タスク法など  
がこれに該当する。`
        },
        {
                "mondaiId": 106290190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ソフトウェアの開発では、作成したプログラムのモジュール単体に対するテスト  
や、モジュール同士の結合テストなど、さまざまなテストをしてから運用に入る。  
テストに関する記述として、最も適切なものはどれか。  
  
ア  結合テストの方法の1つにビッグバンテストがあり、複数のモジュールを一挙  
に結合して、その動作を検証する。  
  
イ  上位モジュールと下位モジュールを結合してテストを実施したいが上位モジュ  
ールが完成していない場合、スタブと呼ばれるダミーモジュールを作ってテスト  
する。  
  
ウ  ブラックボックステストでは、モジュール内の分岐や繰り返しなど、内部ロジ  
ックが正しいかをテストする。  
  
エ  モジュールのテストでは、まずモジュール間を接続し、結合テストを行って全  
体の整合性を確認し、その後単体テストを実施してモジュール単体の動作を詳し  
くテストする。  
        
`
        },
        {
                "mondaiId": 106290200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システム化の構想や計画、あるいはIT投資評価などを行う際に必要となる概念  
やフレームワークなどに関する記述として、最も適切なものはどれか。  
  
ア  EA(EnterpriseArchitecture)とは、組織全体の意思決定の階層を、戦略的計  
画、マネジメントコントロール、オペレーショナルコントロールの  
つに分け  
て、システム化の構想をするものである。  
  
イ  ITポートフォリオとは、リスクやベネフィットを考慮しながらIT投資の対  
象を特性に応じて分類し、資源配分の最適化を図ろうとするものである。  
  
ウ  SLA(ServiceLevelAgreement)とは、ITサービスを提供する事業者とITサ  
ービスを利用する企業間の契約で、ITサービスを提供する事業者が知り得た経  
営上あるいは業務上の知識や情報の秘密を漏えいしないための秘密保持契約をい  
う。  
  
エ  WBS(WorkBreakdownStructure)とは、現行の業務フロー分析を行い、シス  
テム化の範囲を定めるために用いる手法である。`
        },
        {
                "mondaiId": 106290210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システム開発の成功のためには、プロジェクトの予算と実績の差異分析が重要に  
なる。その手法の\u0002つにアーンド・バリュー分析がある。アーンド・バリュー分析  
では、AC(ActualCost：コスト実績値)、EV(EarnedValue：出来高実績値)、PV  
(PlannedValue：出来高計画値)を用いて、コスト効率指数であるCPI(Cost PerformanceIndex)や、スケジュール効率指数であるSPI(SchedulePerformance Index)などを計算する。  
CPIとSPIの計算式の組み合わせとして、最も適切なものはどれか。  
  
ア  CPI＝AC/EV　SPI＝EV/PV  
  
イ  CPI＝EV/AC　SPI＝EV/PV  
  
ウ  CPI＝EV/PV　SPI＝AC/EV  
  
エ  CPI＝EV/PV　SPI＝EV/AC  
        
`
        },
        {
                "mondaiId": 106290220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ATMを使った金融取引やPCへのログインの際など、本人確認のための生体認  
証技術が広く社会に普及している。認証の精度は、他人受入率(FAR：False  AcceptanceRate)と本人拒否率(FRR：FalseRejectionRate)によって決まる。この2つはトレードオフ関係にあり、一般に片方を低く抑えようとすると、もう片方  
は高くなる。  

FARとFRRに関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、  
最も適切なものを下記の解答群から選べ。  

ａ　Ａが低いと安全性を重視したシステムになり、Ｂが低いと利  
用者の利便性を重視したシステムになる。  
ｂ　ATMでの生体認証では、Ｃが十分低くなるように設定されている。  
ｃ　なりすましを防止するには、Ｄを低く抑えることに重点をおけばよ  
い。

（解答群）  
  
ア  Ａ：FAR　Ｂ：FRR　Ｃ：FAR　Ｄ：FAR  
  
イ  Ａ：FAR　Ｂ：FRR　Ｃ：FRR　Ｄ：FRR  
  
ウ  Ａ：FRR　Ｂ：FAR　Ｃ：FAR　Ｄ：FAR  
  
エ  Ａ：FRR　Ｂ：FAR　Ｃ：FRR　Ｄ：FRR  
        
`
        },
        {
                "mondaiId": 106290230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業Ａ社は、現在クライアント・サーバ方式で財務・会計システムを保有し  
ているが、クラウド・コンピューティングへの移行を検討している。  
クラウド・コンピューティングに関する記述として、最も適切なものはどれか。  
  
ア  PaaSを利用する場合、ミドルウェア部分のサービスのみが提供されるため、  
現行のクライアント・サーバシステムを保有し続ける必要がある。  
  
イ  SaaS利用ではアプリケーション、PaaS利用ではミドルウェアというように、  
それぞれサービスを提供する業者が異なるため、それらをうまく組み合わせてシ  
ステムを再構築する必要がある。  
  
ウ  SaaSを利用する場合、課金体系は月額固定制であることが法的に義務付けら  
れているため、システムの利用頻度が高いほど業務単位当たりの実質的コストが  
軽減できる。  
  
エ  SaaSを利用する場合、業者の提供するアプリケーションを活用することにな  
るため、自社業務への適合性などをよく検討する必要がある。  
        
`
        },
        {
                "mondaiId": 106290240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある企業では、ここ数年の月当たり販売促進費とその月の売上高を整理したとこ  
ろ、下図のような関係が観察された。  
販売促進費と売上高の関係式を求めるための分析手法として、最も適切なものを  
下記の解答群から選べ。  

<imgTag>106290240_1.png</imgTag>  

（解答群）  
  
ア  因子分析  
  
イ  回帰分析  
  
ウ  クラスター分析  
  
エ  コンジョイント分析  
        
`
        },
        {
                "mondaiId": 106290250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2017,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
ア  ンケート調査では、どのようなデータを収集するか、あるいはどのような測定  
尺度を用いるかを定める必要がある。  
それらに関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適  
切なものを下記の解答群から選べ。  
ａＡで測定されたデータは、身長や体重などのように値\u000eが絶対的な意  
味を持つ。  
ｂＢで測定されたデータは、値の大小関係と差の大きさに意味があり、  
値\u000eは相対的な意味しか持たない。  
ｃ特定の年あるいは期間に生まれた人の集団をＣといい、人口の推移を  
分析する場合などに用いられる。  
ｄ同一標本に対し継続的に繰り返し調査を行う場合、調査対象となった集団を  
Ｄという。  

（解答群）  
  
ア  Ａ：間隔尺度　Ｂ：比(比例)尺度  
Ｃ：コーホート(cohort)　Ｄ：パネル(panel)  
  
イ  Ａ：間隔尺度　Ｂ：比(比例)尺度  
Ｃ：パネル(panel)　Ｄ：コーホート(cohort)  
  
ウ  Ａ：比(比例)尺度　Ｂ：間隔尺度  
Ｃ：コーホート(cohort)　Ｄ：パネル(panel)  
  
エ  Ａ：比(比例)尺度　Ｂ：間隔尺度  
Ｃ：パネル(panel)　Ｄ：コーホート(cohort)  
`
        },
        {
                "mondaiId": 107290011,
                "name": "第1問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

総務省｢2009年、2014年経済センサス安基礎調査｣、総務省・経済産業省｢2012  
年経済センサス安活動調査｣に基づき、中小企業数の推移を、2009年、2012年、  
2014年の２時点で比較した場合、一貫してＡしており、Ａのペ  
ースは、Ｂ。  

もっとも企業規模別に企業数の推移を見ると、小規模企業と中規模企業では違い  
も見られる。Ｃでは、2012年から2014年にかけての期間においてのみ、  
企業数がＤしている。  

なお、ここでは企業数は会社数と個人事業者数の合計とする。中規模企業とは小  
規模企業を除く中小企業である。企業規模区分は中小企業基本法に準ずる。  

（設問１）  

文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：減少Ｂ：拡大している  
  
イ  Ａ：減少Ｂ：緩やかになっている  
  
ウ  Ａ：増加Ｂ：拡大している  
  
エ  Ａ：増加Ｂ：緩やかになっている`
        },
        {
                "mondaiId": 107290012,
                "name": "第1問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

総務省｢2009年、2014年経済センサス安基礎調査｣、総務省・経済産業省｢2012  
年経済センサス安活動調査｣に基づき、中小企業数の推移を、2009年、2012年、  
2014年の２時点で比較した場合、一貫してＡしており、Ａのペ  
ースは、Ｂ。  

もっとも企業規模別に企業数の推移を見ると、小規模企業と中規模企業では違い  
も見られる。Ｃでは、2012年から2014年にかけての期間においてのみ、  
企業数がＤしている。  

なお、ここでは企業数は会社数と個人事業者数の合計とする。中規模企業とは小  
規模企業を除く中小企業である。企業規模区分は中小企業基本法に準ずる。  


（設問２）  
文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：小規模企業Ｄ：減少  
  
イ  Ｃ：小規模企業Ｄ：増加  
  
ウ  Ｃ：中規模企業Ｄ：減少  
  
エ  Ｃ：中規模企業Ｄ：増加  
        
`
        },
        {
                "mondaiId": 107290020,
                "name": "第2問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

総務省｢2014年経済センサス安基礎調査｣、総務省・経済産業省｢2012年経済セ  
ンサス安活動調査｣に基づき、2012年から2014年にかけての期間について、企業  
規模別業種別に中小企業数の増減を見た場合、小規模企業と中規模企業とも、医  
療・福祉では増加、Ａでは減少している。これに対し、Ｂでは小  
規模企業のみ減少している。  

なお、ここでは中規模企業は小規模企業以外の中小企業とし、企業規模区分は中  
小企業基本法に準ずるものとする。  

（解答群）  
  
ア  Ａ：建設業Ｂ：小売業  
  
イ  Ａ：建設業Ｂ：製造業  
  
ウ  Ａ：小売業Ｂ：建設業  
  
エ  Ａ：小売業Ｂ：製造業  
  
オ  Ａ：製造業Ｂ：小売業  
        
`
        },
        {
                "mondaiId": 107290031,
                "name": "第3問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。

財務省｢法人企業統計調査年報｣に基づき、リーマン・ショック後の2009年度か  
ら2014年度の期間について、企業規模別に経常利益の推移を見ると、（　　　　）にある。  

次に、財務省｢法人企業統計調査季報｣に基づき、2009年と2015年について、経  
常利益の変動要因を、売上高、変動費、人件費、減価償却費、営業外損益に大別し  
て、企業規模別に見ると、<u>中小企業と大企業では違いが見られる</u>。 

なお、ここでは大企業は資本金10億円以上の企業、中小企業とは資本金１千万  
円以上１億円未満の企業とする。  

（設問１）  

文中の空欄に入る記述として、最も適切なものはどれか。  
  
ア  大企業、中小企業とも減少傾向  
  
イ  大企業、中小企業とも増加傾向  
  
ウ  大企業は減少傾向、中小企業は増加傾向  
  
エ  大企業は増加傾向、中小企業は減少傾向`
        },
        {
                "mondaiId": 107290032,
                "name": "第3問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                次の文章を読んで、下記の設問に答えよ。

財務省｢法人企業統計調査年報｣に基づき、リーマン・ショック後の2009年度か  
ら2014年度の期間について、企業規模別に経常利益の推移を見ると、（　　　　）にある。  

次に、財務省｢法人企業統計調査季報｣に基づき、2009年と2015年について、経  
常利益の変動要因を、売上高、変動費、人件費、減価償却費、営業外損益に大別し  
て、企業規模別に見ると、<u>中小企業と大企業では違いが見られる</u>。 

なお、ここでは大企業は資本金10億円以上の企業、中小企業とは資本金１千万  
円以上１億円未満の企業とする。  

（設問２）  
文中の下線部について、企業規模別に経常利益の変動要因を見た場合の記述と  
して、最も適切なものはどれか。  
  
ア  大企業では売上高の減少、中小企業では売上高の増加が寄与している。  
  
イ  大企業では売上高の増加、中小企業では売上高の減少が寄与している。  
  
ウ  大企業では変動費の減少、中小企業では変動費の増加が寄与している。  
  
エ  大企業では変動費の増加、中小企業では変動費の減少が寄与している。  
        
`
        },
        {
                "mondaiId": 107290040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財務省｢法人企業統計調査年報｣に基づき、企業規模別に1993年度、2005年度、  
2014年度の貸借対照表における資産規模(総資産)の推移を見た場合の記述として、  
最も適切なものはどれか。なお、ここでは資本金１億円未満の企業を中小企業と  
し、同10億円以上の企業を大企業とする。  
  
ア  大企業、中小企業とも一貫して減少している。  
  
イ  大企業、中小企業とも一貫して増加している。  
  
ウ  大企業は一貫して増加する一方、中小企業では増減が見られる。  
  
エ  中小企業は一貫して増加する一方、大企業では増減が見られる。  
        
`
        },
        {
                "mondaiId": 107290051,
                "name": "第5問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
財務省｢2014年度法人企業統計調査年報｣に基づき、法人企業における中小企業  
と大企業の売上高経常利益率と<u>①自己資本比率</u>の中央値を一次産業を除く全産業で比較すると、いずれも中小企業が大企業を下回っており規模間格差が存在しているが、業種別に見ると中小企業と大企業の規模間格差の状況には違いも見られる。  

同様に<u>②従業者一人当たりの売上高</u>を比較しても、業種別で規模間格差には違いが  
見られる。

なお、ここでは企業規模区分は中小企業基本法に準ずるものとする。  

（設問１）  

文中の下線部①について、財務省｢2014年度法人企業統計調査年報｣に基づき、  
製造業、卸・小売業、サービス業における自己資本比率を規模別に比較した場合  
の記述として、最も適切なものはどれか。  
  
ア  大企業、中小企業とも卸・小売業が最も高い。  
  
イ  大企業、中小企業とも卸・小売業が最も低い。  
  
ウ  大企業、中小企業ともサービス業が最も高い。  
  
エ  大企業、中小企業とも製造業が最も高い。  
  
オ  大企業、中小企業とも製造業が最も低い。  
        
`
        },
        {
                "mondaiId": 107290052,
                "name": "第5問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `      
次の文章を読んで、下記の設問に答えよ。  
財務省｢2014年度法人企業統計調査年報｣に基づき、法人企業における中小企業  
と大企業の売上高経常利益率と<u>①自己資本比率</u>の中央値を一次産業を除く全産業で比較すると、いずれも中小企業が大企業を下回っており規模間格差が存在しているが、業種別に見ると中小企業と大企業の規模間格差の状況には違いも見られる。  

同様に<u>②従業者一人当たりの売上高</u>を比較しても、業種別で規模間格差には違いが  
見られる。

なお、ここでは企業規模区分は中小企業基本法に準ずるものとする。  

（設問２）  
文中の下線部②について、財務省｢2014年度法人企業統計調査年報｣に基づき、  
中小企業の従業者一人当たりの売上高を、製造業、卸・小売業、サービス業で比  
較した場合の記述として、最も適切なものはどれか。  
  
ア  卸・小売業が最も多く、サービス業が最も少ない。  
  
イ  卸・小売業が最も多く、製造業が最も少ない。  
  
ウ  サービス業が最も多く、製造業が最も少ない。  
  
エ  製造業が最も多く、卸・小売業が最も少ない。  
  
オ  製造業が最も多く、サービス業が最も少ない。  
        
`
        },
        {
                "mondaiId": 107290061,
                "name": "第6問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

小規模企業の特徴として、法人化していない個人事業者が多いことが指摘でき  
る。総務省｢2014年経済センサス安基礎調査｣に基づくと、わが国企業数(非一次  
産業)の約　Ａ　％を占める小規模企業のうち、約　Ｂ　割は個人事業者  
である。これに対して中規模企業における個人事業者の占める割合は約　Ｃ　割である。  
同様に、<u>常用雇用者のいない小規模企業が多い</u>ことも特徴であり、中規模企業と  
比べても規模の小ささが際立っている。 

なお、ここでは企業数は会社数と個人事業者数の合計とする。中規模企業とは小  
規模企業を除く中小企業である。企業規模区分は中小企業基本法に準ずるものとす  
る。  

（設問１）

文中の空欄Ａ~Ｃに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：70　Ｂ：6 Ｃ：2  
  
イ  Ａ：70 Ｂ：7 Ｃ：4  
  
ウ  Ａ：85 Ｂ：6 Ｃ：2  
  
エ  Ａ：85 Ｂ：7 Ｃ：4  
  
オ  Ａ：90 Ｂ：6 Ｃ：2  
        
`
        },
        {
                "mondaiId": 107290062,
                "name": "第6問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

小規模企業の特徴として、法人化していない個人事業者が多いことが指摘でき  
る。総務省｢2014年経済センサス安基礎調査｣に基づくと、わが国企業数(非一次  
産業)の約　Ａ　％を占める小規模企業のうち、約　Ｂ　割は個人事業者  
である。これに対して中規模企業における個人事業者の占める割合は約　Ｃ　割である。  
同様に、<u>常用雇用者のいない小規模企業が多い</u>ことも特徴であり、中規模企業と  
比べても規模の小ささが際立っている。 

なお、ここでは企業数は会社数と個人事業者数の合計とする。中規模企業とは小  
規模企業を除く中小企業である。企業規模区分は中小企業基本法に準ずるものとす  
る。  

（設問２）  
文中の下線部について、総務省｢2014年経済センサス安基礎調査｣に基づき、  
小規模企業の常用雇用者の有無を見た場合の記述として、最も適切なものはどれ  
か。  
  
ア  小規模企業のうち、常用雇用者がいない企業割合は約6割である。  
  
イ  小規模企業のうち、常用雇用者がいない企業割合は約9割である。  
  
ウ  法人化していない小規模企業のうち、常用雇用者がいない企業割合は約6割  
である。  
  
エ  法人化していない小規模企業のうち、常用雇用者がいない企業割合は約9割  
である。  
        
`
        },
        {
                "mondaiId": 107290071,
                "name": "第7問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

本格的な人口減少社会に突入した日本経済において、需要縮小や労働供給の制約を克服し、持続的な発展を目指すためには、企業の労働生産性の向上が不可欠である。<u>①労働生産性</u>の水準は扱う財やサービスの性質や企業規模にも影響を受ける。財務省｢2014年度法人企業統計調査年報｣に基づくと、中小企業の労働生産性(一次産業を除く全産業)は、大企業に比較すると約（　Ａ　）割の水準にとどまっているのが現状である。中小企業は｢2014年経済センサス安基礎調査｣を見ても、会社と個人事業所の従業者総数(2014年、民営非一次産業)の約（　Ｂ　）割を占める存在であるため、日本経済全体にとって中小企業の労働生産性の向上は大きな課題となっている。  

他方で、経済産業省｢2014年企業活動基本調査｣に基づき、企業規模別業種別に労働生産性の分布を見ると、<u>②同業大企業の平均を上回る中小企業が一定数存在している</u>。こうした労働生産性の高い中小企業の特徴を分析すると、大企業よりも生産性が低い同業中小企業と比べて設備投資額が大きいことや資本装備率が高いことが指摘できる。中小企業の労働生産性の向上を図るためには、こうした労働生産性の高い中小企業をいかにして増やしていくかという視点も重要である。  

なお、ここでは企業規模区分は中小企業基本法に準ずるものとするが、｢2014年  
企業活動基本調査｣では従業者数50人未満、もしくは資本金または出資金3，000万  
円未満の法人企業は調査対象に含まれていない。労働生産性は付加価値額を従業者  
数で除したものとする。  

（設問１）  

文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：２　Ｂ：３  
  
イ  Ａ：４　Ｂ：５ 
  
ウ  Ａ：５　Ｂ：５  
        
エ  Ａ：６　Ｂ：７  
  
オ  Ａ：７　Ｂ：９  
        
`
        },
        {
                "mondaiId": 107290072,
                "name": "第7問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

本格的な人口減少社会に突入した日本経済において、需要縮小や労働供給の制約を克服し、持続的な発展を目指すためには、企業の労働生産性の向上が不可欠である。<u>①労働生産性</u>の水準は扱う財やサービスの性質や企業規模にも影響を受ける。財務省｢2014年度法人企業統計調査年報｣に基づくと、中小企業の労働生産性(一次産業を除く全産業)は、大企業に比較すると約（　Ａ　）割の水準にとどまっているのが現状である。中小企業は｢2014年経済センサス安基礎調査｣を見ても、会社と個人事業所の従業者総数(2014年、民営非一次産業)の約（　Ｂ　）割を占める存在であるため、日本経済全体にとって中小企業の労働生産性の向上は大きな課題となっている。  

他方で、経済産業省｢2014年企業活動基本調査｣に基づき、企業規模別業種別に労働生産性の分布を見ると、<u>②同業大企業の平均を上回る中小企業が一定数存在している</u>。こうした労働生産性の高い中小企業の特徴を分析すると、大企業よりも生産性が低い同業中小企業と比べて設備投資額が大きいことや資本装備率が高いことが指摘できる。中小企業の労働生産性の向上を図るためには、こうした労働生産性の高い中小企業をいかにして増やしていくかという視点も重要である。  

なお、ここでは企業規模区分は中小企業基本法に準ずるものとするが、｢2014年  
企業活動基本調査｣では従業者数50人未満、もしくは資本金または出資金3，000万  
円未満の法人企業は調査対象に含まれていない。労働生産性は付加価値額を従業者  
数で除したものとする。  

（設問２）

文中の下線部①について、財務省｢2014年度法人企業統計調査年報｣に基づき、  
業種別の労働生産性を見た場合、高いものから低いものへと並べた組み合わせと  
して、最も適切なものを下記の解答群から選べ。  

ａ：卸・小売業  
ｂ：宿泊業、飲食サービス業  
ｃ：製造業  

（解答群）  
  
ア  ａ：卸・小売業　－　ｂ：宿泊業、飲食サービス業　－　ｃ：製造業  
  
イ  ａ：卸・小売業　－　ｃ：製造業　－　ｂ：宿泊業、飲食サービス業  
  
ウ  ｂ：宿泊業、飲食サービス業　－　ａ：卸・小売業　－　ｃ：製造業  
  
エ  ｃ：製造業　－　ａ：卸・小売業　－　ｂ：宿泊業、飲食サービス業  
  
オ  ｃ：製造業　－　ｂ：宿泊業、飲食サービス業　－　ａ：卸・小売業`
        },
        {
                "mondaiId": 107290073,
                "name": "第7問設問3",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

本格的な人口減少社会に突入した日本経済において、需要縮小や労働供給の制約を克服し、持続的な発展を目指すためには、企業の労働生産性の向上が不可欠である。<u>①労働生産性</u>の水準は扱う財やサービスの性質や企業規模にも影響を受ける。財務省｢2014年度法人企業統計調査年報｣に基づくと、中小企業の労働生産性(一次産業を除く全産業)は、大企業に比較すると約（　Ａ　）割の水準にとどまっているのが現状である。中小企業は｢2014年経済センサス安基礎調査｣を見ても、会社と個人事業所の従業者総数(2014年、民営非一次産業)の約（　Ｂ　）割を占める存在であるため、日本経済全体にとって中小企業の労働生産性の向上は大きな課題となっている。  

他方で、経済産業省｢2014年企業活動基本調査｣に基づき、企業規模別業種別に労働生産性の分布を見ると、<u>②同業大企業の平均を上回る中小企業が一定数存在している</u>。こうした労働生産性の高い中小企業の特徴を分析すると、大企業よりも生産性が低い同業中小企業と比べて設備投資額が大きいことや資本装備率が高いことが指摘できる。中小企業の労働生産性の向上を図るためには、こうした労働生産性の高い中小企業をいかにして増やしていくかという視点も重要である。  

なお、ここでは企業規模区分は中小企業基本法に準ずるものとするが、｢2014年  
企業活動基本調査｣では従業者数50人未満、もしくは資本金または出資金3，000万  
円未満の法人企業は調査対象に含まれていない。労働生産性は付加価値額を従業者  
数で除したものとする。  

（設問３）  
文中の下線部②について、経済産業省｢2014年企業活動基本調査｣に基づき、  
業種別に大企業の労働生産性の平均値を上回る中小企業の構成比率を見た場合、  
飲食サービス業、卸売業、小売業、製造業の４業種のうち、大企業の平均値を上  
回る中小企業の構成比率が最も高いものはどれか。  
  
ア  飲食サービス業  
  
イ  卸売業  
  
ウ  小売業  
  
エ  製造業  
        
`
        },
        {
                "mondaiId": 107290081,
                "name": "第8問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

中小企業におけるIT導入として、従来のように自社でハードウェアやソフトウ  
ェアを所有することなく、インターネット等のネットワークを通じて情報処理サー  
ビスを提供するクラウド・コンピューティングの利用が注目を集めている。  

経済産業省｢情報処理実態調査｣に基づき、クラウド・コンピューティングに係る  
関連費用の発生の有無から、2009年から2013年の期間について、企業規模別にク  
ラウド・コンピューティングの利用割合を見た場合、各年とも中小企業の利用割合  
は大企業に比べ（　Ａ　）。また利用割合は（　Ｂ　）基調で推移している。  

次に2014年の同調査から、企業規模別に<u>クラウド・コンピューティングのメリット</u>を見た場合(複数回答)、大企業・中小企業とも｢導入までの期間が短い｣｢初期コストが安い｣とする回答割合が高いものの、大企業と中小企業では違いも見られ  
る。  

（設問１）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：高いＢ：減少  
  
イ  Ａ：高いＢ：増加  
  
ウ  Ａ：低いＢ：減少  
  
エ  Ａ：低いＢ：増加  
  
オ  Ａ：低いＢ：横ばい  
        
`
        },
        {
                "mondaiId": 107290082,
                "name": "第8問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                次の文章を読んで、下記の設問に答えよ。  

中小企業におけるIT導入として、従来のように自社でハードウェアやソフトウ  
ェアを所有することなく、インターネット等のネットワークを通じて情報処理サー  
ビスを提供するクラウド・コンピューティングの利用が注目を集めている。  

経済産業省｢情報処理実態調査｣に基づき、クラウド・コンピューティングに係る  
関連費用の発生の有無から、2009年から2013年の期間について、企業規模別にク  
ラウド・コンピューティングの利用割合を見た場合、各年とも中小企業の利用割合  
は大企業に比べ（　Ａ　）。また利用割合は（　Ｂ　）基調で推移している。  

次に2014年の同調査から、企業規模別に<u>クラウド・コンピューティングのメリット</u>を見た場合(複数回答)、大企業・中小企業とも｢導入までの期間が短い｣｢初期コストが安い｣とする回答割合が高いものの、大企業と中小企業では違いも見られ  
る。  


（設問２）  
文中の下線部について、経済産業省｢2014年情報処理実態調査｣に基づき、企  
業規模別にクラウド・コンピューティングのメリットを見た場合\u0004複数回答\u0005、  
｢技術的な専門知識がなくても導入できる｣、｢サービス・プラットフォームの定  
期的な機能拡充ができる｣、｢ソフトウェア利用の停止・解除が容易｣、｢ユーザーアカウントの追加等サービス拡張が容易｣のうち、中小企業の回答企業割合が最  
も高いものはどれか。  
  
ア  技術的な専門知識がなくても導入できる  
  
イ  サービス・プラットフォームの定期的な機能拡充ができる  
  
ウ  ソフトウェア利用の停止・解除が容易  
  
エ  ユーザーアカウントの追加等サービス拡張が容易  
        
`
        },
        {
                "mondaiId": 107290090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
次の文中の空欄Ａ~Ｃに入る語句と数値の組み合わせとして、最も適切なものを  
下記の解答群から選べ。  

中小製造業における直接輸出企業の数は長期的にも増加傾向にあるものの、経済  
産業省｢2013年工業統計表｣、総務省・経済産業省｢2012年経済センサス－活動調  
査｣に基づき、企業規模別に製造業の直接輸出企業数を見た場合、中小製造業全体  
に占める割合はＡ％に留まっている。  

また、経済産業省｢2013年工業統計表｣に基づき、中小製造業における直接輸出  
企業の業種構成を産業中分類で見ると、Ｂの構成割合が最も高く、約  
Ｃ割を占めており、機械器具関連がその中心を担っている。  

なお、ここでは従業者数4人以上の事業所単位の統計を企業単位で再集計するも  
のとし、企業規模区分は中小企業基本法に準ずるものとする。  

（解答群）  
  
ア  Ａ：3.5　Ｂ：生産用機械器具製造業　Ｃ：2  
  
イ  Ａ：3.5　Ｂ：生産用機械器具製造業　Ｃ：4  
  
ウ  Ａ：3.5　Ｂ：電気機械器具製造業　Ｃ：2 
  
エ  Ａ：10.5　Ｂ：生産用機械器具製造業　Ｃ：2  
  
オ  Ａ：10.5　Ｂ：電気機械器具製造業　Ｃ：4  
        
`
        },
        {
                "mondaiId": 107290101,
                "name": "第10問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

総務省｢事業所・企業統計調査｣、同｢2009年、2014年経済センサス－基礎調査｣  
に基づき、直接投資を行っている企業数の推移を見た場合、2001年と2006年の比  
較ではＡ、2006年と2009年の比較ではＢ、2009年と2014年の  
比較ではＣしている。  

また、企業規模別業種別に見ると、2001年から2014年の期間において、直接投  
資企業数全体に占める中小企業の割合は約Ｄ割の水準で推移している。業  
種別ではＥが中小企業における直接投資企業数のおおむね半分を占めて推  
移している。  
なお、ここでは企業規模区分は中小企業基本法に準ずるものとする。  

（設問１）

文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：減少Ｂ：減少Ｃ：増加  
  
イ  Ａ：減少Ｂ：増加Ｃ：増加  
  
ウ  Ａ：増加Ｂ：減少Ｃ：減少  
  
エ  Ａ：増加Ｂ：減少Ｃ：増加  
  
オ  Ａ：増加Ｂ：増加Ｃ：増加`
        },
        {
                "mondaiId": 107290102,
                "name": "第10問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

総務省｢事業所・企業統計調査｣、同｢2009年、2014年経済センサス－基礎調査｣  
に基づき、直接投資を行っている企業数の推移を見た場合、2001年と2006年の比  
較ではＡ、2006年と2009年の比較ではＢ、2009年と2014年の  
比較ではＣしている。  

また、企業規模別業種別に見ると、2001年から2014年の期間において、直接投  
資企業数全体に占める中小企業の割合は約Ｄ割の水準で推移している。業  
種別ではＥが中小企業における直接投資企業数のおおむね半分を占めて推  
移している。  
なお、ここでは企業規模区分は中小企業基本法に準ずるものとする。  

（設問２）  
文中の空欄ＤとＥに入る数値と語句の組み合わせとして、最も適切なものはど  
れか。  
  
ア  Ｄ：３　Ｅ：製造業  
  
イ  Ｄ：５　Ｅ：卸売業  
  
ウ  Ｄ：５　Ｅ：製造業  
  
エ  Ｄ：７　Ｅ：卸売業  
  
オ  Ｄ：７　Ｅ：製造業  
        
`
        },
        {
                "mondaiId": 107290111,
                "name": "第11問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

日本銀行｢金融経済月報｣等に基づき、中小企業白書2016年版が行った分析によ  
れば1993年から2015年の期間について、企業規模別に金融機関(国内銀行)の企業  
向け貸出の推移を見た場合、中小企業向け貸出、大企業向け貸出ともに、バブル崩  
壊以降から2000年代半ばにかけては大きく減少してきたが、<u>その後の推移は異なっている</u>。  

また2000年代以降について、国内銀行の法人向け貸出に占める中小企業向け貸  
出の割合の推移を見ると、2000年代半ばからＡしたが、2007年頃から  
Ｂ傾向に転じ、2013年頃からＣ傾向が続いている。  

（設問１）  
文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：上昇Ｂ：低下Ｃ：横ばい  
  
イ  Ａ：上昇Ｂ：横ばいＣ：低下  
  
ウ  Ａ：低下Ｂ：上昇Ｃ：横ばい  
  
エ  Ａ：低下Ｂ：横ばいＣ：上昇`
        },
        {
                "mondaiId": 107290112,
                "name": "第11問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

日本銀行｢金融経済月報｣等に基づき、中小企業白書2016年版が行った分析によ  
れば1993年から2015年の期間について、企業規模別に金融機関(国内銀行)の企業  
向け貸出の推移を見た場合、中小企業向け貸出、大企業向け貸出ともに、バブル崩  
壊以降から2000年代半ばにかけては大きく減少してきたが、<u>その後の推移は異なっている</u>。  

また2000年代以降について、国内銀行の法人向け貸出に占める中小企業向け貸  
出の割合の推移を見ると、2000年代半ばからＡしたが、2007年頃から  
Ｂ傾向に転じ、2013年頃からＣ傾向が続いている。  


（設問２）  
文中の下線部について、2011年から2015年の期間にかけて、企業規模別に金  
融機関(国内銀行)の企業向け貸出の推移を見た場合の記述として、最も適切なも  
のはどれか。  
  
ア  大企業向けはおおむね減少基調で推移している。  
  
イ  大企業向けはおおむね増加基調で推移している。  
  
ウ  中小企業向けはおおむね減少基調で推移している。  
  
エ  中小企業向けはおおむね増加基調で推移している。  
        
`
        },
        {
                "mondaiId": 107290120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業の資金調達の多様化の1つとしてABLの利用が考えられる。ABLに  
関する記述として、最も適切なものはどれか。  
  
ア  インターネットを利用して行う不特定多数からの資金調達手法  
  
イ  企業が保有する在庫や売掛債権、機械設備等を担保とする融資手法  
  
ウ  公募債以外の少数の投資家が直接引き受ける債券発行による資金調達手法  
  
エ  政府系金融機関の行う無担保無保証の融資手法`
        },
        {
                "mondaiId": 107290130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業基本法に基づく、中小企業の範囲に含まれる企業として、最も適切なも  
のはどれか。  
  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>

ア  従業員数60人の飲食店（資本金６千万円）  
  
イ  従業員数150人の飲食料品卸売業（資本金１億５千万円）  
  
ウ  従業員数200人の一般貨物自動車運送業（資本金２億円）<hint hidden='true'>**
←⭕
**</hint>    
  
エ  従業員数500人の食料品製造業（資本金５億円）  
        
`
        },
        {
                "mondaiId": 107290141,
                "name": "第14問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成26年6月に成立した小規模企業振興基本法では、小規模企業の振興に関する施策を講じる際の<u>①4つの基本方針</u>を定めている。さらに、同法に基づく、｢小規模企業振興基本計画｣では、その4つの基本方針の実現に向け、4つの目標と<u>②10の重点施策</u>を設定している。  

（設問１）  
文中の下線部①として、<u>最も不適切なものはどれか</u>。  
  
ア  経営資源の有効な活用、人材育成・確保  
  
イ  需要に応じた商品の販売、新事業展開の促進  
  
ウ  小規模企業向けの金融の円滑化  
  
エ  地域経済の活性化に資する事業活動の推進`
        },
        {
                "mondaiId": 107290142,
                "name": "第14問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成26年6月に成立した小規模企業振興基本法では、小規模企業の振興に関する施策を講じる際の<u>①4つの基本方針</u>を定めている。さらに、同法に基づく、｢小規模企業振興基本計画｣では、その4つの基本方針の実現に向け、4つの目標と<u>②10の重点施策</u>を設定している。  

（設問2）

文中の下線部②に含まれる施策として、最も適切なものはどれか。  
  
ア  海外進出支援  
  
イ  公正な市場環境の整備  
  
ウ  事業承継・円滑な事業廃止  
  
エ  下請取引のあっせん  
        
`
        },
        {
                "mondaiId": 107290151,
                "name": "第15問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成28年７月に中小企業等経営強化法が施行された。この法律では、主務大臣が事業分野ごとに生産性向上の方法などを示した指針を策定する。  

中小企業・小規模事業者等が、この法律に基づきＡを申請し、認定されることによって、Ｂの軽減措置や各種金融支援を受けることができる。なお、Ａの申請時に提出する指標としては、原則としてＣが基本となる。 

（設問１）  

文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  経営革新計画  
  
イ  経営力向上計画  
  
ウ  事業継続計画  
  
エ  事業承継計画  
        
`
        },
        {
                "mondaiId": 107290152,
                "name": "第15問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成28年７月に中小企業等経営強化法が施行された。この法律では、主務大臣が事業分野ごとに生産性向上の方法などを示した指針を策定する。  

中小企業・小規模事業者等が、この法律に基づきＡを申請し、認定されることによって、Ｂの軽減措置や各種金融支援を受けることができる。なお、Ａの申請時に提出する指標としては、原則としてＣが基本となる。 

（設問２）  
文中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  固定資産税  
  
イ  事業税  
  
ウ  登録免許税  
  
エ  特許料`
        },
        {
                "mondaiId": 107290153,
                "name": "第15問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

平成28年７月に中小企業等経営強化法が施行された。この法律では、主務大臣が事業分野ごとに生産性向上の方法などを示した指針を策定する。  

中小企業・小規模事業者等が、この法律に基づきＡを申請し、認定されることによって、Ｂの軽減措置や各種金融支援を受けることができる。なお、Ａの申請時に提出する指標としては、原則としてＣが基本となる。 

（設問３）  
文中の空欄Ｃに入る語句として、最も適切なものはどれか。  
  
ア  営業利益  
  
イ  経常利益  
  
ウ  付加価値額  
  
エ  労働生産性  
        
`
        },
        {
                "mondaiId": 107290161,
                "name": "第16問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

経済の好循環を実現するためには、下請等中小企業の取引条件を改善していくこ  
とが重要である。このため平成28年12月に、下請中小企業振興法に基づく振興基  
準の改正が行われた。この振興基準においては、｢下請代金の支払方法改善｣につい  
て以下のように記載されている。  

・親事業者は、下請代金の支払は、発注に係る物品等の受領後、できる限り速や  
かに、これを行うものとする。また、下請代金はできる限り現金で支払うものと  
し、少なくともＡに相当する金額については、全額を現金で支払うもの  
とする。  

・下請代金の支払に係る手形等のサイトについては、ＢはＣ以  
内、その他の業種はＤ以内とすることは当然として、段階的に短縮に努  
めることとし、将来的にはＥ以内とするよう努めるものとする。

（設問１）  

文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  外注加工賃  
  
イ  租税公課  
  
ウ  賃金  
  
エ  利子割引料  
        
`
        },
        {
                "mondaiId": 107290162,
                "name": "第16問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

経済の好循環を実現するためには、下請等中小企業の取引条件を改善していくこ  
とが重要である。このため平成28年12月に、下請中小企業振興法に基づく振興基  
準の改正が行われた。この振興基準においては、｢下請代金の支払方法改善｣につい  
て以下のように記載されている。  

・親事業者は、下請代金の支払は、発注に係る物品等の受領後、できる限り速や  
かに、これを行うものとする。また、下請代金はできる限り現金で支払うものと  
し、少なくともＡに相当する金額については、全額を現金で支払うもの  
とする。  

・下請代金の支払に係る手形等のサイトについては、ＢはＣ以  
内、その他の業種はＤ以内とすることは当然として、段階的に短縮に努  
めることとし、将来的にはＥ以内とするよう努めるものとする。

（設問２）  
文中の空欄ＢとＣに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｂ：建設業Ｃ：60日  
  
イ  Ｂ：繊維業Ｃ：60日  
  
ウ  Ｂ：建設業Ｃ：90日  
  
エ  Ｂ：繊維業Ｃ：90日`
        },
        {
                "mondaiId": 107290163,
                "name": "第16問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

経済の好循環を実現するためには、下請等中小企業の取引条件を改善していくこ  
とが重要である。このため平成28年12月に、下請中小企業振興法に基づく振興基  
準の改正が行われた。この振興基準においては、｢下請代金の支払方法改善｣につい  
て以下のように記載されている。  

・親事業者は、下請代金の支払は、発注に係る物品等の受領後、できる限り速や  
かに、これを行うものとする。また、下請代金はできる限り現金で支払うものと  
し、少なくともＡに相当する金額については、全額を現金で支払うもの  
とする。  

・下請代金の支払に係る手形等のサイトについては、ＢはＣ以  
内、その他の業種はＤ以内とすることは当然として、段階的に短縮に努  
めることとし、将来的にはＥ以内とするよう努めるものとする。

（設問３）  
文中の空欄ＤとＥに入る日数の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｄ：100日　Ｅ：30日  
  
イ  Ｄ：100日　Ｅ：60日  
  
ウ  Ｄ：120日　Ｅ：30日  
  
エ  Ｄ：120日　Ｅ：60日  
        
`
        },
        {
                "mondaiId": 107290171,
                "name": "第17問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

サービス産業は、経済全体に大きく影響を与える存在であるものの、労働生産性  
が低いことが指摘されている。経済産業省は、中小サービス業が生産性向上に取り  
組む際の参考となるよう、｢中小サービス事業者の生産性向上のためのガイドライ  
ン｣を策定している。  

このガイドラインにおいては、生産性向上のための方向性を以下の2つに大別し  
ている。  
        
１．（　Ａ　）  
２．（　Ｂ　）  

その上で、｢（　Ａ　）｣を実現する手法として新規顧客層への展開など８項目を提示し、｢（　Ｂ　）｣を実現する手法として２項目を提示している。  

（設問１）

文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  IT利活用  
  
イ  サービス提供プロセスの改善  
  
ウ  独自性・独創性の発揮  
  
エ  付加価値の向上`
        },
        {
                "mondaiId": 107290172,
                "name": "第17問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

サービス産業は、経済全体に大きく影響を与える存在であるものの、労働生産性  
が低いことが指摘されている。経済産業省は、中小サービス業が生産性向上に取り  
組む際の参考となるよう、｢中小サービス事業者の生産性向上のためのガイドライ  
ン｣を策定している。  

このガイドラインにおいては、生産性向上のための方向性を以下の2つに大別し  
ている。  
        
１．（　Ａ　）  
２．（　Ｂ　）  

その上で、｢（　Ａ　）｣を実現する手法として新規顧客層への展開など８項目を提示し、｢（　Ｂ　）｣を実現する手法として２項目を提示している。  

（設問２）  
文中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  価値や品質の見える化  
  
イ  効率の向上  
  
ウ  顧客満足度の向上  
  
エ  ブランド力の強化  
        
`
        },
        {
                "mondaiId": 107290180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
平成28年12月に公表された｢事業承継ガイドライン｣は、中小企業経営者の高齢  
化の進展等を踏まえ、円滑な事業承継の促進を通じた中小企業の事業活性化を図る  
ため、事業承継に向けた早期・計画的な準備の重要性や課題への対応策、事業承継  
支援体制の強化の方向性等について取りまとめたものである。  

このガイドラインでは、円滑な事業承継の実現のためには、５つのステップを経  
ることが重要である旨が明記されている。たとえば、｢親族内・従業員承継｣のケー  
スにおいて、５つのステップを見てみると、以下のとおりである。  

・事業承継に向けた５ステップ(親族内・従業員承継のケース)  

ステップ１　事業承継に向けた準備の必要性の認識  
↓  
ステップ２　（　Ａ　）  
↓  
ステップ３　（　Ｂ　）  
↓  
ステップ４　事業承継計画策定  
↓  
ステップ５　事業承継の実行  

上記ステップの空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：経営状況・経営課題等の把握　Ｂ：事業承継に向けた経営改善  
  
イ  Ａ：経営状況・経営課題等の把握　Ｂ：マッチング実施  
  
ウ  Ａ：後継者と事業承継に関する対話　Ｂ：事業承継に向けた経営改善  
  
エ  Ａ：後継者と事業承継に関する対話　Ｂ：マッチング実施  
        
`
        },
        {
                "mondaiId": 107290191,
                "name": "第19問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

｢革新的ものづくり・商業・サービス開発支援補助金｣は、国際的な経済社会情勢  
の変化に対応し、足腰の強い経済を構築するため、経営力向上に資する革新的サー  
ビス開発・試作品開発・生産プロセスの改善を行う中小企業・小規模事業者の設備  
投資等を支援するものである。  

この施策の支援対象は、認定支援機関の全面バックアップを得た事業を行う中小  
企業・小規模事業者であり、以下のいずれかに取り組む者である。  

｢中小サービス事業者の生産性向上のためのガイドライン｣で示された方法で行う  
革新的なサービスの創出・サービス提供プロセスの改善であり、Ａ年で、  
｢付加価値額｣年率Ｂ％および｢Ｃ｣年率  
％の向上を達成できる計  
画であること。  

または｢Ｄ｣に基づく特定ものづくり基盤技術を活用した革新的な試作品  
開発・生産プロセスの改善を行い、生産性を向上させる計画であること。  

（設問１）

文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：２~３　Ｂ：３  
  
イ  Ａ：２~３　Ｂ：５  
        
ウ  Ａ：３~５　Ｂ：３  
  
エ  Ａ：３~５　Ｂ：５  
        
        
`
        },
        {
                "mondaiId": 107290192,
                "name": "第19問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

｢革新的ものづくり・商業・サービス開発支援補助金｣は、国際的な経済社会情勢  
の変化に対応し、足腰の強い経済を構築するため、経営力向上に資する革新的サー  
ビス開発・試作品開発・生産プロセスの改善を行う中小企業・小規模事業者の設備  
投資等を支援するものである。  

この施策の支援対象は、認定支援機関の全面バックアップを得た事業を行う中小  
企業・小規模事業者であり、以下のいずれかに取り組む者である。  

｢中小サービス事業者の生産性向上のためのガイドライン｣で示された方法で行う  
革新的なサービスの創出・サービス提供プロセスの改善であり、Ａ年で、  
｢付加価値額｣年率Ｂ％および｢Ｃ｣年率  
％の向上を達成できる計  
画であること。  

または｢Ｄ｣に基づく特定ものづくり基盤技術を活用した革新的な試作品  
開発・生産プロセスの改善を行い、生産性を向上させる計画であること。  

（設問２）
文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：営業利益Ｄ：中小企業等経営強化法  
  
イ  Ｃ：営業利益Ｄ：中小ものづくり高度化法  
  
ウ  Ｃ：経常利益Ｄ：中小企業等経営強化法  
  
エ  Ｃ：経常利益Ｄ：中小ものづくり高度化法`
        },
        {
                "mondaiId": 107290200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
食品製造業を営む中小企業のA社は、他の中小企業者と連携して新たな事業活  
動を行いたいと計画している。A社の経営者から相談を受けた中小企業診断士のB  
氏は、｢新連携｣の支援施策を紹介することにした。  
新連携の支援に関するB氏の説明として、最も適切なものはどれか。  
  
ア  同じ分野の中小企業の連携でも、｢新たな事業活動｣であれば支援対象になりま  
す。  
  
イ  新規開業しておおむね10年以上の企業が対象になります。  
  
ウ  中小企業者の最低３者以上の連携が支援対象になります。  
  
エ  中小企業等経営強化法に基づく事業計画の認定を受けた企業が対象になりま  
す。  
        
`
        },
        {
                "mondaiId": 107290211,
                "name": "第21問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

日本酒製造業を営むＸ氏は、地域内の同業者と連携した海外展開プロジェクトを  
検討している。Ｘ氏から海外展開に関する相談を受けた中小企業診断士のＹ氏は、  
Ｘ氏に対して｢JAPANブランド育成支援事業｣を紹介することにした。以下は、Ｘ  
氏とＹ氏との会話である。  

Ｘ氏：｢JAPANブランド育成支援事業の支援対象について教えてください。｣  

Ｙ氏：｢この事業の対象となるのは、複数の中小企業者等です。Ａ以上の  
連携が支援対象となるための条件です。｣  

Ｘ氏：｢どのような支援を受けることができるのですか。｣  

Ｙ氏：｢支援内容は、「戦略策定段階への支援」と「ブランド確立段階への支援」の２段階に分かれています。今回のプロジェクト実現のためには、まずは基本的  
な戦略の構築が必要ですので、「戦略策定段階への支援」を検討してみてはい  
かがでしょうか。｣  

Ｘ氏：｢そうですね。基本的な戦略を構築するために、市場調査を実施してみたい  
と思っています。｣  

Ｙ氏：｢市場調査も支援対象になりますよ。市場調査など?戦略策定段階への支援?  
はＢで、Ｃ支援を受けることができます。｣  
        
（設問１） 

文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  ２者  
  
イ  ３者  
  
ウ  ４者  
  
エ  ５者
`
        },
        {
                "mondaiId": 107290212,
                "name": "第21問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

日本酒製造業を営むＸ氏は、地域内の同業者と連携した海外展開プロジェクトを  
検討している。Ｘ氏から海外展開に関する相談を受けた中小企業診断士のＹ氏は、  
Ｘ氏に対して｢JAPANブランド育成支援事業｣を紹介することにした。以下は、Ｘ  
氏とＹ氏との会話である。  

Ｘ氏：｢JAPANブランド育成支援事業の支援対象について教えてください。｣  

Ｙ氏：｢この事業の対象となるのは、複数の中小企業者等です。Ａ以上の  
連携が支援対象となるための条件です。｣  

Ｘ氏：｢どのような支援を受けることができるのですか。｣  

Ｙ氏：｢支援内容は、「戦略策定段階への支援」と「ブランド確立段階への支援」の２段階に分かれています。今回のプロジェクト実現のためには、まずは基本的  
な戦略の構築が必要ですので、「戦略策定段階への支援」を検討してみてはい  
かがでしょうか。｣  

Ｘ氏：｢そうですね。基本的な戦略を構築するために、市場調査を実施してみたい  
と思っています。｣  

Ｙ氏：｢市場調査も支援対象になりますよ。市場調査など?戦略策定段階への支援?  
はＢで、Ｃ支援を受けることができます。｣  
        
（設問２）   
文中の空欄ＢとＣに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｂ：３分の２補助　Ｃ：年間に限り  
  
イ  Ｂ：３分の２補助　Ｃ：最大３年間の  
  
ウ  Ｂ：定額補助　Ｃ：１年間に限り  
  
エ  Ｂ：定額補助　Ｃ：最大３年間の  
        
`
        },
        {
                "mondaiId": 107290221,
                "name": "第22問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のＸ氏は、情報処理サービス業を営むＹ氏から、｢自社の経営は  
健全だが、取引先の倒産という事態はいつ起こるかわからない。そのような不測の  
事態に備えたい。｣との相談を受けた。そこで、Ｘ氏はＹ氏に、｢経営セーフティ共  
済｣を紹介することとした。以下は、Ｘ氏とＹ氏との会話である。  

Ｘ氏：｢経営セーフティ共済は、中小企業倒産防止共済制度の愛称です。｣  

Ｙ氏：｢当社は、その共済制度に加入できますか。｣  

Ｘ氏：｢対象となる方は、Ａ以上継続して事業を行っている中小企業です  
ので、御社は対象になりますよ。｣  

Ｙ氏：｢具体的には、どのような支援を受けることができるのでしょうか。｣  

Ｘ氏：｢取引先企業が倒産し、売掛金や受取手形などの回収が困難となった場合、  
共済金の貸付けを受けることができます。貸付けにあたっては、  
Ｂ。共済金の貸付けは無利子ですが、貸付けを受けた共済金の  
Ｃに相当する額が掛金総額から控除されます。｣  

Ｙ氏：｢掛金について教えてください。｣  

Ｘ氏：｢掛金月額は5，000円から200，000円の範囲内で設定できます。加入後増額  
することもできますよ。掛金総額がＤまで積立てることができま  
す。毎年の掛金は損金に算入できます。｣  

Ｙ氏：｢それはいい制度ですね。さっそく、加入を検討したいと思います。｣  
        
（設問１）

文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：６か月　Ｂ：担保・保証人が必要になる場合もあります  
  
イ  Ａ：６か月　Ｂ：担保・保証人は必要ありません  
  
ウ  Ａ：１年　Ｂ：担保・保証人が必要になる場合もあります  
  
エ  Ａ：１年　Ｂ：担保・保証人は必要ありません`
        },
        {
                "mondaiId": 107290222,
                "name": "第22問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のＸ氏は、情報処理サービス業を営むＹ氏から、｢自社の経営は  
健全だが、取引先の倒産という事態はいつ起こるかわからない。そのような不測の  
事態に備えたい。｣との相談を受けた。そこで、Ｘ氏はＹ氏に、｢経営セーフティ共  
済｣を紹介することとした。以下は、Ｘ氏とＹ氏との会話である。  

Ｘ氏：｢経営セーフティ共済は、中小企業倒産防止共済制度の愛称です。｣  

Ｙ氏：｢当社は、その共済制度に加入できますか。｣  

Ｘ氏：｢対象となる方は、Ａ以上継続して事業を行っている中小企業です  
ので、御社は対象になりますよ。｣  

Ｙ氏：｢具体的には、どのような支援を受けることができるのでしょうか。｣  

Ｘ氏：｢取引先企業が倒産し、売掛金や受取手形などの回収が困難となった場合、  
共済金の貸付けを受けることができます。貸付けにあたっては、  
Ｂ。共済金の貸付けは無利子ですが、貸付けを受けた共済金の  
Ｃに相当する額が掛金総額から控除されます。｣  

Ｙ氏：｢掛金について教えてください。｣  

Ｘ氏：｢掛金月額は5，000円から200，000円の範囲内で設定できます。加入後増額  
することもできますよ。掛金総額がＤまで積立てることができま  
す。毎年の掛金は損金に算入できます。｣  

Ｙ氏：｢それはいい制度ですね。さっそく、加入を検討したいと思います。｣  
        
（設問２）
文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：10分の１　Ｄ：800万円  
  
イ  Ｃ：10分の１　Ｄ：1，000万円  
  
ウ  Ｃ：20分の１　Ｄ：800万円  
  
エ  Ｃ：20分の１　Ｄ：1，000万円  
        
`
        },
        {
                "mondaiId": 107290231,
                "name": "第23問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業等の法人税率は軽減されている。具体的には、法人税法の本則(期限の定めなし)において、年Ａ万円以下の所得金額についてＢ％に軽減されている。対象となるのは、資本金Ｃ億円以下の中小法人等である。  

国際的な経済環境の変化等により景気の先行きに不透明さが増す中、当該税率は時  
限的な措置として、租税特別措置でＤ％に軽減されている。

（設問１） 

文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：800　Ｂ：19  
  
イ  Ａ：800　Ｂ：25  
  
ウ  Ａ：1，000　Ｂ：19  
  
エ  Ａ：1，000　Ｂ：25`
        },
        {
                "mondaiId": 107290232,
                "name": "第23問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2017,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業等の法人税率は軽減されている。具体的には、法人税法の本則(期限の定めなし)において、年Ａ万円以下の所得金額についてＢ％に軽減されている。対象となるのは、資本金Ｃ億円以下の中小法人等である。  

国際的な経済環境の変化等により景気の先行きに不透明さが増す中、当該税率は時  
限的な措置として、租税特別措置でＤ％に軽減されている。


（設問２）  
文中の空欄ＣとＤに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：１　Ｄ：12  
  
イ  Ｃ：１　Ｄ：15  
  
ウ  Ｃ：３　Ｄ：12  
  
エ  Ｃ：３　Ｄ：15  
        
`
        },
        {
                "mondaiId": 101280010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本とアメリカの1990年以降の１人当たり実質GDPとGDPデフレー  
ターの推移を示している。図中のａ~ｄに該当する国の組み合わせとして、最も適  
切なものを下記の解答群から選べ。  

<imgTag>101280010_1.png</imgTag>  
出所：内閣府「経済財政白書」平成27年度版  
        

（解答群）  
  
ア  ａ：アメリカｂ：日本ｃ：アメリカｄ：日本  
  
イ  ａ：アメリカｂ：日本ｃ：日本ｄ：アメリカ  
  
ウ  ａ：日本ｂ：アメリカｃ：アメリカｄ：日本  
  
エ  ａ：日本ｂ：アメリカｃ：日本ｄ：アメリカ  
        
`
        },
        {
                "mondaiId": 101280020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
少子高齢化と経済のグローバル化が進む中で、わが国においても租税制度の体系  
的な見直しが進められている。下図は、1989年度以降のわが国の所得税、法人税、消費税の各税収額の推移を示したものであるが、税目名はａ、ｂ、ｃという形で伏  
せられている。これらａ、ｂ、ｃに関する記述として、最も適切なものを下記の解  
答群から選べ。  

<imgTag>101280020_1.png</imgTag>  
出所：財務省ホームページによる。ただし、2015年度の値は予測値である。  

（解答群）  
  
ア  ａは所得税であり、ｂは消費税である。  
  
イ  ａは所得税であり、ｃは消費税である。  
  
ウ  ａは法人税であり、ｂは消費税である。  
  
エ  ａは法人税であり、ｃは所得税である。  
        
`
        },
        {
                "mondaiId": 101280030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
グローバルな環境にある日本経済は、為替レートの変動によって大きな影響を受  
ける。下図は、縦軸の上方a下方cほど通貨の価値が高くなるa低くなるcようにし  
て、円a日本c、人民元a中国c、ドルaアメリカcの実質実効為替レートの推移（2010年＝100）を示したものである。図中のａ~ｃに該当する通貨の組み合わせとして、  
最も適切なものを下記の解答群から選べ。  

<imgTag>101280030_1.png</imgTag>  
出所：国際決済銀行ホームページによる。  

（解答群）  
  
ア  ａ：円ｂ：人民元ｃ：ドル  
  
イ  ａ：円ｂ：ドルｃ：人民元  
  
ウ  ａ：人民元ｂ：円ｃ：ドル  
  
エ  ａ：人民元ｂ：ドルｃ：円  
        
`
        },
        {
                "mondaiId": 101280040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　マクロの経済活動を表す指標に関する記述として、最も適切なものはどれか。  
  
ア  <hintTarget>国内</hintTarget>総生産には、<hintTarget>居住者</hintTarget>である外国人の所得は<hintTarget>含まれない</hintTarget>。  

<hint hidden='true'>**  
❌含まれます。もし<hintTarget>国民</hintTarget>総生産であれば含まれません。
**</hint>  

イ  国内総生産には、<hintTarget>農家の自家消費や持ち家の帰属家賃</hintTarget>は<hintTarget>含まれない</hintTarget>。  

<hint hidden='true'>**  
❌含まれます。
**</hint>  
  
ウ  市場価格表示の国民所得は、国民総所得から固定資本減耗を控除したものに等しい。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

エ  <hintTarget>要素費用</hintTarget>表示の国民所得と<hintTarget>市場価格</hintTarget>表示の国民純生産は一致す
る。

<hint hidden='true'>**  
❌一致しません。  
市場価格表示の国民所得と、  
市場価格表示の国民純生産　であれば一致する。
**</hint>  
  

`
        },
        {
                "mondaiId": 101280050,
                "name": "第5問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
日本銀行は、2013年１月に｢物価安定の目標｣として消費者物価の前年比上昇率  
を２％と定めた。これは消費者物価指数(CPI)によって測られる。消費者物価指数  
に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　消費者物価指数は、家計に直接影響する物価の変動を的確にとらえるために、  
消費者が購入するすべての商品を対象として価格を調査している。  
ｂ　消費者物価指数は、家計による消費支出を対象とするので、消費税などの間接  
税を含まない。  
ｃ　生鮮食品を除く総合指数による消費者物価指数は、｢コアCPI｣と呼ばれる。  
ｄ　総務省が作成する消費者物価指数はラスパイレス方式である。  

（解答群）  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 101280060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
賃金に関する考え方の１つに効率賃金理論がある。効率賃金理論に関する記述と  
して、最も適切なものはどれか。  
  
ア  企業が支払う効率賃金の下で完全雇用が実現すると主張する。  
  
イ  均衡賃金に等しい水準の賃金を支払うことが企業の効率的な生産につながると  
主張する。  
  
ウ  均衡賃金を超える水準の賃金を支払うことが生産性を高め、企業の利潤を増や  
すと主張する。  
  
エ  均衡賃金を下回る水準の賃金を支払うことが生産性を高め、企業の利潤を増や  
すと主張する。`
        },
        {
                "mondaiId": 101280070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
デフレーションからの脱却は、日本経済が抱える長年の課題である。デフレーシ  
ョンが経済に及ぼす影響として、最も適切なものはどれか。  
  
ア  デフレーションは、実質利子率を低下させる効果をもち、投資を刺激する。  
  
イ  デフレーションは、賃借契約における負債額の実質価値を低下させるので、債  
務を抑制する。  
  
ウ  デフレーションは、保有資産の実質価値の増加を通じて、消費を抑制する。  
  
エ  デフレーションは、名目賃金が財・サービスの価格よりも下方硬直的である場  
合には実質賃金を高止まりさせる。  
        
`
        },
        {
                "mondaiId": 101280081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `

　財市場における総需要Aが以下のように定式化されている。  
<img src="assets/img/toku/101280081_1.svg" style='height: auto;width: 30em;'>     
ここで、消費Cを以下のように定式化する。  
<img src="assets/img/toku/101280081_2.svg" style='height: auto;width: 30em;'>     
        
このとき、総需要は<hintTarget> <img src="assets/img/toku/101280081_3.svg" style='height: 1em;width: auto;'> </hintTarget>と書き改めることができ、総需要線として下図の実線AAのように描くことができる。  

　下図の45度線（Ｙ＝Ａ）は、財市場で需要と供給が一致する均衡条件を示しており、実線AAとの交点Eによって均衡所得が与えられる。なお、簡便化のために、<hintTarget>限界消費性向cは0.8</hintTarget>であると仮定する。  
　このような状況をもとに、下記の設問に答えよ。  
<imgTag>101280081_4.png</imgTag>   
        

        
（設問1）  
　政府支出乗数と租税乗数の値として、最も適切なものはどれか。  
   
<hint hidden='true'>**  
政府支出乗数の導出：  
<imgTag>101280081_5.png</imgTag>    
政府支出乗数は、政府支出Gの増分と比した、所得Yの増分です。  
以上の式から、政府支出Gが増加した場合、所得YがGの増加分の1／（１－ｃ）倍だけ増加することを表しているため、
　1／（１－ｃ）
が政府支出乗数であり、ｃに0.8を代入して5となります。
**</hint>

   
<hint hidden='true'>**  
租税乗数の導出：  
<imgTag>101280081_6.png</imgTag>    
租税乗数は、税金の増分と比したYの増分であるため、上記の式のうち
－ｃ／（１－ｃ）
の部分が租税乗数となり、cに0.8を代入して4となります。
**</hint>


ア  政府支出乗数と租税乗数はともに４である。  
  
イ  政府支出乗数と租税乗数はともに５である。  
  
ウ  政府支出乗数は５、租税乗数は４である。<hint hidden='true'>**  
←⭕
** </hint>  
  
エ  政府支出乗数は８、租税乗数は２である。

`
        },
        {
                "mondaiId": 101280082,
                "name": "第8問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　財市場における総需要Aが以下のように定式化されている。  
<img src="assets/img/toku/101280081_1.svg" style='height: auto;width: 30em;'>    
ここで、消費Cを以下のように定式化する。  
<img src="assets/img/toku/101280081_2.svg" style='height: auto;width: 30em;'>     
        
このとき、総需要は <img src="assets/img/toku/101280081_3.svg" style='height: 1em;width: auto;'> と書き改めることができ、総需要線として下図の実線AAのように描くことができる。  

　下図の45度線（Ｙ＝Ａ）は、財市場で需要と供給が一致する均衡条件を示しており、実線AAとの交点Eによって均衡所得が与えられる。なお、簡便化のために、限界消費性向cは0.8であると仮定する。  
　このような状況をもとに、下記の設問に答えよ。  
<imgTag>101280082_1.png</imgTag>    
                

（設問２）  
　いま、他の条件を一定として、I＋Gの値が外生的に増加し、図中の実線AAが破線BBへシフトし、点Fで均衡するものとする。このとき、均衡所得の変化量として、最も適切なものはどれか。  
  
<hint hidden='true'>**  
設問１より、政府支出乗数は５なので、  
Yの増分＝<hintTarget>政府支出乗数×（I＋Gの増分）＝５×５</hintTarget>＝２５
** </hint>  

ア  4  
  
イ  10  
  
ウ  25<hint hidden='true'>**
←⭕
** </hint>    
  
エ  40
`
        },
        {
                "mondaiId": 101280090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
経済成長のためには、企業による投資の増加が必要である。投資の変化を説明す  
る伝統的な理論に加速度原理がある。加速度原理の説明として、最も適切なものは  
どれか。  
  
ア  生産量が一定のとき、投資は増加する。  
  
イ  生産量の増加が拡大傾向にあるとき、投資は増加する。  
  
ウ  生産量の増加の度合いが小さくなるとき、投資は増加に転じる。  
  
エ  生産量の変化は、在庫の調整によって吸収されるため、投資に影響を与えな  
い。  
        
`
        },
        {
                "mondaiId": 101280100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
財政制度の改正にはそれぞれの目的があるが、これは同時に、財政のビルトイ  
ン・スタビライザーの機能にも影響する。財政のビルトイン・スタビライザーの機  
能に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ子育て支援における低所得者向けの給付の拡充は、ビルトイン・スタビライザ  
ーの機能を低下させる。  
ｂ所得税における最高税率の引き上げは、ビルトイン・スタビライザーの機能を  
高める。  
ｃ生活保護における生活扶助費の引き下げは、ビルトイン・スタビライザーの機  
能を高める。  
ｄ失業者に対する失業給付の拡充は、ビルトイン・スタビライザーの機能を高め  
る。  

（解答群）  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101280111,
                "name": "第11問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
財政・金融政策の効果を理解するためには、IS-LM分析が便利である。IS曲線  
とLM曲線が下図のように描かれている。下記の設問に答えよ。  

<imgTag>101280111_1.png</imgTag>  

（設問１）

IS曲線とLM曲線の傾きに関する説明として、最も適切なものはどれか。  
  
ア  IS曲線は、限界消費性向が大きいほど、より緩やかに描かれる。  
  
イ  LM曲線は、貨幣の利子弾力性が小さいほど、より緩やかに描かれる。  
  
ウ  利子率が高くなるほど貨幣需要が拡大すると考えており、したがってLM  
曲線は右上がりとなる。  
  
エ  利子率が高くなるほど投資需要が拡大すると考えており、したがってIS曲  
線は右下がりとなる。`
        },
        {
                "mondaiId": 101280112,
                "name": "第11問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
財政・金融政策の効果を理解するためには、IS-LM分析が便利である。IS曲線  
とLM曲線が下図のように描かれている。下記の設問に答えよ。  

<imgTag>101280111_1.png</imgTag>  

（設問２）
IS曲線をISからIS′へとシフトさせる要因として、最も適切なものはどれか。  
  
ア  外国人観光客の増加による消費の増加  
  
イ  歳出削減による財政健全化  
  
ウ  量的緩和策によるマネタリーベースの増加  
  
エ  老後の生活に備えるための貯蓄の増加  
        
`
        },
        {
                "mondaiId": 101280120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、正常財と考えられる医療に対する需要曲線が下図のように描けるものとす  
る。現状は実線で描かれている需要曲線上の点Aであり、同一の需要曲線上には  
点Cもある。また、破線の需要曲線上には点Bが描かれている。この図を用いて  
需要の変化の仕方を考察した記述として、最も適切なものを下記の解答群から選  
べ。  


<imgTag>101280120_1.png</imgTag>  

（解答群）  
  
ア  医療の価格を引き上げたとき、医療に対する需要は、点Aから点Bまで移  
動すると考えられる。  
  
イ  医療費の抑制が必要であることを需要者に説得できたとすれば、たとえ価格  
が変化しなくとも、医療に対する需要は、点Aから点Bまで移動すると考え  
られる。  
  
ウ  需要者の所得が増加すれば、医療に対する需要は、点Aから点Cまで移動  
すると考えられる。  
  
エ  需要者の所得が増加すれば、たとえ価格が変化しなくとも、医療に対する需  
要は、点Aから点Bまで移動すると考えられる。  
        
`
        },
        {
                "mondaiId": 101280130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図では、需要の価格弾力性が１より小さい農産物の需要曲線Dが実線で描か  
れている。また、当該農産物の供給曲線は破線で描かれており、好天に恵まれるな  
どの外生的な理由によって、供給曲線が当初のS0からS1へシフトしたものとす  
る。この図に関する説明として、最も適切なものを下記の解答群から選べ。  

<imgTag>101280130_1.png</imgTag>  


（解答群）  
  
ア  供給曲線の右へのシフトは、価格の低下による需要量の増加はあるものの、  
生産者の総収入を減少させる。  
  
イ  供給曲線の右へのシフトは、価格の低下による需要量の増加を通じて、生産  
者の総収入を増加させる。  
  
ウ  供給曲線の右へのシフトは、価格を低下させるものの、需要量には影響を与  
えない。  
  
エ  供給曲線の右へのシフトは、価格を低下させるものの、生産者の総収入には  
影響を与えない。  
        
`
        },
        {
                "mondaiId": 101280140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、相対的に緩い傾斜の需要曲線が破線で描かれ、相対的に急な傾斜の供  
給曲線が実線で描かれている。これら需要曲線と供給曲線の交点は、点Eとして  
与えられている。この図に関する説明として、最も適切なものを下記の解答群から  
選べ。  


<imgTag>101280140_1.png</imgTag>  

（解答群）  
  
ア  供給曲線が右下がりであるため、ワルラス的調整を通じて点Eへ収束する  
力は働かない。  
  
イ  供給曲線の傾きが相対的に急であるため、｢蜘蛛の巣理論｣による調整を通じ  
て点Eへ収束する力は働かない。  
  
ウ  交点よりも価格が高いとき、需要量よりも供給量が多いため、価格調整を通  
じて点Eへ収束する力が働く。  
  
エ  交点よりも数量が少ないとき、供給価格が需要価格よりも高いため、マーシ  
ャル的な数量調整を通じて点Eへ収束する力が働く。  
        
`
        },
        {
                "mondaiId": 101280150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある個人が限られた所得を有しており、財X1と財X2を購入することができる。  
下図には、同一の所得にもとづいて、実線の予算制約線Aと破線の予算制約線B  
とが描かれている。また、予算制約線Aと点Eで接する無差別曲線と、予算制約  
線Bと点Fで接する無差別曲線も描かれている。下図に関する記述として、最も  
適切なものを下記の解答群から選べ。  


<imgTag>101280150_1.png</imgTag>  

（解答群）  
  
ア  等しい所得の下で予算制約線が描かれているので、点Eと点Fから得られ  
る効用水準は等しい。  
  
イ  予算制約線Aと予算制約線Bを比較すると、予算制約線Bの方が、財X2  
の価格が高いことを示している。  
  
ウ  予算制約線Aと予算制約線Bを比較すると、予算制約線Bの方が、実質所  
得が高いことを示している。  
  
エ  予算制約線Aと予算制約線Bを比較すると、両財の相対価格が異なること  
が示されている。  
        
`
        },
        {
                "mondaiId": 101280160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、ある合理的個人が、限られた所得の下で２つの財aX，Ycを需要する状況
を考える。２つの財の需要量は、それぞれDXおよびDYと表記し、財Xの価格をPXと表記する。  

下図は、予算制約線１と無差別曲線１が点Aで接する状況から、他の条件を一定としてPXのみが下落し、予算制約線２と無差別曲線２が点Bで接する状況へと変化した様子を描いたものである。この図に関する記述として、最も適切なものの  
組み合わせを下記の解答群から選べ。  



<imgTag>101280160_1.png</imgTag>  


        
ａ　財Yの価格を一定としてPXが下落したとき、代替効果でDXが増加した。  
同時に、PXの下落は、所得効果によってDXを減少させた。上図では、代替効果よりも所得効果が大きいため、財Xはギッフェン財の性質を示している。  

ｂ　財Yの価格を一定としてPXが下落したとき、代替効果でDXが増加した。  
同時に、PXの下落は、所得効果によってもDXを増加させた。上図では、代替効果と所得効果がともにDXを増加させていることから、財Xはギッフェン財の性質を示している。  

ｃ　財Yの価格を一定としてPXが下落したとき、DYが増加した。これは、財Yが財Xの粗代替財であることを示している。  

ｄ　財Yの価格を一定としてPXが下落したとき、DYが増加した。これは、財Yが財Xの粗補完財であることを示している。  

（解答群）  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101280170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、完全競争下にある合理的な企業の生産活動を考える。当該企業が生産活動  
で考慮する私的限界費用MCPは下図のように描くことができるものとし、価格が  
kであるものとして生産量を決定している。  

ただし、当該企業の生産ではいわゆる｢負の外部性｣が生じている。負の外部性を  
考慮した社会的限界費用MCSは、私的限界費用に社会的負担を加えたものとして  
下図のように描くことができる。当該企業は、外部性を考慮することなく、価格k  
と私的限界費用が一致する生産量を選択するが、社会的に最適な生産量は価格kと  
社会的限界費用が一致する生産量であるため、社会的には過剰生産による厚生損失  
(デッドウエイトロス)が生じてしまう。  

このとき、下図に関する記述として、最も適切なものの組み合わせを下記の解答  
群から選べ。  
<imgTag>101280170_1.png</imgTag>          
        
ａ　外部性を考慮しない当該企業の私的な生産費用の大きさは、△oikで示される面積に相当する。  

ｂ　外部性を考慮しない当該企業の私的な生産者余剰の大きさは、△ojkで示される面積に相当する。  

ｃ　外部性によって生じるデッドウエイトロスは、△ohjで示される面積に相当する。  

ｄ　外部性によって生じるデッドウエイトロスは、△hijで示される面積に相当する。  

（解答群）  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ`
        },
        {
                "mondaiId": 101280180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
地球温暖化を防止するためには、二酸化炭素(CO2)などの温室効果ガスの排出量  
を削減することが必要である。CO2の排出量を削減するためのインセンティブをよ  
り高める手段として、<u>最も不適切なものはどれか</u>。  
  
ア  企業が生産に伴ってCO2を排出することに着目して、CO2排出企業に減産  
補助金を与えること。  
  
イ  企業が生産に伴ってCO2を排出することに着目して、CO2排出企業に対し  
てCO2排出量に応じた課税をすること。  
  
ウ  私たちの生活における車の利用がCO2を排出することに着目して、ガソリ  
ンの消費に対して課税をすること。  
  
エ  私たちの日常の生活がCO2を排出することに着目して、すべての人に  
人  
当たり定額の課税をすること。  
        
`
        },
        {
                "mondaiId": 101280190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、AさんとBさんだけが存在し、それぞれコメと豚肉のみが生産可能な世  
界を考える。下表は、AさんとBさんが、ある定められた時間Tのすべてを一方  
の生産に振り向けた場合に生産可能な量を示している。また、下表にもとづく2人  
の生産可能性フロンティアは、下図にある右下がりの直線のように描けるものと  
し、AさんとBさんは、自らの便益を高めるために生産可能性フロンティア上に  
ある生産量の組み合わせを選択する。  

このような状況を説明する記述として、最も適切なものを下記の解答群から選  
べ。  

<imgTag>101280190_1.png</imgTag>  
        
        
（解答群）  
  
ア  Aさんは、いずれの財の生産においても、Bさんに対して比較優位を有する  
ために、Bさんとの生産物の交換から便益を得ることができない。  
  
イ  Aさんは、いずれの財を生産するにせよBさんよりも生産性が高く、絶対  
優位を有するために、Bさんとの生産物の交換から便益を得ることができな  
い。  
  
ウ  比較優位性を考慮すると、Aさんはコメの生産に、Bさんは豚肉の生産にそ  
れぞれ特化し、相互に生産財を交換し合うことで、双方が同時に便益を高める  
ことができる。  
  
エ  豚肉の生産について、AさんはBさんに対して比較優位を有する。  
        
`
        },
        {
                "mondaiId": 101280200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、ある1つの投入要素のみを使って、1つの生産財を生産する企業を考える。この企業の生産活動を規定する生産関数は、下図のような形状をしているものとし、要素投入量はゼロより大きい。下図に関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。なお、ある要素投入量Xに対する生産量がYであるとき、Y／Xを｢平均生産物｣と呼び、ある要素投入量に対応する生産関数の接線の傾きを｢限界生産物｣と呼ぶこととする。  

<imgTag>101280200_1.png</imgTag>  

ａ　平均生産物の大きさは、要素投入量が増えるほど小さくなる。  

ｂ　限界生産物の大きさは、要素投入量には依存しない。  

ｃ　どの要素投入量においても、平均生産物の大きさは、限界生産物の大きさよりも大きい。  

ｄ　要素投入量がある程度まで大きくなると、限界生産物の大きさは、平均生産物の大きさよりも大きくなる。  

（解答群）  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101280210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、１つの要素を用いて１つの生産財を生産するものとする。要素投入量をx とし、その単位当たりの購入価格をwとする。また、生産財の生産量をyとし、  
その１単位当たりの販売価格をpとする。このとき利潤πは、π＝py－wxと書くことができ、y＝π/p　＋　 wx/pと書き直すこともできる。  

以下にある４つの図は、縦軸を生産量、横軸を要素投入量として、一般的な生産関数を実線で描き、上記で導いたy＝π/p　＋　 wx/pを破線で書き加えたものである。  

企業の利潤最大化が実現することを示す図として、最も適切なものを下記の解答  
群から選べ。  

<imgTag>101280210_1.png</imgTag>  

（解答群）  
  
ア  図A  
  
イ  図B  
  
ウ  図C  
  
エ  図D  
        
`
        },
        {
                "mondaiId": 101280220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
多くの地方自治体が、地域活性化の手段として、企業誘致に取り組んでいる。企  
業の市場への参入や立地は、企業の費用構造や他の企業との関係性と密接な関連を  
もつ。企業行動に関する記述として、最も適切なものの組み合わせを下記の解答群  
から選べ。  
ａ収穫逓減産業では、限界生産力が低下するので、範囲の経済のメリットを享受  
しうる。  
ｂ収穫逓増産業では、生産規模の拡大を通じて規模の経済のメリットを享受しう  
る。  
ｃ企業が集中して立地することにより集積の経済のメリットを享受しうる。  
ｄ費用逓減産業は、長期平均費用が低くなるので、中小企業にとって参入が容易  
である。  
（解答群）  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101280230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図では、利潤最大化を目指す合理的な企業が直面する寡占市場を念頭におい  
て、点Eで屈曲する｢屈折需要曲線｣DEFが描かれている。この需要曲線のDE部  
分に対応する限界収入曲線が線分LM、EF部分に対応する限界収入曲線が線分  
RSである。いま、当該市場でq1の生産量を選択していた企業の限界費用曲線MC1  
がMC2へシフトしたものとする。下図に関する記述として、最も適切なものを下  
記の解答群から選べ。  

<imgTag>101280230_1.png</imgTag>  



（解答群）  
  
ア  限界費用曲線がMC2へシフトしたことにより生産量をq1からq2へ増加させ  
る。  
  
イ  限界費用曲線がMC2へシフトしたことにより生産量をq1からq3へ増加させ  
る。  
  
ウ  限界費用曲線がMC2へシフトしても、価格は変わらない。  
  
エ  限界費用曲線がMC2へシフトすると、価格をp1からp2へ引き下げる。  
`
        },
        {
                "mondaiId": 102280010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
６月のA商品に関する仕入および売上は以下のとおりである。先入先出法を採  
用しているとき、６月の売上原価として最も適切なものを下記の解答群から選べ。  

　　　　　　　　　数量　単価  
6月1日　前月繰越　10個　200円  
　　3日　仕入　　　50個　190円  
　　5日　売上　　　30個　300円  
　　11日　仕入　　　10個　210円  
　　20日　売上　　　20個　300円  
　　24日　仕入戻し　5個　210円  
　　30日　次月繰越　15個  


（解答群）  
  
ア  2，950円  
  
イ  8，650円  
  
ウ  9，600円  
  
エ  15，000円  
        
`
        },
        {
                "mondaiId": 102280020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
売上控除と<u>ならない</u>項目として最も適切なものはどれか。  
  
ア  売上値引  
  
イ  売上戻り  
  
ウ  売上割引  
  
エ  売上割戻`
        },
        {
                "mondaiId": 102280030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
のれんに関する記述として最も適切なものはどれか。  
  
ア  ｢中小企業の会計に関する指針｣では、のれんの償却を行わないとしている。  
  
イ  のれんとは、被合併会社から受け継ぐ総資産額が被合併会社の株主に交付され  
る金額よりも大きいときに計上される。  
  
ウ  のれんの償却期間は最長5年である。  
  
エ  のれんはマイナスの金額になることもあり、その場合、発生時の損益計算書に  
特別利益として計上される。  
        
`
        },
        {
                "mondaiId": 102280040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
新規のファイナンス・リースに関する記述として最も適切なものはどれか。  
  
ア  貸し手にとっては資産の販売とみなされる取引である。  
  
イ  借り手にとっては原則として賃借取引であり、費用計上による節税効果を期待できる。  
  
ウ  支払リース料は損益計算書に販売費・一般管理費として計上される。  
  
エ  少額の違約金を支払えば、途中解約することができる。  
        
`
        },
        {
                "mondaiId": 102280051,
                "name": "第5問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料に基づいて、下記の設問に答えよ。  

【資料】  
(期首)純資産の部(単位：千円)  
<imgTag>102280051_1.png</imgTag>  

期中取引(発生順)  
１．増資にあたり、株式300株を１株当たり70千円の価格で発行し、払込金は当座預金とした。  
なお、会社法が定める最低額を資本金とした。また、株式募集のための費用150千円を小切手を振出して支払った。  

２．株主総会が開催され、繰越利益剰余金の分配を次のように決定した。  
　①利益準備金会社法が定める最低額  
　②配当金800千円  
　③別途積立金180千円  
        
（設問１）    
期中取引が終わった時点の資本金の金額として、最も適切なものはどれか。  
  
ア  90，500千円  
  
イ  90，650千円  
  
ウ  101，000千円  
  
エ  101，150千円`
        },
        {
                "mondaiId": 102280052,
                "name": "第5問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の資料に基づいて、下記の設問に答えよ。  

【資料】  
(期首)純資産の部(単位：千円)  
<imgTag>102280051_1.png</imgTag>  

期中取引(発生順)  
１．増資にあたり、株式300株を１株当たり70千円の価格で発行し、払込金は当座預金とした。  
なお、会社法が定める最低額を資本金とした。また、株式募集のための費用150千円を小切手を振出して支払った。  

２．株主総会が開催され、繰越利益剰余金の分配を次のように決定した。  
　①利益準備金会社法が定める最低額  
　②配当金800千円  
　③別途積立金180千円  
        
（設問2）    
期中取引が終わった時点の繰越利益剰余金の金額として、最も適切なものはど  
れか。  
  
ア  120千円  
  
イ  140千円  
  
ウ  184千円  
  
エ  220千円  
        
`
        },
        {
                "mondaiId": 102280060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
原価計算基準上の原価に関する記述として最も適切なものはどれか。  
  
ア  原価には盗難による損失も含められる。  
  
イ  財務諸表の表示上、全部原価のみが認められている。  
  
ウ  実際原価は実際に発生した原価であって、予定価格が使われることはない。  
  
エ  総原価とは製造原価の合計額のことをいう。  
        
`
        },
        {
                "mondaiId": 102280070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当月の直接材料に関するデータは以下のとおりであった。このとき価格差異とし  
て最も適切なものはどれか。  
　　　消費数量　　価格  
実際　820kg　　　490円／kg  
標準　800kg　　　500円／kg  
  
ア  8，000円の有利差異  
  
イ  8，200円の有利差異  
  
ウ  9，800円の不利差異  
  
エ  10，000円の不利差異  
        
`
        },
        {
                "mondaiId": 102280081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料に基づいて、下記の設問に答えよ。 

【資料】
<imgTag>102280081_1.png</imgTag>   
販売単価1，000円単位当たり変動費600円  　１期当たり固定費33，000円  

（設問１）
資料に関する説明の組み合わせとして、最も適切なものを下記の解答群から選  
べ。  

ａ　全部原価計算を採用した場合、第１期と第２期の営業利益は同じである。  

ｂ　第１期では、全部原価計算を採用した場合の営業利益の方が、直接原価計算を採用した場合よりも大きい。  

ｃ　第２期では、全部原価計算を採用した場合の営業利益の方が、直接原価計算を採用した場合よりも大きい。  

ｄ　直接原価計算を採用した場合、第１期と第２期の営業利益は同じである。  


（解答群）  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｄ  
  
エ  ｃとｄ  

`
        },
        {
                "mondaiId": 102280082,
                "name": "第8問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の資料に基づいて、下記の設問に答えよ。 

【資料】
<imgTag>102280081_1.png</imgTag>   
販売単価1，000円単位当たり変動費600円  　１期当たり固定費33，000円  

（設問２）第２期の損益分岐点比率として最も適切なものはどれか。  
  
ア  17.5％  
  
イ  45.0％  
  
ウ  55.0％  
  
エ  82.5％  
        
`
        },
        {
                "mondaiId": 102280091,
                "name": "第9問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の貸借対照表と損益計算書について、下記の設問に答えよ。  


<imgTag>102280091_1.png</imgTag>   

        
（設問１）

キャッシュ・フロー計算書上の表示として最も適切なものはどれか。  
  
ア  売上債権の増加額　△35，000千円  
  
イ  減価償却費　△10，000千円  
  
ウ  固定資産の増加額　125，000千円  
  
エ  仕入債務の増加額　△20，000千円`
        },
        {
                "mondaiId": 102280092,
                "name": "第9問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の貸借対照表と損益計算書について、下記の設問に答えよ。  


<imgTag>102280091_1.png</imgTag>   

        
（設問２）  
財政状態に関する記述として最も適切なものはどれか。  
  
ア  固定比率は改善している。  
  
イ  自己資本比率は改善している。  
  
ウ  正味運転資本は減少している。  
  
エ  流動比率は悪化している。  
        
`
        },
        {
                "mondaiId": 102280100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
直接金融と間接金融に関する記述として最も適切なものはどれか。  
  
ア  ある企業���増資に応じて、個人投資家が証券会社を通して株式を取得したと  
き、その企業にとっては直接金融となる。  
  
イ  銀行が株式の発行を行った場合は間接金融となる。  
  
ウ  金融庁は、｢貯蓄から投資へ｣というスローガンの下、直接金融の割合を減らし  
間接金融の割合を増やすことを目指している。  
  
エ  社債の発行による資金調達は、借入金による資金調達と同じ負債の調達であ  
り、間接金融である。  
        
`
        },
        {
                "mondaiId": 102280110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　リスク中立的な投資家の効用関数のグラフとリスク回避的な投資家の効用関数のグラフの組み合わせとして、最も適切なものを下記の解答群から選べ。  

<imgTag>102280110_1.png</imgTag>  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】
<imgTag>102280110_2.png</imgTag>  
</div>
</hint>

[解答群]  
  
ア  リスク中立的な投資家：①　リスク回避的な投資家：②<hint hidden='true'>**
←⭕リスク中立的な投資家は、リスクの増分に対して、求めるリターンの増分も一定になります。
**</hint>    
  
イ  リスク中立的な投資家：①　リスク回避的な投資家：③  
  
ウ  リスク中立的な投資家：①　リスク回避的な投資家：④  
  
エ  リスク中立的な投資家：③　リスク回避的な投資家：②  
  
オ  リスク中立的な投資家：③　リスク回避的な投資家：④  
        
`
        },
        {
                "mondaiId": 102280121,
                "name": "第12問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
資本資産評価モデル(CAPM)に関する下記の設問に答えよ。  

（設問１）  

資本資産評価モデルを前提とした場合の記述として、最も適切なものはどれ  
か。  
  
ア  β＝－1である資産を安全資産と呼ぶ。  
  
イ  β＝1であるリスク資産の期待収益率は、市場ポートフォリオの期待収益率と同じである。  
  
ウ  β＝2であるリスク資産の予想収益率の分散は、β＝1であるリスク資産の予想収益率の分散の２倍である。  
  
エ  市場ポートフォリオのリターンが正のとき、β＝0.5であるリスク資産の価格が下落することはない。  
        
`
        },
        {
                "mondaiId": 102280122,
                "name": "第12問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `                
資本資産評価モデル(CAPM)に関する下記の設問に答えよ。  

（設問２）  

資本資産評価モデルを前提とした場合、以下の資料に基づく株式の期待収益率として最も適切なものを、下記の解答群から選べ。  

【資料】  
　市場ポートフォリオの期待収益率：8％  
　無リスク資産の期待収益率：3％  
　β：1.4  
　実効税率：40％  

（解答群）  
  
ア  4.4％  
  
イ  7％  
  
ウ  10％  
  
エ  11.2％  
        
`
        },
        {
                "mondaiId": 102280130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業買収に関する略語の説明として最も適切なものはどれか。  
  
ア  KPIとは、同業他社の株価を参照することを通じて買収価格を決定したうえ  
で、経営権の取得等を行うことである。  
  
イ  LBOとは、従業員が資金を出し合って、経営権の取得等を行うことである。  
  
ウ  MBOとは、金融機関が自身の資金によって経営権の取得等を行うことであ  
る。  
  
エ  TOBとは、不特定多数の者に対し、証券市場以外の場における株券の買付け  
等の勧誘を通じて経営権の取得等を行うことである。  
        
`
        },
        {
                "mondaiId": 102280141,
                "name": "第14問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
加重平均資本コスト(WACC)の計算手順に関する次の記述について、下記の設  
問に答えよ。  

加重平均資本コストは、株主資本(自己資本)コストと他人資本コストを、その  
Ａに応じて加重平均することで求められる。加重平均に用いるのは、理論  
的にはそれぞれのＢである。  

また、他人資本コストにはＣを考慮する必要がある。具体的には、他人  
資本コストにＤを乗じることで、Ｃを考慮した他人資本コストを  
求める。  

（設問１）  

記述中の空欄ＡおよびＢにあてはまる語句の組み合わせとして最も適切なもの  
はどれか。  
  
ア  Ａ：運用形態Ｂ：時価  
  
イ  Ａ：運用形態Ｂ：簿価  
  
ウ  Ａ：資本構成Ｂ：時価  
  
エ  Ａ：資本構成Ｂ：簿価  
  
オ  Ａ：調達源泉Ｂ：簿価`
        },
        {
                "mondaiId": 102280142,
                "name": "第14問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
加重平均資本コスト(WACC)の計算手順に関する次の記述について、下記の設  
問に答えよ。  

加重平均資本コストは、株主資本(自己資本)コストと他人資本コストを、その  
Ａに応じて加重平均することで求められる。加重平均に用いるのは、理論  
的にはそれぞれのＢである。  

また、他人資本コストにはＣを考慮する必要がある。具体的には、他人  
資本コストにＤを乗じることで、Ｃを考慮した他人資本コストを  
求める。  

（設問２）  
記述中の空欄ＣおよびＤにあてはまる語句の組み合わせとして最も適切なもの  
はどれか。  
  
ア  Ｃ：節税効果Ｄ：1安限界税率  
  
イ  Ｃ：節税効果Ｄ：限界税率  
  
ウ  Ｃ：レバレッジ効果Ｄ：1安限界税率  
  
エ  Ｃ：レバレッジ効果Ｄ：1＋限界税率  
  
オ  Ｃ：レバレッジ効果Ｄ：限界税率  
        
`
        },
        {
                "mondaiId": 102280151,
                "name": "第15問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
現在、３つの投資案(投資案A~投資案C)について検討している。各投資案の収  
益率は、景気や為替変動などによって、パターン①~パターン④の４つのパターン  
になることが分かっており、パターンごとの予想収益率は以下の表のとおりであ  
る。なお、この予想収益率は投資額にかかわらず一定である。また、各パターンの  
生起確率はそれぞれ25％と予想している。  

<imgTag>102280151_1.png</imgTag>  

（設問１）
投資案Aおよび投資案Bの予想収益率の共分散と相関係数の組み合わせとして最も適切なものはどれか。  
  
ア  共分散：－15　相関係数：－0.95  
  
イ  共分散：－15　相関係数：　0.95  
  
ウ  共分散：　15　相関係数：－0.95  
  
エ  共分散：　15　相関係数：　0.95  
        
`
        },
        {
                "mondaiId": 102280152,
                "name": "第15問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
現在、３つの投資案(投資案A~投資案C)について検討している。各投資案の収  
益率は、景気や為替変動などによって、パターン①~パターン④の４つのパターン  
になることが分かっており、パターンごとの予想収益率は以下の表のとおりであ  
る。なお、この予想収益率は投資額にかかわらず一定である。また、各パターンの  
生起確率はそれぞれ25％と予想している。  

<imgTag>102280151_1.png</imgTag>  

（設問２）
投資案Aおよび投資案Cに関する記述として最も適切なものはどれか。  
  
ア  投資案Aと投資案Cに半額ずつ投資する場合も、投資案Cのみに全額投資  
する場合も、予想収益率の分散は同じである。  
  
イ  投資案Aの予想収益率と投資案Cの予想収益率の相関係数は2である。  
  
ウ  投資案Cの予想収益率の期待値は64％である。  
  
エ  投資案Cの予想収益率の標準偏差は、投資案Aの予想収益率の標準偏差の2倍である。`
        },
        {
                "mondaiId": 102280160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
1年後の配当は105千円、その後毎年3％の成長が永続することを見込んでい  
る。割引率-株主資本コスト/が年5％である場合、配当割引モデルに基づく企業価  
値の推定値として最も適切なものはどれか。  
  
ア  1，575千円  
  
イ  2，100千円  
  
ウ  3，500千円  
  
エ  5，250千円  
        
`
        },
        {
                "mondaiId": 102280170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
現在、３つのプロジェクト(プロジェクト①~プロジェクト③)の採否について検討している。各プロジェクトの初期投資額、第１期末から第３期末に生じるキャッシュフロー、および内部収益率(IRR)は以下の表のとおり予測されている。いずれのプロジェクトも、経済命数は３年である。初期投資は第１期首に行われる。なお、法人税は存在しないと仮定する。  
<imgTag>102280170_1.png</imgTag>  

内部収益率法を用いた場合のプロジェクトの順位づけとして、最も適切なものを下記の解答群から選べ。たとえば、プロジェクト①＞プロジェクト②は、プロジェクト①の優先順位が高いことを示す。なお、内部収益率の計算にあたっては、以下の表を用いること。  

経済命数が３年の場合の複利現価係数および年金現価係数  
<imgTag>102280170_2.png</imgTag>          
        
（解答群）  
  
ア  プロジェクト①＞プロジェクト②＞プロジェクト③  
  
イ  プロジェクト①＞プロジェクト③＞プロジェクト②  
  
ウ  プロジェクト②＞プロジェクト①＞プロジェクト③  
  
エ  プロジェクト②＞プロジェクト③＞プロジェクト①  
  
オ  プロジェクト③＞プロジェクト①＞プロジェクト②  
        
`
        },
        {
                "mondaiId": 102280181,
                "name": "第18問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のグラフは、ポートフォリオ理論の下での、すべてのリスク資産と無リスク  
資産の投資機会集合を示している。これに関して、下記の設問に答えよ。  

<imgTag>102280181_1.png</imgTag>  

（設問1）

無リスク資産が存在しない場合の記述として最も適切なものはどれか。  
  
ア  B-C間を効率的フロンティアと呼ぶ。  
  
イ  均衡状態においては、すべての投資家が同一のポートフォリオを所有する。  
  
ウ  合理的な投資家はA-B間から、各人のリスク回避度に応じてポートフォリオを選択する。  
  
エ  投資家のリスク回避度が高くなるほど、点Cに近いポートフォリオを選択する。  
        
`
        },
        {
                "mondaiId": 102280182,
                "name": "第18問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のグラフは、ポートフォリオ理論の下での、すべてのリスク資産と無リスク  
資産の投資機会集合を示している。これに関して、下記の設問に答えよ。  

<imgTag>102280181_1.png</imgTag>  

（設問２）

無リスク資産が存在する場合の記述として最も適切なものはどれか。  
  
ア  均衡状態においては、すべての投資家が所有する危険資産と無リスク資産の  
比率は同じである。  
  
イ  資金の借り入れが、無リスク資産利子率において無制限に可能である場合、  
投資家はD-E間を選択せず、F-D間から各自のリスク回避度に応じてポート  
フォリオを選択する。  
  
ウ  すべてのリスク回避的な投資家は無リスク資産のみに投資する。  
  
エ  点Dを選択する投資家も存在する。  
`
        },
        {
                "mondaiId": 103280010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　ドメインの定義、および企業ドメインと事業ドメインの決定に関する記述として、最も適切なものはどれか。  
  
ア  <hintTarget>事業</hintTarget>ドメインに関する企業内の関係者間での合意を｢ドメイン・コンセンサス｣と呼び、その形成には、トップマネジメントが周年記念の場などで、企業のあり方を<hintTarget>簡潔</hintTarget>に情報<hintTarget>発信</hintTarget>する必要がある。  
  
<hint hidden='true'>**
❌ドメインコンセンサスは、どちらかというと企業ドメイン（事業ドメインではなく）に関する関係者間での合意です。  
また、事業ドメインと比べて、製品そのものではなく概念的なものであるため、根気強いコンセンサス形成が求められます。「簡潔」な「発信」ではなく、<hintTarget>適度に冗長で双方向性のある伝達手段</hintTarget>による浸透が望ましいと考えられます。
**</hint>    

イ  多角化している企業では、<hintTarget>企業</hintTarget>ドメインの決定は、<hintTarget>競争</hintTarget>戦略として差別化の方針を提供し、<hintTarget>日常の</hintTarget>オペレーションに直接関連する。  

<hint hidden='true'>**
❌下図のとおり、競争戦略や日常のオペレーションに影響するのは、企業ドメインでなく<hintTarget>事業</hintTarget>ドメインです。  
<imgTag>103280010_2.png</imgTag>  
**</hint>    

ウ  多角化せずに<hintTarget>単一の事業</hintTarget>を営む企業では、企業ドメインと事業ドメインは同義であり、<hintTarget>全社戦略と競争戦略</hintTarget>は一体化して策定できる。  

<hint hidden='true'>**
⭕この選択肢の記述は単一の事業を営むケースなので、適切です。全社戦略・競争戦略と、企業ドメイン・事業ドメインの対応イメージは、選択肢イの図のとおりです。
**</hint>    

エ  ドメインの定義における機能的定義は、エーベルの３次元の<hintTarget>顧客層</hintTarget>に相当する顧客ニーズと、それに対して自社の提供する<hintTarget>サービス内容</hintTarget>で定義する方法である。  
  
<hint hidden='true'>**
❌下図の通り、  
・ 機能的定義は、顧客層でなく<hintTarget>顧客機能</hintTarget>に相当します。  
・ サービス内容は、顧客層でなく<hintTarget>技術（提供する製品なので）</hintTarget>に相当します。   
<imgTag>103280010_1.png</imgTag>  
**</hint>  

オ  ドメインの定義における物理的定義は、エーベルの３次元の<hintTarget>技術</hintTarget>ではなく、物理的存在である製品によってドメインを定義する。  

<hint hidden='true'>**
❌選択肢エの図の通り、物理的定義は、技術であっています。
**</hint>  

`
        },
        {
                "mondaiId": 103280020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　プロダクト・ポートフォリオ・マネジメントに関する記述として、最も適切なものはどれか。  
  
ア  競争優位性のある｢金のなる木｣事業は、分野の将来性に大きな魅力はなく、さらなる資金投下には資金効率からの判断が必要である。  
  
<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

イ  市場成長率の高い｢<hintTarget>花形商品</hintTarget>｣事業からの大きな余剰資金と｢問題児｣事業の売却で得た資金は、衰退期に入った業界の｢<hintTarget>金のなる木</hintTarget>｣事業に集中的に投入して市場地位を維持することが重要である。  

<hint hidden='true'>**
❌下図のとおり、金のなる木で得た余剰資金を、花形に投資することが望ましいとされています。**
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/103003_1.png" target="_blank" >
<img src="assets/img/kaisetu/103003_1.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>

ウ  市場成長率の高い｢花形商品｣事業の生み出す<hintTarget>余剰資金は大きい</hintTarget>ので、その資金を｢問題児｣事業に分散して投入を図ることが重要である。  

<hint hidden='true'>**
❌花形は、<hintTarget>資金を獲得できるいっぽうで、市場シェア維持のための資金の投入も必要である</hintTarget>ため、余剰資金は大きくありません。
**</hint>

エ  プロダクト・ポートフォリオ・マネジメントでは、事業への資金の投入量は自社の相対的な<hintTarget>市場シェア</hintTarget>で決まると考える。  

<hint hidden='true'>**
❌<hintTarget>市場成長率</hintTarget>で決まると考えます。
**</hint>

オ  プロダクト・ポートフォリオ・マネジメントは、キャッシュフローの観点から企業の事業戦略の方向性を示し、事業間のキャッシュフローの<hintTarget>アンバランスを許容</hintTarget>している。  

<hint hidden='true'>**
❌金のなる木で得たキャッシュフローを、花形や問題児に配分することを推奨しており、アンバランスを許容しているとは言えません。
**</hint>

`
        },
        {
                "mondaiId": 103280030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、自社の経営資源を活用して成長を図る内部成長とともに、外部企業の経営  
資源を使用する権利を獲得するライセンシングや、外部企業の持つ経営資源を取得  
して成長を目指していく買収が活発になっている。これらの戦略に関する記述とし  
て、最も適切なものはどれか。  
  
ア  相手企業のコア・コンピタンスとなっている技術を自社に吸収し、自社の技術  
水準を上げていくためには、買収よりも独占的ライセンシングを活用する方が適  
している。  
  
イ  既存の事業が衰退期に入っている場合、当該業界における市場支配力を高める  
には、既存の経営資源を活用するための投資を増強していく内部成長よりも、競  
合企業を買収する方が適している。  
  
ウ  国内で高価格な製品を製造・販売している企業が、新興国で新たに低価格製品  
��販売して短期間のうちに軌道に乗せるためには��現地の同業企業を買収するよ  
りも、独自に販売ルートを開拓していく内部成長の方が適している。  
  
エ  製品メーカーが、稀少性の高い原材料メーカーとの取引を安定化し、取引費用  
の削減をしていくためには、買収によって自社に取り込むよりも、ライセンシン  
グによって関係を構築する方が適している。  
        
`
        },
        {
                "mondaiId": 103280041,
                "name": "第4問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
現代の企業にとって、外部組織との連携の活用は、事業の競争力を構築するための主要な経営課題となっている。ヘンリー・チェスブロウは｢企業内部と外部のアイデアを有機的に結合させ、価値を創造すること｣をオープン・イノベーションと定義した。技術や市場の変化の激しい経営環境では、経営資源の制約のある中小企業にとっても、新商品開発でのオープン・イノベーションの必要性は小さくない。<u>①  オープン・イノベーションにはメリット</u>とデメリットがあり、オープン・イノベーションによる競争力の構築にあたっては、経営者の戦略的な判断が問われる。自動  
車産業での密接な企業間関係に見られるように、日本企業も企業外部の経営資源の  
活用に取り組んできた。近年では、<u>②大学や公的研究所などの研究組織との共同開発</u>に積極的な取り組みをする企業も増えている。  

（設問１）

文中の下線部①の｢オープン・イノベーションにはメリット｣があることに関す  
る記述として、<u>最も不適切なものはどれか</u>。  
  
ア  オープン・イノベーションは、企業外部の経営資源の探索プロセスにおい  
て、内部での商品開発に対する競争圧力が強くなり、組織の活性化につなが  
る。  
  
イ  オープン・イノベーションは、企業内部の優れた人材に限らず、企業外部の  
優秀な人材と共同で新商品開発を進めればよく、内部での開発コストの低減が  
期待できる。  
  
ウ  オープン・イノベーションは、研究開発から事業化・収益化までのすべての  
プロセスを企業内部で行う手法の延長上に位置付けられるが、企業内部の経営  
資源の見直しに左右されずに進捗する。  
  
エ  オープン・イノベーションは、一般的により高い専門性をもつ企業との連携  
などによって新商品開発プロセスのスピードアップにつながる。  
        
`
        },
        {
                "mondaiId": 103280042,
                "name": "第4問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": ` 
次の文章を読んで、下記の設問に答えよ。  
現代の企業にとって、外部組織との連携の活用は、事業の競争力を構築するための主要な経営課題となっている。ヘンリー・チェスブロウは｢企業内部と外部のアイデアを有機的に結合させ、価値を創造すること｣をオープン・イノベーションと定義した。技術や市場の変化の激しい経営環境では、経営資源の制約のある中小企業にとっても、新商品開発でのオープン・イノベーションの必要性は小さくない。<u>①  オープン・イノベーションにはメリット</u>とデメリットがあり、オープン・イノベーションによる競争力の構築にあたっては、経営者の戦略的な判断が問われる。自動  
車産業での密接な企業間関係に見られるように、日本企業も企業外部の経営資源の  
活用に取り組んできた。近年では、<u>②大学や公的研究所などの研究組織との共同開発</u>に積極的な取り組みをする企業も増えている。  

（設問２）

文中の下線部②にあるように、大学と共同で開発した成果を活用して、新たに  
起業する場合の問題に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  大学教員をパートナーに起業した場合には、営利取得の可能性があるため  
に、当該教員が企業家活動から個人的利益を追求する利益相反を生み出すこと  
がある。  
  
イ  大学教員をパートナーに起業した場合には、大学の知的資源や労力を流用す  
る際に、営利目的のために大学院生や学部学生を利用し、学部教育や大学院教  
育を弱体化させることがある。  
  
ウ  大学教員をパートナーに起業した場合には、大学の発明に対して排他的な権  
利を保有したいと要望し、知識の流通を限定して潜在的に価値のある商業技術  
の普及を遅らせることがある。  
  
エ  大学教員をパートナーに起業した場合には、利益相反の問題は大学やその事  
務職員の株式保有にかかわりなく、当該教員が研究を行う企業の株式を保有し  
ているかどうかによって生じる。  
        
`
        },
        {
                "mondaiId": 103280050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
多数の競争相手が互いにしのぎを削る熾烈な競争を繰り広げている業界での、効  
果的な戦略対応に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  これまでの内部留保を活用して、同業他社との合併を進めることで市場シェア  
を拡大し、規模の経済や経験効果を高めて、コスト優位性を生み出して収益の拡  
大を図る。  
  
イ  差別化が難しい汎用品による乱戦状況を改善するべく、加工の水準をあげて顧  
客の信頼を得たり、顧客に利便性の高いサービスを付け加えたりして、自社製品  
の付加価値を高めて、根強いロイヤルティをもつ顧客層の拡大を図る。  
  
ウ  多種多様な顧客ニーズに対応するべくあらゆる製品を提供して、大量生産によ  
るコスト優位による競争優位を確立する。  
  
エ  多数の企業が乱立する原因である多様な市場ニーズに対応するべく、製品の設  
計を見直して生産コストを大幅に切り下げて、標準品が買い得であることを理解  
してもらい、規模の経済を基に競争優位をつくり出す。  
        
`
        },
        {
                "mondaiId": 103280060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業が競争優位を獲得するための競争戦略のひとつであるコスト・リーダーシッ  
プ戦略に関する記述として、最も適切なものはどれか。  
  
ア  コスト・リーダーシップ戦略では、継続的に自社製品を購入する顧客を確保す  
るために、ブランド・ロイヤルティを高めることが課題となり、企業の提供する  
付加価値が明確になっている。  
  
イ  コスト・リーダーシップ戦略は、市場成長率が安定してきて、製品ライフサイ  
クルの成熟期以降に採用する戦略として適しており、企業が脱成熟をしていくう  
えで有益な戦略となる。  
  
ウ  コスト・リーダーシップ戦略は、多角化した企業において、シナジーの創出に  
よるコスト削減を目指していく戦略であるので、事業間の関連性が高い企業の方  
が、優位性を得やすくなる。  
  
エ  コスト・リーダーシップ戦略を行う企業が、浸透価格政策をとると、自社の経  
験効果によるコスト低下のスピードは、競合他社よりもはやくなる。  
  
オ  コスト・リーダーシップ戦略を行っている企業は、特定モデルの専用工場を建  
設し、生産性の高い設備を導入しており、新しい市場ニーズへも迅速に対応でき  
る。  
        
`
        },
        {
                "mondaiId": 103280070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業界での競争地位によって、企業はリーダー、チャレンジャー、フォロワー、ニ  
ッチャーに分類できる。そのなかで、チャレンジャーとニッチャーに関する記述と  
して、最も適切なものはどれか。  
  
ア  チャレンジャーは、業界で生き残ることを目標に、購買の動機として価格を重  
視するセグメントをターゲットにし、徹底的なコストダウンを行い、代替品を低  
価格で提供していく戦略を採る。  
  
イ  チャレンジャーは、市場全体をターゲットとするフル・カバレッジにより、リ  
ーダーの製品を模倣していく戦略を採る。  
  
ウ  チャレンジャーは、リーダーに対する価格・製品・プレイス・プロモーション  
という４Pの差別化よりも、ドメインの差別化を行う。  
  
エ  ニッチャーは、狭いターゲットに対して、業界の価格競争には巻き込まれない  
ように閉鎖型の販売チャネルを採用して、媒体を絞り込んだプロモーションを展  
開する。  
  
オ  ニッチャーは、自社が属する業界のライフサイクルの導入期に活動が活発にな  
り、他社の行動を追随する同質化を推進し、市場全体の規模を広げる役割を担っ  
ている。  
        
`
        },
        {
                "mondaiId": 103280080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
競争優位の源泉を分析するには、バリュー・チェーン(価値連鎖)という概念が有  
効である。バリュー・チェーンに関する記述として、最も適切なものはどれか。  
  
ア  差別化の効果は、買い手が認める価値と、自社のバリュー・チェーンのなかで  
作り出した特異性を生み出すためのコストが同水準になった時に最大化する。  
  
イ  バリュー・チェーン内で付加価値を生み出していない価値活動に関して、アウ  
トソーシングなどによって外部企業に依存する場合、企業の競争力を弱めてしま  
う。  
  
ウ  バリュー・チェーンの各々の価値活動とともに、それらの結び付き方は、企業  
の独特な経営資源やケイパビリティとして認識することができる。  
  
エ  バリュー・チェーンの全体から生み出される付加価値は、個別の価値活動がそ  
れぞれ生み出す付加価値の総和であり、各価値活動の部分最適化を図っていくこ  
とが、収益性を高める。  
        
`
        },
        {
                "mondaiId": 103280090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
企業は市場の変化に対応するため、限られた経営資源を特定の事業や事業領域に  
集中特化し、事業活動の一部をアウトソーシングすることがある。企業のそのよう  
な戦略対応に関する記述として最も適切なものはどれか。  
  
ア  アウトソーシングすることによって、自社能力の適用の幅が狭くなり、顧客ニ  
ーズへの対応力も弱まるので、新規顧客の開拓が難しくなる。  
  
イ  アウトソーシングする事業領域と自社で取り組む事業領域を峻別して経営資源  
を集中特化することによって、特定事業領域で独自能力の構築を目指すことが可  
能になる。  
  
ウ  アウトソーシングによって外部の専門能力を利用する傾向が強まると、同種の  
社内能力を維持強化しようとする能力構築の動きが強まり、企業活動が活性化す  
る。  
  
エ  アウトソーシングを行い生産から販売まで一貫した事業に統合化することによ  
って、事業の伸縮自在性が高まるので、外部環境の急激な変化に対応することが  
できる。  
        
`
        },
        {
                "mondaiId": 103280100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
技術志向の企業では、企業価値に占める無形資産の割合が有形資産のそれを大き  
く上回る企業が多く見られ、知的資産の戦略的経営が注目されている。特に特許は  
守るだけでなく、企業価値を高めるべくそれを他社と相互に活用したりすることも  
重要になっている。特許の戦略的運用に関する記述として、最も適切なものはどれ  
か。  
  
ア  特許をオープンライセンスすることは、ライセンスを許諾することによって自  
社技術基盤の上に他社製品をのせて、他社の代替技術開発のモチベーションを下  
げる効果を期待できるが、ロイヤルティ収入は期待できなくなる。  
  
イ  プロパテント戦略は特許侵害に対応すべく、訴訟に訴えて差止請求権や損害賠  
償請求権などの法的手段で特許を守る戦略であり、知財戦略の基本をなすもので  
ある。  
  
ウ  包括クロスライセンス契約では、特定分野についてリスト化された特許の範囲  
で特許の相互利用が許されるが、その後成立した特定分野の特許についてはリス  
トに加えることは法的に許されていない。  
  
エ  包括クロスライセンス契約を結ぶのは、主として企業間で特許を相互に幅広く  
利用するためであり、契約提携企業間での金銭の授受を伴うこともある。  
        
`
        },
        {
                "mondaiId": 103280111,
                "name": "第11問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

ものづくりに強みをもつといわれているわが国の製造業であるが、近年大きな変化が見られるようになってきた。<u>①エレクトロニクスメーカー各社の苦境が伝えられており</u>、エレクトロニクスメーカー各社では、事業分野の再構築を図る動きが活発である。  

自動車産業では、国内市場が縮小するなか、グローバルな競争に対応すべく生産拠点の海外移転や現地での研究開発の展開など大きな変化が見られる。また、<u>②自動車のモジュール生産が本格化してきており、系列による垂直統合型の生産に変化が起こっている</u>。さらに、環境対応技術や自動運転技術の開発が進むにつれて、自動車産業のサプライヤーにも技術の変化への対応が求められるようになっている。  
        
（設問１）  

文中の下線部①に記述されているエレクトロニクスメーカーの苦境の原因は多  
様である。そのような原因と考えられるエレクトロニクス産業の状況に関する記  
述として、<u>最も不適切なものはどれか</u>。  
  
ア  エレクトロニクス産業では、あらゆる分野の製品を生産し販売するという総  
花的な自前主義の戦略を見直して、事業分野の選択と集中を図り、電子部品サ  
プライヤーとの垂直的統合を強化したため、事業分野の幅が狭くなり、グロー  
バルな競争力が低下してきている。  
  
イ  エレクトロニクス産業では、安価な電子部品をグローバルに調達して、それ  
らを組み合わせた製品が多くなるにつれて、部品から製品までの一貫生産がコ  
スト競争のうえから不利になっている。  
  
ウ  エレクトロニクス産業では、競争優位の構築を目指しながらも、互いに同質  
的な戦略を展開しながら、技術進歩や製品開発を促進してきたが、電子技術を  
一方向に収  
しゅう  
斂  
れん  
させる傾向が強まり、多機能を搭載した類似製品の競争に陥り  
がちになっている。  
  
エ  エレクトロニクス産業では、先発企業が自社技術を武器に市場シェアを獲得  
していても、後発企業が安価な部材をグローバルに調達し、技術的にほぼ同等  
な製品で価格訴求力を武器に先発企業のシェアを奪うことが多くなっている。  
        
`
        },
        {
                "mondaiId": 103280112,
                "name": "第11問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `                
次の文章を読んで、下記の設問に答えよ。  

ものづくりに強みをもつといわれているわが国の製造業であるが、近年大きな変化が見られるようになってきた。<u>①エレクトロニクスメーカー各社の苦境が伝えられており</u>、エレクトロニクスメーカー各社では、事業分野の再構築を図る動きが活発である。  

自動車産業では、国内市場が縮小するなか、グローバルな競争に対応すべく生産拠点の海外移転や現地での研究開発の展開など大きな変化が見られる。また、<u>②自動車のモジュール生産が本格化してきており、系列による垂直統合型の生産に変化が起こっている</u>。さらに、環境対応技術や自動運転技術の開発が進むにつれて、自動車産業のサプライヤーにも技術の変化への対応が求められるようになっている。  
        
（設問２）  

文中の下線部②のわが国における自動車のモジュール生産の進展とそれにとも  
なう生産体制の変化に関する記述として、最も適切なものはどれか。  
  
ア  モジュール生産の進展にともなって、アジア域内の現地中堅サプライヤーが  
生産するエンジンやパワートレイン等の大型のモジュール部品を一か所に集約  
して、そこからアジアの生産拠点に供給する配送システムが構築されている。  
  
イ  モジュール生産の進展にともなって、車種間でのプラットフォームの統合を  
進めて、生産の規模の経済や部品や設備の共通化による生産コストの低減が行  
われるようになっているが、一次サプライヤーの供給する部品点数は変わらな  
い。  
  
ウ  モジュール生産の進展にともなって、車種間で共用化を進める基本部分と多  
様化のための可変的な部分を切り分ける生産体制がとられるようになるにつれ  
て、サプライヤーはこのような生産体制に柔軟に対応する部品供給が求められ  
るようになった。  
  
エ  モジュール生産の進展にともなって、部品間の擦り合わせの頻度が高まって  
くるので、組立メーカーでは完成車組立工場の敷地内にサプライヤーを集積さ  
せたサプライヤー・パークを設ける例がみられるようになった。  
        
`
        },
        {
                "mondaiId": 103280120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
機能別組織、事業部制組織、マトリックス組織の特徴に関する記述として、最も  
適切なものはどれか。  
  
ア  機能別組織は部門間で緊密な調整が必要な場合に有効であるが、安定した環境  
のもとで官僚制的な組織になるという短所がある。  
  
イ  事業部制組織が有効に機能するためには、トップマネジメントが業務的意思決  
定から解放され、戦略的意思決定と管理的意思決定に専念できるようにする必要  
がある。  
  
ウ  事業部制組織は複数の製品―市場分野を持つ企業が、範囲の経済を実現するの  
に適しているが、規模の経済を追求することは難しい。  
  
エ  マトリックス組織は変化の速い環境で部門間の相互依存が高い場合に有効であ  
るが、コンフリクトや曖昧さを許容する組織文化を持たないと効果的に機能しに  
くい。  
  
オ  マトリックス組織を効果的に管理するためには、１人の部下に対して、機能マ  
ネジャーとプロダクトマネジャーが同じ権限を持っていなければならない。  
        
`
        },
        {
                "mondaiId": 103280130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業組織における並列的部門間関係は、プールされた(pooled)相互依存、連続的  
(sequential)相互依存、相互補完的(reciprocal)相互依存の3つのタイプに分けられ  
る。これらの部門間関係と組織編成に関する記述として、最も適切なものはどれ  
か。  
  
ア  相互補完的依存関係にある部門間では、頻繁なチームワークによる調整が必要  
になるため、各部門が同じ規則や手続きに従って行動するよう事前に調整してお  
く必要がある。  
  
イ  相互補完的依存関係にある部門間の調整は、同じ専門能力を共有するために、  
各部門の管理者が直接顔を合わせて水平方向のコミュニケーションを頻繁に行う  
必要がある。  
  
ウ  プールされた相互依存関係にある部門間では、仕事は基本的にそれぞれの部門  
内で独自の規則や手続きに従って処理できるため、3つの相互依存関係の中で最  
も調整の必要性が低い。  
  
エ  連続的相互依存関係にある多くの部門間では、事前の計画やフィードバックコ  
ントロールによる調整が必要になる。  
  
オ  連続的相互依存関係にある部門間調整には、水平的なコミュニケーションの必  
要性が高いので、部門間で共通した規則や手続きに従って業務を遂行する必要が  
ある。  
        
`
        },
        {
                "mondaiId": 103280140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
官僚制の逆機能といわれる現象に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  革新的な計画に抵抗するために、日常のルーティン対応を探し求める、グレシャムの法則。  
  
イ  規則や手続きそのものを絶対視するような態度が、杓子定規な画一的対応を生み出す、形式主義。  
  
ウ  組織全体の利益よりも、自分が所属する部局の利益を優先する、セクショナリズム。  
  
エ  膨大な手続きと書類作成に煩わされる、繁文縟礼（はんぶんじょくれい）。  
  
オ  本来は手段にすぎない規則や手続きが目的に転じてしまう、目的置換。  
        
`
        },
        {
                "mondaiId": 103280150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
わが国の自動車産業におけるリーン生産方式への関心の高まりとともに、チーム  
ごとにタスクを振り分け、多能工化した作業員が自律的に職務を行うチーム型作業  
組織が注目されてきた。官僚制的統制とは異なる組織原理を持ったチーム型作業組  
織に期待される効果に関する記述として、最も適切なものはどれか。  
  
ア  １人１タスクの原則に基づいて、グローバル化や情報化の進展など、経営環境  
の変化に対する迅速かつ適切な対処能力がある。  
  
イ  自律的な調整のための積極的な参加が求められるため、メンバー間のコミュニ  
ケーションが活発になり、互いに助け合いながら共同することによる労働生活の  
質(QWL)の向上が期待できる。  
  
ウ  多能工化した職務は、自律的に働くことを好まない労働者に対して、複数の技  
能を獲得することによる職務の充実と、より高度な仕事へコミットすることによ  
る心理的満足をもたらす。  
  
エ  チーム型作業組織は、経営者の視点から見た企業の競争優位の源泉としてでは  
なく、労働者が自主的な管理の権限を取得し職務満足へとつなげていく活動とし  
てとらえられる。  
        
`
        },
        {
                "mondaiId": 103280160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A社は技術者によって設立された中堅企業で、ハイテクエレクトロニクス製品を  
生産している。これまでマトリックス組織を採用して、既存製品のバージョンアッ  
プを通じて新製品を次々に市場に投入し成長してきた。この間、トップマネジメン  
トは経営戦略を策定する際に、技術者であるプロダクトマネジャーから５年先まで  
の投資計画と利益計画を毎年提出させ、彼らと対話することを通じてどの製品分野  
に予算を配分するかの全社的な投資決定をしてきた。一方、機能マネジャーには、  
複数の製品を生産するのに同じ工程技術が使えることなどから、原価計算を行い、  
その後に算定される利益率に応じて生産的経営資源を配分する権限を与えてきた。  

既存製品のバージョンアップによる新製品開発も成熟段階に達したため、既存の  
マトリックス組織のもとで、これまでの製品とは不連続な技術による新製品の事業  
化に乗り出した。この製品の利益率は既存の製品群に比べて高かったので、機能マ  
ネジャーは積極的に生産的経営資源を新規事業分野に配分し始めたが、この企業全  
体の利益率は低下してきている。  

A社の全社的な利益率の低下の背後にあると考えられる問題に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  既存製品のバージョンアップが新製品に結びつく段階では有効に機能したマト  
リックス組織が、既存製品とは不連続な技術に基づく新規事業を遂行するには障  
害となった。  
  
イ  既存製品のプロダクトマネジャーは  
年計画を毎年提出していたため、トップ  
マネジメントが近視眼的な学習に陥ってしまい、利益率の低い既存事業に投資を  
続けてしまった。  
  
ウ  機能マネジャーが、新製品の方が利益率が高いことを知りつつ、その全社的な  
投資戦略に対する意味をトップマネジメントに伝えなかったため、トップマネジ  
メントが迷信的学習に陥ってしまった。  
  
エ  機能マネジャーに生産的経営資源の配分権限を与えていたが、投資決定権限を  
与えていなかったために、機能マネジャーが傍観者的学習に陥ってしまい、企業  
全体として最適な資源配分ができなくなっていた。  
        
`
        },
        {
                "mondaiId": 103280170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業は比較的規模が小さい創業段階から成長して規模が大きくなるためには、一  
般に成長段階に応じて異なる経営上の課題を解決していかなければならない。組織  
の成長段階と克服すべき課題や有効性に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  企業が多数の機能部門を持つような規模に成長すると、経営者は次第に業務的  
決定から離れ、規則や手続きを整備し官僚制的な組織構造を構築する必要が生じ  
る。  
  
イ  強力なリーダーシップを持つ企業家によって設立された企業は、必要な資源を  
獲得するために資本家や顧客、労働者、供給業者などから正当性を獲得する必要  
がある。  
  
ウ  創業段階を経て環境との安定的な関係の構築に成功した企業では、経営者は非  
公式なコミュニケーションを通じた統制から、次第に権限を委譲しつつ、公式の  
統制システムを構築しなければならない。  
  
エ  組織の公式化が進み官僚制の逆機能が顕在化した段階では、公式の権限に依拠  
した規則や手続きをより詳細に設計しなければならない。  
  
オ  単一製品・単一機能で創業した小規模企業が、経営資源を有効に活用するため  
に垂直統合戦略を採用した場合、集権的な機能別組織へ移行する必要がある。  
        
`
        },
        {
                "mondaiId": 103280180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
現代の複雑な環境においては、確率的に計算しうるリスク管理を超えて、不測の  
事態に備える危機管理(クライシス・マネジメント)が重要になってきている。一般  
に危機が発生すると、まず最初に危機管理チームが編成され、危機管理センターが  
設置される。組織の危機管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  危機管理チームと危機が起きている現場とのコミュニケーションが確保��きれ  
ば、危機管理チームは現場に対し、何を行��べきかだけでなく、いかに行うべき  
かについても集権的に意思決定することが望ましい。  
  
イ  危機管理チームは、時間の切迫と過重な負荷の中で迅速に意思決定をしなけれ  
ばならないために、組織内の諸資源を十分な自由裁量を持って動員する権限を持  
つ必要がある。  
  
ウ  危機管理チームは、問題の技術的局面を解決できる役員の他に、社長や法務担  
当・広報担当役員などのトップマネジメント、時には外部のコンサルタントなど  
も含めて構成する必要がある。  
  
エ  危機発生時には通常の情報伝達システムが破壊されている場合が多いので、常  
に情報伝達が途絶しないよう注意し、状況に変化があった場合はもちろん、変化  
がない場合にもその旨を伝える情報を提供し、従業員の心的緊張を和らげるよう  
にすべきである。  
  
オ  組織内の通常の情報伝達システムが遮断されている場合には、危機管理センタ  
ーを中心として、危機管理にかかわる関係者の連絡先や、必要データの入手先な  
どの情報ネットワークを迅速に確保する必要がある。  
        
`
        },
        {
                "mondaiId": 103280190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働市場に対して組織の状況や特色をアピールする際に、応募者に好感される情  
報を強調するのではなく、ときには好感されにくい現実をありのままに伝えようと  
する広報戦略を、RJP(RealisticJobPreview)と呼ぶ。RJPの効果として、<u>最も不適切なものはどれか</u>。  
  
ア  自己の能力を見つめなおさせ、自己選抜によって応募を辞退させる効果。  
  
イ  職務や職場への初期適応を円滑にする効果。  
  
ウ  入社後の離職を回避させる効果。  
  
エ  入社前に組織に対して抱く期待や、やる気を引き上げる効果。`
        },
        {
                "mondaiId": 103280200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
360度評価は、上司が部下を評価するだけではなく、自分を取り囲む先輩や同  
僚、部下、場合によっては関係先の部署や取引先などの、さまざまな関係の人達か  
ら評価を受ける手法である。また、多様な評価を被評価者にフィードバックするこ  
とによる効果も期待されている。360度評価の効果として、<u>最も不適切なものはどれか</u>。  
  
ア  顧客や取引先が評価者となった場合には、被評価者の顧客志向が高まる。  
  
イ  異なった評価を見ることによって、評価者を訓練する機会を提供する。  
  
ウ  上司と部下のコミュニケーションの活性化が図られる。  
  
エ  中立的な評価を行うことができる評価者を選抜することができる。  
  
オ  普段の業務では得られない、さまざまな情報を入手できる。  
        
`
        },
        {
                "mondaiId": 103280210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
人材のダイバーシティが組織やそのメンバーに与える影響に関する記述として、  
最も適切なものはどれか。  
  
ア  これまで社外に求められていた異質な見方を社内に取り込むことで、組織変革  
や新商品開発などのイノベーションが期待される。  
  
イ  少数派の意見を強制的に反映させるアファーマティブ・アクションを通じて、  
格差の是正を超えた経営成果の改善がもたらされる。  
  
ウ  他者あるいは他の集団との比較を行い、他者を自分にはない魅力を備えた存在  
と認識させることによって、個人の自尊心が保たれる。  
  
エ  多様な人材の確保によって、共通の人生経験や価値観などのような類似したバ  
ックグラウンドを全く見いだせなくなり、人々の交流やコミュニケーションに好  
ましくない影響を与える。`
        },
        {
                "mondaiId": 103280220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働契約に関する記述として、最も適切なものはどれか。  
  
ア  使用者が、労働者との間で、労働基準法で定める基準に達しない労働条件を定  
める労働契約を結んだ場合、労働基準法で定める基準より労働者に有利な部分も  
含めて、当該労働契約は無効となる。  
  
イ  使用者は、満60歳以上の労働者との間で、５年の契約期間の労働契約を締結  
することができる。  
  
ウ  使用者は、労働契約の締結において、労働契約の不履行について違約金を定め  
ることはできないが、労働者が使用者に損害を被らせる事態に備えて、損害賠償  
額を予定することはできる。  
  
エ  労働基準法は、使用者が労働者に金銭を貸すこと、及び貸金債権と賃金を相殺  
することを一律に禁止している。  
        
`
        },
        {
                "mondaiId": 103280230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働基準法における労働時間、休憩・休日に関する記述として、最も適切なもの  
はどれか。  
  
ア  使用者は、労働時間が連続８時間を超える場合においては少なくとも１時間の  
休憩時間を労働時間の途中に与えなければならず、労働時間が連続12時間を超  
える場合には少なくとも１時間30分の休憩時間を労働時間の途中に与えなけれ  
ばならない。  
  
イ  使用者は、所定労働時間が５時間である労働者に１時間の時間外労働を行わせ  
たときは、少なくとも45分の休憩時間を労働時間の途中に与えなければならな  
い。  
  
ウ  使用者は、労働者に対して、４週間を通じ４日以上の休日を与え、その４週間  
の起算日を就業規則その他これに準じるものにおいて明らかにしているときには、当該労働者に、毎週１回の休日を与えなくてもよい。  
  
エ  労働時間に該当するか否かは、労働者の行為が使用者の指揮命令下に置かれた  
ものと評価することができるか否かにより客観的に定まるものではなく、労働契  
約、就業規則、労働協約等の定めのいかんにより決定されるべきものである。  
        
`
        },
        {
                "mondaiId": 103280240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
X社では、営業所員の雇用管理について、営業所長に一定の権限を委ねている。  
以下は、人事部が複数の営業所長から報告を受けた案件処理である。労働法規上、  
<u>最も不適切なものはどれか</u>。  
  
ア  営業所で新たにアルバイトを採用することにしたが、人件費予算も限られてい  
るため、日本よりも物価水準の低い国から来日している留学生を採用することに  
し、時給は600円と定めた。  
  
イ  営業所では、繁忙期の業務処理をパートタイマーやアルバイトで賄っている  
が、所定労働時間１日８時間、所定労働日数週５日勤務を契約内容とするアルバイ  ト(変形労働時間制、変形休日制はいずれも採用していないものとする)を、1日8時間、週6日間働かせた場合、所定労働日数を超えた日の労働時間について  
割増賃金を支払った。  
  
ウ  月末退職予定の営業所員が、｢引継ぎ等があるために、有給休暇を消化できな  
いから、残存有給休暇を買い上げてほしい｣と言ってきた。実際、この者の業務  
引継ぎは営業所としても重要であり、この期間に休まれては困るので、この申し  
出には応じることにした。  
  
エ  先日、地元のハローワークに同業種の営業職経験者の求人を出したが、同業種  
経験者は採用できず、異業種の若手営業経験者を採用内定した。その者が勤務開  
始後に、｢内定時に示された給与額が求人票の額を下回っているのは違法だ｣と言  
ってきたが、本人に提示額の根拠説明をし、求人票の額を下回る給与を支払っ  
た。  
        
`
        },
        {
                "mondaiId": 103280250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働安全衛生、労災保険に関する記述として、最も適切なものはどれか。  
  
ア  事業場の常時使用労働者数にかかわらず、事業者は、常時使用する労働者を雇  
い入れた際に健康診断を実施しなければならない。ただし、雇い入れ日以前3カ  
月以内に医師による健康診断を受けた労働者が、その診断結果の証明書類を提出  
した場合には実施を省略できる。  
  
イ  事業場の常時使用労働者数にかかわらず、事業者は、毎月1回以上衛生委員会  
を開催しなければならない。  
  
ウ  社員食堂のランチタイム時に1日3時間、調理業務に従事するパートタイマー  
が、調理中に火傷を負った。この場合において、事業主が労災保険の保険関係成  
立届の提出を怠っていたときは、このパートタイマーは、労災保険の保険給付を  
受けることができない。  
  
エ  労働者が通常の通勤経路上での出勤途上、駅の階段を下りているときに足首を  
ひねって捻挫した。このケガは、自らの不注意によるものであるため、通勤災害  
とはならない。  
        
`
        },
        {
                "mondaiId": 103280261,
                "name": "第26問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マーケティング・チャネルに関する下記の設問に答えよ。  
（設問１）  

マーケティング・チャネルの構造に関する記述として、最も適切なものはどれ  
か。  
  
ア  オーケストラの演奏者が用いるような高価な楽器を揃える店舗の商圏は狭小  
であるため、広くて長いチャネルを構築することが有効性を発揮する。  
  
イ  卸売業者や小売業者にチャネル費用の一部を転嫁することができるため、広  
くて長いチャネルは、カバレッジ確保の上で有効であることが多い。  
  
ウ  希少性の高い高級ブランドの衣料品や雑貨などでは、ブランドイメージのコ  
ントロール度を高く保つことを目的のひとつとして、選択的チャネルが採用さ  
れることが多い。  
  
エ  チャネルの広狭水準は、メーカーが販路として設定する地理的な市場の大き  
さによって規定される。`
        },
        {
                "mondaiId": 103280262,
                "name": "第26問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マーケティング・チャネルに関する下記の設問に答えよ。  

（設問２）  
  
マーケティング・チャネルの管理に関する記述として、最も適切なものはどれ  
か。  
  
ア  チャネル構成員との間でメーカーが相互浸透戦略を実行することは、チャネ  
ル・コンフリクトの抑制に寄与する。  
  
イ  チャネル構成員の動機づけと統制の手段には、大別すると、物理的パワー、  
情報的パワー、組織的パワーの３種がある。  
  
ウ  チャネル構成員を動機づけたり、統制したりするための手段となる経営資源  
のことをチャネル・スチュアードシップと呼ぶ。  
  
エ  取引依存度モデルでは、メーカーが特定のチャネル構成員への販売依存度を  
高めるにつれて、そのチャネル統制力が上昇することが示されている。  
        
`
        },
        {
                "mondaiId": 103280270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
価格に関する記述として、最も適切なものはどれか。  
  
ア  Aさんは、ある家電専門店の店頭で5，000円のスマートフォンを目にした時、  
価格の安さに大きな驚きを感じた。その製品の詳細なスペック“仕様≪を販売員に  
尋ね、購買に至った。これは｢品質のバロメーター｣としての価格が、消費者の購  
買意思決定を後押しした例である。  
  
イ  一物一価の原則は常に存在するわけではない。購買の行われるタイミングや季  
節、地域、顧客区分、あるいは需要の動向によって、価格はある程度柔軟に変更  
される。  
  
ウ  演奏家向けのバイオリンの製作者であるBさんは、この数年、効率的に製作  
に取り組める工房を手に入れ、バイオリン１丁の生産に要する時間を１割程度削  
減した。そこで販売価格を１割下げたところ、受注が殺到している。これは価格  
のもつ｢プレステージ｣効果による。  
  
エ  消費者にとって、価格には複数の意味があるとされる。そのうちのひとつが  
｢支出の痛み｣である。どんなに価格を引き下げても消費者が購買に踏み切ること  
のできない状況を示すものである。  
        
`
        },
        {
                "mondaiId": 103280280,
                "name": "第28問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
売り手とその顧客との関係性に関する記述として、最も適切なものはどれか。  
  
ア  航空会社やホテル、スーパーやドラッグストアなどにおけるCRMプログラム  
導入事例に触発された大規模飲料メーカーA社は、一般的に低コストでできる  
仕組みであるため、最終消費者を対象とした顧客関係管理システムを導入した。  
  
イ  地域スーパーの経営者B氏は、ロイヤルティ・カードを通じて収集した顧客  
の購買データを見て驚いた。既存顧客の下位１割は、特売商品ばかり購入してお  
り、損失をもたらしているのだ。この種の顧客はとくに、ミルクスキマーと呼ば  
れる。  
  
ウ  ファストフードチェーンのC社は、ID-POSの導入にあたって、｢リレーショ  
ンシップ・マーケティングは、顧客との関係性を深め、継続・拡大する考え方だ  
から、個々の顧客を特定するための有用なデータを集めていく必要がある｣とい  
う発想を持っていた。  
  
エ  訪問販売による小売業者D社は、ここ数年、既存顧客の高齢化とともに顧客  
数の減少に悩まされている。そこで、一般的に既存顧客の維持よりも費用がかか  
らないことから、新規顧客の獲得にシフトしていく意思決定を行った。  
        
`
        },
        {
                "mondaiId": 103280291,
                "name": "第29問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

顧客が求める価値を提供し、継続的な関係を築くために、多くの企業は<u>①マーケティング・リサーチ</u>を行い、得られたデータから顧客についての深い洞察を得ている。その手法は多様化している。例えば<u>②顧客の生活に入り込むなどして観察</u>を行ったり、マーケティング刺激に対する眼球の動きや脳内の血流を測定したりするなど、文化人類学や脳科学の手法も積極的に取り入れている。  

（設問１）  

文中の下線部①の｢マーケティング・リサーチ｣に関する記述として、最も適切  
なものはどれか。  
  
ア  売上高、利益やGRP(グロス・レイティング・ポイント)などのマーケティ  
ング変数は、間隔尺度に含まれる。  
  
イ  顧客の意見や市場のニーズを抽出するために、コールセンターやWebサイ  
トなどに寄せられるユーザーの意見を用いてデータマイニングを行うことは、  
一般的に有効である。  
  
ウ  質問票の作成に際し、例えば｢新しい清涼飲料水には、あと味がすっきりし  
ていることや健康促進効果があることが望ましい｣という詳細な選択項目を用  
意することが必要である。  
  
エ  調査対象とする課題が明確になったら、製造業の場合、担当者は自社に適し  
た最新のデータを獲得するために一次データ(プライマリーデータ)の収集から  
始めるのが一般的である。  
        
`
        },
        {
                "mondaiId": 103280292,
                "name": "第29問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

顧客が求める価値を提供し、継続的な関係を築くために、多くの企業は<u>①マーケティング・リサーチ</u>を行い、得られたデータから顧客についての深い洞察を得ている。その手法は多様化している。例えば<u>②顧客の生活に入り込むなどして観察</u>を行ったり、マーケティング刺激に対する眼球の動きや脳内の血流を測定したりするなど、文化人類学や脳科学の手法も積極的に取り入れている。  

（設問2）  

文中の下線部②の｢顧客の生活に入り込むなどして観察｣を行う調査法として、  
最も適切なものはどれか。  
  
ア  エスノグラフィーによる調査  
  
イ  セントラル・ロケーション・テスト  
  
ウ  ニューロ・マーケティングによる調査  
  
エ  フォーカス・グループ・インタビュー  
        
`
        },
        {
                "mondaiId": 103280301,
                "name": "第30問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

<u>①マーケティング概念</u>は、今日に至るまで複数の<u>②段階</u>を経て発展してきたとフィリップ・コトラーは指摘している。近年のマーケティングを取り巻く環境は、私たちが暮らす社会における問題解決が強く求められている点に特徴づけられる。<u>複雑化するおける社会・経済的な適切さとは何か</u>③。環境面における望ましさとは何か。現代におけるマーケティング活動の実践においては、こうした点を事業のミッションやビジョン、価値の中核に据えることがますます重要になってきていると考えられている。  

（設問１）

文中の下線部①に示すマーケティング概念について、アメリカ・マーケティング協会(AMA)は、現在、以下のような定義を行っている。空欄に当てはまる語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

Marketing is the activity，set of( Ａ )，and processes for creating，communicating，delivering and exchanging ( Ｂ ) that have value for( Ｃ )，clients，partners，and ( Ｄ ).  

（解答群）  
  
ア  Ａ：commercial institutions　Ｂ：products and services　Ｃ：consumers  Ｄ：the mother earth  
  
イ  Ａ：commercial organizations　Ｂ：products and services　Ｃ：consumers  Ｄ：people across the globe  
  
ウ  Ａ：institutions　Ｂ：offerings　Ｃ：customers　Ｄ：society at large  
  
エ  Ａ：non-profitorganizations　Ｂ：products　Ｃ：money Ｄ：people in need  
  
オ  Ａ：organizations　Ｂ：outputs　Ｃ：employees　Ｄ：shareholders  
        
`
        },
        {
                "mondaiId": 103280302,
                "name": "第30問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

<u>①マーケティング概念</u>は、今日に至るまで複数の<u>②段階</u>を経て発展してきたとフィリップ・コトラーは指摘している。近年のマーケティングを取り巻く環境は、私たちが暮らす社会における問題解決が強く求められている点に特徴づけられる。<u>複雑化するおける社会・経済的な適切さとは何か</u>③。環境面における望ましさとは何か。現代におけるマーケティング活動の実践においては、こうした点を事業のミッションやビジョン、価値の中核に据えることがますます重要になってきていると考えられている。  

（設問2)

文中の下線部②に示す段階に関する記述として、最も適切なものはどれか。  
  
ア  Marketing1.0とも呼ばれる第１段階では、経済の高度化にともなって、多  
品種・小ロットを重視する柔軟な市場対応が重要視された。  
  
イ  Marketing1.0とも呼ばれる第１段階では、生産者の生産能力と需要を整合  
するために、市場指向の考え方が採用されるようになった。  
  
ウ  Marketing2.0と呼ばれる第２段階では、情報技術の進展に後押しされる形  
での展開が見られ、より優れた製品をターゲット市場セグメントに投入するこ  
との重要性が高まった。  
  
エ  Marketing3.0とも呼ばれる第３段階では、デジタル技術によるオートメー  
ションがマーケティング戦略策定における支配的なツールになることが強調さ  
れている。  
  
オ  マーケティングは、国や地域、機関の境界線を超えて共通の段階を経て、発  
展してきている。  
        
`
        },
        {
                "mondaiId": 103280303,
                "name": "第30問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `        
次の文章を読んで、下記の設問に答えよ。  

<u>①マーケティング概念</u>は、今日に至るまで複数の<u>②段階</u>を経て発展してきたとフィリップ・コトラーは指摘している。近年のマーケティングを取り巻く環境は、私たちが暮らす社会における問題解決が強く求められている点に特徴づけられる。<u>複雑化するおける社会・経済的な適切さとは何か</u>③。環境面における望ましさとは何か。現代におけるマーケティング活動の実践においては、こうした点を事業のミッションやビジョン、価値の中核に据えることがますます重要になってきていると考えられている。  

（設問３)

文中の下線部③と関連する記述として、最も適切なものはどれか。  
  
ア  CSRは、法令遵守を中核とする受動的な考え方であり、その中において企  
業の社会的責任が、本業と関連性のないチャリティとして遂行されるとする考  
え方である。  
  
イ  かつて近江商人の間で実践されていた｢三方よし｣(売り手よし、買い手よし、  
世間よし)の考え方は、CSV(CreatingSharedValue)の基本コンセプトである  
ポジショニング概念の基礎となった。  
  
ウ  ソサイエタル・マーケティング(societalmarketing)の考え方に従うと、マ  
ーケターは企業の利益を最大化することで、地域社会や国民経済への貢献を図  
ることが求められている。  
  
エ  マイケル・ポーターが提唱するCSV(CreatingSharedValue)の考え方は、  
社会的価値と経済的価値の両立をうたうものであり、高い収益性の実現を重視  
するものである。  
        
`
        },
        {
                "mondaiId": 103280311,
                "name": "第31問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

多くの消費者の支持を得ることができた<u>①ブランド</u>をどのように管理し、成長させていくかは、企業収益を左右する重要な課題である。<u>②ブランド開発戦略</u>として説明 されているように、例えば、同じブランド名を用いて、同じカテゴリーに形、色、サイズ、フレーバーなどを変えた製品を導入する（　Ａ　）や異なるカテゴリーの新製品を導入する（　Ｂ　）がとられる。  

同一ブランドでのさらなる市場浸透策が難しいと判断される場合には、同じカテゴリーに新ブランドを展開する（　Ｃ　）や、他社との共同開発という形をとり、自社のブランド名と他社の人気ブランド名の2つを同一製品で用いる（　Ｄ　）が検討される。  

（設問１）

文中の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：ブランド拡張Ｂ：マルチ・ブランド  
Ｃ：ライセンス・ブランドＤ：ライン拡張  
  
イ  Ａ：マルチ・ブランドＢ：ブランド拡張  
Ｃ：ライン拡張Ｄ：コ・ブランディング  
  
ウ  Ａ：マルチ・ブランドＢ：ライン拡張  
Ｃ：コ・ブランディングＤ：ブランド拡張  
  
エ  Ａ：ライン拡張Ｂ：コ・ブランディング  
Ｃ：マルチ・ブランドＤ：ブランド拡張  
  
オ  Ａ：ライン拡張Ｂ：ブランド拡張  
Ｃ：マルチ・ブランドＤ：コ・ブランディング  
        
`
        },
        {
                "mondaiId": 103280312,
                "name": "第31問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
                
次の文章を読んで、下記の設問に答えよ。  

多くの消費者の支持を得ることができた<u>①ブランド</u>をどのように管理し、成長させていくかは、企業収益を左右する重要な課題である。<u>②ブランド開発戦略</u>として説明 されているように、例えば、同じブランド名を用いて、同じカテゴリーに形、色、サイズ、フレーバーなどを変えた製品を導入する（　Ａ　）や異なるカテゴリーの新製品を導入する（　Ｂ　）がとられる。  

同一ブランドでのさらなる市場浸透策が難しいと判断される場合には、同じカテゴリーに新ブランドを展開する（　Ｃ　）や、他社との共同開発という形をとり、自社のブランド名と他社の人気ブランド名の2つを同一製品で用いる（　Ｄ　）が検討される。  

（設問２）

文中の下線部①の｢ブランド｣に関する記述として、最も適切なものはどれか。  
  
ア  自社ブランドが｢拒否集合｣に入っている場合、消費者は購買か非購買かの判  
断に必要な情報を持っていない。  
  
イ  製品ライフサイクルの考えに基づけば、成長期に入ったブランドは、急速に  
市場に受け入れられ、その売上・利益ともに増えるため、企業はセールス・プ  
ロモーション活動を抑制してもよい。  
  
ウ  人は自分のパーソナリティに合うブランドを選ぶ傾向があるため、多くの有  
名ブランドは、｢洗練｣、｢興奮｣、｢誠実｣など、明確なブランド・パーソナリテ  
ィの構築に努めている。  
  
エ  ブランド担当者は、顧客獲得を巡り、同じカテゴリーにおいて他社とのブラ  
ンド間競争に、集中して対処する必要がある。  
        
`
        },
        {
                "mondaiId": 103280313,
                "name": "第31問設問3",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
                
次の文章を読んで、下記の設問に答えよ。  

多くの消費者の支持を得ることができた<u>①ブランド</u>をどのように管理し、成長させていくかは、企業収益を左右する重要な課題である。<u>②ブランド開発戦略</u>として説明 されているように、例えば、同じブランド名を用いて、同じカテゴリーに形、色、サイズ、フレーバーなどを変えた製品を導入する（　Ａ　）や異なるカテゴリーの新製品を導入する（　Ｂ　）がとられる。  

同一ブランドでのさらなる市場浸透策が難しいと判断される場合には、同じカテゴリーに新ブランドを展開する（　Ｃ　）や、他社との共同開発という形をとり、自社のブランド名と他社の人気ブランド名の2つを同一製品で用いる（　Ｄ　）が検討される。  

（設問３）
文中の下線部②の｢ブランド開発戦略｣に関する記述として、最も適切なものは  
どれか。  
  
ア  既存のブランド名を用いて、異なるカテゴリーの新製品を導入することは、  
当該ブランド名が元の製品カテゴリーと強い結びつきを有している場合には、  
容易である。  
  
イ  同一製品カテゴリーにおいて新しいブランド(群)を適正規模で展開すること  
は、顧客セグメントごとの細かなニーズへの対応を可能にする。それは、製品  
特徴を際立たせるための有効な手段となる。  
  
ウ  同一ブランド名で形、色、サイズ、フレーバーなどを変えた製品を同じカテ  
ゴリーに導入することにより、小売店頭でより大きなシェルフスペースを確保  
できたり、バラエティを望む消費者のニーズに応えられたりするので、一般的  
に、リスクのない新製品導入の方法といえる。  
  
エ  ブランドは顧客からの長期的な愛顧を目指すものであるため、同一製品カテ  
ゴリーであれ、異なる製品カテゴリーであれ、積極的な新ブランドの開発は一  
般的に、経営資源の効率的な活用につながる。  
        
`
        },
        {
                "mondaiId": 103280320,
                "name": "第32問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
顧客が製品やサービスに求める価値は、基本価値、便宜価値、感覚価値と観念価  
値の4つに分けられる。これらの価値に関する記述として、最も適切なものはどれ  
か。  
  
ア  すべての価値を一度に高められない場合、基本価値のレベルにかかわらず、タ  
ーゲットに応じて他の価値のいずれかを強化することが得策である。  
  
イ  製品やサービスが顧客にもたらす基本価値や便宜価値は、普遍性や安定性が高  
く、顧客は価値を理解しやすい。したがって、顧客の満足を得るために企業担当  
者は、常に、機能を増やし、効用を高め続けることを強いられている。  
  
ウ  製品やサービスの感覚価値は、顧客の客観的な優劣判断を困難にする。そのた  
め、この価値を高めることで、企業は一般的に価格競争に巻き込まれやすくな  
る。  
  
エ  入手の難しい高価なブランドにおいては、観念価値の作用する割合が大きく、  
ブランドの歴史や物語などの訴求を通じて、ブランドの高い価値を支えている。  
        
`
        },
        {
                "mondaiId": 103280330,
                "name": "第33問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費者の購買行動に関する記述として、最も適切なものはどれか。  
  
ア  精緻化見込みモデルに基づけば、人が時間や労力をかけた購買意思決定プロセ  
スをとるのは、以下のいずれかの条件が満たされた場合である。それは、｢製品  
やサービスの購買に対する動機づけレベルが高い場合｣、｢情報処理の能力を有す  
る場合｣、あるいは、｢利用可能な情報に接する機会や時間がある場合｣である。  
  
イ  多属性態度理論に基づけば、人は、製品が有するある属性のマイナス面を他の  
属性のプラス面で相殺することをしない。  
  
ウ  特定の製品カテゴリーに対する関与が高い場合、知識が少ない人より多い人の  
ほうが、満足の最大化を目指して、限定的な意思決定プロセスをとりやすい。  
  
エ  ｢見せかけのロイヤルティ｣の顧客とは、対象製品やサービスに対して好ましい  
態度や高い購買意向を持ちながら、購買行動に移さない人のことをいう。  
`
        },
        {
                "mondaiId": 104280010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ある機械加工職場における生産リードタイムの短縮を目指した改善活動に関する  
記述として、<u>最も不適切なものはどれか</u>。  
  
ア  処理を開始してすべての処理を完了するまでの総所要時間を短くするために、  
ディスパッチングルールを変更した。  
  
イ  流れ線図を作成し、｢設備間の距離×物流量の総和｣を計算してレイアウトを変  
更した。  
  
ウ  納期管理を徹底するために、PERTを使ってロットサイズを変更した。  
  
エ  マンマシンチャートを作成し、作業者の作業手順を変更した。`
        },
        {
                "mondaiId": 104280020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産形態に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  少品種多量生産では、加工・組立の工数を少なくする製品設計が有用である。  
  
イ  少品種多量生産では、工程の自動化が容易で、品種の変化に対するフレキシビ  
リティが高い。  
  
ウ  多品種少量生産では、進捗管理が難しく、生産統制を適切に行わないと納期遵  
守率が低下する。  
  
エ  多品種少量生産では、汎用設備の活用や多能工化が有用である。  
        
`
        },
        {
                "mondaiId": 104280030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
プッシュ型管理方式およびプル型管理方式に関する記述として、最も適切なもの  
はどれか。  
  
ア  プッシュ型管理方式では、顧客の注文が起点となって順番に製造指示が発生す  
るため、余分な工程間在庫を持つ必要がない。  
  
イ  プッシュ型管理方式では、生産計画の変更は最終工程のみに指示すればよい。  
  
ウ  プル型管理方式では、管理部門が生産・在庫情報を集中的に把握する必要があ  
り、大掛かりな情報システムなどの仕掛けが必要となる。  
  
エ  プル型管理方式では、稼働率を維持するための作りだめなどができないため、  
過剰在庫が発生する可能性は少ない。`
        },
        {
                "mondaiId": 104280040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
VEにおける製品の機能に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  貴重機能は製品の使用目的にかかわる機能である。  
  
イ  製品の機能は基本機能と二次機能に分類され、二次機能は基本機能を補助す  
る。  
  
ウ  必要機能はその製品の基本機能になる場合が多いが、貴重機能が基本機能にな  
る場合もある。  
  
エ  不必要機能は製品の二次機能に発生する場合が多い。  
        
`
        },
        {
                "mondaiId": 104280050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、機械加工設備をロットサイズと製品の種類で分類したものである。空欄  
①~③にあてはまる設備の組み合わせとして、最も適切なものを下記の解答群から  
選べ。  

<imgTag>104280050_1.png</imgTag>  

（解答群）  
  
ア  ①：FMS　②：トランスファーマシン　③：汎用工作機械  
  
イ  ①：FMS　②：汎用工作機械　③：トランスファーマシン  
  
ウ  ①：汎用工作機械　②：FMS　③：トランスファーマシン  
  
エ  ①：汎用工作機械　②：トランスファーマシン　③：FMS  
        
`
        },
        {
                "mondaiId": 104280060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産ラインの工程編成に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  サイクルタイムは、生産ラインに資材を投入する時間間隔を規定する。  
  
イ  正味稼働時間を生産量で除算することにより、サイクルタイムを求めることが  
できる。  
  
ウ  総作業時間を生産速度で除算することにより、最小工程数を求めることができ  
る。  
  
エ  バランスロスは、１から編成効率を減算することで求めることができる。`
        },
        {
                "mondaiId": 104280070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製番管理方式の特徴に関する記述として、最も適切なものはどれか。  
  
ア  多くの製品に共通して使用する部品の発注に適している。  
  
イ  継続生産における部品の数量統制に適している。  
  
ウ  製造命令書の発行時に在庫中の常備品を引き当てることができる。  
  
エ  納期変更や製品仕様の変更があった場合に、特定の部品の発注指示、生産指示  
などの変更が容易である。  
        
`
        },
        {
                "mondaiId": 104280080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品Aは調達ロットサイズが20単位で、リードタイムは２期である(n期の期末に発注したものは、n＋2期の期首に納入される)。各期の所要量は必ず確保することを前提に、期末在庫量が最小になるように各期の発注量を決定する。1期から5期までの所要量などの情報の一部が下表で与えられているとき、1期から3期までの発注量の合計(表の①~③の合計)として、最も適切なものを下記の解答群から選べ。  


<imgTag>104280080_1.png</imgTag>  

（解答群）  
  
ア  80  
  
イ  95  
  
ウ  100  
  
エ  120  
        
`
        },
        {
                "mondaiId": 104280090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、製品Aの部品構成を示している。製品Aを30台組み立てる際に、部品dの所要量として、最も適切なものを下記の解答群から選べ(単位：個)。  

製品Aの部品構成表  
<imgTag>104280090_1.png</imgTag>  

（解答群）  
  
ア  240  
  
イ  390  
  
ウ  570  
  
エ  750  
        
`
        },
        {
                "mondaiId": 104280100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、あるプロジェクト業務を行う際の各作業の要件を示している。CPM  (Critical Path Method)を適用して、最短プロジェクト遂行期間となる条件を達成したときの最小費用を、下記の解答群から選べ(単位：万円)。


<imgTag>104280100_1.png</imgTag>  

（解答群）  
  
ア  650  
  
イ  730  
  
ウ  790<hint hidden='true'>**
←⭕
**</hint>    
  
エ  840  
        
`
        },
        {
                "mondaiId": 104280110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
工数計画およびそれに対応した余力管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  各職場・各作業者について手持仕事量と現有生産能力とを調査し、これらを比  
較対照したうえで手順計画によって再スケジュールをする。  
  
イ  工数計画において、仕事量や生産能力を算定するためには、一般的に作業時間  
や作業量が用いられる。  
  
ウ  工数計画において求めた工程別の仕事量と日程計画で計画された納期までに完  
了する工程別の仕事量とを比較することを並行的に進めていき、生産能力の過不  
足の状況を把握する。  
  
エ  余力がマイナスになった場合に、就業時間の延長、作業員の増員、外注の利  
用、機械・設備の増強などの対策をとる。`
        },
        {
                "mondaiId": 104280120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
内外作区分に関連する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  一過性の需要に対応するためには、生産設備を増強して、内作で対応すること  
が好ましい。  
  
イ  自社が特殊な技術を持っており、その優位性を維持するためには、該当する部  
品を継続的に内作することが好ましい。  
  
ウ  特許技術のような特に優れた技術を他社が持っている場合には、外作すること  
が好ましい。  
  
エ  秘密性や重要性が低い部品で、自社において稼働率が低く、コストが引き合わ  
ないときには外作することが好ましい。  
        
`
        },
        {
                "mondaiId": 104280130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
品質保証活動の中で用いられる品質展開に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  顧客の要求や設計者の意図を生産部門まで確実に伝えるために、品質展開を行  
った。  
  
イ  顧客の要求を技術的な品質特性に変換するために、品質特性展開表を作成し  
た。  
  
ウ  顧客の要求を整理するために、要求品質展開表と構成部品展開表から品質表を  
作成した。  
  
エ  他社製品との比較や自社製品のセールスポイントを設定するために、品質展開  
の結果を活用した。`
        },
        {
                "mondaiId": 104280140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
作業管理に利用される｢標準作業｣に関する記述として、最も不適切なものはどれ  
か。  
  
ア  作業管理者を中心に、IEスタッフや現場作業者の意見を入れて全員が納得し  
た作業でなければならない。  
  
イ  作業者の教育・訓練の基礎資料とするため、熟練作業者であれば実施可能にな  
る最善の作業でなければならない。  
  
ウ  生産の構成要素である4M(Man，Machine，Material，Method)を有効に活用し  
た作業でなければならない。  
  
エ  製品または部品の製造工程全体を対象にした作業順序・作業方法・管理方法・  
使用設備などに関する基準の規定でなければならない。  
        
`
        },
        {
                "mondaiId": 104280150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
作業改善を目的とした時間測定と分析に関する記述として、<u>最も適切なものはどれか</u>。  
  
ア  作業時間が管理状態にあるかどうかを確認するために、pn管理図を作成して  
分析した。  
  
イ  作業時間の測定精度を高めるために、やり直しを行った作業等の異常値は記録  
から除外して測定を行った。  
  
ウ  作業方法の変化を見つけ易くするために、作業の各サイクルに規則的に表れる  
要素作業と不規則に表れる要素作業は区別して時間測定を行った。  
  
エ  測定対象となる作業者に心理的な負担を与えないために、測定の実施を事前に  
通告せずに作業者から見えない場所で測定を行った。  
        
`
        },
        {
                "mondaiId": 104280160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
        
人の作業者が電気部品の組み立てを行っている工程でワークサンプリング法を  
実施した結果が下記に示されている。この実施結果から算出される｢主体作業｣と  
｢職場余裕｣の時間構成比率の組み合わせとして、最も適切なものを下記の解答群か  
ら選べ。  

作業項目：度数  
ハンダ付け　：　120  
基盤への部品の取り付け　：　90  
基盤のネジ止め　：　80  
組立作業完了後の製品検査(全数)　：　60  
ロット単位での完成部品の運搬　：　33  
不良品の手直し　：　30  
ネジ・ハンダの補充(不定期)　：　22  
部品不足による手待ち　：　24  
打ち合わせ　：　19  
朝礼　：　12  
水飲み　：　5  
用便　：　5  
合計　：　500  

（解答群）  
  
ア  主体作業：58％　職場余裕：11％  
  
イ  主体作業：58％　職場余裕：12％  
  
ウ  主体作業：70％　職場余裕：11％  
  
エ  主体作業：70％　職場余裕：12％  
        
`
        },
        {
                "mondaiId": 104280170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
サーブリッグ分析で用いられる記号は、次の3つに分類される。  

第１類：仕事を行ううえで必要な動作要素  
第２類：第１類の作業の実行を妨げる動作要素  
第３類：作業を行わない動作要素  

下表は、｢部品容器から左手で取り出した部品を右手に持ち換えた後、ある定め  
られた位置に部品を定置する動作｣をサーブリッグ分析したものである。この動作  
の中で第１類に分類される左手の動作要素の数と右手の動作要素の数の組み合わせ  
として、最も適切なものを下記の解答群から選べ。  

部品を取り置く動作のサーブリッグ分析の結果  
<imgTag>104280170_1.png</imgTag>  


（解答群）  
  
ア  左手：３個　右手：２個  
  
イ  左手：４個　右手：３個  
  
ウ  左手：５個　右手：４個  
  
エ  左手：６個　右手：５個  
        
`
        },
        {
                "mondaiId": 104280180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、ある設備の故障状況に関して、故障後の設備修復が終わってから再び故  
障に至るまでの故障間隔とその頻度を度数分布表にまとめたものである。設備の修  
復時間をある一定時間以下に短縮することにより、90％以上のアベイラビリティ  
(可用率)を達成したい。これを達成するための設備の平均修復時間の最大値とし  
て、最も適切なものを下記の解答群から選べ(単位：時間)。  

<imgTag>104280180_1.png</imgTag>  

（解答群）  
  
ア  6  
  
イ  8  
  
ウ  10  
  
エ  12  
        
`
        },
        {
                "mondaiId": 104280190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
製品製造のための年間固定費と変動費単価がそれぞれ異なる3つの設備案A、  
B、Cの中から、年間の総費用が最小となる最適設備を選択することを考える。設  
備間での生産量に関する優劣分岐点は、以下の値であることが分かっている。設備  
の年間固定費がA、B、Cの順に高いとき、最適設備の選択に関する記述として、  
最も適切なものを下記の解答群から選べ。  
        
1．設備Aと設備Bの生産量に関する優劣分岐点は2，500個／年である。  
2．設備Bと設備Cの生産量に関する優劣分岐点は7，500個／年である。  
3．設備Aと設備Cの生産量に関する優劣分岐点は5，000個／年である。  
（解答群）  
  
ア  年間の生産量が2，000個のとき、設備Aを選択した。  
  
イ  年間の生産量が4，000個のとき、設備Bを選択した。  
  
ウ  年間の生産量が6，000個のとき、設備Aを選択した。  
  
エ  年間の生産量が8，000個のとき、設備Cを選択した。  
        
`
        },
        {
                "mondaiId": 104280200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
個別受注生産を行う工場において、次月の計画として下表に示すA~Eの注文を  
受注した。すべての注文を社内で処理する(すなわち、内作する)能力がないため、  
いくつかの注文については外作を行うことを検討している。以下の条件のもとで内  
外作に割り当てる注文を適切に決定することにより、内作費用と外作費用を合わせ  
た総費用の最小化を考える。総費用の最小値として、最も適切なものを下記の解答  
群から選べ(単位：万円)。  
        
1．注文を処理するのに必要な処理時間、内作で処理した場合に発生する費用、  
外作で処理した場合に発生する費用は下表のとおりである。  

<imgTag>104280200_1.png</imgTag>  

2．内作では、2つ以上の注文を同時に処理することはできない。  
3．上表の注文の処理に利用できる社内製造時間は30時間である。  

（解答群）  
  
ア  69  
  
イ  84  
  
ウ  88  
  
エ  90  
        
`
        },
        {
                "mondaiId": 104280210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
2段階の直列工程で毎日80個の単一製品を製造する生産ラインを考える。製品1
個当たりの前工程での処理時間は4分、後工程での処理時間は5分であり、処理  
時間のバラツキは両工程ともに十分に小さい。また、前工程から後工程への中間製  
品の運搬ロットサイズは10個としている。80個の製品の総生産時間を短縮するた  
めの方策に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  作業改善によって後工程での処理時間を短縮する。  
  
イ  前工程と後工程での担当作業を見直し、生産ラインの編成効率を高める。  
  
ウ  前工程と後工程の間の運搬ロットサイズを小さくする。  
  
エ  前工程の入口と後工程の出口とを｢かんばん｣で結ぶ。`
        },
        {
                "mondaiId": 104280220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
工場内で利用される産業用ロボットに関する記述として、最も適切なものはどれ  
か。  
  
ア  運転中の作業者への危険を回避するため、労働安全衛生法の規制対象となる産  
業用ロボットを運転する際には、柵または囲いを必ず設けなければならない。  
  
イ  垂直多関節型ロボットは、上下方向に部品を強く押し込んだりする作業の自動  
化に向いている。  
  
ウ  水平多関節型ロボットは、多方向からの複雑な作業の自動化に向いている。  
  
エ  労働安全衛生法の規制対象となる産業用ロボットの可動範囲内において教示等  
を行う作業者は、同法で定める特別教育を必ず受講しなければならない。  
        
`
        },
        {
                "mondaiId": 104280230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
平成26年に、中心市街地活性化法の一部が改正された。改正前に、内閣官房・  
中心市街地活性化推進委員会がG中心市街地活性化に向けた制度・運用の方向性t平  
成25年12月xHをとりまとめた。当該報告書における、平成26年改正前の中心市  
街地活性化法に基づき認定された中心市街地の状況に関する記述として、最も適切  
なものはどれか。  
  
ア  認定された中心市街地内における大規模小売店舗の出店件数(大規模小売店舗  
立地法に基づく届出数)は、認定された中心市街地外と比べて多かった。  
  
イ  認定された中心市街地内の小売業の事業所数・年間販売額は増加していた。  
  
ウ  平成24年度末までに基本計画が終了した市町村において、基本計画に設定さ  
れた評価指標のうち、目標を達成した評価指標は全体の5割に達していた。  
  
エ  平成24年度末までに基本計画が終了した市町村において、基本計画に設定さ  
れた評価指標別の目標達成率としては、｢通行量｣、｢施設入込数等｣が比較的高い  
のに対し、｢空き店舗等｣が低かった。`
        },
        {
                "mondaiId": 104280240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店舗(一般住居と併用するものは除く)における防火管理に関する記述とし  
て、最も適切なものはどれか。  
  
ア  店舗に設置されている消火器具や火災報知設備などの機器点検は、1年に1回  
行わなければならない。  
  
イ  店舗に設置されている非常電源や配線の総合点検は、2年に1回行わなければ  
ならない。  
  
ウ  店舗は、機器点検・総合点検を行った結果を消防長または消防署長へ3年に1回報告しなければならない。  
  
エ  店舗は、特定防火対象物である。  
        
`
        },
        {
                "mondaiId": 104280251,
                "name": "第25問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
外国人旅行者の状況や外国人旅行者向け免税店制度の内容に関する以下の設問に答えよ。  

（設問1)

観光庁のG訪日外国人の消費動向平成27年年次報告書Hから確認できる近  
年の訪日外国人の実態に関する記述として、最も適切なものはどれか。  
  
ア  平成27年の訪日外国人の年間旅行消費額は3兆円を超え、過去最高額を記  
録している。  
  
イ  平成27年の訪日外国人の年間旅行消費額を費目別にみると、買物代が50％  
を超える。  
  
ウ  平成27年の訪日外国人の年間旅行消費額の多い国・地域のトップ3は、米  
国、タイ、韓国である。  
  
エ  訪日外国人の年間旅行消費額は、平成22年から平成27年まで毎年連続で増  
加している。  
        
`
        },
        {
                "mondaiId": 104280252,
                "name": "第25問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
外国人旅行者の状況や外国人旅行者向け免税店制度の内容に関する以下の設問に答えよ。  

（設問2)  
消費税免税店t輸出物品販売場x制度に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  一般物品の免税対象額は、同一の非居住者に対して、同一店舗における1日  
の一般物品の販売合計額が3千円を超えるものであること。  
  
イ  消耗品においては、免税購入する非居住者から、購入後30日以内に輸出す  
る旨の購入者誓約書を提出してもらうことが免税販売における要件である。  
  
ウ  免税手続きカウンターを設置した商店街と隣接している商店街は、一つの特  
定商業施設として免税販売手続きが可能である。  
  
エ  輸出物品販売場を経営する事業者は、所定の手続きを踏めば、外航クルーズ  
船が寄港する港湾の施設内に臨時販売場を設置して免税販売をすることができ  
る。`
        },
        {
                "mondaiId": 104280260,
                "name": "第26問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経済産業省の「買物弱者応援マニュアルVer.3.0」における｢買物弱者｣に対する流  
通業者やサービス業者の取り組みとして、<u>最も不適切なものはどれか</u>。  
  
ア  郊外での大型店の出店・開発  
  
イ  消費者からの注文に応じて商品を届ける宅配サービス  
  
ウ  消費者の居住地域での仮設店舗の出店  
  
エ  商品を積載した車による移動販売  
  
オ  来店手段となるバス等の運行  
        
`
        },
        {
                "mondaiId": 104280270,
                "name": "第27問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店の商品仕入に関する記述として、最も適切なものはどれか。  
  
ア  委託仕入では、一定期間店頭で販売し、売れ残った商品だけ小売店が買い取  
る。  
  
イ  委託仕入では、商品の販売価格は原則として小売店が自由に設定する。  
  
ウ  委託仕入において、店頭在庫の所有権は小売店にある。  
  
エ  消化仕入では、商品の販売時に小売店に所有権が移転する。  
  
オ  消化仕入をすると、小売店の廃棄ロスが発生しやすい。`
        },
        {
                "mondaiId": 104280280,
                "name": "第28問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店の商品管理に関する記述として、最も適切なものはどれか。  
  
ア  ある期間の商品回転率が6である場合、当該期間の売上高は期末在庫高の6倍  
である。  
  
イ  売上高が減った場合でも、平均在庫高を一定に保てば商品回転率は維持でき  
る。  
  
ウ  売場に商品を補充する際、先入れ先出しをすると必ず商品回転率が高まる。  
  
エ  売れ筋商品の品ぞろえを増やして売上高が増加すれば、平均在庫高が増えたと  
しても必ず商品回転率が高まる。  
  
オ  需要期後に売れ残った季節商品を値引きや廃棄して処分すると、その処分をし  
ないよりも商品回転率は高まる。  
        
`
        },
        {
                "mondaiId": 104280290,
                "name": "第29問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、商品Aから商品Eの5商品の販売棚における陳列数と最近1か月の売  
上数量を示したものである。これらの5商品の商品単価と商品パッケージのサイズ  
は同じで商品棚に陳列できる最大フェイス数は20とした場合、棚全体の売上数量  
を増やすために商品棚割を改善する考え方に関する記述として、最も適切なものを  
下記の解答群から選べ。なお、期間中に品切れは発生していなかったものとする。  

<imgTag>104280290_1.png</imgTag>  

（解答群）  
  
ア  売場面積あたりの生産性が最も高い商品Aのフェイス数を増やす。  
  
イ  商品Dと商品Eのフェイス数を2ずつにそろえる。  
  
ウ  商品補充の作業性の面で最も効率が悪い商品Dのフェイス数を増やす。  
  
エ  フェイス数を1つ増やしたときに売上数量が増えるフェイス効果は、商品  
Aより商品Eの方が高い。  
        
`
        },
        {
                "mondaiId": 104280300,
                "name": "第30問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費者の購買慣習からみた商品分類として、最寄品、買回品、専門品という分類  
がある。これら3つの分類と分類にあてはまる商品の一般的な特徴に関する次のａ~ｃの記述の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　消費者は商品へのこだわりがあり、複数の店舗を比較して買う。  
ｂ　消費者は手近にある情報により、買うことを決める。  
ｃ　消費者は時間をかけることを惜しまずに、遠方の店舗でも買いに行く。  

（解答群）  
  
ア  買回品―ｂ  
  
イ  専門品―ｂ  
  
ウ  専門品―ｃ  
  
エ  最寄品―ａ  
  
オ  最寄品―ｃ  
        
`
        },
        {
                "mondaiId": 104280310,
                "name": "第31問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表の条件で3種類の商品を仕入れ、販売単価を設定したとき、3商品全体の売  
価値入率t小数点第2位を四捨五入xとして、最も適切なものを下記の解答群から選  
べ。  

　　　　仕入単価 (円)　販売単価(円)　仕入数量(個)  
商品A　　　　60　　　　　　100　　　　　　300  
商品B　　　　70　　　　　　140　　　　　　100  
商品C　　　　90　　　　　　120　　　　　　200  
（解答群）  
  
ア  36.8％  
  
イ  38.3％  
  
ウ  61.7％  
  
エ  63.2％  
        
`
        },
        {
                "mondaiId": 104280320,
                "name": "第32問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売業の販売促進の方法と主な目的に関する記述として、最も適切なものはどれ  
か。  
  
ア  売り場におけるクロスマーチャンダイジングは、関連する商品同士を並べて陳  
列することで、計画購買を促進する狙いがある。  
  
イ  エンドなどにおける大量陳列は、商品の露出を高めて買い忘れを防止するな  
ど、計画購買を促進する狙いがある。  
  
ウ  会計時に発行するレシートクーポンは、次回来店時の計画購買を促進する狙い  
がある。  
  
エ  試食販売などのデモンストレーション販売は、リピート購買を促進する狙いが  
ある。  
  
オ  新聞折り込みチラシは、お買い得商品の情報を伝えて、想起購買を促進する狙  
いがある。  
        
`
        },
        {
                "mondaiId": 104280330,
                "name": "第33問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店舗における在庫管理に関する記述として、最も適切なものはどれか。  
  
ア  あらかじめ設定した発注点に基づいて発注すると、発注間隔は必ず一定にな  
る。  
  
イ  安全在庫は、需要の変動に備えて過剰在庫を防止するために設定する在庫量の  
ことである。  
  
ウ  需要の変動に備えて安全在庫を見込む場合でも、定期発注方式で発注量を算定  
するときには安全在庫を含めない。  
  
エ  発注から補充までの期間が短いほど、安全在庫量を少なくすることができる。`
        },
        {
                "mondaiId": 104280340,
                "name": "第34問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流ネットワークに関する記述として、最も適切なものはどれか。  
  
ア  商品回転率が低い商品は、多くの物流拠点に分散在庫するよりも、少数の物流  
拠点に集中在庫する方が望ましい。  
  
イ  複数の物流拠点を水平的に統合すると、通常、物流段階数が少なくなる。  
  
ウ  物流活動は、販売活動を行う各営業所がそれぞれ物流拠点を設けて行わなけれ  
ばならない。  
  
エ  物流拠点の数は、輸配送コスト以外に物流サービスだけを考慮すれば合理的に  
決定することができる。  
        
`
        },
        {
                "mondaiId": 104280350,
                "name": "第35問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
輸配送管理に関する用語の記述として、最も適切なものはどれか。  
  
ア  一貫パレチゼーションは、積載効率を高め、輸送効率を向上させる。  
  
イ  パレチゼーションは、自家用物流施設内では行われず、自家用物流施設と社外  
物流施設間において行われる。  
  
ウ  プールパレットとは、自家用物流施設内で商品を保管することを主目的とした  
パレットのことである。  
  
エ  複合一貫輸送とは、ある輸送単位の貨物を組み替えることで、異なる輸送機関  
を組み合わせて行う輸送のことである。  
  
オ  ユニットロードとは、複数の物品又は包装貨物を、機械及び器具による取扱い  
に適するように、パレット、コンテナなどを使って一つの単位にまとめた貨物の  
ことである。`
        },
        {
                "mondaiId": 104280360,
                "name": "第36問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
共同物流に関する記述として、最も適切なものはどれか。  
  
ア  共同物流とは、複数の企業が物流機能を共同化することであり、同業種の企業  
同士で行われ、異業種の企業同士では行われない。  
  
イ  共同物流は、複数の企業にとって、配送先の店舗や物流拠点が共通していると  
きに行われ、配送先が異なるときには行われない。  
  
ウ  共同物流を担う物流事業者を指定するのは発荷主であり、着荷主が指定するこ  
とはない。  
  
エ  複数の企業が共同配送を行うと、各企業がそれぞれ配送していたときに比べ  
て、配送車両の積載効率が高まることはあるが、各企業にとっての配送数量が減  
ることはない。  
  
オ  複数の企業がそれぞれ所有する商品を共同で配送することはあるが、同じ物流  
拠点に共同で保管することはない。  
        
`
        },
        {
                "mondaiId": 104280370,
                "name": "第37問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センターの機能に関する記述として、最も適切なものはどれか。  
  
ア  クロスドッキングとは、物流センターの荷受場で、入荷品を事前出荷通知に基  
づき保管するか出荷するかを識別して、出荷品を出荷場に通過させることであ  
る。  
  
イ  店舗に対して一括物流を行うには、物流センターで在庫を持つ必要がある。  
  
ウ  店舗の発注から店舗への納品までの期間は、一般的に、在庫を持たない物流セ  
ンターを経由して納品する方が、在庫を持つ物流センターを経由して納品するよ  
りも短い。  
  
エ  包装は、内装と外装に大別され、前者を商業包装、後者を工業包装ともいう。  
  
オ  保管機能とは、商品を一定の場所で、品質、数量の保持など適正に管理し、空  
間的懸隔と時間的懸隔を克服するものである。`
        },
        {
                "mondaiId": 104280380,
                "name": "第38問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センターの運営に関する記述として、最も適切なものはどれか。  
  
ア  仕分けとは、物品を品種別、送り先方面別、顧客別などに分ける作業のことで  
あり、シングルピッキングの後に行われ、トータルピッキングの後には行われな  
い。  
  
イ  棚卸方法の一つである循環棚卸は、実在庫量と理論在庫量の差異を補正するた  
めに行われる。  
  
ウ  荷主は、物流センターの運営を物流事業者に委託するとき、委託先の物流事業  
者が所有する物流センターを利用しなければならない。  
  
エ  ピッキングとは、保管場所から必要な物品を取り出す作業のことであり、ピッ  
カーが保管場所まで移動しなければならない。  
        
`
        },
        {
                "mondaiId": 104280391,
                "name": "第39問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある小売店の一定期間におけるID-POSデータを用いて、100人のある顧客セグ  
メントに対するマーケットバスケット分析を行ったところ、商品aと商品bの購  
買に関して、下表のような結果が得られたとする。  
このとき、以下の設問に答えよ。  

購買した商品群　／　購買した顧客数  
商品a　　　　　／　20  
商品b　　　　　／　40  
商品aかつ商品　／　b10  

（設問１）
支持度tサポートxに関係する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  商品aのみを購買した顧客数は10人である。  
  
イ  商品bのみを購買した顧客数は30人である。  
  
ウ  商品aと商品bを共に購買した顧客数は10人である。  
  
エ  商品aも商品bも購買していない顧客数は40人である。  
  
オ  商品aも商品bも購買していない顧客数は、商品bのみを購買した顧客数  
より多い。`
        },
        {
                "mondaiId": 104280392,
                "name": "第39問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
ある小売店の一定期間におけるID-POSデータを用いて、100人のある顧客セグ  
メントに対するマーケットバスケット分析を行ったところ、商品aと商品bの購  
買に関して、下表のような結果が得られたとする。  
このとき、以下の設問に答えよ。  

購買した商品群　／　購買した顧客数  
商品a　　　　　／　20  
商品b　　　　　／　40  
商品aかつ商品　／　b10  

（設問2)  
リフト値(lift商品(a⇒商品b))の値として、最も適切なものはどれか。  
  
ア  0.25  
  
イ  0.50  
  
ウ  1.00  
  
エ  1.25  
  
オ  2.50  
        
`
        },
        {
                "mondaiId": 104280400,
                "name": "第40問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
従来のバーコードでは実現が困難であった高度な商品等の管理や業務の効率化を  
実現するツールとして、近年、電子タグ(ICタグ、RFタグ、無線タグなど)が注目  
されている。この電子タグの特徴に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  電子タグのICチップには、メモリが搭載されており、識別情報などを記録す  
ることができる。  
  
イ  電子タグは、金属で被覆しても、通常非接触で読み取り可能である。  
  
ウ  電子タグは、必要に応じて、メモリに書き込まれたデータを保護し、セキュリ  
ティを強化することができる。  
  
エ  電子タグは、無線を使って通信するため、非接触で読み取り可能である。  
  
オ  電子タグは、用途に応じてカード型やボタン型の形状が存在し、小型化や薄型  
化も進んでいる。`
        },
        {
                "mondaiId": 104280410,
                "name": "第41問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
流通システム開発センターが定める｢GTINアロケーション(設定)ガイドライン  
(2009年版)｣によると、個々にGTINを設定すべき要素として、<u>最も不適切なものはどれか</u>。  
  
ア  商品の正味量  
  
イ  商品の等級  
  
ウ  商品の販売店舗  
  
エ  商品ブランド名  
  
オ  商品名  
        
`
        },
        {
                "mondaiId": 104280420,
                "name": "第42問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢個人情報の保護に関する法律についての経済産業分野を対象とするガイドライ  
ン(平成26年12月)｣の対象となっている個人情報として、<u>最も不適切なものはどれか</u>。  
  
ア  企業が保有している雇用管理情報  
  
イ  企業の財務情報等、法人等の団体そのものに関する情報  
  
ウ  特定個人を識別できる情報ではないが、周知の情報の補完によって個人を識別  
できる情報  
  
エ  日本国民ではない外国人の個人に関する情報  
  
オ  防犯カメラに記録された情報等本人が判別できる映像情報`
        },
        {
                "mondaiId": 104280430,
                "name": "第43問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある共同購入クーポンサイトで、出品数、購入条件、販売期間、取引成立量を限  
定し、高割引率を設定するフラッシュマーケティングを活用してクーポン(商品・サービス)を販売する場合、その期待する効果として、<u>最も不適切なものはどれか</u>。  
  
ア  初回限定のトライアル利用商品の販売を通じた新規顧客の獲得  
  
イ  ソーシャルメディアでの情報拡散を通じた商品や店舗の宣伝  
  
ウ  提供サービス閑散期における稼働率の上昇  
  
エ  リピーターの増大  
`
        },
        {
                "mondaiId": 105280010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株式会社の役員に関する記述の組み合わせとして、最も適切なものを下記の解答  
群から選べ。  

ａ定款で定めれば、株主総会において、議決権を行使することができる株主の  
議決権の3分の1以上の割合を有する株主が出席し、出席した当該株主の議決  
権の過半数をもって、監査役を解任することができる。  

ｂ定款で定めれば、増員として選任された監査役の任期を、他の現任監査役の  
任期の満了する時までとすることができる。  

ｃ公開会社でない株式会社は、監査等委員会設置会社又は指名委員会等設置会  
社でない限り、取締役の任期について、定款で定めることにより、選任後10  
年以内に終了する事業年度のうち最終のものに関する定時株主総会終結の時ま  
で伸長することができる。  

ｄ正当な理由なく取締役を解任された者は、解任によって生じた損害の賠償を  
株式会社に対して請求することができる。ここでいう損害には、残存任期中に  
支給を受けるはずだった取締役の報酬等も含まれる。  
（解答群）  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 105280020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
X株式会社(以下｢X社｣という。)の株主であるA株式会社(以下｢A社｣という。)
からの譲渡承認請求に関する以下の会話は、中小企業診断士であるあなたとX社  
の代表取締役甲氏との間で行われたものである。会話の中の空欄に入る語句とし  
て、最も適切なものを下記の解答群から選べ。なお、X社は、発行する株式の全  
てが譲渡制限株式であり、取締役会設置会社であるとする。また、これらの点を除  
き、定款に特段の定めもX社とA社との合意による別段の定めもないものとす  
る。  

甲氏：｢当社の株主A社から、このような請求書が内容証明郵便で届きました。｣  

あなた：｢どれどれ・・・。『A社が保有している株式をB株式会社(以下｢B社｣と  
いう。)に譲渡したいので、B社がその株式を取得することについて承認す  
るかどうかを決定してほしい。もし、承認しない場合には、X社かX社  
が指定する第三者に買い取ってほしい。』という内容ですね。甲さんは、B  
社が株主になっても構わないのですか。｣  

甲氏：｢正直B社という会社がどういう会社なのか全く分からないので、できれ  
ば株主にはなってほしくないですね。｣  

あなた：｢この請求書は、いつX社に届いたのですか。｣  

甲氏：｢平成28年8月10日です。｣  

あなた：｢そうすると、（　　　）までに、承認しない旨の通知がA社に届かない  
と、承認したものとみなされてしまって困ったことになりますね。承認し  
ない旨の通知も内容証明郵便で送った方がいいと思います。また、その後  
に、X社が買うか、買取人を指定するかの手続も控えていますから、早  
く顧問弁護士の先生に相談した方がいいと思いますよ。｣  

甲氏：｢分かりました。すぐにでも連絡を取ってみます。｣  
        
        
【参考】平成28年8月のカレンダー  
日 月 火 水 木 金 土  
    1  2  3  4  5  6  
 7  8  9 10 11 12 13  
14 15 16 17 18 19 20  
21 22 23 24 25 26 27
28 29 30 31  
  

（解答群）  
  
ア  平成28年8月16日  
  
イ  平成28年8月17日  
  
ウ  平成28年8月23日  
  
エ  平成28年8月24日  
        
`
        },
        {
                "mondaiId": 105280031,
                "name": "第3問設問1",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたとX株式会社(以下｢X社｣という。)
の代表取締役甲氏との間で行われたものである。X社は、αの製造販売事業(以下  
｢α事業｣という。)を営んでいる。この会話を読んで、下記の設問に答えよ。  

甲氏：｢おかげさまで弊社のα事業は好調です。そこで、業容を拡大したいと考  
えていたところ、先日ちょうど、取引銀行を通じて、弊社と同じα事業  
を営んできたY社から、事業の選択と集中を進めたいから同事業を買収  
しないかという話をもらいまして、現在前向きに検討しています。Y社  
は、α事業以外の事業も営んでいるので、新設分割でα事業をいったん切  
り出して子会社Z社を設立し、弊社がY社からZ社の全株式を現金で買  
い取るスキームを考えています。何か注意しておいた方がいいことはあり  
ますか。｣  

あなた：｢（　Ａ　）。それから、同業他社から競合する事業を買収することにな  
りますから、独占禁止法に抵触するかどうかも問題になります。少なくと  
も公正取引委員会への届出の要否については検討しなければなりません。｣  
甲氏：｢（　Ｂ　）。｣  

あなた：｢（　Ｃ　）。いずれにせよ、事業の買収、特に買い手の場合には、大小  
様々なリスクを伴いますから、その分野に詳しい専門家からアドバイスを  
受けないと後で痛い目を見ますよ。ちょうどいい人を知っていますから紹  
介しますよ。｣  

甲氏：｢ありがとうございます。｣  
        
（設問１）

会話の中の空欄Ａに入る記述として、<u>最も不適切なものはどれか</u>。  
  
ア  α事業に関係する債務は、Z社が承継する債務から除外することはできない  
ので、α事業に関係する簿外債務がないかどうかの調査が重要になります  
  
イ  Y社がα事業に関して締結している契約の中に、会社分割が解除事由とし  
て定められているものがないかの確認が重要になります  
  
ウ  Z社においてα事業を営むのに新たに許認可を取得することが必要な場合に  
は、その許認可を得るのに必要な期間やコストを把握しておく必要があり、そ  
のコストをX社が負担するのかY社が負担するのか交渉する必要があります  
  
エ  契約の分割等の要否を検討するために、Y社が、α事業とそれ以外の事業の  
双方で、同一の契約に基づいて使用しているリース資産やシステムがないかど  
うかの確認が必要になります`
        },
        {
                "mondaiId": 105280032,
                "name": "第3問設問2",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
以下の会話は、中小企業診断士であるあなたとX株式会社(以下｢X社｣という。)
の代表取締役甲氏との間で行われたものである。X社は、αの製造販売事業(以下  
｢α事業｣という。)を営んでいる。この会話を読んで、下記の設問に答えよ。  

甲氏：｢おかげさまで弊社のα事業は好調です。そこで、業容を拡大したいと考  
えていたところ、先日ちょうど、取引銀行を通じて、弊社と同じα事業  
を営んできたY社から、事業の選択と集中を進めたいから同事業を買収  
しないかという話をもらいまして、現在前向きに検討しています。Y社  
は、α事業以外の事業も営んでいるので、新設分割でα事業をいったん切  
り出して子会社Z社を設立し、弊社がY社からZ社の全株式を現金で買  
い取るスキームを考えています。何か注意しておいた方がいいことはあり  
ますか。｣  

あなた：｢（　Ａ　）。それから、同業他社から競合する事業を買収することにな  
りますから、独占禁止法に抵触するかどうかも問題になります。少なくと  
も公正取引委員会への届出の要否については検討しなければなりません。｣  
甲氏：｢（　Ｂ　）。｣  

あなた：｢（　Ｃ　）。いずれにせよ、事業の買収、特に買い手の場合には、大小  
様々なリスクを伴いますから、その分野に詳しい専門家からアドバイスを  
受けないと後で痛い目を見ますよ。ちょうどいい人を知っていますから紹  
介しますよ。｣  

甲氏：｢ありがとうございます。｣  
        
（設問２）
  
会話の中の空欄ＢとＣに入る記述の組み合わせとして、<u>最も適切なものはどれか</u>。  
  
ア  Ｂ：株式買取りのスケジュールには影響しますか  
Ｃ：公正取引委員会が短縮を認めてくれない限り、最短でも届出を受理され  
てから、30日を経過するまでは、株式を取得することはできないので、  
スケジュールに影響しますね  
  
イ  Ｂ：届出を行うのは、X社ですか。Y社ですか  
Ｃ：Y社です  
  
ウ  Ｂ：届出を行う前に、公正取引委員会に相談に行くことはできるのですか  
Ｃ：できません  
  
エ  Ｂ：どんな規模でも届出が必要になるのですか  
Ｃ：X社の企業グループ全体の国内売上高が10億円以上の場合で、かつ、  
Z社とその子会社の国内売上高の合計が1億円以上の場合に、届出が必  
要になります  
        
`
        },
        {
                "mondaiId": 105280040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
X株式会社(以下｢X社｣という。)は、中小企業における経営の承継の円滑化に関  
する法律に定める特例中小企業者である。  

以下の事実関係の下で、平成29年4月の時点で、CがAから生前贈与を受けた  
X社の発行済株式の全てについて除外合意が有効に成立していた場合と固定合意  
が有効に成立していた場合におけるDに係る遺留分侵害額の組み合わせとして、  
最も適切なものを下記の解答群から選べ。  

なお、平成28年8月以降、X社の発行済株式総数は、2，400株のまま変化して  
おらず、Aの家族構成にも変わりなく、A以外に亡くなった者はおらず、廃除さ  
れた相続人もいない。また、下記以外に、寄与分及び特別受益は存在せず、Aが  
保有している財産はない。  

平成28年8月
Aは、X社の代表取締役社長を務め、X社の発行済株式の  
全て(2，400株)を保有していた。Aの家族構成は、図  
のとおりであった。Aの家族のうち、X社の経営に興味があったの  
がCのみであったことから、Aの家族の間では、CがAの後  
継者としてX社の経営を引き継ぐことは共通認識であり、C  
は、X社の代表取締役専務として、X社の業務に従事してお  
り、他方、B、D、E及びFは、X社の経営にも業務にも関与  
していなかった。  

平成29年4月
Aは、引退を決意し、保有するX社の発行済株式の全てを  
Cに生前贈与し、代表取締役を退任し、CがX社の代表取締  
役社長に就任した。同月時点におけるAが保有する財産及び  
その金額は、図2のとおりであった。  

平成29年4月以降
Cは、社長就任後、社業に邁進し、そのおかげもあって、X  
社は、業績を順調に伸ばし、企業価値を向上させた。  

平成33年8月
Aは死亡した。この時までにX社の1株当たりの株式の価  
値は、20万円に上昇し、その他の財産(自宅不動産及び預貯  
金)の金額は、平成29年4月時点から変わりはなかった。A  
は、図3のとおりに財産を相続させることを内容とする有効な  
遺言書を残していた。  
        
図1　Aの家族関係  
<imgTag>105280040_1.png</imgTag>          


図2 平成29年4月時点でAが保有していた財産  
　財産　／　金額  
　X社株式　／　2，400株　(1株10万円)　／　2億4，000万円  
　自宅不動産　／　8，000万円  
　預貯金　／　6，000万円  
　負債　／　なし  


図3 Aの遺言の内容  
　相続人　／　相続する財産  
　Ｂ　／　自宅不動産8，000万円  
　Ｄ　／　預貯金2，000万円  
　Ｅ　／　預貯金2，000万円  
　Ｆ　／　預貯金2，000万円  


（解答群）  
  
ア  除外合意：0円　固定合意：375万円  
  
イ  除外合意：0円　固定合意：1，875万円  
  
ウ  除外合意：875万円　固定合意：375万円  
  
エ  除外合意：875万円　固定合意：1，875万円  
        
`
        },
        {
                "mondaiId": 105280050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表は、各法的倒産手続についてまとめたものである。空欄Ａ~Ｄに入る語句の  
組み合わせとして、最も適切なものを下記の解答群から選べ。  


<imgTag>105280050_1.png</imgTag>      


（解答群）  
  
ア  Ａ：会社更生手続Ｂ：民事再生手続Ｃ：破産手続Ｄ：特別清算手続  
  
イ  Ａ：破産手続Ｂ：会社更生手続Ｃ：民事再生手続Ｄ：特別清算手続  
  
ウ  Ａ：破産手続きＢ：民事再生手続Ｃ：特別清算手続Ｄ：会社更生手続  
  
エ  Ａ：民事再生手続Ｂ：会社更生手続Ｃ：破産手続Ｄ：特別清算手続  
        
`
        },
        {
                "mondaiId": 105280060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
実用新案登録技術評価に関する記述として、最も適切なものはどれか。  
  
ア  実用新案法には、２以上の請求項に係る実用新案登録出願については、実用新  
案技術評価の請求は、請求項ごとにすることができない旨が規定されている。  
  
イ  実用新案法には、実用新案技術評価の請求をした後においては、実用新案登録  
出願を取り下げることができない旨が規定されている。  
  
ウ  実用新案法には、実用新案権の消滅後においても、常に当該実用新案技術評価  
の請求をすることが可能である旨が規定されている。  
  
エ  実用新案法によれば、実用新案権者は、その登録実用新案に係る実用新案技術  
評価書を提示して警告をした後でなければ、自己の実用新案権の侵害者等に対  
し、その権利を行使することができない。  
        
`
        },
        {
                "mondaiId": 105280070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の文章は、特許法等の一部を改正する法律(平成27年月10日法律第55号)のうち、主に職務発明に関するものである。  

文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものを下記の解答  
群から選べ。  

グローバル競争が激化する中、わが国のイノベーションを促進するためには、研  
究者の研究開発活動に対するインセンティブの確保と、企業の競争力強化を共に実  
現するための環境整備が重要である。このような事情に鑑み、知的財産の適切な保  
護及び活用を実現するための制度を整備し、わが国のイノベーションを促進するこ  
とを目的として、まず、職務発明制度の見直し、次に、特許料等の改定、さらに  
は、（　Ａ　）及び商標に関するシンガポール条約の実施のための規定の整備を行  
うこととした。  

なお、従来の職務発明制度の柱は、まず、特許を受ける権利は（　Ｂ　）に帰属  
し、（　Ｃ　）が特許出願をするには、その権利を譲り受ける形となる点、及び、  
（　Ｂ　）は、特許を受ける権利を（　Ｃ　）に承継させた場合、その対価を請求  
することができる(いわゆる｢対価請求権｣)というものであった。  

また、従来の職務発明制度では、異なる（　Ｃ　）における共同発明者甲及び乙  
が存在する場合、（　Ｃ　）が、自社の発明者(甲)から特許を受ける権利を承継す  
る場合、他社の発明者(乙)の同意も得る必要があるため、権利の承継に係る手続負  
担が課題となっていた。また、例えば共同研究の途中で、従業者(共同発明者)の人  
事異動が発生した場合は、再度、当該従業者から同意を取り直す等、権利の承継に  
係る手続がより複雑化していた。これらは、昨今共同研究の必要性が高まる中、企  
業のスピーディーな知財戦略実施の阻害要因のひとつとなっていた。  

そこで、特許を受ける権利を初めから（　Ｃ　）に帰属させることにより、この  
問題を解決することとした。  
        
        
（解答群）  
  
ア  Ａ：特許協力条約Ｂ：使用者等Ｃ：発明者  
  
イ  Ａ：特許協力条約Ｂ：発明者Ｃ：使用者等  
  
ウ  Ａ：特許法条約Ｂ：使用者等Ｃ：発明者  
  
エ  Ａ：特許法条約Ｂ：発明者Ｃ：使用者等  
        
`
        },
        {
                "mondaiId": 105280080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたは、意匠登録出願をしようとしている顧客の経営者  
X氏から相談を受けている。あなたとX氏との会話の組み合わせのうち、あなた  
の回答が最も適切なものはどれか。  
  
ア  
Ｘ氏：弊社が開発した、ビデオディスクレコーダーの録画予約機能を発揮で  
きる状態にするために行われる操作に用いられる画像は、意匠登録の  
対象となるでしょうか。  

あなた：いいえ、意匠登録の対象とはなりません。ビデオディスクレコーダー  
の操作画像でテレビ受像器に表示されたものは、意匠登録の対象であ  
る｢物品の形状、模様若しくは色彩又はこれらの結合｣に該当せず、意  
匠登録を受けられる場合はありません。  
  

イ  
Ｘ氏：弊社のアイスクリームのヒット商品｢診断くん｣のデザインを一新しま  
した。ぜひとも意匠登録をして模倣品対策をしたいのですが、意匠登  
録は可能でしょうか。  

あなた：はい、意匠登録は可能です。アイスクリームは、時間の経過によりそ  
の形態が変化してしまいます。しかし取引時には固定した形態を有し  
ているので、意匠登録の対象となることはあります。  
  

ウ  
Ｘ氏：弊社のバラの造花は、デザイン性が高いため、売れ行きが非常に好調  
です。そこで、類似品が販売されるのを防止するため意匠登録をした  
いと考えています。このようなバラの造花は、意匠登録の対象となる  
ことはありますか。  

あなた：いいえ、意匠登録の対象となることはありません。貴社のバラの造花  
は、自然物の形状、模様、色彩を模したものですから、意匠登録の対  
象となることはありません。  
  
エ 
 Ｘ氏：弊社は、仏壇の製造販売を行っています。このたび、大変斬新な形態  
の仏壇のデザインができたため、意匠登録をしようと考えています。  
この仏壇のデザインは意匠権に加えて、著作権によっても保護されま  
すか。  
        
あなた：いいえ、著作権によっては保護されません。美術的作品であっても、  
仏壇のように量産されるものであるときは、著作権により保護される  
ことはありません。貴社の仏壇は、著作権によってではなく、意匠権  
によってのみ保護されます。  
        
`
        },
        {
                "mondaiId": 105280090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
意匠法に規定される秘密意匠制度は、意匠登録出願人が、意匠権の設定の登録の  
日から3年以内の期間を指定して、その期間その意匠を秘密にすることを請求する  
ことができる制度である(意匠法第14条)。これは、先願により意匠権を確保して  
おく必要があるものの、直ちに当該意匠の実施を行わない場合に意匠公報が発行さ  
れることによる第三者の模倣を防止しようとする趣旨によるものである。  
このような秘密意匠制度に関する記述として、最も適切なものはどれか。  
  
ア  甲は、出願公開された特許出願を意匠登録出願に変更した。この場合、当該変  
更出願に係る意匠はすでに新規性を失っている。したがって、これを秘密にすべ  
き利益を失っているため、甲は、その意匠登録出願について秘密にすることを請  
求することができない。  
  
イ  乙は、本意匠Aとそれに類似する関連意匠Bを同日に意匠登録出願した。こ  
の意匠登録出願の際、乙は、Aのみを秘密にすることを請求していた。この場  
合、その期間が経過するまで、Bについても秘密にすべき利益を保護する必要が  
生じる。したがって、Bに係る意匠登録出願の願書に添付した図面の内容が意匠  
公報に掲載されることはない。  
  
ウ  丙は、意匠登録出願前に意匠が記載されたカタログを重要顧客に頒布した場合  
であっても、その意匠を秘密にすることを請求することができる。  
  
エ  丁は、パリ条約の同盟国において意匠登録出願をした。その意匠が公報に掲載  
された後に、丁が日本国においてこの意匠登録出願に基づきパリ条約による優先  
権主張を伴う意匠登録出願をするときは、既に当該意匠を秘密にすべき利益を失  
っている。したがって、丁は、その意匠を秘密にすることを請求することができ  
ない。  
        
`
        },
        {
                "mondaiId": 105280100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
甲が商標Aについて商標登録出願を行ったところ、他人乙の先願先登録商標B  
が、商標Aに類似する商標として引用され、拒絶理由通知が発せられた。この場  
合に関する記述として、最も適切なものはどれか。  
  
ア  商標Aと商標Bの類否は、それぞれの商標が同一又は類似の商品に使用され  
た場合に、商品の出所につき誤認混同を生ずるおそれがあるか否かによって決せ  
られる。  
  
イ  商標Aと商標Bの類否は、まず、それぞれの商標の要部を抽出し、その後、  
商標Aと商標Bの要部のみを対比することにより、判断しなければならない。  
  
ウ  商標Aの登録を乙が承諾している旨を示す証拠が提出された場合、乙の利益  
が害されることはないため、審査官は当該証拠を資料として参酌して登録する義  
務がある。  
  
エ  商標Aは立体商標であり、その指定商品は有体物である。一方、商標Bは平  
面商標であり、その指定役務は、無体物である。この場合、商標Aと商標Bと  
は互いに類似とされることはないため、甲は意見書を提出して審査官の判断を覆  
すべきである。  
        
`
        },
        {
                "mondaiId": 105280110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
不正競争防止法(以下、｢法｣という。)に規定する商品等表示に関する記述として、  
最も適切なものはどれか。なお、各選択肢中の｢周知表示混同惹起行為｣とは法第2条第1項第1号に規定する行為をいい、｢著名表示冒用行為｣とは同第2号に掲げる  
行為をいう。  
  
ア  高級車ブランドとして知られるA社の著名な自動車に関する商品表示を、A  
と無関係の者であるBがサングラスに付して販売している。この場合、Bの行  
為は、著名表示冒用行為となると考えられるが、周知表示混同惹起行為となるこ  
とはない。  
  
イ  製菓メーカーC社のポテトチップスの表示甲が普通名称化し、取引者・需要  
者間で普通名称として用いられるようになった場合、この普通名称化の前に既に  
表示甲がポテトチップスを表示するものとして著名であるときは、当該表示を普  
通に用いられる方法で使用する行為は、著名表示冒用行為となる。  
  
ウ  ピザの宅配業者であるDの営業表示乙は、現在、ある地域で周知である。表  
示乙が周知化する前から、Dと同一地域でピザの宅配業者Eが表示乙と類似の  
表示である丙を使用しているという事実がある。この場合、Dは、Eによる丙の  
使用に不正の目的がある場合でも、Eによる丙の使用を差し止めることができな  
い。  
  
エ  ヨーロッパの世界的アパレル・ブランドである企業Fの著名な商品表示を、  
スナックGがわが国の地方都市の郊外において商号として一店舗のみの看板な  
どに用いている。この場合、FG間に競争関係はないものの、周知表示混同惹起  
行為となることがある。  
        
`
        },
        {
                "mondaiId": 105280120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の文章は、不正競争防止法上の営業秘密に関するものである。文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

不正競争防止法上の｢営業秘密｣に該当するためには、｢秘密管理性｣、  
｢Ａ｣および｢Ｂ｣の3つの要件を満たすことが必要である。  

この｢秘密管理性｣があるというためには、その情報に合法的かつ現実に接触する  
ことができる従業員等からみて、その情報が会社にとって秘密としたい情報である  
ことが分かる程度に、アクセス制限やマル秘表示といった秘密管理措置がなされて  
いることが必要である。  

また、｢Ａ｣の要件は、脱税情報や有害物質の垂れ流し情報などの公序良  
俗に反する内容の情報を、法律上の保護の範囲から除外することに主眼を置いた要  
件であり、それ以外の情報であれば｢Ａ｣が認められることが多い。現実に  
利用されていなくてもよく、失敗した実験データというようなネガティブ・インフ  
ォメーションにも｢Ａ｣が認められ得る。  

さらに、｢Ｂ｣があるというためには、合理的な努力の範囲内で入手可能  
な刊行物には記載されていないなど、保有者の管理下以外では一般に入手できない  
ことが必要である。なお、例えば、Ｃ目的で、詐欺等行為又は管理侵害行  
為によって、営業秘密を不正に取得する行為等は営業秘密侵害罪を構成しうる。  

（解答群）  
  
ア  Ａ：適法性Ｂ：新規性Ｃ：営利  
  
イ  Ａ：適法性Ｂ：非公知性Ｃ：営利  
  
ウ  Ａ：有用性Ｂ：新規性Ｃ：図利加害  
  
エ  Ａ：有用性Ｂ：非公知性Ｃ：図利加害  
        
`
        },
        {
                "mondaiId": 105280130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
契約の成立に関する記述として、最も適切なものはどれか。  
  
ア  A市内の会社からB市内の会社に対して有効期間を明記した注文書を郵送で  
発送した場合、注文書に注文は撤回可能である旨の記載があっても、注文者は注  
文を撤回することができない。  
  
イ  インターネットショッピングでEC(電子商取引)事業者が顧客からの購買申込  
みを承諾する通知を電子メールで送信したが、顧客から購買申込みを撤回する電  
子メールによる通知がされた場合、契約の成否は承諾の通知の発信と申込撤回の  
通知の到達の先後により決せられる。  
  
ウ  隔地者に対する契約の申込みは、申込みの発信後その到達前に申込者が死亡し  
た場合でも有効であるが、その申込みの相手方が承諾の発信前に申込者の死亡を  
知った場合には、申込みは効力を失う。  
  
エ  株式会社の代表者同士の対面交渉において承諾期間を定めずに契約の申込みが  
された場合、相手方が直ちに承諾しなくても、申込みの効力は有効に存続する。  
        
`
        },
        {
                "mondaiId": 105280140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
債務者による詐害的な行為に対する債権者からの権利行使に関する記述として、  
最も適切なものはどれか。  
  
ア  債務者が債権者を害することを知ってした5年前の法律行為を債権者が知って  
から2年が経過するまでは、債権者は詐害行為取消請求に係る訴えを提起するこ  
とができる。  
  
イ  債務者が第三者に対して有する債権をもって債権者の一部の者に代物弁済した  
場合、代物弁済に供した債権額が消滅した債務額を超過していなければ、他の債  
権者に対して詐害行為とはならない。  
  
ウ  詐害行為によって譲渡された不動産が受益者から転得者へ譲渡され、詐害行為  
について受益者は悪意であるが転得者は善意である場合、債権者は詐害行為取消  
権を行使することができない。  
  
エ  新設分割会社が新設分割設立株式会社に承継されない債務の債権者を害するこ  
とを知って新設分割をした場合、当該債権者は、その新設分割設立株式会社に対  
し、承継しなかった財産の価額を限度として、当該債務の履行を請求できる。  
        
`
        },
        {
                "mondaiId": 105280151,
                "name": "第15問設問1",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士であるあなたとX株式会社の代表取締役甲氏との間の以下の会  
話を読んで、下記の設問に答えよ。  

甲氏：｢当社で開発した遺伝子検査の新技術について、α国に本社のある会社に  
技術ライセンスを供与する交渉をしています。当社から英文契約書案を提  
示する予定で、担当者がひな形を準備したのですが、こういう条項につい  
てはどのようなことに注意すればいいですか。  
“Article XX Taxes  
II.All taxes，duties or levies that may be imposed by the Governments of  
Japan and α on any payment made to the Licensor under this Agreement  
shall be borne by the Licensee.In the event that the Licensee is required to  
( Ａ ) such tax from the amount paid to the Licens or hereunder，and  
to pay the tax for the account of the Licensor，the Licensee shall provide  
the Licensor with certificates of cash withholding and payment.”｣  

あなた：｢使用料の支払にかかる税金の負担についての条項ですね。まず支払国に  
おける知的財産の使用料に対する源泉徴収制度、次に日本・α国間の租税  
条約の有無を確認して、それらとの整合性をチェックする必要がありま  
す。α国で源泉徴収税を免税してもらうか、源泉徴収分を日本で外国税額  
控除の対象としてもらえないと、二重課税になりかねませんから。｣  

甲氏：｢当社は外国企業への技術ライセンスが多いので、知的財産の使用料に対  
する所得課税の税率が低いことで有名なβ国に現地法人を設立し、そこ  
に知的財産を集約して使用料収入の税負担を抑えるというプランの提案も  
受けています。｣  

あなた：｢そうですか。そういう事業スキームを構想する場合には、専門家のアド  
バイスだけをうのみにせず、経営の原点に立ち返って考えた方がいいです  
よ。国際的な租税回避策に利用される“Ｂ”に関して、β国は注視  
されている国ですし、その国での独立企業としての実体がなかったり、業  
態に応じた事業活動上の目的や必要性等の観点から、節税以外にその国に  
知的財産を集約する経済合理性を説明できなかったりすると、税務当局か  
ら租税回避行為と認定されるおそれもあります。｣  

（設問1)  
会話の中の空欄Ａには、特定の所得の支払者がその所得(源泉所得)を支払う際  
に支払金額から所得税額を差し引いて徴収し、その徴収額を国に納付するという  
税制上の仕組みを意味する英単語の動詞が入る。空欄Ａに入る語句として、最も  
適切なものはどれか。  
  
ア  impose  
  
イ  pay  
  
ウ  provide  
  
エ  withhold`
        },
        {
                "mondaiId": 105280152,
                "name": "第15問設問2",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士であるあなたとX株式会社の代表取締役甲氏との間の以下の会  
話を読んで、下記の設問に答えよ。  

甲氏：｢当社で開発した遺伝子検査の新技術について、α国に本社のある会社に  
技術ライセンスを供与する交渉をしています。当社から英文契約書案を提  
示する予定で、担当者がひな形を準備したのですが、こういう条項につい  
てはどのようなことに注意すればいいですか。  
“Article XX Taxes  
II.All taxes，duties or levies that may be imposed by the Governments of  
Japan and α on any payment made to the Licensor under this Agreement  
shall be borne by the Licensee.In the event that the Licensee is required to  
( Ａ ) such tax from the amount paid to the Licens or hereunder，and  
to pay the tax for the account of the Licensor，the Licensee shall provide  
the Licensor with certificates of cash withholding and payment.”｣  

あなた：｢使用料の支払にかかる税金の負担についての条項ですね。まず支払国に  
おける知的財産の使用料に対する源泉徴収制度、次に日本・α国間の租税  
条約の有無を確認して、それらとの整合性をチェックする必要がありま  
す。α国で源泉徴収税を免税してもらうか、源泉徴収分を日本で外国税額  
控除の対象としてもらえないと、二重課税になりかねませんから。｣  

甲氏：｢当社は外国企業への技術ライセンスが多いので、知的財産の使用料に対  
する所得課税の税率が低いことで有名なβ国に現地法人を設立し、そこ  
に知的財産を集約して使用料収入の税負担を抑えるというプランの提案も  
受けています。｣  

あなた：｢そうですか。そういう事業スキームを構想する場合には、専門家のアド  
バイスだけをうのみにせず、経営の原点に立ち返って考えた方がいいです  
よ。国際的な租税回避策に利用される“Ｂ”に関して、β国は注視  
されている国ですし、その国での独立企業としての実体がなかったり、業  
態に応じた事業活動上の目的や必要性等の観点から、節税以外にその国に  
知的財産を集約する経済合理性を説明できなかったりすると、税務当局か  
ら租税回避行為と認定されるおそれもあります。｣  

（設問2)    
会話の中の空欄Ｂに入る語句として、<u>最も不適切なものはどれか</u>。  
  
ア  tax haven  
  
イ  tax heaven  
  
ウ  tax inversion  
  
エ  tax shelter  
        
`
        },
        {
                "mondaiId": 105280160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の文章は、経済産業省が公表している情報システムの企画・開発におけるモ  
デル契約の解説を要約して抜粋したものである。文中の空欄Ａ~Ｄに入る語句の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  

ユーザ(委託者)がベンダ(受託者)に情報システムの構築を委託する場合、フェー  
ズごとに、契約類型として準委任と請負のどちらとするかを選択しなければならな  
い。  

（　Ａ　）ではベンダは仕事(受託業務)の完成の義務を負うのに対し、  
（　Ｂ　）ではベンダは仕事の完成についての義務は負わない。そうすると、受託  
業務に着手する前の段階でベンダにとって成果物の内容が具体的に特定できる内部  
設計やソフトウェア設計などのフェーズは、（　Ａ　）で行うことが可能である。  
これに対し、システム化計画や要件定義のフェーズは、ユーザ自身にとっても業務  
要件が具体的に確定しておらず、ベンダにとっても成果物の内容を具体的に想定す  
ることは通常不可能であるから、（　Ａ　）にはなじみにくく、
（　Ｂ　）が適切  
ということになる。  

（　Ａ　）では、ユーザに引き渡された成果物に瑕疵があった場合、ベンダは無  
過失責任としてのＣ責任を負い、ユーザは修補や損害賠償を請求すること  
ができ、瑕疵により契約の目的を達成できないときは契約を解除できる。これに対  
して、
（　Ｂ　）の場合、（　Ｃ　）責任を負うことはないが、事務処理に関して  
善管注意義務違反があった場合には、（　Ｄ　）責任を負うこととなる。  

実際の契約において、準委任型とするか、請負型とするかは、成果物の特定につ  
いての当事者同士の経験や役割分担の遂行能力等に基づき、成果物についての共通  
理解が事前に十分に成立しているかによるが、
（　Ｂ　）型としなかった場合、ユ  
ーザ自身のシステム化計画や要件定義におけるステークホルダとの調整を行う責任  
等が曖昧になり、要件定義上の見落としも生じやすいとの指摘が多い。  
        
        
（解答群）  
  
ア  Ａ：請負Ｂ：準委任Ｃ：瑕疵担保Ｄ：債務不履行  
  
イ  Ａ：請負Ｂ：準委任Ｃ：債務不履行Ｄ：瑕疵担保  
  
ウ  Ａ：準委任Ｂ：請負Ｃ：瑕疵担保Ｄ：債務不履行  
  
エ  Ａ：準委任Ｂ：請負Ｃ：債務不履行Ｄ：瑕疵担保  
        
`
        },
        {
                "mondaiId": 105280170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
共有に関する記述として、最も適切なものはどれか。  
  
ア  共同相続された金融商品のうち、株式は共同相続人らの共有となるが、委託者  
指図型投資信託の受益権は相続分に応じて分割債権として各共同相続人に単独で  
承継取得される。  
  
イ  共有に係る商標権の共有者は、他の共有者の同意を得なくてもその持分を譲渡  
することができるが、その商標権に係る通常使用権を他人に許諾することについ  
ては、共有者の持分価格の過半数によって決する必要がある。  
  
ウ  共有不動産の共有者の１人の持分を競売により取得した買受人は、他の共有者  
との間で協議が調わなければ、その共有不動産全部について単独で所有権を取得  
することができない。  
  
エ  不動産の共有者の１人は、共有不動産について全く実体上の権利を有しないの  
に持分移転登記を経由している者に対し、単独でその持分移転登記の抹消登記手  
続を請求することができる。  
        
`
        },
        {
                "mondaiId": 105280180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2016,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
上場準備中に行われる株式移動等に関する規制についての記述として、最も適切  
なものはどれか。  
  
ア  新規上場申請者の株式を最も多く所有する株主が、新規上場申請日の直前事業  
年度の末日から起算して２年前から上場日の前日までの期間において、新規上場  
申請者の株式を譲り受けた場合には、譲渡価格を新規上場申請のための有価証券  
報告書において開示する必要はない。  
  
イ  新規上場申請日の直前事業年度の末日から起算して１年前より後において取得  
した新規上場申請者の株式については、当該末日から起算して１年前より前に発  
行された新株予約権を行使して取得したものであっても、上場後直ちに売却する  
ことはできない。  
  
ウ  新規上場申請日の直前事業年度の末日から起算して２年前から上場日の前日ま  
での期間において、新規上場申請者の特別利害関係人が、新規上場申請者の株式  
を譲り受けると、東京証券取引所への上場は認められない。  
  
エ  東京証券取引所が適当と認める役員又は従業員に報酬として割り当てられた新  
株予約権を行使して取得した新規上場申請者の株式については、上場後直ちに売  
却することができる。  
`
        },
        {
                "mondaiId": 106280010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
各種業務処理を行ううえでパーソナルコンピュータ(PC)の重要度が増す中、業  
務内容に適した機器構成を検討することは重要である。これに関する記述として最  
も適切なものはどれか。  
  
ア  HDDとは異なりSSDは、OSのインストールができないため起動ドライブと  
しては使えない。  
  
イ  PCにグラフィックボードを付ける場合、IDEインタフェースに装着する。  
  
ウ  PCには、処理速度を向上させるために、メモリモジュールを複数枚組み合わ  
せて利用できるものがある。  
  
エ  マザーボード上のCPUソケットの形状は標準化されているので、処理速度の  
速いどのようなCPUへの交換も可能である。  
        
`
        },
        {
                "mondaiId": 106280020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
最近では液晶パネルが様々な種類のコンピュータ用ディスプレイやスマートフォ  
ン等に使用されている。液晶パネルには動作原理が異なる数種類の製品があり、そ  
の選択に当たっては、業務における利用目的に合った画面の表示能力や描画速度を  
有しているか等に注意を払わなければならない。特に商品の写真や動画を扱う場合  
などは、正確な色を再現することができる特性を備えた製品を選択する必要があ  
る。  

液晶パネルに関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、最  
も適切なものを下記の解答群から選べ。  

液晶パネルでは、電荷を与えて液晶の並びを変化させることで光のＡ  
を変えているので、正面以外の角度からパネルを見ると正しい色や輝度が表現で  
きず画面が見にくいが、この特性を改良した製品も販売されている。  
液晶パネルの中で、Ｂ方式のパネルは、比較的応答速度が速く、光漏  
れが少ないので黒がはっきりと表現できるが、正面以外の角度からは見にくい。  
一方、Ｃ方式のパネルは正面以外の角度からの見え方が比較的良好で色  
の再現性は良いが、光漏れの発生度合いが大きくコントラスト比を高くしにく  
く、応答速度の高速化も難しい。  

液晶ディスプレイは色強度、コントラストなどが調整できるようになっている  
が、商品の写真や動画などの色を正確に表現するためには、Ｄを考慮し  
た調整が可能なカラーマネジメント機能を備えたものを使用する必要がある。  


（解答群）  
  
ア  Ａ：透過率　Ｂ：IPS　Ｃ：TN　Ｄ：カラーバランス  
  
イ  Ａ：透過率　Ｂ：TN　Ｃ：IPS　Ｄ：色温度  
  
ウ  Ａ：反射率　Ｂ：TN　Ｃ：VA　Ｄ：カラーバランス  
  
エ  Ａ：反射率　Ｂ：VA　Ｃ：TN　Ｄ：色温度  
        
`
        },
        {
                "mondaiId": 106280030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務等に利用する各種のアプリケーションプログラムの実行が円滑に行われるよ  
うに、コンピュータには様々な仕組みが組み込まれている。しかし、コンピュータ  
の種類によってそれらの仕組みの装備状況が異なり、機能にも能力差があるので仕  
組みの内容を理解することも必要である。  

コンピュータの仕組みに関する以下の①~④の記述と、その名称の組み合わせと  
して、最も適切なものを下記の解答群から選べ。  

①主記憶装置の記憶領域において、実行中のプログラムが使用しなくなった領域  
のうち断片化したものを整理し、連続して利用可能な記憶領域を確保すること。  

②コンピュータが仮想記憶の仕組みを備えている場合、主記憶装置と補助記憶装  
置の間でデータの入れ替えを行うこと。  

③演算装置の処理能力に比べて大幅に処理が遅い装置に対するデータの入出力処  
理において、データを一時的に補助記憶装置等に保存して処理することで、コン  
ピュータの処理効率を向上させること。  

④半導体の記憶装置を実装したハードディスクで、使用頻度が高いデータを半導  
体記憶装置に記憶させ、低速の磁気ディスクからの読み出し回数を減少させて処  
理の高速化を図ること。  


（解答群）  
  
ア  ①：ガーベージコレクション②：スワッピング  
③：ファイルダンプ④：キャッシング  
  
イ  ①：ガーベージコレクション②：ホットスワップ  
③：スプーリング④：ページング  
  
ウ  ①：コンパクション②：スワッピング  
③：スプーリング④：キャッシング  
  
エ  ①：コンパクション②：ホットスワップ  
③：ファイルダンプ④：ページング  
        
`
        },
        {
                "mondaiId": 106280040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
PCには多様なソフトウェアが使われている。ソフトウェアに関する記述として  
最も適切なものはどれか。  
  
ア  デバイスドライバとは、PCに接続される周辺機器を制御するためのソフトウ  
ェアである。  
  
イ  ファームウェアとは、OSの一部分を指し、接続される周辺機器と通信するた  
めのソフトウェアである。  
  
ウ  ミドルウェアとは、OSの中核となって機能するソフトウェアである。  
  
エ  ユーティリティプログラムとは、アプリケーションプログラムの総称である。  
        
`
        },
        {
                "mondaiId": 106280050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
商品売上高を示したデータが下記のように、表計算ソフトウェアのシート中の  
A~C列に入力されている。  

D列に示したような、売上高が多い順の順位を求めたい。同じ値が複数ある場  
合は同じ順位を与え、次の大きさの値には重複した分を飛ばした順位を与える。  

このために、条件に一致した値の個数を数えるのにCOUNTIF文を利用して順  
位を求める式を考え、その式をD2のセルに入力する。D2の式を下の行に複写し  
て、D列のような順位を求めたい。  

COUNTIF文を用いたD2のセルに入る式として、最も適切なものを下記の解  
答群から選べ。  

ただし、COUNTIF文、＆の使用方法は以下のとおりである。  

・COUNTIF(対象範囲，条件式)：対象範囲のうち、条件式に記述した内容を  
満たすセルの個数を返す関数  
・＆：文字列の連結  

<imgTag>106280050_1.png</imgTag>  

（解答群）  
  
ア  =COUNTIF(C$2:C$7，”<”&C2)＋1  
  
イ  =COUNTIF(C$2:C$7，”>=”&C2)  
  
ウ  =COUNTIF(C$2:C$7，”<=”&C2)  
  
エ  =COUNTIF(C$2:C$7，”>”&C2)＋1  
        
`
        },
        {
                "mondaiId": 106280060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務において各種のサービスを各々異なるサーバ機能で運用する場合、各サービ  
スを利用するごとに、それぞれのID、パスワードを入力して認証を受けなければ  
ならないのは、運用者・利用者の双方にとってID管理の負担が大きく非効率的で  
ある。  

この状況を解決するための方法に関する以下の文章の空欄Ａ~Ｄに入る語句の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  

複数のサーバ機能による各サービスの利用者認証を、利用者ごとにひとつの  
IDとパスワードの組み合わせで行う仕組みがＡである。  

この仕組みは、サーバ機能によるサービスの利用時だけではなく、社内の  
ＢにＣを接続して利用する際の認証にも利用することができ  
る。  

この仕組みを社内で導入するには、Ｄをサーバマシン上で運用する必  
要がある。  


（解答群）  
  
ア  Ａ：シングルサインオンＢ：LANＣ：プリンタ  
Ｄ：LinuxとApache  
  
イ  Ａ：シングルサインオンＢ：無線LANＣ：PCやスマートフォン  
Ｄ：RADIUSとLDAP  
  
ウ  Ａ：マルチセッションＢ：VPNＣ：プリンタ  
Ｄ：LinuxとLDAP  
  
エ  Ａ：マルチログインＢ：無線LANＣ：POS端末  
Ｄ：ApacheとRADIUS  
        
`
        },
        {
                "mondaiId": 106280070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、最新のWeb技術を有効に利用して、広告活動や各種の公示活動を魅力あ  
るものにしようという動きが活発になりつつある。Webコンテンツの作成技術に  
関する記述として最も適切なものはどれか。  
  
ア  Webページに画像を掲載する場合、SVG、JPEG、GIFなどのラスタ形式の画  
像フォーマットを使うよりも、TIF、PNGなどのベクタ形式のものを使った方  
が拡大した場合などに画像が劣化しない。  
  
イ  Webページの記述言語であるHTML5は、PCあるいはスマートフォン向けの  
すべてのブラウザに対応していることから、Webページの記述は今後HTML5  
で行う方が良い。  
  
ウ  繰り返し自社のWebページを閲覧してもらうようにするためにはUI/UXが  
重要である。  
  
エ  現実世界をコンピュータ技術で拡張してWebページに表示する技術を総称し  
てAIと呼ぶ。  
        
`
        },
        {
                "mondaiId": 106280080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
リレーショナルデータベース(RDB)では定義された複数の表に様々なデータを  
格納して処理を行う。下記のようなA表とB表がある場合、参照の完全性(参照整  
合性ともいう)を保つために必要な事柄として、最も適切なものを下記の解答群か  
ら選べ。  

<imgTag>106280080_1.png</imgTag>  

（解答群）  
  
ア  B表の外部キーの値には重複や空の値があってはならない。  
  
イ  B表の外部キーの値はA表の主キーに存在しなければならない。  
  
ウ  B表の行は削除できるが、A表の行は削除できない。  
  
エ  商品に関するデータが未登録であった場合、B表にデータ入力を行う。  
        
`
        },
        {
                "mondaiId": 106280090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
多様な入力機器の発達、コンピュータ処理の多方面への進展により、ビッグデー  
タと呼ばれる多様で大量のデータを扱うことが多くなった。そのような時代の要請  
に対応するデータベース技術に関する記述として最も適切なものはどれか。  
  
ア  RDBでは、ひとつのデータベースを複数のコンピュータで分散して管理する  
機能はないので、ビッグデータのような多様で大量のデータは扱えない。  
  
イ  XMLデータベースとは、XMLの階層構造をRDBの階層構造にマッピングし  
て利用するデータベースである。  
  
ウ  キーバリューデータベースは、データの構造や属性を決めるスキーマ設計をし  
なくても使える。  
  
エ  ビッグデータに適したNoSQLデータベースと呼ばれるものは、RDBと区別  
するためにその呼び名を用いているが、データ検索にはRDBと同じようにSQL  
を使う。  
        
`
        },
        {
                "mondaiId": 106280100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
コンピュータやスマートフォン、あるいは通信機能を備えた周辺機器をLAN環  
境で利用するために、それらの機器にマシン名やアドレスを割り振るなどの管理が  
必要である。アドレスにはMACアドレスやIPアドレスがあるが、後者は従来の  
IPv4に加え、より広いアドレス空間を持つIPv6も利用されている。  

このアドレス管理に関する記述として最も適切なものはどれか。  
  
ア  IPv4のIPアドレスは32ビットからなり、前半のネットワーク部と後半のホ  
スト部から構成される。CIDR表記では、例えば、211.11.0.1/16のように、/  
以下にネットワーク部のビット数を記す。  
  
イ  IPv6ではIPアドレスは128ビットからなり、IPv4との互換性を保つために、  
先頭から1~80ビットはゼロ、81~96ビットは１とし、残りの32ビットに  
IPv4のアドレスを入れるIPv4互換アドレスという方法を採用している。  
  
ウ  IPv6ではIPアドレスは128ビットからなり、プレフィックスとインタフェー  
スIDにより構成される。インタフェースIDがIPv4のネットワーク部に該当す  
る。  
  
エ  MACアドレスは64ビットからなり、先頭の24ビットが製品固有の番号、残  
りの40ビットに製造メーカ番号が割り当てられ製造メーカから出荷される。  
        
`
        },
        {
                "mondaiId": 106280110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットを利用するために光ケーブルあるいはCATV等のWAN側の回  
線を選択すると、その回線をLANに接続するONUやモデムが設置される。ONU  
やモデムに無線LAN機能が付いていない場合に、無線LAN環境を利用して複数  
のPCやLAN対応機器を接続したい場合には、無線LANルータを設置・運用す  
る。  

この無線LANルータの利用に関する以下の文章の空欄Ａ~Ｄに入る語句の組み  
合わせとして、最も適切なものを下記の解答群から選べ。  
設置されたONUやモデムにLAN接続端子が装備されているので、ここから  
無線LANルータのWAN側の接続端子に、LANケーブルによって接続する。  
無線LANルータにＡの機能が付いている場合はLAN接続端子が複数  
あるので、その数のPCやLAN対応機器を接続できる。さらに多くの機器を利  
用したい場合はＡを多段に接続し、使用可能台数を増やすことができ  
る。  

無線の到達距離を伸ばしたい場合は、複数の無線LANルータを設置する。２  
台目以降の無線LANルータはルータモードではなくＢモードで使用す  
るのが一般的である。  

無線LAN環境を利用する場合は無線LANルータにおいて、SSIDの名称設  
定、Ｃ等の無線LAN接続の認証方法と暗号化方式の選択、および暗号  
化キーの設定を行い、近隣に設置された機器が利用している周波数と重ならない  
ようにＤの変更を行う。  

（解答群）  
  
ア  Ａ：DSUＢ：WiFiＣ：TKIP-AESＤ：バンド  
  
イ  Ａ：スイッチングハブＢ：WiFiＣ：WPS-PSKＤ：ホッピング  
  
ウ  Ａ：スイッチングハブＢ：ブリッジＣ：WPA-AESＤ：チャネル  
  
エ  Ａ：リピータハブＢ：スイッチＣ：WPA-WEPＤ：バンド  
        
`
        },
        {
                "mondaiId": 106280120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットを利用した電子メールが普及し、PCのみならず、スマートフォ  
ンやタブレット端末などの様々な機器で電子メールの送受信が行われている。各種  
の機器で電子メールの送受信を行う場合、Webブラウザ上のWebメール機能の利  
用や、それぞれの機器に対応したメーラーと呼ばれる電子メールクライアントソフ  
トを利用する。その利用のためにいくつかの項目を設定することが必要な場合もあ  
るので、電子メールの設定の仕組みを理解することが望ましい。  

電子メールの利用に関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  

自社が管理する電子メールアドレスの送受信をWebブラウザで行う場合は、  
ＡサーバにWebメール対応の仕組みを稼働させるWebメールインタ  
フェースを追加する必要がある。  

一方、PC、スマートフォンやタブレット端末などでメーラーを使用する場合  
は、各々の機種に対応したソフトウェアを入手し、メールを受け取るPOP3や  
IMAPサーバおよびメールを送信するＢサーバのアドレスと  
Ｃを初めに設定する必要がある。またメールを暗号化して送受信したい  
場合はＤに対応したメーラーを使用する必要がある。  
（解答群）  
  
ア  Ａ：DNSＢ：SambaＣ：認証IDＤ：https  
  
イ  Ａ：WebＢ：SambaＣ：パスワードＤ：DES  
  
ウ  Ａ：メールＢ：SMTPＣ：ポート番号Ｄ：S/MIME  
  
エ  Ａ：メールＢ：SNMPＣ：認証IDＤ：DES  
        
`
        },
        {
                "mondaiId": 106280130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
個人番号カードによる公的個人認証サービスに関する記述として、最も適切なも  
のはどれか。  
  
ア  個人番号カードから利用者証明用電子証明書を読み出せば、基本４情報も読み  
出せる。  
  
イ  個人番号カードの利用者証明用電子証明書は、地方公共団体情報システム機構  
の署名用認証局から発行される。  
  
ウ  個人番号カード保有者が転居しても、市区町村に転居を届けて個人番号カード  
記載内容を変更してあれば、個人番号カードの利用者証明用電子証明書にアクセ  
スすることで転居したことが分かる。  
  
エ  個人番号カード保有者の転居により住所が変わっても、個人番号カードの利用  
者証明用電子証明書は有効である。  
        
`
        },
        {
                "mondaiId": 106280140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ものづくりにおいては、ロボット等の製造設備間の、ITの活用による｢つなが  
る｣仕組みとそのセキュリティの実現が求められている。日本でも、一般社団法人  
日本ロボット工業会がORiN(Open Robot/Resource interface for the Network)を  
策定して推進している。最新版はORiN2である。これに関する記述として最も適  
切なものはどれか。  
  
ア  ORiN2はCAOを利用して、多様な制御装置や製造設備、各種アプリケーショ  
ンを相互に接続する。  
  
イ  ORiN2は、ソフトウェアとハードウェアの標準化仕様である。  
  
ウ  コネクタと呼ばれるインタフェースが制御装置のメーカ固有のインタフェース  
を変換するので、既存の制御装置のインタフェースをそのまま利用できる。  
  
エ  通信プロトコルであるe-CAPは、SOAP(SimpleObjectAccessProtocol)を利  
用している。  
        
`
        },
        {
                "mondaiId": 106280150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システム開発プロジェクトには失敗事例が多いといわれる。システム開発の失敗  
をできるだけ避けるため、種々の指針や概念が提示されている。これに関する記述  
として最も適切なものはどれか。  
  
ア  開発担当者と運用担当者が一体となり、お互いに協力してシステムの開発・リ  
リースを的確に行おうという考え方を一般的にDevOpsという。  
  
イ  コンピュータシステムに組み込むべき業務に関わる要求を機能要求、業務機能  
ではない要求を非機能要求という。独立行政法人情報処理推進機構では非機能要  
求の要求項目を、可用性、性能・拡張性、運用・保守性の3項目に整理してい  
る。  
  
ウ  システム開発受託企業がシステムに求められる要件をどこまで実現するのかを  
明記し、かつ、実現できなかった場合の対処法も明記する契約書をSLMと呼  
ぶ。  
  
エ  システム開発の際に用いられる用語として、As-IsとTo-Beがあるが、As-Is  
とは開発するシステムのあるべき姿を指す。`
        },
        {
                "mondaiId": 106280160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システム開発プロジェクトにおいて見積もりの方法として使われているCoBRA  
法に関する記述として、最も適切なものはどれか。  
  
ア  CoBRA法では、工数の尺度として予算総額を用いる。  
  
イ  CoBRA法では、変動要因の値の散らばり方を近似するのに、正規分布を用い  
る。  
  
ウ  規模・工数の実績データが10件程度あれば、CoBRA法を適用できる。  
  
エ  ファンクションポイントからCoBRA法により、開発するシステムの規模を見  
積もる。  
        
`
        },
        {
                "mondaiId": 106280170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業がシステム開発を開発者?ベンダNに発注する場合、発注側の要求が開発  
結果に正しく反映されないことがある。以下のシステム開発の①~③の段階と、要  
求と結果の間に起こり得るａ~ｃのギャップの説明の組み合わせとして、最も適切  
なものを下記の解答群から選べ。  

発注者の要求内容  
　　↓　①発注者の要求内容から要件定義書を作成する段階  
要件定義書の内容  
　　↓　②要件定義書から外部設計を行う段階  
外部設計書の内容  
　　↓　③外部設計書から詳細設計を行う段階  
完成したソフトウェア  


＜ギャップの説明＞  
ａ発注者が開発者に説明した要件定義書に盛り込まれた内容が、開発側設計者  
の誤認等何らかの理由により開発内容から漏れた。  

ｂ開発者が何らかの理由により要件定義書の内容を誤認・拡大解釈し、実現範  
囲に盛り込んでしまった。  

ｃ要件定義すべき内容が抜けており、発注者が開発者に説明していない。  


（解答群）  
  
ア  ａ：①　ｂ：②　ｃ：②  
  
イ  ａ：②　ｂ：②　ｃ：①  
  
ウ  ａ：②　ｂ：③　ｃ：①  
  
エ  ａ：③　ｂ：②　ｃ：①  
        
`
        },
        {
                "mondaiId": 106280180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経済産業省は、IT人材および人材育成者が活用できる人材育成関連ツールとし  
て｢iコンピテンシ・ディクショナリ｣を紹介している。iコンピテンシ・ディクシ  
ョナリにおける情報セキュリティに関わるセキュリティアドミニストレータ人材と  
しては、情報セキュリティアドミニストレータ、ISセキュリティアドミニストレ  
ータ、そしてインシデントハンドラが含まれる。これらのうち、情報セキュリティ
アドミニストレータ人材の活動内容として最も適切なものはどれか。  
  
ア  セキュリティ管理  
  
イ  セキュリティ基準の策定  
  
ウ  セキュリティ事故と対応の分析  
  
エ  セキュリティ方針の策定`
        },
        {
                "mondaiId": 106280190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報システムの利用においては、フィッシング詐欺や情報漏洩事案などの増加に  
対応するために情報セキュリティをより高めなければならない。その一環としてユ  
ーザ認証の強化が叫ばれている。これに関する記述として最も適切なものはどれ  
か。  
  
ア  CHAP認証とは、チャレンジ／レスポンスという方式で、Webサイトにアク  
セスしてきたユーザを認証するものである。  
  
イ  二段階認証とは、同じパスワードを２回入力させてユーザの認証を行う方式の  
ことである。  
  
ウ  ハードウェアトークンとは、その機器を認証装置にかざすことで本人を認証す  
る仕組みのことである。  
  
エ  ワンタイムパスワードとは、サイトに登録した際に最初の認証に利用されるパ  
スワードである。  
        
`
        },
        {
                "mondaiId": 106280200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報セキュリティへの脅威としてのクリックジャッキング攻撃およびその対策に  
関する記述として、最も適切なものはどれか。  
  
ア  Webページに出力するすべての要素に対して、エスケープ処理を実施するこ  
とで、クリックジャッキング攻撃を防止することができる。  
  
イ  WebページのHTTPレスポンスヘッダにX-Frame-Optionsヘッダフィールド  
を出力しないことが、クリックジャッキング攻撃への対策となる。  
  
ウ  クリックジャッキング攻撃とクロスサイト・リクエスト・フォージェリに共通  
する対策がある。  
  
エ  クリックに応じた処理を実行する直前のページで再度パスワードの入力を求  
め、再度入力されたパスワードが正しい場合のみ処理を実行することが、クリッ  
クジャッキング攻撃とクロスサイト・スクリプティングで共通の対策となる。  
        
`
        },
        {
                "mondaiId": 106280210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
顧客に情報サービスを提供するシステムを開発するプロジェクトにおいては、品  
質に関わる様々な基準や仕組みを考慮しなければならない。以下のａ~ｃについて  
参考とする基準や仕組みの組み合わせとして、最も適切なものを下記の解答群から  
選べ。  

ａ顧客に提供する情報サービス品質の管理  
ｂセキュリティを重視するIT製品の調達  
ｃソフトウェア品質保証のために厳密に定義された仕様記述と検証  

（解答群）  
  
ア  ａ：CommonCriteria　ｂ：ITSMS　ｃ：ISMS  
  
イ  ａ：ITSMS　ｂ：CommonCriteria　ｃ：VDM  
  
ウ  ａ：ISMS　ｂ：VDM　ｃ：CommonCriteria  
  
エ  ａ：VDM　ｂ：ISMS　ｃ：ITSMS  
        
`
        },
        {
                "mondaiId": 106280220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年、クラウドサービスが台頭し、自社システムからクラウドサービスに移行す  
る動きが活発になりつつある。クラウドサービスは中小事業者にとっても有益であ  
るが、その利用のためには様々な課題について検討しなくてはならない。  
クラウドサービスやその利用に関する記述として最も適切なものはどれか。  
  
ア  クラウドサービスにおいては、情報セキュリティの確保が重要になるが、独立  
行政法人情報処理推進機構ではクラウドサービスの安全利用に関する手引きを出  
している。  
  
イ  クラウドサービスの利用料金の多くはサービス内容に応じて異なるが、使用し  
たデータ容量では異ならないので、コストの視点から大企業の多くがクラウドサ  
ービスを利用し始めている。  
  
ウ  パブリッククラウドの形態には、SaaS、PaaS、IaaS、DaaSなどがあり、いず  
れもアプリケーション、ミドルウェア、OS、ハードウェアが一体化されたサー  
ビスとしてエンドユーザに提供される。  
  
エ  オンプレミス型クラウドサービスとは自社でインフラを持たずクラウド事業者  
からサービスの提供を受ける形態をいい、ホステッド型クラウドサービスとは自  
社でインフラを持つ企業内クラウドの形態をいう。  
        
`
        },
        {
                "mondaiId": 106280230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
多くの情報機器を用いて業務を行わなくてはならない状況が進展しつつある中、  
そのエネルギー消費や機器の廃棄などで環境への配慮が重要な課題となりつつあ  
る。環境問題への対応に関する記述として最も適切なものはどれか。  
  
ア  EuP(Energy-usingProduct)とは、エネルギー使用製品に対して環境配慮設計  
を義務づけるわが国独自の規制のことである。  
  
イ  IT機器自体の省エネをGreenbyIT、ITを利活用した社会の省エネをGreen  
 of ITといい、グリーンIT委員会(JEITA)はそれらを導入した場合の省エネ効  
果を試算している。  
  
ウ  PUE(PowerUsageEffectiveness)とは、データセンターやサーバ室のエネル  
ギー効率を表す指標で、企業全体の消費電力をデータセンターの消費電力で除算  
した数値である。  
  
エ  ホワイトデータセンターとは、電力消費を低減するため雪氷熱を利用するデー  
タセンターである。  
        
`
        },
        {
                "mondaiId": 106280240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商品の需要量を予測する時系列モデルにはいくつかのものがある。これらに関す  
る記述として最も適切なものはどれか。  
  
ア  ARMAモデルは、自己回帰モデルと移動平均モデルを組み合わせたものであ  
る。  
  
イ  指数平滑法は、自己回帰モデルと物価指数による単回帰モデルを組み合わせた  
ものである。  
  
ウ  バスモデルでは、t期の売上はその期の購入者数に比例して発生するとして計  
算する。  
  
エ  バスモデルでは、イノベータとは消費者のうち製品を初期に購入する2.5％の  
層のことである。  
        
`
        },
        {
                "mondaiId": 106280250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2016,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
当社は製造に必要なある共通部品を3社から仕入れている。手元にある各社の在  
庫部品からいくつかを抜き出して、それぞれの重量を計った(下表参照)。在庫部品  
の平均重量が仕入元によって異なるかどうかを知りたい。  
その方法に関する記述として、最も適切なものを下記の解答群から選べ。  

各部品の重量  
<imgTag>106280250_1.png</imgTag>  


（解答群）  
  
ア  3社のデータについての比較なので、多元配置の分散分析を利用する。  
  
イ  3社のデータについての比較なので、分散分析では群間の自由度は\u000eにな  
る。  
  
ウ  5％の有意水準で2社間の平均値の差のt検定を3回繰り返して、いずれも  
有意差が出ないならば5％水準で差がないといえる。  
  
エ  平均値の差のt検定を任意の2社間で繰り返すと、検定の多重性による第二  
種の過誤が大きくなる。  
`
        },
        {
                "mondaiId": 107280011,
                "name": "第1問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。 

中小企業が抱える大きな課題として、大企業との収益力の格差があげられる。財  
務省｢法人企業統計調査年報｣に基づき、1980年度から2013年度の期間について、  
企業規模別に売上高経常利益率の推移を全産業で見た場合、<u>大企業の売上高経常利  
益率は中小企業を上回っており、すう勢的に見てその差は2000年代に拡大する傾  
向にある</u>。  

売上高経常利益率同様、1980年度から2013年度の期間について、企業規模別に  
売上高固定費比率と売上高変動費比率の推移を全産業で見ると、おおむね中小企業  
の売上高固定費比率は大企業よりも（　Ａ　）水準にあり、売上高変動費比率は大  
企業よりも（　Ｂ　）水準にある。中小企業と大企業の収益力格差を解消するため  
には、こうした中小企業の費用構造を理解して取り組むことも重要である。  

なお、ここでは大企業は資本金１億円以上の企業、中小企業は資本金１億円未満  
の企業とする。  
        
（設問１）

文中の下線部について、2000年代以降、中小企業と大企業の売上高経常利益  
率の格差が拡大傾向にある理由として、最も適切なものはどれか。  
  
ア  中小企業の売上高経常利益率の上昇幅を、大企業の売上高経常利益率の上昇  
幅が上回ったため。  
  
イ  中小企業の売上高経常利益率が低下する一方、大企業の売上高経常利益率が  
上昇したため。  
  
ウ  中小企業の売上高経常利益率の低下幅を、大企業の売上高経常利益率の低下  
幅が下回ったため。  
  
エ  中小企業の売上高経常利益率が横ばいであった一方、大企業の売上高経常利  
益率が上昇したため。`
        },
        {
                "mondaiId": 107280012,
                "name": "第1問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。 
 
中小企業が抱える大きな課題として、大企業との収益力の格差があげられる。財  
務省｢法人企業統計調査年報｣に基づき、1980年度から2013年度の期間について、  
企業規模別に売上高経常利益率の推移を全産業で見た場合、<u>大企業の売上高経常利  
益率は中小企業を上回っており、すう勢的に見てその差は2000年代に拡大する傾  
向にある</u>。  

売上高経常利益率同様、1980年度から2013年度の期間について、企業規模別に  
売上高固定費比率と売上高変動費比率の推移を全産業で見ると、おおむね中小企業  
の売上高固定費比率は大企業よりも（　Ａ　）水準にあり、売上高変動費比率は大  
企業よりも（　Ｂ　）水準にある。中小企業と大企業の収益力格差を解消するため  
には、こうした中小企業の費用構造を理解して取り組むことも重要である。  

なお、ここでは大企業は資本金１億円以上の企業、中小企業は資本金１億円未満  
の企業とする。  
        
（設問２）

文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：高いＢ：高い  
  
イ  Ａ：高いＢ：低い  
  
ウ  Ａ：低いＢ：高い  
  
エ  Ａ：低いＢ：低い  
        
`
        },
        {
                "mondaiId": 107280021,
                "name": "第2問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

わが国の産業構造の変化を事業所数と従業者数の推移により見ていく。総務省  
｢事業所・企業統計調査｣、同｢平成21年経済センサス安基礎調査｣、総務省・経済  
産業省｢平成24年経済センサス安活動調査｣に基づくと、わが国の事業所数は  
Ａ年代末までは増加傾向にあったが、以降は減少傾向に転じている。従業  
者数はＢ年代央までは増加傾向にあったが、その後は景気変動などによる  
増減はあるものの、おおむね横ばいで推移している。  

次に事業所数と従業者数で見た1986年と2012年の産業構成比を見ると、事業所  
数で見た産業構成比、従業者数で見た産業構成比とも、サービス業、医療、福祉が  
増加している。一方で、Ｃの事業所数で見た産業構成比は増加しているも  
のの、従業者数で見た産業構成比は減少している。またＤの従業者数で見  
た産業構成比の減少割合は、事業所数で見た産業構成比の減少割合と比較してもと  
りわけ大きなものになっている。  

（設問１）

文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：1970Ｂ：1980  
  
イ  Ａ：1980Ｂ：1980  
  
ウ  Ａ：1980Ｂ：1990  
  
エ  Ａ：1990Ｂ：1990  
  
オ  Ａ：1990Ｂ：2000  
        
`
        },
        {
                "mondaiId": 107280022,
                "name": "第2問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

わが国の産業構造の変化を事業所数と従業者数の推移により見ていく。総務省  
｢事業所・企業統計調査｣、同｢平成21年経済センサス安基礎調査｣、総務省・経済  
産業省｢平成24年経済センサス安活動調査｣に基づくと、わが国の事業所数は  
Ａ年代末までは増加傾向にあったが、以降は減少傾向に転じている。従業  
者数はＢ年代央までは増加傾向にあったが、その後は景気変動などによる  
増減はあるものの、おおむね横ばいで推移している。  

次に事業所数と従業者数で見た1986年と2012年の産業構成比を見ると、事業所  
数で見た産業構成比、従業者数で見た産業構成比とも、サービス業、医療、福祉が  
増加している。一方で、Ｃの事業所数で見た産業構成比は増加しているも  
のの、従業者数で見た産業構成比は減少している。またＤの従業者数で見  
た産業構成比の減少割合は、事業所数で見た産業構成比の減少割合と比較してもと  
りわけ大きなものになっている。  

（設問２）

文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：建設業Ｄ：製造業  
  
イ  Ｃ：小売業Ｄ：卸売業  
  
ウ  Ｃ：小売業Ｄ：建設業  
  
エ  Ｃ：小売業Ｄ：製造業  
  
オ  Ｃ：製造業Ｄ：建設業`
        },
        {
                "mondaiId": 107280030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄に入る語句として、最も適切なものを下記の解答群から選べ。  

経済産業省｢商業統計表｣、総務省・経済産業省｢平成24年経済センサス安活動  
調査｣に基づき、2002年と2011年について、卸売業の事業所数、従業者数、年間  
販売額、従業者１人当たりの年間販売額の増減を見た場合、（　　）のみ増加し  
ている。  

なお、2011年の事業所数、従業者数は2012年２月１日現在の数値とする。  

（解答群）  
  
ア  事業所数  
  
イ  従業者数  
  
ウ  従業者１人当たりの年間販売額  
  
エ  年間販売額  
        
`
        },
        {
                "mondaiId": 107280040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財務省｢法人企業統計年報｣に基づき、業種別に法人企業である中小企業の労働生  
産(2013年、中央値)を比較した場合、卸・小売業、建設業、サービス業、製造  
業のうち、最も高いものはどれか。ただし、企業規模区分は中小企業基本法に準ず  
るものとする。  
  
ア  卸・小売業  
  
イ  建設業  
  
ウ  サービス業  
  
エ  製造業  
        
`
        },
        {
                "mondaiId": 107280051,
                "name": "第5問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

総務省・経済産業省｢平成24年経済センサス安活動調査｣に基づくと、わが国に  
おいて小規模企業の事業者数は334万者、従業者数は約Ａ万人、わが国の  
企業数の約9割、総従業者数の約4分の1を占める非常に大きな存在である。他方  
で売上高を企業規模別に見た場合、小規模企業の割合は全体の約Ｂ割にと  
どまっている。  

また小規模企業の業種構成(非一次産業)を見た場合、事業者数ではＣの  
構成割合が、従業者数ではＤの構成割合が最も高くなっており、これらの  
業種での小規模企業性の高さがうかがえる。  

なお、企業規模区分は中小企業基本法に準ずるものとする。ただし、宿泊業・娯  
楽業は常用雇用者20人以下の企業を小規模企業とする。  

（設問１）

文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：800 Ｂ：1  
        
イ  Ａ：1，200 Ｂ：1  
        
ウ  Ａ：1，600 Ｂ：2  
  
エ  Ａ：2，000 Ｂ：2  
  
オ  Ａ：2，400 Ｂ：3  
        
`
        },
        {
                "mondaiId": 107280052,
                "name": "第5問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

総務省・経済産業省｢平成24年経済センサス安活動調査｣に基づくと、わが国に  
おいて小規模企業の事業者数は334万者、従業者数は約Ａ万人、わが国の  
企業数の約9割、総従業者数の約4分の1を占める非常に大きな存在である。他方  
で売上高を企業規模別に見た場合、小規模企業の割合は全体の約Ｂ割にと  
どまっている。  

また小規模企業の業種構成(非一次産業)を見た場合、事業者数ではＣの  
構成割合が、従業者数ではＤの構成割合が最も高くなっており、これらの  
業種での小規模企業性の高さがうかがえる。  

なお、企業規模区分は中小企業基本法に準ずるものとする。ただし、宿泊業・娯  
楽業は常用雇用者20人以下の企業を小規模企業とする。  

（設問2）

文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：建設業Ｄ：小売業  
  
イ  Ｃ：建設業Ｄ：製造業  
  
ウ  Ｃ：小売業Ｄ：建設業  
  
エ  Ｃ：小売業Ｄ：製造業  
  
オ  Ｃ：製造業Ｄ：小売業  
        
`
        },
        {
                "mondaiId": 107280060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

総務省・経済産業省｢平成24年経済センサス安活動調査｣に基づき、三大都市圏  
と三大都市圏以外で、企業規模別に見た従業者数の割合を比較した場合、大企業は  
三大都市圏の方が三大都市圏以外よりＡ。小規模企業以外の中小企業で  
は、三大都市圏の方が三大都市圏以外よりＢ。小規模企業では三大都市圏  
の方が三大都市圏以外よりＣ。  

なお、企業規模区分は中小企業基本法に準ずるものとする。ただし、ゴム製品製  
造業は常用雇用者900人以下、旅館、ホテルは常用雇用者200人以下、ソフトウエ
ア業、情報処理・提供サービス業は資本金3億円以下または常用雇用者300人以下  
の企業を中小企業に含む。宿泊業・娯楽業については常用雇用者20人以下の企業  
を小規模企業とする。  

また、三大都市圏とは、東京圏(埼玉県、千葉県、東京都、神奈川県)、名古屋圏  
(岐阜県・愛知県・三重県)、大阪圏(京都府・大阪府・兵庫県・奈良県)をいう。  

（解答群）  
  
ア  Ａ：高いＢ：高いＣ：低い  
  
イ  Ａ：高いＢ：低いＣ：低い  
  
ウ  Ａ：低いＢ：高いＣ：低い  
  
エ  Ａ：低いＢ：低いＣ：高い  
        
`
        },
        {
                "mondaiId": 107280070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経営資源の限られる小規模企業にとって、安定的な資金調達は事業を円滑に運営  
するうえで極めて重要である。中小企業庁｢小規模事業者の事業活動の実態把握調  
査(2015年1月)｣に基づき、小規模企業の運転資金と設備資金の調達方法を見た場  
合(複数回答)、最も適切な記述はどれか。  
  
ア  運転資金・設備資金とも｢個人からの借り入れ｣が最も回答企業割合が高い。  
  
イ  運転資金・設備資金とも｢銀行借り入れ｣が最も回答企業割合が高い。  
  
ウ  運転資金のみ｢個人からの借り入れ｣が最も回答企業割合が高い。  
  
エ  設備資金のみ｢個人からの借り入れ｣が最も回答企業割合が高い。  
  
オ  設備資金のみ｢自己資金｣が最も回答企業割合が高い。  
        
`
        },
        {
                "mondaiId": 107280081,
                "name": "第8問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
生産年齢人口の減少や高齢化が進展する中、わが国経済の潜在的労働力として女  
性に注目が集まっており、女性が活躍できる環境づくりの必要性が高まっている。  
総務省｢平成24年就業構造基本調査｣に基づき、男女別・年齢別就業率を見た場合、  
女性の就業率の特徴として<u>Ｍ字カーブ</u>の存在が指摘され、その解消が女性の活躍を  
促進する際の一つの目標とされている。  

また、従業員規模別に雇用者の性別や年齢を見た場合、女性の従業員の割合は規  
模の小さな企業ほどＡ傾向にあり、高齢者の雇用の割合は規模の小さな企  
業ほどＢ傾向にある。物的な経営資源の乏しい中小企業にとり人材は最大  
の経営資源といえるが、雇用をめぐる環境が変化する中で、多様な雇用の担い手と  
しての役割も期待されている。  

（設問1)

文中の下線部に関する説明として、最も適切なものはどれか。  
  
ア  20代前半の女性就業率が男性就業率に比べ低いこと。  
  
イ  20代後半から30代にかけての女性就業率が低下すること。  
  
ウ  30代後半から40代にかけての女性就業率が低下すること。  
  
エ  40代後半から50代にかけての女性就業率が低下すること。  
  
オ  50代の女性就業率が男性就業率に比べ低いこと。  
        
`
        },
        {
                "mondaiId": 107280082,
                "name": "第8問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  
生産年齢人口の減少や高齢化が進展する中、わが国経済の潜在的労働力として女  
性に注目が集まっており、女性が活躍できる環境づくりの必要性が高まっている。  
総務省｢平成24年就業構造基本調査｣に基づき、男女別・年齢別就業率を見た場合、  
女性の就業率の特徴として<u>Ｍ字カーブ</u>の存在が指摘され、その解消が女性の活躍を  
促進する際の一つの目標とされている。  

また、従業員規模別に雇用者の性別や年齢を見た場合、女性の従業員の割合は規  
模の小さな企業ほどＡ傾向にあり、高齢者の雇用の割合は規模の小さな企  
業ほどＢ傾向にある。物的な経営資源の乏しい中小企業にとり人材は最大  
の経営資源といえるが、雇用をめぐる環境が変化する中で、多様な雇用の担い手と  
しての役割も期待されている。  

（設問2)

文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：高いＢ：高い  
  
イ  Ａ：高いＢ：低い  
  
ウ  Ａ：低いＢ：高い  
  
エ  Ａ：低いＢ：低い  
        
`
        },
        {
                "mondaiId": 107280090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
大企業と中小企業の取引構造を見ると、戦後長らく｢系列｣に代表される下請取引  
構造が存在し、大企業の下請を行う中小企業は単工程の受託加工を中心とした生産  
活動を行ってきた。下請中小企業は、発注側大企業から発注と引き換えに厳しい品  
質、納期、コスト対応を迫られる一方で、生産活動に経営資源を集中できるという  
メリットも享受してきた。しかしながらグローバル規模で企業間競争が激化する中  
で、大企業と中小企業の取引構造も大きく変容している。  

中小企業庁｢発注方式等取引条件改善調査｣に基づき、1991年から2013年の期間  
について、下請企業が常時取引する親事業者数と取引額の最も大きい親事業者への  
依存度を見た場合の記述として、最も適切なものはどれか。  
  
ア  常時取引する親事業者数は減少傾向にあり、取引額の最も大きい親事業者への  
依存度は上昇傾向にある。  
  
イ  常時取引する親事業者数は減少傾向にあり、取引額の最も大きい親事業者への  
依存度は低下傾向にある。  
  
ウ  常時取引する親事業者数は増加傾向にあり、取引額の最も大きい親事業者への  
依存度は上昇傾向にある。  
  
エ  常時取引する親事業者数は増加傾向にあり、取引額の最も大きい親事業者への  
依存度は低下傾向にある。  
        
`
        },
        {
                "mondaiId": 107280100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

取り巻く事業環境が激変する中で、中小企業においてもイノベーションの重要性  
が高まっているが、その実現に向けてはさまざまな課題を抱えているのが実情であ  
る。  

文部科学省科学技術・学術政策研究所｢第3回全国イノベーション調査(2013年1月)｣に基づき、企業規模別にイノベーション活動を実施した企業が経験したイノ  
ベーションの阻害要因を見た場合、大企業ではＡ、中小企業では  
Ｂをあげる回答企業割合が最も高くなっている。中小企業のイノベーショ  
ンを促進するためには、こうした違いに留意しながら支援体制を構築していくこと  
も重要である。  

なお、ここで中小企業とは常用雇用者(国内及び海外)10人以上249人以下の企  
業、大企業とは同250人以上の企業を指す。またイノベーションとは、新しい商  
品・サービスの市場投入や、生産プロセス、組織形態、マーケティング手法の画期  
的な改善を自社に導入することを指す。  

（解答群）  
  
ア  Ａ：技術に関する情報不足  
Ｂ：社内・グループ内資金不足  
  
イ  Ａ：技術に関する情報不足  
Ｂ：能力ある従業者不足  
  
ウ  Ａ：能力ある従業者不足  
Ｂ：社内・グループ内資金不足  
  
エ  Ａ：能力ある従業者不足  
Ｂ：必要な協力相手を見つけることが困難であること  
  
オ  Ａ：必要な協力相手を見つけることが困難であること  
Ｂ：社内・グループ内資金不足  
        
`
        },
        {
                "mondaiId": 107280111,
                "name": "第11問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業にとって新たな販路の開拓は収益性の向上に向けて重要な経営課題とな  
っている。  

中小企業庁｢市場開拓と新たな取り組みに関する調査(2014年12月)｣に基づき、  
事業者が今まで自社の市場と認識していなかった新規市場と、事業者が現在自社の  
市場と認識している既存市場に大別して、業種別に販路開拓の取り組み状況を見た  
場合(複数回答)、業種によって取り組み状況に違いが見られる。例えば、  
Ａにおいては既存市場の販路開拓に取り組む回答企業割合が最も高いのに  
対し、Ｂにおいては既存市場、新規市場のいずれも販路開拓の取り組みな  
しとする回答企業割合が最も高い。  

次に販路開拓に取り組んだ結果として、売上目標の達成状況を見ると、既存市場  
の開拓においては約50％の企業が目標を達成しているものの、新規市場の開拓に  
おいては売上目標を達成している企業は約20％にとどまっている。<u>同様に売上目  
標を達成できなかった理由を見ても(複数回答)、既存市場と新規市場の開拓では違  
いが見られる</u>。中小企業が新たな販路開拓を進めるためには、こうした課題を克服  
していくことが求められている。  

（設問1)

文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：建設業Ｂ：小売業  
  
イ  Ａ：小売業Ｂ：建設業  
  
ウ  Ａ：サービス業Ｂ：製造業  
  
エ  Ａ：製造業Ｂ：サービス業  
        
`
        },
        {
                "mondaiId": 107280112,
                "name": "第11問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

中小企業にとって新たな販路の開拓は収益性の向上に向けて重要な経営課題とな  
っている。  

中小企業庁｢市場開拓と新たな取り組みに関する調査(2014年12月)｣に基づき、  
事業者が今まで自社の市場と認識していなかった新規市場と、事業者が現在自社の  
市場と認識している既存市場に大別して、業種別に販路開拓の取り組み状況を見た  
場合(複数回答)、業種によって取り組み状況に違いが見られる。例えば、  
Ａにおいては既存市場の販路開拓に取り組む回答企業割合が最も高いのに  
対し、Ｂにおいては既存市場、新規市場のいずれも販路開拓の取り組みな  
しとする回答企業割合が最も高い。  

次に販路開拓に取り組んだ結果として、売上目標の達成状況を見ると、既存市場  
の開拓においては約50％の企業が目標を達成しているものの、新規市場の開拓に  
おいては売上目標を達成している企業は約20％にとどまっている。<u>同様に売上目  
標を達成できなかった理由を見ても(複数回答)、既存市場と新規市場の開拓では違  
いが見られる</u>。中小企業が新たな販路開拓を進めるためには、こうした課題を克服  
していくことが求められている。  

（設問2)
文中の下線部について、新規市場の開拓において売上目標が未達成の理由を見  
た場合、回答企業割合が最も高いものはどれか。  
  
ア  商品・サービスの価格の問題  
  
イ  商品・サービスの品質の問題  
  
ウ  納期の問題  
  
エ  販売・提供する組織体制の問題  
        
`
        },
        {
                "mondaiId": 107280121,
                "name": "第12問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

地方圏における雇用確保や経済活性化を目的として、多くの自治体が製造業分野を主体とする企業誘致を積極的に進めている。しかしながら、経済産業省｢工場立地動向調査｣に基づき、1981年から2013年の期間について、製造業の工場立地件数と①雇用予定従業者数を見た場合、安定的に推移しているわけではない。地域経済の活性化には、中長期的な②産業構造の変化も適切に踏まえて取り組むことが重要である。  

（設問1)  

文中の下線部①について、経済産業省｢工場立地動向調査｣に基づき、1981年  
から2013年の期間について、工場当たりの雇用予定従業者数の推移を見た場合  
の記述として、最も適切なものはどれか。なお、ここでは、工場立地件数と雇用  
予定従業者数については、それぞれ後方3期移動平均で算出している。  
  
ア  1980年代は増加、1990年代は減少で推移している。  
  
イ  1990年代は増加、2000年代以降は減少で推移している。  
  
ウ  1990年代は減少、2000年代以降は増加で推移している。  
  
エ  長期的に見て減少基調で推移している。  
  
オ  長期的に見て増加基調で推移している。  
        
`
        },
        {
                "mondaiId": 107280122,
                "name": "第12問設問2",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                次の文章を読んで、下記の設問に答えよ。  

地方圏における雇用確保や経済活性化を目的として、多くの自治体が製造業分野を主体とする企業誘致を積極的に進めている。しかしながら、経済産業省｢工場立地動向調査｣に基づき、1981年から2013年の期間について、製造業の工場立地件数と①雇用予定従業者数を見た場合、安定的に推移しているわけではない。地域経済の活性化には、中長期的な②産業構造の変化も適切に踏まえて取り組むことが重要である。  

（設問2)  

文中の下線部②について、経済産業省｢工業統計｣に基づき、従業者数で見た産  
業構成比(製造業中分類)の変化を1986年と2012年の時点で比較した場合の記述  
として、<u>最も不適切なものはどれか</u>。  

なお、ここでは従業者数4名以上の事業所を対象としている。  
  
ア  一般機械の構成割合は増加している。  
  
イ  食料品の構成割合は増加している。  
  
ウ  繊維の構成割合は減少している。  
  
エ  電気機械器具の構成割合は減少している。  
  
オ  輸送用機械の構成割合は減少している。  
        
`
        },
        {
                "mondaiId": 107280131,
                "name": "第13問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

<u>①グローバル経済化が加速する中で、わが国企業の海外展開は拡大傾向にある</u>。こ  
れに伴い海外子会社からの配当金やロイヤリティなどの収入も増加しており、企業  
経営における重要性も高まっている。財務省・日本銀行｢国際収支統計｣に基づき、  
わが国企業が海外で稼いだ利益の還元状況を海外での再投資と日本国内への利益還  
流に大別して見ると(2014年)、国内への利益還流は約Ａ割を占めている。  
なお経済産業省｢海外事業活動基本調査｣に基づき、2001年度から2013年度の期間  
について、日本側出資者向支払額の業種別割合の推移を見ると、Ｂが一貫  
して過半を占めている。  

もっとも<u>②海外現地法人の売上高経常利益率</u>を業種別に見ると、業種によって海外  
展開から利益を拡大できている業種とそうでない業種があることも見て取れる。中  
小企業の海外展開を支援するうえでは、こうした違いを理解することも重要であ  
る。  

ここでは海外における再投資は直接投資収益のうち再投資収益の受取額、国内へ  
の利益還元は配当金・配分済支店収益の受取額を示す。  

（設問1)  

文中の下線部①について、経済産業省｢海外事業活動基本調査｣と財務省｢法人  
企業統計調査｣に基づき、2010年度から2013年度の期間について、海外現地法  
人と国内法人の売上高経常利益率(全産業)の推移を比較した場合の記述として、  
最も適切なものはどれか。  
  
ア  海外現地法人と国内法人の売上高経常利益率の格差は縮小傾向にある。  
  
イ  海外現地法人の売上高経常利益率は一貫して上昇している。  
  
ウ  海外現地法人の売上高経常利益率は国内法人を一貫して大きく上回ってい  
る。  
  
エ  国内法人の売上高経常利益率は海外現地法人を一貫して大きく上回ってい  
る。  
        
`
        },
        {
                "mondaiId": 107280132,
                "name": "第13問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

<u>①グローバル経済化が加速する中で、わが国企業の海外展開は拡大傾向にある</u>。こ  
れに伴い海外子会社からの配当金やロイヤリティなどの収入も増加しており、企業  
経営における重要性も高まっている。財務省・日本銀行｢国際収支統計｣に基づき、  
わが国企業が海外で稼いだ利益の還元状況を海外での再投資と日本国内への利益還  
流に大別して見ると(2014年)、国内への利益還流は約Ａ割を占めている。  
なお経済産業省｢海外事業活動基本調査｣に基づき、2001年度から2013年度の期間  
について、日本側出資者向支払額の業種別割合の推移を見ると、Ｂが一貫  
して過半を占めている。  

もっとも<u>②海外現地法人の売上高経常利益率</u>を業種別に見ると、業種によって海外  
展開から利益を拡大できている業種とそうでない業種があることも見て取れる。中  
小企業の海外展開を支援するうえでは、こうした違いを理解することも重要であ  
る。  

ここでは海外における再投資は直接投資収益のうち再投資収益の受取額、国内へ  
の利益還元は配当金・配分済支店収益の受取額を示す。  

（設問2)  

文中の空欄ＡとＢに入る数値と語句の組み合わせとして、最も適切なものはど  
れか。  
  
ア  Ａ：3 Ｂ：卸小売業  
  
イ  Ａ：3 Ｂ：製造業  
  
ウ  Ａ：7 Ｂ：卸小売業  
  
エ  Ａ：7 Ｂ：製造業`
        },
        {
                "mondaiId": 107280133,
                "name": "第13問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

<u>①グローバル経済化が加速する中で、わが国企業の海外展開は拡大傾向にある</u>。こ  
れに伴い海外子会社からの配当金やロイヤリティなどの収入も増加しており、企業  
経営における重要性も高まっている。財務省・日本銀行｢国際収支統計｣に基づき、  
わが国企業が海外で稼いだ利益の還元状況を海外での再投資と日本国内への利益還  
流に大別して見ると(2014年)、国内への利益還流は約Ａ割を占めている。  
なお経済産業省｢海外事業活動基本調査｣に基づき、2001年度から2013年度の期間  
について、日本側出資者向支払額の業種別割合の推移を見ると、Ｂが一貫  
して過半を占めている。  

もっとも<u>②海外現地法人の売上高経常利益率</u>を業種別に見ると、業種によって海外  
展開から利益を拡大できている業種とそうでない業種があることも見て取れる。中  
小企業の海外展開を支援するうえでは、こうした違いを理解することも重要であ  
る。  

ここでは海外における再投資は直接投資収益のうち再投資収益の受取額、国内へ  
の利益還元は配当金・配分済支店収益の受取額を示す。  

（設問3)  
文中の下線部②について、経済産業省｢第44回海外事業活動基本調査(2014年  
月)｣に基づき、業種別に海外現地法人の売上高経常利益率(2013年度)を見た  
場合、最も高いものはどれか。  
  
ア  化学  
  
イ  小売業  
  
ウ  情報通信機械  
  
エ  鉄鋼  
  
オ  輸送機械  
        
`
        },
        {
                "mondaiId": 107280141,
                "name": "第14問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　中小企業基本法は、中小企業施策について、基本理念・基本方針などを定めている。その第三条には基本理念が示され、中小企業を｢多様な事業の分野において特色ある事業活動を行い、多様な就業の機会を提供し、個人がその能力を発揮しつつ事業を行う機会を提供することにより我が国の経済の基盤を形成しているもの｣と位置付けている。  
特に、｢多数の中小企業者が（　Ａ　）経営の向上を図るための事業活動を行うことを通じて、新たな産業を創出し、就業の機会を増大させ、（　Ｂ　）、地域における経済の活性化を促進する等我が国経済の活力の維持及び強化に果たすべき重要な使命を有するもの｣としている。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>

（設問 １） 
　中小企業基本法に基づく、中小企業者の範囲や小規模企業者の範囲に関する記  
述として、最も適切なものはどれか。  
  
ア  従業員数10人、個人経営のパン製造小売業は、小規模企業者の範囲に含まれる。  
  
イ  従業員数30人、株式会社で資本金が5百万円の金型製造業は、小規模企業者の範囲に含まれる。  
  
ウ  従業員数50人、株式会社で資本金が5千万円の農業法人は、中小企業者の範囲に含まれる。<hint hidden='true'>**
←⭕
**</hint>    
  
エ  従業員数100人、株式会社で資本金が8千万円の情報機器小売業は、中小企業者の範囲に含まれる。  
        
`
        },
        {
                "mondaiId": 107280142,
                "name": "第14問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　中小企業基本法は、中小企業施策について、基本理念・基本方針などを定めている。その第三条には基本理念が示され、中小企業を｢多様な事業の分野において特色ある事業活動を行い、多様な就業の機会を提供し、個人がその能力を発揮しつつ事業を行う機会を提供することにより我が国の経済の基盤を形成しているもの｣と位置付けている。  
特に、｢多数の中小企業者が（　Ａ　）経営の向上を図るための事業活動を行うことを通じて、新たな産業を創出し、就業の機会を増大させ、（　Ｂ　）、地域における経済の活性化を促進する等我が国経済の活力の維持及び強化に果たすべき重要な使命を有するもの｣としている。  

（設問２）  
文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  経営資源を活用して  
  
イ  個性を発揮して  
  
ウ  潜在力を発揮して  
  
エ  創意工夫を生かして`
        },
        {
                "mondaiId": 107280143,
                "name": "第14問設問3",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
　次の文章を読んで、下記の設問に答えよ。  

　中小企業基本法は、中小企業施策について、基本理念・基本方針などを定めている。その第三条には基本理念が示され、中小企業を｢多様な事業の分野において特色ある事業活動を行い、多様な就業の機会を提供し、個人がその能力を発揮しつつ事業を行う機会を提供することにより我が国の経済の基盤を形成しているもの｣と位置付けている。  
特に、｢多数の中小企業者が（　Ａ　）経営の向上を図るための事業活動を行うことを通じて、新たな産業を創出し、就業の機会を増大させ、（　Ｂ　）、地域における経済の活性化を促進する等我が国経済の活力の維持及び強化に果たすべき重要な使命を有するもの｣としている。  

（設問3)  
文中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  新たな市場を創造し  
  
イ  公正な市場環境を整え  
  
ウ  市場における競争を促進し  
  
エ  市場の格差を是正し  
        
`
        },
        {
                "mondaiId": 107280150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下請代金支払遅延等防止法は、親事業者の不公正な取引を規制し、下請事業者の  
利益を保護することを図るものである。中小企業庁と公正取引委員会は、親事業者  
が同法のルールを遵守しているかどうか調査を行い、違反事業者に対しては、同法  
を遵守するよう指導している。  
この法律が適用される取引として、最も適切なものはどれか。  
  
ア  資本金2千万円の事業者が、資本金1千万円の事業者に物品の製造を委託する。  
  
イ  資本金(6千万円の事業者が、資本金2千万円の事業者に物品の製造を委託する。  
  
ウ  資本金1億円の事業者が、資本金3千万円の事業者に物品の製造を委託する。  
  
エ  資本金2億円の事業者が、資本金5千万円の事業者に物品の製造を委託する。  
        
`
        },
        {
                "mondaiId": 107280160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士のA氏は、飲食店を経営するB氏から｢廃業や退職に備え、生活  
の安定や事業の再建を図るための資金をあらかじめ準備しておきたい｣と相談を受  
けた。そこで、A氏はB氏に、いわば｢経営者の退職金制度｣である小規模企業共  
済制度を紹介することにした。 

この制度に関する、A氏のB氏に対する説明として、最も適切なものはどれか。  
  
ア  一括して受け取られる共済金は一時所得として取り扱われます。  
  
イ  勤労者退職金共済機構と退職金共済契約を結び、掛金を払うだけで、簡単に退  
職金制度を設けることができます。  
  
ウ  この制度の対象となるのは、1年以上継続して事業を行っている中小企業者で  
す。  
  
エ  その年に納付した掛金は全額所得控除できます。`
        },
        {
                "mondaiId": 107280170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢技術研究組合｣は、企業と企業、企業と大学などが、効果的な共同研究を進める  
ための相互扶助組織である。この組合制度に関する記述として、<u>最も不適切なもの  
はどれか</u>。  
  
ア  株式会社への移行など柔軟な組織変更が可能である。  
  
イ  特許料や特許審査請求料が免除される。  
  
ウ  賦課金を支払う組合員に対し研究開発税制が適用される。  
  
エ  法人格を有している。  
        
`
        },
        {
                "mondaiId": 107280180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士のX氏は、食品製造業を営むY氏から経営相談を受けた。以下  
は、X氏とY氏との会話である。  
会話の中の下線部に関する例として、<u>最も不適切なものを下記の解答群から選  
べ</u>。  

Ｘ氏：｢本日は顔色がさえませんね。今回は、どのようなご相談でしょうか？｣  

Ｙ氏：｢ここ数年、原材料が高騰しているのですが、親事業者に単価引き上げを求  
めても、まったく聞く耳をもってくれません。それどころか、先週、親事業  
者の一方的な都合で、代金の値引きを要求されてしまいました・・・。どう  
したらいいかと悩んでいます。｣  

Ｘ氏：｢お悩み察します。まずは、全国48カ所に設置されている下請かけこみ寺に  
相談してみてはいかがでしょうか？下請かけこみ寺では、中小企業・小規模  
事業者の取引に関する<u>さまざまな相談を受け付けています</u>。｣  

Ｙ氏：｢ただ、相談費用の捻出も厳しい状況なのです。｣  

Ｘ氏：｢アドバイス等は無料ですし、弁護士による無料相談も実施しています。問  
題が深刻化する前に相談されることをお薦めしますよ。｣  


（解答群）  
  
ア  原材料が高騰しているにもかかわらず、単価引き上げに応じてくれない。  
  
イ  仕事の受注の見返りに、取引先が取り扱う商品の購入を求められた。  
  
ウ  下請取引のあっせんを行ってほしい。  
  
エ  代金の値引き(減額)を要求された。  
        
`
        },
        {
                "mondaiId": 107280190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業者が集まって組織化することは、生産性の向上を図り、価値実現力を高  
め、あるいは対外交渉力の強化を図るための有効な方策のひとつである。中小企業  
者の組合としては、たとえば、事業協同組合、企業組合、協業組合、<u>商店街振興組  
合</u>などがある。  

上記文中の下線部の商店街振興組合に関する記述として、最も適切なものはどれ  
か。  
  
ア  議決権は出資比例である。  
  
イ  組合の地区の重複は禁止され、1地区に1組合しか設立できない。  
  
ウ  総組合員の2分の1以上が小売業を営む者でなければ設立することができな  
い。  
  
エ  ｢中小企業団体の組織に関する法律｣に基づく組合である。  
        
`
        },
        {
                "mondaiId": 107280201,
                "name": "第20問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業者と農林漁業者とが連携して行う事業活動を支援するために、法的措置  
や予算措置などにより総合的な支援が展開されている。  

中小企業者と農林漁業者とが連携し、それぞれの経営資源を有効に活用して行う  
新商品、新サービスの開発等を行う際、｢中小企業者と農林漁業者との連携による  
事業活動の促進に関する法律(農商工等連携促進法)｣に基づく支援のほか、さまざ  
まな支援を受けることができる。  

（設問1)  
農商工等連携促進法の支援対象として、最も適切なものはどれか。  
  
ア  中小企業者と農林漁業者との交流機会の提供を行う地方自治体であって、こ  
の法律に基づき｢農商工等連携支援事業計画｣を作成し、国の認定を受けた者  
  
イ  中小企業者等に対する農商工連携に関する指導等を行う一般社団・財団法人  
又はNPO法人であって、この法律に基づき｢農商工等連携支援事業計画｣を作  
成し、都道府県知事の認定を受けた者  
  
ウ  農商工等連携により新たな事業活動を展開しようとするNPO法人であっ  
て、この法律に基づき｢農商工等連携事業計画｣を作成し、都道府県知事の認定  
を受けた者  
  
エ  農商工等連携により新たな事業活動を展開しようとする中小企業者であっ  
て、この法律に基づき｢農商工等連携事業計画｣を作成し、国の認定を受けた者  
        
`
        },
        {
                "mondaiId": 107280202,
                "name": "第20問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                次の文章を読んで、下記の設問に答えよ。  

中小企業者と農林漁業者とが連携して行う事業活動を支援するために、法的措置  
や予算措置などにより総合的な支援が展開されている。  

中小企業者と農林漁業者とが連携し、それぞれの経営資源を有効に活用して行う  
新商品、新サービスの開発等を行う際、｢中小企業者と農林漁業者との連携による  
事業活動の促進に関する法律(農商工等連携促進法)｣に基づく支援のほか、さまざ  
まな支援を受けることができる。  

（設問2)  

農商工等連携促進法に基づいて、中小企業者と農林漁業者が連携して新商品・  
新サービスの開発等を行う｢農商工等連携事業計画｣を共同で作成し、認定を受け  
ると、各種支援施策を利用することができる。これらの支援施策として、<u>最も不  
適切なものはどれか</u>。  
  
ア  減価償却資産にかかわる税制の特別措置  
  
イ  信用保証の特例  
  
ウ  政府系金融機関による融資制度  
  
エ  マーケティングの専門家による支援`
        },
        {
                "mondaiId": 107280210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄に入る語句として、最も適切なものを下記の解答群から選べ。  

｢商業・サービス競争力強化連携支援事業｣は、地域経済を面的に底上げするた  
め、中小企業者が行う新しいサービスモデルの開発等を支援するものである。  

この事業の支援対象となるのは、｢異分野連携新事業分野開拓計画｣の認定を受  
け、産学官で連携し、｢中小サービス事業者の（　　　）のためのガイドライン｣に  
沿って行う新しいサービスモデルの開発等を行う中小企業者である。  

（解答群）  
  
ア  経営革新  
  
イ  新事業創出  
  
ウ  新連携  
  
エ  生産性向上  
        
`
        },
        {
                "mondaiId": 107280220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士のA氏は、中小製造業者のB氏から｢将来の一時的かつ至急の資  
金ニーズに備えておきたい｣との相談を受けた。そこで、A氏はB氏に、信用保証  
協会の債務保証付き融資を予約する制度である｢予約保証制度｣を紹介することにし  
た。  

この制度に関する、A氏のB氏に対する説明として、最も適切なものはどれか。  
  
ア  実際に保証付きの融資を受ける際に、通常の保証料率から一定割合を差し引い  
た保証料を負担します。  
  
イ  保証期間は3年以内になります。  
  
ウ  予約の時点で一定の手数料を支払います��  
  
エ  予約の有効期間は最長１年です。  
        
`
        },
        {
                "mondaiId": 107280231,
                "name": "第23問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

高度化事業は、中小企業者が共同で事業環境の改善や経営基盤の強化に取り組む  
場合に必要となる資金について、事業計画に対するアドバイスを受けたうえで、長  
期・低利(または無利子)で貸付けを受けることができるものである。  

高度化事業に対する貸付割合は、原則としてＡ％以内であり、貸付対象  
はＢである。  

主な事業の活用例のうち、｢Ｃ｣は、商店街に、アーケードやカラー舗  
装、駐車場などを整備したり、各商店を改装し、商店街の魅力・利便性を向上させ  
集客力を高めるものである。  

（設問１)  

文中の空欄ＡとＢに入る数値と語句の組み合わせとして、最も適切なものはど  
れか。  
  
ア  Ａ：50　Ｂ：運転資金・設備資金  
  
イ  Ａ：50　Ｂ：設備資金  
  
ウ  Ａ：80　Ｂ：運転資金・設備資金  
  
エ  Ａ：80　Ｂ：設備資金`
        },
        {
                "mondaiId": 107280232,
                "name": "第23問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                次の文章を読んで、下記の設問に答えよ。  

高度化事業は、中小企業者が共同で事業環境の改善や経営基盤の強化に取り組む  
場合に必要となる資金について、事業計画に対するアドバイスを受けたうえで、長  
期・低利(または無利子)で貸付けを受けることができるものである。  

高度化事業に対する貸付割合は、原則としてＡ％以内であり、貸付対象  
はＢである。  

主な事業の活用例のうち、｢Ｃ｣は、商店街に、アーケードやカラー舗  
装、駐車場などを整備したり、各商店を改装し、商店街の魅力・利便性を向上させ  
集客力を高めるものである。  

（設問２)  
文中の空欄Ｃに入る語句として、最も適切なものはどれか。  
  
ア  共同施設事業  
  
イ  施設集約化事業  
  
ウ  集積区域整備事業  
  
エ  集団化事業  
        
`
        },
        {
                "mondaiId": 107280241,
                "name": "第24問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のX氏は、海外展開を検討・計画している小規模事業者のY社  
長から｢海外市場での事業展開に向けた海外展開事業計画の策定などに関する支援  
施策についてアドバイスがほしい｣との相談を受けた。以下は、X氏とY社長との  
会話である。  

Ｘ氏：｢海外での事業展開に向けてどのようなことを考えているのですか。｣  

Ｙ社長：｢まずは、海外市場への投資に関する実現可能性調査が必要だと思ってい  
ます。それから、当社には現在、日本語のWebサイトしかありませんの  
で、Webサイトの外国語化も行いたいと考えています。｣  

Ｘ氏：｢分かりました。それで具体的には、どのような支援をご希望でしょう  
か。｣  

Ｙ社長：｢実現可能性調査に関しては、市場調査費や現地調査に係る海外旅費、通  
訳費、資料翻訳費、コンサルタント費用などの支援を受けることができれ  
ば助かります。また、Webサイトの外国語化に伴う翻訳費やレイアウト  
作成費などの補助をいただければありがたいです。｣  

Ｘ氏：｢それでは、Ａの利用を検討してみてはいかがでしょうか。申請  
内容が採択されれば、市場調査費、翻訳費などの支援を受けることができ  
ます。｣  

Ｙ社長：｢それは、まさしく当社にぴったりの支援施策ですね。利用申請などはど  
こにすればいいのですか。｣  

Ｘ氏：｢この事業の実施主体は、Ｂです。まずは、問い合わせをしてみ  
ましょう。御社の初めての海外展開、楽しみですね。｣  

Ｙ社長：｢ありがとうございます。前向きにがんばりますので、これからもご支援  
をお願いします。｣  
        
（設問１)  

会話中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  海外ビジネス戦略推進支援事業  
  
イ  地域間交流支援事業  
  
ウ  地域ネットワーク活用海外展開支援事業  
  
エ  海外事業再編戦略支援事業`
        },
        {
                "mondaiId": 107280242,
                "name": "第24問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業診断士のX氏は、海外展開を検討・計画している小規模事業者のY社  
長から｢海外市場での事業展開に向けた海外展開事業計画の策定などに関する支援  
施策についてアドバイスがほしい｣との相談を受けた。以下は、X氏とY社長との  
会話である。  

Ｘ氏：｢海外での事業展開に向けてどのようなことを考えているのですか。｣  

Ｙ社長：｢まずは、海外市場への投資に関する実現可能性調査が必要だと思ってい  
ます。それから、当社には現在、日本語のWebサイトしかありませんの  
で、Webサイトの外国語化も行いたいと考えています。｣  

Ｘ氏：｢分かりました。それで具体的には、どのような支援をご希望でしょう  
か。｣  

Ｙ社長：｢実現可能性調査に関しては、市場調査費や現地調査に係る海外旅費、通  
訳費、資料翻訳費、コンサルタント費用などの支援を受けることができれ  
ば助かります。また、Webサイトの外国語化に伴う翻訳費やレイアウト  
作成費などの補助をいただければありがたいです。｣  

Ｘ氏：｢それでは、Ａの利用を検討してみてはいかがでしょうか。申請  
内容が採択されれば、市場調査費、翻訳費などの支援を受けることができ  
ます。｣  

Ｙ社長：｢それは、まさしく当社にぴったりの支援施策ですね。利用申請などはど  
こにすればいいのですか。｣  

Ｘ氏：｢この事業の実施主体は、Ｂです。まずは、問い合わせをしてみ  
ましょう。御社の初めての海外展開、楽しみですね。｣  

Ｙ社長：｢ありがとうございます。前向きにがんばりますので、これからもご支援  
をお願いします。｣  
        
（設問２)  

会話中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  商工会・商工会議所  
  
イ  中小企業基盤整備機構  
  
ウ  都道府県等中小企業支援センター  
  
エ  日本貿易振興機構  
        
`
        },
        {
                "mondaiId": 107280250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
カイゼン指導者育成事業は、製造業・サービス業の中小企業・小規模事業者の生  
産性向上を促進することを目的としている。  
この事業の必要経費の補助対象者として、最も適切なものはどれか。  
  
ア  社内で生産性向上のためのリーダーを育成する中小企業・小規模事業者  
  
イ  生産性向上のための指導者を受け入れる中小企業・小規模事業者  
  
ウ  生産性向上のための指導を行う人材の育成・派遣事業を行う民間団体  
  
エ  地域産業の生産性向上のための指導者育成を行う地方自治体  
        
`
        },
        {
                "mondaiId": 107280260,
                "name": "第26問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士のX氏は、今年で設立(年を迎えた製造業(資本金3千万円、従  
業者数30人)のY社長から｢新製品にかかわる独自技術の特許出願を検討してい  
る。特許関連費用が安くなる制度を知りたい｣との相談を受けた。以下は、X氏と  
Y社長との会話である。  

以下の会話中の空欄ＡとＢに入る言葉の組み合わせとして、最も適切なものを下  
記の解答群から選べ。  

Ｘ氏：｢平成30年3月までに特許の審査請求または国際出願を行う場合に、審査  
請求料・国際出願に係る手数料の軽減措置が受けられます。｣  

Ｙ社長：｢当社も対象になるのでしょうか。｣  

Ｘ氏：｢対象は、①小規模の個人事業主、②事業開始後10年未満の個人事業主、  
③小規模法人企業、④設立後10年未満でＡ以下の法人です。③  
および④については、大企業の子会社など支配法人のいる場合を除きま  
す。御社は対象になりますよ。｣  

Ｙ社長：｢具体的には、どのような措置が受けられるのでしょうか。｣  

Ｘ氏：｢特許を取得する際の審査請求料・国際出願に係る手数料がＢに  
軽減されます。｣  

Ｙ社長：｢それは、ありがたい制度ですね。ぜひ、利用を検討してみます。｣  


（解答群）  
  
ア  Ａ：資本金3億円　Ｂ：１／２  
  
イ  Ａ：資本金3億円　Ｂ：１／３  
  
ウ  Ａ：従業者数100人　Ｂ：１／２  
  
エ  Ａ：従業者数100人　Ｂ：１／３  
        
`
        },
        {
                "mondaiId": 107280270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小規模製造業者のX社は、事業の持続的発展のための取り組みに必要な機械設  
備の導入にあたり、3千万円の資金の借り入れを検討している。X社の社長から  
相談を受けた中小企業診断士のY氏は、X社に対して、小規模事業者経営発達支  
援融資制度を提案することにした。  

以下は、X社の社長に対するY氏の説明である。空欄ＡとＢに入る言葉の組み  
合わせとして、最も適切なものを下記の解答群から選べ。  

Ｘ社社長：｢今ご提案をいただいた融資制度を利用するには、何か要件があるので  
しょうか。｣  

Ｙ氏：｢この融資制度の対象となるのは、小規模事業者の方で、次の要件をす  
べて満たす方になります。  

・Ａの認定を受けた商工会・商工会議所から、売上の増加や収  
益の改善、持続的な経営のための事業計画策定にあたり助言とフォロー
アップを受けること。  
・地域経済の活性化のために、Ｂ。  
・経営者および従業員の知識、技能、管理能力の向上を図る研修に参加  
するなど人材の確保・育成に努めていること。  
・商工業者であり、かつ、日本政策金融公庫の融資対象業種を営んでい  
ること。  

以上が要件になります。｣  

Ｘ社社長：｢了解しました。当社は融資対象になりそうですね。申し込みを検討し  
てみます。｣  
        
        
（解答群）  
  
ア  Ａ：経営改善普及事業Ｂ：新たな雇用または雇用の維持が認められること  
  
イ  Ａ：経営改善普及事業Ｂ：地域資源を活用していること  
  
ウ  Ａ：経営発達支援計画Ｂ：新たな雇用または雇用の維持が認められること  
  
エ  Ａ：経営発達支援計画Ｂ：地域資源を活用していること`
        },
        {
                "mondaiId": 107280280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業者等の事業引継ぎや事業承継の促進・円滑化を図るため、｢事業引継ぎ  
支援センター｣による支援が行われている。  

この事業引継ぎ支援センターに関する記述として、最も適切なものはどれか。  
  
ア  事業引継ぎに関する専門家等が、助言、情報提供を行うほか、M＆A等を活  
用した後継者マッチング支援を行う。  
  
イ  すでにマッチング相手がいる場合、支援対象にはならない。  
  
ウ  全国のよろず支援拠点に設置されている。  
  
エ  中小企業の後継者が事業承継をした場合の相続税・贈与税などの特別措置につ  
いて、税理士が常駐し相談を受けている。  
        
`
        },
        {
                "mondaiId": 107280290,
                "name": "第29問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2016,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
新たな事業活動を行うことで経営の向上を図りたいと考える中小製造業のX社  
は、経営革新支援事業に関する支援を受けるため、経営革新計画を作成した。3年  
後の損益に関わる経営計画は下表のとおりである。この場合、経営革新計画の数値  
目標となる｢付加価値額｣として、最も適切なものを下記の解答群から選べ(単位：  
百万円)。  

<imgTag>107280290_1.png</imgTag>  


（解答群）  
  
ア  75  
  
イ  90  
  
ウ  100  
  
エ  110  
  
オ  150  
`
        },
        {
                "mondaiId": 101270010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財務省の資料によれば、わが国の一般会計歳入≫決算額?の構成は、税収、公債金  
収入、その他の収入という3つに区分されている。2013年度のわが国の歳入構成  
の説明として、最も適切なものはどれか。  
  
ア  公債金収入が全体の約8割を占めている。  
  
イ  公債金収入が全体の約2割5分を占めている。  
  
ウ  税収が全体の約7割を占めている。  
  
エ  税収と公債金収入はそれぞれ4割強で同程度を占めている。  
        
`
        },
        {
                "mondaiId": 101270020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本銀行｢資金循環統計｣より、各年度末における各主体の資産・負債差  
額≫資産から負債を差し引いた金額?をグラフにしたものである。ａ~ｃは、家計、  
一般政府、非金融法人企業のいずれかにあたる。家計と非金融法人企業にあたるも  
のの組み合わせとして、最も適切なものを下記の解答群から選べ。  

<imgTag>101270020_1.png</imgTag>  
出所： 資金循環統計 日本銀行  

[解答群]  
  
ア  家計：ａ非金融法人企業：ｂ  
  
イ  家計：ａ非金融法人企業：ｃ  
  
ウ  家計：ｂ非金融法人企業：ａ  
  
エ  家計：ｂ非金融法人企業：ｃ  
        
`
        },
        {
                "mondaiId": 101270030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　国民経済計算の概念として、最も適切なものはどれか。  
  
ア  国内純生産＝国内総生産<hintTarget>＋</hintTarget>固定資本減耗  

<hint hidden='true'>**
❌＋でなく－が適切です。
** </hint>

イ  国内総生産＝雇用者報酬＋営業余剰・混合所得＋生産・輸入品に課される税－補助金  

<hint hidden='true'>**
❌もし上記の式に、「＋<hintTarget>固定資本減耗</hintTarget>」があれば、GDPの支出面の定義として適切になります。
** </hint>

ウ  国内総生産＝民間最終消費支出＋政府最終消費支出＋総固定資本形成＋在庫品増加＋財貨・サービスの純輸出  

<hint hidden='true'>**
⭕適切です。  
** </hint>
  
エ  国民総所得＝<hintTarget>雇用者報酬</hintTarget>＋海外からの所得の純受取

<hint hidden='true'>**
❌雇用者報酬でなく、<hintTarget>国内総生産</hintTarget>が適切です。
** </hint>

`
        },
        {
                "mondaiId": 101270040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　ライフサイクルモデルの消費と貯蓄に関する記述として、最も適切なものはどれか。なお、生涯所得を一定とし、選択肢中にある貯蓄率とは所得に対する貯蓄の割合をさす。  

ア  個人の各期の消費は、個人の<hintTarget>年功所得</hintTarget>にあわせて変化する。  

<hint hidden='true'>**
❌ライフサイクルモデルでは、<hintTarget>生涯所得</hintTarget>に基づいて各期の消費を決めます。年功所得のように各期で変動する所得に基づきません。
** </hint>

イ  個人の各期の<hintTarget>貯蓄</hintTarget>は、生涯を通じて<hintTarget>一定</hintTarget>である。  

<hint hidden='true'>**
❌ライフサイクルモデルにおいて、貯蓄は一定ではありません。所得は年齢に応じて増減するが、消費が一定なので、貯蓄は増減すると考えます。
** </hint>
  
ウ  労働から引退した高齢者の、人口全体に占める割合が大きくなれば、<hintTarget>貯蓄率が低く</hintTarget>なる。  
  
<hint hidden='true'>**
⭕適切です。ライフサイクルモデルでは以下のように考えます。  
若年：所得低い、所得一定なので、貯蓄率低い  
中年：所得高い、所得一定なので、貯蓄率高い  
高齢：所得低い、所得一定なので、貯蓄率低い  
** </hint>

エ  労働しない若年者の、人口全体に占める割合が大きくなれば、貯蓄率が高くなる。  
<hint hidden='true'>**
❌上記選択肢ウの解説のとおりです。
** </hint>
        
`
        },
        {
                "mondaiId": 101270050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
2種類の財(A財とB財)を用いて、物価指数を計算する。これらの財の数量と  
単位当たりの価格は、基準年と比較年でそれぞれ以下の表のとおりであった。基準  
年の物価指数を100とした場合、比較年の物価指数として最も適切なものを下記の  
解答群から選べ。  

<imgTag>101270050_1.png</imgTag>  

[解答群]  
  
ア  ラスパイレス指数では99、パーシェ指数では100  
  
イ  ラスパイレス指数では99、パーシェ指数でも99  
  
ウ  ラスパイレス指数では100、パーシェ指数では99  
  
エ  ラスパイレス指数では100、パーシェ指数でも100  
        
`
        },
        {
                "mondaiId": 101270060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
拡張的な財政政策、たとえば政府支出の拡大は、下図のIS曲線をISからIS′へ  
とシフトさせる。ただし、YはGDP、rは利子率である。下図に関する説明とし  
て、最も適切なものの組み合わせを下記の解答群から選べ。  


<imgTag>101270060_1.png</imgTag>  

ａ　政府支出拡大の結果、利子率の上昇によって投資が減少するため、GDPはY1  
となる。  
ｂ　E2では、貨幣市場において、貨幣の超過供給が発生している。  
ｃ　｢r1－r0｣で表される利子率の上昇は、政府支出拡大による、貨幣の取引需要増  
加の結果生じた。  
ｄ　｢Y1－Y0｣が政府支出の拡大分に相当する。  


[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101270071,
                "name": "第7問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
総需要曲線(AD)と総供給曲線(AS)が下図のように描かれている。ただし、Pは  物価、Yは実質GDP、Yfは完全雇用GDPであり、Eが現在の均衡点である。  
下記の設問に答えよ。  


<imgTag>101270071_1.png</imgTag>  

（設問１）
  
総需要曲線の右シフト要因として、最も適切なものの組み合わせを下記の解答  
群から選べ。  

ａ中央銀行による買いオペレーションの実施  
ｂ政府支出の削減  
ｃ所得減税の実施  
ｄ民間銀行による融資縮小  

[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｄ  
  
エ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 101270072,
                "name": "第7問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
総需要曲線(AD)と総供給曲線(AS)が下図のように描かれている。ただし、Pは  物価、Yは実質GDP、Yfは完全雇用GDPであり、Eが現在の均衡点である。  
下記の設問に答えよ。  


<imgTag>101270071_1.png</imgTag>  


（設問２）  
総供給曲線に関する記述として、最も適切なものの組み合わせを下記の解答群  
から選べ。  

ａ　GDPが完全雇用水準を下回っても、つまり非自発的失業が存在しても、名  
目賃金率が硬直的であれば、総供給曲線の形状は右上がりになる。  

ｂ　GDPが完全雇用水準を下回っても、つまり非自発的失業が存在しても、実  
質賃金率が硬直的であれば、総供給曲線の形状は右上がりになる。  

ｃ　技術進歩が生じると、総供給曲線は下方にシフトする。  

ｄ　原油価格が高騰すると、総供給曲線は下方にシフトする。  

[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101270080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中央銀行は、名目貨幣量を拡大させる金融緩和政策を実施することがある。この  
名目貨幣量拡大により、総需要が増加することで、名目賃金率と物価が上昇し始め  
ると、企業側は総供給を増やそうとする。このときの労働者側の短期における行動  
について、自然失業率仮説の記述として最も適切なものはどれか。  
  
ア  物価上昇は認識せず、名目賃金率上昇のみを認識するため、労働供給量を増や  
す。  
  
イ  名目賃金率上昇と物価上昇をともに認識し、労働供給量を増やす。  
  
ウ  名目賃金率上昇と物価上昇をともに認識せず、労働供給量を変えない。  
  
エ  名目賃金率上昇は認識せず、物価上昇のみを認識するため、労働供給量を減ら  
す。`
        },
        {
                "mondaiId": 101270090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　日本銀行｢企業物価指数｣では円ベースの輸出入物価指数が公表されている。この統計を利用するためにも、ここで為替レートの変化と物価の動きとの関係を考えてみたい。  
　自国を日本、外国をアメリカとして、為替レートと輸出財・輸入財価格との関係に関する記述として、最も適切なものはどれか。  
  
<hint hidden='true'>**
購買力平価説に関する問題です。**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/101005.png" target="_blank" >
<img src="assets/img/kaisetu/101005.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>


ア  <hintTarget>円高</hintTarget>にあわせて、ある輸入財の円価格が<hintTarget>引き上げ</hintTarget>られれば、その輸入財のドル価格は<hintTarget>一定</hintTarget>に保たれている。  
  
<hint hidden='true'>**
❌
購買力平価説の公式から  
　国内の物価指数　＝　為替レート　×　海外の物価指数  
なので、選択肢の記述は、  
　国内の物価指数（引き上げ・上昇）　＝　為替レート（円高・低下）　×　海外の物価指数  
となり、この等式を満たすため、海外の物価指数は<u>上昇</u>します。
** </hint>

イ  <hintTarget>円高</hintTarget>にかかわらず、ある輸出財のドル価格を<hintTarget>一定</hintTarget>に保つためには、その輸出財の円価格を<hintTarget>引き上げ</hintTarget>なくてはならない。  
    
<hint hidden='true'>**
❌
購買力平価説の公式から、  
　国内の物価指数　＝　為替レート　×　海外の物価指数  
なので、選択肢の記述は、  
　国内の物価指数　＝　為替レート（<hintTarget>円高・低下</hintTarget>）　×　海外の物価指数（<hintTarget>一定</hintTarget>）  
となり、この等式を満たすため、国内の物価指数は<u>引き下げ</u>なくてはなりません。
** </hint>

ウ  <hintTarget>円安</hintTarget>にあわせて、ある輸入財のドル価格が<hintTarget>引き上げ</hintTarget>られれば、その輸入財の円価格は<hintTarget>一定</hintTarget>に保たれる。  
   
<hint hidden='true'>**
❌
購買力平価説の公式から  
　国内の物価指数　＝　為替レート　×　海外の物価指数  
なので、選択肢の記述は、  
　国内の物価指数　＝　為替レート（円安・上昇）　×　海外の物価指数（<hintTarget>引き上げ・上昇</hintTarget>）  
となり、この等式を満たすため、国内の物価指数は<u>上昇</u>します。
** </hint>

エ  <hintTarget>円安</hintTarget>にかかわらず、ある輸出財の円価格が<hintTarget>一定</hintTarget>に保たれれば、その輸出財の<hintTarget>ドル価格は低下</hintTarget>する。  

<hint hidden='true'>**
⭕
購買力平価説の公式から  
　国内の物価指数　＝　為替レート　×　海外の物価指数  
なので、選択肢の記述は、  
　国内の物価指数（<hintTarget>一定</hintTarget>）　＝　為替レート（<hintTarget>円安・上昇</hintTarget>）　×　海外の物価指数  
となり、この等式を満たすため、海外の物価指数は<u>低下</u>します。
**</hint>  

        
`
        },
        {
                "mondaiId": 101270100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
今日、経済政策の効果は、開放経済の枠組みで考える必要がある。  

下図は、開放経済におけるマクロ経済モデルを描いたものである。小国開放経  
済、不完全資本移動、変動相場制度、物価硬直性、期待外国為替相場一定を仮定す  
る。図中のBP曲線は、国際収支を均衡させる、GDPと利子率との組み合わせを  
表したものである。  

貨幣量の拡大に伴う効果に関する記述として、最も適切なものを下記の解答群か  
ら選べ。  


<imgTag>101270100_1.png</imgTag>  

[解答群]  
  
ア  貨幣量の拡大はLM曲線を下方にシフトさせ、純輸出を増加させるものの、  
民間投資支出の減少を通じてGDPを減少させる。  
  
イ  貨幣量の拡大はLM曲線を下方にシフトさせ、GDPを増加させるものの、  
クラウディングアウトを発生させる。  
  
ウ  貨幣量の拡大は自国金利が相対的に低下することで内外金利差を生み出し、  
自国通貨を減価させる。  
  
エ  貨幣量の拡大は自国通貨を増価させ、純輸出を減少させる。  
        
`
        },
        {
                "mondaiId": 101270110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
昨今、外国人労働者の受け入れの是非が議論されている。  

2種類の生産要素、資本Kと労働Nを用いて、生産Yが行われる。資本と労  
働、そして生産との関係を、労働1単位あたりの資本と労働1単位あたりの生産と  
の対応関係である、次の生産関数で表す。  

y＝f(k)

ここでk=K／Nは資本・労働比率を、yは労働1単位あたりの生産量を表して  
いる。また、労働供給は一定率nで増加し、常に完全雇用が実現しているとする。  
また人々は、所得の一定割合sを常に貯蓄するとする。  

新古典派の経済成長モデルの下図を参照した上で、外国人労働者の継続的な受け  
入れによる労働成長率の上昇が、定常状態における資本・労働比率と労働1単位あ  
たり生産量に与える影響に関する記述として、最も適切なものを下記の解答群から  
選べ。ただしk1は、定常状態の資本・労働比率を表している。  



<imgTag>101270110_1.png</imgTag>  


[解答群]  
  
ア  資本・労働比率は上昇し、労働1単位あたり生産量は減少する。  
  
イ  資本・労働比率は上昇し、労働1単位あたり生産量は増加する。  
  
ウ  資本・労働比率は低下し、労働1単位あたり生産量は減少する。  
  
エ  資本・労働比率は低下し、労働1単位あたり生産量は増加する。  
        
`
        },
        {
                "mondaiId": 101270120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
常に一定の固定比率で一緒に消費されるような財を完全補完財という。完全補完  
財であるような2財の無差別曲線を示す図として、最も適切なものを下記の解答群  
から選べ。  


<imgTag>101270120_1.png</imgTag>  

[解答群]  
  
ア  図Ａ  
  
イ  図Ｂ  
  
ウ  図Ｃ  
  
エ  図Ｄ  
        
`
        },
        {
                "mondaiId": 101270130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年では、企業の業績が上向いてきたことなどもあり賃金が上昇傾向にあるが、  
賃金上昇が労働者に与える影響を経済モデルで考えてみたい。  

いま、ある消費財の消費量をC、その価格を1とする。個人は、賃金率wでL  
という時間だけ労働してwLという所得を稼ぎ、当該の消費財を消費することがで  
きる。1日24時間のうち労働以外の時間を余暇Rとすると、労働時間は  
L暗24安Rと表すことができる。こうした仮定のもとにある個人は、  
C=w(24－R)という制約の中で、CとRを組み合わせることになる。ただし、  
労働に投じることができる時間は、最大で12時間(L≦12)であるものとする。下  
図は、上記の仮定を踏まえて、賃金率wの場合と賃金率w′の場合(w＜w′)とに分  
けて、個人が直面する制約が右下がりの直線として描かれている。また、この制約  
線と無差別曲線Ui(i=1，2)との接点として、それぞれの場合における最適なC  
とRの組み合わせが与えられている。この図で、賃金率がwからw′へ上昇したも  
のと想定した場合の記述として、最も適切なものを下記の解答群から選べ。  


<imgTag>101270130_1.png</imgTag>  
        
        
[解答群]  
  
ア  この図では、賃金率の上昇に伴って生じる所得効果と代替効果を合計した効  
果(全効果)は、余暇時間を減少させる。  
  
イ  この図では、賃金率の上昇に伴って生じる所得効果と代替効果を合計した効  
果(全効果)は、労働時間を減少させる。  
  
ウ  この図では、賃金率の上昇に伴って生じる所得効果は、労働時間を増加させ  
る。  
  
エ  この図では、賃金率の上昇に伴って生じる代替効果は、余暇時間を増加させ  
る。  
        
`
        },
        {
                "mondaiId": 101270140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、1つの財、財X1と財X2を消費可能な個人の効用最大化行動を考える。  

当該の個人は、所得80を有し、財X1の価格は2、財X2の価格は4という条件の  
もとで、効用が最大になるよう財X1の消費量x1と財X2の消費量x2とを組み合わ  
せることができる。この個人の効用関数はU=x1x2と与えられており、合理的な  
当該個人は、x1=20、x2=10という組み合わせを選択することが分かっている。  

下図では、縦軸の切片aと横軸の切片bとを結ぶ予算制約線と無差別曲線Uの接  
点として、効用最大化の行動が図示されている。  

この状況を説明する記述として、最も適切なものを下記の解答群から選べ。  

<imgTag>101270140_1.png</imgTag>  

[解答群]  
  
ア  この個人は、所得80の使い道として、x1=20、x2=10以外の組み合わせ  
を選択することで効用を一層高める余地が残されている。  
  
イ  財X2の消費量がゼロならば、財X1を30消費することで所得80を使い切  
ることができる。  
  
ウ  縦軸の切片aの値は、財X1の価格に応じて変化する。  
  
エ  無差別曲線U上の2財の組み合わせ(x1，x2)では、いずれも効用水準が200  
で一定である。  
        
`
        },
        {
                "mondaiId": 101270150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、固定費用Fと可変費用で構成される総費用曲線が描かれている。ま  
た、原点から始まり総費用曲線と点Kで接する補助線Aと、固定費用Fから始ま  
り総費用曲線と点Mで接する補助線Bが描かれている。この図に関する説明とし  
て、最も適切なものを下記の解答群から選べ。  

<imgTag>101270150_1.png</imgTag>  


[解答群]  
  
ア  生産量Q2は、平均費用が最小となる生産量である。  
  
イ  平均可変費用と限界費用が一致する点は操業停止点といわれ、図中で点K  
がこれに該当する。  
  
ウ  平均費用と限界費用が一致する点は損益分岐点といわれ、図中で点Mがこ  
れに該当する。  
  
エ  平均費用と平均可変費用は、生産量Q1で一致する。  
        
`
        },
        {
                "mondaiId": 101270160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、

<imgTag>101270160_1.png</imgTag>  

という生産関数を考える。ただし、Yは生産量、Kは生産  
における資本投入量、Nは生産における労働投入量である。このときの記述とし  
て、最も適切なものの組み合わせを下記の解答群から選べ。  

ａこの生産関数において、投入される資本の限界生産力は一定である。  

ｂこの生産関数において、投入される資本の限界生産力は逓減している。  

ｃこの生産関数では、ある状態から資本投入量と労働投入量をともに2倍にする  
と、生産量も2倍になる。  

ｄこの生産関数では、ある状態から資本投入量と労働投入量をともに2倍にして  
も、生産量は2倍には及ばない。  

[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101270170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、下図において、ある財の平均費用曲線と限界費用曲線、および当該財の価  
格が描かれており、価格と限界費用曲線の交点dによって利潤を最大化する生産  
量qが与えられている。この図に関する説明として、最も適切なものを以下の解答  
群から選べ。  


<imgTag>101270170_1.png</imgTag>  



[解答群]  
  
ア  利潤が最大となる生産量のとき、四角形adqoによって平均可変費用の大き  
さが示される。  
  
イ  利潤が最大となる生産量のとき、四角形adqoによって利潤の大きさが示さ  
れる。  
  
ウ  利潤が最大となる生産量のとき、四角形bcqoによって収入の大きさが示さ  
れる。  
  
エ  利潤が最大となる生産量のとき、四角形bcqoによって総費用の大きさが示  
される。  
        
`
        },
        {
                "mondaiId": 101270181,
                "name": "第18問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
財務省によれば、わが国の2013年度末の公債残高は、GDPの2倍程度であり、  
財政再建の必要性が指摘されている。財政再建のためには、行政の効率化による支  
出削減と増税による収入増とを適切に組み合わせることが必要になろう。こうした  
状況を踏まえて、以下では税に関する経済モデルを考えている。下記の設問に答えよ。  

（設問1）  
いま、価格に反応しない垂直な需要曲線と一定の傾きを持つ供給曲線が、それ  
ぞれ実線の直線で下図に描かれている。このとき、政府が従量税を課すと、図中  
の点線の直線で示されているような形で課税後の供給曲線が描かれるものとす  
る。この図に関する説明として、最も適切なものを下記の解答群から選べ。  

なお、以下では｢税の大きさ｣とは財1単位あたりの税を意味する。  


<imgTag>101270181_1.png</imgTag>  


[解答群]  
  
ア  税収と税の大きさには図Ａのような関係があり、課税による死重損失は発  
生しない。  
  
イ  税収と税の大きさには図Ｂのような関係があり、課税によって死重損失が  
発生する。  
  
ウ  税収と税の大きさには図Ｃのような関係があり、課税によって死重損失が  
発生する。  
  
エ  税収と税の大きさには図Ｄのような関係があり、課税によって死重損失が  
発生する。  
  
オ  税収と税の大きさには図Ｄのような関係があり、課税による死重損失は発  
生しない。  
        
`
        },
        {
                "mondaiId": 101270182,
                "name": "第18問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
財務省によれば、わが国の2013年度末の公債残高は、GDPの2倍程度であり、  
財政再建の必要性が指摘されている。財政再建のためには、行政の効率化による支  
出削減と増税による収入増とを適切に組み合わせることが必要になろう。こうした  
状況を踏まえて、以下では税に関する経済モデルを考えている。下記の設問に答えよ。  

（設問2）  
一般に、生活必需品Aに対する家計の需要曲線は、価格に対して非弾力的で  
あり、下記の左図のように描くことができる。他方で、贅沢な嗜好品Bに対す  
る家計の需要曲線は、価格に対して弾力的であり、下記の右図のように描くこと  
ができる。企業による供給曲線は、これらAとBの2財において価格弾力性が  
十分に大きく(無限大)、水平な直線として描くことができるとする。このとき、  
政府によって企業へ従量税が課される場合の説明として、最も適切なものを下記  
の解答群から選べ。  


<imgTag>101270182_1.png</imgTag>  

[解答群]  
  
ア  嗜好品Bへの課税では、企業へ帰着する税の負担は、家計へ帰着する税  
の負担より大きい。  
  
イ  嗜好品Bへの課税では、企業へのみ税の負担が帰着する。  
  
ウ  生活必需品Aへの課税では、家計へのみ税の負担が帰着する。  
  
エ  生活必需品Aへの課税に比べて、嗜好品Bへの課税は、死重損失が小さ  
くなりやすい。  
        
`
        },
        {
                "mondaiId": 101270190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業は、供給する財の価格を決定するとき、消費者の価格弾力性を考慮に入れる  
ことがある。  

いま、ある企業が2つの市場Aと市場Bにおいて同一の財を独占的に供給して  
いる。当該企業は、2つの市場において同一かつ一定の限界費用でこの財を生産し  
ているが、2つの市場で異なる価格を設定することができる。ただし、各地域内で  
は、消費者ごとに価格を差別することはできないものとする。  

この財への需要は、市場Aと市場Bでは異なり、市場Aでの需要の価格弾力性  
は、市場Bでの需要の価格弾力性よりも相対的に低い(相対的に非弾力的である)。

このときの記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ合理的な独占企業は、限界収入と限界費用が一致する生産量を選択する。  

ｂ合理的な独占企業は、需要曲線が示す価格と限界費用が一致する生産量を選択  
する。  

ｃ需要の価格弾力性が高い市場Bの価格は、市場Aの価格よりも高くなる。  

ｄ需要の価格弾力性が低い市場Aの価格は、市場Bの価格よりも高くなる。  

[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101270200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、ある事業を独占的に提供している既存の企業Aがある。この事業には、  
新規参入を希望する企業Bも存在している。企業Aは、この事業で｢高価格｣戦略  
か｢低価格｣戦略を採用することができ、企業Bは、｢参入｣ないし｢参入せず｣を選  
択することができる。以下の樹形図は、このようなゲームの様子を整理したもので  
あり、カッコ内の値は、左が企業A、右が企業Bの利得を表している。なお、政  
府は、この事業へ参入規制を設けて、新規企業Bが｢参入｣を選択できないように  
計らうこともできる。  

このときの記述として、最も適切なものの組み合わせを下記の解答群から選べ。  

<imgTag>101270200_1.png</imgTag>  


ａ政府が参入規制を設けていない場合、企業Aが｢高価格｣を設定し、企業Bが  
｢参入せず｣を選択するのはナッシュ均衡である。  

ｂ政府が参入規制を設けていない場合、企業Aが｢低価格｣を設定し、企業Bが  
｢参入せず｣を選択するのはナッシュ均衡である。  

ｃバックワード・インダクションの解では、政府が参入規制を設けていない場  
合、企業Bは｢参入せず｣を選択する。  

ｄバックワード・インダクションの解では、政府が参入規制を設けている場合、  
企業Aは｢低価格｣を選択する。  
        
        
[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 101270210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
TPP協定では、関税引き下げが交渉されている。  

いま、ある農産物の輸入には禁止的高関税が従量税で課されており、輸入が起こ  
らない状況であるとする。そこに、当該農産物の輸出国との間で貿易交渉が行わ  
れ、関税が大幅に引き下げられた結果、この農産物は図中のP1の価格で輸入され  
ることとなった。関税が引き下げられた場合の余剰と輸入量に関する記述として、  
最も適切なものを下記の解答群から選べ。  


<imgTag>101270210_1.png</imgTag>  

[解答群]  
  
ア  生産者余剰は減少し、消費者余剰は増加するものの、両者の余剰合計は変わ  
らない。また、輸入量はQ3となる。  
  
イ  生産者余剰は減少し、消費者余剰は増加するが、両者の余剰合計は増加す  
る。また、輸入量は｢Q3安Q1｣となる。  
  
ウ  生産者余剰は増加し、消費者余剰は減少するが、両者の余剰合計は減少す  
る。また、輸入量はQ1となる。  
  
エ  生産者余剰は増加し、消費者余剰は減少するものの、両者の余剰合計は変わ  
らない。また、輸入量は｢Q3安Q2｣となる。  
        
`
        },
        {
                "mondaiId": 101270220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、中小企業庁が公表している｢2005年規模別産業連関表｣の一部を抜き出  
したものである。  
｢一般機械≫小?部門が電力・ガス・水道部門から購入した中間投入財の金額｣≫以  
下、｢中間投入財｣という。?と｢一般機械≫小?部門が生み出した付加価値額｣≫以下、  
｢付加価値｣という。?の組み合わせとして、最も適切なものを下記の解答群から選  
べ。  

(単位：10億円)  
<imgTag>101270220_1.png</imgTag>  
注：(小)は中小企業をさす。  
出所：2005年規模別産業連関表(中小企業庁)  

[解答群]  
  
ア  中間投入財：230億円付加価値：6兆660億円  
  
イ  中間投入財：230億円付加価値：11兆7，120億円  
  
ウ  中間投入財：1，670億円付加価値：6兆660億円  
  
エ  中間投入財：1，670億円付加価値：11兆7，120億円  
        
`
        },
        {
                "mondaiId": 101270230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、公正取引委員会｢生産・出荷集中度調査｣より、平成23年度と平成24年  
度における、液晶パネル製造業とインターネット広告代理業における累積生産集中  
度の値を抜き出したものである。下表の解釈として、最も適切なものを下記の解答  
群から選べ。  


<imgTag>101270230_1.png</imgTag>  
出所：生産・出荷集中度調査(公正取引委員会)  

[解答群]  
  
ア  平成23年度のインターネット広告代理業では、上位10社の企業で市場を占  
拠している。  
  
イ  平成24年度のインターネット広告代理業では、平成23年度と比較して、上  
位5社の企業によって占拠される割合が小さくなった。  
  
ウ  平成24年度の液晶パネル製造業では、10社を超える企業の競争状態にあ  
る。  
  
エ  平成24年度の液晶パネル製造業では、平成23年度と比較して、上位3社の  
企業によって占拠される割合が大きくなった。  
`
        },
        {
                "mondaiId": 102270010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の資料に基づいて、今期の売上原価として最も適切なものを下記の解答群から選べ。  

【資料】  
期首商品棚卸高　120，000円  
当期商品品純仕入高　650，000円  
期末帳簿棚卸数量　1，300個　(<hintTarget>原価＠100円</hintTarget>)  
期末<hintTarget>実地</hintTarget>棚卸数量　1，000個  

<hintTarget>棚卸減耗損は売上原価</hintTarget>とする。  

<hint hidden='true'>**
棚卸減耗損は、盗難・紛失などで、実際の数量が帳簿上の数量を下回る場合に、帳簿価額を切り下げるために使用します。  
そのため、期末商品棚卸高には、【資料】中の<hintTarget>期末実地棚卸数量</hintTarget>を使用します。  
**
<imgTag>102270010_1.png</imgTag>  
</hint>  

[解答群]  
  
ア  610，000円  
  
イ  640，000円  
  
ウ  670，000円<hint hidden='true'>**
←⭕
**</hint>    
  
エ  700，000円`
        },
        {
                "mondaiId": 102270020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
本支店会計において本店集中計算制度を採用している場合、A支店からB支店  
へ現金200，000円を送付したときのB支店の仕訳として、最も適切なものはどれ  
か。  
  
ア  (借)B支店200，000?　(貸)A支店200，000  
  
イ  (借)現金200，000?　(貸)A支店200，000  
  
ウ  (借)現金200，000　(貸)B支店200，000  
  
エ  (借)現金2　(貸)00，000　(貸)本店200，000  
        
`
        },
        {
                "mondaiId": 102270030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
決算日現在の当店の当座預金勘定の残高は339，000円であったが、銀行から受け  
取った残高証明書と一致しなかったので、原因を調査したところ、次の(1)~(3)の事  
実が判明した。  

(1)福島商店に仕入代金として振り出した小切手50，000円が、決算日現在では銀  
行に未呈示であった。  

(2)得意先宮城商店から売掛金の支払いとして当座預金口座に71，000円が振り込  
まれていたが、決算日までに通知が届いていなかった。  

(3)販売手数料34，000円の入金を43，000円と誤って記帳していた。  


このとき、当座預金の残高として最も適切なものはどれか。  
  
ア  351，000円  
  
イ  401，000円  
  
ウ  409，000円  
  
エ  419，000円  
        
`
        },
        {
                "mondaiId": 102270040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株主総会の決議により、その他資本剰余金を取り崩して600，000円配当すること  
にした。なお、資本金は4，000，000円、準備金の合計は950，000円である。このと  
き積み立てるべき準備金の種類と金額の組み合わせとして、最も適切なものを下記  
の解答群から選べ。  

【準備金の種類】  
ａ資本準備金  
ｂ利益準備金  

【金額】  
ｃ50，000円  
ｄ60，000円  

[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 102270050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の仕訳の取引として最も適切なものを下記の解答群から選べ。  

(借)仕入500，000?　(貸)支払手形500，000  


[解答群]  
  
ア  現金500，000円を約束手形を振り出して借り入れ、この現金で商品500，000  
円を仕入れた。  
  
イ  商品500，000円を仕入れ、為替手形を振り出し、得意先の引き受けを得て仕  
入先に渡した。  
  
ウ  商品500，000円を仕入れ、代金として自己宛為替手形を振り出した。  
  
エ  商品500，000円を仕入れ、代金は掛けとした。`
        },
        {
                "mondaiId": 102270060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
原価計算に関する記述として最も適切なものはどれか。  
  
ア  原価計算における総原価とは、製造原価を意味する。  
  
イ  原価計算は、財務諸表を作成する目的のためだけに行う。  
  
ウ  原価計算は、製造業にのみ必要とされる計算手続きである。  
  
エ  材料費・労務費・経費の分類は、財務会計における費用の発生を基礎とする分  
類である。  
        
`
        },
        {
                "mondaiId": 102270070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料は、工場の20X1年8月分のデータである。このとき、製造指図書  
＃123の製造原価として最も適切なものを下記の解答群から選べ。なお、すべて当  
月に製造を開始した。  


【資料】  
(1)製造直接費  

<imgTag>102270070_1.png</imgTag>  

(2)製造間接費  
実際発生額：90，000円  

(3)製造間接費は直接作業時間を配賦基準として各製品に配賦する。  

[解答群]  
  
ア  212，500円  
  
イ  220，300円  
  
ウ  253，000円  
  
エ  262，500円  
        
`
        },
        {
                "mondaiId": 102270080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
販売予算が以下のとおり編成されていたとする。いま、第2四半期(Q2)の実際  
販売量が1，100個、販売価格が99，000円であったとする。数量差異と価格差異の  
組み合わせとして、最も適切なものを下記の解答群から選べ。  

<imgTag>102270080_1.png</imgTag>  


[解答群]  
  
ア  数量差異900万円(不利差異)と価格差異210万円(不利差異)  
  
イ  数量差異1，000万円(不利差異)と価格差異110万円(不利差異)  
  
ウ  数量差異1，100万円(不利差異)と価格差異10万円(不利差異)  
  
エ  数量差異1，200万円(不利差異)と価格差異90万円(有利差異)
`
        },
        {
                "mondaiId": 102270090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
キャッシュフローの減少額として最も適切なものはどれか。  
  
ア  減価償却費  
  
イ  仕入債務の増加  
  
ウ  棚卸資産の増加  
  
エ  長期借入金の増加  
        
`
        },
        {
                "mondaiId": 102270101,
                "name": "第10問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
前期と今期の損益計算書は次のように要約される。下記の設問に答えよ。  

損益計算書(単位：千円)  

<imgTag>102270101_1.png</imgTag>  


（設問1）  
今期の損益分岐点売上高として最も適切なものはどれか。  
  
ア  12，000千円  
  
イ  16，400千円  
  
ウ  18，000千円  
  
エ  20，000千円`
        },
        {
                "mondaiId": 102270102,
                "name": "第10問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `                
前期と今期の損益計算書は次のように要約される。下記の設問に答えよ。  

損益計算書(単位：千円)  

<imgTag>102270101_1.png</imgTag>  

（設問2）  
収益性に関する記述として最も適切なものはどれか。  
  
ア  損益分岐点比率が前期よりも悪化したのは、固定費の増加による。  
  
イ  損益分岐点比率が前期よりも悪化したのは、変動費率の低下による。  
  
ウ  損益分岐点比率が前期よりも改善されたのは、固定費の増加による。  
  
エ  損益分岐点比率が前期よりも改善されたのは、変動費率の低下による。  
        
`
        },
        {
                "mondaiId": 102270111,
                "name": "第11問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の貸借対照表と損益計算書について、下記の設問に答えよ。  

貸借対照表(平成X5年度) (単位：千円)  
<imgTag>102270111_1.png</imgTag>  


損益計算書(平成X5年度)(単位：千円)  
<imgTag>102270111_2.png</imgTag>  
        
        
（設問1）  
総資産回転率として最も適切なものはどれか。  
  
ア  0.68回  
  
イ  0.87回  
  
ウ  1.25回  
  
エ  1.47回`
        },
        {
                "mondaiId": 102270112,
                "name": "第11問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の貸借対照表と損益計算書について、下記の設問に答えよ。  

貸借対照表(平成X5年度) (単位：千円)  
<imgTag>102270111_1.png</imgTag>  


損益計算書(平成X5年度)(単位：千円)  
<imgTag>102270111_2.png</imgTag>  

（設問2）
インタレスト・カバレッジ・レシオとして最も適切なものはどれか。  
  
ア  13.3％  
  
イ  20.2％  
  
ウ  13.3倍  
  
エ  20.2倍`
        },
        {
                "mondaiId": 102270120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A社は、株主に対する利益還元政策を行うこととした。利益還元政策として、  
<u>最も不適切なものはどれか</u>。なお、A社は十分な現金を所有しており、財務的破  
綻について考慮する必要はない。  
  
ア  株式の分割  
  
イ  記念配当の実施  
  
ウ  自己株式の取得  
  
エ  普通配当の増配  
        
`
        },
        {
                "mondaiId": 102270131,
                "name": "第13問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
MM理論に基づく最適資本構成に関する以下の記述について、下記の設問に答  
えよ。  

MM理論の主張によると、完全な資本市場の下では、企業の資本構成は企業価  
値に影響を与えない。しかし、現実の資本市場は完全な資本市場ではない。そこ  
で、完全な資本市場の条件のうち、法人税が存在しないという仮定を緩め、法人税  
の存在を許容すると、負債の増加はＡを通じて企業価値をＢこと  
になる。この条件下では、負債比率がＣの場合において企業価値が最大と  
なる。  

一方で、負債比率が高まると、Ｄも高まることから、債権者も株主も  
Ｅリターンを求めるようになる。結果として、ＡとＤの  
Ｆを考慮して最適資本構成を検討する必要がある。  

（設問1）  
記述中の空欄Ａ~Ｃにあてはまる語句の組み合わせとして最も適切なものはど  
れか。  
  
ア  Ａ：支払利息の増加による株主価値の低下Ｂ：高めるＣ：0％  
  
イ  Ａ：支払利息の増加による株主価値の低下Ｂ：低めるＣ：100％  
  
ウ  Ａ：節税効果Ｂ：高めるＣ：100％  
  
エ  Ａ：節税効果Ｂ：低めるＣ：0％  
        
`
        },
        {
                "mondaiId": 102270132,
                "name": "第13問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
MM理論に基づく最適資本構成に関する以下の記述について、下記の設問に答  
えよ。  

MM理論の主張によると、完全な資本市場の下では、企業の資本構成は企業価  
値に影響を与えない。しかし、現実の資本市場は完全な資本市場ではない。そこ  
で、完全な資本市場の条件のうち、法人税が存在しないという仮定を緩め、法人税  
の存在を許容すると、負債の増加はＡを通じて企業価値をＢこと  
になる。この条件下では、負債比率がＣの場合において企業価値が最大と  
なる。  

一方で、負債比率が高まると、Ｄも高まることから、債権者も株主も  
Ｅリターンを求めるようになる。結果として、ＡとＤの  
Ｆを考慮して最適資本構成を検討する必要がある。  

（設問2）  
記述中の空欄Ｄ~Ｆにあてはまる語句の組み合わせとして最も適切なものはど  
れか。  
  
ア  Ｄ：債務不履行?デフォルトNリスクＥ：より高いＦ：トレードオフ  
  
イ  Ｄ：債務不履行?デフォルトNリスクＥ：より低いＦ：相乗効果  
  
ウ  Ｄ：財務レバレッジＥ：より高いＦ：相乗効果  
  
エ  Ｄ：財務レバレッジＥ：より低いＦ：トレードオフ`
        },
        {
                "mondaiId": 102270140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のB社の資料に基づいて加重平均資本コストを計算した値として、最も適  
切なものを下記の解答群から選べ。なお、B社は常に十分な利益を上げている。  

株主資本(自己資本)コスト10％  
他人資本コスト5％  
限界税率40％  
負債の簿価600百万円  
負債の時価600百万円  
株主資本の簿価1，000百万円  
株主資本の時価1，400百万円  

[解答群]  
  
ア  7％  
  
イ  7.375％  
  
ウ  7.6％  
  
エ  7.9％  
        
`
        },
        {
                "mondaiId": 102270151,
                "name": "第15問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
C社は、取引先に対して貸付けを行っている。当該貸付金は、以下のようなキャ  
ッシュフローをもたらす予定である。現在価値の計算について、下記の設問に答え  
よ。なお、現行の会計基準との整合性を考慮する必要はない。  

①元本は100万円、貸付日は20X1年4月1日、貸付期間は4年である。  

②利息として、20X2年から20X5年までの毎年3月31日に6万円が支払われ  
る。  

③満期日の20X5年3月31日には元本の100万円が返済される。  


（設問1）  
この貸付金の、貸付日時点の現在価値として最も適切なものはどれか。なお、  
割引率は6％とする。  
  
ア  792，000円  
  
イ  982，200円  
  
ウ  1，000，000円  
  
エ  1，240，000円  
        
`
        },
        {
                "mondaiId": 102270152,
                "name": "第15問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `                
C社は、取引先に対して貸付けを行っている。当該貸付金は、以下のようなキャ  
ッシュフローをもたらす予定である。現在価値の計算について、下記の設問に答え  
よ。なお、現行の会計基準との整合性を考慮する必要はない。  

①元本は100万円、貸付日は20X1年4月1日、貸付期間は4年である。  

②利息として、20X2年から20X5年までの毎年3月31日に6万円が支払われ  
る。  

③満期日の20X5年3月31日には元本の100万円が返済される。  

（設問2）  
貸付けを行っている取引先の財政状態が悪化し、元本の一部が回収不能となる  
可能性が高まっていることが確認された。このとき、現在価値の計算は設問1  
と比べてどのように変化するか、最も適切なものを選べ。  
  
ア  割引率が高くなるため、現在価値は大きくなる。  
  
イ  割引率が高くなるため、現在価値は小さくなる。  
  
ウ  割引率が低くなるため、現在価値は大きくなる。  
  
エ  割引率が低くなるため、現在価値は小さくなる。  
        
`
        },
        {
                "mondaiId": 102270161,
                "name": "第16問設問1",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

D社は、4つの投資案(①~④)の採否について検討している。同社では、投資  
案の採否を正味現在価値法(NPV法)に基づいて判断している。いずれの投資案も、  
経済命数は3年である。  

4つの投資案の初期投資額および第1期末から第3期末に生じるキャッシュフロ  
ーは、以下の表のとおり予測されている。初期投資は第1期首に行われる。なお、  
法人税は存在せず、割引率は8％とする。  

(単位：百万円)  

<imgTag>102270161_1.png</imgTag>  
        
        
（設問1）  
投資案②のNPV(空欄A)および投資案③のNPV(空欄B)にあてはまる金額の  
組み合わせとして、最も適切なものを下記の解答群から選べ。なお、NPVの計  
算にあたっては、以下の表を用いること。  


割引率8％の場合の複利現価係数および年金現価係数  

　　　　　　　１年　２年　３年  
複利現価係数　0.93　0.86　0.79  
年金現価係数　0.93　1.78　2.58  


[解答群]  
  
ア  Ａ：22百万円Ｂ：30百万円  
  
イ  Ａ：33百万円Ｂ：30百万円  
  
ウ  Ａ：33百万円Ｂ：46百万円  
  
エ  Ａ：36百万円Ｂ：30百万円  
  
オ  Ａ：36百万円Ｂ：46百万円`
        },
        {
                "mondaiId": 102270162,
                "name": "第16問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

D社は、4つの投資案(①~④)の採否について検討している。同社では、投資  
案の採否を正味現在価値法(NPV法)に基づいて判断している。いずれの投資案も、  
経済命数は3年である。  

4つの投資案の初期投資額および第1期末から第3期末に生じるキャッシュフロ  
ーは、以下の表のとおり予測されている。初期投資は第1期首に行われる。なお、  
法人税は存在せず、割引率は8％とする。  

(単位：百万円)  

<imgTag>102270161_1.png</imgTag>  
        
（設問2）  
4つの投資案は相互に独立しており、D社は複数の投資案を採択することが  
可能である。しかし、資金の制約があり、初期投資額の上限は380百万円であ  
る。このとき、採択すべき投資案の組み合わせとして最も適切なものはどれか。  

なお、D社は他の投資案を有しておらず、380百万円のうち初期投資に使用さ  
れなかった残額から追加のキャッシュフローは生じない。  
  
ア  投資案①、投資案②、および投資案③  
  
イ  投資案①、投資案②、および投資案④  
  
ウ  投資案②および投資案③  
  
エ  投資案②および投資案④  
  
オ  投資案③および投資案④  
        
`
        },
        {
                "mondaiId": 102270171,
                "name": "第17問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

E社は、2つのプロジェクト(プロジェクトAおよびプロジェクトB)の採否に  
ついて検討している。両プロジェクトの収益率は、今夏の気候にのみ依存すること  
が分かっており、気候ごとの予想収益率は以下の表のとおりである。なお、この予  
想収益率は投資額にかかわらず一定である。また、E社は、今夏の気候について、  
猛暑になる確率が40％、例年並みである確率が40％、冷夏になる確率が20％と  
予想している。  


<imgTag>102270171_1.png</imgTag>  

（設問1）  
プロジェクトAに全額投資したと仮定する。当該プロジェクトから得られる  
予想収益率の期待値および標準偏差の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  期待値：1％標準偏差：3.4％  
  
イ  期待値：1％標準偏差：11.8％  
  
ウ  期待値：2％標準偏差：3.3％  
  
エ  期待値：2％標準偏差：10.8％  
        
`
        },
        {
                "mondaiId": 102270172,
                "name": "第17問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `       
次の文章を読んで、下記の設問に答えよ。  

E社は、2つのプロジェクト(プロジェクトAおよびプロジェクトB)の採否に  
ついて検討している。両プロジェクトの収益率は、今夏の気候にのみ依存すること  
が分かっており、気候ごとの予想収益率は以下の表のとおりである。なお、この予  
想収益率は投資額にかかわらず一定である。また、E社は、今夏の気候について、  
猛暑になる確率が40％、例年並みである確率が40％、冷夏になる確率が20％と  
予想している。  


<imgTag>102270171_1.png</imgTag>  

（設問2）  
2つのプロジェクトに関する記述として最も適切なものはどれか。  
  
ア  2つのプロジェクトに半額ずつ投資することで、どちらかのプロジェクトに  
全額投資した場合よりもリスクが低減される。  
  
イ  2つのプロジェクトの予想収益率の相関係数は0以上1未満となる。  
  
ウ  プロジェクトAのリスクのほうがプロジェクトBのリスクよりも大きい。  
  
エ  プロジェクトBの期待収益率は負である。`
        },
        {
                "mondaiId": 102270180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
資本資産評価モデル(CAPM)に関する記述として最も適切なものはどれか。  
  
ア  βが0以上1未満である証券の期待収益率は、無リスク資産の利子率よりも低  
い。  
  
イ  βがゼロである証券の期待収益率はゼロである。  
  
ウ  均衡状態においては、すべての投資家が、危険資産として市場ポートフォリオ  
を所有する。  
  
エ  市場ポートフォリオの期待収益率は、市場リスクプレミアムと呼ばれる。  
        
`
        },
        {
                "mondaiId": 102270190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ポートフォリオ理論におけるリスクに関する記述として最も適切なものはどれ  
か。  
  
ア  安全資産とは、リスクがなく、期待収益率がゼロである資産のことである。  
  
イ  収益率が完全な正の相関を有する2つの株式へ分散投資しても、リスク分散効  
果は得られない。  
  
ウ  同一企業の社債と株式への投資を比較すると、リスクが高いのは社債への投資  
である。  
  
エ  分散投資によって、リスクをゼロにすることができる。  
`
        },
        {
                "mondaiId": 103270010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　プロダクト・ポートフォリオ・マネジメント（PPM）に関する記述として、最も適切なものはどれか。  
  
ア  競争優位性を期待できない｢負け犬｣事業からの撤退の検討に加え、資金投入によって成長市場で競争優位の実現を期待できる｢問題児｣の選択が重要である。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

イ  競争優位性を期待できない｢負け犬｣事業からの撤退を進めるのに重要な資金供給源は｢<hintTarget>花形商品</hintTarget>｣の事業である。  

<hint hidden='true'>**
❌金のなる木に関する記述です。
**</hint>

ウ  衰退期に入った業界の｢<hintTarget>花形商品</hintTarget>｣事業は、徐々に撤退してできるだけ多くのキャッシュを生み出させることが重要である。  

<hint hidden='true'>**
❌金のなる木に関する記述です。
**</hint>

エ  プロダクト・ポートフォリオ・マネジメントの考え方では、資金の流入と流出は<hintTarget>市場と自社事業との成長率</hintTarget>で決まる。  

<hint hidden='true'>**
❌下図のとおり、<hintTarget>市場の占有率と、市場の成長率</hintTarget>で決まります。  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/103003_1.png" target="_blank" >
<img src="assets/img/kaisetu/103003_1.png" alt="ネットワーク接続を確認ください"></a>
</div>
**</hint>

オ  プロダクト・ポートフォリオ・マネジメントの考え方は、外部からの資金調達を考慮していないが、事業の財務面を重視して<hintTarget>事業間のマーケティングや技術に関するシナジー</hintTarget>を考慮している。  

<hint hidden='true'>**
❌PPMでは、事業間のマーケティングや技術に関するシナジーは考慮していません。
**</hint>

`
        },
        {
                "mondaiId": 103270020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
複数事業を営む企業の企業ドメインおよび事業ドメインの決定に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  企業ドメインの決定は、現在の活動領域や製品・事業分野との関連性ととも  
に、将来の企業のあるべき姿を包含して経営理念を反映している。  
  
イ  企業ドメインの決定は、全社戦略策定の第一歩として自社の存続のために外部  
の多様な利害関係者との間の様々な相互作用の範囲を反映している。  
  
ウ  企業ドメインの決定は、多角化した企業において個々の事業の定義を足し合わ  
せることではなく、企業ドメインに合わせて事業の定義を見直すことが重要であ  
る。  
  
エ  事業ドメインの決定は、将来の事業領域の範囲をどう定義するかについて、企  
業が自らの相互作用の対象として選択した事業ポートフォリオの決定であり、特  
定の市場での競争戦略に影響を受ける。  
  
オ  事業ドメインの決定は、日常的なオペレーションがルーティン化していたとし  
ても、競争優位を持続するためには必要である。  
        
`
        },
        {
                "mondaiId": 103270030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の経営資源と持続的な競争優位に関する記述として、<u>最も不適切なものはど  
れか</u>。  
  
ア  ある市場において、競合企業が業界のリーダーのもつ経営資源を複製する能力  
をもっていても、市場規模が限られていて複製を行わないような経済的抑止力の  
ある状況では模倣しない傾向がある。  
  
イ  競合企業に対する持続可能な競争優位の源泉となるためには、代替可能な経営  
資源の希少性が長期にわたって持続する必要がある。  
  
ウ  時間の経過とともに形成され、その形成のスピードを速めることが難しく、時  
間をかけなければ獲得できない経営資源には経路依存性があり、模倣を遅らせる  
ことで先発者を保護する。  
  
エ  代替製品の脅威は事業の収益性に影響を与えるが、競合企業は代替資源で同様  
の顧客ニーズを満たす製品を提供できる。  
  
オ  独自能力の概念では、競争戦略の実行に不可欠な経営資源であっても、自社製  
品や事業のオペレーションを特徴づける独自なものでなければ、その資源は競争  
優位の源泉とはならない。  
        
`
        },
        {
                "mondaiId": 103270040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
自社の仕入先および顧客に対する交渉力に関する記述として、最も適切なものは  
どれか。  
  
ア  今まで仕入先から調達していた部品の内製の割合を高めていく場合は、自社の  
仕入先に対する交渉力は弱くなる。  
  
イ  希少価値の高い原材料を仕入れている場合は、自社の仕入先に対する交渉力は  
強くなる。  
  
ウ  顧客が他社製品へ乗り換える際に多大なコストが発生する場合は、自社の顧客  
に対する交渉力は強くなる。  
  
エ  仕入先の売上高に占める自社の割合が高い場合は、自社の交渉力は弱くなる。  
  
オ  自社が顧客の意思決定を左右できるような場合は、仕入先に対する交渉力は弱  
くなる。  
        
`
        },
        {
                "mondaiId": 103270050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
どのようにして早く競争力のある製品を開発し、市場に供給するか、という時間  
をめぐる競争は｢タイムベース競争｣と呼ばれるが、タイムベース競争に関する記述  
として、<u>最も不適切なものはどれか</u>。  
  
ア  製品開発では、最初に製品を生産・販売することにより、企業のブランドを一  
般名詞のように使うことで顧客の頭の中に刷り込み、商品選択の際に有利となる  
ような先発者の優位性が生じる。  
  
イ  製品開発では、最初に製品を生産・販売することで競合他社よりも早期に量産  
化し、大規模生産による経験効果を連続的に享受できるような先発者の優位性が  
生じる。  
  
ウ  タイムベース競争の効果は、開発から生産・販売までのリードタイムの短縮に  
よる販売上の機会損失の発生の防止にも現れる。  
  
エ  タイムベース競争の効果は、工場での生産リードタイムの短縮による原材料費  
の削減によって、原材料購入にかかわる金利の削減にも現れる。  
  
オ  タイムベース競争の効果は、顧客ニーズに俊敏に対応することで価格差を克服  
し、結果的に競合他社よりも高い利益率を実現することにも現れる。  
        
`
        },
        {
                "mondaiId": 103270060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
デファクト・スタンダードに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  自社規格がデファクト・スタンダードとなるためには、競合企業に対して規格  
をオープンにし、協定を締結することが必要となる。  
  
イ  自社規格がデファクト・スタンダードとなるためには、公的な標準化機関の認  
定を必要としない。  
  
ウ  デファクト・スタンダードとなる規格が登場することによって、多くの企業が  
同一規格の製品を販売し、機能面での差別化競争や安さを売りにした低価格競争  
が激化することがある。  
  
エ  デファクト・スタンダードとなる規格の登場は、市場の導入期から成長期への  
移行を加速させる。  
        
`
        },
        {
                "mondaiId": 103270070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品アーキテクチャがモジュール化するにつれて、技術戦略は変わってくる。そ  
のような変化がもたらす部品メーカーの状況や、部品メーカーの変化への対応に関  
する記述として、最も適切なものはどれか。  
  
ア  製品サブシステムのインターフェースが標準化されるにつれて、部品メーカー  
は一定のデザインルールのもとで、独自に技術開発を進めることが可能になる。  
  
イ  製品統合が容易になり、組立メーカーの製品が標準化されるにつれて、その収  
益が低下するので、部品メーカーも収益が悪化する。  
  
ウ  製品のサブシステム間の関係が簡素になるので、部品メーカーは部品生産技術  
をめぐって、組立メーカーとの技術交流を緊密化することが重要になる。  
  
エ  標準化された部品の生産プロセスにおける技術改良の余地がなくなり、価格競  
争が激化するので、部品メーカーの収益は悪化する。  
  
オ  部品メーカーにとっては、自社固有の独自技術を梃子（てこ）にして新規なモジュール  
部品を開発する必要性がなくなるので、これまで取引がなかった組立メーカーに  
も販路を広げることが重要になる。  
        
`
        },
        {
                "mondaiId": 103270081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

技術開発型ベンチャー企業が自社開発の技術の成果を商品化していくプロセスは、いくつかの段階に分かれている。研究段階では研究開発チームなどでシーズを創出し、開発段階では研究から開発へと発想転換してマーケティングによる仕様の絞り込みで製品開発に取り組む。そのうえで、開発した製品を市場へ投入して事業化を成し遂げ、事業の拡大を意図した戦略をもとに生産・販売体制の確立を進めていく。

しかし、段階を進めていく過程ではいくつかの障壁に直面し、その回避策を考える必要がある。研究段階から事業化に至るまでの障壁には、<u>①基礎研究で開発されたシーズの社会的な有用性が識別しにくいことによる｢デビルリバー(魔の川)｣、応用研究と製品開発の間で十分な資金や人材などの資源を調達できない｢デスバレー(死の谷)｣</u>があり、事業化を成し遂げた後にも、<u>②市場で直面する激しい競争状況を意味する｢ダーウィンの海｣</u>と呼ばれる障壁がある。  
        
（設問1）  
文中の下線部①の｢デビルリバー(魔の川)｣と｢デスバレー(死の谷)｣に関する記  
述として、最も適切なものはどれか。  
  
ア  TLOなどを活用して大学の技術との連携を積極化するよりも、基礎技術や  
高い要素技術を必要とする領域に踏み込んで自社技術の開発に注力することが  
｢デビルリバー｣の回避につながる。  
  
イ  技術シーズ志向の研究とニーズ志向の開発では、新たなシーズを絞り込む収  
束型作業から大きなニーズを見つける発散型作業へ切り替えができなければ、  
｢デスバレー｣を越えられずに資金的に行き詰まってしまう。  
  
ウ  社内プロジェクトメンバーの担当を入れ替え、商品化や顧客マーケティング  
に近いメンバーに権限を持たせることは｢デスバレー｣の回避につながる。  
  
エ  所有している特許権や意匠権などの産業財産権のうち、一部の専用実施権を第三者企業に付与するのを避けることで｢デビルリバー｣を超える時間の短縮に  
つながる。`
        },
        {
                "mondaiId": 103270082,
                "name": "第8問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

技術開発型ベンチャー企業が自社開発の技術の成果を商品化していくプロセスは、いくつかの段階に分かれている。研究段階では研究開発チームなどでシーズを創出し、開発段階では研究から開発へと発想転換してマーケティングによる仕様の絞り込みで製品開発に取り組む。そのうえで、開発した製品を市場へ投入して事業化を成し遂げ、事業の拡大を意図した戦略をもとに生産・販売体制の確立を進めていく。

しかし、段階を進めていく過程ではいくつかの障壁に直面し、その回避策を考える必要がある。研究段階から事業化に至るまでの障壁には、<u>①基礎研究で開発されたシーズの社会的な有用性が識別しにくいことによる｢デビルリバー(魔の川)｣、応用研究と製品開発の間で十分な資金や人材などの資源を調達できない｢デスバレー(死の谷)｣</u>があり、事業化を成し遂げた後にも、<u>②市場で直面する激しい競争状況を意味する｢ダーウィンの海｣</u>と呼ばれる障壁がある。  

（設問2)  
文中の下線部②の｢ダーウィンの海｣を回避するための方策に関する記述とし  
て、最も適切なものはどれか。  
  
ア  研究開発段階で大手企業と共同開発をしていても、事業化以降はアライアン  
スの解消を進める。  
  
イ  生産と販売・アフターサービスを分離して独立させた体制の構築を進める。  
  
ウ  生産に伴う原材料の支払いサイトと製品販売後の回収サイトの時間差を短縮  
する。  
  
エ  生産の外部委託を進め、製品企画と製品設計に注力する。  
        
`
        },
        {
                "mondaiId": 103270090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
社外の企業や研究機関と連携して展開されるイノベーションが注目されている。  
そのようなイノベーションへの対応や課題に関する記述として、最も適切なものは  
どれか。  
  
ア  NIH(NotInventedHere)現象と呼ばれる社外技術の活用への抵抗の主な理由  
は、社外技術への不信感や予算削減への抵抗ではなく、自社技術への強い自信に  
ある。  
  
イ  新しいモノ好きのリードユーザー(先端顧客)からの製品情報は、技術的な裏付  
けを欠くので、イノベーションのアイデアとして評価するにとどめる。  
  
ウ  自社の経営資源を社外に開放して活用することによって、知的財産権からの収  
入やジョイントベンチャー設置による事業収入などの多様な収益源を確保できる  
可能性が生まれる。  
  
エ  製品が市場に出るまでの時間や製品のライフサイクルが短くなるにつれて、研  
究開発部門への技術人材や資金の投入が効果的でなくなるので、他社とのオープ  
ンな技術交流による研究開発にそれらを集中的に投入しなければならない。  
        
`
        },
        {
                "mondaiId": 103270100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
リストラクチャリング(事業構造の再構築)に関する記述として、最も適切なもの  
はどれか。  
  
ア  リストラクチャリングの一環として事業売却を行う場合は、対象となる事業の  
従業員に時間をかけて納得してもらい、ボトムアップで売却ステップを検討して  
いくことが課題となる。  
  
イ  リストラクチャリングの一環として事業を子会社として独立させる場合は、各  
子会社に大幅に権限を委譲し、意思決定の迅速化を図ることが課題となる。  
  
ウ  リストラクチャリングを円滑に進めるうえでは、既存の取引先との取引量を増  
やすことを目的に、リベートや割引販売などの販売促進策を積極的に行うことが  
課題となる。  
  
エ  リストラクチャリングを円滑に進めるうえでは、業務プロセスを抜本的に見直  
すことによって業務を再設計し、業務の効率化を図ることが課題となる。  
  
オ  リストラクチャリングを円滑に進めるうえでは、従業員のモチベーションを上  
げていくために、ストックオプションを導入していくことが課題となる。  
        
`
        },
        {
                "mondaiId": 103270111,
                "name": "第11問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

日本企業は、中国やアセアン諸国等の新興国に向けて、大企業のみならず中小中  
堅企業も数多く進出している。中小中堅企業は、大手取引先の海外生産拠点への部  
品供給や技術支援を目的に海外進出をする場合が多い。その一方で、近年、  
<u>①小売業やサービス業分野はもとより一部の製造業でも現地市場への浸透を目指す海外進出  
が増加しており、成功事例も多くなっている</u>。  

他方、アジアでは自国の経済が発展するにつれて現地の有力企業が台頭し、海外  
企業と激しく競争する例がみられるようになった。わが国の多くの企業では高所得  
層のハイエンド市場に現地市場戦略の重心をシフトする例が少なくない。しかし、  
人口が多く、将来的に大きく成長する可能性のある  
<u>②中所得層や低所得層の潜在的な市場への浸透を図ることも重要である</u>ことを看過してはならない。  
        
（設問1）

文中の下線部①で指摘されている現地市場への浸透の成功事例は、業種の特性  
や進出国の状況などによって多様である。成功している現地対応策に関する記述  
として、<u>最も不適切なものはどれか</u>。  
  
ア  M＆Aをした企業の現地人材に自社のビジョンや戦略の理解を促し、現地  
に大幅な経営権限を与えて、現地に即した経営を展開して現地化を図る。  
  
イ  アジアの新興国市場の発展可能性を評価して、新興国対応のために製品の企  
画から生産、販売までの事業単位を編成して、現地市場への対応強化を図る。  
  
ウ  現地市場への浸透や市場の拡大のスピードを速めるためには、現地法人のガ  
バナンスを強化して、派遣した日本人だけによる生産販売活動に切り換える。  
  
エ  現地の市場で優位に立つのは、日本国内や海外のライバル企業であることも  
多いので、ライバル企業の戦略を分析して自社の現地優位性を確立することを  
重視する。  
  
オ  新興国で小売や飲食サービスのチェーン展開を図るために、ブランドを重視  
して、事業コンセプトに沿った現地でのオペレーションを実施する。  
        
`
        },
        {
                "mondaiId": 103270112,
                "name": "第11問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

日本企業は、中国やアセアン諸国等の新興国に向けて、大企業のみならず中小中  
堅企業も数多く進出している。中小中堅企業は、大手取引先の海外生産拠点への部  
品供給や技術支援を目的に海外進出をする場合が多い。その一方で、近年、  
<u>①小売業やサービス業分野はもとより一部の製造業でも現地市場への浸透を目指す海外進出  
が増加しており、成功事例も多くなっている</u>。  

他方、アジアでは自国の経済が発展するにつれて現地の有力企業が台頭し、海外  
企業と激しく競争する例がみられるようになった。わが国の多くの企業では高所得  
層のハイエンド市場に現地市場戦略の重心をシフトする例が少なくない。しかし、  
人口が多く、将来的に大きく成長する可能性のある  
<u>②中所得層や低所得層の潜在的な市場への浸透を図ることも重要である</u>ことを看過してはならない。  
        
（設問2）

文中の下線部②で指摘するような市場への浸透について注意すべきことに関す  
る記述として、最も適切なものはどれか。  
  
ア  現地の大衆市場でコモディティ化が進行する製品分野では、改良型製品を  
次々に市場に投入するスピードを発揮できれば、価格競争を回避し得る。  
  
イ  現地の大衆市場では低価格を武器とする現地企業と競合して不採算に陥りや  
すいので、現地対応の低価格製品を日本国内の生産で供給する体制をとる。  
  
ウ  現地の大衆市場では薄利多売が有効であるが、損益分岐点が押し上げられる  
ため、営業費用等の変動費を下げる必要がある。  
  
エ  現地の低所得層の市場では、商品配送に支障をもたらす道路事情や商品知識  
に乏しい顧客が散在しているなどのため、濃密でコストのかかる人的接触重視  
によるアプローチも求められることに注意しなければならない。  
        
`
        },
        {
                "mondaiId": 103270120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織を情報処理システムとしてみた場合、組織デザインの手段は、情報処理の必  
要性と情報処理能力の観点から評価できる。組織デザインに関する記述として、最  
も適切なものはどれか。  
  
ア  横断的組織の導入は、情報処理の必要性を高くするとともに、組織の情報処理  
能力を高くする。  
  
イ  階層組織は、情報処理の必要性を高くするとともに、組織の情報処理能力を高  
くする。  
  
ウ  規則の使用は、情報処理の必要性を減らすが、組織の情報処理能力を低くす  
る。  
  
エ  自己完結的組織は、情報処理の必要性を高くするとともに、組織の情報処理能  
力を高くする。  
  
オ  垂直的情報処理システムの導入は、情報処理の必要性を高くするが、組織の情  
報処理能力を低くする。  
        
`
        },
        {
                "mondaiId": 103270130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
人間や組織は、単純化や経験則に頼って意思決定をすることが多い。こうした単  
純化の方法は、ヒューリスティックと呼ばれ、時には論理的な意思決定に対してバ  
  
イ  アスをかけてしまうこともある。このようなヒューリスティックやバイアスに関  
する記述として、最も適切なものはどれか。  
  
ア  ある選択肢に好意を抱いた人は、その選択肢を支持するような証拠を探し求  
め、データをそのように解釈する｢後知恵バイアス(hindsight bias)｣に陥りやす  
い。  
  
イ  同じ業績であっても、上司のそばに席を置いている部下の方が、遠くの席の部  
下よりも高く評価される傾向がある場合には、｢確証バイアス(confirmation  
bias)｣が作用している可能性が高い。  
  
ウ  肯定的仮説検証現象が起きると、結果が出たあとにものごとを振り返った場  
合、他の結果も起こりえた可能性を無視してしまう｢感情ヒューリスティック  
(affect heuristic)｣に陥りやすい。  
  
エ  人間が意思決定する際に、｢営業に適した人は社交性が必要だ｣といったよう  
に、あらかじめ抱いている固定観念に合った特性を見いだそうとする｢代表性ヒ  
ューリスティック(representativeness heuristic)｣を利用する傾向がある。  
  
オ  人間は天気の良い日には楽観的になって、株価が上昇したりするが、このよう  
な効果は｢利用可能性ヒューリスティック(available heuristic)｣に依拠する。  
        
`
        },
        {
                "mondaiId": 103270140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
バーナードの理論において、組織の権威の根拠を従業員に求めたとき、上意下達  
のリーダーシップが維持されると考えられる理由として、最も適切なものはどれ  
か。  
  
ア  従業員が組織の権威を受け入れている場合、組織的なコミュニケーションに従  
わないことは、自らの利害を損ねることになるから。  
  
イ  組織の権威を受け入れた従業員の間には、組織に反抗する非公式組織が形成さ  
れないから。  
  
ウ  組織の権威を伝達するためには、ビジョンを提示し、自ら部下の規範となる行  
動を行い、その結果を評価することができるカリスマ的リーダーシップが求めら  
れるから。  
  
エ  リーダーが何を命令しようが、従業員がそれに従おうとしない限り、命令は実  
行されないから。  
  
オ  リーダーシップには、単に従業員に命令を下して目的を達成する機能だけでは  
なく、人間関係に配慮して集団凝集性を高める機能の両方が求められるから。  
        
`
        },
        {
                "mondaiId": 103270150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業で働く人々は、雇用契約として規則で明文化されている処遇が改善されるか  
どうかにかかわらず、業務上で必要な仕事に取り組む傾向がある。このような働き  
方を支える人々の心理的状態に注目する概念のひとつに、心理的契約がある。心理  
的契約に関する記述として、最も適切なものはどれか。  
  
ア  心理的契約があれば、組織は明文化された雇用契約以上の業績を期待すること  
ができる。  
  
イ  心理的契約は、雇用契約を結ぶ組織との間ではなく、実際の当事者間の相互期  
待や理解として結ばれている。  
  
ウ  心理的契約は、正規社員との間には結ばれるが、非正規社員との間には結ばれ  
ない。  
  
エ  心理的契約は、組織との明文化された契約関係ではなく、将来に関する人と人  
との間での約束である。  
  
オ  心理的契約は、外部の社会的・経済的環境、人事施策、リーダーシップなどと  
は独立に結びついた、心理的な状態を指す。  
        
`
        },
        {
                "mondaiId": 103270160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業において、経営者はすべての側面で平等に個人を処遇することはできず、差  
異を正当に評価する必要がある。この正当性は、一般的に広く認められた公平なル  
ールによって担保されるが、とくに企業で広く利用されるルールのことを、組織的  
公正と呼ぶ。組織的公正に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  意思決定の諸ルールに基づき、定められた役割の人が成果の分配にかかわる意  
思決定にあたる。  
  
イ  個人が置かれた境遇に基づき、社会的な必要性に応じて成果を分配する。  
  
ウ  仕事の客観的便益ではなく、生来の能力や外的環境に左右されない、努力に応  
じた処遇を行う。  
  
エ  成果の分配を一律平等にするために、個人属性を不問にする。  
  
オ  組織や社会に対して個人が提供した客観的便益の対価として成果を分配する。`
        },
        {
                "mondaiId": 103270170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
職務再設計とは、職務を通じた動機づけを目的とした管理方法の総称であるが、  
その方法のひとつである職務拡大に関する記述として、最も適切なものはどれか。  
  
ア  新たな上司や同僚との調整コストが発生するというデメリットがある。  
  
イ  個人が行うタスクの数や種類を増やし、職務に多様性を持たせる。  
  
ウ  仕事のやりがいが感じられなくなった場合、同一レベルで同様のスキルを要す  
る職務に配置換えを行う。  
  
エ  職務の計画、実施、評価を、自分自身で管理できるようにする。  
  
オ  複数の職務を横断させることでスキルの拡張を図る。  
        
`
        },
        {
                "mondaiId": 103270180,
                "name": "第18問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織の有効性や従業員のウエルネス?心身ともに良好な状態…の改善を目指して、  
人間的かつ民主的価値観のもとで計画的に組織変革に介入するマネジメント手法と  
して、古くから組織開発が実践されてきた。組織開発が重視している価値観とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  階層的な権威や支配にこだわらない。  
  
イ  信頼関係で結ばれ、他者に対して開かれ、協力的な環境を持った組織が効果的  
で健全である。  
  
ウ  組織メンバーは、責任感をもち、誠実で思いやりがある存在として尊敬に値す  
る。  
  
エ  変革の影響を受ける人を決定に参加させ、変革の実行に関与させる。  
  
オ  問題解決の結果にはこだわらず、取り組みのプロセスを重視する。`
        },
        {
                "mondaiId": 103270190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織スラックに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  組織スラックは、イノベーションを遂行するための資源となりうる。  
  
イ  組織スラックは、緊急事態に対応するための余裕資源として、組織の安定に寄  
与する。  
  
ウ  組織スラックは、新規行動案の探索をリスク回避的にする傾向にある。  
  
エ  組織スラックは、複数の利害関係者の組織に対する要求を調整する機能を持  
つ。  
  
オ  組織スラックは、利害関係者が組織に対して求める要求が、満足水準に基づく  
ことから生じる傾向にある。  
        
`
        },
        {
                "mondaiId": 103270200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織は、ときに環境変化に対して抵抗することがある。組織が変化へ抵抗する理  
由として、<u>最も不適切なものはどれか</u>。  
  
ア  個人が変革を志向していたとしても、グループの規範がこれを抑制する慣性を  
もつから。  
  
イ  組織が有する公式化されたルールが、既存のルールに従うよう組織メンバーを  
社会化するから。  
  
ウ  組織固有の特殊スキルを持つグループが、組織の外部へと専門家ネットワーク  
を広げているから。  
  
エ  組織内で大きな予算を有し決定権限を持つグループが、自らの利益や権力を守  
ろうとするから。  
  
オ  組織を構成するサブシステムが存在するため、変化が部分的なものにとどまり  
がちになるから。  
        
`
        },
        {
                "mondaiId": 103270210,
                "name": "第21問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織文化の機能やその変容に適したメカニズムは、組織の発展段階に応じて異な  
る。組織文化の機能と変容メカニズムに関する記述として、最も適切なものはどれ  
か。  
  
ア  垂直統合や多角化を通じて組織が成長初期段階に達すると、下位組織文化が発  
達し始め、組織文化は組織のアイデンティティーの源泉としての機能を持つよう  
になる。  
  
イ  成熟段階の組織において、組織文化がイノベーションを妨げるものに転嫁した  
場合、スキャンダルや神話の構築を使った組織文化の変革手法が有効になる。  
  
ウ  創業者やその家族が支配している創業段階の組織では、組織文化を変革するた  
めには、組織開発などの手法が効果的である。  
  
エ  組織の創業段階では、組織文化はまだ明確ではなく十分機能しないため、組織  
構造面での精緻な統制が必要である。  
  
オ  組織が成熟段階に達し、パラダイム・レベルでの深い組織文化の変革が必要な  
場合には、首脳陣の大量交代や組織構造の再編成などの方法が有効である。  
        
`
        },
        {
                "mondaiId": 103270220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
賃金の支払いに関する記述として、最も適切なものはどれか。  
  
ア  就業規則により1日の勤務時間が午前9時から午後5時まで(休憩時間1時間)  
と定められている事業所で、労働者に午後5時から午後6時まで｢残業｣をさせた  
場合、労働基準法第37条の定めにより、この1時間についての割増賃金を支払  
わなければならない。  
  
イ  賃金はその全額を労働者に支払わなければならないのが原則であるが、法令で  
定められている源泉所得税や社会保険料などは賃金からの控除が認められてい  
る。  
  
ウ  通勤距離が片道2キロメートル未満でも、自家用自動車、自転車等の交通用具  
を使用する場合に支給される通勤手当については非課税扱いとなる。  
  
エ  労働者が業務上の災害により休業する場合には、労働者災害補償保険法に基づ  
き休業補償給付が支給されるが、休業3日目までは事業主が、平均賃金の10割  
に相当する額を休業補償として支払わなければならない。`
        },
        {
                "mondaiId": 103270230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
退職に関する記述として、最も適切なものはどれか。  
  
ア  就業規則において、｢競業他社へ転職する場合は退職金を減額する｣旨を定める  
ことは違法とみなされ、その定めは常に無効となる。  
  
イ  傷病休職中の従業員が、病状が回復せずに休職期間満了を迎えた場合、退職と  
して扱うか解雇として扱うかは就業規則で定めることができる。  
  
ウ  退職を申し出た従業員が、退職日までの間にまったく出勤せず、未消化の年次  
有給休暇をすべて取得することを希望した場合、その従業員の退職願を承認しな  
いことができる。  
  
エ  未曾有の経営危機に際して、説明会を経て全従業員から退職願をいったん提出  
させた場合、この退職願に基づいて会社は任意に従業員を退職させることができ  
る。  
        
`
        },
        {
                "mondaiId": 103270240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働安全衛生法第66条の(に定める｢医師による面接指導｣に関する記述として、  
<u>最も不適切なものはどれか</u>。  
  
ア  事業者は、休憩時間を除き1週間当たり40時間を超えて労働させた場合にお  
けるその超えた時間が1カ月当たり100時間を超え、かつ、疲労の蓄積が認めら  
れる労働者に対しては、本人の申出の有無にかかわらず、面接指導を実施しなけ  
ればならない。  
  
イ  事業者は、面接指導の結果に基づき、当該面接指導の結果の記録を作成して、  
これを5年間保存しなければならない。また、その記録は、医師の意見を記載し  
たものでなければならない。  
  
ウ  事業者は、面接指導の結果に基づき、当該労働者の健康を保持するために必要  
な措置について、医師の意見を聴かなければならない。この医師からの意見聴取  
は、面接指導が行われた後、遅滞なく行わなければならない。  
  
エ  事業者は、面接指導を行う労働者以外の労働者であって、健康への配慮が必要  
なもの(時間外・休日労働が1カ月あたり80時間を超える者等)については、面  
接指導や面接指導に準ずる措置などを講ずるよう努めなければならない。  
        
`
        },
        {
                "mondaiId": 103270250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
各社会保険の目的に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  健康保険は、労働者の疾病、負傷、死亡に関して保険給付を行い、国民の生活  
の安定と福祉の向上に寄与することを目的とするが、出産は保険給付の対象とな  
らない。  
  
イ  厚生年金保険は、労働者の老齢、障害、死亡について保険給付を行い、労働者  
及びその遺族の生活の安定と福祉の向上に寄与することを目的とするが、疾病、  
負傷は保険給付の対象とならない。  
  
ウ  雇用保険は、労働者が失業した場合、労働者について雇用の継続が困難となっ  
た場合及び教育訓練を受けた場合に、生活及び雇用の安定と就職の促進のために  
失業給付等を支給することにより労働者の生活及び雇用の安定を図るとともに、  
失業の予防・雇用状態の是正・雇用機会の増大、労働者の能力の開発、労働者の  
福祉の増進を図ることを目的とする。  
  
エ  労働者災害補償保険は、労働者が業務上の災害や通勤による災害を受けた場合  
に、被災労働者やその遺族を保護するために必要な給付を行う制度であるが、老  
齢は保険給付の対象とならない。  
        
`
        },
        {
                "mondaiId": 103270261,
                "name": "第26問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

Aさんはアウトドア・グッズを品揃えする専門店を営んでいる。単独店舗によ  
る経営で、従業者はAさんを含む3名である。開業時からスポーツ自転車を取り  
扱ってきたが、ここ数年の自転車ブームを受けて、｢この小売店オリジナルの自転  
車や関連雑貨を用意してほしい｣という顧客の声が目立っている。Aさんは、｢  
<u>①PB</u>商品の品揃えは、大きな小売業者でなければ難しいのではないか｣と思い込んでい  
たが、様々な事例を参考にすべく、関連するテーマの本や雑誌を読んだり、各地の  
小売業者に話を聞きに行ったりしながら、<u>②自店のPB商品導入を検討している</u>。  


（設問1）

文中の下線部①に示す｢PB｣に関する記述として、最も適切なものはどれか。  
  
ア  PB商品は、その登場から現代に至るまで、一貫して劣等財として消費者の  
間で普及している。  
  
イ  PB商品を販売することができるのは、小売業者に限られた特権である。  
  
ウ  PBは、パーソナル・ブランドの略称であり、ヨーロッパでは、オウン・ブ  
ランドと呼ばれることもある。  
  
エ  品揃えにおけるPB商品の構成比が高まると、消費者の不満を招くことがあ  
る。  
        
`
        },
        {
                "mondaiId": 103270262,
                "name": "第26問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `                
次の文章を読んで、下記の設問に答えよ。  

Aさんはアウトドア・グッズを品揃えする専門店を営んでいる。単独店舗によ  
る経営で、従業者はAさんを含む3名である。開業時からスポーツ自転車を取り  
扱ってきたが、ここ数年の自転車ブームを受けて、｢この小売店オリジナルの自転  
車や関連雑貨を用意してほしい｣という顧客の声が目立っている。Aさんは、｢  
<u>①PB</u>商品の品揃えは、大きな小売業者でなければ難しいのではないか｣と思い込んでい  
たが、様々な事例を参考にすべく、関連するテーマの本や雑誌を読んだり、各地の  
小売業者に話を聞きに行ったりしながら、<u>②自店のPB商品導入を検討している</u>。  

（設問2)

文中の下線部②に関連して、小売業者のPB商品の一部導入に関する記述とし  
て、最も適切なものはどれか。  
  
ア  PB商品の導入によって、NB商品の一部が小売業者の店頭から姿を消すた  
め、小売業者の独立性が低下する。  
  
イ  PB商品の導入によって、消化仕入れの取引条件を活用することが可能とな  
り、在庫保有に起因する危険負担を軽減することができる。  
  
ウ  PB商品の導入によって、商圏内の競争関係にある店舗との間で、自らの店  
舗が独占的状況を作り出しやすくなる。  
  
エ  PB商品の導入によって、自らが価格設定を行う必要がなくなるので、仕入  
れに関する多くの業務を削減することができる。`
        },
        {
                "mondaiId": 103270263,
                "name": "第26問設問3",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

Aさんはアウトドア・グッズを品揃えする専門店を営んでいる。単独店舗によ  
る経営で、従業者はAさんを含む3名である。開業時からスポーツ自転車を取り  
扱ってきたが、ここ数年の自転車ブームを受けて、｢この小売店オリジナルの自転  
車や関連雑貨を用意してほしい｣という顧客の声が目立っている。Aさんは、｢  
<u>①PB</u>商品の品揃えは、大きな小売業者でなければ難しいのではないか｣と思い込んでい  
たが、様々な事例を参考にすべく、関連するテーマの本や雑誌を読んだり、各地の  
小売業者に話を聞きに行ったりしながら、<u>②自店のPB商品導入を検討している</u>。  

（設問3)

文中の下線部②に示す、Aさんの自店でのPB商品導入に向けた検討内容に関  
する記述として、最も適切なものはどれか。  
  
ア  Aさんの店舗でのPB商品の品揃えに協力を行う意思決定をした大手自転車  
メーカーの動機は、単品当たりのより高い粗利益額を得ることである。  
  
イ  Aさんの店舗では大量のPB商品を販売することは当面難しいが、有名メー  
カーのパーツを用いたPB商品や、ダブルチョップ方式で、実現可能であり、  
一定の売上を期待することができる。  
  
ウ  Aさんの店舗は小規模であるが、PB商品の自転車や関連商品を品揃えする  
ことで、有名メーカーに対する強い交渉力を短期間で形成することができる。  
  
エ  Aさんの店舗は単独店舗での経営であるが、近隣に立地する複数の異業種  
の町工場と連携すれば、独自のPB自転車を低コストで容易に開発することが  
できる。  
        
`
        },
        {
                "mondaiId": 103270270,
                "name": "第27問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
人的販売に関する記述として、最も適切なものはどれか。  
  
ア  人的販売では、個人への信頼(人格信頼)が主要な役割を果たすため、企業信頼  
の効果は限定的である。  
  
イ  人的販売は、営業担当者や売場担当者によって遂行され、広告と同様、説得的  
な情報を一方向で伝達する役割を果たしている。  
  
ウ  人的販売は、結果統制型の管理システムを通じた費用の低減が顕著な販売促進  
の手段であり、企業規模の大小を問わず、他のプロモーションよりも予算規模は  
小さい。  
  
エ  人的販売は、商談や営業のプロセスで顧客の新たな欲求やユーザーイノベーシ  
ョンを察知したり、競争動向の情報を収集したりする機能をもつ。  
        
`
        },
        {
                "mondaiId": 103270280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
価格政策に関する記述として、最も適切なものはどれか。  
  
ア  EDLPを実現するためには、メーカーとの交渉を通じて一定期間の買取り数量  
を決め、納入価格を引き下げ、価格を固定し、自動発注化や物流合理化などを促  
進する必要がある。  
  
イ  キャプティブ(虜)・プライシングは、同時に使用される必要のある\u000fつの商品  
のマージンを各々高く設定する価格政策である。  
  
ウ  ターゲット・コスティングによる価格決定は、ある製品に要する変動費と固定  
費の水準をもとにして、そこにマージンを付加する方法である。  
  
エ  日本の小売業では、チラシを用いた特売を活用したロスリーダー方式が採用さ  
れる場合が多い。その主な狙いは消費者による単品大量購買を喚起することであ  
る。  
        
`
        },
        {
                "mondaiId": 103270291,
                "name": "第29問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

Ａに関する活動の一環である保管の目的のひとつは、需要のＢ  
への対応である。そのために所有される在庫量は、需要変動の大きさや注文から納  
品までのＣなどに依存する。消費多様化やＤの短縮化も、在庫の  
増加に影響を与えうる要因である。企業は、<u>こうした日々の在庫に起因するリスク  
をコントロールするための各種の工夫</u>を行っている。  

（設問1）  
文中の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：所有権移転Ｂ：不可視性Ｃ：タイムラグ  
Ｄ：小売業態ライフサイクル  
  
イ  Ａ：販売促進Ｂ：ボラティリティＣ：サイクルタイム  
Ｄ：製品ライフサイクル  
  
ウ  Ａ：物流Ｂ：季節変動Ｃ：リードタイム  
Ｄ：小売業態ライフサイクル  
  
エ  Ａ：物流Ｂ：不確実性Ｃ：リードタイム  
Ｄ：製品ライフサイクル  
  
オ  Ａ：ロジスティクスＢ：不可視性Ｃ：タイムラグ  
Ｄ：小売業態ライフサイクル  
        
`
        },
        {
                "mondaiId": 103270292,
                "name": "第29問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

Ａに関する活動の一環である保管の目的のひとつは、需要のＢ  
への対応である。そのために所有される在庫量は、需要変動の大きさや注文から納  
品までのＣなどに依存する。消費多様化やＤの短縮化も、在庫の  
増加に影響を与えうる要因である。企業は、<u>こうした日々の在庫に起因するリスク  
をコントロールするための各種の工夫</u>を行っている。  

（設問2）  
文中の下線部に示す｢各種の工夫｣に関連する記述として、最も適切なものはど  
れか。  
  
ア  情報技術の活用は、組織間の情報共有を通じた生産段階の効率化、そして在  
庫管理や商品補充など物流合理化に貢献しているが、取引上のペーパーレス化  
に対する貢献はない。  
  
イ  製品形態と生産数量についての意思決定をできるだけ実需の発生時点まで延  
期し、原料・素材から迅速・柔軟な生産を行う方式は、生産段階における規模  
の優位性を生み出す。  
  
ウ  投機の原理に従えば、できるだけ早い段階で製品形態や製品の在庫位置に関  
する意思決定を行うことで、需要の変動性によるリスクの多くを吸収すること  
ができる。  
  
エ  流通段階では、製品の在庫位置変更のタイミングをできるだけ実需の発生時  
点・地点に近づける形での延期型対応が有効である。  
        
`
        },
        {
                "mondaiId": 103270300,
                "name": "第30問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費者心理に関する記述として、最も適切なものはどれか。  
  
ア  近年、ブランド関連商品をおまけに付けた雑誌が売上を伸ばしているが、その  
要因は｢道具的条件づけ｣で説明することができる。  
  
イ  消費者の知覚リスクは、商品知識が乏しい場合や複雑な商品の場合に高まる  
が、商品に対する関与が高い場合や多くの選択肢から選べる場合には低下する。  
  
ウ  消費者の認知や好意的評価を得ていない新しい商品やサービスの場合には、一  
般的に、自社ブランドの強みとなる一点を集中的に訴求することが有効である。  
  
エ  プロモーション活動において、たとえば紫外線が肌にもたらす悪影響を示すメ  
ッセージが化粧品に用いられることがある。通常、恐怖喚起が強ければ強いほど  
商品効能に対する説得効果は高まる。  
        
`
        },
        {
                "mondaiId": 103270311,
                "name": "第31問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

人は、一般的に、自分にとって最良と思われる商品を購入する。しかし、購入後  
に｢本当にこの選択でよかったのか｣、｢迷ったもうひとつの商品のほうがよかった  
のではないか｣と思い悩むことは、決して珍しいことではない。購入した商品は最  
良と思う一方で、他の商品のほうがよかったのではないかとも考える。人は、こう  
した2つの認識の矛盾から、<u>心理的な緊張</u>を高める。  

（設問1）

文中の下線部の｢心理的な緊張｣状態を表す語句として、最も適切なものはどれ  
か。  
  
ア  サイコグラフィックス  
  
イ  認知的不協和  
  
ウ  バラエティシーキング  
  
エ  ブランドスイッチング`
        },
        {
                "mondaiId": 103270312,
                "name": "第31問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

人は、一般的に、自分にとって最良と思われる商品を購入する。しかし、購入後  
に｢本当にこの選択でよかったのか｣、｢迷ったもうひとつの商品のほうがよかった  
のではないか｣と思い悩むことは、決して珍しいことではない。購入した商品は最  
良と思う一方で、他の商品のほうがよかったのではないかとも考える。人は、こう  
した2つの認識の矛盾から、<u>心理的な緊張</u>を高める。  

（設問2）
 
文中の下線部の｢心理的な緊張｣状態に関する記述として、最も適切なものはど  
れか。  
  
ア  この状態が生じると、好ましい情報を求めて、当該企業のホームページや広  
告を見る傾向がある。  
  
イ  この状態が生じると、当該購買行動が非常に重要な出来事であったかのよう  
に過大に感じる。  
  
ウ  この状態は関与が低くブランド間知覚差異が小さいと生じやすい。  
  
エ  この状態は信頼財よりも探索財や経験財において生じやすい。  
        
`
        },
        {
                "mondaiId": 103270320,
                "name": "第32問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
パッケージに関する記述として、最も適切なものはどれか。  
  
ア  陳列棚での調和を考慮して、新商品には当該カテゴリーの連想色をパッケージ  
に用いるべきである。  
  
イ  パッケージデザインの変更はブランド鮮度を訴求する有効な手段であるため、  
  
イ  メージを一新するパッケージ変更を積極的に行うべきである。  
  
ウ  パッケージは、触覚への訴求を重視することがあるブランド要素である。  
  
エ  パッケージ変更は短期的な成果をもたらさないにもかかわらず、他のマーケテ  
ィング・コミュニケーションの変更と比べると、一般的にコストが非常に大き  
い。`
        },
        {
                "mondaiId": 103270330,
                "name": "第33問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
プロモーションに関する記述として、最も適切なものはどれか。  
  
ア  コーズリレーテッド・マーケティングは、一般的に、当該企業の事業収益と関  
連づけない。  
  
イ  パブリシティについては、原則として、ニュース性の高い情報であれば、企業  
がコントロールすることができる。  
  
ウ  パブリックリレーションズでは、製品、人、地域、アイデア、活動、組織、さ  
らには国家さえも対象としてコミュニケーションを実施する。  
  
エ  プロモーションミックスとは、広告、セールスプロモーション、パブリックリ  
レーションズ、インベスターズリレーションズの)つの活動を、マーケティング  
目標に応じて適切に組み合わせることをいう。  
        
`
        },
        {
                "mondaiId": 103270340,
                "name": "第34問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
サービスと、その品質評価や顧客満足に関する記述として、最も適切なものはど  
れか。  
  
ア  SERVQUALでは、信頼性、対応性、確実性、共感性、有用性の5つの側面か  
らサービス品質を評価する。  
  
イ  顧客が、直接、サービスを提供される場面をサービス・スケープといい、重要  
性から｢真実の瞬間｣と称されることがある。  
  
ウ  サービス・プロフィット・チェーンは、従業員の満足を高めることが顧客満足  
や顧客ロイヤルティの向上につながるという考え方を示している。  
  
エ  提供するサービスが���客の期待水準に達すれば、確実に顧客は高い満足を得  
る。`
        },
        {
                "mondaiId": 103270350,
                "name": "第35問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
サービスのマーケティング策に関する記述として、最も適切なものはどれか。  
  
ア  家事代行の利用権をカードなどの形態で販売することは、当該サービス需要拡  
大のための有効策となりうる。  
  
イ  ドリンクバーのように顧客自身にサービス提供者が行う活動を代替してもらう  
ことはコスト削減につながるなどメリットもあるが、顧客満足の視点からは避け  
たほうがよい。  
  
ウ  ファーストフード店においては、サービスを提供する空間が、顧客にとって居  
続けたいと感じる環境になるよう最大限の努力を払うべきである。  
  
エ  旅行会社が、目的地の空を覆うオーロラの神秘性を強くアピールすることは、  
不確実性をともなうとしても顧客満足を高めることになる。  
`
        },
        {
                "mondaiId": 104270010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　ある製造ラインでは、下図に示すように、第１工程から第３工程を経て製品の加工が終了し、出荷される。各工程で不適合品が発生した場合には、手直し工程で手直しされる。投入された製品はすべて次工程に送られ、直行品と手直し品には品質に差はないものとする。各工程の不適合品率は下図に示すとおりである。この製造ラインの直行率の値として、最も適切なものを下記の解答群から選べ。  

<imgTag>104270010_1.png</imgTag>  
<hint hidden='true'>**
直行率とは、初工程から最終工程まで、手直し・調整・手戻りなどがなく順調に通過した品物の、全体の品物に対する割合をいいます。
この問題の直行率を計算すると下記になります。  
**</hint>  

[解答群]
  
ア  0.2％  
  
イ  64.8％  
  
ウ  86.7％  
  
エ  90.0％  
        
`
        },
        {
                "mondaiId": 104270020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
見込生産の特徴に関する記述として、最も適切なものの組み合わせを下記の解答  
群から選べ。  

ａ多品種少量生産である。  
ｂ需要変動はなるべく製品在庫で吸収する。  
ｃ営業情報やマーケットリサーチ情報に基づき需要予測を行い、生産量を決定す  
る。  
ｄ納期をどれだけ守れるかが生産管理のポイントとなる。  

[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 104270030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製造プロセスのデジタル化に関する記述として、最も適切なものはどれか。  
  
ア  CADを導入することで複数台のNC工作機がコンピュータで結ばれ、効率的  
な設備の運用が可能となった。  
  
イ  CAEを導入することで樹脂や金属製の立体物が造形され、開発コストの低減  
と開発期間の短縮が可能となった。  
  
ウ  CAMを導入することでCADと連携したマシニングセンタへの指示プログラ  
ムが作成され、熟練工の高度な加工技術を再現することが可能となった。  
  
エ  3次元CADと3Dプリンタを連携させることで構造解析・流体解析等のシミ  
ュレーションがコンピュータ上で可能となり、開発コストの低減と開発期間の短  
縮につながった。`
        },
        {
                "mondaiId": 104270040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
SLP(SystematicLayoutPlanning)に関する記述として、<u>最も不適切なものはど  
れか</u>。  
  
ア  SLPでは、P(製品)、Q(量)、R(経路)、S(補助サービス)、T(時間)の5つは、  
｢レイアウト問題を解く鍵｣と呼ばれている。  
  
イ  SLPでは、最初にアクティビティの位置関係をスペース相互関連ダイアグラ  
ムに表してレイアウトを作成する。  
  
ウ  SLPにおけるアクティビティとは、レイアウト計画に関連する構成要素の総  
称で、面積を持つものも持たないものも両方含まれる。  
  
エ  アクティビティ相互関連ダイアグラムとは、アクティビティ間の近接性評価に  
基づき作成された線図である。  
        
`
        },
        {
                "mondaiId": 104270050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ある工場では15種類の製品を生産している。これらの製品についてのPQ分析  
を行ったところ、下図のような結果が得られた。  

<imgTag>104270050_1.png</imgTag>  

この図に示すａ、ｂ、ｃの各グループに適した設備レイアウトを次の①~③の中  
から選択する場合に、最も適切な組み合わせを次ページの解答群から選べ。  

【選択する設備レイアウト】  
①工程別レイアウト  
②グループ別レイアウト  
③製品別レイアウト  
        
        

[解答群]  
  
ア  ａ：①ｂ：②ｃ：③  
  
イ  ａ：②ｂ：①ｃ：③  
  
ウ  ａ：③ｂ：①ｃ：②  
  
エ  ａ：③ｂ：②ｃ：①`
        },
        {
                "mondaiId": 104270060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
VEには、現場の基本的なVE活動手順と７つのVE質問を組み合わせて、その  
問いにひとつずつ答えていく方法がある。  

VE活動手順とVE質問の組み合わせとして、最も適切なものはどれか。  
  
ア  改善案の作成　－　他に同じ働きをするものはないか。  
  
イ  機能の定義　－　それは何か。  
  
ウ  機能の評価　－　それは必要な機能を果たすか。  
  
エ  対象の選定　－　それは何をするためのものか。  
        
`
        },
        {
                "mondaiId": 104270070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンカレントエンジニアリングに関する記述として、最も適切なものはどれか。  
  
ア  企業の目標の達成を阻害する制約条件を発見し、それに対処するためのシステ  
ムの改善を図った。  
  
イ  資材供給から生産、流通、販売に至る物またはサービスの供給連鎖をネットワ  
ークで統合化し、情報を共有することによって経営業務全体のスピードおよび効  
率を高めた。  
  
ウ  製品の企画段階から設計、生産、販売までの過程を統合化し、リードタイムの  
短縮を実現した。  
  
エ  製品のライフサイクルの中で使用される製品に関する種々のデータを互いに関  
連付けて、一元的な管理を行った。`
        },
        {
                "mondaiId": 104270080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
機械加工工場で用いられる設備に関する記述として、最も適切なものはどれか。  
  
ア  旋盤は、工作物に回転運動を与え、バイトなどの工具に送り運動を与えること  
により、工作物に加工を施す工作機械である。  
  
イ  鋳造設備は、金属を加熱して高温にした状態で力を加え、変形させることによ  
って製品をつくる設備である。  
  
ウ  鍛造設備は、溶解した金属を型に流し込んで冷却することによって製品をつく  
る設備である。  
  
エ  フライス盤は、ドリルに回転運動を与えつつ上下に送り運動を与えることによ  
り、工作物に加工を施す工作機械である。  
        
`
        },
        {
                "mondaiId": 104270090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
ある会社では、商品の需要予測に指数平滑法(平滑化定数α=0.4)を用いてい  
る。当期の需要予測値75に対し、需要実績値は55であった。次期の需要予測値と  
して、最も適切なものはどれか。  
  
ア  63  
  
イ  65  
  
ウ  67  
  
エ  69`
        },
        {
                "mondaiId": 104270100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
3つの注文を2工程フローショップにおいて生産することを考える。各注文の各  
工程における処理時間は下表に与えられている。すべての注文を生産するのに要す  
る総所要時間を最小にする生産順序として、最も適切なものを下記の解答群から選  
べ。  
　　　　工程１　工程２  
注文１　　５　　　１    
注文２　　３　　　２      
注文３　　１　　　６     
        

[解答群]  
  
ア  注文１　→　注文２　→　注文３    
  
イ  注文１　→　注文３　→　注文２  
  
ウ  注文２　→　注文３　→　注文１  
  
エ  注文３　→　注文２　→　注文１  
        
`
        },
        {
                "mondaiId": 104270110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
１個当たりの在庫保管費(円／個)、1回当たりの発注費(円／回)、安全余裕およ  
び総需要量が経済的発注量に及ぼす影響に関する記述として、最も適切なものはど  
れか。  
  
ア  安全余裕が増加すると経済的発注量は減少する。  
  
イ  在庫保管費(円／個)が増加すると経済的発注量は増加する。  
  
ウ  総需要量が減少すると経済的発注量は増加する。  
  
エ  発注費(円／回)が減少すると経済的発注量は減少する。`
        },
        {
                "mondaiId": 104270120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
新QC7つ道具に関する記述として、最も適切なものはどれか。  
  
ア  2つの事象を行と列に設定し、交差するところに存在する情報を記号化してデ  
ータの傾向をつかむために、マトリックス図法を用いた。  
  
イ  効果的な日程管理を行うために、PDPC法を用いた。  
  
ウ  目標達成プロセスの過程で想定外の問題が生じたとき、できるだけ早く目標に  
向かって軌道修正するために、連関図法を用いた。  
  
エ  問題の因果関係を明らかにすることで、問題の原因の絞り込み、問題解決の手  
がかりの発見、問題の本質的な原因の発見に役立てるために、系統図法を用い  
た。  
        
`
        },
        {
                "mondaiId": 104270130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ものの流れの管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ある在庫点から見て、ものの流れにおける<hintTarget>上流</hintTarget>側の在庫点の在庫の総和によって定義される在庫量をエシェロン在庫という。  

<hint hidden='true'>**
❌正しくは下流側です。
**</hint>
  
イ  “顧客　－　小売業　－　卸売業　－　製造業　－　部品・資材サプライヤ”などの供給活動の連鎖構造を、サプライチェーンという。  
  
ウ  サプライチェーンの上流に行くほど発注量の変動が大きくなる現象を、ブルウィップ効果という。  
  
エ  多段階生産・在庫システムにおける見込生産と受注生産の分岐点を、デカップリングポイントという。  
        
`
        },
        {
                "mondaiId": 104270140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
工場では、ある製品を一定の速度でロット生産している。工場で完成された製品  
は、ロットの生産途中でも販売店に配送できる。工場から販売店への輸送リードタ  
イムは0とする。販売店には一定速度で需要が到着している。下図は、販売店が一  
定量を定期的に発注している場合の、工場での累積生産量、工場在庫量、工場から  
販売店への累積配送量、販売店在庫量の時間推移を示している。  

このうち、工場在庫量、累積配送量、販売店在庫量の3つの量の推移を示す線の  
組み合わせとして、最も適切なものを下記の解答群から選べ。  

<imgTag>104270130_1.png</imgTag>  

[解答群]  
  
ア  工場在庫量：①　累積配送量：②　販売店在庫量：④  
  
イ  工場在庫量：①　累積配送量：④　販売店在庫量：②  
  
ウ  工場在庫量：②　累積配送量：③　販売店在庫量：④  
  
エ  工場在庫量：②　累積配送量：④　販売店在庫量：③  
        
`
        },
        {
                "mondaiId": 104270150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
標準時間の算定に関する記述として、最も適切なものはどれか。  
  
ア  PTS法で標準時間を算定する際には、レイティングの操作をする必要がない。  
  
イ  観測作業の速度が基準とする作業ペースより速いとき、レイティング係数の値  
は100より小さく設定される。  
  
ウ  正味時間は、観測時間に余裕率を掛けることで算定される。  
  
エ  標準時間は、正味時間と付帯作業時間から構成される。  
        
`
        },
        {
                "mondaiId": 104270160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
複数個の同一製品を、同じ機能を持った設備AまたはBを利用して加工処理し  
ている工程がある。この工程には1名の作業者がおり、次のような手順で製品を加  
工処理している。  

＜製品の処理手順＞  
1．手空きになっている;加工処理が行われていないIいずれかの設備に、作業  
者によって製品がセットされる。セットには5秒かかる。  
        
2．セットされた製品は、直ちに設備で自動的に加工処理される。加工処理に  
は20秒かかる。  

3．加工が終わった製品は、作業者によって設備から取り出される。製品の取  
り出しには5秒かかる。  

4．取り出された製品は、作業者によって検査が行われて製品の処理が完了す  
る。検査には5秒かかる。  

次ページの表は、この工程で4つの製品が処理される過程を連合作業分析した結  
果である。この作業において90秒の総作業時間を短縮するために、連合作業分析  
表の1列目;作業者の欄Iに示された作業番号④、⑤、⑥、⑦、⑧の作業を⑦→⑤→⑧→④→⑥の順序に変更することを考える。この変更によって短縮される時間の最  
大値として、最も適切なものを次ページの解答群から選べ。なお、作業者と設備  
A、Bのそれぞれは、同時に複数個の製品を処理することはできない。  
        
        
連合作業分析表  
<imgTag>104270160_1.png</imgTag>  



[解答群]  
  
ア  5秒  
  
イ  10秒  
  
ウ  15秒  
  
エ  20秒  
        
`
        },
        {
                "mondaiId": 104270170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
先入先出法で製品の入出庫を行う倉庫において、始業(8時)から終業(17時)ま  
での期間で流動数分析を実施した。下図は、この観測期間内での入出庫の結果を流  
動数グラフにまとめたものである。この流動数グラフの分析結果として、最も適切  
なものを下記の解答群から選べ。  

<imgTag>104270170_1.png</imgTag>  

[解答群]  
  
ア  最大在庫量は、6個である。  
  
イ  終業直後の倉庫内在庫量は、3個である。  
  
ウ  製品の平均在庫量は、3(個／時間)である。  
  
エ  製品の平均滞留時間は、4(時間／個)である。  
        
`
        },
        {
                "mondaiId": 104270180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
保全活動に関する記述として、最も適切なものはどれか。  
  
ア  改良保全は、設備故障の発生から修復までの時間を短縮する活動である。  
  
イ  保全活動は、予防保全、改良保全、保全予防の3つに分けられる。  
  
ウ  保全予防は、設備の計画・設計段階から、過去の保全実績等の情報を用いて不  
良や故障に関する事項を予測し、これらを排除するための対策を織り込む活動で  
ある。  
  
エ  予防保全は、定期保全と集中保全の2つに分けられる。`
        },
        {
                "mondaiId": 104270190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
作業改善の際に利用される｢5W1Hの原則｣は、Why以外の疑問詞とWhyを  
組み合わせて作業への問い掛けを行うものである。問い掛けを合理的に行っていく  
ための実施手順として、<u>最も不適切なものはどれか</u>。  
  
ア  ｢What？Why？｣の問い掛けの後に、｢How？Why？｣の問い掛けを実施し  
た。  
  
イ  ｢Where？Why？｣の問い掛けの後に、｢Who？Why？｣の問い掛けを実施し  
た。  
  
ウ  ｢When？Why？｣の問い掛けの後に、｢Where？Why？｣の問い掛けを実施し  
た。  
  
エ  ｢Who？Why？｣の問い掛けの後に、｢What？Why？｣の問い掛けを実施し  
た。  
        
`
        },
        {
                "mondaiId": 104270200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢動作経済の原則｣に関する記述として、最も適切なものはどれか。  
  
ア  下図の斜線部分は、目の動きを伴わずに両手を同時に動かしやすい領域を示し  
ている。  
<imgTag>104270200_1.png</imgTag>  

イ  手の動作の時間・努力・疲労の程度を表す動作等級は、指・手首・前腕・上  
腕・肩の観点から5つに分かれている。  
  
ウ  照明に関する指摘は、｢動作経済の原則｣には含まれていない。  
  
エ  両手を同方向に同時に動かす動作は、｢動作経済の原則｣にかなっている。  
        
`
        },
        {
                "mondaiId": 104270210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢資源の有効な利用の促進に関する法律;資源有効利用促進法I｣のもとで、以下に  
示された製品と、製品の製造事業者に求められる内容の組み合わせとして、最も適  
切なものを下記の解答群から選べ。  

＜製品＞  
①自動車、金属製家具  
②スチール製の缶、ペットボトル  
③パソコン、小形二次電池  

＜製造事業者に求められる内容＞  
ａ再生資源または再生部品の利用の促進に取り組むことが求められる。  
ｂ分別回収の促進のための表示を行うことが求められる。  
ｃ自主回収および再資源化に取り組むことが求められる。  

[解答群]  
  
ア  ①：ａ②：ｂ③：ｃ  
  
イ  ①：ａ②：ｃ③：ｂ  
  
ウ  ①：ｂ②：ａ③：ｃ  
  
エ  ①：ｃ②：ｂ③：ａ  
        
`
        },
        {
                "mondaiId": 104270220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
総合衛生管理製造過程の承認制度は、HACCP(Hazard Analysis and Critical  
Control Point)の概念を取り入れている。この総合衛生管理製造過程の承認制度と  
HACCPに関する記述として、<u>最も不適切なものはどれか</u>。  
  
<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】HACCPのの１２手順  
<img src="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/images/haccptejun.png"/>
<a href="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/haccpgimuka.html" target="_blank">  
(外部リンクを開きます　千葉市：HACCP(ハサップ)に沿った衛生管理)</a>  
</div>
</hint>

ア  HACCPでは、最終出荷製品の抜取検査を行った結果から重点管理すべき<hintTarget>製品</hintTarget>  
を特定して衛生管理が行われる。  
  
<hint hidden='true'>**
❌製品の品質管理のように、抜き取り検査から重点管理すべき製品を特定する制度ではありません。  
上図手順１－５のように現状把握をして、手順６，７で分析し重要管理点を決定するなど、危害要因を管理して安全性を確保します。
**</hint>

イ  HACCPの7原則の中には、｢危害分析｣と｢管理基準の設定｣が含まれる。  
  
ウ  総合衛生管理製造過程の承認対象となる食品は、乳・乳製品、食肉製品、魚肉  
練り製品、容器包装詰加圧加熱殺菌食品、清涼飲料水の5つである。  
  
エ  総合衛生管理製造過程の承認を受けた施設であっても、食品衛生管理者を置く  
ことが義務づけられる。`
        },
        {
                "mondaiId": 104270230,
                "name": "第23問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
都市計画法および建築基準法による用途地域に関する説明として、最も適切なも  
のはどれか。  
  
ア  床面積が1，000m2の店舗の場合、第一種低層住居専用地域に出店することが  
できる。  
  
イ  床面積が2，000m2の店舗の場合、第二種中高層住居専用地域に出店すること  
ができる。  
  
ウ  床面積が5，000m2の店舗の場合、第一種住居地域に出店することができる。  
  
エ  床面積が12，000m2の店舗の場合、準住居地域に出店することができる。  
  
オ  床面積が15，000m2の店舗の場合、近隣商業地域に出店することができる。  
        
`
        },
        {
                "mondaiId": 104270240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
建築基準法の一部を改正する法律(平成26年法律第54号)により改正された建築  
基準法に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  建築物におけるエレベーター事故や災害等が発生した場合の国および特定行政  
庁の調査体制が強化された。  
  
イ  工事中の建築物の仮使用について、一定の安全上の要件を満たす場合、指定確  
認検査機関が認めたときは仮使用できるようになった。  
  
ウ  住宅の容積率の算定に当たり地下室の床面積を延べ面積に算入しない特例を、  
新たに店舗専用の建築物にも適用できるようになった。  
  
エ  本改正前の建築基準では対応できない新建築材料や新技術について、国土交通  
大臣の認定制度を創設できるようになった。`
        },
        {
                "mondaiId": 104270250,
                "name": "第25問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
店舗施設の売場を演出する照明の説明に関して、次の文中の空欄Ａ~Ｃに入る語  
句として、最も適切なものの組み合わせを下記の解答群から選べ。  

照明光の性質を知る代表的なものとして、光源に照らされた明るさを表す  
Ａ、光源の色みを表すＢがある。また、光で照明された物体の色  
の見え方をＣという。  

[解答群]  
  
ア  Ａ：演色Ｂ：光色Ｃ：照度  
  
イ  Ａ：光色Ｂ：演色Ｃ：照度  
  
ウ  Ａ：光色Ｂ：照度Ｃ：演色  
  
エ  Ａ：照度Ｂ：演色Ｃ：光色  
  
オ  Ａ：照度Ｂ：光色Ｃ：演色  
        
`
        },
        {
                "mondaiId": 104270260,
                "name": "第26問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
店舗施設の案内表示や店の看板、売場を演出する色彩の説明に関して、次の文中  
の空欄Ａ~Ｃに入る語句として、最も適切なものの組み合わせを下記の解答群から  
選べ。  

周囲の環境の中から特に目を引く効果をＡという。店舗施設における注  
意や禁止、危険なものを伝達しなければならない情報の視覚表示においては、これ  
を高めることが望ましい。  

案内表示などには、文字や数字を理解しやすくするためのＢと、図形の  
細部を知覚しやすくするためのＣを高めることが要求される。  

[解答群]  
  
ア  Ａ：可読性Ｂ：誘目性Ｃ：明視性  
  
イ  Ａ：視認性Ｂ：可読性Ｃ：誘目性  
  
ウ  Ａ：誘目性Ｂ：視認性Ｃ：可読性  
  
エ  Ａ：誘目性Ｂ：可読性Ｃ：明視性  
  
オ  Ａ：誘目性Ｂ：明視性Ｃ：視認性  
        
`
        },
        {
                "mondaiId": 104270270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
一般社団法人日本ショッピングセンター協会による｢SC年間販売統計調査報告  
2014年｣のデータから確認できるショッピングセンター;SCIの実態に関する記述  
として、最も適切なものはどれか。  

なお、立地については、以下のように定義されている。  

中心地域：当該市・町・村の商業機能が集積した中心市街地  

周辺地域：中心地域に隣接した商業・行政・ビジネス等の都市機能が適度に存  
在する地域  
郊外地域：都市郊外で住宅地・農地等が展開されている地域  
  
ア  関東にある郊外地域のSCの対前年の売上高伸長率は、年間ベースでマイナス  
であった。  
  
イ  既存SC(総合)は、消費税増税にもかかわらず、2014年4月の対前年同月の売  
上高伸長率はプラスであった。  
  
ウ  九州・沖縄にある中心地域と周辺地域のSCの対前年の売上高伸長率は、年間  
ベースでプラスであった。  
  
エ  東北にある中心地域のSCの対前年の売上高伸長率は、年間ベースでプラスで  
あった。  
        
`
        },
        {
                "mondaiId": 104270280,
                "name": "第28問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売業の販売価格決定に関する次の文中の空欄AとBに入る数値として、最も  
適切なものの組み合わせを下記の解答群から選べ。ただし、消費税は考慮しないも  
のとする。  

仕入単価700円の商品Xを売価値入率30％で価格設定した。このときの商品  
Xの販売価格はＡ円である。しばらくすると、この商品Xの売れ行きが  
悪くなってきたため、商品Xを3個で1セットとして、1セットの販売価格を  
2，500円に設定した。この商品Xのセットの売価値入率はＢ％である。  

[解答群]  
  
ア  Ａ：910　Ｂ：16  
  
イ  Ａ：910　Ｂ：20  
  
ウ  Ａ：1，000　Ｂ：16  
  
エ  Ａ：1，000　Ｂ：20  
        
`
        },
        {
                "mondaiId": 104270290,
                "name": "第29問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売業の販売方法に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  慣習価格を崩さずに商品の容量を減らすことは、顧客生涯価値を高めるのに役  
立つ。  
  
イ  クーポンを発行して、レジで商品の価格を割り引いて販売することは、単なる  
値引きと比べて消費者の内的参照価格の低下を防ぐのに役立つ。  
  
ウ  シーズン性のある商品をそのシーズンの終わりに価格を割り引いて販売するこ  
とは、商品在庫の削減に役立つ。  
  
エ  商品の価格を変更せずに容量を増やして販売することは、割安感を演出するの  
に役立つ。  
  
オ  値引き後の販売価格と値引き前の通常販売価格を二重価格表示することは、割  
安感を演出するのに役立つ。`
        },
        {
                "mondaiId": 104270300,
                "name": "第30問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店舗において客の動線長をのばすための施策として、最も適切なものはどれ  
か。  
  
ア  計画購買率の高い商品を店舗の奥に配置する。  
  
イ  ゴールデンラインを複数設置する。  
  
ウ  チラシ掲載の特売商品を店舗の入口付近に配置する。  
  
エ  パワーカテゴリーを集中配置する。  
        
`
        },
        {
                "mondaiId": 104270310,
                "name": "第31問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
イ  ンストアプロモーション(ISP)における目的とその施策に関する記述として、  
<u>最も不適切なものはどれか</u>。  
  
ア  売れ筋商品の利益率を向上させるために、価格プロモーションを実施する。  
  
イ  買上率を向上させるために、POPによって商品の価値を理解しやすくする。  
  
ウ  関連商品の同時購買を促進するために、クロスMDを実施する。  
  
エ  商品の魅力や機能を伝えるために、サンプリングを実施する。  
        
`
        },
        {
                "mondaiId": 104270320,
                "name": "第32問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商品予算計画に関する以下の用語とその算出方法の組み合わせとして、最も適切  
なものを下記の解答群から選べ。  

＜用語＞  
①GMROI  
②交差主義比率  
③商品回転率  

＜算出方法＞  
ａ　粗利益　÷　平均商品在庫高;原価I  
ｂ　粗利益率　×　商品回転率  
ｃ　年間売上高　÷　平均商品在庫高;売価I  

[解答群]  
  
ア  ①：ａ　②：ｂ　③：ｃ  
  
イ  ①：ａ　②：ｃ　③：ｂ  
  
ウ  ①：ｂ　②：ａ　③：ｃ  
  
エ  ①：ｂ　②：ｃ　③：ａ  
  
オ  ①：ｃ　②：ａ　③：ｂ  
        
`
        },
        {
                "mondaiId": 104270330,
                "name": "第33問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネット通信販売に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  インターネットショッピングモールで販売する場合は、消費者が他店との価格  
比較を容易にできるため、他店との価格競争にさらされやすい。  
  
イ  売れ筋の商品を中心に品揃えすることによって、いわゆるロングテールを形成  
することができる。  
  
ウ  実店舗よりも商圏を広くすることができる。  
  
エ  資本金が少ない企業でも、実店舗を持たずにウェブサイトを通じて商品を販売  
することができる。`
        },
        {
                "mondaiId": 104270340,
                "name": "第34問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センター内のエリア管理に関する記述として、最も適切なものはどれか。  
  
ア  エリアごとに作業員を決める固定ロケーション管理は、人別の業務評価がしや  
すい。  
  
イ  固定ロケーション管理は、在庫量を一定に保ちやすい。  
  
ウ  フリーロケーション管理では、保管スペースの有効活用がしやすい。  
  
エ  フリーロケーション管理は、固定ロケーション管理よりも人によるピッキング  
作業の効率がよい。  
  
オ  ロケーション管理では、物流センター内の人員配置を適切にマネジメントする  
ことが重要である。  
        
`
        },
        {
                "mondaiId": 104270350,
                "name": "第35問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
店舗における商品の在庫管理に関する記述として、最も適切なものはどれか。  
  
ア  売場の棚に陳列する商品のフェイス数は、均一にした方が補充頻度を一定にし  
やすい。  
  
イ  需要予測を実需が上回った場合、過剰在庫が発生しやすい。  
  
ウ  店舗では、リードタイム期間の平均需要量を発注点とすると、欠品を起こすこ  
とがない。  
  
エ  発注から納品までのリードタイムを短くすると、発注点を低くできる。  
  
オ  万引き等によるロスがあると、自動発注時の発注量が増加する。`
        },
        {
                "mondaiId": 104270360,
                "name": "第36問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
チェーン小売業の物流に関する記述として、最も適切なものはどれか。  
  
ア  DC(Distribution Center)では在庫を持たず、複数店舗へ納入する商品を一括  
して納入業者から受け取り、店舗別に仕分けして出荷する。  
  
イ  カテゴリー納品は、カテゴリーごとに適切な配送スケジュールを組み、物流セ  
ンターから店舗へ納品することをいう。  
  
ウ  小売店舗への共同配送は、店舗の人時生産性向上につながる。  
  
エ  専用物流センターを持つ小売業と納入業者の取引価格設定は、原則として物流  
センターまで届けることを前提に設定されている。  
  
オ  多頻度小口配送は、車両積載効率を向上させコスト削減につながる。  
        
`
        },
        {
                "mondaiId": 104270370,
                "name": "第37問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センターのコスト削減を目的にアクティビティごとの作業時間分析を行い、  
下表を作成した。  


<imgTag>104270370_1.png</imgTag>  


この分析結果から、以下に示す4つのアクティビティのうち、物流コスト削減余  
地が｢最も大きなアクティビティ｣と｢2番目に大きなアクティビティ｣の組み合わせ  
として、最も適切なものを下記の解答群から選べ。  

ａ　ケースピッキング  
ｂ　ピースピッキング  
ｃ　段ボール箱梱包  
ｄ　行き先別仕分け  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｃ  
  
オ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 104270380,
                "name": "第38問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センターに関する記述として、最も適切なものはどれか。  
  
ア  3PL(ThirdPartyLogistics)は、荷主企業の物流業務を受託する企業である  
が、物流センターを保有していないこともある。  
  
イ  VMI(VendorManagedInventory)は、物流センターの在庫情報を卸売業者が  
小売業者に提供するシステムである。  
  
ウ  WMS(WarehouseManagementSystem)は、物流センター内の生産性向上を  
目的として人員最適化に用いられるシステムである。  
  
エ  物流センターでは、利用者の求めに応じて保管の役割のほか、値付けや包装な  
どを行うことがあり、これを荷役という。`
        },
        {
                "mondaiId": 104270390,
                "name": "第39問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
JANコード(標準タイプ13桁)GTIN-13におけるチェックデジットに関する記  
述として、最も適切なものはどれか。  
  
ア  一番右の1桁がチェックデジットと呼ばれる。  
  
イ  一番左の1桁がチェックデジットと呼ばれる。  
  
ウ  チェックデジット以外のすべての桁の数字をひとつの数字とみなして2乗した  
値の下1桁をチェックデジットの値とする。  
  
エ  チェックデジット以外のすべての桁の数値の積の下1桁をチェックデジットの  
値とする。  
  
オ  左から7桁目の数字がチェックデジットと呼ばれる。  
        
`
        },
        {
                "mondaiId": 104270400,
                "name": "第40問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
RFM分析のうち、Rを除いてFM分析を行うとする。以下のような顧客  
001~006が存在し、Fは平均来店購買間隔(単位：日)、Mは総購買金額(単位：万  
円)で評価するものとし、以下のような計算結果が得られているとする。件数を均  
等に分割する方法でそれぞれFとMを上位と下位に分割するとき(ただし同点が  
発生した場合、上位に属するものとする)、F上位かつM上位となる顧客の人数と  
して最も適切なものを下記の解答群から選べ。  

(計算結果)
　　　　　Ｆ　　Ｍ
顧客001　6.9　7.3    
顧客002　9.3　4.7  
顧客003　3.9　7.5    
顧客004　1.7　0.4    
顧客005　6.1　1.9    
顧客006　3.2　4.7    

[解答群]  
  
ア  0人  
  
イ  1人  
  
ウ  2人  
  
エ  3人  
  
オ  4人  
        
`
        },
        {
                "mondaiId": 104270410,
                "name": "第41問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
品揃えなどで用いられるABC分析に関する記述として、最も適切なものはどれ  
か。  
  
ア  2つの数値属性を持つ特徴量の間の関連性を数値で表現する分析方法である。  
  
イ  顧客を購買金額で10等分し、それぞれのグループの特徴などを分析する方法  
である。  
  
ウ  商品Xと商品Yを購買する顧客は、商品Zも購買するというような関連性を  
発見する分析方法である。  
  
エ  商品の売上を降順にソートし、その累積比率を利用してグループ分けする分析  
方法である。  
        
`
        },
        {
                "mondaiId": 104270420,
                "name": "第42問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある小売店舗のPOSデータからクーポンに対する反応予測モデルを作成して、  
｢反応｣と予測される顧客グループにクーポンを配布したとすれば、検証データに対  
して以下のような反応結果が得られるものとする。このとき、結果の解釈として最  
も適切なものを下記の解答群から選べ。ただし、全顧客数は100人であるとする。  


<imgTag>104270420_1.png</imgTag>  


[解答群]  
  
ア  この予測結果の正答率(accuracy)は1/2である。  
  
イ  この予測結果の｢反応｣に対する再現率(recallI)は2/3である。  
  
ウ  この予測結果の｢反応｣に対する精度(precision)は4/7である。  
  
エ  この予測モデルで｢反応｣と予測された顧客にのみクーポンを配布したほう  
が、全顧客に配布するより反応効率がよい。  
        
`
        },
        {
                "mondaiId": 104270431,
                "name": "第43問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢食品トレーサビリティシステム導入の手引き(第2版)｣;(食品トレーサビリティガイドライン)に記載されている内容に関して、以下の設問に答えよ。  

（設問1）  
日本の国内法のうち、国内流通における食品トレーサビリティシステムに関係  
する法律として上記手引きに列挙されている17の法律に<u>該当しないものはどれか</u>。  
  
ア  牛海綿状脳症対策特別措置法  
  
イ  健康増進法  
  
ウ  食品リサイクル法  
  
エ  製造物責任法  
  
オ  薬事法`
        },
        {
                "mondaiId": 104270432,
                "name": "第43問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
｢食品トレーサビリティシステム導入の手引き(第2版)｣;(食品トレーサビリティガイドライン)に記載されている内容に関して、以下の設問に答えよ。  

（設問2)

トレーサビリティシステムにおいて製品に添付して送られる情報の表現様式や  
情報伝達媒体として、バーコード、2次元コード、電子タグ(ICタグ)を利用し  
て情報伝達を行う場合を比較した記述として、最も適切なものはどれか。  
  
ア  作成コストは、電子タグが最も安価である。  
  
イ  情報記録容量は、バーコードが最も大きい。  
  
ウ  データの再書き込みは、電子タグを利用した場合のみ可能である。  
  
エ  透過読み取りは、どの方法を利用しても可能である。  
  
オ  複製を作成する場合、2次元コードが最も困難である。  
`
        },
        {
                "mondaiId": 105270010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の者のうち、X株式会社において、社外取締役の要件を<u>満たさない者</u>はど  
れか。なお、経過規定については考慮しないものとする。  
  
ア  15年前まで、X株式会社に勤務していた者  
  
イ  X株式会社の親会社の業務執行取締役  
  
ウ  X株式会社の業務執行取締役の甥  
  
エ  X株式会社の主要な取引先の業務執行取締役  
        
`
        },
        {
                "mondaiId": 105270021,
                "name": "第2問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
自己株式の取得に関する以下の会話は、中小企業診断士であるあなたとX株式  
会社(以下｢X社｣という。)の総務部門の担当者である甲氏との間で行われたもので  
ある。この会話を読んで、下記の設問に答えよ。なお、X社は、公開会社ではな  
く、取締役会設置会社であるとする。また、定款に特段の定めもないものとする。  

甲氏：｢今、有償での自己株式の取得を検討しているのですが、手続について教  
えてもらえませんか。株主総会の決議が必要なのは分かっているのです  
が。｣  

あなた：｢株主との合意によりX社の株式を取得するということですよね。有償で  
自己株式を取得する場合、取得対価の帳簿価格の総額がＡを超え  
てはいけないことになっているのですが、その点は大丈夫ですか。｣  

甲氏：｢はい。それは既に確認しているので大丈夫です。｣  
あなた：｢よかったです。では、手続ですが、株主全員に譲渡の勧誘をする方法(①  
の方法)と特定の株主から取得する方法(②の方法)の2つがあります。②  
の方法では、特定の株主だけから株式を取得するので、その株主の氏名を  
株主総会で決議する必要があります。ただ、②の方法の場合、他の株主  
は、自己を取得の相手に加えるように請求することができます。｣  

甲氏：｢なるほど。2つの方法で株主総会の招集手続に違いはありますか。｣  

あなた：｢書面又は電磁的方法による議決権行使を認めないことを前提とすると、  
総会の日のＢ前までに招集通知を発送しなければならないのは、  
①の方法でも②の方法でも変わらないのですが、②の方法の場合、自己を  
取得の相手に加える旨の請求を行う機会を与えるために、その請求ができ  
ることを招集通知の発送期限までに株主に通知しなければなりません。こ  
の通知と招集通知を兼ねるとすると、②の方法の場合の方が、①の方法の  
場合よりも早く招集通知を発送しなければならないことになります。｣  

甲氏：｢決議要件はどうでしょうか。｣  

あなた：｢Ｃ｣  

甲氏：｢なるほど。ありがとうございました。｣  
        
あなた：｢今回の場合にどちらの手続が具体的に良いのかは、専門家にきちんと相  
談した方がいいと思います。顧問弁護士の先生に連絡を取ってみてはどう  
でしょうか。｣  

（設問1）  
会話の中の空欄Ａに入る語句として最も適切なものはどれか。  
  
ア  資本金の額  
  
イ  資本準備金の額  
  
ウ  投資有価証券の額  
  
エ  分配可能額`
        },
        {
                "mondaiId": 105270022,
                "name": "第2問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
自己株式の取得に関する以下の会話は、中小企業診断士であるあなたとX株式  
会社(以下｢X社｣という。)の総務部門の担当者である甲氏との間で行われたもので  
ある。この会話を読んで、下記の設問に答えよ。なお、X社は、公開会社ではな  
く、取締役会設置会社であるとする。また、定款に特段の定めもないものとする。  

甲氏：｢今、有償での自己株式の取得を検討しているのですが、手続について教  
えてもらえませんか。株主総会の決議が必要なのは分かっているのです  
が。｣  

あなた：｢株主との合意によりX社の株式を取得するということですよね。有償で  
自己株式を取得する場合、取得対価の帳簿価格の総額がＡを超え  
てはいけないことになっているのですが、その点は大丈夫ですか。｣  

甲氏：｢はい。それは既に確認しているので大丈夫です。｣  
あなた：｢よかったです。では、手続ですが、株主全員に譲渡の勧誘をする方法(①  
の方法)と特定の株主から取得する方法(②の方法)の2つがあります。②  
の方法では、特定の株主だけから株式を取得するので、その株主の氏名を  
株主総会で決議する必要があります。ただ、②の方法の場合、他の株主  
は、自己を取得の相手に加えるように請求することができます。｣  

甲氏：｢なるほど。2つの方法で株主総会の招集手続に違いはありますか。｣  

あなた：｢書面又は電磁的方法による議決権行使を認めないことを前提とすると、  
総会の日のＢ前までに招集通知を発送しなければならないのは、  
①の方法でも②の方法でも変わらないのですが、②の方法の場合、自己を  
取得の相手に加える旨の請求を行う機会を与えるために、その請求ができ  
ることを招集通知の発送期限までに株主に通知しなければなりません。こ  
の通知と招集通知を兼ねるとすると、②の方法の場合の方が、①の方法の  
場合よりも早く招集通知を発送しなければならないことになります。｣  

甲氏：｢決議要件はどうでしょうか。｣  

あなた：｢Ｃ｣  

甲氏：｢なるほど。ありがとうございました。｣  
        
あなた：｢今回の場合にどちらの手続が具体的に良いのかは、専門家にきちんと相  
談した方がいいと思います。顧問弁護士の先生に連絡を取ってみてはどう  
でしょうか。｣  

（設問2)

会話の中の空欄Ｂに入る期間として最も適切なものはどれか。  
  
ア  5日  
  
イ  1週間  
  
ウ  2週間  
  
エ  1か月`
        },
        {
                "mondaiId": 105270023,
                "name": "第2問設問3",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
                
自己株式の取得に関する以下の会話は、中小企業診断士であるあなたとX株式  
会社(以下｢X社｣という。)の総務部門の担当者である甲氏との間で行われたもので  
ある。この会話を読んで、下記の設問に答えよ。なお、X社は、公開会社ではな  
く、取締役会設置会社であるとする。また、定款に特段の定めもないものとする。  

甲氏：｢今、有償での自己株式の取得を検討しているのですが、手続について教  
えてもらえませんか。株主総会の決議が必要なのは分かっているのです  
が。｣  

あなた：｢株主との合意によりX社の株式を取得するということですよね。有償で  
自己株式を取得する場合、取得対価の帳簿価格の総額がＡを超え  
てはいけないことになっているのですが、その点は大丈夫ですか。｣  

甲氏：｢はい。それは既に確認しているので大丈夫です。｣  
あなた：｢よかったです。では、手続ですが、株主全員に譲渡の勧誘をする方法(①  
の方法)と特定の株主から取得する方法(②の方法)の2つがあります。②  
の方法では、特定の株主だけから株式を取得するので、その株主の氏名を  
株主総会で決議する必要があります。ただ、②の方法の場合、他の株主  
は、自己を取得の相手に加えるように請求することができます。｣  

甲氏：｢なるほど。2つの方法で株主総会の招集手続に違いはありますか。｣  

あなた：｢書面又は電磁的方法による議決権行使を認めないことを前提とすると、  
総会の日のＢ前までに招集通知を発送しなければならないのは、  
①の方法でも②の方法でも変わらないのですが、②の方法の場合、自己を  
取得の相手に加える旨の請求を行う機会を与えるために、その請求ができ  
ることを招集通知の発送期限までに株主に通知しなければなりません。こ  
の通知と招集通知を兼ねるとすると、②の方法の場合の方が、①の方法の  
場合よりも早く招集通知を発送しなければならないことになります。｣  

甲氏：｢決議要件はどうでしょうか。｣  

あなた：｢Ｃ｣  

甲氏：｢なるほど。ありがとうございました。｣  
        
あなた：｢今回の場合にどちらの手続が具体的に良いのかは、専門家にきちんと相  
談した方がいいと思います。顧問弁護士の先生に連絡を取ってみてはどう  
でしょうか。｣  

（設問3)

会話の中の空欄Ｃに入る記述として最も適切なものはどれか。  
  
ア  ①の方法の場合でも②の方法の場合でも特別決議です。  
  
イ  ①の方法の場合でも②の方法の場合でも普通決議です。  
  
ウ  ①の方法の場合には特別決議ですが、②の方法の場合には普通決議です。  
  
エ  ①の方法の場合には普通決議ですが、②の方法の場合には特別決議です。  
        
`
        },
        {
                "mondaiId": 105270031,
                "name": "第3問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたとX株式会社(以下｢X社｣という。)  
の代表取締役甲氏との間で行われたものである。この会話を読んで、下記の設問に  
答えよ。  

甲氏：｢今度、当社で開発した新製品aの販売を計画しています。そこで、卸売  
業者との間で締結する継続的な売買基本契約の内容を検討しています。ち  
ょっと見てもらってもいいですか。売主が当社で、買主が卸売業者になり  
ます。｣  

あなた：｢分かりました。あれ、g買主は、売主が指定した価格で商品を小売業者に  
転売するものとする。jという条項が定められていますね。｣  

甲氏：｢何か問題がありますか。｣  

あなた：｢こうした条項を定めることは、Ａのうちの再販売価格の拘束に  
当たり、独占禁止法上、原則として違法となるとされていたはずです。｣  
甲氏：｢そうなんですか。｣  

あなた：｢ええ。他にも、卸売業者に対して、aと競合する商品の購入を禁止した  
り、X社が事前に同意していない小売業者への転売を禁止したりすると、  
Ａに該当する可能性があります。｣  
甲氏：｢知りませんでした。Ａに該当するとどのような処分を受けるの  
ですか。｣  

あなた：｢例えば、公正取引委員会からＢを受ける場合があります。ただ、  
Ａに該当する可能性のある条項でも場合によっては定めることが  
できたと思います。詳しいことは弁護士の先生に相談してみてはどうでし  
ょうか。｣  
        
（設問1）  
会話の中の空欄Ａに入る語句として最も適切なものはどれか。  
  
ア  抱き合わせ販売  
  
イ  不公正な取引方法  
  
ウ  不当な取引制限  
  
エ  優越的地位の濫用`
        },
        {
                "mondaiId": 105270032,
                "name": "第3問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
以下の会話は、中小企業診断士であるあなたとX株式会社(以下｢X社｣という。)  
の代表取締役甲氏との間で行われたものである。この会話を読んで、下記の設問に  
答えよ。  

甲氏：｢今度、当社で開発した新製品aの販売を計画しています。そこで、卸売  
業者との間で締結する継続的な売買基本契約の内容を検討しています。ち  
ょっと見てもらってもいいですか。売主が当社で、買主が卸売業者になり  
ます。｣  

あなた：｢分かりました。あれ、g買主は、売主が指定した価格で商品を小売業者に  
転売するものとする。jという条項が定められていますね。｣  

甲氏：｢何か問題がありますか。｣  

あなた：｢こうした条項を定めることは、Ａのうちの再販売価格の拘束に  
当たり、独占禁止法上、原則として違法となるとされていたはずです。｣  
甲氏：｢そうなんですか。｣  

あなた：｢ええ。他にも、卸売業者に対して、aと競合する商品の購入を禁止した  
り、X社が事前に同意していない小売業者への転売を禁止したりすると、  
Ａに該当する可能性があります。｣  
甲氏：｢知りませんでした。Ａに該当するとどのような処分を受けるの  
ですか。｣  

あなた：｢例えば、公正取引委員会からＢを受ける場合があります。ただ、  
Ａに該当する可能性のある条項でも場合によっては定めることが  
できたと思います。詳しいことは弁護士の先生に相談してみてはどうでし  
ょうか。｣  
        
（設問2)

会話の中の空欄Ｂに入る語句として、<u>最も不適切なものはどれか</u>。  
  
ア  課徴金納付命令  
  
イ  警告  
  
ウ  排除措置命令  
  
エ  罰金刑  
        
`
        },
        {
                "mondaiId": 105270040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
一定の取引分野における競争を実質的に制限することになる、株式の取得、合  
併、吸収分割、共同株式移転、事業譲受けといった企業結合は、独占禁止法により  
禁止されている。この点、企業結合後のハーフィンダール・ハーシュマン指数(以  
下｢HHI｣という。)が次の①から③のいずれかに該当する場合には、通常、独占禁止  
法に違反しないと考えられている。この①から③の規準を満たす領域のことを｢セ  
ーフハーバー｣と呼ぶ。  

HHIは、一定の取引分野における各事業者の市場シェア(百分率で示される。)を  
それぞれ2乗し、2乗された市場シェアを合計することによって算出される。  

①企業結合後のHHIが1，500以下である場合  

②企業結合後のHHIが1，500超2，500以下であって、かつ、結合後のHHIか  
ら結合前のHHIを控除した数値が250以下である場合  

③企業結合後のHHIが2，500を超え、かつ、結合後のHHIから結合前のHHI  
を控除した数値が150以下である場合  

企業結合前の一定の取引分野における各事業者の市場シェアについては、次の(1)  
と(2)の2つの場合があるとして、セーフハーバーを<u>満たさないものを下記の解答群  
から選べ</u>。  

(1)企業結合前の一定の取引分野における各事業者の市場シェアが、A社  
30％、B社25％、C社20％、D社10％、E社3％、F社6％の場合。  

(2)企業結合前の一定の取引分野における各事業者の市場シェアが、A社  
40％、B社30％、C社12％、D社10％、E社5％、F社3％の場合。  
        
        
[解答群]  
  
ア  (1)の場合において、C社とE社が企業結合を行い、結合後の市場シェアが  
A社30％、C＋E社29％、B社25％、D社10％、F社6％となるとき。  
  
イ  (1)の場合において、D社とE社が企業結合を行い、結合後の市場シェアが  
A社30％、B社25％、C社20％、D＋E社19％、F社6％となるとき。  
  
ウ  (2)の場合において、C社とE社が企業結合を行い、結合後の市場シェアが  
A社40％、B社30％、C＋E社17％、D社10％、F社3％となるとき。  
  
エ  (2)の場合において、D社とE社が企業結合を行い、結合後の市場シェアが  
A社40％、B社30％、D＋E社15％、C社12％、F社3％となるとき。`
        },
        {
                "mondaiId": 105270050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業における経営の承継の円滑化に関する法律に定められた遺留分に関する  
民法の特例に関する記述として、最も適切なものはどれか。  
  
ア  後継者が旧代表者から贈与により取得した財産のうち、一部を除外合意の対象  
とし、残りの一部を固定合意の対象とすることができる。  
  
イ  除外合意や固定合意の効力を生じさせるためには、経済産業大臣の許可を受け  
る必要がある。  
  
ウ  除外合意や固定合意の効力を生じさせるためには、後継者以外の旧代表者の推  
定相続人も家庭裁判所の許可を受ける必要がある。  
  
エ  除外合意や固定合意の対象となる株式を除いた後継者が所有する株式に係る議  
決権の数が総株主の議決権の50％を超える場合であっても、除外合意や固定合  
意をすることができる。  
        
`
        },
        {
                "mondaiId": 105270060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の記述は、ある条約に関するものである。この内容を定める条約として、最  
も適切なものを下記の解答群から選べ。  

1883年に成立したこの条約が適用される国は、工業所有権の保護のための同盟  
を形成する。各同盟国の国民は、工業所有権の保護に関し、この条約で特に定める  
権利を害されることなく、他のすべての同盟国において、当該他の同盟国の法令が  
内国民に対し現在与えており又は将来与えることがある利益を享受する。すなわ  
ち、同盟国の国民は、内国民に課される条件及び手続に従う限り、内国民と同一の  
保護を受け、かつ、自己の権利の侵害に対し内国民と同一の法律上の救済を与えら  
れる。  
[解答群]  
  
ア  シンガポール条約  
  
イ  特許協力条約  
  
ウ  パリ条約  
  
エ  マドリッド協定  
        
`
        },
        {
                "mondaiId": 105270070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の文章は、著作権法の解説である。空欄Ａ~Ｄに入る語句の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  

作家Xが文芸作品を制作した場合、その作品の著作権はＡの時に発生  
し、保護期間は、Ｂである。また、その作品を原作として映画などの二次  
的著作物が作成された場合において、作家Xは作成された二次的著作物の利用に  
関して、Ｃ。なお、作家Xの意に反して作品の内容を勝手に改変するこ  
とは同一性保持権の侵害となるが、同一性保持権は作家Xから他者へＤ。  

[解答群]  
  
ア  Ａ：著作権の設定登録Ｂ：公表後70年Ｃ：権利を持たない  
Ｄ：譲渡できない  
  
イ  Ａ：著作権の設定登録Ｂ：著作者の死後50年Ｃ：権利を持つ  
Ｄ：譲渡できる  
  
ウ  Ａ：著作物の創作Ｂ：公表後70年Ｃ：権利を持たない  
Ｄ：譲渡できる  
  
エ  Ａ：著作物の創作Ｂ：著作者の死後50年Ｃ：権利を持つ  
Ｄ：譲渡できない  
        
`
        },
        {
                "mondaiId": 105270080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
商標制度に関する記述として最も適切なものはどれか。  
  
ア  自己の氏名を普通に用いられる方法で表示する商標であっても、先に登録され  
た商標と同一であれば商標権の侵害となる。  
  
イ  商標の更新登録の申請の際には、審査官による実体審査はなされない。  
  
ウ  テレビやコンピュータ画面等に映し出される変化する文字や図形は商標登録さ  
れる場合はない。  
  
エ  文字や図形等の標章を商品等に付す位置が特定される商標が商標登録される場  
合はない。`
        },
        {
                "mondaiId": 105270090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
不正競争防止法に定める不正競争行為に<u>該当しないものとして</u>、最も適切なもの  
はどれか。  
  
ア  広告に商品の原産地について誤認させるような表示をする行為。  
  
イ  他人の商品の形態を模倣したものであるが、その商品の機能を確保するために  
不可欠な形態を採用した商品を譲渡する行為。  
  
ウ  他人の商品又は営業と混同を生じさせることなく、他人の商品表示として需要  
者の間に広く認識されているものと同一の商品表示を使用する行為。  
  
エ  ライバル会社の営業上の信用を害する虚偽の事実を流布する行為。  
        
`
        },
        {
                "mondaiId": 105270100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士のあなたは、顧問先より以下の内容の質問を受けた。この質問に  
対する回答として、<u>最も不適切なものを下記の解答群から選べ</u>。  

当社に対してライバルのX社より、同社が5年前に登録した商標Bについて、  
｢あなたの会社が使用している商標Aは、わが社が5年前に登録した商標Bの商標  
権を侵害しているため、当該商標Aの使用を即刻中止するよう求める｣との内容の  
警告状が送られてきました。当社が取りうる対応としては、どのようなものがある  
か教えてください。  

[解答群]  
  
ア  商標Aが商標Bの商標権の効力の範囲内に含まれるか否かについて、特許  
庁に判定を求める。  
  
イ  商標Bが商標Bの指定商品について、継続して3年以上不使用の状態では  
ないかを調べる。  
  
ウ  商標Bに商標法で定める不登録事由がないかを調べ、あれば特許庁に対し  
て異議申立てを行う。  
  
エ  ライバルのX社が実際に商標Bの登録を所有しているか否かを、商標登録  
原簿で調べる。  
        
`
        },
        {
                "mondaiId": 105270111,
                "name": "第11問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士のあなたと顧客の経営者X氏との以下の会話を読んで、下記の  
設問に答えよ。なお、実在するキャラクターや特産品を考慮する必要はない。  

Ｘ氏：｢当社の本社はC県のAB市にあり、私は地元の経済団体の役職にも就い  
ているのですが、最近、AB市で盛り上がっているのが、AB市内の漁港  
で水揚げされた海老のすり身を煎餅の生地に練り込んで焼いた特産品の  
「ABせんべい」をもっと全国的に売り出そうという企画なんです。確か、  
地域の特産品の名称を保護するような商標がありましたよね。｣  

あなた：｢地域団体商標のことですか。正確なことは専門家に聞いた方がいいと思  
いますが、地域団体商標が認められるには、結構要件が厳しかったはずで  
すよ。権利の主体は、事業協同組合等のほか、平成26年に施行された法  
改正で新たにNPO法人や①等にも広げられました。しかし、  
「ABせんべい」という名称を使用しているだけでは難しくて、例えば「あ  
あ、あのAB市特産の、海老を原材料にした煎餅だな」と消費者や事業者  
が広く認識する程度の周知性が必要です。｣  

Ｘ氏：｢地域的にどの程度まで周知ならいいのですか。｣  

あなた：｢一般的には、②に及ぶ程度の周知性が必要とされています。｣  

Ｘ氏：｢なるほど、そう簡単なわけでもなさそうですね。実は、AB市の公募で  
採用された「ABせん兵衛くん」という、いわゆるゆるキャラが「ABせんべ  
い」の知名度向上に一役買っているのですが、最近、地元のイベントで  
「ABせん兵衛くん」の偽物が現れましてね。よく似た着ぐるみを着て、  
「海老みそブシューッ！」と叫びながらエビ反りになってのたうち回るなん  
てギャグをやったりして、子供にはうけますが、下品だと言って嫌う人も  
います。こういったゆるキャラの権利を知的財産で守るような法的手段は  
ないのでしょうか。｣  

あなた：｢キャラクターのデザインや絵柄の創作を保護するなら、やはり  
③で守るというのが最も素直でしょう。広告宣伝用であれば、平  
面だけでなく立体的な構成も④、あるいは⑤で保護が可能ですが、広告宣伝の対象となる商品やサービスを特定する必要がありま  
すし、④は登録の手続が、⑤は権利行使のために周知性  
の立証が必要です。また、ぬいぐるみの量産品であれば⑥で保護  
される可能性も出てきますが、一方でそのような物品が③による  
保護の対象になるか、という問題も出てきます。結局、場面に応じた個別  
的な法的保護の組み合わせでキャラクターの利益を守るしかないのが現状  
です。｣  

（設問1）  
会話の中の空欄①と②に入る語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  ①：一般社団法人②：全国  
  
イ  ①：商工会議所②：隣接都道府県  
  
ウ  ①：中小企業団体中央会②：近隣市町村  
  
エ  ①：公益社団法人②：全国８地方区分の同一区分`
        },
        {
                "mondaiId": 105270112,
                "name": "第11問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
中小企業診断士のあなたと顧客の経営者X氏との以下の会話を読んで、下記の  
設問に答えよ。なお、実在するキャラクターや特産品を考慮する必要はない。  

Ｘ氏：｢当社の本社はC県のAB市にあり、私は地元の経済団体の役職にも就い  
ているのですが、最近、AB市で盛り上がっているのが、AB市内の漁港  
で水揚げされた海老のすり身を煎餅の生地に練り込んで焼いた特産品の  
「ABせんべい」をもっと全国的に売り出そうという企画なんです。確か、  
地域の特産品の名称を保護するような商標がありましたよね。｣  

あなた：｢地域団体商標のことですか。正確なことは専門家に聞いた方がいいと思  
いますが、地域団体商標が認められるには、結構要件が厳しかったはずで  
すよ。権利の主体は、事業協同組合等のほか、平成26年に施行された法  
改正で新たにNPO法人や（　①　）等にも広げられました。しかし、  
「ABせんべい」という名称を使用しているだけでは難しくて、例えば「あ  
あ、あのAB市特産の、海老を原材料にした煎餅だな」と消費者や事業者  
が広く認識する程度の周知性が必要です。｣  

Ｘ氏：｢地域的にどの程度まで周知ならいいのですか。｣  

あなた：｢一般的には、（　②　）に及ぶ程度の周知性が必要とされています。｣  

Ｘ氏：｢なるほど、そう簡単なわけでもなさそうですね。実は、AB市の公募で  
採用された「ABせん兵衛くん」という、いわゆるゆるキャラが「ABせんべ  
い」の知名度向上に一役買っているのですが、最近、地元のイベントで  
「ABせん兵衛くん」の偽物が現れましてね。よく似た着ぐるみを着て、  
「海老みそブシューッ！」と叫びながらエビ反りになってのたうち回るなん  
てギャグをやったりして、子供にはうけますが、下品だと言って嫌う人も  
います。こういったゆるキャラの権利を知的財産で守るような法的手段は  
ないのでしょうか。｣  

あなた：｢キャラクターのデザインや絵柄の創作を保護するなら、やはり  
（　③　<hint hidden='true'>**
著作権
**</hint>）で守るというのが最も素直でしょう。広告宣伝用であれば、平  
面だけでなく立体的な構成も（　④　<hint hidden='true'>**
商標権
**</hint>）、あるいは（　⑤　<hint hidden='true'>**
不正競争防止法
**</hint>）　で　保護が可能ですが、広告宣伝の対象となる商品やサービスを特定する必要がありま  
すし、（　④　<hint hidden='true'>**
商標権
**</hint>）は登録の手続が、（　⑤　<hint hidden='true'>**
不正競争防止法
**</hint>）は権利行使のために周知性  
の立証が必要です。また、ぬいぐるみの量産品であれば（　⑥　<hint hidden='true'>**
意匠権
**</hint>）で保護  
される可能性も出てきますが、一方でそのような物品が（　③　<hint hidden='true'>**
著作権
**</hint>）による  
保護の対象になるか、という問題も出てきます。結局、場面に応じた個別  
的な法的保護の組み合わせでキャラクターの利益を守るしかないのが現状  
です。｣  

（設問２）

会話の中の空欄③~⑥に入る語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  ③：商品化権　④：不正競争防止法　⑤：商標権　⑥：意匠権  
  
イ  ③：著作権　④：商標権　⑤：景品表示法　⑥：パブリシティ権  
  
ウ  ③：著作権　④：商標権　⑤：不正競争防止法　⑥：意匠権<hint hidden='true'>**
←⭕適切です。
**</hint>    
  
エ  ③：意匠権　④：不正競争防止法　⑤：商標権　⑥：著作権  
        
`
        },
        {
                "mondaiId": 105270120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
意匠登録制度に関する記述として最も適切なものはどれか。  
  
ア  関連意匠制度とは、本意匠及び関連意匠に類似する意匠について意匠登録を受  
けることができる制度である。  
  
イ  組物意匠制度とは、セットで販売される物品であって、組物全体として統一感  
があるものについて一意匠として意匠登録を受けることができる制度である。  
  
ウ  部分意匠制度とは、物品の部分に関する意匠について意匠登録を受けることが  
できる制度である。  
  
エ  秘密意匠制度とは、意匠権の設定の登録の日から5年以内の期間に限り、意匠  
を秘密にすることを請求することができる制度である。`
        },
        {
                "mondaiId": 105270130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
著作権の制限に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  違法なインターネット配信から、違法と知りながら音楽をダウンロードした場  
合でも、私的使用目的であれば著作権侵害とはならない。  
  
イ  写真撮影において、被写体の背景にやむを得ず写り込んでしまった事物は、著  
作権者の利益を不当に害するものでなければ著作権侵害とはならない。  
  
ウ  新聞に掲載して発行された時事問題に関する論説は、特に禁止する旨の注意が  
されていなければ、他の新聞に転載する事ができる。  
  
エ  有名な画家が描いた絵画を所有している場合、その所有者はその絵画を公に展  
示することができる。  
        
`
        },
        {
                "mondaiId": 105270140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
著作権及び著作者人格権に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  契約によって｢著作権の全部を譲渡する｣旨の条項を定めることにより、著作権  
を構成する複製権等の支分権を個別に特定しなくても、支分権の全てが譲渡人か  
ら譲受人に移転する。  
  
イ  著作権法上、職務上作成する著作物の著作者は、雇用契約等で別途規定しない  
限り使用者であるから、使用者が法人であっても著作者人格権に基づき当該著作  
物の改変行為の差止めを請求できる。  
  
ウ  電子書籍の出版権者は、電子書籍の公衆送信権のみを専有するにとどまるが、  
海賊版業者が違法配信目的で電子書籍の複製を行う行為の差止めを請求できる。  
  
エ  わが国の著作権法上、リバース・エンジニアリングがプログラムの著作物の著  
作権を侵害するか否かについては議論があるが、これを禁止する条項をソフトウ  
ェアの使用許諾契約で定めることは可能である。  
        
`
        },
        {
                "mondaiId": 105270150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
外国企業への生産委託に関する以下の文章の空欄ＡとＢに入る語句の組み合わせ  
として、最も適切なものを下記の解答群から選べ。  

国内の中小製造業者がアジア諸国等の現地企業に生産委託を行う場合、現地法人  
や現地工場を確保する場合と比較して、生産コスト面や労務管理面での負担軽減、  
海外販路の確保、ハイテク製品とローテク製品の生産のすみ分け等といった利点が  
挙げられる。その一方、技術流出や秘密漏洩のリスクがあるほか、品質管理やブラ  
ンド管理に困難を伴うといった問題点もある。  

受託者U現地企業Tに開示する技術情報についての秘密保持義務を生産委託契約で  
規定することは、受託者への義務付けを通して技術流出の未然防止が期待できる  
が、いったん技術情報が流出してしまえば、第三者による技術利用を拘束する効力  
はない。このような第三者による重要な生産技術の利用を防ぐ上で、受託者の本拠  
地国でのＡは一定の効果があるが、他方で権利を取得する前に生産方法の  
公開により技術的なノウハウが広く全世界に流出してしまうリスクも生じさせるの  
で、慎重に検討する必要がある。  

生産委託の形態として採用される手法のひとつが、委託者U国内企業Tが製品の設  
計から制作・組立図面に至るまで受託者へ支給しU場合によっては技術指導も行  
う。T、委託者のブランドで製品を生産するOEMである。委託者にとっては、製品  
市場の導入期や成長期におけるブランドの知名度向上、生産能力不足のカバーとい  
ったメリットが大きい。この生産委託の形態をさらに進化させたのが、製品の設計  
段階から製品開発、場合によってはマーケティングに至るまで受託者が一貫して提  
供するＢであり、受託者の技術レベルが委託者と同水準以上にあることが  
基本的な特徴である。  

[解答群]  
  
ア  Ａ：意匠権の取得Ｂ：FMS  
  
イ  Ａ：オープンソース化Ｂ：EMS  
  
ウ  Ａ：商標権の取得Ｂ：ODF  
  
エ  Ａ：特許権の取得Ｂ：ODM  
        
`
        },
        {
                "mondaiId": 105270161,
                "name": "第16問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本企業と外国企業との間の共同研究開発契約書(Cooperative Research and  
Development Agreement)において規定されている以下の条項を読んで、下記の設  
問に答えよ。  

Article●●  （　空欄　）

1．During the term of this Agreement，no collaborative research and/or  
development in competition with research or development under this Agreement  
shall be made by any party to this Agreement with a third party，without  
obtaining its prior written approval of the other party.  
        
2．Termination of this Agreement shall be without prejudice to the obligation  
provided for in the preceding paragraph.  

（設問1）  
契約書の(空欄)に入る語句として最も適切なものはどれか。  
  
ア  Confidentiality  
  
イ  Non-competition  
  
ウ  Non-delegation　to　a　thirdparty  
  
エ  Obligation　upon　Termination`
        },
        {
                "mondaiId": 105270162,
                "name": "第16問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
日本企業と外国企業との間の共同研究開発契約書(Cooperative Research and  
Development Agreement)において規定されている以下の条項を読んで、下記の設  
問に答えよ。  

Article●●  （　空欄　）

1．During the term of this Agreement，no collaborative research and/or  
development in competition with research or development under this Agreement  
shall be made by any party to this Agreement with a third party，without  
obtaining its prior written approval of the other party.  
        
2．Termination of this Agreement shall be without prejudice to the obligation  
provided for in the preceding paragraph.  

（設問2）  

共同研究開発契約において、特に本条第  
項のような規定を定めることは、わ  
が国では違法となるおそれが強いとされているが、その根拠となる法律として、  
最も適切なものはどれか。  
  
ア  産業技術力強化法  
  
イ  中小企業新事業活動促進法  
  
ウ  独占禁止法  
  
エ  特許法  
        
`
        },
        {
                "mondaiId": 105270170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A株式会社(株券発行会社ではない。以下｢A社｣という。)は、その発行株式の全  
部について譲渡による取得に取締役会の承認を要する旨、定款で定めているが、A  
社が相続人に対してその取得したA社株式をA社に売り渡すことを請求できる旨  
の定款規定は存在しない。  

A社を弟の専務X1とともに創業し、A社の発行済株式の3分の2(以下｢甲株  
式｣という。)を保有する社長甲は、甲とZU故人。甲と婚姻関係を有したことはな  
い。Tとの間で出生した長女であるYに｢その所有に属する遺産全部を遺贈する｣旨  
の自筆遺言証書を作成した。甲の死後、甲の遺言書が自宅で発見され、家庭裁判所  
で甲の長男X2(亡妻との間の子)の立ち会いの下、検認の手続が行われた。甲の子  
はX2とYの2名だけである。  

<imgTag>105270170_1.png</imgTag>  

この場合、甲株式の法律関係に関する記述として最も適切なものはどれか。な  
お、遺言執行者の指定、推定相続人の廃除及び相続人と受遺者間の合意はいずれも  
存在せず、甲株式以外の相続財産、相続債務、寄与分及び特別受益についても考慮  
しないものとする。  
        
        
  
ア  X2は、Yに対して遺留分減殺請求権を行使すれば直ちに、甲株式のうち、自  
らの遺留分を保全するのに必要な限度の株式数を単独で取得することができる。  
  
イ  X2は、遺留分減殺請求により甲株式につき権利を取得した場合、Yの同意を  
得たうえで、権利行使者をX2と指定してA社に通知すれば、単独で株主権を  
行使することができる。  
  
ウ  Yが甲株式についての権利を取得するためには、その取得についてA社に承  
認の請求を行い、A社取締役会による承認の決定を得ることが必要である。  
  
エ  Yに対して、X1は相続財産の3分の1、X2は相続財産の2分の1の割合で、  
各自遺留分減殺請求権を行使することができる。  
        
`
        },
        {
                "mondaiId": 105270181,
                "name": "第18問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士であるあなたと、東京証券取引所が運営する新興市場に上場した  
顧客企業のIR担当執行役員甲氏との以下の会話を読んで、下記の設問に答えよ。 

あなた：｢今月上場されたのですよね。おめでとうございます。｣  

甲氏：｢ありがとうございます。｣  

あなた：｢でも、甲さんの仕事はこれからが大変ですね。上場企業となるといろい  
ろ情報開示が求められますし。｣  

甲氏：｢そうですね。一口に企業内容の開示といっても、法定開示、<u>適時開示</u>、  
それに任意開示があって、何か起こったときにどれに該当するかはなかな  
か瞬時には判断がつきません。あと、開示内容が誤っていたり虚偽記載が  
あったりした場合の法的責任も重そうですよね。例えば有価証券報告書に  
虚偽記載があった場合、どのようなペナルティがありますか。｣  

あなた：｢当局に提出して開示した有価証券報告書の重要な事項について虚偽記載  
があったり記載が欠けたりした場合、有価証券の発行者である会社が課徴  
金を国庫に納めなければなりません。課徴金の金額については、その発行  
会社が発行する有価証券の市場価額の総額にＡを乗じて算出した  
額が600万円を超えなければ600万円、超えればその算出額になります。  
また、重要な事項について虚偽記載等のある有価証券報告書の提出会社  
は、流通市場における有価証券の取得者・Ｂに対して、金融商品  
取引法に基づく損害賠償責任を負う可能性があります。この流通市場にお  
ける提出会社の損害賠償責任については、発行市場における発行会社の損  
害賠償責任とＣ、Ｄであるとされています。さらに罰則  
もあります。具体的に重要な事項の虚偽記載とされる事例については、専  
門家のアドバイスを受けてください。｣  

甲氏：｢ウチみたいに経営の規模が小さければ、Ｅに対する監査の免除  
といった新規上場企業の負担軽減措置も受けられますが、やはり上場した  
以上、責任は重いのですね。｣  
        
（設問1）  
次の①~⑥の事項のうち、その開示が会話の中の下線部(適時開示)に当てはま  
るものの組み合わせとして、最も適切なものを下記の解答群から選べ。  

①業務上の提携又は業務上の提携の解消  
②会社業務における法令違反事実等の不祥事の発覚  
③有価証券報告書、四半期報告書  
④有価証券報告書及び四半期報告書の提出遅延  
⑤中期経営計画  
⑥事業報告における内部統制システムに関する取締役会決議の概要  

[解答群]  
  
ア  ①、④  
  
イ  ①、⑤  
  
ウ  ②、③  
  
エ  ④、⑥`
        },
        {
                "mondaiId": 105270182,
                "name": "第18問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
中小企業診断士であるあなたと、東京証券取引所が運営する新興市場に上場した  
顧客企業のIR担当執行役員甲氏との以下の会話を読んで、下記の設問に答えよ。 

あなた：｢今月上場されたのですよね。おめでとうございます。｣  

甲氏：｢ありがとうございます。｣  

あなた：｢でも、甲さんの仕事はこれからが大変ですね。上場企業となるといろい  
ろ情報開示が求められますし。｣  

甲氏：｢そうですね。一口に企業内容の開示といっても、法定開示、<u>適時開示</u>、  
それに任意開示があって、何か起こったときにどれに該当するかはなかな  
か瞬時には判断がつきません。あと、開示内容が誤っていたり虚偽記載が  
あったりした場合の法的責任も重そうですよね。例えば有価証券報告書に  
虚偽記載があった場合、どのようなペナルティがありますか。｣  

あなた：｢当局に提出して開示した有価証券報告書の重要な事項について虚偽記載  
があったり記載が欠けたりした場合、有価証券の発行者である会社が課徴  
金を国庫に納めなければなりません。課徴金の金額については、その発行  
会社が発行する有価証券の市場価額の総額にＡを乗じて算出した  
額が600万円を超えなければ600万円、超えればその算出額になります。  
また、重要な事項について虚偽記載等のある有価証券報告書の提出会社  
は、流通市場における有価証券の取得者・Ｂに対して、金融商品  
取引法に基づく損害賠償責任を負う可能性があります。この流通市場にお  
ける提出会社の損害賠償責任については、発行市場における発行会社の損  
害賠償責任とＣ、Ｄであるとされています。さらに罰則  
もあります。具体的に重要な事項の虚偽記載とされる事例については、専  
門家のアドバイスを受けてください。｣  

甲氏：｢ウチみたいに経営の規模が小さければ、Ｅに対する監査の免除  
といった新規上場企業の負担軽減措置も受けられますが、やはり上場した  
以上、責任は重いのですね。｣  
        
（設問2）

会話中の空欄Ａ~Ｅに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ａ：10万分の6 Ｂ：処分者Ｃ：異なり  
Ｄ：立証責任の転換された過失責任Ｅ：内部統制報告書  
  
イ  Ａ：10万分の3 Ｂ：処分者Ｃ：同様  
Ｄ：無過失責任Ｅ：有価証券届出書  
  
ウ  Ａ：100分の2.25 Ｂ：転得者Ｃ：異なり  
Ｄ：一般不法行為と同様の過失責任Ｅ：有価証券報告書  
  
エ  Ａ：100分の1 Ｂ：発行者Ｃ：同様  
Ｄ：結果責任Ｅ：事業報告書  
        
`
        },
        {
                "mondaiId": 105270190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
会社の社債に関する記述として最も適切なものはどれか。  
  
ア  株主が違法な社債の発行の事前差止めを求めて訴えることはできず、専ら事後  
的に取締役・執行役の損害賠償責任を追及するしか是正手段がない。  
  
イ  総額1億円未満の少人数私募債については有価証券届出書の提出等の開示義務  
がなく、届出がないこと等について投資家への告知義務もない。  
  
ウ  取締役会設置会社においては、社債を発行するに当たり、募集事項の決定を代  
表取締役に委任することができない。  
  
エ  振替社債については、社債券が発行されていないので、投資家保護のための開  
示規制が適用される有価証券に該当しない。  
`
        },
        {
                "mondaiId": 106270010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータは、業務に必要な各種の周辺装置を直接あるいはネットワーク経由  
等で接続して利用する。周辺装置を選択する場合は、各装置の特性を理解した上  
で、業務に適した装置を選択する必要がある。  
周辺装置の特性に関する記述として最も適切なものはどれか。  
  
ア  外部記憶装置として利用される磁気ディスクは製造後にフォーマットを行わな  
ければ利用できないが、SSDはフォーマットが不要でホットスワップ機能のも  
とでのディスク交換に向いている。  
  
イ  カラープリンタで画像を印刷する場合は、画像のベクターデータとともに、  
XGA，WXGA等の解像度に関する情報がプリンタへと指示されるので、パラレ  
ルインタフェースが使用されている。  
  
ウ  業務用のハンディターミナルに搭載されることの多い抵抗膜方式のタッチパネ  
ルは、スマートフォンに搭載されている静電容量方式のパネルと比べ、ペンや手  
袋等をしていても反応し、耐久性能や耐衝撃性能が優れている。  
  
エ  有機ELディスプレイは有機EL素子自体が発光する特性を利用し、高輝度で  
コントラストが高く鮮明な表示が可能で、バックライトが不要なので消費電力も  
少ない。  
        
`
        },
        {
                "mondaiId": 106270020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
自社のWebサイトの開発にあたっては、利用可能な様々な言語や仕組みがあ  
り、Webコンテンツごとに必要な機能や表現に合ったものを使用する必要がある。  
これらの言語や仕組みの特徴に関する以下の①~④の記述と、その名称の組み合わ  
せとして、最も適切なものを下記の解答群から選べ。  

①Webページに記述された文書・データの表示位置の指示や表の定義、および、  
文字修飾指示等の表示方法に関する事項を記述するもの。  

②Webページ内でHTMLとともに記述することができるスクリプト言語で、サ  
ーバ側においてスクリプトを処理し、その結果を端末側で表示することが可能で  
あり、データベースとの連携も容易である。  

③Webページの中に実行可能なコマンドを埋め込み、それをサーバ側で実行さ  
せ、実行結果を端末側で表示させる仕組み。  

④コンピュータグラフィックスに関する図形、画像データを扱うベクターイメー  
ジデータをXMLの規格に従って記述するもの。  

[解答群]  
  
ア  ①：CSS②：ASP③：PHP④：SGML  
  
イ  ①：CSS②：PHP③：SSI④：SVG  
  
ウ  ①：SMIL②：Javaアプレット③：ASP④：SSI  
  
エ  ①：SVG②：SMIL③：PHP④：SGML  
        
`
        },
        {
                "mondaiId": 106270030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータによる業務支援が様々な場面で求められるが、小規模なプログラム  
作成で対応可能な場合でも、ソースプログラムの記述から、最終的に実行可能なプ  
ログラムk実行プログラムlを作成することが必要な場合がある。  
以下にソースプログラムから実行プログラムに変換する手順を図示した。図中の  
①~④に当てはまる用語の組み合わせとして、最も適切なものを下記の解答群から  
選べ。  

<imgTag>106270030_1.png</imgTag>  

[解答群]  
  
ア  ①：インタプリタ②：タスク  
③：カーネル④：コンパイラ  
  
イ  ①：コンパイラ②：オブジェクトファイル  
③：ライブラリファイル④：リンカ  
  
ウ  ①：コンパイラ②：カーネル  
③：ジョブ④：ジェネレータ  
  
エ  ①：コンパイラ②：ジョブ  
③：オブジェクトファイル④：リンカ  
        
`
        },
        {
                "mondaiId": 106270040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ソフトウェアの開発には多様なプログラミング言語が使われるが、それぞれ特徴  
がある。下記の記述のうち最も適切なものはどれか。  
  
ア  Cは、OSも開発できる言語であるが、メモリ解放の指示を忘れるとメモリリ  
ークバグが発生することがある。  
  
イ  C#は、日本人が開発したオブジェクト指向型言語であるが、Perlを参考にし  
て開発された。  
  
ウ  Javaは、インタプリタ言語なので、初心者にも習得がしやすい。  
  
エ  Perlは、HTMLとともに記述することができるサーバサイドスクリプト言語  
で、Webページ作成に特化している。  
        
`
        },
        {
                "mondaiId": 106270050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務において条件に応じた処理を行う必要がある場合、条件を一覧表にして判定  
条件を検討することがある。  

例えば、下記の表のように、項目A~Cには商品の色が赤の場合はrが、緑の場  
合はgが入り、A~Cに入っている商品の色を判定して、Z欄に示す結果となるよ  
うな判定を行う場合を考える。  

判定には、以下の構文のIF文を用いて判定式を表記する。  

IF (条件式，判定が真の場合の処理，判定が偽の場合の処理)  

ただし、IF文の判定が真または偽の場合の処理部分にはIF文のネストが許可さ  
れる。また、条件式で文字列を表す場合は””の記号で囲み、等しくないことは  
＜＞で表す。  

下記の解答群に示す判定式の中で、正しくない判定結果となるものはどれか。  

<imgTag>106270050_1.png</imgTag>  


[解答群]  
  
ア  IF(A=”g”，IF(B＜＞”g”，1，IF(C＜＞”g”，1，0))，1)  
  
イ  IF(A=”r”，1，IF(B=”r”，1，IF(C=”r”，1，0)))  
  
ウ  IF(A＜＞”g”，1，IF(B=”r”，1，IF(C＜＞”g”，1，0)))  
  
エ  IF(A＜＞”r”，IF(B=”g”，1，IF(C＜＞”g”，1，0))，1)  
        
`
        },
        {
                "mondaiId": 106270060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務処理用システムの入出力画面の設計を行う場合に、作業者の利用しやすさや  
リレーショナルデータベース(RDB)の管理上の特性を考慮する必要がある。  
以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切なものを下記  
の解答群から選べ。  
商品の受注業務を行う端末画面において、新規顧客からの受注データを入力す  
る際に、Ａ、顧客住所k都道府県lなどをキーボードから直接入力するの  
ではなく、あらかじめ用意したデータ一覧から選択し入力する方法を採用するの  
はRDB内のデータのＢするためである。  
この一覧から選択して入力する作業のための画面設計において、項目が比較的  
少数の場合はＣを、項目数が多く画面に収まらない場合などはスクロー  
ルバー付のＤを用いる。  

[解答群]  
  
ア  Ａ：顧客年齢Ｂ：保守性を確保Ｃ：テキストボックス  
Ｄ：プルダウンメニュー  
  
イ  Ａ：顧客名Ｂ：可用性を確保Ｃ：プルダウンメニュー  
Ｄ：テキストボックス  
  
ウ  Ａ：商品コードＢ：可用性を確保Ｃ：テキストボックス  
Ｄ：チェックボックス  
  
エ  Ａ：商品名Ｂ：冗長性を排除Ｃ：ラジオボタン  
Ｄ：リストボックス  
        
`
        },
        {
                "mondaiId": 106270070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
RDBの設計においては、利用するマスタファイルやトランザクションファイル  
のテーブル定義が行われる。  
ある業務で利用しているRDBでは、以下のようなマスタテーブルやトランザク  
ションテーブルが定義されている。各テーブルが正規化されている場合、以下の空  
欄A~Cに入る項目の組み合わせとして、最も適切なものを下記の解答群から選  
べ。  

<imgTag>106270070_1.png</imgTag>  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】正規化
正規化とは、リレーショナルデータベースにおいて、データの冗長・重複を排除し、データの不整合を起こりにくくするためにテーブル構造を最適化する事を言います。  
非正規形を、第1，第2，第3正規形まで最適化すると良いとされています。  
  
参考：非正規形の例  
<img src="https://ferret-one.akamaized.net/images/5c457be0979afc6384002888/original.png?public=true"/>
<a href="https://oss-db.jp/dojo/dojo_info_04" target="_blank">  
(外部リンクを開きます　OSS-DB：データベースの正規化)</a>  
</div>
</hint>  

[解答群]  
  
ア  Ａ：顧客住所Ｂ：仕入先コードＣ：受注日付<hint hidden='true'>**
←⭕
**</hint>    
  
イ  Ａ：顧客電話番号Ｂ：仕入先住所Ｃ：販売金額  
  
ウ  Ａ：顧客電話番号Ｂ：在庫量Ｃ：値引率  
  
エ  Ａ：自社担当者名Ｂ：仕入単価Ｃ：仕入先住所  
        
`
        },
        {
                "mondaiId": 106270080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
今週の商品の販売実績は下表のとおりであった。下表から売上金額を評価基準と  
したパレート図を作成して、来週の販売方策を検討したいと考えた。パレート図作  
成のため、まず売上金額の大きい順に商品を並べたデータを得るためのSQL文と  
して、最も適切なものを下記の解答群から選べ。  

販売実績表  
<imgTag>106270080_1.png</imgTag>  

[解答群]  
  
ア  SELECT 商品番号，商品名，販売数＊(売価－仕入価格)  
FROM 販売実績表 ORDER BY 販売数＊売価 ASC  
  
イ  SELECT 商品番号，売価－仕入価格，販売数＊売価  
FROM 販売実績表 ORDER BY 売価－仕入価格  
  
ウ  SELECT 商品名，販売数＊(売価－仕入価格)  
FROM 販売実績表 ORDER BY 5 ASC  
  
エ  SELECT 商品名，販売数＊(売価－仕入価格)，販売数＊売価  
FROM 販売実績表 ORDER BY 販売数＊売価 DESC  
        
`
        },
        {
                "mondaiId": 106270090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
事業所内におけるTCP/IPを利用したネットワーク環境には、コンピュータや  
各種サーバの他、ルータ等のネットワーク機器や様々な周辺機器が接続されてい  
る。このようなネットワーク環境における通信状態を調べる手段であるpingの役  
割として、最も適切なものはどれか。  
  
ア  ネットワーク上で、対象とするコンピュータや機器が応答可能かを調べ、応答  
時間を表示する。  
  
イ  ネットワーク上で、対象とするコンピュータや機器までの経路を調べて表示す  
る。  
  
ウ  ネットワークに接続されたコンピュータや機器のMACアドレスを、IPアド  
レスを指定して求める。  
  
エ  ネットワークに流れるパケットを捕獲して、その中身の表示や解析・集計など  
を行う。  
        
`
        },
        {
                "mondaiId": 106270100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年、情報ネットワークが発展・普及し、その重要性はますます高まっている。  
そのようなネットワークに関する以下の文章の空欄Ａ~Ｄに入る用語の組み合わせ  
として、最も適切なものを下記の解答群から選べ。  
ある限られたエリアの限定的なネットワークであるＡから、別の  
Ａのユーザにアクセスしようとすれば、Ａ同士をつなぐ  
Ｂと呼ばれるネットワークが必要となる。一方、ユーザ同士を電話回線  
などを利用して単に結びつけるだけでなく、コード変換などのサービスも提供し  
ようとしたのがＣと呼ばれるサービスである。  
現在のネットワークの普及には、インターネットの登場が大きな影響を与え  
た。ＢやＣが提供する通信サービスもインターネットで代替で  
きるようになり、安価かつ容易に広範囲な情報ネットワークを構築できるように  
なった。しかし、インターネットには通信のセキュリティの問題がある。遠隔の  
複数の拠点にまたがる組織の拠点間の通信セキュリティを高めるために利用でき  
る技術としてＤなどが知られている。  
[解答群]  
  
ア  Ａ：LANＢ：WANＣ：EDIＤ：IPS  
  
イ  Ａ：LANＢ：WANＣ：VANＤ：VPN  
  
ウ  Ａ：WANＢ：LANＣ：EDIＤ：VPN  
  
エ  Ａ：WANＢ：LANＣ：VANＤ：IPS  
        
`
        },
        {
                "mondaiId": 106270110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
コンピュータの性能に関する評価尺度は複数あるが、その中のひとつであるスル  
ープットに関する記述として、最も適切なものはどれか。  
  
ア  OSのマルチタスクの多重度で性能を評価する。  
  
イ  主記憶装置のデータ書き換え速度で性能を評価する。  
  
ウ  ターンアラウンドタイムではなく、レスポンスタイムで性能を評価する。  
  
エ  命令の処理量や周辺機器とのやり取り等を総合的に加味した、単位時間当たり  
の処理件数で性能を評価する。  
        
`
        },
        {
                "mondaiId": 106270120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報システムの信頼性を高めることがますます重要になってきている。  
高信頼化へのアプローチに関する以下の①~④の記述と、その名称の組み合わせ  
として、最も適切なものを下記の解答群から選べ。  
①故障や障害が発生しないよう対処する取り組み。  
②故障や障害が発生したときも主な機能の動作が続行できるように設計するこ  
と。  
③故障や障害が発生した場合でも限定的ながらシステムの稼働を続行している状  
態。  
④故障や障害が発生した場合、システムの被害を最小限にとどめる動作をさせる  
こと。  
[解答群]  
  
ア  ①：フェイルセーフ②：フォールトアボイダンス  
③：フェイルソフト④：フォールトトレランス  
  
イ  ①：フォールトアボイダンス②：フェイルオーバ  
③：フォールトトレランス④：フォールバック  
  
ウ  ①：フォールトアボイダンス②：フォールトトレランス  
③：フォールバック④：フェイルセーフ  
  
エ  ①：フォールトトレランス②：フェイルセーフ  
③：フェイルオーバ④：フォールトアボイダンス  
        
`
        },
        {
                "mondaiId": 106270130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業経営における情報技術の利用が進み、その重要性が増す中で、情報技術を利  
用するシステムやシステム化指針を省略語もしくはカタカナ語として言い表すこと  
が多くなった。それらに関する記述として最も適切なものはどれか。  
  
ア  PERT/CPMで用いられるクリティカルパス法と情報技術を組み合わせて、顧  
客と企業との間の業務フローの最適化を行うためのシステムをCRMと呼ぶ。  
  
イ  企業を構成する様々な部門・業務で扱う資源を統一的・一元的に管理すること  
を可能にするシステムをERPと呼ぶ。  
  
ウ  クラウドコンピューティングの多様なサービスが展開されているが、その中か  
ら最適なサービスを選択するシステム化指針をクラウドソーシングと呼ぶ。  
  
エ  クラウドコンピューティングの利用に際して、社内にサーバを設置して情報の  
漏えいを防ぐシステム化指針をインソーシングと呼ぶ。`
        },
        {
                "mondaiId": 106270140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
国税庁は平成17年月31日付の告示第4号で、スキャナで読み取ってタイムス  
タンプなしで保存できる書類を定めた。タイムスタンプなしで適時に入力・保存で  
きるものとして最も適切なものはどれか。  
  
ア  3万円未満の契約書や領収書  
  
イ  検収書や注文書  
  
ウ  小切手や約束手形  
  
エ  請求書や納品書  
        
`
        },
        {
                "mondaiId": 106270150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
クラウドコンピューティングの実現にも使われる仮想化技術に関する記述とし  
て、最も適切なものはどれか。  
  
ア  仮想サーバの規模に比例してサーバの管理オーバヘッドが次第に大きくなるこ  
とを、スケールアップという。  
  
イ  複数の物理サーバを負荷分散装置に追加して1台の仮想サーバとする方式は、  
顧客データの更新処理が多量に発生する場合に効率的である。  
  
ウ  物理サーバを追加することで仮想サーバの処理能力を増やすことを、スケール  
  
ア  ウトという。  
  
エ  ブレードPC方式のデスクトップ仮想化では、ブレードPCの処理余力をデス  
クトップで相互に有効利用することができる。  
        
`
        },
        {
                "mondaiId": 106270160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システム設計の際に使われる図に関する以下の①~④の記述と、図の名称の組み  
合わせとして、最も適切なものを下記の解答群から選べ。  
①情報システムの内外の関係するデータの流れを表す図である。  
②データを、実体、関連およびそれらの属性を要素としてモデル化する図であ  
る。  
③システムにはどのような利用者がいるのか、利用者はどのような操作���するの  
かを示すために使われる図である。  
④システムの物理的構成要素の依存関係に注目してシステムの構造を記述する図  
である。  
[解答群]  
  
ア  ①：DFD②：ERD③：アクティビティ図④：配置図  
  
イ  ①：DFD②：ERD③：ユースケース図④：コンポーネント図  
  
ウ  ①：ERD②：DFD③：ステートチャート図④：コンポーネント図  
  
エ  ①：ERD②：DFD③：ユースケース図④：配置図`
        },
        {
                "mondaiId": 106270170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システム開発を発注者と受注者が検討する場合、想定する情報システムの機能要  
求だけでなく、非機能要求も検討する必要がある。独立行政法人情報処理推進機構  
kIPAlが発表した｢非機能要求グレード｣に関する記述として、最も適切なものはど  
れか。  
  
ア  開発したシステム全体の優劣をグレードとして表示する。  
  
イ  システムの規模の違いにより6つのモデルシステムが定義されている。  
  
ウ  要求項目やメトリクスの重複がないように、体系的に整理されている。  
  
エ  要求項目を段階的に詳細化して、その内容について合意していく。  
        
`
        },
        {
                "mondaiId": 106270180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年の多様なIT機器の発達、激しいビジネス環境の変動の中で、アジャイルシ  
ステム開発が注目されている。アジャイルシステム開発の方法論であるフィーチャ  
駆動開発、スクラム、かんばん、XPに関する記述として、最も適切なものはどれ  
か。  
  
ア  フィーチャ駆動開発は、要求定義、設計、コーディング、テスト、実装という  
システム開発プロセスを逐次的に確実に行う方法論である。  
  
イ  スクラムは、ラウンドトリップ・エンジニアリングを取り入れたシステム開発  
の方法論である。  
  
ウ  かんばんは、ジャストインタイムの手法を応用して、システム開発の際に、ユ  
ーザと開発者との間でかんばんと呼ばれる情報伝達ツールを用いることに特徴が  
ある。  
  
エ  XPは、開発の基幹手法としてペアプログラミングを用いるが、それは複数の  
  
オ  ブジェクトを複数の人々で分担して作成することで、システム開発の迅速化を  
図ろうとするものである。  
        
`
        },
        {
                "mondaiId": 106270190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
多様な情報システムを開発して新規に導入したり、以前からあった情報システム  
を変更して利用したりすることが頻繁に行われ、情報システムの複雑性が増してい  
る。情報システムが複雑になればなるほど、ソフトウェアテストの重要性が高ま  
る。これに関する記述として最も適切なものはどれか。  
  
ア  V字モデルにおけるテストとは、システム開発の過程をさかのぼるようにし  
て、総合テスト、受入テスト、単体テストを逐次に実施する方法である。  
  
イ  回帰テストとは、保守によってシステムに変更が加えられたならば、変更した  
部分だけのテストを行う方法である。  
  
ウ  デシジョンテーブルテストとは、ソフトウェアの利用に際してユーザが行う意  
思決定の内容を、デシジョンテーブルに整理してテストを行う方法である。  
  
エ  ブラックボックステストとは、プログラムの内部構造は考慮せず、機能やイン  
タフェースだけに着目してテストデータを作成し、テストを行う方法である。  
        
`
        },
        {
                "mondaiId": 106270200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
業務フローの改善を検討するために、ビジネスプロセスをモデル化することの重  
要性が増している。そのためのモデリング技法として様々な記法が提案されている  
が、それらは、ワークフロー的視座に立つものと調整的視座に立つものに大別でき  
る。このうち、調整的視座に立つモデリング技法として最も適切なものはどれか。  
  
ア  BPMN(BusinessProcessModel and Notation)  
  
イ  DEMO(Design&EngineeringMethodology for Organizations)  
  
ウ  EPC(Event-drivenProcessChain)  
  
エ  ペトリネット(PetriNet)`
        },
        {
                "mondaiId": 106270210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットを利用する企業にとって、通信のセキュリティを守ることがます  
ます重要になっている。2014年4月にOpenSSLの脆弱性(CVE-2014-0160)が発  
覚して大きな問題になった。この脆弱性に関する記述として最も適切なものはどれ  
か。  
  
ア  暗号化通信方式のフォールバック機能により、OpenSSLを使用した暗号通信  
の内容が漏えいした。  
  
イ  攻撃者は、OpenSSLを使用しているサーバのメモリ内データの一部を読み取  
ることができた。  
  
ウ  この脆弱性はshellshockと呼ばれた。  
  
エ  中間者攻撃(man-in-the-middleattack)により、暗号通信の内容が漏えいした。  
        
`
        },
        {
                "mondaiId": 106270220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
一般財団法人日本情報経済社会推進協会のISMSユーザーズガイド(リスクマネ  
ジメント編)などが、情報セキュリティリスクアセスメントを実施するためのアプ  
ローチとして、ベースラインアプローチ、非形式的アプローチ、詳細リスク分析、  
組み合わせアプローチの4つを紹介している。これらのアプローチに関する記述と  
して最も適切なものはどれか。  
  
ア  ベースラインアプローチとは、システムの最も基本的な部分を選び、これに確  
保すべき一定のセキュリティレベルを設定して、現状とのギャップをリスクとし  
て評価することを指す。  
  
イ  非形式的アプローチとは、組織や担当者の経験や判断によってリスクを評価す  
ることを指す。  
  
ウ  詳細リスク分析とは、システムをサブシステムに分解し、そのシステムごとに  
リスク評価を行うことを指す。  
  
エ  組み合わせアプローチとは、システムをサブシステムに分解し、その組み合わ  
せすべてについてリスク評価を行うことを指す。`
        },
        {
                "mondaiId": 106270230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本工業規格JISX-0129-1ではソフトウェア製品の品質を規定している。これ  
に含まれる品質は、機能性・信頼性・使用性・効率性・保守性・移植性の\u000f品質で  
ある。これらの品質に含まれる副特性に関する記述として、最も適切なものはどれ  
か。  
  
ア  運用性は、保守性品質に含まれる。  
  
イ  成熟性は、移植性品質に含まれる。  
  
ウ  セキュリティは、機能性品質に含まれる。  
  
エ  魅力性は、効率性品質に含まれる。  
        
`
        },
        {
                "mondaiId": 106270240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
様々なデータ分析技法が開発されており、広く使われている。それらの技法に関  
する以下の①~③の記述と、その名称の組み合わせとして、最も適切なものを下記  
の解答群から選べ。  
①複数の母集団の平均値の間に差があるかどうかを統計的に検定するのに使える  
方法。  
②Webサイトで2つの異なるページをランダムに表示して、それらに対する利  
用者の反応の違いを統計的に分析するのに使える方法。  
③事前に与えられたデータが2つの異なるグループに分かれる場合、新しいデー  
タがどちらのグループに入るのかを区別するのに使える方法。  
[解答群]  
  
ア  ①：判別分析②：A/Bテスト③：分散分析  
  
イ  ①：判別分析②：分散分析③：A/Bテスト  
  
ウ  ①：分散分析②：A/Bテスト③：判別分析  
  
エ  ①：分散分析②：判別分析③：A/Bテスト  
        
`
        },
        {
                "mondaiId": 106270250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2015,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
年中無休のある店舗で、日次売上高を2年分集計した。年ごとの平均日次売上高  
の母集団の分布と分散は分からないが、平均日次売上高に有意な差があるかどうか  
について、有意水準5％でz値を計算し仮説検定を行った。z値は、各年の平均日  
次売上高の差が分子、各年の日数で調整した標本標準偏差を分母としたときの比率  
(z＞0)である。  
この仮説検定に関する記述として最も適切なものはどれか。  
  
ア  ｢１－(第2種の誤りの確率)｣を、検定力という。  
  
イ  z値が1.96よりも大きいときは、帰無仮説が採択できる。  
  
ウ  z値を計算するときの分母は、各年の標本標準偏差を各年の日数で除したもの  
の和である。  
  
エ  問題文中の記述と同様にして、年ごとの平均月次売上高の差も検定できる。  
`
        },
        {
                "mondaiId": 107270011,
                "name": "第1問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業は、わが国経済の基盤的存在である。総務省・経済産業省｢平成24年経済センサス安活動調査(民営、非一次産業、2012年)｣に基づくと、中小企業のう  
ち小規模企業は、わが国の企業数の約Ａ割、会社および個人事業所の従業  
者総数の約Ｂを占めており、非常に重要な存在である。  
しかしながら、中小企業数の推移を見ると、近年の経済・社会構造の変化に伴  
い、長期にわたり減少傾向で推移している。企業規模別に<u>2009年から2012年にか  
けての企業数の増減を見る</u>と、小規模企業の減少率は、小規模企業を除く中小企業  
の減少率を上回っており、小規模企業を取り巻く経営環境が一段と厳しさを増して  
いることがうかがえる。  
なお、企業規模区分は中小企業基本法の定義に準ずるものとする。ただし、ゴム  
製品製造業は常用雇用者900人以下、旅館、ホテルは常用雇用者200人以下、ソフ  
トウエア業、情報処理・提供サービス業は資本金3億円以下または常用雇用者300  
人以下の企業を中小企業に含む。また、宿泊業・娯楽業については常用雇用者20  
人以下の企業を小規模企業とする。  

（設問1）  
文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：7 Ｂ：3分の1  
  
イ  Ａ：7 Ｂ：4分の1  
  
ウ  Ａ：8 Ｂ：5分の1  
  
エ  Ａ：3 Ｂ：4分の1  
  
オ  Ａ：3 Ｂ：5分の1  
        
`
        },
        {
                "mondaiId": 107270012,
                "name": "第1問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業は、わが国経済の基盤的存在である。総務省・経済産業省｢平成24年経済センサス安活動調査(民営、非一次産業、2012年)｣に基づくと、中小企業のう  
ち小規模企業は、わが国の企業数の約Ａ割、会社および個人事業所の従業  
者総数の約Ｂを占めており、非常に重要な存在である。  
しかしながら、中小企業数の推移を見ると、近年の経済・社会構造の変化に伴  
い、長期にわたり減少傾向で推移している。企業規模別に<u>2009年から2012年にか  
けての企業数の増減を見る</u>と、小規模企業の減少率は、小規模企業を除く中小企業  
の減少率を上回っており、小規模企業を取り巻く経営環境が一段と厳しさを増して  
いることがうかがえる。  
なお、企業規模区分は中小企業基本法の定義に準ずるものとする。ただし、ゴム  
製品製造業は常用雇用者900人以下、旅館、ホテルは常用雇用者200人以下、ソフ  
トウエア業、情報処理・提供サービス業は資本金3億円以下または常用雇用者300  
人以下の企業を中小企業に含む。また、宿泊業・娯楽業については常用雇用者20  
人以下の企業を小規模企業とする。  

（設問2）  

文中の下線部について、総務省｢平成21年経済センサス－基礎調査｣、総務  
省・経済産業省｢平成24年経済センサス－活動調査｣に基づき、中小企業性の強  
い小売業、宿泊業・飲食サービス業、建設業について、2009年から2012年にか  
けての中小企業の規模別業種別開業・廃業件数を見た場合の記述として、最も適  
切なものはどれか。  
  
ア  開業件数は小売業が最も多い。  
  
イ  開業件数は宿泊業・飲食サービス業が最も多い。  
  
ウ  廃業件数は建設業が最も多い。  
  
エ  廃業件数は小売業が最も少ない。  
  
オ  廃業件数は宿泊業・飲食サービス業が最も少ない。  
        
`
        },
        {
                "mondaiId": 107270020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

中小企業の事業活動は法人によってのみ行われているわけではない。総務省・経  
済産業省｢平成24年経済センサス安活動調査I民営、非一次産業、2012年N｣に基  
づくと、小規模企業における個人事業者の割合は約Ａ割、小規模企業を除  
く中小企業における個人事業者の割合は約Ｂ割である。  

なお、企業規模区分は中小企業基本法の定義に準ずるものとする。ただし、ゴム  
製品製造業は常用雇用者900人以下、旅館、ホテルは常用雇用者200人以下、ソフ  
トウエア業、情報処理・提供サービス業は資本金3億円以下または常用雇用者300  
人以下の企業を中小企業に含む。また、宿泊業・娯楽業については常用雇用者20  
人以下の企業を小規模企業とする。  

[解答群]  
  
ア  Ａ：2 Ｂ：6  
  
イ  Ａ：4 Ｂ：6  
  
ウ  Ａ：5 Ｂ：5  
  
エ  Ａ：6 Ｂ：2          
  
オ  Ａ：9 Ｂ：4  
        
`
        },
        {
                "mondaiId": 107270031,
                "name": "第3問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

わが国の輸出額と対外直接投資額の推移を見ると、輸出額は、近年ではリーマ  
ン・ショック後の2009年に大きく落ち込んでいるものの、長期的には増加傾向に  
ある。対外直接投資額も、バブル崩壊後、2000年代前半までは低迷していたが、  
その後増加している。こうした中で、輸出や直接投資を実施する中小企業も着実に  
増加してきている。  

経済産業省｢工業統計表｣、総務省・経済産業省｢平成24年経済センサス安活動  
調査｣に基づき、<u>①直接輸出を実施している中小製造業</u>の企業数および中小製造業全  
体に占める割合の推移を見ても、小規模企業を含め、ともに増加傾向にある。経済  
産業省｢企業活動基本調査｣に基づき、<u>②海外子会社を保有する企業</u>の割合の推移を見  
ても、大企業には及ばないものの、中小企業の海外子会社保有割合は増加傾向にあ  
る。もっとも大企業と中小企業では<u>③海外子会社の地域構成</u>や投資目的に違いも見ら  
れる。中小企業の海外展開支援に際しては、政府や公的支援機関による支援に加え  
て、企業の実情に通じた中小企業診断士をはじめとする民間の支援事業者の活躍が  
期待されている。  

（設問1）  
文中の下線部①のうち、生産用機械器具製造業、電気機械器具製造業、金属製  
品製造業について、直接輸出を実施する中小製造業の業種構成割合I2011年Nを  
見た場合の記述として、最も適切なものはどれか。  
なお、ここでは中小企業のうち従業者数4人以上を対象とする。  
  
ア  金属製品製造業の割合が最も高い。  
  
イ  金属製品製造業の割合は電気機械器具製造業の割合より高い。  
  
ウ  生産用機械器具製造業の割合が最も高い。  
  
エ  電気機械器具製造業の割合は生産用機械器具製造業の割合より高い。  
        
`
        },
        {
                "mondaiId": 107270032,
                "name": "第3問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  

わが国の輸出額と対外直接投資額の推移を見ると、輸出額は、近年ではリーマ  
ン・ショック後の2009年に大きく落ち込んでいるものの、長期的には増加傾向に  
ある。対外直接投資額も、バブル崩壊後、2000年代前半までは低迷していたが、  
その後増加している。こうした中で、輸出や直接投資を実施する中小企業も着実に  
増加してきている。  

経済産業省｢工業統計表｣、総務省・経済産業省｢平成24年経済センサス安活動  
調査｣に基づき、<u>①直接輸出を実施している中小製造業</u>の企業数および中小製造業全  
体に占める割合の推移を見ても、小規模企業を含め、ともに増加傾向にある。経済  
産業省｢企業活動基本調査｣に基づき、<u>②海外子会社を保有する企業</u>の割合の推移を見  
ても、大企業には及ばないものの、中小企業の海外子会社保有割合は増加傾向にあ  
る。もっとも大企業と中小企業では<u>③海外子会社の地域構成</u>や投資目的に違いも見ら  
れる。中小企業の海外展開支援に際しては、政府や公的支援機関による支援に加え  
て、企業の実情に通じた中小企業診断士をはじめとする民間の支援事業者の活躍が  
期待されている。  

（設問2）  

文中の下線部②について、経済産業省｢平成24年企業活動基本調査｣に基づき、  
海外に子会社または関連会社を保有する中小企業の業種構成割合I2011年度Nを  
見た場合、多いものから少ないものへと並べた組み合わせとして、最も適切なも  
のを下記の解答群から選べ。  

なお、ここでは中小企業のうち、従業者50人以上、かつ資本金または出資金  
3，000万円以上の企業を対象とし、サービス業には小売業、宿泊、飲食サービス  
業を含む。  

ａ卸売業  
ｂサービス業  
ｃ製造業  

[解答群]  
  
ア  ａ卸売業　－　ｂサービス業　－　ｃ製造業  
  
イ  ａ卸売業　－　ｃ製造業　－　ｂサービス業  
  
ウ  ｂサービス業　－　ａ卸売業　－　ｃ製造業  
  
エ  ｃ製造業　－　ａ卸売業　－　ｂサービス業  
  
オ  ｃ製造業　－　ｂサービス業　－　ａ卸売業  
        
`
        },
        {
                "mondaiId": 107270033,
                "name": "第3問設問3",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
                
次の文章を読んで、下記の設問に答えよ。  

わが国の輸出額と対外直接投資額の推移を見ると、輸出額は、近年ではリーマ  
ン・ショック後の2009年に大きく落ち込んでいるものの、長期的には増加傾向に  
ある。対外直接投資額も、バブル崩壊後、2000年代前半までは低迷していたが、  
その後増加している。こうした中で、輸出や直接投資を実施する中小企業も着実に  
増加してきている。  

経済産業省｢工業統計表｣、総務省・経済産業省｢平成24年経済センサス安活動  
調査｣に基づき、<u>①直接輸出を実施している中小製造業</u>の企業数および中小製造業全  
体に占める割合の推移を見ても、小規模企業を含め、ともに増加傾向にある。経済  
産業省｢企業活動基本調査｣に基づき、<u>②海外子会社を保有する企業</u>の割合の推移を見  
ても、大企業には及ばないものの、中小企業の海外子会社保有割合は増加傾向にあ  
る。もっとも大企業と中小企業では<u>③海外子会社の地域構成</u>や投資目的に違いも見ら  
れる。中小企業の海外展開支援に際しては、政府や公的支援機関による支援に加え  
て、企業の実情に通じた中小企業診断士をはじめとする民間の支援事業者の活躍が  
期待されている。  

（設問3）    
次の文章の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記  
の解答群から選べ。  

なお、ここでは中小企業のうち、従業者50人以上、かつ資本金または出資金  
3，000万円以上の企業を対象とする。大企業は中小企業基本法に定義する中小企  
業以外をいう。  

文中の下線部③について、経済産業省｢平成24年企業活動基本調査｣に基づき、  
中国I香港を含む)、アジア(中国を除く)、ヨーロッパ、北米に大別して、海外子  
会社の地域構成割合I2011年度Nを見た場合、大企業ではＡの割合、中  
小企業ではＢの割合が最も高い。  

[解答群]  
  
ア  Ａ：アジア(中国を除く) Ｂ：中国(香港を含む)  
  
イ  Ａ：中国(香港を含む) Ｂ：アジア(中国を除く)  
  
ウ  Ａ：中国(香港を含む) Ｂ：中国(香港を含む)  
  
エ  Ａ：北米 Ｂ：中国(香港を含む)  
  
オ  Ａ：ヨーロッパ Ｂ：アジア(中国を除く)  
        
`
        },
        {
                "mondaiId": 107270040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財務省｢法人企業統計年報｣に基づき、法人企業の主要財務指標(非一次産業、  
2012年度、中央値)を企業規模別に比較した記述として、<u>最も不適切なものはどれ  
か</u>。  
なお、企業規模区分は中小企業基本法の定義に準ずるものとする。ただし、ゴム  
製品製造業は常用雇用者900人以下、旅館、ホテルは常用雇用者200人以下、ソフ  
トウエア業、情報処理・提供サービス業は資本金3億円以下または常用雇用者300  
人以下の企業を中小企業に含む。中小企業以外の企業を大企業とする。  
  
ア  大企業の売上高経常利益率は中小企業を上回っている。  
  
イ  大企業の自己資本比率は中小企業を上回っている。  
  
ウ  大企業の総資本回転率は中小企業を上回っている。  
  
エ  大企業の付加価値率は中小企業を上回っている。  
  
オ  大企業の労働生産性は中小企業を上回っている。  
        
`
        },
        {
                "mondaiId": 107270051,
                "name": "第5問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
人口減少・少子高齢化、グローバル化の進展、<u>①就業構造の変化</u>などの経済・社会  
構造の変化を受けて、地域経済を取り巻く環境は厳しさを増している。こうした中  
で、地域資源の活用が地域活性化の方策のひとつとして注目を集めている。  

中小企業地域産業資源活用促進法に基づき、地域産業資源として各都道府県が指  
定している件数は、2014年2月末時点で13，780件に達し、<u>②事業計画の認定件数も  
2013年度までに1，214件に達している</u>。なお、地域産業資源の分類ごとの累計の  
指定・認定状況を見ると、都道府県が地域産業資源として指定した件数は、  
Ａがおおむね半分を占めるのに対して、事業計画として認定を受けた件数  
はＢが過半を占めている。  

（設問1）  
文中の下線部①について、国税庁｢民間給与等実態調査｣に基づき、製造業とサ  
ービス業の平均給与と給与所得者数を2002年と2012年について各々比較した場  
合の記述として、<u>最も不適切なものはどれか</u>。  
なお、ここで給与所得者とは、役員、正規職員、非正規職員の合計をいい、\f  
年未満の勤続者も含む。給与支給総額とは、給料・手当および賞与の合計額をい  
う。  
  
ア  サービス業の給与所得者数は増加している。  
  
イ  サービス業の平均給与は減少している。  
  
ウ  製造業の給与所得者数は減少している。  
  
エ  製造業の平均給与は2002年、2012年ともサービス業を下回っている。  
  
オ  製造業の平均給与は増加している。  
        
`
        },
        {
                "mondaiId": 107270052,
                "name": "第5問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  
人口減少・少子高齢化、グローバル化の進展、<u>①就業構造の変化</u>などの経済・社会  
構造の変化を受けて、地域経済を取り巻く環境は厳しさを増している。こうした中  
で、地域資源の活用が地域活性化の方策のひとつとして注目を集めている。  

中小企業地域産業資源活用促進法に基づき、地域産業資源として各都道府県が指  
定している件数は、2014年2月末時点で13，780件に達し、<u>②事業計画の認定件数も  
2013年度までに1，214件に達している</u>。なお、地域産業資源の分類ごとの累計の  
指定・認定状況を見ると、都道府県が地域産業資源として指定した件数は、  
Ａがおおむね半分を占めるのに対して、事業計画として認定を受けた件数  
はＢが過半を占めている。  

（設問2）  

文中の下線部②について、2007年度から2013年度の期間について、中小企業  
地域産業資源活用促進法に基づく事業計画の認定件数の推移と認定者別累計認定  
件数を見た場合の記述として、<u>最も適切なものはどれか</u>。  
  
ア  共同申請による認定が全体の3割を占めている。  
  
イ  単独申請による認定が全体の9割超を占めている。  
  
ウ  認定件数は一貫して減少している。  
  
エ  認定件数は一貫して増加している。`
        },
        {
                "mondaiId": 107270053,
                "name": "第5問設問3",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  
人口減少・少子高齢化、グローバル化の進展、<u>①就業構造の変化</u>などの経済・社会  
構造の変化を受けて、地域経済を取り巻く環境は厳しさを増している。こうした中  
で、地域資源の活用が地域活性化の方策のひとつとして注目を集めている。  

中小企業地域産業資源活用促進法に基づき、地域産業資源として各都道府県が指  
定している件数は、2014年2月末時点で13，780件に達し、<u>②事業計画の認定件数も  
2013年度までに1，214件に達している</u>。なお、地域産業資源の分類ごとの累計の  
指定・認定状況を見ると、都道府県が地域産業資源として指定した件数は、  
Ａがおおむね半分を占めるのに対して、事業計画として認定を受けた件数  
はＢが過半を占めている。  

（設問3）    
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：観光資源Ｂ：鉱工業品  
  
イ  Ａ：観光資源Ｂ：農林水産物  
  
ウ  Ａ：鉱工業品Ｂ：観光資源  
  
エ  Ａ：鉱工業品Ｂ：農林水産物  
  
オ  Ａ：農林水産物Ｂ：観光資源  
        
`
        },
        {
                "mondaiId": 107270061,
                "name": "第6問設問1",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
近年、ITを利用した外部資源を活用する手段のひとつとして、<u>クラウドソーシ  
ング</u>が注目を集めている。クラウドソーシングとは、インターネットを介して不特  
定多数の個人や企業にアクセスして、必要な人材を調達する仕組みである。実際に  
従業員を雇用するわけではなく、あくまで業務を発注する人材をクラウドソーシン  
グサイト等で見つけ、仕事の発注を行うものである。  

中小企業庁｢日本のクラウドソーシングの利用実態に関する調査(2013年12月)｣  
に基づき、発注者がクラウドソーシングを利用するメリットと課題について見た場  
合I複数回答N、発注者のメリットについてはＡと回答する割合が  
Ｂと回答する割合より高く、発注者の課題についてはＣと回答す  
る割合がＤと回答する割合より高い。  

中小企業が経営資源の補完を目的にしてクラウドソーシングを活用するために  
は、クラウドソーシングの特徴を十分理解したうえで利用することが求められる。  
        
（設問1）  
文中の下線部について、文中の調査に基づき、クラウドソーシングサイトにお  
いて発注者が発注した仕事内容として回答した割合I複数回答Nが高いものから低  
いものへと並べた組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａウェブ開発関連(ウェブ開発、スマホアプリ開発、ソフトウエア開発、EC  
サイト制作等)  

ｂ画像・動画加工関連(画像加工、写真加工、動画作成等)  

ｃデザイン関連(ロゴ作成、キャラクター作成、イラスト作成、名刺作成、チ  
ラシ作成等)  

[解答群]  
  
ア  ａウェブ開発関連　－　ｂ画像・動画加工関連　－　ｃデザイン関連  
  
イ  ａウェブ開発関連　－　ｃデザイン関連　－　ｂ画像・動画加工関連  
  
ウ  ｂ画像・動画加工関連　－　ａウェブ開発関連　－　ｃデザイン関連  
  
エ  ｂ画像・動画加工関連　－　ｃデザイン関連　－　ａウェブ開発関連  
  
オ  ｃデザイン関連　－　ａウェブ開発関連　－　ｂ画像・動画加工関連  
        
`
        },
        {
                "mondaiId": 107270062,
                "name": "第6問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  
近年、ITを利用した外部資源を活用する手段のひとつとして、<u>クラウドソーシ  
ング</u>が注目を集めている。クラウドソーシングとは、インターネットを介して不特  
定多数の個人や企業にアクセスして、必要な人材を調達する仕組みである。実際に  
従業員を雇用するわけではなく、あくまで業務を発注する人材をクラウドソーシン  
グサイト等で見つけ、仕事の発注を行うものである。  

中小企業庁｢日本のクラウドソーシングの利用実態に関する調査(2013年12月)｣  
に基づき、発注者がクラウドソーシングを利用するメリットと課題について見た場  
合I複数回答N、発注者のメリットについてはＡと回答する割合が  
Ｂと回答する割合より高く、発注者の課題についてはＣと回答す  
る割合がＤと回答する割合より高い。  

中小企業が経営資源の補完を目的にしてクラウドソーシングを活用するために  
は、クラウドソーシングの特徴を十分理解したうえで利用することが求められる。  
        
（設問2）  

文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：コストの削減Ｂ：質の高い成果物の受取  
  
イ  Ａ：自社のPRＢ：質の高い成果物の受取  
  
ウ  Ａ：自社のPRＢ：必要な時のみ発注可能  
  
エ  Ａ：質の高い成果物の受取Ｂ：必要な時のみ発注可能  
  
オ  Ａ：必要な時のみ発注可能Ｂ：コストの削減`
        },
        {
                "mondaiId": 107270063,
                "name": "第6問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
                
次の文章を読んで、下記の設問に答えよ。  
近年、ITを利用した外部資源を活用する手段のひとつとして、<u>クラウドソーシ  
ング</u>が注目を集めている。クラウドソーシングとは、インターネットを介して不特  
定多数の個人や企業にアクセスして、必要な人材を調達する仕組みである。実際に  
従業員を雇用するわけではなく、あくまで業務を発注する人材をクラウドソーシン  
グサイト等で見つけ、仕事の発注を行うものである。  

中小企業庁｢日本のクラウドソーシングの利用実態に関する調査(2013年12月)｣  
に基づき、発注者がクラウドソーシングを利用するメリットと課題について見た場  
合I複数回答N、発注者のメリットについてはＡと回答する割合が  
Ｂと回答する割合より高く、発注者の課題についてはＣと回答す  
る割合がＤと回答する割合より高い。  

中小企業が経営資源の補完を目的にしてクラウドソーシングを活用するために  
は、クラウドソーシングの特徴を十分理解したうえで利用することが求められる。  
        
（設問3）  

文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：作業の進捗管理に労力がかかるＤ：仕事の質が不安定  
  
イ  Ｃ：仕事の質が不安定Ｄ：作業の進捗管理に労力がかかる  
  
ウ  Ｃ：受注者の評価の信ぴょう性Ｄ：作業の進捗管理に労力がかかる  
  
エ  Ｃ：受注者の評価の信ぴょう性Ｄ：利用手数料の高さ  
  
オ  Ｃ：利用手数料の高さＤ：仕事の質が不安定  
        
`
        },
        {
                "mondaiId": 107270070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
情報化の進展により、中小企業を取り巻く経営環境は大きく変わっており、顧客  
ニーズの多様化や同業他社との競争激化が進む一方で、新たな販売機会の創出や市  
場の拡大といったビジネスチャンスも生じている。しかしながら、大企業と中小企  
業の規模間で見た情報格差はいまだ大きく、多くの中小企業が情報化の進展による  
ビジネスチャンスを活かせていないのが実情である。  
中小企業庁｢ITの活用に関するアンケート調査(2012年11月)｣に基づき、規模  
別・利用形態別のITの導入状況を見た場合の記述として、最も適切なものはどれ  
か。  
なお、ここでは従業員300人以下(卸売業・サービス業は100人以下、小売業は  
50人以下)の企業を中小企業とする。中小企業のうち、従業員20人以下(卸売業、  
サービス業、小売業は5人以下)の企業を小規模企業とする。  
  
ア  小規模企業の3割以上が自社サイトでの製品販売・予約受付を実施している。  
  
イ  小規模企業の8割以上が自社ホームページを開設している。  
  
ウ  小規模企業を除く中小企業の8割以上が自社ホームページを開設している。  
  
エ  小規模企業を除く中小企業の半数以上が自社サイトでの製品販売・予約受付を  
実施している。  
        
`
        },
        {
                "mondaiId": 107270081,
                "name": "第8問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  

わが国の中小企業支援においては、従来から<u>①商工会</u>、商工会議所、中小企業団体  
中央会等が<u>②大きな役割を果たしてきた</u>が、中小企業が抱える経営課題が多様化・複  
雑化する中で、近年より専門性の高い支援事業を行うことが求められるようになっ  
てきた。  

厳しい経営環境に直面する中小企業を支援するためには、国、自治体、民間事業  
者を含めた支援を行う各機関が連携し、各々の強みを活かすことで、地域全体にお  
ける中小企業に対する支援機能の質を高めていくことが求められている。  

（設問1）  
文中の下線部①について、全国商工会連合会｢商工会実態調査｣に基づき、2003  
年度から2012年度の期間について、商工会の会員数と経営指導に従事する職員  
数の推移を見た場合の記述として、最も適切なものはどれか。  
  
ア  会員数、職員数とも一貫して減少している。  
  
イ  会員数、職員数とも一貫して増加している。  
  
ウ  会員数、職員数とも横ばいで推移している。  
  
エ  会員数は一貫して減少しているが、職員数は横ばいで推移している。  
  
オ  会員数は横ばいで推移しているが、職員数は一貫して減少している。  
        
`
        },
        {
                "mondaiId": 107270082,
                "name": "第8問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `                
次の文章を読んで、下記の設問に答えよ。  

わが国の中小企業支援においては、従来から<u>①商工会</u>、商工会議所、中小企業団体  
中央会等が<u>②大きな役割を果たしてきた</u>が、中小企業が抱える経営課題が多様化・複  
雑化する中で、近年より専門性の高い支援事業を行うことが求められるようになっ  
てきた。  

厳しい経営環境に直面する中小企業を支援するためには、国、自治体、民間事業  
者を含めた支援を行う各機関が連携し、各々の強みを活かすことで、地域全体にお  
ける中小企業に対する支援機能の質を高めていくことが求められている。  

（設問2）  

文中の下線部②について、中小企業庁｢中小企業支援機関の連携状況と施策認  
知度に関する調査I2013年11月N｣に基づき、商工会、商工会議所、中小企業団  
体中央会における実際の相談分野として回答された割合が高いものから低いもの  
へと並べた組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａ金融支援  
ｂ経営改善支援  
ｃ販路開拓・マーケティング支援  
[解答群]  
  
ア  ａ金融支援　－　ｂ経営改善支援　－　ｃ販路開拓・マーケティング支援  
  
イ  ａ金融支援　－　ｃ販路開拓・マーケティング支援　－　ｂ経営改善支援  
  
ウ  ｂ経営改善支援　－　ａ金融支援　－　ｃ販路開拓・マーケティング支援  
  
エ  ｃ販路開拓・マーケティング支援　－　ａ金融支援　－　ｂ経営改善支援  
  
オ  ｃ販路開拓・マーケティング支援　－　ｂ経営改善支援　－　ａ金融支援  
        
`
        },
        {
                "mondaiId": 107270091,
                "name": "第9問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
産業集積は、地理的に近接した特定の地域内に多数の企業が、相互関連性を以て  
立地している状態である。こうした産業集積は全国各地に点在するが、形成過程や  
特質に応じて、企業城下町型集積、産地型集積、<u>①都市複合型集積等</u>に大別できる。<u>②わが国のものづくり</u>にとって、産業集積は基盤的な存在といえるが、国内市場の  
縮小や大手企業の生産の海外移転等を背景に、多くの産業集積が苦境に立たされ縮  
小傾向にあるのが現状である。  

（設問1）  
文中の下線部①に関する説明として、最も適切なものはどれか。  
  
ア  機械金属関連の集積が多く、系列を超えたゆるやかな分業関係が形成されて  
いることが多い。  
  
イ  基礎素材関連の集積が多く、系列を超えたゆるやかな分業関係が形成されて  
いることが多い。  
  
ウ  消費財関連の集積が多く、垂直的な取引関係が形成されていることが多い。  
  
エ  消費財等の特定業種に属する企業が集中立地して形成された集積である。  
  
オ  特定の大企業の量産工場を中心に関連下請企業群が多数立地して形成された  
集積である。  
        
`
        },
        {
                "mondaiId": 107270092,
                "name": "第9問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
産業集積は、地理的に近接した特定の地域内に多数の企業が、相互関連性を以て  
立地している状態である。こうした産業集積は全国各地に点在するが、形成過程や  
特質に応じて、企業城下町型集積、産地型集積、<u>①都市複合型集積等</u>に大別できる。<u>②わが国のものづくり</u>にとって、産業集積は基盤的な存在といえるが、国内市場の  
縮小や大手企業の生産の海外移転等を背景に、多くの産業集積が苦境に立たされ縮  
小傾向にあるのが現状である。  

（設問2）  
文中の下線部②について、経済産業省｢工業統計表｣、総務省・経済産業省｢平  
成24年経済センサス－活動調査｣に基づき、2000年と2011年について、製造  
業事業所数、製造品出荷額等、1事業所当たりの製造品出荷額等の増減を見た場  
合の記述として、最も適切なものはどれか。  
なお、ここでは従業者数4人以上の事業所を対象とする。  
  
ア  製造業事業所数、製造品出荷額等、1事業所当たりの製造品出荷額等とも減  
少している。  
  
イ  製造業事業所数と1事業所当たりの製造品出荷額等のみ減少している。  
  
ウ  製造業事業所数と製造品出荷額等のみ減少している。  
  
エ  製造業事業所数のみ減少している。  
  
オ  製造品出荷額等と1事業所当たりの製造品出荷額等のみ減少している。`
        },
        {
                "mondaiId": 107270100,
                "name": "第10問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
東京商工リサーチ｢全国企業倒産白書｣に基づき、2008年から2013年の期間につ  
いて、企業倒産件数、負債金額、要因別倒産件数構成比の推移を見た場合の記述と  
して、最も適切なものはどれか。  
なお、ここでは負債金額1，000万円以上の企業について対象としている。  
  
ア  資本金1億円未満の企業倒産件数は一貫して増加している。  
  
イ  資本金1億円未満の企業の負債金額は一貫して減少している。  
  
ウ  全企業倒産件数は一貫して増加している。  
  
エ  全企業の負債金額は一貫して減少している。  
  
オ  要因別倒産件数構成比は販売不振が一貫して過半を占めている。  
        
`
        },
        {
                "mondaiId": 107270110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経営者保証は、経営者への規律付けや信用補完として資金調達の円滑化に寄与す  
る面がある一方で、経営者による思い切った事業展開や、早期の事業再生等を阻害  
する要因となる等、保証契約時・履行時等において様々な課題を有している。この  
ため｢経営者保証に関するガイドライン｣が策定され、平成26年から適用されてい  
る。  
｢経営者保証に関するガイドライン｣に関する記述として、<u>最も不適切なものはど  
れか</u>。  
  
ア  ガイドラインは法的拘束力を有していない。  
  
イ  早期に事業再生や廃業を決断した際に、一定の生活費等を残すことや｢華美で  
ない｣自宅に住み続けられることなどを検討する。  
  
ウ  法人と個人が明確に分離されている場合などに、経営者の個人保証を求めな  
い。  
  
エ  保証債務の履行時に返済しきれない債務残額は、原則として分割弁済が認めら  
れる。  
        
`
        },
        {
                "mondaiId": 107270120,
                "name": "第12問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
総務省｢就業構造基本調査｣に基づき、1997年以降の5年ごとにわが国の起業希  
望者数と起業家数の推移を見た場合の記述として、最も適切なものはどれか。  
なお、ここで起業希望者とは、有業者の転職希望者のうち｢自分で事業を起こし  
たい｣、または無業者のうち｢自分で事業を起こしたい｣と回答した者をいう。起業  
家とは、過去1年間に職を変えたまたは新たに職についた者のうち、現在は自営業  
主I内職者を除くNとなっている者をいう。  
  
ア  起業家数と起業希望者数は一貫して増加している。  
  
イ  起業家数は一貫して減少しているが、起業希望者数は横ばいで推移している。  
  
ウ  起業家数を1997年と2012年で比較するとほぼ半減している。  
  
エ  起業希望者数と起業家数とも横ばいで推移している。  
  
オ  起業希望者数を1997年と2012年で比較するとほぼ半減している。`
        },
        {
                "mondaiId": 107270130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　中小企業基本法の定義に基づく中小企業者に関する記述として、<u><u>最も不適切なものはどれか</u></u>。  
  
<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>

ア  従業員数60人で資本金が6千万円の食料品小売業は中小企業に該当し、従業員数3人で資本金100万円の食料品小売業は小規模企業に該当する。<hint hidden='true'>**
←❌
**</hint>  
  
イ  従業員数80人で資本金が2億円の化粧品卸売業は中小企業に該当し、従業員数5人で資本金が500万円の化粧品卸売業は小規模企業に該当する。  
  
ウ  従業員数80人で資本金が3千万円の飲食業は中小企業に該当し、従業員数5人で資本金500万円の飲食業は小規模企業に該当する。  
  
エ  従業員数500人で資本金が2億円の機械器具製造業は中小企業に該当し、従業員数20人で資本金が3千万円の機械器具製造業は小規模企業に該当する。  
        
`
        },
        {
                "mondaiId": 107270141,
                "name": "第14問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
小規模事業者は、地域の経済や雇用を支える極めて重要な存在であり、経済の好  
循環を全国津々浦々まで届けていくためには、その活力を最大限に発揮させること  
が必要不可欠である。  

平成25年の通常国会において、｢Ａ｣が成立したが、Ｂの基本  
理念にのっとりつつ、小規模企業に焦点を当て、｢Ａ｣をさらに一歩進める  
観点から、平成26年の通常国会において｢<u>小規模企業振興基本法(小規模基本法)</u>｣  
および｢Ｃによる小規模事業者の支援に関する法律の一部を改正する法律  
(小規模支援法)｣が成立した。  

（設問1）  
文中の空欄ＡとBに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：小規模企業活性化法Ｂ：中小企業基本法  
  
イ  Ａ：小規模企業活性化法Ｂ：中小企業憲章  
  
ウ  Ａ：中小企業経営力強化支援法Ｂ：中小企業基本法  
  
エ  Ａ：中小企業経営力強化支援法Ｂ：中小企業憲章  
        
`
        },
        {
                "mondaiId": 107270142,
                "name": "第14問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
小規模事業者は、地域の経済や雇用を支える極めて重要な存在であり、経済の好  
循環を全国津々浦々まで届けていくためには、その活力を最大限に発揮させること  
が必要不可欠である。  

平成25年の通常国会において、｢Ａ｣が成立したが、Ｂの基本  
理念にのっとりつつ、小規模企業に焦点を当て、｢Ａ｣をさらに一歩進める  
観点から、平成26年の通常国会において｢<u>小規模企業振興基本法(小規模基本法)</u>｣  
および｢Ｃによる小規模事業者の支援に関する法律の一部を改正する法律  
(小規模支援法)｣が成立した。  

（設問2）  
文中の下線部に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  この法律において｢小企業者｣とは、おおむね常時使用する従業員の数が5人  
以下の事業者をいう。  
  
イ  この法律において｢小規模企業者｣とは、中小企業基本法に規定する小規模企  
業者をいう。  
  
ウ  この法律において政府は、小規模企業をめぐる情勢の変化などを勘案し、お  
おむね5年ごとに基本計画を変更するものとした。  
  
エ  この法律は、小規模企業の事業活動の活性化を図る観点から、中小企業基本  
法等の一部を改正し、｢基本理念｣と｢施策の方針｣を明確化するものである。`
        },
        {
                "mondaiId": 107270143,
                "name": "第14問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
小規模事業者は、地域の経済や雇用を支える極めて重要な存在であり、経済の好  
循環を全国津々浦々まで届けていくためには、その活力を最大限に発揮させること  
が必要不可欠である。  

平成25年の通常国会において、｢Ａ｣が成立したが、Ｂの基本  
理念にのっとりつつ、小規模企業に焦点を当て、｢Ａ｣をさらに一歩進める  
観点から、平成26年の通常国会において｢<u>小規模企業振興基本法(小規模基本法)</u>｣  
および｢Ｃによる小規模事業者の支援に関する法律の一部を改正する法律  
(小規模支援法)｣が成立した。  

（設問3）    
文中の空欄Ｃに入る語句として、最も適切なものはどれか。  
  
ア  商工会及び商工会議所  
  
イ  中小企業再生支援協議会  
  
ウ  都道府県  
  
エ  認定支援機関  
        
`
        },
        {
                "mondaiId": 107270151,
                "name": "第15問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業のうち特に小規模事業者は、経営内容が不安定であること、担保・信用  
力が乏しいこと等の理由から事業の生命線ともいうべき金融確保の面で極めて困難  
な立場に置かれている。  

こうした状況に鑑み、小規模事業者経営改善資金融資制度(マル経融資)は、小規  
模事業者の資金繰りを支援する制度である。  

（設問1）  
マル経融資I通常枠Nに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  貸付期間は、運転資金7年以内、設備資金10年以内である。  
  
イ  貸付限度額は2，000万円である。  
  
ウ  金利は日本政策金融公庫の基準金利である。  
  
エ  日本政策金融公庫の審査を経て、融資が実施される。`
        },
        {
                "mondaiId": 107270152,
                "name": "第15問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  

中小企業のうち特に小規模事業者は、経営内容が不安定であること、担保・信用  
力が乏しいこと等の理由から事業の生命線ともいうべき金融確保の面で極めて困難  
な立場に置かれている。  

こうした状況に鑑み、小規模事業者経営改善資金融資制度(マル経融資)は、小規  
模事業者の資金繰りを支援する制度である。  

（設問2）    
マル経融資(通常枠)の融資対象になるための要件に関する説明として、最も適  
切なものはどれか。  
  
ア  経常利益が黒字であること。  
  
イ  原則として同一の商工会・商工会議所の地区内で1年以上事業を行っている  
こと。  
  
ウ  商工会・商工会議所の会員であること。  
  
エ  商工会・商工会議所の経営指導員による経営指導を原則3カ月以上受けてい  
ること。  
        
`
        },
        {
                "mondaiId": 107270160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
JAPANブランド育成支援事業は、複数の中小企業・小規模事業者が連携し、自  
らが持つ素材や技術等の強みを踏まえた戦略を策定し、当該戦略に基づいて行う商  
品の開発や海外見本市への出展等を行うプロジェクトを支援することにより、中小  
企業・小規模事業者の海外販路開拓の実現を図るものである。  

この事業に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ｢戦略策定段階への支援｣は、自らの強みを分析し、明確なブランドコンセプト  
等と基本戦略を固めるため、専門家の招聘、市場調査、セミナー開催などを行  
うプロジェクトに対し、1年間に限り支援を実施する。  
  
イ  ｢ブランド維持・強化の支援｣は、ブランド確立後、最大5年間にわたり、ブラ  
ンド力の維持・強化のためのプロモーションや製品改良などの取り組みに対して  
継続的支援を行う。  
  
ウ  ｢ブランド確立段階への支援｣は、具体的な海外販路開拓を行うため、専門家の  
招  
しょう  
聘  
へい  
、新商品開発、海外展示会への出展等を行うプロジェクトに対し、最大3年間の支援を実施する。  
  
エ  ｢プロデュース支援｣は、海外現地のニーズ等に詳しい外部人材の活用による、  
日本の生活文化の特色を活かした魅力ある商材の海外需要獲得に向けた｢市場調  
査、商材改良、PR・流通｣まで一貫したプロデュース活動を支援する。  
        
`
        },
        {
                "mondaiId": 107270170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
家具小売業を経営するA氏から、｢従業員の将来の生活を考えて、安全確実な退  
職金制度を確立したい｣との相談を受けた中小企業診断士のB氏は、｢中小企業退  
職金共済制度｣を紹介することにした。  
この制度に関する、B氏のA氏への説明として、最も適切なものはどれか。  
  
ア  掛金の一部については非課税になります。  
  
イ  新規加入時には掛金の一部を国が助成します。  
  
ウ  (独)中小企業基盤整備機構と退職金共済契約を結びます。  
  
エ  納付した掛金合計額の範囲内で事業資金などの貸付けを受けることができま  
す。  
        
`
        },
        {
                "mondaiId": 107270180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士のX氏は、顧問先で機械製造業のY社長から｢交際費を支出し  
た場合の税制措置を知りたい｣との相談を受けた。以下は、X氏とY社長との会話  
である。  

会話中の空欄AとBに入る記述の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  

Ｘ氏：｢中小企業には交際費の損金算入の特例があります。｣  
Ｙ社長：｢当社も対象になるのでしょうか。｣  
Ｘ氏：｢対象は、資本金1億円以下の法人などです。御社も対象になりますよ。｣  
Ｙ社長：｢どのような措置が受けられるのでしょうか。｣  
Ｘ氏：｢ＡまたはＢのうち、どちらかを選択して損金算入でき  
ます。Ｂの場合、支出する飲食費についての上限はありません。  
詳しいことは、税理士に相談してくださいね。｣  
Ｙ社長：｢ありがとうございます。よく分かりました。｣  

[解答群]  
  
ア  Ａ：支出した交際費等の500万円までの全額Ｂ：支出した飲食費の50％  
  
イ  Ａ：支出した交際費等の500万円までの全額Ｂ：支出した飲食費の80％  
  
ウ  Ａ：支出した交際費等の800万円までの全額Ｂ：支出した飲食費の50％  
  
エ  Ａ：支出した交際費等の800万円までの全額Ｂ：支出した飲食費の80％  
        
`
        },
        {
                "mondaiId": 107270190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士A氏のもとに、下記のア?エの4人の個人事業主から経営資金  
借り入れに関する相談があった。A氏は、その中の1人に日本政策金融公庫の｢女  
性、若者／シニア起業家支援資金｣を紹介することにした。  
A氏が上記の融資制度を紹介した人物として、最も適切なものはどれか。  
  
ア  雑貨卸売業(新規開業して6年)を経営するB氏(28歳、男性)  
  
イ  惣菜小売店(新規開業して12年)を経営するC氏(60歳、女性)  
  
ウ  日本料理店(新規開業して10年)を経営するD氏(40歳、女性)  
  
エ  洋菓子小売店(新規開業して3年)を経営するE氏(50歳、男性)`
        },
        {
                "mondaiId": 107270200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
食品製造業のA社(従業員10名)は、円安による原材料コストの高騰の影響によ  
り利益が減少し、一時的に資金繰りに支障をきたしている。  

A社から資金の借り入れ相談を受けた中小企業診断士B氏は、中長期的には業  
況の回復が見込まれると判断し、日本政策金融公庫(国民生活事業)の｢セーフティ  
ネット貸付制度(経営環境変化対応資金)｣を紹介することとした。  

セーフティネット貸付制度の経営環境変化対応資金に関するB氏の説明として、  
最も適切なものはどれか。  
  
ア  一定の要件を満たせば、経営責任者の個人保証を免除する制度を利用できま  
す。  
  
イ  売上高や利益の減少について、市区町村等の認定が必要になります。  
  
ウ  貸付期間は5年以内です。  
  
エ  資金使途は運転資金が対象になり、設備資金は対象になりません。  
        
`
        },
        {
                "mondaiId": 107270210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
プログラマーのA氏、デザイナーのB氏、セキュリティ専門家のC氏、マーケ  
ティング専門家のD氏の4名は、共同でソフトウエアの開発販売事業を計画して  
いる。  
メンバーのA氏から、事業の進め方について相談を受けた中小企業診断士のE  
氏は、有限責任事業組合(LLP)の設立を勧め、この事業体を活用するメリットにつ  
いて、A氏に説明を行った。  
E氏の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  議決権と損益分配は出資比率に応じるため、シンプルで分かりやすい制度で  
す。  
  
イ  組合事業から発生するリスクに対して、各々が出資の価額の範囲で責任を負い  
ます。  
  
ウ  構成員課税となるため、損失が出れば、各組合員の所得と通算できます。  
  
エ  取締役会などの設置が不要です。  
        
`
        },
        {
                "mondaiId": 107270220,
                "name": "第22問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下請事業者は、親事業者から規格やデザインなどの指定を伴う製造、加工または  
修理の委託を受けて事業活動を行っており、しかも親事業者に対する取引依存度が  
高いことから、しばしば親事業者から不利な取引条件を強いられることがある。  

そこで国は、下請取引の適正化を図るため、昭和31年に下請代金支払遅延等防  
止法(下請代金法)を制定施行し、親事業者の不公正な取引行為を規制している。  

下請代金法で定められている｢親事業者の義務｣として、<u>最も不適切なものはどれ  
か</u>。  
  
ア  下請代金の支払期日を定める義務  
  
イ  書面を交付する義務  
  
ウ  書類の作成・保存義務  
  
エ  遅延利息の支払義務  
  
オ  返品時の事前通告義務`
        },
        {
                "mondaiId": 107270230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業者、協同組合等は、税制上の様々な特別措置を受けることができる。法  
人税率の特例(平成29年3月31日まで)に関する記述として最も適切なものはどれ  
か。  
  
ア  資本金100万円の小売業者は、年所得1，000万円以下の部分に軽減税率15％  
が適用される。  
  
イ  資本金500万円の製造業者は、年所得800万円以下の部分に軽減税率15％が  
適用される。  
  
ウ  資本金2億円の製造業者は、年所得800万円以下の部分に軽減税率15％が適  
用される。  
  
エ  中小企業等協同組合は、年所得1，000万円以下の部分に軽減税率15％が適用  
される。  
        
`
        },
        {
                "mondaiId": 107270241,
                "name": "第24問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
後継者に事業を引き継ぐ場合、｢中小企業における経営の承継の円滑化に関する  
法律｣に基づき、事業承継円滑化に向けた金融や税制などの総合的な支援を受ける  
ことができる。  
事業承継税制については、この法律における経済産業大臣の認定を受けた  
Ａの後継者が対象となる。雇用確保をはじめとする事業継続要件などを満  
たす場合に、自社株式等にかかるＢやＣの納税が猶予される。  

（設問1）  
文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  資本金5，000万円以下の法人企業  
  
イ  資本金1億円以下の法人企業  
  
ウ  資本金3億円以上の法人企業  
  
エ  非上場中小企業`
        },
        {
                "mondaiId": 107270242,
                "name": "第24問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
後継者に事業を引き継ぐ場合、｢中小企業における経営の承継の円滑化に関する  
法律｣に基づき、事業承継円滑化に向けた金融や税制などの総合的な支援を受ける  
ことができる。  
事業承継税制については、この法律における経済産業大臣の認定を受けた  
Ａの後継者が対象となる。雇用確保をはじめとする事業継続要件などを満  
たす場合に、自社株式等にかかるＢやＣの納税が猶予される。  

（設問2）

文中の空欄ＢとＣに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｂ：相続税Ｃ：住民税  
  
イ  Ｂ：相続税Ｃ：贈与税  
  
ウ  Ｂ：法人税Ｃ：住民税  
  
エ  Ｂ：法人税Ｃ：贈与税  
        
`
        },
        {
                "mondaiId": 107270250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
輸入食品卸売業のA社は、景気や為替相場の変動の理由により、事業活動の縮  
小を余儀なくされている。A社の経営者は、一時的な休業、教育訓練を行うこと  
により労働者の雇用維持を図りたいと考えている。  
経営者から相談を受けた中小企業診断士のB氏は、A社に雇用調整助成金の利  
用を勧めることにした。この制度に関するB氏の説明として、<u>最も不適切なもの  
はどれか</u>。  
  
ア  教育訓練を行う場合、訓練費として1人1日当たり一定額の加算があります。  
  
イ  受給額は、1人1日当たり雇用保険基本手当日額の最高額を限度とします。  
  
ウ  中小企業の場合、助成率は休業手当または賃金相当額の5分の1です。  
  
エ  年間の支給限度日数が定められています。`
        },
        {
                "mondaiId": 107270260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組合制度は、中小規模の事業者・勤労者などが組織化し、共同購買事業、共同生  
産・加工事業、共同研究開発、共同販売事業、金融事業などの共同事業を通じて、  
技術・情報・人材等個々では不足する経営資源の相互補完を図るためのものであ  
る。  
主な中小企業組合としては、事業協同組合、企業組合、協業組合などがある。  
このうち、事業協同組合に関する記述として、最も適切なものはどれか。  
  
ア  組合員の2分の1以上は、組合の行う事業に従事しなければならない。  
  
イ  組合員は、自己の資本と労働力のすべてを組合に投入する。  
  
ウ  設立するに当たっては、組合員になろうとする者4人以上が発起人になること  
が必要である。  
  
エ  中小企業団体の組織に関する法律を根拠法規とする組合である。  
        
`
        },
        {
                "mondaiId": 107270270,
                "name": "第27問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
グローバルニッチトップ(GNT)支援貸付は、グローバルニッチトップ(GNT)企  
業およびその候補企業を対象に、長期安定資金を融資するものである。  
この融資制度に関する記述として、最も適切なものはどれか。  
  
ア  貸付限度額は、1貸付け当たり1億円である。  
  
イ  資金使途として、国内子会社に関する出資金がある。  
  
ウ  自社製品について、海外において一定のシェアを確保している企業が融資対象  
になる。  
  
エ  利率は、事業の成否に応じた成功払い金利となる。  
        
`
        },
        {
                "mondaiId": 107270281,
                "name": "第28問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
｢ものづくり・商業・サービス革新事業｣は、国内外のニーズに対応したサービス  
やものづくりの新事業を創出するため、革新的な設備投資やサービス・試作品の開  
発を行う中小企業者を支援するものである。  
この事業における｢ものづくり技術｣の対象となるためには、①｢中小ものづくり  
高度化法｣で指定された分野の技術を活用した事業であること、②どのように  
Ａを明記した事業計画を作り、その実効性についてＢの確認を受  
けていること、が必要である。  
｢革新的サービス｣の対象となるためには、①｢中小サービス事業者の生産性向上  
のためのガイドライン｣で示された方法で行う革新的なサービスの創出等であり、  
3~5年の事業計画で｢Ｃ｣年率3％、および｢Ｄ｣年率1％の向上  
を達成する計画であること、②どのようにＡを明記した事業計画を作り、  
その実効性についてＢの確認を受けていること、が必要である。  

（設問1）  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：雇用環境を整備し人材活用を促進するかＢ：都道府県  
  
イ  Ａ：雇用環境を整備し人材活用を促進するかＢ：認定支援機関  
  
ウ  Ａ：他者と差別化し競争力を強化するかＢ：都道府県  
  
エ  Ａ：他者と差別化し競争力を強化するかＢ：認定支援機関  
        
`
        },
        {
                "mondaiId": 107270282,
                "name": "第28問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2015,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
｢ものづくり・商業・サービス革新事業｣は、国内外のニーズに対応したサービス  
やものづくりの新事業を創出するため、革新的な設備投資やサービス・試作品の開  
発を行う中小企業者を支援するものである。  
この事業における｢ものづくり技術｣の対象となるためには、①｢中小ものづくり  
高度化法｣で指定された分野の技術を活用した事業であること、②どのように  
Ａを明記した事業計画を作り、その実効性についてＢの確認を受  
けていること、が必要である。  
｢革新的サービス｣の対象となるためには、①｢中小サービス事業者の生産性向上  
のためのガイドライン｣で示された方法で行う革新的なサービスの創出等であり、  
3~5年の事業計画で｢Ｃ｣年率3％、および｢Ｄ｣年率1％の向上  
を達成する計画であること、②どのようにＡを明記した事業計画を作り、  
その実効性についてＢの確認を受けていること、が必要である。  

（設問2）  

文中の空欄ＣとＤに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ｃ：売上高Ｄ：営業利益  
  
イ  Ｃ：売上高Ｄ：経常利益  
  
ウ  Ｃ：付加価値額Ｄ：営業利益  
  
エ  Ｃ：付加価値額Ｄ：経常利益  
`
        },
        {
                "mondaiId": 101260010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本の国内総生産に占める、｢雇用者報酬｣、｢営業余剰・混合所得｣、  
｢固定資本減耗｣、｢生産・輸入品に課される税と補助金の差額｣、それぞれの割合を  
ａ~ｄに表したものである。これらのうち、｢雇用者報酬｣の割合を表すものとして  
最も適切なものを下記の解答群から選べ。  
20121995200020052010（年）  
60.0  
50.0  
40.0  
30.0  
20.0  
10.0  
0.0  
（％）  
a  
b  
c  
d  
出所：O2012年度国民経済計算U(内閣府)から計算  
[解答群]  
  
ア  ａ  
  
イ  ｂ  
  
ウ  ｃ  
  
エ  ｄ  
        
`
        },
        {
                "mondaiId": 101260020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、次の  
つの指標の四半期ごとの推移を、ａ~ｄに表したものである。  
・大企業の生産・営業用設備判断DI  
・中小企業の生産・営業用設備判断DI  
・金融機関の大企業に対する貸出態度判断DI  
・金融機関の中小企業に対する貸出態度判断DI  
｢生産・営業用設備判断DI｣については｢過剰｣と回答した割合から｢不足｣と回答  
した割合を引いたもの、｢金融機関の貸出態度判断DI｣については｢緩い｣と回答し  
た割合から｢厳しい｣と回答した割合を引いたものとなっている。  
これらのうち、｢中小企業の生産・営業用設備判断DI｣(以下、｢設備｣という。)  
と、｢金融機関の中小企業に対する貸出態度判断DI｣(以下、｢貸出態度｣という。)を  
表すものとして、最も適切なものの組み合わせを下記の解答群から選べ。  
出所：O全国企業短期経済観測調査U(日本銀行)  
201320122011201020092008（年）  
25  
20  
15  
10  
5  
0  
－5  
－10  
－15  
－20  
a  
b  
c  
d  
        
        
[解答群]  
  
ア  設備がｂで、貸出態度がａ  
  
イ  設備がｄで、貸出態度がａ  
  
ウ  設備がｄで、貸出態度がｂ  
  
エ  設備がｄで、貸出態度がｃ`
        },
        {
                "mondaiId": 101260030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、アメリカとユーロ圏の消費と失業率の動向について描き出したものであ  
る。図中のａ~ｄに該当する国または地域の組み合わせとして、最も適切な���のを  
下記の解答群から選べ。  

<imgTag>101260030_1.png</imgTag>  

出所：「世界経済の潮流2013年II」内閣府  


<hint hidden='true'>**
冒頭問題の定番、統計データから該当する国・地域を問う問題です。  
　
aは、アメリカです。リーマンショックの2008年に景気が急速に悪化したアメリカですが、2009年以降、（ユーロ圏と比較すると）堅調に景気回復しています。  
bは、ユーロ圏です。    
cは、アメリカです。  
dは、ユーロ圏です。
**</hint>  


[解答群]  
  
ア  ａ：アメリカｂ：ユーロ圏ｃ：アメリカｄ：ユーロ圏  
  
イ  ａ：アメリカｂ：ユーロ圏ｃ：ユーロ圏ｄ：アメリカ  
  
ウ  ａ：ユーロ圏ｂ：アメリカｃ：アメリカｄ：ユーロ圏  
  
エ  ａ：ユーロ圏ｂ：アメリカｃ：ユーロ圏ｄ：アメリカ  
        
`
        },
        {
                "mondaiId": 101260041,
                "name": "第4問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　財市場における<hintTarget>総需要</hintTarget><img src="assets/img/toku/101260041_3.svg" style='height: 1em;width: auto;'>は、消費C、投資I、政府支出Gの合計であるとする。所得をY、限界消費性向をc、所得がゼロでも必要な最低限の定額の消費額を<img src="assets/img/toku/101260041_4.svg" style='height: 1em;width: auto;'>とすれば、消費は<img src="assets/img/toku/101260041_5.svg" style='height: 1em;width: auto;'>と書き表すことができる。総供給<img src="assets/img/toku/101260041_6.svg" style='height: 1em;width: auto;'>と所得が等しいとすれば、これらの関係から(1)式と(2)式が得られ、下図のように示すことができる。  
　いま、上記の標準的なモデルに追加して、所得Yに対して<hintTarget>定率tで課税</hintTarget>する線形の租税関数tYを考えると、消費関数は<img src="assets/img/toku/101260041_6.svg" style='height: 1em;width: auto;'>となり(3)式を得る。  
　また、企業投資が(3)式のIから外生的に<hintTarget>増加してI′</hintTarget>になった場合を(4)式で表記する。なお、税収は政府支出Gには影響を与えないものとする。  

このとき下記の設問に答えよ。  

<img src="assets/img/toku/101260041_2.svg" style='height: 6em;width: auto;'>
        
<imgTag>101260041_1.png</imgTag>  

<hint hidden='true'>**  
総需要線に関する問題です。
**</hint>



（設問1）  
この図の中に(4)式を描き、(2)式と比較した場合の記述として最も適切なものは  
どれか。  
  
ア  (2)式と(4)式の傾きは等しく、(4)式の縦軸の切片の位置は(2)式よりも下になる。  
  
イ  (4)式の傾きは(2)式よりも急になり、(4)式の縦軸の切片の位置は(2)式よりも上になる。  
  
ウ  (4)式の傾きは(2)式よりも急になり、(4)式の縦軸の切片の位置は(2)式よりも下になる。  
  
エ  (4)式の傾きは()式よりも緩くなり、(4)式の縦軸の切片の位置は(2)式よりも上になる。  
  
オ  (4)式の傾きは(2)式よりも緩くなり、(4)式の縦軸の切片の位置は(2)式よりも下になる。<hint hidden='true'>**  
←⭕
** </hint>

`
        },
        {
                "mondaiId": 101260042,
                "name": "第4問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　財市場における<hintTarget>総需要</hintTarget><img src="assets/img/toku/101260041_3.svg" style='height: 1em;width: auto;'>は、消費C、投資I、政府支出Gの合計であるとする。所得をY、限界消費性向をc、所得がゼロでも必要な最低限の定額の消費額を<img src="assets/img/toku/101260041_4.svg" style='height: 1em;width: auto;'>とすれば、消費は<img src="assets/img/toku/101260041_5.svg" style='height: 1em;width: auto;'>と書き表すことができる。総供給<img src="assets/img/toku/101260041_6.svg" style='height: 1em;width: auto;'>と所得が等しいとすれば、これらの関係から(1)式と(2)式が得られ、下図のように示すことができる。  
　いま、上記の標準的なモデルに追加して、所得Yに対して定率tで課税する線形の租税関数tYを考えると、消費関数は<img src="assets/img/toku/101260041_6.svg" style='height: 1em;width: auto;'>となり(3)式を得る。  
　また、企業投資が(3)式のIから外生的に増加してI′になった場合を(4)式で表記する。なお、税収は政府支出Gには影響を与えないものとする。  

このとき下記の設問に答えよ。  

  
<hint hidden='true'>**  
乗数効果に関する問題です。  
Yの増加イメージは、下記グラフのとおりです。  
このように、有効需要（ここでは投資）の増加幅以上に、国民所得（ここではY）が増加することを、乗数効果と呼びます。
**</hint>
        
<imgTag>101260042_1.png</imgTag>  
                                
（設問2）  
　他を一定として、企業投資がIから<hintTarget>I′へ1.8</hintTarget>だけ増加した形で(3)式から(4)式への変化が発生したものとする。このとき、所得<hintTarget>Yの変化</hintTarget>として最も適切なものはどれか。ただし、限界消費性向<hintTarget>cは0.8</hintTarget>、税率<hintTarget>tは0.2</hintTarget>とする。  


<hint hidden='true'>**  
<imgTag>101260042_2.png</imgTag>  
**</hint>



ア  Yは1増加する。  
  
イ  Yは1.8増加する。  
  
ウ  Yは5増加する。<hint hidden='true'>** ⭕
** </hint>    
  
エ  Yは9増加する。  
  
オ  Yは増加しない。  
        
`
        },
        {
                "mondaiId": 101260050,
                "name": "第5問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の2つの図は、標準的なIS‐LM分析の図である。両図において、初期状態  
がISとLMの交点であるE0として与えられている。政府支出の増加によってIS  
がIS′に変化したとき、以下の両図に関する説明として最も適切なものを下記の解  
答群から選べ。  
図\f  
利子率  
GDP  
図\u000e  
利子率  
GDP  
[解答群]  
  
ア  図\fが示すところによれば、政府支出の増加による総需要刺激効果は、クラ  
  
ウ  ディング・アウトによって完全に相殺されている。  
  
イ  図\fで点Aから点E1までの動きは、｢流動性の罠｣と呼ばれる状況が生じて  
いることを示している。  
  
ウ  図\fで点E0から点Aまでの動きは、政府支出の増加によるクラウディン  
グ・アウトの効果を示している。  
  
エ  図\u000eでは、政府支出の増加によって利子率が上昇することを示している。  
  
オ  図\u000eでは、政府支出の増加によるクラウディング・アウトは発生していな  
い。  
        
`
        },
        {
                "mondaiId": 101260060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　定期給与の増加または一時金の支給が消費に与える影響を、<hintTarget>恒常所得仮説</hintTarget>を用いて説明した記述として、最も適切なものはどれか。  

<hint hidden='true'>**
恒常所得仮説の定義は、下記を参照ください。**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/101003.png" target="_blank" >
<img src="assets/img/kaisetu/101003.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>

ア  一時金の支給は<hintTarget>恒常所得の減少</hintTarget>にあたり、消費を増加させる。  

<hint hidden='true'>**
❌変動所得の増加です。恒常所得に影響しません。
** </hint>

イ  一時金の支給は変動所得にあたり、消費を<hintTarget>減少</hintTarget>させる。  
  
<hint hidden='true'>**
❌一時金（変動所得）の増減は、消費に影響しません。
** </hint>

ウ  定期給与のベースアップは恒常所得の増加にあたり、消費を増加させる。  

<hint hidden='true'>**
⭕
** </hint>

エ  定期給与のベースアップ分は<hintTarget>変動所得</hintTarget>にあたり、消費を変化させない。
  
<hint hidden='true'>**
❌恒常所得です。
** </hint>


`
        },
        {
                "mondaiId": 101260070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
投資理論に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ケインズ型投資理論では、企業は資本の限界効率がゼロとなるところに投資水  
準を決める。  
  
イ  資本の限界生産物は資本の水準が高まるにつれて逓減すると仮定すれば、新古  
典派の投資理論では、資本の使用者費用の増加は投資を減らすと考えられる。  
  
ウ  資本の限界生産物は資本の水準が高まるにつれて逓減すると仮定すれば、新古  
典派の投資理論では、生産技術の進歩による資本の限界生産物の増加は投資を増  
やすと考えられる。  
  
エ  投資の加速度原理では、GDPの成長が高いときには投資も増えるとされる。  
        
`
        },
        {
                "mondaiId": 101260080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経常収支の決定を考える理論的枠組みのひとつに、経常収支をGDPと国内アブ  
ソープションとの差であるとみるアブソープション・アプローチがある。実質外国  
為替相場の減価がGDPと国内アブソープションに与える影響として、以下の?と  
?において最も適切なものの組み合わせを下記の解答群から選べ。  
?GDPに与える影響  
ａ実質外国為替相場が減価すると輸出が拡大するために、GDPは増加する。  
ｂ実質外国為替相場が減価すると輸出が拡大するために、GDPは減少する。  
ｃ実質外国為替相場が減価すると輸出が縮小するために、GDPは増加する。  
?国内アブソープションに与える影響  
ｄ実質外国為替相場減価によるGDP増加に伴い、国内アブソープションが  
増加する。  
ｅ実質外国為替相場減価によるGDP増加に伴い、国内アブソープションが  
減少する。  
ｆ実質外国為替相場減価によるGDP減少に伴い、国内アブソープションが  
増加する。  
[解答群]  
  
ア  ?：ａ~：ｄ  
  
イ  ?：ａ~：ｅ  
  
ウ  ?：ｂ?：ｆ  
  
エ  ?：ｃ?：ｄ  
        
`
        },
        {
                "mondaiId": 101260090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　マネーストックあるいはマネタリーベースに含まれるものとして最も適切なものはどれか。  
  
<hint hidden='true'>**
マネタリーベース、マネーストックに関する問題です。各用語の定義は、画面下部の類似問題を参照ください。  
** </hint>

ア  日銀当座預金は<hintTarget>マネーストック</hintTarget>に含まれる。  

<hint hidden='true'>**
❌マネタリーベースに含まれます
** </hint>

イ  日銀当座預金はマネタリーベースに含まれる。  

<hint hidden='true'>**
⭕
** </hint>

ウ  <hintTarget>預金取扱機関の</hintTarget>保有現金はマネーストックに<hintTarget>含まれる</hintTarget>。  

<hint hidden='true'>**
❌含まれません。預金取扱機関<hintTarget>への預金</hintTarget>であれば含まれます。
** </hint>

エ  預金取扱機関への預金は<hintTarget>マネタリーベース</hintTarget>に含まれる。

<hint hidden='true'>**
❌マネーストックに含まれます
** </hint>

`
        },
        {
                "mondaiId": 101260100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
古典派経済学体系での貨幣の扱いと金融政策に関する記述として、最も適切なも  
のはどれか。  
  
ア  k％ルールとは、物価上昇率を一定の値に収める金融政策運営上のルールであ  
る。  
  
イ  貨幣市場の均衡条件によって実質利子率が決まり、貨幣的側面が実物面に影響  
を与える。  
  
ウ  実質GDPは労働市場の均衡から決定されるため、貨幣供給量を増やしても実  
質GDPは拡大しない。  
  
エ  数量方程式で表される貨幣需要には投機的動機のみを想定している。  
        
`
        },
        {
                "mondaiId": 101260110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
景気循環理論のひとつに、リアル・ビジネス・サイクル理論がある。この理論に  
よる景気循環の説明として最も適切なものはどれか。  
  
ア  貨幣供給量の変動が予想されないインフレーションを生み出し、家計や企業の  
行動を撹  
かく  
乱することを通じて、景気循環を生む。  
  
イ  技術進歩に代表される供給ショックが、景気循環を生む。  
  
ウ  経済規模は需要サイドから決まり、有効需要の変動が景気循環を生む。  
  
エ  マクロ経済が不均衡にある状態によって、景気循環が生まれる。  
        
`
        },
        {
                "mondaiId": 101260120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、日本のGDP成長率、GDP成長率への労働の寄与、GDP成長率への資  
本の寄与を表したものである。成長会計から、GDP成長率への全要素生産性  
\u001fTFPの寄与を下表から読み取った記述として最も適切なものはどれか。  
1995‐1999年  
0.7安0.2安0.1  
4.62.00.9  
1985‐1989年1990‐1994年  
労働の寄与  
実質GDP成長率  
2.32.2資本の寄与1.2  
2005‐2009年  
安0.4  
安0.2  
0.5  
2000‐2004年  
1.4  
安0.1  
0.6  
\u001f単位：％  
出所：O通商白書2013U\u001f経済産業省から作成  
  
ア  GDP成長率へのTFPの寄与は、｢1985‐1989年｣から｢2005‐2009年｣まで一貫  
してプラスであった。  
  
イ  GDP成長率へのTFPの寄与は、｢1985‐1989年｣と｢2000‐2004年｣ではプラス  
であった。  
  
ウ  GDP成長率へのTFPの寄与は、｢1985‐1989年｣のみマイナスであった。  
  
エ  GDP成長率へのTFPの寄与は、｢2005‐2009年｣のみマイナスであった。  
        
`
        },
        {
                "mondaiId": 101260131,
                "name": "第13問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図の形状をした生産関数について下記の設問に答えよ。ただし、ここでの生産  
に投入される要素は労働のみであり、その投入量はゼロより大きいものとする。  
労働量  
生産関数  
生産量  
（設問1）  
この図に関する説明として最も適切なものはどれか。  
  
ア  この生産関数では、限界生産物は労働の投入量が増加するほど大きくなる。  
  
イ  この生産関数では、ある労働の投入量のもとで平均生産物は限界生産物より  
も大きい。  
  
ウ  この生産関数では、平均生産物は労働の投入量が増加するほど大きくなる。  
  
エ  この生産関数は、収穫一定であることを示している。  
        
`
        },
        {
                "mondaiId": 101260132,
                "name": "第13問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問2）  
この図に描かれた生産関数を用いて、縦軸に実質賃金を、横軸に労働量を取  
り、労働需要曲線を導出する。このとき、労働需要に関する説明として最も適切  
なものはどれか。  
  
ア  企業の利潤最大化行動を前提として導出される労働需要曲線は、右下がりと  
なる。  
  
イ  利潤最大化を目指す企業は、生産関数の接線の傾きが生産物価格と一致する  
ように、労働量を決定する。  
  
ウ  利潤最大化を目指す企業は、労働の限界生産物がゼロとなるところに労働量  
を決定するため、労働需要曲線は水平になる。  
  
エ  労働需要が実質賃金の増加関数であることは、古典派の第二公準として知ら  
れている。  
        
`
        },
        {
                "mondaiId": 101260140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、ある財市場における生産者の供給曲線と消費者の需要曲線が描かれて  
いる。ただし、当該財には税が課されており、課税前の需要曲線と、課税後の需要  
曲線とが示されている。この図に関する説明として最も適切なものを下記の解答群  
から選べ。  
数量  
価格  
課税後の  
需要曲線  
供給曲線  
課税前の需要曲線  
A  
G  
B  
CD  
EF  
[解答群]  
  
ア  課税によって発生する死重損失のうち、消費者の損失分はBDGで示され  
る。  
  
イ  課税によって発生する死重損失のうち、消費者の損失分はCDEで示され  
る。  
  
ウ  需要の価格弾力性が相対的に小さいため、租税の影響は消費者への帰着がよ  
り大きい。  
  
エ  線分BFは、消費者が直面する課税前の購入価格と課税後の購入価格の違い  
を示している。  
        
`
        },
        {
                "mondaiId": 101260150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
���図には、予算制約線Aと予算制約線Bおよび、これらの予算制約線上に��る  
a，b，c，d，eという5つの点が描かれている。ある合理的な消費者にとって最も  
高い効用をもたらすのは、予算制約線A上ならば点cであり、予算制約線B上な  
らば点dであることがわかっている。この図の説明として最も適切なものを下記  
の解答群から選べ。  
財Yの消費量  
財Xの消費量  
b  
c  
d  
e  
a  
予算制約線B  
予算制約線A  
[解答群]  
  
ア  図中に点cより効用が高い点はない。  
  
イ  図中で点cより効用が高い点は、点aと点eである。  
  
ウ  図中で点dより効用が高い点は、点cである。  
  
エ  図中に点dより効用が高い点はない。  
        
`
        },
        {
                "mondaiId": 101260160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、財Xと財Yを消費する合理的個人が予算制約線Aに直面し、予算制約  
線Aと無差別曲線U1との接点Lで効用を最大化する状態を描いている。他の条  
件を一定として、財Xの価格の低下によって予算制約線がBへと変化すると、こ  
の合理的個人は、予算制約線Bと無差別曲線U2との接点Nを選択することで効用  
を最大化することができる。なお、破線で描かれた補助線は、予算制約線Bと同  
じ傾きを有し、点Mで無差別曲線U1と接している。この図に関する説明として最  
も適切なものを下記の解答群から選べ。  
予算制約線B  
予算制約線A  
L  
M  
N  
DEF  
財Xの消費量  
財Yの消費量  
[解答群]  
  
ア  この図における財Xは、下級財の特性を示している。  
  
イ  財Xの価格の低下によって効用を最大化する消費の組み合わせは点Lから  
点Nへ変化した。この変化のうち｢所得効果｣は点Lから点Mへの変化によっ  
て示されている。  
  
ウ  財Xの価格の低下によって効用を最大化する消費の組み合わせは点Lから  
点Nへ変化した。この変化のうち｢代替効果｣は点Mから点Nへの変化によっ  
て示されている。  
  
エ  財Xの価格の低下による｢代替効果｣のみを考えると、財Yの消費量が減少  
することが示されている。  
        
`
        },
        {
                "mondaiId": 101260170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、標準的なエッジワース・ボックスであり、左下にAさんの原点、右上  
にBさんの原点がとられている。横軸はAさんにとっての財Xの量xAとBさん  
にとっての財Xの量xBを意味し、縦軸はAさんにとっての財Yの量yAとBさん  
にとっての財Yの量yBを意味している。図中にあるAさんの無差別曲線はAさ  
んの原点に近い側からUA  
1，UA  
2，UA  
3，UA  
4となっている。Bさんの無差別曲線はB  
さんの原点に近い側からUB  
1，UB  
2，UB  
3，UB  
4となっている。点C，点D，点F，点G  
は、AさんとBさんの無差別曲線が接する点であり、これらの点を結んだ軌跡が  
右上がりの実線として描かれている。この図に関する説明として最も適切なものを  
下記の解答群から選べ。  
Bさんの原点  
Aさんの原点  
[解答群]  
  
ア  Bさんにとって、点Gは、点Eよりも効用が高い。  
  
イ  Bさんにとって、点Gは、点Iよりも効用が高い。  
  
ウ  点C、点D、点F、点Gはパレート最適であり、これらの点を通過する右  
上がりの実線は｢契約曲線｣と呼ばれる。  
  
エ  点Cと点Dは、AさんとBさんの双方にとって無差別である。  
  
オ  点Dは、点Gに比べてパレートの意味で効率性を改善する配分である。  
        
`
        },
        {
                "mondaiId": 101260180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、完全競争下の企業の総費用曲線と総収入曲線を示したものである。この  
図に基づいて利潤曲線を描いたものとして最も適切なものを下記の解答群から選  
べ。  
        
        
[解答群]  
  
イ    
利潤  
生産量  
利潤曲線  
0  
  
ウ    
利潤  
生産量  
利潤曲線  
0  
  
オ    
利潤  
生産量  
利潤曲線  
0  
  
エ    
利潤  
生産量  
利潤曲線  
0  
  
ア    
利潤  
生産量  
利潤曲線  
0  
        
`
        },
        {
                "mondaiId": 101260190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、独占市場におけるある企業の短期の状況を描いたものである。ACは平  
均費用曲線、MCは限界費用曲線、Dは需要曲線、MRは限界収入曲線であり、独  
占企業が選択する最適な生産量は、MCとMRの交点で定まるWとなる。この図  
に関する説明として最も適切なものを下記の解答群から選べ。  
数量  
価格  
MC  
AC  
C  
B  
A  
W  
L  
M  
ND  
MR  
[解答群]  
  
ア  この独占企業が得る利潤は、□ALMBで示される。  
  
イ  この独占企業が得る利潤は、□ALNCで示される。  
  
ウ  生産量Wのとき、限界収入曲線が平均費用を下回るため、□BMNCの損失  
が発生する。  
  
エ  生産量Wのとき、需要曲線が平均費用を上回るため、□ALMBの損失が発  
生する。  
        
`
        },
        {
                "mondaiId": 101260200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、企業Rが直面する競争的な財市場における私的限界費用曲線、社会  
的限界費用曲線が描かれている。社会的限界費用曲線と私的限界費用曲線との乖離  
は、企業Rの生産活動に負の外部性が伴うことを意味する。この負の外部性の負  
担者は企業Sのみであり、企業Rとの交渉を費用ゼロで行うことができる。また、  
企業Rの生産活動に対して、政府は外部性を相殺するピグー課税を導入すること  
もできる。この図に関する説明として最も適切なものを下記の解答群から選べ。  
ただし、下図で、aefを単にA、□acgfを単にB、acdを単にC、abdを  
単にDと呼称し、価格はeで所与のものとする。  
生産量  
価格  
社会的限界費用曲線  
私的限界費用曲線  
価格  
A  
B  
g  
e  
f  
b  
a  
c  
d  
mn  
D  
C  
[解答群]  
  
ア  コースの定理に従えば、生産量はmとなる。  
  
イ  自由放任の活動下で生産量がnのときに発生している死重損失はC＋Dで  
ある。  
  
ウ  ピグー課税が導入されると、企業Rの余剰はA＋Bになる。  
  
エ  ピグー課税が導入されると、政府の税収はB＋C＋Dとなる。  
        
`
        },
        {
                "mondaiId": 101260210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
関税撤廃の経済効果を、ある小国の立場から、ある\f財の市場のみに注目した部  
分均衡分析の枠組みで考える。下図は当該財の国内供給曲線と、当該財に対する国  
内需要曲線からなる。関税撤廃前には当該財の輸入に関税が課され、当該財の国内  
価格はP0であり、関税収入は消費者に分配されていた。関税が撤廃されると当該  
財の国内価格はP1となった。関税撤廃による変化に関する記述として最も適切な  
ものを下記の解答群から選べ。  
数量  
価格  
需要曲線  
供給曲線  
ef  
ba  
cd  
[解答群]  
  
ア  関税収入はeだけ減少する。  
  
イ  消費者余剰と生産者余剰の合計はb＋d＋e＋fだけ増加する。  
  
ウ  消費者余剰はcだけ増加する。  
  
エ  生産者余剰はd＋e＋fだけ減少する。  
        
`
        },
        {
                "mondaiId": 101260220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は標準的な囚人のジレンマの状況を示す利得表である。下表で企業Aと企  
業Bの両者は合理的主体であり、両者による取引において｢協力する｣か｢裏切る｣  
かを選択することができる。表中のカッコ内の数字は、\f度の取引で得られる利得  
を示すもので、左側が企業Aの取り分、右側が企業Bの取り分である。ただし、  
相手の｢裏切る｣に対してはトリガー戦略を採用するものと考える。この利得表に関  
する説明として最も適切なものを下記の解答群から選べ。  
協力する\u001f10，10\u001f\f，12  
協力する裏切る  
企業B  
企業A  
裏切る\u001f12，\f\u001f\u000e，\u000e  
[解答群]  
  
ア  将来利得の割引因子の値が十分に\fに近い\u001fただし\f未満状況下で、両者の  
取引が無限に繰り返されるのであれば、両者がともに｢裏切る｣ことがパレート  
最適になるというのがフォーク定理の示唆するところである。  
  
イ  将来利得の割引因子の値が十分に\fに近い\u001fただし\f未満状況下で、両者の  
取引が無限に繰り返されるのであれば、両者がともに｢協力する｣を選択すると  
いうのがフォーク定理の示唆するところである。  
  
ウ  両者の取引が\f回限りであれば、企業Aは、企業Bが｢裏切る｣と予想して  
も、｢協力する｣ことで自分の利得を最大化できるというのがフォーク定理の示  
唆するところである。  
  
エ  両者の取引が\f回限りであれば、両者がともに｢協力する｣ことが支配戦略で  
あるというのがフォーク定理の示唆するところである。  
        
`
        },
        {
                "mondaiId": 101260230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、あるリスク回避的な個人における資産額と効用水準の関係を示したもの  
である。下図で、50％の確率で高い資産額Bになり、50％の確率で低い資産額  
Aとなるような不確実な状況を｢状況R｣と呼ぶことにする。また、AとBのちょ  
うど中間の資産額Cを確実に得られる状況を｢状況S｣と呼ぶことにする。｢状況R｣  
の期待効用と｢状況S｣の期待効用とを比較したときの説明として、最も適切なもの  
を下記の解答群から選べ。  
資産額  
効用水準  
ACB  
[解答群]  
  
ア  期待効用は｢状況R｣の方が大きく、この個人のリスクプレミアムは正の値と  
なる。  
  
イ  期待効用は｢状況R｣の方が大きく、この個人のリスクプレミアムは負の値と  
なる。  
  
ウ  期待効用は｢状況R｣の方が小さく、この個人のリスクプレミアムは正の値と  
なる。  
  
エ  ｢状況R｣と｢状況S｣の期待効用は等しく、この個人のリスクプレミアムはゼ  
ロとなる。  
`
        },
        {
                "mondaiId": 102260010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
帳簿組織に関する記述として、最も適切なものはどれか。  
  
ア  単一仕訳帳制においては、普通仕訳帳から総勘定元帳に合計転記される。  
  
イ  単一仕訳帳制においては、補助記入帳から総勘定元帳に個別転記される。  
  
ウ  特殊仕訳帳制においては、普通仕訳帳は不要である。  
  
エ  特殊仕訳帳制においては、補助記入帳の特別欄の金額は総勘定元帳に合計転記  
される。`
        },
        {
                "mondaiId": 102260020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は、当期において売上割戻契約を得意先A社だけと締結した。以下の資料  
に基づいて、決算における損益計算書に計上すべき売上高として、最も適切なもの  
を下記の解答群から選べ。  
a資料a  
当期中の総売上高のうち、850，000円がA社を対象とする売上高であり、当期  
中に実行された売上割戻は10，000円であった。また、A社に対する総売上高のう  
ち250，000円が当会計期間の最終月における売上高であり、この売上高に対して  
2％の割戻しが翌期に実行されることが見積もられたため、決算において売上割戻  
引当金が設定された。なお、A社以外の得意先への売上高合計は2，500，000円で  
ある。  
[解答群]  
  
ア  3，335，000円  
  
イ  3，340，000円  
  
ウ  3，345，000円  
  
エ  3，350，000円  
        
`
        },
        {
                "mondaiId": 102260030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　税効果会計における評価性引当額に関する記述として、最も適切なものはどれか。ただし、スケジューリング不能な一時差異に係る繰延税金資産は存在しない。  
  
ア  他の条件が一定のとき、将来における課税所得の減少は評価性引当額の増加を招く。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

イ  他の条件が一定のとき、タックスプランニングの内容は評価性引当額に<hintTarget>影響しない</hintTarget> 。  

<hint hidden='true'>**
❌例えば、損金に算入されない棚卸資産等に係る評価損が影響する（将来減算一時差異になる）場合があります。
**</hint>

ウ  他の条件が一定のとき、<hintTarget>当期</hintTarget>の業績低下は評価性引当額の増加を招く。  

<hint hidden='true'>**
❌当期は影響せず、将来の業績低下の見通しが影響します。
**</hint>

エ  他の条件が一定のとき、当期の繰越欠損金の発生は評価性引当額の<hintTarget>減少</hintTarget>を招く。  

<hint hidden='true'>**
❌増加を招きます。
**</hint>
        
`
        },
        {
                "mondaiId": 102260040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は支店分散計算制度を採用しており、本支店間の債権債務は支店勘定と本店  
勘定をそれぞれ利用して会計処理している。未達事項整理前の本店の支店勘定残高  
は400，000円8借方残高;であり、決算において判明した未達事項は以下のとおりで  
あった。未達事項整理後の支店の本店勘定貸方残高として、最も適切なものを下記  
の解答群から選べ。  
a未達事項a  
・本店から支店に現金70，000円を送付した。  
・支店は本店負担の運送費30，000円を支払った。  
・支店は本店の売掛金80，000円を回収した。  
[解答群]  
  
ア  300，000円  
  
イ  350，000円  
  
ウ  380，000円  
  
エ  450，000円  
        
`
        },
        {
                "mondaiId": 102260050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の資料に基づいて、社債償還損益の金額を計算した場合、最も適切なものを  
下記の解答群から選べ。  
a資料a  
平成X3年  
月1日に、社債8額面2，000，000円;を額面100円につき98円で買  
い入れた。この社債は、平成X1年  
月1日に額面100円につき95円で発行され  
た社債8額面5，000，000円、年利率4％、利払日は\u0005月末日と3月末日、償還期限  
5年;の一部である。なお、決算日は\u0005月31日、社債は償却原価法によって適切に  
処理されている。  
[解答群]  
  
ア  社債償還益20，000円  
  
イ  社債償還益60，000円  
  
ウ  社債償還損20，000円  
  
エ  社債償還損60，000円  
        
`
        },
        {
                "mondaiId": 102260060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
リース取引に関する会計処理および開示に関する記述として、最も適切なものの  
組み合わせを下記の解答群から選べ。  
ａオペレーティング・リース取引のうち解約不能なものに係る未経過リース料  
は、貸借対照表上、負債に含める。  
ｂ所有権移転外ファイナンス・リース取引に係るリース資産の減価償却費は、原  
則として、リース期間を耐用年数とし、残存価額をゼロとして算定する。  
ｃファイナンス・リース取引に係るリース債務については、支払の期限の到来時  
期にかかわらず固定負債に属するものとして開示する。  
ｄファイナンス・リース取引に係るリース資産及びリース債務の計上額は、原則  
として、リース契約締結時に合意されたリース料総額からこれに含まれている利  
息相当額の合理的な見積額を控除して算定する。  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 102260070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下に示す今年度の実績資料に基づいて、目標営業利益600，000円を達成すると  
きの総資本営業利益率を計算した場合、最も適切なものを下記の解答群から選べ。  
なお、総資本は売上高増加額の10％分の増加が見込まれる。  
a資料a  
売上高5，000，000円  
営業費用の内訳  
変動費2，500，000円  
固定費2，400，000円  
営業利益100，000円  
総資本2，400，000円  
[解答群]  
  
ア  10.0％  
  
イ  12.0％  
  
ウ  24.0％  
  
エ  25.0％  
        
`
        },
        {
                "mondaiId": 102260080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　投資会社が被投資会社の財務状態を投資会社の財務諸表に反映するための会計方法に持分法がある。<hintTarget>持分法</hintTarget>に関する記述として、最も適切なものはどれか。  
  
ア  持分法適用会社における少数株主損益は、損益計算書上、<hintTarget>当期純利益</hintTarget>の直前で加算あるいは控除される。  

<hint hidden='true'>**  
❌<hintTarget>営業外利益</hintTarget>の直前です。  
** </hint>
  
イ  持分法適用会社の資産と負債は投資会社の資産と負債に<hintTarget>合算される。</hintTarget>  

<hint hidden='true'>**  
❌合算するのは親会社、子会社（連結貸借対照表）の場合です。関連会社（持分法）では合算しません。  
** </hint>
  
ウ  持分法適用会社の純資産のうち投資会社に帰属する部分だけが投資会社の純資産となる。  
  
<hint hidden='true'>**  
⭕適切です。
** </hint>

エ  持分法適用会社の当期純利益は、その<hintTarget>全額</hintTarget>が投資会社の当期純利益となる。  

<hint hidden='true'>**  
❌全額ではありません。持分に応じて配分します。  
** </hint>
        
`
        },
        {
                "mondaiId": 102260090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
以下の資料に基づき、X1年度とX2年度の経営状態の変化を表す記述として、  
最も適切なものの組み合わせを下記の解答群から選べ。  
a資料a  
2.22.0  
売上高純利益率  
X2年度X1年度  
4％5％  
総資本回転率  
自己資本比率50％40％  
ａX1年度と比較してX2年度は自己資本純利益率が下落した。  
ｂX1年度と比較してX2年度は自己資本純利益率が上昇した。  
ｃX1年度と比較してX2年度は総資本純利益率は下落した。  
ｄX1年度と比較してX2年度は総資本純利益率は上昇した。  
[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 102260100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
特定の資産を費用化することによる財務比率への影響に関する記述として、最も  
適切なものの組み合わせを下記の解答群から選べ。なお、純利益は自己資本よりも  
小さいものとする。  
ａ他の条件を一定とすると、自己資本純利益率は不変である。  
ｂ他の条件を一定とすると、総資本純利益率は下落する。  
ｃ他の条件を一定とすると、負債比率は上昇する。  
ｄ他の条件を一定とすると、流動比率は上昇する。  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 102260110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A製品とB製品は工程の終点で分離される連産品である。以下の当月の資料に  
基づいて結合原価を配分し、A製品の原価として最も適切なものを下記の解答群  
から選べ。  
a当月の資料a  
当月完成品量A製品1，000単位  
B製品2，000単位  
当月完成品結合原価702，000円  
その他の情報  
A製品  
単位当たり販売費  
及び一般管理費  
単位当たり  
正常市価  
400円1，000円  
B製品4，000円700円  
単位当たり  
利益  
100円  
300円  
[解答群]  
  
ア  54，000円  
  
イ  58，500円  
  
ウ  78，000円  
  
エ  234，000円  
        
`
        },
        {
                "mondaiId": 102260120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
X製品の需要が高まっているため、遊休機械設備を利用して月間1，200個増産  
することを検討中である。以下の資料に基づいて、増産によって得られる追加的な  
利益として、最も適切なものを下記の解答群から選べ。  
a資料a  
・遊休機械設備に関するデータ  
月間減価償却費は500，000円であり、増産した場合には月間メンテナンス費  
用が追加的に120，000円かかる。  
・X製品に関するデータ  
X製品の販売価格は2，000円であり、単位当たり変動費は1，500円である。  
また、減価償却費以外の固定費が月間250，000円発生すると予測されるが、こ  
のうち60％は増産による追加的なコストである。  
[解答群]  
  
ア  安170，000円  
  
イ  330，000円  
  
ウ  450，000円  
  
エ  480，000円  
        
`
        },
        {
                "mondaiId": 102260130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のデータに基づいて、A社のフリー・キャッシュフローを計算した場合、  
最も適切なものを下記の解答群から選べ。  
aA社のデータa  
営業利益200百万円  
減価償却費20百万円  
売上債権の増加額10百万円  
棚卸資産の増加額15百万円  
仕入債務の減少額5百万円  
当期の設備投資額40百万円  
法人税率40％  
[解答群]  
  
ア  70百万円  
  
イ  80百万円  
  
ウ  120百万円  
  
エ  130百万円  
        
`
        },
        {
                "mondaiId": 102260140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
効率的市場仮説に関する記述として、最も適切なものの組み合わせを下記の解答  
群から選べ。  
ａ市場が効率的であるとき、市場は完ぺきな予測能力をもっている。  
ｂ効率的市場では、市場価格はすべての入手可能な情報を反映している。  
ｃ効率的市場では市場価格は変動しない。  
ｄ投資家間の激しい競争によって市場効率性は高まる。  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 102260151,
                "name": "第15問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
現在A社は、全額自己資本で資金調達しており、その時価は10，000万円であ  
る。A社は毎期600万円の営業利益をあげており、この営業利益はフリー・キャ  
ッシュフローに等しい。MM理論が成り立つものとして、下記の設問に答えよ。  
（設問1）  
A社が利子率2％の借入を行うことによって2，000万円の自己株式を買入消  
却し、負債対自己資本比率を20：80に変化させたとき、A社の自己資本利益率  
は何％になるか。最も適切なものを選べ。ただし、法人税は存在しないものとす  
る。  
  
ア  7％  
  
イ  8％  
  
ウ  22％  
  
エ  24％`
        },
        {
                "mondaiId": 102260152,
                "name": "第15問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問2）設問1のようにA社が資本構成を変化させたとき、法人税が存在する場合、  
資本構成変化後のA社の企業価値はいくらになるか。最も適切なものを選べ。  
ただし、法人税率は40％とする。  
  
ア  9，960万円  
  
イ  10，000万円  
  
ウ  10，040万円  
  
エ  10，800万円  
        
`
        },
        {
                "mondaiId": 102260160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章の空欄A、Bに入る語句として、最も適切なものの組み合わせを下記の  
解答群から選べ。  
A社は現在、相互に排他的な2つのプロジェクトX案とY案の評価を行ってい  
る。X案とY案のNPVとIRRは下表のとおりである。なお、2つのプロジェク  
トとも初期投資を行った後はプロジェクト期間の終わりまで常にプラスのキャッシ  
ュフローをもたらす。  
12.94％751万円  
IRRNPV8割引率10％;  
Y案  
X案669万円16.04％  
表のとおり、割引率10％のもとでNPVはY案のほうが高いが、IRRはX案の  
ほうが上回っている。そこで、Y案のキャッシュフローからX案のキャッシュフ  
ローを差し引いた差額キャッシュフローのIRRを計算したところ、10.55％であ  
った。したがって、資金制約がなく割引率が10.55％以下の時は差額キャッシュ  
フローのNPVはＡとなり、Ｂ案が採択されることになる。  
[解答群]  
  
ア  Ａ：プラスＢ：X  
  
イ  Ａ：プラスＢ：Y  
  
ウ  Ａ：マイナスＢ：X  
  
エ  Ａ：マイナスＢ：Y  
        
`
        },
        {
                "mondaiId": 102260170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
安全資産の収益率とリスク資産の収益率との相関係数ρの値として、最も適切  
なものはどれか。  
  
ア  ρ暗安1  
  
イ  ρ暗0  
  
ウ  0＜ρ＜1  
  
エ  ρ暗1`
        },
        {
                "mondaiId": 102260180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A証券および市場ポートフォリオの収益率に関する以下のデータに基づいて、A  
証券のベータ値を計算した場合、最も適切なものを下記の解答群から選べ。  
aデータa  
5％市場ポートフォリオ  
標準偏差  
A証券10％  
A証券と市場ポートフォリオとの相関係数：0.4  
[解答群]  
  
ア  0.4  
  
イ  0.5  
  
ウ  0.8  
  
エ  2  
        
`
        },
        {
                "mondaiId": 102260190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A社の配当は60円で毎期一定であると期待されている。このとき、以下のデー  
タに基づいてA社の理論株価を算定した場合、最も適切なものを下記の解答群か  
ら選べ。  
aデータa  
安全利子率：2％  
市場ポートフォリオの期待収益率：4％  
A社のベータ値：1.5  
[解答群]  
  
ア  1，000円  
  
イ  1，200円  
  
ウ  1，500円  
  
エ  3，000円  
        
`
        },
        {
                "mondaiId": 102260201,
                "name": "第20問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業価値評価に関する次の文章を読んで、下記の設問に答えよ。  
企業価値評価では、一般的に  
①  
PBRやPERなどの諸比率を用いたに代  
表されるマーケット・アプローチと呼ばれる手法のほか、企業の期待キャッシュフ  
ローの割引現在価値によって評価額を推計する  
②  
DCFアプローチ、企業の保有する  
資産や負債の時価などから企業価値を評価するコスト・アプローチといった手法も  
用いられている。  
（設問1）;  
文中の空欄に入る語句として、最も適切なものはどれか。  
  
ア  収益還元法  
  
イ  純資産価額法  
  
ウ  マルチプル法8乗数法;  
  
エ  リアルオプション法`
        },
        {
                "mondaiId": 102260202,
                "name": "第20問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問2）;  
文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  PBRとは、株価を\f株当たり売上総利益で除して求められる。  
  
イ  PBRとは、株価を\f株当たり売上高で除して求められる。  
  
ウ  PBRとは、株価を\f株当たり純資産で除して求められる。  
  
エ  PBRとは、株価を\f株当たり当期純利益で除して求められる。  
        
`
        },
        {
                "mondaiId": 102260203,
                "name": "第20問設問3",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005;  
文中の下線部②について、以下の問いに答えよ。  
A社の財務データは以下のとおりである。なお、A社の営業利益は、利息・  
税引前キャッシュフローに等しく、将来も永続的に期待されている。A社は負  
債を継続的に利用しており、その利息は毎年一定である。また、A社の法人税  
率は40％であり、税引後利益はすべて配当される。負債の利子率が5％、株式  
の要求収益率が9％であるとき、負債価値と株主資本価値とを合わせたA社の  
企業価値をDCF法によって計算した場合、最も適切な金額を下記の解答群から  
選べ。  
aA社のデータa8単位：万円;  
営業利益1，100  
支払利息500  
税引前利益600  
法人税8税率：40％;240  
税引後利益360  
[解答群]  
  
ア  4，000万円  
  
イ  6，000万円  
  
ウ  14，000万円  
  
エ  14，333万円  
        
`
        },
        {
                "mondaiId": 102260210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システマティック・リスクの意味として、最も適切なものはどれか。  
  
ア  先物価格と現物価格との差が理論値からかい離することにより損益が変動する  
リスク。  
  
イ  市場全体との相関によるリスクであり、分散化によって消去できないリスク。  
  
ウ  市場で取引量が少ないために、資産を換金しようと思ったときにすぐに売るこ  
とができない、あるいは希望する価格で売ることができなくなるリスク。  
  
エ  取引相手に信用供与を行っている場合に、取引相手の財務状況の悪化や倒産に  
より利払いや元本の受取が滞ってしまうリスク。`
        },
        {
                "mondaiId": 102260220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
コール・オプションの価値に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  他の条件が一定であるとき、金利が高ければコール・オプションの価値は高く  
なる。  
  
イ  他の条件が一定であるとき、原資産の価格が高ければコール・オプションの価  
値は高くなる。  
  
ウ  他の条件が一定であるとき、原資産の価格変動性が高ければコール・オプショ  
ンの価値は低くなる。  
  
エ  他の条件が一定であるとき、行使価格が高ければコール・オプションの価値は  
低くなる。  
`
        },
        {
                "mondaiId": 103260010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
市場の成熟期を迎えた製造業の企業は、これまでの経営戦略を見直し、成熟段階  
にふさわしい戦略をとることが重要になる。成熟期の戦略に関する記述として、最  
も適切なものはどれか。  
  
ア  買い慣れた顧客が増えて、市場シェアを巡る競争は緩和するので、ブランド戦  
略を追求する。  
  
イ  市場での競争が緩和するので、市場シェアの拡大のために生産や販売の分野に  
積極的な追加投資をすることが効果的になる。  
  
ウ  市場や技術はほぼ安定するので、競争の重点をコストとサービスに置くように  
する。  
  
エ  通常、成熟期に向かうにつれて流通業者のマージンが減少し、撤退する流通業  
者が増えるので、製造業企業は強くなった交渉力を活かして流通支配力の強化を  
図る戦略を狙う。  
  
オ  転用のきかない経営資産は、帳簿価格が清算価値を上回っていれば売却してキ  
ャッシュフローの増大を図る。  
        
`
        },
        {
                "mondaiId": 103260020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
産業内で他社との競争状態に影響を及ぼす要因に関する記述として、最も適切な  
ものはどれか。  
  
ア  企業数とともに産業内で企業の規模がどのように分布しているかは、企業間の  
競争と利益に影響を与える。  
  
イ  企業の固定費や在庫費が高ければ、供給量の増加に伴う追加費用も相対的に大  
きく、競争は緩和しやすい。  
  
ウ  産業からの撤退にあたって何らかの障害があれば、産業の成長が鈍化しても競  
争は緩和しやすい。  
  
エ  漸進的な生産能力の拡張ができない場合には、生産設備の増強によって一時的  
に競争は緩和しやすい。`
        },
        {
                "mondaiId": 103260030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業績が悪化している事業から撤退すべきであっても、なかなかそれができないの  
は、撤退を阻む障壁が存在するからである。そのような撤退障壁が生じている状況  
に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  自社の精神ともいうべき事業への創業者や従業員の思い入れが強く、現状で踏  
ん張らざるをえない。  
  
イ  生産過剰で収益率が悪化しているが、業界秩序を守る協定が存在しているので  
同業者数に変化はなく、市場競争は平穏である。  
  
ウ  撤退のための社内再配置等のコストがかさむので、撤退の判断が難しくなる。  
  
エ  特定の業種にしか利用できない資産のために清算価値が低く、それを移動した  
り流用しようとすると、そのためのコスト負担が新たに大きくのしかかる。  
  
オ  不採算に陥っている事業であっても、他の事業との関連性が強いために、撤退  
すると他の事業の不利益を招き、自社の戦略上の強みを失いかねない。  
        
`
        },
        {
                "mondaiId": 103260040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A社は、現社長が高齢化したために、家族や親族以外の者への事業承継をMBI  
(managementbuy-in)によって行うことを検討している。MBIに関する記述とし  
て、最も適切なものはどれか。  
  
ア  現社長と役員は、投資ファンドから資金を調達し、現経営陣を支援してもら  
う。  
  
イ  現社長は、社外の第三者に自社株式を買い取らせ、経営を引き継いでもらう。  
  
ウ  現社長は、投資ファンドに自社株式を買い取ってもらい、経営を外部から監視  
してもらう。  
  
エ  現社長は、長く勤めた営業部長に自社株式を買い取らせず、経営を引き継いで  
もらう。  
  
オ  現社長は、長く勤めた営業部長や経理課長に自社株式を買い取らせ、営業部長  
に経営を引き継いでもらう。`
        },
        {
                "mondaiId": 103260050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
シナジー効果に関する記述として、最も適切なものはどれか。  
  
ア  動的なシナジーよりも静的なシナジーをつくり出せるような事業の組み合わせ  
の方が望ましい。  
  
イ  範囲の経済の効果とは別個に発生し、複数事業の組み合わせによる費用の低下  
を生じさせる。  
  
ウ  複数事業の組み合わせによる情報的資源の同時多重利用によって発生する効果  
を指す。  
  
エ  複数の製品分野での事業が互いに足りない部分を補い合うことで、企業全体と  
して売上の季節変動などを平準化できる。  
        
`
        },
        {
                "mondaiId": 103260060,
                "name": "第6問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　プロダクト・ポートフォリオ・マネジメント(PPM)の考え方に関する記述として、最も適切なものはどれか。  
  
ア  事業単位は他の事業単位と製品や市場について相互に関連した統合的戦略をもち、計画の範囲内で自由に対処する。  
  
<hint hidden='true'>**
❌選択肢オのとおり、PPMはドメインの定義などとあわせて統合的戦略を形成していきます。
**</hint>

イ  資金の流出は市場での<hintTarget>競争上の地位</hintTarget>で決まると考える。  

<hint hidden='true'>**
❌試飲流出（資金の配分先）は、競争上の地位（市場占有率）ではなく、市場成長率で決まると考えます。
**</hint>
  
ウ  資金の流出量を<hintTarget>削減</hintTarget>して優位性を確保できる｢問題児｣の選択が重要である。  
  
<hint hidden='true'>**
❌問題児は、市場占有率を拡大して優位性を確保するために、資金流出を増加させることが望ましいとされている領域です。
**</hint>

エ  自社の相対的な市場シェアと<hintTarget>自社事業</hintTarget>の成長率を基準として事業を分類する。  

<hint hidden='true'>**
❌下図のとおり、自社事業でなく、市場の成長率です。**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/103003_1.png" target="_blank" >
<img src="assets/img/kaisetu/103003_1.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>

オ  全社的な資源配分のための論理のひとつとしての位置付けが重要であり、ドメインの定義と併せることで現実的な資源配分の指針となる。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  
  
`
        },
        {
                "mondaiId": 103260070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
規模の経済と経験曲線および経験効果に関する記述として、最も不適切なものは  
どれか。  
  
ア  規模の経済と経験効果は連続的に生じ、コスト低下の効果が生じない停滞期間  
が存在することは少ないが、物理的な特性が効率性の向上の水準を制限する場合  
もある。  
  
イ  規模の経済の追求には相当額の投資が必要であり、多くの場合、特殊化した資  
産が投資対象となって長期間にわたって実現されるコストの減少を通じた投資回  
収を目指す。  
  
ウ  規模の経済は、ある一定程度の総生産量が増加することによるコストの低下を  
指し、大規模な工場施設の建設などで模倣することはできるが、経験効果の構築  
にはある程度の時間を必要とする。  
  
エ  規模の経済は、業界内において利益をあげられる企業数の上限を決定する一因  
となり、市場規模に対する生産の最小効率規模が大きいほど、当該業界に存在で  
きる企業数は少なくなる。  
  
オ  経験曲線は累積生産量の増加に伴ってコストが低下することを表し、累積生産  
量に対応する技術の進歩や改善等の要因からも生じるが、生産機能において生じ  
る経験効果に限定されない。  
        
`
        },
        {
                "mondaiId": 103260080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
自動車メーカーや電気・電子機器メーカーなどの大手企業と部品サプライヤーの  
中小企業との取引関係に関する記述として、最も適切なものはどれか。  
  
ア  自動車メーカーが承認図方式をとるのは、自社技術がカバーしにくい領域の部  
品であり、第一次系列のサプライヤーに限られるので、中小企業が承認図部品の  
発注を受けることはない。  
  
イ  自動車メーカーが複数発注をするのは重要な部品が中心であり、月間生産計画  
がほぼ固定された特定のサプライヤーに渡されて、貸与図による部品生産が行わ  
れる。  
  
ウ  中小企業が優良外注先としての評価を高めるためには、通常好業績で高い生産  
能力をもつことが求められるが、大手企業側からみれば、そのような中小企業と  
の取引を数多くもつことによって、生産全体に柔軟性を持たせることができる。  
  
エ  電気・電子機器メーカーからの注文の契約期間は概して短期間であるが、発注  
量が安定しており、納入価格も明示されるので、自社の生産計画に合わせやす  
い。  
  
オ  電気・電子機器メーカーからの発注品目は比較的標準的な部品ながら、持続的  
な系列取引が求められるので、自社の生産設備を更新する必要はほとんどない。  
        
`
        },
        {
                "mondaiId": 103260090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
研究開発に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  基礎研究から生み出された技術が成功するためには、その技術に基づく製品が  
市場で勝ち抜くことを阻む｢死の谷｣と呼ばれる断絶を克服しなければならない。  
  
イ  自社の技術だけで最終製品が生まれることはまれであり、関連する技術領域を  
幅広く動員する技術の統合能力が製品開発には必要である。  
  
ウ  市場ニーズをくみ上げて技術開発を進めるには、研究開発要員が日常的に市場  
との対話の機会を持ったり、営業部門や生産部門との連携を保つことが重要であ  
る。  
  
エ  新規な技術が生まれにくくなるにつれて、顧客の感性に訴えるデザインや利便  
性あるいは顧客の課題解決提案などの新たな視点による製品開発の例も生まれて  
いる。  
  
オ  模倣は、研究開発投資のコストや時間を節約できるばかりでなく、先発企業の  
市場開拓に追随すればよいので、マーケティング・コストの負担も軽減できる可  
能性が高い。  
        
`
        },
        {
                "mondaiId": 103260100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
創業間もないベンチャー企業は、新製品や新サービスを受け入れる市場が一様で  
はなく、いくつかの異なったグループによって構成されていることに着目する必要  
がある。新製品・サービスの販売に悪戦苦闘する場合にみられる｢市場の断層?キャ  
ズム@｣に関する記述として、最も適切なものはどれか。  
  
ア  新しいモノ好きの層?イノベーター@や目利きの層?アーリー・アドプター@には  
受け入れられても、いかに流行に敏感な層?アーリー・マジョリティー@に受け入  
れられるかが課題である。  
  
イ  新しいモノ好きの層?イノベーター@や流行に敏感な層?アーリー・マジョリテ  
ィー@には受け入れられても、いかに無関心の層?ラガード@に受け入れられるか  
が課題である。  
  
ウ  流行に敏感な層?アーリー・マジョリティー@には一時的に受け入れられても、  
新しいモノ好きの層?イノベーター@には受け入れられないという問題である。  
  
エ  流行に敏感な層?アーリー・マジョリティー@の反応を見て、新しいモノ好きの  
層?イノベーター@や目利きの層?アーリー・アドプター@の反応を勘違いして判断  
してしまう問題である。  
  
オ  流行に敏感な層?アーリー・マジョリティー@や流行を後追いする層?レイト・  
マジョリティー@には受け入れられても、いかに無関心の層?ラガード@に受け入  
れられるかが課題である。  
        
`
        },
        {
                "mondaiId": 103260110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
スタートアップ段階のベンチャー企業A社は、将来の成長ステージについて段  
階を追って計画を立て、達成目標と時期を明確にしたマイルストーンの明示を真剣  
に考えている。同社は成果が出るまでに数年を要すると考えて、累積的なキャッシ  
ュフローはJカーブ曲線を描くと予想し、それを経営の根幹に位置付けて計画を検  
討している。累積的なキャッシュフローが描くJカーブ曲線に関する記述として、  
最も適切なものはどれか。  
  
ア  新製品・サービスが意図した通りのプロセスで開発できるかどうかのリスクを  
示し、アイデアの創出から市場投入までの商業化段階での効果を指す。  
  
イ  新製品・サービスが意図した通りのプロセスで開発できるかどうかのリスクを  
示し、市場投入前の先行投資であるサポートコストの影響を受ける。  
  
ウ  新製品・サービスが顧客の望む数量や価格水準で受け入れられるかどうかのリ  
スクを示し、市場投入後の再投資を含むスタートアップコストの影響も受ける。  
  
エ  新製品・サービスの開発、生産、販売などを予想通り行えるかどうかのリスク  
を示し、商品の市場投入までの時間と量産までの時間の両方から影響を受ける。  
        
`
        },
        {
                "mondaiId": 103260120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業が海外でモノづくりに取り組む際に注意すべき点や考慮すべき事項に関  
する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  海外でのトラブル対処には時間とコストがかかり、時には政治的な介入があり  
うる事を想定しておかなければならないので、国際弁護士や国内関係機関との連  
携を構築することも必要である。  
  
イ  自社製品の海外拠点での生産販売を目指す場合、日本国内で現地人を採用し、  
日本語によるビジネス能力を育成し、彼らを責任者にした輸出から着手する方が  
回り道であるが、現地拠点とのビジネス・コミュニケーションが円滑になり、進  
出リスクを軽減できる可能性が高まる例がみられる。  
  
ウ  単独出資での進出に制限がある場合、現地パートナーとの関係構築が重要にな  
るので、現地操業後の管理運営について密接な情報交換と深い人間関係を通じた  
信頼の構築が難しい場合は、進出を中止することも選択肢になる。  
  
エ  部品のサプライヤーとして進出する場合は、アセンブラーと連携を図るととも  
に、現地での販売先を確保できる見通しをつけておくことが必須になる。  
        
`
        },
        {
                "mondaiId": 103260130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業は自己の利益を追求するばかりではなく、その活動が社会に与える影響につ  
いて適切に対応するとともに、社会が企業に求める期待に応えることが要請されて  
いる。以下の記述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  意欲的な企業ではNPOに金銭的な寄付のみならず、余剰の商品在庫を提供し  
たり、従業員のボランティア派遣を行ったり、管理職がNPOの役員に就任した  
りして、NPOとの連携で社会貢献を図ろうとする例がみられる。  
  
イ  偽装を取り締まる法律は通称JAS法しかなく、表示内容にあざむく意図がな  
い場合は誤表示として扱われて、JAS法の適用を免れるので、企業は偽装の疑  
いに対して誤表示と言い張る例が昨今多くなっている。  
  
ウ  国際標準化機構の社会的責任の国際規格ISO26000は、日本ではJISZ26000  
｢社会的責任に関する手引｣として普及が図られている。  
  
エ  社会的に批判を受けている一部の企業では、若年者の長時間労働とその使い捨  
て、各種のハラスメントの横行などがみられ、著しく離職率が高い特徴をもつ。  
  
オ  世界経済フォーラム発表の2013年のジェンダー・ギャップ指数で日本は136  
カ国中105位と低く、欧米諸国に比べてわが国の企業では女性の管理職への登用  
や女性が働く職場環境の整備が進んでいない。  
        
`
        },
        {
                "mondaiId": 103260140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
有効な組織デザインには、適切なコントロール・システムを組み込むことが不可  
欠である。組織のコントロール・システムに関する記述として、最も適切なものは  
どれか。  
  
ア  官僚主義的なコントロールとは、規則や基準、階層構造や合法的権威にもとづ  
き、業務遂行のプロセス中での組織メンバーの行動を統制することをいう。  
  
イ  クラン・コントロール(clancontrol)は、組織文化や帰属意識、伝統などの社  
会的特性を利用して行動をコントロールすることで、不確実性が高く変化が速い  
環境で重要になる。  
  
ウ  市場コントロールは、組織内部の部門のコントロールには利用できないが、市  
場における価格競争が組織の生産量や生産性を評価する時に有効である。  
  
エ  予算管理システムや事業部門の目標管理に基づく管理者の報酬システムは、市  
場コントロールの有効なサブシステムである。  
  
オ  リーダーが従業員の知識や裁量を行使する範囲を明確に設定できない場合に  
は、従業員自身に自己目標を設定させ、自らの成果を監視させる自律的コントロ  
ールが有効である。  
        
`
        },
        {
                "mondaiId": 103260150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経営者による戦略の選択は組織構造とプロセスを形作るとともに、組織構造とプ  
ロセスは経営戦略を制約する。代表的な研究者であるレイモンド・マイルズとチャ  
ールズ・スノーは、組織の戦略の型によって解決すべき企業者的問題、技術的問  
題、管理的問題が異なり、そのコストと利点も異なると主張した。これについて最  
も適切なものはどれか。  
  
ア  受動型(reactor)戦略をとる企業は、経営者がトップダウンで明確な戦略を示  
すことができないため、社内ベンチャーのようなボトムアップからイノベーショ  
ンが生まれるような制度をもっている傾向が高い。  
  
イ  探索型(prospector)戦略をとる企業の企業者的問題は、新製品市場の開発と新  
市場の創造にある。そのために柔軟に試作的な技術を追求し、中央集権的な統制  
システムを採用する。  
  
ウ  分析型(analyzer)戦略をとる組織が直面する企業者的問題は、既存の製品―市  
場分野の維持と新しい製品―市場分野の開発のバランスをとることにある。その  
ために、応用研究分野で複数のコア技術を持ち、安定性と柔軟性のバランスをと  
る組織構造が必要とされる。  
  
エ  防衛型(defender)戦略をとる組織が直面する企業者的問題は、既存の事業領域  
を積極的に維持することである。そのためにコアとなる技術以外にもイノベーシ  
ョンを追求し、有機的管理システムを採用する必要がある。  
        
`
        },
        {
                "mondaiId": 103260160,
                "name": "第16問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
モチベーション理論の多くは、満足や不満足を知覚する内容やそのプロセスを個  
人の特性としてとらえようとしてきた。これに対して、モチベーションに影響する  
要因には、職務として与えられた仕事それ自体の特性があることが知られている。  
仕事を構成する中核的職務特性に関する記述として、最も適切なものはどれか。  
  
ア  与えられた仕事ではなく、自ら仕事を選ぶことに関する｢自律性｣。  
  
イ  仕事の結果について、品質をチェックする専門部局から与えられる正確な情報  
に基づいた｢フィードバック特性｣。  
  
ウ  既に有するスキルや才能を利用して、どれほど多様な業務がこなせるかに関す  
る｢技能多様性｣。  
  
エ  他者に対してではなく、自分自身にとってその仕事が重要であることを示す  
｢タスク重要性｣。  
  
オ  自らがかかわる仕事が自己完結していて、全体像がつかめる｢タスク完結性｣。`
        },
        {
                "mondaiId": 103260170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
目標による管理であるMBO(managementbyobjectives)プログラムの原理に関  
する記述として、最も適切なものはどれか。  
  
ア  経営理念に直接結び付くことによって、自らが働く社会的意義を感じられるよ  
うになる。  
  
イ  個別の目標と組織全体の目標が統合されないという欠陥がある。  
  
ウ  職務内容の管理だけではなく、動機づけのための手段として利用される。  
  
エ  特定の期限を設けず、フィードバックを繰り返して行動の結果を振り返りなが  
ら、絶えず行動を修正できるようにする。  
  
オ  目標を与える管理者による、トップダウンの管理方法である。  
        
`
        },
        {
                "mondaiId": 103260180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業組織はオープンシステムであり、その存続は環境からの資源獲得ができるか  
否かにかかっている。この資源制約は、企業組織が環境からコントロールされる可  
能性を意味するとともに、企業自身も自由裁量を確保するために環境を操作しよう  
とすることを意味している。企業組織の環境操作戦略に関する記述として、最も適  
切なものはどれか。  
  
ア  技術革新を通じて代替的な原材料などを開発することにより、既存の資源依存  
関係を変えることなく交渉を有利に進めることができる。  
  
イ  経営者と労働者が将来の行動について双方が満足できるように折衝するのは、  
取引される財やサービスについての合意を意図する交渉戦略である。  
  
ウ  財務的資源を必要とする企業が金融機関の代表を自社の取締役会に招くなどの  
結託戦略は、資源依存関係を変えることなく、環境からの脅威を小さくすること  
ができる。  
  
エ  市場浸透戦略を進めて、新たな顧客層を獲得することで、顧客に対する資源依  
存関係を変えることが可能になる。  
  
オ  新製品に複数の製品規格が存在すると、消費者が買い控えをする可能性がある  
ので、企業間であらかじめ業界標準を決めてしまう包摂戦略は、新規市場の成長  
率を高めることができる。  
        
`
        },
        {
                "mondaiId": 103260190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
特定の国や地域において産業の地理的集中がおき、他の地域に対する比較優位性  
が生じることがある。近年、ポーターらの提唱する産業クラスター論では、従来の  
産業集積論とは異なる意義が求められている。産業クラスター論に関する記述とし  
て、最も適切なものはどれか。  
  
ア  科学技術インフラや先進的な顧客ニーズなど、特定のロケーションに埋め込ま  
れた知識が、比較優位性の基盤になる。  
  
イ  産業が地域的に集中する要因として、土地や天然資源などの生産要素を重視す  
る。  
  
ウ  産業の集積を通じて流通費用の最小化を目指す。  
  
エ  仲間内での競争を避け、協調的なネットワークの必要性を明示する。`
        },
        {
                "mondaiId": 103260200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業経営における意思決定の不正を防止したり、企業価値の向上を目指すために  
企業統治(コーポレート・ガバナンス)の重要性が高まっている。企業統治を強化す  
るために有効な方法として、<u>最も不適切なものはどれか</u>。  
  
ア  業務に関係して違法行為や背任行為を起こさないよう内部統制制度を導入す  
る。  
  
イ  取締役会に社外取締役を、監査役会に社外監査役を導入する。  
  
ウ  取締役会の中に指名委員会、監査委員会、報酬委員会を設置する。  
  
エ  取締役のほかに執行役員をおき、取締役会に参加させる。  
  
オ  倫理憲章や行動規範などを作成周知し、社員の意思決定における判断基準とし  
て制度化する。  
        
`
        },
        {
                "mondaiId": 103260211,
                "name": "第21問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
変化する環境に効果的に適応していくためには、組織を戦略的に変革することが  
必要となることがある。一般に戦略的に組織変革を進めていくプロセスを、  
①  
組織変  
革の必要性を認識すること、組織変革案の創造、  
②  
組織変革の実施・定着という\u0005段  
階に分けて考えることができる。戦略的な組織変革のプロセスについて、以下の設問に答えよ。  
（設問1）@  
文中の下線部①の組織変革の必要性を認識することに関する記述として、最も  
適切なものはどれか。  
  
ア  組織の現状を客観的に診断するために、組織内の情報に頼らず、外部環境の  
調査機関やコンサルタントなどから情報を収集する。  
  
イ  組織変革を進めている間にも現在の業務を遂行しなければならないため、中  
間管理職や現場管理者を巻き込まないよう配慮し、大局観をもったトップマネ  
ジメントが現状を診断する必要がある。  
  
ウ  組織メンバー間やコンサルタントとの間で、フェイス・ツー・フェイスのコ  
ミュニケーションを通じて、できるだけ問題が生じている現場の生のデータを  
収集し、予期されなかった事態についての情報にも耳を傾ける必要がある。  
  
エ  変革の認識を共有する場面では、様々なコンフリクトが顕在化した場合、円  
滑な変革プロセスを妨害する可能性があるため、速やかに政治的な処理をして  
いく必要がある。  
        
`
        },
        {
                "mondaiId": 103260212,
                "name": "第21問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
@  
いかに優れた変革案が作成されても、実際にその変革を実施し、定着させる過  
程で様々な混乱や抵抗が生じることがある。文中の下線部②の組織変革の実施・  
定着段階に関する記述として、最も適切なものはどれか。  
  
ア  既存の組織内で権力を持っていた集団が新しい組織案ではその権力を失って  
しまうことに抵抗を示す可能性があるので、そのような権力集団を排除する必  
要がある。  
  
イ  望まし��組織変革案を支持するメンバーに対して、ボーナス、給与、昇進な  
どの報酬を与え動機づける必要がある。  
  
ウ  変革の実施段階では、非公式のコミュニケーションルートを様々なうわさや  
妨害情報が流れるので、非公式な情報ルートを遮断し、公式なコミュニケーシ  
ョンを徹底することが重要となる。  
  
エ  変革を自己に有利な形で利用して権力を握ろうとする集団が登場することが  
あるため、混乱が収まるまで新しい組織案を提示しないようにしなければなら  
ない。  
        
`
        },
        {
                "mondaiId": 103260220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年、米国を中心に、人的資源を企業の競争優位の源泉としてとらえるSHRM  
(戦略的人的資源管理)が注目されている。人材の調達先(外部からの登用安内部で  
の育成)、管理の対象(仕事の結果安仕事のプロセス)という二軸から分類した場合、  
それぞれに適合的な人的資源戦略が考えられる(下図参照)。人的資源戦略に関する  
記述として、最も適切なものはどれか。  
仕事の結果  
外部からの登用内部での育成  
仕事のプロセス  
コミットメント型  
人的資源戦略  
協力型  
人的資源戦略  
家父長型  
人的資源戦略  
伝統型  
人的資源戦略  
  
ア  家父長型人的資源戦略では、企業内部の従業員がきちんと結果が出せるように  
能力開発を行う。  
  
イ  協力型人的資源戦略は、コンサルタントや契約社員などの外部の人材に対し  
て、仕事の結果を通じた統制を行う。  
  
ウ  コミットメント型人的資源戦略では、企業内部の従業員に仕事のプロセスに対  
して責任を負うことを求めていく。  
  
エ  伝統型人的資源戦略では、企業内部の人材を登用し、仕事のプロセスに対する  
統制がなされる。  
        
`
        },
        {
                "mondaiId": 103260230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
就業規則の記載事項に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  育児休業は、労働基準法に定められたものではないが、就業規則の絶対的必要  
記載事項のひとつである｢休暇｣に該当するので、対象となる労働者の範囲等の付  
与要件及び休業取得に必要な手続き並びに休業期間について、就業規則に記載す  
る必要がある。  
  
イ  退職金制度を設ける場合には、適用される労働者の範囲、退職金の決定、計算  
及び支払の方法並びに退職金の支払の時期について、就業規則に記載しなければ  
ならない。  
  
ウ  パートタイマー等、勤務態様、職種、本人の希望等によって始業及び終業の時  
刻が異なる労働者については、就業規則に基本となる始業及び終業の時刻を記載  
するとともに、具体的な各人ごとの始業及び終業の時刻については、個別の労働  
契約等で定める旨の委任規定を設けることでも差し支えない。  
  
エ  労働基準法第89条第\f号から第\u0005号までの絶対的必要記載事項の一部、又は  
同条第\u0005号の  
以下の相対的必要記載事項中、当該事業場が適用を受けるべき事  
項を記載していない就業規則は、他の要件を具備していてもその全部が無効であ  
る。  
        
`
        },
        {
                "mondaiId": 103260240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働者災害補償保険(以下｢労災保険｣という。)に関する記述として、最も適切な  
ものはどれか。  
  
ア  国内の事業場に所属する労働者が、当該事業場の使用者の指揮に従って海外の  
業務に従事する場合に、海外で遭遇した保険事故について労災保険の給付を受け  
るためには、海外派遣者の特別加入手続きをしなければならない。  
  
イ  事業主が労災保険の保険関係成立の手続きをしていない場合に、その期間中に  
生じた保険事故に対しては、国(政府)からは保険給付が行われないので、事業主  
が災害補償義務を負う。  
  
ウ  労災保険の保険給付には、労働者の業務上の負傷、疾病、障害又は死亡に関す  
る保険給付及び労働者の通勤による負傷、疾病、障害又は死亡に関する保険給付  
並びに二次健康診断等給付の3つがある。  
  
エ  労災保険は、労働者が業務上の災害等に遭遇したときに、事業主に代わって国  
(政府)が保険給付を行うものであるが、一定の数以下の労働者を使用する事業  
(いわゆる中小企業)の事業主は、中小事業主等の労災保険に係る労働保険関係成  
立届を所轄の労働基準監督署に届け出ることによって、当該事業主は労災保険に  
特別加入することができる。  
        
`
        },
        {
                "mondaiId": 103260250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
合同労働組合(以下｢合同労組｣という。)に関する記述として、最も適切なものは  
どれか。  
  
ア  解雇した労働者が解雇後に加入した個人加盟の合同労組から解雇撤回の団体交  
渉を求められた場合、この合同労組は当該使用者が雇用する労働者が参加する労  
働組合ではないので、この団体交渉に応じる義務はない。  
  
イ  合同労組が加入する産業別あるいは地域別協議会などの上部団体の役員は、そ  
の合同労組の委任があれば、団体交渉に参加することができる。  
  
ウ  合同労組とは、主として中小企業の労働者を対象にした個人加盟労組で、産業  
別、職業別又は産業や職種を問わない一般労組として組織されたものをいい、女  
性や青年、管理職など性別、年齢別、職階別に組織されたユニオンは、個人加盟  
であっても合同労組には当たらない。  
  
エ  労働組合は、労働者によって構成され、当該労働組合に加入する労働者の労働  
条件の維持改善その他経済的地位の向上を図ることを主な目的とした組織である  
から、学生アルバイトや主婦パートが参加する合同労組は労働組合とはいえな  
い。  
        
`
        },
        {
                "mondaiId": 103260260,
                "name": "第26問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業が従業員の能力開発を目的として行うOJT、Off-JT、自己啓発に関する記  
述として、最も適切なものはどれか。  
  
ア  Off-JTとOJTは、それぞれの目的と役割がある。Off-JTは、主として社外  
の技術や知識、ノウハウを社内に取り入れることを目的としたものであり、OJT  
は、社内の技術やノウハウ、仕事の進め方などを社内に定着させることを目的と  
したものである。  
  
イ  Off-JTは、階層別教育と職能別教育が柱であるといわれているが、Off-JTが  
実際に効果があるのは、外部から企業に新たに参加してきた新入社員やマンネリ  
ズムに陥った管理職(者)など同質の集団を対象として行う階層別教育であって、  
営業や製造などのラインの業務に関して行う職能別教育においては、Off-JTは  
あまり効果がない。  
  
ウ  OJTは、管理職等が日常の活動の中で指揮命令を通して行うものであるから、  
OJTを計画的に実施したり、マニュアルに基づいてOJTを行うなどということ  
は、実際には困難であり、あまり現実的ではない。  
  
エ  自己啓発は本来自主的に行うものであるから、テーマや内容について他から強  
制されるべきものではないが、企業が設ける自己啓発支援制度においては、一般  
的には、業務に役立つ知識を習得したり、業務スキルを磨くものなど、自己啓発  
のテーマや内容を業務に関連のあるものが対象とされる。  
        
`
        },
        {
                "mondaiId": 103260271,
                "name": "第27問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
家業の果物農家を継いだS氏は、父親の代から取り組んできた大手小売チェー  
ンへの完全直販体制をさらに強化するために、地域の若手農家とともに、この小売  
チェーンとの間で自らが生産する果物の  
①  
ブランド化を図る準備を進めている。各種  
の調査結果からは、消費者の年齢と果物の消費量との間には強い正の相関があるこ  
とが明らかにされている。そこで、S氏はこの取り組みのメンバーと  
②  
マーケティン  
グ・リサーチ検討会を立ち上げ、今後とるべき方策の判断材料を集めることにした。  
（設問1）@  
文中の下線部①に示す｢ブランド化｣に関する記述として、最も適切なものはど  
れか。  
  
ア  一般的に果物の成分効用は明確に識別することが可能である。おのおのの果  
物の成分効用という情緒的属性をベースとしたブランド・アイデンティティを  
デザインすれば、大きな効果を得ることができる。  
  
イ  果物のブランド化においては、取引先小売チェーンの顧客データや販売デー  
タを分析し、より廃棄ロスの少ない形でのブランド育成の方法を探索すること  
が望まれる。  
  
ウ  この果物のブランド化の取り組みは、少なからず作柄の影響を受ける。作柄  
に応じて、生産者側が出荷制限を行ったり、小売チェーン側が卸売市場を通し  
た機動的な仕入れを行ったりすることが商品の安定的供給の上で不可欠であ  
る。  
  
エ  取引相手となる小売チェーンの店頭で展開する果物商品を通じて消費者の欲  
求理想点に近いポジションを獲得することができれば、このブランド化の構想  
は、強力なプッシュ効果による指名買い行動を生み出すことが可能となる。  
        
`
        },
        {
                "mondaiId": 103260272,
                "name": "第27問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
@  
文中の下線部②に示す｢マーケティング・リサーチ｣に関する記述として、最も  
適切なものはどれか。  
  
ア  S氏らは、果物を購買し消費する人々の行動原理がどうなっているのか、そ  
してそれらの行動の背景にはどのような気持ちがあるのかを知りたいと思って  
いる。そうした｢消費者の潜在意識的な部分｣のことをコンシューマー・マイン  
ドマップと呼ぶ。  
  
イ  S氏らは、取引先小売チェーンの協力のもと、果物の消費量の異なる複数の  
セグメントに属する消費者を集めてデプス・インタビューを継続的に実施して  
いる。グループダイナミクスの効果によって、思いもかけない果物の消費体験  
例を知ることができる。  
  
ウ  S氏らは、取引先の小売チェーンにPOSデータの分析を依頼している。そ  
の中でもとくに陳列情報、販促情報やその他果物の販売量に影響を与えるコー  
ザルデータの分析を重視している。この種のデータはPOSデータから直接、  
簡単に取得できるものであり、迅速な意思決定を支援する。  
  
エ  S氏らは、年齢と果物消費量の関係をさらに深掘りして把握するために、調  
査設計において被験者の｢年齢｣、｢時代｣、｢世代｣からなる3つの要因を分解し  
て分析可能なアプローチを設計した。これは、有効な方法である。  
        
`
        },
        {
                "mondaiId": 103260281,
                "name": "第28問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
Y氏は、国内外の生産者への特別発注で仕入れたカジュアル衣料品と雑貨を品  
揃えする小売店15店舗を、地方都市の商店街やショッピングセンター(SC)の中  
で、チェーンストア・オペレーションによって経営している。近年、自店舗で取り  
扱う商品カテゴリーにおけるe-コマース比率が上昇していることを受け、Y氏は
オンライン・ショッピングモールへの出店を行っている。実店舗の商圏ではなかな  
か売り切ることのできなかった商品も遠隔地の消費者が購買してくれるケースが目  
立ち、今やインターネット店舗事業の販売額が実店舗の販売額を上回るようになっ  
ており、顧客の購買履歴を活用した商品提案も好評である。  
今後の課題は、各シーズンの在庫を適切な時期に望ましい価格で販売し、常に新  
鮮な品揃えを提供することである。そのための手段としてY氏は各種の  
①  
価格・プ  
ロモーション施策を試み、その効果測定を通じた今後の展開の検討を行っている。  
もうひとつの課題は、  
②  
買い物の目的・状況によって特定の実店舗で購買したり、  
インターネット店舗で購買したりする顧客の増加が顕著になってきていることであ  
る。この点についても今後、有効な対策を講じたいとY氏は考えている。  
        
（設問1）)  
文中の下線部①に示す｢価格・プロモーション施策｣に関する記述として、最も  
適切なものはどれか。  
  
ア  Y氏の小売チェーンでは毎年夏、ヨーロッパのメーカーとの製販連携の取  
り組みを通して仕入れた高品質のポロシャツの販売強化を行っている。例年、  
3，500円から4，500円の範囲で価格設定をしていたが、需要数量に大きな差は  
なかった。今年、これを5，200円に設定すると需要数量は激減した。このよう  
な効果を、端数価格効果という。  
  
イ  Y氏の店舗の品揃えの多くは｢こだわりの特注品｣であるため、Y氏は過度  
の値引き販売は極力避けるようにしているが、過去\u0005シーズンにおいては、商  
品ごとに大幅な値引き価格を表示したセールをしている。これらのセールによ  
る消費者の内的参照価格の低下は起こりにくい。  
  
ウ  Y氏は以前、消費者吸引を意図して世界各国から仕入れた雑貨を100円均  
一で販売するキャンペーンを継続的に実施していた。日本ではほとんどみられ  
ない商品ばかりだったため、買い物客の多くは価格を品質判断の手段として用  
い、｢これらは安かろう、悪かろうだろう｣という結論に至る場合が目立った。  
これは、価格の品質バロメーター機能である。  
  
エ  Y氏は顧客ひとりあたりの購買単価を上げるための施策として、キャンペ  
ーン期間中に一定数量(点数)以上の買い物を行った顧客に対して、次回以降に  
使用可能なバンドル販売型買い物クーポンを配布した。この種のバンドル販売  
の欠点は、消費者の内的参照価格が下がることである。  
        
`
        },
        {
                "mondaiId": 103260282,
                "name": "第28問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
@  
文中の下線部②に示す状況に対するY氏の対応策に関する記述として、最も  
適切なものはどれか。  
  
ア  インターネット小売事業と実店舗による小売事業との間の明確な線引きが今  
後より必要となってくる。そのため、顧客対応のための組織体制づくりにおい  
ても両者の相乗り状況を排除して、それぞれの形態固有のサービス品質の向上  
に取り組んでいくことが望まれる。  
  
イ  同じ顧客であっても、実店舗で購買する場合とインターネット店舗で購買す  
る場合とでは買い物目的は大きく異なるので、顧客データ上の扱いとしては  
別々の個人として認識する方が有効である。  
  
ウ  顧客データ分析の基盤がかなり整ってきている場合には、オムニ・チャネル  
化の推進が望ましい。そのプロセスでは、インターネット店舗とすべての実店  
舗を横断する形での顧客情報の統合や在庫データの共有によって、従来難しか  
ったサービスの提供が視野に入ってくる。  
  
エ  消費者費用の観点から判断すると、インターネット店舗で購買した方が、顧  
客にとってより負担の少ないことが明らかである。したがって、今後はインタ  
ーネット販売をさらに重視することが望ましい。  
        
`
        },
        {
                "mondaiId": 103260291,
                "name": "第29問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
美容院を営む一家で育ったP氏は、美容専門学校に進学して美容師になった兄・  
姉とは異なり、高校卒業後、ある大学で企業経営理論を学んだ。大学卒業後、P氏  
は消費財メーカーでの営業やマーケティング調査部署などにおける数年間の勤務を  
経て、両親が経営する  
①  
フランチャイズ方式による美容院の成長を支援するために基  
幹スタッフとして入社した。そこで、P氏は将来の美容院チェーン経営者にとって  
不可欠な  
②  
サービスやサービス・マーケティングについて、フランチャイズ方式での  
事業拡大の構想と関連付けながら学ぶことにした。  
（設問1）@  
文中の下線部①に示す｢フランチャイズ方式｣に関する記述として、最も適切な  
ものはどれか。  
  
ア  P氏の兄と姉はともに美容師であり、資格取得後、家業の美容院に勤務して  
いたが、法人を設立して独立するにあたってこの美容院とフランチャイズ契約  
を結び、第一号のフランチャイザー、つまり加盟店となった。  
  
イ  P氏は将来的に美容院事業の国際展開も視野に入れているが、国境を越えた  
フランチャイズ・システムの導入はサービス業においては先例がみられないた  
め、代替的な参入様式を検討している。  
  
ウ  加盟店から確実に一定額のロイヤルティを徴収するためには、粗利益分配方  
式を導入することが適切である。  
  
エ  フランチャイズ方式とは、一般的に一定の資金の制約のもとでのスピーディ  
ーな多店舗化を達成するための手段のひとつである。  
        
`
        },
        {
                "mondaiId": 103260292,
                "name": "第29問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
@  
文中の下線部②に示す｢サービスやサービス・マーケティング｣に関する記述と  
して、最も適切なものはどれか。  
  
ア  P氏は加盟店を募集するにあたって、事前の生産と在庫ができないというサ  
ービス固有の問題を、本部による継続的なカット・シャンプーの技能講習を通  
して有効に解決できる点を訴求している。  
  
イ  サービス・マーケティングのマネジメントでは、有形財の4Psにpeopleを  
加えた5Psで考えることが通常である。  
  
ウ  サービス・マーケティングをめぐる課題のひとつはサービスの無形性に対し  
て可視的な属性を付加していくことであるが、フランチャイズ方式の導入によ  
ってこの問題の解決を図ることは極めて難しい。  
  
エ  フランチャイズ方式を通じて大学のキャンパスや空港など新たなタイプの立  
地における加盟店の獲得を目指す場合には、チェーン全体の中で価格やサービ  
ス内容の柔軟性を獲得することが可能となる。  
        
`
        },
        {
                "mondaiId": 103260293,
                "name": "第29問設問3",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005@  
文中の下線部②に示す｢サービスやサービス・マーケティング｣に関する記述と  
して、最も適切なものはどれか。  
  
ア  サービス業においては、従業員が顧客に対して全力で尽くすことはもちろ  
ん、顧客こそが価値を共につくるパートナーであるという認識をもつことが重  
要である。  
  
イ  サービスの特性のひとつに｢非均質性｣がある。この問題を解決するための適  
切な方法が、顧客に対する来店ポイント付与制度である。  
  
ウ  従業員の組織に対する当事者意識の水準が高い企業の間では、顧客が知覚す  
る価値が低くなる傾向が、近年の研究によって明らかにされている。  
  
エ  美容院の顧客から寄せられる主な不満は｢カットした後、しばらくすると、  
髪型が崩れてしまう｣というものである。このような消費による価値の消滅は  
サービスに固有の性格であり、有形財には当てはまらない。  
        
`
        },
        {
                "mondaiId": 103260301,
                "name": "第30問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
消費行動の分析においては、一般的に消費者個人ではなく、家族という  
Ａ単位、あるいは家計というＢ単位が基本的な分析の単位として  
用いられる。その理由は、Ｃの選択やＤの配分において、家族人  
数に代表される規模的要因が大きく影響するため、個人ベースでの分析よりも家計  
単位での分析が適しているからである。  
Ｄの配分としての消費行動は、生活様式やＣによって規定され  
るが、消費行動を分析する視点には、3つの代表的アプローチがある。それらは、  
①  
ライフサイクル・アプローチ、ライフスタイル・アプローチ、ならびに  
②  
ライフコー  
ス・アプローチである。いずれも、生活主体としての家族ないし個人の生活構造上  
の特徴に着目し、その集約的指標と消費行動とを関連付けて分析するための視点で  
ある。（設問1）@  
文中の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：社会Ｂ：経済Ｃ：消費様式D：支出  
  
イ  Ａ：社会Ｂ：契約Ｃ：購買様式D：支出  
  
ウ  Ａ：生活Ｂ：経済Ｃ：購買行動D：権限  
  
エ  Ａ：生活Ｂ：契約Ｃ：購買行動D：権限  
  
オ  Ａ：文化Ｂ：社会Ｃ：購買様式D：資源  
        
`
        },
        {
                "mondaiId": 103260302,
                "name": "第30問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
)  
文中の下線部①に示す｢ライフサイクル・アプローチ、ライフスタイル・アプ  
ローチ｣に関する記述として、最も適切なものはどれか。  
  
ア  近年の家計調査によれば、家族ライフサイクルの終点近くに位置する後期高  
齢者による耐久消費財支出の増加傾向が読み取れる。  
  
イ  ライフサイクル・アプローチに示されるフルネスト(fullnest)段階におかれ  
た家計の消費支出をみると、医療、外食、ファッションといった項目の構成比  
が高まることが分かる。  
  
ウ  ライフサイクル・アプローチは、家族という集団を人の一生に例え、｢家族  
のライフサイクル｣の普遍的な共通性に着目したアプローチである。個別の家  
族に固有な出来事の影響を反映した分析を行う点に最も大きな特徴がある。  
  
エ  ライフスタイル・アプローチは、モチベーション・リサーチやパーソナリテ  
ィ研究から発展したサイコグラフィクスを源流とするとされる。`
        },
        {
                "mondaiId": 103260303,
                "name": "第30問設問3",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005)  
文中の下線部②に示す｢ライフコース・アプローチ｣に関する記述として、最も  
適切なものはどれか。  
  
ア  Fさんは、アメリカ人の夫とともに英会話による学童保育施設を開業した。  
これはDINKS型ライフコースを選択する家族の増加を受けてのことである。  
  
イ  ライフコース・アプローチでは、近年着目される｢絆｣の重視や｢家族回帰｣を  
通じた家族や友人グループの中での合意に基づいた集団的な意思決定の影響が  
尊重されている。  
  
ウ  ライフコースの概念では、ライフイベントごとの選択のあり方が個々の人生  
の道筋の多様化を生み出すとされている。これら選択の多様化によって、社会  
人教育や婚活(結婚活動)など新たな消費機会が生まれる。  
  
エ  ライフコースの複雑化により、年齢別労働力率曲線にみる女性の年齢階級別  
の就労状況は｢V字曲線｣と呼ばれるようになっている。  
        
`
        },
        {
                "mondaiId": 103260310,
                "name": "第31問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の図は、マーケティング・ミックスにおける価格の位置付けを示したもので  
ある。この図に関する記述として、最も不適切なものを下記の解答群から選べ。  
・図中の　　　は、市場関係を表す。  
・図中の　　　は、組織内部の意思決定による定義関係を表す。  
・図中の×、＋、－は、演算記号である。  
マーケティング  
費用  
需要数量  
A  
B  
C  
－  
価格  
その他の費用総費用  
売上高  
利益  
（損失）  
×  
＋  

[解答群]  
  
ア  図中の点線Aに示す関係からは、広告や営業などの形で投下するマーケテ  
ィング費用が需要数量の増加を意図していることが分かる。  
  
イ  図中の点線Bに示すマーケティング費用と価格との関係からは、特定の製  
品の価格水準が、製品差別化の程度や販売経路の特徴といった他のマーケティ  
ング・ミックス属性の影響を受けていることが分かる。  
  
ウ  図中の点線Cに示す関係からは、設定した価格によって需要数量が変動す  
ることが分かる。ブランド化した製品では需要の価格弾力性が大きくなり、高  
価格でも多くの販売数量の実現が可能となる。  
  
エ  マーケティング費用は、管理費用などの間接費の製品配賦額や製造原価とい  
う直接費とは区別される、マーケティング活動に固有の費用として識別される。  
        
`
        },
        {
                "mondaiId": 103260320,
                "name": "第32問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ブランドに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  競争関係にある企業の技術水準が高い位置で平準化している状況では、同じ価  
格帯の商品であれば機能的・品質的な違いを認めることが難しくなる。このよう  
な状態をパリティ(parity)と呼ぶ。それはコモディティ化の原因のひとつでもあ  
る。  
  
イ  ブランド・エクイティとは、ブランド名やシンボルなどと結び付いて形成・蓄  
積された無形の正味資産を指す。  
  
ウ  ブランド・エクステンション(ブランド拡張)とは、ある製品カテゴリーにおい  
て確立されたブランド名を同種の製品カテゴリー内の新しい製品に活用すること  
である。  
  
エ  ブランドの機能に従った分類を行うと、企業ブランドと製品ブランド(個別ブ  
ランド)に分けることができる。これらのブランドには、品質保証の役割(エンド  
ーサーとしての役割)や購買駆動機能(ドライバーとしての役割)がある。  
        
`
        },
        {
                "mondaiId": 103260330,
                "name": "第33問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業の｢海外市場への進出｣に関連して、企業が国境を越えたマーケティング活動  
を展開するまで、そして、その程度を深めていくプロセスには、一般的にみて次の  
5つの段階が存在すると考えられている。  
第1段階：国内マーケティング(Domesticmarketing)  
第2段階：輸出マーケティング(Exportmarketing)  
第3段階：国際マーケティング(Internationalmarketing)  
第4段階：多国籍マーケティング(Multinationalmarketing)  
第5段階：グローバルマーケティング(Globalmarketing)  
これらの段階に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  第1段階では、対象とする市場は国内となる。これら企業の競争相手には国内  
の企業だけでなく海外企業も含まれるが、この段階では多くの企業は国際的な事  
業環境への注視を行わない。  
  
イ  第2段階では、中間業者を利用する形での輸出が着手され、次第に直接輸出に  
移行していく場合がある。こうした国際化の展開は、組織内部の問題の観点から  
とらえると、経営者のマインドや組織資源・能力、あるいは事業規模によって左  
右されることがある。  
  
ウ  第3段階では、海外事業は完全に企業の事業活動の重要な構成要素として統合  
化される。この段階では進出先の市場ごとに異なる文化、社会経済的背景を考慮  
に入れた上で、標準製品の使用方法や使用場面を工夫する提案を行うことで適応  
化が進展する。  
  
エ  第4段階では、製品開発、生産、マーケティング活動などを主要地域の中で統  
合的に実施することがもたらす規模の経済の重要性が高まる。共通性の高い地域  
内での統一コミュニケーション・キャンペーンを実施したり、物流費用の共同負  
担や生産拠点の共同化を採用したりすることが具体的な内容である。  
  
オ  第5段階では、従来の段階でみられた高コスト化傾向を防ぐために、マーケテ  
ィング・ミックス標準化によるグローバル顧客の創出や、事業活動全体の国境を  
越えた統合性の強化などを実施している。  
`
        },
        {
                "mondaiId": 104260010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
安全性の評価値のひとつとして用いられる災害発生の頻度を表す度数率の式とし  
て、最も適切なものはどれか。  
  
ア  ‰死傷災害件数庵1，000，000´／延べ労働時間数  
  
イ  ‰死傷災害件数庵1，000，000´／延べ労働日数  
  
ウ  ‰労働損失日数庵1，000´／延べ労働時間数  
  
エ  ‰労働損失日数庵1，000´／延べ労働日数`
        },
        {
                "mondaiId": 104260020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工場レイアウトの分析手法に関する記述として、最も適切なものはどれか。  
  
ア  アクティビティ間の相互の関係を近接性の観点から検討するために、アクティ  
ビティ相互関係図表を用いる。  
  
イ  職場や生産設備の近接性を職場や生産設備の向き‰Direction´と関係強度  
‰Intensity´の観点から検討するために、DI分析を用いる。  
  
ウ  複数の品種について流れの共通性を製品の品種と生産量の観点から検討するた  
めに、多品種工程図表を用いる。  
  
エ  レイアウトを作成するときに重点をおくべき製品の種類‰Product´を流量  
‰Rate´の観点から検討するために、P安R分析を用いる。  
        
`
        },
        {
                "mondaiId": 104260030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄に入る語句として、最も適切なものを下記の解答群から選べ。  
VE実施手順の基本ステップのひとつである｢機能評価｣は、、機能の  
評価、対象分野の3つから構成される。  

[解答群]  
  
ア  アイデア発想  
  
イ  機能別コスト分析  
  
ウ  機能の定義  
  
エ  分析対象の情報収集`
        },
        {
                "mondaiId": 104260040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品や設備のライフサイクルに関する記述として、最も適切なものはどれか。  
  
ア  製品のライフサイクルは、導入期、成熟期、成長期、衰退期の順序で示される。  
  
イ  設備のライフサイクルにおける故障率の推移を示したグラフのひとつに、バス  
タブ曲線がある。  
  
ウ  設備のライフサイクルは、計画、設計、製作、運用、保全までを指している。  
  
エ  ライフサイクルコスティングは、一般に環境コストを含まない。  
        
`
        },
        {
                "mondaiId": 104260050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品の開発プロセスに関する記述として、最も適切なものはどれか。  
  
ア  生産技術や量産技術を先取りして設計・開発するために、フロントローディン  
グ活動を行う。  
  
イ  製品企画、製品開発、生産準備の作業を同時並行して行うために、ウォーター  
フォール型開発を行う。  
  
ウ  製品の使いやすさを試作段階で把握するために、製品工程分析を行う。  
  
エ  製品の組み立てやすさを設計段階で把握するために、組作業分析を行う。`
        },
        {
                "mondaiId": 104260060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工程管理で用いられる用語および略号に関する記述として、最も適切なものはど  
れか。  
  
ア  改善を行うときの問いかけとして、｢なくせないか、一緒にできないか、順序  
の変更はできないか、標準化できないか｣があり、これらを総称して｢ECRSの原  
則｣と呼ぶ。  
  
イ  職場の管理の前提として、｢整理、整頓、清掃、習慣、躾｣があり、これらを総  
称して｢5S｣と呼ぶ。  
  
ウ  生産活動を効率的に行うための考え方として、｢単純化、専門化、標準化｣があ  
り、これらを総称して｢3S｣と呼ぶ。  
  
エ  生産管理が対象とするシステムの構成要素として、｢Man，Machine，  
Management｣があり、これらを総称して｢3M｣と呼ぶ。  
        
`
        },
        {
                "mondaiId": 104260070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ライン生産方式に関する記述として、最も適切なものはどれか。  
  
ア  混合品種組立ラインでは、生産する品種により作業ステーションの構成を切り  
替え、多品種が生産される。  
  
イ  混合品種組立ラインの編成効率は、一般に、すべての品種の総作業時間の総和  
を‰作業ステーション数庵サイクルタイム´で除すことで計算される。  
  
ウ  サイクルタイムは、ピッチタイムとも呼ばれ、生産ラインの生産速度の逆数と  
して計算される。  
  
エ  ライン生産方式では、一般に、生産設備をライン上に配置し、作業者がライン  
を移動するにつれて製品が加工される。`
        },
        {
                "mondaiId": 104260080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工程管理方式に関する記述として、最も適切なものはどれか。  
  
ア  完成品や仕掛品の現品管理を容易にするために、追番管理方式を採用した。  
  
イ  工程間の仕掛在庫量を管理するために、製番管理方式を採用した。  
  
ウ  受注見積りの時点で信頼できる納期を提示するために、かんばん方式を採用し  
た。  
  
エ  注文品ごとに部品を管理するために、生産座席予約方式を採用した。  
        
`
        },
        {
                "mondaiId": 104260090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
ある製品をロット生産している工場で、以下の表に示す\u0007日間の需要量‰個´に対  
する生産計画を考える。製品を生産する日には、生産に先だち段取りが必要で、\f  
回当たり段取り費5，000円が発生する。また、生産した製品を当日の需要に充当す  
る場合、在庫保管費は発生しないが、翌日以降に繰り越す場合、繰越在庫量に比例  
して、\f個1日当たり10円の在庫保管費が発生する。  
生産計画の案は1日目に\u0007日間の総需要量700個を生産する計画で、総費用  
‰段取り費と在庫保管費の合計´は16，000円になる。  
案\f?案  
は総需要量700個を\u000e回に分けて生産する計画である。これらの中で  
総費用を最小にするものを、下記の解答群から選べ。  
123  
案  
案\u000e  
案  
        
日  
案\u0005  
案\f  
需要量  
総費用  
16，000  
0  
52000  
3800320  
2005000  
70000  
200180140  
6000  
5  
100  
0  
0  
0  
0  
100  
4  
80  
0  
0  
0  
180  
0  

[解答群]  
  
ア  案\f  
  
イ  案\u000e  
  
ウ  案\u0005  
  
エ  案  
        
        
`
        },
        {
                "mondaiId": 104260100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
受注したプロジェクトを遂行するために必要な作業の先行関係と所要日数が以下  
の表に与えられている。1日の作業遅れがプロジェクトの所要日数に影響する作業  
の数を、下記の解答群から選べ。  
4D，E，FG  
A  
C  
E  
作業名  
F  
D  
B  
所要日数先行作業  
5安  
3A  
4A  
5B  
3B，C  
5C  

[解答群]  
  
ア  2個  
  
イ  3個  
  
ウ  4個  
  
エ  5個  
        
`
        },
        {
                "mondaiId": 104260110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある工場では、2つの生産設備を用いて\u000e種類の製品A、Bが生産可能である。  
以下の表には、製品\f単位を生産するのに必要な工数と製品\f単位当たりの利益、  
および各設備において使用可能な工数が示されている。  
使用可能な工数の範囲内で製品A、Bを生産するとき、下記の解答群に示す生産  
量の組(Aの生産量，Bの生産量)のうち、総利益を最も高くする実行可能なものは  
どれか。  
6  
4  
製品\f単位  
当たりの利益  
設備\f設備\u000e  
製品B  
使用可能工数  
製品A  
安2028  
42  
24  

[解答群]  
  
ア  (10，0)  
  
イ  (7，0)  
  
ウ  (0，5)  
  
エ  (6，2)`
        },
        {
                "mondaiId": 104260120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
材料、部品などを常備品として管理するための要件として、最も適切なものはど  
れか。  
  
ア  継続的に消費され共通的に使用される。  
  
イ  単価が高く在庫費用の負担が重い。  
  
ウ  長期間保存した場合に品質が劣化する。  
  
エ  調達期間が短く消費が少ない。  
        
`
        },
        {
                "mondaiId": 104260130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
TQM‰総合的品質管理´の活動要素は、次の3つに大別される。  
①新製品開発管理・プロセス保証  
②方針管理・小集団改善活動・品質管理教育  
③標準化・日常管理  
TQMの手法と関連性の強い活動要素の組み合わせとして、最も適切なものはど  
れか。  
  
ア  QC七つ道具安③標準化・日常管理  
  
イ  改善の手順安②方針管理・小集団改善活動・品質管理教育  
  
ウ  品質機能展開安②方針管理・小集団改善活動・品質管理教育  
  
エ  プロセスフローチャート安①新製品開発管理・プロセス保証`
        },
        {
                "mondaiId": 104260140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
工程分析に関する記述として、最も適切なものはどれか。  
  
ア  工程図記号における基本図記号は、  
つの要素工程‰加工、運搬、停滞、検査´  
を示すために用いられる。  

<hint hidden='true'>**
⭕製品工程分析では、加工、運搬、停滞、検査を示すための図記号を用います。
** </hint>


イ  工程分析における工程図は、生産対象物に変化を与える要素工程のレイアウト  
を示すために用いられる。  
  
ウ  作業者と製品の流れを同時に分析するために、作業者工程分析を用いる。  
  
エ  要素工程の複数の機能または状態を示すために、基本図記号を並べて用いる。  
        
`
        },
        {
                "mondaiId": 104260150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある作業における余裕率を算定するために、非作業時間を除く1日の仕事時間の  
中で、余裕の発生頻度を計測するワークサンプリングを実施した。余裕率(％)は、  
全計測数に対する余裕の発生回数が占める割合として求められる。この余裕率を用  
いた作業の標準時間の計算式として、最も適切なものはどれか。  
  
ア  正味時間庵1＋  
余裕率  
100  
  
イ  正味時間庵1＋余裕率  
100  
  
ウ  正味時間庵1  
1安  
余裕率  
100  
  
エ  正味時間庵1安  
1  
余裕率  
100`
        },
        {
                "mondaiId": 104260160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
作業研究(作業測定と方法研究)に関する記述として、最も適切なものはどれか。  
  
ア  作業測定では、作業が｢工程｣、｢単位作業｣、｢単位動作｣のいずれかに分割され  
る。  
  
イ  時間測定の計測単位である1DM(DecimalMinute)は、0.1分を表している。  
  
ウ  方法研究では、作業または製造方法を分析し、標準化、統合化によって作業方  
法または製造工程を設計・改善する。  
  
エ  モーションマインドは、作業研究の分析手法を的確に実施・活用するための能  
力や態度を意味している。  
        
`
        },
        {
                "mondaiId": 104260170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の①~④に示す事象に対して作業者工程分析を行った。｢作業｣に分類された  
事象の数として、最も適切なものを下記の解答群から選べ。  
①対象物を左手から右手に持ち替える。  
②機械設備での対象物の加工を作業者が監視する。  
③対象物を加工するための前準備や加工後の後始末をする。  
④出荷のために対象物の数量を確認する。  

[解答群]  
  
ア  1個  
  
イ  2個  
  
ウ  3個  
  
エ  4個`
        },
        {
                "mondaiId": 104260180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
設備の信頼性や安全性の向上対策に関する記述として、最も適切なものはどれ  
か。  
  
ア  故障が生じても設備の安全性を確保するために、フールプルーフの考え方を取  
り入れて設備設計を行った。  
  
イ  人為的に不適切な行為があっても設備の信頼性を確保するために、フェールセ  
ーフの考え方を取り入れて設備設計を行った。  
  
ウ  設備の信頼性向上や寿命の延長を図るために、ディレーティングを実施した。  
  
エ  定常運用の前に設備の問題個所を把握しておくために、バーンインを実施し  
た。  
        
`
        },
        {
                "mondaiId": 104260190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
設備総合効率の算出に必要なデータの組み合わせとして、最も適切なものはどれ  
か。  
  
ア  加工数量、基準サイクルタイム、負荷時間  
  
イ  加工数量、基準サイクルタイム、良品数量  
  
ウ  加工数量、負荷時間、良品数量  
  
エ  基準サイクルタイム、負荷時間、良品数量`
        },
        {
                "mondaiId": 104260200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
マテリアルハンドリングを合理化するための原則に関する記述として、最も不適  
切なものはどれか。  
  
ア  原材料の運搬量や運搬回数を削減するために、必要量以上の原材料を製造ライ  
ンに払い出さないようにした。これは｢自重軽減の原則｣にかなう改善である。  
  
イ  工場の床面とトラックの荷台の高さを揃えることで、フォークリフトを使って  
トラックから直接原材料を搬入できるようにした。これは｢継ぎ目の原則｣にかな  
う改善である。  
  
ウ  作業者が個別に運搬を行ってきた複数種類の原材料を、運搬用パレットを準備  
してフォークリフトで一度に運べるようにした。これは｢ユニット化の原則｣にか  
なう改善である。  
  
エ  倉庫の床に直接置いていた製品をパレット上で保管するようにした。これは  
｢活性荷物の原則｣にかなう改善である。  
        
`
        },
        {
                "mondaiId": 104260210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ISO14051で規定されるマテリアルフローコスト会計(MFCA：MaterialFlow  
CostAccounting)に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  MFCAで算定されるコストは、マテリアルコスト、システムコスト、エネル  
ギーコスト、廃棄物管理コストの  
つから構成される。  
  
イ  MFCAでは、組織内のマテリアルのフローおよびストックを物量単位で追  
跡・定量化するマテリアルフローモデルを策定する。  
  
ウ  MFCAにおけるコストの配賦基準である｢マテリアル配分率｣は、全品種の総  
生産量の中における各品種の生産量が占める割合として算定される。  
  
エ  MFCAは、組織におけるマテリアルおよびエネルギーの使用に関する透明性  
の向上を狙った会計手法である。  
        
`
        },
        {
                "mondaiId": 104260220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある食品スーパーは、人口100万人の市の第一種住居地域に、店舗面積  
1，600m2の新しい店の出店を計画している。商圏内には中学校があり、駐車場出  
入口が面する道路は、一方通行規制により進入路が限定されている。また、この店  
舗の自動車分担率は60％であり、来店客数が最大となるのは休日であると予測さ  
れている。この食品スーパーの大規模小売店舗立地法に基づく対応に関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  新店舗への来店者がどの方面からどの程度来店するかを、アクセス経路や町丁  
目別世帯数等から予測した。  
  
イ  駐車場出入口の直近交差点付近に看板を設置し、出店計画により設定された経  
路を通行するよう案内することにした。  
  
ウ  平日\f時間に予想される来客の自動車台数を基本に、新店舗に必要な駐車台数  
を算出した。  
  
エ  来客が学校に面する道路を通行しないように案内する看板を設置した。  
        
`
        },
        {
                "mondaiId": 104260230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
平成26年に改正された中心市街地活性化法に関する記述として、最も適切なも  
のはどれか。  
  
ア  小売業の顧客の増加や小売事業者の経営の効率化を支援するハード事業を認定  
する制度を設けることとなった。  
  
イ  国土交通省とも連携し、災害に強い市街地実現に向け、道路占有許可を厳しく  
運用することとなった。  
  
ウ  地元の意見を十分に反映させる制度とするため、商業活動調整協議会を設置す  
ることとなった。  
  
エ  中心市街地への来訪者や就業者、小売業の売上高を相当程度増加させるなどの  
効果が高い民間プロジェクトを支援対象として認定することとなった。  
        
`
        },
        {
                "mondaiId": 104260240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本ショッピングセンター協会のデータから確認できるショッピングセンター  
(SC)の状況について、次の文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最  
も適切なものを下記の解答群から選べ。  
2012年に新たに開業したSC数は35であった。これらのSCの平均店舗面積は  
約14，800m2で、平均テナント数はＡであった。2012年の新規開業SC数  
および平均店舗面積は、5年前の2007年に比べてＢしている。また、  
2012年末時点のSCにおいて最も多い核店舗はＣである。  

[解答群]  
  
ア  Ａ：55Ｂ：減少Ｃ：百貨店  
  
イ  Ａ：55Ｂ：減少Ｃ：総合スーパー  
  
ウ  Ａ：55Ｂ：増加Ｃ：百貨店  
  
エ  Ａ：155Ｂ：増加Ｃ：総合スーパー  
  
オ  Ａ：155Ｂ：増加Ｃ：百貨店`
        },
        {
                "mondaiId": 104260250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業庁のv平成24年度商店街実態調査報告書yから確認できる平成24年の商  
店街の実態に関する記述として、最も適切なものはどれか。  
  
ア  空き店舗率商店街の空き店舗数の合計  
商店街の全店舗数の合計は、平成21年度調査に比べて減少  
した。  
  
イ  商店街組織の約  
3  
4  
は、専従事務局員が不在である。  
  
ウ  商店街組織への会費の未納率は、\u0005割を超えている。  
  
エ  退店あるいは廃業した理由として最も多いのは、｢大型店の進出｣である。  
        
`
        },
        {
                "mondaiId": 104260260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
食品スーパーのバックヤードの衛生管理に関する記述として、最も不適切なもの  
はどれか。  
  
ア  駆除作業に殺そ剤または殺虫剤を使用する場合には、食品、器具および容器包  
装を汚染しないよう、その取扱いに十分注意する。  
  
イ  商品に異物が混入するのを防ぐため、ざるやボウル、計量カップは、割れやす  
い樹脂製のものよりステンレス製のものを使用する。  
  
ウ  食品トレーは、作業の効率性を重視して商品を載せる面を上向きに保管する。  
  
エ  冷却機能に問題がないか、適切な温度が維持されているかどうかを確認するた  
めに、冷蔵庫に温度計を付け、その温度を記録する。`
        },
        {
                "mondaiId": 104260270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
PI(PurchaseIncidence)値を用いた需要予測に関する次の文中の空欄ＡとＢに入  
る数字の組み合わせとして、最も適切なものを下記の解答群から選べ。  
ある小売店舗で、ある日3，000人がレジを通過した。この日に商品Xが60個売  
れたとき、この商品のPI値はＡである。この店舗で翌週の同曜日に見込  
まれるレジ通過人数が4，000人のとき、商品Xの販売数量はＢ個と予測  
できる。  

[解答群]  
  
ア  Ａ：20Ｂ：65  
  
イ  Ａ：20Ｂ：80  
  
ウ  Ａ：50Ｂ：65  
  
エ  Ａ：50Ｂ：80  
        
`
        },
        {
                "mondaiId": 104260280,
                "name": "第28問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
仕入単価80円で1，000個仕入れた商品の販売価格を売価値入率20％で設定し  
た。この商品を800個販売したところで、売れ行きが悪くなってきた。そこで、残  
りの200個を当初の販売価格から10％値下げしてすべて販売した。この結果の粗  
利益率(小数点第\u000e位を四捨五入)として、最も適切なものはどれか。なお、消費税  
は考慮しないものとする。  
  
ア  15.4％  
  
イ  16.4％  
  
ウ  17.4％  
  
エ  18.4％  
  
オ  19.4％`
        },
        {
                "mondaiId": 104260290,
                "name": "第29問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
店舗における商品陳列に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  アイランドタイプのショーケース陳列は、どの方向からでも商品を見ることが  
できる。  
  
イ  衣料品の陳列で用いられるボックス陳列は、商品のデザインが見えにくい。  
  
ウ  カットケース陳列は、価格の安さを訴求しやすい。  
  
エ  ジャンブル陳列は、商品が少なくなると売れ残りのイメージが出やすい。  
  
オ  前進立体陳列は、前出し作業が不要である。  
        
`
        },
        {
                "mondaiId": 104260300,
                "name": "第30問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある小売店の2013年の月次売上高と月別平均指数は、以下の表のとおりであっ  
た。2014年の年間売上高予算が3，000万円のとき、2014年10月の月次売上高予算  
を月別平均法で求めたものとして、最も適切なものを下記の解答群から選べ。  
80  
80  
60  
月別平均指数  
160  
120  
月次売上高  
‰万円´  
2  
月  
3  
1  
1212060  
11  
160  
200100  
10240120  
9320160  
8320160  
7240120  
6200100  
516080  
416080  

[解答群]  
  
ア  240万円  
  
イ  250万円  
  
ウ  288万円  
  
エ  300万円  
  
オ  320万円  
        
`
        },
        {
                "mondaiId": 104260310,
                "name": "第31問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
イ  ンストアマーチャンダイジングに関する次の文中の空欄ＡとＢに入る語句の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  
客単価を上げるためには、インストアマーチャンダイジングを実践することが有  
効である。たとえば、Ａためにはマグネットポイントの配置を工夫するこ  
とが重要である。また、棚の前に立ち寄った客の視認率を上げるためには  
Ｂことが重要である。  

[解答群]  
  
ア  Ａ：買上率を高めるＢ：CRMを実施する  
  
イ  Ａ：買上率を高めるＢ：プラノグラムを工夫する  
  
ウ  Ａ：客の動線長を伸ばすＢ：CRMを実施する  
  
エ  Ａ：客の動線長を伸ばすＢ：プラノグラムを工夫する  
        
`
        },
        {
                "mondaiId": 104260320,
                "name": "第32問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  
小売業者がインターネット通販を行う場合は、Ａに基づいて、事業者の  
名称、住所および電話番号などについて表示しなければならない。Ａは、  
基本的にはＢに適用される。  

[解答群]  
  
ア  Ａ：特定商取引に関する法律(特定商取引法)  
Ｂ：事業者間の取引  
  
イ  Ａ：特定商取引に関する法律(特定商取引法)  
Ｂ：事業者と消費者との間の取引  
  
ウ  Ａ：不当景品類及び不当表示防止法(景品表示法)  
Ｂ：事業者間の取引  
  
エ  Ａ：不当景品類及び不当表示防止法(景品表示法)  
Ｂ：事業者と消費者との間の取引  
        
`
        },
        {
                "mondaiId": 104260330,
                "name": "第33問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットを利用した商品販売に関する記述として、最も適切なものはどれ  
か。  
  
ア  インターネットオークションは個人間取引に限られる。  
  
イ  電子商店街(オンライン・ショッピングモール)に出店した場合、販売者は独自  
に商品の販売価格を決めることができる。  
  
ウ  ドロップシッピングでは、販売者が自らの在庫をドロップシッピングサービス  
プロバイダーに預けて、消費者から受注した後に出荷を依頼する。  
  
エ  フルフィルメントサービスを利用すると、販売者は在庫を持たなくてもサービ  
ス提供企業に仕入れ・受注・出荷等の業務を委託することができる。`
        },
        {
                "mondaiId": 104260340,
                "name": "第34問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センターに関する用語の説明として、最も適切なものはどれか。  
  
ア  小売業が物流センターの運営を卸売業に委託した際に、小売業が卸売業に支払  
う費用をセンターフィーという。  
  
イ  顧客から注文された商品の総数をいったん集めて顧客別に商品を配分するピッ  
キング方法を摘み取り方式という。  
  
ウ  物流センターで商品を加工し、付加価値をつけることを流通加工という。  
  
エ  物流センターの在庫を仕分けし、各店舗に配送する仕組みのことをクロスドッ  
キングという。  
        
`
        },
        {
                "mondaiId": 104260351,
                "name": "第35問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある企業が物流センターのコストを削減するために、出荷作業についてABC分  
析を実施した。このときに作成した下表に基づき、下記の設問に答えよ。  
5，000  
0  
3，000  
資材消耗品  
費‰円´  
ケースピッキング  
段ボール箱梱包  
  
ア  クティビティ名  
行き先別仕分け  
ピースピッキング  
ピッキング準備  
月間処理量アクティビティ  
原価‰円´単位数量  
行3，00030，000  
ケース500120，000  
ピース5，000150，000  
箱10016，000  
ケース  
125，000  
100，000  
27，000  
人件費  
‰円´  
0  
0  
20，000  
20，000  
0  
機械設備  
費‰円´  
0  
10，000  
60060，000  
3，000012，000枚60015，000荷札貼付  
60，000  
6，000  
合計391，000安安330，00040，00021，000  
（設問1）´  
物流コストを削減する効果が最も大きいものはどれか。  
  
ア  ｢行き先別仕分け｣にかかる作業時間を短縮して人件費を10％削減する。  
  
イ  ｢ケースピッキング｣にかかる作業時間を短縮して人件費を\u000f％削減する。  
  
ウ  ｢段ボール箱梱包｣の資材消耗品の調達価格を下げて資材消耗品費を20％削  
減する。  
  
エ  ｢荷札貼付｣にかかる人件費を10％削減するとともに、資材消耗品費を  
50％削減する。  
        
`
        },
        {
                "mondaiId": 104260352,
                "name": "第35問設問2",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問2）  
  
ア  クティビティ単価として最も適切なものの組み合わせを、下記の解答群から  
選べ。  
ａケースピッキング200円／ケース  
ｂピースピッキング25円／ピース  
ｃ段ボール箱梱包100円／箱  
ｄ行き先別仕分け100円／ケース  
ｅ荷札貼付25円／枚  

[解答群]  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｃとｅ  
  
オ  ｄとｅ`
        },
        {
                "mondaiId": 104260360,
                "name": "第36問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
静脈物流に関する説明として、最も適切なものはどれか。  
  
ア  原材料を産地から工場へ輸送すること。  
  
イ  再使用や再資源化を目的に廃棄物を回収すること。  
  
ウ  物流センターから店舗まで配送したトラックが、返品商品を回収すること。  
  
エ  ひとつの企業内で工場から物流センターへトラックで貨物を輸送すること。  
        
`
        },
        {
                "mondaiId": 104260370,
                "name": "第37問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
店舗の在庫管理に関する記述として、最も適切なものはどれか。  
  
ア  定量発注方式で自動発注を行う場合、在庫が欠品しないように発注点を設定す  
る。  
  
イ  当月の売上高が前月より減少したとき、前月と平均在庫高が同じならば在庫回  
転率は高くなる。  
  
ウ  発注から納品までのリードタイムが長い場合、短い場合と比べて安全在庫は少  
なく設定する。  
  
エ  販売数量と比べて発注数量が多いと理論在庫は減少する。`
        },
        {
                "mondaiId": 104260380,
                "name": "第38問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本でのインストアマーキング用JANシンボルの印刷に際して留意すべき点に  
関する記述として、最も適切なものはどれか。  
  
ア  印刷スペースがとれない場合、シンボルのバーの高さを削ることができる。  
  
イ  印刷品質のチェック用に、携帯電話搭載のバーコードリーダーを使用すること  
ができる。  
  
ウ  シンボルのバーは赤色や青色のバーを印刷することができる。  
  
エ  シンボルは基本寸法の大きさで印刷しなければならない。  
  
オ  シンボルは原則的に黒地に白色のバーを用いる。  
        
`
        },
        {
                "mondaiId": 104260390,
                "name": "第39問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
XML(eXtensibleMarkupLanguage)を用いたEDIに関する記述として、最も適  
切なものはどれか。  
  
ア  Web画面を通じたデータのやりとりのため、受発注作業の簡素化が期待され  
ている。  
  
イ  業界ごとに取引画面の標準化が進んでおり、発注作業の効率化が期待されてい  
る。  
  
ウ  自社システムと連携できないことから取引先の入力代行に過ぎないという指摘  
がなされている。  
  
エ  発注のみの従来型EOSやWeb-EDIが混在することになり、運用の煩雑化が  
指摘されている。  
  
オ  ファイル転送を自動化することができ、受発注業務の負担軽減が期待されてい  
る。  
        
`
        },
        {
                "mondaiId": 104260400,
                "name": "第40問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流情報システムの一環としてICタグの利用が徐々に広がってきている。複数  
企業がICタグを利用する際のコード体系に関する記述として、最も適切なものは  
どれか。  
  
ア  他の企業との間でコードの重複が生じないように、なるべく複雑で独自のコー  
ド体系を採用することが望ましい。  
  
イ  独自のコード体系を採用することで、競争優位性を獲得・維持することができ  
る。  
  
ウ  他の企業の商品コードを読み取った場合にコードを判別できるように、コード  
体系の標準化が要請されている。  
  
エ  目標とする対象物以外のデータを読み込んでしまう場合があるために、自社の  
みで識別可能な独自のコード体系による運用が望ましい。  
        
`
        },
        {
                "mondaiId": 104260410,
                "name": "第41問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売店頭では、販売促進などのために消費者の個人情報を把握しようとする場合  
が少なくない。そこで、個人情報の有用性に配慮しながら、個人の権利利益を保護  
することを目的として、いわゆる｢個人情報保護法｣が制定された。この法律におけ  
る｢個人情報｣に関する記述の組み合わせとして、最も適切なものを下記の解答群か  
ら選べ。  
ａ生存者か死者かは問わない。  
ｂ公開情報か非公開情報かは問わない。  
ｃプライバシー性やセンシティブ性の有無は問わない。  
ｄ他の情報との照合可能性は問わない。  

[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ`
        },
        {
                "mondaiId": 104260420,
                "name": "第42問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
食品のトレーサビリティに関する記述として、最も適切なものはどれか。  
  
ア  RFIDなど最新の情報システムを整備・導入する必要がある。  
  
イ  消費者の商品選択に役立つよう、原材料の原産地名などの情報を表示する取り  
組みである。  
  
ウ  食品の安全を直ちに確保することができる取り組みである。  
  
エ  入出荷の記録に加えて、ロット情報の対応付けを明らかにするなどの高度な取  
り組みがある。  
`
        },
        {
                "mondaiId": 105260010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
X株式会社?以下｢X社｣という。?の発行済株式総数は、30万株であり、そのすべ  
てをAが保有していた。その後、Aは死亡し、B・C・D・Eの\u000e名のみが相続人  
としてAの財産を相続した。Bは、Aの配偶者である。C及びDは、AとBとの  
間で出生した子である。Eは、AとAと婚姻関係を有したことがないFとの間で  
出生した子であり、AはEを認知している?下図参照?。  
BAF  
CDE  
この場合、X社の株式の権利関係に関する記述として最も適切なものはどれか。  
なお、遺言はなく、遺産分割協議も整っておらず、相続人はいずれも廃除されて  
いないものとし、寄与分及び特別受益についても考慮しないものとする。  
  
ア  B、C、D及びEが30万株を共有し、Bの共有持分が2分の1、C、D及びE  
の\u0005名の共有持分がそれぞれ\u000f分の1となる。  
  
イ  B、C、D及びEが30万株を共有し、Bの共有持分が2分の1、C及びDの  
        
名の共有持分がそれぞれ\u0007分の1、Eの共有持分が10分の1となる。  
  
ウ  Bが15万株を、C、D及びEがそれぞれ\u0007万株を保有する株主となる。  
  
エ  Bが15万株を、C及びDがそれぞれ\u000f万株を、Eが\u0005万株を保有する株主と  
なる。  
        
`
        },
        {
                "mondaiId": 105260021,
                "name": "第2問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
X株式会社?以下｢X社｣という。?は、Y株式会社?以下｢Y社｣という。?との間で、  
それぞれが出資して株式会社形態の新会社を設立し、合弁事業を行おうとしてい  
る。これを前提に下記の設問に答えよ。  
（設問1）?  
次の  
つの条項は、X社がY社との間で締結した合弁契約書に定められたも  
のである。この  
つの条項の標題として空欄A及びBに入る語句の組み合わせ  
として最も適切なものを下記の解答群から選べ。  
Ａ  
第○条X社は、合弁会社の株式の全部又は一部を第三者に譲渡しようとする場  
合には、Y社に対し、書面をもって、当該譲渡の相手方?以下、本条におい  
て｢譲受人｣という。?の氏名又は名称、譲渡しようとする株式の数及び譲渡の  
条件を通知する。  
        
Y社は、前項の通知が到達した日から60日以内にX社に対して買取りを  
希望する旨の書面による通知を行った場合には、Y社が指定する公認会計  
士によって合弁会社の資産状態その他一切の事情を考慮して定められた価格  
で、X社が譲渡を希望する株式を買い取ることができるものとする。  
\u0005Y社は、X社に対し、合弁会社の株式の価格についての意見を前項の公  
認会計士に述べる機会を与えるものとする。  
\u000eX社は、Y社が、第  
項の期間内に買取りを希望する旨の書面による通  
知を行わなかった場合には、第\f項で通知した数の合弁会社の株式を譲受人  
に対して譲渡できるものとする。  
Ｂ  
第○条Y社は、次の各号の事由が生じた場合、X社に対し、Y社が保有する合  
弁会社の株式の全部又は一部を、Y社が指定する公認会計士によって合弁  
会社の資産状態その他一切の事情を考慮して定められた価格で、買い取るよ  
        
        
う請求することができるものとする。  
?X社Y社いずれの当事者の責めに帰することのできない事由により、  
X社とY社との間又はX社が指名した取締役とY社が指名した取締役  
との間で意見が対立して調整がつかず合弁会社の事業運営が滞った場合  
?X社Y社いずれの当事者の責めに帰することのできない事由により、  
合弁会社に重大な損失が生じ、合弁会社の事業の継続が不可能又は著し  
く困難となった場合  
?前各号に掲げるもののほか、X社Y社いずれの当事者の責めに帰す  
ることのできない事由により、合弁会社の事業の遂行が不可能となった  
場合  
[解答群]  
  
ア  Ａ：拒否権Ｂ：コール・オプション  
  
イ  Ａ：拒否権Ｂ：プット・オプション  
  
ウ  Ａ：先買権Ｂ：コール・オプション  
  
エ  Ａ：先買権Ｂ：プット・オプション  
        
`
        },
        {
                "mondaiId": 105260022,
                "name": "第2問設問2",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
)  
X社とY社の交渉により、合弁会社に対しては、Y社が議決権の過半数を確  
保できるように出資することとなった。しかし、取締役の全員をY社だけで選  
任できることとすると、合弁会社の運営にX社の意向が全く反映されないこと  
になりかねないので、累積投票制度を排除しないことになった。  
この点、株主総会に出席した株主が有する株式数の合計がa株である場合にお  
いて、b名の取締役を選任するときに、自派からc名の取締役を当選させること  
ができる最小株式数は、  
ac  
(b＋\f)  
＋\fV端数切捨Xとなることが知られている。  
合弁会社の発行済株式総数を90株、X社が保有する株式を31株、Y社が保  
有する株式を59株、選任する取締役の数を\u0007名とした場合に、Y社が株主総会  
に出席して反対しても、X社が当選させることができる取締役の最大の人数と  
して、最も適切なものはどれか。  
  
ア  名  
  
イ  1名  
  
ウ    
名  
  
エ  \u0005名  
        
`
        },
        {
                "mondaiId": 105260030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A氏は、X株式会社(以下｢X社｣という。)に対しB氏をX社の取締役に選任す  
る議案を株主総会に提出したい。  
これに関する記述として最も適切なものはどれか。  
ただし、X社は取締役会設置会社であり、A氏の所有株式数は10株で、X社の  
議決権のある株式数は2，000株である。  
また、当該議案と実質的に同一の議案は、過去\u0005年間上程されていないものとす  
る。  
  
ア  A氏はB氏を取締役とする議案の要領を株主総会の招集通知に記載すること  
を請求することはできないが、取締役選任の議題があれば株主総会においてB  
氏を取締役とする議案を提出することができる。  
  
イ  A氏はいかなる場合もB氏を取締役とする議案を株主総会に提出することは  
できない。  
  
ウ  A氏はいつでもB氏を取締役とする議案の要領を株主総会の招集通知に記載  
することを請求でき、かつ、株主総会においてB氏を取締役とする議案を提出  
することができる。  
  
エ  A氏は株主総会の\u0010週間前までであれば、B氏を取締役とする議案の要領を株  
主総会の招集通知に記載することを請求できるが、株主総会においてB氏を取  
締役とする議案を提出することができない。  
        
`
        },
        {
                "mondaiId": 105260041,
                "name": "第4問設問1",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
取引先の信用状態が悪化した場合における債権回収に関する以下の会話は、中小  
企業診断士であるあなたとX株式会社の代表取締役甲氏との間で行われたもので  
ある。この会話を読んで、下記の設問に答えよ。  
甲氏：｢Y社が振り出した手形が不渡りになったという情報を聞きました。Y社  
に対しては、売掛債権が1億円もあるんです。これを回収できないとなる  
と当社の経営に大きな打撃となってしまいます。｣  
あなた：｢何か担保は取っていなかったのですか。｣  
甲氏：｢何も取れませんでした。｣  
あなた：｢その売掛債権の内容はどうなっていますか。｣  
甲氏：｢Y社は、卸売業者です。当社は、取引基本契約書を締結して、Y社に対  
して当社の製品を販売し、Y社は、これを小売業者に転売しています。  
当社がY社に対して有する売掛債権は、当社の製品をY社に販売して発  
生したものです。｣  
あなた：｢その売掛債権について、支払期限は、もう来ているのですか。｣  
甲氏：｢いや、まだ来ていません。｣  
あなた：｢では、取引基本契約書に、Ａとして、手形の不渡りが定められ  
ていますか。｣  
甲氏：｢いや、ちょっと分かりません。｣  
あなた：｢ちょっと契約書を見せてもらえますか。｣  
甲氏：｢どうぞ、これです。｣  
あなた：｢どれどれ。ああ、ここに定められていますね。Ｂを行使するに  
は支払期限が到来している必要があるのですが、ここにＡとして  
手形の不渡りが定められているので、いますぐＢを主張できるか  
もしれません。Ｂを主張できれば、甲さんの会社がY社に販売  
した製品について、他の債権者に先立って弁済を受ける権利、つまり、優  
先弁済権が認められます。｣  
甲氏：｢でも、Y社は、既に、当社から購入した製品を転売して、買い受けた第  
        
        
三者がその製品の引渡しを受けてしまっているようです。それでも優先弁  
済権が認められるのですか。｣  
あなた：｢その場合でも、Y社が第三者から転売代金の支払を受けていなければ、  
その転売代金債権についてＢを行使できることになっています。  
これをＣといいます。｣  
甲氏：｢そんなことができるのですか。｣  
あなた：｢ただ、このＣもY社が第三者から転売代金の支払を受けてしま  
っていると行使することができません。｣  
甲氏：｢手続はどうすればいいのでしょうか。｣  
あなた：｢Y社が転売代金を受け取る前に裁判所に申し立てる必要があるので、弁  
護士さんに依頼するのがよいと思います。とにかく時間がないので、早く  
顧問弁護士の先生のところに相談に行きましょう。｣  
（設問1）?  
会話の中の空欄Ａに入る語句として最も適切なものはどれか。  
  
ア  危険の移転事由  
  
イ  期限の利益喪失事由  
  
ウ  契約の解除事由  
  
エ  契約の取消事由`
        },
        {
                "mondaiId": 105260042,
                "name": "第4問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
?  
会話の中の空欄Ｂ及びＣに入る語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  Ｂ：先取特権Ｃ：物上代位  
  
イ  Ｂ：先取特権Ｃ：物上保証  
  
ウ  Ｂ：抵当権Ｃ：物上代位  
  
エ  Ｂ：抵当権Ｃ：物上保証  
        
`
        },
        {
                "mondaiId": 105260050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
不当景品類及び不当表示防止法(以下｢景表法｣という。)で定義される表示に関す  
る記述として、最も適切なものはどれか。  
  
ア  イタリアで縫製され、日本でラベルが付された衣料品について、｢MADEIN  
ITALY｣と表示することは、景表法に違反する。  
  
イ  景表法上、比較広告を行うことは禁止されている。  
  
ウ  口頭で行うセールストークは、表示には含まれない。  
  
エ  消費者庁長官から、表示の裏付けとなる合理的な根拠を示す資料の提出を求め  
られたのに提出しなかった場合には、景表法に違反する表示とみなされる。`
        },
        {
                "mondaiId": 105260060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
不正競争防止法の商品等表示に、含まれないものはどれか。  
  
ア  学校法人の名称  
  
イ  化粧品について、｢尿素｣｢ヒアルロン酸｣等の成分表示  
  
ウ  商品の包装  
  
エ  俳優の芸名  
        
`
        },
        {
                "mondaiId": 105260070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
意匠制度に関する記述として最も適切なものはどれか。  
  
ア  意匠登録出願人は、意匠権の設定の登録の日から\u0005年以内の期間を指定して、  
その期間その意匠を秘密にすることを請求することができる。  
  
イ  関連意匠の意匠登録を受けた意匠が本意匠に類似しないものであることを理由  
として、その関連意匠の意匠登録に対して意匠登録無効審判を請求することがで  
きる。  
  
ウ  組物を構成する物品に係る意匠としてなされた意匠登録出願に対して、組物全  
体として統一性がないことを理由として意匠登録無効審判を請求することができ  
る。  
  
エ  ｢乗用自動車｣の意匠が公然知られている場合に、その乗用自動車と形状の類似  
する｢自動車おもちゃ｣について意匠登録出願したときは、その出願は新規性がな  
いとして拒絶される。`
        },
        {
                "mondaiId": 105260080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
商標登録の要件に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  外国の国旗と同一又は類似の商標は商標登録されることはない。  
  
イ  菊花紋章と同一又は類似の商標は商標登録されることはない。  
  
ウ  市町村を表示する標章と同一又は類似の商標は商標登録されることはない。  
  
エ  赤十字の標章又は名称と同一又は類似の商標は商標登録されることはない。  
        
`
        },
        {
                "mondaiId": 105260090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
商標制度に関する記述として最も適切なものはどれか。  
  
ア  継続して  
年間、日本国内において商標権者、専用使用権者又は通常使用権者  
のいずれもが各指定商品又は役務についての登録商標の使用をしていないとき  
は、その指定商品又は役務に係る商標登録を取り消すことについて審判を請求す  
ることができる。  
  
イ  商標権者の不正使用による商標登録の取消しの審判の請求は、請求人が利害関  
係人でないときは、審判を請求することができない。  
  
ウ  商標登録を取り消すべき旨の審決が確定したときは、商標権は、商標権の設定  
の登録の日に消滅したものとみなす。  
  
エ  登録異議申立てについての審理においては、登録異議の申立てがされていない  
指定商品又は指定役務については、審理することができない。`
        },
        {
                "mondaiId": 105260100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
著作権法に関する記述として最も適切なものはどれか。  
  
ア  ゴーストライターが自らの創作に係る著作物を他人名義で出版することに同意  
した場合、そのゴーストライターは、その著作物の著作者とはならない。  
  
イ  小学校の教科書に小説を掲載する際に、難解な漢字をひらがな表記に変更する  
行為は、同一性保持権の侵害となる。  
  
ウ  著作権者の許諾なく、スーパーマーケットで、BGMとしてCDの音楽を流す  
ことは、演奏権の侵害となる。  
  
エ  著作者人格権は、その全部又は一部を譲渡することができる。  
        
`
        },
        {
                "mondaiId": 105260110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次のうち、その法的主張が認められるためにはその主張者が無過失であることが  
必要な場合として、最も適切なものはどれか。  
  
ア  相手方と通謀の上、不動産を仮装譲渡したところ、仮装の事実を知らずに相手  
方から当該不動産の転売を受けた第三者が当該不動産の所有権を主張する場合  
  
イ  第三者から強迫行為を受けて契約させられた者に対して、強迫の事実を知らな  
かった契約の相手方が契約の有効性を主張する場合  
  
ウ  法律行為の要素の錯誤を理由とする意思表示の無効を表意者が主張する場合  
  
エ  無権代理人(成人)による契約と知らずに契約した相手方が、無権代理人に対し  
て当該契約の履行責任を主張する場合`
        },
        {
                "mondaiId": 105260120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費貸借契約に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  現金を渡した相手方から借用証を受け取っても、返済期日の約束がなければ、  
消費貸借契約の効力が生ずることはない。  
  
イ  準消費貸借契約のメリットとして、債務の弁済期を遅らせたり、売掛金の消滅  
時効期間を貸付金のそれに切り替えたり、金利改定ができる場合があることが挙  
げられる。  
  
ウ  消費貸借契約公正証書を作成しても、執行認諾文言がなければ、その公正証書  
を債務名義として強制執行をすることができない。  
  
エ  利息制限法上、貸付金50万円の約定利息の上限は年額9万円である。  
        
`
        },
        {
                "mondaiId": 105260131,
                "name": "第13問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
産業財産権?工業所有権?の存続期間に関する下記の設問に答えよ。  
（設問1）?  
次のａ~ｄの各権利とその存続期間の組み合わせとして最も適切なものを下記  
の解答群から選べ。ただし、法改正に伴う経過措置、存続期間の延長及び更新に  
ついては考慮しないものとする。  
R権利S  
ａ意匠権ｂ実用新案権ｃ特許権ｄ商標権  
[解答群]  
  
ア  ａ：出願日から20年ｂ：出願日から10年  
ｃ：登録日から10年ｄ：登録日から10年  
  
イ  ａ：登録日から10年ｂ：出願日から6年  
ｃ：出願日から20年ｄ：出願日から10年  
  
ウ  ａ：登録日から15年ｂ：登録日から10年  
ｃ：登録日から20年ｄ：出願日から10年  
  
エ  ａ：登録日から20年ｂ：出願日から10年  
ｃ：出願日から20年ｄ：登録日から10年`
        },
        {
                "mondaiId": 105260132,
                "name": "第13問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
?  
産業財産権のうち、?存続期間の更新登録制度があるもの、?存続期間の延長  
登録制度があるものの組み合わせとして最も適切なものはどれか。  
  
ア  ?：意匠権?：特許権  
  
イ  ?：実用新案権?：意匠権  
  
ウ  ?：商標権?：特許権  
  
エ  ?：特許権?：意匠権  
        
`
        },
        {
                "mondaiId": 105260140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
契約の解除に関する記述として最も適切なものはどれか。  
  
ア  AからBが買い受けた土地をBからCが買い受けたところ、AがBの債務不  
履行を理由にAB間の売買契約を解除した場合、Aが背信的悪意者のような場  
合でない限り、Cは、登記を得なければAに対して自らの所有権を主張できな  
い。  
  
イ  自宅の工事を発注した施主が発注後に死亡した場合、施主の共同相続人は、請  
負契約で定められた工期を遅延したまま督促しても工事完了の見込みが立たない  
請負業者に対して、特約がない限り、法定相続分に応じて個別に解除権を行使で  
きる。  
  
ウ  商人間の売買でなくても、債務者に履行の意思がないことが明らかであれば、  
履行遅滞を理由とする債務不履行解除には催告を必要としない。  
  
エ  特定物の売買契約における売主のための保証人は、反対の特約がない限り、契  
約解除による売買代金の返還義務についての保証の責任を負わない。  
        
`
        },
        {
                "mondaiId": 105260151,
                "name": "第15問設問1",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の英文契約書の秘密保持条項を読んで、下記の設問に答えよ。  
ArticleXX“Confidentiality”  
A.RecipientshallmaintaininconfidenceandsafeguardallConfidential  
InformationwhichisdisclosedbyDisclosertoRecipientinconnectionwiththis  
Agreementandwhichisdesignatedconfidentialatthetimeofdisclosure.  
B.TheConfidentialInformationmayinclude，butisnotlimitedto，alltechnical，  
financial，marketing，staffingandbusinessinformation.  
C.ThetermConfidentialInformationshallnotincludeinformationwhich:  
a)WasorbecomespubliclyavailablewithoutthebreachofthisAgreementby  
Recipient;  
b)WasinRecipient?spossessionfreeofanyobligationofconfidenceatthetimeof  
Discloser?scommunicationthereoftoRecipient;  
c)IsdevelopedbyRecipientindependentlyofandwithoutreferencetoanyof  
Discloser?sConfidentialInformationorotherinformationthatDiscloser  
disclosedinconfidencetoanythirdparty;  
d)IsrightfullyobtainedbyRecipientfromthirdpartiesauthorizedtomakesuch  
disclosurewithoutrestriction;or  
e)IsidentifiedbyDiscloserasnolongerXorconfidential.  
D.Provided，however，thatnothingcontainedhereinshallpreventtheRecipient  
fromdisclosinganyofsuchConfidentialInformationtotheextentthatsuch  
ConfidentialInformationisrequiredtobedisclosedbylaworforthepurposeof  
complyingwithgovernmentalregulations.  
E.TheYthefactssetforthinsub-paragraphsCa)throughe)，and  
ParagraphDshallrestwiththeRecipient.  
        
（設問1）?  
この秘密保持条項の規定の内容として、<u>最も不適切なものはどれか</u>。  
  
ア  受領者の本契約違反によらずに公知となった情報は、秘密情報に該当しな  
い。  
  
イ  秘密情報を利用せずに独自に受領者が開発した同一の情報は、秘密情報に該  
当するが、開示しても秘密保持義務違反にならない。  
  
ウ  法令又は政府の命令を遵守するために開示を要求される秘密情報は、秘密情  
報に該当するが、開示しても秘密保持義務違反にならない。  
  
エ  本条の秘密情報の要件として、開示時に秘密指定を行う必要があるが、その  
秘密指定が特定の限定された方法によるものであることは必要でない。`
        },
        {
                "mondaiId": 105260152,
                "name": "第15問設問2",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
?  
文中の空欄Xには｢機密の｣、空欄Yには｢立証責任｣を意味す  
る語句が入る。各空欄に入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ｘ：backroomＹ：evidence-basedfindingof  
  
イ  Ｘ：exchange-valuableＹ：lenderliabilityof  
  
ウ  Ｘ：expensiveＹ：preponderanceofevidenceof  
  
エ  Ｘ：proprietaryＹ：burdenofproving  
        
`
        },
        {
                "mondaiId": 105260161,
                "name": "第16問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士であるあなたと顧客である会社役員A氏との以下の会話を読ん  
で、下記の設問に答えよ。  
Ａ氏：｢うちの製品は国内での販売は頭打ちなのだが、なぜかB国からの注文は  
結構あってね。｣  
あなた：｢B国は今まさに工業化が進み、御社の製品はまだまだ必要になりますか  
らね。｣  
Ａ氏：｢それで今までは日本から製品を直接輸出していたのだが、B国に進出し  
情報収集や営業のためにうちの若手を送り込みたいと思っているのだよ。｣  
あなた：｢まずは駐在員事務所や支店の設立など、B国への進出形態による法制度  
を確かめないといけませんね。｣  
Ａ氏：｢それと税制も気になるんだよ。うちがB国に支店を作りそこから売上を  
計上した場合、税金は今と比べてどうなるのかな。｣  
あなた：｢B国の税制は調べないとわかりませんね。しかし逆に考えて、例えば外  
国法人が日本で支店を設置し、御社と同じような製品を本国から輸入して  
日本の顧客に販売して利益を得た場合、どうなると思いますか？｣  
Ａ氏：｢日本で稼いだ利益には日本で税金をとってもらわないと不公平だな。｣  
あなた：｢そうなのです。もう少し詳しくいうと、外国法人が日本国内に支店を持  
っている場合は、日本で行った事業からの所得に対して課税が行われるの  
です。支店のような事業を行う一定の場所のことをと呼びま  
す。Aさんもお聞きになったことがあると思います。｣  
Ａ氏：｢ね。どこかで聞いたことがあるな。つまり、うちも支店を出  
せばとされてB国で課税されることになるのかな。｣  
あなた：｢そうなる可能性が高いですね。さらに厄介なのは、実は日本の税制では、  
海外支店の所得も日本国内の支店の所得も合算して課税されるのです。つ  
まり、日本で設立された法人は、その法人の世界中の所得を課税の対象と  
する制度をとっているのです。｣  
Ａ氏：｢えっ。それじゃ二重に税金がかかってしまうじゃないか。｣  
        
        
あなた：｢そういう問題を解決するために、国家間で租税条約を締結して二重課税  
を回避する仕組みを構築しているのです。ただ、これはすべての国と締結  
されているわけではなく、内容もかなり複雑なので具体的には専門家に相  
談することをお勧めします。｣  
（設問1）?  
会話の中の空欄に入る語句として最も適切なものはどれか。  
  
ア  PA?PublicAddress?  
  
イ  PD?ProjectDevelopment?  
  
ウ  PE?PermanentEstablishment?  
  
エ  PR?PublicRelations?`
        },
        {
                "mondaiId": 105260162,
                "name": "第16問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
?  
会話の中の下線部の租税条約の特徴に関する記述として最も適切なものはどれ  
か。  
  
ア  租税条約は国際間の課税実務を調整するため世界各国で共通である。また、  
国内法と租税条約が異なる場合の適用は国内法が優先する。  
  
イ  租税条約は国際間の課税実務を調整するため世界各国で共通である。また、  
国内法と租税条約が異なる場合の適用は租税条約が優先する。  
  
ウ  租税条約は国家間で締結するので複数の租税条約が存在し、その規定は租税  
条約ごとに異なる。また、国内法と租税条約が異なる場合の適用は国内法が優  
先する。  
  
エ  租税条約は国家間で締結するので複数の租税条約が存在し、その規定は租税  
条約ごとに異なる。また、国内法と租税条約が異なる場合の適用は租税条約が  
優先する。  
        
`
        },
        {
                "mondaiId": 105260170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　合同会社の特徴に関する記述として最も適切なものはどれか。  
  
ア  合同会社では、会社法で規定する機関として<hintTarget>社員総会と代表社員</hintTarget>の設置が必要であり、日常業務は代表社員が担い、重要な意思決定は社員総会の決議による。  
  
<hint hidden='true'>**
❌いずれも必要ではありません。
**</hint>

イ  合同会社では、定款に記載することによって、出資額が少ない社員に対する損益分配の割合を増やすように定めることができる。  
  
<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ  合同会社では、定款で業務執行社員を定めて一部の社員を業務執行から除外することができる。逆に<hintTarget>社員ではない</hintTarget>が経営能力に優れた人を<hintTarget>業務執行社員</hintTarget>とすることもできる。  
  
<hint hidden='true'>**
❌合同会社では、社員でない人を業務執行役員にはできません。なお、株式会社であれば、社員でなくとも業務執行社員となれます（例：社外取締役など）。
**</hint>

エ  合同会社には<hintTarget>資本金</hintTarget>の概念が<hintTarget>ない</hintTarget>。このため会社設立にあたって必要な設立登記のための登録免許税を納付する必要がない。

<hint hidden='true'>**
❌資本金の概念はあります。したがって登録免許税の納付も必要です。
**</hint>

<hintTarget></hintTarget>


`
        },
        {
                "mondaiId": 105260180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2014,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
会社分割(吸収分割を前提とする)と事業譲渡の相違に関する記述として最も適切  
なものはどれか。  
  
ア  会社分割では吸収分割契約の内容を記録した書面又は電磁的記録を本店に備え  
置かなければならないが、事業譲渡ではこのような制度はない。  
  
イ  会社分割では適法に債権者保護手続を経ることで対象事業の債務を移転させる  
ことができるが、事業譲渡では個々の債権者から同意を得ずに債務を移転させる  
ことができる。  
  
ウ  会社分割では分割会社が取得している許認可は承継することができないが、事  
業譲渡ではそれを承継することができる。  
  
エ  会社分割では分割承継資産の対価として承継会社の株式を発行しなければなら  
ないが、事業譲渡の対価は金銭に限られる。  
`
        },
        {
                "mondaiId": 106260010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータは、データ記録やオペレーティングシステム(OS)の起動ディスク  
としてハードディスクドライブ(HDD)やソリッドステートドライブ(SSD)を装備  
している。HDDやSSDには様々な種類の製品があり、データ記録のための管理方  
法も複数の種類がある。  
HDDやSSDにおけるFATやNTFSなどによるデータ記録管理方法に関する記  
述として、最も適切なものの組み合わせを下記の解答群から選べ。  
ａ記憶装置上のデータ記録位置を、セクタとそれをまとめたクラスタで管理す  
る。  
ｂファイルはクラスタ容量の単位で分割され、記憶装置上に書き込まれる。その  
物理的な記録位置はパス名で記録される。  
ｃ\fファイルを記録する連続したクラスタがない場合、まず記録位置の再配置を  
行い連続性を確保して記録する。  
ｄ\fファイルの記録場所をひとつ以上のクラスタの接続情報として管理する。  
[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
        
`
        },
        {
                "mondaiId": 106260020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータには様々な装置を取り付けるための入出力インタフェースが用意されており、業務に応じて入出力装置や外部記憶装置などを接続するのに利用される。このような入出力インタフェースに関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切なものを下記の解答群から選べ。  

コンピュータの入出力インタフェースには、データを1ビットずつ転送する（　Ａ　<hint hidden='true'>**
シリアル
**</hint>  ）インタフェースと、複数ビットを同時に転送する（　Ｂ　<hint hidden='true'>**
パラレル
**</hint>）インタフェースがある。  

これらのインタフェースを実装する規格のうち（　Ｃ　<hint hidden='true'>**
USB、SATA
**</hint>）は（　Ａ　<hint hidden='true'>**
シリアル
**</hint>）インタフェース、（　Ｄ　<hint hidden='true'>**
IDE、SCSI
**</hint>）は（　Ｂ　<hint hidden='true'>**
パラレル
**</hint>）インタフェースである。  

転送速度が速いのは（　Ａ　<hint hidden='true'>**
シリアル
**</hint>）インタフェースで、最近の多くの入出力装置や外部記憶装置で採用されている。  

[解答群]  
  
ア  Ａ：シリアルＢ：パラレルＣ：PCI、MIDI  
Ｄ：セントロニクス、IEEE1394  
  
イ  Ａ：シリアルＢ：パラレルＣ：USB、SATA  
Ｄ：IDE、SCSI<hint hidden='true'>**
←⭕
**</hint>    
  
ウ  Ａ：パラレルＢ：シリアルＣ：e-SATA、IEEE1394  
Ｄ：IDE、セントロニクス  
  
エ  Ａ：パラレルＢ：シリアルＣ：USB、MIDI  
Ｄ：PCI、SCSI  
        
`
        },
        {
                "mondaiId": 106260030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
パーソナルコンピュータのOSには複数の利用者が使用することを考慮して、複  
数のユーザアカウントを作成可能なものがあり、ユーザごとに管理者が設定可能な  
管理項目が設けられている。  
このような管理項目に関する記述として最も適切なものはどれか。  
  
ア  主記憶装置上の利用可能な記憶領域の範囲をユーザごとに割り当て、使用でき  
る記憶容量を制限することができる。  
  
イ  特定の発信者からのみe-mailを受け取ることができるようユーザごとに設定  
できる。  
  
ウ  ネットワーク利用環境で使用する新規ユーザの登録には、ユーザ名、パスワー  
ド、利用対象とするコンピュータ名を設定する。  
  
エ  ファイルシステムに存在する各種ファイルの参照や実行、作成や削除の権限を  
ユーザごとに設定できる。  
        
`
        },
        {
                "mondaiId": 106260040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
様々なコンピュータの発達やインターネットの普及にともなって、Webにかか  
わる技術は急速に進展している。Webにかかわるソフトウェア開発に関する以下  
の①~④の記述と、それらに対応する言語の組み合わせとして、最も適切なものを  
下記の解答群から選べ。  
①電子商取引サイトやWebサービスなどのシステム構築に用いられる。  
②データベースと連携するWebページを作成するのに利用される。  
③コンピュータ間で柔軟かつ自動的に変換される文書の標準化などのために用い  
られる。  
④スマートフォン向けブラウザに対応したホームページの記述によく利用され  
る。  
[解答群]  
  
ア  ①：DHTML②：PHP③：Ruby④：HTML5  
  
イ  ①：DHTML②：XSL③：XHTML④：PHP  
  
ウ  ①：Java②：Perl③：XML④：XBRL  
  
エ  ①：Java②：PHP③：XML④：HTML5  
        
`
        },
        {
                "mondaiId": 106260050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータでデータ処理を行うために様々なアルゴリズムが提供されている。  
各種業務に必要な処理内容に照らして適切なものを選択できるように、それらの特  
性を把握しておく必要がある。  
  
ア  ルゴリズムの特性や処理手順に関する以下の文章の空欄Ａ~Ｄに入る語句の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  
次に示す  
つの並べ替え手順のうち、データ交換や比較の回数が多く並べ替えの  
実行時間が大きいのはＡの方法である。  
①最も小さい(大きい)値を見つけて先頭のデータと交換し、以下残りのデータ  
に対して同様の手順を適用する方法。  
②先頭から順に隣り合うデータを比較して、順序が逆ならば交換する作業を最  
終データまで行い、最も小さい(大きい)値を末端に移動させ、以下同様の手順  
を繰り返す方法。  
データ探索において、求めるデータが中央のデータより前にあるか後ろにあるか  
の判定を、範囲を狭めながら繰り返し行うＢではＣを用意する必  
要がある。  
また、ハッシュ法ではハッシュ値にＤを組み込む必要がある。  
[解答群]  
  
ア  Ａ：①Ｂ：線形探索法Ｃ：規則性がないデータ  
Ｄ：衝突が生じた際の処理  
  
イ  Ａ：①Ｂ：二分探索法Ｃ：規則性がないデータ  
Ｄ：誤差が含まれた際の処理  
  
ウ  Ａ：②Ｂ：線形探索法Ｃ：順序よく並べたデータ  
Ｄ：誤差が含まれた際の処理  
  
エ  Ａ：②Ｂ：二分探索法Ｃ：順序よく並べたデータ  
Ｄ：衝突が生じた際の処理  
        
`
        },
        {
                "mondaiId": 106260060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務においてデータ変換や通信のための処理、あるいは、データを記憶装置に保  
存する処理過程で、圧縮と呼ばれる操作を行う場合がある。以下に示す処理過程の  
中で、圧縮操作として最も適切なものはどれか。  
  
ア  音のアナログデータから一定時間間隔ごとにデータを取り出し、有限精度の数  
値に変換する過程。  
  
イ  漢字をデータ化する際、偏(へん)や旁(つくり)を分類してコード化する過程。  
  
ウ  通信のための伝送路を有効利用するために、複数のデータ信号を重ね、同時に  
伝送する過程。  
  
エ  デジタル化した画像データを記憶する際、同一データが連続するものを省略す  
る過程。  
        
`
        },
        {
                "mondaiId": 106260070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータシステムの運用に際して障害が発生した場合に備えて、迅速に復旧  
できるようシステムの冗長化や多重化を行う必要がある。システムの冗長化や多重  
化に関する以下の文章の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切なも  
のを下記の解答群から選べ。  
費用はかかるが、同一構成のシステムを  
つ用意し、通常は並行して同じ処理  
を行わせ、障害が発生した場合に一方のシステムのみで処理を続行できるのが  
Ａである。  
また、  
つのシステムを用意するが、一方は主系として十分な能力のシステム  
を用意し、他方は従系として用意するＢもある。この方法は、比較的少  
ない費用で障害に備えることができる。このとき、従系のシステムを普段は電源  
を入れずに待機させ、障害発生時に電源を入れて利用する方式をＣ、常  
に電源を入れてプログラムを動作可能な状態で待機させる方式をＤとい  
う。  
[解答群]  
  
ア  Ａ：デュアルシステムＢ：デュプレックスシステム  
Ｃ：ウォームスタンバイＤ：ホットスタンバイ  
  
イ  Ａ：デュアルシステムＢ：デュプレックスシステム  
Ｃ：コールドスタンバイＤ：ホットスタンバイ  
  
ウ  Ａ：デュアルシステムＢ：ロードシェアシステム  
Ｃ：ホットスタンバイＤ：ウォームスタンバイ  
  
エ  Ａ：デュプレックスシステムＢ：デュアルシステム  
Ｃ：コールドスタンバイＤ：ウォームスタンバイ  
        
`
        },
        {
                "mondaiId": 106260080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年のコンピュータは、多様なマルチメディアデータを取り扱うことができるよ  
うになり、データ形式も増加している。  
コンピュータ内の補助記憶装置内のあるディレクトリを、ファイル管理ツールで  
見ると、以下の①~④の拡張子のついたファイルがあった。これらの拡張子とファ  
  
イ  ルの種類の組み合わせとして、最も適切なものを下記の解答群から選べ。  
①png  
②csv  
③mp4  
④htm  
[解答群]  
  
ア  ①：静止画ファイル②：テキストファイル  
③：静止画ファイル④：音楽ファイル  
  
イ  ①：静止画ファイル②：テキストファイル  
③：動画ファイル④：テキストファイル  
  
ウ  ①：動画ファイル②：音楽ファイル  
③：静止画ファイル④：テキストファイル  
  
エ  ①：動画ファイル②：静止画ファイル  
③：テキストファイル④：音楽ファイル  
        
`
        },
        {
                "mondaiId": 106260090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
様々な業務において利用されるリレーショナルデータベースでは、各種の処理要  
求がSQL言語によって指示される。SQL言語の要素は以下の①~④のように区分  
できる。これら区分とSQL言語の要素の組み合わせとして最も適切なものを下記  
の解答群から選べ。  
①データ定義言語  
②データ操作言語  
③演算子  
④関数  
[解答群]  
  
ア  ①：CREATE②：INSERT③：UPDATE④：UNION  
  
イ  ①：CREATE②：SELECT③：LIKE④：COUNT  
  
ウ  ①：DELETE②：CREATE③：BETWEEN④：AVG  
  
エ  ①：SELECT②：DROP③：INSERT④：ALL  
        
`
        },
        {
                "mondaiId": 106260100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
LANを敷設した事業所内では、コンピュータ端末やアプリケーションサーバか  
ら、各種サーバやLAN対応機器に様々なプロトコルに基づいた指令が送られる。  
以下の①~④に示すサーバやLAN対応機器と、それらを利用するプロトコルの  
組み合わせとして最も適切なものを下記の解答群から選べ。  
①データベースサーバ  
②ファイルサーバ  
③LAN対応プリンタ  
④メールサーバ  
[解答群]  
  
ア  ①：API②：DNS③：RPC④：SMTP  
  
イ  ①：JDBC②：NNTP③：RAW④：FTP  
  
ウ  ①：ODBC②：NFS③：LPR④：POP  
  
エ  ①：RPC②：FTP③：IPP④：SNMP  
        
`
        },
        {
                "mondaiId": 106260110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報ネットワークの構築において、通信技術や通信プロトコルは重要な役割を演  
じる。それらに関する記述として、最も適切なものはどれか。  
  
ア  CSMA/CD方式で通信を行う場合、複数の発信元が同時に情報を送信してパ  
ケット衝突が発生すると、それ以降、それらの発信元は情報を発信できなくな  
る。  
  
イ  TCP/IPは、MACアドレスと呼ばれる情報機器固有の番号を用いて通信する  
方式である。  
  
ウ  電話回線によるシリアル通信で使われていたプロトコルを発展させたものが、  
インターネットのプロトコルである。  
  
エ  トークンリングは、トークンと呼ばれる信号を高速で周回させ、それを利用し  
て通信を行う方式である。  
        
`
        },
        {
                "mondaiId": 106260120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
コンピュータを会社内のLANに接続し、インターネットを利用する場合、LAN  
接続に必要な項目の設定作業を行わなければならない。社内LANにおいてDHCP  
サーバが稼働している場合は、LAN接続に必要な設定項目をこのサーバから受け  
取り自動的に完了させることもできる。  
DHCPサーバが稼働しているか否かにかかわらず、LANに接続するのに設定が  
必要な項目を列挙したものとして最も適切なものはどれか。  
  
ア  MACアドレスDNSサーバのIPアドレス  
プロキシサーバのIPアドレスサブネットマスク  
  
イ  MACアドレスポート番号  
ルータのIPアドレスコンピュータ名  
  
ウ  当該コンピュータのIPアドレスDNSサーバのIPアドレス  
デフォルトゲートウェイのIPアドレスサブネットマスク  
  
エ  当該コンピュータのIPアドレスポート番号  
コンピュータ名SSID  
        
`
        },
        {
                "mondaiId": 106260130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業経営において情報システム(IS)の有用性は益々高まりつつある。政府も高度  
情報通信ネットワーク社会推進戦略本部を設け、様々な政策の検討に入るととも  
に、｢政府CIOポータル｣サイトを設置して、わが国が世界最高水準のIT利活用  
社会になることを目指している。  
このポータルサイトでは、一般的にCIOは｢���織におけるイノベータであり、マ  
ーケティングから組織内外の情報流通まで含む知識基盤を支える重要な役割｣を担  
うものとしている。  
このポータルサイト内でCIOに必要となる知識体系とされているものに関する  
記述として、最も適切なものはどれか。  
  
ア  IS戦略・ITガバナンスにかかわる知識とは、IT投資管理、組織・人材育成、  
IT技術変革潮流、ITリスク管理にかかわる知識である。  
  
イ  業務・プロセス改革にかかわる知識とは、ISの個別プロジェクトの目的の達  
成に求められる計画の立案・実行にかかわる知識である。  
  
ウ  経営戦略にかかわる知識とは、ISの適切な調達を行うために求められる調達  
戦略立案、外部委託先管理等にかかわる知識である。  
  
エ  情報活用戦略にかかわる知識とは、企業に蓄積された情報や情報技術を活用し  
たビジネスモデルの変革を担うために必要となる知識である。  
        
`
        },
        {
                "mondaiId": 106260140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
法務省の電子認証登記所が発行する、オンライン申請に際して必要な電子証明書  
に関する説明として、最も適切なものはどれか。  
  
ア  電子証明書の証明期間は\u0005か月から36か月まで任意に選択できる。  
  
イ  電子証明書発行の申請地にかかわらず、東京法務局が電子認証登記所になる。  
  
ウ  登記官の自己署名証明書ハッシュ値はMD5で計算されている。  
  
エ  登記官の電子署名は\u0005年ごとに更新される。`
        },
        {
                "mondaiId": 106260150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年注目されているシステム開発手法に関する記述として、最も適切なものはど  
れか。  
  
ア  エクストリームプログラミングは、システムテストを省くなどしてウォーター  
フォール型システム開発を改善した手法である。  
  
イ  エンベデッドシステムは、あらかじめインストールしておいたアプリケーショ  
ンを有効に利用してシステム開発を行う手法である。  
  
ウ  オープンデータは、開発前にシステム構想およびデータをユーザに示し、ユー  
ザからのアイデアを取り入れながらシステム開発を行う手法である。  
  
エ  スクラムは、開発途中でユーザの要求が変化することに対処しやすいアジャイ  
ルソフトウェア開発のひとつの手法である。  
        
`
        },
        {
                "mondaiId": 106260160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
システム開発プロジェクトにおいて｢経営層によるプロジェクト運営への関与が  
十分でない｣ことが原因で失敗するリスクがあるとき、いくつかの対策が考えられ  
る。  
対策は回避策と軽減策に分けられ、回避策は、それを十分に行った場合にリスク  
事象ドライバー※を消滅させる措置である。他方、軽減策は、リスク事象ドライバ  
ーを消滅させることはないが、リスク事象の発生確率を減少させる措置である。  
経営層からのプロジェクトおよびプロジェクトメンバーへの明確な直接的メッセ  
ージあるいは経営者のプロジェクトへの参画があれば回避策になり、そうでなけれ  
ば軽減策になる。  
軽減策にあたるものとして最も適切なものはどれか。  
※ドライバー：誘発する要因  
  
ア  関連組織の参画について経営者の指示を文書化する。  
  
イ  経営層への定期的あるいはフェーズごとの報告の必要性について経営層の了解  
を得る。  
  
ウ  重要なタイミングにおける経営者の参加の重要性を説明し経営層の了承を得  
る。  
  
エ  プロジェクト要件の優先順位に関する経営層の考え方を文書化する。  
        
`
        },
        {
                "mondaiId": 106260170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図のＡ~Ｄは、システム分析もしくはシステム設計に使われる図である。図と  
その名称の組み合わせとして最も適切なものを下記の解答群から選べ。  
顧客  
宿泊予約管理  
システム  
管理者  
宿泊予約システム  
宿泊予約する  
宿泊予約を変更  
宿泊予約取消  
予約状況確認  
顧客情報管理  
図Ａ  
新着メールなし  
メール確認  
する  
内容確認  
して削除  
新着メールあり  
返事を書く  
図Ｂ  
        
        
図Ｃ  
生産注文  
生産計画  
独立需要充足  
資材計画  
従属需要充足  
従属需要独立需要  
製造活動  
計　画  
製造注文  
製造実績  
外注実績外注注文資材在庫購買実績購買注文  
仕掛在庫  
生産実績  
品目定義製品在庫資源設備  
図Ｄ  
ユーザ情報登録  
データベース登録  
データベース  
メールユーザユーザ登録画面  
ユーザ登録  
登録結果  
登録結果表示  
ユーザ情報入力  
登録結果  
[解答群]  
  
ア  図Ａ：アクティビティ図図Ｂ：ステートチャート図  
図Ｃ：DFD図Ｄ：ユースケース図  
  
イ  図Ａ：コミュニケーション図図Ｂ：アクティビティ図  
図Ｃ：オブジェクト図図Ｄ：配置図  
  
ウ  図Ａ：ユースケース図図Ｂ：DFD  
図Ｃ：アクティビティ図図Ｄ：コミュニケーション図  
  
エ  図Ａ：ユースケース図図Ｂ：アクティビティ図  
図Ｃ：DFD図Ｄ：コミュニケーション図  
        
`
        },
        {
                "mondaiId": 106260180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業がベンダにシステム開発を委託する場合、中小企業診断士には両者の橋  
渡しを期待される場合がある。このとき、中小企業とベンダのコミュニケーション  
手段となるシステム仕様書には、構造、機能、振舞の\u0005側面を書かなければならな  
い。それぞれに書く内容の組み合わせとして最も適切なものはどれか。  
  
ア  構造：データ(属性)型機能：入出力振舞：並列性  
  
イ  構造：データ(属性)型機能：並列性振舞：入出力  
  
ウ  構造：並列性機能：データ(属性)型振舞：入出力  
  
エ  構造：並列性機能：入出力振舞：データ(属性)型`
        },
        {
                "mondaiId": 106260190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
携帯端末の普及に伴い、個人所有の端末を社内に持ち込み仕事に利用する  
BYODが注目を集めている。特に、IT投資の削減や情報共有の効率化が図られる  
ことなどから、BYODに対する期待は大きい。BYODに関する記述として最も適  
切なものはどれか。  
  
ア  BYODを導入するとともに、自社サーバの機能をクラウドサービスに移行す  
れば、BCP対策の一環となる。  
  
イ  MDMとは、持ち込まれる端末のデータベース管理システムを統一することを  
指す。  
  
ウ  シャドーITとは、会社所有の情報機器と同じハード、ソフトからなる端末に  
限定して持ち込みを許可することを指す。  
  
エ  端末を紛失した場合などに対処するため、遠隔操作でデータを消去するローカ  
ルワイプと呼ばれる機能がある。  
        
`
        },
        {
                "mondaiId": 106260200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ITプロジェクト成功のひとつの鍵は、適切なビジネスアナリシスである。これ  
に必要な知識とスキルの標準として、IIBA(InternationalInstituteofBusiness  
Analysis)が2009年に発表したBABOK(AGuidetotheBusinessAnalysisBodyof  
Knowledge)2.0がある。これに関する記述として、最も適切なものはどれか。  
  
ア  BABOKでは、新しいソリューションを実現するための要求を、ビジネス要  
求、ステークホルダー要求、ソリューション要求、そして移行要求の2つに分類  
している。  
  
イ  BABOKのソリューション要求に含まれる機能要求とは、ソリューションが有  
効に存続するための環境条件や、システムが備えているべき品質のことである。  
  
ウ  BABOKの知識エリアは、それぞれビジネスアナリシスの実行フェーズに対応  
している。  
  
エ  BABOKは、ビジネスアナリシスのタスクを実行するプロセスやタスクの実行  
順序について規定している。  
        
`
        },
        {
                "mondaiId": 106260210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットが普及した現在においては、関係者以外に知られてはならないよ  
うな情報を、インターネットを介してやり取りしなければならない状況も多い。そ  
のような状況下では暗号化の技術が重要になる。  
大阪のAさんが、東京にいるBさんに顧客名簿を送ってもらうように依頼した。  
その場合に利用する暗号化方式に関する記述として最も適切なものはどれか。  
  
ア  Bさんは、顧客名簿のファイルを、暗号化鍵を管理する社内部署から鍵をひと  
つもらって暗号化した。Aさんに送付後、その鍵で暗号化したことを鍵管理部  
署に連絡した。Aさんは、その部署からBさんが使った鍵を聞き、送られたフ  
ァイルを復号化した。この方式はSSL方式のひとつである。  
  
イ  Bさんは、顧客名簿のファイルをAさんとBさんが共有する秘密鍵で暗号化  
してAさんに送付した。この方式はシーザー暗号方式のひとつである。  
  
ウ  Bさんは、顧客名簿のファイルをAさんの公開鍵で暗号化して送付した。A  
さんは、Bさんの秘密鍵で復号化した。この方式は公開鍵方式のひとつである。  
  
エ  Bさんは、顧客名簿のファイルを任意に決めた鍵で暗号化してAさんに送付  
した。AさんはBさんから電話でその鍵を聞き、復号化した。この方式は共通  
鍵方式のひとつである。  
        
`
        },
        {
                "mondaiId": 106260220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
事業継続のためには、各種の脅威にさらされた際の情報システムの回復力を高め  
ておくことが必要である。独立行政法人情報処理推進機構が2012年に公開した｢事  
業継続のための高回復力システム基盤導入ガイド｣では、様々な脅威を前提に2つ  
のモデルシステムを提示している。それらすべてに共通する記述として最も適切な  
ものはどれか。  
  
ア  機器などの冗長化を要求している。  
  
イ  震度\u000f弱までの地震に耐えられる建物・設備を要件としている。  
  
ウ  大規模災害によるシステム障害や不正アクセスなどのセキュリティ事故を対象  
としている。  
  
エ  バックアップサイトの保有を要件としている。`
        },
        {
                "mondaiId": 106260230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
顧客の信頼を高めることを目指して、ITサービスを提供するある中小企業が、  
一般財団法人日本情報経済社会推進協会(JIPDEC)のITSMS(ITサービスマネジメ  
ントシステム)認証の取得を検討している。この認証取得のためのITSMS適合性  
評価制度に関する記述として最も適切なものはどれか。  
  
ア  JIPDECは受審申請を受け付け、審査する認証機関を割り当てる。  
  
イ  管理すべき資料のうち、紙の書類は文書、紙以外の媒体を使うものは記録と区  
別されている。  
  
ウ  審査は初回審査と再認証審査の二種類である。  
  
エ  組織の一部であっても、あるいは複数の組織にまたがっていても、サービス単  
位で受審できる。  
        
`
        },
        {
                "mondaiId": 106260240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある電機メーカーが自社製品のシェアを調べようとしている。前回調査ではシェ  
  
ア  が10％であったので、今回の調査でもその程度と予想している。信頼係数95  
％で誤差を3％以内としたい。この推定をするのに必要な標本数nの計算式とし  
て、最も適切なものはどれか。  
  
ア  n暗1.96  
0.102  
庵0.03庵0.97  
  
イ  n暗1.96  
0.032  
庵0.10庵0.90  
  
ウ  n暗2.58  
0.952  
庵0.10庵0.90  
  
エ  n暗2.58  
0.102  
庵0.03庵0.97`
        },
        {
                "mondaiId": 106260250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2014,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある店舗の日次売上高の変動を調べるために、その日次売上高データを\fか月分  
抽出した。まず、日次売上高データの平均値と標準偏差を求めた。次に日次売上高  
データの平均値からの偏差を標準偏差で割ったものの二乗和を求めた。この値は、  
ある確率分布に従う。これに関する記述として最も適切なものはどれか。  
  
ア  この確率分布の分布表には片側有意水準のみ示されている。  
  
イ  この確率分布の分布表を用いて、日次売上高を推定できる。  
  
ウ  この確率分布は正規分布である。  
  
エ  自由度が変わってもこの分布の形状は変わらない。  
`
        },
        {
                "mondaiId": 107260011,
                "name": "第1問設問1",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
製造業、商業・サービス業¶小売業、卸売業、各サービス業の総称‰について、財  
務省｢法人企業統計年報｣に基づき、企業規模別の財務状況の中長期的な変化¶1983  
年から2011年までの期間‰を見ると、いくつかの特徴を指摘することができる。  
製造業ではＡ、Ｂが徐々に自己資本比率を高めている一方で、  
Ｃの自己資本比率は低い水準でとどまっている。このような規模間格差  
は、商業・サービス業でも製造業に比べると小さいものの同様の傾向にある。  
また、ＡやＢでは固定比率が徐々に低下しているが、  
Ｃでは高い水準で推移しており、特に製造業での格差は大きくなっている。  
（設問1）‰  
文中の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものはどれか。  
なお、ここでの企業区分は中小企業基本法の定義に準ずるものとし、中規模企  
業とは小規模企業以外の中小企業を指す。  
  
ア  Ａ：小規模企業Ｂ：中規模企業Ｃ：大企業  
  
イ  Ａ：小規模企業Ｂ：大企業Ｃ：中規模企業  
  
ウ  Ａ：中規模企業Ｂ：小規模企業Ｃ：大企業  
  
エ  Ａ：大企業Ｂ：小規模企業Ｃ：中規模企業  
  
オ  Ａ：大企業Ｂ：中規模企業Ｃ：小規模企業  
        
`
        },
        {
                "mondaiId": 107260012,
                "name": "第1問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の下線部について、自己資本比率の改善を図る方策として、最も適切なも  
のはどれか。  
  
ア  企業間信用を拡大すること。  
  
イ  私募債を発行すること。  
  
ウ  短期借入金を長期借入金で借り換えること。  
  
エ  利益の内部留保を進めること。`
        },
        {
                "mondaiId": 107260020,
                "name": "第2問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
総務省｢平成21年経済センサス安基礎調査｣に基づき、産業別に中小企業数を比  
較した場合、多いものから少ないものへと並べた組み合わせとして、最も適切なも  
のを下記の解答群から選べ。  
ここで企業数は会社数と個人事業所の合計とする。  
ａ建設業  
ｂ製造業  
ｃ小売業  
[解答群]  
  
ア  ａ建設業安ｂ製造業安ｃ小売業  
  
イ  ａ建設業安ｃ小売業安ｂ製造業  
  
ウ  ｂ製造業安ａ建設業安ｃ小売業  
  
エ  ｂ製造業安ｃ小売業安ａ建設業  
  
オ  ｃ小売業安ａ建設業安ｂ製造業  
        
`
        },
        {
                "mondaiId": 107260031,
                "name": "第3問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
少子高齢化、人口減少が進展しているわが国経済が持続的成長を遂げるために  
は、高齢者や女性の労働市場への参入を促すとともに、企業の労働生産性を高めて  
いくことが重要である。財務省｢法人企業統計年報｣に基づき、企業規模別に労働生  
産性¶付加価値額／従業者数‰の推移を見ると、製造業、商業・サービス業¶小売業、  
卸売業、各サービス業の総称‰ともに、企業規模が大きいほど労働生産性は高い。  
M中小企業白書2013年版Qでは、このような規模間格差が生じる主な要因として、  
資本装備率の格差に加え、近年小規模企業のが低下傾向にあることを指  
摘している。  
（設問1）‰  
文中の下線部について、財務省｢法人企業統計年報｣に基づき、1983年と2011  
年について、規模別、製造業、商業・サービス業別に労働生産性¶中央値‰を見た  
場合、最も適切なものはどれか。  
なお、ここでの企業区分は中小企業基本法の定義に準ずるものとし、中規模企  
業とは小規模企業以外の中小企業を指す。  
  
ア  商業・サービス業のみ、小規模企業と中規模企業の労働生産性の格差が拡大  
している。  
  
イ  製造業、商業・サービス業ともに、小規模企業と中規模企業の労働生産性の  
格差が拡大している。  
  
ウ  製造業のみ、小規模企業と中規模企業の労働生産性の格差が拡大している。  
  
エ  製造業より商業・サービス業で、大企業と小規模企業の労働生産性の格差が  
拡大している。  
        
`
        },
        {
                "mondaiId": 107260032,
                "name": "第3問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の空欄に入る語句として、最も適切なものはどれか。  
  
ア  売上高経常利益率  
  
イ  資本生産性  
  
ウ  流動比率  
  
エ  労働分配率`
        },
        {
                "mondaiId": 107260041,
                "name": "第4問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業は地域経済における雇用の創出主体として重要な役割を担っている。総  
務省｢平成21年経済センサス安基礎調査｣によれば、全都道府県のうち  
①  
三大都市圏  
中心市の所在しない道県では、会社の常用雇用者数と個人事業所の従業者総数の約  
Ａ割を小規模企業が、Ｂ割強を中規模企業が雇用している。  
また、中小企業は女性の雇用でも重要な役割を担っている。総務省｢平成19年就  
業構造基本調査｣に基づき、従業者規模別の雇用者に占める  
②  
女性雇用者の割合を見  
た場合、従業者規模の小さな企業ほど女性雇用者の割合が高い。  
なお、ここで三大都市圏中心市が所在しない道県とは、埼玉県、千葉県、東京  
都、神奈川県、愛知県、京都府、大阪府、兵庫県以外の道県をいう。企業区分は中  
小企業基本法の定義に準ずるものとし、中規模企業とは小規模企業以外の中小企業  
を指す。  
        
（設問1）‰  
文中の下線部①について、総務省｢平成21年経済センサス安基礎調査｣に基づ  
き、都道府県別に会社の常用雇用者数と個人事業所の従業者総数に占める中小企  
業の割合を見た場合、高いものから低いものへと並べた組み合わせとして、最も  
適切なものを下記の解答群から選べ。  
ａ愛知県  
ｂ大阪府  
ｃ東京都  
[解答群]  
  
ア  ａ愛知県安ｂ大阪府安ｃ東京都  
  
イ  ａ愛知県安ｃ東京都安ｂ大阪府  
  
ウ  ｂ大阪府安ａ愛知県安ｃ東京都  
  
エ  ｂ大阪府安ｃ東京都安ａ愛知県  
  
オ  ｃ東京都安ａ愛知県安ｂ大阪府`
        },
        {
                "mondaiId": 107260042,
                "name": "第4問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：\u0005Ｂ：\u0005  
  
イ  Ａ：\u0005Ｂ：\u0007  
  
ウ  Ａ：\u000eＢ：\u000e  
  
エ  Ａ：\u0007Ｂ：\u0005  
        
`
        },
        {
                "mondaiId": 107260043,
                "name": "第4問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005‰  
文中の下線部②について、総務省｢平成19年就業構造基本調査｣に基づき、管  
理的職業従事者に占める女性の割合を従業者規模別¶\f?\u000e人、\u0007?19人、20  
?49人、50?99人、100?299人、300人以上‰に見た場合、最も適切なもの  
はどれか。  
なお、ここで管理的職業従事者とは、事業経営方針の決定・経営方針に基づく  
執行計画の樹立・作業の監督・統制等、経営体の全般または課¶課相当を含む‰以  
上の内部組織の経営・管理に従事する者をいう¶官公庁、その他法人・団体に勤  
めている者は含まない‰。  
  
ア  従業者300人以上の企業と従業者\f?\u000e人の企業では、女性の割合はほぼ同  
じである。  
  
イ  従業者300人以上の企業と従業者20?49人の企業では、女性の割合はほぼ  
同じである。  
  
ウ  従業者規模が大きな企業ほど、女性の割合が高い傾向にある。  
  
エ  従業者規模が小さな企業ほど、女性の割合が高い傾向にある。`
        },
        {
                "mondaiId": 107260050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
小売業の経営分析を行う際に用いられる効率性を示す主要指標のひとつに交差比  
率がある。交差比率の説明として、最も適切なものはどれか。  
  
ア  売上高総利益率と商品回転率を掛け合わせたもの。  
  
イ  売上高総利益率と総資産回転率を掛け合わせたもの。  
  
ウ  売上高を総売場面積で除したもの。  
  
エ  売上高を総従業員数で除したもの。  
        
`
        },
        {
                "mondaiId": 107260060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経済産業省｢商業統計表｣に基づき、1991年から2007年までの期間について、小  
売業の事業所数、従業者数、年間商品販売額の推移について見た場合、最も適切な  
ものはどれか。  
  
ア  事業所数は一貫して減少している。  
  
イ  従���者1人当たりの年間商品販売額は一貫して減少している。  
  
ウ  従業者数は一貫して減少している。  
  
エ  年間商品販売額は一貫して減少している。`
        },
        {
                "mondaiId": 107260070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の資金調達方法は、企業の外部から資金を調達する外部金融と、企業の内部  
資金を活用する内部金融に大別できる。外部金融として、最も不適切なものはどれ  
か。  
  
ア  買掛金  
  
イ  株式  
  
ウ  減価償却費  
  
エ  支払手形  
  
オ  社債  
        
`
        },
        {
                "mondaiId": 107260081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
グローバル競争が激化する中で、わが国製造業の競争力の低下が懸念されてい  
る。とりわけ近年では、新興国企業に対して圧倒的な優位性を有してきた技術力に  
ついても、研究開発投資の停滞、海外への技術流出、新規設備投資の減少に伴う  
①  
設  
備老朽化等を背景に、その優位性が揺らいでいる。  
技術の源泉である研究開発は民間企業が中心的な役割を担っている。わが国の企  
業部門の研究開発費の推移を見ると  
②  
対GDP比では高水準にある。しかしながら  
③  
企  
業部門の研究開発費の実額推移を見ると、中国、韓国が大幅に増加しているのに対  
して、わが国の研究開発費の伸び率は米国やドイツ等の主要国と比較しても低水準  
で推移している。経済産業省が行った今後\u0005年間の研究開発投資の見通しに関する  
調査¶2012年12月‰等を見ても、全体的に研究開発投資を据え置く企業が多く、引  
き続き研究開発の量的な停滞が懸念される状況である。  
        
（設問1）‰  
文中の下線部①について、内閣府｢民間企業資本ストック｣｢国富調査｣に基づ  
き、1990年から2012年までの期間について、わが国製造業の生産設備の平均年  
齢¶ビンテージ‰を業種別で見た場合、高いものから低いものへと並べた組み合わ  
せとして、最も適切なものを下記の解答群から選べ。  
ａ化学  
ｂ鉄鋼  
ｃ輸送機械  
[解答群]  
  
ア  ａ化学安ｂ鉄鋼安ｃ輸送機械  
  
イ  ａ化学安ｃ輸送機械安ｂ鉄鋼  
  
ウ  ｂ鉄鋼安ａ化学安ｃ輸送機械  
  
エ  ｂ鉄鋼安ｃ輸送機械安ａ化学  
  
オ  ｃ輸送機械安ａ化学安ｂ鉄鋼`
        },
        {
                "mondaiId": 107260082,
                "name": "第8問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の下線部②について、科学技術政策研究所¶現科学技術・学術政策研究所‰  
｢科学技術指標2012｣に基づき、1995年から2011年までの期間について、わが国  
の企業部門の研究開発費の対GDP比の推移を国際比較した場合、最も不適切な  
ものはどれか。  
  
ア  韓国を一貫して上回っている。  
  
イ  中国を一貫して上回っている。  
  
ウ  ドイツを一貫して上回っている。  
  
エ  米国を一貫して上回っている。  
        
`
        },
        {
                "mondaiId": 107260083,
                "name": "第8問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005‰  
文中の下線部③について、科学技術政策研究所¶現科学技術・学術政策研究所‰  
｢科学技術指標2012｣に基づき、2002年度から2010年度までの期間について、わ  
が国製造業の研究開発費の推移を見た場合、最も適切なものはどれか。  
  
ア  製造業全体に占める業種別の研究開発費の割合を直近時点で見た場合、医薬  
品製造業の割合が最も高い。  
  
イ  製造業全体に占める業種別の研究開発費の割合を直近時点で見た場合、輸送  
用機械製造業の割合が最も高い。  
  
ウ  製造業全体の研究開発費は2005年度以降減少に転じている。  
  
エ  製造業全体の研究開発費は2007年度以降増加に転じている。  
  
オ  製造業全体の研究開発費は20兆円前後で推移している。  
        
`
        },
        {
                "mondaiId": 107260091,
                "name": "第9問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
次の文章を読んで、下記の設問に答えよ。  
わが国経済の活性化を図るためには、欧米に比べて低迷傾向にある起業活動をよ  
り活発なものにしていくことが望まれている。しかしながら、  
①  
起業形態は多様であ  
り、起業家の属性、選択する事業分野、経営志向等もさまざまである。中小企業庁  
｢起業の実態に関する調査｣¶2012年11月‰を見ても、起業して間もない企業¶スタ  
ートアップ企業‰には成長志向の企業のみならず、事業の安定を優先したいとする  
企業が多数存在している。今後目指している市場も、同一市町村や同一都道府県と  
回答する企業から、海外と回答する企業までさまざまである。  
スタートアップ企業が持続、発展を遂げるためには、事業を進めるうえで直面す  
るさまざまな課題に柔軟に対応していく必要がある。  
②  
起業の準備段階から経営が安  
定するまでに生じる課題は、起業形態によっても異なり、成長・発展の段階に応じ  
て抱える課題や必要となる経営資源の内容も大きく変化していくためである。  
起業を支援する人材には、多様な起業形態を理解したうえで、起業の準備段階か  
ら経営が安定するまでの過程の中で、さまざまに変化する支援ニーズを的確にとら  
えて、細やかに対応していくことが求められている。  
        
（設問1）‰  
文中の下線部①について、中小企業庁｢起業の実態に関する調査｣¶2012年11  
月‰に基づき、起業の特徴を見た場合、<u>最も不適切なものはどれか</u>。  
なお、ここで｢地域需要創出型｣とは、上記調査において、事業経営方針として  
｢規模の拡大より、事業の安定継続を優先したい｣と回答し、かつ目指している今  
後の市場として｢同一市町村｣または｢同一都道府県｣と回答している企業を指す。  
｢グローバル成長型｣とは、事業経営方針として｢規模を拡大したい｣と回答し、か  
つ目指している今後の市場として｢全国｣または｢海外｣と回答している企業を指  
す。  
  
ア  起業家に占める女性の割合を見ると、｢地域需要創出型｣に占める女性起業家  
割合は｢グローバル成長型｣より高い。  
  
イ  起業時と現在の従業員数の伸び率を見ると、｢グローバル成長型｣が｢地域需  
要創出型｣を上回っている。  
  
ウ  起業時の起業家の平均年齢を見ると、｢地域需要創出型｣は｢グローバル成長  
型｣より高い。  
  
エ  主要市場を見ると、｢グローバル成長型｣では対個人消費者向けの割合が｢地  
域需要創出型｣を上回っている。  
        
`
        },
        {
                "mondaiId": 107260092,
                "name": "第9問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の下線部②について、中小企業庁｢起業の実態に関する調査｣¶2012年11  
月、複数回答‰に基づき、起業準備に着手してから本業の製品・商品・サービス  
による売上が計上されていない段階¶萌芽期‰における資金調達について見た場  
合、資金調達先として、回答企業割合が高いものから低いものへと並べた組み合  
わせとして、最も適切なものを下記の解答群から選べ。  
ａ家族・親族の出資・融資  
ｂ自己資金¶預貯金、副業収入等‰  
ｃ民間金融機関等の融資  
[解答群]  
  
ア  ａ家族・親族の出資・融資安ｂ自己資金¶預貯金、副業収入等‰安  
ｃ民間金融機関等の融資  
  
イ  ａ家族・親族の出資・融資安ｃ民間金融機関等の融資安  
ｂ自己資金¶預貯金、副業収入等‰  
  
ウ  ｂ自己資金¶預貯金、副業収入等‰安ａ家族・親族の出資・融資安  
ｃ民間金融機関等の融資  
  
エ  ｂ自己資金¶預貯金、副業収入等‰安ｃ民間金融機関等の融資安  
ａ家族・親族の出資・融資  
        
`
        },
        {
                "mondaiId": 107260101,
                "name": "第10問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
事業環境が構造的にも変化する中で、中小企業にとって強みである機動性・柔軟  
性等を活かして、既存事業とは異なる事業分野・業種に進出する  
①  
新事業展開の重要  
性が増している。  
新事業展開の効果として、収益の向上にとどまらず、企業の知名度や信用力の向  
上、従業員のモチベーションの向上といったさまざまな効果が期待できる一方で、  
経営資源が限られる中小企業にとっては、  
②  
新事業を実施する過程においてさまざま  
な課題も生じる。このような課題を克服するためには、中小企業同士が互いに連携  
し、不足している経営資源を相互補完する企業連携の取り組みも有効であると考えられる。  
（設問1）‰  
文中の下線部①について、経済産業省｢工業統計表｣に基づき、製造業事業所の  
従業者規模別の新事業展開実施事業所数の割合¶新事業展開割合‰を見た場合、最  
も適切なものはどれか。  
なお、ここで新事業展開は2000年と2010年との比較で見るものとし、事業転  
換とは新事業展開のうち主力事業が変わったもの、多角化とはこれ以外のものを  
示す。また、従業者規模は、小規模事業所¶従業者数\u000e?20人‰、中小事業所¶同  
21?300人‰、大事業所¶同300人超‰で比較するものとする。  
  
ア  従業者規模の小さな事業所ほど、新事業展開割合が低い。  
  
イ  小規模事業所の新事業展開では、多角化を実施した事業所の割合が事業転換  
を上回っている。  
  
ウ  小規模事業所の新事業展開割合は、大事業所を上回っている。  
  
エ  大事業所の新事業展開では、事業転換を実施した事業所の割合が多角化を上  
回っている。  
  
オ  中小事業所の新事業展開割合は、大事業所を上回っている。  
        
`
        },
        {
                "mondaiId": 107260102,
                "name": "第10問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の下線部②について、中小企業庁｢中小企業の新事業展開に関する調査｣  
¶2012年11月、複数回答‰に基づき、過去10年間に新事業展開を実施した中小  
企業について、規模別に新事業展開に際して直面した課題を比較した場合、最も  
適切なものはどれか。  
なお、ここでの企業区分は中小企業基本法の定義に準ずるものとし、中規模企  
業とは小規模企業以外の中小企業を指す。  
  
ア  小規模企業では、｢情報収集力が不足｣と回答する企業割合が｢自己資金が不  
足｣と回答する企業割合より高い。  
  
イ  小規模企業では、｢製品開発力、商品企画力が不足｣と回答する企業割合が  
｢自己資金が不足｣と回答する企業割合より高い。  
  
ウ  中規模企業では、｢新事業分野の参入障壁｣と回答する企業割合が｢自己資金  
が不足｣と回答する企業割合より高い。  
  
エ  中規模企業では、｢新事業を担う人材の確保が困難｣と回答する企業割合が  
｢自己資金が不足｣と回答する企業割合より高い。  
        
`
        },
        {
                "mondaiId": 107260111,
                "name": "第11問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
経営資源の乏しい中小企業にとって、企業運営上、経営者の経営能力、意欲への  
依存度は非常に高いため、円滑な事業承継は企業存続に直結する問題である。  
近年の事業承継を取り巻く状況を見ると、経営者の高齢化が進む中で、小規模企  
業を中心に後継者難から事業継続を断念する企業も増加している。これまで中小企  
業の事業承継においては、  
①  
経営者の子どもをはじめとする親族への承継が一般的で  
あったが、少子化が進展する現状においては、親族以外への事業承継や第三者への  
②  
事業売却も含めて、事業承継を検討することも必要になっている。  
一方、親族による承継と親族以外による承継では、事業を引き継ぐ際の課題も異  
なると考えられ、円滑な事業承継を進めるためにはこのような課題を克服していく  
ことが必要である。  
なお、ここでの企業区分は中小企業基本法の定義に準ずるものとし、中規模企業  
とは小規模企業以外の中小企業を指す。  
（設問1）‰  
文中の下線部①について、中小企業庁｢中小企業の事業承継に関するアンケー  
ト調査｣¶2012年11月‰に基づき、事業承継の時期別¶20年以上前、10?19年  
前、?3年前‰で、企業規模別に現経営者と先代経営者の関係を見た場合に、  
最も適切なものはどれか。  
  
ア  小規模企業では｢息子・娘への承継｣と回答する企業割合がいずれの時期も過  
半を占めている。  
  
イ  小規模企業では｢息子・娘への承継｣と回答する企業割合が増加している。  
  
ウ  中規模企業では｢息子・娘への承継｣と回答する企業割合がいずれの時期も過  
半を占めている。  
  
エ  中規模企業では｢息子・娘への承継｣と回答する企業割合が増加している。  
        
`
        },
        {
                "mondaiId": 107260112,
                "name": "第11問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の下線部②について、中小企業庁｢中小企業の事業承継に関するアンケー  
ト調査｣¶2012年11月、複数回答‰に基づき、企業規模別に事業売却を行う場合  
の障害を見た場合に、最も適切なものはどれか。  
  
ア  小規模企業では、｢買い手企業を見つけることが難しい｣と回答する企業割合  
が｢取引先との関係を維持しにくい｣と回答する企業割合より高い。  
  
イ  小規模企業では、｢自社の株主からの理解を得にくい｣と回答する企業割合が  
中規模企業より高い。  
  
ウ  小規模企業では、｢役員・従業員からの理解を得にくい｣と回答する企業割合  
が｢買い手企業を見つけることが難しい｣と回答する企業割合より高い。  
  
エ  小規模企業では、｢役員・従業員からの理解を得にくい｣と回答する企業割合  
が中規模企業より高い。  
        
`
        },
        {
                "mondaiId": 107260120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
情報技術¶IT‰はますます進歩しており、中小企業においてもITの導入・活用は  
避けられないものとなっている。中小企業庁｢ITの活用に関するアンケート調査｣  
¶2012年11月‰に基づき、ITの活用が必要と考えている中小企業がITを導入して  
いない理由¶複数回答‰について見た場合、回答企業割合が高いものから低いものへ  
と並べた組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａ｢導入の効果が分からない、評価できない｣  
ｂ｢ITを導入できる人材がいない｣  
ｃ｢適切なアドバイザー等がいない｣  
[解答群]  
  
ア  ａ｢導入の効果が分からない、評価できない｣安ｂ｢ITを導入できる  
人材がいない｣安ｃ｢適切なアドバイザー等がいない｣  
  
イ  ａ｢導入の効果が分からない、評価できない｣安ｃ｢適切なアドバイ  
ザー等がいない｣安ｂ｢ITを導入できる人材がいない｣  
  
ウ  ｂ｢ITを導入できる人材がいない｣安ａ｢導入の効果が分からない、  
評価できない｣安ｃ｢適切なアドバイザー等がいない｣  
  
エ  ｂ｢ITを導入できる人材がいない｣安ｃ｢適切なアドバイザー等がい  
ない｣安ａ｢導入の効果が分からない、評価できない｣  
  
オ  ｃ｢適切なアドバイザー等がいない｣安ａ｢導入の効果が分からない、  
評価できない｣安ｂ｢ITを導入できる人材がいない｣  
        
`
        },
        {
                "mondaiId": 107260130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　中小企業基本法の定義に基づく中小企業者に関する記述の正誤について、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　従業員数80人で資本金が８千万円の飲食業は、中小企業に該当する。<hint hidden='true'>**
❌
**</hint>
  
ｂ　従業員数150人で資本金が１億５千万円の食品卸売業は、中小企業に該当する。<hint hidden='true'>**
❌
**</hint>
  
ｃ　従業員数200人で資本金が２億円の建設業は、中小企業に該当する。<hint hidden='true'>**
⭕
**</hint>    


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>


[解答群]  
  
ア  ａ：正　ｂ：正　ｃ：誤  
  
イ  ａ：正　ｂ：誤　ｃ：正  
  
ウ  ａ：誤　ｂ：正　ｃ：誤  
  
エ  ａ：誤　ｂ：誤　ｃ：正<hint hidden='true'>**
←⭕
**</hint>    
        
`
        },
        {
                "mondaiId": 107260141,
                "name": "第14問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の中小企業基本法の基本理念に関する文章を読んで、下記の設問に答えよ。  
中小企業基本法では、中小企業を｢多様な事業の分野においてＡを行い、  
多様な就業の機会を提供し、個人がその能力を発揮しつつ事業を行う機会を提供す  
ることにより我が国の経済の基盤を形成しているもの｣と位置付けている。  
特に、多数の中小企業者が創意工夫を生かして経営の向上を図るための事業活動  
を行うことを通じて、①新たな産業の創出、②就業の機会の増大、③Ｂ、  
④Ｃなど、我が国経済の活力の維持と強化に果たすべき重要な役割を担う  
ことを期待している。  
（設問1）‰  
文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  経営資源の確保  
  
イ  経営の革新  
  
ウ  経済的社会的環境への対応  
  
エ  特色ある事業活動  
        
`
        },
        {
                "mondaiId": 107260142,
                "name": "第14問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の空欄ＢとＣに入る語句の組み合わせとして、最も適切なものを下記の解  
答群から選べ。  
ａ企業の社会貢献の推進  
ｂ市場における競争の促進  
ｃ地域における経済の活性化  
ｄ豊かな国民生活の実現  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 107260150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小規模企業は、地域の経済や雇用を支える存在として重要な役割を果たすととも  
に、その成長によって日本経済全体を発展させる重要な意義を有している。  
しかし、小規模企業は、資金や人材等の経営資源の確保が特に困難であることが  
多いこと等を背景に、近年、企業数・雇用者数ともに他の規模の企業と比べても減  
少している。このような状況を踏まえ、平成25年に｢小規模企業活性化法｣が施行  
され、中小企業基本法等の一部が改正された。  
小規模企業活性化法に関連する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  関係する個別法律において、小規模企業の範囲の弾力化が図られた。  
  
イ  小規模企業が有する重要な意義のひとつとして｢地域住民の生活の向上及び交  
流の促進に寄与する｣ことが、中小企業基本法に明記された。  
  
ウ  中小企業基本法の｢基本理念｣に、｢小規模企業の活力が最大限に発揮されなけ  
ればならない｣ことが規定された。  
  
エ  中小企業基本法の｢施策の方針｣に、｢小規模企業の規模拡大を支援する｣ことが  
明記された。  
        
`
        },
        {
                "mondaiId": 107260160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A県にて製造業を営む中小企業者が共同で事業環境の改善に取り組むために、  
県内において｢高度化事業｣の実施を計画している。中小企業者から｢高度化事業｣に  
関する相談を受けた中小企業診断士B氏のアドバイスとして、最も適切なものは  
どれか。  
  
ア  貸付けに当たっては、事業計画について、都道府県が中小企業診断士等の専門  
家を活用するなどして診断・助言を行います。  
  
イ  貸付対象は、設備資金と運転資金になります。  
  
ウ  貸付割合は、原則として必要資金総額の\u0005分の  
以内になります。  
  
エ  ｢共同施設事業｣は、工場を拡張したいが隣接地に用地を確保できない、騒音問  
題のため操業に支障があるなどの問題を抱える中小企業者が集まり、郊外に設備  
の整った工場を新設し、事業の拡大・効率化、公害問題の解決を図るものです。  
        
`
        },
        {
                "mondaiId": 107260171,
                "name": "第17問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
小規模企業共済制度は、共済契約者が独立行政法人中小企業基盤整備機構¶中小  
機構‰に掛金を納付し、中小機構がこれらの掛金を運用した上で、共済金等を給付  
する制度である。昭和40年に制度が創設され、平成25年\u0005月末の在籍者は約122  
万人となっている。  
（設問1）‰  
文中の｢小規模企業共済制度｣の加入対象として、<u>最も不適切なものはどれか</u>。  
  
ア  事業に従事する組合員の数が20人以下の企業組合の役員  
  
イ  小規模企業の共同経営者  
  
ウ  小規模企業の常用の従業員  
  
エ  小規模企業の役員`
        },
        {
                "mondaiId": 107260172,
                "name": "第17問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の｢小規模企業共済制度｣に関する記述として、最も適切なものはどれか。  
  
ア  新たに加入した共済契約者に対して、掛金月額の一部を国が助成する。  
  
イ  売掛金や受取手形などの回収が困難となった場合、共済金が支払われる。  
  
ウ  契約者貸付制度が設けられており、貸付けの担保、保証人は不要である。  
  
エ  その年に納付した掛金について、一定の額を税額控除できる。  
        
`
        },
        {
                "mondaiId": 107260180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本政策金融公庫の｢再チャレンジ支援融資制度｣は、一旦事業に失敗したことに  
より、再起を図る上で困難な状況に直面している中小企業を対象とした融資制度で  
ある。この制度の融資対象となるために必要な要件として、最も不適切なものはど  
れか。  
  
ア  廃業後、5年以内であること。  
  
イ  廃業時の負債が新たな事業に影響を与えない程度に整理される見込みであるこ  
と。  
  
ウ  廃業の理由・事情がやむを得ないものであること。  
  
エ  廃業歴等を有する個人または廃業歴等を有する経営者が営む法人であること。  
        
`
        },
        {
                "mondaiId": 107260190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  
｢地域商店街活性化事業｣は、商店街組織がＡの担い手として実施する、  
継続的な集客促進、需要喚起、商店街の体質強化に効果のある取り組みを支援する  
ものである。対象となるのは、商店街振興組合、事業協同組合等において組織され  
る商店街組織などである。  
この事業において商店街組織に対する支援は、Ｂによって行われる。  
[解答群]  
  
ア  Ａ：地域コミュニティ  
Ｂ：全国商店街振興組合連合会  
  
イ  Ａ：地域コミュニティ  
Ｂ：よろず支援拠点  
  
ウ  Ａ：中心市街地活性化  
Ｂ：全国商店街振興組合連合会  
  
エ  Ａ：中心市街地活性化  
Ｂ：よろず支援拠点  
        
`
        },
        {
                "mondaiId": 107260201,
                "name": "第20問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
下請代金支払遅延等防止法¶下請代金法‰は、親事業者の不公正な取引を規制し、  
下請事業者の利益を保護することを図るものである。ＡとＢは、  
親事業者が下請代金法のルールを遵守しているかどうか調査を行い、違反事業者に  
対しては、同法を遵守するよう指導している。  
（設問1）‰  
文中の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：各都道府県中小企業支援センターＢ：公正取引委員会  
  
イ  Ａ：各都道府県中小企業支援センターＢ：中小企業庁  
  
ウ  Ａ：公正取引委員会Ｂ：商工会・商工会議所  
  
エ  Ａ：公正取引委員会Ｂ：中小企業庁  
  
オ  Ａ：商工会・商工会議所Ｂ：中小企業庁`
        },
        {
                "mondaiId": 107260202,
                "name": "第20問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
次のケースのうち、下請代金法が適用される取引として、最も適切なものはど  
れか。  
  
ア  資本金  
千万円の企業が、資本金\f千万円の企業に物品の製造を委託する。  
  
イ  資本金\u0007千万円の企業が、資本金  
千万円の企業に物品の製造を委託する。  
  
ウ  資本金1億円の企業が、資本金\u0007千万円の企業に物品の製造を委託する。  
  
エ  資本金  
億円の企業が、資本金1億円の企業に物品の製造を委託する。  
        
`
        },
        {
                "mondaiId": 107260210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商業に関連する中小企業施策は、時代に応じて拡充されてきた。下記のa~cの  
法律に関する記述について、制定の古いものから順に正しく並べたものを下記の解  
答群から選べ。  
ａ地域住民の生活の向上や交流の促進に寄与してきた商店街の活性化を図るため  
に、｢地域商店街活性化法｣が制定された。  
ｂ商店街の整備、店舗の集団化、共同店舗等の整備等の事業の実施を円滑にし、  
中小小売商業者の経営の近代化を促進するために、｢中小小売商業振興法｣が制定  
された。  
ｃ消費生活の変化等の社会経済情勢の変化に対応し、中心市街地における都市機  
能の増進や経済活力の向上を推進するために、いわゆる｢まちづくり三法｣のひと  
つとして｢中心市街地活性化法｣が制定された。  
[解答群]  
  
ア  ａ安ｂ安ｃ  
  
イ  ａ安ｃ安ｂ  
  
ウ  ｂ安ａ安ｃ  
  
エ  ｂ安ｃ安ａ  
  
オ  ｃ安ｂ安ａ  
        
`
        },
        {
                "mondaiId": 107260221,
                "name": "第22問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
各地域の｢強み｣である地域資源を活用して新商品や新サービスの開発・市場化を  
行う中小企業者に対して、中小企業地域資源活用促進法に基づく支援の他、様々な  
支援が展開されている。  
中小企業地域資源活用促進法に基づいて、中小企業者等が単独または共同で、地  
域資源を活用した新商品・新サービスの開発・市場化を行う｢地域産業資源活用事  
業計画｣を作成し認定を受けると、各種支援施策を利用できる。  
なお、活用しようとする地域資源については、が指定している必要がある。  
（設問1）‰  
文中の下線部の｢各種支援施策｣として、<u>最も不適切なものはどれか</u>。  
  
ア  試作品開発、展示会出展等に係る費用の一部補助  
  
イ  信用保証の特例  
  
ウ  税制の特別措置  
  
エ  中小企業投資育成株式会社法の特例`
        },
        {
                "mondaiId": 107260222,
                "name": "第22問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
‰  
文中の空欄に入る語句として、最も適切なものはどれか。  
  
ア  各経済産業局  
  
イ  市町村  
  
ウ  主務大臣  
  
エ  都道府県  
        
`
        },
        {
                "mondaiId": 107260230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
これから創業するA氏は、創業資金の借り入れについて、中小企業診断士のB  
氏に相談を行った。B氏は、A氏に日本政策金融公庫の｢新創業融資制度｣を紹介す  
ることにした。  
この制度に関する、B氏のA氏への説明として、最も適切なものはどれか。  
  
ア  貸付利率は、基準利率より低い金利です。  
  
イ  資金使途は、創業時に必要な設備資金ですので、運転資金は対象になりませ  
ん。  
  
ウ  事前の経営相談、事後の経営指導を受けることが条件になります。  
  
エ  担保や保証条件は、原則として、無担保・無保証人です。`
        },
        {
                "mondaiId": 107260240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
LLP¶有限責任事業組合‰は、共同事業の組織として創設された制度である。技術  
やビジネスアイデアを持つ個人が共同経営者としてパートナーシップを組む場合  
や、中小企業同士の連携事業、中小企業と大企業の連携事業、産学連携事業など多  
様な活用が可能である。  
この制度の特徴に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  組合員全員が有限責任である。  
  
イ  構成員に課税される。  
  
ウ  組織の内部ルールの設定が柔軟である。  
  
エ  法人格を有する。  
        
`
        },
        {
                "mondaiId": 107260250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
創業を行うための支援策のひとつとして、｢創業促進補助金｣がある。この補助金  
に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  新たな需要を創造する新商品・サービスを提供する創業であることが補助金受  
給に必要な要件となる。  
  
イ  事業承継に伴い後継者が業態転換をする場合も対象となる。  
  
ウ  商工会・商工会議所の経営指導員による創業指導を受けることが補助金受給に  
必要な要件となる。  
  
エ  店舗借入費や設備費等の創業に要する費用を支援する。`
        },
        {
                "mondaiId": 107260260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
技術研究組合制度は、各企業や大学・公的研究機関等が組合員となって技術研究  
組合を設立し、事業を実施するために必要な資金、知的財産、研究者等を出し合っ  
て、組合員に共通する技術課題について共同研究を行うためのものである。  
この制度の具体的な特徴として、<u>最も不適切なものはどれか</u>。  
  
ア  組合が有する試験研究用資産に優遇税制¶圧縮記帳‰が適用される。  
  
イ  組合は法人格を有する。  
  
ウ  事業協同組合や企業組合への組織変更が容易である。  
  
エ  賦課金を支払う組合員に対し研究開発税制が適用される。  
        
`
        },
        {
                "mondaiId": 107260270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章の空欄に入る語句として、最も適切なものを下記の解答群から選べ。  
中小企業新事業活動促進法では｢経営革新｣を、｢事業者が新事業活動を行うこと  
により、その経営の相当程度の向上を図ること｣と定義している。  
この法律に基づいて、中小企業者が｢経営革新計画｣の承認を受けると、低利の融  
資制度や信用保証の特例など多様な支援を受けることができる。  
経営革新計画として承認されるためには、計画期間¶\u0005年間?5年間‰終了時にお  
ける経営指標の｢伸び率｣が要件のひとつとなる。経営革新計画の目標として採用さ  
れる複数の経営指標のうち、の目標伸び率は、5年計画であれば、\u0007％  
以上である。  
[解答群]  
  
ア  営業利益  
  
イ  経常利益  
  
ウ  従業員1人当たり付加価値額  
  
エ  付加価値額`
        },
        {
                "mondaiId": 107260280,
                "name": "第28問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
世代の交代期を迎えた中小企業の後継者が事業承継した場合、相続税、贈与税、  
または所得税の特例措置を受けることができる。事業承継円滑化のための税制措置  
として、<u>最も不適切なものはどれか</u>。  
  
ア  特定小規模宅地を相続した場合、評価額が減額となる課税の特例措置がある。  
  
イ  非上場株式等についての相続税の納税猶予制度がある。  
  
ウ  非上場株式等についての贈与税の納税猶予制度がある。  
  
エ  非上場の相続株式を他社に売却した場合、所得税の納税猶予制度がある。  
        
`
        },
        {
                "mondaiId": 107260290,
                "name": "第29問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2014,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
海外展開事業化可能性調査¶F/S‰支援事業は、海外市場での事業展開に向けて、  
事前市場調査、海外現地調査を通じた海外展開事業計画の策定を支援するものであ  
る。  
この事業に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  海外経験が豊富な専門家が支援する。  
  
イ  中小企業者を主とする概ね10社以上の共同グループを対象とした事業である。  
  
ウ  調査等に要する経費の一部を補助する。  
  
エ  独立行政法人中小企業基盤整備機構において、申請内容を審査し、支援企業の  
採択を決定する。  
`
        },
        {
                "mondaiId": 101250010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図のＡ~Ｄは、総務省が公表している°労働力調査¶より、｢15?24歳｣、  
｢25?34歳｣、｢45?54歳｣、｢55?64歳｣の、  
つの年齢階級別の完全失業率の  
推移を表したものである。下図のうち、｢15?24歳｣の完全失業率にあたるものは  
どれか。最も適切なものを下記の解答群から選べ。  
ABCD  
2012（年）20102008200620042002200019981996199419921990  
12  
10  
8  
6  
4  
2  
0  
（％）  
[解答群]  
  
ア  Ａ  
  
イ  Ｂ  
  
ウ  Ｃ  
  
エ  Ｄ  
`
        },
        {
                "mondaiId": 101250020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
内閣府が公表している｢需給ギャップ｣では、総需要と総供給に、それぞれどのよ  
うな変数を用いているか。最も適切なものの組み合わせを下記の解答群から選べ。  
ａ実際のGDP  
ｂ非自発的失業者が全く存在しないことを想定して求めた、完全雇用GDP  
ｃ完全雇用GDPに対応した国内総支出  
ｄ存在する民間設備と労働力を使って生み出せる、潜在GDP  
[解答群]  
  
ア  総需要：ａ総供給：ｂ  
  
イ  総需要：ａ総供給：ｄ  
  
ウ  総需要：ｃ総供給：ｂ  
  
エ  総需要：ｃ総供給：ｄ`
        },
        {
                "mondaiId": 101250030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
いま、総需要Dは、GDPをYとするとき、D暗50＋0.8Yで与えられるもの  
とする。完全雇用GDPを300としたときの説明として最も適切なものはどれか。  
  
ア  均衡GDPは250であり、10のインフレギャップが生じている。  
  
イ  均衡GDPは250であり、10のデフレギャップが生じている。<hint hidden='true'>**
←⭕
**</hint>    
  
ウ  均衡GDPは250であり、50のデフレギャップが生じている。  
  
エ  均衡GDPは300であり、50のインフレギャップが生じている。  
`
        },
        {
                "mondaiId": 101250040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
いま、GDPをY暗C＋I＋G、消費関数をC暗C0＋c(Y安T)で表すものと  
する。ただし、各記号の定義は以下のとおりである。  
Y：GDPである。  
C：消費である。  
I：投資であり10とする。  
G：政府支出であり\u0015とする。  
C0：基礎的消費であり\u0015とする。  
c：限界消費性向であり0.8とする。  
T：租税であり\u0015とする。  
政府が均衡予算を採用しているとき、上記の状況から政府が租税を\f増加させた  
ときのGDPの説明として最も適切なものはどれか。  
  
ア  GDPは0.8低下する。  
  
イ  GDPは\f増加する。  
  
ウ  GDPは\f低下する。  
  
エ  GDPは変わらない。`
        },
        {
                "mondaiId": 101250050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
労働のみを用いて生産を行っている企業を考える。この企業が生産物\f単位を生  
産するのに必要な労働量は一定であり、生産物価格と名目賃金率に基づいて利潤が  
最大になるように生産量を決定する。他方、労働者は、実質賃金率の水準に応じ  
て、労働供給量を決定する。縦軸に物価水準を、横軸に生産量をとり、これら企業  
と労働者の行動から導き出された総供給曲線を描くとき、その形状として、最も適  
切なものはどれか。  
  
ア  垂直イ水平ウ右上がりエ右下がり  
`
        },
        {
                "mondaiId": 101250060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
資産は貨幣と債券の\u0015つから構成されており、貨幣に利子は付かないと想定す  
る。  
貨幣供給量を増加させた場合、これが企業の設備投資や家計の住宅投資に与える  
影響に関する説明として、以下の?と?において、最も適切なものの組み合わせを  
下記の解答群から選べ。ただし、資産市場ではワルラス法則が成立しているものと  
する。  
?債券市場では、  
ａ超過需要が発生し、債券価格が上昇することで、利子率が低下する。  
ｂ超過供給が発生し、債券価格が下落することで、利子率が上昇する。  
??における利子率の変化により、  
ｃ債券から貨幣への需要シフトが起こり、また投資を行う際に必要な資金調  
達コストが低下するため、投資が促進される。  
ｄ貨幣から債券への需要シフトが起こり、また投資を行う際に必要な資金調  
達コストが上昇するため、投資が減退する。  
[解答群]  
  
ア  ?：ａ~：ｃ  
  
イ  ?：ａ~：ｄ  
  
ウ  ?：ｂ?：ｃ  
  
エ  ?：ｂ?：ｄ  
`
        },
        {
                "mondaiId": 101250070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
政府支出の増加が持つ有効需要創出効果がないケース、あるいは有効需要創出効  
果が弱められるケースの説明として、最も適切なものの組み合わせを下記の解答群  
から選べ。  
ａ政府支出の増加が公債発行によってまかなわれると、債券市場で超過需要が発  
生することから、利子率が上昇し、民間投資が抑えられる。  
ｂ在庫が存在する場合には、政府支出の増加に対して、財貨・サービスの供給  
を、在庫の取り崩しによって対応する。  
ｃ政府支出の増加によって、以前は民間によって供給されていた財貨・サービス  
を、政府が民間に代わって供給する。  
ｄ政府支出の増加によって、貨幣の取引需要が減少し、利子率が上昇すること  
で、民間投資が抑えられる。  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｃとｄ  
`
        },
        {
                "mondaiId": 101250080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
政府は一時的な減税を行うのと同時に、公債を発行するものとする。家計が生涯  
所得に依存して消費を決定し、また子孫世代のことを考慮に入れる場合、このよう  
な公債発行が家計行動に与える影響として、最も適切なものはどれか。  
  
ア  家計に流動性制約がある場合、現時点の貯蓄を増加させる。  
  
イ  家計は公債が償還される将来を見通して行動するため、現時点の消費を増加さ  
せる。  
  
ウ  公債償還時に借換債の発行が行われ続けることが予想されると、現時点の貯蓄  
を減らす。  
  
エ  公債の償還が自らの生存中にないことが分かっている場合、現時点の消費を減  
らす。  
`
        },
        {
                "mondaiId": 101250091,
                "name": "第9問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
  
イ  ンフレーション?インフレ?について、下記の設問に答えよ。  
（設問1）?  
  
イ  ンフレに関する記述として最も適切なものの組み合わせを下記の解答群から  
選べ。  
ａ同一時点で成立する財貨・サービスの相対価格体系を変化させる。  
ｂ異時点間で成立する財貨・サービスの相対価格体系を変化させる。  
ｃ名目利子率を所与として、期待インフレ率がより高くなると、実質利子率は  
低くなる。  
ｄ期待インフレ率がより高くなるのと同じだけ、名目利子率も高くなると、実  
質利子率も高くなる。  
[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ`
        },
        {
                "mondaiId": 101250092,
                "name": "第9問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問2）  
  
イ  ンフレが、所得分配に与える影響に関する記述として、最も不適切なものは  
どれか。  
  
ア  インフレは債権者から債務者への実質所得移転をもたらす。  
  
イ  インフレは名目額で固定された所得を得ている人々の実質所得を減少させ  
る。  
  
ウ  課税最低所得がインフレにスライドして引き上げられない場合、インフレは  
課税対象者を増やす効果を持つ。  
  
エ  累進課税のもとでは、インフレは名目所得税額を変化させない。  
`
        },
        {
                "mondaiId": 101250100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
実質貨幣鋳造収入は、実質貨幣残高と期待インフレ率の積に相当する。期待イン  
フレ率の変化が実質貨幣鋳造収入に与える影響に関する記述として、最も適切なも  
のの組み合わせを下記の解答群から選べ。  
ａ期待インフレ率の上昇は、実質貨幣残高\f単位あたりの実質貨幣鋳造収入を引  
き上げる。  
ｂ期待インフレ率の上昇は、実質貨幣残高\f単位あたりの実質貨幣鋳造収入を引  
き下げる。  
ｃ期待インフレ率の上昇は、名目金利を上昇させ、実質貨幣需要が増加する。そ  
のため、実質貨幣残高が増加し、実質貨幣鋳造収入を引き上げる。  
ｄ期待インフレ率の上昇は、名目金利を上昇させ、実質貨幣需要が減少する。そ  
のため、実質貨幣残高が減少し、実質貨幣鋳造収入を引き下げる。  
[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101250110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、\u0015種類の生産要素、資本Kと労働Nを用いて、生産量Yが産出されてい  
る。次の生産関数は、労働\f単位あたりの資本と労働\f単位あたりの生産量との対  
応関係を表している。  
y暗f(k)  
ここでk暗K／Nは資本・労働比率を、y暗Y／Nは労働\f単位あたりの生産量  
である。また、労働成長率nは所与であり、常に完全雇用が実現しているとする。  
また、人々は所得の一定割合sを常に貯蓄するとする。  
下図の新古典派の経済成長モデルを参照した上で、労働成長率nの低下と貯蓄  
率sの低下、それぞれが定常状態における労働\f単位あたりの生産量に与える影響  
に関する記述として、最も適切なものを下記の解答群から選べ。ただし、k1は定  
常状態の資本・労働比率を表している。  
[解答群]  
  
ア  労働成長率の低下と貯蓄率の低下、いずれによっても減少する。  
  
イ  労働成長率の低下と貯蓄率の低下、いずれによっても増加する。  
  
ウ  労働成長率の低下によって減少し、貯蓄率の低下によって増加する。  
  
エ  労働成長率の低下によって増加し、貯蓄率の低下によって減少する。  
`
        },
        {
                "mondaiId": 101250120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
トービンのqに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  投資に影響を与えるのは株価であるとされる。  
  
イ  トービンのqが1を上回ると、企業は新たな投資を行うことが望ましい。  
  
ウ  トービンのqが1を下回ると、企業が保有する資本ストックを市場で売却した  
金額のほうが、その資本ストックを使って企業が生み出す利益よりも<hintTarget>小さい</hintTarget>こと  
を表している。

<hint hidden='true'>**
❌　トービンのq＝　株式市場で評価された企業価値　÷　資本の再取得価格
**</hint>

エ  トービンのqは、分子に株式市場で評価された企業価値と企業の負債総額の和  
をとり、分母に資本の再取得価格をとる。  
`
        },
        {
                "mondaiId": 101250130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
人生を若年期(期間1)と老年期(期間2)とに分ける。期間i(i暗1，2)における消  
費をCi、所得をYi、利子率をrとする。貯蓄と借入(負の貯蓄)は若年期において  
のみ行われ、老年期を終える時点では貯蓄も借入も残さないものとする。  
下図は、横軸に若年期の消費(C1)を、縦軸に老年期の消費(C2)をとり、若年期  
と老年期の所得を所与として、貯蓄や借入に制約がない場合に選択可能な若年期と  
老年期の消費の組み合わせを右下がりの直線ABで表している。  
直線ABの形状の説明として、最も適切なものを下記の解答群から選べ。  
B  
A  
[解答群]  
  
ア  縦軸の切片がY1＋(1＋r)Y2で、傾きが安(1＋r)の直線。  
  
イ  縦軸の切片がY1＋(1＋r)Y2で、傾きが安1／(1＋r)の直線。  
  
ウ  縦軸の切片が(1＋r)Y1＋Y2で、傾きが安(1＋r)の直線。  
  
エ  縦軸の切片が(1＋r)Y1＋Y2で、傾きが安1／(1＋r)の直線。  
`
        },
        {
                "mondaiId": 101250141,
                "name": "第14問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
いま、余暇時間Lと労働所得Yからのみ効用を得るような個人を考える。余暇  
時間の増加は、24時間のうち労働する時間が減少することを意味し、賃金率庵労  
働時間で与えられる労働所得が減少するという関係にある。下図では、この個人が  
直面する予算線はJKであり、無差別曲線U1と接する点Aで最適な余暇時間と労  
働所得の組み合わせが与えられている。  
この状態から、政府が労働所得に比例税率αを課したとき、予算線はHKへ変  
化し、最適点は、HKと無差別曲線U2が接する点Bによって与えられる。点線  
MNは、政府が一括税を課した場合の予算線であり、JKと平行で点Bを通るよう  
に描かれており、点Cで無差別曲線U3と接する。点線WWは、HKと平行で無  
差別曲線U1と点Dで接するような補助線である。  
        
（設問1）?  
この図に関する説��として、最も適切なものはどれか。  
  
ア  線分HOの長さを線分JOの長さで除した値は、賃金率となる。  
  
イ  線分KOの長さを線分JOの長さで除した値は、労働所得に課される比例税  
率αとなる。  
  
ウ  線分MNが示す一括税は、線分HKが示す比例税よりも、この個人が合理  
的に選択する労働時間を短くする。  
  
エ  点Bの税収は、点Cの税収と同じである。`
        },
        {
                "mondaiId": 101250142,
                "name": "第14問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問2）?  
政府が労働所得に比例税率αを課すと、最適な余暇時間と所得との組み合わ  
せは、点Aから点Bへと移る。所得への課税が余暇時間に与える影響を、｢代替  
効果｣と｢所得効果｣とに分けた記述として、最も適切なものはどれか。ただし余  
暇は、下級財ではないものとする。  
  
ア  ｢代替効果｣は相対的に高くなった余暇時間を増やす点Aから点Dへの変化  
で表され、｢所得効果｣は点Dから点Bへの変化で表される。  
  
イ  ｢代替効果｣は相対的に安くなった余暇時間を増やす点Aから点Dへの変化  
で表され、｢所得効果｣は点Dから点Bへの変化で表される。  
  
ウ  ｢所得効果｣は点Aから点Dへの変化で表され、｢代替効果｣は相対的に高く  
なった余暇時間を減らす点Dから点Bへの変化で表される。  
  
エ  ｢所得効果｣は点Aから点Dへの変化で表され、｢代替効果｣は相対的に安く  
なった余暇時間を減らす点Dから点Bへの変化で表される。  
`
        },
        {
                "mondaiId": 101250150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、下図のような線形の需要曲線ABを考える。需要曲線AB上の点Lは、  
線分OMと線分MBの長さが等しくなるような線分ABの中点である。需要曲線  
AB上の点Kは、点Lより左に位置している。  
需要曲線の価格弾力性εの絶対値εは、価格をp、数量をxとし、価格が微少  
にΔpだけ増加したときの数量の微少な変化分をΔxとすれば、ε暗  
Δx  
x  
Δp  
p  
暗  
Δx  
Δp  
p  
x  
と書き表すことができる。この需要曲線に関する説明として、最も不適切なものを  
下記の解答群から選べ。  
[解答群]  
  
ア  点Kにおける  
Δx  
Δp  
は、線分ODの長さを線分ACの長さで除した値と等しい。  
  
イ  点Kにおける  
p  
x  
は、線分OCの長さを線分ODの長さで除した値と等しい。  
  
ウ  点Kの需要の価格弾力性は、線分BDの長さを線分ODの長さで除すこと  
で求められる。  
  
エ  点Lの需要の価格弾力性は\fより大きい。  
`
        },
        {
                "mondaiId": 101250160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、競争的市場である製品を生産する企業を考える。総費用TCが当該製品  
の生産量xの関数として以下のように与えられている。ただし、x＞0とする。  
TC暗224＋6x安2x2＋x3  
この費用関数に基づいて計算された限界費用と平均可変費用の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
ａ6安4x＋3x2  
ｂ6安2x＋x2  
ｃ  
224  
x  
＋6安2x＋x2  
ｄ安4x＋3x2  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101250170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、平均費用が逓減局面にある財市場で企業Zによる自然独占が発生して  
いる状況を示している。この図に関する記述として最も適切なものを下記の解答群  
から選べ。  
平均費用  
限界費用  
価格  
限界収入  
[解答群]  
  
ア  企業Zが独占企業として振る舞う場合、四角形ACFHが独占的利潤の大き  
さを意味する。  
  
イ  企業Zに対して政府が限界費用価格形成原理を課す場合、三角形CADに相  
当する死重損失が発生する。  
  
ウ  企業Zに対して政府が限界費用価格形成原理を課す場合、四角形DBGHに  
相当する損失が発生する。  
  
エ  企業Zに対して政府が平均費用価格形成原理を課す場合、当該財の生産量  
はIとなる。  
`
        },
        {
                "mondaiId": 101250180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、消費額に応じて定率a％で課税する制度を新規に導入しようとしている。  
ただし、すべての課税対象者に対して定額bを現金で給付する制度も併せて導入す  
る。このとき、新しい制度に伴う納税額Tは、消費額をYとすればT暗aY安b  
と書くことができ、下図のように描くことができる。なお、下図で平均税率とは、  
納税額を消費額で除した値?T／Y?で表されるものとする。  
この図に関する記述として、最も不適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  課税対象者に対する現金の給付額bを変化させても、消費額Y1の個人が直  
面する平均税率は変わらない。  
  
イ  高額な納税者ほど平均税率は高くなっているため、その意味では、この制度  
は累進的である。  
  
ウ  消費額Y0の納税者は、この制度の導入によって実質的に可処分所得が増加  
する。  
  
エ  すべての納税者にとって、消費額を\f単位増やしたときの限界税率は等しい。  
`
        },
        {
                "mondaiId": 101250190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、ある地方自治体は、住民?同質的であると仮定?へ地方税を課税して得た財  
源で公共サービスを提供している。住民は公共サービスを享受しつつ、地方税の税  
引き後所得を用いて私的財を消費している。もし、地方税をゼロとすれば、私的財  
の消費量はAとなり、所得のすべてを納税すれば公共サービスの消費量はBとな  
る。これが予算制約ABとなり、この地方自治体の代表的個人は予算制約上の点E  
を選好しているものとする。  
この地方自治体が当該住民の負担にならない｢補助金｣を国から得たとする。地方  
自治体は、この一部ないし全部を住民に現金で給付することもできるし、公共サー  
ビスを直接的に提供することもできるものとし、この状況が予算制約CDとして描  
かれている。もし、この｢補助金｣の全額が住民に対して現金で給付されたならば、  
代表的個人は点Fを選好するものと考える。  
この図に関する説明として、最も不適切なものを下記の解答群から選べ。  
私的財の量  
公共サービスの量  
        
[解答群]  
  
ア  ｢補助金｣を得た地方自治体がIの公共サービスを提供するならば、この地方  
自治体は住民の効用を最大化している。  
  
イ  ｢補助金｣を得た地方自治体がKの公共サービスを提供するならば、住民の  
効用は点Fが選択される場合よりも低下する。  
  
ウ  ｢補助金｣を得た地方自治体がKの公共サービスを提供するならば、それは  
｢フライペーパー効果｣とみることができる。  
  
エ  ｢補助金｣を得た地方自治体がその全額を住民へ現金で給付すると、｢補助金｣  
を得る前と比べて、住民の地方自治体への納税額は減少する。  
`
        },
        {
                "mondaiId": 101250200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、\u0015人?AとB?、\u0015財?XとY?の設定で描かれたエッジワースのボック  
スダイアグラムである。OAが個人Aの原点、OBが個人Bの原点であり、\u0015人が  
保有する\u0015財の量を識別するため、XとYの右下にはAとBというインデックス  
が付されている。  
いま、下図では、XとYにまつわる\u0015人の初期の資源配分が線分DF上のパレ  
ート最適な点Kとして与えられている。点Kでは、線分DFと無差別曲線UAと  
が接している。なお、無差別曲線UA上には点Mもある。点線で描かれている線  
分CEは線分DFと同じ傾きを有し、点Nを通過する。線分CE上にはパレート  
最適な点Lも与えられている。  
このとき、下図に関する説明として最も適切なものを下記の解答群から選べ。  
        
[解答群]  
  
ア  所得再分配によって、点Kから点Lへ財の保有量を変化させることは、パ  
レート効率的である。  
  
イ  線分DFの傾きは、\u0015財の価格の比率と見なすこともできる。  
  
ウ  点Kから点Mへ財の保有量を変化させても市場の効率性は失われない。  
  
エ  点Nから点Lへ財の保有量を変化させることは、個人Aと個人Bが保有す  
るXとYの合計量をそれぞれ増加させる。  
`
        },
        {
                "mondaiId": 101250210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、\u0015つの企業AとBを考える。両企業は、それぞれ、重要な特許権と、重  
要ではない特許権を有している。もし、双方が重要ではない特許権のみを拠出し，  
それらを共有するならば、開発される新製品の質は低く、双方の企業は22の利益  
しかあげることができない。しかしながら、両企業が重要な特許権を拠出し、それ  
らを共有するならば画期的な新製品の開発によって、双方とも35の利益をあげる  
ことができる。  
ただし、相手が重要な特許権を拠出しながらも、自らは重要ではない特許権を拠  
出することができ、それらを共有するならば、自らの企業だけが新製品の開発に成  
功し40の利益をあげることができる一方で、相手企業は新製品の開発ができず利  
益は20にとどまる。  
下表は、このような企業間の関係を利得表の形で整理したものである。企業A  
と企業Bが相互に利得表の内容を理解しているときの説明として、最も適切なも  
のを下記の解答群から選べ。  
重要な特許権を拠出  
する。  
?35，35??20，40?  
重要な特許権を拠出  
する。  
重要ではない特許権  
のみを拠出する。  
企業Ｂの戦略  
企業Ａの戦略  
重要ではない特許権  
のみを拠出する。  
?40，20??22，22?  
[解答群]  
  
ア  このような企業間の関係が\f回限りで生じている場合、資源配分が?22，22?  
となるとき、パレート最適が実現している。  
  
イ  このような企業間の関係が\f回限りで生じている場合、両企業が｢重要では  
ない特許権のみを拠出する｣のは、ナッシュ均衡である。  
  
ウ  このような企業間の関係が\u0015回だけ繰り返される場合、\f回目の取引で資源  
配分が?22，22?となるとき、情報の非対称性によるモラルハザードが起きてい  
る。  
  
エ  このような企業間の関係が\u0015回だけ繰り返される場合、企業Aが\f回目の  
取引で｢重要な特許権を拠出する｣のは支配戦略である。  
`
        },
        {
                "mondaiId": 101250220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経済用語や経済政策に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  中央銀行による｢量的緩和政策｣には、例えば、金利が著しく低い状況の下で、  
中央銀行が伝統的には取扱い対象としない社債なども購入することでマネタリー  
ベースを増加させることがあてはまる。  
  
イ  ｢賃金の下方硬直性｣には、例えば、物価が継続的に低下している状況であって  
も賃金が容易には低下しないことがあてはまる。  
  
ウ  ｢モラルハザード｣には、ある個人が生活保護制度による扶助を念頭におきつ  
つ、情報の非対称性を利用して意図せざる失業を装うようなことがあてはまる。  
  
エ  わが国では、農業政策のひとつとして、コメの輸入に関税と輸入量の上限規制  
とを設けることで国内の生産者を保護している。`
        },
        {
                "mondaiId": 101250230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
京都議定書やわが国の環境政策に関する記述として、最も不適切なものはどれ  
か。  
  
ア  環境省が2012年度に公表した値によれば、2010年度のわが国の温室効果ガス  
の総排出量は、京都議定書の規定による基準年の総排出量と比べると減少してい  
る。  
  
イ  京都議定書では、各国の数値目標を達成するための補助的手段として、排出量  
取引やクリーン開発メカニズムなどが導入された。  
  
ウ  京都議定書は、2004年にアメリカが批准したことで発効した。  
  
エ  わが国が排出する温室効果ガスのうち、全体の排出量の90％超を占めている  
のが二酸化炭素である。  
`
        },
        {
                "mondaiId": 102250011,
                "name": "第1問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
伝票式会計は、分業による経理処理の効率化のための工夫として広く採用されて  
いる。伝票式会計に関する以下の設問に答えよ。  
（設問1）≫  
伝票式会計に関する記述として、最も適切なものの組み合わせを下記の解答群  
から選べ。  
ａ伝票式会計を導入している場合、売上戻りは売上伝票に記入される。  
ｂ伝票式会計を導入している場合、仕訳帳は利用されない。  
ｃ伝票式会計を導入している場合、仕訳日計表には売上伝票と仕入伝票を集計  
しない。  
ｄ伝票式会計を導入している場合、補助簿の記入は仕訳日計表を利用して行  
う。  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 102250012,
                "name": "第1問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
≫  
本日における伝票の一部が以下に示されている。売掛金勘定の本日の残高とし  
て最も適切なものを下記の解答群から選べ。なお、昨日の売掛金勘定は借方残高  
120，000円であった。  
入金伝票No.101  
売掛金”Ａ店≫￥60，000  
入金伝票No.102  
受取手形”Ｂ店≫￥70，000  
売上伝票No.401  
Ｂ店￥50，000  
振替伝票No.301  
借方：買掛金”Ｄ店≫￥40，000  
貸方：支払手形”Ｄ店≫￥40，000  
振替伝票No.302  
借方：裏書手形”Ｅ店≫￥10，000  
貸方：受取手形”Ｅ店≫￥10，000  
振替伝票No.303  
借方：受取手形”Ｂ店≫￥80，000  
貸方：売掛金”Ｂ店≫￥80，000  
売上伝票No.402  
Ｃ店￥70，000  
[解答群]  
  
ア  40，000円  
  
イ  90，000円  
  
ウ  100，000円  
  
エ  240，000円  
`
        },
        {
                "mondaiId": 102250020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
剰余金の処分において、株主に対して配当金2，000千円を支払うことを決定し  
た。以下の資料に基づいて、会社法に従うとき積み立てるべき利益準備金の最低額  
はいくらか。最も適切なものを下記の解答群から選べ。  
\u0014資料\u0015  
資本金15，000千円  
資本準備金2，200千円  
利益準備金1，500千円”既積立額≫  
[解答群]  
  
ア  0千円  
  
イ  50千円  
  
ウ  200千円  
  
エ  250千円  
`
        },
        {
                "mondaiId": 102250030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の株主資本等変動計算書に基づいて、当期末純資産合計として最も適切なも  
のを下記の解答群から選べ。  
純資産  
合計  
当期変動額  
剰余金の配当  
前期末残高  
利益  
剰余金  
合計  
資本金  
資本剰余金利益剰余金  
株主  
資本  
合計  
株主資本  
別途  
積立金  
繰越利益  
剰余金  
資本  
準備金  
その他  
資本  
剰余金  
資本  
剰余金  
合計  
利益  
準備金  
その他利益剰余金  
50，0005，0001，0006，0002，5002，4004，90060，90060，900  
6006，6006，000”≫”≫  
別途積立金の積み立て3，8003，800  
当期純利益9，6009，600”≫”≫  
当期変動額合計”≫”≫”≫”≫”≫”≫  
当期末残高50，0005，0001，0006，000”≫”≫”≫”≫”≫”≫  
”単位：千円≫  
[解答群]  
  
ア  56，000千円  
  
イ  59，100千円  
  
ウ  60，900千円  
  
エ  64，500千円  
`
        },
        {
                "mondaiId": 102250040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
キャッシュ・フロー計算書が対象とする資金の範囲は、現金及び現金同等物であ  
る。現金同等物に含まれる短期投資に該当する最も適切なものの組み合わせを下記  
の解答群から選べ。なお、ａ~ｅの資産の運用期間はすべて\u0005か月以内であるとす  
る。  
ａ株式  
ｂ株式投資信託  
ｃコマーシャル・ペーパー  
ｄ定期預金  
ｅ普通預金  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｃとｄ  
  
オ  ｄとｅ  
`
        },
        {
                "mondaiId": 102250051,
                "name": "第5問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次に示す財務諸表に基づいて、以下の設問に答えよ”単位：千円≫。  
120，000負債・純資産合計120，000資産合計  
85，000純資産  
X2年度末X2年度末  
流動資産  
130，000  
86，000  
X1年度末X1年度末  
130，000  
固定資産95，00091，000固定負債28，00020，000  
15，00016，000流動負債29，00035，000  
H貸借対照表J  
H損益計算書J  
X1年度X2年度  
売上高180，000170，000  
営業費用150，000152，000  
営業利益30，00018，000  
支払利息1，000800  
経常利益29，00017，200  
固定資産売却損1，000200  
税引前当期純利益28，00017，000  
法人税等10，0004，000  
当期純利益18，00013，000  
（設問1）≫  
収益性の動向に関する説明として最も適切なものはどれか。なお、比率の計算  
における総資本は年度末の金額を利用する。  
  
ア  総資本営業利益率：悪化売上高営業利益率：悪化総資本回転率：改善  
  
イ  総資本営業利益率：悪化売���高営業利益率：改善総資本回転率：改善  
  
ウ  総資本営業利益率：改善売上高営業利益率：悪化総資本回転率：改善  
  
エ  総資本営業利益率：改善売上高営業利益率：改善総資本回転率：悪化  
`
        },
        {
                "mondaiId": 102250052,
                "name": "第5問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
≫  
安全性の動向に関する説明として最も適切なものはどれか。  
  
ア  流動比率：悪化固定長期適合率：悪化負債比率：改善  
  
イ  流動比率：悪化固定長期適合率：改善負債比率：改善  
  
ウ  流動比率：改善固定長期適合率：悪化負債比率：改善  
  
エ  流動比率：改善固定長期適合率：改善負債比率：悪化`
        },
        {
                "mondaiId": 102250060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の資料はA社の貸借対照表および関連する情報である。A社を現金  
620，000千円で買収する際に生じる、会計上ののれんはいくらか。最も適切なもの  
を下記の解答群から選べ。  
\u0014資料\u0015  
貸借対照表  
”単位：千円≫  
1，100，000  
200，000剰余金備品  
売掛金  
400，000  
1，100，000  
棚卸資産500，000資本金600，000  
300，000借入金200，000  
売掛金の時価150，000千円  
棚卸資産の時価450，000千円  
備品の時価220，000千円  
負債の簿価は時価と等しい。  
[解答群]  
  
ア  のれん0千円  
  
イ  のれん100，000千円  
  
ウ  負ののれん80，000千円  
  
エ  負ののれん180，000千円  
`
        },
        {
                "mondaiId": 102250070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
損益計算上の利益が増加することに関する説明として、最も適切なものの組み合  
わせを下記の解答群から選べ。  
ａ生産コストが低減し、その他の条件に変化がないとすれば、利益が増加する。  
ｂ生産量が減少し、その他の条件に変化がないとすれば、利益が増加する。  
ｃ生産量が増加し、その他の条件に変化がないとすれば、利益が増加する。  
ｄ販売量が減少し、その他の条件に変化がないとすれば、利益が増加する。  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 102250080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A社の当期の売上高は20，000千円、費用は以下のとおりであった。なお、一般  
管理費はすべて固定費である。安全余裕率として最も適切なものを下記の解答群か  
ら選べ。  
変動製造費用5，000千円  
固定製造費用9，000千円  
変動販売費3，000千円  
固定販売費800千円  
一般管理費1，000千円  
[解答群]  
  
ア  10.0％  
  
イ  10.9％  
  
ウ  25.0％  
  
エ  28.0％  
`
        },
        {
                "mondaiId": 102250090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
次にあげる費目のうち、労務費ではないものの組み合わせを下記の解答群から選  
べ。  
ａ外注加工賃  
ｂ雑給  
ｃ従業員賞与手当  
ｄ所得税預り金  
ｅ割増賃金  
[解答群]  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｃとｄ  
  
オ  ｄとｅ  
`
        },
        {
                "mondaiId": 102250100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
標準原価計算を実施しているA社の当月に関する以下のデータに基づき、材料  
数量差異として最も適切なものを、下記の解答群から選べ。なお、材料は工程の始  
点で投入される。  
直接材料費の原価標準データ  
300円/kg庵3kg暗900円  
当月の生産関連データ  
当月材料消費量3，100kg材料消費価格310円/kg  
月初仕掛品200単位  
当月完成品900単位  
月末仕掛品300単位  
[解答群]  
  
ア  不利差異30，000円  
  
イ  不利差異31，000円  
  
ウ  不利差異61，000円  
  
エ  不利差異120，000円  
`
        },
        {
                "mondaiId": 102250110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のデータに基づき、期末仕掛品原価として最も適切なものを、下記の解答群  
から選べ。なお、材料は工程の始点で投入される。  
・数量データ  
当月投入800単位  
当月完成品600単位  
月末仕掛品200単位”加工進捗度0.5≫  
・原価データ  
直接材料費1，440千円  
加工費1，400千円  
[解答群]  
  
ア  560千円  
  
イ  710千円  
  
ウ  2，130千円  
  
エ  2，280千円`
        },
        {
                "mondaiId": 102250120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
現金の増加要因となりうるものとして、<u>最も不適切なものはどれか</u>。  
  
ア  株主資本の減少  
  
イ  現金以外の流動資産の減少  
  
ウ  固定資産の減少  
  
エ  負債の増加  
`
        },
        {
                "mondaiId": 102250130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ファイナンス・リースに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ファイナンス・リース取引によるリース契約では、通常、借り手側は中途解約  
ができない。  
  
イ  ファイナンス・リース取引によるリース物件の維持管理費用は、貸し手が負担  
する。  
  
ウ  ファイナンス・リース取引によるリース物件は、借り手側の貸借対照表上で開  
示される。  
  
エ  ファイナンス・リース取引によるリース物件は、借り手において減価償却費が  
算定される。`
        },
        {
                "mondaiId": 102250140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のデータからA社の加重平均資本コストを計算した場合、最も適切なもの  
を下記の解答群から選べ。  
有利子負債額：4億円  
株式時価総額：8億円  
負債利子率：4％  
法人税率：40％  
A社のベータ”β≫値：1.5  
安全利子率：3％  
市場ポートフォリオの期待収益率：8％  
[解答群]  
  
ア  5.8％イ6.7％ウ7.8％エ8.3％  
`
        },
        {
                "mondaiId": 102250150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の文章の空欄Ａ、Ｂに入る最も適切な語句の組み合わせを、下記の解答群か  
ら選べ。  
完全市場を前提としたMM理論では資本構成と企業価値は独立であり、最適な  
資本構成は存在しないとされる。しかし現実には市場は不完全であり、MM理論  
は現実の企業の資本調達行動をうまく説明できていない。こうした中で現実の企業  
の資本調達行動を説明するための様々な仮説が提示されており、それらのひとつに  
ペッキングオーダー仮説がある。この仮説によれば、経営者は資本調達において、  
まずＡなどの内部資金を優先し、ついで外部資金のうちでも社債発行など  
のＢを優先するとされている。  
[解答群]  
  
ア  Ａ：企業間信用Ｂ：エクイティ・ファイナンス  
  
イ  Ａ：企業間信用Ｂ：デット・ファイナンス  
  
ウ  Ａ：内部留保Ｂ：エクイティ・ファイナンス  
  
エ  Ａ：内部留保Ｂ：デット・ファイナンス`
        },
        {
                "mondaiId": 102250160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
代替案の選択によって金額に差異が生じないコストであり、将来の意思決定に無  
関連な原価を表すものとして、最も適切なものはどれか。  
  
ア  機会原価  
  
イ  限界原価  
  
ウ  裁量可能原価  
  
エ  埋没原価  
`
        },
        {
                "mondaiId": 102250170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下の文章の空欄Ａ、Ｂに入る最も適切な語句の組み合わせを、下記の解答群か  
ら選べ。  
投資プロジェクトの評価方法には、正味現在価値法のほか、Ａ、回収期  
間法、会計的投資利益率法など多くの代替的手法がある。さらに近年では、不確実  
性の高い経営環境のもとで投資プロジェクトに対する経営の柔軟性を評価する  
Ｂも提唱されている。  
[解答群]  
  
ア  Ａ：線形計画法Ｂ：平均・分散モデル  
  
イ  Ａ：線形計画法Ｂ：リアル・オプション・アプローチ  
  
ウ  Ａ：内部収益率法Ｂ：マーケット・アプローチ  
  
エ  Ａ：内部収益率法Ｂ：リアル・オプション・アプローチ`
        },
        {
                "mondaiId": 102250180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A社では、生産コストの低減を目的として新規設備の購入を検討している。新  
規設備の取得原価は4，500万円であり、その経済命数は5年である。また経済命数  
経過後の残存価額はゼロと見込まれている。A社では定額法によって減価償却を  
行っており、同社の法人税率は40％である。A社は当該投資案に対して回収期間  
法によって採否を決定することとしており、採択となる目標回収期間を\u0005年と定め  
ている。新規設備が採択されるために最低限必要とされる年間の生産コスト低減額  
として最も適切なものはどれか。なお、貨幣の時間価値は考慮せず、年間の生産コ  
スト低減額は毎期一定である。また、当該投資案によって減価償却費以外の追加的  
費用は発生しない。  
  
ア  600万円  
  
イ  900万円  
  
ウ  1，500万円  
  
エ  1，900万円  
`
        },
        {
                "mondaiId": 102250190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
縦軸に投資の期待収益率、横軸に当該投資収益率の標準偏差をとった平面上にお  
けるリスク回避者の無差別曲線を表す図形として、最も適切なものはどれか。  
`
        },
        {
                "mondaiId": 102250201,
                "name": "第20問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次のデータに基づき、以下の設問に答えよ。  
1.2  
PBR配当利回り配当性向自己資本比率ROE  
\u0005％36％60％10％  
（設問1）≫  
自己資本配当率”DOE≫として、最も適切なものはどれか。  
  
ア  3.6％  
  
イ  7.2％  
  
ウ  21.6％  
  
エ  43.2％`
        },
        {
                "mondaiId": 102250202,
                "name": "第20問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
≫  
PERとして、最も適切なものはどれか。  
  
ア  2倍  
  
イ  3.3倍  
  
ウ  12倍  
  
エ  40倍  
`
        },
        {
                "mondaiId": 102250210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
資本市場理論におけるベータ値に関する説明として、最も不適切なものはどれ  
か。  
  
ア  個々の証券の収益率の全変動におけるアンシステマティック・リスクを測定す  
る値である。  
  
イ  市場全体の変動に対して個々の証券の収益率がどの程度変動するかの感応度を  
表す値である。  
  
ウ  市場ポートフォリオのベータ値は\fである。  
  
エ  ベータ値は理論上マイナスの値もとりうる。`
        },
        {
                "mondaiId": 102250220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
輸入業を営むA社は、\u0005か月後にドル建てで商品の仕入代金を支払う予定であ  
る。A社が為替リスクをヘッジするときの取引として、最も適切なものはどれか。  
  
ア  ドル売りの為替予約を行う。  
  
イ  ドル買いの為替予約を行う。  
  
ウ  ドル建ての借入を行い、為替の直物レートで円を買う。  
  
エ  ドルの\u0005か月物コール･オプションを売る。  
`
        },
        {
                "mondaiId": 102250230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
現物株\f単位の買いポジションと当該株式を原資産とする個別株プットオプショ  
ン\f単位の買いポジションを組み合わせた戦略の損益を表す図表として、最も適切  
なものはどれか。なお、Xは権利行使価格である。  
`
        },
        {
                "mondaiId": 103250010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経営計画の策定と実行について留意すべき点に関する記述として、最も適切なも  
のはどれか。  
  
ア  経営計画策定時に用いられる業績に関する定量的なデータを収集して分析する  
ことによって、新機軸の戦略を構築することができる。  
  
イ  経営計画になかった機会や脅威から生まれてくる新規な戦略要素を取り入れて  
いくには、計画遂行プロセスで学習が起こることが重要になる。  
  
ウ  経営計画に盛り込まれた戦略ビジョンは、予算計画や下位レベルのアクショ  
ン・プランと連動させるとコントロール指針として機能するようになり、戦略行  
動の柔軟性を失わせる。  
  
エ  経営計画の策定に際して、将来の様々な場合を想定した複数のシナリオを描い  
て分析することによって、起こりそうな未来を確定することができる。  
  
オ  経営計画の進行を本社の計画部門と事業部門が双方向的にコントロールするこ  
とは、事業の機会や脅威の発見には無効であるが、部門間の齟  
そ  
齬  
ご  
を把握するには  
有効である。  
`
        },
        {
                "mondaiId": 103250020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　プロダクト・ポートフォリオ・マネジメントに関する記述として、最も適切なものはどれか。  
  
ア  ｢金のなる木｣の事業や資金流出の小さい｢負け犬｣事業の中には市場成長率が低くとも高収益事業がある。  
  
<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

イ  投資家の注目を集める｢<hintTarget>花形製品</hintTarget>｣の事業は、マーケットシェアの維持に要する再投資を上回るキャッシュフローをもたらし、｢負け犬｣事業からの撤退を支える。  

<hint hidden='true'>**
❌金のなる木に関する記述です。
**</hint>

ウ  プロダクト・ポートフォリオ・マネジメントの考え方は、<hintTarget>外部からの</hintTarget>技術導入と<hintTarget>資金調達</hintTarget>とによる規模の経済の達成で優位性を構築する業界にも適用できる。  

<hint hidden='true'>**
❌下図のとおり、社内の事業である金のなる木の事業で得た資金を、花形や問題児に配分するため、外部ではなく内部の資金調達です。**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/103003_1.png" target="_blank" >
<img src="assets/img/kaisetu/103003_1.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>

エ  プロダクト・ポートフォリオ・マネジメントの考え方は、<hintTarget>製品市場の定義とはかかわりなく</hintTarget>、相対的なマーケットシェアが小さくとも大きなキャッシュフローを生み出すケースにも適用できる。  

<hint hidden='true'>**
❌PPMは基本的に、市場シェアや市場成長率が優位性につながる製品市場を前提としています。例えばブランド品のように、市場シェアや市場成長率が必ずしも優位性につながらず、PPMが適さないケースがあります。
**</hint>

`
        },
        {
                "mondaiId": 103250030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業は収益を確保するべく、活動JオペレーションKの効率を高めようとする。オ  
ペレーション効率の特徴に関する記述として、最も適切なものはどれか。  
  
ア  オペレーション効率とは、ライバル企業と異なる活動を効率的な方法で行うこ  
とである。  
  
イ  オペレーション効率における競争は、生産性を改善しながら、優れた収益性を  
長期にわたって企業にもたらす。  
  
ウ  オペレーション効率の差異がもたらす収益性は、企業間の差別化のレベルに直  
接的に影響を与えることはないが、コスト優位に影響を与える。  
  
エ  オペレーション効率は、企業が投入した資源を有効に活用できるような活動の  
すべてを指している。  
  
オ  オペレーション効率を改善するべく、他社をベンチマークするほど、企業の活  
動は似通ってくるので、両社の戦略の差異はなくなる。  
`
        },
        {
                "mondaiId": 103250040,
                "name": "第4問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
他社と連携を考慮する企業にとって、企業としての独立性を維持し、企業間に緩  
やかで柔軟な結びつきをつくるには、戦略的提携が有効な戦略オプションのひとつ  
である。戦略的提携に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  企業の評判に悪影響が起こる可能性は、戦略的提携における裏切りのインセン  
ティブを抑制する要素となる。  
  
イ  戦略的提携が希少性を有しても、低コストでの代替が可能であれば、その戦略  
的提携は持続的な競争優位をもたらさない。  
  
ウ  戦略的提携によって、新たな業界もしくは業界内の新しいセグメントへ低コス  
トで参入しようとするのは、企業間のシナジーを活用する試みとなる。  
  
エ  戦略的提携を構築する際、その主要な課題はパートナーが提携関係を裏切る可  
能性を最小化しつつ、提携による協力から得られる恩恵を最大限に享受すること  
である。  
  
オ  内部開発による範囲の経済を実現するコストが戦略的提携によるコストよりも  
小さい場合、内部開発は戦略的提携の代替とはならない。  
`
        },
        {
                "mondaiId": 103250050,
                "name": "第5問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A社は医療分野での先端的な製品開発を通じて社会に貢献するという理念の下  
で、現在の医療機器事業に加えて新薬開発の支援や再生医療の分野を包含した将来  
的なドメインの定義を企図している。企業ドメインと事業ドメインの決定に関する  
記述として、最も適切なものはどれか。  
  
ア  企業ドメインの決定は、現状追認ではなく将来の方向性を明示しているが、注  
意の焦点を絞り込んで資源分散を防止するのには適さない。  
  
イ  企業ドメインの決定は、差別化の基本方針を提供し、新たに進出する事業の中  
心となる顧客セグメントの選択の判断に影響する。  
  
ウ  企業ドメインの決定は、将来の企業のあるべき姿や経営理念を包含している生  
存領域を示すが、現在の生存領域や事業分野との関連性は示していない。  
  
エ  事業ドメインの決定は、将来手がける事業をどう定義するかの決定であり、企  
業戦略策定の第一歩として競争戦略を結びつける役割を果たす。  
  
オ  事業ドメインは、全社的な資源配分に影響を受けるため、企業ドメインの決定  
に合わせて見直すこともありうる。  
`
        },
        {
                "mondaiId": 103250061,
                "name": "第6問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
①  
企業の価値連鎖の中の活動にどこまで携わるかによって、垂直統合の程度は異な  
る。垂直統合は、企業が経済的な取引を管理・統治する重要な方法であるが、企業  
によっては活用可能な管理・統治のための選択肢のひとつにすぎない。  
企業が経済的な取引を管理する際に実施する統治選択JgovernancechoiceKにつ  
いてはオプションを持っているのが通常である。その内容を垂直統合か非垂直統合  
かによって大きく\u000fつに分けた場合、非垂直統合による管理・統治の方法は、さら  
に逐次契約JsequentialcontractingK、  
②  
完備契約Jcompletecontingentclaims  
contractsK、スポット市場契約Jspot-marketcontractKなどに分類できる。  
（設問1）K  
文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  企業が価値連鎖の中で携わる活動の数は一定で安定する必要があるが、価値  
連鎖上で高付加価値を生み出している活動は垂直統合に適している。  
  
イ  企業が価値連鎖の中で携わる活動の数は一定で安定する必要があるが、清涼  
飲料水の生産者が独立したフランチャイジーだったボトラーと戦略的な提携を  
始めるように前方垂直統合を行う例もある。  
  
ウ  企業が価値連鎖の中で携わる活動の数はその増減から垂直統合度は推測でき  
ないが、価値連鎖で統合されている活動に関する情報開示があれば垂直統合度  
のおおよその見当はつく。  
  
エ  自社の境界外に当該事業にかかわる価値創出活動の多くを出している企業は  
売上高付加価値率が低く、垂直統合度は低いレベルにある。  
`
        },
        {
                "mondaiId": 103250062,
                "name": "第6問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部②の完備契約とスポット市場契約に関する記述として、最も適切  
なものはどれか。  
  
ア  完備契約は、契約履行の詳細なモニタリングと、取引主体が契約上の義務を  
果たさない場合に法的な制裁が科されるという脅威で機会主義をコントロール  
できる。  
  
イ  完備契約は、取引主体の権利と義務を詳細に特定している契約であるが、取  
引において将来いくつかの異なる展開を示す可能性は想定していない。  
  
ウ  スポット市場契約では、複雑な契約書の作成や履行は必要がなく、多数の買  
い手と売り手が存在すれば機会主義の脅威が小さくなる。  
  
エ  スポット市場契約は、市場で取引される製品やサービスの品質確認に大きな  
コストをかければ、機会主義的な行動の脅威は小さくなる。  
  
オ  スポット市場契約は、市場で取引される製品やサービスの品質が低いコスト  
で保証され、取引の相手が限られている場合には経済的な取引を管理・統治す  
る適切な方法である。  
`
        },
        {
                "mondaiId": 103250071,
                "name": "第7問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
完成品メーカーと部品メーカーの取引関係に関する次の文章を読んで、下記の設  
問に答えよ。  
①  
完成品メーカーと部品メーカーとの取引関係は、両社が属する業界の競争状況や  
為替相場などの影響を受けながら複雑に変化している。完成品メーカーがこれまで  
の取引関係を見直して、新たな部品メーカーとの取引を検討したり、あるいは完成  
品メーカーが外部に発注していた部品を内製化することは頻繁に起こることであ  
る。このような取引関係の変化に対応して、  
②  
部品メーカーは完成品メーカーに対し  
て様々な手を打つことになる。  
（設問1）K  
文中の下線部①に関する記述として、最も適切なものはどれか。  
  
ア  ある部品の発注先を分散することによって、特定の部品メーカーから大量に  
調達する場合よりも、部品メーカー\f社当たりの生産負担が軽減され、部品コ  
ストが低下する。  
  
イ  外注する部品について発注先を多様化して競わせることによって、部品メー  
カーの忠誠心を高め、納入部品の価格を低く抑えることができる。  
  
ウ  業界共通の汎用部品の場合、専門部品メーカー数社に発注を集約すれば、そ  
のメーカーに独自能力が蓄積され、完成品メーカーの交渉力が低下する。  
  
エ  重要な部品について、完成品メーカーが発注先の部品メーカーを増やせば、  
調達部品の発注明細の標準化や取引条件の単純化が進み、調達の管理コストが  
下がる。  
  
オ  重要な部品については複数の会社に分散発注することで、部品メーカーの競  
争による品質の向上が期待でき、不測の事態による供給不足にも対応できる。  
`
        },
        {
                "mondaiId": 103250072,
                "name": "第7問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部②に関する記述として、最も適切なものはどれか。  
  
ア  完成品メーカーからの受注量を拡大して、現行の技術に依拠した生産の範囲  
の経済を発揮して、完成品メーカーへの交渉力を高める。  
  
イ  完成品メーカー向けの部品の特殊な生産設備への投資によって、部品���値下  
げ圧力や取引先の切り替えに対抗する。  
  
ウ  系列部品メーカーの場合、自社の生産技術やノウハウをブラックボックス化  
して、完成品メーカーの製品開発に積極的に参加する機会を増やす。  
  
エ  自社の特殊な生産設備による部品が完成品の性能・機能にとって不可欠な役  
割を果たす場合、その生産能力増強については完成品メーカーからの投資負担  
を求めることがで��る。  
  
オ  部品メーカーは、自社の設計による部品の生産納入を図る貸与図方式への転  
換を図ることで、継続的な取引を確保できるようになる。  
`
        },
        {
                "mondaiId": 103250080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品の設計が、部品間のインターフェースが単純なモジュラー的な場合と、複雑  
で調整が必要な擦り合わせ的な場合とで、製品開発や技術開発の進め方が異なる。  
モジュラー的な製品開発や技術開発に関する記述として、最も適切なものはどれ  
か。  
  
ア  モジュール部品を多様に組み合わせて得られる製品は、低価格・高機能を容易  
に実現でき、差別化による高い収益性を発揮できる。  
  
イ  モジュラー化の進展によって、自社固有の技術開発余地が狭まり、標準部品を  
使った製品間の競争が激化し、価格競争が激しくなる。  
  
ウ  モジュラー的な製品開発では、多様な部品を幅広く組み合わせるので、技術開  
発と製品開発が緊密に連携することが不可欠になる。  
  
エ  モジュラー的な製品では、モジュール部品を広く外部から調達することが可能  
になるので、これまでの社内のモジュール部品の生産設備は埋没原価になる。  
  
オ  モジュラー的な製品は、技術を持たない企業の参入可能性を高めるが、先発企  
業はシステム統合技術で先行するので、市場シェアには大きな影響を与えない。  
`
        },
        {
                "mondaiId": 103250090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
産業の空洞化が進行したり、国内市場が縮小したために、国内にとどまる中小企  
業は販売の低迷に直面する例が多くなっている。他方、拡大するアジアの市場を目  
指して海外進出する企業にも数々の問題が発生している。このような状況に直面す  
る中小企業の対応に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  アジアの進出予定国のカントリーリスクを分析するとともに、現地人の採用は  
生産現場の雇用期限付きの賃労働者に限定し、現地人の幹部や現場指導者の登用  
は能力と適性を見て判断する。  
  
イ  親企業の要請に応えて海外進出する場合、親企業の指導や支援を受けることが  
できることを前提に、外国企業向け工業団地に工場を設け、そこに償却済みの設  
備を持ち込んで、単純な工程からなる少数の生産品目から開始する。  
  
ウ  海外から安価な原材料や部品を輸入して販売コストの低減を図るとともに、余  
剰人員の合理化による賃金コストの削減を図り、収益の改善を目指す。  
  
エ  特定の工業部品の製造販売に特化していたために、その部品の売上が低迷した  
中小企業は、生産設備をすべて売却して、アジアの新興国で評判の汎用商品を輸  
入して流通市場に参入するという成長戦略を展開する。  
`
        },
        {
                "mondaiId": 103250101,
                "name": "第10問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
中小企業が同族経営である場合、戦略的問題に関する意思決定は創業者と創業者  
一族が中心となり、外部の利害関係者の影響力は限定的であることが多い。  
老舗と呼ばれる中小企業B社は、代々受け継ぐ製法や技法による生産品を中心  
にリピーターとなる顧客の支持を得ている。株式上場はしていないがその検討をし  
ており、諸外国のガバナンス機構も調査している。  
B社は、新しい品目や製造プロセスの改良に関して、外部から技術導入や人材の  
中途採用を実施してきたが、創業者以来の価値観や行動規範の理解を第一に求め、  
創業者の直系の現社長と役員の過半数を占める創業者一族の同族経営として従業員  
との一体感を重視してきた。危機的な状況も乗り切ってきたが、最近では、過去に  
危機からB社を救った伝統的な考え方に基づく事業戦略の策定がうまくいかなく  
なってきた。  
（設問1）K  
老舗の中小企業B社の組織文化に関する記述として、最も不適切なものはど  
れか。  
  
ア  創業以来の歴史では、創業者の選択した戦略的な問題の解決法が効果を発揮  
してきたため、その解決法が常套手段として繰り返し用いられて組み込まれて  
いる。  
  
イ  創業以来の歴史において、外部から導入した製造プロセスの改良技術に基づ  
き、技術関係部門同士の連携による問題解決が定型化されている。  
  
ウ  創業以来の歴史において、外部から招いた人材のほとんどは意思決定プロセ  
スに同化し、創業者一族の戦略を十分に理解するようになった。  
  
エ  創業者とその一族の経験した過去の事業戦略の成功が、現在の戦略上の失策  
の原因になっているのに、誰も認めようとしない。  
  
オ  組織として確立した問題解決法は、創業者の意向を大きく反映したものであ  
り、特定の問題解決や特別任務の遂行への対処が求められた創業期に生まれて  
いる。  
`
        },
        {
                "mondaiId": 103250102,
                "name": "第10問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部に関して、コーポレートガバナンスとその改革に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  B社のような同族経営では、株式公開によって事業規模の拡大とともに株式  
の分散化が生じ、創業者一族の影響力が低下し、機関投資家などの比重が高ま  
ることを懸念する場合が多い。  
  
イ  アメリカ型のガバナンスにならった改革では、社長の権限の分散と牽制が鍵  
であり、指名委員会、報酬委員会、監査委員会の設置などが企図されている。  
  
ウ  多くの株主が株式を手放すことで経営者責任を問い、経営者を交代へ追い込  
むウォールストリートルールは機関投資家が多くの株を所有する現実の下では  
ほとんど期待できない。  
  
エ  現在の会社法の委員会設置会社制度で業務遂行を委ねられる執行役員は、代  
表取締役の指揮下で業務執行の一部を担当する。  
  
オ  ドイツ型のガバナンスでは、株主総会で選出された株主と労働者の代表から  
なる監査役会が最高決定機関として取締役の任免と監督を行うが、形式的には  
株主と労働者が主権を分かち合っている。  
`
        },
        {
                "mondaiId": 103250110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業組織を設計するには、市場環境の変化、技術革新の速度、生産システムや職  
務特性、部門間関係など様々な変数に配慮しなければならない。組織設計に関する  
記述として、最も適切なものはどれか。  
  
ア  航空会社などのサービス技術は、顧客に対する無形のアウトプットを迅速に提  
供することが求められるため、ルーティン化することは困難で、サービス現場で  
の対人スキルが重視される。  
  
イ  自動車組立てラインのように、部門間を業務が一方向的に流れて行く関係にあ  
る場合には、手順の規則化や事前の計画が重要で、部門間調整は上からのコミュ  
ニケーションが有効である。  
  
ウ  熟練技術を要する職務は、戦略立案ほど非ルーティン化の程度は高くないた  
め、公式のトレーニングや文書化したマニュアルが重視される。  
  
エ  新製品開発などの補完的相互作用が必要な部門では、機能横断的なチームや非  
公式の対面的コミュニケーションが重視される。  
  
オ  特定の注文品顧客を対象とした小バッチ生産システムでは、機械的マネジメン  
トシステムが適している。  
`
        },
        {
                "mondaiId": 103250120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織の中では、個人個人の努力の総和より大きい業績を達成するために、しばし  
ばチーム活動が利用される。チーム活動に関する記述として、最も適切なものはど  
れか。  
  
ア  ある業務を個人よりもチームが担った方が良いのは、不確実性が低く、個人の  
目標を超えた共通目標が必要なほど業務が複雑な場合である。  
  
イ  チームの業績を高めるためには、異なるパーソナリティのメンバーが参加する  
ことが望ましく、チームに対する忠誠心が高いメンバーと低いメンバーが適切に  
組み合わされることが必要である。  
  
ウ  チームのメンバーに対する金銭的報酬システムとして個人別業績評価を採用す  
ると、チームの成果よりも個人の成果を優先してしまうため、チームメンバーに  
平等な固定給の報酬システムが、チームの業績を高める。  
  
エ  チームレベルのアカウンタビリティを明確にし、チームメンバーがフリーライ  
ドする可能性を避けるためには、個人レベルのアカウンタビリティを明確にする  
必要がある。  
  
オ  チームを効果的に管理するためには、個人間のコンフリクトをなくすように、  
各メンバーの役割は相互独立に設計し、相互依存度を最小限に抑える必要があ  
る。  
`
        },
        {
                "mondaiId": 103250130,
                "name": "第13問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
期待理論に基づいたリーダーシップ行動を説明するものとして、最も適切なもの  
はどれか。  
  
ア  与えられた目標にとらわれることがないよう、報酬を目標の達成と切り離して  
処遇する。  
  
イ  結果をあまり気にせず、まず行動を起こしてみるように従業員に働きかける。  
  
ウ  どうすれば目標を達成できるかという戦略を、チーム全員で考えさせる支持的  
態度を取る。  
  
エ  目標達成のプロセスはともあれ、目標それ自体の社会的意義を共有させる。  
  
オ  目標を実現することによって得られる報酬が、いかに魅力的なものであるのか  
を説得する。`
        },
        {
                "mondaiId": 103250140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業は成長することによって、複数の製品やサービスを提供する事業部など、専  
門化された複数の組織単位からなる複合組織になっていくことが知られている。複  
合組織となった企業が、環境変化に対して組織的に適応するために必要な行動とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  環境分析に専門部署を割り当て、変化の渦中でも持続可能な長期的戦略計画を  
立てさせる。  
  
イ  既存の事業部に戦略的な重点課題の遂行を割り当て、責任を持って取り組ませ  
る。  
  
ウ  通常業務と新たな利益を創出する業務のための予算計画を分けることによっ  
て、常に環境変化に反応できるような組織体制と組織文化を醸成する。  
  
エ  複合組織の経営者は、専門化された組織間の調整を通じて、企業の環境適応を  
図る。  
`
        },
        {
                "mondaiId": 103250150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業間取引関係は、それぞれの企業の渉外担当者JboundarypersonnelKの行動に  
よって担われている。この渉外担当者の役割に関する記述として、最も不適切なも  
のはどれか。  
  
ア  渉外担当者は、外部環境の情報に接することができるため、組織内の各部門に  
対し不確実性を削減する機能を持っており、しばしば組織変革にとって重要な誘  
導者となる。  
  
イ  渉外担当者は、外部組織が自らの組織に対して影響力を行使する際にターゲッ  
トとなるため、それに対応するのに十分な交渉力を持つ必要がある。  
  
ウ  渉外担当者は、外部組織との連結環としての役割を持つとともに、外部組織の  
脅威から自らの組織を防衛する境界維持的機能を果たしている。  
  
エ  渉外担当者は、外部に対して組織を代表する顔であるため、組織内部の価値観  
や規範、組織文化などからは自由に、外部組織の要請にあわせて臨機応変に行動  
しなければならない。  
  
オ  渉外担当者は、組織内部でなされた組織的意思決定を、具体的な環境適応行動  
として実行する重要な役割を担っている。  
`
        },
        {
                "mondaiId": 103250161,
                "name": "第16問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
C社の研究開発部門で働く研究員は、公式に仕事として与えられた研究開発テー  
マ以外にも、自らの興味や関心に基づき、非公式に新しい研究開発テーマを探索し  
ていた。もちろん、公式な仕事として与えられたわけではないので、新しい研究開  
発テーマを探索する場所や設備などの作業環境は良好なものではなかったし、昼休  
みや終業後の時間が費やされていた。  
このことをインフォーマルに伝え聞いた経営者は、研究員による自発的活動をよ  
り活発なものにするために、新たな研究開発テーマの探索に必要な作業環境を改善  
するとともに、就業時間外に行った活動にも金銭的報酬を支払う制度を導入するこ  
とにした。  
ところが、新制度を導入した後には、研究員は昼休みや終業後の時間に、新しい  
研究開発テーマを探索することがめっきり少なくなってしまった。研究員にアンケ  
ートを取ってみると、作業環境の改善によって満足度が上がったわけでもなさそう  
であった。  
（設問1）K  
作業環境が改善されたにもかかわらず、研究員の満足度が改善されなかった理  
由として、最も適切なものはどれか。  
  
ア  経営者の判断によって行われた作業環境の改善内容が、研究員が望んでいた  
ものとは異なっていたから。  
  
イ  作業環境に対する不満足の解消と、新たな研究開発テーマの探索を通じて得  
られる満足は別問題だから。  
  
ウ  作業環境の改善内容が、研究員が望んでいた希求水準を下回っていたから。  
  
エ  作業環境の改善を通じて低次欲求を充足しても、満足にはつながらないから。  
  
オ  作業環境を経営者が改善してくれたこと自体が、研究員に対するホーソン効  
果を生みだしたから。  
`
        },
        {
                "mondaiId": 103250162,
                "name": "第16問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
昼休みや終業後の時間に、研究員が自発的に新たな研究開発テーマを探索しな  
くなった。その理由として、<u>最も不適切なものはどれか</u>。  
  
ア  あくまで自らの意志で行っていたということを、金銭的報酬が与えられたこ  
とによって見失ってしまったから。  
  
イ  新しい研究開発テーマの探索が、金銭的報酬のためであると知覚されるよう  
になったから。  
  
ウ  困難な仕事内容を考えれば、新しい研究開発テーマの探索の対価としてはふ  
さわしくないと感じられたから。  
  
エ  制度設計をした経営者が、研究員の自発的行動をコントロールするためでは  
なく、研究員に報いるためのものであることをきちんと説明しなかったから。`
        },
        {
                "mondaiId": 103250170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
国際化する企業間競争において競争優位を獲得・維持するには、コスト削減能力  
だけでなく、知識基盤の裏付けを持ったイノベーションの遂行能力が必要不可欠で  
ある。イノベーションそのものを組織学習プロセスとして考えた場合、必要なメカ  
ニズムとして、<u>最も不適切なものはどれか</u>。  
  
ア  結果の可視化とストーリー性を持ったリッチな分析  
  
イ  現場ライン部門への権限委譲・能力開発にともなうスタッフ部門の削減  
  
ウ  様々な視点を持った参加者の活用  
  
エ  試行・実験を促進するような評価体系の整備  
  
オ  成功・失敗経験のデータベース化と情報の共有  
`
        },
        {
                "mondaiId": 103250180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、横軸に時間、縦軸に重要な革新の頻度をとり、製品革新と工程革新の観  
点から見た生産ユニットJproductiveunitKの進化過程と生産性のジレンマを描いた  
ものである。  
ドミナントデザインの確立までを流動化段階、その後製品革新の頻度が減少しつ  
つ工程革新が進む段階を成長段階、もはや製品革新は末端技術に限られ工程革新も  
成熟してきた段階を特定化段階と区分した場合、それぞれの段階に適した組織に関  
する記述として最も適切なものを下記の解答群から選べ。  
ドミナントデザインの確立  
工程革新  
製品革新  
時間  
革新の頻度  
流動化段階成長段階特定化段階  

[解答群]  
  
ア  成長段階後期になると製品アーキテクチャは安定し、市場規模は縮小し始め  
るので、業界として部品ごとに水平分業関係を築き、部品間のインターフェー  
スについて規格を統一していくことが重要な成功要因になる。  
  
イ  製品革新の頻度が少なくなってくると、しだいに工程革新へと関心がシフト  
していき、生産性が次第に低下し、価格競争は少なくなるため、企業間で水平  
的な分業関係が構築しやすくなる。  
        
  
ウ  特定化段階には企業間の分業が進み、製品・工程とも革新の頻度は低くなっ  
てしまうが、これを再び流動化段階に脱成熟させるには、一定以上の垂直統合  
が必要となる。  
  
エ  ドミナントデザインが確立される前の流動化段階では、市場規模も大きくな  
く、製品アーキテクチャの各要素の不確定性が高いため、\f企業で新製品開発  
のリスクを負うのではなく、部品ごとに水平分業をして業界全体で新製品の開  
発を行うべきである。  
  
オ  ひとたびドミナントデザインが確立されると、製品アーキテクチャが確立す  
るため、部品メーカーを内部化する垂直統合戦略をとり、効率的な生産ができ  
るよう組織を機能別に編成すべきである。`
        },
        {
                "mondaiId": 103250190,
                "name": "第19問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
今日、雇用者である専門経営者や管理者が様々な理由で企業を支配するようになり、経営者の独走を制するためのガバナンスが求められるようになっている。専門経営者や管理者による企業の支配に関する記述として、最も不適切なものはどれか。  

<hint hidden='true'>**
企業の所有と経営は、以下のように分類することができます。  
（１）所有と経営が一体の状態  
例：個人事業など、所有者が経営も行っている状態  
（２）所有と経営の分離  
例：企業者一族など限られた株主が所有し、経営は雇用者である専門経営者や管理者が行う  
（３）経営者支配の状態  
例：株を公開するなど、多様な株主が所有する。経営は雇用者である専門経営者や管理者が行う  
　  
この問題は、上記の（３）にあたる、専門経営者や管理者による企業の支配（アドルフ・バーリとガーディナー・ミーンズにより指摘された概念）について問う問題です。    
**</hint>  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】  上記（３）：アドルフ・バーリとガーディナー・ミーンズにより指摘された概念  
<a href="http://kccn.konan-u.ac.jp/konan/keiei/topics/topic59.htm" target="_blank">  
<img src="http://kccn.konan-u.ac.jp/konan/keiei/topics/image/tit-t59.gif"/>  
(外部リンクを開きます　
甲南大学：所有と支配の分離</a>  
</div>
</hint>  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】  上記（２）の代表的なデメリットである、プリンシパル・エージェント問題  
<a href="https://diamond.jp/articles/-/232438" target="_blank">  
<img src="https://dol.ismcdn.jp/mwimgs/4/9/670m/img_49c991a4abaf181f36c8583dd91c0d1e456545.jpg"/>  
(外部リンクを開きます　
Diamond online：部下を思い通りに動かすための2つのポイント)</a>  
</div>
</hint>  


ア  株価による経営評価が行き過ぎることで、CSRが果たしにくくなった。  
<hint hidden='true'>**
⭕適切です。  
上記（１）や（２）の状態では、株主は、CSRのように中長期的に株価を上げる活動を行います。  
しかし、（３）における株主は、株価の短期的な変動に応じて株を売り買いし、経営者はその株価による経営評価に基づき経営するため、CSRが果たしにくくなりました。
**</hint>  

イ  企業規模の拡大に伴う株式の分散によって、経営者が企業の法的所有者である株主から直接的な影響を受けにくくなった。  
<hint hidden='true'>**
⭕適切です。  
上記（３）の状態で株価が下がった場合、株主は株を売ります。（３）の株主は、（一般的に）経営に関する知識も関心が低く、株式が分散しており経営に与えられる影響は小さいためです。  
いっぽう、上記（２）の状態で株価が下がった場合、  
・多くの株を少数の株主が持っているため、影響を与えやすい  
・株の流動性が低いため株を売るという選択はしにくく、経営改善するよう影響を与えて保有する株の価値を上げるほうが合理的  
となります
**</hint>  
  
ウ  企業で使用される技術の高度化によって、技術者集団ないし専門家に対する依存が大きくなった。  
<hint hidden='true'>**
⭕適切です。  
上記（２）（３）とも、経営に求められる技術が高度化し、法的所有者である株主が「経営」まで行うことが困難になったため、専門知識について、経営を支配する経営者に依存するようになりました。  
　（１）の所有と経営が一体の状態と比べると、経営環境や生産技術の専門性を高めることによって、高度化・変化のスピードに追従できるようになったとも言えます。
**</hint>  
  
エ  様々な生産技術に精通する管理者が、企業全体の生産プロセスに対して大きな影響力を持つようになった。  
<hint hidden='true'>**
⭕管理者についても、選択肢ウと同様に専門性が高まります。
**</hint>  
  
オ  新製品やサービスを市場に提供し、競争を通じて利潤を追求する企業家的な行動が一般的なものとなった。  
<hint hidden='true'>**
❌不適切です。  
アドルフ・バーリとガーディナー・ミーンズの1930年の調査では、アメリカ企業は４割（資産額で６割）が企業経営が専門化、複雑化した結果、企業の外で出資だけをしている株主には実質的に企業経営をチェックできるだけの知識も時間が持てない「所有と支配が分離した経営者支配」になっていると指摘しています。
経営者支配の状態では、株主は経営に関する知識・関心・影響力が低く、株価の短期的な変動に注目するため、設問文にある「経営者の独走」が発生しやすい状態です。  
そのため、経営者が株価対策を優先し、選択肢オにあるような市場への製品提供や競争はむしろ弱まることが懸念されます。
**</hint>

`
        },
        {
                "mondaiId": 103250200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
昭和63年の労働基準法の改正時に大幅な労働時間の弾力化が図られたが、その  
後、経済社会の発展に対応して、弾力的な労働時間制度が拡充されてきた。弾力的  
な労働時間制度に関する記述として、最も適切なものはどれか。  
  
ア  企画業務型裁量労働制は、重要な事業方針等を決定する事業場において、事業  
運営に係る企画、立案、調査及び分析の業務に従事するホワイトカラー労働者に  
適用されるが、対象者が一定の年収以上の者に限定されているため、あまり普及  
していない。  
  
イ  専門業務型裁量労働制を新たに導入するためには、事業場の労働者の過半数で  
組織された労働組合又は労働者の過半数を代表する者との間で労使協定を締結  
し、かつ、対象業務に従事する労働者の同意を得ることが必要である。  
  
ウ  フレックスタイム制は、就業規則等で\fカ月以内の一定の期間の総労働時間を  
定めておき、労働者はその総労働時間の範囲内で、各日の始業又は終業の時刻を  
選択して働くという労働時間制度であり、時差出勤J時差勤務Kもその一種であ  
る。  
  
エ  労働者が自宅で情報通信機器を用いて業務を行う、いわゆる｢在宅勤務｣につい  
ても、当該業務が起居寝食等私生活を営む自宅で行われ、かつ当該通信機器が使  
用者の指示によって常時通信可能な状態におかれておらず、また、当該業務が随  
時使用者の具体的な指示に基づいて行われていない場合には、事業場外のみなし  
労働時間制を適用することができる。  
`
        },
        {
                "mondaiId": 103250210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働基準法又は育児・介護休業法に定める休業に関する記述として、最も不適切  
なものはどれか。  
  
ア  育児休業をする厚生年金保険の被保険者を使用する事業所の事業主が、厚生労  
働大臣に申出をしたときは、当該育児休業を開始した日の属する月から、その休  
業が終了する日の翌日が属する月の前月までに係る保険料の徴収は免除される。  
  
イ  介護休業とは、労働者が要介護状態にある対象家族を介護するためにする休業  
をいうが、介護休業の回数は、特別の事情がない限り、同一の対象家族につい  
て、\f要介護状態ごとに\f回とされ、日数は、その回数が\u000f回以上に及ぶ場合に  
も、最初の介護休業を開始した日から通算して93日までとされている。  
  
ウ  労働基準法では、産前休業は\u0010週間J多胎妊娠の場合は14週間K、産後休業は  
原則(週間とされているが、分娩の日が予定より早まったために産前休業が)週  
間しかとれなかった場合には、産後休業を10週間与えなければならない。  
  
エ  労働基準法は、労働者が業務上負傷し、又は疾病にかかり、療養のために休業  
する場合には、使用者は、その療養の期間中平均賃金の100分の60の休業補償  
を行わなければならないこととしているが、労働者災害補償保険からこの休業補  
償に相当する給付が行われる場合には、使用者は当該休業補償の責を免れる。  
`
        },
        {
                "mondaiId": 103250220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働契約に関する記述として、最も適切なものはどれか。  
  
ア  使用者が、就業規則を変更し、変更後の就業規則を労働者に周知させ、かつそ  
の就業規則の変更が、労働者の受ける不利益の程度等の事情に照らして合理的な  
ものであるときは、労働契約の内容である労働条件は、当該変更後の就業規則の  
定めるところによる。  
  
イ  使用者は、労働者と有期労働契約を締結したときは、その契約期間が終了する  
までは、客観的に合理的な理由があり、社会通念上相当であると認められる場合  
でなければ解雇することができない。  
  
ウ  有期労働契約が\u000f回以上繰り返され、同一の使用者との間で締結された通算契  
約期間が5年を超える労働者が、労働契約が満了する日までの間に、無期労働契  
約への転換の申込みをしたときは、使用者は当該申込みを承諾したものとみなさ  
れるが、この場合、転換後の労働条件は、当該事業場における無期労働契約で働  
く同種の労働者と同一のものとしなければならない。  
  
エ  労働者を定年後に子会社に転籍させ、当該子会社で有期労働契約によって継続  
雇用する場合、当該労働者の業務内容及び当該業務に伴う責任の程度に変更がな  
いときは、継続雇用後の労働条件は、労働契約の期間を除き、当該子会社の無期  
労働契約の労働者の労働条件と相違することは認められない。  
`
        },
        {
                "mondaiId": 103250230,
                "name": "第23問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
賃金に関する基本用語の定義として、<u>最も不適切なものはどれか</u>。  
  
ア  基本給は、毎月支払われる賃金の中で最も基本的な部分を占め、年齢、学歴、  
勤続年数、経験、能力、資格、地位、職務、業績など、労働者本人の属性又は労  
働者の従事する職務に伴う要素によって算定される賃金で、原則として同じ賃金  
体系が適用される労働者全員に支給されるものをいう。  
  
イ  賞与は、ボーナス、一時金、期末手当などの呼称で呼ばれることがあるが、労  
働基準法上の賞与は、定期又は臨時に、原則として労働者の勤務成績及び経営状  
態等に応じて支給されるものであって、その額があらかじめ確定されていないも  
のをいう。  
  
ウ  定期昇給は、あらかじめ定められた賃金表がある場合にはそれに基づいて、賃  
金表がない場合には、年齢や勤続年数、考課査定などをもとに、毎年\f回以上定  
期的に個別賃金を引き上げるものであるのに対し、ベースアップは賃金水準を底  
上げするもので、賃金表がある場合には賃金表そのものを書き換えることにより  
行われる。  
  
エ  モデル賃金とは、学制どおりに進級して正規に学校を卒業し、直ちに入社して  
引き続き同一企業に勤務し、その後標準的に昇進・昇格、昇給して、世帯形成も  
標準的に経過している標準者の賃金カーブをいう。  
  
オ  労働基準法上の平均賃金は、その算定事由の発生した日以前\u0005カ月間にその労  
働者に支払われた賃金の総額を、その期間の所定労働日数で除した金額のことで  
あり、解雇予告手当や休業手当、業務上の災害における災害補償の計算等に用い  
る。  
`
        },
        {
                "mondaiId": 103250241,
                "name": "第24問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
企業は、その利益実現のための要素のひとつとして売上高の変化に関心を持つ。  
そして、売上高の変化にどのように戦略的に対応していくかを意思決定することが  
求められる。  
ここで、  
企業の売上高暗対象市場規模庵市場シェア  
という定義式を用いることにする。この式の内容を図式化すると下図でとらえるこ  
とができる。これを見ると、  
①  
企業の売上高は対象とする市場全体の成長率だけでな  
く、市場シェアの変化によっても多様に変動することが分かる。また、このことは  
②  
企業の戦略適応の方法が異なることも示している。  
対象市場の成長率  
減少増加  
企  
業  
の  
市  
場  
シ  
ェ  
  
ア    
減  
少  
AB  
増  
加  
CD  
（設問1）K  
文中の下線部①に示す｢売上高の多様な変動｣について、図の各象限における売  
上高の変動パターンを検討した場合、最も適切なものはどれか。  
  
ア  象限Aでは、売上高は微減の方向へと向かっていく。  
  
イ  象限Bでは、売上高に変化が見られないことがある。  
  
ウ  象限Cでは、売上高は減少することになる。  
  
エ  象限Dでは、売上高が減少するケースも起こりうる。  
`
        },
        {
                "mondaiId": 103250242,
                "name": "第24問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部②に示す｢異なる戦略適応｣のパターンとそれらの特徴に関する記  
述として、最も適切なものはどれか。  
  
ア  象限Aでは、対象市場の適否についての分析を早急に進めるとともに競争  
戦略の再検討が求められる。  
  
イ  象限Bでは、対象市場の再選択が優先順位の高い作業となる。  
  
ウ  象限Bと象限Cの状態でそれぞれ同じ程度の売上増を目標として設定した  
場合、Bの方がより多くのマーケティング費用が必要となる。  
  
エ  象限Dでは、市場が飽和に達しつつあり、現状の戦略をさらに推進するこ  
とにはリスクが伴う。  
`
        },
        {
                "mondaiId": 103250251,
                "name": "第25問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
消費者の購買意思決定プロセスにおいては、特定の情報発信型消費者が他の消費  
者に対して強い影響力を持つことがある。このように情報源としてとくに重視され  
る人々はＡ集団と呼ばれる。この種の集団には憧れや分離の対象といった  
個々の消費者が直接的な接点を持たない対象だけでなく、学校や職場、サークルな  
どのように実際に消費者自身が所属している集団も含まれる。  
ロジャースによる普及理論は、Ｂが特定の製品を採用することが新製品  
普及の最初のステップであるとしている。ハイテク技術を観察対象としたムーアの  
研究では、この次の段階にくる初期多数派への普及がいかに速やかに行われるかが  
製品普及の分かれ道であるという結論が導かれている。この分かれ道はＣ  
と名付けられた。  
これに対してオピニオンリーダー理論では特定の製品分野についての深い専門知  
識を持った人々が、新しい製品に関する情報を収集し、自ら編集したメッセージで  
情報発信することの重要性が示されている。  オピニオンリーダーとは対照的に製品カテゴリ横断的な幅広い知識を持ち、さら  
には知識を伝える方法も幅広く持っていることに特徴づけられる情報発信型消費者  
はＤと呼ばれている。このタイプの消費者は、インターネット上のコミュ  
ニティやソーシャルメディア上で情報を拡散させるコミュニケーション機能を果た  
す極めて現代的な情報発信者といえるだろう。  
（設問1）K  
文中の空欄Ａ~Ｄに入る語句の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：参照Ｂ：イノベーターＣ：キャズム  
Ｄ：リードユーザー  
  
イ  Ａ：参照Ｂ：インキュベーターＣ：ディバイド  
Ｄ：マーケットメーカー  
        
  
ウ  Ａ：準拠Ｂ：イノベーターＣ：キャズム  
Ｄ：マーケットメイブン  
  
エ  Ａ：準拠Ｂ：リードユーザーＣ：ディバイド  
Ｄ：マーケットメーカー  
  
オ  Ａ：ミラーＢ：リードユーザーＣ：ディバイド  
Ｄ：マーケットメイブン`
        },
        {
                "mondaiId": 103250252,
                "name": "第25問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部に示す｢購買意思決定｣についての消費者または企業の行動に関連  
する記述として、最も適切なものはどれか。  
  
ア  ある小売商が店頭で靴下の｢よりどり\u0005点600円｣の販促を実施した。多くの  
消費者は売れ筋の商品だけでなく、単独の販売ランキング下位の商品も購買し  
ていた。これは、バラエティ・シーキング論の主張と一致する。  
  
イ  個々の消費者による購買行動はその人物の文化的、社会的、個人的、心理的  
な特性の強い影響を受けるが、マーケターに与えられた役割はこれらの特性を  
変容させることである。  
  
ウ  消費者がある製品に対して高い製品関与水準を持つとき、この消費者は自ら  
が蓄積した豊かな製品知識を容易に参照できるため、購買意思決定プロセスは  
単純化する。これは精緻化見込みモデルによる見解である。  
  
エ  生産財の購買は組織内の異なる部門や複数の階層から構成される購買センタ  
ーを通じて行われるため、購買主体は十分な知識を持って迅速な意思決定を下  
すことができる。  
`
        },
        {
                "mondaiId": 103250261,
                "name": "第26問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
清涼飲料水の中のあるカテゴリは、年間を通して様々な機会に私たちが楽しむ飲  
み物としてすっかり定着している。その生産量もこの10年あまりの期間に10倍以  
上にも増加した。他の主要な飲料カテゴリと比較しても群を抜く伸びである。飲料  
メーカーX社は\u000f年後を目途にこのカテゴリへの新規参入を検討している。  
それに先駆けて、X社のマーケティング部門では、  
①  
このカテゴリの製品市場に  
おける市場占有率J金額ベースKについてのデータ分析を行った。それをもとに、  
②  
こ  
の市場の上位メーカーへの集中度がどのような状態になっているのかを検討するこ  
とにした。この時のデータを簡易的に整理したものが下表である。表中の｢その他｣  
を分解してみると、30社がそれぞれ1％ずつの市場占有率を持つ構図が見られる。  
順位メーカー名市場占有率J％K  
J金額ベースK  
\fＡ社25  
\u000fＢ社15  
\u0005Ｃ社15  
)Ｄ社10  
\u0007Ｅ社5  
その他30  
合計100  
        
（設問1）K  
文中の下線部①に示す｢市場占有率｣に関する記述として、最も適切なものはど  
れか。  
  
ア  X社が参入を検討している製品市場での上位\u0007社による累積占有率は70％  
に達している。この市場で第)位に位置するD社の占有率は10％である。  
これをいわゆるプロダクト・ポートフォリオ・マネジメントでの相対シェアと  
して換算すると0.55となる。  
  
イ  寡占度が高い市場では、追随者JフォロワーKとしての新規参入が比較的容易  
である。  
  
ウ  国内のある製品市場においての個別メーカーによる金額ベースの市場占有率  
を算出するためには、a：｢自社の当該製品の国内向け出荷額｣、ｂ：｢当該製品  
に関する国内の全事業者による出荷額｣、ｃ：｢当該製品に関する海外への輸出  
額｣を用いる。算出式は、市場占有率J％K暗虻a／Jb＋cK飴庵100となる。  
  
エ  表中のE社はこのカテゴリにおいて極めて差別化水準の高い新製品を開発  
し、高価格の小売形態を中心とする販売経路政策を通じて、価格プレミアム化  
に成功した。このことから、出荷数量ベースでの同社の占有率は、売上高ベー  
スのそれと比べて低い値となっている。`
        },
        {
                "mondaiId": 103250262,
                "name": "第26問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部②に示されているように、X社のマーケティング部門はこの飲  
料水カテゴリの製品市場への新規参入に向けた検討を重ねている。その一環とし  
てハーフィンダール指数の算出を行った。その算出結果として、最も近いものは  
どれか。  
  
ア  約0.05  
  
イ  約0.12  
  
ウ  約0.33  
  
エ  約0.50  
`
        },
        {
                "mondaiId": 103250271,
                "name": "第27問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
ある金曜日の夕方、機械部品メーカーの\u000f代目経営者のYさんは取引先とのミ  
ーティングを終えると足早に家電専門店チェーンの大型店舗に立ち寄った。この店  
舗は駅に隣接したショッピング・センターJSCKのテナントとして出店している。  
Yさんは、取引先が国際展開をしていることがきっかけで自社の創業以来はじめ  
て海外市場へのアプローチに着手した。海外のエージェントとのリアルタイムの会  
議を円滑に行うために、翻訳機能付きの電子手帳の購入を検討している。  
いくつかの商品を比較している最中に、Yさんのスマートフォンにeメールが  
送られてきた。この家電専門店チェーンのウェブ店舗からのものだった。メールを  
あけてみると、数日前にYさんがスマートフォンを使ってこの小売業者のウェブ  
店舗で検索し、｢お気に入りJbookmarkK｣に登録していた電子手帳の詳細情報が記  
載されている。また、このSC内店舗での売場の位置と  
①  
実際にこの商品を購入し、  
使用している消費者によるレビューが紹介されている。メールを見ながら売場に移  
動し、この電子手帳を手に取ってみるとYさんが今必要としている機能が満載の  
商品であることが分かった。  
Yさんはおもむろにこの商品の型番をスマートフォンに入力し、検索をかけて  
みた。すると、別の家電専門店のウェブ店舗では全く同じ商品が5，000円安い価格  
で販売されていることが分かった。Yさんは、早速この電子手帳をスマートフォ  
ンサイト経由で注文し、クレジットカードで決済した。また、このネットショッピ  
ングで  
②  
Yさんは購入額の10％のポイントを獲得した。日曜日の朝、Yさんは電  
子手帳を受け取り、あれこれ操作を試し、海外エージェントとのミーティングで想  
定されるフレーズを学習した。  
        
（設問1）K  
文中に示すYさんの行動に関する記述として、最も適切なものはどれか。  
  
ア  YさんはSC内の家電専門店チェーンの店舗に立ち寄った際にこのチェーン  
のウェブ店舗からeメールを受け取ったことで、AIDMAでいえばM  
JMemoryKにあたる内容を活性化することができた。  
  
イ  Yさんは金曜日の夕方にSC内の家電専門店チェーンの店舗に立ち寄る前  
に、このチェーンのウェブ店舗で翻訳機能付きの電子手帳を網羅的に検索して  
いたので、買い物出向前に明確なブランドの選好マップが形成されていたとい  
ってよい。  
  
ウ  Yさんは店頭で受け取ったeメールを読むとすぐさま商品関連情報を検索  
し、電子手帳の購買にいたる意思決定を行った。この一連の流れの中でYさ  
んはコミュニケーションに対する消費者の反応J購買Kプロセスモデルのひとつ  
であるAISASに含まれるすべてのステップを踏んでいたといえる。  
  
エ  今回のYさんの電子手帳の購買プロセスの一部にも見られたような、｢実際  
の店舗で商品の実物展示を体験してから、より低い商品価格と消費者費用で同  
じ商品を購入することのできるウェブ店舗を探してそこで購買を行う｣タイプ  
の行為は一般にブラウジングJbrowsingKといわれている。`
        },
        {
                "mondaiId": 103250272,
                "name": "第27問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部①に示す｢消費者によるウェブ上のレビュー｣は、情報化時代にお  
けるある種の口  
クチ  
コミとしてとらえることができる。顧客満足と口コミに関する記  
述として、最も適切なものどれか。  
  
ア  Yさんが最終的に電子手帳を購入した家電専門店のウェブ店舗では、購入  
後の返品・交換時の送料や手数料を無料にするサービスを提供している。この  
サービスは消費者の認知的不協和をできる限り軽減・解消することを意図した  
ものであるといえる。  
        
  
イ  一般に消費者は満足時よりも不満足時に多くの口コミを行うといわれてい  
る。とくに消費者による熟知性が高いブランドでは、ネガティブな口コミによ  
って消費者の購買意図が顕著に低下する効果が見られるため注意が必要であ  
る。  
  
ウ  高水準の顧客満足が実現すると既存顧客の忠誠顧客化による反復購買が行わ  
れるようになるだけでなく、満足顧客による口コミが新規顧客の獲得を支援す  
るようになる。新規顧客獲得費用は既存顧客の維持費用よりも一般的に低いた  
め、企業は積極的に優良顧客による口コミの影響を活用することが望ましい。  
  
エ  顧客満足の理論によれば、顧客満足・不満足の程度は事前の期待度とは関係  
なく、対象商品の品質の良しあしの水準によるという点が強調されている。`
        },
        {
                "mondaiId": 103250273,
                "name": "第27問設問3",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005K  
文中の下線部②は、セールス・プロモーションJSPKに関連する記述である。  
SPに関する記述として、最も適切なものはどれか。  
  
ア  1970年代までは日本のメーカーにおいてSP費が広告費を上回る状況が続い  
ていたが、この30年間に数多くの企業がグローバル企業へと成長したことも  
あり、両者の比率が逆転している。  
  
イ  Yさんが以前に同じ家電専門店チェーンの実店舗でスマートフォンを購入  
した際、専用ケースをおまけとして受け取った。この種のSPは長期的なブラ  
ンド忠誠の醸成を支援する効果を持つ。  
  
ウ  購買金額の一定割合が付与されるポイントサービスは、主として将来の値引  
きを約束するSPであり、顧客の再来店を促す役割を果たしている。  
  
エ  メーカーによるインセンティブは消費者のみをターゲットとしており、具体  
的な活動には特売価格の提供、景品コンテスト、サンプリング、POP広告な  
どがある。  
`
        },
        {
                "mondaiId": 103250280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
Z氏はラーメン店のチェーン経営を行う人物であり、現在フランチャイジングを  
用いた海外市場への進出を計画している。｢フランチャイジング｣に関する記述とし  
て、最も適切なものはどれか。  
  
ア  Z氏の海外市場への進出においては、現地企業と合弁で現地本部を設立し、ま  
ずはこの現地本部との間でマスター・フランチャイジング契約を結び、そして、  
現地本部と他の事業者との間でサブ・フランチャイジング契約を結ぶ方法も選択  
肢のひとつである。  
  
イ  Z氏のラーメン店チェーンの国内の本部が進出先国でフランチャイジング参加  
の募集をかけ、現地事業者と直接フランチャイジング契約を締結する形態もZ  
氏がとりうる進出方法のひとつである。この方法はダイレクト・マーケティング  
と呼ばれる。  
  
ウ  フランチャイジングとはフランチャイジーと呼ばれる事業者がフランチャイザ  
ーと呼ばれる他の事業者との間に契約を結び、フランチャイザーに対して同一の  
  
イ  メージのもとに事業を行う権利をフランチャイジーが有償で与えるものであ  
る。  
  
エ  フランチャイジングを用いたチェーンストアオペレーションは、コーポレート  
チェーンと呼ばれており、一般的に事業の国際化にあたって積極的に活用されて  
いる。  
`
        },
        {
                "mondaiId": 103250290,
                "name": "第29問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄Ａ~Ｄに入る語句の組み合わせとして最も適切なものを、下記の  
解答群から選べ。  
P氏はある酒屋の)代目として店を継ぐことになった。\u0005代目からも聞いていた  
とおり、この店がある商店街は一昔前と比べると活気がなくなっている。  
P氏は自分の店の活性化策としてＡブランド商品を品揃えの中核に据  
え、贈答用の小物や日常的に使用できる気の利いた雑貨もそのラインアップに加え  
た。美術大学で産業デザインを学び、コンテスト入賞経験を持つP氏は仕入れた  
商品に装飾を施すなど、Ｂ加工による独自の付加価値づくりを重視し、地  
域の消費者のギフト需要を吸収するようになっていた。  
P氏はさらに先代の時代から懇意にしていた取引相手である中小の地方酒造メー  
カー数社の同世代経営者と連携を深めていく。P氏は商店街の懸賞企画の一環で地  
域消費者の家庭での食生活や外食の嗜好についての大規模なアンケート調査を実施  
した。その結果、この地域市場には飲食料品の消費について明確な消費者クラスタ  
ーが存在することが分かった。  
これを踏まえ、P氏は各酒造メーカーと原材料段階までさかのぼった共同商品開  
発に着手し、)種類の日本酒をＡブランド商品として導入した。その際、  
作り手の顔が見えるように、それぞれの酒造メーカーの企業名も併記する形のブラ  
ンド名称を採用した。このようなブランド表記はダブルＣと呼ばれる。  
P氏はこれら特異な商品を自店のＤブランドとして、商店街活性化のた  
めに活用しようと考えた。そして、地域の飲食店への卸売もスタートした。  

[解答群]  
  
ア  Ａ：地域Ｂ：流通Ｃ：マークＤ：ラグジュアリー  
  
イ  Ａ：ナショナルＢ：意匠Ｃ：マークＤ：小売店舗  
  
ウ  Ａ：プライベートＢ：意匠Ｃ：マークＤ：ストア  
  
エ  Ａ：プライベートＢ：自家Ｃ：コンテンツＤ：小売事業  
  
オ  Ａ：プライベートＢ：流通Ｃ：チョップＤ：ストア  
`
        },
        {
                "mondaiId": 103250301,
                "name": "第30問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
T社は大手自動車メーカーのアルミ部品などを製造する中小サプライヤーであ  
る。近年、製品納入先である自動車メーカーの販売動向が様々な政治・経済・社会  
的要因によって相当の影響を受け、その余波がT社に及ぶことが頻繁になってき  
ている。  
そこで、T社ではこの自動車メーカーに対する販売先依存度を下げるために、  
主体的に市場創造を行うべく、消費財分野への参入を図っている。具体的には世界  
最高水準といわれるアルミ加工技術を活用した鍋、やかんや食器、さらにはアルミ  
製の台所棚などの製品系列を開発している。この消費財分野での  
①  
営業組織の編成は  
まだ準備段階にあるが、これらの製品を購入した顧客の満足度は非常に高いことが  
購入後のフォローアップ調査で明らかになっている。  
T社は現在、強い決意で消費財部門を第二の柱として強化するための喫緊の課  
題として営業体制づくりを行っているが、産業財メーカーとしてはじめての消費財  
分野への参入であるため、  
②  
営業管理のあり方について様々な議論を行っている。  
（設問1）K  
文中の下線部①に示す｢営業｣に関する記述として、最も適切なものはどれか。  
  
ア  営業の目的は、製品・地域・取引相手のタイプといった区分で新規顧客を獲  
得することである。  
  
イ  営業は、マーケティング・ミックスのプロモーション活動に固有に含まれる  
人的販売のことである。  
  
ウ  企業の事業活動が国境を越えて展開されるにつれて、営業活動内容の複雑さ  
は逓減していく。  
  
エ  個々の営業パーソンに対する顧客の評価は、企業信頼と人格信頼の両者によ  
って支えられている。  
`
        },
        {
                "mondaiId": 103250302,
                "name": "第30問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u000fK  
文中の下線部②について、T社における消費財部門の営業管理のあり方に関  
する記述として、最も適切なものはどれか。  
  
ア  消費財部門では営業組織の強化はあきらめ、訪問販売を行うにとどめる。  
  
イ  消費財部門の拡大によって組織全体としての営業人員が不足するが、固定費  
の増加を防止したいので、産業財部門の最も熟練した営業パーソンを消費財部  
門に配置転換する。  
  
ウ  消費財部門は全く新しい分野であるため、発想の柔軟な若手営業パーソンを  
この部門に配置転換し、一部インセンティブ制の報酬を固定給に上乗せ可能な  
制度を設ける。  
  
エ  フリーランスの営業パーソンと契約し、完全歩合制の営業管理を徹底し、販  
売経路の幅を広げることに注力する。  
`
        },
        {
                "mondaiId": 104250010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産における管理目標?PQCDSME†に関する記述として、最も不適切なものは  
どれか。  
  
ア  管理目標Pに着目して、生産量と投入作業者数との関係を調査し、作業者\f  
人当たりの生産量を向上させるための対策を考えた。  
  
イ  管理目標Cに着目して、製品原価と原材料費との関係を調査し、製品原価に  
占める原材料費の低減方策を考えた。  
  
ウ  管理目標Sに着目して、実績工数と標準工数との関係を調査し、その乖離が  
大きい作業に対して作業の改善や標準工数の見直しを行った。  
  
エ  管理目標Mに着目して、技術的な資格と取得作業者数との関係を調査し、重  
点的に取る資格の取得率の向上に向けて研修方策を提案した。`
        },
        {
                "mondaiId": 104250020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産技術部門、製造部門において用いられるシミュレーションに関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  コンピュータ上の仮想工場を利用して、製造ラインの人・物・設備などの動き  
を解析するために、バーチャルマニファクチャリングを用いた。  
  
イ  従来、模型を作製して行っていた製品設計に関する検討作業を、コンピュータ  
の画面上に実現するために、ディジタルモックアップを用いた。  
  
ウ  生産システムの挙動を解析するために、作業時間が確率分布に従うと仮定し  
て、モンテカルロ法を用いた。  
  
エ  生産システムの構成要素をエージェントとして実装して、個々の挙動を解析す  
るために、システムダイナミクスを用いた。  
`
        },
        {
                "mondaiId": 104250030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
X社は、A、B、Cの\u0005種類の製品を、切断、穴あけ、プレス、旋盤、検査の\u0007  
つの職場で加工している。工場レイアウトの検討を行うために、1日当たりの移動  
回数および移動距離を評価尺度として以下の条件①、②に基づいて分析を行った。  
この分析・評価に関する記述として、最も適切なものを下記の解答群から選べ。  
F条件G  
①製品A、B、Cの加工順序と1日当たりの生産ロット数は、表\fに与えられ  
ている。職場間の移動は、生産ロット単位で行われている。  
切断或穴あけ或プレス或検査\u0006  
切断或穴あけ或旋盤或検査  
        
切断或プレス或旋盤或検査\u000e  
加工の順序生産ロット数／日  
Ｂ  
製品  
Ｃ  
Ａ  
表\f製品の加工順序と1日当たりの生産ロット数?���動回数†  
②各職場間の移動距離は、製品によらず表\u000fに与えられている。  
5  
30  
15  
旋盤  
検査  
切断  
プレス  
穴あけ  
検査  
25  
12  
7  
5  
安12  
30  
5  
安  
12  
穴あけ  
5  
安旋盤  
プレス  
8  
5  
安  
5  
7  
切断  
安  
12  
8  
15  
25  
表\u000f職場間の移動距離?単位：ｍ†  

[解答群]  
  
ア  職場間の移動回数がとなる職場同士の組は、\u0005個ある。  
  
イ  職場間の移動回数が最も多いのは、旋盤と検査の間である。  
  
ウ  職場間の移動距離の合計値が最も大きいのは、切断と穴あけの間である。  
  
エ  製品ごとの移動距離の合計値が最も大きいのは、製品Aである。  
`
        },
        {
                "mondaiId": 104250040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
VEに関する記述として、最も適切なものはどれか。  
  
ア  対象物の価値は、対象物の  
コスト  
機能  
でとらえられる。  
  
イ  対象物の機能を金額で評価するときは、その構成部品の合計購入金額が用いら  
れる。  
  
ウ  対象物の機能を整理するときに用いる機能体系図?機能系統図†は、機能を特性  
と要因に分け、相互の関係を階層化して示した図である。  
  
エ  対象物の機能を定義するときは、名詞と動詞を用いて、｢○○を○○する｣のよ  
うに記述する。`
        },
        {
                "mondaiId": 104250050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
設計・製造段階における品質に関する記述として、最も適切なものはどれか。  
  
ア  製造品質は、製造段階で責任を持つべき品質であり、｢ねらいの品質｣と呼ばれ  
ている。  
  
イ  設計品質は、品質特性に対する品質目標であり、｢できばえの品質｣と呼ばれて  
いる。  
  
ウ  代用特性は、品質特性を直接測定することが困難な場合に、その代わりとして  
用いられる特性である。  
  
エ  品質特性は、顧客の要求をそのまま表現した特性であり、製品価格もその\fつ  
である。  
`
        },
        {
                "mondaiId": 104250060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
バイオテクノロジーに関する技術用語とその応用事例との組み合わせとして、最  
も不適切なものはどれか。  
  
ア  遺伝子組換え：除草剤に耐性のある穀物の生産  
  
イ  ナノバイオテクノロジー：分子レベルのモータの開発  
  
ウ  バイオインフォマティクス：遺伝子情報を組み合わせた新薬の開発  
  
エ  バイオマス：生物の働きによる環境中の汚染物質の除去`
        },
        {
                "mondaiId": 104250070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産・物流業務におけるバーコードの利用に関する記述として、最も適切なもの  
はどれか。  
  
ア  誤読率が0.1％で読取不能の比率が0.2％のシンボルAと、誤読率が0.2％  
で読取不能の比率が0.1％のシンボルBとがある場合には、シンボルBを用い  
る。  
  
イ  情報の量が多く、シンボルラベルの面積をできるだけ小さくする場合には、\u000f  
次元シンボルより\f次元シンボルを用いる。  
  
ウ  部品のラベルに\u000f次元シンボルが使用されている場合には、小型で作業がしや  
すいペン型のスキャナを用いる。  
  
エ  ラベルとスキャナとの距離が数メートル離れている場合には、レーザスキャナ  
を用いる。  
`
        },
        {
                "mondaiId": 104250080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
需要量が多く、市場が安定している製品の組立を行う生産方式を決定することに  
関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  製品の組立作業に必要な設備の多くが高価であるので、一人生産方式を採用す  
ることにした。  
  
イ  製品の組立作業要素の数が多く複雑であるので、ライン生産方式を採用するこ  
とにした。  
  
ウ  製品の組立作業要素の数は少ないが作業者の作業時間変動が大きいので、一人  
生産方式を採用することにした。  
  
エ  製品の組立作業要素の数が少なく効率の高いライン編成ができないので、一人  
生産方式を採用することにした。`
        },
        {
                "mondaiId": 104250090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
混合品種組立ラインの編成を検討した結果、サイクルタイムを150秒、ステーシ  
ョン数を10とする案が提示された。生産される\u0005種類の製品A、B、Cの総作業  
時間と\fか月当たりの計画生産量は、以下の表に与えられている。この案の編成効  
率に最も近い値を、下記の解答群から選べ。  
生産量?個／月†  
総作業時間?秒／個†  
製品A  
1，000  
1，450  
製品C製品B  
1，450  
1，0002，000  
1，400  

[解答群]  
  
ア  0.94  
  
イ  0.95  
  
ウ  0.96  
  
エ  0.97  
`
        },
        {
                "mondaiId": 104250100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
需要予測の活用に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  工場を新設するため、需要量の長期予測を行った。  
  
イ  人員計画を策定するため、需要量の中短期予測を行った。  
  
ウ  新製品の設備計画を策定するため、需要量の短期予測を行った。  
  
エ  短納期の資材の仕入れ量を決定するため、需要量の短期予測を行った。`
        },
        {
                "mondaiId": 104250110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
工場における製品や部品の生産量と生産時期を定める日程計画に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  小日程計画は一般に、作業者や機械などの稼働率の最大化、仕掛在庫量の最小  
化などを目的に作成される。  
  
イ  大日程計画は一般に、将来必要とされる設備能力、作業者数、資材量などの算  
定に用いられる。  
  
ウ  大日程計画は一般に、月単位の各工場の生産計画を作成することから、月度生  
産計画と呼ばれることもある。  
  
エ  日程計画は一般に、大日程計画、中日程計画、小日程計画に分けられる。  
`
        },
        {
                "mondaiId": 104250120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある製造工程で生産される製品の品質特性を調べるために度数分布を作成するこ  
とにした。測定結果は以下の表に与えられている。度数分布の平均値に最も近い値  
を、下記の解答群から選べ。  
1  
3  
5  
7  
9  
合計  
No.  
10  
8  
6  
4  
2  
度数区間の中心値区間  
14039.5?41.5  
44241.5?43.5  
124443.5?45.5  
204645.5?47.5  
254847.5?49.5  
205049.5?51.5  
125251.5?53.5  
45453.5?55.5  
15655.5?57.5  
15857.5?59.5  
100安安  

[解答群]  
  
ア  47.9  
  
イ  48.0  
  
ウ  48.1  
  
エ  48.2  
`
        },
        {
                "mondaiId": 104250130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
TQM?総合的品質管理†の原則は、以下の3つに大別される。  
①目的に関する原則  
②手段に関する原則  
③目的の達成と手段の実践を支える組織の運営に関する原則  
このうちの｢②手段に関する原則｣に当てはまるものの組み合わせとして、最も適  
切なものはどれか。  
  
ア  源流管理、再発防止、事実に基づく管理  
  
イ  潜在トラブルの顕在化、QCD結果に基づく管理、教育・訓練の重視  
  
ウ  品質第一、重点志向、標準化  
  
エ  マーケットイン、プロセス重視、未然防止`
        },
        {
                "mondaiId": 104250140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
生産統制における現品管理の目的として、<u>最も不適切なものはどれか</u>。  
  
ア  運搬や保管の容易化  
  
イ  運搬ロットの最小化  
  
ウ  過剰仕掛品の防止  
  
エ  品質の維持  
`
        },
        {
                "mondaiId": 104250150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
職務訓練に関する記述として、最も適切なものはどれか。  
  
ア  OffJT?OfftheJobTraining†では、職務外の技能・知識・問題解決能力を身に  
付けるために、社員が自主的に学習する。  
  
イ  OJT?OntheJobTraining†は、社内職務に関する技能・知識・問題解決能力を  
広範かつ体系的に教育することに適している。  
  
ウ  SD?SelfDevelopment†では、社員の自己能力の開発に向けて、企業が社外講  
師を迎えて教育を実施する。  
  
エ  TWI?TrainingWithinIndustry†では、JI?JobInstruction†、JM?JobMethods†、  
JR?JobRelations†に関する職務訓練が、職場監督者に対して行われる。`
        },
        {
                "mondaiId": 104250160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
機械設備を利用した金属加工職場で作業研究を実施した。発生事象の分類の仕方  
として、<u>最も不適切なものはどれか</u>。  
  
ア  運搬作業者が金属材料を運搬する作業を｢主作業｣に分類した。  
  
イ  機械設備の金型交換を行う段取作業を｢付随作業｣に分類した。  
  
ウ  機械設備への注油や工具の交換を｢作業余裕｣に分類した。  
  
エ  供給業者からの部品待ちや突発的な設備停止を｢職場余裕｣に分類した。  
`
        },
        {
                "mondaiId": 104250170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
サーブリッグ分析に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  片方の手が作業しているために手待ちとなっている他方の手の状態は、UD  
?UnavoidableDelay†である。  
  
イ  部品を供給箱の中から手元に移動する手の動作は、TL?TransportLoaded†で  
ある。  
  
ウ  部品を容器の中でつかみ取る手の動作は、H?Hold†である。  
  
エ  ペンチを使って金属板を所定形状に折り曲げる手の動作は、U?Use†である。`
        },
        {
                "mondaiId": 104250180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
職場管理における5S?整理、整頓、清掃、清潔、躾†?JISZ8141-5603†に関する  
記述として、最も適切なものはどれか。  
  
ア  整理：必要なものを必要な時にすぐ使用できるように、決められた場所に準備  
しておくこと。  
  
イ  整頓：必要なものと不必要なものを区分し、不必要なものを片づけること。  
  
ウ  清掃：必要なものについた異物を除去すること。  
  
エ  清潔：清掃が繰り返され、汚れのない状態を維持していること。  
`
        },
        {
                "mondaiId": 104250190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
TPM?TotalProductiveMaintenance†における自主保全の\u0006つのステップを示す  
以下の図の空欄Ａ~Ｃに入る語句として、最も適切なものの組み合わせを下記の解  
答群から選べ。  
1．Ａ  
2．発生源･困難個所対策  
3．Ｂ  
4．総点検  
5．自主点検  
6．Ｃ  
7．自主管理の徹底  

[解答群]  
  
ア  Ａ：故障原因の究明  
Ｂ：故障の再発防止策の策定  
Ｃ：標準化  
  
イ  Ａ：故障原因の究明  
Ｂ：自主保全仮基準の作成  
Ｃ：保全組織の決定  
  
ウ  Ａ：初期清掃?清掃・点検†  
Ｂ：故障の再発防止策の策定  
Ｃ：保全組織の決定  
  
エ  Ａ：初期清掃?清掃・点検†  
Ｂ：自主保全仮基準の作成  
Ｃ：標準化  
`
        },
        {
                "mondaiId": 104250200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
生産工程の改善施策に関する記述として、最も適切なものはどれか。  
  
ア  ｢自働化｣は、故障を事前に予測する仕組みを機械設備に設けることで、設備稼  
働率の向上を狙った改善施策である。  
  
イ  ｢生産ラインのU字化｣は、生産ラインの形状をU字に変更することで、生産  
設備の敷設スペースの抑制を狙った改善施策である。  
  
ウ  ｢段取作業の外段取り化｣は、段取時間の短縮を図ることで、段取作業に従事す  
る作業者数の削減を狙った改善施策である。  
  
エ  ｢平準化｣は、前工程から引き取る部品の種類と量を平均化させることで、工程  
への負荷変動の抑制を狙った改善施策である。`
        },
        {
                "mondaiId": 104250210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ISO14040で規定されるLCA?ライフサイクルアセスメント†に関する記述とし  
て、最も適切なものはどれか。  
  
ア  LCAは、製品の原材料の取得から製造および使用に至るまでの過程を通じて、  
製品の環境への影響を調査・評価する環境マネジメントの手法である。  
  
イ  ｢ライフサイクルインベントリ分析｣では、多様な環境影響をひとつの数値にま  
とめる特性化を実施する。  
  
ウ  ｢ライフサイクル影響評価｣は必ずしも行う必要はない。  
  
エ  ｢ライフサイクル解釈｣では、実施されたLCAが要求された規格や原則を満た  
しているかどうかを検証する。  
`
        },
        {
                "mondaiId": 104250220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
都市計画法に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  映画館やアミューズメント施設などにも、大規模集客施設として、大規模小売  
店舗と同様の出店制限がある。  
  
イ  商業地域とは、商業その他の業務の利便を増進するために定めた地域である  
が、住宅や小規模の工場も建てられる。  
  
ウ  店舗の床面積が150m2以下の小規模店舗であれば、第\f種低層住居専用地域  
へ出店することができる。  
  
エ  床面積が\f万m2を超える店舗の出店が可能な地域は、原則として近隣商業地  
域・商業地域・準工業地域の\u0005地域である。`
        },
        {
                "mondaiId": 104250230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
大規模小売店舗立地法に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  大規模小売店舗法と異なり、大規模小売店舗立地法は小売店舗が営利活動を営  
んでいるかどうかを問題としないため、生協や農協の大規模店舗も同法の対象と  
なる。  
  
イ  建物の設置者が配慮すべき駐車場の収容台数や荷捌き施設の位置などの具体的  
な事項は、大規模小売店舗立地法に基づく指針で定められている。  
  
ウ  都道府県は、地元市町村や地元住民の意見を聴取するための協議会を設置する  
ことが義務づけられている。  
  
エ  都道府県は、建物の設置者が勧告に従わない場合、その旨を公表することがで  
きるが、従わない者への罰則規定はない。  
`
        },
        {
                "mondaiId": 104250240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売吸引力に関して、次の文中の空欄Ａ~Ｃに入る語句として最も適切なものの  
組み合わせを下記の解答群から選べ。  
小売吸引力とは、小売店が顧客を引き付ける力を意味し、市場地域特性、個別消  
費者特性、立地点特性、店舗特性、マーケティング特性などによって規定される。  
引力モデルで有名なハフは、ある目的地の効用はその地点にある小売施設の規模に  
Ａし、消費者がその目的地に到着するのに必要な時間にＢすると  
指摘した。また、小売吸引力によって、小売店が顧客を引き付ける地域を  
Ｃという。  

[解答群]  
  
ア  Ａ：反比例Ｂ：比例Ｃ：商圏  
  
イ  Ａ：反比例Ｂ：比例Ｃ：ポジショニング  
  
ウ  Ａ：比例Ｂ：反比例Ｃ：ポジショニング  
  
エ  Ａ：比例Ｂ：反比例Ｃ：商圏  
`
        },
        {
                "mondaiId": 104250250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ショッピングセンターにおける賃料の徴収形態に関して、次の文中の空欄Ａ~Ｄ  
に入る語句として最も適切なものの組み合わせを下記の解答群から選べ。  
Ａは、ディベロッパーが安定した収入を見込めるという利点がある。  
一方、Ｂは、ディベロッパーに対して、テナントの経営にかかわり自ら  
の賃料収入を増加させようとするインセンティブを与える。ただし、この場合、  
ディベロッパーはショッピングセンターの事業計画を立てるのが困難であるため、  
Ｃと組み合わせた賃料を設定し、リスクを小さくするのが一般的である。  
たとえば、賃料が売上高の10％、Ｃが50万円と設定されているショッピ  
ングセンターの場合、売上高400万円のテナントの賃料は、Ｄ万円となる。  

[解答群]  
  
ア  Ａ：固定家賃Ｂ：歩合型家賃Ｃ：最低保証家賃Ｄ：40  
  
イ  Ａ：固定家賃Ｂ：歩合型家賃Ｃ：最低保証家賃Ｄ：50  
  
ウ  Ａ：固定家賃Ｂ：歩合型家賃Ｃ：逓増歩合型家賃Ｄ：50  
  
エ  Ａ：歩合型家賃Ｂ：固定家賃Ｃ：最低保証家賃Ｄ：40  
  
オ  Ａ：歩合型家賃Ｂ：固定家賃Ｃ：逓増歩合型家賃Ｄ：40`
        },
        {
                "mondaiId": 104250260,
                "name": "第26問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
売場や商品を演出する色彩に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  オクラを緑色のネットに入れることで、対比現象により商品の色を鮮やかに見  
せることができる。  
  
イ  色相が連続する虹色の順に商品を陳列すると、売場に連続性が形成される。  
  
ウ  フェイスアウトの陳列をする場合、明度のグラデーションで高明度の色を手前  
に、暗い色を奥に置くのが一般的である。  
  
エ  ベビー用品は、優しい印象を与えるために、明度が高く、やわらかく見える色  
が多く使われている。  
`
        },
        {
                "mondaiId": 104250270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商品Aは売価1，000円、売価値入率30％であり、商品Bは売価1，500円、売  
価値入率20％である。商品Aと商品Bの売上を伸ばすために、\u000fつの商品のバ  
ンドル販売を企画した。商品Aと商品Bのセットを売価値入率5％にしたとき、  
このセットの売価として、最も適切なものはどれか。ただし、消費税は考慮しない  
ものとする。  
  
ア  1，800円  
  
イ  1，900円  
  
ウ  2，000円  
  
エ  2，375円  
  
オ  2，500円`
        },
        {
                "mondaiId": 104250280,
                "name": "第28問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある小売店では、当月売上高予算600万円、年間売上高予算6，000万円、年間予  
定商品回転率が  
回転である。この場合に、百分率変異法による月初適正在庫高と  
して、最も適切なものはどれか。  
  
ア  600万円  
  
イ  750万円  
  
ウ  800万円  
  
エ  825万円  
  
オ  850万円  
`
        },
        {
                "mondaiId": 104250290,
                "name": "第29問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費者の内的参照価格の低下を防ぐインストアプロモーション?ISP†の方法とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  クーポンを発行し、レジで商品の価格を割り引く。  
  
イ  商品を購入した人にキャッシュバックを実施する。  
  
ウ  特売価格を設定し、通常価格と併記して販売する。  
  
エ  バンドル販売によって複数の商品を購入した場合に価格を割り引く。  
  
オ  ポイントカード会員に、購入金額の一定割合のポイントを発行する。`
        },
        {
                "mondaiId": 104250300,
                "name": "第30問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売業の価格設定と価格政策に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  慣習価格政策は、すでに一般的に浸透している価格を設定する手法である。  
  
イ  コストプラス方式の価格設定は、価格が市場の実情に合わない場合がある。  
  
ウ  マーケットプライス法は、全国共通の価格を設定する手法である。  
  
エ  名声価格政策は、意識的に高価格を設定することによって、高品質であること  
を連想させる手法である。  
`
        },
        {
                "mondaiId": 104250310,
                "name": "第31問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小売業では、部門別などのグループごとに売上や粗利益などを管理する。そのひ  
とつの指標として相乗積?利益相乗積係数†がある。小売店舗における相乗積に関す  
る記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ある部門の相乗積は、店舗全体の粗利益高に占める当該部門の粗利益高の割合  
を示す。  
  
イ  ある部門の相乗積は、当該部門の売上高構成比と粗利益率の積である。  
  
ウ  すべての部門の相乗積の和は、店舗全体の粗利益率に等しくなる。  
  
エ  部門ごとの相乗積を比較すると、最も利益を生み出している部門が分かる。`
        },
        {
                "mondaiId": 104250320,
                "name": "第32問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある店舗では、日々の売上に大きな変動がなく、需要予測の精度が比較的高い定  
番商品について、店頭欠品を防ぐために基準在庫を設定したうえで、発注業務を行  
っている。この店舗で取引先と発注・納品のスケジュールに関する条件を見直す  
際、発注頻度や発注から納品までのリードタイムを変更したときの影響に関する記  
述として、最も適切なものはどれか。  
  
ア  発注頻度は変えずに、リードタイムを\u0005日から\u000e日に変更した場合、基準在庫  
を変更前の\u000f倍にすることが必要である。  
  
イ  発注頻度を週\u0005回から週\f回に変更し、リードタイムを\u000e日から\u0005日に変更し  
た場合、基準在庫を変更前の半分に減らしても店頭の欠品が増えることはない。  
  
ウ  リードタイムは変えずに、発注頻度を週\f回から週\u0005回に変更した場合、基準  
在庫を変更前より減らすことができる。  
  
エ  リードタイムは変えずに、発注頻度を週\u0005回から週\f回に変更した場合、変更  
前の基準在庫を維持すれば店頭の欠品を防ぐことができる。  
`
        },
        {
                "mondaiId": 104250330,
                "name": "第33問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある物流センターにおいて、比較的需要が安定した商品を定量発注方式で在庫補  
充している。定量発注方式では、手持在庫量が発注点を下回った際に、あらかじめ  
決めた発注量で補充するが、発注点と発注量の考え方に関する記述として、最も適  
切なものはどれか。  
  
ア  適切に発注量を設定することにより年間在庫総費用を抑えることができる。  
  
イ  発注点と発注量は一度決めても定期的に見直さなければ手持在庫量が増加す  
る。  
  
ウ  発注点は平均在庫量に安全在庫を加えたものに決められることが多い。  
  
エ  発注量を増やすと発注回数が減少し、手持在庫量は減少する。`
        },
        {
                "mondaiId": 104250340,
                "name": "第34問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
地球温暖化対策として、物流活動にともなうCO2排出量の削減に向けた荷主企  
業と物流事業者が連携した取り組みを推進するために、グリーン物流パートナーシ  
ップ会議が行われている。荷主企業と物流事業者の環境負荷低減の取り組みに関す  
る記述として、最も適切なものの組み合わせを下記の解答群から選べ。  
ａ配送の小口化  
ｂ複数の荷主による共同輸送  
ｃ輸送および物流拠点の分散化  
ｄ輸送コンテナの大型化  

[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 104250350,
                "name": "第35問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流センターにおけるピッキング方式に関する説明として、最も適切なものの組  
み合わせを下記の解答群から選べ。  
ａシングルピッキング方式とは、1人の作業者が受注単位ごとに保管場所を周回  
して、ピッキング作業を完結する摘み取り型のピッキング方式である。  
ｂ品種別ピッキング方式とは、複数の作業者がその作業範囲を分担し、それぞれ  
が中継してピッキング作業を完結させる方式である。  
ｃ品種別・オーダー別複合ピッキング方式とは、受注を一定受注先数ごとに集約  
して、品種単位にまとめてピッキングし、その直後に商品を受注先ごとに仕分け  
る作業を繰り返す方式である。  
ｄリレー式ピッキング方式とは、受注を集約して品種単位にまとめてピッキング  
し、そのピッキングした商品を後工程でオーダー先ごとに仕分ける種まき型のピ  
ッキング方式である。  

[解答群]  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｃ  
  
オ  ｂとｄ  
`
        },
        {
                "mondaiId": 104250360,
                "name": "第36問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流ABCに基づき原価計算をする場合、次の条件下における梱包作業の梱包\f  
口当たりのアクティビティ単価として、最も適切なものを下記の解答群から選べ。  
月間の総人件費：1，000万円  
月間の総作業時間：10，000分  
月間の梱包作業時間：500分  
月間の梱包口数：5，000個  

[解答群]  
  
ア  4円  
  
イ  100円  
  
ウ  2，000円  
  
エ  20，000円  
  
オ  500，000円`
        },
        {
                "mondaiId": 104250370,
                "name": "第37問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
在庫型物流センターにおいては、在庫を過剰に増やさず、品切れを起こさないた  
めに、適正な在庫補充をする必要がある。補充量を決める際には、将来の需要を予  
測する必要があるが、需要予測に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  移動平均法とは過去\f週間などの短い期間の需要データを用いて需要を予測す  
る手法である。  
  
イ  季節商品の需要を予測する場合、前年同時期の需要データを参考にすることが  
できる。  
  
ウ  需要予測の精度を高めるには、需要データを日常的に収集することが必要であ  
る。  
  
エ  販売促進の影響は、販売促進をする商品の需要だけでなく同じ品目の商品全体  
の需要とも関連するため、把握することが難しい。  
`
        },
        {
                "mondaiId": 104250380,
                "name": "第38問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
JANコードに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  実際の製造が海外で行われる商品であっても、日本の企業のブランドで販売さ  
れる場合は、日本の国コードが用いられる。  
  
イ  商品が製造または出荷される段階で、製造業者または発売元が商品包装に  
JANコードをJANシンボルにより表示することを、ソースマーキングという。  
  
ウ  ソースマーキングにより、商品の供給責任者がどこの企業か、何という商品か  
を識別することができる。  
  
エ  ソースマーキングのコード体系は、価格処理の違いにより、PLU方式と  
NonPLU方式に区分される。`
        },
        {
                "mondaiId": 104250390,
                "name": "第39問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
集合包装用商品コード?GTIN-14†のコード体系に関する記述として、最も不適  
切なものはどれか。  
  
ア  かつて日本では国内用として16桁の集合包装用商品コードを業界標準として  
定めて利用してきたために、切り替えが必要になった。  
  
イ  企業間の取引単位である集合包装に対して設定されているため、チェックデジ  
ットを持たないコード体系である。  
  
ウ  集合包装の入数違いなどを識別するために、｢\f｣から｢  
｣までの数字がインジ  
ケータとして用いられる。  
  
エ  集合包装の内容物である単品単位を識別しているJANコードからチェックデ  
ジットを除いた12桁を使用し設定したコード体系を、日本では｢一致型｣と呼ぶ。  
`
        },
        {
                "mondaiId": 104250400,
                "name": "第40問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
流通標準EDI?JEDICOS†では、情報伝達規約と情報表現規約を｢流通標準EDI  
?JEDICOS†メッセージ｣として提示している。このとき、同メッセージにおいて、  
小売業から卸売業や製造業に向けて発信される情報として提示されるものについ  
て、最も適切なものの組み合わせを下記の解答群から選べ。  
ａ在庫情報  
ｂ品切情報  
ｃ商品マスター情報  
ｄ発注データ  
ｅ返品データ  

[解答群]  
  
ア  ａとｂとｃ  
  
イ  ａとｄとｅ  
  
ウ  ｂとｃとｄ  
  
エ  ｂとｄとｅ  
  
オ  ｃとｄとｅ  
`
        },
        {
                "mondaiId": 104250410,
                "name": "第41問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、ある店舗における顧客の購買履歴データをもとにRFM分析を行った結  
果である。  
\u0005\u0005\f003  
\f\f\u0005005  
顧客  
004\f\f\f  
002\f  
\u0005\u0005\u0005001  
RMF  
\f\u000f  
このとき、｢以前は優良顧客であったが、最近は来店のない顧客｣と分析される顧  
客として最も適切なものを、下記の解答群から選べ。  
ただし、RFM値の点数化には、次の方法を用いた。  
\u000f回以下F  
M\f万円未満\f万円以上\u0005万円未満\u0005万円以上  
\u000e回以上\u0005回以上\u0007回以下  
\u000f点\u0005点\f点  
R31日以上前15日以上30日以内14日以内  

[解答群]  
  
ア  顧客001  
  
イ  顧客002  
  
ウ  顧客003  
  
エ  顧客004  
  
オ  顧客005  
`
        },
        {
                "mondaiId": 105250010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業買収の手法に関する以下の会話は、中小企業診断士であるあなたとX株式  
会社の代表取締役甲氏との間で行われたものである。空欄ＡとＢには、下記のａ~  
ｃの記述のうちいずれかひとつが入る。空欄と記述の組み合わせとして最も適切な  
ものを下記の解答群から選べ。  
甲氏：｢私の会社も、将来に向けて海外に展開していかなきゃいけないと考えて  
います。そうしたところ、取引銀行から、私の会社の事業とのシナジー効  
果が見込めそうな外国会社の事業買収の案件の紹介を受けたので、検討を  
始めたのですが、どういった手法がよいのか考えがまとまらなくて困って  
います。株式の譲受け、事業譲受け、吸収分割といった手法が考えられる  
と思うのですが、それぞれどのようなメリット・デメリットがありますか。｣  
あなた：｢そうですね。まず、株式の譲受けについては、特約で禁止されていない  
限り、買収対象企業が契約を締結している相手方3取引先  
の同意を必要と  
しません。次に、事業譲受けの場合、Ａ。それから、吸収分割の  
場合、Ｂ。というように、それぞれ、メリット・デメリットがあ  
りますし、税務的な観点からの検討も必要になります。弁護士や税理士の  
先生の協力も得て検討すると良いと思いますよ。｣  
甲氏：｢なるほど、考えを整理することができました。｣  
ａ相手方が外国会社だと行うことはできないと実務的には考えられているので、  
今回のケースでは採用できないと思います  
ｂ財務諸表に計上されていない偶発債務を切り離すことができるメリットがあり  
ます  
ｃ取引の相手が消滅してしまうので、後日何か問題があっても取引の相手に責任  
を追及できないというデメリットがあります  
[解答群]  
  
ア  Ａ：ａＢ：ｃイＡ：ｂＢ：ａウＡ：ｂＢ：ｃ  
  
エ  Ａ：ｃＢ：ａ  
`
        },
        {
                "mondaiId": 105250021,
                "name": "第2問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の会話は、中小企業診断士であるあなたとX株式会社3以下｢X社｣という。  
        
の代表取締役甲氏との間で行われたものである。現在、X社は、Y株式会社3以下  
｢Y社｣という。  
との間で、Y社の完全子会社であるZ株式会社3以下｢Z社｣とい  
う。  
の全株式の買取りに向けた交渉を行っている。この会話を読んで、下記の設問  
に答えよ。  
甲氏：｢今、わが社によるZ社株式の買取りについての契約書を読んでいるので  
すが、見慣れない用語が飛び交っていて正直よく分かりません。｣  
あなた：｢確かに、株式譲渡契約は、売買、賃貸、請負といった、企業間の商取引  
とは異なる構造になっているので、慣れないと難しいですよね。これらの  
契約には、ＡやＢ事項といったＣ系の概念が用  
いられており、Ｄ系に属する日本にはなじみにくいところがあり  
ます。｣  
甲氏：｢まず、クロージングとは何でしょうか。｣  
あなた：｢取引の実行のことですね。通常、契約書を締結してから、取引を実行す  
るまでに間隔が空くので、クロージングという概念がでてきます。株式譲  
渡の場合でいうと、売主から買主への株主権の移転と買主から売主への株  
式譲渡代金の支払ということになります。｣  
甲氏：｢なるほど。Ａとはどういうことでしょうか。｣  
あなた：｢株式譲渡の場合だと、契約の一方当事者が、相手方当事者に対し、株式  
やその株式を発行している株式会社の状況などについて、契約書締結時や  
クロージング時などの一定の時点において、一定の事項が真実かつ正確で  
あることをＡするものです。今回の契約書ですと、Y社が御社  
に対して、Z社において未払い残業代がないことなどをＡしてい  
ます。｣  
甲氏：｢難しいですねえ。Ｂ事項とは何でしょうか。｣  
あなた：｢契約の一方当事者が、相手方当事者に対し、一定の行為を行う、又は行  
わないことを約束し、又はその義務を負うことです。大きく分けてクロー  
ジング前のものとクロージング後のものがあります。今回の契約書です  
と、Y社がクロージングまで、Z社を適切に経営していくことなどがこれ  
に該当します。｣  
        
甲氏：｢やっぱり、難しいですねえ。｣  
あなた：｢うまく説明できなくてすみません。これらを理解するには、Ａ  
やＢ事項に違反した場合にどういう効果が発生するのかを考える  
と分かり��すいかもしれません。まず、クロージング前にＡ違反  
やＢ事項違反が発覚した場合には、一方当事者が違反した当事者  
に対し、①取引の実行拒否、②契約の解除及び③損害の補償請求を求める  
ことができると契約書に定めることが多いです。他方、クロージング後に  
違反が発覚した場合については、①から③までのうち、Ｅのみ認  
められると契約書に定めることが多いです。｣  
甲氏：｢なるほど。ようやく理解できました。買主である当社としては、契約締  
結後に取引を取り止めたい事由や契約を解除したい事由、Y社に損害を  
補償してもらいたいと考えるケースについてY社のＡや  
Ｂ事項として契約書に定めておけばいいわけですね。｣  
あなた：｢そのとおりです。｣  
（設問1）  
        
会話の中の空欄Ａ、Ｂ及びＥに入る語句の組み合わせとして最も適切なものは  
どれか。  
  
ア  Ａ：誓約Ｂ：表明・保証Ｅ：②契約の解除  
  
イ  Ａ：誓約Ｂ：表明・保証Ｅ：③損害の補償請求  
  
ウ  Ａ：表明・保証Ｂ：誓約Ｅ：①取引の実行拒否  
  
エ  Ａ：表明・保証Ｂ：誓約Ｅ：③損害の補償請求`
        },
        {
                "mondaiId": 105250022,
                "name": "第2問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
        
会話の中の空欄Ｃ及びＤに入る語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  Ｃ：英米法Ｄ：大陸法  
  
イ  Ｃ：国際法Ｄ：国内法  
  
ウ  Ｃ：私法Ｄ：公法  
  
エ  Ｃ：手続法Ｄ：実体法  
`
        },
        {
                "mondaiId": 105250030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
違反行為を自主申告した事業者に対し、公正取引委員会が支払を命じる課徴金を  
減免する課徴金減免制度に関する記述として、最も適切なものはどれか。  
  
ア  課徴金減免制度は、談合、カルテルなどの不当な取引制限のみが対象であり、  
私的独占や優越的地位の濫用は対象ではない。  
  
イ  公正取引委員会による調査開始後であっても、最初に課徴金減免申請を行った  
事業者については、課徴金が全額免除される。  
  
ウ  公正取引委員会による調査開始前であっても、公正取引委員会に把握されてい  
ない事実を報告しなければ、課徴金の減免を受けることはできない。  
  
エ  子会社と共同して課徴金減免申請を行うことはできない。`
        },
        {
                "mondaiId": 105250041,
                "name": "第4問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
事業承継に関する以下の会話は、中小企業診断士であるあなたとX株式会社3以  
下｢X社｣という。  
の代表取締役であり、かつ、X社の全株式を保有する甲氏との  
間で行われたものである。この会話を読んで、下記の設問に答えよ。なお、X社  
は、取締役会設置会社である。  
甲氏：｢私ももう70歳です。そろそろ第一線から退いて、後継者と考えている長  
男の乙に株式をすべて譲り、私は、取締役相談役といった形で経営にかか  
わっていきたいと考えています。ただ、長男はまだ40歳で、経営者とし  
てはまだ少し若いような気がするので、少し不安が残ります。｣  
あなた：｢それでしたら、甲さんが現在保有している株式はすべて乙さんに譲りつ  
つ、新たに甲さんにＡを発行したらいかがでしょうか。そうすれ  
ば、甲さんの賛成がなければ、X社の株主総会決議事項又は取締役会決  
議事項の全部又は一部を決議できないようにできます。｣  
甲氏：｢なるほど。そのような株式を発行することができるのです���。｣  
あなた：｢ただし、この株式を発行した場合、Ｂので、注意してください  
        
ね。｣  
甲氏：｢分かりました。｣  
（設問1）  
        
会話の中の空欄Ａに入る語句として最も適切なものはどれか。  
  
ア  拒否権付株式  
  
イ  取得条項付株式  
  
ウ  取得請求権付株式  
  
エ  役員選任権付株式`
        },
        {
                "mondaiId": 105250042,
                "name": "第4問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
        
会話の中の空欄Ｂに入る記述として最も適切なものはどれか。  
  
ア  乙さんが経営努力を重ねてX社の株式の価値が上がれば上がるほど、乙さ  
ん以外の相続人の遺留分の額が増加してしまうことになる  
  
イ  議決権のない株式を有する株主について、優先的に配当を行うなどの配慮が  
必要になる  
  
ウ  甲さんが万が一お亡くなりになった場合、乙さん以外の相続人が乙さんに対  
して遺留分減殺請求を行う可能性が高まる  
  
エ  甲さんと乙さんとの間で株主総会決議事項又は取締役会決議事項について意  
見が食い違って調整できないときは、何も決められない状態に陥る危険がある  
`
        },
        {
                "mondaiId": 105250051,
                "name": "第5問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
監査役の権限が会計に関するものに限定されている取締役会設置会社に関する以  
下の文章を読んで、下記の設問に答えよ。  
会社法の施行に伴う関係法律の整備等に関する法律により、会社法施行直前に  
Ａであった公開会社でない株式会社については、監査役の監査の範囲を会  
計に関するものに限定する旨の定款の定めがあるものとみなされる。したがって、  
定款に監査役の監査の範囲を会計に関するものに限定する旨の定めがないからとい  
って、直ちに監査役の監査の範囲が限定されていないと判断することはできない。  
監査役の監査の範囲が会計に関するものに限定されている場合、Ｂ。  
（設問1）  
        
文中の空欄Ａに入る語句として最も適切なものはどれか。  
  
ア  関連会社イ子会社ウ小会社エ中会社`
        },
        {
                "mondaiId": 105250052,
                "name": "第5問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
        
文中の空欄Ｂに入る記述として最も適切なものはどれか。  
  
ア  株主総会において、組織、運営、管理その他株式会社に関する一切の事項に  
ついて決議することができる  
  
イ  監査役は、取締役が提出しようとする会計に関する議案については、調査  
し、法令若しくは定款に違反し、又は著しく不当な事項がないときでも、その  
調査の結果を株主総会に報告しなければならない  
  
ウ  定時株主総会の招集通知に際して、株主に対し、計算書類を提供する必要は  
ない  
  
エ  取締役が株式会社の事業の部類に属する取引をしようとする場合には、株主  
総会において、その承認を受けなければならない  
`
        },
        {
                "mondaiId": 105250060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許権の侵害に関する記述として最も適切なものはどれか。  
  
ア  特許権者は、自己の特許権を侵害するおそれがある者に対し、その侵害の予防  
を請求することができる。  
  
イ  特許権を侵害した者が、特許権者に対し、その侵害の行為によって受けた利益  
の額を超えて、損害を賠償すべき場合はない。  
  
ウ  物を生産する方法の発明において、その発明により生産された物を輸入する行  
為は、当該発明に係る特許権の侵害とはならない。  
  
エ  物を生産する方法の発明についての特許権の侵害訴訟において、その物が特許  
出願前に日本国内において公然と知られた物であるときは、その物と同一の物は  
その方法により生産したものと推定される。`
        },
        {
                "mondaiId": 105250070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許を受ける権利に関する記述として最も適切なものはどれか。  
  
ア  産業上利用することができる発明をした場合であっても、その発明について特  
許出願がなされなければ、発明者に特許を受ける権利が発生しない。  
  
イ  特許を受ける権利がAとBの共有に係る場合、AとBは、それぞれ他の共有  
者の同意を得ずに、自己の持分について譲渡することができる。  
  
ウ  特許を受ける権利は、譲渡により移転することができる。  
  
エ  特許を受ける権利は、抵当権の目的とすることができる。  
`
        },
        {
                "mondaiId": 105250080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許権及び実施権に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  特許権者Aが保有する特許権について、Bに専用実施権の設定の登録がなさ  
れた。この場合、当該設定行為で定めた範囲内において、特許権者Aと専用実  
施権者Bとは、当該特許発明の実施をする権利を共有する。  
  
イ  特許権者Cから専用実施権の設定の登録を受けたDは、当該特許権を侵害す  
る者に対して、差止請求権を行使することができる。  
  
ウ  特許権者は、専用実施権者があるときは、当該専用実施権者の承諾を得た場合  
に限り、その特許権を放棄することができる。  
  
エ  日本国内において、特許権の設定の登録の日から継続して\u0005年以上、その特許  
発明の実施が適当にされていないとき、その特許発明の実施をしようとする者  
は、特許権者又は専用実施権者に対し通常実施権の許諾について協議を求めるこ  
とができる。ただし、その特許発明に係る特許出願の日から\u000e年を経過している  
ものとする。`
        },
        {
                "mondaiId": 105250090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
地域団体商標に関する記述として最も適切なものはどれか。  
  
ア  地域団体商標に係る商標権者は、その商標権について、｢地域団体商標に係る  
商標権を有する組合等の構成員｣3地域団体構成員  
以外の他人に専用使用権を許  
諾することができる。  
  
イ  地域団体商標に係る商標権は譲渡することができる。  
  
ウ  ｢地域団体商標に係る商標権を有する組合等の構成員｣3地域団体構成員  
は、当  
該地域団体商標に係る登録商標の使用をする権利を移転することができない。  
  
エ  地域の名称のみからなる商標も、地域団体商標として登録を受けることができ  
る。  
`
        },
        {
                "mondaiId": 105250100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
意匠権に関する記述として最も適切なものはどれか。  
  
ア  Aは組物の意匠として一組の飲食用ナイフ、スプーン及びフォークのセット  
の意匠登録を受けた。Aの当該意匠権の効力は、ナイフのみの意匠には及ばな  
い。  
  
イ  意匠権の効力は、商標権の効力とは異なり、登録意匠に類似する意匠には及ば  
ない。  
  
ウ  関連意匠の意匠権の存続期間は、関連意匠の意匠権の設定の登録の日から20  
年をもって終了する。  
  
エ  業として登録意匠に係る物品を輸出する行為は、意匠権の侵害とはならない。`
        },
        {
                "mondaiId": 105250110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の債権のうち､それぞれの起算点からの消滅時効期間が最も短いものとして、  
最も適切なものはどれか｡  
  
ア  信用保証協会が中小企業の債務を保証し､代位弁済した場合における求償債権  
  
イ  製品事故を起こしたメーカーに対する製造物責任法による損害賠償請求権  
  
ウ  取締役の善管注意義務・忠実義務違反による会社から取締役への損害賠償請求  
権  
  
エ  メーカーが販売店に売却した製品の販売代金債権  
`
        },
        {
                "mondaiId": 105250120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次のａ~ｅの著作物の利用行為のうち、著作権者の許諾なしに行った場合、著作  
権法の下で、①｢刑事罰の対象となることもあり得るもの｣、②｢刑事罰はないが民  
事上違法となり得るもの｣、③｢適法なもの｣の組み合わせとして最も適切なものを、  
下記の解答群から選べ。  
ａ個人的に視聴する目的で、映画DVDのアクセスガードを解除して内容をコピ  
ーする行為  
ｂ企業活動のPRに使用されている動物をデフォルメした｢ゆるキャラ｣の顔のデ  
ザインは変えないままで、その体中の毛をむしり取られて丸裸にされているとい  
う状況を表現した画像を、企業としての事業活動とは無関係な当該企業の社長の  
個人的活動を批判する目的で、自分のブログにアップロードする行為  
ｃ有料音楽ファイルのコピーを違法にアップロードしたサイトと知りながら、当  
該サイトから個人的に楽しむ目的で音楽ファイルをダウンロードする行為  
ｄ企業における研究開発業務の参考資料として新聞や書籍、雑誌をコピーした  
PDFファイルを、すべての事業所の従業員がアクセスし、閲覧できるサーバー  
コンピューターに保存する行為  
ｅクールビズの時期に撮影した従業員の集合写真で、うち1人が着たTシャツ  
にプリントされた漫画キャラクターが小さく写り込んでいるものを、画像処理を  
しないまま当該企業の職場紹介のホームページにアップロードする行為  
[解答群]  
  
ア  ①：ａ②：ｂとｃ③：ｄとｅ  
  
イ  ①：ａとｄとｅ②：ｃ③：ｂ  
  
ウ  ①：ｂとｃ②：ａとｅ③：ｄ  
  
エ  ①：ｂとｃとｄ②：ａ③：ｅ  
`
        },
        {
                "mondaiId": 105250131,
                "name": "第13問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
外国からの医療機器の輸入に関して、輸入業者側が手配した輸送手段に輸出業者  
が製品を積載することによって製品を受領する旨の合意が成立し、次の英文契約書  
の条項が定められた。  
PURCHASEAGREEMENTFORMEDICALDEVICEPRODUCT  
ArticleXX  
x.DeliveryoftheProductsshallbemadeatSanFranciscoPort，California，onor  
beforethe31stofAugust，2013，onＡSanFranciscobases.  
xx.Thetradeterm“Ａ”shallbeinterpretedinaccordancewith  
Ｂ.  
（設問1）  
        
契約書の空欄Ａには、｢本船渡し｣の貿易条件を意味する貿易用語が入る。この  
用語として最も適切なものはどれか。  
  
ア  CIF3cost，insurance，freight  
        
  
イ  DDP3delivereddutypaid  
        
  
ウ  EXW3exworks  
        
  
エ  FOB3freeonboard  
`
        },
        {
                "mondaiId": 105250132,
                "name": "第13問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
        
契約書の空欄Ｂには、国際商業会議所が制定した、貿易取引慣習として普遍的  
に使用されている貿易取引条件の解釈に関する国際規則の略称が入る。この略称  
として最も適切なものはどれか。  
  
ア  CISGイINCOTERMS2010ウU.C.C.2009-2010ed.  
  
エ  UPICC  
`
        },
        {
                "mondaiId": 105250141,
                "name": "第14問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業診断士であるあなたと、顧客であるSNS3ソーシャル・ネットワーキン  
グ・サービス  
運営会社の社長甲氏との以下の会話を読んで、下記の設問に答えよ。  
甲氏：｢今度、当社のSNS事業を、乙社に譲渡することになりました。｣  
あなた：｢やはり、最近外資系のSNSサイトや無料通話アプリに押され気味でした  
ものね。｣  
甲氏：｢これからいろいろ面倒な手続があるみたいですけど。｣  
あなた：｢そうですね、譲渡資産の帳簿価額が御社の総資産額のＡであれ  
ば、株主総会のＢによる事業譲渡契約の承認が必要ですし、従業  
員の雇用の引継ぎについても、Ｃが適用されるのはＤの  
場合ですから、事業譲渡では原則に戻って労働者から個別に乙社への移籍  
について同意を得る必要があります。｣  
甲氏：｢知的財産の権利関係はどうなりますか。当社は独自開発したSNSの機能  
について特許を複数取得しており、その一部はSNSの運用ソフトウェア  
やデザインの著作権とまとめてライセンスに出しているんですが。｣  
あなた：｢特許については登録をしなくてもライセンシーが乙社に通常実施権を対  
抗できます。著作権については、登録制度はライセンシーから乙社に対し  
て利用権を対抗するための  
①  
?ａ．手段ではない／ｂ．手段となるEので、ラ  
  
イ  センシーが利用を継続するには  
②  
?ｃ．利用権の登録／ｄ．乙社の許諾Eが  
必要です。｣  
        
（設問1）  
        
会話の中の空欄Ａ~Ｄに入る語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  Ａ：15％超Ｂ：普通決議Ｃ：労働契約法  
Ｄ：会社整理  
  
イ  Ａ：20％超Ｂ：特別決議Ｃ：労働契約法  
Ｄ：合併  
  
ウ  Ａ：20％超Ｂ：特別決議Ｃ：労働契約承継法  
Ｄ：会社分割  
  
エ  Ａ：30％超Ｂ：普通決議Ｃ：労働契約承継法  
Ｄ：支配株主の変更`
        },
        {
                "mondaiId": 105250142,
                "name": "第14問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
        
会話の中の下線部①と②について、正しい発言の組み合わせとして最も適切な  
ものはどれか。  
  
ア  ①：ａ②：ｃ  
  
イ  ①：ａ②：ｄ  
  
ウ  ①：ｂ②：ｃ  
  
エ  ①：ｂ②：ｄ  
`
        },
        {
                "mondaiId": 105250150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
上場している製薬企業甲社と、医療支援ビジネスを手掛けるベンチャー企業乙社  
が、包括的な事業提携契約を締結した。次のａ~ｅの者のうち、甲社が上場してい  
る証券取引所における事業提携契約の適時開示前に、甲社・乙社間の事業提携契約  
の締結を知った上で自ら甲社株式の売買をすることにつき、インサイダー取引によ  
る規制対象者の範囲に含まれない者3複数の者による共犯関係が成立する場合を除  
く。  
として最も適切な組み合わせを、下記の解答群から選べ。  
ａ乙社取締役会における承認・報告がないのに事業提携契約を締結したことを、  
乙社代表取締役から伝えられた社外の第三者から教わった乙社取締役A  
ｂ甲社の代表取締役として事業提携先候補の選定を行った後、半年前に取締役を  
退任したB  
ｃ甲社取材の際に偶然耳にした役員同士の立ち話から、乙社との事業提携を知っ  
た経済誌記者C  
ｄ顧問先である乙社の役員から甲社との事業提携を知らされた中小企業診断士  
D  
ｅ事業提携について甲社代表取締役から伝えられた証券会社担当者に教わった甲  
社株主E  
[解答群]  
  
ア  ａとｃ  
  
イ  ａとｅ  
  
ウ  ｂとｄ  
  
エ  ｃとｅ  
`
        },
        {
                "mondaiId": 105250160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
法の適用に関する通則法の下で、準拠法に関する記述として最も適切なものはど  
れか。  
  
ア  債権譲渡の債務者その他の第三者に対する効力は、債務者の住所地法が準拠法  
となる。  
  
イ  日本に事務所のある外国法人と従業員との雇用契約に当該外国法を準拠法とす  
る規定がある場合、当該従業員に日本の労働基準法の規定の適用は認められな  
い。  
  
ウ  日本に事務所のある外国法人と日本の消費者との契約の約款に当該外国法を準  
拠法とする規定がある場合、日本の消費者に日本の法令によるクーリングオフは  
認められない。  
  
エ  法律行為の成立及び効力は、当事者による選択がなければ、当該法律行為当時  
において当該法律行為に最も密接な関係がある地の法が準拠法となる。`
        },
        {
                "mondaiId": 105250170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
外国会社が日本において取引を継続して行うこと3営業活動  
を計画している。こ  
の場合、会社法上留意すべき事項に関する記述として最も適切なものはどれか。  
  
ア  外国会社が日本において営業活動を行う場合には、外国会社の登記が必要であ  
る。この場合には、日本における営業所を設置しその住所を登記しなければなら  
ない。  
  
イ  外国会社が日本において営業活動を行う場合には、外国会社の登記が必要であ  
る。この場合には、日本に住所を有する代表者を最低1名定め登記しなければな  
らない。  
  
ウ  外国会社が日本において営業活動を行う場合には、外国会社の登記が必要であ  
る。これは、これから行う営業活動の準備として、もっぱら市場調査や情報収集  
を行うだけの場合でも同様である。  
  
エ  外国会社が日本において営業活動を行う場合には、当該国の法律に従うため、  
日本の会社法は適用されない。  
`
        },
        {
                "mondaiId": 105250180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
公私混同が激しく株式会社の存続を危うくする代表取締役Aを解職して、代表  
権をはく奪したい。さらにAを取締役から解任したい。この場合の記述として最  
も適切なものはどれか。なお、当該株式会社は取締役会設置会社であり定款による  
別段の定めがないことを前提とする。  
  
ア  代表取締役Aを解職して代表権のない取締役にするには、株主総会において  
議決権の過半数を有する株主が出席し、出席した当該株主の議決権の過半数をも  
って行わなければならない。  
  
イ  代表取締役Aを解職して代表権のない取締役にするには、取締役会において  
議決に加わることができる取締役の過半数が出席し、出席取締役の過半数の決議  
によらなければならない。  
  
ウ  取締役Aを解任するには、株主総会において議決権の過半数を有する株主が  
出席し、出席した当該株主の議決権の\u0005分の  
以上に当たる多数をもって行わな  
ければならない。  
  
エ  取締役Aを解任するには、取締役会において議決に加わることができる全取  
締役が出席し全員の同意によって行わなければならない。  
`
        },
        {
                "mondaiId": 105250190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のあなたと社長の会話の中の空欄ＡとＢに入る語句の組み合わせとして最も  
適切なものを、下記の解答群から選べ。  
社長：｢先日アドバイスをもらって銀行に提出したＡの実績がどうなっ  
ているか、説明を求められているのだが。｣  
あなた：｢銀行の担当者も内部での説明義務があるのですよ。｣  
社長：｢説明義務？何を説明するのかな。｣  
あなた：｢御社は債務超過が続き、借入金のリスケジュールをしてもらっています  
よね。銀行としては、金融庁の金融検査マニュアルに基づいて、御社の債  
務者区分を破綻懸念先にしているかもしれません。｣  
社長：｢破綻懸念先。冗談じゃない。そうなるとどうなるの？｣  
あなた：｢破綻懸念先に対する貸付金は不良債権として取り扱われます。｣  
社長：｢不良債権！とんでもない。今は少し苦しいけど新しい取引先も増えてき  
て、来年からは業績も好転するのに。｣  
あなた：｢それを示すのがＡなんです。担当者はそれが合理的で実行可能  
であれば、その区分を破綻懸念先ではなくＢとしてよいからなの  
です。｣  
社長：｢担当者にはうちの状況は理解してもらっているとは思っているが、進捗  
についてももう少し話しておいたほうがよいな。｣  
[解答群]  
  
ア  Ａ：経営改善計画Ｂ：要確認先  
  
イ  Ａ：経営改善計画Ｂ：要注意先  
  
ウ  Ａ：資産売却計画Ｂ：要確認先  
  
エ  Ａ：資産売却計画Ｂ：要注意先  
`
        },
        {
                "mondaiId": 105250200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
株式上場のメリット、デメリットに関する以下の文章の空欄Ａ~Ｃに入る語句の  
組み合わせとして最も適切なものを、下記の解答群から選べ。  
株式を上場すると株式の流動性が高まり、Ａ市場において公募による  
Ｂなどにより直接金融の道が開かれ、資金調達の円滑化・多様化を図るこ  
とができる。また、会社の知名度や信用力の向上もメリットといえる。  
しかし一方では、上場後は誰でも上場企業の株主になることができるため、  
Ｃによって経営権を脅かされるリスクが生じる。さらに投資家保護のた  
め、証券取引所の規則や金融商品取引法に基づく企業内容の開示などの新たな負担  
が増加するというデメリットもある。  
株式上場を決断するためには、このメリットとデメリットを十分に検討する必要  
がある。  
[解答群]  
  
ア  Ａ：発行Ｂ：株主割当増資Ｃ：内部告発  
  
イ  Ａ：発行Ｂ：時価発行増資Ｃ：買占め  
  
ウ  Ａ：流通Ｂ：株主割当増資Ｃ：買占め  
  
エ  Ａ：流通Ｂ：時価発行増資Ｃ：内部告発  
`
        },
        {
                "mondaiId": 105250210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
東京証券取引所では、成長企業向け市場であるマザーズのほか、2008年の金融  
商品取引法改正を機に導入された｢プロ向け市場制度｣に基づきTOKYOPRO  
Marketを開設している。  
TOKYOPROMarketは、主に投資家として投資に関する知識や経験があり自ら  
の判断で投資行動を行えるプロ3特定投資家  
を対象としている。  
同市場へ上場する企業はJ-Adviserを確保することを要件としている。  
J-Adviserは、上場を希望する企業の上場適格性を評価するとともに、上場までの  
過程において助言・指導を行い、上場後も規則遵守や情報開示をサポートする。こ  
のような仕組みにより、上場手続きの簡素化によって迅速な上場が可能となり、情  
報開示等の規制によるコストを必要最小限に抑えながら成長に必要な長期資金の調  
達をより円滑に行うことが期待されている。  
マザーズとTOKYOPROMarketの特徴を比較した記述として、最も適切なも  
のはどれか。  
  
ア  上場基準のうち形式要件としては、マザーズでは利益の額の基準はないが、流  
通株式数や株主数の基準が存在する。TOKYOPROMarketではこのような数値  
基準はない。  
  
イ  上場後の金融商品取引法上の開示義務については、マザーズでは内部統制報告  
書、四半期報告書の開示義務はないが、有価証券報告書の開示が求められる。  
TOKYOPROMarketではこれらの開示義務はない。  
  
ウ  上場申請の時期については、マザーズでは上場承認を希望する日の少なくとも  
10営業日前までとするが、TOKYOPROMarketでは  
か月前までに申請しな  
ければならない。  
  
エ  申請書類に添付される財務諸表等に対する監査法人等の監査は、マザーズでは  
最近事業年度の監査を受けている必要があるが、TOKYOPROMarketでは監査  
報告書の添付は不要である。  
`
        },
        {
                "mondaiId": 106250010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務におけるコンピュータの多岐にわたる利用では、日々発生する様々なデータは補助記憶装置に蓄積される。記憶したデータ量が補助記憶装置の記憶容量の許容限度を超える前に、その適切な増設を行うことが必要になる。  

補助記憶装置の利用に関する以下の文章の空欄Ａ~Ｄに入る用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  

パーソナルコンピュータ(パソコン)に外部接続して利用する補助記憶装置として以下のものがある。  

①　（　Ａ　<hint hidden='true'>**
e-SATA
**</hint>）や（　Ｂ　<hint hidden='true'>**
USB
**</hint>）をインタフェースとして利用するハードディスクやDVD装置  

②　LANに直接接続して利用する（　Ｃ　<hint hidden='true'>**
NAS
**</hint>）  


②の補助記憶装置には（　Ｄ　<hint hidden='true'>**
OS。NASはNetwork Attached Strageの略で、補助記憶装置とパーソナルコンピュータのデータの送受信をネットワーク越しに実行できるように、OSを必要とします。
**</hint>）を備える必要があるが、①の装置には必要がな  
い。      

[解答群]  
  
ア  Ａ：e-SATA　Ｂ：TCP/IP　Ｃ：NFS　Ｄ：DNS  
  
イ  Ａ：e-SATA　Ｂ：USB　Ｃ：NAS　Ｄ：OS<hint hidden='true'>**
←⭕
**</hint>    
  
ウ  Ａ：IDE　Ｂ：USB　Ｃ：UPS　Ｄ：NFS  
  
エ  Ａ：NFS　Ｂ：e-SATA　Ｃ：DWH　Ｄ：NAS  
`
        },
        {
                "mondaiId": 106250020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
事業所内で利用する情報機器の種類は多様化し、それぞれの特性を生かすオペレ  
ーティングシステムOOSUが開発され、それら情報機器に搭載されている。OSに関  
する記述として最も適切なものはどれか。  
  
ア  Javaはモバイル端末向けのマルチウィンドウ、ネットワーク機能を提供する  
OSである。  
  
イ  オープンソースソフトウェアとして提供される、マルチタスク、マルチウィン  
ドウの機能を持つワークステーション用OSもある。  
  
ウ  家電やパソコンの周辺機器などに組み込まれて使用されるOSは、パソコンな  
どの汎用OSを利用したものは無く、その機器ごとに最適なOSが設計され組み  
込まれている。  
  
エ  マルチウィンドウやネットワーク機能を利用できるパソコン用のOSは、CPU  
がマルチタスク機能を備えていることが必要で、複数ユーザが利用するためのユ  
ーザ管理を行う必要がある。`
        },
        {
                "mondaiId": 106250030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
スマートフォン、パソコン、メインフレームなど多様な情報機器を有効に連携さ  
せてビジネスに利用するケースが増えてきた。それらの機器や連携に関する記述と  
して最も適切なものはどれか。  
  
ア  スマートフォンで作ったテキストデータはメインフレームでは利用できない。  
  
イ  スマートフォンのアプリケーションは、パソコンでも作ることが可能である。  
  
ウ  スマートフォンはOSを利用しない。  
  
エ  パソコン用のアプリケーションはメインフレームに対して上位互換になってい  
るので、メインフレームでも使うことができる。  
`
        },
        {
                "mondaiId": 106250040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
Webコンピューティングに使われる言語に関する下記の説明①~③と、それら  
に該当する言語の組み合わせとして最も適切なものを下記の解答群から選べ。  
①SGMLを元とする言語で、WWWで使用されるハイパーテキストを記述する  
ために用いられる。  
②タグと呼ばれる特別な文字列を利用する言語で、ユーザ独自のタグも定義でき  
る。  
③UNIX用のテキスト処理言語として開発されたインタプリタ言語である。  
[解答群]  
  
ア  ①：HTML②：DHTML③：XBRL  
  
イ  ①：HTML②：XML③：Perl  
  
ウ  ①：XML②：DHTML③：XBRL  
  
エ  ①：XML②：HTML③：Perl  
`
        },
        {
                "mondaiId": 106250050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータを利用して、データ処理を行う手順には、データを配列などに一時  
的に記憶させるものがある。  
そのような処理手順に関する以下の文章の空欄Ａ~Ｄに入る用語の組み合わせと  
して、最も適切なものを下記の解答群から選べ。  
・納品された商品を倉庫に保管し、早く納品された商品から出荷するためのシミュ  
レーションを行う場合は、配列などで構成したＡを利用し処理を行う。  
このような処理手順はＢと呼ばれる。  
・四則計算の式2庵(1＋6/3)を、順次処理し計算結果を求める手順では、配列な  
どに構成したＣに式の左項から順に  
，\f，\u000eの値を記憶させ、その  
後、このＣから順次\u000e，\f，  
の値を取り出しながら計算処理を行う。  
このような処理手順はＤと呼ばれる。  
[解答群]  
  
ア  Ａ：キューＢ：FIFOＣ：スタックＤ：LIFO  
  
イ  Ａ：キューＢ：LIFOＣ：スタックＤ：FIFO  
  
ウ  Ａ：スタックＢ：FIFOＣ：キューＤ：LIFO  
  
エ  Ａ：スタックＢ：LIFOＣ：キューＤ：FIFO  
`
        },
        {
                "mondaiId": 106250060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務に使用する情報処理システムの性能は、その構成方法に影響される。構成方  
法の選択においては、機器単体の処理能力で判断するだけではなく、信頼性や保守  
性など多面的な観点から性能の評価を行わなければならない。  
情報処理システムの構成方法に関する記述として最も適切なものはどれか。  
  
ア  集中処理システムに比べてクライアントサーバシステムでは、サーバとクライアントに役割の異なる機能を分担させるので、レスポンスタイムが向上し、信頼  
性も高くなる。  
  
イ  集中処理システムに比べて分散処理システムでは、処理を分割して複数のコン  
ピュータに分配し処理結果を統合する必要があるので、オーバヘッドは減少する  
が、システム全体の故障率は各システムの故障率の和となり信頼性は低下する。  
  
ウ  シングルプロセッサの集中処理システムにもう一組の処理システムを追加し、  
デュアルシステムに変更した場合、レスポンスタイムはほぼ半減し、信頼性も高  
くなる。  
  
エ  複数のプロセッサで同時に処理を行うマルチプロセッサによる処理システムで  
は、シングルプロセッサでの処理に比べてスループットが向上し、信頼性も高く  
なる。  
`
        },
        {
                "mondaiId": 106250070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
会社や商品の紹介、さらに商品のネット上での販売など、自社のWebコンテン  
ツ作成の機会が増えている。これらに用いるソフトウェア資源に関する以下の文章  
の空欄Ａ~Ｄに入る用語の組み合わせとして、最も適切なものを下記の解答群から  
選べ。  
会社や商品の紹介など、基本的なWebコンテンツを作成する場合も、ページ数  
が多くなるとともに、各ページの書式の統一性やデザイン的側面など管理項目が多  
くなる。デザイン性の向上や書式の統一性確保のためには、使いやすいユーザイン  
タフェースによってコンテンツ作成が可能なＡを利用する。また、新商品  
をニュースのような形式で順次告知したいなど、多くのWebページの追加・更新  
などを一元管理したい場合はＢなどが利用できる。  
さらに、商品管理システムと連携した商品販売サイト機能の構築・運用を行いた  
い場合は、Webサーバソフトウェア、Ｃ、スクリプト言語など複数のソ  
フトウェア資源が必要となる。これらが容易に利用できるように、オープンソース  
ソフトウェアのＤなどはそれらをセットにして提供するものである。  
[解答群]  
  
ア  Ａ：オーサリングツールＢ：CMSＣ：DBMSＤ：LAMP  
  
イ  Ａ：コンストラクションツールＢ：CGIＣ：CMSＤ：SOAP  
  
ウ  Ａ：コンテンツフィルタＢ：SOAPＣ：DNSＤ：CGI  
  
エ  Ａ：マルチメディアツールＢ：PHPＣ：FTPＤ：Ajax  
`
        },
        {
                "mondaiId": 106250080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務の中で発生するデータは多くの場合、データベースによって管理する。デー  
タベース全体の構造や仕様を定義するものに、データベーススキーマがある。デー  
タベーススキーマの構成の仕方のひとつに、以下の3つの構成要素を用いるものが  
ある。  
ａ外部スキーマ  
ｂ概念スキーマ  
ｃ内部スキーマ  
上記の構成要素の説明を以下に示す。  
①磁気ディスク装置などへデータを記録する際、どの位置に、どのような物理レ  
コードサイズで記録するかを定義する。  
②アプリケーションから利用することを想定したデータベースの仕様で、アプリ  
ケーションからのデータ入力や出力の方法を定義する。  
③データの論理構造をデータモデルに従って定義したもので、リレーショナルデ  
ータベースでいえば、関係表の定義を指す。  
データベーススキーマの構成要素ａ~ｃと、その説明①~③の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  ａ：①ｂ：②ｃ：③  
  
イ  ａ：②ｂ：③ｃ：①  
  
ウ  ａ：③ｂ：①ｃ：②  
  
エ  ａ：③ｂ：②ｃ：①  
`
        },
        {
                "mondaiId": 106250090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
リレーショナルデータベースに蓄えられた以下のような商品表がある。この商品  
表から、商品区分が筆記具あるいはノートで、販売数量が20以上のデータを抽出  
するSQL文のWHERE部の記述として、最も適切なものを下記の解答群から選べ。  
商品表  
21027  
筆記具9020  
商品区分単価販売数量  
B5判ノート  
ボールペン  
A4判ノート  
商品名  
30cmスチール定規  
クリアファイル  
鉛筆HB  
ノート27012  
定規8505  
筆記具15017  
ファイル25035  
ノート  
[解答群]  
  
ア  販売数量>20ORO商品区分='筆記具'OR商品区分='ノート'U  
  
イ  販売数量>=20AND商品区分='筆記具'OR商品区分='ノート'  
  
ウ  販売数量>=20ANDO商品区分='筆記具'AND商品区分='ノート'U  
  
エ  販売数量>=20ANDO商品区分='筆記具'OR商品区分='ノート'U  
`
        },
        {
                "mondaiId": 106250100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ISOによって提唱されたネットワーク接続にかかわるモデルをOSI参照モデル  
と呼ぶ。OSI参照モデルは、アプリケーション層、プレゼンテーション層、セッシ  
ョン層、トランスポート層、ネットワーク層、データリンク層、物理層の\u0006つの層  
からなる。ネットワーク接続に関する記述として最も適切なものはどれか。  
  
ア  Bluetooth通信は、データリンク層での接続方法の規定だけを用いて通信を行  
う形式であり、物理層を規定していないため、無線通信が��能となる。  
  
イ  TCP/IPにおいて、TCPパケットに発信元のIPアドレスを付けてIPパケッ  
トを作りネット��ークに送り出すのは、ネットワーク層に該当する。  
  
ウ  TCP/IPは、\u0006つの層のうち、\u0010つの層を規定して通信を行う通信プロトコル  
である。  
  
エ  イーサネットの基本仕様は、上位層であるアプリケーション層とプレゼンテー  
ション層の  
つの層で規定される。  
`
        },
        {
                "mondaiId": 106250110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットが普及した現在、業務の様々な場面で情報資源の所在を確認する  
ことやネットワーク機器を識別することが求められる。  
以下のＡ~Ｄの情報資源やネットワーク機器を指示した事例と、その名称の組み  
合わせとして、最も適切なものを下記の解答群から選べ。  
指示事例  
Ａhttp://www.meti.go.jp/  
Ｂmeti.go.jp  
Ｃ11:22:33:dd:ee:ff  
Ｄ192.168.0.1  
[解答群]  
  
ア  Ａ：URLＢ：ドメイン名Ｃ：MACアドレスＤ：IPアドレス  
  
イ  Ａ：URLＢ：ホスト名Ｃ：IPアドレスＤ：MACアドレス  
  
ウ  Ａ：ドメイン名Ｂ：ホスト名Ｃ：ポート番号Ｄ：IPアドレス  
  
エ  Ａ：ドメイン名Ｂ：マシン名Ｃ：SSIDＤ：ポート番号  
`
        },
        {
                "mondaiId": 106250120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
事業所内において個人所有の情報機器を有効に利用したり、事業所外から会社内  
のデータにアクセスして業務を効率的に進めたいとの要望がある。しかし、情報漏  
えい、個人情報管理、通信のセキュリティ確保など、問題も多い。  
このような状況における情報機器の利用に関する以下の文章の空欄Ａ~Ｄに入る  
用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  
パソコンなどを利用して事業所外から社内データにアクセスする場合、  
Ａを利用すれば、第三者の侵入や妨害を受けにくくなる。  
個人所有のスマートフォンやＢを社内業務に利用する場合、私用情報の  
Ｃ、業務用アプリケーションやデータの配布・更新・削除などの際の情報  
セキュリティ管理、社内情報管理などのためにＤを利用することが望まし  
い。  
[解答群]  
  
ア  Ａ：ADSLＢ：タブレットＣ：フォーマット  
Ｄ：BYOD  
  
イ  Ａ：TCP/IPＢ：パソコンＣ：消去  
Ｄ：ルータ  
  
ウ  Ａ：VPNＢ：タブレットＣ：退避  
Ｄ：MDM  
  
エ  Ａ：WiFiＢ：ハンディターミナルＣ：カスタマイズ  
Ｄ：ファイアウォール  
`
        },
        {
                "mondaiId": 106250130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある職場では、下図のようなネットワーク構成でインターネットおよび各情報機  
器の接続を行っている。今、パソコン\fからインターネットに接続ができなくなっ  
た。そのときの対応や考え方に関する記述として最も適切なものを下記の解答群か  
ら選べ。  
ネットワークプリンタ  
ブロードバンドルータ  
スイッチングハブ  
パソコン1パソコン2パソコン3  
インターネット  
[解答群]  
  
ア  インターネットにつながっているブロードバンドルータが原因であるので、そ  
れを交換すれば、インターネットへの接続は復活する。  
  
イ  インターネットにつながらなくなったのは、ブロードキャストストームが起こ  
ったことが原因である。  
  
ウ  パソコン  
からインターネット上にあるサーバなどとの間でパケットのやり取  
りができれば、ブロードバンドルータとインターネットとの接続は正常に機能し  
ている。  
  
エ  ブロードバンドルータとインターネットとの接続が正常に機能していないとす  
るならば、ネットワークプリンタも動作しないはずである。  
`
        },
        {
                "mondaiId": 106250140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
SNSなどの発達によってソーシャルメディアは、個人間の私的な情報交換に利  
用されるだけでなく、ビジネスでも多様に利用されつつある。ソーシャルメディア  
を利用する上での要点や対処法に関する記述として最も適切なものはどれか。  
  
ア  個人が開設したブログに社内で起こった出来事を書いたが、社外秘の情報が含  
まれていたので不適切だと分かった。翌日に削除すれば問題はない。  
  
イ  自分の店舗に来た人の名前を、当人の了解を得ずソーシャルメディアに投稿し  
て広告として利用しても、店舗は公共の場所なので問題はない。  
  
ウ  ソーシャルメディアに投稿したすべての内容は、一定期間保存された後、新規  
投稿内容で上書きされるので、何を投稿してもよい。  
  
エ  自らがソーシャルメディアを使わなくても、ソーシャルメディアの炎上に巻き  
込まれることがある。`
        },
        {
                "mondaiId": 106250150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
通信技術の高度化と機器のインテリジェント化によって、企業の内外で多様で大  
量のデータが蓄積されるようになり、ビッグデータが注目されている。ビッグデー  
タに関する記述として最も適切なものはどれか。  
  
ア  ビッグデータ活用で発展が期待されている経済産業省の｢IT融合新産業｣とは、  
IT産業の構造変化によって創出される新ビジネスのことである。  
  
イ  ビッグデータ活用の鍵となるC2Cは、インターネットで連結されたデータ通  
信の技術である。  
  
ウ  ビッグデータ活用の鍵となるM2Mは、人間と機械との間の自動データ連携の  
技術である。  
  
エ  ビッグデータの活用では、業務取引上生成される構造化データだけでなく非構  
造化データも注目されている。  
`
        },
        {
                "mondaiId": 106250160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
クラウドサービスの活用に関する記述として最も適切なものはどれか。  
  
ア  クラウドサービス事業者がSaaSを提供しているとき、それに必要なサーバを  
自社で持っていない場合がある。  
  
イ  クラウドサービス事業者がパスワードリセット機能を提供している場合、ユー  
ザ企業ではクラウドサービスのすべての利用者にその方法を伝えて、パスワード  
を自分で再設定できるようにしておくのがよい。  
  
ウ  クラウドサービス事業者がバックアップをアーカイブとして確保しているの  
で、ユーザ企業側でバックアップする必要はない。  
  
エ  クラウドサービスの稼働率がSLAOServiceLevelAgreementUで年99.9％以  
上と保証されていれば、不慮のサービス停止の場合でも\f時間以内に稼働状態に  
復旧できる。  
`
        },
        {
                "mondaiId": 106250170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経済産業省の｢IT経営ロードマップ｣では、IT経営を実現するための取り組み  
を、成功企業の事例を踏まえ、以下に示す｢共有化｣、｢柔軟化｣、｢見える化｣の3つ  
に整理している。3つの取り組みの順序として最も適切なものを下記の解答群から  
選べ。  
｢共有化｣  
現場で積み上げられた業務や情報の客観的把握の成果を、経営戦略上必要と思わ  
れる社内外の関係者間において、いつでも効率的に使えるような環境を作り上げる  
こと。  
｢柔軟化｣  
将来予測される外部環境の変化に対して、必要に応じていつでも自社の業務を柔  
軟に組み替えられるようにすること、および、社内外の必要な情報を組み合わせて  
新たなイノベーションを迅速に創出できるようにすること。  
｢見える化｣  
経営から得られる視点に基づき、現場の課題抽出と解決検討の材料につながるよ  
うに、業務や情報を客観的に把握できるようにすること。  
[解答群]  
  
ア  共有化或柔軟化或見える化  
  
イ  柔軟化或見える化或共有化  
  
ウ  見える化或共有化或柔軟化  
  
エ  見える化或柔軟化或共有化  
`
        },
        {
                "mondaiId": 106250180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
多くの中小企業は今まで多様なシステム化を行ってきたが、そのために多くの課  
題が浮上してきている。例えば、システム投資が現行システムのメンテナンス中心  
となり、新たなシステム開発に必要となる業務分析のノウハウを失ったり、リレー  
ショナルデータベースが複数構築されてその整理がなされなかったりすることがあ  
る。それらの課題への対処やシステム開発に関する記述として最も適切なものはど  
れか。  
  
ア  現行のシステムがメインフレーム中心のシステムであるならば、クライアント  
サーバシステムに移行すれば、アプリケーションのメンテナンスは不要になる。  
  
イ  多様に構築されたデータベースを有効に利用するには、すべてのデータベース  
を統合してデータウェアハウスを開発し、旧データベースを廃棄する必要があ  
る。  
  
ウ  複数のデータベースを一元化することで検索などが容易になり業務処理のスピ  
ード向上が期待できるが、それにはデータの重複が問題になる。  
  
エ  要求定義とは、現在の業務の流れを抜本的に見直すことである。  
`
        },
        {
                "mondaiId": 106250190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ソフトウェアのテスト方法には、ホワイトボックステスト、ブラックボックステ  
スト、およびこれらの混合であるグレーボックステストがある。これらのうち、前  
        
者に関する記述として最も適切なものはどれか。  
  
ア  ブラックボックステストでは、すべての場合を網羅した組み合わせテストによ  
っても、すべての組み合わせバグを検出できるとは限らない。  
  
イ  ブラックボックステストは、システム仕様の視点からのテストである。  
  
ウ  ブラックボックステストは、テスト対象が小さい場合にはホワイトボックステ  
ストよりも効果が高い。  
  
エ  ホワイトボックステストは、主にテスト段階の後期に行う。`
        },
        {
                "mondaiId": 106250200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある中小企業では社内のIT人材育成の指針として、独立行政法人情報処理推進  
機構OIPAUの｢共通キャリア・スキルフレームワークOCCSFU｣の適用を考えている。  
2012年\u0005月に公開されたその｢第一版・追補版｣に関する記述として最も適切なも  
のはどれか。  
  
ア  CCSFが参照する\u0010つのスキル標準を基にして、自社の業務に合わせたスキル  
標準を社内で制定する。  
  
イ  CCSFにおけるキャリアとは、システムアーキテクトのような基本戦略系人  
材、ストラテジストのようなソリューション系人材、クリエーターのようなクリ  
  
エ  ーション系人材の3つの人材類型を指す。  
  
ウ  IPAの調査によると、ユーザ企業が利用拡大を考えているITサービスとして  
は、IDCサービスやASPサービスよりも、経営/業務改革コンサルティングや  
システムコンサルティングのほうが多い。  
  
エ  共通キャリア・スキルフレームワークをそのまま適用するのではなく、まず必  
要なタスクからスキルをひも付ける。  
`
        },
        {
                "mondaiId": 106250210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある中小企業では、過去、様々な業務を\f台のホストコンピュータで処理する集  
中処理システムを構築してきた。それを現在のビジネス環境に適応できるように、  
クライアントサーバシステムやクラウドコンピューティングを利用して分散処理す  
るシステムに移行したいと考えている。この企業における分散処理システムの導入  
の仕方に関する記述として、最も適切なものはどれか。  
  
ア  今までの集中処理システムのうち、分散処理システムに移行しやすいアプリケ  
ーションを選択して、分散処理対応アプリケーションを開発し、それを稼働させ  
るとともに、旧システムも復活できるようにしておけば、万が一の不具合にも対  
応できる。  
  
イ  今までの集中処理システムをすべて廃棄し、クライアントサーバシステムに移  
行して、最も良く売れているERPパッケージに置き換えれば、この企業の業務  
は容易に現在のビジネス環境に対応できる。  
  
ウ  今までの集中処理システムを入出力部分とデータ処理部分に切り分け、データ  
処理部分を、アプリケーションごとにクラウドコンピューティングを行う業者に  
移管すれば、業務を中断することなく、現行のシステムを分散処理システムへ円  
滑に移行できる。  
  
エ  集中処理システムから分散処理システムへの移行で大きな問題となるのは、外  
字コードが集中処理システムのアプリケーションの中に埋め込まれてしまってい  
ることである。従って、その外字コードを削除すれば、分散処理対応アプリケー  
ションへの移行は円滑に行うことができる。  
`
        },
        {
                "mondaiId": 106250220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経済産業省は、2007年に｢IT投資価値評価ガイドライン｣の試行版を発表した。  
このガイドラインの試行版で述べられている内容は、ITの投資価値評価における  
基本的な要件を示していると考えられる。IT投資価値評価の基本的な要件として  
最も適切なものはどれか。  
  
ア  IT投資価値評価では、IT投資をインフラ型、業務効率型、戦略型の3つのタ  
  
イ  プに分けてとらえると評価基準などを設定しやすい。  
  
イ  IT投資価値評価は、構想・企画段階で行い、開発完了後には行わない。  
  
ウ  IT投資価値評価をする上でのコスト配賦の基準は、取り扱いデータ数だけで  
ある。  
  
エ  IT投資価値評価をするには、あくまでも金銭的効果を評価基準とするべきで  
あり、ユーザ満足度などを評価基準に組み入れるべきではない。`
        },
        {
                "mondaiId": 106250230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織の情報セキュリティ基準として、｢情報セキュリティマネジメントシステム  
OISMSU適合性評価制度｣が広く使われている。これに関する記述として最も適切な  
ものはどれか。  
  
ア  JISQ20000-1適合性に関する制度である。  
  
イ  適合性の認証制度は、｢認証機関｣、｢要員認証機関｣、｢認定機関｣からなる仕組  
みである。  
  
ウ  適合性の認証登録後は、10年ごとに再認証審査を行う。  
  
エ  標準として決められたセキュリティレベルでのシステム運用を求める。  
`
        },
        {
                "mondaiId": 106250240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある工場では、旋盤で切削・研削して、ある製品を出荷している。平均して製品  
3，000個に\f個の割合で不良品が出る。この製品を1，000個ずつ箱詰めして出荷す  
る時、箱に不良品が含まれない確率を求めたい。この計算に最も適する分布はどれ  
か。  
  
ア  指数分布  
  
イ  正規分布  
  
ウ  超幾何分布  
  
エ  ポアソン分布  
`
        },
        {
                "mondaiId": 106250250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2013,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
統計的仮説を検定する方法には様々なものがあり、適切に使い分ける必要があ  
る。以下の文章の空欄Ａ~Ｃに入る用語の組み合わせとして、最も適切なものを下  
記の解答群から選べ。  
母分散が未知の  
つの母集団の平均の差を検定したい。  
つの母集団からそれぞ  
れ独立に、十分に大きな数の標本を採取できる場合には、標本平均は正規分布に従  
うと考えて、標本の不偏分散を母分散の代わりに使ってＡを適用できる。  
少数の標本しか得られない場合には、母分散の信頼できる推定ができないので、  
Ａを使えない。しかし、  
つの母集団が共に正規分布をし分散が等しい場  
合には、Ｂが利用できる。その場合、  
つの母集団の分散が等しいことを  
検定するには、Ｃを利用することができる。  
[解答群]  
  
ア    
  
ウ    
  
エ    
  
イ    
ＣＢＡ  
z検定t検定F検定  
t検定z検定F検定  
z検定F検定t検定  
F検定t検定z検定  
`
        },
        {
                "mondaiId": 107250010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄Ａ~Ｃに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  
財務省｢法人企業統計年報｣に基づき、中小企業￣非一次産業Eの財務指標の推移を  
2008年度から2010年度の期間について見ると、売上高経常利益率はＡ、  
自己資本比率はＢ、労働生産性はＣしている。ここで中小企業と  
は、中小企業基本法の定義に準ずるものとする。  
[解答群]  
  
ア  Ａ：上昇Ｂ：上昇Ｃ：向上  
  
イ  Ａ：上昇Ｂ：低下Ｃ：向上  
  
ウ  Ａ：上昇Ｂ：低下Ｃ：低下  
  
エ  Ａ：低下Ｂ：上昇Ｃ：向上  
  
オ  Ａ：低下Ｂ：低下Ｃ：低下  
`
        },
        {
                "mondaiId": 107250020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
総務省｢2009年経済センサス安基礎調査｣に基づき、企業ベースで、産業別規模  
別従業者数￣民営、非一次産業Eを見た場合に、中小企業の構成比率が高いものから  
低いものへと並べた組み合わせとして、最も適切なものを下記の解答群から選べ。  
ここで中小企業とは中小企業基本法の定義に準ずるものとする。従業者数は会社の  
常用雇用者数と個人事業所の従業者総数を示す。  
ａ小売業  
ｂ建設業  
ｃ情報通信業  
[解答群]  
  
ア  ａ小売業安ｂ建設業安ｃ情報通信業  
  
イ  ａ小売業安ｃ情報通信業安ｂ建設業  
  
ウ  ｂ建設業安ａ小売業安ｃ情報通信業  
  
エ  ｂ建設業安ｃ情報通信業安ａ小売業  
  
オ  ｃ情報通信業安ａ小売業安ｂ建設業  
`
        },
        {
                "mondaiId": 107250030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  
中小企業の経営は実に多様である。経営者や家族の生計の維持を主な目的とする  
Ａを行う企業もあれば、成長志向・利益志向の強いＢを行う企業  
もある。企業診断を行うに当たっては、こうした経営の特徴を踏まえることも重要  
である。  
[解答群]  
  
ア  Ａ：生業的経営Ｂ：家業的経営  
  
イ  Ａ：生業的経営Ｂ：ベンチャー的経営  
  
ウ  Ａ：専業的経営Ｂ：多角的経営  
  
エ  Ａ：ファミリー・ビジネスＢ：コミュニティ・ビジネス  
  
オ  Ａ：ファミリー・ビジネスＢ：ソーシャル・ビジネス  
`
        },
        {
                "mondaiId": 107250041,
                "name": "第4問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
①  
中小製造業はわが国産業の基盤的存在としてきわめて重要である。大手製造業の  
生産を支えるサポーティングインダストリーとしての役割にとどまらず、大手製造  
業が手掛けない細かな需要に柔軟に対応することで、独自の製品を供給している中  
小製造業も少なくない。  
しかしながら、近年急速に進展するグローバル化や取引関係の変化、技術環境の  
変化等によって、中小製造業を取り巻く環境も激変しており、  
②  
経営状況は大きな影  
響を受けている。  
（設問1）E  
文中の下線部①について、経済産業省｢2009年工業統計表｣に基づき、大企業、  
中小企業￣小規模企業を除くE、小規模企業別に付加価値額を比較した場合、金額  
が多いものから少ないものへと並べた組み合わせとして、最も適切なものを下記  
の解答群から選べ。ここでは従業者数\u000e人以上の事業所単位の統計を企業単位で  
再集計し、企業規模区分は中小企業基本法の定義に準ずるものとする。  
ａ大企業  
ｂ中小企業  
ｃ小規模企業  
[解答群]  
  
ア  ａ大企業安ｂ中小企業安ｃ小規模企業  
  
イ  ａ大企業安ｃ小規模企業安ｂ中小企業  
  
ウ  ｂ中小企業安ａ大企業安ｃ小規模企業  
  
エ  ｃ小規模企業安ａ大企業安ｂ中小企業  
  
オ  ｃ小規模企業安ｂ中小企業安ａ大企業  
`
        },
        {
                "mondaiId": 107250042,
                "name": "第4問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
文中の下線部②について、中小製造業の企業数、従業者数の推移を見た場合、  
最も適切なものはどれか。ここでは経済産業省｢工業統計表｣に基づき、従業者数  
\u000e人以上の事業所単位の統計を企業単位で再集計し、2000年から2009年の期間  
の推移を見るものとする。中小製造業とは中小企業基本法の定義に準ずるものと  
する。  
  
ア  \f企業当たりの従業者数は、減少傾向にある。  
  
イ  資本金規模の大きな企業ほど、企業数が増加傾向にある。  
  
ウ  従業者規模の大きな企業ほど、企業数が増加傾向にある。  
  
エ  従業者規模の小さな企業ほど、企業数が減少傾向にある。  
`
        },
        {
                "mondaiId": 107250051,
                "name": "第5問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
新興国市場が拡大する一方で、国内需要の停滞や取引先の海外移転が進む中、海  
外展開に対する関心が高まっている。現在のところ、資金、販路、人材、現地情報  
の確保等が障壁となって、中小企業全体に占める  
①  
直接輸出を行う企業￣輸出企業Eお  
よび  
②  
海外直接投資を行う企業￣直接投資企業Eの割合は高くないが、中小企業の海外  
展開は中長期的に見れば拡大傾向にある。（設問1）E  
文中の下線部①について、経済産業省｢2009年工業統計表｣に基づき、中小製  
造業における輸出企業の業種構成、従業者規模別の輸出企業の割合を企業数で見  
た場合、最も適切なものはどれか。  
ここでは従業者数\u000e人以上の事業所単位の統計を企業単位で再集計し、産業区  
分は直接輸出を行う事業所を保有する企業の産業分類に従うものとする。中小製  
造業とは中小企業基本法の定義に準ずるものとする。  
  
ア  業種構成を見ると、化学工業の割合が生産用機械器具製造業の割合より高  
い。  
  
イ  業種構成を見ると、金属製品製造業の割合が化学工業の割合より高い。  
  
ウ  業種構成を見ると、生産用機械器具製造業の割合が金属製品製造業の割合よ  
り高い。  
  
エ  従業者規模が小さいほど輸出企業の割合は高い。  
`
        },
        {
                "mondaiId": 107250052,
                "name": "第5問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
文中の下線部②について、総務省｢2009年経済センサス安基礎調査｣に基づ  
き、規模別・業種別の直接投資企業数を見た場合、最も適切なものはどれか。  
なお、ここで直接投資企業とは、海外に子会社￣当該会社が50％超の議決権  
を所有する会社。子会社または当該会社と子会社の合計で50％超の議決権を所  
有する場合と、50％以下でも連結財務諸表の対象となる場合も含む。Eを保有す  
る企業￣個人事業所は含まないEをいう。企業規模区分は、中小企業基本法の定義  
に準ずるものとする。  
  
ア  大企業の直接投資企業数は、中小企業の直接投資企業数より少ない。  
  
イ  中小卸売業の直接投資企業数は、中小製造業の直接投資企業数より多い。  
  
ウ  中小小売業の直接投資企業数は、中小卸売業の直接投資企業数より多い。  
  
エ  中小製造業の直接投資企業数は、中小小売業の直接投資企業数より少ない。  
`
        },
        {
                "mondaiId": 107250060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
東日本大震災以降、購入電力使用の多い業種を中心に、電力需給のひっ迫、電気  
料金の引き上げ等が企業経営に与える影響が懸念されている。経済産業省｢2009年  
工業統計表｣に基づき、製造業の規模別・業種別に購入電力使用額が原材料使用額  
等に占める割合￣購入電力使用額割合Eを見た場合、最も適切なものはどれか。  
ここでは中小企業は従業者数300人以下、大企業は同300人超の企業とし、従業  
者数30人以上の事業所単位の統計を企業単位で再集計する。また、原材料使用額  
等は原材料使用額、燃料使用額、購入電力使用額、委託生産費、製造等に関連する  
外注費および転売した商品の仕入額の合計を示す。  
  
ア  銑鉄鋳物製造業￣鋳鉄管・可鍛鋳鉄を除く中小企業Eの購入電力使用額割合は、  
中小企業平均を下回っている。  
  
イ  中小企業の購入電力使用額割合は、大企業を上回っている。  
  
ウ  鉄鋼業の購入電力使用額割合は、中小企業が大企業を上回っている。  
  
エ  電気めっき業￣表面処理鋼材製造業を除く中小企業Eの購入電力使用額割合は、  
中小企業平均を下回っている。  
`
        },
        {
                "mondaiId": 107250070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経済産業省｢2010年企業活動基本調査｣に基づき、企業規模別・業種別に、日本  
企業が保有する海外子会社の地域構成と地域別の中小企業の海外子会社の業種構成  
を見た場合、最も適切なものはどれか。  
ここで海外子会社とは海外の子会社および関連会社を含む。子会社とは当該会社  
が50％超の議決権を所有する会社を示すが、子会社または当該会社と子会社の合  
計で50％超の議決権を所有する会社と、50％以下でも当該会社が実質的に支配  
している会社を含む。関連会社は当該会社が20％以上50％以下の議決権を所有  
している会社を示すが、15％以上20％未満であっても、重要な影響を与えるこ  
とができる会社を含む。企業規模区分は中小企業基本法の定義に準ずるものとす  
る。  
  
ア  アジアにおける中小企業の海外子会社の3割以上が製造業である。  
  
イ  中小企業の海外子会社の地域構成は、大企業と比べてアジアの割合が低い。  
  
ウ  中小企業の海外子会社の地域構成は、大企業と比べて北米の割合が高い。  
  
エ  中小企業の海外子会社の地域構成は、中国の割合が最も高い。  
`
        },
        {
                "mondaiId": 107250081,
                "name": "第8問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
起業は、産業構造に絶え間のない新陳代謝をもたらして経済成長をけん引すると  
ともに、雇用を創出し、社会の多様性を生み出すという点でも大きな意義を有して  
いるが、  
①  
国際的に見てもわが国の起業活動は低調であると言わざるを得ない。  
他方で起業家の現状を見ると、男女別・年齢層別で  
②  
起業分野や  
③  
起業家数、企業規  
模等に違いがみられ、抱える課題も異なっている。こうした中で起業の促進を図る  
ためには、起業家の特性を十分踏まえて、そのニーズにあわせた適切な政策支援を  
行うことが喫緊の課題となっている。  
（設問1）E  
文中の下線部①について、起業活動に関する国際比較を行っているGlobal  
EntrepreneurshipMonitor￣GEME｢2010GlobalReport｣に基づき、GEMが国際  
同一基準でのアンケート調査等により算出した各国の起業活動率￣Total  
EntrepreneurshipActivityEを比較した場合、<u>最も不適切なものはどれか</u>。  
  
ア  韓国の起業活動率は、日本を上回っている。  
  
イ  ドイツの起業活動率は、日本を上回っている。  
  
ウ  ドイツの起業活動率は、米国を下回っている。  
  
エ  米国の起業活動率は、韓国を下回っている。  
  
オ  米国の起業活動率は、日本を上回っている。  
`
        },
        {
                "mondaiId": 107250082,
                "name": "第8問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
文中の下線部②について、総務省｢2007年就業構造基本調査｣に基づき、｢男性  
の起業家の起業分野￣非一次産業E｣と｢女性の起業家の起業分野￣非一次産業E｣を  
比較した場合、最も適切なものはどれか。ここで起業家とは、過去1年間に職を  
変えたまたは新たに職に就いた者のうち、現在は自営業主￣内職者を含まないEと  
なっている者をいう。  
  
ア  飲食店・宿泊業での起業の割合は、女性が男性よりも高い。  
  
イ  教育・学習支援業での起業の割合は、男性が女性よりも高い。  
  
ウ  小売業での起業の割合は、男性が女性よりも高い。  
  
エ  情報通信業での起業の割合は、女性が男性よりも高い。  
  
オ  製造業での起業の割合は、女性が男性よりも高い。`
        },
        {
                "mondaiId": 107250083,
                "name": "第8問設問3",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005E  
文中の下線部③について、総務省｢2007年就業構造基本調査｣に基づき、年齢  
層別の起業家数、起業家の個人所得、経営する企業の従業者数を男女別に見た場  
合、最も適切なものはどれか。ここで起業家とは、過去1年間に職を変えたまた  
は新たに職に就いた者のうち、現在は自営業主￣内職者を含まないEとなっている  
者をいう。  
  
ア  起業家の所得平均で見ると、男性と女性に格差はない。  
  
イ  従業者数規模で見ると、男性の起業は女性の起業よりも比較的規模が大き  
い。  
  
ウ  年齢層別の起業家数を見ると、女性では20歳代と50歳代が多くなってい  
る。  
  
エ  年齢層別の起業家数を見ると、男女とも60歳代が最も多い。  
  
オ  年齢層別の起業家数を見ると、男性では20歳代と50歳代が多くなってい  
る。  
`
        },
        {
                "mondaiId": 107250090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
内閣府によれば、求職活動をしていないものの、就業を希望している女性の非労  
働力人口は、約342万人存在すると推計されている￣内閣府｢2011年版男女共同参  
画白書｣E。こうした女性の就業促進を図ることは、労働力確保だけではなく、世帯  
所得の増加による消費活動の活発化が期待できるという面でも重要である。  
総務省｢労働力調査｣に基づき、2002年から2010年までの期間における男女別・  
産業別の雇用者数の増減を見た場合、最も適切なものはどれか。ここでいう雇用者  
には、家族従業者を含み、有給役員を含まない。  
  
ア  卸売業・小売業、教育・学習支援業、医療・福祉では、それぞれの産業で女性  
雇用者数が増加している。  
  
イ  製造業、医療・福祉、宿泊業・飲食サービス業では、それぞれの産業で女性雇  
用者数が増加している。  
  
ウ  全産業では、女性雇用者数が減少する一方で男性雇用者数は増加している。  
  
エ  全産業では、男性雇用者数が減少する一方で女性雇用者数は増加している。  
  
オ  全産業では、男性雇用者数と女性雇用者数とも増加している。  
`
        },
        {
                "mondaiId": 107250100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年の中小企業の国内外における特許出願件数の推移を見た場合、最も適切なも  
のはどれか。ここでは中小企業が2009年から2011年に行った国内特許出願件数と  
海外特許出願件数￣海外特許出願のうち特許協力条約により日本に提出された国際  
出願件数Eを比較する。中小企業とは中小企業基本法の定義に準ずるものとする。  
  
ア  国内特許出願件数と海外特許出願件数とも減少傾向にある。  
  
イ  国内特許出願件数と海外特許出願件数とも増加傾向にある。  
  
ウ  国内特許出願件数は減少傾向にあるが、海外特許出願件数は増加傾向にある。  
  
エ  国内特許出願件数は増加傾向にあるが、海外特許出願件数は減少傾向にある。`
        },
        {
                "mondaiId": 107250110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
東京商工リサーチ｢全国企業倒産白書｣に基づき、2001年から2011年の期間につ  
いての企業倒産動向￣ただし負債金額1，000万円以上Eを見た場合、最も適切なもの  
はどれか。  
  
ア  資本金1億円未満の企業の倒産件数は、2000年代初めが最も多い。  
  
イ  資本金1億円未満の企業の倒産件数は、2000年代末以降増加している。  
  
ウ  要因別倒産件数構成比を見ると、販売不振を要因とする倒産は減少傾向にあ  
る。  
  
エ  要因別倒産件数構成比を見ると、連鎖倒産を要因とする倒産は増加傾向にあ  
る。  
`
        },
        {
                "mondaiId": 107250121,
                "name": "第12問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
中小企業の財務構造を大企業と比較した場合、  
①  
金融機関借入比率は高く、自己資  
本比率は低いことが指摘できる。この要因のひとつとして、  
②  
情報の非対称性が大き  
く、信用力の乏しい中小企業にとっては、  
③  
資本市場からの資金調達が容易でないこ  
とがあげられる。  
（設問1）E  
文中の下線部①について、中小企業庁の調べに基づき、金融機関別中小企業向  
け貸出残高の推移を、2006年から2011年の期間について見た場合、最も適切な  
ものはどれか。ここで政府系金融機関とは、日本政策金融公庫の中小企業事業と  
国民生活事業、商工組合中央金庫をいう。中小企業とは中小企業基本法の定義に  
準ずるものとする。  
  
ア  国内銀行の貸出残高は、増加傾向にある。  
  
イ  政府系金融機関の貸出残高は、減少傾向にある。  
  
ウ  中小企業向け総貸出残高に占める政府系金融機関のシェアは、約  
割で推移  
している。  
  
エ  中小企業向け総貸出残高は、減少傾向にある。  
`
        },
        {
                "mondaiId": 107250122,
                "name": "第12問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
文中の下線部②について、情報の非対称性からは様々な問題が発生する。この  
うち金融機関が、融資後に発生が懸念される融資先のモラルハザードを防ぐため  
に行っている方法として、<u>最も不適切なものはどれか</u>。  
  
ア  クレジットスコアリングモデルの利用  
  
イ  経営財務情報提供の義務付け  
  
ウ  資金使途の制限  
  
エ  不動産担保の徴求`
        },
        {
                "mondaiId": 107250123,
                "name": "第12問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005E  
文中の下線部③について、直接金融による資金調達手法として、最も適切なも  
のはどれか。  
  
ア  売掛債権担保付借入  
  
イ  社債の発行  
  
ウ  当座借越￣貸越E契約の締結  
  
エ  動産担保付借入  
`
        },
        {
                "mondaiId": 107250130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
わが国の中小企業金融においては、公的���信用補完制度が大きな役割を果たして  
いる。信用補完制度に関する記述として、最も適切なものはどれか。  
  
ア  信用保証協会が金融機関に代位弁済した場合、日本政策金融公庫から保険金が  
支払われる。  
  
イ  信用保証協会の保証承諾は、2007年度から2011年度の期間、件数、金額とも  
増加している。  
  
ウ  信用保証協会のリスク軽減のため、日本政策金融公庫による再保証制度が設け  
られている。  
  
エ  信用保証協会は金融機関から斡旋を受けた場合、中小企業の負担軽減のため保  
証引受に関する審査を免除する。`
        },
        {
                "mondaiId": 107250140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
東日本大震災を契機として、従業員の生命と会社の財産を守るために、中小企業  
においてもBCP￣事業継続計画Eの重要性が改めて認識された。中小企業庁｢中小企  
業BCPガイド￣2008年\u0005月E｣におけるBCP策定に当たってのポイントに関する記  
述として、最も適切なものはどれか。  
  
ア  緊急時には不測の事態が発生するため目標復旧時間の設定は避けること。  
  
イ  現場感覚、ボトムアップが重要であることから従業員に策定を任せること。  
  
ウ  すべての事業の継続、同時復旧を目指して幅広く計画を立案すること。  
  
エ  取引先とあらかじめ計画について協議しておくこと。  
`
        },
        {
                "mondaiId": 107250150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
経営資源の限られる中小企業にとって、金融機関、特に地域の金融機関と良好な  
関係を築くことは重要であるが、同じ金融機関でも業態によって制度的な相違点が  
ある。地域金融機関である信用金庫と信用組合について見た場合、最も適切なもの  
はどれか。  
  
ア  信用金庫の会員以外への貸出に特段の制限はない。  
  
イ  信用金庫の会員資格に特段の制限はない。  
  
ウ  信用組合と信用金庫の預金受入れに特段の制限はない。  
  
エ  信用組合と信用金庫は協同組織形態の非営利法人である。  
  
オ  信用組合の組合員以外への貸出に特段の制限はない。`
        },
        {
                "mondaiId": 107250160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　中小企業基本法の定義に基づく、小規模企業者と中小企業者に関する記述として、最も適切なものはどれか。  
  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>


ア  従業員数3人の飲食業者は小規模企業者に該当し、資本金6，000万円で従業員数が60人の飲食業者は中小企業者に該当する。  
  
イ  従業員数5人の小売業者は小規模企業者に該当し、資本金8，000万円で従業員数が70人の小売業者は中小企業者に該当する。  
  
ウ  従業員数10人の卸売業者は小規模企業者に該当し、資本金3，000万円で従業員数が80人の卸売業者は中小企業者に該当する。  
  
エ  従業員数10人の建設業者は小規模企業者に該当し、資本金5，000万円で従業員数が200人の建設業者は中小企業者に該当する。<hint hidden='true'>**
←⭕
**</hint>   
`
        },
        {
                "mondaiId": 107250171,
                "name": "第17問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
中小企業基本法では、中小企業政策において特に重点的に支援をしていく施策対  
象及び事業活動の支援を、基本方針として以下のとおり規定している。  

1．中小企業者の経営の革新及び創業の促進並びにＡを図ること。  
        
．中小企業の経営資源の確保の円滑化を図ること、中小企業に関する取引の適正  
化を図ること等により、中小企業の経営基盤の強化を図ること。  
\u0005．経済的社会的環境の変化に即応し、中小企業の経営の安定を図ること、事業の  
転換の円滑化を図ること等により、その変化への適応の円滑化を図ること。  
\u000e．中小企業に対する資金の供給の円滑化及び中小企業のＢを図ること。  
（設問1）E  
文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  企業間連携の促進  
  
イ  新事業展開の促進  
  
ウ  創造的な事業活動の促進  
  
エ  地域経済への貢献の促進`
        },
        {
                "mondaiId": 107250172,
                "name": "第17問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
文中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  事業機会の確保  
  
イ  自己資本の充実  
  
ウ  人材確保の支援  
  
エ  投資の円滑化  
`
        },
        {
                "mondaiId": 107250180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
関東地方のX県で洋菓子小売業￣従業員\u0005人Eを営むA氏は、経営を改善するた  
めの資金を必要としている。A氏から相談を受けた中小企業診断士のB氏は、小  
規模事業者経営改善資金融資￣マル経E制度を紹介することにした。このときのB  
氏の説明として最も適切なものはどれか。  
  
ア  原則として同一地区で\u000fか月以上事業を行っていることが必要です。  
  
イ  商工会・商工会議所等の経営指導を原則\u0005か月以上受けていることが必要で  
す。  
  
ウ  日本政策金融公庫が融資審査と資金の貸付けを行います。  
  
エ  無担保・無保証人で、金利は日本政策金融公庫の基準金利になります。`
        },
        {
                "mondaiId": 107250190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢中小企業憲章｣では、政府の中小企業政策の具体的な取り組みの柱として、\u0010つ  
の｢行動指針｣を定めている。  
この行動指針にあげられているものとして、<u>最も不適切なものはどれか</u>。  
  
ア  海外展開を支援する。  
  
イ  人材の育成・確保を支援する。  
  
ウ  大企業との生産性・賃金の格差を是正する。  
  
エ  地域及び社会に貢献できるよう体制を整備する。  
  
オ  中小企業の立場から経営支援を充実・徹底する。  
`
        },
        {
                "mondaiId": 107250200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業A社の社長は、｢取引先企業の倒産によって自らも連鎖倒産や経営危機  
に陥る事態を防止したい｣と考えている。この社長から相談を受けた中小企業診断  
士のB氏は、｢中小企業倒産防止共済制度｣を紹介することにした。B氏の説明と  
して、最も適切なものはどれか。  
  
ア  掛金納付月数が\u0005か月以上ある加入者について、取引先の倒産や夜逃げなどに  
よって、売掛金債権等の回収困難が生じたときに共済金の貸付けを行います。  
  
イ  共済金の貸付限度額は、回収が困難となった売掛金債権等の額と掛金総額の  
10倍に相当する額のいずれか大きい額です。  
  
ウ  共済金の貸付けは、無担保、無保証、低利で行われます。  
  
エ  毎年の掛金は、必要経費￣個人Eまたは損金￣法人Eに算入できます。`
        },
        {
                "mondaiId": 107250211,
                "name": "第21問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
｢  
①  
中小ものづくり高度化法｣は、高度なものづくり基盤技術を有する中小企業者の  
存在がわが国製造業を支えていることを踏まえ、｢ものづくり基盤技術｣の高度化へ  
の研究開発等を支援することにより、わが国製造業の国際競争力の強化や新たな事  
業の創出を図ることを目的としている。  
ものづくりの基盤となる技術のうち、主として中小企業が担い、その高度化を図  
ることがわが国製造業の国際競争力の強化や新たな事業の創出に資するものを｢  
②  
特  
定ものづくり基盤技術｣として指定する。  
        
（設問1）E  
文中の下線部①に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  川上製造業者のニーズを踏まえた研究開発等の実施方法を整理し、高度化指  
針として定めている。  
  
イ  中小企業者は、単独または共同で、｢特定研究開発等計画｣を作成する。  
  
ウ  中小企業者は、｢特定研究開発等計画｣を作成し、経済産業大臣の認定を受け  
る。  
  
エ  ｢特定研究開発等計画｣の認定を受けた中小企業者に対して、｢中小企業信用  
保険法の特例｣、｢特許料及び特許審査請求料の特例｣等の支援措置が整備され  
ている。`
        },
        {
                "mondaiId": 107250212,
                "name": "第21問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
文中の下線部②の｢特定ものづくり基盤技術｣に指定されている技術として、最  
も不適切なものはどれか。  
  
ア  金属プレス加工に係る技術  
  
イ  鋳造に係る技術  
  
ウ  燃料電池に係る技術  
  
エ  めっきに係る技術  
`
        },
        {
                "mondaiId": 107250220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本政策金融公庫の｢女性、若者／シニア起業家支援資金｣の貸付対象者になるも  
のとして、最も適切なものはどれか。  
  
ア  新たに事業を始める50歳の男性  
  
イ  新規開業して\u0005年の35歳の男性  
  
ウ  新規開業して\u000e年の40歳の女性  
  
エ  新規開業して10年の65歳の女性`
        },
        {
                "mondaiId": 107250231,
                "name": "第23問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
中小企業新事業活動促進法は、創業の支援、中小企業の経営革新、異分野の中小  
企業の連携による新事業分野開拓の支援を行う等により、中小企業の新たな事業活  
動の促進を図ることを目的としている。このうち、経営革新支援事業では、中小企  
業が経営革新計画の承認を受けると、低利の融資制度や信用保証の特例等の多様な  
支援を受けることができる。  
（設問1）E  
文中の下線部に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  経営革新計画は、都道府県知事または経済産業局等の国の機関に申請する。  
  
イ  個別の中小企業者のみならず、組合および任意グループも支援対象となる。  
  
ウ  支援を受けるためには、経営革新計画承認に加え、利用を希望する支援策の  
実施機関による審査が必要となる。  
  
エ  新事業活動とは、①新商品の開発、②新商品の生産、③新商品の販売のいず  
れにも該当する取り組みをいう。  
`
        },
        {
                "mondaiId": 107250232,
                "name": "第23問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
中小小売業のA社は、中小企業新事業活動促進法に基づく支援を受けるため、  
経営革新計画を作成した。\u0005年後の計画は下表のとおりである。この場合、経営  
革新計画の数値目標となる｢付加価値額｣として、最も適切なものを下記の解答群  
￣単位：百万円Eから選べ。  
営業利益  
経常利益  
税引前当期純利益  
当期純利益  
参  
考  
売上高  
売上総利益  
売上原価  
販売費及び一般管理費  
￣人件費E  
￣その他E  
営業外収益  
営業外費用  
特別利益  
0  
40  
45  
15  
60  
100  
100  
200  
\u0005年後計画  
特別損失  
法人税、住民税及び事業税  
減価償却費  
借入金残高50  
18  
23  
16  
39  
0  
0  
39  
1  
￣単位：百万円E  
[解答群]  
  
ア  54  
  
イ  55  
  
ウ  72  
  
エ  73  
  
オ  100  
`
        },
        {
                "mondaiId": 107250241,
                "name": "第24問設問1",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
ＪＡＰＡＮブランド育成支援事業は、自らが持つ素材や技術等の強み・弱みを踏  
まえた戦略に基づいて行う商品の開発や、海外見本市への出展等を行うプロジェク  
トを支援することにより、中小企業の海外販路開拓の実現を図るものである。この  
事業の支援内容は、｢段階への支援｣と｢海外市場開拓段階への支援｣に分  
かれている。  
（設問1）E  
文中の下線部の支援対象として、<u>最も不適切なものはどれか</u>。  
  
ア  NPO法人  
  
イ  組合  
  
ウ  商工会  
  
エ  商工会議所  
  
オ  単独の中小企業`
        },
        {
                "mondaiId": 107250242,
                "name": "第24問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
E  
文中の空欄に入る語句として、最も適切なものはどれか。  
  
ア  研究開発  
  
イ  商品開発  
  
ウ  商品企画  
  
エ  戦略策定  
  
オ  ビジネスマッチング  
`
        },
        {
                "mondaiId": 107250250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  
下請中小企業振興法に基づいて、経済産業大臣は、下請中小企業の振興を図るた  
め｢振興基準｣を定めている。振興基準の主な内容は、以下のとおりである。  

1．下請事業者の生産性の向上及び製品若しくは情報成果物の品質若しくは性能又  
は役務の品質の改善に関する事項  
        
．Ａに関する事項  
\u0005．下請事業者の施設又は設備の導入、技術の向上及び事業の共同化に関する事項  
\u000e．Ｂに関する事項  
\u0007．下請事業者の連携の推進に関する事項  
[解答群]  
  
ア  Ａ：親事業者からの受注機会の増大及び受注確保対策  
Ｂ：下請適正取引推進のための遵守事項その他適正化のための措置  
  
イ  Ａ：親事業者からの受注機会の増大及び受注確保対策  
Ｂ：対価決定の方法、納品の検査の方法その他取引条件の改善  
  
ウ  Ａ：親事業者の発注分野の明確化及び発注方法の改善  
Ｂ：下請適正取引推進のための遵守事項その他適正化のための措置  
  
エ  Ａ：親事業者の発注分野の明確化及び発注方法の改善  
Ｂ：対価決定の方法、納品の検査の方法その他取引条件の改善  
`
        },
        {
                "mondaiId": 107250260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
部品製造業￣従業員10人EのA社の経営者は、親企業との取引に関して、｢代金  
の入金が遅れる｣、｢代金の値引きを要求される｣という悩みを抱えている。この経  
営者から相談を受けた中小企業診断士のB氏は、下請かけこみ寺事業を紹介する  
ことにした。  
このときのB氏のアドバイスとして、最も適切なものはどれか。  
  
ア  親事業者の義務違反がある場合には、中小企業基盤整備機構から弁護士の無料  
派遣を受けることができます。  
  
イ  企業間取引に関する様々な相談に相談員等が親身になって応じます。弁護士相  
談も行っていて、相談費用の\u0005分の  
の補助を受けることができます。  
  
ウ  裁判外紛争解決手続￣ADREにより簡易・迅速な紛争解決を行います。  
  
エ  東京、名古屋および大阪に本部が設置されています。商工会議所、商工会など  
に寄せられた相談も取り次がれるよう連携しています。`
        },
        {
                "mondaiId": 107250270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
カウンセリング等の就職支援や中小企業の魅力発信等の人材確保支援をワンスト  
ップで提供するため、各都道府県には｢ジョブカフェ｣が設置されている。  
｢ジョブカフェ｣に関する記述として、最も適切なものはどれか。  
  
ア  高齢者のためのワンストップサービスセンターである。  
  
イ  仕事と家庭を両立する女性のためのワンストップサービスセンターである。  
  
ウ  若年者のためのワンストップサービスセンターである。  
  
エ  女性、若年者、高齢者のためのワンストップサービスセンターである。  
`
        },
        {
                "mondaiId": 107250280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業者には、税制上の様々な特別措置がある。中小企業に適用される税制に  
関する記述として、最も適切なものはどれか。  
  
ア  個人事業者には地方税においても、住民税や事業税の専従者給与控除、事業税  
の事業主控除などの制度がある。  
  
イ  資本金  
億円で従業者数200人の製造業は、中小企業のための軽減税率の対象  
になる。  
  
ウ  中小企業については、年500万円までの交際費支出のうち\u0010割まで損金算入制  
度が講じられている。  
  
エ  中小企業のための軽減税率は、年所得1，000万円以下の部分に適用される。`
        },
        {
                "mondaiId": 107250290,
                "name": "第29問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢商業活性化アドバイザー派遣事業｣は、商業活性化の取り組みを行う際に、専門  
家によるアドバイスを受けることができる事業である。この事業に関する記述とし  
て、最も適切なものはどれか。  
  
ア  アドバイザーを商工会議所・商工会に登録し、派遣する。  
  
イ  対象となるのは、中心市街地活性化協議会、まちづくり会社である。  
  
ウ  中小企業新事業活動促進法に基づいて承認を受けた経営革新計画を実施する個  
店に対し、助言・診断を行う。  
  
エ  派遣期間が一定期間内であれば、利用者の自己負担はないが、一定期間を超え  
た場合、派遣費用の一部が自己負担となる。  
`
        },
        {
                "mondaiId": 107250300,
                "name": "第30問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業等協同組合制度は、中小企業等協同組合法に基づくもので、中小規模の  
事業者、勤労者等が、組織化し、相互扶助の精神に基づき、協同して事業に取り組  
むことによって、技術・情報・人材等お互いの不足する経営資源の相互補完を図る  
ための制度である。  
上記の法律に基づく中小企業等協同組合には、事業協同組合、火災共済協同組  
合、企業組合等があり、それぞれの機能・目的に応じて積極的に活動することによ  
り、中小企業の成長発展に大きく寄与している。  
このうち、企業組合に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  組合員の2分の1以上は、組合の行う事業に従事しなければならない。  
  
イ  組合員の経営の合理化と取引条件の改善を図ることを目的とした組織である。  
  
ウ  組合員は、原則として組合の事業に従事して報酬を受ける勤労者的存在とな  
る。  
  
エ  組合自体が\f個の企業体として事業を行う。  
`
        },
        {
                "mondaiId": 107250310,
                "name": "第31問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢新事業創出支援事業｣は、中小企業者等の事業計画作りから、販路開拓に至るま  
で、一貫して支援する制度である。マーケティング等に精通した専門家が、事業段  
階に応じ支援を行う。  
なお、この事業の対象となるのは、3つの法律に基づく事業計画の認定を目指す  
中小企業者等である。  
3つの法律として、<u>最も不適切なものはどれか</u>。  
  
ア  中小企業新事業活動促進法  
  
イ  中小企業地域資源活用促進法  
  
ウ  中小小売商業振興法  
  
エ  農商工等連携促進法`
        },
        {
                "mondaiId": 107250320,
                "name": "第32問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2013,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢ものづくり中小企業・小規模事業者試作開発等支援補助金｣は、中小企業経営力  
強化支援法の認定経営革新等支援機関￣認定支援機関E等と連携しつつ、ものづくり  
中小企業・小規模事業者が実施する試作品の開発や設備投資等を支援するものであ  
る。この施策の対象となるための要件として、<u>最も不適切なものはどれか</u>。  
  
ア  企業、大学、公的研究機関等との連携を行う事業であること。  
  
イ  顧客ニーズにきめ細かく対応した競争力強化を行う事業であること。  
  
ウ  ｢中小ものづくり高度化法｣に基づいて指定された22分野の技術を活用した事  
業であること。  
  
エ  認定支援機関によって事業計画の実効性等が確認されていること。  
`
        },
        {
                "mondaiId": 101240010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
内閣府の景気動向指数において、先行系列の経済指標として、最も適切なものは  
どれか。  
  
ア  機械製造業者が受注する設備用機械の受注状況を調査したものである｢実質機  
械受注，船舶・電力を除く民需O｣  
  
イ  生産された製品の出荷動向を総合的に表した指標である｢鉱工業生産財出荷指  
数｣  
  
ウ  生産量と生産能力の比から求めた指標である｢稼働率指数，製造業O｣  
  
エ  設備投資を、投資主体の資産増加として実現した段階でとらえたものである  
｢実質法人企業設備投資，全産業O｣`
        },
        {
                "mondaiId": 101240020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
物価指数の作成に関する説明として、最も適切なものはどれか。  
  
ア  総務省統計局が公表している消費者物価指数は、パーシェ式で計算されてい  
る。  
  
イ  ラスパイレス式の特色は、比較時点の構造変化に伴う品目の重要度の変化を、  
  
ウ  エイトに取り込めるところである。  
  
ウ  ラスパイレス式は、基準時点ウエイトを採用する加重総和法算式を用いてい  
る。  
  
エ  隣接年次間の連環指数を使って計算される連鎖指数は、比較時点の構造変化に  
伴う品目の重要度の影響を取り除く目的で計算されている。  
        
`
        },
        {
                "mondaiId": 101240030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、  
つの国について、物価上昇率と失業率の関係を見るために作成された  
ものである。なお、統計は、2000年?2010年暦年と2011年Q1?Q3の\u0005四半期  
データにもとづき、中国のみは2010年までのデータである。  
これらの図の説明として、最も適切なものを下記の解答群から選べ。  
出所：内閣府4世界経済の潮流9，2011年ⅡO  
[解答群]  
  
ア  アメリカのデータには、失業率と物価上昇率との間に負の相関が緩やかに見  
てとれるので、オークンの法則が部分的には満たされている。  
  
イ  英国のデータは、短期的なフィリップス曲線の有する典型的な特性とは異な  
る姿を示している。  
        
        
  
ウ  中国のデータは、ペティー=クラークの法則が示した物価上昇率の停滞を表  
す状況を示している。  
  
エ  ブラジルのデータによれば、物価上昇率と失業率の値がともに10％を超え  
ていたが、こうした状況はリフレーションといわれる。`
        },
        {
                "mondaiId": 101240040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、2000年第\f四半期から2011年第\u0005四半期までの、日本の四半期ごとの  
貿易収支、サービス収支、所得収支、経常移転収支を示したものである。図中のＡ  
?Ｄに当てはまる最も適切なものの組み合わせを下記の解答群から選べ。  
[解答群]  
  
ア  Ａ：貿易収支Ｃ：所得収支  
  
イ  Ａ：貿易収支Ｄ：所得収支  
  
ウ  Ｂ：所得収支Ｄ：経常移転収支  
  
エ  Ｃ：貿易収支Ｄ：経常移転収支  
        
`
        },
        {
                "mondaiId": 101240050,
                "name": "第5問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表は、総務省が公表した｢家計調査報告，二人以上世帯O｣2010年11月分、2011  
年11月分にある勤労者世帯の収支内訳から、実収入，世帯主収入、配偶者の収入、  
他の世帯員の収入等の合計O、消費支出、非消費支出，所得税、社会保険料等Oの金  
額を抜き出したものである。これら勤労者世帯の限界消費性向を求めるとき、その  
求め方として最も適切なものを下記の解答群から選べ。  
F暗73，480円E暗295，066円D暗424，272円2011年11月  
C暗74，018円B暗309，548円A暗431，281円2010年11月  
非消費支出消費支出実収入  
[解答群]  
  
ア    
E  
D安F  
  
イ    
B＋E  
A＋D  
  
ウ    
E安B  
D安A  
  
エ    
B＋E  
，A安CO＋，D安FO  
  
オ    
E安B  
，D安FO安，A安CO  
        
`
        },
        {
                "mondaiId": 101240060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表は、国土交通省が公表している｢建設工事受注動態統計調査結果｣の一部を抜  
き出したものである。生産物市場の均衡条件が次のように与えられるとき、表にあ  
る数字の解釈として、最も適切なものを下記の解答群から選べ。  
Y暗C＋I＋G＋X安M  
Y：GDPC：民間消費支出I：民間投資支出G：政府支出X：輸出  
M：輸入  
2，028，0506，980，213平成22年度  
564，9662，308，2387，167，601平成21年度  
公共機関民間等  
海外  
国内  
513，701  
，単位：百万円O  
[解答群]  
  
ア  海外からの建設工事発注額減少は、民間投資支出の減少につながり、それは  
生産物供給の減少から、日本のGDPを減少させることになる。  
  
イ  海外からの建設工事発注額減少は、輸入の減少につながり、それは生産物供  
給の減少から、日本のGDPを減少させることになる。  
  
ウ  公共機関からの建設工事発注額減少は、政府支出の減少につながり、それは  
生産物需要の減少から、日本のGDPを減少させることになる。  
  
エ  民間等からの建設工事発注額減少は、民間消費支出の減少につながり、それ  
は生産物需要の減少から、日本のGDPを減少させることになる。  
        
`
        },
        {
                "mondaiId": 101240070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
家計、企業、政府から構成される閉鎖経済モデルを考える。各記号は、Y：  
GDP、C：民間消費支出、I：民間投資支出、G：政府支出、T：租税収入を意味  
し、単位は兆円とする。  
生産物市場の均衡条件Y暗C＋I＋G  
消費関数C暗0.8，Y安TO＋20  
租税関数T暗0.25Y安10  
民間投資支出I暗32  
政府支出G暗20  
このモデルから導かれる記述として、最も適切なものはどれか。  
  
ア  生産物市場が均衡しているときのGDPは360兆円である。  
  
イ  生産物市場が均衡しているときの財政収支，T安GOは、30兆円の赤字になる。  
  
ウ  政府支出乗数は\u0007である。  
  
エ  政府支出を10兆円拡大させると、生産物市場が均衡しているときのGDPは  
25兆円増加する。  
        
`
        },
        {
                "mondaiId": 101240081,
                "name": "第8問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
金融政策およびマネーサプライ，マネーストックOに関する下記の設問に答えよ。  
（設問1）O  
金融政策に関する記述として、最も適切なものの組み合わせを下記の解答群か  
ら選べ。  
ａ貨幣の供給メカニズムで中央銀行が直接的に操作するのは、マネタリーベー  
ス，ハイパワードマネーOというよりも、マネーサプライ，マネーストックOであ  
る。  
ｂ市中銀行の保有する現金を分子、預金を分母とする比率が上昇すると、信用  
乗数，貨幣乗数Oは上昇する。  
ｃ市中銀行から中央銀行への預け金を分子、市中銀行の保有する預金を分母と  
する比率が上昇すると、信用乗数，貨幣乗数Oは低下する。  
ｄ信用乗数，貨幣乗数Oは、分子をマネーサプライ，マネーストックO、分母をマ  
ネタリーベース，ハイパワードマネーOとして算出される比率のことである。  
[解答群]  
  
ア  ａとｂイａとｄウｂとｃエｃとｄ`
        },
        {
                "mondaiId": 101240082,
                "name": "第8問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　日本銀行が公表しているマネーサプライ統計は、2008年に、マネーストック統計へと見直しが行われた。この見直しに関する説明として、最も適切なものはどれか。  
  
<hint hidden='true'>**
マネタリーベース、マネーストックに関する問題です。各用語の定義は、画面下部の類似問題を参照ください。  
** </hint>

ア  <hintTarget>証券会社</hintTarget>が保有する現金通貨が、M1に含まれることになった。  

<hint hidden='true'>**
❌証券会社は対象外です
** </hint>

イ  ゆうちょ銀行への要求払預金が、M1に含まれることになった。  

<hint hidden='true'>**
⭕ゆうちょ銀行が保有する預金はM1に含まれます
** </hint>
  
ウ  預金取扱機関が保有する現金通貨が、M1に含まれることになった。  

<hint hidden='true'>**
❌本設問で問われている2008年の変更点ではありません（M1に含まれる点は正しい）
** </hint>
  
エ  預金取扱機関への定期性預金が、M1に含まれることになった。  
        
<hint hidden='true'>**
❌本設問で問われている2008年の変更点ではありません（M1に含まれる点は正しい）
** </hint>
  
`
        },
        {
                "mondaiId": 101240090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
IS-LMモデルでは、横軸にGDP、縦軸に利子率をとり、IS曲線とLM曲線を  
描く。IS曲線とLM曲線の形状とシフトに関する説明として、最も適切なものは  
どれか。  
  
ア  GDPが増えると貨幣の取引需要も大きくなることから、貨幣市場の均衡利子  
率は低くなり、LM曲線は右上がりに描かれる。  
  
イ  貨幣供給量を増やすと、貨幣市場を均衡させる利子率が低下することから、  
LM曲線は上方向にシフトする。  
  
ウ  政府支出を拡大させると、生産物の供給も拡大することから、IS曲線は右方  
向にシフトする。  
  
エ  利子率が高い水準にあると投資水準も高くなると考えられることから、生産物  
市場の均衡を表すIS曲線は、右下がりに描かれる。  
  
オ  流動性のわなが存在する場合、貨幣需要の利子弾力性がゼロになり、LM曲線  
は水平になる。`
        },
        {
                "mondaiId": 101240100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
効率賃金仮説に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  縦軸に労働者の効率を、横軸に賃金をとり、効率曲線を描くと、賃金は、この  
効率曲線が原点から発する直線に接するところに決まる。  
  
イ  賃金水準と労働者の効率との関係を表す効率関数では、賃金水準が上昇するに  
つれて、労働者の効率はかえって低下すると想定する。  
  
ウ  賃金は、企業によって、自社内の労働者の効率を考慮して決定されると考え  
る。  
  
エ  賃金は、企業によって、賃金\f単位あたりで測った効率が最大になる水準に決  
定されると考える。  
        
`
        },
        {
                "mondaiId": 101240110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、日本の家計貯蓄率の推移を表したものである。この図からは、可処分所  
得に対する貯蓄の比率が、2008年を底に回復していることが見てとれる。次の成  
長会計式を用いて、貯蓄が産出量に与える影響の説明として、最も適切なものを下  
記の解答群から選べ。  
ΔY  
Y  
暗  
ΔA  
A  
＋α  
ΔK  
K  
＋β  
ΔL  
L  
Y：産出量A：全要素生産性K：資本L：労働  
α：産出の資本に関する弾力性β：産出の労働に関する弾力性  
出所：OECD，EconomicOutlook，No.90.  
[解答群]  
  
ア  家計の貯蓄が、海外での投資に向かえば、それは成長会計式の右辺第\f項  
ΔA  
A  
の値を増やし、国内産出量の増加につながる。  
  
イ  家計の貯蓄が、海外での投資に向かえば、それは成長会計式の右辺第\u0015項  
α  
ΔK  
K  
の値を増やし、国内産出量の増加につながる。  
  
ウ  家計の貯蓄が、海外での投資に向かえば、それは成長会計式の右辺第\u0005項  
β  
ΔL  
L  
の値を増やし、国内産出量の増加につながる。  
  
エ  家計の貯蓄が、国内での投資に向かえば、それは成長会計式の右辺第\u0015項  
α  
ΔK  
K  
の値を増やし、国内産出量の増加につながる。  
  
オ  家計の貯蓄が、国内での投資に向かえば、それは成長会計式の右辺第\u0005項  
β  
ΔL  
L  
の値を増やし、国内産出量の増加につながる。  
        
`
        },
        {
                "mondaiId": 101240120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄Ａ~Ｄに当てはまる語句として、最も適切なものの組み合わせを  
下記の解答群から選べ。  
市場において、供給量が需要量を上回っているならば、市場では、価格が  
Ａする圧力が生じ、逆に、需要量が供給量を上回っているならば、価格が  
Ｂする圧力が生じる。  
その市場に、市場の内部ではコントロールができない、また、市場がその発生を  
抑止できない力が加わった場合を考える。例えば、突然の自然災害によって、工場  
や機械などの生産設備が破壊された場合、Ｃが減少することで、  
Ｃ曲線はＤ方向にシフトする。  
[解答群]  
  
ア  Ａ：上昇Ｂ：低下Ｃ：供給Ｄ：右  
  
イ  Ａ：上昇Ｂ：低下Ｃ：需要Ｄ：右  
  
ウ  Ａ：低下Ｂ：上昇Ｃ：供給Ｄ：左  
  
エ  Ａ：低下Ｂ：上昇Ｃ：供給Ｄ：右  
  
オ  Ａ：低下Ｂ：上昇Ｃ：需要Ｄ：左  
        
`
        },
        {
                "mondaiId": 101240130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、需要曲線と供給曲線を下図のような直線で表すものとする。  
このとき、政府が点線で示されているような形で従量税を課す場合、税収と税の  
大きさ，財\f単位あたりの税Oとの関係を表す図として、最も適切なものを下記の解  
答群から選べ。  
[解答群]  
        
`
        },
        {
                "mondaiId": 101240140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図には、需要曲線と供給曲線が描かれており、市場で決まる｢課税前の価格｣は  
D点によって与えられる。ここで、当該財へ政府が税を課すと、｢課税後の買い手  
の支払い価格｣はA点で与えられ、｢課税後の売り手の受取価格｣はC点で与えられ  
ることになるとする。  
この図の説明として、最も適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  課税によって生じる負担は需要者，買い手Oの方が重い。  
  
イ  この財市場の需要曲線は、供給曲線に比べて価格弾力性が高い。  
  
ウ  三角形ABDは、課税によって失う生産者余剰である。  
  
エ  線分BCの長さは、課税によって生じる需要量の減少を意味している。  
        
`
        },
        {
                "mondaiId": 101240150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、ある国の立場から、\fつの財の市場のみに注目した部分均衡分析の枠組  
みを用いて、自由貿易協定の経済効果を示している。当該財の価格がP1である第  
Ⅰ国からの輸入に、この国では関税を賦課しており、関税賦課後の価格はP2とな  
っていた。それが、第Ⅱ国と自由貿易協定を結ぶことによって、第Ⅱ国から価格  
P3で当該財を輸入できることになった。なお、図中のa~iは線で囲まれた範囲  
の面積を表すものとする。  
第Ⅱ国と自由貿易協定を結ぶ場合、協定締結後のこの国の経済厚生は、締結前と  
比較して、どれだけ変化したか、最も適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  c＋d＋e＋f  
  
イ  c＋d＋f安h  
  
ウ  d＋e＋f  
  
エ  d＋f安h  
        
`
        },
        {
                "mondaiId": 101240160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、X財とY財に対するある個人の無差別曲線，U1，U2，U3Oを描いたもの  
である。U1，U2，U3は直線であるものとし、A点とB点は無差別曲線U1上にあ  
り、C点は無差別曲線U3上にある。  
この図の説明として最も適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  効用の不飽和性が成り立つ場合、A点とC点の効用水準は等しい。  
  
イ  これらの無差別曲線の限界代替率は逓減している。  
  
ウ  これらの無差別曲線は、\u0015つの財が完全代替財であることを意味している。  
  
エ  これらの無差別曲線は、\u0015つの財が完全補完財であることを意味している。  
  
オ  無差別曲線U1上でA点から得られる効用水準は、B点から得られる効用水  
準よりも高い。  
        
`
        },
        {
                "mondaiId": 101240170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、\u0015つの財，X財とY財Oのみを消費する消費者の効用最大化行動を描��  
たものである。当初の予算制約線はABで与えられ、効用を最大にする消費量の組  
み合わせは、無差別曲線U1との接点すなわち座標，G，EOとして与えられている。  
このとき、X財の価格が下落し予算制約線がACへと変化すると，効用を最大にす  
る消費量の組み合わせは無差別曲線U2との接点すなわち座標，I，DOへと変化す  
る。なお、補助線，破線Oは、予算制約線ACと同じ傾きを持ち、無差別曲線U1と  
接するものとする。  
この図の説明として、最も適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  X財に生じた所得効果は線分HIの長さで測られ、Y財に生じた所得効果は  
線分EFの長さで測られる。  
  
イ  X財の価格の低下は、X財の消費量の減少を引き起こしている。  
  
ウ  X財はギッフェン財である。  
  
エ  Y財に生じた所得効果の絶対値は、Y財に生じた代替効果の絶対値よりも  
大きい。  
  
オ  座標，H，FOの効用水準は、座標，G，EOの効用水準よりも低い。  
        
`
        },
        {
                "mondaiId": 101240180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下図は、\fつの生産要素のみを用いて、\fつの最終生産財を生産する場合を想定  
したものである。この図の説明として、最も適切なものの組み合わせを下記の解答  
群から選べ。  
ａ生産関数\fの限界生産物は逓減している。  
ｂ生産関数\fは、規模に関する収穫逓増を示している。  
ｃ生産関数\u0015の限界生産物は逓増している。  
ｄ他の条件が等しく、同じ生産量を実現しているとき、生産関数\fを有する企業  
が生産に要する費用は、生産関数\u0015を有する企業のそれよりも小さい。  
[解答群]  
  
ア  ａとｄ  
  
イ  ｂとｃ  
  
ウ  ｂとｄ  
  
エ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 101240190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
完全競争市場の下で、ある任意の財を生産・販売する企業を考える。当該企業の  
総収入曲線と総費用曲線が下図のように描き出されるとする。ただし、総費用曲線  
は、固定費用が存在するためにD点を切片として生産量に応じて変化し、総収入  
曲線とA点およびC点で交差している。また、総収入曲線と同じ傾きを持つ補助  
線，破線Oも描かれており、補助線はB点で総費用曲線と接している。  
この図の説明として最も適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  C点では、限界収入暗限界費用という条件が満たされている。  
  
イ  Q2より生産量が増えると、当該企業の利潤は増加する。  
  
ウ  合理的な当該企業が利潤最大化するように選択した生産量から得られる利潤  
の大きさは、A点、B点、C点を結んで形成されるレンズ型の面積の大きさに  
よって示される。  
  
エ  生産量がゼロの時、当該企業の利潤は負である。  
  
オ  横軸上のQ1からB点までの高さは、合理的な当該企業が利潤最大化するよ  
う選択した生産量から得られる利潤の大きさを意味している。  
        
`
        },
        {
                "mondaiId": 101240200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
不完全競争を理解するための経済理論モデルに関する説明として、最も適切なも  
のはどれか。  
  
ア  寡占市場における屈折需要曲線の説明では、限界収入曲線が不連続になる点に  
特徴の\fつがある。  
  
イ  規模の経済が働き、平均費用が低下しているような自然独占の市場では、限界  
費用は平均費用を上回っている。  
  
ウ  複占市場におけるクールノー・モデルの説明では、ライバル関係にある企業が  
価格競争，価格引き下げ競争Oを行うと仮定する点に特徴の\fつがある。  
  
エ  複占市場におけるベルトラン・モデルの説明では、ライバル関係にある企業が  
数量競争，生産量を増やす競争Oを行うと仮定する点に特徴の\fつがある。  
        
`
        },
        {
                "mondaiId": 101240210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
いま、企業Aが個人Bに対して負の外部性を発生させる財を生産している。下  
図は、企業Aの私的限界費用の上方に個人Bへの影響を考慮した社会的限界費用  
が描かれており、線分Eの長さは限界的な外部性の大きさを表している。当該財  
の価格がPで一定であるとすれば、自由放任の状況下で外部性を考慮しない場合  
の企業Aが選択する合理的な生産量はQ2、外部性を考慮して社会的余剰を最大に  
する場合の生産量がQ1となる。なお、図中のCとDは線で囲まれた範囲の三角  
形の面積を表すものとする。  
この図に関する説明として、最も不適切なものを下記の解答群から選べ。  
[解答群]  
  
ア  現状で生産量Q2が選択されているとき、コースの定理によれば、企業Aと  
個人Bの自発的な交渉が可能であれば生産量Q1が選択される。  
  
イ  自由放任の状況下で外部性を考慮しない場合の企業Aが選択する生産量Q2  
は、\u0015つの三角形の面積の合計，C＋DOに相当する死重損失を生む。  
  
ウ  数量規制によって生産量がQ2からQ1へ減少する場合、企業Aは、面積C  
に相当する分だけ余剰が減少する。  
  
エ  生産量がQ2からQ1へ減少する場合、個人Bは、\u0015つの三角形の面積の合  
計，C＋DOに相当する分の外部不経済を被らずに済む。  
        
`
        },
        {
                "mondaiId": 101240220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
公共財に関する説明として、最も適切なものはどれか。  
  
ア  公共財とは、少なくとも競合性を有する財である。  
  
イ  公共財とは、少なくとも非排除性を有する財である。  
  
ウ  公共財とは、政府のみが供給する権利のある財である。  
  
エ  公共財とは、納税者のみが利用する権利のある財である。`
        },
        {
                "mondaiId": 101240230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、｢囚人のジレンマ｣として知られる非協力ゲームの利得表である。いま、  
\u0015人の個人，個人Ａと個人ＢOが\f度限りの取引を行い、\u0015つの選択肢，自らの選好  
を｢正直に表明｣するか、｢過小に表明｣するOのいずれかを選択することができる。  
なお、以下の表中にあるカッコ内の値は、それぞれ左側が個人Ａの利得、右側が個  
人Ｂの利得を示している。この表から得られる記述として、最も適切なものを下記  
の解答群から選べ。  
個人Ｂ  
正直に表明過小に表明  
個人Ａ  
正直に表明，2，2O，0，4O  
過小に表明，4，0O，1，1O  
[解答群]  
  
ア  個人Ａが非協力的に利得の最大化をめざすならば｢過小に表明｣を選択する。  
  
イ  個人Ａにとって｢正直に表明｣を選択するのが支配戦略である。  
  
ウ  個人Ａは、個人Ｂの選択に応じて最適な行動を変化させる。  
  
エ  個人Ｂが｢正直に表明｣を選択してくれることが確実であれば、個人Ａも｢正  
直に表明｣を選択することが合理的である。  
        
`
        },
        {
                "mondaiId": 101240240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本の社会保障や失業に関する説明として、最も適切なものはどれか。  
  
ア  OECDの統計によれば、2000年から2010年の期間に限れば、日本の失業率は  
一貫して米国よりも高い。  
  
イ  2011年\u0006月時点の生活保護受給者数，被保護実人員数Oは、集計が始まった戦  
後間もない1951年よりは少ない。  
  
ウ  4高齢社会白書9，平成23年版Oによれば、2055年には、高齢者1人に対して現  
役世代，15?64歳Oは約\u0005人という人口比になる。  
  
エ  日本の社会保障給付費は、おおよそ100兆円規模である。  
`
        },
        {
                "mondaiId": 102240010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の仕訳の説明として最も適切なものを下記の解答群から選べ｡  
†借・仕入400，000†貸・売掛金400，000  
[解答群]  
  
ア  掛売りした商品のうち400，000円分の返品を得意先から受けた。  
  
イ  商品400，000円を掛で仕入れた際に勘定科目を貸借反対に仕訳していたので  
訂正した。  
  
ウ  商品400，000円を仕入れ、為替手形を振り出し、得意先の引き受けを得て仕  
入先に渡した。  
  
エ  商品400，000円を返品した際に誤って掛売りとして仕訳していたので訂正し  
た。  
        
`
        },
        {
                "mondaiId": 102240020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の商品有高帳、仕入帳および売上帳に基づき、甲品の月間の売上総利益として  
最も適切なものを下記の解答群から選べ。商品の評価は先入先出法による。  
月日摘要内訳金額  
74Ａ商店掛  
甲品50＠￥42021，000  
乙品40＠￥60024，00045，000  
5Ａ商店掛返品  
甲品10＠￥4204，200  
23Ｂ商店現金  
甲品40＠￥40016，000  
総仕入高61，000  
仕入戻し高4，200  
純仕入高56，800  
仕入帳  
月日摘要内訳金額  
76Ｃ商店掛  
甲品40＠￥55022，000  
25Ｄ商店掛  
甲品30＠￥56016，800  
乙品40＠￥80032，00048，800  
26Ｄ商店掛値引  
甲品16＠￥50800  
総売上高70，800  
売上値引高800  
純売上高70，000  
売上帳  
摘要月日  
数量金額単価数量  
残高払出受入  
410208，20041020前月繰越17  
金額単価数量金額単価  
省略  
8，200  
41，00010041，000100  
商品有高帳  
先入先出法品名甲品  
[解答群]  
  
ア  1，800円  
  
イ  4，900円  
  
ウ  5，800円  
  
エ  9，000円  
        
`
        },
        {
                "mondaiId": 102240030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
仕入割引に関する記述として最も適切なものはどれか。  
  
ア  一定期間に多額または多量の取引のあった仕入先による仕入代金の返戻額等で  
ある。  
  
イ  仕入品の両目不足、品質不良、破損等の理由による代価からの控除額である。  
  
ウ  代金支払期日前の支払に対する買掛金の一部免除等である。  
  
エ  品質不良、破損等の理由による返品から生じる仕入代金の減少額である。  
        
`
        },
        {
                "mondaiId": 102240040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のキャッシュ・フロー計算書に基づき、支払利息勘定の空欄Aの金額として  
最も適切なものを下記の解答群から選べ。  
キャッシュ・フロー計算書  
†単位：千円・  
Ⅰ営業活動によるキャッシュ・フロー  
税引前当期純利益52，100  
減価償却費78，400  
退職給付引当金の増加額8，800  
貸倒引当金の増加額400  
受取利息及び受取配当金2，600  
支払利息1，100  
有形固定資産売却損益†純額・600  
売上債権の増加額10，200  
たな卸資産の減少額9，500  
仕入債務の増加額1，000  
小計139，100  
利息及び配当金の受取額3，200  
利息の支払額1，000  
法人税等の支払額10，400  
営業活動によるキャッシュ・フロー130，900  
†以下省略・  
†・†・  
Ａ前払利息†・当座預金  
†・損益400前払利息  
支払利息  
†注・金額の単位は千円である。  
[解答群]  
  
ア  100千円イ300千円ウ500千円  
  
エ  1，000千円  
        
`
        },
        {
                "mondaiId": 102240050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料に基づき、連結当期純利益を算定するうえで、P社(親会社)とS社(子  
会社)の当期純利益合計から控除される金額の計算式として、最も適切なものを下  
記の解答群から選べ。  
M資料N  
1．P社はS社へ原価に対し一定の利益を付加して商品を販売している。  
2．S社の期末商品たな卸高はすべてP社からの仕入分である。  
3．P社はS社から配当金を受け取っている。  
[解答群]  
  
ア  S社当期純利益庵少数株主の持分比率＋S社からの受取配当金  
  
イ  S社当期純利益庵少数株主の持分比率＋S社からの受取配当金＋S社期末  
たな卸商品未実現利益  
  
ウ  (S社当期純利益安S社期末たな卸商品未実現利益)庵少数株主の持分比率  
  
エ  (S社当期純利益安S社期末たな卸商品未実現利益)庵少数株主の持分比率  
＋S社からの受取配当金`
        },
        {
                "mondaiId": 102240060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
原価計算制度において、原価に算入される項目として最も適切なものはどれか。  
  
ア  支払利息等の財務費用  
  
イ  任意積立金繰入額  
  
ウ  福利施設負担額  
  
エ  臨時多額の退職手当  
        
`
        },
        {
                "mondaiId": 102240070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は個別原価計算制度を採用している。当月における製造指図書別の製造・販  
売および製造原価に関する資料は次のとおりである。当月の売上原価として最も適  
切なものを下記の解答群から選べ。  
3，2000直接材料費  
5，6000005，600前月繰越  
合計#124#123#122#121製造指図書  
備考  
1，900時間200時間700時間900時間100時間機械運転時間  
4，7204601，8602，100300直接労務費  
6，8001，2002，400  
安未完成完成･未渡完成･引渡完成･引渡  
M資料N  
†単位：千円・  
†注・製造間接費は機械運転時間に基づいて予定配賦している。本年度の製造間接  
費予算額は48，000千円†予定機械運転時間24，000時間・である。  
[解答群]  
  
ア  7，600千円  
  
イ  13，200千円  
  
ウ  15，320千円  
  
エ  18，860千円  
        
`
        },
        {
                "mondaiId": 102240080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料に基づく売上総利益の増減分析における単位当たり利益の変化による売  
上総利益の増減額として、最も適切なものを下記の解答群から選べ。  
前期当期増減  
売上高311，600円320，000円8，400円  
売上原価190，000円196，000円6，000円  
売上総利益121，600円124，000円2，400円  
販売数量380kg400kg20kg  
販売価格820円800円安20円  
単位原価500円490円安10円  
[解答群]  
  
ア  安4，000円  
  
イ  安200円  
  
ウ  6，000円  
  
エ  6，400円`
        },
        {
                "mondaiId": 102240090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
セグメントとしての事業部が各事業部に共通的に発生する固定費を回収し、さら  
に利益を獲得することに貢献する度合を示す利益額として最も適切なものはどれ  
か。  
  
ア  売上高安売上原価  
  
イ  売上高安変動費  
  
ウ  売上高安変動費安管理可能固定費  
  
エ  売上高安変動費安個別固定費  
        
`
        },
        {
                "mondaiId": 102240101,
                "name": "第10問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
当期と前期との比較損益計算書†要約・は次のとおりである。これに基づいて下記  
の設問に答えよ。  
比較損益計算書†要約・  
†単位：百万円・  
科目前期†第22期・当期†第23期・  
売上高1，0001，200  
売上原価450530  
売上総利益550670  
販売費及び一般管理費430550  
営業利益120120  
営業外収益4060  
営業外費用3060  
経常利益130120  
特別利益1020  
特別損失2030  
税引前当期純利益120110  
法人税等5045  
当期純利益7065  
（設問1）・  
付加価値率に前期と当期で変化がなく、平均従業員数が前期は30人、当期は  
32人であるとき、生産性の変化に関する記述として最も適切なものはどれか。  
  
ア  従業員1人当たり売上高が上昇し、付加価値労働生産性が上昇した。  
  
イ  従業員1人当たり売上高が上昇し、付加価値労働生産性が低下した。  
  
ウ  従業員1人当たり売上高が低下し、付加価値労働生産性が上昇した。  
  
エ  従業員1人当たり売上高が低下し、付加価値労働生産性が低下した。  
        
`
        },
        {
                "mondaiId": 102240102,
                "name": "第10問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
・  
前々期†第21期・の売上高が950百万円、経常利益が133百万円であるとき、  
成長性の変化に関する記述として最も適切なものはどれか。  
  
ア  売上高と経常利益の成長性が上昇した。  
  
イ  売上高と経常利益の成長性が低下した。  
  
ウ  売上高の成長性は上昇し、経常利益の成長性は低下した。  
  
エ  売上高の成長性は低下し、経常利益の成長性は上昇した。`
        },
        {
                "mondaiId": 102240110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
損益分岐点分析に関する次の文章の空欄Ａ~Ｃに入る用語の組み合わせとして最  
も適切なものを下記の解答群から選べ。  
損益分岐点売上高の定義より、利益は売上高に対する限界利益と損益分岐点売上  
高に対する限界利益の差として求められる。よって、限界利益と売上高との関係か  
ら、ＡとＢおよびＣとの間には、  
Ａ暗Ｂ庵Ｃ  
という関係がある。  
[解答群]  
  
ア  Ａ：売上利益率Ｂ：安全余裕率Ｃ：限界利益率  
  
イ  Ａ：売上利益率Ｂ：損益分岐点比率Ｃ：限界利益率  
  
ウ  Ａ：限界利益率Ｂ：安全余裕率Ｃ：売上利益率  
  
エ  Ａ：限界利益率Ｂ：損益分岐点比率Ｃ：売上利益率  
        
`
        },
        {
                "mondaiId": 102240120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
当期の資金繰り表の記載項目ａ~ｆのうち、経常外収支の項目として、最も適切  
なものの組み合わせを下記の解答群から選べ。  
ａ受取手形の期日入金  
ｂ現金売上  
ｃ支払手形の期日決済  
ｄ設備投資  
ｅ手形割引  
ｆ未払金の支払  
[解答群]  
  
ア  ａとｂイｂとｃウｄとｅエｅとｆ`
        },
        {
                "mondaiId": 102240130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次のデータに基づいて、営業キャッシュフローを求めた場合、最も適切な金額を  
下記の解答群から選べ。  
売上高：100百万円  
現金支出を伴う費用：50百万円  
減価償却費：15百万円  
実効税率：40％  
[解答群]  
  
ア  21百万円  
  
イ  35百万円  
  
ウ  36百万円  
  
エ  45百万円  
        
`
        },
        {
                "mondaiId": 102240140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次のデータに基づいて前期から当期の1年間における正味運転資本の増減額を計  
算した場合、最も適切なものを下記の解答群から選べ。  
当期  
†単位：百万円・  
流動資産  
現金  
受取手形  
たな卸資産  
流動負債  
買掛金  
支払手形  
未払税金  
8  
20  
40  
30  
5  
60  
前期  
32  
20  
30  
5  
10  
50  
[解答群]  
  
ア  30百万円の減少  
  
イ  20百万円の減少  
  
ウ  6百万円の増加  
  
エ  16百万円の増加`
        },
        {
                "mondaiId": 102240150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
内部金融に関する記述として、最も適切なものはどれか。  
  
ア  内部金融とは、企業の事業活動によって獲得された自己資本調達であり、利益  
の内部留保、企業間信用などから構成される。  
  
イ  内部金融とは、企業の事業活動によって獲得された他人資本調達であり、減価  
償却、ファイナンス・リースなどから構成される。  
  
ウ  内部金融とは、企業の事業活動によって獲得された短期資金調達であり、減価  
償却、企業間信用などから構成される。  
  
エ  内部金融とは、企業の事業活動によって獲得された長期資金調達であり、利益  
の内部留保、減価償却などから構成される。  
        
`
        },
        {
                "mondaiId": 102240160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下のデータに基づいて、加重平均資本コストを計算したとき、最も適切な数値  
を下記の解答群から選べ。なお、自己資本コストは配当割引モデルによって求める  
ものとする。  
†単位：万円・  
発行済み株式数：100万株  
現在の1株当たり配当金：5円  
配当成長率：10％  
負債の税引前コスト：\u000e％  
実効税率：40％  
時価  
負債5，000  
自己資本5，000  
[解答群]  
  
ア  6.7％  
  
イ  \u0006％  
  
ウ  11.3％  
  
エ  11.7％  
        
`
        },
        {
                "mondaiId": 102240171,
                "name": "第17問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
現在、X社は総資本10億円†時価ベース・の全額を株主資本で調達して事業活動  
を行っており、その税引前総資本営業利益率は12％である。また、ここでの税引  
前営業利益は税引前当期利益に等しく、また同時に税引前キャッシュフローにも等  
しいものとする。X社は今後の事業活動において、負債の調達と自己株式の買い  
入れによって総資本額を変えずに負債と株主資本との割合を4：6に変化させるこ  
とを検討しており、その影響について議論している。  
（設問1）・  
もし市場が完全で税金が存在しない場合、X社が資本構成を変化させたとき、  
ROEは何％となるか。最も適切な数値を選べ。なお、負債利子率は6％であ  
り、資本構成の変化によって税引前総資本営業利益率は変化しないものとする。  
  
ア  6％  
  
イ  12％  
  
ウ  16％  
  
エ  18％`
        },
        {
                "mondaiId": 102240172,
                "name": "第17問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
・  
完全市場において法人税のみが存在する場合、X社が資本構成を変化させる  
ことで、企業全体の価値にどのような影響があるか。最も適切なものを選べ。な  
お、実効税率は40％である。  
  
ア  2，400万円企業価値が減少する。  
  
イ  2，400万円企業価値が上昇する。  
  
ウ  16，000万円企業価値が減少する。  
  
エ  16，000万円企業価値が上昇する。  
        
`
        },
        {
                "mondaiId": 102240180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
Y社では4つの投資案について採否を検討している。投資案はいずれも初期投  
資額として2，500万円を必要とし、投資プロジェクトの耐用年数は5年である。ま  
た、Y社の資本コストは8％であり、プロジェクト期間中に追加の資金は必要と  
しない。4つの投資案の判定基準となるべきデータは以下のとおりである。Y社の  
投資可能な資金が5，000万円に制限されているとき、企業価値増大の観点からY  
社が採択すべき投資案の組み合わせとして最も適切なものを下記の解答群から選  
べ。  
投資案甲乙丙丁  
NPV†万円・280300180安25  
IRR†％・911106  
回収期間†年・3422  
[解答群]  
  
ア  甲と乙  
  
イ  甲と丙  
  
ウ  乙と丙  
  
エ  丙と丁  
        
`
        },
        {
                "mondaiId": 102240190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
Z社は現在、余剰資金の全額を期待収益率8％、標準偏差6％の投資信託で運  
用している。Z社では余剰資金の運用方針を変更し、余剰資金の全額を、2％の収  
益率をもつ安全資産と上記投資信託に等額投資する運用を考えている。変更後の期  
待収益率と標準偏差の組み合わせとして最も適切なものはどれか。  
  
ア  期待収益率：\u0007％標準偏差：\u0005％  
  
イ  期待収益率：\u0007％標準偏差：\u000f％  
  
ウ  期待収益率：\u000f％標準偏差：\u000f％  
  
エ  期待収益率：10％標準偏差：\u000f％  
        
`
        },
        {
                "mondaiId": 102240201,
                "name": "第20問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで下記の設問に答えよ。  
企業価値の評価手法には、伝統的な企業業績評価手法であるデュポン・システム  
を応用したものがある。これによれば株価は、\f株当たり当期純利益とＡ  
との積に分解され、さらに\f株当たり当期純利益は\f株当たり純資産とROEとの  
積に分解される。こうした会計数値に基づく手法のほか、今日では企業価値評価手  
法として、キャッシュフローに基づく手法やEVAなどを利用したＢとい  
った手法も利用されている。  
（設問1）・  
文中の空欄ＡおよびＢに入る用語の組み合わせとして最も適切なものはどれ  
か。  
  
ア  Ａ：BPSＢ：資本資産評価モデル  
  
イ  Ａ：PBRＢ：割引超過利益モデル  
  
ウ  Ａ：PERＢ：市場株価比較方式  
  
エ  Ａ：PERＢ：割引超過利益モデル`
        },
        {
                "mondaiId": 102240202,
                "name": "第20問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
・  
文中の下線部のROEを企業価値評価手法として直接使用する場合に考えられ  
る問題点として、<u>最も不適切なものはどれか</u>。  
  
ア  ROEによって測定される値では、企業規模による影響を考慮した比較が困  
難である。  
  
イ  ROEによって測定される値には、株主の資本コストが反映されていない。  
  
ウ  ROEによって測定される値は、企業の採用する会計処理方法によって影響  
を受けることがある。  
  
エ  ROEによって測定される値は、財務レバレッジの影響を受けることがある。  
        
`
        },
        {
                "mondaiId": 102240210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
オプション取引に関する記述として、最も適切なものはどれか。  
  
ア  コール・オプションの買いの場合、原資産価格が行使価格を上回ったときにア  
  
ウ  ト・オブ・ザ・マネーとなる。  
  
イ  コール・オプションの買いの場合、原資産価格が行使価格を下回ったときにイ  
ン・ザ・マネーとなる。  
  
ウ  プット・オプションの買いの場合、原資産価格が行使価格を上回ったときにア  
ット・ザ・マネーとなる。  
  
エ  プット・オプションの買いの場合、原資産価格が行使価格を下回ったときにイ  
ン・ザ・マネーとなる。`
        },
        {
                "mondaiId": 102240220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
先物取引に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  先物取引は、必ずしも現物の受渡しを必要としない。  
  
イ  先物取引は、定型化されており取引所において取引される。  
  
ウ  先物取引は、特定の受渡日に取引が決済される。  
  
エ  先物取引は、日々証拠金の値洗いが行われる。  
`
        },
        {
                "mondaiId": 103240010,
                "name": "第1問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
複数事業を営む企業における企業ドメインと事業ドメインならびに事業ポートフ  
ォリオの決定に関する記述として、最も適切なものはどれか。  
  
ア  企業ドメインの決定は、通常、新たに進出する事業における自社の競争力と当  
該事業の発展性を判断基準とし、当該事業の他事業への波及効果は個別事業選択  
の判断基準として考慮されていない。  
  
イ  企業ドメインの決定は、通常、企業にとって多角化の広がりの程度を決め、個  
別事業の競争力を決める問題である。  
  
ウ  企業ドメインの決定は、通常、多角化した複数事業間の関連性のあり方に影響  
するが、集約型の事業間関連性パターンでは規模の経済を重視して資源を有効利  
用しようとする。  
  
エ  事業ドメインの決定は、通常、企業のビジョンの枠を超えて企業のアイデンテ  
ィティの確立を規定し、企業の境界を決める。  
  
オ  事業ドメインの決定は、通常、設定された領域の中で事業マネジャーにオペレ  
ーションを行う自律性を与える。  
        
`
        },
        {
                "mondaiId": 103240020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の多角化に関する分析フレームワークについての記述として、最も適切なも  
のはどれか。  
  
ア  外的な成長誘引は、通常、企業を新たな事業へと参入させる外部環境の機会も  
しくは脅威のことである。  
  
イ  事業拡大への誘引と障害は、通常、企業の多角化形態や将来の収益性の基礎に  
までは影響しない。  
  
ウ  新規事業への進出は、通常、当該企業の中核事業から始まり、マーケットシェ  
  
ア  を維持するために行われる。  
  
エ  内的な成長誘引は、通常、企業を多角化へと向かわせる企業内部の条件のこと  
であり、多くの場合、防御的な性格を持ち、自社の内部資源を最大限に活用した  
いという企業の欲求から生じる。  
  
オ  防御的な多角化は、通常、当初の市場において変化するニーズに当該企業の技  
術が適応していると判断した場合に行われる。  
        
`
        },
        {
                "mondaiId": 103240030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
現代の企業において、経営資源の利用と蓄積は、経営戦略の策定と実行にとって  
重要である。経営資源は、通常、人的資源、物的資源、資金、情報的資源に区別さ  
れる。情報的資源に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  企業活動における仕事の手順や顧客の特徴のように、情報的資源は日常の企業  
活動を通じて経験的な効果として蓄積される。  
  
イ  企業活動における設計図やマニュアルのように言語や数値化されているような  
情報は、熟練やノウハウなどよりも模倣困難性が高くない。  
  
ウ  企業にとって模倣困難性の低い情報的資源が競争にとって重要ならば、特許や  
商標のような手段で法的に模倣のコストを高める必要はない。  
  
エ  企業の特定の事業分野における活動で蓄積された情報的資源の利用は、その事  
業に補完的な事業分野に限定されない。  
  
オ  企業のブランドやノウハウのような情報的資源は、その特殊性が高いほど企業  
に競争優位をもたらす源泉となる。  
        
`
        },
        {
                "mondaiId": 103240040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
いかに早く競争力のある製品を開発し、市場に供給するか、という時間をめぐる  
競争はタイムベース競争と呼ばれている。そのような競争をめぐる問題点や考慮す  
べき点に関する記述として、最も適切なものはどれか。  
  
ア  商品購入時にユーザー登録をしてもらって利用特典を与える販売方式は、バー  
ジョンアップした自社商品への乗り換えを難しくするので、その企業の商品の普  
及スピードを鈍化させることになる。  
  
イ  生産リードタイムの短縮によって、原材料の在庫の回転率があがるが、生産コ  
ストに変化はなく、収益も変わらない。  
  
ウ  先発して市場に参入すれば、有利な立地や優秀な人材を先取りできるばかりで  
はなく、市場動向に素早く対応して、売り上げが増大する可能性が高くなる。  
  
エ  他社に先駆けて特許等で参入障壁を築いて防衛的地位を固めると、ニッチ市場  
に入り込んでしまい、市場の変化に取り残されてしまうことになる。  
  
オ  他社の競合品よりも多くの量の自社製品をすばやく生産することを続けると、  
単位あたりコストが増大し、市場競争で劣位に立たされることになる。  
        
`
        },
        {
                "mondaiId": 103240050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
差別化戦略は競争者に対抗するための基本的戦略の\fつである。商品の属性と製  
品差別化に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  売り手の信用をもとに安全性を確認するような信用的な属性については、物理  
的な差異による製品差別化よりも広告や宣伝活動による製品差別化が有効である。  
  
イ  購入前に調べてみれば分かるような探索的な属性については、広告や宣伝活動  
による製品差別化よりも物理的な差異による製品差別化が有効である。  
  
ウ  実際の消費経験から判断できるような経験的な属性については、物理的な差異  
による製品差別化よりも広告や宣伝活動による製品差別化が有効である。  
  
エ  製品差別化は特定の売り手の製品に関する買い手の主観的な判断をベースとし  
ている。`
        },
        {
                "mondaiId": 103240060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業は自社の業界における相対的な地位を踏まえて競争戦略を展開することが重  
要である。そのような競争戦略に関する記述として、最も適切なものはどれか。  
  
ア  チャレンジャーは、リーダーの高い技術力が生み出した差別化された製品と同  
質な製品を販売し、リーダーの差別化効果を無効にすることを狙うべきである。  
  
イ  ニッチャーは特定の市場セグメントで独自性を発揮できる戦略を遂行して、強  
い市場支配力を狙うことが必要である。  
  
ウ  フォロワーは特定市場でリーダーの製品を模倣しつつ、非価格競争によって収  
益をあげることが基本戦略になる。  
  
エ  ライバル企業に比べて技術力や生産能力に劣るニッチャーの場合、価格競争に  
重点をおいた販売戦略を幅広い市場で展開することが重要になる。  
  
オ  リーダーは周辺の需要を拡大することによって、売り上げの増加や市場シェア  
の拡大を図ることができるが、その反面で新製品の投入を遅らせてしまうことに  
なる。  
        
`
        },
        {
                "mondaiId": 103240070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　戦略事業単位とプロダクト・ポートフォリオ・マトリックスに関する記述として、最も適切なものはどれか。  
  
ア  資金の投入によって成長市場で競争優位の実現を期待できる｢金のなる木｣の選択は重要であり、競争優位性を期待できない｢負け犬｣事業からは事業担当者へのインセンティブを考慮して<hintTarget>撤退を検討する必要</hintTarget>がある。  
  
<hint hidden='true'>**
❌負け犬は、投資に対して得られるキャッシュフローが相対的に大きい事情が含まれることもあるため、必ずしも撤退を検討する必要はありません。
**</hint>

イ  戦略事業単位の責任者は、当該事業の成功に必須の技術、製造、マーケティングに関して、計画の範囲内で自由に対処できる。  
  
<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

ウ  ｢<hintTarget>花形商品</hintTarget>｣の事業は、｢負け犬｣ではなく｢問題児｣の中の特定の事業に対する集中的な投資の主要な資金供給源として重要である。  
  
<hint hidden='true'>**
❌下図のとおり、<hintTarget>金のなる木</hintTarget>に関する記述です。**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/103003_1.png" target="_blank" >
<img src="assets/img/kaisetu/103003_1.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>

エ  プロダクト・ポートフォリオ・マトリックスの考え方は、<hintTarget>外部</hintTarget>からの資金調達を考慮して低コスト戦略を重視している。    

<hint hidden='true'>**
❌内部です。
**</hint>

オ  プロダクト・ポートフォリオ・マトリックスの考え方は、主として事業の財務面だけではなく、<hintTarget>事業間のマーケティングや技術に関するシナジー</hintTarget>も重視している。  
        
<hint hidden='true'>**
❌PPMは、事業間のマーケティングや技術に関するシナジーは考慮しません。
**</hint>

`
        },
        {
                "mondaiId": 103240080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
社内ベンチャーは、新事業の創造のために組織化されてきた。社内ベンチャーに  
関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  社内ベンチャーは、社内に独立性の高い集団を設けて小さな独立した企業のよ  
うに運営させるが、新しい事業領域での学習のための装置としても適切な組織で  
ある。  
  
イ  社内ベンチャーは、新事業の運営について自律感を高め、新事業の推進に必要  
な心理的エネルギーを生み出す組織としての役割を果たすことができる。  
  
ウ  社内ベンチャーは、新事業の運営について、本業や既存事業からの過剰な介入  
や悪影響を排し、トップダウン型の思考様式から乖離した発想を生み出すことが  
できる。  
  
エ  社内ベンチャーは、ハンズオン型のベンチャーキャピタルに比べ、新事業に対  
して親企業の関与する程度は低くなる。  
  
オ  社内ベンチャーは、本業や既存事業とは異なった事業分野への進出や根本的に  
異質な製品開発を目的として設置されることが多い。  
        
`
        },
        {
                "mondaiId": 103240091,
                "name": "第9問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
  
ア  ライアンスやアウトソーシングに関する次の文章を読んで、下記の設問に答えよ。  
企業を取り巻く環境は、グローバル化や先端技術の開発の進展などに伴って、か  
つてない要因をはらみながら激しく変化している。このような環境の変化に対応す  
べく、企業は他の企業や関係機関と連携を模索することが多くなり、戦略的にアラ  
  
イ  アンスを組む事例も報道されるようになっている。しかし、アライアンスが意図  
した成果を実現するには、相手をどう選ぶかにも増して、  
①  
  
ア  ライアンスのマネジメ  
ントが重要であることを見落としてはならない。  
他方、市場を通じて業務の外部化を図るというアウトソーシングも頻繁に実施さ  
れるようになった。何をアウトソーシングするかの検討は慎重でなければならない  
が、  
②  
委託者と受託者の関係についても注意しておくべきであることは指摘するまで  
もない。  
（設問1）5  
文中の下線部①のアライアンスのマネジメントとして最も適切なものはどれ  
か。  
  
ア  相手を上回る出資比率を維持して、意思決定の権限を確保することに留意し  
て、それができない場合はアライアンスを見送るようにしなければならない。  
  
イ  互いに連携によって得られる便益とそのために必要な費用を計算すると、信  
頼が醸成されなくなるので、アライアンスは期待した効果を生みにくくなるこ  
とに注意しなければならない。  
  
ウ  提携企業間の人事施策、組織の特性、経営上の価値観などの社風の違いは、  
相手企業を吸収合併して価値観の一体化を促すことによってしか克服できない  
ことに注意しておくべきである。  
  
エ  連携が長くなるにつれて互いに心が通い合い信頼が醸成されやすいが、その  
ことによって取り引きの経済評価が甘くならないように注意しなければならな  
い。  
  
オ  連携の中身やお互いの能力について理解しあうことは重要であるが、手の内  
を見せすぎることになるので、関係が深くなることは避けなければならない。  
        
`
        },
        {
                "mondaiId": 103240092,
                "name": "第9問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
文中の下線部②のアウトソーシングを戦略的に展開する際に注意すべき点に関  
する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  アウトソーシングの受託者が多くなるにつれて、利害関係や連携方式が複雑  
になるので、アウトソーシングの調整を担当する部署を設けて機敏な対応を確  
保するべきである。  
  
イ  アウトソーシングの主たる目的である相乗効果や新規事業の創造に結びつく  
には、実務レベルでの密な意見交換や共同事業を推進するべきである。  
  
ウ  自社能力の強化に振り向ける資金とアウトソーシングに伴う費用の負担と便  
益を比較することで、アウトソーシングに踏み切るかどうかの判断をするべき  
である。  
  
エ  受託者の能力不足や非協力的な態度が判明した場合、アウトソーシングの解  
消や違約による損害賠償を視野に入れてアライアンスの解消を検討するべきで  
ある。  
  
オ  独自な能力をもつ受託者からは、共同事業を通じてその能力を学ぶ姿勢をも  
つように連携関係を強化するべきである。  
        
`
        },
        {
                "mondaiId": 103240101,
                "name": "第10問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業の海外進出とその戦略対応に関する次の文章を読んで、下記の設問に答え  
よ。  
  
ア  ジアの途上国は次々に経済的に自立し、新興工業国としてめざましい発展を続  
けている国も少なくない。このようなアジアの経済成長に対応すべく、エレクトロ  
ニクス産業や自動車産業を中心に現地への進出が相次いでいる。とくに巨大な市場  
となった  
①  
中国では激烈な企業間競争が繰り広げられている。  
しかし、わが国のエレクトロニクス産業は劣勢に立たされることが多いのに対し  
て、自動車産業は市場への浸透を高めている。そうしたなかで、近年、中国での成  
功事例を踏まえて、  
②  
リバースイノベーションの重要性が指摘されている。  
（設問1）5  
文中の下線部①のように中国市場の激しい競争のなかで、外資企業は成功をお  
さめる製品やサービスを輩出している。それらの企業の戦略行動には注目すべき  
いくつかの特徴が見られる。そのような特徴に関する記述として、最も不適切な  
ものはどれか。  
  
ア  合弁企業では現地のパートナー企業の中国人を董事長と総経理に登用して、  
本国からの社員の派遣を行わないことによって、中国社会や市場への浸透を図  
る例が多くなっている。  
  
イ  富裕層をターゲットに先進国の高品質で高価格の製品を輸出して、ステイタ  
ス・シンボルに訴える顕示的な消費を促している。  
  
ウ  ボリュームゾーンと呼ばれる巨大な大衆市場向けに、現地生産による低価格  
商品を投入して、価格競争を挑んでいる。  
  
エ  有名ブランドながら、中国人好みのデザインや色彩、ネーミングにこだわっ  
たきめ細かい現地向けの商品政策を展開している。  
  
オ  流通販売網を独自に整備し、現地の販売拠点に向けて魅力的な報償制度を設  
けたり、現地販売員に顧客志向のホスピタリティを訓練しながら売り上げを伸  
ばしている。  
        
`
        },
        {
                "mondaiId": 103240102,
                "name": "第10問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
文中の下線部②のリバースイノベーションに関する記述として最も適切なもの  
はどれか。  
  
ア  所得の高い先進国で先進的な技術の製品の普及を図り、その製品のライフサ  
  
イ  クルにあわせて、次第に所得の低い途上国への輸出を行い、やがて現地生産  
に切り替えるイノベーション戦略である。  
  
イ  先進国で開発された製品を、途上国の開発拠点で現地向けに開発し直し、現  
地の生産販売を図りつつ、それを先進国モデルへと進化させるイノベーション  
戦略である。  
  
ウ  対抗機種の性能を分析したり、それを分解したりして、技術特性を調査する  
ことから着手するイノベーション戦略である。  
  
エ  通常とは逆にサプライヤー側からの開発提案を受け入れて、アセンブラーが  
サプライヤーと共同で製品開発に取り組むイノベーション戦略である。  
  
オ  本国の研究開発部門でユーザーフレンドリーな製品の開発を行い、それを現  
地に持ち込んで、グローバルにイノベーション成果の普及を図るイノベーショ  
ン戦略である。  
        
`
        },
        {
                "mondaiId": 103240110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
グローバル化や技術イノベーションの進展は、サプライチェーンに新たな課題や  
可能性をもたらしている。このような状況下での中小企業をめぐるサプライチェー  
ンの動向に関する記述として最も適切なものはどれか。  
  
ア  アウトバウンドなサプライチェーンは、低価格な汎用部品では有利であるが、  
頻繁な設計や生産計画の変更に柔軟に対応する場合、外部企業との管理が複雑に  
なりやすく、有利とは言えなくなる。  
  
イ  オープンイノベーションが盛んになるにつれて、大企業間の技術提携が活発に  
なり、技術開発力や特異な生産技術をもつ中小企業は次第に締め出される。  
  
ウ  欧州ではソフトウェアのプラットフォームの国際標準化が活発化しており、わ  
が国中小企業の多くはそのネットワークへ参加して、標準化適応部品の国際受注  
を増大させている。  
  
エ  部品を特定の企業や生産地域に依存しすぎることから生じるリスクを回避する  
動きが強まっているため、地場産業は受注困難な状況に直面するようになった。  
        
`
        },
        {
                "mondaiId": 103240120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業組織の中では分業された職務を調整するために、多くの場面で標準化が行わ  
れる。組織における標準化に関する記述として最も適切なものはどれか。  
  
ア  会計士や弁護士など、社外の教育機関で訓練を受け、仕事のやり方や成果に対  
して一定の基準を共有しているプロフェッショナルの活用は、判断業務の少ない  
職場で有効性が高くなる。  
  
イ  社内外で部品間のインターフェイスを標準化することで、クローズドアーキテ  
クチャを実現できるとともに、安価な外部部品を利用することが可能になる。  
  
ウ  社内の人材を教育訓練したり、社会化を通じて組織文化への同調を求めること  
を通じて、労働力そのものを標準化することにより、分業が調整しやすくなる。  
  
エ  多様な顧客を対象とする営業現場では、作業手順を標準化しマニュアルを用意  
することで不確実性を減らすことができるが、目標などのアウトプットの標準化  
は顧客満足度を低下させてしまう。  
  
オ  並行型分業体制をとっている部門間に、目標や評価基準の標準化を導入する  
と、部門間競争が激化するため、従業員の職務への動機づけは低下してしまう。  
        
`
        },
        {
                "mondaiId": 103240130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
小集団におけるコミュニケーションネットワークとして、以下の3つの型を仮定  
する。  
これらのネットワークの型は、小集団における合意された意思決定への到達速  
度、伝達の正確さ、リーダーが出現する可能性、メンバーの満足度などに与える効  
果に差異がある。これらの比較に関する記述として最も適切なものを下記の解答群  
から選べ。  
\u000eチェーン型  
\u000fホイール型  
\u0010全チャネル型  

[解答群]  
  
ア  3つの型のうち、全チャネル型のコミュニケーションネットワークが、意思  
決定への到達速度は最も速く、伝達の正確性は中程度である。また、リーダー  
が出現する可能性は最も低いが、メンバーの満足度は最も高い。  
        
        
  
イ  3つの型のうち、チェーン型のコミュニケーションネットワークが、意思決  
定への到達速度は中程度だが、伝達の正確性は最も高い。また、リーダーが出  
現する可能性は最も高く、メンバーの満足度は最も低い。  
  
ウ  3つの型のうち、チェーン型のコミュニケーションネットワークが、意思決  
定への到達速度は最も速いが、伝達の正確性は最も低い。また、リーダーが出  
現する可能性は中程度で、メンバーの満足度は最も高い。  
  
エ  3つの型のうち、ホイール型のコミュニケーションネットワークが、意思決  
定への到達速度は最も速いが、伝達の正確性は最も低い。また、リーダーが出  
現する可能性は中程度で、メンバーの満足度は最も高い。  
  
オ  3つの型のうち、ホイール型のコミュニケーションネットワークが、意思決  
定への到達速度は最も速く伝達の正確性は最も高い。また、リーダーが出現す  
る可能性は中程度で、メンバーの満足度は最も低い。  
        
`
        },
        {
                "mondaiId": 103240140,
                "name": "第14問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織が成立・存続していくためには、その協働体系が有効かつ能率的に機能する  
条件がある。この条件を明らかにした｢組織均衡4organizationalequilibrium5｣の考  
え方には、5つの中心的公準がある。  
この中心的公準に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  貢献が十分にあって、その貢献を引き出すのに足りるほどの量の誘因を提供し  
ているかぎりにおいてのみ、組織は｢支払い能力がある｣すなわち存続する。  
  
イ  参加者それぞれ、および参加者の集団それぞれは、組織から誘因を受け、その  
見返りとして組織に対する貢献を行う。  
  
ウ  参加者のさまざまな集団によって提供される貢献が、組織が参加者に提供する  
��因を作り出す源泉である。  
  
エ  組織は、組織の参加者と呼ばれる多くの人々の相互に関連した社会的行動の体  
系である。  
  
オ  それぞれの参加者は、提供される誘因と要求されている貢献の差し引き超過分  
が正の場合にだけ、組織への参加を続ける。  
        
`
        },
        {
                "mondaiId": 103240151,
                "name": "第15問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
組織におけるパワーは、目的達成の鍵となることが多い。  
①  
パワー現象には本質的  
に持つ者と持たざる者の間に相互依存関係があり、使い方も異なる。パワーの行使  
は、組織内外の  
②  
政治的行動を伴うものである。  
（設問1）5  
文中の下線部①のパワーを説明する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  相手が高く評価し範例となることからくる同一化パワー  
  
イ  相手が必要とする知識・スキル・経験からくるエキスパート・パワー  
  
ウ  降格・解雇などのように相手がいやがることができる能力からくる強制的パ  
ワー  
  
エ  公式的な権限を持っている���いう相手側の信念からくる合法パワー  
  
オ  昇進・昇格などのように相手が望むことが与えられる能力からくる報償パワ  
ー`
        },
        {
                "mondaiId": 103240152,
                "name": "第15問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
文中の下線部②の組織内外の｢政治的行動｣を説明する記述として、最も適切な  
ものはどれか。  
  
ア  政治的行動は、影響を受けた個人の権利を尊重したものであるかどうかの倫  
理基準が優先される。  
  
イ  政治的行動は、課題達成の機会ではなく脅威とみなされると防御的行動につ  
ながるが、仕事や職場環境への否定的感情に結びつかない。  
  
ウ  政治的行動は、公正かつ公平なものであると印象を管理することで正当化さ  
れ、倫理的ジレンマを回避できる。  
  
エ  政治的行動は、不確実性が高く組織目標間の優先順位について意見の不一致  
があるときに合意形成をつくるメカニズムである。  
  
オ  政治的行動は、倫理的には個人の自己利益と組織目標の双方に合致している  
ことが条件である。  
        
`
        },
        {
                "mondaiId": 103240160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
組織ストレスに関する説明とその対処のための介入法として、最も適切なものの  
組み合わせを下記の解答群から選べ。  
ａ介入対象の組織の従業員が、努力・報酬・職場環境の評価に基づく介入が実行  
されていると認識する場合に、介入が最も効果的となる。  
ｂ介入対象の組織の従業員が、介入案の策定や実施に関与し、その意思決定に参  
加することは、介入の効果を阻害する。  
ｃ組織・個人レベルで変化に対応する能力・意欲があることが、介入の成功につ  
ながり、過去に類似した経験をしていることは、その後の介入への反応に影響を  
与えない。  
ｄ現場の管理職の行動は、従業員へ介入を実行するプロセスにおいて、円滑なコ  
ミュニケーションを促進しようとする態度が、特に結果としての介入の効果の程  
度へ影響を与える。  

[解答群]  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
        
`
        },
        {
                "mondaiId": 103240171,
                "name": "第17問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
経営資源アプローチの提唱者としてしばしば指摘されるエディス・ペンローズ  
4EdithPenrose5は、その主著u企業成長の理論tにおいて企業を、経営資源の集  
合体であるとともに、またＡとしての側面を持つと定式化した。企業が  
Ａの側面を持つため、経営者集団自体が最も重要な経営資源となる。ペン  
ローズは、企業成長について、Ｂという結論を導き出した。これはペンロ  
ーズ効果とも呼ばれている。  
（設問1）5  
文中の空欄Ａにあてはまる言葉として最も適切なものはどれか。  
  
ア  株主の代理人  
  
イ  管理組織  
  
ウ  事業機会  
  
エ  生産関数  
  
オ  利害関係者の連合体`
        },
        {
                "mondaiId": 103240172,
                "name": "第17問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
文中の空欄Ｂにあてはまる記述として最も適切なものはどれか。  
  
ア  企業規模に関する収穫が一定なら、長期平均費用曲線は水平になる  
  
イ  企業の規模に限界はないが、その成長率は経営者の学習の速度によって制約  
される  
  
ウ  企業の規模は経営者効用が最大化される点まで成長する  
  
エ  企業は限界費用と限界収入が一致する点まで、成長することができる  
  
オ  長期平均費用曲線が最小となる点と長期限界費用曲線が一致する規模まで、  
企業規模は成長する  
        
`
        },
        {
                "mondaiId": 103240180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
現代のように様々な分野で知識創造が行われている社会では、すべての技術的知  
識を自社内で開発することは困難であり、企業のイノベーションプロセスには外部  
からの知識が不可欠になっている。この外部の知識を評価し活用する能力は、イノ  
ベーションにとって欠かせない能力となっており、この能力は｢吸収能力  
4absorptivecapacity5｣と呼ばれている。この吸収能力について、技術的機会や知  
識の占有可能性と、吸収能力や研究開発投資に関する記述として、最も適切なもの  
はどれか。  
  
ア  イノベーションが速い分野では、吸収能力の必要性は高くなるため、自社の研  
究開発投資を低く抑える必要がある。  
  
イ  応用科学に関連する技術的機会の増加に比べて、基礎科学に関する技術的機会  
の増大は、R&Dの必要性を低くする。  
  
ウ  吸収能力が高くなるにつれて、当該企業は中央研究所のような基礎研究を行う  
部門を持つ必要性が低くなるので、研究開発投資負担を削減することが可能にな  
る。  
  
エ  自社の基礎研究への投資は、吸収能力を高める効果を持ち、急速に進化する科  
学技術をイノベーションに活かすことに役立つ。  
  
オ  知識の占有可能性が高まると、社内外への波及効果が高くなるため、基礎科学  
分野よりも応用科学分野の方が、吸収能力の必要性は高くなる。  
        
`
        },
        {
                "mondaiId": 103240190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次のケースを読み、この工場で組織変革がうまくいかなかった理由または採るべ  
きであった手法に関する記述として、最も適切なものを下記の解答群から選べ。  
｢ある耐久消費財メーカーの経営者は、需要の変動に対応するために、それまで  
バッチ生産システムを採っていたアセンブリー工場に、新たにセル生産システムを  
導入しようと考えた。経営者ならびに工場長は、この工場の日常業務に影響を与え  
ないよう配慮し、コンサルタントを秘密裏に導入して従業員の行動を分析させると  
ともに、工場とは離れた本社で変革案を作成した。  
最終的に変革案がまとまった段階で、従業員全員を一堂に集め、会社のおかれた  
状況を説明し、明日からセル生産システムに移行すること、その移行の手続きにつ  
いてほぼ一日かけて説明した。従業員は疲れていたようだが、ただ黙っていただけ  
で特に反論もなかった。  
その後、新しい職務訓練を経て、セル生産システムへの移行を実施したが、生産  
性は著しく低下してしまった。｣  

[解答群]  
  
ア  工場と離れた本社で変革案を作成するのではなく、セル生産への移行プロセ  
スに工場の実態を反映させるために、工場内で従業員が帰ってから作成すべき  
だった。  
  
イ  従業員に対する説明の時間が長過ぎて、疲れてしまったため理解が得られな  
かったので、説明時間をもっと簡潔にすべきだった。  
  
ウ  セル生産システムへの移行について説明した際に、従業員から反論がなかっ  
たのは良く理解できなかったからであり、従業員一人一人を対象に説明をすべ  
きであった。  
  
エ  変革案の作成ならびに執行計画について、従業員たちを参加させ、フェアプ  
ロセスを経ていると実感させるべきであった。  
        
`
        },
        {
                "mondaiId": 103240200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
最低賃金法に定める最低賃金に関する記述として、最も適切なものはどれか。  
  
ア  最低賃金法上の最低賃金には、中央最低賃金審議会又は地方最低賃金審議会で  
の調査審議結果に基づいて厚生労働大臣又は都道府県労働局長が決定する地域別  
最低賃金と、企業ごとに決定する企業内最低賃金の二種類がある。  
  
イ  すべての使用者は、地域別最低賃金の適用を受ける労働者に対し、その地域で  
定められた最低賃金額以上の賃金を支払わなければならないが、これに違反した  
場合にも、行政指導を受けるだけで罰則はない。  
  
ウ  地域別最低賃金は、時間額について定められているので、時給制で働くパート  
タイマーやアルバイト、派遣社員などの非正規社員のみに適用され、月給制や年  
俸制の正社員には適用されない。  
  
エ  地域別最低賃金は、地域における労働者の生計費及び賃金並びに通常の事業に  
おける賃金支払能力を考慮して定めることとされているが、労働者の生計費を考  
慮するに当たっては、生活保護施策との整合性に配慮するものとされている。  
        
`
        },
        {
                "mondaiId": 103240210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
海外出向4派遣5に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  海外の現地法人に出向する労働者の賃金の全額を現地法人から支払う場合に  
は、出向元との間で雇用関係が継続されていても、その労働者に係るわが国の雇  
用保険の被保険者資格は喪失する。  
  
イ  海外の現地法人に出向する労働者は、出向先の地域に公的年金制度がある場合  
には、原則として現地の制度にも加入しなければならないが、わが国との間で社  
会保障協定を締結している地域では、二重に加入しなくてもよいこととされてい  
る。  
  
ウ  海外の現地法人に出向中の者が労災保険の適用を受けるためには、あらかじめ  
当該労働者ごとに海外派遣特別加入をしなければならない。  
  
エ  事業者は、(カ月以上労働者を海外に派遣しようとするときは、あらかじめ、  
当該労働者に対して法所定の項目等について医師による健康診断を受けさせなけ  
ればならない。また、派遣終了後帰任し、国内の事業に就かせるときも同様であ  
る。  
        
`
        },
        {
                "mondaiId": 103240220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
配置転換、出向、転籍等の人事異動に関する記述として、最も不適切なものはど  
れか。  
  
ア  アナウンサーや機械工といった特殊な技能を必要とする業務に従事する労働者  
についても、労働契約において職種限定の合意が認められない限り、個別の同意  
を得なくても配置転換を命ずることができる場合がある。  
  
イ  事業を全部譲渡する際に、転籍を承諾しない労働者がいる場合にも、転籍を承  
諾しないことのみを理由に解雇することはできず、解雇に当たっては整理解雇と  
同様の要件が求められる。  
  
ウ  出向4在籍出向5者に対する就業規則の適用は、一般に、労働時間関係や勤務関  
係、服務規律関係、安全衛生関係などについては出向先のものが適用され、解  
雇、退職、人事異動などの従業員としての地位に関する事項については、出向元  
の就業規則が適用されると解されている。  
  
エ  労働者に住居の移転を伴う転勤命令を課すためには、当該転勤先への異動が余  
人をもって代え難いといった高度の必要性までは求められないが、適正配置や業  
務能率の向上、能力開発、勤務意欲の高揚、業務運営の円滑化などといった、企  
業の都合を理由とするだけでは、転勤命令を課すことはできない。  
        
`
        },
        {
                "mondaiId": 103240230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
募集・採用及び雇用に関する記述として、最も適切なものはどれか。  
  
ア  56人以上の労働者を雇用する事業主4国及び地方公共団体を除く。5には、法定  
雇用率によって計算された人数の障害者を雇用することが義務づけられており、  
実際に障害者の雇用数がこの基準に満たない場合には、雇用数が1人不足するご  
とに一定額の罰金が課せられることとされている。  
  
イ  雇用の分野における男女の均等な機会及び待遇の確保等に関する法律は、募集  
及び採用について、｢その性別にかかわりなく均等な機会を与えなければならな  
い。｣と定めているので、女性が少ない企業で女性を優先的に採用しようとする場  
合にも、女性を有利に取り扱うことはできない。  
  
ウ  事業主は、新たに外国人労働者4特別永住者及び在留資格｢外交｣・｢公用｣の者  
を除く。5を雇入れた場合又はその労働者が離職した場合には、当該外国人労働者  
の氏名、在留資格、在留期間等を、ハローワークを通じて厚生労働大臣に届け出  
なければならない。  
  
エ  満65歳4平成24年度までは64歳。5までの雇用を確保する措置として継続雇用  
制度を設ける場合、継続雇用制度の対象となる高年齢者に係る基準を定め、当該  
基準に基づく制度を導入したときは、継続雇用制度を設けたものとみなすことと  
されているが、その基準は就業規則に定めるものでも差し支えない。  
        
`
        },
        {
                "mondaiId": 103240240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
試用期間と解雇に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  契約期間を1年間とする有期労働契約においても、最初の\u0005カ月間を試用期間  
と定めた場合に、本採用にふさわしくないと認められるときは、試用期間満了時  
に本採用しないこととすることができる。  
  
イ  試用期間中であっても、雇入れから14日を超えた後に解雇する場合には、解  
雇予告除外認定を受けた場合を除き、少なくとも30日前にその予告をするか、  
30日分以上の平均賃金を支払わなければならない。  
  
ウ  試用期間満了時の本採用拒否は、解雇に当たる。  
  
エ  労働基準法上の｢試の使用期間｣4試用期間5は14日間とされているが、この期  
間中は、解雇権濫用法理は適用されず、労働者を自由に解雇することができる。  
        
`
        },
        {
                "mondaiId": 103240251,
                "name": "第25問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
マーケティングについての共通認識であるマーケティング・パラダイムは時代と  
ともに変化している。1990年代には、それまでのＡパラダイムに変わっ  
て、Ｂパラダイムに注目が集まるようになった。その主要な背景として、  
Ｃによって新規顧客獲得のためのコストが非常に大きくなったことが挙げ  
られる。  
さらに最近では、P.コトラーらが、製品中心の｢マーケティング1.0｣、消費者  
志向の｢マーケティング2.0｣に代わる新たなマーケティングとして｢マーケティン  
グ3.0｣を提唱して大きな注目を集めている。  
（設問1）5  
文中の空欄Ａ~Ｃにあてはまる語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  Ａ：関係性Ｂ：交換Ｃ：ICTの進展  
  
イ  Ａ：関係性Ｂ：交換Ｃ：市場の成熟  
  
ウ  Ａ：交換Ｂ：関係性Ｃ：ICTの進展  
  
エ  Ａ：交換Ｂ：関係性Ｃ：市場の成熟`
        },
        {
                "mondaiId": 103240252,
                "name": "第25問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
文中の下線部の｢マーケティング3.0｣固有の特徴として、最も不適切なものは  
どれか。  
  
ア  企業理念の重視  
  
イ  協働志向・価値共創の重視  
  
ウ  顧客満足の重視  
  
エ  社会貢献・社会価値の重視  
  
オ  精神的価値の重視  
        
`
        },
        {
                "mondaiId": 103240261,
                "name": "第26問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
関与と知識は消費者行動、とりわけ購買意思決定に大きな影響を及ぼす。たとえ  
ば、消費者が重視する情報源は、関与と知識の水準によって表のように異なってい  
る。  
低  
関与  
高低  
知  
識  
高AB  
CD  
（設問1）5  
文中の下線部の｢関与｣に関する記述として最も適切なものはどれか。  
  
ア  感情的関与によって形成された態度は変化しにくい。  
  
イ  関与が高まってくると消費者の情報処理は単純化される。  
  
ウ  関与が高まってくると消費者の注意や情報探索の量が増加する。  
  
エ  ブランドに対する関与の水準は、そのブランドを含む製品カテゴリーに対す  
る関与に規定される。  
        
`
        },
        {
                "mondaiId": 103240262,
                "name": "第26問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
表のA~Dにあてはまる語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ａ：製品仕様書Ｂ：店頭の現物Ｃ：販売員の説明  
Ｄ：テレビCM  
  
イ  Ａ：製品仕様書Ｂ：販売員の説明Ｃ：店頭の現物  
Ｄ：テレビCM  
  
ウ  Ａ：テレビCMＢ：製品仕様書Ｃ：店頭の現物  
Ｄ：販売員の説明  
  
エ  Ａ：テレビCMＢ：店頭の現物Ｃ：販売員の説明  
Ｄ：製品仕様書  
  
オ  Ａ：販売員の説明Ｂ：テレビCMＣ：製品仕様書  
Ｄ：店頭の現物`
        },
        {
                "mondaiId": 103240270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消費者行動に影響を及ぼす社会的要因のひとつである準拠集団に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  近年家族の個人化が進んでおり、家族の消費者行動への影響を分析する際に  
は、ライフサイクルだけでなく、ライフコースにも注目する必要がある。  
  
イ  準拠集団とは、家族、職場、世代など、個人が直接・間接に所属している集団  
のことである。  
  
ウ  準拠集団は消費者にとって、情報源となるだけでなく、価値観や規範の形成要  
因ともなる。  
  
エ  プライベートな場面で使用される製品よりも、パブリックな場面で使用される  
製品の方がそのブランド選択において準拠集団の影響は大きくなる。  
        
`
        },
        {
                "mondaiId": 103240281,
                "name": "第28問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
モチベーション・リサーチでは、消費者の購買行動の根底にある深層心理を探る  
ため、様々な定性調査手法が用いられる。たとえば、  
①  
｢ダイエットについて一般的  
な主婦はどう考えているでしょうか｣といった質問への回答が専門家によって分析  
される。  
モチベーション・リサーチの方法論には、  
②  
いくつかの問題点が指摘されていた  
が、近年、インターネット調査とテキスト・マイニングを用いることでその問題が  
克服されるようになっている。  
（設問1）5  
文中の下線部①のような調査手法の名称として最も適切なものはどれか。  
  
ア  語句連想法イ第三者技法ウ文章完成法  
  
エ  物語法オロールプレイング法`
        },
        {
                "mondaiId": 103240282,
                "name": "第28問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
文中の下線部②に関連して、モチベーション・リサーチの方法論上の問題とし  
て最も適切なものの組み合わせを下記の解答群から選べ。  
ａサンプル・サイズの確保が困難。  
ｂ解釈の客観性の確保が困難。  
ｃビジュアル助成が困難。  
ｄ非合理的な動機の把握が困難。  

[解答群]  
  
ア  ａとｂイａとｄウｂとｃエｂとｄオｃとｄ  
        
`
        },
        {
                "mondaiId": 103240290,
                "name": "第29問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
カップ麺、レトルト食品、アイスクリームなどの容器を中心とするプラスチック  
製品の製造を行うA社では、レトルトタイプの画期的な食品容器の開発に成功し  
た。この新製品は次のような特徴を有している。  
非常に気密性が高く、これまで流通・消費において冷蔵を要した商品を、一度開  
封するまでは常温で保存することができるため、スーパーの冷蔵棚に陳列されてい  
る商品の多くに使用可能と考えられる。また、成形が容易で様々なサイズ展開がで  
きる上に、開封後も再封できるため消費者にとっての利便性も高い。  
同社は、このような特徴をうまくアピールして、同社とこれまで取引のなかった  
納豆、漬物、練り製品などの食品メーカーに販売することを計画しているが、その  
マーケティングとして、<u>最も不適切なものはどれか</u>。  
  
ア  営業のプレゼンテーションにおいて、自社の企業理念が顧客志向であることを  
訴求する。  
  
イ  大手メーカーの受注を確保するため、製品形態に加え、サービス対応や配送な  
ども徹底的に当該顧客のニーズに合わせてカスタマイズする。  
  
ウ  製品特性を活かして、小売店での陳列などを含めた提案営業を行う。  
  
エ  パブリシティや広告などの非人的情報によるプロモーションを行う。  
        
`
        },
        {
                "mondaiId": 103240300,
                "name": "第30問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章は、ブランドの機能について記述したものである。空欄A~Dに入る  
語句の組み合わせとして最も適切なものを下記の解答群から選べ。  
ブランドは製品やその販売者を識別する印としてAの基礎となると同時  
に、製品に関する責任の所在を明確にすることで品質や機能を保証する役割を果た  
す。これがブランドの基本機能であるが、優れたブランドが有する機能はこれだけ  
にとどまらない。  
たとえば、これまでに食品や飲料のブラインド・テストでたびたび示されてきた  
ように、ブランドが消費者のBを変化させることがある。また、｢○○な  
らば耐久性は心配ないだろう｣というように、購買行動における消費者の情報処理  
をブランドが単純化することもある。  
こうしたブランドの諸機能によって、競合他社に対して個別市場を形成すること  
でCが可能になる。また、消費者のDを形成することで、流通組  
織化の基盤をつくることができる。  

[解答群]  
  
ア  Ａ：差別化Ｂ：態度Ｃ：プレミアム価格  
Ｄ：ブランド・アイデンティティ  
  
イ  Ａ：差別化Ｂ：知覚Ｃ：プレミアム価格  
Ｄ：ブランド・ロイヤルティ  
  
ウ  Ａ：市場細分化Ｂ：態度Ｃ：価格競争  
Ｄ：ブランド・アイデンティティ  
  
エ  Ａ：市場細分化Ｂ：知覚Ｃ：価格競争  
Ｄ：ブランド・ロイヤルティ  
        
`
        },
        {
                "mondaiId": 103240310,
                "name": "第31問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
製品ライフサイクルの段階に対応した消費財のプロモーション戦略に関する記述  
として、<u>最も不適切なものはどれか</u>。  
  
ア  成熟期においては、自社ブランドに対するロイヤルティを維持したり、買い替  
えや買い増しを刺激するために、リマインダー型広告が重要となる。  
  
イ  成熟期の広告では、機能面での差別化よりもイメージ面での差別化が重要にな  
ることが多い。  
  
ウ  成長期には、自社ブランドの市場シェアを拡大するために、情報提供型広告に  
よってブランド選好を確立する必要がある。  
  
エ  導入期には、消費者向けSP4セールス・プロモーション5だけでなく、流通業  
者向けSPによって製品の取り扱いを確保することも重要である。  
  
オ  導入期の広告出稿においては、フリークエンシーよりもリーチの方が重要であ  
る。`
        },
        {
                "mondaiId": 103240320,
                "name": "第32問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
需要の不確実性の増大を背景に、多くの製品の流通・マーケティングは投機型か  
ら延期型に移行している。延期型のマーケティング・システムの構築の手段とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  POSシステム  
  
イ  SCM  
  
ウ  SPA型ビジネス・モデル  
  
エ  製販連携  
  
オ  大ロット生産  
        
`
        },
        {
                "mondaiId": 103240331,
                "name": "第33問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
2000年代に入ったころから、インターネットにおいて、ブログをはじめとする  
Aが消費者の間で爆発的に普及した。Aは消費者間での情報のや  
り取りを促進し、CtoCコミュニケーションを強力なクチコミの場へと成長させた。  
その後、2000年代後半になると、SNSや動画共有サービスなど、新たなツールが  
目覚ましく発達し、Aはソーシャル・メディアと呼ばれることが多くなっ  
た。  
現在では、ソーシャル・メディアは｢信頼や評判を稼ぐメディア｣4Earned  
Media5の主要な一部として、広告やスポンサーシップのようなB、自社  
サイトや販売員のようなCと並ぶ、重要なマーケティング・コミュニケー  
ション・ツールと考えられるようになっている。  
（設問1）5  
文中の空欄A~Cにあてはまる語句の組み合わせとして最も適切なものはど  
れか。  
  
ア  Ａ：CGMＢ：OwnedMediaＣ：PaidMedia  
  
イ  Ａ：CGMＢ：PaidMediaＣ：OwnedMedia  
  
ウ  Ａ：CRMＢ：OwnedMediaＣ：PaidMedia  
  
エ  Ａ：CRMＢ：PaidMediaＣ：OwnedMedia  
        
`
        },
        {
                "mondaiId": 103240332,
                "name": "第33問設問2",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
5  
文中の下線部の｢クチコミ｣に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  インターネット上のクチコミは対面でのクチコミよりも、広く速く情報が伝  
播する。  
  
イ  クチコミをマーケティング・コミュニケーションのツールとして利用する場  
合、倫理ガイドラインを整備・遵守する必要がある。  
  
ウ  購買意思決定においてクチコミが重視される原因のひとつに、消費者の情報  
過負荷がある。  
  
エ  コメント書き込みやトラックバックといった強力なリンク機能によってブロ  
グは情報伝播を促進した。  
  
オ  消費者の購買意思決定段階の後半になるほどクチコミの影響は小さくなる。  
`
        },
        {
                "mondaiId": 104240010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産管理における作業時間に関する記述として、最も適切なものはどれか。  
  
ア  間接作業時間には、作業現場における伝票処理の時間は含まれない。  
  
イ  主体作業時間は、直接作業時間の一部であり、主作業時間と付随作業時間とか  
ら構成される。  
  
ウ  直接作業時間には、準備段取時間は含まれない。  
  
エ  余裕時間は、標準時間を構成する要素であり、管理余裕時間と職場余裕時間に  
分類される。`
        },
        {
                "mondaiId": 104240020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産における管理目標?PQCDSMEOに関する記述として、最も適切なものはど  
れか。  
  
ア  Pは生産性を示し、労働生産性、設備生産性、製品生産性などにより評価され  
る。  
  
イ  Sは安全性を示し、度数率、強度率、労働損失日数などにより評価される。  
  
ウ  Mはモラールを示し、職務の満足度、集団の団結感、顧客の満足度などによ  
り評価される。  
  
エ  Eは環境を示し、製品の使用期間、生産リードタイム、廃棄物の量などにより  
評価される。  
        
`
        },
        {
                "mondaiId": 104240030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工場計画に関する記述として、最も適切なものはどれか。  
  
ア  ｢工場内物流計画｣では、人の動線や製品、部品、資材および廃棄物の動線に関  
する計画を行う。  
  
イ  ｢施設レイアウト計画｣では、電気、水、空気、ガス、光、熱、音、排水などの  
供給および処理に関する計画を行う。  
  
ウ  ｢設備計画｣では、生産、その他の要求機能に対する、建物の規模、形状および  
構造に関する計画を行う。  
  
エ  ｢建物計画｣では、建物や設備の相互関係およびスペースに関する計画を行う。`
        },
        {
                "mondaiId": 104240040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
信頼性設計におけるフェイルセーフに関する記述として、最も適切なものはどれ  
か。  
  
ア  機械加工中の刃の摩耗が限度を超えると、自動的に機械が停止するようにす  
る。  
  
イ  ネジの取り付け本数をセンサーで検出し、作業者の取り付けた本数が不足して  
いる場合に警報が鳴るようにする。  
  
ウ  部品の形状を非対称にしておき、反対向きに取り付け作業ができないようにす  
る。  
  
エ  モーターの回転部分に保護カバーを取り付け、触れることができないようにす  
る。  
        
`
        },
        {
                "mondaiId": 104240050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産活動におけるコンピュータ支援技術に関する記述として、最も適切なものは  
どれか。  
  
ア  コンピュータの内部に表現されたモデルに基づいて、生産に必要な各種情報を  
作成すること、およびそれに基づいて進める生産の形式は、CADと呼ばれる。  
  
イ  生産活動に関連する設備、システムの運用、管理などについて、コンピュータ  
の支援のもとで教育または学習を行う方法は、CAIと呼ばれる。  
  
ウ  製品の形状その他の属性データからなるモデルをコンピュータ内部に作成し、  
解析・処理することによって進める設計は、CAEと呼ばれる。  
  
エ  製品を製造するために必要な情報をコンピュータを用いて統合的に処理し、製  
品品質、製造工程などを解析評価することは、CAMと呼ばれる。`
        },
        {
                "mondaiId": 104240060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
産業用ロボットのアクチュエータに関する記述として、最も適切なものはどれ  
か。なお、ここでアクチュエータとは、ロボットの動力を発生させる機構であり、  
電気、油圧、空気圧エネルギーなどが利用される。  
  
ア  空気圧を動力源とするものは、直接には回転運動が得られないため、直線運動  
から回転運動へ変換する装置が必要となる。  
  
イ  空気圧を動力源とするものは、油圧より高精度な位置決めができる。  
  
ウ  油圧を動力源とするものは、空気圧より大きな動力が得られる。  
  
エ  油圧を動力源とするものは、空気圧より物を高速に移動させることができる。  
        
`
        },
        {
                "mondaiId": 104240070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産の管理方式の\fつであるオーダエントリー方式に関する記述として、最も適  
切なものはどれか。  
  
ア  後工程から引き取られた量を補充するためだけに、生産を指示する。  
  
イ  受注時に、製造設備の使用日程・資材の使用予定などのオーダを割り付ける。  
  
ウ  生産工程にある製品に顧客のオーダを引き当て、製品仕様の選択または変更を  
する。  
  
エ  製造命令書を発行するときに、その製品に関するすべての作業の指示書を同時  
に準備する。`
        },
        {
                "mondaiId": 104240080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
物の流れの分析手法に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  P安Qチャートは、横軸に製品種類Pをとり、縦軸に生産量Qをとって、生  
産量Qの大きい順に並べて作成される。  
  
イ  運搬活性示数は、対象品の移動のしやすさを示す数で、バラ置きの対象品を移  
動する場合、①まとめる、②起こす、③移動する、という3つの手間が必要とな  
る。  
  
ウ  流れ線図?フローダイヤグラムOでは、物や人の流れ、逆行した流れ、隘路、無  
用な移動、配置の不具合が視覚的に把握できる。  
  
エ  流入流出図表?フロムツウチャートOは、多品種少量の品物を生産している職場  
の、機械設備および作業場所の配置計画をするときに用いられる。  
        
`
        },
        {
                "mondaiId": 104240090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
生産される製品の種類と量により、基本的なレイアウトのタイプは、一般に、製  
品固定型、製品別、グループ別、工程別の  
つに分類される。レイアウトのタイプ  
と生産される製品の種類・量との組み合わせにおいて、最も関連性の強いものはど  
れか。  
  
ア  製品固定型レイアウト多品種少量生産  
  
イ  製品別レイアウト少品種多量生産  
  
ウ  グループ別レイアウト少品種少量生産  
  
エ  工程別レイアウト中品種中量生産`
        },
        {
                "mondaiId": 104240100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
発注方式における発注点と発注量の決定に関する記述として、最も不適切なもの  
はどれか。  
  
ア  ?s，SO方式における発注量は、一般に、決められた在庫調査間隔での在庫量  
が発注点sを下回ったとき、補充点Sと在庫量の差として算出する。  
  
イ  \u000eビン方式における発注点は、一般に、発注量と同じである。  
  
ウ  定期発注方式における発注量は、一般に、?調達期間＋発注間隔O先までの払  
い出し量の予測値に安全在庫を加えて算出する。  
  
エ  定量発注方式における発注点は、一般に、調達期間中の平均的な払い出し量に  
安全在庫量を加えて算出する。  
        
`
        },
        {
                "mondaiId": 104240110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある販売店では、翌日に繰り越すことのできない商品を、日々、仕入れて販売し  
ている。商品の日々の需要量は、過去の実績から、平均が\u0005個で、その発生確率は  
下表で与えられることが知られている。需要量が仕入量を超えたとき、品切れが発  
生す��。  
日々の仕入量を\u0005個に設定したときの平均品切れ量に最も近い値を、下記の解答  
群から選べ。  
需要量発生確率累積確率  
00.080.08  
10.140.22  
20.230.45  
30.220.67  
40.140.81  
50.070.88  
60.050.93  
70.040.97  
80.020.99  
90.011.00  

[解答群]  
  
ア  0.3  
  
イ  0.5  
  
ウ  0.7  
  
エ  0.9  
        
`
        },
        {
                "mondaiId": 104240120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
管理図の用途に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  観測値を用いて工程を管理状態に保持するために、解析用管理図を用いる。  
  
イ  群の大きさに対する不適合品数の割合を用いて工程を評価するために、p管理  
図を用いる。  
  
ウ  群の標準偏差を用いて工程の分散を評価するために、ｓ管理図を用いる。  
  
エ  サンプルの個々の観測値を用いて工程を評価するために、X管理図を用いる。`
        },
        {
                "mondaiId": 104240130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
過去の需要量の時系列データを用いる需要予測法に関する記述として、最も不適  
切なものはどれか。  
  
ア  移動平均法では、データが過去にさかのぼるほど、その重みが増加する。  
  
イ  移動平均法の予測精度は、用いるデータの数に影響される。  
  
ウ  指数平滑法では、データが過去にさかのぼるほど、その重みが減少する。  
  
エ  指数平滑法の予測誤差は、平滑化定数の値に影響される。  
        
`
        },
        {
                "mondaiId": 104240140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
受注したジョブは、\u0006つの作業要素Ａ~Ｇをもっている。各要素の作業時間と作  
業要素間の先行関係が下表に与えられている。このジョブの最短処理時間に最も近  
いものを下記の解答群から選べ。  
Ａ3Ｄ  
Ａ4Ｃ  
5Ｂ  
6Ａ  
先行作業作業時間作業要素  
Ｄ，Ｅ2Ｇ  
Ｃ3Ｆ  
Ｂ5Ｅ  

[解答群]  
  
ア  11  
  
イ  12  
  
ウ  13  
  
エ  14  
        
`
        },
        {
                "mondaiId": 104240150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
以下は、演繹的な生産システムの設計法の\fつである｢ワークデザイン｣の最初か  
ら\u0007番目までの分析手順を示したものである。空欄Ａ~Ｃにあてはまる記述の組み  
合わせとして、最も適切なものを下記の解答群から選べ。  

1．Ａ  
\u000e．Ｂ  
\u0005．Ｃ  
        
．代案の作成  
\u0007．実行可能案の選択  

[解答群]  
  
ア  Ａ：機能の決定Ｂ：情報の収集  
Ｃ：理想システムの展開  
  
イ  Ａ：機能の決定Ｂ：理想システムの展開  
Ｃ：情報の収集  
  
ウ  Ａ：情報の収集Ｂ：機能の決定  
Ｃ：理想システムの展開  
  
エ  Ａ：理想システムの展開Ｂ：機能の決定  
Ｃ：情報の収集  
        
`
        },
        {
                "mondaiId": 104240160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
連合作業分析に関する記述として、最も適切なものはどれか。  
  
ア  連合作業分析では、作業を単独作業、連合作業、連続作業の3つに分類して作  
業分析を実施する。  
  
イ  連合作業分析では、作業を要素動作の単位に分割して分析を実施する。  
  
ウ  連合作業分析は、配置人員を検討する際に利用できる。  
  
エ  連合作業分析は、複数の素材を組み合わせて製品を製造するプロセスを分析す  
るための手法である。`
        },
        {
                "mondaiId": 104240170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
初期導入された設備を300時間利用したときの稼働および故障修復について、下  
図のような調査結果が得られた。この設備の①MTBF(平均故障間隔)、②MTTR  
(平均修復時間)、③アベイラビリティー(可用率)の値として、最も適切なものの組  
み合わせを下記の解答群から選べ。  
稼働  
修  
復稼働  
修  
復稼働  
修  
復  
08095170195280300(時間)  

[解答群]  
  
ア  ①：20②：80③：0.2  
  
イ  ①：20②：80③：0.8  
  
ウ  ①：80②：20③：0.2  
  
エ  ①：80②：20③：0.8  
        
`
        },
        {
                "mondaiId": 104240180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
設備投資額として2，000万円の初期投資を行うと、その後5年間にわたって毎年  
末に450万円の人件費の節減効果が期待できる設備投資案の内部収益率は約  
％  
と計算される。この投資案の正味現在価値?円Oと金利を考慮した割引回収期間?年O  
に関する記述として、最も適切なものはどれか。ただし、資本コストは年率\u0006％  
とする。また、税金は考慮しないものとする。  
  
ア  正味現在価値＞かつ割引回収期間＞\u0007  
  
イ  正味現在価値＞かつ割引回収期間＜\u0007  
  
ウ  正味現在価値＜かつ割引回収期間＞\u0007  
  
エ  正味現在価値＜かつ割引回収期間＜\u0007`
        },
        {
                "mondaiId": 104240190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
仕事を改善するための基本原則である｢ECRSの原則｣が適用できる分析手法とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  製品工程分析  
  
イ  流動数分析  
  
ウ  両手動作分析  
  
エ  連続稼働分析  
        
`
        },
        {
                "mondaiId": 104240200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
｢エネルギーの使用の合理化に関する法律｣?省エネ法Oに関する記述として、最も  
適切なものはどれか。  
  
ア  省エネ法が直接規制する事業分野は、工場、輸送、建築物の\u0005分野である。  
  
イ  省エネ法で規定する｢エネルギー管理指定工場等｣では、エネルギー管理者もし  
くはエネルギー管理員を選任することが義務付けられている。  
  
ウ  省エネ法で規定する｢特定事業者｣は、中長期的にみて年平均10％以上のエネ  
ルギー使用量の低減を達成しなければならない。  
  
エ  省エネ法では、事業者のエネルギー使用量を、燃料と熱の使用量に基づいて算  
定する。`
        },
        {
                "mondaiId": 104240210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
労働災害におけるハインリッヒの法則に関する記述として、最も不適切なものは  
どれか。  
  
ア  \f件の重大な傷害事故の背後には約30件の軽微な傷害事故が存在する。  
  
イ  \f件の重大な傷害事故の背後には約300件の無傷事故が存在する。  
  
ウ  ハインリッヒの法則は、事故事例の調査に基づく経験則である。  
  
エ  労働環境において、不安全状態を原因とする事故は不安全行動を原因とする事  
故よりも多い。  
        
`
        },
        {
                "mondaiId": 104240220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
大規模小売店舗立地法は、大規模小売店舗の設置者に対し、特に周辺地域の生活  
環境の保持のため、その施設の配置および運営方法について合理的な範囲で配慮を  
求めている。大規模小売店舗を設置する者が配慮すべき事項として、最も不適切な  
ものはどれか。  
  
ア  騒音の発生に係る事項への配慮  
  
イ  地域商業の需給調整への配慮  
  
ウ  駐車需要の充足等交通に係る事項への配慮  
  
エ  廃棄物に係る事項等への配慮  
  
オ  街並みづくり等への配慮  
        
`
        },
        {
                "mondaiId": 104240230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中心市街地活性化法が定めた中心市街地の要件として、最も適切なものを下記の  
解答群から選べ。  
ａ相当数の小売商業者が集積し、都市機能が相当程度集積している市街地であ  
り、その存在している市町村の中心としての役割を果たしていること。  
ｂ土地利用及び商業活動の状況等からみて、機能的な都市活動の確保又は経済活  
力の維持に支障を生じ、又は生ずるおそれがあると認められる市街地であるこ  
と。  
ｃ都市機能の増進及び経済活力の向上を総合的かつ一体的に推進することが、市  
街地の存在する市町村及びその周辺の地域の発展にとって有効かつ適切であると  
認められること。  

[解答群]  
  
ア  ａ、ｂ、ｃいずれか\fつの要件を満たしていること。  
  
イ  ａ、ｂ、ｃすべての要件を満たしていること。  
  
ウ  ａおよびｂの要件を満たしていること。  
  
エ  ａおよびｃの要件を満たしていること。  
  
オ  ｂおよびｃの要件を満たしていること。  
        
`
        },
        {
                "mondaiId": 104240240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消防法により、大規模な小売店舗が義務付けられている防火管理として、最も不  
適切なものはどれか。  
  
ア  消防機関への通報、初期消火、避難誘導などを円滑に行うため、自衛消防組織  
を置かなければならない。  
  
イ  建物が密集し火災の危険度が高い防火地域へは、新規に大規模な小売店舗を出  
店することができない。  
  
ウ  防火管理者を定め、店舗の消防計画を作成し、それに基づき、消火、通報及び  
避難の訓練の実施などを行わなければならない。  
  
エ  廊下、階段、避難口その他の避難上必要な施設について避難の支障になる物件  
が放置されないように管理しなければならない。  
        
`
        },
        {
                "mondaiId": 104240250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本のショッピングセンターの現況に関して、次の文中の空欄Ａ~Ｄに入る最も  
適切なものの組み合わせを下記の解答群から選べ。  
なお、解答群のうち、｢周辺地域｣とは｢中心地域に隣接した都市機能が適度に存  
在する地域｣を、｢郊外地域｣とは｢都市郊外で住宅地や農地等が展開されている地  
域｣をいう。  
日本ショッピングセンター協会では、ショッピングセンターの基準をＡ  
により計画、開発されるものであり、①小売業の店舗面積が1，500m2以上であり、  
②キーテナントを除くテナントが10店舗以上含まれ、③キーテナントの面積がシ  
ョッピングセンター面積の80％程度を超えないこと、④Ｂ等があり、広  
告宣伝、共同催事などの共同活動を行っていることとしている。  
ショッピングセンターの立地傾向は、2000年以降、Ｃへの立地が約\u000f  
?\u0006割を占めていたが、2010年は\u0007割を下回った。それに対してＤへの  
立地が、約\f?\u000e割から約  
割に増大した。  

[解答群]  
  
ア  Ａ：自治体Ｂ：商業協同組合Ｃ：郊外地域Ｄ：周辺地域  
  
イ  Ａ：自治体Ｂ：テナント会Ｃ：周辺地域Ｄ：郊外地域  
  
ウ  Ａ：ディベロッパーＢ：商業協同組合Ｃ：周辺地域Ｄ：郊外地域  
  
エ  Ａ：ディベロッパーＢ：テナント会Ｃ：郊外地域Ｄ：周辺地域  
        
`
        },
        {
                "mondaiId": 104240260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある小売店では、年間売上高2，900万円、期首在庫高?原価O800万円、期中仕入  
高1，600万円、期末在庫高?原価O700万円であった。この店のGMROIとして最も  
適切なものはどれか。  
  
ア  120％  
  
イ  130％  
  
ウ  160％  
  
エ  180％  
  
オ  200％`
        },
        {
                "mondaiId": 104240270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある小売店舗は3つの部門から構成されている。A部門の粗利益率は25％で売  
上高構成比は40％、B部門の粗利益率は30％で売上高構成比は40％、C部門の  
粗利益率は35％で売上高構成比は20％である。この店舗の相乗積の値として最  
も適切なものはどれか。  
  
ア  28％  
  
イ  29％  
  
ウ  30％  
  
エ  31％  
  
オ  32％  
        
`
        },
        {
                "mondaiId": 104240280,
                "name": "第28問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
イ  ンストアプロモーション?ISPOには価格主導型ISPと非価格主導型ISPがあ  
る。価格主導型ISPとして最も適切なものの組み合わせを下記の解答群から選べ。  
ａクロスマーチャンダイジング  
ｂサンプリング  
ｃ増量パック  
ｄバンドル販売  

[解答群]  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ`
        },
        {
                "mondaiId": 104240290,
                "name": "第29問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商品陳列方法とそのメリットに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  カットケース陳列には、高級感を出しやすいというメリットがある。  
  
イ  ゴンドラ陳列には、フェイスをそろえやすいというメリットがある。  
  
ウ  ジャンブル陳列には、ディスプレイに手間がかからないというメリットがあ  
る。  
  
エ  ショーケース陳列には、商品が汚れにくいというメリットがある。  
  
オ  フック陳列には、陳列されている商品の在庫量が分かりやすいというメリット  
がある。  
        
`
        },
        {
                "mondaiId": 104240300,
                "name": "第30問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
フランチャイズ・チェーンに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  フランチャイズ・チェーンでは、加盟店は本部から店舗運営ノウハウなどを得  
ることができる。  
  
イ  フランチャイズ・チェーンの本部は、加盟店によって結成された組織である。  
  
ウ  フランチャイズ・チェーンの目的の\fつは、規模のメリットを追求する点であ  
る。  
  
エ  フランチャイズ・チェーンのロイヤルティの仕組みには、粗利益分配方式、売  
上高比例方式、定額方式などがある。`
        },
        {
                "mondaiId": 104240310,
                "name": "第31問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
棚割?プラノグラムOの目的に関する記述として、最も適切なものはどれか。  
  
ア  棚内のゾーニングの工夫によって客動線を長くすることができる。  
  
イ  バーティカル陳列によって同じグループ内の商品比較がしやすい売場をつくる  
ことができる。  
  
ウ  フェイシングの工夫によって売上高や商品回転率を上げることができる。  
  
エ  ホリゾンタル陳列によって商品グループ間の比較がしやすい売場をつくること  
ができる。  
        
`
        },
        {
                "mondaiId": 104240320,
                "name": "第32問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
日本の物流センターに関する記述として最も適切なものはどれか。  
  
ア  小売業の専用センターで共同配送を行うと、店舗への配送頻度を高めてサービ  
スレベルを高めることができるが、物流コストは増加する。  
  
イ  小売業の専用センターは、卸売業者により運営されていることが多い。  
  
ウ  小売業の専用センターは、クロスドッキングの機能を持つ通過型センターであ  
る。  
  
エ  センターフィーは、小売業者の得る利益等を勘案して合理的であると認められ  
る範囲であることが｢大規模小売業告示｣で求められている。`
        },
        {
                "mondaiId": 104240330,
                "name": "第33問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
サプライチェーンにおけるブルウィップ効果に関する記述として最も適切なもの  
はどれか。  
  
ア  ブルウィップ効果は、卸売業者から小売業者へ納品する頻度を減らすことで抑  
制できる。  
  
イ  ブルウィップ効果は、サプライチェーンにおいて情報共有が進むと抑制でき  
る。  
  
ウ  ブルウィップ効果は、サプライチェーンの中で特に小売店に大きな影響を与え  
る。  
  
エ  ブルウィップ効果は、メーカーから最終消費者までの流通経路が短いほど効果  
が大きい。  
        
`
        },
        {
                "mondaiId": 104240340,
                "name": "第34問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
RFIDを活用することにより、サプライチェーンにとって期待できることとし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  検品や入出庫管理にかかる時間を短縮できるようになる。  
  
イ  在庫状況をリアルタイムで把握できるようになる。  
  
ウ  トレーサビリティの情報管理ができるようになる。  
  
エ  バーコードと比べて商品管理システムの導入コストが安価になる。`
        },
        {
                "mondaiId": 104240350,
                "name": "第35問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
トレーサビリティに関する記述として最も適切なものはどれか。  
  
ア  トレーサビリティでは、製品の原材料から消費後の廃棄に至るまでに排出する  
二酸化炭素の量を製品に表示することが必要である。  
  
イ  トレーサビリティは、原材料の産地や製造工場を製品ラベルに記載することを  
指す。  
  
ウ  トレーサビリティは、商品の品質を高め最終消費者に安全なものを提供できる  
ように小売業が行う。  
  
エ  トレーサビリティを構築する仕組みには、ロットナンバー管理とシリアルナン  
バー管理という2つの方法がある。  
        
`
        },
        {
                "mondaiId": 104240360,
                "name": "第36問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
流通における発注方式のうち、定期発注方式と定量発注方式に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  定期発注方式では、発注サイクルを短縮すると在庫が減少する。  
  
イ  定期発注方式は、需要予測の精度が低くても品切れを起こしにくい。  
  
ウ  定量発注方式では、発注点を高くすると品切れが起こりにくい。  
  
エ  定量発注方式は、需要が安定している商品に向いている。`
        },
        {
                "mondaiId": 104240370,
                "name": "第37問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流のコスト分析手法である物流ABCに関する記述として、最も不適切なもの  
はどれか。  
  
ア  物流ABCでは、出荷や配送などの活動ごとに作業時間や作業量を把握する。  
  
イ  物流ABCでは、人、施設、機器に注目してコストを分析する。  
  
ウ  物流ABCにより、顧客別の採算分析ができるようになる。  
  
エ  物流ABCにより、出荷\fケースあたりのコストを算出することができるよう  
になる。  
        
`
        },
        {
                "mondaiId": 104240380,
                "name": "第38問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
国土交通省は、モーダルシフト等推進事業に関して補助金等により事業者を支援  
している。モーダルシフトに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  モーダルシフトにより、環境負荷の軽減が期待される。  
  
イ  モーダルシフトによ��、出荷ロットが小さくなる場合が多い。  
  
ウ  モーダルシフトにより、長距離区間の一括大量輸送のコスト削減が期待され  
る。  
  
エ  モーダルシフトにより、配送のリードタイムが長くなる場合が多い。`
        },
        {
                "mondaiId": 104240390,
                "name": "第39問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
消化仕入?あるいは売上仕入Oについてのシステム化計画に関する記述として、最  
も適切なものはどれか。  
  
ア  売れ残った在庫を返品することができるために、返品処理の設計が必要とな  
る。  
  
イ  日次などの締め処理の中で、売上計上分だけを仕入として処理する必要があ  
る。  
  
ウ  納品段階で、入庫処理や仕入計上を行うために、在庫や買掛金の管理システム  
が必要となる。  
  
エ  販売手数料が得られるために、売上や原価の処理システムを設計する必要がな  
い。  
        
`
        },
        {
                "mondaiId": 104240400,
                "name": "第40問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商品の販売データの分析に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  いったん｢売れ筋｣商品と位置づけられた商品であっても、条件が変われば｢死  
に筋｣商品になる可能性がある。  
  
イ  いわゆる｢ロングテール現象｣とは、インターネット通信販売などにおいて、  
｢死に筋｣商品の売上をすべて合計すると大きな売上が得られるという現象を指  
す。  
  
ウ  小売店舗の売場面積は限られているために、交差比率の低い｢死に筋｣商品を排  
除することが重要である。  
  
エ  販売数量を期待できないが、他の商品の販売促進効果が期待できる商品群を  
｢見せ筋｣ということがある。`
        },
        {
                "mondaiId": 104240410,
                "name": "第41問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
下表は、Ａ~Ｄの  
種類のキーワードで検索エンジン広告を利用したときの状況  
を表している。このとき、広告効果の評価指標の\fつであるCVR?Conversion  
RateOが最も高いキーワードを下記の解答群から選べ。  
キーワード総費用／月獲得クリック数／月  
A10万円500  
B6万円300  
C5万円250  
D1万円100  
申込数／月  
25  
15  
20  
10  

[解答群]  
  
ア  キーワードＡイキーワードＢウキーワードＣ  
  
エ  キーワードＤ  
        
`
        },
        {
                "mondaiId": 104240420,
                "name": "第42問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年、情報通信技術の発達や利用者ニーズの多様化などにより、資金決済システ  
ムを巡る環境は大きく変化してきている。そのような変化に対応するために、資金  
決済に関する法律?資金決済法Oが制定され、平成22年  
月1日より施行されてい  
る。この法律に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  銀行等以外の一般事業者が、為替取引?顧客から依頼を受けて資金を移動する  
サービスOを営む資金移動業を創設した。  
  
イ  商品券やIC型プリペイドカードなどの前払式支払手段に加えて、新たに、サ  
ーバ型前払式支払手段を規制の対象とした。  
  
ウ  前払式支払手段の払戻し?換金、おつりなどOを原則として禁止することとし  
た。  
  
エ  有効期限が1年以内である前払式支払手段については、規制の対象外とした。  
  
オ  利用者保護の仕組みとして、前払式支払手段の発行者は、未使用残高の\u000e分の  
\f以上に相当する額の発行保証金を供託することが義務づけられた。  
        
`
        },
        {
                "mondaiId": 104240430,
                "name": "第43問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物流情報システムの用語に関する記述として、最も適切なものの組み合わせを下  
記の解答群から選べ。  
ａPOSデータから消費者が購入した分だけ補充するシステムをVMIという。  
ｂ国内統一物流EDI標準の\fつにJTRNがある。  
ｃ商品が納品される前に、納品予定数などの情報を事前に相手先に送付すること  
をASNという。  
ｄ納入業者に発注業務を委託することをCRPという。  

[解答群]  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 105240010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
Wホールディングス株式会社及びその子会社における役員の状況は以下のとお  
りである。  
同じ英文字は、同じ人物であることを示し、取締役のうち、○がついている者は  
業務執行取締役とする。このとき、｢会社法第  
条に定義される社外取締役又は社  
外監査役｣に該当しない者として最も適切な者を下記の解答群から選べ。なお、当  
該図以外の要件については特に問題とならないものとする。  
Wホールディングス株式会社  
取締役：Ａ、Ｂ、Ｃ、D、E  
監査役：F、G、H、I  
X株式会社  
取締役：A、Ｊ、Ｋ  
監査役：C、L  
Y株式会社  
取締役：B、Ｍ、Ｎ  
監査役：E、L  
Z株式会社  
取締役：C、Ｐ  
監査役：L  
[解答群]  
  
ア  Wホールディングス株式会社における取締役A  
  
イ  X株式会社における監査役C  
  
ウ  Y株式会社における取締役B  
  
エ  Z株式会社における監査役L  
        
`
        },
        {
                "mondaiId": 105240020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株主管理のコストに関する以下の会話は、中小企業診断士であるあなたと、顧客  
である株式会社の代表取締役甲氏との間で、平成24年\u000e月\u000e日に行われたもので  
ある。その前提で、会話中の空欄に当てはまる語の組み合わせとして、最も適切な  
ものを下記の解答群から選べ。  
甲氏：｢実は、当社では、株主管理のためのコストが問題となっていまして…。｣  
あなた：｢御社の株主の状況はどうなっていましたっけ。｣  
甲氏：｢この書面のとおりです。｣  
あなた：｢ええっ、本当ですか…。この\f株ずつ持っている500名はどういった人  
ですか。｣  
甲氏：｢取引先の経営者かその関係者です。何十年も前に、取引先にも株を持っ  
てもらおうということで、先代の社長が実施しまして、当社は資本金が  
5，000万円しかないのに、株主は約500名という形になりました。これで  
も当初は取引先とも関係がよくなるなどメリットは多かったのですが、そ  
の後の長い間に取引先も代替わりや廃業などがあって、現在では、メリッ  
トは失われ、毎年の株主総会の招集通知を送るコストだけでもばかになら  
ないよという話になってきまして。｣  
あなた：｢なるほど。そうしますと、ＡあるいはＢを利用するこ  
とが考えられると思います。｣  
甲氏：｢そうするとどうなるのですか。｣  
あなた：｢どちらでも、例えば、今の10株を\fつのまとまりにしてしまう、といっ  
たことができます。そうすると、その他500名の方に、株主総会の招集通  
知を送る必要がなくなります。｣  
甲氏：｢  
つの方法では何が違うのですか。｣  
あなた：｢Ａの場合、これらの500名の方は、最終的には、お金が支払わ  
れ、御社の株主ではなくなります。Ｂの場合は、買取請求をされ  
たりした場合には株主でなくなりますが、そうでなければ、これらの500  
名の方も株主であり続けます。｣  
        
        
甲氏：｢今年の招集通知を送らなくても済む方法を使いたいのですが。｣  
あなた：｢残念ながら、どちらの方法も、株主総会での特別決議がないと実施でき  
ないので、最短でも、今回の総会で承認決議をしてからということになり  
ます。｣  
E甲氏が持参した書面G  
当社株式合計\f万株  
I内訳L  
甲6，200株  
X氏2，200株  
Y氏1，000株  
Z氏100株  
その他500名各\f株  
[解答群]  
  
ア  Ａ：株式分割Ｂ：単位株制度  
  
イ  Ａ：株式併合Ｂ：単元株制度  
  
ウ  Ａ：単位株制度Ｂ：株式併合  
  
エ  Ａ：単元株制度Ｂ：株式分割  
        
`
        },
        {
                "mondaiId": 105240030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
いわゆる濫用的会社分割に関する以下の会話は、中小企業診断士であるあなた  
と、顧客であるX株式会社の代表取締役甲氏との間で行われたものである。X株  
式会社がA設備株式会社に対して売掛金を請求する場合の法的根拠として、最も  
適切なものを下記の解答群から選べ。なお、以下の会話中、A社とはA株式会社  
のことをいう。  
甲氏：｢取引先のA社から、支払いがなくなったんですよ。それであわてて、A  
社の本社に行ってみたら、A社という会社の看板はなくなっていて、代  
わりに、A社と全く同じ設備、同じ人で、名前だけがA設備株式会社と  
いう名前になって、同じ営業をしていたんです。それでどういうことだと  
思って話を聞いてみたら、会社分割という方法を使って、今度からはA  
設備株式会社がここで営業をする、と言うんですよ。  
それなので、うちの売掛金も払ってもらえるもんだと思って話をしてみた  
ら、A設備株式会社は、A社とは別の会社だから支払えない、A社は閉  
めてしまった、と言うんです。｣  
あなた：｢それはやられましたね。濫用的会社分割などと言われているものだと思  
います。｣  
甲氏：｢濫用的会社分割…。それはどういったものですか。｣  
あなた：｢資産を別会社に移して、従来の負債はそのまま元の会社に残しておいて、  
実質的に債務を免れるものです。｣  
甲氏：｢どうしてそんなことができるんですか。債権者に連絡したりしないとい  
けないんじゃないですか。｣  
あなた：｢元の会社が負債の全部の支払を引き受けるときには、元の会社の債権者へ  
の連絡はいらないんですよ。今回の場合も、A社が全部の債務を負うとい  
うことにしていれば、何の連絡もなく、会社分割ができてしまうんですよ。｣  
甲氏：｢そうすると、彼らの言うとおり、もうA設備株式会社には請求ができな  
いということですか。｣  
あなた：｢いえ、必ずしもそうではありません。何か方法があるはずです。知り合  
        
        
いの弁護士を紹介しますから相談してみてください。｣  
[解答群]  
  
ア  債権者代位  
  
イ  詐害行為取消権  
  
ウ  併存的債務引受  
  
エ  連帯保証`
        },
        {
                "mondaiId": 105240040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
あなたの顧客であるX株式会社I以下｢X社｣という。Lの代表取締役甲氏からの、  
X社の組織再編に関する以下の相談内容を前提に、Y株式会社I以下｢Y社｣とい  
う。LのC部門を独立した\fつの会社とする手続きとして最も適切なものを下記の  
解答群から選べ。  
E甲氏の相談内容G  
X社では、100パーセント子会社としてA株式会社I以下｢A社｣という。Lを保有  
している。  
一方、Y社では、B部門とC部門の  
つの事業を行っており、このうち、C部  
門の事業は、A社の事業と同じである。  
X社としては、事業を拡大するため、Y社のC部門を譲り受けたい。  
譲り受けるにあたっては、A社とY社では、従業員の処遇に違いがあることか  
ら、一度に統合することは難しい可能性もある。そのため、C部門をそのまま切り  
出して、直接\fつの独立した会社とした後に、その株式を譲り受け、A社と同様  
に、X社の100パーセント子会社とすることとしたい。  
[解答群]  
  
ア  吸収合併  
  
イ  吸収分割  
  
ウ  事業譲渡  
  
エ  新設分割  
        
`
        },
        {
                "mondaiId": 105240050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたと、顧客である甲氏との間の遺産分割に関する以下  
の会話を読んで、あなたの回答として空欄に当てはまる最も適切なものを下記の解  
答群から選べ。  
甲氏：｢実は、私の友人のAさんに私の会社から300万円貸していたんですよ。  
ところが、そのAさんは、1年ほど前に亡くなってしまって…。  
Aさんの奥さんはもうお亡くなりになっているんですが、息子さんが  
        
人いるんです。それで、たまたま、次男のほうは、大きな会社に勤める  
人で知っていたものですから、Aさんに貸したお金を返して欲しいとい  
う話を\u0005か月くらい前にしに行ったんですよ。  
そうしたら、その次男が言うには、去年の夏に、長男と次男で、遺産分  
割協議をして、全部長男が相続することになった、だから、自分は支払う  
必要はないはずだ、って言うんですよ。それで、その次男からは、司法書  
士に作ってもらったという遺産分割協議書も見せられたんですが、確か  
に、長男と次男の連名で実印も押してあって、長男が全部相続して次男は  
何も相続しないことになっていたんです。  
ですので、先日、長男の家を訪ねたのですが、長男はリストラにあって  
しまって、お金がないので、返したくても返せないと言うんですよ。  
次男は、大きな会社に勤めているので、返す能力はあると思うのです  
が、次男に請求することはできないんでしょうかね…。｣  
あなた：｢。弁護士を紹介しますからご相談されてはいかがですか。｣  
[解答群]  
  
ア  遺産分割されてしまったのであれば、仕方がありませんから、長男から300  
万円返してもらえるか、何かいい方法がないか考えた方がいいと思います  
  
イ  次男に請求することも可能ですが、請求した場合、次男としてはそれから  
30日以内に相続放棄の手続をとれば相続放棄が認められますから、結局は次  
男からは回収できないこととなってしまうと思います  
        
        
  
ウ  次男は遺産分割協議書を作成していたとしても相続放棄をしたことにはなり  
ませんから、法定相続分に従って、150万円の返済を次男に求めることはでき  
るはずですよ  
  
エ  負債があるのに1人にだけ資産を全部集中させる内容の遺産分割は無効です  
から、次男に300万円請求することはできるはずですよ`
        },
        {
                "mondaiId": 105240060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株主代表訴訟に関する以下の文章中の空欄Ａ~Ｃに入る語句の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
違法行為があったにもかかわらず、その会社が取締役に対し、その責任の追及を  
しないとき、株主は、株主代表訴訟を提起することができる。  
代表訴訟を提起することができる株主は、Ａ以上の株式を、定款に特別  
の定めがない限り、Ｂか月前から引き続き保有している株主である。な  
お、公開会社以外の会社では、この期間の制限はない。  
株主は、まず、当該会社に対し、取締役の責任追及等の訴えを提起するよう請求  
する。  
その請求を行ったにもかかわらず、Ｃ日以内に、その会社が当該取締役  
の責任追及の訴えを行わない場合には、株主は、代表訴訟を提起することができ  
る。  
[解答群]  
  
ア  Ａ：\f株Ｂ：\u0005Ｃ：30  
  
イ  Ａ：\f株Ｂ：\u000eＣ：60  
  
ウ  Ａ：発行済み株式総数の\u0005パーセントＢ：\u000eＣ：30  
  
エ  Ａ：発行済み株式総数の\u0005パーセントＢ：\u0005Ｃ：60  
        
`
        },
        {
                "mondaiId": 105240070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
商標権の効力に関する記述として最も適切なものはどれか。  
  
ア  指定商品｢菓子｣についての登録商標｢恋人｣の商標権者は、｢菓子｣と｢パン｣は相  
互に類似する商品と見なされているとしても、他人に｢パン｣について登録商標  
｢恋人｣を使用する権利を許諾することはできない。  
  
イ  他人の商品｢おもちゃ｣に係る商標｢スター｣についての商標登録出願前から、商  
標｢スター｣を周知性を得られないまま善意で商品｢おもちゃ｣について使用してい  
た者は、たとえその商標登録出願が商標登録された後でも商標｢スター｣を商品  
｢おもちゃ｣について継続的に使用することができる。  
  
ウ  他人の著名な漫画の主人公の図柄について商標登録した場合でも、商標権者  
は、他人の承諾を受けることなく、漫画の主人公の図柄から成る登録商標をいず  
れの指定商品についても使用することができる。  
  
エ  他人の登録商標と同一であっても、自己の氏名であれば、商標として使用する  
ことができる。`
        },
        {
                "mondaiId": 105240080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許権の共有に関し、法律上<u>最も不適切なものはどれか</u>。  
  
ア  特許権の共有者は、契約で別段の定めをしていない場合には他の共有者と共に  
でなくとも、単独で、特許発明の実施をすることができる。  
  
イ  特許権の共有者は、他の���有者と共にでなくとも、単独で、共有持分を放棄す  
ることができる。  
  
ウ  特許権の共有者は、他の共有者と共にで��くとも、他の共有者の同意を得たう  
えで単独で、共有持分を譲渡することができる。  
  
エ  特許権の共有者は、他の共有者と共にでなくとも、単独で、特許を無効とする  
審決に対する審決取消訴訟を提起することができる。  
        
`
        },
        {
                "mondaiId": 105240090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
以下の記述は、ある条約に関するものである。この条約の名称として最も適切な  
ものを下記の解答群から選べ。  
この条約について日本は1978年\u0006月1日に加入書を寄託しており、同年10月\f  
日付で日本について効力を発生した。  
この条約に基づく国際出願とは、ひとつの出願書類をその規則に従って提出する  
ことにより、加盟国であるすべての国I2011年3月1日現在144カ国Lに同時に出  
願したことと同じ効果が得られる。しかし、出願人が特許を取得したい国を指定国  
として願書に記載をするのが通例である。  
ある発明に対して特許権を付与するか否かの判断は、各国がそれぞれの特許法に  
基づいて行う。従って、特定の国で特許を取得するためには、その国に対して直  
接、特許出願を行うことが必要となる。  
経済と技術のボーダレス化を背景として、多くの国で製品を販売したい、模倣品  
から自社製品を保護したい、等の理由から特許を取得したい国の数は増加する傾向  
にある。特許を取得したいすべての国に対して個々に特許出願を行うことはとても  
煩雑であり、更に先願主義のもと、特許出願は一日でも早く行うことが重要であ  
る。たとえ、出願日を早く確保しようとしても、すべての国に対して同日に、それ  
ぞれ異なった言語を用いて異なった出願書類を提出することは、ほぼ不可能といえ  
る。  
この条約による国際出願では、国際的に統一された出願書類を加盟国である自国  
の特許庁に対して\f通だけ提出すれば、その国際出願はすべての加盟国に対して  
｢国内出願｣を出願したことと同じ扱いを得ることができる。しかしながら、この条  
約では、あくまでも出願手続きを簡素化したものに過ぎず、特許要件の審査は、各  
国毎の特許法により行われるものであり、いわゆる｢世界特許｣ではないことに注意  
を要する。  
[解答群]  
  
ア  国連ウィーン条約イ特許協力条約ウパリ条約  
  
エ  ヘーグ条約  
        
`
        },
        {
                "mondaiId": 105240100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
不正競争防止法の不正競争に該当する商品等表示I人の業務に係る氏名、商号、  
商標、標章、商品の容器若しくは包装その他の商品又は営業を表示するものをい  
う。以下同じ。Lに関する記述として最も適切なものはどれか。  
  
ア  外国でのみ著名な商品等表示の使用を第三者に許諾している甲は、その商品等  
表示と類似の商品等表示である標章を付した商品を譲渡して、当該第三者の商品  
と混同を生じさせる行為を行った乙に対して、その標章を付した商品の引き渡し  
及び損害賠償を請求することができる。  
  
イ  関西地方の需要者の間に広く認識されている商品等表示の使用を第三者に許諾  
している甲は、その商品等表示と同一の商品等表示である標章を付した商品を譲  
渡して、当該第三者の商品と混同を生じるおそれがある行為を行った乙に対し  
て、その標章を付した商品の廃棄及び標章を製造する装置の除却を請求すること  
ができる。  
  
ウ  関東地方の需要者の間に広く認識されている商品等表示を使用している甲は、  
その商品等表示と非類似の商品等表示である看板を使用した商品を譲渡した乙に  
対して、その看板の廃棄と謝罪広告を請求することができる。  
  
エ  世界中の需要者に広く認識されている商品等表示を使用している甲は、その商  
品等表示と非類似の商品等表示である看板を使用して営業を行った乙に対して、  
不当利得の返還及びその看板の廃棄を請求することができる。  
        
`
        },
        {
                "mondaiId": 105240110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
商標の使用に関する記述として最も適切なものはどれか。  
  
ア  家庭用テレビゲーム機用プログラムを記憶させたCD-ROMに標章を付して販  
売する行為は、役務についての商標の使用にあたる。  
  
イ  商標は、業として商品を生産し、証明し、又は譲渡する者がその商品について  
使用するものであるため、商品の生産準備中に、使用予定の商標を雑誌などに広  
告することは商標の使用にあたらない。  
  
ウ  電気通信回線を通じて提供されるダウンロード可能な｢電子出版物｣のデータに  
標章を付して販売する行為は、商品についての商標の使用にあたる。  
  
エ  標章を付した商品をわが国から輸出する行為は、その商品は輸出先国での販売  
が予定されているので、わが国での商標の使用にあたらない。  
        
`
        },
        {
                "mondaiId": 105240121,
                "name": "第12問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業における経営の承継の円滑化に関する法律I平成20年法律第33号Lにお  
いては、中小企業における経営の承継の円滑化を図るため、遺留分に関する民法の  
特例I以下｢遺留分特例｣といい、遺留分特例の適用対象となる事業者を｢特例中小企  
業者｣という。Lを定めている。  
これに関連した下記の設問に答えよ。  
（設問1）L  
民法の遺留分に関する原則の記述として最も適切なものはどれか。  
  
ア  遺贈及び生前贈与の減殺を請求することができるのは、遺留分権利者本人に  
限られ、その承継人は請求することができない。  
  
イ  遺留分減殺請求権の消滅時効期間は、遺留分権利者が、相続の開始及び減殺  
すべき贈与又は遺贈があったことを知った時から1年間である。  
  
ウ  兄弟姉妹のみが相続人である場合の遺留分の割合は、被相続人の財産の\u0005分  
の\fである。  
  
エ  相続の開始前における遺留分の放棄は、推定相続人の単独の意思表示によ  
り、その効力を生ずる。  
        
`
        },
        {
                "mondaiId": 105240122,
                "name": "第12問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
L  
遺留分特例に関連する説明として最も適切なものはどれか。  
  
ア  遺留分算定において、後継者が生前贈与を受けた自社株式の価額を固定し、  
又は遺留分の算定から除外する旨の推定相続人間の合意は、その合意が特例中  
小企業者の経営の承継の円滑化を図るためにされたこと等についての経済産業  
大臣の確認を受けた者の申立てにより、地方裁判所の許可を得たときに限り、  
その効力を生ずる。  
  
イ  遺留分特例により、特例中小企業者の先代経営者から後継者に自社株式を生  
前贈与した場合、後継者を含む先代経営者の推定相続人は、当該生前贈与後に  
成立した推定相続人全員の合意をもって、書面により、後継者が上記生前贈与  
により取得した自社株式について、遺留分を算定するための基礎財産の価額に  
算入すべき価額を当該生前贈与の時における価額とすることができる。  
  
ウ  遺留分特例により、特例中小企業者の先代経営者から後継者に自社株式を生  
前贈与した場合、後継者を含む先代経営者の推定相続人は、その全員の合意を  
もって、書面により、後継者が上記生前贈与により取得した自社株式の価額に  
ついて、遺留分を算定するための基礎財産の価額に算入しないことができる。  
  
エ  特例中小企業者において、先代経営者から後継者に自社株式を生前贈与した  
場合、先代経営者の死亡後に、後継者と非後継者との間の遺産分割協議によ  
り、生前贈与株式についての非後継者の遺留分を放棄する合意をしたとして  
も、家庭裁判所の許可がなければ遺留分放棄の効力を生じない。  
        
`
        },
        {
                "mondaiId": 105240131,
                "name": "第13問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
特許権を取得した会社の専務取締役甲氏と、中小企業診断士であるあなたとの間  
の、特許権のライセンスに関する以下の会話を読んで、下記の設問に答えよ。  
甲氏：｢知財担当の主任から聞きましたが、平成24年4月から特許法の改正法が施  
行されて、特許権のライセンスについて登録制度が変更されたそうですね。｣  
あなた：｢はい。特許権のＡの設定を受けたライセンシーが、特許権を譲  
り受けた第三者に自らの権利を対抗するため、これまでは特許庁にその権  
利の登録をする必要がありました。今後、ライセンシーは登録なしで  
Ａを特許権の譲受人に対して当然に対抗できることになります。｣  
甲氏：｢当社はライセンシー側でもありますが、登録制度を利用していませんで  
した。｣  
あなた：｢また、破産手続のことを考えると、破産管財人は破産手続開始時点で  
Ｂである破産者・第三者間の双務契約を解除できるのが原則です  
が、ライセンス契約においては、たとえＣが破産しても  
Ａについて対抗要件が備わっていれば、破産管財人はＡ  
の設定契約を解除できません。今回の特許法改正により、特許権者から  
Ａの設定を受けたライセンシーはその後特許権者が破産しても、  
破産管財人に当然に対抗できます。ライセンスを受けた技術を安心して利  
用し続けられますし、特許権のライセンスビジネスでの活用の幅も広がり  
ます。｣  
甲氏：｢だけど、せっかく第三者が特許権を買い取っても、特許庁の登録を見て  
も分からないライセンシーへのライセンスを打ち切れないわけですよね。  
それって特許権を活用したファイナンスとかM&Aの妨げになりません  
か。｣  
あなた：｢企業買収の際には、買収企業側が被買収企業側にデュー・ディリジェン  
スを実施し、被買収企業側からの?開示したライセンシーがすべてであり、  
開示されないライセンシーは存在しない?というＤ条項をおけば、  
買収側としては一応のリスク回避が可能です。ただ、おっしゃるとおり、  
        
        
隠れたライセンシーの存在やライセンス日付のバックデートの可能性が、  
特許権を活用した資金調達のマイナス要因になりかねないという指摘はあ  
ります。｣  
甲氏：｢それに、特許権の譲渡後に譲渡人が新たなライセンシーとライセンス契  
約を結んでしまったりした場合、ライセンシーはＡを特許権の譲  
受人に主張できますか。｣  
あなた：｢特許法の条文上は、ライセンシーはＥ後に特許権を取得した第  
三者にその権利の効力を主張できますから、Ｆが特許権の移転登  
録より先であれば、Ａの方が優先します。｣  
（設問1）L  
会話中の空欄Ａ~Ｃに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ａ：専用実施権Ｂ：双方未履行Ｃ：ライセンシー  
  
イ  Ａ：専用実施権Ｂ：双方履行済みＣ：ライセンサー  
  
ウ  Ａ：通常使用権Ｂ：一方履行済みＣ：ライセンシー  
  
エ  Ａ：通常実施権Ｂ：双方未履行Ｃ：ライセンサー`
        },
        {
                "mondaiId": 105240132,
                "name": "第13問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
L  
会話中の空欄Ｄ?Ｆに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ｄ：瑕疵担保責任Ｅ：特許発明の実施  
Ｆ：ライセンス契約締結  
  
イ  Ｄ：実績補償Ｅ：特許発明の実施  
Ｆ：ライセンス対象技術の実際の利用  
  
ウ  Ｄ：損失補償Ｅ：通常実施権の発生  
Ｆ：ライセンス対象技術の実際の利用  
  
エ  Ｄ：表明・保証Ｅ：通常実施権の発生  
Ｆ：ライセンス契約締結  
        
`
        },
        {
                "mondaiId": 105240140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
A社が新製品の開発に利用する予定のオープンソースソフトウェアのライセン  
ス規定として、下記の条項があった。この規定に表題を付記し、内容を説明すると  
した場合、最も適切なものを下記の解答群から選べ。  
YoureceivetheProgramwithacovenantfromeachauthorandconveyorofthe  
Program，andofanymaterial，conveyedunderthisLicense，onwhichtheProgram  
isbased，thatthecovenantingpartywillnotassert(orcauseotherstoassert)any  
oftheparty?sessentialpatentclaimsinthematerialthatthepartyconveyed，  
againstyou，arisingfromyourexerciseofrightsunderthisLicense.Ifyouconveya  
coveredwork，yousimilarlycovenanttoallrecipients，includingrecipientsofworks  
basedonthecoveredwork，nottoassertanyofyouressentialpatentclaimsinthe  
coveredwork.  
＊語句の説明  
covenant：約款convey：伝達する  
patentclaim：特許請求の範囲、特許クレームrecipient：受領者  
[解答群]  
  
ア  I非係争義務L  
ライセンス対象物に対して特許権を行使しないライセンシーの義務を規定し  
ている。  
  
イ  I非独占的ライセンス許諾L  
ライセンシーに対する特許権の非独占的ライセンスを規定している。  
  
ウ  I不争義務L  
ライセンス対象物に関する特許権の有効性を争わないライセンシーの義務を  
規定している。  
  
エ  I保証の排除L 第三者からの特許権に基づく訴訟がライセンシーに対して提起されない保証  
を排除している。  
        
`
        },
        {
                "mondaiId": 105240150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
フランチャイズ契約に関する記述として、最も適切なものはどれか。  
  
ア  中小小売商業振興法上の特定連鎖化事業に該当する事業を行うフランチャイズ  
本部には、その本部とフランチャイズ契約を締結しようとする加盟希望者に対  
し、あらかじめ、加盟に際し徴収する金銭、加盟者に使用させる商標・商号その  
他フランチャイズ契約の概要等を記載した書面を交付し、その記載事項について  
説明する義務はない。  
  
イ  フランチャイズ契約解除後、フランチャイズ本部からフランチャイズ・チェー  
ン名称の使用を継続している旧加盟店に対して名称使用の差止請求をするには、  
その名称の商標登録が必要である。  
  
ウ  フランチャイズ契約において、契約終了後も、フランチャイズ本部が加盟店に  
対して、特定地域で成立している商権の維持、同本部が加盟店に供与したノウハ  
  
ウ  の保護等に必要な範囲を超えるような地域、期間又は内容の競業禁止義務を課  
す規定をおくことは、優越的地位の濫用に該当する。  
  
エ  フランチャイズ契約における加盟希望者が小売店である場合でも、小売店は消  
費者とみなされるから、消費者契約法の適用がある。  
        
`
        },
        {
                "mondaiId": 105240161,
                "name": "第16問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
電子部品メーカーX株式会社I以下｢X社｣という。Lの資金調達に関する、X社社  
長甲氏と中小企業診断士であるあなたとの間の以下の会話を読んで、下記の設問に  
答えよ。なお、あなたの発言の下線部①~④のうち、  
つには誤りが含まれてい  
る。  
甲氏：｢この間、メインバンクから、大口の取引先への売掛金を、現在掛売りし  
ている分だけでなく、今後発生する取引の分もまとめて担保に差し出せと  
言われたんだけど、そんなことできるの。｣  
あなた：｢集合債権の譲渡担保という方法があります。担保提供を受けた銀行は、  
Ａ登記に加えて、登記事項証明書交付によるＢがされれ  
ば、他の債権者に対しても売掛先に対しても、将来発生する売掛債権も含  
めて、担保権者として優先弁済を受ける権利を主張できます。  
①  
つまり、担  
保権者は、融資先の期限の利益が喪失した時点で少なくとも具体的に発生  
している売掛債権については、融資先に担保権の実行通知を出して売掛債  
権の取得や処分ができますし、弁済期の到来した売掛先には直接取立てが  
できるので、一般債権者に優先して債権回収ができます。｣  
甲氏：｢へえ、そうなんだ。あと、同業のY社は代理店になっているメーカーの  
意向で、電子手形というのを始めたらしいんだけど、これって要するに紙  
の手形が要らなくなるってやつでしょ。｣  
あなた：｢そうですね。紙の手形の代わりに、Ｃの電子記録で債権の発生  
や譲渡が管理されるので、債権管理のコスト削減にもつながりますし、コ  
ンピュータのセキュリティを確保しておけば、紙の手形のような証券の紛  
失・盗難や偽造のリスクもありません。  
②  
債務者が電子記録名義人に支払い  
さえすれば重大な過失がない限り免責されることや、金融機関に持ち込ん  
で割引を受けるときに債権金額の分割ができないことは、紙の手形と同様  
です。｣  
甲氏：｢資金調達の手段が便利になったのはいいけど、作った製品が売れないこ  
とには借金を返すめども立たん。わが社はガラケーI※ガラパゴス携帯電  
        
        
話L全盛時代に専用設備の投資にシフトし過ぎたから、Y社のようにスマ  
ホI※スマートフォンLのタッチパネル製造用に機械を更新する資金的な余  
裕もない。このままだと資金ショートで即アウトになりかねないから、何  
とか会社を生き残らせるために、民事再生とかも考えないと。｣  
あなた：｢そうですね。  
③  
民事再生であれば、再生手続開始後も会社の業務遂行権や  
財産の管理処分権は維持されますから、経営者自身が企業の再建を進めて  
いけるのが原則です。裁判所によって選任された監督委員の同意が必要な  
行為もありますけどね。あと、  
④  
民事再生手続が開始されれば、債権譲渡担  
保のような担保権についても、再生手続の中に組み込まれ、担保権者は届  
出をして再生手続に参加しない限り、担保権を実行することができませ  
ん。｣  
（��問\fL  
会話中の空欄Ａ~Ｃに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ａ：債権譲渡Ｂ：第三債務者の承諾Ｃ：手形交換所  
  
イ  Ａ：債権譲渡Ｂ：第三債務者への通知Ｃ：電子債権記録機関  
  
ウ  Ａ：債権譲渡Ｂ：第三債務者への通知Ｃ：法務局  
  
エ  Ａ：動産譲渡Ｂ：引渡しＣ：電子債権記録機関`
        },
        {
                "mondaiId": 105240162,
                "name": "第16問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
L  
会話中のあなたの発言の下線部①~④のうち、正しい発言の組み合わせとして  
最も適切なものはどれか。  
  
ア  下線部①と下線部②  
  
イ  下線部①と下線部③  
  
ウ  下線部②と下線部④  
  
エ  下線部③と下線部④  
        
`
        },
        {
                "mondaiId": 105240171,
                "name": "第17問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
株式上場にあたり証券取引所が審査を行う基準は形式基準と実質基準の  
つから  
構成される。形式基準は、上場を申請するにあたって最低限クリアしなければなら  
ない一定の数値や事実を要件として設定されている。これに対して実質基準は、規  
則上は定性的な項目を示し、具体的な考え方を｢手引き｣や｢ガイドライン｣、｢Q&  
A｣の形で公表している。  
実質基準に関連する下記の設問に答えよ。  
（設問1）L  
東京証券取引所の上場審査において、実質基準の｢企業経営の健全性｣の項目で  
は、新規上場申請者の企業グループとその関連当事者との間に取引が発生してい  
る場合に、取引の合理性、取引条件の妥当性、取引の開示の適正性等を確認する  
としている。  
新規上場申請会社I以下｢会社｣という。Lと関連当事者との間で行われた取引に  
関する審査上の判断として、最も適切なものはどれか。  
  
ア  会社が、会社の役員I関連当事者Lに対し報酬の支払いを行っていたとして  
も、その報酬について財務諸表上で関連当事者との取引として開示を行わない  
ことは問題はない。  
  
イ  会社が、会社の役員I関連当事者Lの所有する不動産を賃借しているが、直接  
の契約の相手方は外部の仲介不動産業者であるため、財務諸表上で関連当事者  
との取引として開示を行わないことは問題はない。  
  
ウ  会社が、会社の役員を退職したオーナーI関連当事者Lを顧問に招聘し顧問料  
を支払う場合は、期待する役割やその達成状況、顧問料の算定根拠について確  
認できなくても問題はない。  
  
エ  会社が所有するビルの空きスペースを、会社の役員I関連当事者Lが個人事業  
として営む飲食店に無償貸与することは、空きスペースでもあり問題はない。  
        
`
        },
        {
                "mondaiId": 105240172,
                "name": "第17問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
L  
JASDAQグロースの上場審査において下記の??書きの事項を確かめるのは、  
JASDAQにおける有価証券上場規程第10条第  
項に掲げられている実質基準の  
主にどの項目に該当するか。最も適切なものを下記の解答群から選べ。  
?競争優位性及び事業環境等の外部環境や内部環境の適切な分析を踏まえた、  
客観性の認められる事業計画I中長期事業計画等Lを有しているか。?  
[解答群]  
  
ア  企業行動の信頼性  
  
イ  企業の成長可能性  
  
ウ  企業の存続性  
  
エ  健全な企業統治及び有効な内部管理体制の確立`
        },
        {
                "mondaiId": 105240180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
会社法では、機関の設計が柔軟化され監査役を設置しない株式会社も認められ  
る。監査役の設置に関連した説明として最も適切なものはどれか。  
  
ア  株式会社が委員会設置会社の場合は、監査役を設置することはできない。  
  
イ  株式会社が、公開会社でも会計監査人設置会社でもない場合は、監査役を設置  
することはできない。  
  
ウ  株式会社が、大会社でも委員会設置会社でもない場合は、監査役の設置は任意  
となる。  
  
エ  株式会社が、大会社でも公開会社でもない場合は、監査役の設置は任意とな  
る。  
        
`
        },
        {
                "mondaiId": 105240191,
                "name": "第19問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
新たな取引先と商品の売買取引を開始することに関連した下記の設問に答えよ。  
（設問1）L  
新たに取引を開始するにあたり、商業登記簿謄本I登記事項証明書Lで取引先の  
内容を把握することが重要である。これによって把握できるものに関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  会社の資本金の額を閲覧し、資本金の大きさを確かめる。  
  
イ  会社の本店及び支店の所在場所を閲覧し、実際の取引先の住所と一致してい  
るかを確かめる。  
  
ウ  会社の目的を閲覧し、実際の取引を行う事業が含まれているかを確かめる。  
  
エ  会社の役員を閲覧し、代表取締役の氏名および学歴を確かめる。`
        },
        {
                "mondaiId": 105240192,
                "name": "第19問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
L  
取引先I買主Lと新規に取引を開始するに先立ち、債権の保全・回収のための特  
約を定めた取引基本契約書を締結することが望まれる。この契約書の特約条項の  
うち、｢期限の利益の喪失｣条項を盛り込むことによって期待される効果に関する  
記述として、最も適切なものはどれか。  
  
ア  取引先が代金を完済するまで納入品の所有権を売主のものとし、いつでも納  
入品の返還を要求できる。  
  
イ  取引先に信用不安や経営危機などの一定の事由が発生した場合に、一方的に  
取引を終了させることができる。  
  
ウ  取引先に代金の支払遅延などの一定の事由が発生した場合に、支払期日前で  
も支払期日の到来していないすべての売掛金について直ちに支払いを請求する  
ことができる。  
  
エ  取引先の債権回収に不安が生じた場合に、納入品の引き渡し数量を制限した  
りストップすることができる。  
        
`
        },
        {
                "mondaiId": 105240200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
株式会社が作成する計算書類及び事業報告並びにこれらの附属明細書の取扱いに  
関する記述として、<u>最も不適切なものはどれか</u>。  
なお、本問の前提となる株式会社は、取締役会および監査役を置くが、会計参  
与、会計監査人は設置していない。  
  
ア  株式会社は、計算書類を作成した時から10年間、当該計算書類とその附属明  
細書を保存しなければならない。  
  
イ  計算書類及び事業報告については監査役の監査を受けなければならないが、附  
属明細書は監査役監査の対象とはならない。  
  
ウ  事業報告は、株式会社の状況に関する重要な事項を記載し、定時株主総会の日  
の  
週間前の日から5年間その本店に備え置かなければならない。  
  
エ  取締役は、計算書類及び事業報告を定時株主総会に提出し、計算書類について  
は承認を受けなければならないが、事業報告については内容の報告で足りる。  
`
        },
        {
                "mondaiId": 106240010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータには様々な記憶装置が使用されている。そのうち、半導体を利用し  
た記憶装置は処理速度や信頼性に影響を与えるなど、重要な役割を担っている。用  
途に適した半導体の記憶装置が装備されたコンピュータを選択できるように、その  
特性を把握しておくことが必要である。  
半導体を利用した記憶装置の特性に関する記述として最も適切なものはどれか。  
  
ア  RISC型プロセッサの主記憶装置はSRAMを使用し、CISC型プロセッサでは  
DDR-SDRAMを使用しているので、RAMを増設する場合はメモリの種類を確  
認する必要がある。  
  
イ  主記憶装置として利用するRAMにはメモリインタリーブに対応したものと対  
応していないものがあるので、RAMを増設する場合はこの点を確認する必要が  
ある。  
  
ウ  信頼性が高いコンピュータが必要な場合は、ECCによる誤り訂正機能がつい  
たRAMが装備されたコンピュータを使用することが望ましい。  
  
エ  マスクROMは電源を切っても記憶内容が保持され、また、内容の消去や書き  
込みが可能なので、バージョンアップが必要なBIOSの記憶に向いている。  
        
`
        },
        {
                "mondaiId": 106240020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、多様なコンピュータが登場しつつあるが、その基本的動作原理は同じであ  
る。それを把握しておくことで、新たな技術の理解も早くできる。下記は、コンピ  
ュータの構成要素とそれらによってコンピュータの基本機能が実現される過程を記  
述した文章である。  
以下の文中の空欄A~Dに入る言葉の組み合わせとして最も適切なものを下記  
の解答群から選べ。  

1．データおよび処理命令が主記憶装置に記憶されている。  
        
．Ａの指示で、主記憶装置に記憶されたデータおよび処理命令は、  
Ｂに転送される。  
\u0005．Ｂでは、処理命令に従ってデータを処理し、Ａの指示でその  
演算結果を転送させてＣに記憶させる。  
\u000e．Ｃに記憶された演算結果は、Ａの指示でＤに転送さ  
れて出力される。  
[解答群]  
  
ア  Ａ：演算装置Ｂ：制御装置Ｃ：主記憶装置Ｄ：出力装置  
  
イ  Ａ：演算装置Ｂ：制御装置Ｃ：補助記憶装置Ｄ：主記憶装置  
  
ウ  Ａ：制御装置Ｂ：演算装置Ｃ：主記憶装置Ｄ：出力装置  
  
エ  Ａ：制御装置Ｂ：演算装置Ｃ：補助記憶装置Ｄ：主記憶装置  
        
`
        },
        {
                "mondaiId": 106240030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータは多様な業務に利用される。コンピュータの処理能力は様々な要因  
に左右され、処理能力に影響を与える特性や、それを評価する指標が複数存在す  
る。適用業務の処理に十分な能力のコンピュータを選択するためには、これらの内  
容を適切に理解することが必要である。  
以下の記述の中で最も適切なものはどれか。  
  
ア  コンピュータのCPUと主記憶装置間の転送速度を表す内部クロッ��周波数  
は、値が大きいほど転送速度は速くなる。  
  
イ  整数演算の回数を表すFLOPSは、科学技術計算では処理速度の参考にはでき  
ない。  
  
ウ  ひとつの命令を実行するためにクロック周期が何サイクル必要かを表すCPI  
は、値が小さいほど演算処理速度は速い。  
  
エ  プログラム中の命令のステップ数を\f万ステップ単位で表したMIPSは、値  
が小さいほどコンパクトなプログラムであり、処理速度も速い。  
        
`
        },
        {
                "mondaiId": 106240040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ソフトウェアのバグやセキュリティ上の不具合の修正が行われた場合、適切に対  
応することが必要である。  
ソフトウェアの修正が行われた場合の対応に関する、以下の文中の空欄A~C  
に入る語句の組み合わせとして最も適切なものを下記の解答群から選べ。  
コンピュータ上では各種業務について目的別に利用するＡソフトウェア  
と、コンピュータの資源を効率よく利用するためのＢソフトウェアがあ  
る。これらのソフトウェアはいずれも随時改良が行われるが、小さな不具合の修正  
を行う場合は修正部分だけを抜き出したＣをユーザに配布することが多  
く、この修正部分を入れ替えればよい。  
[解答群]  
  
ア  Ａ：アプリケーションＢ：システムＣ：パッチファイル  
  
イ  Ａ：アプリケーションＢ：パッケージＣ：シェル  
  
ウ  Ａ：システムＢ：パッケージＣ：カーネル  
  
エ  Ａ：パッケージＢ：アプリケーションＣ：パッチファイル  
        
`
        },
        {
                "mondaiId": 106240050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務の処理にソフトウェアを使用する場合、中小企業診断士が、そのソフトウェアの利用を支援するだけでなく、状況によってはソフトウェアそのものの開発にか  
かわったりする場面もある。ソフトウェアの機能や開発に関する記述として最も適  
切なものはどれか。  
  
ア  OSとは、BIOS?BasicInput/OutputSystem?に先立って起動し、ディスプレイやキーボードが使えるようにするソフトウェアである。  
  
イ  コンパイラとは、高級言語で書かれたプログラムをコンピュータが実行可能な  
機械語に翻訳するソフトウェアである。  
  
ウ  ミドルウェアとは、FORTRANなどの第一世代高級言語とCやJavaなどの第二世代の間に登場したプログラミング言語である。  
  
エ  リンカとは、ネットワークを機能させるソフトウェアである。`
        },
        {
                "mondaiId": 106240060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
各種業務において様々なソフトウェアを利用しているが、それぞれのソフトウェ  
  
ア  では目的に応じた演算処理が行われている。コンピュータの演算処理では計算誤  
差が発生する場合のあることが知られているので、計算誤差に対して適切な対応が  
必要である。  
以下の計算誤差に関する記述として最も適切なものはどれか。  
  
ア  C言語においては、整数型の変数を使用する場合、その扱える範囲内の加減算  
でも桁落ち誤差が発生する。  
  
イ  どのようなソフトウェアを利用しても、金額に関する計算では浮動小数点演算  
の仕組みを利用しないので計算誤差は生じない。  
  
ウ  表計算ソフトの演算処理では、丸め誤差が発生する。  
  
エ  リレーショナルデータベースの処理ではデータの格納および格納されたデータ  
の検索だけを行うので計算誤差は生じない。  
        
`
        },
        {
                "mondaiId": 106240070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
スマートフォンなどの多様な携帯端末機器の発達は、顧客にとっても、サービス  
を提供する事業者にとっても、多様な情報を迅速かつ場所を選ばず検索、照会、処  
理、発信できるというメリットを享受可能にしている。  
ある中小製造企業は、製品を自社ウェブサイトで広告し、自社製造工程での受注  
製品の製造プロセスおよび発送プロセスの進捗状況を、顧客に知らせることができ  
るシステムを構築したいと望んでいる。また進捗状況が許す範囲内で、製品仕様の  
変更や送り先の変更などができるシステムとしたいと望んでいる。  
このシステム構築に関する記述として最も適切なものはどれか。  
  
ア  顧客が送り先の変更を携帯端末で行えるようにするには、送り先の変更処理を  
バッチ処理方式にする必要がある。  
  
イ  顧客の携帯端末からの照会要求に対して、即座に製造プロセスの進捗状況を知  
らせるには、照会要求処理をリアルタイム処理方式にする必要がある。  
  
ウ  顧客の携帯端末で自社サーバに接続できるようにするには、VPNと呼ばれる  
ネットワークの接続形態が必要である。  
  
エ  スマートフォンから自社ウェブサイトを閲覧可能にするためには、この中小製  
造企業はそのための専用アプリケーションを開発し、顧客はそれをダウンロード  
してインストールする必要がある。  
        
`
        },
        {
                "mondaiId": 106240080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
パーソナルコンピュータ?PC?を業務に利用する場合、各々の業務の特性を考え  
たソフトウェアや周辺機器を適切に選ぶ必要がある。  
以下の文中の空欄A~Dに入る語句の組み合わせとして最も適切なものを下記  
の解答群から選べ。  
業務においてPCを利用する場合は対話型処理で利用する場面が多いが、月末な  
どにまとめて請求書作成などのＡ処理を行うこともある。作業指示を出し  
た後、この処理画面に応答が返るまでのＢが長い場合は、作業の進捗状況  
の把握ができない。このような場合はプログレスバーなどを表示させて進捗状況の  
把握が行えるようなソフトウェアを選択する。  
また、請求書作成処理の指示をPCに与え、PCが作業を終了したという表示が  
得られるまでの経過時間はＣと呼ばれ、この時間の短いことが望ましい。  
PCが作業終了状態となっても、プリンタが印字作業を続けていることがある  
が、これはプリンタがＤ機能を備えている場合にも起こる。この機能を利  
用する場合は印字の作業量に適した容量のバッファが備わったプリンタを選択する  
必要がある。  
[解答群]  
  
ア  Ａ：オフラインＢ：レスポンスタイム  
Ｃ：スループットＤ：スレッド  
  
イ  Ａ：オンラインＢ：ターンアラウンドタイム  
Ｃ：オーバレイＤ：スループット  
  
ウ  Ａ：トランザクションＢ：スループット  
Ｃ：レスポンスタイムＤ：キュー  
  
エ  Ａ：バッチＢ：レスポンスタイム  
Ｃ：ターンアラウンドタイムＤ：スプール  
        
`
        },
        {
                "mondaiId": 106240090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
業務におけるデータベースの処理ではネットワークにつながる複数の端末から、  
あるデータに対して同時に複数の処理要求が発生し、本来の処理が正しく行われな  
い場合がある。これを防ぐために排他制御あるいは同時実行制御と呼ばれる方法が  
利用される。  
これに関する以下の文中の空欄A~Dに入る語句の組み合わせとして最も適切  
なものを下記の解答群から選べ。  
①データファイル内の処理対象とするデータにＡをかけ、それが解除さ  
れるまで他の処理が行われないようにする方法がある。この方法では、  
Ｂが発生する可能性があり、これを低減させる対応が必要である。  
②データファイル内のデータを読み書きするＣを記録し、別の処理でデ  
ータを読み書きする際、Ｃを随時監視しあいながら処理を行う方法があ  
る。この方法では、処理の競合が多い場合、処理の取り消しが多くなり実用性が  
低下する。  
③データファイルから処理したいデータをＤに読み込み、そこで処理を  
実行する方法がある。この方法��は、処理した結果を書き戻す際に、当該データ  
が他の処理で書き換えられていないかを時刻でチェックする。  
[解答群]  
  
ア  Ａ：デッドロックＢ：セマフォＣ：順番Ｄ：テーブル  
  
イ  Ａ：ロックＢ：セマフォＣ：時刻Ｄ：テーブル  
  
ウ  Ａ：ロックＢ：セマフォＣ：順番Ｄ：キャッシュ領域  
  
エ  Ａ：ロックＢ：デッドロックＣ：時刻Ｄ：キャッシュ領域  
        
`
        },
        {
                "mondaiId": 106240100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
業務で利用するデータのコード化に関する以下の記述の空欄A~Dに入る語句  
の組み合わせとして、最も適切なものを下記の解答群から選べ。  
コード化には、例えば次のような方法がある。  
①店舗を利用している顧客にコード番号を付与し、販売動向を把握したい。来店  
した顧客に\fから順番に番号を割り振る方法を使用すれば、Ａを利用で  
きる。  
②住んでいる場所を特定し管理したい場合は顧客番号に加え、コード内に総務省  
などが公開している市区町村コードを組み入れて利用する。このようなコード化  
の方法はＢという。  
③コード番号に住んでいる場所を区別する市区町村コードを組み入れ、この市区  
町村コード別に\fから始まる顧客番号を与える方法もあり、この方法は  
Ｃと呼ばれる。  
なお、コード化においては、同じ顧客を重複登録してしまう可能性があり、デー  
タ管理上Ｄの問題に配慮しなければならない。  
[解答群]  
  
ア  Ａ：識別機能Ｂ：桁別分類法Ｃ：区分分類法Ｄ：一意性  
  
イ  Ａ：識別機能Ｂ：連番法Ｃ：合成法Ｄ：冗長性  
  
ウ  Ａ：分類機能Ｂ：合成法Ｃ：区分分類法Ｄ：冗長性  
  
エ  Ａ：分類機能Ｂ：表意法Ｃ：連番法Ｄ：一意性  
        
`
        },
        {
                "mondaiId": 106240110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットへの接続やLANの構成に際して必要となる知識に関する記述と  
して最も適切なものはどれか。  
  
ア  DNSとは、グローバルIPアドレスをプライベートIPアドレスに変換するサ  
ーバである。  
  
イ  MACアドレスとは、ネットワーク機器のLANカードなどに付けられたアド  
レスである。  
  
ウ  プライベートIPアドレスとは、個人に割り当てられるグローバルIPアドレス  
である。  
  
エ  ルーティングとは、ネットワーク上で円滑に情報を送信できるように、ゲートウェイやサブネットマスクのアドレスを指定することである。  
        
`
        },
        {
                "mondaiId": 106240120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
物品の販売サイトをインターネット上で運営する場合、利用者の利便性を考慮し  
た販売サイト構築が必要である。そのためには、運営するサーバの特性と顧客が所  
有するPC上での商品購入のための操作のかかわりを把握する必要がある。  
販売サイトを運営するためのサーバや顧客が利用するPCに関する記述として、  
最も適切なものはどれか。  
  
ア  顧客PCへデータを渡して処理を行う仕組みではなく、販売側のサーバで必要  
な処理を行った結果を、顧客PCへと返す仕組みで販売サイトを運営すれば、販  
売側のデータの外部への流出は発生しない。  
  
イ  顧客の商品購入の操作に対して、期待した時間内に応答が得られない場合は、  
顧客のPCを演算処理能力の高い機器に替える必要がある。  
  
ウ  販売側のウェブサーバで顧客からの商品購入依頼について処理した結果を、顧  
客側PCで表示させるためには、顧客PCのウェブブラウザには販売側のウェブ  
サーバから提供されるバーチャルマシン機能をあらかじめ組み込んでおく必要が  
ある。  
  
エ  販売側のサーバで非同期通信の仕組みを取り入れれば、顧客のPC画面上で商  
品画像の上にマウスカーソルを移動させるとその商品説明が表示されるなどの、  
動的な表示を行うことができる。  
        
`
        },
        {
                "mondaiId": 106240130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
企業がITガバナンスを強化するためには、まず情報システム戦略を確立し、こ  
れに基づいて以下の2つの構成要素を順次実現していくことが必要である。その順  
序として最も適切なものを下記の解答群から選べ。  
ａシステム運営?セキュリティ管理・リスク管理・サービスレベル管理など?  
ｂ説明責任?システム監査・ベンチマーク・投資効果など?  
ｃ組織運営?予算管理・投資管理・ベンダー管理・契約管理など?  
ｄリレーションシップ管理?教育・提案・情報発信など?  
[解答群]  
  
ア  ａ或ｂ或ｃ或ｄ  
  
イ  ａ或ｃ或ｂ或ｄ  
  
ウ  ｂ或ａ或ｄ或ｃ  
  
エ  ｂ或ｃ或ａ或ｄ`
        },
        {
                "mondaiId": 106240140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
EDIなどで用いる標準コードに関する説明として最も適切なものはどれか。  
  
ア  EDI等に利用される事業所コードGLN?GlobalLocationNumber?は、主に製  
造業で利用される。  
  
イ  EDI等に利用される標準企業コードの最後の\f桁は、チェックディジットで  
ある。  
  
ウ  標準企業コードの最初にを付加すると、GLN?GlobalLocationNumber?とし  
て利用できる。  
  
エ  標準企業コードは、国際規格UN/EDIFACTのコードの一部として利用でき  
る。  
        
`
        },
        {
                "mondaiId": 106240150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネットのメディアとしての特徴は、双方向性、オープン性、即応性など  
とともにメディアミックスを可能にするところにある、と言われている。インター  
ネットや携帯端末の普及とともに、これらの特徴を用いた様々なビジネスやコミュ  
ニケーションが行われている。  
これに関連する記述として最も適切なものはどれか。  
  
ア  SNSは広く普及しているが、匿名性を排除するため、本名で登録しなければ  
利用できない。  
  
イ  インターネットとは、通信プロトコルとして、TCP/IPを使うネットワークで  
ある。  
  
ウ  スマートフォンは、既存の携帯電話の表示画面を大きくし、その大きさを統一  
したものである。  
  
エ  ブログとは、自らの日記を公開するものであるから、そのURLを他人がその  
  
ウ  ェブサイトの中で引用することは不可能な仕組みになっている。`
        },
        {
                "mondaiId": 106240160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
総務省から発表された｢ASP・SaaSの安全・信頼性に係る情報開示指針?第\f  
版?｣に基づいた｢ASP・SaaS安全・信頼性に係る情報開示認定制度｣により、中小  
企業ユーザがASP・SaaSのサービスを選択する場合の参考情報が提供されている。  
この認定制度に関する記述として最も適切なものはどれか。  
  
ア  ASP・SaaSサービスだけでなく、ASP・SaaS事業者の経営状況等も認定して  
いる。  
  
イ  ASP・SaaSサービスの安全性や信頼性に係る実施水準や状態を認定している。  
  
ウ  審査対象となる情報開示項目をすべて開示しなければ、認定を得ることはでき  
ない。  
  
エ  同一サービスであっても、安全性や信頼性に複数のサービスレベルを設定して  
いる。  
        
`
        },
        {
                "mondaiId": 106240170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある中小販売企業では、インターネットで受注を開始することにした。それに先  
立ち、下記の図を描いてインターネットによる受注システムの検討を行っている。  
この図に関する説明として最も適切なものを下記の解答群から選べ。ただし、こ  
の図は未完成である。  
[解答群]  
  
ア  業務のデータの流れと処理の関係を記述したDFDである。  
  
イ  データベースをどのように構築したら良いかを示すERDである。  
  
ウ  利用者がシステムとどのようにやり取りするかを示すユースケース図であ  
る。  
  
エ  利用者相互のコミュニケーションの関係を描いたコミュニケーション図であ  
る。  
        
`
        },
        {
                "mondaiId": 106240180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
独立行政法人情報処理推進機構ソフトウェア・エンジニアリング・センターの  
｢非機能要求グレード｣では、非機能要求を①可用性、②性能・拡張性、③運用・保  
守性、④移行性、⑤セキュリティ、⑥システム環境・エコロジーの\u000fつに大別して  
いる。  
これらに関連する記述として最も適切なものはどれか。  
  
ア  運用監視などを含む通常運用は可用性に含まれる。  
  
イ  回線冗長化などを含む耐障害性は運用・保守性に含まれる。  
  
ウ  データ暗号化などを含むアクセス・利用制限は可用性に含まれる。  
  
エ  同時アクセス数などを含む通常時の業務量は性能・拡張性に含まれる。  
        
`
        },
        {
                "mondaiId": 106240190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
SaaSの提供者?ベンダ企業?と利用者?ユーザ企業?との間でSLAを締結する場合  
に必要な作業の実施順序は以下のとおりである。空欄Ａ~Ｃと記述群の①~③の組  
み合わせとして最も適切なものを下記の解答群から選べ。  
＜作業の実施順序＞  
1．SaaS利用者側の前提条件の整理  
2．Ａ  
3．Ｂ  
4．SaaS提供者の免責範囲の定義  
5．Ｃ  
6．SaaSの提供・利用の結果に対する対応の定義  
?空欄に入る記述群?  
①SaaSの提供・利用に関する委託内容／範囲の定義  
②SaaSの提供・利用のサービスレベルの定義  
③SaaSの提供・利用の役割／責任分担の定義  
[解答群]  
  
ア  Ａ：①Ｂ：②Ｃ：③  
  
イ  Ａ：①Ｂ：③Ｃ：②  
  
ウ  Ａ：②Ｂ：①Ｃ：③  
  
エ  Ａ：②Ｂ：③Ｃ：①  
        
`
        },
        {
                "mondaiId": 106240200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ITの進展に伴い、それを有効に利用して競争優位を獲得しようとする試みは、  
もはや特別なことではない。その際に重要になることとして、適切なハードウェ  
  
ア  、ソフトウェアの開発または選択は言うに及ばず、近年、それらを使いこなす  
人々の能力の向上が叫ばれている。  
これに関連する記述として最も適切なものはどれか。  
  
ア  eラーニングは、ITスキルの習得に用いられるばかりでなく、近年では経営  
理念の浸透や環境問題への意識向上などにも用いられている。  
  
イ  現在、中学校に｢情報｣という科目が設けられ、必修となっている。従って、今  
後、新システムを導入しても、それを使いこなす新入社員の能力は問題ない。  
  
ウ  構築したシステムの評価を、従業員が行うことをEUCと呼ぶ。  
  
エ  情報リテラシーとは、コンピュータを利用した情報システムを使いこなす能  
力、つまりコンピュータリテラシーと同義である。`
        },
        {
                "mondaiId": 106240210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
近年、多くの情報漏洩被害をもたらしている｢新しいタイプの攻撃｣?Advanced  
PersistentThreats：APT?への対策としては、入口対策だけでなく、出口対策も  
重要になる。  
出口対策として最も適切なものはどれか。  
  
ア  IDS?IntrusionDetectionSystem?の導入  
  
イ  RAT?RemoteAccessTrojan/RemoteAdministrationTool?による内部proxy  
通信いわゆるCONNECT接続の検知遮断  
  
ウ  パッチファイルの適用による脆弱性対策  
  
エ  ファイアウォールによるステルス機能の導入  
        
`
        },
        {
                "mondaiId": 106240220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
インターネット利用が普及して、インターネット上で取引情報やプライバシーに  
かかわる情報を扱う場面が多くなっている。従って情報セキュリティについて、そ  
の基礎事項を把握しておくことは重要である。  
情報セキュリティにかかわる記述として最も適切なものはどれか。  
  
ア  インターネットを介して、顧客情報を収集してそれをデータベース化した場  
合、それが漏洩しないようにするにはウイルス対策を行えばよい。  
  
イ  インターネット���介して、顧客に送り先等の他に年齢、家族構成などを入力し  
てもらう場合、その用途については顧客に知らせる必要はな���。  
  
ウ  取引企業、顧客との情報のやりとりは、暗号化することが好ましいが、その場  
合に用いる公開鍵暗号方式とは、関係者の間で共通鍵を設定して、情報を暗号化  
する方式である。  
  
エ  ファイアウォールを自社コンピュータに対する不正アクセスの防止手段として  
利用する場合、どのような内容のアクセスを拒否するのかをあらかじめ設定する  
必要がある。  
        
`
        },
        {
                "mondaiId": 106240230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
ある中小製造企業は、顧客の要望に合わせて製品を設計・製造・販売している。  
今まで、受注量が少なかったことから、電話やファクシミリ等で顧客への対応をし  
ていた。近年、海外を含めて顧客からの受注が増加している。このような状況か  
ら、受発注にかかわる処理、問い合わせやクレーム処理を含めて顧客とのコミュニ  
ケーション、社内の製造指示などをシステム化することを検討している。その検討  
の中での聞き取り調査の結果、経営者や従業員は、このシステム開発の投資評価を  
はっきりさせておきたいと考えていることが分かった。  
投資評価に関する記述として最も適切なものはどれか。  
  
ア  本システムの構築には多様な案が考えられるが、それらを検討する場合に、シ  
ステム開発のプロジェクト遂行に関するリスクと、システムによってもたらされ  
るベネフィットとの  
軸の視点から、それらの案を評価するポートフォリオ分析  
が有用である。  
  
イ  本システムへの投資をTCOで評価する場合、従業員の教育などにかかわる技  
術サポートコスト、セキュリティ管理などにかかわる管理コスト、コンピュータ  
の利用にかかわるエンドユーザコストの3つの視点から行う。  
  
ウ  本システムを評価する場合、顧客がどう評価するかが重要であり、このような  
視点から、顧客ならば提案されたシステムをいくらなら購入するかを算定しても  
らうリアルオプションプライシングと言われる手法を採用することが妥当であ  
る。  
  
エ  本来、システム導入は合理化のためであり、従って、システム導入に際して従  
業員何人を減らすことができるかを算定できれば、本システムの投資価値は判断  
できる。  
        
`
        },
        {
                "mondaiId": 106240240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
あるコンビニエンスストアチェーンの調査部では、各店舗の売上高を、半径  
1km圏内の大学などの重要拠点数と地域人口で説明する重回帰モデルで分析して  
いる。  
これに関連する記述として最も適切なものはどれか。  
  
ア  重相関係数が負の値をとることはない。  
  
イ  自由度調整を行っても、決定係数が負になることはない。  
  
ウ  自由度調整を行うのは、パラメータの数に比べてデータの数が相対的に多い回  
帰式で、見かけ上の決定係数が高くなるからである。  
  
エ  独立変数が  
つなので、最小  
乗法は使えない。`
        },
        {
                "mondaiId": 106240250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2012,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
1日平均50万円の売上高がある店舗で、商品の新しい陳列方法を\f週間試行し  
てみたところ、1日平均売上高が52万円になった。しかし実際には新しい陳列方  
法に効果がなく、たまたま他の理由で1日平均売上高が高くなったのかもしれな  
い。  
これに関連する記述として最も適切なものはどれか。  
  
ア  χ2分布を利用して平均売上高の差の検定を行うことができる。  
  
イ  売上高の観察データを分析して、仮説が正しいといえるかどうかを調べること  
を仮説推定という。  
  
ウ  実際にはそうではないにもかかわらず、新しい陳列方法で｢売上高が増えた｣と  
誤判断する誤りを、第二種の誤りという。  
  
エ  真の平均売上高が50万円なのに、\f週間の平均売上高がたまたま52万円にな  
る確率を二項分布を利用して計算することができる。  
`
        },
        {
                "mondaiId": 107240010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財務省｢2009年度法人企業統計年報｣に基づき、中小企業と大企業の売上高経常  
利益率、労働生産性、労働装備率を比較した場合?2009年度、中央値、\f次産業を  
除く全産業I、最も適切なものはどれか。  
  
ア  中小企業の売上高経常利益率、労働装備率、労働生産性はすべて大企業を下回  
る。  
  
イ  中小企業の売上高経常利益率と労働生産性のみ大企業を上回る。  
  
ウ  中小企業の売上高経常利益率と労働装備率のみ大企業を上回る。  
  
エ  中小企業の労働生産性のみ大企業を上回る。  
  
オ  中小企業の労働装備率と労働生産性のみ大企業を上回る。  
        
`
        },
        {
                "mondaiId": 107240021,
                "name": "第2問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
総務省｢2006年事業所・企業統計調査?民営事業所I｣によれば、  
①  
中小企業の企業  
数?会社数と個人事業所の合計Iは419.8万社でわが国の企業数の99.7％を占め、  
中小企業の従業者数?会社の常用雇用者数と個人事業所の従業者数の合計Iは2，784  
万人でわが国の雇用の約Ａ割を占めている。  
また、経済産業省｢2008年工業統計表｣によれば、国内総生産の約  
割を占める  
製造業においても、中小企業は製造業付加価値額?従業者数\u000e人以上の事業所の付  
加価値額合計Iの約Ｂ割を占めている。このように中小企業はわが国経済、  
社会を支える重要な存在である。  
もっとも同じ中小企業といっても、中小企業基本法第  
条の規定に基づく  
②  
小規模  
企業者とそれ以外の中小企業では、保有する経営資源にも大きな違いがある。中小  
企業の現状を分析するに当たっては、同一階層の中でのこうした多様性にも留意し  
ていくことが求められる。  
（設問1）I  
文中の下線部①について、総務省｢2006年事業所・企業統計調査｣に基づき、  
業種別に中小企業の企業数を見た場合に、最も適切なものはどれか。  
  
ア  建設業の企業数は、小売業より多く製造業より少ない。  
  
イ  建設業の企業数は、製造業より多く小売業より少ない。  
  
ウ  小売業の企業数は、建設業より多く製造業より少ない。  
  
エ  小売業の企業数は、製造業より多く建設業より少ない。  
  
オ  製造業の企業数は、小売業より多く建設業より少ない。  
        
`
        },
        {
                "mondaiId": 107240022,
                "name": "第2問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の空欄AとBに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：\u0007Ｂ：\u0005  
  
イ  Ａ：\u000fＢ：\u000e  
  
ウ  Ａ：\u0006Ｂ：\u0007  
  
エ  Ａ：\u0010Ｂ：\u000f`
        },
        {
                "mondaiId": 107240023,
                "name": "第2問設問3",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005I  
文中の下線部②について、総務省｢2006年事業所・企業統計調査｣に基づき、  
小規模企業者の企業数、従業者数を見た場合に、最も適切なものはどれか。  
  
ア  小規模企業者の企業数は、わが国企業数の約\u0007割を占める。  
  
イ  小規模企業者の企業数は、わが国企業数の約\u0006割を占める。  
  
ウ  小規模企業者の企業数は、わが国企業数の約3割を占める。  
  
エ  小規模企業者の従業者数は、わが国の雇用の約\u0005割を占める。  
  
オ  小規模企業者の従業者数は、わが国の雇用の約\u0007割を占める。  
        
`
        },
        {
                "mondaiId": 107240031,
                "name": "第3問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小小売業は地域需要の担い手や地域コミュニティの拠点として、地域の生活を  
支える重要な役割を担っているが、近年の中小小売業を取り巻く事業環境は安定し  
たものではない。商圏人口の減少、  
①  
大型店舗等との競合に加えて、消費者の購買手  
段も多様化している。こうした中で、全国的にもいわゆる  
②  
シャッター通り化を余儀  
なくされている商店街も少なくなく、活性化に向けた取り組みが求められていると  
ころである。  
（設問1）I  
文中の下線部①について、経済産業省｢商業統計表｣に基づき、1997年から  
2007年までの売場面積規模別に、小売業の年間販売額と売場面積の推移を見た  
場合、最も適切なものはどれか。なお、ここでは、売場面積500平方メートル以  
上の事業所を大事業所、売場面積500平方メートル未満の事業所を中小事業所と  
する。  
  
ア  大事業所では売場面積は増加傾向、年間販売額は減少傾向にある。  
  
イ  大事業所では年間販売額は増加傾向、売場面積は減少傾向にある。  
  
ウ  中小事業所では売場面積、年間販売額とも減少傾向にある。  
  
エ  中小事業所では売場面積は増加傾向、年間販売額は減少傾向にある。  
  
オ  中小事業所では年間販売額は増加傾向、売場面積は減少傾向にある。  
        
`
        },
        {
                "mondaiId": 107240032,
                "name": "第3問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の下線部②について、中小企業庁｢2009年度全国商店街調査｣に基づき、  
商店街の空き店舗の状況を見た場合、最も適切なものはどれか。  
  
ア  \f商店街当たりの平均空き店舗数は約10店舗である。  
  
イ  \f商店街当たりの平均空き店舗数は約20店舗である。  
  
ウ  \f商店街当たりの平均空き店舗率は10％強である。  
  
エ  \f商店街当たりの平均空き店舗率は20％強である。  
        
`
        },
        {
                "mondaiId": 107240041,
                "name": "第4問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
①  
経営的に苦境に立った中小企業が事業の再建に向けて利用できる法的手続きとし  
て、民事再生制度がある。民事再生制度においては、債権者の多数の同意を得て、  
かつ裁判所の認可を受けた再生計画を定め、  
②  
債務者である中小企業と債権者間での  
権利関係の調整を行いながら、事業の再生を目指すことになる。民事再生法は  
2000年に施行されたものであるが、中小企業の民事再生申請件数は累計で7，100  
件を超えており?2010年\u0005月時点I、多くの中小企業で事業再生手続として活用さ  
れている。  
中小企業の事業再生に向けては、民事再生制度以外にも、中小企業再生支援協議  
会の設置、  
③  
事業再生ADR制度の創設等、さまざまな関連制度の整備が進められて  
いる。  
（設問1）I  
文中の下線部①について、中小企業庁｢中小企業の企業再生調査?2010年11  
月、複数回答I｣に基づき、民事再生を申請した中小企業について経営的に苦境に  
陥った原因を見た場合に、中小企業の回答割合が高いものから低いものへと並べ  
た組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａ金融機関による貸し渋り、貸しはがし  
ｂ取引先の倒産・事業縮小  
ｃ本業の経営不振  
[解答群]  
  
ア  ａ金融機関による貸し渋り、貸しはがしｂ取引先の倒産・事業縮小  
ｃ本業の経営不振  
  
イ  ａ金融機関による貸し渋り、貸しはがしｃ本業の経営不振  
ｂ取引先の倒産・事業縮小  
        
        
  
ウ  ｂ取引先の倒産・事業縮小ａ金融機関による貸し渋り、貸しはがし  
ｃ本業の経営不振  
  
エ  ｃ本業の経営不振ａ金融機関による貸し渋り、貸しはがし  
ｂ取引先の倒産・事業縮小  
  
オ  ｃ本業の経営不振ｂ取引先の倒産・事業縮小  
ａ金融機関による貸し渋り、貸しはがし`
        },
        {
                "mondaiId": 107240042,
                "name": "第4問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の下線部②について、経営者を連帯保証人として金融機関から融資を受け  
ていた中小企業が民事再生の適用を受けた場合の記述として、最も適切なものは  
どれか。  
  
ア  経営者の保証債務は全額免除される。  
  
イ  経営者の保証債務は免除されない。  
  
ウ  中小企業の借入債務は全額免除される。  
  
エ  中小企業の借入債務は保有資産を上回る範囲でのみ免除される。`
        },
        {
                "mondaiId": 107240043,
                "name": "第4問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005I  
文中の下線部③について、産業活力の再生及び産業活動の革新に関する特別措  
置法の改正?2007年Iにより創設された事業再生ADRの説明として最も適切な  
ものはどれか。  
  
ア  公正な第三者機関による裁判外紛争解決手続である。  
  
イ  商取引債権者のみを対象とした私的債務整理手続である。  
  
ウ  当事者のみによる私的債務整理手続である。  
  
エ  都道府県による公的調停手続である。  
        
`
        },
        {
                "mondaiId": 107240051,
                "name": "第5問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
経済の閉そく感が強まる中で、経済の新陳代謝や雇用の創出につながる起業を促  
進する必要性が高まっている。しかしながら、総務省｢事業所・企業統計調査｣や  
｢経済センサス安基礎調査｣によれば、企業単位でも事業所単位でも、わが国では  
1980年代後半から開業率が廃業率を下回る状況が続いており、米国や英国と比べ  
ても起業活動は低い水準にあるのが現状である。  
もっとも、  
①  
すべての業種で開業率が廃業率を下回っているわけではない。また、  
総務省｢就業構造基本調査｣によれば、2007年時点で起業希望者と起業準備者をあ  
わせて  
②  
100万人を上回る潜在的な起業家が存在しており、起業に関心を持つ人たち  
は多い。起業を促進するためには、こうした潜在的な  
③  
起業家の抱えるさまざまな課  
題を除去していくことが重要であろう。  
（設問1）I  
文中の下線部①について、総務省｢事業所・企業統計調査｣に基づき、業種別の  
開廃業率?2004?2006年、企業単位、年平均Iを見た場合、開業率から廃業率を  
差し引いた値が大きいものから小さいものへと並べた組み合わせとして、最も適  
切なものを下記の解答群から選べ。  
ａ医療・福祉  
ｂ教育・学習支援業  
ｃ情報通信業  
[解答群]  
  
ア  ａ医療・福祉ｂ教育・学習支援業ｃ情報通信業  
  
イ  ａ医療・福祉ｃ情報通信業ｂ教育・学習支援業  
  
ウ  ｂ教育・学習支援業ａ医療・福祉ｃ情報通信業  
  
エ  ｂ教育・学習支援業ｃ情報通信業ａ医療・福祉  
  
オ  ｃ情報通信業ａ医療・福祉ｂ教育・学習支援業  
        
`
        },
        {
                "mondaiId": 107240052,
                "name": "第5問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の下線部②について、総務省｢就業構造基本調査｣に基づき、起業家および  
起業希望者の性別および年齢別構成?2007年Iを見た場合、最も適切なものはど  
れか。なお、ここで、起業家とは過去1年間に職を変えたまたは新たに職に就い  
た者のうち、現在は自営業主?内職者を除くIとなっている者をいう。また、起業  
希望者とは有業者の転職希望者のうち｢自分で事業を起こしたい｣と回答した者お  
よび無業者のうち｢自分で事業を起こしたい｣と回答した者をいう。  
  
ア  起業家を年齢別構成で見ると29歳以下の割合が全体の約\u0005割を占める。  
  
イ  起業家を年齢別構成で見ると40歳代より60歳以上の割合が高い。  
  
ウ  起業希望者を性別で見ると女性が全体の約\u0007割を占める。  
  
エ  起業希望者を年齢別構成で見ると29歳以下が全体の約\u0005割を占める。  
  
オ  起業希望者を年齢別構成で見ると60歳以上が全体の約\u0005割を占める。`
        },
        {
                "mondaiId": 107240053,
                "name": "第5問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005I  
文中の下線部③について、中小企業庁｢起業に関する実態調査?2010年12月、  
複数回答I｣に基づき、起業家が起業時?起業準備期間中Iおよび起業後?起業後か  
ら調査時点に至る期間Iに直面した課題を見た場合、最も適切なものはどれか。  
  
ア  起業時の最大の課題は仕入先の確保である。  
  
イ  起業時の最大の課題は販売先の確保である。  
  
ウ  起業後の最大の課題は資金調達である。  
  
エ  起業後の最大の課題は質の高い人材確保である。  
  
オ  起業時と起業後では最大の課題は同一である。  
        
`
        },
        {
                "mondaiId": 107240060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経済のグローバル化が急速に進展しているものの、中小企業では海外に直接投資  
を行う企業の占める割合は依然として低い。総務省｢2006年事業所・企業統計調  
査｣によれば、わが国の中小企業全体に占める直接投資を行う企業の割合は0.51％  
にとどまっているが、業種によって違いも見られる。  
業種別に直接投資を行う中小企業の割合?直接投資割合Iを見た場合、最も適切な  
ものはどれか。ここで、直接投資を行う企業とは、海外に子会社または関連する会  
社を保有する企業をいい、個人事業所は含まない。  
  
ア  小売業の直接投資割合は、情報通信業より多く製造業より少ない。  
  
イ  小売業の直接投資割合は、製造業より多く情報通信業より少ない。  
  
ウ  情報通信業の直接投資割合は、小売業より多く製造業より少ない。  
  
エ  情報通信業の直接投資割合は、製造業より多く小売業より少ない。  
  
オ  製造業の直接投資割合は、情報通信業より多く小売業より少ない。`
        },
        {
                "mondaiId": 107240071,
                "name": "第7問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
日本国内におけるものづくりを取り巻く環境が厳しさを増す中で、製造業の国内  
における設備投資は抑制傾向にある。この結果、内閣府｢民間企業資本ストック｣や  
｢国富調査｣によれば、日本企業の国内における設備年齢は年々上昇する傾向にあ  
る。設備年齢が上昇すると、設備の老朽化とこれに伴うの悪化が懸念さ  
れる。設備年齢の上昇によるの悪化は、企業の競争力の低下に加えて、  
さらなる設備投資の抑制という悪循環を引き起こす可能性を有している。製造業の  
経営にあたっては、短期的な利益追求だけでなく、持続的な競争力を維持する観点  
から長期的な視点に立った投資判断も求められる。  
        
        
（設問1）I  
文中の下線部について、日本政策投資銀行｢2010・2011・2012年度設備投資計  
画調査｣に基づき、製造業の業種別に、日本国内における設備投資?国内設備投  
資Iに対する海外における設備投資?海外設備投資Iの比率?2010年度実績、海外  
／国内設備投資比率Iを見た場合に、最も適切なものはどれか。  
  
ア  自動車産業の海外／国内設備投資比率は、電気機械産業を下回っている。  
  
イ  自動車産業の海外／国内設備投資比率は、非鉄金属産業を下回っている。  
  
ウ  製造業全体の海外／国内設備投資比率は、約\u000e割に達している。  
  
エ  製造業全体の海外／国内設備投資比率は、約\u0010割に達している。  
  
オ  電気機械産業の海外／国内設備投資比率は、非鉄金属産業を上回っている。`
        },
        {
                "mondaiId": 107240072,
                "name": "第7問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の空欄に入る最も適切な語句はどれか。  
  
ア  固定長期適合率  
  
イ  固定比率  
  
ウ  生産性  
  
エ  総資本回転率  
        
`
        },
        {
                "mondaiId": 107240080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
日本の製造業では伝統的に製品の企画・開発から生産、販売を垂直的に手掛ける  
企業が多いが、近年製造業を取り巻く環境の変化を受けて、世界規模で見れば、  
EMS?ElectronicsManufacturingServiceIやODM?OriginalDesignManufacturerI  
と呼ばれる新たな業態が出現している。EMSとODMの説明として、最も適切な  
ものはどれか。  
  
ア  EMSとは、自社ブランドで電子機器の生産のみを手掛ける業態である。  
  
イ  EMSとは、自社ブランドで電子機器の設計、開発から生産までを手掛ける業  
態である。  
  
ウ  ODMとは、相手先ブランドで製品の生産のみを手掛ける業態である。  
  
エ  ODMとは、相手先ブランドで製品の設計、開発を手掛ける業態である。  
  
オ  ODMとは、自社ブランドで製品の設計、開発を手掛ける業態である。`
        },
        {
                "mondaiId": 107240091,
                "name": "第9問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
  
次の文章を読んで、下記の設問に答えよ。  
①  
自己資本比率が低く間接金融への依存度が高い中小企業では、金融機関との良好  
な関係を築いて、安定的な資金調達を行うことが望まれる。また、大企業の資金需  
要が低迷する中で、資金の貸し手である金融機関にとっても、中小企業への貸出機  
能を強化する必要性が高まっている。このため、近年では中小企業、金融機関とも  
接触頻度を上げて、顔の見える関係を構築することで、情報の非対称性を軽減しよ  
うとする動きを強めている。  
さらに、金融機関が貸出以外の事業面での支援を通じて資金需要を喚起していく  
動きも広がっている。しかしながら、これら貸出以外の取り組みについては、  
②  
中小  
企業の意識と金融機関の意識が一致していないきらいがあり、それぞれの意識につ  
いて相互理解を深めて、効果的な支援が行われることが望まれている。  
        
        
（設問1）I  
文中の下線部①について、財務省｢2009年度法人企業統計年報｣に基づき、業  
種別に中小企業の自己資本比率?2009年度、中央値Iを見た場合、最も適切なも  
のはどれか。  
  
ア  卸・小売業の自己資本比率は、サービス業より高く製造業より低い。  
  
イ  卸・小売業の自己資本比率は、製造業より高くサービス業より低い。  
  
ウ  サービス業の自己資本比率は、卸・小売業より高く製造業より低い。  
  
エ  サービス業の自己資本比率は、製造業より高く卸・小売業より低い。  
  
オ  製造業の自己資本比率は、卸・小売業より高くサービス業より低い。`
        },
        {
                "mondaiId": 107240092,
                "name": "第9問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の下線部②について、中小企業庁｢経営環境実態調査?2010年11月I｣、  
｢中小企業向け融資に関する調査?2010年10月I｣に基づき、中小企業が金融機関  
に求めている相談項目と金融機関が重視している相談項目を比較した場合、最も  
適切なものはどれか。  
  
ア  中小企業では｢経営計画の作成に関する相談｣を最も重視しているのに対し、  
金融機関は｢新規分野進出に関する相談｣を最も重視している。  
  
イ  中小企業では｢事業引き継ぎに関する相談｣を最も重視しているのに対し、金  
融機関は｢設備投資に関する相談｣を最も重視している。  
  
ウ  中小企業では｢新規分野進出に関する相談｣を最も重視しているのに対し、金  
融機関は｢経営計画の作成に関する相談｣を最も重視している。  
  
エ  中小企業では｢設備投資に関する相談｣を最も重視しているのに対し、金融機  
関は｢事業引き継ぎに関する相談｣を最も重視している。  
        
`
        },
        {
                "mondaiId": 107240100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄AとBに入る最も適���な語句の組み合わせを下記の解答群から  
選べ。  
中小企業庁｢2009年中小企業実態基本調査｣に基づき、従業者規模別業種別に中  
小企業の対売上高営業利益率を見ると、いずれの業種でも規模が小さいほど  
Ａ傾向にある。同じく対売上高費用率を見ると、宿泊業、飲食サービス業  
を除くいずれの業種でも、販売原価の割合が高くなる。特に卸売業、小売業では、  
商品仕入原価がいずれの規模でも\u0006?\u0010割を占める。一方で、宿泊業、飲食サービ  
ス業ではＢの割合が最も高くなっている。  
[解答群]  
  
ア  Ａ：高いＢ：人件費  
  
イ  Ａ：高いＢ：地代家賃  
  
ウ  Ａ：低いＢ：広告宣伝費  
  
エ  Ａ：低いＢ：人件費  
  
オ  Ａ：低いＢ：地代家賃`
        },
        {
                "mondaiId": 107240110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
わが国の中小企業金融の特徴として、金融機関からの借入金でありながら継続的  
な借換え等により、中小企業にとって事実上資本的性格を有すると認識されている  
資金の存在が指摘される。こうした資金を何と呼ぶか。最も適切なものを選べ。  
  
ア  運転資本  
  
イ  擬似資本  
  
ウ  固定資本  
  
エ  他人資本  
  
オ  長期資本  
        
`
        },
        {
                "mondaiId": 107240120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
事業環境が構造的に変化する中で、主な製品・商品・サービスにつき業種を超え  
て転換する業種転換を図る企業も存在する。こうした業種転換の効果を見るため  
に、経済産業省｢工業統計表｣、総務省｢日本標準産業分類｣に基づき、1997年から  
2002年の期間に製造業内中分類での業種転換を行った中小企業の事業所?業種転換  
事業所Iと業種転換を行っていない中小企業の事業所?非業種転換事業所Iについて、  
2002年から2007年における製造品出荷額等、付加価値額、労働生産性?いずれも  
実質値Iの変化を比較した場合、最も適切なものはどれか。  
なお、ここで業種転換とは出荷額構成比の最も高い業種の転換をいう。また、従  
業者数29人以下の事業所の付加価値額は粗付加価値額を用い、労働生産性は付加  
価値額を従業者数で除して算出する。対象となる事業所は、1997年?2007年の間  
に存続した従業者数\u000e人以上の事業所である。  
  
ア  業種転換事業所の製造品出荷額等、付加価値額、労働生産性の伸び率はすべて  
非業種転換事業所を上回っている。  
  
イ  業種転換事業所の製造品出荷額等、付加価値額、労働生産性の伸び率は非業種  
転換事業所を下回っている。  
  
ウ  業種転換事業所の製造品出荷額等、付加価値額の伸び率のみ非業種転換事業所  
を上回っている。  
  
エ  業種転換事業所の製造品出荷額等、労働生産性の伸び率のみ非業種転換事業所  
を上回っている。  
  
オ  業種転換事業所の付加価値額、労働生産性の伸び率のみ非業種転換事業所を上  
回っている。  
        
`
        },
        {
                "mondaiId": 107240130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　中小企業基本法の定義に基づく、小規模企業者と中小企業者に関する記述として、最も適切なものはどれか。  
 

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>


ア  資本金100万円で従業員数100人の飲食店は小規模企業者に該当し、資本金1億円で従業員数が100人の飲食店は中小企業者に該当する。  
  
イ  資本金1，000万円で従業員数5人の小売業者は小規模企業者に該当し、資本金3，000万円で従業員数が200人の小売業者は中小企業者に該当する。<hint hidden='true'>**
←⭕
**</hint>   
  
ウ  従業員数10人の卸売業者?個人企業Iは小規模企業者に該当し、資本金3，000万円で従業員数が100人の卸売業者は中小企業者に該当する。  
  
エ  従業員数10人の製造業者?個人企業Iは小規模企業者に該当し、資本金5億円で従業員数が500人の卸売業者は中小企業者に該当する。

`
        },
        {
                "mondaiId": 107240140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業のA社は、地元の特産品を活用した観光客向けの新商品の開発を計画  
している。中小企業診断士のB氏は、A社が｢中小企業新事業活動促進法｣に基づ  
く支援を受けることができるよう、事業内容や経営目標を盛り込んだ計画を作成す  
るようアドバイスを行った。  
A社が、この法律の支援対象となるための計画を作成するにあたり、B氏がA  
社に行うアドバイスとして、<u>最も不適切なものはどれか</u>。  
  
ア  計画期間は、\u0005?5年で作成してください。  
  
イ  経常利益の伸び率が年率平均\u0007％以上になることが必要です。  
  
ウ  この計画においては、付加価値額は、営業利益、人件費、減価償却費の合計と  
して算出してください。  
  
エ  付加価値額の伸び率が年率平均\u0005％以上になることが必要です。  
        
`
        },
        {
                "mondaiId": 107240150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
農業者と連携を計画している中小製造業のA社は、｢中小企業者と農林漁業者と  
の連携による事業活動の促進に関する法律｣に基づく支援を受けるため、｢農商工等  
連携事業計画｣の作成を予定している。中小企業診断士のB氏は、A社の社長か  
ら、この計画の申請、認定に関して、アドバイスを求められた。  
A社社長に対する中小企業診断士B氏のアドバイスとして、最も適切なものは  
どれか。  
  
ア  計画認定のためには、既存商品の売上増加に結びつくことが求められます。  
  
イ  計画は、農業者と中小企業者の両者が共同して作成しなければなりません。  
  
ウ  農業者と中小企業者が、通常の取引関係において、原材料の売買を行うことが  
認定要件となります。  
  
エ  連携相手の農業者が食品製造を行っている場合は、計画の申請ができません。  
        
`
        },
        {
                "mondaiId": 107240161,
                "name": "第16問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
地域中小企業応援ファンドは、対象となる地域の中小企業者のＡに応じ  
て｢Ｂ｣と｢チャレンジ企業応援型｣の  
種類のスキームがあり、地域の知恵  
と工夫を活かして、地域の多様な取組を支援する。  
Ｂの支援内容は、以下のとおりである。ファンドを組成するＣ  
に対して、Ｄが必要な資金の一部を貸付け、Ｃを通じてファンド  
管理者に貸付を行う。このファンドの運用益を原資として、中小企業等を対象に助  
成を行う。  
（設問1）I  
文中の空欄AとBに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ａ：規模と業種Ｂ：スタート・アップ応援型  
  
イ  Ａ：規模と業種Ｂ：地域ビジネス応援型  
  
ウ  Ａ：成長段階Ｂ：スタート・アップ応援型  
  
エ  Ａ：成長段階Ｂ：地域ビジネス応援型`
        },
        {
                "mondaiId": 107240162,
                "name": "第16問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の空欄CとDに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ｃ：市区町村Ｄ：中小企業基盤整備機構  
  
イ  Ｃ：市区町村Ｄ：日本政策金融公庫  
  
ウ  Ｃ：都道府県Ｄ：中小企業基盤整備機構  
  
エ  Ｃ：都道府県Ｄ：日本政策金融公庫  
        
`
        },
        {
                "mondaiId": 107240170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　下記は、創業予定者に対する中小企業診断士のアドバイスである。空欄AとBに入るものの組み合わせとして最も適切なものを下記の解答群から選べ。  
｢創業には、多様な事業体の活用が可能です。たとえば、Ａは、新しい  
会社形態として平成18年に創設された制度です。合名会社や合資会社と同様に人的会社と呼ばれる組織形態で、人的な能力を活かした創業などで活用が可能です。  
出資者全員が有限責任であることや、組織の内部ルールの設定が柔軟であること、課税はＢであるなどの特徴があります。｣  

[解答群]  
  
ア  Ａ：合同会社いわゆるLLC　Ｂ：構成員課税  
  
イ  Ａ：合同会社いわゆるLLC　Ｂ：法人課税  
  
ウ  Ａ：有限責任事業組合いわゆるLLP　Ｂ：構成員課税  
  
エ  Ａ：有限責任事業組合いわゆるLLP　Ｂ：法人課税

`
        },
        {
                "mondaiId": 107240180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
洋菓子小売業を開業予定のA氏から、開業資金の相談を受けた中小企業診断士  
のB氏は、日本政策金融公庫の｢新創業融資制度｣の利用を薦めることにした。  
このとき、B氏からA氏へのアドバイスとして、最も適切なものはどれか。  
  
ア  貸付は、都道府県を通じて行います。  
  
イ  この貸付は、中小企業新事業活動促進法に基づいて設けられたもので、創業す  
る方や創業後5年未満の方を支援するための融資制度です。  
  
ウ  ビジネスプランを審査して、原則として無担保・無保証人で融資をする制度で  
す。  
  
エ  必要資金の2分の1を無利子で貸し付ける制度です。  
        
`
        },
        {
                "mondaiId": 107240190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
わが国経済の活性化のためには、独自の技術やノウハウを持ち地域経済を支える  
中小企業が破綻に追い込まれることがないよう、円滑な再生を進めることが不可欠  
である。特に、中小企業の再生の必要性・重要性は高く、｢中小企業再生支援協議  
会｣を軸とした中小企業の再生の取り組みが行われている。｢中小企業再生支援協議  
会｣に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  47都道府県の認定支援機関に設置されている。  
  
イ  支援業務部門は、個別支援チームを編成し、再生計画策定支援を行う。  
  
ウ  支援業務部門は、主要債権者等との連携を図りながら具体的で実現可能な再生  
計画の策定支援を行う。  
  
エ  中小企業支援法に基づき設置されている。  
        
`
        },
        {
                "mondaiId": 107240200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業のA社は、従業員のために退職金制度を設けたいと思っているが、独  
力では、退職金制度を設けることが困難な状況にある。A社の社長から相談を受  
けた中小企業診断士のB氏は、一般の中小企業退職金共済制度を紹介することに  
した。B氏の説明として、最も適切なものはどれか。  
  
ア  掛金の2分の1は、事業主の損金又は必要経費として認められます。  
  
イ  掛金の2分の1を都道府県が助成してくれます。  
  
ウ  事業主との間に使用従属関係が認められる同居の親族についても、｢従業員｣と  
してこの制度に加入できます。  
  
エ  退職金は、中小企業者を通して、従業員に支払われます。  
        
`
        },
        {
                "mondaiId": 107240211,
                "name": "第21問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
下請事業者は、親事業者に対する取引依存度が高いことから、しばしば親事業者  
から不利な取引条件を強いられることがある。国は、下請取引の適正化を図るた  
め、下請代金支払遅延等防止法?下請代金法Iによって、親事業者の不公正な取引行  
為を規制している。  
（設問1）I  
下請代金法で、親事業者に課せられている義務として、最も不適切なものはど  
れか。  
  
ア  下請ガイドラインを策定する義務  
  
イ  下請代金の支払期日を定める義務  
  
ウ  書面を交付する義務  
  
エ  書類の作成・保存義務  
  
オ  遅延利息の支払義務`
        },
        {
                "mondaiId": 107240212,
                "name": "第21問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
下請代金法が適用される取引に該当するものとして、最も適切なものはどれか。  
  
ア  A社?資本金50万円Iが、タッチパネルのメーカーB社?資本金500万円Iか  
ら、部品を支給され組立てを受託する。  
  
イ  C社?資本金1，000万円Iが、D社?資本金5，000万円Iが製造するペットボト  
ルに印刷加工する仕事を受託する。  
  
ウ  E社?資本金5，000万円Iが、F社?資本金1億円Iから部品の製造を受託す  
る。  
  
エ  鋳造業であるG社?資本金\u0007億円Iが、自動車メーカーH社?資本金50億円I  
からエンジン部品の製造を受託する。  
        
`
        },
        {
                "mondaiId": 107240220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業のA社は、取引先企業の倒産によって、経営の安定に支障を生じてい  
る。そこで、中小企業診断士のB氏は、A社への資金供給の円滑化を図るため、  
｢経営安定関連保証制度?セーフティネット保証I｣を紹介することにした。  
この制度に関する、B氏のA社への説明として、<u>最も不適切なものはどれか</u>。  
  
ア  この制度を利用するためには、事業所の所在地を管轄する市町村長又は特別区  
長の認定が必要になります。  
  
イ  信用保証協会が通常の保証限度額内で、とくに有利な条件で保証を行います。  
  
ウ  制度を利用するための認定とは別に、金融機関及び信用保証協会による金融上  
の審査があります。  
  
エ  取引先の倒産だけでなく、自然災害によって経営の安定に支障が生じたケース  
でも活用できます。  
        
`
        },
        {
                "mondaiId": 107240231,
                "name": "第23問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
高度化事業は、中小企業者が共同で事業環境の改善や経営基盤の強化に取り組む  
場合に必要となる設備資金について、事業計画に対するアドバイスを受けたうえ  
で、長期・低利?又は無利子Iで貸付けを受けることができる制度である。  
高度化融資の貸付方法には、A方式とB方式がある。A方式は、\fつの都道府  
県内で行われる事業に対する貸付方法である。B方式は、原則として、  
つ以上の  
都道府県にまたがる広域の事業に対する貸付方法である。  
対象となる事業のうち、?は、中小小売商業者が共同で入居するショッ  
ピングセンターを建設したり、中小製造業者が生産工程を統合し共同で使用する工  
場を建設したりするなど、共同で利用する\fつの建物を設置・運営する事業であ  
る。  
?は、工場を拡張したいが隣接地に用地を確保できない、騒音問題のた  
め操業に支障があるなどの問題を抱える中小企業者が集まり、適地に充実した設備  
の整った工場を新設し、事業の拡大・効率化、公害問題の解決を図るものである。  
（設問1）I  
高度化資金の貸付に関する記述として、最も適切なものはどれか。  
  
ア  A方式では、都道府県が中小企業者に貸付を行う。  
  
イ  貸付期間は、10年以内である。  
  
ウ  貸付割合は、原則として50％以内である。  
  
エ  担保・保証人は、原則として不要である。  
        
`
        },
        {
                "mondaiId": 107240232,
                "name": "第23問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
文中の空欄?と?に入る高度化事業の組み合わせとして、最も適切なものはど  
れか。  
  
ア  ?：企業合同事業?：集団化事業  
  
イ  ?：企業合同事業?：連鎖化事業  
  
ウ  ?：施設集約化事業?：集団化事業  
  
エ  ?：施設集約化事業?：連鎖化事業  
        
`
        },
        {
                "mondaiId": 107240241,
                "name": "第24問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
商店街振興組合は、商店街が形成されている地域において、小売商業又はサービ  
ス業に属する事業その他の事業を営む者及び定款で定めた者のための組織であっ  
て、共同経済事業や環境整備事業を行うことを目的とする。  
（設問1）I  
商店街振興組合に関する記述として、最も適切なものはどれか。  
  
ア  \f地区に複数組合の設立が可能である。  
  
イ  ｢中小企業団体の組織に関する法律｣に基づく組合制度である。  
  
ウ  組合員としての資格を有する者の\u0005分の  
以上が組合員とならなければ設立  
できない。  
  
エ  総組合員の\u0005分の  
以上が小売商業に属する事業を営む者でなければ設立で  
きない。  
        
`
        },
        {
                "mondaiId": 107240242,
                "name": "第24問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
下記のグラフのA~Dは、企業組合、協業組合、商工組合、商店街振興組合  
の組合数の推移を示したものである。この中で、｢商店街振興組合｣の推移を示し  
たものはどれか。最も適切なものを下記の解答群から選べ。  
注\fいずれも\u0005月末。平成23年\u0005月末の数値は速報値。  
注  
商工組合と商店街振興組合には、それぞれ連合会を  
含んでいる。  
[解答群]  
  
ア  A  
  
イ  B  
  
ウ  C  
  
エ  D  
        
`
        },
        {
                "mondaiId": 107240251,
                "name": "第25問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文章を読んで、下記の設問に答えよ。  
｢エンジェル税制｣は、一定の要件を満たすベンチャー企業に対して投資を行う個  
人投資家が減税を受けることができる制度である。  
（設問1）I  
  
エ  ンジェル税制に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  この税制の適用を受ける場合には、個人投資家は各地域の経済産業局に確認  
書の発行申請を行わなければならない。  
  
イ  所得税の減税を受けることができる制度である。  
  
ウ  対象となるベンチャー企業株式を譲渡等した年に減税を受けることができ  
る。  
  
エ  対象となるベンチャー企業へ投資した年に減税を受けることができる。`
        },
        {
                "mondaiId": 107240252,
                "name": "第25問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問  
I  
  
エ  ンジェル税制の対象となるベンチャー企業の要件に関する記述として、最も  
不適切なものはどれか。  
  
ア  外部?特定の株主グループ以外Iからの投資を\u000f分の1以上取り入れている会  
社であること。  
  
イ  創業20年未満の中小企業者であること。  
  
ウ  大規模法人?資本金1億円超等I及び当該大規模法人と特殊の関係?子会社等I  
にある法人の所有に属さないこと。  
  
エ  未登録・未上場の株式会社で、風俗営業等に該当する事業を行う会社でない  
こと。  
        
`
        },
        {
                "mondaiId": 107240260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
次の文中の空欄AとBに入る語句の組み合わせとして最も適切なものを下記の  
解答群から選べ。  
｢戦略的中心市街地商業等活性化支援事業｣は、Ａの認定を受けた  
Ｂの｢中心市街地活性化基本計画｣に基づいて実施する、商業の活性化や中  
心市街地のにぎわい創出等に資する事業に対して支援を行う事業である。  
[解答群]  
  
ア  Ａ：経済産業大臣Ｂ：市町村  
  
イ  Ａ：経済産業大臣Ｂ：都道府県  
  
ウ  Ａ：内閣総理大臣Ｂ：市町村  
  
エ  Ａ：内閣総理大臣Ｂ：都道府県  
        
`
        },
        {
                "mondaiId": 107240270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業に関する施策は、時代に応じて変遷をしてきた。下記のａからｃの中小  
企業の法律に関する記述について、古いものから順に正しく配列したものを下記の  
解答群から選べ。  
ａ中小企業の自助努力を尊重しつつ、きめ細かな支援を行うため、中小企業基本  
法が改正され、中小企業政策の抜本的見直しが行われた。  
ｂ中小企業近代化促進法の制定により、政策の力点は近代化・高度化におかれ  
た。  
ｃわが国製造業の国際競争力を支えるものづくり中小企業の支援を行うため、中  
小ものづくり高度化法が制定された。  
[解答群]  
  
ア  ａｂｃ  
  
イ  ａｃｂ  
  
ウ  ｂａｃ  
  
エ  ｂｃａ  
  
オ  ｃｂａ  
        
`
        },
        {
                "mondaiId": 107240280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2012,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
中小企業のA社は、海外での模倣品の製造により、知的財産権の侵害を受けて  
いる。模倣品対策を検討しているA社に対して、中小企業診断士のB氏は、｢中小  
企業知的財産権保護対策事業｣を紹介することにした。  
この事業に関して、B氏のA社に対するアドバイスとして、最も不適切なもの  
はどれか。  
  
ア  海外の知的財産事情に詳しい専門家を\u000fカ月間無料で派遣してくれます。  
  
イ  相談・受付窓口は、日本貿易振興機構になります。  
  
ウ  補助対象経費は、侵害調査にかかる海外調査機関への委託費用です。  
  
エ  補助率は、\u0005分の  
以内で、上限額があります。  
`
        },
        {
                "mondaiId": 101230010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
GDP（国内総生産）とGNP（国民総生産）の関係について、次の式の空欄にあては  
まる最も適切なものを下記の解答群から選べ。  
GDP＝GNP＋  
〔解答群〕  
  
ア  海外からの要素所得受取－海外への要素所得支払  
  
イ  海外への要素所得支払－海外からの要素所得受取  
  
ウ  固定資本減耗＋間接税－補助金  
  
エ  固定資本減耗＋補助金－間接税  
`
        },
        {
                "mondaiId": 101230020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本の名目GDP成長率と実質GDP成長率を示したものである。この  
図から読み取れることおよび経済状況の説明として最も適切なものはどれか。  
内閣府『経済財政白書』（２０１０年版）  
  
ア  １９６０年代の高度経済成長期には、持続的な物価の上昇が見られ、これは貨幣  
価値を上昇させる効果を持つ。  
  
イ  １９７０年代前半には、第\u0002次オイルショックに伴い、物価の上昇と不況が発生  
し、スタグフレーションの現象に陥った。  
  
ウ  １９８０年代後半には、円高不況、バブル経済、アジア通貨危機を経験し、その  
後、長期の景気低迷を迎えることとなった。  
  
エ  ２０００年代は、持続的な物価の下落が見られ、これは企業の実質債務の増加や  
実質利子率の上昇を生じさせる効果を持つ。  
  
オ  「名目GDP成長率＝実質GDP成長率－GDPデフレータ変化率」という関係  
が成立し、名目GDP成長率と実質GDP成長率の差は物価の変化を表している。  
`
        },
        {
                "mondaiId": 101230030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　消費の決定に関する説明として、<u>最も<hintTarget>不適切</hintTarget>なものはどれか</u>。  
  
ア  倹約のパラドクスでは、美徳とされる倹約の推奨が経済全体では消費支出とGDPの減少を引き起こすと考える。  
<hint hidden='true'>**
⭕
** </hint>
  
イ  消費の習慣仮説では、景気の後退局面においても、消費の減少に対して<hintTarget>歯止めが作用</hintTarget>すると考える。  
<hint hidden='true'>**
⭕ラチェット効果の説明です（詳細は画面下部の重点単語を参照）
** </hint>

ウ  絶対所得仮説では、<hintTarget>1回限りの減税によって変動所得が増加しても消費は一定</hintTarget>にとどまると考える。  

<hint hidden='true'>**
❌<hintTarget>恒常</hintTarget>所得仮説に関する説明です（詳細は画面下部の重点単語を参照）
** </hint>

エ  ライフサイクル仮説では、生涯所得の増加が消費の増加を引き起こすと考える。
<hint hidden='true'>**
⭕
** </hint>

`
        },
        {
                "mondaiId": 101230040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
貨幣市場に関する説明として最も適切なものはどれか。  
  
ア  古典派の貨幣数量説では、貨幣需要は投機的需要のみであると考える。  
  
イ  ハイパワードマネーは、公定歩合の引き下げ、売りオペによって増加する。  
  
ウ  マネーストックのうちM\u0002は、現金通貨と預金通貨から構成される。  
  
エ  流動性選好理論では、貨幣市場において超過需要が発生する場合、債券市場も  
超過需要の状態にあり、それは利子率の上昇を通じて解消されると考える。  
`
        },
        {
                "mondaiId": 101230050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
日本の金融政策に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  インフレ・ターゲティングとは、物価の安定を具体的な物価上昇率（消費者物  
価指数等）の数値で示すなど、金融政策の透明性向上のためのひとつの枠組みと  
して議論されたものである。  
  
イ  金融の量的な指標に目標値を定め、それが達成されるように金融緩和を行うこ  
とを「量的緩和政策」と呼ぶ。  
  
ウ  準備預金制度とは、この制度の対象となる都市銀行などの金融機関に対して、  
受け入れている預金等の一定比率（準備率）以上の金額を日本銀行に預け入れるこ  
とを義務づける制度である。  
  
エ  日本銀行は中央銀行としての独立性を担保されているが、金融政策運営を討  
議・決定する会合（金融政策決定会合）では、財務大臣は議決権を行使することが  
できる。  
`
        },
        {
                "mondaiId": 101230060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
いま、家計、企業、政府、外国から構成される経済モデルを考える。各々の記  
号は、Y：GDP、C：消費支出、I：民間投資支出、G：政府支出、T：租税収入、  
X：輸出、M：輸入、C０：独立消費、M０：独立輸入であり、単位は兆円とする。ま  
た、c：限界消費性向、m：限界輸入性向である。  
生産物市場の均衡条件Y＝C＋I＋G＋X－M  
消費関数C＝C０＋c（Y－T）  
C０＝５０，c＝０．６  
民間投資支出I＝１１０  
政府支出G＝５０  
租税収入T＝５０  
輸出X＝８０  
輸入関数M＝M０＋mY  
M０＝１０，m＝０．１  
このモデルから導かれる記述として最も適切なものはどれか。  
  
ア  均衡GDPは６００兆円である。  
  
イ  減税が\u0002兆円の規模で実施された場合、均衡GDPは\u0003兆円増加する。  
  
ウ  政府支出が\u0002兆円増加した場合、均衡GDPは１２．５兆円増加する。  
  
エ  輸出が１０兆円減少した場合、均衡GDPは２０兆円増加する。  
`
        },
        {
                "mondaiId": 101230070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経済が「流動性のわな」に陥った場合の説明として、最も適切なものの組み合わせ  
を下記の解答群から選べ。  
ａ貨幣供給が増加しても伝達メカニズムが機能せず、利子率は低下するが、投資  
支出の増加が生じない。  
ｂ政府支出の増加が生じてもクラウディング・アウトは発生しない。  
ｃ「流動性のわな」のもとでは、貨幣需要の利子弾力性はゼロになり、利子率が下  
限値に達すると、債券価格は上限値に到達する。  
ｄ「流動性のわな」のもとでは、GDPの水準は貨幣市場から独立であり、生産物  
市場から決定される。  
〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101230080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、開放経済下におけるマクロ経済モデルを描いたものである。  
いま、小国モデル、完全資本移動、変動為替レート制、物価の硬直性、為替レー  
トの静学的な予想を仮定する。下図では、これらの前提に基づき、生産物市場の均  
衡を示すIS曲線、貨幣市場の均衡を示すLM曲線、自国利子率（r）と外国利子率  
（r＊）の均等化を示すBP曲線が表されている。  
政府支出の増加に伴う効果の説明として最も適切なものを下記の解答群から選  
べ。  
〔解答群〕  
  
ア  政府支出の増加は、IS曲線を右方にシフトさせ、所得の拡大を生じさせる。  
  
イ  政府支出の増加は、IS曲線を右方にシフトさせ、金利差に伴う大規模な資  
本の流出を引き起こし円高を招く。  
  
ウ  政府支出の増加は、クラウディング・アウトを通じて民間投資支出の減少を  
引き起こす。  
  
エ  政府支出の増加は、それを完全に相殺する経常収支の悪化を引き起こし、所  
得に影響を与えない。  
`
        },
        {
                "mondaiId": 101230090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
内生的経済成長モデル（AKモデル）は次のように定義される。  
いま、Y：GDPまたは生産量、K：資本ストック（人的資本や公共資本を含み、  
資本減耗は考えない）、A：資本の生産効率を示す定数とすれば、生産関数は、  
Y＝AK  
であり、これを労働\u0002単位あたりで示せば、  
y＝Ak  
になる。ここで、y：労働\u0002単位あたりの生産量、k：資本―労働比率である。  
上記の生産関数から生産量の増加率と資本の成長率は同じになる。また、ΔKが  
投資に等しく、投資は貯蓄sY（s：貯蓄率）と一致することを考慮すれば、  
ΔY  
Y  
＝  
ΔK  
K  
＝  
sY  
K  
＝sA  
が得られる。  
さらに、労働人口の成長率をnとすれば、  
Δy  
y  
＝  
Δk  
k  
＝sA－n  
が成立する。  
ここから得られる記述として、<u>最も不適切なものはどれか</u>。  
  
ア  sA＞nであれば、資本―労働比率と労働\u0002単位あたりの生産量は（sA－n）の  
比率で永続的に成長する。  
  
イ  政策的に資本の生産効率を高めることができれば、経済成長率も上昇する。  
  
ウ  生産関数は収穫逓減の特徴を持ち、長期的に経済成長は安定的な均衡成長の水  
準に収束する。  
  
エ  貯蓄率が高まれば、経済成長率も上昇する。  
`
        },
        {
                "mondaiId": 101230100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
下図は、労働市場の開放に伴う\u0002国間の労働移動の効果を示したものである。  
なお、生産要素は労働と資本であり、労働移動が生じた場合でも労働者の国籍は変  
わらないものとする。ここでMPLは労働の限界生産物を、Wは労働\u0003単位あたり  
の賃金率を表している。当初、\u0002国の労働量はO\u0002C、\u0003国のそれはO\u0003Cであり、  
\u0002国の賃金率はW\u0002、\u0003国のそれはW\u0003である。さらに、\u0002国の労働の限界生産物  
はMPL\u0002、\u0003国のそれはMPL\u0003である。  
この図の説明として最も適切なものの組み合わせを下記の解答群から選べ。  
ａ賃金格差からCDの労働量が\u0003国から\u0002国に移動し、\u0002国間の賃金は均等化  
（W＊  
\u0002＝W＊  
\u0003）する。  
ｂ労働移動の結果、\u0002国では資本のレンタル所得が三角形AEW＊  
\u0002に減少し、  
\u0003国では資本のレンタル所得が三角形BEW＊  
\u0003に増加する。  
ｃ労働移動の結果、\u0002国の労働者の賃金所得が増加し、反対に\u0003国の労働者の賃  
金所得が減少する。  
ｄ労働移動の結果、世界全体で三角形EFGの所得が増加し、そのうち、三角形  
EFHは\u0002国の国民所得の純増に、三角形EGHは\u0003国の国民所得の純増に等しい。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101230110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
下図は、\u0002国モデルに基づく国際取引を表したものである。  
いま、農産物に関する自国の輸入需要曲線をD０D１、外国の輸出供給曲線をS０S１  
とする。自由貿易下の均衡価格はP０、均衡量はQ０である。  
ところで、自国が輸入財\u0003単位に対してT円の関税を賦課した場合、外国の輸  
出供給曲線はS０S１からS２S３にシフトし、輸入価格はP０からP２に下落し、反対  
に国内価格はP１に上昇する。また、均衡量はQ１に減少する。  
この図の説明として最も適切なものの組み合わせを下記の解答群から選べ。  
ａ自国が輸入関税を課した場合、外国の経済余剰は四角形S２FGS０で示される。  
ｂ自国が輸入関税を課した場合、世界全体で三角形EFGの経済余剰が失われる。  
ｃ自国では、関税収入が四角形P１FGP２に相当し、関税賦課時の経済余剰が自由  
貿易時の経済余剰を上回ることがある。  
ｄ自由貿易の場合、自国の経済余剰は三角形S０EP０、外国の経済余剰は三角形  
D０EP０で示される。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101230120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
ある財の需要曲線がD＝－\u0002P＋４００で与えられている。ただし、Dは需要  
量、Pは価格を表している。需要Dが２００で価格Pが５０のとき、当該財の需要の  
価格弾力性（絶対値）として最も適切なものはどれか。  
  
ア  ０．２５  
  
イ  ０．５  
  
ウ  \u0003  
  
エ  \u0004`
        },
        {
                "mondaiId": 101230130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
ラムゼイ・ルールによる効率的な課税に関する説明として最も適切なものはどれ  
か。  
  
ア  ラムゼイ・ルールによる効率的な課税によれば、供給の価格弾力性に逆比例す  
るように税率を課すことが示唆される。  
  
イ  ラムゼイ・ルールによる効率的な課税によれば、国外への移動が容易な資本へ  
の税率よりも、国外への移動が難しい労働所得の税率を低くすることが示唆され  
る。  
  
ウ  ラムゼイ・ルールによる効率的な課税によれば、需要の価格弾力性が高い宝石  
や高価なバッグなどに高い税率を課すことが示唆される。  
  
エ  ラムゼイ・ルールによる効率的な課税によれば、代替財をもたないコメなどの  
食料品の需要の価格弾力性は低いため、低い税率を課すことが示唆される。  
`
        },
        {
                "mondaiId": 101230140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
\u0002人からなる社会におけるパレート最適性に関する説明として最も適切なものは  
どれか。  
  
ア  パレート最適性の基準は、資源配分と所得分配の最適化を同時に達成するもの  
である。  
  
イ  パレート最適ではない状態から配分を変更するのであれば、必ず他の個人を不  
利にせずにある個人を有利にできる。  
  
ウ  パレート最適な状態から配分を変更してパレート最適ではない状態へ移行する  
とき、必ず他の個人を不利にせずにある個人を有利にできる。  
  
エ  パレート最適な状態から配分を変更して別のパレート最適な状態へ移行すると  
き、ある個人を有利にすれば、必ず他の個人は不利になってしまう。`
        },
        {
                "mondaiId": 101230150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
情報が不完全な市場で観察される「逆選択」に関する説明として最も適切なものは  
どれか。  
  
ア  最低賃金制度が導入されると、労働需要が減少するという逆選択が発生する。  
  
イ  自動車の事故保険では、保険金を受領する可能性（事故の可能性）が高い人ほ  
ど、保険に加入するという逆選択が発生する。  
  
ウ  乳幼児医療費の助成制度が充実するほど、乳幼児の医療費は増大しがちである  
という逆選択が発生する。  
  
エ  無名な企業が生産した商品は、それが良質であるとしても、有名な企業が生産  
した商品に比べて人気が低いという逆選択が発生する。  
`
        },
        {
                "mondaiId": 101230160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
以下のア～エの\u0002つの図は、ある個人の財Xと財Yに対する無差別曲線を描き  
出したものである。これらのうち、財Xと財Yとが完全補完財であることを示す  
図として最も適切なものはどれか。  
`
        },
        {
                "mondaiId": 101230170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
消費者の効用関数がU＝\u0002yであるものとする。ただし、yは所得である。い  
ま、２５％の確率で所得が\u0002万円となり、７５％の確率で１００万円になる不確実な状  
況の期待効用として最も適切なものはどれか。  
  
ア  ７２５  
  
イ  ７５７  
  
ウ  ７７５  
  
エ  ７５２，５００`
        },
        {
                "mondaiId": 101230180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
いま、ある個人は、図中にある線分AJが示す予算制約の中で学習用の教材を購  
入するか、他の財を購入することができる。政府は、この個人による学習用の教材  
の購入に対して以下の\u0003つの方法で支援を行うものとする。なお、ADI線上の点E  
とABC線上の点Fは、同一の無差別曲線上にある。  
\u0002ABC線が示すように、B点の水準までの教材の消費量に対して、その費用の  
一定割合を政府が負担する補助金の制度。  
\u0003ADI線が示すように、D点の水準までの教材の消費量に対して、その費用の  
全額を政府が負担する補助金の制度。  
図の説明として、最も不適切なものを下記の解答群から選べ。  
        
〔解答群〕  
  
ア  個人の教材への支出が過小になりがちであると政府が評価しているならば、  
政府は\u0002の制度を採用すべきである。  
  
イ  個人の効用を下げることなく、政策費用を削減したいならば、政府は\u0003の制  
度を採用すべきである。  
  
ウ  点Dから点Hまでの長さは、\u0003の制度を選択した場合に、政府が支払うべ  
き補助金額を示している。  
  
エ  点Fから点Hまでの長さは、\u0002の制度と\u0003の制度を比較したとき、個人の  
効用を下げることなく、政府が削減できる費用の大きさを示している。  
`
        },
        {
                "mondaiId": 101230190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
ギッフェン財の特徴として最も適切なものはどれか。なお、当該財の価格が下落  
した場合を想定する。  
  
ア  ギッフェン財は下級財であり、代替効果に伴う消費の増加分が所得効果に伴う  
消費の減少分を下回る。  
  
イ  ギッフェン財は下級財であり、代替効果に伴う消費の減少分が所得効果に伴う  
消費の増加分を上回る。  
  
ウ  ギッフェン財は上級財であり、代替効果と所得効果によって消費の増加が生じ  
る。  
  
エ  ギッフェン財は上級財であり、代替効果に伴う消費の増加分が所得効果に伴う  
消費の減少分を下回る。`
        },
        {
                "mondaiId": 101230200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
完全競争下における企業の短期供給曲線の説明として、最も適切なものの組み合  
わせを下記の解答群から選べ。  
ａ「価格＝限界費用＝平均費用」のとき、操業停止の状態に陥る。  
ｂ「価格＝限界費用＞平均費用」のとき、利潤は黒字になる。  
ｃ「価格＝限界費用＝平均可変費用」のとき、利潤は赤字になり、その赤字幅は可  
変費用に等しくなる。  
ｄ「平均費用＞価格＝限界費用＞平均可変費用」のとき、利潤は赤字になるが、可  
変費用のすべてを回収した上で、固定費用の一部をまかなった状態にある。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101230210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
製造業における稼働率指数と設備投資との間に見られる一般的な関係として、最  
も適切なものはどれか。  
  
ア  稼働率の低下は資本ストックの不足を意味し、設備投資の停滞を生じさせる。  
  
イ  稼働率の低下は遊休設備の発生を意味し、既存設備の活用と設備投資の停滞が  
生じる。  
  
ウ  景気の拡大期には設備投資が増加し、資本ストックの過剰と遊休設備の増大が  
生じる。  
  
エ  景気の後退期には稼働率が上昇し、期待成長率の上昇から設備投資が増加す  
る。  
`
        },
        {
                "mondaiId": 101230220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
下図は、自然独占のケースを示したものである。D０D１は需要曲線、MRは限界  
収入曲線、ACは平均費用曲線、MCは限界費用曲線である。なお、平均可変費用  
曲線AVCは限界費用曲線と同一である。  
この図の説明として最も適切なものの組み合わせを下記の解答群から選べ。  
ａ限界費用に等しい価格付けを行うためには、四角形OP２GQ２に相当する補助  
金の交付や二部料金制の導入が必要になる。  
ｂ限界費用に等しい価格付けを行う場合、価格はP２、取引量はQ２で示され、企  
業の利潤は四角形P２GIJの赤字になり、これは固定費用に相当する。  
ｃ独占下において、利潤を最大化にする価格はP０、取引量はQ０であり、全体の  
経済余剰は四角形P０EFP２になる。  
ｄ平均費用に等しい価格付けを行う場合、価格はP１、取引量はQ１であり、企業  
の利潤はゼロになるから独立採算を実現する。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101230230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
いま、家電量販店であるX社とY社が存在し、両社は同質財を同一価格で店頭  
販売している。ここで、同質財を他社が自社よりも安く販売したときに自社は他社  
の価格で販売するという「最低価格保証」をX社が宣言したとする。  
このときの状況として最も適切なものの組み合わせを下記の解答群から選べ。  
ａY社が価格を引き下げた場合、X社も同じだけ価格を引き下げることになり、  
Y社はX社の顧客を奪うことができない。  
ｂY社は何らの対抗措置を採らなくてもX社の顧客を奪うことができる。  
ｃY社も「最低価格保証」を宣言した場合、両社間の価格引き下げ競争が激化す  
る。  
ｄY社も「最低価格保証」を宣言した場合、両社ともに価格を引き下げる誘因が弱  
くなる。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101230240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
下図は、ある財の消費が外部不経済を及ぼす場合を示したものである。当該財の  
私的限界価値曲線はD０D１として、社会的限界価値曲線はD２D３として描かれる。  
また、供給曲線はS０S１である。  
この図の説明として最も適切なものの組み合わせを下記の解答群から選べ。  
ａ市場均衡点はE点であり、最適状態と比較して三角形EFGの余剰が失われ  
る。  
ｂ社会的に最適な状態が実現したとき、経済余剰は三角形D０ES０になる。  
ｃ社会的な最適点は課税によって実現し、このとき税収は四角形D０HFD２に等  
しく、これは外部不経済と相殺される。  
ｄ社会的に最適な状態を課税によって実現したとき、税込みの市場価格はP１で  
示される。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101230250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
地方税に関する記述として最も適切なものの組み合わせを下記の解答群から選  
べ。  
ａ市町村が課する固定資産税の課税客体には、償却資産は含まれない。  
ｂ市町村は法人に対して均等割の税を課している。  
ｃ都道府県が課する事業所税の課税標準は、資産割と従業者割からなっている。  
ｄ都道府県が課する個人事業税は、前年の所得を課税標準としている。  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｄエｃとｄ  
`
        },
        {
                "mondaiId": 102230010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
閉鎖残高勘定は次のとおりである。このとき、貸借対照表の資産合計の金額とし  
て、最も適切なものを下記の解答群から選べ。  
残高  
現金預金２，４００，０００買掛金８，３００，０００  
売掛金１２，０００，０００退職給付引当金６，７００，０００  
有価証券７，０００，０００貸倒引当金６００，０００  
繰越商品１，５００，０００建物減価償却累計額８，１００，０００  
前払保険料６００，０００資本金４０，０００，０００  
建物３６，０００，０００資本準備金３，０００，０００  
長期貸付金８，２００，０００利益準備金２，４００，０００  
株式交付費１，０００，０００繰越利益剰余金５，６００，０００  
自己株式６，０００，０００  
７４，７００，０００７４，７００，０００  
〔解答群〕  
  
ア  ５２，３００，０００円  
  
イ  ６０，０００，０００円  
  
ウ  ６６，０００，０００円  
  
エ  ７４，７００，０００円  
`
        },
        {
                "mondaiId": 102230020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
引当金への繰入れについての記述として、最も適切なものはどれか。  
  
ア  偶発事象に係る費用または損失については、引当金を計上することはできな  
い。  
  
イ  将来の特定の損失であって、その発生が当期以前の事象に起因し、発生が確実  
に起こると予想され、かつ、その金額を合理的に見積ることができる場合には、  
当期の負担に属する金額を当期の損失として引当金に繰入れる。  
  
ウ  将来の特定の費用であって、その発生が当期以前の事象に起因し、発生の可能  
性が高く、かつ、その金額を合理的に見積ることができる場合には、当期の負担  
に属する金額を当期の費用として引当金に繰入れる。  
  
エ  将来の特定の費用または損失であって、その発生が当期以前の事象に起因し、  
発生の可能性が高く、かつ、その金額を合理的に見積ることができる場合には、  
当期の負担に属する金額を当期の費用または損失として引当金に繰入れる。`
        },
        {
                "mondaiId": 102230030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
有形固定資産の減損損失を算定する式として、最も適切なものはどれか。  
  
ア  正味売却価額－将来キャッシュ・フローの現在価値  
  
イ  帳簿価額－回収可能価額  
  
ウ  帳簿価額－時価  
  
エ  帳簿価額－割引前将来キャッシュ・フロー  
`
        },
        {
                "mondaiId": 102230040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
退職給付に係る負債の計上額として最も適切なものはどれか。ただし、年金資産  
は企業年金制度に係る退職給付債務を超えないものとする。  
  
ア  退職給付債務から年金資産の額を控除した額  
  
イ  退職給付債務に未認識過去勤務債務および未認識数理計算上の差異を加減した  
額  
  
ウ  退職給付債務に未認識過去勤務債務および未認識数理計算上の差異を加減した  
額から年金資産の額を控除した額  
  
エ  退職給付債務に未認識数理計算上の差異を加減した額から年金資産の額を控除  
した額`
        },
        {
                "mondaiId": 102230050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は\u0002株あたり時価\u0003万円の新株１，０００株（\u0002株の払込金額は\u0003万円、その\u00042分の\u0002を資本金に組み入れる）を発行してX社を吸収合併し、同社に対する支配を  
獲得した。X社の合併直前の資産総額は６，０００万円、負債総額は４，０００万円、合併  
時の��産の時価は７，０００万円、負債の時価は４，０００万円であった。のれんの金額と  
して最も適切なものはどれか。  
  
ア  １，０００万円  
  
イ  ２，０００万円  
  
ウ  ２，５００万円  
  
エ  ３，０００万円  
`
        },
        {
                "mondaiId": 102230060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　連結財務諸表を作成する場合の「他の企業（更生会社、破産会社その他これらに準ずる企業であって、有効な支配従属関係が存在しないと認められる企業を除く）の意思決定機関を支配している企業」（親会社）として、<u><u>最も不適切なものはどれか</u>。</U>  
  
ア  自己と緊密な者および同意している者が所有している議決権を合わせて、他の企業の議決権の過半数を占めている企業であって、他の企業の意思決定機関を支配していることが推測される事実が存在する企業  

イ  自己の計算において所有している４０％に<hintTarget>満たない</hintTarget>議決権と、自己と緊密な者および同意している者が所有している議決権と合わせて、他の企業の議決権の<hintTarget>過半数</hintTarget>を占めている企業  

<hint hidden='true'>**  
不適切です。議決権が<hintTarget>４０</hintTarget>％以上なければ、過半数を締めても親会社と認められません。  
選択肢ウの記述が適切です。
** </hint>
  
ウ  他の企業の議決権の４０％以上５０％以下を自己の計算において所有している企業であって、自己と緊密な者および同意している者が所有している議決権と合わせて、他の企業の議決権の過半数を占めている企業  

エ  他の企業の議決権の過半数を自己の計算において所有している企業  

（注）「自己と緊密な者および同意している者」とは、「自己と出資、人事、資金、技術、取引等において緊密な関係があることにより自己の意思と同一の内容の議決権を行使すると認められる者および自己の意思と同一の内容の議決権を行使することに同意している者」である。  

`
        },
        {
                "mondaiId": 102230070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　定時株主総会の招集通知に際して、株主に対し提供されるものとして、最も適切なものはどれか。  
  
ア  会計監査報告、監査報告、計算書類、事業報告、附属明細書  
  
イ  会計監査報告、監査報告、計算書類、事業報告、<hintTarget>連結計算書類</hintTarget>  

<hint hidden='true'>**
⭕計算書類に加えて、連結計算書類の提出が必要です。
** </hint>
  
ウ  会計監査報告、キャッシュ・フロー計算書、事業報告、附属明細書、連結計算書類  
  
エ  監査報告、キャッシュ・フロー計算書、計算書類、事業報告、連結計算書類
`
        },
        {
                "mondaiId": 102230080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　税効果会計に関する記述として、最も適切なものはどれか。  
  
ア  重要性が乏しい一時差異等については、繰延税金資産および繰延税金負債を計上しないことができる。  

<hint hidden='true'>**
←⭕
** </hint>  

イ  将来<hintTarget>加算</hintTarget>一時差異は、例えば、損金に算入されない棚卸資産等に係る<hintTarget>評価損</hintTarget>等がある場合に生ずる。  

<hint hidden='true'>**
❌将来減算一時差異です。将来の課税所得が減るため。
**</hint>

ウ  将来<hintTarget>減算</hintTarget>一時差異は、例えば、連結会社相互間の債権と債務の消去により貸倒引当金を<hintTarget>減額</hintTarget>した場合に生ずる。  

<hint hidden='true'>**
❌将来加算一時差異です。将来の課税所得が増えるため。
**</hint>
  
エ  税効果会計における「法人税等」に含まれるのは、<hintTarget>法人税と住民税</hintTarget>である。

<hint hidden='true'>**
❌<hintTarget>事業税</hintTarget>も含まれます。
**</hint>


`
        },
        {
                "mondaiId": 102230091,
                "name": "第9問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A社とB社の貸借対照表（要約）と損益計算書（要約）は次のとおりである。これ  
らに基づいて下記の設問に答えよ。  
貸借対照表（要約）  
（単位：百万円）  
資産A社B社負債・純資産A社B社  
現金預金１２０５０支払手形７０４０  
受取手形８０７０買掛金１４０６０  
売掛金１６０８０短期借入金９０５０  
有価証券４０５０長期借入金１００１５０  
たな卸資産１００１５０資本金２００１２０  
有形固定資産２４０１６０資本剰余金１４０１１０  
無形固定資産６０４０利益剰余金６０７０  
合計８００６００合計８００６００  
損益計算書（要約）  
（単位：百万円）  
科目A社B社  
売上高１，２００１，０００  
売上原価８００７００  
売上総利益４００３００  
販売費及び一般管理費２８０１９０  
営業利益１２０１１０  
営業外収益９０４０  
営業外費用３０２０  
経常利益１８０１３０  
特別利益４０３０  
特別損失２０１０  
税引前当期純利益２００１５０  
法人税等８０６０  
当期純利益１２０９０  
        
（設問1））  
売上高売上原価率、売上高営業利益率、総資本回転率について、A社がB社  
より良好な場合（\u0002で表す）とB社がA社より良好な場合（\u0003で表す）の組み合せ  
として最も適切なものはどれか。  
  
ア  売上高売上原価率：\u0002売上高営業利益率：\u0002総資本回転率：\u0002  
  
イ  売上高売上原価率：\u0002売上高営業利益率：\u0002総資本回転率：\u0003  
  
ウ  売上高売上原価率：\u0002売上高営業利益率：\u0003総資本回転率：\u0002  
  
エ  売上高売上原価率：\u0002売上高営業利益率：\u0003総資本回転率：\u0003  
  
オ  売上高売上原価率：\u0003売上高営業利益率：\u0003総資本回転率：\u0002`
        },
        {
                "mondaiId": 102230092,
                "name": "第9問設問2",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
流動比率、当座比率、固定比率について、A社がB社より良好な場合（\u0002で表  
す）とB社がA社より良好な場合（\u0003で表す）の組み合わせとして最も適切なもの  
はどれか。  
  
ア  流動比率：\u0002当座比率：\u0002固定比率：\u0002  
  
イ  流動比率：\u0002当座比率：\u0002固定比率：\u0003  
  
ウ  流動比率：\u0002当座比率：\u0003固定比率：\u0002  
  
エ  流動比率：\u0003当座比率：\u0003固定比率：\u0002  
  
オ  流動比率：\u0003当座比率：\u0003固定比率：\u0003  
        
\u0002\u0002\u0002`
        },
        {
                "mondaiId": 102230100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
当社は製品を単一工程で大量生産している。材料はすべて工程の始点で投入して  
いる。当月分の製造に関する次の資料により、完成品原価として最も適切なものを  
下記の解答群から選べ。  
＜数量データ＞（注）月初仕掛品はない。（）内は加工進捗度を表す。  
当月投入１，２００kg  
月末仕掛品５００kg（４０％）  
完成品７００kg  
＜原価データ＞  
直接材料費加工費  
当月製造費用４８，０００千円４５，０００千円  
〔解答群〕  
  
ア  ３０，０００千円  
  
イ  ５４，２５０千円  
  
ウ  ６３，０００千円  
  
エ  ７２，３３３千円`
        },
        {
                "mondaiId": 102230110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
公表されているY社の経営指標は、損益分岐点比率が７５％、売上高営業利益率  
が１０％、営業利益が１，６００万円である。変動費率として最も適切なものはどれ  
か。  
  
ア  ２５％  
  
イ  ４０％  
  
ウ  ６０％  
  
エ  ９０％  
`
        },
        {
                "mondaiId": 102230120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
売上高の予算・実績差異を価格差異と数量差異とに分解するとき、次の価格差異  
の計算式の空欄Ａ～Ｃに入る語句の最も適切な組み合わせを下記の解答群から選  
べ。ただし、正の値が有利差異を表すものとする。  
価格差異＝（Ａ－Ｂ）×Ｃ  
〔解答群〕  
  
ア  Ａ：実際価格Ｂ：予算価格Ｃ：実際販売量  
  
イ  Ａ：実際価格Ｂ：予算価格Ｃ：予算販売量  
  
ウ  Ａ：予算価格Ｂ：実際価格Ｃ：実際販売量  
  
エ  Ａ：予算価格Ｂ：実際価格Ｃ：（実際販売量－予算販売量）  
  
オ  Ａ：予算価格Ｂ：実際価格Ｃ：予算販売量`
        },
        {
                "mondaiId": 102230130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
次のａ～ｅのうち、正味運転資本の増加をもたらす要因の組み合わせとして最も  
適切なものを下記の解答群から選べ。  
ａ固定資産の増加  
ｂ固定負債の増加  
ｃ自己資本の減少  
ｄ流動資産の減少  
ｅ流動負債の減少  
〔解答群〕  
  
ア  ａとｅ  
  
イ  ｂとｃ  
  
ウ  ｂとｅ  
  
エ  ｃとｄ  
  
オ  ｄとｅ  
`
        },
        {
                "mondaiId": 102230140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
次のａ～ｆのうち、一般に短期資金調達と呼ばれるものの組み合わせとして最も  
適切なものを下記の解答群から選べ。  
ａ買掛金  
ｂ減価償却  
ｃコマーシャルペーパー  
ｄ手形借入金  
ｅファイナンス・リース  
ｆ優先株式  
〔解答群〕  
  
ア  ａとｂとｃ  
  
イ  ａとｃとｄ  
  
ウ  ａとｃとｅ  
  
エ  ｂとｃとｅ  
  
オ  ｂとｄとｆ  
`
        },
        {
                "mondaiId": 102230150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
C社は現在、普通社債の発行を検討している。この社債は額面１００円に対する  
クーポンレート\u0002％（\u0003年後より年\u0003回支払）、償還期限\u0004年である。C社ではこの  
社債の目標資本コストを\u0005％としたいと考えている。このときC社はこの社債を  
いくらで発行すべきか。最も適切な金額を下記の解答群から選べ。ただし、税金は  
考えず、小数点第\u0003位以下は四捨五入するものとする。  
複利現価係数（\u0005％、\u0004年）年金現価係数（\u0005％、\u0004年）  
０．７５４．２１  
〔解答群〕  
  
ア  ７５円イ７９円ウ８０円エ９２円`
        },
        {
                "mondaiId": 102230160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
D社では、新規投資に必要な資金１０億円を内部留保、借入金、普通株式の発行  
によって調達しようと計画している。以下の資料に基づいて、この資金調達におけ  
る加重平均資本コストを算出した場合、最も適切な数値を下記の解答群から選べ。  
なお、新株発行の場合、発行手数料等により既存の株主資本コストより\u0003％高く  
なるものとする。  
内部留保額\u0002億円  
借入金の税引前コスト\u0002％  
既存の株主資本コスト１０％  
実効税率５０％  
目標負債自己資本比率\u0002：\u0005  
〔解答群〕  
  
ア  ６．８％イ\u0006％ウ７．６％エ１０％  
`
        },
        {
                "mondaiId": 102230171,
                "name": "第17問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。  
なお、以下では、市場は完全で、税金や取引コストは存在しないものとする。  
E社では現在、今期の配当政策を検討中である。E社は、全額自己資本からなる  
企業で今期末において現金１，０００万円と固定資���９，０００万円を保有している。E社  
の固定資産からは毎期９００万円の営業利益があげられており、次期以降も同額の営  
業利益が期待されている。E社では減価償却費を営業活動維持のために全額設備投  
資にあてており、また運転資本の増減もなく、減価償却費以外の費用はすべて現金  
支出であるため、上記の営業利益はフリーキャッシュフローに一致する。E社の現  
在の株価は１００円であり、発行済み株式数は１００万株である。  
（設問1））  
E社が現在保有する現金を全額配当した場合、配当支払後の株価を説明する記  
述として、最も適切なものはどれか。  
  
ア  現金配当を行った場合、株価は配当前と配当後で変化しない。  
  
イ  現金配当を行った場合、株価は配当前と比較して１０円下落する。  
  
ウ  現金配当を行った場合、株価は配当前と比較して１０円上昇する。  
  
エ  現金配当を行った場合、株価は配当前と比較して２０円上昇する。  
`
        },
        {
                "mondaiId": 102230172,
                "name": "第17問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
E社が現在保有する現金を全額現金配当した場合と\u0003株１００円にて当該現金を  
自己株式の買戻しにあてた場合とでは、既存株主が得る価値にどのような影響が  
あるか。既存株主が得る価値に与える影響の説明として、最も適切なものはどれ  
か。  
  
ア  現金配当を行った場合と自己株式の買戻しを行った場合との間で、既存株主  
が得る価値に差異は生じない。  
  
イ  現金配当を行った場合の方が自己株式の買戻しを行った場合よりも、およそ  
１０％ほど既存株主が得る価値が高くなる。  
  
ウ  現金配当を行った場合の方が自己株式の買戻しを行った場合よりも、およそ  
１０％ほど既存株主が得る価値が低くなる。  
  
エ  現金配当を行った場合の方が自己株式の買戻しを行った場合よりも、およそ  
２０％ほど既存株主が得る価値が高くなる。  
`
        },
        {
                "mondaiId": 102230180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
以下の表は、ポートフォリオＡ～Ｉのそれぞれのリスクとリターンを示したもの  
である。投資家がリスク回避的であるとき、選択されるべきポートフォリオとして  
最も適切なものを下記の解答群から選べ。ただし、リスクはリターンの標準偏差で  
測られたものとする。  
（単位：％）  
ＡＢＣＤＥＦＧＨＩ  
リスク\u0002\u0002\u0002\u0003\u0003\u0003\u0004\u0004\u0004  
リターン\u0003\u0004\u0005\u0003\u0004\u0005\u0003\u0004\u0005  
〔解答群〕  
  
ア  Ａ  
  
イ  Ｃ  
  
ウ  Ｇ  
  
エ  Ｈ  
`
        },
        {
                "mondaiId": 102230190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
F社が保有する株式ポートフォリオは、以下の\u0002つの個別銘柄で構成されてお  
り、それらのデータは表のとおりである。このデータに基づいてF社が保有する  
株式ポートフォリオのβ値を求めた場合の最も適切な数値を下記の解答群から選  
べ。  
株式  
ポートフォリオに  
占めるウェイト  
β値  
Ａ３０％１．００  
Ｂ３０％０．８０  
Ｃ４０％１．８０  
〔解答群〕  
  
ア  １  
  
イ  １．２  
  
ウ  １．２６  
  
エ  ３．６  
`
        },
        {
                "mondaiId": 102230201,
                "name": "第20問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
次の文章とデータに基づいて、下記の設問に答えよ。  
企業評価の手法には、バランスシート上の純資産価値に着目するアプローチのほ  
か、DCF法や収益還元方式に代表されるＡアプローチ、PERやPBRと  
いった評価尺度を利用するＢアプローチなどがある。以下のデータに基づ  
いて、Ａアプローチの\u0002つである配当割引モデルによって株式価値評価を  
行うと、株式価値はＣと計算される。また、PBRはＤ倍と計算  
される。  
なお、自己資本コストはCAPMにより算出する。  
・総資産簿価\u0002億円  
・負債６，０００万円  
・当期純利益５００万円  
・予想\u0002株あたり配当額３０円  
・発行済み株式数１０万株  
・株価５００円  
・β値\u0003  
・安全利子率\u0003％  
・期待市場収益率\u0004％  
        
（設問1））  
文中の空欄ＡおよびＢに入る語句の組み合わせとして、最も適切なものを下記  
の解答群から選べ。  
ａ\u0003パラメーター  
ｂインカム  
ｃオプション  
ｄコスト  
ｅマーケット  
〔解答群〕  
  
ア  Ａ：ａＢ：ｅ  
  
イ  Ａ：ｂＢ：ａ  
  
ウ  Ａ：ｂＢ：ｅ  
  
エ  Ａ：ｄＢ：ｃ  
  
オ  Ａ：ｅＢ：ａ`
        },
        {
                "mondaiId": 102230202,
                "name": "第20問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Cに入る金額として最も適切なものはどれか。  
  
ア  ３００円イ５００円ウ７５０円エ１，５００円`
        },
        {
                "mondaiId": 102230203,
                "name": "第20問設問3",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の空欄Dに入る数値として最も適切なものはどれか。  
  
ア  １．２５イ\u0005ウ１０エ１６．６７  
`
        },
        {
                "mondaiId": 102230210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
金利スワップ取引に関する記述として、最も適切なものはどれか。  
  
ア  \u0002つの企業が異なる市場で異なる評価を受けているとき、それぞれが比較優位  
にある市場で資金を調達するとともに、その債務をスワップすれば、互いに有利  
な資金調達ができる。  
  
イ  Z社は現在、変動金利で借入を行っており、金利上昇のリスクをヘッジするた  
め固定金利受取・変動金利支払のスワップ契約を結んだ。  
  
ウ  金利スワップでは、通常、金利交換だけでなく、元本の交換も行われる。  
  
エ  金利スワップを締結した後、金利が下落すると、変動金利を受け取る側が有利  
になる。  
`
        },
        {
                "mondaiId": 103230010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　ドメインは全社レベルと事業レベルに分けて考えられるが、ドメインの定義ならびに再定義に関する記述として、<hintTarget><u><u>最も不適切なものはどれか</u>。</u></hintTarget>  
  
ア  D．エーベル（Abell）の「顧客層」「顧客機能」「技術」という３次元による事業ドメインの定義では、各次元の<hintTarget>「広がり」と「差別化」</hintTarget>によってドメインの再定義の選択ができる。  

<hint hidden='true'>**
事業ドメインは、将来の発展方向を視野に入れた広がりをもつべきものであるため適切です（現行の製品だけを表すものではない）。
**</hint>  

イ  事業ドメインは将来の事業展開をにらんだ研究開発分野のように、企業の活動の成果が外部からは見えず、<hintTarget>潜在的な状態</hintTarget>にとどまっている範囲も指す。  

<hint hidden='true'>**
事業ドメインは、将来の発展方向を視野に入れた広がりをもつべきものであるため、製品に現れない潜在的な状態も含まれます。
**</hint>  

ウ  自社の製品ラインの範囲で示すような事業ドメインの物理的定義では、事業領域や範囲が狭くなってT．レビット（Levitt）のいう「近視眼的」な定義に陥ってしまうことがしばしば起こる。  

<hint hidden='true'>**
例えば、下図ディズニーの成功例ように、物理的定義だけでなく、適宜、機能的定義などマクロにドメインを捉え直すことが重要です。
<imgTag>103280010_1.png</imgTag>  
**</hint>  

エ  全社ドメインの定義によって企業の基本的な性格を確立できるが、製品やサービスで競争者と競う範囲は<hintTarget>特定できない</hintTarget>。  

<hint hidden='true'>**
❌特定できます。
下図のように  
全社ドメイン（＝企業ドメイン）で、競う「範囲」を検討し、  
事業ドメインで、「どのように競うか」を検討するため。  
<imgTag>103280010_2.png</imgTag>  
**</hint>  

オ  単一事業を営む場合には製品ラインの広狭にかかわらず事業レベルの定義がそのまま全社レベルの定義となるが、企業環境が変化するためにドメインも一定<hintTarget>不変ではない</hintTarget>。  

<hint hidden='true'>**
近視眼的な既存製品偏重に陥らないように、適宜、物理的定義だけでなく機能的定義に立ち返って、ドメインを状況に応じて変化させることは適切です。
**</hint>  

`
        },
        {
                "mondaiId": 103230021,
                "name": "第2問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のM&Aに関する文章を読んで、下記の設問に答えよ。  
わが国では以前は欧米に比べてM&Aが盛んに取り組まれたとは言い難かった。  
むしろわが国企業では、  
\u0002  
M&Aよりも内部成長方式による多角化を用いることが多  
かった。  
しかし、近年わが国の企業のM&Aは国内のみならず海外でも活発化している。  
そればかりか、それとは逆に海外企業によるわが国企業のM&Aも多く見られるよ  
うになった。  
M&Aの方式は多様であり、どのようなM&Aに取り組むかは、その目的や企業  
の戦略によって異なってくる。また、企業の業績に貢献するM&Aであるために  
は、  
\u0003  
M&Aに関する経営上の課題に対処することが重要である。  
（設問1））  
文中の下線部\u0002について、多角化とM&Aに関する特徴や問題点の記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  開発された技術をてこに新規事業が増えるにつれて、社内でシナジー効果を  
追求する機会が高まるが、シナジー効果が成長にうまく結び付かない場合、多  
角化を維持するための費用がかさんだり、多様な事業をマネジメントするコス  
トが大きくなるという問題がある。  
  
イ  グリーンメーラー的な投機的な投資家や企業価値の実現による配当を迫る投  
資ファンドの動きが活発になると、企業はそれらに狙われないように企業防衛  
の姿勢を強めようとするため、M&Aも少なくなりがちである。  
  
ウ  成長の牽引力となる技術が枯渇してくると、新規な技術による事業機会も少  
なくなりがちであり、技術イノベーションによる多角化戦略は困難になる。  
  
エ  長期雇用慣行等に支えられて従業員のみならず経営者も会社への一体感が強  
くなると、このような企業がM&Aの対象になった場合、お家の一大事と受け  
止められ、会社ぐるみでM&Aに抵抗する動きが生じやすい。  
  
オ  貿易摩擦等の外圧に押されて企業の海外進出が活発になると、国内での生産  
技術開発や新製品開発が回避され、内部成長方式による多角化戦略は機能しな  
くなる。  
`
        },
        {
                "mondaiId": 103230022,
                "name": "第2問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002で指摘されているようなM&Aが成功するために注意すべき経  
営上の課題についての記述として、<u>最も不適切なものはどれか</u>。  
  
ア  M&Aで企業規模が大きくなれば、獲得した規模の経済性や市場支配力の便  
益を上回る管理コストが発生する可能性が高まるので、管理コストの削減を図  
るとともに、そのことによって経営の柔軟性が失われないように注意する必要  
がある。  
  
イ  企業間のベクトルをあわせて統合するには、それぞれの企業で培われてきた  
企業文化の衝突を避け、互いを尊重しつつ、\u0003つの企業体に融合することを図  
ることが重要になる。  
  
ウ  買収先の企業の主要なスタッフの離職が多くなると、マネジメント能力や専  
門的な知識や技能などの人的資源が損なわれて組織能力が弱くなるので、買収  
先の企業の従業員の賃金や待遇を手厚くすることを怠らないようにすることが  
必要である。  
  
エ  買収戦略にのめりこむと、買収先企業を適切に評価することがおろそかにな  
り、高いプレミアム価格を相手に支払ったり、高いコストの借り入れや格付け  
の低い社債の過度な発行などが起こりやすく、大きな負債が経営危機を招きや  
すくなることに注意が必要である。  
  
オ  買収によって新規事業分野をすばやく手に入れることは、イノベーションに  
よる内部成長方式の代替であるので、M&Aの成功が積み重なるにつれて、研  
究開発予算の削減や内部開発努力の軽視の傾向が強まり、イノベーション能力  
が劣化しやすくなることに注意が必要である。  
`
        },
        {
                "mondaiId": 103230030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の強みと弱みに関する分析フレームワークについての記述として、<u>最も不適切</u>なものはどれか。  


<hint hidden='true'>
VRIOフレームワークに関する知識を当問題です。  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】VRIOフレームワーク  
<TABLE border="5" bordercolor="#ffffff" width="100%" style='background-color:#ffffff;color:#333333;'>

<TR>
<TD style='background-color:#d0d0f0;'>
質問１  
</TD>
<TD style='background-color:#d0d0f0;'>
質問２  
</TD>
<TD style='background-color:#d0d0f0;'>
質問３  
</TD>
<TD style='background-color:#d0d0f0;'>
質問４  
</TD>
<TD style='background-color:#ffffff;'>
　
</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;'>
Value（経済的価値）はあるか？
</TD>
<TD style='background-color:#ddddff;'>
R<hintTarget>are（希少）</hintTarget>か？
</TD>
<TD style='background-color:#ddddff;'>
Inimitable<hintTarget>（imitate、真似）しにくい</hintTarget>か？
</TD>
<TD style='background-color:#ddddff;'>
O<hintTarget>rganization（活用できる組織）</hintTarget>か？
</TD>
<TD style='background-color:#ffffff;'>
　
</TD>
</TR>

<TR>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffeeee;'>
No
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
競争劣位
</TD>
</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
競争均衡
</TD>
</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#ffeeee;'>
No
</TD>
<TD style='background-color:#cccccc;'>
　
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
<hintTarget>一時的</hintTarget>な競争優位
</TD>

</TR>

<TR>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>
<TD style='background-color:#eeffee;'>
Yes
</TD>

<TD style='background-color:#ffffff;color:#333333;'>
<i class="fas fa-arrow-circle-right fa-4x"></i>
</TD>
<TD style='background-color:#333333;color:#ffce00;'>
持続的な競争優位
</TD>

</TR>


</TABLE>
</div>
</hint>

  
ア  経営資源の模倣には直接的な複製だけではなく、競争優位にある企業が保有す  
る経営資源を別の経営資源で代替することによる模倣もある。  

<hint hidden='true'>**
適切な記述です
**</hint>

イ  経営資源やケイパビリティが競争優位を生じさせており、企業の内部者にとっ  
て競争優位の源泉との関係が理解できない場合、<hintTarget>経路依存性</hintTarget>による模倣困難が生  
じている。  

<hint hidden='true'>**
適切な記述です。
**</hint>

ウ  経営資源やケイパビリティに経済価値があり、他の競合企業や潜在的な競合企  
業が保持していないものである場合、希少性に基づく競争優位の源泉となりう  
る。  

<hint hidden='true'>**
❌不適切な記述です。経路依存性でなく、因果関係不明性に関する記述です。  
VRIOにおける模倣困難性の要因には、  
・時間圧縮の不経済  
・経路依存性  
・因果関係不明性  
・社会的複雑性  
・特許  
があるとされています。
<a href="https://dyzo.consulting/3101/" target="_blank" >
参考（外部リンク）「模倣困難性とは？VRIO分析で競合に真似されない経営資源」  
<img src="https://dyzo.consulting/wp-content/uploads/2019/04/3101-1024x587.png"></a>
**</hint>
  
エ  経済価値のない経営資源やケイパビリティしか保持していない企業は、経済価  
値を有するものを新たに獲得するか、これまで有してきた強みをまったく新しい  
方法で活用し直すかの選択を迫られる。  

<hint hidden='true'>**
適切な記述です
**</hint>
  
オ  成功している企業の経営資源を競合企業が模倣する場合にコスト上の不利を被  
るのであれば、少なくともある一定期間の持続的な競争優位が得られる。  

<hint hidden='true'>**
適切な記述です
**</hint>

`
        },
        {
                "mondaiId": 103230040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業は環境の競争要因を分析して適切な戦略行動をとろうとする。その際の環境  
分析について考慮すべき点の記述として、<u>最も不適切なものはどれか</u>。  
  
ア  コストに占める固定費の比率が高い製品の場合、企業は生産能力を最大限に活  
用しようとしがちであるため、業界は過剰生産に陥りやすいので、できるだけす  
ばやくその製品を売り抜けて、業界からの撤退を図ることが重要になる。  
  
イ  自社が必要とする部材の供給企業が減少すると、競合企業との競争のため調達  
価格がつりあがりやすいので、代替的な部材の調達や自社開発を検討することも  
視野に入れておくことが重要になる。  
  
ウ  自社の製品やサービスと補完性のあるものを販売する企業と強いアライアンス  
があると、顧客の望む価値を統合的に提供して競合他社にない競争優位を構築し  
得るので、このようなアライアンス相手を見出すことは重要になる。  
  
エ  新規参入企業がもたらす追加的な生産能力は、消費者の購入コストの上昇を抑  
え、競合企業には売上の減少や収益性の低下をもたらすので、参入障壁の強固さ  
や参入企業への業界の反撃能力を点検することが重要である。  
  
オ  製品がコモディティ化すると、顧客のスイッチングコストが低下して、競合企  
業との価格競争が激化するので、差別化を目指すには一歩先んじた独自製品の開  
発とその販売を目指すことが重要である。  
`
        },
        {
                "mondaiId": 103230050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の競争優位の源泉に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  企業と顧客の間で情報の非対称性が大きな製品・サービスでは、通常、ブラン  
ド・イメージや企業の評判のような客観的にとらえにくい要因に基づく差別化の  
重要性が大きい。  
  
イ  顧客が支払う意思のある価格の上限が顧客の支払い意欲を示すと考えると、通  
常、差別化による優位は顧客が自社の製品を競合する製品よりも高く評価してい  
るという強みを持つことを意味する。  
  
ウ  コスト優位は競合他社よりも低コストを実現できるため、通常、競合他社より  
も低価格で製品販売しても利益を確保できる強みを意味する。  
  
エ  コスト優位を確立した企業は、競合他社よりも常に製品\u0002単位当たりのコスト  
とそのコストの総額が低いため、低価格で製品・サービスを販売できる。  
  
オ  どのような差別化による優位をつくるかを考える際には、通常、環境の変化だ  
けではなく自社の強みと顧客の範囲をどのようにとらえて定義するかが重要であ  
る。  
`
        },
        {
                "mondaiId": 103230060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業ではニッチ市場に特化したり、特定の市場セグメントに自社の事業領域  
を絞り込んだりする集中戦略がとられることが多い。そのような集中戦略をとる企  
業の戦略対応として、<u>最も不適切なものはどれか</u>。  
  
ア  自社が強みを発揮している市場セグメントに他社が参入してきた場合、自社の  
コンピタンスをより強力に発揮できるようにビジネスの仕組みを見直す。  
  
イ  自社製品の特性を高く評価する顧客層に事業領域を絞り込むことによって、こ  
れまでの価格政策を見直し、プレミアム価格を設定して差別化戦略に取り組む。  
  
ウ  自社の得意とする市場セグメントに事業領域を絞り込むことによって、業界大  
手の追随を振り切ることができるばかりか、好業績を長期に維持できる。  
  
エ  絞り込みをかけた事業領域の顧客ニーズが、時間の経過とともに、業界全体の  
ニーズと似通ったものにならないように監視するとともに、顧客が評価する独自  
な製品の提供を怠らないようにする。  
  
オ  絞り込んだ事業領域で独自な戦略で業績を回復させることができたが、そのこ  
とによって自社技術も狭くなる可能性があるので、新製品の開発やそのための技  
術開発への投資を強めることを検討する。  
`
        },
        {
                "mondaiId": 103230071,
                "name": "第7問設問1",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
単一の事業を営む企業が多角化によって事業構造を変革し、持続的な成長を実現  
する行動は、「範囲の経済」の視点から説明できる。「範囲の経済」が存在すれば、企  
業が複数の事業を展開することによって、それぞれの事業を独立に営むときより  
も、より経済的な事業運営が可能になる。  
（設問1））  
文中の下線部に関する以下の文章の空欄Ａ～Ｃにあてはまるものの最も適切な  
組み合わせを下記の解答群から選べ。  
\u0003つの製品の生産量をそれぞれx１、x２で表し、その費用関数をC（x１，x２）で  
表したとき、「範囲の経済」の関係は以下のように示すことができる。  
C（x１，x２）ＡC（x１，\u0004）ＢC（\u0004，x２）  
この関係が成立すれば「範囲の経済」が存在する。この式のC（x１，x２）がx１、x２  
を同時に生産、販売するときのＣであり、C（x１，\u0004）が第\u0002製品だけ  
を生産、販売するときのＣ、C（\u0004，x２）が第\u0003製品だけを生産、販売す  
るときのＣである。  
〔解答群〕  
  
ア  Ａ：＞Ｂ：＋Ｃ：総費用  
  
イ  Ａ：＞Ｂ：－Ｃ：平均費用  
  
ウ  Ａ：＜Ｂ：－Ｃ：総費用  
  
エ  Ａ：＞Ｂ：＋Ｃ：平均費用  
  
オ  Ａ：＜Ｂ：＋Ｃ：総費用  
`
        },
        {
                "mondaiId": 103230072,
                "name": "第7問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0002つの事業がお互いに補い合って\u0003つの物的資源をより完全に利用して生ま  
れる効果は、範囲の経済の効果である。  
  
イ  \u0002つの事業がお互いに情報的資源を使い合うと、資源の特質から使用量の限  
界がなく他の分野で同時多重利用できるため、物的資源を使い合うよりも効率  
性の高い範囲の経済を生み出せる。  
  
ウ  合成繊維企業が蓄積した自らの化学技術を使用し、本業の補完・関連分野の  
事業に進出するのは範囲の経済の例である。  
  
エ  範囲の経済が生まれるのは、基本的には未利用資源の活用が原因であり、企  
業規模が大きいほど経済効率が良くなることを意味する。  
  
オ  範囲の経済は、多角化が進みすぎると新たに進出した事業と企業の保持して  
いるコア・コンピタンスとの関連性が希薄になって生じなくなる。  
`
        },
        {
                "mondaiId": 103230080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
完成品メーカーと部品供給メーカーとの企業間の取引には、常に競争と協調の両  
面が存在する。そのような企業間の取引で発生する事態についての記述として、最  
も不適切なものはどれか。  
  
ア  過剰な生産能力を持つ業界の部品メーカーA社は、過小な生産能力の業界の  
部品メーカーB社に比べて、高い利益率を獲得できる可能性は低くなる。  
  
イ  部品メーカーC社は、\u0002社の完成品メーカー各社に同じ量の部品を独占的に  
供給しているが、その部品の生産ラインにトラブルが発生したため、生産量を長  
期にわたって減らさざるを得なくなったにもか��わらず、利益率はむしろ増加傾  
向に転じた。  
  
ウ  部品メーカーD社は、供給先の完成品メーカーE社との取引契約に、E社が  
他の部品メーカーに乗り換える場合D社に打診するという条項を結んでいるの  
で、値引き要求や競合他社との受注争奪で有利になる可能性が高くなった。  
  
エ  部品メーカーF社は、自社のみが生産できるある部品について取引の大きな  
完成品メーカーG社と最も有利な条件を自動的に適用するという契約書を結ん  
でいるが、このことが他の完成品メーカーにも知られた結果、各社の値引き要求  
に屈して利益が激減してしまった。  
`
        },
        {
                "mondaiId": 103230090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
技術開発に必要な経営資源を「技術革新において中核となる技術上のノウハウ」と  
その「補完資産」とに分けて考えた場合、ハイテク・ベンチャー企業に関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  多くの顧客に対して販売促進活動を行い、顧客からの注文を受けて製品を届  
け、対価を受け取っている企業は、補完資産としての販売力を自社で保有してい  
る。  
  
イ  技術革新を商業化して経営成果として結実させるために必要なマーケティング  
やアフターサービスの活動に関する能力は補完資産として重要である。  
  
ウ  少数の特定の顧客企業が自社の大部分の製品を購入している場合、補完資産と  
しての販売力を自社で保有している。  
  
エ  ハイテク・ベンチャー企業の「技術革新において中核となる技術上のノウハウ」  
は中核能力として位置づけられ、その獲得は技術革新実現の必要条件である。  
  
オ  ハイテク・ベンチャー企業の「技術革新において中核となる技術上のノウハウ」  
を価値連鎖として完結するために、補完資産の外部への依存を考慮することは重  
要である。  
`
        },
        {
                "mondaiId": 103230100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
ベンチャー企業と大学や研究機関が連携を図り、イノベーションに取り組む動き  
が多く見られるようになった。そのような状況や提携に際して考慮するべき問題点  
についての記述として最も適切なものはどれか。  
  
ア  オープン・イノベーションを推進するために、大学とベンチャー企業が連携し  
て、大学から独立した研究機関を設ける試みが行われているが、ベンチャー企業  
の資金力が弱いので、そのような研究機関から技術イノベーションが生まれるこ  
とはほとんど見られない。  
  
イ  行政による産業クラスター等の技術支援施策を受けて、わが国では大学や研究  
機関の技術の民間への移転が活発であり、その結果株式公開に至るベンチャー企  
業が多く生まれている。  
  
ウ  国立大学法人が他機関との技術提携をする場合、知財本部やTLOを通じるこ  
とが義務づけられているため、技術提携コストや調整の負担がかさむことになる  
が、そのことがベンチャー企業の国立大学との連携を難しくしている。  
  
エ  大学発ベンチャーが大学や研究機関と連携しながら、自前の技術を進化させた  
り、不足する技術力を補うことが行われているが、事業として発展するには企業  
者能力が重要になる。  
  
オ  米国に比べてわが国では大学発ベンチャーはあまり成功していないが、その理  
由として技術開発者の大学教員が経営に直接関与することが禁じられていること  
を指摘できる。  
`
        },
        {
                "mondaiId": 103230110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
グローバル化の進展とともに日本企業が海外に工場を開設する動きが活��化して  
いる。しかし、海外進出は国際化に必要な経営資源が不足する中小企業にとっては  
容易ではない。そのため中小企業では商社に仲介を受けながら、現地パートナーと  
合弁企業を営む例が見られる。そのような海外進出で考慮すべき点の記述として、  
<u>最も不適切なものはどれか</u>。  
  
ア  現地のパートナー企業の技術力が弱い場合、商社を介在して高品質の原材料を  
持ち込んだり、進出企業による現地での技術指導を通じて製品の品質が低下しな  
いようにすることは重要な経営のポイントになる。  
  
イ  現地のパートナー企業や現地国はわが国の企業の進んだ技術の移転を求めてい  
るが、自社技術の保護の観点から、商社等に協力してもらって、合弁事業開始前  
に、守るべき技術や製品の模倣禁止等に関して詳細な規定を含む合弁事業契約を  
パートナー企業と締結しておくことが重要になる。  
  
ウ  合弁事業の出資割合は出資企業がその比率に応じて合弁事業の経営に努力を傾  
注する程度を示すが、商社や現地企業は概してその経営努力とは無関係に配当を  
要求することでトラブルになることに注意することが重要になる。  
  
エ  商社が、情報能力を活かして進出企業に現地の各種情報を伝えたり、現地の法  
務等の対応を図ってくれるので、進出企業は現地国で工場のオペレーションに経  
営努力を傾注できる利点がある。  
  
オ  パートナー企業の合弁事業以外での業務実態について見落とすと、守秘義務条  
項や競合禁止条項が破られ、製品の模倣が行われ、現地市場を失うばかりか、進  
出企業の信用を失墜しかねないので、現地駐在社員の現場の監視能力の向上を図  
ることが重要である。  
`
        },
        {
                "mondaiId": 103230120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
企業組織は、一般に分業と協業のシステムとして階層性という特徴を持ってい  
る。この組織編成に関する記述として最も適切なものはどれか。  
  
ア  イノベーションを目的とした組織においては指揮命令系統の一元性が確保され  
ていなければならないので、階層組織よりはグループ型のフラットな組織が望ま  
しい。  
  
イ  管理者の職務に関する事業の範囲やタイムスパンの責任に応じて、組織は階層  
を設計する必要がある。  
  
ウ  組織における職務の公式化を進めることによって、管理者の統制範囲（spanof  
control）は狭くなるので、階層数は増える傾向にある。  
  
エ  組織の階層を構成する中間管理職の職務について、責任と権限が公式に一致し  
なければならない。  
  
オ  不確実性が高い環境下では、分権化を進めるため、階層のないフラットな構造  
にすることが望ましい。`
        },
        {
                "mondaiId": 103230130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
動機づけに関する概念モデルの\u0002つに「職務特性モデル」がある。この概念モデル  
についての記述として、<u>最も不適切なものはどれか</u>。  
  
ア  個人の動機づけは、仕事の業績評価システム、上司や先輩を通じてのフィード  
バックの程度に影響される。  
  
イ  個人の動機づけは、仕事の出来栄えが社内の人々や顧客に、どれほどのインパ  
クトをもたらすかの程度に影響される。  
  
ウ  個人の動機づけは、仕事の流れの全体像にかかわっている程度に影響される。  
  
エ  個人の動機づけは、仕事をうまく遂行する上で必要なスキル（技能）の多様性の  
程度に影響される。  
  
オ  個人の動機づけは、担当する仕事の範囲で、自主的に工夫して仕事のやり方を  
決められる程度に影響される。  
`
        },
        {
                "mondaiId": 103230140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
日本企業において人事制度の見直しが進んでいる。事業構造の再構築のなかで、  
成果に応じて格差のある報酬配分を行うことのメリットとデメリットを調和させた  
導入が重要になる。  
これに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  新たな評価制度を運用する際には、評価者と被評価者が意見を交わしながら目  
標管理シートを作成するなど、意思疎通の機会を通して満足感を高めるとよい。  
  
イ  職場には既存の文化や風土、価値観が存在するため、新たな評価制度を導入す  
る際には、その基準や手続きに関して十分に理解されるための時間が必要であ  
る。  
  
ウ  評価者が被評価者の全般的な要望や意見を説明しながら評価を行ったならば、  
評価結果が期待したほどではない場合でも、不公正感が抑えられ、動機づけを高  
めることができる。  
  
エ  評価に対する納得感は、自己比較とともに他者相対比較の側面もあることか  
ら、適切に動機づけを高めるためには、社内の公正な評価制度に関する情報開示  
が要となる。  
  
オ  自らが投入した時間・努力量や成果と、それに対する評価・報酬とが見合うな  
らば、人は公正感を感じる。  
`
        },
        {
                "mondaiId": 103230150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
優れた人材を採用し、定着率を高め、能力を高めるには、キャリア開発プログラ  
ムを充実させておく必要がある。キャリア開発プログラムに関する記述として最も  
適切なものはどれか。  
  
ア  キャリア開発の公平性を期すために、直属の上司による評価ではなく、人事部  
のような中立的機関が客観的評価基準を用意する必要がある。  
  
イ  キャリア開発プログラムは人事部のようなスタッフ部門ではなく、ラインの管  
理者の責任において策定される必要がある。  
  
ウ  キャリア開発は、組織階層を昇進することだけでなく、よりチャレンジングで  
魅力的なプロジェクトに参加させることを通じてなされる。  
  
エ  経営戦略や事業計画は頻繁に変化するので、キャリア開発プログラムはそうし  
た計画とは独立に長期的視点から設計しなければならない。  
  
オ  キャリア開発プログラムにおけるメンタリングでは、メンターがプライバシー  
に十分配慮し、自身の職務経験を基礎にした公式のアドバイスをするよう訓練さ  
れる必要がある。  
`
        },
        {
                "mondaiId": 103230160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
従業員の企業に対する帰属意識に関する概念に組織コミットメントがある。組織  
コミットメントとは、一般に組織と個人の心理的距離や関係を規定する概念であ  
る。組織コミットメントに関する記述として最も適切なものはどれか。  
  
ア  規範的コミットメントとは、合理的に組織目標へ自我没入し、個人の役割状況  
にかかわらず、組織へ関与することである。  
  
イ  功利的コミットメントとは、活動の継続・中止にかかわらず、首尾一貫する行  
動のことである。  
  
ウ  情緒的コミットメントとは、個人の信念に基づいて組織の価値や目標へ感情  
的、かつ、熱狂的に支持をすることである。  
  
エ  態度的コミットメントとは、組織の価値や目標を進んで受け入れ、関連した役  
割などに積極的に関与することである。  
`
        },
        {
                "mondaiId": 103230170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
不確実な状況変化のなか職場で良好な人間関係を築きながら、リーダーシップを  
発揮することが大きな課題となってきている。リーダー・メンバー交換（LMX）理  
論に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  リーダーシップが有効に発揮されるかどうかは、リーダーがメンバーと良好な  
交換関係を築くことができるかに依存している。  
  
イ  リーダーとの特別な関係を構築しない外集団のメンバーは、公式権限に基づく  
やり取りのみになるため、業績評価が低く、離職の意志も高くなる。  
  
ウ  リーダーは、自分の部下の一部の小集団と特別な関係を築くが、この集団に属  
するメンバーはリスクをとらずに、より多くの資源や機会を活かす傾向がある。  
  
エ  リーダーは、組織内の様々な人々に対してそれぞれ異なる行動を取り、自分と  
似た考え方や個人特性を持ったメンバーで、能力の高い者を内集団のフォロワー  
に選ぶ傾向が高い。  
  
オ  リーダーは、自分が必要とする貢献行動をメンバーに求めると同時に、その貢  
献行動に対する内的・外的報酬を提供する。  
`
        },
        {
                "mondaiId": 103230180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
企業経営者が、損失を隠す粉飾決算を行い、投資を呼び込むために株価をつり上  
げ、そのことが公表されて倒産するに至るという事件が起きることがある。このよ  
うな場合、投資家、顧客、従業員、地域社会の他、利害関係者に大きな損害を与え  
る可能性があるとともに、資本主義経済システムへの信頼そのものを失わせてしま  
う可能性がある。  
有効なコーポレートガバナンスの仕組みに関する記述として最も適切なものはど  
れか。  
  
ア  株式市場に上場し、より多くの株主に株式を分散して保有してもらい、多様な  
株主による株主総会でのチェック機構を強化する。  
  
イ  企業の会計基準を時価会計にあらため、外部監査会社による積極型会計  
（aggressiveaccounting）を法的に義務づけ積極的に情報の開示を促進させる。  
  
ウ  内部統制と内部統制報告書の作成を促進し、情報の開示や説明責任の明確化な  
どを図る必要がある。  
  
エ  万一こうした損害が発生した場合、その被害を最小限に抑えるために、一定以  
上の資産をデリバティブなどのリスク管理資産として留保しておくよう義務づけ  
る。  
`
        },
        {
                "mondaiId": 103230191,
                "name": "第19問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
企業はその生存にとって必要な資源を、外部環境を構成する他の組織に依存して  
いるために、そうしたステイクホルダーからのコントロールを受け入れる必要があ  
るという「資源依存モデル」がある。  
資源依存モデルに関する以下の設問に答えよ。  
（設問1））  
資源依存モデルによれば、環境の構造的特徴によって、焦点組織が直面する不  
確実性は異なってくるという。このことに関する記述として最も適切なものはど  
れか。  
  
ア  組織間相互依存度が高くても、重要な資源が豊かにある場合、組織間コンフ  
リクトの可能性は低くなるため、焦点組織が直面する不確実性は低くなる。  
  
イ  組織間で権力の集中度が高くなると、組織間コンフリクトの可能性が高くな  
るため、焦点組織が直面する不確実性は高くなる。  
  
ウ  組織間で権力の集中度が低くなると、組織間の相互依存度が高くなり、組織  
間コンフリクトの可能性が高くなるため、焦点組織が直面する不確実性は高く  
なる。  
  
エ  組織間の連結の度合いが高く、組織間で権力の集中度が低い場合、組織間コ  
ンフリクトの可能性が低くなるため、焦点組織が直面する不確実性は低くな  
る。  
  
オ  組織間の連結の度合いが高いと、組織間の相互依存度が高くなり、組織間コ  
ンフリクトの可能性が低くなるため、焦点組織が直面する不確実性は低くな  
る。  
`
        },
        {
                "mondaiId": 103230192,
                "name": "第19問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
資源依存モデルによれば、環境コンテキストは、組織の行動や構造の変化、経  
営者の継承や交代、組織内の権力関係の変化などをもたらすという。このことに  
関する記述として最も適切なものはどれか。  
  
ア  環境コンテキストが組織内の権力関係に影響を与えることを通じて、誰が経  
営者として選任されるかに影響を与え、組織の行動や構造の変化をもたらし、  
その結果、環境コンテキストに変化を与えようとする。  
  
イ  環境コンテキストが組織内の権力関係に影響を与え、組織の行動や構造の変  
化をもたらすので、誰が経営者として選任されるかを決めることを通じて、そ  
の結果、環境コンテキストに変化を与えようとする。  
  
ウ  環境コンテキストが組織の行動や構造の変化をもたらし、組織内の権力関係  
に影響を与えることを通じて、誰が経営者として選任されるかに影響を与え、  
その結果、環境コンテキストに変化を与えようとする。  
  
エ  環境コンテキストが誰が経営者として選任されるかに影響を与えることを通  
じて、組織内の権力関係に影響を与え、組織の行動や構造の変化をもたらし、  
その結果、環境コンテキストに変化を与えようとする。  
  
オ  環境コンテキストが誰が経営者として選任されるかに影響を与えることを通  
じて、組織の行動や構造の変化をもたらし、組織内の権力関係に影響を与え、  
その結果、環境コンテキストに変化を与えようとする。  
`
        },
        {
                "mondaiId": 103230200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
次のケースを読み、あなたがA社のコンサルタントとして診断するとすれば、  
経営者への助言として、最も適切なものを下記の解答群から選べ。  
「創業以来、個性的な開発者による小規模なプログラムの受注開発を請け負って  
きたA社は、その成長とともに、次第により大きな規模の企業から複雑なプログ  
ラムの開発を受注するように主な事業ドメインをシフトしてきた。従業員数も急激  
に増加してきたが、\u0002年ほどたつと、取引先企業のコスト削減要求が強くなり、一  
方で競争も激化し、売上高の伸び率も鈍化してきた。それまで開発者同士を切磋琢  
磨させるために導入していた個人へのインセンティブシステムを廃止し、従業員も  
削減し始めた。またこの時期から、創業当初からいた個性的な開発者たちが次々と  
A社を辞職していき、利益率も悪化し始めた。  
そこでA社は、個人へのインセンティブシステムを復活させ、従業員のモチ  
ベーションを高めようと組織改革に取り組んだ。しかし、取引先企業からは納期の  
遅れや品質面での苦情が多数寄せられるようになり、その修復にコストがかかるよ  
うになり、一時的に利益率は回復したものの、その後、かえって利益率は低下して  
しまった。」  
〔解答群〕  
  
ア  開発者たちの創造性を高めるため、個人へのインセンティブの幅を大きく  
し、従業員間の競争を促進する。  
  
イ  個人へのインセンティブの幅を小さくし、プロジェクト単位での顧客満足度  
を報酬に反映させるようにする。  
  
ウ  従業員を削減し、個人の責任を明確にするとともに、個人へのインセンティ  
ブの幅を大きくする。  
  
エ  職務のルーティン化を進め、個人へのインセンティブの幅を削減し、人件費  
総額を圧縮する。  
  
オ  創業当初からいた個性的な開発者たちをA社に戻すため、特別の給与体系  
を用意する。  
`
        },
        {
                "mondaiId": 103230210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
時間外・休日労働に関する労使協定（以下「三六協定」という。）に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  三六協定（労働協約による場合を除く。）の有効期間に関する法令上の定めはな  
いが、行政通達では、有効期間を\u0002年間とすることが望ましいとされている。  
  
イ  三六協定の有効期間中に労使いずれかから一方的な協定破棄の申し入れをして  
も、他方がこれに応じないときは、当該協定の効力には影響がない。  
  
ウ  事業場の労働者の過半数で組織された労働組合との間で締結された三六協定  
に、労使両当事者の署名又は記名押印があればその協定は労働協約となるが、そ  
の効力は、当該組合の組合員だけでなく、当該事業場の全労働者に及ぶ。  
  
エ  特別条項付き三六協定を定める場合、特別の事情があるときは限度時間を超え  
て労働させることができるが、その長さと回数には上限が定められている。`
        },
        {
                "mondaiId": 103230220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
社外積立型退職金（年金）制度に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  確定給付企業年金には基金型と規約型があるが、基金型は、厚生年金基金と同  
様、厚生労働大臣の認可を受けて設立した基金が運営主体となるのに対し、規約  
型は、税制適格年金と同様、財務大臣の承認を受けた規約に基づいて、金融機関  
が実施主体となって運営するものである。  
  
イ  確定拠出年金の企業型年金は、厚生年金適用事業所の事業主が単独又は共同し  
て実施する制度であり、その加入者は６０歳未満のもので、厚生年金保険の被保  
険者又は私立学校教職員共済制度の加入者のいずれかに該当する者である。  
  
ウ  厚生年金基金の掛金は、原則として、加入員と加入員を使用する事業主がそれ  
ぞれ掛金の半額を負担することになっている。  
  
エ  中小企業退職金共済の給付は、原則として、労働者が退職したときに退職一時  
金として支払われるものであるが、一定の要件を満たす場合には、\u0003年間又は  
１０年間に分割し、年金と同様の方法で受け取ることができる。  
`
        },
        {
                "mondaiId": 103230230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
安全衛生管理体制に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  衛生管理者は、少なくとも毎週\u0002回作業場を巡視し、設備、作業方法又は衛生  
状態に有害のおそれがあるときは、直ちに、労働者の健康障害を防止するために  
必要な措置を講じる義務がある。  
  
イ  産業医及び衛生管理者は、ともに原則として選出すべき事由が発生してから  
１４日以内に選任し、それぞれ選任したときは、遅滞なく選任報告書を所轄労働  
基準監督署長に提出しなければならない。  
  
ウ  常時１０人以上５０人未満の労働者を雇用する事業場では、業種を問わず、衛生  
推進者を選任することとされている。  
  
エ  常時５０人以上の労働者を雇用するすべての事業場で設置が義務づけられてい  
る衛生委員会の委員には、必ず衛生管理者と産業医を指名しなければならない。`
        },
        {
                "mondaiId": 103230240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
解雇（雇止めを含む。）に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  期間の定めのある労働契約を締結した場合は、やむを得ない事由がある場合で  
なければ、その労働契約が満了するまでの間は労働者を解雇することができな  
い。  
  
イ  定年後の再雇用制度を設けている場合に、労使協定で定めた再雇用制度の対象  
となる高年齢者に係る基準に達しない高年齢者を再雇用しない場合には、解雇予  
告が必要になる。  
  
ウ  有期労働契約（３０日未満の有期契約を除く。）を\u0003回以上更新し、又は雇入れの  
日から起算して\u0002年を超えて継続勤務している場合に、当該契約を更新しないこ  
ととしようとするときは、使用者は、少なくとも当該契約の期間の満了する日の  
３０日前までに、当該労働者にその旨予告しなければならない。ただし、あらか  
じめ更新しない旨が明示されている場合はこの限りではない。  
  
エ  労働契約で試用期間を\u0003か月間と定めた場合にも、解雇予告なしに即時解雇す  
ることができるのは、行政官庁の認定を受けた場合を除き、雇入れの日から\u0004週  
間以内に限られる。  
`
        },
        {
                "mondaiId": 103230250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
割増賃金の算定基礎賃金となる手当に関する記述として、最も適切なものはどれ  
か。  
  
ア  賃貸住宅居住者に家賃の一定割合を手当として支給する一方、持家居住者にも  
ローン月額の一定割合を手当として支給する場合、このような手当は住宅手当と  
はいえないので、割増賃金の算定基礎賃金から除外することはできない。  
  
イ  転勤命令によって、赴任地に家族を帯同せずに単身で住居を設ける場合に支給  
される、いわゆる単身赴任手当は、法令上の別居手当と考えられるので、割増賃  
金の算定基礎賃金から除外することができる。  
  
ウ  扶養家族数に応じて支払われるものでも、物価手当や都市手当などの名称のも  
のは、家族手当ではないので、割増賃金の算定基礎賃金に含めなければならな  
い。  
  
エ  毎月ある一定量の目標を突破した場合に、出来高に応じて支払われる奨励手当  
は、割増賃金の算定基礎賃金には含まれない。  
`
        },
        {
                "mondaiId": 103230261,
                "name": "第26問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
次の文章を読んで、下記の設問に答えよ。  
近年、具体的な製品価格の決定に当たり、PSM（PriceSensitivityMeasurement）  
調査という方法が利用されるようになっている。PSMの基本的な考え方は以下の  
とおりである。  
まず、消費者の受容価格に関する次の\u0002つの質問がなされる。  
\u0002どの価格で、その製品があまりにも安いので品質に不安を感じ始めますか。  
\u0003どの価格で、品質に不安はないが、安いと感じますか。  
\u0004どの価格で、その品質ゆえ、買う価値があるが、高いと感じ始めますか。  
\u0005どの価格で、その製品があまりにも高いので品質が良いにもかかわらず、買う  
価値がないと感じますか。  
下図の曲線a～dは、この\u0002つの質問に対する回答を示しており、aとbは価格  
の低い方からの、cとdは価格の高い方からの累積回答率でグラフ化されている。  
        
（設問1））  
調査の質問\u0002～\u0003と、それぞれの回答率を示した図中の曲線a～dの組み合わ  
せとして最も適切なものはどれか。  
  
ア  \u0002：a\u0004：b\u0005：c\u0003：d  
  
イ  \u0002：a\u0004：c\u0005：b\u0003：d  
  
ウ  \u0002：b\u0004：a\u0005：d\u0003：c  
  
エ  \u0002：c\u0004：d\u0005：b\u0003：a  
  
オ  \u0002：d\u0004：c\u0005：a\u0003：b`
        },
        {
                "mondaiId": 103230262,
                "name": "第26問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
この図から読み取ることのできる消費者の受容価格帯として最も適切なものは  
どれか。  
  
ア  AB  
  
イ  AC  
  
ウ  AD  
  
エ  BC  
  
オ  BD  
`
        },
        {
                "mondaiId": 103230271,
                "name": "第27問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
次の文章を読んで、下記の設問に答えよ。  
多くの製品がコモディティ化している今日の成熟市場では、消費者にとって価値  
ある  
\u0002  
ブランドを創造・提供することが重要である。顧客ベースのブランド・エクイ  
ティという概念によれば、ブランドの強さは消費者のＡによって決まる。  
Ａは、ＢとＣという\u0002つの次元から構成される。強い  
ブランドを構築するためには、  
\u0003  
ブランド要素の選択、支援的マーケティング・プロ  
グラムの開発、二次的な連想の活用によって、深く広いＢと、強く、好ま  
しく、ユニークなＣを獲得する必要がある。  
（設問２））  
文中の空欄Ａ～Ｃにあてはまる語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  Ａ：ブランド・アイデンティティＢ：ブランド知識  
Ｃ：ブランド・イメージ  
  
イ  Ａ：ブランド・イメージＢ：ブランド知識  
Ｃ：ブランド・ロイヤルティ  
  
ウ  Ａ：ブランド・ロイヤルティＢ：ブランド認知  
Ｃ：ブランド知識  
  
エ  Ａ：ブランド知識Ｂ：ブランド認知  
Ｃ：ブランド・イメージ  
  
オ  Ａ：ブランド認知Ｂ：ブランド・アイデンティティ  
Ｃ：ブランド・ロイヤルティ  
`
        },
        {
                "mondaiId": 103230272,
                "name": "第27問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  成功している既存ブランドを利用することで、迅速かつ低コストで新製品を  
導入することができる。  
  
イ  製品ミックスの整合性が低下するほど、企業ブランドによって製品の意味を  
明確化する必要がある。  
  
ウ  プライベート・ブランドは、ナショナル・ブランドと比べて、売上高に占め  
る販売管理費が低いため、相対的に高い粗利益率を確保できる。  
  
エ  ブランドによって、製品に対する消費者の知覚が変化することがある。  
  
オ  ブランドの最も基本的な機能は、ある企業の製品を他の企業の製品から区別  
する識別機能である。`
        },
        {
                "mondaiId": 103230273,
                "name": "第27問設問3",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0003に関する以下の文章の空欄Ａ～Ｃにあてはまる語句の組み合わ  
せとして最も適切なものを下記の解答群から選べ。  
ブランドは様々な要素から構成され、それらによって消費者はブランドを知覚  
する。  
Ａは視覚と聴覚に訴求することができるとともに、言語的な意味性も  
備えることができる。Ｂは聴覚のみへの訴求だが、言語的な意味性は高  
い。  
Ｃは、視覚だけでなく触覚にも訴求できる点に特徴がある。  
〔解答群〕  
  
ア  Ａ：キャラクターＢ：パッケージＣ：スローガン  
  
イ  Ａ：ジングルＢ：キャラクターＣ：ロゴ  
  
ウ  Ａ：パッケージＢ：スローガンＣ：ジングル  
  
エ  Ａ：ブランド・ネームＢ：ジングルＣ：スローガン  
  
オ  Ａ：ブランド・ネームＢ：スローガンＣ：パッケージ  
`
        },
        {
                "mondaiId": 103230280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
市場調査において一次データを収集するための調査方法に関する記述として、最  
も不適切なものはどれか。  
  
ア  インターネットによるサーベイ調査は低コストで短期間にデータを回収できる  
が、面接法や電話法ほど正直な回答が得られない。  
  
イ  観察調査は客観性と正確性に優れているが、収集できるデータのタイプには制  
約がある。  
  
ウ  グループ・インタビューでは、回答者の反応に応じて機動的な質問ができる  
が、調査の成否がインタビュアーの力量に大きく左右される。  
  
エ  実験法によって、特定の要因間の因果関係を明らかにすることができるが、そ  
の他の要因の影響を統制できなければ実験結果を信頼することはできない。  
  
オ  留置法によってサーベイ調査を行うことによって質問票の回収率と調査結果の  
信頼性を高めることができるが、調査コストは高くなってしまう。  
`
        },
        {
                "mondaiId": 103230290,
                "name": "第29問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
ある地方都市の小規模菓子メーカーA社（資本金３，０００万円）は、クッキー、ビ  
スケットを中心に安心、安全な菓子作りに取り組んできた。低カロリーのクッキー  
は同社の主力商品であるが、健康志向の高まりによって大手メーカーも同様のクッ  
キーを製造・販売するようになっており、売上は伸び悩んでいた。また、低カロ  
リーのクッキーは、カロリーを抑えるために砂糖を使わず、乳脂肪分も控えている  
ため、従来のクッキーに比べてどうしても甘みや口当たりに劣っており、その購買  
者は限られていた。  
しかし最近A社は長年の努力の結果、低カロリーながら従来のクッキーに勝る  
とも劣らない味を実現する新技術の開発に成功した。同社ではこの画期的な技術に  
よる新製品を今後の存続・成長の柱として育てるために、慎重にその市場導入の方  
法を検討している。この新製品の「低カロリーでおいしい」というベネフィットは、  
十分に消費者に知覚されるものであることが調査によって確認されていたので、価  
格は\u0002箱３８０円（２０枚、８０グラム）と従来製品よりも１５０円ほど高い価格を設定  
し、これを堅持したいと考えていた。  
この新製品の流通経路政策として、<u>最も不適切なものはどれか</u>。  
なお、現時点での同社の売上は\u0003割が地元スーパーチェーン、\u0002割が自社のホー  
ムページによるものである。  
  
ア  インターネットによる直接販売を強化するために、自社ホームページに加え、  
インターネット上のショッピングモールに出店する。  
  
イ  カロリー摂取に敏感な人が多くいると考えられる病院やホテルの売店で販売す  
る。  
  
ウ  新製品の付加価値をアピールするために、地元の洋菓子店を新たな販路として  
開拓する。  
  
エ  販売量を拡大するために、全国チェーンの大手スーパーにプライベート・ブラ  
ンドとして供給する。  
`
        },
        {
                "mondaiId": 103230301,
                "name": "第30問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
次の文章を読んで、下記の設問に答えよ。  
電通『２００９年日本の広告費』によれば、日本の２００９年の総広告費は\u0002年連続で  
減少し、\u0003兆９，２２２億円となった。特に  
\u0002  
マスコミ\u0004媒体の広告費は\u0003年連続の減少  
を記録した。こうした状況のなか、企業のマーケティングにおける  
\u0003  
コミュニケー  
ション戦略の新たな考え方が登場してきている。  
（設問\u0005）  
文中の下線部\u0002に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  新聞・雑誌といった印刷媒体は、広告変更の柔軟性は高いが、メッセージの  
寿命は短い。  
  
イ  ターゲットを絞り込んで説得的なメッセージを発信するためには、テレビよ  
りもラジオや雑誌の方が適している。  
  
ウ  テレビ広告のコストは、そのリーチを勘案すれば必ずしも高いとはいえな  
い。  
  
エ  テレビ広告の場合、番組CMよりもスポットCMの方がタイミングやエリ  
  
ア  について柔軟な出稿が可能である。  
  
オ  ラジオはほぼ音声による訴求しかできないが、テレビよりもザッピングが少  
ない。  
`
        },
        {
                "mondaiId": 103230302,
                "name": "第30問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  CGM（ConsumerGeneratedMedia：消費者生成型メディア）の普及に伴っ  
て、消費者が企業のマーケティング活動の成果に及ぼす影響は大きくなってい  
る。  
  
イ  インターネット広告は、成果に応じた報酬を支払う取引が行われるため、費  
用対効果を追求する広告主のニーズに適合している。  
  
ウ  検索サイトで検索されたときに、検索結果の上位に表示されるよう自社の  
  
ウ  ェブページの内容を調整することをアフィリエイト・プログラムという。  
  
エ  ニュースサイトやブログ、マスコミ報道や消費者のクチコミなどは、企業や  
ブランドに対する信用や評判を得ることができるため、「アーンド・メディア  
（earnedmedia）」と呼ばれている。  
  
オ  複数の広告媒体を活用し、パソコンや携帯電話を通じてインターネットでの  
検索や購買といった効果を発揮させることを目的とした媒体戦略は、クロス・  
メディアと呼ばれる。  
`
        },
        {
                "mondaiId": 103230311,
                "name": "第31問設問1",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
次の文章を読んで、下記の設問に答えよ。  
品揃えの中心がＡであるスーパーマーケットでの購買行動の多くは  
ＢのＣであるため、消費者が購買するブランドの決定にインスト  
  
ア  ・マーチャンダイジングが大きな影響を及ぼす。インストア・マーチャンダイジ  
ングとは、小売業の店頭活動であり、店内のフロア・レイアウト、商品陳列、店内  
プロモーションを内容とする。  
（設問1））  
文中の空欄Ａ～Ｃにあてはまる語句の組み合わせとして最も適切なものはどれ  
か。  
  
ア  Ａ：買回品Ｂ：高関与Ｃ：非計画購買  
  
イ  Ａ：買回品Ｂ：低関与Ｃ：計画購買  
  
ウ  Ａ：最寄品Ｂ：高関与Ｃ：条件購買  
  
エ  Ａ：最寄品Ｂ：低関与Ｃ：計画購買  
  
オ  Ａ：最寄品Ｂ：低関与Ｃ：非計画購買`
        },
        {
                "mondaiId": 103230312,
                "name": "第31問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
  
イ  ンストア・マーチャンダイジングの手法のひとつであるクロス・マーチャン  
ダイジング（クロスMD）に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  クロスMDによって、買物における消費者の情報処理負荷を軽減すること  
ができる。  
  
イ  クロスMDによって消費者の購買点数を増加させることができる。  
  
ウ  クロスMDの有効性は、他商品との組み合わせ購買が生じる確率（条件付き  
購買確率）を通常の購買確率で除したリフト値によって判断することができ  
る。  
  
エ  クロスMDは、小売業者の商品部門間のコンフリクトを低下させる。  
  
オ  メーカーが小売業者にクロスMDを提案する場合には、自社製品だけでな  
く他社製品の売上増にも寄与する提案が望ましい。  
`
        },
        {
                "mondaiId": 103230321,
                "name": "第32問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
次の文章を読んで、下記の設問に答えよ。  
ある地方の山間部の温泉地で旅館を経営しているB社は、当該温泉地を代表す  
る老舗高級旅館で、高サービス・高価格を特徴としている。その主要な顧客層は比  
較的裕福な中高年層であるが、景気の悪化の影響もあり、ここ数年来客数が減少し  
ており、客室稼働率の低下に悩まされている。このような状況への対策として、同  
社は顧客満足度の向上に取り組もうとしていた。  
（設問1））  
物財と比べたときのサービス財の一般的特徴に関する記述として、最も不適切  
なものはどれか。  
  
ア  サービス財の場合、買い手がその生産に関与し成果に影響を及ぼす。  
  
イ  サービス財は需要の変動が大きいため、物財よりも多くの在庫をもたなけれ  
ばならない。  
  
ウ  サービス財は生産と消費を時間的・空間的に分離して行うことができない。  
  
エ  サービス財は物財と比べて品質を標準化することが困難である。  
  
オ  サービス財は無形であるため、物財に比べ、利用前にその品質水準を評価す  
ることが難しい。`
        },
        {
                "mondaiId": 103230322,
                "name": "第32問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
B社の顧客満足度向上に向けた具体的施策として、最も不適切なものはどれ  
か。  
  
ア  従業員の研修を徹底し、接客技術・知識の向上を図る。  
  
イ  従業員の表彰制度などを導入して、従業員のモチベーションを高める。  
  
ウ  接客のマニュアル化を徹底してサービスの標準化を図る。  
  
エ  料理、客室の調度品や寝具、温泉施設などに関する顧客の苦情やリクエスト  
などをデータベース化する。  
  
オ  料理の特徴・楽しみ方や温泉の効能・利用方法などを顧客に分かりやすく説  
明する。  
`
        },
        {
                "mondaiId": 104230010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　生産における管理目標に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  強度率は、労働災害１件当たりの大きさを示す指標の１つである。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

イ  生産リードタイムは、<hintTarget>顧客が注文してからその製品を手にする</hintTarget>までの時間である。  

<hint hidden='true'>**
❌この記述は<hintTarget>発注</hintTarget>リードタイムです。  
生産リードタイムは、<hintTarget>生産の着手時期から完了時期</hintTarget>までの時間です。
**</hint>

ウ  直行率は、初工程から最終工程まで、手直し・調整・手戻りなどがなく順調に通過した品物の、全体の品物に対する割合をいう。  

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ  労働生産性は、一般に、付加価値を従業員数で除した値で示される。

<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

`
        },
        {
                "mondaiId": 104230020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
機能別職場の特徴に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  熟練工を職長にすることにより部下の技術指導がしやすくなる。  
  
イ  職場間での仕事量にバラつきが生じやすい。  
  
ウ  職場間の運搬が煩雑である。  
  
エ  製品の流れの管理がしやすい。  
`
        },
        {
                "mondaiId": 104230030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工程管理における余力の調整および余力管理に関する記述として、最も不適切な  
ものはどれか。  
  
ア  職場間の応援や残業は、小日程計画における余力の調整業務にあたる。  
  
イ  設備投資の検討、職場組織の変更は、大日程計画における余力の調整業務にあ  
たる。  
  
ウ  手持ちの仕事量と現有能力の把握、その両者の比較、作業分配、作業予定の決  
定は、余力管理の業務にあたる。  
  
エ  流動数グラフ、製品工程分析表は、余力を把握するための図表として用いられ  
る。`
        },
        {
                "mondaiId": 104230040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産システムへのITの利用に関する記述として、最も適切なものはどれか。  
  
ア  資材の計画、要員や設備などの資源の管理のために、MRP\u0002を導入する。  
  
イ  生産情報をリアルタイムに処理し、現場管理者に提供するために、MRPを導  
入する。  
  
ウ  製品のモデルを用いて製品設計を仮想的に評価するために、ERPを導入す  
る。  
  
エ  物、人、金を対象に、生産を総合的に管理するために、CAEを導入する。  
`
        },
        {
                "mondaiId": 104230050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
VEなどにおいて、アイデアを発想する手法の\u0002つとして用いられるブレーン・  
ストーミングの\u0003つのルールに関する記述として、最も適切なものはどれか。  
  
ア  制約条件に合致したアイデアを考える。  
  
イ  できるだけ時間をかけてアイデアを考える。  
  
ウ  できるだけ質の高いアイデアを考える。  
  
エ  人が出したアイデアをもとに、発展したアイデアを考える。`
        },
        {
                "mondaiId": 104230060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
機械の機構において、往復運動を回転運動に変換する、あるいはその逆を行う仕  
組みに関する項目として、<u>最も不適切なものはどれか</u>。  
  
ア  ピストンとクランク  
  
イ  ピニオンとラック  
  
ウ  ベルトとプーリー  
  
エ  遊星歯車  
`
        },
        {
                "mondaiId": 104230070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
品質展開に関する次の文中の空欄Ａ～Ｃに入る、最も適切な用語の組み合わせを  
下記の解答群から選べ。  
JISQ９０２５によれば、品質展開とは、「ＡをＢに変換し、製品  
の設計品質を定め、各機能部品、個々の構成部品の品質、及びＣの要素に  
展開する方法」と定義している。  
〔解答群〕  
  
ア  Ａ：品質特性Ｂ：要求品質Ｃ：工程  
  
イ  Ａ：品質特性Ｂ：要求品質Ｃ：動作  
  
ウ  Ａ：要求品質Ｂ：品質特性Ｃ：工程  
  
エ  Ａ：要求品質Ｂ：品質特性Ｃ：動作`
        },
        {
                "mondaiId": 104230080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ライン生産方式に関する記述として、最も適切なものはどれか。  
  
ア  移動作業方式では、一般に、作業者と品物が同時に移動し、作業者は所定の作  
業域内で所定の作業を行う。  
  
イ  静止作業方式では、一般に、作業者が連続的に移動し、品物は定位置に置いて  
所定の作業を行う。  
  
ウ  タクト生産方式では、一般に、生産ラインの全作業者が同時に所定の作業に着  
手し、ある時間間隔で同時に終了し、作業者が一斉に次の作業ステーションへ移  
動する。  
  
エ  ライン生産方式では、一般に、ライン上の各作業ステーションに作業が割り付  
けられ、作業者が作業ステーションを移動するにつれて加工が進んでいく。  
`
        },
        {
                "mondaiId": 104230090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
最終製品XとYの部品構成表が下図に与えられている。（）内の数は親に対し  
て必要な部品の個数を示している。製品Xを１０個、製品Yを\u0002個生産するのに必  
要な部品eの数量に最も近いものを下記の解答群から選べ。  
X  
A\u0002  
e\u0003f\u0002e\u0002  
B\u0003  
e\u0004f\u0003g\u0004  
C\u0003  
e\u0003g\u0003  
D\u0004  
Y  
〔解答群〕  
  
ア  ５０イ６０ウ７０エ８０`
        },
        {
                "mondaiId": 104230100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
\u0003機械ジョブショップにおいて、各ジョブの作業時間と作業順序が表に与えられ  
ている。各ショップでのジョブ投入順序をSPT（最小作業時間）ルールで決定した  
とき、総所要時間の値として最も適切なものを下記の解答群から選べ。  
作業時間  
作業順序  
機械\u0004機械\u0003  
ジョブ\u0004１．０３．０機械\u0003→機械\u0004  
ジョブ\u0003４．０２．０機械\u0004→機械\u0003  
ジョブ\u0005３．０４．０機械\u0004→機械\u0003  
ジョブ\u0006２．０１．０機械\u0003→機械\u0004  
〔解答群〕  
  
ア  １１．０イ１２．０ウ１３．０エ１４．０  
`
        },
        {
                "mondaiId": 104230110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
製造企業において外注を利用する意義に関する記述として、最も不適切なものは  
どれか。  
  
ア  景気等による需要変動に対する安全弁として利用できる。  
  
イ  社内製作に必要とされる生産設備や要員の固定費を削減できる。  
  
ウ  垂直的分業によって、他社の生産技術や生産設備を利用できる。  
  
エ  品質、コスト、納期、数量を容易に管理できる。`
        },
        {
                "mondaiId": 104230120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　品質管理におけるQC手法と分析内容の組み合わせとして、最も適切なものはどれか。  
  
ア  管理図　―　不良の発生が慢性的か、突発的かなどを吟味する。

<hint hidden='true'>**
⭕許容上限・下限の逸脱が慢性的か確認する際に使用します。
** </hint>      

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】QC7７つ道具  
<TABLE border="5" bordercolor="#ffffff" width="100%" style='background-color:#ffffff;color:#333333;'>
<TR>
<TD style='background-color:#ffdddd;'>チェックシート</TD>
<TD><imgTag>104010110_2.png</imgTag></TD>
<TD style='background-color:#ddddff;'><hintTarget>確認事項</hintTarget>をまとめて、データ収集を効率化したり、整理する
</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>特性要因</hintTarget>図</TD>
<TD><imgTag>104010110_3.png</imgTag></TD>
<TD style='background-color:#ddddff;'>結果と原因の関係を分析する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>パレート</hintTarget>図</TD>
<TD><imgTag>104010110_4.png</imgTag></TD>
<TD style='background-color:#ddddff;'>不具合の原因を<hintTarget>発生頻度</hintTarget>順に並べて、原因調査の絞り込みなどに活用する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>ヒストグラム</hintTarget>
</TD>
<TD><imgTag>104010110_5.png</imgTag></TD>
<TD style='background-color:#ddddff;'>区分ごとのデータの分布を俯瞰する
</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'><hintTarget>散布</hintTarget>図</TD>
<TD><imgTag>104010110_6.png</imgTag></TD>
<TD style='background-color:#ddddff;'>X,Y軸に2つの特性を配置して相関の有無を見る</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'>管理図</TD>
<TD><imgTag>104010110_7.png</imgTag></TD>
<TD style='background-color:#ddddff;'><hintTarget>許容範囲の上限加減の逸脱状況</hintTarget>の点検などに使用する</TD>
</TR>

<TR>
<TD style='background-color:#ffdddd;'>グラフ</TD>
<TD>　</TD>
<TD style='background-color:#ddddff;'>必要に応じて可視化して、実態把握することを推奨しています</TD>
</TR>

</TABLE>
</div>
</hint>

イ  散布図　―　<hintTarget>問題となっている不良を現象や原因で分類</hintTarget>し、整理する。  

<hint hidden='true'>**
❌ヒストグラムに関する記述です。
** </hint>      
  
ウ  パレート図　―　ある製品の品質特性値の<hintTarget>分布</hintTarget>を知るため、特性値を記録する。  

<hint hidden='true'>**
❌散布図に関する記述です。
** </hint>      

エ  ヒストグラム　―　問題とする<hintTarget>特性とそれに影響すると思われる要因</hintTarget>との関係を整理する。  

<hint hidden='true'>**
❌特性要因図に関する記述です。
** </hint>      
  

`
        },
        {
                "mondaiId": 104230130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
TQM推進の段階に至る順序として、最も適切なものを下記の解答群から選べ。  
\u0002具体的な個別問題の解決  
\u0003制度、運営面での問題の解決  
\u0004潜在的な問題の抽出と解決  
\u0005他社とのベンチマーキング、劣位項目の抽出  
〔解答群〕  
  
ア  \u0002→\u0003→\u0004→\u0005  
  
イ  \u0002→\u0003→\u0005→\u0004  
  
ウ  \u0003→\u0002→\u0004→\u0005  
  
エ  \u0003→\u0002→\u0005→\u0004`
        },
        {
                "mondaiId": 104230140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
生産・販売活動における在庫に関する記述として、最も適切なものはどれか。  
  
ア  <hintTarget>安全</hintTarget>在庫は、経済的理由から補充量をまとめることによって発生する在庫で、その低減方法に段取りの費用や発注の費用の削減がある。  
  
<hint hidden='true'>**
❌ロットサイズ在庫に関する記述です。
**</hint>

イ  <hintTarget>エシェロン</hintTarget>在庫は、需要と供給の不確実性が原因で保有する在庫で、その低減方法に調達リードタイムの短縮や予測精度の向上がある。  

<hint hidden='true'>**
❌<hintTarget>安全</hintTarget>在庫に関する記述です。  
**</hint>
  
ウ  見越し在庫は、需要の波のために必要となる在庫で、その低減方法に販売キャンペーンや低需要期の価格割引などによる需要の波の平準化がある。  
  
<hint hidden='true'>**
⭕適切な記述です。
**</hint>  

エ  ロットサイズ在庫は、多段階在庫システムにおいて対象とする在庫点とその下流にある在庫の合計で、末端に生じた変化をリアルタイムに各在庫点に波及させて表現できる。  

<hint hidden='true'>**
❌エシェロン在庫に関する記述です。 
**</hint>

`
        },
        {
                "mondaiId": 104230150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
標準時間の算定法に関する記述として、最も適切なものはどれか。  
  
ア  PTS法では、長時間の作業の標準時間を簡便に算定することができる。  
  
イ  経験見積法では、余裕時間を必ず別途算定しなければならない。  
  
ウ  実績資料法では、精度の高い標準時間を得ることができる。  
  
エ  ストップウォッチ法では、レイティングを行う必要がある。`
        },
        {
                "mondaiId": 104230160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
生産システムにおける作業者の分析手法に関する記述として、最も不適切なもの  
はどれか。  
  
ア  組立工程における作業者の手の動きを分析するために、作業者工程分析を実施  
する。  
  
イ  作業者の\u0002日の作業内容と作業状況を分析するために、連続稼働分析を実施す  
る。  
  
ウ  作業者の移動に関する問題を抽出するために、ストリングダイヤグラムを作成  
する。  
  
エ  設備オペレータの適正な設備担当台数を検討するために、連合作業分析を実施  
する。  
`
        },
        {
                "mondaiId": 104230170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
ワークサンプリングを使って、出現率がpと予想される作業を、信頼度９５％、  
絶対誤差aで推定するために必要なサンプル数nは次式で与えられる。  
n＝  
１．９６２  
a２p（\u0002－p）  
出現率pが０．５と予測される作業を、信頼度９５％、相対誤差０．１（絶対誤差でな  
いことに注意）で推定するために必要なサンプル数として、最も近い値はどれか。  
  
ア  １００  
  
イ  ４００  
  
ウ  ７００  
  
エ  １，０００`
        },
        {
                "mondaiId": 104230180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
生産保全の観点から見た保全活動の実施に関する記述として、最も不適切なもの  
はどれか。  
  
ア  偶発故障期にある設備の保全体制として、定期保全を実施する。  
  
イ  初期故障期にある設備の保全体制として、改良保全を実施する。  
  
ウ  新設備の初期故障期における故障発生を抑制するために、保全予防を実施す  
る。  
  
エ  摩耗故障期にある設備の保全体制として、予知保全を実施する。  
`
        },
        {
                "mondaiId": 104230190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
個別受注生産での改善施策に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  受注製品ごとの進度管理の精度を高めるために、追番管理方式を導入する。  
  
イ  受注製品の納入リードタイムを短縮するために、設計部門と製造部門の業務を  
同時並行で行うコンカレントエンジニアリングを採用する。  
  
ウ  製品仕様の変更や追加要求を回避するために、客先との間で取り交わす製品仕  
様書の記入内容を再吟味する。  
  
エ  部材調達費の抑制や量産効果を得るために、部品や中間製品の共通化・汎用化  
を図る。`
        },
        {
                "mondaiId": 104230200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
製造物責任法（PL法）に関する記述として、最も適切なものはどれか。  
  
ア  製造業者が利用者に製造物を引き渡した時点から\u0002年で、損害賠償の請求権の  
時効が成立する。  
  
イ  製造物責任法の適用対象物の中には、コンピュータ・ソフトウェアも含まれ  
る。  
  
ウ  製造物の欠陥により他人の生命、身体又は財産を侵害したときは、製造業者  
は、過失の有無にかかわらず、損害を賠償する責任がある。  
  
エ  製造物の輸入業者は、製造物責任法によって賠償の責任を問われることはな  
い。  
`
        },
        {
                "mondaiId": 104230210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
食品の衛生管理システムであるHACCP（HazardAnalysisandCriticalControl  
Point）の１２手順が以下に示されている。空欄Ａ～Ｃと記述群の\u0002～\u0003の組み合わ  
せとして、最も適切なものを下記の解答群から選べ。  
＜HACCPの１２手順＞  
１．専門家チームを編成する。  
２．製品の仕様、特性について記述する。  
３．食べ方、使用法について確認する。  
４．(  Ａ  <hint hidden='true'>**
②製造工程をフローダイヤグラムに書く。
**</hint>)  
５．製造工程を現場で確認する。  
６．危害分析を行う。  
７．(  Ｂ  <hint hidden='true'>**
③重要管理点（CCP：CriticalControlPoints）を決定する。
**</hint>)  
８．管理基準を設定する。  
９．(  Ｃ  <hint hidden='true'>**
①モニタリング方法を設定する。
**</hint>)  
１０．改善措置の方法を設定する。  
１１．検証方法を設定する。  
１２．記録の維持管理方法を決める。  

【空欄に入る記述群】  
①モニタリング方法を設定する。  
②製造工程をフローダイヤグラムに書く。  
③重要管理点（CCP：CriticalControlPoints）を決定する。  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】HACCPのの１２手順  
<img src="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/images/haccptejun.png"/>
<a href="https://www.city.chiba.jp/hokenfukushi/iryoeisei/hokenjo/shokuhin/haccpgimuka.html" target="_blank">  
(外部リンクを開きます　千葉市：HACCP(ハサップ)に沿った衛生管理)</a>  
</div>
</hint>

〔解答群〕  
  
ア  Ａ：①　Ｂ：②　Ｃ：③  
  
イ  Ａ：②　Ｂ：①　Ｃ：③  
  
ウ  Ａ：②　Ｂ：③　Ｃ：①<hint hidden='true'>**
←⭕
**</hint>    
  
エ  Ａ：③　Ｂ：②　Ｃ：①  
`
        },
        {
                "mondaiId": 104230220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
都市計画法の用途地域において立地可能な集客施設の例として、最も不適切なも  
のはどれか。  
  
ア  準工業地域に計画された床面積２０，０００m２のショッピングモール  
  
イ  準住居地域に計画された床面積１０，０００m２のショッピングセンター  
  
ウ  商業地域に計画された床面積３，０００m２のシネマコンプレックス  
  
エ  第一種住居地域に計画された床面積５，０００m２の総合スーパー`
        },
        {
                "mondaiId": 104230230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
大規模小売店舗立地法の届出を行ったことのある大型店が届出事項を変更しよう  
とする際、届出が必要な事例として、最も適切なものはどれか。  
  
ア  駐輪場の収容台数を増加させるとき。  
  
イ  店舗面積の増加分が、届出済面積の０．１倍もしくは１，０００m２を超えないと  
き。  
  
ウ  廃棄物等の保管施設の容量を増加させるとき。  
  
エ  閉店時刻を繰り下げるとき。  
`
        },
        {
                "mondaiId": 104230240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
経済産業省が実施した「平成１９年商業統計調査」から確認できる全国の小売商店  
の立地傾向に関して、次の文中の空欄Ａ～Ｄに入る最も適切なものの組み合わせを  
下記の解答群から選べ。  
総合スーパーのＡは、商店数、年間商品販売額ともに平成１４年調査に  
比べて増加しており、これまで総合スーパーの代表的な立地環境であった  
Ｂと並ぶ主要な立地環境へと移行しつつある。また、コンビニエンススト  
  
ア  の商店数を立地別にみると、Ｃが最も多いが、Ｄ、工業地区、  
その他地区の割合が高まっている。  
〔解答群〕  
  
ア  Ａ：駅周辺型Ｂ：ロードサイド型  
Ｃ：オフィス街地区Ｄ：住宅地区  
  
イ  Ａ：駅周辺型Ｂ：ロードサイド型  
Ｃ：住宅地区Ｄ：オフィス街地区  
  
ウ  Ａ：ロードサイド型Ｂ：駅周辺型  
Ｃ：オフィス街地区Ｄ：住宅地区  
  
エ  Ａ：ロードサイド型Ｂ：駅周辺型  
Ｃ：住宅地区Ｄ：オフィス街地区`
        },
        {
                "mondaiId": 104230250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
経済産業省が提唱する環境配慮型小売（エコストア）に関する説明として、最も不  
適切なものはどれか。  
  
ア  カーボンオフセット付きプライベートブランド商品を開発する。  
  
イ  店舗の照明を発光ダイオードからハロゲンランプに切り替える。  
  
ウ  風力発電や太陽光発電等の新エネルギーを導入する。  
  
エ  レジ袋を有料化し、「マイバッグ」の利用を呼びかける。  
`
        },
        {
                "mondaiId": 104230260,
                "name": "第26問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
食料品等の日常の買い物が困難な状況におかれる人々が増加している。これらの  
人々を一般に「買い物弱者」という。このような状況が発生した背景として、最も不  
適切なものはどれか。  
  
ア  商圏内人口が減少したことによって、中小小売業だけでなく大規模小売業にお  
いても経営が成り立ちにくくなったこと。  
  
イ  地域公共交通機関の廃止や運行本数の削減により、交通機能が弱体化したこ  
と。  
  
ウ  中小小売業者の高齢化や後継者難などにより、閉店する店舗が増加したこと。  
  
エ  日本の食料品自給率が、他の先進国に比べて低いこと。`
        },
        {
                "mondaiId": 104230270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
インターネットの普及に伴って、商品の仕入れなどに電子商取引が活用される  
ようになった。複数の売り手と複数の買い手が参加する電子商取引市場である  
e―マーケットプレイスに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  e―マーケットプレイスでは、買い手は複数の売り手の価格を比較できる。  
  
イ  e―マーケットプレイスは、スポット取引に向いていない。  
  
ウ  e―マーケットプレイスへの参加は、買い手にとっての取引機会の拡大につな  
がる。  
  
エ  e―マーケットプレイスへの参加は、調達コスト削減につながる。  
`
        },
        {
                "mondaiId": 104230280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
PI（PurchaseIncidence）値に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  PI値は、顧客ロイヤルティの分析に役立てることができる。  
  
イ  PI値は、商品のライフサイクルの判断に役立てることができる。  
  
ウ  PI値は、店舗間で比較することによって、売り場の改善に役立てることがで  
きる。  
  
エ  ある商品のPI値が判明している場合、来客数の予測に基づいてその商品の販  
売数量を予測することができる。`
        },
        {
                "mondaiId": 104230290,
                "name": "第29問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
ある小売店では、当月売上高予算２５０万円、年間売上高予算２，４００万円、年間予  
定商品回転率が\u0002回転である。この場合に、基準在庫法によって月初適正在庫高を  
算出するといくらになるか。最も適切なものを選べ。  
  
ア  ４００万円  
  
イ  ４５０万円  
  
ウ  ５００万円  
  
エ  ６００万円  
  
オ  ６５０万円  
`
        },
        {
                "mondaiId": 104230300,
                "name": "第30問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
次の文中の空欄に入る最も適切なものを下記の解答群から選べ。  
ある店舗のTシャツの売価は、５００円、９８０円、１，２００円、１，９８０円、２，５００円  
であり、\u0002カ月当たりの販売点数はそれぞれ、２５点、３０点、２８点、３５点、１８点  
であった。このとき、プライスポイントはである。  
〔解答群〕  
  
ア  \u0003つ  
  
イ  ２８点  
  
ウ  ５００円～２，５００円  
  
エ  １，２００円  
  
オ  １，９８０円`
        },
        {
                "mondaiId": 104230310,
                "name": "第31問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
商品の陳列は、大きく補充型陳列（オープンストック）と展示型陳列（ショーディ  
スプレイ）に分けられる。補充型陳列と展示型陳列に関する記述として、最も不適  
切なものはどれか。  
  
ア  ゴンドラを用いて補充型陳列をする場合は、前進立体陳列や先入れ先出し法を  
実施することが重要である。  
  
イ  展示型陳列におけるスタンド陳列とは、マネキンに衣料などを着せて展示する  
陳列方法である。  
  
ウ  展示型陳列におけるステージ陳列では、テーマにふさわしい商品を組み合わせ  
て提案することが重要である。  
  
エ  補充型陳列は、一般的に購買頻度が高い定番的な商品を効率的に補充し、継続  
的に販売するための陳列方法である。  
`
        },
        {
                "mondaiId": 104230320,
                "name": "第32問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
商品予算計画に関する算出数値として、<u>最も不適切なものはどれか</u>。  
  
ア  １，８００円で仕入れた商品を売価値入率２５％で販売する場合、販売価格は  
２，４００円である。  
  
イ  ある小売店の\u0002年間の粗利益高が１，３００万円、年間平均在庫高（原価）が５００万  
円である場合、GMROIは２６０％である。  
  
ウ  ある商品の売上高粗利益率が３０％であり、商品回転率が\u0003回転である場合  
に、交差主義比率は\u0004％である。  
  
エ  期首商品棚卸高６００万円、期末商品棚卸高４００万円、年間売上高３，０００万円の  
場合に、商品回転率を求めると、\u0003回転である。`
        },
        {
                "mondaiId": 104230330,
                "name": "第33問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３３問  
わが国で運用されている一括物流センターに関する記述として、最も不適切なも  
のはどれか。  
  
ア  一括物流センターでは、出荷時に小売店舗側に事前出荷明細を送信することに  
より店舗側での検品の負担を減らすことが可能である。  
  
イ  一括物流センターでは、特定小売チェーン専用の物流センターの運営を\u0005PL  
（ThirdPartyLogistics）事業者が受託する場合が多い。  
  
ウ  一括物流センターの機能には、在庫型センター（DC）機能と通過型センター  
（TC）機能がある。  
  
エ  カテゴリー納品を効率的に行うためには、ベンダーで店舗別にあらかじめ仕分  
けされたものを通過型センター（TC）で荷合わせする必要がある。  
  
オ  在庫型センター（DC）では、特定の卸売業者が運営を受託する場合、他の卸売  
業者の在庫を共同保管し一括物流を実現する。  
`
        },
        {
                "mondaiId": 104230340,
                "name": "第34問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３４問  
加工食品などの最寄品の流通で、定番商品と特売商品を分離し、特売商品を事前  
に取り決めた販売促進計画に従って発注する方式を導入する場合がある。この定  
番・特売分離発注方式を導入した場合に期待される効果に関する記述として、最も  
不適切なものはどれか。  
  
ア  定番商品の小売店頭での欠品率の引き下げ  
  
イ  定番商品の発注から店舗納品までのリードタイムの短縮  
  
ウ  定番商品の物流センターでの在庫削減  
  
エ  特売商品の物流センターでの在庫削減  
  
オ  特売商品のメーカー倉庫での在庫削減`
        },
        {
                "mondaiId": 104230350,
                "name": "第35問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３５問  
物流センター内の荷役作業に関する記述として、最も適切なものはどれか。  
  
ア  出荷件数をパレート分析した結果、Aランクとなった商品を出荷口に近い場所  
に保管することが効率的である。  
  
イ  取り扱い品目の入れ替わりが頻繁な場合、ハンディ端末による荷役作業は適さ  
ない。  
  
ウ  納品対象��なる品目が特定品目に集中している場合は、シングルピッキング方  
式が適している。  
  
エ  フリーロケーションによる保管管理を行う場合、作業効率は向上するが保管効  
率は低下する。  
`
        },
        {
                "mondaiId": 104230360,
                "name": "第36問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３６問  
輸送管理に関する記述��して、最も適切なものはどれか。  
  
ア  小売チェーンのドミナント出店戦略は、物流センターから店舗への商品配送効  
率の向上に有効である。  
  
イ  仕入先ごとの納品から共同配送に切り替えると物流サービス水準は低下する。  
  
ウ  仕入先から小売専用物流センターへの\u0002回当たりの納品量が多く納品頻度が低  
い場合は、当該センターから仕入先を巡回し、集荷する方式が適している。  
  
エ  輸送における運行効率は、実働率と積載効率の積として求められる。`
        },
        {
                "mondaiId": 104230370,
                "name": "第37問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３７問  
ユニットロードシステムの導入効果に関する記述として、最も不適切なものはど  
れか。  
  
ア  国際複合一貫輸送が可能になる。  
  
イ  コンテナやパレットの滞留や偏在を防止することが可能になる。  
  
ウ  出荷地点から着地点まで荷姿を崩すことなく納品することが可能になる。  
  
エ  荷役機械の活用により荷役作業の自動化・省力化が可能になる。  
  
オ  包装費の削減や貨物の破損の防止が可能になる。  
`
        },
        {
                "mondaiId": 104230380,
                "name": "第38問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３８問  
物流センター内でのLSP（LaborSchedulingProgram）の活用に必要な情報に関す  
る記述として、<u>最も不適切なものはどれか</u>。  
  
ア  入出庫数量の変動に連動しない固定作業の情報  
  
イ  入出庫予定数量情報  
  
ウ  在庫の保管ロケーション情報  
  
エ  配送先の立地に関する情報  
  
オ  物流センター作業の標準時間情報`
        },
        {
                "mondaiId": 104230390,
                "name": "第39問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３９問  
POSシステム導入の直接的な狙いについて、最も適切なものの組み合わせを下  
記の解答群から選べ。  
ａ顧客の囲い込み  
ｂ個人情報の保護  
ｃ従業員による不正の防止  
ｄ受発注業務の効率化  
ｅ伝票処理業務の合理化  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｃとｅ  
  
オ  ｄとｅ  
`
        },
        {
                "mondaiId": 104230400,
                "name": "第40問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４０問  
EDIに関する説明として、最も適切なものの組み合わせを下記の解答群から選  
べ。  
ａ伝票をOCR処理して電子化する場合は、EDIの一形式と考えられる。  
ｂXML形式でデータ交換を行う場合、ブラウザ操作などの人手を介する必要が  
ある。  
ｃ企業固有の独自仕様でなく標準的なデータ形式を用いる場合を、オープン  
EDIという。  
ｄ電子メールにファイルを添付しデータ交換を行う場合を、e-mailEDIとい  
う。  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 104230411,
                "name": "第41問設問１",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４１問  
次の文章を読んで、下記の設問に答えよ。  
スーパーW店では、ある期間の来店客１，０００人分のPOSデータを用いて、\u0002回  
の買い物で同時に購入する商品の組み合わせを分析した。その結果、商品Aの購  
入者が２００人、商品Bの購入者が１５０人、両方を購入した者が７５人であった。  
（設問1））  
「来店客が、商品Aを購入するとき、その何パーセントが商品Bを同時に購入  
するか」を計算したとき、その結果として、最も適切な値はどれか。  
  
ア  ７．５％  
  
イ  ３７．５％  
  
ウ  ５０．０％  
  
エ  ６０．０％  
  
オ  ７５．０％  
`
        },
        {
                "mondaiId": 104230412,
                "name": "第41問設問２",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
この結果から、「商品Aを購入する客は、同時に商品Bを購入する」という  
「ルール」が有効であるかどうかを検討したい。分析結果を評価する指標のひとつ  
である「信頼度（コンフィデンス）」に関する記述として、最も適切なものはどれ  
か。  
  
ア  期間内の来店客のうち、「商品Aと商品Bを同時に購入する客」の割合、つ  
まり「ルール」を満たす客が出現する割合。  
  
イ  「期間内の来店客のうち、商品Aと商品Bを同時に購入する客の割合」÷「商  
品Aを購入する客が商品Bを同時に購入する割合」の値。  
  
ウ  商品Aを購入する客は、商品Aを購入しない客に比べて、商品Bを購入す  
る割合がどれくらい高いかを示す指標。  
  
エ  商品Aを購入する客の何パーセントが商品Bを購入しているか、を計算し  
た値。`
        },
        {
                "mondaiId": 104230420,
                "name": "第42問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４２問  
POSシステムにおけるPLU（PriceLookUp）方式に関する記述として、最も不適  
切なものはどれか。  
  
ア  JANシンボルの最初の\u0002桁（プレフィクス）に、２０～２９、０２が用いられる場合  
は、JANシンボルの中に売価が必ず表示されている。  
  
イ  生鮮食品のような量り売り商品で、価格が個々に異なるものでは、PLU方式  
ではなく、NonPLU方式が使用されることが多い。  
  
ウ  製造・出荷段階でJANシンボルが印刷されない商品の場合、NonPLUコード  
だけでなく、PLUコードの利用も可能である。  
  
エ  製造・出荷段階でJANシンボルが印刷される商品の場合、JANシンボルの中  
に売価が表示されないPLUコードを利用する。  
`
        },
        {
                "mondaiId": 105230010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A、B、C、Dの\u0002人は、株式会社を設立することを考えている。\u0002名全員が発  
起人となり、資本金額は１，２００万円を予定しており、各人の出資内容は以下のとお  
りである。また、A、B、Cは取締役となり、Dは監査役となる。Dは税理士であ  
る。これに関し、下記の解答群のア～エに示す\u0002人の発言のうち最も適切なものを  
選べ。  
（出資内容）  
A：現金５０万円、X社株式２５０万円分（X社は東京証券取引所一部上場企業）  
B：商品３００万円分  
C：現金２００万円、什器備品類１００万円分  
D：現金３００万円  
〔解答群〕  
  
ア  Aの発言  
「私が現物出資するX社株式は、上場企業の株式であるので、定款認証の日  
の\u0003か月前から前日までの終値の平均の金額を基準として算定してあれば、検  
査役の検査は不要となるはずだ。」  
  
イ  Bの発言  
「私が現物出資する商品は、税理士であるDがその金額が相当であることに  
ついて証明してくれれば、検査役の検査は不要となるはずだ。」  
  
ウ  Cの発言  
「A、Bが現物出資する物について検査役の検査が不要となれば、私が現物  
出資する什器備品類だけなら１００万円分なので、検査役の検査は不要となるは  
ずだ。」  
  
エ  Dの発言  
「検査役の検査が必要となると面倒だから、Aが出資する株式は１５０万円  
分、Bが出資する商品は２５０万円分として、それぞれ現金を増やそう。そうす  
れば、現物出資の総額が５００万円だから、検査役の検査は不要なはずだ。」  
`
        },
        {
                "mondaiId": 105230020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
東京に本社があるX株式会社（以下「X社」という。）は、事業再編の一環として、  
会社分割の手法を利用して、札幌支店における事業全部を、札幌にある関連会社の  
Y株式会社（以下「Y社」という。）に移転することを検討している。この場合、X社  
又はY社の債権者であるA社～D社のうち、X社又はY社において、債権者保護  
手続（通知・公告）を行う必要がある債権者として最も適切なものの組み合わせを下  
記の解答群から選べ。なお、会社法第７５８条第\u0002号・第７６０条第\u0003号に掲げる事項  
についての定めはなく、また、簡易分割にも該当しないものとする。  
A社：X社本社の事業に関する債権者で、分割対象の負債にはせず、分割後もX  
社で取引及び支払を行う。  
B社：X社札幌支店の事業に関する債権者で、分割対象の負債として、分割時点の  
負債をY社が引き継ぎ（X社は支払の義務を負わない）、分割後はY社だけ  
が取引及び支払を行う。  
C社：X社本社及び札幌支店の事業に関する債権者で、札幌支店分の負債について  
は、分割対象の負債として、Y社が引き継いで支払うこととしたいが、区別  
がはっきりしない部分もあるので、分割時点の負債全額について、X社が支  
払うこととし、分割後は、X社、Y社それぞれが自社の分を支払う。  
D社：Y社の債権者  
〔解答群〕  
  
ア  A社とB社  
  
イ  A社とC社  
  
ウ  B社とD社  
  
エ  C社とD社  
`
        },
        {
                "mondaiId": 105230030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A株式会社（以下「A社」という。）とB株式会社（以下「B社」という。）は、A社を  
吸収合併消滅会社、B社を吸収合併存続会社として、おおむね以下のスケジュール  
で吸収合併を実施する予定である。その際、A社の吸収合併の手続に関する記述と  
して最も適切なものを下記の解答群から選べ。なお、A社の定款には、関連する事  
項について特段の定めはなく、また、A社は、発行する株式の全てが譲渡制限株式  
であり、かつ、取締役会設置会社であるものとする。  
平成２４年\u0002月２０日（月）吸収合併契約の調印  
平成２４年\u0003月１４日（水）吸収合併契約承認の株主総会  
平成２４年\u0004月\u0005日（日）吸収合併の効力発生日  
〔解答群〕  
  
ア  A社では、吸収合併契約書を本店に備え置く必要があるが、本件の場合、吸  
収合併契約調印の翌日の平成２４年\u0002月２１日から備置きを実施しなければ、本  
件吸収合併は無効となる。  
  
イ  A社の株主に対しては、株主総会の招集通知と株式買取請求権に関する通知  
をしなければならないが、前者は株主総会の\u0005週間前まで、後者は吸収合併の  
効力発生日の２０日前までと決まっているので、同時に通知することはできな  
い。  
  
ウ  A社の債権者が\u0005社だけの場合であっても、A社では、その債権者に対して  
本件吸収合併について通知するだけでなく、吸収合併の公告を行わなければな  
らない。  
  
エ  今回の吸収合併の場合、効力発生日が日曜日にあたり、法務局で登記を受け  
付けてもらえないため、A社の登記上、解散の理由は、実際に登記申請した日  
に合併した旨記載され、平成２４年\u0004月\u0005日に合併した旨は記載されない。  
`
        },
        {
                "mondaiId": 105230040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
X株式会社の法的倒産手続（再建型）に関し、債権者\u0002～\u0003までの債権額及び計画  
案に対する賛否は以下のとおりである。  
このとき、X株式会社の法的手続が、民事再生手続であった場合の再生計画案と  
会社更生手続であった場合の更生計画案それぞれの可決の成否について、最も適切  
なものを下記の解答群から選べ。なお、\u0002～\u0003の債権はすべて一般債権でかつ債権  
額が議決権額とし、それ以外の可決要件はすべて充足しているものとする。  
債権者番号債権額賛否  
\u0002２０万円反対  
\u0004３０万円反対  
\u0005５０万円賛成  
\u0006１００万円反対  
\u0007３００万円反対  
3１，５００万円賛成  
３，５００万円反対  
        
４，５００万円賛成  
\u000b\u0002億円反対  
\f\u0003億円反対  
\u0003１０億円賛成  
合計１８億円  
（賛否の内訳）  
賛成：人数\u0003名、債権額１０億６，０５０万円  
反対：人数\u0004名、債権額\u0004億３，９５０万円  
〔解答群〕  
  
ア  再生計画案の場合も更生計画案の場合も、ともに可決される。  
  
イ  再生計画案の場合も更生計画案の場合も、ともに否決される。  
  
ウ  再生計画案の場合は可決されるが、更生計画案の場合は否決される。  
  
エ  再生計画案の場合は否決されるが、更生計画案の場合は可決される。  
`
        },
        {
                "mondaiId": 105230050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章は、中小企業診断士であるあなたと、顧客であるX株式会社の代表取  
締役の甲氏との間の会話である。この会話の空欄ＡとＢに入る用語の組み合わせと  
して最も適切なものを下記の解答群から選べ。  
甲氏：「私の会社もある程度大きくなって、取引先も大手の会社が増えてきたか  
ら、社員の肩書を変えようかと思いましてね。」  
あなた：「どのようなものをお考えなんですか。」  
甲氏：「何かこう見栄えのいいのがいいなあと思いましてね。取引先でもらう名  
刺なんか見ると、エグゼクティブ何とかとか、何ちゃらプレジデントと  
か、何とかＡとか、いろいろあるからそれを参考にして、営業本  
部長を業務Ｂとかそういった名前にしようかなと思っているんで  
すよ。」  
あなた：「えっ、そのＢという肩書ですと、本当は会社法上の機関でない  
のに、会社法上の機関、役員と間違われてしまいますよ。」  
甲氏：「えっ、役員ということは取締役と一緒ということですか。それじゃあう  
まくないなあ。そうすると、Ａというのも、機関というものにな  
るわけですか。」  
あなた：「いえ、Ａという名称は、法律上にこれといった根拠があるもの  
ではなく、最近の実務慣行で使われるようになった名称ですので、会社法  
上の機関ではありません。そういったこともあって、取締役といった役員  
の名称とは別に、Ａという名称を使っている場合もあります。」  
甲氏：「へえ。じゃあＡという名称はどういったときに使えばいいんで  
すか。」  
あなた：「いろいろなケースがあるので一概にはいえません。会社との間の契約の  
内容も様々といわれています。」  
〔解答群〕  
  
ア  Ａ：CFOＢ：執行役員イＡ：執行役Ｂ：CFO  
  
ウ  Ａ：執行役Ｂ：執行役員エＡ：執行役員Ｂ：執行役  
`
        },
        {
                "mondaiId": 105230061,
                "name": "第6問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたと、顧客であるX株式会社（以下「X社」という。）の  
代表取締役の甲氏との会話を読んで、下記の設問に答えよ。なお、X社は、発行す  
る株式の全てが譲渡制限株式であり、取締役会設置会社であるとする。また、定款  
に特段の定めもないものとする。  
甲氏：「そういえば、こんな書類が昨日来たんだよね。」  
あなた：「えーと、Aさんが持っている御社の株式をBさんに譲渡したいから承認  
して欲しい……。株式の譲渡承認請求の通知ですね。」  
甲氏：「うん。そうなんだよ。この譲渡人って書いてあるAさんは、元々はうち  
の取引先だったんだけど、２０年近く前に店を閉めてしまってね。その後  
もずっとうちの株を持っていてくれていたんだけど、もうさすがに譲渡し  
たいということのようなんだ。株主が他の人に交代するのは仕方がないか  
なとは思うけど、ただ、今回来た書類に書いてあるBさんという人は全  
然知らない人だから、不安なんだよね。」  
あなた：「もし、Bさんが株主となるのが、御社では好ましくないとお考えなら、  
今回の譲渡を拒否することもできるはずですよ。」  
甲氏：「そうなの。どうすればいいの。」  
あなた：「えーと、確か、拒否するんだったら早いうちに回答をしないといけない  
はずで、回答しないと認めたことになってしまうと思います。私も細かい  
ところまでは分かりませんので、弁護士を紹介しますから、すぐに相談に  
行かれてはいかがですか。」  
甲氏：「ありがとう。早速相談に行ってみるよ。」  
        
（設問1））  
会社法では、譲渡制限株式の譲渡について、譲渡承認請求がなされた日から一  
定期間内に、会社がその承認の可否に関する決定の通知をしなかった場合には、  
会社がその譲渡を承認したものとみなす旨の定めがある。この場合の一定期間と  
して最も適切なものはどれか。  
  
ア  \u0002週間  
  
イ  \u0003週間  
  
ウ  ２０日間  
  
エ  \u0002か月`
        },
        {
                "mondaiId": 105230062,
                "name": "第6問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
X社で、本件の譲渡承認請求の可否について決定すべき機関として、最も適切  
なものはどれか。  
  
ア  株主総会  
  
イ  代表取締役  
  
ウ  代表取締役又は株主総会のいずれか  
  
エ  取締役会  
`
        },
        {
                "mondaiId": 105230070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許・意匠登録・商標登録制度に関する記述として、最も不適切なものはどれ  
か。  
  
ア  意匠登録出願人は、特許出願人と異なり、意匠権設定の登録の日から\u0002年以内  
の期間を指定して、その期間その意匠を秘密にするよう請求することができる。  
  
イ  特許権の存続期間の始期は、意匠権及び商標権と同様に設定登録の日から起算  
される点で共通し、設定登録の日から２０年をもって終了する。  
  
ウ  特許出願は、意匠登録出願及び商標登録出願と異なり、出願審査の請求を待っ  
て審査官により特許を受けることができる発明であるかについて審査が行われ  
る。  
  
エ  特許出願は、意匠登録出願と異なり、特許出願の日から\u0003年\u0004月を経過したと  
きは特許掲載公報の発行したものを除き、願書に記載した事項及び願書に添付し  
た明細書等に記載した事項並びに図面の内容等が出願公開される。  
`
        },
        {
                "mondaiId": 105230080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の記述は、ある条約（以下「A」という。）に関するものである。「A」に該当するも  
のとして最も適切なものを下記の解答群から選べ。  
日本が１９９９年１２月１４日に加盟し、２０００年\u0002月１４日付で効力が発生した商標  
登録の手続のための条約で、現在のところ、加盟国（地域を含む）は、８０か国以上  
に及んでいる。  
商標その他特許、実用新案及び意匠は、基本的には属地主義のために各国（若し  
くは地域、以下、「各国」という。）ごとに登録しなければならないが、各国別に出願  
手続を行う場合、各国ごとの所定の条件に従わなければならず、あるいは料金の支  
払いが必要となる。そして、各国別にそこに居住する弁護士又は弁理士によらなけ  
れば出願手続ができない。  
そこで、これらの不都合を解消するため、世界知的所有権機関WIPO（国際事務  
局）は、１８９１年\u0003月に「A」の親というべき条約を制定したが、未加盟国から、使用  
言語（フランス語のみ）、審査期間（１２か月）、本国登録への従属性（国際登録日から  
\u0004年を経過していない国際登録に関して本国登録が何らかの理由により消滅した場  
合には、国際登録も同時に消滅する）などその加盟を困難にさせる問題点があるこ  
とが指摘された。「A」は、これらの問題点を克服して、より多くの国が参加できる  
ような商標の国際登録制度を確立することを目的に独立した条約として１９８９年\u0005  
月に採択されたものである。  
「A」の制度のもとでは、締約国の官庁に商標登録出願をし又は商標登録を受けた  
名義人は、その出願又は登録を基礎に、保護を求める締約国を指定し、本国官庁を  
通じて国際事務局に国際出願をし、国際登録を受けることにより、指定国官庁が  
１２か月（又は、各国の宣言により１８か月）以内に拒絶の通報をしない限り、その指  
定国において商標の保護を確保することができる。  
〔解答群〕  
  
ア  BeneluxConventiononIntellectualProperty  
  
イ  CommunityTradeMark  
  
ウ  ProtocolRelatingtotheMadridAgreementConcerningtheInternational  
RegistrationofMarks  
  
エ  TheParisConvention，knownastheInternationalConventionforthe  
ProtectionofIndustrialProperty  
`
        },
        {
                "mondaiId": 105230090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
商標法上の商品に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  運送業者が、貨物自動車の運送の提供に関連して使用する段ボール箱を役務の  
提供とは独立して継続的に販売する場合には、その段ボール箱は、商標法上の商  
品である。  
  
イ  商品は、流通性のあるものでなければならないため、料理屋の店内で飲食のた  
めに提供される料理は、商標法上の商品ではない。  
  
ウ  予備校で講座の教材として用いられる印刷物は、独立して取引の対象とされる  
場合であっても、商標法上の商品ではない。  
  
エ  料理屋が店頭で包装箱に入れて継続的に販売する料理は、商標法上の商品であ  
る。`
        },
        {
                "mondaiId": 105230100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
次の文章は、不正競争防止法の解説である。空欄Ａ～Ｄに入る語句の組み合わせ  
として最も適切なものを下記の解答群から選べ。  
不正競争防止法第\u0002条第\u0003項第\u0004号は、商品の形態を模倣から保護する規定であ  
る。その形態が意匠法における登録の要件をＡ。ただし、その形態が  
Ｂ形態である場合には、保護を受けることができない。また、保護を受け  
ることができる期間は、最初のＣの日からＤである。  
〔解答群〕  
  
ア  Ａ：満たさなくてもよいＢ：商品の通常有するＣ：販売Ｄ：\u0004年間  
  
イ  Ａ：満たさなくてもよいＢ：新規性のないＣ：製造Ｄ：\u0004年間  
  
ウ  Ａ：満たす必要があるＢ：商品の通常有するＣ：販売Ｄ：\u0005年間  
  
エ  Ａ：満たす必要があるＢ：新規性のないＣ：製造Ｄ：\u0005年間  
`
        },
        {
                "mondaiId": 105230110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
平成２１年\u0002月\u0003日、国際物品売買契約に関する国際連合条約（通称：ウィーン売  
買条約、CISG）が日本について発効した。この条約は、国際物品売買契約に関し、  
契約の成立及び当事者（売主・買主）の権利義務を規定するものであり、主に、異な  
る締約国に営業所を有する企業間の物品売買契約に適用されるとされている。  
この条約と日本の民法・商法その他の契約に関する規定との共通点・相違点につ  
いての記述として、最も適切なものはどれか。  
  
ア  隔地者間の契約について、日本の民法では、承諾の意思表示が発信された時に  
契約が成立するとされているが、この条約では、承諾の意思表示が申込者に到達  
した時に契約が成立するとされている。  
  
イ  この条約は、営業所が異なる国に所在する当事者（売主・買主）間の物品売買契  
約において、この条約を適用する旨定めた場合にのみ適用されるのに対して、日  
本の民法・商法は、日本に営業所が所在する当事者間の契約である限り、常に適  
用される。  
  
ウ  日本の民法・商法では、契約の解除ができる場合が「重大な契約違反」がある場  
合に限られているが、この条約にはそのような制限がない。  
  
エ  日本の民法では、申込みと承諾が完全に一致しなくても、その違いが実質的な  
ものでない場合には、契約が成立するとされているが、この条約では、申込みと  
承諾が完全に一致しなければ契約は成立しないとされている。  
`
        },
        {
                "mondaiId": 105230120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
  
ウ  ェブシステムの開発・販売、保守運用等の事業を営んでいるX社は、自社で  
開発したインターネット受発注システム（以下「本件システム」という。）を、企業向  
けウェブシステムの販売、コンサルティング等の事業を営んでいるY社に販売し  
て納品した。Y社は、X社から販売・納品を受けた本件システムを自社のエンド  
ユーザーである顧客向けに転売・納品すると同時に、転売・納品した本件システム  
の保守運用業務をX社に委託した。  
X社からY社に販売した本件システムの販売代金については、発注時に\u0002分の  
\u0003、X社による納品・Y社の検収時に\u0002分の\u0003、納品・検収から\u0004か月後に残り\u00022分の\u0003の金額を支払うとの約定であったところ、Y社は、発注時、納品・検収時の  
分割金はそれぞれ支払ったものの、残り\u0002分の\u0003の金額については支払期限が経過  
しても支払おうとしない。他方、本件システムの保守運用業務の業務委託料につい  
ては、客先での本件システムの稼働開始から\u0002か月後に\u0003回目の業務委託料を支払  
うものとのX社・Y社間の約定があり、いまだ支払期限は到来していない。  
この事例において考えられるX社のY社に対する債権回収の手段・方法に関す  
る記述として、<u>最も不適切なものはどれか</u>。  
        
  
ア  X社がY社に本件システムを販売した際に、Y社代表者Aが個人として販売  
代金の支払について連帯保証する旨X社代表者に対して発言し、X社代表者が  
口頭でAの個人保証を承諾していた場合、X社は、A個人に対して保証債務の  
履行として残代金の支払を請求することができる。  
  
イ  X社がY社に本件システムを販売した際に、Y社代表者Aが個人としても販  
売代金の支払について保証する旨の電子メールをX社代表者に送信し、X社代  
表者がAの個人保証を承諾する旨の電子メールをAに返信していた場合、X社  
は、Y社に対して本件システムの販売残代金の支払を求めることなく、A個人に  
対して保証債務の履行を請求できる。  
  
ウ  Y社が取引先企業に対する売掛債権を有している場合、X社のY社に対する  
本件システムの販売残代金債権を保全する方法として、Y社が有する売掛債権に  
対する仮差押命令の申立てが考えられる。  
  
エ  Y社の資産状況が著しく悪化した状況にある場合には、いまだ支払期限の到来  
していない本件システムの保守運用業務の業務委託料の支払が得られない危険が  
あることを理由として、X社が、Y社顧客の下で稼働中の本件システムに関する  
保守運用業務を一方的に停止することが許される場合もある。  
`
        },
        {
                "mondaiId": 105230130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
次の文章は、ゲームソフトの開発・制作等の事業を営んでいる株式会社甲の代表  
取締役社長（以下「甲社社長」という。）と、中小企業診断士であるあなたとの会話で  
ある。この会話の空欄Ａ～Ｅに入る用語の組み合わせとして最も適切なものを下記  
の解答群から選べ（※法令名は略称による。）。  
甲社社長：「ちょっと、困ったことが起こって。」  
あなた：「どうされたのですか？」  
甲社社長：「うちの会社（甲社）は、携帯電話向けSNS（ソーシャル・ネットワーキ  
ング・サイト）用のゲームソフトを開発して、大学生や社会人向けのソ  
フトをX社に、中高生向けのソフトをY社にそれぞれ供給しているん  
だけど、今回、X社との間のゲームソフト制作・開発委託契約を更新す  
る話合いの中で、同業他社に同種のゲームソフトを提供しないことを当  
社に義務付ける内容の条項を追加するようにX社から要求されている  
んだ。しかもリーガルチェックの段階で、うち（甲社）がX社の担当者  
に対してこの新条項に難色を示したら、『この条項の追加を受け入れて  
もらえなければ、御社（甲社）のゲームソフトを当社（X社）のSNSの会  
員向けゲームカテゴリーから外すことも考えなければならない。』と言わ  
れたんだよ。カテゴリーから外されると、うち（甲社）のゲームは新規会  
員の獲得ができず、ダウンロード済みの顧客からの課金収入しかなく  
なってしまうので、死活問題になっちゃうよ。」  
あなた：「ちょっと待ってください、社長。X社は携帯電話向けSNS用のオンラ  
  
イ  ンゲームでは、\u0002割以上のシェアを握っていて業界トップでしょう。  
そういう会社が社長の言われるような行為をしているとなると、  
Ａに該当する疑いがあり、Ｂで禁止されている  
Ｃの問題となる可能性があります。そんな新条項の追加要求に  
応じる必要はないと思いますよ。」  
甲社社長：「確かにそうなんだけど、うちのようなベンチャー企業は、どうしても  
大手のオンラインゲーム会社の要求をのまざるを得ないんだよなあ。何  
        
しろ、Y社が主催しているゲームフェアに参加させてもらったときは、  
ほとんどうち（甲社）のゲームが来場者の目に触れるようなブースもな  
かったのに、協賛金を負担するように要請されて支払ったこともあった  
んだ。」  
あなた：「社長、どこまでお人好しなんですか。そんな御社（甲社）の売上にとっ  
て直接的なメリットのない協賛金を負担させていたとなると、Y社の行  
為はＤに該当する可能性が高いですから、やっぱりＣ  
の問題となりますよ。そういう大手のむちゃな要求ばかりのんで、当面  
の受注は取れても、長い目で見れば御社（甲社）のためになりませんよ。」  
甲社社長：「うーん、そうなるとやっぱりどこかに相談しないと。どこか役所でこ  
ういう問題を相談できるところはないの？」  
あなた：「ＢではＣに該当する行為の排除措置を命ずる権限が  
Ｅに与えられていますし、Ｂに違反する事実があれ  
ば、だれでもその事実をＥに報告して適当な措置をとるように  
求めることができますから、そこに相談するのが筋だと思います。」  
〔解答群〕  
  
ア  Ａ：拘束条件付取引Ｂ：独占禁止法Ｃ：不当な取引制限  
Ｄ：共同の取引拒絶Ｅ：中小企業庁  
  
イ  Ａ：再販売価格の拘束Ｂ：下請法Ｃ：不公正な取引方法  
Ｄ：差別対価Ｅ：公正取引委員会  
  
ウ  Ａ：抱合せ販売等Ｂ：特定商取引法Ｃ：不当な取引制限  
Ｄ：優越的地位の濫用Ｅ：消費者庁  
  
エ  Ａ：排他条件付取引Ｂ：独占禁止法Ｃ：不公正な取引方法  
Ｄ：優越的地位の濫用Ｅ：公正取引委員会  
`
        },
        {
                "mondaiId": 105230141,
                "name": "第14問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
企業情報の法的保護に関する次の文章を読んで、下記の設問に答えよ。  
企業における技術、ノウハウ、顧客情報等の企業情報について、企業が収益を生  
み出す知的資産としての法的な保護を享受するためには、そのような企業情報が、  
Ａにより特許権等の知的財産権を取得して活用するのにふさわしいもの  
か、それともＢし、不正競争防止法上の営業秘密等の機密情報として管理  
していくのが適切なものかを振り分けていくという経営判断が必要になる。  
企業情報が不正競争防止法上の営業秘密として保護されるためには、秘密として  
管理されていること（秘密管理性）、有用な営業上又は技術上の情報であること（有  
用性）、公然と知られていないこと（非公知性）の\u0002要件をすべて満たすことが必要  
とされている。例えば、技術・ノウハウ等を記録したデータファイルが企業内の  
サーバーコンピューターに保存されていたが、アクセス制限がなくパスワードも設  
定されていないという状態では、Ｃの要件を欠き、営業秘密とは認められ  
ない可能性が高い。  
平成２１年の不正競争防止法の改正により、営業秘密の侵害行為に対する処罰範  
囲が拡大され、改正前は不正競争の目的で、詐欺、窃盗、横領等の不正な方法によ  
り営業秘密を使用し又は開示する行為等だけが処罰の対象とされていたものが、改  
正後は、\u0002不正の利益を得たり保有者に損害を加えたりする目的で、営業秘密を  
不正な方法により使用し又は開示する行為、更には、\u0003上記\u0002の目的で、不正な  
方法により、営業秘密を第三者が取得、又は従業者・取引先等が領得する行為等も  
処罰の対象とされることとなった。その結果、Ｄ行為、Ｅ行為等  
も、営業秘密の侵害として処罰されることとなった。  
        
（設問1））  
本文中の空欄Ａ～Ｃに入る用語の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  Ａ：オープン化Ｂ：ブラックボックス化Ｃ：非公知性  
  
イ  Ａ：オープン化Ｂ：ブラックボックス化Ｃ：秘密管理性  
  
ウ  Ａ：ブラックボックス化Ｂ：オープン化Ｃ：秘密管理性  
  
エ  Ａ：ブラックボックス化Ｂ：オープン化Ｃ：有用性`
        },
        {
                "mondaiId": 105230142,
                "name": "第14問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
本文中の空欄Ｄ・Ｅに入る記述の組み合わせとして、最も適切なものはどれ  
か。  
  
ア  Ｄ：機密保持契約を締結して提携先企業から提供を受けた営業秘密を、機密  
保持契約に違反して提携終了後に記憶媒体から消去したように装って実  
際には消去せず、自社の製品開発に利用する  
Ｅ：社内規定による許可無しに営業秘密記録媒体を自宅に持ち帰って残業す  
る  
  
イ  Ｄ：自社の内部告発規定に違反する方法で、自社の不正情報とともに営業秘  
密をマスコミに提供し、謝金をもらう  
Ｅ：社内規定による許可無しに営業秘密記録媒体を自宅に持ち帰って残業す  
る  
  
ウ  Ｄ：社内規定による許可無しに営業秘密記録媒体を自宅に持ち帰って残業す  
る  
Ｅ：報酬を得る目的で、保有企業に無断で営業秘密を外国政府に開示する  
  
エ  Ｄ：保有企業への嫌がらせ目的で当該企業の営業秘密をネット上の掲示板に  
書き込む  
Ｅ：報酬を得る目的で、保有企業に無断で営業秘密を外国政府に開示する  
`
        },
        {
                "mondaiId": 105230143,
                "name": "第14問設問3",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
企業の保有する技術・ノウハウ等を営業秘密として管理する場合のメリット・  
デメリットに関する記述として最も適切なものはどれか。  
  
ア  一定期間、譲渡可能な排他的独占権を取得できる一方で、出願内容を公開す  
ることが権利取得の前提となるので、自社の開発動向が他社に知られることに  
なる。  
  
イ  失敗した実験のデータ等のノウハウも保護対象となり得る一方、保護期間が  
満了すればだれでも利用可能となる。  
  
ウ  事前の審査を通じて権利の内容が明確となるが、他社が同一技術を独自開発  
した場合には独占できなくなる。  
  
エ  製品の分解等により明らかにならない限り、保護期間の制限がなく、他社と  
の差別化を図ることができる一方で、登録制度がなく、権利の存否・内容が不  
明確となりがちである。  
`
        },
        {
                "mondaiId": 105230150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
ソフトウェアプログラムのライセンスに関する英文契約（LicenseAgreement）に  
おいて、「ライセンサーは、当該プログラム（“theProgram”）が許諾地域において保  
護される第三者の知的財産権を侵害しないという保証をしない」旨を定めている条  
項として最も適切なものはどれか。  
  
ア  Licensorherebyrepresentsandwarrantsthatitisthesoleownerofthe  
proprietaryrightstotheProgramorhassufficientrightsintheProgramto  
enterintoandgranttherightssetforthinthisAgreementinthelicensed  
territory.  
  
イ  LicensorinnowaywarrantsthattheProgramdoesnotinfringeanypatent，  
trademark，tradename，copyright，tradesecretrightorotherproprietaryrights  
protectedinthelicensedterritory.  
  
ウ  LicensormakesnowarrantiesthattheProgramshallsatisfactorilyfunction  
soastofitforanyparticularpurposesorusesrequiredbythepurchasersor  
usersofendproductsinthelicensedterritory.  
  
エ  Licensorshallhavethesolerighttotakeanyactionagainstinfringementof  
allintellectualpropertyrightithas，ordefendanyactionbythirdpartiesfor  
infringementofanyotherproprietaryrightwithrespecttotheProgram.  
`
        },
        {
                "mondaiId": 105230160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
下図は、日本の代表的な新興株式市場であるジャスダック、マザーズ、ヘラクレ  
ス市場の新規上場会社数の推移である。図の\u0002～\u0003に当てはまる市場名称の組み合  
わせとして最も適切なものを下記の解答群から選べ。  
なお、ジャスダック市場の上場会社数には前身の店頭登録会社数を、ヘラクレス  
市場の上場��社数には前身のナスダックジャパン市場の会社数を含めて集計してい  
る。  
〔解答群〕  
  
ア  \u0002：ジャスダック\u0004：ヘラクレス\u0003：マザーズ  
  
イ  \u0002：ジャスダック\u0004：マザーズ\u0003：ヘラクレス  
  
ウ  \u0002：マザーズ\u0004：ジャスダック\u0003：ヘラクレス  
  
エ  \u0002：マザーズ\u0004：ヘラクレス\u0003：ジャスダック  
`
        },
        {
                "mondaiId": 105230170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
下表は、株式会社における少数株主からの主な権利行使の決議要件を整理したも  
のである。表の\u0002～\u0003に入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
なお、本問での会社は、非公開会社で取締役会設置会社であることを前提として  
いる。  
権利の内容決議要件  
\u0002総株主の議決権の  
\u0002％以上または  
３００個以上  
総会検査役選任請求権総株主の議決権の\u0002％以上  
総会招集請求権総株主の議決権の\u0003％以上  
\u0004総株主の議決権または発行済  
株式の  
\u0003％以上  
取締役・監査役に対する  
解任請求権  
総株主の議決権または発行済  
株式の  
\u0003％以上  
\u0003総株主の議決権または発行済  
株式の  
１０％以上  
〔解答群〕  
  
ア  \u0002：会計帳簿閲覧請求権\u0004：会社解散請求権  
\u0003：株主提案権  
  
イ  \u0002：会計帳簿閲覧請求権\u0004：株主提案権  
\u0003：会社解散請求権  
  
ウ  \u0002：株主提案権\u0004：会計帳簿閲覧請求権  
\u0003：会社解散請求権  
  
エ  \u0002：株主提案権\u0004：会社解散請求権  
\u0003：会計帳簿閲覧請求権  
`
        },
        {
                "mondaiId": 105230181,
                "name": "第18問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
会計参与の制度に関連する下記の設問に答えよ。  
なお、下記の設問の会社は、非公開会社で委員会設置会社でない株式会社を前提  
とする。  
（設問1））  
会計参与の設置に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  新たに会計参与設置会社とするためには定款を変更しなければならないの  
で、株主総会の特別決議が必要となる。  
  
イ  会計参与が何らかの事情で欠けた場合に備えて、補欠の会計参与を選任する  
ことができる。  
  
ウ  会計参与の任期は、監査役と同様であり、原則として選任後\u0003年以内に終了  
する事業年度の定時株主総会の終結の時までである。  
  
エ  会計参与を新たに設置した場合には、その旨ならびに会計参与の氏名または  
名称および計算書類等の備え置きの場所を登記しなければならない。`
        },
        {
                "mondaiId": 105230182,
                "name": "第18問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
会計参与の職務と責任に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  会計参与が悪意または重過失により計算書類等に虚偽の記載を行い第三者に  
損害を与えた場合には、その損害賠償責任を負う。  
  
イ  会計参与は、各事業年度の計算書類等を\u0005年間備え置き、その業務時間内で  
あれば株主や債権者からの閲覧や謄写等の請求に応じる義務がある。  
  
ウ  会計参与は、取締役と共同して計算書類等を作成するとともに、それが適正  
に表示されているかの意見を付した会計参与報告書を作成する。  
  
エ  取締役会を設置する会社は監査役を置かなければならないが、会計参与設置  
会社では監査役の設置は不要となる。  
`
        },
        {
                "mondaiId": 105230191,
                "name": "第19問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
社債発行に関連する下記の設問に答えよ。  

（設問１）  
社債の発行に関する記述として、<u><u>最も不適切なものはどれか</u></u>。  
  
ア  株式会社が社債を発行するためには、取締役会設置会社では取締役会の決議が必要である。  
  
イ  銀行、信託会社、<hintTarget>証券会社</hintTarget>は、社債権者の利益保護のために設置される<hintTarget>社債管理者</hintTarget>となることができる。  

<hint hidden='true'>**
❌証券会社は社債管理者になれません。
**</hint>  
  
ウ  社債券を発行しない社債の発行も認められる。この場合、社債譲渡の効力を会社その他の第三者に対抗するためには社債原簿の書き換えが必要となる。  
  
エ  社債は、株式会社のみならず合名会社、合資会社、合同会社でも発行することができる。


`
        },
        {
                "mondaiId": 105230192,
                "name": "第19問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
次の文中の空欄に入る記述として最も適切なものを下記の解答群から選べ。  
社債の発行は、金融商品取引法の規制の対象となる。これに対して、少人数の  
縁故者を対象として社債を発行する少人数私募債は、同法に定める有価証券の募  
集の要件に該当しないため、簡易に社債を発行することができる。  
募集の具体的な要件は、新たに発行される社債の未満であり、か  
つ、多数の者に譲渡される恐れが少ないことである。なお、この人数には過去\u0004  
か月以内に同一種類の社債を発行している場合にはそれも合計しなければならな  
い。  
〔解答群〕  
  
ア  最終の取得者の人数が５０名  
  
イ  取得勧誘の相手方の人数が\u0005名  
  
ウ  取得勧誘の相手方の人数が５０名  
  
エ  取得勧誘の相手方の人数が５００名  
`
        },
        {
                "mondaiId": 106230010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務で高精細な表現力が必要な図面や動画などを利用する場合、コンピュータの  
グラフィック出力における解像度や描画速度に気を配らなければならない。グラ  
フィック出力を担うビデオカードに関する以下の文中の空欄Ａ～Ｃに入る語句の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  
グラフィック出力の性能をより高性能化したい場合は、ビデオカードを取り替え  
ることで対応できる。このビデオカードにおいてグラフィック出力の性能にかかわ  
る部分は、Ａの処理速度、Ｂの容量、Ｃの転送速度であ  
る。  
〔解答群〕  
  
ア  Ａ：CPUＢ：キャッシュＣ：ネットワーク  
  
イ  Ａ：GPUＢ：VRAMＣ：インタフェース  
  
ウ  Ａ：PCIExpressＢ：ROMＣ：ATA  
  
エ  Ａ：キャッシュＢ：主記憶Ｃ：レジスタ  
`
        },
        {
                "mondaiId": 106230020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務に使用しているパーソナルコンピュータ（PC）の応答速度が遅くなってき  
た。その際の状況と対策を記述した次の文中の空欄Ａ～Ｄに入る語句の組み合わせ  
として、最も適切なものを下記の解答群から選べ。  
業務に使用しているPCには仮想記憶を利用するオペレーティングシステム  
（OS）がインストールされている。最近、PCを使用した作業中にＡにアクセスしている頻度が高くなり、速度低下は仮想記憶に関連する部分で発生して  
いることが疑われた。そこで、Ｂの利用状況を調査したところＣ  
が頻繁に発生していることが判明した。そこで、Ｄ、改善を図った。  
〔解答群〕  
  
ア  Ａ：DVD装置  
Ｂ：DVDメディア  
Ｃ：断片化  
Ｄ：DVDメディアの不要なディレクトリ（フォルダ）やファイルを消去し  
  
イ  Ａ：LAN  
Ｂ：LANのパケット  
Ｃ：分断化  
Ｄ：使用していないLAN接続の周辺機器の電源を切断し  
  
ウ  Ａ：ハードディスク  
Ｂ：主記憶装置  
Ｃ：スワッピング  
Ｄ：主記憶装置の増設を行い  
  
エ  Ａ：ハードディスク  
Ｂ：主記憶装置のキャッシュ  
Ｃ：キャッシュミス  
Ｄ：性能の高いCPUに取り替え  
`
        },
        {
                "mondaiId": 106230030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、情報システムの開発には多様な言語が用いられるようになってきた。それ  
らを適切に使い分けるためには、各言語の特徴を把���しておく必要がある。言語に  
関する説明として最も適切なものはどれか。  
  
ア  COBOLは科学技術計算のために開発された手続き型プログラミング言語であ  
る。  
  
イ  HTMLはWWWで使用されるハイパーテキストを記述するための言語で、  
SGMLの元となっている言語である。  
  
ウ  JavaはUNIX用のテキスト処理用言語として開発されたインタプリタ型言語  
である。  
  
エ  XMLはネットワーク上でデータ交換に使用される言語で、近年ではデータ  
ベースの開発にも利用されている。`
        },
        {
                "mondaiId": 106230040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータを用いて業務データの処理を行う場合は、処理対象となるデータ量  
を考慮し、業務の要求を満たす時間内に、プログラムによる処理が終了するよう配  
慮する必要がある。このためには、処理速度を考慮した適切な処理方法を選択しな  
ければならない。プログラムによる処理方法に関する記述として最も適切なものは  
どれか。  
  
ア  売上高を変数に格納し集計処理するプログラムでは、売上高を整数の型で格納  
しても、実数の型で格納しても集計処理速度は変わらない。  
  
イ  売上高の標準偏差を求める処理手順では、売上高の合計を求めてから売上高の  
\u0002乗の合計を求める方法に比べ、売上高の合計と、売上高の\u0002乗の合計を、同じ  
繰り返し処理の中で求める方法の方がプログラムの処理速度が速い。  
  
ウ  売上高の平均を求める処理手順では、売上高データがあらかじめ大きい順また  
は小さい順に並べ替えられていた場合の方が、並べ替えを行っていない場合に比  
べ、プログラムの平均処理速度は速い。  
  
エ  取引先の住所データを磁気ディスクに格納したまま検索処理をした場合も、主  
記憶の配列に格納して検索処理した場合も、検索処理速度は大きくは変わらない。  
`
        },
        {
                "mondaiId": 106230050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
表計算ソフトウェアに、下表のような年度ごとの売上数量データが入力されてい  
る。この表の列Cに２０００年度の売上数量を\u0002とした場合の、各年度の売上数  
量の増減割合を計算したい。このときC２セルに式を入力し、それをC３～C１０の  
セルに複写する。はじめにC２セルに入力する式として最も適切なものを下記の解  
答群から選べ。  
ただし、セル番地指定における$記号は絶対参照を表すものとする。  
ＡＢＣ  
１年度売上数量増減割合  
２２０００１２３０  
３２００１１５６０  
４２００２１４７０  
５２００３９８０  
６２００４１６８０  
７２００５２０１０  
８２００６１３２０  
９２００７２３８０  
１０２００８１８５０  
〔解答群〕  
  
ア  ＝B$２/B$２  
  
イ  ＝B$２/B２  
  
ウ  ＝B２/$B２  
  
エ  ＝B２/B$２  
`
        },
        {
                "mondaiId": 106230060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
社内において業務に使用するPCや周辺機器が増え、それらの役割も多様化して  
きたので、LAN環境の構築を検討している。LAN環境におけるPCや周辺機器配  
備に関する記述として最も適切なものはどれか。  
  
ア  LANに接続されたPCにUSB接続されたプリンタを、同じLAN上の他のPC  
から利用可能にすることはできない。  
  
イ  業務を行うための処理を水平分散の考え方に基づいて行う場合は、複数のPC  
をRAID\u0002によって構成し、ストライピングによって処理を分散させ、処理効率  
の向上を図る。  
  
ウ  クライアントPCで必要最小限の機能しか持たず、処理のほとんどをサーバマ  
シンに依存する端末をファットクライアントという。  
  
エ  サーバ機能とクライアント機能はソフトウェアによって実現するので、それら  
の機能を複数のPCに分散させることも、単一のPCに持たせることもできる。  
`
        },
        {
                "mondaiId": 106230070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　Webサイトの構築にあたって、閲覧者に対して働きかける様々な方法が利用可能になっている。  

　そのような方法に関する以下のａ～ｃの説明と、それに対応する用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ　HTML文書の中にJavaScriptなどのスクリプト言語を埋め込んだり、CSSを使用して、デザイン性が高く、動きがあって対話性を持ったWebページを作成する方法。<hint hidden='true'>**
←DHTMLに関する記述です  
** </hint>    

ｂ　Webサイトの見出しや要約、更新時刻などのメタデータを構造化してXMLに準拠したフォーマットで記述し、Webページが更新されたことを伝えるもの。<hint hidden='true'>**
←RSSに関する記述です  
** </hint>    

ｃ　サーバと非同期通信を行うことによって、Web画面の総書き換えを伴わない動的なWebアプリケーションを実現する方法。<hint hidden='true'>**
←Ajaxに関する記述です  
** </hint>    

〔解答群〕  
  
ア  ａ：Ajax　ｂ：Web２．０　ｃ：Java  
  
イ  ａ：DHTML　ｂ：RSS　ｃ：Ajax<hint hidden='true'>**
←⭕
** </hint>   
  
ウ  ａ：Java　ｂ：DocumentObjectModel　ｃ：DHTML  
  
エ  ａ：Web２．０　ｂ：Ajax　ｃ：RSS  

`
        },
        {
                "mondaiId": 106230080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下表は２０１１年\u0002月３０日から同年\u0003月２０日までの販売履歴表である。この表に  
対して次のSQL文を実行した場合、下記の解答群の中のどの結果を得るか。最も  
適切なものを選べ。  
SELECT担当者コード，製品名，SUM（個数）  
FROM販売履歴表  
GROUPBY担当者コード，製品名  
HAVINGSUM（個数）>=３  
販売履歴表  
販売コード製品名個数販売日担当者コード  
１０１テレビ１２０１１/１/３０E１０３  
１０２エアコン２２０１１/２/５E１０２  
１０３テレビ１２０１１/２/７E１０３  
１０４電池１０２０１１/２/７P１０１  
１０５エアコン１２０１１/２/１０E１０２  
１０６テレビ２２０１１/２/１５P１０１  
１０７電池３２０１１/２/１６E１０２  
１０８テレビ２２０１１/２/２０P１０２  
〔解答群〕  
  
ア    
担当者コード製品名SUM（個数）  
E１０２エアコン３  
E１０２電池３  
P１０１電池１０  
  
イ    
担当者コード製品名SUM（個数）  
E１０２エアコン２  
E１０２エアコン１  
E１０２電池３  
P１０１電池１０  
  
ウ    
製品名SUM（個数）  
テレビ５  
  
エ  アコン３  
電池１３  
  
エ    
製品名個数  
  
エ  アコン３  
電池３  
電池１０  
`
        },
        {
                "mondaiId": 106230090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
データベースは近年の情報システムの要ともなっている。その開発の成否が情報  
システムのパフォーマンスに多大な影響を及ぼす。データベースに関する記述とし  
て最も適切なものはどれか。  
  
ア  システム開発におけるリポジトリとは、データだけではなくソフトウェア開発  
および保守における情報、例えば、プログラム間の関連、各種図表なども一元的  
に管理するためのものである。  
  
イ  スキーマとは、データベース上のデータ内容、データ構造などを記述したもの  
を指すが、このうち概念スキーマとは、利用者やアプリケーションプログラムか  
ら見たデータの定義を意味する。  
  
ウ  データベースの設計は、データ分析、概念設計、論理設計、物理設計の順にな  
されるが、ER図は物理設計の際に用いられる有用なツールである。  
  
エ  ネットワークデータベースは、ひとつのテーブルに記録するデータのレコード  
間に親子関係のような\u0002対多の関係性を持たせたデータベースである。  
`
        },
        {
                "mondaiId": 106230100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
PCを用いた業務においては様々なソフトウェアを利用し、多くのファイルを  
作成・保存する。ファイルの管理では、保存する記憶装置の容量を考慮したり、必  
要なファイルの保存場所が後で簡単に分かるようにしたりしなければならない。  
ファイルの管理方法に関する次の文中の空欄Ａ～Ｄに入る語句の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
各種のソフトウェアで作成したファイルはユーザ自身が後々探しやすい場所に  
保存できるように、ＡのＢを工夫しながら保存する。  
ファイルを保存する際は記憶装置の容量を確認し、容量が少なくなった場合は  
ソフトウェアを利用して複数のファイルをまとめてＣしたり、ファイルを  
含むＡごとＣして記憶装置の空き容量を増やすこともできる。  
作成したファイルを検索する機能があり、ファイル名のある一部分はどのような  
文字列でもよいファイルを検索したい場合、Ｄを使用した検索ができる。  
〔解答群〕  
  
ア  Ａ：ディレクトリ（フォルダ）Ｂ：階層構造  
Ｃ：圧縮Ｄ：ワイルドカード  
  
イ  Ａ：フィールドＢ：レコード  
Ｃ：結合Ｄ：ハッシュ関数  
  
ウ  Ａ：ページＢ：パス  
Ｃ：デフラグメンテーションＤ：ブラウザ  
  
エ  Ａ：レコードＢ：スタック  
Ｃ：キャッシュＤ：ハイパーメディア  
`
        },
        {
                "mondaiId": 106230110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
多様なワイヤレス技術の発達は、複雑な配線を伴わないことからシステム構築を  
容易かつ柔軟にし、機器の小型化ともあいまって、情報システムをより身近なもの  
にしている。  
今、事務所内で下図のようなシステムを構築しようとしている。このシステムの  
構築、運用に関する記述として最も適切なものを下記の解答群から選べ。  
【図の説明】  
・サーバPCと無線LAN機能付きブロードバンドルータはLANケーブルで接続。  
・プリンタおよびノートPC、デスクトップPCはブロードバンドルータに無線  
LANで接続。  
        
〔解答群〕  
  
ア  Bluetoothは１０m以内であれば、すべての対応機器に反応してしまい、接  
続する機器が特定できないので、この事務所ではBluetooth機能付きのキー  
ボードは使うべきではない。  
  
イ  PCやプリンタとブロードバンドルータの接続をWPAと呼ばれる暗号化方  
式で認証する場合、PCやプリンタ側ではブロードバンドルータで設定した  
SSIDとWPAキーを入力する。  
  
ウ  デスクトップPCとノートPCの間のファイル交換は、Bluetoothによっては  
行えず、無線LANとブロードバンドルータを介して行う。  
  
エ  ノートPCとプリンタは、USB接続端子があっても、ひとたびワイヤレス接  
続の設定をすると、以後はUSB接続ができなくなる。  
`
        },
        {
                "mondaiId": 106230120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
事業所内で、インターネットの様々な仕組みを業務に利用しなければならない場  
面が増え、インターネットの管理・運用についての理解が求められている。  
インターネットにおいて、以下のａ～ｃの記述内容とそれを提供する機能や機器  
名称の組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａドメイン名・ホスト名とIPアドレスを対応付ける機能を持ち、Webクライア  
ントからのアドレス指定の際の問い合わせなどに答える。  
ｂ事業所内のLANにPCが接続された時、当該PCが使用するIPアドレスを割  
り当てる。  
ｃグローバルIPアドレスと事業所内のプライベートIPアドレスの変換を行う。  
〔解答群〕  
  
ア  ａ：DHCPｂ：NATｃ：VPN  
  
イ  ａ：DNSｂ：DHCPｃ：NAT  
  
ウ  ａ：NATｂ：ブロードバンドルータｃ：プロキシサーバ  
  
エ  ａ：VPNｂ：プロキシサーバｃ：DNS`
        },
        {
                "mondaiId": 106230130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
IS（InformationSystem）の利用において、企業内で「経営層」、「IS部門」、「IS活  
用部門」（すなわちユーザ）それぞれの果たすべき役割が明確でないと、ISの企画・  
計画等を担当する要員が、開発・運用を担当する要員に比べて手薄になる場合があ  
る。この結果起こり得る問題として、最も適切なものはどれか。  
  
ア  ITガバナンス機能の不在  
  
イ  開発プロジェクトの失敗  
  
ウ  経営戦略とIT戦略のギャップ  
  
エ  システム処理能力の不足  
`
        },
        {
                "mondaiId": 106230140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
今後の取引決済では、電子的資金決済がさらに普及してくる。平成２０年に施行  
された電子記録債権法に基づいて実施される電子記録債権制度に関する説明とし  
て、最も適切なものはどれか。  
  
ア  参加金融機関は全国銀行協会正会員だけである。  
  
イ  電子記録債権にも手形同様に印紙税が課税される。  
  
ウ  電子債権記録機関は全国銀行協会が設立した「でんさいネット」だけである。  
  
エ  利用者には個人事業主が含まれる。`
        },
        {
                "mondaiId": 106230150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
多量の業務データを分析して経営意思決定に活用するBI（BusinessIntelligence）  
システムでは、OLAP（OnLineAnalyticalProcessing）が利用される。OLAPを構築  
するには、R-OLAP（Relational-OLAP）とM-OLAP（MultidimensionalOLAP）とい  
う\u0002つのアーキテクチャがある。この\u0002つを比較したとき、R-OLAPに関する説明  
として最も適切なものはどれか。  
  
ア  業務システムのマスターテーブルに存在しない、分析のためのカテゴリ項目の  
設定が難しい。  
  
イ  集計結果を保持するために大きなメモリが必要になる。  
  
ウ  要約情報を事前に作成するために、まとまった時間が必要になる。  
  
エ  利用者のデータ分析要求に対する応答が早い。  
`
        },
        {
                "mondaiId": 106230160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
高額なソフトウェアやサーバを直接購入しなくても、膨大なIT資源を自由に使  
えるとうたっているクラウドコンピューティングが注目されている。クラウドコン  
ピューティングに関する記述として最も適切なものはどれか。  
  
ア  会計システムや人事システムは機密性が要求されるため、クラウドコンピュー  
ティングの対象から除外されている。  
  
イ  企業などの組織が利用するのではなく、私的個人を対象にソフトウェアを利用  
できるようにするサービスは、プライベートクラウドである。  
  
ウ  ソフトウェア開発環境、OS、ハードウェアなどをネットワークを通じて利用  
できるようにしたサービスは、PaaS型クラウドコンピューティングである。  
  
エ  パソコンやプリンタなどのハードウェア本体を貸し出すサービスは、IaaS型  
のクラウドコンピューティングである。`
        },
        {
                "mondaiId": 106230170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
ソフトウェア、システム、サービスに関係する人々が、システム開発にかかわっ  
て同じ言葉を使うことができるような共通の枠組みを提供する目的で「共通フレー  
ム２００７」がまとめられた。これに関する記述として最も適切なものはどれか。  
  
ア  ITガバナンスについてまでは踏み込んでいない。  
  
イ  経営層、業務部門、情報システム部門ごとに、担当すべき要件定義の内容を定  
めている。  
  
ウ  システム開発のプロセスで要件定義が正しくなかった場合、運用テストで対処  
するように推奨している。  
  
エ  システム化計画などの上流段階で詳細な見積もりを行い、その見積もりを目標  
値として開発プロセスを進行させることを推奨している。  
`
        },
        {
                "mondaiId": 106230180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
EA（EnterpriseArchitecture）に含まれる業務参照モデル（BRM:Business  
ReferenceModel）を適用するために行うものとして、以下の作業が必要とされて  
いる。これらを実施する作業手順として最も適切なものを下記の解答群から選べ。  
１LOB（LineOfBusiness）により業務分類を明らかにする。  
２LOB（LineOfBusiness）により情報収集対象を限定する。  
３機能構成図により業務・システムの対象範囲を確認する。  
４参照を希望する類似システムに対する調査を行う。  
５類似システムを探索してベストプラクティスを収集する。  
〔解答群〕  
  
ア  \u0002→\u0003→\u0004→\u0005→\u0006  
  
イ  \u0002→\u0003→\u0006→\u0005→\u0004  
  
ウ  \u0003→\u0002→\u0004→\u0006→\u0005  
  
エ  \u0003→\u0002→\u0005→\u0004→\u0006`
        },
        {
                "mondaiId": 106230190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
中小企業がソフトウェア開発を外注する場合、外注先の選定や外注でのプロジェ  
クトに関して、「発注者として行うべきこと」に含まれないものはどれか。  
  
ア  外注先の開発プロジェクトの進捗管理。  
  
イ  外注先の受託体制や能力の評価。  
  
ウ  外注先の人事管理。  
  
エ  要件定義の明確化。  
`
        },
        {
                "mondaiId": 106230200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
ソフトウェア品質レビュー技法のうち、インスペクションの説明として最も適切  
なものはどれか。  
  
ア  プログラム作成者、進行まとめ役、記録役、説明役、レビュー役を明確に決め  
て、厳格なレビューを公式に行う。  
  
イ  プログラム作成者が他のメンバに問題点を説明して、コメントをもらう。  
  
ウ  プログラム作成者とレビュー担当者の\u0002名だけで、作成したプログラムを調べ  
る。  
  
エ  プログラムを検査担当者に回覧して、個別にプログラムを調べてレビュー結果  
を戻してもらう。`
        },
        {
                "mondaiId": 106230210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
情報システムがネットワーク上で稼動するようになっている。その場合、情報シ  
ステムへの不正侵入を防いだり、ネットワーク上で情報が漏洩したりしないように  
するため、暗号化や各種認証方式が採用される。これに関する記述として最も適切  
なものはどれか。  
  
ア  公開鍵暗号方式とは、送受信者だけが知る公開鍵をお互いに持ち、送信者はそ  
の鍵で暗号化し、受信者はその鍵で復号化する。  
  
イ  チャレンジレスポンス認証とは、キーホルダー型などの形態の、認証サーバと  
同期したパスワード発生装置を利用して認証を行う。  
  
ウ  デジタル署名とは、自分のサインをデジタルカメラで撮影し、それを送信文に  
貼り付けることをいう。  
  
エ  ハイブリッド方式とは、公開鍵暗号方式と共通鍵暗号方式を組み合わせたもの  
である。  
`
        },
        {
                "mondaiId": 106230220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
情報システムに対するセキュリティ攻撃は、クライアントPCを標的にしたもの  
とサーバコンピュータを標的にしたものがある。セキュリティ攻撃に関する記述と  
して最も適切なものはどれか。  
  
ア  ガンブラーは詐取したIDやパスワードを使って信用のあるウェブサイトを書  
き換え、ここにアクセスしたユーザのクライアントPCを、このウェブサイトで  
  
ウ  ィルスに感染させる。  
  
イ  クロスサイトスクリプティングは、不正なスクリプトを埋め込んだウェブペー  
ジにアクセスしたクライアントPCが、動的に生成される標的ウェブページにア  
クセスしたときに、そのPCのCookieを詐取することがある。  
  
ウ  バッファオーバーフローは、偽装したウェブページにアクセスしたクライアン  
トPCに、多量のデータを送りつけ、バッファ溢れを生じさせて管理者権限を奪  
取するものである。  
  
エ  ワンクリック詐欺は、だますことを目的にしたウェブページにアクセスしたク  
ライアントPCに不正に侵入し、記録されている重要情報を流出させて、それで  
詐欺を行うものである。  
`
        },
        {
                "mondaiId": 106230230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
ある企業では、公開サーバとしてWWWサーバとメールサーバ、業務サーバと  
してファイルサーバとデータベースサーバを用いるインターネットショッピングサイトの構築を検討している。サーバの外部委託の方法として次の\u0002つが候補にあ  
がった。各方法の長所・短所に関する記述として最も適切なものを下記の解答群か  
ら選べ。  
【方法\u0003】  
公開サーバ、業務サーバは自社のものを利用して、設置場所だけは外部業者の  
ものを借用する。システム開発・運用は自社で行う。  
【方法\u0004】  
公開サーバ、業務サーバおよびその設置場所は外部業者のものを借用する。シ  
ステム開発・運用は自社で行う。  
【方法\u0002】  
公開サーバ、業務サーバおよびその設置場所は外部業者のものを借用する。シ  
ステム開発・運用は外部業者に任せ、データの入力・更新は社内に設置するクライアントPCを用いて、外部業者の業務サーバにアクセスして行う。  

〔解答群〕  
  
ア  【方法\u0003】では、サーバの保守は一般に設置を受託した外部業者が行う。  
  
イ  【方法\u0004】では、障害発生時の責任はすべて自社にある。  
  
ウ  【方法\u0002】では、情報システムの運用・管理・保守のスキルは社内には蓄積で  
きない。  
  
エ  【方法\u0002】では、ショッピングサイトの改訂はできない。  
`
        },
        {
                "mondaiId": 106230240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
破断強度を調べるために在庫している銅線をサンプル調査し、１０個のデータを  
得て、平均値の区間推定を行った。以下のうち��最も適切なものはどれか。  
  
ア  自由度を１０として計算する。  
  
イ  信頼係数９５％で区間を求める場合、区間は－１．９６σから＋１．９６σの範囲であ  
る。  
  
ウ  推定には、標準正規分布を使う。  
  
エ  母集団分布は正規分布を仮定する。`
        },
        {
                "mondaiId": 106230250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2011,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
分析のために業務データを多量に集めると、それは様々な確率分布をする。代表  
的な確率分布に関する説明として最も適切なものはどれか。  
  
ア  自由度nのt分布をする変数の\u0002乗は、自由度\u0003及びnのF分布をする。  
  
イ  生起確率が非常に低い多数の独立事象の分布を近似するには、超幾何分布が適  
している。  
  
ウ  二項分布で有限母集団からの非復元抽出の場合はポワソン分布になる。  
  
エ  ベルヌーイ分布で平均値を\u0004、標準偏差を\u0003に固定すると、ポワソン分布にな  
る。  
`
        },
        {
                "mondaiId": 107230010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
少子高齢化やデフレの進展等に加え、インターネットを経由した電子商取引の本  
格的な普及も相まって、既存の小売業を取り巻く事業環境は厳しさを増している。  
こうした中、経済産業省「商業統計表」に基づき、１９９７年と２００７年の小売業の推移  
を見ると、Ａは増加し、Ｂは減少している。  
〔解答群〕  
  
ア  Ａ：事業所数Ｂ：従業者数と年間販売額  
  
イ  Ａ：事業所数と従業者数Ｂ：年間販売額  
  
ウ  Ａ：従業者数Ｂ：事業所数と年間販売額  
  
エ  Ａ：従業者数と年間販売額Ｂ：事業所数  
  
オ  Ａ：年間販売額Ｂ：従業者数と事業所数  
`
        },
        {
                "mondaiId": 107230020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
総務省「２００６年事業所・企業統計調査」に基づき、会社と個人事業所の常用雇用  
者数（民営、非一次産業）を都道府県別企業規模別に見た場合、次の都府県を、常用  
雇用者数全体に占める中小企業の構成比率が高いものから低いものへと並べた組み  
合わせとして、最も適切なものを下記の解答群から選べ。なお、ここでは、常用雇  
用者数３００人以下（卸売業、サービス業は１００人以下、小売業、飲食店は５０人以  
下）、または資本金\u0002億円以下（卸売業は\u0003億円以下、小売業、飲食店、サービス業  
は５，０００万円以下）の企業を中小企業とする。  
ａ愛知県  
ｂ大阪府  
ｃ東京都  
〔解答群〕  
  
ア  ａ愛知県―ｂ大阪府―ｃ東京都  
  
イ  ａ愛知県―ｃ東京都―ｂ大阪府  
  
ウ  ｂ大阪府―ａ愛知県―ｃ東京都  
  
エ  ｂ大阪府―ｃ東京都―ａ愛知県  
  
オ  ｃ東京都―ｂ大阪府―ａ愛知県`
        },
        {
                "mondaiId": 107230031,
                "name": "第3問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業庁「商店街実態調査」は商店街の景況、空き店舗の状況、抱える課題等に  
ついて調査するものである。この調査に基づき、２００６年度と２００９年度の商店街の  
推移を見た場合、\u0003商店街当たりの平均店舗数はＡ、\u0003商店街当たりの空  
き店舗率はＢしている。  
        
（設問1））  
文中の下線部について、「商店街実態調査（２００９年度）」に基づき、商店街の業  
種別店舗数の構成を見た場合に、次の業種を全体に占める構成比率が高いものか  
ら低いものへと並べた組み合わせとして、最も適切なものを下記の解答群から選  
べ。なお、ここで最寄品とは、消費者が頻繁に手軽にほとんど比較しないで購入  
する物品（加工食品、家庭雑貨等）を指す。  
ａ衣料品・身の回り品店等  
ｂ飲食店  
ｃ最寄品小売店  
〔解答群〕  
  
ア  ａ衣料品・身の回り品店等―ｂ飲食店―ｃ最寄品小売店  
  
イ  ａ衣料品・身の回り品店等―ｃ最寄品小売店―ｂ飲食店  
  
ウ  ｂ飲食店―ａ衣料品・身の回り品店等―ｃ最寄品小売店  
  
エ  ｂ飲食店―ｃ最寄品小売店―ａ衣料品・身の回り品店等  
  
オ  ｃ最寄品小売店―ｂ飲食店―ａ衣料品・身の回り品店等`
        },
        {
                "mondaiId": 107230032,
                "name": "第3問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄ＡとＢに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ａ：減少Ｂ：減少  
  
イ  Ａ：減少Ｂ：増加  
  
ウ  Ａ：増加Ｂ：減少  
  
エ  Ａ：増加Ｂ：増加  
`
        },
        {
                "mondaiId": 107230040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経済産業省「工業統計表」に基づき、１９９５年から２００８年までの製造業の出荷額の  
推移を見た場合に、最も適切なものはどれか。  
  
ア  ２０００年代前半に減少した後、増加基調で推移している。  
  
イ  ２０００年代前半に増加した後、減少基調で推移している。  
  
ウ  一貫して減少している。  
  
エ  一貫して増加している。  
  
オ  ほぼ横ばいで推移している。`
        },
        {
                "mondaiId": 107230051,
                "name": "第5問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業と大企業を比較すると財務・損益面でも多くの違いが指摘できる。資金  
調達構成を見ても、中小企業は大企業に比べ借入金依存度が高く、金融機関の貸出  
姿勢の変化は中小企業経営により大きな影響を与える。  
日本銀行「金融経済統計月報」や中小企業庁の調査に��ると、中小企業向け総貸出  
残高２５２．１兆円（２００９年１２月、国内銀行信託勘定他を除く）のうち、金融機関別で  
は民間金融機関が約��割を占めており、残りが政府系金融機関等となって  
いる。また、２００７年\u0002～\u0003月期から２００９年１０～１２月期の期間について、四半期  
末ごとの金融機関別中小企業向け貸出残高の推移（前年同期比）を見ると、  
Ｂは２００８年１０～１２月期以降の貸出残高が増加傾向にあるものの、  
Ｃにおいては残高の減少基調が続くなど、金融機関によって中小企業向け  
貸出動向に違いが見られる結果となっている。  
        
（設問1））  
文中の下線部について、財務省「法人企業統計年報（２００８年度、非一次産業）」  
に基づき、大企業と中小企業の主要な財務指標を比較した場合、最も適切なもの  
はどれか。なお、ここで、中小企業とは中小企業基本法の定義によるものとし、  
それ以外の企業を大企業とする。  
  
ア  中小企業の借入金利子率  
（＝  
支払利息・割引料  
短期・長期借入金＋社債＋受取手形割引高  
×１００）は大企業の\u0003倍以上の  
水準である。  
  
イ  中小企業の債務償還年数  
（＝  
短期・長期借入金＋社債  
経常利益×５０％＋減価償却費＋特別減価償却費－役員賞与－中間配当額－配当金  
）  
は大企業の\u0003倍以上の水準である。  
  
ウ  中小企業の自己資本比率  
（＝  
純資産－新株予約権  
総資本  
×１００）は大企業の半分以下の水準である。  
  
エ  中小企業の総資本回転率  
（＝  
売上高  
総資本  
）は大企業を上回っている。`
        },
        {
                "mondaiId": 107230052,
                "name": "第5問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ａに入る最も適切な数値はどれか。  
  
ア  \u0004イ\u0005ウ\u0006エ\u0007オ3`
        },
        {
                "mondaiId": 107230053,
                "name": "第5問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問）  
文中の空欄ＢとＣに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ｂ：信用金庫および信用組合Ｃ：地方銀行および第二地方銀行  
  
イ  Ｂ：信用金庫および信用組合Ｃ：都市銀行  
  
ウ  Ｂ：都市銀行Ｃ：信用金庫および信用組合  
  
エ  Ｂ：地方銀行および第二地方銀行Ｃ：信用金庫および信用組合  
`
        },
        {
                "mondaiId": 107230061,
                "name": "第6問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
  
エ  ネルギー資源の制約が顕在化し、地球温暖化問題への対応の重要性が増す中  
で、今後、中小企業に対しても、省エネルギー（以下「省エネ」という。）の一層の推  
進および  
\u0002  
二酸化炭素をはじめとした温室効果ガスの排出量削減を求める動きが強ま  
るものと考えられる。  
中小企業の省エネの取り組みを見ると、空室時の消灯や温湿度管理の徹底等の運  
用による取り組みと、高効率な設備機器や制御装置の導入等の投資による取り組み  
に大別されるが、各々の取り組み状況は規模や業種によって異なっているのが現状  
である。製造業のエネルギー投入比率（生産額に占める燃料使用額と購入電力使用  
額の合計）を見ても、大企業と比較するとさらなる改善余地があると考えられる。  
中小企業が省エネの取り組みを進めるにあたっては、省エネに関する情報・知識  
の不足と人的・資金的な投資負担が課題になると考えられることから、  
\u0003  
他社の技術  
やノウハウの活用、国等において設けられている各種省エネ支援制度の積極的な活  
用が求められる。  
（設問1））  
文中の下線部\u0002について、中小企業庁の推計に基づく中小企業のエネルギー起  
源二酸化炭素排出量に関する記述として、<u>最も不適切なものはどれか</u>。なお、こ  
こで、中小企業庁の推計は総務省「２００６年事業所・企業統計調査」、資源エネル  
ギー庁「２００７年度総合エネルギー統計」、「２００７年度エネルギー消費統計」基礎  
データからの再集計・推計によるもので、中小企業とは中小企業基本法で定義す  
る常用雇用者数規模に該当する企業をいう。  
  
ア  飲食・宿泊業におけるエネルギー起源二酸化炭素排出量は、中小企業が大企  
業を上回っている。  
  
イ  製造業における中小企業のエネルギー起源二酸化炭素排出量に占める割合  
は、約\u0003割を占めている。  
        
  
ウ  中小企業のエネルギー起源二酸化炭素排出量は、わが国全体の\u0002割強を占め  
ている。  
  
エ  中小企業の部門別のエネルギー起源二酸化炭素排出量に占める割合は、産業  
部門（製造業、農林水産業、鉱業、建設業等）よりも業務部門（対事業所サービ  
ス、対個人サービス等）が高い。`
        },
        {
                "mondaiId": 107230062,
                "name": "第6問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0002について、中小企業が省エネに関する他社の技術やノウハウを  
利用する方策の\u0002つとして、ESCO（EnergyServiceCompany）事業の利用が考え  
られる。ESCO事業を利用する利点として、<u>最も不適切なものはどれか</u>。  
  
ア  ESCO事業者がエネルギーに関する包括的なサービスを提供するため、中小  
企業は自社に人材が確保できなくても省エネに取り組める。  
  
イ  ESCO事業者が省エネ結果の計測および検証を行うことから、中小企業は省  
  
エ  ネ投資の経済効果について適切に把握できる。  
  
ウ  ESCO事業者は原則として省エネ効果を保証し、保証した省エネ効果が得ら  
れなかった場合は損失を補てんすることから、中小企業はリスクの軽減を図る  
ことができる。  
  
エ  省エネ設備の導入に際しては契約形態を問わず中小企業に初期投資負担が発  
生しない。  
`
        },
        {
                "mondaiId": 107230071,
                "name": "第7問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
国立社会保障・人口問題研究所「日本の将来推計人口（２００６年１２月中位推計）」に  
よると、  
\u0002  
２０３５年の総人口は\u0002億１，０６７万人、そのうち生産年齢人口（１５～６４歳）は  
６，２９２万人になると見込まれている。  
こうした中で、中小企業にとっては、労働力の確保、自社技術や知識の継承者の  
不足等が中長期的な経営課題として浮上してくることが懸念される。既に中小企業  
においても、  
\u0003  
女性や高齢者、非正規社員の活用等が取り組まれているが、より多様  
な  
\u0004  
人材を確保するとともに確保した人材を効果的に活用していくことが、企業存続  
の観点からも不可欠になっていくものと考えられる。  
（設問1））  
文中の下線部\u0002について、「日本の将来推計人口（２００６年１２月中位推計）」に基  
づき、２０３５年の総人口と生産年齢人口を各々２００８年比で比較した場合、最も適  
切なものはどれか。  
  
ア  生産年齢人口の減少率は４０％を上回っている。  
  
イ  生産年齢人口の減少率は総人口の減少率を上回っている。  
  
ウ  生産年齢人口はほぼ横ばいである。  
  
エ  総人口の減少率は４０％を上回っている。  
  
オ  総人口はほぼ横ばいである。  
`
        },
        {
                "mondaiId": 107230072,
                "name": "第7問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、総務省「就業構造基本調査」に基づき、１９９２年、  
１９９７年、２００２年、２００７年の\u0003時点について、規模別に就業者に占める女性と高  
齢者（６５歳以上）の割合を見た場合、最も適切なものはどれか。なお、ここでは  
従業者数２９９人以下（卸売業、サービス業は９９人以下、小売業、飲食店は４９人  
以下）の企業を中小企業、中小企業以外の企業を大企業とする。  
  
ア  大企業の就業者に占める女性の割合は減少してきている。  
  
イ  中小企業の就業者に占める高齢者の割合は減少してきている。  
  
ウ  中小企業の就業者に占める高齢者の割合は\u0003時点とも大企業を下回ってい  
る。  
  
エ  中小企業の就業者に占める女性の割合は減少してきている。  
  
オ  中小企業の就業者に占める女性の割合は\u0003時点とも大企業を下回っている。`
        },
        {
                "mondaiId": 107230073,
                "name": "第7問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の下線部\u0003について、厚生労働省「職業安定業務統計」に基づき、２００３年  
度と２００８年度の業種別の新規有効求人数の増減を見た場合、最も適切なものは  
どれか。  
  
ア  医療、福祉業においては新規有効求人数が減少している。  
  
イ  飲食店、宿泊業においては新規有効求人数が増加している。  
  
ウ  教育、学習支援業においては新規有効求人数が減少している。  
  
エ  建設業においては新規有効求人数が増加している。  
  
オ  製造業においては新規有効求人数が増加している。  
`
        },
        {
                "mondaiId": 107230081,
                "name": "第8問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
経済のグローバル化の進展を受けて、中小企業においても輸出額や直接投資によ  
る海外子会社を保有する企業の割合が増加する等、国際化は着実に進展している。  
しかしながら、経済産業省「海外事業活動基本調査」や財務省「法人企業統計年報」を  
基に、２００７年度の製造業における規模別の海外生産比率（注）を見ると、大企業で  
は約Ａ％であるのに対し、中小企業では約Ｂ％にとどまってい  
る。  
また、経済産業省「企業活動基本調査」を基に、２００７年度の海外子会社を保有す  
る企業の割合を見ると、大企業の２８．２％に対して、中小企業では１２．１％となっ  
ており、総じて見れば中小企業の国際化の程度は大企業に比べると依然として低い  
水準にある。なお、ここで海外子会社を保有する企業とは、年度末時点に海外に子  
会社または関連会社を所有する企業をいう。また、子会社とは当該会社が５０％超  
の議決権を有する会社をいい、子会社または当該会社と子会社の合計で５０％超の  
議決権を有する会社も含む。関連会社とは、当該会社が２０％以上５０％以下の議  
決権を直接有している企業を示している。  
（注）海外生産比率＝  
現地法人（製造業）売上高  
現地法人（製造業）売上高＋国内法人（製造業）売上高  
        
（設問1））  
文中の下線部について、「海外事業活動基本調査」に基づき、中小企業の有する  
海外現地法人数（２００７年度）を地域別に見た場合に、次の国・地域を全体に占め  
る構成比率が高いものから低いものへと並べた組み合わせとして、最も適切なも  
のを下記の解答群から選べ。なお、ここでASEANとは、マレーシア、タイ、  
フィリピン、インドネシア、ベトナム、カンボジア、シンガポール、ラオス、  
ミャンマー、ブルネイの１０カ国をいう。  
ａ中国  
ｂASEAN  
ｃ北米  
〔解答群〕  
  
ア  ａ中国―ｂASEAN―ｃ北米  
  
イ  ａ中国―ｃ北米―ｂASEAN  
  
ウ  ｂASEAN―ａ中国―ｃ北米  
  
エ  ｂASEAN―ｃ北米―ａ中国  
  
オ  ｃ北米―ｂASEAN―ａ中国`
        },
        {
                "mondaiId": 107230082,
                "name": "第8問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄ＡとＢに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：\u0004Ｂ：\u0002  
  
イ  Ａ：１４Ｂ：\u0005  
  
ウ  Ａ：２１Ｂ：\u0006  
  
エ  Ａ：２８Ｂ：\u0005  
  
オ  Ａ：３５Ｂ：\u0002  
`
        },
        {
                "mondaiId": 107230091,
                "name": "第9問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業庁が総務省「事業所・企業統計調査」に基づき試算している事業所数（民  
営、非一次産業）を基にした開業率・廃業率の推移を見ると、わが国では１９８９年～  
１９９１年を対象期間とした調査以降、一貫して開業率を廃業率が上回る結果となっ  
ている。  
企業数（会社数および個人事業所数）を基にした試算によっても同様の傾向にある  
が、企業数の増減を見ると産業分野による差も大きい。例えば、総務省「事業所・  
企業統計調査」によって、２００１年と２００６年の産業別企業数（民営、非一次産業）を  
比較すると、Ａでは企業数が減少しているのに対して、Ｂでは企  
業数が増加している。  
（設問1））  
文中の下線部について、２００１年と２００６年の大事業所数と中小事業所数を比較  
した場合に最も適切なものはどれか。なお、ここで総従業者数３００人以下（卸売  
業、サービス業は１００人以下、小売業、飲食店は５０人以下）の事業所を中小事業  
所とし、それ以外を大事業所とする。  
  
ア  大事業所数の減少割合を中小事業所数の減少割合は上回っている。  
  
イ  大事業所数は増加している。  
  
ウ  中小事業所数の減少割合を大事業所数の減少割合は上回っている。  
  
エ  中小事業所数は増加している。  
`
        },
        {
                "mondaiId": 107230092,
                "name": "第9問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
  
ア  Ａ：「医療、福祉」と「卸売・小売業」Ｂ：「情報通信業」と「製造業」  
  
イ  Ａ：「飲食店、宿泊業」と「製造業」Ｂ：「医療、福祉」と「卸売・小売業」  
  
ウ  Ａ：「卸売・小売業」と「製造業」Ｂ：「医療、福祉」と「飲食店、宿泊業」  
  
エ  Ａ：「卸売・小売業」と「製造業」Ｂ：「医療、福祉」と「情報通信業」  
  
オ  Ａ：「情報通信業」と「製造業」Ｂ：「医療、福祉」と「卸売・小売業」  
`
        },
        {
                "mondaiId": 107230101,
                "name": "第10問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
次の文章を読んで、下記の設問に答えよ。  
中小企業診断士X氏は、顧問先の中小企業である機械部品製造業者Y社社長か  
ら、「取引先の生産活動が海外シフトしているのに対応して、自社でも海外生産法  
人の設立を検討しているが、中小企業が海外直接投資を行うにあたっての留意点・  
現状について簡単に説明してほしい。」との相談を受けた。なお、現在のところY  
社の事業活動は国内に限定されている。  
以下は、X氏とY社社長との会話である。  
X氏：「直接投資を行う場合、進出国の諸制度への対応、コスト管理や販路確  
保に加えて、人材確保・労務管理、投資資金等の資金調達についてもよ  
く考える必要がありますね。」  
Y社社長：「必要な投資資金は進出国では調達できないのですか。」  
X氏：「経済産業省の２００８年海外事業活動基本調査によれば、  
\u0002  
現地での資金調  
達手法については、本社企業が中小企業の進出企業の場合、日本本社か  
らの資金送金で必要資金を賄っている企業が多いようです。」  
Y社社長：「海外生産法人で利益が出た場合、日本本社にも利益を還流させたいの  
ですが。」  
X氏：「収益状況や進出国の法制度等にもよりますが、先ほどの経済産業省の  
調査によれば、２００７年度の中小企業の  
\u0003  
現地法人の売上高に占める日本  
本社への支払費用の比率は２．６％で、同じく出資金に対する配当金の  
比率は７．３％です。なお、２００９年度の税制改正により  
\u0004  
外国子会社配当  
益金不算入制度が創設されたことから、今後は海外子会社から日本本社  
への配当金が増加することが期待されています。」  
        
（設問1））  
文中の下線部\u0002について、「２００８年海外事業活動基本調査」に基づき、現地で  
の必要資金の調達手法について、本社企業が大企業の進出企業（大企業）と本社企  
業が中小企業の進出企業（中小企業）を比較した場合、最も不適切なものはどれ  
か。  
  
ア  主にグループ内の金融会社から資金調達していると回答する企業割合は、大  
企業が中小企業を上回る。  
  
イ  主に現地金融機関から資金調達していると回答する企業割合は、大企業が中  
小企業を上回る。  
  
ウ  主に本社からの資金送金で資金調達していると回答する企業割合が、中小企  
業では過半を占める。  
  
エ  主に本社からの資金送金で資金調達していると回答する企業割合は、中小企  
業が大企業を上回る。`
        },
        {
                "mondaiId": 107230102,
                "name": "第10問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0003について、「海外事業活動基本調査」に基づき、２００１年度から  
２００７年度の期間、売上高に占める本社企業への支払費用の比率の推移を、本社  
が大企業の進出企業（大企業）と本社が中小企業の進出企業（中小企業）のそれぞれ  
について見た場合、最も適切なものはどれか。  
  
ア  大企業の支払費用比率は、一貫して上昇している。  
  
イ  大企業の支払費用比率は、ほぼ横ばいで推移している。  
  
ウ  中小企業の支払費用比率は、一貫して上昇している。  
  
エ  中小企業の支払費用比率は、大企業の支払費用比率を一貫して下回ってい  
る。  
  
オ  中小企業の支払費用比率は、ほぼ横ばいで推移している。  
`
        },
        {
                "mondaiId": 107230103,
                "name": "第10問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、外国子会社配当益金不算入制度に関する記述の正誤  
について、最も適切なものの組み合わせを下記の解答群から選べ。  
ａ外国子会社から受け取る配当の額の９５％を益金不算入とする制度である。  
ｂ対象となる外国子会社は、内国法人の持株割合が２５％以上（租税条約によ  
り異なる割合が定められている場合はその割合）で保有期間が\u0003カ月以上の外  
国法人である。  
〔解答群〕  
  
ア  ａ：正ｂ：正  
  
イ  ａ：正ｂ：誤  
  
ウ  ａ：誤ｂ：正  
  
エ  ａ：誤ｂ：誤`
        },
        {
                "mondaiId": 107230110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
中小企業白書（２０１０）は、産業集積内の企業の取引構造を分析したヒエラルキー図  
を作成している。  
わが国有数の産業集積として中小製造業が多く立地する浜松市と東大阪市におけ  
る企業の地域内の取引構造に関する記述の正誤について、最も適切なものの組み合  
わせを下記の解答群から選べ。  
ａ浜松市では、多数の取引先を有するハブ企業が重層的に存在し、垂直的な取引  
構造が形成されている。  
ｂ東大阪市では、比較的取引先数の少ない企業が多数存在し、水平的な取引構造  
が形成されている。  
〔解答群〕  
  
ア  ａ：正ｂ：正  
  
イ  ａ：正ｂ：誤  
  
ウ  ａ：誤ｂ：正  
  
エ  ａ：誤ｂ：誤  
`
        },
        {
                "mondaiId": 107230120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　中小企業基本法の定義に基づく、中小企業者と小規模企業者に関する記述の正誤について、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　資本金1億円で従業員数が３０人の食品小売業者は中小企業者に該当し、資本金1千万円で従業員数5人の食品小売業者は小規模企業者に該当する。  

ｂ　資本金5億円で従業員数が２００人の運輸業者は中小企業者に該当し、資本金1千万円で従業員数5人の運輸業者は小規模企業者に該当する。  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>


〔解答群〕  
  
ア  ａ：正　ｂ：正<hint hidden='true'>**
←⭕
**</hint>   
  
イ  ａ：正　ｂ：誤  
  
ウ  ａ：誤　ｂ：正  
  
エ  ａ：誤　ｂ：誤  
`
        },
        {
                "mondaiId": 107230131,
                "name": "第13問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
次の文章を読んで、下記の設問に答えよ。  
平成２２年\u0002月、中小企業政策の基本的考え方と方針を明らかにした「中小企業憲  
章」が閣議決定された。  
この憲章は、中小企業の歴史的な位置付けや、今日の中小企業の経済的・社会的  
役割などについての考え方を  
\u0002  
基本理念として示すとともに、中小企業政策に取り組  
むに当たっての  
\u0003  
\u0003つの基本原則や、それを踏まえて政府として進める中小企業政策  
の行動指針を示している。  
（設問\u0004）  
文中の下線部\u0002の「基本理念」に記述されている中小企業像として、最も適切な  
ものはどれか。  
  
ア  中小企業は、国家の財産ともいうべき存在である。  
  
イ  中小企業は、市場経済の中核ともいうべき存在である。  
  
ウ  中小企業は、社会のインフラストラクチャーともいうべき存在である。  
  
エ  中小企業は、地域経済の潤滑油ともいうべき存在である。`
        },
        {
                "mondaiId": 107230132,
                "name": "第13問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
文中の下線部\u0003の「\u0003つの基本原則」に含まれるものとして、最も適切なものは  
どれか。  
  
ア  起業を増やす。  
  
イ  雇用環境を整える。  
  
ウ  大企業との格差を是正する。  
  
エ  中小企業構造の高度化を図る。  
`
        },
        {
                "mondaiId": 107230141,
                "name": "第14問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
次の文章を読んで、下記の設問に答えよ。  
中小企業新事業活動促進法は、中小企業の新たな事業活動を総合的に支援し、そ  
の促進を図る法律であり、  
\u0002  
中小企業基本法が掲げる理念を具体化するための作用法  
であるという位置づけを有している。  
中小企業新事業活動促進法における「新事業活動」とは、\u0002新商品の開発又は生  
産、\u0003Ａの開発又は提供、\u0004商品の新たな生産又はＢの方式の導  
入、\u0005役務の新たな提供の方式の導入その他の新たな事業活動をいう。  
また、この法律において「  
\u0003  
異分野連携新事業分野開拓」とは、その行う事業の分野  
を異にする事業者が有機的に連携し、その経営資源を有効に組み合わせて、新事業  
活動を行うことにより、新たな事業分野の開拓を図ることをいう。  
（設問1））  
文中の下線部\u0002の中小企業基本法では、その第\u0003条で中小企業政策の基本方針  
が示されている。この基本方針として、<u>最も不適切なものはどれか</u>。  
  
ア  経営の革新及び創業の促進  
  
イ  経済的社会的環境の変化への適応の円滑化  
  
ウ  自己資本の充実  
  
エ  生産性の向上`
        },
        {
                "mondaiId": 107230142,
                "name": "第14問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の空欄ＡとＢに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：新役務Ｂ：販売  
  
イ  Ａ：新役務Ｂ：流通  
  
ウ  Ａ：新技術Ｂ：販売  
  
エ  Ａ：新技術Ｂ：流通  
`
        },
        {
                "mondaiId": 107230143,
                "name": "第14問設問3",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002の異分野連携新事業分野開拓の計画の認定などに関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  異分野連携新事業分野開拓計画においては、中小企業者のみならず、大企業  
や大学、NPO等の参加を得ることにより、様々な経営資源を組み合わせて新  
事業活動を行うことも想定されている。  
  
イ  国として積極的な関与を行い、成功に導く支援をなすべきであるため、認定  
主体は国（主務大臣）である。  
  
ウ  支援対象となるには、中小企業者の占める割合が、\u0002分の\u0003以上必要と規定  
されている。  
  
エ  認定を受ける場合、その事業分野を異にする\u0004以上の中小企業者の参加が必  
要である。  
`
        },
        {
                "mondaiId": 107230151,
                "name": "第15問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
次の文章を読んで、下記の設問に答えよ。  
中小企業新事業活動促進法における「経営革新」とは、事業者が新事業活動を行う  
ことにより、その経営の相当程度の向上を図ることをいう。  
経営革新は、定量的な経営目標を定め、それに向かって中小企業者が自主的に邁  
進していく取り組みである。指標としてはＡの向上とＢの向上が  
支援に当たっての判断基準とされている。  
Ａについては��たとえば、\u0002年間の計画の場合、目標伸び率が１５％以  
上のものが求められる。Ｂについては、たとえば、\u0003年間の計画とした場  
合、Ｃ％��上のものが求められる。  
（設問\u0004）  
文中の空欄ＡとＢに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：売上高Ｂ：営業利益  
  
イ  Ａ：売上高Ｂ：経常利益  
  
ウ  Ａ：付加価値額Ｂ：営業利益  
  
エ  Ａ：付加価値額Ｂ：経常利益`
        },
        {
                "mondaiId": 107230152,
                "name": "第15問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
文中の空欄Ｃに入るものとして、最も適切なものはどれか。  
  
ア  \u0003  
  
イ  \u0002  
  
ウ  \u0006  
  
エ  １０  
`
        },
        {
                "mondaiId": 107230161,
                "name": "第16問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
小規模企業共済制度は、小規模企業共済法に基づく共済制度である。この制度に  
関して、下記の設問に答えよ。（設問1））  
小規模企業共済制度の加入対象となる者として、<u>最も不適切なものはどれか</u>。  
  
ア  企業組合（事業に従事する組合員の数２０人）の役員A氏  
  
イ  小売業（常時使用する従業員数２０人）の事業主であるB氏  
  
ウ  製造業（常時使用する従業員数２０人の個人事業）の共同経営者C氏  
  
エ  農業経営を主に行う農事組合法人（常時使用する従業員の数２０人）の役員D  
氏`
        },
        {
                "mondaiId": 107230162,
                "name": "第16問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
小規模企業共済制度に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  掛金月額は、加入後増額することができる。  
  
イ  その年に納付した掛金はその年分の総所得金額から全額所得控除できる。  
  
ウ  取引先企業が倒産した場合、売掛金などの回収が困難となった額と、積み立  
てた掛金総額の１０倍に相当する額のいずれか少ない額の貸付を受けることが  
できる。  
  
エ  納付した掛金総額の範囲内で事業資金などの貸付を受けることができる。  
`
        },
        {
                "mondaiId": 107230170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章の空欄ＡとＢに入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
中小企業者等は税制上の様々な特別措置を受けることができる。たとえば、中小  
企業（資本金\u0002億円以下の法人企業）は、年所得Ａ万円以下の部分について  
軽減された法人税率が適用されている。また、交際費についても、年６００万円まで  
の交際費支出のうちＢ割まで、損金算入が認められている。  
〔解答群〕  
  
ア  Ａ：８００Ｂ：\u0003  
  
イ  Ａ：８００Ｂ：\u0004  
  
ウ  Ａ：９００Ｂ：\u0003  
  
エ  Ａ：９００Ｂ：\u0004  
`
        },
        {
                "mondaiId": 107230181,
                "name": "第18問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
次の文章を読んで、下記の設問に答えよ。  
中小企業投資促進税制は、青色申告書を提出する個人事業者または資本金\u0002億円  
以下の中小企業等について、  
\u0002  
対象となる設備・資産を導入した場合、  
\u0003  
税制の特別措  
置を受けることができる制度である。  
（設問1））  
文中の下線部\u0002について、対象となる設備・資産として、最も適切なものはど  
れか。  
  
ア  建設費が１，０００万円の店舗用建物  
  
イ  取得価額が１５０万円の営業用乗用車  
  
ウ  取得価額が１５０万円の電子計算機  
  
エ  取得価額が１，０００万円の営業用土地`
        },
        {
                "mondaiId": 107230182,
                "name": "第18問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0003について、資本金が\u0004千万円を超えない法人に該当する特別措  
置として、最も適切なものはどれか。  
  
ア  税額控除および特別償却が認められる。  
  
イ  税額控除のみが認められる。  
  
ウ  税額控除または特別償却が認められる。  
  
エ  特別償却のみが認められる。  
`
        },
        {
                "mondaiId": 107230190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
有限責任事業組合（LLP）は、「有限責任事業組合契約に関する法律」（LLP法）に基  
づく、共同事業のための事業体制度である。LLPに関する記述として、最も不適  
切なものはどれか。  
  
ア  LLPには、軽減された法人税率が適用される。  
  
イ  LLPの全組合員は、事業上の意思決定と業務執行への参加が義務づけられて  
いる。  
  
ウ  組合員の組合事業への貢献度に応じて、出資比率とは異なる損益や議決権の分  
配が可能である。  
  
エ  組合員は、出資の価額の範囲までしか事業上の責任を負わない。`
        },
        {
                "mondaiId": 107230200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
中小企業には、産業財産権（特許権・実用新案権・意匠権・商標権）に関する様々  
な支援策が提供されている。特許出願などに関わる支援施策として、最も不適切な  
ものはどれか。  
  
ア  審査請求料、特許料の無償化  
  
イ  電子出願を行うためのインターネット出願ソフトの無償提供  
  
ウ  特許出願に関わる早期審査・早期審理制度  
  
エ  特許情報活用支援アドバイザーによる無料アドバイス  
`
        },
        {
                "mondaiId": 107230211,
                "name": "第21問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
次の文章を読んで、下記の設問に答えよ。  
「中小企業のものづくり基盤技術の高度化に関する法律」では、製造業の国際競争  
力強化や新たな事業の創出を促進するために、製品を製造するうえで、  
基盤となる「特定ものづくり基盤技術」の研究開発等を支援する。  
（設問1））  
文中の下線部に関連する記述として、最も適切なものはどれか。  
  
ア  組込みソフトウェア、金型、プラスチック成形加工等の技術が、「特定もの  
づくり基盤技術」として指定されている。  
  
イ  この法律において「特定ものづくり基盤技術の高度化」とは、特定ものづくり  
基盤技術に関する研究開発を行うこと及びその成果を利用することをいう。  
  
ウ  この法律に基づく支援を受けるために、中小企業は、「特定ものづくり基盤  
技術高度化指針」を作成し、認定を受ける必要がある。  
  
エ  特定ものづくり基盤技術には、技術ごとに「特定研究開発等計画」が策定され  
ている。`
        },
        {
                "mondaiId": 107230212,
                "name": "第21問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄に入る言葉として、最も適切なものはどれか。  
  
ア  川下  
  
イ  工業  
  
ウ  産業用  
  
エ  汎用  
`
        },
        {
                "mondaiId": 107230220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
中小商業活力向上事業は、商店街等が地域コミュニティの担い手として実施す  
る、社会課題に対応した集客力向上または売上増加の効果のある取り組みを支援す  
ることにより、商店街の活性化を図ることを目的とするものである。  
この事業の対象となる社会課題として、<u>最も不適切なものはどれか</u>。  
  
ア  安全・安心、少子化、高齢化  
  
イ  環境  
  
ウ  国際化・交流人口拡大、観光  
  
エ  地域資源活用・農商工連携`
        },
        {
                "mondaiId": 107230230,
                "name": "第23問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
地域間交流支援事業（RegionalIndustryTie-UpProgram）は、海外との産業交流  
を通じた地域産業の国際ビジネス促進支援を行うものである。  
この事業に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0002企業では、この事業に申請できない。  
  
イ  中小企業基盤整備機構が、海外出張調査、ミッション派遣、海外有識者・有力  
企業の日本招聘を行う。  
  
ウ  メンバーの\u0003分の\u0004以上が中小企業であることが、この事業の対象となる条件  
である。  
  
エ  有志の協議会、研究会は、この事業の対象となり得る。  
`
        },
        {
                "mondaiId": 107230240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
中小企業の組織化を図るための手段としては、中小企業組合（企業組合、協業組  
合、事業協同組合、商店街振興組合など）、共同出資会社による会社、任意グルー  
プ等の手段がある。  
下線部の組合制度の中で、\u0002人以上の発起人が必要となるものとして、最も適切  
なものはどれか。  
  
ア  企業組合  
  
イ  協業組合  
  
ウ  事業協同組合  
  
エ  商店街振興組合`
        },
        {
                "mondaiId": 107230250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2011,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
次の文章の空欄に入る最も適切なものを下記の解答群から選べ。  
緊急事態は突然発生する。有効な手を打つことができなければ、とくに中小企業  
は、経営基盤がぜい弱なため、廃業に追い込まれる恐れがある。  
「中小企業BCP策定運用指針」におけるBCPとは、企業が自然災害、大火災、テ  
ロ攻撃などの緊急事態に遭遇した場合において、事業資産の損害を最小限にとどめ  
つつ、の継続あるいは早期復旧を可能とするために、平常時に行うべき  
活動や緊急時における事業継続のための方法、手段などを取り決めておく計画のこ  
とである。  
〔解答群〕  
  
ア  サプライチェーン  
  
イ  事業拠点  
  
ウ  生産体制  
  
エ  中核事業  
  
オ  ビジネスシステム  
`
        },
        {
                "mondaiId": 101220010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本の民間最終消費支出、民間住宅・民間企業設備・民間企業在庫品増  
加、公的需要、輸出、輸入の各項目がGDPに占める割合（実質値）を表したもので  
ある。図中のＡ～Ｄに当てはまる最も適切なものの組み合わせを下記の解答群から  
選べ。  
出所：内閣府「国民経済計算」（内閣府ホームページ）  
〔解答群〕  
  
ア  Ａ：公的需要Ｂ：輸入  
Ｃ：輸出Ｄ：民間住宅・民間企業設備・民間企業在庫品増加  
  
イ  Ａ：公的需要Ｂ：民間住宅・民間企業設備・民間企業在庫品増加  
Ｃ：輸入Ｄ：輸出  
  
ウ  Ａ：輸出Ｂ：輸入  
Ｃ：公的需要Ｄ：民間住宅・民間企業設備・民間企業在庫品増加  
  
エ  Ａ：輸出Ｂ：輸入  
Ｃ：民間住宅・民間企業設備・民間企業在庫品増加Ｄ：公的需要  
`
        },
        {
                "mondaiId": 101220021,
                "name": "第2問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
貯蓄に関する下記の設問に答えよ。  
（設問1））  
下図のＡ～Ｅは、日本、アメリカ、ドイツ、スウェーデン、デンマーク各国の  
貯蓄率（世帯別に見た家計貯蓄率）の推移を表したものである。その中で、日本に  
当てはまるものはどれか。最も適切なものを下記の解答群から選べ。  
出所：OECD，EconomicOutlook，No.８６，November２００９.  
〔解答群〕  
  
ア  Ａ  
  
イ  Ｂ  
  
ウ  Ｃ  
  
エ  Ｄ  
  
オ  Ｅ  
`
        },
        {
                "mondaiId": 101220022,
                "name": "第2問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
個々の家計において、収入のうち、どの程度を消費に回し、どの程度を貯蓄に  
回すかは、下図に示される「現在の消費」と「将来の消費」に関する無差別曲線と予  
算制約式によって決定される。無差別曲線の位置・形状の変化を通じて貯蓄額に  
影響を与えることを示す最も適切なものを下記の解答群から選べ。  
〔解答群〕  
  
ア  家族の構成員が新たに働くことによって収入が上がり、貯蓄額が上昇する。  
  
イ  定年退職して収入が下がり、貯蓄額が低下する。  
  
ウ  利子率が低下することで、貯蓄の魅力が低下し、貯蓄額が下がる。  
  
エ  老後の生活への不安が高まり、貯蓄額が上昇する。  
`
        },
        {
                "mondaiId": 101220031,
                "name": "第3問設問1",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財政に関する下記の設問に答えよ。  
（設問1））  
下図のＡ～Ｅは、日本、カナダ、アメリカ、ドイツ、イタリア各国の一般政府  
の債務残高（対GDP比、SNAベース）の推移を表したものである。Ａ～Ｅに当て  
はまる国名の組み合わせとして、最も適切なものを下記の解答群から選べ。  
出所：OECD，EconomicOutlook，No.８５，June２００９.  
〔解答群〕  
  
ア  Ａ：イタリアＣ：日本  
  
イ  Ａ：イタリアＤ：ドイツ  
  
ウ  Ａ：日本Ｂ：イタリア  
  
エ  Ａ：日本Ｄ：アメリカ  
  
オ  Ａ：日本Ｅ：カナダ  
`
        },
        {
                "mondaiId": 101220032,
                "name": "第3問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
一般政府の債務残高の増加に関する説明として、最も適切なものの組み合わせ  
を下記の解答群から選べ。  
ａ債務残高の累増に伴い、消費の拡大と貨幣需要の増加という資産効果が作用  
し、利子率の上昇が生じる。  
ｂ債務残高の累増に伴い、消費の拡大と貨幣需要の減少という資産効果が作用  
し、利子率の低下が生じる。  
ｃ債務残高を一定とした場合、名目経済成長率が利子率を上回れば、債務残高  
の対名目GDP比は低下する。  
ｄ債務残高を一定とした場合、利子率が名目経済成長率を上回れば、債務残高  
の対名目GDP比は低下する。  
〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101220040,
                "name": "第4問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
投資決定の説明として、最も適切なものの組み合わせを下記の解答群から選べ。  
ａケインズの投資理論では、投資の限界効率が利子率を下回るほど、投資を実行  
することが有利になると考える。  
ｂ資本のレンタル料が資本の限界生産物価値を上回る場合、投資が増加し、資本  
ストックの積み増しが生じる。  
ｃ投資の加速度原理では、生産拡大の速度が大きくなるほど、投資も拡大すると  
考える。  
ｄトービンのq理論では、株価総額と負債総額の合計である企業価値が、現存  
の資本ストックを再び購入するために必要とされる資本の再取得費用を上回るほ  
ど、設備投資が実行されると考える。  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 101220050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
いま、家計、企業、政府から構成される閉鎖経済モデルを考える。ここで、各記  
号は、Y：GDP、C：消費支出、I：民間投資支出、G：政府支出、T：租税収入、  
C０：独立消費を意味し、単位は兆円とする。また、cは限界消費性向とする。  
生産物市場の均衡条件Y＝C＋I＋G  
消費関数C＝C０＋c（Y－T）  
C０＝６０、c＝０．６  
民間投資支出I＝１２０  
政府支出G＝５０  
租税収入T＝５０  
ここから得られる結果として、最も適切なものの組み合わせを下記の解答群から  
選べ。  
ａ均衡GDPは５００兆円である。  
ｂ均衡時における消費は３３０兆円、貯蓄は１７０兆円である。  
ｃ均衡予算を編成した上で政府支出を\u0002兆円増加させた場合、均衡GDPは\u0002兆  
円増加する。  
ｄ減税を\u0002兆円規模で実施した場合、均衡GDPは１２．５兆円増加する。  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｃ  
  
オ  ｂとｄ  
`
        },
        {
                "mondaiId": 101220061,
                "name": "第6問設問1",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の財政・金融政策の効果と有効性に関する文章を読んで、下記の設問に答え  
よ。  
いま、生産物市場の均衡条件が  
Y＝C＋I＋G  
で与えられ、YはGDP、Cは消費支出、Iは民間投資支出、Gは政府支出である。  
ここで、  
消費関数C＝C０＋c（Y－T）  
C０：独立消費、c：限界消費性向（\u0002＜c＜\u0003）、T：租税収入  
投資関数I＝I０－ir  
I０：独立投資、i：投資の利子感応度、r：利子率  
とする。  
他方、貨幣市場の均衡条件は  
M＝L  
であり、Mは貨幣供給、Lは貨幣需要である。  
ここで、  
貨幣需要関数L＝kY－hr  
k：貨幣需要の所得感応度、h：貨幣需要の利子感応度  
とする。  
これらを連立させることにより、均衡GDPは  
Y＝  
\u0003  
１－c＋i  
k  
h  
（C０－cT＋I０＋G＋  
i  
h  
M）  
として求められる。  
上記の式から、  
\u0002  
財政政策（政府支出）の乗数は  
ΔY  
ΔG  
＝  
\u0003  
１－c＋i  
k  
h  
である。  
        
また、  
\u0002  
金融政策の乗数は  
ΔY  
ΔM  
＝  
i  
h  
１－c＋i  
k  
h  
である。  
（設問1））  
文中の下線部\u0003について、財政政策の効果に関する説明として、最も適切なも  
のの組み合わせを下記の解答群から選べ。  
ａ貨幣需要の利子感応度が小さいほど、クラウディング・アウトの程度が小さ  
く、財政政策に伴う所得拡大効果は大きくなる。  
ｂ限界貯蓄性向が大きいほど、財政政策の乗数はより大きくなる。  
ｃ投資の利子感応度が大きいほど、クラウディング・アウトの程度が大きく、  
財政政策に伴う所得拡大効果は小さくなる。  
ｄ「流動性のわな」に陥った場合、財政政策の乗数は  
\u0002  
１－c  
で示される。  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 101220062,
                "name": "第6問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、金融政策の効果に関する説明として、最も適切なも  
のの組み合わせを下記の解答群から選べ。  
ａ貨幣需要の利子感応度が小さいほど、貨幣供給の増加に伴う利子率の低下幅  
が大きく、金融政策の所得拡大効果が大きくなる。  
ｂ投資の利子感応度が大きいほど、利子率の低下に伴う民間投資支出の拡大幅  
が大きく、金融政策の有効性が高まる。  
ｃ投資の利子感応度が無限大の場合、金融政策の乗数はゼロになる。  
ｄ「流動性のわな」に陥った場合、金融政策の乗数は  
\u0003  
k  
になる。  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 101220070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
不完全競争市場における多くの企業は、標準的な平均費用に一定の比率を乗じた  
上で価格を設定している。いま、生産費用が労働に対する報酬のみであるとした場  
合、次の式が成り立つ。  
P＝（\u0002＋m）  
WL  
Y  
ここで、Pは価格、mはマークアップ率、Wは労働\u0002単位当たりの名目賃金、L  
は雇用量、Yは生産量である。また、ここでは限界生産物が逓減する生産関数を仮  
定する。  
マークアップ率に基づく価格形成に関する説明として、最も適切なものの組み合  
わせを下記の解答群から選べ。  
ａ生産量が増加するにつれて、労働に関する平均生産物が上昇するために価格は  
下落する。  
ｂ生産量が増加するにつれて、労働に関する平均生産物が低下するために価格は  
上昇する。  
ｃ需要の価格弾力性が大きい財ほど、マークアップ率を高くし、企業は収入の増  
加を図る。  
ｄ需要の価格弾力性が大きい財ほど、マークアップ率を低くし、企業は収入の増  
加を図る。  
〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101220081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の開放マクロ経済モデルに関する文章を読んで、下記の設問に答えよ。  
下図は、開放経済下におけるマクロ経済モデルを描いたものである。  
いま、小国モデル、完全資本移動、変動為替レート制、物価の硬直性、静学的為  
替レート予想を仮定する。下図では、これらの前提に基づき、生産物市場の均衡を  
示すIS曲線、貨幣市場の均衡を示すLM曲線、自国利子率（r）と外国利子率（r＊）  
の均等化を示すBP曲線が表されている。  
（設問1））  
貨幣供給の増加に伴う効果の説明として、最も適切なものはどれか。  
  
ア  貨幣供給の増加は、LM曲線を右方にシフトさせ、民間投資支出の増加を通  
じて所得を増加させる。  
  
イ  貨幣供給の増加は、LM曲線を右方にシフトさせるが、外貨準備の取り崩し  
とそれに伴う貨幣供給の反転減少を伴い、所得に影響を与えない。  
  
ウ  貨幣供給の増加は、経常収支の改善を通じて所得の拡大を引き起こす。  
  
エ  貨幣供給の増加は、内外金利差に伴う大量の資本の流出を引き起こし、円高  
を生じさせる。  
`
        },
        {
                "mondaiId": 101220082,
                "name": "第8問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
外国利子率の低下に伴う効果の説明として、最も適切なものはどれか。  
  
ア  外国利子率の低下は、円安を通じて自国の経常収支を改善させる効果を持  
つ。  
  
イ  外国利子率の低下は、金融緩和と同じ効果を持ち、LM曲線を右方にシフト  
させ、円安を通じて所得の拡大を引き起こす。  
  
ウ  外国利子率の低下は、金利差に伴う資本の流入を引き起こし、円高を通じて  
IS曲線を左方にシフトさせ、所得の減少をもたらす。  
  
エ  外国利子率の低下は、自国利子率の低下をもたらし、民間投資支出の増加を  
通じて所得の拡大を生じさせる。`
        },
        {
                "mondaiId": 101220090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
実物的景気循環理論（リアル・ビジネス・サイクル理論）の考え方として、最も適  
切なものはどれか。  
  
ア  貨幣の中立性が成立し、金融政策が景気循環の要因であると考える。  
  
イ  財・サービスの価格は粘着的であり、企業は独占的競争のもとで活動してい  
ると考える。  
  
ウ  市場は競争的で、かつ連続的に均衡し、市場の失敗が景気循環の要因である  
と考える。  
  
エ  実質GDPの変動は、企業の技術水準や政府支出などの持続的な変化による  
と考える。  
`
        },
        {
                "mondaiId": 101220100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
下図は、最低賃金制度や農産物の価格支持政策のような価格の下限規制を描いた  
ものである。  
完全競争市場における均衡は、需要曲線D０D１と供給曲線S０S１の交点Eで実現  
し、均衡価格はP０、均衡量はQ０である。ここで、均衡価格より高いP１の水準で  
価格の下限が規制されたとする。このとき、取引量はQ１に減少する。  
この図の説明として、最も不適切なものを下記の解答群から選べ。  
〔��答群〕  
  
ア  価格の下限が規制された場合でもパレート最適が実現する。  
  
イ  価格の下限規制により、経済余剰の損失は三角形EFGになる。  
  
ウ  価格の下限規制により、消費者余剰は減少し、三角形D０GP１に相当する。  
  
エ  価格の下限規制のもとでは、生産者余剰は台形S０FGP１に等しくなる。  
`
        },
        {
                "mondaiId": 101220110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
いま、A国とB国間の貿易において、各国が自由貿易を選択するか、それとも  
保護貿易を選択するか、を迫られているとする。下表は、このときの利得を表した  
ものである。  
両国が自由貿易を選択すれば、ともに４０兆円の利益を得る。しかし、一方の国  
が保護貿易を選択すれば、当該国の利益は５０兆円であるが、自由貿易を選択する  
他方の国の利益は\u0002兆円である。さらに、両国が保護貿易を選択すれば、両国の利  
益はともに１０兆円である。  
下表の説明として、最も適切なものを下記の解答群から選べ。  
B国  
保護貿易自由貿易  
A  
国  
保護貿易  
A国：１０兆円  
B国：１０兆円  
A国：５０兆円  
B国：８兆円  
自由貿易  
A国：８兆円  
B国：５０兆円  
A国：４０兆円  
B国：４０兆円  
〔解答群〕  
  
ア  協力ゲームの解は、両国が保護貿易を選択することである。  
  
イ  ナッシュ均衡の解は、両国が保護貿易を選択することであり、パレート最適  
になる。  
  
ウ  非協力ゲームの解は、両国が自由貿易を選択することである。  
  
エ  両国が自由貿易協定を締結した場合、両国全体の利益は最大になる。  
`
        },
        {
                "mondaiId": 101220120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
下図は、独占的競争下にある企業の短期均衡を描いたものである。Dは需要曲  
線、MRは限界収入曲線、ACは平均費用曲線、MCは限界費用曲線である。この  
とき、利潤最大化を前提とした価格はP０、取引量はQ０に決定される。  
この図の説明として、最も適切なものの組み合わせを下記の解答群から選べ。  
ａ独占的競争では、少数の企業が相互に差異化した財・サービスを供給する。  
ｂMR＝MCが成り立つところで利潤が最大になり、P＞ACであるために企業  
の利潤は黒字になる。  
ｃ短期均衡において企業の利潤が黒字であるために、新たな企業の参入が生じ、  
\u0002社当たりの需要が減少して需要曲線が左方にシフトする。  
ｄ企業の利潤が黒字であるかぎり、新規参入が継続し、短期均衡における利潤は  
ゼロになる。  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101220130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
新興国の中には、自国通貨をアメリカ・ドルに事実上連動させている国もある。  
自国通貨の切り上げ圧力が高まっているときに、その国が為替介入を行いアメリ  
カ・ドルとの固定レートを維持しようとすると、どのような影響があるか。最も適  
切なものの組み合わせを下記の解答群から選べ。  
ａ自国の金融市場は緩和的になる。  
ｂ自国の金融市場は引き締め的になる。  
ｃ外貨準備高が増加する。  
ｄ外貨準備高が減少する。  
〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101220140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
BOP（BaseofPyramidあるいはBottomofPyramid）と呼ばれる社会の底辺に位  
置する人々に対するビジネスが注目を浴びている。その中で、バングラデシュのグ  
ラミン銀行に代表されるマイクロクレジットという手法は大きな注目を集めた。マ  
  
イ  クロクレジットは、通常は銀行からの融資を受けられない人々を対象に、ごく少  
額の融資を行うものである。融資された人々が事業を行うことが可能になり、収入  
を得て、貧困から脱することができる。  
マイクロクレジットの手法の特徴として、グループに対するごく少額の金額の貸  
し付け、定期的な返済、返済が滞るとグループ全体が連帯責任を負うことの義務付  
け、などが挙げられる。これらの特徴をゲームの理論から見た場合、最も適切なも  
のはどれか。  
  
ア  逆選択を発生させず、\u0002回のゲームの中で囚人のジレンマを避ける仕組み。  
  
イ  逆選択を発生させず、繰り返しゲームの中で囚人のジレンマを避ける仕組  
み。  
  
ウ  モラルハザードを発生させず、\u0002回のゲームの中で囚人のジレンマを避ける  
仕組み。  
  
エ  モラルハザードを発生させず、繰り返しゲームの中で囚人のジレンマを避け  
る仕組み。  
`
        },
        {
                "mondaiId": 101220150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
ある財の生産において公害が発生し、私的限界費用線と社会的限界費用線が下図  
のように乖離している。ここで、政府は企業が社会的に最適な生産量を産出するよ  
うに、\u0002単位当たりt＝BGの環境税の導入を決定した。その際、社会的な余剰  
は、どれだけ変化するか。最も適切なものを下記の解答群から選べ。  
〔解答群〕  
  
ア  三角形BCE分の増加  
  
イ  三角形CEH分の減少  
  
ウ  三角形CEH分の増加  
  
エ  四角形BCEG分の減少  
  
オ  四角形BCEG分の増加  
`
        },
        {
                "mondaiId": 101220160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
「レモン」市場のように情報が不完全な場合、買い手は価格が低くなると品質が低  
下することを予想する。下図は、「レモン」市場における需要曲線と供給線につい  
て、\u0002つのパターンを示している。「レモン」市場における需要曲線の形状ならび  
に、ワルラス的調整およびマーシャル的調整に関し、最も適切なものを下記の解答  
群から選べ。  
図\u0003図\u0002  
〔解答群〕  
  
ア  需要曲線は図\u0003のように描かれ、A点の近傍ではワルラス的調整、マーシャ  
ル的調整とも安定である。  
  
イ  需要曲線は図\u0003のように描かれ、B点の近傍ではワルラス的調整、マーシャ  
ル的調整とも不安定である。  
  
ウ  需要曲線は図\u0002のように描かれ、C点の近傍ではワルラス的調整は安定で、  
マーシャル的調整は不安定である。  
  
エ  需要曲線は図\u0002のように描かれ、D点の近傍ではワルラス的調整は不安定  
で、マーシャル的調整は安定である。  
`
        },
        {
                "mondaiId": 101220170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
一国の成長戦略の策定において、経済政策の効果が高い産業への投資が求められ  
る場合がある。そこで、重点的な産業の選択のための\u0002つの方法として、産業連関  
表を用いた分析がある。  
下表において、A産業で\u0002単位の生産を行うために必要なB産業からの原材料  
投入の構成を示す係数として、最も適切な数値を下記の解答群から選べ。  
中間需要  
最終需要生産額  
A産業B産業  
中間投入  
A産業３０１５０１２０３００  
B産業６０２５０１９０５００  
粗付加価値２１０１００  
生産額３００５００  
出所：総務省「産業連関表の仕組み」（総務省ホームページ）  
〔解答群〕  
  
ア  ０．１  
  
イ  ０．２  
  
ウ  ０．３  
  
エ  ０．５  
`
        },
        {
                "mondaiId": 101220180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
ある市場で\u0002社が競争している状況を考える。生産量で競争する場合と、価格で  
競争する場合に、それぞれの企業の反応関数ならびに反応関数の交点が下図のよう  
に表されている。先導者と追随者が区別された場合に、以下のａ～ｄの記述のう  
ち、最も適切なものの組み合わせを下記の解答群から選べ。  
生産量で競争する場合  
価格で競争する場合  
        
ａ生産量で競争する場合、先導者となった企業の利益は、反応関数の交点におけ  
る利益よりも高い。  
ｂ生産量で競争する場合、追随者となった企業の利益は、反応関数の交点におけ  
る利益よりも高い。  
ｃ価格で競争する場合、先導者となった企業の利益は、反応関数の交点における  
利益よりも高い。  
ｄ価格で競争する場合、追随者となった企業の利益は、反応関数の交点における  
利益よりも高い。  
〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ`
        },
        {
                "mondaiId": 101220190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
消費者需要理論に関し、消費の外部性を表す用語として、次の文中の空欄Ａおよ  
びＢに入る最も適切なものの組み合わせを下記の解答群から選べ。  
「期間限定の商品です」という宣伝文句はＡ効果を、「現在売れています」  
はＢ効果を反映している。  
〔解答群〕  
  
ア  Ａ：ヴェブレンＢ：スノッブ  
  
イ  Ａ：スノッブＢ：バンドワゴン  
  
ウ  Ａ：バンドワゴンＢ：スノッブ  
  
エ  Ａ：バンドワゴンＢ：ラチェット  
`
        },
        {
                "mondaiId": 101220200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
生産性向上に関し、全要素生産性（TFP：TotalFactorProductivity）の重要性に  
注目が集まっている。ある国のマクロ生産関数を次のように設定する。  
Y＝AK  
α  
L１－α  
ここで、YはGDP、Kは投入資本量、Lは投入労働量、αはパラメーター（\u0002≦  
α≦\u0003）である。この式から、以下の式が導出できる。  
ΔY  
Y  
＝  
ΔA  
A  
＋α  
ΔK  
K  
＋（\u0003－α）  
ΔL  
L  
TFPの変化率に相当するものとして、最も適切なものはどれか。  
  
ア  （\u0003－α）  
ΔL  
L  
  
イ  α  
ΔK  
K  
  
ウ    
ΔA  
A  
  
エ    
ΔA  
A  
＋α  
ΔK  
K  
  
オ    
ΔA  
A  
＋（\u0003－α）  
ΔL  
L  
`
        },
        {
                "mondaiId": 101220210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
行動経済学は、ノーベル経済学賞受賞者を出すなど、最近注目されている分野で  
ある。次の文中の空欄ＡおよびＢに入る最も適切な用語の組み合わせを下記の解答  
群から選べ。  
行動経済学において、目先の利得に惑わされ、将来の利得を過度に低く評価して  
しまいがちという考え方は、Ａという。また、得の領域では低い確率を高  
く見積もり、損の領域では高い確率を低く見積もることで、損失を利益より過大に  
見積もってしまうことは、Ｂという。  
〔解答群〕  
  
ア  Ａ：双曲割引Ｂ：限定合理性  
  
イ  Ａ：双曲割引Ｂ：プロスペクト理論  
  
ウ  Ａ：限定合理性Ｂ：双曲割���  
  
エ  Ａ：プロスペクト理論Ｂ：限定合理性  
`
        },
        {
                "mondaiId": 102220010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
主要簿と補助簿からなる帳簿組織における記録に関する記述として、最も適切な  
ものの組み合わせを下記の解答群から選べ。  
ａ特殊仕訳帳を導入した場合、取引の内容に応じて、一定期間に発生した取引の  
合計額を一括してまたは取引ごとに個別に総勘定元帳への転記が行われる。  
ｂ特殊仕訳帳を導入した場合、\u0002つの取引が普通仕訳帳と特殊仕訳帳の両方に記  
録される。  
ｃ普通仕訳帳のみを仕訳帳として用いる場合、取引の合計額を一括して総勘定元  
帳への転記が行われる。  
ｄ普通仕訳帳のみを仕訳帳として用いる場合、取引は普通仕訳帳から総勘定元帳  
に転記される。  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｃ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 102220021,
                "name": "第2問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
収益の認識は、一般に、商品等の販売または役務の給付によって  
\u0002  
実現したことを  
もって行われるとされている。しかし、長期の未完成請負工事等については、工事  
Ａ基準とともに、工事Ｂ基準が認められてきた。  
工事契約に係る収益（工事収益）およびその原価（工事原価）に関して定めた  
\u0003  
企業会  
計基準第１５号「工事契約に係る会計基準」では、工事の進行途上においても、その  
進捗部分についてＣの確実性が認められる場合には、工事Ｂ基準  
を適用し、この要件を満たさない場合には工事Ａ基準を適用するとされ  
る。  
（設問1））  
文中の空欄Ａ～Ｃに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：完成Ｂ：進行Ｃ：進捗  
  
イ  Ａ：完成Ｂ：進行Ｃ：成果  
  
ウ  Ａ：完成Ｂ：進捗Ｃ：進行  
  
エ  Ａ：進行Ｂ：完成Ｃ：進捗  
  
オ  Ａ：進捗Ｂ：完成Ｃ：成果  
`
        },
        {
                "mondaiId": 102220022,
                "name": "第2問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002の実現したことに相当する事項として、最も適切なものはどれ  
か。  
  
ア  委託販売における積送品に対する荷付為替手形の取り組み  
  
イ  市場性が高い鉱産物の採掘  
  
ウ  試用販売における得意先による買い取りの意思表示  
  
エ  予約販売における予約金の受け取り`
        },
        {
                "mondaiId": 102220023,
                "name": "第2問設問3",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0003の企業会計基準第１５号の適用範囲に関する記述として、最も  
適切なものの組み合わせを下記の解答群から選べ。  
ａ工事契約とは、土木、建築、造船や一定の機械装置の製造等、仕事の完成に  
対して対価が支払われる請負契約をいう。  
ｂ工事契約とは、仕事の完成に対して対価が支払われる請負契約のうち、土  
木、建築、造船や一定の機械装置の製造等、基本的な仕様や作業内容を顧客の  
指図に基づいて行うものをいう。  
ｃ工事契約に関して、施工者における工事原価および工事収益の会計処理に適  
用される。  
ｄ工事契約に関して、施工者における工事収益の会計処理ならびに開示に適用  
される。  
ｅ受注制作のソフトウェアについても、工事契約に準じて適用する。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｅオｄとｅ  
`
        },
        {
                "mondaiId": 102220030,
                "name": "第3問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当年度における次の勘定記入の空欄Ａ～Ｃに入る最も適切なものの組み合わせを  
下記の解答群から選べ。  
受取利息  
\u0002/\u0002Ａ５，０００\u0003/\u0002現金６，０００  
１２/３１Ｂ（）\u0004/\u0002現金（）  
１２/３１Ｃ（）  
１７，０００１７，０００  
〔解答群〕  
  
ア  Ａ：前期繰越Ｂ：残高Ｃ：次期繰越  
  
イ  Ａ：前期繰越Ｂ：未収利息Ｃ：次期繰越  
  
ウ  Ａ：前受利息Ｂ：残高Ｃ：未収利息  
  
エ  Ａ：前受利息Ｂ：損益Ｃ：前受利息  
  
オ  Ａ：未収利息Ｂ：損益Ｃ：未収利息  
`
        },
        {
                "mondaiId": 102220040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の商品有高帳（単位：円）に基づき、A品の先入先出法による月間の売上原価と  
次月繰越高として、最も適切なものの組み合わせを下記の解答群から選べ。  
商品有高帳  
先入先出法品名A品  
月日摘要  
受入払出残高  
数量単価金額数量単価金額数量単価金額  
７１前月繰越２０６００１２，０００２０６００１２，０００  
７仕入７０６００４２，０００９０６００５４，０００  
１３売上５０６００３０，０００４０６００２４，０００  
１９仕入５５６４０３５，２００  
２０仕入戻し５６４０３，２００  
２５売上４０  
２９売上２０  
３１次月繰越  
〔解答群〕  
  
ア  売上原価：６３，６００円次月繰越高：１９，２００円  
  
イ  売上原価：６３，６００円次月繰越高：２２，４００円  
  
ウ  売上原価：６６，８００円次月繰越高：１６，０００円  
  
エ  売上原価：６６，８００円次月繰越高：１９，２００円  
  
オ  売上原価：７０，０００円次月繰越高：１６，０００円  
`
        },
        {
                "mondaiId": 102220050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄Ａ～Ｃに入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
株式会社の設立または株式の発行に際し、株主となる者が当該株式会社に払込み  
または給付をした財産のＡを資本金とするのが原則である。しかし、払込  
額または給付額のＢを資本金としないで、資本準備金として計上すること  
ができる。また、公開会社では、設立に際し発行可能株式総数のＣの株式  
を発行しなければならない。  
〔解答群〕  
  
ア  Ａ：\u0002分の\u0003Ｂ：\u0004分の\u0003Ｃ：\u0002分の\u0003以上  
  
イ  Ａ：\u0002分の\u0003以上Ｂ：\u0004分の\u0003までＣ：\u0004分の\u0003以上  
  
ウ  Ａ：全額Ｂ：\u0002分の\u0003までＣ：\u0004分の\u0003以上  
  
エ  Ａ：全額Ｂ：\u0002分の\u0003までＣ：\u0002分の\u0003  
  
オ  Ａ：全額Ｂ：\u0002分の\u0003Ｃ：\u0002分の\u0003以上  
`
        },
        {
                "mondaiId": 102220060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当期の資産と損益に関する次の資料（単位：千円）に基づいて、キャッシュ・フ  
ロー計算書の空欄Ａに入る数値として最も適切なものを下記の解答群から選べ（単  
位：千円）。  
資産損益  
期首期末減価償却費２，０４０  
有形固定資産４８，７００４７，０００固定資産売却益１５０  
減価償却累計額１２，０００１３，２００  
３６，７００３３，８００  
キャッシュ・フロー計算書  
営業活動によるキャッシュ・フロー  
営業収入１８６，６００  
原材料または商品の仕入れによる支出\u0002１３８，６００  
人件費の支出\u0002９，３００  
その他の営業支出\u0002７，８００  
小計３０，９００  
利息及び配当金の受取額１，５００  
利息の支払額\u0002４６０  
法人税等の支払額\u0002１１，８００  
営業活動によるキャッシュ・フロー２０，１４０  
投資活動によるキャッシュ・フロー  
有価証券の売却による収入１，８５０  
有形固定資産の売却による収入Ａ  
投資活動によるキャッシュ・フロー  
（以下省略）  
〔解答群〕  
  
ア  ８４０イ９６０ウ１，０１０エ１，２００  
`
        },
        {
                "mondaiId": 102220070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄に入る最も適切なものを下記の解答群から選べ。  
原価計算制度において、原価とは、経営における一定の給付にかかわらせて、財  
貨または用役（以下「財貨」という。）の消費を把握し、貨幣価値的に表したものであ  
る。原価は、に関して消費された経済価値であり、正常な状態における  
経営活動を前提として把握された価値の消費である。  
〔解答群〕  
  
ア  財貨の生産  
  
イ  財貨の生産、販売  
  
ウ  財貨の生産、販売および財務活動  
  
エ  財貨の調達、生産  
`
        },
        {
                "mondaiId": 102220080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当期の損益計算書は次のとおりである。総資本回転率が１．２回、経営資本回転率  
が１．４回であるとき、事業活動に使用している投下資本に対して本業から利益をど  
の程度生み出すことができたのかを示す資本利益率の値として、最も適切なものを  
下記の解答群から選べ。  
損益計算書（要旨）  
（単位：千円）  
売上高５００，０００  
売上原価３０７，０００  
売上総利益１９３，０００  
販売費及び一般管理費１６３，０００  
営業利益３０，０００  
営業外収益７，６００  
営業外費用４，２００  
経常利益３３，４００  
特別利益１，７００  
特別損失２，８００  
税引前当期純利益３２，３００  
法人税等１２，３００  
当期純利益２０，０００  
〔解答群〕  
  
ア  ４．８％  
  
イ  ５．６％  
  
ウ  ７．２％  
  
エ  ８．４％  
`
        },
        {
                "mondaiId": 102220090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当期の売上高と費用の内訳は次のとおりである。他の条件に変化はないものとし  
て、販売価格が１，７００円に低下した場合の損益分岐点売上高の変化として、最も適  
切なものを下記の解答群から選べ（単位：千円）。  
売上高（価格２，０００円、数量４００個）８００千円  
変動費３２０千円  
固定費３６０千円  
〔解答群〕  
  
ア  －１００  
  
イ  ＋８０  
  
ウ  ＋１００  
  
エ  ＋２００  
`
        },
        {
                "mondaiId": 102220100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
製品X（価格６００円、単位あたり変動費３６０円）と製品Y（価格１，０００円、単位あ  
たり変動費７００円）の実行可能な販売数量の組み合わせは下図のとおりである。利  
益を最大にする販売数量の組み合わせとして、最も適切なものを下記の解答群から  
選べ。  
（注）x：製品Xの販売数量、y：製品Yの販売数量。  
〔解答群〕  
  
ア  Xの販売数量：０Yの販売数量：３，０００  
  
イ  Xの販売数量：１，５００Yの販売数量：３，０００  
  
ウ  Xの販売数量：２，２５０Yの販売数量：２，２５０  
  
エ  Xの販売数量：３，０００Yの販売数量：１，２５０  
  
オ  Xの販売数量：４，０００Yの販売数量：５００  
`
        },
        {
                "mondaiId": 102220110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
変動予算制を採用しているX社の販売費予算と実際販売費等は次のとおりであ  
る。実際販売数量に対する販売費予算と実際販売費との差異（予算差異）として、最  
も適切なものを下記の解答群から選べ（単位：円）。  
\u0002\u0002\u0002\u0002\u0002\u0002\u0002\u0002\u0002\u0002  
予算実際  
販売数量１００，０００個９０，０００個  
変動販売費６００，０００円５６４，０００円  
固定販売費８００，０００円８１１，０００円  
販売費計１，４００，０００円１，３７５，０００円  
〔解答群〕  
  
ア  １１，０００（不利差異）  
  
イ  ２４，０００（不利差異）  
  
ウ  ２５，０００（有利差異）  
  
エ  ３５，０００（不利差異）  
`
        },
        {
                "mondaiId": 102220120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
A社の損益に関するデータは以下のとおりである。A社の減価償却費は１，０００千  
円であり、これは全額更新投資にあてられる。また、実効税率は４���％であり、運  
転資本の増減はない。このとき、A社のフリー・キャッシュ・フローの金額として  
最も適切なものを下記の解答群から選べ（単位：千円）。  
（単位：千円）  
営業利益１０，０００  
支払利息４，０００  
税引前利益６，０００  
法人税等２，４００  
当期純利益３，６００  
〔解答群〕  
  
ア  ４，６００イ６，０００ウ７，０００エ７，４００`
        },
        {
                "mondaiId": 102220130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
ファイナンス・リースの特徴に関する説明として、最も不適切なものはど��か。  
  
ア  借手が支払うリース料は目的物の経済価値全体ではなく、リース期間終了後の  
目的物の残存価値や不特定多数の事業者に複数回賃貸することなどを考慮して算  
定される。  
  
イ  借手からみた場合、経済的には目的物の購入資金を融資されるのとほぼ同じ機  
能をもつ。  
  
ウ  借手がリース契約を解約する場合には、通常、未経過リース料に相当する違約  
金を支払う。  
  
エ  目的物を使用する借手が当該目的物の導入に際し、一度に多額の資金を必要と  
しないメリットがある。  
`
        },
        {
                "mondaiId": 102220141,
                "name": "第14問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
次の文章を読んで、下記の設問に答えよ。  
B社は全額株主資本で事業活動を行っており、営業利益の確率分布は下表のとお  
りで今後毎期一定である。なお、営業利益は税・利息支払前利益（EBIT）に等しい  
ものとする。  
（単位：万円）  
好況（確率：０．５）不況（確率：０．５）  
営業利益（EBIT）１，２００８００  
（設問1））  
B社の企業価値は、完全市場の仮定のもとで\u0002億円と評価される。  
このとき、B社の事業活動のリスクに対して、市場が要求する株主資本収益率  
として最も適切なものはどれか。  
  
ア  ８％  
  
イ  １０％  
  
ウ  １２％  
  
エ  ２０％  
`
        },
        {
                "mondaiId": 102220142,
                "name": "第14問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
B社と資産内容が全く同じで、同一の事業を営むC社が存在するものとす  
る。したがって、C社が生み出す毎期のEBITの確率分布は、B社と全く同一で  
ある。ただし、C社とB社では資本構成が異なっており、C社は５，０００万円の負  
債を利用している。この負債の利子率は\u0003％である。この市場において、法人  
税のみが存在しその実効税率が４０％であるとすれば、B社の企業価値とC社の  
企業価値との差はどのようになるか、最も適切なものを選べ。  
  
ア  C社の企業価値はB社と変わらない。  
  
イ  C社の企業価値はB社より２００万円小さい。  
  
ウ  C社の企業価値はB社より２，０００万円大きい。  
  
エ  C社の企業価値はB社より５，０００万円大きい。`
        },
        {
                "mondaiId": 102220150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
ある投資プロジェクトによって\u0004年後にもたらされるキャッシュ・フローは、  
５０％の確率で３，０００万円であるか、５０％の確率で１，０００万円であるかのどちらか  
であるという。今、安全利子率は１０％である。意思決定者がリスク中立的である  
とき、この意思決定者は、当該投資プロジェクトに現在約何万円まで拠出するか、  
最も適切なものを選べ。  
  
ア  ４５４万円  
  
イ  ９０９万円  
  
ウ  １，８１８万円  
  
エ  ２，７２７万円  
`
        },
        {
                "mondaiId": 102220160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
資金を\u0002つの証券に分散して投資を行う場合、投資収益率のリスク低減効果が最  
大になるのはどれか、最も適切なものを選べ。  
  
ア  \u0002つの証券の投資収益率が完全に相関している場合  
  
イ  \u0002つの証券の投資収益率が完全に負相関している場合  
  
ウ  \u0002つの証券の投資収益率間に全く相関がない場合  
  
エ  \u0002つの証券の投資収益率間に弱い負相関がある場合`
        },
        {
                "mondaiId": 102220171,
                "name": "第17問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。  
D社は現在、新規設備の導入を検討中である。D社では、この投資案件の意思  
決定を正味現在価値法に基づいて判定することにしている。そこでD社は、正味  
現在価値法において  
\u0002  
割引率として用いられるべき資本コストの推計に取り掛かって  
いる。  
D社は、今回の投資案件において、投資に必要とされる資金の調達を全額長期  
借入によって行うことにしている。この借入金の金利は\u0003％である。  
また、D社は企業目標として平均的に有利子負債と株主資本との割合を\u0004：\u0004  
で維持することにしており、現在の株式資本コストについても検討することにし  
た。D社では、経験的に自社の株式投資収益率とTOPIXの変化率との間に何らか  
の関係性があることを認識していた。そこでD社は、自社の株式投資収益率  
（RDt）とTOPIXの変化率（RTt）との間に次式のような関係があるものとして、過  
去の観察データに基づいて次式のαとβを実証的に推計することにした。  
\u0003  
RDt＝α＋βRTt＋et  
ただし、etの期待値はゼロ、分散は一定と仮定される。  
        
（設問1））  
文中の下線部\u0002のように、この場合において投資判定基準として用いられるべ  
き資本コストの説明として最も適切なものはどれか。  
  
ア  過去のROAの平均値を用いる。  
  
イ  株式資本コストを用いる。  
  
ウ  企業全体の平均的な負債と株主資本との比率を用いた加重平均資本コストを  
用いる。  
  
エ  長期借入金利を用いる。`
        },
        {
                "mondaiId": 102220172,
                "name": "第17問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0003のような関係性を仮定して証券収益率の期待値を推計するモデ  
ルとして最も適切なものはどれか。  
  
ア  \u0003項モデル  
  
イ  APT  
  
ウ  CAPM  
  
エ  インデックス・モデル  
`
        },
        {
                "mondaiId": 102220181,
                "name": "第18問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
次の文章を読んで、下記の設問に答えよ。  
事務機器の販売を行っているF社は、得意先であるアメリカの会社から販売代  
金１００万ドルを\u0002カ月後に受け取ることになっている。F社は円高傾向を予想して  
おり、為替変動リスクをヘッジするためにZ銀行と\u0002ドル９８円の予約レートで為  
替予約（ドル売り）を結んだ。一方、ゲームソフトの販売を行っているG社も同じ  
く販売代金２０万ドルをアメリカの会社から\u0002カ月後に受け取ることになってい  
る。G社もまた為替変動リスクに備えるため、先物市場においてドルの\u0002カ月物先  
物を先物価格１００円にて２０万ドル分売建てた。なお、両者の商品販売時であるこ  
の時点での直物レートは\u0002ドル＝１０２円であった。  
さて、\u0002週間が経過した後、当初の予想に反し、直物レートは\u0002ドル＝１０５円の  
円安となった。これを受けてG社は反対売買による差金決済を行った。このとき  
の先物価格は\u0002ドル＝１０３円であった。  
その後\u0002カ月が経過し、販売代金受け取り時における直物レートは\u0002ドル＝１０８  
円になっていた。  
（設問1））  
F社の為替予約による損益と直物による損益とをあわせたネットの損益として  
最も適切なものはどれか。  
  
ア  １，０００万円の損失  
  
イ  ４００万円の損失  
  
ウ  ６００万円の利益  
  
エ  １，０００万円の利益  
`
        },
        {
                "mondaiId": 102220182,
                "name": "第18問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
G社の通貨先物取引による損益と直物による損益とをあわせたネットの損益と  
して最も適切なものはどれか。  
  
ア  １６０万円の損失  
  
イ  ６０万円の損失  
  
ウ  ６０万円の利益  
  
エ  １２０万円の利益  
`
        },
        {
                "mondaiId": 102220190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
次の文章の空欄ＡとＢに入る語句の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  
業績連動型の配当政策をとった場合、毎期のＡは比較的安定するが\u0002株  
当たり配当額の変動が大きくなる。また、ＢはROEとＡを掛け  
合わせたものであり、資本効率と利益還元政策のバランスを見る\u0002つの指標であ  
る。  
〔解答群〕  
  
ア  Ａ：株主資本配当率Ｂ：内部成長率  
  
イ  Ａ：配当性向Ｂ：株主資本配当率  
  
ウ  Ａ：配当性向Ｂ：内部成長率  
  
エ  Ａ：配当利回りＢ：株主資本配当率  
  
オ  Ａ：配当利回りＢ：内部成長率`
        },
        {
                "mondaiId": 102220200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
E社では、創業以来のオーナー経営者が引退することとなり事業承継のあり方を  
検討していたが、オーナー経営者の後継者として適当な人材がいなかった。そこ  
で、M&Aを活用し外部の有能な経営者に事業を承継させたいと考えた。このよう  
な場合において、友好的なM&Aを行う上でふさわしいと考えられる手段として、  
最も適切なものはどれか。  
  
ア  MBI  
  
イ  MBO  
  
ウ  TOB  
  
エ  ホワイトナイト  
`
        },
        {
                "mondaiId": 103220010,
                "name": "第1問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
これまで高い評判を受けていたメーカーの製品が、あるとき、急にその評判を落  
としてしまうことがある。評判を落とす直接的な原因は、顧客からのクレームの多  
発やクレーム対応のまずさなどであるが、クレームが発生するまでに、しばしば既  
に企業内部に幾多の問題が潜んでいることがある。また、問題が発生する状況は業  
種によって異なることが多い。次の記述のうち、クレームを発生させる可能性が最  
も高いものはどれか。  
  
ア  ある家電メーカーでは、部品の標準化やモジュール化を徹底して製品の構造設  
計の簡素化を推し進めながら、製品検査とクレーム対応の現地化を図るために、  
海外の生産拠点での仕様の一部変更を認めるようにした。  
  
イ  技術の分かるマネジャーによる市場対応を図るために、生産技術に支障がない  
ように注意して、エンジニアリング部門から営業部門や事務部門への配置転換を  
進めている。  
  
ウ  これまでクレーム等の消費者対応はすべて本社での対応であったが、販売増大  
とともに事務処理が滞るようになったので、クレーム情報は本社に報告するが、  
重大なクレーム以外の日常的な消費者対応は営業拠点に委ねることにした。  
  
エ  自社の清涼飲料水の売り上げが急拡大しているので、自動化ラインの工場を立  
ち上げて、従業員を募って量産体制に入った。  
  
オ  高い品質で知られる中堅部品メーカーでは、収益性をさらに高めるべく、手間  
とコストのかかる品質検査を公的検査機関に依頼するとともに、賃金の高い熟練  
技術者に代わって若手従業員を新規に雇用し、個々の業績評価を賃金に連動させ  
るように人的資源戦略を転換した。  
`
        },
        {
                "mondaiId": 103220020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
どの業種にもいわゆる勝ち組と負け組が見られる。激しい競争にもかかわらず他  
社よりも優れた業績をあげている企業の特徴に関する記述として、最も不適切なも  
のはどれか。  
  
ア  ある通信機器メーカーでは、生産を国内工場に集約して生産現場で厳格な品質  
管理体制をとり、堅牢な機器と先進的なデータ処理を売りに、顧客の信頼を得な  
がら業界水準よりも高い価格で売り上げを伸ばしている。  
  
イ  ある町工場では単品物の受注に特化しているが、熟練を活かした加工技術を武  
器に、あらゆる注文に応えられる受注生産体制を敷いて、特定業種にこだわらな  
い受注先を確保している。  
  
ウ  健康食品を製造販売しているある企業では、顧客からのダイレクトな注文や問  
い合わせに応えるべく、コールセンターの充実を図るとともに、それを基にした  
顧客データベースを活かして、逆に顧客への情報発信を行い、顧客との強い信頼  
関係の構築を目指している。  
  
エ  創業間もない中小化粧品メーカーでは、肌に潤いを与える希少な天然素材を活  
用した高価な基礎化粧品に絞り込んで、全国的な広告宣伝と大手百貨店や量販店  
への出店を目指している。  
  
オ  激しい価格競争と急激な利益率低下のため大手の電子機器メーカーが撤退した  
市場で、ある中堅メーカーでは海外企業からの低価格な中間財の調達と自社が得  
意とする実装技術を活かして、実用本位の機能に絞り込んだ低価格製品で安定し  
た売り上げを確保している。  
`
        },
        {
                "mondaiId": 103220030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業に固有な能力は、自社独特の戦略展開を可能にするものとして重要である。  
企業が生産技術にかかわる固有能力を維持したり構築しようとする試みに関する記  
述として最も適切なものはどれか。  
  
ア  業界に普及している汎用設備の導入を進めて、資本装備率の向上を目指してい  
る。  
  
イ  現場からの改善提案のうち、全社QC大会で表彰を受けたものだけを実行して  
いる。  
  
ウ  現場への新人の投入時に技術指導を中心に導入教育に時間をかけている。  
  
エ  自社の生産技術を守るべく、生産部門での人事異動も新規採用も行わないこと  
にしている。  
  
オ  他社の優れた生産技術を積極的に導入して自社技術を常に一新するようにして  
いる。  
`
        },
        {
                "mondaiId": 103220040,
                "name": "第4問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業は技術の発展にともなって、しばしば研究開発組織をダイナミックに組み替  
えたり、研究開発の仕組みに工夫をしている。そのような状況に関する記述のう  
ち、製品開発戦略の展開に結びつく研究開発組織のあり方として、最も不適切なも  
のはどれか。  
  
ア  技術ごとの機能分化が進み過ぎたので、市場が求める製品のニーズに沿って技  
術部門を再編するとともに、営業部門との協議を開くことにしている。  
  
イ  技術的に複雑な製品が増えてきたので、プロダクトチームのほかに技術分野ご  
との機能別チームを再び編成し、開発段階に沿って機能別チームがプロダクト  
チームと連携することにしている。  
  
ウ  製品開発のプロジェクトリーダーには、専門的な技術知識とともにチームをま  
とめるマネジメント能力や人間性を重視して任命している。  
  
エ  製品ごとに開発担当者をおき、その下に開発チームを編成し、開発が終わった  
段階で担当者およびチーム参加者は解散することにしている。  
  
オ  中央研究所を見直して担当を開発研究に絞り込み、外部と取引や技術交流のあ  
る生産技術部門や営業部門に基礎研究を移管している。  
`
        },
        {
                "mondaiId": 103220050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業は経済社会の一員として多面的に社会的な責任や期待を負いつつ、経済活動  
を展開している。そのような社会的存在としての企業の経営行動に関する記述とし  
て最も適切なものはどれか。  
  
ア  企業価値は株式時価を中心に測定されるが、株価は企業が直接操作できない証  
券市場で形成されるため、企業価値は具体的な数値目標で表される目的にはなり  
にくいので、株価にとらわれない自社のビジョンに基づく経営を維持するべく上  
場を廃止する例が見られるようになった。  
  
イ  企業の利益極大化の追求は、納品業者や販売業者さらには労働者に厳しいコス  
ト削減を強いることになるので、利益計画は公表しないことにしている。  
  
ウ  長期の不況の中で賃金コストの抑制が図られ、安価な労働力として非正規雇用  
が増えたが、企業は雇用不安を抑えるべく、近年ではワークシェアリングを盛ん  
に導入している。  
  
エ  日本では同業者間で同質な技術や商品の開発競争が激化しやすく、その競争を  
一挙に海外でも展開する傾向があり、集中豪雨的な進出として批判されることが  
あるばかりか、技術力の低下が起こっている。  
`
        },
        {
                "mondaiId": 103220060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
先端的な技術分野では、研究開発に要する資金が大きくなるにつれて、企業間の  
技術や部材の調達をめぐって、これまでにない提携関係が多く見られるようになっ  
てきた。そのような提携に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  エレクトロニクス産業では、EMSと呼ばれる中間製品の安価な供給メーカー  
から、半導体や液晶ディスプレイなどを買い付けて、価格競争力を確保する動き  
が国際的に見られる。  
  
イ  カーエレクトロニクス化が進むにつれて、車載組み込みのソフトやハードの開  
発コストが膨大になっているので、ライバルメーカーが共同してその標準化に取  
り組む共同体が、欧州や日本に設立されている。  
  
ウ  技術が複雑多様化するにつれて、すべての技術を自前で持つことが不可能に  
なったので、研究開発テーマによっては、異業種他社の参加を広範囲に求めるこ  
とが多くなった。  
  
エ  技術規格が定まらない新規技術分野では、いくつかの企業が連携して技術規格  
の標準化を目指す動きが活発であるが、その帰趨は技術の優位性に依存してい  
る。  
  
オ  国際競争力を保つべく、同業者が連携して、規模の経済を狙って業界内でコア  
な標準部品の生産を特定企業に集中し、生産から撤退した企業はそこから供給を  
受ける仕組みが見られるようになった。  
`
        },
        {
                "mondaiId": 103220070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
科学的な基礎研究が事業に直接に結びつくとは限らない。また、事業化を目指し  
て開発研究を展開し、商品化に成功したからといって、その商品が期待したような  
成功を収めるわけではない。自社技術の独自性を磨くことは大事であるが、それが  
企業の技術競争力に結びつくとは限らない。このような技術開発への対応に関する  
記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ある大手企業では中央研究所のあり方を見直し、研究者の一部を事業部門での  
応用技術研究に配属して、事業部門の研究開発力を強化することにした。  
  
イ  ある耐久消費財メーカーでは、これまでのロット生産を廃止して、生産工程で  
は顧客の求める仕様を作り込むように生産計画を組んで、限りなく受注生産に近  
い生産技術を開発して顧客ニーズに応えるようにした。  
  
ウ  自社で行う研究分野を絞り込んで、集中的に研究者や資金を配分して、研究の  
スピードアップを図っているが、どの分野に集中するかの目利きが難しいので、  
中央研究所のメンバーにより技術ロードマップを作成した。  
  
エ  他社に先駆けて新技術の製品を発売するようにしているが、後発の他社にやが  
てシェアを奪われてしまうので、開発段階に営業部門が参加し、市場のニーズを  
活かした改良を加えて製品の独自性や魅力を高めるようにした。  
`
        },
        {
                "mondaiId": 103220081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
  
エ  レクトロニクス業界においては、自前で開発した技術にこだわる自社技術志向  
企業と、グローバルに中間財や他社技術を導入して対応しようとする国際水平分業  
志向企業とでは、製品のコモディティ化への対応が異なっている。  
下図は、\u0002つの企業の市場シェアの変化をコモディティ化に関連づけてイメージ  
化したものである。この図を参考にしながら下記の設問に答えよ。  
製品のコモディティ化と市場シェア  
        
（設問1））  
図のような現象に関連する状況についての記述として、最も不適切なものはど  
れか。  
  
ア  国際市況価格で原材料やキーデバイスを仕入れても、同業者も同様に振る舞  
うことができる分野では、価格優位の長期の維持は難しく、収益低下によって  
誰もが儲からない競争になりがちである。  
  
イ  国際水平分業志向企業は、グローバルに調達した安い原材料やキーデバイス  
で生産コストの安い国で生産を行いながら価格競争力を高めるのが合理的であ  
る。  
  
ウ  自社技術志向企業が価格競争力を失うのは、自前主義で時間のかかる独自技  
術の開発を貫こうとするためなので、コモディティ化のスピードに遅れた自社  
技術は積極的に破棄してその市場からの撤退を進めなければならない。  
  
エ  製品技術の優位性が薄らいでくると、国際的な中間財受託生産企業がキーデ  
バイス等の供給を通じて、新規業者の参入を推し進めるので、急速に価格競争  
が激化する。`
        },
        {
                "mondaiId": 103220082,
                "name": "第8問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
自社技術志向企業がコモディティ化に対応する戦略に関する記述として、最も  
不適切なものはどれか。  
  
ア  新しい技術を加えた製品を次々に発売して、キーデバイス等の供給企業の追  
随を振り払う。  
  
イ  技術の知的財産権を守り、急激なコモディティ化に歯止めをかけるように対  
応する。  
  
ウ  国際水平分業志向企業に技術を供与して、低価格をとる企業の間のライバル  
競争を煽り、それら企業を採算割れに追い込む。  
  
エ  国内や海外での市場を分析して、現地のニーズに合った製品の供給体制を構  
築する。  
  
オ  自社技術を磨いて、新興工業国の国際水平分業志向企業が作れない高機能製  
品に生産販売を集中する。  
`
        },
        {
                "mondaiId": 103220090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業は新規参入を阻止して競争激化を抑制しようとするが、他方では業界内部の  
類似する戦略をとる企業の間で戦略グループが形成され、それが企業の自由な戦略  
行動を抑制するように作用し始める。前者は参入障壁であり、後者は移動障壁であ  
る。これらの障壁と戦略の関係に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ある技術に基づいて生産し販売される製品分野は、ライバル企業の間で製品の  
類似性が高くなるので、企業は顧客忠誠心やブランド力を高めてライバルとの差  
別化を図ることが重要になる。  
  
イ  業界特有の販売チャネルや仕入れルートを同業者間で強化することは、他社の  
参入を防ぐには有効である。  
  
ウ  業界内の競争を通じて形成された事業システムやマネジメント方式は、企業に  
戦略上の癖や慣性を生み出すので、企業が移動障壁に直面する事態にはならな  
い。  
  
エ  垂直統合や共同化は取引先への交渉力の強化や新たな技術の獲得には有効であ  
るが、その縛りが強いと自社の戦略の成否が他社の戦略展開能力に影響されるよ  
うになる。  
  
オ  同業者間に共通する戦略課題について協調を維持すると、やがて戦略の類似性  
が強まり、新規な戦略の展開が困難になる。  
`
        },
        {
                "mondaiId": 103220100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
マイケル・ポーターは、競争戦略を策定する際に考慮すべき産業の利益率や競争  
に影響を与える要因として、下図の\u0002つを指摘している。この図に関する説明とし  
て、最も不適切なものを下記の解答群から選べ。  
\u0002つの競争要因  
〔解答群〕  
  
ア  買い手への対応は、消費者のクレームや消費者行動の変化に対処しつつ、高  
いマージンに結びつく市場との良好な関係を構築することが重要である。  
  
イ  供給業者については、資金や原材料の供給先や労働市場との交渉力の保持が  
重要であるので、そのためには特定の資源の供給者に強く依存することなく、  
常に代替���な資源の開発に取り組むなど外部への依存性が強くならないように  
しておくことが重要である。  
  
ウ  競争業者との戦いは、マージンの高いドメ��ンに自社を位置づけて、そこで  
の防衛的な地位を保つために、徹底した差別化戦略を展開することが第一に重  
要である。  
  
エ  新規参入については、その可能性や参入を受けた場合の競争の変化を分析し  
て、自社の市場への参入障壁をどのように築くことができるか、日ごろから注  
意しておかなければならない。  
  
オ  代替品は、大きな技術の変化や消費者のニーズの変化によってこれまでにな  
い新商品として登場し、既存の商品に取って代わる脅威になることがあるの  
で、技術や市場のマクロなトレンドを見失わないように注意しなければならな  
い。  
`
        },
        {
                "mondaiId": 103220111,
                "name": "第11問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
次の文書を読んで、下記の設問に答えよ。  
地球温暖化防止をめぐって、  
\u0002  
様々な提案や取り組みが国際的に展開されている  
が、その動向は企業の戦略行動や国の環境対応に影響を与えている。特に二酸化炭  
素の排出量が問題となっている産業では、企業は  
\u0003  
二酸化炭素の排出を削減したりエ  
ネルギー消費を抑える技術の開発に熱心である。  
\u0004  
二酸化炭素排出量を削減する努力  
は企業に新たなコスト負担を強いることになるが、他方では開発された環境技術を  
活かして新たな市場が生まれつつある。  
（設問1））  
文中の下線部\u0002で指摘するような地球温暖化防止の試みとして、１９９７年の京  
都議定書では排出量取引が認められた。排出量取引に関する記述として、最も不  
適切なものはどれか。  
  
ア  一般に排出量取引のコストは環境技術やシステムの開発コストより低いた  
め、排出量取引が選好されて、温室効果ガスの排出削減が進まないことが懸念  
されている。  
  
イ  開発途上国の企業は先進国が開発した環境技術を導入することで、その開発  
コストを負担しないですむため、先進国と開発途上国の間でコスト負担にイン  
バランスが生じることになる。  
  
ウ  日本では排出量取引は全産業を対象にして自主参加型のものが試行されてい  
る段階である。  
  
エ  排出量取引では、排出量を排出枠内に抑えて発生した余剰（炭素クレジット）  
を、排出枠を超えて排出してしまった国が買い取れば、排出枠を遵守したと見  
なされる。  
`
        },
        {
                "mondaiId": 103220112,
                "name": "第11問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002の企業の環境技術の開発をめぐる動向に関する記述として、最  
も不適切なものはどれか。  
  
ア  ２００９年\u0003月に開始されたエコカー減税は、特例措置として低燃費・低排出  
ガスを認定された自動車の取得税や重量税に減免措置が適用されるので、自動  
車メーカーにはエコカー開発のインセンティブになっている。  
  
イ  ２００９年\u0004月に開始された家電エコポイントは、企業に対してグリーン家電  
の開発を促し、消費者には購買意欲を駆り立てている。  
  
ウ  \u0002次電池の開発に取り組んでいる企業では、電極のレアメタルの高騰が電池  
価格に反映することが隘路のひとつになっている。  
  
エ  電気自動車は構造が比較的単純であるので、充電システムについては自動車  
業界で統一した方法が確立している。  
  
オ  バイオ燃料は環境配慮型エネルギーとしてトウモロコシや大豆、菜種などか  
ら代替燃料が開発されており、欧州ではバイオディーゼルにも使用されている  
が、ディーゼル車が普及していない日本ではそのようなディーゼルの開発は低  
調である。  
`
        },
        {
                "mondaiId": 103220113,
                "name": "第11問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002のように環境技術を活かした市場とエコ産業をめぐる中小中堅  
企業の取り組みに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  各種のリサイクル法が制定されるにつれて、容器包装、家電、建設資材、食  
品、自動車などの再商品化や解体リサイクルに新規参入の機会が生まれ、静脈  
産業にベンチャー企業が誕生している。  
  
イ  古紙などの回収資源は天然資源に対して価格競争力をもっているので、古紙  
回収事業に多くの中小事業者が新規に参入している。  
  
ウ  資源循環型社会のエコタウン事業では、ゼロ・エミッションを目指して、各  
地の特性を活かして誘致企業とともにリサイクル技術の開発を進めている。  
  
エ  消費者のエコ意識とともに製品の安全性への関心が強くなるにつれて、安く  
ても安全性に疑念がある製品よりも少し高くても信頼できる製品を求める傾向  
が見られ、生産技術の確かな中小企業にビジネスチャンスが生まれる例が見ら  
れる。  
  
オ  リサイクル企業には貴金属鉱石よりも含有率が高い破棄家電等からレアメタ  
ルを抽出する独自技術を工夫する例があり、都市からレアメタルが生産される  
様を都市鉱山ということがある。  
`
        },
        {
                "mondaiId": 103220120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
リーダーシップの諸学説に関する記述として、最も適切なものはどれか。  
  
ア  ハウスによるパス・ゴール理論は、リーダーの職務は部下の業務目標の達成を  
助けることであり、そのために必要な方向性や支援を与えることにあるとした。  
  
イ  フィードラーによるコンティンジェンシー理論では、環境の不確実性が高い場  
合には有機的なリーダーシップが、不確実性が低い場合には機械的リーダーシッ  
プが望ましいとした。  
  
ウ  ブレイクとムートンによるマネジリアル・グリッドは、「構造作り」と「配慮」と  
いう二軸でリーダーシップ特性を分類し、\u0002―\u0002型が最も高い成果を生むとし  
た。  
  
エ  リッカートによる参加型リーダーシップでは、リーダーは部下の意思決定に積  
極的に参加し、影響力を行使することが重要であるとした。  
`
        },
        {
                "mondaiId": 103220131,
                "name": "第13問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
あなたがコンサルタントとしてアドバイスしている家庭用品メーカーA社には、  
以下のような特徴がある。これを読んで下記の設問に答えよ。  
A社は４０年の歴史があり、主力事業は既に成熟期に入っていて、その事業を展  
開する部門では安定的な利益率を確保していた。社員は部品レベルでの品質改善に  
取り組んでおり、皆忙しいと言っているが、市場シェアはほとんど変わらない。全  
体的に現状に満足している社員が多く、職場は比較的和気あいあいとしている。  
社長が主力事業部の従業員を活性化しようと、工場やマーケティング部門に権限  
を委譲し、生産コストや市場シェアによって評価する人事管理システムを導入し  
た。しかし、  
\u0002  
その結果、市場シェアは増大したが、歩留りが悪化し、利益率は低下  
してしまった。  
その一方で、トップマネジメントは  
\u0003  
新規事業に対して積極的に取り組むことを指  
示したが、部門管理者たちは最初は綿密な計画を立てるものの、実行段階になると  
業務がスムーズに運ばなくなり、いつのまにか撤退を余儀なくされてしまうことを  
繰り返してきた。  
        
（設問1））  
文中の下線部\u0002のような結果は、なぜ生まれたのか。考えられうる可能性とし  
て最も適切なものを選べ。  
  
ア  この事業部が扱う家庭用品市場がすでに成熟しており、価格競争でしかシェ  
  
ア  拡大が難しくなっていたため、コスト削減をトップが指示した可能性がある  
から。  
  
イ  事業部によって異なる目標管理制度が導入されたため、当該事業部の従業員  
が公平性を欠くと認識しこれに反発した可能性があるから。  
  
ウ  市場シェア目標やコスト管理目標が、事業部の投資利益率目標とは連携して  
いても、A社全体の利益率目標と合理的に連携していなかった可能性があるか  
ら。  
  
エ  市場シェア目標を達成するために、マーケティング部門は価格を低く設定  
し、その結果、販売数量が増加し、生産部門はコスト管理を徹底したために品  
質を犠牲にすることになった可能性があるから。  
  
オ  市場シェアや生産コスト管理のような、成果主義による管理方針に対して、  
従業員が反発した可能性があるから。`
        },
        {
                "mondaiId": 103220132,
                "name": "第13問設問2",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
A社が下線部\u0003のような組織になってしまう理由として考えられうる可能性と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  従業員の間で意思決定権限が細分化されており、多くの管理者の同意を得な  
ければならない可能性があるから。  
  
イ  従業員の業績評価システムが、ミスや失敗による減点方式になっている可能  
性があるから。  
  
ウ  従業員の職務と責任・権限が、会社の利益と関係づけて理解されていない可  
能性があるから。  
  
エ  主力事業部の規模や資産等のスラックが大きく、従業員が市場における変化  
や競争圧力を感じにくくなっている可能性があるから。  
  
オ  新規事業開発についてミドルマネジメントに十分な権限を委譲していないた  
め、彼らの知識創造力を十分活用できていない可能性があるから。  
`
        },
        {
                "mondaiId": 103220133,
                "name": "第13問設問3",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
A社の組織全体が抱えている問題点を改善する方策として、最も適切なものは  
どれか。  
  
ア  市場の動向に関する情報をもつ現場の従業員に権限を与え、ボトムアップで  
変革案を作成させる。  
  
イ  従業員に業績連動型の報酬制度を導入し、企業の利益と職務の関係を明確に  
する。  
  
ウ  中間管理職に権限を委譲し、彼らの自主性を重視したチーム運営ができるよ  
うにする。  
  
エ  中間管理職を横断する組織を作って、合議による変革プランを作成させる。  
  
オ  トップマネジメントによる方針決定と執行担当管理者の意思決定権限の所在  
を明確に定義する。`
        },
        {
                "mondaiId": 103220140,
                "name": "第14問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
従業員の動機づけ理論と、報酬制度との関係についての記述として、最も適切な  
ものはどれか。  
  
ア  時間給のような固定給制度は、個人の特性による差異を反映した公平理論と整  
合性が高い。  
  
イ  職能資格制度のような能力給は、仕事そのものにやりがいを見いだそうとする  
内発的動機づけ理論と整合性が高い。  
  
ウ  職務給制度は、その職務をよりよく遂行することを通じて自己実現を達成しよ  
うとする欲求階層説と整合性が高い。  
  
エ  年功給与制度は、年齢の上昇とともにそれに見合った能力を身につけようとす  
る人間の達成動機と整合性が高い。  
  
オ  利益分配制度のような変動給与制は、個人の業績とモチベーションが最大に  
なったときに受け取る報酬との間に強い関係があるとする期待理論と整合性が高  
い。  
`
        },
        {
                "mondaiId": 103220150,
                "name": "第15問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
新規事業に進出する際の手法は、本業との技術面での関連性と市場面での関連性  
を考慮する必要がある。下図は、縦軸に市場面での関連性を、横軸に技術面での関  
連性を、それぞれ本業、関連事業、非関連事業として、新規事業を分類したもので  
ある。B、D、Eに該当する新規事業に進出する際に適切な手法の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
〔解答群〕  
  
ア  B：社内計画的開発D：社内ベンチャー  
E：ジョイントベンチャー（合弁）  
  
イ  B：社内ベンチャーD：社内計画的開発  
E：ジョイントベンチャー（合弁）  
  
ウ  B：社内ベンチャーD：ジョイントベンチャー（合弁）  
E：買収  
  
エ  B：ジョイントベンチャー（合弁）D：社内計画的開発  
E：買収  
  
オ  B：ジョイントベンチャー（合弁）D：社内ベンチャー  
E：買収  
`
        },
        {
                "mondaiId": 103220161,
                "name": "第16問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
あらかじめ予測不能な事態が生じた場合、それを危機として認識し、適切に対応  
しなければ企業の存続が左右されてしまうことがある。このような対応の管理を、  
予測可能な範囲内での変化への対応としてのリスクマネジメントと区別して、「ク  
ライシスマネジメント」ということがある。  
クライシスマネジメントに関する下記の設問に答えよ。  
（設問1））  
不測の事態はあらかじめ予測することが困難ではあるが、多くの場合それに先  
立って何らかの兆候が見られる。したがってクライシスマネジメントでは、まず  
このような兆候を認識し、それが重大な危機を招く可能性があることを予測する  
組織能力が必要である。このような組織に関する記述として、最も適切なものは  
どれか。  
  
ア  オペレーションの現場近くにいる管理者や従業員を重視して、状況のわずか  
な変化を把握したり、それを事前に伝達した場合、十分に報いるような制度を  
整備しておく。  
  
イ  これまでの成功経験や処方箋を基礎に、職務をできるだけ規則的なものに定  
型化し、これを遵守するよう義務づける。  
  
ウ  組織としての情報処理能力を高めるために、他者とは異なる個人的意見を控  
え、メンバーが共有している事柄を基礎に議論をするよう習慣づける。  
  
エ  組織の中間管理職レベルの価値観を統一し、それを一貫性のある体系として  
維持することによって、そこから逸脱が生じた場合に問題に気づくことができ  
るようにしておく。  
  
オ  わずかなミスやヒヤリハット事例を収集し、それぞれの部門で原因や対処方  
法について議論する機会を定期的にもつようにする。  
`
        },
        {
                "mondaiId": 103220162,
                "name": "第16問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
万一、不測の事態が発生してしまった場合、その影響を最小限のものとし、で  
きるだけ迅速にその状況から脱却するための組織能力を高めておくことが必要で  
ある。このような組織に関する記述として、最も適切なものはどれか。  
  
ア  過去においてその組織がどのように成功してきたかに関する事例をできるだ  
け多く用意しておき、不測の事態が発生した場合に直ちに参照できるようにし  
ておく。  
  
イ  組織として同じ過ちを繰り返さないためには、従業員に対して過失を犯さな  
いよう十分な注意を払わせるとともに、過失を人事考課に反映させる仕組みを  
構築しておく。  
  
ウ  不測の事態が発生したときには、組織内に不安が広がらないよう、非公式な  
コミュニケーションルートを遮断し、公式の責任―権限関係を基礎に対応策  
を検討する。  
  
エ  不測の事態が発生した場合の標準業務手続きや職務規則をあらかじめ用意し  
ておき、計画的な訓練を行っておく。  
  
オ  不測の事態の発生とその深刻さを適切に伝えるために、電話や書類などでは  
なく、フェイス・ツー・フェイスのコミュニケーションを活用する。  
`
        },
        {
                "mondaiId": 103220170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
ある程度歴史を持った企業同士が、買収や合併をうまく遂行して高い成果に結び  
つけていくためには、事前にそれぞれの企業の組織文化、観察可能な人工物や標榜  
されている価値観レベルだけでなく、とくに暗黙に共有された仮定レベルの文化を  
明らかにしておく必要がある。このような組織文化を明らかにする方法として、最  
も適切なものはどれか。  
  
ア  社員によるグループを構成し、そのメンバーたちに率直に組織文化について語  
りあってもらう。  
  
イ  組織メンバー全員を対象に、どのような価値観を標榜しているかについて、質  
問紙調査法による調査を行う。  
  
ウ  その企業で重要な役割を果たしている個人に、どのような組織文化を持ってい  
ると思うかインタビューする。  
  
エ  その企業の具体的な問題解決の場面に、外部のファシリテータを介入させ、メ  
ンバーが暗黙のうちに前提としている考え方を自ら気づくようにする。  
`
        },
        {
                "mondaiId": 103220180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
日本国内の事業所で働く外国人労働者の労働・社会保険の適用に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  いわゆる不法就労の外国人は、業務上の災害のため傷病にかかった場合にも、  
労災保険の給付は受けられない。  
  
イ  いわゆる不法就労の外国人は、健康保険の被保険者となることはできない。  
  
ウ  健康保険の適用事業所の事業主は、日本国内に住所を有し健康保険に加入する  
満４０歳以上６５歳未満の外国人労働者についても、健康保険の一般保険料と合わ  
せて介護保険の保険料を徴収しなければならない。  
  
エ  雇用保険には国籍要件はないので、労働時間が週２０時間以上で、かつ、３１日  
以上雇用する見込みのある外国人労働者は、雇用保険に加入させなければならな  
い。  
  
オ  資格外活動の許可を受けて適用事業所に使用される外国人留学生は、\u0002カ月以  
内の期間を定めて雇用される者や所定労働時間が短い者など、厚生年金保険の被  
保険者資格要件を満たさないものを除き、厚生年金保険の被保険者となる。`
        },
        {
                "mondaiId": 103220190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
割増賃金に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  休憩時間を除き、\u0003日\u0004時間を超えて労働させた場合には、\u0002割\u0005分増以上の  
割増賃金を支払わなければならない。  
  
イ  法定休日を上回って設けられた所定休日に労働させた場合には、\u0006割\u0005分増以  
上の割増賃金を支払わなければならない。  
  
ウ  法定時間外労働が午後１０時以降（翌午前\u0005時まで）に及んだときは、その時間  
に対して\u0005割増以上の割増賃金を支払わなければならない。  
  
エ  労働者を\u0003カ月に６０時間を超えて法定時間外労働をさせた場合には、その超  
えた時間について、\u0005割増以上の割増賃金を支払わなければならない。  
`
        },
        {
                "mondaiId": 103220200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
雇用調整に関する記述として、最も適切なものはどれか。  
  
ア  雇用調整のために希望退職を募集する場合には、平均賃金の３０日分以上の割  
増退職金を支払わなければならない。  
  
イ  雇用調整のために新規学卒者の内定取消しを行う場合には、公共職業安定所に  
届け出て許可を受けなければならない。  
  
ウ  雇用調整のために操業を短縮し、労働者を一時的に休業（一時帰休）させたとき  
は、公共職業安定所から助成金が支給されるが、支給要件や支給額は企業規模に  
よる区別はない。  
  
エ  雇用調整のために操業を短縮し、労働者を一時的に休業（一時帰休）させたとき  
は、その休業期間中、当該労働者に平均賃金の６０％以上の休業手当を支払わな  
ければならない。`
        },
        {
                "mondaiId": 103220210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
団体交渉に関する記述として、最も適切なものはどれか。  
  
ア  いわゆる争議団は労働組合ではないので、憲法上の団体交渉権の保護を受ける  
ことができない。  
  
イ  企業内組合との間で締結した労働協約に唯一交渉団体条項がある場合には、そ  
れを理由に合同労組からの団体交渉申入れを拒否することができる。  
  
ウ  使用者は、上部団体が交渉委員に加わることを理由に団体交渉を拒否すること  
はできない。  
  
エ  新規採用者の初任給の上げ下げの問題は、組合員の労働条件や待遇に関するも  
のではないから、義務的団体交渉事項には当たらない。  
`
        },
        {
                "mondaiId": 103220220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
就業規則の作成、変更に関する記述として、最も適切なものはどれか。  
  
ア  就業規則は、複数の事業場がある企業では、原則として、それぞれの事業場を  
管轄する行政官庁（労働基準監督署長）に届け出なければならないが、一定の要件  
を満たす場合には、本社を管轄する行政官庁に一括して届け出ることができる。  
  
イ  常時１０人以上の労働者を使用する使用者は就業規則を作成しなければならな  
いが、この場合の常時使用する労働者には、パートタイマーやアルバイト、嘱託  
社員などは含まない。  
  
ウ  パートタイマーを対象とする就業規則を作成したときは、その就業規則に、  
パートタイマーの過半数を代表する者の意見を記した意見書を添えて、行政官庁  
（労働基準監督署長）に届け出なければならない。  
  
エ  変更した就業規則は、労働者に周知されていなくても、労働者代表（過半数労  
働組合または過半数代表者）の意見を聴取し、行政官庁（労働基準監督署長）に届  
け出れば効力が発生する。  
`
        },
        {
                "mondaiId": 103220230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
地方銀行のA銀行は、リテール・バンキングの顧客基盤を全国規模に拡大する  
ために、インターネット・バンキングのシステム整備を他行に先駆けて完了した。  
次に、製品・ブランド開発やプロモーション計画に着手しなければならない。A銀  
行の今後の市場細分化（セグメンテーション）と標的市場設定（ターゲティング）に関  
する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  銀行の製品・サービスに対する需要の異質性は確実に存在するので、それらを  
うまく見いだして対応することができればブランド化の実現は十分可能である。  
  
イ  行動による細分化変数のひとつに購買決定に関する役割がある。それは、「発  
案者」、「影響者」、「決定者」、「購買者」、「使用者」の\u0002つに類型化される。  
  
ウ  市場細分化（セグメンテーション）と製品差別化はブランド化シナリオの中核に  
ある考え方で、両者はしばしば代替的な関係に置かれる。  
  
エ  市場細分化（セグメンテーション）を通じた競争は、競争相手に対して正面から  
挑戦していく性格をもつ。  
  
オ  デモグラフィクスによる細分化変数には、年齢、ライフステージ、性別、所  
得、社会階層などが含まれる。  
`
        },
        {
                "mondaiId": 103220240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
現代においては、価格政策が他のマーケティング・ミックス要素（小売業者の場  
合は小売ミックス要素）と統合的に作用することで、企業の競争優位性が左右され  
るようになっている。現代の価格政策に関する記述として、最も不適切なものはど  
れか。  
  
ア  小売業者がHi-Lo政策を導入し、フォワード・バイイングを行う場合、保管ス  
ペースが多く必要になったり、商品の鮮度が低下したりする。  
  
イ  小売業者によるEDLP政策は、キャンペーンなどによる一時的な値引きを行  
わず、日常的に低価格で商品を販売する方法である。  
  
ウ  情報化社会の進展に伴い、消費者のもつ製品知識水準が向上したため、価格の  
品質バロメーター機能が作用しにくい状況が目立つようになっている。  
  
エ  伝統的な価格設定方法のひとつにコスト・プラス法がある。この手法は、消費  
者の価格感度や製品市場での競争状況を価格設定に反映させている。  
  
オ  プレステージ（Prestige）商品では、価格が低下するといったん需要が増加する  
が、さらなる低価格化が進むと需要は減少することも多い。  
`
        },
        {
                "mondaiId": 103220250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
ある地方都市の、手作りの折りたたみ式マウンテンバイクを製造する、小さな町  
工場の社長Y氏は、高視聴率を誇るテレビのビジネス情報番組で自社の製品が紹  
介されたことがきっかけとなり、全国から対応しきれないほどの数の引き合いを受  
けるようになった。昨今の健康ブームも相まって、自転車通勤に切り替えたり、週  
末にサイクリングで名所めぐりをする消費者が全国的に増加していることもあり、  
Y氏はこの機会に自社で手作りする自転車を全国市場で販売することを決心した。  
その販売経路政策として、<u>最も不適切なものはどれか</u>。ちなみにY氏の町工場が  
生産する自転車の価格帯は、１２万円から２０万円程度であり、テレビ報道を機に商  
標名の認知度も高まってきている。  
  
ア  大手自転車メーカーと販売に関する提携をして、適切な小売店に納入してい  
く。  
  
イ  各々の地域市場において有力な自動車ディーラーの営業担当者を介した販売を  
進めていく。  
  
ウ  自社製品の露出をできる限り高めるために、開放型チャネル戦略を展開する。  
  
エ  全国市場でのテスト販売を兼ねて、まずは有力なインターネット・ショッピン  
グ・モールに出店し、売上の動向や収益性の判断を、相応の時間をかけて行う。  
  
オ  大都市圏のみに数店舗を構えるライフスタイル型の専門店小売企業に取引先を  
限定し、高性能・高級ブランド自転車として販売していく。  
`
        },
        {
                "mondaiId": 103220260,
                "name": "第26問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
インターネット上で各種カテゴリの製品に対する膨大な数の消費者からの評価や  
コメントを収集し、分析結果を販売するC社は、いち早く現代におけるマーケ  
ティング・コミュニケーションの変化に着目した企業のひとつとして知られてい  
る。C社の事業活動をとりまく、現代のマーケティング・コミュニケーション環境  
に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0002大マス媒体には、テレビ、新聞、雑誌、ラジオが挙げられるが、インター  
ネット広告は、すでにその広告費において、\u0002大マス媒体のうちの\u0003つを凌いで  
いる。  
  
イ  広告の効果は伝統的にAIDMA（注目→興味→欲求→記憶→購買）モデルに  
よって説明されてきたが、インターネットの普及にともなって、AISAS（注  
目→興味→検索→行動→共有）モデルの妥当性が提唱されるようになった。  
  
ウ  標的とするオーディエンスに対して長期的に、バランスがよく、測定可能で、  
説得力の高いブランド・コミュニケーション・プログラムを計画、開発、実施  
し、さらにそれを評価するために用いる戦略的なビジネス・プロセスのことを統  
合マーケティング・コミュニケーションと呼ぶ。  
  
エ  プロモーション・ミックスとは、メッセージの送り手によってコントロールさ  
れるマーケティング・コミュニケーション要素のことであり、広告、販売促進、  
PR、対面販売などが含まれる。  
  
オ  マーケティング・コミュニケーションの主な役割のひとつは、同一カテゴリの  
製品・サービスの中から特定のものを購入してもらえるよう消費者（ないし最終  
使用者）に対するプッシュ活動を遂行することである。  
`
        },
        {
                "mondaiId": 103220271,
                "name": "第27問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
次の文章を読んで、下記の設問に答えよ。  
消費者行動論の領域では、購買プロセスは、消費者が内部および外部からの刺激  
によって問題やニーズを認識した時に始まるとされている。こうした視点から消費  
者の購買行動をとらえると、消費者は一回一回の購買において、「問題認識」→「情  
報探索」→「代替案の評価」→「選択」→「結果の評価」からなる\u0002段階の意思決定プ  
ロセスのすべて（または一部）を経由することになる。  
（設問２））  
情報探索段階において消費者は、次のような情報源に依拠した探索行動を行  
う。空欄ＡとＢにあてはまる語句の組み合わせとして最も適切なものを下記の解  
答群から選べ。  
商業的情報源―広告、ウェブサイト、販売員、商品パッケージ、店頭の実  
物展示など  
Ａ情報源―マスメディア、製品評価を行う消費者団体など  
個人的情報源―家族、友人、隣人、職場の同僚、知人など  
Ｂ情報源―製品の操作、検討、使用など  
〔解答群〕  
  
ア  Ａ：公共的Ｂ：経験的  
  
イ  Ａ：社会的Ｂ：行動的  
  
ウ  Ａ：社会的Ｂ：実物的  
  
エ  Ａ：操作的Ｂ：行動的  
  
オ  Ａ：操作的Ｂ：能動的  
`
        },
        {
                "mondaiId": 103220272,
                "name": "第27問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
消費者の購買意思決定に関する理論の諸仮説の記述として、最も不適切なもの  
はどれか。  
  
ア  消費者は聞いたことのない小売業者のウェブサイトから製品を購買する際、  
しばしば意思決定の内容を変更したり、延期したりすることがあるが、その一  
因は、さまざまな知覚リスクを回避しようという行動にあるといえる。  
  
イ  消費者は購買意思決定プロセスの中で、完全情報の獲得に基づいて行動する  
わけではなく、情報処理や意思決定は経験則、つまりヒューリスティクスに左  
右されることがある。  
  
ウ  精緻化見込みモデルによると、十分な「動機」、「能力」、「機会」をもたない消  
費者は、製品・サービスやブランドに対して、あまり深い考察を伴わずに態度  
を形成したり、変容させたりする。  
  
エ  低関与製品を高関与製品に転換するためのひとつの手法は、製品を関与度の  
高い問題と関連付けることである。  
  
オ  バラエティ・シーキングとは、低関与製品のうち、ブランド間の差異が小さ  
い場合にみられる頻繁なブランド・スイッチングのことをいう。  
`
        },
        {
                "mondaiId": 103220281,
                "name": "第28問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
次の文章を読んで、下記の設問に答えよ。  
マーケティングは、極めて実践的な概念であるため、マーケティングの活動主体  
となる組織・機関の範囲や、それらを取り巻く環境の変化を受け、これまでにもそ  
の定義がしばしば改定されてきた。アメリカ・マーケティング協会による近年の定  
義として、以下２００４年のものと２００７年のものが挙げられる。  
（２００４年の定義）  
「マーケティングとは、顧客価値を創造・伝達・提供し、組織とその  
Ａの双方を利する形で顧客との関係性を管理するための組織機能と一  
連のプロセスのことを指す」  
（２００７年の定義）  
「マーケティングとは、顧客やクライアント、パートナー、さらには広く  
Ｂ一般にとって価値のあるオファリングスを創造・伝達・提供・交換  
するための活動とそれに関わる組織・機関、および一連のプロセスのことを指  
す」  
（設問1））  
文中の空欄Ａにあてはまる最も適切なものはどれか。  
  
ア  エンプロイー  
  
イ  カスタマー・リレーションシップ  
  
ウ  ステークホルダー  
  
エ  セールス・エージェント  
  
オ  マーチャント・ホールセラー  
`
        },
        {
                "mondaiId": 103220282,
                "name": "第28問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄Ｂにあてはまる最も適切なものはどれか。  
  
ア  環境  
  
イ  国民経済  
  
ウ  社会  
  
エ  組織・個人  
  
オ  文化`
        },
        {
                "mondaiId": 103220283,
                "name": "第28問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
以下のア～エは、２００４年の定義と比べたとき、２００７年の定義にはどのような  
特徴があるかを記述したものである。このうち、<u>最も不適切なものはどれか</u>。  
  
ア  ２００７年の定義に含まれる「交換」という言葉は、単発的な取引を通じて、組  
織や機関が収益をあげることを意味する。  
  
イ  ２００７年の定義は、「透明性」・「より広い参加者」・「継続性」の\u0003つの観点の  
重要性を示唆している。  
  
ウ  ２００７年の定義は、持続可能性の視点を、より明確に示したものである。  
  
エ  ２００７年の定義は、マーケティングを「組織・機関の中の一部門による機能」  
ではなく、「より広い組織・機関全体の活動」として位置付けている。  
`
        },
        {
                "mondaiId": 103220291,
                "name": "第29問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
次の文章を読んで、下記の設問に答えよ。  
新興のデザイン家電メーカーX社は、パソコンの基幹部品の標準化・汎用化が  
高度に進み、消費者のパソコン使用経験が深まっている現状を潜在機会としてとら  
え、革新的な製品によるパソコン市場への参入を計画している。ただし、パソコン  
市場を概観すると製品ライフサイクルの成熟化段階に達しているため、同社の新製  
品開発に先駆けたマーケティング計画の策定過程では、構想力に満ちた魅力的な製  
品コンセプト・アイデアの創出に加え、  
\u0002  
消費者の知覚の立場からの市場分析が重要  
な課題となる。そこで同社は、社内の関連部署のスタッフによって構成されるブラ  
ンド・チームをつくり、  
\u0003  
製品（ブランド）ポジショニングに関する検討を行ってい  
る。  
（設問1））  
文中の下線部\u0002に関する以下の文章の空欄Ａ～Ｃにあてはまる語句の組み合わ  
せとして最も適切なものを下記の解答群から選べ。  
知覚品質とは、消費者がＡにとらえる品質のことである。消費者が高  
品質を知覚する製品は、その品質連想によって価格Ｂが生み出されるた  
め、その価格を下げたとしても需要数量に大きな変化が生じないことがある。こ  
のことは同製品の需要の価格Ｃが小さい状況を意味する。また、同種製  
品市場で事業を展開する他社が類似製品の値下げを行ったとしても、同製品の需  
要数量に大きな変化が生じないことがある。これは、同製品の交差（交叉）  
Ｃの低さを示しているといえる。  
〔解答群〕  
  
ア  Ａ：社会的Ｂ：エクイティＣ：弾力性  
  
イ  Ａ：主観的Ｂ：ノベルティＣ：有効性  
  
ウ  Ａ：主観的Ｂ：プレミアムＣ：弾力性  
  
エ  Ａ：主体的Ｂ：レバレッジＣ：有効性  
  
オ  Ａ：能動的Ｂ：レバレッジＣ：差別性  
`
        },
        {
                "mondaiId": 103220292,
                "name": "第29問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002に関する以下の文章の空欄Ａ～Ｄにあてはまる語句の組み合わ  
せとして最も適切なものを下記の解答群から選べ。  
市場が成熟し、ひとつの製品市場に多くのブランドが登場するようになると、  
競争関係にあるブランドの製品とのＡを保ちながら、多くの消費者の  
Ｂに近接したポジションを見出すことは難しくなる。つまり、有効な  
Ｃを行うことのできるブランド・ポジショニングが、ますます困難にな  
るということである。このような状況を打開するためには、Ｄの活用が  
必要になってくる。  
〔解答群〕  
  
ア  Ａ：カテゴリ同調性Ｂ：カテゴリ認識点  
Ｃ：市場細分化Ｄ：製品差別化  
  
イ  Ａ：関連Ｂ：欲求充足点  
Ｃ：製品差別化Ｄ：ライフスタイ��・セグメンテーション  
  
ウ  Ａ：競合性Ｂ：関与増加点  
Ｃ：顧客指向化Ｄ：機動的な営業力  
  
エ  Ａ：距離Ｂ：欲求理想点  
Ｃ：製品差別化Ｄ：市場細分化  
  
オ  Ａ：類似性Ｂ：カテゴリ認識点  
Ｃ：市場細分化Ｄ：異質需要に関する知識  
`
        },
        {
                "mondaiId": 103220293,
                "name": "第29問設問3",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
X社はパソコン市場への参入に際して、自社の資源・能力の特異性であるデザ  
  
イ  ン創造力を考慮し、パソコンの「本体のサイズ」と「マルチメディア対応度」をも  
とに消費者の知覚マップを作成した。他のメーカーの製品導入状況を分析しなが  
ら、自社製品のポジショニングの検討を行っている。この段階で用いられている  
資料は下図のとおりである。この図をもとにX社が計画している新製品開発と  
そのポジショニングについての記述として、最も不適切なものを下記の解答群か  
ら選べ。  
なお、図中の小さな四角（■）は消費者の選好分布を、白抜きの大きな四角（\u0002）  
は、他のメーカーによる既存製品のポジショニングを示している。X社が開発を  
検討している製品が想定するポジションは図中の大きな丸印（〇）によって表示さ  
れている。図中の縦軸はマルチメディア対応度の高低を、横軸は本体サイズ（本  
体を設置した時の容積）の大小を指している。  
        
〔解答群〕  
  
ア  X社が開発を検討している製品はインテリア性が高く、家庭のリビング  
ルームに設置し、テレビの地上デジタル放送の視聴や音楽ファイルの再生、  
電子マネーをつかったインターネット・ショッピングが可能なだけでなく、  
仕事や勉強にも快適に活用できるスペックを備えている。  
  
イ  X社のポジショニング計画は、いわゆるブルー・オーシャン（Blue  
Ocean）戦略としてとらえられるが、その市場成果はここでのブランド化に  
よって吸収可能な消費者の数に依存している。  
  
ウ  X社は、種々の汎用部品を用いてコンパクトでスタイリッシュなシェル  
（筺体）仕様にすることにしたが、その際の大幅な費用増を相殺するために、  
パソコン用液晶ディスプレイ・メーカーと長期契約を交わし、特定モデルの  
液晶部品を割引価格で調達しようとしている。  
  
エ  X社は競争相手による対応が行われていない未開拓のニーズに着目し、多  
機能がコンパクトにビルトインされた小型製品を開発し、象限\u0002の標的市場  
での早期の製品普及後、象限\u0003の大規模市場からの遷移顧客の獲得を企図し  
ている。  
  
オ  マップ上の象限\u0003に位置する企業は象限\u0002にも参入することが可能であ  
る。したがって、X社には先発の利益を獲得・維持するための工夫が必要と  
される。  
`
        },
        {
                "mondaiId": 103220300,
                "name": "第30問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
明治４０年から続く豆腐製造業を継いだA氏は、豆腐市場における価格競争の激  
化や原料価格の高騰によって、ここ数年の間、減収減益に悩まされており、A氏の  
豆腐工場は、今や廃業の危機に直面している。そこでA氏は既存の製品分野であ  
る豆腐製品を脱コモディティ化させるとともに、自社の資源を有効に活用できそう  
な新たな製品分野として手作りドーナツの製造に目をつけ、事業の再建を目指して  
いる。現在、A氏は、自社製品のブランド化とそのための資金調達に向けて、日夜  
奔走している。  
以下はA氏が計画しているブランド構想についてのシナリオの一部であり、融  
資を相談している金融機関に対する説得材料でもある。このうち、最も不適切なも  
のはどれか。  
  
ア  A氏は、取引先の有力チェーン小売企業の棚の好位置により多くの新製品の  
フェイス獲得を図るため、その交換条件として同チェーンの安価なPB商品のた  
めの専用生産ライン増設への投資を検討している。  
  
イ  A氏は製品による差別化だけでなく、自社製品の販売経路（チャネル）や営業手  
法による差別化機会を検討しているが、経験価値が重要となるドーナツの製造販  
売ではフランチャイズ方式による小売店舗網の構築を計画している。  
  
ウ  持続的な競争優位を確立するためには、自社の内部だけでなく、外部の環境を  
しっかり把握しないといけないが、後者を分析するにあたっては競合に加え、異  
業種の市場の観察から市場創造の構想を練ることが欠かせない。  
  
エ  消費者が、自社の製品の属性を識別し、明確に差別化されたものであるという  
知覚イメージをもつことがブランド化の要であるから、商品説明のためのコミュ  
ニケーションの基本として産業財の営業を参考にしようと計画している。  
  
オ  豆腐の製造販売での新製品のブランド化を目指すにあたって、A氏は活気ある  
多くの商店街の空き店舗に簡易直営店を出店する計画を立てている。これは配送  
車両の積載率向上にもつながる。  
`
        },
        {
                "mondaiId": 104220010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産管理において、受注生産と見込生産の分岐点であるデカップリングポイント  
に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  顧客が注文をしてから製品を受け取るまでの期間を短縮できる。  
  
イ  在庫を置くべき適切な中間製品と、その在庫量を明確化する必要がある。  
  
ウ  最終製品の在庫を必要以上に持つことにより発生する、死蔵在庫（デッドス  
トック）を減少できる。  
  
エ  製品を作り始めてから完成するまでの製造期間を短くできる。`
        },
        {
                "mondaiId": 104220020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ロット生産に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0002つのロットに含まれる製品の個数をロットサイズと呼び、その数量を決定す  
る活動を、ロットサイジングと呼ぶ。  
  
イ  \u0002つのロットの中は、一般にすべて同じ品種で構成される。  
  
ウ  受注生産と見込生産の中間的な生産形態であり、断続生産とも呼ばれる。  
  
エ  ロットにまとめて生産することにより発生する在庫は、ロットサイズ在庫と呼  
ばれる。  
`
        },
        {
                "mondaiId": 104220030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
FMS（FlexibleManufacturingSystem）の一般的な特徴に関する次の記述の正誤  
について、最も適切なものの組み合わせを下記の解答群から選べ。  
ａ生産品種の多様化への対応が容易である。  
ｂ設備変更に対して柔軟性がある。  
ｃ生産内容の変更が容易である。  
〔解答群〕  
  
ア  ａ：正ｂ：正ｃ：正  
  
イ  ａ：正ｂ：誤ｃ：正  
  
ウ  ａ：誤ｂ：正ｃ：誤  
  
エ  ａ：誤ｂ：誤ｃ：誤`
        },
        {
                "mondaiId": 104220040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品開発・製品設計に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  インダストリアルデザインは、製品設計と製造・販売などの統合化、同時進行  
化を行う方法である。  
  
イ  デザインレビューは、新製品の設計のできばえを評価・確認する方法のひとつ  
である。  
  
ウ  フロントローディングは、製品開発・製品設計の段階で、製品の生産、販売、  
使用、廃棄などで発生する問題を見いだし、解決策を出し、製品の検討評価をす  
る方法である。  
  
エ  ベンチマーキングは、優れた製品を基準として、開発した製品の比較評価を行  
う方法である。  
`
        },
        {
                "mondaiId": 104220050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
VEの「機能」に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  基本機能は、必要機能と貴重機能に分類される。  
  
イ  使用機能は、基本機能と二次機能に分類される。  
  
ウ  製品の形や色彩などのデザイン的特徴に関わる機能を、貴重機能という。  
  
エ  製品やサービスの使用目的に関わる機能を、使用機能という。`
        },
        {
                "mondaiId": 104220060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工作機械に関する記述として、最も適切なものはどれか。  
  
ア  研削盤は、加工物に回転運動を与え、固定された砥石で研磨する機械である。  
  
イ  旋盤は、切削用バイトに回転運動を与え、加工物を固定して切削する機械であ  
る。  
  
ウ  フライス盤は、加工物に回転運動を与え、工具のフライスを送りながら切削す  
る機械である。  
  
エ  ボール盤は、ドリルを回転させながら、固定された加工物に穴をあける機械で  
ある。  
`
        },
        {
                "mondaiId": 104220070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の条件で運転されている仕分けコンベヤがある。このコンベヤの能力を  
１０％向上させる方策として最も適切なものを下記の解答群から選べ。ただし、選  
択肢に記されている以外の条件の変更はないものとする。  
【条件】  
\u0002仕分けコンベヤの速度５０m／分  
\u0003仕分けコンベヤ上の仕分け物の投入ピッチ\u0002ｍ  
〔解答群〕  
  
ア  コンベヤの速度を、４５m／分に変更する。  
  
イ  コンベヤの速度を、５５m／分に変更する。  
  
ウ  仕分け物の投入ピッチを、０．９mに変更する。  
  
エ  仕分け物の投入ピッチを、１．１mに変更する。`
        },
        {
                "mondaiId": 104220080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
サイクル時間５０において組立ラインのラインバランシングを行ったところ、  
ワークステーション数が\u0003となり、次表に示される各ワークステーションの作業時  
間が得られた。この工程編成における編成効率の値に最も近いものを下記の解答群  
から選べ。  
ワークステーション\u0002\u0004\u0005\u0006\u0003  
作業時間４６５０４７４６４６  
〔解答群〕  
  
ア  ０．９０  
  
イ  ０．９２  
  
ウ  ０．９４  
  
エ  ０．９６  
`
        },
        {
                "mondaiId": 104220090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工程管理方式に関する記述として、最も適切なものはどれか。  
  
ア  追番管理方式では、製作番号が異なれば同じ部品であっても違った部品として  
管理される。  
  
イ  常備品管理方式は、部品の調達リードタイムが長い場合に有効である。  
  
ウ  生産座席予約システムでは、完成品や仕掛品の現品管理が容易である。  
  
エ  製番管理方式は、受注見積りの時点で信頼できる納期を提示できる。`
        },
        {
                "mondaiId": 104220100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　２つの工程が直列に連結され、工程間の在庫容量が制約されている２段階直列型生産システムを考える。各工程の作業時間は変動しているが、上流工程の作業者は単位時間当たり平均６個を生産し、下流工程の作業者は単位時間当たり平均８個を生産する能力があるものとする。  
　システムの生産量は単位時間当たり平均４．８個であったとする。上流工程の作業者の稼働率に最も近いものはどれか。  

<hint hidden='true'>**
❌稼働率の公式で解答できる問題です。  
<imgTag>104220100_1.png</imgTag>  
**</hint>

ア  ６０％  
  
イ  ７０％  
  
ウ  ８０％  
  
エ  ９０％  
`
        },
        {
                "mondaiId": 104220110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
\u0002時間当たりの必要量を\u0003トン、\u0002回の運搬費を１０，０００円、\u0002トン\u0002時間当た  
りの保管費を１００円とするとき、運搬費と保管費の総和が、最も少ない運搬間隔は  
どれか。  
  
ア  ８時間  
  
イ  ９時間  
  
ウ  １０時間  
  
エ  １１時間`
        },
        {
                "mondaiId": 104220120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
統計的方法を用いた問題解決手順の「\u0002テーマの選定」、「\u0003現状把握」、「\u0004原因  
の想定」、「\u0005解析」に関する記述として、最も適切なものはどれか。  
  
ア  「\u0002テーマの選定」では、問題による影響度、緊急度、改善による効果の大きさ  
について実験計画法を用いてまとめ、重点を絞りテーマを決める。  
  
イ  「\u0003現状把握」では、チェックシートを利用したデータの採取、特性要因図によ  
る要因の把握が有効である。  
  
ウ  「\u0004原因の想定」では、問題のくせを参考にして、なぜそのようなことが起こり  
うるのかをグラフ・管理図にまとめる。  
  
エ  「\u0005解析」では、特性要因図にあげた要因のなかで寄与率の大きなものは何なの  
か、層別したデータの比較や散布図を用いて検討する。  
`
        },
        {
                "mondaiId": 104220130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
在庫管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  定期発注方式の発注間隔は、取引先との関係や生産計画のサイクル、さらに経  
済性などを考慮して決められる。  
  
イ  定期発注方式は、あらかじめ定められた発注間隔で、発注の都度、発注量を決  
めて発注する方式である。  
  
ウ  定量発注方式の発注点は、調達期間中の推定需要量と安全在庫量の和として求  
められる。  
  
エ  定量発注方式は、実在庫水準が発注点を下回った時点で一定量を発注する方式  
である。`
        },
        {
                "mondaiId": 104220140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
パレタイジングに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  交互列積付とは、奇数段と偶数段で荷物の配置を１８０度回転させて積み付ける  
方式である。  
  
イ  パレタイジングの目的は、数多くの品物を\u0002つの取り扱い単位にまとめること  
によって、輸送や保管の効率を高めることにある。  
  
ウ  パレットの積付パターンには、れんが積付、ブロック積付、ピンホイール積  
付、交互列積付などがある。  
  
エ  ブロック積付では、個々の商品が同じ位置に配置され、品物のかみ合いがない  
ため荷崩れを起こす可能性がある。  
`
        },
        {
                "mondaiId": 104220150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
製品工程分析に関する記述として、最も適切なものはどれか。  
  
ア  工程図記号における滞留は、原料、材料、部品または製品を、計画により貯え  
ている過程を表すものである。  
  
イ  工程図記号には、複数の要素工程を同時に表示するために基本図記号を組み合  
わせて用いる、複合記号がある。  
  
ウ  工程図記号は基本図記号と補助図記号に分類され、基本図記号には、加工、運  
搬、貯蔵、滞留、検査の\u0002つがある。  
  
エ  製品工程分析は、生産する作業者を中心に、原材料、部品などが製品化される  
過程を工程図記号で表して調査・分析する手法である。`
        },
        {
                "mondaiId": 104220160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
作業管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  作業管理は、作業方法の分析・改善によって標準作業と標準時間を設定して、  
この標準を維持する一連の活動である。  
  
イ  作業指導の\u0003つの方法であるOJT（OntheJobTraining）では、職場内の上司や  
先輩が部下や後輩に対して、作業に必要な知識・技能・問題解決能力を日常業務  
内で教育訓練する。  
  
ウ  作業指導を行うにあたっては、個々の作業に対する技能スキルを向上させるだ  
けでなく、複数の作業を担当できる作業者を養成することを考慮に入れなければ  
ならない。  
  
エ  製造現場においては、常に生産効率の向上を目指して、各作業者が独自の判断  
により標準作業に工夫を施して作業を実施することが好ましい。  
`
        },
        {
                "mondaiId": 104220170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章中の空欄に入る計算式として最も適切なものを、下記の解答群から選  
べ。  
Z社では月間N個の製品を製造しているが、その中の\u0002％が不良品として廃棄  
される。この不良について調査を行ったところ、新たに作業者を雇って手直しを行  
えば、すべてを良品にできることが分かった。  
以下の前提条件のもとで、製品の販売単価がp円、製造変動費単価がv円、不  
良品\u0003個当たりの廃棄費用がs円のとき、作業者の雇用が採算的に見合うために  
は、月間の人件費は、０．０５×N×（）円未満でなければならない。  
【前提条件】  
１．この製品に対する需要は十分に高く、良品となった製品はすべて販売するこ  
とができる。  
２．不良品の手直しを行う場合には、作業者の人件費だけが追加費用として発生  
し、例えば材料費等の人件費以外の追加費用は発生しない。  
〔解答群〕  
  
ア  p＋s  
  
イ  p－v＋s  
  
ウ  v  
  
エ  v＋s  
`
        },
        {
                "mondaiId": 104220180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
下図に示すようなユニットa、b、cから成る\u0002つのシステム\u0003、\u0004、\u0002を考え  
る。それぞれのシステムの信頼度（与えられた条件のもとで、ある時間内に要求機  
能を実行できる確率）R\u0003、R\u0004、R\u0002の大小を表す関係式として最も適切なものを  
下記の解答群から選べ。  
ただし、ユニットa、b、cの信頼度はすべてα（\u0005＜α＜\u0003）で等しいものとす  
る。  
システム\u0003：信頼度R\u0003  
システム\u0004：信頼度R\u0004  
システム\u0002：信頼度R\u0002  
〔解答群〕  
  
ア  R\u0003＞R\u0004＞R\u0002  
  
イ  R\u0004＞R\u0002＞R\u0003  
  
ウ  R\u0004＞R\u0003＞R\u0002  
  
エ  R\u0002＞R\u0004＞R\u0003  
`
        },
        {
                "mondaiId": 104220190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
作業改善に関する記述として、動作経済の原則に照らして、最も不適切なものは  
どれか。  
  
ア  組立工程の生産性を高めるために、合理的な組立順序を検討した上で、その順  
序が実現できるように部品を配置した。  
  
イ  作業者の手の動きを効率化するために、移動箇所を直線で結んだ軌跡で手を動  
かすよう作業指導した。  
  
ウ  精密作業職場における作業者の疲労を軽減するために、照明の照射角度と照度  
を調整した。  
  
エ  部品供給が重力を利用して行えるように、部品棚の形状を変更した。`
        },
        {
                "mondaiId": 104220200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
人の健康や生態系に有害な恐れのある化学物質を適切に把握・管理するための  
PRTR（PollutantReleaseandTransferRegister）制度に関する記述として、最も不  
適切なものはどれか。  
  
ア  PRTR制度において国が行うべきことは、事業者からの届出データなどに基づ  
き、化学物質の排出量・移動量を集計・公表することである。  
  
イ  PRTR制度において事業者が行うべきことは、化学物質の排出量・移動量を自  
らが把握して国に届け出ることである。  
  
ウ  PRTR制度の対象となる化学物質は、PRTR法で定める対象化学物質の中の第  
一種指定化学物質に限られる。  
  
エ  PRTR制度は、従業員数が３００名を超える製造企業に限って適用される。  
`
        },
        {
                "mondaiId": 104220210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
都市計画法に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  市街化区域とは、すでに市街地を形成している区域及びおおむね１０年以内に  
優先的かつ計画的に市街化を図るべき区域をいう。  
  
イ  市街化調整区域とは、おおむね１０年後から段階的かつ計画的に市街化を図る  
べき区域をいう。  
  
ウ  都市計画区域は、一体の都市として総合的に整備し、開発し、及び保全する必  
要がある区域として、原則として都道府県が指定する。  
  
エ  都市計画法は、国土の均衡ある発展と公共の福祉の増進に寄与することを目的  
としている。`
        },
        {
                "mondaiId": 104220220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
店舗施設計画を策定する際、ユニバーサルデザインやバリアフリーに配慮するこ  
とは重要である。このうち、車いす利用者に対応した店舗施設計画に関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  エレベーターの乗降ロビーに設ける操作ボタンを床から１．０mの高さに設置  
した。  
  
イ  階段に代わるスロープの勾配を  
\u0002  
\u0003  
とした。  
  
ウ  自動車\u0002台当たりの駐車スペースを４．０m×６．０mとした。  
  
エ  トイレ内部の有効幅を１．０mとした。  
`
        },
        {
                "mondaiId": 104220230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
店舗施設の照明に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  間接照明は、光源を直接見えにくくして、柔らかな雰囲気を演出するものであ  
る。  
  
イ  グレアカット照明は、まぶしさを感じさせないように配慮をしたものである。  
  
ウ  装飾照明は、照度を高くして、より明るさを求めるものである。  
  
エ  ベース照明は、天井に付けられる照明で、店内全体を平均的に明るくするもの  
である。`
        },
        {
                "mondaiId": 104220240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
小売店舗における商品陳列に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  エンド陳列には、非計画購買を促進する効果がある。  
  
イ  ジャンブル陳列は、高付加価値商品の陳列に適している。  
  
ウ  フック陳列は、小型の文具などの陳列に適している。  
  
エ  ボックス陳列は、セーターなどの衣料品の陳列に利用されている。  
`
        },
        {
                "mondaiId": 104220250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
商品のフェイシングと陳列に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ある商品のフェイス数が増加すればするほど、その商品の売上高はフェイス数  
の増加に比例して増加する。  
  
イ  商品の販売量に比例させて陳列量を決定すると、発注や陳列などの作業や管理  
が効率的になる。  
  
ウ  水平陳列（ホリゾンタル陳列）は、機能や品質に差がある商品グループの陳列に  
効果的である。  
  
エ  的確なフェイシングを実現するためには、仮説と検証を繰り返しながらフェイ  
ス数を調整することが重要である。`
        },
        {
                "mondaiId": 104220260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
  
イ  ンストア・プロモーション（以下、「ISP」という。）に関する記述として、最も  
不適切なものはどれか。  
  
ア  ISPの手法は、対象商品の製品ライフサイクルによって変える必要がある。  
  
イ  価格主導型ISPには、値引き、バンドル販売、増量パックなどの手法があ  
る。  
  
ウ  価格弾力性が低い商品には、価格プロモーションが有効である。  
  
エ  クロスMD（マーチャンダイジング）は、非価格主導型のISPである。  
`
        },
        {
                "mondaiId": 104220270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
交差主義比率に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  粗利益率が変化しなければ、商品回転率が上がると、交差主義比率も上がる。  
  
イ  交差主義比率は基本的に高い方が良い。  
  
ウ  商品の交差主義比率を比較すると、店の売り上げに貢献している商品が分か  
る。  
  
エ  値入率が高いが交差主義比率が低い場合は、在庫の量が適正ではない可能��が  
ある。`
        },
        {
                "mondaiId": 104220280,
                "name": "第28問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
商品A、商品B、商品Cの現在の販売価格と値入高は次の通りである。商品A  
と商品Bと商品Cをセットで販売する場合、売価値入率を２０％にするためには、  
このセットをいくらで販売したらよいか。  
最も適切なものを下記の解答群から選べ。ただし、消費税は考慮しないものとす  
る。  
商品A商品B商品C  
販売価格１，２００円１，０００円８００円  
値入高５００円４００円３００円  
〔解答群〕  
  
ア  １，５６０円  
  
イ  １，７５０円  
  
ウ  ２，１６０円  
  
エ  ２，２５０円  
  
オ  ２，４６０円  
`
        },
        {
                "mondaiId": 104220290,
                "name": "第29問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
販売価格８００円、売価値入率２５％の商品を\u0002個破損した場合、\u0002個分の仕入高  
を補てんするためにはこの商品をいくつ販売する必要があるか。  
最も適切なものを選べ。ただし、消費税は考慮しないものとする。  
  
ア  \u0002個  
  
イ  \u0003個  
  
ウ  \u0004個  
  
エ  \u0005個  
  
オ  １０個`
        },
        {
                "mondaiId": 104220300,
                "name": "第30問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
物流ABC（ActivityBasedCosting）に関する記述として最も適切なものはどれ  
か。  
  
ア  物流ABCによるコストの算出においては、間接費の配賦を行わない。  
  
イ  物流ABCは、顧客別の物流サービス水準の適否の評価には活用できない。  
  
ウ  物流ABCを導入することにより、物流コストに影響する要因を明確化するこ  
とができる。  
  
エ  物流ABCを導入する場合、徹底して詳細なアクティビティの設定を行うべき  
である。  
`
        },
        {
                "mondaiId": 104220310,
                "name": "第31問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
CAO（ComputerAssistedOrdering）を活用した場合に期待される効果として最  
も適切なものはどれか。  
  
ア  計算された発注点に基づいた自動発注による適正在庫水準の維持  
  
イ  発注精度の向上による店舗における棚卸業務の削減  
  
ウ  発注頻度の削減による店舗における品出しの頻度削減  
  
エ  販売実績数量分の自動的な補充発注による品切れの防止`
        },
        {
                "mondaiId": 104220320,
                "name": "第32問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
わが国のチェーン小売業の専用物流センターに関する記述として最も適切なもの  
はどれか。  
  
ア  専用物流センターでは、小売店舗で販売されるすべての商品を在庫するのが一  
般的である。  
  
イ  専用物流センターでは、仕入高に対して一定率の物流センターフィーを徴収す  
る場合が多い。  
  
ウ  専用物流センターは、チェーン小売業が自ら運営することが一般的である。  
  
エ  専用物流センターを経由する取引では、各店舗へ配送するために実際かかった  
運賃を小売業が負担することが一般的である。  
`
        },
        {
                "mondaiId": 104220330,
                "name": "第33問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３３問  
サプライチェーン・マネジメントにおける投機戦略に関する記述として最も適切  
なものはどれか。  
  
ア  アパレル製品の縫製前の先染めを縫製後の後染めにする。  
  
イ  小売店舗の安全在庫を物流センターに集約する。  
  
ウ  顧客の注文を受けてからサプライヤーに発注する割合を従来よりも増やす。  
  
エ  品切れを防止するために従来よりも多めに在庫を持つ。`
        },
        {
                "mondaiId": 104220340,
                "name": "第34問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３４問  
サプライチェーン・マネジメントにおけるブルウィップ効果の抑制策として、最  
も不適切なものはどれか。  
  
ア  小売業の各店舗への納品を複数のサプライヤーによる共同配送に切り替えた。  
  
イ  サプライチェーンの各段階の事業者が顧客に納品する頻度を週に\u0002回から週に  
\u0003回に増した。  
  
ウ  チェーン小売業の専用物流センターの在庫をサプライヤーが管理して必要量を  
補充する方式に切り替えた。  
  
エ  メーカーが小売店舗における販売実績データを入手し、自社の生産計画に活用  
するようにした。  
`
        },
        {
                "mondaiId": 104220350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３５問  
わが国では、GS\u0002が規定した国際標準コードの採用など、新しい標準化の動き  
が始まっている。  
さて、日用品メーカーA社では、商品の販売促進の取り組みの\u0002つとして、増  
量キャンペーンの実施を考えている。このとき、商品アイテムコード（JANコー  
ド）の付番の仕方として、最も適切なものはどれか。  
  
ア  キャンペーン商品とそうでない商品を区別するために、前者を「非PLU型」の  
  
イ  ンストアマーキング用コードで対応する。  
  
イ  増量キャンペーンは、流通加工の\u0002つと認識されるので、「PLU型」のインス  
トアマーキング用コードで対応する。  
  
ウ  増量されることで商品属性に変更が生じるので、既存商品と区別するために、  
JANコードを変更する。  
  
エ  増量による価格変更がなく、期間限定であれば、従来のJANコードをそのま  
ま利用する。  
`
        },
        {
                "mondaiId": 104220360,
                "name": "第36問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３６問  
国際的な情報共有を進めていくために、GS\u0002が整備している取り組みに関する  
次の用語と説明文の組み合わせとして、最も不適切なものを下記の解答群から選  
べ。  
【用語】  
１EPC  
２GDS  
３GLN  
４GTIN  
【説明文】  
ａRFIDを用いて詳しい商品情報を共有する仕組み  
ｂ企業間で商品マスター情報を標準化し、共有する仕組み  
ｃ企業間取引において利用される国際標準の事業所コード  
ｄ商品を分類するために設定されたデータ項目  
〔解答群〕  
  
ア  \u0002とａ  
  
イ  \u0003とｂ  
  
ウ  \u0004とｃ  
  
エ  \u0005とｄ  
`
        },
        {
                "mondaiId": 104220370,
                "name": "第37問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３７問  
POSシステムから得られたレシートデータの分析手法の\u0002つである、いわゆる  
バスケット分析に関する記述として、最も適切なものはどれか。  
  
ア  バスケットに入れられた商品と、買い手の性別や年齢などの個人属性との相関  
関係を分析する手法のこと。  
  
イ  バスケットに入れられた商品と、販売日時の気温や近隣のイベント情報などと  
の相関関係を分析する手法のこと。  
  
ウ  バスケットに入れられた商品の組み合わせに注目し、ある商品と同時に購買さ  
れる確率の高い商品の組み合わせを明らかにする手法のこと。  
  
エ  バスケットに入れられた商品の情報をもとに、いわゆる「売れ筋」を明らかに  
し、品揃えのヒントを得るための手法のこと。  
`
        },
        {
                "mondaiId": 104220380,
                "name": "第38問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３８問  
入荷検品業務の簡素化・効率化を狙いとして、EDIと連動させた物流情報シス  
テムの流れに関する次の文中の空欄に最も適切なものの組み合わせを下記の解答群  
から選べ。  
まず、納入側は、品揃え、梱包などが終わった時点で、納品データ（事前出荷明  
細データ）を作成する。次に、商品を出荷する前に、納入側から小売側に納品デー  
タがEDIで伝送される。このことで、小売側は検収予定の商品を事前に把握でき  
るようになる。このとき、事前に伝送される出荷明細データをＡという。  
さらに、納入側は、納品用折りたたみコンテナなどに混載された商品を識別するた  
めにＢラベルを貼付する。小売側では、ＡとＢラベルと  
を照合することで、入荷検品作業の簡素化・効率化、納品書の削減を実現すること  
ができる。なお、出荷される商品が混載ではなく単品ケースの場合は、Ｃ  
シンボルが印刷された段ボールを用いることになり、Ｂラベルは不要であ  
る。  
〔解答群〕  
  
ア  Ａ：ASNＢ：BMSＣ：JAN  
  
イ  Ａ：ASNＢ：SCMＣ：ITF  
  
ウ  Ａ：SKUＢ：SCMＣ：JAN  
  
エ  Ａ：SKUＢ：BMSＣ：ITF  
`
        },
        {
                "mondaiId": 104220390,
                "name": "第39問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３９問  
食品のトレーサビリティシステムを構築する際に、記録対象となる情報には、ト  
レーサビリティ確保のうえで必須となる情報だけでなく、有効性やコストを勘案し  
たうえで付加的に記録する情報がある。このとき、以下に示す情報のうち、付加的  
に記録される情報、すなわちトレーサビリティを確立する上で、必須事項ではない  
ものはどれか。  
  
ア  受入時点の衛生状態や品質管理状態などのプロセスの履歴  
  
イ  原料と製品とを対応づける記録  
  
ウ  原料の識別単位とその仕入れ先とを対応づける記録  
  
エ  当該食品を取り扱った事業者、年月日、場所`
        },
        {
                "mondaiId": 104220400,
                "name": "第40問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４０問  
個人情報保護法に定める「個人情報」として、<u>最も不適切なものはどれか</u>。  
  
ア  営業担当者が保有している個人見込客リスト  
  
イ  記名方式のアンケートをもとに、回答だけを年齢別に集計した報告書  
  
ウ  取引先の契約担当者の氏名などの情報  
  
エ  パートやアルバイトに関する人事考課情報  
  
オ  名刺索引付きのファイルを用いて手作業で管理されている名刺  
`
        },
        {
                "mondaiId": 105220010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
甲は、株式会社を設立することとし、設立時発行株式は、発行価額\u0002万円で  
１，０００株を予定している。甲は、発起人として６００株を引き受ける予定であるが、  
残り４００株については募集設立の方式を使って募集することとし、申込期間を\u0003月  
\u0004日から\u0003月３０日までとして募集したところ、Ａ～Ｄの\u0005名から、以下のとお  
り、申込みがあった。  
申込日時引受希望株式数  
Ａ\u0003月\u0002日午前\u0006時２００株  
Ｂ\u0003月２１日午前１０時３００株  
Ｃ\u0003月２１日午後\u0007時４００株  
Ｄ\u0003月２８日午前１１時１００株  
甲は、引受人及びその引受株式数を決定することとしているが、募集に際し、決  
定方法は特に定めなかった。甲��しては、申込者の\u0005名の中では、今後の取引関係  
等を考慮すると、ＢとＤに引き受けてもらうのが最も望ましく、他の方法は、法律  
上やむを得ない場合��実施したいと考えている。かかる前提で、会社法の制約の範  
囲内で、甲の希望を最大限に実現する割り当て方法として最も適切なものはどれ  
か。  
  
ア  Ａ：０株Ｂ：３００株Ｃ：０株Ｄ：１００株  
  
イ  Ａ：８０株Ｂ：１２０株Ｃ：１６０株Ｄ：４０株  
  
ウ  Ａ：２００株Ｂ：８６株Ｃ：１１４株Ｄ：０株  
  
エ  Ａ：２００株Ｂ：２００株Ｃ：０株Ｄ：０株  
`
        },
        {
                "mondaiId": 105220020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたは、資本金３，０００万円で株式会社を設立しようとし  
ているあなたの友人から、設立する会社の組織をどうしたらよいかについて相談を  
受けた。あなたの友人の希望は以下のとおりであるが、それを前提に友人の希望に  
���う組織形態を、あなたがアドバイスするとすれば、最も適切なものはどれか。下  
記の解答群から選べ。  
【あなたの友人の希望内容】  
私を含めて株主は約１０名を予定しており、私が１，６００万円出資する予定であ  
る。会社の運営に当たっては、何でも株主総会で決議できるというのでは支障を  
来すので、株主総会で決議できる事項を制限し、代表取締役社長の決定で大部分  
の業務執行を行えるようにしたい。また、当初の役員の人数は必要最小限とした  
い。  
〔解答群〕  
  
ア  取締役\u0002名（うち代表取締役\u0002名）  
  
イ  取締役\u0003名（うち代表取締役\u0002名）取締役会  
  
ウ  取締役\u0004名（うち代表取締役\u0002名）監査役\u0002名会計参与\u0002名  
  
エ  取締役\u0004名（うち代表取締役\u0002名）取締役会監査役\u0002名  
`
        },
        {
                "mondaiId": 105220030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
破産手続、民事再生手続及び会社更生手続について述べた次の文章について、下  
線部\u0002～\u0003の説明のうち最も適切なものを下記の解答群から選べ。  
破産手続、民事再生手続及び会社更生手続の違いとしては、第一に、手続が目指  
す結果の違いが挙げられる。即ち、  
\u0002  
破産手続は、清算型と呼ばれ、法人・自然人を  
問わず破産者が破産手続開始決定時に保有する全ての資産を金銭に換価して配当に  
充てることになるが、民事再生手続、会社更生手続は、再建型と呼ばれ、それぞれ  
の手続に従って、債務者の再建を図りながら弁済を行うこととなる。  
第二に、対象となる人の違いが挙げられる。  
\u0004  
破産手続、民事再生手続は、法人・  
自然人を問わず全ての人に適用されるが、会社更生手続は、会社法上に規定がある  
会社にのみ適用され、それ以外の法人・自然人には適用されない。  
第三に、手続の主体の違いが挙げられる。  
\u0005  
破産手続、会社更生手続では、管財人  
が選任され、管財人が資産の管理処分等を行うが、民事再生手続では、管財人とい  
う制度が法律上存在しないため、債務者自身が主体となって手続を遂行することと  
なっている。  
第四に、抵当権等の担保権に関する基本的な取り扱いの違いが挙げられる。  
\u0003  
破産  
手続、民事再生手続は、担保権は別除権となり、担保権者は手続外で担保権を実行  
することが可能であるが、会社更生手続においては、担保権は更生担保権となり、  
手続外での実行は禁止される。  
〔解答群〕  
  
ア  下線部\u0002  
  
イ  下線部\u0004  
  
ウ  下線部\u0005  
  
エ  下線部\u0003  
`
        },
        {
                "mondaiId": 105220040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
甲株式会社（以下「甲社」という。）では、営業部門を会社分割の手続を利用して分  
社化することとしているが、その中で、従業員Ａ～Ｄの所属について、以下の対応  
を検討している。これら従業員のうち、「会社分割に伴う労働契約の承継等に関す  
る法律」第\u0002条第\u0003項に基づく通知が必要となる者の組み合わせとして最も適切な  
ものを下記の解答群から選べ。なお、分社化により新たに設立される会社を乙株式  
会社（以下「乙社」という。）とする。  
従業員Ａ：入社以来、営業部門に従事している者であるため、会社分割に際して  
も、乙社所属とする。  
従業員Ｂ：総務部門に従事する者であるが、乙社での総務担当者がおらず、従業員  
Ｂは過去に営業部門に関連する総務業務も担当していたことがあるた  
め、会社分割に際しては、乙社所属とする。  
従業員Ｃ：経理部門に従事し、営業部門に関連する経理も若干担当していたことは  
あるものの、会社分割に際しては、甲社所属とする。  
従業員Ｄ：一昨年の人事異動で、営業部門に異動となり、その後約\u0002年間その業務  
に従事していたが、適性の問題もあることから、会社分割に際しては、  
甲社所属とし、異動前の部署に戻す。  
〔解答群〕  
  
ア  Ａ、Ｂ、Ｃ  
  
イ  Ａ、Ｂ、Ｄ  
  
ウ  Ａ、Ｃ、Ｄ  
  
エ  Ｂ、Ｃ、Ｄ  
`
        },
        {
                "mondaiId": 105220050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたは、依頼者であるX有限会社（特例有限会社）の代  
表取締役である甲に対して、以下のアドバイスを行おうと考えている。このアドバ  
  
イ  ス案のうちで最も適切なものはどれか。  
なお、X有限会社は、資本金３００万円、株主は甲、甲の弟\u0002名、甲の子の計\u0003  
名、役員は、代表取締役の甲のほか、甲の弟\u0002名がそれぞれ取締役、甲の子が監査  
役に就任している。  
  
ア  御社の場合、取締役会を設置することはいつでもできますが、設置するメリッ  
トはないと思います。  
  
イ  増資を行う場合、誰に割り当てるかは代表取締役のあなたが自由に定めること  
ができますから、あなたに割り当てることにすれば、あなたの持株数を簡単に増  
やせます。  
  
ウ  特例有限会社から株式会社に組織を変更し、X株式会社という商号を使用する  
には、定款変更の手続をとって、商号を変更して、それを登記すれば足ります。  
  
エ  特例有限会社の取締役の任期は１０年までに制限されていますから、任期が満  
了するときにはまた取締役を選び、登記をしないといけません。  
`
        },
        {
                "mondaiId": 105220061,
                "name": "第6問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　会社を設立しようとしているあなたの友人甲と中小企業診断士であるあなたとの以下の会話を読んで、下記の設問に答えよ。なお、Ａ～Ｄの空欄には、同一語句は入らない。  

あなた：「それで設立する会社の種類はどうするのかい。」  

甲：「会社の種類ってなんだい。株式会社のことじゃないのかい。」  

あなた：「株式会社以外にも、（　Ａ<hint hidden='true'>**
合名会社
**</hint>　）、（　Ｂ<hint hidden='true'>**
合同会社
**</hint>　）、（　Ｃ　）を設立することができるんだよ。」  

甲：「へえ。どう違うんだい。」  

あなた：「会社法上では、出資者のことを社員というんだけど、その社員の責任の 内容が違うんだ。  
（　Ａ<hint hidden='true'>**
合名会社
**</hint>　）というのは、出資者全員が、無限責任社員といって、個人財産で限度なしに責任を負う会社で、逆に、（　Ｂ<hint hidden='true'>**
合同会社
**</hint>　）というのは、出資者全員が、有限責任社員といって、出資の範囲内でしか責任を負わない会社だよ。」  

甲：「へえ。そうすると、（　Ｃ　）というのは、なんだい。」  

あなた：「（　Ｃ　）は、無限責任社員と有限責任社員と両方の社員がいる会社だよ。」  

甲：「なるほどねえ。そういえば、ときどき（　Ｄ　）っていう名前も見るけどこれは会社じゃないのかい。」  

あなた：「それも会社だよ。でも、平成１７年に会社法という法律ができたりしたので、平成１８年５月からは設立することができなくなったんだ。」  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
持分会社（合同会社、合名会社、合資会社）と株式会社の比較表

<TABLE align="left" width="100%">
<TR>
<TD></TD>
<TD align='center' style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;' colspan="3">
持分会社
</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-top: 1px solid gray;border-left: 1px solid gray;'>
株式会社</TD>
</TR>

<TR>
<TD>
　</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合同会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合名会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合資会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>
</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者
</TD>
<TD align='center' colspan='3' style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >社員</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;'>株主</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
必要な出資者（社員または株主）の人数
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上 </hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>２人以上
（有限責任社員と無限責任社員が各１名以上）</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上  </hintTarget></TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者の責任
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>間接有限責任</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>無限責任（全ての責任）</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>有限責任社員と無限責任社員</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >間接有限責任（出資した範囲の責任）</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>
  
        
（設問１）  
会話中の空欄Ａ・Ｂに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア 　 Ａ：合資会社　Ｂ：合同会社  
  
イ 　 Ａ：合同会社　Ｂ：合資会社  
  
ウ  　Ａ：合名会社　Ｂ：合資会社  
  
エ  　Ａ：合名会社　Ｂ：合同会社<hint hidden='true'>**
←⭕
**</hint>  

`
        },
        {
                "mondaiId": 105220062,
                "name": "第6問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　会社を設立しようとしているあなたの友人甲と中小企業診断士であるあなたとの以下の会話を読んで、下記の設問に答えよ。なお、Ａ～Ｄの空欄には、同一語句は入らない。  

あなた：「それで設立する会社の種類はどうするのかい。」  

甲：「会社の種類ってなんだい。株式会社のことじゃないのかい。」  

あなた：「株式会社以外にも、（　Ａ　）、（　Ｂ　）、（　Ｃ<hint hidden='true'>**
合資会社
**</hint>　）を設立することができるんだよ。」  

甲：「へえ。どう違うんだい。」  

あなた：「会社法上では、出資者のことを社員というんだけど、その社員の責任の 内容が違うんだ。  
（　Ａ　）というのは、出資者全員が、無限責任社員といって、個人財産で限度なしに責任を負う会社で、逆に、（　Ｂ　）というのは、出資者全員が、有限責任社員といって、出資の範囲内でしか責任を負わない会社だよ。」  

甲：「へえ。そうすると、（　Ｃ<hint hidden='true'>**
合資会社
**</hint>　）というのは、なんだい。」  

あなた：「（　Ｃ<hint hidden='true'>**
合資会社
**</hint>　）は、無限責任社員と有限責任社員と両方の社員がいる会社だよ。」  

甲：「なるほどねえ。そういえば、ときどき（　Ｄ<hint hidden='true'>**
有限会社
**</hint>　）っていう名前も見るけどこれは会社じゃないのかい。」  

あなた：「それも会社だよ。でも、平成１７年に会社法という法律ができたりしたので、平成１８年５月からは設立することができなくなったんだ。」  
 

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
持分会社（合同会社、合名会社、合資会社）と株式会社の比較表

<TABLE align="left" width="100%">
<TR>
<TD></TD>
<TD align='center' style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;' colspan="3">
持分会社
</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-top: 1px solid gray;border-left: 1px solid gray;'>
株式会社</TD>
</TR>

<TR>
<TD>
　</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合同会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合名会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border: 1px solid gray;'>
合資会社</TD>
<TD style='background-color:#dddddd;color:#3880ff;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>
</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者
</TD>
<TD align='center' colspan='3' style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >社員</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;'>株主</TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
必要な出資者（社員または株主）の人数
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上 </hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>２人以上
（有限責任社員と無限責任社員が各１名以上）</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>１人以上  </hintTarget></TD>
</TR>

<TR>
<TD style='background-color:#ddddff;color:#3880ff;border: 1px solid gray;'>
出資者の責任
</TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>間接有限責任</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>無限責任（全ての責任）</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' ><hintTarget>有限責任社員と無限責任社員</hintTarget></TD>
<TD style='background-color:#ffffff;color:#3880ff;border: 1px solid gray;' >間接有限責任（出資した範囲の責任）</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>
  
                
（設問２）  
会話中の空欄Ｃ・Ｄに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  　Ｃ：合資会社　Ｄ：合名会社  
  
イ  　Ｃ：合資会社　Ｄ：有限会社<hint hidden='true'>**
←⭕
**</hint>    
  
ウ  　Ｃ：合同会社　Ｄ：合資会社  
  
エ  　Ｃ：合名会社　Ｄ：有限会社  
`
        },
        {
                "mondaiId": 105220071,
                "name": "第7問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたは、顧問先の会社の社長甲から、甲の子が勤務して  
いた会社が倒産したとして相談を受けた。甲の子が勤務していた会社の破産の概要  
及び甲の子が会社に対して有している債権の内容は以下のとおりである。そのうえ  
で、あなたと甲との会話を踏まえて下記の設問に答えよ。  
甲：「配当はあるのでしょうか。」  
あなた：「破産の場合、配当する順番が決まっているから、それに従うことになり  
ます。」  
甲：「具体的にはどうなるのですか。」  
あなた：「まず、破産財団から、Ａに対する配当を行います。これには破  
産管財人の費用その他破産財団の管理・換価及び配当に関する費用などが  
含まれます。Ａに全額配当してもまだ破産財団に余剰があるとい  
う場合にはＢに対する配当を行います。  
Ｂに全額配当してもまだ破産財団に余剰がある場合には、一般破  
産債権に対する配当が行われますが、通常は、全額弁済できないので、按  
分して配当されることになります。」  
甲：「そうすると、今回の場合どうなるのでしょう。」  
あなた：「仮に、現状を前提にして考えると、破産財団１，０００万円から、破産管財  
人の費用その他破産財団の管理・換価及び配当に関する費用２００万円がま  
ず支払われます。そして、残りの破産財団８００万円から、Ａに該  
当する税金や未払給料への配当など、先ほどお話した順番で配当がされま  
す。ですから、お子さんの場合、配当額は、Ｃということになり  
ます。ただし、配当額は、破産財団の管理等に要した費用などで大きく変  
動しますから、詳しくは破産管財人に問い合わせて下さい。また、未払給  
料については、独立行政法人労働者健康福祉機構で行っている未払賃金の  
立替払制度もありますから、こちらの利用も検討してもよろしいかと思い  
ます。」  
        
【破産した会社の概要】  
決算期毎年\u0002月\u0003日～\u0004月３１日  
破産手続開始決定日時平成２２年\u0003月\u0005日（水）午後\u0006時  
現在の破産財団約１，０００万円  
破産管財人の費用その他破産財団の管理・換価及び配当に関する費用（見込額）  
約２００万円  
税金の滞納分平成１９年分約１００万円  
平成２０年分約１５０万円  
平成２１年分約５００万円合計約７５０万円  
未払給料（甲の子を含む１０名分）  
平成２１年\u0002月～\u0005月分約５０万円  
平成２１年\u0007月～3月分約１００万円  
平成２１年１０月分～１２月分約３００万円合計約４５０万円  
【甲の子が有する債権の概要】  
平成２１年\u0007月～3月分の未払給料約１５万円  
平成２１年１０月～１２月分の未払給料約３０万円合計約４５万円  
（設問２））  
会話中の空欄Ａ・Ｂに入る語句の組み合わせとして最も適切なものはどれか。  
  
ア  Ａ：共益債権Ｂ：別除権  
  
イ  Ａ：財団債権Ｂ：優先的破産債権  
  
ウ  Ａ：別除権Ｂ：財団債権  
  
エ  Ａ：優先的破産債権Ｂ：共益債権  
`
        },
        {
                "mondaiId": 105220072,
                "name": "第7問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
会話中の空欄Ｃに入る文章として最も適切なものはどれか。  
  
ア  債権額全額の約４５万円  
  
イ  債権額約４５万円の\u0003分の\u0004の約\u0005万円  
  
ウ  平成２１年１０月から１２月分の未払給料全額にあたる約３０万円  
  
エ  平成２１年１０月から１２月分の未払給料約３０万円の\u0002分の\u0004の約１５万円  
`
        },
        {
                "mondaiId": 105220080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許法第３５条によれば、職務発明とは、従業者、法人の役員、国家公務員又は  
地方公務員（以下「従業者等」という。）がその性質上使用者、法人、国又は地方公共  
団体（以下「使用者等」という。）の業務範囲に属し、かつ、その発明をするに至った  
行為がその使用者等における従業者等の現在又は過去の職務に属する発明であると  
規定されている。次の記述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  菓子メーカーA社の従業者甲は、菓子を製造する装置に関する職務発明を完  
成させた。当該発明に関する特許を受ける権利は、勤務規則に従いA社に譲渡  
されたが、A社は、特許出願を行わなかった。甲は、A社が特許出願を行わな  
かったとしても、A社に対して特許法第３５条に規定される相当の対価の支払請  
求権を有する。  
  
イ  携帯電話メーカーB社の研究開発部門に所属していた従業者乙は、B社在職  
中に携帯電話に関する発明を完成させた後に、その内容を秘匿して退職した。そ  
の後、乙が当該発明について特許出願を行った場合、当該発明は、職務発明と認  
定される場合がある。  
  
ウ  自動車メーカーC社の経理部門に所属する従業者丙が、自動車用エンジンに  
関する発明を完成させた場合でも、丙の職務が自動車用エンジンに関する発明を  
行うものではないので、丙が完成させた発明は職務発明には該当しない。  
  
エ  筆記具メーカーD社の従業者丁は、筆記具に関する職務発明を完成させた。  
しかし、当該発明に関する特許を受ける権利がD社に譲渡されず丁が当該発明  
について特許を受けた場合、D社は、特許法第３５条に規定される相当の対価を  
丁に支払わなければ当該発明を実施することができない。  
`
        },
        {
                "mondaiId": 105220090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
商標登録出願に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  商標登録出願人は、二つ以上の商品又は役務を指定商品又は指定役務とする商  
標登録出願の一部を一つ又は二つ以上の新たな商標登録出願とすることができ、  
その新たな商標登録出願は、もとの商標登録出願の時にしたものとみなされる。  
  
イ  商標登録出願人は、自己の業務に係る商品又は役務について使用をする商標に  
ついて商標登録を受けることができる。団体商標にあってはその使用者は団体の  
構成員であるため、商標登録出願は団体の構成員の全員の名前により行う必要が  
ある。  
  
ウ  商標登録出願人は、登録料の納付と同時に、商標登録出願に係る区分の数を減  
ずる補正をすることができる。  
  
エ  商標登録出願は、商標ごとに、商標の使用をする一つのみの指定商品又は指定  
役務を指定して行うことができ、また、複数の商品又は役務の区分に所属する複  
数の指定商品又は指定役務を指定して行うこともできる。  
`
        },
        {
                "mondaiId": 105220100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
商標登録を受けることができる商標に関する記述として、最も適切なものはどれ  
か。  
  
ア  種苗法第１８条第\u0002項の規定による品種登録を受けた品種の名称と類似の商標  
であって、その品種の種苗に類似する商品について使用するものは、品種登録の  
期間が経過したときには、品種登録を受けたものに限って商標登録を受けること  
ができる。  
  
イ  商標登録出願の日前の商標登録出願に係る他人の登録商標と同一の商標であっ  
て、その商標登録に係る指定商品に類似する商品について使用するものは、その  
他人が当該商標を商標登録することに承諾している場合には、商標登録を受ける  
ことができる。  
  
ウ  商標登録出願の日前の商標登録出願に係る他人の登録商標に類似する商標で  
あって、その商標登録に係る指定商品に類似する商品について使用するものは、  
その商標登録出願後に他人の商標権が消滅して消滅後\u0003カ月を経過した後であれ  
ば、当該商標について商標登録を受けることができる。  
  
エ  他人の著名な芸名と同一の商標について商標登録出願をした場合には、その他  
人が当該商標の商標登録を承諾しているときには他人の人格権の保護が図られて  
いることから、商標登録を受けることができる。  
`
        },
        {
                "mondaiId": 105220110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
日本における知的財産に関する権利を有する者が、自己の権利を侵害すると認め  
る貨物が輸入されようとする場合に、税関長に対し、当該貨物の輸入を差し止める  
よう申し立てることが認められているが、必ずしも税関に対する輸入差止請求の対  
象とならない貨物として最も適切なものはどれか。  
  
ア  意匠登録されている意匠に係る物品を権利者に無断でアルゼンチンにおいて製  
造し、日本国向けに輸出された物品  
  
イ  特許発明に係る物品を権利者に無断でインドネシアにおいて製造し、日本国向  
けに輸出された物品  
  
ウ  日本国内での商標権者が、タイにおいても同一内容について商標登録を有して  
いる場合に、権利者からタイでの製造・販売について許諾を受けた者が製造し、  
権利者に無断で日本国向けに輸出した商品  
  
エ  日本国内で発売された音楽CD（コンパクトディスク）と同一内容の音楽CDを  
権利者に無断で米国において製造し、日本国向けに輸出された音楽CD  
`
        },
        {
                "mondaiId": 105220120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
ホテル業を営むA会社は、新しくホテルを建設することとし、B設計建築会社  
（以下「B会社」という。）との間で工事請負契約を締結した。予定どおり竣工し、A  
会社は、当該契約に基づいてこのホテルの引渡を受け営業を開始した。  
しかし、このホテルは、B会社から構造設計の委託を受けた一級建築士Cが、  
建築基準法令で定められた耐震強度を満たしたかのように偽装したものであった。  
なお、このホテルの建築に関し、D会社による確認審査、E会社による構造計算適  
合性判定においては、それぞれ建築基準関係規定に適合しているとされていた。  
その後、同地域を襲った地震により、このホテルの耐震強度偽装が発覚した。そ  
の結果、行政当局の指導を受け、このホテルを休業および補修し、A会社は多額の  
損害を被った。  
これらの状況を前提に、以下の選択肢ア～エのうち最も適切なものはどれか。  
  
ア  A会社は、B会社に請求する損害賠償とは関係なく、建築士Cに対してもB  
会社を債権者代位して契約上の義務に違反するとして損害賠償請求をすることが  
できる。  
  
イ  A会社は、偽装を見抜けなかったD会社・E会社に対しても、自己に生じた  
損害について無過失責任を追及することができる。  
  
ウ  A会社は、不完全履行があるとして、B会社に対して、補修に要した相当額の  
不当利得返還請求をすることができる。  
  
エ  A会社は、補修および休業したことにより生じた損害について、B会社に対  
し、債務不履行責任に基づく損害賠償請求をすることができる。  
`
        },
        {
                "mondaiId": 105220130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
日本において設立された株式会社甲はヤングカジュアル衣類を製造販売する会社  
であるが、このたび、上海に主たる営業所がある中国の会社である会社乙と、会社  
乙がデザイン・製造したカジュアルジャケットαについて売買契約を締結しようと  
している。  
以上の事情を前提に、株式会社甲の代表取締役社長と中小企業診断士をめざして  
いるあなたとの次の会話を読み、下線部\u0002～\u0003のあなたの回答のうち最も適切なも  
のを下記の解答群から選べ。  
社長：「今まで、わが社は国産にこだわってきたのだけど、最近、中国でも技術  
や質が上がってきているし、コストのメリットもあるしね。だから、今  
度、縁あって、新規に中国の会社乙と契約しようと思っているんだよ。」  
あなた：「それは、良かったですね。」  
社長：「この会社は外国企業との取引に慣れているようで、契約書を提示してき  
たんだ。中国語や英語は分からないといったら、契約書は日本語のものを  
持ってきてくれたよ。でも、いろいろな面で取引を慎重に進めなくてはな  
らないよね。どんなことに注意したらいいのかな。ぼくにとって海外取引  
は初めてだから、一般的なところから教えて欲しいんだ。」  
あなた：「契約内容については、欠けているところがないか弁護士に聞いた方がい  
いですよ。」  
社長：「そうだよね。でもね、たとえば、契約に規定していないことが起きた  
ら、どうなるの。」  
あなた「  
\u0002  
契約書には一般的に準拠法の定めがあるはずです。それがない場合に  
は、製品の買主側、すなわち、日本の法律が適用されることになります  
よ。」  
社長：「それと、価格の部分とかは後で決めるのでもいいのかなー。」  
あなた：「  
\u0004  
それでも良いと思います。けれども、契約書で何も規定していない場合  
は、近年、日本でも発効した国際物品売買契約に関する国際連合条約に  
従って、運送費は買主負担になるので、運送費の負担方法だけでも契約書  
        
で規定していた方がいいと思いますよ。」  
社長：「会社乙は営業所が大阪にあってね、ここで外国会社の支店の登録もし  
て、日本向け販売の窓口になっているんだ。ぼくたちはジャケットαに自  
社ブランドのロゴをつけて国内で販売する予定だけど、会社乙と日本で契  
約締結ということもできるのかなー。」  
あなた：「  
\u0002  
会社乙日本支店の日本における代表者の名の下に、会社乙と国内で契約  
締結することも可能ですよ。ただ、外国会社の支店といっても、本社から  
独立して法人格を有するわけではないので、結局、契約の相手方は会社乙  
ですけどね。」  
社長：「ところで、万が一、相手方と裁判になったら怖いよね。相手方を訴える  
ときは、中国でしないといけないのかなー。」  
あなた：「  
\u0003  
それは、裁判管轄の問題ですね。ただ、契約上の裁判管轄がどこであ  
れ、契約書が日本語であれば、日本で提訴することが可能ですよ。でも、  
強制執行するときは、結局、中国まで行かなくてはならないから、中国で  
提訴するのと一緒ですね。」  
〔解答群〕  
  
ア  下線部\u0004  
  
イ  下線部\u0005  
  
ウ  下線部\u0002  
  
エ  下線部\u0003  
`
        },
        {
                "mondaiId": 105220140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
株式会社Aは、一般消費者である女性をターゲットに各家庭を訪問して、ある  
いはインターネットにおける自社のショッピングサイト上で、高額化粧品をディス  
カウントして販売する業者である。この株式会社Aによる商品の販売に関する説  
明として最も適切なものはどれか。  
  
ア  株式会社Aがインターネットのショッピングサイト上で掲載している売買契  
約上、当該化粧品から生ずるいかなる肌のトラブルについても責任を負わない旨  
の規定がある場合には、当然に、当該契約全体が無効とな���。  
  
イ  株式会社Aによる商品のインターネット販売にはクーリング・オフ規定の適  
用はないが、この商品のショッピングサイト上に返品の可否および条件を記載し  
ていない場合、インターネットを通じてこの商品を購入した女性の都合により契  
約を解除されることがある。  
  
ウ  株式会社Aの担当者が訪問販売において、「重大な過失がある場合でも株式会  
社Aの損害賠償額は１０万円を限度とさせていただきます。」とする旨を女性に手  
渡しした売買契約書において規定し、女性がこれについて説明を受け、納得した  
上で署名押印した場合は、かかる規定は有効である。  
  
エ  株式会社Aの担当者が訪問販売において、女性から「商品が必要ないので、  
帰ってください。」と言われたにもかかわらず、居座って話を続けて説得した上で  
販売した商品は、この女性が契約書面を受領した日から起算して\u0002日間が経過す  
ると、女性から売買契約を取り消すことができない。  
`
        },
        {
                "mondaiId": 105220150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
以下の条項を読み、この条項が何について書かれたものか、最も不適切なものを  
下記の解答群から選べ。  
なお、この条項は、ロンドンに本社を有するXXX社が開発したソフトウェア  
（文中“Software”を意味する）を、XXX社がYYY社に対しライセンスを付与する  
（文中“License”を意味する）契約書中の\u0002つの条項であるとする。  
Article〇〇  
ThisLicenseAgreementconstitutestheentireagreementbetweenthe  
partieswithrespecttotheuseoftheSoftwarelicensedhereunderand  
supersedesallpriororcontemporaneousunderstandingsregardingsuchsubject  
matter．NoamendmenttoormodificationofthisLicenseAgreementwillbe  
bindingunlessexecutedbybothpartiesinwriting．Anytranslationofthis  
LicenseAgreementisdoneforlocalrequirementsandintheeventofadispute  
betweentheEnglishandanynon-Englishversions，theEnglishversionofthis  
LicenseAgreementshallgovern．  
〔解答群〕  
  
ア  XXX社とYYY社間の本契約締結前の合意は本契約に劣後する。  
  
イ  XXX社とYYY社両者の書面による合意なしには本契約は修正できない。  
  
ウ  英語以外の言語に翻訳された契約書と英語版の契約書の内容に矛盾がある場  
合は、英語版が優先する。  
  
エ  本契約の一部が無効となった場合でも、本契約の他の部分は効力を有する。  
`
        },
        {
                "mondaiId": 105220160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
X社の代表取締役甲の母親乙は、不動産等の資産を有しており、X社が自社工場  
建設などの事業資金を必要とした１０年前に、X社のY銀行からの１１億円の借り  
入れについて、乙所有の不動産に抵当権を設定して、物上保証人兼連帯保証人と  
なった。甲はこの借り入れについて、連帯保証人となっている。X社は１０年間は  
返済を毎月履行してきたが、最近、業績悪化のため返済が滞りがちである。  
これらの状況を前提に、以下の選択肢ア～エのうち最も適切なものはどれか。  
  
ア  Y銀行が、月々の返済について１１年目になって初めて乙に支払うよう請求し  
てきた場合、乙は自らの保証債務に関する消滅時効を援用して、Y銀行の請求を  
拒否することができる。  
  
イ  Y銀行から乙が支払わないと乙の不動産の競売をする旨の通知を受けた場合、  
乙は、X社の有する工場等の資産に対する執行を完了するまで、Y銀行の請求を  
拒絶することができる。  
  
ウ  Y銀行から請求を受けた際には、甲乙間で\u0002分の\u0003ずつ負担をする取り決めが  
甲と乙の話し合いによりなされている場合、乙はY銀行からの支払いの請求に  
対して\u0002分の\u0003の部分のみに応ずればよい。  
  
エ  Y銀行に対する支払債務を乙が履行する場合、乙が有する不動産を売却又は競  
売してその金員をもってY銀行に返済した上で、さらに債務の残額があるとき  
には、この残額も支払う義務がある。  
`
        },
        {
                "mondaiId": 105220170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
金融庁の公表している「金融検査マニュアル」によれば、金融機関に対して自己査  
定を行う体制の整備・確立を求めている。それによると、金融機関では、貸出金な  
どを債務者の信用リスクに応じて適切に管理するため、��務者を信用格付けやその  
他の状況等により正常先、要注意先、破綻懸念先、実質破綻先及び破綻先に区分  
（債務者区分）する。さらにそれぞれの債務者ごとの個別の債権の分類を行う。貸倒  
引当金の算定は、この債務者区分や債権の分類に基づき行われる。  
上記に関連した記述として、<u>最も不適切なものはどれか</u>。  
  
ア  債権の分類は、債権の資金使途等の内容を個別に検討し、担保や保証等の状況  
を勘案のうえ、債権の回収の危険性又は価値の毀損の危険性の度合いに応じて行  
うものとする。  
  
イ  債務者区分について、特に中小・零細企業等については、債務者の財務状況等  
のほか、当該企業の技術力、販売力や成長性などの情報や代表者等の収入状況や  
資産内容等を総合的に勘案するものとする。  
  
ウ  債務者区分の破綻懸念先の債務者は、さらに要管理先である債務者とそれ以外  
の債務者とを分けて管理することが望ましい。要管理先である債務者とは、金利  
減免などの貸出条件や、支払が延滞しているなどの履行状況に問題がある債務者  
をいう。  
  
エ  信用格付は、債務者の財務内容、格付機関による格付、信用調査機関の情報な  
どに基づき、債務者の信用リスクの程度に応じて行われる。信用格付は、債務者  
区分と整合的でなければならない。  
`
        },
        {
                "mondaiId": 105220181,
                "name": "第18問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
次の文章を読んで、下記の設問に答えよ。  
金融商品取引法により、上場会社は財務報告に係る内部統制報告書の提出が義務  
づけられている。  
企業会計審議会が公表した「財務報告に係る内部統制の評価及び監査の基準」（以  
下「本基準」という。）によれば、内部統制とは、基本的に、企業の\u0002つの目的の達成  
のために企業内のすべての者によって遂行されるプロセスであり、\u0003つの基本的要  
素から構成されると定義されている。この\u0002つの目的のうち、「財務報告の信頼性」  
を確保するための内部統制を「財務報告に係る内部統制」と定義し、この有効性につ  
いて経営者による評価及び公認会計士等による監査についての考え方を示してい  
る。  
中小企業においては、上場会社でない限りこのような基準の適用はないが、規模  
がある程度以上の会社になると、健全な会社経営のために、会社が営む事業の規  
模・特性等に応じた内部統制を整備することが求められている。  
（設問\u0004）  
本基準で示している内部統制によって企業が達成すべき\u0002つの目的のうち、  
「財務報告の信頼性」以外の目的として、<u>最も不適切なものはどれか</u>。  
  
ア  企業統治体制の確立  
  
イ  業務の有効性及び効率性  
  
ウ  事業活動に関わる法令等の遵守  
  
エ  資産の保全  
`
        },
        {
                "mondaiId": 105220182,
                "name": "第18問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
経営者は、内部統制の目的を達成するために内部統制の基本的要素が組み込ま  
れたプロセスを構築し、それを適切に機能させていくことが求められている。本  
基準では\u0003つの基本的要素（\u0002統制環境、\u0003リスクの評価と対応、\u0004統制活動、  
\u0005情報と伝達、\u0006モニタリング、\u0007ITへの対応）を列挙している。  
このうち、経営者の意向および姿勢のように、組織内のすべての者の統制に対  
する意識に影響を与えるとともに、他の基本的要素の基礎をなすものとして最も  
適切なものはどれか。  
  
ア  統制活動  
  
イ  統制環境  
  
ウ  モニタリング  
  
エ  リスクの評価と対応  
`
        },
        {
                "mondaiId": 105220190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
業績が悪化した会社の再建のため、債権者がその債権を債務会社の株式に振り替  
えることがある。このような、会社に対する金銭債権を現物出資し株式を発行する  
手法を指す名称（略称）として最も適切なものはどれか。  
  
ア  ADR（AlternativeDisputeResolution）  
  
イ  DDS（DebtDebtSwap）  
  
ウ  DES（DebtEquitySwap）  
  
エ  DIP（DebtorInPossession）`
        },
        {
                "mondaiId": 105220200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
会社法における株式会社の剰余金の配当規定に関連する説明として、最も不適切  
なものはどれか。なお、本問における株式会社は、取締役会設置会社であるが会計  
監査人設置会社ではないものとする。  
  
ア  株式会社の純資産額が３００万円を下回らない限り、株主総会の決議によってい  
つでも剰余金の配当をすることができる。  
  
イ  株主総会の決議によって、配当財産を金銭以外の財産とする現物配当をするこ  
とができる。ただし、当該株式会社の株式等を配当財産とすることはできない。  
  
ウ  事業年度の一定の日を臨時決算日と定め、臨時計算書類を作成して取締役会お  
よび株主総会で承認を受けた場合は、臨時決算日までの損益も分配可能額に含ま  
れる。  
  
エ  定款で定めることにより一事業年度の途中において何回でも取締役会の決議に  
よって中間配当をすることができる。ただし、配当財産は金銭に限られる。  
`
        },
        {
                "mondaiId": 106220010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
パーソナルコンピュータ（PC）には様々な半導体を利用した記憶装置が使用されているが、業務に適したものを選択しなければならない。したがって、その特性を理解しておく必要がある。  

次のａ～ｄの記述と半導体を利用した記憶装置の組み合わせとして、最も適切なものを下記の解答群から選べ。  

ａ主記憶装置に利用され、高速に読み書きができるが、記憶保持のためにはリフレッシュ操作が必要で、電源を切ると内容は消去される。  

<hint hidden='true'>**
SDRAMです。
**</hint>  

ｂBIOSなどのデータを工場出荷時に書き込み、PCでは読み込み専用で使用するもので、電源を切っても内容は保持される。  

<hint hidden='true'>**
マスクROMです。
**</hint>  

ｃ書き換えが可能で、デジタルカメラからPCへのデータ移動にも使用される。他の半導体記憶装置と比べると書き換えできる回数が少ないが、電源を切っても内容は保持される。  

<hint hidden='true'>**
フラッシュメモリです。
**</hint>  

ｄ画面に描画するRGBの輝度データを記憶させるもので、電源を切ると内容は消去される。  
〔解答群〕  
  
ア  ａ：SDRAMｂ：マスクROM  
ｃ：フラッシュメモリｄ：VRAM<hint hidden='true'>**
←⭕
**</hint>    
  
イ  ａ：VRAMｂ：フラッシュメモリ  
ｃ：SDRAMｄ：マスクROM  
  
ウ  ａ：フラッシュメモリｂ：VRAM  
ｃ：マスクROMｄ：SDRAM  
  
エ  ａ：マスクROMｂ：SDRAM  
ｃ：VRAMｄ：フラッシュメモリ  
`
        },
        {
                "mondaiId": 106220020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
PCの処理能力は様々である。その中から業務に適した能力のPCを選択しなけ  
ればならない。PCの処理能力に関する次の文中の空欄Ａ～Ｅに入る語句の組み合  
わせとして、最も適切なものを下記の解答群から選べ。  
PCの処理能力はCPUの演算速度によって変化する。CPUの動作クロック周波  
数がＡのものに比べＢで動作するものは演算速度が速い。PCに  
使用していたCPUを、動作クロック周波数がＣものに取り替えると処理  
能力は高くなる。  
CPUとメモリや周辺機器の間ではデータのやり取りがＤを通じて行わ  
れる。Ｄによるデータ伝送の幅はＥで表現され、数値が大きいほ  
どPCの処理能力は向上する。  
〔解答群〕  
  
ア  Ａ：\u0002GHzＢ：８００MHzＣ：低い  
Ｄ：キャッシュＥ：bps  
  
イ  Ａ：\u0002μsＢ：８００msＣ：低い  
Ｄ：キャッシュＥ：ビット数  
  
ウ  Ａ：８００MHzＢ：\u0002GHzＣ：高い  
Ｄ：バスＥ：ビット数  
  
エ  Ａ：８００msＢ：\u0002μsＣ：高い  
Ｄ：バスＥ：bps  
`
        },
        {
                "mondaiId": 106220030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータにはオペレーティングシステ���（OS）が組み込まれ、その上で各種  
のアプリケーションソフトウェア等が稼働している。このOSの機能に関する記述  
として最も適切なものはどれか。  
  
ア  キーボードからの入力、プリンタへの印字出力、外部記憶装置に対する読み書  
きなど、入出力デバイスの管理を行う。  
  
イ  プログラム実行中にキャッシュメモリの記憶容量を超える状態が発生しても、  
プログラムの実行が中断しないように、仮想記憶の仕組みを提供する。  
  
ウ  文字コードにはJIS、シフトJIS、EUCなど、体系の異なるものが存在する  
が、OSはこれらを自動判別し、常に正しい画面表示を行う機能を有する。  
  
エ  利用資格を持った特定のユーザがWebサイトを閲覧できるように、ユーザID  
とパスワードによる管理を提供する。  
`
        },
        {
                "mondaiId": 106220040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
オープンソースソフトウェアにも業務に利用できるものがあり、近年は選択の幅  
も広がっている。このオープンソースソフトウェアに関する記述として最も適切な  
ものはどれか。  
  
ア  OSは、各々のハードウェアに依存して開発されているので、オープンソース  
ソフトウェアは提供されていない。  
  
イ  オープンソースソフトウェアはフリーウェアを意味し、作成者が著作権を放棄  
したソフトウェアである。  
  
ウ  オープンソースソフトウェアはプログラムのソースコードも入手可能で、その  
プログラムを改良して使用したり、改良したプログラムを再配布することも自由  
に行える。  
  
エ  文書作成、表計算あるいはプレゼンテーションのためのソフトウェアは、その  
動作が各々のOSに依存するので、オープンソースソフトウェアは提供されてい  
ない。`
        },
        {
                "mondaiId": 106220050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
事業所では多くの文書ファイルが保存・管理されている。そのような文書ファイ  
ルの中から「A株式会社」という会社名に加えて「請求書」という文字列が含まれ、さ  
らに、「１００円」または「２００円」の文字列の一方あるいは両方が含まれる文書を探し  
たい。この検索条件を論理式で表現したとき最も適切なものはどれか。  
  
ア  （A株式会社and請求書）and（１００円or２００円）  
  
イ  （A株式会社and請求書）or（１００円or２００円）  
  
ウ  （A株式会社or請求書）and（１００円or２００円）  
  
エ  （A株式会社or請求書）or（１００円and２００円）  
`
        },
        {
                "mondaiId": 106220060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
クライアントサーバシステムは業務処理用システムとしても利用されている。ク  
ライアントサーバシステムの一種に、プレゼンテーション層、ファンクション層、  
データ層から構成される\u0002層クライアントサーバシステムがある。この\u0002層クライアントサーバシステムに関する記述として最も適切なものはどれか。  
  
ア  \u0002層クライアントサーバシステムを構築する際、一方のコンピュータをファン  
クション層、他方をデータ層として構成したものがデュアルシステムである。  
  
イ  Webサーバの負担軽減のために、一方のコンピュータでファンクション層と  
してWebアプリケーションサーバを、他方でデータ層としてデータベースサー  
バを稼働させるタイプも\u0002層クライアントサーバシステムの一種である。  
  
ウ  プレゼンテーション層において、ユーザからの入力受付機能としてタッチパネ  
ル方式を利用することによって、ユーザインタフェースの向上とともに、ファン  
クション層での処理量の軽減が期待できる。  
  
エ  プレゼンテーション層、ファンクション層、データ層は論理的な区分であり、  
実装する場合はファンクション層とデータ層は同一のコンピュータ上で稼働させ  
る必要がある。  
`
        },
        {
                "mondaiId": 106220070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ノートPCやモバイル機器を事業所内の様々な場所で利用してインターネットに  
接続したり、プリンタや共有ディスクを様々な場所から利用したりするなど、柔軟  
な情報設備利用を行いたい場合、無線LANを利用することも多い。この無線LAN  
に関する記述として最も適切なものはどれか。  
  
ア  マウスやキーボードの接続に利用されるBluetoothは、無線LANが採用して  
いるCSMA/CA方式のプロトコルを使用しているので、遠隔地にあるPCへの  
データ入力や各種の操作が可能である。  
  
イ  無線LANではUHF帯の電波を利用しているので、地上デジタル放送の電波  
を利用して通信が可能である。  
  
ウ  無線LANのアクセスポイント設置には、最低\u0002つのIPアドレスが与えられ  
た有線LANのノードが必要で、無線LANのアクセスポイント同士を中継して  
到達距離を延ばすことはできない。  
  
エ  無線LANの通信方法にはPC等の機器同士が直接通信するアドホックモード  
と、アクセスポイントを介して機器同士が通信するインフラストラクチャモード  
がある。  
`
        },
        {
                "mondaiId": 106220080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
社内において外部情報の収集のためにインターネットを利用し、また社内情報の  
共有化のためにイントラネットをも利用したい。その実現方法に関する記述として  
最も適切なものはどれか。  
  
ア  イントラネットにおいて様々な機能を提供する各種サーバを運用する場合は、  
外部からそれらのサーバ群に接続できなくするために、NTPサーバによるファイアウォールを設置する必要がある。  
  
イ  会社独自のドメイン名を用いた電子メールを利用したい場合は、DNSサーバ  
やsendmailなどのメールサーバソフトウェアの運用を行う。  
  
ウ  社内において使用するサーバやクライアントコンピュータのオペレーティング  
システムは、同一のものを使用する必要がある。  
  
エ  社内において文書作成や表計算のソフトウェアで作成したファイルを、社員間  
で共有したい場合は、DMZにWebサーバやFTPサーバを設置して利用する。  
`
        },
        {
                "mondaiId": 106220090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、ユーザインタフェースの高度化を目指して、様々な入力装置が開発されて  
いる。次のａ～ｄの記述と入力装置の組み合わせとして、最も適切なものを下記の  
解答群から選べ。  
ａ写真、図形、文書などをイメージとして取り込む。  
ｂキーを押すことで入力文字がコンピュータに伝達される。  
ｃ商品などに付けられているコードを読み取る。  
ｄポインティングデバイスの\u0002つで、装置そのものを動かし、相対位置変化を読  
み取る。  
〔解答群〕  
  
ア  ａ：ハンディスキャナｂ：デジタイザ  
ｃ：バーコードリーダｄ：マウス  
  
イ  ａ：フラットベッドスキャナｂ：キーボード  
ｃ：バーコードリーダｄ：マウス  
  
ウ  ａ：フラットベッドスキャナｂ：キーボード  
ｃ：ハンディスキャナｄ：タッチパネル  
  
エ  ａ：フラットベッドスキャナｂ：デジタイザ  
ｃ：ハンディスキャナｄ：タッチパネル  
`
        },
        {
                "mondaiId": 106220100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
近年の技術革新により、映像や音声を中心とするマルチメディアデータを提供す  
るサービスが実用化されている。マルチメディアデータには種々の形式や処理方法  
が存在している。これに関する記述として最も適切なものはどれか。  
  
ア  インターネットラジオとは、個人や放送を専門としない企業や団体がインター  
ネットで各種音声コンテンツを流すサービスであるが、著作権の問題があるため  
に既存放送局がそのようなサービスを行っている国はない。  
  
イ  ストリーミングとは、データをすべてダウンロードした後で自動的に再生する  
方式であり、再生後データを消去すれば著作権上問題が起こらない方法である。  
  
ウ  ビデオオンデマンドとは、ユーザが要求した時点で、映像コンテンツを配信す  
るサービスであるが、同時刻に多数のユーザから要求があった場合、送出サーバ  
や通信回線の帯域によっては、すべての要求に応えられないなどの問題がある。  
  
エ  マルチメディアデータやソフトウェアで著作権フリーとするものがあるが、著  
作権フリーと書かれている以上、制作者は著作権を放棄しており利用上の制約は  
ない。  
`
        },
        {
                "mondaiId": 106220110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
近年、インターネットを介して多様な情報システムサービスが提供されるように  
なった。代表的なネットワークサービスに関する記述として最も適切なものはどれ  
か。  
  
ア  インターネット上のどこかにある販売管理向けソフトウェアを利用し、自社で  
はそのソフトウェアを持たない仕組みはASPである。  
  
イ  業務用のアプリケーションソフトウェアパッケージを、インターネットを介し  
て顧客がダウンロードして利用するサービスはサース（SaaS）である。  
  
ウ  メッセージ交換をXMLで行い、通信はSOAPのデータおよび命令交換ルール  
に従って行う仕組みはWebサービスである。  
  
エ  ユーザの要求に応じてネットワーク設備を割り当てるサービスはパース  
（PaaS）である。  
`
        },
        {
                "mondaiId": 106220120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
下表は、２０１０年\u0002月\u0003日のあるスーパーマーケットの売上表である。この表に  
対して、次のSQL文を実行した結果として最も適切なものを下記の解答群から選  
べ。  
SELECT顧客IDFROM売上表  
WHERE商品名LIKE‘野菜％’  
AND単価>１００  
売上表  
取引番号取引日顧客ID商品名購入数単価  
１００２０１０/２/９１１１カップ麺ニコニコ４９８  
１０１２０１０/２/９１１１野菜N産大根１１５６  
１０２２０１０/２/９２０１野菜N産大根１１５６  
１０３２０１０/２/９３０３カップ麺ケロケロ２９８  
１０４２０１０/２/９３０３野菜M産にんじん１５５  
１０５２０１０/２/９３０３ジュース野菜味２１１０  
１０６２０１０/２/９１０１サラダ野菜２１７５  gaku
１０７２０１０/２/９１０１カップ麺はるさめ１８８  
１０８２０１０/２/９２２２野菜N産大根２１５６  
１０９２０１０/２/９２２２サラダ果物１２５０  
１１０２０１０/２/９２２２野菜M産にんじん２５５  
１１１２０１０/２/９１１１ジュースオレンジ３９９  
１１２２０１０/２/９１１１牛乳K産１１２５  
〔解答群〕  
  
ア    
顧客ID  
１０１  
３０３  
  
イ    
顧客ID  
１１１  
２０１  
２２２  
  
ウ    
顧客ID  
１１１  
２０１  
３０３  
２２２  
  
エ    
顧客ID  
１１１  
２０１  
３０３  
１０１  
２２２  
`
        },
        {
                "mondaiId": 106220130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
近年、レガシーシステムから、クライアントサーバシステムなどのオープン系シ  
ステムへの移行が課題となっている。その際に、両者のシステムでどのようなファ  
  
イ  ル管理方式が使われているのかを把握することは、円滑な移行を行う上で欠くこ  
とができない。ファイル管理システムに関する記述として最も適切なものはどれ  
か。  
  
ア  オープン系システムで使われるようになった区分編成ファイルとは、メンバー  
と呼ばれる複数の順編成ファイルで構成され、メンバー単位でのアクセスと格納  
  
ア  ドレスを指定するアクセスとの両方ができる。  
  
イ  オープン系システムで使われるようになった順編成ファイルとは、書き込み順  
で物理的に連続してレコードを記録する方式であり、格納アドレスを指定して、  
直接アクセスができる。  
  
ウ  レガシーシステムではOSがレコード管理機能を持っているが、オープン系シ  
ステムではOSによる管理はファイルシステムまでであり、各ファイル内のファ  
  
イ  ル構造はアプリケーションが管理する。  
  
エ  レガシーシステムでよく使われている可変長レコードとは、データによってレ  
コードの長さが変わるもので、固定長レコードのようにレコードの長さを示す  
フィールドを持たない。  
`
        },
        {
                "mondaiId": 106220140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
システム開発の基本的フェーズは、フェーズ\u0002：要件定義、フェーズ\u0003：外部設  
計、フェーズ\u0004：内部設計、フェーズ\u0005：プログラム開発、フェーズ\u0006：各種テス  
ト、フェーズ\u0007：稼動である。これら各フェーズを後戻りすることなく順に行って  
いく方法論を、ウォータフォール型システム開発方法論と呼ぶ。しかし、この方法  
論には種々の課題があるとされ、その課題の解消を目的に多様な方法論が開発され  
ている。そのような方法論に関する記述として最も適切なものはどれか。  
  
ア  RADは、ウォータフォール型システム開発方法論よりも迅速に開発すること  
を目的としたもので、システムエンジニアだけで構成される大人数の開発チーム  
で一気に開発する方法論である。  
  
イ  システム開発を迅速かつ確実に進める方法論としてXPがあるが、それは仕様  
書をほとんど作成せず、ストーリーカードと受け入れテストを中心に開発を進め  
る方法論である。  
  
ウ  スパイラル開発は、\u0002つのフェーズが終わったら、もう一度、そのフェーズを  
繰り返すペアプログラミングと呼ばれる手法を用いて確実にシステムを開発して  
いく方法論である。  
  
エ  プロトタイピングは、フェーズ\u0006の各種テストを簡略に行う方法論である。  
`
        },
        {
                "mondaiId": 106220150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
情報システム開発において、発注者と開発者とのコミュニケーションを円滑に行  
うために、また迅速で間違いのないシステム開発のために、多様なダイヤグラムが  
用いられるようになってきた。これに関する記述として、最も不適切なものはどれ  
か。  
  
ア  ER図とは、データ中心設計法で用いられる表記法で、データ間の関連を描画  
する。  
  
イ  UMLとは、オブジェクト指向開発において利用される統一表記法である。  
  
ウ  ネットワーク図とは、オブジェクト間の関係とメッセージフロー等を構造的に  
表現する、UMLのダイヤグラムの\u0002つである。  
  
エ  ユースケース図とは、システムにはどのような利用者がいるのか、その利用者  
がどのような操作をするのかを記述する、UMLのダイヤグラムの\u0002つである。`
        },
        {
                "mondaiId": 106220160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
近年、データのセキュリティを確保することの重要性が高まっている。セキュリ  
ティの確保において暗号化は欠くことのできない手法である。これに関する記述と  
して最も適切なものはどれか。  
  
ア  「TOKYO」を、シーザー暗号を用いて暗号化した場合、その\u0002つは「WRNBR」  
である。  
  
イ  SSL（SecureSocketLayer）は、シーザー暗号、デジタル証明書、ハッシュ関数  
を用いており、情報の改ざんやなりすまし等を防ぐことができる技術である。  
  
ウ  公開鍵暗号方式の場合、送り手は受け手の秘密鍵で送信情報を暗号化し、受け  
手は送り手の公開鍵で情報を復号化する。  
  
エ  秘密鍵または共通鍵暗号方式を用いて\u0003人の相手と通信する場合、通信相手ご  
とに異なる\u0004つの秘密鍵または共通鍵を共有する必要がある。  
`
        },
        {
                "mondaiId": 106220170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
インターネットなどの情報ネットワーク技術の広範な普及に伴い、情報ネット  
ワークを様々な業務において利用することが一般化しつつある。その場合、情報  
ネットワークのセキュリティをいかに確保するかが大きな課題となる。これに関す  
る記述として最も適切なものはどれか。  
  
ア  VPNは、安価な公衆網、例えばインターネットなどを用いて通信データの交  
換を行う場合、そのデータが通っている回線上で一時的にその他のパケットが入  
り込まないようにしてセキュリティを確保する技術である。  
  
イ  アプリケーションゲートウェイとは、通信データをすべてチェックするアプリ  
ケーションを各PCにインストールして、セキュリティを確保する方式であり、  
データの中身まで検査できることから、高いセキュリティが確保できる。  
  
ウ  パケットフィルタリングとは、ルータにおいて、通信データに含まれる情報を  
判読し、フィルタリング設定にそぐわないパケットを通過させない方式である。  
  
エ  無線LANを利用する場合、盗聴を防ぐための対策が必要であり、それには  
RASサーバを設置して暗号化する方法が有効である。  
`
        },
        {
                "mondaiId": 106220180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
情報システム開発の上流工程に関して留意すべき事項が、例えば原理原則とし  
て、いくつか提案されている。そのような留意すべき事項の記述として最も適切な  
ものはどれか。  
  
ア  開発プロセスの進展に合わせて反復して行う多段階の見積りは、見積り変動に  
より発注者や開発者のリスクを増大させる。  
  
イ  数値化していない要件は、それを満たしているか否かの判定基準が人によって  
異なるので、数値化すべきである。  
  
ウ  要件定義に未確定な部分があるときは、漏れがないように決定を先送りすべき  
である。  
  
エ  要件定義の設定は、発注者と開発者の共同責任である。`
        },
        {
                "mondaiId": 106220190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
情報システムの投資価値を検討する枠組みとして、独立行政法人情報処理推進機  
構（IPA）が２００９年に発表した価値指向マネジメントフレームワーク（IT―VDM／  
VOM;ITValueDomainModel，ValueOrientedManagement）が利用できる。こ  
れは、情報システム開発に関するユーザとベンダの問題意識の共有を目的とするも  
のである。このフレームワークに関する記述として最も適切なものはどれか。  
  
ア  IT―VDMでは、価値ドメインと価値プロセスの二次元での分析を元に意思決  
定を行う。  
  
イ  IT―VDMの価値ドメインでは、ビジネス企画、システム企画、開発などの、  
意思決定が必要となる局面を定義する。  
  
ウ  IT―VDMの価値プロセスは、P（計画）→D（実施）→C（点検）→A（改善）サイ  
クルのP（計画）局面で遂行する。  
  
エ  IT―VOMは価値指向管理を目標とし、この具体的な適用方法がIT―VDMにな  
る。  
`
        },
        {
                "mondaiId": 106220200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
１９８９年に米国で発表されて日本にも紹介されたビジネスインテリジェンスシス  
テムに関する記述として最も適切なものはどれか。  
  
ア  競合他社の競争力を測定して、その強みと弱みを発見する。  
  
イ  業務システムに蓄積されたデータを分析・加工して、企業の意思決定に活用す  
る。  
  
ウ  経済全般の情勢を測定して、将来の経済動向を予測する。  
  
エ  社内のデータや通信を監視し、規則への適合性を確認することで、情報漏洩を  
防止する。`
        },
        {
                "mondaiId": 106220210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
今日では多様な業務をアウトソーシングすることができる。アウトソーシングで  
利用できる業務やサービスに関する記述として最も適切なものはどれか。  
  
ア  企業の電話窓口でオペレータが対話的に応答することをIVR（Interactive  
VoiceResponse）という。  
  
イ  社内スタッフからの業務支援要請を取り次ぐ部門をコンタクトセンタという。  
  
ウ  情報設備の新設や移設と設定などを行うサービスをキッティングサービスとい  
う。  
  
エ  保有するサーバの一部機能を利用者に有料で利用させることをハウジングサー  
ビスという。  
`
        },
        {
                "mondaiId": 106220220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
中小企業診断士は中小企業経営者を支援して、企業のシステム開発プロジェクト  
に関与することがある。このようなシステム開発のプロジェクト管理で用いる  
WBS（WorkBreakdownStructure）に関する記述として、最も適切なものはどれ  
か。  
  
ア  WBS作成には、PMBOKが規定した標準作成方法が利用できる。  
  
イ  成果物を得るのに必要な工程や作業について記述する。  
  
ウ  担当者の分担に基づいて、WBSを作成する。  
  
エ  プロジェクトの実施段階で、管理資料として作成する。`
        },
        {
                "mondaiId": 106220230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
複数のサーバから構成される情報システムにおける可用性および可用性を高める  
技法に関する記述として、最も適切なものはどれか。  
  
ア  可用性が高いとは、システムの処理結果が一貫していて、いつでも信頼できる  
ことである。  
  
イ  可用性の指標は平均故障間隔（MTBF）である。  
  
ウ  可用性は計画停止時間数を除いて測定する。  
  
エ  可用性を高める技法であるフェイルセーフとは、障害が発生したサーバを自動  
的に切り離して、その処理を他のサーバが引き継ぐことをいう。  
`
        },
        {
                "mondaiId": 106220240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
母集団の分布形状は不明であるが、その平均は１００で標準偏差が３０である場  
合、ここから無作為に標本１，０００個を抽出するときに、その標本の基本統計量に関  
する説明として最も適切なものはどれか。  
  
ア  標本平均の分散は０．３である。  
  
イ  標本平均の分散は０．９である。  
  
ウ  標本平均の分布はχ２分布になる。  
  
エ  標本平均の分布形状は母集団と同じになる。`
        },
        {
                "mondaiId": 106220250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2010,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
当社工場で製造した製品１００個の全数検査をした。このうち１０個にAタイプの  
欠陥が、\u0002個にBタイプの欠陥があり、以上のうち\u0003個には両方の欠陥があっ  
た。この１００個の中から無作為に抜き出した製品\u0004個にAタイプの欠陥があった  
とき、これにBタイプの欠陥もある確率として最も近い数値はどれか。  
  
ア  ０．０６  
  
イ  ０．１  
  
ウ  ０．１８  
  
エ  ０．３  
`
        },
        {
                "mondaiId": 107220010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
わが国における企業倒産の多くは中小企業によるものである。（株）東京商工リ  
サーチ「全国企業倒産白書」によれば、２００８年に発生した企業倒産件数（負債金額  
\u0002千万円以上）のうち、中小企業（資本金\u0002億円未満）が全体の９７．５％を占めてい  
る。同年の業種別の倒産件数を見るとＡの倒産が最も多く、倒産要因別の  
倒産件数を見ると、１９９８年から２００８年まで一貫してＢによるものが最も  
多くなっている。  
〔解答群〕  
  
ア  Ａ：建設業Ｂ：販売不振  
  
イ  Ａ：建設業Ｂ：放漫経営  
  
ウ  Ａ：サービス業Ｂ：販売不振  
  
エ  Ａ：サービス業Ｂ：放漫経営  
`
        },
        {
                "mondaiId": 107220021,
                "name": "第2問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
わが国経済において、中小企業は大きな役割を果たしている。財務省「法人企業  
統計」によれば、法人企業の産出する付加価値額（２００７年度）のＡ割強を中  
小企業（資本金\u0002億円未満の法人企業）が占めており、そのシェアは１９６０年からお  
おむね安定的に推移している。  
また、総務省「事業所・企業統計調査（２００６年）」によれば、企業ベースで、  
\u0002  
民営非一次産業の会社の常用雇用者数と個人事業所の従業者総数４，０１２万人のうち  
約Ｂ割が中小企業（中小企業基本法において定義されるもの）で雇用されて  
おり、  
\u0003  
雇用機会の提供という面でも中小企業は大きな役割を果たしている。  
（設問1））  
文中の空欄ＡとＢに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：\u0003Ｂ：\u0003  
  
イ  Ａ：\u0003Ｂ：\u0004  
  
ウ  Ａ：\u0004Ｂ：\u0005  
  
エ  Ａ：\u0004Ｂ：\u0003  
  
オ  Ａ：\u0004Ｂ：\u0004  
`
        },
        {
                "mondaiId": 107220022,
                "name": "第2問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、総務省「事業所・企業統計調査（２００６年）」に基づ  
き、企業ベースで会社の常用雇用者数と個人事業所の従業者総数を産業別規模別  
に見た場合、次の産業において、中小企業（中小企業基本法において定義される  
もの）の構成比が高いものから低いものへ並べた組み合わせとして、最も適切な  
ものを下記の解答群から選べ。  
ａ建設業  
ｂ製造業  
ｃ飲食店、宿泊業  
〔解答群〕  
  
ア  ａ建設業―ｂ製造業―ｃ飲食店、宿泊業  
  
イ  ａ建設業―ｃ飲食店、宿泊業―ｂ製造業  
  
ウ  ｂ製造業―ａ建設業―ｃ飲食店、宿泊業  
  
エ  ｂ製造業―ｃ飲食店、宿泊業―ａ建設業  
  
オ  ｃ飲食店、宿泊業―ａ建設業―ｂ製造業`
        },
        {
                "mondaiId": 107220023,
                "name": "第2問設問3",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0003について、総務省「就業構造基本調査」に基づき、２００２年時点  
と２００７年時点の中小企業と大企業における雇用形態を比較した場合、最も適切  
なものはどれか。なお、ここでは、非一次産業の従業者数２９９人以下（卸売業、  
サービス業は９９人以下、小売業、飲食店は４９人以下）の企業を中小企業、それ  
以外を大企業とし、正社員とは「常雇」かつ「正規の職員・従業員」を示す。  
  
ア  大企業と中小企業とも正社員比率は増加している。  
  
イ  大企業の正社員比率の減少幅は中小企業の正社員比率の減少幅を上回ってい  
る。  
  
ウ  中小企業の正社員比率は大企業の正社員比率を２００７年時点のみ上回ってい  
る。  
  
エ  中小企業の正社員比率は大企業の正社員比率を両時点とも上回っている。  
`
        },
        {
                "mondaiId": 107220031,
                "name": "第3問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
自動車産業は長らくわが国のリーディング産業とされてきたが、  
\u0002  
世界規模で企業  
間競争が激化する中で、自動車（完成車）メーカー各社は競争力向上に向けて、生産  
ネットワークの再編、次世代自動車・技術の開発、部品の共通化や  
\u0003  
モジュール生産  
方式の導入等によるコストダウンに取り組んでおり、生産組織の基盤を担ってきた  
\u0004  
中小自動車部品メーカーも生き残りをかけた対応を迫られている。  
（設問1））  
文中の下線部\u0002について、２００９年実績（生産台数ベース）で見て、世界最大の  
自動車生産国はどこか。  
  
ア  インド  
  
イ  中国  
  
ウ  ドイツ  
  
エ  日本  
  
オ  米国`
        },
        {
                "mondaiId": 107220032,
                "name": "第3問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0003について、モジュール生産方式の導入により自動車メーカーが  
期待できる効果として、<u>最も不適切なものはどれか</u>。  
  
ア  組立作業の効率化  
  
イ  直接取引する部品メーカー数の減少による取引（調整）コストの削減  
  
ウ  直接取引する部品メーカー数の増加による価格交渉力の向上  
  
エ  部品メーカーの生産・開発力の活用  
`
        },
        {
                "mondaiId": 107220033,
                "name": "第3問設問3",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、近年の事業環境の変化を受けた中小自動車部品メー  
カーの取り組みとして、<u>最も不適切なものはどれか</u>。  
  
ア  営業部門の閉鎖・縮小による生産活動への特化  
  
イ  コア技術を活かした新事業・新分野への展開  
  
ウ  受注先企業のグローバル展開に対応した海外拠点の新設  
  
エ  素材・加工法の見直しによるコスト競争力の強化  
  
オ  電機・電子系技術の取り込み`
        },
        {
                "mondaiId": 107220040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の空欄ＡとＢに最も適切な語句の組み合わせを下記の解答群から選べ。  
経済産業省「商業統計表」に基づき、２００４年と２００７年の卸売業の推移を見た場  
合、Ａは増加し、Ｂは減少している。  
〔解答群〕  
  
ア  Ａ：事業所数と従業者数Ｂ：年間販売額  
  
イ  Ａ：従業者数Ｂ：事業所数と年間販売額  
  
ウ  Ａ：年間販売額Ｂ：事業所数と従業者数  
  
エ  Ａ：年間販売額と事業所数Ｂ：従業者数  
  
オ  Ａ：年間販売額と従業者数Ｂ：事業所数  
`
        },
        {
                "mondaiId": 107220050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財務基盤がぜい弱な成長初期の中小企業にとって、リスクマネーを供給するエク  
  
イ  ティファイナンスの担い手として、ベンチャーキャピタルが果たすべき役割は大  
きいと考えられるが、（財）ベンチャーエンタープライズセンター「ベンチャーキャ  
ピタル等投資動向調査」に基づき、わが国のベンチャーキャピタルの投資残高を  
１９９８年度からの１０年間で見ると最大\u0002兆円程度で推移しており、わが国金融機関  
の中小企業向け貸出残高と比較しても小さな規模にとどまっているのが現状であ  
る。  
日本のベンチャーキャピタル投資残高を、米国、ユーロ圏と比較した場合、最も  
適切なものはどれか。  
  
ア  米国およびユーロ圏をともに下回っている。  
  
イ  米国を上回っている。  
  
ウ  米国を上回っているがユーロ圏を下回っている。  
  
エ  ユーロ圏を上回っている。  
  
オ  ユーロ圏を上回っているが米国を下回っている。`
        },
        {
                "mondaiId": 107220060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄に入る最も適切な数値を下記の解答群から選べ。  
経済のグローバル化の進展を受けて、海外直接投資が増加している。総務省「平  
成１８年事業所・企業統計調査」を見ると、海外に子会社もしくは関連会社を保有し  
ている海外展開企業数（会社ベース）は１０，１３８社で、うち中小企業は７，５���１社と  
なっている。これは全中小企業（会社ベース）の約に相当する。  
中小企業にとっては、国内市場の成熟化を背景に内需の低迷が続く中で、海外市  
場の重要性は今後ますます増すものと考えられ、顧客ニーズを踏まえた適切な海外  
戦略の構築が求められている。  
〔解答群〕  
  
ア  ０．５％イ５．０％ウ１０．０％エ１５．０％オ２０．０％  
`
        },
        {
                "mondaiId": 107220070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄Ａ～Ｃに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
財務省「法人企業統計季報」に基づき、２００４年から２００８年までの期間について、  
中小企業の有利子負債残高償還年数（有利子負債残高／キャッシュフロー）の推移を  
見ると、２００８年に入りＡしている。これは、ＢのＣが  
主因であると考えられる。なお、ここでは資本金\u0002千万円以上\u0002億円未満の企業を  
中小企業とする。  
〔解答群〕  
  
ア  Ａ：下降Ｂ：キャッシュフローＣ：増加  
  
イ  Ａ：下降Ｂ：有利子負債残高Ｃ：減少  
  
ウ  Ａ：上昇Ｂ：キャッシュフローＣ：減少  
  
エ  Ａ：上昇Ｂ：有利子負債残高Ｃ：増加  
`
        },
        {
                "mondaiId": 107220081,
                "name": "第8問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
グローバル規模で企業間競争が激化する中で、中小企業が事業の存続、発展を図  
るためには、新たな付加価値を創出することが求められており、その実現に向けた  
\u0002  
研究開発活動の重要性がますます高まっている。  
しかしながら、わが国において研究開発に取り組む中小企業の割合は、全体とし  
てみれば、  
\u0003  
大企業に比べて高いものではなく、中小企業の研究開発費の総額も大企  
業の約１５兆円に対して約\u0002兆円にとどまっている（総務省「科学技術研究調査（２００８  
年）」）。その要因の\u0002つとして、中小企業が相対的に資金力や人的資源に乏しいこ  
とがあげられる。  
（設問1））  
文中の下線部\u0002について、経済産業省「企業活動基本調査」に基づき、１９９４年  
度から２００６年度について、中小製造業における研究開発費が売上高に占める割  
合（売上高研究開発費比率）と売上高営業利益率の推移を比較した場合、最も適切  
なものはどれか。なお、ここでは、売上高研究開発費比率２．５％以上、同  
２．５％未満（\u0003％を除く）、同\u0003％の企業群を比較するものとする。  
  
ア  一貫して、売上高研究開発費比率が高い企業ほど売上高営業利益率も高い。  
  
イ  売上高研究開発費比率と売上高営業利益率に相関関係は見られない。  
  
ウ  景気の回復局面では、売上高研究開発費比率の低い企業ほど売上高営業利益  
率が高くなっている。  
  
エ  景気の後退局面では、売上高研究開発費比率の低い企業ほど売上高営業利益  
率が高くなっている。  
`
        },
        {
                "mondaiId": 107220082,
                "name": "第8問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、総務省「科学技術研究調査（２００８年）」に基づき、  
企業規模別の研究開発の実施割合を見た場合に、<u>最も不適切なものはどれか</u>。  
なお、ここでは、従業者数\u0003～２９９人の企業を中小企業、それ以外の企業を大企  
業とする。  
  
ア  大企業（製造業）の研究開発の実施割合は、大企業（非製造業）の実施割合を  
上回っている。  
  
イ  大企業（非製造業）の研究開発の実施割合は、中小企業（非製造業）の実施割合  
を上回っている。  
  
ウ  中小企業（製造業）の研究開発の実施割合は、大企業（非製造業）の実施割合を  
上回っている。  
  
エ  中小企業（製造業）の研究開発の実施割合は、中小企業（非製造業）の実施割合  
を上回っている。  
`
        },
        {
                "mondaiId": 107220091,
                "name": "第9問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
知識経済化の進展を受けて、現代企業の経営にとって、自らが生み出した知的財  
産の保護、活用を図る重要性が高まっていることから、  
\u0002  
世界的に見ても特許出願件  
数は急速に増加しており、わが国における特許出願件数も年間４０万件前後と高水  
準で推移している。  
一方で、わが国の特許保有や  
\u0003  
特許出願件数に占める中小企業の割合は高いもので  
はない。これは、特許取得に関するコスト負担が大きいこと等に加えて、中小企業  
が、自らの知的財産について、特許の出願により保護するのではなく、ほかへの流  
出を嫌って、成果（情報）を内部化することで保護しようとしていることも一因にあ  
ると考えられる。  
（設問1））  
文中の下線部\u0002について、特許庁「特許行政年次報告書２００８年版」に基づき、  
次の各国における特許出願件数を比較した場合、２００７年時点で特許出願件数が  
多いものから少ないものへ並べた組み合わせとして、最も適切なものを下記の解  
答群から選べ。  
ａ日本  
ｂ米国  
ｃ中国  
〔解答群〕  
  
ア  ａ日本―ｂ米国―ｃ中国  
  
イ  ａ日本―ｃ中国―ｂ米国  
  
ウ  ｂ米国―ａ日本―ｃ中国  
  
エ  ｂ米国―ｃ中国―ａ日本  
  
オ  ｃ中国―ａ日本―ｂ米国  
`
        },
        {
                "mondaiId": 107220092,
                "name": "第9問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、特許庁「平成１９年知的財産活動調査」に基づき、  
大企業と中小企業の\u0003社当たりの国内特許出願件数（業種全体）を比較した場合、  
最も適切な説明はどれか。なお、ここでは中小企業とは中小企業基本法に定義す  
るものを示し、大企業はこれ以外の企業を示す。  
  
ア  大企業の国内特許出願件数は中小企業の約\u0004倍である。  
  
イ  大企業の国内特許出願件数は中小企業の約１５倍である。  
  
ウ  大企業の国内特許出願件数は中小企業の約３０倍である。  
  
エ  大企業の国内特許出願件数は中小企業の約５０倍である。  
  
オ  大企業の国内特許出願件数は中小企業の約７０倍である。`
        },
        {
                "mondaiId": 107220100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
次の文章の空欄Ａ～Ｃに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
中小企業の経営を見ると、いくつかの特徴を指摘することができる。第\u0003は保有  
する経営資源が、ぜい弱なことである。第\u0002は中小規模がゆえに存立可能な事業分  
野が限定されることである。必要資本量がＡで、需要のＢが大き  
く規模の経済が働きにくい分野ほど、中小企業の存立可能性は高くなる。第\u0005は組  
織としての能力が弱いことから、Ｃ個人の能力への経営面の依存度が高い  
ことである。  
〔解答群〕  
  
ア  Ａ：少額Ｂ：均質性Ｃ：経営者  
  
イ  Ａ：少額Ｂ：均質性Ｃ：従業員  
  
ウ  Ａ：少額Ｂ：不均質性Ｃ：経営者  
  
エ  Ａ：多額Ｂ：均質性Ｃ：経営者  
  
オ  Ａ：多額Ｂ：不均質性Ｃ：従業員  
`
        },
        {
                "mondaiId": 107220110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
次の文章の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
中小企業白書（２００９年版）では、中小企業の賃金体系は大企業の賃金体系に比べ  
年功主義よりも成果主義の性格がＡと指摘されている。例えば、厚生労  
働省「平成１９年賃金構造基本統計調査」に基づき、規模別製造業・非製造業別正  
社員の賃金カーブを見ても、１８～１９歳の階層を基点に、大企業では製造業、非製  
造業とも５０～５４歳の階層まで、中小企業では製造業で４５～４９歳、非製造業で  
４０～４４歳の階層までは、一貫して年齢が高くなるほど賃金が上昇しているが、中  
小企業のほうが上昇のスピードはＢである。もっとも、年功主義と成果主  
義にはそれぞれメリットとデメリットがあり、賃金体系が従業員のモチベーション  
に与える影響は大きいことから、中小企業経営者は従業員の意識や仕事ぶりをしっ  
かりと把握したうえで、年功主義と成果主義の要素を適切に組み合わせた自社に適  
した賃金体系を構築していくことが重要であると考えられる。  
〔解答群〕  
  
ア  Ａ：強いＢ：急  
  
イ  Ａ：強いＢ：緩やか  
  
ウ  Ａ：弱いＢ：急  
  
エ  Ａ：弱いＢ：緩やか  
`
        },
        {
                "mondaiId": 107220120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
中小企業が抱える経営課題を解決する方策の\u0002つとして、既存の取引関係の有無  
にこだわらず、自社の独立性を確保しつつ、異業種の中小企業と各々の強みを活か  
したゆるやかな連携関係を構築することが有効であると考えられる。新事業展開を  
目指す中小企業が、自社単独で事業活動を進める場合と比較して、こうしたゆるや  
かな連携による事業活動を進めることで期待できるメリットとして、最も不適切な  
ものはどれか。  
  
ア  経営資源の相互補完が図れること。  
  
イ  受発注の機会が増加すること。  
  
ウ  利害調整コストが発生しないため効率的に活動できること。  
  
エ  リスクの分散を図れること。`
        },
        {
                "mondaiId": 107220130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
中小企業庁「規模別輸出額・輸入額（２００７年）」に基づき、中小企業性製品につい  
ての輸出相手国・地域を見た場合に、次の国・地域を全体に占める構成比率が高い  
ものから低いものへ並べた組み合わせとして、最も適切なものを下記の解答群から  
選べ。なお、中小企業性製品とは、日本標準産業分類（細分類）で従業者数３００人以  
下の中小事業所の出荷額が７０％以上（２００５年基準）を占めるものをいう。  
ａ中国  
ｂ北米  
ｃASEAN  
〔解答群〕  
  
ア  ａ中国―ｂ北米―ｃASEAN  
  
イ  ａ中国―ｃASEAN―ｂ北米  
  
ウ  ｂ北米―ａ中国―ｃASEAN  
  
エ  ｂ北米―ｃASEAN―ａ中国  
  
オ  ｃASEAN―ａ中国―ｂ北米  
`
        },
        {
                "mondaiId": 107220141,
                "name": "第14問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
次の文章を読んで、下記の設問に答えよ。  
経済のグローバル化、内需の成熟化の進展を背景に、製造業を取り巻く事業環境  
は厳しさを増している。総務省「事業所・企業統計調査」を見ても、１９８６年から  
２００６年の期間において、製造業は事業所数で約Ａ割、従業者数で約  
Ｂ割も減少している。  
こうした傾向は、東大阪市、大田区、浜松市といった中小製造業が多く立地する  
わが国の有数の産業集積においても同様であるが、特にこれらの地域では産業集積  
内部で基盤的存在の役割を担ってきた従業者数\u0002～\u0003人の小規模事業所の廃業によ  
る事業所数の減少が顕著であることが指摘されており、これまで産業集積が果たし  
てきた機能の弱体化が一段と懸念される状況となっている。  
（設問1））  
文中の空欄ＡとＢに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：\u0004Ｂ：２．５  
  
イ  Ａ：\u0004Ｂ：\u0003  
  
ウ  Ａ：\u0005Ｂ：２．５  
  
エ  Ａ：\u0005Ｂ：\u0003  
`
        },
        {
                "mondaiId": 107220142,
                "name": "第14問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部の小規模事業所の廃業が増加している一因として、自営業主の  
高齢化が考えられる。総務省「就業構造基本調査」に基づき、１９８７年から２００７年  
の期間において、自営業主の年齢層別の人数と平均年齢の推移を見た場合、最も  
不適切なものはどれか。  
  
ア  ６５歳以上の自営業主数は一貫して増加している。  
  
イ  自営業主数は一貫して減少している。  
  
ウ  自営業主の平均年齢は一貫して上昇している。  
  
エ  自営業主の約半数が２００７年時点で６５歳以上である。`
        },
        {
                "mondaiId": 107220150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　中小企業基本法における中小企業等の定義に関連する記述の正誤について、最も適切なものの組み合わせを下記の解答群から選べ。  

ａ　資本金2億円で、従業員数が２００人の食品卸売業者は、中小企業と定義される。  

ｂ　資本金1億円で、従業員数が１５人の医薬品を製造するベンチャー企業は、小規模企業と定義される。  


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
【重要知識】
中小企業者、小規模企業者の定義  
<TABLE align="left" width="100%">
<TR>
<TD style='background-color:#dddddd;color:#3880ff;border-top: 1px solid gray;border-right: 1px solid gray;border-bottom: 0px solid gray;border-left: 1px solid gray;'>
業種
</TD>
<TD colspan="2" style='background-color:#ddcccc;color:#3880ff; border: 1px solid gray;'>
中小企業者  
下記の<u>いずれか</u>の基準を満たすこと。  
（暗記の語呂合わせは、「セイオロコーサーゴゴゴイチ」など）
</TD>
<TD style='background-color:#ccddcc;color:#3880ff; border: 1px solid gray;'>
小規模企業者
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border-top: 0px solid gray;border-right: 1px solid gray;border-bottom: 1px solid gray;border-left: 1px solid gray;'>　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
資本金　
</TD>
<TD style='background-color:#eedddd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
<TD style='background-color:#ddeedd;color:#3880ff; border: 1px solid gray;'>
常時使用する従業員数
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
①製造業・建設業・運輸業  
　（その他②-④以外）
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>3</hintTarget>億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>300</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>20</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
②卸売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
1億円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>100</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5</hintTarget>人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
③小売業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>5000万</hintTarget>円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
100人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

<TR>
<TD style='background-color:#dddddd;color:#3880ff; border: 1px solid gray;'>
④サービス業</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
5000万円以下
</TD>
<TD style='background-color:#ffeeee;color:#3880ff; border: 1px solid gray;'>
<hintTarget>50</hintTarget>人以下
</TD>
<TD style='background-color:#eeffee;color:#3880ff; border: 1px solid gray;'>
5人以下
</TD>
</TR>

</TABLE>  
&nbsp;
</div>
</hint>


〔解答群〕  
  
ア  ａ：正　ｂ：正  
  
イ  ａ：正　ｂ：誤  
  
ウ  ａ：誤　ｂ：正<hint hidden='true'>**
←⭕
**</hint>   
  
エ  ａ：誤　ｂ：誤  
`
        },
        {
                "mondaiId": 107220161,
                "name": "第16問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
中小企業地域資源活用促進法に関する下記の設問に答えよ。  
（設問1））  
中小企業地域資源活用促進法に関する記述として、最も不適切なものはどれ  
か。  
  
ア  この法律における「地域」とは、自然的経済的社会的条件からみて一体である  
地域である。  
  
イ  「地域産業資源活用事業」の事業主体は、\u0003社以上の中小企業の連携体を対象  
としている。  
  
ウ  「地域産業資源活用事業計画」の認定の申請は、都道府県知事を経由して行  
う。  
  
エ  地域資源を不可欠な原材料とした商品を、当該資源に係る地域以外の製造業  
者が生産する事業は、「地域産業資源活用事業」にはならない。`
        },
        {
                "mondaiId": 107220162,
                "name": "第16問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
中小企業地域資源活用促進法における「地域産業資源活用事業」に該当するもの  
として、<u>最も不適切なものはどれか</u>。  
  
ア  地域資源である観光資源の特徴を利用して行われる役務（ツアー）の提供  
  
イ  地域資源である観光資源の特徴を利用して行われる商品（土産品）の開発・生  
産  
  
ウ  地域資源である工業品の生産技術を不可欠なものとして用いて行われる商品  
（家具）の開発  
  
エ  地域資源である農産物（一次産品）の需要の開拓  
`
        },
        {
                "mondaiId": 107220171,
                "name": "第17問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。  
農商工等連携促進法の目的は、「中小企業者と農林漁業者とがに連携  
し、それぞれの経営資源を有効に活用して行う事業活動を促進することにより、中  
小企業の経営の向上及び農林漁業経営の改善を図り、もって国民経済の健全な発展  
に寄与する」（同法第\u0002条）ことである。この法律に基づく農商工等連携事業の支援  
策を受けるためには、「農商工等連携事業計画」の認定を受ける必要がある。  
（設問1））  
文中の空欄に入る記述として、最も適切なものはどれか。  
  
ア  一体的  
  
イ  双利的  
  
ウ  対等的  
  
エ  有機的`
        },
        {
                "mondaiId": 107220172,
                "name": "第17問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部の認定要件に関する記述として、最も適切なものはどれか。  
  
ア  新商品もしくは新サービスの開発、生産・提供又は需要の開拓を行うもので  
あること。  
  
イ  総売上高の１０％以上の地域外売上が見込めること。  
  
ウ  都道府県が指定する地域資源を活用した事業であること。  
  
エ  農林漁業者に加え、\u0003以上の中小企業者が参加すること。  
`
        },
        {
                "mondaiId": 107220181,
                "name": "第18問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
次の文章を読んで、下記の設問に答えよ。  
事業協同組合は、相互扶助の精神に基づいて、新技術・製品開発、市場開拓、共  
同生産・販売等の事業を共同で行うことにより、事業者の新事業展開、経営革新、  
経営効率化を図るための組合である。この組合は、Ａを根拠法規とする。  
事業協同組合を設立するには、Ｂ人以上の発起人を要する。  
（設問1））  
文中の下線部に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  株式会社への組織変更が可能である。  
  
イ  組合員に対する手形の割引は禁止されている。  
  
ウ  組合員の議決権及び選挙権は、出資口数にかかわらず、平等である。  
  
エ  その名称中に、協同組合の文字を用いなければならない。`
        },
        {
                "mondaiId": 107220182,
                "name": "第18問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄ＡとＢに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：中小企業団体の組織に関する法律Ｂ：\u0003  
  
イ  Ａ：中小企業団体の組織に関する法律Ｂ：\u0004  
  
ウ  Ａ：中小企業等協同組合法Ｂ：\u0003  
  
エ  Ａ：中小企業等協同組合法Ｂ：\u0004  
`
        },
        {
                "mondaiId": 107220191,
                "name": "第19問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
下請代金支払遅延等防止法は、下請代金の支払遅延等を防止することによって、  
親事業者の下請事業者に対する取引を公正にすることなどを目的としている。この  
法律に関する下記の設問に答えよ。  
（設問1））  
この法律の対象となる取引として、最も適切なものはどれか。  
  
ア  資本金５００万円の製造業者が、個人の製造業者に物品の製造を委託する。  
  
イ  資本金５，０００万円の製造業者が、資本金２，０００万円の製造業者に物品の製造  
を委託する。  
  
ウ  資本金\u0002億円の製造業者が、資本金５，０００万円の製造業者に物品の製造を  
委託する。  
  
エ  資本金\u0003億円の製造業者が、資本金\u0002億円の製造業者に物品の製造を委託  
する。`
        },
        {
                "mondaiId": 107220192,
                "name": "第19問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
この法律では、親事業者に3つの義務が課せられている。これらの義務とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  下請事業者の利益を確保する義務  
  
イ  下請代金の支払期日を定める義務  
  
ウ  書類等の作成・保存義務  
  
エ  書面を交付する義務  
`
        },
        {
                "mondaiId": 107220200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
次の文章の空欄に入る最も適切なものを下記の解答群から選べ。  
中小ものづくり高度化法は、燃料電池、情報家電、自動車等の川下製品を製造  
するうえで、基盤となる「特定ものづくり基盤技術」の研究開発等を支援するもので  
ある。ここで、「特定ものづくり基盤技術」とは、製造業の又は新事業の  
創出に資するものであり、その技術を用いて行う事業活動の相当部分が中小企業に  
よって行われているものである。  
〔解答群〕  
  
ア  基盤技術の高度化  
  
イ  経営革新  
  
ウ  国際競争力の強化  
  
エ  生産性の向上`
        },
        {
                "mondaiId": 107220210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
中小企業投資育成株式会社による中小企業支援内容として、最も不適切なものは  
どれか。  
  
ア  株式会社の設立に際して発行される株式の引き受け  
  
イ  金融機関の中小企業者向け貸付債権を束ね、証券として投資家に販売  
  
ウ  新株予約権付社債の引き受け  
  
エ  投資先企業の事業承継の支援  
`
        },
        {
                "mondaiId": 107220221,
                "name": "第22問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
次の文章を読んで、下記の設問に答えよ。  
平成２１年\u0002月に施行された「地域商店街活性化法」は、商店街の活性化を図るこ  
とを目的として制定されたものである。この法律の第\u0003条では、「Ａ並び  
に地域住民の生活の向上及びＢに寄与してきた商店街の活力が低下して  
いる」とし、「商店街振興組合等が行う地域住民の需要に応じた事業活動」に対する  
支援措置等について定めるとされている。  
（設問２））  
文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  小売商業及びサービス業の振興  
  
イ  小売商業の振興  
  
ウ  中小小売商業及び中小サービス業の振興  
  
エ  中小小売商業の振興`
        },
        {
                "mondaiId": 107220222,
                "name": "第22問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  居住環境の維持  
  
イ  交流の促進  
  
ウ  地域活性化  
  
エ  賑わい形成  
`
        },
        {
                "mondaiId": 107220231,
                "name": "第23問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
次の文章を読んで、下記の設問に答えよ。  
高度化事業に対する融資の貸付対象はＡであり、貸付割合は原則として  
Ｂ％以内である。  
高度化事業には、\u0002つの事業がある。\u0003つが、中小企業者が事業協同組合などを  
設立し共同で取り組む事業である。もう\u0003つは、第\u0004セクターなどが地元の中小企  
業者を支援するために行う事業である。  
（設問２））  
文中の空欄ＡとＢに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：設備資金Ｂ：５０  
  
イ  Ａ：設備資金Ｂ：８０  
  
ウ  Ａ：長期運転資金と設備資金Ｂ：５０  
  
エ  Ａ：長期運転資金と設備資金Ｂ：８０`
        },
        {
                "mondaiId": 107220232,
                "name": "第23問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部に該当する事業として、最も適切なものはどれか。  
  
ア  イノベーション推進事業  
  
イ  集団化事業  
  
ウ  商店街整備等支援事業  
  
エ  地域産業創造基盤整備事業  
`
        },
        {
                "mondaiId": 107220241,
                "name": "第24問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
次の文章を読んで、下記の設問に答えよ。  
世代の交代期を迎えた中小企業の後継者が事業承継を行う場合、相続税等の特例  
措置を受けることができる。「中小企業における経営の承継の円滑化に関する法律」  
（経営承継法）における経済産業大臣の認定を受けたＡについて、その後継  
者が先代経営者から相続によりＢを取得した際に、Ｂに係る相続  
税の納税猶予制度を利用することができる。  
（設問1））  
文中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  資本金１，０００万円以上の中小企業  
  
イ  小規模企業  
  
ウ  上場中小企業  
  
エ  非上場中小企業`
        },
        {
                "mondaiId": 107220242,
                "name": "第24問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  自社株式  
  
イ  上場株式  
  
ウ  生産設備  
  
エ  のれん、屋号  
`
        },
        {
                "mondaiId": 107220251,
                "name": "第25問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
以下は、個人で食品小売業を営むX氏と中小企業診断士Y氏との会話である。  
この会話を読んで、下記の設問に答えよ。  
X氏：「運転資金の融資を受けたいと考えているのですが、保証人を探すのに苦労  
をしています。何か良い制度はありませんか。」  
Y氏：「たとえば、日本政策金融公庫に、第三者保証人等を不要とする融資制度が  
あります。この制度の利用を検討してみてはどうでしょうか。融資対象とな  
る要件には、原則として、所得税等を完納していることとＡがあり  
ますが、Xさんは融資対象には該当していますよ。」  
X氏：「具体的な保証や担保の条件はどのようになっているのですか。」  
Y氏：「審査は必要ですが、Ｂ」  
（設問1））  
会話の中の空欄Ａに入る語句として、最も適切なものはどれか。  
  
ア  開業後\u0003年以上経過していること  
  
イ  従業員２０人以下の中小企業者であること  
  
ウ  税務申告を\u0004期以上行っていること  
  
エ  同一地区で\u0004年以上事業を営んでいること`
        },
        {
                "mondaiId": 107220252,
                "name": "第25問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
会話の中の空欄Ｂに入る語句として、最も適切なものはどれか。  
  
ア  売掛債権などを担保にすることによって無保証融資を受けることができま  
す。  
  
イ  信用保証協会の保証によって融資を受けることができます。  
  
ウ  本人もしくは家族の保証があれば無担保で融資を受けることができます。  
  
エ  無担保・無保証人で融資を受けることができます。  
`
        },
        {
                "mondaiId": 107220260,
                "name": "第26問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
人材投資促進税制は、従業員の教育訓練を後押しする減税措置である。この制度  
に関して、「対象となりえる者」と「措置の内容」の組み合わせとして、最も適切なも  
のはどれか。  
  
ア  対象となりえる者：青色申告書を提出する個人事業者  
措置の内容：一律１２％を税額控除することができる。  
  
イ  対象となりえる者：青色申告書を提出する個人事業者  
措置の内容：労務費に占める教育訓練費の割合に応じて、税額控除率は  
異なる。  
  
ウ  対象となりえる者：資本金\u0002億円の製造業者  
措置の内容：一律１２％を税額控除することができる。  
  
エ  対象となりえる者：資本金\u0002億円の製造業者  
措置の内容：労務費に占める教育訓練費の割合に応じて、税額控除率は  
異なる。`
        },
        {
                "mondaiId": 107220270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2010,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
中小企業の事業再生に関する手法として、「第二会社方式」の事業再生がある。財  
務状況が悪化している中小企業者が、第二会社方式を活用した「中小企業承継事業  
再生計画」を作成し、認定を受けると、各種の支援策を利用することができる。  
これらの支援策として、<u>最も不適切なものはどれか</u>。  
  
ア  営業上必要な許認可等を承継  
  
イ  税負担の軽減措置  
  
ウ  中小企業再生支援協議会による補助金  
  
エ  日本政策金融公庫による特別融資  
`
        },
        {
                "mondaiId": 101210010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の付加価値に関する文章中の空欄に入る最も適切な数字を下記の解答群から選  
べ。  
\u0002農家による「オレンジ」の生産が４０万円であった。ただし、生産に必要とされ  
る中間生産物などの投入費用はゼロとする。このうち、飲料メーカーに３０万円  
分を卸し、残りの１０万円分を消費者に販売した。  
\u0003飲料メーカーは農家から仕入れた３０万円分の「オレンジ」で、「オレンジジュー  
ス」５０万円分を生産した。  
\u0004スーパーマーケットは飲料メーカーから５０万円分の「オレンジジュース」を仕  
入れ、消費者への「オレンジジュース」の販売が６０万円であった。  
\u0005このとき、付加価値の合計は万円に等しい。  
〔解答群〕  
  
ア  ６０  
  
イ  ７０  
  
ウ  ８０  
  
エ  ９０  
  
オ  １００  
`
        },
        {
                "mondaiId": 101210020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、世界の輸出市場に参加する労働力人口を表したものである。この図によ  
れば、新興工業国の経済発展とともに、世界の輸出に関わる労働力人口は、２５年  
間で約\u0002倍の伸びを示している。しかし、このうち、日本への輸出に関わる労働力  
人口の増加は約\u0003倍の伸びにとどまっている。このため、少子化が加速する日本国  
内では、グローバル化の効果を活用していないことが指摘される。  
下図と関連して、次の文章中の空欄ＡおよびＢに入る最も適切なものの組み合わ  
せを下記の解答群から選べ。  
少子化が加速し、労働が相対的に希少な日本では、グローバル化の効果として  
Ａが生じ、それに伴い、国内においてはＢ圧力が掛かると考えら  
れる。  
出所：内閣府『経済財政白書２００８年版』  
〔解答群〕  
  
ア  Ａ：労働集約財の輸出拡大Ｂ：賃金の引き上げ  
  
イ  Ａ：労働集約財の輸出拡大Ｂ：賃金の引き下げ  
  
ウ  Ａ：労働集約財の輸入拡大Ｂ：賃金の引き上げ  
  
エ  Ａ：労働集約財の輸入拡大Ｂ：賃金の引き下げ  
`
        },
        {
                "mondaiId": 101210030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、最終需要項目\u0002単位の増加がどれだけ生産を誘発するかを、大企業と中  
小企業に分けて表したものである。この図から、民間消費や公的固定資本形成の拡  
大による生産誘発効果は、大企業よりも中小企業のほうが大きいことがわかる。  
しかし、近年、中小企業が依存する民間消費は伸び悩み、それが中小企業の利益  
率を低迷させていることが指摘されている。その理由として、最も適切なものの組  
み合わせを下記の解答群から選べ。  
出所：中小企業庁『中小企業白書２００８年版』  
ａ家計部門の所得は急速に増加しているが、公共事業の縮小に伴う景気へのマイ  
ナス効果が生じている。  
ｂ正規雇用から非正規雇用への雇用形態のシフトに伴い、賃金を押し下げる要因  
が作用している。  
ｃ賃金が下降傾向にあり、家計部門の所得の伸び悩みから消費拡大のスピードが  
緩慢になっている。  
ｄ労働市場が\u0003迫し賃金は上昇傾向にあるが、家計部門の貯蓄の増大が著しい。  
〔解答群〕  
  
ア  ａとｂイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101210041,
                "name": "第4問設問1",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の均衡GDPの決定および変動に関する文章を読んで、下記の設問に答えよ。  
右の図は、均衡GDPの決定を表したものである。  
いま、総需要ADが消費支出C、投資支出I、政府支出G、貿易収支（輸出Xマ  
  
イ  ナス輸入M）から構成される経済モデルを想定する。  
AD＝C＋I＋G＋X－M  
また、消費関数、投資関数、輸入関数はそれぞれ、  
C＝C０＋c（Y－T０）  
I＝I０－ir  
M＝M０＋mY  
として与えられる。各記号は、Y：GDP、C０：独立消費、c：限界消費性向（\u0002＜c  
＜\u0003）、T０：租税収入（定額税）、I０：独立投資、i：投資の利子感応度、r：利子  
率、M０：独立輸入、m：限界輸入性向（m＞\u0002、c＞m）である。なお、政府支出  
G、輸出Xは与件であり、おのおのG＝G０、X＝X０とする。利子率も与件であ  
り、r＝r０とする。  
このとき、総需要線は  
AD＝C０＋c（Y－T０）＋I０－ir０＋G０＋X０－M０－mY  
である。  
他方、図中の４５度線はY＝ADを描いた直線である。  
ここで、  
\u0002  
総需要線ADと４５度線の交点において生産物市場が均衡し、均衡GDP  
はY＊の水準に決定される。  
\u0003  
独立投資や輸出などの変化は乗数効果を通じて、均衡  
GDPの水準に影響を及ぼすことになる。  
        
（設問1））  
文中の下線部\u0002について、総需要線ADの説明として、最も適切なものの組み  
合わせを下記の解答群から選べ。  
ａ総需要線の傾きは、限界貯蓄性向と限界輸入性向の差に等しい。  
ｂ政府支出の拡大と増税が同じ規模で実施された場合、総需要線の位置は変わ  
らない。  
ｃ投資の利子感応度がゼロの場合、利子率が低下しても総需要線の位置は変わ  
らない。  
ｄ独立輸入の増加は、総需要線を下方にシフトさせる。  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
  
オ  ｃとｄ  
`
        },
        {
                "mondaiId": 101210042,
                "name": "第4問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、輸出の変化に伴う外国貿易乗数として最も適切なも  
のはどれか。  
  
ア    
\u0003  
１－c  
  
イ    
\u0003  
１－c＋m  
  
ウ    
\u0003  
１－c－m  
  
エ    
\u0003  
c－m  
`
        },
        {
                "mondaiId": 101210050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の景気動向指数に関する文章中の空欄ＡおよびＢに当てはまる最も適切なもの  
の組み合わせを下記の解答群から選べ。  
景気動向指数は、景気の現状分析や見通し、景気の局面や転換点の確認を行う上  
で有効な指標である。このうち、先行系列は景気の予測に用いられ、Ａ、  
東証株価指数などが含まれる。一致系列は景気の現状を見る上で活用され、生産指  
数（鉱工業）、Ｂ、中小企業売上高（製造業）などが含まれる。さらに、遅行  
系列は景気の局面を把握するために用いられ、常用雇用指数（製造業）などが含まれ  
ている。  
〔解答群〕  
  
ア  Ａ：稼働率指数（製造業）  
Ｂ：新設住宅着工床面積  
  
イ  Ａ：実質機械受注（船舶・電力を除く民需）  
Ｂ：稼働率指数（製造業）  
  
ウ  Ａ：新設住宅着工床面積  
Ｂ：実質機械受注（船舶・電力を除く民需）  
  
エ  Ａ：有効求人倍率（除学卒）  
Ｂ：実質機械受注（船舶・電力を除く民需）  
`
        },
        {
                "mondaiId": 101210060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
いま、マネーサプライ（またはマネーストック）Mが流通現金通貨Cと預金Dか  
ら構成され、  
M＝C＋D  
とする。  
また、ハイパワードマネーHは流通現金通貨Cと準備預金（日銀預け金）Rから  
構成される。  
H＝C＋R  
このとき、マネーサプライとハイパワードマネーとの間には、  
M＝  
C＋D  
C＋R  
H……\u0002  
が成立し、分数部分について分母および分子を預金Dで割ると、  
M＝  
C  
D  
＋\u0002  
C  
D  
＋  
R  
D  
H……\u0003  
である。  
このうち、  
C  
D  
を現金―預金比率、  
R  
D  
を準備率とする。\u0003式は、貨幣乗数を通  
じたマネーサプライとハイパワードマネーとの関係を表している。なお、過剰準備  
が存在せず、準備率は法定準備率に等しいと仮定する。  
このとき、\u0003式の説明として最も適切なものの組み合わせを下記の解答群から選  
べ。  
ａ買いオペは、ハイパワードマネーを増加させ、マネーサプライの増加を生じさ  
せる。  
ｂ貨幣乗数は\u0002より大きい。  
ｃ公定歩合の引き下げは、ハイパワードマネーの減少を通じてマネーサプライの  
減少を引き起こす。  
ｄハイパワードマネーが一定のもとで法定準備率を引き下げると、貨幣乗数が低  
下しマネーサプライは減少する。  
        
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｃ  
  
ウ  ａとｄ  
  
エ  ｂとｃ  
  
オ  ｂとｄ`
        },
        {
                "mondaiId": 101210070,
                "name": "第7問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章中の空欄に入る最も適切なものを下記の解答群から選べ。  
古典派の貨幣数量説において、「貨幣の中立性」が成り立つ場合、名目貨幣供給が  
n倍になれば、もn倍になる。  
〔解答群〕  
  
ア  雇用量  
  
イ  実質GDP  
  
ウ  実質貨幣供給  
  
エ  実質政府支出  
  
オ  物価水準  
`
        },
        {
                "mondaiId": 101210081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のIS―LM分析に関する文章を読んで、下記の設問に答えよ。  
下図は、生産物市場の均衡を表すIS曲線と、貨幣市場の均衡を表すLM曲線を  
描いている。  
まず、生産物市場の均衡条件は  
Y＝C＋I＋G  
で与えられる。ここで、Y：GDP、C：消費支出、I：投資支出、G：政府支出であ  
る。  
消費関数は  
C＝C０＋c（Y－T０）  
であり、C０：独立消費、c：限界消費性向（\u0002＜c＜\u0003）、T０：租税収入（定額税）で  
ある。  
また、投資関数は  
I＝I０－ir  
であり、I０：独立投資、i：投資の利子感応度、r：利子率である。  
さらに、政府支出は与件であり、G＝G０とする。  
        
この結果、IS曲線は  
r＝－  
１－c  
i  
Y＋  
C０－cT０＋I０＋G０  
i  
として導出される。  
\u0002  
この式はIS曲線の傾きや位置を示すものである。  
次に、貨幣市場の均衡条件は  
M＝L  
である。ここで、M：貨幣供給、L：貨幣需要である。  
貨幣需要関数は  
L＝kY－hr  
で与えられ、k：貨幣需要の所得感応度、h：貨幣需要の利子感応度である。  
また、貨幣供給は与件であり、M＝M０とする。  
これらから、LM曲線が導出され、  
r＝  
k  
h  
Y－  
M０  
h  
として示される。  
\u0003  
この式はLM曲線の傾きや位置を表している。  
（設問1））  
文中の下線部\u0002について、IS曲線の特徴に関する説明として、最も適切なも  
のの組み合わせを下記の解答群から選べ。  
ａIS曲線より右側の領域では、生産物市場は超過供給の状態にある。  
ｂ限界貯蓄性向が大きいほど、IS曲線はより緩やかな形状で描かれる。  
ｃ政府支出の拡大と増税が同じ規模で実施された場合、IS曲線の位置は変わ  
らない。  
ｄ投資の利子感応度が小さいほど、IS曲線はより急な形状で描かれる。  
〔解答群〕  
  
ア  ａとｂイａとｃウａとｄ  
  
エ  ｂとｃオｃとｄ  
`
        },
        {
                "mondaiId": 101210082,
                "name": "第8問設問2",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、LM曲線の特徴に関する説明として、最も適切なも  
のの組み合わせを下記の解答群から選べ。  
ａLM曲線より下方の領域では、貨幣市場は超過供給の状態にある。  
ｂ貨幣供給の増加はLM曲線を上方にシフトさせる。  
ｃ貨幣需要の利子感応度が大きいほど、LM曲線はより緩やかな形状で描かれ  
る。  
ｄ貨幣需要の利子感応度がゼロの場合、LM曲線は垂直に描かれ、GDPの水  
準は貨幣市場から決定される。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃ  
  
エ  ｂとｄオｃとｄ  
`
        },
        {
                "mondaiId": 101210090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の消費決定に関する文章中の空欄ＡおよびＢに当てはまる最も適切なものの組み合わせを下記の解答群から選べ。  

　恒常所得仮説では、（　Ａ　）は消費の拡大に影響を与えないと考える。また、ライフサイクル仮説では生涯所得の増加が消費の拡大に寄与するとされる。さらに、消費習慣仮説では、現在の消費は過去に到達した生活水準に依存すると考え、所得が落ち込んだとしても消費は即座に減少しない。これを（　Ｂ　）効果と呼ぶ。

〔解答群〕  
  
ア  Ａ：１回限りの減税の実施　Ｂ：ラチェット<hint hidden='true'>**
⭕ラチェット効果については下図参照**  
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】
<a href="assets/img/kaisetu/101003.png" target="_blank" >
<img src="assets/img/kaisetu/101003.png" alt="ネットワーク接続を確認ください"></a>
</div>
</hint>  
  
イ  Ａ：<hintTarget>月給の増加</hintTarget><hint hidden='true'>**
←❌恒久所得に相当するので影響を与えると考えます
** </hint>　Ｂ：スルツキー  
  
ウ  Ａ：<hintTarget>恒久</hintTarget>減税の実施<hint hidden='true'>**
←❌恒久所得に相当するので影響を与えると考えます
** </hint>　Ｂ：デモンストレーション  
  
エ  Ａ：宝くじの賞金獲得　Ｂ：ヴェブレン  
`
        },
        {
                "mondaiId": 101210100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
次の輸入関税と生産補助金の効果に関する文章を読んで、経済厚生分析の説明と  
して、最も不適切なものを下記の解答群から選べ。  
下図は、輸入競争財市場（たとえば農産物）を描いたものである。いま、当該財の  
国内需要曲線がDDで、国内供給曲線がS１S２で描かれている。ここで、自国は  
「小国」であり、当該財の国際価格をPfとする。自由貿易下での消費量はQ１、生産  
量はQ２であり、輸入量は（Q１－Q２）に等しい。  
ここで、当該財の輸入にTの関税を賦課した場合、国内価格はPd（＝Pf＋T）に  
上昇する。この結果、消費量はQ３に減少し、生産量はQ４に増加する。他方、生  
産補助金を交付することで輸入関税の場合と同じ生産量Q４を実現することも可能  
である。このとき、生産補助金の交付により、国内供給曲線はS１S２からS３S４に  
平行移動する。  
        
〔解答群〕  
  
ア  生産者に対する補助金交付額は、四角形S１HIS３に相当する。  
  
イ  生産補助金交付時の経済余剰の損失は、三角形EFGになる。  
  
ウ  生産補助金交付時の生産者余剰は、三角形PfIS３に当たる。  
  
エ  輸入関税下における経済余剰の損失は、三角形EFGと三角形HIJの和に  
なる。`
        },
        {
                "mondaiId": 101210110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
下表は、\u0002国（日本とアメリカ）・\u0002財（X財とY財）モデルを前提として、各国  
におけるX財とY財の生産費用をそれぞれの通貨で表示したものである。いま、  
完全競争と自由貿易を仮定し、価格＝費用が成立すると考える。現在、日本では、  
X財\u0003単位が５００円、Y財\u0003単位が１，０００円で生産され、アメリカでは、X財\u0003単  
位が１０ドル、Y財\u0003単位が\u0004ドルで生産されている。  
このとき、下表から得られる説明として、最も適切なものの組み合わせを下記の  
解答群から選べ。  
日本アメリカ  
X財\u0003単位の生産費用５００円��０ドル  
Y財\u0003単位の生産費用１，０００円５ドル  
ａアメリカはX財、日本はY財にそれぞれ比較優位を持つ。  
ｂ為替レートが\u0003ドル＝５０円から\u0003ドル＝２００円の範囲内に決まれば、比較優  
位に基づく貿易が可能となる。  
ｃ為替レートが\u0003ドル＝２５０円の場合、日本はX財とY財をともに輸入するこ  
とになる。  
ｄ日本のX財とY財の価格がともに\u0002倍になれば、比較優位に基づく貿易を可  
能とする為替レートは\u0003ドル＝１００円から\u0003ドル＝４００円の範囲内に決まる必要  
がある。  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101210120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
各事業者の効率化の度合いを相対的に評価し、効率的な事業者を基準に、非効率  
的な事業者に対して適正な目標値まで効率化を進めるよう促す競争原理の考え方が  
ある。この考え方は、規制下に置かれた産業への競争促進策として、これまで活用  
されてきた。この競争原理を表す言葉として最も適切なものはどれか。  
  
ア  クリームスキミング  
  
イ  モラルハザード  
  
ウ  ヤードスティック  
  
エ  レントシーキング`
        },
        {
                "mondaiId": 101210131,
                "name": "第13問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
生産関数ならびに総費用関数について、以下の設問に答えよ。  
（設問1））  
下図のように実線で表される生産関数を持つ企業を考える。最も生産効率の高  
い点はどれか。最も適切なものを下記の解答群から選べ。  
〔解答群〕  
  
ア  a点イb点ウc点エd点  
`
        },
        {
                "mondaiId": 101210132,
                "name": "第13問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
次に、下図のように実線で表される総費用関数を持つ企業を考える。ここで、  
総収入線が太い直線αで表されている。利潤の観点から最適な点はどれか。最も  
適切なものを下記の解答群から選べ。  
〔解答群〕  
  
ア  A点  
  
イ  B点  
  
ウ  C点  
  
エ  D点  
`
        },
        {
                "mondaiId": 101210140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
人々は、さまざまな状況においてサーチ（探索）を行う。安い価格を提示するガソ  
リンスタンド探し、魅力的な就職先探しなどである。このようなサーチのコストに  
関する記述として、最も適切なものの組み合わせを下記の解答群から選べ。  
ａサーチコストが高いときには、サーチ対象となる商品の価格分散が大きくなる。  
ｂサーチコストが高いときには、サーチ対象となる商品の価格分散が小さくなる。  
ｃ商品の価格分散が大きいほど、よりサーチコストをかけるインセンティブが働く。  
ｄ商品の価格分散が小さいほど、よりサーチコストをかけるインセンティブが働く。  
〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ`
        },
        {
                "mondaiId": 101210150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
サブプライムローンを組み込んだ金融商品は、そのリスクが十分に認識されない  
まま、高いリターンを生むものとして積極的に販売された。販売する側にとって、  
リスク性の認識にかかわらず売れる商品を積極的に販売することは合理的な選択で  
あった。しかし、そのような金融商品の販売量が増大して、リスクが経済全体に蓄  
積していることがひとたび認識されるようになると、経済全体に大きな悪影響を与  
えるようになった。このように、ミクロ（企業行動）の視点では正しいとしても、そ  
れがマクロ（集計量）の世界では意図しない結果が生じることを表す言葉として最も  
適切なものはどれか。  
  
ア  合成の誤謬  
  
イ  合理的期待形成  
  
ウ  シグナリング効果  
  
エ  スピルオーバー効果  
`
        },
        {
                "mondaiId": 101210160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
不景気になると、規模の経済性を獲得するための水平的な企業合併が盛んになる  
ことが予想される。独占禁止法に照らして、水平的な企業合併が認められる際の判  
断基準となるのが、競争を実質的に制限することになるかどうかである。これに関  
連し、独占の程度に関する指標としてハーフィンダール・ハーシュマン指数（HHI）  
がある。各企業の市場占有率を小数点以下の数値（例えば、０．２）で表すと、HHIの  
とりうる範囲は\u0002から\u0003の間の数値となる。このHHIに関する記述として最も適  
切なものの組み合わせを下記の解答群から選べ。  
ａHHIは、当該業界のすべての企業の市場占有率の\u0004乗和で表される。  
ｂHHIは、当該業界の上位\u0005社の市場占有率の\u0004乗和で表される。  
ｃ独占状態に近ければ近いほど、HHIの値は\u0003に近づく。  
ｄ独占状態に近ければ近いほど、HHIの値は\u0002に近づく。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101210171,
                "name": "第17問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。  
株価下落や不良債権増大に直面した銀行は、中小企業を含む民間企業への貸し出  
しを減らす必要に迫られる。健全な企業に対しても銀行側の都合で貸し出しが減少  
するなど、貸し渋り、貸しはがしのような社会問題が発生した。  
このような銀行の行動の背景には、銀行が国際取引を行う際の自己資本比率に関  
する規制がある。その規制は、「自己資本比率が\u0002％以上でなければ、銀行は国際  
的な業務ができない」というものである。  
以下では、自己資本比率に関する規制について、次のような単純な構造を考え  
る。  
自己資本比率＝自己資本÷貸し出し  
自己資本＝基本項目＋補完項目  
基本項目：資本金＋法定準備金＋剰余金など  
補完項目：株式・社債の含み益（その４５％を上限）など  
ただし、補完項目の合計が基本項目の合計を超えてはならない。  
ここで、次のような単純化された資本構成の銀行を考える。  
基本項目は、３６億円。  
補完項目は、株式・社債の含み益のみで、その額は８０億円。  
貸し出しは、民間貸し出しのみ９００億円。  
この場合、自己資本比率は\u0002％である。  
自己資本比率＝｛３６＋８０×（４５／１００）｝÷９００  
＝０．０８  
        
（設問1））  
株価下落などにより、株式・社債の含み益が半分の４０億円に低下した場合、  
自己資本比率は何％になるか。最も適切なものを選べ。  
  
ア  \u0003％  
  
イ  \u0004％  
  
ウ  \u0005％  
  
エ  \u0006％  
  
オ  \u0007％`
        },
        {
                "mondaiId": 101210172,
                "name": "第17問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問3）  
株価下落などにより、株式・社債の含み益が半分の４０億円に低下した場合、  
％の自己資本比率に関する規制を達成するためには、民間貸し出しを今より  
どれほど減らす必要があるか。最も適切なものを選べ。  
  
ア  ９０億円  
  
イ  １３５億円  
  
ウ  １８０億円  
  
エ  ２２５億円  
`
        },
        {
                "mondaiId": 101210181,
                "name": "第18問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
リスク選好度に関する下記の設問に答えよ。  
（設問1））  
下図の曲線は、ある消費者の所得と効用水準の関係を表したものである。この  
消費者のリスク選好度とリスクプレミアムに関し、最も適切なものの組み合わせ  
を下記の解答群から選べ。ここで、リスクプレミアムの値は、この個人が合理的  
に期待する所得を保障する保険に対して支払ってもよいと考える保険料の額を表  
す。  
ａこの消費者はリスク愛好的である。  
ｂこの消費者はリスク回避的である。  
ｃリスクプレミアムは負の値をとる。  
ｄリスクプレミアムは正の値をとる。  
〔解答群〕  
  
ア  ａとｃ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 101210182,
                "name": "第18問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
不況に直面したとき、企業は雇用を削減する必要に迫られる。労働者は解雇さ  
れれば大幅な所得減に直面するが、解雇されなければこれまでと同等の所得水準  
を維持できる。したがって、不況下では労働者は所得水準の変動リスクに直面す  
ることになる。一方、ワークシェアリングでは、すべての労働者が解雇されるわ  
けではないものの、一定水準の所得減となる。リスク選好度に関して最も適切な  
ものはどれか。  
  
ア  リスク愛好的な労働者はワークシェアリングを好む。  
  
イ  リスク回避的な労働者はワークシェアリングを好む。  
  
ウ  リスク中立的な労働者はワークシェアリングを好む。  
  
エ  リスクの選好度にかかわらず、どの労働者もワークシェアリングを好まな  
い。`
        },
        {
                "mondaiId": 101210190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
企業は、ある研究開発プロジェクトに関し、本格的な投資の意思決定の時期を遅  
らせ、プロジェクトの進捗状況などの不確実性が軽減された段階で、意思決定を行  
うケースがある。このケースでは、行おうとしていた投資のリターンが予想された  
ほど高くないと判明した場合、投資を差し控えることができる。すなわち、ダウン  
サイドのリスクをゼロにして、アップサイドの利益だけを享受できる。このような  
考え方を表す言葉として最も適切なものはどれか。  
  
ア  機会主義  
  
イ  ヘッジ  
  
ウ  リアルオプション  
  
エ  リスクシェア  
`
        },
        {
                "mondaiId": 101210200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
ある合理的な消費者がX財とY財を消費するとしよう。X財とY財は、ともに  
正常財である。ある価格の組み合わせのもと、下図では予算制約式Aが表わされ  
ている。一方、別の価格の組み合わせで予算制約式Bが表されている。予算制約  
式Aにおいて、この消費者は最も高い効用をもたらす点としてc点を選んだ。こ  
の消費者にc点より高い効用を与える可能性のあるX財とY財の消費量の組み合  
わせを表す点はどれか。最も適切なものを下記の解答群から選べ。  
〔解答群〕  
  
ア  a点  
  
イ  b点  
  
ウ  d点  
  
エ  e点  
`
        },
        {
                "mondaiId": 102210010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
期末の決算整理前残高試算表と決算整理事項（単位：千円）は次のとおりである。  
当期の純損益として、最も適切なものを下記の解答群から選べ（単位：千円）。  
決算整理前残高試算表  
（単位：千円）  
借方勘定科目貸方  
５，０００現金  
１５，０００当座預金  
３０，０００売掛金  
貸倒引当金１，０００  
６，０００繰越商品  
１２，０００備品  
備品減価償却累計額５，４００  
買掛金７，６００  
借入金１８，０００  
資本金４０，０００  
売上６８，０００  
５７，０００仕入  
１１，０００給料  
３，０００支払家賃  
１，０００支払利息  
１４０，０００１４０，０００  
決算整理事項：  
\u0002商品の期末たな卸高は８，０００である。  
\u0003売掛金の残高に対して\u0002％の貸倒引当金を設定する。  
\u0004備品（耐用年数\u0003年、残存価額は取得原価の１０％、取得後\u0002年間経過）の減  
価償却を定額法により行う。  
\u0005家賃の前払い額は４００、利息の未払い額は２００である。  
〔解答群〕  
  
ア  損失３，８００イ損失１０，６００  
  
ウ  利益９，０００エ利益１３，２００  
`
        },
        {
                "mondaiId": 102210020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
損益に関するＡ群とＢ群の用語の組み合わせとして、最も適切なものを下記の解  
答群から選べ。  
【Ａ群】  
\u0002売上値引  
\u0003売上割引  
\u0004売上割戻  
【Ｂ群】  
ａ総売上高の控除項目  
ｂ販売費  
ｃ営業外費用  
〔解答群〕  
  
ア  \u0002とｂ  
  
イ  \u0003とａ  
  
ウ  \u0003とｃ  
  
エ  \u0004とｂ  
`
        },
        {
                "mondaiId": 102210030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
期末現在において保有する有価証券の内訳は次のとおりである。貸借対照表の純  
資産の部に計上されるその他有価証券評価差額として、最も適切なものを下記の解  
答群から選べ（単位：千円）。  
（単位：千円）  
銘柄取得原価時価評価額備考  
A社株式１，８００２，１００売買目的で保有  
B社社債１，７００１，８００満期保有目的で保有  
C社株式６，０００６，１００関連会社株式  
D社株式４，０００４，６００持ち合い株式  
E社株式３，５００１，０００  
持ち合い株式、時価の著しい下落の  
回復は不明  
〔解答群〕  
  
ア  －２，０００  
  
イ  －１，８００  
  
ウ  ５００  
  
エ  ６００  
`
        },
        {
                "mondaiId": 102210040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
税引前当期純利益（または税引前当期純損失）に必要な調整項目を加減する方法  
（間接法）により営業活動によるキャッシュ・フローを表示する場合に、加算される  
項目として最も適切なものはどれか。  
  
ア  損益計算書に収益として計上されている項目のうち、資金の増加を伴う項目  
  
イ  損益計算書に収益として計上されている項目のうち、投資活動によるキャッ  
シュ・フローの区分に含まれない項目  
  
ウ  損益計算書に費用として計上されている項目のうち、資金の減少を伴わない項  
目  
  
エ  損益計算書に費用として計上されている項目のうち、投資活動によるキャッ  
シュ・フローの区分にも財務活動によるキャッシュ・フローの区分にも含まれな  
い項目`
        },
        {
                "mondaiId": 102210050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業の会計に関する次の文章の空欄ＡとＢに入る語句の組み合わせとして、  
最も適切なものを下記の解答群から選べ。  
中小企業が、資金調達先の多様化や取引先の拡大等も見据えて、会計の質の向上  
を図る取り組みを促進する一環として、Ａが平成１７年に公表された。そ  
の後、平成１８年に会社法、会社法施行規則および会社計算規則の制定に伴い見直  
され、それ以降毎年改正が行われている。Ａは、中小企業が、計算書類の  
作成に当たり、拠ることがB会計処理や注記等を示すものである。  
〔解答群〕  
  
ア  Ａ：中小会社会計基準Ｂ：義務付けられる  
  
イ  Ａ：中小会社会計基準Ｂ：望ましい  
  
ウ  Ａ：中小企業の会計に関する指針Ｂ：義務付けられる  
  
エ  Ａ：中小企業の会計に関する指針Ｂ：望ましい  
`
        },
        {
                "mondaiId": 102210060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当社は個別原価計算制度を採用している。原価計算表および製造・販売状況、製  
造勘定、製品勘定は以下のとおりである。直接材料費と直接労務費の合計額に基づ  
いて製造間接費を配賦するとき、当月の製品製造原価と売上原価の組み合わせとし  
て、最も適切なものを下記の解答群から選べ（単位：千円）。  
原価計算表  
（単位：千円）  
＃９１＃９２＃９３合計  
前月繰越３，８００（）（）（）  
直接材料費４００（）１，６００（）  
直接労務費６００１，４００（）（）  
製造間接費（）１，４００１，４００３，２００  
合計（）（）（）（）  
製造・販売状況：  
製造指図書＃９０：前月完成（製品製造原価３，４００千円）、当月引渡し  
製造指図書＃９１：前月着手、当月完成、当月引渡し  
製造指図書＃９２：前月着手、当月未完成  
製造指図書＃９３：当月着手、当月完成、次月引渡し予定  
製造（単位：千円）製品（単位：千円）  
前月繰越６，２００（）（）前月繰越３，４００（）（）  
直接材料費４，１００次月繰越（）（）（）次月繰越（）  
直接労務費３，９００（）（）  
製造間接費（）  
（）（）  
〔解答群〕  
  
ア  製品製造原価１０，１００売上原価８，２００  
  
イ  製品製造原価１０，１００売上原価８，６００  
  
ウ  製品製造原価１１，２００売上原価９，３００  
  
エ  製品製造原価１１，２００売上原価９，７００  
`
        },
        {
                "mondaiId": 102210071,
                "name": "第7問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当期と前期との比較貸借対照表（要約）と比較損益計算書（要約）は次のとおりであ  
る。これらに基づいて以下の設問に答えよ。  
比較貸借対照表（要約）  
（単位：百万円）  
資産前期当期負債・純資産前期当期  
現金預金６０３０支払手形３０３０  
受取手形４０６０買掛金７０６０  
売掛金８０１００短期借入金４０５０  
有価証券２０２０長期借入金６０１００  
たな卸資産５０８０資本金１００１００  
有形固定資産１２０１３０資本剰余金２０３０  
無形固定資産３０２０利益剰余金８０７０  
合計４００４４０合計４００４４０  
（注）前期期首資産合計は３８０百万円である。  
比較損益計算書（要約）  
（単位：百万円）  
科目前期当期  
売上高４００４２０  
売上原価１８０１９０  
売上総利益２２０２３０  
販売費及び一般管理費１００１２０  
営業利益１２０１１０  
営業外収益１０１０  
営業外費用３０２０  
経常利益１００１００  
特別利益１０１０  
特別損失２０１０  
税引前当期純利益９０１００  
法人税等３６４０  
当期純利益５４６０  
        
（設問1））  
収益性について前期と当期を比較した記述として、最も適切なものはどれか。  
  
ア  売上高経常利益率と総資本回転率が上昇したため、総資本経常利益率が上昇  
した。  
  
イ  売上高経常利益率と総資本回転率が低下したため、総資本経常利益率が低下  
した。  
  
ウ  売上高経常利益率は上昇したが総資本回転率が低下したため、総資本経常利  
益率が低下した。  
  
エ  売上高経常利益率は低下したが総資本回転率が上昇したため、総資本経常利  
益率が上昇した。`
        },
        {
                "mondaiId": 102210072,
                "name": "第7問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
流動性について前期と当期を比較した記述として、最も適切なものはどれか。  
  
ア  固定長期適合率は悪化し、負債比率も悪化した。  
  
イ  固定長期適合率は良化したが、負債比率は悪化した。  
  
ウ  流動比率は悪化したが、負債比率は良化した。  
  
エ  流動比率は良化し、負債比率も良化した。  
`
        },
        {
                "mondaiId": 102210080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
当期の売上高と費用の内訳は次のとおりである。安全余裕率として、最も適切な  
ものを下記の解答群から選べ。  
数量（個）単価（円）金額（円）  
売上高１０，０００８００８，０００，０００  
変動費５００５，０００，０００  
固定費－２，７００，０００  
〔解答群〕  
  
ア  ５％  
  
イ  １０％  
  
ウ  １５％  
  
エ  ２５％`
        },
        {
                "mondaiId": 102210090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料に基づく販売数量の変化による売上高の増減額として、最も適切なもの  
を下記の解答群から選べ（単位：円）。  
前期当期増減  
売上高２８８，０００円３０１，０００円１３，０００円  
販売数量４００kg４３０kg３０kg  
販売価格７２０円７００円－２０円  
〔解答群〕  
  
ア  －８，６００  
  
イ  ６００  
  
ウ  １５，０００  
  
エ  ２１，６００  
`
        },
        {
                "mondaiId": 102210101,
                "name": "第10問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
Y社の以下に掲げる次年度の部門別損益計算書に基づいて、下記の設問に答え  
よ。ただし、費用の構造は一定とする。  
（単位：百万円）  
A部門B部門C部門合計  
売上高１，８００１，２００１，０００４，０００  
変動費１，０８０８４０５８０２，５００  
個別固定費２４０２２０１００５６０  
共通固定費配賦額２８０２４０１２０６４０  
純利益（）（）（）３００  
（設問1））  
Y社全体に対するA部門の貢献を示す利益額として、最も適切なものはどれ  
か（単位：百万円）。  
  
ア  １４０  
  
イ  ２００  
  
ウ  ３２０  
  
エ  ４８０`
        },
        {
                "mondaiId": 102210102,
                "name": "第10問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
仮にB部門を廃止するとすれば、Y社全体の純利益の増減額として最も適切  
なものはどれか（単位：百万円）。ただし、共通固定費は発生を回避することがで  
きないものとする。  
  
ア  減少１４０  
  
イ  減少９４０  
  
ウ  増加１００  
  
エ  増加３２０  
`
        },
        {
                "mondaiId": 102210110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
次年度の売上高と仕入高の四半期別予算額、その内訳および代金の決済条件は次  
のとおりである。第\u0002四半期の売上収入（現金売上高および売掛金回収高）と仕入支  
出（買掛金支払高）の差額として、最も適切なものを下記の解答群から選べ（単位：  
千円）。  
（単位：千円）  
第\u0003四半期第\u0002四半期  
売  
上  
高  
現金売上高１６，０００１２，０００  
掛売上高２４，０００１８，０００  
計４０，０００３０，０００  
掛仕入高２４，０００４０，０００  
代金の決済条件：  
\u0002売掛金は発生額の５０％が商品を販売した期（四半期）に回収され、残りが次の  
期（四半期）に回収される。  
\u0003買掛金は発生額の７０％が商品を仕入れた期（四半期）に支払われ、残りが次の  
期（四半期）に支払われる。  
〔解答群〕  
  
ア  １，６００（支出超過）  
  
イ  ２，２００（支出超過）  
  
ウ  １６，２００（収入超過）  
  
エ  ２０，２００（収入超過）  
`
        },
        {
                "mondaiId": 102210120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
資金調達手段の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  株式発行による調達は、長期資金であり外部金融である。  
  
イ  企業間信用による調達は、長期資金であり外部金融である。  
  
ウ  減価償却による調達は、長期資金であり内部金融である。  
  
エ  利益の内部留保による調達は、長期資金であり内部金融である。`
        },
        {
                "mondaiId": 102210130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
A社の普通株式の次期の配当は、\u0002株当たり５０円と予想されている。配当の成  
長率が今後\u0003％で永久に継続すると期待されている。A社の現在の株価が１，０００円  
であるとき、A社の普通株式の資本コストとして、最も適切なものはどれか。  
  
ア  ５％  
  
イ  ５．４％  
  
ウ  ８％  
  
エ  １３％  
`
        },
        {
                "mondaiId": 102210140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
以下の文章は、株式分割について述べたものである。文中の空欄Ａ～Ｄに入る語  
句の組み合わせとして、最も適切なものを下記の解答群から選べ。  
完全市場を仮定するとき、株式分割によって\u0002株当たり株価はＡ。なぜ  
なら、株式分割によって株式数は増加するが、株主の持分割合はＢ、ま  
た、株式分割は企業の資産内容やキャッシュ・フローに影響をＣため、株  
主の富がＤからである。  
〔解答群〕  
  
ア  Ａ：下落するＢ：減少しＣ：与えるＤ：減少する  
  
イ  Ａ：下落するＢ：変化せずＣ：与えないＤ：変化しない  
  
ウ  Ａ：上昇するＢ：変化せずＣ：与えるＤ：増加する  
  
エ  Ａ：変化しないＢ：減少しＣ：与えないＤ：減少する  
`
        },
        {
                "mondaiId": 102210151,
                "name": "第15問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
次の文章を読んで、下記の設問に答えよ。  
B社は現在、普通株式と社債によって資金調達を行っており、それぞれの時価は  
以下のとおりである。  
（単位：万円）  
時価  
普通株式５，０００  
社債３，０００  
また、投資家は現在、普通株式には１３％、社債には\u0002％の収益率を要求してい  
る。なお、税金はないものと仮定する。  
（設問２））  
B社の加重平均資本コストとして、最も適切なものはどれか。  
  
ア  \u0004％イ１０％ウ１３％エ１８％`
        },
        {
                "mondaiId": 102210152,
                "name": "第15問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
B社は、３，０００万円の普通株式を発行し、社債を償還することを検討中であ  
る。社債が償還された場合、普通株式の要求収益率はどのようになるか。最も適  
切なものを選べ。  
  
ア  \u0002％イ\u0006％ウ１０％エ１３％  
`
        },
        {
                "mondaiId": 102210160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
C社では、工場拡張投資を計画中である。この投資案の初期投資額は、４，０００万  
円である。計画では、この投資により今後毎年売上高が２，４００万円増加し、現金支  
出費用が１，２００万円増加する。この投資物件の耐用年数は\u0002年であり、残存価額は  
ゼロである。減価償却法として定額法を用いており、実効税率は５０％であるとす  
る。なお、運転資金の額は変化しないものとする。  
資本コストが１０％であるとき、この投資案の正味現在価値として、最も適切な  
ものを下記の解答群から選べ（単位：万円）。なお、現価係数は下表のとおりであ  
る。  
複利現価係数（１０％，\u0002年）年金現価係数（１０％，\u0002年）  
０．６２３．７９  
〔解答群〕  
  
ア  ５４８  
  
イ  －２１０  
  
ウ  －２８０  
  
エ  －９００  
`
        },
        {
                "mondaiId": 102210170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
リスク資産に加え、リスクフリーレートで自由に借り入れと貸し出しができる場  
合、投資機会集合の効率的フロンティアを表す曲線として最も適切なものを下記の  
解答群から選べ。  
〔解答群〕  
  
ア  曲線ABCイ曲線ABEウ曲線DBCエ曲線DBE`
        },
        {
                "mondaiId": 102210180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
株式Gの投資収益率について、以下のデータが得られている。このとき、株式  
Gのベータ係数として、最も適切な値を下記の解答群から選べ。  
標準偏差マーケット・ポートフォリオとの共分散  
株式G２０％０．０１５  
マーケット・ポートフォリオ１０％０．０１  
〔解答群〕  
  
ア  ０．１５イ０．３ウ０．７５エ１．５  
`
        },
        {
                "mondaiId": 102210191,
                "name": "第19問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
次の図は、ヨーロピアンタイプのオプション取引を行ったときの損益図表を示し  
ている。この図と以下の文章から、下記の設問に答えよ。  
この図で示される実線\u0002はＡの損益を示しており、破線\u0003はＢ  
の損益を示している。この図から分かるように、Ａの最大損失は  
Ｃに限定されるが、Ｂの損失は、決済時の原資産の価格によって  
無限になる可能性をもっている。  
        
（設問1））  
文中の空欄ＡとＢに入る用語の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：コールオプションの売り手Ｂ：コールオプションの買い手  
  
イ  Ａ：コールオプションの買い手Ｂ：コールオプションの売り手  
  
ウ  Ａ：プットオプションの売り手Ｂ：プットオプションの買い手  
  
エ  Ａ：プットオプションの買い手Ｂ：プットオプションの売り手`
        },
        {
                "mondaiId": 102210192,
                "name": "第19問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｃに入る用語として、最も適切なものはどれか。  
  
ア  オプション契約時における原資産価格  
  
イ  オプション決済時における原資産価格  
  
ウ  オプションプレミアム  
  
エ  権利行使価格  
`
        },
        {
                "mondaiId": 102210201,
                "name": "第20問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
次のZ社の資料と文章に基づいて、下記の設問に答えよ。  
（資料）  
売上高２０億円  
税引後当期利益\u0002億円  
当期支払配当総額１，０００万円  
純資産額１０億円  
発行済株式数１００万株  
株価１，５００円  
上の資料から、Z社のPERはＡ倍と計算され、また、PBRはＢ  
倍と計算される。  
（設問1））  
文中の空欄Ａに入る値として、最も適切なものはどれか。  
  
ア  \u0003イ１０ウ１５エ２０`
        },
        {
                "mondaiId": 102210202,
                "name": "第20問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｂに入る値として、最も適切なものはどれか。  
  
ア  ０．７５イ１．５ウ１５エ１５０  
`
        },
        {
                "mondaiId": 103210011,
                "name": "第1問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
国民経済の主な担い手は営利企業であるが、その活動にはおのずと限界がある。  
特に  
\u0002  
市民社会が求めるサービスは、営利企業以外の活動主体によって提供される方  
がうまくいくことが少なくない。そのような活動主体としてNPO（Non-Profit  
Organization）が数多く存在する。  
また、企業も市民社会の構成単位として、  
\u0003  
社会的責任（CSR）を明確にして経済活  
動を展開することが強く求められており、さまざまな非経済的な活動に取り組んで  
いる。そのこと以上に直接的に企業の社会的責任のあり方が顕在化するのは、  
\u0004  
企業  
の提供する製品やサービスへのクレームやトラブルあるいは不祥事をめぐってであ  
る。発生した問題に対して企業が誠実に適切に対応することは当然なことである  
が、それがうまく行われていない例が数多く見られる。  
\u0005  
社会が期待するように企業  
が自ら進んで責任を果たそうとする姿勢は、その後の企業の存続に直結しかねない  
重大な課題なのである。  
        
（設問1））  
文中の下線部\u0002で指摘されるような企業活動では提供しにくいサービスの発生  
の理由として「市場の失敗」がもたらす非効率性が指摘されている。そのような市  
場の失敗を説明する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  医師と患者の間では医療情報に顕著な差があるため、効率的な医療サービス  
が歪められる可能性が高い。  
  
イ  学校教育では学生が費用を負担し、教育の便益を受けるが、企業は教育の費  
用を負担することなく学生を採用することによって教育の便益を得ることがで  
きるので、放置すれば教育の提供が歪められる。  
  
ウ  企業から発生した公害の解決や費用の負担をNPOや公共団体が担えば、企  
業は生産活動に専念できるようになるので経済効果が高まり、住民の福利厚生  
も増大する。  
  
エ  サービスを受けてもその対価を負担しない受益者を排除することが難しい場  
合、企業はその事業に参入しにくい。  
`
        },
        {
                "mondaiId": 103210012,
                "name": "第1問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002で指摘されるように、企業の社会的責任（CSR）は企業にとって  
は重要な戦略課題になっている。企業のあり方を問い直そうとするCSRへの取  
り組みは、国際的な広がりを示しつつ、大企業のみならず中小企業、さらには企  
業経営者にとっても重要な経営課題になっている。そのようなCSRをめぐる状  
況に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ISO（国際標準化機構）は組織の社会的責任をとりあげ、国際的な倫理規範化  
を目指している。  
  
イ  欧州では１９７６年に経済協力開発機構（OECD）が「OECD多国籍企業ガイドラ  
  
イ  ン」を発表し、さらに２０００年の改訂ではCSRの範囲を雇用、人権、環境、  
情報開示、消費者利益などに広げ、指針を設けて自主的な取り組みを求めてい  
る。  
  
ウ  かつて経済団体連合会は会員企業の経常利益の\u0003％相当額以上の支出や個  
人の寄付を財源にする\u0003％（ワンパーセント）クラブを設立して社会貢献活動を  
行ってきたが、２００２年に日本経済団体連合会に統合する際にその活動は使命  
を終えたとして停止された。  
  
エ  近年、CSRへの取り組みは中小企業にも広がっており、株主、従業員、消  
費者などを超えて、地域社会をも責任の範囲とする考え方が見られ、他方では  
コミュニティ・ビジネスが誕生してきている。  
  
オ  フィランソロピーは博愛的な精神に基づく慈善活動行為であるが、米国では  
富豪によるものが盛んであるのに対して、わが国では企業による社会的貢献活  
動として実施される傾向がある。  
`
        },
        {
                "mondaiId": 103210013,
                "name": "第1問設問3",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002で指摘されるような問題が発生する可能性の高い状況として、  
最も適切なものを下記の解答群から選べ。  
ａ新製品は発売から半年をめどに製品の完成度を高めるようにしているので、  
その間のクレームには技術部門を含めて全社をあげて積極的に応じるが、その  
後は営業部門のみで対応するようにしている。  
ｂ独立行政法人製品評価技術基盤機構（NITE）の事故原因の調査報告や提言に  
注意しつつ、消費生活用製品安全法に基づく指示命令が出るまで企業からの積  
極的な対応は控えることにしている。  
ｃ生産現場で起こったトラブルは直ちに工場長に伝えられ、工場の独立採算性  
を勘案して各工場段階で対応措置がとられるようにしており、本社へはその措  
置のめどが立った段階で報告される。  
〔解答群〕  
  
ア  ａとｂは問題が発生する可能性が高い。  
  
イ  ａとｃは問題が発生する可能性が高い。  
  
ウ  ｂとｃは問題が発生する可能性が高い。  
  
エ  ａとｂとｃはいずれも問題が発生する可能性が高い。  
  
オ  ａとｂとｃはいずれも問題が発生する可能性は低い。  
`
        },
        {
                "mondaiId": 103210014,
                "name": "第1問設問4",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002で指摘されるような企業の社会的責任への対応について述べた  
記述として、<u>最も不適切なものはどれか</u>。  
  
ア  企業は未然にリスクや不祥事等を防ぐべくコンプライアンスを実施するとと  
もに、外部からの予測できない事態に対してはリスク・マネジメントと呼ばれ  
る危機対応を実施することが重要である。  
  
イ  顧客の中に企業のアイデンティティを形成することができれば、企業は良好  
な評判を確立しやすくなるので、日常的にコーポレート・コミュニケーション  
を展開することが重要である。  
  
ウ  人命にかかわるトラブルについては、超正直企業といわれるほどに収益性を  
犠牲にしてでも、従業員ぐるみで製品の修理や回収を徹底することが必要であ  
り、そのためにはトップの強力なリーダーシップが重要である。  
  
エ  品質管理の失敗は、損失に直結するコストとしてばかりでなく、長期的に顧  
客の忠誠心を低下させて、将来の収益を損なう経営リスクとしての認識を全社  
的に促すことが重要である。  
  
オ  不祥事が発生した場合、企業の不祥事への対応と活動方針を社会に伝えるべ  
く利害関係者に広く情報提供することが重要である。  
`
        },
        {
                "mondaiId": 103210020,
                "name": "第2問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
一般に企業はグローバル化するにつれて、海外でも研究開発活動を展開するよう  
になる。このことについて説明する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  海外研究開発拠点の設置は、海外市場や海外生産への依存度が高くなると増え  
る傾向が見られるが、進出先の研究開発力が劣っているとその傾向は弱まる。  
  
イ  企業が海外研究開発拠点を設けるのは、技術移転、海外子会社の要請、現地の  
研究能力や技術の獲得などのためであるが、逆に国内で研究開発の規模の経済が  
大きい場合や技術ノウハウの保護を重視する場合、海外研究開発拠点の開設に消  
極的になりやすい。  
  
ウ  国の差異を利用してグローバルに技術ノウハウを蓄積することによって、研究  
開発のグローバル・シナジーが実現される可能性が高い場合、海外研究開発拠点  
の設置が見られる。  
  
エ  米国企業では既に１９６０年代に海外研究開発拠点の設立が見られるのに対し  
て、日本企業のそれは１９８０年代に入ってから多くなるが、いずれも企業の海外  
進出が本格化したことと関連している。  
  
オ  わが国では研究開発能力の低い産業分野に海外研究開発拠点を設ける例が増加  
しており、近年食品や繊維などの分野の中小企業が海外研究開発拠点を開設する  
傾向が強まっている。  
`
        },
        {
                "mondaiId": 103210030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業界の競争や取引関係は、限られた市場の争奪という側面ばかりではない。逆に  
市場を奪い合わずに自社の売り上げや利益を増やす関係になることもある。そのよ  
うな状況に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  新しい駅ビルに、これまで以上に多様な小売業者の入店を図るとともに、映画  
館やアスレチック施設、さらに大学のサテライト教室なども入れて来客数を増や  
す。  
  
イ  ゲーム業界ではゲーム機器メーカーがゲームソフトを買い取ると同時にゲーム  
の開発指導を行うため、ゲームソフト開発メーカーの起業が活発になり、業界の  
発展を促している。  
  
ウ  コストダウンは自動車部品供給業者の利益を圧迫するが、それが自動車会社の  
販売力に結びつくと、自動車生産量を増加させることになるので、長期的に見れ  
ば供給業者に有利に働く。  
  
エ  新製品の市場規模がまだ小さい場合、ライバル企業の参入によって当該製品を  
めぐって市場での競争が起こり、その結果その製品の市場は拡大する可能性をも  
つ。  
  
オ  宅配業者がコンビニエンスストアを取次店とする集配を始めたので、コンビニ  
  
エ  ンスストア間の荷物発注客の増加をめざした競争は、宅配市場を掘り起こす効  
果をもたらした。  
`
        },
        {
                "mondaiId": 103210040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ライバルとの競争は、市場でコストや差別化をめぐって展開されるばかりではな  
く、ビジネスのさまざまな局面で戦略的に展開されている。そのような戦略の具体  
的な可能性として、<u>最も不適切なものはどれか</u>。  
  
ア  自社の主力製品の即席カップ麺にライバル企業が攻勢をかけてきたので、その  
対抗策としてライバル企業が得意とする袋入即席麺に自社製品を大量投入するこ  
とにした。  
  
イ  先端技術をめぐって有力な大学や各種研究機関との共同研究を他社に先行して  
確保し、自社の研究開発部門の強化を図ることにした。  
  
ウ  ライバル企業の得意とする技術分野は自社と異なっているが、ライバル企業の  
技術分野に属する独立の企業を買収して、その技術を独占的に保有することにし  
た。  
  
エ  ライバルの製品と技術差異はあるが、顧客は価格に敏感に反応するので、販売  
網を再構築し、ライバルと同じ価格を守りながらシェアの増大を図ることにし  
た。  
  
オ  旅客ビジネスにおいて、硬直的な価格体系や画一的なサービスを提供している  
ライバル企業に対して、同一の路線を頻繁に利用する顧客のニーズに即して定時  
運行を心がけ心温まるサービスの提供をすることにした。  
`
        },
        {
                "mondaiId": 103210050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
国際化の進展は産業分野や製品分野でまちまちであり、すべてを同じように取り  
扱うことはできない。しかし、よく観察すると一見ばらばらに見える国際化も、背  
後に共通のロジックが存在する。最近���国際化の状況や国際化のロジックに関する  
記述として、最も適切なものはどれか。  
  
ア  BRICsと呼ばれる新興工業国に先進国で成功している製品を持ち込むだけで  
は、現地の市場に適合的な製品を提供しながら成長を遂げている巨大なコングロ  
マリットとの競争に後れをとることになりやすい。  
  
イ  薄型パネルテレビでは国内で生産して輸出するという戦略が中心になっている  
が、それは国際的な水平分業によって生産コストや労務コストを下げるという戦  
略が展開されているからである。  
  
ウ  台湾や韓国の薄型パネルの生産規模はわが国を凌駕しているが、その生産に必  
要な部材の多くをわが国に依存しているので、海外での薄型パネルの生産増加は  
結果的にわが国の部材メーカーを潤すと同時に、日本製薄型テレビの海外輸出を  
促進する効果をもたらしている。  
  
エ  パソコン分野では中国での生産の増強や移転の例が見られるが、その理由は労  
務コストが安価であることに加えて、必要不可欠なモジュール部品のすべてが安  
価に現地生産されており、実装技術を持ち込めば低廉に生産できるという中国の  
産業構造の高度化が進展しているからである。  
  
オ  わが国の自動車産業では米国への輸出は抑制気味で、現地生産は拡大してお  
り、米国内の自動車販売に占める日本車のシェアも高いが、これは日本の大衆車  
への需要が強いためである。  
`
        },
        {
                "mondaiId": 103210060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
最近、先端技術を集積したパソコン、デジタルカメラ、薄型テレビなどのデジタ  
ル製品が相次いで赤字に転落している。その原因として、急速に悪化する景気の影  
響をあげることができるが、それ以上に、この分野に特有の競争と収益の構造によ  
る影響が大きいようである。そのような構造について説明する記述として、最も不  
適切なものはどれか。  
  
ア  EMS（ElectronicManufacturingService）やモジュール部品供給メーカーはシ  
ステム統合の技術を、レファレンスモデルを通じて提供する場合が多く、製品市  
場の参入障壁を低めている。  
  
イ  主要機能が満たされれば、それ以外の機能を付け加えても購買意欲に結びつか  
ないので、価格のみが競争優位をもたらす要因になっている。  
  
ウ  部品と部品を組み合わせるインターフェースの標準化が進展している。  
  
エ  部品のモジュール化の進展によって、生産工数を減らすことができるので、短  
期的にはコストの低下や生産性の向上が期待できるが、その効果は直ちに価格競  
争に反映される。  
  
オ  要素技術についての大きなイノベーションよりも改良された要素部品の組み換  
えや製品デザイン変更による製品開発が多く、製品ライフサイクルの短縮化につ  
ながっている。  
`
        },
        {
                "mondaiId": 103210070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業はヒット商品を連続して生みだそうと努力するが、なかなか期待したような  
成果をあげることができないでいることが多い。そのような困難と関連する事情に  
ついて説明する記述として最も適切なものはどれか。  
  
ア  主力商品を生み出した技術分野に経営資源が重点的に配分されるほど、企業は  
その技術分野に磨きをかけることができるが、技術の幅が狭くなるので、製品開  
発能力は消失する。  
  
イ  製品開発チームに部門をまたいで社内能力を動員しそれらを統合する権限を与  
えることができれば、自社の固有技術を活かした製品開発を押し進めるうえで有  
効である。  
  
ウ  他社がまねのできない独自技術を開発するには、概して特定技術への長期的な  
投資が必要であるため、市場の短期的な変化に柔軟に対応するための商品開発が  
できなくなる。  
  
エ  独自な技術で生み出された商品が後発商品と価格競争をしつつ市場を拡大する  
ようになると、顧客ニーズが硬直化するので、その市場への新商品の投入はすべ  
て無効になる。  
`
        },
        {
                "mondaiId": 103210081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
長期的に自動車産業を観察すると、自動車をめぐって幾度か大きな変化が起こっ  
ていることが分かる。馬車をいくら改良しても自動車にならないといわれるよう  
に、まず自動車が発明されなければならなかった。ガソリンエンジンを搭載して  
自力で走行する自動車がＡによって発明された。こうして誕生した初期の  
自動車は、一品ごとの受注生産による高価なものであった。これを大衆的な乗り物  
にするには量産技術が必要であった。  
\u0002  
移動組立ラインによる大量生産の技術は、  
２０世紀初頭に米国でＢによって生み出された。こうして自動車が普及す  
るにつれて、人々のニーズを反映した自動車が求められるようになり、多様な自動  
車が作られるようになった。自動車が道路にあふれるようになると新たな問題が発  
生した。  
\u0003  
自動車が環境やエネルギーさらには人間社会とどのように調和していくか  
という問題である。２１世紀の自動車は、これらの問題を克服するべくさまざまな  
側面で大きな変化が求められている。特に日本は欧米よりもこの問題への技術対応  
について先行しているといわれている。  
（設問1））  
文中の空欄ＡとＢに入る最も適切な人名の組み合わせを下記の解答群から選  
べ。  
ａアルフレッド・スローン  
ｂカール・ベンツ  
ｃジョヴァンニ・アニェッリ  
ｄヘンリー・フォード  
ｅルイ・ルノー  
〔解答群〕  
  
ア  Ａ：ａＢ：ｃイＡ：ａＢ：ｄウＡ：ｂＢ：ｄ  
  
エ  Ａ：ｃＢ：ｅオＡ：ｄＢ：ｅ  
`
        },
        {
                "mondaiId": 103210082,
                "name": "第8問設問2",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002の生産を可能にした当時の条件について述べた記述として、最  
も不適切なものはどれか。  
  
ア  作業条件の標準化と工程の専門化  
  
イ  車両デザインの限定と固定化  
  
ウ  需要の価格弾力性がある潜在的に大きな市場の存在  
  
エ  生産ラインの労働者の多能工化  
  
オ  単一車種生産による生産の同期化`
        },
        {
                "mondaiId": 103210083,
                "name": "第8問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
下線部\u0003のように現代の自動車に問われる課題は、実は多くの産業で同様に直  
面する課題でもある。そのような課題およびその克服をめぐる技術戦略が既に具  
体的に取り組まれている。これに関する記述として、最も不適切なものはどれ  
か。  
  
ア  新たな動力源やエネルギー源の開発が重要であるが、これまでの自動車産業  
の技術体系と異質であるし、開発コストが膨大にかかることから、自動車産業  
では産官学の共同研究が活発である。  
  
イ  円滑な自動車交通を図るべく、ITS（IntelligentTransportationSystem）と呼  
ばれる情報技術に支援された交通流システムの実証実験が自動車メーカーや情  
報通信関連企業によって行われており、新しい産業需要を生み出すと期待され  
ている。  
  
ウ  環境問題に対応すべく自動車のエレクトロニクス化が急ピッチで進んでお  
り、家電や重電の大手企業が自動車メーカーの有力なパートナーになってきて  
いる。  
  
エ  軽量で剛性が高く、乗り心地が良い車として、モジュール型アーキテク  
チャーの車体が自動車の大勢となり、自動車開発は全体最適を優先するように  
なってきた。  
  
オ  わが国では環境にやさしく燃費のよいエンジンとして注目されるクリーン・  
ディーゼルエンジン車の普及度は欧州に比べ低いといわれる。  
`
        },
        {
                "mondaiId": 103210090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
全国各地に長い社歴を誇る企業が存在する。そのような長寿企業に興味深い経営  
上の特徴が見られる。それらの特徴を説明する記述として、最も不適切なものはど  
れか。  
  
ア  江戸時代には上方から江戸に向けて送られる荷物は下り荷と呼ばれたが、運送  
できる量や必要とされる時間などの制約が強いため、上方で評判の製品は江戸で  
は珍重され、それが現在に続く老舗ブランドを形成している。  
  
イ  近江商人が重視する三方よしとは、生産者、販売者の利益が改善されることに  
より、全体の経済効率が高まることをいう。  
  
ウ  老舗企業では互いに市場を棲み分けながら、自社製品への根強い愛顧者を安定  
的に確保しており、競争と共生のバランスが生まれていることが見受けられる。  
  
エ  地域に根を下ろした長寿企業では地域の生活ニーズを満たすべく、地域の資源  
や伝統的な技術を駆使するとともに、そこに現代の技術成果を導入して、生産性  
の向上や商品開発を試みるなどの例が多い。  
  
オ  強い商品ブランドや企業ブランドを守るべく、経営が保守的になりやすく、新  
製品や新市場の開発が鈍くなり、生活スタイルの変化とともに市場が狭隘化する  
傾向がある。  
`
        },
        {
                "mondaiId": 103210100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
変化の激しい市場環境の下では、新製品開発にもスピードと柔軟性が求められ  
る。そのため、概念設計、機能設計、生産設計、量産設計などの設計プロセスや生  
産計画の立案などの各フェイズに、関連する部門が重複して参加・協働する知識創  
造の場として、自己組織化を促進する開発方式がとられることがある。このような  
新製品開発活動が成功する条件に関する記述として、最も適切なものはどれか。  
  
ア  各部門の責任・権限関係に混乱が生じないよう、あらかじめ明確に分業関係を  
構築しておく必要がある。  
  
イ  個人・集団・企業といった異なるレベルの学習とともに、異なる職能間での学  
習も促進する必要がある。  
  
ウ  それぞれのフェイズでチェックポイントを設け、それをクリアした後で次の  
フェイズに進めるようにし、各部門管理者は自己のフェイズに責任を負う必要が  
ある。  
  
エ  複数の部門が製品開発の目標を共有できるよう、トップは詳細な設計に関する  
具体的な基準を設定する必要がある。  
  
オ  複数の部門間での調整に混乱が生じないよう、命令の一元性を徹底して管理す  
る必要がある。  
`
        },
        {
                "mondaiId": 103210111,
                "name": "第11問設問1",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
ヘンリー・ミンツバーグ（HenryMintzberg）は、企業組織を構成する\u0002つの基  
本的要素として、「戦略的トップ（StrategicApex）」、「ミドルライン（Middle  
Line）」、「現業部門（OperatingCore）」、「テクノストラクチャ（Technostructure）」、  
「サポートスタッフ（SupportStaff）」をあげ、これらの要素の組み合わせによって、  
組織形態（configuration）は、単純構造、機械的官僚制、プロフェッショナル官僚制  
（ProfessionalBureaucracy）、分権構造、アドホクラシー（Adhocracy）の\u0002つに分  
類できるとした。  
組織形態に関する下記の設問に答えよ。  
        
（設問1））  
ミンツバーグのいう「プロフェッショナル官僚制（ProfessionalBureaucracy）」  
は、病院や会計事務所などでよく見られるという。プロフェッショナル官僚制に  
関する記述として、最も適切なものはどれか。  
  
ア  専門家の業務に対する戦略的トップによる直接的コントロールが強い。  
  
イ  多角化した企業における事業部のポートフォリオマネジメントに適してい  
る。  
  
ウ  定型的な業務を効率的に遂行する戦略に適している。  
  
エ  テクノストラクチャは少なく、専門職を支援するサポートスタッフは多い。  
  
オ  比較的単純で安定的な環境に適しており、公式化の程度は比較的高い。`
        },
        {
                "mondaiId": 103210112,
                "name": "第11問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
ミンツバーグによれば、研究開発組織などに「アドホクラシー（Adhocracy）」と  
いう組織形態が適しているといわれる。アドホクラシーに関する記述として、最  
も適切なものはどれか。  
  
ア  サポートスタッフが多く、変化する環境に適応できる支援を行っている。  
  
イ  事業部長などのミドルラインに権限を集権化し、環境への迅速な対応を可能  
にしている。  
  
ウ  戦略的トップによる現業部門に対する直接的コントロールが強くなる。  
  
エ  組織内の仲間によるコントロールと相互調整によって管理される傾向が強  
い。  
  
オ  テクノストラクチャが充実しており、現業部門に対する支援を積極的に行っ  
ている。  
`
        },
        {
                "mondaiId": 103210121,
                "name": "第12問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
次の文章を読んで、下記の設問に答えよ。  
高度な技術が複雑にかかわる現代のイノベーションにおいて、外部からイノベー  
ションに必要な知識や情報を獲得することが不可欠である。しかし、イノベーショ  
ンに有用な情報を、リードユーザーと呼ばれる顧客がもっている場合、  
\u0002  
そのような  
情報はしばしば粘着性（stickiness）が高く、入手することが困難である。このよう  
な外部からの知識や情報を理解し活用するためには、高い  
\u0003  
吸収能力（absorptive  
capacity）が必要である。  
（設問1））  
文中の下線部\u0002にある粘着性の高い情報を獲得する方法に関する記述として、  
最も適切なものはどれか。  
  
ア  LANなどの情報システムへ投資し、従業員間で情報の共有がスムーズに行  
われるようにする。  
  
イ  インターネットなどの検索エンジンを活用して、広い範囲から技術情報を獲  
得する。  
  
ウ  顧客が生産活動や消費活動を行っている現場に技術者や営業員を行かせ、対  
面形式の会話を通じて入手する。  
  
エ  コンサルタントや広告代理店を通じてマーケティング調査を行い、統計的に  
意味ある情報を入手する。  
  
オ  最先端の技術情報が掲載されている学会誌や業界雑誌などをレビューする専  
門の部門を配置する。  
`
        },
        {
                "mondaiId": 103210122,
                "name": "第12問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002に関して、外部からの知識や情報を理解し活用するために必要  
な吸収能力を獲得するために、有効な手段として最も適切なものはどれか。  
  
ア  広告宣伝活動  
  
イ  サプライチェーンマネジメント  
  
ウ  自社の研究開発投資  
  
エ  市場調査  
  
オ  従業員満足度の向上  
`
        },
        {
                "mondaiId": 103210130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
組織文化の機能に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  企業の年齢が若く、規模が小さい段階では、組織文化は従業員のこころを結び  
つける接着剤の役割を果たす。  
  
イ  組織文化は、外部環境への適応行動である戦略行動にはあまり影響を与えない  
が、組織内部の管理や人事評価などに強い影響力を持つ。  
  
ウ  組織文化は、従業員が信奉する価値観など、組織内で論理的に解決できない問  
題への解を提供することで、従業員の不安を解消する。  
  
エ  組織文化は、新入社員に対して、この組織ではどのような振る舞いが望ましい  
のか、何を良いと感じるべきかを教育する機能を持つ。  
  
オ  組織文化を外部環境に発信することを通じて、その組織文化に共感するメン  
バーの参加を促す効果を持つ。  
`
        },
        {
                "mondaiId": 103210140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
ホスピタリティ産業やサービス産業などの企業では、顧客の多様な要求に迅速に  
応じる必要があり、従業員へのエンパワーメントが進んでいる。このような組織  
は、「逆ピラミッド」型組織といわれることもある。このような組織における管理に  
関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  従業員が業務遂行に必要なスキルや知識を定期的に確認し、行動実践に移すこ  
とができているか評価する。  
  
イ  従業員に自分で判断し行動する権限を与え、上司はコーチとして従業員を信頼  
するとともに、支援していく役割を担う。  
  
ウ  従業員には複雑なマニュアルではなく、顧客へのサービス哲学や組織の価値観  
を象徴する単純なメッセージを共有させる。  
  
エ  従業員満足度調査の中で、上司のリーダーシップやキャリア開発への支援のあ  
り方を部下が評価し、それを上司に直接提出させる。  
  
オ  専門部門の垣根を越えてチームとして顧客に接するような組織文化を構築する  
ために、従業員相互のコミュニケーションを促進する。  
`
        },
        {
                "mondaiId": 103210151,
                "name": "第15問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
さいな  
規模の大小にかかわらず閉塞感に苛まれている成熟した事業を展開している企業  
では、優れた人材の持つ能力を制約したり破壊するような組織内環境をつくってし  
まう可能性がある。こうした組織を、人材の持つ専門能力やエネルギーを組織の力  
として効果的に統合するように変革を進めていくためには、個々の従業員のコンピ  
テンシーを開発するとともに、その力を組織へ統合し、維持していかなければなら  
ない。こうした組織変革のプロセスに関する下記の設問に答えよ。  
（設問1））  
組織における個人のコンピテンシーを高め、自発的に職務にコミットさせるよ  
うにするためのプロセスとして、最も適切なものはどれか。  
  
ア  管理者は、部下のコンピテンシーの状態を絶えず評価し、彼らがミスを犯さ  
ないようにプロセスをチェックしていく。  
  
イ  現場の従業員に経営資源を活用する権限を移譲し、自己規律に基づくエンパ  
ワーメントをしていく。  
  
ウ  個人の自発的コミットメントを引き出すために、従業員の賃金のベースアッ  
プ率を高くする。  
  
エ  従業員個人の職務を明確に規定し、どの職務を担っても処遇に差が生じない  
よう、一律の賃金体系を導入する。  
  
オ  同僚や高い評価を受けている従業員に対して不公平感を感じないようにする  
ため、従業員相互で評価についての比較をさせないようにする。  
`
        },
        {
                "mondaiId": 103210152,
                "name": "第15問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
個人のコンピテンシーとコミットメントを、組織全体の力として統合していく  
ための施策として、最も適切なものはどれか。  
  
ア  管理者は部下のメンターもしくはコーチとして行動し、専門職能を基軸とし  
た分権的組織構造を構築する必要がある。  
  
イ  個人間の情報伝達をオープンにするため、非公式の情報伝達ルートを制限  
し、誰でも情報を入手できるようにする。  
  
ウ  個人の責任・権限と財務的報酬を明確に定義した業績評価システムを構築  
し、個人レベルの学習が組織にとってどう関係するかを理解できるようにす  
る。  
  
エ  従業員が参加できる集団的意思決定方式を導入し、全員一致の合意に基づく  
政策決定を優先するようなリーダーによって運営される必要がある。  
  
オ  成熟した事業部門の枠を越えて、他の事業部門との横方向の情報交換を促進  
するような場の設定や、情報システムを導入する。`
        },
        {
                "mondaiId": 103210160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
リーダーシップに関する学説の多くは、「人間もしくは人間関係指向」と「課業指  
向」という指向性の区別に言及している。このことに関する記述として最も適切な  
ものはどれか。  
  
ア  アージリスは、職務拡充を通じて、課業指向的なリーダーシップを、人間関係  
指向的なリーダーシップにかえていくことができると主張した。  
  
イ  ハーシーとブランチャードは、高課業指向、高関係性指向のリーダーシップ  
が、最も説得的で生産的であると主張した。  
  
ウ  フィードラーは、低いLPCリーダーは課業指向で、高いLPCリーダーは人間  
関係指向であると主張した。  
  
エ  ブルームは、民主的・参加型リーダーシップが高い生産性を生むと主張した。  
`
        },
        {
                "mondaiId": 103210170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
  
イ  ノベーションを目的とするアライアンス関係の代表的な形態には、下請関係、  
ライセンシング、コンソーシアム、ジョイントベンチャーなどがあり、それぞれ長  
所と短所を持っている。これらのアライアンス関係に関する記述として最も適切な  
ものはどれか。  
  
ア  コンソーシアムは、基礎研究のように不確実性の高い場合に、複数の企業が共  
同出資することで投資リスクを低くする効果を持っているが、コンソーシアム解  
散後の企業間の差別化が困難になるという問題を持つ。  
  
イ  下請関係は製品製造コストの削減には有効であるが、新製品の開発や技術革新  
については取引コストが高くついてしまう。  
  
ウ  ジョイントベンチャーは、比較的長期にわたり同質的な技術をもつ企業同士が  
提携することであるが、組織文化の対立などによってコントロールを失う可能性  
もある。  
  
エ  ライセンシングは、短期間に技術を獲得するのに有効であるが、獲得した技術  
を自社が自由に利用する権利が制約されるリスクがある。  
`
        },
        {
                "mondaiId": 103210180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
労働基準法では、労働者を解雇する場合の解雇制限や解雇手続きについて定めら  
れているが、解雇制限や解雇手続きに関する記述として、最も不適切なものはどれ  
か。  
  
ア  産前・産後の女性が労働基準法の定めにより休業する期間とその後３０日間は  
解雇はできない。  
  
イ  日々雇い入れられる者や\u0002か月以内の期間を定めて使用される者を解雇する場  
合は、労働基準法に定める解雇予告や解雇予告手当の支払いの必要はない。ただ  
し、日々雇い入れられる者が\u0003か月を超えて引き続き使用された場合や\u0002か月以  
内の期間を定めて使用される者が所定の期間を超えて引き続き使用された場合  
は、解雇予告や解雇予告手当の支払いが必要である。  
  
ウ  労働者が業務上の傷病でその療養のため休業する期間とその後３０日間の解雇  
は禁止されているが、療養開始後\u0004年を経過しても治らない場合は特段の保障な  
く解雇することができる。  
  
エ  労働者の責めに帰すべき事由により解雇する場合でも、少なくとも３０日前に  
解雇予告をするか、または３０日分以上の平均賃金の解雇予告手当を支払う必要  
があるが、労働者の責めに帰すべき解雇事由について行政官庁の認定を受けた場  
合は、その必要はない。  
  
オ  労働者を解雇する場合には、少なくとも３０日前に解雇予告をするか、または  
３０日分以上の平均賃金の解雇予告手当を支払う必要があるが、天災事変その他  
やむを得ない事由により事業の継続が不可能になり、その事由について行政官庁  
の認定を受けた場合は、その必要はない。  
`
        },
        {
                "mondaiId": 103210190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
労働安全衛生法では、事業者に対して常時使用する労働者を対象に健康診断の実  
施を義務づけているが、健康診断等に関する記述として、最も不適切なものはどれ  
か。  
  
ア  事業者とは、事業を行う者で労働者を使用するものとされ、労働者の安全と健  
康を確保する義務主体で、法人企業であれば法人自体であり、個人企業であれば  
経営者個人である。  
  
イ  事業者は、期間の定めのない労働契約によるパートタイム労働者でも、その者  
の\u0002週間の所定労働時間が当該事業場の同種の業務に従事する通常の労働者の\u0002  
週間の所定労働時間の\u0003分の\u0004以上の場合は、一般健康診断を実施しなければな  
らない。  
  
ウ  事業者は、常時使用する労働者に対しては年\u0002回、深夜業など一定の業務に従  
事する労働者に対しては当該業務への配置替えの際および\u0005か月毎に\u0002回、定期  
的に一般健康診断を実施しなければならない。  
  
エ  事業者は、常時使用する労働者を雇い入れるときは、当該労働者に対して一般  
健康診断を実施（健康診断を受けた後、\u0003か月を経過しない者がその結果を証明  
する書面を提出した場合の診断項目は除く）しなければならない。  
  
オ  事業者は、労働安全衛生法に基づいて作成すべき一般健康診断の健康診断個人  
票を\u0006年間保存しなければならない。  
`
        },
        {
                "mondaiId": 103210200,
                "name": "第20問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
人事考課に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  人事考課で最も重要なことは公正な評価が行われることであるが、人事考課に  
ともないやすい評定誤差として、中央化傾向、寛大化傾向、論理的誤差および対  
比誤差などがある。  
  
イ  人事考課の評価基準には、定められた基準（レベル）に基づいて評価する絶対評  
価と評価対象者の中での比較による相対評価があるが、絶対評価の代表的なもの  
にはプロブスト法などがある。  
  
ウ  人事考課の評価項目には、能力考課、業績考課および情意考課があるが、その  
うち情意考課とは職務に取り組む意欲や勤務態度、積極性や協調性などを評価す  
るものである。  
  
エ  人事考課は、昇進・昇格、昇給・賞与の管理、配置転換や人事異動および能力  
開発や教育訓練のニーズの把握など、さまざまな人的資源管理の根拠となる。  
  
オ  ハロー効果とは、同じ考課者が同じ被考課者を評価しても、時間や順序が変わ  
ると異なった評価になる傾向のことをいう。  
`
        },
        {
                "mondaiId": 103210210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
労働者派遣事業の適正な運営の確保及び派遣労働者の就業条件の整備等に関する  
法律（労働者派遣法）に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  一般労働者派遣事業は、厚生労働大臣の許可が必要であるが、特定労働者派遣  
事業は、厚生労働大臣ヘの届け出をすれば事業を行うことができる。  
  
イ  派遣元事業主は派遣元管理台帳を、派遣先は派遣先管理台帳を作成し、それぞ  
れ\u0002年間保存しなければならない。  
  
ウ  労働者派遣事業には、港湾運送業務、建設業務、警備業務およびその他政令で  
定める業務はその対象とされていない。  
  
エ  労働者派遣事業には、常時雇用している者の中から派遣する一般労働者派遣事  
業と、登録している者の中から派遣する特定労働者派遣事業がある。  
  
オ  労働者派遣とは、自己の雇用する労働者をその雇用関係を維持したまま、他人  
の指揮命令を受けて、当該他人のために労働に従事させることをいい、当該他人  
に対し当該労働者を当該他人に雇用させることを約しているものは含まない。  
`
        },
        {
                "mondaiId": 103210220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
価格設定は、企業のマーケティング目的や、消費者心理を反映して行われる。以  
下の記述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  イメージ価格設定（イメージ・プラ���シング）は、消費者の自己イメージ形成欲  
求や顕示欲求が強い製品群において、とくに有効である。  
  
イ  市場浸透価格（ペネトレーティング・プライス）の設定を通じて企業が市場占有  
率の最大化を目指すとき、ひとつには経験効果によって生産費用と流通費用が低  
減すること、いまひとつは市場の需要の価格弾力性が低いことが、その成功の条  
件となる。  
  
ウ  消費者は、特定の製品の価格を正確に記憶していることは少ないが、過去の買  
い物経験などを通じて蓄積された内的参照価格と、値札情報など実際の買い物場  
面で提示されている外的参照価格の影響を受けて購買意思決定を行う。  
  
エ  大規模な投資をともなう技術開発をベースとした新製品が市場に投入される  
際、上澄み吸収価格（スキミング・プライス）が用いられることが多い。企業がこ  
の方法を採用するための前提条件は、利益を期待できる十分な数の買い手による  
需要が存在すること、さらにはイニシャル・コストが高いことから潜在的な競合  
企業の参入が困難なことである。  
  
オ  同一の製品であっても、国内と海外、シーズン中とオフシーズン、あるいは業  
務用と家庭用などの区分によって、消費者間での需要の価格弾力性が異なること  
がある。  
`
        },
        {
                "mondaiId": 103210231,
                "name": "第23問設問1",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
次の文章を読んで、下記の設問に答えよ。  
市場細分化を行う際、より多くの細分化変数を用いれば、より詳細な水準での市  
場細分（市場セグメント）の明確化を行うことができる。ただし、  
\u0002  
すべての市場細分  
が、マーケティング活動による働きかけの対象として有効であるとは限らない。ま  
た、標的として設定する市場細分の選定にあたっても、  
\u0003  
企業の目的・戦略・資源と  
の適合を図る必要がある。  
（設問1））  
文中の下線部\u0002に関連する以下の文章の空欄ＡおよびＢにあてはまる語句の組  
み合わせのうち、最も適切なものを下記の解答群から選べ。  
市場細分の評価は、「測定可能性」、「Ａ可能性」、「差別化可能性」、と  
くに物流とＢ流の上での「到達（接近）可能性」、さらにはマーケティン  
グ・プログラムの「実行可能性」を主な基準として行われる。  
〔解答群〕  
  
ア  Ａ：独占的競争Ｂ：商  
  
イ  Ａ：費用低減Ｂ：資金  
  
ウ  Ａ：費用低減Ｂ：情報  
  
エ  Ａ：利益確保Ｂ：資金  
  
オ  Ａ：利益確保Ｂ：情報  
`
        },
        {
                "mondaiId": 103210232,
                "name": "第23問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002に関連する以下の記述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  カメラ・メーカーのX社は、インスタント・カメラで特定市場細分におけ  
る専門化の利益を享受していたが、デジタル技術の登場によって存続の危機に  
陥った。これは集中型マーケティングに固執し、環境適応を怠ったからであ  
る。  
  
イ  書籍流通業者のY社は各地の大学図書館の多様なニーズを充足するため、  
大学図書館の書籍購入システムを効率化するだけでなく、図書館業務の専門家  
を派遣し、利用者へのレファレンス業務のサービス向上に貢献している。この  
ような特定市場の複数ニーズに総合的に対応するアプローチを市場専門化とい  
う。  
  
ウ  製パン・メーカーのZ社は、同一の製品を、スーパーマーケットやコンビ  
ニエンスストア、総合スーパーに加え、学校や病院、企業の食堂や大手レスト  
ラン・チェーンに販売している。こうしたアプローチを選択的専門化という。  
  
エ  相互に異質な性格をもつ複数の市場細分の間に、活用できる類似性が存在す  
ることがある。たとえば、四輪駆動車は登山やスキーの愛好家たちからも、都  
会でのドライブを楽しむ人たちからも選好される。このような市場細分群のこ  
とをスーパーセグメントという。  
`
        },
        {
                "mondaiId": 103210240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
ほぼ１００％をPB（プライベート・ブランド）による品揃えとする、高級食品スー  
パーマーケット・チェーンを運営するA社は、ある県の小売市場で急成長を遂げ  
ている。その県内で全１２店舗を展開し、専用物流センターを活用しているA社  
は、提携する地域生産者および自社工場の稼働率を上げ、一定の規模の経済性を達  
成することを目指して、店舗網を拡大し、近隣都道府県へ進出することを計画して  
いる。A社が現在検討している進出案に関する以下の記述のうち、最も不適切なも  
のはどれか。  
  
ア  A社が使用する専用物流センターの効率的な稼働を考慮し、店舗網の拡大は、  
現在使用している物流センターのサービス範囲への集中出店（ドミナント出店）方  
式をとる。  
  
イ  A社の小売事業ブランド化は、強大な購買支配力（バイイング・パワー）をもと  
に展開されたものではなく、地域生産者とのパートナーシップによって支えられ  
てきたので、店舗網の拡大後も、これらの生産者の成長を支援する役割が期待さ  
れる。  
  
ウ  A社は、近隣都道府県への進出の一案としてフランチャイズ展開を計画してい  
る。それは、企業型垂直マーケティング・システム（企業型VMS）の一形態であ  
る。  
  
エ  A社は、店舗網がある一定の地理的範囲を超えて、近隣都道府県の外部まで達  
した段階では、有休生産設備をもつNB（ナショナル・ブランド）メーカーや進出  
先地域の生産者との間に新たに製造委託契約を交わすことができる。  
  
オ  A社は、より多くの地域に出店を進めることで、店舗業務や店舗支援業務が複  
雑になるので、より明確な目的意識をもってチャネル・リーダー（チャネル・  
キャプテン）としての役割を果たさなければならない。  
`
        },
        {
                "mondaiId": 103210250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
消費者が買い求めるさまざまな商品は、購買習慣に基づいて、最寄品、買回品、  
専門品、非探索品に分類される。これらに関する以下の記述のうち、最も不適切な  
ものはどれか。  
  
ア  購買頻度が高く、即座に商品が引き渡されることが要求され、かつ比較や購買  
に際して最小限の努力しか払おうとしない商品は最寄品に分類される。  
  
イ  専門品の購買行動では、消費者が買い物の途中で選好を明確にするが、買回品  
の場合、買い物出向に先駆けて選好が確立している。  
  
ウ  非探索品とは、消費者がその製品・サービスに対する必要性が生じるまでは意  
識したり、興味を抱いたりすることのないものを指す。  
  
エ  最寄品と買回品の区分は探索性向の水準の高低に基づいている。前者ではこの  
水準が極めて低く、後者では高くなっている。  
`
        },
        {
                "mondaiId": 103210260,
                "name": "第26問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
音響機器メーカーのB社は、１０年前にデジタル時代に向けた基幹ブランドとし  
て開発した携帯オーディオ・プレイヤーの販売実績が思わしくないことから、大幅  
に改良した新モデルの投入に先駆けて、大規模なプロモーション・キャンペーンを  
実施することにした。同社の宣伝部で行われるキャンペーン施策に関する以下の記  
述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  AIDMAモデルの最初のAは、Attention（注目）の獲得を意味するから、まず  
は注目度を向上させるために、標的市場への到達度と広告接触頻度を掛け合わせ  
た指標を使って、媒体間の期待広告効果の比較をすることが重要である。  
  
イ  広告は大きな支出をともなうが、メッセージの内容や発信のタイミング、さら  
には到達する対象の特徴がとらえやすい。一方、販促を用いれば販売業者と消費  
者に対して強く訴求できるが、必ずしも効果が長続きするとはいえない。  
  
ウ  今回の新製品プロモーション・キャンペーンでは大規模な広告投資を行うが、  
その売上への貢献は、製品コンセプトの差別性、販売業者との取引条件や製品価  
格の設定、チャネル・サービスの質と量など、マーケティング・ミックス要素の  
総合的成果である。  
  
エ  テレビ、新聞、雑誌、ラジオのマス\u0002媒体は、このところ急速にセグメント・  
メディア化が進んでいるので、６０歳以下の幅広いターゲットを狙う場合、今回  
の新商品のプロモーションには不適切である。  
  
オ  プロモーション政策は、広告、販促、パブリシティ、営業活動を含むものであ  
り、これらの活動は、マーケティング目的に対して明確な意味づけがなされ、意  
思決定プロセスや効果測定の方法も明示しておく必要がある。  
`
        },
        {
                "mondaiId": 103210270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
数多くのブランドを展開する中堅アパレル・メーカーのC社では、各ブランド  
のプロモーション活動が局地的に実施されてきたことが理由で、企業理念と各ブラ  
ンドの戦略が結びつかない状況に陥り、その結果、顧客離れが進んでしまった。こ  
の状況を打破するために、同社は、統合マーケティング・コミュニケーション  
（IMC）の視点の導入を図っている。IMCに関する以下の記述のうち、最も不適切  
なものはどれか。  
  
ア  IMCのアプローチは、顧客関係性に重点を置いているため、そのコミュニ  
ケーション目的は、市場占有率だけでなく、顧客シェア、つまり顧客の生涯価値  
の重要性に着目する。  
  
イ  IMCの枠組みでは、コミュニケーション投資の効果、つまり投下資本回収率  
の計測を重視しているが、現実にはこれを測定することは非常に難しい。  
  
ウ  IMCは伝統的な広告と同様、送り手から受け手に対する一方通行のコミュニ  
ケーションであることに変わりはないが、顧客起点である点と、顧客を含むス  
テークホルダーへの配慮に重点を置いている点がユニークな特徴である。  
  
エ  広告、販促、広報のいずれもが、企業の発信するコミュニケーション活動であ  
り、これらは企業理念、企業戦略、事業戦略、ブランド戦略の中で一貫性をも  
ち、いわば「ひとつの統一されたメッセージ」で結びつけられることが欠かせな  
い。  
  
オ  伝統的なマーケティング・コミュニケーションでは、一定の期間に集中的にコ  
ミュニケーション投下を行っていたが、IMCでは複数のプロモーション・ミッ  
クス要素を組み合わせて断続的な働きかけを行っていく。  
`
        },
        {
                "mondaiId": 103210281,
                "name": "第28問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
次の文章を読んで、下記の設問に答えよ。  
  
ア  メリカ・マーケティング協会によって説明されるように、一般にブランドとは  
「ある売り手の財やサービスが、他の売り手のそれとは異なるものであることを  
してもらうための、  
\u0002  
名前、用語、デザイン、シンボル、およびその他の  
ユニークな特徴」であるとされている。現在では、  
\u0003  
ブランド概念のさまざまな側面  
が議論されている。  
（設問1））  
文中の空欄に入る最も適切なものはどれか。  
  
ア  広告  
  
イ  識別  
  
ウ  示唆  
  
エ  断言  
  
オ  明言`
        },
        {
                "mondaiId": 103210282,
                "name": "第28問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部\u0002に挙げられるものとして、ロゴ、キャラクター、パッケージ、  
スローガンなども、自社の製品を特徴づけ、他社製品と差別化するために用いら  
れる代表的な知覚コードである。これらの総称として最も適切なものはどれか。  
  
ア  ブランド・エクステンション  
  
イ  ブランド・エレメント  
  
ウ  ブランド・ライセンシング  
  
エ  ポジショニング・ステートメント  
`
        },
        {
                "mondaiId": 103210283,
                "name": "第28問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002に関連する以下の記述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  成分ブランドは、コ・ブランディング（Cobranding）の一種であり、最終製  
品自体がブランド化しているだけでなく、その製品に使用されているパーツな  
どもブランド化しているものである場合を指す。  
  
イ  プライベート・ブランドは、卸売業者や小売業者による商標のことである。  
  
ウ  ブランド・アイデンティティを確立するためには、ブランドの機能的便益だ  
けでなく、情緒的・自己表現的便益を明確にすることが重要であり、それは顧  
客関係性の構築のためにも欠かせない。  
  
エ  ブランド・エクイティとは、特定の組織にとって自社のブランドによって連  
想される内容から、その資産となる部分を総和したものであり、わが国におい  
ては、近年その測定についての標準算出式が導入されている。  
  
オ  ブランド開発では「モノ」をどのような生活空間・生活場面と結び付け、「モ  
ノへの意味付け」を通じて価値や便益を創造・伝達し、どのように顧客との強  
力な関係性を構築するかが重視される。  
`
        },
        {
                "mondaiId": 103210291,
                "name": "第29問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
次の文章を読んで、下記の設問に答えよ。  
消費者行動論の分野では、消費者の購買意思決定プロセスのモデル化が行われて  
きた。そのなかでも代表的なモデルは、問題の認識とその解決としての購買行動を  
前提として、「問題認識」→「情報探索」→「」→「選択」→「結果の評価」から  
なる\u0002段階のプロセスによる説明を行っている。  
（設問２））  
文中の空欄に入る最も適切なものはどれか。  
  
ア  購買目的の精緻化  
  
イ  情報分散化  
  
ウ  代替案の評価  
  
エ  トライアル購買  
  
オ  取引条件の交渉`
        },
        {
                "mondaiId": 103210292,
                "name": "第29問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の下線部に関連する以下の記述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  ある消費者が特定ブランドに対する高いロイヤルティ（忠誠）を持つ場合、こ  
の消費者は購買意思決定プロセスの中で広範囲問題解決を行うことが多い。  
  
イ  情報探索段階で長期記憶により多くの知識を持っている消費者は、少ない知  
識を持つ消費者と比べ、意思決定のための情報を効果的に処理できる傾向が強  
い。  
  
ウ  情報探索段階の外部探索とは、消費者が店舗出向したり、カタログやウェブ  
サイト、友人の意見などを参考にしたりして情報収集を行うことである。  
  
エ  製品関与とは、消費者がある製品に対してどれだけ重要性やリスクを感じる  
かの水準を指し、消費者がその製品にどれだけこだわりを持っているかという  
ことである。  
`
        },
        {
                "mondaiId": 103210301,
                "name": "第30問設問1",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
次の文章を読んで、下記の設問に答えよ。  
ある地域スーパーマーケット・チェーンでは、この１０年間、「地元の小・中学校  
に芝生の運動場を」というスローガンを掲げて、地域の消費者がこのスーパーで買  
い物をして、ポイント・カードに２，０００円分のポイントを集めるごとにクーポンを  
渡している。クーポンを手にした消費者は、地元の任意の小・中学校にクーポンを  
寄付する。一定額のクーポンと引き換えに、各学校が芝生の運動場への改良工事費  
を施工業者に支払うことのできる仕組みである。  
（設問1））  
このスーパーマーケットが実施しているような社会的責任マーケティングの手  
法には特定の名称が与えられている。この名称として、最も適切なものはどれか。  
  
ア  インターナル（Internal）・マーケティング  
  
イ  ヴァイラル（Viral）・マーケティング  
くち  
  
ウ  口コミ・マーケティング  
  
エ  グリーン（Green）・マーケティング  
  
オ  コーズ・リレイテッド（Cause-related）・マーケティング`
        },
        {
                "mondaiId": 103210302,
                "name": "第30問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
社会的責任マーケティングに関する以下の記述のうち、最も不適切なものはど  
れか。  
  
ア  インターネットをはじめとするメディアが発達した今日では、企業の非倫理  
的・非社会的行為に対する非難や糾弾の意見が、迅速かつ広範囲に伝播してい  
く現象が顕著になっている。  
  
イ  企業のソーシャル・マーケティング活動は、事業遂行における法令遵守が的  
確に行われていれば、十分な責任が果たされているといえる。  
  
ウ  ソーシャル・マーケティングの実践は、しばしば教育的要素をともなうた  
め、いかに楽しくメッセージを伝達し、理解してもらうかが重要である。  
  
エ  非営利組織や政府機関が社会問題などに直接的に働きかけるために実施され  
るマーケティング活動もソーシャル・マーケティングと呼ばれる。  
`
        },
        {
                "mondaiId": 104210010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工程管理における生産統制に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  生産統制における管理の担当者は、主として現場の管理者や監督者である。  
  
イ  生産統制には、一般に生産の実績の報告と、その評価が含まれる。  
  
ウ  生産統制は、現品管理と余力管理の\u0002つの機能から構成される。  
  
エ  生産統制は、工程管理機能の一環であり、生産計画に対応する活動である。`
        },
        {
                "mondaiId": 104210020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産現場における管理目標（PQCDSME）の記号とその活動との組み合わせとし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  C―原価の低減や経済性の向上を推進する活動  
  
イ  D―決められた量の確保や納期を遵守する活動  
  
ウ  S―作業の標準化や標準部品の使用を進める活動  
  
エ  M―職場環境の向上や勤労意欲を高める活動`
        },
        {
                "mondaiId": 104210030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産形態と生産計画の手法との組み合わせとして、最も関連性の強いものはどれ  
か。  
  
ア  個別単品生産―ラインバランシング  
  
イ  多品種少量生産―PERT  
  
ウ  多品種ロット生産―サイクリックスケジューリング  
  
エ  単一品種多量生産―ジョブショップスケジューリング  
`
        },
        {
                "mondaiId": 104210040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の結合技術のうち、組立性、分解性が最も良いものはどれか。  
  
ア  圧入イカシメウネジ締めエハンダ付け`
        },
        {
                "mondaiId": 104210050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
新QC七つ道具に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  PDPC法は、事前に考えられるさまざまな結果を予測し、プロセスの進行をで  
きるだけ望ましい方向に導く手法である。  
  
イ  系統図法は、事実、意見、発想を言語データでとらえ、それらの相互の親和性  
によって図を作成し、問題の所在、形態を明らかにする手法である。  
  
ウ  マトリックス図法は、行に属する要素と、列に属する要素の二元的関係の中か  
ら問題解決の着想を得たりする手法である。  
  
エ  連関図法は、原因－結果、目的－手段などが絡み合った問題について、その関  
係を論理的につないでいくことによって問題を解明する手法である。`
        },
        {
                "mondaiId": 104210060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工程管理における現品管理の活動に関する記述として、最も不適切なものはどれ  
か。  
  
ア  材料や外注品の、運搬や移動に関する活動  
  
イ  倉庫に保管されている物品の、受け払いに関する活動  
  
ウ  納入時における物品の、数量の過不足を確認する活動  
  
エ  物の流れを円滑にするための、作業者の作業時間を管理する活動  
`
        },
        {
                "mondaiId": 104210070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
工場計画に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  海外立地を検討する際の要因には、国内立地検討要因のほか、カントリーリス  
クなど、海外立地特有の要因が存在する。  
  
イ  工場計画では、サプライチェーンマネジメントが優位性を発揮するための方策  
を考慮する必要がある。  
  
ウ  工場計画は、敷地選定、建屋の設計、職場の設計、設備の設計、治工具の設計  
などが相互に関連して進められる。  
  
エ  工場レイアウト設計におけるレイアウトのタイプは、製品の種類により、製品  
別、グループ別、工程別の\u0002つに分類される。`
        },
        {
                "mondaiId": 104210080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品設計に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  環境に配慮した製品設計では、製品の製造におけるエネルギー消費の環境に対  
する負荷を積算して環境に対するインパクトを評価するLCAが重要である。  
  
イ  製造物責任（PL）の理念、目的は、損害賠償という事後的救済を強化するだけ  
でなく、欠陥製品事故を未然に防止し、消費者の製品への信頼を高めることにあ  
る。  
  
ウ  製品開発段階では、製品に要求される製品機能、性能、品質を満たすだけでな  
く、製造工程の生産性向上、製品欠陥への消費者の保護、地球環境負荷の低減な  
ど製品開発後を強く意識した製品設計が求められる。  
  
エ  製品構造の単純化は、多種の製品を少種の部品で実現することにより、考慮す  
べき総部品点数の低減、生産工程の単純化が図れるため、作りやすい製品設計の  
基本として重要である。  
`
        },
        {
                "mondaiId": 104210090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
CADにおける三次元の形状の表現方法に関する記述として、最も適切なものは  
どれか。  
  
ア  サーフィスモデルには、構成部品同士の干渉チェックができるという利点があ  
る。  
  
イ  サーフィスモデルは、複雑な形状では立体的なイメージが把握しにくいという  
欠点がある。  
  
ウ  ソリッドモデルでは、容積や質量、重心などの物理量の計算はできないという  
欠点がある。  
  
エ  ワイヤーフレームモデルは、複雑な形状に対して高速に処理・表示できるとい  
う利点がある。`
        },
        {
                "mondaiId": 104210100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
複数の組立作業を複数の作業者で行う生産方式に関する記述として、最も不適切  
なものはどれか。  
  
ア  \u0002人生産方式では、ライン生産方式よりも高い能力の作業者が必要である。  
  
イ  \u0002人生産方式は、作業数が少なく、作業時間の変動が大きい場合に有効であ  
る。  
  
ウ  ライン生産方式では、工程間在庫が工程間負荷のアンバランスを吸収するのに  
有効である。  
  
エ  ライン生産方式では、ラインバランシングが生産効率の向上に重要である。  
`
        },
        {
                "mondaiId": 104210110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
下図に示される\u0002段階生産工程では、かんばんループにより仕掛在庫が制御され  
ている。すなわち、かんばんは、工程\u0003で生産物に取り付けられ、処理が開始され  
る。工程\u0003で処理を完了した生産物は工程\u0002に送られる。工程\u0002での処理が完了し  
たとき、かんばんは取り外され、工程\u0003の生産指示に回される。ただし、各工程で  
は、\u0003度に、\u0003つの生産物が処理されるものとする。  
次の表は、工程\u0003と工程\u0002における品番１１～１７の生産物の処理の開始時刻と完  
了時刻を示している。かんばん枚数が\u0004枚のとき、工程\u0003における品番１８の生産  
物の処理の開始時刻として最も適切なものを下記の解答群から選べ。  
品番１１１２１３１４１５１６１７１８  
工程\u0003  
開始時刻４６５６６６６９７６８０８７  
完了時刻４９５８６９７４８０８４８８  
工程\u0002  
開始時刻７６７８８７９２１００１０８１０９  
完了時刻７８８７９２１００１０８１０９１１７  
〔解答群〕  
  
ア  ８７イ８８ウ９２エ１００  
`
        },
        {
                "mondaiId": 104210120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
\u0002種類の製品A、Bは、\u0003種類の原料a、b、cを混合して生産される。製品\u0004単  
位の生産に使用される原料の量、原料の保有量および製品\u0004単位当たりの利益は下  
表に示されている。  
保有原料で製品A、Bを生産するとき、下記の解答群に示す生産量の組（Aの生  
産量，Bの生産量）のうち、総利益を最も高くする実行可能なものを選べ。  
原料a原料b原料c  
製品\u0004単位  
当たりの利益  
製品A１２５１  
製品B４５６３  
原料の保有量１２２０３６－  
〔解答群〕  
  
ア  （６，１）イ（４，２）ウ（２，３）エ（０，４）`
        },
        {
                "mondaiId": 104210130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
計量モデルによる需要予測に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  最小二乗法による需要関数のパラメータ推定では、パラメータ数以上の需要  
データが必要である。  
  
イ  重相関係数は、需要関数での推定がどの程度需要量を説明したかを測るのに用  
いられる。  
  
ウ  需要関数の説明変数が多くなれば、最小二乗法における残差平方和は小さくな  
る。  
  
エ  需要関数の説明変数が多くなればなるほど、予測精度は高くなる。  
`
        },
        {
                "mondaiId": 104210140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
在庫のABC管理に関する記述として、最も適切なものはどれか。  
  
ア  B品目では、数量に応じて類似品をグループ化し、グループごとに予測・発  
注・納入を行うことに重点が置かれる。  
  
イ  在庫金額の多いA品目では、在庫の削減よりも、むしろ、発注業務や伝票作  
成などの管理事務の手間を省くことに重点が置かれる。  
  
ウ  在庫金額の少ないC品目では、現品管理を徹底し、納入時点をきめ細かく指  
示して余分な発注を慎むことに重点が置かれる。  
  
エ  在庫のABC管理では、横軸に品目を在庫金額の少ない順に、縦軸に在庫金額  
を示したABC曲線が用いられる。`
        },
        {
                "mondaiId": 104210150,
                "name": "第15問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
サプライチェーンの上流に行くほど発注量の変動が大きくなるブルウィップ効果  
と呼ばれる現象が知られている。定期発注方式を用いたときに需要量の変動よりも  
発注量の変動が増加する原因として、<u>最も不適切なものはどれか</u>。  
  
ア  安全在庫量の増加  
  
イ  需要量の予測誤差の増加  
  
ウ  納入リードタイムの増加  
  
エ  発注間隔の増加  
`
        },
        {
                "mondaiId": 104210160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
資源有効利用促進法（資源の有効な利用の促進に関する法律）において事業者に求  
められる事項に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  指定再利用促進製品の製造事業者は、分別回収の促進のための表示を行うこと  
が求められる。  
  
イ  指定省資源化製品の製造事業者は、原材料等の使用の合理化、長期間の使用の  
促進その他使用済み物品等の発生の抑制に取り組むことが求められる。  
  
ウ  特定再利用業種の属する事業者は、再生資源又は再生部品の利用に取り組むこ  
とが求められる。  
  
エ  特定省資源業種の属する事業者は、副産物の発生抑制等に取り組むことが求め  
られる。`
        },
        {
                "mondaiId": 104210170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
標準時間に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  PTS法や標準時間資料法では、正味時間を算定する際にレイティングの操作  
を行う必要がある。  
  
イ  標準時間は正味時間と余裕時間の和として構成され、内掛け法で算出された余  
裕率を利用する際には、正味時間×  
\u0002  
１－余裕率  
によって計算される。  
  
ウ  標準時間は生産計画の立案に利用されるだけでなく、生産活動の進捗管理や改  
善を行う際にも利用される。  
  
エ  標準時間は、その仕事に適性をもった習熟した作業者が、所定の作業条件のも  
とで、適切な余裕をもち、正常な作業ペースで仕事を遂行するのに必要となる時  
間である。  
`
        },
        {
                "mondaiId": 104210180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
生産システム内での作業者の動きや作業方法の問題点を明らかにする分析手法と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  P―Q分析  
  
イ  サーブリッグ分析  
  
ウ  両手動作分析  
  
エ  連合作業分析`
        },
        {
                "mondaiId": 104210190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
次の設備総合効率を求める計算式中の空欄Ａ～Ｃに入る、最も適切な用語の組み  
合わせを下記の解答群から選べ。  
設備総合効率＝時間稼働率×性能稼働率×Ａ  
ただし、  
時間稼働率＝（負荷時間－Ｂ）÷負荷時間  
性能稼働率＝（Ｃ×加工数）÷稼働時間  
〔解答群〕  
  
ア  Ａ：不良率Ｂ：設備稼働時間Ｃ：製造リードタイム  
  
イ  Ａ：不良率Ｂ：設備停止時間Ｃ：製造リードタイム  
  
ウ  Ａ：良品率Ｂ：設備稼働時間Ｃ：基準サイクルタイム  
  
エ  Ａ：良品率Ｂ：設備停止時間Ｃ：基準サイクルタイム  
`
        },
        {
                "mondaiId": 104210200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
生産ラインの生産性の向上を狙った次の改善施策のうち、最も不適切なものはど  
れか。  
  
ア  異品の組付けによる不良発生を防ぐために、部品棚にポカよけ装置を設置し  
た。  
  
イ  各工程の作業者の作業効率を高めるために、部品や工具をできるだけ作業者の  
作業位置に近づけて供給した。  
  
ウ  生産ラインのバランスロスを抑制してライン全体の生産効率を高めるために、  
直線ラインをU字化し、作業分担を見直した。  
  
エ  段取作業による生産ラインの停止時間を抑制するために、外段取作業を内段取  
化した。  
`
        },
        {
                "mondaiId": 104210210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
大規模小売店舗立地法に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  同法では小売業が対象業種であるが、飲食店業は含まれていない。  
  
イ  同法では店舗周辺の地域の住民の利便及び商業その他の業務の利便の双方への  
配慮を求めている。  
  
ウ  同法の適用対象となる店舗面積は５００平方メートルを超えるものである。  
  
エ  同法の店舗面積に含まれる売場とは直接物品販売の用に供する部分をいう。`
        },
        {
                "mondaiId": 104210220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
商店街の空洞化の原因に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  大型店などの商業施設がスプロール的に郊外やロードサイドに進出したこと。  
  
イ  商店街として消費者のニーズに的確に十分対応しきれなかったこと。  
  
ウ  商店街の中に大型店の進出を受け入れたこと。  
  
エ  人口が郊外化するとともに、公的施設の郊外への移転が進んだこと。  
  
オ  都市や町の中心部の商店街が自動車社会に十分対応できなかったこと。  
`
        },
        {
                "mondaiId": 104210230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
商圏に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ある地域の消費者が、ある商業集積へ買い物に行く確率を求める公式として  
「ハフ・モデル」がある。  
  
イ  一般的に、買回品を扱う小売業の商圏より、最寄品を扱う小売業の商圏の方が  
狭い。  
  
ウ  商圏を把握するために類似商圏をいくつか選ぶ公式として、「ライリー・コン  
バースの法則」がある。  
  
エ  商店街の診断などで行われる商圏調査では、商圏は\u0002次商圏、\u0003次商圏、\u0004次  
商圏・影響圏などに分けられる。  
  
オ  通信販売やインターネットを通じた電子商取引は、従来の商圏という概念に制  
約されない。  
`
        },
        {
                "mondaiId": 104210241,
                "name": "第24問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
ある小売店の平成２０年度の営業実績は次の通りであった。下記の設問に答え  
よ。  
・売上高１，５００万円  
・売上原価１，０５０万円  
・期首在庫高（売価）６００万円  
・期末在庫高（売価）４００万円  
・売価値入率４０％  
（設問1））  
この小売店の平成２０年度のGMROIとして最も適切なものはどれか。  
  
ア  １００％イ１３０％ウ１５０％エ１７０％`
        },
        {
                "mondaiId": 104210242,
                "name": "第24問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
この小売店の平成２０年度の交差主義比率として最も適切なものはどれか。  
  
ア  ８０％イ８５％ウ９０％エ９５％  
`
        },
        {
                "mondaiId": 104210250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
平成２０年度に改正された省エネ法（エネルギーの使用の合理化に関する法律）に  
関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  これまで一定規模以上の大規模な工場などに対しエネルギー管理の義務を課し  
ていたが、事業者単位でのエネルギー管理が義務付けられることになる。  
  
イ  同法の対象となる事業者の目安は、小売店舗であれば約\u0002万m２以上、コンビ  
ニエンスストアであれば７０店舗以上とされている。  
  
ウ  特定事業者及び特定連鎖化事業者は、エネルギー管理統括者などを選任し、企  
業全体としてのエネルギー管理体制を推進することが義務付けられている。  
  
エ  年間のエネルギー使用量が一定以上であれば、そのエネルギー使用量を経済産  
業局に届け出て、特定事業者または特定連鎖化事業者の指定を受けることが必要  
になる。`
        },
        {
                "mondaiId": 104210260,
                "name": "第26問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
  
イ  ンストア・マーチャンダイジング（以下、「ISM」という。）に関する説明とし  
て、最も適切なものはどれか。  
  
ア  ISMは、買い上げ点数の増加に加え、来店回数の増加、人件費の削減、在庫  
の削減などを期待することができる。  
  
イ  ISMは、価格を主要な操作手段としている。  
  
ウ  ISMは、消費者の計画購買を促進することを主たる目的としている。  
  
エ  ISMは、来店客数の増加を目指して様々な方策を展開することである。  
`
        },
        {
                "mondaiId": 104210270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
  
インストア・プロモーション（以下、「ISP」という。）に関する説明として、最も  
不適切なものはどれか。  
  
ア  ISP計画を立案するにあたっては、カテゴリーとブランドの現状分析を実施す  
る。  
  
イ  ISPの手法は多様であり、適切に組み合わせることで高い効果が期待できる。  
  
ウ  ISPは非価格主導型が主になっている。  
  
エ  ISPを実施する商品を決定するにあたっては、消費者の商品の購買履歴から購  
入金額と購入数量並びに購入間隔などを分析することが望ましい。`
        },
        {
                "mondaiId": 104210280,
                "name": "第28問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
店舗での商品陳列に関する説明として、最も適切なものはどれか。  
  
ア  入口周辺に比較的高価格な商品を陳列し、店奥には比較的低価格な商品を陳列  
することに留意すると、心理的抵抗を和らげることができる。  
  
イ  計画的に購買される傾向のある商品群は入口周辺に陳列し、計画的に購買され  
る傾向が弱い商品群は店奥に陳列する。  
  
ウ  購買頻度や使用頻度の高い商品群を店奥に陳列し、購買頻度や使用頻度の低い  
商品群は入口周辺に陳列する。  
  
エ  商品ごとの売上げに応じてスペース配分を決め、陳列方法やゴールデン・ゾー  
ンなどを考慮する。  
`
        },
        {
                "mondaiId": 104210290,
                "name": "第29問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
\u0002PL（ThirdPartyLogistics）に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0002PL事業者には、自社で輸送手段や保管施設を保有している者と保有してい  
ない者がある。  
  
イ  \u0002PLを活用する場合も、物流拠点ネットワークの設計などは荷主側で行う必  
要がある。  
  
ウ  荷主にとって\u0002PLを活用する利点は、コスト削減のみならず高度な物流サー  
ビスの提供を受けることが可能となることにある。  
  
エ  ロジスティクス・コスト削減の成果を荷主と\u0002PL事業者で分け合う包括契約  
を締結する場合がある。`
        },
        {
                "mondaiId": 104210300,
                "name": "第30問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
物流サービスに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  適正な物流サービス水準は、その物流サービスの実現に費やされるコストを最  
低にする水準で決定するのが望ましい。  
  
イ  物流サービスの内容と水準は、荷主と物流事業者もしくは\u0002PL（ThirdParty  
Logistics）事業者の間で明確にすることが望ましい。  
  
ウ  物流サービスは、KPI（KeyPerformanceIndicator）によって実績管理が行わ  
れることが望ましい。  
  
エ  物流サービスは、リードタイムや注文充足率など複数の管理指標によって測定  
することが可能である。  
`
        },
        {
                "mondaiId": 104210310,
                "name": "第31問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
小売店舗への一括物流に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  一括物流とは、従来、卸売業などがそれぞれ行っていた小売店舗への物流を一  
括納品管理する、特定小売業用の物流センターを活用した物流システムである。  
  
イ  一括物流を導入する目的としては、一般的に小売店頭作業の軽減におくことが  
多い。  
  
ウ  通過型物流センターでは、物流センターで店別の仕分けを行う場合がある。  
  
エ  通過型物流センターは在庫型物流センターに比べて、小売業からの受注後納品  
完了までのリードタイムは短くなる傾向にある。`
        },
        {
                "mondaiId": 104210320,
                "name": "第32問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
輸送ネットワークに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  一般的に在庫コスト（リスク）が増大すると、物流（在庫）拠点は集約する必要性  
が高まる。  
  
イ  一般的にハブ・アンド・スポーク・システムでは、ポイント・トゥ・ポイン  
ト・システムよりも輸送効率は低下する。  
  
ウ  一般的に物流拠点数を増やすと店舗への配送コストは減少するが、保管コスト  
は増大する。  
  
エ  輸送手段は、輸送距離、輸送量などの基本的な輸送条件に加えて、貨物の特  
性、運賃負担力、輸送リードタイム、輸送頻度などの諸条件を勘案して決めるこ  
とが望ましい。  
`
        },
        {
                "mondaiId": 104210330,
                "name": "第33問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３３問  
物流に関連した法律に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  省エネ法では、保有車両台数２００台以上の貨物輸送事業者、年間の委託輸送量  
が３，０００万トンキロ以上の荷主などには省エネルギーについての取り組み義務が  
課せられる。  
  
イ  道路交通法では、放置違反の標章が付けられた車両について運転者が反則金を  
納付しない場合、当該車両の使用者が反則金を納付しなければならない。  
  
ウ  物流三法とは、貨物自動車運送事業法、貨物利用運送事業法、鉄道事業法であ  
る。  
  
エ  物流総合効率化法における流通業務総合効率化事業の実施主体は、貨物運送業  
や倉庫業などの物流専業者でなければならない。`
        },
        {
                "mondaiId": 104210340,
                "name": "第34問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３４問  
小売業などの荷受け側が納入業者を巡回して集荷する「引取物流」に関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  小売業などの荷受け側が自ら引取物流のための輸送システムを構築することに  
よって、自社にとって望ましい時間帯に効率的に集荷できることが、引取物流の  
目的の一つである。  
  
イ  引取物流では、小売業などの荷受け側が専用物流センターに集荷するのが一般  
的である。  
  
ウ  引取物流では、小売業などの荷受け側が自ら所有するトラックや物流専業者の  
トラックをメーカーや卸売業者に差し向けて仕入商品を引き取る。  
  
エ  引取物流では、引取のための輸送に要したコストの負担をメーカーや卸売業者  
に求めることはない。  
`
        },
        {
                "mondaiId": 104210350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３５問  
JANコードに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  インストアマーキングには、バーコードの中に価格情報が含まれないPLU  
（PriceLookUp）タイプがある。  
  
イ  商品アイテムコードの設定において、希望小売価格が異なる場合は、異なる商  
品アイテムコードを設定することが原則である。  
  
ウ  ソースマーキングに用いられるJANコードの先頭\u0002桁は国番号であり、当該  
製品の原産国を表している。  
  
エ  ひとつの製造業者に割り当てられる商品アイテムコードの桁数には限りがある  
ため、何年かすると番号が一巡し、異なる商品に同じコードが付番される可能性  
がある。`
        },
        {
                "mondaiId": 104210360,
                "name": "第36問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３６問  
EDIに関する記述として最も適切なものはどれか。  
  
ア  Web-EDIでは、Webサーバーを構築すればよいので、ASPは利用されない。  
  
イ  Web-EDIでは、XMLという言語を用いたメッセージを送受信するため、メッ  
セージの設定において自由度が限定される。  
  
ウ  Web-EDIでは、社内システムと連携する際に手作業が発生する場合が多く、  
全体としての効率化に必ずしも結び付かないことがある。  
  
エ  Web-EDIは、画像データを取り扱うことができない。  
`
        },
        {
                "mondaiId": 104210370,
                "name": "第37問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３７問  
食品のトレーサビリティの効果に関する記述として、最も不適切なものはどれ  
か。  
  
ア  問題が起きないように、食品の安全管理を直接的に行うこと。  
  
イ  問題が発生した場合に、安全な他の流通ルートを確保すること。  
  
ウ  問題が発生した場合に、対象商品を特定して迅速に回収すること。  
  
エ  問題への対応だけでなく、在庫管理、物流管理を効率化すること。  
  
オ  問題への対応だけでなく、生産や加工方法などに関する情報を提供すること。  
`
        },
        {
                "mondaiId": 104210380,
                "name": "第38問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３８問  
商取引の電子化にともない、データベース化された取引先や顧客の個人情報の利  
用機会が拡大している。一方で、個人情報の活用だけでなく、個人の権利利益の保  
護の重要性が指摘されている。このとき、いわゆる個人情報保護法（個人情報の保  
護に関する法律）に関する次の文中の空欄ＡとＢに入る最も適切なものの組み合わ  
せを下記の解答群から選べ。  
個人情報保護法の義務規定の対象となる事業者は、その事業の用に供する個人情  
報データベース等を構成する個人情報によって識別される特定個人の数の合計が過  
去Ａ以内のいずれかの日においてＢを超える者である。  
〔解答群〕  
  
ア  Ａ：\u0002年Ｂ：５，０００  
  
イ  Ａ：\u0002年Ｂ：１０，０００  
  
ウ  Ａ：\u0003か月Ｂ：５，０００  
  
エ  Ａ：\u0003か月Ｂ：１０，０００  
`
        },
        {
                "mondaiId": 104210390,
                "name": "第39問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３９問  
電子マネーに関する次の文中の空欄Ａ～Ｃに入る最も適切なものの組み合わせを  
下記の解答群から選べ。  
電子マネーは、ネットワーク型とICカード型に大別される場合がある。前者  
は、あらかじめ用意した専用の電子財布に金額情報を保管しておき、インターネッ  
トなどのネットワーク上でのみ使用できるものである。後者は、ICチップに金額  
情報を入力して使用する。現在では、Ａを利用したものが主流である。さ  
らに、ICカード型は、あらかじめ金額をチャージしておき、利用の都度、減額さ  
れるＢと、クレジットカードと連携して、クレジットカードと同様に、利  
用金額をまとめて請求されるＣに大別される。  
〔解答群〕  
  
ア  Ａ：接触型IC  
Ｂ：交通系カード  
Ｃ：買い物系カード  
  
イ  Ａ：接触型IC  
Ｂ：プリペイド方式  
Ｃ：ポストペイ方式  
  
ウ  Ａ：非接触型IC  
Ｂ：買い物系カード  
Ｃ：交通系カード  
  
エ  Ａ：非接触型IC  
Ｂ：プリペイド方式  
Ｃ：ポストペイ方式  
  
オ  Ａ：非接触型IC  
Ｂ：ポストペイ方式  
Ｃ：プリペイド方式  
`
        },
        {
                "mondaiId": 105210010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
簡易吸収合併（会社法第７９６条第\u0002項）における吸収合併消滅株式会社、吸収合併  
存続株式会社がとるべき手続について、以下の\u0002から\u0003の点について、会社法の規  
定を比較した。この比較結果を記載した下記の表のうち、誤った内容が含まれてい  
るものを下記の解答群から選べ。  
\u0002株主総会決議による合併契約の承認が必要か否か。  
\u0004自社の株主に対する通知・公告を要するか否か。  
\u0005自社の債権者に対する通知・公告を要するか否か。  
\u0003自社の新株予約権者に対する通知・公告を要するか否か。  
吸収合併消滅株式会社吸収合併存続株式会社  
\u0002必要不要  
\u0004必要必要  
\u0005必要不要  
\u0003必要不要  
〔解答群〕  
  
ア  \u0002  
  
イ  \u0004  
  
ウ  \u0005  
  
エ  \u0003  
`
        },
        {
                "mondaiId": 105210020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A株式会社（以下「A社」という。）は、１００パーセント子会社であるB株式会社に  
対し、A社の事業の一部を分割し、吸収させることを検討している。A社の貸借対  
照表及び分割を検討している資産等の状況は下記のとおりである。これを前提とし  
た簡易吸収分割（会社法第７８４条第\u0002項）に関する説明のうち、最も適切なものを下  
記の解答群から選べ。  
A社貸借対照表  
（単位：百万円）  
資産の部負債の部  
流動資産１，０５０流動負債２５０  
固定資産１，３５０固定負債９００  
負債合計１，１５０  
純資産の部  
資本金４８０  
利益剰余金７７０  
純資産合計１，２５０  
資産合計２，４００負債・純資産合計２，４００  
A社が分割を検討している資産、負債の項目及び帳簿価額  
（単位：百万円）  
資産負債  
流動資産３０流動負債１００  
固定資産２００  
合計２３０合計１００  
        
〔解答群〕  
  
ア  分割対象となる資産合計額から負債合計額を控除した額は\u0002億３，０００万円  
で、A社の総資産額の\u0003分の\u0002を下回っているが、２０分の\u0002を超えるので、  
簡易吸収分割の規定が適用とならず、A社では、吸収分割契約を承認する株主  
総会を開催する必要がある。  
  
イ  分割対象となる資産合計額から負債合計額を控除した額は\u0002億３，０００万円  
で、A社の純資産額の\u0003分の\u0002を下回っているので、簡易吸収分割の規定が適  
用となり、A社では、吸収分割契約を承認する株主総会を開催する必要がな  
い。  
  
ウ  分割対象となる資産額合計は\u0004億３，０００万円で、A社の純資産額の\u0003分の\u0002  
を下回っているが、２０分の\u0002を超えるので、簡易吸収分割の規定は適用とな  
らず、A社では、吸収分割契約を承認する株主総会を開催する必要がある。  
  
エ  分割対象となる資産額合計は\u0004億３，０００万円で、A社の総資産額の\u0003分の\u0002  
を下回っているので、簡易吸収分割の規定が適用となり、A社では、吸収分割  
契約を承認する株主総会を開催する必要がない。  
`
        },
        {
                "mondaiId": 105210030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
個人で雑貨の輸入業を営んでいる甲氏とあなたとの間の以下の会話を読んで、会  
話中の空欄に入る説明として最も適切なものを下記の解答群から選べ。  
甲氏：「先日、ひょんなことから同業者の乙という方と知り合って、会社組織に  
して、一緒に仕事をしようということになったんです。ただ、乙さんも  
私も、ノウハウや在庫はあっても、現金はあまり持っておらず、資本金  
２００万円くらいにしかなりません。これで会社は設立できるのですか。」  
あなた：「今は、資本金２００万円でも株式会社を設立することはできますよ。」  
甲氏：「そうなんですか。でも、資本金２００万円だと、取引先の信用が得られな  
いような気もするんですよね……。うーん……。」  
あなた：「それでしたら、甲さんや乙さんが持っている在庫などを現物出資して、  
資本金に組み入れることを検討してはいかがですか。」  
甲氏：「へえ、そういったことができるのですか。私が保有している在庫などは  
たぶん４００万円分くらいありますから、乙さんのもあわせると資本金は  
１，０００万円くらいになるかもしれませんね。それなら、取引先からも十分  
に信用を得られそうですね。」  
あなた：「」  
        
〔解答群〕  
  
ア  ８００万円を現物出資の金額とすると、裁判所が選任する検査役の調査が必要  
になりますが、例外として、弁護士や税理士などの証明書があれば、検査役の  
調査は不要になります。  
  
イ  現金による出資金と現物出資の金額が併せて５００万円を超えると、裁判所が  
選任する検査役の調査が必要となりますから、現物出資の金額は２８０万円くら  
いにした方がよいでしょう。  
  
ウ  現物出資の金額が３００万円を超えた場合、裁判所が選任する検査役の調査が  
必要になります。そうすると、費用も時間もかかることになりますから、ご注  
意下さい。  
  
エ  そうですね。資本金１，０００万円程度であれば、現物出資をするうえで何の問  
題もありませんから、すぐに現物出資して会社を設立しましょう。  
`
        },
        {
                "mondaiId": 105210041,
                "name": "第4問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
法的倒産手続には、破産、民事再生、会社更生などの手続があるが、設問1）～\u0003  
のグラフA～Cは、平成元年（１９８９年）から平成１９年（２００７年）までの全国の裁判所  
における法的倒産手続（\u0002～\u0003）の新受件数（新たに事件として受け付けられた件数）  
を示したグラフである。これを前提として下記の設問に答えよ。  
（設問1））  
次のグラフAで示されている法的倒産手続\u0002として最も適切なものを下記の  
解答群から選べ。  
グラフA  
〔解答群〕  
  
ア  会社更生  
  
イ  特別清算  
  
ウ  破産  
  
エ  民事再生  
`
        },
        {
                "mondaiId": 105210042,
                "name": "第4問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
次のグラフBで示されている法的倒産手続\u0002として最も適切なものを下記の  
解答群から選べ。  
グラフB  
〔解答群〕  
  
ア  会社更生  
  
イ  特別清算  
  
ウ  破産  
  
エ  民事再生  
`
        },
        {
                "mondaiId": 105210043,
                "name": "第4問設問3",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
次のグラフCで示されている法的倒産手続\u0002～\u0003の説明として、最も不適切  
なものを下記の解答群から選べ。  
グラフC  
〔解答群〕  
  
ア  \u0002の手続は、平成１３年以降には全く利用されていないが、これは手続の  
根拠となる法律が廃止され、この手続自体が利用できなくなったからであ  
る。  
  
イ  \u0004の手続は、裁判所に対する申立てが必要なく、迅速に処理が可能となる  
うえ、税務上のメリットがあることから、赤字子会社を迅速に整理したいと  
きに用いられている。  
  
ウ  \u0005の手続は、他の法的倒産手続に比べ、大型倒産事件と呼ばれる事件のと  
きに用いられることが多いとされている。  
  
エ  \u0003の手続は、平成１９年には全く利用されていないが、これは、平成１８年  
に商法が改正され、会社法が施行された際に、この手続が廃止されたからで  
ある。  
`
        },
        {
                "mondaiId": 105210050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたは、顧客であるA株式会社（以下「A社」という。）の  
代表取締役X氏と話をしていた際、以下のアドバイスを行った。そのアドバイス  
に対応するX氏の話の内容として、最も適切なものを下記の解答群から選べ。な  
お、A社は、取締役会設置会社であり、株主は３０名である。  
【あなたのアドバイス】  
「これから作成しようとしている契約書の内容は、会社法に定める自己取引（利  
益相反取引）に該当しますから、取締役会の承認が必要だと思いますよ。」  
〔解答群〕  
  
ア  会社のトラックを\u0002台買い替えることになって、中古車でかなり状態がいい  
のが見つかったんだけど、２０万円だけ予算をオーバーしててね。それで、私  
が会社に、無利息・無担保で、返済は資金繰りに余裕ができたときという内容  
で２０万円を貸すことになって、今、その契約書を作っているんだ。  
  
イ  会社本社の土地は、私名義の土地で会社に貸していたんだけど、借地料は何  
十年も据え置きにしていたんだ。でも、その間に、固定資産税額が上がって、  
借地料だけでは足りなくなったんだ。だから、今回、借地料を固定資産税額の  
\u0003倍の金額にすることになって、新しい契約書を作っているんだよ。  
  
ウ  当社支店の土地は、地主から借りていて、その土地に会社で建物を建てて  
使っていたんだけど、先日、地主からその土地を買ってくれないかと言われた  
んだ。ただ、会社の資金繰りだと難しいので、私個人で��金を出して私名義で  
買うということになったから、今、売買契約書を作ってもらっているんだ。  
  
エ  私が代表取締役を兼務しているB社は、A社の１００パーセント子会社で、  
C社から機械をリースしていたんだけど、今度、C社から承諾をもらって、A  
社でB社からリースを受けることにしたんだ。今、A社とB社の契約書を  
作っているんだけど、リース料やリース期間なども含め、B社とC社のリー  
ス契約と全く同じ内容にすることになったんだ。  
`
        },
        {
                "mondaiId": 105210060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許法における発明（特許法第\u0002条、第\u0003条）と実用新案法における考案（実用新  
案法第\u0002条、第\u0003条）に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  実用新案法における考案には、方法の考案も対象となっている。  
  
イ  特許法における発明及び実用新案法における考案には、ニュートンの万有引力  
の法則のような発見や自然法則を利用していない人為的な取り決めは該当しな  
い。  
  
ウ  特許法における発明には、物の発明ばかりではなく、方法の発明も対象とな  
る。  
  
エ  特許法における発明は技術的思想の創作のうち高度のものをさしているが、実  
用新案法における考案については高度という限定はなく、技術的思想の創作の程  
度のいかんを問わない。`
        },
        {
                "mondaiId": 105210070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A社の代表取締役社長からの次の質問に対する回答として最も適切なものを下記  
の解答群から選べ。  
【A社の代表取締役社長からの質問】  
「当社は、平成１６年（２００４年）\u0004月に設立され、設立時から苛性ソーダの製  
造・販売を主な事業としていますが、このたびB社から『貴社の苛性ソーダの  
製造方法について弊社の保有する苛性ソーダの製造方法に関する特許権に抵触  
するので直ちに製造・販売を中止し、現在市場に出回っている苛性ソーダを回  
収するように。』との警告書を受け取りました。当社内で調べたところ、この警  
告書に記載されたB社の保有する特許権の番号から特許出願がなされたのは  
平成１７年（２００５年）\u0005月であることが分かりました。この警告書に対してどの  
ように対処すればよいでしょうか。」  
        
〔解答群〕  
  
ア  B社の特許権に係る特許出願の時点で、すでに御社がB社の特許と同一の  
方法により苛性ソーダの製造を行っていたことを立証できれば、B社の特許権  
が存続していても将来にわたり苛性ソーダの製造方法を実施する権利がありま  
す。  
  
イ  B社の特許権は、平成１７年（２００５年）\u0002月に出願されており、まだ特許出願  
日から２０年を経過していないため、現在でも有効に存続していることから、  
すぐに製造・販売を中止し、市場に出回っている御社の苛性ソーダを回収しま  
しょう。  
  
ウ  御社が用いている苛性ソーダの製造方法が、B社の保有する特許権に係る特  
許発明の技術的範囲に属するか否かの判定を特許庁に請求するのがよいと思い  
ます。  
  
エ  御社は、B社の特許権に係る特許出願前から苛性ソーダの製造方法を実施し  
ていたので、B社の特許権に係る特許発明は特許出願前に公然実施された発明  
に該当するとして特許無効の審判を裁判所に請求して、B社とのライセンス交  
渉を行うことがよいと思います。  
`
        },
        {
                "mondaiId": 105210080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
X社の代表取締役社長からの次の質問に対する回答として、最も不適切なものを  
下記の解答群から選べ。  
【X社の代表取締役社長からの質問】  
「当社は、以前からその製造・販売に係る特殊な構造を有するシャープペンシ  
ルについて実用新案権を保有していますが、競争会社Y社が最近同一の構造  
を有すると思われるシャープペンシルを製造・販売するようになりました。こ  
の製造・販売を止めさせたいと思いますが、どのようにすればよいでしょう  
か。」  
〔解答群〕  
  
ア  御社の実用新案権に係る登録実用新案と競争会社Y社の製造・販売に係る  
シャープペンシルの構造が同一であるか調べる必要があります。  
  
イ  実用新案権の存続期間は、特許権の存続期間より短く、実用新案登録出願の  
日から１０年で終了するので、実用新案登録出願の日がいつだったかを確認す  
ることが必要です。  
  
ウ  実用新案権は、特許権と同様に排他的独占権の性質を有しているので、特許  
庁の審査官が作成した実用新案技術評価書を提示しなくても、競争会社Y社  
の製造・販売の中止を求めることはできます。  
  
エ  当初\u0002年間分の登録料は、実用新案登録出願時に一時に納付されています  
が、実用新案権は、第\u0003年分以降の各年分の登録料を特許庁に納付しないと消  
滅しますから、確認が必要です。  
`
        },
        {
                "mondaiId": 105210090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
C社の代表取締役社長からの次の質問に対する回答として最も適切なものを下記  
の解答群から選べ。  
なお、C社の商標◯◯とD社の登録商標◯◯は、同一商標とし、商品「みそ」と  
「菓子（クッキーを含む）」とは非類似の商品とする。  
【C社の代表取締役社長からの質問】  
「当社は、平成２０年（２００８年）\u0002月に、平成１６年（２００４年）１１月頃から製造・  
販売していた商品区分第３０類クッキーについて商標◯◯を商標登録出願しま  
したところ、最近特許庁からD社名義の登録商標◯◯（商品区分第３０類：み  
そ、菓子）｛出願日：平成１５年（２００３年）\u0003月３０日、登録日：平成１６年（２００４  
年）１２月\u0002日｝を引用されて拒絶理由通知書が来ました。当社内で調べました  
ところ、D社は、登録商標◯◯を指定商品中「みそ」については使用している  
ことが判明しました。あと数日中に意見書を特許庁に提出しないといけないよ  
うですが、どのように対処すればよいでしょうか。」  
        
〔解答群〕  
  
ア  御社の商標◯◯とD社名義の登録商標◯◯とは、同一の商標であり、御社  
の商標登録出願に係る指定商品「クッキー」とD社名義の商標登録に係る指定  
商品「みそ、菓子」とは同一若しくは類似の関係にあるため、意見書を提出して  
も仕方がないと思います。  
  
イ  御社の商標◯◯に関しては意見書を提出しても商標登録を受けることはでき  
ないと思いますが、御社の商標◯◯が、その出願前から製造・販売していた商  
なんぴと  
品「クッキー」について使用された結果、何人かの商品であることを需要者の間  
で広く認識することができるに至っている場��であれば、D社の登録商標の  
存在にもかかわらず、その使用を継続することができると思います。  
  
ウ  御社の商標◯◯は、その出願前から製造・販売していた商品「クッキー」につ  
なんぴと  
いて使用された結果、何人かの商品であることを需要者の間で広く認識するこ  
とができるに至っている場合であれば、D社の商標登録に対して登録無効の  
審判を請求して、当該登録無効の審判の審決が確定するまで審査の中止を審査  
官に求める旨を記載した意見書を提出するのがよいと思います。  
  
エ  御社の商標◯◯をその製造・販売に係る「クッキー」について商標登録を受け  
るためには、指定商品「菓子」についてのD社の商標登録を取り消すことが必  
要です。D社の登録商標◯◯は指定商品「菓子」について使用されていないよ  
うですので、D社の指定商品「菓子」についての商標登録に対して不使用を理  
由とする取消審判を請求して、当該取消審判の審決が確定するまで審査の中止  
を審査官に求める旨を記載した意見書を提出するのがよいと思います。  
`
        },
        {
                "mondaiId": 105210100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
A株式会社とB株式会社は、それぞれが有する専門技術を生かして新規のシス  
テムを共同開発することを模索し、まずは秘密保持契約を締結して相互に技術情報  
を相手方に開示しようと合意した。この秘密保持契約に関する記述として最も適切  
なものはどれか。  
  
ア  秘密保持契約において相互に開示したデータや情報について、契約終了後、開  
示した当事者の請求に基づいてそれらを破棄または返却するなどの条項を設けな  
くとも、開示した当事者は受領した当事者に対し、所有権に基づきデータ・情報  
の破棄又は消去を請求できる。  
  
イ  秘密保持契約は、共同開発が本格化した場合に締結される共同開発契約等の本  
契約とは別個に締結されるものであるから、本契約とは別途、秘密保持の対象と  
なるべき情報が授受される期間や当該情報の秘密を保持すべき期間などの条項を  
定めることができる。  
  
ウ  秘密保持契約は、秘密を保持すべき義務を課すものであるが、故意又は過失に  
より秘密を開示されたことによって生ずる損害が明確に立証できないためにいわ  
ゆる紳士協定であるともいわれ、相手方が万一契約違反をした場合であっても、  
他の法令に違反しない限りは相手方に対し何ら権利を主張できない。  
  
エ  平成１７年（２００５年）に改正された新不正競争防止法により、共同開発を目的と  
して開示された情報について、情報を受領した当事者に対し\u0002年間秘密保持義務  
が課されたので、あえて秘密保持契約を締結する必要はない。  
`
        },
        {
                "mondaiId": 105210110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
C株式会社（以下「C社」という。）が、次のような状況にあることを前提として、  
賃貸借契約に関し、最も適切なものを下記の解答群から選べ。  
C社はX氏の所有する倉庫を、倉庫兼事務所として使用するために平成\u0002年  
（１９９０年）\u0003月\u0004日から\u0005年ごとに都度合意して賃貸借契約を更新し、賃借してい  
る。しかし、賃料が近隣の相場より高く、C社の経営状態を圧迫しているために、  
よりコスト負担の少なくなる方策を検討している。なお、本件賃貸借契約におい  
て、C社は賃料\u0006カ月分相当額を「保証金」との名目でX氏に預けている。また、  
来年（平成２２年（２０１０年））\u0007月末には、更新の時期を迎える。  
〔解答群〕  
  
ア  C社は、X氏の了承を得て倉庫に備えつけた汎用的な建具を残して引越費用  
を削減したいとしても、C社とX氏間の賃貸借契約に特段の定めがない場  
合、X氏から「残しても良い」旨の同意がない限り、C社は入室した当初と同様  
の状態にするためのコストを負担しなければならない。  
  
イ  現在のC社近隣の賃料相場と比べてC社の賃料が著しく高くなっている場  
合、C社が支払っている現在の賃料を下げる方法には、X氏と交渉することの  
ほか、法律に基づき、C社はX氏に対し裁判を起こして賃料を下げるように  
請求することもできる。  
  
ウ  引越しするには、次の賃貸借契約締結の際に保証金を引越先のオーナーへ支  
払うこととなっているが、C社は、建物明渡後に返還される保証金から未払い  
賃料を相殺するようにX氏に請求することができるので、期間満了時まで賃  
料の支払いを止めて、引越先の保証金を準備することができる。  
  
エ  本件賃貸借契約の契約書には、更新について「更新料を支払った場合に更新  
できる」と規定があるだけで、特段の記載がない場合、C社が更新時期の\u0002カ  
月以上前にX氏に対して更新をしない旨を通知しないときは、自動的に\u0005年  
間契約が更新される。  
`
        },
        {
                "mondaiId": 105210120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
D株式会社（以下「D社」という。）は、E株式会社（以下「E社」という。）から、E社  
がその著作権および著作者隣接権を保有する音楽コンテンツ（以下「本コンテンツ」  
という。）の管理・運用を依頼され、これをオンライン上で配信してユーザーに課金  
するサービスを提供することを約した。本コンテンツの管理・運用に関するD社  
の著作権法上の義務に関する記述として最も適切なものはどれか。  
  
ア  本コンテンツについて、E社の請求があるときは、原則として、E社に対し配  
信した実績を証明する資料を開示する義務がある。  
  
イ  本コンテンツについて、原則として、その著作者である第三者やE社の指示  
にしたがい、著作者名を表示し、あるいは、表示しない義務がある。  
  
ウ  本コンテンツについて、自社の提供するサービスを通じて音楽コンテンツを取  
得したユーザーが、取得したデータを不正に利用しないように、データに技術的  
な保護手段を講じて管理する義務がある。  
  
エ  本コンテンツについて、ユーザーが不正利用したことが発覚した場合、E社の  
請求があるときは、原則として、配信したユーザーに関する情報をE社に開示  
する義務がある。  
`
        },
        {
                "mondaiId": 105210130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
X社は商品の物流を担っている株式会社であるが、ソフトウェアの開発を専門と  
する株式会社であるY社に対して、事業に使用するシステムに組み込むソフトウェアの開発をしてほしいと考えている。  
そこで、X社は、自社の事業所内の一部を作業場所として提供し、Y社の従業員  
でソフトウェア技術者であるZ氏に、その作業場所を利用して、システム開発お  
よびその保守の作業にあたらせることを予定している。  
次は中小企業診断士のあなたとX社の総務担当部長の会話であるが、会話中の  
空欄Ａ～Ｃに入る用語の組み合わせとして最も適切なものを下記の解答群から選  
べ。  
部長：「Z氏を推薦してきたY社に『早急に新しいシステムがほしいので、来月  
から始めたいね』と言ったら、すぐにＡ書を持ってきたよ。でも  
ね、すべて先方の責任として、だけれども当社の指示はきちんと守るよう  
に、うちの社員と同様に使えるよう、交渉したいなぁと思うのだけど。」  
あなた：「それは、Ｂ書ではないのですか。」  
部長：「違うね。何かまずいの。」  
あなた：「Y社は派遣業者として届出や許可がある業者なのですか。」  
部長：「うーん、わからないねー。」  
あなた：「Y社に届出や許可がないのであれば、Y社との契約はＣなどで  
あることが必要ですから、相手方に一定の裁量を与えなければダメです  
よ。」  
部長：「どうして。」  
あなた：「Ｂは法律で労働者を保護するためにさまざまな規制がありま  
す。これを脱法するためにＣ書にして対応しようとする企業があ  
ります。この契約書のタイトルは『Ａ書』になることもあります。  
ただ、これらは偽装請負や偽装委託などといって、違法な行為になりま  
す。」  
        
部長：「ああ、聞いたことがある言葉だね。そんな悪いことをする会社に思えな  
かったけど。」  
あなた：「Ｃといえるためには、Z氏がその作業を遂行するにあたり、そ  
の遂行方法に関する指示その他の管理をY社が行い、Y社が自らの責任  
で御社に仕事完成物を納品することが必要となります。ですから、契約書  
もY社に管理権限があることになっているのでしょうし、実態もそうで  
なくてはなりません。就業場所が御社ということですと、就業規則なども  
他の社員と同じように守ってもらいたいでしょうし、なかなか難しそうで  
すね。」  
部長：「それでは、このままだと当社は基本的にわざわざY社を通じてZ氏を管  
理しなくてはならないということなんだね。」  
あなた：「そのとおりです。ですから、御社がパート社員のようにZ氏を使いたい  
のであれば、Y社が届出や許可を受けている派遣業者か確認しなけれ��な  
らないと思います。」  
部長：「適法な派遣業者か確認して、Ｂの関係にしてもらうほうがよい  
ということですね。」  
あなた：「その方がコンプライアンスにかなうと思いますよ。」  
〔解答群〕  
  
ア  Ａ：業務委託契約Ｂ：雇用契約Ｃ：準委任契約  
  
イ  Ａ：業務委託契約Ｂ：労働者派遣契約Ｃ：請負契約  
  
ウ  Ａ：準委任契約Ｂ：雇用契約Ｃ：請負契約  
  
エ  Ａ：準委任契約Ｂ：労働者派遣契約Ｃ：業務委託契約  
`
        },
        {
                "mondaiId": 105210140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
次の英文は、カリフォルニア州法人であるABC社と日本法人であるXYZ社の  
間で締結されたABC社が販売するソフトウェアのライセンス契約書の一条文と  
する。この条文の記載内容について、最も適切なものを下記の解答群から選べ。な  
お、「SOFTWARE」とは、本ライセンス契約のライセンスの対象となっているソフ  
トウェア（以下「本ソフトウェア」という）を意味し、また、本契約書はもともと  
ABC社から提案されたもので準拠法はカリフォルニア州法となっている。  
INNOCASEWILL  
ABC  
BELIABLEFORANYCONSEQUENTIAL，SPECIAL，  
INDIRECT，INCIDENTALORPUNITIVEDAMAGESWHATSOEVER（INCLUDING，  
WITHOUTLIMITATION，DAMAGESFORLOSSOFBUSINESSPROFITS，  
BUSINESSINTERRUPTION，LOSSOFBUSINESSINFORMATION，LOSSOFDATA  
OROTHERSUCHLOSS）ARISINGOUTOFTHEUSEORINABILITYTOUSE  
THESOFTWARE，EVENIF  
ABC  
HASBEENADVISEDOFTHEPOSSIBILITYOF  
SUCHDAMAGES.INNOCASEWILL  
ABC  
’SAGGREGATELIABILITYARISING  
OUTOFTHISLICENSEAGREEMENTEXCEEDTHEROYALTIESACTUALLY  
PAIDBY  
XYZ  
FORTHESOFTWARE.  
〔解答群〕  
  
ア  XYZ社が、本条に基づいてABC社に対して損害賠償を請求する場合は、本  
ソフトウェアをABC社に返却しなければならない。  
  
イ  本条がすべて大文字により規定されている理由は、法的な効果と関係なく、  
単に、ABC社のXYZ社に対する取引上の良心に基づき、目立つように記載さ  
れたものである。  
  
ウ  本条の規定は、ABC社がXYZ社に提供した本ソフトウェアを原因として損  
害が発生した場合における、ABC社の損害賠償責任の範囲を限定している。  
  
エ  本ソフトウェアの性能がABC社と合意したレベルに至っていない場合、  
XYZ社は、本条に基づいて、ABC社に対し実際に支払ったライセンス料を返  
金してもらうことができる。  
`
        },
        {
                "mondaiId": 105210150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
A社は、公共のインフラを整備する事業を行うことを目的として、平成２０年  
（２００８年）\u0002月に新機器の開発とその製造に関するコンペティション（入札）を行  
い、その結果、B社の製品を採用することを決定した。  
A社とB社間で製品の仕様・対価・数量の概要が合意され、A社が平成２１年  
（２００９年）\u0003月から新機器を利用したサービス開始を予定していたため、B社は新  
機器の開発やA社の要求する数量を期限までに間に合わせるために、A社とB社  
間で開発及び製造に関する業務開発委託契約書を作成する前に、B社は前倒しで部  
品の調達及び新機器の開発を進めていた。このような事情をA社の担当者は把握  
しており、A社はB社に対して納品がサービス開始の日程に間に合うかというこ  
とを何度となく確認し、B社も急ピッチで作業を行っていた。  
ところが、A社の代表者が平成２１年\u0004月にいわゆるインサイダー取引の容疑で  
刑事訴追を受けたことから、A社の取締役が総入れ替えして新体制となり、事業縮  
小の一環で当該事業も取りやめる旨を決定した。  
結局、A社とB社間では契約書の作成も行われず、B社が調達した部品や開発  
した機器が、A社の当該事業のために特別に発注したものだったため、他に転用す  
ることができず、B社のもとに残った。  
以上のような事情を前提に次のうち最も適切なものはどれか。  
  
ア  A社は、B社との間で契約を締結していないことから、B社に生じた損害を一  
切賠償する必要はない。  
  
イ  A社はB社に対し、契約締結の準備段階における信義則上の注意義務に基づ  
いて、B社が実際に当該機器の開発・製造のために調達した部品の代金の全部又  
はその一部を賠償しなければならない。  
  
ウ  A社はB社に対し、契約を締結しなかったという債務不履行責任に基づい  
て、実際にB社がA社に当該機器を販売した場合に得られるべき利益を賠償し  
なければならない。  
  
エ  A社はB社に対し、その代表者が刑事訴追を受けたという不法行為責任に基  
づいて、B社に生じた損害を賠償しなければならない。  
`
        },
        {
                "mondaiId": 105210161,
                "name": "第16問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　有限責任制の人的会社制度として、会社法に合同会社制度が創設された。また、有限責任事業組合契約に関する法律により、新たな事業体としての有限責任事業組合の設立が可能となった。  
　このほか人的資産の活用としては、民間の行う社会貢献活動の健全な発展を促進する目的で特定非営利活動促進法が施行され、特定非営利活動法人を設立することが可能となっている。  

（設問１）  
　合同会社と有限責任事業組合は共通点も多い。次の説明のうち、<u><u>最も不適切なものはどれか</u></u>。  
  
ア  合同会社、有限責任事業組合の債権者は、当該会社または組合の営業時間内は、いつでも、作成した日から5年以内の計算書類または財務諸表の閲覧または謄写の請求をすることができる。  
  
<hint hidden='true'>**
適切な記述です。
**</hint>

イ  合同会社の常務に属する業務以外の業務は、定款に別段の定めがある場合を除き、社員の過半数をもって決定する。<hintTarget>有限責任事業組合</hintTarget>も重要な財産の処分および譲受けや多額の借財という業務執行を決定するには、総組合員の<hintTarget>過半数</hintTarget>の同意によらなければならない。  

<hint hidden='true'>**
❌有限責任事業組合は、原則、業務執行には<hintTarget>全員</hintTarget>の同意が必要です（過半数でない）。
**</hint>  

ウ  合同会社の設立手続きは、社員になろうとする者が定款を作成し、設立の登記をする時までにその出資の全額を払い込みまたは給付を行う。有限責任事業組合では、各当事者が組合契約書を作成し、それぞれの出資に係る払込みまたは給付の全部を履行する。いずれも、設立時に公証人の定款認証を受ける必要はない。  
  
<hint hidden='true'>**
適切な記述です。
**</hint>

エ  合同会社は定款、有限責任事業組合は総組合員の同意により、その出資者の損益分配の割合を出資の価額に応じたものと異なる割合に定めることができる。

<hint hidden='true'>**
適切な記述です。
**</hint>





<hintTarget></hintTarget>

`
        },
        {
                "mondaiId": 105210162,
                "name": "第16問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文章を読んで、下記の設問に答えよ。  

　有限責任制の人的会社制度として、会社法に合同会社制度が創設された。また、有限責任事業組合契約に関する法律により、新たな事業体としての有限責任事業組合の設立が可能となった。  
　このほか人的資産の活用としては、民間の行う社会貢献活動の健全な発展を促進する目的で特定非営利活動促進法が施行され、特定非営利活動法人を設立することが可能となっている。  


（設問２）  

　合同会社と有限責任事業組合との相違点の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  合同会社は、合同会社名義で特許権の出願ができる。これに対し有限責任事業組合では、有限責任事業組合名義で特許権の出願をすることはできない。  

<hint hidden='true'>**
適切な記述です。
**</hint>  

イ  合同会社は、合名会社、合資会社、株式会社に組織変更することができる。これに対して有限責任事業組合は、このような組織変更ができず、当該有限責任事業組合を解散し、新たに会社を設立しなければならない。  

<hint hidden='true'>**
適切な記述です。
**</hint>  
  
ウ  合同会社は社員１名でも設立できる。これに対し有限責任事業組合は、２名以上の個人または法人の組合員が必要となる。ただし、組合員のうち<hintTarget>過半数</hintTarget>以上は、日本の居住者または内国法人でなければならない。  

<hint hidden='true'>**
❌過半数でなく、<hintTarget>１</hintTarget>名以上です。
**</hint>

エ  合同会社は、配当額が当該利益の配当をする日における利益額を超える場合には、当該利益の配当をすることができない。有限責任事業組合の組合財産は、その分配の日における純資産額から組合員の出資の総額と３００万円のいずれか小さい額を控除した額を超えて分配することができない。  

<hint hidden='true'>**
適切な記述です。
**</hint>  


`
        },
        {
                "mondaiId": 105210163,
                "name": "第16問設問3",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
特定非営利活動法人の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  特定非営利活動法人の役員として、理事\u0002名以上および監事\u0003名以上を置か  
なければならない。なお、役員のうち報酬を受ける者の数は役員総数の\u0002分の  
\u0003以下でなければならない。  
  
イ  特定非営利活動法人は、特定非営利活動を目的としなければならないが、特  
定非営利活動の事業に支障のない範囲で、その他の事業を行うことができる。  
  
ウ  特定非営利活動法人は、毎事業年度初めの\u0002か月以内に、前事業年度の事業  
報告書等ならびに役員名簿等を作成し、定款等とともに、その社員その他の利  
害関係人が閲覧できるよう主たる事務所に備え置かなければならない。  
  
エ  特定非営利活動法人を設立するためには、定款、役員名簿、社員\u0004名以上の  
名簿、設立趣旨書などの必要書類を添付した申請書を所轄庁に提出して、設立  
の認証を受けなければならない。  
`
        },
        {
                "mondaiId": 105210170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 5,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文中の空欄に入る最も適切なものを下記の解答群から選べ。  
証券取引所に新規に上場申請する会社が、上場申請日から上場日の前日までの期  
間に行う株式の公募または売出し（以下「上場前の公募等」という。）の価格（以下「公  
開価格」という。）の決定については証券取引所の定める方法で行わなければならな  
い。  
証券取引所は、その規則で、上場前の公募等を行う場合にはか競争入  
札による公募等のいずれかの手続きを行わなければならないと定めている。  
このうち、とは、新規上場申請会社および引受証券会社が、その申請  
会社の財政状態および経営成績、ならびに、有価証券の投資に係る専門知識および  
経験を有する者の意見等を総合的に勘案した公開価格に係る仮条件を決定し、それ  
をもとに投資者からの需要状況を調査する手続きである。公開価格は、  
により把握した投資者の需要状況に基づき、上場日までの期間における株式相場の  
変動により発生し得る危険および需要見通しを総合的に勘案して決定される。  
〔解答群〕  
  
ア  比例配分方式  
  
イ  ブック・ビルディング  
  
ウ  マーケット・メイク  
  
エ  類似会社比準方式  
`
        },
        {
                "mondaiId": 105210181,
                "name": "第18問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
会社法では、株式会社は株主との合意により当該会社の株式（以下「自己株式」と  
いう。）を有償で取得することができると定めている。それに関連した以下の設問に  
答えよ。  
なお、本問で想定している会社は、株式会社で取締役会設置会社であるが、会計  
監査人設置会社ではない。また、発行する株式には、譲渡制限が付されており、異  
なる種類の株式はなく、市場価格がないものとする。  
（設問1））  
会社法では、株式会社は株主との合意によりすべての株主に申し込み機会を与  
えて自己株式を有償で取得することができると定めている。  
このような有償取得を行うに当たって、会社法で定められた手続きや方法およ  
び財源規制についての説明として、<u>最も不適切なものはどれか</u>。  
  
ア  このような有償取得では、あらかじめ株主総会で株式の取得に関する事項を  
決議しなければならない。この決議は臨時株主総会でもよいが、取得すること  
ができる期間は、次回の定時株主総会の期日を越えることはできない。  
  
イ  このような有償取得では、その都度、取締役会で取得する株式の数、株式\u0002  
株を取得するのと引換えに交付する金銭等の内容等およびその総額、株式の譲  
り渡しの申し込みの期日を決定し、株主に通知しなければならない。  
  
ウ  このような有償取得では、当該行為により株主等に対して交付する金銭等の  
帳簿価額の総額は、当該行為がその効力を生ずる日における分配可能額を超え  
てはならない。  
  
エ  このような有償取得では、譲り渡しの申し込みの期日において、株主の申込  
総数が、株式会社が決定した取得総数を超えるときは、取得総数を申込総数で  
除して得た数に株主が申し込みをした株式の数を乗じて得た数の株式の譲り受  
けを承諾したものとみなされる。  
`
        },
        {
                "mondaiId": 105210182,
                "name": "第18問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
会社法では、株式会社は株主との合意により特定の株主から自己株式を有償で  
取得することができると定めている。  
この場合、特定の株主だけが株式を会社に売却できるのでは、株主平等原則を  
損なうおそれがある。このため会社法では、他の株主に、当該特定の株主に加え  
て自己をも売主とするよう請求できる権利（以下「売主追加請求権」という。）を定  
めている。  
この規定についての説明として、<u>最も不適切なものはどれか</u>。  
  
ア  会社が、株主の相続人からその相続により承継した自己株式を取得する場合  
には、売主追加請求権の規定は適用されない。ただし、当該相続人が株主総会  
���すでに議決権を行使した場合はこの限りではない。  
  
イ  会社が、その子会社の有する当該会社（親会社）の株式を取得する場合には、  
売主追加請求権の規定は適用されない。  
  
ウ  特定の株主から自己株式を有償で取得することについて、売主追加請求権の  
規定を適用しない旨を定款で定めることができる。この定めをするには、株主  
総会の特別決議が必要となる。  
  
エ  特定の株主から自己株式を有償で取得するときには、あらかじめ株主総会の  
特別決議が必要である。この場合には、特定の株主は議決権を行使することが  
できない。ただし、特定の株主以外の株主の全部が議決権を行使することがで  
きない場合はこの限りでない。  
`
        },
        {
                "mondaiId": 106210010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
各種業務において、パーソナルコンピュータ（PC）を利用して、データ交換を  
行ったり、画像データなどを取り扱ったりする場面が増加している。そのような場  
面で利用される外部記憶装置に関する、次の文中の空欄Ａ～Ｄに入る語句の組み合  
わせとして最も適切なものを下記の解答群から選べ。  

デジタルカメラなどで撮影した画像データの記録や各種のデータ交換に使用さ  
れ、（　Ａ　<hint hidden='true'>**
USB
**</hint>）を経由してPCに接続しデータの読み書きを行うことができる半導  
体メモリは、（　Ｂ　<hint hidden='true'>**
フラッシュメモリ
**</hint>）と呼ばれる。  
PCに（　Ａ　<hint hidden='true'>**
USB
**</hint>）経由で、この（　Ｂ　<hint hidden='true'>**
フラッシュメモリ
**</hint>）を接続する際は、（　Ｃ　<hint hidden='true'>**
ホットプラグ
**</hint>）機能によ  
りPCの（　Ｄ　<hint hidden='true'>**
リフレッシュ（再起動）
**</hint>）を行わなくとも使用することができる。  
〔解答群〕  
  
ア  Ａ：IDEＢ：DRAM  
Ｃ：デバイスドライバＤ：デフラグメンテーション  
  
イ  Ａ：PCIＢ：フラッシュメモリ  
Ｃ：デバイスドライバＤ：リフレッシュ  
  
ウ  Ａ：USBＢ：SRAM  
Ｃ：プラグアンドプレイＤ：リフレッシュ  
  
エ  Ａ：USBＢ：フラッシュメモリ  
Ｃ：ホットプラグＤ：再起動<hint hidden='true'>**
←⭕
**</hint>    
`
        },
        {
                "mondaiId": 106210020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務に使用してきたPCの取り換えを検討している。複数メーカのPCを、プロ  
グラム処理速度に関して比較する場合の記述として、最も適切なものはどれか。  
  
ア  PCに内蔵されているCPUのクロック周波数が\u0002倍になれば、プログラムの処  
理速度もクロック周波数に比例して\u0002倍になる。  
  
イ  外部記憶装置などを接続するインタフェースとして、パラレルATAが装備さ  
れた機種よりもシリアルATAが装備された機種を選択する方が、プログラム処  
理速度向上の点では望ましい。  
  
ウ  内蔵ハードディスクの回転速度、データ転送速度、MTBFがプログラム処理  
速度に影響を与える。  
  
エ  マザーボード上に装着するRAMは、CPUの処理能力に合った容量であれば  
よく、それを超えて装着してもプログラムの処理速度は向上しない。  
`
        },
        {
                "mondaiId": 106210030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
各種業務においてPCを利用していると、文書、表計算、画像などのデータが  
日々蓄積する。これらのデータの交換を行ったり、バックアップをする目的で、外  
部記憶装置が利用される。現在利用できる外部記憶装置用の記録媒体に関する記述  
として最も適切なものはどれか。  
  
ア  ３．５インチ光磁気ディスクは最大２．３GB程度の記憶容量であるが、メディア  
自身の耐久性はCDやDVDに比べて高い。また、MediaIDと呼ばれる著作権  
保護の仕組みが利用できる媒体もある。  
  
イ  \u0002インチCDメディアに記録できるデータ量は、３．５インチフロッピーディス  
ク約５，０００枚程度である。  
  
ウ  \u0002インチDVDメディアには片面のみ記録可能であるが、\u0003層の他、\u0004層に書  
き込み可能なものがあり、記録できるデータ量は\u0002インチCDメディアの約１００  
枚程度である。  
  
エ  現在市販されているブルーレイディスクの\u0002インチメディアには、２５GB程度  
の容量のものと５０GB程度の容量のものがあるが、一方はディスクの片面を、  
他方は両面を利用している。  
`
        },
        {
                "mondaiId": 106210040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
さまざまな業種においてeビジネスとのかかわりが求められている。自社製品の  
告知などのためにWebサーバの運用を行うことがeビジネスの第一歩であり、そ  
のためには情報発信に利用するHTMLの特徴に注意を払う必要がある。HTMLに  
関する記述として最も適切なものはどれか。  
  
ア  HTMLではタグと呼ばれる形式で各種の指示を文書中に埋め込むので、指示  
の開始と終了を記述する必要がある。  
  
イ  HTMLで指定できる文字の色数は２５６種類である。  
  
ウ  HTMLにはリンク機能があり、アンカーによって示された文字や画像をマウ  
スでクリックして、異なったページを表示させることができる。  
  
エ  HTMLには表やグラフの表示機能がないので、スクリプト言語を併用して表  
示させたり、他のソフトウェアを使用して作成した表やグラフを画像データに変  
換して表示させたりする必要がある。  
`
        },
        {
                "mondaiId": 106210050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
社員表（氏名、年齢、住所、標準給与月額）のデータが配列に格納されている。  
このデータの操作に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  社員表のデータは一次元の配列では処理できないので、二次元の配列を利用す  
る。  
  
イ  社員表の中の\u0002つの項目、例えば年齢を対象に並べ替えを行うと、最初に入力  
したデータ順に戻せなくなる場合がある。  
  
ウ  データ検索を二分探索法で行いたい場合は、検索対象の項目をキーとしてあら  
かじめ並べ替える必要がある。  
  
エ  配列において、社員表のデータの他に項目ごとにリスト構造を設け、画面に表  
示させる際にリスト構造を利用して並び順を変えて表示させれば、元のデータの  
順番を変えなくても済む。  
`
        },
        {
                "mondaiId": 106210060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
各種業務においてインターネットの仕組みを活用する場面が増えている。システ  
ム開発者ばかりでなく、PCの有効活用やeビジネスへ積極的な取り組みをする立  
場からも、インターネットの基礎的な仕組みの理解は重要である。インターネット  
の仕組みに関する記述として最も適切なものはどれか。  
  
ア  URLでインターネットのリソースを指定する場合、ホスト名の代わりにIPア  
ドレスを用いることも可能である。  
  
イ  Webサーバ内にHTMLファイルを格納すると、HTMLファイルのリンクに  
関する記述がDNSサーバに登録される。登録後はインターネットに接続された  
他のPCから、いつでも当該HTMLファイルが閲覧できる。  
  
ウ  Webブラウザ上で電子メールの送受信を行うWebメールでは、SNTPプロト  
コルを利用してメールの送受信を行う。  
  
エ  Webブラウザのアドレス欄において、URLで指定した閲覧先のWebサーバ  
内のファイルは、POP\u0002プロトコルによってWebブラウザ側のPCに送信され  
る。  
`
        },
        {
                "mondaiId": 106210070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年のシステム開発においてはデータベースが重要になっており、多様な方式の  
データベースが現れている。データベースに関する記述として最も適切なものはど  
れか。  
  
ア  XMLデータベースには、管理方式としてネイティブ方式とハイブリッド方式  
があるが、両者ともリレーショナルデータベースの機能を持つ。  
  
イ  外部スキーマとは、データベースのデータを記憶媒体にどのように格納するか  
を記述したものである。  
  
ウ  第一正規形とは、主キー以外のデータ項目が主キーによってのみ特定できる形  
式をいう。  
  
エ  リレーショナルデータベースを管理するソフトウェアをRDBMSと呼ぶよう  
に、オブジェクト指向データベースを管理するソフトウェアをOODBMSと呼  
ぶ。  
`
        },
        {
                "mondaiId": 106210080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
３０坪程度の面積のオフィスに下図に示すようなLANを構築した。このLANの  
接続形態などに関する記述として最も適切なものを下記の解答群から選べ。  
ただし、PC―AからPC―Dは\u0002Gbps対応のLANカードを有し、LANと接続  
している。また、無線LAN内蔵のノートPCは、オフィス内で無線LANを利用し  
ている。そして、PC―AからPC―D、ノートPCおよびLAN対応ハードディスク  
とLAN対応プリンタには、ブロードバンドルータのDHCP機能を利用してローカ  
ルIPアドレスが割り当てられている。さらに、LAN内の機器間の通信を理論値の  
速度で行うことは、種々の要因で現実的には難しいが、本問では、理論値で通信が  
できるものと仮定する。  
        
〔解答群〕  
  
ア  PC―AからLAN対応プリンタで印刷したい場合、プリンタをスイッチング  
ハブ―Eに接続し直す必要がある。  
  
イ  PC―AとPC―Dとの間でデータ交換の速度が遅い場合、PC―Dをスイッチ  
ングハブ―Eに接続し直せば速度は上がる可能性がある。  
  
ウ  インターネットに接続したいPCにはグローバルIPアドレスを割り当てな  
ければならない。  
  
エ  無線LAN内蔵ノートPCからデータをLAN対応ハードディスクにコピーし  
たい場合、ノートPCをスイッチングハブ―Eに有線接続しなければならな  
い。`
        },
        {
                "mondaiId": 106210090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータの利用によって、画像、音楽、動画などを利用したプレゼンテー  
ション用資料の作成が行われるようになっている。このようなマルチメディアデー  
タを扱うために複数のデータ形式が存在する。それらの特色に関する記述として最  
も適切なものはどれか。  
  
ア  GIF，PNGは静止画像を扱うデータ形式で、データの圧縮を行って保存する。  
これらのデータ形式は、元のデータが完全に再現できる可逆圧縮方式を採用して  
いるので、圧縮したデータを元に戻した場合、画像の劣化が起こらない。  
  
イ  JPEG，TIFF，MIDIは静止画像を扱うデータ形式で、ワープロソフト上でこ  
れらのデータを取り込んで表現力の高いプレゼンテーション用資料を作成するこ  
とができる。  
  
ウ  MPEG\u0002，MPEG\u0003，MP\u0004は動画を扱うデータ形式で、Webブラウザによっ  
てダウンロードする際は、ストリーミング方式によりデータをダウンロードしな  
がら視聴することができる。  
  
エ  WAVE，WMA，BMPは音声や音楽などを扱うデータ形式で、データの圧縮  
を行って保存する。インターネット上での音楽配信によく利用される。  
`
        },
        {
                "mondaiId": 106210100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
下表は、いくつかの商品の２００９年\u0002月\u0002日から\u0003日までの売上履歴である。表  
の\u0004行を\u0004件の販売履歴としたとき、\u0002件以上の販売履歴がある商品について、そ  
の商品名、販売件数、販売数量の平均、を求め、それらを販売数量の平均の降順に  
表示したい。それを行うためのSQL文を下記に示す。文中の空欄Ａ～Ｄに入る記  
述の組み合わせとして最も適切なものを下記の解答群から選べ。  
売上表  
商品コード商品名販売年月日販売単価販売数量顧客コード  
１１００１砂糖２００９/２/２１００１０００９０２０２０００１  
１２００１小麦粉２００９/２/２６０３１００９０２０２０００１  
１１００２黒砂糖２００９/２/３１３０５００８１２２０００１０  
１１００１砂糖２００９/２/４１００１２００７０６１７００２１  
１２００１小麦粉２００９/２/５６０２２００８０１２００００２  
１３００１きな粉２００９/２/５８０１１００８０２２０００８５  
１２００１小麦粉２００９/２/７６０１５００７０５１０００３３  
１１００３グラニュー糖２００９/２/７１２０３００９０２０２０００１  
１１００１砂糖２００９/２/８１００２０００８１２２０００１０  
SQL文  
SELECT商品名，Ａ，AVG(販売数量)  
FROM売上表  
GROUPBY商品名  
HAVINGＢ  
ＣAVG(販売数量)Ｄ  
〔解答群〕  
  
ア  Ａ：COUNT(*)Ｂ：COUNT(*)>2Ｃ：WHEREＤ：ASC  
  
イ  Ａ：COUNT(*)Ｂ：COUNT(*)>=2Ｃ：ORDERBYＤ：DESC  
  
ウ  Ａ：COUNT(商品名)Ｂ：COUNT(商品名)>1Ｃ：WHEREＤ：DESC  
  
エ  Ａ：COUNT(商品名)Ｂ：COUNT(商品名)>=2Ｃ：ORDERBYＤ：ASC  
`
        },
        {
                "mondaiId": 106210110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
業務処理に用いるコンピュータシステムでは、負荷分散、信頼性向上、障害対策  
などに注意を払う必要がある。コンピュータシステム構成方法に関する、次の文中  
の空欄Ａ～Ｄに入る語句の組み合わせとして最も適切なものを下記の解答群から選  
べ。  
信頼性を上げるために\u0002系統のシステムを用意し、処理を並列に行わせて一定時  
間ごとに処理結果の照合を行い、機器故障時は故障した装置を切り離して処理を続  
行する仕組みをＡという。  
一方、複数の演算処理装置を設置するもののＢは共有して利用する方法  
で処理効率を向上させる仕組みをＣという。この仕組みも、一部の演算処  
理装置に故障が発生した場合に処理の続行が可能である。  
コンピュータシステム運用の際、故障が発生しても処理を中断することなく機能  
を維持しようとするシステム構成方法をＤという。  
〔解答群〕  
  
ア  Ａ：タンデムシステムＢ：レジスタや外部記憶装置  
Ｃ：デュプレックスシステムＤ：フールプルーフ  
  
イ  Ａ：デュアルシステムＢ：主記憶装置や外部記憶装置  
Ｃ：マルチプロセッサシステムＤ：フェールソフト  
  
ウ  Ａ：デュプレックスシステムＢ：主記憶装置やキャッシュ  
Ｃ：タンデムシステムＤ：フェールセーフ  
  
エ  Ａ：ロードシェアシステムＢ：レジスタや制御装置  
Ｃ：デュアルシステムＤ：フェールセーフ  
`
        },
        {
                "mondaiId": 106210120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
業務においてRFIDの利用を考慮する必要性が増しているが、このRFIDの利  
用に関する記述の組み合わせとして最も適切なものを下記の解答群から選べ。  

ａICタグは製造時にあらかじめ書き込んだデータを、使用時に非接触の状態で  
読み取り利用するもので、流通段階で発生する管理情報を<hintTarget>追加記録することはできない</hintTarget>。  

<hint hidden='true'>**
❌ICタグには書き込み可能な領域（EPC領域。一般的に96ビットまたは128ビット）があります
**</hint>

ｂICタグが付いた複数の商品がかごに入っている場合も、商品を１つずつ取り  
出すことなく、複数ICタグの情報を一括して認識できる。  

<hint hidden='true'>**
⭕適切な記述です。電波で認識するため（バーコード読み取りに使われる高額読み取り方式と異なり）、一括認識できます。
**</hint>

ｃICタグに納められるデータ量は<hintTarget>数メガバイト</hintTarget>であるので、付与する商品の区  
別のためにEPCなどの識別コードや製造・流通段階の各種管理情報を納めるこ  
とができる。  

<hint hidden='true'>**
❌一般的なICタグの書き込み領域（EPC領域）は96ビットまたは128ビット程度しかありません。
**</hint>
  
ｄRFIDとバーコードなどの既存の仕組みを共存させることも必要で、その際は  
JANコードとEPCコードというように複数コードの同時管理が必要となる。  

<hint hidden='true'>**
⭕適切な記述です。JANコードは商品のSKU単位
SKUの例　カテゴリ「インナー」→アイテム「Yシャツ」→SKU「ブランドA・S・白」  
で付与されますが、個体を管理するための番号がないためJANに個体ごとにシリアル番号を付与したEPCが必要になります。  
このようにすることで、  
例えば同じSKUのシャツを３枚買った場合に、どのシャツが何枚売れたかを正しく識別することができます。
**</hint>


<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【参考】JANコードに個体ごとのシリアル番号を付与したものがEPC 
<img src="https://www.dsri.jp/standard/epc/images/epc_qa6.png"/>
<a href="https://www.dsri.jp/standard/epc/question.html" target="_blank">  
(外部リンクを開きます　電子タグ（EPC/RFID）に関するQ&A：GS1Japan)</a> 
</div>
</hint>  

〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｂとｄ  
`
        },
        {
                "mondaiId": 106210130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
三次元CADによる製品設計、そのデータを利用したデジタルマニュファクチャ  
リングが大企業において実用段階に入っていることから、中小企業もそれらに対応  
していくことが求められるようになってきた。機械系産業に��けるCADのデータ  
の取り扱いに関する記述として最も適切なものはどれか。  
  
ア  CADのデータ標準は確立しているので、どのようなCADシステムを使って  
も他のCADシステムとのデータ交換は問題なくできる。  
  
イ  IGES（InitialGraphicsExchangeSpecification）とは、異なるCADシステム  
間でのデータ伝達の標準を確立すべく、１９８０年に日本の大企業が中心となり策  
定したCADシステム間の標準インタフェース仕様である。  
  
ウ  送り手と受け手が同一のCADシステムを使ってデータ交換を行う方法を、  
ANSIデータの交換という。  
  
エ  製品データの交換に利用できるSTEP（STandardfortheExchangeof  
Productmodeldata）は、「情報表現」と「情報交換」に関する規格で、形状データ  
のみならず構成管理データや運用管理データも交換するためのものである。  
`
        },
        {
                "mondaiId": 106210140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
蓄積した大量のデータをいかに分析するか、部署間で情報共有をいかに行うか、  
さらには新たな知識の創造を行う仕組みをどのように構築するかは、現代企業の重  
要な課題となりつつある。次の記述の空欄Ａ～Ｃに入る語句の組み合わせとして最  
も適切なものを下記の解答群から選べ。  
業務処理で蓄積された多様なデータのデータベースやインターネットから取り込  
んだデータのデータベースなどを、総合的な情報分析に適するように統合したもの  
をＡと呼ぶ。そのＡにある膨大なデータ、例えば、売上履歴にか  
しこう  
かわるデータから消費者の購買行動や顧客の嗜好の変化などを、さまざまな手法を  
用いて分析する技術をＢという。  
一方、企業内での情報共有を進めるに際して、どこにどのような情報があるの  
か、誰がどのような情報を持っているのかなどを明らかにすることが課題である。  
それらの課題の解決を支援するためのシステムをＣと呼ぶ。  
〔解答群〕  
  
ア  Ａ：データウェアハウスＢ：OLAP  
Ｃ：データマート  
  
イ  Ａ：データウェアハウスＢ：データマイニング  
Ｃ：ナレッジポータル  
  
ウ  Ａ：データマートＢ：OLAP  
Ｃ：データマイニング  
  
エ  Ａ：ナレッジポータルＢ：データマイニング  
Ｃ：データウェアハウス  
`
        },
        {
                "mondaiId": 106210150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
情報技術の発展に伴い、計算資源や情報処理サービスのさまざまな提供方法が提  
案されるようになってきた。以下の記述と用語の組み合わせとして最も適切なもの  
を下記の解答群から選べ。  
ａXML形式標準プロトコルでメッセージ送受信を行うインターネット技術を組  
み合わせて、アプリケーションサービスを提供する。  
ｂ仮想化技術を活用して、インターネット経由でユーザが必要とするサービスを  
柔軟に提供する。  
ｃコンピュータのハードウェアやソフトウェアの利用を、買い取りやリースでは  
なく、利用量による従量制で支払う考え方である。  
ｄ多数の小型コンピュータをネットワーク経由で協調処理させる形態で、主な視  
点は処理性能とスケーラビリティにある。  
【用語】  
１SOA（ServiceOrientedArchitecture）  
２クラウドコンピューティング  
３グリッドコンピューティング  
４ユーティリティコンピューティング  
〔解答群〕  
  
ア  ａ―\u0002ｂ―\u0003ｃ―\u0004ｄ―\u0005  
  
イ  ａ―\u0002ｂ―\u0005ｃ―\u0003ｄ―\u0004  
  
ウ  ａ―\u0005ｂ―\u0003ｃ―\u0002ｄ―\u0004  
  
エ  ａ―\u0005ｂ―\u0004ｃ―\u0003ｄ―\u0002  
`
        },
        {
                "mondaiId": 106210160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
近年、多様なシステム開発方法論が提案されている。システム開発方法論に関す  
る記述として最も適切なものはどれか。  
  
ア  ウォータフォール型システム開発方法論では、要件定義などの基本設計の後  
に、システムの機能や構造を決める内部設計が行われ、次にユーザとのインタ  
フェースの内容などを決める外部設計が行われる。  
  
イ  エクストリームプログラミングとは、変化するビジネス環境に対応してソフトウェアを開発する手法の\u0002つであり、\u0003つの価値と１２ないしそれ以上の実践項  
目（プラクティス）の下で迅速なシステム開発を目指すものである。  
  
ウ  スパイラルモデルとは、オブジェクト指向の設計論を用いて、独立性の高い機  
能から開発を始め、開発プロセスを繰り返しながら徐々にシステムの完成度を高  
めていくものである。  
  
エ  プロトタイプを作成して利用するシステム開発方法論では、プロトタイプが問  
題ないことをユーザに確認してもらえば、以降の手続きで構築する本格的なシス  
テムは問題なく稼動する。  
`
        },
        {
                "mondaiId": 106210170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
経済産業省が平成２０年\u0002月２１日に発表した「SaaS向けSLAガイドライン」の内  
容として最も適切なものはどれか。  
  
ア  SaaSはASP（ApplicationServiceProvider）と同様のマルチテナント型サー  
ビスであるとしている。  
  
イ  SaaSベンダのデータセンタでヘルプデスクを提供するが、クライアント側で  
も基本操作に関する管理者やヘルプ対応要員が必要であるとしている。  
  
ウ  サービスレベル目標保証型のSaaSで、サービスレベル未達成に対して、財務  
上の対応として金銭的な補償を取り決める場合には、将来の請求額から差し引く  
のではなく、上限を設定した払い戻しとすることが一般的であるとしている。  
  
エ  利用者のパソコンにクライアントプログラムをインストールして、データセン  
タのサーバ上にあるデータを利用したりクライアントプログラムを更新したりす  
る形態のSaaSを中心に解説している。`
        },
        {
                "mondaiId": 106210180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
システムテストにおけるソフトウェアテストの設計では、まずテストの対象とな  
るシステムを分解・構造化し、次いでテスト実施の観点と実施結果の期待値を設定  
する。これ以降の次の\u0003つの作業の順序として最も適切なものを下記の解答群から  
選べ。  
ａテスト項目に優先度と重要度を付け、実施すべきテスト項目を絞り込む。  
ｂテスト項目とテストケースの関連を明確にする。  
ｃテスト項目を確認する手順とテストデータを決定する。  
〔解答群〕  
  
ア  ａ→ｂ→ｃイａ→ｃ→ｂ  
  
ウ  ｂ→ａ→ｃエｂ→ｃ→ａ  
`
        },
        {
                "mondaiId": 106210190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
情報システムの導入に関連して、ITIL（InformationTechnologyInfrastructure  
Library）が注目されている。これに関する記述として最も適切なものはどれか。  
  
ア  システム開発段階において、開発基盤となるプログラムライブラリを統一管理  
する仕組みである。  
  
イ  実在企業のベストプラクティスが紹介されている。  
  
ウ  情報システムセキュリティに関する基準を体系化したものである。  
  
エ  組織のIT基盤を構成する標準規格を体系化したものである。`
        },
        {
                "mondaiId": 106210200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
インターネットでの暗号通信としてSSL（SecureSocketLayer）が広く利用さ  
れている。今日ではさらに、より安全性の高い次世代SSLとしてEVSSL  
（ExtendedValidationSSL）が普及しつつある。これに関する記述として最も適切  
なものはどれか。  
  
ア  EVSSLの利用で、中間者（man-in-the-middle）攻撃を回避できる。  
  
イ  EVSSLは証明書に、SSLサーバ証明書では必須でなかったドメイン名を記載  
して、サーバを特定している。  
  
ウ  SSLと異なりEVSSLでは、発行者の住所（事業所所在地の要約）が証明書に  
記載され、証明書から発行者の実在性を高いレベルで確認できる。  
  
エ  SSLはセッション層（レイヤ\u0002）で暗号化するが、EVSSLはIP層（レイヤ  
\u0003）で暗号化するので、より安全である。  
`
        },
        {
                "mondaiId": 106210210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
ある企業ではインターネットを通じて商品販売をしている。そのデータ処理の信  
頼性を高めるために、データ同期レプリケーションでゼロデータロスを実現した  
い。この実現形態として最も適切なものはどれか。  
  
ア  RAID\u0002のミラーリングでデータ同期レプリケーションを実現する。  
  
イ  複数のハードディスクに対して相互に書き込み完了を確認し合う形態によっ  
て、データ書き込みを完全二重化する。  
  
ウ  マスターシステムの更新完了後に、予備システムにデータを転送して更新させ  
る形態のデータ同期レプリケーションにする。  
  
エ  マスターシステムのハードディスクへの書き込みに失敗した場合にも、予備シ  
ステムで処理を継続できるようにする。  
`
        },
        {
                "mondaiId": 106210220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
ある企業では、新規事業のための情報システムを新たに開発することとした。そ  
のシステムの開発投資の評価方法に関して関係者が集まり、議論が行われた。その  
議論の一端を下記に示す。文中の空欄Ａ～Ｃに入る語句の組み合わせとして最も適  
切なものを下記の解答群から選べ。  
X氏：「今回のシステムの目的は、どちらかというと、業務処理にかかわるコスト  
削減というよりも、売り上げの増大を目指すシステムだよね。資本回収期間  
法などの一般の設備投資に用いられるような方法よりは、システムが持って  
いる機能が何個あるかをＡとして数えて、複数のシステム開発会社  
から出てきた見積もりをそのＡで割って、その値で評価する方法は  
どうだろうね。」  
Y氏：「しかし、その方法では、コストは評価できるけれど、システムの導入に  
よって得られる価値は評価できないよね。とにかく皆で複数の評価基準を  
作って、それらの評価基準について、関係者が点数を付けて、その結果を見  
ながら皆で議論するＢという方法を採用してみてはどうかな。」  
Z氏：「経営的な視点で情報システムの有効性を評価する方法としてＣが  
あるよ。つまり、情報システムを財務、顧客、業務プロセス、学習と成長の  
\u0002つの視点からみるんだ。」  
〔解答群〕  
  
ア  Ａ：TCOＢ：ファンクションポイント  
Ｃ：バランストスコアカード  
  
イ  Ａ：TCOＢ：ファンクションポイント  
Ｃ：ポートフォリオ分析  
  
ウ  Ａ：ファンクションポイントＢ：TCO  
Ｃ：ポートフォリオ分析  
  
エ  Ａ：ファンクションポイントＢ：スコアリングモデル  
Ｃ：バランストスコアカード  
`
        },
        {
                "mondaiId": 106210230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
ITと環境問題との関連性が、近年、にわかに注目されるようになった。これに  
関する記述として最も適切なものはどれか。  
  
ア  グリーンITの取り組みには、ITを利用することで産業構造を変革し、資源エ  
ネルギーを効率よく活用する仕組みを作るアプローチだけでなく、IT機器その  
ものの消費電力を削減するアプローチも含まれる。  
  
イ  グリーンITの取り組みの\u0002つとしてIT機器の\u0003Rがあるが、事業所の使用  
済みPCの回収・再資源化を希望する法人は、パソコン\u0003R推進センターに回収  
を依頼する。  
  
ウ  経済産業省の広報資料によれば、２０２５年には２００６年に比べて、取扱情報量は  
２，０００倍になり、国内IT機器の消費電力は１２倍になるとされている。  
  
エ  経済産業省は、環境保護と経済成長を両立させるために「グリーンITイニシ  
  
ア  ティブ」を提唱し、２００８年\u0002月に産官学のパートナーシップによる「グリーン  
グリッド」を設立した。  
`
        },
        {
                "mondaiId": 106210240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
ある地域の不動産価格をその床面積で説明する単回帰モデルを作成して計算した  
ところ、次のような結果になった。  
平方和自由度平均平方  
回帰３７８０２３１３７８０２３  
残差６３４４００３５１８１２６  
全体１０１２４２３３６  
非標準化係数標準誤差標準化係数  
（定数）４５３９０．８  
床面積０．３４３０．０７５０．６１１  
この分析結果から明らかになることの説明として最も適切なものはどれか。  
  
ア  F値は２．０程度である。  
  
イ  使用データ数は３６である。  
  
ウ  定数項の標準化係数は\u0002程度である。  
  
エ  床面積のt値は４．６程度である。  
`
        },
        {
                "mondaiId": 106210250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2009,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
建設業工事受注の県別\u0002企業当たり発注元数を、元請工事と下請工事に分け、A  
県とB県で比較するクロス表を作成したところ表\u0002のようになった。この表\u0002に  
基づいて、工事契約の種類と県の違いが相互に独立であると仮定した場合の期待度  
数を計算すると、表\u0003のようになった。  
表\u0002発注元数表\u0003期待度数  
A県B県合計A県B県合計  
元請工事８９１７元請工事８．５８．５１７  
下請工事７６１３下請工事６．５６．５１３  
合計１５１５３０合計１５１５３０  
これから以下の\u0003つの計算を行った。  
y＝  
３０×（\u0004×\u0005－\u0006×\u0007）２  
１７×１３×１５×１５  
z＝  
（\u0004－８．５）２  
８．５  
＋  
（\u0006－６．５）２  
６．５  
＋  
（\u0007－８．５）２  
８．５  
＋  
（\u0005－６．５）２  
６．５  
これらの計算値とχ２分布表を使って独立性検定を行った。  
以下の記述のうち最も適切なものはどれか。  
  
ア  yとzの値は、理論上同じ値となる。  
  
イ  yの計算は平均値の検定の計算である。  
  
ウ  zの計算は平均値の検定の計算である。  
  
エ  正規分布を使う比率の差の検定では、独立性検定と異なる結果になる。  
`
        },
        {
                "mondaiId": 107210011,
                "name": "第1問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
総務省「事業所・企業統計調査」に基づく中小企業庁の推計によると、２００４年～  
２００６年を対象期間とした非一次産業全体の事業所単位での年平均の廃業率は  
６．５％であり、開業率の６．４％を上回る結果となっている。これを２００１年～２００４  
年を対象期間とした推計結果と比較すると、廃業率はＡ、開業率は  
Ｂとなっている。こうした廃業率が開業率を上回る傾向は、１９８９年～  
１９９１年を対象期間とした調査以降、わが国においては一貫して観察される現象で  
あるが、地域や業種によっても差異が見られることには留意する必要がある。  
（設問1））  
文中の空欄ＡとＢに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ａ：上昇Ｂ：低下  
  
イ  Ａ：上昇Ｂ：横ばい  
  
ウ  Ａ：低下Ｂ：上昇  
  
エ  Ａ：横ばいＢ：上昇  
  
オ  Ａ：横ばいＢ：低下`
        },
        {
                "mondaiId": 107210012,
                "name": "第1問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部について、総務省「事業所・企業統計調査」に基づく中小企業庁の  
推計から、２００４年～２００６年を対象期間とした年平均の事業所単位での開業率と  
廃業率を業種別に比較した場合に、最も適切なものはどれか。  
  
ア  卸売業の開業率は製造業の開業率よりも高い。  
  
イ  サービス業の廃業率は小売業の廃業率よりも高い。  
  
ウ  製造業の開業率はサービス業の開業率よりも高い。  
  
エ  製造業の廃業率は卸売業の廃業率よりも高い。  
`
        },
        {
                "mondaiId": 107210021,
                "name": "第2問設問1",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
近年、先進諸国においては、経済のサービス化が進展してきたと指摘されること  
が多い。わが国でも、内閣府「国民経済計算（２００６年度）」によると、  
\u0002  
国内総生産（産  
業計）に占める第三次産業の割合は１９９６年の６４．２％から２００６年には６８．１％に上  
昇している。また、総務省「労働力調査（２００７年）」によると、全就業者数のうち第  
三次産業の就業者数の占める割合も約\u0002割の水準に達しており、同様の傾向がうか  
がえる。こうした中で、総務省「事業所・企業統計調査」に基づき２００１年、２００４  
年、２００６年の\u0003時点の産業別規模別企業数を見ても、  
\u0003  
中小企業の総数（非一次産  
業）のうち第三次産業に属する中小企業の数が占める割合が上昇しており、大企業  
分野にとどまらず、中小企業分野においても経済のサービス化が進展していること  
が分かる。  
（設問\u0004）  
文中の下線部\u0002について、内閣府「国民経済計算（２００６年度）」に基づき、特に  
２００１年以降の国内総生産（産業計）に占める第三次産業の割合の推移を見た場  
合、最も適切なものはどれか。  
  
ア  一貫して上昇している。  
  
イ  上昇した後、２００２年から低下している。  
  
ウ  低下した後、２００４年から上昇している。  
  
エ  低下している。  
  
オ  ほぼ横ばいで推移している。  
`
        },
        {
                "mondaiId": 107210022,
                "name": "第2問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、総務省「事業所・企業統計調査」に基づき２００１年と  
２００６年の産業別規模別企業数を比較した場合、中小企業の総数（非一次産業）の  
うち第三次産業に属する中小企業の数が占める割合が上昇している理由として、  
最も適切なものはどれか。  
  
ア  第三次産業に属する中小企業数の減少以上に、中小企業総数が減少している  
ため。  
  
イ  第三次産業に属する中小企業数は横ばいながら、中小企業総数が減少してい  
るため。  
  
ウ  中小企業総数が減少する中、第三次産業に属する中小企業数が増加している  
ため。  
  
エ  中小企業総数が横ばいの中、第三次産業に属する中小企業数が増加している  
ため。  
  
オ  中小企業総数の増加以上に、第三次産業に属する中小企業数が増加している  
ため。  
`
        },
        {
                "mondaiId": 107210030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文中の下線部の原因について、最も適切なものを下記の解答群から選べ。  
一般にサービスの価格も需要と供給の関係において決まるが、中小企業庁「サー  
ビスの生産性向上に関する実態調査（２００７年１１月）」によると、回答企業の約\u0002社  
に\u0003社が自社の提供するサービスの品質や価値が価格に対して「あまり反映されて  
いない」あるいは「まったく反映されていない」と回答している。サービスの品質や  
価値が価格に反映できていない原因については、「価格競争の激化」をあげる企業が  
最も多いが、対消費者サービスと、対事業所サービスでは異なる点も見られる。  
〔解答群〕  
  
ア  対消費者サービスにおいては「業界の慣行や慣例による制約」をあげる企業が  
多いのに対し、対事業所サービスでは「業界の慣行や慣例による制約」をあげる  
企業は少ない。  
  
イ  対消費者サービスにおいては「景気の低迷」をあげる企業が多いのに対し、対  
事業所サービスでは「取引先の一���的な価格要求」をあげる企業が多い。  
  
ウ  対消費者サービスにおいては「需要の減少」をあげる企業が多いのに対し、対  
事業所サービスでは「法律、規制等の制約」をあげる企業が多い。  
  
エ  対消費者サービスにおいては「品質と価格は関係ない」とする企業が多いのに  
対し、対事業所サービスでは「契約内容のあいまいさ」をあげる企業が多い。  
`
        },
        {
                "mondaiId": 107210041,
                "name": "第4問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業のIT（InformationTechnology）活用は戦略的課題の\u0002つであるが、ソ  
フトウェアを中心にIT資産の蓄積が進んでおらず、総じてみればITの活用も大  
企業ほど進んでいないのが現状である。  
実際、経済産業省「２００６年情報処理実態調査」によれば、近年普及が進む電子商  
取引についても、中小企業における実施割合は大企業に比べて低い。また、同じ調  
査から企業における情報システムの活用状況を見ると、部門内部レベル、全社レベ  
ル、調達先や販売先を含めたレベルの各階層で、大企業に比べてITの活用が実現  
できていないとする中小企業が多くなっている。  
一方で、中小企業の中にも、ITの活用によって、業務プロセスの合理化やコス  
ト削減にとどまらず、組織内の情報を進めることや、情報発信により新  
たな販売機会を獲得している事例が見受けられる。中小企業がIT化を進めるに当  
たっては、さまざまな課題が山積していることも事実であるが、近年政策的な支援  
制度も拡充しており、これらも活用しながら課題解決に取り組んでいくことが期待  
される。  
（設問1））  
文中の下線部について、経済産業省「２００６年情報処理実態調査」に基づき、中  
小企業の電子商取引の実施状況を見た場合、最も実施割合が高い販売取引はどれ  
か。  
  
ア  製造業のBtoB（対企業）販売取引  
  
イ  製造業のBtoC（対消費者）販売取引  
  
ウ  非製造業のBtoB（対企業）販売取引  
  
エ  非製造業のBtoC（対消費者）販売取引`
        },
        {
                "mondaiId": 107210042,
                "name": "第4問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄に入る最も適切なものはどれか。  
  
ア  共有イ専有ウ操作エ分割  
`
        },
        {
                "mondaiId": 107210051,
                "name": "第5問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
財務省「法人企業統計年報（２００６年度）」によると、中小企業（なお、ここでは資本  
金\u0002億円未満の企業を指す。）の自己資本比率（総資産に占める自己資本の割合）は、  
大企業の自己資本比率３７％に対し％である。これは、中小企業の収益  
性が大企業に対し相対的に低く内部留保が十分でないことに加えて、資本市場から  
の資金調達（エクイティ・ファイナンス）を行う企業が少ないためである。各証券取  
引所による公開基準を緩和した新興市場の整備にもかかわらず、株式公開を行って  
いる中小企業の割合は限定的である。また、近年、中小企業基盤整備機構をはじめ  
として、中小企業向けのプライベート・エクイティ・ファンドの設立も増加してい  
るが、依然として日本の非上場企業へのエクイティ・ファイナンスの投資残高は国  
際的に見て低水準である。  
（設問1））  
文中の空欄に入る最も適切な数値はどれか。  
  
ア  １１イ１６ウ２１エ２６オ３１`
        },
        {
                "mondaiId": 107210052,
                "name": "第5問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部について、わが国の代表的な新興市場の\u0002つである東証マザーズ  
の上場審査基準（形式要件）として、最も適切なものはどれか。  
  
ア  上場時の株主数が８００人以上であること。  
  
イ  上場時見込みの時価総額が１０億円以上であること。  
  
ウ  新規上場申請日から起算して、\u0004か月前から取締役会を設置して継続的に事  
業活動をしていること。  
  
エ  利益の額が最近の\u0003年間において\u0002億円以上であること。  
  
オ  連結純資産の額が１０億円以上であること。  
`
        },
        {
                "mondaiId": 107210061,
                "name": "第6問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
経済産業省「商業統計表」に基づき、１９８８年から２００４年の間の小売業の年間販売  
額と事業所数の推移を見ると、年間販売額は１９８８年の１１５兆円から２００４年には  
１３３兆円に拡大する一方で、事業所数は１９８８年の１６２万店から２００４年には  
万店まで減少している。これを従業者規模別に見ると、年間販売額・事  
業所数とも、中規模小売業（従業者\u0002～５０人以下）、大規模小売業（従業者５１人以  
上）で増加しているのに対し、小規模小売業（従業者\u0003人以下）で大きく減少してい  
ることが指摘される。  
（設問\u0004）  
文中の空欄に入る最も適切な数値はどれか。  
  
ア  １０５イ１１２ウ１２４エ１４２オ１５４`
        },
        {
                "mondaiId": 107210062,
                "name": "第6問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
文中の下線部について、経済産業省「商業統計表」を基に、１９８８年から２００４年  
の間の小規模小売業（従業者\u0003人以下）の年間販売額の推移を見た場合に最も適切  
なものはどれか。  
  
ア  １９９０年代半ばまでは大幅に減少してきたものの、１９９０年代後半以降は横ば  
いで推移している。  
  
イ  １９９０年代半ばまでは横ばいで推移したものの、１９９０年代後半以降大きく落  
ち込んでいる。  
  
ウ  １９９９年にピークに達した後、一貫して減少している。  
  
エ  ２００４年の年間販売額は１９８８年の年間販売額の\u0006割程度の水準にとどまって  
いる。  
  
オ  一貫して減少基調で推移している。  
`
        },
        {
                "mondaiId": 107210071,
                "name": "第7問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
機械部品製造を手がける中小企業者のX氏は、今後の経営の参考にするため自  
社の収益力の水準を知りたいと考え、中小企業診断士Y氏に相談した。  
以下は、X氏とY氏の会話である。  
X氏：「直近の決算書を見ると、当社の売上高経常利益率は３．１％でした。製造業  
で見た場合、当社の収益水準は低いのでしょうか。」  
Y氏：「景気変動もあり、単純な比較は難しいのですが、財務省の法人企業統計年  
報によれば、２００６年の資本金\u0002億円以下または従業員３００人以下で製造業  
を営む中小企業の売上高経常利益率の中央値はＡ％ですから、こ  
れと比較すると御社の収益力はBといえます。」  
X氏：「製造業を営む大企業の売上高経常利益率は、どの程度ですか。」  
Y氏：「同じく財務省の法人企業統計年報によれば、２００６年の製造業を営む大企業  
の売上高経常利益率の中央値はＣ％となっています。」  
X氏：「大企業と中小企業では収益力にも違いがあるのですね。」  
Y氏：「収益力の向上を図るためには、労働生産性の向上が求められます。財務省  
の法人企業統計年報により製造業の２００６年の労働生産性の中央値を見れ  
ば、大企業はＤ万円、中小企業はＥ万円と格差が生じてい  
ます。こうした労働生産性の格差は、おおむね、資本装備率の相違で説明で  
きるといわれています。」  
        
（設問1））  
会話の中の空欄Ａ～Ｃに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：１．７Ｂ：高いＣ：３．５  
  
イ  Ａ：２．２Ｂ：高いＣ：４．７  
  
ウ  Ａ：３．５Ｂ：低いＣ：５．２  
  
エ  Ａ：４．１Ｂ：低いＣ：６．８  
  
オ  Ａ：５．３Ｂ：低いＣ：７．１`
        },
        {
                "mondaiId": 107210072,
                "name": "第7問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
会話の中の空欄ＤとＥに入る最も適切なものの組み合わせはどれか。  
  
ア  Ｄ：６１８Ｅ：３２８  
  
イ  Ｄ：７６９Ｅ：４３６  
  
ウ  Ｄ：８１１Ｅ：４４８  
  
エ  Ｄ：１，０２６Ｅ：５６２  
  
オ  Ｄ：１，２３５Ｅ：７１６`
        },
        {
                "mondaiId": 107210073,
                "name": "第7問設問3",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
会話の中の下線部について、資本装備率の説明として最も適切なものはどれ  
か。  
  
ア  資本ストックあたりの付加価値額  
  
イ  資本ストックあたりの労働投入量  
  
ウ  労働投入量あたりの資本ストック  
  
エ  労働投入量あたりの付加価値額  
`
        },
        {
                "mondaiId": 107210081,
                "name": "第8問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
地域経済の活性化のためには、地域の中小企業の事業展開を支えるための資金調  
達環境の整備が不可欠であり、とりわけ、地域金融機関に期待される役割が大き  
い。  
\u0002  
中小企業の多くは、資金調達面で借入金への依存度が高く、加えて自社の本社  
所在地と同じ都道府県内に本店を置く地域金融機関をメインバンクとしているため  
である。  
地域金融機関には、地域の中小企業の技術や将来性に関する「目利き」能力を向上  
させるとともに、貸し手である金融機関と借り手である中小企業の間の情報の非対  
称性を克服し、  
\u0003  
不動産担保や保証に過度に依存しない融資の拡大を含め、地域密着  
型金融を推進していくことが望まれている。他方、中小企業の側も、地域金融機関  
に対して事業内容や財務状態に関して十分な情報を提供する等、コーポレートガバ  
ナンス構築への取り組みを進めることが求められる。経済環境が厳しさを増す中  
で、地域における中小企業金融の機能を維持・強化するためには、地域金融機関と  
中小企業の双方が、それぞれの課題に取り組む必要がある。  
        
（設問1））  
文中の下線部\u0002について、財務省「法人企業統計年報（２００６年度）」に基づき、  
企業の従業員規模別（従業員数２０人以下、同２１～１００人、同１０１～３００人、同  
３０１人以上）の資金調達構成を見た場合に最も適切なものはどれか。  
ここで、資金調達は負債、資本、割引手形によって行われるものとし、借入金  
依存度とは、資金調達に占める金融機関およびその他からの長期・短期借入金の  
割合を示す。  
  
ア  従業員規模が小さくなるにつれて、借入金依存度は高くなっている。  
  
イ  従業員数２０人以下の企業では、借入金依存度が約\u0003割である。  
  
ウ  従業員数１０１～３００人の企業では、借入金依存度が約\u0004割である。  
  
エ  従業員数３０１人以上の企業では、資金調達に占める社債の割合が\u0002割以上で  
ある。`
        },
        {
                "mondaiId": 107210082,
                "name": "第8問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の下線部\u0003に関して、不動産担保や保証に過度に依存した伝統的な融資方  
法に代わり、近年金融機関が取り組んでいる新たな資金供給方法として、最も不  
適切なものはどれか。  
  
ア  クレジットスコアリングモデル利用融資  
  
イ  私募債  
  
ウ  シンジケートローン  
  
エ  政府系金融機関の代理貸付制度  
  
オ  知的財産権担保融資  
`
        },
        {
                "mondaiId": 107210091,
                "name": "第9問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
わが国の製造業は、し烈化するグローバル競争に対応するため、積極的に海外で  
の生産活動を含めた事業展開を加速している。しかしながら、海外生産が拡大して  
いるものの、国内生産が一方的に縮小しているわけではない。経済産業省「工場立  
地動向調査」を見ても、日本国内における工場立地は、２００２年に調査開始以来の最  
低水準を記録した後は回復基調で推移しており、２００７年には件数・面積とも２００２  
年の\u0002倍以上の水準となっている。わが国の製造業は、海外生産拠点での生産を拡  
大する一方で、  
\u0002  
それぞれの優位性を勘案しながら、国内生産拠点の維持・拡大も  
行っているのが実情である。中小製造業���は、こうした状況を踏まえながら、  
\u0003  
自ら  
の海外展開の是非を含めた存立基盤の再構築を行うことが求められている。  
（設問２））  
文中の下線部\u0002について、海外生���拠点に対する国内生産拠点の一般的な優位  
性として、<u>最も不適切なものはどれか</u>。  
  
ア  開発と生産現場の一体化により効率的な開発を行うことができる。  
  
イ  若年労働力を活用することによりコスト競争力を発揮できる。  
  
ウ  製造技術のノウハウ流出を防ぐことができる。  
  
エ  素形材産業の集積や熟練工を活用することができる。  
`
        },
        {
                "mondaiId": 107210092,
                "name": "第9問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、総務省「事業所・企業統計調査」に基づき、２００１年  
時点と２００６年時点で海外展開する中小企業数を製造業・非製造業別に比較した  
場合、最も適切なものはどれか。  
  
ア  製造業より非製造業において、海外展開する中小企業数はより多く増加して  
いる。  
  
イ  非製造業、製造業とも海外展開する中小企業数は減少している。  
  
ウ  非製造業では海外展開する中小企業数が増加しているが、製造業では海外展  
開する中小企業数は減少している。  
  
エ  非製造業では海外展開する中小企業数が増加しているが、製造業では海外展  
開する中小企業数は横ばいである。  
  
オ  非製造業より製造業において、海外展開する中小企業数はより多く増加して  
いる。  
`
        },
        {
                "mondaiId": 107210100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
次の文中の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
中小企業が他の企業とともに事業連携活動を行う際の代表的な形態の\u0002つとして  
中小企業組合の存在があげられる。  
全国中小企業団体中央会「平成１８年度版中小企業組合の設立動向（２００７年\u0003月）」  
を基に、２００１年から２００５年までの業種別の中小企業組合の新設状況と解散状況を  
みると、Ａの組合においては解散組合が新設組合を上回っているのに対  
し、サービス業の組合とＢの組合については新設組合が解散組合を上回っ  
ており、組合形式による事業連携ニーズも一様でないことがうかがえる。  
〔解答群〕  
  
ア  Ａ：異業種Ｂ：製造業  
  
イ  Ａ：建設業Ｂ：異業種  
  
ウ  Ａ：建設業Ｂ：卸売業  
  
エ  Ａ：製造業Ｂ：異業種  
  
オ  Ａ：製造業Ｂ：卸売業  
`
        },
        {
                "mondaiId": 107210110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
多様な中小企業の経営を画一的にとらえることは困難であるが、大企業の経営と  
の比較において見ると、いくつかの共通の経営面での特質を指摘できる。中小企業  
経営の共通の特質として、<u>最も不適切なものはどれか</u>。  
  
ア  外部資金調達面で不利性を有している。  
  
イ  外部の経営資源への依存度が高い。  
  
ウ  経営者の個人的資質への依存度が高い。  
  
エ  人的資源の不足を補うため、階層的な管理組織を形成する必要性が高い。  
  
オ  中小規模性ゆえに参入・存立が限定される事業分野がある。  
`
        },
        {
                "mondaiId": 107210120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
次の文中の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
中小企業と大企業では、賃金水準や労働時間の点で、格差が存在するのが現状で  
ある。たとえば、厚生労働省「賃金構造基本統計調査（２００７年）」の再編加工結果か  
ら、企業規模別の正社員の平均給与額（きまって支給する現金給与額）を見ると、中  
小企業の２９．８万円に対し、大企業では３８．３万円であり、特に製造業・非製造業別  
ではＡにおける大企業と中小企業の賃金格差が大きい。ちなみに、大企業  
と中小企業の賃金格差（全業種）の推移を見ると、１９９０年代と比べてＢし  
ている。また、正社員の\u0002カ月当たりの平均労働時間数を見ても、中小企業の平均  
労働時間数１８４．３時間に対し、大企業では１７５．３時間となっており、中小企業が大  
企業を上回っている。  
〔解答群〕  
  
ア  Ａ：製造業Ｂ：拡大  
  
イ  Ａ：製造業Ｂ：縮小  
  
ウ  Ａ：非製造業Ｂ：拡大  
  
エ  Ａ：非製造業Ｂ：縮小  
`
        },
        {
                "mondaiId": 107210130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
中小企業基本法における中小企業の定義に関する記述について、その正誤の組み  
合わせとして最も適切なものを下記の解答群から選べ。  
ａ従業員数６０人の飲食業（資本金\u0002千万円）は、中小企業と定義される。  
ｂ従業員数１５０人の卸売業（資本金\u0003億\u0004千万円）は、中小企業と定義される。  
ｃ従業員数２００人の運輸業（資本金\u0005億円）は、中小企業と定義される。  
〔解答群〕  
  
ア  ａ：正ｂ：正ｃ：誤  
  
イ  ａ：正ｂ：誤ｃ：正  
  
ウ  ａ：誤ｂ：正ｃ：誤  
  
エ  ａ：誤ｂ：誤ｃ：正  
`
        },
        {
                "mondaiId": 107210140,
                "name": "第14問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
金型メーカーのA社は、得意先の部品軽量化のニーズに対応するため、新たな  
金型技術の研究開発を検討している。A社社長から、当該研究開発に関する相談を  
受けた中小企業診断士B氏は、「中小ものづくり高度化法」とそれに基づく支援措  
置を紹介することとした。  
B氏のA社社長に対する説明として、最も適切なものはどれか。  
  
ア  研究開発にかかわる諸経費を国が負担する「戦略的基盤技術高度化支援事業」の  
採択を受けるためには、特定研究開発等計画の認定が条件になります。  
  
イ  この法律では、国が「特定ものづくり基盤技術」として、御社の専門分野である  
金型の他、燃料電池、情報家電などを指定しています。  
  
ウ  この法律に基づく支援を受けるためには、高度化指針に沿った適切な計画を作  
成し、都道府県知事の認定を受ける必要があります。  
  
エ  特定研究開発等計画の認定を受けると、日本政策金融公庫の低利融資を受ける  
ことができます。この場合、日本政策金融公庫の審査は免除されます。  
`
        },
        {
                "mondaiId": 107210150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
次の文章の空欄に入る最も適切なものを下記の解答群から選べ。  
下請取引に関連する中小企業施策のひとつに、「下請かけこみ寺」事業がある。  
「下請かけこみ寺」においては、下請相談のほか、などの取組を行ってい  
る。  
「下請かけこみ寺」は全国中小企業取引振興協会を本部として、４７都道府県下請  
企業振興協会に設置されている。  
〔解答群〕  
  
ア  官公需等についての受注機会の確保  
  
イ  裁判外紛争解決手続（ADR）を用いた迅速な紛争解決  
  
ウ  登録された下請支援アドバイザーの派遣  
  
エ  ビジネス・マッチング・ステーションを活用した下請取引のあっせん  
`
        },
        {
                "mondaiId": 107210161,
                "name": "第16問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
次の文章を読んで下記の設問に答えよ。  
中小企業新事業活動促進法に基づく経営革新の支援策を受けるには、経営革新計  
画の承認を受ける必要がある。経営革新計画が承認されるためには、計画期間であ  
る\u0002年～\u0003年のそれぞれの期間終了時における付加価値額や経常利益の伸び率がポ  
  
イ  ントとなる。  
（設問\u0004）  
\u0003年計画の経営革新計画を作成する場合、計画期間終了時における「付加価値  
額」の目標伸び率として、最も適切なものはどれか。  
  
ア  \u0002％以上イ\u0003％以上ウ１０％以上エ１５％以上`
        },
        {
                "mondaiId": 107210162,
                "name": "第16問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
経営革新計画における「付加価値額」の算出方法として、最も適切なものはどれ  
か。  
  
ア  付加価値額＝営業利益＋減価償却費  
  
イ  付加価値額＝営業利益＋人件費＋減価償却費  
  
ウ  付加価値額＝経常利益＋減価償却費  
  
エ  付加価値額＝経常利益＋人件費＋減価償却費  
`
        },
        {
                "mondaiId": 107210170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
地域経済の活性化のためには、地域に存在する経営資源を連携して、これまでに  
ない取り組みを数多く創出することが必要になっている。そこで、「地域力連携拠  
点」が、地域に存在する支援機関や有能な人材等をつなぎ合わせて、ワンストップ  
で中小・小規模企業の経営サポートを行う機関として整備されている。「地域力連  
携拠点」は、平成２０年\u0002月に全国で一斉に事業を開始している。  
「地域力連携拠点」に関する記述として、最も適切なものはどれか。  
  
ア  株式会社は、地域力連携拠点になることができない。  
  
イ  すべての地域力連携拠点には、「事業承継支援センター」が併設されている。  
  
ウ  地域内の連携事業に対して融資を行う。  
  
エ  地域力連携拠点は、公募により選定されている。  
`
        },
        {
                "mondaiId": 107210180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
以下は、中小企業診断士のX氏と小規模製造業のY社社長との会話である。会  
話の中の空欄ＡとＢに入る最も適切なものの組み合わせを下記の解答群から選べ。  
X氏：「最近の経済情勢を考えると、取引先の倒産といった不測の事態に備え  
ておくことが必要だと思います。」  
Y社社長：「そうですね。当社のような小規模企業は、とく���取引先の影響を受け  
やすいですからね。何かよい対策はありますか。」  
X氏：「経営セーフティ共済に加入しておくのはいかがでしょうか。」  
Y社社長：「それは、どのような制度ですか。」  
X氏：「経営セーフティ共済は、いわば、“取引先に不測の事態が生じたときの  
資金手当”をする制度で、Ａに基づいています。」  
Y社社長：「具体的には、どのような支援を受けることができるのでしょうか。」  
X氏：「取引先企業が倒産した場合、共済金の貸付けを無担保、無保証人、  
Ｂで受けることができます。」  
〔解答群〕  
  
ア  Ａ：小規模企業共済法Ｂ：低利  
  
イ  Ａ：小規模企業共済法Ｂ：無利子  
  
ウ  Ａ：中小企業倒産防止共済法Ｂ：低利  
  
エ  Ａ：中小企業倒産防止共済法Ｂ：無利子  
`
        },
        {
                "mondaiId": 107210190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
次の文章の空欄ＡとＢに入る最も適��なものの組み合わせを下記の解答群から選  
べ。  
新製品等の販路の確保・拡大を希望している中小企業に対する支援施策のひとつ  
に、「販路ナビゲーター創出支援事業」がある。  
この事業では、都道府県等の支援機関が主催している中小企業を支援するマッチ  
ングイベントにおいて、販路ナビゲーターの派遣を希望する中小企業がある場合に  
は、Ａに登録している販路ナビゲーターを派遣する。なお、この事業にお  
ける販路ナビゲーターとは、Ｂ等のことである。  
〔解答群〕  
  
ア  Ａ：商工会・商工会議所  
Ｂ：百貨店等のバイヤーなどの専門家  
  
イ  Ａ：商工会・商工会議所  
Ｂ：豊富な経験を有する企業OB  
  
ウ  Ａ：中小企業基盤整備機構  
Ｂ：百貨店等のバイヤーなどの専門家  
  
エ  Ａ：中小企業基盤整備機構  
Ｂ：豊富な経験を有する企業OB  
`
        },
        {
                "mondaiId": 107210201,
                "name": "第20問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
次の文章を読んで、下記の設問に答えよ。  
個人で建築設計業を営むX氏は、同業者と合同して経営規模を拡大したいと考え  
ている。X氏から相談を受けた中小企業診断士のY氏は、X氏に「企業組合」の設立  
を勧めることにした。  
以下は、X氏と中小企業診断士Y氏の会話である。  
X氏：「企業組合とは、どのような制度なのでしょうか。」  
Y氏：「企業組合は、一見、会社に似ています。Ａ」  
X氏：「設立の手続きは、どのようにすればよいのですか。」  
Y氏：「Ｂ人以上の発起人がいれば設立可能です。また、企業組合の設立  
にあたっては、Ｃの認可を受ける必要があります。」  
（設問1））  
文中の空欄Ａに入る記述として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0002口の出資金額は自由に設定することができます。  
  
イ  NPO法人と異なり、利益は、出資者であり事業従事者である組合員に配分  
することができます。  
  
ウ  株式会社の株主と同じく、企業組合の組合員には出資額に比例して議決権が  
与えられます。  
  
エ  無限責任制度が適用される合名会社や合資会社とは異なり、企業組合の出資  
者である組合員には、株式会社と同じく有限責任制度が適用されます。  
`
        },
        {
                "mondaiId": 107210202,
                "name": "第20問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄ＢとＣに入る最も適切なものの組み合わせはどれか。  
  
ア  Ｂ：\u0003Ｃ：国  
  
イ  Ｂ：\u0003Ｃ：都道府県知事  
  
ウ  Ｂ：\u0004Ｃ：国  
  
エ  Ｂ：\u0004Ｃ：都道府県知事`
        },
        {
                "mondaiId": 107210210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
起業に関する支援施策のひとつに、「起業支援ネットワーク“NICe（ナイス）”」が  
ある。この施策に関する記述として、最も適切なものはどれか。  
  
ア  各地域のコーディネート機関（経済団体等）が中心となって、起業家を支援する  
プロジェクトを総合的に支援する。  
  
イ  起業家、起業支援者等がインターネット上で情報交換ができるSNS（ソーシャ  
ルネットワーキングサービス）事業を行う。  
  
ウ  商工会・商工会議所と実務家が連携して、起業を広範にサポートするネット  
ワークを形成する。  
  
エ  都道府県等中小企業支援センターに起業支援の専門家を配置するとともに、専  
門家のネットワークを形成する。  
`
        },
        {
                "mondaiId": 107210220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
中小企業のX社は、これまで財務と会計だけにとどまっていたシステムを、生産  
管理や在庫管理と統合して経営の革新を図りたいと考えているが、社内にIT専門  
家がいないため、どのようにシステムを構築したらよいかが分からずに困ってい  
る。以下は、相談を受けた中小企業診断士Y氏とX社社長との会話である。  
会話の中の空欄ＡとＢに入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
Y氏：「システムの構築をベンダーの言いなりでやっている企業が多く見受け  
られますが、御社にふさわしいシステムを構築するには、御社の見解を  
はっきり主張することが大切です。また、社内のIT人材の育成を進め  
ることも必要でしょう。Ａという中小企業施策がありますの  
で、利用してみたらいかがでしょうか。」  
X社社長：「それは、どのような事業なのでしょうか。」  
Y氏：「この事業を利用すれば、中小企業の立場を詳しく知るIT専門家が常  
駐してくれます。ベンダーとの交渉にあたっては、御社の見解をはっき  
り主張してくれると思いますよ。派遣期間は\u0002カ月間で、更新も可能で  
す。」  
X社社長：「利用方法を教えてくれますか。」  
Y氏：「Ｂなどに派遣依頼をしてください。Ｂが、登録して  
いるアドバイザーから適任者を選定したうえで派遣してくれます。」  
〔解答群〕  
  
ア  Ａ：IT経営応援隊Ｂ：中小企業基盤整備機構  
  
イ  Ａ：IT経営応援隊Ｂ：地域中小企業支援センター  
  
ウ  Ａ：戦略的CIO育成支援事業Ｂ：中小企業基盤整備機構  
  
エ  Ａ：戦略的CIO育成支援事業Ｂ：地域中小企業支援センター  
`
        },
        {
                "mondaiId": 107210231,
                "name": "第23問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
次の文章を読んで、下記の設問に答えよ。  
中小企業診断士のX氏は、小売業（従業員\u0002名）を営むY氏から、経営改善を図  
るための融資制度を知りたいとの相談を受けた。そこで、X氏はY氏に「小規模事  
業者経営改善資金融資制度（マル経融資）」を紹介することにした。以下は、X氏と  
Y氏との会話の一部である。  
X氏：「マル経融資という制度がありますので、検討してみたらいかがでしょう。  
低利で融資を受けることができますよ。」  
Y氏：「その融資制度では、担保などは必要になるのでしょうか。」  
X氏：「マル経融資は無担保・無保証人で、Ａです。また、業種要件は、  
最近Ｂ以上事業を行っている商工業者です。御社も対象になります  
よ。」  
Y氏：「いくらまで融資を受けることができるのですか。」  
X氏：「平成２１年\u0003月に取りまとめられた\u0004経済危機対策”において、マル経融資の  
拡充が盛り込まれました。これを受けて、貸付限度額はＣ万円に  
なっています。」  
（設問\u0005）  
文中の空欄ＡとＢに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：経営者本人の保証のみ必要Ｂ：\u0006カ月  
  
イ  Ａ：経営者本人の保証のみ必要Ｂ：\u0005年  
  
ウ  Ａ：経営者本人の保証も不要Ｂ：\u0006カ月  
  
エ  Ａ：経営者本人の保証も不要Ｂ：\u0005年`
        },
        {
                "mondaiId": 107210232,
                "name": "第23問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0007）  
文中の空欄Ｃに入る最も適切なものはどれか。  
  
ア  １，０００イ１，２００ウ１，５００エ１，８００オ２，０００  
`
        },
        {
                "mondaiId": 107210240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
平成２０年\u0002月に施行された「農商工等連携促進法」に関する記述として、最も不  
適切なものはどれか。  
  
ア  「農商工等連携事業計画」の認定は国が行う。  
  
イ  「農商工等連携事業計画」の認定を受けると、政府系金融機関の無担保融資が利  
用できる。  
  
ウ  農商工等連携に対して指導・助言をするNPO法人は、この法律に基づく支援  
の対象になる。  
  
エ  連携して新事業展開に取り組む漁業者と中小企業者は、この法律の支援対象に  
なる。  
`
        },
        {
                "mondaiId": 107210250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
次の文章の空欄ＡとＢに入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
中小企業等のIT活用を支援する事業のひとつとして、「中小企業経営革新プ  
ラットフォームシステム開発事業」がある。この事業の支援対象は、Ａ等  
である。また、支援内容は、Ｂ等である。  
〔解答群〕  
  
ア  Ａ：経営革新を目指し、ITの利活用を図る中小企業・小規模企業の経営者  
Ｂ：SaaS（インターネット経由で情報処理を行うサービス）を利用した業務  
効率化を行うための基盤システムの開発  
  
イ  Ａ：経営革新を目指し、ITの利活用を図る中小企業・小規模企業の経営者  
Ｂ：仕様が異なる複数のEDI（電子データ交換）と連携が図れるインター  
フェイス・ソフトの開発と実証  
  
ウ  Ａ：中小企業を中心とした複数企業が構成するコンソーシアム、組合  
Ｂ：SaaS（インターネット経由で情報処理を行うサービス）を利用した業務  
効率化を行うための基盤システムの開発  
  
エ  Ａ：中小企業を中心とした複数企業が構成するコンソーシアム、組合  
Ｂ：仕様が異なる複数のEDI（電子データ交換）と連携が図れるインター  
フェイス・ソフトの開発と実証  
`
        },
        {
                "mondaiId": 107210260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
「流動資産担保保証制度」は、売掛金債権、割賦販売代金債権などを担保として金  
融機関が融資を行う際、信用保証協会が債務保証を行う制度であり、不動産担保に  
依存しない中小企業の資金調達の途を開くものである。  
この制度に関する記述として、最も適切なものはどれか。  
  
ア  棚卸資産は担保にならない。また、法人代表者以外の保証人は徴求しない。  
  
イ  棚卸資産は担保にならない。また、法人代表者の保証は不要である。  
  
ウ  棚卸資産も担保になる。また、法人代表者以外の保証人は徴求しない。  
  
エ  棚卸資産も担保になる。また、法人代表者の保証は不要である。  
`
        },
        {
                "mondaiId": 107210271,
                "name": "第27問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
次の文章を読んで、下記の設問に答えよ。  
中小企業の組合制度のひとつに「商店街振興組合」がある。この組合は、組合員で  
ある資格を有する者の\u0002分の\u0003以上が組合員となり、かつ、総組合員の\u0003分の\u0004以  
上がに属する事業を営む者でなければ設立することができない。  
（設問\u0004）  
文中の下線部に関する記述として、最も適切なものはどれか。  
  
ア  株式会社への組織変更が可能である。  
  
イ  組合地区の重複は禁止されている。  
  
ウ  設立するにあたっては、\u0005人以上の発起人が必要である。  
  
エ  中小企業等協同組合法を根拠法規とする。`
        },
        {
                "mondaiId": 107210272,
                "name": "第27問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄に入る最も適切なものはどれか。  
  
ア  小売商業  
  
イ  小売商業または卸売商業  
  
ウ  小売商業またはコミュニティビジネス  
  
エ  小売商業またはサービス業  
`
        },
        {
                "mondaiId": 107210280,
                "name": "第28問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
中小企業再生支援協議会に関する記述の正誤の組み合わせとして、最も適切なも  
のを下記の解答群から選べ。  
ａ支援を受けるためには、都道府県知事の再生計画認定を受ける必要がある。  
ｂ中小企業支援法に基づき設置されている公正中立的機関である。  
ｃ都道府県ごとに設置されている公的機関である。  
〔解答群〕  
  
ア  ａ：正ｂ：正ｃ：誤  
  
イ  ａ：正ｂ：誤ｃ：誤  
  
ウ  ａ：誤ｂ：誤ｃ：正  
  
エ  ａ：誤ｂ：正ｃ：誤`
        },
        {
                "mondaiId": 107210290,
                "name": "第29問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
女性、若者、高齢者向けの金融支援制度として、日本政策金融公庫の「女性、若  
者／シニア起業家支援資金」がある。  
中小企業診断士のA氏は、\u0002人の個人経営者から、この融資制度の利用に関す  
る相談を受けた。A氏が、この融資制度の利用を薦める経営者として、最も不適切  
なものはどれか。  
  
ア  新規開業して\u0003年の２８歳の男性  
  
イ  新規開業して\u0004年の５５歳の男性  
  
ウ  新規開業して\u0005年の４０歳の女性  
  
エ  新規開業して１０年の６０歳の女性  
`
        },
        {
                "mondaiId": 107210300,
                "name": "第30問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2009,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
次の文章の空欄に入るものとして最も適切なものを下記の解答群から選べ。  
中小企業が抱える知的財産に関する課題解決を支援する中小企業施���のひとつ  
に、「知財駆け込み寺」事業がある。この事業において、「知財駆け込み寺」は  
に設置されている。  
〔解答群〕  
  
ア  商工会・商工会議所  
  
イ  地域中小企業支援センター  
  
ウ  中小企業・ベンチャー総合支援センター  
  
エ  都道府県等中小企業支援センター  
`
        },
        {
                "mondaiId": 101200010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のGDPに関する文章中の空欄Ａ～Ｄに入る最も適切なものの組み合わせを下  
記の解答群から選べ。  
生産面から見たGDP、分配面から見たGDP、支出面から見たGDPが  
Ａに一致することを「三面等価の原則」という。このうち、生産面から見た  
GDPは各生産段階におけるＢの総計に等しく、支出面から見たGDPは  
Ｃと呼ばれる。  
なお、GDPから固定資本減耗を差し引いたものをＤと呼ぶ。  
〔解答群〕  
  
ア  Ａ：事後的Ｂ：中間生産物の価値Ｃ：国内総支出Ｄ：国内純生産  
  
イ  Ａ：事後的Ｂ：付加価値Ｃ：国内総支出Ｄ：国内純生産  
  
ウ  Ａ：事後的Ｂ：付加価値Ｃ：国民総支出Ｄ：国民純生産  
  
エ  Ａ：事前的Ｂ：中間生産物の価値Ｃ：国内総支出Ｄ：国内純生産  
  
オ  Ａ：事前的Ｂ：付加価値Ｃ：国民総支出Ｄ：国民純生産  
`
        },
        {
                "mondaiId": 101200020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章中の空欄ＡおよびＢに入る最も適切なものの組み合わせを下記の解答群  
から選べ。  
下図は日本のＡの推移を表したものである。この図から、Ａが  
急激に低下していることが読みとれる。Ａが低下する主な要因として  
Ｂなどが挙げられる。これにより、日本の長期的な経済成長が鈍化する可  
能性が指摘されている。  
出所：内閣府『国民経済計算』により算出  
〔解答群〕  
  
ア  Ａ：家計貯蓄率Ｂ：高齢化の進展  
  
イ  Ａ：製造業の国内生産額の対GDP比率Ｂ：サービス経済化の進展  
  
ウ  Ａ：政府支出の対GDP比率Ｂ：財政構造改革の進展  
  
エ  Ａ：輸出依存度Ｂ：海外における景気の低迷  
`
        },
        {
                "mondaiId": 101200030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本の完全失業率、消費者物価変化率、現金給与総額伸び率を表したも  
のである。この図の説明として最も適切なものはどれか。  
出所：総務省「消費者物価指数」「労働力調査」、厚生労働省「毎月勤労統計調査」  
  
ア  １９８０年代後半のいわゆるバブル経済期には、失業率の低下に応じて実質賃金  
は下落傾向にある。  
  
イ  ２０００年以降、失業率の上昇期には実質賃金は上昇傾向にある。  
  
ウ  「オークンの法則」と同様、物価と名目賃金はほぼ同じ傾向を示し、実質賃金は  
硬直的に推移している。  
  
エ  「フィリップス曲線」が示すように、物価変化率と失業率は相反する傾向が見ら  
れる。  
`
        },
        {
                "mondaiId": 101200040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の古典派マクロ経済学に関する文章中の空欄ＡおよびＢに入る最も適切なもの  
の組み合わせを下記の解答群から選べ。  
古典派マクロ経済理論では、市場の価格調整メカニズムが万全であり、物価およ  
び名目賃金が上下に伸縮的であると考える。このため、労働市場では常に完全雇用  
が実現し、GDPは完全雇用GDPの水準と一致する。古典派マクロ経済理論では  
Ａが成立し、ＢサイドからGDPが決定されると主張する。  
〔解答群〕  
  
ア  Ａ：セイの法則Ｂ：供給  
  
イ  Ａ：セイの法則Ｂ：需要  
  
ウ  Ａ：有効需要の原理Ｂ：供給  
  
エ  Ａ：有効需要の原理Ｂ：需要`
        },
        {
                "mondaiId": 101200050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財政の役割に関する説明として最も適切なものはどれか。  
  
ア  インフレ・ギャップが生じている場合、物価を安定させるために政府支出の縮  
小が必要とされる。  
  
イ  限界貯蓄性向が大きいほど、租税乗数は大きくなる。  
  
ウ  減税は可処分所得の減少を通じて消費を拡大させ、GDPを増加させる。  
  
エ  政府支出の拡大と減税を同規模で行った場合、GDPは一定に維持される。  
  
オ  定率的な所得税は景気後退を自動的に防止する役割を果たすが、これを「裁量  
的財政政策」と呼ぶ。  
`
        },
        {
                "mondaiId": 101200060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、投資の利子弾力性がゼロである場合を想定したIS―LM曲線を描いた  
ものである。この図の説明として最も適切なものはどれか。  
  
ア  GDPの水準は生産物市場の動向とは無関係であり、貨幣市場の動向から決定  
される。  
  
イ  貨幣供給が増加しても利子率は不変であり、投資は一定の水準に維持される。  
  
ウ  貨幣供給の増加は利子率の低下を通じて投資の拡大を引き起こす。  
  
エ  政府支出の増加により利子率の上昇が生じるが、クラウディング・アウトは発  
生しない。  
  
オ  政府支出の増加はGDPの拡大を引き起こすが、クラウディング・アウトが生  
じる分だけGDPの拡大は抑制される。  
`
        },
        {
                "mondaiId": 101200071,
                "name": "第7問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
GDPと外国貿易に関する次の文章を読んで、下記の設問に答えよ。  
いま、自国と外国の\u0002国モデルを仮定し、自国と外国の生産物市場の均衡条件が  
それぞれ次のように与えられるとする。  
Y＝C＋I＋G＋X－M  
Y＊＝C＊＋I＊＋G＊＋X＊－M＊  
ここで、Y：GDPまたは所得、C：消費、I：投資、G：政府支出、X：輸出、  
M：輸入であり、記号の右肩に＊を付したものは外国の変数である。なお、自国の  
輸出Xは外国の輸入M＊に等しく、自国の輸入Mは外国の輸出X＊に等しい。  
自国の消費関数と輸入関数はそれぞれ  
C＝cY  
M＝mY  
で示され、c：限界消費性向、m：限界輸入性向である。また、投資支出と政府支  
出はおのおのI＝I０、G＝G０である。  
同様に、外国の消費関数と輸入関数はそれぞれ  
C＊＝c＊Y＊  
M＊＝m＊Y＊  
である。外国においても、投資支出と政府支出はおのおのI＊＝I＊  
０、G＊＝G＊  
０で  
ある。  
このとき、自国と外国の生産物市場の均衡条件は次のように表される。  
Y＝cY＋I０＋G０＋m＊Y＊－mY  
Y＊＝c＊Y＊＋I＊  
０＋G＊  
０＋mY－m＊Y＊  
この結果、自国のGDPの決定式は  
Y＝  
（\u0003－c＊＋m＊）（I０＋G０）＋m＊（I＊  
０＋G＊  
０）  
（\u0003－c＊）（\u0003－c＋m）＋m＊（\u0003－c）  
になる。  
        
同様に、外国のGDPの決定式は  
Y＊＝  
（\u0002－c＋m）（I＊  
０＋G＊  
０）＋m（I０＋G０）  
（\u0002－c）（\u0002－c＊＋m＊）＋m（\u0002－c＊）  
で表される。  
上記の\u0003国のGDP決定式から、自国や外国の財政政策の変更が両国のGDPに  
いかなる影響を与えるかが明らかにされる。  
（設問1））  
文中の下線部について、自国の政府支出Gが増加した場合の経済効果の説明  
として最も適切なものはどれか。  
  
ア  外国では経常収支の悪化を通じてGDPが拡大する。  
  
イ  外国のGDPの拡大を通じて自国の輸出が誘発され、自国のGDP拡大効果  
は大きくなる。  
  
ウ  自国では経常収支の改善を通じてGDPが拡大する。  
  
エ  自国のGDPを増加させるが、外国のGDPを減少させ、近隣窮乏化を引き  
起こす。`
        },
        {
                "mondaiId": 101200072,
                "name": "第7問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部について、外国の政府支出G＊が増加した場合の経済効果の説明  
として最も適切なものはどれか。  
  
ア  外国では経常収支の改善を通じてGDPが拡大する。  
  
イ  外国のGDPを増加させるが、自国のGDPを減少させてしまう。  
  
ウ  自国では経常収支の悪化を通じてGDPが減少する。  
  
エ  自国では輸出が誘発され、経常収支の改善を通じてGDPが拡大する。  
`
        },
        {
                "mondaiId": 101200080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の自由貿易地域に関する文章を読んで、自由貿易地域が形成された場合の経済  
効果の説明として最も適切なものを下記の解答群から選べ。  
下図は、自国と\u0002つの外国（X国とY国）間の貿易取引を表し、自国の輸入競争  
財市場（たとえば農産物）を対象としている。農産物の国内需要曲線がDD、国内供  
給曲線がSSで描かれている。  
いま、X国からの農産物の輸入価格がP０、Y国からの農産物の輸入価格がP１で  
あるとする。このとき、自由貿易を想定すれば、農産物はより安価なX国から輸  
入され、Y国から輸入されることはない。また、両国からの輸入に関税（T円）を同  
じだけ賦課したとしても、（P１＋T）が（P０＋T）よりも大きいため、農産物は依然  
としてX国から輸入され続ける。ここで、（P０＋T）をP２で示し、（P１＋T）線は  
図示していない。  
ところが、X国からの輸入には関税を賦課したままで自国とY国が自由貿易地  
域を形成した場合、Y国に対する輸入関税は撤廃され、両国からの輸入価格は  
P２＞P１になるから、農産物の輸入先はX国からY国に切り替わる。  
        
〔解答群〕  
  
ア  \u0002EIJと\u0002HKLの和が\u0002FGLIより大きければ、自由貿易地域を形成するこ  
とによって自国の総余剰が増加する。  
  
イ  自由貿易地域が形成されると、\u0002BEFと\u0002CGHの余剰が回復する。  
  
ウ  自由貿易地域形成下の貿易利益は、自由貿易下の利益\u0002ABCより大きい。  
  
エ  貿易創造効果は\u0002EFGHに等しい。  
  
オ  貿易転換効果は\u0002EIJと\u0002HKLの和に等しい。  
`
        },
        {
                "mondaiId": 101200090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、開放経済下におけるマクロ経済モデルを描いたものである。この図に関  
する次の文章中の空欄Ａ～Ｃに入る最も適切なものの組み合わせを下記の解答群か  
ら選べ。  
いま、小国モデル、完全資本移動、固定為替レート制、物価の硬直性、静学的な  
為替レート予想を仮定する。下図は、これらの前提に基づき、生産物市場の均衡を  
表すIS曲線、貨幣市場の均衡を表すLM曲線、自国利子率（r）と外国利子率（r＊）  
が均等化することを表すBP曲線を描いたものである。  
ここで政府支出が増加すると、IS曲線が右方にシフトし、新たなIS曲線とLM  
曲線の交点においてＡになる。このため、Ｂが生じる。結果とし  
て、Ｃになる。  
        
〔解答群〕  
  
ア  Ａ：国際収支（経常収支と資本収支の合計）が赤字  
Ｂ：外貨準備の減少と貨幣供給の減少  
Ｃ：LM曲線が左方にシフトして国際収支の均衡が回復するが、財政政策は  
景気拡大に無効  
  
イ  Ａ：国際収支（経常収支と資本収支の合計）が黒字  
Ｂ：外貨準備の増加と貨幣供給の増加  
Ｃ：LM曲線が右方にシフトして国際収支の均衡が回復し、財政政策は景気  
拡大に有効  
  
ウ  Ａ：国際収支（経常収支と資本収支の合���）が黒字  
Ｂ：円高  
Ｃ：LM曲線が左方にシフトして国際収支の均衡が回復するが、財政政策は  
景気拡大に無効  
  
エ  Ａ：経常収支が黒字  
Ｂ：外貨準備の増加と貨幣供給の増加  
Ｃ：LM曲線が右方にシフトして経常収支の均衡が回復し、財政政策は景気  
拡大に有効  
  
オ  Ａ：資本収支が赤字  
Ｂ：円安  
Ｃ：LM曲線が右方にシフトして資本収支の均衡が回復し、財政政策は景気  
拡大に有効  
`
        },
        {
                "mondaiId": 101200101,
                "name": "第10問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
下図は、ケインズ派モデルにおける総需要曲線（AD）と総供給曲線（AS）を描い  
たものである。ここで、供給サイドにおいては、物価は上下に伸縮的であるが、名  
目賃金は硬直的であると考える。下記の設問に答えよ。  
（設問1））  
総需要曲線の説明として最も適切なものの組み合わせを下記の解答群から選  
べ。  
ａ経済が「流動性のわな」の状態にあるとき、総需要曲線は垂直に描かれる。  
ｂ増税は総需要曲線を右方にシフトさせる。  
ｃ投資の利子弾力性が大きいほど、総需要曲線はより急勾配に描かれる。  
ｄ物価の下落は、実質貨幣供給の増加を通じて利子率を低下させ、投資の拡大  
と総需要の増加をもたらす。  
〔解答群〕  
  
ア  ａとｂイａとｄウｂとｃ  
  
エ  ｂとｄオｃとｄ  
`
        },
        {
                "mondaiId": 101200102,
                "name": "第10問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
総供給曲線の説明として最も適切なものの組み合わせを下記の解答群から選  
べ。  
ａエネルギーなどの原材料費の上昇は、総供給曲線を左方にシフトさせる。  
ｂ技術進歩は生産性の上昇を通じて総供給曲線を右方にシフトさせる。  
ｃ人口構成の少子化・高齢化に伴う労働市場の変化は、総供給曲線を右方にシ  
フトさせる。  
ｄ物価の上昇は、実質賃金の上昇を通じて労働需要を増加させ、生産量の拡大  
を生じさせる。  
〔解答群〕  
  
ア  ａとｂイａとｃウａとｄ  
  
エ  ｂとｃオｂとｄ  
`
        },
        {
                "mondaiId": 101200111,
                "name": "第11問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
医師不足・偏在、診療科の廃止など医療を取り巻く問題が多発している。病気に  
なった人が、医療機関に行って受ける通常の医療サービスに関して、下記の設問に  
答えよ。  
（設問1））  
この医療サービスの一般的な特徴として最も適切なものはどれか。  
  
ア  非競合性と非排除性を有さず、価値財でもない。  
  
イ  非競合性と非排除性を有さないが、価値財である。  
  
ウ  非競合性は有しており、価値財でもあるが、非排除性を有していない。  
  
エ  非排除性は有しており、価値財でもあるが、非競合性を有していない。`
        },
        {
                "mondaiId": 101200112,
                "name": "第11問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
上記に関連して、公共財と私的財に関する説明として最も適切なものはどれ  
か。  
  
ア  非競合性と非排除性の性質を持つ財を公共財という。その財の供給は市場に  
任せると過少になる傾向がある。  
  
イ  非競合性と非排除性の性質を持つ財を公共財という。その財の供給は市場に  
任せると過剰になる傾向がある。  
  
ウ  非競合性と非排除性の性質を持つ財を私的財という。その財の供給は市場に  
任せると過少になる傾向がある。  
  
エ  非競合性と非排除性の性質を持つ財を私的財という。その財の供給は市場に  
任せると過剰になる傾向がある。  
`
        },
        {
                "mondaiId": 101200121,
                "name": "第12問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
原油価格の高騰が起きれば、ガソリンスタンドはガソリン価格の引き上げを余儀  
なくされる。一方で、ガソリン価格の高騰は買い控えなどによる顧客離れを引き起  
こしかねない。そこで、ガソリンスタンドでは価格をどのような水準に設定するか  
が重要となる。この点を踏まえて、下記の設問に答えよ。  
（設問1））  
ドル建ての原油価格が上昇しても、ガソリンの円建ての小売価格への影響を小  
さくする要因の組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａ円高  
ｂ円安  
ｃ低い需要の価格弾力性  
ｄ高い需要の価格弾力性  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ`
        },
        {
                "mondaiId": 101200122,
                "name": "第12問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
あるガソリンスタンドが、周りの競合店よりも極端に低い価格を付け、競合店  
を市場から排除した後に独占的な地位を得ようとする可能性が指摘できる。この  
ような価格戦略を表す言葉として最も適切なものはどれか。  
  
ア  限界価格設定  
  
イ  フルコスト・プライシング  
  
ウ  ラムゼイ価格  
  
エ  略奪的価格設定  
`
        },
        {
                "mondaiId": 101200130,
                "name": "第13問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
経営者は、将来の経営に影響を与える要素に関するすべての情報がそろっている  
中で意思決定を行うことはほとんどない。次の文章の空欄ＡおよびＢに入る最も適  
切なものの組み合わせを下記の解答群から選べ。  
過去のデータなどを用いて将来起こることの発生確率が予測されている場合は  
Ａがあるといい、何がどの程度の確率で起こるのかさえ予測できない場合  
はＢがあるという。両者は経済学では明確に区別される。  
〔解答群〕  
  
ア  Ａ：コールオプションＢ：リスク  
  
イ  Ａ：不確実性Ｂ：コールオプション  
  
ウ  Ａ：不確実性Ｂ：リスク  
  
エ  Ａ：リスクＢ：コールオプション  
  
オ  Ａ：リスクＢ：不確実性`
        },
        {
                "mondaiId": 101200140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
何度も飛行機に乗ることを経験するごとに、自動車に乗ることよりも危険の発生  
確率が低いことを知ることによって飛行機を利用するという選択を行う場合があ  
る。このような行動心理学の考え方の背景には、「ある結果（データ）が得られたと  
き、その結果を反映させて事後確率を求める」という考え方があるが、その考え方  
を表す言葉として最も適切なものはどれか。  
  
ア  ガウス\u0002マルコフの定理  
  
イ  コースの定理  
  
ウ  中心極限定理  
  
エ  ベイズの定理  
`
        },
        {
                "mondaiId": 101200150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
製品に関する情報が偽って宣伝されると、市場メカニズムに多大な悪影響を与え  
る。この点に関連して、次の文章の空欄ＡおよびＢに入る最も適切なものの組み合  
わせを下記の解答群から選べ。  
情報の非対称性により、消費者が市場に流通している製品の品質につき、十分な  
情報を得ることができない場合がある。アカロフによる「レモン市場」の考え方によ  
れば、その場合にはＡにより、平均的な品質がＢすることが知ら  
れている。  
〔解答群〕  
  
ア  Ａ：逆選択Ｂ：上昇  
  
イ  Ａ：逆選択Ｂ：低下  
  
ウ  Ａ：モラルハザードＢ：上昇  
  
エ  Ａ：モラルハザードＢ：低下`
        },
        {
                "mondaiId": 101200160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
いわゆるサブプライムローン問題が大きな問題となっている。金融機関が不良債  
権を抱えた場合、一定の自己資本比率を維持するために必要な方策として、最も適  
切なものの組み合わせを下記の解答群から選べ。  
ａ貸し出しの減少  
ｂ貸し出しの増加  
ｃ他社による当該金融機関への資本注入  
ｄ他社の株式購入  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101200171,
                "name": "第17問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。  
下図の直線で示される海辺で、\u0002店のアイスクリーム屋（X店とY店）が営業を  
している。それぞれの店は最適な立地を探して、下図の直線上の希望する場所に店  
を構えることができるとする。ここで、競合相手より右の方に立地した店は、その  
店から右の客をすべて獲得できるとする。同様に、左の方に立地した店は、その店  
から左の客をすべて獲得できるとする。\u0002つの店の間の客については、その中間点  
より自店に近い客を獲得できるとする。また、海水浴客は海辺に均等にいると想定  
する。  
\u0002\u0002\u0002\u0002\u0002  
A地点B地点C地点D地点E地点  
←左右→  
（例）  
もしX店がB地点に、Y店がD地点に店を構えると、X店はA地点からC地  
点の客を、Y店はC地点からE地点の客を獲得できる。（海辺の長さを均等に\u0003  
分割し、左から順にAからE地点としている。）  
（設問\u0004）  
もしX店が先にA地点に立地したとすると、Y店にとって最も望ましい立地  
はどこか。  
  
ア  A地点のやや右（X店の右隣）  
  
イ  B地点  
  
ウ  C地点  
  
エ  D地点  
  
オ  E地点のやや左  
`
        },
        {
                "mondaiId": 101200172,
                "name": "第17問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
ここで、両店にとってナッシュ均衡となる立地はどこか。最も適切なものを選  
べ。  
  
ア  A地点で隣り合わせに立地  
  
イ  B地点で隣り合わせに立地  
  
ウ  C地点で隣り合わせに立地  
  
エ  D地点で隣り合わせに立地  
  
オ  E地点で隣り合わせに立地  
`
        },
        {
                "mondaiId": 101200181,
                "name": "第18問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
次の文章を読んで、下記の設問に答えよ。  
消費と余暇に関する、ある労働者の当初の予算制約式が次のように与えられてい  
るとする。  
C＝w×（２４－L）  
ここで、Cは消費、wは時間当たりの賃金、Lは余暇時間とする。労働者は、余  
暇時間以外の時間に働くとする。下図のように、人々は消費と余暇に関する無差別  
曲線と予算制約式により、最適な労働時間と消費水準を決定する。ここで、この労  
働者の初期の最適点はE点で与えられている。  
        
（設問1））  
賃金が低下し、予算制約式が変化して、下図のように点線で与えられたものと  
する。このとき、新たな最適点はE′点となった。余暇の時間に対する所得効果  
と代替効果に関して、最も適切なものを下記の解答群から選べ。  
〔解答群〕  
  
ア  所得効果の影響より、代替効果の影響の方が大きい。  
  
イ  代替効果の影響より、所得効果の影響の方が大きい。  
  
ウ  所得効果の影響と代替効果の影響は同じである。  
  
エ  所得効果と代替効果、双方とも存在しない。  
`
        },
        {
                "mondaiId": 101200182,
                "name": "第18問設問2",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
もともとの最適点がE点で与えられている。政府の福祉政策により、所得水  
準がある一定水準以下の労働者に対して給付金が出ることになった。この場合、  
労働者が新たに選択する消費と余暇の時間の組み合わせに関して、最も適切なも  
のを下記の解答群から選べ。  
〔解答群〕  
  
ア  消費は上がって、労働時間を増やす。  
  
イ  消費は上がって、労働時間を減らす。  
  
ウ  消費は同じで、労働時間も変わらない。  
  
エ  消費は下がって、労働時間を増やす。  
  
オ  消費は下がって、労働時間を減らす。  
`
        },
        {
                "mondaiId": 101200190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
ある会社の製品に対する消費者行動に関して、以下のような統計データが得られ  
た。ここで、Daは自社製品への需要、ADaは自社製品に対する広告額、Paは自社  
製品の価格、Pbは競合他社製品の価格、wは経済全体の賃金上昇率とする。それ  
ぞれの変数のパラメーターはすべて統計的に有意であるとする。  
Da＝－１２＋０．４ADa－４．２Pa＋３．４Pb－３．２w  
こ��統計データの説明として、<u>最も不適切なものはどれか</u>。  
  
ア  競合他社製品の価格の上昇は、自社製品の需要に好影響を与える。  
  
イ  広告は需要に好影響を与える。  
  
ウ  自社製品の��格の上昇は、需要に悪影響を与える。  
  
エ  所得が高まれば需要が増える。  
`
        },
        {
                "mondaiId": 102200010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の資料に基づいて、支店独立会計制度における未達事項整理後の支店勘定残高  
の計算式として、最も適切なものを下記の解答群から選べ（単位：円）。  
未達事項整理前の支店勘定残高２０２，０００円（借方）  
未達事項  
\u0002本店から支店へ発送した商品９８，０００円  
\u0003支店から本店への６０，０００円の送金  
\u0004支店の売掛金１６２，０００円の本店による回収  
\u0005本店の販売費２１，０００円の支店による立替え払い  
〔解答群〕  
  
ア  ２０２，０００－１６２，０００＋６０，０００イ２０２，０００－９８，０００＋１６２，０００  
  
ウ  ２０２，０００－６０，０００－２１，０００エ２０２，０００＋２１，０００＋９８，０００`
        },
        {
                "mondaiId": 102200020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
G社では、先入先出法により商品の払出単価を計算している。ある商品の仕入と  
売上に関する次の資料に基づいて、この商品の月次売上原価として最も適切なもの  
を下記の解答群から選べ（単位：円）。  
月日摘要  
受入払出残高  
数量  
（個）  
単価  
（円）  
金額  
（円）  
数量  
（個）  
数量  
（個）  
\u0002１前月繰越５０４００２０，０００５０  
７仕入８０４００３２，０００１３０  
１２売上９０４０  
１９仕入６０４４０２６，４００１００  
２６売上７０３０  
３１次月繰越３０  
１９０７８，４００１９０  
〔解答群〕  
  
ア  ６５，２００イ６５，６８０ウ６６，０２１エ６６，４００  
`
        },
        {
                "mondaiId": 102200030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のａ～ｄのうち、繰延資産に計上することが認められるものとして最も適切な  
ものの組み合わせを下記の解答群から選べ。  
ａ株式交付費  
ｂ研究開発費  
ｃ社債発行差金  
ｄ創立費  
〔解答群〕  
  
ア  ａとｂ  
  
イ  ａとｄ  
  
ウ  ｂとｃ  
  
エ  ｃとｄ`
        },
        {
                "mondaiId": 102200040,
                "name": "第4問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
H社は、\u0002月２４日に公債（年利率\u0003％）額面総額５，０００，０００円を１００円につき  
９８円で買い入れた。債券価格には経過利息が含まれず、代金は端数利息とともに  
小切手で支払った。利払日は\u0004月３１日と\u0005月３０日の年\u0006回である。この公債の取  
得原価として最も適切なものはどれか（単位：円）。ただし、手数料は考えないこと  
とする。  
  
ア  ５，０００，０００  
  
イ  ５，０００，０００×０．９８  
  
ウ  ５，０００，０００×０．９８＋５，０００，０００×０．９８×０．０５×  
１４６  
３６５  
  
エ  ５，０００，０００×０．９８＋５，０００，０００×０．０５×  
１４５  
３６５  
`
        },
        {
                "mondaiId": 102200050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄Ａに入るＡ群の記述と空欄Ｂに入るＢ群の用語の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
将来の特定の費用又は損失で、Ａ場合には、当期の負担に属する金額を  
当期の費用又は損失として引当金に繰入れ、当該引当金の残高を貸借対照表の  
Ｂに記載する。  
【Ａ群】  
\u0002既に代価の支払が完了しまたは支払義務が確定し、これに対応する��務の提  
供を受けたにもかかわらず、その効果が将来にわたって発現するものと期待さ  
れる  
\u0003その発生が当期以前の事象に起因し、発生の可能性が高く、かつ、その金額  
を合理的に見積ることができる  
【Ｂ群】  
ａ資産の部  
ｂ負債の部  
ｃ負債の部又は資産の部  
〔解答群〕  
  
ア  \u0002とａ  
  
イ  \u0002とｂ  
  
ウ  \u0003とａ  
  
エ  \u0003とｃ  
`
        },
        {
                "mondaiId": 102200061,
                "name": "第6問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下に掲げる当期のキャッシュ・フロー計算書（単位：千円）に基づいて、下記の  
設問に答えよ。  
キャッシュ・フロー計算書  
\u0002営業活動によるキャッシュ・フロー  
税引前当期純利益２５，０００  
減価償却費８，０００  
貸倒引当金の増加額Ａ  
受取利息及び受取配当金－４，３００  
支払利息７，２００  
有形固定資産売却益Ｂ  
売上債権の増加額－１０，０００  
たな卸資産の減少額６，０００  
仕入債務の減少額－１７，０００  
小計（）  
利息及び配当金の受取額４，７００  
利息の支払額－６，２００  
法人税等の支払額－９，０００  
営業活動によるキャッシュ・フロー３，０００  
（以下省略）  
（設問1））  
空欄ＡとＢに入る数値の組み合わせとして、最も適切なものはどれか。  
  
ア  Ａ：－６００Ｂ：－２，０００  
  
イ  Ａ：－６００Ｂ：２，０００  
  
ウ  Ａ：６００Ｂ：－２，０００  
  
エ  Ａ：６００Ｂ：２，０００  
`
        },
        {
                "mondaiId": 102200062,
                "name": "第6問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
当期の営業損益に関するデータは次のとおりである。営業収入と原材料又は商  
品の仕入れによる支出の金額（単位：千円）の組み合わせとして、最も適切なもの  
を下記の解答群から選べ。  
（単位：千円）  
売上高２２０，０００売上原価１６０，０００  
販売費及び一般管理費３４，１００営業利益２５，９００  
営業損益に関するデータ  
〔解答群〕  
  
ア  営業収入：１９０，０００原材料又は商品の仕入れによる支出：１７１，０００  
  
イ  営業収入：１９０，０００原材料又は商品の仕入れによる支出：１８３，０００  
  
ウ  営業収入：２１０，０００原材料又は商品の仕入れによる支出：１７１，０００  
  
エ  営業収入：２１０，０００原材料又は商品の仕入れによる支出：１８３，０００  
`
        },
        {
                "mondaiId": 102200070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
I社はJ社の発行済株式総数の７０％を８，０００千円で一括取得した。株式取得日  
における個別貸借対照表と連結貸借対照表は次のとおりであった（単位：千円）。連  
結貸借対照表の空欄ＡとＢに入る数値の計算式の組み合わせとして、最も適切なも  
のを下記の解答群から選べ。  
ただし、上記の株式取得日におけるJ社の資産および負債の評価差額はない。  
I社貸借対照表J社貸借対照表  
諸資産８２，０００諸負債５０，０００諸資産３０，０００諸負債２０，０００  
投資８，０００資本金２８，０００資本金７，０００  
利益剰余金１２，０００利益剰余金３，０００  
９０，０００９０，０００３０，０００３０，０００  
Ａ  
Ｂ  
I社連結貸借対照表  
諸資産１１２，０００諸負債７０，０００  
のれん資本金２８，０００  
利益剰余金１２，０００  
少数株主持分  
（）（）  
〔解答群〕  
  
ア  Ａ：（７，０００＋３，０００）－８，０００Ｂ：（７，０００＋３，０００）×０．３  
  
イ  Ａ：（７，０００＋３，０００）－８，０００Ｂ：（１２，０００＋３，０００）×０．３  
  
ウ  Ａ：８，０００－（７，０００＋３，０００）×０．７Ｂ：（７，０００＋３，０００）×０．３  
  
エ  Ａ：８，０００－（７，０００＋３，０００）×０．７Ｂ：（１２，０００＋３，０００）×０．３  
`
        },
        {
                "mondaiId": 102200080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
繰延税金資産および繰延税金負債に関する次の資料（単位：千円）に基づいて、損  
益計算書（抜粋）の空欄Ａに入る最も適切なものを下記の解答群から選べ。  
期首残高当期計上額当期取崩額  
繰延税金資産３６０１４０６０  
繰延税金負債２５０５０９０  
\u0002\u0002\u0002\u0002\u0002  
損益計算書（抜粋）  
（単位：千円）  
…  
…  
税引前当期純利益２，５００  
法人税等１，１２０  
法人税等調整額Ａ  
当期純利益（）  
〔解答群〕  
  
ア  \u0003１２０イ\u0003４０ウ４０エ１２０  
`
        },
        {
                "mondaiId": 102200090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の式の空欄Ａ～Ｃに入る用語の組み合わせとして、最も適切なものを下記の解  
答群から選べ。  
Ａ＝Ｂ＋Ｃ＋製造間接費  
〔解答群〕  
  
ア  Ａ：加工費Ｂ：直接労務費Ｃ：直接経費  
  
イ  Ａ：製造原価Ｂ：直接材料費Ｃ：直接労務費  
  
ウ  Ａ：総原価Ｂ：販売費及び一般管理費Ｃ：素価  
  
エ  Ａ：素価Ｂ：直接材料費Ｃ：直接労務費`
        },
        {
                "mondaiId": 102200100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
労務費に関する次の資料に基づいて、製造原価明細書の空欄ＡとＢに入る数値の  
計算式の組み合わせとして、最も適切なものを下記の解答群から選べ。  
賃金：期首未払高３，６００千円当期支払高１１，１００千円期末未払高２，５００千円  
\u0002\u0002\u0002\u0002\u0002  
製造原価明細書  
（単位：千円）  
\u0002原材料費（）  
\u0003労務費Ａ  
\u0004経費（）  
当期総製造費用Ｂ  
期首仕掛品たな卸高８，２００  
合計（）  
期末仕掛品たな卸高７，９００  
当期製品製造原価３７，１００  
        
〔解答群〕  
  
ア  Ａ：１１，１００＋２，５００－３，６００Ｂ：３７，１００＋７，９００－８，２００  
  
イ  Ａ：１１，１００＋２，５００－３，６００Ｂ：３７，１００＋８，２００－７，９００  
  
ウ  Ａ：１１，１００＋３，６００－２，５００Ｂ：３７，１００＋７，９００－８，２００  
  
エ  Ａ：１１，１００＋３，６００－２，５００Ｂ：３７，１００＋８，２００－７，９００`
        },
        {
                "mondaiId": 102200110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
次の表において記号「↑」は指標の値の上昇を、「↓」は指標の値の低下をそれぞれ  
表している。各指標が良好になる場合の空欄Ａ～Ｃに入る記号の組み合わせとし  
て、最も適切なものを下記の解答群から選べ。  
指標  
算定式  
記号  
分子分母  
総資本回転率（）（）↑  
売上債権回転期間売上債権（）Ａ  
当座比率（）流動負債Ｂ  
固定比率固定資産（）Ｃ  
〔解答群〕  
  
ア  Ａ：↑Ｂ：↓Ｃ：↑  
  
イ  Ａ：↑Ｂ：↓Ｃ：↓  
  
ウ  Ａ：↓Ｂ：↑Ｃ：↓  
  
エ  Ａ：↓Ｂ：↑Ｃ：↑  
`
        },
        {
                "mondaiId": 102200120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
当期の損益計算書（要旨）は次のとおりである。変動費、固定費の構造は一定とす  
ると、経常利益の目標１０，５００千円を達成する売上高として、最も適切なものを下  
記の解答群から選べ（単位：千円）。  
損益計算書（要旨）  
（単位：千円）  
売上高１００，０００  
売上原価６０，５００  
販売費及び一般管理費２６，０００  
営業利益１３，５００  
営業外収益３，２００  
営業外費用６，９００  
経常利益９，８００  
（以下省略）  
（注）１．営業費用のうち固定費は２１，５００千円である。  
２．売上高が変化しても営業外収益、営業外費用は  
一定である。  
〔解答群〕  
  
ア  １０２，０００イ１０５，０００ウ１１０，０００エ１１３，０００`
        },
        {
                "mondaiId": 102200130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
株式評価に関する次の記述のうち、最も適切なものの組み合わせを下記の解答群  
から選べ。  
ａPBRの値が\u0002より小さいと、株価は\u0002株当たり純資産より高く評価されてい  
る。  
ｂPBRの値が\u0002より小さいと、株価は\u0002株当たり純資産より低く評価されてい  
る。  
ｃPERは、EPSを株価で除して算出される。  
ｄPERは、株価をEPSで除して算出される。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 102200140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
今P円を借り入れ、N年後に元利合計Q円を満期時一括返済するとき、この資  
本コスト（r）を計算する方式についての次の説明のうち、最も適切なものの組み合  
わせを下記の解答群から選べ。ただし、Nは\u0002より大きい整数で、QはPより大き  
い。  
ａ単利方式を用いると、r＝  
Q－P  
P  
で算出される。  
ｂ\u0002年複利方式を用いると、r＝  
N  
\u0002  
Q  
P  
－\u0002で算出される。  
ｃ単利方式による資本コストは、\u0002年複利方式の資本コストより高く算出され  
る。  
ｄ単利方式による資本コストは、\u0002年複利方式の資本コストより低く算出され  
る。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 102200150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
K社は次の条件で、普通社債を発行した。この普通社債の資本コスト（r）を算定  
するための計算式として最も適切なものを下記の解答群から選べ。ただし、税金は  
考えないものとする。また、式において、－（マイナス）はキャッシュ・アウトフ  
ローを意味している。  
\u0002額面１００円につき９７円で発行  
\u0002償還期限\u0002年  
\u0002クーポンレート\u0003％（\u0004年後より年\u0004回支払）  
\u0002社債発行費は額面１００円につき\u0005円（発行時に現金支払）  
〔解答群〕  
  
ア  ９５－  
３．８８  
（\u0004＋r）  
－  
３．８８  
（\u0004＋r）２－  
１０３．８８  
（\u0004＋r）３＝\u0006  
  
イ  ９５－  
\u0003  
（\u0004＋r）  
－  
\u0003  
（\u0004＋r）２－  
１０４  
（\u0004＋r）３＝\u0006  
  
ウ  ９７－  
３．８８  
（\u0004＋r）  
－  
３．８８  
（\u0004＋r）２－  
１０３．８８  
（\u0004＋r）３＝\u0006  
  
エ  ９７－  
\u0003  
（\u0004＋r）  
－  
\u0003  
（\u0004＋r）２－  
１０４  
（\u0004＋r）３＝\u0006`
        },
        {
                "mondaiId": 102200160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
次の資料に基づいて、加重平均資本コストを求めよ（単位：％）。なお、自己資本  
のコストはCAPMにより算出する。  
負債の税引前コスト４％実効税率４０％  
安全利子率２％期待市場収益率８％  
β値１．２自己資本比率（時価に基づく）４０％  
  
ア  ３．０４イ４．８ウ５．１２エ\u0007  
`
        },
        {
                "mondaiId": 102200170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
配当政策に関する説明として、最も適切なものの組み合わせを下記の解答群から  
選べ。  
ａ配当性向を安定化させる配当政策の場合、\u0002株当たり配当金額は毎期の利益変  
動によっても変動しない。  
ｂ配当性向を安定化させる配当政策の場合、\u0002株当たり配当金額は毎期の利益変  
動により変動する。  
ｃ\u0002株当たり配当金額を安定的に支払う配当政策の場合、配当性向は毎期の利益  
変動によっても変動しない。  
ｄ\u0002株当たり配当金額を安定的に支払う配当政策の場合、配当性向は毎期の利益  
変動により変動する。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ`
        },
        {
                "mondaiId": 102200180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
MM理論を説明している記述として、最も適切なものの組み合わせを解答群か  
ら選べ。なお、文中の企業価値は負債の価値と自己資本の価値の合計額を意味す  
る。  
ａ法人税がないと仮定したとき、企業価値は資本構成と無関係である。  
ｂ法人税がないと仮定したとき、企業価値は負債の利用度が高まるほど高まる。  
ｃ法人税が存在する場合、企業価値は資本構成と無関係である。  
ｄ法人税が存在する場合、企業価値は負債の利用度が高まるほど高まる。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 102200190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
L社株式に\u0002年間投資するときの投資利益率とその確率を次のとおり予想した。  
このとき、分散を計算する式として最も適切なものを下記の解答群から選べ。  
投資利益率確率  
\u0003％０．３  
\u0004％０．４  
\u0005％０．３  
〔解答群〕  
  
ア  （\u0003－５．７）×０．３＋（\u0004－５．７）×０．４＋（\u0005－５．７）×０．３  
  
イ  （\u0003－５．７）２×０．３＋（\u0004－５．７）２×０．４＋（\u0005－５．７）２×０．３  
  
ウ  （\u0003－\u0004）×０．３＋（\u0004－\u0004）×０．４＋（\u0005－\u0004）×０．３  
  
エ  （\u0003－\u0004）２×０．３＋（\u0004－\u0004）２×０．４＋（\u0005－\u0004）２×０．３  
`
        },
        {
                "mondaiId": 102200200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
自己資金で\u0002つの株式にさまざまな比率で投資するとき、当該ポートフォリオの  
収益率の期待値と標準偏差の関係を表す図形として最も適切なものはどれか。ただ  
し、ここでは－\u0003＜相関係数＜\u0003とする。  
`
        },
        {
                "mondaiId": 102200210,
                "name": "第21問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
次の文章の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。ただし、手数料、金利等は考えないこととする。  
現在\u0002ドル１０５円の為替相場（直物）である。\u0002か月後に決済日が来る\u0002万ドルの  
債権を有する企業が、\u0002ドル１０４円で\u0002万ドルのドル売り為替予約（\u0002か月後の受  
け渡し）を行うとすると、\u0002か月後の為替相場にかかわらず、円手取金を確定でき  
る。このとき、\u0002か月後の為替相場（直物）が１０８円になると、為替予約をしなかっ  
た場合に比べて円手取収入はＡ。他方、\u0002か月後の為替相場（直物）が１０３  
円になると、為替予約をしなかった場合に比べて円手取収入はＢ。  
  
ア  Ａ：\u0003万円多くなるＢ：\u0004万円少なくなる  
  
イ  Ａ：\u0003万円少なくなるＢ：\u0004万円多くなる  
  
ウ  Ａ：\u0005万円多くなるＢ：\u0002万円少なくなる  
  
エ  Ａ：\u0005万円少なくなるＢ：\u0002万円多くなる`
        },
        {
                "mondaiId": 102200220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
設備投資のキャッシュ・フローを予測する際の説明として、最も適切なものはど  
れか。  
  
ア  貸し付けている土地の貸借契約を解除し、そこに工場建設をする場合、この受  
取地代を反映させる必要はない。  
  
イ  新製品投資によって、既存の製品のキャッシュ・フローが減少する場合、減少  
するキャッシュ・フローは新製品投資のキャッシュ・フローに反映させる。  
  
ウ  投資の資金調達から生じる支払利息はキャッシュ・フローに反映させる。  
  
エ  未使用の土地に工場建設をする場合、未使用の土地は簿価で評価して投資額に  
反映させる。  
`
        },
        {
                "mondaiId": 102200230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
投資の経済性計算に関する記述として、最も適切なものの組み合わせを下記の解  
答群から選べ。  
ａ内部利益率は、投資案の正味現在価値をゼロとする割引率である。  
ｂ内部利益率は、投資案の割引キャッシュ・フローの和をゼロとする割引率であ  
る。  
ｃ収益性指数は、投資案の正味現在価値をその投資額で除して求められる。  
ｄ回収期間法は、回収後のキャッシュ・フローを無視している。  
ｅキャッシュ・フローが、当初マイナスでその後プラスになる投資案の場合、そ  
の正味現在価値は割引率が大きくなるほど大きくなる。  
〔解答群〕  
  
ア  ａとｂとｄイａとｃとｄウｂとｃとｅエｂとｄとｅ`
        },
        {
                "mondaiId": 102200240,
                "name": "第24問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
投資額５００万円を必要とし、経済命数\u0002年、各年度の減価償却費１００万円の投資  
案の税引後キャッシュ・フローが２２０万円と予測されている。この投資案の税引後  
会計的投資利益率を算出するとき、最も適切な税引後利益額はどれか（単位：万  
円）。ただし、実効税率４０％とし、減価償却費以外の費用および収益はすべて  
キャッシュ・フローとする。  
  
ア  １２０イ１３２ウ２８０エ３２０  
`
        },
        {
                "mondaiId": 102200250,
                "name": "第25問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
初期投資額４００万円を必要とする投資案（経済命数２年）の第\u0002年度のキャッ  
シュ・フローの現在価値が２００万円となる確率が０．５、３００万円となる確率が０．５  
と予測されている。  
第\u0002年度に２００万円のキャッシュ・フローの現在価値が生じた場合に、第\u0003年度  
のキャッシュ・フローの現在価値が３００万円となる確率が０．５、４００万円となる確  
率が０．５と予想されている。  
また、第\u0002年度に３００万円のキャッシュ・フローの現在価値が生じた場合に、第  
\u0003年度のキャッシュ・フローの現在価値が３００万円となる確率が０．５、４００万円と  
なる確率が０．５と予測されている。  
この投資案についての記述として最も適切なものはどれか。  
  
ア  正味現在価値が１００万円となる確率は０．５である。  
  
イ  正味現在価値が３００万円となる確率は０．５である。  
  
ウ  正味現在価値の期待値は２００万円である。  
  
エ  正味現在価値の期待値は３００万円である。  
`
        },
        {
                "mondaiId": 103200010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経営計画を策定する際には、さまざまな経営計画技法や管理技法が用いられる。  
そのような技法に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  ABM（ActivityBasedManagement）は、操業度よりも費消した補助活動を基  
準にして費用を跡づける間接費の管理技法として用いられる。  
  
イ  DCF（割引キャッシュフロー）法は、いくつかのプロジェクトの価値をキャッ  
シュフローの現在価値に換算して比較評価しようとする場合に用いられる。  
  
ウ  ガントチャートは、コンピュータを活用して、クリティカル・パスを明らかに  
し、そこに労働力や設備等を重点的に投入して効果的な日程管理をしようとする  
場合に用いられる。  
  
エ  線形計画法は、使用量に制限のある\u0002つの資源AとBを用いて、利益を最大  
化するために製品XとYをどのくらい生産すればよいかを計算する場合に用い  
られる。  
  
オ  待ち行列理論は、到着間隔やサービス時間の確率分布をもとに製品の輸送と在  
庫の管理を計画的に進める場合に用いられる。  
`
        },
        {
                "mondaiId": 103200020,
                "name": "第2問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経営資源と企業の戦略に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ある経営資源を保有しない企業は、すでに保有している企業に比べて、その複  
製が困難であると、コスト上の不利益を被りやすい。  
  
イ  企業が特定の経営資源を獲得、開発、活用する能力は、企業の歴史的経緯に依  
存しているので、先行企業は持続的な競争優位を得やすい。  
  
ウ  企業の競争優位と個々の経営資源の関係が不明確になるのは、内部者にとって  
その経営資源があまりに当然なものであったり、経営資源が個別に分離しにくく  
一体となって競争優位をつくり出しているからである。  
  
エ  競争優位の源泉である特殊な経営資源の外部からの調達可能性が高く、その調  
達コストが低いほど、それを調達する企業はコスト上優位になり、競争優位性を  
長期的に維持できる。  
  
オ  保有する経営資源が希少であることは大事であるが、そのような経営資源は特  
殊であるため、顧客の価値と合致しないことが起こりやすくなるので、これだけ  
では競争優位にはつながりにくい。  
`
        },
        {
                "mondaiId": 103200030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
競争を通じて、同業者は似通った戦略をとるグループを形成することがある。こ  
のような現象や成立の理由に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  ある製品分野の生産のために垂直統合を強めると、企業の生産体制や製品ライ  
ンは似通ってくるので、戦略グループが生まれやすくなる。  
  
イ  いったん戦略グループが形成されると、そのグループから他のグループへの移  
動は難しくなりがちであるが、グループ内では競争関係は緩和される。  
  
ウ  顧客層と製品ラインの幅を考慮して、最適生産規模を追求したり、共通コスト  
の節約を図ると、次第に一貫した戦略行動になるので、似通った企業の集団が生  
まれやすくなる。  
  
エ  同一産業内に複数の戦略グループが存在することが少なくないが、これは市場  
の広がりと製品ラインの絞り込み等が異なるからである。  
  
オ  同一産業内の戦略グループ間で収益が異なるのは、それぞれの戦略グループが  
直面する脅威と機会が異なるからである。`
        },
        {
                "mondaiId": 103200040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の成長をめぐる戦略に関する記述として、最も適切なものはどれか。  
  
ア  自社が優位を占める成長分野への他社の参入を防ぐために、積極的に生産の増  
強を図ったり、広告宣伝などのマーケティング活動を展開して、市場支配力を強  
める戦略を追求する。  
  
イ  社内の研究開発能力が不十分な場合、外部から技術導入を図ったり、重要な技  
術部品を社外から調達せざるをえないので、低価格戦略しかとりえなくなる。  
  
ウ  多角化は成長には有効であるが、総花的な戦略を強めて、企業の競争優位を喪  
失させるので、収益を悪化させることになる。  
  
エ  リストラクチャリングは自社の強みを喪失させるので、既存事業分野の価格競  
争や技術開発競争が激化しているときには回避しなければならない。  
`
        },
        {
                "mondaiId": 103200050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
日本のベンチャー企業をめぐる動向に関する記述として、最も不適切なものはど  
れか。  
  
ア  TLOや産学連携活動を通じて大学の知的資源の市場化が進んでおり、起業家  
的人材教育とあいまって、大学発ベンチャーの数は着実に増加している。  
  
イ  新興業界では、いち早く技術やマーケティングの優位性を確立して、業界の競  
争ルールに影響力を持つことができると、先行者優位を享受することができる。  
  
ウ  大企業からのスピンアウトによるハイテク・ベンチャーが少ないのは、発明者  
に報いることなく特許がすべて会社の知財になってしまったり、終身雇用慣行の  
ため独立意識が低いからである。  
  
エ  知財権保護の法的整備が進むにつれて、技術特許のビジネス化が可能になって  
おり、ハイテクを武器にするベンチャー企業の創業が多くみられるようになっ  
た。  
  
オ  ベンチャー企業への支援制度をみると、人材や経営能力などの資金以外の経営  
資源の不足を克服するには必ずしも十分ではないが、資金助成や税制優遇などは  
多様化してきている。  
`
        },
        {
                "mondaiId": 103200061,
                "name": "第6問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
２００７年度の  
\u0002  
中国国内での自動車販売台数は日本を上回ったが、生産台数も急激  
に伸びており、数年のうちに\u0002千万台に達するといわれている。日本の自動車メー  
カーは中国での生産を増強している。他方、日本の電機メーカーをみると、これま  
で中国市場で大きな市場シェアを誇っていたテレビなどの  
\u0003  
デジタル家電製品は、韓  
国や中国の製品が競争力を強めるにつれて苦戦している。電機メーカーはこのよう  
な状況を踏まえながら、  
\u0004  
生産の国内回帰を図るなど技術力を生かした戦略を展開し  
ている。  
（設問1））  
文中の下線部\u0002に関連して、中国自動車産業の戦略的な動向として、最も適切  
なものはどれか。  
  
ア  中国政府は自国自動車メーカーと対等の出資比率での合弁を前提に、外資  
メーカーの参入を認めている。  
  
イ  中国では基幹産業である自動車産業への台湾からの進出は許可されていない  
が、台湾からの部品の購入は自由であり、近年急増している。  
  
ウ  中国には競争力に乏しく生産性も低い中小の自動車メーカーが多かったの  
で、１９９０年代初頭から中国政府はその集約を図り、外資との合弁を大手メー  
カーのみに許可している。  
  
エ  日本の完成車メーカーの系列部品メーカーの中国進出が多くなっているが、  
中国では系列を超えて欧米の自動車メーカーにも部品を供給する例もみられ  
る。  
`
        },
        {
                "mondaiId": 103200062,
                "name": "第6問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  台湾の電子部品メーカーでは生産コストの安い中国への生産移転が相次いで  
いるが、金型などの生産技術も中国に移転されており、中国メーカーの技術競  
争力の強化に結び付いている。  
  
イ  中国市場の将来性に注目して、韓国の旧財閥系企業は中国での生産や販売の  
拠点を増強しながら、中国製品との価格競争に特化した戦略を展開している。  
  
ウ  中国のデジタル家電市場は大衆的な価格帯のものが中心であり、高機能で高  
額な日本製品は価格競争力が弱く相対的に市場シェアを低下させている。  
  
エ  中国のデジタル家電メーカーはキーデバイスの自社開発力が弱いので、技術  
による差別化よりも価格競争力を志向することになるが、それが大衆市場の  
ニーズと合致している。  
  
オ  中国のデジタル家電メーカーは、台湾や韓国などの電子部品メーカーから  
キーデバイスやパーツを調達して、自国のニーズに対応したデザインや仕様で  
製品を次々に開発している。  
`
        },
        {
                "mondaiId": 103200063,
                "name": "第6問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002の生産の国内回帰に関する説明として、最も不適切なものはど  
れか。  
  
ア  技術開発のスピード・アップへの対応のために、国内開発拠点を重視するこ  
とが多くなっている。  
  
イ  携帯電話やデジタルカメラなどのデジタル製品は多様な技術を垂直に統合し  
た生産体制が不可欠であり、そのため海外での生産が困難になっている。  
  
ウ  国内工場は、先端技術を駆使した自動化の推進や新しい生産技法の導入など  
により生産性を高めており、高次な製品を中心に生産を強化している。  
  
エ  特定の国に生産拠点を集中させるとカントリーリスクの回避が難しくなるた  
め、生産拠点を他の国に分散させるとともに、一部を日本に戻すべく生産の国  
内回帰に取り組んでいる。  
  
オ  日本的生産システムの強みである現場の熟練技術を喪失しないように、国内  
工場での生産を増やそうとする企業が増えている。  
`
        },
        {
                "mondaiId": 103200070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
技術イノベーションと戦略の関係に関する記述として、最も不適切なものはどれ  
か。  
  
ア  開発時の技術が顧客の支持を受けるほど、その後の技術発展の方向が制約され  
やすく、技術分野が固定化されて企業の競争優位が失われていく。  
  
イ  技術優位と市場ニーズが合致するとは限らないので、高機能の先端技術製品が  
技術的に劣る製品に敗れるという「ダーウィンの海」と呼ばれる現象がしばしば起  
こる。  
  
ウ  自社技術の拡散スピードが速い場合、技術優位性は守りにくくなるが、先発者  
利得を獲得したり、累積生産量を大きくして製品の差別化を持続的に確立するこ  
とができる。  
  
エ  市場ニーズに適合的な技術に基づく製品は、企業の成長に貢献すればするほ  
ど、革新的な技術の製品が新しい市場を築き始めると、急速に市場を失うことが  
ある。  
  
オ  部門内に蓄積された大量の情報や暗黙知などは、技術部門と営業部門の交流を  
阻むので、市場ニーズから遊離した製品が開発されやすくなる。  
`
        },
        {
                "mondaiId": 103200081,
                "name": "第8問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
企業が設立される理由はさまざまであるが、  
\u0002  
近年の会社法の施行や証券市場の整  
備によって創業やIPOが容易になったことも重要な要因である。しかし、制度が  
整備されたとはいえ、創業した企業を発展させ、持続的成長を図ることは容易では  
ない。零細企業を脱してIPOが可能な企業への成長を目指す場合、創業してから  
安定成長の軌道に乗るまでに克服しなければならないいくつかの壁がある。  
創業から間もなく直面する壁は、  
\u0003  
企業として自立するために創業時の制約条件を  
克服することである。これを克服して従業員が増え、会社としての形態が整ってく  
ると、やがて創業者は  
\u0004  
創業時の熱気を維持し組織の活力を高めることを課題として  
自覚するようになる。これが第\u0002の大きな壁で���る。この克服には時間と情熱を傾  
けて取り組まなければならない。組織として会社が動くようになると、  
\u0005  
各部署の戦  
略行動をまとめ上げて、会社としての総合力が発揮されるように組織に働きかける  
ことが重要になる。このことが自律的に発展を続ける企業への脱皮に向けて取り組  
む最後の壁になる。  
        
（設問1））  
文中の下線部\u0002のような措置によって創業やIPOが容易になったことをめぐ  
る説明として、最も適切なものはどれか。  
  
ア  会社法で新設された合同会社（LLC）は、出資者\u0002名以上の有限責任制度で、  
しかも取締役会・監査役会が不要であることから、新規創業の方式として注目  
されている。  
  
イ  会社法では最低資本金制度が廃止されて、株式会社は資本金\u0002円でも設立可  
能であり、取締役会設置会社を除き設立時の取締役は\u0003名だけで済むように  
なった。  
  
ウ  会社法では有限会社が廃止されたが、既存の有限会社は特例有限会社として  
登記し直すことによって存続できることになっており、有限会社の廃止は創業  
意欲に水を差すものではない。  
  
エ  キャピタル・ゲインへの課税方式の変更や株券の電子化は、個人の株式投資  
への誘因になっており、個人投資家の資金流入が増加して、IPOを目指す企業  
の追い風になっている。  
  
オ  東京証券取引所のジャスダック市場、大阪証券取引所のヘラクレス市場をは  
じめ各種の新興市場が開設されてかなり経過しているが、近年これらの市場で  
IPOが急増している。  
`
        },
        {
                "mondaiId": 103200082,
                "name": "第8問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1）���  
文中の下線部\u0002への対応に関する説明として、最も適切なものの組み合わせを  
下記の解答群から選べ。  
ａキャッシュフロー・マネジメントに留意して自己資本比率を高めるととも  
に、資金調達先への依存度を調整する。  
ｂ以前勤めていた企業で身につけた技能とそこからの受注で創業したが、その  
企業の将来性は厳しく受注も伸び悩んでいる。しかし、他社からの受注活動は  
一切しないようにする。  
ｃ創業の契機になった自社技術や市場は新規参入が相次いで競争が激化してお  
り、赤字転落したので、採算の見込める価格に改訂し、市場拡大を図る。  
ｄ創業時に支援を受けた以前の勤務先やそこから紹介を受けた得意先への依存  
を改めるため、新規顧客の開拓を活発に進める。  
〔解答群〕  
  
ア  ａとｂイａとｃウａとｄ  
  
エ  ｂとｃオｃとｄ  
`
        },
        {
                "mondaiId": 103200083,
                "name": "第8問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002の課題は、会社の成長にともなってしばしば発生する課題であ  
る。経営者は多くの時間をこの課題の克服のために割いているが、その対応とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  会社の目標と計画に沿って個人別の目標を設定する場合、部下の参画を求  
め、主体的な目標管理を促すとともに、新入社員に対しては上司が積極的に目  
標設定を指導する。  
  
イ  会社への忠誠心を高めるために、個人別に業績を評価し、それを給与や処遇  
に連動させた計数管理を徹底する。  
  
ウ  職務へのコミットメントを高めるために、個人別に権限と責任を明確にした  
管理システムを導入する。  
  
エ  創業の思いを共有するため、トップは従業員との対話の機会を増やし、創業  
時の思いや成功・失敗談を語るとともに、個々の仕事への意欲的なチャレンジ  
を奨励する。  
`
        },
        {
                "mondaiId": 103200084,
                "name": "第8問設問4",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002への対応に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  カンパニー制度のような自律的な事業組織を編成し、事業部長に経営計画や  
資源配分および利益目標等の企画に関するすべての責任と権限を与え、本社は  
業績の管理だけに徹するようにする。  
  
イ  機軸となる新規プロジェクトについては、そのリーダーに大きな権限を与え  
て、社内資源の動員を図り、資源のシナジーを生かしたプロジェクト遂行を進  
める。  
  
ウ  経営計画を定め、計画と統制のサイクルを各組織の単位で回すとともに、個  
人レベルでもPDCAサイクルが回るように目標管理体制を構築する。  
  
エ  事業分野が多様化して、ヒト、モノ、カネなどの配置に無駄が生まれるの  
で、全社の戦略目標を明確にしてPPMに基づいた資源配分を試みる。  
  
オ  製品市場分野ごとに現場での独自な取り組みを促すための予算や人員の配置  
を行うとともに、その実施プロセスと成果について全社レベルで意見交換や分  
析を行う。  
`
        },
        {
                "mondaiId": 103200090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
１９９０年代の日本経済は長期不況に見舞われ、しばしば失われた１０年と呼ばれて  
いる。この間、経済はグローバル化し、企業は個々に戦略的対応を繰り広げてきて  
いる。このような失われた１０年を説明するものとして、最も適切なものはどれ  
か。  
  
ア  この時代、熟練技能労働者が韓国や中国の企業にも流出したため、一部では生  
産技術の国際格差が縮まり、競争優位を失うことが見られた。  
  
イ  事業領域の選択と集中を行う企業が増えたが、狭い得意分野に特化したため市  
場適応能力を失い長期にわたって業績不良に悩まされる企業が多発し、不況を長  
引かせるところとなった。  
  
ウ  生産の海外移転が順調に進み、海外生産規模は１９９０年代末にはGDPの５０％  
に達するようになり、税制面から海外子会社を連結対象にした新しい会計制度が  
施行された。  
  
エ  不良債権処理のため低金利政策がとられたので、融資条件が大幅に緩和され、  
中小企業は資金需要を容易に満たすことができたので、廃業件数は減少した。  
`
        },
        {
                "mondaiId": 103200100,
                "name": "第10問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
競争優位の源泉を低コストと精密な加工技術に置く製造企業では、セル生産と呼  
ばれる新しい生産方式に切り換えて、一層の生産性改善に取り組む例がみられるよ  
うになった。このようなセル生産方式に関する説明として、最も不適切なものはど  
れか。  
  
ア  セル生産方式は、現場労働者を多能工化することによって成立するので、多工  
程持ちが進み、作業工程の手待ちの無駄を排除できる。  
  
イ  セル生産方式は、少人数グループの「ワークセル」を単位とするチーム生産方式  
が進化したものとみることができる。  
  
ウ  セル生産方式は、製品が多様化し変化のスピードが速い場合、生産ラインの切  
り換えコストを節約できるので有効である。  
  
エ  セル生産方式は、単調な労働を排除して労働の人間化を実現でき、従業員のモ  
チベーションが高まり、生産性が改善できる可能性が高い。  
  
オ  セル生産方式は、ベルトコンベアを完全に撤去した熟練労働による生産である  
ため、熟練労働力が不足する海外でこれを展開することは難しい。  
`
        },
        {
                "mondaiId": 103200111,
                "name": "第11問設問1",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
企業の規模や経営戦略、環境条件などさまざまな要因によって、組織が処理すべ  
き情報の量や質が異なるため、それに応じて機能別部門組織（functional  
organization）、事業部制組織（divisionalorganization）、マトリックス組織（matrix  
organization）など、異なる組織構造をデザインする必要がある。これに関して、  
下記の設問に答えよ。  
（設問1））  
機能別部門組織に関する記述として、最も適切なものはどれか。  
  
ア  機能別部門組織では、各機能部門が専門機能を基礎に編成されているため、  
全社的なコントロールを担当する次世代のトップマネジメントを養成すること  
が難しい。  
  
イ  機能別部門組織では、高度な分権化が進展しているため、トップマネジメン  
トへの集権化の程度は低い。  
  
ウ  機能別部門組織では、それぞれの部門が異なる機能を担当しているため、変  
化する環境でも部門間コンフリクトが発生する可能性は低い。  
  
エ  機能別部門組織の利点は、機能部門ごとの専門化の利益を最大限に発揮でき  
る点にあり、その分、規模の経済は犠牲になる。  
  
オ  機能別部門組織は、単一製品－市場分野に進出している企業に採用される傾  
向が高く、あまり大規模な操業には適さない。  
`
        },
        {
                "mondaiId": 103200112,
                "name": "第11問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
事業部制組織に関する記述として、最も適切なものはどれか。  
  
ア  事業部制組織では、各事業部は独立採算のプロフィットセンターとして管理  
されるために、複数の事業部にまたがる統合的な製品の開発などは遅れがちに  
なる。  
  
イ  事業部制組織では、各事業部を評価する統一的な基準がないために、本社機  
構のオーバーヘッドコストが高くなる傾向がある。  
  
ウ  事業部制組織では、本社と事業部の間に擬似的な資本市場が存在することに  
なり、一般に各事業部の限界利益率に応じて予算配分が行われる。  
  
エ  事業部制組織は、複数の製品－市場分野に進出している企業で採用される傾  
向が高く、事業部間の高度な連携をとることが容易になる。  
  
オ  事業部制組織は、本社の情報処理負担が軽減されるとともに、事業戦略に関  
する権限が本社に集中するために、事業部の再編成や既存事業の融合を通じた  
新規事業を創造しやすくなる。  
`
        },
        {
                "mondaiId": 103200113,
                "name": "第11問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
機能部門－事業部門からなる恒常的なマトリックス組織に関する記述として、  
最も適切なものはどれか。  
  
ア  マトリックス組織が有効に機能するためには、複数の命令系統に柔軟に対応  
し、コンフリクトを創造的に解決する組織文化の裏付けが必要である。  
  
イ  マトリックス組織では、機能マネジャーと事業マネジャーが同じ内容の権限  
を持つので、従業員は\u0003人の上司の管理下におかれ高いストレスを感じる。  
  
ウ  マトリックス組織では、主要な権限を委譲された事業マネジャーと機能マネ  
ジャーのコンフリクトが発生しやすいので、トップマネジメントの情報処理負  
担は大きくなる。  
  
エ  マトリックス組織は、環境変化の速い複数の非関連事業に多角化した企業  
が、複数の事業部にまたがる横断的調整機能を導入したものである。  
  
オ  マトリックス組織は、現場での事業感覚が重要である組織に導入すると事業  
活動を制約してしまうため、主に本社機構に導入される傾向がある。  
`
        },
        {
                "mondaiId": 103200120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
組織における分業のデザインや職務設計に関する記述として、最も適切なものは  
どれか。  
  
ア  エンパワーメントとは、職務拡大（jobenlargement）が進化した形で、個人に  
割り当てる職務の幅をプランニング権限にまで広げたものである。  
  
イ  個人に割り当てる職務をあまり単純な単位に分解すると、単調な作業を繰り返  
すだけになるため、職務の幅を広げて多能工化することで、職務充実（job  
enrichment）を図る必要がある。  
  
ウ  個人の多能工化と品質管理を一体化した生産方式を導入すると、生産数量の頻  
繁な変化に柔軟に対応しつつ、低コストで一定以��の品質を維持することができ  
る。  
  
エ  職務のプロセスを標準化すると、従業員の専門能力を向上させるとともに、ア  
  
ウ  トプットの分散が大きくなり検査コストが増える。  
  
オ  職務の目標や評価基準を標準化することを通じて、職務のモジュール化が促進  
されるため、管理者の調整負担は増えるが、不確実性への対応は容易になる。  
`
        },
        {
                "mondaiId": 103200130,
                "name": "第13問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
組織階層の高さを決める要因に「管理の幅（spanofcontrol）」がある。「管理の幅」  
に関する記述として、最も適切なものはどれか。  
  
ア  作業工程などのマニュアル化が進むと、例外事項が発生する可能性が高くなる  
ので、「管理の幅」は狭くなる。  
  
イ  職務間で同期をとる必要性が高い職場では、複数の業務にまたがる調整が必要  
になるので、「管理の幅」は広くなる。  
  
ウ  部下が複数の業務に熟練している場合には、業務間の相互依存度が高くなるの  
で、「管理の幅」は狭くなる。  
  
エ  部下間の職務の相互依存度が高く、環境が不規則に変化する場合には、「管理  
の幅」を広くとることができる。  
  
オ  部下や下位部門が、標準化された業績評価指標で統一的に管理できる場合に  
は、「管理の幅」は広くなる。  
`
        },
        {
                "mondaiId": 103200140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
ある工場で次のような問題が発生した。  
「ある日の朝１０時ごろに、従業員Kが機械で左手の指を\u0002本けがした。Kは  
直ちに医務室に行って手当てを受け、１０時３０分には、指に包帯を巻いて持ち  
場に戻り仕事を再開した。しかし、この間にKのけがに関するうわさが工場  
全体に広がっていた。そのうわさによれば、Kの所属部署から遠く離れた部  
署に行けば行くほど、Kのけがは大げさになっており、最もひどいところで  
は、Kは左手を失って入院したという話になっていた。従業員のモラールは  
著しく低下し始めた。」  
このような状況において、工場管理者がとるべき対応として、最も適切なものは  
どれか。  
  
ア  うわさが自然におさまるまで、あえて何もしない。  
  
イ  うわさには触れず、直ちに工場内放送を通じて、事実関係について発表する。  
  
ウ  このうわさがなぜ発生したのかを調査し、後日、社内報で従業員に結果を報告  
する。  
  
エ  直ちに従業員を調査し、うわさを広げた張本人を探し出すとともに、厳しい処  
分をくだす。  
  
オ  直ちに役員会を開催して対策を検討し、記者会見を開いて、うわさが事実と異  
なるということを主張する。  
`
        },
        {
                "mondaiId": 103200150,
                "name": "第15問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
リーダーシップ理論に関する記述として、最も適切なものはどれか。  
  
ア  PM理論によれば、有効なリーダーシップスタイルは、P（目標達成度）とM  
（集団維持機能）の関係および組織形態によって変わるという。  
  
イ  パス－ゴール理論によれば、フォロワーのタスク特性からあいまいさを排除  
し、タスク自体から得られる満足度を最大化するリーダーシップスタイルが望ま  
しいという。  
  
ウ  フィードラーのコンティンジェンシー理論によれば、友好的で開放的なリー  
ダーシップスタイルが望ましい成果を生むという。  
  
エ  リーダーシップに関するオハイオ研究によれば、参加型のリーダーシップが、  
専制型のリーダーシップよりも望ましいという。  
  
オ  リッカートによれば、支持的関係の原理や連結ピン機能が、媒介変数である従  
業員の信頼感や高い業績目標設定に影響を与え、その結果として生産性や欠勤率  
に影響を及ぼすという。  
`
        },
        {
                "mondaiId": 103200160,
                "name": "第16問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
機能別部門組織をとるA社は、これまで不定期に新商品企画を行いながら、耐  
久消費財を生産するメーカーである。その新商品開発活動は、企画部門における商  
品コンセプト設計、研究所における製品機能設計と製品試作、工場エンジニアリン  
グ部門における生産工程設計と量産設計、生産部門などの複数の部門にまたがる業  
務の調整を通じて行ってきた。A社の属する産業の規模が拡大し、競争が激しくな  
るにつれて、定期的に新商品開発を行う必要性が出てきた。  
A社の新商品開発組織に関する記述として、最も適切なものはどれか。  
なお、「軽量級のプロダクトマネジャー」とは、予算執行権限や人事権などを持た  
ず弱い調整権限しか持たないマネジャーを、「重量級のプロダクトマネジャー」と  
は、予算執行権限や人事権など時には機能部門長以上の権限を持つマネジャーを、  
それぞれ意味する。  
  
ア  既存の組織内で新商品開発を行うと、協力が得られにくいため、独立の新商品  
開発プロジェクト組織を設置し、軽量級のプロダクトマネジャーを配置する。  
  
イ  商品開発過程全体でのやり直しに伴うコストを最小限にするためには、各過程  
で十分な検査過程を経てから次の業務段階に移行するよう組織し、業務間の調整  
の必要性を最小限にする。  
  
ウ  商品コンセプト設計から生産に至るプロセスを連続的（sequential）に組織化  
し、各段階が終了したら次の段階にスムーズに移行できるよう、調整担当者とし  
て重量級のプロダクトマネジャーをおく。  
  
エ  商品コンセプト設計段階から、製品機能設計や試作段階の技術者を参加させて  
直接コミュニケーションを促すなど、複数の業務をオーバーラップさせながら重  
量級のプロダクトマネジャーに管理を任せる。  
`
        },
        {
                "mondaiId": 103200170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
互いに激しい競争をしている複数の企業に対して、工場で使用されている生産設  
備の一部を納入している企業P社がある。P社の製品は、それぞれの工場のエンジ  
ニアたちによって微妙に修正が施されたり、P社の想定とは異なる使用方法で利用  
されたりしているという。  
このような状況下で、P社の新商品企画が競争優位を維持する方法に関する記述  
として、最も適切なものはどれか。  
  
ア  P社と顧客企業とを自動受発注情報システムを通じてリンクし、ジャストイン  
タイムで情報が共有できるようにする。  
  
イ  P社の技術者と営業担当者が連携して、直接顧客企業の工場に入り、顧客企業  
のエンジニアと直接対話する機会を増やす。  
  
ウ  P社の研究開発投資を削減し、その分を営業費用に回し、顧客企業の工場から  
情報収集をできるようにする。  
  
エ  業界の技術情報を詳しくレポートするコンサルタントと契約し、新商品の動向  
に関する情報を得る。  
  
オ  複数の企業の多様性に対応するには、商社や問屋などを通じて営業活動をし、  
調査報告書を提出してもらう。  
`
        },
        {
                "mondaiId": 103200180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
中小企業Q社は、\u0002つの主要な部品XとYを組み合わせて商品を生産してい  
る。部品Xは汎用品で、インターフェースは業界全体で標準化されている。一  
方、部品YはQ社の特注品で、大企業R社に依頼して生産してもらっている。  
このとき、Q社は、部品XとYを生産している企業との組織間関係をどのよう  
にすればよいか、最も適切なものを選べ。  
  
ア  部品Xの供給を特定の\u0003社から受けることで、規模の経済を実現できるた  
め、取引コストを低く抑えることができる。  
  
イ  部品Xを供給できる企業が多いので、そのうちの\u0003社をQ社内部に取り込み  
部品Xを内製化していくことで、より低い取引コストで部品獲得が可能にな  
る。  
  
ウ  部品Yの技術革新が頻繁に起こる場合には、投資規模があまり大きくないな  
ら、Q社は部品Yの生産を内製化し、技術革新がもたらす成果を独占すること  
で競争優位を維持することができる。  
  
エ  部品Yを生産している企業R社にとって、Q社との取引量が大きい場合に  
は、Q社が市場取引関係を通じて部品Yを調達すれば、取引コストは低くな  
る。  
  
オ  部品Yを生産するのに大規模な設備投資を必要とする場合には、部品Yの生  
産を垂直統合してQ社の管理下に置くことが望ましい。  
`
        },
        {
                "mondaiId": 103200190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
企業が長期に成長・発展していくためには、シングルループ学習とダブルループ  
学習を適切に切り替えて行っていく必要がある。このことに関する記述として、最  
も適切なものはどれか。  
  
ア  業績評価基準を成果主義型から過程重視型にシフトすることを通じて、シング  
ルループ学習を抑え、ダブルループ学習を促進する可能性が高くなる。  
  
イ  計画策定部門と執行部門を明確に区分し、適切なコミュニケーションを確保す  
る組織を構築することで、シングルループ学習とダブルループ学習を適切に切り  
替える可能性が高くなる。  
  
ウ  執行部門により多くの権限を委譲することを進めると、シングルループ学習を  
促進し、ダブルループ学習を阻害する可能性が高くなる。  
  
エ  職務を細分化し、過程別専門化を進めていくことが、シングルループ学習を阻  
害し、ダブルループ学習を促進する可能性を高める。  
  
オ  専門化された各部門の責任・権限を明確化することを通じて、シングルループ  
学習を抑え、ダブルループ学習を促進する可能性が高くなる。`
        },
        {
                "mondaiId": 103200200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
組織の個体群生態学（populationecology）モデルに関する記述として、最も適切  
なものはどれか。  
  
ア  ある成功した企業の組織形態を、他の多くの企業が正当性を獲得するために模  
倣することを通じて、組織個体群に含まれる企業の組織形態は類似する傾向があ  
る。  
  
イ  ある組織個体群が淘汰されていくのは、政府の政策や規制などよりも、市場で  
の競争に敗退したことによって起こりやすい。  
  
ウ  各企業は環境の変化に応じて経営戦略や組織を修正していくため、その企業が  
属する組織個体群は成長していく。  
  
エ  各企業はそれぞれの経営戦略にしたがって、合理的な組織形態を採用する結  
果、同じような戦略をとる企業は類似の組織形態をとるようになる。  
`
        },
        {
                "mondaiId": 103200210,
                "name": "第21問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
近年の雇用形態や就業意識の多様化により、労働者ごとに労働条件の決定や変更  
が行われるケースが増えていることに伴い、個別労働関係紛争が増加している。こ  
れまでの個別労働関係紛争は労働基準法によって解決を図ってきたが、増加する紛  
争の解決とその未然防止および労働契約が円滑に継続するための基本ルール等を定  
めた「労働契約法」が平成２０年\u0002月\u0003日に施行された。  
労働契約法の労働契約の基本原則に関する記述として、最も不適切なものはどれ  
か。  
  
ア  労働契約は、雇用形態に応じた就業の実態に合わせて定められた個別基準によ  
り締結し、または変更すべきものとする。  
  
イ  労働契約は、労働者と使用者が仕事と生活の調和（ワークライフバランス）にも  
配慮しつつ締結し、または変更すべきものとする。  
  
ウ  労働契約は、労働者と使用者が対等の立場における合意に基づいて締結し、ま  
たは変更すべきものとする。  
  
エ  労働者と使用者は、労働契約に基づく権利の行使に当たって、それを濫用する  
ことがあってはならない。  
  
オ  労働者と使用者は、労働契約を遵守するとともに、信義に従い誠実に権利を行  
使し、義務を履行しなければならない。  
`
        },
        {
                "mondaiId": 103200220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
労働基準法では、常時１０人以上の労働者を使用する場合は就業規則を作成し、  
労働者の過半数で組織する労働組合がある場合はその労働組合、労働者の過半数で  
組織する労働組合がない場合は労働者の過半数を代表する者の意見書を添付して、  
管轄の労働基準監督署に届出しなければならないとされている。この場合の就業規  
則の法定記載事項に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  解雇の事由を含む退職に関する事項  
  
イ  始業・終業時刻や休憩時間および休日・休暇など労働時間に関する事項  
  
ウ  賞与・期末手当および退職手当に関する事項  
  
エ  賃金の決定や昇給、賃金締切日・計算方法・支払日および支払方法など賃金に  
関する事項  
`
        },
        {
                "mondaiId": 103200230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
労働者災害補償保険は、労働者の業務上または通勤途上の災害について、労働者  
を保護する観点から保険給付される制度である。しかし、中小企業の事業主や役員  
であっても労働者災害補償保険の適用が受けられる特別加入制度がある。  
中小企業の事業主等の特別加入制度に関する記述として、最も不適切なものはど  
れか。  
  
ア  特別加入ができる中小企業は、自社の労働保険の事務処理を労働保険事務組合  
に委託していることが必要である。  
  
イ  特別加入している事業主等は、事業主や役員としての業務遂行中の災害につい  
ては保険給付の対象とされていない。  
  
ウ  特別加入の対象となる中小企業には、業種や企業規模などにより一定の範囲が  
ある。  
  
エ  特別加入の申請手続きは、事業主が事業場を管轄する労働基準監督署に直接行  
うことになっている。  
`
        },
        {
                "mondaiId": 103200240,
                "name": "第24問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
企業経営の中で人材育成は不可欠の要件の\u0002つである。その手法としての能力開  
発の体系や手法に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  CDP（キャリア・ディベロップメント・プログラム）は、社員各自の希望と企業  
の人材ニーズに照らした長期的なキャリア・プランに基づく教育訓練と人事評価  
や処遇を合わせて行う必要がある。  
  
イ  OFF―JTは、集合教育、外部の講習会への参加などで、通常の業務遂行外で  
行われるため、計画的に実施することができる長所がある。  
  
ウ  OJTは、上司や先輩が部下に対して日常的に業務上の知識や技能を指導する  
方法で、その成果が仕事に直接反映されやすい長所がある。  
  
エ  教育訓練は、一般に階層別教育訓練、職能別教育訓練および課題別教育訓練か  
ら構成される。  
  
オ  自己啓発は、社員の自発性に根ざした自らが必要と考えている業務上の知識の  
レベルアップや他の知識の取得および自己の関心事について自ら挑戦すること  
で、自己啓発意欲を支援する趣旨から企業がその費用の一部を支援する義務があ  
る。  
`
        },
        {
                "mondaiId": 103200250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
厚生年金保険の保険給付には、老齢給付、障害給付および遺族給付がある。これ  
らの保険給付に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  ６０歳台前半の老齢厚生年金（特別支給の老齢厚生年金）の支給開始年齢は、生  
年月日や性別に応じて段階的に引き上げられている。  
  
イ  ６０歳台前半の老齢厚生年金（特別支給の老齢厚生年金）は、その受給権者が厚  
生年金保険の被保険者として在職している場合でもその全額が支給される。  
  
ウ  遺族給付には、厚生年金保険の被保険者や老齢厚生年金・障害厚生年金（除く  
障害等級\u0002級）の受給権者などが亡くなった場合に、その遺族に支給される遺族  
厚生年金がある。  
  
エ  障害給付には、障害等級に応じた障害厚生年金と、障害手当金（一時金）があ  
る。  
  
オ  老齢給付には、６０歳台前半の老齢厚生年金（特別支給の老齢厚生年金）と６０歳  
台後半以降の老齢厚生年金がある。  
`
        },
        {
                "mondaiId": 103200260,
                "name": "第26問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
法定労働時間は、労働基準法により\u0002週４０時間（\u0002日\u0003時間）とされているが、  
常時１０人未満の労働者を使用する商業、保健衛生業など一定の業種の事業場で  
は、\u0002週４４時間（\u0002日\u0003時間）とする特例措置が講じられている。しかし、外勤の  
多い営業職等の場合は、事業場外労働が常態であるため、労働時間の算定が困難な  
場合が多い。労働者が労働時間の全部または一部について事業場外で労働した場合  
の労働時間の算定に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  事業場外においてその業務を遂行するために通常必要な時間は、労使協定で定  
めることができ、この場合の事業場外での労働時間は同協定に定められた時間が  
労働時間とされる。  
  
イ  事業場外で労働した場合、使用者が労働時間を算定することが困難なときは、  
原則として所定労働時間労働したものとみなされる。  
  
ウ  何人かのグループで事業場外労働した場合で、そのメンバーの中に労働時間を  
管理する者がいるときは、実際に労働した時間が労働時間となる。  
  
エ  訪問先、帰社時刻など当日の業務について具体的な指示を受けて、事業場外で  
業務を遂行して帰社した場合、所定労働時間を超えていても、所定労働時間労働  
したものとみなされる。  
`
        },
        {
                "mondaiId": 103200270,
                "name": "第27問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
宿泊者に応じた上質のサービスで知られるある旅館では、若女将が前面に立って  
従業員に教育を行っている。必ずしも旅館業界での経験があったわけではないが、  
他業界での経験なども生かして、経営に取り組んでいる。その成果は顧客の再訪の  
多さとなって現れている。この若女将の行っていることとして、最も不適切なもの  
はどれか。  
  
ア  過去の膨大なサービス対応事例をまとめた詳細なマニュアルを作成して、従業  
員が暗記するまで徹底して指導している。  
  
イ  顧客重視に関する旅館の理念を記載したカードを就業中所持させている。  
  
ウ  顧客の好みについて従業員の気づいたことを、携帯端末を使って、なるべく即  
時に入力して、それを顧客データベースに統合し、全員で情報を共有している。  
  
エ  宿泊業界に限らず優秀なサービス提供企業を、従業員に見つけ出させて、それ  
を実際に体験する研修会を行っている。  
  
オ  朝礼のときに、前日のトラブルとその解決に関する情報や、良い判断をした従  
業員の対応について、話している。  
`
        },
        {
                "mondaiId": 103200280,
                "name": "第28問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
ある製品ラインを販売しているA社は、一部の顧客の支持を得ることができ  
た。次いで、一層の市場浸透を狙って同一製品ラインの中でのアイテムを拡大する  
ことに成功してきた。同社は、さらなる業績の拡大を目指して、別の製品ラインヘ  
の進出を狙っている。その際に、すでに成功した既存の製品ブランドを利用するか  
どうか検討している。これに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  価格帯が低い製品ラインに既存ブランドをつけると、既存ブランドのイメージ  
が低下してしまう。  
  
イ  既存の製品ブランドから生じるハロー効果を利用することによって、既存の製  
品ラインの顧客とは別の顧客層に訴求できる。  
  
ウ  従来とは別の市場に参入することになる場合、既存ブランドを活用してもマー  
ケティング戦略を新規に構築する必要がある。  
  
エ  新規ブランドを採用すると、新しい製品ラインが失敗した場合に、すでに成功  
していたブランドに与える影響が少ない。  
`
        },
        {
                "mondaiId": 103200290,
                "name": "第29問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
女性がスパやエステティックサロンを選択する場合、口コミの利用が多く、広告  
なども活用されている。事前に客観的な評価がしにくいので、消費者はその選択に  
際して適切な情報を得にくいという状況にある。それは、欧米においても同様であ  
り、調査員の公正な調査に基づくガイドブックが発行されたりしている。その日本  
版の出版に関して、<u>最も不適切なものはどれか</u>。  
  
ア  インターネットを通じて利用客の声を多数集め、それを調査対象選定に活用す  
ることによって、ガイドブック購入者の参加意識を高めることが重要である。  
  
イ  ガイドブック自体の認知度を高めるために、日本版の出版発表の前に、欧米版  
のガイドブックの認知度を高めるためのパブリシティを積極的に行うことが重要  
である。  
  
ウ  このガイドブックの主要な読者層と重なる女性誌とタイアップして、その女性  
誌の名前を冠したガイドブックにすることが重要である。  
  
エ  信頼度が判別できない情報が多数あるので、調査員による信頼度の高い公正な  
情報を提供することが重要である。  
  
オ  若い女性の旅行目的の\u0002つとして、スパやエステティックサロンが挙げられる  
ことがあるので、都市だけでなく、リゾート地のホテルのスパやエステティック  
サロンも対象に加えるのが重要である。  
`
        },
        {
                "mondaiId": 103200300,
                "name": "第30問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
オリンピック、サッカーワールドカップ、野球など、スポーツとビジネスのかか  
わりは、従来よりもますます深くなってきている。そのような状況に関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  スポーツイベントに協賛し、冠スポーツ大会とすることで、企業イメージを高  
めることが可能となる。  
  
イ  世界的なスポーツイベントに協賛し、関連機器を提供することにより、権威付  
けを得られることになる。  
  
ウ  世界的なスポーツイベントに協賛し、競技場で広告看板を掲出することは、新  
製品のブランドロイヤルティの確立が主な目的となっている。  
  
エ  トップアスリートに用具を提供し、その選手やチームが活躍することによっ  
て、同等もしくは類似の用具の販売に寄与する。  
  
オ  入手が困難なスポーツイベントのチケットを配布することによって、プロモー  
ション効果を高めることができる。  
`
        },
        {
                "mondaiId": 103200310,
                "name": "第31問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
プロモーション手段の\u0002つであるポイント制度、スタンプ制度、マイレージ制度  
といったもの（以下、「ポイント制度」という。）は、多様な業種・業態において採用  
されている。さらには、インターネット上でも多数みることができるようになって  
きている。このポイント制度に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  顧客が頻繁に購入しない商品にポイント制度を適用すると、頻繁に購入する商  
品に比べて、その効果が大きく現れる。  
  
イ  多数のポイントカードをすでに保有している顧客にとって、常に携帯されるポ  
イントカードとなるには、高い魅力が必要である。  
  
ウ  複数の企業間でポイントを交換できたり、相互利用できるようになる傾向にあ  
るが、利用顧客数の不均衡などで提携関係は必ずしも永続しない場合がある。  
  
エ  ポイント制度を、新会員を紹介してくれた現会員に向けて適用することによっ  
て、新規顧客の獲得を促進することができる。  
  
オ  ポイントを、単一企業内だけでなく複数の企業間で交換利用する場合は、プリ  
ペイドカード法による供託金は必要ではない。  
`
        },
        {
                "mondaiId": 103200320,
                "name": "第32問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
フランチャイザー（本部）とフランチャイジー（加盟店）とから成るフランチャイ  
ズ・チェーン制度の特徴として、<u>最も不適切なものはどれか</u>。  
  
ア  開業の資金や意欲は有しながら、十分な経営経験や店舗運営経験のない人で  
も、加盟店になることによって、独立の経営者となることができる。  
  
イ  加盟店は、経営規模の拡大を目指して、任意に対象地域を設定することはでき  
ない。  
  
ウ  経営のノウハウを持っている本部が、たとえ、十分な資金を持っていなかった  
としても、チェーンを大規模にすることができる。  
  
エ  小規模の独立の加盟店が、所有上の独立性を有したまま、共同仕入れなどの運  
営上の共同作業を行うものである。  
  
オ  本部は、加盟店から、経営指導などの対価としてロイヤルティを受け取る。  
`
        },
        {
                "mondaiId": 103200330,
                "name": "第33問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３３問  
価格設定の要因には、コスト、競争、��要がある。これらに基づく価格設定法に  
関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  価格ライン別の価格設定の場合は、各価格ライン内の製品のバリエーションに  
応じた多様な価格を設定する必要がある。  
  
イ  競争志向型の価格設定のなかには、業界の平均的価格にあわせて価格設定する  
方法がある。  
  
ウ  市場調査を行うことによって、顧客が知覚する価値を推定して、それをもとに  
価格設定していく方法がある。  
  
エ  市場をいくつかのセグメントに分けて、セグメントごとの需要の価格弾力性の  
差を利用して価格を設定する方法がある。  
  
オ  複数の商品をセットにして、価格設定することがある。この場合には、そうし  
なければ購入しないであろう顧客にとって魅力的な価格を設定する必要がある。`
        },
        {
                "mondaiId": 103200340,
                "name": "第34問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３４問  
ブランドの資産価値を考えることとあわせて、顧客の資産としての価値（顧客資  
産）を考えることが増えてきている。そこでは、新規顧客の獲得、既存顧客の維  
持、追加販売の\u0002つの要素が考えられる。これに関する記述として、最も不適切な  
ものはどれか。  
  
ア  顧客維持による収益が高いと見込まれる場合ほど、顧客獲得への投資は大きく  
なる傾向にある。  
  
イ  顧客維持による収益が高くなく、顧客獲得時の投資を回収する期間が短い場合  
には、その顧客からの次期の収益を考えて投資する傾向にある。  
  
ウ  顧客獲得の対象となるセグメント数を増加させるにつれて、増やしたセグメン  
トでのレスポンス率が徐々に低下していく傾向にある。  
  
エ  見込み顧客の将来価値を予測して、それがその顧客の獲得コストを上回るかぎ  
り顧客獲得に投資する傾向にある。  
`
        },
        {
                "mondaiId": 103200350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３５問  
不適切な製品の販売が発覚し、消費者の権利を脅かすことがある。ソーシャル  
マーケティングなどで企業の社会的責任が指摘されてから、すでに時間が経過して  
いるが、トラブル事例はなかなかゼロにはならない。アメリカにおいては、大統領  
によって消費者の権利が示されている。１９６２年のケネディ大統領による\u0002つの権  
利に加えて、１９７５年にフォード大統領が追加した消費者の権利として、最も適切  
なものはどれか。  
  
ア  安全である権利  
  
イ  意見を聞き届けられる権利  
  
ウ  消費者教育を受ける権利  
  
エ  知らされる権利  
  
オ  選択する権利`
        },
        {
                "mondaiId": 103200360,
                "name": "第36問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３６問  
消費者の購買行動は、いくつかの段階を経て行われている。これに関する記述と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  ある特定の商品カテゴリーにおけるさまざまなブランドに関する消費者の知覚  
を図にしたものを知覚マップという。  
  
イ  高関与の場合には、その商品カテゴリーの関心度が高いので、広範囲に情報探  
索活動が行われる。  
  
ウ  購買行動の出発点となる問題認識は、最寄品の場合、家庭内ストックのような  
内部と、広告などの外部からの刺激が主な要因となる。  
  
エ  消費者が商品を評価する際には、選好が重要であり、これは、この消費者の主  
観的評価に基づくものである。  
  
オ  選択の対象として、存在を知っている商品のすべてについて、情報収集・評価  
を行う傾向にある。  
`
        },
        {
                "mondaiId": 103200370,
                "name": "第37問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３７問  
販売促進活動は、社内に向けても実施されている。これに関する記述として、最  
も不適切なものはどれか。  
  
ア  シーズンオフ期における販売を刺激するために社内向けの販売促進が行われる  
ことがある。  
  
イ  社内向けの販売促進の目的の\u0002つには、顧客への訪問回数を促すことがある。  
  
ウ  新製品発売時には、製品コンセプトを明確に理解できるようにセールスマニュ  
  
ア  ルを用意する。  
  
エ  スキル向上を狙って、従業員対象の研修やセミナーが行われる。  
  
オ  セールスコンテストは、社員の緊張感を維持するために、通常は通年行われて  
いる。`
        },
        {
                "mondaiId": 103200380,
                "name": "第38問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３８問  
市場を、全体を\u0002つとみないで、セグメントに分割する接近法がしばしば用いら  
れている。この市場細分化の軸の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  居住地域は、市場細分化変数として有効性が高い。  
  
イ  サイコグラフィック変数は、刊行データによって入手することができる。  
  
ウ  デモグラフィック変数には、性別、年齢、所得などが含まれる。  
  
エ  ライフスタイルは、生活者の生活価値観に基づいている。  
  
オ  ロイヤルティは、市場細分化変数の\u0002つとなっている。  
`
        },
        {
                "mondaiId": 103200390,
                "name": "第39問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３９問  
保管に関連する業務についての記述として、<u>最も不適切なものはどれか</u>。  
  
ア  集品作業（ピッキング）において、バーコードを利用するスキャン検品を行う  
と、単なるリストピッキングに比べてその精度は高まる。  
  
イ  倉庫の中には、自動化が進んでいるものがあり、入出庫はコンピュータで管理  
される。  
  
ウ  貯蔵倉庫は、商品の品質保持や盗難予防が重要な要素となる倉庫である。  
  
エ  保管拠点を増やすことは、サービス水準の向上と物流トータルコストの低減に  
貢献する。  
  
オ  流通倉庫は、検品、仕分け、品揃えなどを伴い、短期間だけ保管する倉庫であ  
る。  
`
        },
        {
                "mondaiId": 104200010,
                "name": "第1問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
品質管理で用いられる管理図に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  p管理図は、不適合品（不良品）の数を管理するために用いられる。  
  
イ  X―R管理図は、データの平均値と範囲を用いた管理図である。  
  
ウ  管理図には、管理限界を表す線が描かれる。  
  
エ  計量値の管理図は、一般にデータが正規分布に従うことを仮定している。`
        },
        {
                "mondaiId": 104200020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
品質マネジメントシステム（JISQ９００１）に示されている「改善に関する要求事項」  
として、<u>最も不適切なものはどれか</u>。  
  
ア  継続的改善  
  
イ  是正処置  
  
ウ  トレーサビリティ  
  
エ  予防処置`
        },
        {
                "mondaiId": 104200030,
                "name": "第3問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
マテリアルハンドリングの原則に関する記述として、最も適切なものはどれか。  
  
ア  活性荷物の原則は、荷物を活性の低い状態において安定させよ、ということで  
ある。  
  
イ  自重軽減の原則は、運搬の対象物の重さをできるだけ減らせ、ということであ  
る。  
  
ウ  重力化の原則は、物の運搬に重力がかからないようにせよ、ということである。  
  
エ  継目の原則は、運搬の過程で移動の終点と次の移動の始点との間で取り扱いの  
手間を少なくせよ、ということである。  
`
        },
        {
                "mondaiId": 104200040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
稼働分析の手法であるワークサンプリングに関する記述として、最も不適切なも  
のはどれか。  
  
ア  \u0002名の観測者で、多くの観測対象の観測ができる。  
  
イ  確率論の考え方が基本となっている。  
  
ウ  作業の時間値を直接得ることができる。  
  
エ  連続観測法と比較して、労力が少なくて済む。`
        },
        {
                "mondaiId": 104200050,
                "name": "第5問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
標準時間設定の手法に関する記述として、最も適切なものはどれか。  
  
ア  PTS法は、分析が容易な方法で、繰り返しの少ない作業に適した手法であ  
る。  
  
イ  実績資料法は、作業時間のデータを分類・整理して図表や式等にまとめたもの  
を用いて標準時間を設定する方法で、同じ要素作業が含まれる作業に適した手法  
である。  
  
ウ  ストップウォッチ法は、実際の作業を直接測定する方法で、繰り返しの多い作  
業に適した手法である。  
  
エ  標準時間資料法は、過去のデータを基礎として標準時間を設定する方法で、繰  
り返しの少ない作業に適した手法である。  
`
        },
        {
                "mondaiId": 104200060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
作業速度（作業ペース）に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  作業速度の要因には、努力（働く意欲）、熟練（技量）、条件（環境）などがある。  
  
イ  作業速度は、身体動作の速度だけでなく、作業の難易度も含めて総合的に決ま  
る。  
  
ウ  標準より遅い速度で作業を行っているときには、レイティング係数の値が  
１００（％）より大きくなる。  
  
エ  レイティングは、実測の作業時間を、平均的な熟練度の作業者が持続可能な速  
度で行う時間に修正する手法である。`
        },
        {
                "mondaiId": 104200070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ある社員食堂において、食事をする人の流れの問題点を見いだしたい。  
次の条件のもとで用いられる生産管理の分析手法として、最も不適切なものを下  
記の解答群から選べ。  
【条件】  
食事をする人は、出入り口から入り、食券を購入し、トレーを取り、カウン  
ターで食事を受け取り、はしやお茶のエリアで必要なものを取り、席で食事を  
し、食器の載ったトレーを棚に返却し、出入り口から出る。  
〔解答群〕  
  
ア  稼働分析  
  
イ  作業者工程分析  
  
ウ  流動数分析  
  
エ  レイアウト分析  
`
        },
        {
                "mondaiId": 104200080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ある設備の故障分布が下表のように与えられたとき、平均故障間隔として、最も  
近い月数を下記の解答群から選べ。  
修理後故障が起こるまでの月数故障確率  
\u0002０．１５  
\u0003０．０５  
\u0004０．１０  
\u0005０．１５  
\u0006０．２０  
\u0007０．３５  
〔解答群〕  
  
ア  \u0003  
  
イ  \u0004  
  
ウ  \u0005  
  
エ  \u0006`
        },
        {
                "mondaiId": 104200090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
自主管理活動に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  活動を行う理由の\u0002つに、従業員の相互啓発の促進がある。  
  
イ  企業組織における、上から下への命令系統を重視した活動である。  
  
ウ  職場において、人を生かすことが特徴の\u0002つとなっている。  
  
エ  品質管理、工程管理、設備管理、ミス防止、安全などに関連した活動も展開さ  
れている。  
`
        },
        {
                "mondaiId": 104200100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
省エネルギー法（エネルギーの使用の合理化に関する法律）で示されている、「工  
場においてエネルギーを使用して事業を行う者の判断の基準となるべき事項」に関  
するものとして、<u>最も不適切なものはどれか</u>。  
  
ア  人的エネルギー使用の合理化  
  
イ  燃料の燃焼の合理化  
  
ウ  廃熱の回収利用  
  
エ  放射、伝導、抵抗等によるエネルギーの損失の防止`
        },
        {
                "mondaiId": 104200110,
                "name": "第11問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
生産形態は、生産の時期、品種と生産量の多少、仕事の流し方によって分類され  
る。生産形態の組み合わせとして、最も関連性の弱いものはどれか。  
  
ア  受注生産―多品種少量生産―個別生産  
  
イ  受注生産―多品種少量生産―ロット生産  
  
ウ  見込生産―少品種多量生産―ロット生産  
  
エ  見込生産―多品種少量生産―連続生産  
`
        },
        {
                "mondaiId": 104200120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
JIS生産管理用語におけるサプライチェーンマネジメントを定義した次の文中の  
空欄Ａ～Ｃに入る最も適切な用語の組み合わせを、下記の解答群から選べ。  
サプライチェーンマネジメントは、資材供給から生産、流通、販売に至る物又は  
Ａの供給連鎖をネットワークで結び、販売情報、需要情報などを部門間又  
はＢでリアルタイムに共有することによって、経営業務全体のＣ  
及び効率を高めながら顧客満足を実現する経営コンセプトである。  
〔解答群〕  
  
ア  Ａ：サービスＢ：企業間Ｃ：スピード  
  
イ  Ａ：サービスＢ：工場間Ｃ：経済性  
  
ウ  Ａ：情報Ｂ：企業間Ｃ：経済性  
  
エ  Ａ：情報Ｂ：工場間Ｃ：スピード`
        },
        {
                "mondaiId": 104200130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
製品企画業務の流れに沿ってその手法を並べた順序として、最も適切なものはど  
れか。  
  
ア  アンケート調査→コンジョイント分析→ポジショニング分析  
  
イ  アンケート調査→ポジショニング分析→コンジョイント分析  
  
ウ  コンジョイント分析→アンケート調査→ポジショニング分析  
  
エ  ポジショニング分析→コンジョイント分析→アンケート調査  
`
        },
        {
                "mondaiId": 104200140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
製品設計に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  機能設計は、期待する製品の性能を発揮するのに必要な機能とそれらの関連を  
求め、各機能を実現させる構造を求める活動である。  
  
イ  コンカレントエンジニアリングは、製品設計と製造、販売などの統合化、同時  
進行化を行うための方法である。  
  
ウ  生産設計は、製品設計で指定した製品品質、生産量、納期を考慮した工程表や  
工程図を作成し、作業方法および生産設備を選定する活動である。  
  
エ  製品設計は、期待する製品の性能を発揮させるために、構成部品の機能・形状  
とそれらの関連を決める活動である。`
        },
        {
                "mondaiId": 104200150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
ナノテクノロジーに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  大きなものを加工して、ナノメータのサイズの構造物あるいはナノメータの精  
度で構造物を作っていく技術は、トップダウンのナノテクノロジーと呼ばれてい  
る。  
  
イ  原子や分子を積み重ねて、ナノメータの精度で制御された新しい材料やデバイ  
スを作る技術は、ボトムアップのナノテクノロジーと呼ばれている。  
  
ウ  ナノテクノロジーの分野で、生物学と工学の融合を図り、新しい分野の新しい  
産業を創出していく技術は、バイオインフォマティクスと呼ばれている。  
  
エ  ナノメータは、１０億分の１メータを示す長さの単位である。  
`
        },
        {
                "mondaiId": 104200160,
                "name": "第16問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
ある工程では、一定の生産速度\u0002（個/時）で、一定の速度\u0003（個/時）で到着する  
需要を満たすため、間欠的にロット生産を行っている（下図参照）。生産に必要な材  
料は専用容器に収容され、工程に供給される。空になった容器に材料を収容し、工  
程に供給するのに必要な時間は\u0003（時/回）であるとする。容器の収容数（個）をでき  
るだけ小さくしたい。  
容器の数を\u0004つとしたとき、容器の最小収容数に最も近い値を下記の解答群から  
選べ。  
図ロット生産における在庫の推移  
〔解答群〕  
  
ア  ７０イ８０ウ９０エ１００  
`
        },
        {
                "mondaiId": 104200170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
ライン生産方式に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  各工程の作業時間は、サイクルタイム以下でなければならない。  
  
イ  サイクルタイムはピッチタイムとも呼ばれ、品物が生産ラインから送り出され  
ていく時間間隔を意味する。  
  
ウ  生産ラインの編成効率は、  
ステーション数×サイクルタイム  
作業時間の総和  
で計算される。  
  
エ  タクト方式は、すべての工程が同時に作業を開始し、一定時間間隔をもって、  
品物が一斉に次の工程に移動する方式である。`
        },
        {
                "mondaiId": 104200180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
\u0002つの生産設備M１、M２が直列に連結されたフローショップ工程で、\u0003つの  
ジョブの総処理時間を最小にする生産スケジュールについて考える。すなわち、各  
ジョブは、まず、生産設備M１で処理され、次にM２で処理される。ただし、各生  
産設備は、\u0004度に\u0004つのジョブしか処理できないものとする。  
各ジョブの各生産設備における処理時間が下表に示されるとき、最小の総処理時  
間（すべてのジョブの処理を完了するまでの時間）を下記の解答群から選べ。  
表処理時間データ  
M１M２  
ジョブ\u0004\u0003\u0003  
ジョブ\u0002\u0005\u0006  
ジョブ\u0007\u0006\u0007  
ジョブ\u0006\u00023  
ジョブ\u0003\u0003  
合計２２２７  
〔解答群〕  
  
ア  ２７イ２９ウ３１エ３３  
`
        },
        {
                "mondaiId": 104200190,
                "name": "第19問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
外注管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  外注先の負荷状況は、外注先を選定するための重要な基準の\u0002つである。  
  
イ  外注したほうがコスト低減になっても、自社で生産可能な場合は必ず内作を利  
用する。  
  
ウ  外注品は、購入側企業の仕様が加えられた品物で、一般市販品と区別される。  
  
エ  外注を利用する目的には、経営上の危険分散、生産の平準化などがある。`
        },
        {
                "mondaiId": 104200200,
                "name": "第20問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
購買管理に関する記述として、最も適切なものはどれか。  
  
ア  経済的発注量（EOQ）は、一般に、発注費、在庫保管費および品切れ費の総計  
を最小にする発注量である。  
  
イ  定期発注方式における安全在庫量は、一般に、発注間隔が長くなると多くな  
る。  
  
ウ  定期補充点方式などで用いられる補充点は、発注間隔中の推定需要量として求  
められる。  
  
エ  定量発注方式などで用いられる発注点は、調達期間中の推定需要量として求め  
られる。  
`
        },
        {
                "mondaiId": 104200210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
平成１９年\u0002月に施行された改正建築基準法に取り入れられた新しい内容に関す  
る記述として、最も適切なものはどれか。  
  
ア  階数が二以上である建築物の一定の工程についての中間検査を義務づける。  
  
イ  建築確認の審査機関として民間の参入を図る。  
  
ウ  構造計算適合性判定制度の導入を図る。  
  
エ  都市の中心市街地内での建物の制限の緩和を図る。`
        },
        {
                "mondaiId": 104200220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
平成１８年度に改正された、いわゆる「まちづくり三法」に関する記述として、最  
も不適切なものはどれか。  
  
ア  いわゆる「まちづくり三法」とは、中心市街地活性化法・大規模小売店舗法・都  
市計画法の三法を指す。  
  
イ  いわゆる「まちづくり三法」の\u0003つである中心市街地活性化法では、「中心市街  
地活性化基本計画」については内閣総理大臣の認定を得なければならなくなっ  
た。  
  
ウ  いわゆる「まちづくり三法」の\u0003つである中心市街地活性化法では、中心市街地  
活性化の中心的役割を担ってきた「TMO制度」から「中心市街地活性化協議会制  
度」に切り替わった。  
  
エ  いわゆる「まちづくり三法」の改正点には、大型店（大規模集客施設）等の郊外立  
地にブレーキをかける目的も含まれる。  
`
        },
        {
                "mondaiId": 104200230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
陳列に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  エンド陳列とは、ゴンドラエンドで行う大量陳列のことをいい、コストをあま  
りかけない陳列方法である。  
  
イ  ゴールデンゾーンとは、商品を最も取りやすい高さの範囲のことで、一般的に  
男性は床より８０～１４０cm、女性は７０～１３０cm程度の範囲をいう。  
  
ウ  ジャンブル陳列とは、ゴンドラエンドで行うような小品目大量陳列を、ゴンド  
ラのライン内で行う陳列方法である。  
  
エ  集視陳列とは、陳列の方法を変えることで注目度を高めようとする陳列方法で  
ある。`
        },
        {
                "mondaiId": 104200240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
次の文章は、照明の光源の\u0002つの特性に関する記述である。  
文中の空欄Ａ～Ｃに入る最も適切な用語の組み合わせを下記の解答群から選べ。  
１．明るさの度合いで光の量を表す光束のことをＡという。  
２．光源のもつ光の色を表す色温度のことをＢという。  
３．物が正確に見える光の演色性を表す色彩の再現忠実度合いのことをＣ  
という。  
【用語】  
\u0002lx（ルクス）\u0003Ra（アールエイ）  
\u0004K（ケルビン）\u0005lｍ（ルーメン）  
〔解答群〕  
  
ア  Ａ：\u0002Ｂ：\u0003Ｃ：\u0004  
  
イ  Ａ：\u0003Ｂ：\u0002Ｃ：\u0005  
  
ウ  Ａ：\u0004Ｂ：\u0005Ｃ：\u0003  
  
エ  Ａ：\u0005Ｂ：\u0004Ｃ：\u0003  
`
        },
        {
                "mondaiId": 104200250,
                "name": "第25問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
サプライチェーンマネジメントにおいて、ブルウィップ効果の抑制は重要であ  
る。サプライチェーンにおけるブルウィップ効果について、最も不適切なものはど  
れか。  
  
ア  VMI（VendorManagedInventory）の導入は、購入者側の在庫リスクの削減に  
は効果があるが、ブルウィップ効果の抑制には直接的には効果がない。  
  
イ  卸売業が、小売業のPOSデータを自らの在庫管理に活用すると、ブルウィッ  
プ効果はある程度抑制できる。  
  
ウ  卸売業が、複数の小売店舗からの受注を曜日指定などのスケジュール発注に  
よって平準化すると、ブルウィップ効果の発生をある程度抑制できる。  
  
エ  サプライチェーンの各段階で予測に基づき在庫に関する意思決定をすると、ブ  
ルウィップ効果が発生する。  
  
オ  サプライチェーンの各段階の調達リードタイムがゼロの場合、原理的にはブルウィップ効果は発生しない。`
        },
        {
                "mondaiId": 104200260,
                "name": "第26問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
クロスドッキングに関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  クロスドッキングは、通過型物流センターで多く導入されている。  
  
イ  クロスドッキングは、通常、サプライチェーン全体で店舗に近い川下の物流セ  
ンターで行われる。  
  
ウ  クロスドッキングを行うためには、事前に荷受けのタイミングを調整しておく  
ことが重要である。  
  
エ  クロスドッキングを行うためには、物流センターに入荷する時点で店舗別に小  
分けされていることが望ましい。  
  
オ  チルド食品や日配品の物流では、クロスドッキング方式は適さない。  
`
        },
        {
                "mondaiId": 104200270,
                "name": "第27問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
倉庫管理とピッキングに関する次の記述について、最も適切なものの組み合わせ  
を下記の解答群から選べ。  
ａ一般に、出荷量が多く高回転の商品群は、入荷即出荷ができる倉庫の出入り口  
の近くに置くのがよい。  
ｂフリーロケーションでは、\u0002アイテムが複数のロケーションに登録される場合  
がある。  
ｃ出荷先が多く多品種で、\u0002品目当たりの出荷数量が少ない場合には、トータル  
ピッキングが適している。  
ｄ平場による保管方法は、作業性と保管効率の両面で劣っている。  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｃ  
  
エ  ｂとｄオｃとｄ`
        },
        {
                "mondaiId": 104200280,
                "name": "第28問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
サプライチェーンマネジメント（以下、「SCM」という。）におけるRFIDについ  
て、最も適切なものはどれか。  
  
ア  GPSによって貨物の位置に関する情報が取得できれば、リアルタイムでの  
SCMが可能になる。  
  
イ  ICタグから取得された情報を使うと、物流におけるトラブルの原因が判明す  
る。  
  
ウ  RFIDのSCMへの活用の観点からは、国際的な標準規格が確立されることが  
望ましい。  
  
エ  サプライチェーン全体でのトレーサビリティは、個品に対するICタグの添付  
で可能になる。  
  
オ  物流におけるICタグのコードは、川上のメーカーが管理することが望まし  
い。  
`
        },
        {
                "mondaiId": 104200290,
                "name": "第29問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
需要予測に関する次の記述について、最も適切なものの組み合わせを下記の解答  
群から選べ。  
ａ移動平均法や指数平滑法は、データのノイズの除去には適さない。  
ｂ需要量に影響を及ぼす諸要因の構造分析には、多変量解析が用いられることが  
ある。  
ｃ需要予測は、品目単位で詳細に行うほど当該カテゴリー全体の予測精度が向上  
する。  
ｄ製品の普及率の推移は、ロジスティック曲線が当てはまることが多い。  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｃ  
  
エ  ｂとｄオｃとｄ`
        },
        {
                "mondaiId": 104200300,
                "name": "第30問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
ビジュアル・マーチャンダイジング（以下、「VMD」という。）は、商品の良さや特  
性を視覚に訴求する商品演出の意味である。VMDを行う目的に関して、最も不適  
切なものはどれか。  
  
ア  商品価値を最大限に表現することをねらいとする。  
  
イ  陳列商品における販売促進の有効性を高めることをねらいとする。  
  
ウ  店舗における重点商品を、消費者に明確に伝達することをねらいとする。  
  
エ  販売員個々人の感性を重視した陳列にすることで、消費者からの評価を高める  
ことをねらいとする。  
  
オ  有効なインストア・プロモーションの推進をねらいとする。  
`
        },
        {
                "mondaiId": 104200310,
                "name": "第31問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
商品の陳列の基本に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  消費者の手に届きやすく、崩れないように安定した陳列をすることが重要にな  
る。  
  
イ  商品の大きさ、形態、色などによって陳列の高さを決定することが重要にな  
る。  
  
ウ  商品の分類やレイアウトなどにより、消費者の立場から商品の陳列位置を決定  
することが重要になる。  
  
エ  陳列什器やPOP広告の訴求を優先することが重要になる。  
  
オ  用途の類似する商品を比較できるように分類した上で、それらを一定の幅にま  
とめて陳列することが重要になる。`
        },
        {
                "mondaiId": 104200320,
                "name": "第32問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
コンビニエンス・ストアの店舗運営において、店舗の生産性はスペース収益性  
（売場の単位スペース当たりの収益性）で評価することができる。このスペース収益  
性に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  スペース収益性は、粗利益率と単位スペース当たり在庫額の\u0002つから構成され  
ている。  
  
イ  スペース収益性は、単位スペース当たり粗利益額を高めることで向上する。  
  
ウ  スペース収益性は、店舗や商品カテゴリーなどの各レベルで生産性を示すこと  
ができる。  
  
エ  スペース収益性を指標化することによって、マーチャンダイジング、インスト  
  
ア  ・マーチャンダイジング、新商品導入管理の改善に役立てることができる。  
`
        },
        {
                "mondaiId": 104200330,
                "name": "第33問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３３問  
売場における有効な商品配置・売場配賦に関するＡ群の語群とＢ群の説明文の組  
み合わせとして、最も適切なものを下記の解答群から選べ。  
＜Ａ群＞  
１ニーズ距離の最小化  
２視認性の最大化  
３単位売上高の均等化  
４シェルフごとのROIの均衡化  
５商品力・販売力の結合  
＜Ｂ群＞  
ａ良い売場に強い商品を配置することを目指すことである。  
ｂ「品目別純利益÷単位面積当たりの投資額」が、すべての売場で均衡するよう  
に品目別の面積配賦を目指すことである。  
ｃ品目別の陳列量について、単位面積当たりの売上高がすべての売場で均等に  
なることを目指すことである。  
ｄ陳列そのものが有するストーリー性やカラー・コンディショニングなど、陳  
列棚全体が見渡せるようにすることである。  
ｅ消費者の関連購入の割合の高い商品を近接して配置することで、関連購入の  
促進を目指すことである。  
〔解答群〕  
  
ア  \u0002とｄイ\u0003とｃウ\u0004とｂエ\u0005とｅオ\u0006とａ  
`
        },
        {
                "mondaiId": 104200340,
                "name": "第34問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３４問  
ブライアン・F．ハリスが提唱した、カテゴリー・マネジメントの推進ステップ  
における、最終段階としてのカテゴリー戦略とは、戦略に適した商品とのリンク化  
である。カテゴリー戦略と商品の特徴に関して、最も適切なものの組み合わせを下  
記の解答群から選べ。  
＜戦略名称＞  
１客数増大戦略  
２客単価増大戦略  
３利益貢献戦略  
４興奮増大戦略  
５イメージ向上戦略  
＜戦略に対応した商品の特徴＞  
ａ店舗テーマを強化し、話題を提供する商品  
ｂ新商品、季節性の高い商品及び急成長の商品  
ｃ標的顧客が好んで購入し、競合他店においても頻繁に販売促進する商品  
ｄ単品価格、値入率及びロイヤルティが総じて高いものの、価格弾力性が総じ  
て低い商品  
ｅ市場占拠率及び世帯浸透率が高く、購入頻度が高い商品  
〔解答群〕  
  
ア  \u0002とｄイ\u0003とｃウ\u0004とｂエ\u0005とｅオ\u0006とａ  
`
        },
        {
                "mondaiId": 104200350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３５問  
小売業における代表的な価格決定の方法に関する説明として、最も適切なものは  
どれか。  
  
ア  原価加算法とは、競争企業の設定価格に対して競争的水準に決定する方法であ  
る。  
  
イ  市場価格法とは、商圏内の競争価格の主導権を確保する水準で決定する方法で  
ある。  
  
ウ  心理的価格決定法には、小売価格を５００円とせず４９８円とするような、端数価  
格と呼ばれる価格決定方法もある。  
  
エ  マークアップ法とは、小売業の名声や有名ブランド商品のイメージを積極的に  
活用・維持するために相対的に高く決定する方法である。  
  
オ  模倣的価格決定法とは、単品別に成立していると考えられる慣習的小売価格に  
よって決定する方法である。`
        },
        {
                "mondaiId": 104200360,
                "name": "第36問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３６問  
商品予算策定に関する算出数値として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0002月計画売上高５，０００万円、前年\u0002月実績売上高３，０００万円、前年\u0002月実績月  
初在庫高４，５００万円の場合に、在庫高対売上高比率法によって月初計画在庫高を  
算出すると７，５００万円になる。  
  
イ  原価が８００円、売価値入率が２０％の場合の値入額は、１６０円である。  
  
ウ  原価値入率が１５０％のとき、売価値入率は６０％である。  
  
エ  当月売上高予算４，５００万円、年間売上高予算３０，０００万円、年間予定商品回転  
率が\u0003回転である場合に、基準在庫法によって月初適正在庫高を算出すると  
７，０００万円になる。  
`
        },
        {
                "mondaiId": 104200370,
                "name": "第37問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３７問  
商品識別用コードに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  GDS（GlobalDataSynchronization）とは、商品を識別するために国際的に統一  
された１４桁の共通商品コードである。  
  
イ  GLN（GlobalLocationNumber）とは、１３桁の国際標準規格の企業・事業所  
コードのことである。  
  
ウ  GTIN（GlobalTradeItemNumber）とは、単品コードや集合包装品コードを包  
含するあらゆる荷姿の商品を識別するためのコードである。  
  
エ  ITF（Inter-leavedTwoofFive）とは、物流梱包などの外装などに表示される  
バーコードのことである。  
  
オ  JAN（JapaneseArticleNumber）とは、消費者購入単位の商品であれば、集合  
包装の形状であっても付番される共通商品コードである。`
        },
        {
                "mondaiId": 104200380,
                "name": "第38問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３８問  
近年注目を浴びているXMLを利用したEDIに関する記述として、最も適切な  
ものはどれか。  
  
ア  UN/EDIFACTに準拠したインターネット対応の可変長メッセージである。  
  
イ  各社が独自仕様をとりやすく、必ずしも全体の効率化に結びつかない。  
  
ウ  拡張性が高いことが、かえって効率を下げる危険性があるため、標準化が必要  
である。  
  
エ  システム導入は、Webサーバーを構築すればよいので、比較的安価にでき  
る。  
  
オ  社内システムとの連携の際に、手作業が発生する場合が多い。  
`
        },
        {
                "mondaiId": 104200390,
                "name": "第39問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３９問  
ターンアラウンド型取引業務に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  オンラインの発注データが取引先で複写式の統一伝票に印字され、その内容が  
仕入伝票として小売業に帰ってくることをターンアラウンドという。  
  
イ  月次の代金決済時に自社・取引先双方で売掛と買掛に相違がなくなることか  
ら、請求を省略することができる取引形態のことである。  
  
ウ  出荷、受領、請求、支払などのデータ間の連携が可能になり、決済段階での不  
照合が生じた場合の原因追求ができる仕組みである。  
  
エ  商品が販売された時点で所有権が移転すると同時に債権・債務が発生する取引  
形態であることから、取引情報を交換する必要がない取引形態のことである。  
  
オ  発注時に小売業が指定した取引番号をもとに、納品数量や受領情報が共有され  
ることにより、請求・支払業務の効率化・正確化を図ることができる取引形態の  
ことである。  
`
        },
        {
                "mondaiId": 104200401,
                "name": "第40問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４０問  
次のEDIの通信プロトコルに関する記述を読んで、下記の設問に答えよ。  
流通業界で最も古い通信プロトコルは、Ａであり、これは、漢字の使用  
がＢである。後に、ISDN回線やデジタル回線用に日本チェーンストア協  
会がＣを制定した。また、Ｄ用のプロトコルとして、JX手順や  
EDIINTAS\u0002が制定されている。  
（設問２））  
文中の空欄Ａ～Ｄに入る最も適切な用語の組み合わせはどれか。  
  
ア  Ａ：H手順（JCA―H手順）Ｂ：可能  
Ｃ：J手順（JCA手順）Ｄ：VAN  
  
イ  Ａ：J手順（JCA手順）Ｂ：不可  
Ｃ：H手順（JCA―H手順）Ｄ：インターネット  
  
ウ  Ａ：J手順（JCA手順）Ｂ：不可  
Ｃ：全銀手順Ｄ：インターネット  
  
エ  Ａ：全銀手順Ｂ：不可  
Ｃ：H手順（JCA―H手順）Ｄ：専用回線  
  
オ  Ａ：全銀手順Ｂ：可能  
Ｃ：J手順（JCA手順）Ｄ：VAN`
        },
        {
                "mondaiId": 104200402,
                "name": "第40問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部のJX手順とEDIINTAS\u0002の説明として、最も適切なものはど  
れか。  
  
ア  EDIINTAS\u0002は、クライアントから通信を起動するため、リアルタイム処  
理を苦手としている。  
  
イ  EDIINTAS\u0002は、取引量が少ない場合に効果的である。  
  
ウ  JX手順は、EDIINTAS\u0002と比べて、初期導入コストが比較的安価で済む。  
  
エ  JX手順は、海外での適用事例が多い。  
`
        },
        {
                "mondaiId": 104200410,
                "name": "第41問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４１問  
GS１-１２８（旧称UCC/EAN-１２８）コードは、標準バーコードであるJAN（Japanese  
ArticleNumber）コード、ITF（Inter-leavedTwoofFive）コードで表示できなかっ  
た多様なデータを表示することができる。  
GS１-１２８コードで表示可能なデータとして、<u>最も不適切なものはどれか</u>。  
  
ア  顧客発注番号、商品委託番号などの業務管理データ  
  
イ  梱包番号、納品数量などの物流管理データ  
  
ウ  製造日、賞味期限、ロット番号などの商品管理データ  
  
エ  販売価格、商品名などの販売管理データ`
        },
        {
                "mondaiId": 104200420,
                "name": "第42問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４２問  
統一伝票に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  業界ごとに伝票様式が異なるだけでなく、伝票の基本構成も異なっている。  
  
イ  業際統一伝票では、売上伝票と返品伝票が規格化されている。  
  
ウ  統一伝票の他に請求書の標準化を進めている業界がある。  
  
エ  統一伝票は、大量印刷によるコスト削減が期待できる。  
  
オ  統一伝票様式に業界特性を加味することにより、業種別統一伝票が相次いで制  
定されている。  
`
        },
        {
                "mondaiId": 105200010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株式会社の機関である、会計参与、監査の範囲を会計に関するものに限定する旨  
の定款の定めがある監査役及びかかる限定がない監査役について、以下の\u0002から\u0003  
の点について比較した。この比較結果を記載した表のうち、誤った内容が含まれて  
いるものを下記の解答群から選べ。  
\u0002役員（会社法第３２９条第\u0002項）に該当するか否か。  
\u0004株主総会への出席義務があるかないか。  
\u0005取締役会への出席義務があるかないか。  
\u0003監査役会の構成員となることが可能か否か。  
会計参与監査役  
（会計監査に限定）  
監査役  
（会計監査に  
限定されない）  
\u0002該当する該当する該当する  
\u0004ありありあり  
\u0005一定の場合にありありあり  
\u0003不可能不可能可能  
〔解答群〕  
  
ア  \u0002  
  
イ  \u0004  
  
ウ  \u0005  
  
エ  \u0003  
`
        },
        {
                "mondaiId": 105200020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
平成２０年\u0002月\u0003日、中小企業診断士であるあなたは、顧客から以下の相談を受  
けた。  
このときのあなたの回答として最も適切なものを下記の解答群から選べ。  
【相談概要】  
平成２０年\u0004月１０日、父親が死去した。父親は事業（個人事業）を行っていたが、  
その事業は長男が継ぐことになっている。父の事業は必ずしもうまくいっているわ  
けではなく、若干だが、資産よりも負債の方が多いようだ。次男である私は独自で  
生計を立てているので、父の負債を一切相続しないようにしたい。  
〔解答群〕  
  
ア  あなただけがお父様の負債を相続しないようにするには、家庭裁判所で相続  
放棄の手続をとらなければいけません。相続放棄の期間は、原則として、相続  
開始があったことを知ってから\u0005か月以内ですから、急いだ方がよいと思いま  
す。  
  
イ  お父様がお亡くなりになってから、１００日以内に家庭裁判所で限定承認の手  
続をとれば、資産があったときだけ返済すればよいことになりますから、他の  
相続人の方が反対しても、お一人でその手続をとられた方がよいでしょう。  
  
ウ  現時点で、あなたはお父様の事業には何も関与されていませんから、お父様  
の負債を負うことは法律上あり得ません。どうしても、気になるのであれば、  
念のため、お父様の負債だけ放棄する手続を家庭裁判所でとればよいと思いま  
す。  
  
エ  相続人全員で遺産分割協議を行って、ご長男が全部相続することにすれば、  
法律上負債も当然にご長男が相続されたことになって、あなたがお父様の負債  
を負うことはありませんので、これからゆっくり遺産分割協議を行えばよいと  
思います。  
`
        },
        {
                "mondaiId": 105200030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
C株式会社（以下「C社」という。）は、取締役会及び監査役（但し、監査の範囲を会  
計に関するものに限定する旨の定款の定めがある）を設置している会社（公開会社で  
はなく、かつ、大会社でもない）である。また、C社の事業年度は毎年\u0002月\u0003日か  
ら翌年\u0004月３１日までとされている。  
C社では、平成２１年の定時株主総会までのスケジュールを以下のとおりに定め  
た。  
このとき、会社法第４４２条に基づき、「計算書類等の本店での備え置きを開始す  
る」日は、いずれの日とするのがよいか。最も適切な日を下記の解答群から選べ。  
C社平成２１年定時総会スケジュール  
\u0004月３１日（火）基準日  
\u0002月２４日（金）取締役が計算書類及び事業報告を監査役に提出する。  
\u0005月１４日（木）取締役が計算書類及び事業報告の附属明細書を監査役に  
提出する。  
\u0005月２０日（水）監査役が監査報告の内容を通知する。  
\u0005月２２日（金）取締役会開催  
計算書類及び事業報告並びにこれらの附属明細書の承  
認・株主総会の招集の決定  
\u0006月１７日（水）招集通知発送日  
\u0006月２６日（金）定時株主総会開催日  
〔解答群〕  
  
ア  \u0005月\u0003日（金）  
  
イ  \u0005月１５日（金）  
  
ウ  \u0006月１２日（金）  
  
エ  \u0006月１９日（金）  
`
        },
        {
                "mondaiId": 105200041,
                "name": "第4問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士であるあなたは、X株式会社の全株所有者である甲社長から相談  
を受けた。以下は、あなたと甲社長との会話の一部である。この会話を読んで、下  
記の設問に答えよ。  
甲社長：「私もだいぶ高齢になったので、そろそろ引退しようと思っているんです  
が、会社にはどうも適当な後継者がいないんですよ。それで、どうしよう  
かと思っていたら、どうもY株式会社が引き継いでくれそうな感じなん  
です。  
私が持っている１００パーセントの株式をY株式会社に譲渡しようかと  
思ったのですが、当社は、若干ですが、不動産の賃貸業もやっていますか  
ら、Y株式会社に引き継いでもらうとしても本業だけにして、不動産の賃  
貸業は残しておこうかと思うんです。  
賃貸業でもちょっとした収入になりますから、私の生活の足しにもなりま  
すし、私一人でできますから。そういったこともできますかね。」  
あなた：「事業譲渡、会社分割の方法で可能になると思いますよ。」  
甲社長：「事業譲渡というのは、分かるのですが、会社分割というのを使うとどう  
いう形に進めるわけですか。」  
あなた：「Ａ」  
甲社長：「なるほど。事業譲渡と会社分割なら、どちらの方がいいわけですか。」  
あなた：「どちらにも一長一短ありますし、Y株式会社の都合にもよりますから、  
何ともいえません。」  
甲社長：「じゃあ、この\u0002つの方法で、何か違うところがあるのですか。」  
あなた：「ありますよ。例えば、Ｂ」  
        
（設問1））  
会話の中の空欄Ａに入る文章として最も適切なものはどれか。  
  
ア  不動産業をX株式会社に残して、会社分割の方法によって、本業を行う子  
会社を作ります。それから、その子会社の株式をY株式会社に譲渡します。  
  
イ  本業をX株式会社に残して、会社分割の方法によって、不動産業を行う子  
会社を作ります。その子会社設立と同時に、その子会社の株式全部をY株式  
会社に割り当てます。  
  
ウ  本業をX株式会社に残して、会社分割の方法によって、不動産業を行う子  
会社を作ります。それから、X株式会社の株式をY株式会社に譲渡します。  
  
エ  本業を分割して、当然にY株式会社の一部門とすることができますから、  
その結果、甲社長もY株式会社の株主となることができます。`
        },
        {
                "mondaiId": 105200042,
                "name": "第4問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
会話の中の空欄Ｂに入る文章として最も適切なものはどれか。  
  
ア  事業譲渡の場合は、金銭が対価でなければなりませんが、会社分割の場合  
は、法律上、Y株式会社の株式が対価でなければなりません。Y株式会社に  
とっては、会社分割の方が、資金手当が必要でない点がメリットとなります。  
  
イ  事業譲渡の場合は、譲渡した部分は、Y株式会社の一部として組み込まれま  
すが、会社分割の場合は、法人格を保ったまま、会社ごと、Y株式会社の子会  
社になります。  
  
ウ  事業譲渡の場合は、譲渡の対価は当然にY株式会社から甲社長に支払われ  
ますが、会社分割の場合は、譲渡の対価は当然にY株式会社からX株式会社  
に支払われることになります。  
  
エ  事業譲渡の場合は、取引先も従業員も当然にY株式会社に引き継がれます  
が、会社分割の場合は、取引先や従業員から個別の同意が必要となります。  
`
        },
        {
                "mondaiId": 105200051,
                "name": "第5問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下は、中小企業診断士であるあなたと、顧客であるD株式会社の乙社長との  
会話である。この会話を読んで、下記の設問に答えよ。  
なお、乙社長には、長男、次男、長女の\u0002人の子ども（いずれも嫡出子）がおり、  
長男がD株式会社の専務取締役となっている。乙社長の妻は\u0003年前他界してお  
り、次男及び長女は、ともに他県で会社員として生計を立てている。  
乙社長：「私ももう６８歳になったので、そろそろ長男に会社を任せようと思ってい  
るんですよ。ただ、当社の建物が建っている土地は、私の個人名義の土地  
ですから、私が死んだ後に、子どもたちで相続争いが起こっても困ると思  
いましてね。それで、公正証書で遺言書を作ってもらえばいいという話を  
本で読んだものですから、先月、公証人役場に行って、長男にすべての遺  
産を相続させるという遺言書を作成してもらってきたんですわ。これでも  
う安心ですよ。」  
あなた：「社長、遺言書があるから、安心とは限りませんよ。民法には、  
Ａという制度がありますから、今回の場合、ご次男とご長女は、  
それぞれが遺産のＢ分の\u0004ずつ、その権利を主張することができ  
ます。そうすると、遺産の内容によっては、ご長男が、その分を金銭で準  
備せざるを得なくなる事態もありえますので、注意された方がよろしいと  
思いますよ。」  
（設問\u0004）  
会話の中の空欄Ａに入る最も適切なものはどれか。  
  
ア  遺留分イ過剰遺言の取消ウ寄与分エ特別受益`
        },
        {
                "mondaiId": 105200052,
                "name": "第5問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
会話の中の空欄Ｂに入る最も適切なものはどれか。  
  
ア  \u0002イ\u0005ウ\u0006エ\u0007  
`
        },
        {
                "mondaiId": 105200061,
                "name": "第6問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
特許権もＡであるから、特許発明を自由に使用し、収益、処分すること  
ができる。これを特許権の効力の\u0002つとしてのＢという。そして、このこ  
とを特許法は第６８条で規定している。特許権のもう\u0002つの効力はＣであ  
る。このＣのなかには差止請求権、損害賠償請求権、侵害物廃棄請求権、  
不当利得返還請求権、Ｄ等がある。（設問1））  
文中の空欄Ａ～Ｄに入るものとして、<u>最も不適切なものはどれか</u>。  
  
ア  Ａ：財産権  
  
イ  Ｂ：専用権  
  
ウ  Ｃ：排他権  
  
エ  Ｄ：特許権の取消請求権`
        },
        {
                "mondaiId": 105200062,
                "name": "第6問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  差止請求権とは、特許権が侵害され、又は侵害されるおそれのある場合にそ  
の停止又は予防を請求する権利である。  
  
イ  侵害物廃棄請求権とは、権利侵害物の廃棄や侵害の行為に供した設備の除却  
を請求する権利である。  
  
ウ  損害賠償請求権とは、権利侵害によって生じた損害の賠償を請求する権利で  
あり、この権利は損害発生の事実を知った日から\u0004年で時効により消滅する。  
  
エ  不当利得返還請求権とは、法律上の原因なくして他人の特許権を利用して利  
益を受けた者に対し、その利益の返還を求めることのできる権利であり、故意  
過失を要件とはしない。  
`
        },
        {
                "mondaiId": 105200070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士のあなたは、いくつかの顧問先より、平成１９年\u0002月\u0003日からそ  
の登録が認められるようになった小売等役務商標について、質問を受けた。  
各質問に対する回答として、<u>最も不適切なものはどれか</u>。  
  
ア  質問「うちがこの『◯◯屋』という屋号で商標登録を受けた場合、この屋号を  
独占排他的に使用できるのでしょうか。」  
回答「もし同業者が、この『◯◯屋』という屋号を平成１９年\u0004月３１日以前か  
ら使用していた場合でも、周知でない限り、貴店の屋号と区別できるよ  
うに何らかの記号を付け加えるように求めることはできます。」  
  
イ  質問「うちの店と同じ屋号で同業者が商標権を取得してしまった場合、うち  
の屋号を使用することが制限されるのでしょうか。」  
回答「貴店は、この屋号を平成１９年\u0004月３１日以前から使用しているのです  
から、たとえ同業者に同じ屋号で商標権を取得されてしまっても、その  
使用につき一切制限を受けないはずですよ。」  
  
ウ  質問「うちの店は私で\u0004代目であり、古くから続いている雑貨屋ですが、う  
ちの店の屋号である『鈴木商店』という名前でも商標登録が受けられるの  
でしょうか。」  
回答「この『鈴木商店』という屋号はありふれており、日本全国で多くの小売  
業者が使用している可能性があるので、そのままでは商標登録を受ける  
のは難しいでしょう。」  
  
エ  質問「うちはスーパーなのですが、うちで扱っている商品についてすでにい  
くつか商品商標を取得しています。さらに小売等役務商標を取得するメ  
リットがあるのでしょうか。」  
回��「貴店のように多種類の商品を取り扱うお店にあっては、\u0003つの小売等  
役務商標で商標権を取得すれば貴店のすべての取扱商品をカバーできる  
場合があるので、経済的であり、メリットがありますよ。」  
`
        },
        {
                "mondaiId": 105200080,
                "name": "第8問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許法によれば、発明はその特許出願前に公知にしてしまったものについては、  
新規性を喪失してしまったものとして取り扱い、特許を受けることができない（特  
許法第２９条第\u0002項各号）。しかしながら、発明者にとって酷な場合もあることか  
ら、一定の要件を満たす場合には、例外として新規性を喪失していないものとして  
取り扱う規定を置いている（特許法第３０条）。この新規性の喪失の例外規定の適用  
を受けられない発明はどれか。  
  
ア  特許出願前に市場での反応を見るために発明品を試験的に販売して公知にして  
しまった発明。  
  
イ  特許出願前に特許庁長官が指定した学術団体が開催する研究集会において、文  
書で発表して公知にしてしまった発明。  
  
ウ  特許出願前に発明品を自社のカタログやパンフレットへ掲載して不特定多数の  
ものに頒布して公知にしてしまった発明。  
  
エ  特許出願前に自らの意思に反してテレビ放送を通じて公知にされてしまった発  
明。  
`
        },
        {
                "mondaiId": 105200090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業診断士のあなたは、地方都市の野菜の卸会社であるＥ株式会社を訪問し  
た際に、そこの社長との間で次のような会話を交わした。この会話の中の空欄Ａ～  
Ｄに入るものとして、最も不適切なものを下記の解答群から選べ。  
社長：「うちの会社で取り扱っている、この地域の地名◯◯に野菜の普通名称  
\u0002\u0002  
を組み合わせてこれを商品名「◯◯  
\u0002\u0002  
」とする野菜  
\u0002\u0002  
は、この地域  
の特産品ですが、\u0003～\u0004年前から隣接他県でも知られるところとなり、引  
き合いも多く、取扱高も増えています。ところが、人気が出てきたせい  
か、最近この地域以外で生産された野菜  
\u0002\u0002  
にまで◯◯の地名を付けて  
「◯◯  
\u0002\u0002  
」の商品名で出荷されてくるようになってきています。この地域  
の活性化を図る旗振り役を務めている私としては、これ以上他地域で生産  
された野菜  
\u0002\u0002  
に、この地域の地名◯◯を組み合わせた「◯◯  
\u0002\u0002  
」の商標  
が使用されないようにするために、何とかしたいと考えていますが、何か  
方法はありませんか。ほら、何とかという地域ブランドの登録制度がある  
と聞いていますが。」  
あなた：「それはＡのことではないかと思います。確か平成１８年の\u0005月か  
ら登録が認められるようになっています。」  
社長：「そうそう、それそれ、それってうちの会社でも出願することができるの  
ですかね。会社でだめならば私個人でも構いませんが・・・。」  
あなた：「いや、このＡもＢですから、確か株式会社ではだめだ  
と思いますよ。社長個人でもだめだと思います。」  
社長：「それでは一体誰が登録出願をすればよいのですか。」  
あなた：「この場合は、この地域のＣが最適と考えます。」  
社長：「あ、そう、なるほどね。それではこの地域のＣには私の幼なじ  
みがいるので、早速話をしてみましょう。その他に、このＡを取  
得するのに必要なことはありませんか。」  
あなた：「そうですね、この野菜  
\u0002\u0002  
の商品名「◯◯  
\u0002\u0002  
」は、隣接他県にも知られ  
ているようですが、ただ出願しただけでは足らず、必ずＤを証明  
する資料が必要のようです。詳しくは私の友人である弁理士を紹介いたし  
ますので、相談してみてください。」  
        
〔解答群〕  
  
ア  Ａ：地域団体商標  
  
イ  Ｂ：団体商標  
  
ウ  Ｃ：農業協同組合  
  
エ  Ｄ：著名性  
`
        },
        {
                "mondaiId": 105200101,
                "name": "第10問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
次の文章を読んで、下記の設問に答えよ。  
画面デザインとは、家電や各種情報機器等の表示部に表示される画像のデザイン  
のことをいうが、意匠法では、Ａと密接な関係にある画面のデザインにつ  
いて機器に表された状態で物品を構成する要素として保護の対象としている。具体  
的には、携帯電話機、デジタルカメラ、カーナビ、炊飯器、掃除機、複写機等にお  
いて、Ｂに用いられる画像や、その画像がなければ機器として成り立たな  
いような画像が保護の対象となる。  
しかし、意匠法は物品のＣ、模様若しくは色彩又はこれらのＤ  
を保護の対象としており、物品ごとに意匠が成立し、物品を離れて意匠は存在しな  
いものである。  
（設問1））  
文中の空欄Ａ～Ｄに入るものとして、<u>最も不適切なものはどれか</u>。  
  
ア  Ａ：機器の機能イＢ：操作ウＣ：構造エＤ：結合`
        },
        {
                "mondaiId": 105200102,
                "name": "第10問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
画面デザインに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  画面デザインの意匠でも部分意匠の登録出願をすることができる。  
  
イ  グラフィカルユーザインタフェース（GUI）のソフトウェアによって表示され  
る画像は、特定の物品と結び付きがないので、画面デザインとして保護の対象  
とはならない。  
  
ウ  ゲームを行っている状態の画面は、ゲーム機そのものの制御や設定を行う操  
作のための画面ではないので、画面デザインとして保護の対象とはならない。  
  
エ  ビデオディスクプレーヤーの録画のための画面デザインを、自社のカーナビ  
の目的地設定の画面デザインとして使用する場合、どちらか一方の物品で意匠  
の登録をしておけば、両物品共保護される。  
`
        },
        {
                "mondaiId": 105200110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
A株式会社（以下「A社」という。）は、B株式会社（以下「B社」という。）から、携帯  
電話上に表示されるB社ホームページのサイト運営に使用する目的で、ソフトウェアに関する開発業務の委託を受け、新規にプログラミングをしたソフトウェア  
Xを２０００年１２月１５日にB社に納入し、その代金を受領した。  
しかし、B社がA社に無断で、このXをB社ホームページのサイトから切り離  
して、パソコン上でも利用できるように改変したソフトウェアYを２００７年１２月  
から製造し、これをコピーして一般消費者に販売しているという事実が、最近、判  
明した。  
A社・B社いずれにも、既に開発業務委託を受けた当時の詳細を知るものはおら  
ず、開発業務委託契約についての書面も、２０００年\u0002月\u0003日付けのB社からの簡単  
な発注書以外には残っていない。当該発注書には「使途：B社ホームページのサイ  
ト運営」との記載がある。  
この場合、A社が取りうる手段について最も適切なものはどれか。  
  
ア  A社がXについて有する著作権のひとつである翻案権を根拠に、B社に対し  
てYの販売差し止めの請求をする。  
  
イ  A社がYについて有する著作権のひとつである複製権を根拠に、B社に対し  
て損害賠償の請求をする。  
  
ウ  B社の秘密情報に関する秘密保持義務違反という債務不履行を根拠に、B社に  
対して損害賠償の請求をする。  
  
エ  ソフトウェアの開発委託については、著作権法の規定により、その著作権が発  
注者（この場合はB社）に帰属することとされているので、B社に対してなんら  
請求することはできない。  
`
        },
        {
                "mondaiId": 105200120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
次の文章は、機械のパーツを製造することを業とし、資本金を１，０００万円とする  
株式会社甲の代表者社長と中小企業診断士であるあなたの会話である。この会話の  
空欄Ａには下請代金支払遅延等防止法（下請法）に違反する行為が入るが、この空欄  
にあてはまらないものを下記の解答群から選べ。  
社長：「ちょっと、最近嫌な感じがするんだけど。」  
あなた：「どうされたのですか？」  
社長：「うちの売り上げの半分を占める乙社なんだけどね、最近、乙社のライバ  
ル社との競争による価格の下げプレッシャーが強いんだ。それとね、どう  
も乙社の状態も良くないらしくてね、Ａようになったんだよ。」  
あなた：「それはいけませんねえ。たしか乙社は、資本金が\u0002億円ある工業用機械  
メーカーですよね。」  
社長：「そうそう。」  
あなた：「いわゆる下請法に基づいて、ちゃんと貴社の権利を主張するべきです  
よ。」  
〔解答群〕  
  
ア  支払期限を、従来は納品から３０日後現金払いだったものを納品から４５日後  
に定める  
  
イ  正当な理由がないのに発注した物品の納期を延期する  
  
ウ  大量発注の割引価格で見積もりさせ、その単価で少量を発注する  
  
エ  発注後直ちに発注内容を記載した書面の交付をしない  
`
        },
        {
                "mondaiId": 105200130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
日本企業と外国企業との間で締結された英文契約書における次の条項について、  
最も適切なものを下記の解答群から選べ。  
Article〇〇  
ThisAGREEMENTshallbegovernedbyandconstruedinaccordancewith  
thelawsofJapanandtheTokyoDistrictCourtofJapanshallhaveexclusive  
jurisdictionoveranydisputebetweenthepartieshereto．  
〔解答群〕  
  
ア  本条項は、外国企業と第三者の間で日本国内において紛争が起きた場合の対  
応の仕方について規定されている。  
  
イ  本条項は、この契約において準拠すべき法についてのみ規定されたものであ  
り、日本法を基準としている。  
  
ウ  本条項は、当事者間で紛争となった場合の日本法に基づく仲裁に関する手続  
きについて触れられている。  
  
エ  本条項は、当事者間に紛争が発生し、訴訟を提起する場合、東京地方裁判所  
を専属管轄裁判所としている。  
`
        },
        {
                "mondaiId": 105200140,
                "name": "第14問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
インターネットや携帯電話の掲示板などで誹謗中傷を受けたり、個人情報を掲載  
されて、個人の権利が侵害されるなどの事案が発生したときの責任や権利について  
定められた法令に「特定電気通信役務提供者の損害賠償責任の制限及び発信者情報  
の開示に関する法律」いわゆるプロバイダ責任制限法がある。その実効性を期待さ  
れて成立したが、実際に運用が始まってみるといろいろと問題も生じてきた。  
この法律の内容について、<u>最も不適切なものはどれか</u>。  
  
ア  権利者は書き込みを行った発信者情報の開示をプロバイダに請求できるが、開  
示請求した権利者の「権利が侵害されたことが明らかであるとき」に限られ、明ら  
かかどうかについての一次的な判断はプロバイダに委ねられている。  
  
イ  発信者の「表現の自由」を著しく侵害したり、誤って発信者情報を開示してしま  
う可能性などがあり、プロバイダには慎重な判断が求められるため、プロバイダ  
に故意もしくは重大な過失がなければ、開示請求に応じなくても賠償責任を負わ  
ないことを定めている。  
  
ウ  プロバイダには自己が管理するサイトについて常時監視義務が規定され、権利  
者から通知があった情報については、自己の管理するサイト内において違法な侵  
害行為があるか否かについて、常に監視していなければならないとされている。  
  
エ  プロバイダは、権利を侵害されたとする被害者から書き込み内容の削除要請が  
あったとき、要請があったことを書き込みした人物に対して伝えたにもかかわら  
ず\u0002日以内に返事がない場合には、削除などの「送信防止措置」をとることができ  
る。  
`
        },
        {
                "mondaiId": 105200150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
株式会社A（以下「A社」という。）は、株式会社B（以下「B社」という。）に対して継  
続して商品を販売しており、B社に対して売掛金債権を有している。最近、B社か  
らの支払いが滞りがちなので、B社の代表者Cとの交渉により、その支払いを確  
実にしたいと考えている。A社の対応として、<u>最も不適切なものはどれか</u>。  
  
ア  B社がA社に対する売掛金債務の履行をしない場合の強制執行について認諾  
した旨の記載がある公正証書を作成してもらう。  
  
イ  B社が第三者に対して有する売掛金債権について、A社とB社との間で集合  
債権譲渡担保設定契約を締結し、これについて債権譲渡登記をする。  
  
ウ  C個人に無期限かつ金額の上限なくA社のB社に対するすべての売掛金債権  
について口頭で保証してもらう。  
  
エ  既に存在するA社のB社に対するすべての売掛金債権について、新たに書面  
でC個人の連帯保証をしてもらう。  
`
        },
        {
                "mondaiId": 105200161,
                "name": "第16問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
次の文章を読んで、下記の設問に答えよ。  
個人事業を営んでいるA氏は、事業の信用を高めるため株式会社の設立を準備  
中である。かねてから親交のある中小企業診断士であるあなたに、会社法が平成  
１８年\u0002月に施行されたことにより会社の設立方法が変わったと聞いたがどのよう  
になったのか質問があった。また、資本金はいくらにすればよいか、設立後に注意  
しなければならないことについてアドバイスを求められた。  
（設問２））  
文中の下線部の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  公告方法は定款の絶対的記載事項ではなくなった。ただし、定款に記載する  
場合は、官報に掲載する方法か日刊新聞紙に掲載する方法のいずれかを定めな  
ければならない。  
  
イ  従来定められていた最低資本金制度が廃止された。ただし、設立に際して出  
資される財産の価額または最低額を定款に記載しなければならないため、その  
金額を下回ることはできない。  
  
ウ  同一市町村内に同一の営業のために同一又は類似の商号を登記することがで  
きないという類似商号規制が廃止された。ただし、同一の商号を同一の住所に  
登記することはできない。  
  
エ  発起設立において、払込みの取扱いをした銀行等の払込金保管証明制度が廃  
止された。ただし、募集設立においては、この制度は規定されている。  
`
        },
        {
                "mondaiId": 105200162,
                "name": "第16問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
資本金は、大きいほど資金面でみれば有利にみえる。しかし、資本金額によっ  
ては各種の法律上の適用が異なることも留意点になる。次の中で、最も不適切な  
ものはどれか。  
  
ア  会社法では、最終事業年度に係る貸借対照表に計上した資本金が\u0003億円以上  
の会社は大会社となり、会計監査人を置かなければならない。  
  
イ  株式会社の設立登記時に納める登録免許税は、資本金の額に１，０００分の\u0004を  
乗じた金額となる。ただし、その金額が１５万円に満たないときは、１５万円と  
なる。  
  
ウ  消費税法上、資本金\u0005千万円以下の会社については設立年度と翌事業年度の  
消費税の納税義務が免除される。  
  
エ  法人事業税では、各事業年度終了の日において資本金の額が\u0005億円を超える  
法人は外形標準課税が適用され、所得のほか、付加価値額と資本金等の額に応  
じて課税される。`
        },
        {
                "mondaiId": 105200163,
                "name": "第16問設問3",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0006）  
会社の設立後には、各種機関への届出が必要になる。次の中で、最も不適切な  
ものはどれか。  
  
ア  給与等を支払う法人を設立した日から\u0005か月以内に、給与支払事務所等の開  
設届出書を税務署に提出しなければならない。  
  
イ  従業員を使用する法人を設立した日から\u0003日以内に労働保険関係成立届、雇  
用保険適用事業所設置届を労働基準監督署に提出しなければならない。  
  
ウ  常時従業員を使用する法人を設立した日から\u0003日以内に健康保険・厚生年金  
保険新規適用届を社会保険事務所に提出しなければならない。  
  
エ  法人を設立した場合には、設立の日から\u0002か月以内に法人設立届出書を税務  
署に提出しなければならない。  
`
        },
        {
                "mondaiId": 105200171,
                "name": "第17問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。  
金融商品取引法では、企業内容の開示が適切に行われることを確保するため、平  
成２０年\u0002月\u0003日以降開始する事業年度から、内部統制報告制度およびＡ  
書制度を導入した。  
内部統制報告書とは、会社の属する企業集団及び会社に係る財務計算に関する書  
類その他の情報の適正性を確保するために必要な体制について評価した報告書であ  
る。  
また、Ａ書とは、有価証券報告書や半期報告書、四半期報告書の記載内  
容が、金融商品取引法令に基づき適正であることをＡした旨を記した書面  
であり、当該有価証券報告書等と併せて提出することを義務づけることによって、  
その記載内容の適正性をより高めることを目的として導入された。  
（設問２））  
文中の空欄Ａに入る用語として最も適切なものはどれか。  
  
ア  確認イ鑑定ウ宣誓エ調査`
        },
        {
                "mondaiId": 105200172,
                "name": "第17問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の内部統制報告制度の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  内部統制報告書で評価結果を表明する場合には、内部統制が有効であるか、  
または重要な欠陥があり有効でないかを記載しなければならない。  
  
イ  内部統制報告書には、公認会計士又は監査法人の監査証明を受けなければな  
らない。  
  
ウ  内部統制報告書は、有価証券報告書、半期報告書、四半期報告書を提出する  
ごとに併せて提出しなければならない。  
  
エ  有価証券報告書を提出する会社であっても、有価証券を上場または店頭登録  
していない会社は内部統制報告書の提出は義務付けられていない。  
`
        },
        {
                "mondaiId": 105200180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
会社がその株式を取引所に上場すると、投資者を保護するため金融商品取引法に  
定められた種々の開示の義務が発生する。  
企業内容の開示の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  会社は、公益又は投資者保護のため開示が必要な事象が発生した場合には、そ  
の内容を記載した臨時報告書を、遅滞なく提出しなければならない。  
  
イ  会社は、事業年度ごとに有価証券報告書を当該事業年度経過後\u0002か月以内に提  
出しなければならない。  
  
ウ  会社は、事業年度の期間を\u0002か月ごとに区分した期間ごとに四半期報告書をそ  
の各期間経過後\u0003か月以内に提出しなければならない。  
  
エ  有価証券報告書、四半期報告書、臨時報告書の開示手続きは、金融商品取引法  
第２７条の３０の\u0003に定義されているEDINET（開示用電子情報処理組織）を使用  
することが義務付けられている。  
`
        },
        {
                "mondaiId": 106200010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
業務に応じて、パーソナルコンピュータ（パソコン）の周辺機器である外部記憶装  
置やプリンタなどを適切に選択したり、増設を検討する必要がある。パソコンの周  
辺機器接続に関する以下の記述について、最も適切なものの組み合わせを下記の解  
答群から選べ。  

ａ　電源が入れられた使用中のパソコンにおいて、ハードディスクやDVD装置な  
どの周辺機器を新たに接続した場合、それらの機器を認識させて使用可能な状態  
とするには、<hintTarget>ルーティング機能の備わった</hintTarget>ハードウェアが必要である。  

<hint hidden='true'>**
❌通常、ルーティング機能を用いて周辺機器とパソコン間の通信を行う必要はありません。
**</hint>

ｂ　プリンタは、USB、セントロニクス、LANなどのインタフェースによって接  
続して利用できる。  

<hint hidden='true'>**
⭕
**</hint>    

ｃ　1台のプリンタを複数のパソコンで共有利用するためには、<hintTarget>最低1台のパソコン</hintTarget>をプリントサーバとして使用する必要がある。  

<hint hidden='true'>**
❌パソコンなしにプリントサーバとして機能する機器などを導入することでも、プリンタを共有することができます。
**</hint>

ｄ　光磁気ディスク装置を装備したパソコンがLANに接続されていれば、当該パソコンで共有利用を許可する設定を行うことによって、他のパソコンでもその光磁気ディスクを共有利用することができる。  

<hint hidden='true'>**
⭕
**</hint>    

〔解答群〕  
  
ア  ａとｂ  
イ　ａとｃ  
ウ　ｂとｄ<hint hidden='true'>**
←⭕
**</hint>    
エ　ｃとｄ  
`
        },
        {
                "mondaiId": 106200020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
事業所におけるパソコンの利用に際しては、業務内容に応じてさまざまな周辺機  
器を接続して利用する必要がある。周辺機器接続に関する以下のａ～ｄの記述と、  
その記述に適合するインタフェース名称の組み合わせとして、最も適切なものを下  
記の解答群から選べ。  
ａ液晶ディスプレイなどの表示装置を接続するためのもので、デジタル伝送に  
よって信号を伝えることができる。  
ｂハードディスク、DVD装置、ビデオカメラなどを接続するためのもので、こ  
れらの装置をデイジーチェーンやツリー状に最大６３台までつなぐことができ  
る。  
ｃ内蔵のハードディスクやDVD装置などを接続するもので、\u0002本の接続ケーブ  
ルで\u0003台までの装置をつなぐことができる。  
ｄ内蔵および外付けでハードディスクやDVD装置などを接続するもので、\u0002本  
の接続ケーブルで\u0002台の装置をつなぐことができる。  
〔解答群〕  
  
ア  ａ：D４ｂ：USB  
ｃ：SCSIｄ：パラレルATA  
  
イ  ａ：DVIｂ：IEEE１３９４  
ｃ：パラレルATAｄ：シリアルATA  
  
ウ  ａ：IEEE１３９４ｂ：シリアルATA  
ｃ：パラレルATAｄ：SCSI  
  
エ  ａ：VGAｂ：セントロニクス  
ｃ：USBｄ：IEEE１３９４  
`
        },
        {
                "mondaiId": 106200030,
                "name": "第3問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
オペレーティングシステムの機能に関する次の記述について、最も適切なものの  
組み合わせを下記の解答群から選べ。  
ａメモリ、ファイル、周辺装置の管理を行い、これらをアプリケーションソフト  
で利用できるようにする。  
ｂ有線LANや無線LANの接続状態を監視して、これらが切り替えられた場合  
に適切なファームウェアを呼び出し、LAN接続に関する接続変更のための作業  
を行う。  
ｃマウスやウィンドウ上のアイコンなどを利用した場合の操作を受け付けて、対  
応するプログラムを実行させる。  
ｄワープロや表計算ソフトを起動させる前に、適切なデバイスドライバを選択し  
て起動させる。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 106200040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
表計算ソフトウェアに関する以下の記述について、空欄Ａ～Ｅに入る最も適切な  
用語の組み合わせを下記の解答群から選べ。  
\u0002表計算ではデータや式を入力するセルを番地で表して、この番地を式の中で利用  
することができる。セルに入力するデータはＡとＢに区別され  
る。  
\u0002番地を使用して記述した式においては、式の中に記述した番地の中のデータが書  
き換えられた場合、直ちに式の値が計算し直されるのはＣの仕組みが取  
り入れられて機能しているからである。  
\u0002下表のようにデータが入力されたワークシートの\u0003のセルにおいて、入力された  
左側の\u0002つのデータの合計を求めるために、番地を使用して式を入力した。この  
式を\u0004と\u0005のセルに複写した場合も正しい値が求まるのは、Ｄの仕組み  
が働いているからである。  
\u0002また、下表の\u0003～\u0005の値の合計を\u0006に求めた。この\u0006の値を分母として、\u0003～\u0005  
の各値が占める割合を\u0007～3のセルに求めたい。初めに\u0007に入力した式をと3  
のセルに複写し正しい値を求めようとする場合は、\u0007にＤとＥ  
を併用した式を記述しなければならない。  
表計算のワークシート  
１００１２０１４０\u0003\u0007  
１１０１３０１５０\u0004  
１２０１４０１６０\u00053  
\u0006  
注：\u0003～3はセルの場所を示す。  
それ以外は入力されたデータを示す。  
        
〔解答群〕  
  
ア  Ａ：関数Ｂ：記号Ｃ：オートフィルタ  
Ｄ：相対参照Ｅ：マクロ  
  
イ  Ａ：関数Ｂ：数値Ｃ：マクロ  
Ｄ：ソートＥ：フォーム  
  
ウ  Ａ：文字列Ｂ：関数Ｃ：自動再計算  
Ｄ：絶対参照Ｅ：相対参照  
  
エ  Ａ：文字列Ｂ：数値Ｃ：自動再計算  
Ｄ：相対参照Ｅ：絶対参照  
`
        },
        {
                "mondaiId": 106200050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
パソコンにおけるWebブラウザの利用に関する以下の記述について、空欄Ａ～  
Ｄに入る最も適切なものの組み合わせを下記の解答群から選べ。  
\u0002Webブラウザのアドレス指定欄に  
C:\\web\\mydata\\myhistory.html  
のように入力して、画面にその内容が表示された場合は、Ａ表示してい  
る。  
また、この表記の中の「\\web\\mydata\\」をＢと呼ぶ。  
\u0002Webブラウザのアドレス指定欄に  
http://web.myserver.co.jp/web/mydata/myhistory.html  
のように入力して、画面にその内容が表示された場合は、Ｃ表示してい  
る。  
また、この表記の中の「web.myserver.co.jp」をＤと呼ぶ。  
〔解答群〕  
  
ア  Ａ：Webサーバに格納されていたデータを取得して  
Ｂ：ドメイン名  
Ｃ：操作しているパソコン内の記憶装置に存在するデータを  
Ｄ：パス  
  
イ  Ａ：Webサーバに格納されていたデータを取得して  
Ｂ：ホスト名  
Ｃ：操作しているパソコン内の記憶装置に存在するデータを  
Ｄ：URL  
  
ウ  Ａ：操作しているパソコン内の記憶装置に存在するデータを  
Ｂ：URL  
Ｃ：Webサーバに格納されていたデータを取得して  
Ｄ：プロトコル  
  
エ  Ａ：操作しているパソコン内の記憶装置に存在するデータを  
Ｂ：パス  
Ｃ：Webサーバに格納されていたデータを取得して  
Ｄ：ホスト名  
`
        },
        {
                "mondaiId": 106200060,
                "name": "第6問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
インターネットで用いられるプログラム言語の処理形態に関する以下の記述につ  
いて、最も適切なものの組み合わせを下記の解答群から選べ。  
ａHTMLで記述されたプログラムはWebサーバ上で処理され、その結果がイン  
ターネットを経由してクライアントパソコン上に送り届けられる。  
ｂJavaScriptの記述を含むプログラムは、そのままインターネットを経由してク  
ライアントパソコン上に送り届けられる。  
ｃJavaアプレットで記述されたプログラムは、機械語にコンパイルされてWeb  
サーバ内に格納されている。クライアントパソコンからリクエストがあった場  
合、機械語のプログラムがインターネットを経由してクライアントパソコン上に  
送り届けられる。  
ｄPHPによりデータベースサーバへの検索要求が記述されたプログラムでは、  
検索引数を取得後、Webサーバ内にあるプログラムの実行によりデータベース  
サーバに対して問い合わせが行われ、その結果がインターネットを経由してクラ  
  
イ  アントパソコン上に送り届けられる。  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｄエｃとｄ  
`
        },
        {
                "mondaiId": 106200070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータのアプリケーションソフトやプログラム言語におけるデータファイ  
ルの取り扱いに関する記述として、最も適切なものはどれか。  
  
ア  CSVファイルはデータとデータ間をカンマで区切った固定長ファイルであ  
る。  
  
イ  固定長ファイルにはデータとデータの区切り記号は必要ない。  
  
ウ  ハードディスクに記録した可変長ファイルでは、インデックス（索引）ファイル  
を用いてもデータの検索を高速化することはできない。  
  
エ  ランダムアクセスファイルをハードディスクに記録した場合、常にデータの先  
頭から順番に読み込む必要がある。`
        },
        {
                "mondaiId": 106200080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
LANなどのネットワークでは、さまざまなプロトコルが、それぞれの役割ごと  
に利用されている。以下のａ～ｅの機能と、その機能を利用する場合に使用される  
プロトコルの組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａインターネットにおけるパケット通信  
ｂWeb閲覧  
ｃファイルのダウンロードやアップロード  
ｄメールの送信  
ｅメールの受信  
〔解答群〕  
  
ア  ａ：PPPｂ：FTPｃ：ISPｄ：DNSｅ：SMTP  
  
イ  ａ：TCP/IPｂ：HTTPｃ：FTPｄ：SMTPｅ：POP\u0002  
  
ウ  ａ：TCP/IPｂ：URLｃ：FTPｄ：DNSｅ：WEP  
  
エ  ａ：UDPｂ：URLｃ：OSIｄ：SNTPｅ：WEP  
`
        },
        {
                "mondaiId": 106200090,
                "name": "第9問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
パソコンを利用して各種業務処理を行う場合、複数の処理を同時並行して行った  
り、大容量のファイルを読み込んで処理したりする場合が多くなった。このような  
状況に対応するために、オペレーティングシステムやハードウェアの機能が充実し  
てきている。そのような機能に関する記述として、最も適切なものはどれか。  
  
ア  仮想記憶管理とは、キャッシュメモリに主記憶の役割を仮想的に持たせること  
をいう。  
  
イ  スプール処理とは、低速の入出力装置などを使う場合に、他の処理を行えるよ  
うにCPUを早く解放するため、補助記憶装置などにデータを一時的に書き込  
み、後の空き時間に処理する方式をいう。  
  
ウ  スワッピングとは、デュアルコアCPUなど複数のCPUを用いて処理を交互に  
行う方式をいう。  
  
エ  多重プログラミングとは、複数のプログラムをそのプログラムの数のCPUに  
よって同時に処理させる方式をいう。  
`
        },
        {
                "mondaiId": 106200101,
                "name": "第10問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
勤務実態を把握するため、部署ごとに\u0002月の平均残業時間を調査することになっ  
た。そこで、以下のようなSQL文でデータベースに問い合わせをすることにし  
た。データテーブルは、以下の\u0003つである。  
テーブル名：\u0002月の残業時間  
従業員ID氏名所属ID残業時間  
A０１００２鈴木英子１０１４  
A０１０７９佐藤浩司１０２１０  
A０２０１４高木有一１０１４  
A０２０４３田中真一１０３３  
A０４０８１赤城和郎１０２１１  
A０５０４７本田まり１０３２  
A０５０５１藤田二郎１０２１２  
テーブル名：部署  
部署ID部署名  
１０１個人営業部  
１０２法人営業部  
１０３経理部  
SQL文  
SELECT\u0002，\u0003，AVG（残業時間）FROM\u0004，\u0005  
WHERE\u0004．所属ID=\u0005．部署ID  
GROUPBY\u0006，\u0007  
        
（設問1））  
SQL文の空欄\u0002～\u0003に入る最も適切なものの組み合わせはどれか。  
  
ア  \u0002：\u0003月の残業時間\u0004：所属ID\u0005：部署ID\u0003：部署名  
  
イ  \u0002：\u0003月の残業時間\u0004：部署ID\u0005：氏名\u0003：所属ID  
  
ウ  \u0002：所属ID\u0004：部署ID\u0005：部署名\u0003：従業員ID  
  
エ  \u0002：部署ID\u0004：部署名\u0005：\u0003月の残業時間\u0003：部署`
        },
        {
                "mondaiId": 106200102,
                "name": "第10問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
SQL文の空欄\u0006、\u0007に入る最も適切なものの組み合わせはどれか。  
  
ア  \u0006：\u0003月の残業時間\u0007：残業時間  
  
イ  \u0006：従業員ID\u0007：\u0003月の残業時間  
  
ウ  \u0006：所属ID\u0007：部署ID  
  
エ  \u0006：部署ID\u0007：部署名  
`
        },
        {
                "mondaiId": 106200110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
ある企業A社では、インターネットによって社員間の情報共有を推進するため  
に、自宅パソコンからのアクセス、携帯電話からのアクセス、カフェなどでの無線  
LANによるアクセスなど、多様なネットワーク接続で自社システムを利用できる  
環境を実現している。これに関する記述として、最も適切なものはどれか。  
  
ア  A社のホームページにHTTPSでアクセスして、ID、パスワードを入力して接  
続し、データの送受信を行っているが、これだけではデータは暗号化されていな  
いので、機密情報の共有には問題がある。  
  
イ  VPN技術を用いると、インターネットもあたかも専用線のようなイメージと  
なりセキュリティも確保される。  
  
ウ  携帯電話のインターネット接続サービスを用いてA社ホームページにアクセ  
スする場合、専用のブラウザを携帯端末にあらかじめインストールする必要があ  
る。  
  
エ  自宅パソコンからA社のシステムのメールサーバにアクセスして、メールの  
送受信を行うためには、自宅パソコンのIPアドレスとMACアドレスが必要に  
なる。`
        },
        {
                "mondaiId": 106200120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
顧客へのWebサーバによる各種サービスを中断しないようにするためには、  
サーバコンピュータの信頼性を向上させることが課題である。一般の廉価なパソコ  
ンをサーバとして用いる中小企業が多いが、その信頼性が問題となる。万が一に備  
えて、メインのサーバと同機能のサブのサーバを並列化して利用することを検討し  
ている。メインのサーバの信頼性が９０％、サブとするサーバの信頼性が７０％と  
するならば、並列化したシステムの信頼性として、最も適切なものはどれか。  
  
ア  ６３％イ８０％ウ９７％エ９９．５％  
`
        },
        {
                "mondaiId": 106200130,
                "name": "第13問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
情報システムの設計に関する記述として、最も適切なものはどれか。  
  
ア  DFDは、データの流れ、その時間的情報を記述する手法であり、要件定義の  
際に用いられる。  
  
イ  ER図は、データベース設計を行う際に利用される記憶領域の配置を検討する  
ために用いられる。  
  
ウ  STD（状態遷移図）は、外部設計や内部設計において、画面設計などに用いられ  
る。  
  
エ  UMLは、ウォータフォール型システム開発において統一的に利用できるシス  
テム記述言語である。`
        },
        {
                "mondaiId": 106200140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
ある事業所で、受発注処理や商品管理に関わる業務の基幹となるシステムが古く  
なってきたので、事業所の業務をよく知るソフトウェアハウスが主体となって、  
  
ウ  ォータフォール型システム開発方法論によって新規のシステムを開発することに  
した。システムの移行を円滑に行うためにはシステムテストが必要になる。これに  
関する記述として、最も適切なものはどれか。  
  
ア  事業所側も、システムを構成するモジュールテストをソフトウェアハウスで行  
う段階から原則として参画しなければならない。  
  
イ  想定される最大業務負荷に耐えられるかどうかを確認するシステムテストを、  
事業所側でも行う方がよい。  
  
ウ  ソフトウェアハウスで行うテストとして、とにかく最初にシステム全体を完成  
させて、システムテストを行う方がよい。  
  
エ  ブラックボックステストをソフトウェアハウスが行う場合、事業所側は今まで  
例外処理されていたデータだけをテストデータとして提供する。  
`
        },
        {
                "mondaiId": 106200150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
ある企業B社では、役員の確定申告に際して電子申告を奨励することになっ  
た。電子申告を始めるためには、最初に開始手続を行うが、その手続きの\u0002つとし  
て認証機関から電子証明書を発行してもらう必要がある。この電子証明書について  
の説明として、最も適切なものはどれか。  
  
ア  公開鍵で暗号化された文書を復号する際には、通常は私有鍵が使われるが、電  
子証明書がついている公開鍵であれば、その公開鍵で復号することができるとい  
う利点を持っている。  
  
イ  公的個人認証用の電子証明書を発行するのは信頼のある公的機関であることが  
必要であり、具体的にはGPKI（政府認証基盤）がその役割を担っている。  
  
ウ  電子証明書は、データの作成や送付をしたのが本人であることを証明するため  
に、送付するたびに認証局より自動的に発行される。  
  
エ  電子署名などに使う公開鍵が本人のものであることを証明するのが、電子証明  
書の役割である。  
`
        },
        {
                "mondaiId": 106200160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
情報システムは、日常的経営活動だけでなく、競争戦略的にも役割期待が高まっ  
ている。しかしその一方で、情報システムへの不正アクセスや情報漏洩（ろうえい）  
などにより、ともすると企業の存続さえ脅かす問題を発生させる危険性もはらんで  
いる。そのため、ITガバナンスの重要性が叫ばれ、ITに対する強力な内部統制プ  
ログラム構築への意識が高まっている。このようなプログラムの特徴として、最も  
不適切なものはどれか。  
  
ア  効率性（efficiency）は失われるが、有効性（effectiveness）の高いオペレーショ  
ンが実現できる。  
  
イ  知的資産が流出するのを防ぎ、企業における競争力を一層維持することが可能  
になる。  
  
ウ  適時に質の高い情報を用いることが可能になるため、一層合理的かつ有効な  
（effective）意思決定を実現できる。  
  
エ  プライバシー保護などの他の問題についての法令遵守にも役立つ。  
`
        },
        {
                "mondaiId": 106200170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
インターネットによるサービス提供の主要技術となったWebサービスは、\u0002つ  
の主要技術から構成されている。以下のａ～ｄの説明と、その説明に適する用語の  
組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａプログラムからWebサービスを呼び出すためのインタフェース情報  
ｂWebサービスを登録・検索するための仕組み  
ｃWebサービスの文書をHTTPで交換する通信規約  
ｄコンピュータが文書のデータ構造と内容を解析できるようにした表現形式  
〔解答群〕  
  
ア  ａ：SOAPｂ：UDDIｃ：WSDLｄ：XML  
  
イ  ａ：UDDIｂ：WSDLｃ：XMLｄ：SOAP  
  
ウ  ａ：WSDLｂ：UDDIｃ：SOAPｄ：XML  
  
エ  ａ：XMLｂ：SOAPｃ：UDDIｄ：WSDL`
        },
        {
                "mondaiId": 106200180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
近年注目されているアーキテクチャであるSOA（ServiceOrientedArchitecture）  
の説明として、最も適切なものはどれか。  
  
ア  開発効率を重視して、XMLベースの部品を柔軟に組み合わせて構築するアー  
キテクチャである。  
  
イ  既存のハードウェアとソフトウェアを付加価値ベースで組み合わせて、顧客の  
個別ニーズに適合するシステムとして再販する事業アーキテクチャである。  
  
ウ  サービス業界における業務体系のシステム開発アーキテクチャである。  
  
エ  実装技術を汎用性の高いJavaに限定・統一した開発アーキテクチャである。  
`
        },
        {
                "mondaiId": 106200190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
システム開発における設計フェーズには、次のような作業が含まれる。これらの  
作業順序として、最も適切なものを下記の解答群から選べ。  
ａ各設計フェーズに基づいて、開発工程別に作業単位を積み上げた工数の算出  
ｂ開発所要期間の見積結果と本番運用開始時期との整合性確認  
ｃ要員単価表による開発コストの算出  
ｄファンクションポイント法などの生産性指標や類推法に基づいた工数の算定  
〔解答群〕  
  
ア  ａ→ｂ→ｃ→ｄ  
  
イ  ａ→ｄ→ｃ→ｂ  
  
ウ  ｄ→ａ→ｂ→ｃ  
  
エ  ｄ→ｃ→ｂ→ａ`
        },
        {
                "mondaiId": 106200200,
                "name": "第20問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
当社は、アセスメントモデルによる評価を踏まえて情報システムの評価を行おう  
としている。経済産業省の「アセスメントモデル活用ガイド」（平成１８年１２月）で  
は、組織やプロジェクトのプロセス改善活動のアセスメントについての指針を提示  
している。この指針について、最も適切なものはどれか。  
  
ア  アセスメント結果を第三者に公開するための自己アセスメントは、独立アセス  
メントでなければならない。  
  
イ  アセスメント参加者は、アセスメントを受診する際に、問診（インタビュー）を  
受けるが、プロセスの作業生産物を見せる必要はない。  
  
ウ  プロセス能力評定にはABCという\u0002段階順序尺度を使う。  
  
エ  プロセス能力評定にはSWOR（Strength，Weakness，Opportunity，Risk）分析を  
用いる。  
`
        },
        {
                "mondaiId": 106200210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
システム開発プロジェクト管理の方法として、EVM（EarnedValue  
Management）が使われる。これについて、最も適切なものはどれか。  
  
ア  RADのような短期間の開発プロジェクト管理に向く方法である。  
  
イ  作業単位をコストに変換するので、この変換精度が悪いと進ちょく度の把握に  
狂いが生じる。  
  
ウ  全工程の１０％程度が完了した段階でプロジェクト完了の時期とコストをかな  
りの精度で予測できる。  
  
エ  プロジェクト全体を見渡しやすい小規模なプロジェクトでの適用に向いている  
が、大規模なプロジェクトには向かない。`
        },
        {
                "mondaiId": 106200220,
                "name": "第22問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
「ITセキュリティ評価及び認証制度」において「認証機関が公開する評価基準」の  
規格として公開しているCommonCriteriaの説明として、最も適切なものはどれ  
か。  
  
ア  運用時の脆弱性や誤使用に対する抵抗力も評価される。  
  
イ  企業などで業務処理に利用する個別情報システムのセキュリティ能力を評価す  
る。  
  
ウ  基準を適用する際に使用すべき評価方法についても規定している。  
  
エ  認定における評価結果��用いるための手続きについても規定している。  
`
        },
        {
                "mondaiId": 106200230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
立地の異なる当社の\u0002店舗で、それぞれ\u0003カ月間の日次売上高データを集めた。  
平均日次売上高に差がないという帰無仮説を統計的に検定したい。それぞれの母集  
団の標準偏差は分からない。しかし、データを調べたら両標本の分散が異なってい  
た。分析方法として、最も適切なものはどれか。  
  
ア  ウェルチ（Welch）検定を行う。  
  
イ  カイ二乗（χ２）検定を行う。  
  
ウ  二元配置分散分析を行う。  
  
エ  二標本による平均のZ検定を行う。`
        },
        {
                "mondaiId": 106200240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2008,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
当社の\u0003カ月間の日次売上高データを集めた。このデータの統計的分析方法を検  
討するために、まず正規確率プロット図を作成する。この場合、最も適切なものは  
どれか。  
  
ア  S字形になったので、売上高データは正規分布している。  
  
イ  正規確率プロット図は、正規分布をするデータでなければ作成できない。  
  
ウ  データの順番に対応する標準化値を求める。  
  
エ  山形の正規確率プロット図になったので、売上高データは正規分布している。  
`
        },
        {
                "mondaiId": 107200011,
                "name": "第1問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
新規開業によって期待される効果のひとつとして、雇用の創出が挙げられる。総  
務省「事業所・企業統計調査」に基づき、１９９４年時点と２００４年時点の全事業所の雇  
用変動量を比較した中小企業庁の推計によると、１９９４年に存在せず２００４年時点に  
存在した事業所（開業事業所）による雇用創出は２，１１５万人と、両時点に存在が確認  
される存続事業所における雇用創出の５４１万人を大きく上回る。加えて、雇用変動  
を雇用形態別に見ると、開業事業所で生み出される雇用は、による雇用  
が最も多いことも注目される。なお、業種別に雇用変動状況を見ると、両時点で雇  
用を増加させている業種がある一方で、雇用を大きく減少させている業種もあり、  
業種によって雇用変動に違いが見られることも指摘される。  
（設問1））  
文中の空欄に入る最も適切な語句はどれか。  
  
ア  個人業主・家族従業者  
  
イ  正社員  
  
ウ  パート・アルバイト  
  
エ  臨時雇用者`
        },
        {
                "mondaiId": 107200012,
                "name": "第1問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部について、総務省「事業所・企業統計調査」に基づく中小企業庁の  
推計から、１９９４年時点と２００４年時点の雇用変動を業種別に比較した場合に、最  
も適切なものはどれか。  
  
ア  医療福祉・教育学習支援業においては雇用が増加している。  
  
イ  金融保険業においては雇用が増加している。  
  
ウ  建設業においては雇用が増加している。  
  
エ  製造業においては雇用が増加している。  
`
        },
        {
                "mondaiId": 107200021,
                "name": "第2問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
日本においては、少子高齢化の進展により、今後労働力人口の大幅な減少が避け  
られない。このような中で、日本経済が持続的発展を遂げるためには、労働生産  
性、すなわち労働投入量（労働時間または労働者数）当たりのＡの向上が不  
可欠と考えられる。  
とりわけ、中小企業の労働生産性の水準は低い。経済産業省「企業活動基本調  
査」、厚生労働省「毎月勤労統計調査」に基づく中小企業庁の推計結果（２００５年度）を  
見ても、中小企業の労働生産性の水準は、製造業、情報通信業、卸売業、小売業、  
飲食店・宿泊業のすべての業種において、大企業を下回っている。このような大企  
業と中小企業の労働生産性の格差は、中小企業における労働投入量当たりの資本ス  
トックとして算出されるＢの水準が低いことによるところが大きいと考え  
られる。（設問1））  
文中の空欄Ａに入る最も適切な語句はどれか。  
  
ア  減価償却費  
  
イ  人件費  
  
ウ  生産費用  
  
エ  付加価値額  
`
        },
        {
                "mondaiId": 107200022,
                "name": "第2問設問2",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部について、次の選択肢の中で大企業と中小企業の労働生産性の格  
差が最も大きいものはどれか。  
  
ア  飲食店・宿泊業  
  
イ  卸売業  
  
ウ  小売業  
  
エ  情報通信業  
  
オ  製造業`
        },
        {
                "mondaiId": 107200023,
                "name": "第2問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｂに入る最も適切な語句はどれか。  
  
ア  機械稼働率  
  
イ  固定資産比率  
  
ウ  自己資本比率  
  
エ  資本装備率  
  
オ  労働分配率  
`
        },
        {
                "mondaiId": 107200030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄ＡとＢに入る最も適切な数値の組み合わせを下記の解答群から選  
べ。  
中小企業、とりわけ小規模企業を取り巻く経営環境は依然として厳しく、中小企  
業庁「中小企業実態基本調査」（２００６年）をもとに、従業者規模別の債務超過企業の  
割合を見ると、従業者数１０１～３００人の企業ではＡ％であるのに対し、従  
業者数\u0002～\u0003人の企業ではＢ％となっている。  
〔解答群〕  
  
ア  Ａ：１．３Ｂ：２２．５  
  
イ  Ａ：３．８Ｂ：５０．１  
  
ウ  Ａ：４．５Ｂ：３６．６  
  
エ  Ａ：７．３Ｂ：１０．２  
`
        },
        {
                "mondaiId": 107200041,
                "name": "第4問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
経済産業省「工業統計表」（２００４年）によれば、日本の製造業の出荷額に占める中  
小企業のシェアはＡ％に及んでいる。業種（産業）別に見ると、Ｂ  
産業やＣ産業におけるシェアが比較的高い。川下のＤ産業におけ  
る最終製品は大企業がもっぱら生産しているが、その生産過程では、川上・川中の  
Ｃ産業に属する多くの中小企業が関与しているものと考えられる。  
なお、ここでは業種分類を次のとおりとしている。  
\u0002素材・部品型：繊維、木材・木製品、パルプ・紙・紙加工品、化学、石油製品・  
石炭製品、プラスチック製品、ゴム製品、なめし革・同製品・毛  
皮、窯業・土石製品、鉄鋼、非鉄金属、金属製品  
\u0002加工・組立型：一般機械、電気機械、輸送用機械、精密機械  
\u0002生活関連型：食料品、飲料・たばこ・飼料、衣服・その他の繊維製品、家具・  
装備品、印刷・同関連業、その他の製造業  
（設問1））  
文中の空欄Ａに入る最も適切な数値はどれか。  
  
ア  １８．８イ３６．２ウ５０．５エ７１．８`
        },
        {
                "mondaiId": 107200042,
                "name": "第4問設問2",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｂ～Ｄに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ｂ：加工・組立型Ｃ：素材・部品型Ｄ：生活関連型  
  
イ  Ｂ：生活関連型Ｃ：加工・組立型Ｄ：素材・部品型  
  
ウ  Ｂ：生活関連型Ｃ：素材・部品型Ｄ：加工・組立型  
  
エ  Ｂ：素材・部品型Ｃ：生活関連型Ｄ：加工・組立型  
`
        },
        {
                "mondaiId": 107200051,
                "name": "第5問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
近年、製造業を取り巻く環境変化のひとつとして、グローバル競争の加速化、市  
場ニーズの多様化、急激な技術革新の進展などを背景に、製品の市場投入から成  
長、成熟、衰退までのライフサイクルが短縮化していることが挙げられる。上場し  
ている製造業を対象とした経済産業省の調べ（２００７年２月）をもとに、業種別に主  
力製品の現在のライフサイクル年数を５年前と比較すると、特に産業に  
おける短縮化が著しく、\u0002年前の約\u0003割の水準となっている。こうした製品のライ  
フサイクルの短縮化は、サポーティングインダストリーの中核を担う中小製造業の  
経営にも大きな影響を及ぼしているものと考えられる。  
（設問\u0004）  
文中の空欄に入る最も適切な語句はどれか。  
  
ア  化学  
  
イ  家電  
  
ウ  自動車  
  
エ  鉄鋼`
        },
        {
                "mondaiId": 107200052,
                "name": "第5問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
文中の下線部について、想定される中小製造業への影響として、最も不適切な  
ものはどれか。  
  
ア  価格低下のスピードが速まる。  
  
イ  技術革新への対応が求められるようになる。  
  
ウ  生産変動が大きくなる。  
  
エ  知的財産保護への対応が求められるようになる。  
  
オ  納期が短縮化する。  
`
        },
        {
                "mondaiId": 107200061,
                "name": "第6問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
経済産業省「商業統計表」を基に、１９９１年から２００４年までの小売業の業態別販売  
額の推移を見ると、スーパーのシェアが上昇する一方で、のシェアは  
１９９１年の４７．２％から２００４年には３７．５％まで落ち込んでいる。また、店舗規模  
別の総面積と販売額の推移を見ると、１９９７年以降、売場面積５００平方メートル以  
上の規模では、総面積、販売額とも増加する一方、売場面積５００平方メートル未満  
の規模では総面積、販売額とも減少している。  
（設問1））  
文中の空欄に入る最も適切な語句はどれか。  
  
ア  コンビニエンス・ストア  
  
イ  専門店（同一取扱商品の占有率９０％以上）  
  
ウ  中心店（同一取扱商品の占有率５０％以上）  
  
エ  ドラッグストア`
        },
        {
                "mondaiId": 107200062,
                "name": "第6問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の下線部について、２００４年時点の売場面積別小売業販売額のうち、「売場  
面積５００平方メートル未満」の小売業販売額として最も適切な数値はどれか。  
  
ア  \u0004兆円イ２３兆円ウ５３兆円エ１３３兆円  
`
        },
        {
                "mondaiId": 107200070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、流通構造の変化の中で、中小卸売業を取り巻く事業環境は厳しさを増して  
いるが、経済産業省「商業統計表」に基づいて、１９９１年と２００４年の卸売業の事業所  
数、従業者数、年間販売額を比較した場合に、<u>最も不適切なものはどれか</u>。  
  
ア  事業所数が約１０万店減少している。  
  
イ  従業者数が約\u0002割減少している。  
  
ウ  年間販売額が約１６８兆円減少している。  
  
エ  両時点ともに従業者規模\u0003～９９人の事業所が事業所数の約\u0004割を占めてい  
る。`
        },
        {
                "mondaiId": 107200081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
大企業と中小企業の資金調達構成は大きく異なり、一般的には中小企業の借入金  
への依存度が高いことが知られている。財務省「法人企業統計年報」によると、２００５  
年度において、短期・長期金融機関借入金の総資産に対する比率である金融機関借  
入金比率は、大企業が１８．０％であるのに対し、中小企業は３２．１％である。なお、  
ここでは資本金\u0003億円以上の企業を大企業、資本金\u0003億円未満の企業を中小企業と  
している。  
（設問２））  
文中の下線部について、中小企業の資金調達構成で借入金依存度が高い理由と  
して最も適切なものはどれか。  
  
ア  金融機関の貸出姿勢が安定しており、かつ常時積極的であるため。  
  
イ  資金需要が乏しく、資本市場からの調達の必要性がないため。  
  
ウ  資本市場からの資金調達が困難であり、借入れに依存せざるを得ないため。  
  
エ  大企業と比較して、有利な条件で借入れを行うことができるため。  
`
        },
        {
                "mondaiId": 107200082,
                "name": "第8問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
財務省「法人企業統計年報」に基づいて、１９９０年代後半以降の中小企業の金融  
機関借入比率の推移を見た場合、最も適切なものはどれか。  
  
ア  上昇している。  
  
イ  低下した後、２０００年代以降顕著に上昇している。  
  
ウ  低下している。  
  
エ  横ばいで推移している。`
        },
        {
                "mondaiId": 107200090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
中小企業の資金繰りにとって、企業間信用（手形、掛取引）は大きな役割を果たし  
ている。例えば、財務省「法人企業統計年報」によれば、２００５年における中小企業  
（資本金\u0003億円未満の製造業）の総資産に対する企業間信用割合（  
支払手形＋買掛金  
総資産  
×  
１００）は１６．２％である。これは、同じく総資産に対する短期借入金比率（  
短期借入金  
総資産  
×１００）と比べて、Ａ。  
また、企業間信用割合の内訳別推移や手形交換の推移を見ると、このところ支払  
手形での代金決済がＢ傾向にあることがうかがえる。こうした手形取引の  
Ｂは、中小企業の資金繰りにも少なからず影響を及ぼすものと考えられ  
る。  
〔解答群〕  
  
ア  Ａ：高いＢ：減少  
  
イ  Ａ：高いＢ：増加  
  
ウ  Ａ：低いＢ：減少  
  
エ  Ａ：低いＢ：増加  
`
        },
        {
                "mondaiId": 107200100,
                "name": "第10問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
グローバル化の進展は、日本の企業間取引関係にも大きな影響を与えている。  
国際生産分業体制の構築等によって、多くの企業や産業分野において、いわゆる  
系列関係に代表される少数の特定取引先に依存した取引関係の見直しが進み、多数  
の取引先との多面的な取引関係への移行、すなわち取引構造のメッシュ化の進展が  
見受けられる。企業間取引において、取引先の増加や大口取引先への依存度低下が  
進むことにより、企業が期待できるメリットとして、<u>最も不適切なものはどれか</u>。  
  
ア  価格や納期等において有利な条件を提示する取引先を選択できる。  
  
イ  技術流出のリスクが低減する。  
  
ウ  受発注量の変動に対するリスク分散を図ることができる。  
  
エ  多様な情報が入手可能である。`
        },
        {
                "mondaiId": 107200110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
次の文章の空欄Ａ～Ｃに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
東京商工リサーチ「全国企業倒産白書」によれば、２００６年の企業倒産件数は  
１３，２４５件で、うち資本金\u0002億円未満の企業が１３，０１１件である。業種別に倒産件  
数・負債金額の内訳を見ると、倒産件数ではＡが最も多く、負債金額では  
Ｂが最も多くなっている。要因別倒産件数構成比を見ると、Ｃを  
要因とする倒産の割合が最も多くなっている。  
〔解答群〕  
  
ア  Ａ：建設業Ｂ：サービス業Ｃ：販売不振  
  
イ  Ａ：建設業Ｂ：不動産業Ｃ：放漫経営  
  
ウ  Ａ：サービス業Ｂ：建設業Ｃ：販売不振  
  
エ  Ａ：サービス業Ｂ：商業Ｃ：放漫経営  
  
オ  Ａ：不動産業Ｂ：サービス業Ｃ：販売不振  
`
        },
        {
                "mondaiId": 107200120,
                "name": "第12問",
                "seikai": "オ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
次の文章の空欄に入る最も適切な語句を下記の解答群から選べ。  
日本の製造業は、し烈化するグローバル競争に対応するため、積極的に海外での  
事業展開を進めている。この結果、経済産業省「海外事業活動基本調査」によると、  
製造業の海外生産比率（国内全法人ベース）（注）は、１９９１年度の７．９％から２００５年  
度には１６．７％に達し、過去最高の水準となっている。２００５年度の海外生産比率  
（国内全法人ベース）を業種別に見ると、が３７．０％と最も高く、次に多  
いのが情報通信機械の３４．９％となっている。  
（注）海外生産比率＝  
海外現地法人売上高  
海外現地法人売上高＋国内法人売上高  
×１００  
〔解答群〕  
  
ア  一般機械  
  
イ  精密機械  
  
ウ  鉄鋼  
  
エ  非鉄金属  
  
オ  輸送機械  
`
        },
        {
                "mondaiId": 107200131,
                "name": "第13問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
次の文章を読んで、下記の設問に答えよ。  
大企業との比較において、相対的に保有する経営資源が限られる中小企業が、自  
社単独で製品開発・技術開発を行うことや、新たな販路開拓を行うことは容易では  
ない。また、市場ニーズが目まぐるしく変化する中で、必要とするすべての経営資  
源を自社内に抱え込むことは、中小企業が本来的な強みとする環境変化に対する柔  
軟な対応力を弱めかねない。  
このため、現代の中小企業経営にとっては、外部の経営資源をいかに有効活用す  
るかが重要な戦略的課題となっている。こうした中で近年注目されているのが、産  
学官連携の促進に加えて、複数の中小企業が各自保有する経営資源を相互提供する  
ことで、不足する経営資源の補完や融合を図り、新たな事業活動や価値を創造する  
取り組みである。こうした中小企業の取り組みは、Ａのような関係にみら  
れる垂直的で硬い連携関係ではなく、既存の取引関係の有無を問わない緩やかで  
Ｂな水平的な連携関係であることが多い。  
（設問1））  
文中の空欄Ａに入る最も適切な語句はどれか。  
  
ア  異業種交流イ系列取引  
  
ウ  産学連携エ地域コンソーシアム`
        },
        {
                "mondaiId": 107200132,
                "name": "第13問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｂに入る最も適切な語句はどれか。  
  
ア  固定的イ集権的ウ対等エ長期的`
        },
        {
                "mondaiId": 107200133,
                "name": "第13問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の下線部について、このような取り組みを維持・発展させるために必要な  
条件として、<u>最も不適切なものはどれか</u>。  
  
ア  公正な成果配分や費用負担  
  
イ  参加企業が同業種であること  
  
ウ  参加する企業間の信頼関係の構築  
  
エ  全体を取りまとめるコーディネーターの存在  
`
        },
        {
                "mondaiId": 107200140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
中小企業基本法における中小企業の定義に関する次の記述の正誤について、最も  
適切なものの組み合わせを下記の解答群から選べ。  
ａ従業員数８０名のサービス業（資本金\u0002億円）は、中小企業と定義される。  
ｂ中小企業基本法の中小企業の定義においては、資本金基準と従業員基準の両方  
の基準を満たす必要がある。  
ｃ中小企業の定義において、会社役員は従業員に含まれない。  
〔解答群〕  
  
ア  ａ：正ｂ：正ｃ：誤  
  
イ  ａ：正ｂ：誤ｃ：正  
  
ウ  ａ：誤ｂ：正ｃ：誤  
  
エ  ａ：誤ｂ：誤ｃ：正`
        },
        {
                "mondaiId": 107200150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
中小企業基本法において、小規模企業の定義に該当する企業として最も適切なも  
のはどれか。  
  
ア  従業員規模が１０人で、資本金が１００万円の小売業  
  
イ  従業員規模が１０人の個人経営の飲食店  
  
ウ  従業員規模が２０人で、資本金が\u0002億円の製造業  
  
エ  従業員規模が３０人で、資本金が１，０００万円の製造業  
`
        },
        {
                "mondaiId": 107200160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
中小企業診断士A氏は、創業を考えているB氏から、事業開始時に必要となる  
設備資金の借り入れに関する相談を受けた。B氏は、現在の勤務先での２０年の勤  
務経験を生かし、従業員を\u0002名程度雇用して、創業予定とのことである。  
そこで、A氏は、国民生活金融公庫で取り扱っている「新創業融資制度」をB氏  
に紹介することとした。この融資制度に関するA氏の説明として、最も不適切な  
ものはどれか。  
  
ア  開業資金総額の\u0003分の\u0002以上の自己資金が確認できることが必要です。  
  
イ  貸付限度額は１，０００万円です。  
  
ウ  設備資金の貸付期間は、\u0004年以内です。  
  
エ  無担保・無保証人で借り入れができます。法人代表者の保証も不要です。`
        },
        {
                "mondaiId": 107200170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
「JAPANブランド育成支援事業」は、ブランド確立に向けて地域一丸となって取  
り組むプロジェクトを総合的に支援する事業である。ブランド育成の支援は、戦略  
策定段階、ブランド確立段階の\u0003段階にわたって行われる。この事業に関する記述  
として最も適切なものはどれか。  
  
ア  経済産業局において、年\u0002回プロジェクトの公募を行っている。  
  
イ  ブランド確立に関する支援期間は、最大\u0005か年である。  
  
ウ  プロジェクトに対し、独立行政法人中小企業基盤整備機構を通じて支援する。  
  
エ  プロジェクトの戦略策定支援は、\u0002件あたり１，０００万円程度で、\u0005分の\u0003補助  
である。  
`
        },
        {
                "mondaiId": 107200180,
                "name": "第18問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
「セーフティネット保証制度」は、経済環境の急激な変化に直面し、経営の安定に  
支障を生じている中小企業者に対する保証制度である。この制度に関する記述とし  
て最も適切なものはどれか。  
  
ア  保証金額を問わず、無担保無保証人の保証制度である。  
  
イ  保証限度額の別枠化を図る制度である。  
  
ウ  保証料は無料である。  
  
エ  利用にあたっては、経済産業大臣の認定が必要となる。`
        },
        {
                "mondaiId": 107200191,
                "name": "第19問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
次の文章を読んで、下記の設問に答えよ。  
「中小企業地域資源活用促進法」は、地域経済が自立的・持続的な成長を実現して  
いくために、各地域の強みである「農林水産物」、「Ａ」、「観光資源」からな  
る地域資源を活用して新商品の開発等の事業を行う中小企業を支援する法律であ  
る。この法律では、Ｂが地域資源の指定等を内容とする基本構想を策定す  
る。  
（設問1））  
文中の空欄Ａに入る最も適切な語句はどれか。  
  
ア  工業製品イ鉱業品及び工業品  
  
ウ  鉱工業品及びその生産技術エ伝統工芸品`
        },
        {
                "mondaiId": 107200192,
                "name": "第19問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｂに入る最も適切な語句はどれか。  
  
ア  国イ市町村  
  
ウ  商工会議所・商工会エ都道府県  
`
        },
        {
                "mondaiId": 107200201,
                "name": "第20問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
次の文章を読んで、下記の設問に答えよ。  
中小企業者等は税制上のさまざまな特別措置を受けることができる。たとえば、  
個人事業者の所得税における措置として、Ａの家族専従者については、家  
族専従者に対する支払給与の金額が、その労務の対価として相当であると認められ  
るときは、Ｂが必要経費に算入できる。  
法人事業者のための措置としては、中小法人（資本金\u0002億円以下の法人）について  
は、法人税について軽減税率（年所得Ｃ万円までＤ％）が適用され  
ている。  
（設問1））  
文中の空欄ＡとＢに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ａ：青色申告者Ｂ：その９０％の金額  
  
イ  Ａ：青色申告者Ｂ：その全額  
  
ウ  Ａ：青色申告者および白色申告者Ｂ：その９０％の金額  
  
エ  Ａ：青色申告者および白色申告者Ｂ：その全額`
        },
        {
                "mondaiId": 107200202,
                "name": "第20問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄ＣとＤに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ｃ：６００Ｄ：１５  
  
イ  Ｃ：６００Ｄ：２２  
  
ウ  Ｃ：８００Ｄ：１５  
  
エ  Ｃ：８００Ｄ：２２  
`
        },
        {
                "mondaiId": 107200211,
                "name": "第21問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
次の文章を読んで、下記の設問に答えよ。  
中小企業新事業活動促進法は、\u0002創業、\u0003Ａ、\u0004Ｂの取り組み  
を支援するものである。なお、この法律における創業の対象は、これから事業を開  
始しようとする個人や創業Ｃ年以内の事業者などである。  
（設問1））  
文中の空欄ＡとＢに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ａ：経営革新Ｂ：新製品開発  
  
イ  Ａ：経営革新Ｂ：新連携  
  
ウ  Ａ：経営基盤強化Ｂ：新製品開発  
  
エ  Ａ：経営基盤強化Ｂ：新連携`
        },
        {
                "mondaiId": 107200212,
                "name": "第21問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄Ｃに入る数値として最も適切なものはどれか。  
  
ア  \u0002イ\u0003ウ\u0004エ\u0005  
`
        },
        {
                "mondaiId": 107200221,
                "name": "第22問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
下請代金支払遅延等防止法に関して、以下の設問に答えよ。  
（設問1））  
この法律の対象となる取引として最も適切なものはどれか。  
  
ア  自動車ディーラー（資本金５００万円）が、請け負った自動車の修理作業を修理  
会社（個人経営）に委託する。  
  
イ  自動車メーカー（資本金\u0003億円）が、自動車の部品の製造を部品メーカー（資  
本金\u0004億円）に委託する。  
  
ウ  電機メーカー（資本金\u0002億円）が、販売した製品の修理用部品の製造を部品  
メーカー（資本金５，０００万円）に委託する。  
  
エ  電機メーカー（資本金\u0004億円）が、電気製品の部品製造に必要な金型の製造を  
金型メーカー（資本金\u0002億円）に委託する。`
        },
        {
                "mondaiId": 107200222,
                "name": "第22問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
次の文中の空欄に入る数値として最も適切なものを下記の解答群から選べ。  
支払期日までに下請代金を支払わなかったときは、下請事業者から物品等を受  
領した日から起算して６０日を経過した日から支払いをするまでの期間につい  
て、その日数に応じ、未払金額に年利パーセントを乗じた額を遅延利  
息として支払わなければならない。  
〔解答群〕  
  
ア  ８．６イ１０．６ウ１２．６エ１４．６  
`
        },
        {
                "mondaiId": 107200230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
中小企業者のS氏は、物流を効率化するため、専門家のアドバイスを求めてい  
る。S氏から相談を受けた中小企業診断士のT氏は、S氏に「物流効率化専門指導  
員派遣事業」を紹介することにした。  
以下は、S氏とT氏の会話である。  
会話の中の空欄ＡとＢに入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
T氏：「物流効率化専門指導員派遣事業を利用すれば、物流効率化アドバイザーか  
ら、物流の効率化を図る上での諸課題に関するアドバイスを受けることがで  
きます。」  
S氏：「費用負担は、どのようになるのですか。」  
T氏：「アドバイザーへの謝金のＡ分の\u0002について自己負担となります。」  
S氏：「申し込みをするには、どうすればよいのですか。」  
T氏：「Ｂに申し込みをしてください。Ｂからアドバイザーが派  
遣されます。」  
〔解答群〕  
  
ア  Ａ：\u0003Ｂ：中小企業支援センター  
  
イ  Ａ：\u0003Ｂ：独立行政法人中小企業基盤整備機構  
  
ウ  Ａ：\u0004Ｂ：中小企業支援センター  
  
エ  Ａ：\u0004Ｂ：独立行政法人中小企業基盤整備機構  
`
        },
        {
                "mondaiId": 107200240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
金型メーカーのA社は、高性能自動車部品を開発・製造するために、金型メー  
カーB社、成形加工メーカーC社、D社と連携し、有限責任事業組合（LLP）を設  
立した。このLLPに関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  開発投資による損失を、組合員の所得と通算できる。  
  
イ  開発への貢献の大きい組合員に、出資比率以上の議決権と利益を分配すること  
ができる。  
  
ウ  貸借対照表の作成が不要である。  
  
エ  取締役会の設置が不要である。  
  
オ  利益が出れば、LLPには課税されず、組合員への利益分配に直接課税され  
る。  
`
        },
        {
                "mondaiId": 107200250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
中小企業経営者のX氏は、従業員の退職金制度を整備したいと考え、中小企業  
診断士のY氏に相談した。  
以下は、X氏とY氏の会話である。  
会話の中の空欄ＡとＢに入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
X氏：「中小企業者が利用できる退職金制度があれば、教えてほしいのですが。」  
Y氏：「ありますよ。中小企業退職金共済制度を利用すれば、中小企業も簡便で有  
利な退職金制度を整備できます。」  
X氏：「具体的には、どのような制度なのでしょうか。」  
Y氏：「この制度は、事業主の相互共済の仕組みと国の援助によって、中小企業だ  
けでは難しい退職金制度の整備を支援してくれます。」  
X氏：「どのように利用するのですか」  
Y氏：「従業員ごとにＡと退職金共済契約を締結します。毎月一定額の掛  
金を納付すると、従業員が退職したときに、所定の退職金がＢ。」  
〔解答群〕  
  
ア  Ａ：独立行政法人勤労者退職金共済機構  
Ｂ：中小企業者を通じて従業員に支払われます  
  
イ  Ａ：独立行政法人勤労者退職金共済機構  
Ｂ：直接、従業員に支払われます  
  
ウ  Ａ：独立行政法人中小企業基盤整備機構  
Ｂ：中小企業者を通じて従業員に支払われます  
  
エ  Ａ：独立行政法人中小企業基盤整備機構  
Ｂ：直接、従業員に支払われます  
`
        },
        {
                "mondaiId": 107200260,
                "name": "第26問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
ある事業協同組合は、組合員の生産性向上を図るため、ITを有効に活用するシ  
ステムの構築を目指している。以下の会話は、この事業協同組合の理事であるA  
氏と中小企業診断士のB氏との会話である。会話の中の空欄に入る最も適切なも  
のを下記の解答群から選べ。  
A氏：「私どもの組合では、組合員の社内基幹業務システムと複数のEDIシステム  
との連携を図るためのシステム構築を計画しています。何かよい支援施策が  
あったら、ぜひ紹介してほしいのですが。」  
B氏：「中小企業の生産性向上につながるシステムの構築を目指すコンソーシアム  
や組合などを支援する施策としてがあります。公募がありますの  
で、事業計画書を作成し応募してみてはいかがでしょうか。」  
〔解答群〕  
  
ア  IT経営実践促進事業  
  
イ  戦略的CIO育成支援事業  
  
ウ  中小企業IT経営革新支援事業  
  
エ  中小企業経営革新プラットフォームシステム開発事業  
`
        },
        {
                "mondaiId": 107200270,
                "name": "第27問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
次の文中の空欄ＡとＢに入る最も適切なものの組み合わせを下記の解答群から選  
べ。  
技術革新支援に関連する中小企業施策のひとつに、「地域イノベーション創出研  
究開発事業」がある。この事業では、公募により研究開発テーマを募集する。支援  
対象は、地域のＡであり、新産業の創出に貢献しうるような最先端の技術  
シーズをもとにした研究開発テーマが対象となる。この事業の研究期間は  
Ｂ年以内である。  
〔解答群〕  
  
ア  Ａ：研究開発型ベンチャー企業Ｂ：\u0002  
  
イ  Ａ：研究開発型ベンチャー企業Ｂ：\u0003  
  
ウ  Ａ：産学官からなる研究体Ｂ：\u0002  
  
エ  Ａ：産学官からなる研究体Ｂ：\u0003  
`
        },
        {
                "mondaiId": 107200280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
「小規模企業設備資金貸付制度」は、小規模企業者等の経営基盤の強化に必要な設  
備導入を図ることを目的とした制度である。この制度に関する記述として、最も不  
適切なものはどれか。  
  
ア  貸付対象は、同一地区で\u0002年以上事業を行っている小規模企業である。  
  
イ  貸付利率は無利子である。  
  
ウ  都道府県中小企業支援センターが、書類調査、企業診断等を行う。  
  
エ  連帯保証人または物的担保が必要である。`
        },
        {
                "mondaiId": 107200291,
                "name": "第29問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
次の文章を読んで、下記の設問に答えよ。  
経営革新支援事業は、中小企業が今日的な経営課題に即応するために行う経営革  
新を全業種にわたり幅広く支援するものである。対象となるのは、事業内容や経営  
目標を盛り込んだ「経営革新計画」を作成し、Ａの承認を受けた中小企業者  
等である。  
「経営革新計画」に盛り込む経営目標は、付加価値額または従業員\u0002人あたりの付  
加価値額が年率平均Ｂ％以上伸び、かつ、経常利益が年率平均  
Ｃ％以上伸びる計画となっていることが必要である。  
（設問1））  
文中の空欄Ａに入る最も適切な語句はどれか。  
  
ア  国  
  
イ  市区町村  
  
ウ  市区町村または都道府県  
  
エ  都道府県または国  
`
        },
        {
                "mondaiId": 107200292,
                "name": "第29問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2008,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄ＢとＣに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ｂ：\u0002Ｃ：\u0003  
  
イ  Ｂ：\u0004Ｃ：\u0003  
  
ウ  Ｂ：\u0004Ｃ：\u0002  
  
エ  Ｂ：\u0005Ｃ：\u0002  
  
オ  Ｂ：\u0005Ｃ：\u0004  
`
        },
        {
                "mondaiId": 101190010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本の実質GDP成長率とGDPデフレータの長期統計を表したもので  
ある。この図の説明として最も適切なものはどれか。  
出所：内閣府『国民経済計算』  
  
ア  １９５０年代後半以降、日本経済は高度成長期に入るが、１９６０年代後半は「神武景  
気」と呼ばれる長期の景気拡大が見られた。  
  
イ  １９７０年代前半、第\u0001次石油ショックの影響を受けて、物価の上昇と景気後退  
の併存現象が見られ、日本経済は「スタグフレーション」に見舞われた。  
  
ウ  １９８０年代には、ブレトンウッズ体制崩壊の影響を受けて為替レート制の変更  
が生じ、日本経済は低成長時代に入った。  
  
エ  ２０００年代に入ると、日本経済がデフレ傾向にあることが読み取れ、名目GDP  
成長率が実質GDP成長率を上回る現象が見られた。  
`
        },
        {
                "mondaiId": 101190020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図は、日本の雇用形態を描いたものである。それによれば、正規雇用者数が減  
少する反面、非正規雇用者数が増加し、非正規雇用比率（全雇用者に占める非正規  
雇用者の割合）が上昇していることが読み取れる。この傾向を説明するものとし  
て、最も適切な記述の組み合わせを下記の解答群から選べ。  
出所：『経済財政白書』（２００６年版）  
ａ規制緩和は労働市場の流動化を引き起こし、非正規雇用者数の増加を加速させ  
る要因である。  
ｂ正規雇用者と非正規雇用者の間では賃金の格差がなく、企業の人件費総額はほ  
ぼ不変である。  
ｃニートやフリーターの減少は非正規雇用者数を押し上げる要因になっている。  
ｄ非正規雇用者数の増加に伴い、企業の人件費は減少傾向にある。  
〔解答群〕  
  
ア  ａとｂイａとｃウａとｄ  
  
エ  ｂとｃオｃとｄ  
`
        },
        {
                "mondaiId": 101190030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
１９８０年代後半以降、日本の製品輸入比率は上昇傾向にある。その要因として、  
最も適切な記述の組み合わせを下記の解答群から選べ。  
ａアジア地域における工業化の進展は、日本の製品輸入比率を上昇させる要因で  
ある。  
ｂ円安は、日本の製品輸入比率の上昇を引き起こす。  
ｃ日本企業の企業内貿易の進展は、製品輸入の増加と産業内貿易から産業間貿易  
への変化を引き起こす。  
ｄ日本企業の対外直接投資の増加は、日本の製造業の海外生産比率を上昇させる  
とともに、製品輸入比率も上昇させる。  
〔解答群〕  
  
ア  ａとｂイａとｃウａとｄ  
  
エ  ｂとｃオｂとｄ  
`
        },
        {
                "mondaiId": 101190041,
                "name": "第4問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の均衡GDPの決定に関する文章を読んで、下記の設問に答えよ。  
総需要ADが消費C、投資I、政府支出G、経常収支（輸出X－輸入M）から構  
成される経済モデルを想定する。すなわち、  
AD＝C＋I＋G＋X－M  
である。  
ここで、消費関数と輸入関数はそれぞれ、  
C＝C０＋c（Y－T）  
M＝M０＋mY  
として与えられ、YはGDPまたは国内所得、C０は独立消費、cは限界消費性向、  
Tは租税収入、M０は独立輸入、mは限界輸入性向である。なお、租税収入、投資  
支出、政府支出、輸出はおのおのT＝T０、I＝I０、G＝G０、X＝X０とする。  
他方、所得の処分は、  
Y＝C＋S＋T  
として示される。ここでSは貯蓄である。  
このとき、下図のように、  
\u0001  
X－M線と（S＋T）－（I＋G）線の交点Eにおいて  
生産物市場が均衡し、均衡GDPはY０の水準に決定される。また、  
\u0002  
X－M線また  
は（S＋T）－（I＋G）線がシフトすれば、それによって均衡GDPや経常収支の水  
準も変化する。  
        
（設問\u0001）  
文中の下線部\u0001について、最も適切な記述の組み合わせを下記の解答群から選  
べ。  
ａ（S＋T）－（I＋G）線の縦軸の切片は、投資の水準が大きいほど上方に位置  
する。  
ｂX－M線の縦軸の切片は、輸出と独立輸入の大きさに依存する。  
ｃ均衡点Eでは、OAに相当する経常収支の赤字が生じている。  
ｄ限界消費性向cが大きいほど、（S＋T）－（I＋G）線は、より緩やかな形状  
で描かれる。  
ｅ限界輸入性向mが小さいほど、X－M線は、より急な形状で描かれる。  
〔解答群〕  
  
ア  ａとｃイａとｅウｂとｄエｂとｅオｃとｄ`
        },
        {
                "mondaiId": 101190042,
                "name": "第4問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0002について、最も適切な記述の組み合わせを下記の解答群から選  
べ。  
ａ減税は（S＋T）－（I＋G）線を上方にシフトさせる。  
ｂ政府支出の増加は（S＋T）－（I＋G）線を下方にシフトさせ、均衡GDPの  
増加と経常収支の悪化を引き起こす。  
ｃ投資の増加は（S＋T）－（I＋G）線を下方にシフトさせ、均衡GDPの増加  
と経常収支の改善を引き起こす。  
ｄ独立輸入の増加はX－M線を下方にシフトさせ、均衡GDPの増加と経常  
収支の悪化を引き起こす。  
ｅ輸出の増加はX－M線を上方にシフトさせ、均衡GDPの増加と経常収支  
の改善を引き起こす。  
〔解答群〕  
  
ア  ａとｃイａとｅウｂとｄエｂとｅオｃとｄ  
`
        },
        {
                "mondaiId": 101190043,
                "name": "第4問設問3",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
経常収支に関する記述として、最も適切なものの組み合わせを下記の解答群か  
ら選べ。  
ａアブソープション・アプローチでは、経常収支はGDPと国内需要（内需）の  
差額に等しい。  
ｂ経常収支が黒字の場合、財政収支が赤字であれば、民間の貯蓄と投資の差額  
は必ずプラスになる。  
ｃJカーブ効果が発生しない場合、為替レートの増価は経常収支を改善させ  
る。  
ｄJカーブ効果が発生する場合、経常収支は為替レートの減価によって一時的  
に改善するが、時間の経過とともに悪化する。  
〔解答群〕  
  
ア  ａとｂイａとｃウａとｄ  
  
エ  ｂとｃオｂとｄ  
`
        },
        {
                "mondaiId": 101190050,
                "name": "第5問",
                "seikai": "オ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
下図はIS曲線とLM曲線を描いたものである。それぞれの説明として、最も適  
切なものの組み合わせを下記の解答群から選べ。  
ａ貨幣供給の減少はLM曲線を右方にシフトさせる。  
ｂ貨幣需要の利子弾力性が大きいほど、LM曲線は、より急な形状で描かれる。  
ｃ限界消費性向の値が大きいほど、IS曲線は、より急な形状で描かれる。  
ｄ政府支出の増加はIS曲線を右方にシフトさせる。  
ｅ投資の利子弾力性が小さいほど、IS曲線は、より急な形状で描かれる。  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｅ  
  
エ  ｃとｄオｄとｅ  
`
        },
        {
                "mondaiId": 101190060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のデフレーションに関する文章中の空欄Ａ～Ｃに入る最も適切なものの組み合  
わせを下記の解答群から選べ。  
デフレーションは需要の減退によってもたらされ、貨幣の実質価値を高める効果  
を持ち、Ａへの所得再分配を生じさせる。また、人々がＢを予想  
すれば、支出を手控えることになり、デフレ・スパイラルに陥り、不況をさらに悪  
化させることがある。需要の減退が生じている場合、「需要不足失業」とも呼ばれる  
Ｃが観察され、需要拡大政策の発動を通じて失業の解消に努めることが要  
請される。  
〔解答群〕  
  
ア  Ａ：債権者から債務者Ｂ：物価の持続的下落Ｃ：循環的失業  
  
イ  Ａ：債権者から債務者Ｂ：物価の持続的上昇Ｃ：摩擦的失業  
  
ウ  Ａ：債務者から債権者Ｂ：物価の持続的下落Ｃ：構造的失業  
  
エ  Ａ：債務者から債権者Ｂ：物価の持続的下落Ｃ：循環的失業  
  
オ  Ａ：債務者から債権者Ｂ：物価の持続的上昇Ｃ：摩擦的失業`
        },
        {
                "mondaiId": 101190070,
                "name": "第7問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　財政政策の理論に関する説明として、最も適切なものはどれか。  
  
ア  貨幣需要が利子率にまったく反応しない場合、政府支出の発動によってクラウディング・アウトは生じず、所得の増加がもたらされる。  
  
イ  経済が「流動性のわな」に陥った場合、政府支出を発動しても完全なクラウディング・アウトを招くのみで、景気押し上げ効果が発生しない。  
  
ウ  恒常所得仮説では、<hintTarget>１回かぎりの減税</hintTarget>は可処分所得の増加と<hintTarget>消費の拡大</hintTarget>を引き起こし、景気の拡大に寄与すると考えられる。  

<hint hidden='true'>**
❌恒常所得仮説では、１回限りの減税は変動所得であるため消費を拡大しないと考えます。
** </hint>

エ  等価定理が成り立つ場合、人々は、財政赤字を伴う政府支出の増加は将来の増税によって賄われることを予想するために、景気刺激効果は発生しない。  

<hint hidden='true'>**
⭕適切です。  
** </hint>

`
        },
        {
                "mondaiId": 101190080,
                "name": "第8問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
貨幣理論および金融政策に関する説明として、最も適切なものはどれか。  
  
ア  貨幣数量説と完全雇用を前提とすれば、名目貨幣供給が増加しても実質貨幣供  
給は不変であるが、利子率の低下を通じて投資を刺激する。  
  
イ  貨幣数量説と完全雇用を前提とすれば、名目貨幣供給の増加はそれと同率の物  
価の上昇を引き起こし、貨幣の中立性が成立する。  
  
ウ  公定歩合の引き下げ、売りオペ、外貨準備の増加はハイ・パワード・マネーの  
増加を通じて貨幣供給量を増加させる。  
  
エ  流動性選好理論では、所得の増加によって貨幣の投機的需要が増加すると考え  
る。  
  
オ  流動性選好理論では、利子率の低下によって貨幣需要が減少すると考える。  
`
        },
        {
                "mondaiId": 101190090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　金利平価説によれば、国際的に金融資産への投資を行うことにより、次式が成り立つところで為替レートが決まると考えられている。  

<imgTag>101190090_1.png</imgTag>  

この式の意味するものとして、最も適切な記述はどれか。  
  
ア  <hintTarget>アメリカの利子率</hintTarget>が日本の利子率より<hintTarget>高い</hintTarget>としても、現実の為替レートが<hintTarget>円高・ドル安</hintTarget>の方向に動けば、日本の金融資産に投資することが有利になる。  
  
イ  左辺は日本の金融資産への投資に伴う収益率、右辺はアメリカの金融資産への投資に伴う収益率であり、左辺が右辺より<hintTarget>大きければ</hintTarget>、日本への投資が増加し、為替レートは<hintTarget>円安・ドル高</hintTarget>になる。  

<hint hidden='true'>**
❌  
<imgTag>101190090_2.png</imgTag>  
** </hint>

ウ  日本の金融緩和は、国内利子率を低下させて為替レートを円高・ドル安の方向に変動させる。  
  
エ  <hintTarget>予想</hintTarget>為替レートが<hintTarget>円高・ドル安</hintTarget>の方向に動けば、<hintTarget>現実</hintTarget>の為替レートも<hintTarget>円高・ドル安</hintTarget>の方向へと動き、予想の自己実現が見られる。  

<hint hidden='true'>**
⭕  
<imgTag>101190090_3.png</imgTag>  
**</hint>



`
        },
        {
                "mondaiId": 101190100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
内生的経済成長論の説明として、最も適切なものはどれか。  
  
ア  AKモデルでは、限界生産力逓減の生産関数を仮定し、貯蓄率や全要素生産性  
の変化が経済成長の要因になることを明らかにしている。  
  
イ  ITの普及によって低インフレや高成長が生じたという「ニュー・エコノミー」  
を説明するものとして内生的経済成長論が確立され、技術進歩を内生変数（モデ  
ル内で説明される変数）として扱う。  
  
ウ  新古典派経済成長論は、\u0001人当たりの産出量が持続的に上昇することを明らか  
にしているが、内生的経済成長論は、それが長期的には一定の水準に収束するこ  
とを証明している。  
  
エ  内生的経済成長論では、教育、知識、人的資本、研究開発が経済成長に果たす  
役割を重要視している。  
`
        },
        {
                "mondaiId": 101190110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
所得格差を示す数値であるジニ係数は、その値が低いほど平等であると考えられ  
る。日本のジニ係数に関して、<u>最も不適切なものはどれか</u>。  
  
ア  \u0001世帯当たりの世帯人員を勘案した年間可処分所得（等価可処分所得）のジニ係  
数をみると、先進国の中で日本は国際的に中位に位置する。  
  
イ  高齢者層ほど、総世帯の年間可処分所得（等価可処分所得）のジニ係数が高い。  
  
ウ  租税や社会保険料の支払、年金給付等の移転所得の受取後の年間収入に関する  
ジニ係数は、その再分配前の年間収入に関するジニ係数よりも大きい。  
  
エ  年間収入に関する日本のジニ係数は増加傾向にある。`
        },
        {
                "mondaiId": 101190120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
高齢化社会を迎え、医療機関が提供する医療サービスに対する価格水準について  
の議論が行われるようになっている。以下の記述について、最も適切なものの組み  
合わせを下記の解答群から選べ。  
ａ生死にかかわるような重度な症状の場合、医療サービスへの需要の価格弾力性  
は低い。  
ｂ生死にかかわらない症状で、自然治癒などの代替的治療法が複数ある場合、医  
療サービスへの需要の価格弾力性は低い。  
ｃ公的保険制度が充実して患者自己負担が少ない場合、医療サービスへの需要の  
価格弾力性は低い。  
ｄ公的保険制度が貧弱で患者自己負担が高い場合、医療サービスへの需要の価格  
弾力性は低い。  
〔解答群〕  
  
ア  ａとｃイａとｄウｂとｃエｂとｄ  
`
        },
        {
                "mondaiId": 101190131,
                "name": "第13問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
完全競争下で操業する企業が、次のような費用関数を持つと仮定する。ここで、  
Cは総費用を、Xは生産量を表す。  
C＝X３－\u0001X２＋\u0002X＋１０  
下記の設問に答えよ。  
（設問２））  
平均可変費用関数として最も適切なものはどれか。  
  
ア  X２－\u0001X＋\u0002  
  
イ  \u0004X２－\u0005X＋\u0002  
  
ウ  X３－\u0001X２＋\u0002X  
  
エ  X２－\u0001X＋\u0002＋  
１０  
X`
        },
        {
                "mondaiId": 101190132,
                "name": "第13問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
限界費用関数として最も適切なものはどれか。  
  
ア  X２－\u0001X＋\u0002  
  
イ  \u0004X２－\u0005X＋\u0002  
  
ウ  X３－\u0001X２＋\u0002X  
  
エ  X２－\u0001X＋\u0002＋  
１０  
X`
        },
        {
                "mondaiId": 101190133,
                "name": "第13問設問3",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
上記の費用関数から、それ以下では操業できない価格水準（操業停止点に対応  
する価格）を求めよ。  
  
ア  \u0004イ\u0005ウ\u0006エ\u0002  
`
        },
        {
                "mondaiId": 101190141,
                "name": "第14問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
次の文章を読んで、下記の設問に答えよ。  
下表は、A国とB国が、農業製品または工業製品を\u0001単位生産するのに必要な生  
産要素量を示している。ここで、簡単化のために、A国とB国の\u0002国のみを想定  
し、それぞれの国は、農業製品ならびに工業製品のみを生産すると考える。さら  
に、生産要素として労働力のみを考え、両国間で労働力の移動はないものとする。  
農業製品工業製品  
A国\u0003\u0004  
B国\u0005\u0001  
（設問\u0001）  
A国とB国の比較優位、絶対優位に関する説明として、最も適切なものはどれ  
か。  
  
ア  A国は、工業製品に比較優位を持っているが、絶対優位は持っていない。  
  
イ  A国は、農業製品に比較優位を持っているが、どちらの製品に関しても絶対  
優位は持っていない。  
  
ウ  B国は、工業製品に比較優位を持っているが、どちらの製品に関しても絶対  
優位は持っていない。  
  
エ  B国は、農業製品に比較優位を持っており、かつ、どちらの製品に関しても  
絶対優位を持っている。  
`
        },
        {
                "mondaiId": 101190142,
                "name": "第14問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
A国とB国が比較優位の原理にしたがって貿易を行おうとするとき、両国間で  
の貿易のパターンとして、最も適切なものはどれか。  
  
ア  A国は、工業製品も農業製品も輸出できない。  
  
イ  A国は、両国間で貿易が行われるとすれば、農業製品を輸出する。  
  
ウ  B国は、A国に比べ同程度の生産要素の賦存量を持つとすると、農業製品を  
輸出するが、A国に比べ賦存量が大きいと、工業製品を輸出する。  
  
エ  B国は、両国間で貿易が行われるとすれば、農業製品を輸出する。`
        },
        {
                "mondaiId": 101190143,
                "name": "第14問設問3",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
産業発展を目指すA国に関して、<u>最も不適切なものはどれか</u>。  
  
ア  教育の充実により、比較優位を変えることで、貿易のパターンを変えること  
ができる。  
  
イ  産業政策により、比較優位を変えることで、貿易のパターンを変えることが  
できる。  
  
ウ  生産要素賦存量を変えても、比較優位を変えることはできない。  
  
エ  比較優位を変えなくても、工業製品を輸出することで、貿易の利益を得るこ  
とができる。  
`
        },
        {
                "mondaiId": 101190151,
                "name": "第15問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
次の文章を読んで、下記の設問に答えよ。  
社会問題となっている入札談合に関し、談合を行った企業\u0001社（企業X，企業Y）  
が、捜査当局の取り調べに対し、自白するか、自白せず黙秘するかの選択を迫られ  
ているとする。それぞれの行動を取ったときの、各企業の利得行列は下表のように  
表され、各パラメーターは所与の利得の大きさを示し、数値が高いほど効用が高い  
とする。たとえば、（b，c）は企業Xの利得がbの水準であり、企業Yの利得が  
cの水準である。  
企業Y  
黙秘（協調）自白（非協調）  
企業X  
黙秘（協調）（a，a）（c，b）  
自白（非協調）（b，c）（d，d）  
（設問1））  
いわゆる囚人のジレンマが発生している場合、次の文章中の空欄Ａ～Ｄに入る  
最も適切なものの組み合わせを下記の解答群から選べ。  
囚人のジレンマは、Ａの利益の最大化が、Ｂの最適な選択と  
はならない状況である。どちらの企業にとっても、他社の行動にかかわらず、自  
白を選択する方がＣである。したがって、共に自白を選択することが  
Ｄである。  
〔解答群〕  
  
ア  Ａ：個々Ｂ：全体Ｃ：支配戦略Ｄ：ナッシュ均衡  
  
イ  Ａ：個々Ｂ：全体Ｃ：ナッシュ均衡Ｄ：支配戦略  
  
ウ  Ａ：全体Ｂ：個々Ｃ：ナッシュ均衡Ｄ：非支配戦略  
  
エ  Ａ：全体Ｂ：個々Ｃ：非支配戦略Ｄ：ナッシュ均衡  
`
        },
        {
                "mondaiId": 101190152,
                "name": "第15問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
囚人のジレンマが発生している場合の、各パラメーターの大小関係に関して、  
最も適切なものはどれか。  
  
ア  a＞b＞c＞d  
  
イ  a＞b＞d＞c  
  
ウ  b＞a＞c＞d  
  
エ  b＞a＞d＞c  
  
オ  c＞a＞d＞b`
        },
        {
                "mondaiId": 101190153,
                "name": "第15問設問3",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
同じゲームでも無限回の繰り返しゲームになると、協調解が均衡解として成立  
することが知られている。この理論を表す最も適切なものはどれか。  
  
ア  戦略的補完性  
  
イ  パレート最適  
  
ウ  フォーク定理  
  
エ  ベイジアン・ナッシュ均衡  
  
オ  ミニマックス原理  
`
        },
        {
                "mondaiId": 101190161,
                "name": "第16問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
現在、日本経済では、金利が非常に低い水準にあるが、今後は金利の上昇が見込  
まれている。その金利上昇の消費への影響を、所得効果と代替効果から分析を行  
う。下記の設問に答えよ。  
（設問\u0001）  
多くの金融資産を持っている高齢者について、金利上昇の所得効果と代替効果  
による現時点での消費への影響に関して、最も適切なものの組み合わせはどれ  
か。  
  
ア  所得効果：減少代替効果：減少  
  
イ  所得効果：減少代替効果：増加  
  
ウ  所得効果：増加代替効果：減少  
  
エ  所得効果：増加代替効果：増加  
  
オ  所得効果：増加代替効果：なし`
        },
        {
                "mondaiId": 101190162,
                "name": "第16問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 101,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
現在、住宅ローンあるいは借金生活を余儀なくされている労働者が、変動金利  
で借金をしているとすれば、金利上昇の所得効果と代替効果による現時点での消  
費への影響に関して、最も適切なものの組み合わせはどれか。  
  
ア  所得効果：減少代替効果：減少  
  
イ  所得効果：減少代替効果：増加  
  
ウ  所得効果：増加代替効果：減少  
  
エ  所得効果：増加代替効果：増加  
  
オ  所得効果：増加代替効果：なし  
`
        },
        {
                "mondaiId": 102190010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の表の空欄Ａに入る最も適切な金額を下記の解答群から選べ（単位：千円）。  
（単位：千円）  
期首期末  
収益費用  
純資産の変動  
資産負債資産負債  
当期  
純損益  
その他  
増加減少  
６４０２８０Ａ３４０８１０９３０（）２１０１９０  
〔解答群〕  
  
ア  ５６０イ６００ウ７２０エ８４０  
`
        },
        {
                "mondaiId": 102190021,
                "name": "第2問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の精算表に基づき、下記の設問に答えよ。  
（設問\u0001）  
売上総利益が２８０千円であるとき、空欄Ａに入る金額として最も適切なものは  
どれか（単位：千円）。  
  
ア  １８６イ１９０ウ２０６エ２１０`
        },
        {
                "mondaiId": 102190022,
                "name": "第2問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
空欄ＢとＣに入る最も適切な金額の組み合わせはどれか（単位：千円）。  
  
ア  Ｂ：１０Ｃ：５イＢ：１０Ｃ：１５  
  
ウ  Ｂ：２０Ｃ：１５エＢ：２０Ｃ：２５  
精算表  
（単位：千円）  
残高試算表修正記入損益計算書貸借対照表  
借方貸方借方貸方借方貸方借方貸方  
現金（）１３０  
当座預金８２８（）  
売掛金（）３６０  
繰越商品Ａ（）（）１９８  
貸付金（）２７０  
備品３００（）  
買掛金（）３５５  
貸倒引当金５（）（）  
減価償却累計額９０（）（）  
資本金（）１，５００  
売上１，４４０（）  
受取利息Ｂ（）Ｃ  
仕入１，１５２（）（）（）  
給料（）１００  
支払家賃（）（）（）  
保険料１８（）９  
３，４００３，４００  
貸倒引当金繰入１３（）  
減価償却費４５（）  
前払保険料（）（）  
未払家賃１２（）  
未収利息５（）  
当期（）ＤＥＦＧ  
（）（）（）（）２，１００２，１００  
`
        },
        {
                "mondaiId": 102190023,
                "name": "第2問設問3",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
空欄Ｄ～Ｇのうち、金額が入る箇所として最も適切な組み合わせはどれか。  
  
ア  ＤとＦイＤとＧウＥとＦエＥとＧ`
        },
        {
                "mondaiId": 102190030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
経過勘定項目について述べた次の文章の空欄Ａ～Ｄに入る最も適切な語句の組み  
合わせを下記の解答群から選べ。  
一定の契約に従い、継続して役務の提供をＡ場合、Ｂ対価は、  
当期の損益計算Ｃするとともに貸借対照表のＤの部に計上しなけ  
ればならない。  
〔解答群〕  
  
ア  Ａ：受けるＢ：いまだ提供されていない役務に対し支払われた  
Ｃ：に計上Ｄ：資産  
  
イ  Ａ：受けるＢ：既に提供された役務に対し支払いが終わらない  
Ｃ：から除去Ｄ：資産  
  
ウ  Ａ：行うＢ：いまだ提供していない役務に対し支払いを受けた  
Ｃ：から除去Ｄ：負債  
  
エ  Ａ：行うＢ：既に提供した役務に対し支払いを受けていない  
Ｃ：に計上Ｄ：負債  
`
        },
        {
                "mondaiId": 102190040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
役員賞与の会計処理に関する記述として最も適切なものはどれか。  
  
ア  当事業年度の職務に係る役員賞与を期末後に開催される株主総会の決議事項と  
する場合には、当該支給は株主総会の決議が前提となるので、当該決議事項とす  
る額を費用として処理することは適当でない。  
  
イ  役員賞与は、経済的実態としては業績連動型報酬ではなく、確定報酬と同様の  
性格であると考えられるため、費用として処理することが適当である。  
  
ウ  役員賞与は、利益をあげた功労に報いるために支給されるものであって、職務  
執行の対価として支給される役員報酬とは性格が異なるため、費用として処理す  
ることは適当でない。  
  
エ  役員賞与と役員報酬は職務執行の対価として支給されるが、職務執行の対価と  
しての性格は、本来、支給手続の相違により影響を受けるものではないと考えら  
れるため、その性格に従い、費用として処理することが適当である。  
`
        },
        {
                "mondaiId": 102190050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
連結貸借対照表の純資産の部の記載形式として最も適切なものはどれか。  
  
ア    
（純資産の部）  
株主資本２１，６００  
資本金１０，０００  
資本剰余金１，０００  
利益剰余金５，２００  
自己株式\u0001１，６００  
新株予約権４，０００  
少数株主持分３，０００  
評価・換算差額等１，４００  
繰延ヘッジ損益１，４００  
純資産合計２３，０００  
  
イ    
（純資産の部）  
株主資本１８，６００  
資本金１０，０００  
資本剰余金１，０００  
利益剰余金５，２００  
自己株式\u0001１，６００  
新株予約権４，０００  
評価・換算差額等１，４００  
繰延ヘッジ損益１，４００  
少数株主持分３，０００  
純資産合計２３，０００  
  
ウ    
（純資産の部）  
株主資本１７，６００  
資本金１０，０００  
資本剰余金１，０００  
利益剰余金５，２００  
自己株式\u0001１，６００  
少数株主持分３，０００  
評価・換算差額等１，４００  
繰延ヘッジ損益１，４００  
新株予約権４，０００  
純資産合計２３，０００  
  
エ    
（純資産の部）  
株主資本１４，６００  
資本金１０，０００  
資本剰余金１，０００  
利益剰余金５，２００  
自己株式\u0001１，６００  
評価・換算差額等１，４００  
繰延ヘッジ損益１，４００  
新株予約権４，０００  
少数株主持分３，０００  
純資産合計２３，０００  
`
        },
        {
                "mondaiId": 102190060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
株主資本の前期末残高は次のとおりである。利益剰余金の配当以前に当期におけ  
る株主資本の変動はなく、決議された配当の総額２００百万円は適切であるとする。  
このとき、利益準備金と繰越利益剰余金の増加または減少の金額として、会社法お  
よび会社計算規則に照らして最も適切なものの組み合わせを下記の解答群から選べ  
（単位：百万円）。  
株主資本前期末残高  
（単位：百万円）  
資本金２，４００  
資本剰余金  
資本準備金４００  
その他資本剰余金２０  
資本剰余金合計４２０  
利益剰余金  
利益準備金１２０  
その他  
利益剰余金  
任意積立金１００  
繰越利益剰余金１，０８０  
利益剰余金合計１，３００  
株主資本合計４，１２０  
〔解答群〕  
  
ア  利益準備金：１８の減少繰越利益剰余金：１８２の減少  
  
イ  利益準備金：２０の増加繰越利益剰余金：２２０の減少  
  
ウ  利益準備金：２２の増加繰越利益剰余金：２２２の減少  
  
エ  利益準備金：８０の増加繰越利益剰余金：２８０の減少  
`
        },
        {
                "mondaiId": 102190070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
株式会社の会社法上の計算書類として最も適切なものはどれか。  
  
ア  貸借対照表、損益計算書、株主資本等変動計算書、個別注記表、<hintTarget>事業報告、付属明細書</hintTarget>  
<hint hidden='true'>**  
❌計算書類に含まれません。会社法上、10年間保存が必要な書類のであれば、この選択肢の通り（ミスリードを狙ったいわば「引っ掛け」です）。  
** </hint>
  
イ  貸借対照表、損益計算書、株主資本等変動計算書、個別注記表、事業報告  
  
ウ  貸借対照表、損益計算書、株主資本等変動計算書、個別注記表<hint hidden='true'>**  
←⭕計算書類として適切です。  
** </hint>  
  
エ  貸借対照表、損益計算書、株主資本等変動計算書、事業報告
`
        },
        {
                "mondaiId": 102190080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
原価は、その消費量および価格の算定基準にしたがって、実際原価と標準原価と  
に区別される。標準原価に関する以下の記述のうち、最も適切なものの組み合わせ  
を下記の解答��から選べ。  
ａ標準原価として、実務上予定原価が意味される場合がある。予定原価とは、将  
来における財貨の予定消費量と予定価格または実際価格とをもって計算した原価  
をいう。  
ｂ標準原価として、実務上予定原価が意味される場合がある。予定原価とは、将  
来における財貨の予定消費量と予定価格とをもって計算した原価をいう。  
ｃ標準原価とは、科学的、統計的調査に基づいて将来における財貨の実際消費量  
を予定し、かつ、予定価格または実際価格とをもって計算した原価をいう。  
ｄ標準原価とは、財貨の消費量を科学的、統計的調査に基づいて能率の尺度とな  
るように予定し、かつ、正常価格または実際価格をもって計算した原価をいう。  
ｅ標準原価とは、財貨の消費量を科学的、統計的調査に基づいて能率の尺度とな  
るように予定し、かつ、予定価格または正常価格をもって計算した原価をいう。  
〔解答群〕  
  
ア  ａとｃイａとｅウｂとｄエｂとｅ  
`
        },
        {
                "mondaiId": 102190090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A社とB社の貸借対照表（要約）は次のとおりである。両社の流動性に関する記述  
として最も適切なものを下記の解答群から選べ。  
貸借対照表（要約）  
（単位：百万円）  
資産A社B社負債・純資産A社B社  
現金預金４０６０支払手形５０８０  
受取手形３０３０買掛金９０６０  
売掛金５０４０長期借入金８０１００  
売買目的有価証券４０５０資本金１００１１０  
たな卸資産１６０１１０資本剰余金９０６０  
固定資産１５０１５０利益剰余金６０３０  
合計４７０４４０合計４７０４４０  
〔解答群〕  
  
ア  自己資本比率はA社がB社より良好であるが、固定長期適合率はB社がA社  
より良好である。  
  
イ  自己資本比率はB社がA社より良好であるが、固定長期適合率はA社がB社  
より良好である。  
  
ウ  当座比率はA社がB社より良好であるが、流動比率はB社がA社より良好で  
ある。  
  
エ  当座比率はB社がA社より良好であるが、流動比率はA社がB社より良好で  
ある。  
`
        },
        {
                "mondaiId": 102190101,
                "name": "第10問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
次の製品別の販売価格および原価等のデータに基づき、下記の設問に答えよ。  
（製品単位：kg）  
製品A製品B製品C  
販売価格６，０００円９，０００円１２，０００円  
単位当たり変動費４，２００円６，３００円８，４００円  
限界利益率（）％（）％（）％  
単位当たり設備稼働時間\u0001時間\u0002時間\u0003時間  
最大可能販売数量４００kg２００kg１２０kg  
共通製造固定費５７７，０００円  
共通販売・一般管理固定費３２０，０００円  
（設問\u0001）  
損益分岐点売上高として最も適切なものはどれか（単位：円）。  
  
ア  ２，９００，０００イ２，９０９，０００ウ２，９９０，０００エ２，９９９，０００`
        },
        {
                "mondaiId": 102190102,
                "name": "第10問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
最大可能な設備稼働時間が１，０００時間であるとき、営業利益を最大にする  
各製品の実現可能な販売数量の組み合わせとして最も適切なものはどれか  
（単位：kg）。  
  
ア  Ａ：１２０Ｂ：２００Ｃ：１２０  
  
イ  Ａ：２００Ｂ：２００Ｃ：１００  
  
ウ  Ａ：４００Ｂ：６０Ｃ：１２０  
  
エ  Ａ：４００Ｂ：２００Ｃ：５０  
`
        },
        {
                "mondaiId": 102190110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
次の≪Ａ群≫に示された事柄は企業買収あるいは買収防衛策に関するものであ  
り、≪Ｂ群≫にはそれらの一般的な呼称を示している。最も適切なものの組み合わ  
せを下記の解答群から選べ。  
≪Ａ群≫  
\u0001買収企業が、買収のターゲットとする企業に対し、買い占めた株式の買い取  
りを申し入れる。  
\u0002買収のターゲットとされた企業が、自社の優良事業を他社に売却して、企業  
価値を低下させる。  
\u0003買収のターゲットとされた企業と友好関係にある企業が、買収のターゲット  
となった企業の株式保有などの支援をする。  
\u0004買収のターゲットとされた企業における買収企業の持株比率が一定水準に達  
したとき、買収のターゲットとされた企業が既存の株主や買収企業以外の企業  
に新株予約権を交付する。  
≪Ｂ群≫  
ａグリーンメール  
ｂ焦土作戦  
ｃホワイト・ナイト  
ｄポイズン・ピル  
〔解答群〕  
  
ア  \u0001―ａ\u0002―ｂ\u0003―ｃ\u0004―ｄ  
  
イ  \u0001―ａ\u0002―ｄ\u0003―ｃ\u0004―ｂ  
  
ウ  \u0001―ｃ\u0002―ｂ\u0003―ａ\u0004―ｄ  
  
エ  \u0001―ｃ\u0002―ｄ\u0003―ａ\u0004―ｂ  
`
        },
        {
                "mondaiId": 102190121,
                "name": "第12問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
次のA社の資料に基づき、下記の設問に答えよ。  
株価純資産倍率  
（PBR）  
配当性向配当利回り  
１．５倍６０％\u0001％  
（設問1））  
資料より求められるA社の自己資本利益率として最も適切なものはどれか。  
  
ア  ２．４％イ\u0003％ウ\u0004％エ１０％`
        },
        {
                "mondaiId": 102190122,
                "name": "第12問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
資料より求められるA社の株価収益率（PER）として最も適切なものはどれか。  
  
ア  \u0001倍イ\u0004倍ウ１５倍エ４０倍  
`
        },
        {
                "mondaiId": 102190130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
次の資料に基づく売上および仕入に関するキャッシュ・フローの記述として、最  
も適切なものを下記の解答群から選べ。  
期首残高期末残高  
売上債権１００万円１５０万円  
仕入債務６０万円１００万円  
商品３０万円５０万円  
当期売上高１，０００万円  
当期仕入高６００万円  
〔解答群〕  
  
ア  売上によるキャッシュ・イン・フローが９５０万円、仕入によるキャッシュ・  
  
ア  ウト・フローが５６０万円である。  
  
イ  売上によるキャッシュ・イン・フローが９５０万円、仕入によるキャッシュ・  
  
ア  ウト・フローが６４０万円である。  
  
ウ  売上によるキャッシュ・イン・フローが１，０５０万円、仕入によるキャッ  
シュ・アウト・フローが５４０万円である。  
  
エ  売上によるキャッシュ・イン・フローが１，０５０万円、仕入によるキャッ  
シュ・アウト・フローが６４０万円である。  
`
        },
        {
                "mondaiId": 102190140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
ある投資家は、X株式とY株式の\u0001銘柄のうち、リスクの低い方に投資資金の  
６０％を、リスクの高い方に投資資金の４０％を投資しようとしている。この投資  
家は、各銘柄のβ係数、安全利子率、および市場期待収益率について、以下のと  
おり予想している。CAPMに基づいて投資をするとき、当該ポートフォリオの期  
待収益率として最も適切なものを下記の解答群から選べ。  
X株式のβ１．５安全利子率\u0001％  
Y株式のβ０．８市場期待収益率\u0002％  
〔解答群〕  
  
ア  ４．９０％イ５．２４％ウ５．４５％エ５．６６％`
        },
        {
                "mondaiId": 102190150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
Z株式\u0003株を原資産とする\u0003プット・オプション（ヨーロッパ型オプションで権  
利行使価格５２５円）とZ株式\u0003株から成るポートフォリオを所有している投資家が  
いる。この投資家が、当該ポートフォリオをプット・オプションの満期時に精算す  
るとき、満期時のZ株価が５００円である場合と６００円である場合の収入に関する記  
述として、最も適切なものはどれか。ただし、売買手数料、税金は考えないものと  
する。  
  
ア  満期時の株価が５００円のとき５００円の収入、満期時の株価が６００円のとき６００  
円の収入。  
  
イ  満期時の株価が５００円のとき５００円の収入、満期時の株価が６００円のとき６７５  
円の収入。  
  
ウ  満期時の株価が５００円のとき５２５円の収入、満期時の株価が６００円のとき６００  
円の収入。  
  
エ  満期時の株価が５００円のとき５２５円の収入、満期時の株価が６００円のとき６７５  
円の収入。  
`
        },
        {
                "mondaiId": 102190161,
                "name": "第16問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
次の文章を読んで、下記の設問に答えよ。  
K社では、相互排他的投資案としてA投資案とB投資案が提案されており、両投  
資案とも経済命数は\u0001年であり、各投資案のキャッシュ・フロー・データは次のと  
おりである。  
初期投資額第\u0002期第\u0003期第\u0001期  
A投資案  
期待値\u0004１，０００万円３００万円４００万円８００万円  
標準偏差０１５５１５５１５５  
B投資案  
期待値\u0004１，０００万円７００万円４００万円３００万円  
標準偏差０１５５１５５１５５  
※\u0004を付した金額はキャッシュ・アウト・フローを意味する。  
また、各期の期待値に基づく両投資案のNPV（正味現在価値）と割引率の関係が  
下図に示されている。  
        
（設問\u0001）  
A投資案とB投資案のNPVの標準偏差に関する記述として、最も適切なもの  
はどれか。  
ただし、NPVを計算する際の各期の割引率は、A投資案、B投資案とも同じ  
とする。また、キャッシュ・フローの各期相互の相関係数も、A投資案、B投資  
案とも同じとする。  
  
ア  A投資案とB投資案のNPVの標準偏差は同じである。  
  
イ  A投資案のNPVの標準偏差はB投資案のそれより大きい。  
  
ウ  B投資案のNPVの標準偏差はA投資案のそれより大きい。  
  
エ  NPVの標準偏差は相関係数の水準に依存するので、A投資案のNPVの標準  
偏差がB投資案のそれより大きいとか小さいとかは一概には言えない。`
        },
        {
                "mondaiId": 102190162,
                "name": "第16問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
リスク調整割引率法により投資案のNPVを算出する場合の記述として、最も  
適切なものはどれか。ただし、キャッシュ・フローが確実に生じる場合の割引率  
を毎期１０％とする。  
  
ア  毎期のリスク・プレミアムが\u0001％のとき、A投資案が選択される。  
  
イ  毎期のリスク・プレミアムが\u0001％のとき、B投資案が選択される。  
  
ウ  毎期のリスク・プレミアムが\u0003％のとき、A投資案が選択される。  
  
エ  毎期のリスク・プレミアムが９．５％と１２．６％の間にあるとき、A投資案が  
選択される。  
`
        },
        {
                "mondaiId": 102190171,
                "name": "第17問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。ただし、文中の（）については解  
答する必要はない。  
１，０００万円の資金を必要とするS株式会社を設立するにあたり、発起人は資金調  
達について検討に入った。なお、発起人の間では次の点について意見が一致してい  
る。  
（合意事項）  
１．会社設立後、会社は毎年１００万円の営業キャッシュ・フローを確実にもたら  
す。  
２．毎年の減価償却費は４０万円で、これと同金額が経営能力の維持のために毎年  
投資される。減価償却費以外の費用、収益はすべてキャッシュ・フローである。  
３．株式発行する場合の発行価格は\u0001株１０万円とする。  
４．市場利子率は\u0002％で、この率で自由に借り入れ・貸し付けできる。  
５．資本構成については、必要資金１，０００万円を全額株式で調達する案（以下「株式  
調達案」という。）と、５００万円を株式で調達し残りを借り入れとする案（以下「借  
入調達案」という。）の\u0003つについて検討する。  
６．利益はすべて現金配当する。  
発起人Pは、「税金を考えないものとすると、  
\u0001  
貸借対照表に基づく自己資本利益  
率は負債の利用度が高いほど高くなるので、自己資本利益率重視の経営を考えるな  
ら、借入調達案の方がよい。」と主張した。  
発起人Qは、「税金を考えないものとしても、われわれも投資家であるので、簿  
価ではなく時価で自己資本利益率を考えるべきではないか。利益、したがって配当  
総額は、株式調達案の場合は毎年（）万円、借入調達案の場合は毎年（）  
万円である。その結果、自己資本の価値が、株式調達案ではＡ万円、借入  
調達案ではＢ万円となる。そして、  
\u0002  
時価に基づく自己資本利益率は、株式  
調達案で（）％、借入調達案で（）％となる。」と述べた。  
発起人Rは、「前記合意事項に加えて、法人税率を４０％と仮定するとき、企業  
価値は、株式調達案ではＣ万円、借入調達案ではＤ万円となる。」  
と述べた。  
        
（設問\u0001）  
文中の下線部\u0001について、簿価による自己資本利益率の説明として最も適切な  
ものはどれか。  
  
ア  自己資本利益率は、株式調達案では\u0002％、借入調達案では\u0003％である。  
  
イ  自己資本利益率は、株式調達案では\u0002％、借入調達案では\u0004％である。  
  
ウ  自己資本利益率は、株式調達案では\u0002％、借入調達案では１２％である。  
  
エ  自己資本利益率は、株式調達案では１０％、借入調達案では\u0004％である。`
        },
        {
                "mondaiId": 102190172,
                "name": "第17問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0005）  
文中の空欄ＡおよびＢに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：１，０００Ｂ：５００イＡ：１，０００Ｂ：１，０００  
  
ウ  Ａ：１，５００Ｂ：５００エＡ：１，５００Ｂ：１，０００`
        },
        {
                "mondaiId": 102190173,
                "name": "第17問設問3",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0006）  
文中の下線部\u0002について、時価による自己資本利益率の説明として最も適切な  
ものはどれか。  
  
ア  時価による自己資本利益率は、株式調達案と借入調達案とでは同じである。  
  
イ  時価による自己資本利益率は、株式調達案の方が借入調達案より大きい。  
  
ウ  時価による自己資本利益率は、株式調達案の方が借入調達案より小さい。  
  
エ  時価による自己資本利益率は、簿価による自己資本利益率と同じである。`
        },
        {
                "mondaiId": 102190174,
                "name": "第17問設問4",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 102,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄ＣおよびＤに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ｃ：６００Ｄ：３００イＣ：６００Ｄ：６００  
  
ウ  Ｃ：９００Ｄ：６００エＣ：９００Ｄ：１，１００  
`
        },
        {
                "mondaiId": 103190010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
目標管理およびその技法や経営計画に関する説明として最も適切なものはどれ  
か。  
  
ア  米国大手電機メーカーなどで用いられているシックス・シグマという目標管理  
技法は、統計的管理幅を±\u0001シグマに定め、不良品の発生頻度を９６％以内に抑  
えて、経営効率を上げようとするものである。  
  
イ  米国からわが国に導入されたQCは独自な展開をみせ、やがて全社的な品質管  
理をめざすTQCへと発展したが、その推進の担い手であった日本科学技術連盟  
はその呼称をTQM（TotalQualityManagement）に変更した。  
  
ウ  米国レーガン政権時代の商務長官にちなんで設けられたマルコム・ボルドリッ  
ジ賞は、米国製造業の研究開発力を高める上で貢献したが、経営体質の改善や国  
際競争力を高める上では機能しなかった。  
  
エ  目標管理制度とQC活動が緊密に結びつくと短期志向の目先の管理に陥りやす  
いことから、この弊害を除くべくわが国ではクロス・ファンクショナル・チーム  
が用いられている。  
`
        },
        {
                "mondaiId": 103190021,
                "name": "第2問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
企業は規模を拡大するにつれ、生産の効率化や事業領域の調整、資金繰りや設備  
投資などあらゆる事業活動を計画的に進めることが重要になる。長期経営計画はそ  
のための代表的なものである。しかし、  
\u0001  
長期経営計画にはいくつか重要な問題点が  
指摘されている。そのため、それを克服すべく戦略的経営計画が広く用いられてい  
る。さらに、近年では  
\u0002  
バランス・スコアカードを導入する企業も増えつつある。  
（設問\u0001）  
文中の下線部\u0001の長期経営計画の問題点に関する記述として、最も不適切なも  
のはどれか。  
  
ア  過去の実績の趨勢や積み上げによる計画部分が多いと、環境の変化から遊離  
した計画になりやすく、現状維持的な業務遂行に甘んじがちになる。  
  
イ  計画と統制のサイクルが緊密に連動して、管理サイクルが短くなると、現場  
で創意工夫する余裕がなくなり、ルーティンな仕事ぶりが目に付くようにな  
る。  
  
ウ  計画の策定は通常半年以上かかるので、新年度に入ると早くも次期の計画の  
策定に取り掛かることになり、計画のローリングは不可能であるばかりか、計  
画そのものが絵にかいた餅として見捨てられがちになる。  
  
エ  本社の企画部門が中心になって策定した計画は、生産や営業の現場の声が反  
映されにくいことから、現場の挑戦意欲をそぎ、現場では受容されにくい傾向  
がある。  
`
        },
        {
                "mondaiId": 103190022,
                "name": "第2問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部\u0001のバランス・スコアカードに関する記述として最も適切なもの  
はどれか。  
  
ア  バランス・スコアカードでは、業績の原動力となるものをパフォーマンス・  
ドライバーとよび、これを特定して直接に管理することによって事前段階から  
の業績管理を可能にしようとする。  
  
イ  バランス・スコアカードには、経営のバランスを図るべく、ビジネス戦略の  
視点、財務の視点、顧客の視点、業務の視点、学習・成長の視点の\u0002つの視点  
が設定されている。  
  
ウ  バランス・スコアカードは、業績評価システムの構築を目指すものであり、  
成果主義的な管理制度には不可欠な管理ツールである。  
  
エ  バランス・スコアカードは、多様な目標を総花的に並べることになるだけ  
に、目標間の横の関係性や因果連鎖を的確に把握することは実際には不可能で  
あり、管理技法としての限界が指摘されている。  
  
オ  バランス・スコアカードは、日本的な目標管理制度を具体化する計画技法と  
して１９９０年代初頭に開発された。  
`
        },
        {
                "mondaiId": 103190030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
生産数量や費用構造の変動と競争状況に関する記述として、最も不適切なものは  
どれか。  
  
ア  企業間で生産能力や変動費に非対称性があり、それを制御できる場合、企業は  
競争優位を確立して、安定的な地位を築くことができる。  
  
イ  業界で自由競争が展開されている状況で、企業の費用構造が同質化するにつれ  
て、価格競争は激化しやすくなる。  
  
ウ  収穫が逓減するのは、固定的な生産要素が存在せず、生産数量が拡大するにつ  
れて、平均費用が減少する場合である。  
  
エ  需要の価格弾力性が低く、かつ、供給能力が伸長している市場では、価格競争  
の回避が難しくなり、企業の収益は低迷しやすくなる。  
  
オ  費用構造が収穫逓増を示すとき、最適生産規模が成立しないので、企業は生産  
数量を拡大して効率を上げようとしがちになり、規模拡大にともなってビジネス  
のリスクが高くなりやすい。  
`
        },
        {
                "mondaiId": 103190040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
IT（情報技術）を利用した新規事業の成功事例が次々に生まれている。このよう  
な新規事業に関する説明として最も適切なものはどれか。  
  
ア  ITは誰もが利用できる完結した技術システムであり、コア・コンピタンスで  
ある固有の情報や知識などの資源をベースにする必要がまったくない点が、IT  
ビジネスの大きな特徴である。  
  
イ  IT利用によって、顧客に提供する製品・サービスの価値や情報を広く伝える  
ことができるようになるが、その反面で、IT機器への投資が巨額になるので収  
益性が低下することは避けられない。  
  
ウ  IT利用の新規事業では、顧客の求める価値を提供できるようにビジネスを設  
計することが大切であるが、その設計アイデアは概して他社に見えやすく、模倣  
されやすいので、それを防ぐ手段を講じることの重要性を軽視してはならない。  
  
エ  ITを利用した新規事業の成功事例は、しばしばビジネス・モデルと呼ばれる  
が、これはビジネスのアイデアやデザインについて知的財産権が確立されたもの  
を指している。  
  
オ  ITを利用して自社に特徴的な分業の構造、インセンティブのシステム、情  
報、モノ、カネの流れなどを統合化する場合、独創性に欠けたものになるので自  
社の強みが薄らぐことに注意しなければならない。  
`
        },
        {
                "mondaiId": 103190050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品のモジュール化や開発競争をめぐる問題点や戦略的な適応に関する記述とし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  安価な部品やデバイス等を提供する中間財市場が成立するにつれて、製品のモ  
ジュール化が進んで、差別化による競争が激化することになる。  
  
イ  エレクトロニクス業界では、製品のコモディティ化を抑制する方法として、標  
準部材市場の成立を遅延させるために製品開発のスピードアップが試みられてい  
る。  
  
ウ  オープンな特許政策や自社部品の過剰な社外販売を展開すると、自社規格のデ  
ファクト・スタンダード化が起こりうるが、その反面で参入した他社との間で製  
品の価格競争が発生して、製品が一挙にコモディティ化する可能性が高まる。  
  
エ  技術の高度化につれて、商品の機能が向上するが、競争激化とともに顧客の支  
払う対価が低下し、商品ニーズの頭打ちとともに、商品価格の下落がみられるよ  
うになる。  
`
        },
        {
                "mondaiId": 103190060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
企業の業績に影響を与える業界の環境や構造、そして戦略に関する説明として、  
<u>最も不適切なものはどれか</u>。  
  
ア  業界の需要や費用の構造が企業の戦略に支配的な影響力をもつ場合、ライバル  
企業間の売上規模の差異は見られなくなり、それら企業の業績は比較的類似なも  
のになる。  
  
イ  高収益を続けている企業は、同業他社に比べて創業の早い段階から独自な製品  
や技術による強固な市場を築くとともに、技術開発を怠らず、新製品を連続的に  
生み出す傾向がある。  
  
ウ  多角化の目的のひとつは売上規模の拡大にあるが、新規市場での高い収益を維  
持する仕組みや参入障壁の構築に注意しないと、同業他社の追随を招きやすい。  
  
エ  他社と同じ市場を選択しても、自社のあらゆる活動を目的合理的に見直し他社  
と異質な環境適応の仕組みを構築することで独自性を発揮できる。  
`
        },
        {
                "mondaiId": 103190070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、薄型テレビやデジタルカメラなどのエレクトロニクス分野では、技術開発  
力のある大企業ばかりでなく、中小企業も巻き込んで活発な技術開発や製品開発の  
競争がみられる。そのような動向に関する説明として、最も不適切なものはどれ  
か。  
  
ア  技術開発力が不足するために技術競争を回避せざるをえない企業は、標準化し  
た技術や中間財を利用して、価格を武器に市場ニーズに直結した商品開発に特化  
して、参入障壁の高い強固な市場地位を確立できる。  
  
イ  技術革新をめぐる競争が製品の性能アップ競争として展開される場合に、性能  
を数値目標化して開発を進めると、数値目標が目に見えやすくなるのでライバル  
企業の追随を受けやすくなる。  
  
ウ  製品のキーデバイスを外部調達して大量安価に製品を供給できる仕組みを、国  
際的な水平分業によって実現できれば、世界的に市場シェアを高めて、コスト・  
リーダーシップを握ることが可能になる。  
  
エ  製品の性能アップ競争が激しくなると、顧客による性能対費用の評価も厳しく  
なるので、企業の収益性は悪化することになる。  
`
        },
        {
                "mondaiId": 103190080,
                "name": "第8問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
近年、多くの産業で、技術革新の進展や顧客ニーズの変化のスピードアップにと  
もなって、製品のライフサイクルが短縮化する傾向が見られる。これに対応すべく  
企業のイノベーション活動は活発化している。そのような戦略対応に関する説明と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  一般に、新製品開発のイノベーションが先行して、生産工程のイノベーション  
がそれに続くことが多いが、時間の経過とともにイノベーション・プロセスは洗  
練・精密化され、やがてイノベーションの発生頻度は低下するようになる。  
  
イ  営業部門が保有する固有の市場情報を製品イノベーションに用いるには、技術  
部門と営業部門との密接な連携が重要であるが、それぞれの部門には価値観の違  
いや固有の情報処理特性があるので、交流には時間やコストをかけた努力が必要  
になる。  
  
ウ  業界主流の製品を供給している企業は、技術イノベーションに注力しすぎて、  
当該製品をしばしば消費者の求める製品の性能から乖離した高性能製品にしてし  
まうことがある。  
  
エ  業界で市場シェアの高い有力な製品をもつ企業では、現有市場の顧客ニーズを  
重視するあまり、自社のこれまでの技術と異質な新規技術への取り組みが後手に  
回り、しばしば次世代技術に乗り遅れることが見られる。  
  
オ  リード・ユーザーは現有製品を自分好みに改良しようとする傾向が強いので、  
このユーザーの情報に基づいて開発した製品は、一般ユーザーを対象に行う市場  
調査情報に基づいて開発された製品に比べて、改良型製品になりやすい。  
`
        },
        {
                "mondaiId": 103190091,
                "name": "第9問設問1",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
\u0001  
日本企業が海外への直接進出を始めた１９６０年代から１９７０年代には、欧米の企業  
と異なる海外直接投資の特徴がみられた。現在ではそれらの特徴も薄らぎ、日本企  
業はグローバルな事業展開を目指すようになっている。そして親企業の海外展開に  
ともなって、系列の中堅・中小企業も海外進出することは最早当たり前になってき  
た。さらに、  
\u0002  
ASEANや中国などへは中堅・中小企業が独自に進出することも珍し  
くない。  
しかし、東アジアでは  
\u0003  
日本の中堅・中小企業が得意とする分野で現地企業の技術  
水準が上昇してきており、分業関係が複雑化していることに注意しておかなければ  
ならない。  
（設問\u0001）  
文中の下線部\u0001で指摘されている日本企業の海外への直接進出の特徴に関する  
説明として、<u>最も不適切なものはどれか</u>。  
  
ア  高度成長期の日本企業の基本戦略は、拡大を続ける巨大な国内市場への対応  
にあったため、海外進出よりも輸出による海外市場開拓を志向する傾向が強  
かった。  
  
イ  電機産業では商社のあっせんで現地パートナーの工場や施設を利用したり、  
現地の工場団地に入居したりして初期投資を節約しながら海外進出するといっ  
た商社参加型の進出が多くみられた。  
  
ウ  日本企業の海外子会社は本国志向が強く、現地人の幹部登用が少なく、日常  
業務では日本語が多く使われるなど、現地への適応は欧米に比べて遅れた。  
  
エ  米国が先進国のヨーロッパにまず初めに海外直接投資したこととは対照的  
に、日本企業は東南アジアや中南米諸国など発展途上国への海外直接投資が数  
多く試みられた。  
`
        },
        {
                "mondaiId": 103190092,
                "name": "第9問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部\u0001のような進出の理由として最も適切なものはどれか。  
  
ア  近年、進出企業の多いベトナムでは、外資優遇策、低廉な工業用地、質の高  
い勤勉な若い労働力などが外資を引き付けているが、概して産業インフラが十  
分に整っていない場合が少なくない点に注意が必要である。  
  
イ  これらの地域では既に多くの日本企業が進出しているので、それらの企業を  
通じて原材料や中間財のほとんどすべてを必要な量だけ安価に現地調達でき、  
また、現地国にも供給企業が多数存在するので、安定した操業を確保できるこ  
とが進出の魅力になっている。  
  
ウ  中堅・中小企業では商社をパートナーにした海外進出が多く見られるが、こ  
れは商社を通じて不足する海外進出ノウハウを補完できることや、自前で現地  
市場情報を直接に入手したり、海外進出のノウハウを習得できるなどのメリッ  
トがあるからである。  
  
エ  東アジアでは急拡大する現地市場が外資に開放されているが、注目���集まる  
中国では流通網が整っているのでそれを利用したマーケティング活動が可能で  
あることが、中堅・中小企業の中国進出に拍車をかけている。  
`
        },
        {
                "mondaiId": 103190093,
                "name": "第9問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部\u0001のような状況に関する説明として、最も不適切なものはどれ  
か。  
  
ア  現在の中国の電機分野をみると、台湾企業等の指導を受けて、金型製作や圧  
縮・押出・射出成形の技術が急速に向上し、日本の技術水準を上回ってきたた  
め、この領域への日本からの進出ができなくなった。  
  
イ  現地企業との分業関係が発展しにくいのは、自社の技術やノウハウの漏洩  
（ろうえい）防止への拘泥、取引先が日系企業であることなどのためであるが、  
その結果日本企業の現地との交流が乏しく、現地化が遅れる要因になってい  
る。  
  
ウ  この地域の電子組立・実装技術は高度であり、コンピュータなどの電子機器  
については世界の生産センターになりつつあるが、すり合わせ技術タイプの自  
動車については先進国の技術指導を受け入れる段階にある。  
  
エ  日系企業は現地で一貫生産体制をとることが多く、工程分業をする場合でも  
日系企業をパートナーに選択する傾向が強いが、台湾や韓国の企業は現地企業  
との取引関係を強めながら、現地化を推進している。  
`
        },
        {
                "mondaiId": 103190100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
発展している新興の産業分野を見ると、業界独特の機会が存在しており、それを  
活用することによって企業は他社を上回る業績をあげることができる。そのような  
業界の機会に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  業界の発展の初期段階で先行する企業は、競合する他社よりも早く累積生産量  
を積み増すことができるので、コスト優位よりも製品の差別化を追求できる。  
  
イ  新興市場の不確実性が高い場合、後続企業は新規分野への参入の意思決定を遅  
らせて、ビジネスの機会が確実になるとただちに量産体制を整えて市場に参入す  
れば、先発企業に比べてコスト優位を発揮できる可能性が高くなる。  
  
ウ  成功に結びつく経営資源を業界に知れ渡る前に入手することによって、持続可  
能な競争優位を獲得して、模倣に対する障壁を築くことができる。  
  
エ  先行企業が特許取得によって得られる利益機会は、早期投資に見合う収益が不  
確実である場合、必ずしも大きくなるとはいえない。  
`
        },
        {
                "mondaiId": 103190110,
                "name": "第11問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
市場の成長力を分析して自社製品を戦略的に位置づける場合の対応として、最も  
不適切なものはどれか。  
  
ア  自社製品が強い市場は売上げが伸び悩んでいるが、一定の収益が得られている  
し、これまで投入した生産設備や販売網の投資を考えると撤退は難しいので、現  
有の製品の改良や販売方法の改善をすることにした。  
  
イ  自社ではいくつか有力な製品の開発が進んでいるが、莫大な研究開発費がかか  
るので、有望分野を絞り込むために、これまでしたことのない方法であるが営業  
部門の意見を聞くべく、開発担当者と第一線の営業所長との合同会議を開催する  
ことにした。  
  
ウ  自社の独創技術による新製品は業界トップを占めて急進しているが、近々他社  
が類似製品を投入する予定であり、競争の激化が予想されるので、既存顧客への  
拡販に重点をおいた営業活動に特化し、生産や研究開発への投資を控えることに  
した。  
  
エ  市場の成長力はかつてのような勢いを失いつつあるものの、自社製品は依然と  
して業界トップの地位にあるので、ライバルに対しては必要最小限の対抗手段を  
とり、コストのかかる追加投資については慎重な姿勢をとることにした。  
  
オ  成長力の乏しい不採算部門については、リストラの一環として他社へ売却する  
ことにしたが、存続部門と技術的に関連の深い熟練技能者や技術者については他  
の部門に配属することにした。  
`
        },
        {
                "mondaiId": 103190120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
組織構造デザインのコンティンジェンシー理論によれば、組織は情報処理システ  
ムとしてみることができ、組織構造のデザインは、組織が直面する不確実性に応じ  
て適切な組み合わせで設計する必要があるという。組織構造デザインの方策には、  
組織が処理すべき情報量を軽減していく方策と、組織の情報処理能力自体を向上さ  
せる方策とに分類される。  
次のａからｄのうち、組織が処理すべき情報量の軽減に貢献するものとして最も  
適切なものの組み合わせを下記の解答群から選べ。  
ａ自己完結的職務の形成  
ｂ横断的関係の構築  
ｃ縦系列の情報処理システムの改善  
ｄ調整付加資源の投入  
〔解答群〕  
  
ア  ａとｂイａとｄウｂとｃ  
  
エ  ｂとｄオｃとｄ  
`
        },
        {
                "mondaiId": 103190131,
                "name": "第13問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
次の文章を読んで、下記の設問に答えよ。  
経営組織において  
\u0001  
集団は、個人が直接帰属意識を感じる準拠枠を提供するととも  
に、社会的欲求を充足する基本的単位として重要な役割を果たしている。  
\u0002  
集団圧力  
は参加者に同調行動を促すため、集団のリーダーは集団規範の形成や組織文化の形  
成に大きな影響を与える。役割や規則がとかく重視される公式組織に、価値を注入  
して活性化するリーダーシップの機能は、といわれる。  
（設問\u0001）  
文中の下線部\u0001の集団のダイナミクスに関する記述として最も適切なものはど  
れか。  
  
ア  集団圧力が強く作用する非公式集団が多いほど、上位の公式組織の目標達成  
度は高くなる。  
  
イ  集団圧力の強さは、その集団が個人にとっての環境をコントロールできる範  
囲が拡大するにつれて小さくなる。  
  
ウ  集団に対する外部からの脅威は、集団の凝集性を高め、個人が集団の価値と  
一体化する可能性を高める。  
  
エ  集団の規模が大きいほど、その集団の組織内での威信が高くなるから、個人  
が集団の価値と一体化する度合いは強くなる。  
`
        },
        {
                "mondaiId": 103190132,
                "name": "第13問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部\u0001に関する記述として最も適切なものはどれか。  
  
ア  集団の規模が大きくなると、個人の努力と集団の成果の関係が明確になるた  
め、「ただ乗り」するメンバーが出てくる可能性は高くなる。  
  
イ  集団の規模が大きくなるほど、またメンバーが経験を共有する期間が長いほ  
ど、集団の凝集性も高くなる。  
  
ウ  集団の凝集性が高くなるほど、生産性も向上する。  
  
エ  集団のメンバーがコンセンサスを重視しすぎると、「グループシンク（group  
think）」と呼ばれる現象に陥る可能性が高まる。  
  
オ  同質性の高い集団の方が、個人の場合よりも、よりリスクの低い意思決定を  
行う傾向がある、このことを「グループシフト（groupshift）」という。`
        },
        {
                "mondaiId": 103190133,
                "name": "第13問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄に入る言葉として最も適切なものはどれか。  
  
ア  温情型リーダーシップ  
  
イ  権威主義的リーダーシップ  
  
ウ  参加型リーダーシップ  
  
エ  制度的リーダーシップ  
  
オ  リーダーの連結ピン機能  
`
        },
        {
                "mondaiId": 103190140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
企業組織の境界の決定要因の一つに取引コスト（transactioncost）がある。取引  
コストと境界の決定に関する記述として最も適切なものはどれか。  
  
ア  当該企業に特有の知識などを必要とする特異性が高い職務についての労働力市  
場は、内部化したほうが労使間の情報の非対称性が大きくなるため、取引コスト  
を低くすることができる。  
  
イ  当該部品を供給できる企業の数が少ない場合には、市場メカニズムを通じて取  
引すると取引相手が機会主義的に行動できる余地が少なくなるので、内部化した  
ほうが取引コストを低くすることができる。  
  
ウ  取引主体の合理性の限界を超える複雑な職務の場合、組織に内部化するより  
も、市場メカニズムを通じて調達したほうが取引コストが低くなる。  
  
エ  内部労働市場では組織が個人を評価する能力が高くなるので、個人の機会主義  
的な行動を抑制し、取引コストを低く抑えることができる。  
`
        },
        {
                "mondaiId": 103190150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
組織における個人のモチベーションに影響を与える内的要因として欲求理論があ  
る。欲求理論に関する記述として最も適切なものはどれか。  
  
ア  アルダファーが提唱したERG理論は、欲求を存在欲求・関係性欲求・成長欲  
求の\u0001つの次元に分類し、低次の欲求が満たされないと高次の欲求はモチベー  
ション要因とはならないと主張した。  
  
イ  ハーズバーグが主張した\u0002要因論によれば、動機づけ要因と衛生要因には高い  
相関関係があり、衛生要因を充足しなければモチベーションは起こらないとい  
う。  
  
ウ  マグレガーは、管理者が部下に対して持つ人間観の理念型として、X理論・Y  
理論を提唱し、Y理論に従うと、部下を意思決定に参加させる方が仕事への意欲  
が高まるとした。  
  
エ  マクレランドは、欲求を達成欲求・権力欲求・親和欲求に分類し、達成欲求の  
高い従業員が優れた管理職になると主張した。  
  
オ  マズローが主張した欲求階層説によれば、自己実現など上位の欲求のほうが、  
モチベーション要因として強く作用するという。  
`
        },
        {
                "mondaiId": 103190161,
                "name": "第16問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
次の文章を読んで、下記の設問に答えよ。  
現代の企業は、必要な経営資源のすべてを自社内に所有することはほとんど不可  
能であり、企業の外部にある経営資源をコントロールする必要がある。そのため  
に、  
\u0001  
他の組織と望ましい組織間関係を構築したり、  
\u0002  
ドメインに影響を与え経営環境  
を操作しようとする。  
（設問\u0001）  
文中の下線部\u0001の組織間関係の構築に関する記述として、最も不適切なものは  
どれか。  
  
ア  ある企業の取締役が、資源依存関係にある他の企業の取締役に就任すること  
を通じて、両社の利害関係を調整したり、経営政策や意思決定に影響を与える  
ことができる。  
  
イ  企業は買収を通じて、他の企業の一部または全部の経営資源を直接コント  
ロールする権利を手にすることができる。  
  
ウ  企業はライセンス契約を通じて、自社の独立性を確保しつつ、不足する経営  
資源に関する不確実性を軽減することができる。  
  
エ  相互に補完的関係にある複数の企業が、共同で独立した合弁企業を作ること  
を通じて、その合弁企業の親会社の影響を受けずに、イノ��ーションや事業に  
関するリスクとコストを分散することができる。  
`
        },
        {
                "mondaiId": 103190162,
                "name": "第16問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部\u0001のドメインに影響を与える環境操作戦略に関する記述として、  
<u>最も不適切なものはどれか</u>。  
  
ア  同じような利害をもつ他の企業と共同で業界団体などの組織をつくることを  
通じて、企業は自らの製品やサービスの正当性を確保することができる。  
  
イ  広報活動は企業が情報を提供することを通じて、利害関係者集団に影響を与  
え、企業に対して好ましいイメージを持ってもらうために行われる。  
  
ウ  戦略的選択とは、企業が多角化や事業の売却、資源調達先の多様化を通じ  
て、ドメインそのものを変化させることをいう。  
  
エ  法律や行政による規制は、企業が獲得・利用できる資源に重要な影響を与え  
るが、内部資源にはその影響が及ばないため、企業は内部資源を増やす傾向に  
ある。  
`
        },
        {
                "mondaiId": 103190170,
                "name": "第17問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
　動機づけの過程理論と呼ばれるものには、目標設定理論（goal-settingtheory）や公平理論（equitytheory）、期待理論（expectancytheory）などがある。これらの理論に関する記述として最も適切なものはどれか。  
  
ア  期待理論によると、ある努力をした結果高い成果が生まれたにもかかわらず、低い報酬しか得られなかった場合、従業員は報酬の誘意性に関する主観確率を高く見積もる傾向がある。  
  
イ  公平理論によると、時間給制度のもとで、過大な報酬をもらっていると感じて  
いる従業員は、公平な報酬を得ている従業員と比較して生産量を減らそうとす  
る。  
  
ウ  公平理論によると、出来高給制度の下では、過大な報酬をもらっていると感じ  
ている従業員は、公平な報酬を得ている従業員と比較して生産量を低く抑え、品  
質を高くするよう努力する。  
  
エ  目標設定理論によると、従業員が目標の設定に参加した場合のほうが、目標が  
与えられた場合と比べ、高い業績を達成すると考えられる。  
  
オ  目標設定理論によると、従業員により困難な目標を与えたほうが、高い業績を  
生むと期待される。  
`
        },
        {
                "mondaiId": 103190180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
企業組織を、組織それ自体の構造や管理・コントロールに責任を持つ「経営管理  
コア（administrativecore）」と、原材料等を製品やサービスに変換するビジネスプ  
ロセスに責任を負う「技術コア（technicalcore）」という\u0001つのコアからなるものと  
考えることができる。組織階層上は、経営管理コアは技術コアの上位に位置する。  
このとき、組織構造や経営戦略の変革に関する記述として最も適切なものはどれ  
か。  
  
ア  主に技術革新によって成長してきた企業が、事業を再構築したり報酬システム  
を変更する場合には、経営管理コアから中央集権的に変革をリードする必要があ  
る。  
  
イ  技術革新や生産工程の変革などは、技術コアを中心とした、機械的な組織構造  
を通じて促進される傾向にある。  
  
ウ  経営管理コアのイノベーションを頻繁に行う必要のある組織は、技術コアを有  
機的な組織に維持しておき、ボトムアップで変革を行う必要がある。  
  
エ  戦略の変更や組織のダウンサイジングなど重要な意思決定は、分権化された経  
営管理コアが有機的な組織として主導する必要がある。  
  
オ  ボトムアップ形式で技術革新を行ってきた企業が危機に直面した場合、技術コ  
アを中心とした有機的な組織が中心となって経営管理コアの変革を促進する必要  
がある。  
`
        },
        {
                "mondaiId": 103190191,
                "name": "第19問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
次の文章を読んで、下記の設問に答えよ。  
A社は産業用中間財部品を生産する中規模の企業で、機能部門別組織構造を採用  
している。売上高・利益率ともに、\u0001年連続して低下してきたため、コンサルタン  
トに調査・分析を依頼した。コンサルタントのヒアリングに対し、A社の社長や各  
部長は次のように答えたという。  
社長：「年度計画や中期計画を策定することや財務管理の機能については  
私が責任を負っているが、技術革新の速度も比較的速く、顧客企業  
の要求もさまざまであるために、基本的に部長たちに事業に関する  
権限のほとんどは委譲している。各部門間の調整は、毎週の業務報  
告書と電話で行っているはずです。」  
研究開発部長：「わが社の技術力は比較的高く、技術者はそのことに誇りを持って  
いる。技術はある程度速く変化しており、開発した製品に自信はあ  
るが、最近営業部門から顧客のニーズに関する情報が入ってこなく  
なっている。業績悪化の原因は営業力の弱さにあるのではないだろ  
うか。」  
製造部長：「工場のものづくり能力は同業他社に比べて高いと思います。不良  
品の率も低いし、製造原価も最低限に抑えています。しかし、研究  
開発部門から提案される製品が、なかなかそのままでは量産できな  
かったりするので、しばしばこちらで若干のデザイン修正を行う必  
要があります。」  
営業部長：「営業部門の社員はよく働いています。お客様のニーズに合わせて  
勤務時間外の労働もいとわない者たちです。お客様には、わが社の  
製品は技術的には品質も優れているとは言われるのですが、価格が  
高すぎるところと、新製品の開発が他社に比べて若干遅くなる点が  
弱点ではないでしょうか。」  
        
（設問\u0001）  
このヒアリングから判断して、A社の組織をどのように分析するか。最も適切  
なものを選べ。  
  
ア  各部門に暗黙知を蓄積するメカニズムがないため、知識創造が適切に行われ  
ていない。  
  
イ  各部門のコスト意識が低いために、利益率が低下してきている。  
  
ウ  各部門の専門能力は高いものの、それが「訓練された無能（skilled  
incompetence）」につながり、シングルループ学習が促進される組織文化に  
なっている。  
  
エ  官僚制的組織文化が形成されてきており、部門間の壁が高くなってしまって  
いるため、部門間調整が十分にできていない。  
  
オ  研究開発部門や製造部門に比べて、営業部門の営業力が弱く、収益性の低下  
につながっている。`
        },
        {
                "mondaiId": 103190192,
                "name": "第19問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
このヒアリングからみて、A社の組織改善の方向性をどのように判断するか。  
最も適切なものを選べ。  
  
ア  各部門の情報共有を促進し、社長を含め部長たちが直接会合などで意見交換  
できる機会を増やす。  
  
イ  研究開発部門と製造部門の従業員を若干減らし、営業部門の人員を強化す  
る。  
  
ウ  事業部制組織を採用して、より分権化を促進し、PPMなどを通じて財務管  
理を強化する。  
  
エ  社長に権限の多くを集中し、中央集権的に部門間調整ができるようにする。  
  
オ  社内に電子メールシステムなどを導入し、直接会わなくても、情報の交換が  
できるようにする。  
`
        },
        {
                "mondaiId": 103190200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
個別労働関係紛争の解決の促進に関する法律が施行されているが、この法律の中  
で「あっせん」制度が紛争解決の方法として活用されている。その「あっせん」の申請  
に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  「あっせん」の申請が、個々の労働者に係る事項のみならず、これを超えて、事  
業所全体にわたる制度の創設、賃金額の増加等を求めるいわゆる利益紛争を目的  
としたものでなければならない。  
  
イ  「あっせん」の申請は、申請手続を代理人が行う場合を含め、紛争当事者本人の  
名義で行わなければならない。  
  
ウ  「あっせん」の申請は、紛争当事者である労働者及び事業主の双方、または一方  
のいずれからもすることができる。  
  
エ  「あっせん」の申請は、紛争当事者である労働者に係る事業場の所在地を管轄す  
る都道府県労働局長にしなければならない。`
        },
        {
                "mondaiId": 103190210,
                "name": "第21問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
労災保険で保護される通勤災害について、<u>最も不適切なものはどれか</u>。  
  
ア  一人の労働者が複数の事業場間を労働契約によって就業のため移動中の災害  
は、通勤災害である。  
  
イ  事業主の提供する専用バスによる通勤途上の災害は、通勤災害である。  
  
ウ  単身赴任の労働者が赴任先住居と帰省先住居（自宅）の間を移動中の災害は、通  
勤災害である。  
  
エ  労働者が就業に関し、住居と就業の場所との間を合理的な経路及び方法により  
往復すること（業務の性質を有するものを除く）による災害は、通勤災害である。  
`
        },
        {
                "mondaiId": 103190220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
高年齢者等の雇用の安定等に関する法律は、事業主に定年の引き上げ、継続雇用  
制度の導入等を義務付けているが、次の記述のうち、<u>最も不適切なものはどれか</u>。  
  
ア  継続雇用後の労働条件については、法律の趣旨を踏まえたものであれば最低賃  
金など雇用のルールの範囲内で、フルタイム、パートタイムなどの労働時間、賃  
金、待遇などに関して事業主と労働者の間で決めることができる。  
  
イ  継続雇用を希望する者について、定年後に子会社やグループ会社へ転籍させ、  
６５歳まで雇用が確保されれば、この法律の措置を講じたものとみてよい。ただ  
し、両社間に明確な支配力があり、常時採用・配転等の人事管理が行われている  
ことが必要である。  
  
ウ  この法律は男性の年金支給開始年齢の引き上げスケジュールに合わせた無年金  
者対策を目的としているので、男女の年金の支給開始年齢に合わせて男女別定年  
制を設けてもよい。  
  
エ  当分の間６０歳に達する労働者がいない企業でも、定年の引き上げ、継続雇用  
制度の導入等の措置は講じておかなければならない。`
        },
        {
                "mondaiId": 103190230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
時間外労働に関する労使間の協定（３６協定）で定める労働時間の延長の限度に関  
する基準として、<u>最も不適切なものはどれか</u>（変形労働時間を除く）。  
  
ア  \u0001週間１５時間  
  
イ  \u0002週間２７時間  
  
ウ  \u0003か月１２０時間  
  
エ  \u0004か月２００時間  
  
オ  \u0001年間３６０時間  
`
        },
        {
                "mondaiId": 103190240,
                "name": "第24問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
変形労働時間制に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  \u0001か月以内の一定期間について平均し、原則として週の法定労働時間４０時間  
を超えない範囲で、\u0001日または週の法定労働時間を超えて労働させることができ  
る。  
  
イ  \u0001か月を超え\u0001年以内の一定期間、平均して週４０時間を超えない範囲で、\u0001  
日または週の法定労働時間を超えて労働させることができる。  
  
ウ  労働者が１０人未満の商業、映画演劇（映画製作は除く）、保健衛生、接客娯楽  
業の場合は、週の法定労働時間は４４時間である。  
  
エ  労働者が３０人未満の小売業、旅館、料理店及び飲食店の場合は、週の労働時  
間が４０時間になっていれば、\u0001日について１２時間まで労働させることができ  
る。  
`
        },
        {
                "mondaiId": 103190250,
                "name": "第25問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
消費財メーカーのA社は、先発メーカーとして新たな市場を創造するような新製  
品開発を行ってきている。A社は、こうした新製品に付けるブランド・ネームにつ  
いて、消費者が���れに接したとき、今までに同様の製品がなくても、当該製品の用  
途やその製品で解決できる生活上の問題点を正しく推測できるように注意を払って  
いる。この方針のもつ特徴として、<u>最も不適切なものはどれか</u>。  
  
ア  A社が日本で成功した製品を海外に向けて展開するときには、相手先の言語に  
合わせてネーミングを改変することになり、世界商品となりにくいこともある。  
  
イ  A社の方針のようなブランド・ネームの付け方は、その製品が市場に浸透する  
と、他の製品カテゴリーに当該ブランドの拡張を容易にする効果をもつ。  
  
ウ  この方針は、小売店にとっては新市場に接することを意味し、カテゴリーの判  
断が難しいことから、A社製品の取り扱いをためらう小売店もある。  
  
エ  他社がA社製品を模倣したものを後発として市場に出す場合に、両者の違いを  
消費者が判断しにくいこともある。  
  
オ  ブランド・ネームの付け方が、当該製品の用途や新しさについての消費者の理  
解に大きな影響を与える。  
`
        },
        {
                "mondaiId": 103190260,
                "name": "第26問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
飲料メーカーB社は、高機能性の清涼飲料水の新製品を市場に導入する際に、流  
通チャネルにコンビニエンスストアだけを選択して、慣習価格よりも高い価格を設  
定した。このような方策を採用した理由として最も適切なものはどれか。  
  
ア  コンビニエンスストアでは、値引き販売が通常行われ、製品イメージの低下が  
懸念されるものの、販売量を増やすことが可能となる。  
  
イ  コンビニエンスストアでは、弁当類と飲料とがセットで購入されることも多  
く、その場合、飲料のもつ高機能性を印象づけることが可能となる。  
  
ウ  コンビニエンスストアは、家族需要に対応しており、家庭内に買い置きしてお  
く購買行動に適している。  
  
エ  コンビニエンスストアは、個人だけではなく、法人などによる贈答用の需要に  
も適している。  
  
オ  コンビニエンスストアは、この商品のもつ高機能性を、店員が豊富な情報量で  
具体的に訴求するのに適している。  
`
        },
        {
                "mondaiId": 103190270,
                "name": "第27問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
マーケティングにおいては、消費者の購買そのものだけではなく、購買後の消費  
者の状況なども重要となってくる。これに関して、<u>最も不適切なものはどれか</u>。  
  
ア  購買後に高い満足を示した消費者に、その製品の広告の推奨者として登場して  
もらうことは有効である。  
  
イ  購買後に容器などを廃棄する段階での消費者の環境意識を重視する必要があ  
る。  
  
ウ  購買後の評価は、ブログなどインターネットによって、従来以上に広範囲に伝  
達される傾向にある。  
  
エ  購買後の満足度を高めるために、広告などを活用して事前の期待を大きく高め  
ておく必要がある。  
  
オ  消費者は、購買後に、企業の想定しない利用方法や用途を発見することがある  
ので、企業はそれに耳を傾ける体制をとる必要がある。`
        },
        {
                "mondaiId": 103190280,
                "name": "第28問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
サービスの満足保証制度が販売促進策として有効に機能する場合に関する説明と  
して、<u>最も不適切なものはどれか</u>。  
  
ア  安価なサービスよりも、高価なサービスの場合  
  
イ  顧客がその種のサービスに関する知識を多く有している場合  
  
ウ  顧客にとって購買リスクが高い場合  
  
エ  新規顧客の獲得が難しい場合  
  
オ  ネットを含めて、口コミによる評判が需要に影響する場合  
`
        },
        {
                "mondaiId": 103190290,
                "name": "第29問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
大多数の商品は、工場で生産されたときの姿のままでは流通することが困難であ  
り、さまざまな包装が施される。包装に関して、<u>最も不適切なものはどれか</u>。  
  
ア  外装には、荷印など物流用の情報を組み入れることが重要である。  
  
イ  内装は、個々の商品に施された包装であり、特にデザインが重視されている。  
  
ウ  包装することによって、機械による商品の取り扱いを容易にすることができ  
る。  
  
エ  包装することによって、商品の魅力をより高めることができる。  
  
オ  包装することによって、商品を熱や衝撃から保護することができる。`
        },
        {
                "mondaiId": 103190300,
                "name": "第30問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
航空会社は、搭乗距離によるフリークエント・フライヤーズ・プログラム（FFP）  
を取り入れている。それと類似の、購買金額によるポイント制度（たとえばフリー  
クエント・ショッパーズ・プログラム）が小売業においても、多く採用されてい  
る。これに関して、<u>最も不適切なものはどれか</u>。  
  
ア  クレジットカード利用客に向けて、ポイント付与率を現金客より低く設定する  
ことによって、両者の販売にかかるコストの差を縮小できる。  
  
イ  小売店にとってポイント制度は競争手段であり、他社によって模倣されにく  
く、競争優位を維持することができる。  
  
ウ  顧客の実質的支払金額は低くなっても、販売価格それ自体は大幅な値引きをし  
ていないことになる。  
  
エ  他店とポイント連携をすると、新規顧客の獲得に有利に働く一方、自店のポイ  
ントを他店で活用され、自店の売上増加につながるとは限らない。  
  
オ  販売価格そのものの割り引きは、顧客の固定化に結び付きにくいが、これに対  
し、ポイント制度は次回の来店を促し、顧客の固定化に結び付けることができ  
る。  
`
        },
        {
                "mondaiId": 103190310,
                "name": "第31問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
新製品開発のプロセスにおいて、発売前に、新製品を実際に市場に導入してテス  
トを行うことがある。このテストマーケティングに関して最も適切なものはどれ  
か。  
  
ア  テスト市場において、試用購買率が高くて反復購入率が低ければプロモーショ  
ン不足なので、本格的市場導入時にはプロモーション予算を増額する。  
  
イ  テストマーケティングのときに、複数のプロモーション手段を試行し、その成  
果を比較して、最善のものを本格的市場導入時に採用するのが望ましい。  
  
ウ  テストマーケティングを行うと、他社に自社の新製品に関する情報が漏れるの  
で、重要な商品属性を意図的に改変してテストするのが望ましい。  
  
エ  テストマーケティングを行う都市は、都市による偏りをなくすために、無作為  
抽出で選定するのが望ましい。`
        },
        {
                "mondaiId": 103190320,
                "name": "第32問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
消費者行動を規定する要素の一つとして、準拠集団がある。これに関連する記述  
として、<u>最も不適切なものはどれか</u>。  
  
ア  あこがれの有名人が持っているものと同じものを、自分も所有したいと思う。  
  
イ  ある集団は、服装やマナーなど基本的な価値観を形成するうえで大きな影響を  
与える。  
  
ウ  購買において、ある集団を模範にしたくないと思う。  
  
エ  他人の目に触れないような商品のブランド選択行動について、準拠集団が与え  
る影響は大きい。  
  
オ  パーティー販売においては、\u0001人だけ買わないとバツが悪く感じて、つい買っ  
てしまう。  
`
        },
        {
                "mondaiId": 103190330,
                "name": "第33問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３３問  
生鮮食品においてもブランドを付けることが進みつつある。これに関して、最も  
不適切なものはどれか。  
  
ア  生鮮食品には、流通業者はブランドを付与できず、生産者だけがブランドを付  
与できる。  
  
イ  生鮮食品にブランドを付与し、それが浸透すると、より高価な価格設定が可能  
となる。  
  
ウ  生鮮食品の生産方法などが知的所有権などで保護されなくても、ブランド名だ  
けで独自性を維持することができる場合もある。  
  
エ  ブランドが確立すると、認知度が高いゆえに特売の対象とされやすく、ブラン  
ドイメージが低下する場合がある。  
  
オ  ブランドが浸透すると、ブランドがないものは、品質が同等であっても、消費  
者に選択されなくなる恐れがある。`
        },
        {
                "mondaiId": 103190340,
                "name": "第34問",
                "seikai": "オ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３４問  
有料で販売される印刷媒体に加えて、無料で配布される印刷媒体が多数発行され  
ている。この無料印刷媒体に関して、<u>最も不適切なものはどれか</u>。  
  
ア  この媒体の広告主は、有料印刷媒体を購入しない層をターゲットにすることが  
できる。  
  
イ  この媒体への接触をきっかけとして、有料媒体へ誘引することも可能である。  
  
ウ  地域限定版の媒体は、その地域のクーポン情報が入手できるために、読者の期  
待が高い。  
  
エ  媒体としての信頼を得ることができると、メディアミックスが有効に作用す  
る。  
  
オ  配布場所を頻繁に変えることによって、読者に有効に配布することができる。  
`
        },
        {
                "mondaiId": 103190350,
                "name": "第35問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３５問  
日本の大手小売チェーンは卸売業者を活用して流通活動を展開していることが多  
い。これに関して、<u>最も不適切なものはどれか</u>。  
  
ア  卸売業者が運営する特定の小売業者のための専用物流センターについては、小  
売業者が卸売業者に物流センターフィーを支払う。  
  
イ  共同配送システムを構築したとき、物流は専門のベンダーが担うが、商流は既  
存の卸売業者が担うこともある。  
  
ウ  小売業者が従来行っていた小分け業務を、卸売業者に物流活動の一貫として  
担ってもらうことができる。  
  
エ  仕入先卸売業者同士を競争させることなどによって、価格のメリットを得るこ  
とができる。  
  
オ  本来小売業者が行うデータ分析や棚割管理などを、卸売業者に担ってもらうこ  
とができる。`
        },
        {
                "mondaiId": 103190360,
                "name": "第36問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３６問  
ある製品カテゴリーにおいて、市場開拓に最初に成功したブランドは競争優位を  
確保することができる。これに関して、<u>最も不適切なものはどれか</u>。  
  
ア  このブランドから他へブランドスイッチすると、すでに購入した関連商品を改  
めて買い直すこともある。  
  
イ  このブランドでその製品カテゴリーを初めて知った場合には、そのブランドの  
属性が製品選択の基準を形成しやすい。  
  
ウ  このブランドの使用体験や消費体験によって、そのブランドへのロイヤルティ  
が形成されやすい。  
  
エ  このブランドの持つ属性と類似の属性を持つ他社製品に接すると、その他社製  
品は改良された優良なものであると知覚されやすい。  
  
オ  このブランドは消費者に長期間露出されることになるので、想起集合に入りや  
すい。  
`
        },
        {
                "mondaiId": 103190370,
                "name": "第37問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３７問  
産業需要家に向けて人的販売を行う場合の特徴として、最も不適切なものはどれ  
か。  
  
ア  顧客のニーズを把握して、その解決方法を提案することが有効である。  
  
イ  販売員は、交渉相手から反論があったときは、それが正当でない場合、その旨  
を伝えることが必要である。  
  
ウ  販売員は、交渉のなるべく早い段階で、発注を求めることが必要である。  
  
エ  販売の交渉においては、当事者間での利害関係を認識することが重要である。  
  
オ  見込顧客の開拓のためには、既存顧客に潜在顧客を紹介してもらうのもよい。`
        },
        {
                "mondaiId": 103190380,
                "name": "第38問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 103,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３８問  
インターネットを利用して広告を行う方法は、急速にその種類を増してきてい  
る。そのなかで、検索画面において、キーワードを入力すると、そのキーワードを  
事前に登録した企業等の広告がWeb画面上に掲出される方式がある。これに関し  
て、<u>最も不適切なものはどれか</u>。  
  
ア  キーワードおよびその関連語の並び順によって効果が異なることも多い。  
  
イ  キーワードの反応をみるための管理を的確に行うと、短時間でその効果を測定  
することもできる。  
  
ウ  検索結果と広告が同一の画面に表示されるので、広告主のサイトへ誘引されや  
すい。  
  
エ  この方式の広告は、主要な検索ページでは固定費が高く、主に大企業が出稿し  
ている。  
  
オ  知りたいと思ったときに、そのことに関連する情報を提供してくれる広告に接  
触することが容易となる。  
`
        },
        {
                "mondaiId": 104190010,
                "name": "第1問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　管理目標に関する記述として、最も適切なものはどれか。  
  
ア  稼働率とは、人または機械における就業時間もしくは拘束時間に対する<hintTarget>稼働可能な</hintTarget>時間との比率のことである。  
  
<hint hidden='true'>**
❌稼働可能な時間でなく、有効稼働時間（実際に稼働した時間）です。公式は、  
稼働率＝<hintTarget>有効稼働時間／（就業時間もしくは拘束時間）</hintTarget>
**</hint>

イ  仕事量とは、仕事を遂行するために必要な<hintTarget>単位時間あたりの仕事の負荷量</hintTarget>のことである。  
  
<hint hidden='true'>**
❌仕事を遂行するために、必要な仕事の密度と時間の積です。
**</hint>

ウ  付加価値とは、製品またはサービスの価値のなかで、<hintTarget>顧客にとって有効な</hintTarget>価値のことである。  
  
<hint hidden='true'>**
❌製品又はサービスの価値のなかで，自己の企業活動の結果として新た
に付与された価値です。
**</hint>

エ  リードタイムとは、発注してから納入されるまでの時間、あるいは、素材が準備されてから完成品になるまでの時間のことである。

<hint hidden='true'>**
⭕適切な記述です。前半が<hintTarget>発注</hintTarget>リードタイム、後者が<hintTarget>生産</hintTarget>リードタイムです。
**</hint>  

`
        },
        {
                "mondaiId": 104190020,
                "name": "第2問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
システマティック・レイアウト・プランニングに関する分析として、最も不適切  
なものはどれか。  
  
ア  P―Q分析  
  
イ  アクティビティ相互関係の分析  
  
ウ  基準日程の分析  
  
エ  物の流れの分析  
`
        },
        {
                "mondaiId": 104190030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
製品開発に関する記述として、最も適切なものはどれか。  
  
ア  機能設計は、製品企画段階で行われる活動である。  
  
イ  組立図は、構成部品とそれらの関連の状態を表現した図である。  
  
ウ  製品開発には一般に、製品企画、製品設計、生産設計、生産の立ち上げに関す  
る活動、生産が開始されてからの生産量の変動への対応が含まれる。  
  
エ  製品寿命とは、一つの製品の企画・設計段階から、製品が完成するまでの期間  
をいう。`
        },
        {
                "mondaiId": 104190040,
                "name": "第4問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータによる設計生産支援システムに関する次の文中の空欄Ａ～Ｃに入  
る、最も適切な用語の組み合わせを下記の解答群から選べ。  
Ａは、製品の形状などのデータから構成されるモデルを、コンピュータ  
の内部に作成し、解析することにより進められる。Ｂは、Ａで作  
成されたデータをもとに、生産に必要な情報を生成し、生産する方式である。  
Ｃは、それらの情報を統合的に処理し、製品品質、製造工程などを解析評  
価する方式である。  
〔解答群〕  
  
ア  Ａ：CADＢ：CAMＣ：CAE  
  
イ  Ａ：CADＢ：CAMＣ：CIM  
  
ウ  Ａ：CAMＢ：CADＣ：CAE  
  
エ  Ａ：CAMＢ：CADＣ：CIM  
`
        },
        {
                "mondaiId": 104190050,
                "name": "第5問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
職務設計に関する記述として、最も適切なものはどれか。  
  
ア  受け持ちの仕事の範囲を広げたり、仕事の種類を多様化することは、職務転換  
（jobrotation）にあたる。  
  
イ  同じ職場のメンバー間での応援や、協力、交替ができるようにすることは、集  
団作業化（grouping）にあたる。  
  
ウ  質的に高度の仕事を与えたり、工程の設計や改善ができるようにすることは、  
職務拡大（jobenlargement）にあたる。  
  
エ  生産状況に応じて受け持ちの仕事を変えることは、職務充実（jobenrichment）  
にあたる。`
        },
        {
                "mondaiId": 104190060,
                "name": "第6問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次に示す作業者の動きを、作業者工程分析により、分析記号〇、\u0001、  
\u0001  
、\u0002を用  
いて分析した。〇記号の数として、最も適切なものはどれか。  
〔作業者の動き〕  
\u0003部品を取りに部品棚まで移動する。  
\u0004部品棚にある部品箱を開け、部品を取り出す。  
\u0005部品を持って作業ステーションへ移動する。  
\u0006部品の長さと直径の検査をする。  
\u0007部品を中間製品へ取り付ける。  
3部品をネジで中間製品へ固定する。  
ネジに塗料を塗る。  
        
塗料が乾くまで待つ。  
\u000b塗料が乾いたことを確認する。  
\f中間製品を次の作業ステーションへ運ぶ。  
  
ア  \u0002個イ\u0003個ウ\u0004個エ\u0005個  
`
        },
        {
                "mondaiId": 104190070,
                "name": "第7問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
作業現場の管理における\u0001Ｓに関する記述として、最も適切なものはどれか。  
  
ア  「気がついたときに、機械についている汚れを拭いている」、これは、「清潔」で  
ある。  
  
イ  「捨ててよいものと保管しておくものの区別をするために、ルールを作り守る  
ようにした」、これは、「しつけ（躾）」である。  
  
ウ  「机の上にあった書類の中でいらない物を廃棄した」、これは、「整頓」である。  
  
エ  「机の上の文房具を取りやすいように並べて立てておくことにした」、これは、  
「整理」である。`
        },
        {
                "mondaiId": 104190080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
設備総合効率を求めるときに必要な項目として、<u>最も不適切なものはどれか</u>。  
  
ア  時間稼働率  
  
イ  性能稼働率  
  
ウ  付加価値率  
  
エ  良品率  
`
        },
        {
                "mondaiId": 104190090,
                "name": "第9問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
作業研究において、次の図の網掛けで示される作業領域の名称として、最も適切  
なものはどれか。  
  
ア  最大作業域  
  
イ  座り作業可能域  
  
ウ  正常作業域  
  
エ  標準作業域`
        },
        {
                "mondaiId": 104190100,
                "name": "第10問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
環境ラベル及び宣言－自己宣言による環境主張（JIS―Q―１４０２１、ISO１４０２１）に関す  
る記述として、<u>最も不適切なものはどれか</u>。  
  
ア  「環境に安全」、「環境に優しい」という「環境主張」は、許されている。  
  
イ  この規格で規定しているシンボルは、三角形を形成する互いに追いかける三つ  
の曲がった矢の形をしたメビウスループである。  
  
ウ  製品、包装またはそれらの構成要素が、生分解して比較的均質で安定な腐植質  
の物質を生成することをコンポスト化可能という。  
  
エ  リサイクル材料含有率（％）は、（リサイクル材料の質量／製品の質量）×１００に  
より算出される。  
`
        },
        {
                "mondaiId": 104190110,
                "name": "第11問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
品質検査の目的として、<u>最も不適切なものはどれか</u>。  
  
ア  検査によって得られた品質情報を関係部門に提供し、不良品や不良ロットの発  
生の予防に役立てること。  
  
イ  納入された品物について、要求された数量の存在を確認すること。  
  
ウ  納入者に対して良い品物を提供しようとする意欲をもたせること。  
  
エ  不良品や不良ロットが次工程や顧客、消費者に引き渡されることがないように  
保証すること。`
        },
        {
                "mondaiId": 104190120,
                "name": "第12問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
工程計画は、製品設計が完了した後、製品の技術的、方法的および空間的な変換  
過程を計画することである。工程計画の手順として、最も適切なものはどれか。  
  
ア  工程設計→工程レイアウト設計→工程間の物流設計→作業設計  
  
イ  工程設計→工程レイアウト設計→作業設計→工程間の物流設計  
  
ウ  工程設計→作業設計→工程間の物流設計→工程レイアウト設計  
  
エ  工程設計→作業設計→工程レイアウト設計→工程間の物流設計  
`
        },
        {
                "mondaiId": 104190130,
                "name": "第13問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
購買管理に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  購買計画は、購買方針、生産計画に基づいて、購入する品目、数量、納期、予  
算などを決める活動である。  
  
イ  購買は、生産または営業に必要な設備、原材料、部品、消耗品などを購入する  
活動である。  
  
ウ  購買費用は、資材等の購入のために発生する費用をいい、人件費、運搬費、受  
け入れ検査費などが含まれる。  
  
エ  コック倉庫方式は、毎月継続するような標準品などを購買先の倉庫に預け、納  
入すると同時に買い付けをしたとする購買方式である。`
        },
        {
                "mondaiId": 104190140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
\u0001つの工程が直列に連結された生産ラインにおいて、\u0002種類の製品A、B、Cを  
投入順序［A、B、C］でサイクリックに連続生産している。ただし、工程間に在庫  
スペースはなく、第\u0003工程で作業を完了したものが第\u0001工程に引き取られるまで、第\u0003工程では次の作業を開始することができないものとする。  
次の表に示される作業時間に対して、\u0003サイクルの時間として、最も適切なもの  
はどれか。  
第\u0003工程第\u0001工程  
製品A１０\u0004  
製品B\u0002\u0001  
製品C\u0005\u0002  
  
ア  ２１イ２２ウ２３エ２４  
`
        },
        {
                "mondaiId": 104190150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
高分子材料に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  液晶ポリマーは、高分子物質で液晶性を示すものである。  
  
イ  エンジニアリングプラスチックは、力学的強度が高く、耐熱性、耐摩耗性など  
に優れ、金属に代替して使用されるプラスチックである。  
  
ウ  軽くて強く、剛性にも優れた材料として開発された繊維強化プラスチックは、  
スーパーエンプラと呼ばれる。  
  
エ  生分解性プラスチックは、天然素材と同様に自然界の微生物の作用により分解  
する。`
        },
        {
                "mondaiId": 104190160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
日々変動する需要に対して定量発注方式を用いる在庫管理に関する記述として、  
最も適切なものはどれか。  
  
ア  一定の���注点と発注量に対し、需要量変動が増加すると、年間の品切れ量は、  
減少する。  
  
イ  一定の発注点と発注量に対し、納入リードタイムが短くなると、年間の品切れ  
量は、増加する。  
  
ウ  一定の発注点に対し、発注量を増加させると、年間の品切れ量は、減少する。  
  
エ  一定の発注量に対し、発注点を高くすると、年間の品切れ量は、増加する。  
`
        },
        {
                "mondaiId": 104190170,
                "name": "第17問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
PERTでは、アクティビティ間に定められた遂行順序に従ってアクティビティを  
連結し、一つのアローダイアグラムを作成する。  
次の図の結合点\u0001から結合点１０へのアクティビティ（\u0001，１０）の最早開始時刻と  
して、最も適切なものはどれか。なお、円内の数値は結合点の番号を、結合点i，  
j間の矢線上の数値はアクティビティ（i，j）の所要時間を表している。  
  
ア  ２５イ２６ウ２７エ２８`
        },
        {
                "mondaiId": 104190180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
傾向変動のある需要系列の予測モデルとして、<u>最も不適切なものはどれか</u>。  
  
ア  自己回帰モデル  
  
イ  指数関数モデル  
  
ウ  多項式関数モデル  
  
エ  直線モデル  
`
        },
        {
                "mondaiId": 104190190,
                "name": "第19問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
加工と評価に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  加工温度は、加工プロセスの重要な評価項目である。  
  
イ  加工によって生じる副産物の状態とその処理性の良否は、加工における評価対  
象となる。  
  
ウ  長さ、重さ、時間、温度のうち、現在、最も正確に測定できるのは、長さであ  
る。  
  
エ  マイクロメータは、工作物の寸法を測定するのに使用される。`
        },
        {
                "mondaiId": 104190200,
                "name": "第20問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
セル生産方式に関する記述として、最も適切なものはどれか。  
  
ア  セル型レイアウトでは、GTの原理を適用して類似工作物がグループ化され  
る。  
  
イ  セル型レイアウトでは、類似機械がまとめて配置される。  
  
ウ  セル生産方式は、多能工を必要としない生産方式である。  
  
エ  セル生産方式は、高価な設備が必要な場合に適した生産方式である。  
`
        },
        {
                "mondaiId": 104190211,
                "name": "第21問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
次に示す建築基準法第\u0001条（用語の定義）について、下記の設問に答えよ。  
延焼のおそれのある部分とは、隣地境界線、道路中心線又は同一敷地内の  
Ａ以上の建築物（延べ面積の合計がＢm２以内の建築物は、\u0002の建  
築物とみなす。）相互の外壁間の中心線から、Ｃ階にあってはＤm  
以下、Ｅ階以上にあってはＦm以下の距離にある建築物の部分  
をいう。  
（設問1））  
文中の空欄ＡとＢに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：\u0001Ｂ：１００イＡ：\u0001Ｂ：５００  
  
ウ  Ａ：\u0003Ｂ：２００エＡ：\u0003Ｂ：４００`
        },
        {
                "mondaiId": 104190212,
                "name": "第21問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の空欄ＣとＤに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ｃ：\u0002Ｄ：\u0003イＣ：\u0002Ｄ：\u0004  
  
ウ  Ｃ：\u0001Ｄ：\u0003エＣ：\u0001Ｄ：\u0004`
        },
        {
                "mondaiId": 104190213,
                "name": "第21問設問3",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄ＥとＦに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ｅ：\u0001Ｆ：\u0005イＥ：\u0001Ｆ：１０  
  
ウ  Ｅ：\u0003Ｆ：\u0005エＥ：\u0003Ｆ：１０  
`
        },
        {
                "mondaiId": 104190220,
                "name": "第22問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
店舗レイアウトの考え方に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  一般的に、売場の括り（くくり）は、以前は「売る立場」から考えられたものが、  
近年では「買う立場」からも考えられるように変化してきている。  
  
イ  一般的に、すべての階の実際の通行量をそのまま比較することによって、どの  
階のレイアウトがよいかを判断できる。  
  
ウ  客に通路上をできる限り長い距離を歩いてもらえるように、通路は売場内の隅  
から隅へと、最初に大回りしてもらえるように設定することが一般的である。  
  
エ  客にとって商品が見やすく、さわりやすいように、通路が適切に設計されてい  
ることが大切である。`
        },
        {
                "mondaiId": 104190230,
                "name": "第23問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
商業施設の一般的な基本計画に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  一般的に、商業施設の駐車場の規模設定は、売場面積に相応した駐車台数を計  
画することが望ましい。駐車台数が少ない場合は、使いやすさと駐車場の回転率  
を高めるような対応も必要となる。  
  
イ  一般的に、商業施設の配置には、敷地の形状・接道状況・施設の規模等を総合  
的に検討した上で、集客を第一とする考えから、客に分かりやすく安全にアプ  
ローチしやすい計画が望まれる。  
  
ウ  一般的に、商業施設の「バックヤードと共用部」には、荷さばき等の商品管理部  
門、事務室等の事務管理部門、および食堂・ロッカールーム等の従業員厚生部門  
の諸室が含まれる。  
  
エ  商業施設の床面積を「売場」と「バックヤードと共用部」とに配分するとき、その  
割合は、スーパーマーケットや百貨店および集合専門店に共通して５０：５０にす  
ることが一般的だとされている。  
`
        },
        {
                "mondaiId": 104190240,
                "name": "第24問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
小売店舗におけるLSP（LaborSchedulingProgram）に関する記述として、最も不  
適切なものはどれか。  
  
ア  LSPの対象は、固定作業と変動作業の両方を含んでいる。  
  
イ  LSPはコスト管理が目的であり、販売・仕入計画とは連動しなくともよい。  
  
ウ  LSPは人件費の削減のみならず、顧客サービスの改善も期待できる。  
  
エ  LSPを導入するには、店内作業時間の計測が必要である。  
  
オ  LSPを導入するには、店内作業内容の標準化が必要である。`
        },
        {
                "mondaiId": 104190250,
                "name": "第25問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
ある小売店が定期発注方式を採用している。この小売店の店頭在庫管理政策に関  
して、<u>最も不適切なものはどれか</u>。  
  
ア  店頭在庫を圧縮するために、発注間隔を短くし、発注頻度を増やした。  
  
イ  店頭品切れを防止するため、POSデータに基づき販売予測の精度を上げた。  
  
ウ  店頭品切れを防止するため、発注から納品までのリードタイムを短くした。  
  
エ  店頭品切れを防止するため、フェイス数を削減した。  
  
オ  ベンダーの欠品を防止するため、ベンダーとPOSデータや在庫データを共有  
した。  
`
        },
        {
                "mondaiId": 104190260,
                "name": "第26問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
小売店の品揃えを診断する方法として、自店のPOSデータによるABC分析結果  
と、市場（もしくは他店）のPOSデータによるABC分析の結果を比較する方法があ  
る。この品揃え診断技法に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  市場POSデータでAランクであっても、自店POSデータでCランクのアイテ  
ムはカット候補とする。  
  
イ  自店POSデータでAランク、市場POSデータでもAランクのアイテムは取扱  
継続候補とする。  
  
ウ  自店POSデータのABC分析のランク付け基準と、市場POSデータのABCの  
分析ランク付け基準は同一とする。  
  
エ  自店POSデータの実績がないが、市場POSデータでAランクのアイテムは新  
規取扱候補とする。  
  
オ  ローカルブランド商品やプライベートブランド商品は、評価対象となりにく  
い。`
        },
        {
                "mondaiId": 104190270,
                "name": "第27問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
小売業の発注残高統制手法としてのOTB（OpenToBuy）に関する記述につい  
て、<u>最も不適切なものはどれか</u>。  
  
ア  売上高予算が増加すると、OTBは増加する。  
  
イ  期末在庫高予算が減少すると、OTBは増加する。  
  
ウ  仕入先への返品が減少すると、OTBは減少する。  
  
エ  手持ち在庫高が増加すると、OTBは減少する。  
`
        },
        {
                "mondaiId": 104190280,
                "name": "第28問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
流通業の物流センターにおけるABC（ActivityBasedCosting）導入に関する記述  
として、<u>最も不適切なものはどれか</u>。  
  
ア  商品別の費用分析には利用できるが、配送先店舗別の費用分析には利用できな  
い。  
  
イ  荷受け、ピッキング等の作業ごとに発生費用を把握することができる。  
  
ウ  物流サービスの内容に応じた価格設定が可能となる。  
  
エ  物流センターにおける活動を定義し、処理量や作業時間を測定する必要があ  
る。  
`
        },
        {
                "mondaiId": 104190291,
                "name": "第29問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
ある小売店の平成１８年度の営業実績は次の通りであった。下記の設問に答え  
よ。  
売上高１，０００百万円  
仕入高５００百万円  
期首棚卸高（原価）１００百万円  
期末棚卸高（原価）１５０百万円  
（設問\u0001）  
この小売店の平成１８年度の粗利益率を求めよ。  
  
ア  ４５．０％イ５０．０％ウ５５．０％  
  
エ  ６０．０％オ６５．０％`
        },
        {
                "mondaiId": 104190292,
                "name": "第29問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
この小売店の平成１８年度の月平均商品回転率を求めよ（原価ベースで計算する  
こと）。  
  
ア  ０．２５０イ０．２７８ウ０．３００  
  
エ  ０．３０６オ０．３７５`
        },
        {
                "mondaiId": 104190293,
                "name": "第29問設問3",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
この小売店の平成１８年度のGMROIを求めよ。  
  
ア  ３３３％イ３６７％ウ４００％  
  
エ  ４４０％オ５５０％  
`
        },
        {
                "mondaiId": 104190300,
                "name": "第30問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
最寄り品における消費者の店舗内購買行動に関して、最も不適切なものはどれ  
か。  
  
ア  限られた情報で、短時間に意思決定がなされる。  
  
イ  購買直後でも、購買商品に関する記憶はあいまいである。  
  
ウ  購買率の高いカテゴリーほど、売場内滞在時間が長い。  
  
エ  商品購買において、複雑な情報処理を行っているわけではない。`
        },
        {
                "mondaiId": 104190310,
                "name": "第31問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３１問  
  
イ  ンストア・マーチャンダイジング（以下、「ISM」という。）は、店頭における価  
値工学ととらえられている。ISMと、具体的なインストア・プロモーション（以  
下、「ISP」という。）に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  ISMにおいては、顧客の計画購買の増加を主眼としている。  
  
イ  ISP手法としてのノベルティは、非価格主導型の手法である。  
  
ウ  ISPの対象は、消費者の購買行動分析などを踏まえて選定することがよい。  
  
エ  ISPは、客単価増加に着目するとともに、\u0001人当たりの買い上げ個数増加を意  
図している。  
  
オ  視認率を高めるために、POPの効果活用およびカラーコーディネート技術の  
活用などに留意すべきである。  
`
        },
        {
                "mondaiId": 104190320,
                "name": "第32問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３２問  
消費財の価格弾力性に関する説明として、<u>最も不適切なものはどれか</u>。  
  
ア  価格弾力性とは、売価を一定割合変化させたときの販売数量の変化の割合をさ  
す。  
  
イ  価格弾力性の低い商品は、特売が売上金額の増加につながりにくい。  
  
ウ  価格弾力性の低い商品を値引きすることは、プロモーション手段として適して  
いる。  
  
エ  価格弾力性は、カテゴリーの特性やアイテムの商品力によって異なる。  
  
オ  非必需的なアイテムは、価格弾力性が高くなりやすい。`
        },
        {
                "mondaiId": 104190330,
                "name": "第33問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３３問  
小売業が商品を選定するには、販売面と利益面での期待がある。この中で、利益  
面での期待を高める上で必要となる情報に関する説明として、最も不適切なものは  
どれか。  
  
ア  競合他店の販売数量実績。  
  
イ  生鮮品や日配品における商品ロス率。  
  
ウ  当該ブランドが属する市場における価格競争の程度。  
  
エ  販売や商品補充に必要な人手や陳列に要する面積などの販売経費の見通し。  
  
オ  リベートや販売促進費などを加えた取引条件。  
`
        },
        {
                "mondaiId": 104190340,
                "name": "第34問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３４問  
陳列に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  アイランド陳列を行う場合には、顧客の回遊性を損なわないよう留意すること  
が必要である。  
  
イ  ジャンブル陳列やアイランド陳列など、陳列に変化をつける方法を総称して変  
化陳列という。  
  
ウ  人体寸法に合わせて、人が無理なく商品を手にとれる高さのことを、ゴールデ  
ン・ゾーンという。  
  
エ  陳列棚の商品区分は、視野にかかわらず横割りが理想的である。  
  
オ  陳列には、商品を豊富に並べる量感陳列と、小物などと並べてテーマや季節感  
を演出する展示陳列がある。`
        },
        {
                "mondaiId": 104190350,
                "name": "第35問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３５問  
売場における有効な商品配置・売場配賦のために考慮する原則に関して、最も不  
適切なものはどれか。  
  
ア  売場における商品カテゴリー別の陳列量は、単位売場面積当たりの売上高が全  
売場について均等になるように決定することが目指される。  
  
イ  売場における商品カテゴリー別の陳列量は、単位売場面積当たりの投資額と、  
単品別の純利益をそれぞれ算定し、投資純利益率がすべての売場において均等に  
なることが目指される。  
  
ウ  関連購買される確率が高い商品を離して配置することで、店内回遊率を高める  
ことができる。  
  
エ  陳列のストーリー訴求やカラー・コンディショニングなどによって、陳列棚全  
体の視認性を高めることができる。  
`
        },
        {
                "mondaiId": 104190360,
                "name": "第36問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３６問  
POSシステム導入の直接的な効果として、<u>最も不適切なものはどれか</u>。  
  
ア  従業員による不正の防止  
  
イ  受発注作業の効率化  
  
ウ  精算時間短縮  
  
エ  伝票作業の軽減`
        },
        {
                "mondaiId": 104190370,
                "name": "第37問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３７問  
次のJANコードに関する記述について、空欄Ａ～Ｃに入る最も適切な用語の組  
み合わせを下記の解答群から選べ。  
製造業者において、製造・出荷時にＡにJANシンボルが印刷されるこ  
とが多い。これをＢと呼ぶ。これに対して、製造・出荷段階でJANシン  
ボルを印刷できない商品に、小売業者がJANシンボルを印刷することがある。こ  
れをＣと呼ぶ。  
〔解答群〕  
  
ア  Ａ：集合包装Ｂ：インストアマーキングＣ：ソースマーキング  
  
イ  Ａ：集合包装Ｂ：ソースマーキングＣ：インストアマーキング  
  
ウ  Ａ：商品包装Ｂ：インストアマーキングＣ：ソースマーキング  
  
エ  Ａ：商品包装Ｂ：ソースマーキングＣ：インストアマーキング  
`
        },
        {
                "mondaiId": 104190380,
                "name": "第38問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３８問  
製造業ないし卸売業と小売業の間での商品情報を共有するための基盤として期待  
されるGDS（GlobalDataSynchronization）に関する記述として、最も不適切なも  
のはどれか。  
  
ア  GDS普及の阻害要因の\u0001つとして、標準化された商品マスターが登録される  
データベースに接続するためのシステム投資が、新たに必要となる点が指摘され  
ている。  
  
イ  卸売業や小売業では、各社独自の商品マスター管理が不要になることから、コ  
ストダウン効果が期待されている。  
  
ウ  商品マスターの独自性が損なわれることから、小売業の競争力が損なわれる恐  
れが懸念されている。  
  
エ  標準化された商品マスターを一括管理するための費用負担をどうするかという  
課題を克服する必要がある。`
        },
        {
                "mondaiId": 104190390,
                "name": "第39問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３９問  
EDIを実施する上で注意すべき法律に関する記述として、最も不適切なものは  
どれか。  
  
ア  いわゆる「IT書面一括法」では、電子的手段による書面交付や手続きが認めら  
れたため、EDIの対象範囲が広がったと考えることができる。  
  
イ  いわゆる「個人情報保護法」では、EDIを通じて交換されるデータに契約担当  
者等の情報が含まれる場合、それらは個人情報となるため、それらの取り扱いに  
留意しなければならない。  
  
ウ  いわゆる「電子契約法」では、契約の成立時点を承諾通知が到達した時点と規定  
しているため、EDIの場合、郵送等の方法とは契約成立時期が異なることにな  
る。  
  
エ  いわゆる「電子帳簿保存法」では、自らが作成したデータのみを保存対象と規定  
しているため、相手先から受信したデータは保存しなくてよい。  
`
        },
        {
                "mondaiId": 104190400,
                "name": "第40問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４０問  
Web技術を利用したWeb―EDIに関する記述として、最も不適切なものはどれ  
か。  
  
ア  大量データの送受信では、従来のEDIの方が効率的であることが多い。  
  
イ  取引情報における意味情報が発注企業ごとに異なるため、社内システムとの連  
動が難しい。  
  
ウ  標準化が進んでいないために、取引先ごとに画面が異なる「多画面現象」が生じ  
ている。  
  
エ  ユーザーインターフェースの向上がみられたものの、送受信可能なデータ形式  
は従来のEDIと変わらない。`
        },
        {
                "mondaiId": 104190410,
                "name": "第41問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 104,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第４１問  
RFID（RadioFrequencyIdentification）などの電子タグに関する記述として、最  
も不適切なものはどれか。  
  
ア  EDIとの連携を展開していく場合、EDIメッセージ項目変更などが今後の課  
題となる。  
  
イ  事前出荷通知データと入荷製品とを自動照合するためには、少なくとも電子タ  
グ導入時にEDIを実現しておく必要がある。  
  
ウ  電子タグを導入する際には、取り込むデータ内容が業界ごとに標準化されてい  
るので、その標準に準拠する必要がある。  
  
エ  電子タグを利用することで、商品に接触することなく、商品情報を読み取るこ  
とができるため、作業の効率化が期待できる。  
`
        },
        {
                "mondaiId": 105190010,
                "name": "第1問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
入札談合に関する記述として最も適切なものはどれか。  
  
ア  入札談合がなされた場合でも、入札談合行為がなく適法に入札が行われたと仮  
定した場合に想定される落札価額が、入札談合行為に基づき行われた実際の落札  
価額を上回っていれば、違法とはならない。  
  
イ  入札談合等関与行為防止法では、公正取引委員会から、各省各庁の長等に対し  
て、入札談合等関与行為を排除するために必要な改善措置を要求できる制度な  
ど、入札談合防止のための特別な規定が置かれている。  
  
ウ  入札談合に参加した企業に対しては、独占禁止法では、課徴金を課すことがで  
きず、刑法の談合罪に該当した場合に限り、課徴金を課すことができる。  
  
エ  入札において、国や県などの公共団体の関与なく、入札参加予定企業だけで話  
し合いを行って、落札予定価額や落札予定企業を定めることは何の問題もなく、  
違法とはなりえない。  
`
        },
        {
                "mondaiId": 105190020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
以下の内容のA株式会社の現在事項全部証明書がある。この現在事項全部証明書  
だけから分かる内容として最も適切なものを下記の解答群から選べ。  
なお、現在事項全部証明書発行時において、登記事項はすべて適法に登記されて  
いるものとする。  
現在事項全部証明書  
東京都〇〇区〇〇一丁目〇〇番〇〇号  
A株式会社  
会社法人等番号（略）  
商号A株式会社  
本店東京都〇〇区〇〇一丁目〇〇番〇〇号  
公告をする方法官報に掲載してする。  
会社成立の年月日昭和５６年〇〇月〇〇日  
目的（略）  
発行可能株式総数６０万株  
発行済株式の総数  
並びに種類及び数  
発行済株式の総数  
４９万５０００株  
株券を発行する旨  
の定め  
当会社の株式については、株券を発行する。  
（登記日等略）  
資本金の額金\u0001億９５００万円  
役員に関する事項取締役B平成１８年\u0002月３０日重任  
平成１８年\u0003月１０日登記  
取締役C平成１８年\u0002月３０日就任  
平成１８年\u0003月１０日登記  
（中略）  
取締役H平成１９年\u0002月２９日就任  
平成１９年\u0003月９日登記  
東京都△△区△△二丁目△番△号  
代表取締役H  
平成１９年\u0002月２９日就任  
平成１９年\u0003月９日登記  
監査役I平成１７年\u0002月２８日就任  
平成１７年\u0003月７日登記  
監査役J平成１７年\u0002月２８日就任  
平成１７年\u0003月７日登記  
取締役会設置会社  
に���する事項  
取締役会設置会社  
（登記日等略）  
監査役設置会社に  
関する事項  
監査役設置会社  
（登記日等略）  
これは登記簿に記録されている現に効力を有する事項の全部であることを証明  
した書面で���る。  
平成１９年\u0004月\u0005日  
東京法務局  
登記官K印  
        
〔解答群〕  
  
ア  この会社が、公開会社（会社法第\u0001条第\u0002号）であるかどうかは、この現在事  
項全部証明書からは分からない。  
  
イ  この会社では、株主総会決議によらないで、取締役会決議のみで、毎期の剰  
余金の配当（中間配当を除く）を行うことはできない。  
  
ウ  この会社に支店が設置されているかどうかは、この現在事項全部証明書から  
は分からない。  
  
エ  この会社は、大会社（会社法第\u0001条第\u0003号）である。`
        },
        {
                "mondaiId": 105190030,
                "name": "第3問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
募集株式と募集社債との比較に関する記述として、最も適切なものはどれか。  
  
ア  募集株式：必ずしも株券を発行する必要はない。  
募集社債：必ず社債券を発行しなければならない。  
  
イ  募集株式：いかなる場合でも、取締役会の決議だけで発行できる。  
募集社債：いかなる場合でも、株主総会の特別決議がなければ発行できない。  
  
ウ  募集株式：持分会社は発行できない。  
募集社債：持分会社も発行することができる。  
  
エ  募集株式：割当てを受ける者が３０人を超えた場合は、株式管理者を置かなけ  
ればならない。  
募集社債：割当てを受ける者の数や社債の金額を問わず、社債管理者を置かな  
ければならない。  
`
        },
        {
                "mondaiId": 105190040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A、B、C、Dの\u0001人は、\u0001人で共同して、株式会社を設立することを予定して  
おり、\u0001人が役員となるとの前提で設立する会社の機関設計について検討してい  
る。このときの\u0001人の会話から結論づけられる株式会社の機関設計として最も適切  
なものを下記の解答群から選べ。なお、（\u0001）から（\u0002）には、それぞれ株式  
会社の異なる機関名が入るが、解答する必要はない。  
A：「\u0001人とも（\u0001）になることでどうだろう。」  
B：「それでは、会社の会計責任者が誰なのか、対外的にはっきりしなくなってし  
まうから、今回は適当でないと思う。Dは税理士でもあるのだから、Dに  
（\u0003）か（\u0004）になってもらった方がよいのではないか。」  
D：「私も基本的にはBの意見に賛成だ。ただ、事業が順調に進めば、比較的短い  
期間で大会社（会社法第\u0002条第\u0003号）となる。そのときに、改めて機関設計をや  
り直すのでは手間がかかる。改めて設置しなければならない機関をなるべく少  
なくできるようにした方がよい。」  
A、B、C：「もっともだ。」  
C：「そうすると、Dは、（\u0003）でも（\u0004）でも、どちらでもよいのかい。」  
D：「いや。私が（\u0003）となって、A、B、Cの\u0004人が（\u0001）ということにな  
ると、大会社となったときに改めて（\u0004）を設置しなければならない。それ  
を避けて、（\u0001）を\u0002人として、\u0005人が（\u0003）となるとすると、今度は  
（\u0002）を設置できなくなって、結局、後で、（\u0001）を\u0005人以上増やして  
（\u0002）を設置しなければならなくなるから、やはり面倒だ。最初から  
（\u0002）も設置して、私が（\u0004）になる方がよい。」  
A、B、C：「では、そうしよう。」  
〔解答群〕  
  
ア  取締役及び会計参与が設置されている会社  
  
イ  取締役及び監査役が設置されている会社  
  
ウ  取締役、取締役会及び会計参与が設置されている会社  
  
エ  取締役、取締役会及び監査役が設置されている会社  
`
        },
        {
                "mondaiId": 105190051,
                "name": "第5問設問1",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
中小企業診断士である甲氏は、顧問先のX株式会社（以下、「X社」という。）の社  
長乙氏から、おおむね以下の内容の相談を受けた。それに続くのは甲氏と乙氏との  
会話である。  
なお、本問における会社はすべて日本法人の取締役会・監査役設置会社とし、以  
下に記載があるほかは、本件手続に支障のある事情はないものとする。また、本件  
手続は、簡易組織再編行為・略式組織再編行為（会社法第７８４条・第７９６条）に該当  
しないものとする。  
［相談内容の概要］  
X社では、現在、販売部門の事業拡大を考えているが、X社の製品を販売する  
子会社であるY株式会社（X社の１００％子会社。以下、「Y社」という。）だけでは  
人員も能力も足りない。  
そこで、販売部門が強いZ株式会社（以下、「Z社」という。）を傘下におさめた  
いが、単純にZ社の発行済株式全部を買い取る方法はX社の都合で難しく、また  
許認可の問題から事業譲渡の方法も難しいので、これら以外の方法でX社がZ  
社の発行済株式全部を取得してZ社をX社の傘下におさめることができる方法を  
知りたい。  
その場合、X社の１００％子会社でX社の製品を販売する会社が\u0001つになるの  
で、Z社を傘下におさめると同時にZ社をY社に統合することも考えられる。  
甲氏：「そうすると、本件でZ社を傘下におさめる方法としては、株式交換によ  
る方法と、いわゆる三角合併の方法の\u0001通りが考えられます。」  
乙氏：「株式交換というのと、三角合併というのは、何が違うのですか。」  
甲氏：「Ａ」  
        
（設問\u0001）  
本件で想定されている株式交換の説明として最も適切なものはどれか。  
  
ア  X社が保有するX社の株式等と、Z社の発行済株式全部とを交換する方法。  
  
イ  X社が保有するY社の株式等と、Z社が保有するZ社の自己株式とを交換す  
る方法。  
  
ウ  Y社が保有するX社の株式等と、Z社の発行済株式全部とを交換する方法。  
  
エ  Y社が保有するY社の株式等と、Z社が保有するZ社の自己株式とを交換す  
る方法。`
        },
        {
                "mondaiId": 105190052,
                "name": "第5問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
本件で想定されている三角合併の説明として最も適切なものはどれか。  
  
ア  X社が、Z社の株主に対し、X社が保有するX社の株式を交付する方式で、  
Z社を吸収合併する方法。  
  
イ  Y社が、Z社の株主に対し、Y社が保有するX社の株式を交付する方式で、  
Z社を吸収合併する方法。  
  
ウ  Z社が、X社に対し、Z社の発行済株式全部を交付する方式で、Y社を吸収  
合併する方法。  
  
エ  Z社が、Y社に対し、Z社が保有するX社の株式を交付する方式で、Y社を  
吸収合併する方法。  
`
        },
        {
                "mondaiId": 105190053,
                "name": "第5問設問3",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
株式交換と三角合併の違いに関する説明として、空欄Ａに入る最も適切なもの  
はどれか。  
  
ア  株式交換の場合は、X社の株主総会決議による株式交換契約の承認が必要で  
すが、三角合併の場合は、X社の株主総会決議による合併契約の承認は不要で  
す。  
  
イ  株式交換の場合は、X社、Y社、Z社、いずれの会社の株主にも株式買取請  
求権が認められますが、三角合併の場合は、逆にいずれの会社の株主にも株式  
買取請求権が認められません。  
  
ウ  株式交換の場合は、契約の当事者は、Y社とZ社の二社だけで足りますが、  
三角合併の場合は、契約の当事者は、X社、Y社及びZ社の三社でなければな  
らないと会社法上定められています。  
  
エ  株式交換の場合は、交換の対価は株式か現金でなければなりませんが、三角  
合併の場合は、合併の対価は株式、現金、社債から選択することが認められて  
います。  
`
        },
        {
                "mondaiId": 105190060,
                "name": "第6問",
                "seikai": "ア",
                "haiten": 5,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
Y市で古くから製麺業を営むA製麺所は、\u0001年ほど前からその材料と製法に工夫  
を凝らした生麺を「〇〇〇」という商品名で売り出し、A製麺所の店先や、Y市の  
スーパーで販売をしていたが、商標「〇〇〇」については商標登録出願をしていな  
かった。A製麺所の生麺「〇〇〇」は、今年に入って、ようやくY市でも味と食感に  
優れたおいしい生麺として人に知られるところとなり、売れ行きも好調になってき  
ていた。  
そのような矢先突然、株式会社B製麺所（以下、「B製麺所」という。）というとこ  
ろから、A製麺所のものと同じ商標「〇〇〇」で商品区分・第３０類について商標権  
を取得したので、A製麺所の商標「〇〇〇」の使用を直ちに中止して欲しい旨の内容  
証明が送られてきた。  
そこで、A製麺所から相談を受けたあなたが商標公報を見たところ、確かにB製  
麺所は生麺、生そばを含む商品区分・第３０類について商標「〇〇〇」について商標  
権を取得しているが、出願日はA製麺所が生麺を商標「〇〇〇」で売り出した日から  
\u0002年後であることが判明した。  
A製麺所に対するアドバイスとして最も適切なものはどれか。  
  
ア  A製麺所が、商標「〇〇〇」をB製麺所の登録出願日よりも先に使用を開始して  
いたといっても、わが国は先願主義によっており、先に出願され、商標権が取得  
された以上、B製麺所の登録商標と同一のA製麺所の商標使用は中止せざるを得  
ないと思います。  
  
イ  A製麺所の商標「〇〇〇」を付した生麺は、味と食感に優れているということ  
で、今ではY市で知られた存在となっていることから、周知商標として保護さ  
れ、そのまま使用できるはずです。  
  
ウ  A製麺所は、B製麺所の商標登録出願日よりも前に商標「〇〇〇」の使用を開始  
していたのですから、B製麺所の登録商標「〇〇〇」は当然無効であるので、商標  
登録無効審判を提起できるはずです。  
  
エ  A製麺所は、B製麺所の商標登録出願日よりも前に商標「〇〇〇」の使用を開始  
していたのである以上、当然使用する権利があるはずですから、その���の回答を  
したらよいでしょう。  
`
        },
        {
                "mondaiId": 105190070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
外国出願については、各種国際条約や取り決めがなされており、出願の種類、出  
願希望国とその国数、出願費用等により、さまざまな出願方法が選べるようになっ  
ている。  
あなたが、顧問先の会社から外国出願について相談を受けた際のアドバイスとし  
て、<u>最も不適切なものはどれか</u>。  
  
ア  日本、イギリス、フランス、ドイツ、イタリアで特許権を取得したいというこ  
とであったので、日本でまず特許出願を行い、その後、この日本での特許出願に  
基礎を置く優先権を主張してヨーロッパ特許条約（EPC）に基づくヨーロッパ特許  
出願をするように勧めた。  
  
イ  日本、中国、韓国、シンガポール、ベトナムで商標権を取得したいということ  
であったので、マドリッド協定議定書に基づく国際商標登録出願をするように勧  
めた。  
  
ウ  日本、中国、韓国、台湾、インド、アメリカ、カナダ、イギリスで特許権を取  
得したいということであったので、特許協力条約（PCT）に基づく国際特許出願を  
するように勧めた。  
  
エ  日本、中国、韓国で特許権を取得したいということであったので、まず、日本  
へ特許出願を行い、その後パリ条約に基づく優先権を主張して、中国、韓国へ国  
別に特許出願を行うように勧めた。  
`
        },
        {
                "mondaiId": 105190080,
                "name": "第8問",
                "seikai": "エ",
                "haiten": 5,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
特許法は、その第３５条で職務発明について規定を置いている。この規定の内容  
として、<u>最も不適切なものはどれか</u>。  
  
ア  従業者等は、勤務規則等の定めにより、職務発明について使用者等に特許を受  
ける権利を承継させたり、もしくは当該職務発明についての特許権を承継させた  
りした場合には、使用者等より相当の対価の支払を受ける権利を有する。  
  
イ  職務発明でない場合に、あらかじめ勤務規則等で使用者等が特許を受ける権利  
を承継できる旨を定めても、それは無効である。  
  
ウ  職務発明とは、従業者等が、その性質上使用者等の業務範囲に属し、かつ、そ  
の発明をするに至った行為がその使用者等における従業者等の現在または過去の  
職務に属する発明をいう。  
  
エ  職務発明に関する相当の対価を決定するための基準は、重要な事項であるか  
ら、必ず勤務規則で定めなくてはならない。  
`
        },
        {
                "mondaiId": 105190090,
                "name": "第9問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
A社、B社、C社およびD社は、中小企業診断士であるあなたの顧問先である。  
A社は、売れ行き好調な自社製品（せんべいの製造装置）について特許権があるか  
らうちは安心だと言っており、一方、B社は、X社の製品（おもちゃ）が売れ行き好  
調のようなので、B社でも作りたいが、特許権があるということなので、手をこま  
ねいているようである。  
また、C社は、Y社で今度発売された商品（自動按摩機）は、C社の特許製品をま  
ねた商品で、しかも、C社の商品よりも、かなり安く発売されているので、Y社に  
製造販売をやめるように要求すると言っている。  
さらに、D社では、このたび、D社で新しく開発した商品（家具転倒防止器具）  
は、まだ世の中になく、どこにも発売されていないものなので、早速量産して大々  
的に売り出すと言っている。  
A社、B社、C社およびD社に対するあなたのアドバイスとして、最も不適切な  
ものはどれか。  
  
ア  A社に対しては、特許権があるといっても、その特許権がA社の製品を本当に  
保護しているかどうかは別の問題ですから、本当に保護されているかどうかを検  
討しておいたほうがよいですよ、とアドバイスする。  
  
イ  B社に対しては、X社の特許権があるといっても、その特許権を侵害しないよ  
うに作ることができる場合があるようですから、X社の特許権の特許公報を取り  
寄せて、検討してみたらどうでしょうか、とアドバイスする。  
  
ウ  C社に対しては、それは大変なことなので、早速C社の取引先はもちろんのこ  
と、Y社の取引先にも、Y社の商品はC社の権利侵害品であるとの文書を送りつ  
けるように、とアドバイスする。  
  
エ  D社には、その商品が世の中に見当たらないといっても、第三者が特許権等を  
持っている場合もありますから、D社の新商品が権利範囲に入る有効な特許権や  
実用新案権、意匠権等がないかどうか調べておいたほうがよいですよ、とアドバ  
  
イ  スする。  
`
        },
        {
                "mondaiId": 105190100,
                "name": "第10問",
                "seikai": "ウ",
                "haiten": 5,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
外国法人のA社が持つ、指定商品が「X」で「〇〇〇」というローマ字の大文字で書  
した先願既登録商標に対し、B社は同じく指定商品を「X」とし、「〇〇〇CLUB」と  
いう字句を同書、同大、同間隔、かつ一連に書して商標登録出願したところ、商標  
権を取得できた。  
その後、A社は自社の登録商標「〇〇〇」を付した商品の大々的な広告宣伝活動を  
開始した。  
その結果、A社の登録商標は、B社の商標が登録された後ではあったが、A社の  
商品を表示するものとして日本国内において広く人に知られる存在となった。その  
後、B社は自社の登録商標「〇〇〇CLUB」のうち「〇〇〇」と「CLUB」の間を\u0001文字  
離して使用したところ、A社から警告書が送られて来た。  
この場合のB社の対応について、<u>最も不適切なものはどれか</u>。  
  
ア  \u0001文字も離すと、「〇〇〇」と「CLUB」との間に、それぞれ別の意味が生じてく  
る可能性があることから、B社の登録商標「〇〇〇CLUB」の使用とはいえない場  
合が生じてくるので使用を中止する。  
  
イ  「〇〇〇」と「CLUB」の間を\u0001文字離して使用する場合、B社の方に信用のただ  
乗り（Freeride）意思があるとして、B社の登録商標は不正使用取消審判の対象  
となり得るので対策を考える。  
  
ウ  商標「〇〇〇」と「CLUB」の間を\u0001文字離した程度では、商標「〇〇〇」と  
「CLUB」の間の一体性は損なわれていないので、B社の登録商標「〇〇〇CLUB」  
の使用であるとして使用を中止しない。  
  
エ  たとえ\u0001文字離した程度であったとしても、A社の登録商標「〇〇〇」が既に周  
知著名商標となっていることを考慮すると、A社の商品との間に出所の混同を生  
ずるとされる恐れがあるので、使用を中止する。  
`
        },
        {
                "mondaiId": 105190111,
                "name": "第11問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
企業甲は、社長乙氏が代表取締役となり設立された携帯電話用のソフトウェアの  
プログラムを開発する株式会社であり、開発部門の部長丙氏を含む２０名の従業員  
が就業している。  
社長乙氏が中小企業診断士のあなたに、従業員にかかわる企業甲の営業秘密の保  
護の仕方について相談している。  
下記の設問に答えよ。  
社長乙：「うちの営業を担当していた従業員が辞めるらしくてね。うちでもそろそ  
ろ、企業秘密について何か対策を取らなくてはならないと考えているとこ  
ろなんだ。この従業員からは辞めるに際して、守秘義務をうたった誓約書  
を取ったら十分かな。」  
あなた：「もちろん、誓約書はあった方がいいですね。でも、誓約書を退職時に取  
るだけでは不十分ですよ。就業中から秘密情報は企業の重要な財産の一つ  
として守っていくべきですから、御社で企業秘密としたいものを、ちゃん  
と、法律によって保護される「営業秘密」という形にした方がよいと思いま  
すよ。」  
社長乙：「営業秘密管理などといわれているものですか。マル秘マークを付けて、  
文書を保存したりするんでしょう。うちでも、重要な文書については  
「CONFIDENTIAL」というハンコを押してますよ。でも、実のところ、我  
が社の場合、一番重要な情報はプログラムなどのデジタルデータでしょ  
う。パソコンの中に入っているものまでは、ハンコは押せなくて…。」  
あなた：「ハンコを押すかどうかということは、営業秘密であることを示す一つの  
事情にすぎないんです。「営業秘密」と認められる情報といえるためには、  
もっとたくさんのことをする必要があります。判例や経済産業省による指  
針などで示されている\u0001要件を満たさなければなりません。」  
社長乙：「何ですか。それは。」  
あなた：「Ａ、Ｂ、Ｃの\u0001つになります。」  
社長乙：「最初はＡですか。それは当然ではないですか。」  
        
あなた：「そうですが、実はこの点が認められないために、営業秘密には該当しな  
いとして、情報漏洩（ろうえい）された会社側が負けている判決が結構多い  
んですよ。」  
社長乙：「判決っていうぐらいだと、会社の方は、当然、営業秘密になるんだと  
思って訴えているんでしょう。Ａについて詳しく教えてもらいた  
いですね。」  
あなた：「まず、Ａがあると認められるためには、さらにＤと  
Ｅという要件が要求されています。先ほどのハンコの話は、文書  
情報に接した者にそれが秘密であると認識できるようになっているので、  
文書についてはＥの要件を満たすということがいえますね。た  
だ、御社は、デジタルデータについては何もしていないということになる  
かもしれません。」  
社長乙：「どうすればよいですか。」  
あなた：「まず、情報に接することのできる人を制限するなど、Ｄの要件  
を満たすことができるような社内体制を整えること、それについてマニュ  
  
ア  ルを作成し、社内で周知徹底することなど、物理的管理や技術的管理を  
行い、これと並行して人的管理を行います。具体的には、就業規則で明示  
して、従業員や新入社員から秘密保持誓約書を取りつける。そのほか、従  
業員教育を行い、それぞれの責務を明確にすることです。」  
社長乙：「営業秘密とすべき情報自体は、どのようなものでなければならないので  
すか。」  
あなた：「Ｂの要件があります。情報自体が、客観的に事業活動に利用さ  
れていたり、利用されることによって、経費の節約、経営効率の改善等に  
役立つものでなければなりません。御社で開発したプログラムはもちろ  
ん、営業活動に資する顧客情報なども含まれることがあります。」  
社長乙：「そういうものを、営業秘密として他の雑多な情報と区別して管理しなけ  
ればいけないということですね。」  
あなた：「そのとおりです。最後にＣという要件も満たしていなければな  
りません。これも、秘密というくらいですから一般に入手できない情報で  
あることが必要となります。当たり前といえば、当たり前ですね。」  
        
社長乙：「いろいろ大変そうですけど、情報は我が社の生命線ですから、早速、取  
りかからないといけないですね。」  
（設問\u0001）  
文中の下線部の法律においては、営業秘密の定義およびこれに関連して損害賠  
償請求や刑事罰などの規定がある。この法律の名称として最も適切なものはどれ  
か。  
  
ア  個人情報の保護に関する法律  
  
イ  商法  
  
ウ  不正アクセス行為の禁止等に関する法律  
  
エ  不正競争防止法`
        },
        {
                "mondaiId": 105190112,
                "name": "第11問設問2",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄Ａ、Ｂ、Ｃのいずれにも、当てはまらないものはどれか。  
  
ア  認識可能性（情報が秘密であると認識できること）  
  
イ  非公知性（公然と知られていないこと）  
  
ウ  秘密管理性（秘密として管理されていること）  
  
エ  有用性（有用な情報であること）  
`
        },
        {
                "mondaiId": 105190113,
                "name": "第11問設問3",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
企業甲における営業秘密について、文中の空欄Ｂ～Ｅに関連した説明として最  
も適切なものはどれか。  
  
ア  Ｂ：企業甲が脱税をしている事実を記載している情報について、こ  
れを秘匿していることが企業甲の利益につながることから、企  
業甲の営業秘密としてＢの要件を満たすことができ  
る。  
  
イ  Ｃ：一般に公表されている取引先の企業名やその住所について、こ  
れが当該企業のその他の情報と一体となって管理されている場  
合であっても、企業甲の営業秘密としてはＣの要件を  
満たすことができない。  
  
ウ  Ｄ：部長丙氏自らが職務上創作した情報について、これが社長乙氏  
のみがアクセスできるものとしてＤの要件を満たし、  
企業甲の営業秘密として管理されている場合でも、部長丙氏が第三者に開示することができる。  
  
エ  Ｅ：デジタルデータは、パスワードを設定してこれを知る人を限定  
するなど情報・人の管理の対象を明確化し、デジタルデータが  
保存されているデータベースを外部ネットワークから遮断する  
こと等により、Ｅの要件を満たすことができる。  
`
        },
        {
                "mondaiId": 105190120,
                "name": "第12問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
A社は、平成１８年\u0001月にA社の従業員Bが職務上創作した動物キャラクター「ぽ  
かぽかうさぎ」について、これを商品化し、名称「ぽかぽかうさぎ」を付したキャラ  
クター商品として平成１９年度秋頃から日本国内で販売することを企画している。  
最も適切なものはどれか。  
  
ア  A社が、動物キャラクター「ぽかぽかうさぎ」に関する権利について著作権法に  
基づき保護を受けるためには、Bから権利の譲渡を受け、「ぽかぽかうさぎ」に関  
する権利の譲渡を受けたことについて文化庁に「著作権の移転等の登録」の手続を  
しなければならない。  
  
イ  A社は、動物キャラクター「ぽかぽかうさぎ」について、第三者C社にキャラク  
ター商品の製造および販売の委託をする際には、C社に対して独占的に動物キャ  
ラクター「ぽかぽかうさぎ」に関する著作権の使用許諾をしなければならない。  
  
ウ  第三者Dが指定商品を「おもちゃ、人形」とし、「ぽかぽかうさぎ」との文字から  
構成される登録商標を有する場合でも、A社が動物キャラクター「ぽかぽかうさ  
ぎ」を立体化して作成したぬいぐるみのタグに「ぽかぽかうさぎ」というキャラク  
ター名称を小さく表示するのであれば、Dの商標権を侵害しない。  
  
エ  動物キャラクター「ぽかぽかうさぎ」と外観の類似したキャラクターが、世間に  
名の知られていない、海外において出版された絵本の中に第三者Eにより描かれ  
ている事実が判明した場合にも、動物キャラクター「ぽかぽかうさぎ」創作時にB  
を含めたA社の従業員がこのような事実を全く知らなかったときは、キャラク  
ター商品の販売を中止しなくともよい。  
`
        },
        {
                "mondaiId": 105190130,
                "name": "第13問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
日本法人であるA社は、アメリカ合衆国において特許権Xを取得し、アメリカ合  
衆国における事業展開を模索していたところ、特許権Xを実施したいと希望する  
ニューヨーク州法人であるB社とライセンス契約締結交渉を行うに至った。交渉を  
行う過程において、双方相手方との事業提携において、まず、最初の契約では、A  
社がB社に対し、この契約を締結した日から\u0001年間、当該特許を実施する権利を独  
占的にライセンスし、実際に事業を行ってみて、両者にとってプラスになる事業提  
携となるのであるならば、次年度以降ステップアップしていこうという趣旨の合意  
をした。  
その結果、次の条項が当初のライセンス契約書に規定されることとなったが、こ  
の条項の内容として最も適切なものを下記の解答群から選べ。  
なお、次の文中の語句について、「Licensor」はA社、「Licensee」はB社、  
「Territory」はアメリカ合衆国内、「Patent」は特許権X、「LicensedProducts」はX  
特許実施品を意味するものとする。  
Article〇FirstRefusalRight  
LicensorshallgrantLicenseeafirstrightofrefusaltonegotiateingood  
faithanexclusivelicensefortheLicensor’srightsofthePatentone（l）month  
priortotheendofthetermofthisAgreement.Suchlicenseshallbeexclusivein  
theTerritory，shallbeoncommerciallyreasonabletermsandshallprovide  
Licenseewithanexclusiverighttomanufacture，havemanufactured，use，sell，  
havesoldandoffertoselltheLicensedProductsintheTerritory.  
        
〔解答群〕  
  
ア  この条項により、A社はB社に対して、アメリカ合衆国内における特許権X  
の独占的な実施に関し、他者に優先して交渉する権利を付与した。  
  
イ  この条項により、A社はB社に対して、この契約の終了日の\u0001ヶ月前まで  
に、この契約を更新するか否かの選択をする権利を付与した。  
  
ウ  この条項により、A社は特許権Xについて、この契約期間中、アメリカ合衆  
国において、B社以外にライセンスすることが禁止された。  
  
エ  この条項により、A社は特許権Xの関連特許について、この契約期間満了日  
の\u0001ヶ月前までは、B社以外にライセンスすることが禁止された。  
`
        },
        {
                "mondaiId": 105190140,
                "name": "第14問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
日本の企業Aが海外の企業Bと国際商取引をする場合において、最も適切なもの  
はどれか。  
なお、各語句の意味は、国際商業会議所により作成された国際商取引慣習として  
使用されている貿易取引条件の解釈に関する国際規則であるインコタームズの  
「Incoterms２０００」に従うものとし、特に文中に明示されているもの以外は、当事者  
間で特約はないものとする。  
  
ア  CIFとは、Cost，InsuranceandFreightの略語であって、売買契約上定められ  
た船積港において、売主が船舶に目的物を船積みすることによって売主の引渡義  
務が完了し、売主が指定仕向港までの海上運賃と海上保険料を負担しない取引条  
件をいう。  
  
イ  FOBOsakaの条件であれば、大阪港が引渡場所となり、危険負担について  
は、この売買契約上定められた船積港において、物品が本船の舷側に設けられた  
手すりを横切って通過した瞬間に、売主から買主に移転する取引条件になる。  
  
ウ  売買契約上、物品の引渡が「海上および内陸水路輸送」以外で行われる取引を予  
定している場合は、FOB、CIFいずれの条件によっても合意することができず、  
買主によって指定された運送人に引き渡すという合意をするほかない。  
  
エ  弁済すべき場所について民法の原則は取立債務であるため、売買契約上、引渡  
義務の履行のためには売主が買主に送付すべきと合意されている場合には、目的  
物である物品が目的地に到達して、そこに買主が受領できる状態に至ってはじめ  
て物品が特定する。  
`
        },
        {
                "mondaiId": 105190150,
                "name": "第15問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
次の文中の空欄Ａ～Ｄと\u0001～\u0002の用語の組み合わせとして最も適切なものを下記  
の解答群から選べ。  
一般的な買収手続きは大まかに次のような段階を経て行うことが多い。  
まず、買収予定企業、売却予定企業双方の買収交渉開始の意向が確認されたら、  
売却予定企業の事業や財務などの基礎的情報の提供を受けるためＡを行  
う。ここで得られる情報は、売却予定企業の定款や株主名簿、税務申告書などであ  
り、情報の量は売却予定企業の協力度合いによって異なる。  
これらの情報を検討し、買収価格や買収の方法、スケジュールなどの基本的条件  
や付帯条件を交渉する。その結果、基本的な合意がなされた場合には、その双方の  
意思を書面で確認するためＢを行う。ここでは最終的な契約の細目が確定  
しているわけではなく、買収価格等は今後の調査の結果によって修正されるという  
付帯条項がついていることが多い。  
次に、売却予定企業の事業内容や財務内容、さらには法務面等の詳細な調査であ  
るＣを実施する。これによって買収価格の修正要因や、場合によっては買  
収を断念せざるを得ない要因が発見されることがある。  
この結果をもとに買収価格の修正や支払い条件などの最終的な交渉をし、合意に  
至れば最終的な契約書となるＤを行う。  
≪用語≫  
\u0001基本合意書の締結\u0003デューデリジェンス（DueDiligence）  
\u0004買収契約書の締結\u0002秘密保持契約の締結  
〔解答群〕  
  
ア  Ａ：\u0001Ｂ：\u0002Ｃ：\u0003Ｄ：\u0004  
  
イ  Ａ：\u0001Ｂ：\u0004Ｃ：\u0002Ｄ：\u0003  
  
ウ  Ａ：\u0002Ｂ：\u0001Ｃ：\u0003Ｄ：\u0004  
  
エ  Ａ：\u0002Ｂ：\u0004Ｃ：\u0003Ｄ：\u0001  
`
        },
        {
                "mondaiId": 105190161,
                "name": "第16問設問1",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `
　次の文書を読んで、下記の設問に答えよ。  

　製造業を営んでいる中堅非上場企業のX株式会社（以下、「X社」という。）の社長である甲氏は、製品市場の競合の激化から事業の採算が悪化しており、これを打開するために何らかの手立てが必要と感じていた。そのとき、ライバルの非上場企業であるY株式会社（以下、「Y社」という。）から、お互いの会社を合併して事業を共同で行わないかとの打診を受けた。  

　それを聞いた甲氏は、Y社と合併することのメリットとデメリットを考えた。確かにY社と合併すれば、取扱製品のシェアが拡大することから代理店に対する発言力が増すとともに、生産量が増加するため原料の仕入先に対しても有利な調達が可能となり利益率の改善が期待できる。  
　しかし、合併となると、自身の経営権の問題や従業員の雇用の問題など解決しなければならない課題が多いとも感じていた。  
　甲氏は会社の顧問であるコンサルタントの乙氏に助言を求めた。  
　乙氏のアドバイスによると、事業を共同で行うことのメリットは十分あるが、そのためには何も合併を選択しなければならない訳ではなく、業務提携契約でも可能である。また、事業を共同で行うことを重視するのであれば、合併に代えて<u>株式移転</u>によるいわゆる（　Ａ<hint hidden='true'>**
持株会社
**</hint>　）を設立する方法もあるとのことであった。株式移転とは、一または二以上の株式会社がその発行済株式の全部を新たに設立する株式会社に取得させる企業再編の手法である。この方法によれば、X社は（　Ａ<hint hidden='true'>**
持株会社
**</hint>　）の傘下の子会社として独立して存続するため、労働条件や人事ポストの調整、商号の統一、企業文化の融合などの合併における諸問題を当面回避しながら、グループ会社のシナジーを追及できる。この（　Ａ<hint hidden='true'>**
持株会社
**</hint>　）の設立は、平成９年の独占禁止法の改正以後、商法及び会社法の整備により上場企業でもしばしば行われている企業の統合手法の一つである。

（設問１）  
文中の空欄Ａに入る最も適切なものはどれか。  
  
ア 合同会社  
イ 合名会社  
ウ 持株会社<hint hidden='true'>**
←⭕
**</hint>    
エ 持分会社  

`
        },
        {
                "mondaiId": 105190162,
                "name": "第16問設問2",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部の株式移転に関する説明として、<u>最も不適切なものはどれか</u>。  
なお、以下の説明文中の完全親会社および完全子会社は、それぞれ会社法第  
７７３条第\u0002項第\u0002号および第\u0003号に定義されている株式移転設立完全親会社およ  
び株式移転完全子会社をいう。  
  
ア  二以上の株式会社が共同して株式移転を行うためには、当該株式会社は株式  
移転計画を共同して作成しなければならない。  
  
イ  株式移転計画には、株式移転により設立する完全親会社の目的、商号、本店  
の所在地、発行可能株式総数、完全親会社の設立時取締役の氏名等を定めなけ  
ればならない。  
  
ウ  株式移転計画は完全子会社の株主総会の特別決議による承認が必要である。  
この場合に、完全子会社となる会社の規模が小さくても簡易な手続きは認めら  
れていない。  
  
エ  完全親会社は、完全子会社の株式移転計画の承認が行われた日に、その発行  
済株式の全部を取得する。  
`
        },
        {
                "mondaiId": 105190171,
                "name": "第17問設問1",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
次の文章を読んで、下記の設問に答えよ。  
株式を上場するためには、上場を申請する証券市場を開設する証券取引所が定め  
た上場基準に適合しなくてはならない。一定の数値や一定の事実の有無によって判  
定できるいわゆる形式的な要件を充足した会社が、当該取引所による事業内容や経  
営体制、開示体制等に関する実質的な審査を受ける。前者の形式的な要件を形式基  
準、後者の実質的な審査の考え方を実質基準と呼ぶことがある。  
形式基準は、Ａ、株主数、時価総額などの株式の流通や株価形成の確保  
のための項目や、利益の額、純資産の額などの財務数値的な項目のほか、審査資料  
として提出される財務諸表等に虚偽記載が行われていないこと、財務諸表等に添付  
される公認会計士等の監査意見が適正であることや株式の事務代行機関の設置など  
に適合する必要がある。それぞれの項目や具体的な数値は各市場によって異なって  
いる。  
（設問\u0001）  
文中の空欄Ａに入る最も適切なものはどれか。  
  
ア  安定株式数  
  
イ  株式数  
  
ウ  債権者数  
  
エ  取引先数  
`
        },
        {
                "mondaiId": 105190172,
                "name": "第17問設問2",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部について、東京証券取引所マザーズ市場、大阪証券取引所ヘラク  
レス市場グロース基準、ジャスダック証券取引所ジャスダック市場の上場審査基  
準としての形式基準の説明として、<u>最も不適切なものはどれか</u>。  
  
ア  ジャスダック市場では、原則として直前事業年度に当期純利益が計上されて  
いるか、経常利益が一定額以上であることが必要である。ただし、上場日にお  
ける時価総額が一定額以上となる見込みのある場合にはこれらの利益金額は問  
わない。  
  
イ  ヘラクレス市場グロース基準では、一定金額以上の純資産の額または上場時  
時価総額または利益の額のいずれかの項目に適合することが必要である。  
  
ウ  マザーズ市場では、利益の額の項目はないが、一定額以上の純資産の額の項  
目に適合する必要がある。  
  
エ  マザーズ市場、ヘラクレス市場グロース基準、ジャスダック市場のいずれに  
おいても株主数は上場時に最低の条件でも３００人以上必要である。  
`
        },
        {
                "mondaiId": 105190180,
                "name": "第18問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 105,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
次の文中の下線部の一定の要件の説明として、最も不適切なものを下記の解答群  
から選べ。  
新株予約権を活用した制度の一例としてストックオプションがある。これは、企  
業が一定の金額で自社株を取得できる新株予約権を自社の従業員等に報酬として付  
与するものをいう。  
報酬として無償で新株予約権を付与された個人の税務上の取り扱いは、新株予約  
権の付与時にその価値に対して課税されるのではなく、新株予約権の権利行使時  
に、行使して取得した株式の時価と行使価額との差額に対して課税される。しか  
し、一定の要件を満たす場合には権利行使して取得した株式を譲渡するときまで課  
税を繰り延べる特例が認められている。ストックオプション制度の導入に当たって  
は、この税務上の適格要件にも注意することが必要である。  
〔解答群〕  
  
ア  権利行使価額の年間の合計額が１，２００万円を超えないこと。  
  
イ  新株予約権の行使は、付与決議の日後\u0001年を経過した日から１０年を経過す  
る日までに行わなければならないこと。  
  
ウ  新株予約権は譲渡してはならないこととされていること。  
  
エ  付与される者が取締役、監査役、使用人であること。ただし大口株主および  
その特別関係者を除く。  
`
        },
        {
                "mondaiId": 106190010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のコンピュータの主記憶装置に関する文章について、空欄Ａ～Ｄに入る最も適  
切な用語の組み合わせを下記の解答群から選べ。  
  
オフィスにおけるコンピュータの利用に際しては、その処理速度が仕事の効率に  
影響を及ぼす場合がある。この処理速度を左右する原因としてコンピュータに装備  
する主記憶装置の容量が関係し、主記憶装置の容量が少ないと処理速度が低下する  
といわれている。主記憶装置には多くの場合、（　Ａ　<hint hidden='true'>**
SDRAM
**</hint>  ）が利用されるが、価格な  
どの点で装備される記憶容量が限られるので、このような問題が発生する。  
この場合の処理速度低下の一つの要因は、補助記憶装置である（　Ｂ　<hint hidden='true'>**
ハードディスク
**</hint>）と主記  
憶装置との間で（　Ｃ　<hint hidden='true'>**
スワッピング
**</hint>）が発生するためである。このような現象が生じるのは  
（　Ｄ　仮想記憶）という仕組みを取り入れ、主記憶装置の容量不足を補っているためであ  
る。  

〔解答群〕  
  
ア  Ａ：EPROMＢ：ROM  
Ｃ：メモリインタリーブＤ：フラグメンテーション  
  
イ  Ａ：PROMＢ：フラッシュメモリ  
Ｃ：リフレッシュＤ：キャッシュ  
  
ウ  Ａ：SDRAMＢ：ハードディスク  
Ｃ：スワッピングＤ：仮想記憶<hint hidden='true'>**
←⭕
**</hint>    
  
エ  Ａ：VRAMＢ：光磁気ディスク  
Ｃ：キャッシュＤ：ランダムアクセス  
`
        },
        {
                "mondaiId": 106190020,
                "name": "第2問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータによってプレゼンテーション資料を作成する場合、画像を利用する  
機会が増えている。  
コンピュータで画像を取り扱う入出力装置などを使用する場合、扱える画像の大  
きさや精度に関する特性を考慮し、機器の選択や目的に応じた利用を行わなければ  
ならない。  
入出力装置などに関する記述として最も適切なものはどれか。  
  
ア  イメージスキャナの読み取り精度はdpiで表され、この数値が大きいほど解像  
度が高くなる。  
  
イ  コンピュータディスプレイ装置の表示の大きさを表す単位はdpiであり、この  
数値が大きいほど表示面積が大きくなり同時に解像度も高くなる。  
  
ウ  出力装置であるレーザプリンタの印字精度は印字ヘッドのドット数で表され、  
この数値が大きいほど解像度が高くなる。  
  
エ  デジタルカメラで撮影しJPEG形式で保存された静止画データのファイル容量  
はCCDイメージセンサの性能によって決まるので、保存された複数の静止画  
データのデータ量はどれも同じである。  
`
        },
        {
                "mondaiId": 106190030,
                "name": "第3問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータの内部ではさまざまな種類のソフトウェアが利用されているが、そ  
れぞれが担う機能には役割分担がある。これらのソフトウェアの中でデバイスドライバに関する記述として、最も適切なものはどれか。  
  
ア  オペレーティングシステムとアプリケーションソフトウェアの間で機能し、共  
通したインタフェース利用方法や統一的なコンピュータ機能の利用をアプリケー  
ションソフトウェアの要求に従って提供する。  
  
イ  コンピュータの周辺機器を制御するためのソフトウェアで、利用する周辺機器  
ごとに必要なものをオペレーティングシステムに組み込んで使用する。  
  
ウ  対話型処理システムにおいては、ユーザが端末から入力したユーザインタ  
フェースに関する指示を解釈し、相当するプログラムの起動や制御を行う。  
  
エ  プログラムのソースコードまたは中間コードを一命令ずつ解釈し、機械語に翻  
訳しながら実行する。  
`
        },
        {
                "mondaiId": 106190040,
                "name": "第4問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のプログラム言語に関する記述について、空欄Ａ～Ｄに入る最も適切な用語の  
組み合わせを下記の解答群から選べ。  
\u0001アセンブラ言語は機械語と\u0001対\u0001で対応するＡ言語で、アセンブラ言  
語で記述したソースプログラムを機械語に翻訳するソフトウェアをＢと  
いう。  
\u0001Javaはインターネットで利用されているオブジェクト指向のプログラム言語  
で、ソースプログラムをコンパイルした後の中間コードは特定のＣに依  
存せずに実行できることが特徴である。  
\u0001Perlはテキスト処理などに利用されるＤのプログラム言語で、ウェブアプリケーションの作成にも利用される。  
〔解答群〕  
  
ア  Ａ：インタプリタ型Ｂ：コマンドインタプリタ  
Ｃ：デバイスドライバＤ：組み込み型  
  
イ  Ａ：コンパイラ型Ｂ：コードジェネレータ  
Ｃ：アプリケーションプログラムＤ：組み込み型  
  
ウ  Ａ：低水準Ｂ：アセンブラ  
Ｃ：CPUＤ：インタプリタ型  
  
エ  Ａ：低水準Ｂ：コードジェネレータ  
Ｃ：デバイスドライバＤ：コンパイラ型  
`
        },
        {
                "mondaiId": 106190050,
                "name": "第5問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
インターネットにおいてはサーバコンピュータによって各種サービスや仕組みが  
提供されている。この各種サービスや仕組みに関する記述として最も適切なものは  
どれか。  
  
ア  FTPクライアントソフトを用いると、FTPサーバとの間で文書データやソフ  
トウェアを送受信することが可能で、ホームページの更新などにも利用される。  
  
イ  IP電話サービスはVPNの仕組みを利用し、音声をデジタル化して伝達する  
サービスのことである。  
  
ウ  ネットニュースは事前登録することによって、各新聞社のみならず個人までも  
が記者となって、ニュースを配信できるインターネット上のサービスの呼称であ  
る。  
  
エ  メーリングリストはウェブブラウザ上で、コミュニケーションを図りたい仲間  
のメールアドレスをグループごとに分類・管理し、必要なグループのメンバーの  
メールアドレスをいつでも検索できるようにしたサービスの呼称である。  
`
        },
        {
                "mondaiId": 106190061,
                "name": "第6問設問1",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の表は、ある家電量販店の「売上伝票テーブル」を示している。下記の設問に答  
えよ。  


<imgTag>106190061_1.png</imgTag>  

（設問１）  
この「売上伝票テーブル」は、正規化を進めることができ、結果として「売上  
テーブル」と「商品テーブル」の2つに分割することができる。「商品テーブル」に  
入る項目として、最も適切なものの組み合わせはどれか。  
  

<hint hidden='true'>
<div style="padding: 10px; margin-bottom: 10px; border: 1px dashed #333333;background-color:#ffffff;color:#3880ff;">
💡【重要知識】正規化
正規化とは、リレーショナルデータベースにおいて、データの冗長・重複を排除し、データの不整合を起こりにくくするためにテーブル構造を最適化する事を言います。  
非正規形を、第1，第2，第3正規形まで最適化すると良いとされています。  
  
参考：非正規形の例  
<img src="https://ferret-one.akamaized.net/images/5c457be0979afc6384002888/original.png?public=true"/>
<a href="https://oss-db.jp/dojo/dojo_info_04" target="_blank">  
(外部リンクを開きます　OSS-DB：データベースの正規化)</a>  
</div>
</hint>  

ア  粗利益、メーカー、製品名、売上ID  
  
イ  製品ID、販売単価、販売数量、製品名  
  
ウ  メーカー、販売数量、仕入単価、販売単価  
  
エ  メーカー、販売単価、製品ID、仕入単価<hint hidden='true'>**
←⭕
**</hint>    
`
        },
        {
                "mondaiId": 106190062,
                "name": "第6問設問2",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
以下のSQL文を実行した場合に得られる値として、最も適切なものを下記の  
解答群から選べ。  
SELECTAVG販売単価FROM売上伝票テーブルWHEREメーカー=“A電気”  
〔解答群〕  
  
ア  ８５，５００イ１０８，０００ウ１５３，０００エ３２４，０００  
`
        },
        {
                "mondaiId": 106190070,
                "name": "第7問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次のデータベースの動作に関する記述について、空欄Ａ～Ｄに入る最も適切な用  
語の組み合わせを下記の解答群から選べ。  
ネットワーク上で稼働するデータベースシステム（DBシステム）では、複数の  
ユーザがDBシステムに同時にアクセスし、同じデータの更新を行おうとする事態  
などが発生する可能性がある。このような不都合の発生を防止するため、あるユー  
ザがアクセス中のデータについては別のユーザからのアクセスを制限する  
Ａと呼ばれる仕組みがある。この仕組みの中の占有ロック方式は、アクセ  
ス中のデータに別のユーザがＢ、更新、削除、Ｃ、Ｄが  
できないようにする仕組みである。一方、共有ロック方式は、アクセス中のデータ  
に別のユーザが更新、削除、Ｄができないようにする仕組みである。  
〔解答群〕  
  
ア  Ａ：トランザクション制御Ｂ：占有ロック  
Ｃ：照会Ｄ：共有ロック  
  
イ  Ａ：トランザクション制御Ｂ：占有ロック  
Ｃ：デッドロックＤ：照会  
  
ウ  Ａ：排他制御Ｂ：照会  
Ｃ：共有ロックＤ：占有ロック  
  
エ  Ａ：排他制御Ｂ：デッドロック  
Ｃ：照会Ｄ：共有ロック  
`
        },
        {
                "mondaiId": 106190080,
                "name": "第8問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
コンピュータで記憶装置に保存されるデータはファイルとして保存されるが、こ  
のファイルをさまざまな用途で各種のアプリケーションソフトによって利用する場  
合、保存されたファイルの特性に注意を払う必要がある。  
コンピュータで扱われるファイルに関する記述として最も適切なものはどれか。  
  
ア  インターネットの情報発信に利用される、HTMLタグを利用して作成した文  
書は、機械語に翻訳されて記憶装置に保存され、バイナリファイルとして扱われ  
る。  
  
イ  インターネットの電子メールでは、本文での文書送信のほか、デジタルカメラ  
で撮影したJPEG形式のファイルをそのまま添付して送信することができる。  
  
ウ  表計算ソフトを使用してデータ入力を行い、さらに式や関数を使用して集計な  
どを行った後、記憶装置に保存したものはバイナリファイルである。  
  
エ  ワープロソフトでフォントデザインや文字の大きさを指定した文書を記憶装置  
に保存したものは、テキストファイルである。  
`
        },
        {
                "mondaiId": 106190090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
ある企業では、自社内の複数のパーソナルコンピュータ（パソコン）で社内LAN  
を構築し、その中のルー���機能を持つ一台のパソコンをインターネットに接続し  
て、その他の複数のパソコンでもインターネット上のホームページの閲覧や電子  
メールのやり取りができるネットワーク環境を構築したいと考えている。次の記述  
の中で最も適切なものはどれか。  
  
ア  \u0001台のパソコンで複数のNIC（NetworkInterfaceCard）を使うことはできな  
い。  
  
イ  各パソコンにはすでにMACアドレスが割り当てられているので、社内のパソ  
コンを数珠つなぎで１０BASE―Tケーブルを用いて接続する。  
  
ウ  すべてのパソコンのNIC（NetworkInterfaceCard）間をストレートケーブルで  
接続する。  
  
エ  ルータ機能を持つパソコンにハブを接続して、そのハブに１０BASE―Tケーブ  
ルを用いてその他のパソコンを接続する。  
`
        },
        {
                "mondaiId": 106190100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
コンピュータシステムによる処理に関する次のａ～ｄの記述と、その記述を表す  
用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａコンピュータシステムの単位時間当たりに処理される仕事の量を表す用語で、  
単位時間当たりに処理できる命令数や通信回線の実効転送量などに依存する。  
ｂコンピュータシステムに対して指示した処理とは直接関係のない、コンピュー  
タシステム全体にかかわる制御や管理にコンピュータ資源が使われること。  
ｃコンピュータシステムに対して、端末からある処理の処理要求を開始した時点  
から、その結果の出力が終わるまでの時間のこと。  
ｄコンピュータシステムに対して、端末からある処理の処理要求を出し終えた時  
点から、その応答が始まるまでの時間のこと。  
〔解答群〕  
  
ア  ａ：bpsｂ：マルチタスク  
ｃ：レスポンスタイムｄ：サイクルタイム  
  
イ  ａ：FLOPSｂ：マルチスレッド  
ｃ：サイクルタイムｄ：アクセスタイム  
  
ウ  ａ：MIPSｂ：スループット  
ｃ：アクセスタイムｄ：ターンアラウンドタイム  
  
エ  ａ：スループットｂ：オーバーヘッド  
ｃ：ターンアラウンドタイムｄ：レスポンスタイム  
`
        },
        {
                "mondaiId": 106190110,
                "name": "第11問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
信頼性の高いコンピュータシステムの構成に関する次のａ～ｄの記述と、その記  
述を表す用語の組み合わせとして、最も適切なものを下記の解答群から選べ。  
ａ同じ処理を行うコンピュータシステムを二重に用意する形態。一定時間ごとに  
処理結果を照合して処理の正しさの確認を行い、どちらかのコンピュータシステ  
ムに障害が発生しても、一方のシステムで処理を続行でき高い信頼性が得られ  
る。  
ｂコンピュータシステムの負荷を分散させる目的で複数のCPUやコンピュータ  
を接続し、各CPUやコンピュータで分担して処理を行うコンピュータシステム  
の総称である。  
ｃ業務処理を通常行うコンピュータシステムでオンライン処理を行っている間、  
別の処理を行わせながら待機させる別システムを用意する形態。通常使用してい  
るシステムに障害が発生した場合は、待機システムに切り替えてオンライン処理  
を続行する。  
ｄ独立して動作する複数のコンピュータを相互に接続し、全体として信頼性の高  
いコンピュータシステムを構築する形態。コンピュータの一部が障害を起こして  
も他のコンピュータに処理を肩代わりさせ、システム全体の停止を防止する。  
〔解答群〕  
  
ア  ａ：デュアルシステムｂ：ロードシェアシステム  
ｃ：コールドスタンバイｄ：クラスタリング  
  
イ  ａ：デュプレックスシステムｂ：タンデムシステム  
ｃ：ホットスタンバイｄ：マルチプロセッシングシステム  
  
ウ  ａ：ホットスタンバイｂ：クラスタリング  
ｃ：ミラーリングｄ：ロードシェアシステム  
  
エ  ａ：マルチプロセッシングシステムｂ：フォールトトレラントシステム  
ｃ：タンデムシステムｄ：デュアルシステム  
`
        },
        {
                "mondaiId": 106190120,
                "name": "第12問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
ハードウェアやソフトウェアの性能の高度化、ブロードバンドネットワークの急  
激な浸透、無線通信技術と携帯端末・電話の発展などが進んでいる。中小企業にお  
いても、仕事のやり方はもちろんのこと、企業革新や情報戦略などにそれらを考慮  
していくことがますます重要になると考えられる。それらに関する記述として、最  
も不適切なものはどれか。  
  
ア  ERPを導入することはビジネス社会ではもはや避けて通れない。遅れていた  
当社の業務IT化を挽回し、競争優位を実現するためにも、コンピュータベン  
ダーの進言でERPの全面的導入を即決した。  
  
イ  ある従業員は、社内業務処理とともにインターネットで電子メールやブラウザ  
を利用して、業務上の情報収集を通常業務用パソコンから行っていた。しかし、  
セキュリティなどの問題を考慮して、ネットワーク系統を社内の基幹系と情報系  
に分割することとした。  
  
ウ  同業他社では、従業員教育にeラーニングシステムを導入して、教育の合理  
化、効率化を図ろうとしている。当社ではその実績が無いことから試験的な利用  
を検討したが、基本的な方針としてしばらく他社での実績をみつつ勉強を重ねる  
こととした。  
  
エ  どんな時代であっても最新のITの動向を調査し、その利用可能性を検討しな  
がら、その適用を含めて当社独自のビジネスプロセスを試行錯誤して構築するこ  
とが重要であるとの認識に至った。  
`
        },
        {
                "mondaiId": 106190130,
                "name": "第13問",
                "seikai": "イ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
従来の一方的な情報提供中心のウェブ利用ではなく、より高度なサービスをウェ  
ブで提供するものとして、Web２．０が注目されている。これを応用することで、  
顧客により分かりやすく、従来のサイトと差別化できる品質のウェブページを提供  
することが可能になった。また、eビジネスの新しい可能性としてもWeb２．０が  
注目されている。この提唱者であるTimO’Reillyが示す、「Web２．０企業のコアコ  
ンピタンス」として最も適切なものはどれか。  
  
ア  強力なカスタマーサポートを通して、主要なサイトを取り込む。  
  
イ  単一デバイスの枠を超えたソフトウェアを提供する。  
  
ウ  独自性があり、同じものを作ることが難しいソフトウェアを提供する。  
  
エ  費用効率が高く、拡張性のあるパッケージソフトウェアを提供する。`
        },
        {
                "mondaiId": 106190140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
社内LANをインターネットに接続する際のセキュリティルータの設定に関する  
記述として、最も適切なものはどれか。  
  
ア  DHCPを稼働させれば、LAN側のパソコンに自動的にIPアドレスが配布され  
るので、ARP（AddressResolutionProtocol）データグラムは流れない。  
  
イ  ISP（InternetServiceProvider）によって割り当てられたIPアドレスを発信元  
とするパケットをインターネットから受信した場合には、LAN側に転送するよ  
うに設定した。  
  
ウ  インターネット側からLAN側へのIPポートをすべて開けておかないと、社  
内からインターネット上のウェブサイトなどにアクセスできなくなる。  
  
エ  セキュリティルータのLAN側IPアドレスとして、ISP（InternetService  
Provider）によって割り当てられたIPアドレスと異なるアドレスを設定した。  
`
        },
        {
                "mondaiId": 106190150,
                "name": "第15問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
次のシステム開発に関する文章について、空欄Ａ～Ｄに入る最も適切な用語の組  
み合わせを下記の解答群から選べ。  
中小事業者が自社システムの企画、開発、保守、運用をすべて独自に行うのは容  
易なことではない。そこで、それら業務を一括して請け負う業者がありＡ  
と呼ばれる。すべてをＡに任せるとしても、システム開発の工程がどのよ  
うなものであるかを理解しておく必要がある。  
システム開発にはさまざまな方法が考えられてきたが、Ｂは、各工程を  
後戻りなく行う方法であり、最も基本的な方法とされている。Ｃは、試作  
品をユーザに見せて確認しながら行う方法であるが、比較的小規模なシステムの開  
発に限定されるなどの課題がある。Ｄは、システムを複数のサブシステム  
に分け、基本となるサブシステムをまずＢの方法で開発してユーザに試用  
してもらい、その結果を反映させて次のサブシステムを開発する方法であり、近年  
注目されている。  
〔解答群〕  
  
ア  Ａ：ASPＢ：アウトソーシング  
Ｃ：プロトタイプモデルＤ：デルファイ法  
  
イ  Ａ：APSＢ：プロトタイプモデル  
Ｃ：インクリメンタルモデルＤ：スパイラルモデル  
  
ウ  Ａ：システムインテグレータＢ：インクリメンタルモデル  
Ｃ：ウォータフォールモデルＤ：プロトタイプモデル  
  
エ  Ａ：システムインテグレータＢ：ウォータフォールモデル  
Ｃ：プロトタイプモデルＤ：スパイラルモデル  
`
        },
        {
                "mondaiId": 106190160,
                "name": "第16問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
インターネット上で店舗を運営しているある企業は、顧客が商品希望条件などを  
随時登録する顧客台帳と、業者が商品情報を随時登録する商品台帳とを週単位で照  
合して、顧客に適合商品情報を提供している。  
この店舗のDFD（略図）は次のように記述できる。Ａ～Ｄに入る最も適切な用語  
の組み合わせを下記の解答群から選べ。  
〔解答群〕  
  
ア  Ａ：顧客Ｂ：希望商品情報検索  
Ｃ：商品台帳Ｄ：顧客台帳  
  
イ  Ａ：顧客Ｂ：商品台帳  
Ｃ：希望商品情報検索Ｄ：顧客台帳  
  
ウ  Ａ：顧客台帳Ｂ：希望商品情報検索  
Ｃ：商品台帳Ｄ：顧客  
  
エ  Ａ：顧客台帳Ｂ：商品台帳  
Ｃ：顧客Ｄ：希望商品情報検索  
`
        },
        {
                "mondaiId": 106190170,
                "name": "第17問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
運用中の基幹システムの保守が困難になってきたので、基幹システムの再構築を  
実施することになった。運用中のシステムは過去十数年間運用してきたが、開発時  
点の設計書や要件定義書もすべて残っている。この再構築に当たって、次の記述の  
中で最も適切なものはどれか。  
  
ア  運用中のシステムの利用プロセスを分析し、これを参考に新システムを再構築  
する。  
  
イ  運用中のシステムを上書きしながら新システムを開発し、これに本番データを  
入力してその挙動を調べることで、新システムの正当性を確認することができ  
る。  
  
ウ  開発時点の設計書に基づいて、新システムを再構築する。  
  
エ  開発時点の設計書を詳細に分析して、その処理手順をすべて新システムで実現  
することが、システムの一貫性保持の点で望ましい。`
        },
        {
                "mondaiId": 106190180,
                "name": "第18問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
利用者の視点を重視する業務システム分析・設計において、UML（Unified  
ModelingLanguage）が広く使われるようになった。UML標準によるUMLの記述  
として最も適切なものはどれか。  
  
ア  UMLでモデルを書けば、ツールを使ってシステムを自動作成することができ  
る。  
  
イ  UMLのダイアグラムをどのような順序や組み合わせで使うかは、UML標準  
で決められている。  
  
ウ  UMLはオブジェクト指向によるシステム分析・設計で用いられる。  
  
エ  UMLはシステム開発のための方法論である。  
`
        },
        {
                "mondaiId": 106190190,
                "name": "第19問",
                "seikai": "ア",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
あるソフトウェア開発企業に外注していた新業務システムの承認（検収）テスト  
に、発注者の顧問として立ち会うことになった。この承認（検収）テストについて最  
も適切なものはどれか。  
  
ア  主要機能から徐々に全機能へとテスト範囲を広げ、ブラックボックステストと  
してすべての機能をチェックする。  
  
イ  承認（検収）テストの基準となる検査仕様書は自社で作成すべきであり、ソフトウェア開発企業に作成を委託してはならない。  
  
ウ  前回のシステムテストでも正常動作を確認しているので、前回のシステムテス  
ト後に修正を依頼した個所が正しく動作していれば検収できる。  
  
エ  例外処理機能を検査するブラックボックステストでは、適正なデータをテスト  
データに加えてはならない。`
        },
        {
                "mondaiId": 106190200,
                "name": "第20問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
社内のIT技術者を育成・確保する上で、IT技術者の公的なITスキル標準（IT  
SkillStandard：ITSS）が参考になる。これについて最も適切なものはどれか。  
  
ア  IT技術者の公的スキル標準ではすべての職種でエントリレベルからハイレベ  
ルまで規定されているので、これに沿ってその職種内でレベルアップを図るべき  
である。  
  
イ  IT技術者の公的スキル標準にある職種を社内ですべてそろえるべきである。  
  
ウ  目標人材モデルはIT技術者の公的スキル標準を参照して、独自のものを策定  
すべきである。  
  
エ  目標人材モデルは情報システム部門が現状の業務分析から策定すべきである。  
`
        },
        {
                "mondaiId": 106190210,
                "name": "第21問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
ネットワークへの不正侵入対策には侵入検知システム（IntrusionDetection  
System：IDS）が使われる。これに関する説明として最も適切なものはどれか。  
  
ア  ネットワーク型IDSは、通常、ステルスモードで設置するので、DoS攻撃に  
も影響されない。  
  
イ  不正侵入対策にはネットワークレベルとホストレベルがあるので、情報システ  
ムの特徴を考慮していずれかでの対策を検討するべきである。  
  
ウ  ホスト型IDSには、VPNパケットについても分析処理が可能なものがある。  
  
エ  ホスト型IDSは、ネットワーク上に独立の侵入検知用サーバホストを設置し  
て、ネットワーク上の他のサーバホストを監視するシステムである。`
        },
        {
                "mondaiId": 106190220,
                "name": "第22問",
                "seikai": "エ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
当社は、政府機関への情報サービス提供を計画している。このとき、経済産業省  
が公表している「情報システムに係る政府調達へのSLA導入ガイドライン」に準拠  
することが求められる。これについて最も適切なものはどれか。  
  
ア  SLAは、契約書の付属資料として提出する仕様書の中で提示しなければなら  
ない。  
  
イ  SLAを提案するならば、既稼動システムの保守・運用契約の更新時の導入は  
システムの一貫性を維持することが難しいので、新規システムの開発・運用を一  
括発注する場合に提案するほうが容易である。  
  
ウ  サービスレベルが所定の水準と異なった場合の対応として、契約上の対応と財  
務上の対応をあらかじめ決定してSLAに記載することは必須であるが、運用上  
の対応については任意である。  
  
エ  落札方式には、総合評価落札方式と最低価格落札方式がある。このうち最低価  
格落札方式では、SLAを仕様書に記載することが必須である。  
`
        },
        {
                "mondaiId": 106190230,
                "name": "第23問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
二大新聞（A新聞とB新聞）の地域による普及度の違いを調べるために、関東の  
２２０世帯と関西の１８０世帯で読者数を調べたところ、それぞれ次の表のようになっ  
た。この表から地域・新聞ごとの普及度における違いの\u0001％水準での統計的な有  
意性を調べたい。この表のχ２値は７．５３である。  
χ２表によると、\u0001％水準でのχ２値は自由度\u0002では３．８４、自由度\u0003では５．９９、  
自由度\u0004では９．４９である。  
最も適切なものを下記の解答群から選べ。  
関東関西合計  
A新聞１４０９０２３０  
B新聞８０９０１７０  
合計２２０１８０４００  
〔解答群〕  
  
ア  ７．５３は９．４９より小さく、統計的に有意な差はない。  
  
イ  関東と関西で標本数が異なるので、統計的に有意な差を確認できない。  
  
ウ  要因の自由度は\u0002である。  
  
エ  要因の自由度は\u0003であり、統計的に有意な差がある。  
`
        },
        {
                "mondaiId": 106190240,
                "name": "第24問",
                "seikai": "ウ",
                "haiten": 4,
                "nendo": 2007,
                "mondaiSyuId": 106,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
コンサルティング会社を使って、当社の製品やサービスについて満足度や評価の  
  
ア  ンケート調査を実施した。２０の質問をリッカート尺度（\u0001段階尺度で、\u0002＝全く  
違うと思う、\u0003＝いずれとも言えない、\u0001＝全くそう思う）により計測した。この  
データを因子分析法により分析する。これについて最も適切なものはどれか。  
  
ア  因子分析により、多数の因子を抽出することができる。２５程度の因子を抽出  
することも可能である。  
  
イ  固有値が\u0002以下の因子を残すべきである。  
  
ウ  質問変数間の関係は線形であることが前提である。  
  
エ  抽出される因子は、データ中に異常値があってもこれに影響されない。  
`
        },
        {
                "mondaiId": 107190010,
                "name": "第1問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の産業のうち、総務省「事業所・企業統計調査」に基づいて、１９９９年から２００４  
年までの期間に中小事業所の数が減少した産業として、最も適切なものの組み合わ  
せを、下記の解答群から選べ。ただし、産業区分は２００２年改訂後の日本標準産業  
分類に従うものとする。  
ａ情報通信業ｂ金融・保険業  
ｃ飲食店、宿泊業ｄ教育、学習支援業  
〔解答群〕  
  
ア  ａとｂイａとｃウｂとｃ  
  
エ  ｂとｄオｃとｄ`
        },
        {
                "mondaiId": 107190020,
                "name": "第2問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
財務省「法人企業統計季報」に基づいて、１９９４年以降２００５年までの期間について  
中小企業のキャッシュフロー額と設備投資額の推移を見た場合、最も適切なものは  
どれか。なお、ここでは資本金\u0001億円未満の法人企業を中小企業と見なす。また、  
ここでは、キャッシュフロー額を経常利益額の半分と減価償却費の合計と定義す  
る。  
  
ア  中小企業のキャッシュフロー額は一貫して設備投資額を上回っているが、その  
差は縮小傾向にある。  
  
イ  中小企業のキャッシュフロー額は一貫して設備投資額を上回っており、その差  
は拡大傾向にある。  
  
ウ  中小企業のキャッシュフロー額は一貫して設備投資額を下回っているが、その  
差は縮小傾向にある。  
  
エ  中小企業のキャッシュフロー額は一貫して設備投資額を下回っており、その差  
は拡大傾向にある。  
`
        },
        {
                "mondaiId": 107190031,
                "name": "第3問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。なお、ここでは開業率を２００１年時点  
の既存企業数に対する開業企業数の比率、廃業率を２００１年時点の既存企業数に対  
する廃業企業数の比率と定義する。  
総務省「事業所・企業統計調査」に基づく中小企業庁の推計によれば、２００１年か  
ら２００４年までの期間における非一次産業の年平均の廃業企業数は、開業企業数を  
約Ａ万社上回っている。廃業率は年平均Ｂ％で過去最高の水準  
に達した。ただし、  
\u0001  
開業率も廃業率も地域や業種によって大きく異なる。また、新  
規開業企業の多くは小規模であり、  
\u0002  
会社形態ではなく個人事業として開業してい  
る。  
（設問\u0001）  
文中の空欄ＡとＢに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：\u0002Ｂ：３．５イＡ：\u0003Ｂ：９．７  
  
ウ  Ａ：１２Ｂ：６．１エＡ：２１Ｂ：１３．３`
        },
        {
                "mondaiId": 107190032,
                "name": "第3問設問2",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の下線部\u0001について、総務省「事業所・企業統計調査」に基づいて２００１～  
２００４年における非一次産業の企業の開業率と廃業率を都道府県別に見た場合、  
東京都、大阪府、愛知県などの大都市圏は地方圏と比較してどのように特徴づけ  
られるか。最も適切なものを選べ。  
  
ア  大都市圏では開業率・廃業率ともに地方圏よりも高い。  
  
イ  大都市圏では開業率・廃業率ともに地方圏よりも低い。  
  
ウ  大都市圏では開業率は地方圏よりも高いが、廃業率は地方圏よりも低い。  
  
エ  大都市圏では開業率は地方圏よりも低いが、廃業率は地方圏よりも高い。  
`
        },
        {
                "mondaiId": 107190033,
                "name": "第3問設問3",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部\u0001について、総務省「事業所・企業統計調査」に基づいて２００１～  
２００４年における非一次産業の開業企業を経営組織別に見た場合、会社形態での  
開業の割合が最も高いものはどれか。  
  
ア  飲食店、宿泊業イ卸売業ウ教育、学習支援業  
  
エ  小売業`
        },
        {
                "mondaiId": 107190040,
                "name": "第4問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
総務省「就業構造基本調査」によれば、１９９０年代初期以降、女性の創業希望者・  
準備者の数は一貫してＡ、創業実現率（創業希望者のうち実際に創業した  
者の割合）を男女別に見ると、Ｂのほうが高い。  
〔解答群〕  
  
ア  Ａ：減少しているがＢ：女性  
  
イ  Ａ：減少しておりＢ：男性  
  
ウ  Ａ：増加しているがＢ：男性  
  
エ  Ａ：増加しておりＢ：女性  
`
        },
        {
                "mondaiId": 107190050,
                "name": "第5問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄ＡとＢに入る最も適切な用語の組み合わせを下記の解答群から選  
べ。  
小規模企業の多くは、事業規模の拡大を意図していないとされる。実際、小規模  
企業における事業規模拡大志向の企業の割合は、より規模の大きい中小企業よりも  
低い。その理由のひとつとして、競争環境が厳しく、経営資源も乏しいために、事  
業規模の拡大を考える余裕がないということも考えられる。しかし、小規模企業の  
一般的な特性がＡをターゲットとする柔軟な経営にあると考えると、事業  
規模の拡大は必ずしも適切な戦略ではない。また、事業規模の拡大につれて  
Ｂの変更を余儀なくされることも多いので、これまでのＢを維持  
するために、事業規模の拡大を志向しないということも考えられる。  
〔解答群〕  
  
ア  Ａ：新規市場Ｂ：事業編成  
  
イ  Ａ：新規市場Ｂ：ビジネスモデル  
  
ウ  Ａ：ニッチ市場Ｂ：事業編成  
  
エ  Ａ：ニッチ市場Ｂ：ビジネスモデル  
`
        },
        {
                "mondaiId": 107190060,
                "name": "第6問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄Ａ～Ｃに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
倒産企業のほとんどは中小企業であり、倒産企業全体の動向は中小企業の倒産の  
動向を反映している。（株）東京商工リサーチの調査によれば、企業の倒産件数（負  
債金額一千万円以上）は２００１年以降２００５年までＡ傾向にある。倒産の内  
訳を見ると、銀行取引停止処分の割合がＢしており、この背景のひとつに  
は企業間信用のＣがあると考えられる。  
〔解答群〕  
  
ア  Ａ：減少Ｂ：上昇Ｃ：減少  
  
イ  Ａ：減少Ｂ：低下Ｃ：減少  
  
ウ  Ａ：増加Ｂ：上昇Ｃ：増加  
  
エ  Ａ：増加Ｂ：低下Ｃ：増加`
        },
        {
                "mondaiId": 107190070,
                "name": "第7問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
中小企業再生支援協議会がこれまでに実施した財務面での再生手法のうち、最も  
多く用いられた手法として最も適切な組み合わせを、下記の解答群から選べ。  
ａ新規融資  
ｂ既存借入金のリスケジュール  
ｃ債務免除  
ｄ再生ファンドによる株式・社債の引き受け  
〔解答群〕  
  
ア  ａとｂイａとｃウａとｄ  
  
エ  ｂとｃオｂとｄ  
`
        },
        {
                "mondaiId": 107190081,
                "name": "第8問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章を読んで、下記の設問に答えよ。  
日本の中小製造業の海外直接投資が、近年大きく増加している。経済産業省「海  
外事業活動基本調査」（２００４年）によれば、２００２年度に海外現地法人を持つ中小製造  
業の数は４００社を超え、海外現地法人を持つ製造業企業全体の中での中小企業の比  
率はおよそＡ％である（ここでは、資本金\u0001億円以下の法人企業を中小企  
業とする）。中でもアジア地域への中小企業の進出が目立っている。  
なお、前掲の資料によれば、中小製造業の半分以上は、アジア製造拠点の技術水  
準が日本Ｂと回答している。一方、アジア拠点での生産活動に伴って国内  
の生産量や雇用Ｃと回答した企業が中小製造業の半分以上を占める。  
（設問1））  
文中の空欄Ａに入る最も適切な数値はどれか。  
  
ア  \u0003イ１５ウ３０エ４５オ６０`
        },
        {
                "mondaiId": 107190082,
                "name": "第8問設問2",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の空欄ＢとＣに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ｂ：と同等であるＣ：が減少している  
  
イ  Ｂ：と同等であるＣ：は減少していない  
  
ウ  Ｂ：より低いＣ：が減少している  
  
エ  Ｂ：より低いＣ：は減少していない  
`
        },
        {
                "mondaiId": 107190090,
                "name": "第9問",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `  
次の文章の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
最近では、機械産業の業種によって国際的な立地戦略が異なってきている。  
Ａ機械器具産業で、アジア向け対外投資が頭打ちになり、日本国内での設  
備投資が再び大きく増加している一方で、中国やタイを中心にアジアでの生産拡大  
が続くＢ機械器具産業では、国内での設備投資の伸び以上にアジアでの設  
備投資が急激に拡大している。  
〔解答群〕  
  
ア  Ａ：一般・精密Ｂ：電気・情報通信  
  
イ  Ａ：一般・精密Ｂ：輸送用  
  
ウ  Ａ：電気・情報通信Ｂ：一般・精密  
  
エ  Ａ：電気・情報通信Ｂ：輸送用  
  
オ  Ａ：輸送用Ｂ：一般・精密  
`
        },
        {
                "mondaiId": 107190100,
                "name": "第10問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１０問  
中小企業の経営者が高齢化し、事業承継を円滑に進めることが重要な課題となっ  
ている。家族・親族以外の者が事業を承継する方法として、MBO（Management  
BuyOut）と並んでMBI（ManagementBuyIn）が注目されている。MBIの説明と  
して最も適切なものはどれか。なお、ここでは、オーナー社長が経営する企業が  
MBIによって承継される状況を想定している。  
  
ア  現社長以外の役員等が現社長の意思に基づいて買収を行う。  
  
イ  現社長以外の役員等が自社株式を買収するのではなく、現社長から事業譲渡を  
受ける。  
  
ウ  現社長以外の役員等が投資ファンドから調達した資金で買収を行う。  
  
エ  現社長が他の役員等ではなく社外の第三者に自社を買収してもらい、経営を引  
き継いでもらう。`
        },
        {
                "mondaiId": 107190111,
                "name": "第11問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１１問  
次の文章を読んで、下記の設問に答えよ。  
企業で働く労働者は内部労働者と外部労働者に区別される。内部労働者はさらに  
正社員と非正社員（パート・アルバイト・契約社員・嘱託等）に分かれる。外部労働  
者は派遣労働者と請負労働者に分かれる。近年、非正社員と外部労働者の比率が高  
まっていることはよく知られているが、総務省「労働力調査」と厚生労働省「派遣労  
働者実態調査」の結果から推計すると、２００４年時点で製造業企業の従業者のうち、  
これら正社員以外の従業者の割合はおよそＡである。規模別に見ると、中  
小製造業では大企業と比較して、内部労働者に占める非正社員の比率が  
Ｂ、全従業者に対する外部労働者の比率Ｃ傾向がある。  
        
（設問\u0001）  
文中の下線部について、派遣労働者と請負労働者の共通点と相違点の説明とし  
て、最も適切なものはどれか。  
  
ア  派遣労働者も請負労働者も実際に働いている企業と雇用関係にないが、請負  
労働者がその企業の指揮命令関係の下にいるのに対し、派遣労働者は指揮命令  
関係の下にいない。  
  
イ  派遣労働者も請負労働者も実際に働いている企業と雇用関係にないが、派遣  
労働者がその企業の指揮命令関係の下にいるのに対し、請負労働者は指揮命令  
関係の下にいない。  
  
ウ  派遣労働者も請負労働者も実際に働いている企業と指揮命令関係にないが、  
請負労働者がその企業との短期的な雇用関係の下にいるのに対し、派遣労働者  
は雇用関係の下にいない。  
  
エ  派遣労働者も請負労働者も実際に働いている企業と指揮命令関係にないが、  
派遣労働者がその企業との短期的な雇用関係の下にいるのに対し、請負労働者  
は雇用関係の下にいない。`
        },
        {
                "mondaiId": 107190112,
                "name": "第11問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄Ａに入る最も適切な数値はどれか。  
  
ア  \u0001割イ\u0002割ウ\u0003割エ\u0004割`
        },
        {
                "mondaiId": 107190113,
                "name": "第11問設問3",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問２））  
文中の空欄ＢとＣに入る最も適切な語句の組み合わせはどれか。  
  
ア  Ｂ：高いがＣ：は低い  
  
イ  Ｂ：高くＣ：も高い  
  
ウ  Ｂ：低いがＣ：は高い  
  
エ  Ｂ：低くＣ：も低い  
`
        },
        {
                "mondaiId": 107190120,
                "name": "第12問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１２問  
中小企業の中で、社債発行によって資金調達を行う企業が少ないことはよく知ら  
れているが、財務省「法人企業統計年報」に基づいて１９９９年度から２００４年度までの  
中小企業と大企業の社債残高（金額）の推移を比較した場合、最も適切なものはどれ  
か。なお、ここでは資本金\u0001億円以上の法人企業を大企業、それ以外の法人企業を  
中小企業とする。  
  
ア  中小企業の社債残高が大きく減少しているのに対して、大企業の社債残高はむ  
しろ増加傾向にある。  
  
イ  中小企業の社債残高が大きく増加しているのに対して、大企業の社債残高はむ  
しろ減少傾向にある。  
  
ウ  中小企業の社債残高も大企業の社債残高も、ともに大きく減少している。  
  
エ  中小企業の社債残高も大企業の社債残高も、ともに大きく増加している。  
`
        },
        {
                "mondaiId": 107190131,
                "name": "第13問設問1",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１３問  
次の文章を読んで、下記の設問に答えよ。  
中小小売店は、まちのにぎわい作りに貢献する。小売店が客を集めるだけではな  
く、飲食店やサービス業の店舗もその周辺に集まる傾向がある。実際、特に商業地  
区では、中小小売店の店舗数が増えるほど、近隣の飲食店・対人サービス業の店舗  
数も増えるという関係（正の相関関係）が観察される。しかし、近年は多くの地域で  
中小小売店舗数が減少している。商店数の変化を立地別に見ると、１９９７年から  
２００４年までの期間に売場面積５００平方メートル未満の商店数が増加したのは、経  
済産業省「商業統計表」によれば、にほぼ限定される。  
（設問\u0001）  
文中の下線部に関して、このような正の相関関係が主に見られる立地条件の違  
いを、東京・大阪・名古屋周辺の大都市圏とそれ以外の地域（地方圏）で比較した  
場合、最も適切なものはどれか。  
  
ア  大都市圏では駅周辺や市街地において観察されるが、地方圏では郊外のロー  
ドサイドにおいて観察される。  
  
イ  大都市圏では郊外のロードサイドにおいて観察されるが、地方圏では駅周辺  
や市街地において観察される。  
  
ウ  大都市圏でも地方圏でも駅周辺や市街地において観察される。  
  
エ  大都市圏でも地方圏でも郊外のロードサイドにおいて観察される。`
        },
        {
                "mondaiId": 107190132,
                "name": "第13問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄に入る最も適切な語句はどれか。  
  
ア  オフィス街地区  
  
イ  工業地区  
  
ウ  住宅地区  
  
エ  商業地区のうち駅周辺・市街地  
  
オ  商業地区のうち郊外のロードサイド  
`
        },
        {
                "mondaiId": 107190140,
                "name": "第14問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１４問  
経済産業省「商業統計表」のデータによれば、卸売業と小売業の販売額の比率  
（W/R比率）は１９９９年から２００４年までに大幅に低下した。その理由として最も適  
切なものはどれか。  
  
ア  卸売業者の経営効率性が低下した。  
  
イ  卸売業者の経営統合が進展した。  
  
ウ  大規模小売店舗が増加した。  
  
エ  流通経路の短縮化が進展した。`
        },
        {
                "mondaiId": 107190150,
                "name": "第15問",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１５問  
次の文章の空欄Ａ～Ｃに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
近年、中小企業にとっても特許等の知的財産の戦略的な重要性が増しているが、  
技術革新の成果を確保・専有化するためには、特許出願よりも情報の秘匿のほうが  
有効である場合もある。たとえば、Ａの成果はＢの成果よりも特  
許出願されにくい。また、生産においてＣが強く働く分野における技術成  
果についても、特許出願よりも秘匿が好まれる傾向がある。  
〔解答群〕  
  
ア  Ａ：プロセス・イノベーションＢ：プロダクト・イノベーション  
Ｃ：外部不経済  
  
イ  Ａ：プロセス・イノベーションＢ：プロダクト・イノベーション  
Ｃ：経験曲線効果  
  
ウ  Ａ：プロダクト・イノベーションＢ：プロセス・イノベーション  
Ｃ：外部不経済  
  
エ  Ａ：プロダクト・イノベーションＢ：プロセス・イノベーション  
Ｃ：経験曲線効果  
`
        },
        {
                "mondaiId": 107190160,
                "name": "第16問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１６問  
いわゆる「まちづくり三法」が平成１０年に制定されたが、各種の取り組みにもか  
かわらず、地方都市を中心に中心市街地の衰退は深刻化している。こうした問題に  
対応するため、平成１８年に都市計画法、中心市街地活性化法が改正された。  
これらの法律の改正に関する記述として最も適切なものはどれか。  
  
ア  中心市街地活性化法改正では、中心市街地整備推進機構、商��会または商工会  
議所等により組織される「中心市街地活性化協議会」が制度化された。  
  
イ  中心市街地活性化法改正では、都道府県が作成する基本計画の内閣総理大臣に  
よる認定制度が創設された。  
  
ウ  都市計画法改正では、市街化調整区域における病院、社会福祉施設、学校の開  
発許可は不要となった。  
  
エ  都市計画法改正では、床面積\u0001万平方メートル超の大規模集客施設の出店を大  
幅に規制し、「近隣商業地域」と「準工業地域」には原則として出店ができなくなっ  
た。  
`
        },
        {
                "mondaiId": 107190170,
                "name": "第17問",
                "seikai": "イ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１７問  
近年、地域産業の活性化や地域おこしの観点から、地域ブランドに対する期待が  
高まっている。地域ブランドを適切に保護することにより、産業競争力の強化と地  
域経済の活性化を支援するため、平成１８年の商標法改正によって、地域団体商標  
の登録が認められるようになった。  
地域団体商標に関する記述として、<u>最も不適切なものはどれか</u>。  
  
ア  図形等が入った商標は、地域団体商標として登録を受けることができない。  
  
イ  全国的な知名度を獲得し、特定の事業者の商品であることを識別できること  
が、商標登録の条件となる。  
  
ウ  地域団体商標の出願前から、不正競争の目的なく同一の商標を使用している第  
三者は、当該商標を継続して使用することができる。  
  
エ  登録を受けることができるのは、事業協同組合等の特別の法律により設立さ  
れ、加入の自由が法的に担保されている法人である。`
        },
        {
                "mondaiId": 107190180,
                "name": "第18問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１８問  
新連携支援事業は、他の事業者と連携し、優れた経営資源を有効に組み合わせる  
ことで、新しい製品・サービスを創出しようとする中小企業の取り組みを認定し、  
支援を行うものである。  
新連携支援事業における「連携体の条件」として最も適切なものはどれか。  
  
ア  \u0001社以上の異分野の中小企業が参加することが条件となる。  
  
イ  中核となる中小企業、大企業のいずれかが存在することが条件となる。  
  
ウ  メンバーにNPO法人等の非営利法人を含んでいないことが条件となる。  
  
エ  メンバーが同一都道府県もしくは同一地域ブロックに存在することが条件とな  
る。  
`
        },
        {
                "mondaiId": 107190190,
                "name": "第19問",
                "seikai": "エ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第１９問  
中小企業診断士S氏は、顧問先の金型メーカーT社の社長から、「同業のX社、  
加工メーカーY社、Z社と連携して、高性能な自動車部品を開発・製造予定である  
が、この件に関してX社の社長から有限責任事業組合（LLP）の設立を打診されてい  
る。LLPについて簡単に説明して欲しい。」との相談を受けた。  
以下は、S氏とT社社長の会話である。  
S氏：「LLPは、株式会社と同じく、責任は有限です。」  
T社社長：「では、株式会社と同じように、LLPも法人格を持つのですね。」  
S氏：「Ａ」  
T社社長：「課税方法はどのようになっているのですか。」  
S氏：「Ｂ」  
会話の中の空欄ＡとＢに入る最も適切なものの組み合わせはどれか。  
  
ア  Ａ：はい、法人格を持ちます。  
Ｂ：LLPに課税されます。  
  
イ  Ａ：はい、法人格を持ちます。  
Ｂ：出資者が得る利益に直接課税されます。  
  
ウ  Ａ：いいえ、法人格は持ちません。  
Ｂ：LLPに課税されます。  
  
エ  Ａ：いいえ、法人格は持ちません。  
Ｂ：出資者が得る利益に直接課税されます。  
`
        },
        {
                "mondaiId": 107190201,
                "name": "第20問設問1",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２０問  
次の文章を読んで、下記の設問に答えよ。  
「中小企業地域資源活用プログラム」は、各地域の「強み」である地域資源を活用し  
て新商品や新サービスを開発する中小企業者に対して、法的措置や予算措置、金融  
措置などにより総合的な支援を展開するものである。  
中小企業者が、地域資源を活用した新商品・新サービスの事業化を行う際、「中  
小企業地域資源活用促進法」に基づく支援のほか、さまざまな支援を受けることが  
できる。  
（設問\u0001）  
「中小企業地域資源活用促進法」に関する記述の正誤について、最も適切なものの  
組み合わせを下記の解答群から選べ。  
ａ地域資源を活用した中小企業の取り組みは、大きく分けて\u0001鉱工業品型、\u0002農  
林水産型の\u0002類型に分けられている。  
ｂ都道府県の指定する地域資源を活用した取り組みが、支援対象となる。  
〔解答群〕  
  
ア  ａ：正ｂ：正イａ：正ｂ：誤  
  
ウ  ａ：誤ｂ：正エａ：誤ｂ：誤`
        },
        {
                "mondaiId": 107190202,
                "name": "第20問設問2",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
地域資源を活用した新たな取り組みの掘り起こしを支援する「地域資源活用企業  
化コーディネート活動支援」に関する記述の正誤について、最も適切なものの組み  
合わせを下記の解答群から選べ。  
ａNPO法人が行うコーディネート活動も支援対象に含まれる。  
ｂ「中小企業地域資源活用促進法」の認定が必要である。  
〔解答群〕  
  
ア  ａ：正ｂ：正イａ：正ｂ：誤  
  
ウ  ａ：誤ｂ：正エａ：誤ｂ：誤  
`
        },
        {
                "mondaiId": 107190211,
                "name": "第21問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２１問  
次の文章を読んで、下記の設問に答えよ。  
JAPANブランド育成支援事業は、「日本」を表現しつつ世界に通用するブランド  
を実現していこうとする取り組みを総合的に支援する事業である。  
具体的には、Ａ等が核となってＢの中小企業等をコーディネー  
トし、市場調査、ブランド戦略づくり、新商品開発や展示会出展等に取り組むプロ  
ジェクトを支援している。  
（設問\u0001）  
文中の下線部のJAPANブランド育成支援事業の支援内容に関する記述とし  
て、最も適切なものはどれか。  
  
ア  戦略策定支援、ブランド確立支援ともに定額補助（５００万円程度）である。  
  
イ  戦略策定支援は  
\u0002  
\u0003  
補助（補助上限額２，０００万円）、ブランド確立支援は定額  
補助（５００万円程度）である。  
  
ウ  戦略策定支援は定額補助（５００万円程度）、ブランド確立支援は  
\u0002  
\u0003  
補助（補  
助上限額２，０００万円）である。  
  
エ  ブランド確立支援、戦略策定支援ともに  
\u0002  
\u0003  
補助（補助上限額２，０００万円）で  
ある。`
        },
        {
                "mondaiId": 107190212,
                "name": "第21問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄ＡとＢに入る最も適切な用語の組み合わせはどれか。  
  
ア  Ａ：JAPANブランド・プロデューサーＢ：異分野  
  
イ  Ａ：JAPANブランド・プロデューサーＢ：地域  
  
ウ  Ａ：商工会議所、商工会Ｂ：異分野  
  
エ  Ａ：商工会議所、商工会Ｂ：地域  
`
        },
        {
                "mondaiId": 107190220,
                "name": "第22問",
                "seikai": "ウ",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２２問  
中小企業基本法における中小企業等の定義に関する記述の正誤について、最も適  
切なものの組み合わせを下記の解答群から選べ。  
ａ資本金\u0001億円で、従業者数３００人の印刷業は、「中小企業」と定義される。  
ｂ従業員数１０人の美容院（個人経営）は、「小規模企業者」と定義される。  
ｃ資本金\u0002億円で、従業者数１００人の食品スーパーマーケットは、「中小企業」と  
定義される。  
〔解答群〕  
  
ア  ａ：正ｂ：正ｃ：誤  
  
イ  ａ：正ｂ：誤ｃ：正  
  
ウ  ａ：正ｂ：誤ｃ：誤  
  
エ  ａ：誤ｂ：正ｃ：正  
  
オ  ａ：誤ｂ：正ｃ：誤  
`
        },
        {
                "mondaiId": 107190230,
                "name": "第23問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２３問  
次の図は、中小企業組合数の推移を示したものである。図中の空欄Xに該当する  
組合制度に関する説明として、最も不適切なものを下記の解答群から選べ。  
注）組合数は、各年の\u0001月時点。  
〔解答群〕  
  
ア  議決権は、出資比例である。  
  
イ  組合員となれるものは、個人および法人などである。  
  
ウ  組合員の\u0002分の\u0003以上は、組合の行う事業に従事しなければならない。  
  
エ  組合自体が\u0003個の企業体として事業を行う。  
`
        },
        {
                "mondaiId": 107190241,
                "name": "第24問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２４問  
次の文章を読んで、下記の設問に答えよ。  
X社は、食品の加工販売業者である。昨年開発したオリジナル食品がヒットし  
て、順調に売り上げを伸ばしている。原材料の仕入資金調達が必要であるが、担保  
として余力のある不動産が無いことから新たな資金調達が困難な状況にある。  
X社の社長から資金調達の相談を受けた中小企業診断士のY氏は、流動資産担保  
保証制度を紹介することにした。  
以下は、Y氏とX社社長の会話である。  
Y氏：「売掛債権を担保とする売掛債権担保融資保証制度が従来ありました  
が、平成１９年度からは売掛債権に棚卸資産も加わって、流動資産担保  
保証制度になり、より充実しました。この制度を利用すれば、新たな資  
金調達の道が開けます。これは、金融機関が融資を行う際に、保有して  
いる売掛債権や棚卸資産を担保として信用保証協会が債務保証を行う制  
度です。」  
X社社長：「信用保証協会はいくら保証してくれるのですか。」  
Y氏：「保証限度額は、従来は円でしたが、平成１９年度からは\u0001億  
円です。」  
（設問1））  
会話の中の下線部の信用保証協会に関する記述の正誤として、最も適切なもの  
の組み合わせを下記の解答群から選べ。  
ａ保証業務のほかに、中小企業の経営相談、金融相談等の業務を行っている。  
ｂ保証に際しては、経営者を保証人として徴求することはない。  
ｃ信用保証協会が金融機関への代位弁済を行った後は、信用保証協会に求償権  
が発生し、信用保証協会が中小企業者から債権回収を行う。  
〔解答群〕  
  
ア  ａ：正ｂ：正ｃ：誤イａ：正ｂ：誤ｃ：正  
  
ウ  ａ：誤ｂ：正ｃ：誤エａ：誤ｂ：誤ｃ：正  
`
        },
        {
                "mondaiId": 107190242,
                "name": "第24問設問2",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
会話の中の空欄に入る最も適切な数値はどれか。  
  
ア  \u0002千万  
  
イ  \u0003千万  
  
ウ  \u0004千万  
  
エ  \u0005億`
        },
        {
                "mondaiId": 107190251,
                "name": "第25問設問1",
                "seikai": "エ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２５問  
次の文章を読んで、下記の設問に答えよ。  
高度化事業は、ＡとＢが財源を出し合い、事業計画等に対する  
  
ア  ドバイスを行いながら、長期・低利で融資する制度である。高度化事業には、中  
小企業者が実施する事業、第三セクターが実施する事業がある。  
（設問\u0005）  
文中の空欄ＡとＢに入る最も適切な用語の組み合わせはどれか。  
  
ア  Ａ：国Ｂ：政府系金融機関  
  
イ  Ａ：国Ｂ：中小企業基盤整備機構  
  
ウ  Ａ：都道府県Ｂ：政府系金融機関  
  
エ  Ａ：都道府県Ｂ：中小企業基盤整備機構`
        },
        {
                "mondaiId": 107190252,
                "name": "第25問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0001）  
文中の下線部の第三セクターが実施する事業として最も適切なものはどれか。  
  
ア  集積区域整備事業イ集団化事業  
  
ウ  商店街整備等支援事業エ連鎖化事業  
`
        },
        {
                "mondaiId": 107190261,
                "name": "第26問設問1",
                "seikai": "イ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ",
                        "オ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２６問  
次の文章を読んで、下記の設問に答えよ。  
小企業等経営改善資金融資制度（マル経融資）を利用すると、小企業者等は経営改  
善のための融資を無担保・無保証人・低利で受けることができる。  
この制度を利用するためには、商工会・商工会議所の経営指導員による経営指導  
を原則Ａ以上受けていること、原則として同一地区でＢ以���事業  
を行っていること、商工業者であり、かつ、国民生活金融公庫の融資対象業種を営  
んでいること、などの要件を満たすことが必要である。  
また、この融資の貸付期間は、運転資金\u0001年以内（据置期間は\u0002カ���以内）、設備  
資金Ｃ以内（据置期間はＤ以内）である。  
（設問２））  
文中の空欄ＡとＢに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ａ：\u0002カ月Ｂ：\u0002カ月  
  
イ  Ａ：\u0002カ月Ｂ：\u0003年  
  
ウ  Ａ：\u0003年Ｂ：\u0002カ月  
  
エ  Ａ：\u0003年Ｂ：\u0003年  
  
オ  Ａ：\u0003年Ｂ：\u0004年`
        },
        {
                "mondaiId": 107190262,
                "name": "第26問設問2",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問\u0004）  
文中の空欄ＣとＤに入る最も適切な数値の組み合わせはどれか。  
  
ア  Ｃ：\u0005年Ｄ：\u0002カ月  
  
イ  Ｃ：\u0005年Ｄ：\u0003年  
  
ウ  Ｃ：１０年Ｄ：\u0002カ月  
  
エ  Ｃ：１０年Ｄ：\u0003年  
`
        },
        {
                "mondaiId": 107190271,
                "name": "第27問設問1",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２７問  
次の文章を読んで、下記の設問に答えよ。  
中小企業診断士のX氏は、Y県の中小企業支援センターから、下請企業を���象と  
した研修会の講師を依頼された。以下は、研修会におけるX氏の話の一部である。  
「親事業者が下請をいじめるのは法令違反になります。下請取引の適正化と健全  
化を目的とした、下請取引に関する法律のひとつに下請代金支払遅延等防止法、い  
わゆる下請代金法があります。この法律には、親事業者が守らなければいけない四  
つの義務が定められていますので紹介しましょう。  
第一は、下請事業者から製品等を受領した日から起算してＡ日以内で、  
かつ、出来る限り短い期間を下請代金の支払期日と定める義務です。  
第二は、下請事業者に発注する際には、発注の内容、下請代金の額、支払期日、  
支払方法等を記載した書面を交付する義務です。  
第三は、下請事業者からの給付の受領日、下請代金の支払日等下請取引の経過を  
記載した書類等を作成し、それをＢ間保存する義務です。  
第四は、下請代金を支払期日までに支払わなかった場合は、給付を受領した後、  
Ａ日を経過した日から支払を行った日までの日数に、年率１４．６％を乗じ  
た金額を遅延利息として支払う義務です。」  
（設問\u0001）  
文中の空欄Ａに入る数値として最も適切なものはどれか。  
  
ア  １５イ３０ウ６０エ９０`
        },
        {
                "mondaiId": 107190272,
                "name": "第27問設問2",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `（設問1））  
文中の空欄Ｂに入る数値として最も適切なものはどれか。  
  
ア  \u0003カ月イ\u0001年ウ\u0002年エ\u0004年  
`
        },
        {
                "mondaiId": 107190280,
                "name": "第28問",
                "seikai": "ウ",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２８問  
中小企業BCP普及事業に関する記述として最も適切なものはどれか。  
  
ア  業況悪化に備えるための計画を対象としている。  
  
イ  事業承継等を円滑に進めるための計画を対象としている。  
  
ウ  自然災害等に備えるための計画を対象としている。  
  
エ  連鎖倒産等を防止するための計画を対象としている。`
        },
        {
                "mondaiId": 107190290,
                "name": "第29問",
                "seikai": "ア",
                "haiten": 3,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第２９問  
次の文章の空欄に入る最も適切な用語を下記の解答群から選べ。  
「物流効率化推進事業」は、中小企業組合等が、物流機能の効率化・強化を図るた  
めに中小企業が連携して事業に取り組む際、「調査研究・基本計画策定事業」等に要  
する経費の補助を受けることができるものである。この事業を利用するにあたって  
は、または全国卸商業団地協同組合連合会に対し、事業計画書を提出す  
る。  
〔解答群〕  
  
ア  各経済産業局イ各都道府県  
  
ウ  中小企業基盤整備機構エ日本商工会議所  
`
        },
        {
                "mondaiId": 107190300,
                "name": "第30問",
                "seikai": "ア",
                "haiten": 2,
                "nendo": 2007,
                "mondaiSyuId": 107,


                "sentakushi": [
                        "ア",
                        "イ",
                        "ウ",
                        "エ"
                ],
                //"category": "＿",
                //"difficulty": 2,
                
                "mondaiBun": `第３０問  
次の文章の空欄ＡとＢに入る最も適切な語句の組み合わせを下記の解答群から選  
べ。  
中小企業診断士のX氏は、個人で食品小売業を営むY氏から、「廃業に備え、生  
活の安定を図るための資金をあらかじめ準備しておきたい。」との相談を受けた。  
そこで、X氏は、Ａを紹介することとした。以下は、X氏とY氏の会話  
である。  
Y氏：「掛金はいくらになるのでしょうか。」  
X氏：「掛金は月額Ｂの範囲内で自由に決めることができます。」  
〔解答群〕  
  
ア  Ａ：小規模企業共済制度Ｂ：１，０００円から７０，０００円  
  
イ  Ａ：小規模企業共済制度Ｂ：５，０００円から５０，０００円  
  
ウ  Ａ：中小企業退職金共済制度Ｂ：１，０００円から７０，０００円  
  
エ  Ａ：中小企業退職金共済制度Ｂ：５，０００円から５０，０００円  
―２５―◇Ｍ７（０２３―１７９）`
        },

];

// マークダウン（保存用）です。
// <ion-icon name="help" size="large"></ion-icon>
// <i class="fab fa-twitter" style="zoom:1.7;"></i>
// <i class="fab fa-twitter" faa-tada animated style="zoom:3;"></i>
// * **ア**　選択肢の<font size='+3' color="Red">** *解説の記述* **</font>と、ここは~~間違って~~いる。これ<u>下線</u>だ。
// **太字**です。太字で*ない*。** *太字イタリック* **
// <font size=150% class="faa-float animated">あいうえおおお</font>

// > aaa
// An h1 header
// ============

// Paragraphs are separated by a blank line.

// 2nd paragraph. *Italic*, **bold**, \`monospace\`. Itemized lists
// look like:

//   * this one
//   * that one

// > Block quotes are
// > written like so.

// An h2 header
// ------------

// 1. first item
// 2. second item
// 3. third item

//     # Let me re-iterate ...
//     for i in 1 .. 10 { do-something(i) }

// ~~~
// define foobar() {
//     print 'Welcome to flavor country!';
// }
// ~~~

// \`\`\`python
// import time
// # Quick, count to ten!
// for i in range(10):
//     # (but not *too* quick)
//     time.sleep(0.5)
//     print i
// \`\`\`

// ### An h3 header ###

// Tables can look like this:

// |size |material     |color
// |---- |------------ |------------
// |9    |leather      |brown
// |10   |hemp canvas  |natural
// |11   |glass        |transparent

// <TABLE border="1" align="left">
// <TR>
// <TD>商品名</TD>
// <TD>商品番号</TD>
// <TD>商品価格</TD>
// </TR>
// <TR>
// <TD>スモールライト</TD>
// <TD>1-100</TD>
// <TD>300円</TD>
// </TR>
// <TR>
// <TD>ダックモービル</TD>
// <TD>1-107</TD>
// <TD>700円</TD>
// </TR>
// <TR>
// <TD>しびれおにぎり</TD>
// <TD>2-098</TD>
// <TD>200円</TD>
// </TR>
// </TABLE>
// ### 日本語ヘッダー

// ネスト構造

// 1. ほげ
//     1. ほげほげ
// 1. ぴよ
//     1. ぴよぴよ

// 数式記入例
//分数の例。注意：拡大時に画面恥で開業されるので、分数はなるべく１行に収めて書くこと

// 　・分数はいずれの方式でも、100%表示で1/2以下にするよう気を付ける
// 　・下線方式
// 　　・メリット：書きやすい
// 　　・デメリット：分数以外の項があるとタテがずれる。若干見づらい。
// <TABLE BORDER=0 CELLSPACING=0 CELLPADDING=0>
//   <TR ALIGN=center>
//     <TD NOWRAP>&nbsp;π&nbsp;
//       <HR SIZE=1 COLOR=0>&nbsp;４&nbsp;</TD>
//     <TD NOWRAP>&nbsp;＝&nbsp;</TD>
//     <TD NOWRAP>１－</TD>
//     <TD NOWRAP>&nbsp;１&nbsp;
//       <HR SIZE=2 COLOR=0>&nbsp;固定資産<sub>xy（下付き文字）</sub>&nbsp;</TD>
//     <TD>＋</TD>
//     <TD NOWRAP>&nbsp;現金<sup>2（乗数）</sup>&nbsp;
//       <HR SIZE=2 COLOR=0>&nbsp;５&nbsp;</TD>
//     <TD>－</TD>
//     <TD NOWRAP>&nbsp;貸倒引当金＋税&nbsp;
//       <HR SIZE=1 COLOR=0>&nbsp;配当金&nbsp;</TD>
//     <TD>－</TD>
//     <TD NOWRAP>&nbsp;繰越資産＋税&nbsp;
//       <HR SIZE=1 COLOR=0>&nbsp;売上&nbsp;</TD>
//   </TR>
// </TABLE>
// 　・枠線方式
// 　　・メリット：きれい。分数と、分数でない項が混在しても、縦がずれない
// 　　・デメリット：拡大時に他も含めではみ出す　＃上限は設けない。拡大したいときもあるかもだし、わかるでしょ。
// <table border="0" cellspacing="0" cellpadding="0">
//   <tr>
//     <td><u>　　　　税　　　　</u></td>
//   </tr>
//   <tr>
//     <td>コスト＋配当金＋配当金＋配当金＋配当金＋配当金＋配当金＋配当金＋配当金＋配当金＋配当金＋配当金＋配当金</td>
//   </tr>
// </table>
