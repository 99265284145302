import { Component, OnInit, ViewChild } from '@angular/core';
import { FirestoreAccessorService } from '../firestore-accessor.service';

import { Mondai } from '../mondai/mondai';
//import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
//import { Kaitou } from '../models/Kaitou';
import { MokujiData } from '../models/MokujiData';
import { MONDAIDATA_r1 } from '../mondai/mondaidata_r1';
import { MONDAIDATA_r2 } from '../mondai/mondaidata_r2';
import { TangoData } from '../models/TangoData';
import { YougoData } from '../models/YougoData';
import { MONDAIDATAAddedCategory } from '../mondai/mondaidataAddedCategory';
// import { MOKUJIDATAMASTER } from '../mondai/MOKUJIDATAMASTER';
import { TANGODATAMASTER } from '../TangoDataMaster';
import { YOUGODATAMASTER } from '../YougoDataMaster';
// import { GakusyuRireki } from '../models/GakusyuRireki';
// import { MatTableDataSource, MatPaginator } from '@angular/material';

import { animate, state, style, transition, trigger } from '@angular/animations';
import * as FileSaver from 'file-saver';
import { ModalController, ToastController } from '@ionic/angular';
import { ModalPage } from '../modal/modal.page';
import { DomSanitizer } from '@angular/platform-browser';
import { kaitouToukeiChartData } from '../models/KaitouToukeiChartData';
import { Platform, LoadingController, IonContent } from '@ionic/angular';
//import { data_json } from '../mondai/kaitouToukeiChartData.json';
//import * as kaitouToukeiChartData from '../mondai/kaitouToukeiChartData.json';
//import { kaitouToukeiChartData } from '../../assets/kaitouToukeiChartData';
//import { nwErrorPngBase64 } from '../../assets/nwErrorPngBase64';
import { json, quantileSeq } from 'mathjs'
//import {AfterViewInit} from '@angular/core';
//import { myEnterAnimation, myLeaveAnimation } from '../animation/enter';
import { IonInfiniteScroll, IonVirtualScroll, IonSlides } from '@ionic/angular';
import { MondaiPreviewPage } from '../mondai-preview/mondai-preview.page';
//import { TodoService } from '../services/todo.service';
//import { KaitouBun } from '../models/kaitoubun.model';
import { ScatterData } from '../models/scatterdata.model';

import * as Plotly from 'plotly.js-dist-min';//'plotly.js/dist/plotly.js';
//import { hexToRgb } from '@swimlane/ngx-charts';
//import * as Hammer from 'hammerjs';
import { GoogleTranslationServiceService } from '../google-translation-service.service';
import { Kaitou } from '../models/Kaitou';

import { Capacitor } from '@capacitor/core';
import { GoogleCloudVisionServiceService } from '../google-cloud-vision-service.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { VideoService } from '../services/video.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-m',
  templateUrl: './m.component.html',
  styleUrls: ['./m.component.scss'],
  animations: [
    trigger("homeAccordionAnimation", [ //下部のもくじ押下時のアニメーション定義
      state("default", style({ // 未選択（default）のスタイル ...（5）
        backgroundColor: "rgba(255,0,0,0.0)",
        height: '0em', minHeight: '0', opacity: 1, overflow: 'hidden' //, display: 'none'
      })),
      state("selected", style({ // 選択（selected）のスタイル ...（6）
        backgroundColor: "rgba(255,0,255,0.0)",
        height: '*'
      })),
      // アニメーションの時間と速度変化（イージング）の指定 ...（7）
      transition("default => selected", animate("500ms ease-out")),
      transition("selected => default", animate("500ms ease-in"))
    ]),

    trigger('detailExpand_mokuji', [
      //アニメーションの定義　＠　もくじの詳細
      // state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      // state('expanded', style({ height: '*' })),
      // transition('expanded => collapsed', animate('1s ease-out')),
      // transition('collapsed => expanded', animate('2250ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      state("collapsed", style({
        //backgroundColor:"#ff8080", transform:"scale(1.4)"
        height: '0px', minHeight: '0', display: 'none'
      })),
      state("expanded", style({
        height: '*'
      })),
      transition("collapsed => expanded", animate("1500ms ease-in")),
      transition("expanded => collapsed", animate("1500ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ]),
    trigger("mondai_select_animation", [
      //アニメーションの定義　＠　もくじのボタン
      state("default", style({
        transform: "scale(1.4)"//backgroundColor:"#80ff80",
      })),
      state("selected", style({
        transform: "scale(1)"//backgroundColor:"#ff8080",
      })),
      transition("default => selected", animate("1500ms ease-in")),
      transition("selected => default", animate("1s ease-out"))
    ]),
    trigger('accordion', [
      transition(':enter', [
        style({ height: '0', opacity: 0, overflow: 'hidden' }),
        animate('500ms', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: '1', overflow: 'hidden' }),
        animate('500ms', style({ height: '0' }))
      ])
    ]),
    trigger("summaryMetricsAnimation", [ //homeのマトリクス表示のアニメーション
      state("default", style({
        height: '0em', minHeight: '0', opacity: 1, overflow: 'hidden'
      })),
      state("selected", style({
        height: '*'
      })),
      transition("default => selected", animate("500ms ease-out")),
      transition("selected => default", animate("500ms ease-in"))
    ]),


    trigger("nijiAccordionAnimation", [ //下部のもくじ押下時のアニメーション定義
      state("default", style({ // 未選択（default）のスタイル ...（5）
        //backgroundColor:"rgba(0,0,0,0.1)",
        height: '0em', minHeight: '0', opacity: 1, overflow: 'hidden' //, display: 'none'
      })),
      state("selected", style({ // 選択（selected）のスタイル ...（6）
        backgroundColor: "rgba(255,255,255,0.0)",
        height: '*'
      })),
      // アニメーションの時間と速度変化（イージング）の指定 ...（7）
      transition("default => selected", animate("300ms ease-out")),
      transition("selected => default", animate("300ms ease-in"))
    ]),

    trigger("barChartAccordionAnimation", [ //二次の特徴量の棒グラフの表示
      state("default", style({ // 未選択（default）のスタイル ...（5）
        //backgroundColor:"rgba(0,0,0,0.1)",
        height: '0em', minHeight: '0', opacity: 1, overflow: 'hidden' //, display: 'none'
      })),
      state("selected", style({ // 選択（selected）のスタイル ...（6）
        backgroundColor: "rgba(255,255,255,0.0)",
        height: '*'
      })),
      // アニメーションの時間と速度変化（イージング）の指定 ...（7）
      transition("default => selected", animate("300ms ease-out")),
      transition("selected => default", animate("300ms ease-in"))
    ]),

    trigger("scatterChartAccordionAnimation", [ //二次の類似度散布図の表示
      state("default", style({ // 未選択（default）のスタイル ...（5）
        //backgroundColor:"rgba(0,0,0,0.1)",
        height: '0em', minHeight: '0', opacity: 1, overflow: 'hidden' //, display: 'none'
      })),
      state("selected", style({ // 選択（selected）のスタイル ...（6）
        backgroundColor: "rgba(255,255,255,0.0)",
        height: '*'
      })),
      // アニメーションの時間と速度変化（イージング）の指定 ...（7）
      transition("default => selected", animate("300ms ease-out")),
      transition("selected => default", animate("300ms ease-in"))
    ]),

    trigger("furikaeriAccordionAnimation", [ //FAQの表示
      state("default", style({ // 未選択（default）のスタイル ...（5）
        //backgroundColor:"rgba(0,0,0,0.1)",
        height: '0em', minHeight: '0', opacity: 1, overflow: 'hidden' //, display: 'none'
      })),
      state("selected", style({ // 選択（selected）のスタイル ...（6）
        backgroundColor: "rgba(255,255,255,0.0)",
        height: '*'
      })),
      // アニメーションの時間と速度変化（イージング）の指定 ...（7）
      transition("default => selected", animate("300ms ease-out")),
      transition("selected => default", animate("300ms ease-in"))
    ]),

  ],
})

export class MComponent implements OnInit {
  @ViewChild('theScrollEl') theScrollEl;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonVirtualScroll) virtualScroll: IonVirtualScroll;
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild('mainSlides') mainSlides: IonSlides;
  //@ViewChild(IonTextarea) nijiTextArea: IonTextarea

  // @ViewChildren('slideQueryLs') slideQueryList: QueryList<IonTextarea>;

  // textAreaChildren: { [mondaiId: number]: IonTextarea } = {};

  // @ViewChild(MatSort) sort: MatSort;
  //@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  // rirekiDataSource: MatTableDataSource<GakusyuRireki>;
  // // mokujiDataSource: MatTableDataSource<MokujiData>;
  // kaitouMondaiJoin: GakusyuRireki[];
  // mondaiKaitouJoin: GakusyuRireki[];

  kamokuName = {
    101: "経済",
    102: "財務",
    103: "経営",
    104: "運営",
    105: "法務",
    106: "情報",
    107: "中小",
    201: "事例I",
    202: "事例II",
    203: "事例III",
    204: "事例IV"
  };

  kamokuNameLong = {
    101: "経済学・経済政策",
    102: "財務・会計",
    103: "企業経営理論",
    104: "運営管理",
    105: "経営法務",
    106: "経営情報システム",
    107: "中小企業経営・中小企業政策",
    201: "2次試験再現答案事例I",
    202: "2次試験再現答案事例II",
    203: "2次試験再現答案事例III",
    204: "2次試験再現答案事例IV"
  };

  nendoName = {
    // 2022: "令和4年度",
    2021: "令和3年度",
    2020: "令和2年度",
    2019: "令和元年度",
    2018: "平成30年度",
    2017: "平成29年度",
    2016: "平成28年度",
    2015: "平成27年度",
    2014: "平成26年度",
    2013: "平成25年度",
    2012: "平成24年度",
    2011: "平成23年度",
    2010: "平成22年度",
    2009: "平成21年度",
    2008: "平成20年度",
    2007: "平成19年度"
  };

  nendoNameShort = {
    // 2022: "r 4",
    2021: "R 3",
    2020: "R 2",
    2019: "R 1",
    2018: "H30",
    2017: "H29",
    2016: "H28",
    2015: "H27",
    2014: "H26",
    2013: "H25",
    2012: "H24",
    2011: "H23",
    2010: "H22",
    2009: "H21",
    2008: "H20",
    2007: "H19"
  };

  nendoNameShort_jp = {
    // 2022: "令 4",
    2021: "令 3",
    2020: "令 2",
    2019: "令 1",
    2018: "平30",
    2017: "平29",
    2016: "平28",
    2015: "平27",
    2014: "平26",
    2013: "平25",
    2012: "平24",
    2011: "平23",
    2010: "平22",
    2009: "平21",
    2008: "平20",
    2007: "平19"
  };


  nendoName_niji = {
    // 2022: "令和4年度",
    2021: "令和3年度",
    2020: "令和2年度",
    2019: "令和元年度",
    2018: "平成30年度",
    2017: "平成29年度",
    2016: "平成28年度",
    2015: "平成27年度",
    2014: "平成26年度",
    2013: "平成25年度",
    2012: "平成24年度",
    2011: "平成23年度",
    2010: "平成22年度",
    2009: "平成21年度",
    2008: "平成20年度",
    2007: "平成19年度",
    2006: "平成18年度",
    2005: "平成17年度",
    2004: "平成16年度",
    2003: "平成15年度",
    2002: "平成14年度",
    2001: "平成13年度",
  };

  nendoNameShort_jp_niji = {
    // 2022: "令 4",
    2021: "令 3",
    2020: "令 2",
    2019: "令 1",
    2018: "平30",
    2017: "平29",
    2016: "平28",
    2015: "平27",
    2014: "平26",
    2013: "平25",
    2012: "平24",
    2011: "平23",
    2010: "平22",
    2009: "平21",
    2008: "平20",
    2007: "平19",
    2006: "平18",
    2005: "平17",
    2004: "平16",
    2003: "平15",
    2002: "平14",
    2001: "平13",
  };
  
  nendoNameShort_niji = {
    // 2022: "令 4",
    2021: "R.3",
    2020: "R.2",
    2019: "R 1",
    2018: "H30",
    2017: "H29",
    2016: "H28",
    2015: "H27",
    2014: "H26",
    2013: "H25",
    2012: "H24",
    2011: "H23",
    2010: "H22",
    2009: "H21",
    2008: "H20",
    2007: "H19",
    2006: "H18",
    2005: "H17",
    2004: "H16",
    2003: "H15",
    2002: "H14",
    2001: "H13",
  };
  
  nijiColor = {
    201: "2870ef",
    202: "ffce00",
    203: "989aa2",
    204: "10dc60",
  };
  

  mappingKamokuIconName = {
    //101: "fa fa-line-chart fa-lg",//-2x//
    101: "fas fa-chart-line fa-lg fa-fw",//fa-lg",
    102: "fas fa-yen-sign fa-lg fa-fw",
    103: "fas fa-user-tie fa-lg fa-fw",//"fa fa-building fa-lg fa-fw",//
    104: "fa fa-shopping-cart fa-lg fa-fw",
    105: "fa fa-gavel fa-lg fa-fw",//"fas fa-balance-scale fa-lg fa-fw",//
    106: "fas fa-laptop fa-lg fa-fw",
    107: "fas fa-chart-pie fa-lg fa-fw",//"far fa-chart-bar fa-lg fa-fw",//
    201: "fas fa-dice-one fa-lg fa-fw",
    202: "fas fa-dice-two fa-lg fa-fw",
    203: "fas fa-dice-three fa-lg fa-fw",
    204: "fas fa-dice-four fa-lg fa-fw",
  };

  colorScheme = {
    domain: [
      '#ffce00', '#e0b500', //経済
      '#f07841', '#d37039',//'#f04141', '#d33939', //'#c23616', //財務
      '#7044ff', '#633ce0', //'#0097e6', //経営
      '#3880ff', '#3171e0', // '#44bd32', //運営
      '#0cd1e8', '#0bb8cc', //'#e1b12c', //法務
      '#10dc60', '#0ec254', //'#8c7ae6', //情報
      '#989aa2', '#86888f', //'#40739e', //中小
      '#22222f', '#111111', //'#40739e', //事例1
      '#22222f', '#111111', //'#40739e', //事例2
      '#22222f', '#111111', //'#40739e', //事例3
      '#22222f', '#111111', //'#40739e', //事例4
    ]
  };

  colorSchemeMapping = {
    '101': { true: 0, false: 1 },
    '102': { true: 2, false: 3 },
    '103': { true: 4, false: 5 },
    '104': { true: 6, false: 7 },
    '105': { true: 8, false: 9 },
    '106': { true: 10, false: 11 },
    '107': { true: 12, false: 13 },
    '201': { true: 14, false: 15 },
    '202': { true: 16, false: 17 },
    '203': { true: 18, false: 19 },
    '204': { true: 20, false: 21 },
  };

  colorSchemString = {
    '101': "warning",
    '102': "zaimucolor",
    '103': "tertiary",
    '104': "primary",
    '105': "secondary",
    '106': "success",
    '107': "medium",
    '201': "darkgray",
    '202': "darkgray",
    '203': "darkgray",
    '204': "darkgray",
  };

  // currentGuiStatus = {
  //   // ashiatoHyoujiKamoku: 0,
  //   // ashiatoHyoujiNendo: 0,
  //   // ashiatoHyoujiKeyword: "すべて表示",
  //   //ayumiHyouUnVisible: true,
  //   categoryMode: {
  //     101: 'true',
  //     102: 'true',
  //     103: 'true',
  //     104: 'true',
  //     105: 'true',
  //     106: 'true',
  //     107: 'true'
  //   },
  //   currentMondaiId:{
  //     101: 101300030,
  //     102: 102300040,
  //     103: 103300020,
  //     104: 104300020,
  //     105: 105300020,
  //     106: 106300020,
  //     107: 107300020
  //   },
  //   // mokujiSetteiVisible: false,
  //   // mokujiSyousaiHyouji: true,
  //   // sakuinHyoujiKamoku: 0,
  //   // sakuinSearchKeyword: "",
  //   // mokujiTangoKamoku: 0,
  //   mokujiSearchKeyword: {
  //     101: '',
  //     102: '',
  //     103: '',
  //     104: '',
  //     105: '',
  //     106: '',
  //     107: ''
  //   },
  //   // mondaiImageOffline: {},
  //   mondaiFontSize: 100,
  //   myName: "",
  //   currentTab: 101,
  //   // kaitouCount_all: 0,
  //   // kaitouCount_kamokubetu: {},
  //   // seikaiCount_kamokubetu: {},
  //   // lastKaitouBi: new Date(),
  //   // renzokuKaitouBi: 1,
  //   // mokujiCategoryShowAll: false,
  //   //lastMondaiSyuStatus_kamokubetu: {},
  //   //lastKaitouBi: new Date().setDate(yesterday.getDate() - 1);
  //   mokujiData: {
  //     101: '',
  //     102: '',
  //     103: '',
  //     104: '',
  //     105: '',
  //     106: '',
  //     107: ''
  //   },
  //   currentMondai: {
  //     101: {},
  //     102: {},
  //     103: {},
  //     104: {},
  //     105: {},
  //     106: {},
  //     107: {},
  //   },
  //   currentMokuji: {
  //     101: '',
  //     102: '',
  //     103: '',
  //     104: '',
  //     105: '',
  //     106: '',
  //     107: '',
  //   },
  //   mondaiVisible: {
  //     101: false,
  //     102: true,
  //     103: true,
  //     104: true,
  //     105: true,
  //     106: false,
  //     107: true
  //   },
  //   selectedSentakushi:{
  //     101: '',
  //     102: '',
  //     103: '',
  //     104: '',
  //     105: '',
  //     106: '',
  //     107: ''
  //   },
  // };
  mondaidata: Mondai[] = [];//MONDAIDATAAddedCategory;
  tangoDataMaster: TangoData[] = TANGODATAMASTER;
  yougoDataMaster: YougoData[] = YOUGODATAMASTER;

  //mokujiData: MokujiData[] = []

  // mokujiDataSource: MatTableDataSource<MokujiData>;
  mokujiDataSourceDic = {
    '101': {
      true: '',//
      false: ''
    },
    '102': {
      true: '',//
      false: ''
    },
    '103': {
      true: '',//
      false: ''
    },
    '104': {
      true: '',//
      false: ''
    },
    '105': {
      true: '',//
      false: ''
    },
    '106': {
      true: '',//
      false: ''
    },
    '107': {
      true: '',//
      false: ''
    },
    '201': {
      true: '',//
      false: ''
    },
    '202': {
      true: '',//
      false: ''
    },
    '203': {
      true: '',//
      false: ''
    },
    '204': {
      true: '',//
      false: ''
    },
  };
  columnsToDisplayMokujiHyou = ['mokujiCategory', 'mondaiList'];
  expandedElementMokujiData: MokujiData | null;

  mokujiButtonColor = {
    0: "#ececec",
    1: "#ececec",
    2: "#ececec",
    3: "#ececec"
    // 0: "#11ff00",//difficultyによるボタン職変更は一旦外す
    // 1: "#bbffdd",
    // 2: "#ffaa77",
    // 3: "#cc2222"
  };


  mokuji_detail_difficulty_color = {
    0: "easy",//"#11ff00",
    1: "normal",//"#bbffdd",
    2: "hard",//"#ffaa77",
    3: "hell",//"#cc2222"
  };
  mokujiButtonDifficultyLabel = {
    0: "易<BR>問",
    1: "中<BR>級",
    2: "上<BR>級",
    3: "難<BR>問"
  };
  mokujiBgColor = "#000000";
  kaitouToukeiChartData = [];//: KaitouToukeiChartData[] = [];
  kaitouToukeiChartSize: any[] = [
    this.platform.width() / 1.2,
    this.platform.height() / 3
  ];


  enter_app_done = false;
  policy_html_string = '';
  mokuji_detail_col_size = 6;//もくじ詳細の問題の列表示デフォルト数決めるカラム幅

  todayDateStr: string = "";
  //mondaisyu_detail_visible = {};
  mokuji_detail_visible = {};

  innerState: string = "default";//下部の目次のアニメーション状態の変数
  //barChartAccordionOpenTargetMondaiId: string = "default";
  scatterChartAccordionOpenTargetMondaiId: string = "default";
  furikaeriAccordionOpenTargetStr: string = "default";
  isIosApp: boolean = false;
  testStr: String = "";

  nameCategoryModeMokuji = {
    true: "📂単元別",
    false: "📆年度別",
  };

  nameLevel = {
    0: "🔰基礎問題",
    1: '💬解説有り',
    2: '全問表示',
    // 0: "🔰ウォームアップ",
    // 1: '🌱初級',
    // 2: '☘️中級',
    // 3: '🌲上級',
  };

  nameMondaiSyuId = {
    0: "🌈全科目",
    101: "📈経済学・経済政策",
    102: "￥財務・会計",
    103: "👔企業経営理論",
    104: "🏭運営管理",
    105: "⚖️経営法務",
    106: "💻経営情報システム",
    107: "📊中小企業経営・政策",
    201: "事例I・2次試験",
    202: "事例II・2次試験",
    203: "事例III・2次試験",
    204: "事例VI・2次試験"
  };

  pickerColumnsDef = [
    {
      name: 'categoryMode',
      options: [
        {
          text: '📂単元別',
          value: true
        },
        {
          text: '📆年度別',
          value: false
        }
      ]
    },
    {
      name: 'mondaiSyuId',
      options: [
        {
          text: "🌈全科目",
          value: 0
        },
        {
          text: "📈経済",
          value: 101
        },
        {
          text: "💰財務",
          value: 102
        },
        {
          text: "👔経営",
          value: 103
        },
        {
          text: "🏭運営 ",//🛒🏭⚙️
          value: 104
        },
        {
          text: "⚖️法務 ",
          value: 105
        },
        {
          text: "💻情報",
          value: 106
        },
        {
          text: "📊中小",
          value: 107
        },
        {
          text: "事例I・2次試験",
          value: 201
        },
        {
          text: "事例II・2次試験",
          value: 202
        },
        {
          text: "事例III・2次試験",
          value: 203
        },
        {
          text: "事例IV・2次試験",
          value: 204
        },
      ]
    },
    {
      name: 'level',
      options: [
        {
          text: '🔰基礎問題',
          value: 0
        },
        {
          text: '💬解説有り',
          value: 1
        },
        {
          text: '全問表示',
          value: 2
        }
        // {
        //   text: '🔰ウォームアップ',
        //   value: 0
        // },
        // {
        //   text: '🌱初級',
        //   value: 1
        // },
        // {
        //   text: '🌴中級 ',
        //   value: 2
        // },
        // {
        //   text: '🌲上級',
        //   value: 3
        // },
      ]
    },
  ];

  // sentakuBadgeHyouji = {
  //   'ア': 'ア',
  //   'イ': 'イ',
  //   'ウ': 'ウ',
  //   'エ': 'エ',
  //   'オ': 'オ',
  //   //'カ': 'カ',
  //   'exclude_failure': '🗑️',
  // };

  tutorial_color = 'tertiary';
  scrollElement;
  divOffsetTop;

  memo = {};
  breadCrumbsClassName: string = 'breadcrumbs-list-visible'



  public mokujiVirtualList: MokujiData[] = [];
  public mokujiDataList: MokujiData[] = [];
  public virtualScrollWindowSize = 15;

  // public mokujiVirtualList_mokujiMondaiList: number[] = [];
  // public virtualScrollWindowSize_mokujiMondaiList = 15;


  //mokujiTitleDisplayFlag: boolean = false;

  slideOpts = {
    spaceBetween: 0,
    autoHeight: 'true',
    followFinger: true,
  };
  //slideNumber: number = 0;
  //slideEndBool: boolean = false;
  //slideLength: number = 10;

  //mainSlideNumArray:number[] = [0,1,2,3]

  tokuSlideClass = 'tokuSlideStart';

  tokuSlideVisible: boolean = true;
  visibleChartName: string = '';

  // あしあとのグラフ設定
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  yAxisLabel = '問題数';
  showYAxisLabel = false;

  ashiato_yScaleMin: number = 0;
  colorScheme_timer = {
    domain: [
      '#ffce00', //経済
      '#f07841', //財務
      '#7044ff', //経営
      '#3880ff', //運営
      '#0cd1e8', //法務
      '#10dc60', //情報
      '#96988f', //中小
    ]
  };
  ashiatoRangeVisible: boolean = false;
  chartSize: any[] = [
    this.platform.width() / 1.2,
    this.platform.height() / 5
  ];

  tmpDate_last;
  tmpDate_today;
  summaryMetricsVisible: boolean = false;

  mokujiZeroMenuArray = [
    {
      'label':"年度順",
      'className':"fas fa-calendar-alt fa-6x fa-fw",
      'categorySlideSelected_parameter':"false",
    },
    {
      'label':"出題頻度順",
      'className':"fas fa-folder fa-6x fa-fw",
      'categorySlideSelected_parameter':"true",
    },
    {
      'label':"不正解の問題",
      'className':"far fa-dizzy fa-6x fa-fw",
      'categorySlideSelected_parameter':"onlyFuseikai",
    },
  ];
  // legendPosition = 'right';//'below';

  amazon_url:{
    101:string;
    102:string;
    103:string;
    104:string;
    105:string;
    106:string;
    107:string;
    201:string;
    202:string;
    203:string;
    204:string;
  } = {
    101: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054477&linkId=308dde79ea8e66a71c76817f18d2bc48",
    102: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054485&linkId=19a1196036f62485a1bb645e165800a0",
    103: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054493&linkId=a765fe69d907eaf50ec5fa02f63a66af",
    104: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054507&linkId=c02b2bb428f9023f77649d80e89acf42",
    105: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054515&linkId=9a38f974450780d354ce372a450d871d",
    106: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054531&linkId=b7f3e65a75df8e57ed712eda359588a3",
    // 106: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054523&linkId=3a7b043c96152d3c34f9d8c79e6e01c2",
    107: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054531&linkId=b7f3e65a75df8e57ed712eda359588a3",
    201: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054531&linkId=b7f3e65a75df8e57ed712eda359588a3",
    202: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054531&linkId=b7f3e65a75df8e57ed712eda359588a3",
    203: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054531&linkId=b7f3e65a75df8e57ed712eda359588a3",
    204: "//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=opendrill-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4496054531&linkId=b7f3e65a75df8e57ed712eda359588a3"
  };

  amazon_url_iosApp:{
    101:string[];
    102:string[];
    103:string[];
    104:string[];
    105:string[];
    106:string[];
    107:string[];
    201:string[];
    202:string[];
    203:string[];
    204:string[];
  } = {
    101: ['https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?adgrpid=67231837884&dchild=1&gclid=Cj0KCQjwupD4BRD4ARIsABJMmZ-pllLucnsUHmJN13hG2UZ4ZnmizuE6Eepnmq22iWXn6z5TBF5Gk3IaAhlMEALw_wcB&hvadid=338570618348&hvdev=c&hvlocphy=1009343&hvnetw=g&hvqmt=e&hvrand=10223694217314047220&hvtargid=kwd-647112979617&hydadcr=26603_10270219&jp-ad-ap=0&keywords=%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594164846&sr=8-7&linkCode=li2&tag=opendrill-22&linkId=41f823f42421a70bca443b11842630f2&language=ja_JP',
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054477&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li2&o=9&a=4496054477"
  ],
    102: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054485&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li2&o=9&a=4496054485"],
    103: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054493&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li2&o=9&a=4496054493"],
    104: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054507&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li2&o=9&a=4496054507"],
    105: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054515&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li2&o=9&a=4496054515"],
    106: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054531&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li3&o=9&a=4496054531"],
    // ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    // "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054523&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    // "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li2&o=9&a=4496054523"],
    107: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054531&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li3&o=9&a=4496054531"],
    201: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054531&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li3&o=9&a=4496054531"],
    202: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054531&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li3&o=9&a=4496054531"],
    203: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054531&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li3&o=9&a=4496054531"],
    204: ["https://www.amazon.co.jp/1-%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96-2020%E5%B9%B4%E7%89%88-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC-%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E8%A3%BD%E4%BD%9C%E5%A7%94%E5%93%A1%E4%BC%9A/dp/4496054477/ref=as_li_ss_il?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E7%B5%8C%E6%B8%88%E5%AD%A6%E3%83%BB%E7%B5%8C%E6%B8%88%E6%94%BF%E7%AD%96+(2020%E5%B9%B4%E7%89%88+%E9%81%8E%E5%8E%BB%E5%95%8F%E5%AE%8C%E5%85%A8%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&qid=1594165163&sr=8-1&linkCode=li2&tag=opendrill-22&linkId=45464c9c7dec6e0041a87115d62b1dd4&language=ja_JP",
    "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4496054531&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=opendrill-22&language=ja_JP",
    "https://ir-jp.amazon-adsystem.com/e/ir?t=opendrill-22&language=ja_JP&l=li3&o=9&a=4496054531"]
  };

  //todos: Kaitoubun[] = [];
  //kaitouBun: KaitouBun;// = new KaitouBun();
  //scatterTangoRanking = {};

  //scatterValue = {};
  //scatterDataAll = {};
  //scatterColorArr = [];

  // scatterSettings = [
  //   {
  //     name: "合否不明",
  //     //color: "rgba(100,100,100,0.5)",
  //     symbol: "circle-open",
  //     visible: "student",
  //   },
  //   {
  //     name: "×BCD(サイズ小は不合格)　●A(サイズ小は合格)　※色は得点目安",//"合否判明。※得点の色は目安、サイズ大はA、×は不合格(サイズ大はBCD)",
  //     //color: "rgba(50,250,250,0.5)",
  //     //symbol: "circle",
  //     visible: "gouhiKaitouZumi",
  //   },
  //   {
  //     name: "予備校",
  //     //color: "rgba(100,130,100,0.5)",
  //     symbol: "star-triangle-down-open",//"diamond-open",
  //     visible: "school",
  //   },
  //   {
  //     name: "あなたの解答",
  //     //color: "rgba(100,100,200,0.5)",
  //     symbol: "star",//"star",//"hexagram",//
  //     visible: "mine",
  //   },
  // ];
  //newtodos: Todo[] = [];
  tokutenSelectArr = [100,99,98,97,96,95,94,93,92,91,90,89,88,87,86,85,84,83,82,81,80,79,78,77,76,75,74,73,72,71,70,69,68,67,66,65,64,63,62,61,60,59,58,57,56,55,54,53,52,51,50,49,48,47,46,45,44,43,42,41,40,39,38,37,36,35,34,33,32,31,30,29,28,27,26,25,24,23,22,21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0];
  gouhiStrTokutenKaijiNasiArr = ["合格","A","B","C","D"];

  result: any
  myImage: string = null;
  myImageBase64Data = '';
  // hikakuScatterData = [
  //   {
  //     x: ['giraffes', 'orangutans', 'monkeys'],
  //     y: [20, 14, 23],
  //     type: 'bar'
  //   } as Plotly.ScatterData
  // ];
  urlParamUrlOptionString: string = "";
  mediaRecorder: any;
  b64sound = "";
  speechToTextString = "　";
  isRecordingSound = false;
  nijiBunsekiSyousaiHyoujiBool = false;
  //変数定義終わり/////////////////////////////

  //執筆モード設定//////////////////////
  sippituMode = false;//執筆時だけ　//false//　から //true// に設定する。mondaidata.ts更新ですぐlocalhostに反映される
  fileSyuturyoku = false;
  // 解説執筆時の表示テスト用領域。ここで確認して、問題なければmondaidataに貼って、インデントなくして保存する
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public firestoreAccessor: FirestoreAccessorService,
    private translation: GoogleTranslationServiceService,
    public domSanitizer: DomSanitizer,
    public platform: Platform,
    public loadingCtrl: LoadingController,
    private modalController: ModalController,
    public toastController: ToastController,
    //public todoService: TodoService,
    //private clipboard: Clipboard,
    private vision: GoogleCloudVisionServiceService,
    public videoService: VideoService,
    public alertController: AlertController,
  ) {
    //２ファイルに分割した問題マスタを結合する処理
    let mondaidata_r1:Mondai[] = [];
    mondaidata_r1 = MONDAIDATA_r1;
    let mondaidata_r2:Mondai[] = [];
    mondaidata_r2 = MONDAIDATA_r2;
    let tmpMondai = [];
    tmpMondai = tmpMondai.concat(mondaidata_r2);
    tmpMondai = tmpMondai.concat(mondaidata_r1);
    const MONDAIDATA:Mondai[] = tmpMondai;//問題マスタを結合

    this.firestoreAccessor.nendoName = this.nendoName;
    this.firestoreAccessor.kaitouHistory = this.firestoreAccessor.getAllRecord('kaitouHistory');
    if (this.firestoreAccessor.getAllRecord('kaitouHistory2ji')){
      //カラでなければ、読み込み
      this.firestoreAccessor.kaitouHistory2ji = this.firestoreAccessor.getAllRecord('kaitouHistory2ji');
    }

    //初回起動時のmyName埋め込み処理
    //console.log("this.currentGuiStatus.myName"+this.firestoreAccessor.currentGuiStatus.myName)
    //myNameが空文字の場合、値を生成する
    if (this.firestoreAccessor.currentGuiStatus.myName == "") {
      let rand = Math.floor(1000 + Math.random() * 90000000);
      let newMyName: string = [
        this.dateFormat.format(new Date(), 'mn-yyyyMMdd-hhmmss-'), rand
      ].join('');
      this.firestoreAccessor.currentGuiStatus.myName = newMyName;
    }
    //テスト環境の場合は識別できる文字列をMNに付加する
    if (RegExp('localhost').test(this.platform.url()) && RegExp('desktop').test(String(this.firestoreAccessor.currentGuiStatus.platformName)) ){
      if (!RegExp('test_').test(this.firestoreAccessor.currentGuiStatus.myName)){
        //すでにtest_が付与されていれば変更しない
        this.firestoreAccessor.currentGuiStatus.myName = 'test_'+this.firestoreAccessor.currentGuiStatus.myName;
      }
    }

    // if (this.firestoreAccessor.getAllRecord('timerHistory')){
    //   //LocalDBがからでなければ、変数読み込み
    //   this.firestoreAccessor.timerHistory = this.firestoreAccessor.getAllRecord('timerHistory');
    // }

    if (this.sippituMode && RegExp('localhost').test(this.platform.url())  ) {//ローカル環境、かつ、執筆のフラグある時だけ処理実行

      //mondaidataに、カテゴリや正答率など各種外部データ埋込む。#ファイル
      //言い換えると（MONDAIDATAAddedCategory）は使わずここで埋め込んだメモリ上の値を使う
      this.firestoreAccessor.mondaidataOnFile = MONDAIDATA;
      this.fillCategoryNameToMondaidata();
      this.fillSeitourituToMondaidata();
      this.fillDifficultyToMondaidata();

      //執筆時は、問題一覧を生成する
      let ichiranData = this.firestoreAccessor.generateIchirandata();
      // let ichiranData = {};
      // for (var tmpMondaiSyuId in this.kamokuName) {
      //   ichiranData[tmpMondaiSyuId] = this.firestoreAccessor.getMokujiDataList(Number(tmpMondaiSyuId), "false" ,2)
      // }

      if (this.fileSyuturyoku ) {
        //ファイルに保存(Mondai)
        let headerCodeString_mondai = `import { Mondai } from './mondai';
        export const MONDAIDATAAddedCategory: Mondai[] = `
        var data = new Blob([headerCodeString_mondai + JSON.stringify(this.firestoreAccessor.mondaidataOnFile)], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(data, 'mondaidataAddedCategory.ts');

        //ファイルに保存(Mokuji)
        let headerCodeString_mokuji = `import { MokujiData } from '../models/MokujiData';export const MOKUJIDATAMASTER: MokujiData[] = `
        var data = new Blob([headerCodeString_mokuji + JSON.stringify(this.generateMokujiDataTs())], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(data, 'MOKUJIDATAMASTER.ts');

        //ファイルに保存(Ichiran)
        let headerCodeString_ichiran = `import { MokujiData } from '../models/MokujiData';export const ICHIRANDATAMASTER = `
        var data = new Blob([headerCodeString_ichiran + JSON.stringify(ichiranData)], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(data, 'ICHIRANDATAMASTER.ts');

        
        //YOUGOを含む問題のリストを取得し、ファイルに保存(Yougo)
        let headerCodeString_yougo = `import { YougoData } from '../models/YougoData';export const YOUGODATAFILLEDMONDAI = `

        //用語のマスターを順になめて、OK単語とNG単語にあったMondai[]取得して
        //OBJをファイルに書き出す。
        for (let i_ydm in this.yougoDataMaster){
          //this.yougoDataMaster[i_ydm].mondaiArray = this.firestoreAccessor.getMondaiArr(this.yougoDataMaster[i_ydm].targetOkWords);
          this.yougoDataMaster[i_ydm].mondaiIdArray = this.firestoreAccessor.getMondaiIdArr(this.yougoDataMaster[i_ydm].targetOkWords);
        }
        var data = new Blob([headerCodeString_yougo + JSON.stringify(this.yougoDataMaster)], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(data, 'YOUGODATAFILLEDMONDAI.ts');
      }
    }
    else {
      //非執筆時はファイルを参照
      this.firestoreAccessor.mondaidataOnFile = MONDAIDATAAddedCategory;
    }

    this.reloadMokujiDataSource();


    if (this.firestoreAccessor.getAllRecord("currentGuiStatus")) {
      Object.assign(//既存レコードに、項目追加した場合にデフォルト値を設定するための処理
        this.firestoreAccessor.currentGuiStatus,
        this.firestoreAccessor.getAllRecord("currentGuiStatus")
      );
      //２次追加に伴う修正//値が存在しなければ枠を作る。値はひとまず固定
      let defaultValueCheckArray:string[] =["categoryMode","mokujiSearchKeyword","currentMondai","currentMondaiList","currentMokuji","level","kaitouCountToday","kaitouCountAll","timerCountAll","currentMainSlidePosition"];
      for (let key_currentGuiStatus_key in defaultValueCheckArray){
        //
        if (!this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][201]){
          //201が存在しないなら、枠だけ作る
          this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][201] = this.firestoreAccessor.currentGuiStatus_defaultValue[defaultValueCheckArray[key_currentGuiStatus_key]][201];
        }
        if (!this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][202]){
          //202が存在しないなら、枠だけ作る
          this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][202] = this.firestoreAccessor.currentGuiStatus_defaultValue[defaultValueCheckArray[key_currentGuiStatus_key]][202];
        }
        if (!this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][203]){
          //203が存在しないなら、枠だけ作る
          this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][203] = this.firestoreAccessor.currentGuiStatus_defaultValue[defaultValueCheckArray[key_currentGuiStatus_key]][203];
        }
        if (!this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][204]){
          //204が存在しないなら、枠だけ作る
          this.firestoreAccessor.currentGuiStatus[defaultValueCheckArray[key_currentGuiStatus_key]][204] = this.firestoreAccessor.currentGuiStatus_defaultValue[defaultValueCheckArray[key_currentGuiStatus_key]][204];
        }
      }
    }
    this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);


    for (var tmpMondaiSyuId in this.kamokuName) {
      //主に初回起動時、全科目、問題リストを初期化させる
      //console.log("tmpMondaiSyuId__"+tmpMondaiSyuId)
      if (this.firestoreAccessor.currentGuiStatus.currentMondaiList[tmpMondaiSyuId].length == 0){
        let defaultMondaiId = {
          101:101030010,
          102:102030010,
          103:103030010,
          104:104030010,
          105:105030010,
          106:106030010,
          107:107030010,
          201:201030111,
          202:202030111,
          203:203030111,
          204:204030111,

        }
        this.firestoreAccessor.setMondaiList(defaultMondaiId[tmpMondaiSyuId]);
      }
    }
    //カレントの問題をセット
    this.mokujiTabTapped(""+this.firestoreAccessor.currentGuiStatus.currentTab);



    // this.firestoreAccessor.currentMokujiStatus.levelMokuji = {
    //   101: this.firestoreAccessor.currentGuiStatus.level[101],
    //   102: this.firestoreAccessor.currentGuiStatus.level[102],
    //   103: this.firestoreAccessor.currentGuiStatus.level[103],
    //   104: this.firestoreAccessor.currentGuiStatus.level[104],
    //   105: this.firestoreAccessor.currentGuiStatus.level[105],
    //   106: this.firestoreAccessor.currentGuiStatus.level[106],
    //   107: this.firestoreAccessor.currentGuiStatus.level[107],
    //   201: this.firestoreAccessor.currentGuiStatus.level[201],
    // };

    // this.firestoreAccessor.currentMokujiStatus.categoryModeMokuji = {
    //   101: ''+this.firestoreAccessor.currentGuiStatus.categoryMode[101],
    //   102: ''+ this.firestoreAccessor.currentGuiStatus.categoryMode[102],
    //   103: ''+ this.firestoreAccessor.currentGuiStatus.categoryMode[103],
    //   104: ''+ this.firestoreAccessor.currentGuiStatus.categoryMode[104],
    //   105: ''+ this.firestoreAccessor.currentGuiStatus.categoryMode[105],
    //   106: ''+ this.firestoreAccessor.currentGuiStatus.categoryMode[106],
    //   107: ''+ this.firestoreAccessor.currentGuiStatus.categoryMode[107],
    //   201: ''+ this.firestoreAccessor.currentGuiStatus.categoryMode[201],
    // };


    this.firestoreAccessor.currentGuiStatus.platformName = this.platform.platforms();

    if (RegExp('ios').test(String(this.firestoreAccessor.currentGuiStatus.platformName)) && RegExp('cordova').test(String(this.firestoreAccessor.currentGuiStatus.platformName)))
    {
      this.isIosApp = true;
    } else {
      this.isIosApp = false;
    }

    if (RegExp('cordova').test(String(this.firestoreAccessor.currentGuiStatus.platformName)))
    {
      //スマホの場合は、同意を求める
      this.firestoreAccessor.currentGuiStatus.privacy_policy_accepted = true;
    }


    // // if (this.firestoreAccessor.currentGuiStatus.currentTab == 0){
    // //   //インストール直後初回起動時の、チュートリアル表示
    // //   this.presentToastWithOptions('🔰 右上の「MENU」から科目を選択してください', 10000,'middle');
    // // }
    // for (var key in this.firestoreAccessor.currentGuiStatus.currentMokuji) {
    //   //インストール直後初回起動時などの、カレントもくじのNull埋め。カラの場合は、最初のもくじ。
    //   if (
    //     this.firestoreAccessor.currentGuiStatus.currentMokuji[key]) {
    //     //カレントのもくじが存在する科目はなにも処理せずここをスルー
    //   } else {
    //     //this.firestoreAccessor.currentGuiStatus.currentMokuji[key] = this.mokujiDataSourceDic[key][this.firestoreAccessor.currentGuiStatus.categoryMode[key]][0];
    //     this.setCurrentMokujiCategory(Number(key), this.mokujiDataSourceDic[key][this.firestoreAccessor.currentGuiStatus.categoryMode[key]][0])
    //   }
    // }

    // for (var key in this.firestoreAccessor.currentGuiStatus.currentMondai) {
    //   //インストール直後初回起動時などの、カレント問題のNull埋め。カラの場合は、最初のカテゴリの最初の問題。
    //   if (this.firestoreAccessor.currentGuiStatus.currentMondai[key]) {
    //     //カレントの問題が存在する科目はなにも処理せずここをスルー
    //   } else {
    //     //カレントの問題が存在しない場合は、デフォルトの問題（該当の科目、カテゴリモードの最初の問題）を表示
    //     // this.jumpToMondai(Number(key), this.mokujiDataSourceDic[key][this.firestoreAccessor.currentGuiStatus.categoryMode[key]][0].mokujiMondaiList[0]);

    //     //this.firestoreAccessor.getOneMondaiFromCfs(Number(key), this.mokujiDataSourceDic[key][this.firestoreAccessor.currentGuiStatus.categoryMode[key]][0].mokujiMondaiList[0],true);//falseなのは、初回起動でCurrentの問題がない場合にエラーになるから
    //     this.jumpToMondai(Number(key), this.mokujiDataSourceDic[key][this.firestoreAccessor.currentGuiStatus.categoryMode[key]][0].mokujiMondaiList[0], 0, false);
    //   }
    // }

    // this.firestoreAccessor.todayDate = new Date();
    // this.firestoreAccessor.todayDate.setTime(this.firestoreAccessor.todayDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
    // if (!this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(this.firestoreAccessor.todayDate).substr(1, 10) + "T00:00:00.000Z"]) {
    //   //時間を入れる変数の入れ物がなければ作る
    //   this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(this.firestoreAccessor.todayDate).substr(1, 10) + "T00:00:00.000Z"] = {
    //     0: 0,
    //     101: 0,
    //     102: 0,
    //     103: 0,
    //     104: 0,
    //     105: 0,
    //     106: 0,
    //     107: 0,
    //     201: 0
    //   };
    // }
    // this.todayDateStr = JSON.stringify(this.firestoreAccessor.todayDate).substr(1, 4);

    // //LCDの問題数更新
    // setTimeout(() => {
    //   //console.log('hello')
    //   this.firestoreAccessor.reloadAshiatoData();
    //   setTimeout(() => {
    //     //console.log('callback')
    //     this.firestoreAccessor.updateCount_kaitou_and_timer();
    //   }, 200)
    // }, 200)

    // //日付絞り込みGUIの影響をデフォルト値に戻す
    // this.firestoreAccessor.currentGuiStatus.dateRangeDual = { "lower": -(firestoreAccessor.daysFromStartingDate + 1), "upper": 0 };

    if (!this.firestoreAccessor.currentGuiStatus.privacy_policy_accepted && !this.checkDirectUrlAccess()) {
      //同意していない場合、（解答実績ない場合の分岐作り込みが面倒なのでひとまず）
      //かつ、URLから直接来て「いない」場合は、
      //Tutorial_modeをONにする。
      this.set_tutorial_mode(true);

      //welcomeモーダル表示
      this.openModal("welcome", false);
    }

    if ( this.firestoreAccessor.currentGuiStatus.currentKansouId != ''){
      this.firestoreAccessor.setKansouDoc();
    }

    // //当面、過去に変更できた版のユーザも全問題表示になるようLEVEL変更。
    // this.firestoreAccessor.currentMokujiStatus.levelMokuji= {
    //   101: 2,
    //   102: 2,
    //   103: 2,
    //   104: 2,
    //   105: 2,
    //   106: 2,
    //   107: 2,
    //   201: 2,
    // };
    // this.firestoreAccessor.currentGuiStatus.level = {
    //   101: 2,
    //   102: 2,
    //   103: 2,
    //   104: 2,
    //   105: 2,
    //   106: 2,
    //   107: 2,
    //   201: 2,
    // };


  }

  ngOnInit() {
  }

  // ngAfterViewInit() {
  //   this.slideQueryList.forEach((item, index) => {
  //     if (this.textAreaChildren[this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab][index].mondaiId] !== item) {
  //       setTimeout(() => {
  //         this.textAreaChildren[this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab][index].mondaiId] = item;
  //       }, 0);
  //     }
  //   });
  // }

  ionViewWillEnter() {
    //タイマースレッド起動
    //this.startActiveCheckThread();
    this.firestoreAccessor.tokutenSyuukei(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab]);

  }

  ionViewDidEnter() {
    //URLでmondaiIdなど指定したダイレクトアクセスか判定。
    if (this.checkDirectUrlAccess()) {
      //URLパラメタ取得
      let urlParamMondaiId: number = Number(this.route.snapshot.paramMap.get('mondaiId'));
      this.urlParamUrlOptionString = this.route.snapshot.paramMap.get('urlOptionString');//履歴削除など、ユーザサポートのための変数だけ作成しておいた
      let urlParamHint: boolean;
      if (this.route.snapshot.paramMap.get('hint_visible') == 'true') {
        urlParamHint = true;
      } else if (this.route.snapshot.paramMap.get('hint_visible') == 'false') {
        urlParamHint = false;
      }
      let urlParamCategoryMode: boolean;
      if (this.route.snapshot.paramMap.get('categoryMode') == 'true') {
        urlParamCategoryMode = true;
      } else if (this.route.snapshot.paramMap.get('categoryMode') == 'false') {
        urlParamCategoryMode = false;
      }

      let cMData: Mondai = this.firestoreAccessor.getOneMondaiFromFile(urlParamMondaiId);
      //URLパラメタの適正チェック。ALL OKだった場合は、該当する問題、目次を引き、ModeのBoolなどをCurrentGUIに持ってくる
      this.setCurrentMokujiCategory(cMData.mondaiSyuId, this.firestoreAccessor.getOneMokujiData(cMData.mondaiId, urlParamCategoryMode));

      //メインスライドの位置を解くスライドにする
      this.firestoreAccessor.currentGuiStatus.currentMainSlidePosition[cMData.mondaiSyuId] = 3;
      // //科目のタブ切り替え
      // this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab] = cMData;

      //関連問題を削除するために、一旦初期化する
      this.firestoreAccessor.currentGuiStatus.currentMondaiList[cMData.mondaiSyuId] = [];

      this.jumpToKamoku(cMData.mondaiSyuId);
      //カレントの問題を変更する
      this.firestoreAccessor.setMondaiList(cMData.mondaiId);
      //console.log("AAA_cMData.mondaiId"+cMData.mondaiId)

      this.mondaiSlideSelected(cMData.mondaiSyuId, cMData.mondaiId);
      //if (cMData.niji == true){
      
      this.firestoreAccessor.currentGuiStatus.currentNendoRange[this.firestoreAccessor.currentGuiStatus.currentTab] = cMData.nendo;
      console.log("用語のリンクから移動。用語モーダル開きます")
      
      //解答文字のパラメタがsentakuであれは、埋める
      if (this.urlParamUrlOptionString.slice(0, 7) == 'sentaku') {
        //URLの解答欄に入れる文字列がnone出ない場合のみ、埋め込みする
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku = this.urlParamUrlOptionString.slice(7);

        if (urlParamHint == true) {
          //解説ありの場合は、採点処理を走らせる
          this.selectSentakushi(cMData, this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku, 'sentaku', true);
        }
      // } else if (urlParamUrlOptionString.slice(0, 7) == 'sanpuzu') {
      //   //リンクからいきなり散布図表示させる場合。エラーになるのでコメントアウト
      //   //散布図を見るボタンを押したときと、同じ関数実行
      //   this.hikaku(
      //     this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab],
      //     this.firestoreAccessor.currentGuiStatus.currentKaitou[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId].sentaku,
      //     'sentaku'
      //   )
      } else if (this.urlParamUrlOptionString == 'gouhiJouhou') {
        //特典開示のURLの場合は、特典開示登録用のModal開く
        this.openModal("gouhiJouhou", false);
      } else if (this.urlParamUrlOptionString.indexOf('yougo') != -1) {
        //用語のリンクだった場合は、モーダル開く
        //console.log("用語のリンクから移動。用語モーダル開きます")
        this.openModal("yougo", true);
        this.urlParamUrlOptionString = "";//Paramが残っていると、URLからYOUGO指定のあと、普通にカメラボタン押したときにカメラが上がらなくなるので空文字にしておく
      } else if (this.urlParamUrlOptionString.indexOf('kaiji') != -1) {
        //開示のリンクだった場合は、モーダル開く
        //console.log("用語のリンクから移動。用語モーダル開きます")
        this.openModal("kaiji", true);
      }

      if (cMData.niji == true) {
        //二次試験の問題の場合は、対象の問題の解説アコーディオンひらく
        this.innerState = '' + cMData.mondaiId;
      }


      //this.jumpToMondai(mondaiMatchedUrlParam.mondaiSyuId, mondaiMatchedUrlParam.mondaiId, 500);

      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].hint_visible = urlParamHint;
    } else {
      //ダイレクトアクセスでない場合は、最終スライドの再現
      this.jumpToKamoku(this.firestoreAccessor.currentGuiStatus.currentTab);
    }




  }


  compareById(o1, o2) {
    console.log("o1.id_"+o1.id +"__o2.id_"+o2.id  )
    return o1.id === o2.id
  }

  checkDirectUrlAccess(): boolean {
    let directUrlAccessBool: boolean = false;
    //URLでmondaiIdなど指定したダイレクトアクセスか判定。
    //URLパラメタ取得
    let urlParamMondaiId: number = Number(this.route.snapshot.paramMap.get('mondaiId'));
    let urlParamHint: boolean;
    if (this.route.snapshot.paramMap.get('hint_visible') == 'true') {
      urlParamHint = true;
    } else if (this.route.snapshot.paramMap.get('hint_visible') == 'false') {
      urlParamHint = false;
    }
    let urlParamCategoryMode: boolean;
    if (this.route.snapshot.paramMap.get('categoryMode') == 'true') {
      urlParamCategoryMode = true;
    } else if (this.route.snapshot.paramMap.get('categoryMode') == 'false') {
      urlParamCategoryMode = false;
    }

    let mondai_MatchedUrlParam: Mondai = this.firestoreAccessor.getOneMondaiFromFile(urlParamMondaiId);
    let mokujiData_MatchedUrlParam: MokujiData;
    if (mondai_MatchedUrlParam != undefined) {
      mokujiData_MatchedUrlParam = this.firestoreAccessor.getOneMokujiData(mondai_MatchedUrlParam.mondaiId, urlParamCategoryMode);
    }
    //console.log('mokujiData_MatchedUrlParam_'+JSON.stringify(mokujiData_MatchedUrlParam))

    if (mondai_MatchedUrlParam != undefined && urlParamHint != undefined && mokujiData_MatchedUrlParam != undefined) {
      //URLパラメタの適正チェック。ALL OKだった場合は、Trueを返す
      directUrlAccessBool = true;
    }
    return directUrlAccessBool;
  }

  // timer(){
  //   this.firestoreAccessor.timerCounter();
  // }

  scrollToGuiPosition( delayMilliSecond: number) {

    //問題の描画処理の遅れでセレクタ取得できないことがあるので、目次から遷移したときはWait入れている


    //問題の上部までスクロールする
    //該当の問題までスクロールするための位置を取得
    let mondaiArrayPosition:number = 0;
    for (var key_mondai in this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab]){
      //
      if (this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab][key_mondai] == this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId){
        //
        mondaiArrayPosition = Number(key_mondai);
      }
    }


    //console.log("DDDD_"+mondaiArrayPosition)
    this.delay(delayMilliSecond).then(any => {
      setTimeout(() => {
        //まず要素の場所取得
        //let cardsElements = document.querySelector('ion-content').querySelectorAll('.kanrenmondaiScrollPosition');
        let cardsElements = document.querySelector('ion-content').querySelectorAll('.mondai-card');

        setTimeout(() => {
          //座標の数値取得
          let targetScroolPointValue: number = window.pageYOffset + Number(cardsElements[mondaiArrayPosition].getBoundingClientRect().top) - 100;//ヘッダがずれるので100ずらす

          setTimeout(() => {
            //console.log("targetScroolPointValue_"+targetScroolPointValue)
            //スクロールする
            document.querySelector('ion-content').scrollByPoint(0, targetScroolPointValue, 1000);
          }, 100)
        }, 100)
      }, 100)

    });
  }


  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.scrollElement = this.theScrollEl.nativeElement.getScrollElement();
  //     console.log(this.scrollElement);
  //     this.doTheScroll();
  //   }, 40);
  // }

  // doTheScroll() {
  //   let container = document.querySelector('.container');
  //   let div = container.querySelectorAll('.scroll-test');
  //   let menu = document.querySelector('#menu');
  //   let that = this;

  //   menu.addEventListener('click', function (ev) {
  //     let target = ev.target;
  //     // console.log();
  //     let newId = 'div-' + target['id'];

  //     for (let i = 0; i < div.length; i++) {
  //       if (newId === div[i].id) {
  //         // console.log(h2[i].offsetTop);
  //         that.divOffsetTop = div[i]['offsetTop'];
  //       }
  //     }
  //     that.scrollElement.scrollToPoint(0, that.divOffsetTop, 200);

  //     console.log('divOffsetTop', that.divOffsetTop, '//', 'scrollElement.scrollTop', that.scrollElement.scrollTop);

  //   });
  // }

  showTab(tabName: number) {
    this.firestoreAccessor.currentGuiStatus.currentTab = Number(tabName);

    // setTimeout(() => {
    //   //当該科目の前回スライド位置を再現
    //   this.mainSlides.slideTo(this.firestoreAccessor.currentGuiStatus.currentMainSlidePosition[tabName]);
    //   setTimeout(() => {
    //     //スライド位置がもくじスライドの場合は、もくじ再読み込み
    //     this.checkSlideNumThenDoSomeAction(this.firestoreAccessor.currentGuiStatus.currentMainSlidePosition[tabName]);
    //     this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);
    //     setTimeout(() => {
    //       this.slideDidChange();//スライド位置を取得
    //     }, 0)
    //   }, 0)
    // }, 0)
  }

  // showTab_FirstTime(tabName:number) {
  //   //初回起動時のみのカレント科目設定。チュートリアル表示する
  //   this.openMokuji(false);
  //   // this.presentToastWithOptions('🔰 山のマーク（緑色の三角形）クリックで問題表示',15000,'middle');
  //   this.showTab(tabName);
  // }



  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => this.wait_delay_end());
  }

  wait_delay_end() { }//WAITのための何もしないメソッド


  categoryModeChanged() {
    //this.displaySpinner(`もくじ表示を切替中..`,1500);
    //this.delay(1000).then(any => {
    this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);
    //});
    // //画面のカテゴリーモードが変わったら、対象のもくじを読み込み直す
    // let tmpMokujiData: MokujiData[] = [];
    // if (this.currentGuiStatus.categoryMode[mondaiSyuId]=='true') {
    //   //categoryModeの場合は、単語が設定されているもの（すなわちカテゴリーモードのもくじ）を取得
    //   tmpMokujiData = this.mokujiData.filter(data => {
    //     return (data.mondaiSyuId == Number(mondaiSyuId) && data.tangoId);
    //   });
    // } else {
    //   tmpMokujiData = this.mokujiData.filter(data => {
    //     return (data.mondaiSyuId == Number(mondaiSyuId) && !data.tangoId);
    //   });
    // }

    // const returnMokujiData: MokujiData[] = JSON.parse(JSON.stringify(tmpMokujiData));
    // this.currentGuiStatus.mokujiData[mondaiSyuId] = returnMokujiData;

  }

  // getMokujiData(mondaiSyuId: number) {
  //   // this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.currentGuiStatus);

  //   //画面のカテゴリーモードが変わったら、対象のもくじを読み込み直す
  //   let tmpMokujiData: MokujiData[] = [];
  //   if (this.firestoreAccessor.currentGuiStatus.categoryMode[mondaiSyuId]) {
  //     //categoryModeの場合は、単語が設定されているもの（すなわちカテゴリーモードのもくじ）を取得
  //     tmpMokujiData = this.firestoreAccessor.mokujidataOnCache.filter(data => {
  //       return (data.mondaiSyuId == Number(mondaiSyuId) && data.tangoId);
  //     });
  //   } else {
  //     tmpMokujiData = this.firestoreAccessor.mokujidataOnCache.filter(data => {
  //       return (data.mondaiSyuId == Number(mondaiSyuId) && !data.tangoId);
  //     });
  //   }

  //   return tmpMokujiData;
  // }

  fillCategoryNameToMondaidata() {

    //for (var tmpMondaiSyuId in this.kamokuName) {

    //mondaidataのカテゴリの項目に、カテゴリのマスタの値を埋め込むメソッド
    for (var i_mon in this.firestoreAccessor.mondaidataOnFile) {
      //console.log("this.firestoreAccessor.mondaidataOnFile[i_mon]_"+JSON.stringify(i_mon));

      this.firestoreAccessor.mondaidataOnFile[i_mon].category = [];//変数初期化
      this.firestoreAccessor.mondaidataOnFile[i_mon].categoryName = [];//変数初期化
      //this.firestoreAccessor.mondaidataOnFile[i_mon].categoryKaisetu = [];//変数初期化

      //処理対象単語データの絞り込み
      let tmpTangoMaster: TangoData[] = this.tangoDataMaster.filter(data => {
        return (data.mondaiSyuId == this.firestoreAccessor.mondaidataOnFile[i_mon].mondaiSyuId);
      });

      //問題マスタで、単語マスタを検索して、categoruのArrayに格納
      for (var i_tan in tmpTangoMaster) {
        let matchedOkWordFlag = false;
        let matchedNgWordFlag = false;
        //単語ごとに、OKワード含有フラグ、NGワード含有フラグ立てていく。
        let targetOkWordsArray = tmpTangoMaster[i_tan].targetOkWords;
        let targetNgWordsArray = [];
        if (tmpTangoMaster[i_tan].targetNgWords != null) {
          if (tmpTangoMaster[i_tan].targetNgWords[0] != "") {
            targetNgWordsArray = tmpTangoMaster[i_tan].targetNgWords;
          }
        }
        for (var i_twa in targetOkWordsArray) {
          if (
            RegExp(targetOkWordsArray[i_twa],"m").test(this.firestoreAccessor.mondaidataOnFile[i_mon].mondaiBun)//正規表現検索

          ) {//単語マスタの検索キーワードを解説文が含むか正規表現で検索し、ヒットしたらフラグを立てる
            //カテゴリマスタの問題IDが一致する値で更新
            matchedOkWordFlag = true;
            for (var i_ng in targetNgWordsArray) {
              if (RegExp(targetNgWordsArray[i_ng],"m").test(this.firestoreAccessor.mondaidataOnFile[i_mon].mondaiBun)
              ) {
                matchedNgWordFlag = true;
                break;
              }
            }
            break;
          }
        }
        if (matchedOkWordFlag == true && matchedNgWordFlag != true) {
          //単語マスタの検索キーワードを解説文が含む場合は、「単語IDを」カテゴリにPUSH。カテゴリ名と、解説も。
          this.firestoreAccessor.mondaidataOnFile[i_mon].category.push(tmpTangoMaster[i_tan].tangoId);
          this.firestoreAccessor.mondaidataOnFile[i_mon].categoryName.push(tmpTangoMaster[i_tan].name);
          //this.firestoreAccessor.mondaidataOnFile[i_mon].categoryKaisetu.push(tmpTangoMaster[i_tan].kaisetu);
        }
      }
    }
  }

  getTangoData(tangoId: string) {
    //let tangoId: number = Number(targetTangoId);
    //指定した単語IDの単語データを返す
    let tmpTangoMaster: TangoData[] = this.tangoDataMaster.filter(data => {
      return (data.tangoId == tangoId);
    });
    //console.log("tmpTangoMaster[0]_"+tmpTangoMaster[0]);
    return tmpTangoMaster[0];
  }

  fillSeitourituToMondaidata() {
    //mondaidataのカテゴリの項目に、チャートのデータ、正答率、難易度を埋め込むメソッド

    for (var i_mon in this.firestoreAccessor.mondaidataOnFile) {
      this.firestoreAccessor.mondaidataOnFile[i_mon].kaitouToukeiChartData = [{ name: '', value: 0 }];//'';//変数初期化

      //統計JSONのファイルを読み込んで、Fillする
      //console.log('AA'+JSON.stringify(kaitouToukeiChartData));
      this.firestoreAccessor.mondaidataOnFile[i_mon].kaitouToukeiChartData = kaitouToukeiChartData[this.firestoreAccessor.mondaidataOnFile[i_mon].mondaiId];//JSON.stringify(kaitouToukeiChartData[this.firestoreAccessor.mondaidataOnFile[i_mon].mondaiId]);

      //Mondaiの正答率をFillする
      //統計チャートの該当問題のレコードを抽出
      //console.log("kaitouToukeiChartData_"+kaitouToukeiChartData[this.firestoreAccessor.mondaidataOnFile[i_mon].mondaiId])
      let targetElementArray = kaitouToukeiChartData[this.firestoreAccessor.mondaidataOnFile[i_mon].mondaiId];

      //該当問題の正解選択肢のデータを取得
      //生成スクリプト21_KaitouToukeiMasterUpdater.tsがtsのUPDATEか何かで使えなくなったので、固定値を埋めるためコメントアウト＠2021/10/21
      // let targetElement = targetElementArray.filter(data => {
      //   return (data.name == this.firestoreAccessor.mondaidataOnFile[i_mon].seikai);
      // })[0];

      this.firestoreAccessor.mondaidataOnFile[i_mon].seitouritu = 0.5//targetElement.value;
    }
  }

  fillDifficultyToMondaidata() {
    //難易度を埋める
    let seitouritu_array: number[] = this.firestoreAccessor.mondaidataOnFile.map(({ seitouritu }) => seitouritu);
    let quartile60 = quantileSeq(seitouritu_array, [15 / 100, 25 / 100, 80 / 100]);//純粋な四分位でなく、合格ラインの６割を中央値とするイメージ
    //console.log('quartile60_' + quartile60);
    //console.log('quartile60[0]_' + quartile60[0]);

    for (var i_mon in this.firestoreAccessor.mondaidataOnFile) {
      this.firestoreAccessor.mondaidataOnFile[i_mon].difficulty = 0;//デフォルト易で、変数初期化
      let target_seitouritu: number = this.firestoreAccessor.mondaidataOnFile[i_mon].seitouritu;
      //console.log('this.firestoreAccessor.mondaidataOnFile[i_mon].seitouritu_' + this.firestoreAccessor.mondaidataOnFile[i_mon].seitouritu);

      //console.log('target_seitouritu < quantileSeq[0]_' + target_seitouritu < quantileSeq[0]);
      if (this.firestoreAccessor.mondaidataOnFile[i_mon].seitouritu < quartile60[0]) {
        //難
        this.firestoreAccessor.mondaidataOnFile[i_mon].difficulty = 3
      } else if (this.firestoreAccessor.mondaidataOnFile[i_mon].seitouritu < quartile60[1]) {
        //やや難
        this.firestoreAccessor.mondaidataOnFile[i_mon].difficulty = 2
      } else if (this.firestoreAccessor.mondaidataOnFile[i_mon].seitouritu < quartile60[2]) {
        //やや易
        this.firestoreAccessor.mondaidataOnFile[i_mon].difficulty = 1
      }
    }
  }

  generateMokujiDataTs() {
    //もくじデータのtsの元データを生成するメソッド
    let mokujiData: MokujiData[] = [];
    let categoryModeArr = [true, false]

    for (var i_cm in categoryModeArr) {
      for (var kamokuMei in this.kamokuName) {

        //console.log("categoryMode_"+categoryModeArr[i_cm]);
        //科目ごとのリスト作成
        let mondaidataStr: string = JSON.stringify(this.firestoreAccessor.mondaidataOnFile);
        let MONDAIDATACopied: Mondai[] = JSON.parse(mondaidataStr); // JSON文字列化したものを戻す
        let TANGODATACopied: TangoData[] = JSON.parse(JSON.stringify(this.tangoDataMaster));

        let tmpMondai: Mondai[] = MONDAIDATACopied.filter(data => {
          return (data.mondaiSyuId == Number(kamokuMei));
        });
        let tmpTangoDataMaster: TangoData[] = TANGODATACopied.filter(data => {
          return (data.mondaiSyuId == Number(kamokuMei));
        });

        let categoryNameArray: any[] = [];

        //名前の配列（重複あり）を取得　＠　カテゴリ出題
        //この辺、問題のリストを先に取得さｓてから、解答履歴をさらうように改造。それ動いたあとに、単語マスターから問題のリストを取得するように改造する。
        if (
          categoryModeArr[i_cm]
        ) {
          //単語マスターからカテゴリー名のリストを抽出
          for (var key in tmpTangoDataMaster) {
            //console.log("getKeywordList が呼ばれた ",Number(key));
            if (this.firestoreAccessor.currentGuiStatus.mokujiSearchKeyword[Number(kamokuMei)] == "") {
              categoryNameArray.push(tmpTangoDataMaster[Number(key)].tangoId);
            } else {
              //キーワードでフィルタがかかっていた場合は、該当する（単語和名か、OKワードの配列にヒット）もののみカテゴリ名の配列にPUSH
              if (
                tmpTangoDataMaster[Number(key)].targetOkWords.includes(this.firestoreAccessor.currentGuiStatus.mokujiSearchKeyword[Number(kamokuMei)]) ||
                tmpTangoDataMaster[Number(key)].name.indexOf(this.firestoreAccessor.currentGuiStatus.mokujiSearchKeyword[Number(kamokuMei)]) != -1
              ) {
                //
                categoryNameArray.push(tmpTangoDataMaster[Number(key)].tangoId);
              }

            }

          };
        } else { //名前の配列（重複あり）を取得　＠　年度出題
          for (var key in tmpMondai) {
            categoryNameArray.push(tmpMondai[Number(key)].nendo);
          };
        }
        //重複を排除
        let categoryNameArrayNoDuplication = categoryNameArray.filter(function (x, i, self) {
          return self.indexOf(x) === i;
        });

        for (var cKey in categoryNameArrayNoDuplication) {
          let categoryName = categoryNameArrayNoDuplication[cKey];

          //対象の試験の、学習履歴（問題ごとの最新の解答）を取得。kamokuMeiと、categoryNameが一致する　gakusyuRirekiを抽出
          let targetMondai = [];
          targetMondai = MONDAIDATACopied.filter(data => {
            if (
              //this.currentGuiStatus.categoryMode
              categoryModeArr[i_cm]
            ) {
              return (
                //data.category == categoryName &&
                data.category.includes(categoryName) &&
                data.mondaiSyuId == Number(kamokuMei)
              );
            } else {
              return (
                data.nendo == Number(categoryName) &&
                data.mondaiSyuId == Number(kamokuMei)
              );
            }

          });
          // //カテゴリ出題の場合は正答率でソートする
          // if (
          //   categoryModeArr[i_cm]
          // ) {
          //   targetMondai.sort(function (a, b) {
          //     if (a.seitouritu > b.seitouritu) return -1;
          //     if (a.seitouritu < b.seitouritu) return 1;
          //     return 0;
          //   });
          // };

          let targetMondaiIdArray: number[] = [];
          let targetMondaiIdArray_HavingKaisetu: number[] = [];
          for (var i_mnd in targetMondai) {
            targetMondaiIdArray.push(targetMondai[i_mnd].mondaiId);
            if (targetMondai[i_mnd].mondaiBun.indexOf('<hint') > -1) {
              //解説が書かれている問題だけ抽出する
              targetMondaiIdArray_HavingKaisetu.push(targetMondai[i_mnd].mondaiId);
            }
            //console.log('正答率：'+targetMondai[i_mnd].seitouritu);
          }

          if (targetMondai[0] != null) {

            let categoryNameToDisplay = categoryName;
            let tmpTangoId = '';
            let tmpTangoData: TangoData;
            if (
              //this.currentGuiStatus.categoryMode
              categoryModeArr[i_cm]
            ) {
              //カテゴリの場合は、カテゴリ名は表示用のものに変換し、単語IDを格納

              let targetTangoData = this.tangoDataMaster.filter(data => {
                if (
                  //this.currentGuiStatus.categoryMode
                  categoryModeArr[i_cm]
                ) {
                  return (
                    data.tangoId == categoryName
                  );
                }
              });
              categoryNameToDisplay = targetTangoData[0].name;
              tmpTangoId = targetTangoData[0].tangoId;
              tmpTangoData = targetTangoData[0];
            }

            //問題集ごとにまとめた情報を集計して、Const作ってPUSH。
            const tempMokujiData: MokujiData = {
              mondaiSyuId: Number(kamokuMei),
              mokujiCategory: categoryNameToDisplay,
              mokujiMondaiList: targetMondaiIdArray,//targetMondai,
              mokujiMondaiList_HavingKaisetu: targetMondaiIdArray_HavingKaisetu,
              tangoId: tmpTangoId,
              tangoData: tmpTangoData
            }

            mokujiData.push(tempMokujiData);


          }
        }

      }
    }

    return mokujiData;
  }

  reloadMokujiDataSource() {
    //科目毎（と年度・単語）ごとのテーブルソースを生成
    let tmpCategoryMd = [true, false];
    for (var trueFalse in tmpCategoryMd) {
      for (var key in this.kamokuName) {
        let tmpMokujiData: MokujiData[] = [];
        if (tmpCategoryMd[trueFalse]) {
          //categoryModeの場合は、単語が設定されているもの（すなわちカテゴリーモードのもくじ）を取得
          tmpMokujiData = this.firestoreAccessor.mokujidataOnFile.filter(data => {
            return (data.mondaiSyuId == Number(key) && data.tangoId);
          });
          //カテゴリ出題の場合は、問題の数が多い順にソート
          tmpMokujiData.sort(function (a, b) {
            if (a.mokujiMondaiList.length > b.mokujiMondaiList.length) return -1;
            if (a.mokujiMondaiList.length < b.mokujiMondaiList.length) return 1;
            return 0;
          });
        } else {
          tmpMokujiData = this.firestoreAccessor.mokujidataOnFile.filter(data => {
            return (data.mondaiSyuId == Number(key) && !data.tangoId);
          });
          //年ごと出題の場合は、年の番号順にソート
          tmpMokujiData.sort(function (a, b) {
            if (a.mokujiCategory > b.mokujiCategory) return -1;
            if (a.mokujiCategory < b.mokujiCategory) return 1;
            return 0;
          });
        }

        //this.mokujiDataSourceDic[key][''+tmpCategoryMd[trueFalse]] = new MatTableDataSource(tmpMokujiData);
        this.mokujiDataSourceDic[key]['' + tmpCategoryMd[trueFalse]] = tmpMokujiData;
      };
    }

  }

  searchMokujiData(mondaiSyuId: number, categoryMode: boolean) {

  }


  changeMondaiFontSize(zoomDirection: string) {
    let currentValue = this.firestoreAccessor.currentGuiStatus.mondaiFontSize;
    let changeDelta = 0;
    if (zoomDirection == 'zoomUp') {
      changeDelta = 20
    } else if (zoomDirection == 'zoomDown') {
      changeDelta = -20
    } else {
      this.firestoreAccessor.currentGuiStatus.mondaiFontSize = 100;
    }
    this.firestoreAccessor.currentGuiStatus.mondaiFontSize = currentValue + changeDelta;
    this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);
  }

  setCurrentMokujiCategory(kamokuNum: number, mokujiItem: MokujiData) {
    this.firestoreAccessor.currentGuiStatus.currentMokuji[kamokuNum] = mokujiItem;

    //console.log("this.firestoreAccessor.currentGuiStatus.currentMokuji_" + JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentMokuji));

    //console.log("this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab]_" + JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab]));

    for (var targetMondaiId in mokujiItem.mokujiMondaiList) {
      //解答を格納する変数の初期化
      this.firestoreAccessor.loadLastKaitouHistory(kamokuNum, Number(mokujiItem.mokujiMondaiList[targetMondaiId]));
    }
    // //最初の問題をカレントにセットする
    // this.jumpToMondai(mokujiItem.mondaiSyuId,mokujiItem.mokujiMondaiList[0]);
  }


  // mondaiNext(targeKamokuId:number,targetMondaiId:number){
  //   let nextMondaiId:number;
  //   console.log('Nextボタン押された')

  //   //問題リストの次の問題の問題ID取得
  //   nextMondaiId = this.firestoreAccessor.currentGuiStatus.currentMokuji[targeKamokuId].mokujiMondaiList[this.firestoreAccessor.currentGuiStatus.currentMokuji[targeKamokuId].mokujiMondaiList.indexOf(targetMondaiId)+1];

  //   this.jumpToMondai(targeKamokuId,nextMondaiId);
  // }

  // async nextMokuji(){
  //   //問題リストの末尾の場合は、もくじを開くダイアログ表示
  //   const toast = await this.toastController.create({
  //     message: '🏆お疲れさまでした🏆\nこの単元の全問題を終了しました！\もくじから他の単元も解いてみましょう😄',
  //     position: 'middle',
  //     buttons: [
  //       {
  //         icon: "list",
  //         text: 'もくじ開く',
  //         side: 'start',
  //         handler: () => {
  //           this.openMokuji(true);
  //         }
  //       }, {
  //         //text: 'もどる',
  //         //<ion-icon name="close-circle"></ion-icon>
  //         icon: 'close-circle',
  //         //text: 'もう少し考える',
  //         role: 'cancel',
  //         handler: () => {
  //         }
  //       }
  //     ]
  //   });
  //   toast.present();
  // }

  sentakuButtonStyle(cMData: Mondai, sentakushi: string) {
    //選択ボタンの、見た目の制御。Disableも。
    let returnStyle = {
      toolbarColor: 'light',
      sentakuColor: 'primary',
      excludeColor: 'primary',
      sentakuDisabled: false,
      excludeDisabled: false,
      sentakuMaruHidden: true,
      sentakuBatuHidden: true,
      excludeMaruHidden: true,
      excludeBatuHidden: true,
      //sentakuIcon: '',
    }

    if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka == true || this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka == false) {
      //採点済みなら、背景Smokeにする
      returnStyle.toolbarColor = 'smoke';
      returnStyle.sentakuDisabled = true;
      returnStyle.excludeDisabled = true;

      // returnStyle.excludeDisabled = 'true';
      // returnStyle.sentakuBatuHidden = this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka;//不正解なら、バツのHiddenをFalseにする
    } else {
      //未採点なら、消去法に乗っていた場合のみSmoke
      if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi != []) {
        //
        if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi.indexOf(sentakushi) !== -1) {
          //
          returnStyle.toolbarColor = 'smoke';
        }
      }
    }

    if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi != []) {
      //
      if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi.indexOf(sentakushi) !== -1) {
        //消去法が選ばれたとき、Trashの色を変えて、マルバツを表示する
        returnStyle.sentakuColor = 'smoke';
        returnStyle.excludeColor = 'light';
        returnStyle.sentakuDisabled = true;
        returnStyle.excludeDisabled = true;
        if (cMData.seikai == sentakushi) {
          //正解と一致してしまった場合はバツを表示
          returnStyle.excludeBatuHidden = false;
        } else {
          returnStyle.excludeMaruHidden = false;
        }
      }
    }

    if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku == sentakushi) {
      //選択肢が選ばれたとき、ハートの色を変えて、マルバツを表示する
      returnStyle.sentakuColor = 'light';
      returnStyle.excludeColor = 'smoke';
      if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka == true) {
        returnStyle.sentakuMaruHidden = false;
      } else if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka == false) {
        returnStyle.sentakuBatuHidden = false;
      }
    }

    //採点済み　　→　背景Smoke、Trash：Disable・Primary、Heart：Disable・Smoke
    //消去法選択　→　背景Smoke、Trash：Disable・Primary、Heart：Disable・Smoke

    return returnStyle;
  }


  jumpToMondai(targeKamokuId: number, targetMondaiId: number, delayMilliSecond?: number, scrollBool?: boolean, forceNewKaitou?: boolean) {
    //カレントの問題を設定する
    //console.log("BBB__010");
    this.setCurrentMondai(targeKamokuId, targetMondaiId);

    this.checkKaitouZumiMondai(targetMondaiId);
    //this.scrollToGuiPosition(50);

  }

  skipKaitouZumiChecker(){
    //２次試験かつURLダイレクトアクセスかつカレントとも同じ問題ID、解説開いているの場合、回答済みのダイアログ表示しないという判断をするための関数
    let skipCheckResultBool = false;

    let urlParamMondaiId: number = Number(this.route.snapshot.paramMap.get('mondaiId'));
    let cMData:Mondai = this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab];

    if (cMData.mondaiId == urlParamMondaiId){//cMData.niji==true &&
      skipCheckResultBool = true
    }

    return skipCheckResultBool;
  }

  checkKaitouZumiMondai(targetMondaiId: number){
    //解答済みの問題を表示する際に、再度解くか聞くダイアログ表示
    if (!this.skipKaitouZumiChecker()){
      if (this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].saitenKekka != null && this.firestoreAccessor.currentGuiStatus.currentMainSlidePosition[this.firestoreAccessor.currentGuiStatus.currentTab] == 3 ) {
        //解説を表示している問題にJumpした場合は、Modalで確認する。＃resがTrueなら、再読み込みと同じ処理を行う
        this.openModal('kaitouZumiMondaiKaitouReloadConfirm', true, null, 8000);
      }
    }
  }

  // flipOpen(cMData: Mondai,flipString: string){
  //   //タップしたフリップをCurrenKaitouに記録して、DB保存し、フリップのマスク処理含む、MondaiBunの文字列置換処理を再実行する
  //   console.log('flipOpenddddddddddd_at_'+flipString);
  //   this.updateKaitou(cMData,'flipOpen',flipString);

  //   //updateKaitou実行。DBにKaitou保存。

  // }







  // async getMondaiDataToLocalDB(targeKamokuId:number, targetMondaiId:number) {
  //   try { //NW異常時にも消さないようTry
  //     await this.firestoreAccessor.getMondaiDataFromFirebaseToLocalDB(targeKamokuId, targetMondaiId);
  //   } catch (e) {
  //     console.log(e + "initMasterData");
  //     return;
  //   }
  // }

  openMokujiDetail(mondaiSyuId: number, mokujiCategory: string) {
    //もくじのボタンが押されたら、積み上げグラフや用語解説が書かれた詳細を開く。
    //console.log('もくじの詳細を開きます＠'+mokujiCategory);
    if (this.firestoreAccessor.currentGuiStatus.currentMokuji[mondaiSyuId] == mokujiCategory) {
      //同じボタン連続クリック時は閉じる
      this.firestoreAccessor.currentGuiStatus.currentMokuji[mondaiSyuId] = '';
    } else {
      this.firestoreAccessor.currentGuiStatus.currentMokuji[mondaiSyuId] = mokujiCategory;
    }

    this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);

    //一旦覚えさせずに開いて速度を見てみるか
    //ウィンドウサイズや環境に合わせて列数を指定
    // if (this.platform.is('ios')){
    //   //iosなら強制２列
    //   this.mokuji_detail_col_size = 6;
    // }
    if (this.platform.width() > 1600) {
      //1680以上なら6列
      this.mokuji_detail_col_size = 2;
    } else if (this.platform.width() > 1000) {
      //FHD1080以上4列
      this.mokuji_detail_col_size = 4;
    }

  }


  highLightMokujiTango(mondaiBun: string, targetOkWords: string[]): string {
    //もくじで、検索キーワードをハイライトする
    let highLightedString: string = mondaiBun;

    for (var okWord in targetOkWords) {
      let beforeString = targetOkWords[okWord];
      let afterString = "<font color=#ff2233><span style='background-color:#000000'>\n" + targetOkWords[okWord] + '</span></font>';

      highLightedString = highLightedString.replace(beforeString, afterString);
    }
    return highLightedString;
  }

  // do_policy_visible() {
  //   this.policy_html_string = 'この問題集は、open-drillが開発しています。  問題集の品質向上のために、解答データを取得します。詳細は[プライバシーポリシー](https://zu-de.com/assets/img/policy.html)を参照ください。';
  // }

  async displaySpinner(message: string, milliSecounds?: number) {

    let milliSec: number = 100;
    if (milliSecounds) {
      milliSec = milliSecounds;
    }

    const loading = await this.loadingCtrl.create({
      //spinner: spinner,//"lines",
      message: message,
      duration: milliSec,//100
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }


  // mokuji_detail_open(kamokuId: number, mokujiNum: number) {
  //   if (!this.firestoreAccessor.currentGuiStatus.mokuji_detail_visible[mokujiNum][kamokuId]) {
  //     //開くときのみLOADINGを表示
  //     this.loading_hidden = false
  //   }
  //   this.firestoreAccessor.currentGuiStatus.mokuji_detail_visible[mokujiNum][kamokuId] = !this.firestoreAccessor.currentGuiStatus.mokuji_detail_visible[mokujiNum][kamokuId];

  //   this.delay(2000).then(any => {
  //     this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);
  //     this.loading_hidden = true;
  //   });
  // }

  nijiSaiten(cMData: Mondai, sentakushi_to_select: string, mode: string, ){
    //
    setTimeout(() => {
      //console.log('hello')
      let targetMondaiId = cMData.mondaiId;
      let targetMondaiSyuId = cMData.mondaiSyuId;
      this.setCurrentMondai(targetMondaiSyuId, targetMondaiId);
      setTimeout(() => {
        //console.log('callback')
        this.innerState = ""+targetMondaiId;
        this.selectSentakushi(cMData, this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku, 'sentaku')

      }, 0)
    }, 0)
  }

  // stringDivider(str, width, spaceReplacer) {
  //   if (str.length>width) {
  //       let p=width
  //       for (;p>0 && str[p]!=' ';p--) {
  //         if (p>0) {
  //           let left = str.substring(0, p);
  //           let right = str.substring(p);
  //             //console.log('1111')
  //             return left + spaceReplacer + this.stringDivider(right, width, spaceReplacer);
  //         }
  //       }
  //       //console.log('str-'+str)
  //   }
  //   return str;
  // }

  // removeNumFromScatterTextArr(){
  //   //RESTで帰ってきた解答のゴミ取りや改行追加。
  //   let schoolNameArr = ['(資格の大原)','(TBC)','(MMC)','(TAC)','(AAS)'];

  //   for (let i_sta in this.scatterTextArr) {
  //     let targetScatterTextArr = this.scatterTextArr[i_sta];
  //     for (let i_tsta in targetScatterTextArr) {
  //       targetScatterTextArr[i_tsta] = String(targetScatterTextArr[i_tsta]).replace(RegExp("^[0-9]*,","g"), ``);
  //       targetScatterTextArr[i_tsta] = this.stringDivider(targetScatterTextArr[i_tsta], 20, "<BR>");
  //       if ( i_sta == "2"){
  //         targetScatterTextArr[i_tsta] = schoolNameArr[i_tsta]//予備校の名前を追記
  //       }
  //     }
  //     this.scatterTextArr[i_sta] = targetScatterTextArr;
  //   }
  // }


  // generateScatterTextArr(){
  //   //RESTで帰ってきた、再現答案の解答文を、表示用のARRに格納する
  //   let returnArr = [];
  //   for (var i = 0; i < this.scatterData.length; i++) {
  //     //予備校、不明、ABCD、自分のデータ取る
  //     let kaitouBunArr = [];
  //     let kaitouDfArr = JSON.parse(this.scatterData[i]["kaitouDf"]);
  //     console.log("kaitouDfArrーーーーーーー"+kaitouDfArr)

  //     for (var i_kdf = 0; i_kdf < kaitouDfArr.length; i_kdf++) {
  //       //プロットの、凡例ごとの中の複数項目だけ、解答文のデータ取って、配列に格納する
  //       let kaitouBunStr = kaitouDfArr[i_kdf]["kaitou"];
  //       kaitouBunArr.push(kaitouBunStr);
  //     }
  //     returnArr[i] = kaitouBunArr;
  //   }
  //   return returnArr;
  // }
  // generateMySaitenAiKekkaArr(mine:any[] , hokaNoHito:any[]  ){
  //   //採点モデルが返した。自分の採点結果と、正解した人の解答文を格納する
  //   this.mySaitenAiKekkaArr = {
  //     "mine":mine,
  //     "hokaNoHito":hokaNoHito,
  //   };
  //   //console.log("kaitouDf--"+kaitouDf)
  // }


  // zenkaku2hankaku(str) {
  //   return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
  //       return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  //   });
  // }

  // kuuhakuSkujo(str) {//B to Bなど揃えようと思ったが、事例4でリスクあるので使わない方向に。toでヒットさせられることを期待
  //   return str.replace(/^[\s　\uFEFF\xA0]+|[\s　\uFEFF\xA0]+$/g, '');
  // }

  barString( valueNum: number){
    //主成分負荷量に応じた、グラフになる文字列を返す
    let returnNum = Math.abs(valueNum * 100);
    let barwidthNum = Math.ceil(returnNum);
    let returnStr:string = "";
    for (var i = 0; i < barwidthNum; i++) {
      returnStr = returnStr + "■";
    }
    return returnStr
  }


  // generateScatterTangoRanking(pcNum: number, pcDfString: string) {
  //   //散布図で、X軸とY軸に影響が大きい単語のランクを取得する
  //   let pcDf = JSON.parse(pcDfString);

  //   if( !this.firestoreAccessor.currentGuiStatus.scatterTangoRanking[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId] ){
  //     //変数がカラなら枠を作る
  //     this.firestoreAccessor.currentGuiStatus.scatterTangoRanking[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId] = [];
  //   }

  //   // Create items array
  //   var items = Object.keys(pcDf).map(function(key) {
  //     return [key, pcDf[key]];
  //   });

  //   // Sort the array based on the second element
  //   items.sort(function(first, second) {
  //     return second[1] - first[1];
  //   });

  //   let pcDfStart = JSON.parse(JSON.stringify(items.slice(0, 10)));
  //   let pcDfEnd = JSON.parse(JSON.stringify(items.slice(-10)));

  //   //console.log("DDD"+JSON.stringify(pcDfStart));

  //   if (pcNum == 1) {
  //     //右はし。上から5つ
  //     this.firestoreAccessor.currentGuiStatus.scatterTangoRanking[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][0] = pcDfStart;

  //     //左はし。下から5つ
  //     this.firestoreAccessor.currentGuiStatus.scatterTangoRanking[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][1] = pcDfEnd;

  //   } else if (pcNum == 2) {

  //     //上はし。上から5つ
  //     this.firestoreAccessor.currentGuiStatus.scatterTangoRanking[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][2] = pcDfStart;

  //     //下はし。下から5つ
  //     this.firestoreAccessor.currentGuiStatus.scatterTangoRanking[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][3] = pcDfEnd;

  //   }

  // }

  // bunpuButtonDisableChecker(cMData: Mondai){
  //   //再現答案の分布を見るのボタンを押せるかどうかを、判定する関数
  //   let returnButtonDisabledBool:boolean = false;
  //   console.log("FFFF"+this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].kaitouLength);
  //   if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].kaitouLength > cMData.maxlength){
  //     //文字数制限Overのときは、常に、ボタン押させない
  //     returnButtonDisabledBool = true;
  //   }else if (this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[cMData.mondaiId]){
  //     //文字数以内でも、過去の採点結果がある場合、変更していなければ、ボタン押させない
  //     if (this.firestoreAccessor.currentGuiStatus.saitenKekka2jiChangedFlag != true && this.firestoreAccessor.currentGuiStatus.kaitoubun2jiChangedFlag != true){
  //       //解答の変更も、採点の変更もしていなければ、押させない
  //       returnButtonDisabledBool = true;
  //     }
  //   }
  //   return returnButtonDisabledBool;
  // }

  // saitenKekka2jiFixed(key:string){

  //   if (this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][key] == 0) {
  //     this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][key] = 1;
  //   } else {
  //     this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][key] = 0;
  //   }

  //   //console.log("AAAA"+JSON.stringify(this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId]));

  //   // this.firestoreAccessor.convertSaitenKekka2ji(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId)

  //   // this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab][saitenKijunStr] =  !this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab][saitenKijunStr];
  //   this.firestoreAccessor.currentGuiStatus.saitenKekka2jiChangedFlag = true;
  //   // console.log("AAAA"+JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab]));

  //   console.log("BBBB"+JSON.stringify(key));
  // }

  saitenKekka2jiChanged(targetSaitenkijunStr:string,valueStr:string, event){
    this.firestoreAccessor.currentGuiStatus.saitenKekka2jiChangedFlag = true;
    //console.log('this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId]["trainable"]---'+this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId]["trainable"])

    this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][targetSaitenkijunStr] = valueStr;

    //受験生が自分で採点を直した場合は、採点状態のフラグを変更する
    //this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId]["trainable"] = "jukenseiCorrected";

  }

  plotScatter(cMData:Mondai){
    //散布図を描画する
    Plotly.newPlot('myDiv' + cMData.mondaiId, this.firestoreAccessor.currentGuiStatus.scatterValue[cMData.mondaiId], { legend: { x: 0.3, y: -0.3 } },
    {
      responsize: true,
      displayModeBar: false
    })
  }

  plotJunniRaderChart(cMData: Mondai) {
    //順位のレーダーチャートを描画する
    Plotly.newPlot(
      'junniRaderChartDiv',
      this.firestoreAccessor.currentGuiStatus.junniRaderChartData[this.getMondaiIdSentou5(cMData.mondaiId)],
      {
        polar: {
          radialaxis: {
            visible: true,
            autorange: "reversed",
            autotick: false,
            showgrid: false,
            zeroline: false,
            showline: false,
            showticklabels: false,
            ticks: '',
          }
        },
        showlegend: true,
        // layout: {
        //   radialaxis: { autorange: false, range:[1, ]},
        //   //backgroundColor: "rgba(255,0,255,0.5)",
        //   //width: 320,
        // },
      },
      {
        displayModeBar: false,
      }
    )
  }

  // createJunniRaderChartData(cMData: Mondai) {
  //   //CurrentGuiにRaderのチャートデータ作って格納

  //   //該当の問題の、設問の数だけ、順位とか名前を所得して配列に格納する
  //   let tmpR = [];
  //   let tmpTheta = [];

  //   let tmpR_A = [];
  //   let tmpR_B = [];

  //   // let i_mondaiId in this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].daimonList

  //   let saikaiNum = 0;//全問題で最下位の数字を保持する。未回答の問題の順位埋めに使うため
  //   for (let i_mondaiId in cMData.daimonList){
  //     let tmpMondaiId = cMData.daimonList[i_mondaiId];
  //     tmpR.unshift(this.firestoreAccessor.getRankingTopPangeHyouji(tmpMondaiId).junni);
  //     tmpTheta.unshift(this.firestoreAccessor.getOneMondaiFromFile(tmpMondaiId).name)
  //     if (saikaiNum < this.firestoreAccessor.getRankingTopPangeHyouji(tmpMondaiId).ninzuu){
  //       //
  //       saikaiNum = this.firestoreAccessor.getRankingTopPangeHyouji(tmpMondaiId).ninzuu
  //     }

  //     //A答案の平均順位を取得する
  //     tmpR_A.unshift(this.firestoreAccessor.getHeikinJunni(tmpMondaiId,"A"));
  //     tmpR_B.unshift(this.firestoreAccessor.getHeikinJunni(tmpMondaiId,"B"));
  //   }

  //   //未回答の問題は最下位の順位の数字に置換する
  //   for (let i_tr in tmpR){
  //     if (tmpR[i_tr] == 0){
  //       //
  //       tmpR[i_tr] =saikaiNum
  //     }
  //   }
  //     //tmpR = tmpR.map(function(x){ return x.replace(0,saikaiNum) });

  //   //Raderが一周するよう追加
  //   tmpR.unshift(this.firestoreAccessor.getRankingTopPangeHyouji(cMData.daimonList[0]).junni);
  //   tmpTheta.unshift(this.firestoreAccessor.getOneMondaiFromFile(cMData.daimonList[0]).name);
  //   tmpR_A.unshift(this.firestoreAccessor.getHeikinJunni(cMData.daimonList[0],"A"));
  //   tmpR_B.unshift(this.firestoreAccessor.getHeikinJunni(cMData.daimonList[0],"B"));

  //   this.firestoreAccessor.currentGuiStatus.junniRaderChartData[cMData.mondaiId] = 
  //   [
  //     {
  //       type: 'scatterpolar',
  //       r: tmpR,//[10, 11, 8, 4, 12, 10],
  //       theta: tmpTheta,//['A','B','C', 'D', 'E', 'A'],
  //       //fill: 'toself',
  //       name: 'あなたの解答',
  //       hovertemplate: '%{r:,r}位<extra></extra>',
  //       line: {color: '#000000'}
  //       },
        
  //       {
  //       type: 'scatterpolar',
  //       r: tmpR_A,//[2.2, 3.3, 3.3, 4.4, 5.5, 2.2],
  //       theta: tmpTheta,//['A','B','C', 'D', 'E', 'A'],
  //       //fill: 'toself',
  //       name: 'A答案平均',
  //       hovertemplate: '%{r:,.1f}位<extra></extra>',
  //       line: {color: '#ffce00'}
  //       },
        
  //       {
  //         type: 'scatterpolar',
  //         r: tmpR_B,//[2.2, 3.3, 3.3, 4.4, 5.5, 2.2],
  //         theta: tmpTheta,//['A','B','C', 'D', 'E', 'A'],
  //         //fill: 'toself',
  //         name: 'B答案平均',
  //         hovertemplate: '%{r:,.1f}位<extra></extra>',
  //         line: {color: '#0cd1e8'}
  //         }
  //   ]
  //   // {
  //   //   labels: ['Running', 'Swimming', 'Eating', 'Cycling'],
  //   //   datasets: [{
  //   //     data: [20, 10, 4, 2]
  //   //   }]
  //   // }
  // }

  // createTouanSuuBarChartData(cMData: Mondai) {
  //   //
    
  //   //１．順序を作る
  //   this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId] = [];

  //   for (var saitenKijunItem in this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId]) {
  //     //tmpSaitenKijunArr.push(saitenKijunItem);
  //     //グラフデータ格納する
  //     let countGoukaku: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["合格", 'A']).length;
  //     let countFugoukaku: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["不合格", 'B', 'C', 'D']).length;

  //     //let countKaitenai: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["合格", 'A', 'B', 'C', 'D']).length;
      
  //     let countGoukaku_Kaketenai: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["合格", 'A']).length;
  //     let countFugoukaku_Kaketenai: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["不合格", 'B', 'C', 'D']).length;

  //     let countAll: number = countGoukaku + countFugoukaku + countGoukaku_Kaketenai + countFugoukaku_Kaketenai;

  //     let dicToAppend = {
  //       key: saitenKijunItem,
  //       countGoukaku: countGoukaku,
  //       countFugoukaku: countFugoukaku,
  //       //countKaitenai: countKaitenai,

  //       countGoukaku_Kaketenai: countGoukaku_Kaketenai,
  //       countFugoukaku_Kaketenai: countFugoukaku_Kaketenai,

  //       countAll: countAll,
  //     };

  //     this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].push(dicToAppend);
  //   }

  //   //ソートする
  //   this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].sort(function (a, b) {
  //     if (a.countGoukaku + a.countFugoukaku < b.countGoukaku + b.countFugoukaku) return 1;
  //     if (a.countGoukaku  + a.countFugoukaku > b.countGoukaku + b.countFugoukaku) return -1;
  //     if (a.countGoukaku < b.countGoukaku) return 1;
  //     if (a.countGoukaku > b.countGoukaku) return -1;
  //     return 0;
  //   });

  //   //２．順序通りグラフデータ作る
  //   for (var i_gtt in this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {
  //     //
  //     let targetGaitouTouanSuuData = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_gtt];

  //     //console.log("this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]-- "+this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId])
  //     //console.log("targetGaitouTouanSuuData-- "+ JSON.stringify(targetGaitouTouanSuuData))

  //     targetGaitouTouanSuuData.barChartData = [
  //       {
  //         x: [targetGaitouTouanSuuData.countGoukaku / targetGaitouTouanSuuData.countAll * 100],//[20],
  //         y: [''],
  //         name: '合格A[%]',
  //         type: 'bar',
  //         orientation: 'h',
  //         marker: {
  //           color: "#ffff88",//'rgba(64, 219, 82, 0.95)',
  //           line: {
  //             color: 'rgba(255, 255, 255, 1.0)',
  //             width: 1
  //           }
  //         },
  //         hoverlabel: { bgcolor: "#ffff88" },
  //         hovertemplate: '合格A<BR> %{x:,.0f}%<extra></extra>',
  //         // text: [countGoukaku],
  //         // font: {
  //         //   size: 14,
  //         //   color: 'rgba(245,246,249,1)'
  //         // },
  //       },
  //       {
  //         x: [targetGaitouTouanSuuData.countFugoukaku / targetGaitouTouanSuuData.countAll * 100],
  //         y: [''],
  //         name: '不合格BCD[%]',
  //         type: 'bar',
  //         orientation: 'h',
  //         marker: {
  //           color: "#c7c7ff",//'rgba(219, 64,  82, 0.95)',
  //           line: {
  //             color: 'rgba(255, 255, 255, 1.0)',
  //             width: 1
  //           }
  //         },
  //         hoverlabel: { bgcolor: "#c7c7ff" },
  //         hovertemplate: '不合格<BR>BCD<BR> %{x:,.0f}%<extra></extra>',
  //       },
  //       {
  //         x: [(targetGaitouTouanSuuData.countGoukaku_Kaketenai ) / targetGaitouTouanSuuData.countAll * 100],
  //         y: [''],
  //         name: '合格A記述なし[%]',
  //         type: 'bar',
  //         orientation: 'h',
  //         marker: {
  //           color: "#fcfcfc",//'rgba(40, 40,  40, 0.95)',
  //           line: {
  //             color: '#eeeeee',
  //             width: 1
  //           }
  //         },
  //         hoverlabel: { bgcolor: "#f3f3f3" },
  //         hovertemplate: '記述<BR>なし<BR>@合格A<BR> %{x:,.0f}%<extra></extra>',
  //       },
  //       {
  //         x: [(targetGaitouTouanSuuData.countFugoukaku_Kaketenai) / targetGaitouTouanSuuData.countAll * 100],
  //         y: [''],
  //         name: '不合格BCD記述なし[%]',
  //         type: 'bar',
  //         orientation: 'h',
  //         marker: {
  //           color: "#f5f5f5",//'rgba(40, 40,  40, 0.95)',
  //           line: {
  //             color: '#eeeeee',
  //             width: 1
  //           }
  //         },
  //         hoverlabel: { bgcolor: "#f3f3f3" },
  //         hovertemplate: '記述<BR>なし<BR>@不合格<BR>BCD<BR> %{x:,.0f}%<extra></extra>',
  //       }
  //     ]
  //   }
    

  // }

  // createGoodBadData(cMData: Mondai){
    
  //   // let wariaiArr = [];
  //   // let tmpGaitouTouanSuuData = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]));
  //   // tmpGaitouTouanSuuData.sort(function (a, b) {
  //   //   if (a.countGoukaku + a.countFugoukaku < b.countGoukaku + b.countFugoukaku) return 1;
  //   //   if (a.countGoukaku  + a.countFugoukaku > b.countGoukaku + b.countFugoukaku) return -1;
  //   //   if (a.countGoukaku < b.countGoukaku) return 1;
  //   //   if (a.countGoukaku > b.countGoukaku) return -1;
  //   //   return 0;
  //   // });

  //   // for (var i_ttt in this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {

  //   //     //順序の配列を作る
  //   //     let targetGaitouTouanSuuData = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_ttt];
  //   //     wariaiArr.push(targetGaitouTouanSuuData);
  //   // }


  //   for (var i_gtt in this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {
  //     //記述有無の０１を、解答率などの、良し悪しのhyouka情報に変換して値を格納する
  //     let hyoukaArr =  ["",""];
  //     let hyoukaStr = "";
  //     let kijunGoukei:boolean =true;//合計Aの数が、しきい値超えかどうか
  //     let kijunWariai:boolean =true;//合格Aのうち、答えている割合が、不合格BCDのそれより大きいか

  //     let targetGaitouTouanSuuData = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_gtt];

  //     //しきい値判定　＠　解答数
  //     if(
  //       Number(i_gtt) > this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].length * (1/2)
  //     ){
  //       //配列の後半にあったら、解答者少ない論点なので
  //       kijunGoukei = false;
  //     }

      
  //     // countGoukaku: countGoukaku,
  //     // countFugoukaku: countFugoukaku,
  //     // //countKaitenai: countKaitenai,

  //     // countGoukaku_Kaketenai: countGoukaku_Kaketenai,
  //     // countFugoukaku_Kaketenai: countFugoukaku_Kaketenai,

  //     //しきい値判定　＠　解答率

  //     let bunboGoukaku = targetGaitouTouanSuuData.countGoukaku + targetGaitouTouanSuuData.countGoukaku_Kaketenai;
  //     let bunboFugoukaku = targetGaitouTouanSuuData.countFugoukaku + targetGaitouTouanSuuData.countFugoukaku_Kaketenai;
        
  //     if (
  //       //合格者が書いている割合より、不合格者のほうが多ければFalse
  //       (targetGaitouTouanSuuData.countGoukaku / bunboGoukaku) 
  //       <
  //       (targetGaitouTouanSuuData.countFugoukaku / bunboFugoukaku)
  //     ){
  //       kijunWariai = false;
  //     }
  //     // console.log("targetGaitouTouanSuuData.key--"+targetGaitouTouanSuuData.key)
  //     // console.log("kijunGoukei- "+kijunGoukei)
  //     // console.log("kijunWariai- "+kijunWariai)

      
  //     // console.log("targetGaitouTouanSuuData.countGoukaku / bunboGoukaku- "+(targetGaitouTouanSuuData.countGoukaku / bunboGoukaku))
  //     // console.log("targetGaitouTouanSuuData.countFugoukaku / bunboFugoukaku- "+(targetGaitouTouanSuuData.countFugoukaku / bunboFugoukaku))
  //     // console.log("")

  //     if (kijunGoukei){
  //       if (kijunWariai){
  //         hyoukaArr = ["Bad","Good"];
  //         if (this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
  //           hyoukaStr = "Good";
  //         } else {
  //           hyoukaStr = "Bad";
  //         }
  //       }else {
  //         hyoukaArr = ["","Hmm"];
  //         if (this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
  //           hyoukaStr = "Hmm";
  //         } else {
  //           hyoukaStr = "";
  //         }
  //       }
  //     } else {
  //       if (kijunWariai){
  //         hyoukaArr = ["","Fine"];
  //         if (this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
  //           hyoukaStr = "Fine";
  //         } else {
  //           hyoukaStr = "";
  //         }
  //       }else {
  //         hyoukaArr = ["",""];
  //         if (this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId][targetGaitouTouanSuuData.key] ==1){
  //           hyoukaStr = "";
  //         } else {
  //           hyoukaStr = "";
  //         }
  //       }
  //     }

  //     targetGaitouTouanSuuData.hyoukaArr = hyoukaArr;
  //     targetGaitouTouanSuuData.hyoukaStr = hyoukaStr;
  //   }
  // }

  getGoodBadStr(cMData: Mondai,ronteStr: string){
    //
    this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]
    let targetGaitouTouanSuuData = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].filter(data => {
      return (data.key == ronteStr);
    });
    return targetGaitouTouanSuuData[0].hyoukaArr;
  }

  // countGoodFineCount(cMData: Mondai){
  //   //各問題のTOP画面に表示される、似ている論点の数をカウント
  //   let countToReturn = 0;
  //   let tmpGaitouTouanSuuData ;
  //   if (this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]){//初回起動時はカウントネタがないので処理スキップ
  //     tmpGaitouTouanSuuData = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]));
  //     for  (var i_tgd in tmpGaitouTouanSuuData) {
  //       if (tmpGaitouTouanSuuData[i_tgd].hyoukaStr == "Good" || tmpGaitouTouanSuuData[i_tgd].hyoukaStr == "Fine" ){
  //         countToReturn = countToReturn +1 ;
  //       }
  //     }
      
  //   }
  //   return countToReturn;
  // }

  getGoodFineRonten(cMData: Mondai){
    //フィードバックコメントで使う、書けている論点を返す
    let goodFineRontenStrArr = [];
    let tmpGaitouTouanSuuData ;
    if (this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]){//初回起動時はカウントネタがないので処理スキップ
      tmpGaitouTouanSuuData = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]));
      for  (var i_tgd in tmpGaitouTouanSuuData) {
        if (tmpGaitouTouanSuuData[i_tgd].hyoukaStr == "Good" || tmpGaitouTouanSuuData[i_tgd].hyoukaStr == "Fine" ){
          goodFineRontenStrArr.push(tmpGaitouTouanSuuData[i_tgd]);
        }
      }
    }
    return goodFineRontenStrArr;
  }

  plotTouanSuuBarChart(cMData: Mondai) {
    //該当の問題の、合・不合格の解答数の棒グラフデータを作成、描画する関数

    // //１．順序を作る
    // this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId] = [];

    // for (var saitenKijunItem in this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId]) {
    //   //tmpSaitenKijunArr.push(saitenKijunItem);
    //   //グラフデータ格納する
    //   let countGoukaku: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["合格", 'A']).length;
    //   let countFugoukaku: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["不合格", 'B', 'C', 'D']).length;
    //   let countKaitenai: number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["合格", 'A', 'B', 'C', 'D']).length;

    //   let countAll: number = countGoukaku + countFugoukaku + countKaitenai;

    //   let dicToAppend = {
    //     key: saitenKijunItem,
    //     countGoukaku: countGoukaku,
    //     countFugoukaku: countFugoukaku,
    //     countKaitenai: countKaitenai,

    //     countAll: countAll,
    //     goukakuWariai: countGoukaku / countAll,
    //   };

    //   this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].push(dicToAppend);
    // }

    // //ソートする
    // this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId].sort(function (a, b) {
    //   if (a.countAll < b.countAll) return 1;
    //   if (a.countAll > b.countAll) return -1;
    //   return 0;
    // });

    // //２．順序通りグラフデータ作る
    // for (var i_gtt in this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {
    //   //
    //   let targetGaitouTouanSuuData = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_gtt];

    //   //console.log("this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]-- "+this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId])
    //   //console.log("targetGaitouTouanSuuData-- "+ JSON.stringify(targetGaitouTouanSuuData))

    //   targetGaitouTouanSuuData.barChartData = [
    //     {
    //       x: [targetGaitouTouanSuuData.countGoukaku / targetGaitouTouanSuuData.countAll * 100],//[20],
    //       y: [''],
    //       name: '合格A[%]',
    //       type: 'bar',
    //       orientation: 'h',
    //       marker: {
    //         color: "#ffff88",//'rgba(64, 219, 82, 0.95)',
    //         line: {
    //           color: 'rgba(255, 255, 255, 1.0)',
    //           width: 1
    //         }
    //       },
    //       hoverlabel: { bgcolor: "#ffff88" },
    //       hovertemplate: '合格A<BR> %{x:,.0f}%<extra></extra>',
    //       // text: [countGoukaku],
    //       // font: {
    //       //   size: 14,
    //       //   color: 'rgba(245,246,249,1)'
    //       // },
    //     },
    //     {
    //       x: [targetGaitouTouanSuuData.countFugoukaku / targetGaitouTouanSuuData.countAll * 100],
    //       y: [''],
    //       name: '不合格BCD[%]',
    //       type: 'bar',
    //       orientation: 'h',
    //       marker: {
    //         color: "#c7c7ff",//'rgba(219, 64,  82, 0.95)',
    //         line: {
    //           color: 'rgba(255, 255, 255, 1.0)',
    //           width: 1
    //         }
    //       },
    //       hoverlabel: { bgcolor: "#c7c7ff" },
    //       hovertemplate: '不合格<BR>BCD<BR> %{x:,.0f}%<extra></extra>',
    //     },
    //     {
    //       x: [targetGaitouTouanSuuData.countKaitenai / targetGaitouTouanSuuData.countAll * 100],
    //       y: [''],
    //       name: '記述なし[%]',
    //       type: 'bar',
    //       orientation: 'h',
    //       marker: {
    //         color: "#fcfcfc",//'rgba(40, 40,  40, 0.95)',
    //         line: {
    //           color: '#eeeeee',
    //           width: 1
    //         }
    //       },
    //       hoverlabel: { bgcolor: "#f3f3f3" },
    //       hovertemplate: '記述<BR>なし<BR> %{x:,.0f}%<extra></extra>',
    //     }
    //   ]
    // }

    //３．グラフ描画

    for (var i_gtd in this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId]) {
      //
      let targetGaitouTouanSuuData = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][i_gtd];
      Plotly.newPlot(
        "myBarChart" +cMData.mondaiId+ targetGaitouTouanSuuData.key,//+ ,// "testDiv",//
        targetGaitouTouanSuuData.barChartData,
        {
          showlegend: false,
          legend: {
            x: 1,
            xanchor: 'right',
            y: 1
          },
          barmode: 'stack',
          xaxis: {
            //autorange: 'reversed',
            zeroline: false,
            showgrid: false,
            visible: false,
          },
          yaxis: {
            //side: 'right',
            zeroline: false,
            showgrid: false,
            visible: false,
          },
          autosize: true,
          //width: 80,
          height: 50,
          margin: {
            l: 20,
            r: 20,
            b: 5,
            t: 5,
            pad: 0
          },
          //hovermode: 'closest',
        },
        {
          responsize: true,
          displayModeBar: false
        }
      )
    }

    // /////////////////////
    // //グラフデータ作成
    // for (var saitenKijunItem in this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId]) {

    //   //tmpSaitenKijunArr.push(saitenKijunItem);
    //   //グラフデータ格納する
    //   let countGoukaku:number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["合格",'A']).length;
    //   let countFugoukaku:number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 1, ["不合格",'B','C','D']).length;
    //   let countKaitenai:number = this.firestoreAccessor.getKaketeruTouan(cMData.mondaiId, saitenKijunItem, 0, ["合格",'A','B','C','D']).length;

    //   let countAll: number = countGoukaku +countFugoukaku + countKaitenai;

    //   this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId] = {};//初期化
    //   this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][saitenKijunItem] = [
    //     {
    //       x: [countGoukaku/countAll * 100],//[20],
    //       y: [''],
    //       name: '合格A[%]',
    //       type: 'bar',
    //       orientation: 'h',
    //       marker: {
    //         color: "#ffff88",//'rgba(64, 219, 82, 0.95)',
    //         line: {
    //           color: 'rgba(255, 255, 255, 1.0)',
    //           width: 1
    //         }
    //       },
    //       hoverlabel: { bgcolor: "#ffff88" },
    //       hovertemplate: '合格A<BR> %{x:,.0f}%<extra></extra>',
    //       // text: [countGoukaku],
    //       // font: {
    //       //   size: 14,
    //       //   color: 'rgba(245,246,249,1)'
    //       // },
    //     },
    //     {
    //       x: [countFugoukaku/countAll * 100 ],
    //       y: [''],
    //       name: '不合格BCD[%]',
    //       type: 'bar',
    //       orientation: 'h',
    //       marker: {
    //         color: "#c7c7ff",//'rgba(219, 64,  82, 0.95)',
    //         line: {
    //           color: 'rgba(255, 255, 255, 1.0)',
    //           width: 1
    //         }
    //       },
    //       hoverlabel: { bgcolor: "#c7c7ff" },
    //       hovertemplate: '不合格<BR>BCD<BR> %{x:,.0f}%<extra></extra>',
    //     },
    //     {
    //       x: [countKaitenai/countAll * 100 ],
    //       y: [''],
    //       name: '記述なし[%]',
    //       type: 'bar',
    //       orientation: 'h',
    //       marker: {
    //         color: "#fcfcfc",//'rgba(40, 40,  40, 0.95)',
    //         line: {
    //           color: '#eeeeee',
    //           width: 1
    //         }
    //       },
    //       hoverlabel: { bgcolor: "#f3f3f3" },
    //       hovertemplate: '記述<BR>なし<BR> %{x:,.0f}%<extra></extra>',
    //     }
    //   ];


    //   //console.log("this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[saitenKijunItem]--"+JSON.stringify(this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[targetStr]))

    //   //グラフ描画する
    //   Plotly.newPlot(
    //     "myBarChart"+cMData.mondaiId+saitenKijunItem,//+ ,// "testDiv",//
    //     this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId][saitenKijunItem],
    //     {
    //       showlegend: false,
    //       legend: {
    //         x: 1,
    //         xanchor: 'right',
    //         y: 1
    //       },
    //       barmode: 'stack',
    //       xaxis: {
    //         //autorange: 'reversed',
    //         zeroline: false,
    //         showgrid: false,
    //         visible: false,
    //       },
    //       yaxis: {
    //         //side: 'right',
    //         zeroline: false,
    //         showgrid: false,
    //         visible: false,
    //       },
    //       autosize: false,
    //       //width: 80,
    //       height: 50,
    //       margin: {
    //         l: 20,
    //         r: 20,
    //         b: 5,
    //         t: 5,
    //         pad: 0
    //       },
    //       //hovermode: 'closest',
    //     },
    //     {
    //       responsize: true,
    //       displayModeBar: false
    //     }
    //   )
    // }

  }

  // hikakuIkkatu() {
  //   //初回の判定の場合、全設問の文だけ判定のためもhikakuメソッド実行する
  //   for (let i_mondaiId in this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].daimonList) {
  //     setTimeout(() => {
  //       //console.log('callback')
  //       let targetMondai = JSON.parse(JSON.stringify(this.firestoreAccessor.getOneMondaiFromFile(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].daimonList[i_mondaiId])));
  //       //問題IDの数だけHIKAKU繰り返す
  //       this.hikaku(targetMondai, "", "");

  //       setTimeout(() => {
  //         //比較済んだらデータで着てるはずなのでPlotする。
  //         //this.plotTouanSuuBarChart(cMData)ののりと同じ
  //       }, 0)
  //     }, 0)
  //   }
  //   for (let i_mondaiId in this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].daimonList) {
  //     let junNum = this.firestoreAccessor.getRankingTopPangeHyouji(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].daimonList[i_mondaiId]).junni;
  //     console.log('junNum__' + junNum)
  //   }
  // }

  //hikakuの関数をfirestoreAccessorに移動したので、呼ぶだけの関数
  callHikaku(cMData: Mondai, sentakushi_to_select: string, mode: string, ) {
    //
    this.setCurrentMondai(cMData.mondaiSyuId, cMData.mondaiId);
    this.innerState = "" + cMData.mondaiId;
    setTimeout(() => {
      this.firestoreAccessor.hikaku(cMData, sentakushi_to_select, mode);
      setTimeout(() => {
        this.plotJunniRaderChart(cMData)
      }, 0)
    }, 0)
  }


  callSaigenTouanTeikyou(cMData: Mondai,  mode: string, ) {
    //GUIに入力した再現答案をRESTでPOSTする
    this.displaySpinner(`お待ち下さい..<BR>1分程度かかる場合があります`, 40000);

    this.setCurrentMondai(cMData.mondaiSyuId, cMData.mondaiId);
    this.innerState = "" + cMData.mondaiId;
    
    //let handleNamte = "testHandleName";
    let kaitouBun = this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId];
    
    setTimeout(() => {
      this.firestoreAccessor.saigenTouanTeikyou(cMData.mondaiId, kaitouBun, this.firestoreAccessor.currentGuiStatus.myName, false, false);
      // setTimeout(() => {
      //   this.saigenTouanTeikyou(cMData);
      // }, 0)
    }, 0)
  }

  async createNewGazou(){
    //再現答案提供のデフォルトファイルを更新する関数
    
    //特定の問題だけデフォルト画像差し替える場合
    let targetMondaiIdArray = [
      // {mondaiId: 201030111},
      // {mondaiId: 201030211},
      // {mondaiId: 201030311},
      // {mondaiId: 201030411},
      // {mondaiId: 201030511},

      // {mondaiId: 202030111}, 
      // {mondaiId: 202030121}, 
      // {mondaiId: 202030131}, 
      // {mondaiId: 202030141}, 
      // {mondaiId: 202030211}, 
      // {mondaiId: 202030311}, 
      // {mondaiId: 202030321}, 
      // {mondaiId: 202030411}, 

      // {mondaiId: 203030111}, 
      // {mondaiId: 203030121}, 
      // {mondaiId: 203030211}, 
      // {mondaiId: 203030212}, 
      // {mondaiId: 203030221}, 
      // {mondaiId: 203030222}, 
      // {mondaiId: 203030311}, 
      // {mondaiId: 203030411}, 

      {mondaiId: 204030111}, 
      {mondaiId: 204030112}, 
      {mondaiId: 204030121}, 
      {mondaiId: 204030211}, 
      {mondaiId: 204030212}, 
      {mondaiId: 204030221}, 
      {mondaiId: 204030231}, 
      {mondaiId: 204030232}, 
      {mondaiId: 204030311}, 
      {mondaiId: 204030321}, 
      {mondaiId: 204030322}, 
      {mondaiId: 204030411}, 
      {mondaiId: 204030421}
    ];
    
    //採点答案提供対象の問題の全部の画像を差し替える場合
    //let targetMondaiIdArray = this.firestoreAccessor.getMondaiArr_saigenTouanTeikyou();
    
    //let targetMondaiIdArray = [201030111,202030111]
    // setTimeout(() => {
    //   //console.log('hello')
    //   this.firestoreAccessor.reloadAshiatoData();
    //   setTimeout(() => {
    //     //console.log('callback')
    //     this.firestoreAccessor.updateCount_kaitou_and_timer();
    //   }, 1000)
    // }, 1000)
    
    console.log('一括デフォルト画像作成開始しますcreateNewGazou-- ');
    for (var i_tmi in targetMondaiIdArray) {
      //let randomDelayTime = Math.random();//実行タイミングをランダムにずらしてFlaskの負荷分散する
      let targetMondaiId = targetMondaiIdArray[i_tmi].mondaiId;

      // let kaitouBun =
      //   this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId];
      // kaitouBun.sentaku = '';
      // await this.firestoreAccessor.saigenTouanTeikyou(
      //   targetMondaiId,
      //   kaitouBun,
      //   this.firestoreAccessor.currentGuiStatus.myName,
      //   true,
      //   true
      // );
      
      await new Promise((resolve) => {
        setTimeout(() => {
          //console.log('This is iteration ' + i_tmi);
          console.log('targetMondaiId--', targetMondaiId);
          let kaitouBun =
            this.firestoreAccessor.currentGuiStatus.currentKaitou[
              targetMondaiId
            ];
          kaitouBun.sentaku = '';
          this.firestoreAccessor.saigenTouanTeikyou(
            targetMondaiId,
            kaitouBun,
            this.firestoreAccessor.currentGuiStatus.myName,
            true,
            true
          );
          resolve(true);
        }, 60000);
      });

    // this.delay(randomDelayTime * 60000).then(any => {
      //   console.log('targetMondaiId--', targetMondaiId);
      //   let kaitouBun =
      //     this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId];
      //   kaitouBun.sentaku = '';
      //   this.firestoreAccessor.saigenTouanTeikyou(
      //     targetMondaiId,
      //     kaitouBun,
      //     this.firestoreAccessor.currentGuiStatus.myName,
      //     true,
      //     true
      //   );
      // });

    }
   
  }

  // saigenTouanRestToObject(jsonString: string){
  //   //RESTで帰ってきた再現答案の一覧を、オブジェクトに変換し、検索文字のハイライト表示などをする
  //   let returnObject;
  //   //console.log('JSON.parse(jsonString)--', JSON.parse(jsonString));
  //   returnObject = JSON.parse(jsonString)
  //   //returnObject = returnObject.split(',')
  //   return returnObject
  // }

  // hikaku(cMData: Mondai, sentakushi_to_select: string, mode: string, ) {
  //   //再現答案を送信し、RESTから帰ってきたデータで、scatterを作図するメソッド
    
  //   setTimeout(() => {
  //     let targetMondaiId = cMData.mondaiId;
  //     let targetMondaiSyuId = cMData.mondaiSyuId;
  //     this.setCurrentMondai(targetMondaiSyuId, targetMondaiId);
  //     let kaitouStr: string = this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku;

  //     //ゴミ削除
  //     if (kaitouStr == "" || kaitouStr == null) {
  //       kaitouStr = "空欄"
  //     }
  //     kaitouStr = kaitouStr.replace(/(\r\n|\n|\r)/gm, ""); //改行削除
  //     kaitouStr = this.zenkaku2hankaku(kaitouStr); //全角英数字の半角化

  //     let changedSaitenVec = "";
  //     if (this.firestoreAccessor.currentGuiStatus.kaitoubun2jiChangedFlag == true) {
  //       changedSaitenVec = ""
  //     } else if (this.firestoreAccessor.currentGuiStatus.saitenKekka2jiChangedFlag == true) {
  //       //採点結果を書き換えている場合のみ、補正したベクトルを送信する
  //       if (this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[targetMondaiId]) {
  //         //初回起動時はNullの場合があるので分岐
  //         changedSaitenVec = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[targetMondaiId]))
  //       }

  //       //changedSaitenVec = this.firestoreAccessor.convertSaitenKekka2ji(cMData.mondaiId,true) ;
  //     }


  //     this.kaitouBun = {
  //       "mondaiId": cMData.mondaiId,
  //       "nendo": cMData.nendo,
  //       "mondaiSyuId": cMData.mondaiSyuId,
  //       "kaitouBunStr": kaitouStr,//this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku
  //       "myName": this.firestoreAccessor.currentGuiStatus.myName,
  //       "scatterSettings": this.scatterSettings,
  //       "changedSaitenVec": changedSaitenVec,
  //       "gouhi_jouhou": this.firestoreAccessor.currentGuiStatus.gouhi_jouhou[this.getMondaiIdSentou5(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId)]//this.firestoreAccessor.currentGuiStatus.gouhi_jouhou,
  //     }

  //     setTimeout(() => {
  //       //console.log('callback')
  //       this.innerState = "" + targetMondaiId;

  //       this.todoService
  //         .create(this.kaitouBun)
  //         .then(
  //           data => this.scatterDataAll = data[0]
  //         )
  //         // .then(
  //         //   data =>
  //         //     console.log("1111--")
  //         // )
  //         .then(
  //           data => this.firestoreAccessor.kaitouHistory2ji[cMData.mondaiId] = JSON.parse(this.scatterDataAll["kaitouDf"])
  //         )
  //         .then(
  //           data =>
  //             this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_model[cMData.mondaiId] = JSON.parse(this.scatterDataAll["saitenKekka2ji"])[0]//
  //         )
  //         .then(
  //           data =>
  //             this.firestoreAccessor.currentGuiStatus.saitenKekka2ji_view[cMData.mondaiId] = JSON.parse(this.scatterDataAll["saitenKekka2ji"])[0]//
  //         )
  //         // .then(
  //         //   data =>
  //         //   this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[cMData.mondaiId] = this.firestoreAccessor.convertSaitenKekka2ji(cMData.mondaiId) //this.firestoreAccessor.currentGuiStatus.saitenKekka2ji[targetMondaiId]//
  //         // )
  //         .then(
  //           data =>
  //             //console.log("AAAA--"+this.scatterDataAll["rankingDataArr"])
  //             this.firestoreAccessor.generateRankingData(cMData.mondaiId)
  //         )
  //         .then(
  //           data =>
  //             this.generateScatterTangoRanking(1, this.scatterDataAll["pc1Df"])
  //         )
  //         .then(
  //           data =>
  //             this.generateScatterTangoRanking(2, this.scatterDataAll["pc2Df"])
  //         )
  //         .then(
  //           data =>
  //             this.firestoreAccessor.currentGuiStatus.scatterValue[cMData.mondaiId] = [
  //               {//'合否不明'
  //                 x: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[0]["visible"],
  //                   "zahyouXarr"
  //                 ),
  //                 y: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[0]["visible"],
  //                   "zahyouYarr"
  //                 ),
  //                 text: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[0]["visible"],
  //                   "kaitou"
  //                 ),
  //                 type: 'scatter',
  //                 name: this.scatterSettings[0]["name"],
  //                 mode: 'markers',
  //                 textposition: 'bottom center',
  //                 marker: {
  //                   size: 10,
  //                   color: "rgba(10,15,20,0.5)",
  //                   symbol: this.scatterSettings[0]["symbol"],
  //                 },
  //                 hovertemplate: '%{text}',
  //               },
  //               {//合否ご提供有り
  //                 x: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[1]["visible"],
  //                   "zahyouXarr"
  //                 ),
  //                 y: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[1]["visible"],
  //                   "zahyouYarr"
  //                 ),
  //                 text: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[1]["visible"],
  //                   "kaitou"
  //                 ),
  //                 type: 'scatter',
  //                 name: this.scatterSettings[1]["name"],
  //                 mode: 'markers',
  //                 textposition: 'bottom center',
  //                 marker: {
  //                   colorscale: "Viridis",
  //                   showscale: true,
  //                   color: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                     cMData.mondaiId,
  //                     this.scatterSettings[1]["visible"],
  //                     "tokuten"
  //                   ),
  //                   size: this.firestoreAccessor.generateScatterSizeSymbolArr(
  //                     cMData.mondaiId,
  //                     this.scatterSettings[1]["visible"],
  //                     "size"
  //                   ),
  //                   symbol: this.firestoreAccessor.generateScatterSizeSymbolArr(
  //                     cMData.mondaiId,
  //                     this.scatterSettings[1]["visible"],
  //                     "symbol"
  //                   ),
  //                 },
  //                 hovertemplate: '%{text}',
  //               },
  //               {//予備校
  //                 x: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[2]["visible"],
  //                   "zahyouXarr"
  //                 ),
  //                 y: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[2]["visible"],
  //                   "zahyouYarr"
  //                 ),
  //                 text: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[2]["visible"],
  //                   "kaitou"
  //                 ),
  //                 type: 'scatter',
  //                 name: this.scatterSettings[2]["name"],
  //                 mode: 'markers',
  //                 textposition: 'bottom center',
  //                 marker: {
  //                   size: 12,
  //                   color: "rgba(10,15,20,0.8)",
  //                   symbol: this.scatterSettings[2]["symbol"],
  //                 },
  //                 hovertemplate: "予備校公式サイトでご確認下さい",//'%{text}',
  //               },
  //               {//'あなたの解答',
  //                 x: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[3]["visible"],
  //                   "zahyouXarr"
  //                 ),
  //                 y: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[3]["visible"],
  //                   "zahyouYarr"
  //                 ),
  //                 text: this.firestoreAccessor.getScatterDataArrFromKaitouHistory2ji(
  //                   cMData.mondaiId,
  //                   this.scatterSettings[3]["visible"],
  //                   "kaitou"
  //                 ),
  //                 type: 'scatter',
  //                 name: this.scatterSettings[3]["name"],
  //                 mode: 'markers',
  //                 textposition: 'bottom center',
  //                 marker: {
  //                   color: "rgba(250,70,70,0.8)",
  //                   size: 20,
  //                   symbol: this.scatterSettings[3]["symbol"],
  //                 },
  //                 hovertemplate: '%{text}',
  //               },
  //             ]
  //         )
  //         // .then(
  //         //   data => this.plotScatter(cMData)
  //         // )

  //         // .then(
  //         //   data =>
  //         //     this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[cMData.mondaiId] = [{
  //         //       x: [20],
  //         //       y: [''],
  //         //       name: 'SF Zoo',
  //         //       type: 'bar',
  //         //       orientation: 'h'
  //         //     }, {
  //         //       x: [20],
  //         //       y: [''],
  //         //       name: 'SF Zoo',
  //         //       type: 'bar',
  //         //       orientation: 'h'
  //         //     },

  //         //     ]
  //         // )
  //         .then(
  //           data =>
  //             //解答欄の更新チェックフラグを戻す
  //             this.firestoreAccessor.currentGuiStatus.kaitoubun2jiChangedFlag = false
  //         )
  //         .then(
  //           data =>
  //             //採点の更新チェックフラグを戻す
  //             this.firestoreAccessor.currentGuiStatus.saitenKekka2jiChangedFlag = false
  //         )
  //         .then(
  //           data =>
  //             //kaitouHistory2jiをDBに保存する
  //             this.firestoreAccessor.backUpObjectToDB('kaitouHistory2ji', this.firestoreAccessor.kaitouHistory2ji, true)
  //         )
  //         .then(
  //           data =>
  //             this.createTouanSuuBarChartData(cMData)
  //           //this.plotTouanSuuBarChart(cMData)
  //         )
  //         .then(
  //           data =>
  //             this.createJunniRaderChartData(cMData)
  //         )
  //         .then(
  //           data =>
  //             this.plotJunniRaderChart(cMData)
  //         )
  //         .then(
  //           data =>
  //             this.createGoodBadData(cMData)
  //         )
  //         .then(
  //           data =>
  //             this.barChartAccordionOpenTargetMondaiId = "default"//再現答案書き換えた後にボタンオスと棒グラフが消えるので、いっそのことアコーディオン閉じることにした
  //         )
  //         .then(
  //           data =>
  //             //CurrentGUIをDBに保存する
  //             this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus, true)
  //         )



  //       //Kaitouの末尾をメモリ上のKaitouで上書きして、KaitouをDBに保存する
  //       this.firestoreAccessor.kaitouHistory[cMData.mondaiId][this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length - 1] = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId]));
  //       this.firestoreAccessor.backUpObjectToDB('kaitouHistory', this.firestoreAccessor.kaitouHistory, true);
  //       //console.log("0011--")

  //       //操作ログの書き込み（処理後）
  //       this.firestoreAccessor.writeOperationLog(cMData.mondaiSyuId, cMData.mondaiId, 'hikaku()', true);
  //       //console.log("0022--")
  //     }, 0)
  //   }, 0)
    
  // }


  // getAllTodo(): void {
  //   this.todoService.getAllTodo()
  //     .then(todos => this.todo = todos);
  // }

  // pushData(data: Todo): void {
  //   this.newtodos.unshift(data);
  // }

  async selectSentakushi(cMData: Mondai, sentakushi_to_select: string, mode: string, nijiKakikaeBool?: boolean) {

    this.updateKaitou(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab], mode, sentakushi_to_select,nijiKakikaeBool);
    // //Modalにより、確認ダイアログ表示する
    // this.openModal(mode, true, sentakushi_to_select);

    // // //Toastにより、確認ダイアログ表示する
    // // let messageString:string = sentakushi_to_select + ' で解答しますか？';
    // // let iconString:string = 'heart';
    // // if (mode == 'exclude'){
    // //   //
    // //   messageString = sentakushi_to_select + ' を選択肢から除外しますか？';
    // //   iconString = 'trash';
    // // }
    // // const toast = await this.toastController.create({
    // //   color: 'primary',
    // //   message: messageString,
    // //   position: 'middle',
    // //   buttons: [
    // //     {
    // //       icon: iconString,
    // //       text: 'はい',
    // //       side: 'start',
    // //       handler: () => {
    // //         //console.log('Favorite clicked');
    // //         this.updateKaitou(cMData, mode, sentakushi_to_select);
    // //       }
    // //     }, {
    // //       //text: 'もどる',
    // //       //<ion-icon name="close-circle"></ion-icon>
    // //       icon: 'close-circle',
    // //       text: 'もう少し考える',
    // //       role: 'cancel',
    // //       handler: () => {
    // //         //console.log('Cancel clicked');
    // //       }
    // //     }
    // //   ]
    // // });
    // // toast.present();

  }

  updateKaitou(cMData: Mondai, operationName: string, selected: string, nijiKakikaeBool?:boolean) {
    //操作ログの書き込み（処理前）
    this.firestoreAccessor.writeOperationLog(cMData.mondaiSyuId, cMData.mondaiId, operationName + 'updateKaitou_before', false);

    let cfsWriteBool: boolean = false;//選択肢を選んだときなどCFS書き込みを絞るための変数

    //メモリ上のKaitouの、現在日付を更新
    let todayDate = new Date();
    todayDate.setTime(todayDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
    this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].dateTime = todayDate;

    //this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].dateTime = new Date();

    if (operationName == 'sentaku') {
      //メモリ上のKaitouを諸々更新　＠　選択肢を選択
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku = selected;
      this.fixSaitenKekka(cMData, selected);
      if (nijiKakikaeBool == true){
        //２次の解答の書き換え時の採点は、CFS更新頻度多すぎになるので、UPLOADさせない
        cfsWriteBool = false;
      } else {
        cfsWriteBool = true;//解答を確定した場合のみCFSにも書き込ませる
      }
      //this.scrollToGuiPosition(cMData.mondaiId,50);//sentakuまでスクロールする

      //正誤に応じた応援メッセージをTOAST表示
      this.displaySaitenKekkaToast(cMData, selected, operationName);
      //this.displaySaitenKekkaModal(cMData, selected, operationName);
    }

    if (operationName == 'exclude') {
      //メモリ上のKaitouを諸々更新　＠　消去法
      //除外対象に含まれていなければ除外対象の配列に加える。

      if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi == []) {
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi = [selected];
      } else if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi.indexOf(selected) !== -1) {
        //すでに選択済みであれば,その時点でこの関数の処理終了
        return;
      } else {
        //カラでもなく、すでに除外済みを再選択したのでもなければ、PUSH
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].excludedSentakushi.push(selected);
      }
      if (cMData.seikai == selected) {
        //正解と一致しちゃってた場合は、不正解になるよう除外による失点がわかる文字を選択したテイで、saitenを強制実行する
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku = 'exclude_failure';
        this.fixSaitenKekka(cMData, this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku);
        cfsWriteBool = true;//解答を確定した場合のみCFSにも書き込ませる
        //this.scrollToGuiPosition(cMData.mondaiId,50);//sentakuまでスクロールする

      }
      //正誤に応じた応援メッセージをTOAST表示
      this.displaySaitenKekkaToast(cMData, selected, operationName);
      //this.displaySaitenKekkaModal(cMData, selected, operationName);
    }

    if (operationName == 'flipOpen') {
      //メモリ上のKaitouを諸々更新　＠　フリップ開く
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].flipOpenedDic[selected] = true;

      cfsWriteBool = true;//flipでもログ送信する

      // //もし選択肢選択前にフリップ開いた場合はフラグ建てる処理。この処理使わないのでコメントアウトする
      // if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku == null) {
      //   this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].flipOpenedBeforeSelect = true;
      // }
    }

    if (operationName == 'memo') {
      //メモ更新時の処理
    }

    if (!this.firestoreAccessor.currentGuiStatus.privacy_policy_accepted) {
      //利用規約の同意がまだ状態で、書き込みが走る場合は、確認MODAL開く
      if(cfsWriteBool == true){
        this.openModal("privacy_policy_acceptance", true);
      }
    }
    this.persist_updated_kaitou(cMData,operationName,cfsWriteBool);//採点結果や解答をDB保存するなど後処理

    if (cMData.niji != true) {
      //一次試験の解答で、選択肢を選んだが、除外がたまたま正解だった場合は、得点を更新する
      //
      if (operationName == 'sentaku') {
        this.firestoreAccessor.tokutenSyuukei(cMData);
      }
      if (operationName == 'exclude') {
        if (cMData.seikai == selected) {
          this.firestoreAccessor.tokutenSyuukei(cMData);
        }
      }
    }

  }

  persist_updated_kaitou(cMData:Mondai, operationName: string,cfsWriteBool:boolean){
    //採点結果や解答をDB保存するなど後処理する関数

    //カレントの問題を更新する
    this.setCurrentMondai(cMData.mondaiSyuId, cMData.mondaiId);

    //CurrentGUIをDBに保存する
    this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus, cfsWriteBool);

    //Kaitouの末尾をメモリ上のKaitouで上書きして、KaitouをDBに保存する
    this.firestoreAccessor.kaitouHistory[cMData.mondaiId][this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length - 1] = JSON.parse(JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId]));
    this.firestoreAccessor.backUpObjectToDB('kaitouHistory', this.firestoreAccessor.kaitouHistory, cfsWriteBool);

    //操作ログの書き込み（処理後）
    this.firestoreAccessor.writeOperationLog(cMData.mondaiSyuId, cMData.mondaiId, operationName + 'updateKaitou_after', cfsWriteBool);
  }

  sleepByPromise(sec) {//タイマーカウント用のスリープの関数
    return new Promise(resolve => setTimeout(resolve, sec * 1000));
  }


  // async startActiveCheckThread() {
  //   let loopPeriod: number = 30;//30;//ループ間隔。この単位で学習時間加算
  //   let scrollPosition_lastTime: number = 2222;
  //   let scrollPosition_current: number = 2222;
  //   let mondaiSyuId_lastTime: number = 0;
  //   let mondaiSyuId_current: number = this.firestoreAccessor.currentGuiStatus.currentTab;

  //   while (true) {
  //     this.refreshKaitouCountToday()
  //     //ループのたびに日付は更新する。起動しっぱなしの場合のエラー対策
  //     let tmpDate = new Date();
  //     tmpDate.setTime(tmpDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
  //     const dateNow = tmpDate;

  //     this.firestoreAccessor.todayDate = tmpDate;

  //     //当日最初のループ処理の場合、解答数合計値など、日付変更に伴い初期化する
  //     if (!this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"]) {
  //       //前日分をバックアップする
  //       this.firestoreAccessor.backUpObjectToDB('timerCountTodayBackup', this.firestoreAccessor.currentGuiStatus.timerCountToday);

  //       //時間を入れる変数の入れ物が、その日の初回ループ時は存在しないので作る
  //       this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"] = {
  //         0: 0,
  //         101: 0,
  //         102: 0,
  //         103: 0,
  //         104: 0,
  //         105: 0,
  //         106: 0,
  //         107: 0
  //       };
  //       //当日時点の解答数や勉強時間の元ネタになるDateDataを、再生成
  //       setTimeout(() => {
  //         //console.log('hello')
  //         this.firestoreAccessor.reloadAshiatoData();
  //         setTimeout(() => {
  //           //Fab表示の、問題数を更新するためにデータリロードする
  //           this.firestoreAccessor.updateCount_kaitou_and_timer();
  //         }, 1000)
  //       }, 1000)
  //     }
  //     //Fab表示の、問題数を更新するためにデータリロードする
  //     this.firestoreAccessor.updateCount_kaitou_and_timer();

  //     this.content.getScrollElement().then(data => {
  //       mondaiSyuId_current = this.firestoreAccessor.currentGuiStatus.currentTab;
  //       scrollPosition_current = data.scrollTop;
  //       //console.log('scrollPosition_current_'+scrollPosition_current);
  //       if (mondaiSyuId_current != mondaiSyuId_lastTime || scrollPosition_current != scrollPosition_lastTime) {
  //         //前回チェック時の、科目化、スライド位置が変わっていれば、勉強時間を加算する処理。
  //         this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][mondaiSyuId_current] = this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][mondaiSyuId_current] + loopPeriod;
  //         //全科目の合計時間も更新
  //         let tmpTimerAllCount = 0;
  //         for (var kamokuMei in this.kamokuName) {
  //           tmpTimerAllCount = tmpTimerAllCount + this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][kamokuMei];
  //         }
  //         this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][0] = tmpTimerAllCount;
  //         //画面の応援メッセージ用のオブジェクト更新
  //         //this.firestoreAccessor.updateGuideMessageHtml(this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][0] , this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0]['true'] + this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0]['false']);
  //       }

  //       mondaiSyuId_lastTime = mondaiSyuId_current;
  //       scrollPosition_lastTime = scrollPosition_current;
  //     });

  //     await this.sleepByPromise(loopPeriod);
  //   }


  //   // let default_remain_seconds =(60*60)/25;//60分で25問。144秒
  //   // if (mondaiSyuId==101){default_remain_seconds = (60*60)/25} else
  //   // if (mondaiSyuId==102){default_remain_seconds = (60*60)/25} else
  //   // if (mondaiSyuId==103){default_remain_seconds = (90*60)/40} else
  //   // if (mondaiSyuId==104){default_remain_seconds = (90*60)/40} else
  //   // if (mondaiSyuId==105){default_remain_seconds = (60*60)/25} else
  //   // if (mondaiSyuId==106){default_remain_seconds = (60*60)/25} else
  //   // if (mondaiSyuId==107){default_remain_seconds = (90*60)/40}
  //   // default_remain_seconds = default_remain_seconds/10;

  //   // if (this.timer_remain[mondaiSyuId] > 0) {
  //   //   //もともと前回カウント中の値が残っていたら、今回分のカウントのループは行わず、カウントの残数の補填だけする（１秒あたり２階カウントしてしまうため）
  //   //   for (var kamokuMei in this.kamokuName) {
  //   //     if (kamokuMei == String(mondaiSyuId)) {
  //   //       this.timer_remain[mondaiSyuId] = default_remain_seconds;
  //   //     } else {
  //   //       //他科目の残り時間を0にリセット。
  //   //       this.timer_remain[kamokuMei] = 0;
  //   //     }
  //   //   }
  //   // } else {
  //   //   //前回カウントが終了していた場合は、カウント処理のスレッドを実行する

  //   //   for (var kamokuMei in this.kamokuName) {
  //   //     if (kamokuMei == String(mondaiSyuId)) {
  //   //       this.timer_remain[mondaiSyuId] = default_remain_seconds;
  //   //     } else {
  //   //       //他科目の残り時間を0にリセット。
  //   //       this.timer_remain[kamokuMei] = 0;
  //   //     }
  //   //   }
  //   //   let scrollPosition_current:number = 2000;
  //   //   let scrollPosition_lastTime:number = 2000;
  //   //   while (this.timer_remain[mondaiSyuId] > -1) {
  //   //     console.log('this.timer_remain[mondaiSyuId]_' + this.timer_remain[mondaiSyuId]);
  //   //     //remainが０未満になるまでループする
  //   //     if (this.firestoreAccessor.currentGuiStatus.currentTab == mondaiSyuId) {
  //   //       //毎秒チェック。タブが切り替わっていれば、該当の問題のRemainを０にリセット
  //   //       this.timer_remain[this.firestoreAccessor.currentGuiStatus.currentTab] = this.timer_remain[this.firestoreAccessor.currentGuiStatus.currentTab] - 1;//1
  //   //       // this.currentGuiStatus.timerCountAll[this.currentGuiStatus.currentTab] = this.currentGuiStatus.timerCountAll[this.currentGuiStatus.currentTab] + 1;

  //   //       //実行日ぶんのTimerCounterの時間を１秒分加算する処理。
  //   //       if (!this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"]) {
  //   //         //時間を入れる変数の入れ物がなければ作る
  //   //         this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"] = {
  //   //           0: 0,
  //   //           101: 0,
  //   //           102: 0,
  //   //           103: 0,
  //   //           104: 0,
  //   //           105: 0,
  //   //           106: 0,
  //   //           107: 0
  //   //         };
  //   //       }
  //   //       this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][mondaiSyuId] = this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][mondaiSyuId] + 1;//1
  //   //       //全科目の合計時間も更新
  //   //       let tmpTimerAllCount = 0;
  //   //       for (var kamokuMei in this.kamokuName) {
  //   //         tmpTimerAllCount = tmpTimerAllCount + this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][kamokuMei];
  //   //       }
  //   //       this.firestoreAccessor.currentGuiStatus.timerCountToday[JSON.stringify(dateNow).substr(1, 10) + "T00:00:00.000Z"][0] = tmpTimerAllCount;

  //   //       this.content.getScrollElement().then(data => {
  //   //         //this.scrollPosition = data.scrollTop;
  //   //         scrollPosition_current = data.scrollTop;
  //   //         console.log('scrollPosition_current_' + scrollPosition_current);
  //   //         if (this.timer_remain[mondaiSyuId] == 0) {
  //   //           if (scrollPosition_current == scrollPosition_lastTime) {
  //   //             //標準時間チェック。スライド位置が変わっていなければ、Remainを減らして、While終了させる
  //   //             this.timer_remain[mondaiSyuId] = -1;
  //   //           } else {
  //   //             //標準時間チェック。スライド位置が変わっていれば、Remainを標準時間で、補填リセットし、スライド位置を次回チェック向けに保存
  //   //             this.timer_remain[mondaiSyuId] = default_remain_seconds;
  //   //             scrollPosition_lastTime = scrollPosition_current;
  //   //           }
  //   //         }
  //   //       });
  //   //       await this.sleepByPromise(1);
  //   //     } else {
  //   //       this.timer_remain[mondaiSyuId] = 0;
  //   //       break;
  //   //     }
  //   //   }
  //   // }

  // }

  hintVisibleChanged(cMData: Mondai) {
    //CurrentGUIをDBに保存する
    this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);

    this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].hint_visible = !this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].hint_visible;
    //console.log('changed_'+cMData)
    //カレントの問題を更新する
    //this.setCurrentMondai(cMData.mondaiSyuId,cMData.mondaiId);
  }


  updateSaitenKekka_niji_tokuten(cMData: Mondai){
    //採点結果をユーザが修正した期の更新処理
    let tmpTokutenSum: number = 0;
    for (let i_seikai in cMData.seikai_niji) {

      if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji[cMData.ronten[i_seikai]+"【"+cMData.haiten_niji[i_seikai]+"点】"] == true ) {
        //正解の場合は、採点基準ごとの得点を加算
        tmpTokutenSum = tmpTokutenSum + cMData.haiten_niji[i_seikai];
      }
    }
    //得点を格納
    if (tmpTokutenSum >= cMData.haiten) {//配点以上にならないよう処理
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji_tokuten = cMData.haiten;
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = true;
    } else {
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji_tokuten = tmpTokutenSum;
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = false;
    }

    this.persist_updated_kaitou(cMData,'updateSaitenKekka_niji_tokuten',false);//採点結果や解答をDB保存するなど後処理
  }

  fixSaitenKekka(cMData: Mondai, selected: string) {

    //問題の正誤と、２次の場合は採点基準ごとの結果を確定する
    let kekka: boolean;
    //let toastString = '';
    if (cMData.niji) {//２次試験の場合の採点。合格点Over場合のみ正解にする
      //Mondaidataの採点基準だけループしてマルバツつけて、ループ中の累積点数＞配点　なら、Trueを返す関数を実行

      //採点結果を入れる変数の辞書がNullなら初期化
      if (!this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji) {
          this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji = {};
      }

      //解答が、採点基準の文字を含んでたら、結果の辞書にtrue入れる（正規表現で判定）
      //let selected_noSpace: string = selected;
      for (let i_seikai in cMData.seikai_niji) {
        let saitenResult :boolean;
        if (RegExp(cMData.seikai_niji[i_seikai]).test(selected)) {
          saitenResult = true;
          //正解の場合は、採点基準ごとの得点を加算
          //tmpTokutenSum = tmpTokutenSum + cMData.haiten_niji[i_seikai];
        } else {//不正解ならfalseを入れておく
          saitenResult = false;
        }
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji[cMData.ronten[i_seikai]+"【"+cMData.haiten_niji[i_seikai]+"点】"] = saitenResult;
      }

      //採点結果（正誤）や得点を更新
      this.updateSaitenKekka_niji_tokuten(cMData);
      // //this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji_tokuten = tmpTokutenSum;

      // //全論点あわせた得点が配点を超えていれば、全体のsatenKekkaもtrueにする。
      // if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka_niji_tokuten >= cMData.haiten) {
      //   this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = true;
      // } else {
      //   this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = false;
      // }

    } else {//１次試験の問題なら、採点結果の変数に正誤を記入
      if (selected == 'exclude_failure') {
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = false;
      } else if (selected == cMData.seikai) {
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = true;
      } else {
        this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = false;
      }
    }
    //this.presentToastWithOptions(toastString, 6000, 'middle','tertiary');
    //this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka = kekka;
    this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].hint_visible = true;
    this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].dateTime_sentakuFixedAt = this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].dateTime;//採点結果確定時点の日時を保存


    setTimeout(() => {
      //console.log('hello')
      this.firestoreAccessor.reloadAshiatoData();
      setTimeout(() => {
        //console.log('callback')
        this.firestoreAccessor.updateCount_kaitou_and_timer();
      }, 1000)
    }, 1000)

    this.refreshKaitouCountToday();//日付が変わっていれば、回答回数のカウントを初期化する


    this.firestoreAccessor.currentGuiStatus.kaitouCountToday[cMData.mondaiSyuId] = this.firestoreAccessor.currentGuiStatus.kaitouCountToday[cMData.mondaiSyuId] + 1;
    this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0] = this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0] + 1;

    // for (var kamokuMei in this.kamokuName) {
    //   //全科目合計の解答回数を再計算
    //   this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0] = this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0] + this.firestoreAccessor.currentGuiStatus.kaitouCountToday[Number(kamokuMei)];
    // }
    // console.log("afterr_"+this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0]);
  }

  displaySaitenKekkaModal(cMData: Mondai, selected: string, operationName: string) {
    //let kekkaString = 'true';
    let kekkaJson = { 'sentakuMode': operationName, 'kekka': '', 'kekka_lastTimeKaitou': '' };
    //let modeString = 'saitenKekka_sentaku';
    if (operationName == 'sentaku') {
      if (selected == cMData.seikai) {
        kekkaJson.kekka = 'true';
      } else {
        kekkaJson.kekka = 'false';
      }
    } else if (operationName == 'exclude') {
      if (selected == cMData.seikai) {
        kekkaJson.kekka = 'false';
      } else {
        kekkaJson.kekka = 'true';
      }
    }

    if (this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length >= 2) {
      //前回解答が存在する場合は、正誤をkekkaJsonに格納する
      if (this.firestoreAccessor.kaitouHistory[cMData.mondaiId][this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length - 2].saitenKekka != null) {
        kekkaJson['kekka_lastTimeKaitou'] = String(this.firestoreAccessor.kaitouHistory[cMData.mondaiId][this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length - 2].saitenKekka);
      }
    }
    this.openModal('saitenKekka', false, JSON.stringify(kekkaJson), 6000);
  }

  displaySaitenKekkaToast(cMData: Mondai, selected: string, operationName: string) {
    let toastString = '';
    let toastColor = 'primary';
    //let cssClassStr = '';

    let kekkaJson = { 'sentakuMode': operationName, 'kekka': '', 'kekka_lastTimeKaitou': '' };
    //let modeString = 'saitenKekka_sentaku';
    if (operationName == 'sentaku') {
      if (selected == cMData.seikai) {
        kekkaJson.kekka = 'true';
      } else {
        kekkaJson.kekka = 'false';
      }
    } else if (operationName == 'exclude') {
      if (selected == cMData.seikai) {
        kekkaJson.kekka = 'false';
      } else {
        kekkaJson.kekka = 'true';
      }
    }

    if (this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length >= 2) {
      //前回解答が存在する場合は、正誤をkekkaJsonに格納する
      if (this.firestoreAccessor.kaitouHistory[cMData.mondaiId][this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length - 2].saitenKekka != null) {
        kekkaJson['kekka_lastTimeKaitou'] = String(this.firestoreAccessor.kaitouHistory[cMData.mondaiId][this.firestoreAccessor.kaitouHistory[cMData.mondaiId].length - 2].saitenKekka);
      }
    }

    if (cMData.niji != true) {
      //1次問題の場合のみ、Toast表示する
      if (kekkaJson['sentakuMode'] == 'exclude') {
        //除外の場合のメッセージ
        if (kekkaJson['kekka'] == 'true') {
          toastString = '○';;//'⭕正解（消去法で良い選択肢です）🍻';
          toastColor = 'success';
        } else {
          toastString = '☓ 正解の選択肢 ';//'❌不正解（正解の選択肢でした）🔥';
          toastColor = 'danger';
        }
      } else if (kekkaJson['sentakuMode'] == 'sentaku') {
        //選択の場合のメッセージ
        if (kekkaJson['kekka'] == 'true') {
          //正解の場合
          toastColor = 'success';
          if (kekkaJson['kekka_lastTimeKaitou'] == '') {
            //初回＋正解
            toastString = '○ 正解';//'⭕正解🍻';
          } else if (kekkaJson['kekka_lastTimeKaitou'] == 'true') {
            //正解、正解
            toastString = '○ 前回も正解';//'⭕正解（前回も正解）🍻';
          } else if (kekkaJson['kekka_lastTimeKaitou'] == 'false') {
            //不正解、正解
            toastString = '○ 前回は不正解';//'⭕正解（前回は不正解！）🍻';
          }
        } else if (kekkaJson['kekka'] == 'false') {
          //不正解の場合
          toastColor = 'danger';
          if (kekkaJson['kekka_lastTimeKaitou'] == '') {
            //初回＋不正解
            toastString = '☓';//'❌不正解🔥';
          } else if (kekkaJson['kekka_lastTimeKaitou'] == 'true') {
            //正解＋不正解
            toastString = '☓ 前回は正解';//'❌不正解（前回は正解）💣';
          } else if (kekkaJson['kekka_lastTimeKaitou'] == 'false') {
            //不正解＋不正解
            toastString = '☓ 前回も不正解';//'❌不正解（前回も不正解）☔';
          }
        }
      }

      this.presentToastWithOptions(toastString, 4000, 'middle', toastColor);
    }
  }

  // excludeSentakushi(cMData:Mondai,sentakushi_to_exclude:string) {
  //   this.updateKaitou(cMData, 'exclude', sentakushi_to_exclude);
  //   // this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);

  //   // if (cMData.seikai == sentakushi_to_exclude){
  //   //   //正解と一致しちゃってた場合は、不正解になるよう除外による失点がわかる文字を選択したテイで、saitenを強制実行する
  //   //   this.saiten(cMData,'exclude_failure');
  //   // }
  // }

  // saiten(cMData:Mondai,selectedSentakushi:string) {

  //   //選択肢が選ばれていたら採点処理する
  //   if (selectedSentakushi != '') {
  //     //正誤を判定
  //     let kekka: Boolean;
  //     let toastString = '';
  //     if (selectedSentakushi == 'exclude_failure') {
  //       //
  //       kekka = false;
  //       toastString = '❌ 不正解です😨除外した選択肢は正解でした';
  //     } else if (selectedSentakushi == cMData.seikai) {
  //       kekka = true;
  //       toastString = '⭕ 正解です😄';
  //     } else {
  //       kekka = false;
  //       toastString = '❌ 不正解です😨';
  //     }
  //     this.presentToastWithOptions(toastString,6000,'middle');

  //     let saitenDate = new Date();
  //     saitenDate.setTime(saitenDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
  //     //let saitenDateString:string = JSON.stringify(saitenDate);
  //     const kaitouData: Kaitou = {
  //       mondaiId: cMData.mondaiId,
  //       sentaku: selectedSentakushi,
  //       dateTime: saitenDate,
  //       saitenKekka: kekka,
  //       myName: this.firestoreAccessor.currentGuiStatus.myName,
  //       // categoryMode: this.firestoreAccessor.currentGuiStatus.categoryMode[cMData.mondaiSyuId]
  //     };

  //     // if (this.firestoreAccessor.currentGuiStatus.categoryMode[cMData.mondaiSyuId]){
  //     //   //カテゴリー出題の場合は、単語IDと単語名も保存する
  //     //   kaitouData.tangoId = this.tangoId;

  //     //   //単語IDから、該当する単語の名前を取得・保存する
  //     //   let TANGODATACopied: TangoData[] = JSON.parse(JSON.stringify(this.tangoDataMaster));
  //     //   let tmpTangoDataMaster: TangoData[] = TANGODATACopied.filter(data => {
  //     //     return (data.tangoId == this.tangoId);
  //     //   });

  //     //   kaitouData.tangoName = tmpTangoDataMaster[0].name;
  //     // }

  //     if (this.kaitouHistory == null) {
  //       //初回解答時は、変数を初期化する
  //       let mId = cMData.mondaiId;
  //       this.kaitouHistory = {};
  //       //this.kaitouHistory[mId] = JSON.parse(JSON.stringify([kaitouData]));
  //       this.kaitouHistory[mId] = [JSON.parse(JSON.stringify(kaitouData))];
  //       // this.presentToastWithOptions('🔰 次の問題を表示するには、山のマークをクリックします',15000,'bottom');

  //     } else if (this.kaitouHistory[cMData.mondaiId]) {
  //       this.kaitouHistory[cMData.mondaiId].push(JSON.parse(JSON.stringify(kaitouData)));
  //     } else { //問題の初回採点時は配列を新規に作成する
  //       let kaitouArray: Kaitou[] = [];
  //       kaitouArray.push(JSON.parse(JSON.stringify(kaitouData)));
  //       this.kaitouHistory[cMData.mondaiId] = kaitouArray;
  //     }

  //     //解答回数のカウントを加算
  //     this.firestoreAccessor.currentGuiStatus.kaitou_count = Object.keys(this.kaitouHistory).length;

  //     //回答履歴をDBに保存
  //     //this.insertRecord('kaitouHistory', this.kaitouHistory);
  //     this.firestoreAccessor.backUpObjectToDB('kaitouHistory', this.kaitouHistory, this.firestoreAccessor.currentGuiStatus.myName);
  //     this.firestoreAccessor.insertKaitou(cMData.mondaiId, kaitouData,this.firestoreAccessor.currentGuiStatus.myName);

  //   }
  //   this.firestoreAccessor.currentGuiStatus.mondaiVisible[cMData.mondaiSyuId] = false;

  // }

  async openModal(modalMode: string, closeButtonVisible: boolean, modalModeOption?: string, duration?: number) {
    // console.log('modalMode_'+modalMode);
    // console.log('closeButtonVisible_'+closeButtonVisible);
    // console.log('modalModeOption_'+modalModeOption);
    let animationEnter = undefined;
    let animationLeave = undefined;
    let backdropDismissBool = true;

    if (modalMode == 'privacy_policy_acceptance' || 'gouhiJouhou') {
      backdropDismissBool = false;
    } else if (modalMode == 'welcome') {
      backdropDismissBool = false;
    } else if (modalMode == 'mokuji' || modalMode == 'ashiato' || modalMode == 'ranking') {
      // animationEnter = myEnterAnimation;//ionic
      // animationLeave = myLeaveAnimation;
    }

    let durationNum: number = 0;
    if (duration) {
      durationNum = duration;
    }

    const modal = await this.modalController.create({
      component: ModalPage,
      backdropDismiss: backdropDismissBool,
      enterAnimation: animationEnter,
      leaveAnimation: animationLeave,
      cssClass: 'custom-modal-css',
      componentProps: {
        modalMode: modalMode,
        modalModeOption: modalModeOption,
        // targetMondai: targetMondai,
        // targetUrl: targetUrl,//this.generateSnsLinkUrl(targetMondai.mondaiId),
        // mondaiIdArray: this.mondaiIdArray,
        // firestoreAccessor: this.firestoreAccessor,
        // sioriDictionary: this.sioriDictionary,
        // myName: this.currentGuiStatus.myName,
        // snsButtonIcon: this.snsButtonIcon,
        nendoName: this.nendoName,
        kamokuName: this.kamokuName,
        mokujiDataSourceDic: this.mokujiDataSourceDic,
        //kaitouHistory: this.firestoreAccessor.kaitouHistory,
        sippituMode: this.sippituMode,
        mokujiButtonDifficultyLabel: this.mokujiButtonDifficultyLabel,
        firestoreAccessor: this.firestoreAccessor,
        //todoService: this.todoService,
        closeButtonVisible: closeButtonVisible,
        nameCategoryModeMokuji: this.nameCategoryModeMokuji,
        nameLevel: this.nameLevel,
        nameMondaiSyuId: this.nameMondaiSyuId,
        pickerColumnsDef: this.pickerColumnsDef,
        tutorial_color: this.tutorial_color,
        mappingKamokuIconName: this.mappingKamokuIconName,
        // //okotae: okotae,
        // sakuinKeyword: sakuinKeyword,
        // sakuinData: this.sakuinData[sakuinKeyword],
        // ouenBunshou: ouenBunshou,
        // snsBunshou: snsBunshou,
        colorScheme: this.colorScheme,
        colorSchemeMapping: this.colorSchemeMapping,
        nendoNameShort: this.nendoNameShort,
        duration: durationNum,
        urlParamUrlOptionString: this.urlParamUrlOptionString,
        kaijiData: this.firestoreAccessor.kaijiData,
      }
    });

    this.firestoreAccessor.writeOperationLog(this.firestoreAccessor.currentGuiStatus.currentTab , this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId, 'openModal('+modalMode+')', false);

    modal.onDidDismiss()
      .then((res) => {
        //console.log("modalresponce_is_"+JSON.stringify(res.data));
        if (res.data != null) {
          if (res.data["mode"] == 'mokuji') {
            //もくじで問題のボタンを押していた場合は、もくじや問題を書き換え
            this.setCurrentMokujiCategory(res.data['targeKamokuId'], res.data['mokujiItem']);
            this.jumpToMondai(res.data['targeKamokuId'], res.data['targetMondaiId'], 700);
          } else if (res.data["mode"] == 'welcome'){
            //初期表示するタブはHomeにする
            //this.mokujiTabTapped('home');
          } else if (res.data["mode"] == 'sentaku' || res.data["mode"] == 'exclude') {
            //選択肢の、選択や除外の場合は、解答UPDATE実行
            this.updateKaitou(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab], res.data["mode"], res.data["sentakushi_to_select"]);
          } else if (res.data["mode"] == 'kaitouZumiMondaiKaitouReloadConfirm') {
            if (res.data['reloadKaitou'] == true) {
              //解答済みのリロードを選択した場合、カレントの解答を初期化する
              let cMData : Mondai = this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab];
              if (cMData.niji == true){
                //2次試験の問題の場合は、大問List全て初期化する

                let scrollToPositionNum:number = cMData.daimonList.indexOf(cMData.mondaiId);
                console.log("scrollToPositionNum_"+scrollToPositionNum)
                for (let i_pst in cMData.daimonList){
                  this.reloadCurrentKaitou(this.firestoreAccessor.getOneMondaiFromFile( this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab][Number(i_pst)]), scrollToPositionNum);

                }
              } else {
                //１次試験の問題なら最初の問題だけ
                this.reloadCurrentKaitou(this.firestoreAccessor.getOneMondaiFromFile(this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab][0]), 0);
              }
            }
          } else if (res.data["mode"] == 'kansou') {
            if (res.data['sendKansou'] == true) {
              //CFSに書き込む
              this.firestoreAccessor.insertKansou();
              this.presentToastWithOptions('✈️送信しました', 5000, 'middle', 'tertiary');
              //記入欄を空欄にする
              this.firestoreAccessor.kansouString = '';

            }
          } else if (res.data["mode"] == 'kaiji') {
            //console.log("AAAAAAAAAAAAAA");
            this.delay(500).then(any => {
              this.openModal("kaiji", true);
            });
            // if (res.data['sendKansou'] == true) {
            //   //CFSに書き込む
            //   this.firestoreAccessor.insertKansou();
            //   this.presentToastWithOptions('✈️送信しました', 5000, 'middle', 'tertiary');
            //   //記入欄を空欄にする
            //   this.firestoreAccessor.kansouString = '';

            // }
          }
          //  else if (res.data["mode"] == 'gouhiJouhou') {
          //   //Flaskni採点処理を投げる処理を通じて、gohiJouhouを一旦Flaskへ送信
          //   if (this.firestoreAccessor.currentGuiStatus.gouhiJouhou[0]["gouhi"] == "合格"){
          //     alert("ご回答ありがとうございました。合格おめでとうございます");
          //   } else {
          //     alert("ご回答ありがとうございました");
          //   }

          //   this.hikaku(
          //     this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab],
          //     this.firestoreAccessor.currentGuiStatus.currentKaitou[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId].sentaku,
          //     'sentaku'
          //     );
          // }
        }
        //ashiatoを閉じた際に、足跡の科目絞り込み戻す
        this.firestoreAccessor.currentGuiStatus.ashiatoHyoujiKamoku = 0;

        //解答数合計など、日付絞り込みGUIの影響をクリアする
        this.firestoreAccessor.currentGuiStatus.dateRangeDual = { "lower": -1 * (this.firestoreAccessor.daysFromStartingDate + 1), "upper": 0 };
        this.firestoreAccessor.reloadAshiatoData();
        this.firestoreAccessor.updateCount_kaitou_and_timer();

      });
    await modal.present();
  }

  openMokuji(closeButtonVisible: boolean) {
    this.displaySpinner(`お待ち下さい..`, 1500);
    this.delay(500).then(any => {
      this.openModal("mokuji", closeButtonVisible);
    });
  }


  async presentToastWithOptions(toastMessage: string, seconds: number, position: string, color?: string, cssClassStr?: string) {
    let durationNumber = 0;
    let clr = 'dark';
    if (seconds != 0) {
      durationNumber = seconds;
    }
    if (color != null) {
      clr = color;
    }

    if (position == 'top') {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: 'とじる',
            role: 'cancel',
          }
        ],
        duration: durationNumber,
        position: 'top',
        cssClass: cssClassStr,
        color: clr,
        translucent: false
      });
      toast.present();
    } else if (position == 'middle') {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: 'とじる',
            role: 'cancel',
          }
        ],
        duration: durationNumber,
        position: 'middle',
        cssClass: cssClassStr,
        color: clr,
        translucent: false
      });
      toast.present();
    } else if (position == 'bottom') {
      const toast = await this.toastController.create({
        message: toastMessage,
        buttons: [
          {
            text: 'とじる',
            role: 'cancel',
          }
        ],
        duration: durationNumber,
        position: 'bottom',
        cssClass: cssClassStr,
        color: clr,
        translucent: false
      });
      toast.present();
    }
  }

  getMondaiIdSentou5(targetMondaiId : number){
    //問題IDの先頭5文字を返す
    return String(targetMondaiId).slice( 0, 5 );
  }

  setCurrentMondai(mondaiSyuId: number, mondaiId: number) {
    let cMData: Mondai = this.firestoreAccessor.getOneMondaiFromFile(mondaiId);
    this.firestoreAccessor.currentGuiStatus.currentMondai[mondaiSyuId] = cMData;


    //2次試験の比較の場合は
    //合否データがなければ枠を作成する
    if (!this.firestoreAccessor.currentGuiStatus.gouhi_jouhou[this.getMondaiIdSentou5(cMData.mondaiId)]) {
      this.firestoreAccessor.currentGuiStatus.gouhi_jouhou[this.getMondaiIdSentou5(cMData.mondaiId)] = {
        gouhiView: "",
        mondaiSyuId: cMData.mondaiSyuId,
        myName: this.firestoreAccessor.currentGuiStatus.myName,
        nendo: cMData.nendo,
        visible: "student",
        handleName: this.firestoreAccessor.currentGuiStatus.handleName,//"匿名",
        tokutenKoukai: true,
      }
    }

    if (
      cMData.niji &&
      cMData.nendo == this.firestoreAccessor.saigenTouanTeikyouTaisyouNendo 
      //!this.firestoreAccessor.currentGuiStatus.saigenTouanTeikyouResult[mondaiId]&&//取りに行くだけなんだから、古い画像表示させるよりはフォーカスごとに最新画像取りに行くようコメントアウト
      //&& !this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku
      ){
        //２次試験かつ、再現答案提供の対象年度の場合で、
        //再現答案を提供した結果にエられるワードクラウドなどを格納したデータがまだない場合は、
        
        //kaitouUbがカラのRESTを送って、非提供者用の画像B64データを取得させる
        //再現答案の文章は、空文字を仮で入れて、デフォルト画像を取得フラグをTRUEにしてREST送信
        //this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku = "";
        this.firestoreAccessor.saigenTouanTeikyou(mondaiId, this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId], this.firestoreAccessor.currentGuiStatus.myName,true,false);

        // //デフォルトの分析画像を取得するRESTを実行
        // this.firestoreAccessor.doRest_getDeafultBunsekiGazou(mondaiId, this.firestoreAccessor.currentGuiStatus.myName,false);
      
    }


    //レーダーチャート、再描画する
    // setTimeout(() => {
    //   console.log('setCurrentMondai-hello')
    //   this.createJunniRaderChartData(cMData)
    //   setTimeout(() => {
    //     console.log('setCurrentMondai2-callback')
    //     setTimeout(() => {
    if (this.firestoreAccessor.currentGuiStatus.junniRaderChartData[this.getMondaiIdSentou5(cMData.mondaiId)]) {
      //既にデータがあれば描画する
      this.plotJunniRaderChart(cMData)
    }
    //     }, 0)
    //   }, 0)
    // }, 0)

    // for (var key_kamoku in this.kamokuName) {
    //   //カレントの問題が変わった時点で、一旦仕掛中のタイマーはリセットする
    //   this.firestoreAccessor.timer_remain[key_kamoku] = 0;
    // }
    // console.log("mondaiSyuId_"+mondaiSyuId)
    // console.log("mondaiId_"+mondaiId)
    // console.log("this.firestoreAccessor.currentGuiStatus.currentMondai[mondaiSyuId]"+JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentMondai[mondaiSyuId]))
  }

  jumpToKamoku(mondaiSyuId: number) {
    //科目のショートカット押した際の処理
    this.showTab(mondaiSyuId);
    this.jumpToMondai(mondaiSyuId, this.firestoreAccessor.currentGuiStatus.currentMondai[mondaiSyuId].mondaiId);

  }


  dateFormat = {
    _fmt: {
      "yyyy": function (date) { return date.getFullYear() + ''; },
      "MM": function (date) { return ('0' + (date.getMonth() + 1)).slice(-2); },
      "dd": function (date) { return ('0' + date.getDate()).slice(-2); },
      "hh": function (date) { return ('0' + date.getHours()).slice(-2); },
      "mm": function (date) { return ('0' + date.getMinutes()).slice(-2); },
      "ss": function (date) { return ('0' + date.getSeconds()).slice(-2); }
    },
    _priority: ["yyyy", "MM", "dd", "hh", "mm", "ss"],
    format: function (date, format) {
      return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format)
    }
  };


  // bottomMokujiOpen() {
  //   this.innerState = "selected";
  //   this.delay(4000).then(any => {
  //     this.innerState = "default";
  //   });
  // }

  homeAccordionOpen(name: string) {
    if (this.innerState == name) {
      this.innerState = '';
    } else {
      this.innerState = name;
    }

    if (name == 'profile') {
      //profileのアコーディオン開いたときは、スクリプト読み込み
      //twitterの対処///////////
      var script = document.createElement('script');//scriptタグを作ります
      script.async = true;
      script.setAttribute('src', "https://platform.twitter.com/widgets.js");
      script.setAttribute('charset', 'utf-8');
      //aタグ、scriptタグの順で設置します
      var div = document.getElementById("scriptTag-twitter");//ボタンを置きたい場所の手前の要素を取得
      // div.parentNode.insertBefore(element,div.nextSibling);//ボタンを置きたい場所にaタグを追加
      div.parentNode.insertBefore(script, div.nextSibling);//scriptタグを追加してJSを実行し、aタグをボタンに変身させる
    }
  }


  getGuideMessage(keyStr: string): string {
    //
    // console.log("this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0]['true'] + this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0]['false']_"+this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0]['true'] + this.firestoreAccessor.currentGuiStatus.kaitouCountToday[0]['false']);

    let returnStr: string = '';

    if (//this.firestoreAccessor.getTimerCountToday(0, 'm') != '0' &&
      this.firestoreAccessor.getKaitouCountToday(0) == 0) {//判定条件と表示がどう考えても逆だが、この状態のほうが正しく動作するのでこうする
      //console.log('勉強0分ではないが、問題といてない');
      //console.log('問題1つは解いた');
      if (keyStr == 'iconClass') { returnStr = 'far fa-smile-beam' }
      else if (keyStr == 'message-upper') { returnStr = 'いいね!' }
      else if (keyStr == 'message-lower') { returnStr = 'まず1問' }
    } else {
      if (keyStr == 'iconClass') { returnStr = 'far fa-laugh-beam' }
      else if (keyStr == 'message-upper') { returnStr = 'この' }
      else if (keyStr == 'message-lower') { returnStr = '調子!' }
    }

    //console.log('returnStr_'+returnStr)
    return returnStr;
  }

  set_tutorial_mode(modeChangeTo: boolean) {
    this.firestoreAccessor.tutorial_mode = modeChangeTo;
    if (modeChangeTo) {
      //tutorial_modeに突入するときは、1番目の問題だけに絞る
      //this.firestoreAccessor.tutorial_mondai_visible_max_number = 1;
    } else {

      this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] = this.mokujiDataSourceDic[this.firestoreAccessor.currentGuiStatus.currentTab][this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab]][0];

      // //チュートリアル終了後のモーダル表示
      // this.delay(1000).then(any => {
      //   this.openModal('faq', true, "tutorialFaq");
      // });

    }
  }

  // changeMainTab(tabName:string){
  //   this.firestoreAccessor.currentGuiStatus.currentMainTab = tabName;
  //   if (tabName == 'mokuji'){
  //     //もくじのときは、表示用の変数を実行時の科目等に合わせて変更する
  //     this.currentMokujiStatus = {
  //         //currentKamoku: 106,
  //         mondaiSyuIdMokuji: String(this.firestoreAccessor.currentGuiStatus.currentTab),
  //         categoryModeMokuji: ""+this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab],
  //         levelMokuji: '2'//String(this.firestoreAccessor.currentGuiStatus.level[this.firestoreAccessor.currentGuiStatus.currentTab]),
  //     };
  //     this.reloadMokuji();
  //   }
  // }

  reloadMokuji() {
    this.mokujiVirtualList = [];
    //もくじの再読み込み
    this.mokujiDataList = this.firestoreAccessor.getMokujiDataList(
      this.firestoreAccessor.currentGuiStatus.currentTab,
      this.firestoreAccessor.currentMokujiStatus.categoryModeMokuji[this.firestoreAccessor.currentGuiStatus.currentTab],
      this.firestoreAccessor.currentMokujiStatus.levelMokuji[this.firestoreAccessor.currentGuiStatus.currentTab]);
    for (let i = 0; i < this.virtualScrollWindowSize; i++) { //Virtualの方のもくじのリストも再読み込み
      if (this.mokujiDataList[i] != null) {
        //配列末尾に到達していないときのみ、Virtual配列に追記
        this.mokujiVirtualList.push(this.mokujiDataList[i]);
      }
    }
  }

  loadMokujiVirtualList(event) {

    setTimeout(() => {
      let alreadyReadPosition: number = this.mokujiVirtualList.length;
      for (let i = alreadyReadPosition; i < alreadyReadPosition + this.virtualScrollWindowSize; i++) {
        if (this.mokujiDataList[i] != null) {
          //配列末尾に到達していないときのみ、Virtual配列に追記
          this.mokujiVirtualList.push(this.mokujiDataList[i]);
        }
      }
      //Hide Infinite List Loader on Complete
      event.target.complete();

      //Rerender Virtual Scroll List After Adding New Data
      this.virtualScroll.checkEnd();

    }, 500);
  }

  //IPHONEで、縦幅が潰れたり、問題が飛んで表示されることがあったため使用しない
  // reloadMokuji_mokujiMondaiList(){
  //   this.mokujiVirtualList_mokujiMondaiList = [];
  //   //もくじ2の再読み込み
  //   for (let i = 0; i < this.virtualScrollWindowSize_mokujiMondaiList; i++) { //Virtualの方のもくじのリストも再読み込み
  //     if (this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab].mokujiMondaiList[i] != null) {
  //       //配列末尾に到達していないときのみ、Virtual配列に追記
  //       this.mokujiVirtualList_mokujiMondaiList.push(this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab].mokujiMondaiList[i]);
  //     }
  //   }
  // }

  // loadMokujiVirtualList_mokujiMondaiList(event) {

  //   setTimeout(() => {
  //     let alreadyReadPosition: number = this.mokujiVirtualList_mokujiMondaiList.length;
  //     for (let i = alreadyReadPosition; i < alreadyReadPosition + this.virtualScrollWindowSize_mokujiMondaiList; i++) {
  //       if (this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab].mokujiMondaiList[i] != null) {
  //         //配列末尾に到達していないときのみ、Virtual配列に追記
  //         this.mokujiVirtualList_mokujiMondaiList.push(this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab].mokujiMondaiList[i]);
  //       }
  //     }
  //     //Hide Infinite List Loader on Complete
  //     event.target.complete();

  //     //Rerender Virtual Scroll List After Adding New Data
  //     this.virtualScroll.checkEnd();

  //   }, 500);
  // }

  async openMondaiPreview(cMData: Mondai, mokujiItem: MokujiData) {
    const modal = await this.modalController.create({
      component: MondaiPreviewPage,
      componentProps: {
        mondai: cMData,
        firestoreAccessor: this.firestoreAccessor,
        nendoName: this.nendoName,
      }
    });
    modal.onDidDismiss()
      .then((res) => {
        if (res.data != null) {
          //console.log(res.data['targeKamokuId'] + res.data['targetMondaiId']);
          this.closeMokujiAndReturnSelectedMondai(res.data['targeKamokuId'], res.data['targetMondaiId'], mokujiItem)
        }
      });
    await modal.present();
  }

  closeMokujiAndReturnSelectedMondai(targeKamokuId: number, targetMondaiId: number, mokujiItem: MokujiData) {
    //this.displaySpinner(`お待ち下さい..`,1500);
    //もくじから開いた問題のプレビュー画面で、移動するボタン押下時に呼ばれるメソッド。カレントの問題ともくじをセットして、ボタン押下時のcategoryModeやlevelをcurrentGUIに保存して、トーストで変更したことを表示する
    this.displaySpinner(`お待ち下さい..`, 1500);
    this.delay(50).then(any => {
      let res = {};

      // this.firestoreAccessor.jumpToMondaiAndMokuji(targeKamokuId,targetMondaiId,categoryMode,mokujiItem);
      this.firestoreAccessor.currentGuiStatus.currentMokuji[targeKamokuId] = mokujiItem;
      if (this.firestoreAccessor.currentMokujiStatus.categoryModeMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] == 'true') {
        this.firestoreAccessor.currentGuiStatus.categoryMode[targeKamokuId] = true;
      } else {
        this.firestoreAccessor.currentGuiStatus.categoryMode[targeKamokuId] = false;
      }
      this.firestoreAccessor.currentGuiStatus.level[targeKamokuId] = Number(this.firestoreAccessor.currentMokujiStatus.levelMokuji[this.firestoreAccessor.currentGuiStatus.currentTab]);


      // this.firestoreAccessor.getOneMondaiFromCfs(targeKamokuId,targetMondaiId,this.sippituMode);
      let toastMessage = this.nendoName[mokujiItem.mokujiCategory];
      let duration = 4000;
      if (Object.keys(this.firestoreAccessor.kaitouHistory).length < 8) {
        // //初回解答前の、チュートリアル
        // toastMessage =
        //   `🔰 それでは解答してみましょう！\n💙 で選択..`;
        // duration = 8000;
      } else if (this.firestoreAccessor.currentGuiStatus.categoryMode[targeKamokuId]) {
        toastMessage =
          `『 ${mokujiItem.mokujiCategory} 』\nの問題を表示しました。頑張ってください！😃`;
      } else {
        toastMessage =
          `『 ${this.nendoName[mokujiItem.mokujiCategory]} 』\nの問題を表示しました。頑張ってください！😃`;
      }
      this.presentToastWithOptions(toastMessage, duration, 'bottom');

      // res['mode'] = 'mokuji';
      // res['targeKamokuId'] = targeKamokuId;
      // res['targetMondaiId'] = targetMondaiId;
      // res['mokujiItem'] = mokujiItem;
      //this.modalController.dismiss();

      //もくじで問題のボタンを押していた場合は、もくじや問題を書き換え
      this.setCurrentMokujiCategory(targeKamokuId, mokujiItem);
      this.jumpToMondai(targeKamokuId, targetMondaiId, 700);

    });
  }

  kamokuSelectedAtHomeTab(mondaiSyuId: string,hajimekaraBool:boolean){
    //Homeタブで科目が選択されたときの挙動
    this.mokujiTabTapped(mondaiSyuId);
    if (hajimekaraBool){
      this.mainSlides.slideTo(0);
    } else {
      //カレントの問題のSlide表示までする
      this.mainSlides.slideTo(3);
    }
  }

  mokujiTabTapped(mondaiSyuId: string) {

    //科目を移動
    if (this.firestoreAccessor.currentGuiStatus.currentTab != Number(mondaiSyuId)) {
      this.jumpToKamoku(Number(mondaiSyuId));
    }
    
    // //対象の科目の問題リストがカラなら、埋めて初期化する
    // if (this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab].length == 0){
    //   this.firestoreAccessor.setMondaiList();
    // }

    //console.log("BBB__this.firestoreAccessor.currentGuiStatus.currentMondai___"+JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentMondai)+"___"+mondaiSyuId);
    // //カレントの問題がカラなら、問題リストの先頭の問題をカレントにセット。
    // if (this.firestoreAccessor.currentGuiStatus.currentMondai[Number(mondaiSyuId)] == null){
    //   console.log("this.firestoreAccessor.currentGuiStatus.currentMondaiList[Number(mondaiSyuId)][0]_"+JSON.stringify(this.firestoreAccessor.currentGuiStatus.currentMondaiList[Number(mondaiSyuId)][0])+"___"+mondaiSyuId);
    //   let targetMondaiId:number = this.firestoreAccessor.currentGuiStatus.currentMondaiList[Number(mondaiSyuId)][0].mondaiId;
    //   this.firestoreAccessor.currentGuiStatus.currentMondai[Number(mondaiSyuId)] = this.firestoreAccessor.getOneMondaiFromFile(targetMondaiId);
    // }

    //this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);

    this.firestoreAccessor.writeOperationLog(this.firestoreAccessor.currentGuiStatus.currentTab , this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId ,'mokujiTabTapped()', false );
    // //数秒間、科目名を表示
    // setTimeout(() => {
    //   this.mokujiTitleDisplayFlag = true;
    //   setTimeout(() => {
    //     this.mokujiTitleDisplayFlag = false;

    //   }, 6000)
    // }, 100)

  }

  
  nijiMondaiIdou(targetMondai: Mondai){
    //GUI下の出題分布で、二次のものがクリックされた場合の問題の移動
    
    setTimeout(() => {
      document.querySelector('ion-content').scrollToTop(500);
      //カレントのタブ変える
      this.mokujiTabTapped("" + targetMondai.mondaiSyuId);
      setTimeout(() => {
        //カレントの問題変える
        this.firestoreAccessor.setMondaiList(targetMondai.mondaiId);
      }, 0);
    }, 0);
  }

  breadListTapped(slideNum: number, visibleState: boolean) {
    if (visibleState) {
      //非表示状態のBreadクリック時は何もしないための分岐
      this.mainSlides.slideTo(slideNum);
    }
  }

  updateSlides() {
    this.delay(1000).then(any => {
      this.mainSlides.update();
    });
  }

  slideNext() {
    this.mainSlides.slideNext();
    this.slideNumUpdate();
  }
  slidePrev() {
    this.mainSlides.slidePrev();
    this.slideNumUpdate();
    this.firestoreAccessor.writeOperationLog(this.firestoreAccessor.currentGuiStatus.currentTab , this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId ,'slidePrev()', false );
  }

  async slideDidChange() {
    this.slideNumUpdate();
  }

  async slideNumUpdate() {
    //現在のスライド位置に取得し変数に格納する
    await this.slides.getActiveIndex().then(slideNum => {
      this.firestoreAccessor.currentGuiStatus.currentMainSlidePosition[this.firestoreAccessor.currentGuiStatus.currentTab] = slideNum;
      this.checkSlideNumThenDoSomeAction(slideNum);

      this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);

    })
  }


  checkSlideNumThenDoSomeAction(slideNum: number) {
    //ここ通る時点でスライド表示しているので一旦値を初期化する

    if (slideNum == 1) {
      //Slideがもくじのスライド番号の場合、もくじ再読込する
      //
      // this.firestoreAccessor.currentMokujiStatus = {
      //   mondaiSyuIdMokuji: String(this.firestoreAccessor.currentGuiStatus.currentTab),
      //   categoryModeMokuji: "" + this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab],
      //   levelMokuji: '2'
      // };
      this.reloadMokuji();
      // } else if (slideNum == 2) {
      //   //Slideがもくじ2のスライド番号の場合、もくじ再読込する
      //   this.reloadMokuji_mokujiMondaiList();
    } else if (slideNum == 3) {
      //解くスライドだった場合は、スライド表示するクラス名に変更
      this.tokuSlideClass = 'tokuSlideStart';
    }
  }

  categorySlideSelected(targetCategoryModeMokuji: string) {
    // if (targetCategoryModeMokuji == 'true'){
    //   this.firestoreAccessor.currentMokujiStatus.categoryModeMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] = true;
    // } else if (targetCategoryModeMokuji == 'false'){
    //   this.firestoreAccessor.currentMokujiStatus.categoryModeMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] = false;
    // } else if (targetCategoryModeMokuji == 'onlyFuseikai'){
    //   //不正解だけのフラグたてるところから再開★
    //   console.log('onlyFuseikai');
    // }
    this.firestoreAccessor.currentMokujiStatus.categoryModeMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] = targetCategoryModeMokuji;
    this.slideNext();
  }


  mokujiSlideSelected(targetMokujiItem: MokujiData) {
    //this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] = targetMokujiItem;
    this.setCurrentMokujiCategory(targetMokujiItem.mondaiSyuId, targetMokujiItem);
    //console.log('targetMokujiItem_'+JSON.stringify(targetMokujiItem))
    this.slideNext();
  }


  mondaiSlideSelected(targeKamokuId: number, targetMondaiId: number) {
    if (this.firestoreAccessor.currentGuiStatus.currentMondaiList[targeKamokuId][0] != targetMondaiId) {
      //関連問題を削除するために、一旦初期化する
      this.firestoreAccessor.currentGuiStatus.currentMondaiList[targeKamokuId] = [];

      //カレントの問題から変更ある場合だけ、新たに問題を読み込む
      this.jumpToMondai(targeKamokuId, targetMondaiId);

      let targetMondaiIdList:number[] = []
      let cMData:Mondai = this.firestoreAccessor.getOneMondaiFromFile(targetMondaiId);
      if (cMData.niji == true){
        //2次の問題の場合は、リスト取得
        targetMondaiIdList = cMData.daimonList;
      } else {
        //1次の問題の場合は、1問だけ
        targetMondaiIdList = [targetMondaiId]
      }

      for (let i_tmid in targetMondaiIdList){
        //
        this.firestoreAccessor.currentGuiStatus.currentMondaiList[targeKamokuId][Number(i_tmid)] = this.firestoreAccessor.getOneMondaiFromFile(targetMondaiIdList[i_tmid]);
      }

      //this.firestoreAccessor.currentGuiStatus.currentMondaiList[targeKamokuId][0] = this.firestoreAccessor.getOneMondaiFromFile(targetMondaiId);

      //単元別かどうかを切り替える
      this.firestoreAccessor.currentGuiStatus.categoryMode[targeKamokuId] = this.firestoreAccessor.currentMokujiStatus.categoryModeMokuji[targeKamokuId];
    }
    setTimeout(() => {
      //console.log('hello')
      //this.slideNext();
      setTimeout(() => {
        this.checkKaitouZumiMondai(targetMondaiId);//解答済みの問題であれば、解き直すかのダイアログ表示
      }, 0)
    }, 0)

    this.firestoreAccessor.writeOperationLog(targeKamokuId , targetMondaiId ,'mondaiSlideSelected', false );

  }

  breadArrayGenerator(): Array<number> {

    return Array(this.firestoreAccessor.currentGuiStatus.currentMainSlidePosition[this.firestoreAccessor.currentGuiStatus.currentTab] + 1);
  }

  tokutabuSlidePrev() {
    //とくスライドの戻るときだけ表示させない
    this.tokuSlideVisible = false;
    this.slidePrev();
    this.delay(1000).then(any => {
      this.tokuSlideVisible = true;
    });
    // setTimeout(() => {
    //   //console.log('hello')
    //   setTimeout(() => {
    //     //console.log('callback')
    //     setTimeout(() => {
    //       this.tokuSlideBackTransition = false;
    //     }, 0)
    //   }, 0)
    // }, 0)
  }
  // slideTransitionStart(){
  //   this.slideTransition = true;
  // }

  // slideTransitionEnd(){
  //   this.slideTransition = false;
  // }

  end_tutorial_mode() {
    //this.slidePrev();
    this.set_tutorial_mode(false);
  }

  getTutorialFooterMessage() {
    //Tutorialの場合に、下部に表示するメッセージを返す
    let footerMessage = {};
    const tmpSlideNum = this.firestoreAccessor.currentGuiStatus.currentMainSlidePosition[this.firestoreAccessor.currentGuiStatus.currentTab];

    if (tmpSlideNum == 0) {
      footerMessage = {
        'message_main': '出題順を選択してください',
        'message_sub': '迷ったら年度順がおすすめ'
      }
    } else if (tmpSlideNum == 1) {
      if (this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab]) {
        footerMessage = {
          'message_main': '単元を選択してください',
          'message_sub': '迷ったら一番上の単元がおすすめ'
        }
      } else {
        if (this.firestoreAccessor.currentGuiStatus.categoryMode[this.firestoreAccessor.currentGuiStatus.currentTab]){
          footerMessage = {
            'message_main': '出題単元を選択してください',
            'message_sub': '迷ったら一番上の単元がおすすめ'
          }
        } else {
          footerMessage = {
            'message_main': '出題年度を選択してください',
            'message_sub': '迷ったら令和２年度がおすすめ'
          }
        }
      }
    } else if (tmpSlideNum == 2) {
      footerMessage = {
        'message_main': '問題を選択してください',
        'message_sub': '迷ったら一番上の問題がおすすめ'
      }
    } else if (tmpSlideNum == 3) {
      if (this.firestoreAccessor.currentGuiStatus.currentKaitou[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId].saitenKekka == null) {
        footerMessage = {
          'message_main': '<ion-text color="primary"><i class="fas fa-heart"></i></ion-text>を押して解答してみましょう',
          'message_sub': '<ion-text color="primary"><i class="fas fa-trash-alt"></i></ion-text>を押して消去法で解答することもできます'
        }
      } else if (this.firestoreAccessor.tutorial_state == 'firstFlipOpened') {
        footerMessage = {
          'message_main': '',
          'message_sub': ''
        }
      } else {
        footerMessage = {
          'message_main': "<label style='background: #eeee66;'>&nbsp;<i class='fas fa-question' style='color:#000000;width:10%'></i>&nbsp;</label>をクリックすると解説を表示します",
          'message_sub': ''
        }
      }
    }


    return footerMessage;
  }

  showChart(chartName: string) {
    this.visibleChartName = chartName;
    this.chartSize = [
      this.platform.width() / 1.3,
      this.platform.height() / 2
    ];

    console.log('chartName= '+chartName);
    if (chartName == 'kaitou') {
      //this.firestoreAccessor.reloadAshiatoData();
      //console.log('dateData= '+JSON.stringify(this.firestoreAccessor.dateData));

    }
    if (chartName == 'timer') {
      //console.log('rirekiDataSource= '+JSON.stringify(this.rirekiDataSource));
    }
  }

  ashiatoRangeChanged() {
    if (!this.ashiatoRangeVisible) {
      this.ashiatoRangeVisible = true;
    }
    if (this.firestoreAccessor.currentGuiStatus.dateRangeDual.lower == this.firestoreAccessor.currentGuiStatus.dateRangeDual.upper) {
      //Rangeの最小と最大が同じ値になるとエラーになるので補正する
      this.firestoreAccessor.currentGuiStatus.dateRangeDual.lower = this.firestoreAccessor.currentGuiStatus.dateRangeDual.upper - 3;
    }
    this.firestoreAccessor.reloadAshiatoData();

  }

  hintOpened(targetMondaiId: string) {
    //console.log("hintttt" + targetMondaiId);
    //もし選択肢選択前にフリップ開いた場合はフラグ建てる処理。
    if (!this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].sentaku) {
      this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].flipOpenedBeforeSelect = true;
    }
  }

  changeHintFooterVisible() {
    //
    //console.log("this.firestoreAccessor.currentGuiStatus.hintFooterVisible_" + this.firestoreAccessor.currentGuiStatus.hintFooterVisible);
    this.firestoreAccessor.currentGuiStatus.hintFooterVisible = !this.firestoreAccessor.currentGuiStatus.hintFooterVisible
  }

  displayKanrenMondai(targetMondaiId) {
    // //
    // // console.log("targetMondaiId_"+targetMondaiId);
    // // this.firestoreAccessor.currentGuiStatus.currentMokuji[this.firestoreAccessor.currentGuiStatus.currentTab].mokujiMondaiList.push(targetMondaiId);
    // let cMData:Mondai = this.firestoreAccessor.currentGuiStatus.currentMondai[ this.firestoreAccessor.currentGuiStatus.currentTab];

    // let ruijiMondaiIdList:number[] = [];

    // let ruijiMondaiArrayPositionEndNum = 1;//類似問題表示するためのスクロール位置。デフォルト1番目

    // if (cMData.niji == true){
    //   //2次問題なら、末尾の問題の大問Listにカレントの問題含まれていなければ、大問Listの下図だけMondaiListの、後ろ側の問題削除　＃ふくまれていたら何もしない
    //   ruijiMondaiIdList = this.firestoreAccessor.getOneMondaiFromFile(targetMondaiId).daimonList;
    //   ruijiMondaiArrayPositionEndNum = cMData.daimonList.length;
    // } else {
    //   //1次問題なら、
    //   ruijiMondaiIdList = [targetMondaiId];
    //   ruijiMondaiArrayPositionEndNum = 1;
    // }

    // //関連問題の場所(カレントの問題の末尾)に、関連問題を格納する
    // for (let i_rmil in ruijiMondaiIdList){
    //   this.firestoreAccessor.currentGuiStatus.currentMondaiList[this.firestoreAccessor.currentGuiStatus.currentTab][ruijiMondaiArrayPositionEndNum + Number(i_rmil)] = this.firestoreAccessor.getOneMondaiFromFile(ruijiMondaiIdList[i_rmil]);
    //   //解答履歴を読み出す＃ない場合は、履歴をつくる
    //   this.firestoreAccessor.loadLastKaitouHistory(this.firestoreAccessor.currentGuiStatus.currentTab, ruijiMondaiIdList[i_rmil]);
    // }

    // //関連問題の位置までスクロールする
    // this.scrollToGuiPosition(ruijiMondaiArrayPositionEndNum, 50);
  }

  async reloadMondaiMatomete(targetMondai: Mondai) {
    //まとめてカレントの解答をリロードする
    //カレントの問題の、科目と年度が一致する解答履歴のリストを取得する

    const toast = await this.toastController.create({
      header: this.kamokuName[this.firestoreAccessor.currentGuiStatus.currentTab]+this.nendoName[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].nendo]+' をもう一度ときますか？',
      message: '現在のあなたの得点（'+this.firestoreAccessor.currentGuiStatus.currentTokuten[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiSyuId]["tokuten"]+'点）をリセットします',
      position: 'middle',
      buttons: [
        {
          side: 'start',
          icon: 'refresh-outline',
          text: 'はい',
          handler: () => {
            let tmpKaitouRireki:Kaitou[] = this.firestoreAccessor.searchKaitouRireki(targetMondai);
            //let tmpMondai: Mondai[] = this.firestoreAccessor.getMondai_NendoArr(targetMondai.nendo);

            for (var iRireki in tmpKaitouRireki) {
              //tmpMondaiNumArray.push(tmpMondai[mondaiForArray].mondaiId);
              this.reloadCurrentKaitou(this.firestoreAccessor.getOneMondaiFromFile(tmpKaitouRireki[iRireki].mondaiId));
            }
            //カレントの得点情報も初期化する
            this.firestoreAccessor.currentGuiStatus.currentTokuten[this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiSyuId] = {};
          }
        }, {
          text: 'キャンセル',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }


  reloadCurrentKaitou(targetMondai: Mondai, arrayPosition?: number) {
    //解答の変数を初期化
    setTimeout(() => {
      //console.log('hello')
      //this.scrollToGuiPosition(arrayPosition, 50);
      setTimeout(() => {
        //console.log('callback')
        this.firestoreAccessor.loadLastKaitouHistory(targetMondai.mondaiSyuId, targetMondai.mondaiId, true);
      }, 0)
    }, 0)
  }


  // getKanrenMondai(tangoId:string){
  //   //
  //   let targeMokujiData:MokujiData = this.firestoreAccessor.getMokujiDataList(
  //     this.firestoreAccessor.currentGuiStatus.currentTab,
  //     true, 2, tangoId )[0];

  //   return targeMokujiData;
  // }

  noKaisetuVisibleChanged() {
    //解説なしを表示する設定変更時の処理。LevelにNgModelの値を反映する
    if (this.firestoreAccessor.currentGuiStatus.noKaisetuVisible) {
      this.firestoreAccessor.currentMokujiStatus.levelMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] = 2;
    } else if (!this.firestoreAccessor.currentGuiStatus.noKaisetuVisible) {
      this.firestoreAccessor.currentMokujiStatus.levelMokuji[this.firestoreAccessor.currentGuiStatus.currentTab] = 1;
    };

    this.reloadMokuji();
  }

  changeSummaryMetricsVisible() {
    //homeの数値の表示切り替え
    this.summaryMetricsVisible = !this.summaryMetricsVisible;
  }


  refreshKaitouCountToday() {
    //日付が変わっていれば、回答回数のカウントを初期化する
    let tmpDate_last = new Date(this.firestoreAccessor.currentGuiStatus.kaitouCountDate);

    let tmpDate_today = new Date(this.firestoreAccessor.todayDate);

    //時刻補正を戻す
    //tmpDate_last.setTime(tmpDate_last.getTime());
    tmpDate_today.setTime(tmpDate_today.getTime() - 1000 * 60 * 60 * 9);

    if (
      tmpDate_last.getFullYear() != tmpDate_today.getFullYear() ||
      tmpDate_last.getMonth() != tmpDate_today.getMonth() ||
      tmpDate_last.getDate() != tmpDate_today.getDate()
    ) {
      //回答回数の対象日付が、今日でない場合は、解答数の変数を初期化
      this.firestoreAccessor.currentGuiStatus.kaitouCountToday = {
        0: 0,
        101: 0,
        102: 0,
        103: 0,
        104: 0,
        105: 0,
        106: 0,
        107: 0,
        201: 0,
        202: 0,
        203: 0,
        204: 0,
      };
      //回答回数の対象日付の変数を新しい日付で更新
      this.firestoreAccessor.currentGuiStatus.kaitouCountDate = tmpDate_today;
    }
  }

  openKansou(){
    setTimeout(() => {
      this.homeAccordionOpen('kansou');
      setTimeout(() => {
        if (this.innerState == 'kansou'){
          //感想を開く場合は、スクロールする
          document.querySelector('ion-content').scrollToBottom(500);
        }
      }, 300)
    }, 0)
  }

  copyUrlToClipboard(cMData: Mondai){
    //URLを生成する
    //Clipboardに文字列をコピー
    let sharingUrl:string = this.firestoreAccessor.getSharingUrl(cMData.mondaiId);
    this.copyTextToClipboard(sharingUrl);
    this.presentToastWithOptions('📋 この問題のURLをコピーしました　　（'+sharingUrl+'）', 3000, 'middle');
    //this.clipboard.copy('Alphonso');

    this.firestoreAccessor.writeOperationLog(cMData.mondaiSyuId , cMData.mondaiId ,'copyUrlToClipboard()', false );
  }

  addToBookbark(){
    //
    console.log('addToBookbark')
  }

  copyTextToClipboard(textVal:string){
    // テキストエリアを用意する
    var copyFrom = document.createElement("textarea");
    // テキストエリアへ値をセット
    copyFrom.textContent = textVal;

    // bodyタグの要素を取得
    var bodyElm = document.getElementsByTagName("body")[0];
    // 子要素にテキストエリアを配置
    bodyElm.appendChild(copyFrom);

    // テキストエリアの値を選択
    copyFrom.select();
    // コピーコマンド発行
    var retVal = document.execCommand('copy');
    // 追加テキストエリアを削除
    bodyElm.removeChild(copyFrom);
    // 処理結果を返却
    return retVal;
  }

  countWordLength(cMData:Mondai){
    //this.setCurrentMondai(cMData.mondaiSyuId, cMData.mondaiId);
    this.firestoreAccessor.currentGuiStatus.kaitoubun2jiChangedFlag = true;  //解答更新のチェックフラグ更新

    //２次問題の回答文字数をカウントする
    let targetWord : string = this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku;
    const re = /[0-9]{2,}/g

    //半角数字連続を検索して、配列に格納
    let matchedArray :string[];
    if (targetWord != null && targetWord == ''){
      matchedArray = (targetWord).match(re);
    }
    let numberCompressCount:number = 0;//１マス２数字の数
    if (matchedArray != null){
      //配列の半角の塊ごとに、減らす文字数をカウント例：456は1
      for (let i_ma in matchedArray){
        //半角数字２連続の場合のみ１文字減らすようにカウント
        numberCompressCount = numberCompressCount + Math.floor(matchedArray[i_ma].length/2);
      }
    }

    if(this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku){
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].kaitouLength = this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku.length - numberCompressCount;
    }else{
      this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].kaitouLength = 0
    }

    if (this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].saitenKekka != null){
      //採点後の文字修正の場合、そのたびに採点し直す
      this.nijiSaiten(cMData,this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku,'sentaku');
      // console.log("countWordLength and select run")
      // this.selectSentakushi(cMData,this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku,'sentaku', true);
    }



    //最終的な数字に応じて、トリム。先頭から設問の指定文字数まで。
    //this.firestoreAccessor.currentGuiStacurrentKaitou[cMData.mondaiId].sentaku = this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku.slice(0 , (cMData.maxlength + numberCompressCount - this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].sentaku) );

  }

  nijiTextAreaFocused(cMData:Mondai){
    this.innerState = ""+cMData.mondaiId;
    this.setCurrentMondai(cMData.mondaiSyuId, cMData.mondaiId);
    if(this.firestoreAccessor.currentGuiStatus.scatterValue[cMData.mondaiId]){
      //すでに前回のScatterがあれば、TextAreaにFocus時点で図を描画
      //this.plotScatter(cMData);
      //this.plotTouanSuuBarChart(cMData);
    }

  }

  nijiTextAreaBlured(cMData:Mondai){

    //DBに一旦解答保存。AI最店内問題でも着手履歴を残して表示に使うため
    let todayDate = new Date();
    todayDate.setTime(todayDate.getTime() + 1000 * 60 * 60 * 9);// JSTに変換
    this.firestoreAccessor.currentGuiStatus.currentKaitou[cMData.mondaiId].dateTime = todayDate;
    this.persist_updated_kaitou(cMData,"nijiTextAreaBlured",false);

  }

  kaihouSelected(event,targetMmondaiSyuId:number,targetMondaiId:number){
    this.innerState = ""+targetMondaiId;

    // this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].kaihouVisibleStatus = event.detail.value;
    // //this.setCurrentMondai(targetMmondaiSyuId, targetMondaiId);

    setTimeout(() => {
      //console.log('hello')
      this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].kaihouVisibleStatus = event.detail.value;
      setTimeout(() => {
        //console.log('callback')
        this.innerState = ""+targetMondaiId;
        this.setCurrentMondai(targetMmondaiSyuId, targetMondaiId);
      }, 0)
    }, 0)


  }

  nendoSelected(event){
    //カレントの問題をw該当科目、年度の最初の問題にする
    let targetNendo:number = event.detail.value;
    let targetMondaiId:number = this.firestoreAccessor.getMondai_NendoArr(targetNendo)[0].mondaiId;
    //console.log("etargetMondaiId_"+targetMondaiId);
    this.firestoreAccessor.setMondaiList(targetMondaiId) ;
    this.firestoreAccessor.tokutenSyuukei(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab]);//得点をカレントの問題ベースで再計算する

    //this.firestoreAccessor.currentGuiStatus.currentNendoRange[this.firestoreAccessor.currentGuiStatus.currentTab] = "";

  }



  consolePrint(){
    console.log("this.firestoreAccessor.currentGuiStatus"+JSON.stringify(this.firestoreAccessor.currentGuiStatus.gouhi_jouhou));
  }

  // gouhiSelected(){
  //   let targetGouhiJouhou = this.firestoreAccessor.currentGuiStatus.gouhi_jouhou[this.getMondaiIdSentou5(this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiId)];
  //   let targetGouhiView = targetGouhiJouhou.gouhiView;
  //   if ( targetGouhiView == ""){
  //     //合否の解答を入力してなければ、何もしない
  //   } else if (targetGouhiView == "合格" || targetGouhiView == "A"|| targetGouhiView == "B"|| targetGouhiView == "C"|| targetGouhiView == "D"){
  //     //合格ABCDなら、gouhiをそのまま格納し、tokutenは引いた値を入れる
  //     targetGouhiJouhou.gouhi = targetGouhiView;
  //     targetGouhiJouhou.tokuten = this.firestoreAccessor.tokutenTable[targetGouhiView];
      
  //     targetGouhiJouhou.visible = "gouhiKaitouZumi";
  //     //RESTに値をPOSTする
  //     this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
  //     //開示方法のモーダル表示する
  //   } else if (targetGouhiView == "受験していない"){
  //     //受験していないなら、gouhiViewそのまま格納し、値をデフォルト値に戻す
  //     targetGouhiJouhou.gouhi = targetGouhiView;
  //     targetGouhiJouhou.visible = "student";
  //     targetGouhiJouhou.tokuten = null;
  //     //RESTに値をPOSTする
  //     this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
  //   } else if ( -1 < targetGouhiView && targetGouhiView < 101){
  //     //数字なら、tokutenをそのまま格納し、gouhiに引いた文字列を入れる
  //     targetGouhiJouhou.tokuten = targetGouhiView
  //     if ( targetGouhiView < 40){
  //       targetGouhiJouhou.gouhi = "D";
  //     } else if (
  //       40 <= targetGouhiView && targetGouhiView < 50
  //       ){
  //         targetGouhiJouhou.gouhi = "C";
  //     } else if (
  //       50 <= targetGouhiView && targetGouhiView < 60
  //       ){
  //         targetGouhiJouhou.gouhi = "B";
  //     } else if (
  //       60 <= targetGouhiView
  //       ){
  //         targetGouhiJouhou.gouhi = "A";
  //     } 
      
  //     targetGouhiJouhou.visible = "gouhiKaitouZumi";
  //     //RESTに値をPOSTする
  //     this.todoService.create_gouhi_jouhou(targetGouhiJouhou);
  //   }
    
  //   this.firestoreAccessor.backUpObjectToDB('currentGuiStatus', this.firestoreAccessor.currentGuiStatus);
    
  //   //console.log("this.firestoreAccessor.currentGuiStatus"+JSON.stringify(this.firestoreAccessor.currentGuiStatus));//("hi-------------")//
  // }

  rontenUmuCheck(targetMondai:Mondai, targetRontenStr:string):boolean{
    let returnBool : boolean = false;
    //console.log("targetMondai['sentakushi']--"+targetMondai["sentakushi"])
    if (targetMondai["rontenKaisetu"]){
      //console.log("targetRontenStr-t11-"+targetRontenStr)
      if (targetMondai.rontenKaisetu[targetRontenStr]){
        //console.log("targetRontenStr-t22-"+targetRontenStr)
        returnBool = true;
      }
    }
    return returnBool;

  }

  barChartAccordionOpen(targetMondai:Mondai){
    //書いている解答の棒グラフを表示するアコーディオンを開いて、グラフ描画する
    this.displaySpinner(``, 500);//Spinner表示
    if (this.firestoreAccessor.barChartAccordionOpenTargetMondaiId == ""+targetMondai.mondaiId){
      //２回めクリック時は、アコーディオン閉じる
      this.firestoreAccessor.barChartAccordionOpenTargetMondaiId = "default";
    } else {
      //書いている判定の、Goodとかの表示設定作る
      //this.generateGoodBadHyoujiTable(targetMondai);
      
      //アコーディオン開く
      this.firestoreAccessor.barChartAccordionOpenTargetMondaiId = ""+targetMondai.mondaiId;
      
      setTimeout(() => {
        //console.log('hello')
        this.innerState = "" + targetMondai.mondaiId;
        this.setCurrentMondai(targetMondai.mondaiSyuId, targetMondai.mondaiId);
        //棒グラフデータ作ってから、グラフ描画。念の為順序性つける
        this.firestoreAccessor.createTouanSuuBarChartData(targetMondai);
        this.firestoreAccessor.createGoodBadData(targetMondai);
        setTimeout(() => {
          this.delay(500).then(any => {
            this.plotTouanSuuBarChart(targetMondai);
            //console.log('callback')
          });
        }, 200)
      }, 200)

    }

  }

  scatterChartAccordionOpen(targetMondai:Mondai){
    //散布図アコーディオンの制御
    if (this.scatterChartAccordionOpenTargetMondaiId == ""+targetMondai.mondaiId){
      //２回めクリック時は、アコーディオン閉じる
      this.scatterChartAccordionOpenTargetMondaiId = "default";
    } else {
      //アコーディオン開く
      this.scatterChartAccordionOpenTargetMondaiId = ""+targetMondai.mondaiId;
      this.nijiTextAreaFocused(targetMondai);
      if(this.firestoreAccessor.currentGuiStatus.scatterValue[targetMondai.mondaiId]){
        // //散布図描画
        // this.plotScatter(targetMondai);
      }
    }
  }

  firikaeriAccordionOpen(targetRontenStr:string){
    //散布図アコーディオンの制御
    if (this.furikaeriAccordionOpenTargetStr == ""+targetRontenStr){
      //２回めクリック時は、アコーディオン閉じる
      this.furikaeriAccordionOpenTargetStr = "default";
    } else {
      //アコーディオン開く
      this.furikaeriAccordionOpenTargetStr = ""+targetRontenStr;
    }
  }

  generateGoodBadHyoujiTable(targetMondai:Mondai){
    //Goodとかの表示設定作る
    //激、泥臭く、名前のリストをつくる
    let arr = [];
    let wariaiArr = [];
    for (let keyStr in this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[targetMondai.mondaiId]){
      //

      // console.log("i_gts- "+i_gts)
      // console.log("JSON- "+JSON.stringify(this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[i_gts]))
      let seikaiPlusFuseikai:number = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[targetMondai.mondaiId][keyStr][0].x[0] + this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[targetMondai.mondaiId][keyStr][1].x[0];

      let tmpAW = 0;

      if (seikaiPlusFuseikai != 0 ){
        tmpAW = this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[targetMondai.mondaiId][keyStr][0].x[0] / seikaiPlusFuseikai
      };

      let dicToPush = {
        key: keyStr,
        value: seikaiPlusFuseikai,
        wariai: tmpAW,
      };

      arr.push(dicToPush);
    }

    //合＋不合格の割合を　ソートした、配列を取得
    arr.sort(function(a,b){
      if(a.value < b.value) return 1;
      if(a.value > b.value) return -1;
      return 0;
    });
    this.firestoreAccessor.currentGuiStatus.rontenSorted_goukei = JSON.parse(JSON.stringify(arr));

    //合／不合格の割合を　ソートした、配列を取得
    arr.sort(function(a,b){
      if(a.wariai < b.wariai) return 1;
      if(a.wariai > b.wariai) return -1;
      return 0;
    });
    this.firestoreAccessor.currentGuiStatus.rontenSorted_wariai = JSON.parse(JSON.stringify(arr));

    //console.log(arr);

    /////
    //合格ランキング
    //書いてるランキング
    //書いてるランキングから順に、表示用の配列に詰め込む
      //合格ランキングが、前半 && 書いてるランキングも前半　なら、GoodBad　それ以外はFineHmm

      // this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData.sort(function(a,b){
      //   console.log("あ" +a.value.value[0].x[0] +"い" + a.value.value[1].x[0] +"う" + b.value.value[0].x[0] +"え" + b.value.value[1].x[0])
      //   if(a.value.value[0].x[0] < b.value.value[0].x[0]) return 1;
      //   if(a.value.value[0].x[0] > b.value.value[0].x[0]) return -1;
      //   return 0;
      // });


    //Dic to Arr
    // let arr = Object.keys(this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData).map((e)=>({
    //   key: e,
    //   // sortKey: this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[e].x[0],
    //   value: this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[e]
    // }));

    // arr.sort(function (a, b) {
    //   console.log("arr- " + JSON.stringify(a.value.value))
    //   let valueA = JSON.parse(JSON.stringify(a.value));
    //   console.log("valueA- " + a.value.value[0].x)



    //   return 0;
    // });


    // arr.sort(function(a,b){
    //   console.log("あ" +a.value.value[0].x[0] +"い" + a.value.value[1].x[0] +"う" + b.value.value[0].x[0] +"え" + b.value.value[1].x[0])
    //   if(a.value.value[0].x[0] < b.value.value[0].x[0]) return 1;
    //   if(a.value.value[0].x[0] > b.value.value[0].x[0]) return -1;
    //   return 0;
    // });

    // //比率でソート
    // arr.sort(function(a,b){
    //   console.log("あ" +a.value.value[0].x[0] +"い" + a.value.value[1].x[0] +"う" + b.value.value[0].x[0] +"え" + b.value.value[1].x[0])
    //   if(a.value.value[0].x[0] + a.value.value[1].x[0] < b.value.value[0].x[0] + b.value.value[1].x[0]) return 1;
    //   if(a.value.value[0].x[0] + a.value.value[1].x[0] > b.value.value[0].x[0] + b.value.value[1].x[0]) return -1;

    // });
    //console.log("arr- "+JSON.stringify(arr))
    //this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData = arr;


    // //合格と不合格の合計
    // this.firestoreAccessor.currentGuiStatus.gaitouTouanSuuData[saitenKijunItem] = [
    //   {
    //     x: [countGoukaku/countAll * 100],//[20],
    //   }
    // ]
  }

  roundedPercent(targetNum:number){
    //小数をパーセントの整数にして返す
    return Math.round(targetNum * 100)
  }

  async translate() {
    
    let targetStr = "図は、政府の債務残高（対GDP比）の<font size='1' color='0000ff'>国際比較</font>である。  図中のａ～ｃに該当する国の組み合わせとして、<span>最も適切なもの</span>を下記の解答群から選べ。<hint hidden='true'>**冒頭問題の定番、統計データから該当する国・地域を問う問題です。  このタイプの問題で共通する解法は  「マクロな経済状況やイベント（リーマンショック等）の知識から、各国の差異を推定する」  です。  　経済学は2日間の試験の最初なので、パターンを覚えて落ち着いて受験をスタートできます。  画面下部の類似問題から2,3年分を解法を意識してトレーニングすることをおすすめします。　**</hint>  <hint hidden='true'>**選択肢の３カ国のうち、政府の債務残高が非常に高い国は、日本です。**  </hint><imgTag>101010010_1.png</imgTag>  <hint hidden='true'>**政府の債務残高が２００８年以降に悪化した国は、<hintTarget>アメリカです。リーマンショックの２００８年以降（リーマン・ブラザースは２００８年９月に経営破綻）、アメリカ政府は、リーマン・ブラザースをはじめとしたアメリカ国内の銀行に大規模な資本注入等を行ったため、債務残高が悪化しました。</hintTarget>**  </hint>出所：財務省『日本の財政関係資料』（平成30年10月）  〔解答群〕  ア　ａ：アメリカ　ｂ：イタリア　ｃ：日本  イ　ａ：イタリア　ｂ：日本　ｃ：アメリカ  ウ　ａ：日本　ｂ：アメリカ　ｃ：イタリア  エ　ａ：日本　ｂ：イタリア　ｃ：アメリカ<hint hidden='true'>"//this.firestoreAccessor.currentGuiStatus.currentMondai[this.firestoreAccessor.currentGuiStatus.currentTab].mondaiBun;//
    //console.log("targetStr---"+targetStr)
    this.translation.getTranslated(targetStr).subscribe(async (result) => {
      console.log("result---"+result)
      let restResultString = JSON.stringify(result);
      console.log(restResultString)

    }, err => {
      console.log("translationで異常発生");
      console.log(err);
    });

  }

  
  async takePicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      // width: 720,
      // height: 1280,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      promptLabelHeader: '過去問を撮影してください',
    });
    this.myImage = image.webPath;

    //png画像をBase64文字列に変換する。
    this.myImageBase64Data = await this.base64FromPath(image.webPath);

    // 変換後のBase64文字列に含まれる画像として不要な文字列を除去する。
    this.myImageBase64Data = this.myImageBase64Data.replace('data:image/png;base64,', '');
    this.myImageBase64Data = this.myImageBase64Data.replace('data:image/jpeg;base64,', '');
  }

  async base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('method did not return a string');
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  // async searchObject() {
  //   const imageData = this.myImageBase64Data;
  //   this.vision.getLabels(imageData, 'TEXT_DETECTION').subscribe(async (result) => {
  //     // JavaScriptではオブジェクトのコピーをした時に参照をシャローコピーするため、
  //     // コピーに対して変更を加えた場合、元のAPIの結果に対しても意図しない変更を加えてしまう可能性がある。
  //     // それを避けるために、一旦文字列に変換してから再度JSONに変換することでディープコピーする。
  //     this.result = JSON.parse(JSON.stringify(result.json()));
  //     console.log('帰ってきた値＝'+JSON.stringify(result.json()));

  //     this.result = this.result.responses[0].fullTextAnnotation.text;
  //     this.result = this.result.replace('\\n', '');
  //   }, err => {
  //     console.log('getLabelsで異常発生');
  //     console.log(err);
  //   });
  // }

  openBase64ImgWindow(b64data: string){
    // console.log("AAAAAAAAAAAAAA")
    // window.open("assets/img/dendrogramDummy.png", "imgwindow", "width=866,height=580");
    var image = new Image();
        image.src = b64data;//"data:image/jpg;base64," + b64data;

        var w = window.open("");
        w.document.write(image.outerHTML);
  }
  
  
  async recordSound(targetMondaiId:number) {
    // Create a stream of video capturing
    const stream = await navigator.mediaDevices.getUserMedia({
      video: false, //{ facingMode: 'user'},
      audio: true,
    });

    const options = { mimeType: "audio/webm" }; //'video/webm'};
    this.mediaRecorder = new MediaRecorder(stream, options);
    const chunks = [];

    // Store the video on stop
    this.mediaRecorder.onstop = async (event) => {
      this.displaySpinner(`音声を認識中 ..`, 1000);
      const videoBuffer = new Blob(chunks, { type: "audio/webm" }); // 'video/webm' });
      this.b64sound = (await this.videoService.convertBlobToBase64(
        videoBuffer
      )) as string;
      // 変換後のBase64文字列に含まれる画像として不要な文字列を除去する。
      this.b64sound = this.b64sound.replace("data:audio/webm;base64,", "");
      this.videoService.getSpeechToText(this.b64sound,this.videoService.targetPerson.sourceLanguage).subscribe(
        async (result) => {
          // JavaScriptではオブジェクトのコピーをした時に参照をシャローコピーするため、
          // コピーに対して変更を加えた場合、元のAPIの結果に対しても意図しない変更を加えてしまう可能性がある。
          // それを避けるために、一旦文字列に変換してから再度JSONに変換することでディープコピーする。
          //this.speechToTextString = JSON.parse(JSON.stringify(result.json()));
          this.speechToTextString = JSON.parse(
            JSON.stringify(result) //.json())
          ).results[0].alternatives[0].transcript;
          this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].sentaku = this.speechToTextString;
          console.log("this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].sentaku--",this.firestoreAccessor.currentGuiStatus.currentKaitou[targetMondaiId].sentaku)
          this.presentSpeechToTextConfirm();
          
        },
        (err) => {
          console.log("speechToText error");
          console.log(err);
        }
      );
    };
    // Store chunks of recorded video
    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    // Start recording wth chunks of data
    this.mediaRecorder.start(100);
    this.isRecordingSound = true;
  }

  stopRecordSound() {
    this.mediaRecorder.stop();
    this.mediaRecorder = null;
    //this.captureElement.nativeElement.srcObject = null;
    this.isRecordingSound = false;
  }

  async presentSpeechToTextConfirm() {
    //音声認識実行を通知するダイアログ
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '音声を認識しました',
      message: '「'+this.speechToTextString+'」',//<BR>→「'+this.translatedString+'」',
      buttons: [
        {
          text: '閉じる',
          handler: () => {
            let tmpStr = "AAA"//console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }


}
