import { Injectable } from '@angular/core';
import {
  Directory,Filesystem
} from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class VideoService {
  public videos = [];
  private VIDEOS_KEY = "videos";

  //translationで使うデータ
  data = {
    title: "",
    description: "",
    detail: "",
  };

  voiceDictionary = {
    en: {
      languageCode: "en-US",
      name: "en-US-Wavenet-A",
    },
    cn: {
      languageCode: "cmn-CN",
      name: "cmn-CN-Standard-B", //"yue-HK-Standard-A"//
    },
    ja: {
      languageCode: "ja-JP",
      name: "ja-JP-Standard-B", //"ja-JP-Standard-B"//
    },
  };
  //axios: any;
  slackSearchResultData;
  slackMeassagesArray = [];
  kidouTimeStamp = Math.floor( new Date().getTime() / 1000 )

  targetPerson = 
  {
    id:2,
    name:"AAAName",
    sourceLanguage: 'ja',
    targetLanguage: 'en',
    imageFileName: '',
  };
  targetPersonArray = [
    {
      id:1,
      name:"Naomi",
      sourceLanguage: 'en',
      targetLanguage: 'ja',
      imageFileName: '',
    },
  ]
  //定数定義ここまで//////////////////////////////////////

  constructor(public http: HttpClient) {
    //this.axios = axios.create();
  }

  async loadVideos() {
    const videoList = await Storage.get({ key: this.VIDEOS_KEY });
    this.videos = JSON.parse(videoList.value) || [];
    return this.videos;
  }

  async storeVideo(blob) {
    const fileName = new Date().getTime() + ".mp4";

    const base64Data = (await this.convertBlobToBase64(blob)) as string;
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });

    // Add file to local array
    this.videos.unshift(savedFile.uri);

    // Write information to storage
    return Storage.set({
      key: this.VIDEOS_KEY,
      value: JSON.stringify(this.videos),
    });
  }


  getSpeechToText(base64Data, targetLanguage:string) {
    //音声認識するGoogleAPIに音声データをPOSTする
    const body = {
      config: {
        languageCode: this.voiceDictionary[targetLanguage].languageCode,//"ja-JP",
      },
      audio: {
        content: base64Data,
      },
    };
    return this.http.post(
      'https://speech.googleapis.com/v1/speech:recognize?key=' +
        environment.googleSpeechToTextAPIKey,
      body
    );
  }

  // Helper function
  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

}
