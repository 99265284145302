import { Component, OnInit } from '@angular/core';
import { Mondai } from '../mondai/mondai';
import { NavParams, ModalController } from '@ionic/angular';
import { FirestoreAccessorService } from '../firestore-accessor.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-mondai-preview',
  templateUrl: './mondai-preview.page.html',
  styleUrls: ['./mondai-preview.page.scss'],
})
export class MondaiPreviewPage implements OnInit {
  cMData:Mondai;
  nendoName = {};

  constructor(
    private navParams: NavParams, 
    public firestoreAccessor: FirestoreAccessorService,
    public domSanitizer: DomSanitizer,
    public modalController: ModalController,
  ) {
    this.cMData = this.navParams.get('mondai');
    this.firestoreAccessor = this.navParams.get('firestoreAccessor');
    this.nendoName = this.navParams.get('nendoName');
  }

  ngOnInit() {
  }

}
