import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { MondaiPreviewPage } from './mondai-preview.page';

const routes: Routes = [
  {
    path: '',
    component: MondaiPreviewPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  //declarations: [MondaiPreviewPage]
  declarations: []
})
export class MondaiPreviewPageModule {}
